interface SplitOptions {
  allowRegex?: boolean;
}

export function splitSearchTerm(searchTerm: string, options: SplitOptions = {}): string[] {
  const length = searchTerm.length;

  if (!length) {
    return [];
  }

  if (options.allowRegex && (searchTerm[0] === '^' || searchTerm[length - 1] === '$')) {
    return [searchTerm];
  }

  const phrases = [];
  let lastPhrase = '';
  let lastDelimiter = '';
  let isOpenPhrase = false;
  for (let i = 0; i < length; i++) {
    if (
      !isOpenPhrase &&
      !lastPhrase &&
      ['"', "'"].includes(searchTerm[i]) &&
      searchTerm.substr(i + 1).includes(searchTerm[i])
    ) {
      //Start the phrase:
      lastDelimiter = searchTerm[i];
      isOpenPhrase = true;
    } else if (isOpenPhrase && searchTerm[i] === lastDelimiter) {
      //Close the phrase:
      isOpenPhrase = false;
      phrases.push(lastPhrase);
      lastPhrase = '';
    } else if (!isOpenPhrase && lastPhrase && searchTerm[i] === ' ') {
      //Commit the phrase:
      phrases.push(lastPhrase);
      lastPhrase = '';
    } else if (isOpenPhrase || searchTerm[i] !== ' ') {
      lastPhrase += searchTerm[i];
    }
  }
  if (lastPhrase) {
    phrases.push(lastPhrase);
  }
  return phrases;
}
