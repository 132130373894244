import {EnumConnectedServiceType} from '@octaved/env/src/dbalEnumTypes';
import {CalendarEvent} from '@octaved/integration/src/EntityInterfaces/CalendarEvents';
import {ConnectedService} from '@octaved/integration/src/EntityInterfaces/ConnectedService';
import {DateTimeStr} from '@octaved/typescript';
import {ISO_DATE_TIME} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import dayjs from 'dayjs';
import {t} from 'i18next';
import once from 'lodash/once';

export const getDemoAzureCalendarService = once(
  (): ConnectedService => ({
    accountDisplayName: 'demo.account@outlook.com',
    acquiredScopes: [],
    connectedAt: 0,
    data: {
      calendars: [
        {
          color: 'default',
          id: 'E5988623531C4460AB592DE573A8860A',
          name: 'Calendar',
          showInMyCalendar: true,
        },
      ],
    },
    id: '9BF0E6CB60E24890A9EEBBF3D5AE0ED9',
    refreshTokenAcquired: null,
    serviceType: EnumConnectedServiceType.VALUE_AZURE_CALENDAR,
  }),
);

function generateEvent(
  subject: string,
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
  dayShift: number | [number, number],
  isAllDay = false,
): CalendarEvent {
  const startShift = Array.isArray(dayShift) ? dayShift[0] : dayShift;
  const endShift = Array.isArray(dayShift) ? dayShift[1] : dayShift;
  const start = dayjs().second(0).add(startShift, 'day');
  const end = dayjs().second(0).add(endShift, 'day');
  return {
    isAllDay,
    subject,
    calendarId: 'E5988623531C4460AB592DE573A8860A',
    connectedServiceId: '9BF0E6CB60E24890A9EEBBF3D5AE0ED9',
    end: end.hour(endHour).minute(endMinute).format(ISO_DATE_TIME) as DateTimeStr,
    id: generateUuid(),
    start: start.hour(startHour).minute(startMinute).format(ISO_DATE_TIME) as DateTimeStr,
    webUrl: null,
  };
}

export const getDemoCalendarEvents = once((): CalendarEvent[] => {
  return [
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d-1h15'), 15, 0, 16, 0, -1),
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d0h10'), 10, 0, 10, 30, 0),
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d0h14'), 14, 0, 15, 0, 0),
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d1h11'), 11, 0, 12, 0, 1),
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d1h12'), 12, 30, 13, 30, 1),
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d2h08'), 8, 0, 9, 30, 2),
    generateEvent(t('pages:myTasks.calendarEvents.demoData.d3h13'), 13, 30, 14, 0, 3),

    generateEvent(t('pages:myTasks.calendarEvents.demoData.allDay'), 0, 0, 23, 59, [0, 2], true),
  ];
});
