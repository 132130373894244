import {parse} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';

interface Props {
  hours: number | string | null;
  showMinutes?: boolean;
}

const hoursDisplayingDays = new Set([4, 8, 12, 16, 24, 32, 40]);

export default function HoursDisplay({hours, showMinutes = false}: Props): ReactElement {
  if (hours && showMinutes && parse('' + hours) === 0.25) {
    return <>15&nbsp;min</>;
  }

  if (hours && +hours === 1) {
    return <>1&nbsp;h</>;
  }

  if (hours && hoursDisplayingDays.has(+hours)) {
    const days = +hours / 8;
    if (+hours === 4) {
      return <>4&nbsp;h</>;
    } else if (+hours === 12) {
      return <>12&nbsp;h</>;
    }
    return <>{days}&nbsp;d</>;
  }

  return <>{hours}&nbsp;h</>;
}
