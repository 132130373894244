import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {Group as IGroup, Project, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getSubWorkPackageSelector, getWorkPackageSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {
  billingTypesBookLimitsSelector,
  useTimeTrackingSelector,
} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {getTaskSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {isGroup, isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {determineProgressColor} from '@octaved/flow/src/WorkPackage/ProgressColor';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {Group} from 'konva/lib/Group';
import {Rect} from 'konva/lib/shapes/Rect';
import {Text} from 'konva/lib/shapes/Text';
import {createText} from '../../../CommonComponents/Text';
import {createStoreSubscription} from '../../../StoreSubscription';
import {NodeCell} from './NodeCell';

export abstract class EffortCell extends NodeCell {
  protected abstract nodeEntity: NodeEntity | null;

  protected textNode: Text | null = null;
  protected progressBar: Group | null = null;
  protected progress: Rect | null = null;

  protected readonly progressBarWidth = 25;
  protected readonly progressBarHeight = 8;

  init(): void {
    super.init();
    this.progressBar = new Group({visible: false, y: (this.ctx.rowHeight - this.progressBarHeight) * 0.5});

    this.textNode = createText({
      offsetY: this.textLineOffset,
      x: this.padding + this.progressBarWidth,
    });

    this.progress = new Rect({
      height: this.progressBarHeight,
    });
    const progressBackground = new Rect({
      fill: '#f0f0f0',
      height: this.progressBarHeight,
      width: this.progressBarWidth,
    });
    this.progressBar.add(progressBackground);
    this.progressBar.add(this.progress);

    this.root.add(this.textNode);
    this.root.add(this.progressBar);
  }

  protected doRender(): void {
    this.textNode?.fill(this.getFontColor());
    this.textNode?.fontVariant(this.getFontVariant());
  }

  protected hideProgressbar(): void {
    this.progressBar?.visible(false);
  }

  protected renderProgressbar(value: number, total: number, color: string): void {
    if (!this.progressBar || !this.progress) {
      throw new Error('Progressbar not initialized');
    }
    this.progressBar.visible(true);
    this.progress.width(Math.min((this.progressBarWidth * value) / total, this.progressBarWidth));
    this.progress.fill(color);
  }

  protected getProgressColor(
    billingType: EnumFlowPidBillingType,
    billedHours: number | null = null,
    maxEffort: number | null = null,
    effortTo: number | null = null,
  ): string {
    const billingTypesBookLimits = billingTypesBookLimitsSelector(this.ctx.store.getState());
    return determineProgressColor(
      {billingType, trackedMinutes: {billed: (billedHours || 0) * 60}, maxEffort, effortTo},
      billingTypesBookLimits,
    );
  }

  protected getDurationText(from: number | null, to: number | null): string {
    let text = '';
    if (from !== null && to !== null) {
      const diff = to - from;

      const hours = Math.floor(diff / 3600);
      const minutes = Math.floor((diff % 3600) / 60);

      if (hours > 0) {
        text = `${hours}h `;
      }
      if (minutes > 0) {
        text += `${minutes}m`;
      }
      if (diff === 0) {
        text = '0h';
      }
    }

    return text;
  }

  protected renderBilledMax(
    billingType: EnumFlowPidBillingType,
    billedHours: number,
    maxEffortHours: number | null,
  ): void {
    if (!maxEffortHours) {
      this.root.visible(false);
      return;
    }

    const color = this.getProgressColor(billingType, billedHours, maxEffortHours, null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const usingTimeTracking = useTimeTrackingSelector(this.ctx.store.getState());

    if (usingTimeTracking) {
      this.renderProgressbar(billedHours, maxEffortHours, color);
      this.textNode?.text(
        this.ctx.t('general:xOfYHours', {
          count: Math.round(maxEffortHours),
          hours: formatDecimal(billedHours),
          total: formatDecimal(maxEffortHours),
        }),
      );
    } else {
      this.hideProgressbar();
      this.textNode?.text(
        this.ctx.t('general:xHours', {
          count: Math.round(maxEffortHours),
          hours: formatDecimal(maxEffortHours),
        }),
      );
    }
  }

  renderBilledOnly(billedHours: number): void {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const usingTimeTracking = useTimeTrackingSelector(this.ctx.store.getState());

    if (usingTimeTracking) {
      this.textNode?.text(this.getDurationText(0, billedHours * 3600));
    } else {
      this.hideProgressbar();
      this.textNode?.visible(false);
    }

    this.hideProgressbar();
  }
}

export class WorkPackageEffortCell extends EffortCell {
  protected nodeEntity: WorkPackage | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getWorkPackageSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
    );
  }

  protected doRender(): void {
    super.doRender();
    const {x, isVisble} = this.renderProps;
    this.root.visible(isVisble);
    if (!isVisble || !this.nodeEntity || !this.nodeEntity.billingType) {
      return;
    }

    this.root.x(x);

    const billingType = this.nodeEntity.billingType;
    const billedHours = this.nodeEntity.trackedMinutes.billed / 60;

    if (
      [
        EnumFlowPidBillingType.VALUE_CONTINGENT,
        EnumFlowPidBillingType.VALUE_EFFORT_CAP,
        EnumFlowPidBillingType.VALUE_EFFORT_EST,
      ].includes(billingType)
    ) {
      this.renderBilledMax(billingType, billedHours, this.nodeEntity.maxEffort);
    } else if (billingType === EnumFlowPidBillingType.VALUE_FIXED_PRICE) {
      if (this.nodeEntity.maxEffort) {
        this.renderBilledMax(billingType, billedHours, this.nodeEntity.maxEffort);
      } else {
        this.renderBilledOnly(billedHours);
      }
    } else if (billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO) {
      this.#renderBilledFromTo(billingType, billedHours, this.nodeEntity.effortFrom, this.nodeEntity.effortTo);
    } else if (
      [EnumFlowPidBillingType.VALUE_EFFORT, EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE].includes(billingType)
    ) {
      this.renderBilledOnly(billedHours);
    }
  }

  #renderBilledFromTo(
    billingType: EnumFlowPidBillingType,
    billedHours: number,
    effortFromHours: number | null,
    effortToHours: number | null,
  ): void {
    if (!(effortFromHours && effortToHours)) {
      this.root.visible(false);
      return;
    }
    const color = this.getProgressColor(billingType, billedHours, null, effortToHours);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const usingTimeTracking = useTimeTrackingSelector(this.ctx.store.getState());

    if (usingTimeTracking) {
      this.renderProgressbar(billedHours, effortToHours, color);
      this.textNode?.text(
        this.ctx.t('general:xOfYThroughZHours', {
          count: Math.round(effortToHours),
          from: formatDecimal(effortFromHours),
          hours: formatDecimal(billedHours),
          to: formatDecimal(effortToHours),
        }),
      );
    } else {
      this.hideProgressbar();
      this.textNode?.text(
        this.ctx.t('general:xThroughZHours', {
          count: Math.round(effortToHours),
          from: formatDecimal(effortFromHours),
          to: formatDecimal(effortToHours),
        }),
      );
    }
  }
}

export class SubWorkPackageEffortCell extends EffortCell {
  protected nodeEntity: SubWorkPackage | null = null;
  protected workPackage: WorkPackage | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getSubWorkPackageSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeAncestrySelector(store)(this.node.id).workPackage,
        (workPackage) => {
          this.workPackage = workPackage || null;
          this.render();
        },
      ),
    );
  }

  protected doRender(): void {
    super.doRender();
    const {x, isVisble} = this.renderProps;
    this.root.visible(isVisble);
    if (!isVisble || !this.nodeEntity || !this.workPackage || !this.workPackage.billingType) {
      return;
    }

    this.root.x(x);

    const billedHours = this.nodeEntity.trackedMinutes.billed / 60;
    if (this.nodeEntity.maxEffort) {
      this.renderBilledMax(this.workPackage.billingType, billedHours, this.nodeEntity.maxEffort);
    } else {
      this.renderBilledOnly(billedHours);
    }
  }
}

export class TaskEffortCell extends EffortCell {
  protected nodeEntity: Task | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getTaskSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
    );
  }

  protected doRender(): void {
    super.doRender();
    const {x, isVisble} = this.renderProps;
    this.root.visible(isVisble);
    if (!isVisble || !this.nodeEntity) {
      return;
    }

    this.root.x(x);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const usingTimeTracking = useTimeTrackingSelector(this.ctx.store.getState());

    if (usingTimeTracking && this.nodeEntity.plannedTime) {
      this.textNode?.text(this.getDurationText(0, this.nodeEntity.plannedTime * 3600));
    } else {
      this.hideProgressbar();
      this.textNode?.visible(false);
    }

    this.hideProgressbar();
  }
}

export class ProjectGroupEffortCell extends EffortCell {
  protected nodeEntity: IGroup | Project | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          if (isProject(nodeEntity) || isGroup(nodeEntity)) {
            this.nodeEntity = nodeEntity || null;
            this.render();
          }
        },
      ),
    );
  }

  protected doRender(): void {
    super.doRender();
    const {x, isVisble} = this.renderProps;
    this.root.visible(isVisble);
    if (!isVisble || !this.nodeEntity) {
      return;
    }

    this.root.x(x);

    const plannedEffort = this.nodeEntity.maxEffort;
    if (plannedEffort) {
      const trackedHours = this.nodeEntity.trackedMinutes.billed / 60;

      this.renderProgressbar(trackedHours, plannedEffort, trackedHours > plannedEffort ? 'red' : 'green');

      this.textNode?.text(
        this.ctx.t('general:ranges.nOfCountHours', {
          count: plannedEffort,
          countFormatted: formatDecimal(plannedEffort),
          n: formatDecimal(trackedHours),
        }),
      );
    } else {
      this.root.visible(false);
    }
  }
}
