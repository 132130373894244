import Spacer from '@octaved/ui-components/src/React/Spacer';
import classNames from 'classnames';
import {Pencil} from 'lucide-react';

import {HStack, IconButton} from '@octaved/ui';
import {ReactElement, useState} from 'react';
import {SketchPicker} from 'react-color';
import {useTranslation} from 'react-i18next';
import Link from '../../Link';

export const LabelColors = [
  '#009966',
  '#8fbc8f',
  '#3cb371',
  '#00b140',
  '#013220',
  '#6699cc',
  '#0000ff',
  '#e6e6fa',
  '#9400d3',
  '#330066',
  '#808080',
  '#36454f',
  '#f7e7ce',
  '#c21e56',
  '#cc338b',
  '#dc143c',
  '#ff0000',
  '#cd5b45',
  '#eee600',
  '#ed9121',
  '#c39953',
];

export const NewLabelColors = [
  '#009966',
  '#8fbc8f',
  '#3cb371',
  '#00b140',
  '#013220',
  '#6699cc',
  '#0000ff',
  '#e6e6fa',
  '#9400d3',
  '#330066',
  '#808080',
  '#36454f',
  '#f7e7ce',
  '#c21e56',
  '#cc338b',
  '#cd5b45',
  '#eee600',
  '#ed9121',
  '#c39953',
];

interface Props {
  color: string | null;
  isEditing?: boolean;
  palette?: string[];
  readonly?: boolean;
  setColor: (color: string) => void;
}

export default function ColorPickerWithPalette({
  color,
  isEditing,
  palette = LabelColors,
  readonly = false,
  setColor,
}: Props): ReactElement {
  const {t} = useTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [edit, setEdit] = useState(Boolean(isEditing));
  return (
    <div className={classNames('colorPicker', {readonly})}>
      <HStack spacing={'12px'} alignItems={'center'}>
        <div
          className={classNames('color selected', {noColor: !color})}
          key={color}
          style={{backgroundColor: color ?? '#FFF'}}
        />
        {!edit && !readonly && (
          <IconButton icon={<Pencil />} onClick={() => setEdit(true)} variant={'ghost'} size={'sm'} />
        )}
      </HStack>
      {!readonly && <Spacer spacing={10} />}

      {edit && !readonly && (
        <>
          <div className={'palette'}>
            {palette.map((code) => (
              <div
                className={'color'}
                key={code}
                style={{backgroundColor: code}}
                onClick={() => !readonly && setColor(code)}
              />
            ))}
          </div>
          <Spacer spacing={10} />
        </>
      )}

      {edit && !showColorPicker && !readonly && (
        <Link onClick={() => setShowColorPicker(true)}>
          <span>{t('systemSettings:labels.moreColors')}</span>
        </Link>
      )}

      {edit && showColorPicker && !readonly && (
        <div className={'picker'}>
          <SketchPicker
            color={color ?? '#FFF'}
            disableAlpha
            onChange={({hex}) => !readonly && setColor(hex)}
            presetColors={[]}
          />
        </div>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .palette {
          display: flex;
          grid-gap: 0.4375rem;
          flex-wrap: wrap;
        }

        .color {
          border-radius: 2px;
          width: 21px;
          height: 21px;

          &:not(.selected) {
            cursor: pointer;
          }
        }

        .selected {
          width: 2rem;
          height: 2rem;
        }

        .inputLabel {
          padding: 0 !important;
        }

        .inputColor {
          height: 38px;
          width: 38px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        .picker :global(.sketch-picker) {
          box-shadow: none !important;
          padding: 0 !important;
        }

        .picker :global(.sketch-picker .flexbox-fix + .flexbox-fix) {
          display: none !important;
        }

        .readonly .color {
          cursor: default;
          pointer-events: none;
        }

        .noColor {
          border: 1px solid #ccc;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
