import {HStack} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useTimeTrackingSelector} from '../../../Modules/Selectors/SettingSelectors';
import Duration from '../../Duration/Duration';
import Indicator from '../../Tree/Components/WorkPackageTile/Indicator';
import {BillingTypeComponentProps} from '../Components';

export default function BilledOnly({
  noIndicator = false,
  showBillingType,
  showProgressLabel,
  sum: {billableHours},
  typeTranslationToken,
}: BillingTypeComponentProps): ReactElement | null {
  const usingTimeTracking = useSelector(useTimeTrackingSelector);
  if (!usingTimeTracking) {
    return null;
  }

  return (
    <>
      {!noIndicator && (
        <HStack justifyContent={'flex-end'}>
          <Indicator color={'grey'} />
        </HStack>
      )}
      {showProgressLabel && <Duration from={0} to={billableHours * 3600} />}
      {showBillingType && (
        <div>
          (
          <span>
            <Trans i18nKey={typeTranslationToken} />
          </span>
          )
        </div>
      )}
    </>
  );
}
