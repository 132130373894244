import {FlowState} from '@octaved/flow/src/Modules/State';
import {GanttContext, GanttContextProps} from '../Gantt/Context/GanttContext';
import {createStoreSubscription} from '../StoreSubscription';

export type ProjectBasedContextProps = GanttContextProps;

export abstract class ProjectBasedContext extends GanttContext {
  #visibleColumns: string[] = [];
  #extendedNodes: Record<string, boolean> | null = null;

  init(): void {
    super.init();

    this.disposables.push(
      createStoreSubscription(
        this.store,
        (s) => this.getVisibleColumnsSelector(s),
        (visibleColumns) => {
          this.#visibleColumns = visibleColumns;
          if (visibleColumns) {
            this.eventEmitter.emit('visibleColumnsChanged', visibleColumns);
          }
        },
      ),
      createStoreSubscription(
        this.store,
        (s) => this.getExtendedNodesSelector(s),
        (extendedNodes) => {
          this.#extendedNodes = extendedNodes;
          this.eventEmitter.emit('extendedNodesChanged', extendedNodes);
        },
      ),

      createStoreSubscription(
        this.store,
        (s) => this.getColumnsWidthSelector(s),
        (columnWidth) => {
          this.columnWidth = columnWidth;
        },
      ),
    );
  }

  protected abstract getVisibleColumnsSelector(state: FlowState): string[];
  protected abstract getExtendedNodesSelector(state: FlowState): Record<string, boolean> | null;
  protected abstract getColumnsWidthSelector(state: FlowState): number;

  get visibleColumns(): string[] {
    if (this.#visibleColumns === null) {
      throw Error('"visibleColumns" not initialized');
    }
    return this.#visibleColumns;
  }

  get extendedNodes(): Record<string, boolean> | null {
    return this.#extendedNodes;
  }

  abstract setExtendedNodes(extendedNodes: Record<string, boolean>): void;
}
