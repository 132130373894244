import {Uuid} from '@octaved/typescript/src/lib';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLoadNode, useNode} from '../../Modules/Hooks/Nodes';
import {formatNameWithTimeControl} from '../../Modules/TimeControlledEntity';
import {SearchHighlightText} from '../Search/SearchHighlight';

interface NodeNameParams {
  ellipsis?: boolean;
  nodeId: Uuid;
  notSearchable?: boolean;
}

export default function NodeName({ellipsis, nodeId, notSearchable}: NodeNameParams): ReactElement | null {
  const {t} = useTranslation();
  const node = useNode(nodeId);
  useLoadNode(nodeId);
  const dateFormat = useSelector(dateFormatSelector);

  if (!node || !dateFormat) {
    return null;
  }

  const text = formatNameWithTimeControl(t, dateFormat, node);

  if (notSearchable) {
    return <>{text}</>;
  }

  return <SearchHighlightText text={text} ellipsis={ellipsis} />;
}
