import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {getNodeSearchSelector} from './NodeSearchSelectors';
import {getAllDescendantIdsSelector} from './NodeTreeSelectors';

const wpIsCompleteSelector = getNodeSearchSelector('wpIsCompleted');

/**
 * A node is considered executed if it has no descendant work packages that are not executed.
 * So an empty project is considered executed, too.
 *
 * Requires the searches to be run! See useIsNodeExecuted().
 */
const nodeTypeWpSelector = getNodeSearchSelector('nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE);
export const isNodeExecutedSelector = createSelector(
  nodeTypeWpSelector,
  wpIsCompleteSelector,
  getAllDescendantIdsSelector,
  (allWpIds, completedWpIds, getAllDescendantIds) =>
    memoize((nodeId: Uuid): boolean => {
      const allDescendantIdsSet = getAllDescendantIds(nodeId, true);
      for (const descendantId of allDescendantIdsSet) {
        if (allWpIds.has(descendantId) && !completedWpIds.has(descendantId)) {
          return false;
        }
      }
      return true;
    }),
);
