import {post} from '@octaved/network/src/Network';
import {notifyWelcomeFlashPopupClosed} from '@octaved/organization/config/routes';
import {Button, FlexSpacer, Stack} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {closeDialogRoute} from '../../Routing/Routes/DialogRoutes';
import {DialogContent, DialogFrame} from '../DialogFrame';
import Illustration from './Illustration.svg';

export default function DemoWelcome(): ReactElement {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const close = (): void => {
    navigate(closeDialogRoute);
    post(notifyWelcomeFlashPopupClosed);
  };
  return (
    <DialogFrame noOwnForm>
      <DialogContent>
        <Stack alignItems={'center'} spacing={'30px'}>
          <div>
            <Illustration />
          </div>
          <h1>{t('dialogs:demoWelcome.title')}</h1>
          <p>{t('dialogs:demoWelcome.info')}</p>
          <Button onClick={close}>{t('dialogs:demoWelcome.close')}</Button>
          <FlexSpacer />
        </Stack>
        <style jsx>{`
          p {
            text-align: center;
            max-width: 80%;
          }
        `}</style>
      </DialogContent>
    </DialogFrame>
  );
}
