import {ReactElement} from 'react';
import SaveButton, {SaveButtonProps} from './SaveButton';

export default function Save(props: SaveButtonProps): ReactElement {
  return (
    <div className={'save'}>
      <SaveButton {...props} type={'submit'} />
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .save {
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
