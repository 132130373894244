import {schema} from 'normalizr';

export const orgUser = new schema.Entity('orgUser', {}, {});

export const userGroup = new schema.Entity('userGroup');

export const groupUsers = new schema.Entity(
  'groupUsers',
  {},
  {
    processStrategy: (value) => value.users,
  },
);

export const groupGroups = new schema.Entity(
  'groupGroups',
  {},
  {
    processStrategy: (value) => value.groups,
  },
);
