import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M19 15H17C15.8954 15 15 15.8954 15 17V19C15 20.1046 15.8954 21 17 21H19C20.1046 21 21 20.1046 21 19V17C21 15.8954 20.1046 15 19 15Z',
      key: '1',
    },
  ],
  [
    'path',
    {
      d: 'M7 3H5C3.89543 3 3 3.89543 3 5V7C3 8.10457 3.89543 9 5 9H7C8.10457 9 9 8.10457 9 7V5C9 3.89543 8.10457 3 7 3Z',
      key: '2',
    },
  ],
  [
    'path',
    {
      d: 'M6 10V16C6 17.1046 6.89543 18 8 18H14',
      key: '3',
    },
  ],
];

export const LogicalPlanningSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const LogicalPlanning = createLucideIcon('LogicalPlanning', iconDrawing);
