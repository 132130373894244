import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {setNodeToRelative, setNodeToUnplanned} from '@octaved/planning/src/Modules/PlanningOptions';
import {DateTimeStr} from '@octaved/typescript';
import {IconButton, Tooltip} from '@octaved/ui';
// eslint-disable-next-line import/no-internal-modules
import StandardDateOrRangePickerFooter from '@octaved/ui/src/Form/Datepicker/Internal/StandardDateOrRangePickerFooter';
import {generateUuid} from '@octaved/utilities';
import {Airplay, Trash} from 'lucide-react';
import {MutableRefObject, ReactElement, RefObject, useCallback, useEffect, useState} from 'react';
import {FocusedInputShape} from 'react-dates';
import {useDispatch, useSelector} from 'react-redux';
import {isWorkPackage} from '../../../../../../Node/NodeIdentifiers';
import {getNodeSelector} from '../../../../../../Modules/Selectors/NodeSelectors';
import {getTaskWorkPackageSelector, getTasksRootSelector} from '../../../../../../Modules/Selectors/TaskSelectors';
import {FlowState} from '../../../../../../Modules/State';
import PlanningDate from '../../../../../Node/PlanningDate';
import {useTaskListContext} from '../../../../TaskListContext';
import CellEditPopup from '../Components/CellEditPopup';
import PickerView from './PickerView';

interface Props {
  closeCbRef: MutableRefObject<(() => void) | null>;
  contextRef: RefObject<HTMLDivElement>;
  hasPredecessors: boolean;
  id: string;
  onOpenAdvanced: () => void;
  open: boolean;
  plannedEnd: DateTimeStr | null;
  plannedStart: DateTimeStr | null;
  toggleOpen: () => void;
}

interface PatchData {
  plannedStart: DateTimeStr;
  plannedEnd: DateTimeStr;
}

export default function EditPopup({
  closeCbRef,
  contextRef,
  hasPredecessors,
  id,
  onOpenAdvanced,
  open,
  plannedEnd,
  plannedStart,
  toggleOpen,
}: Props): ReactElement {
  const [start, setStart] = useState(plannedStart);
  const [end, setEnd] = useState(plannedEnd);
  const singleDaySelected = start === end;
  const [focused, setFocused] = useState<FocusedInputShape | null>(
    (plannedStart && !plannedEnd) || (plannedStart && singleDaySelected) ? 'endDate' : 'startDate',
  );
  useEffect(() => setStart(plannedStart), [plannedStart]);
  useEffect(() => setEnd(plannedEnd), [plannedEnd]);
  const taskRoot = useSelector((s: FlowState) => getTasksRootSelector(s)(id));
  const {showAdvancedPlanning} = useTaskListContext();
  const dispatch = useDispatch();
  const wp = useSelector((s: FlowState) => getTaskWorkPackageSelector(s)(id));
  const planningDates = useSelector((s: FlowState) => getNodeSelector(s)(id)?.planningDates || null);
  const {plannedStart: wpPlannedStart} = useMinMaxPlanningDates(wp?.id);
  const wpPlanned = wpPlannedStart;

  const patch = useCallback(
    (data: PatchData | null) => {
      if (data) {
        const planingDateId = planningDates?.[0] || generateUuid();
        dispatch(
          setNodeToRelative(id, {
            assignedNodeId: null,
            id: planingDateId,
            name: '',
            nodeId: id,
            ...data,
          }),
        );
      } else {
        dispatch(setNodeToUnplanned(id));
      }
    },
    [dispatch, id, planningDates],
  );
  const isDependent = hasPredecessors;
  const [touched, setTouched] = useState(false);
  const hasAdvancedLink = id && showAdvancedPlanning && isWorkPackage(taskRoot);

  useEffect(() => {
    if (isDependent) {
      onOpenAdvanced();
    }
  }, [onOpenAdvanced, isDependent]);

  closeCbRef.current = () => {
    if (start) {
      patch({plannedEnd: end ? end : start, plannedStart: start});
    }
  };

  return (
    <>
      <CellEditPopup
        open={open}
        contextRef={contextRef}
        noYPadding
        noFooterWrap
        footer={
          <StandardDateOrRangePickerFooter
            showLegend={isWorkPackage(taskRoot) && !isDependent}
            canClear={false}
            close={(e) => {
              e.stopPropagation();
              if (start) {
                patch({plannedEnd: end ? end : start, plannedStart: start});
              }
              toggleOpen();
            }}
            clear={() => false}
            footerAddition={
              <>
                {hasAdvancedLink && (
                  <Tooltip toolTipTranslation={'taskList:rowMenu.openDetails'}>
                    <IconButton
                      variant={'ghost'}
                      icon={<Airplay rotate={'90deg'} />}
                      size={'sm'}
                      onClick={async () => {
                        if (start) {
                          patch({plannedEnd: end ? end : start, plannedStart: start});
                        }
                        onOpenAdvanced();
                      }}
                    />
                  </Tooltip>
                )}
                <IconButton
                  variant={'ghost'}
                  size={'sm'}
                  icon={<Trash />}
                  iconColor={'red'}
                  onClick={() => {
                    patch(null);
                    toggleOpen();
                  }}
                />
              </>
            }
          />
        }
      >
        <div>
          {isDependent && (
            <PlanningDate
              plannedStart={plannedStart}
              plannedEnd={plannedEnd}
              hasGoToProjectPlanningInfo={!showAdvancedPlanning}
            />
          )}

          {!hasPredecessors && (
            <PickerView
              id={id}
              isRange
              start={wpPlanned && !touched ? null : start}
              end={singleDaySelected ? null : wpPlanned && !touched ? null : end}
              focused={focused}
              numberOfMonth={1}
              setFocused={setFocused}
              onChangeDates={(startDate, endDate) => {
                if (focused === 'startDate') {
                  setStart(startDate);
                  setEnd(null);
                  setFocused('endDate');
                } else {
                  if (startDate && endDate) {
                    setStart(startDate);
                    setEnd(endDate);
                  } else if (!endDate) {
                    setStart(startDate);
                    setEnd(start);
                  }
                  setFocused('startDate');
                }
                setTouched(true);
              }}
            />
          )}
        </div>
      </CellEditPopup>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .header {
          display: flex;
          align-items: center;
          grid-gap: 14px;
        }

        .icon {
          margin-left: 4px;
          vertical-align: -0.1em;
        }

        .closeIcon {
          margin-right: 4px;
          vertical-align: -0.2em;
        }

        .close {
          position: absolute;
          top: 0.8rem;
          right: 0.8rem;
        }

        .footer,
        .leftStructure {
          display: flex;
          grid-gap: 14px;
          align-items: center;
        }

        .footer {
          justify-content: space-between;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
