import {isDebug} from '@octaved/env/src/Environment';
import {objectEntries} from '@octaved/utilities/src/Object';
import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';

export const optionalFieldLoading = Symbol('optionalFieldLoading');

type Condition = boolean | string | number | null | undefined | typeof optionalFieldLoading;

function evalCondition(condition: Condition): boolean | typeof optionalFieldLoading {
  if (condition === optionalFieldLoading) {
    return optionalFieldLoading;
  }
  return Boolean(condition);
}

export interface OptionalField {
  enable: () => void;
  set: (show: boolean | typeof optionalFieldLoading) => void;
  show: boolean | typeof optionalFieldLoading;
}

export type OptionalFields<T extends string> = Record<T, OptionalField>;

export function useOptionalFields<T extends string>(
  resetTrigger: string,
  conditions: Record<T, Condition>,
): OptionalFields<T> {
  const conditionsRef = useRef(conditions);
  if (isDebug && JSON.stringify(Object.keys(conditions)) !== JSON.stringify(Object.keys(conditionsRef.current))) {
    throw new Error('The conditions must be static!');
  }
  conditionsRef.current = conditions;

  const setFieldsRef = useRef<Dispatch<SetStateAction<OptionalFields<T>>>>();

  const reset = useCallback((): OptionalFields<T> => {
    return Object.fromEntries(
      objectEntries(conditionsRef.current).map(([key, condition]) => {
        const set = (toggle: boolean | typeof optionalFieldLoading = true): void => {
          setFieldsRef.current?.((cur) => {
            const next = {...cur};
            next[key].show = toggle;
            return next;
          });
        };
        const field: OptionalField = {
          set,
          enable: () => set(true),
          show: evalCondition(condition),
        };
        return [key, field];
      }),
    ) as OptionalFields<T>;
  }, []);

  const [fields, setFields] = useState(reset);
  setFieldsRef.current = setFields;

  useEffect(() => {
    // noinspection BadExpressionStatementJS
    void resetTrigger; //Trigger full reset when this changes
    setFields(reset());
  }, [resetTrigger, reset]);

  //Reset each condition if it changes
  objectEntries(conditions).map(([key, condition]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => fields[key].set(evalCondition(condition)), [key, condition]);
  });

  return fields;
}
