import {isEvent, PlanningEvent} from '@octaved/flow/src/Modules/Events';
import {extendWithAncestorsSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {
  MinMaxPlanningDateInSubtree,
  MinMaxPlanningDateInSubtrees,
} from '@octaved/planning/src/EntityInterfaces/MinMaxPlanningDateInSubtrees';
import {
  minMaxPlanningDateInSubtreesSelector,
  minMaxPlanningDateInSubtreesStatesSelector,
} from '@octaved/planning/src/Selectors/MinMaxPlanningDateInSubtreesSelectors';
import {createTimestampReducer, EntityStates, INVALIDATED} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {Action} from 'redux';
import {getMinMaxPlanningDateInSubtrees} from '../../config/routes';
import {
  FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_START,
  FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_SUCCESS,
} from './ActionTypes';

const reducers = createReducerCollection<MinMaxPlanningDateInSubtrees>({});
export const minMaxPlanningDateInSubtreesReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityStates>({});
export const minMaxPlanningDateInSubtreesStateReducer = stateReducers.reducer;

export const [, useMinMaxPlanningDateInSubtrees] = createUseEntityHook<FlowState, MinMaxPlanningDateInSubtree>(
  FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_START,
  FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_SUCCESS,
  getMinMaxPlanningDateInSubtrees,
  minMaxPlanningDateInSubtreesSelector,
  minMaxPlanningDateInSubtreesStatesSelector,
  reducers,
  stateReducers,
);

export function useMinMaxPlanningDateInSubtree(nodeId: MaybeUuid): {
  hasLoadedOnce: boolean;
  minMaxPlanning: MinMaxPlanningDateInSubtree | null;
} {
  const {hasLoadedOnce, entriesMapped} = useMinMaxPlanningDateInSubtrees(nodeId);
  return {
    hasLoadedOnce,
    minMaxPlanning: (nodeId && entriesMapped.get(nodeId)) || null,
  };
}

export function minMaxPlanningDateInSubtreesRootReducer(state: FlowState, action: Action): FlowState {
  if (isEvent<PlanningEvent>('flow.PlanningEvent', action)) {
    //Must use a root reducer to extend the updated node ids with all of their ancestors:
    const ancestorIds = extendWithAncestorsSelector(state)(action.updatedNodeIds);
    const prevState = minMaxPlanningDateInSubtreesStatesSelector(state);
    const newState = createTimestampReducer('ids', INVALIDATED)(prevState, {ids: ancestorIds});
    return mergeStates(state, {minMaxPlanningDateInSubtrees: {state: newState}});
  }
  return state;
}
