import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {getEndDateDiffBetweenDateLists, getMinMaxFromPlanningDates} from '../Calculations/DateCalculations';
import {PlanningBaselineHistories, PlanningNodeBaselineHistory} from '../EntityInterfaces/PlanningBaselineHistory';
import {PlanningState} from '../PlanningState';
import {MinMaxPlanningDatesResult, getPlanningDatesForNodeSelector} from './PlanningDateSelectors';
import {getSelectedBaselineIdsSelector} from './UiSelectors';

export const planningBaselineHistoryEntityStatesSelector = (state: PlanningState): EntityStates =>
  state.entityStates.planningBaselineHistory;
export const planningBaselineHistoriesSelector = (state: PlanningState): PlanningBaselineHistories =>
  state.entities.planningBaselineHistory;

export const getPlanningNodeBaselineHistorySelector = createSelector(
  planningBaselineHistoriesSelector,
  (baselineHistory) =>
    (baselineId: Uuid, nodeId: Uuid): PlanningNodeBaselineHistory | null => {
      const baseline = baselineHistory[baselineId];
      return baseline?.nodes?.[nodeId] || null;
    },
);

export const calculateMinMaxPlanningDatesForSelectedBaselineNodeSelector = createSelector(
  getPlanningNodeBaselineHistorySelector,
  (getPlanningNodeBaselineHistory) =>
    memoize(
      (baselineId: Uuid, nodeId: Uuid): MinMaxPlanningDatesResult => {
        const nodeData = getPlanningNodeBaselineHistory(baselineId, nodeId);

        return getMinMaxFromPlanningDates(nodeData?.planningDates || []);
      },
      (...args) => args.join('-'),
    ),
);

export const getSelectedPlanningNodeBaselineHistoriesSelector = createSelector(
  getPlanningNodeBaselineHistorySelector,
  getSelectedBaselineIdsSelector,
  (getPlanningNodeBaselineHistory, getSelectedBaselineId) =>
    memoize(
      (rootNodeId: Uuid, nodeId: Uuid): PlanningNodeBaselineHistory[] => {
        const selectedBaselineIds = getSelectedBaselineId(rootNodeId);
        return boolFilter(selectedBaselineIds.map((baselineId) => getPlanningNodeBaselineHistory(baselineId, nodeId)));
      },
      (...args) => args.join('-'),
    ),
);

export const isPlanningBehindSelectedBaselinesSelector = createSelector(
  getSelectedPlanningNodeBaselineHistoriesSelector,
  getPlanningDatesForNodeSelector,
  getOrgWorkMinutesAtDateSelector,
  (getSelectedPlanningNodeBaselineHistories, getPlanningDatesForNode, getOrgWorkMinutesAtDate) =>
    memoize(
      (rootNodeId: Uuid, nodeId: Uuid): boolean => {
        const selectedNodeBaselineHistories = getSelectedPlanningNodeBaselineHistories(rootNodeId, nodeId);
        const baselineDates = selectedNodeBaselineHistories.flatMap((baseline) => baseline.planningDates);
        const planningDate = getPlanningDatesForNode(nodeId);
        return (getEndDateDiffBetweenDateLists(planningDate, baselineDates, getOrgWorkMinutesAtDate) ?? 0) < 0;
      },
      (...args) => args.join('-'),
    ),
);

export const getPlanningBaselinesDiffSelector = createSelector(
  getPlanningNodeBaselineHistorySelector,
  getPlanningDatesForNodeSelector,
  getOrgWorkMinutesAtDateSelector,
  (getPlanningNodeBaselineHistory, getPlanningDatesForNode, getOrgWorkMinutesAtDate) =>
    memoize(
      (baselineId: Uuid, nodeId: Uuid): number | null => {
        const baselineHistory = getPlanningNodeBaselineHistory(baselineId, nodeId);
        if (!baselineHistory) {
          return null;
        }
        const planningDate = getPlanningDatesForNode(nodeId);
        return getEndDateDiffBetweenDateLists(planningDate, baselineHistory.planningDates, getOrgWorkMinutesAtDate);
      },
      (...args) => args.join('-'),
    ),
);
