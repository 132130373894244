import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {isMaterialResource} from '@octaved/flow/src/Node/NodeIdentifiers';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Group} from 'konva/lib/Group';
import {closestWorkDay} from '../../../Calculations/WorkdayCalculations';
import {dateCanBePlanned} from '../../../Pages/Gantt/Components/BarContent/PlaceholderBarWrapper';
import {
  getMinMaxPlanningDatesSelector,
  getPlanningDatesForNodeSelector,
} from '../../../Selectors/PlanningDateSelectors';
import {canChangeBarSelector, showPlaceholderSelector} from '../../../Selectors/PlanningDependencySelectors';
import {Bar, BarProps} from '../../Bars/Bar';
import {HoverCellChangedEvent} from '../../Calendar/Context/CalendarContext';
import {RootContainer, RootContainerProps} from '../../RootContainer';
import {GanttContext} from '../Context/GanttContext';
import {CommonTreeNodeNodeType, DummyTreeNode, isCommonTreeNodeNodeType} from '../Data/TreeNode';
import {BarFactory} from './BarFactory';
import {GhostDraggerPlugin} from './Plugins/GhostDraggerPlugin';

interface PlaceholderCellProps extends RootContainerProps<GanttContext> {
  menuRoot: Group;
  barFactory: BarFactory;
}

export class PlaceholderCell extends RootContainer<GanttContext> {
  #bar: Bar | null = null;
  #nodeId: Uuid | null = null;
  #menuRoot: Group;
  #barFactory: BarFactory;

  constructor(props: PlaceholderCellProps) {
    super(props);
    this.#menuRoot = props.menuRoot;
    this.#barFactory = props.barFactory;
  }

  init(): void {
    this.disposables.push(this.ctx.eventEmitter.on('hoverCellChanged', this.#onHoverCellChanged), () =>
      this.#bar?.dispose(),
    );
  }

  #onHoverCellChanged = (event: HoverCellChangedEvent | null): void => {
    const lockIdent = this.ctx.editLockIdent;

    if (lockIdent) {
      if (!lockIdent.startsWith(GhostDraggerPlugin.lockIdent)) {
        this.#bar?.root.hide();
      }
      return;
    }
    if (event) {
      const state = this.ctx.store.getState();
      const node = getNodeSelector(state)(event.nodeId);

      const showPlaceholder =
        !this.ctx.isReadonly && showPlaceholderSelector(state)(event.nodeId) && !isMaterialResource(node);

      if (isCommonTreeNodeNodeType(node) && showPlaceholder) {
        const getWorkHoursAtDate = getOrgWorkMinutesAtDateSelector(state);
        const placeholderDay = toIsoDateTimeFormat(closestWorkDay(fromIsoFormat(event.date), getWorkHoursAtDate));
        const canChangeBar = canChangeBarSelector(state)(event.nodeId);
        const {plannedEnd, plannedStart} = getMinMaxPlanningDatesSelector(state)(event.nodeId);
        const planningDates = getPlanningDatesForNodeSelector(state)(event.nodeId);
        if (dateCanBePlanned(placeholderDay, planningDates, !canChangeBar, plannedStart, plannedEnd)) {
          this.#updateBar(node, event, placeholderDay);
          return;
        }
      }
    }
    this.#bar?.root.hide();
  };

  #updateBar(node: CommonTreeNodeNodeType, event: HoverCellChangedEvent, date: DateTimeStr): void {
    if (!this.#bar || this.#nodeId !== event.nodeId) {
      this.#bar?.dispose();
      this.#bar?.root.destroy();

      const treeNode = new DummyTreeNode({
        node,
        ctx: this.ctx,
        showMaterialRessources: false,
      });

      const barProps: BarProps = {
        treeNode,
        ctx: this.ctx,
        height: this.ctx.rowHeight,
        isFirst: false,
        isLast: false,
        menuRoot: this.#menuRoot,
        rowIndex: event.rowIndex,
        rowRoot: this.root,
        yOffset: 0,
      };

      this.#bar = this.#barFactory.createPlaceholder(barProps);
      this.#nodeId = event.nodeId;
    }
    this.#bar.root.show();
    this.#bar.rowIndex = event.rowIndex;

    this.root.y(event.rowIndex * this.ctx.rowHeight);
    this.#bar.setDates(date, date);
  }
}
