import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {useLoadNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {sortNodesByPathSelector} from '@octaved/flow/src/Modules/Selectors/NodeSortSelectors';
import {getSubWorkPackageSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {useSubWorkPackagePatchWithId} from '@octaved/flow/src/Modules/WorkPackages';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWAutosaveInput, cn} from '@octaved/ui';
import {format, formatDecimal, parse, validateLocaleFormat} from '@octaved/users/src/Culture/NumberFormatter';
import {boolFilter} from '@octaved/utilities';
import {AlertTriangle} from 'lucide-react';
import {Fragment, ReactElement, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getPercentageEffort} from '../../SubWorkPackage/Common/SubWorkPackageEffort';

interface SubWorkPackageEffortProps {
  readonly?: boolean;
  swpsIds: readonly Uuid[];
  wpEffort: number | null;
}

export default function SubWorkPackageEffort({readonly, swpsIds, wpEffort}: SubWorkPackageEffortProps): ReactElement {
  const {t} = useTranslation();
  useLoadNodes(swpsIds);
  const getSubWorkPackage = useSelector(getSubWorkPackageSelector);
  const patch = useSubWorkPackagePatchWithId();
  const swps = boolFilter<SubWorkPackage>(swpsIds.map(getSubWorkPackage));
  const sortedNodes = useSelector(sortNodesByPathSelector)(swps, true);
  const summedSwpEffort = swps.reduce((acc, swp) => acc + (swp.maxEffort || 0), 0);
  const isOverflowing = wpEffort && wpEffort !== 0 && summedSwpEffort > wpEffort;
  const emptySwps = swps.filter((swp) => !swp.maxEffort);
  const canDefineRemainingEffort = emptySwps.length === 1 && emptySwps[0];

  const valueTransformer = useCallback(
    (val: string, canTakeRemainingEffort: boolean) => {
      if (wpEffort && canTakeRemainingEffort && val === '=') {
        return format(wpEffort - summedSwpEffort);
      }
      return wpEffort ? getPercentageEffort(val, wpEffort) : val;
    },
    [wpEffort, summedSwpEffort],
  );

  return (
    <>
      <div className={'grid grid-cols-[1fr,1fr] items-center gap-y-1'}>
        <div className={'mb-0.5 border-b border-b-slate-200 pb-1 pl-0 pr-2 text-sm font-semibold text-slate-600'}>
          {t('nodeDetails:field.effort.subWorkPackages.table.headerName')}
        </div>
        <div className={'mb-0.5 border-b border-b-slate-200 px-2 pb-1 text-sm font-semibold text-slate-600'}>
          {t('nodeDetails:field.effort.subWorkPackages.table.headerHours')}
        </div>
        {sortedNodes.map((swp) => (
          <Fragment key={swp.id}>
            <div className={'pl-0 pr-2'}>{swp.name}</div>
            <div className={'px-2'}>
              {readonly && <>{formatDecimal(swp.maxEffort)}</>}
              {!readonly && (
                <TWAutosaveInput
                  rightAligned
                  wrapperClassname={'w-shortNumberInput'}
                  value={swp.maxEffort !== null ? formatDecimal(swp.maxEffort) : ''}
                  onSave={(maxEffort) => {
                    if (!maxEffort || validateLocaleFormat(maxEffort)) {
                      patch(swp.id, {maxEffort: maxEffort !== '' ? parse(maxEffort) : null});
                    }
                  }}
                  valueTransformer={(val) =>
                    valueTransformer(val, canDefineRemainingEffort && canDefineRemainingEffort?.id === swp.id)
                  }
                />
              )}
            </div>
          </Fragment>
        ))}
        <div className={'mt-0.5 border-t border-t-slate-200 pl-0 pr-2 pt-1'}>
          {t('nodeDetails:field.effort.subWorkPackages.table.sumLabel')}
        </div>
        <div className={cn('mt-0.5 border-t border-t-slate-200 px-2 pt-1', isOverflowing && 'text-red-600')}>
          <div className={'w-fit min-w-[80px] text-right'}>
            {formatDecimal(summedSwpEffort)}&nbsp;
            {t('nodeDetails:field.effort.subWorkPackages.table.sum', {sum: formatDecimal(wpEffort)})}
          </div>
        </div>
      </div>
      {isOverflowing && (
        <div className={'mt-3 flex items-start gap-x-2 text-sm text-red-600'}>
          <AlertTriangle className={'size-4 flex-shrink-0'} />
          <div>{t('nodeDetails:field.effort.subWorkPackages.superfluesWorkPackageWarning')}</div>
        </div>
      )}
    </>
  );
}
