import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {ReactElement, useMemo} from 'react';
import {ProjectTreeProjectsGroup} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import AvatarStack from '../../../Avatar/AvatarStack';
import GroupingName from './GroupingName';

export default function ProjectManager({node}: {node: ProjectTreeProjectsGroup}): ReactElement {
  const effectiveAssignments = node.children[0].effectiveProjectRoleAssignments;
  const units = useMemo<SlimUnit[]>(() => {
    return effectiveAssignments.filter(({role}) => role.isProjectManagerRole);
  }, [effectiveAssignments]);

  return (
    <div className={'pmRow'}>
      <AvatarStack avatars={units} countBehind />
      <GroupingName leftMargin>{node.groupDisplayName}</GroupingName>
      {/*language=SCSS*/}
      <style jsx>{`
        .pmRow {
          grid-column: 2 / -1;
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
}
