import {FlowState} from '@octaved/flow/src/Modules/State';
import {Icon} from '@octaved/ui';
import classNames from 'classnames';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {getHasWarningSelector} from '../../../../../Selectors/PlanningWarningSelectors';
import ActionBar from './ActionBar';
import {PlanningBarProps} from './PlanningBarProps';

export default function MaterialResourceBar({
  id,
  text,
  showText,
  children,
  hasLessPadding,
  startCanBeResized,
  endCanBeResized,
  canBeDragged,
  canDndDependency,
  onClick,
  planningDate,
  isCreating,
  readonly,
}: PlanningBarProps): ReactElement {
  const warning = useSelector((s: FlowState) => getHasWarningSelector(s)(id, planningDate.id));
  return (
    <ActionBar
      id={id}
      text={text}
      showText={showText}
      startCanBeResized={startCanBeResized}
      endCanBeResized={endCanBeResized}
      canBeDragged={canBeDragged}
      hasLessPadding={hasLessPadding}
      canDndDependency={canDndDependency}
      planningDate={planningDate}
      isCreating={isCreating}
      readonly={readonly}
    >
      <div className={classNames('visibleBar', {warning})} onClick={onClick}>
        {children}
        <div className={classNames('icons')}>
          {warning && (
            <div className={'burningIcon'}>
              <Icon iconColor={'white'} size={'big'} noMargin>
                <Flame />
              </Icon>
            </div>
          )}
        </div>
      </div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .visibleBar {
          background: gray;
          border-radius: 4px;
          height: 100%;
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          font-size: 12px;
        }
        .icons {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .burningIcon {
          & :global(svg) {
            fill: #f00;
          }
        }
      `}</style>
      {/*#endregion*/}
    </ActionBar>
  );
}
