import {mergeStates} from '@octaved/store/src/MergeStates';
import {Uuid} from '@octaved/typescript/src/lib';
import {pick} from 'lodash';
import {Task} from '../../../../EntityInterfaces/Task';

const props = [
  'description',
  'id',
  'isArchived',
  'labels',
  'name',
  'plannedTime',
  'planningLogicalPredecessors',
  'planningLogicalSuccessors',
  'planningPredecessors',
  'status',
] as const;

type Picked = (typeof props)[number];

export type ShallowTask = Pick<Task, Picked>;

const taskCache = new WeakMap<Task, ShallowTask>();
const taskIdCache = new Map<Uuid, ShallowTask>();

export function makeTaskShallow(task: Task): ShallowTask {
  if (taskCache.has(task)) {
    return taskCache.get(task)!;
  }
  const prev = mergeStates(taskIdCache.get(task.id) || {}, pick(task, props)) as ShallowTask;
  taskIdCache.set(task.id, prev);
  taskCache.set(task, prev);
  return prev;
}
