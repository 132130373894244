import {CSSProperties, ReactElement, ReactNode} from 'react';

interface Props {
  children: ReactNode;
  flexGrow?: CSSProperties['flexGrow'];
  flexShrink?: CSSProperties['flexShrink'];
  height?: CSSProperties['height'];
  overflow?: CSSProperties['overflow'];
  width?: CSSProperties['width'];
}

function FlexItem({
  children,
  flexGrow = 0,
  flexShrink = 1,
  height = 'auto',
  overflow = 'visible',
  width = 'auto',
}: Props): ReactElement {
  return (
    <div className={'flexItem'}>
      {children}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .flexItem {
          flex-grow: ${flexGrow};
          flex-shrink: ${flexShrink};
          height: ${height};
          overflow: ${overflow};
          width: ${width};
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}

export {FlexItem};
