import {InboxAction} from '@octaved/env/src/dbalEnumTypes';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {loadUsersDeprecated, publicUserFields} from '@octaved/users/src/Modules/OrgUser';
import {orgUserNameSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useStore} from 'react-redux';
import {InboxNotificationEvent} from './Events';

const titles: Record<InboxAction, string> = {
  [InboxAction.boardApprovalForMe]: 'inbox:systemNotification.boardApprovalForMe',
  [InboxAction.boardApprovalMineAnswered]: 'inbox:systemNotification.boardApprovalMineAnswered',
  [InboxAction.boardFyiForMe]: 'inbox:systemNotification.boardFYIForMe',
  [InboxAction.boardPostMentioned]: 'inbox:systemNotification.boardPostMention',
  [InboxAction.boardQuestionForMe]: 'inbox:systemNotification.boardQuestionForMe',
  [InboxAction.boardQuestionMineAnswered]: 'inbox:systemNotification.boardQuestionMineAnswered',
  [InboxAction.groupTimeRecord]: 'inbox:systemNotification.groupTimeRecord',
  [InboxAction.taskAssigned]: 'inbox:systemNotification.taskAssigned',
  [InboxAction.taskLogicalDependencyCompleted]: 'inbox:systemNotification.taskLogicalDependencyCompleted',
  [InboxAction.taskPlanningDependencyCompleted]: 'inbox:systemNotification.taskPlanningDependencyCompleted',
  [InboxAction.timeRecordForOtherCreated]: 'inbox:systemNotification.timeRecordForOtherCreated',
  [InboxAction.timeRecordForOtherPatched]: 'inbox:systemNotification.timeRecordForOtherPatched',
  [InboxAction.timeRecordForOtherRemoved]: 'inbox:systemNotification.timeRecordForOtherRemoved',
  [InboxAction.workPackageAddedAsResponsible]: 'inbox:systemNotification.workPackageAddedAsResponsible',
  [InboxAction.workPackageAssignedAsResponsible]: 'inbox:systemNotification.workPackageAssignedAsResponsible',
  [InboxAction.workPackageCompletedPm]: 'inbox:systemNotification.workPackageCompletedPm',
  [InboxAction.workPackageLogicalDependencyCompleted]: 'inbox:systemNotification.workPackageLogicalDependencyCompleted',
  [InboxAction.workPackagePlanningChanged]: 'inbox:systemNotification.workPackagePlanningChanged',
  [InboxAction.workPackagePlanningDependencyCompleted]:
    'inbox:systemNotification.workPackagePlanningDependencyCompleted',
};

export default function InboxSystemNotifications(): ReactElement {
  const dispatch = useDispatch();
  const {getState} = useStore<FlowState>();
  const {t} = useTranslation();

  useEffect(() => {
    return subscribe('flow.InboxSystemNotificationEvent', async ({notification}: InboxNotificationEvent) => {
      await dispatch(loadUsersDeprecated([notification.createdBy], publicUserFields));
      const state = getState();
      const userName = orgUserNameSelector(state)(notification.createdBy);
      const title = t(titles[notification.action], {userName});
      new Notification(title, {
        icon: '/apple-touch-icon.png',
      });
    });
  }, [dispatch, getState, t]);

  return <></>;
}
