import {useCallback, useEffect, useState} from 'react';
import {FullUnit} from '../EntityInterfaces/UnitLists';
import {ExtendedUnitType, SimpleFromExtendedUnitType} from '../UnitType';
import {UnitListsOptions, useUnitLists} from './UnitLists';

//For `unitTypes` use a constant like userUnitTypes

export function useUnitSearch<UT extends ExtendedUnitType = ExtendedUnitType>({
  unitTypes,
  ...unitListsOptions
}: {
  unitTypes: ReadonlyArray<UT>;
} & UnitListsOptions<UT>): {
  searchValue: string;
  setSearchValue: (s: string) => void;
  results: ReadonlyArray<FullUnit<SimpleFromExtendedUnitType<UT>>>;
  isLoading: boolean;
} {
  const {isLoaded: areListsLoaded, entries, doLoad} = useUnitLists(unitTypes, {noAutoLoad: true, ...unitListsOptions});
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState<FullUnit<SimpleFromExtendedUnitType<UT>>[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (areListsLoaded) {
      setLoading(false);
      if (searchValue) {
        const lowerValue = searchValue.toLocaleLowerCase();
        setResults(entries.filter(({unitName}) => unitName.toLocaleLowerCase().includes(lowerValue)));
      } else {
        setResults(entries);
      }
    }
  }, [entries, areListsLoaded, searchValue]);

  return {
    isLoading,
    results,
    searchValue,
    setSearchValue: useCallback(
      (value: string) => {
        setSearchValue(value);
        if (!areListsLoaded) {
          setLoading(true);
          doLoad();
        }
      },
      [areListsLoaded, doLoad],
    ),
  };
}
