import {getState} from '@octaved/store/src/Store';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {QuickBillingPreparation} from '../../../../EntityInterfaces/Filter/Custom/BillingPreparation';
import {ProjectFilterQueries} from '../../../../EntityInterfaces/Filter/ProjectFilters';
import {getProjectManagerRolesNodeSearchQuerySelector} from '../../../Selectors/RoleSelectors';
import {FlowState} from '../../../State';

export function quickBillingPreparationQueryGenerator(
  queries: ProjectFilterQueries,
  {userHasProjectManagerRole}: QuickBillingPreparation,
): void {
  if (userHasProjectManagerRole) {
    const state = getState<FlowState>();
    const userId = currentOrgUserIdSelector(state);
    const query = getProjectManagerRolesNodeSearchQuerySelector(state)(userId);
    queries.projects.push(query);
  }
}
