import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {nodeIdInTree, withAncestors, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {splitSearchTerm} from '@octaved/utilities/src/Search/SearchTerm';
import {isPidNumber, isUuid} from '@octaved/validation';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';

type NSC = NodeSearchCondition;

export function createNodeTextSearchQuery(
  search: string,
  searchTarget: NSC,
  {
    searchForCustomer = false,
    searchInAncestors = false,
    searchInDescendants = false,
    searchedNodeTypes,
    validDescendants = null,
  }: {
    searchForCustomer?: boolean;
    searchInAncestors?: boolean;
    searchInDescendants?: boolean;
    searchedNodeTypes?: EnumFlowNodeType[]; //only needed with searchInDescendants
    validDescendants?: NSC | null; //only used with searchInDescendants
  } = {},
): NSC {
  if (search) {
    const searchedNodeTypesQuery: NSC[] = searchedNodeTypes
      ? [{or: searchedNodeTypes.map((type) => ['nodeType', type])}]
      : [];

    const searchPhraseConditions = splitSearchTerm(search).map<NSC>((phrase) => {
      const isUuidPhrase = isUuid(phrase);
      const pidSearch: NSC[] = isPidNumber(phrase) ? [['pidPid', phrase]] : [];
      const nodeNameSearch: NSC = {
        and: [
          ...searchedNodeTypesQuery,
          {
            or: isUuidPhrase ? [nodeIdInTree(phrase)] : [['name', phrase], ...pidSearch],
          },
        ],
      };

      //Search on all node types and the customer name if path is enabled:
      const searchConds: NSC[] = [nodeNameSearch];
      if (searchForCustomer && !isUuidPhrase) {
        searchConds.push(['customerName', phrase]);
        searchConds.push(['customerNumber', `%${phrase}%`]);
      }
      const searchCond: NSC = {or: searchConds};

      const phraseQueries: NSC[] = [searchCond];

      if (searchInAncestors) {
        //If we match, all descendants should match, too:
        phraseQueries.push(withDescendants(searchCond, false));
      }

      if (searchInDescendants) {
        //If we match and are a valid descendant, all ancestors should match, too:
        phraseQueries.push(withAncestors({and: [searchCond, validDescendants || searchTarget]}, false));
      }

      return {or: phraseQueries};
    });

    //The search will now match if all phrases match accross the path of a node.
    // So you can find a task of a project by search for `<projectName> <taskName>`, etc.
    return {and: [searchTarget, ...searchPhraseConditions]};
  }

  return searchTarget;
}

export function applyNodeTextSearch(
  search: string,
  searchedNodeTypes: EnumFlowNodeType[],
  searchForCustomer: boolean,
  searchTarget: NSC,
  validDescendants: NSC | null = null,
): NSC {
  return createNodeTextSearchQuery(search, searchTarget, {
    searchedNodeTypes,
    searchForCustomer,
    validDescendants,
    searchInAncestors: true,
    searchInDescendants: true,
  });
}
