import * as React from 'react';
import {TWInput, TWInputProps} from './TWInput';
import {TWLabel} from './TWLabel';

const TWLabeledInput = React.forwardRef<HTMLInputElement, TWInputProps>(({label, ...props}, ref) => {
  return (
    <div className={'flex w-full flex-col gap-y-1.5'}>
      <TWLabel htmlFor={props.id || props.name}>{label}</TWLabel>
      <TWInput ref={ref} id={props.id || props.name} {...props} />
    </div>
  );
});

TWLabeledInput.displayName = 'TWLabeledInput';

export {TWLabeledInput};
