import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {PlanningRealizationNode} from '../../../../EntityInterfaces/PlanningRealizationNode';
import {PatchPlanningDependency} from '../../../../Modules/Planning';
import {updateDependency} from '../../../../Modules/PlanningDependencies';
import {TWAutosaveInput} from '@octaved/ui';

interface OffsetProps {
  node: PlanningRealizationNode;
  readonly?: false | undefined;
  dependency: PatchPlanningDependency;
  durationError: boolean;
  setDurationError: (error: boolean) => void;
}

interface OffsetPropsReadonly {
  node: PlanningRealizationNode;
  readonly: true;
  dependency: PatchPlanningDependency;
}

export default function Offset(props: OffsetProps | OffsetPropsReadonly): ReactElement {
  const {readonly, dependency, node} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  function save(offset: number): void {
    dispatch(
      updateDependency(node.id, dependency.predecessor, {
        offset,
      }),
    );
  }

  return (
    <div className={'flex flex-row'}>
      <div className={'flex items-center gap-x-2'}>
        <div>
          <TWAutosaveInput
            className={'w-20'}
            value={dependency.offset.toString()}
            hasError={!props.readonly && props.durationError}
            onSave={(v) => {
              if (props.readonly) {
                return;
              }
              const parsedInt = Number(v.trim());
              if (
                v.trim() !== '' &&
                Number.isFinite(parsedInt) &&
                parsedInt % 1 === 0 &&
                parsedInt >= -365 &&
                parsedInt <= 365
              ) {
                save(parsedInt);
                props.setDurationError(false);
              } else {
                props.setDurationError(true);
              }
            }}
            disabled={readonly}
            readOnly={readonly}
          />
        </div>
        <span>
          {t('general:days', {
            count: dependency.offset,
          })}
        </span>
      </div>
    </div>
  );
}
