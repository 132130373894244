import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {emptyNodeSearchResult} from '@octaved/node-search/src/Factories/Tree';
import difference from 'lodash/difference';
import {NodeSearchCondition, NodeSearchTuple, NodeSearchWithStringValue} from '../../EntityInterfaces/NodeSearch';

export function concatQuerySearchStrings<K extends keyof NodeSearchWithStringValue>(
  searchIdent: K,
  values: string[],
): NodeSearchCondition {
  const conditions = values.reduce<NodeSearchTuple[]>((acc, value) => {
    // @ts-ignore this is a matching tuple
    acc.push([searchIdent, value]);
    return acc;
  }, []);
  return {or: conditions};
}

export function createStringsSearchQueryGenerator<K extends keyof NodeSearchWithStringValue>(
  searchIdent: K,
  allPossibleValuesDisables?: NodeSearchWithStringValue[K][], //if the values equal this array, the filter is disabled
): (values: string[]) => NodeSearchCondition | null {
  return (values) => {
    if (values.length && (!allPossibleValuesDisables || difference(allPossibleValuesDisables, values).length)) {
      return concatQuerySearchStrings(searchIdent, values);
    }

    //Do not find anything if no values are selected:
    if (!values.length && allPossibleValuesDisables) {
      return emptyNodeSearchResult;
    }

    return null;
  };
}

export function createNodeTypeBoundStringsSearchQueryGenerator<K extends keyof NodeSearchWithStringValue>(
  searchIdent: keyof NodeSearchWithStringValue,
  nodeType: EnumFlowNodeType,
  allPossibleValuesDisables?: NodeSearchWithStringValue[K][],
): (value: string[]) => NodeSearchCondition | null {
  const gen = createStringsSearchQueryGenerator(searchIdent, allPossibleValuesDisables);
  return (values) => {
    const cond = gen(values);
    if (cond) {
      return {and: [['nodeType', nodeType], cond]};
    }
    return null;
  };
}
