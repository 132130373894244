import {ChevronLeft, ChevronRight} from 'lucide-react';
import {ReactElement, ReactNode} from 'react';
import {IconButton} from '../../Form';
import VerticalCollapsible from './VerticalCollapsible';

interface Props {
  children: ReactNode;
  collapsed: boolean;
  defaultWidth: string;
  isFixedHeight?: boolean;
  reversed?: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

function VerticalCollapsibleContent({
  children,
  collapsed,
  defaultWidth,
  isFixedHeight,
  setCollapsed,
  reversed,
}: Props): ReactElement {
  const isCollapsed = (!reversed && collapsed) || (reversed && !collapsed);
  return (
    <VerticalCollapsible
      isFixedHeight={isFixedHeight}
      defaultWidth={defaultWidth}
      collapsedWidth={'28px'}
      collapsibleIcon={
        <IconButton icon={isCollapsed ? <ChevronRight /> : <ChevronLeft />} variant={'ghost'} size={'sm'} />
      }
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      reversed={reversed}
    >
      {children}
    </VerticalCollapsible>
  );
}

export {VerticalCollapsibleContent};
