import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {dispatch, getState} from '@octaved/store/src/Store';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {UnknownAction} from 'redux';
import {FlowState} from '../../Modules/State';

export interface TimeTrackingSuperUserMode {
  activeUntil: number;
  isActive: boolean;
}

const HEADER = 'octaved-flow-time-tracking-super-user-mode';
const FLOW_SET_MANAGE_NON_BOOKABLE_TIME_RECORDINGS = 'FLOW_SET_MANAGE_NON_BOOKABLE_TIME_RECORDINGS';

interface SetAction extends UnknownAction {
  activeUntil: number;
  type: typeof FLOW_SET_MANAGE_NON_BOOKABLE_TIME_RECORDINGS;
}

export const timeTrackingSuperUserModeActiveUntilSelector = (state: FlowState): number =>
  state.currentOrgUser.timeTrackingSuperUserMode.activeUntil;
export const timeTrackingSuperUserModeIsActiveSelector = (state: FlowState): boolean =>
  state.currentOrgUser.timeTrackingSuperUserMode.isActive;

let timeout = 0;

function judgeIsActive(activeUntil: number): boolean {
  return activeUntil > unix();
}

function startTimer(): void {
  window.clearTimeout(timeout);
  timeout = window.setTimeout(() => {
    const state = getState<FlowState>();
    const activeUntil = timeTrackingSuperUserModeActiveUntilSelector(state);
    const isActive = timeTrackingSuperUserModeIsActiveSelector(state);
    const shouldBeActive = judgeIsActive(activeUntil);
    if (isActive !== shouldBeActive) {
      const action: SetAction = {activeUntil, type: FLOW_SET_MANAGE_NON_BOOKABLE_TIME_RECORDINGS};
      dispatch(action);
    } else if (isActive) {
      startTimer();
    }
  }, 2000); //use 2 seconds to not be too cpu greedy
}

const reducers = createReducerCollection<TimeTrackingSuperUserMode>({
  activeUntil: 0,
  isActive: false,
});
reducers.add<SetAction>(FLOW_SET_MANAGE_NON_BOOKABLE_TIME_RECORDINGS, (state, {activeUntil}) => {
  const isActive = judgeIsActive(activeUntil);
  if (isActive) {
    startTimer();
  }
  return {...state, activeUntil, isActive};
});
export const timeTrackingSuperUserModeReducer = reducers.reducer;

export function setTimeTrackingSuperUserModeActiveUntil(ttlMinutes: number): void {
  const activeUntil = ttlMinutes ? unix() + ttlMinutes * 60 : 0;
  dispatch({activeUntil, type: FLOW_SET_MANAGE_NON_BOOKABLE_TIME_RECORDINGS});
}

export function extendTimeTrackingSuperUserModeHeaders(headers: Record<string, string>): Record<string, string> {
  if (judgeIsActive(timeTrackingSuperUserModeActiveUntilSelector(getState()))) {
    return {...headers, [HEADER]: '1'};
  }
  return headers;
}

if (process.env.NODE_ENV !== 'test') {
  //start timer once 10 seconds after page load - this is to make sure everything is settled and loaded from
  // local storage.
  setTimeout(startTimer, 10);
}
