import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {hasPriceCategory} from '@octaved/flow/src/Modules/Pid';
import {isCustomerBillableSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {useWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

type RequiredFields = keyof Pick<
  WorkPackage,
  'priceCategory' | 'maxEffort' | 'fixedPrice' | 'effortFrom' | 'effortTo' | 'usePriceCategoryPerTimeTracking'
>;
export type RequiredEffortFields = 'maxEffort' | 'effortFrom' | 'effortTo';

const billingTypeRequiredFields: Record<EnumFlowPidBillingType, RequiredFields[]> = {
  [EnumFlowPidBillingType.VALUE_EFFORT]: [],
  [EnumFlowPidBillingType.VALUE_EFFORT_EST]: ['maxEffort'],
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]: ['effortFrom', 'effortTo'],
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP]: ['maxEffort'],
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: ['fixedPrice'],
  [EnumFlowPidBillingType.VALUE_CONTINGENT]: ['maxEffort'],
  [EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE]: [],
};
export const requiredEffortField = new Set<RequiredFields>(['maxEffort', 'effortFrom', 'effortTo']);

function getRequiredFields(node: WorkPackage, isBillable: boolean): RequiredFields[] {
  const requirePriceCategory =
    isBillable && hasPriceCategory(node) && !node.usePriceCategoryPerTimeTracking && !node.priceCategory;
  return [
    ...(node.billingType ? billingTypeRequiredFields[node.billingType] : []),
    ...(requirePriceCategory ? ['priceCategory'] : []),
  ] as RequiredFields[];
}

function canSave(data: Partial<WorkPackage>, node: WorkPackage, isBillable: boolean): boolean {
  if (data.billingType) {
    const requiredFields = getRequiredFields(node, isBillable);
    return !requiredFields.find((field) => !data[field]);
  }
  return false;
}

export function useTimeAndMoneyValidationPatch(liveNode: WorkPackage): {
  node: WorkPackage;
  isUnsaved?: boolean;
  patch: (data: Partial<WorkPackage>) => void;
  requiredFields: RequiredFields[];
} {
  const isBillable = useSelector(isCustomerBillableSelector)(liveNode.flowCustomer);
  const save = useWorkPackagePatch(liveNode.id);
  const [unsavedState, setUnsavedState] = useState<Partial<WorkPackage>>({});
  const isUnsaved = useMemo(() => Object.keys(unsavedState).length > 0, [unsavedState]);
  const node = useMemo(() => ({...liveNode, ...unsavedState}), [liveNode, unsavedState]);

  const patch = useCallback(
    (newData: Partial<WorkPackage>) => {
      if (!newData.billingType && !isUnsaved) {
        save(newData);
      } else {
        const nextData = newData.billingType ? newData : {...unsavedState, ...newData};
        if (canSave(nextData, {...node, ...nextData}, isBillable)) {
          save(nextData);
          setUnsavedState({});
        } else {
          setUnsavedState(nextData);
        }
      }
    },
    [node, unsavedState, save, isUnsaved, isBillable],
  );

  return {
    isUnsaved,
    node,
    patch,
    requiredFields: getRequiredFields(node, isBillable),
  };
}
