// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CombinersShape<T> = Record<string, (sources: any, options: any) => ReadonlyArray<T>>;

export interface OrCondition<C, T, Combiners extends CombinersShape<T> = never> {
  readonly or: ReadonlyArray<AnyCondition<C, T, Combiners>>;
}

export interface AndCondition<C, T, Combiners extends CombinersShape<T> = never> {
  readonly and: ReadonlyArray<AnyCondition<C, T, Combiners>>;
}

export interface NotCondition<C, T, Combiners extends CombinersShape<T> = never> {
  readonly not: AnyCondition<C, T, Combiners>;
}

export interface Transform<T> {
  (result: ReadonlyArray<T>): ReadonlyArray<T>;
}

interface Transformer<C, T, Combiners extends CombinersShape<T> = never> {
  readonly transform: readonly [AnyCondition<C, T, Combiners>, Transform<T>];
}

type ResultRecordToConditionsRecord<Rec, C, T = string, Combiners extends CombinersShape<T> = never> = {
  [source in keyof Rec]: AnyCondition<C, T, Combiners>;
};

type RecToUnion<O> = O[keyof O];

interface CustomCombination<C, T, Combiners extends CombinersShape<T> = never> {
  readonly combine: RecToUnion<{
    [ident in keyof Combiners]: {
      ident: ident;
      options: Parameters<Combiners[ident]>[1];
      sources: ResultRecordToConditionsRecord<Parameters<Combiners[ident]>[0], C, T, Combiners>;
    };
  }>;
}

interface FixResult<T> {
  readonly fixResult: ReadonlyArray<T>;
}

export type AnyCondition<C, T = string, Combiners extends CombinersShape<T> = never> =
  | C
  | OrCondition<C, T, Combiners>
  | AndCondition<C, T, Combiners>
  | NotCondition<C, T, Combiners>
  | Transformer<C, T, Combiners>
  | CustomCombination<C, T, Combiners>
  | FixResult<T>;

export function isAndCondition<C, T, Combiners extends CombinersShape<T>>(
  cond: AnyCondition<C, T, Combiners>,
): cond is AndCondition<C, T, Combiners> {
  return cond && typeof cond === 'object' && Array.isArray((cond as AndCondition<C, T, Combiners>).and);
}

export function isOrCondition<C, T, Combiners extends CombinersShape<T>>(
  cond: AnyCondition<C, T, Combiners>,
): cond is OrCondition<C, T, Combiners> {
  return cond && typeof cond === 'object' && Array.isArray((cond as OrCondition<C, T, Combiners>).or);
}

export function isNotCondition<C, T, Combiners extends CombinersShape<T>>(
  cond: AnyCondition<C, T, Combiners>,
): cond is NotCondition<C, T, Combiners> {
  return cond && typeof cond === 'object' && (cond as NotCondition<C, T, Combiners>).hasOwnProperty('not');
}

export function isCustomCombiner<C, T, Combiners extends CombinersShape<T>>(
  cond: AnyCondition<C, T, Combiners>,
): cond is CustomCombination<C, T, Combiners> {
  return cond && typeof cond === 'object' && (cond as CustomCombination<C, T, Combiners>).hasOwnProperty('combine');
}

export function isTransformer<C, T, Combiners extends CombinersShape<T>>(
  cond: AnyCondition<C, T, Combiners>,
): cond is Transformer<C, T, Combiners> {
  return cond && typeof cond === 'object' && (cond as Transformer<C, T, Combiners>).hasOwnProperty('transform');
}

export function isFixResult<C, T, Combiners extends CombinersShape<T>>(
  cond: AnyCondition<C, T, Combiners>,
): cond is FixResult<T> {
  return cond && typeof cond === 'object' && (cond as FixResult<T>).hasOwnProperty('fixResult');
}

export function wrapWithNot<C, T, Combiners extends CombinersShape<T>>(
  cond: C,
  wrap = true,
): C | NotCondition<C, T, Combiners> {
  return wrap ? {not: cond} : cond;
}
