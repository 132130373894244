import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import classNames from 'classnames';
import {CheckCircle} from 'lucide-react';
import {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import {useNode} from '../../Modules/Hooks/Nodes';
import NodeTile from '../../Pages/Projects/Drawer/NodeTile';
import {flowStyleContext} from '../../Styles/StyleContext';
import LogoSvg from '../../svgs/logo.svg?tsx';
import {useCloseFullScreenDrawer} from './FullScreenDrawer';

interface Props {
  nodeId: Uuid;
  title: string;
}

export default function NodeDrawerHeader({nodeId, title}: Props): ReactElement | null {
  const {
    COLORS: {BORDER},
  } = useContext(flowStyleContext);
  const node = useNode(nodeId);
  const close = useCloseFullScreenDrawer();

  if (!node) {
    return null;
  }

  return (
    <div className={classNames('header', node.nodeType)}>
      <div className={'flex shrink-0 items-center text-lg'}>
        <div className={'logo'}>
          <LogoSvg />
        </div>
        <div className={'ml-2.5'}>
          <Trans i18nKey={title} />
        </div>
      </div>

      <div>
        <NodeTile node={node} />
      </div>

      <div className={'flex shrink-0 items-center'}>
        <IconButton onClick={close} icon={<CheckCircle />} label={'general:close'} />
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .header {
          min-height: 50px;
          border-bottom: 1px solid ${BORDER.WEAK};
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 20px;
          flex-shrink: 0;
        }

        .header.project {
          min-height: auto;
          align-items: center;
        }

        .logo {
          width: 34px;
          overflow: hidden;
        }

        .logo > :global(svg) {
          height: 24px;
          margin-left: -10px;
        }
      `}</style>
    </div>
  );
}
