import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {isEqual} from 'lodash';
import {getPlanningDatesForNodeSelector} from '../../../Selectors/PlanningDateSelectors';
import {Bar, BarProps} from '../../Bars/Bar';
import {PlanningBarProps} from '../../Bars/PlanningDateBar';
import {createStoreSubscription} from '../../StoreSubscription';
import {BarRow} from './BarRow';
import {DependencyLines} from './Dependencies/DependencyLines';

export class PlanningDatesBarRow extends BarRow {
  #planningDateIds: Uuid[] = [];

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getPlanningDatesForNodeSelector(s)(this.node.id).map(({id}) => id),
        (planningDates) => {
          this.#planningDateIds = planningDates;
          this.#renderBars();
        },
        isEqual,
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => getRootGroupTypeSelector(s)(this.node.id),
        () => this.#renderBars(),
      ),
    );

    const dependencyNodeTypes: string[] = [
      EnumFlowNodeType.VALUE_WORK_PACKAGE,
      EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
      EnumFlowNodeType.VALUE_TASK,
    ];

    if (dependencyNodeTypes.includes(this.node.type)) {
      this.#renderDependencies();
    }
  }

  #renderDependencies(): void {
    const dependencyLines = new DependencyLines({
      barHeight: this.height,
      ctx: this.ctx,
      node: this.node,
      planningDataLoader: this.planningDataLoader,
      rowIndex: this.rowIndex,
      yBarOffset: this.yOffset,
    });
    this.dependencyRoot.add(dependencyLines.root);
    dependencyLines.init();
    this.disposables.push(() => dependencyLines.dispose());
  }

  #renderBars = this.debouncedAction((): void => {
    this.root.destroyChildren();
    this.disposeRows();
    for (let i = 0; i < this.#planningDateIds.length; i++) {
      const planningDateId = this.#planningDateIds[i];
      const prevPlanningDateId = this.#planningDateIds[i - 1] || null;
      const nextPlanningDateId = this.#planningDateIds[i + 1] || null;
      const barProps: PlanningBarProps = {
        planningDateId,
        ctx: this.ctx,
        height: this.height,
        isFirst: i === 0,
        isLast: i === this.#planningDateIds.length - 1,
        menuRoot: this.menuRoot,
        rowIndex: this.rowIndex,
        rowRoot: this.root,
        treeNode: this.node,
        yOffset: this.yOffset,
      };
      let bar: Bar | null = null;
      if (this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
        bar = this.barFactory.createWorkPackageBar(barProps, prevPlanningDateId, nextPlanningDateId);
      } else if (this.node.type === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
        bar = this.barFactory.createSubWorkPackageBar(barProps, prevPlanningDateId, nextPlanningDateId);
      } else if (this.node.type === EnumFlowNodeType.VALUE_TASK) {
        bar = this.barFactory.createTaskBar(barProps, prevPlanningDateId, nextPlanningDateId);
      } else if (this.node.type === EnumFlowNodeType.VALUE_GROUP) {
        bar = this.barFactory.createSprintBar(barProps, prevPlanningDateId, nextPlanningDateId);
      }
      this.disposableRows.push(() => bar?.dispose());
    }
    if (this.#planningDateIds.length === 0) {
      const barProps: BarProps = {
        ctx: this.ctx,
        height: this.height,
        isFirst: false,
        isLast: false,
        menuRoot: this.menuRoot,
        rowIndex: this.rowIndex,
        rowRoot: this.root,
        treeNode: this.node,
        yOffset: this.yOffset,
      };
      let bar: null | Bar = null;
      if (this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
        bar = this.barFactory.createGroupedWorkPackageBar(barProps);
      } else if (this.node.type === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
        bar = this.barFactory.createGroupedSubWorkPackageBar(barProps);
      }
      this.disposableRows.push(() => bar?.dispose());
    }
  });
}
