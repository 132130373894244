import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {toChildren} from '@octaved/node-search/src/Factories/Tree';
import {cn, IconButton, NodeIcon} from '@octaved/ui';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {ChevronDown, ChevronRight} from 'lucide-react';
import {ReactElement} from 'react';
import Children from './Children';
import {useProjectTreeSelectorContext} from './ProjectTreeSelectorContext';

interface Props {
  node: NodeType;
}

export default function Node({node}: Props): ReactElement {
  const {isExpanded, isSelected, expand, select} = useProjectTreeSelectorContext();
  const {nodeIds: childIds} = useCombinedNodeSearch({
    and: [
      toChildren(node.id),
      {not: ['isArchived']},
      {not: ['nodeType', EnumFlowNodeType.VALUE_BOARD_POST]},
      {not: ['nodeType', EnumFlowNodeType.VALUE_TASK_SECTION]},
    ],
  });
  const hasChildren = childIds.length > 0;
  const expanded = isExpanded(node.id);
  const selected = isSelected(node.id);
  return (
    <div>
      <div
        className={cn(
          'flex h-8 w-full items-center gap-x-2 overflow-hidden py-1 pr-2 hover:bg-sky-50',
          selected && 'bg-sky-100',
          !selected && 'cursor-pointer',
        )}
        onClick={() => select(node)}
      >
        {hasChildren ? (
          <IconButton
            className={'flex-shrink-0'}
            icon={expanded ? <ChevronDown /> : <ChevronRight />}
            iconColor={'darkGrey'}
            onClick={(e) => {
              e.stopPropagation();
              expand(node.id);
            }}
            size={'xs'}
            variant={'ghost'}
          />
        ) : (
          <div className={'size-6 flex-shrink-0'} />
        )}
        <NodeIcon nodeType={node.nodeType} className={'flex-shrink-0'} />
        <div className={'truncate'}>{node.name}</div>
      </div>
      {hasChildren && (
        <SlideToggleElement visible={expanded}>
          <Children childIds={childIds} />
        </SlideToggleElement>
      )}
    </div>
  );
}
