import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {NodeStatusNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import NodeStatusDropdown from '@octaved/flow/src/Node/NodeStatus/NodeStatusDropdown';
import NodeStatusInheritancePopup, {
  useNodeStatusInheritancePopup,
} from '@octaved/flow/src/Node/NodeStatus/NodeStatusInheritancePopup';
import {IconButton} from '@octaved/ui';
import {MoreHorizontal} from 'lucide-react';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

interface Props {
  node: NodeStatusNode;
}

export default function NodeStatus({frame: Frame, node}: Props & FramedSectionOrTileProps): ReactElement {
  const inheritancePopup = useNodeStatusInheritancePopup(node);
  return (
    <>
      <Frame
        labelToken={'nodeDetails:field.nodeStatus.label'}
        labelAddition={
          <div ref={inheritancePopup.contextRef}>
            <DropdownPopup items={[inheritancePopup.menuItem]} position={'bottom left'} closeOnSelect>
              <IconButton size={'xs'} icon={<MoreHorizontal />} variant={'ghost'} />
            </DropdownPopup>
          </div>
        }
      >
        <NodeStatusDropdown node={node} />
      </Frame>
      {inheritancePopup.show && (
        <NodeStatusInheritancePopup
          close={inheritancePopup.close}
          contextRef={inheritancePopup.contextRef}
          nodeId={node.id}
        />
      )}
    </>
  );
}
