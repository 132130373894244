import {isWorkday} from '@octaved/planning/src/Calculations/WorkdayCalculations';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Notification} from '@octaved/store/src/Notifications';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {mapCache} from '@octaved/utilities';
import dayjs from 'dayjs';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {FlowState} from '../State';
import {PayloadTimeRecord} from '../Ui';
import {TimeRecordWithTimeAndCustomerFilter, TimeRecordWithUnitsFilter} from '../UiPages/TimeRecords';
import {TimeFilter, TimeRecordsUiState, TimeRecordTimeFilter} from '../UiPages/TimeRecordState';
import {GetWorkingTimeAtDate} from './WorkTimeSelectors';

type UiState = FlowState['ui'];
export const uiSelector = (state: FlowState): UiState => state.ui;

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: UiState) => R): (rootState: FlowState) => R {
  return (rootState) => selector(uiSelector(rootState));
}

export const drawerPinnedSelector = (state: FlowState): boolean => state.ui.drawerPinned;
export const editingTimeRecordSelector = (state: FlowState): PayloadTimeRecord | null => state.ui.editTimeRecord;
export const errorsSelector = (state: FlowState): Notification[] => state.ui.notifications.error;
export const warningsSelector = (state: FlowState): Notification[] => state.ui.notifications.warning;
export const showInboxSelector = (state: FlowState): boolean => state.ui.showInbox;
export const showQuickNotesSelector = ui((state) => state.showQuickNotes);
export const showProjectFolderSelector = ui((state) => state.showProjectFolder);
export const collapsedInspectorSectionsSelector = ui((state) => state.collapsedInspectorSections);

export const projectsFilteredCustomerSelector = createSelector(isGrantedSelector, uiSelector, (isGranted, ui) => {
  return isGranted('FLOW_GLOBAL_CUSTOMERS_READ') ? ui.pages.projects.filteredCustomer : null;
});

const projectsOpenNodesSelector = (state: FlowState): Uuid[] => state.ui.pages.projects.openNodes;
export const projectsSearchSelector = (state: FlowState): string => state.ui.pages.projects.search;
// replace
export const timeRecordShowBilledRecordsSelector = (state: FlowState): boolean =>
  state.ui.pages.timeRecords.showBilledRecords;
export const timeRecordShowOnlyOpenRecordsSelector = (state: FlowState): boolean =>
  state.ui.pages.timeRecords.showOnlyOpen;

const timeRecordsUiStateSelector = (state: FlowState): TimeRecordsUiState => state.ui.pages.timeRecords;
export const pageMenuExpandedSelector = (state: FlowState): boolean => state.ui.pageMenu.isExpanded;
export const pageMenuExpandedGroupsSelector = (state: FlowState): string[] => state.ui.pageMenu.expandedGroups;

export const workPackageOptionsUiSelector = (state: FlowState): UiState['workpackageView'] => state.ui.workpackageView;

export const trackTimeSelectWorkPackageTabSelector = (state: FlowState): 'mine' | 'last' | 'search' =>
  state.ui.trackTimeSelectWorkPackageTab;

const timeRecordsFilterWithTimeAndCustomersPageSelector = createSelector(
  timeRecordsUiStateSelector,
  (timeRecorsState) => (page: TimeRecordWithTimeAndCustomerFilter) => {
    return timeRecorsState[page];
  },
);

export const timeRecordSearchFilterSelector = createSelector(
  timeRecordsFilterWithTimeAndCustomersPageSelector,
  (pageSelector) => memoize((page: TimeRecordWithTimeAndCustomerFilter) => pageSelector(page).search),
);

export const timeRecordCustomerFilterSelector = createSelector(
  timeRecordsFilterWithTimeAndCustomersPageSelector,
  (pageSelector) => memoize((page: TimeRecordWithTimeAndCustomerFilter) => pageSelector(page).customerFilter),
);

export const timeRecordTimeFilterSelector = createSelector(
  timeRecordsFilterWithTimeAndCustomersPageSelector,
  (pageSelector) => memoize((page: TimeRecordWithTimeAndCustomerFilter) => pageSelector(page).timeFilter),
);

const timeRecordsFilterWithUnitPageSelector = createSelector(
  timeRecordsUiStateSelector,
  (timeRecorsState) => (page: TimeRecordWithUnitsFilter) => {
    return timeRecorsState[page];
  },
);

export const timeRecordUnitFilterSelector = createSelector(timeRecordsFilterWithUnitPageSelector, (pageSelector) =>
  memoize((page: TimeRecordWithUnitsFilter) => pageSelector(page).unitFilter),
);

export const timeRecordIsFilteringSelector = createSelector(
  timeRecordsFilterWithTimeAndCustomersPageSelector,
  timeRecordShowOnlyOpenRecordsSelector,
  (pageSelector, showOnlyBilled) =>
    memoize((page: TimeRecordWithTimeAndCustomerFilter) => {
      const state = pageSelector(page);
      return (
        showOnlyBilled ||
        state?.search?.length > 0 ||
        state.customerFilter !== null ||
        state.timeFilter.type !== TimeFilter.automatic
      );
    }),
);

export const projectsOpenNodesSetSelector = createSelector(
  projectsOpenNodesSelector,
  (ids) => new Set(ids) as ReadonlySet<Uuid>,
);

//Evaluate time span to be shown, always show today + last *work* day, if there are records between today and the last
//work day, also display them
function evaluateAutomaticTimespan(getWorkingTimeAtDate: GetWorkingTimeAtDate): [DateStr | null, DateStr] {
  let day = dayjs();
  const lastDay = toIsoFormat(day);
  let firstDay = null;
  do {
    day = day.subtract(1, 'day');
    firstDay = toIsoFormat(day);
  } while (!isWorkday(day, getWorkingTimeAtDate));

  return [firstDay, lastDay];
}

export const timeFilterDates = mapCache(
  (
    filter: TimeRecordTimeFilter,
    getWorkingTimeAtDate: GetWorkingTimeAtDate,
  ): {from: string | null; to: string | null} => {
    let from;
    let to;
    const today = toIsoFormat(dayjs());
    switch (filter.type) {
      case TimeFilter.automatic:
        [from, to] = evaluateAutomaticTimespan(getWorkingTimeAtDate);
        break;
      case TimeFilter.lastWeek:
        from = toIsoFormat(dayjs().subtract(1, 'week'));
        to = today;
        break;
      case TimeFilter.lastTwoWeeks:
        from = toIsoFormat(dayjs().subtract(2, 'week'));
        to = today;
        break;
      case TimeFilter.custom:
        from = filter.from;
        to = filter.to;
        break;
      default:
        throw new Error('Invalid value');
    }
    return {from, to};
  },
);

export function timeFilterToRange(filter: TimeRecordTimeFilter, getWorkingTimeAtDate: GetWorkingTimeAtDate): string {
  const today = toIsoFormat(dayjs());
  const {from, to} = timeFilterDates(filter, getWorkingTimeAtDate);
  return `${from || today}-${to || today}`;
}

export const timeRecordGroupsFilterSelector = createSelector(timeRecordUnitFilterSelector, (unitFilter) =>
  memoize((page: TimeRecordWithUnitsFilter) => {
    return unitFilter(page)
      .filter(({unitType}) => unitType === SimpleUnitType.group)
      .map(({unitId}) => unitId);
  }),
);
