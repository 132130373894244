import {Notification} from '@octaved/store/src/Notifications';
import {DeepPartial, SubType} from '@octaved/typescript/src/lib';
import {patchUserSettings} from '@octaved/users/src/Modules/OrgUserSettings';
import {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  MyTasksFilterArrayKeys,
  MyTasksFilterSet,
  MyTasksFilterStates,
} from '../../EntityInterfaces/Filter/MyWorkPackagesFilters';
import {useMyTasksFiltersContext} from '../../Pages/MyTasks/Header/Presets/Filter/MyTasksFiltersContext';
import {UseArrayFilter, useArrayFilter, UseArrayFilterKeyGenArg} from '../Filter/ArrayFilter';
import {UseEditFilterState, useEditFilterState, UseEditFilterStateResult} from '../Filter/FilterState';
import {myTasksActivePresetSelector, myTasksMineFilterPreset} from '../Selectors/Pages/MyTasksSelectors';
import {addErrors, UiState} from '../Ui';
import {MyTasksUiState} from '../UiPages/MyTasks';

type MyTasksUiStateFilterSets = SubType<MyTasksUiState, MyTasksFilterSet['filters'] | null>;

type Value<K extends keyof MyTasksFilterStates> = MyTasksFilterStates[K]['value'];
type ArrayMember<T> = T extends (infer U)[] ? U : T;

export function useEditMyTasksFilter<K extends keyof MyTasksFilterStates>(
  ident: K,
  defaultValue: Value<K>,
): UseEditFilterStateResult<Value<K>> {
  const {filterStates, setFilterStates} = useMyTasksFiltersContext();
  const defaultFilter = myTasksMineFilterPreset[ident];
  return useEditFilterState(filterStates, setFilterStates, ident, defaultFilter?.value ?? defaultValue);
}

const emptyArray: unknown[] = [];

export function useMyTasksArrayFilter<K extends MyTasksFilterArrayKeys>(
  ident: K,
  keyGenerator?: UseArrayFilterKeyGenArg<ArrayMember<Value<K>>>,
): UseArrayFilter<ArrayMember<Value<K>>> {
  return useArrayFilter<ArrayMember<Value<K>>, K>(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ((i) => useEditMyTasksFilter(i, emptyArray as ArrayMember<Value<K>>[])) as UseEditFilterState<
      ArrayMember<Value<K>>[],
      K
    >,
    ident,
    keyGenerator,
  );
}

export function useSaveFilterSets(): (filterSets: MyTasksFilterSet[]) => boolean {
  const searchFavorite = useSelector(myTasksActivePresetSelector);
  const searchFavoriteRef = useRef(searchFavorite);
  searchFavoriteRef.current = searchFavorite;
  const dispatch = useDispatch();
  return useCallback(
    (filterSets) => {
      const errors: Notification[] = [];
      filterSets.forEach(({longName, shortName}, index) => {
        if (!longName) {
          errors.push({
            field: `myWorkPackages_favoriteLongName_${index}`,
            message: '',
          });
        }
        if (!shortName) {
          errors.push({
            field: `myWorkPackages_favoriteShortName_${index}`,
            message: '',
          });
        }
      });
      if (errors.length) {
        dispatch(addErrors(errors));
        return false;
      }
      dispatch(patchUserSettings({workspace: {filterSets}}));
      return true;
    },
    [dispatch],
  );
}

/**
 * The filters must always be fully replaced to allow for emptying or copying over
 */
export function replaceMyTasksFilters(
  state: UiState,
  partialState: DeepPartial<UiState>,
  key: keyof MyTasksUiStateFilterSets,
): UiState {
  const filters = partialState.pages?.myTasks?.[key];
  if (filters) {
    return {
      ...state,
      pages: {
        ...state.pages,
        myTasks: {
          ...state.pages.myTasks,
          [key]: filters as MyTasksFilterSet['filters'],
        },
      },
    };
  }
  return state;
}
