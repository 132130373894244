import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {isResponsibleNode, ResponsibleNode} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {
  getChildNodesSelector,
  getDescendantNodesSelector,
  getNodeAncestrySelector,
} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {taskCountSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {getUsersIdsForGroupSelector} from '@octaved/users/src/Selectors/GroupUserSelectors';
import {memoize} from 'lodash';
import {PlanningDatesList} from '../../EntityInterfaces/PlanningDates';
import {Workload, WorkloadPlannedNode, WorkloadPlannedNodes, Workloads} from '../../EntityInterfaces/Workload';
import {getPlanningDatesForNodeSelector} from '../../Selectors/PlanningDateSelectors';
import {workloadSelector} from '../../Selectors/WorkloadSelectors';

export default class UpdateWorkloadContext {
  readonly #state: FlowState;
  readonly #currentWorkload: Workloads;

  constructor(state: FlowState) {
    this.#state = state;
    this.#currentWorkload = {...workloadSelector(state)};
  }

  get currentWorkload(): Workloads {
    return this.#currentWorkload;
  }

  #getResolvedResponsibleUsers = memoize((node: ResponsibleNode): Set<Uuid> => {
    return node.responsibleGroups.reduce((acc, groupId) => {
      const userIds = getUsersIdsForGroupSelector(this.#state)(groupId);
      userIds.forEach((id) => acc.add(id));
      return acc;
    }, new Set(node.responsibleUsers));
  });

  #getNode(nodeId: MaybeUuid): NodeType | undefined {
    return getNodeSelector(this.#state)(nodeId);
  }

  #getEffort(node: NodeType): number {
    if (isTask(node)) {
      return node.plannedTime || 0;
    }
    if (isWorkPackage(node)) {
      if (node.maxEffort !== null) {
        return node.maxEffort;
      }
      return node.effortTo || 0;
    }
    if (isSubWorkPackage(node)) {
      return node.maxEffort || 0;
    }
    return 0;
  }

  #getTaskEffort(node: NodeType): number {
    if (isWorkPackage(node) || isSubWorkPackage(node)) {
      const taskCount = taskCountSelector(this.#state)(node.id);
      return taskCount.plannedTime;
    }
    return 0;
  }

  #getSubWorkPackageEffort(node: NodeType): number {
    if (isWorkPackage(node)) {
      const subWorkPackages = getDescendantNodesSelector(this.#state)(node.id).filter(isSubWorkPackage);
      return subWorkPackages.reduce((acc, subWorkPackage) => acc + (subWorkPackage.maxEffort || 0), 0);
    }
    return 0;
  }

  updateWorkloadForNode(nodeId: Uuid): void {
    const node = this.#getNode(nodeId);
    if (isResponsibleNode(node)) {
      const planningDates = this.#getPlanningDates(nodeId);
      const inheritedTasks = this.#getInheritedTasks(nodeId);
      const nodesToUpdate = [node, ...inheritedTasks];
      if (planningDates.length === 0) {
        for (const nodeToUpdate of nodesToUpdate) {
          this.#removeNodeFromAllWorkload(nodeToUpdate.id);
        }
      } else {
        for (const nodeToUpdate of nodesToUpdate) {
          const affectedUsers = this.#getResolvedResponsibleUsers(nodeToUpdate);
          for (const [userId, workload] of Object.entries(this.currentWorkload)) {
            if (affectedUsers.has(userId)) {
              if (workload?.plannedNodes[nodeToUpdate.id]) {
                this.#updateNodeInWorkload(nodeToUpdate, userId);
              } else {
                this.#addNodeToWorkload(nodeToUpdate, userId);
              }
            } else {
              this.#removeNodeFromUserWorkload(nodeToUpdate.id, userId);
            }
          }
        }
      }
    }
  }

  #getInheritedTasks(nodeId: Uuid): ResponsibleNode[] {
    const children = getChildNodesSelector(this.#state)(nodeId);
    return children.reduce<ResponsibleNode[]>((acc, child) => {
      if (isTask(child) && child.planningDates.length === 0) {
        //inherited tasks have no planning
        acc.push(child);
      }
      if (!isSubWorkPackage(child)) {
        acc.push(...this.#getInheritedTasks(child.id));
      }
      return acc;
    }, []);
  }

  #removeNodeFromUserWorkload(nodeId: Uuid, userId: Uuid): void {
    const workload = this.#currentWorkload[userId];
    if (workload?.plannedNodes[nodeId]) {
      const plannedNodes = {...workload.plannedNodes};
      delete plannedNodes[nodeId];
      this.#currentWorkload[userId] = {
        ...workload,
        plannedNodes,
        subWorkPackages: workload.subWorkPackages.filter((id) => id !== nodeId),
        tasks: workload.tasks.filter((taskId) => taskId !== nodeId),
        workPackages: workload.workPackages.filter((workPackageId) => workPackageId !== nodeId),
      };
    }
  }

  #removeNodeFromAllWorkload(nodeId: Uuid): void {
    for (const userId of Object.keys(this.#currentWorkload)) {
      this.#removeNodeFromUserWorkload(nodeId, userId);
    }
  }

  #getPlanningDates(nodeId: Uuid): PlanningDatesList {
    const planningDates = getPlanningDatesForNodeSelector(this.#state)(nodeId);
    if (planningDates.length > 0) {
      return planningDates;
    }
    const node = this.#getNode(nodeId);
    if (isTask(node)) {
      //check if task inherited planning dates from parent
      const anchestry = getNodeAncestrySelector(this.#state)(nodeId);
      const parentId = anchestry.subWorkPackage?.id || anchestry.workPackage?.id;
      if (parentId) {
        return getPlanningDatesForNodeSelector(this.#state)(parentId);
      }
    }
    return [];
  }

  #addNodeToWorkload(node: ResponsibleNode, userId: Uuid): void {
    const workload = this.#currentWorkload[userId];
    if (workload) {
      const nodeId = node.id;
      this.#currentWorkload[userId] = {
        ...workload,
        plannedNodes: this.#updateWorkloadPlannedNodes(node, workload),
        subWorkPackages: [...workload.subWorkPackages, ...(isSubWorkPackage(node) ? [nodeId] : [])],
        tasks: [...workload.tasks, ...(isTask(node) ? [nodeId] : [])],
        workPackages: [...workload.workPackages, ...(isWorkPackage(node) ? [nodeId] : [])],
      };
    }
  }

  #updateNodeInWorkload(node: ResponsibleNode, userId: Uuid): void {
    const nodeId = node.id;
    const workload = this.#currentWorkload[userId];
    if (workload?.plannedNodes[nodeId]) {
      this.#currentWorkload[userId] = {
        ...workload,
        plannedNodes: this.#updateWorkloadPlannedNodes(node, workload),
      };
    }
  }

  #updateWorkloadPlannedNodes(node: ResponsibleNode, workload: Workload): WorkloadPlannedNodes {
    return {
      ...workload.plannedNodes,
      [node.id]: this.#createWorkloadPlannedNode(node),
    };
  }

  #createWorkloadPlannedNode(node: ResponsibleNode): WorkloadPlannedNode {
    return {
      effort: this.#getEffort(node),
      id: node.id,
      planningDates: this.#getPlanningDates(node.id).map(({id, plannedEnd, plannedStart}) => ({
        id,
        plannedEnd,
        plannedStart,
      })),
      responsibleUsers: this.#getResolvedResponsibleUsers(node).size,
      subWorkPackageEffort: this.#getSubWorkPackageEffort(node),
      taskEffort: this.#getTaskEffort(node),
    };
  }
}
