import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {Icon} from '@octaved/ui';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Group, Project} from '../../../EntityInterfaces/Pid';
import {useProjectFrameContext} from '../../../Pages/Projects/Pages/ProjectsFrameContext';
import {useProjectContext} from '../../../Pages/Projects/ProjectContext';
import {useGetNewWorkPackageDialogRoute} from '../../../Routing/Routes/Dialogs';

interface Props {
  parent: Project | Group;
  relativeNodeId: Uuid;
}

export default function CanvasAddWorkPackageButton({parent, relativeNodeId}: Props): ReactElement | null {
  const {t} = useTranslation();
  const projectContext = useProjectContext(true);
  const getNewWorkpackageDialogRoute = useGetNewWorkPackageDialogRoute();
  const {showCreationButtons} = useProjectFrameContext();
  const isGranted = useSelector(isGrantedSelector);
  if (
    !projectContext ||
    !showCreationButtons ||
    parent.isArchived ||
    !isGranted('FLOW_NODE_PID_MANAGE_BASIC', parent.id)
  ) {
    return null;
  }
  return (
    <Link to={getNewWorkpackageDialogRoute(relativeNodeId, projectContext.page === 'createOffer')}>
      <div className={'wrapper'}>
        <div className={'fakeTile'}>
          <Icon size={'big'} iconColor={'green'}>
            <PlusCircle strokeWidth={1} />
          </Icon>
          <div className={'newWorkPackage'}>{t('projectsTree:createWorkpackage')}</div>
        </div>
      </div>
      <style jsx>{`
        .wrapper {
          height: 100%;
          width: 280px;
          padding: 5px;
        }

        .fakeTile {
          border-radius: 3px;
          width: 100%;
          border: 1px dashed rgb(204, 204, 204);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 0.4375rem;
          min-height: 75px;
          transition: background-color 120ms ease-in-out 0s;
        }

        .newWorkPackage {
          color: var(--color-grey-600);
        }

        .fakeTile:hover {
          background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.025));
        }
      `}</style>
    </Link>
  );
}
