import {IconButton, Tooltip} from '@octaved/ui';
import {MoreHorizontal} from 'lucide-react';
import {ReactElement, ReactNode} from 'react';
import DropdownPopup from '../../Form/Dropdown/DropdownPopup';
import {appendDropdownOptions} from '../../Form/Dropdown/DropdownPopup/AppendOptions';
import {DropdownPopupItemOptions} from '../../Form/Dropdown/DropdownPopup/Types';

interface Props {
  divider?: ReactNode;
  prepend?: DropdownPopupItemOptions[];
  append?: DropdownPopupItemOptions[];
}

export default function ProjectTreeEllipsisMenu({append, divider, prepend}: Props): ReactElement | null {
  const items: DropdownPopupItemOptions[] = [];
  appendDropdownOptions(items, prepend);
  appendDropdownOptions(items, append);

  if (items.length) {
    return (
      <>
        {divider}
        <DropdownPopup items={items} closeOnSelect position={'bottom right'}>
          <Tooltip toolTipTranslation={'tooltips:components.pageHeader.buttonEllispsis'} position={'bottom center'}>
            <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
          </Tooltip>
        </DropdownPopup>
      </>
    );
  }
  return null;
}
