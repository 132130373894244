import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {type GetWorkingTimeAtDate} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {type DateTimeStr} from '@octaved/typescript';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs, {type Dayjs} from 'dayjs';
import {TYPE_FF, TYPE_FS} from '../Selectors/PlanningDependencySelectors';
import {absoluteWorkdaysInRange, ensureWorkDay} from './WorkdayCalculations';

function requireDayjs(date: DateTimeStr | Dayjs): Dayjs {
  if (dayjs.isDayjs(date)) {
    return date;
  }
  return fromIsoDateTimeFormat(date);
}

export function calculateOffset(
  dependencyType: EnumFlowPlanningDependencyType,
  getWorkMinutesAtDate: GetWorkingTimeAtDate,
  predecessorStart: DateTimeStr | Dayjs,
  predecessorEnd: DateTimeStr | Dayjs,
  successorStart: DateTimeStr | Dayjs,
  successorEnd: DateTimeStr | Dayjs,
  alignForward: boolean,
): number {
  let offset: number;

  if (dependencyType === TYPE_FS) {
    //Finish-start is different: A zero offset is not the same day, but the next! That's why we need to
    // subtract 2 days here for the new offset:
    const predEndWorkDay = ensureWorkDay(requireDayjs(predecessorEnd), getWorkMinutesAtDate, alignForward);
    const sucStartWorkDay = ensureWorkDay(requireDayjs(successorStart), getWorkMinutesAtDate, alignForward);
    const diff = absoluteWorkdaysInRange(predEndWorkDay, sucStartWorkDay, getWorkMinutesAtDate);
    offset = predEndWorkDay.isAfter(sucStartWorkDay) ? -diff : diff - 2;
  } else if (dependencyType === TYPE_FF) {
    const predEndWorkDay = ensureWorkDay(requireDayjs(predecessorEnd), getWorkMinutesAtDate, alignForward);
    const sucEndWorkDay = ensureWorkDay(requireDayjs(successorEnd), getWorkMinutesAtDate, alignForward);
    const diff = absoluteWorkdaysInRange(predEndWorkDay, sucEndWorkDay, getWorkMinutesAtDate) - 1;
    offset = predEndWorkDay.isAfter(sucEndWorkDay) ? -diff : diff;
  } else {
    const predStartWorkDay = ensureWorkDay(requireDayjs(predecessorStart), getWorkMinutesAtDate, alignForward);
    const sucStartWorkDay = ensureWorkDay(requireDayjs(successorStart), getWorkMinutesAtDate, alignForward);
    const diff = absoluteWorkdaysInRange(predStartWorkDay, sucStartWorkDay, getWorkMinutesAtDate) - 1;
    offset = predStartWorkDay.isAfter(sucStartWorkDay) ? -diff : diff;
  }

  return offset;
}
