import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement, useRef, useState} from 'react';
import {useTaskListReadonlyContext} from '../../../TaskListReadonlyContext';
import {ShallowTask} from '../ShallowTask';
import Focused from './AssignmentCell/Focused';
import SelectedContent from './AssignmentCell/SelectedContent';
import CellActionButton from './Components/CellActionButton';
import TaskCell from './Components/TaskCell';

interface Props {
  task: ShallowTask;
}

export default function AssignmentCell({task}: Props): ReactElement {
  const {readonly} = useTaskListReadonlyContext();
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const canManage = useIsGranted('FLOW_NODE_TASK_MANAGE_RESPONSIBLE', task.id) && !task.isArchived;
  const view = readonly || !canManage || !focused;
  return (
    <TaskCell focused={focused} ref={ref} setFocused={setFocused} hasFocusElement wider>
      {view && <SelectedContent readonly={readonly} taskId={task.id} />}
      {!view && <Focused containerRef={ref} setFocused={setFocused} taskId={task.id} />}
      <CellActionButton />
    </TaskCell>
  );
}
