import {NodeTimeControlCustom} from '@octaved/flow/src/EntityInterfaces/TimeControlledEntity';
import {RangePicker} from '@octaved/ui';
import {ReactElement} from 'react';
import {PatchPartial} from '../NodeTimeControlSettings';

interface Props {
  timeControl: NodeTimeControlCustom;
  patch: PatchPartial;
  readonly: boolean;
}

export default function Custom({patch, readonly, timeControl}: Props): ReactElement {
  return (
    <div className={'flex'}>
      <RangePicker
        start={timeControl.from}
        end={timeControl.to}
        readonly={readonly}
        onChange={(from, to) => {
          if (from && to) {
            patch({from, to});
          }
        }}
      />
    </div>
  );
}
