import {FLOW_CHANGE_TASK_REQUEST} from '@octaved/flow/src/Modules/ActionTypes';
import {TaskPatchEvent} from '@octaved/flow/src/Modules/Events';
import {UnknownAction, isAction} from 'redux';
import UpdateWorkloadContext from './UpdateWorkloadContext';

function isPatchTaskRequestAction(action: unknown): action is TaskPatchEvent {
  return isAction(action) && action.type === FLOW_CHANGE_TASK_REQUEST;
}

export function handlePatchTaskAction(context: UpdateWorkloadContext, action: UnknownAction): boolean {
  const canHandle = isPatchTaskRequestAction(action);
  if (canHandle) {
    context.updateWorkloadForNode(action.options.urlParams.taskId);
  }
  return canHandle;
}
