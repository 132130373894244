import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {IdentityOrganization} from '@octaved/identity/src/Interfaces/Identity';
import {patchIdentity} from '@octaved/identity/src/Modules/Identity';
import {switchOrganization} from '@octaved/identity/src/Modules/OrganizationSwitcher';
import {identityHiddenOrganizationIdsSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {organizationIdSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {cn} from '@octaved/ui';
import {Check, EyeOff, Lock, MoreHorizontal} from 'lucide-react';
import {Fragment, ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import DropdownPopup from '../../../Components/Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../../Components/Form/Dropdown/DropdownPopup/Types';

interface Props {
  organization: IdentityOrganization;
}

export default function OrganizationRow({organization}: Props): ReactElement {
  const {t} = useTranslation();
  const currentActiveOrganizationId = useSelector(organizationIdSelector);
  const ref = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const menuItems: DropdownPopupItemOptions[] = useMemo(
    (): DropdownPopupItemOptions[] => [
      {
        icon: <EyeOff />,
        iconColor: 'darkGrey',
        onClick: () => (dispatch, getState) => {
          const current = new Set(identityHiddenOrganizationIdsSelector(getState()));
          current.add(organization.organizationId);
          dispatch(patchIdentity({hiddenOrganizationIds: [...current]}));
        },
        token: 'multiOrganizations:userMenu.organization.hideEntry',
        type: DropdownPopupItemType.item,
      },
    ],
    [organization.organizationId],
  );

  const dispatch = useDispatch();

  return (
    <Fragment key={organization.organizationId}>
      <div
        ref={ref}
        onClick={() => organization.isActive && dispatch(switchOrganization(organization.organizationId, false))}
        className={
          'group flex h-10 cursor-pointer items-center justify-between gap-x-2 px-3 transition-all duration-150 hover:bg-slate-50'
        }
      >
        <div className={'flex items-center gap-x-3 overflow-hidden'}>
          {organization.isActive && (
            <Check
              className={cn(
                'h-5 w-6 flex-shrink-0 text-white',
                organization.organizationId === currentActiveOrganizationId && 'text-green-600',
              )}
            />
          )}
          {!organization.isActive && <Lock className={'h-6 w-6 flex-shrink-0 text-slate-400'} />}
          <div className={'flex flex-grow flex-col overflow-hidden'}>
            <div className={'truncate font-medium text-slate-800'}>{organization.organizationName}</div>
            {!organization.isActive && (
              <div className={'text-sm italic text-slate-400'}>
                {t('multiOrganizations:userMenu.organization.deactivated')}
              </div>
            )}
            {organization.isActive && organization.orgUserType === UserType.guestCustomer && (
              <div className={'truncate text-sm text-slate-600'}>
                {t('multiOrganizations:userMenu.organization.customerUser')}
              </div>
            )}
          </div>
        </div>
        {mounted && (
          <DropdownPopup
            items={menuItems}
            inDrawer
            position={'left center'}
            mountNode={ref.current}
            closeOnSelect={false}
          >
            <button
              className={
                'flex h-6 w-0 flex-shrink-0 items-center justify-center rounded-md bg-slate-100/0 transition-all duration-150 hover:bg-slate-200 group-hover:w-6'
              }
            >
              <MoreHorizontal className={'h-4 w-4 text-slate-500'} />
            </button>
          </DropdownPopup>
        )}
      </div>
      <div className={'h-px w-full bg-slate-200'} />
    </Fragment>
  );
}
