import {error} from '@octaved/env/src/Logger';
import {EntityStates} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {Uuid} from '@octaved/typescript/src/lib';
import {difference, intersection} from 'lodash';
import {Reducer} from 'react';

export interface UuidSearchResults {
  [key: string]: Uuid[] | undefined;
}

export function concatReducers<A>(reducers: Reducer<EntityStates, A>[]) {
  return (state: EntityStates, action: A): EntityStates => {
    return reducers.reduce((acc, r) => r(acc, action), state);
  };
}

export function reduceUuidSearchResponse(
  state: UuidSearchResults,
  {key, response}: {key: string; response: Uuid[]},
): UuidSearchResults {
  const prev = state[key];
  if (!prev || prev.join('+') !== response.join('+')) {
    return mergeStates(state, {[key]: response});
  }
  return state;
}

export function reduceRemoveByPrefix<S extends Record<string, unknown>>(state: S, prefix: string | string[]): S {
  let changed = false;
  const newState = {...state};
  const prefixes = Array.isArray(prefix) ? prefix : [prefix];
  Object.keys(state).forEach((cacheKey) => {
    prefixes.forEach((pref) => {
      if (cacheKey.startsWith(pref)) {
        changed = true;
        delete newState[cacheKey];
      }
    });
  });
  return changed ? newState : state;
}

export function createSearchKeyReducerFactory<SearchIdent extends string>() {
  return function createSearchKeyReducerForAction<Action extends {type: string}, PPK extends keyof Action>(
    patchedPropsKey?: PPK,
  ) {
    return function createSearchKeyReducer(
      searchIdents: SearchIdent[],
      includedProps?: Action[PPK],
      excludedProps?: Action[PPK],
    ): (state: EntityStates, action: Action) => EntityStates {
      return (state, action) => {
        const patchedProps = patchedPropsKey && (action[patchedPropsKey] as unknown as string[] | undefined);
        if ((includedProps || excludedProps) && !patchedProps) {
          error(`Missing patchedKeys in action '${action.type}'`);
        }
        if (patchedProps) {
          if (includedProps && intersection(patchedProps, includedProps as unknown as string[]).length === 0) {
            return state;
          }
          if (excludedProps && difference(patchedProps, excludedProps as unknown as string[]).length === 0) {
            return state;
          }
        }
        return reduceRemoveByPrefix(state, searchIdents);
      };
    };
  };
}

export function reduceRemoveIdFromResults(state: UuidSearchResults, idToRemove: Uuid): UuidSearchResults {
  let changed = false;
  const newState = {...state};
  Object.keys(state).forEach((cacheKey) => {
    if (state[cacheKey]!.includes(idToRemove)) {
      const set = new Set(state[cacheKey]);
      set.delete(idToRemove);
      newState[cacheKey] = [...set];
      changed = true;
    }
  });
  return changed ? newState : state;
}

/**
 * Creates a search result reducer that copies a parent's presence in a search result to its child
 */
export function createInheritingReducer(
  keyPrefix: string,
  parentId: Uuid,
  childId: Uuid,
): (state: UuidSearchResults) => UuidSearchResults {
  return (state) => {
    const newState = {...state};
    let changed = false;
    Object.keys(newState).forEach((key) => {
      if (key.startsWith(keyPrefix) && newState[key]!.includes(parentId)) {
        newState[key] = [...new Set(newState[key]!).add(childId)];
        changed = true;
      }
    });
    return changed ? newState : state;
  };
}
