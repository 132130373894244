import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {IPlanningDateInspectorContext} from '@octaved/planning/src/Inspector/PlanningDateInspectorContext';
import {Uuid} from '@octaved/typescript/src/lib';
import {ComponentType, createContext, PropsWithChildren, useCallback, useContext, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Billing} from '../../EntityInterfaces/Billing/Billings';
import {Task} from '../../EntityInterfaces/Task';
import {ICustomWorkingTimesInspectorContext} from '../../Pages/CustomWorkingTimes/Inspector/CustomWorkingTimesInspectorContext';
import {IFlowCustomerInspectorContext} from './FlowCustomerInspectorContext';
import {IGuestRoleInspectorContext} from './GuestRoleInspectorContext';
import {IIntranetGroupInspectorContext} from './IntranetGroupInspectorContext';
import {IKanbanBoardInspectorContext} from './KanbanBoardInspectorContext';
import {ILabelInspectorContext} from './LabelInspectorContext';
import {IMaterialResourceInspectorContext} from './MaterialResourceInspectorContext';
import {IPermissionInspectorContext} from './PermissionInspectorContext';
import {IPriceCategoryInspectorContext} from './PriceCategoryInspectorContext';
import {IPriceSurchargeInspectorContext} from './PriceSurchargeInspectorContex';
import {IProjectFolderInspectorContext} from './ProjectFolderInspectorContext';
import {IProjectRoleInspectorContext} from './ProjectRoleInspectorContext';
import {ISecurityInspectorContext} from './SecurityInspectorContext';
import {IPidInspectorContext} from './StandardInspectorContext';
import {IUserInspectorContext} from './UserInpsectorContext';

export interface InspectorContextProps {
  type?: InspectorContextSelectedTypes;
  parameterName?: InspectorParameterNames;
}

export type InspectorContextWrapper = ComponentType<PropsWithChildren<InspectorContextProps>>;

interface CloseFunction {
  (): void;
}

interface BackFunction {
  (): void;
}

export interface BaseInspectorContext {
  selectedId: Uuid;
  close?: CloseFunction;
  back?: BackFunction;
  isSubInspector: boolean;
}

interface BillingInspectorContext extends BaseInspectorContext {
  selectedEntity: Billing;
  selectedType: 'billing';
}

interface TaskInspectorContext extends BaseInspectorContext {
  selectedEntity: Task;
  selectedType: EnumFlowNodeType.VALUE_TASK;
}

interface TimeSheetInspectorContext extends BaseInspectorContext {
  selectedEntity: null;
  selectedType: 'timesheet';
}

export type InspectorContext =
  | BillingInspectorContext
  | IPidInspectorContext
  | TaskInspectorContext
  | IFlowCustomerInspectorContext
  | TimeSheetInspectorContext
  | IIntranetGroupInspectorContext
  | IUserInspectorContext
  | IKanbanBoardInspectorContext
  | ILabelInspectorContext
  | IPriceSurchargeInspectorContext
  | IPriceCategoryInspectorContext
  | IProjectRoleInspectorContext
  | IGuestRoleInspectorContext
  | IProjectFolderInspectorContext
  | ISecurityInspectorContext
  | IPermissionInspectorContext
  | IMaterialResourceInspectorContext
  | ICustomWorkingTimesInspectorContext
  | IPlanningDateInspectorContext;

export type InspectorContextSelectedTypes = InspectorContext['selectedType'];

export const inspectorContext = createContext<InspectorContext | null>(null);
export type InspectorParameterNames = 'inspectId' | 'inspectL2Id' | 'unitId' | 'workTimeId';

export function isPidInspectorContext(context: InspectorContext | null): context is IPidInspectorContext {
  const allowedTypes: Array<string | undefined> = [
    EnumFlowNodeType.VALUE_PROJECT,
    EnumFlowNodeType.VALUE_GROUP,
    EnumFlowNodeType.VALUE_WORK_PACKAGE,
    EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
  ];
  return allowedTypes.includes(context?.selectedType);
}

export function isBillingInspectorContext(context: InspectorContext | null): context is BillingInspectorContext {
  return context?.selectedType === 'billing';
}

export function isTimesheetInspectorContext(context: InspectorContext | null): context is TimeSheetInspectorContext {
  return context?.selectedType === 'timesheet';
}

export function useInspectorContext(): InspectorContext {
  const context = useContext(inspectorContext);
  if (!context) {
    throw new Error('Inspector context not set');
  }
  return context;
}

export function useBaseInspectorContextParams(parameterName: InspectorParameterNames): BaseInspectorContext | null {
  const params = useParams<InspectorParameterNames>();
  const id = params[parameterName];

  const parentContext = useContext(inspectorContext);
  const isSubInspector = Boolean(parentContext);
  const closeParentInspector = parentContext?.close;
  const navigate = useNavigate();
  const back = useMemo(() => {
    if (isSubInspector) {
      return () => {
        navigate('..' + window.location.search);
      };
    }
    return undefined;
  }, [isSubInspector, navigate]);
  const close = useCallback(() => {
    if (closeParentInspector) {
      closeParentInspector();
    } else {
      navigate('..' + window.location.search);
    }
  }, [navigate, closeParentInspector]);

  return useMemo(() => {
    if (!id) {
      return null;
    }
    return {
      back,
      close,
      isSubInspector,
      selectedId: id,
    };
  }, [back, close, id, isSubInspector]);
}
