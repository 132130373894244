export type ReducerMapOrCollection<R> =
  | Map<string, R>
  | {
      add: (type: string, reducer: R) => void;
    };

export function addReducerToMapOrCollection<R>(reducers: ReducerMapOrCollection<R>, type: string, reducer: R): void {
  if (reducers instanceof Map) {
    reducers.set(type, reducer);
  } else if (typeof reducers.add === 'function') {
    reducers.add(type, reducer);
  } else {
    throw new Error('Invalid reducers collection given');
  }
}
