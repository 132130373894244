import {getState} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {NodeSearchCondition, NodeSearchTuple} from '../../EntityInterfaces/NodeSearch';

export interface AssignedProjectRoleIdEntry extends SlimUnit {
  roleId: Uuid;
}
export type AssignedProjectRoleIdFilter = AssignedProjectRoleIdEntry[];

export function createAssignedProjectRoleIdsQuery(projectRoleIds: Uuid[], unitId: Uuid): NodeSearchCondition | null {
  if (projectRoleIds.length) {
    const conditions = projectRoleIds.reduce<NodeSearchTuple[]>((acc, projectRoleId) => {
      acc.push(['assignedProjectRoleId', `${unitId}@${projectRoleId}`]);
      return acc;
    }, []);
    return {or: conditions};
  }
  return null;
}

export function myProjectRoleIdsQueryGenerator(projectRoleIds: Uuid[]): NodeSearchCondition | null {
  const userId = currentOrgUserIdSelector(getState());
  return createAssignedProjectRoleIdsQuery(projectRoleIds, userId);
}

export function userProjectRoleIdsQueryGenerator(
  unitProjectRoleIds: AssignedProjectRoleIdFilter,
): NodeSearchCondition | null {
  if (unitProjectRoleIds.length) {
    const conditions = unitProjectRoleIds.reduce<NodeSearchTuple[]>((acc, {unitId, roleId}) => {
      acc.push(['assignedProjectRoleId', `${unitId}@${roleId}`]);
      return acc;
    }, []);
    return {or: conditions};
  }
  return null;
}
