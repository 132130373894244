import {error} from '@octaved/env/src/Logger';
import {PreparedStateDefinition, StorageMiddlewareStateDefinition} from '@octaved/store/src/StorageMiddleware';
import get from 'lodash/get';
import {projectFolderTransformer} from '../Modules/Projects/ProjectFavorites';
import {FlowState} from '../Modules/State';
import {UserSettingsMiddlewareConfig} from './UserSettingsMiddleware';

interface SyncConfigStorage extends Partial<Omit<PreparedStateDefinition, 'path' | 'default'>> {
  target: 'localStorage' | 'sessionStorage';
}

interface SyncConfigUserSettings {
  target: 'userSettings';
}

const toSync: Record<string, SyncConfigStorage | SyncConfigUserSettings> = {
  'currentOrgUser.timeTrackingSuperUserMode.activeUntil': {target: 'localStorage'},
  'currentOrgUser.timeTrackingSuperUserMode.isActive': {target: 'localStorage'},
  'ui.boardPosts.showComments': {target: 'localStorage'},
  'ui.boardPosts.showDescendantPosts': {target: 'localStorage'},
  'ui.boardPosts.sortBy': {target: 'localStorage'},
  'ui.boardPosts.sortDirection': {target: 'localStorage'},
  'ui.collapsedInspectorSections': {target: 'localStorage'},
  'ui.collapsedTasks': {target: 'localStorage'},
  'ui.drawerPinned': {target: 'localStorage'},
  'ui.drawerWidth': {target: 'localStorage'},
  'ui.expandedPageMenu': {target: 'localStorage'},
  'ui.help.hiddenForPages': {target: 'userSettings'},
  'ui.nodeTables.pages.projects.currentViewId': {target: 'localStorage'},
  'ui.nodeTables.pages.projects.gridStates': {target: 'sessionStorage', version: 2},
  'ui.nodeTables.pages.projects.hiddenDefaultViews': {target: 'localStorage'},
  'ui.nodeTables.pages.projects.savedViews': {target: 'userSettings'},
  'ui.nodeTables.pages.projects.search': {target: 'sessionStorage', version: 2},
  'ui.nodeTables.pages.projects.temporaryViews': {target: 'sessionStorage', version: 3},
  'ui.pageMenu.expandedGroups': {target: 'localStorage'},
  'ui.pageMenu.isExpanded': {target: 'localStorage'},
  'ui.pages.billingsList.export.csv.type': {target: 'localStorage'},
  'ui.pages.billingsList.export.timeSheet.advancedIsOpen': {target: 'localStorage'},
  'ui.pages.billingsList.quickFilterValues': {target: 'userSettings'},
  'ui.pages.boards.leftDrawer': {target: 'localStorage'},
  'ui.pages.boards.selectedKanbanBoard': {target: 'localStorage'},
  'ui.pages.customWorkingTimes.selectedUnits': {target: 'userSettings'},
  'ui.pages.myTasks.advancedFilters': {target: 'localStorage'}, //must be the same as `filterPreset`
  'ui.pages.myTasks.closedWorkPackageGroups': {target: 'localStorage'},
  'ui.pages.myTasks.filterPreset': {target: 'localStorage'}, //must be the same as `advancedFilters`
  'ui.pages.myTasks.listExpandedSections': {target: 'localStorage'},
  'ui.pages.myTasks.listSectionOrder': {target: 'localStorage'},
  'ui.pages.myTasks.listShowTasks': {target: 'userSettings'},
  'ui.pages.myTasks.listTimeFrame': {target: 'userSettings'},
  'ui.pages.myTasks.manualWorkPackageSortOrder': {target: 'userSettings'},
  'ui.pages.myTasks.showBlockedTasks': {target: 'userSettings'},
  'ui.pages.myTasks.showConnectedCalendarDemoData': {target: 'localStorage'},
  'ui.pages.myTasks.showDueDates': {target: 'userSettings'},
  'ui.pages.myTasks.showEmptyTaskSections': {target: 'userSettings'},
  'ui.pages.myTasks.showTasksOfOtherUsers': {target: 'userSettings'},
  'ui.pages.myTasks.timelineExpandedLanes.idLane': {target: 'localStorage'},
  'ui.pages.myTasks.timelineExpandedLanes.personalTasks': {target: 'localStorage'},
  'ui.pages.myTasks.timelineExpandedLanes.workLoad': {target: 'localStorage'},
  'ui.pages.myTasks.timelineExpandedLanes.workPackages': {target: 'localStorage'},
  'ui.pages.myTasks.timelineShowTasks': {target: 'userSettings'},
  'ui.pages.myTasks.visibleTasks': {target: 'userSettings'},
  'ui.pages.myTasks.workPackageGroupAndSortBy': {target: 'userSettings'},
  'ui.pages.nodeDetails.largeViewTasks.filterValues': {target: 'localStorage'},
  'ui.pages.organizeBoardsGroups.leftDrawer': {target: 'localStorage'},
  'ui.pages.organizeBoardsGroups.selectedKanbanBoard': {target: 'localStorage'},
  'ui.pages.organizeBoardsProjects.selectedKanbanBoard': {target: 'localStorage'},
  'ui.pages.organizeBoardsTasks.leftDrawer': {target: 'localStorage'},
  'ui.pages.organizeBoardsTasks.selectedKanbanBoard': {target: 'localStorage'},
  'ui.pages.organizeBoardsTasks.selectedUnits': {target: 'localStorage'},
  'ui.pages.organizeBoardsWorkPackages.leftDrawer': {target: 'localStorage'},
  'ui.pages.organizeBoardsWorkPackages.selectedKanbanBoard': {target: 'localStorage'},
  'ui.pages.personalTasks.visibleTasks': {target: 'userSettings'},
  'ui.pages.planning.baselineComparison.barTextDisplayment': {target: 'localStorage'},
  'ui.pages.planning.baselineComparison.columnWidth': {target: 'localStorage'},
  'ui.pages.planning.baselineComparison.nodeSetting': {target: 'localStorage'},
  'ui.pages.planning.baselineComparison.showTasks': {target: 'localStorage'},
  'ui.pages.planning.collapsedSubMenu': {target: 'localStorage'},
  'ui.pages.planning.extendedNodes': {target: 'localStorage'},
  'ui.pages.planning.groupProjectPlanningByCustomer': {target: 'localStorage'},
  'ui.pages.planning.materialResourcesExtendedNodes': {target: 'localStorage'},
  'ui.pages.planning.projectPlanningBarTextDisplayment': {target: 'localStorage'},
  'ui.pages.planning.projectPlanningExtendedNodes': {target: 'localStorage'},
  'ui.pages.planning.projectPlanningSelectedProjects': {target: 'userSettings'},
  'ui.pages.planning.projectPlanningShowMaterialResources': {target: 'localStorage'},
  'ui.pages.planning.selectedSimulationSnapshotId': {target: 'userSettings'},
  'ui.pages.planning.simulationModeActive': {target: 'userSettings'},
  'ui.pages.planning.teamPlanningCollapsedWorkPackages': {target: 'localStorage'},
  'ui.pages.planning.teamPlanningDragMode': {target: 'localStorage'},
  'ui.pages.planning.teamPlanningShowTasks': {target: 'localStorage'},
  'ui.pages.planning.teamPlanningShowWorkload': {target: 'localStorage'},
  'ui.pages.planning.teamPlanningWorkloadDisplayment': {target: 'localStorage'},
  'ui.pages.planning.visibleGanttColumns': {target: 'localStorage', version: 2},
  'ui.pages.planning.visibleMaterialResourceColumns': {target: 'localStorage', version: 2},
  'ui.pages.projectPlanning.ganttColumnWidth': {target: 'localStorage'},
  'ui.pages.projectPlanning.ganttZoom': {target: 'localStorage'},
  'ui.pages.projectPlanning.leftDrawer': {target: 'localStorage'},
  'ui.pages.projectPlanning.showHoveringNodeInfo': {target: 'localStorage'},
  'ui.pages.projects.billing.newBilling.exportSumsAsExcel': {target: 'localStorage'},
  'ui.pages.projects.billing.newBilling.exportTimesheetAsPdf': {target: 'localStorage'},
  'ui.pages.projects.billing.newBilling.exportTimesheetAsPdfWithNames': {target: 'localStorage'},
  'ui.pages.projects.billing.newBilling.transferToErp': {target: 'localStorage'},
  'ui.pages.projects.collapsedInspector': {target: 'localStorage'},
  'ui.pages.projects.collapsedLeftFolder': {target: 'localStorage'},
  'ui.pages.projects.collapsedSubMenu': {target: 'localStorage'},
  'ui.pages.projects.groupProjects': {target: 'localStorage'},
  'ui.pages.projects.inspector.timeTracking.showDaysInsteadOfHours': {target: 'localStorage'},
  'ui.pages.projects.openNodes': {target: 'localStorage'},
  'ui.pages.projects.projectControlling.demoMode': {target: 'localStorage'},
  'ui.pages.projects.projectControlling.showDaysInsteadHours': {target: 'localStorage'},
  'ui.pages.projects.selectedProjectFolder': {target: 'localStorage', transformer: projectFolderTransformer},
  'ui.pages.projects.showOnlyFavoriteProjects': {target: 'localStorage'},
  'ui.pages.projects.showPinnedProjects': {target: 'localStorage'},
  'ui.pages.projects.showProjectFolder': {target: 'localStorage'},
  'ui.pages.projects.showSubFolderProjects': {target: 'localStorage'},
  'ui.pages.projects.sortProjectsBy': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.breakdown': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.chartView': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.compare': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.compareFilter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.compareFilter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.compareFilter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.compareFilter.start': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.filter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.filter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.filter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.billingTypes.showInternalNoCharge': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.chartView': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.compare': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.compareFilter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.compareFilter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.compareFilter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.compareFilter.start': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.filter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.filter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.filter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.bookedTimes.showDaysForBookingTimes': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.breakdownIndex': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.chartView': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.compare': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.compareFilter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.compareFilter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.compareFilter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.compareFilter.start': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.filter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.filter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.chargeableQuota.filter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.demoMode': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.breakdown': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.chartView': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.compare': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.compareFilter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.compareFilter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.compareFilter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.compareFilter.start': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.filter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.filter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.filter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.noCharge.showInternalNoCharge': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.breakdownIndex': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.chartView': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.compare': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.compareFilter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.compareFilter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.compareFilter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.compareFilter.start': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.filter.customersFilter': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.filter.includeArchived': {target: 'localStorage'},
  'ui.pages.reporting.roundingGain.filter.projectAndOUFilter': {target: 'localStorage'},
  'ui.pages.systemSettings.projectFolderLocation': {target: 'localStorage'},
  'ui.pages.systemSettings.selectedProjectFolder': {target: 'localStorage'},
  'ui.pages.teamPlanning.extendedNodes': {target: 'localStorage'},
  'ui.pages.teamPlanning.ganttZoom': {target: 'localStorage'},
  'ui.pages.teamPlanning.selectedUnits': {target: 'localStorage'},
  'ui.pages.teamPlanning.showHoveringNodeInfo': {target: 'localStorage'},
  'ui.pages.teamTasks.collapsedUnits': {target: 'localStorage'},
  'ui.pages.teamTasks.leftDrawer': {target: 'localStorage'},
  'ui.pages.teamTasks.showTasksOfOtherUsers': {target: 'userSettings'},
  'ui.pages.teamTasks.units': {target: 'userSettings'},
  'ui.pages.teamTasks.visibleTasks': {target: 'userSettings'},
  'ui.pages.timeRecords.myRecordings.timeFilter.from': {target: 'localStorage'},
  'ui.pages.timeRecords.myRecordings.timeFilter.to': {target: 'localStorage'},
  'ui.pages.timeRecords.myRecordings.timeFilter.type': {target: 'localStorage'},
  'ui.pages.timeRecords.projects.timeFilter.from': {target: 'localStorage'},
  'ui.pages.timeRecords.projects.timeFilter.to': {target: 'localStorage'},
  'ui.pages.timeRecords.projects.timeFilter.type': {target: 'localStorage'},
  'ui.pages.timeRecords.users.timeFilter.from': {target: 'localStorage'},
  'ui.pages.timeRecords.users.timeFilter.to': {target: 'localStorage'},
  'ui.pages.timeRecords.users.timeFilter.type': {target: 'localStorage'},
  'ui.pages.trackTime.createTimeTrackingDragging': {target: 'localStorage'},
  'ui.pages.trackTime.descriptionDisplay': {target: 'localStorage'},
  'ui.pages.trackTime.expandedTeamUserIds': {target: 'localStorage'},
  'ui.pages.trackTime.leftDrawer.columnCount': {target: 'localStorage'},
  'ui.pages.trackTime.leftDrawer.isOpen': {target: 'localStorage'},
  'ui.pages.trackTime.leftDrawer.search': {target: 'localStorage'},
  'ui.pages.trackTime.leftDrawer.searchScope': {target: 'localStorage'},
  'ui.pages.trackTime.leftDrawer.selectedCustomer': {target: 'localStorage'},
  'ui.pages.trackTime.leftDrawer.showCustomerSelector': {target: 'userSettings'},
  'ui.pages.trackTime.selectedView': {target: 'localStorage'},
  'ui.pages.trackTime.showBillableInfo': {target: 'localStorage'},
  'ui.pages.trackTime.showBillableTimes': {target: 'localStorage'},
  'ui.pages.trackTime.table.showCustomerColumn': {target: 'localStorage'},
  'ui.pages.trackTime.teamUserIds': {target: 'localStorage'},
  'ui.pages.weeklyWorkingTimes.selectedUnits': {target: 'userSettings'},
  'ui.pages.workingTimeMonitoring.export.type': {
    target: 'localStorage',
  },
  'ui.pages.workingTimeMonitoring.export.withAbsences': {
    target: 'localStorage',
  },
  'ui.pages.workingTimeMonitoring.selectedUnits': {
    target: 'localStorage',
  },
  'ui.pages.workingTimeMonitoring.timeFrame': {target: 'localStorage'},
  'ui.taskList.closedGroups': {target: 'localStorage'},
  'ui.taskList.taskSectionColor': {target: 'userSettings'},
  'ui.taskList.tile.showLabels': {target: 'localStorage'},
  'ui.taskList.tile.showStatusIcons': {target: 'localStorage'},
  'ui.taskList.tile.taskPath': {target: 'localStorage'},
  'ui.taskList.visibleColumns': {target: 'localStorage'},
  'ui.taskView.showEffort': {target: 'localStorage'},
  'ui.taskView.showLabels': {target: 'localStorage'},
  'ui.taskView.showPlanning': {target: 'localStorage'},
  'ui.taskView.showResponsibility': {target: 'localStorage'},
  'ui.trackTimeSelectWorkPackageTab': {target: 'localStorage'},
  'ui.workpackageView.progressBar': {target: 'userSettings'},
  'ui.workpackageView.showLabels': {target: 'localStorage'},
  'ui.workpackageView.showPath': {target: 'localStorage'},
  'ui.workpackageView.showPlanning': {target: 'localStorage'},
  'ui.workpackageView.showStatusIcon': {target: 'localStorage'},
  'ui.workpackageView.showTasks': {target: 'userSettings'},
};

function getDefault(initial: FlowState, path: string): unknown {
  const defaultValue = get(initial, path);
  if (typeof defaultValue === 'undefined') {
    error(`Path ${path} does not have a default value, does this prop still exist?`);
  }
  return defaultValue;
}

export function getStorageMiddlewareStates(initial: FlowState): StorageMiddlewareStateDefinition[] {
  return Object.entries(toSync)
    .filter(([, {target}]) => target === 'localStorage' || target === 'sessionStorage')
    .map(([path, {target: storage, ...rest}]) => {
      const defaultValue = getDefault(initial, path);
      return {...rest, path, default: defaultValue, storage: storage as 'localStorage' | 'sessionStorage'};
    });
}

export function getUserSettingsMiddlewarePaths(initial: FlowState): UserSettingsMiddlewareConfig[] {
  return Object.entries(toSync)
    .filter(([, {target}]) => target === 'userSettings')
    .map(([path]) => {
      if (!path.startsWith('ui.')) {
        error(`Path ${path} cannot be used for user settings sync. Only ui-paths are allowed.`);
      }
      getDefault(initial, path); //validate existance
      return {path: path.substr(3)}; //cut off "ui."
    });
}
