import {advancedBillingDefault} from '../../../EntityInterfaces/Filter/Custom/Billing';
import {
  advancedBillingInPeriodDefault,
  quickBillingInPeriodDefault,
} from '../../../EntityInterfaces/Filter/Custom/BillingInPeriod';
import {quickBillingPreparationDefault} from '../../../EntityInterfaces/Filter/Custom/BillingPreparation';
import {quickControlActivityDefault} from '../../../EntityInterfaces/Filter/Custom/ControlActivity';
import {quickControlCriticalTimePrognosisDefault} from '../../../EntityInterfaces/Filter/Custom/ControlCriticalTimePrognosis';
import {quickControlFixedPriceAnalysisDefault} from '../../../EntityInterfaces/Filter/Custom/ControlFixedPriceAnalysis';
import {quickControlPlanningTransgressionDefault} from '../../../EntityInterfaces/Filter/Custom/ControlPlanningTransgression';
import {quickControlTimeTransgressionDefault} from '../../../EntityInterfaces/Filter/Custom/ControlTimeTransgression';
import {
  advancedControlTrackedTimeDefault,
  quickControlTrackedTimeDefault,
} from '../../../EntityInterfaces/Filter/Custom/ControlTrackedTime';
import {advancedCreateOfferDefault} from '../../../EntityInterfaces/Filter/Custom/CreateOffer';
import {FilterState} from '../../../EntityInterfaces/Filter/FilterState';
import {ProjectFilterStatesPages} from '../../../EntityInterfaces/Filter/ProjectFilters';
import {getSortedBillingTypes} from '../../../WorkPackage/BillingType';
import {EnumExecutionStatus} from '../../../WorkPackage/ExecutionStatus';
import {scopedLabelIdsDefault} from '../../Filter/ScopedLabelIds';

const allExecStatus = Object.values<EnumExecutionStatus>(EnumExecutionStatus);

function active<V>(value: V): FilterState<V> {
  return {isActive: true, value};
}

function inactive<V>(value: V): FilterState<V> {
  return {isActive: false, value};
}

/**
 * These definitions must now be complete! All and only these defined here are applied, so these should be kept in
 *  sync with available UI components on the respective UI pages!
 */
export const defaultProjectFilterStates: ProjectFilterStatesPages = {
  billing: {
    advancedBilling: active(advancedBillingDefault),
    descendantScopedLabelIds: inactive(scopedLabelIdsDefault),
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectUnitProjectRoleIds: inactive([]),
    workPackageIsArchived: active(false),
  },
  billingInPeriod: {
    advancedBillingInPeriod: active(advancedBillingInPeriodDefault),
    descendantScopedLabelIds: inactive(scopedLabelIdsDefault),
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectUnitProjectRoleIds: inactive([]),
    quickBillingInPeriod: active(quickBillingInPeriodDefault),
    workPackageIsArchived: active(false),
  },
  billingPreparation: {
    descendantScopedLabelIds: inactive(scopedLabelIdsDefault),
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectUnitProjectRoleIds: inactive([]),
    quickBillingPreparation: active(quickBillingPreparationDefault),
    workPackageIsArchived: active(false),
  },
  billingsList: {},
  controlActivity: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickControlActivity: active(quickControlActivityDefault),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlCriticalTimePrognosis: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickControlCriticalTimePrognosis: active(quickControlCriticalTimePrognosisDefault),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlDegreeOfCompletion: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickExecutionStatus: active(allExecStatus),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlFixedPriceAnalysis: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickControlFixedPriceAnalysis: active(quickControlFixedPriceAnalysisDefault),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlInProgress: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickExecutionStatus: active(allExecStatus),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlPlanningTransgression: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickControlPlanningTransgression: active(quickControlPlanningTransgressionDefault),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlTimeTransgression: {
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickControlTimeTransgression: active(quickControlTimeTransgressionDefault),
    workPackageBillingType: active(getSortedBillingTypes(true)),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  controlTrackedTime: {
    advancedControlTrackedTime: active(advancedControlTrackedTimeDefault),
    advancedControlTrackedTimeLabelIds: inactive([]),
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: active(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    quickControlTrackedTime: active(quickControlTrackedTimeDefault),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  createOffer: {
    advancedCreateOffer: active(advancedCreateOfferDefault),
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: inactive(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  edit: {
    boardPostLabelIds: inactive([]),
    hideParentsWithoutMatches: active(false),
    projectIsArchived: active(false),
    projectIsLocked: inactive(false),
    projectIsTemplate: inactive(false),
    projectLabelIds: inactive([]),
    projectUnitProjectRoleIds: inactive([]),
    subprojectLabelIds: inactive([]),
    taskLabelIds: inactive([]),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(false),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
    workPackageLabelIds: inactive([]),
    workPackageResponsibleUnits: inactive([]),
    workPackageUnitProjectRoleIds: inactive([]),
  },
  timesheetsExternal: {},
};
