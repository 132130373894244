import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useTaskGroupContext} from '../../TaskGroup/TaskGroupContext';
import {useShowNewRow} from '../NewItemHooks';
import NewTaskSectionRow from './NewTaskSectionRow';

interface Props {
  parentId: string;
  previousSiblingId: Uuid | undefined;
}

export default function AddTaskSectionRows({parentId, previousSiblingId}: Props): ReactElement {
  const {focusNewTaskSection} = useTaskGroupContext();
  const {setShow, show} = useShowNewRow(focusNewTaskSection);
  return (
    <>
      {show && (
        <NewTaskSectionRow onFinish={() => setShow(false)} parentId={parentId} previousSiblingId={previousSiblingId} />
      )}
    </>
  );
}
