import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {canReadNodeRoleAssignmentsSelector} from '@octaved/flow/src/Modules/Selectors/RoleAssignments/NodeRoleAssignmentSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {licenseSelector} from '@octaved/license/src/Selectors/LicenseSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {Message} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import SimpleRoleAssignment from '../../Responsible/SimpleRoleAssignment/SimpleRoleAssignment';

export interface NodeGuestUserAssignementProps {
  nodeId: Uuid;
  readonly?: boolean;
}

export default function NodeGuestUserAssignement({
  frame: Frame,
  nodeId,
  readonly,
}: NodeGuestUserAssignementProps & FramedSectionOrTileProps): ReactElement | null {
  const canRead = useSelector((s: FlowState) =>
    canReadNodeRoleAssignmentsSelector(s)(EnumFlowNodeRoleType.VALUE_PERMISSION, true, nodeId),
  );
  const {t} = useTranslation();
  const {orgUserCounts} = useSelector(licenseSelector);
  const activeGuestUsers = (orgUserCounts?.guestCustomer.active || 0) + (orgUserCounts?.guestOther.active || 0);

  if (!canRead) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.guestUser.label'}>
      {activeGuestUsers > 0 && (
        <SimpleRoleAssignment
          isGuestRole
          nodeId={nodeId}
          type={EnumFlowNodeRoleType.VALUE_PERMISSION}
          readonly={readonly}
        />
      )}
      {activeGuestUsers === 0 && <Message>{t('guestUser:inspector.noGuestUser')}</Message>}
    </Frame>
  );
}
