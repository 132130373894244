import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import {TWButton} from '@octaved/ui';
import {MoreHorizontal, Trash} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {PlanningRealizationNode} from '../../../../EntityInterfaces/PlanningRealizationNode';
import {PatchPlanningDependency} from '../../../../Modules/Planning';
import {removeDependency} from '../../../../Modules/PlanningDependencies';

interface MenuProps {
  node: PlanningRealizationNode;
  dependency: PatchPlanningDependency;
}

export default function Menu({node, dependency}: MenuProps): ReactElement {
  const dispatch = useDispatch();

  const items = useMemo(() => {
    const items: DropdownPopupItemOptions[] = [
      {
        className: 'hover:!bg-red-50',
        icon: <Trash />,
        iconColor: 'red',
        onClick: () => {
          dispatch(removeDependency(node.id, dependency.predecessor));
        },
        textColor: 'red',
        token: 'pages:projects.inspector.manage.planning.deleteDependency',
        type: DropdownPopupItemType.item,
      },
    ];

    return items;
  }, [dispatch, node.id, dependency.predecessor]);

  return (
    <DropdownPopup items={items} position={'bottom left'}>
      <TWButton variant={'ghost'} size={'xs'} iconOnly className={'onHover'}>
        <MoreHorizontal className={'size-4'} />
      </TWButton>
    </DropdownPopup>
  );
}
