import {BillingSearchCondition} from '@octaved/flow/src/EntityInterfaces/Billing/BillingSearch';
import {AnyQuickFilterConfig} from '@octaved/quick-filters/src/Config';
import {billingCustomersQuickFilterConfig} from '@octaved/quick-filters/src/Filters/CustomersQuickFilter';
import {billingProjectFolderQuickFilterConfig} from '@octaved/quick-filters/src/Filters/ProjectFolderQuickFilter';
import {billingErpStatusQuickFilterConfig} from './ErpStatus';
import {billingWorkflowStatusQuickFilterConfig} from './WorkflowStatus';

export const billingListQuickFilterConfigs = [
  billingProjectFolderQuickFilterConfig,
  billingCustomersQuickFilterConfig,
  billingWorkflowStatusQuickFilterConfig,
  billingErpStatusQuickFilterConfig,
] as const satisfies AnyQuickFilterConfig<BillingSearchCondition>[];
