import {useMemo} from 'react';
import {sortTimeControlledNode} from '../Selectors/NodeSortSelectors';
import {ProjectsUiState} from '../UiPages/Projects';
import {ProjectTreeProject} from './ProjectTreeInterfaces';

type SortFunctions = {
  [K in ProjectsUiState['sortProjectsBy']]: (a: ProjectTreeProject, b: ProjectTreeProject) => number;
};

const sortFunctions: SortFunctions = {
  alphanumeric: (a, b) => sortTimeControlledNode(a.entity, b.entity),
  customer: (a, b) => (a.customer?.name || '').localeCompare(b.customer?.name || ''),
  pm: (a, b) => a.projectManagersJoined.localeCompare(b.projectManagersJoined),
};

export function useSortedTreeProjects(
  treeProjects: ProjectTreeProject[],
  sortBy: ProjectsUiState['sortProjectsBy'],
): ProjectTreeProject[] {
  return useMemo(() => {
    const sorted = [...treeProjects];

    //Always sort by project name first, it is the fallback:
    sorted.sort(sortFunctions.alphanumeric);

    //Then additionally sort by the sortBy:
    if (sortBy !== 'alphanumeric') {
      sorted.sort(sortFunctions[sortBy]);
    }

    return sorted;
  }, [treeProjects, sortBy]);
}
