import {Uuid} from '@octaved/typescript/src/lib';
import {Dispatch, ReactElement, SetStateAction} from 'react';
import {useSelector} from 'react-redux';
import VerticalFolder from '../../../Components/ProjectFolder/VerticalFolder';
import {
  projctsleftProjectsFolderCollapsedSelector,
  showProjectFolderSelector,
} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useProjectUiStatePatch} from '../Projects';

interface ProjectsVerticalFolderProps {
  selectedProjectFolder: Uuid | null;
  setSelectedProjectFolder: Dispatch<SetStateAction<Uuid>>;
}

export default function ProjectsVerticalFolder({
  selectedProjectFolder,
  setSelectedProjectFolder,
}: ProjectsVerticalFolderProps): ReactElement {
  const patchProjectsUi = useProjectUiStatePatch();
  const collapsed = useSelector(projctsleftProjectsFolderCollapsedSelector);
  const showProjectFolder = useSelector(showProjectFolderSelector);

  return (
    <VerticalFolder
      selectFolder={(folderId) => {
        if (folderId) {
          setSelectedProjectFolder(folderId);
        }
      }}
      selectedFolder={selectedProjectFolder}
      setCollapsed={(collapsedLeftFolder) => patchProjectsUi({collapsedLeftFolder})}
      collapsed={collapsed}
      active={showProjectFolder}
    />
  );
}
