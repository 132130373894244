import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M20 14H13C11.8954 14 11 14.8954 11 16V18C11 19.1046 11.8954 20 13 20H20C21.1046 20 22 19.1046 22 18V16C22 14.8954 21.1046 14 20 14Z',
      key: '1',
    },
  ],
  [
    'path',
    {
      d: 'M20 3H5C3.89543 3 3 3.89543 3 5V7C3 8.10457 3.89543 9 5 9H20C21.1046 9 22 8.10457 22 7V5C22 3.89543 21.1046 3 20 3Z',
      fill: 'currentColor',
      key: '2',
    },
  ],
  [
    'path',
    {
      d: 'M6 10V15C6 16.1046 6.89543 17 8 17H11',
      key: '3',
    },
  ],
];

export const TimeBasedPlanningSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const TimeBasedPlanning = createLucideIcon('TimeBasedPlanning', iconDrawing);
