import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router-dom';

/**
 * @internal - don't import from outside
 */
export const setupRoute = 'setup';
export const organizationNameRoute = 'organizationName';
export const typeOfWorkRoute = 'typeOfWork';
export const startingPointRoute = 'startingPoint';
export const projectNameRoute = 'projectName';
export const workPackagesRoute = 'workPackages';
export const tasksRoute = 'tasks';

const SetupWrapper = lazy(() => import('../../Pages/Setup/SetupWrapper'));
const OrganizationName = lazy(() => import('../../Pages/Setup/OrganizationName'));
const TypeOfWork = lazy(() => import('../../Pages/Setup/TypeOfWork'));
const StartingPoint = lazy(() => import('../../Pages/Setup/StartingPoint'));
const ProjectName = lazy(() => import('../../Pages/Setup/ProjectName'));
const WorkPackages = lazy(() => import('../../Pages/Setup/WorkPackages'));
const Tasks = lazy(() => import('../../Pages/Setup/Tasks'));

export const setupRoutes: RouteObject[] = [
  {
    children: [
      {
        element: <OrganizationName />,
        path: organizationNameRoute,
      },
      {
        element: <TypeOfWork />,
        path: typeOfWorkRoute,
      },
      {
        element: <StartingPoint />,
        path: startingPointRoute,
      },
      {
        element: <ProjectName />,
        path: projectNameRoute,
      },
      {
        element: <WorkPackages />,
        path: workPackagesRoute,
      },
      {
        element: <Tasks />,
        path: tasksRoute,
      },
      {path: '*', element: <Navigate to={organizationNameRoute} replace />},
    ],
    element: <SetupWrapper />,
    path: `${setupRoute}/*`,
  },
];
