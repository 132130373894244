import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {Notification} from '@octaved/store/src/Notifications';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';

export type DependencyType = 'logical' | 'planning';

function getAllSuccessors(
  id: Uuid,
  getNode: (id: Uuid) => NodeEntity | undefined,
  dependencyType: DependencyType,
): Uuid[] {
  const node = getNode(id);
  if (node) {
    const nodeSuccessors = dependencyType === 'planning' ? node.planningSuccessors : node.planningLogicalSuccessors;
    const result = [...nodeSuccessors];
    for (const successorId of nodeSuccessors) {
      result.push(...getAllSuccessors(successorId, getNode, dependencyType));
    }
    return result;
  }
  return [];
}

function getAllPredecessors(
  id: Uuid,
  getNode: (id: Uuid) => NodeEntity | undefined,
  predecessors: Uuid[] | null = null,
  dependencyType: DependencyType,
): Uuid[] {
  const node = getNode(id);
  if (node) {
    const nodePredecessors =
      dependencyType === 'planning' ? node.planningPredecessors : node.planningLogicalPredecessors;
    const result = predecessors ? [] : [...nodePredecessors];
    for (const successorId of predecessors || nodePredecessors) {
      result.push(...getAllPredecessors(successorId, getNode, null, dependencyType));
    }
    return result;
  }
  return [];
}

export const validateCircularDependenciesSelector = createSelector(
  getNodeSelector,
  (getNode) =>
    (successorId: Uuid, dependencPredecessorIds: Uuid[], dependencyType: DependencyType): Notification[] => {
      const successorIds = new Set([
        ...getAllSuccessors(successorId, getNode, dependencyType),
        ...getAllPredecessors(successorId, getNode, dependencPredecessorIds, dependencyType),
      ]);
      for (const predecessorId of dependencPredecessorIds) {
        if (successorIds.has(predecessorId)) {
          const pid = getNode(predecessorId);
          return [
            {
              field: `planningDependency_${predecessorId}`,
              message: {
                i18nKey: 'pages:projects.inspector.manage.planning.predecessorIsSuccessor',
                values: {predecessorName: pid?.name || ''},
              },
            },
          ];
        }
      }
      return [];
    },
);
