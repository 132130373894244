import {Uuid} from '@octaved/typescript/src/lib';
import {FlexItem, HStack} from '@octaved/ui';
import {ReactElement, lazy, useState} from 'react';
import {useSelector} from 'react-redux';
import FullScreenDrawerWithTrigger from '../../../../Components/Drawer/FullScreenDrawerWithTrigger';
import PageLockWrapper from '../../../../Components/PageLock/PageLockWrapper';
import {TimePeriod} from '../../../../Modules/Filter/TimePeriod';
import {useTimeTrackingSelector} from '../../../../Modules/Selectors/SettingSelectors';
import DefaultTrigger from './DefaultTrigger';
import MainContent from './MainContent';

interface Props {
  nodeId: Uuid;
  initialPeriod?: TimePeriod;
  workPackageIds?: Uuid[]; //limits the display to those only
  Trigger?: ReactElement;
}

const Inspector = lazy(() => import('./Inspector'));

export default function ViewNodeTimeTracking({
  nodeId,
  initialPeriod,
  workPackageIds,
  Trigger = <DefaultTrigger />,
}: Props): ReactElement | null {
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const [editTimeRecord, setEditTimeRecord] = useState<Uuid | null>(null);

  if (!useTimeTracking) {
    return null;
  }

  return (
    <FullScreenDrawerWithTrigger trigger={Trigger}>
      <HStack alignItems={'stretch'} overflow={'hidden'} height={'100%'} width={'100%'}>
        <FlexItem flexGrow={1} overflow={'hidden'} height={'100%'}>
          <PageLockWrapper>
            <MainContent
              nodeId={nodeId}
              initialPeriod={initialPeriod}
              workPackageIds={workPackageIds}
              selectedTimeRecordId={editTimeRecord}
              setSelectedTimeRecordId={setEditTimeRecord}
            />
          </PageLockWrapper>
        </FlexItem>
        <FlexItem flexShrink={0}>
          {editTimeRecord && <Inspector editTimeRecord={editTimeRecord} setEditTimeRecord={setEditTimeRecord} />}
        </FlexItem>
      </HStack>
    </FullScreenDrawerWithTrigger>
  );
}
