import {useCloseFullScreenDrawer} from '@octaved/flow/src/Components/Drawer/FullScreenDrawer';
import {useFormattedDateText} from '@octaved/flow/src/Hooks/FormattedDateText';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {ActionTrigger, IconButton, TWButton} from '@octaved/ui';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ArrowLeft, Plus} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
  getPlanningBaselineDisplayTimestampSelector,
  getPlanningBaselinesForNodeSelector,
  getSelectedBaselinesSelector,
} from '../../../Selectors/PlanningBaselineSelectors';
import BaselineSelector from './BaselineSelector';
import DeleteDialog from './DeleteDialog';
import Filter from './Filter';
import NewBaselineDialog from './NewBaselineDialog';
import RenameDialog from './RenameDialog';

export interface HeaderPopups {
  createBaseline?: true;
  renameBaseLine?: Uuid;
  deleteBaseline?: Uuid;
}

interface DrawerHeaderProps {
  rootNodeId: Uuid;
}

export default function DrawerHeader({rootNodeId}: DrawerHeaderProps): ReactElement {
  const {t} = useTranslation();
  const close = useCloseFullScreenDrawer();
  const [headerPopups, setHeaderPopups] = useState<HeaderPopups>({});
  const baselines = useSelector((s: FlowState) => getPlanningBaselinesForNodeSelector(s)(rootNodeId));
  const selectedBaselines = useSelector((s: FlowState) => getSelectedBaselinesSelector(s)(rootNodeId));
  const timestamp = useSelector((s: FlowState) =>
    getPlanningBaselineDisplayTimestampSelector(s)(rootNodeId, selectedBaselines[0]?.id),
  );
  const hasBaselines = baselines.length > 0;
  const formatDate = useFormattedDateText();

  return (
    <div
      className={
        'relative z-[7] flex h-16 flex-shrink-0 items-center justify-between border-b border-b-slate-300 bg-slate-50 px-6'
      }
    >
      <div className={'absolute inset-0 flex items-center justify-center'}>
        {t('pages:planning.baselineComparison.title')}
      </div>

      <div className={'relative z-[1] flex items-center gap-x-5'}>
        <TWButton onClick={close} variant={'outline'} withIcon size={'md'} colorScheme={'slate'} className={'py-0'}>
          <ArrowLeft className={'-mt-px h-4 w-4'} strokeWidth={1.5} />
          <div className={'leading-none'}>{t('general:back')}</div>
        </TWButton>
        <Filter rootNodeId={rootNodeId} />
      </div>
      <div className={'relative z-[1]'}>
        {hasBaselines && (
          <BaselineSelector setHeaderPopups={setHeaderPopups} rootNodeId={rootNodeId}>
            <div>
              <ActionTrigger
                className={'max-w-80'}
                label={t('pages:planning.baselineComparison.baseline')}
                labelValue={t('pages:planning.baselineComparison.selected', {
                  baselineName:
                    selectedBaselines[0]?.name || formatDate(timestamp ? isoDateTimeToIsoFormat(timestamp) : null),
                  count: selectedBaselines.length,
                })}
              />
            </div>
          </BaselineSelector>
        )}

        {!hasBaselines && (
          <IconButton
            icon={<Plus />}
            label={'pages:planning.baselineComparison.addBaseline'}
            onClick={() => setHeaderPopups({createBaseline: true})}
          />
        )}
      </div>

      {headerPopups.createBaseline && <NewBaselineDialog rootNodeId={rootNodeId} close={() => setHeaderPopups({})} />}
      {headerPopups.deleteBaseline && (
        <DeleteDialog
          baselineId={headerPopups.deleteBaseline}
          rootNodeId={rootNodeId}
          close={() => setHeaderPopups({})}
        />
      )}
      {headerPopups.renameBaseLine && (
        <RenameDialog
          baselineId={headerPopups.renameBaseLine}
          rootNodeId={rootNodeId}
          close={() => setHeaderPopups({})}
        />
      )}
    </div>
  );
}
