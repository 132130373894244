import PlanningDateInspectorContext from '@octaved/planning/src/Inspector/PlanningDateInspectorContext';
import {lazy} from 'react';
import {Navigate} from 'react-router';
import {TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../Dialogs';
import {planningDateRoute} from './PlanningDateInspectorRoute';
import {getWrappedStandardInspectorRoutes} from './StandardInspector';

const PlanningDate = lazy(() => import('@octaved/planning/src/Pages/Gantt/Components/Inspector/PlanningDate'));

//#region <inspector tabs>

const inspectorTabConfig: TabRecords = {
  planningDate: [
    {
      path: planningDateRoute,
      token: '',
    },
  ],
};
//#endregion

export const planningDateInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <PlanningDate />
        </InspectorContextLoadingWrapper>
      ),
      path: planningDateRoute,
    },
    {
      element: <Navigate to={planningDateRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: PlanningDateInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
