import {IconButton} from '@octaved/ui';
import {CheckCircle} from 'lucide-react';
import {ReactElement, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, TextArea} from 'semantic-ui-react';
import {TaskPatchData} from '../../../../../../../EntityInterfaces/Task';
import RemainingChars from '../../../../../../RemainingChars';
import {useTaskListReadonlyContext} from '../../../../../TaskListReadonlyContext';

interface Props {
  description: string;
  patch: (data: Partial<TaskPatchData>) => void;
  onFinish: () => void;
}

export default function SimpleNote({description, patch, onFinish}: Props): ReactElement {
  const {t} = useTranslation();
  const [desc, setDesc] = useState(description);
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();

  const onSave = useCallback(() => {
    if (!readonlyOrNotManageable) {
      patch({description: desc});
    }
    onFinish();
  }, [desc, patch, onFinish, readonlyOrNotManageable]);

  return (
    <div className={'flex w-full flex-col items-stretch gap-y-3'}>
      <div>
        <Form size={'small'}>
          <TextArea
            rows={32}
            disabled={readonlyOrNotManageable}
            placeholder={t('taskList:note.addNote')}
            autoFocus
            value={desc}
            onChange={readonlyOrNotManageable ? undefined : (_e, {value}) => setDesc(value as string)}
            maxLength={5000}
          />
        </Form>
        <RemainingChars maxChars={5000} currentChars={desc.length} />
      </div>
      <div className={'flex justify-center pb-2'}>
        <IconButton icon={<CheckCircle strokeWidth={'2'} />} label={'general:save'} onClick={onSave} />
      </div>
    </div>
  );
}
