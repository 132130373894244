import BellSvg from '@fortawesome/fontawesome-pro/svgs/solid/bell.svg';
import {useUnreadInboxCount} from '@octaved/inbox/src/Inbox';
import {CircleIconButton, Tooltip} from '@octaved/ui';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {ReactElement, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import css from 'styled-jsx/css';
import {showInboxSelector} from '../../../Modules/Selectors/UiSelectors';
import {showInbox} from '../../../Modules/Ui';
import {FlowStyleBuilder, useFlowStyle} from '../../../Styles/StyleContext';

//#region <styles>
/*language=SCSS*/
const getStyle: FlowStyleBuilder = ({
  COLORS: {
    DRAWER: {INBOX},
  },
}) => css.resolve`
  .inboxItems {
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    background-color: ${INBOX.NOTIFICATION_BACKGROUND};
    color: ${INBOX.NOTIFICATION_FONT};
    height: 14px;
    min-width: 14px;
    padding: 1px;
    border-radius: 6px;
    z-index: 2;
    top: -3px;
    right: -5px;
    text-align: center;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
  }
`;
//#endregion

const animations = {
  hidden: {opacity: 0, scale: 0},
  visible: {opacity: 1, scale: 1},
};
export default function InboxButton(): ReactElement {
  const {className, styles} = useFlowStyle(getStyle);
  const count = useUnreadInboxCount();
  const visible = useSelector(showInboxSelector);
  const dispatch = useDispatch();
  const toggleInbox = useCallback(() => {
    dispatch(showInbox(!visible));
  }, [dispatch, visible]);

  return (
    <Tooltip toolTipTranslation={'tooltips:components.pageHeader.quickAccessButtonInbox'} position={'bottom right'}>
      <div className={'inboxButton'} onClick={toggleInbox}>
        <CircleIconButton iconColor={'brightBlue'}>
          <BellSvg />
        </CircleIconButton>
        <motion.div
          className={classNames(className, 'inboxItems')}
          animate={count !== undefined && count > 0 ? 'visible' : 'hidden'}
          initial={'hidden'}
          variants={animations}
        >
          {count}
        </motion.div>

        {/*#region styles*/}
        {/*language=SCSS*/}
        <style jsx>{`
          .inboxButton {
            position: relative;
          }
        `}</style>
        {styles}
        {/*#endregion*/}
      </div>
    </Tooltip>
  );
}
