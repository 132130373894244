import {CSSProperties, forwardRef, ReactElement, ReactNode, Ref} from 'react';

export interface StackProps {
  alignItems?: CSSProperties['alignItems'];
  children: ReactNode;
  columnSpacing?: CSSProperties['columnGap'];
  direction?: CSSProperties['flexDirection'];
  flexWrap?: CSSProperties['flexWrap'];
  height?: CSSProperties['height'];
  justifyContent?: CSSProperties['justifyContent'];
  maxW?: CSSProperties['maxWidth'];
  minH?: CSSProperties['minHeight'];
  minW?: CSSProperties['minWidth'];
  overflow?: CSSProperties['overflow'];
  position?: CSSProperties['position'];
  rowSpacing?: CSSProperties['rowGap'];
  spacing?: CSSProperties['gap'];
  width?: CSSProperties['width'];
}

const Stack = forwardRef(function Stack(
  {
    alignItems = 'flex-start',
    children,
    columnSpacing,
    direction = 'column',
    flexWrap = 'nowrap',
    height = 'auto',
    justifyContent = 'flex-start',
    maxW = 'auto',
    minW = 'auto',
    minH = 'auto',
    overflow = 'visible',
    position = 'static',
    rowSpacing,
    spacing = '0px',
    width = 'auto',
  }: StackProps,
  ref: Ref<HTMLDivElement>,
): ReactElement {
  return (
    <div className={'stack'} ref={ref}>
      {children}

      {/*language=SCSS*/}
      <style jsx>{`
        .stack {
          align-items: ${alignItems};
          column-gap: ${columnSpacing || spacing};
          display: flex;
          flex-direction: ${direction};
          flex-wrap: ${flexWrap};
          height: ${height};
          justify-content: ${justifyContent};
          min-height: ${minH};
          overflow: ${overflow};
          position: ${position};
          row-gap: ${rowSpacing || spacing};
          width: ${width};
          max-width: ${maxW};
          min-width: ${minW};
        }
      `}</style>
    </div>
  );
});
Stack.displayName = 'Stack';
export {Stack};
