import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import SimpleRadio from '@octaved/semantic-ui/src/SimpleRadio';
import {Uuid} from '@octaved/typescript/src/lib';
import {Dispatch, ReactElement, SetStateAction, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {useLoadPlanningDates} from '../../../Modules/PlanningDates';
import {setDependency} from '../../../Modules/PlanningDependencies';
import {setNodeToAbsolut, setNodeToRelative, setNodeToUnplanned} from '../../../Modules/PlanningOptions';
import DependencySelectorModal from './DependencySelectorModal';
import {ModalDialog, showModal} from './Messages';
import {PlanningType} from './PlanningType';
import SwitchPlanningTypeModal from './SwitchPlanningTypeModal';

function useHasUnplannedAncestor(node: PlanningRealizationNode): boolean {
  const nodeAncestor = useSelector((s: FlowState) => {
    const ancestry = getNodeAncestrySelector(s)(node.id);
    if (ancestry.subWorkPackage) {
      return ancestry.subWorkPackage;
    } else if (ancestry.workPackage) {
      return ancestry.workPackage;
    }
    return null;
  });
  useLoadPlanningDates(nodeAncestor ? [nodeAncestor.id] : []);
  return nodeAncestor?.planningDates.length === 0;
}

interface PlanningOption {
  readonly: boolean;
  node: PlanningRealizationNode;
  planningType: PlanningType;
  setPlanningType: Dispatch<SetStateAction<PlanningType>>;
}

export default function PlanningOption({readonly, planningType, setPlanningType, node}: PlanningOption): ReactElement {
  const dispatch = useDispatch();
  const [dialogStatus, setDialogStatus] = useState<{
    dialogStatus: ModalDialog;
    nextType: PlanningType | null;
    action: (() => void) | null;
  }>({dialogStatus: ModalDialog.none, nextType: null, action: null});
  const isPlannedRelativeToWorkPackage = useSelector((s: FlowState) => {
    if (isSubWorkPackage(node)) {
      return true;
    }
    if (isTask(node)) {
      const ancestry = getNodeAncestrySelector(s)(node.id);
      return ancestry.subWorkPackage === null;
    }
    return false;
  });
  const hasUnplannedAncestor = useHasUnplannedAncestor(node);

  function resetDialogStatus(): void {
    setDialogStatus({dialogStatus: ModalDialog.none, nextType: null, action: null});
  }

  return (
    <div className={'flex flex-col gap-y-1'}>
      {dialogStatus.dialogStatus === ModalDialog.switchPlanningType && (
        <SwitchPlanningTypeModal
          nodeId={node.id}
          deleteType={dialogStatus.nextType === PlanningType.none ? 'timeBased' : undefined}
          onConfirm={() => {
            dialogStatus.action?.();
            resetDialogStatus();
          }}
          onCancel={resetDialogStatus}
        />
      )}
      {dialogStatus.dialogStatus === ModalDialog.selectDependency && (
        <DependencySelectorModal
          node={node}
          onConfirm={(predecessor: Uuid) => {
            const nextType = PlanningType.dependency;
            dispatch(setDependency(node.id, predecessor));
            setPlanningType(nextType);
            resetDialogStatus();
          }}
          onCancel={resetDialogStatus}
          planningType={planningType}
        />
      )}
      <SimpleRadio
        disabled={readonly}
        checked={planningType === PlanningType.none}
        label={
          isWorkPackage(node)
            ? 'pages:projects.inspector.manage.planning.noPlanning'
            : 'pages:projects.inspector.manage.planning.noOwnPlanning'
        }
        setChecked={() => {
          const nextType = PlanningType.none;
          const dialogStatus = showModal(planningType, nextType, node);
          const action = (): void => {
            setPlanningType(nextType);
            dispatch(setNodeToUnplanned(node.id));
          };

          if (dialogStatus === ModalDialog.none) {
            action();
          } else {
            setDialogStatus({dialogStatus, nextType, action});
          }
        }}
      />
      {(isTask(node) || isSubWorkPackage(node)) && (
        <SimpleRadio
          disabled={readonly || hasUnplannedAncestor}
          checked={planningType === PlanningType.relativeToParent}
          label={
            isPlannedRelativeToWorkPackage
              ? 'pages:projects.inspector.manage.planning.planningRelativeToWorkPackage'
              : 'pages:projects.inspector.manage.planning.planningRelativeToSubWorkPackage'
          }
          setChecked={() => {
            const nextType = PlanningType.relativeToParent;
            const dialogStatus = showModal(planningType, nextType, node);
            const action = (): void => {
              setPlanningType(nextType);
              dispatch(setNodeToRelative(node.id));
            };

            if (dialogStatus === ModalDialog.none) {
              if (node.planningDates.length > 0) {
                action();
              } else {
                setPlanningType(nextType);
              }
            } else {
              setDialogStatus({dialogStatus, nextType, action});
            }
          }}
        />
      )}
      <SimpleRadio
        disabled={readonly}
        checked={planningType === PlanningType.date}
        label={'pages:projects.inspector.manage.planning.planningByPeriod'}
        setChecked={() => {
          const nextType = PlanningType.date;
          const dialogStatus = showModal(planningType, nextType, node);
          const action = (): void => {
            setPlanningType(nextType);
            dispatch(setNodeToAbsolut(node.id));
          };

          if (dialogStatus === ModalDialog.none) {
            if (node.planningDates.length > 0) {
              action();
            } else {
              setPlanningType(nextType);
            }
          } else {
            setDialogStatus({dialogStatus, nextType, action});
          }
        }}
      />
      <SimpleRadio
        disabled={readonly}
        checked={planningType === PlanningType.dependency}
        label={
          isTask(node)
            ? 'taskList:taskPlanning.dependentOnOtherTaskOption'
            : 'pages:projects.inspector.manage.planning.byDependency'
        }
        setChecked={() => {
          const nextType = PlanningType.dependency;
          const dialogStatus = ModalDialog.selectDependency;
          setDialogStatus({dialogStatus, nextType, action: null});
        }}
      />
    </div>
  );
}
