import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {Tooltip} from '@octaved/ui';
import {memo, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {getTranslatedRolesSelector} from '../../Modules/Selectors/RoleSelectors';

interface Props {
  children: ReactElement;
  roleId?: Uuid | null;
}

export default memo(function TooltipWrapper({children, roleId}: Props): ReactElement {
  const roles = useSelector(getTranslatedRolesSelector)[EnumFlowNodeRoleType.VALUE_PROJECT];

  if (roleId) {
    return <Tooltip toolTipTranslation={roles[roleId]?.name || ''}>{children}</Tooltip>;
  }

  return <>{children}</>;
});
