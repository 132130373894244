import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Trash} from 'lucide-react';
import {memo, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, useResolvedPath, useSearchParams} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import {useHasBillingsAttached} from '../../Billing/HasBillingsAttached';
import ConfirmDialog from '../../Components/Dialog/ConfirmDialog';
import {deleteGroup} from '../../Modules/Groups';
import {usePidOrSubWorkPackage} from '../../Modules/Hooks/Nodes';
import {deleteProject} from '../../Modules/Projects';
import {getAllDescendantIdsSelector} from '../../Modules/Selectors/NodeTreeSelectors';
import {deleteSubWorkPackage} from '../../Modules/SubWorkPackages';
import {deleteWorkPackage} from '../../Modules/WorkPackages';
import {isTimeTrackingNode} from '../../Node/NodeIdentifiers';
import {closeDialogRoute} from '../../Routing/Routes/DialogRoutes';
import {editProjectsRoute} from '../../Routing/Routes/Routes';

const titleTransToken = {
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'dialogs:deletePid.deleteSubWorkpackageTitle',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'dialogs:deletePid.deleteWorkpackageTitle',
  [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:deletePid.deleteProjectTitle',
  [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:deletePid.deleteGroupTitle',
};
const contentTransToken = {
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'dialogs:deletePid.deleteSubWorkpackage',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'dialogs:deletePid.deleteWorkpackage',
  [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:deletePid.deleteProject',
  [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:deletePid.deleteGroup',
};
const confirmContentTransToken = {
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'dialogs:deletePid.deleteSubWorkpackageConfirm',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'dialogs:deletePid.deleteWorkpackageConfirm',
  [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:deletePid.deleteProjectConfirm',
  [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:deletePid.deleteGroupConfirm',
};

const hasBillingToken = {
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'dialogs:deletePid.deleteSubWorkpackageHasBilling',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'dialogs:deletePid.deleteWorkpackageHasBilling',
  [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:deletePid.deleteProjectHasBilling',
  [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:deletePid.deleteGroupHasBilling',
};

export default memo(function DeletePid(): ReactElement {
  const dispatch = useDispatch();
  const {pidNodeId} = useParams<'pidNodeId'>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resolvedPath = useResolvedPath(closeDialogRoute);
  const pid = usePidOrSubWorkPackage(pidNodeId);
  const getAllDescendantIds = useSelector(getAllDescendantIdsSelector);
  const {t} = useTranslation();

  const {hasLoadedOnce: hasSearchLoaded, hasBilling} = useHasBillingsAttached(pid);
  const pidType = pid?.nodeType;
  const isLoading = !pid || !pidType || !hasSearchLoaded;

  let confirmDeletion = getAllDescendantIds(pidNodeId!).size > 0;
  if (!confirmDeletion && isTimeTrackingNode(pid)) {
    // check for workpackage if it has no children if it has time timerecords
    // if there is a time record then there should be a calculate time
    if (pid.trackedMinutes.recorded + pid.trackedMinutes.recordedJourney > 0) {
      confirmDeletion = true;
    }
  }
  if (pid?.planningSuccessors.length) {
    //check if there are planning dependencies
    confirmDeletion = true;
  }

  return (
    <ConfirmDialog
      noConfirm={isLoading || hasBilling}
      title={pidType && titleTransToken[pidType]}
      buttonLabel={'general:delete'}
      buttonIcon={<Trash />}
      buttonColorScheme={'delete'}
      submitCallback={() => {
        if (pid) {
          dispatch(
            {
              [EnumFlowNodeType.VALUE_PROJECT]: deleteProject,
              [EnumFlowNodeType.VALUE_GROUP]: deleteGroup,
              [EnumFlowNodeType.VALUE_WORK_PACKAGE]: deleteWorkPackage,
              [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: deleteSubWorkPackage,
            }[pid.nodeType](pid.id),
          );
          //close inspector if "open"
          let newPath = resolvedPath.pathname.split(`/${pid.id}`)[0];
          //pid/sub work package deleted inside node details
          if (newPath.startsWith('/node/')) {
            if (searchParams.get('lastPage')) {
              newPath = searchParams.get('lastPage')!;
            } else {
              newPath = `/${editProjectsRoute}`;
            }
          }
          navigate(newPath);
        }
      }}
      confirmationText={!isLoading && !hasBilling && confirmDeletion ? t('dialogs:deletePid.deleteNow') : ''}
    >
      <Loader active={isLoading} />
      {!isLoading && (
        <span>
          {hasBilling &&
            t(hasBillingToken[pidType], {
              name: pid.name,
            })}

          {!hasBilling && (
            <>
              {!confirmDeletion &&
                t(contentTransToken[pidType], {
                  name: pid.name,
                })}
              {confirmDeletion &&
                t(confirmContentTransToken[pidType], {
                  confirm: t('dialogs:deletePid.deleteNow'),
                  name: pid.name,
                })}
            </>
          )}
        </span>
      )}
    </ConfirmDialog>
  );
});
