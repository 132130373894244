/* eslint-disable import/no-relative-packages */

// It is VERY important to import locally from "../node_modules/dayjs" here! We need the separate not-hoisted module!
import * as mom from 'moment';
import dayjs from '../node_modules/dayjs';
import advancedFormat from '../node_modules/dayjs/plugin/advancedFormat';
import badMutable from '../node_modules/dayjs/plugin/badMutable';
import customParseFormat from '../node_modules/dayjs/plugin/customParseFormat';
import isBetween from '../node_modules/dayjs/plugin/isBetween';
import isMoment from '../node_modules/dayjs/plugin/isMoment';
import isoWeek from '../node_modules/dayjs/plugin/isoWeek';
import isSameOrAfter from '../node_modules/dayjs/plugin/isSameOrAfter';
import isSameOrBefore from '../node_modules/dayjs/plugin/isSameOrBefore';
import localeData from '../node_modules/dayjs/plugin/localeData';
import quarterOfYear from '../node_modules/dayjs/plugin/quarterOfYear';
import timezone from '../node_modules/dayjs/plugin/timezone';
import utc from '../node_modules/dayjs/plugin/utc';
import weekday from '../node_modules/dayjs/plugin/weekday';
import weekOfYear from '../node_modules/dayjs/plugin/weekOfYear';
import weekYear from '../node_modules/dayjs/plugin/weekYear';

dayjs.extend(advancedFormat);
dayjs.extend(badMutable);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isMoment);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(quarterOfYear);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);

export default dayjs;

declare module 'dayjs' {
  export type Moment = mom.Moment;
}
