import Link from '@octaved/flow/src/Components/Link';
import {SearchHighlightContext} from '@octaved/flow/src/Components/Search/SearchHighlight';
import {ActionTrigger, cn, Popup, TWInput} from '@octaved/ui';
import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {Check, LucideIcon} from 'lucide-react';
import {ComponentType, ReactElement, ReactNode, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface TWSearchDropdownFilterTranslations {
  actionTriggerLabel: string;
  actionTriggerEmptyLabel: string;
  actionTriggerSelectedLabel: string;
  searchLabel: string;
  clearSelectionLinkLabel: string;
  noItemsFoundPlaceholder: string;
  notItemsAvailablePlaceholder: string;
}

export interface TWSearchDropdownFilterProps<T> {
  actionTrigger?: ReactNode;
  actionTriggerIcon?: LucideIcon;
  additionalFilterActions?: ReactNode;
  additionalFilterInfo?: ReactNode;
  clearSelection?: () => void;
  className?: string;
  isActive?: boolean;
  isActiveFN: (data: T) => boolean;
  isLoading?: boolean;
  itemRenderer: ComponentType<{item: T}>;
  items: ReadonlyArray<T>;
  labelItemRenderer?: (item: T) => ReactNode;
  searchFilterFN?: (data: T, search: string) => boolean;
  selectionCount: number;
  toggleSelection: (data: T, options: {close: () => void}) => void;
  translations: TWSearchDropdownFilterTranslations;
  triggerClass?: string;
  upwards?: boolean;
}

export function TWSearchDropdownFilter<T>({
  actionTrigger,
  actionTriggerIcon,
  additionalFilterActions,
  additionalFilterInfo,
  className,
  clearSelection,
  isActive,
  isActiveFN,
  isLoading,
  itemRenderer: ItemRenderer,
  items,
  labelItemRenderer,
  searchFilterFN,
  selectionCount,
  toggleSelection,
  translations,
  triggerClass,
  upwards,
}: TWSearchDropdownFilterProps<T>): ReactElement {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const {t} = useTranslation();

  const filteredItems = useMemo(
    () => (searchFilterFN ? items.filter((item) => searchFilterFN(item, search)) : items),
    [items, search, searchFilterFN],
  );

  const hasSelection = selectionCount > 0;
  const singleSelection = selectionCount === 1 && filteredItems.find((item) => isActiveFN(item));

  return (
    <Popup
      additionalClassName={className}
      position={upwards ? 'top left' : undefined}
      offset={upwards ? [0, -6] : undefined}
      basic
      open={open}
      on={'click'}
      setOpen={(open: boolean) => {
        if (!open) {
          setSearch('');
        }
        setOpen(open);
      }}
      trigger={
        <div className={triggerClass} onClick={() => setOpen(true)}>
          {actionTrigger}
          {!actionTrigger && (
            <ActionTrigger
              isActive={isActive}
              icon={actionTriggerIcon}
              label={t(translations.actionTriggerLabel)}
              labelValue={
                <>
                  {!hasSelection && <span className={'text-slate-400'}>-</span>}
                  {selectionCount === 1 && singleSelection && labelItemRenderer && labelItemRenderer(singleSelection)}
                  {hasSelection &&
                    selectionCount > 1 &&
                    t(translations.actionTriggerSelectedLabel, {count: selectionCount})}
                </>
              }
            />
          )}
        </div>
      }
    >
      <div className={'flex w-80 flex-col'}>
        {!upwards &&
          (searchFilterFN || additionalFilterActions || (hasSelection && clearSelection) || additionalFilterInfo) && (
            <div className={'flex flex-col gap-y-2 border-b border-b-slate-200 bg-slate-100 p-3 pb-2'}>
              {(searchFilterFN || additionalFilterActions) && (
                <div className={'flex w-full items-center gap-x-2'}>
                  {searchFilterFN && (
                    <TWInput
                      autoFocus
                      className={'w-full rounded-none border-none'}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={t(translations.searchLabel)}
                      type={'search'}
                    />
                  )}
                  {additionalFilterActions}
                </div>
              )}
              {hasSelection && clearSelection && (
                <div className={'flex justify-end text-sm'}>
                  <Link
                    onClick={() => {
                      clearSelection();
                      setOpen(false);
                    }}
                  >
                    {t(translations.clearSelectionLinkLabel)}
                  </Link>
                </div>
              )}
              {additionalFilterInfo}
            </div>
          )}

        <SearchHighlightContext searchTerm={search}>
          <div className={'flex h-fit max-h-filterPopup flex-col gap-y-0 overflow-y-auto'}>
            {isLoading && (
              <div className={'absolute inset-0 flex items-center justify-center bg-white/50'}>
                <AppLoader />
              </div>
            )}

            {filteredItems.map((item, idx) => (
              <div
                key={idx}
                className={
                  'flex h-filterPopupItem cursor-pointer items-center gap-x-2 rounded-md px-3 py-1 transition-all hover:bg-slate-50 active:scale-95'
                }
                onClick={() => toggleSelection(item, {close: () => setOpen(false)})}
              >
                {hasSelection && <Check className={cn('h-5 w-5 text-white', isActiveFN(item) && 'text-green-600')} />}
                <ItemRenderer item={item} />
              </div>
            ))}

            {!isLoading && filteredItems.length === 0 && search.length > 0 && (
              <div className={'flex h-full items-center justify-center p-10 text-center text-sm text-slate-500'}>
                {t(translations.noItemsFoundPlaceholder)}
              </div>
            )}

            {!isLoading && filteredItems.length === 0 && search.length === 0 && (
              <div className={'flex h-full items-center justify-center p-10 text-center text-sm text-slate-500'}>
                {t(translations.notItemsAvailablePlaceholder)}
              </div>
            )}
          </div>
        </SearchHighlightContext>

        {upwards &&
          (searchFilterFN || additionalFilterActions || (hasSelection && clearSelection) || additionalFilterInfo) && (
            <div className={'flex flex-col gap-y-2 border-t border-t-slate-200 bg-slate-100 p-3 pb-2'}>
              {(searchFilterFN || additionalFilterActions) && (
                <div className={'flex w-full items-center gap-x-2'}>
                  {searchFilterFN && (
                    <TWInput
                      autoFocus
                      className={'w-full rounded-none border-none'}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={t(translations.searchLabel)}
                      type={'search'}
                    />
                  )}
                  {additionalFilterActions}
                </div>
              )}
              {hasSelection && clearSelection && (
                <div className={'flex justify-end text-sm'}>
                  <Link
                    onClick={() => {
                      clearSelection();
                      setOpen(false);
                    }}
                  >
                    {t(translations.clearSelectionLinkLabel)}
                  </Link>
                </div>
              )}
              {additionalFilterInfo}
            </div>
          )}
      </div>
    </Popup>
  );
}
