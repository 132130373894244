import {isEqual} from 'lodash';
import {PlanningPatchData} from '../../Modules/Planning';
import UpdatePlanningState from './UpdatePlanningState';

export function updatePlanningDependencies(state: UpdatePlanningState, updateData: PlanningPatchData): void {
  if (updateData.dependencies) {
    const node = state.getNode(updateData.nodeId);

    if (node) {
      const planningPredecessors = updateData.dependencies.map(({predecessor}) => predecessor);
      const removedIds = node.planningPredecessors.filter((id) => !planningPredecessors.includes(id));

      for (const idToRemove of removedIds) {
        const currentNode = state.getNode(idToRemove);
        if (currentNode && currentNode.planningSuccessors.includes(updateData.nodeId)) {
          state.setNode({
            ...currentNode,
            planningSuccessors: currentNode.planningSuccessors.filter((id) => id !== updateData.nodeId),
          });
        }
        state.removedPlanningDependency(updateData.nodeId, idToRemove);
      }

      for (const dependency of updateData.dependencies) {
        const oldDependency = state.getPlanningDependency(updateData.nodeId, dependency.predecessor);
        const updatedDependency = {
          ...dependency,
          successor: updateData.nodeId,
        };
        if (!isEqual(updatedDependency, oldDependency)) {
          state.setPlanningDependency({
            ...dependency,
            successor: updateData.nodeId,
          });
        }
        const predecessorNode = state.getNode(dependency.predecessor);
        if (predecessorNode && !predecessorNode.planningSuccessors.includes(updateData.nodeId)) {
          state.setNode({
            ...predecessorNode,
            planningSuccessors: [...predecessorNode.planningSuccessors, updateData.nodeId],
          });
        }
      }
      if (!isEqual(planningPredecessors, node.planningPredecessors)) {
        state.setNode({
          ...node,
          planningPredecessors,
        });
      }
    }
  }
}
