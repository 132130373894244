import {Group} from 'konva/lib/Group';
import {throttle} from 'lodash';
import {RootContainer, RootContainerProps} from '../../../RootContainer';
import {GanttContext} from '../../Context/GanttContext';
import {CellRendererProps} from '../BaseRow';

export interface CellProps extends RootContainerProps<GanttContext> {
  padding: number;
  imageSize: number;
  textLineOffset: number;
  menuRoot: Group | null;
  height: number;
}

export abstract class Cell extends RootContainer<GanttContext> {
  protected readonly padding: number;
  protected imageSize: number;
  protected readonly textLineOffset: number;
  protected readonly height: number;

  protected readonly menuRoot: Group | null;

  protected renderProps: CellRendererProps = {isVisble: false, x: 0, width: 0, columnName: '', menuRoot: null};

  protected isFinished = false;

  constructor(props: CellProps) {
    super(props);
    this.padding = props.padding;
    this.imageSize = props.imageSize;
    this.textLineOffset = props.textLineOffset;
    this.menuRoot = props.menuRoot;
    this.height = props.height;
  }

  init(): void {
    // impl in sub classes
  }

  update(props: CellRendererProps): void {
    this.renderProps = props;
    this.render();
    //force redraw
    this.render.flush();
  }

  render = throttle((): void => {
    this.doRender();
  }, 100);

  protected abstract doRender(): void;

  protected getFontColor(): string {
    return this.isFinished ? '#888888' : '#555';
  }

  protected getFontVariant(): string {
    return this.isFinished ? 'italic' : 'normal';
  }
}
