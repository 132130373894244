import {getNodeColorSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import Konva from 'konva';
import {Bar} from '../Bar';
import {BarPlugin} from './BarPlugin';

export class GroupedBarRenderPlugin extends BarPlugin {
  protected middleLine = new Konva.Line({stroke: '#7a7a7a'});
  protected leftTri = new Konva.Line({fill: '#7a7a7a', strokeWidth: 1, closed: true});
  protected rightTri = new Konva.Line({fill: '#7a7a7a', strokeWidth: 1, closed: true});

  init(bar: Bar): void {
    super.init(bar);

    this.root.add(this.middleLine);
    this.root.add(this.leftTri);
    this.root.add(this.rightTri);
    this.onBarUpdated();

    if (!this.bar.treeNode.isExpanded) {
      const color = `#${getNodeColorSelector(this.ctx.store.getState())(this.nodeId)}`;
      this.middleLine.stroke(color);
      this.leftTri.fill(color);
      this.rightTri.fill(color);
    }
  }

  onBarUpdated(): void {
    super.onBarUpdated();
    const triSize = this.bar.height / 2;
    this.middleLine.strokeWidth(Math.floor(triSize / 2));
    this.middleLine.points([0, 0, this.bar.width, 0]);
    this.leftTri.points([0, 0, 0, triSize, triSize, 0]);
    this.rightTri.points([this.bar.width, 0, this.bar.width, triSize, this.bar.width - triSize, 0]);
    const y = (this.bar.height - this.bar.barPaddingY * 2) / 2;
    this.root.y(y);
  }
}
