import {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {FlexItem, Label} from '@octaved/ui';
import {FormikProps} from 'formik/dist/types';
import {get} from 'lodash';
import {ReactElement} from 'react';
import {Input, StrictInputProps} from 'semantic-ui-react';

interface Props<FormikValues> extends StrictInputProps {
  label?: ComplexTranslatable;
  formik: FormikProps<FormikValues>;
  id: keyof FormikValues;
  width?: string;
}

export default function TextInput<FormikValues>({
  id,
  label,
  formik,
  width = '100%',
  ...inputProps
}: Props<FormikValues>): ReactElement {
  const hasError = Boolean(get(formik.touched, id) && get(formik.errors, id));
  const value = get(formik.values, id);

  return (
    <FlexItem width={width}>
      {label && <Label token={label} for={id.toString()} />}
      <Input id={id} name={id} onChange={formik.handleChange} value={value} error={hasError} fluid {...inputProps} />
    </FlexItem>
  );
}
