import {MinMaxPlanningDateInSubtree} from '@octaved/planning/src/EntityInterfaces/MinMaxPlanningDateInSubtrees';
import {DateStr} from '@octaved/typescript';
import {DatePicker, DatePickerProps, HelpTooltip, TWLabel} from '@octaved/ui';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {
  fromIsoDateTimeFormat,
  fromIsoFormat,
  isoDateTimeToIsoFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Radio} from 'semantic-ui-react';
import Toggle from '../../Components/Form/Toggle/Toggle';
import {Pid} from '../../EntityInterfaces/Pid';
import {CopyPidOptions} from '../../Modules/CopyPid';
import {getOrgWorkMinutesAtDateSelector} from '../../Modules/Selectors/WorkTimeSelectors';
import {removeErrorForField} from '../../Modules/Ui';
import CopyPlanningHelp from './CopyPlanningHelp';

const notPlannedTooltips: Record<Pid['nodeType'], string> = {
  group: 'dialogs:duplicatePid.copyPlanning.noPlanningToolTip.group',
  project: 'dialogs:duplicatePid.copyPlanning.noPlanningToolTip.project',
  workPackage: 'dialogs:duplicatePid.copyPlanning.noPlanningToolTip.workPackage',
};

const intros: Record<Pid['nodeType'], string> = {
  group: 'dialogs:duplicatePid.copyPlanning.intro.group',
  project: 'dialogs:duplicatePid.copyPlanning.intro.project',
  workPackage: 'dialogs:duplicatePid.copyPlanning.intro.workPackage',
};

const endDateLabels: Record<Pid['nodeType'], string> = {
  group: 'dialogs:duplicatePid.copyPlanning.radio.option.end.group',
  project: 'dialogs:duplicatePid.copyPlanning.radio.option.end.project',
  workPackage: 'dialogs:duplicatePid.copyPlanning.radio.option.end.workPackage',
};

interface Props {
  copyPidOptions: CopyPidOptions;
  initialCopyPlaning: boolean | undefined;
  minMaxPlanning: MinMaxPlanningDateInSubtree | null;
  sourceNode: Pid;
}

export default function CopyPlanning({
  copyPidOptions,
  initialCopyPlaning,
  minMaxPlanning,
  sourceNode,
}: Props): ReactElement {
  const {t} = useTranslation();
  const [copyPlanning, setCopyPlanning] = useState(initialCopyPlaning ?? false);
  const [alignment, setAlignment] = useState<'start' | 'end'>('start');
  const [targetDate, setTargetDate] = useState<DateStr | null>(
    minMaxPlanning ? isoDateTimeToIsoFormat(minMaxPlanning.plannedStart) : null,
  );
  const hasPlanning = !!minMaxPlanning;
  const dateFormat = useSelector(dateFormatSelector);

  useEffect(() => {
    if (minMaxPlanning?.plannedStart) {
      setTargetDate(isoDateTimeToIsoFormat(minMaxPlanning.plannedStart));
    }
  }, [minMaxPlanning?.plannedStart]);

  copyPidOptions.copyPlanning = hasPlanning && copyPlanning ? {alignment, targetDate} : undefined;

  const dispatch = useDispatch();
  useEffect(() => {
    if (targetDate) {
      dispatch(removeErrorForField(`copy_pid_${sourceNode.id}_copyPlanning_date`));
    }
  }, [dispatch, sourceNode.id, targetDate]);

  const getWorkingTimeAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const isDayBlocked = useCallback(
    (date: DateStr): boolean => getWorkingTimeAtDate(date).project <= 0,
    [getWorkingTimeAtDate],
  );

  let targetLabelOverride: DatePickerProps['triggerOverride'] = undefined;
  if (targetDate) {
    targetLabelOverride = {labelOverride: <>{fromIsoFormat(targetDate).format('DD.MM.YYYY')}</>};
  }

  return (
    <>
      <div className={'flex gap-x-1'}>
        <Toggle
          disabled={!hasPlanning}
          checked={hasPlanning && copyPlanning}
          setChecked={setCopyPlanning}
          label={'dialogs:duplicatePid.copyPlanning.toggle.label'}
        />
        {hasPlanning && (
          <HelpTooltip
            content={<CopyPlanningHelp />}
            position={'right center'}
            toolTipTranslation={''}
            inline
            wide={'very'}
          />
        )}
        {!hasPlanning && <HelpTooltip toolTipTranslation={notPlannedTooltips[sourceNode.nodeType]} inline />}
      </div>
      {minMaxPlanning && (
        <SlideToggleElement visible={copyPlanning}>
          <div className={'flex flex-col gap-y-6'}>
            <div className={'text-sm'}>{t(intros[sourceNode.nodeType])}</div>
            <div>
              <TWLabel>{t('dialogs:duplicatePid.copyPlanning.minMaxPlanningDate.label')}</TWLabel>
              <div>
                {t('dialogs:duplicatePid.copyPlanning.minMaxPlanningDate.value', {
                  endDate: fromIsoDateTimeFormat(minMaxPlanning.plannedEnd).format(dateFormat),
                  startDate: fromIsoDateTimeFormat(minMaxPlanning.plannedStart).format(dateFormat),
                })}
              </div>
            </div>
            <div>
              <TWLabel>{t('dialogs:duplicatePid.copyPlanning.radio.label')}</TWLabel>
              <div className={'flex items-center gap-x-4'}>
                <Radio
                  label={t('dialogs:duplicatePid.copyPlanning.radio.option.start')}
                  name={'copyPid-copyPlanning-alignment'}
                  checked={alignment === 'start'}
                  onChange={() => setAlignment('start')}
                />
                <Radio
                  label={t(endDateLabels[sourceNode.nodeType])}
                  name={'copyPid-copyPlanning-alignment'}
                  checked={alignment === 'end'}
                  onChange={() => setAlignment('end')}
                />
              </div>
            </div>
            <div>
              <TWLabel>
                {alignment === 'start'
                  ? t('dialogs:duplicatePid.copyPlanning.date.label.start')
                  : t('dialogs:duplicatePid.copyPlanning.date.label.end')}
              </TWLabel>
              <DatePicker
                date={targetDate}
                footerOverride={{canClear: false}}
                isDayBlocked={isDayBlocked}
                setDate={setTargetDate}
                strictBlockedDates
                triggerOverride={targetLabelOverride}
              />
            </div>
          </div>
        </SlideToggleElement>
      )}
    </>
  );
}
