import {FlowState} from '@octaved/flow/src/Modules/State';
import {BarRendererPlugin} from './BarRendererPlugin';

export class PlaceholderBarRenderPlugin extends BarRendererPlugin {
  protected getStyleColor(_state: FlowState): string {
    return '#64748b';
  }

  protected style(color: string): void {
    this.rect.fill(color);
  }
}
