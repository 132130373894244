import {FlowState} from '../../State';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['organizeBoardsTasks']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.organizeBoardsTasks);
}

export const organizeBoardsTasksLeftDrawerUiStateSelector = ui((state) => state.leftDrawer);
export const organizeBoardsTasksSelectedWorkPackageSelector = ui((state) => state.leftDrawer.selected);
export const organizeBoardsTasksSelectedKanbanBoardSelector = ui((state) => state.selectedKanbanBoard);
export const organizeBoardsTasksSelectedUnitsSelector = ui((state) => state.selectedUnits);
