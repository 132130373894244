import {getSortWeights} from '@octaved/utilities/src/Sorting';

export type NodeCompletionStatus = 'isCompleted' | 'isNotCompleted';

//Sorted by order
export const sortedNodeStatus = ['isOpen', 'isLocked', 'isApprovedForBilling', 'isClosed'] as const;
export const nodeStatusSortWeights = getSortWeights(sortedNodeStatus);

export type NodeStatus = (typeof sortedNodeStatus)[number];

//Sorted by order
export const sortedExtendedNodeStatus = [...sortedNodeStatus, 'isOffer', 'isTemplate', 'isArchived'] as const;

export type ExtendedNodeStatus = (typeof sortedExtendedNodeStatus)[number];

export const nodeStatusLabels = {
  isApprovedForBilling: 'general:nodeStatus.isApprovedForBilling.label',
  isArchived: 'general:nodeStatus.isArchived.label',
  isClosed: 'general:nodeStatus.isClosed.label',
  isCompleted: 'general:nodeStatus.isCompleted.label',
  isLocked: 'general:nodeStatus.isLocked.label',
  isNotCompleted: 'general:nodeStatus.isNotCompleted.label',
  isOffer: 'general:nodeStatus.isOffer.label',
  isOpen: 'general:nodeStatus.isOpen.label',
  isTemplate: 'general:nodeStatus.isTemplate.label',
} satisfies Record<NodeCompletionStatus | ExtendedNodeStatus, string>;
