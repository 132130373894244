import {initialLeftDrawerUiState, LeftDrawerUiState} from '../../../../../Modules/Ui/LeftDrawer';
import {MultiSelectionUiState, SingleSelectionUiState} from '../../Selection';
import {WpSearchScopeUiState} from './WpSearchScopes';

export type WorkPackageLeftDrawerUiState = LeftDrawerUiState & WpSearchScopeUiState;
export type WorkPackageSingleSelectLeftDrawerUiState = WorkPackageLeftDrawerUiState & SingleSelectionUiState;
export type WorkPackageMultiSelectLeftDrawerUiState = WorkPackageLeftDrawerUiState & MultiSelectionUiState;

export const workPackageLeftDrawerUiState: WorkPackageLeftDrawerUiState = {
  ...initialLeftDrawerUiState,
  searchScope: 'all',
};

export const workPackageSingleSelectLeftDrawerUiState: WorkPackageSingleSelectLeftDrawerUiState = {
  ...workPackageLeftDrawerUiState,
  selected: null,
};

export const workPackageMultiSelectLeftDrawerUiState: WorkPackageMultiSelectLeftDrawerUiState = {
  ...workPackageLeftDrawerUiState,
  selected: [],
};
