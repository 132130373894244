import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';

const wpsOrSwps: NodeSearchCondition = {
  or: [
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
  ],
};

// Changed basis in my work packages
// @see http://trac.local.hcom.de/h.content5/ticket/8108
export const openLikeWorkPackageQuery: NodeSearchCondition = {
  and: [
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    {not: ['isArchived']},
    {not: ['wpIsLocked']},
    {not: withDescendants({or: [['timeControlExceeded'], ['timeControlNotStarted']]}, true)},
  ],
};
export const openLikeWpOrSwpQuery: NodeSearchCondition = {
  and: [
    wpsOrSwps,
    {not: ['isArchived']},
    {not: ['wpIsLocked']},
    {not: ['swpIsLocked']},
    {not: withDescendants({or: [['timeControlExceeded'], ['timeControlNotStarted']]}, true)},
  ],
};

/**
 * Same as above, but doesn't hide upcoming work packages
 */
export const openLikeFutureWorkPackageQuery: NodeSearchCondition = {
  and: [
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    {not: ['isArchived']},
    {not: withDescendants(['timeControlExceeded'], true)},
  ],
};

/**
 * Same as above, but doesn't hide upcoming work packages
 */
export const openLikeFutureWpsOrSwpsQuery: NodeSearchCondition = {
  and: [wpsOrSwps, {not: ['isArchived']}, {not: withDescendants(['timeControlExceeded'], true)}],
};
