import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {RulesList, validateRules} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {pick} from 'lodash';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {NodeType} from '../EntityInterfaces/Nodes';
import {CommonPidNodePatchData, Group, GroupCreationData, GroupPatchData, WorkPackage} from '../EntityInterfaces/Pid';
import {isGroup, isWorkPackage} from '../Node/NodeIdentifiers';
import {
  FLOW_CHANGE_PID_FAILURE,
  FLOW_CHANGE_PID_REQUEST,
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_CREATE_GROUP_FAILURE,
  FLOW_CREATE_GROUP_REQUEST,
  FLOW_CREATE_GROUP_SUCCESS,
} from './ActionTypes';
import {
  createBudgetEntity,
  createBudgetEntityErrorFields,
  getBudgetEntityCopiedFields,
  getBudgetEntityValidationRules,
  transformBudgetEntityToPatchData,
  transformPatchDataToBudgetEntity,
} from './BudgetEntity';
import {
  createCommonPidNode,
  createCommonPidNodeErrorFields,
  createDeleteCommonPidNode,
  getCommonPidNodeCopiedFields,
  getCommonPidNodeValidationRules,
  transformCommonPidNodeToPatchData,
  transformPatchDataToCommonPidNode,
} from './CommonPidNodes';
import {EventData, GroupCreatedEvent, GroupCreateEvent, GroupPatchedEvent} from './Events';
import {nodeEntityReducers} from './Nodes';
import {removeNotAllowedLabels} from './ReduceRemovedLabels';
import {copyResponsibleNode, createResponsibleNode, onResponsibleNodeCreation} from './ResponsibleNode';
import {labelIdsSelector} from './Selectors/LabelSelectors';
import {getGroupSelector} from './Selectors/PidSelectors';
import {reduceSortedSiblingNodeIds} from './SortedSiblingIds';
import {FlowState} from './State';
import {setErrors} from './Ui';

nodeEntityReducers.add<GroupCreateEvent | GroupCreatedEvent | GroupPatchedEvent>(
  [FLOW_CREATE_GROUP_REQUEST, 'flow.GroupCreatedEvent', 'flow.GroupPatchedEvent'],
  reduceSortedSiblingNodeIds((n): n is Group | WorkPackage => isGroup(n) || isWorkPackage(n)),
);

export function createGroupEntity(flowCustomer: Uuid): Group {
  return {
    ...createCommonPidNode(EnumFlowNodeType.VALUE_GROUP, flowCustomer),
    ...createBudgetEntity(),
    ...createResponsibleNode(),
    groupType: EnumFlowGroupType.VALUE_GROUP,
    isClosed: false,
    isLocked: false,
    nodeType: EnumFlowNodeType.VALUE_GROUP,
    timeControl: null,
  };
}

function getGroupEntityCopiedFields(): Array<keyof Group> {
  return [...getCommonPidNodeCopiedFields(), ...getBudgetEntityCopiedFields(), 'color', 'groupType', 'timeControl'];
}

export function copyGroupEntity(source: Group, targetNode: NodeType): Group {
  return {
    ...createGroupEntity(source.flowCustomer),
    ...pick(source, getGroupEntityCopiedFields()),
    ...copyResponsibleNode(source, targetNode),
  };
}

export function transformGroupToPatchData<T extends CommonPidNodePatchData = GroupPatchData>(group: Group): T {
  return {
    ...transformCommonPidNodeToPatchData<T>(group),
    ...transformBudgetEntityToPatchData(group),
  };
}

function transformPatchDataToGroup(patch: GroupPatchData): Group;
function transformPatchDataToGroup(patch: Partial<GroupPatchData>): Partial<Group>;
function transformPatchDataToGroup(patch: DeepPartial<GroupPatchData>): DeepPartial<Group>;
function transformPatchDataToGroup(patch: DeepPartial<GroupPatchData>): DeepPartial<Group> {
  return {
    ...transformPatchDataToCommonPidNode<Group>(patch),
    ...transformPatchDataToBudgetEntity(patch),
  };
}

const validationTexts = {
  nameEmpty: 'general:pid.validationErrors.groupNameNotEmpty',
  nameTooLong: 'general:pid.validationErrors.groupNameTooLong',
};

function getGroupValidationRules(
  id: Uuid,
  patch: DeepPartial<GroupPatchData>,
  isCreation: boolean,
  previous?: GroupPatchData,
): RulesList {
  return [
    ...getCommonPidNodeValidationRules(id, patch, isCreation, validationTexts),
    ...getBudgetEntityValidationRules(id, patch, previous),
  ];
}

export function createGroupErrorFields(id: Uuid): string[] {
  return [...createCommonPidNodeErrorFields(id), ...createBudgetEntityErrorFields(id)];
}

export function createGroup(
  data: GroupCreationData,
  parentNodeId: Uuid,
  sortedSiblingIds?: Uuid[],
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const rules: RulesList = getGroupValidationRules(data.id, data, true, undefined);
    const errors = validateRules(rules);
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    const group = transformPatchDataToGroup(data);
    onResponsibleNodeCreation(group, parentNodeId, state);

    if (sortedSiblingIds) {
      const sortOrder = sortedSiblingIds.findIndex((id) => id === data.id);
      group.sortOrder = sortOrder > -1 ? sortOrder : 0;
    }

    const event: EventData<GroupCreateEvent> = {
      group,
      parentNodeId,
      sortedSiblingIds,
    };

    await dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.putGroup,
        method: 'put',
        options: {
          data: {
            ...group,
            parentNodeId,
            sortedSiblingIds,
          },
          urlParams: {groupId: data.id},
        },
        types: {
          failureType: FLOW_CREATE_GROUP_FAILURE,
          requestType: FLOW_CREATE_GROUP_REQUEST,
          successType: FLOW_CREATE_GROUP_SUCCESS,
        },
      },
    });
    return true;
  };
}

export function patchGroup(id: Uuid, patch: Partial<Group>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const oldGroup = getGroupSelector(state)(id);
    if (oldGroup) {
      const patchData = removeNotAllowedLabels(patch, labelIdsSelector(state));
      const hasSubtreeAction = 'groupType' in patchData;
      if (hasSubtreeAction || !objectContains(oldGroup, patchData)) {
        dispatch({
          [CALL_API]: {
            endpoint: routes.patchGroup,
            method: 'patch',
            options: {
              data: {...patchData, lastChangedBy: currentOrgUserIdSelector(state), lastChangedOn: unix()},
              urlParams: {groupId: id},
            },
            types: {
              failureType: FLOW_CHANGE_PID_FAILURE,
              requestType: FLOW_CHANGE_PID_REQUEST,
              successType: FLOW_CHANGE_PID_SUCCESS,
            },
          },
          //e.g. for NodeSearch reducer that need to updated since no return event is coming:
          patchedKeys: Object.keys(patchData),
          patchedNodeId: id, //for the optimistic patch
        });
      }
    }
  };
}

/**
 * @deprecated use patchGroup & auto-saving and local form validation
 */
export function patchGroupDeprecated(id: Uuid, partial: Partial<GroupPatchData>): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const oldGroup = getGroupSelector(state)(id);
    if (oldGroup) {
      const rules: RulesList = getGroupValidationRules(
        id,
        partial,
        false,
        transformGroupToPatchData<GroupPatchData>(oldGroup),
      );
      const errors = validateRules(rules);
      if (errors.length) {
        dispatch(setErrors(errors));
        return false;
      }

      const transformed = transformPatchDataToGroup(partial);
      dispatch(patchGroup(id, transformed));
    }
    return true;
  };
}

export const deleteGroup = createDeleteCommonPidNode('groupId', routes.deleteGroup);

export function useGroupPatch(nodeId: Uuid): (data: Partial<Group>) => void {
  const dispatch = useDispatch();
  return useCallback((data: Partial<Group>) => dispatch(patchGroup(nodeId, data)), [dispatch, nodeId]);
}
