import intersection from 'lodash/intersection';
import lodashIsPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';
import {FilterState} from '../../EntityInterfaces/Filter/FilterState';

export const isComplete = Symbol('isComplete');
const completeArray = Symbol('completeArray');

function isPlainObject(value: unknown): value is Record<string, unknown> {
  return lodashIsPlainObject(value);
}

export interface FilterStatesValueArray<T> extends Array<T> {
  [isComplete]?: boolean;
  [completeArray]?: Array<T>;
}

function isFilterStatesValueArray(array: unknown): array is FilterStatesValueArray<unknown> {
  return Array.isArray(array);
}

function mergeWithDefault<V = unknown>(value: V, defaultValue: V): V {
  let result = value;
  if (isPlainObject(defaultValue)) {
    if (isPlainObject(value)) {
      const pickedValues = pick({...defaultValue, ...value}, Object.keys(defaultValue));
      result = pickedValues as unknown as V;
      Object.keys(pickedValues).forEach((_key) => {
        const key = _key as keyof typeof result;
        result[key] = mergeWithDefault(result[key], defaultValue[key]);
      });
    } else {
      result = defaultValue;
    }
  } else if (isFilterStatesValueArray(defaultValue)) {
    if (isFilterStatesValueArray(value)) {
      const completeValue = defaultValue[isComplete] ? defaultValue : defaultValue[completeArray];
      if (completeValue) {
        result = intersection(value, completeValue) as unknown as V;
      }
    } else {
      result = defaultValue;
    }
  }
  return result;
}

/**
 * Because the filter states are saved with the user in their settings, the schema of the incoming filters cannot
 * be trusted. Migrating these deeply nested objects can be tedious and error prone.
 *
 * Thus this method will merge the default filters into the incoming filters, making sure all and only those properties
 * exist that are defined in the defaults. Even array items are intersected if a set of allowed items is known.
 */
export function mergeFilterStatesWithDefaults<
  Page extends string,
  FK extends string,
  States extends Record<FK, FilterState<unknown>>,
>(
  filterStates: Partial<Record<Page, Partial<States> | null>> | undefined,
  defaultFilterStates: Record<Page, Partial<States>>,
  page: Page,
): States {
  const userFilterStates = filterStates?.[page] || ({} as States);
  const defaultFilters = defaultFilterStates[page];
  //Make sure all and only those keys defined are let through (makes migrations easier):
  const complete = pick({...defaultFilters, ...userFilterStates}, Object.keys(defaultFilters)) as unknown as States;

  //Make sure the value is well defined and also only consists of defined properties:
  Object.keys(defaultFilters).forEach((_key) => {
    const key = _key as keyof States;
    complete[key] = {...complete[key]};
    complete[key].value = mergeWithDefault(complete[key].value, defaultFilters[key]!.value);
  });

  return complete;
}
