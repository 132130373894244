import {AnimationControls, useAnimationControls} from 'framer-motion';
import {useCallback, useContext, useEffect, useRef} from 'react';
import {pageLockContext} from '../Components/PageLock/PageLockContext';

interface Params {
  controls: AnimationControls;
  setActive: (isActive: boolean) => void;
}

export default function useBackdropShaking(hasChanges: boolean, alwaysDimm = false): Params {
  const controls = useAnimationControls();
  const {isLocked, setLocked} = useContext(pageLockContext);
  const locked = useRef<boolean>(isLocked);
  locked.current = isLocked;

  useEffect(() => {
    setLocked(hasChanges || alwaysDimm);
    return () => setLocked(false);
  }, [hasChanges, alwaysDimm, setLocked]);

  const wiggle = useCallback(
    (e) => {
      if (locked.current && (e.target.classList.contains('pageLock') || e.target.classList.contains('inspectorTabs'))) {
        controls.start(
          {
            rotate: [0, 10, -10, 0, 5, -5, 0],
            x: [0, 10, -10, 0, 5, -5, 0],
          },
          {duration: 0.3, mass: 0.75, damping: 100, stiffness: 100},
        );
      }
    },
    [controls],
  );

  useEffect(() => {
    window.addEventListener('click', wiggle);
    return () => {
      window.removeEventListener('click', wiggle);
    };
  }, [wiggle]);

  return {
    controls,
    setActive: setLocked,
  };
}
