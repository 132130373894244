import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import Tree, {TreeNodeData} from '@octaved/ui-components/src/React/Tree/Tree';
import classNames from 'classnames';
import {ChevronDown} from 'lucide-react';
import {ReactElement, useContext, useMemo} from 'react';
import {Loader} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {useOuTreeNodes} from '../../Modules/Hooks/NodeTree';
import {FlowColors} from '../../Styles/Default';
import {flowStyleContext} from '../../Styles/StyleContext';
import FolderSvg from '../../svgs/folder.svg?tsx';

//#region <styles>
function getStyle(TREE_PATH: FlowColors['TREE_PATH']): {className: string; styles: JSX.Element} {
  /*language=SCSS*/
  return css.resolve`
    .tree :global(.folderIndicatorIcon) {
      width: 21px !important;
      height: 21px !important;
    }

    .folder {
      width: 14px;
      height: 14px;
      stroke: ${TREE_PATH.ICONS};
    }

    .tree :global(.ui.list) {
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .tree :global(.ui.list .list:not(.icon)) {
      padding-left: 27px;
    }

    .tree :global(.ui.list .item .flexItemInner .content.treeNode) {
      //margin-left: 27px !important;
    }

    .tree :global(.nodeIcon) {
      margin-right: 2px !important;
    }

    .tree :global(.treeNode) {
      padding-left: 5px !important;
    }

    .tree :global(.nodeContent) {
      white-space: nowrap;
    }
  `;
}

//#endregion

interface Props {
  isSelectableNode?(node: TreeNodeData<Uuid>): boolean;
  selectedNode: Uuid | null;
  selectNode: (nodeId: Uuid) => void;
}

export default function ProjectFolderTree({isSelectableNode, selectedNode, selectNode}: Props): ReactElement {
  const {
    COLORS: {
      PAGES: {
        PROJECTS: {SELECTION_BACKGROUND},
      },
      TREE_PATH,
    },
  } = useContext(flowStyleContext);
  const {className, styles} = getStyle(TREE_PATH);
  const iconMap = useMemo(
    () => ({
      angle: (
        <IconButton
          size={'xs'}
          iconSize={'small'}
          icon={<ChevronDown />}
          iconColor={'darkGrey'}
          iconStrokeWidth={2}
          variant={'ghost'}
          className={classNames(className, 'angle')}
        />
      ),
      node: <FolderSvg className={classNames(className, 'folder')} />,
    }),
    [className],
  );
  const {isLoading, nodes} = useOuTreeNodes();
  const hasLoadedOnce = useLoadedValue(isLoading, !isLoading);

  const style = useMemo(() => ({selectedBackgroundColor: SELECTION_BACKGROUND}), [SELECTION_BACKGROUND]);
  return (
    <div className={classNames(className, 'tree')}>
      <Tree<Uuid>
        isSelectableNode={isSelectableNode}
        selectedNode={selectedNode}
        selectNode={selectNode as (nodeId: Uuid) => void}
        nodes={nodes}
        icons={iconMap}
        style={style}
      />
      <Loader active={!hasLoadedOnce} />
      {styles}
    </div>
  );
}
