import {onReduxAction} from '@octaved/websocket';
import {SomeAction} from './Store';

export interface Listener<T extends object> {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  (kwArgs: T): SomeAction | void | Promise<void>;
}

interface Dispose {
  (): void;
}

/**
 * Subscribes to websocket messages
 *
 * @deprecated use onReduxAction from @octaved/websocket or better refactor to use onEvent if this is not a redux action
 */
export function subscribe<T extends string, A extends object>(type: T, listener: Listener<A>): Dispose {
  type _A = A & {type: T};
  return onReduxAction(type as _A['type'], listener as (data: _A) => undefined);
}
