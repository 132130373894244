import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {TWButton} from '@octaved/ui';
import {MoveHorizontal} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {canPlanNodeSelector} from '../../Selectors/PlanningDependencySelectors';
import MovePlanning from './MovePlanning';

interface Props {
  node: Project | Group;
}

export default function ShowMovePlanningButton({node}: Props): ReactElement | null {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  const canPlan = useSelector((s: FlowState) => canPlanNodeSelector(s)(node.id));
  if (!canPlan) {
    return null;
  }
  return (
    <div>
      <TWButton size={'md'} withIcon onClick={() => setShow(true)}>
        <MoveHorizontal size={16} />
        {t('dialogs:movePlanning.showButton')}
      </TWButton>
      {show && <MovePlanning close={() => setShow(false)} node={node} />}
    </div>
  );
}
