import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {createStoreSubscription} from '../../../StoreSubscription';
import {TreeNode} from '../../Data/TreeNode';
import {Cell, CellProps} from './Cell';

export interface NodeCellProps extends CellProps {
  node: TreeNode;
}

export abstract class NodeCell extends Cell {
  protected readonly node: TreeNode;

  constructor(props: NodeCellProps) {
    super(props);
    this.node = props.node;
  }

  init(): void {
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => {
          const nodeEntity = getNodeSelector(store)(this.node.id);
          if (isTask(nodeEntity)) {
            return nodeEntity.status === EnumFlowTaskStatus.VALUE_COMPLETE;
          } else if (isWorkPackage(nodeEntity)) {
            return nodeEntity.isCompleted;
          }
          return false;
        },
        (isFinished) => {
          this.isFinished = isFinished;
          this.render();
        },
      ),
    );
  }
}
