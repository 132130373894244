import {
  dayjsToMoment,
  Moment,
  momentToDayjs,
  optionalDayjsToMoment,
  optionalMomentToDayjs,
} from '@octaved/dayjs-moment';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {DateTimeStr} from '@octaved/typescript';
import {Icon} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ISO_DATE, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import classNames from 'classnames';
import dayjs, {Dayjs} from 'dayjs';
import {ArrowLeftCircle, ArrowRightCircle} from 'lucide-react';
import {ReactElement, useCallback, useMemo} from 'react';
import {DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape} from 'react-dates';
import {useSelector} from 'react-redux';
import {getTaskWorkPackageSelector} from '../../../../../../Modules/Selectors/TaskSelectors';
import {FlowState} from '../../../../../../Modules/State';
import DayView from './DayView';

interface Props {
  end: DateTimeStr | null;
  focused: FocusedInputShape | null;
  id?: string;
  isRange: boolean;
  maxDate?: Dayjs;
  numberOfMonth?: number;
  onChangeDates: (startDate: DateTimeStr | null, endDate: DateTimeStr | null) => void;
  setFocused: (focus: FocusedInputShape | null) => void;
  start: DateTimeStr | null;
}

export default function PickerView({
  end,
  focused,
  id,
  isRange,
  maxDate,
  numberOfMonth = 1,
  onChangeDates,
  setFocused,
  start,
}: Props): ReactElement {
  const wp = useSelector((s: FlowState) => getTaskWorkPackageSelector(s)(id));
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(wp?.id);
  const isWorkPackagePlanned = useMemo(() => Boolean(plannedStart), [plannedStart]);
  const isDayWithinPlanned = useCallback(
    (day: Moment) => {
      if (plannedStart) {
        const isoDate = toIsoDateTimeFormat(momentToDayjs(day).startOf('day'));
        return isoDate >= plannedStart && isoDate <= plannedEnd;
      }
      return false;
    },
    [plannedEnd, plannedStart],
  );

  const getInitialMonth = useCallback(() => {
    return dayjsToMoment(dayjs(start ?? (plannedStart || undefined)));
  }, [start, plannedStart]);

  const onRangeChangeDate = useCallback(
    ({startDate, endDate}: {startDate: Moment | null; endDate: Moment | null}): void => {
      const parsedStart = toIsoDateTimeFormat(optionalMomentToDayjs(startDate ? startDate.startOf('day') : null));
      const parsedEnd = toIsoDateTimeFormat(optionalMomentToDayjs(endDate ? endDate.startOf('day') : null));

      onChangeDates(parsedStart, parsedEnd);
      if (focused === 'startDate') {
        setFocused('endDate');
      } else if (focused === 'endDate' && parsedEnd) {
        setFocused('startDate');
      }
    },
    [focused, onChangeDates, setFocused],
  );

  return (
    <div className={classNames('picker', `months-${numberOfMonth}`, {isWorkPackagePlanned})}>
      <Spacer spacing={5} />

      {!isRange && (
        <DayPickerSingleDateController
          focused
          transitionDuration={0}
          initialVisibleMonth={getInitialMonth}
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          noBorder
          keepOpenOnDateSelect
          enableOutsideDays
          numberOfMonths={numberOfMonth}
          renderMonthElement={({month}) => <>{month.format('MMMM YYYY')}</>}
          onFocusChange={() => {
            //empty function
          }}
          navPrev={
            <div className={'prevButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowLeftCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          navNext={
            <div className={'nextButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowRightCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          date={optionalDayjsToMoment(end ? dayjs(end) : null)}
          onDateChange={(date) => {
            const isoDate = toIsoDateTimeFormat(optionalMomentToDayjs(date ? date.startOf('day') : null));
            onChangeDates(isoDate, isoDate);
          }}
          renderDayContents={(day) => (
            <DayView
              day={day}
              isDayWithinPlanned={isDayWithinPlanned(day)}
              isWorkPackagePlanned={isWorkPackagePlanned}
            />
          )}
          isOutsideRange={(date) => !!maxDate && date.format(ISO_DATE) >= maxDate.format(ISO_DATE)}
        />
      )}

      {isRange && (
        <DayPickerRangeController
          transitionDuration={0}
          initialVisibleMonth={getInitialMonth}
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          noBorder
          keepOpenOnDateSelect
          minimumNights={0}
          numberOfMonths={numberOfMonth}
          enableOutsideDays
          navPrev={
            <div className={'prevButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowLeftCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          navNext={
            <div className={'nextButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowRightCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          focusedInput={focused}
          renderMonthElement={({month}) => <>{month.format('MMMM YYYY')}</>}
          startDate={optionalDayjsToMoment(start ? dayjs(start) : null)}
          endDate={optionalDayjsToMoment(end ? dayjs(end) : null)}
          onDatesChange={onRangeChangeDate}
          onFocusChange={(newFocus) => setFocused(newFocus)}
          renderDayContents={(day) => (
            <DayView
              day={day}
              isDayWithinPlanned={isDayWithinPlanned(day)}
              isWorkPackagePlanned={isWorkPackagePlanned}
            />
          )}
        />
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .picker {
          min-height: 195px;

          --color-bg: var(--color-white);
          --color-calendar-caption: var(--color-grey-700);
          --color-calendar-selectedBg: var(--color-blue-600);
          --color-calendar-selectedSpanBg: var(--color-blue-100);
          --color-calendar-hoverSpanBg: var(--color-grey-150);
          --color-calendar-day-outsideMonth-text: var(--color-grey-300);
          --color-calendar-day-outside-text: var(--color-grey-600);
          --color-calendar-day-outside-bg: var(--color-grey-25);
          --color-calendar-day-outside-selectedBg: var(--color-red-400);
          --color-calendar-day-outside-selectedSpanBg: var(--color-red-100);
          --color-calendar-day-outside-hoverBg: var(--color-red-100);
          --color-calendar-day-within-hoverBg: var(--color-green-100);
          --color-calendar-today: var(--color-purple-500);
        }

        .picker :global(.DayPicker),
        .picker :global(.DayPicker > div > div),
        .picker :global(.DayPicker_transitionContainer) {
          width: 228px !important;
        }

        .picker.months-2 :global(.DayPicker),
        .picker.months-2 :global(.DayPicker > div > div),
        .picker.months-2 :global(.DayPicker_transitionContainer) {
          width: 471px !important;
        }

        .picker :global(.DayPicker_transitionContainer[style*='height: 244px']) {
          height: 197px !important;
        }

        .picker :global(.DayPicker_transitionContainer[style*='height: 282px']) {
          height: 229px !important;
        }

        .picker :global(.DayPicker_transitionContainer[style*='height: 320px']) {
          height: 261px !important;
        }

        .picker :global(.CalendarMonthGrid__horizontal) {
          left: 0;
        }

        .picker :global(.DayPicker_weekHeaders__horizontal) {
          margin-left: 0;
        }

        .picker :global(.CalendarMonth),
        .picker :global(.DayPicker_weekHeader) {
          padding: 0 !important;
        }

        .picker.months-2 :global(.CalendarMonthGrid_month__horizontal + .CalendarMonthGrid_month__horizontal) {
          padding-right: 15px;
        }

        .picker :global(.DayPicker_transitionContainer__horizontal) {
          transition: none;
        }

        .picker :global(.CalendarMonth_caption) {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-calendar-caption);
          padding: 0 0 2.5rem 0;
          line-height: 28px;
        }

        .picker :global(.DayPicker_weekHeader) {
          top: calc(50px - 14px);
        }

        .picker :global(.DayPicker_weekHeader + .DayPicker_weekHeader) {
          left: 242px !important;
        }

        .picker :global(.CalendarMonth_caption) {
          font-size: 12px;
        }

        .picker :global(.CalendarDay) {
          height: 32px !important;
          width: 32.5px !important;
          border: 0;
          font-size: 12px;
          font-weight: 400;
          color: var(--color-text);
        }

        .picker :global(.DayPicker_weekHeader_li) {
          width: 32.5px !important;
        }

        .picker :global(.CalendarDay__outside),
        .picker :global(.DayPicker_weekHeader_li) {
          color: var(--color-calendar-day-outsideMonth-text);
        }

        .picker :global(.CalendarDay__selected) {
          color: var(--color-bg);
          background-color: var(--color-calendar-selectedBg);
        }

        .picker :global(.CalendarDay__hovered_span) {
          background-color: var(--color-calendar-hoverSpanBg);
        }

        .picker :global(.CalendarDay__selected_span) {
          background-color: var(--color-calendar-selectedSpanBg);
        }

        .picker :global(.CalendarDay__selected_span.CalendarDay__outside) {
          color: var(--color-text);
        }

        .prevButton,
        .nextButton {
          position: absolute;
          top: 0.2625rem;
        }

        .picker.months-2 .prevButton,
        .picker.months-2 .nextButton {
          top: 0.1em;
        }

        .prevButton {
          left: 0.35rem;
        }

        .nextButton {
          right: 0.35rem;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
