import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M14.2355 8.39672C14.0909 8.54426 14.0099 8.74262 14.0099 8.94921C14.0099 9.1558 14.0909 9.35416 14.2355 9.5017L15.4983 10.7645C15.6458 10.9091 15.8442 10.9901 16.0508 10.9901C16.2574 10.9901 16.4557 10.9091 16.6033 10.7645L19.5788 7.78899C19.9757 8.66601 20.0959 9.64316 19.9233 10.5902C19.7507 11.5373 19.2937 12.4092 18.613 13.0899C17.9323 13.7706 17.0603 14.2277 16.1133 14.4003C15.1662 14.5728 14.1891 14.4526 13.312 14.0558L7.85819 19.5096C7.5442 19.8236 7.11834 20 6.67429 20C6.23024 20 5.80438 19.8236 5.49039 19.5096C5.1764 19.1956 5 18.7698 5 18.3257C5 17.8817 5.1764 17.4558 5.49039 17.1418L10.9442 11.688C10.5474 10.8109 10.4272 9.8338 10.5997 8.88675C10.7723 7.93969 11.2294 7.06772 11.9101 6.38703C12.5908 5.70634 13.4627 5.24926 14.4098 5.0767C15.3568 4.90415 16.334 5.02431 17.211 5.42119L14.2434 8.38883L14.2355 8.39672Z',
      key: '1',
      strokeWidth: '1.5',
    },
  ],
];

export const MaterialRessourceIconSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const MaterialRessourceIcon = createLucideIcon('MaterialRessourceIcon', iconDrawing);
