import {MaybeUuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FlowState} from '../../Modules/State';
import {ExtendedNodeStatus, nodeStatusLabels} from './NodeStatus';
import {nodeStatusIcons, StatusIconProps} from './NodeStatusIcons';
import {getNodeStatusSelector} from './NodeStatusSelectors';

interface NodeStatusIconProps extends StatusIconProps {
  noOpenStatus?: boolean;
  withText?: boolean;
}

export function NodeStatusIcon(
  props: (NodeStatusIconProps & {status: ExtendedNodeStatus}) | (NodeStatusIconProps & {nodeId: MaybeUuid}),
): ReactElement | null;
export function NodeStatusIcon({
  nodeId,
  status: statusFromProp,
  withText = false,
  noOpenStatus = false,
  ...props
}: NodeStatusIconProps & {
  nodeId?: MaybeUuid;
  status?: ExtendedNodeStatus;
}): ReactElement | null {
  const {t} = useTranslation();
  const status =
    useSelector((s: FlowState) => (nodeId ? getNodeStatusSelector(s)(nodeId) : statusFromProp)) ?? 'isOpen';
  if (status === 'isOpen' && noOpenStatus) {
    return null;
  }
  const Icon = nodeStatusIcons[status];
  return (
    <div className={'flex items-center gap-x-2'}>
      <Icon {...props} />
      {withText && <div className={'truncate'}>{t(nodeStatusLabels[status])}</div>}
    </div>
  );
}
