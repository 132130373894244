import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {DateStr} from '@octaved/typescript';

type NSC = NodeSearchCondition;

const tasks: NSC = ['nodeType', EnumFlowNodeType.VALUE_TASK];
const swps: NSC = ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE];
const wps: NSC = ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE];
export const swpTasks: NSC = {and: [tasks, withDescendants(swps, true)]};
export const wpTasks: NSC = {and: [tasks, withDescendants(wps, true), {not: swpTasks}]};
const freeTasks: NSC = {and: [tasks, {not: swpTasks}, {not: wpTasks}]};

const notPlanned: NSC = ['nodeNotPlanned'];

const wpsNotPlanned: NSC = {and: [wps, notPlanned]};
const swpsNotPlanned: NSC = {and: [swps, notPlanned, withDescendants(wpsNotPlanned, true)]};
const tasksNotPlanned: NSC = {
  or: [
    {and: [freeTasks, notPlanned]},
    {and: [swpTasks, withDescendants(swpsNotPlanned, true), notPlanned]},
    {and: [wpTasks, withDescendants(wpsNotPlanned, true), notPlanned]},
  ],
};

export function getEffectiveWpsNotPlanned(): NSC {
  return wpsNotPlanned;
}

export function getEffectiveSwpsNotPlanned(): NSC {
  return swpsNotPlanned;
}

export function getEffectiveTasksNotPlanned(): NSC {
  return tasksNotPlanned;
}

export function getEffectiveWpsPlannedBeforeDate(date: DateStr): NSC {
  const plannedBefore: NSC = ['nodePlanningEndsBeforeDate', date];
  return {and: [wps, plannedBefore]};
}

export function getEffectiveSwpsPlannedBeforeDate(date: DateStr): NSC {
  const plannedBefore: NSC = ['nodePlanningEndsBeforeDate', date];
  const fixOrRelativeSwps: NSC = {and: [swps, plannedBefore]};
  const plannedByWp: NSC = {
    and: [swps, notPlanned, withDescendants(getEffectiveWpsPlannedBeforeDate(date), true)],
  };
  return {or: [fixOrRelativeSwps, plannedByWp]};
}

export function getEffectiveTasksPlannedBeforeDate(date: DateStr): NSC {
  const plannedBefore: NSC = ['nodePlanningEndsBeforeDate', date];
  const fixOrRelativeTasks: NSC = {and: [tasks, plannedBefore]};
  const plannedBySwp: NSC = {
    and: [swpTasks, notPlanned, withDescendants(getEffectiveSwpsPlannedBeforeDate(date), true)],
  };
  const plannedByWp: NSC = {
    and: [wpTasks, notPlanned, withDescendants(getEffectiveWpsPlannedBeforeDate(date), true)],
  };
  return {or: [fixOrRelativeTasks, plannedBySwp, plannedByWp]};
}

function getPlannedAround(from: DateStr, to: DateStr): NSC {
  return ['nodePlannedAroundDateRange', `${from}-${to}`];
}

export function getEffectiveWpsPlannedAroundDateRange(from: DateStr, to: DateStr): NSC {
  return {and: [wps, getPlannedAround(from, to)]};
}

export function getEffectiveSwpsPlannedAroundDateRange(from: DateStr, to: DateStr): NSC {
  const fixOrRelativeSwps: NSC = {and: [swps, getPlannedAround(from, to)]};
  const plannedByWp: NSC = {
    and: [swps, notPlanned, withDescendants(getEffectiveWpsPlannedAroundDateRange(from, to), true)],
  };
  return {or: [fixOrRelativeSwps, plannedByWp]};
}

export function getEffectiveTasksPlannedAroundDateRange(from: DateStr, to: DateStr): NSC {
  const fixOrRelativeTasks: NSC = {and: [tasks, getPlannedAround(from, to)]};
  const plannedBySwp: NSC = {
    and: [swpTasks, notPlanned, withDescendants(getEffectiveSwpsPlannedAroundDateRange(from, to), true)],
  };
  const plannedByWp: NSC = {
    and: [wpTasks, notPlanned, withDescendants(getEffectiveWpsPlannedAroundDateRange(from, to), true)],
  };
  return {or: [fixOrRelativeTasks, plannedBySwp, plannedByWp]};
}
