import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {useTimeEffortPriceSumsPerBillingType} from '@octaved/flow/src/Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {createTotals} from '@octaved/flow/src/Pages/Projects/Inspector/Common/ParentTimeSums/TotalComputers';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWProgress} from '@octaved/ui';
import {FunctionComponent, ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import FixedPriceWithMaxEffortRow from './Components/TimeSums/FixedPriceWithMaxEffortRow';
import HoursOfTotal from './Components/TimeSums/HoursOfTotal';
import {RowProps} from './Components/TimeSums/Interfaces';
import RowWithMaxEffort from './Components/TimeSums/RowWithMaxEffort';

const withMaxEffort: [EnumFlowPidBillingType, FunctionComponent<RowProps>][] = [
  [EnumFlowPidBillingType.VALUE_EFFORT_EST, RowWithMaxEffort],
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP, RowWithMaxEffort],
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO, RowWithMaxEffort],
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE, FixedPriceWithMaxEffortRow],
  [EnumFlowPidBillingType.VALUE_CONTINGENT, RowWithMaxEffort],
];

export interface NodeSumOfTimeLimitsProps {
  workPackageIds: Uuid[];
  billable?: boolean;
}

export default function NodeSumOfTimeLimits({
  billable = false,
  frame: Frame,
  workPackageIds,
}: NodeSumOfTimeLimitsProps & FramedSectionOrTileProps): ReactElement | null {
  const {sums} = useTimeEffortPriceSumsPerBillingType(workPackageIds);
  const totals = useMemo(() => createTotals(sums), [sums]);

  const {t} = useTranslation();
  if (withMaxEffort.every(([type]) => !sums[type])) {
    return null;
  }

  return (
    <Frame
      labelToken={
        billable ? 'nodeDetails:field.sumTimeLimits.billableLabel' : 'nodeDetails:field.sumTimeLimits.nonBillableLabel'
      }
    >
      <div className={'grid grid-cols-[max-content_1fr_max-content] items-center gap-x-4 gap-y-1 pt-2'}>
        {withMaxEffort.map(([type, Component]) => {
          const sum = sums[type];
          return sum && <Component key={type} type={type} sum={sum} billable={billable} />;
        })}

        <div className={'col-span-3 my-1 h-px bg-slate-200'} />

        <div className={'font-semibold'}>{t('nodeDetails:field.controllingTimeTracking.total')}</div>
        <TWProgress
          progress={(totals.billableHoursWithMaxEffort * 100) / totals.maxEffortHours}
          color={'slate'}
          size={'lg'}
        />
        <HoursOfTotal hours={totals.billableHoursWithMaxEffort} total={totals.maxEffortHours} shortUnit />
      </div>
    </Frame>
  );
}
