import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withDescendants, withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {
  getAllAncestorsForNodeIds,
  getAllDescendantsForRootId,
  getParentsToChildrenMap,
} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {Transform} from '@octaved/utilities/src/Condition/Types';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {NodeTree} from '../../../EntityInterfaces/NodeTree';
import {TaskSection} from '../../../EntityInterfaces/TaskSection';
import {useLoadedNodes, useLoadNodes} from '../../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../../Modules/Hooks/NodeSearch';
import {nodeEntitySelector} from '../../../Modules/Selectors/NodeSelectors';
import {FlowState} from '../../../Modules/State';
import {useSearchHighlightContext} from '../../Search/SearchHighlight';
import {useTaskListContext} from '../TaskListContext';
import TaskListReadonlyContext, {useTaskListReadonlyContext} from '../TaskListReadonlyContext';
import {useDynamicDragContext} from './DragAndDrop/DragContext';
import {useOpenSearchedTasksAndGroups} from './OpenSearchedTasksAndGroups';
import TaskList from './Task/TaskList';
import TaskListForNodeContext from './TaskListForNodeContext';
import TaskListStyle from './TaskListStyle';
import TaskSections from './TaskSection/TaskSections';

const extendWithAncestorsCache = new WeakMap<NodeTree, Transform<Uuid>>();
function getExtendWithAncestors(tree: NodeTree): Transform<Uuid> {
  let cached = extendWithAncestorsCache.get(tree);
  if (!cached) {
    cached = (nodeIds: ReadonlyArray<Uuid>): ReadonlyArray<Uuid> => {
      return [...getAllAncestorsForNodeIds(tree, nodeIds, true)];
    };
    extendWithAncestorsCache.set(tree, cached);
  }
  return cached;
}

interface Props {
  nodeId: Uuid;
  taskFilterQuerySelector?: (state: FlowState) => NodeSearchCondition | null;
}

function useTaskSearchQuery(
  nodeId: Uuid,
  filterQuery: NodeSearchCondition | null,
  withParentTasks: boolean,
): NodeSearchCondition {
  const {treeSelector} = useDynamicDragContext();
  const tree = useSelector(treeSelector);
  return useMemo((): NodeSearchCondition => {
    const query: NodeSearchCondition | null = filterQuery
      ? withParentTasks
        ? {transform: [filterQuery, getExtendWithAncestors(tree)]}
        : filterQuery
      : null;
    return {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_TASK],
        {fixResult: [...getAllDescendantsForRootId(tree, nodeId, true)]},
        ...(query ? [query] : []),
      ],
    };
  }, [filterQuery, withParentTasks, tree, nodeId]);
}

/**
 * Shortcut for easier renaming of the result props
 */
function useTaskSearch(query: NodeSearchCondition): [boolean, ReadonlyArray<Uuid>] {
  const {hasLoadedOnce, nodeIds} = useCombinedNodeSearch(query);
  return [hasLoadedOnce, nodeIds];
}

function useTaskSectionSearch(
  nodeId: Uuid,
  search: string,
  taskMatchingQuery: NodeSearchCondition,
): [boolean, ReadonlyArray<Uuid>] {
  const {treeSelector} = useDynamicDragContext();
  const tree = useSelector(treeSelector);
  const {hideEmptyTaskSections} = useTaskListContext();
  const childIds = useMemo(() => getParentsToChildrenMap(tree).get(nodeId) || [], [tree, nodeId]);

  const conditions: NodeSearchCondition[] = [['nodeType', EnumFlowNodeType.VALUE_TASK_SECTION], {fixResult: childIds}];

  //When the search is active, only show sections with matching tasks:
  if (hideEmptyTaskSections || search) {
    conditions.push(withAncestors(taskMatchingQuery, true));
  }

  const {hasLoadedOnce, nodeIds} = useCombinedNodeSearch({and: conditions});
  return [hasLoadedOnce, nodeIds];
}

function useLoadAllTasks(nodeId: Uuid): void {
  //load all tasks for logical dependency predecessor calculation
  const [, allDescendentTaskIds] = useTaskSearch(
    useMemo(
      () => ({
        and: [['nodeType', EnumFlowNodeType.VALUE_TASK], withDescendants(nodeId, true)],
      }),
      [nodeId],
    ),
  );
  useLoadNodes(allDescendentTaskIds);
}

export default function TaskListForNode({nodeId, taskFilterQuerySelector = () => null}: Props): ReactElement {
  const {searchTerm} = useSearchHighlightContext();
  const filterQuery = useSelector(taskFilterQuerySelector);
  const queryWithParents = useTaskSearchQuery(nodeId, filterQuery, true);
  const queryWithoutParents = useTaskSearchQuery(nodeId, filterQuery, false);
  const [, filterVisibleTaskIds] = useTaskSearch(queryWithParents);
  const [filterMatchingTaskIdsLoadedOnce, filterMatchingTaskIds] = useTaskSearch(queryWithoutParents);
  const [, taskSectionIds] = useTaskSectionSearch(nodeId, searchTerm, queryWithoutParents);
  useLoadAllTasks(nodeId);
  const {nodes: taskSections} = useLoadedNodes<TaskSection>(taskSectionIds);
  const node = useSelector((s: FlowState) => nodeEntitySelector(s)[nodeId]);
  const canManageTasks = useIsGranted('FLOW_NODE_TASK_MANAGE_BASIC', nodeId) && !!node && !node.isArchived;
  const {readonly} = useTaskListReadonlyContext();

  useOpenSearchedTasksAndGroups(searchTerm, filterMatchingTaskIdsLoadedOnce, filterMatchingTaskIds);
  const {EmptyTaskRowBoxPlaceholder} = useTaskListContext();
  const showPlaceholder =
    filterMatchingTaskIds.length === 0 && taskSectionIds.length === 0 && EmptyTaskRowBoxPlaceholder;

  return (
    <TaskListStyle>
      <TaskListReadonlyContext canManageTasks={canManageTasks} readonly={readonly}>
        <TaskListForNodeContext
          nodeId={nodeId}
          filterMatchingTaskIds={filterMatchingTaskIds}
          filterVisibleTaskIds={filterVisibleTaskIds}
        >
          <TaskList parentId={nodeId} />
          <TaskSections parentId={nodeId} taskSections={taskSections} />
          {showPlaceholder && <EmptyTaskRowBoxPlaceholder />}
        </TaskListForNodeContext>
      </TaskListReadonlyContext>
    </TaskListStyle>
  );
}
