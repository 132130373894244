import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {TaskPlanningStatus} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {boolFilter} from '@octaved/utilities';
import {Fragment, ReactElement, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import type {Group, Project} from '../../../../../../EntityInterfaces/Pid';
import {useFormattedDateText} from '../../../../../../Hooks/FormattedDateText';
import {isGroup, isProject} from '../../../../../../Node/NodeIdentifiers';
import {getPidSelector, pathSelector} from '../../../../../../Modules/Selectors/PidSelectors';
import {FlowState} from '../../../../../../Modules/State';
import PlanningDate from '../../../../../Node/PlanningDate';
import {useTaskListNodeContext} from '../../../TaskListForNodeContext';

interface Props {
  planningStatus: TaskPlanningStatus;
}

const nodeTypeToken: Record<EnumFlowNodeType.VALUE_GROUP | EnumFlowNodeType.VALUE_PROJECT, string> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'taskList:planning.groupDueDate',
  [EnumFlowNodeType.VALUE_PROJECT]: 'taskList:planning.projectDueDate',
};

type Path = Array<Group | Project>;
const emptyPath: Path = [];

export default function PlanningCellTooltipContent({planningStatus}: Props): ReactElement {
  const {t} = useTranslation();
  const {workPackage: wp} = useTaskListNodeContext();
  const wpId = wp?.id;
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(wpId);
  const formatDate = useFormattedDateText();

  const pathNodes = useSelector(
    useMemo(() => {
      let lastPath: Path = emptyPath;
      return (s: FlowState): Path => {
        const getFullPath = pathSelector(s);
        const getPid = getPidSelector(s);
        if (wpId) {
          const pidPath = getFullPath(wpId);
          const newPath = boolFilter<Group | Project>(
            pidPath.map((id) => getPid(id)).filter((pid) => (isGroup(pid) || isProject(pid)) && pid.dueDate) as
              | Group[]
              | Project[],
          );
          if (newPath.length !== lastPath.length || !newPath.every((v, i) => v === lastPath[i])) {
            lastPath = newPath;
          }
        } else {
          lastPath = emptyPath;
        }
        return lastPath;
      };
    }, [wpId]),
  );

  return (
    <div>
      {planningStatus === TaskPlanningStatus.outSideOfWorkpackagePlanning && (
        <div className={'grid'}>
          {pathNodes.map(({id, nodeType, dueDate}) => (
            <Fragment key={id}>
              <div>
                <Trans i18nKey={nodeTypeToken[nodeType]} />
              </div>
              <div>{formatDate(dueDate)}</div>
            </Fragment>
          ))}
          {plannedStart && plannedEnd && (
            <>
              <div>{t('taskList:planning.workPackagePlanning')}</div>
              <div>
                <PlanningDate plannedStart={plannedStart} plannedEnd={plannedEnd} />
              </div>
            </>
          )}
        </div>
      )}
      {planningStatus === TaskPlanningStatus.overdue && <>{t('taskList:planning.overdue')}</>}

      {/*language=scss*/}
      <style jsx>{`
        .grid {
          display: grid;
          grid-template-columns: max-content max-content;
          gap: 4px 8px;
          padding-bottom: 8px;
        }
      `}</style>
    </div>
  );
}
