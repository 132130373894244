import ComplexTrans from '@octaved/i18n/src/ComplexTrans';
import {Icon, IconColor, IconSize} from '@octaved/ui';
import classNames from 'classnames';
import {ReactElement, forwardRef} from 'react';
import {Button, ButtonColorScheme, ButtonProps, ButtonVariant} from './Button';

interface IconButtonProps extends ButtonProps {
  icon: ReactElement;
  iconSize?: IconSize;
  iconColor?: IconColor;
  iconClassName?: string;
  iconRight?: boolean;
  isUnderlined?: boolean;
  rotate?: number;
}

const ColorButtonTypeMap: Record<ButtonColorScheme, IconColor> = {
  add: 'green',
  blue: 'darkBlue',
  cancel: 'darkGrey',
  delete: 'red',
  gray: 'black',
  green: 'green',
  register: 'purple',
  secondary: 'darkBlue',
};

const variantColorMap: Record<Partial<ButtonVariant>, IconColor | null> = {
  ghost: 'black',
  outline: null,
  solid: 'white',
};

const IconButton = forwardRef<HTMLElement, IconButtonProps>(function IconButton(
  {
    icon,
    iconColor,
    iconClassName,
    iconRight = false,
    iconSize = 'big',
    isActive = false,
    isUnderlined = false,
    label,
    rotate = 0,
    ...buttonProps
  },
  ref,
): ReactElement {
  const color: IconColor =
    (iconColor || variantColorMap[buttonProps.variant || 'solid']) ??
    ColorButtonTypeMap[buttonProps.colorScheme || 'gray'];

  return (
    <Button
      {...buttonProps}
      className={classNames(buttonProps.className, {label, isUnderlined})}
      ref={ref}
      iconOnly={!label}
      isActive={isActive}
    >
      {!iconRight && (
        <Icon iconColor={color} noMargin size={iconSize} rotate={rotate} className={iconClassName}>
          {icon}
        </Icon>
      )}
      {label && <ComplexTrans translatable={label} />}
      {iconRight && (
        <Icon iconColor={color} noMargin size={iconSize} rotate={rotate} className={iconClassName}>
          {icon}
        </Icon>
      )}
    </Button>
  );
});

export {IconButton};
export type {IconButtonProps};
