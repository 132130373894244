import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M13.2761 15.0448C12.8759 15.5373 12.1241 15.5373 11.7239 15.0448L7.32486 9.63059C6.79387 8.97708 7.25894 8 8.10097 8L16.899 8C17.7411 8 18.2061 8.97708 17.6751 9.63059L13.2761 15.0448Z',
      fill: 'currentColor',
      key: '1',
      stroke: 'none',
    },
  ],
];

export const ChevronSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const Chevron = createLucideIcon('Chevron', iconDrawing);
