import {FlowState} from '@octaved/flow/src/Modules/State';
import {EntityStates} from '@octaved/store/src/EntityState';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {WorkingTimeDay, WorkingTimeDays} from '../Interfaces/WorkingTimeDays';
import {getOrgUserDateStoreKey} from './WorkingTimeEntriesSelectors';

export const workingTimeDaysSelector = (s: FlowState): WorkingTimeDays => s.workingTime.days;
export const workingTimeDaysStateSelector = (s: FlowState): EntityStates => s.workingTime.daysState;

export const getWorkingTimeDaySelector = createSelector(workingTimeDaysSelector, (days) =>
  memoize(
    (userId: Uuid, date: DateStr): WorkingTimeDay | undefined => days[getOrgUserDateStoreKey(userId, date)],
    (userId: Uuid, date: DateStr) => `${userId}+${date}`,
  ),
);
