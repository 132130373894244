import {TWLabel} from '@octaved/ui';
import {PropsWithChildren, ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {OptionalNoLabelProps} from './General/Common/Type';

export interface SectionOrTileProps extends OptionalNoLabelProps {
  labelAddition?: ReactNode;
  labelRightContent?: ReactNode;
  labelToken: string;
}

export default function Section({
  children,
  labelAddition,
  labelRightContent,
  labelToken,
  noLabel,
}: PropsWithChildren<SectionOrTileProps>): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'flex flex-col gap-y-1.5'}>
      {!noLabel && (labelToken || labelAddition || labelRightContent) && (
        <div className={'flex h-3.5 w-full justify-between'}>
          <div className={'flex items-center gap-x-2'}>
            <TWLabel>{t(labelToken)}</TWLabel>
            {labelAddition}
          </div>
          {labelRightContent}
        </div>
      )}
      {children}
    </div>
  );
}
