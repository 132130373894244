import {useBoolean} from '@octaved/hooks';
import {DateStr} from '@octaved/typescript';
import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {isObject} from 'lodash';
import {ReactElement, memo, useEffect, useState} from 'react';
import {FocusedInputShape} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PopupPickerView from './Internal/PopupPickerView';
import StandardRangePickerFooter, {StandardRangePickerFooterProps} from './Internal/StandardRangePickerFooter';
import StandardRangePickerTrigger from './Internal/StandardRangePickerTrigger';

export interface RangePickerProps {
  start: DateStr | null;
  end: DateStr | null;
  onChange: (start: DateStr | null, end: DateStr | null) => void;
  readonly?: boolean;
  footerOverride?: Omit<StandardRangePickerFooterProps, 'clear' | 'close'> | ReactElement;
}

const RangePicker = memo(function RangePicker({
  end,
  footerOverride,
  onChange,
  readonly,
  start,
}: RangePickerProps): ReactElement {
  const [open, {on, off, toggle}] = useBoolean(false);

  const [valueStart, setValueStart] = useState(start);
  const [valueEnd, setValueEnd] = useState(end);
  const singleDaySelected = valueStart === valueEnd;
  const [focused, setFocused] = useState<FocusedInputShape | null>(
    (valueStart && !valueEnd) || (valueStart && singleDaySelected) ? 'endDate' : 'startDate',
  );

  useEffect(() => setValueStart(start), [start]);
  useEffect(() => setValueEnd(end), [end]);
  useEffect(() => {
    setFocused((valueStart && !valueEnd) || (valueStart && singleDaySelected) ? 'endDate' : 'startDate');
  }, [valueStart, valueEnd, singleDaySelected]);

  let footer = (
    <StandardRangePickerFooter
      close={() => {
        if (valueStart && !valueEnd) {
          setValueStart(valueStart);
          setValueEnd(valueStart);
          onChange(valueStart, valueStart);
          setFocused('endDate');
        } else if (valueStart && valueEnd) {
          setValueStart(valueStart);
          setValueEnd(valueEnd);
          onChange(valueStart, valueEnd);
        }
        off();
      }}
      clear={() => onChange(null, null)}
      {...(isObject(footerOverride) ? footerOverride : {})}
    />
  );

  if (typeof footerOverride === 'function') {
    footer = footerOverride;
  }

  return (
    <PopupPickerView
      popupProps={{
        onClose: () => {
          if (valueStart && !valueEnd) {
            setValueStart(valueStart);
            setValueEnd(valueStart);
            onChange(valueStart, valueStart);
            setFocused('endDate');
          } else if (valueStart && valueEnd) {
            setValueStart(valueStart);
            setValueEnd(valueEnd);
            onChange(valueStart, valueEnd);
          }
          off();
        },
        onOpen: on,
        open: !readonly && open,
        trigger: (
          <StandardRangePickerTrigger
            start={start}
            end={end}
            onToggle={toggle}
            isActive={open}
            readonly={readonly}
            convertFrom={fromIsoFormat}
          />
        ),
      }}
      datePickerProps={{
        focused,
        setFocused,
        isRange: true,
      }}
      start={valueStart}
      end={singleDaySelected ? null : valueEnd}
      onChangeDate={(startDate, endDate) => {
        if (focused === 'startDate') {
          setValueStart(startDate);
          setValueEnd(null);
          setFocused('endDate');
        } else {
          if (startDate && endDate) {
            setValueStart(startDate);
            setValueEnd(endDate);
          } else if (!endDate) {
            setValueStart(startDate);
            setValueEnd(valueStart);
          }
          setFocused('startDate');
        }
      }}
      footer={footer}
      convertFrom={fromIsoFormat}
      convertTo={toIsoFormat}
    />
  );
});

export {RangePicker};
