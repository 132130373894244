import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {Copy} from 'lucide-react';
import {ReactElement, useCallback} from 'react';
import {usePid} from '../../../Modules/Hooks/Nodes';
import {isWorkPackage} from '../../../Node/NodeIdentifiers';
import {useCopyTasksBetweenWorkPackages} from '../../../Modules/Tasks';

interface Props {
  selectedPid: Uuid | null;
  close: () => void;
  workPackageId: Uuid;
}

export default function Footer({selectedPid, close, workPackageId}: Props): ReactElement {
  const pid = usePid(selectedPid);
  const copyTasksBetweenWorkPackages = useCopyTasksBetweenWorkPackages();
  const copy = useCallback(() => {
    if (selectedPid && isWorkPackage(pid)) {
      copyTasksBetweenWorkPackages(selectedPid, workPackageId);
      close();
    }
  }, [close, copyTasksBetweenWorkPackages, pid, selectedPid, workPackageId]);

  return (
    <div className={'footer'}>
      <IconButton
        icon={<Copy />}
        label={'pages:workspace.tasks.copyTasks.copyButtonText'}
        onClick={copy}
        isDisabled={!isWorkPackage(pid)}
      />
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .footer {
          display: flex;
          justify-content: center;
          align-items: center;

          & > :global(.button) {
            margin: 10px;
          }
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
