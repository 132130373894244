import {CalendarColor} from '@octaved/ui';
import {currentOrgUserSettingsSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {createSelector} from 'reselect';
import {AccountCalendar, calendarServiceTypes} from '../EntityInterfaces/ConnectedService';

export const connectedServicesSelector = createSelector(
  currentOrgUserSettingsSelector,
  ({connectedServices}) => connectedServices,
);

export const connectedCalendarServicesSelector = createSelector(connectedServicesSelector, (connectedServices) =>
  connectedServices.filter(({serviceType}) => calendarServiceTypes.has(serviceType)),
);

export const hasConnectedCalendarServicesSelector = createSelector(
  connectedCalendarServicesSelector,
  (connectedServices) => connectedServices.length > 0,
);

export const connectedServiceCalendarColorsSelector = createSelector(
  connectedCalendarServicesSelector,
  (connectedServices) => {
    const calendarColors = new Map<string, CalendarColor>();
    connectedServices.forEach((acc) => {
      const calendars = (acc.data.calendars || []) as AccountCalendar[];
      calendars.forEach(({id, color}) => {
        calendarColors.set(id, color);
      });
    });
    return calendarColors;
  },
);

export const myCalendarEnabledServicesSelector = createSelector(
  connectedCalendarServicesSelector,
  (connectedServices) => {
    return connectedServices.filter((service) => {
      const calendars = (service.data.calendars || []) as AccountCalendar[];
      return calendars.length > 0 && calendars.some(({showInMyCalendar}) => showInMyCalendar);
    });
  },
);

export const myCalendarEnabledCalendarIdsSelector = createSelector(
  connectedCalendarServicesSelector,
  (connectedServices) => {
    const calendarIds = new Set();
    connectedServices.forEach((acc) => {
      const calendars = (acc.data.calendars || []) as AccountCalendar[];
      calendars.forEach(({id, showInMyCalendar}) => {
        if (showInMyCalendar) {
          calendarIds.add(id);
        }
      });
    });
    return calendarIds;
  },
);

export const hasMyCalendarEnabledServicesSelector = createSelector(
  myCalendarEnabledServicesSelector,
  (calendarIds) => calendarIds.length > 0,
);
