import {getFlowAudit} from '@octaved/flow-api';
import {
  CALL_API,
  CallAction,
  ServerRequestAction,
  ServerResponseAction,
} from '@octaved/network/src/NetworkMiddlewareTypes';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {get} from 'lodash';
import {AuditWorkPackage} from '../EntityInterfaces/Audit/AuditWorkPackage';
import {
  FLOW_GET_AUDIT_FAILURE,
  FLOW_GET_AUDIT_RELOAD,
  FLOW_GET_AUDIT_REQUEST,
  FLOW_GET_AUDIT_SUCCESS,
} from './ActionTypes';
import {
  auditHasLoadedSelector,
  auditIsLoadingSelector,
  generateAuditSearchKey,
  getAuditConnectionSelector,
} from './Selectors/AuditSelectors';
import {FlowState} from './State';

//#region interfaces
export type AuditTables =
  | 'workPackages'
  | 'users'
  | 'labels'
  | 'flowCustomers'
  | 'priceCategories'
  | 'groups'
  | 'projects'
  | 'projectFolders'
  | 'tasks'
  | 'boardPosts'
  | 'billings'
  | 'customerPriceCategoryOverrides'
  | 'userNodes'
  | 'priceSurcharges'
  | 'customerPriceSurchargeOverrides'
  | 'taskSections';

interface PageInfo {
  hasNextPage: boolean;
  endCursor: string;
  isLoading: boolean;
  hasLoaded: boolean;
}

export interface Connection<T> {
  nodes: T[];
  pageInfo: PageInfo;
}

interface ConnectionQuery<T> {
  [x: string]: Connection<T> | undefined;
}

export interface AuditSearchParams {
  endCursor?: string;
  id?: Uuid;
  revisionUserId?: Uuid;
}

export interface AuditState {
  [x: string]: ConnectionQuery<AuditWorkPackage> | undefined;
}

type GetAuditRequestAction = ServerRequestAction<{
  urlParams: AuditSearchParams & {tableName: AuditTables};
}>;
type GetAuditSuccessAction = ServerResponseAction<
  {
    nodes: Record<string, unknown>[];
    pageInfo: PageInfo;
  },
  {
    urlParams: AuditSearchParams & {tableName: AuditTables};
  }
>;
//#endregion
//#region reducer

const reducerMap = new Map();
reducerMap.set(FLOW_GET_AUDIT_REQUEST, (state: AuditState, action: GetAuditRequestAction): AuditState => {
  const searchKey = generateAuditSearchKey(action.options.urlParams);
  return {
    ...state,
    [action.options.urlParams.tableName]: {
      ...get(state, `${action.options!.urlParams.tableName}`, {}),
      [searchKey]: {
        nodes: [],
        ...get(state, `${action.options!.urlParams.tableName}.${searchKey}`, {}),
        pageInfo: {
          endCursor: '',
          hasLoaded: false,
          hasNextPage: false,
          ...get(state, `${action.options!.urlParams.tableName}.${searchKey}.pageInfo`, {}),
          isLoading: true,
        },
      },
    },
  };
});
reducerMap.set(FLOW_GET_AUDIT_SUCCESS, (state: AuditState, action: GetAuditSuccessAction) => {
  const searchKey = generateAuditSearchKey(action.options!.urlParams);
  return {
    ...state,
    [action.options!.urlParams.tableName]: {
      ...get(state, [action.options!.urlParams.tableName], {}),
      [searchKey]: {
        nodes: [
          ...get(state, [action.options!.urlParams.tableName, searchKey, 'nodes'], []),
          ...(action.response?.nodes || []),
        ],
        pageInfo: {
          ...(action.response?.pageInfo || {}),
          hasLoaded: true,
          isLoading: false,
        },
      },
    },
  };
});
reducerMap.set(FLOW_GET_AUDIT_RELOAD, (): AuditState => {
  return {};
});
export const auditReducer = ReduceFromMap(reducerMap);

//#endregion

function getAction(tableName: AuditTables, search: AuditSearchParams): CallAction {
  return {
    [CALL_API]: {
      endpoint: getFlowAudit,
      method: 'get',
      options: {
        urlParams: {tableName, ...search},
      },
      types: {
        failureType: FLOW_GET_AUDIT_FAILURE,
        requestType: FLOW_GET_AUDIT_REQUEST,
        successType: FLOW_GET_AUDIT_SUCCESS,
      },
    },
  };
}

export function getAuditForTable(
  tableName: AuditTables,
  search: AuditSearchParams = {},
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const isLoading = auditIsLoadingSelector(state)(tableName, search);
    const hasLoaded = auditHasLoadedSelector(state)(tableName, search);
    if (!isLoading && !hasLoaded) {
      await dispatch(getAction(tableName, search));
    }
  };
}

export function loadNextAuditPage(
  tableName: AuditTables,
  search: AuditSearchParams = {},
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const connection = getAuditConnectionSelector(state)(tableName, search);
    if (connection && connection.pageInfo.endCursor && connection.pageInfo.hasNextPage) {
      await dispatch(getAction(tableName, {...search, endCursor: connection.pageInfo.endCursor}));
    } else if (!connection) {
      //load first page
      await dispatch(getAuditForTable(tableName, search));
    }
  };
}

export function reload(): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch) => {
    dispatch({type: FLOW_GET_AUDIT_RELOAD});
  };
}
