import {useObjectSnapshot} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useDefaultProjectTreeOptions} from '../../../../Modules/Projects/DefaultProjectTree';
import {useProjectTree} from '../../../../Modules/Projects/ProjectTree';
import {
  ProjectTreeNode,
  ProjectTreeOptions,
  ProjectTreeProject,
} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {nodeTreeSelector} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {projectsOpenNodesSetSelector} from '../../../../Modules/Selectors/UiSelectors';
import {ProjectDndMoveFn} from './ProjectDndContext';
import {ProjectDragObject} from './ProjectSortable';
import {createNewSiblingsList} from './TreeCalculations';

interface CreateDnDTreeResult {
  treeOptions: ProjectTreeOptions;
  hasLoadedOnce: boolean;
  isLoading: boolean;
  flat: ProjectTreeProject[];
  allNodes: Map<Uuid, ProjectTreeNode>;

  resetSortOrder(): void;

  resetNodeTree(): void;

  move?: ProjectDndMoveFn;
}

export function useCreateDnDTree(
  additionalTreeOptions: Partial<ProjectTreeOptions>,
  draggedPid?: ProjectTreeNode | undefined,
  rootNode?: Uuid,
): CreateDnDTreeResult {
  const [expandedNodeIds, setExpandedNodeIds] = useState<ReadonlySet<Uuid>>(useSelector(projectsOpenNodesSetSelector));
  const nodeTree = useSelector(nodeTreeSelector);
  const {patch: patchSortOrder, snapshot: snapSortOrder, reset: resetSortOrder} = useObjectSnapshot(() => ({}));
  const {patch: patchNodeTree, snapshot: snapNodeTree, reset: resetNodeTree} = useObjectSnapshot(() => nodeTree);

  const defaultTreeOptions = useDefaultProjectTreeOptions();

  const treeOptions: ProjectTreeOptions = {
    ...defaultTreeOptions,
    setExpandedNodeIds,
    expandedNodeIds: useMemo(
      () => (rootNode ? new Set([rootNode, ...expandedNodeIds]) : expandedNodeIds),
      [expandedNodeIds, rootNode],
    ),
    nodeTree: snapNodeTree,
    selectableNodeTypes: null,
    sortOrderOverride: snapSortOrder,
    ...additionalTreeOptions,
  };

  const {allNodes, hasLoadedOnce, isLoading, flat} = useProjectTree(treeOptions);

  const move = useCallback<ProjectDndMoveFn>(
    (
      oldIndex: number,
      newIndex: number,
      oldParentId: Uuid | null,
      newParentId: Uuid | null,
      {data: {id}}: ProjectDragObject,
    ) => {
      if (newParentId) {
        const newSiblings = createNewSiblingsList(
          id,
          oldIndex,
          newIndex,
          oldParentId,
          newParentId,
          allNodes,
          (movedNodeId: Uuid) => draggedPid || allNodes.get(movedNodeId),
        );
        patchNodeTree({[id]: newParentId});
        patchSortOrder(
          newSiblings.reduce<Record<Uuid, number>>((acc, sibling, idx) => {
            acc[sibling.id] = idx;
            return acc;
          }, {}),
        );
      }
    },
    [allNodes, patchNodeTree, patchSortOrder, draggedPid],
  );

  return {treeOptions, resetNodeTree, resetSortOrder, move, hasLoadedOnce, isLoading, flat, allNodes};
}
