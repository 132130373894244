import {warning} from '@octaved/env/src/Logger';
import {once} from 'lodash';

const emptyArray: unknown[] = [];
const limit = 500;
const moduleLoaded = Date.now();
const persistedKeys: string[] = [];
const persistedResults = new Map<string, ReadonlyArray<string>>();

/**
 * Send a warning to the backend to track how long it takes for users to run into the cache limit
 */
const notifyLimitReached = once(() => {
  const minutes = Math.round((moduleLoaded - Date.now()) / 60000);
  warning(`persistScalarArray reached its limit`, {minutes});
});

/**
 * Keeps the resulting array instances identical if their content is the same
 *
 * The sort order is preserved, so if you want the same array instance regardless of its sort order, sort the array
 * before calling this method.
 */
export function persistScalarArray<T>(result: ReadonlyArray<T>): ReadonlyArray<T> {
  if (!result.length) {
    return emptyArray as ReadonlyArray<T>;
  }

  const key = JSON.stringify(result);
  let persisted = persistedResults.get(key) as ReadonlyArray<T> | undefined;
  if (!persisted) {
    persisted = result;
    persistedKeys.push(key);
    persistedResults.set(key, result as ReadonlyArray<string>);
  }

  if (persistedKeys.length > limit) {
    notifyLimitReached();
  }

  //Only keep those many results persisted:
  while (persistedKeys.length > limit) {
    persistedResults.delete(persistedKeys.shift()!);
  }

  return persisted;
}
