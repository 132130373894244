import {IconButton} from '@octaved/ui';
import {Check, MoreHorizontal} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DropdownPopup from '../../../Components/Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../../Components/Form/Dropdown/DropdownPopup/Types';
import {projectControllingDemoModeSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {setProjectControllingDemoMode} from '../../../Modules/UiPages/Projects';

export default function ProjectControllingInspectorSubMenu(): ReactElement {
  const demoMode = useSelector(projectControllingDemoModeSelector);
  const {t} = useTranslation();

  const options = useMemo<DropdownPopupItemOptions[]>(
    () => [
      {
        icon: <Check />,
        iconColor: demoMode ? 'green' : 'white',
        onClick: () => setProjectControllingDemoMode(!demoMode),
        token: t('pages:projects.inspector.demoMode'),
        type: DropdownPopupItemType.item,
      },
    ],
    [demoMode, t],
  );

  return (
    <DropdownPopup items={options} closeOnSelect position={'bottom right'}>
      <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
    </DropdownPopup>
  );
}
