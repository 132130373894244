import {
  isGroup,
  isMaterialResource,
  isSubWorkPackage,
  isTask,
  isWorkPackage,
} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';

export const MAX_PLANNING_DATES = 52;

export const canAddGapBarSelector = createSelector(
  getNodeSelector,
  isGrantedSelector,
  (getNode, isGranted) =>
    (nodeId: MaybeUuid, isCanvas = false): boolean => {
      const node = getNode(nodeId);
      if (isMaterialResource(node)) {
        //material resource does not have any planning date restrictions
        return true;
      }
      if (
        (isTask(node) && isGranted('FLOW_NODE_TASK_MANAGE_BASIC', nodeId)) ||
        (isWorkPackage(node) && isGranted('FLOW_NODE_PID_MANAGE_BASIC', nodeId)) ||
        (isSubWorkPackage(node) && isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', nodeId))
      ) {
        const limit = !isCanvas ? MAX_PLANNING_DATES : 1;
        return node.planningDates.length < limit;
      }
      if (isGroup(node) && isGranted('FLOW_NODE_PID_MANAGE_BASIC', nodeId)) {
        const limit = 1;
        return node.planningDates.length < limit;
      }
      return false;
    },
);
