import {FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST} from '@octaved/flow/src/Modules/ActionTypes';
import {NodesResponsibilitiesBulkPatchEvent} from '@octaved/flow/src/Modules/Events';
import {UnknownAction} from 'redux';
import UpdateWorkloadContext from './UpdateWorkloadContext';

function isPatchNodesResponsibilitiesRequestAction(
  action: UnknownAction,
): action is NodesResponsibilitiesBulkPatchEvent {
  return action.type === FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST;
}

export function handlePatchNodesResponsibilitiesAction(context: UpdateWorkloadContext, action: UnknownAction): boolean {
  const canHandle = isPatchNodesResponsibilitiesRequestAction(action);
  if (canHandle && action.patches) {
    for (const nodeId of Object.keys(action.patches)) {
      context.updateWorkloadForNode(nodeId);
    }
  }
  return canHandle;
}
