import {Dispatch, ReactElement, SetStateAction} from 'react';
import FilterContent from '../../../Components/AdvancedFilter/FilterContent';
import {useApplyCurrentFilters, useResetCurrentFilters} from '../../../Modules/Projects/Filters/ProjectFilters';
import {useProjectFrameContext} from '../Pages/ProjectsFrameContext';
import DefaultAdvancedFilter from './AdvancedFilter/DefaultAdvancedFilter';

interface Props {
  active: boolean;
  setAdvancedFilterOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AdvancedFilter({setAdvancedFilterOpen}: Props): ReactElement {
  const reset = useResetCurrentFilters();
  const {onClick} = useApplyCurrentFilters();
  const {advancedFilter} = useProjectFrameContext();
  const Filters = advancedFilter || DefaultAdvancedFilter;
  return (
    <FilterContent
      onCancel={() => setAdvancedFilterOpen(false)}
      onSearch={() => {
        onClick();
        setAdvancedFilterOpen(false);
      }}
      onReset={() => {
        reset();
        setAdvancedFilterOpen(false);
      }}
    >
      <Filters />
    </FilterContent>
  );
}
