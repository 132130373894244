import {cn} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';

interface InspectorFrameProps {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

export default function InspectorFrame({children, footer, header}: InspectorFrameProps): ReactElement {
  return (
    <div className={'flex h-full flex-grow flex-col items-stretch overflow-hidden bg-white'}>
      {header && <div className={'border-b- flex-shrink-0 border-b-slate-200'}>{header}</div>}
      <div className={cn('flex flex-grow flex-col overflow-hidden', footer && 'overflow-y-auto')}>{children}</div>
      {footer && <div className={'py-5'}>{footer}</div>}
    </div>
  );
}
