import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getSubtreeStats} from '@octaved/flow-api/config/routes';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStateAction,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
  reduceStateIds,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {debounceReduxAction} from '@octaved/utilities/src/DebounceReduxAction';
import {objectEntries} from '@octaved/utilities/src/Object';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  initialSubtreeStats,
  LoadSubtreeStats,
  SubtreeStatIdent,
  SubtreeStats,
  subtreeStatsIdents,
} from '../../EntityInterfaces/Statistics/SubtreeStats';
import {
  FLOW_LOAD_SUBTREE_STATS_FAILURE,
  FLOW_LOAD_SUBTREE_STATS_REQUEST,
  FLOW_LOAD_SUBTREE_STATS_SUCCESS,
} from '../ActionTypes';
import {
  NodeCreatedEvent,
  NodePatchedEvent,
  NodeRoleAssignmentEvent,
  NodesRemovedEvent,
  TaskCreatedEvent,
  TaskPatchedEvent,
  TasksCopiedEvent,
  TimeRecordCreatedEvent,
  TimeRecordPatchedEvent,
  TimeRecordRemovedEvent,
  WorkPackageCreatedEvent,
  WorkPackagePatchedEvent,
} from '../Events';
import {extendWithAncestorsSelector, extendWithDescendantsSelector} from '../Selectors/NodeTreeSelectors';
import {
  subtreeStatsLoadedOnceSelector,
  subtreeStatsSelector,
  subtreeStatsStatesSelector,
} from '../Selectors/Statistics/SubtreeStatsSelectors';
import {FlowState} from '../State';

interface LoadRequest {
  response: SubtreeStats;
  type: typeof FLOW_LOAD_SUBTREE_STATS_SUCCESS;
}

const reducers = createReducerCollection<SubtreeStats>(initialSubtreeStats);
reducers.add<LoadRequest>(FLOW_LOAD_SUBTREE_STATS_SUCCESS, (state, {response}) => {
  return mergeStates(state, response);
});
export const subtreeStatsReducer = reducers.reducer;

const stateReducers = createReducerCollection({});
stateReducers.add(FLOW_LOAD_SUBTREE_STATS_REQUEST, createTimestampReducer('stateKeys', LOADING));
stateReducers.add(FLOW_LOAD_SUBTREE_STATS_SUCCESS, createTimestampReducer('stateKeys', LOADED));

//The move events don't contain information about the previous parent, which is needed to accurately invalidate, so
// we must clear all:
stateReducers.add(
  ['flow.NodeMovedEvent', 'flow.NodesRearrangeEvent', 'flow.TasksMovedEvent', 'flow.TaskSectionsMovedEvent'],
  () => ({}),
);

export const subtreeStatsStateReducer = stateReducers.reducer;

function invalidateById(
  state: FlowState,
  action: EntityStateAction,
  ident: SubtreeStatIdent | ReadonlyArray<SubtreeStatIdent> | ReadonlySet<SubtreeStatIdent>,
  id: string | ReadonlyArray<string> | ReadonlySet<string>,
  involvesInheritance = false,
): FlowState {
  //Usually only the ancestors need to be invalidated, unless there is inheritance, which also affects the descendants:
  const ids = typeof id === 'string' ? [id] : [...id];
  const ancestors = extendWithAncestorsSelector(state)(ids);
  const descendants = involvesInheritance ? extendWithDescendantsSelector(state)(ids) : [];
  const idents = typeof ident === 'string' ? [ident] : [...ident];
  const keys: string[] = [];
  [...ancestors, ...descendants].forEach((id) => idents.forEach((ident) => keys.push(`${ident}-${id}`)));
  return mergeStates(state, {
    statistics: {
      subtreeStats: {state: reduceStateIds(subtreeStatsStatesSelector(state), action, INVALIDATED, keys)},
    },
  });
}

const rootReducers = createReducerCollection<FlowState>({} as FlowState);

rootReducers.add<NodeCreatedEvent>('flow.NodeCreatedEvent', (state, action) => {
  if (action.nodeType === EnumFlowNodeType.VALUE_BOARD_POST) {
    return invalidateById(state, action, 'board', action.parentNodeId, true);
  }
  return state;
});

rootReducers.add<NodePatchedEvent>('flow.NodePatchedEvent', (state, action) => {
  if (action.nodeType === EnumFlowNodeType.VALUE_BOARD_POST) {
    return invalidateById(state, action, 'board', action.nodeId, true);
  }
  return state;
});

rootReducers.add<NodeRoleAssignmentEvent>('flow.NodeRoleAssignmentEvent', (state, action) => {
  if (action.isGuestRole) {
    return invalidateById(state, action, 'hasGuestAccess', action.nodeId, true);
  }
  return state;
});

rootReducers.add<TaskCreatedEvent>('flow.TaskCreatedEvent', (state, action) => {
  return invalidateById(state, action, 'taskCountsAndTimeSums', action.parentNodeId);
});

rootReducers.add<TasksCopiedEvent>('flow.TasksCopiedEvent', (state, action) => {
  return invalidateById(state, action, 'taskCountsAndTimeSums', action.toWorkPackageId);
});

rootReducers.add<TaskPatchedEvent>('flow.TaskPatchedEvent', (state, action) => {
  if (action.patchedKeys.includes('status') || action.patchedKeys.includes('plannedTime')) {
    return invalidateById(state, action, 'taskCountsAndTimeSums', action.nodeId);
  }
  return state;
});

rootReducers.add<WorkPackagePatchedEvent>('flow.WorkPackagePatchedEvent', (state, action) => {
  if (action.patchedKeys.includes('isOffer')) {
    // `taskCountsAndTimeSums` excludes offers, so we must clear all descending(inheriting), too:
    return invalidateById(state, action, 'taskCountsAndTimeSums', action.nodeId, true);
  }
  return state;
});

rootReducers.add<TimeRecordCreatedEvent | TimeRecordPatchedEvent | TimeRecordRemovedEvent>(
  ['flow.TimeRecordCreatedEvent', 'flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  (state, action) => {
    return invalidateById(state, action, 'trackedTimeWithEffort', action.affectedTimeTrackingNodeIds);
  },
);

rootReducers.add<WorkPackageCreatedEvent>('flow.WorkPackageCreatedEvent', (state, action) => {
  return invalidateById(state, action, 'trackedTimeWithEffort', action.parentNodeId);
});

rootReducers.add<WorkPackagePatchedEvent>('flow.WorkPackagePatchedEvent', (state, action) => {
  if (
    action.patchedKeys.includes('billingType') ||
    action.patchedKeys.includes('maxEffort') ||
    action.patchedKeys.includes('effortTo')
  ) {
    return invalidateById(state, action, 'trackedTimeWithEffort', action.nodeId);
  }
  return state;
});

rootReducers.add<NodesRemovedEvent>('flow.NodesRemovedEvent', (state, action) => {
  return invalidateById(state, action, subtreeStatsIdents, action.nodeIds);
});

export const subtreeStatsRootReducer = rootReducers.reducer;

const loadSubtreeStats = debounceReduxAction<LoadSubtreeStats, Partial<{[k in SubtreeStatIdent]: Set<Uuid>}>, never[]>(
  function (wanted: LoadSubtreeStats): ActionDispatcher<void, FlowState> {
    return (dispatch, getState) => {
      const state = getState();
      const entityStates = subtreeStatsStatesSelector(state);
      const toLoad: LoadSubtreeStats = {};
      const stateKeysToLoad = new Set<string>();

      objectEntries(wanted).forEach(([ident, ids]) => {
        const idsToLoad = new Set<Uuid>();
        ids.forEach((id) => {
          const key = `${ident}-${id}`;
          const idState = entityStates[key];
          if (!idState || isOutdated(idState)) {
            stateKeysToLoad.add(key);
            idsToLoad.add(id);
          }
        });
        if (idsToLoad.size) {
          toLoad[ident] = [...idsToLoad];
        }
      });

      if (stateKeysToLoad.size) {
        dispatch({
          [CALL_API]: {
            endpoint: getSubtreeStats,
            method: 'post',
            options: {data: toLoad},
            types: {
              failureType: FLOW_LOAD_SUBTREE_STATS_FAILURE,
              requestType: FLOW_LOAD_SUBTREE_STATS_REQUEST,
              successType: FLOW_LOAD_SUBTREE_STATS_SUCCESS,
            },
          },
          stateKeys: [...stateKeysToLoad],
        });
      }
    };
  },
  {
    createValuesCache: () => ({}),
    getValuesFromCache: (val) => val,
    mergeValues: (cache, values) => {
      objectEntries(values).forEach(([ident, ids]) => {
        cache[ident] = cache[ident] ?? new Set();
        ids.forEach((id) => cache[ident]!.add(id));
      });
    },
  },
);

export function useLoadSubtreeStats(wanted: LoadSubtreeStats): boolean {
  useStoreEffect((dispatch) => dispatch(loadSubtreeStats(wanted)), [wanted], subtreeStatsStatesSelector);
  return useSelector((s: FlowState) => subtreeStatsLoadedOnceSelector(s)(wanted));
}

export function useSingleSubtreeStat<I extends SubtreeStatIdent>(
  ident: I,
  nodeId: Uuid | null | undefined,
): SubtreeStats[I][Uuid] | undefined {
  useLoadSubtreeStats(useMemo(() => ({[ident]: [nodeId]}), [ident, nodeId]));
  return useSelector((s: FlowState) => subtreeStatsSelector(s)[ident][nodeId || '']) as
    | SubtreeStats[I][Uuid]
    | undefined;
}
