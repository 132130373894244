import {throttle} from 'lodash';
import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type MouseResizeEvents = ReactMouseEvent | ReactTouchEvent | MouseEvent | TouchEvent;

function isTouchEvent(event: unknown): event is TouchEvent {
  return window.TouchEvent && event instanceof TouchEvent;
}

export function getClientXY(event: MouseResizeEvents): [number, number] {
  let clientX = 0;
  let clientY = 0;
  if (event instanceof MouseEvent) {
    clientX = event.clientX;
    clientY = event.clientY;
  } else if (isTouchEvent(event)) {
    clientX = event.changedTouches[0].clientX;
    clientY = event.changedTouches[0].clientY;
  } else if (event.nativeEvent instanceof MouseEvent) {
    clientX = event.nativeEvent.clientX;
    clientY = event.nativeEvent.clientY;
  } else if (isTouchEvent(event.nativeEvent)) {
    clientX = event.nativeEvent.changedTouches[0].clientX;
    clientY = event.nativeEvent.changedTouches[0].clientY;
  }
  return [clientX, clientY];
}

export interface PositionCallback {
  (clientX: number, clientY: number, event: MouseEvent | TouchEvent, clientStartX: number, clientStartY: number): void;
}

interface OnMouseDown {
  (event: MouseResizeEvents): void;
}

export default function useResizeHook(
  onMove: PositionCallback,
  onFinish: PositionCallback,
  throttleDuration = 100,
): OnMouseDown {
  const [isResizing, setIsResizing] = useState(false);
  const startPos = useRef<[number, number] | null>(null);

  useEffect(() => {
    const onMoveEvent = throttle((event: MouseEvent | TouchEvent) => {
      if (startPos.current) {
        onMove(...getClientXY(event), event, ...startPos.current);
      }
    }, throttleDuration);

    function onUp(event: MouseEvent | TouchEvent): void {
      if (startPos.current) {
        setIsResizing(false);
        onFinish(...getClientXY(event), event, ...startPos.current);
        startPos.current = null;
      }
    }

    if (isResizing) {
      document.addEventListener('mousemove', onMoveEvent);
      document.addEventListener('touchmove', onMoveEvent);
      document.addEventListener('mouseup', onUp);
      document.addEventListener('touchend', onUp);
    }
    return () => {
      document.removeEventListener('mousemove', onMoveEvent);
      document.removeEventListener('touchmove', onMoveEvent);
      document.removeEventListener('mouseup', onUp);
      document.removeEventListener('touchend', onUp);
    };
  }, [isResizing, onMove, onFinish, throttleDuration]);

  return useCallback((event: MouseResizeEvents) => {
    event.stopPropagation();
    event.preventDefault();
    startPos.current = getClientXY(event);
    setIsResizing(true);
  }, []);
}
