import {isDebug} from '@octaved/env/src/Environment';
import {createDebugCombinedSearch, createUseCombinedSearch} from '@octaved/hooks/src/CombinedSearch';
import {
  NodeSearchCustomCombiners,
  nodeSearchCustomCombinersSelector,
} from '@octaved/node-search/src/Selectors/NodeSearchCombinersSelector';
import {Uuid} from '@octaved/typescript/src/lib';
import {createPromise} from '@octaved/utilities';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {NodeSearchCondition, NodeSearchIdent, NodeSearchTuple} from '../../EntityInterfaces/NodeSearch';
import {searchNodes} from '../NodeSearch';
import {getNodeSearchKey, nodeSearchSelector, nodeSearchStateSelector} from '../Selectors/NodeSearchSelectors';
import {FlowState} from '../State';

export interface NodeSearchResult {
  hasLoadedOnce: boolean; //resets together with the cache, see option `invalidateCacheOn`
  isActive: boolean;
  nodeIds: ReadonlyArray<Uuid>;
}

export interface NodeSearchResultWithLoading extends NodeSearchResult {
  isLoading: boolean;
}

if (isDebug) {
  // @ts-ignore debug method
  window.debugNodeSearch = createDebugCombinedSearch<Uuid, NodeSearchTuple, FlowState>(
    getNodeSearchKey,
    nodeSearchSelector,
  );
}

export const useCombinedNodeSearches = createUseCombinedSearch<
  Uuid,
  NodeSearchTuple,
  FlowState,
  NodeSearchCustomCombiners
>(getNodeSearchKey, nodeSearchSelector, nodeSearchStateSelector, searchNodes, nodeSearchCustomCombinersSelector);

export function useCombinedNodeSearch<TrackIsLoading extends boolean>(
  query: NodeSearchCondition | null, //null to disable/inactivate the search
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? NodeSearchResultWithLoading : NodeSearchResult {
  const {hasLoadedOnce, ids, isActive, isLoading} = useCombinedNodeSearches(
    {trackIsLoading: trackIsLoading as true},
    query,
  )[0];
  return {hasLoadedOnce, nodeIds: ids, isActive, isLoading} as NodeSearchResultWithLoading;
}

export function useNodeSearch<TrackIsLoading extends boolean>(
  ident: NodeSearchIdent,
  value?: string,
  active = true,
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? NodeSearchResultWithLoading : NodeSearchResult {
  const query = useMemo<NodeSearchTuple | null>(
    () => (active ? ([ident, value] as NodeSearchTuple) : null),
    [ident, value, active],
  );
  return useCombinedNodeSearch(query, trackIsLoading);
}

export function useAsyncNodeSearch(): (query: NodeSearchCondition) => Promise<ReadonlyArray<Uuid>> {
  const nextIndex = useRef(0);
  const resolves = useRef<Array<((ids: ReadonlyArray<Uuid>) => void) | null>>([]);
  const [queries, setQueries] = useState<Array<NodeSearchCondition | null>>([]);

  const results = useCombinedNodeSearches({}, ...queries);

  useEffect(() => {
    setQueries((queries) => {
      let queriesChanged = false;
      results.forEach((result, index) => {
        const resolve = resolves.current[index];
        if (resolve && result.hasLoadedOnce) {
          resolve(result.ids);
          resolves.current[index] = null;
          queries[index] = null;
          queriesChanged = true;
        }
      });
      return queriesChanged ? [...queries] : queries;
    });
  }, [results]);

  return useCallback((query: NodeSearchCondition) => {
    const index = nextIndex.current;
    nextIndex.current++;
    const [promise, resolve] = createPromise<ReadonlyArray<Uuid>>();
    resolves.current[index] = resolve;
    setQueries((queries) => {
      queries[index] = query;
      return [...queries];
    });
    return promise;
  }, []);
}
