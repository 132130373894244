import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {Tooltip} from '@octaved/ui';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {hiddenBillingTypesForExceedingTaskEffort} from '../../Tiles/Components/ExceedsTaskEffortFlameSection';
import {useNodeTaskPrognosis} from '../../Values/NodeTasksValue';

interface ExceedsTaskEffortFlameProps {
  node: NodeType;
}

export default function ExceedsTaskEffortFlame({node}: ExceedsTaskEffortFlameProps): ReactElement | null {
  const {isExceeding} = useNodeTaskPrognosis(node.id);
  const hiddenByBillingType =
    isWorkPackage(node) && node.billingType && hiddenBillingTypesForExceedingTaskEffort.has(node.billingType);
  if (isExceeding && !hiddenByBillingType) {
    return (
      <Tooltip toolTipTranslation={'nodeDetails:field.tasks.tasksHours.exceedsRemainingEffort'} position={'top right'}>
        <Flame className={'h-4 w-4 text-red-500'} />
      </Tooltip>
    );
  }
  return null;
}
