import {useScrollIntoView} from '@octaved/hooks/src/ScrollIntoView';
import Flex from '@octaved/ui-components/src/Flex';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import classNames from 'classnames';
import {ReactElement, useContext} from 'react';
import {ProjectTreeProject} from '../../../Modules/Projects/ProjectTreeInterfaces';
import {flowStyleContext} from '../../../Styles/StyleContext';
import NodeName from '../../Node/NodeName';
import OpenInspector from '../../Pid/OpenInspector';
import {useProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';
import CustomerName from '../Defaults/Details/CustomerName';
import {projectsTreeComponentContext} from '../ProjectTreeComponentContext';
import ToggleSubPids from './ToggleSubPids';
import {ContextMenu} from '@octaved/node-details/src/Components/ContextMenu/ContextMenu';
import {ContextMenuTrigger} from '@octaved/node-details/src/Components/ContextMenu/ContextMenuTrigger';

export interface ProjectParams {
  node: ProjectTreeProject;
}

export default function Project({node}: ProjectParams): ReactElement | null {
  const {
    ANIMATIONS: {FAST},
  } = useContext(flowStyleContext);
  const project = node.entity;
  const {groupsAndWorkPackages: GroupsAndWorkPackages, projectDetails: DefaultProjectDetails} =
    useContext(projectsTreeComponentContext);
  const {
    canOpenPidInspector,
    compactView: compact,
    greyedOutNodeIds,
    selectableNodeTypes,
    showPidInspectorMenu,
  } = useProjectTreeContext();
  const isSelectable = selectableNodeTypes.has(node.type);
  const isSelected = isSelectable && node.isSelected;
  const projectRef = useScrollIntoView<HTMLDivElement>(isSelected, 250);

  if (!project) {
    return null;
  }

  return (
    <div className={classNames('projectRow', {compact})}>
      <div
        className={classNames('projectGrid', {
          isSelectable,
          greyOut: greyedOutNodeIds && greyedOutNodeIds.has(node.id),
          noIcon: project.color.length === 0,
          selected: isSelected,
        })}
        onClick={(e) => (isSelectable ? node.select() : node.toggleExpansion(e))}
        ref={projectRef}
      >
        <ToggleSubPids isExpanded={node.isExpanded} toggleExpansion={node.toggleExpansion} />

        <div className={'pidName'}>
          <span className={'name'}>
            <NodeName nodeId={project.id} />
            {showPidInspectorMenu && (
              <div className={'projectMenu'}>
                <ContextMenu nodeId={project.id}>
                  <ContextMenuTrigger />
                </ContextMenu>
              </div>
            )}
          </span>
          {canOpenPidInspector && (
            <span className={'openPidInspector'}>
              <OpenInspector id={project.id} rectangle />
            </span>
          )}
        </div>

        {!compact && (
          <>
            <div className={'projectColor'} />
            <Flex fullWidth justifyContent={'flex-end'}>
              <DefaultProjectDetails node={node} />
            </Flex>
          </>
        )}

        {compact && (
          <Flex fullWidth justifyContent={'flex-end'}>
            <div className={'text-right'}>
              <CustomerName node={node} className={'mr-0'} />
            </div>
          </Flex>
        )}
      </div>

      <SlideToggleElement visible={node.isExpanded}>
        <div className={'children'}>
          <GroupsAndWorkPackages parentNode={node} />
        </div>
      </SlideToggleElement>

      {/*#region style*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .projectRow {
          padding: 6px 0 6px 0;
          transition:
            background-color ${FAST},
            border-color ${FAST};
          border-bottom: 1px solid var(--color-border-project, rgba(209, 215, 224, 0.5));

          &:last-child {
            border-bottom: none;
          }

          &.compact {
            border-top: none;
          }
        }

        .selected {
          background-color: var(--color-bg-selected, #edf5fb);
        }

        .children {
          width: 100%;
          padding: 12px 0 12px 0;
        }

        .projectGrid {
          display: grid;
          grid-template-columns: 21px 0.3fr 30px 0.7fr 28px;
          grid-column-gap: 8px;
          align-items: center;
          margin: -8px 0;
          padding: 8px 5px 8px 0;
          transition:
            opacity ${FAST},
            filter ${FAST};
          cursor: pointer;

          &.isSelectable:hover {
            background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.05));
          }

          .compact & {
            grid-template-columns: 21px 1fr max-content;
            grid-column-gap: 8px;
          }
        }

        .pidName {
          font-weight: 600;
          font-size: 14px;
          color: var(--color-font-project-name, #333);
          margin-left: -6px;
        }

        .projectMenu {
          display: inline-flex;
          margin-left: 5px;
          visibility: hidden;
          pointer-events: none;
          vertical-align: -0.25em;
        }

        .projectGrid:hover .projectMenu {
          visibility: visible;
          pointer-events: all;
        }

        .projectColor {
          height: 4px;
          width: 15px;
          border-radius: 2px;
          background-color: ${'#' + node.entity?.color};
        }

        .greyOut {
          opacity: 0.5;
          filter: grayscale(0.8);
        }

        .name {
          vertical-align: top;
        }

        .openPidInspector {
          margin-left: 5px;
          visibility: hidden;
          pointer-events: none;

          & :global(button) {
            vertical-align: -0.25rem;
          }
        }

        .projectGrid:hover .openPidInspector {
          visibility: visible;
          pointer-events: all;
        }

        .emptyPlaceholder {
          padding: 0 0 18px 55px;
          display: flex;
          grid-gap: 14px;
          flex-direction: column;
        }

        .emptyPlaceholderInner {
          display: flex;
          align-items: center;
          grid-gap: 20px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
