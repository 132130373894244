import Notifications, {Notification} from '@octaved/store/src/Notifications';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {LOAD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS, ORGANIZATION_ALLOWED_IP_CLEAR} from './ActionTypes';

export interface UiPagesOrganizationState {
  loadedOrganizationAllowedLoginIPs: boolean;
  notifications: {
    error: Notification[];
  };
}

export const initialOrganizationUiState: UiPagesOrganizationState = {
  loadedOrganizationAllowedLoginIPs: false,
  notifications: {
    error: [],
  },
};

export const reducerMap = new Map();
const {actions} = Notifications('ui.pages.organization', reducerMap);
export const {setErrors, addError, removeError, addErrors, removeErrorForField} = actions;

reducerMap.set(
  LOAD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
  (state: UiPagesOrganizationState): UiPagesOrganizationState => ({
    ...state,
    loadedOrganizationAllowedLoginIPs: true,
  }),
);

reducerMap.set(
  ORGANIZATION_ALLOWED_IP_CLEAR,
  (state: UiPagesOrganizationState): UiPagesOrganizationState => ({
    ...state,
    loadedOrganizationAllowedLoginIPs: false,
  }),
);

export const reducer = ReduceFromMap(reducerMap, initialOrganizationUiState);
