import {setUiState} from '@octaved/flow/src/Modules/Ui';
import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {ZoomLevel} from '../Components/Calendar/CalendarContainer/CalendarContainerContext';
import {teamPlanningSelectedUnitsSelector} from '../Selectors/TeamPlanningSelectors';

export interface TeamPlanningUiState {
  extendedNodes: Array<Uuid | null>;
  ganttZoom: ZoomLevel;
  hoveringNodeId: Uuid | null;
  selectedUnits: SlimUnit[];
  showHoveringNodeInfo: boolean;
}

export const initialTeamPlanningUiState: TeamPlanningUiState = {
  extendedNodes: [],
  ganttZoom: ZoomLevel['100%'],
  hoveringNodeId: null,
  selectedUnits: [],
  showHoveringNodeInfo: false,
};

export function patchTeamPlanningUiState(teamPlanning: DeepPartial<TeamPlanningUiState>): ThunkAct<void> {
  return setUiState({pages: {teamPlanning}});
}

export function patchTeamPlanningZoomLevel(ganttZoom: ZoomLevel): ThunkAct<void> {
  return patchTeamPlanningUiState({ganttZoom});
}

export function toggleSelectedTeamPlanningUnit(unit: SlimUnit): ThunkAct<void> {
  return (dispatch, getState) => {
    const current = teamPlanningSelectedUnitsSelector(getState());
    const index = current.findIndex(({unitId}) => unitId === unit.unitId);
    const newUnits =
      index === -1 ? [...current, {unitId: unit.unitId, unitType: unit.unitType}] : current.toSpliced(index, 1);
    dispatch(patchTeamPlanningUiState({selectedUnits: newUnits}));
  };
}

export function setHoveringNodeId(hoveringNodeId: Uuid | null): ThunkAct<void> {
  return patchTeamPlanningUiState({hoveringNodeId});
}

export function setShowHoveringNodeInfo(showHoveringNodeInfo: boolean): ThunkAct<void> {
  return patchTeamPlanningUiState({showHoveringNodeInfo});
}
