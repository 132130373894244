import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import Konva from 'konva';
import {BarTextDisplayment} from '../../../../Modules/Ui';
import {Bar} from '../../../Bars/Bar';
import {BarPlugin} from '../../../Bars/Plugins/BarPlugin';
import {ResizeBarPlugin} from '../../../Bars/Plugins/ResizeBarPlugin';
import {createText} from '../../../CommonComponents/Text';
import {createStoreSubscription} from '../../../StoreSubscription';
import {GanttContext} from '../../Context/GanttContext';

export class NameBarPlugin extends BarPlugin<GanttContext> {
  #text: Konva.Text | null = null;

  init(bar: Bar): void {
    super.init(bar);
    this.#createLabel();

    this.disposables.push(
      this.ctx.eventEmitter.on('visibleColumnsChanged', () => this.#updateLabel()),
      createStoreSubscription(
        this.ctx.store,
        (s) => this.ctx.getBarTextDisplaymentSelector(s),
        () => this.#updateLabel(),
      ),
    );
  }

  #calcIsVisible(): boolean {
    const barTextDisplayment = this.ctx.getBarTextDisplaymentSelector(this.ctx.store.getState());
    return barTextDisplayment === BarTextDisplayment.always ? true : this.ctx.visibleColumns.includes('hideAll');
  }

  #createLabel(): void {
    const node = getNodeSelector(this.ctx.store.getState())(this.nodeId);
    this.#text = createText({
      text: node?.name,
    });
    this.root.add(this.#text);
  }

  #updateLabel(): void {
    const isVisible = this.#calcIsVisible();
    this.isDisabled = !isVisible;
    this.#text?.visible(isVisible);

    if (isVisible) {
      const node = getNodeSelector(this.ctx.store.getState())(this.nodeId);
      if (node) {
        this.#text?.text(node.name);
      }
      this.#text?.x(this.bar.width + ResizeBarPlugin.imageSize);
      this.#text?.y(this.bar.height / 2 - this.#text.height() / 2);
    }
  }

  onBarUpdated(): void {
    super.onBarUpdated();
    this.#updateLabel();
  }
}
