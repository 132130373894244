import {useScrollIntoView} from '@octaved/hooks/src/ScrollIntoView';
import {Uuid} from '@octaved/typescript/src/lib';
import {forwardRef, ReactElement, ReactNode, useCallback, useContext} from 'react';
import {ProjectTreeWorkPackage} from '../../../Modules/Projects/ProjectTreeInterfaces';
import {useProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';
import WorkPackageTile from '../../WorkPackage/Tile/WorkPackageTile';
import {projectsTreeComponentContext} from '../ProjectTreeComponentContext';
import {ContextMenu} from '@octaved/node-details/src/Components/ContextMenu/ContextMenu';
import {ContextMenuTrigger} from '@octaved/node-details/src/Components/ContextMenu/ContextMenuTrigger';

export interface WorkPackageParams {
  node: ProjectTreeWorkPackage;
  index: number;
  className?: string;
  children?: ReactNode;
  parentId: Uuid;
}

export default forwardRef<HTMLDivElement, WorkPackageParams>(function WorkPackageAsTile(
  {node, children, className}: WorkPackageParams,
  ref,
): ReactElement {
  const {
    canOpenPidInspector,
    compactView,
    focusWorkPackageFavorites,
    greyedOutNodeIds,
    selectableNodeTypes,
    showPidInspectorMenu,
  } = useProjectTreeContext();
  const isSelectable = selectableNodeTypes.has(node.type);
  const isSelected = isSelectable && node.isSelected;
  const select = useCallback(() => isSelectable && node.select(), [isSelectable, node]);
  const tileRef = useScrollIntoView<HTMLDivElement>(isSelected, 250);

  const {workPackageContextDetails: ContextDetails} = useContext(projectsTreeComponentContext);
  return (
    <div className={'w-[280px] p-1.5'} ref={ref}>
      <WorkPackageTile
        canOpenPidInspector={canOpenPidInspector}
        className={className}
        compact={compactView}
        focusFavoriteButton={focusWorkPackageFavorites}
        fullHeight
        id={node.id}
        isSelectable={isSelectable}
        onClick={select}
        opaque={greyedOutNodeIds?.has(node.id)}
        ref={tileRef}
        selected={isSelected}
        actionButton={
          showPidInspectorMenu ? (
            <ContextMenu nodeId={node.id}>
              <ContextMenuTrigger />
            </ContextMenu>
          ) : undefined
        }
      >
        {!compactView && ContextDetails && <ContextDetails node={node} />}
      </WorkPackageTile>
      {children}
    </div>
  );
});
