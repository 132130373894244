import BaseFlowAvatar from '@octaved/flow/src/Components/Avatar/BaseFlowAvatar';
import {ReactElement} from 'react';
import {SlimUnit} from '../EntityInterfaces/UnitLists';
import {useExtendUnitsWithName} from '../Hooks/UnitLists';

interface Props {
  units: ReadonlyArray<SlimUnit>;
}

export default function UnitHStack({units}: Props): ReactElement {
  const unitsWithName = useExtendUnitsWithName(units);
  return (
    <div className={'flex items-center gap-x-2'}>
      <div className={'flex items-center gap-x-1'}>
        {unitsWithName.slice(0, 3).map(({unitId, unitType}) => (
          <BaseFlowAvatar key={unitId} id={unitId} noYMargin type={unitType} size={'small'} />
        ))}
      </div>
      {unitsWithName.length === 1 && <div>{unitsWithName[0].unitName}</div>}
      {unitsWithName.length > 3 && <div>+{unitsWithName.length - 3}</div>}
    </div>
  );
}
