import {NodeTree} from '@octaved/flow/src/EntityInterfaces/NodeTree';
import {
  getUnitWorkMinutesAtDateSelector,
  GetWorkingTimeAtDate,
} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {getAllDescendantsForRootId} from '@octaved/trees/src/GenericTreeBuilder';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Workload, WorkloadPlannedNode, WorkloadPlanningDate} from '../EntityInterfaces/Workload';
import {WorkloadDisplayment} from '../Modules/Ui';

export interface CalculatedDayWorkload {
  dailyUserWorkingHours: number;
  day: DateStr;
  personalTaskHours: number;
  totalWorkload: number;
  visibleWorkload: number;
}

export type WorkloadMap = Map<DateStr, CalculatedDayWorkload>;

export interface WorkloadCalculationContext {
  calculatedWorkloadMap: WorkloadMap;
  getOrgWorkMinutesAtDate: GetWorkingTimeAtDate;
  getWorkMinutesAtDate: GetWorkingTimeAtDate;
  nodeTree: NodeTree;
  userWorkLoad: Workload;
  visibleIds: Set<Uuid>;
}

export function mergeWorkloadMaps(
  getUnitWorkMinutesAtDate: ReturnType<typeof getUnitWorkMinutesAtDateSelector>,
  userIds: Uuid[],
  ...workloadMaps: WorkloadMap[]
): WorkloadMap {
  const mergedMap: WorkloadMap = new Map();
  for (const workloadMap of workloadMaps) {
    for (const [key, value] of workloadMap) {
      const mergedValue = mergedMap.get(key);
      if (mergedValue) {
        mergedValue.totalWorkload += value.totalWorkload;
        mergedValue.visibleWorkload += value.visibleWorkload;
        mergedValue.personalTaskHours += value.personalTaskHours;
      } else {
        mergedMap.set(key, {...value, dailyUserWorkingHours: 0});
      }
    }
  }
  for (const [date, workLoad] of mergedMap) {
    for (const userId of userIds) {
      workLoad.dailyUserWorkingHours += getUnitWorkMinutesAtDate(userId)(date).project / 60;
    }
  }
  return mergedMap;
}

function foreachDay(planningDates: WorkloadPlanningDate[] | null, cb: (date: DateStr) => void): DateStr[] {
  if (!planningDates) {
    return [];
  }
  const days: DateStr[] = [];
  for (const planningDate of planningDates) {
    let currentDay = fromIsoDateTimeFormat(planningDate.plannedStart);
    const endDay = fromIsoDateTimeFormat(planningDate.plannedEnd);
    while (currentDay.isBefore(endDay, 'day') || currentDay.isSame(endDay, 'day')) {
      const currentIsoDate = toIsoFormat(currentDay);
      cb(currentIsoDate);
      days.push(currentIsoDate);
      currentDay = currentDay.add(1, 'day');
    }
  }
  return days;
}

function createBaseEntry(day: DateStr, dailyUserWorkingHours: number): CalculatedDayWorkload {
  return {
    dailyUserWorkingHours,
    day,
    personalTaskHours: 0,
    totalWorkload: 0,
    visibleWorkload: 0,
  };
}

function getOrCreateMapEntry(
  map: WorkloadMap,
  day: DateStr,
  context: WorkloadCalculationContext,
): CalculatedDayWorkload {
  let workload = map.get(day);
  if (!workload) {
    workload = createBaseEntry(day, context.getWorkMinutesAtDate(day).project / 60);
    map.set(day, workload);
  }
  return workload;
}

function setEffort(
  workload: CalculatedDayWorkload,
  calculatedDayEffort: number,
  isVisible: boolean,
  isPersonal: boolean,
): void {
  workload.totalWorkload += calculatedDayEffort;
  if (isVisible) {
    workload.visibleWorkload += calculatedDayEffort;
  }
  if (isPersonal) {
    workload.personalTaskHours += calculatedDayEffort;
  }
}

function calculateSingleResourceWorkload(
  node: WorkloadPlannedNode,
  isPersonal: boolean,
  context: WorkloadCalculationContext,
  map: WorkloadMap = new Map(),
): WorkloadMap {
  const effort = Math.max(0, node.effort - node.subWorkPackageEffort) / node.responsibleUsers;
  let userTotalWorkingTime = 0;
  let userWorkingDays = 0;
  let orgWorkingDays = 0;
  let calendarDays = 0;

  const days = foreachDay(node.planningDates, (currentIsoDate) => {
    const workload = getOrCreateMapEntry(map, currentIsoDate, context);
    userTotalWorkingTime += workload.dailyUserWorkingHours;
    if (workload.dailyUserWorkingHours > 0) {
      userWorkingDays++;
    }
    if (context.getOrgWorkMinutesAtDate(currentIsoDate)) {
      orgWorkingDays++;
    }
    calendarDays++;
  });

  for (const isoDate of days) {
    const workload = map.get(isoDate);
    if (!workload) {
      throw Error('workload not set');
    }
    let calculatedDayEffort: number;
    if (userWorkingDays > 0) {
      const weight = workload.dailyUserWorkingHours / userTotalWorkingTime;
      calculatedDayEffort = effort * weight;
    } else if (orgWorkingDays > 0) {
      //fallback to working days in company
      calculatedDayEffort = effort / orgWorkingDays;
    } else {
      //fallback to calendar days
      calculatedDayEffort = effort / calendarDays;
    }
    setEffort(workload, calculatedDayEffort, context.visibleIds.has(node.id), isPersonal);
  }
  return map;
}

function getDailyUserWorkingHours(workloads: WorkloadMap, date: DateStr): number {
  const workload = workloads.get(date);
  if (workload) {
    return workload.dailyUserWorkingHours || workload.totalWorkload;
  }
  return 0;
}

function calculateCombinedWorkLoadForSubWorkPackage(
  node: WorkloadPlannedNode,
  context: WorkloadCalculationContext,
  handledTasks: Uuid[],
): number {
  const taskWorkloads: WorkloadMap = new Map();
  const childIds = [...getAllDescendantsForRootId<Uuid>(context.nodeTree, node.id, false)];

  let handledTime = 0;

  for (const childId of childIds) {
    if (context.userWorkLoad.tasks.includes(childId) && !handledTasks.includes(childId)) {
      handledTasks.push(childId);
      const task = context.userWorkLoad.plannedNodes[childId];
      if (task) {
        calculateSingleResourceWorkload(task, false, context, taskWorkloads);
        handledTime += task.effort;
      }
    }
  }
  distributeWorkload(node, handledTime, node.taskEffort, taskWorkloads, context);
  return Math.max(node.taskEffort, node.effort);
}

function calculateCombinedWorkLoadForWorkPackage(
  node: WorkloadPlannedNode,
  context: WorkloadCalculationContext,
  handledTasks: Uuid[],
  handledSubWorkPackages: Uuid[],
): void {
  const taskWorkloads: WorkloadMap = new Map();
  const childIds = [...getAllDescendantsForRootId<Uuid>(context.nodeTree, node.id, false)];

  let childEffort = node.taskEffort;
  let handledTime = 0;

  for (const childId of childIds) {
    if (context.userWorkLoad.subWorkPackages.includes(childId)) {
      handledSubWorkPackages.push(childId);
      const subWorkPackage = context.userWorkLoad.plannedNodes[childId];
      if (subWorkPackage) {
        const effort = calculateCombinedWorkLoadForSubWorkPackage(subWorkPackage, context, handledTasks);
        childEffort -= effort;
        handledTime += effort;
      }
    }
  }
  for (const childId of childIds) {
    if (context.userWorkLoad.tasks.includes(childId) && !handledTasks.includes(childId)) {
      handledTasks.push(childId);
      const task = context.userWorkLoad.plannedNodes[childId];
      if (task) {
        calculateSingleResourceWorkload(task, false, context, taskWorkloads);
        handledTime += task.effort;
      }
    }
  }

  distributeWorkload(node, handledTime, childEffort, taskWorkloads, context);
}

function distributeWorkload(
  node: WorkloadPlannedNode,
  handledTime: number,
  childEffort: number,
  taskWorkloads: WorkloadMap,
  context: WorkloadCalculationContext,
): void {
  const remainingChildEffort = Math.max(node.effort, node.taskEffort) - handledTime;
  const nodeWorkload = calculateSingleResourceWorkload(node, false, context);
  if (remainingChildEffort < 0) {
    //There are no work hours from the work package to be distributed as task have priority
    for (const [currentIsoDate, {totalWorkload}] of taskWorkloads) {
      const workloadMapEntry = getOrCreateMapEntry(context.calculatedWorkloadMap, currentIsoDate, context);
      setEffort(workloadMapEntry, totalWorkload, context.visibleIds.has(node.id), false);
    }
  } else {
    const useRemainingTime = node.effort - handledTime < remainingChildEffort;
    const workPackageRemainingTime = Math.max(
      (node.effort - handledTime) / node.responsibleUsers,
      remainingChildEffort / node.responsibleUsers,
    );
    const dates = new Set([...nodeWorkload.keys(), ...taskWorkloads.keys()]);

    let sumReverseWeight = 0;
    let userTotalWorkingTime = 0;

    for (const currentIsoDate of dates) {
      const workloadTask = taskWorkloads.get(currentIsoDate)?.totalWorkload || 0;
      const reverseWeight = getReverseWeight(useRemainingTime, remainingChildEffort, childEffort, workloadTask);
      sumReverseWeight += reverseWeight;

      const dailyUserWorkingHours = getDailyUserWorkingHours(nodeWorkload, currentIsoDate);
      userTotalWorkingTime += dailyUserWorkingHours;
    }

    for (const currentIsoDate of dates) {
      const workloadTask = taskWorkloads.get(currentIsoDate)?.totalWorkload || 0;
      const dailyUserWorkingHours = getDailyUserWorkingHours(nodeWorkload, currentIsoDate);
      const reverseWeight = getReverseWeight(useRemainingTime, remainingChildEffort, childEffort, workloadTask);
      const userWorkloadWeightCalender = dailyUserWorkingHours / userTotalWorkingTime;
      let workloadWeight = userWorkloadWeightCalender;

      if (sumReverseWeight > 0 && userWorkloadWeightCalender > 0) {
        const userWorkloadWeightTask = reverseWeight / sumReverseWeight;
        workloadWeight = (userWorkloadWeightCalender + userWorkloadWeightTask) / 2;
      }

      const distibutedWorkPackageHours = workPackageRemainingTime * workloadWeight;
      const workload = workloadTask + distibutedWorkPackageHours;
      const workloadMapEntry = getOrCreateMapEntry(context.calculatedWorkloadMap, currentIsoDate, context);
      setEffort(workloadMapEntry, workload, context.visibleIds.has(node.id), false);
    }
  }
}

function getReverseWeight(
  useRemainingTime: boolean,
  remainingChildEffort: number,
  childEffort: number,
  workloadTask: number,
): number {
  const effort = useRemainingTime ? remainingChildEffort : childEffort;
  return effort - workloadTask;
}

function calculateCombinedWorkload(context: WorkloadCalculationContext): void {
  const handledTasks: Uuid[] = [];
  const handledSubWorkPackages: Uuid[] = [];
  for (const nodeId of context.userWorkLoad.workPackages) {
    const workPackage = context.userWorkLoad.plannedNodes[nodeId];
    if (workPackage) {
      calculateCombinedWorkLoadForWorkPackage(workPackage, context, handledTasks, handledSubWorkPackages);
    }
  }
  calculatePersonalTaskWorkload(context);
  handleUnhandledSubWorkPackages(context, handledSubWorkPackages, handledTasks);
  handleUnhandledTasks(context, handledTasks);
}

function handleUnhandledTasks(context: WorkloadCalculationContext, handledTasks: Uuid[]): void {
  handledTasks.push(...context.userWorkLoad.personalPlannedTasks);
  const unhandledTaskIds = context.userWorkLoad.tasks.filter((taskId) => !handledTasks.includes(taskId));
  for (const taskId of unhandledTaskIds) {
    const task = context.userWorkLoad.plannedNodes[taskId];
    if (task) {
      calculateSingleResourceWorkload(task, false, context, context.calculatedWorkloadMap);
    }
  }
}

function handleUnhandledSubWorkPackages(
  context: WorkloadCalculationContext,
  handledSubWorkPackages: Uuid[],
  handledTasks: Uuid[],
): void {
  const unhandledIds = context.userWorkLoad.subWorkPackages.filter((id) => !handledSubWorkPackages.includes(id));
  for (const id of unhandledIds) {
    const subWorkPackage = context.userWorkLoad.plannedNodes[id];
    if (subWorkPackage) {
      calculateCombinedWorkLoadForSubWorkPackage(subWorkPackage, context, handledTasks);
    }
  }
}

function calculateWorkPackageWorkload(context: WorkloadCalculationContext): void {
  for (const nodeId of context.userWorkLoad.workPackages) {
    const node = context.userWorkLoad.plannedNodes[nodeId];
    if (node) {
      calculateSingleResourceWorkload(node, false, context, context.calculatedWorkloadMap);
    }
  }
  calculateSubWorkPackageWorkload(context);
}

function calculateSubWorkPackageWorkload(context: WorkloadCalculationContext): void {
  for (const nodeId of context.userWorkLoad.subWorkPackages) {
    const node = context.userWorkLoad.plannedNodes[nodeId];
    if (node) {
      calculateSingleResourceWorkload(node, false, context, context.calculatedWorkloadMap);
    }
  }
}

function calculateTaskWorkload(context: WorkloadCalculationContext): void {
  for (const nodeId of context.userWorkLoad.tasks) {
    const node = context.userWorkLoad.plannedNodes[nodeId];
    if (node) {
      calculateSingleResourceWorkload(node, false, context, context.calculatedWorkloadMap);
    }
  }
  calculatePersonalTaskWorkload(context);
}

function calculatePersonalTaskWorkload(context: WorkloadCalculationContext): void {
  for (const nodeId of context.userWorkLoad.personalPlannedTasks) {
    const node = context.userWorkLoad.plannedNodes[nodeId];
    if (node) {
      calculateSingleResourceWorkload(node, true, context, context.calculatedWorkloadMap);
    }
  }
}

export function calculateWorkload(workloadDisplayment: WorkloadDisplayment, context: WorkloadCalculationContext): void {
  if (workloadDisplayment === WorkloadDisplayment.workloadCombined) {
    calculateCombinedWorkload(context);
  } else if (workloadDisplayment === WorkloadDisplayment.workloadFromWorkpackages) {
    calculateWorkPackageWorkload(context);
  } else if (workloadDisplayment === WorkloadDisplayment.workloadFromTask) {
    calculateTaskWorkload(context);
  }
}
