import {projectPlanningColumnWidthSelector} from '@octaved/flow/src/Modules/Selectors/UiPages/ProjectPlanningSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {Dispatch, SetStateAction} from 'react';
import {
  ProjectBasedContext,
  ProjectBasedContextProps,
} from '../../../../../Canvas/ProjectBasedGantt/ProjectBasedContext';
import {patchProjectPlanningColumnWidth} from '../../../../../Modules/ProjectPlanning';
import {BarTextDisplayment, setExtendedProjectPlanningNodes, setVisibleGanttColumns} from '../../../../../Modules/Ui';
import {
  projectPlanningBarTextDisplaymentSelector,
  projectPlanningExtendedNodesSelector,
  simulationModeActiveSelector,
  visibleGanttColumnsSelector,
} from '../../../../../Selectors/UiSelectors';

interface GanttProjectContextProps extends ProjectBasedContextProps {
  setBaselineComparison: Dispatch<SetStateAction<Uuid | null>>;
}

export class GanttProjectContext extends ProjectBasedContext {
  #setBaselineComparison: Dispatch<SetStateAction<Uuid | null>>;

  constructor(props: GanttProjectContextProps) {
    super(props);
    this.#setBaselineComparison = props.setBaselineComparison;
  }

  protected getVisibleColumnsSelector(state: FlowState): string[] {
    return visibleGanttColumnsSelector(state);
  }
  protected getExtendedNodesSelector(state: FlowState): Record<string, boolean> | null {
    return projectPlanningExtendedNodesSelector(state);
  }
  protected getColumnsWidthSelector(state: FlowState): number {
    return projectPlanningColumnWidthSelector(state);
  }

  set columnWidth(columnWidth: number) {
    super.columnWidth = columnWidth;
    this.store.dispatch(patchProjectPlanningColumnWidth(columnWidth));
  }

  get columnWidth(): number {
    return super.columnWidth;
  }

  setVisibleColumns(visibleGanttColumns: string[]): void {
    this.store.dispatch(setVisibleGanttColumns(visibleGanttColumns));
  }

  setExtendedNodes(extendedNodes: Record<string, boolean>): void {
    this.store.dispatch(setExtendedProjectPlanningNodes(extendedNodes));
  }

  canAddNodes(): boolean {
    return !simulationModeActiveSelector(this.store.getState());
  }

  canAddRootNodes(): boolean {
    return true;
  }

  openGanttComparison(id: Uuid): void {
    this.#setBaselineComparison(id);
  }

  getBarTextDisplaymentSelector(state: FlowState): BarTextDisplayment {
    return projectPlanningBarTextDisplaymentSelector(state);
  }
}
