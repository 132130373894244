import {EnumConnectedServiceType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {CalendarColor} from '@octaved/ui';

export interface AccountCalendar {
  color: CalendarColor;
  id: string;
  name: string;
  showInMyCalendar: boolean;
}

interface BaseConnectedService {
  accountDisplayName: string;
  acquiredScopes: string[];
  connectedAt: number;
  id: Uuid;
  refreshTokenAcquired: number | null;
}

export interface ConnectedAzureCalendar extends BaseConnectedService {
  serviceType: EnumConnectedServiceType.VALUE_AZURE_CALENDAR;
  data: {
    calendars?: AccountCalendar[];
  };
}

export interface ConnectedGoogleCalendar extends BaseConnectedService {
  serviceType: EnumConnectedServiceType.VALUE_GOOGLE_CALENDAR;
  data: Record<string, never>;
}

export type ConnectedService = ConnectedAzureCalendar | ConnectedGoogleCalendar;

export const calendarServiceTypes = new Set([
  EnumConnectedServiceType.VALUE_AZURE_CALENDAR,
  EnumConnectedServiceType.VALUE_GOOGLE_CALENDAR,
]);
