import {error} from '@octaved/env/src/Logger';
import {deleteCustomer, patchCustomer, putCustomer} from '@octaved/flow-api';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {organizationLanuguageSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {
  optimisticAdd,
  optimisticDelete,
  optimisticUpdate,
  setToReducerMap,
} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {getState} from '@octaved/store/src/Store';
import {
  createTextInputVariableRules,
  RulesList,
  validateDecimalIfValidStringFormat,
  validateLength,
  validateNumberStringFormat,
  validateIntegerStringFormat,
  validateMinMax,
} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {parse} from '@octaved/users/src/Culture/NumberFormatter';
import {generateUuid} from '@octaved/utilities';
import {ObjectContains} from '@octaved/validation/src';
import {useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {Customer, CustomerLocation, CustomerPatchData, Customers} from '../EntityInterfaces/Customers';
import {
  FLOW_CREATE_CUSTOMER_FAILURE,
  FLOW_CREATE_CUSTOMER_REQUEST,
  FLOW_CREATE_CUSTOMER_SUCCESS,
  FLOW_PATCH_CUSTOMER_FAILURE,
  FLOW_PATCH_CUSTOMER_REQUEST,
  FLOW_PATCH_CUSTOMER_SUCCESS,
  FLOW_REMOVE_CUSTOMER_FAILURE,
  FLOW_REMOVE_CUSTOMER_REQUEST,
  FLOW_REMOVE_CUSTOMER_SUCCESS,
} from './ActionTypes';
import {customerEntitiesSelector} from './Selectors/CustomerSelectors';
import {settingsSelector} from './Selectors/SettingSelectors';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

const reducerMap = new Map();
type Request = ServerRequestAction<{
  data: Customer;
  urlParams: {customerId: Uuid};
}>;
setToReducerMap<Customers, Request>(
  optimisticAdd,
  reducerMap,
  FLOW_CREATE_CUSTOMER_REQUEST,
  FLOW_CREATE_CUSTOMER_FAILURE,
  FLOW_CREATE_CUSTOMER_SUCCESS,
  ({options}) => options.data.id,
);
setToReducerMap<Customers, Request>(
  optimisticUpdate,
  reducerMap,
  FLOW_PATCH_CUSTOMER_REQUEST,
  FLOW_PATCH_CUSTOMER_FAILURE,
  FLOW_PATCH_CUSTOMER_SUCCESS,
  ({options}) => options.urlParams.customerId,
);
setToReducerMap<Customers, Request>(
  optimisticDelete,
  reducerMap,
  FLOW_REMOVE_CUSTOMER_REQUEST,
  FLOW_REMOVE_CUSTOMER_FAILURE,
  FLOW_REMOVE_CUSTOMER_SUCCESS,
  ({options}) => options.urlParams.customerId,
);

export const customerReducer = ReduceFromMap(reducerMap);

function getValidationRules(id: Uuid, data: DeepPartial<CustomerPatchData>, isCreation: boolean): RulesList {
  const rules: RulesList = [];
  if (isCreation || data.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        data.name!,
        'systemSettings:customer.error.customerNameEmpty',
        'systemSettings:customer.error.customerNameTooLong',
        `customerName_${id}`,
      ),
    );
  }
  if (typeof data.customerNumber !== 'undefined') {
    rules.push([
      validateLength,
      data.customerNumber,
      'systemSettings:customer.error.customerNumberTooLong',
      `customerNumber_${id}`,
    ]);
  }
  if (data.hasOwnProperty('priceCategoryOverrides')) {
    for (const [id, overflow] of Object.entries(data.priceCategoryOverrides!)) {
      if (overflow && overflow.hourlyRate !== null && !overflow.categoryDisabled) {
        rules.push([
          validateNumberStringFormat,
          overflow.hourlyRate,
          'general:error.invalidNumberFormat',
          `override_hourlyRate_${id}`,
        ]);
        rules.push([
          validateDecimalIfValidStringFormat,
          overflow.hourlyRate!,
          'general:error.decimalOutOfRange',
          `override_hourlyRate_${id}`,
        ]);
      }
    }
  }
  if (data.hasOwnProperty('priceSurchargeOverrides')) {
    for (const [id, overflow] of Object.entries(data.priceSurchargeOverrides!)) {
      if (overflow && overflow.surcharge !== null && !overflow.surchargeDisabled) {
        rules.push([
          validateIntegerStringFormat,
          overflow.surcharge,
          'general:error.invalidNumberFormat',
          `override_surcharge_${id}`,
        ]);
        rules.push([
          validateMinMax,
          overflow.surcharge,
          'general:error.invalidNumberFormat',
          `override_surcharge_${id}`,
          -100,
          1000,
        ]);
      }
    }
  }
  return rules;
}

export function createCustomerLocation(): CustomerLocation {
  return {
    id: generateUuid(),
    name: '',
    number: '',
  };
}

export function createCustomerEntity(): Customer {
  return {
    customerNumber: '',
    defaultBillingType: null,
    fileExportLanguage: organizationLanuguageSelector(getState()),
    id: generateUuid(),
    isInternal: false,
    isLocked: false,
    journeyBillingMode: null,
    locations: [],
    logoUrl: null,
    name: '',
    priceCategoryOverrides: {},
    priceSurchargeOverrides: {},
    requiresInternalCharge: true,
    showSignatureFieldInPdfTimeSheets: settingsSelector(getState()).fileExports.timeSheets.showSignatureField,
  };
}

export function useCreateCustomer(): (data: Customer) => Promise<void> {
  const dispatch = useDispatch();
  return useCallback(
    async (data) => {
      await dispatch({
        [CALL_API]: {
          endpoint: putCustomer,
          method: 'put',
          options: {
            data,
            urlParams: {customerId: data.id},
          },
          types: {
            failureType: FLOW_CREATE_CUSTOMER_FAILURE,
            requestType: FLOW_CREATE_CUSTOMER_REQUEST,
            successType: FLOW_CREATE_CUSTOMER_SUCCESS,
          },
        },
      });
    },
    [dispatch],
  );
}

function patchDataToEntity(data: DeepPartial<CustomerPatchData>): DeepPartial<Customer> {
  const entity = {...data} as DeepPartial<Customer>;
  if (data.hasOwnProperty('priceCategoryOverrides')) {
    for (const [id, override] of Object.entries(data.priceCategoryOverrides!)) {
      if (override?.hourlyRate && !override.categoryDisabled && override.overrideIsActive) {
        const rate = override.hourlyRate.trim();
        entity.priceCategoryOverrides![id]!.hourlyRate = rate ? parse(rate) : null;
      } else {
        //reset value
        entity.priceCategoryOverrides![id]!.hourlyRate = null;
      }
    }
  }
  if (data.hasOwnProperty('priceSurchargeOverrides')) {
    for (const [id, override] of Object.entries(data.priceSurchargeOverrides!)) {
      if (override?.surcharge && !override.surchargeDisabled && override.overrideIsActive) {
        const rate = override.surcharge.trim();
        entity.priceSurchargeOverrides![id]!.surcharge = parse(rate);
      }
    }
  }
  return entity;
}

export function usePatchCustomer(): (customerId: Uuid, data: DeepPartial<CustomerPatchData>) => Promise<boolean> {
  const {getState} = useStore<FlowState>();
  const dispatch = useDispatch();
  return useCallback(
    async (customerId, data) => {
      if (!validateErrorRules(getValidationRules(customerId, data, false), dispatch)) {
        return false;
      }
      const customer = customerEntitiesSelector(getState())[customerId];
      if (!customer) {
        throw new Error('Missing customer');
      }
      const patchData = patchDataToEntity(data);
      if (!ObjectContains(customer, patchData)) {
        try {
          await dispatch({
            [CALL_API]: {
              endpoint: patchCustomer,
              method: 'patch',
              options: {
                data: patchData,
                urlParams: {customerId},
              },
              throwNetworkError: true,
              types: {
                failureType: FLOW_PATCH_CUSTOMER_FAILURE,
                requestType: FLOW_PATCH_CUSTOMER_REQUEST,
                successType: FLOW_PATCH_CUSTOMER_SUCCESS,
              },
            },
          });
        } catch (e) {
          error(e);
          return false;
        }
      }
      return true;
    },
    [dispatch, getState],
  );
}

export function useRemoveCustomer(): (customerId: Uuid) => void {
  const dispatch = useDispatch();
  return useCallback(
    (customerId) => {
      return dispatch({
        [CALL_API]: {
          endpoint: deleteCustomer,
          method: 'del',
          options: {
            urlParams: {customerId},
          },
          types: {
            failureType: FLOW_REMOVE_CUSTOMER_FAILURE,
            requestType: FLOW_REMOVE_CUSTOMER_REQUEST,
            successType: FLOW_REMOVE_CUSTOMER_SUCCESS,
          },
        },
      });
    },
    [dispatch],
  );
}
