import {Uuid} from '@octaved/typescript/src/lib';
import {isEqual} from 'lodash';
import {getPlanningDatesForNodeSelector} from '../../../Selectors/PlanningDateSelectors';
import {PlanningBarProps} from '../../Bars/PlanningDateBar';
import {createStoreSubscription} from '../../StoreSubscription';
import {BarRow} from './BarRow';

export class MaterialResourceBarRow extends BarRow {
  #planningDateIds: Uuid[] = [];

  init(): void {
    super.init();

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) =>
          getPlanningDatesForNodeSelector(s)(this.node.id)
            .filter(({assignedNodeId}) => assignedNodeId === this.node.parentNodeId)
            .map(({id}) => id),
        (planningDates) => {
          this.#planningDateIds = planningDates;
          this.#renderBars();
        },
        isEqual,
      ),
    );
  }

  #renderBars = this.debouncedAction((): void => {
    this.root.destroyChildren();
    this.disposeRows();
    for (let i = 0; i < this.#planningDateIds.length; i++) {
      const planningDateId = this.#planningDateIds[i];
      const barProps: PlanningBarProps = {
        planningDateId,
        ctx: this.ctx,
        height: this.height,
        isFirst: i === 0,
        isLast: i === this.#planningDateIds.length - 1,
        menuRoot: this.menuRoot,
        rowIndex: this.rowIndex,
        rowRoot: this.root,
        treeNode: this.node,
        yOffset: this.yOffset,
      };
      const bar = this.barFactory.createMaterialResourceBar(barProps);
      this.disposableRows.push(() => bar?.dispose());
    }
  });
}
