import {boardPostTypeTranslationsMap} from '../../../../BoardPost/BoardPostType';
import {AuditBoardPost} from '../../../../EntityInterfaces/Audit/AuditBoardPost';
import BooleanCell from '../Table/Cells/BooleanCell';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import JsonCell from '../Table/Cells/JsonCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {nodeColumns} from './Node';

const config: AuditTableConfiguration<AuditBoardPost> = {
  columns: [
    ...nodeColumns,
    createColumnDef('boardPostType', 'systemSettings:audit.tables.boardPost.boardPostTypeHeader', ({cell: {value}}) => {
      return <EnumTranslationCell value={value} translationMapping={boardPostTypeTranslationsMap} />;
    }),
    createColumnDef('lastContentChangeBy', 'systemSettings:audit.tables.boardPost.lastChangedByHeader', ReferenceCell),
    createColumnDef('isPersonal', 'systemSettings:audit.tables.boardPost.isPersonalHeader', BooleanCell),
    createColumnDef('data', 'systemSettings:audit.tables.boardPost.dataHeader', JsonCell),
  ],
  tableIdent: 'boardPosts',
  tableNameTranslationToken: 'systemSettings:audit.boardPostTableName',
};
export default config;
