import {TimeControlledEntity} from '@octaved/flow/src/EntityInterfaces/TimeControlledEntity';
import {formatTimeControl, isTimeControlActive} from '@octaved/flow/src/Modules/TimeControlledEntity';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Placeholder from '../../PlaceholderText';

interface TimeControlParams {
  entity: TimeControlledEntity;
}

export default function TimeControlFormatted({entity}: TimeControlParams): ReactElement {
  const {t} = useTranslation();
  const dateFormat = useSelector(dateFormatSelector);
  if (!isTimeControlActive(entity)) {
    return <Placeholder text={'nodeDetails:field.timeControl.disabledText'} />;
  }
  return <span>{formatTimeControl(t, entity, dateFormat || '', false)}</span>;
}
