import {ReactElement, useState} from 'react';
import Modal, {DependencyModalProps} from './DependencyModal';

interface DependencyModalWithTriggerProps extends Omit<DependencyModalProps, 'onClose'> {
  trigger: ReactElement;
}

export default function DependencyModalWithTrigger({
  trigger,
  ...props
}: DependencyModalWithTriggerProps): ReactElement | null {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        {trigger}
      </div>
      {open && <Modal {...props} onClose={() => setOpen(false)} />}
    </>
  );
}
