import {Uuid} from '@octaved/typescript/src/lib';
import {CalendarContext} from '../../Calendar/Context/CalendarContext';
import {RootContainer, RootContainerProps} from '../../RootContainer';
import {Bar} from '../Bar';

export type BarPluginProps<CTX extends CalendarContext = CalendarContext> = RootContainerProps<CTX>;

export class BarPlugin<CTX extends CalendarContext = CalendarContext> extends RootContainer<CTX> {
  #bar: Bar | null = null;
  protected isDisabled = false;

  init(bar: Bar): void {
    this.#bar = bar;
    this.onBarUpdated();
  }

  protected get bar(): Bar {
    if (!this.#bar) {
      throw new Error('BarPlugin not initialized');
    }
    return this.#bar;
  }

  onBarUpdated(): void {
    //impl in subclass
  }

  get disabled(): boolean {
    return this.isDisabled;
  }

  protected get nodeId(): Uuid {
    if (!this.#bar) {
      throw new Error('BarPlugin not initialized');
    }
    return this.#bar?.nodeId;
  }
}
