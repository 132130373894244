import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {timeTrackingSuperUserModeReducer} from '@octaved/flow/src/Authorization/Virtual/TimeTrackingSuperUserMode';
import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {combiner} from '@octaved/store/src/Reducer/Combiner';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {NIL} from '@octaved/utilities';
import {CurrentOrgUser} from '../EntityInterfaces/CurrentOrgUser';
import {currentOrgUserRightsReducer} from './CurrentOrgUserRights';
import {orgUserSettingsReducer} from './OrgUserSettings';

const idReducers = createReducerCollection<CurrentOrgUser['id']>(NIL);
const nameReducers = createReducerCollection<CurrentOrgUser['name']>('');
const typeReducers = createReducerCollection<CurrentOrgUser['type']>(UserType.regular);

idReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) =>
  action.isInOrganization ? action.response.result.orgUser.id : state,
);
nameReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) =>
  action.isInOrganization ? action.response.result.orgUser.name : state,
);
typeReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) =>
  action.isInOrganization ? action.response.result.orgUser.type : state,
);

export const currentOrgUserReducer = combiner<CurrentOrgUser>({
  id: idReducers.reducer,
  name: nameReducers.reducer,
  rights: currentOrgUserRightsReducer,
  settings: orgUserSettingsReducer,
  timeTrackingSuperUserMode: timeTrackingSuperUserModeReducer,
  type: typeReducers.reducer,
});
