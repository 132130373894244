import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {Icon, Tooltip, TooltipProps} from '@octaved/ui';
import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Flame} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {todayIsoDateSelector} from '../../../Today';
import PlanningDate from '../../Node/PlanningDate';

interface Props extends Omit<TooltipProps, 'toolTipTranslation' | 'children'> {
  node: WorkPackage | SubWorkPackage;
  outline?: boolean;
}

export default function OverdueIcon({node, outline = true, ...tooltipProps}: Props): ReactElement | null {
  const {t} = useTranslation();
  const todayIsoDate = isoToIsoDateTimeFormat(useSelector(todayIsoDateSelector));
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(node.id);
  const isOverDue = useMemo(
    () => node && !node.isCompleted && plannedEnd && plannedEnd < todayIsoDate,
    [plannedEnd, todayIsoDate, node],
  );

  if (isOverDue) {
    return (
      <Tooltip
        content={
          <>
            {t('workPackage:tooltips.overdue')}
            {node && (
              <>
                &nbsp;(
                <PlanningDate plannedStart={plannedStart} plannedEnd={plannedEnd} />)
              </>
            )}
          </>
        }
        toolTipTranslation={''}
        {...tooltipProps}
      >
        <Icon iconColor={outline ? 'red' : 'white'} noMargin size={'big'}>
          <Flame fill={outline ? '#FFF' : '#F00'} />
        </Icon>
      </Tooltip>
    );
  }

  return null;
}
