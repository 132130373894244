import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import * as routes from '@octaved/security/config/routes';
import {
  createFlatTimestampReducer,
  EntityState,
  INVALIDATED,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {createTextInputVariableRules, RulesList} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {ObjectContains} from '@octaved/validation/src';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CreateGuestRole, CreateProjectRole, PatchRole, Role, StoreRoles} from '../EntityInterfaces/Role';
import {
  FLOW_CREATE_ROLE_FAILURE,
  FLOW_CREATE_ROLE_REQUEST,
  FLOW_CREATE_ROLE_SUCCESS,
  FLOW_INIT_LOAD_REQUEST,
  FLOW_INIT_LOAD_SUCCESS,
  FLOW_LOAD_ROLES_FAILURE,
  FLOW_LOAD_ROLES_REQUEST,
  FLOW_LOAD_ROLES_SUCCESS,
  FLOW_PATCH_ROLE_FAILURE,
  FLOW_PATCH_ROLE_REQUEST,
  FLOW_PATCH_ROLE_SUCCESS,
  FLOW_REMOVE_ROLE_FAILURE,
  FLOW_REMOVE_ROLE_REQUEST,
  FLOW_REMOVE_ROLE_SUCCESS,
  FLOW_RESORT_ROLES_FAILURE,
  FLOW_RESORT_ROLES_REQUEST,
  FLOW_RESORT_ROLES_SUCCESS,
} from './ActionTypes';
import {RolesReSortedEvent} from './Events';

import {InitAction} from './Initialization/Actions';
import {roleSelector, roleStateSelector} from './Selectors/RoleSelectors';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

const reducerMap = new Map();
reducerMap.set(FLOW_LOAD_ROLES_SUCCESS, (_state: StoreRoles, {response}: {response: Role[]}): StoreRoles => {
  const storeRoles: StoreRoles = {
    [EnumFlowRoleType.VALUE_PERMISSION]: {},
    [EnumFlowRoleType.VALUE_PROJECT]: {},
    [EnumFlowRoleType.VALUE_GLOBAL_PERMISSION]: {},
  };
  response.forEach((role) => {
    storeRoles[role.type][role.id] = role;
  });
  return storeRoles;
});
reducerMap.set(FLOW_INIT_LOAD_SUCCESS, (state: StoreRoles, action: InitAction): StoreRoles => {
  if (action.isInOrganization) {
    const storeRoles: StoreRoles = {
      [EnumFlowRoleType.VALUE_PERMISSION]: {},
      [EnumFlowRoleType.VALUE_PROJECT]: {},
      [EnumFlowRoleType.VALUE_GLOBAL_PERMISSION]: {},
    };
    action.response.result.roles.forEach((role) => {
      storeRoles[role.type][role.id] = role;
    });
    return storeRoles;
  }
  return state;
});

const reduceReSort = (state: StoreRoles, {roleType, roleIds}: RolesReSortedEvent): StoreRoles => {
  let changed = false;
  const newState = {...state, [roleType]: {...state[roleType]}};
  roleIds.forEach((id, index) => {
    const role = newState[roleType][id];
    if (role && role.weight !== index) {
      newState[roleType][id] = {...role, weight: index};
      changed = true;
    }
  });
  return changed ? newState : state;
};
addMultiToReducerMap(reducerMap, [FLOW_RESORT_ROLES_REQUEST, 'flow.RolesReSortedEvent'], reduceReSort);

export const roleReducer = ReduceFromMap(reducerMap, {
  [EnumFlowRoleType.VALUE_PERMISSION]: {},
  [EnumFlowRoleType.VALUE_PROJECT]: {},
  [EnumFlowRoleType.VALUE_GLOBAL_PERMISSION]: {},
});

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_ROLES_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_LOAD_ROLES_SUCCESS, createFlatTimestampReducer(LOADED));
stateReducerMap.set(FLOW_INIT_LOAD_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_INIT_LOAD_SUCCESS, (state: EntityState, action: InitAction) => {
  if (action.isInOrganization) {
    return createFlatTimestampReducer(LOADED)(state, action);
  }
  return state;
});
addMultiToReducerMap(
  stateReducerMap,
  ['flow.RoleCreatedEvent', 'flow.RolePatchedEvent', 'flow.RoleRemovedEvent'],
  createFlatTimestampReducer(INVALIDATED),
);
export const roleStateReducer = ReduceFromMap(stateReducerMap);

function loadRoles(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    if (isOutdated(roleStateSelector(getState()))) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.getRoles,
          types: {
            failureType: FLOW_LOAD_ROLES_FAILURE,
            requestType: FLOW_LOAD_ROLES_REQUEST,
            successType: FLOW_LOAD_ROLES_SUCCESS,
          },
        },
      });
    }
  };
}

export function useLoadRoles(): boolean {
  const dispatch = useDispatch();
  const state = useSelector(roleStateSelector);
  useEffect(() => {
    dispatch(loadRoles());
    // noinspection BadExpressionStatementJS
    void state; //reload-dependency
  }, [dispatch, state]);
  return isLoaded(state);
}

function getValidationRules(id: Uuid, data: DeepPartial<PatchRole>, isCreation: boolean): RulesList {
  const rules: RulesList = [];
  if (isCreation || typeof data.name !== 'undefined') {
    rules.push(
      ...createTextInputVariableRules(
        data.name || '',
        'systemSettings:role.error.nameEmpty',
        'systemSettings:role.error.nameTooLong',
        `role_name_${id}`,
      ),
    );
  }
  return rules;
}

function createRole(
  type: EnumFlowRoleType,
  isGuestRole: boolean,
  roleId: Uuid,
  data: Partial<Role>,
): ActionDispatcher<Promise<boolean>, FlowState> {
  if (isGuestRole && type !== EnumFlowRoleType.VALUE_PERMISSION) {
    throw new Error('Guest roles are for permission type only');
  }
  return async (dispatch) => {
    if (!validateErrorRules(getValidationRules(roleId, data, true), dispatch)) {
      return false;
    }
    await dispatch({
      [CALL_API]: {
        endpoint: routes.createRole,
        method: 'put',
        options: {
          data: {...data, isGuestRole, type, weight: -1}, //weight is set to -1 until sorted manually
          urlParams: {roleId},
        },
        types: {
          failureType: FLOW_CREATE_ROLE_FAILURE,
          requestType: FLOW_CREATE_ROLE_REQUEST,
          successType: FLOW_CREATE_ROLE_SUCCESS,
        },
      },
    });
    return true;
  };
}

export function createGuestRole(roleId: Uuid, data: CreateGuestRole): ActionDispatcher<Promise<boolean>, FlowState> {
  return createRole(EnumFlowRoleType.VALUE_PERMISSION, true, roleId, data);
}

export function createProjectRole(
  roleId: Uuid,
  data: CreateProjectRole,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return createRole(EnumFlowRoleType.VALUE_PROJECT, false, roleId, data);
}

export function patchRole(
  type: EnumFlowRoleType,
  roleId: Uuid,
  data: DeepPartial<PatchRole>,
): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(roleId, data, false), dispatch)) {
      return false;
    }
    const role = roleSelector(getState())[type][roleId];
    if (!role) {
      throw new Error('Missing role');
    }
    if (!ObjectContains(role, data)) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.patchRole,
          method: 'patch',
          options: {
            data,
            urlParams: {roleId},
          },
          types: {
            failureType: FLOW_PATCH_ROLE_FAILURE,
            requestType: FLOW_PATCH_ROLE_REQUEST,
            successType: FLOW_PATCH_ROLE_SUCCESS,
          },
        },
      });
    }
    return true;
  };
}

export function reSortRoles(
  roleType: EnumFlowRoleType,
  isGuestRole: boolean,
  roleIds: Uuid[],
): ActionDispatcher<boolean, FlowState> {
  return (dispatch) => {
    dispatch({
      isGuestRole,
      roleIds,
      roleType,
      [CALL_API]: {
        endpoint: routes.reSortRoles,
        method: 'patch',
        options: {data: {isGuestRole, roleIds, roleType}},
        types: {
          failureType: FLOW_RESORT_ROLES_FAILURE,
          requestType: FLOW_RESORT_ROLES_REQUEST,
          successType: FLOW_RESORT_ROLES_SUCCESS,
        },
      },
    });
    return true;
  };
}

export function removeRole(type: EnumFlowRoleType, roleId: Uuid): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    const role = roleSelector(getState())[type][roleId];
    if (!role) {
      throw new Error('Missing role');
    }
    dispatch({
      [CALL_API]: {
        endpoint: routes.removeRole,
        method: 'del',
        options: {urlParams: {roleId}},
        types: {
          failureType: FLOW_REMOVE_ROLE_FAILURE,
          requestType: FLOW_REMOVE_ROLE_REQUEST,
          successType: FLOW_REMOVE_ROLE_SUCCESS,
        },
      },
    });
    return true;
  };
}
