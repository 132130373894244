import {EntityStates, hasLoadedOnce} from '@octaved/store/src/EntityState';
import {objectEntries} from '@octaved/utilities/src/Object';
import {createSelector} from 'reselect';
import {LoadSubtreeStats, SubtreeStats} from '../../../EntityInterfaces/Statistics/SubtreeStats';
import {FlowState} from '../../State';

export const subtreeStatsSelector = (state: FlowState): SubtreeStats => state.statistics.subtreeStats.data;
export const subtreeStatsStatesSelector = (state: FlowState): EntityStates => state.statistics.subtreeStats.state;

export const subtreeStatsLoadedOnceSelector = createSelector(
  subtreeStatsStatesSelector,
  (state) =>
    (wanted: LoadSubtreeStats): boolean => {
      return objectEntries(wanted).every(([key, ids]) => {
        return [...ids].every((id) => {
          const idState = state[`${key}-${id}`];
          return idState && hasLoadedOnce(idState);
        });
      });
    },
);
