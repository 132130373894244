import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Customer} from '@octaved/flow/src/EntityInterfaces/Customers';
import {MaterialResource} from '@octaved/flow/src/EntityInterfaces/MaterialResource';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {Group, Project, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {TaskSection} from '@octaved/flow/src/EntityInterfaces/TaskSection';
import {formatCustomer} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {getMaterialResourceSelector} from '@octaved/flow/src/Modules/Selectors/MaterialResourceSelectors';
import {
  isNode,
  isPid,
  isProject,
  isSubWorkPackage,
  isTask,
  isTaskSection,
} from '@octaved/flow/src/Node/NodeIdentifiers';
import {compareNodes, SortNodeEntity} from '@octaved/flow/src/Modules/Selectors/NodeSortSelectors';
import {getChildNodesSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {GanttContext} from '../Context/GanttContext';

interface TreeNodeProps<TNodeType extends TreeNodeNodeType | Customer = TreeNodeNodeType | Customer> {
  parentNodeId: Uuid | null;
  node: TNodeType;
  depth?: number;
  visibleNodeIds: Readonly<Uuid[]>;
  ctx: GanttContext;
  showMaterialRessources: boolean;
}
type CommonTreeNodeEnumFlowNodeType =
  | EnumFlowNodeType.VALUE_GROUP
  | EnumFlowNodeType.VALUE_TASK
  | EnumFlowNodeType.VALUE_WORK_PACKAGE
  | EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE
  | EnumFlowNodeType.VALUE_TASK_SECTION;

export type TreeNodeEnumFlowNodeType =
  | CommonTreeNodeEnumFlowNodeType
  | EnumFlowNodeType.VALUE_USER_NODE
  | EnumFlowNodeType.VALUE_PROJECT
  | EnumFlowNodeType.VALUE_MATERIAL_RESOURCE;

export type CommonTreeNodeNodeType = Group | Task | TaskSection | WorkPackage | SubWorkPackage;
type TreeNodeNodeType = CommonTreeNodeNodeType | Project | MaterialResource;

export function isCommonTreeNodeNodeType(node: unknown): node is CommonTreeNodeNodeType {
  return isPid(node) || isSubWorkPackage(node) || isTask(node) || isTaskSection(node);
}

function singletonMemo<RESULT, PARAM>(fn: (p: PARAM) => RESULT): (p: PARAM) => RESULT {
  let result: RESULT;
  let param: PARAM | undefined = undefined;
  return (p: PARAM) => {
    if (param !== p) {
      param = p;
      result = fn(p);
    }
    return result;
  };
}

export abstract class TreeNode<TNodeType extends TreeNodeNodeType | Customer = TreeNodeNodeType | Customer> {
  protected children: TreeNode[] = [];
  readonly id: Uuid;
  readonly parentNodeId: Uuid | null;

  readonly node: TNodeType;
  #canExpand = false;
  readonly depth: number;
  protected readonly ctx: GanttContext;

  protected readonly visibleNodeIds: Readonly<Uuid[]>;
  protected showMaterialRessources: boolean;

  constructor({node, parentNodeId, depth = 0, ctx, visibleNodeIds, showMaterialRessources}: TreeNodeProps<TNodeType>) {
    this.id = node.id;
    this.parentNodeId = parentNodeId;
    this.node = node;
    this.depth = depth;
    this.ctx = ctx;
    this.visibleNodeIds = visibleNodeIds;
    this.showMaterialRessources = showMaterialRessources;

    this.createChildren();
  }

  abstract get type(): TreeNodeEnumFlowNodeType | 'customer';

  get canExpand(): boolean {
    return this.#canExpand;
  }

  get isExpanded(): boolean {
    const extendedNodes = this.ctx.extendedNodes;
    if (!extendedNodes) {
      return true;
    }
    return this.id in extendedNodes ? extendedNodes[this.id] : true;
  }

  set isExpanded(value: boolean) {
    const extendedNodes = this.ctx.extendedNodes || {};
    this.ctx.setExtendedNodes({...extendedNodes, [this.id]: value});
  }

  #getChildNodes(parentNodeId: Uuid): NodeType[] {
    const getChildNodes = getChildNodesSelector(this.ctx.store.getState());
    return getChildNodes(parentNodeId);
  }

  protected createChildren(): void {
    const children = this.#getChildNodes(this.id);
    for (const child of children) {
      if (isCommonTreeNodeNodeType(child)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const node = new CommonTreeNode({
          ctx: this.ctx,
          depth: this.depth + 1,
          node: child,
          parentNodeId: this.id,
          showMaterialRessources: this.showMaterialRessources,
          visibleNodeIds: this.visibleNodeIds,
        });
        this.children.push(node);
      }
    }
  }

  protected isVisible = singletonMemo((searchTerms: string[]): boolean => {
    return this.hasVisibleChildren(searchTerms);
  });

  protected hasVisibleChildren = singletonMemo((searchTerms: string[]): boolean => {
    return this.children.some((c) => c.isVisible(searchTerms));
  });

  protected sort(): void {
    this.children.sort((a, b) => a.compareFn(b));
  }

  protected abstract getSortNodeEntity(): SortNodeEntity;

  protected compareFn(other: TreeNode): number {
    return compareNodes(this.getSortNodeEntity(), other.getSortNodeEntity());
  }

  buildFlatTree(list: TreeNode[] = [], searchTerms: string[] = []): TreeNode[] {
    const isVisible = this.isVisible(searchTerms);
    this.#canExpand = this.hasVisibleChildren(searchTerms);
    this.sort();
    if (isVisible) {
      list.push(this);
      if (this.isExpanded) {
        for (const child of this.children) {
          child.buildFlatTree(list, searchTerms);
        }
      }
    }
    return list;
  }

  protected matchesSearch(searchTerms: string[]): boolean {
    return (
      searchTerms.length === 0 ||
      searchTerms.every((term) => {
        return (
          this.node.id === term ||
          ('pid' in this.node && this.node.pid?.toLowerCase() === term) ||
          this.node.name.toLocaleLowerCase().includes(term)
        );
      })
    );
  }
}

export class DummyTreeNode extends TreeNode<CommonTreeNodeNodeType> {
  constructor(props: Omit<TreeNodeProps<CommonTreeNodeNodeType>, 'parentNodeId' | 'visibleNodeIds'>) {
    super({...props, parentNodeId: null, visibleNodeIds: []});
  }

  get type(): TreeNodeEnumFlowNodeType {
    if (!isNode(this.node)) {
      throw new Error('DummyTreeNode must be a pid');
    }
    return this.node.nodeType as TreeNodeEnumFlowNodeType;
  }

  protected isVisible = (): boolean => true;

  protected getSortNodeEntity(): SortNodeEntity {
    return this.node;
  }
}

export class ProjectTreeNode extends TreeNode<Project> {
  get type(): EnumFlowNodeType.VALUE_PROJECT {
    return EnumFlowNodeType.VALUE_PROJECT;
  }

  protected isVisible = singletonMemo((searchTerms: string[]): boolean => {
    return this.hasVisibleChildren(searchTerms) || this.visibleNodeIds.includes(this.id);
  });

  protected compareFn(other: TreeNode): number {
    if (isProject(other.node)) {
      return this.node.name.localeCompare(other.node.name);
    }
    return super.compareFn(other);
  }

  protected getSortNodeEntity(): SortNodeEntity {
    return this.node;
  }
}

interface CustomerTreeNodeProps extends TreeNodeProps<Customer> {
  projects: Project[];
}

export class CustomerTreeNode extends TreeNode<Customer> {
  constructor(props: CustomerTreeNodeProps) {
    super(props);
    this.#createProjects(props.projects);
  }

  #createProjects(projects: Project[]): void {
    for (const project of projects) {
      const node = new ProjectTreeNode({
        ctx: this.ctx,
        depth: this.depth + 1,
        node: project,
        parentNodeId: null,
        showMaterialRessources: this.showMaterialRessources,
        visibleNodeIds: this.visibleNodeIds,
      });
      this.children.push(node);
    }
  }

  get type(): 'customer' {
    return 'customer';
  }

  protected getSortNodeEntity(): SortNodeEntity {
    return {nodeType: 'customer', name: formatCustomer(this.node)};
  }
}

class MaterialResourceTreeNode extends TreeNode<MaterialResource> {
  get type(): EnumFlowNodeType.VALUE_MATERIAL_RESOURCE {
    return EnumFlowNodeType.VALUE_MATERIAL_RESOURCE;
  }

  protected isVisible = singletonMemo((searchTerms: string[]): boolean => {
    return this.matchesSearch(searchTerms);
  });

  protected getSortNodeEntity(): SortNodeEntity {
    return this.node;
  }
}

interface CommonTreeNodeProps extends TreeNodeProps {
  node: CommonTreeNodeNodeType;
}

export class CommonTreeNode extends TreeNode<CommonTreeNodeNodeType> {
  readonly node: CommonTreeNodeNodeType;
  constructor(props: CommonTreeNodeProps) {
    super(props);
    this.node = props.node;
  }

  get type(): CommonTreeNodeEnumFlowNodeType {
    return this.node.nodeType;
  }

  protected getMaterialResource(id: Uuid): MaterialResource | undefined {
    const getMaterialResource = getMaterialResourceSelector(this.ctx.store.getState());
    return getMaterialResource(id);
  }

  protected createChildren(): void {
    super.createChildren();

    if (!this.showMaterialRessources) {
      return;
    }

    const addedMaterialNodes: Uuid[] = [];
    for (const {nodeId} of this.node.assignedPlanningDates) {
      const materialResource = this.getMaterialResource(nodeId);
      if (materialResource && !addedMaterialNodes.includes(materialResource.id)) {
        const node = new MaterialResourceTreeNode({
          ctx: this.ctx,
          depth: this.depth + 1,
          node: materialResource,
          parentNodeId: this.id,
          showMaterialRessources: this.showMaterialRessources,
          visibleNodeIds: this.visibleNodeIds,
        });
        this.children.push(node);
        addedMaterialNodes.push(materialResource.id);
      }
    }
  }

  protected isVisible = singletonMemo((searchTerms: string[]): boolean => {
    return (
      this.hasVisibleChildren(searchTerms) || (this.visibleNodeIds.includes(this.id) && this.matchesSearch(searchTerms))
    );
  });

  protected getSortNodeEntity(): SortNodeEntity {
    return this.node;
  }
}
