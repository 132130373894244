import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeInternalPermissionRoleAssignments} from '@octaved/flow-api/config/routes';
import {createTimestampReducer, EntityStates, INVALIDATED, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {Reducer} from 'react';
import {StoreNodePermissionRoleAssignments} from '../../EntityInterfaces/RoleAssignments/PermissionRoleAssignments';
import {
  FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS,
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_FAILURE,
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_REQUEST,
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS,
} from '../ActionTypes';
import {RoleRemovedEvent} from '../Events';
import {nodeInternalPermissionRoleAssignmentsStateSelector} from '../Selectors/RoleAssignments/NodePermissionRoleAssignmentSelectors';
import {createLoadNodeRoleAssignmentsAction, reduceNodeRoleAssignmentLoadSuccess} from './NodeRoleAssignments';

const initialState = {};

const reducers = createReducerCollection<StoreNodePermissionRoleAssignments>({});
reducers.add(
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS,
  reduceNodeRoleAssignmentLoadSuccess as Reducer<StoreNodePermissionRoleAssignments, unknown>,
);
export const nodeInternalPermissionRoleAssignmentsReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityStates>({});
export const nodeInternalPermissionRoleAssignmentsStatesReducer = stateReducers.reducer;

const clear = (): EntityStates => initialState;

stateReducers.add(
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_REQUEST,
  createTimestampReducer('options.data.nodeIds', LOADING),
);
stateReducers.add(
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS,
  createTimestampReducer('options.data.nodeIds', LOADED),
);
stateReducers.add(FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS, createTimestampReducer('nodeIds', INVALIDATED));
stateReducers.add(['OrgUserDeletedEvent', 'UserGroupDelegationEvent'], clear);
stateReducers.add<RoleRemovedEvent>('flow.RoleRemovedEvent', (state, action) => {
  if (action.roleType === EnumFlowRoleType.VALUE_PERMISSION) {
    return initialState;
  }
  return state;
});

//Invalidate upon node creation because due to the optimistic reduce it can happen that the role assignments
// were loaded before the server created the node, thus returning an empty result:
stateReducers.add(
  [
    'flow.GroupCreatedEvent',
    'flow.MaterialResourceCreatedEvent',
    'flow.NodeCreatedEvent',
    'flow.ProjectCreatedEvent',
    'flow.SubWorkPackageCreatedEvent',
    'flow.TaskCreatedEvent',
    'flow.WorkPackageCreatedEvent',
  ],
  createTimestampReducer('nodeId', INVALIDATED),
);
stateReducers.add(['flow.NodeRestoredFromTrashEvent'], createTimestampReducer('restoredNodeIds', INVALIDATED));

export const loadNodeInternalPermissionRoleAssignments = createLoadNodeRoleAssignmentsAction(
  nodeInternalPermissionRoleAssignmentsStateSelector,
  getNodeInternalPermissionRoleAssignments,
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_FAILURE,
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_REQUEST,
  FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS,
);
