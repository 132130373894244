import dayjsMutable from '@octaved/dayjs-mutable';
import {SupportedLanguage} from '@octaved/i18n/src/Language/Languages';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import {TFunction} from 'i18next';
import {getLocaleTranslations} from './DateLibs/LocaleTranslations';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(weekday);
dayjs.extend(relativeTime);

dayjs.updateLocale('en', {weekStart: 1});
dayjs.updateLocale('de', {weekStart: 1});

export function initializeDayjsLocale(t: TFunction, language: SupportedLanguage): void {
  //Unclear what dayjs does with the locale object, so better create new separate objects for both dayjs instances:
  dayjs.locale(getLocaleTranslations(t, language));
  dayjsMutable.locale(getLocaleTranslations(t, language));
}
