import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import {dialogRouteObjects} from '../Dialogs';
import {getWrappedStandardInspectorRoutes} from './StandardInspector';

const exportTimesheetsExternalRoute = 'export';
const TimesheetsExternalInspector = lazy(
  () => import('../../../Pages/Projects/Pages/TimesheetsExternal/TimesheetsExternalInspector'),
);

const exportTimesheetsExternalRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <TimesheetsExternalInspector />,
  path: exportTimesheetsExternalRoute,
});

export const timesheetExtrenalInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    exportTimesheetsExternalRouteObject(),
    {
      element: <Navigate to={exportTimesheetsExternalRoute} replace />,
      path: '*',
    },
  ],
  standardInspectorProps: {},
  type: 'timesheet',
});
