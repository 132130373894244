import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export default function NoLogicalTaskPlaceholder(): ReactElement {
  const {t} = useTranslation();

  return (
    <div className={'placeholder'}>
      <div className={'main'}>{t('pages:projects.inspector.manage.planning.newLogicalDependencyTaskPlaceholder')}</div>

      <style jsx>{`
        .placeholder {
          padding: 6px 0px;
        }

        .main {
          font-size: 14px;
          color: var(--color-grey-500);
        }
      `}</style>
    </div>
  );
}
