import {ReactElement} from 'react';
import NodeName from '../../../Components/Node/NodeName';
import ProjectIconView from '../../../Components/Pid/ProjectIconView';
import {Project} from '../../../EntityInterfaces/Pid';

interface Props {
  project: Project;
}

export default function ProjectView({project}: Props): ReactElement {
  return (
    <>
      <div className={'projectView'}>
        <div className={'icon'}>
          <ProjectIconView color={project.color} />
        </div>
        <div className={'name'}>
          <NodeName nodeId={project.id} />
        </div>
      </div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .projectView {
          display: flex;
          align-items: center;
        }

        .icon {
          margin-right: 10px;
        }
      `}</style>
      {/*#endregion styles*/}
    </>
  );
}
