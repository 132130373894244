import {ReactElement} from 'react';
import {ProjectTreeProjectsGroup} from '../../Modules/Projects/ProjectTreeInterfaces';
import ProjectGrouping from '../Tree/Components/ProjectGrouping';

export default function Grouped({grouped}: {grouped: ProjectTreeProjectsGroup[]}): ReactElement {
  return (
    <div>
      {grouped.map((group) => (
        <ProjectGrouping key={group.id} node={group} />
      ))}
    </div>
  );
}
