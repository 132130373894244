import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '../EntityInterfaces/NodeSearch';

export const tokensForBillableCustomer: Record<EnumFlowPidBillingType, string> = {
  [EnumFlowPidBillingType.VALUE_CONTINGENT]: 'general:pid.billingType.contingent',
  [EnumFlowPidBillingType.VALUE_EFFORT]: 'general:pid.billingType.effort',
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP]: 'general:pid.billingType.estimatedEffortCap',
  [EnumFlowPidBillingType.VALUE_EFFORT_EST]: 'general:pid.billingType.estimatedEffort',
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]: 'general:pid.billingType.estimationFromTo',
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: 'general:pid.billingType.fixPrice',
  [EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE]: 'general:pid.billingType.freeOfCharge',
};

const tokensForNonBillableCustomer: Record<EnumFlowPidBillingType, string> = {
  ...tokensForBillableCustomer,
  [EnumFlowPidBillingType.VALUE_EFFORT]: 'general:pid.billingTypeInternal.effort',
};

const billingTypeSortOrder: EnumFlowPidBillingType[] = [
  EnumFlowPidBillingType.VALUE_EFFORT,
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_FIXED_PRICE,
  EnumFlowPidBillingType.VALUE_CONTINGENT,
  EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE,
];

export function getSortedBillingTypes(_isCustomerBillable: boolean): EnumFlowPidBillingType[] {
  return billingTypeSortOrder;
}

export function getBillingTypeTranslatable(type: EnumFlowPidBillingType, isCustomerBillable: boolean): string {
  return (isCustomerBillable ? tokensForBillableCustomer : tokensForNonBillableCustomer)[type];
}

/**
 * These are billed in bulk - a one of payment for the entire planned hours
 */
const bulkBillableTypes: EnumFlowPidBillingType[] = [
  EnumFlowPidBillingType.VALUE_CONTINGENT,
  EnumFlowPidBillingType.VALUE_FIXED_PRICE,
];
export const bulkBillableTypesSet = new Set(bulkBillableTypes);
export const bulkBillableTypesCondition: NodeSearchCondition = {
  or: bulkBillableTypes.map((type) => ['wpBillingType', type]),
};

/**
 * These are billed based on the actual tracked time
 */
const hourlyBillableTypes: EnumFlowPidBillingType[] = [
  EnumFlowPidBillingType.VALUE_EFFORT,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
];
export const hourlyBillableTypesSet = new Set(hourlyBillableTypes);
export const hourlyBillableTypesCondition: NodeSearchCondition = {
  or: hourlyBillableTypes.map((type) => ['wpBillingType', type]),
};

const maxEffortBillingTypes = [
  EnumFlowPidBillingType.VALUE_CONTINGENT,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_FIXED_PRICE,
] as const;
export type MaxEffortBillingType = (typeof maxEffortBillingTypes)[number];

export function isMaxEffortBillingType(
  billingType: EnumFlowPidBillingType | null | undefined,
): billingType is MaxEffortBillingType {
  return !!billingType && maxEffortBillingTypes.includes(billingType as MaxEffortBillingType);
}
