import {useBoolean} from '@octaved/hooks';
import {IconButton} from '@octaved/ui';
import {MenuIcon, Pin} from 'lucide-react';
import {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {pageMenuExpandedSelector} from '../Modules/Selectors/UiSelectors';
import {setUiState} from '../Modules/Ui';

export default function PinMenu(): ReactElement {
  const [isHovering, {on, off}] = useBoolean(false);
  const dispatch = useDispatch();
  const isStoreExapnded = useSelector(pageMenuExpandedSelector);

  return (
    <div onMouseEnter={on} onMouseLeave={off}>
      <IconButton
        icon={isHovering ? <Pin /> : <MenuIcon />}
        variant={'ghost'}
        iconSize={'bigger'}
        size={'sm'}
        iconColor={'darkGrey'}
        isActive={isHovering && isStoreExapnded}
        onClick={() => dispatch(setUiState({pageMenu: {isExpanded: !isStoreExapnded}}))}
      />
    </div>
  );
}
