import {StorageMiddlewareTransformer} from '@octaved/store/src/StorageMiddleware';
import {isUuid} from '@octaved/validation';
import {ProjectsUiState} from '../UiPages/Projects';

export const projectFolderTransformer: StorageMiddlewareTransformer<
  ProjectsUiState['selectedProjectFolder'],
  string | null
> = {
  //for backwards compatibility, there used to be "favorites" in here:
  decode: (value) => (isUuid(value) ? value : null),
  encode: (value) => value,
};
