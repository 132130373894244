import {get} from '@octaved/network/src/Network';
import {getAvailableVersion, getVersion} from '../../config/routes';

export interface VersionInfo {
  branch: string;
  release: string;
  revision: string;
}

export const defaultVersionInfo: VersionInfo = {
  branch: '',
  release: '',
  revision: '', //must be empty initially to determine changes in the EnvironmentListener
};

export async function loadVersion(): Promise<VersionInfo> {
  return get<VersionInfo>(getVersion);
}

interface AvailableVersion {
  archive: string;
  downloadUrl: string;
  revision: string;
  version: string;
}

export interface AvailableVersionInfo {
  stable: AvailableVersion | null;
  preview: AvailableVersion | null;
  success: boolean;
}

export async function loadAvailableVersion(): Promise<AvailableVersionInfo> {
  return get<AvailableVersionInfo>(getAvailableVersion);
}
