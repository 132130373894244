import QuickAccessButtons from '@octaved/flow/src/PageHeader/Components/QuickAccessButtons';
import {cn} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';

interface HeaderProps {
  leftControls?: ReactElement;
  title: ReactNode;
  hasBorder?: boolean;
}

export function Header({leftControls, hasBorder = true, title}: HeaderProps): ReactElement {
  return (
    <div
      className={cn(
        'flex flex-shrink-0 items-center justify-between gap-x-4 overflow-hidden px-6 py-4',
        hasBorder && 'border-b border-b-slate-200',
      )}
    >
      {leftControls && <div className={'flex min-w-[210px] items-center gap-x-2'}>{leftControls}</div>}
      {title}
      <QuickAccessButtons />
    </div>
  );
}
