import {IsCompletedIcon} from '@octaved/flow/src/Node/NodeStatus/NodeStatusIcons';
import {cn, TimeBasedPlanning} from '@octaved/ui';
import {Ban, Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {PlanningStatusOverall} from './PlanningStatus';

const planningStatusColorClasses: Record<PlanningStatusOverall, string> = {
  active: 'text-sky-600',
  betweenBars: 'text-slate-500',
  blocked: 'text-red-700',
  done: 'text-emerald-700',
  none: 'text-slate-500',
  notYetActive: 'text-slate-500',
  overdue: 'text-red-700',
};

const planningStatusTexts: Record<PlanningStatusOverall, string> = {
  active: 'components:planningStatus.active',
  betweenBars: 'components:planningStatus.betweenBars',
  blocked: 'components:planningStatus.blocked',
  done: 'components:planningStatus.done',
  none: 'components:planningStatus.none',
  notYetActive: 'components:planningStatus.notYetActive',
  overdue: 'components:planningStatus.overdue',
};

export function PlanningStatusIconWithText({status}: {status: PlanningStatusOverall}): ReactElement {
  return (
    <div className={'flex h-full items-center gap-x-2'}>
      {status === 'none' ? (
        <Ban className={'size-4 text-slate-500'} />
      ) : (
        <>
          {status === 'done' ? (
            <IsCompletedIcon className={'size-4'} />
          ) : (
            <>
              {status === 'blocked' || status === 'overdue' ? (
                <Flame className={cn('-mt-[0.2em] size-4 shrink-0', planningStatusColorClasses[status])} />
              ) : (
                <TimeBasedPlanning className={cn('size-4 shrink-0', planningStatusColorClasses[status])} />
              )}
            </>
          )}
        </>
      )}
      <span>
        <Trans i18nKey={planningStatusTexts[status]} />
      </span>
    </div>
  );
}
