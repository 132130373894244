import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {Point} from '@octaved/svg';
import {TYPE_FF, TYPE_FS, TYPE_SS} from '../../../../Selectors/PlanningDependencySelectors';

export interface Context {
  predecessorIndex: number;
  index: number;
  cellHeight: number;
  cellWidth: number;
  successorStart: number;
  successorWidth: number;
  dependencyType: EnumFlowPlanningDependencyType;
  predecessorStart: number;
  predecessorWidth: number;
  barPaddingY: number;
  barPaddingX: number;
  barHeight: number;
  yBarOffset: number;
}

const arrowSize = 4;

export type PointList = Point[];
type Directions = 'down' | 'up' | 'right' | 'left';

export function calculateLinePoints(context: Context): {
  linePath: PointList;
  trianglePath: number[];
} {
  const startInDays = getStartInDays(context);
  const endsInDays = getEndInDays(context);
  const from = {x: startInDays, y: context.predecessorIndex};
  const to = {x: endsInDays, y: context.index};
  const {linePath, trianglePath} = createLine(from, to, context);
  return {
    linePath,
    trianglePath,
  };
}

function getStartInDays(context: Context): number {
  let startInDays;
  if (context.dependencyType === TYPE_FS || context.dependencyType === TYPE_FF) {
    startInDays = context.predecessorStart + context.predecessorWidth;
  } else {
    startInDays = context.predecessorStart;
  }
  return startInDays;
}

function getEndInDays(context: Context): number {
  let endsInDays;
  if (context.dependencyType === TYPE_FS || context.dependencyType === TYPE_SS) {
    endsInDays = context.successorStart;
  } else {
    endsInDays = context.successorStart + context.successorWidth;
  }
  return endsInDays;
}

function createLine(from: Point, to: Point, context: Context): {linePath: PointList; trianglePath: number[]} {
  let pointList: PointList;
  if (context.dependencyType === TYPE_FS) {
    pointList = calculateLineType1(from, to, context);
  } else if (context.dependencyType === TYPE_SS) {
    pointList = calculateLineType2(from, to, context);
  } else {
    pointList = calculateLineType3(from, to, context);
  }
  const arrowDirection = getDirections(from, to, context);
  const lastPoint = pointList[pointList.length - 1];
  return {
    linePath: pointList,
    trianglePath: drawArrow(lastPoint.x, lastPoint.y, arrowDirection),
  };
}

function drawArrow(x: number, y: number, direction: Directions): number[] {
  const lineString: number[] = [];
  if (direction === 'down') {
    lineString.push(x, y);
    lineString.push(x + arrowSize, y - arrowSize - 1); //to top right
    lineString.push(x - arrowSize, y - arrowSize - 1); //to top left
  } else if (direction === 'up') {
    lineString.push(x, y);
    lineString.push(x + arrowSize, y + arrowSize + 1); //to bottom right
    lineString.push(x - arrowSize, y + arrowSize + 1); //to bottom left
  } else if (direction === 'right') {
    lineString.push(x + 3, y);
    lineString.push(x - arrowSize - 1 + 3, y - arrowSize); //to bottom right
    lineString.push(x - arrowSize - 1 + 3, y + arrowSize); //to bottom left
  } else if (direction === 'left') {
    lineString.push(x - 3, y);
    lineString.push(x + arrowSize + 1, y - arrowSize); //to top right
    lineString.push(x + arrowSize + 1, y + arrowSize); //to bottom right
  }

  return lineString;
}

function getDirections(from: Point, to: Point, context: Context): Directions {
  let arrowDirection: Directions;

  if (context.dependencyType === TYPE_FS) {
    if (from.x === to.x) {
      if (from.y > to.y) {
        arrowDirection = 'up';
      } else {
        arrowDirection = 'down';
      }
    } else {
      arrowDirection = 'right';
    }
  } else if (context.dependencyType === TYPE_SS) {
    if (from.y > to.y) {
      arrowDirection = 'up';
    } else {
      arrowDirection = 'down';
    }
    if (from.x < to.x) {
      arrowDirection = 'right';
    }
  } else {
    if (from.y > to.y) {
      arrowDirection = 'up';
    } else {
      arrowDirection = 'down';
    }
    if (from.x > to.x) {
      arrowDirection = 'left';
    }
  }
  return arrowDirection;
}

function calculateLineType1(from: Point, to: Point, context: Context): PointList {
  if (from.x <= to.x) {
    return calculateDefaultLineType(from, to, context);
  }
  const pointList: PointList = [];
  const halfCell = context.cellWidth / 2;
  const startY = getStartY(from, to, context.barPaddingY + context.yBarOffset, context);
  const startX = getStartX(from, to, context);

  pointList.push({x: startX, y: startY});
  //line ends in front of bar
  const endY = getEndYLeftRight(from, to, context);
  const endX = to.x * context.cellWidth;
  const cornerY = getCornerY(from, to, context);
  const cornerX = endX - halfCell;
  pointList.push({x: startX, y: cornerY}); //generate corner
  pointList.push({x: cornerX, y: cornerY}); //generate corner
  pointList.push({x: cornerX, y: endY}); //generate corner
  pointList.push({x: endX, y: endY});

  return pointList;
}

function calculateLineType2(from: Point, to: Point, context: Context): PointList {
  if (from.x <= to.x) {
    return calculateDefaultLineType(from, to, context);
  }
  const halfCell = context.cellWidth / 2;
  const pointList: PointList = [];
  const startY = getStartYCentered(from, to, context);
  const startX = getStartX(from, to, context);

  pointList.push({x: startX, y: startY}); //start from side

  const endY = getEndYDownUp(from, to, context);
  const endX = to.x * context.cellWidth + halfCell;
  pointList.push({x: endX, y: startY}); //generate corner
  pointList.push({x: endX, y: endY});
  return pointList;
}

function calculateLineType3(from: Point, to: Point, context: Context): PointList {
  if (from.x >= to.x) {
    return calculateDefaultLineType(from, to, context);
  }
  const halfCell = context.cellWidth / 2;
  const pointList: PointList = [];
  const startY = getStartYCentered(from, to, context);
  const startX = getStartX(from, to, context);

  pointList.push({x: startX, y: startY}); //start from side

  const endY = getEndYDownUp(from, to, context);
  const endX = to.x * context.cellWidth + halfCell;
  pointList.push({x: endX, y: startY}); //generate corner
  pointList.push({x: endX, y: endY});
  return pointList;
}

function calculateDefaultLineType(from: Point, to: Point, context: Context): PointList {
  const startY = getStartY(from, to, context.barPaddingY + context.yBarOffset, context);
  const startX = getStartX(from, to, context);
  let endY: number;
  let endX: number;
  const pointList: PointList = [];
  pointList.push({x: startX, y: startY});
  if (from.x === to.x) {
    //line ends above or below bar
    endY = getEndYDownUp(from, to, context);
    endX = startX;
  } else {
    //line ends in front of bar
    endY = getEndYLeftRight(from, to, context);
    endX = getEndX(from, to, context);
    pointList.push({x: startX, y: endY}); //generate corner
  }
  pointList.push({x: endX, y: endY});
  return pointList;
}

function getStartX(from: Point, to: Point, context: Context): number {
  const halfCell = context.cellWidth / 2;

  if (context.dependencyType === TYPE_FS) {
    return from.x * context.cellWidth + halfCell;
  }
  if (context.dependencyType === TYPE_FF) {
    if (from.x >= to.x) {
      return from.x * context.cellWidth + halfCell;
    }
    return (from.x + 1) * context.cellWidth;
  }
  if (from.x <= to.x) {
    return from.x * context.cellWidth + halfCell;
  }
  return from.x * context.cellWidth;
}

function getStartY(from: Point, to: Point, padding: number, context: Context): number {
  if (from.y > to.y) {
    return from.y * context.cellHeight + padding;
  }
  return from.y * context.cellHeight - padding + context.barHeight;
}

function getStartYCentered(from: Point, _to: Point, context: Context): number {
  const halfHeight = context.barHeight / 2 + context.yBarOffset;
  return from.y * context.cellHeight + halfHeight;
}

function getCornerY(from: Point, to: Point, context: Context): number {
  if (from.y > to.y) {
    return from.y * context.cellHeight;
  }
  return (from.y + 1) * context.cellHeight;
}

function getEndYDownUp(from: Point, to: Point, context: Context): number {
  if (from.y > to.y) {
    return to.y * context.cellHeight + context.cellHeight - context.barPaddingY;
  }
  return to.y * context.cellHeight + context.barPaddingY + context.yBarOffset;
}

function getEndYLeftRight(_from: Point, to: Point, context: Context): number {
  return to.y * context.cellHeight + context.barHeight * 0.5 + context.yBarOffset;
}

function getEndX(_from: Point, to: Point, context: Context): number {
  const endsAtEnd = context.dependencyType === TYPE_FF;
  return (to.x + (endsAtEnd ? 1 : 0)) * context.cellWidth;
}
