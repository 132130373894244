import {
  dayjsToMoment,
  Moment,
  momentToDayjs,
  optionalDayjsToMoment,
  optionalMomentToDayjs,
} from '@octaved/dayjs-moment';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {cn} from '@octaved/ui';
import dayjs, {Dayjs} from 'dayjs';
import {ArrowLeftCircle, ArrowRightCircle} from 'lucide-react';
import {ReactElement, useCallback, useEffect, useRef} from 'react';
import {DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape, ModifiersShape} from 'react-dates';
import {useSelector} from 'react-redux';
import {Icon} from '../../../Util';
import {ConvertFromFn, ConvertToFn, OnChangeFn} from '../DateOrRangePicker';
import DayView from './DayView';

const notBlocked = (): boolean => false;
export type DayRendererFn = ((day: Moment, modifiers?: ModifiersShape) => React.ReactNode) | null | undefined;

export interface PickerViewProps<DATE, CAN_CLEAR extends boolean> {
  canClear?: CAN_CLEAR;
  className?: string;
  convertFrom: ConvertFromFn<DATE>;
  convertTo: ConvertToFn<DATE>;
  end: DATE | null;
  endDateOffset?: (day: DATE) => DATE;
  displayMonth?: DATE;
  focused: FocusedInputShape | null;
  isDayBlocked?: (date: DATE) => boolean;
  isRange: boolean;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  numberOfMonth?: number;
  onChangeDates: OnChangeFn<DATE, CAN_CLEAR>;
  readonly?: boolean;
  renderDayContents?: DayRendererFn;
  setFocused: (focus: FocusedInputShape | null) => void;
  snapToStartDateMonth?: boolean;
  start: DATE | null;
  startDateOffset?: (day: DATE) => DATE;
  strictBlockedDates?: boolean;
  onMonthChange?: (date: Dayjs) => void;
}

export default function PickerView<DATE, CAN_CLEAR extends boolean>({
  canClear = true as CAN_CLEAR,
  className,
  convertFrom,
  convertTo,
  end,
  endDateOffset,
  displayMonth,
  focused,
  isDayBlocked,
  isRange,
  maxDate,
  minDate,
  numberOfMonth = 1,
  onChangeDates,
  onMonthChange,
  readonly,
  renderDayContents,
  setFocused,
  snapToStartDateMonth,
  start,
  startDateOffset,
  strictBlockedDates,
}: PickerViewProps<DATE, CAN_CLEAR>): ReactElement {
  const getInitialMonth = useCallback(() => {
    return dayjsToMoment(convertFrom(start) || dayjs());
  }, [convertFrom, start]);
  const isoDate = useSelector(todayIsoDateSelector);
  const lastManualMonthChange = useRef<Dayjs>();
  const monthSetter = useRef<(currentMonth: Moment, newMonthVal: string) => void>();
  const yearSetter = useRef<(currentMonth: Moment, newMonthVal: string) => void>();

  useEffect(() => {
    const newDate = displayMonth && dayjs(convertFrom(displayMonth));
    if (newDate && (!lastManualMonthChange.current || !newDate.isSame(lastManualMonthChange.current, 'year'))) {
      const isoDayjs = dayjs(isoDate);
      const nextMonth = isoDayjs.isSame(newDate, 'year') ? +isoDayjs.format('MM') : 1;
      yearSetter.current?.(dayjsToMoment(newDate), newDate.format('YYYY'));
      monthSetter.current?.(dayjsToMoment(newDate), (nextMonth - 3).toString());
      lastManualMonthChange.current = newDate;
    }
  }, [displayMonth, convertFrom, isoDate]);

  const isBlockedDate = useCallback(
    (date: Moment): boolean => {
      const max = convertTo(maxDate || null);
      const min = convertTo(minDate || null);
      const currentDate = convertTo(momentToDayjs(date).startOf('day'));
      let blockedByMinMax = false;
      if (max && min) {
        blockedByMinMax = currentDate < min || currentDate > max;
      } else if (min) {
        blockedByMinMax = currentDate < min;
      } else if (max) {
        blockedByMinMax = currentDate > max;
      }
      return blockedByMinMax || Boolean(isDayBlocked?.(currentDate));
    },
    [convertTo, maxDate, minDate, isDayBlocked],
  );

  const onRangeChangeDate = useCallback(
    ({startDate, endDate}: {startDate: Moment | null; endDate: Moment | null}): void => {
      const parsedStart = convertTo(optionalMomentToDayjs(startDate ? startDate.startOf('day') : null));
      const parsedEnd = convertTo(optionalMomentToDayjs(endDate ? endDate.startOf('day') : null));

      if (strictBlockedDates && parsedEnd && parsedStart) {
        const diff = convertFrom(parsedEnd).diff(convertFrom(parsedStart), 'days');
        for (let i = 0; i <= diff; ++i) {
          const checkDate = convertFrom(parsedStart).add(i, 'd');
          if (isBlockedDate(dayjsToMoment(checkDate))) {
            return;
          }
        }
      }

      onChangeDates(parsedStart!, parsedEnd!);

      if (focused === 'startDate') {
        setFocused('endDate');
      } else if (focused === 'endDate' && parsedEnd) {
        setFocused('startDate');
      }
    },
    [convertTo, strictBlockedDates, focused, convertFrom, isBlockedDate, onChangeDates, setFocused],
  );

  const renderDayFn: DayRendererFn = (day, ...args) => {
    if (renderDayContents) {
      return renderDayContents(day, ...args);
    } else {
      return (
        <DayView
          day={day}
          isOutsideOfRange={isBlockedDate(day)}
          hasBlockedDates={Boolean(minDate || maxDate || Boolean(isDayBlocked))}
        />
      );
    }
  };

  return (
    <div className={cn('picker', `months-${numberOfMonth}`, {readonly, strictBlockedDates}, className)}>
      {!isRange && (
        <DayPickerSingleDateController
          focused
          transitionDuration={0}
          initialVisibleMonth={getInitialMonth}
          onNextMonthClick={(date) => {
            const newDate = momentToDayjs(date);
            onMonthChange?.(newDate);
            lastManualMonthChange.current = newDate;
          }}
          onPrevMonthClick={(date) => {
            const newDate = momentToDayjs(date);
            onMonthChange?.(newDate);
            lastManualMonthChange.current = newDate;
          }}
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          noBorder
          keepOpenOnDateSelect
          enableOutsideDays
          numberOfMonths={numberOfMonth}
          renderMonthElement={({month, onMonthSelect, onYearSelect}) => {
            monthSetter.current = onMonthSelect;
            yearSetter.current = onYearSelect;
            return <>{month.format('MMMM YYYY')}</>;
          }}
          onFocusChange={() => {
            //empty function
          }}
          navPrev={
            <div className={'prevButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowLeftCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          navNext={
            <div className={'nextButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowRightCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          date={optionalDayjsToMoment(convertFrom(end))}
          onDateChange={(date) => {
            const isoDate = convertTo(optionalMomentToDayjs(date ? date.startOf('day') : null));
            if (canClear === true || isoDate) {
              onChangeDates(isoDate!, isoDate!);
            }
          }}
          renderDayContents={renderDayFn}
          isDayBlocked={strictBlockedDates ? isBlockedDate : notBlocked}
        />
      )}

      {isRange && (
        <DayPickerRangeController
          key={snapToStartDateMonth ? convertFrom(start)?.format('MM') : undefined}
          transitionDuration={0}
          initialVisibleMonth={getInitialMonth}
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          noBorder
          keepOpenOnDateSelect
          minimumNights={0}
          numberOfMonths={numberOfMonth}
          enableOutsideDays
          navPrev={
            <div className={'prevButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowLeftCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          navNext={
            <div className={'nextButton'}>
              <Icon inline noMargin size={'larger'}>
                <ArrowRightCircle strokeWidth={1} />
              </Icon>
            </div>
          }
          focusedInput={focused}
          renderMonthElement={({month}) => <>{month.format('MMMM YYYY')}</>}
          startDate={optionalDayjsToMoment(convertFrom(start))}
          endDate={optionalDayjsToMoment(convertFrom(end))}
          onDatesChange={onRangeChangeDate}
          onFocusChange={(newFocus) => setFocused(newFocus)}
          renderDayContents={renderDayFn}
          isDayBlocked={strictBlockedDates ? isBlockedDate : notBlocked}
          startDateOffset={
            startDateOffset
              ? (day) => dayjsToMoment(convertFrom(startDateOffset(convertTo(momentToDayjs(day)))))
              : undefined
          }
          endDateOffset={
            endDateOffset
              ? (day) => dayjsToMoment(convertFrom(endDateOffset(convertTo(momentToDayjs(day)))))
              : undefined
          }
        />
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .picker {
          min-height: 195px;
          padding-top: 5px;

          --color-bg: var(--color-white);
          --color-calendar-caption: var(--color-grey-700);
          --color-calendar-selectedBg: var(--color-blue-600);
          --color-calendar-selectedSpanBg: var(--color-blue-100);
          --color-calendar-hoverSpanBg: var(--color-grey-150);
          --color-calendar-day-outsideMonth-text: var(--color-grey-300);
          --color-calendar-day-outside-text: var(--color-grey-600);
          --color-calendar-day-outside-bg: var(--color-grey-25);
          --color-calendar-day-outside-selectedBg: var(--color-red-400);
          --color-calendar-day-outside-selectedSpanBg: var(--color-red-100);
          --color-calendar-day-outside-hoverBg: var(--color-red-100);
          --color-calendar-day-within-hoverBg: var(--color-green-100);
          --color-calendar-today: var(--color-purple-500);
        }

        .picker :global(.DayPicker),
        .picker :global(.DayPicker > div > div),
        .picker :global(.DayPicker_transitionContainer) {
          width: 228px !important;
        }

        .picker.months-2 :global(.DayPicker),
        .picker.months-2 :global(.DayPicker > div > div),
        .picker.months-2 :global(.DayPicker_transitionContainer) {
          width: 471px !important;
        }

        .picker :global(.DayPicker_transitionContainer[style*='height: 244px']) {
          height: 197px !important;
        }

        .picker :global(.DayPicker_transitionContainer[style*='height: 282px']) {
          height: 229px !important;
        }

        .picker :global(.DayPicker_transitionContainer[style*='height: 320px']) {
          height: 261px !important;
        }

        .picker :global(.CalendarMonthGrid__horizontal) {
          left: 0;
        }

        .picker :global(.DayPicker_weekHeaders__horizontal) {
          margin-left: 0;
        }

        .picker :global(.CalendarMonth),
        .picker :global(.DayPicker_weekHeader) {
          padding: 0 !important;
        }

        .picker.months-2 :global(.CalendarMonthGrid_month__horizontal + .CalendarMonthGrid_month__horizontal) {
          padding-right: 15px;
        }

        .picker :global(.DayPicker_transitionContainer__horizontal) {
          transition: none;
        }

        .picker :global(.CalendarMonth_caption) {
          font-size: 14px;
          font-weight: 400;
          color: var(--color-calendar-caption);
          padding: 0 0 2.5rem 0;
          line-height: 28px;
        }

        .picker :global(.DayPicker_weekHeader) {
          top: calc(50px - 14px);
        }

        .picker :global(.DayPicker_weekHeader + .DayPicker_weekHeader) {
          left: 242px !important;
        }

        .picker :global(.CalendarMonth_caption) {
          font-size: 12px;
        }

        .picker :global(.CalendarDay),
        .picker :global(.CalendarDay__blocked_calendar) {
          height: 32px !important;
          width: 32.5px !important;
          border: 0;
          font-size: 12px;
          font-weight: 400;
          color: var(--color-text);
        }

        .picker.strictBlockedDates :global(.CalendarDay__blocked_calendar) {
          background-color: #fff;
        }

        .picker.strictBlockedDates :global(.CalendarDay__outside),
        .picker.readonly :global(.CalendarDay),
        .picker.readonly :global(.CalendarDay .date),
        .picker.readonly :global(.CalendarDay .numberOfMonth) {
          cursor: default !important;
        }

        .picker.readonly :global(.CalendarDay:hover) {
          background: #fff;
        }

        .picker :global(.DayPicker_weekHeader_li) {
          width: 32.5px !important;
        }

        .picker :global(.CalendarDay__outside),
        .picker :global(.DayPicker_weekHeader_li) {
          color: var(--color-calendar-day-outsideMonth-text);
        }

        .picker :global(.CalendarDay__selected),
        .picker.readonly :global(.CalendarDay.CalendarDay__selected:hover) {
          color: var(--color-bg);
          background-color: var(--color-calendar-selectedBg);
        }

        .picker :global(.CalendarDay__hovered_span) {
          background-color: var(--color-calendar-hoverSpanBg);
        }

        .picker :global(.CalendarDay__selected_span),
        .picker.readonly :global(.CalendarDay.CalendarDay__selected_span:hover) {
          background-color: var(--color-calendar-selectedSpanBg);
        }

        .picker :global(.CalendarDay__selected_span.CalendarDay__outside),
        .picker.readonly :global(.CalendarDay.CalendarDay__selected_span.CalendarDay__outside:hover) {
          color: var(--color-text);
        }

        .prevButton,
        .nextButton {
          position: absolute;
          top: 0.2625rem;
        }

        .picker.months-2 .prevButton,
        .picker.months-2 .nextButton {
          top: 1.5px;
        }

        .prevButton {
          left: 0.35rem;
        }

        .nextButton {
          right: 0.35rem;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
