import {CustomTooltip} from '@octaved/ui';
import {timestampToFormatString} from '@octaved/users/src/Culture/DateFormatFunctions';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import BilledIcon from '../../../../Pages/Billing/BilledIcon';

interface Props {
  billedOn: number;
}

export default function LastExportOrPartialSettlementCell({billedOn}: Props): ReactElement {
  const format = useSelector(dateFormatSelector);
  const date = timestampToFormatString(billedOn, format);

  return (
    <div className={'content'}>
      <CustomTooltip
        content={
          <>
            <div>{date}</div>
          </>
        }
      >
        <div>
          <BilledIcon noMargin />
        </div>
      </CustomTooltip>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .content {
          display: flex;
          justify-content: center;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
