import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {error} from '@octaved/env/src/Logger';
import {getGlobalRoleAssignments, patchGlobalRoleAssignments} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import NetworkError from '@octaved/network/src/NetworkError';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createFlatTimestampReducer,
  EntityState as SingleEntityState,
  INVALIDATED,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {FullUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {useLoadedGroups} from '@octaved/users/src/Modules/Group';
import {useLoadedUserNames} from '@octaved/users/src/Modules/OrgUser';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalRoleAssignment, GlobalRoleAssignments} from '../EntityInterfaces/GlobalRoleAssignments';
import {
  FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_FAILURE,
  FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_REQUEST,
  FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS,
  FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_FAILURE,
  FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_REQUEST,
  FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS,
} from './ActionTypes';
import {RoleEvent} from './Events';
import {
  globalRoleAssignmentAllUnitIdsSelector,
  globalRoleAssignmentStatesStateSelector,
  globalRoleAssignmentUnitListsSelector,
} from './Selectors/GlobalRoleAssignmentSelectors';
import {FlowState} from './State';
import {removeErrorForField} from './Ui';

export const globalRoleSelfLockoutErrorFields = ['patchGlobalRoleAssignments.self-lock-out'];

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS,
  (_state: GlobalRoleAssignments, {response}: {response: GlobalRoleAssignments}): GlobalRoleAssignments => {
    return response;
  },
);
export const globalRoleAssignmentsReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS, createFlatTimestampReducer(LOADED));
stateReducerMap.set('flow.GlobalRolesBulkChangedEvent', createFlatTimestampReducer(INVALIDATED));
stateReducerMap.set('flow.RoleRemovedEvent', (state: SingleEntityState, action: RoleEvent): SingleEntityState => {
  if (action.roleType === EnumFlowRoleType.VALUE_GLOBAL_PERMISSION) {
    return createFlatTimestampReducer(INVALIDATED)(state, action);
  }
  return state;
});
export const globalRoleAssignmentsStateReducer = ReduceFromMap(stateReducerMap);

function bulkPatchGlobalRoleAssignments(data: GlobalRoleAssignments): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch) => {
    try {
      await dispatch({
        [CALL_API]: {
          endpoint: patchGlobalRoleAssignments,
          method: 'patch',
          options: {data},
          throwNetworkError: true,
          types: {
            failureType: FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_FAILURE,
            requestType: FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_REQUEST,
            successType: FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS,
          },
        },
      });
      dispatch(removeErrorForField(globalRoleSelfLockoutErrorFields));
      return true;
    } catch (e) {
      if (!(e instanceof NetworkError) || e.responseStatus !== 409) {
        error(e);
      }
      return false;
    }
  };
}

function loadGlobalRoleAssignments(): ActionDispatcher<void> {
  return (dispatch, getState) => {
    if (isOutdated(globalRoleAssignmentStatesStateSelector(getState()))) {
      dispatch({
        [CALL_API]: {
          endpoint: getGlobalRoleAssignments,
          types: {
            failureType: FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_FAILURE,
            requestType: FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_REQUEST,
            successType: FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS,
          },
        },
      });
    }
  };
}

const emptyList: FullUnit[] = [];

/**
 * @return {boolean} hasLoadedOnce
 */
export function useLoadGlobalRoleAssignments(): void {
  useStoreEffect((dispatch) => dispatch(loadGlobalRoleAssignments()), [], globalRoleAssignmentStatesStateSelector);
}

export function useLoadGlobalRoleAssignmentUnits(): void {
  const {groupIds, userIds} = useSelector(globalRoleAssignmentAllUnitIdsSelector);
  useLoadedUserNames(userIds);
  useLoadedGroups(groupIds);
}

export function useReadGlobalRoleAssignments(roleId: string): {
  isLoading: boolean;
  units: FullUnit[];
} {
  const {isLoading: listIsLoading, unitLists} = useSelector(globalRoleAssignmentUnitListsSelector);
  const assignmentsAreLoading = useSelector((s: FlowState) => !isLoaded(globalRoleAssignmentStatesStateSelector(s)));
  return {
    isLoading: assignmentsAreLoading || listIsLoading,
    units: unitLists[roleId] || emptyList,
  };
}

function stringifyUnits(units: FullUnit[]): string {
  return units
    .map(({unitId}) => unitId)
    .sort()
    .join('+');
}

export function useManageGlobalRoleAssignments(roleId: string): {
  hasChanges: boolean;
  isLoading: boolean;
  isSaving: boolean;
  save: () => Promise<boolean>;
  setUnits: (units: FullUnit[]) => void;
  units: FullUnit[];
} {
  const dispatch = useDispatch();
  const {units: storeUnits, isLoading} = useReadGlobalRoleAssignments(roleId);
  const loadedOnce = useRef(false);
  const [isSaving, setSaving] = useState(false);
  const isSavingRef = useRef(false);
  const [units, setUnits] = useState(storeUnits);
  const storeUnitsRef = useRef(storeUnits);
  storeUnitsRef.current = storeUnits;

  const hasChanges = useMemo(() => stringifyUnits(storeUnits) !== stringifyUnits(units), [storeUnits, units]);

  //Trigger initial reset after load:
  useEffect(() => {
    if (!isLoading && !loadedOnce.current) {
      setUnits(storeUnits);
      loadedOnce.current = true;
    }
  }, [isLoading, storeUnits]);

  const save = useCallback(async (): Promise<boolean> => {
    if (isSavingRef.current) {
      return false;
    }
    isSavingRef.current = true;
    setSaving(isSavingRef.current);

    const data: GlobalRoleAssignment = {groupIds: [], userIds: []};
    units.forEach(({unitType, unitId}) => {
      if (unitType === SimpleUnitType.group) {
        data.groupIds!.push(unitId);
      } else {
        data.userIds!.push(unitId);
      }
    });
    const success = await dispatch(bulkPatchGlobalRoleAssignments({[roleId]: data}));
    if (success) {
      setUnits(storeUnitsRef.current);
    }

    isSavingRef.current = false;
    setSaving(isSavingRef.current);

    return success;
  }, [dispatch, roleId, units]);

  return {
    hasChanges,
    isLoading,
    isSaving,
    save,
    units,
    setUnits: useCallback(
      (u: FullUnit[]) => {
        dispatch(removeErrorForField(globalRoleSelfLockoutErrorFields));
        setUnits(u);
      },
      [dispatch],
    ),
  };
}
