import dayjs from 'dayjs';
import Konva from 'konva';
import {Dispatch, SetStateAction} from 'react';
import {Calendar} from '../Calendar/Calendar';
import {CalenderResizeEvent} from '../Calendar/Context/CalendarContext';
import {Zoom} from '../CommonComponents/Zoom';
import {Disposable} from '../Disposable';
import {BarFactory} from '../Gantt/Bars/BarFactory';
import {BarRows} from '../Gantt/Bars/BarRows';
import {GanttContext} from '../Gantt/Context/GanttContext';
import {Table} from '../Gantt/Table/Table';
import {TableFactory} from '../Gantt/Table/TableFactory';
import {createStoreSubscription} from '../StoreSubscription';
import {ProjectBasedGanttDataLoader} from './ProjectBasedGanttDataLoader';

export interface ProjectBasedPlanningProps {
  ctx: GanttContext;
  canvas: HTMLDivElement;
  planningDataLoader: ProjectBasedGanttDataLoader;
  barFactory: BarFactory;
  tableFactory: TableFactory;
  setScrollWidth: Dispatch<SetStateAction<number>>;
  setScrollHeight: Dispatch<SetStateAction<number>>;
}

interface InitOptions {
  initialScrollDate?: dayjs.Dayjs;
}

export class ProjectBasedPlanning extends Disposable {
  readonly #stage: Konva.Stage;
  readonly #ctx: GanttContext;
  readonly #planningDataLoader: ProjectBasedGanttDataLoader;
  readonly #setScrollWidth: Dispatch<SetStateAction<number>>;
  readonly #setScrollHeight: Dispatch<SetStateAction<number>>;
  readonly #barFactory: BarFactory;
  readonly #tableFactory: TableFactory;
  #zoom: Zoom | null = null;

  constructor({
    canvas,
    ctx,
    planningDataLoader,
    setScrollHeight,
    setScrollWidth,
    barFactory,
    tableFactory,
  }: ProjectBasedPlanningProps) {
    super();
    this.#stage = new Konva.Stage({
      container: canvas,
      height: canvas.clientHeight,
      width: canvas.clientWidth,
    });
    this.#ctx = ctx;
    this.#planningDataLoader = planningDataLoader;
    this.#setScrollWidth = setScrollWidth;
    this.#setScrollHeight = setScrollHeight;
    this.#barFactory = barFactory;
    this.#tableFactory = tableFactory;
  }

  init({initialScrollDate}: InitOptions = {}): void {
    const calendarLayer = new Konva.Layer();
    const barLayer = new Konva.Layer();
    this.#stage.add(calendarLayer);
    this.#stage.add(barLayer);

    const barRows = new BarRows({
      barFactory: this.#barFactory,
      ctx: this.#ctx,
      planningDataLoader: this.#planningDataLoader,
    });
    barLayer.add(barRows.root);
    barRows.init();

    const calendar = new Calendar({ctx: this.#ctx});
    calendarLayer.add(calendar.root);
    calendar.init();

    const table = new Table({
      ctx: this.#ctx,
      planningDataLoader: this.#planningDataLoader,
      tableFactory: this.#tableFactory,
    });
    const tableLayer = new Konva.Layer();
    this.#stage.add(tableLayer);
    tableLayer.add(table.root);
    table.init();

    this.#zoom = new Zoom({ctx: this.#ctx});
    this.#zoom.init();
    barLayer.add(this.#zoom.root);
    this.#zoom?.root.x(this.#stage.width() - this.#zoom.root.width() - 20);
    this.#zoom?.root.y(this.#stage.height() - this.#zoom.root.height() - 20);

    this.disposables.push(
      () => this.#stage.destroy(),
      () => calendar.dispose(),
      () => table.dispose(),
      () => barRows.dispose(),
      () => this.#zoom?.dispose(),
      this.#ctx.eventEmitter.on('resize', this.#onResize),
      this.#ctx.eventEmitter.on('flatTreeChanged', this.#updateScrollBars),
      this.#ctx.eventEmitter.on('calenderDatesChanged', this.#updateScrollBars),
      this.#ctx.eventEmitter.on('columnWidthChanged', this.#updateScrollBars),
      createStoreSubscription(
        this.#ctx.store,
        () => this.#ctx.calculateTableWidth(),
        (width) => {
          calendar.root.x(width);
          this.#setScrollWidth(this.#ctx.calendarView.width + width);
        },
      ),
    );

    requestAnimationFrame(() => {
      if (initialScrollDate) {
        this.#ctx.scrolling.scrollToDate(initialScrollDate);
      } else {
        this.#ctx.scrolling.scrollToToday();
      }
    });
  }

  #onResize = ({width, height}: CalenderResizeEvent): void => {
    this.#stage.width(width);
    this.#stage.height(height);
    this.#stage.children.forEach((child) => {
      if (child.canvas.pixelRatio !== window.devicePixelRatio) {
        child.canvas.setPixelRatio(window.devicePixelRatio);
      }
    });

    this.#zoom?.root.x(width - this.#zoom.root.width() - 20);
    this.#zoom?.root.y(height - this.#zoom.root.height() - 20);
  };

  #updateScrollBars = (): void => {
    this.#setScrollHeight(this.#ctx.calendarView.height + Table.footerHeight);
    this.#setScrollWidth(this.#ctx.calendarView.width + this.#ctx.calculateTableWidth());
  };
}
