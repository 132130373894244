import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Sums} from './Sums';

interface Props {
  label: string;
  sums: Sums;
}

export default function NodeScopeEffort({label, sums}: Props): ReactElement {
  const {t} = useTranslation();
  return (
    <>
      <div>{t(label)}:</div>
      {sums.effortHours > 0 && (
        <div>
          {t('general:date.hours', {
            count: sums.effortHours,
            countFormatted: formatDecimal(sums.effortHours),
          })}
          {` (${t('general:date.days', {
            count: sums.effortDays,
            countFormatted: formatDecimal(sums.effortDays),
          })})`}
        </div>
      )}
      {sums.effortHours <= 0 && <div>-</div>}
    </>
  );
}
