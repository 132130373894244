import {flowStyleContext} from '@octaved/flow/src/Styles/StyleContext';
import {Icon, IconColor, IconSize} from '@octaved/ui';
import classNames from 'classnames';
import {ReactElement, useContext} from 'react';

interface Props {
  children: ReactElement;
  iconColor: IconColor;
  className?: string;
  inverted?: boolean;
  iconSize?: IconSize;
}

function CircleIconButton({children, className = '', iconColor, inverted, iconSize = 'normal'}: Props): ReactElement {
  const {
    COLORS: {FONT},
  } = useContext(flowStyleContext);

  return (
    <div className={classNames(className, 'circleIconButton', `color-${iconColor}`, {inverted})}>
      <Icon iconColor={iconColor === 'darkGrey' ? 'darkGrey' : 'white'} noMargin size={iconSize}>
        {children}
      </Icon>
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .circleIconButton {
          background-color: #fff;
          border: 1px solid #ddd;
          height: 32px;
          min-width: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          &:hover {
            border-color: #ccc;
            background-color: #e7e7e7;
          }

          &.color-brightBlue {
            background: linear-gradient(225deg, #5da8ef 0%, #167fe3 100%);
            background-color: #167fe3;
            border-color: #167fe3;
          }

          &.color-darkPurple {
            background: linear-gradient(225deg, #a017c1 0%, #81169b 100%);
            background-color: #81169b;
            border-color: #81169b;
          }

          &.color-yellow {
            background: rgb(252, 187, 17);
            background: linear-gradient(45deg, rgba(252, 187, 17, 1) 0%, rgba(255, 217, 11, 1) 100%);
            border-color: rgba(252, 187, 17, 1);

            & :global(svg) {
              fill: #fff;
            }
          }

          &.color-grey {
            background: rgb(78, 77, 75);
            background: linear-gradient(45deg, rgb(82, 82, 80) 0%, rgb(137, 136, 134) 100%);
            border-color: rgb(78, 77, 75);

            & :global(svg) {
              fill: #fff;
            }
          }
        }

        .inverted {
          background-color: ${FONT.INVERTED};
        }

        .play {
          padding-left: 2px;
        }

        .quickNote {
          padding-left: 1px;
          padding-top: 1px;
        }
      `}</style>
    </div>
  );
}

export {CircleIconButton};
