import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {ProjectFolderTreeNode} from '@octaved/flow/src/Modules/Selectors/ProjectFolderSelectors';
import {rootFoldersProjectFolderSelector} from '@octaved/flow/src/Modules/Selectors/RootFolderSelectors';
import {cn, IconButton, NodeIcon} from '@octaved/ui';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {ChevronDown, ChevronRight} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Children from './Children';
import {useProjectTreeSelectorContext} from './ProjectFolderSelectorContext';

interface Props {
  node: ProjectFolderTreeNode;
}

export default function Node({node}: Props): ReactElement {
  const {t} = useTranslation();
  const rootFolderId = useSelector(rootFoldersProjectFolderSelector);
  const {isExpanded, isSelected, expand, select} = useProjectTreeSelectorContext();
  const hasChildren = node.children.length > 0;
  const expanded = isExpanded(node.id);
  const selected = isSelected(node.id);
  const isFolderRoot = node.id === rootFolderId;
  return (
    <div>
      <div
        className={cn(
          'flex h-8 w-full items-center gap-x-2 overflow-hidden py-1 pr-2 hover:bg-sky-50',
          selected && 'bg-sky-100',
          !selected && 'cursor-pointer',
        )}
        onClick={() => select(node.id)}
      >
        {hasChildren ? (
          <IconButton
            className={'flex-shrink-0'}
            icon={expanded ? <ChevronDown /> : <ChevronRight />}
            iconColor={'darkGrey'}
            onClick={(e) => {
              e.stopPropagation();
              expand(node.id);
            }}
            size={'xs'}
            variant={'ghost'}
          />
        ) : (
          <div className={'size-6 flex-shrink-0'} />
        )}
        <NodeIcon nodeType={EnumFlowNodeType.VALUE_PROJECT_FOLDER} className={'flex-shrink-0'} />
        {isFolderRoot && <span className={'italic'}>{t('nodeTable:quickFilter.all')}</span>}
        {!isFolderRoot && <div className={'truncate'}>{node.name}</div>}
      </div>
      {hasChildren && (
        <SlideToggleElement visible={expanded}>
          <div className={'pl-[27px]'}>
            <Children nodes={node.children} />
          </div>
        </SlideToggleElement>
      )}
    </div>
  );
}
