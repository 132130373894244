import {ReChartPieChartDataSet} from '@octaved/ui-components/src/React/ReCharts/PieChart/SimplePieChart';
import {ReactElement, ReactNode, useMemo} from 'react';
import InspectorPie, {InspectorPieSizes} from './InspectorPie';

interface Props {
  children: ReactNode;
  fillColor?: string;
  percentage: number | undefined;
  size?: InspectorPieSizes;
}

export default function SinglePercentagePie({children, fillColor = '#2388de', percentage, size}: Props): ReactElement {
  const data = useMemo((): ReChartPieChartDataSet[] => {
    if (percentage === undefined) {
      return [{color: '#E1E1E4', name: '', value: 100}];
    }
    return [
      {color: '#E1E1E4', name: '', value: 100 - percentage},
      {color: fillColor, name: '', value: percentage},
    ];
  }, [fillColor, percentage]);
  return (
    <InspectorPie data={data} size={size}>
      {children}
    </InspectorPie>
  );
}
