import {Uuid} from '@octaved/typescript/src/lib';
import classNames from 'classnames';
import {MouseEvent, ReactElement, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Search} from 'semantic-ui-react';
import {SearchProps} from 'semantic-ui-react/dist/commonjs/modules/Search/Search';
import css from 'styled-jsx/css';
import {handleXOrLabelsOnAdd} from '../../../Hooks/Label';
import {LabelScope, getLabelsForSelectionSelector} from '../../../Modules/Selectors/LabelSelectors';
import {FlowStyleBuilder, useFlowStyle} from '../../../Styles/StyleContext';
import FlowLabelCategoryRenderer from './AddFlowLabel/FlowLabelCategoryRenderer';
import FlowLabelCategoryResultRenderer from './AddFlowLabel/FlowLabelCategoryResultRenderer';
import FlowLabelResultRenderer from './AddFlowLabel/FlowLabelResultRenderer';

//#region <styles>
/*language=SCSS*/
const getStyle: FlowStyleBuilder = ({COLORS: {LEVITATION}}) => css.resolve`
  .ui.search.labelSelector :global(.results .category .result) {
    padding: 0;
    border: none;
  }

  .ui.search.labelSelector :global(.ui.icon.input) {
    width: 100%;
  }

  .limitedWidth.ui.search.labelSelector :global(.ui.icon.input) {
    width: 200px;
  }

  .ui.search.labelSelector > :global(.results) {
    border-radius: 0;
    border: none;
    box-shadow: 2px 2px 15px ${LEVITATION.LEVEL_2};
    max-height: 200px;
    overflow: auto;
  }

  .ui.search.labelSelector.upwards > :global(.results) {
    bottom: calc(100% + 0.5em);
    top: auto;
  }

  .ui.search.labelSelector:not(.category) > :global(.results.visible) {
    border: none !important;
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.75rem 14px;
    grid-gap: 5px;
  }

  .ui.search.labelSelector:not(.category) > :global(.results .result) {
    border: none;
    padding: 0;
  }

  .ui.search.labelSelector:not(.category) :global(.message.empty) {
    padding: 0 !important;
  }

  .ui.search.labelSelector:not(.category) :global(.message.empty .header) {
    font-weight: normal;
  }
`;

//#endregion

interface AddLabelsParams {
  assignedIds: ReadonlyArray<Uuid>;
  className?: string;
  placeholder?: string;
  projectFolder: Uuid | null;
  labelScopes: LabelScope[];
  limitedWidth?: boolean;
  upwards?: boolean;

  onAdd(labelId: Uuid, toRemove: Uuid[]): void;
}

export default function AddFlowLabels({
  assignedIds,
  className,
  labelScopes,
  limitedWidth,
  placeholder = 'general:labels.addLabelPlacerholder',
  projectFolder,
  onAdd,
  upwards,
}: AddLabelsParams): ReactElement {
  const {t} = useTranslation();
  const {className: styleClassName, styles} = useFlowStyle(getStyle);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const labels = useSelector(getLabelsForSelectionSelector)(labelScopes, projectFolder);

  const noMoreLabels = useMemo(() => labels.length === assignedIds.length, [assignedIds.length, labels.length]);

  const hasCategories = useMemo(() => {
    const folders = labels.map(({rootFolder}) => rootFolder);
    return new Set(folders).size > 1;
  }, [labels]);

  const results = useMemo(() => {
    const assigned = new Set(assignedIds);
    const filteredLabels = labels
      .filter(({id, name}) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && !assigned.has(id))
      .reduce<Record<string, {id: string; name: string; results: {id: string; title: string; value: Uuid}[]}>>(
        (acc, label) => {
          if (!acc[label.rootFolder]) {
            acc[label.rootFolder] = {
              id: label.rootFolder,
              name: label.rootFolder,
              results: [],
            };
          }
          acc[label.rootFolder].results.push({
            id: label.id,
            title: label.name,
            value: label.id,
          });
          return acc;
        },
        {},
      );

    for (const projectFolder of Object.values(filteredLabels)) {
      projectFolder.results.sort(({title: a}, {title: b}) => a.localeCompare(b));
    }

    if (!hasCategories) {
      return Object.values(filteredLabels)[0]?.results || [];
    }

    return filteredLabels;
  }, [assignedIds, hasCategories, labels, search]);

  const onFocus = useCallback((_event: MouseEvent<HTMLElement>, {value}: SearchProps) => {
    setOpen(true);
    setSearch(value || '');
  }, []);
  const onBlur = useCallback(() => setOpen(false), []);

  return (
    <>
      <Search
        category={hasCategories}
        onFocus={onFocus}
        onBlur={onBlur}
        open={open}
        loading={false}
        onResultSelect={(_e, {result}) => {
          onAdd(result.value, handleXOrLabelsOnAdd(labels, result.value));
          setSearch('');
        }}
        onSearchChange={(_e, {value}) => setSearch(value || '')}
        results={results}
        categoryLayoutRenderer={FlowLabelCategoryResultRenderer}
        categoryRenderer={FlowLabelCategoryRenderer}
        resultRenderer={FlowLabelResultRenderer}
        className={classNames(className, styleClassName, 'labelSelector', {limitedWidth, upwards})}
        value={search}
        placeholder={t(placeholder) || undefined}
        fluid={!limitedWidth}
        noResultsMessage={t(
          search.length > 0 && !noMoreLabels ? 'general:labels.searchNotMatchingLabels' : 'general:labels.noMoreLabels',
        )}
        showNoResults
      />
      {styles}
    </>
  );
}
