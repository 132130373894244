import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {SearchHighlightText} from '../../../../../Search/SearchHighlight';
import EditName, {EditNameProps} from '../../../EditName';

export default function EditTaskName(props: Omit<EditNameProps, 'textAreaPlaceholder' | 'viewNode'>): ReactElement {
  const {t} = useTranslation();
  const name = props.name;
  return (
    <EditName
      {...props}
      fullWidth
      nameInputTopSpacing={2}
      textAreaPlaceholder={'taskList:name.placeholder'}
      viewNode={
        <>
          {name && <SearchHighlightText text={name} />}
          {!name && <span>{t('taskList:name.placeholder')}</span>}
        </>
      }
    />
  );
}
