import {createContext, PropsWithChildren, ReactElement, ReactNode, useContext} from 'react';
import {createPortal} from 'react-dom';
import Drawer from '../Feedback/Drawer/Drawer';

const context = createContext<() => void>(() => undefined);

export function useCloseFullScreenDrawer(): () => void {
  return useContext(context);
}

interface Props {
  aboveDialog?: boolean;
  trigger?: ReactNode;
  close(): void;
  visible: boolean;
}

export default function FullScreenDrawer({
  aboveDialog = false,
  children,
  trigger,
  close,
  visible,
}: PropsWithChildren<Props>): ReactElement {
  return (
    <>
      {trigger}
      {createPortal(
        <Drawer
          aboveDialog={aboveDialog}
          visible={visible}
          fullScreen
          onClose={close}
          animationKey={'fullScreenDrawer'}
          emptyInspector
        >
          <div className={'flex h-screen w-screen flex-col items-stretch'}>
            <context.Provider value={close}>{visible && children}</context.Provider>
          </div>
        </Drawer>,
        document.querySelector('body')!,
      )}
    </>
  );
}
