import Konva from 'konva';
import {PointList} from './LineCalculation';

interface LineCurvedConfig extends Konva.ShapeConfig {
  radius?: number;
  points?: PointList;
}

export class CurvedLine extends Konva.Shape<LineCurvedConfig> {
  #radius: number;
  #points: PointList;

  constructor(config: LineCurvedConfig) {
    super(config);
    this.#radius = config.radius || 0;
    this.#points = config.points || [];
  }

  points(points?: PointList): PointList {
    if (points) {
      this.#points = points;
    }
    return this.#points;
  }

  _sceneFunc(context: Konva.Context): void {
    const points = this.points();

    if (!points.length) {
      return;
    }

    context.beginPath();
    const firstPoint = points[0];
    context.moveTo(firstPoint.x, firstPoint.y);

    for (let n = 1; n < points.length - 1; n += 1) {
      const currentPoint = points[n];
      const nextPoint = points[n + 1];
      if (currentPoint.x !== nextPoint.x) {
        const radius = Math.min(this.#radius, Math.abs(currentPoint.x - nextPoint.x));
        const x = currentPoint.x < nextPoint.x ? currentPoint.x + radius : currentPoint.x - radius;
        context.arcTo(currentPoint.x, currentPoint.y, x, nextPoint.y, radius);
      } else if (currentPoint.y !== nextPoint.y) {
        const radius = Math.min(this.#radius, Math.abs(currentPoint.y - nextPoint.y));
        const y = currentPoint.y < nextPoint.y ? currentPoint.y + radius : currentPoint.y - radius;
        context.arcTo(currentPoint.x, currentPoint.y, nextPoint.x, y, radius);
      }
    }
    const lastPoint = points[points.length - 1];
    context.lineTo(lastPoint.x, lastPoint.y);
    context.strokeShape(this);
  }
}
