import {FlowState} from '@octaved/flow/src/Modules/State';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  INVALIDATED,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {getUserGroups} from '../../config/routes';
import {UserGroupsGroups} from '../EntityInterfaces/UserGroups';
import {OrgUserGroupsBulkPatched, UserGroupMembersBulkPatched} from '../Event/Events';
import {userGroupsStatesSelector} from '../Selectors/UserGroupSelectors';

const LOAD_USER_GROUPS_REQUESTS = 'LOAD_USER_GROUPS_REQUESTS';
const LOAD_USER_GROUPS_FAILURE = 'LOAD_USER_GROUPS_FAILURE';
const LOAD_USER_GROUPS_SUCCESS = 'LOAD_USER_GROUPS_SUCCESS';
const INVALIDATE_USER_GROUPS = 'INVALIDATE_USER_GROUPS';
const INVALIDATE_USER_GROUPS_ALL = 'INVALIDATE_USER_GROUPS_ALL';

interface LoadResponse {
  response: UserGroupsGroups;
}

const reducerMap = new Map();
reducerMap.set(
  LOAD_USER_GROUPS_SUCCESS,
  (state: UserGroupsGroups, {response}: LoadResponse): UserGroupsGroups => mergeStates(state, response),
);

const stateReducers = createReducerCollection<EntityStates>({});
stateReducers.add(LOAD_USER_GROUPS_REQUESTS, createTimestampReducer('options.urlParams.userIds', LOADING));
stateReducers.add(LOAD_USER_GROUPS_SUCCESS, createTimestampReducer('options.urlParams.userIds', LOADED));
stateReducers.add(INVALIDATE_USER_GROUPS, createTimestampReducer('userIds', INVALIDATED));
stateReducers.add(INVALIDATE_USER_GROUPS_ALL, () => ({}));
stateReducers.add<OrgUserGroupsBulkPatched>(
  'OrgUserGroupsBulkPatched',
  createTimestampReducer('orgUserId', INVALIDATED),
);
stateReducers.add<UserGroupMembersBulkPatched>('UserGroupMembersBulkPatched', () => ({}));

export const userGroupsReducers = {
  groups: ReduceFromMap(reducerMap),
  states: stateReducers.reducer,
};

export function loadUserGroups(userIds: Uuid[]): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = userGroupsStatesSelector(getState());
    const toLoad = state ? filterIdsToReload(state, userIds) : userIds;
    if (toLoad.length) {
      dispatch({
        [CALL_API]: {
          endpoint: getUserGroups,
          options: {urlParams: {userIds: toLoad}},
          types: {
            failureType: LOAD_USER_GROUPS_FAILURE,
            requestType: LOAD_USER_GROUPS_REQUESTS,
            successType: LOAD_USER_GROUPS_SUCCESS,
          },
        },
      });
    }
  };
}
