import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {DateStr} from '@octaved/typescript';
import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import {noop} from 'lodash';
import {ComponentType, ReactElement, useMemo} from 'react';
import GroupBar from '../../../../Components/Calendar/CalendarContainer/Content/Bar/GroupBar';
import MaterialResourceBar from '../../../../Components/Calendar/CalendarContainer/Content/Bar/MaterialResourceBar';
import {PlanningBarProps} from '../../../../Components/Calendar/CalendarContainer/Content/Bar/PlanningBarProps';
import SubWorkPackageBar from '../../../../Components/Calendar/CalendarContainer/Content/Bar/SubWorkPackageBar';
import TaskBar from '../../../../Components/Calendar/CalendarContainer/Content/Bar/TaskBar';
import WorkPackageBar from '../../../../Components/Calendar/CalendarContainer/Content/Bar/WorkPackageBar';
import {PlanningDate} from '../../../../EntityInterfaces/PlanningDates';
import {BaseTreeData} from '../BaseTreeData';

interface PlaceholderBarProps {
  data: BaseTreeData;
  plannedStart: DateStr;
}

export default function PlaceholderBar({data, plannedStart}: PlaceholderBarProps): ReactElement {
  const node = data.data;
  const nodeType = 'nodeType' in node && node.nodeType;

  const BarComponent = useMemo<ComponentType<PlanningBarProps>>(() => {
    if (nodeType === EnumFlowNodeType.VALUE_TASK) {
      return TaskBar;
    }
    if (nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
      return WorkPackageBar;
    }
    if (nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
      return SubWorkPackageBar;
    }
    if (nodeType === EnumFlowNodeType.VALUE_GROUP) {
      return GroupBar;
    }
    if (nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
      return MaterialResourceBar;
    }
    throw new Error(`Row type not supported: [${JSON.stringify(nodeType)}]`);
  }, [nodeType]);

  const planningDate = useMemo<PlanningDate>(
    () => ({
      assignedNodeId: null,
      id: generateUuid(),
      name: 'group',
      nodeId: data.id,
      plannedEnd: isoToIsoDateTimeFormat(plannedStart),
      plannedStart: isoToIsoDateTimeFormat(plannedStart),
    }),
    [data.id, plannedStart],
  );

  return (
    <BarComponent
      endCanBeResized={false}
      startCanBeResized={false}
      canBeDragged
      onClick={noop}
      showText={false}
      id={data.id}
      text={''}
      readonly={false}
      isExpanded
      isCreating
      planningDate={planningDate}
    />
  );
}
