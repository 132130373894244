import {useProjectPatch} from '@octaved/flow/src/Modules/Projects';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import NodeBillingsSection from '../../../Components/Fields/Advanced/Section/NodeBillingsSection';
import NodeBudgetScopeSection from '../../../Components/Fields/Advanced/Section/NodeBudgetScopeSection';
import NodeIdSection from '../../../Components/Fields/Advanced/Section/NodeIdSection';
import NodeKeyValueStoreSection from '../../../Components/Fields/Advanced/Section/NodeKeyValueStoreSection';
import NodePidSection from '../../../Components/Fields/Advanced/Section/NodePidSection';
import NodeTimeScopeSection from '../../../Components/Fields/Advanced/Section/NodeTimeScopeSection';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import InspectorViewFrame from '../../InspectorViewFrame';
import {ProjectDetailsProps} from './General';

export default function Advanced({node}: ProjectDetailsProps): ReactElement {
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const patchProject = useProjectPatch(node.id);
  const optionals = useOptionalFields(node.id, {
    priceLimit: node.maxPrice !== null,
    technical: false,
    timeLimit: node.maxEffort !== null,
  });
  const canReadEffort = useIsGranted('FLOW_NODE_PROJECT_READ_EFFORT', node.id);
  const canReadPrices = useIsGranted('FLOW_NODE_READ_PRICES', node.id);
  const canReadKVStore = useIsGranted('FLOW_NODE_READ_KEY_VALUE_STORE', node.id);

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      <EnableFieldButton field={optionals.technical} label={'nodeDetails:field.synthetic.technical.enable'} />
      {canReadEffort && (
        <EnableFieldButton field={optionals.timeLimit} label={'nodeDetails:field.timeLimit.enable.project'} />
      )}
      {canReadPrices && (
        <EnableFieldButton field={optionals.priceLimit} label={'nodeDetails:field.budget.enable.project'} />
      )}
    </div>
  );

  return (
    <InspectorViewFrame footer={canManageBasic ? footer : undefined}>
      {canReadPrices && <NodeBillingsSection node={node} />}
      <NodePidSection pid={node.pid} />
      {optionals.timeLimit.show && canReadEffort && (
        <NodeTimeScopeSection node={node} patch={patchProject} readonly={!canManageBasic} />
      )}
      {optionals.priceLimit.show && canReadPrices && (
        <NodeBudgetScopeSection node={node} patch={patchProject} readonly={!canManageBasic} />
      )}

      {optionals.technical.show && canManageBasic && (
        <>
          <NodeIdSection nodeId={node.id} />
          {canReadKVStore && <NodeKeyValueStoreSection keyValueStore={node.keyValueStore} />}
        </>
      )}
    </InspectorViewFrame>
  );
}
