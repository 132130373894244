import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {HelpTooltip, Label} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import {GroupPatchData, Pid} from '../../EntityInterfaces/Pid';
import {isGroup} from '../../Node/NodeIdentifiers';

interface GroupTypeProps {
  group: GroupPatchData;
  patch: (data: Partial<GroupPatchData>) => void;
  parent: Pid;
}

export default function GroupType({group, patch, parent}: GroupTypeProps): ReactElement | null {
  const {t} = useTranslation();
  const options = useMemo<DropdownItemProps[]>(() => {
    return [
      {
        text: t('pages:projects.inspector.manage.general.groupTypes.group'),
        value: EnumFlowGroupType.VALUE_GROUP,
      },
      {
        text: t('pages:projects.inspector.manage.general.groupTypes.sprint'),
        value: EnumFlowGroupType.VALUE_SPRINT,
      },
      {
        text: t('pages:projects.inspector.manage.general.groupTypes.backlog'),
        value: EnumFlowGroupType.VALUE_BACKLOG,
      },
    ];
  }, [t]);

  if (isGroup(parent)) {
    //this setting is only available on first level
    return null;
  }

  return (
    <div>
      <Spacer />
      <Label
        token={'pages:projects.inspector.manage.general.groupTypes.groupType'}
        addition={<HelpTooltip toolTipTranslation={'pages:projects.inspector.manage.general.groupTypes.tooltip'} />}
      />
      <Dropdown
        selection
        upward
        wrapSelection
        options={options}
        value={group.groupType}
        onChange={(_e, {value}) => {
          if (value) {
            patch({groupType: value as EnumFlowGroupType});
          }
        }}
      />
    </div>
  );
}
