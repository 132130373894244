import {AccessibleItem, isItemAccessibleSelector} from '@octaved/flow/src/AccessibleItem';
import {cn, TWSearchDropdownFilter, TWSearchDropdownFilterProps} from '@octaved/ui';
import {ReactElement, ReactNode, useMemo} from 'react';
import {useSelector} from 'react-redux';

export interface BaseDropdownItem<V extends string, T extends ReactNode = ReactNode> extends AccessibleItem {
  text: T; //will be translated if string
  value: V;
}

interface OwnProps {
  label: string;
  searchable?: boolean;
  isActive?: boolean;
  onReset?: () => void;
}

export type PublicBaseDropdownProps<V extends string> = OwnProps &
  Omit<
    TWSearchDropdownFilterProps<BaseDropdownItem<V>>,
    | 'clearSelection'
    | 'isActiveFN'
    | 'itemRenderer'
    | 'searchFilterFN'
    | 'selectionCount'
    | 'toggleSelection'
    | 'translations'
  >;

type InternalBaseDropdownProps<V extends string> = OwnProps &
  Omit<TWSearchDropdownFilterProps<BaseDropdownItem<V>>, 'searchFilterFN' | 'translations'>;

export default function BaseDropdownDropdown<V extends string>({
  className,
  items,
  label,
  searchable,
  ...props
}: InternalBaseDropdownProps<V>): ReactElement {
  const isItemAccessible = useSelector(isItemAccessibleSelector);
  const accessibleItems = useMemo(() => items.filter(isItemAccessible), [isItemAccessible, items]);
  return (
    <TWSearchDropdownFilter<BaseDropdownItem<V>>
      {...props}
      className={cn('!z-[2050]', className)}
      items={accessibleItems}
      searchFilterFN={
        searchable
          ? (item, search) => typeof item.text === 'string' && item.text.toLowerCase().includes(search.toLowerCase())
          : undefined
      }
      translations={{
        actionTriggerEmptyLabel: 'general:all',
        actionTriggerLabel: label,
        actionTriggerSelectedLabel: 'general:counts.nSelected',
        clearSelectionLinkLabel: 'general:showAll',
        noItemsFoundPlaceholder: 'general:noEntriesFound',
        notItemsAvailablePlaceholder: 'general:noEntriesAvailable',
        searchLabel: 'general:search',
      }}
      triggerClass={'w-fit'}
    />
  );
}
