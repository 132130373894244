import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';

export interface AdvancedControlTrackedTime {
  showProjectsWithoutTrackedTime: boolean;
  showWorkPackagesWithoutTrackedTime: boolean;
}

export const advancedControlTrackedTimeDefault: AdvancedControlTrackedTime = {
  showProjectsWithoutTrackedTime: true,
  showWorkPackagesWithoutTrackedTime: true,
};

export interface QuickControlTrackedTime {
  timePeriod: 'all' | 'last7days' | 'last30days' | 'currentMonth' | 'lastMonth' | 'last12Months' | 'custom';
  timePeriodCustomFrom: string; //iso date
  timePeriodCustomInterval: 'days' | 'weeks' | 'months' | 'quarters' | 'years';
  timePeriodCustomTo: string; //iso date
  units: SlimUnit[];
}

export const quickControlTrackedTimeDefault: QuickControlTrackedTime = {
  timePeriod: 'all',
  timePeriodCustomFrom: '',
  timePeriodCustomInterval: 'days',
  timePeriodCustomTo: '',
  units: [],
};
