import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {
  calculateMinMaxPlanningDatesInSubtreeSelector,
  getMinMaxPlanningDatesSelector,
} from '../../../../Selectors/PlanningDateSelectors';
import {createStoreSubscription} from '../../../StoreSubscription';
import {SimpleTextCell} from './SimpleTextCell';

abstract class PlanningCell extends SimpleTextCell {
  protected nodeEntity: NodeEntity | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (state) => getMinMaxPlanningDatesSelector(state)(this.nodeEntity?.id),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (state) => {
          if (!this.nodeEntity) {
            return null;
          }
          return calculateMinMaxPlanningDatesInSubtreeSelector(state)(this.nodeEntity.id);
        },
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
    );
  }
}

export class PlannedStartCell extends PlanningCell {
  protected getText(): string {
    if (this.nodeEntity) {
      let minMax;
      if (isTask(this.nodeEntity) || isWorkPackage(this.nodeEntity)) {
        minMax = getMinMaxPlanningDatesSelector(this.ctx.store.getState())(this.nodeEntity.id);
      } else {
        minMax = calculateMinMaxPlanningDatesInSubtreeSelector(this.ctx.store.getState())(this.nodeEntity.id);
      }

      if (minMax && minMax.plannedStart) {
        return fromIsoDateTimeFormat(minMax.plannedStart).format('dd. DD.MM.YYYY');
      }
    }
    return '';
  }
}

export class PlannedEndCell extends PlanningCell {
  protected getText(): string {
    if (this.nodeEntity) {
      let minMax;
      if (isTask(this.nodeEntity) || isWorkPackage(this.nodeEntity)) {
        minMax = getMinMaxPlanningDatesSelector(this.ctx.store.getState())(this.nodeEntity.id);
      } else {
        minMax = calculateMinMaxPlanningDatesInSubtreeSelector(this.ctx.store.getState())(this.nodeEntity.id);
      }

      if (minMax && minMax.plannedEnd) {
        return fromIsoDateTimeFormat(minMax.plannedEnd).format('dd. DD.MM.YYYY');
      }
    }
    return '';
  }
}
