import AddDependencyModal from '@octaved/planning/src/Components/AddDependencyModal/AddDependencyModal';
import {PlanningRealizationNode} from '@octaved/planning/src/EntityInterfaces/PlanningRealizationNode';
import {addLogicalDependency} from '@octaved/planning/src/Modules/LogicalDependencies';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

interface AddLogicalDependencyProps {
  node: PlanningRealizationNode;
}

export default function AddLogicalDependency({node}: AddLogicalDependencyProps): ReactElement {
  const dispatch = useDispatch();
  const onSelect = useCallback(
    (predecessor: Uuid | null) => {
      if (predecessor) {
        dispatch(addLogicalDependency(node.id, predecessor));
        return true;
      }
      return false;
    },
    [node.id, dispatch],
  );

  const excludedNodeIds = useMemo<Set<Uuid>>(
    () => new Set([node.id, ...node.planningLogicalPredecessors]),
    [node.id, node.planningLogicalPredecessors],
  );

  return (
    <AddDependencyModal
      dialogHeaderToken={'pages:projects.inspector.manage.planning.newLogicalDependency'}
      dialogInfo={'pages:projects.inspector.manage.planning.newDependencyLogicalInfo'}
      excludedNodeIds={excludedNodeIds}
      buttonToken={'pages:projects.inspector.manage.planning.newDependency'}
      node={node}
      onSelect={onSelect}
      dependencyType={'logical'}
      plannedOnly={false}
    />
  );
}
