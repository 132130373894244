import {Uuid} from '@octaved/typescript/src/lib';
import RadioInput from '@octaved/ui-components/src/React/Formik/RadioInput';
import TextInput from '@octaved/ui-components/src/React/Formik/TextInput';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {FormikProps} from 'formik';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Dropdown} from 'semantic-ui-react';
import FirstLastDateSelection from './FirstLastDateSelection';
import {RecurringPlanningData} from './RecurringPlanningContent';

interface MonthlyConfigProps {
  disabled: boolean;
  formik: FormikProps<RecurringPlanningData>;
  nodeId: Uuid;
}

export default function MonthlyConfig({formik, disabled, nodeId}: MonthlyConfigProps): ReactElement {
  const {t} = useTranslation();
  return (
    <div>
      <RadioInput
        label={t('pages:projects.inspector.manage.planning.recurringPlanning.monthly')}
        name={'type'}
        value={'monthly'}
        formik={formik}
      />
      <SlideToggleElement visible={formik.values.type === 'monthly'}>
        <div className={'flex flex-col gap-y-4 py-2 pl-4 pr-7'}>
          <div className={'flex items-center gap-x-3'}>
            <Dropdown
              options={[
                {text: t('pages:projects.inspector.manage.planning.recurringPlanning.dropdownFirst'), value: 'first'},
                {text: t('pages:projects.inspector.manage.planning.recurringPlanning.dropdownLast'), value: 'last'},
              ]}
              selection
              compact
              value={formik.values.useFirstOfMonth ? 'first' : 'last'}
              onChange={(_e, {value}) => {
                formik.setFieldValue('useFirstOfMonth', value === 'first');
              }}
              style={{minWidth: '100px'}}
              disabled={disabled}
            />
            <Dropdown
              options={[
                {text: t('generalCore:culture.weekDays.monday'), value: 'monday'},
                {text: t('generalCore:culture.weekDays.tuesday'), value: 'tuesday'},
                {text: t('generalCore:culture.weekDays.wednesday'), value: 'wednesday'},
                {text: t('generalCore:culture.weekDays.thursday'), value: 'thursday'},
                {text: t('generalCore:culture.weekDays.friday'), value: 'friday'},
              ]}
              compact
              selection
              value={formik.values.weekday}
              style={{minWidth: '120px'}}
              onChange={(_e, {value}) => {
                formik.setFieldValue('weekday', value);
              }}
              disabled={disabled}
            />
            <Trans i18nKey={'pages:projects.inspector.manage.planning.recurringPlanning.durationLabel'}>
              <TextInput formik={formik} id={'duration'} disabled={disabled} width={'70px'} />
            </Trans>
          </div>

          <FirstLastDateSelection formik={formik} disabled={disabled} nodeId={nodeId} />
        </div>
      </SlideToggleElement>
    </div>
  );
}
