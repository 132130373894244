import {GetWorkingTimeAtDate} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {PlanningDatesList} from '../EntityInterfaces/PlanningDates';
import {maxDate, minDate} from './DateCalculations';
import {addWorkdays, workdaysBetween} from './WorkdayCalculations';

export enum ResizeStartpoint {
  left,
  right,
}

export interface BarResult {
  plannedStart: DateStr;
  plannedEnd: DateStr;
}

enum Direction {
  left = -1,
  right = 1,
}

export function moveBar(
  startX: number,
  currentX: number,
  plannedStart: DateStr,
  plannedEnd: DateStr,
  cellWidth: number,
  limitStart: DateTimeStr | null,
  limitEnd: DateTimeStr | null,
  getWorkingTimeAtDate: GetWorkingTimeAtDate,
): BarResult {
  const mouseMovement = currentX - startX;
  const start = dayjs(plannedStart);
  const end = dayjs(plannedEnd);
  const duration = workdaysBetween(start, end, getWorkingTimeAtDate);
  if (mouseMovement > 0) {
    const movedFields = calculateMovedFields(mouseMovement, cellWidth, end, getWorkingTimeAtDate);
    return moveRight(end, movedFields, duration, fromIsoDateTimeFormat(limitEnd), getWorkingTimeAtDate);
  }
  const movedFields = calculateMovedFields(mouseMovement, cellWidth, start, getWorkingTimeAtDate);
  return moveLeft(start, movedFields, duration, fromIsoDateTimeFormat(limitStart), getWorkingTimeAtDate);
}

function moveLeft(
  start: dayjs.Dayjs,
  movedFields: number,
  duration: number,
  limitStart: dayjs.Dayjs | null,
  getWorkHoursAtDate: GetWorkingTimeAtDate,
): BarResult {
  const newStart = limitedStartDate(start.clone().add(movedFields, 'd'), limitStart, getWorkHoursAtDate);
  const newEnd = addWorkdays(newStart.clone(), duration, getWorkHoursAtDate);
  return {
    plannedEnd: toIsoFormat(newEnd),
    plannedStart: toIsoFormat(newStart),
  };
}

function moveRight(
  end: dayjs.Dayjs,
  movedFields: number,
  duration: number,
  limitEnd: dayjs.Dayjs | null,
  getWorkHoursAtDate: GetWorkingTimeAtDate,
): BarResult {
  const newEnd = limitedEndDate(end.clone().add(movedFields, 'd'), limitEnd, getWorkHoursAtDate);
  const newStart = addWorkdays(newEnd.clone(), -duration, getWorkHoursAtDate);
  return {
    plannedEnd: toIsoFormat(newEnd),
    plannedStart: toIsoFormat(newStart),
  };
}

function calculateMovedFields(
  mouseMovement: number,
  cellWidth: number,
  draggedDate: dayjs.Dayjs,
  getWorkingTimeAtDate: GetWorkingTimeAtDate,
): number {
  const moveDirection = mouseMovement > 0 ? Direction.right : Direction.left;
  const positivMovement = mouseMovement * moveDirection;

  const rest = positivMovement % cellWidth;
  let movedFields = (positivMovement - rest) / cellWidth;
  if (rest > cellWidth * 0.3) {
    movedFields += 1;
  }

  const targetDate = addWorkdays(
    draggedDate.clone().add((movedFields - 1) * moveDirection, 'd'),
    1 * moveDirection,
    getWorkingTimeAtDate,
  );
  return targetDate.diff(draggedDate, 'd');
}

export function resizeBar(
  startX: number,
  currentX: number,
  startPoint: ResizeStartpoint,
  plannedStart: DateStr,
  plannedEnd: DateStr,
  cellWidth: number,
  limitStart: DateTimeStr | null,
  limitEnd: DateTimeStr | null,
  getWorkingTimeAtDate: GetWorkingTimeAtDate,
): BarResult {
  const mouseMovement = currentX - startX;
  const start = dayjs(plannedStart);
  const end = dayjs(plannedEnd);

  if (startPoint === ResizeStartpoint.right) {
    const movedFields = calculateMovedFields(mouseMovement, cellWidth, end, getWorkingTimeAtDate);
    const newEnd = end.add(movedFields, 'd');
    return {
      plannedStart,
      plannedEnd: calcNewEndDate(start, newEnd, fromIsoDateTimeFormat(limitEnd), getWorkingTimeAtDate),
    };
  }

  const movedFields = calculateMovedFields(mouseMovement, cellWidth, start, getWorkingTimeAtDate);
  const newStart = start.add(movedFields, 'd');
  return {
    plannedEnd,
    plannedStart: calcNewStartDate(newStart, end, fromIsoDateTimeFormat(limitStart), getWorkingTimeAtDate),
  };
}

function calcNewEndDate(
  limitStart: dayjs.Dayjs,
  end: dayjs.Dayjs,
  limitEnd: dayjs.Dayjs | null,
  getWorkHoursAtDate: GetWorkingTimeAtDate,
): DateStr {
  if (end.isBefore(limitStart, 'd')) {
    return toIsoFormat(limitStart);
  }
  return toIsoFormat(limitedEndDate(end, limitEnd, getWorkHoursAtDate));
}

function calcNewStartDate(
  start: dayjs.Dayjs,
  limitEnd: dayjs.Dayjs,
  limitStart: dayjs.Dayjs | null,
  getWorkHoursAtDate: GetWorkingTimeAtDate,
): DateStr {
  if (start.isAfter(limitEnd, 'd')) {
    return toIsoFormat(limitEnd);
  }
  return toIsoFormat(limitedStartDate(start, limitStart, getWorkHoursAtDate));
}

function limitedStartDate(
  start: dayjs.Dayjs,
  limitStart: dayjs.Dayjs | null,
  getWorkHoursAtDate: GetWorkingTimeAtDate,
): dayjs.Dayjs {
  if (limitStart && (limitStart.isAfter(start, 'd') || limitStart.isSame(start, 'd'))) {
    return addWorkdays(limitStart, 1, getWorkHoursAtDate);
  }
  return start;
}

function limitedEndDate(
  end: dayjs.Dayjs,
  limitEnd: dayjs.Dayjs | null,
  getWorkHoursAtDate: GetWorkingTimeAtDate,
): dayjs.Dayjs {
  if (limitEnd && (limitEnd.isBefore(end, 'd') || limitEnd.isSame(end, 'd'))) {
    return addWorkdays(limitEnd, -1, getWorkHoursAtDate);
  }
  return end;
}

export interface DateLimitsResult {
  start: null | DateTimeStr;
  end: null | DateTimeStr;
}

export function calculateDateLimits(
  planningDateId: Uuid | undefined | null,
  planningDates: PlanningDatesList,
): DateLimitsResult {
  let start: DateTimeStr | null = null;
  let end: DateTimeStr | null = null;

  const planningDate = planningDates.find(({id}) => id === planningDateId);
  if (planningDate) {
    for (const {id, plannedEnd, plannedStart} of planningDates) {
      if (id !== planningDateId) {
        if (planningDate.plannedStart >= plannedEnd) {
          start = maxDate(start, plannedEnd);
        }
        if (planningDate.plannedEnd <= plannedStart) {
          end = minDate(end, plannedStart);
        }
      }
    }
  }

  return {end, start};
}
export function calculateDateLimitsByDate(
  limitPlannedStart: DateTimeStr | null,
  limitPlannedEnd: DateTimeStr | null,
  planningDates: PlanningDatesList,
): DateLimitsResult {
  let start: DateTimeStr | null = null;
  let end: DateTimeStr | null = null;

  for (const {plannedEnd, plannedStart} of planningDates) {
    if (limitPlannedStart && limitPlannedStart >= plannedEnd) {
      start = maxDate(start, plannedEnd);
    }
    if (limitPlannedEnd && limitPlannedEnd <= plannedStart) {
      end = minDate(end, plannedStart);
    }
  }

  return {end, start};
}
