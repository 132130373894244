import {createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {ProjectFilterStates} from '../../../EntityInterfaces/Filter/ProjectFilters';
import {useProjectContext} from '../../../Pages/Projects/ProjectContext';
import {getProjectsFilterStatesSelector} from '../../Selectors/UiPages/ProjectsSelector';

interface ProjectFiltersContext {
  hasChanges: boolean;
  filterStates: Partial<ProjectFilterStates>;
  setFilterStates: (p: Partial<ProjectFilterStates>) => void;
}

const projectFiltersContext = createContext<ProjectFiltersContext>({} as ProjectFiltersContext);

export function useProjectFiltersContext(): ProjectFiltersContext {
  return useContext(projectFiltersContext);
}

export default function ProjectFiltersContext({children}: {children: ReactNode}): ReactElement {
  const {page} = useProjectContext();
  const projectsActiveFilterStates = useSelector(getProjectsFilterStatesSelector)(page);
  const statesRef = useRef(projectsActiveFilterStates);
  statesRef.current = projectsActiveFilterStates;
  const [hasChanges, setChanged] = useState(false);
  const [filterStates, setStates] = useState<Partial<ProjectFilterStates>>(statesRef.current);
  const setFilterStates = useCallback((states: Partial<ProjectFilterStates>) => {
    setChanged(true);
    setStates(states);
  }, []);

  const ctx = useMemo<ProjectFiltersContext>(
    () => ({hasChanges, filterStates, setFilterStates}),
    [hasChanges, filterStates, setFilterStates],
  );
  return <projectFiltersContext.Provider value={ctx}>{children}</projectFiltersContext.Provider>;
}
