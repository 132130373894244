import {error} from '@octaved/env/src/Logger';
import {Children, ComponentType, ReactElement, ReactNode, useMemo} from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Child = ReactElement<any, ComponentType>;

export function useChildPropsFilter<ChildProps>(children: ReactNode, whitelist: string[]): ChildProps {
  return useMemo(() => {
    const list: Child[] = Children.toArray(children) as Child[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return list.reduce<{[x: string]: any}>((childProps, child) => {
      let type = child.type.displayName;

      if (!type) {
        type = child.type.name;
        error(`Missing 'displayName' on '${type}' - the 'type.name' is minified in production build!`);
      }

      if (process.env.NODE_ENV !== 'production' && !whitelist.includes(type)) {
        throw Error(`element <${type}> is not supported`);
      }

      childProps[type] = child.props;

      return childProps;
    }, {});
  }, [whitelist, children]) as ChildProps;
}
