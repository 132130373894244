import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import ErrorBoundary from '@octaved/ui-components/src/React/ErrorBoundary';
import {ReactElement, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {useUrlWatcher} from '../Routing/UrlWatcher';

export default function FlowOutlet(): ReactElement {
  useUrlWatcher();
  return (
    <Suspense fallback={<AppLoader />}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Suspense>
  );
}
