import {DeepPartialObject} from '@octaved/typescript/src/lib';
import {SortDirection} from 'ag-grid-community';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';

export type BoardPostsSortBy = 'lastChange' | 'createdOn' | 'manual';

export interface BoardPostsUiState {
  showDescendantPosts: boolean;
  sortBy: BoardPostsSortBy;
  sortDirection: SortDirection;
  showComments: boolean;
}

export const initialBoardPostsUiState: BoardPostsUiState = {
  showComments: true,
  showDescendantPosts: true,
  sortBy: 'lastChange',
  sortDirection: 'desc',
};

export function patchBoardPostsUiState(
  boardPosts: DeepPartialObject<BoardPostsUiState>,
  force = false,
): ThunkAct<void> {
  return setUiState({boardPosts}, force);
}

export function usePatchBoardPostsUiState(): (patch: DeepPartialObject<BoardPostsUiState>, force?: boolean) => void {
  const dispatch = useDispatch();
  return useCallback((boardPosts, force = false) => dispatch(patchBoardPostsUiState(boardPosts, force)), [dispatch]);
}
