import {useState} from 'react';

class MappedCache {
  map = new Map<unknown, MappedCache>();
  weakmap = new WeakMap<object, MappedCache>();
  value: unknown;
  valueCalculated = false;

  get(args: ReadonlyArray<unknown>): MappedCache {
    return args.reduce<MappedCache>(this.reducer, this);
  }

  store(value: unknown): Map<unknown, MappedCache>;
  store(value: object): WeakMap<object, MappedCache>;
  store(value: unknown): Map<unknown, MappedCache> | WeakMap<object, MappedCache> {
    const t = typeof value;
    const isObject = (t === 'object' || t === 'function') && value !== null;
    return Reflect.get(this, isObject ? 'weakmap' : 'map');
  }

  reducer(cache: MappedCache, value: unknown | object): MappedCache {
    const store = cache.store(value);
    return store.get(value) || store.set(value, new MappedCache()).get(value)!;
  }
}

function getValue<R>(
  cache: MappedCache,
  fn: (...args: ReadonlyArray<unknown>) => R,
  cacheArgs: ReadonlyArray<unknown>,
  ...fnArgs: ReadonlyArray<unknown>
): R {
  const item = cache.get(cacheArgs);

  if (item.valueCalculated) {
    return item.value as R;
  }

  const result = fn(...fnArgs);
  item.value = result;
  item.valueCalculated = true;
  return result;
}

export function mapCache<Params extends ReadonlyArray<unknown>, Result>(
  fn: (...args: Params) => Result,
): (...args: Params) => Result {
  const cache = new MappedCache();
  return (...args) => getValue(cache, fn as (...args: ReadonlyArray<unknown>) => Result, args, ...args);
}

/**
 * Same as `mapCache` but instead of having multiple arguments, this takes one array as argument and caches
 * based on the values of that array.
 */
export function mapCacheArrayArg<T, Result>(fn: (obj: ReadonlyArray<T>) => Result): (obj: ReadonlyArray<T>) => Result {
  const cache = new MappedCache();
  return (obj) => getValue(cache, fn as (...args: ReadonlyArray<unknown>) => Result, obj, obj);
}

/**
 * Same as `mapCache` but instead of having multiple arguments, this takes one object as argument and caches
 * based on the values of that object.
 */
export function mapCacheObjectArg<O extends object, Result>(fn: (obj: O) => Result): (obj: O) => Result {
  const cache = new MappedCache();
  return (obj) => getValue(cache, fn as (...args: ReadonlyArray<unknown>) => Result, Object.values(obj), obj);
}

/**
 * Returns a cache function that will only return a new array if any of its values changed
 */
export function useShallowArrayCache<T>(): (o: ReadonlyArray<T>) => ReadonlyArray<T> {
  return useState(() => mapCacheArrayArg<T, ReadonlyArray<T>>((id) => id))[0];
}

/**
 * Returns a cache function that will only return a new object if any of its values changed
 */
export function useShallowObjectCache<O extends object>(): (o: O) => O {
  return useState(() => mapCacheObjectArg<O, O>((id) => id))[0];
}
