import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useMinMaxPlanningDateInSubtrees} from '@octaved/planning/src/Modules/MinMaxPlanningDateInSubtrees';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {formatDecimal, roundToFactor} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface NodeScopeDurationProps {
  node: Project | Group;
}

export default function NodeScopeDuration({node}: NodeScopeDurationProps): ReactElement {
  const {t} = useTranslation();
  const {hasLoadedOnce, entriesMapped} = useMinMaxPlanningDateInSubtrees(node.id);
  const minMax = entriesMapped.get(node.id);
  if (minMax) {
    const start = fromIsoDateTimeFormat(minMax.plannedStart);
    const end = fromIsoDateTimeFormat(minMax.plannedEnd);
    const diffMonths = roundToFactor(end.diff(start, 'month', true), 0.5);
    let text: string;
    if (diffMonths >= 1) {
      text = t('general:date.months', {
        count: diffMonths,
        countFormatted: formatDecimal(diffMonths),
      });
    } else {
      text = t('nodeDetails:field.nodeScope.duration.lessThanMonth');
    }
    return <div>{text}</div>;
  }
  return hasLoadedOnce ? <div>{t('nodeDetails:field.nodeScope.duration.notPlanned')}</div> : <div />;
}
