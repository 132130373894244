import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {OrgUserEntity} from '@octaved/users/src/EntityInterfaces/UserEntity';
import {useUnitLists} from '@octaved/users/src/Hooks/UnitLists';
import {userEntitiesSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {extendedUnitTypesForUser} from '@octaved/users/src/UnitType';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IUserInspectorContext extends BaseInspectorContext {
  selectedEntity: OrgUserEntity;
  selectedType: 'user';
}

function isUserInspectorContext(context: InspectorContext | null): context is IUserInspectorContext {
  return context?.selectedType === 'user';
}

export function useUserInspectorContext(): IUserInspectorContext {
  const context = useInspectorContext();
  if (!isUserInspectorContext(context)) {
    throw new Error('is not user inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IUserInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector(userEntitiesSelector)[baseParams!.selectedId];

  return useMemo<IUserInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'user',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function UserInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);
  const {isLoaded} = useUnitLists(extendedUnitTypesForUser);

  if (!isLoaded) {
    return <AppLoader />;
  }

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
