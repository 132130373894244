import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {error} from '@octaved/env/src/Logger';
import {bulkPatchNodesResponsibilities} from '@octaved/flow-api/config/routes';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {NodeRight} from '@octaved/security/src/Authorization/Rights';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher, getState} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {NodeType} from '../EntityInterfaces/Nodes';
import {NodeSearchCondition} from '../EntityInterfaces/NodeSearch';
import {
  isResponsibleNode,
  ResponsibleNode,
  ResponsibleNodeType,
  ResponsibleProps,
} from '../EntityInterfaces/ResponsibleNode';
import {
  FLOW_PATCH_NODES_RESPONSIBILITIES_FAILURE,
  FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST,
  FLOW_PATCH_NODES_RESPONSIBILITIES_SUCCESS,
} from './ActionTypes';
import {EventData, NodesResponsibilitiesBulkPatchedEvent, NodesResponsibilitiesBulkPatchEvent} from './Events';
import {getNodeSearchKey, nodeSearchSelector} from './Selectors/NodeSearchSelectors';
import {getNodeSelector, nodeEntitySelector} from './Selectors/NodeSelectors';
import {getDepthSelector, nodeTreeInvertedSelector} from './Selectors/NodeTreeSelectors';
import {
  getAllAffectedUnitIdsForResponsibleNodeSelector,
  getNextAncestorResponsibleNodeSelector,
  getUnitChangeSet,
} from './Selectors/ResponsibleNodeSelectors';
import {FlowState} from './State';

export const readRights: Record<ResponsibleNodeType, NodeRight> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'FLOW_NODE_GROUP_READ_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_PROJECT]: 'FLOW_NODE_PROJECT_READ_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'FLOW_NODE_SUB_WORK_PACKAGE_READ_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_TASK]: 'FLOW_NODE_TASK_READ_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'FLOW_NODE_WORK_PACKAGE_READ_RESPONSIBLE',
};

export const manageRights: Record<ResponsibleNodeType, NodeRight> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'FLOW_NODE_GROUP_MANAGE_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_PROJECT]: 'FLOW_NODE_PROJECT_MANAGE_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_TASK]: 'FLOW_NODE_TASK_MANAGE_RESPONSIBLE',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'FLOW_NODE_WORK_PACKAGE_MANAGE_RESPONSIBLE',
};

export function createResponsibleNode(): ResponsibleProps {
  return {
    definesOwnResponsible: false,
    responsibleGroups: [],
    responsibleUsers: [],
  };
}

export function copyResponsibleNode(source: ResponsibleProps, targetNode: NodeType): ResponsibleProps {
  const sourceResponsibleNode = source.definesOwnResponsible
    ? source
    : getNextAncestorResponsibleNodeSelector(getState())(targetNode.id);
  return {
    definesOwnResponsible: source.definesOwnResponsible,
    responsibleGroups: sourceResponsibleNode?.responsibleGroups.slice(0) || [],
    responsibleUsers: sourceResponsibleNode?.responsibleUsers.slice(0) || [],
  };
}

export const responsibleProps: (keyof ResponsibleProps)[] = [
  'definesOwnResponsible',
  'responsibleGroups',
  'responsibleUsers',
];

//Types currently exporting as responsible node. Bare in mind that task sections are in between there!
export const responsibleNodeTypes = [
  EnumFlowNodeType.VALUE_PROJECT,
  EnumFlowNodeType.VALUE_GROUP,
  EnumFlowNodeType.VALUE_WORK_PACKAGE,
  EnumFlowNodeType.VALUE_TASK,
] as const;

export const responsibleNodeTypesCondition: NodeSearchCondition = {
  or: responsibleNodeTypes.map((type) => ['nodeType', type]),
};

export function getResponsibleArrayKeyForUnitType(unitType: SimpleUnitType): 'responsibleUsers' | 'responsibleGroups' {
  return unitType === SimpleUnitType.group ? 'responsibleGroups' : 'responsibleUsers';
}

export function onResponsibleNodeCreation(node: ResponsibleNode, parentNodeId: Uuid, state: FlowState): void {
  if (!('definesOwnResponsible' in node)) {
    throw new Error('Missing `definesOwnResponsible` in new node');
  }
  if (!node.definesOwnResponsible) {
    const responsibleParent = getNextAncestorResponsibleNodeSelector(state)(parentNodeId, {self: true});
    if (responsibleParent) {
      node.responsibleGroups = responsibleParent.responsibleGroups.slice(0);
      node.responsibleUsers = responsibleParent.responsibleUsers.slice(0);
    }
  }
}

function stringsEqual(a: ReadonlyArray<string>, b: ReadonlyArray<string>): boolean {
  const aSet = new Set(a);
  const bSet = new Set(b);
  return aSet.size === bSet.size && [...aSet].every((i) => bSet.has(i));
}

export type ResponsiblePatches = Record<Uuid, Partial<ResponsibleProps>>;

export function patchNodesResponsibilities(patches: ResponsiblePatches): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const getResponsibleParent = getNextAncestorResponsibleNodeSelector(getState());

    const autoInheritPatches: ResponsiblePatches = Object.fromEntries(
      Object.entries(patches).map(([nodeId, patch]) => {
        let autoInheritPatch = patch;
        if (autoInheritPatch.definesOwnResponsible !== false) {
          const parent = getResponsibleParent(nodeId);
          if (
            parent &&
            stringsEqual(parent.responsibleGroups, patch.responsibleGroups ?? []) &&
            stringsEqual(parent.responsibleUsers, patch.responsibleUsers ?? [])
          ) {
            autoInheritPatch = {definesOwnResponsible: false};
          }
        }
        return [nodeId, autoInheritPatch];
      }),
    );

    const event: EventData<NodesResponsibilitiesBulkPatchEvent> = {patches: autoInheritPatches};
    dispatch({
      ...event,
      [CALL_API]: {
        endpoint: bulkPatchNodesResponsibilities,
        method: 'patch',
        options: {data: autoInheritPatches},
        types: {
          failureType: FLOW_PATCH_NODES_RESPONSIBILITIES_FAILURE,
          requestType: FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST,
          successType: FLOW_PATCH_NODES_RESPONSIBILITIES_SUCCESS,
        },
      },
    });
  };
}

interface Modifier {
  (ids: Uuid[], id: Uuid, active: boolean): Uuid[];
}

function modifyNodeResponsibleUnit(
  modifier: Modifier,
  nodeId: Uuid,
  unit: SlimUnit,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const node = getNodeSelector(getState())(nodeId);
    if (isResponsibleNode(node)) {
      dispatch(
        patchNodesResponsibilities({
          [node.id]: {
            responsibleGroups: modifier(node.responsibleGroups, unit.unitId, unit.unitType === SimpleUnitType.group),
            responsibleUsers: modifier(node.responsibleUsers, unit.unitId, unit.unitType === SimpleUnitType.user),
          },
        }),
      );
    } else {
      error('Not a responsible node');
    }
  };
}

export function addNodeResponsibleUnit(nodeId: Uuid, unit: SlimUnit): ActionDispatcher<void, FlowState> {
  return modifyNodeResponsibleUnit(
    (ids, id, active) => (active && !ids.includes(id) ? [...ids, id] : ids),
    nodeId,
    unit,
  );
}

export function removeNodeResponsibleUnit(nodeId: Uuid, unit: SlimUnit): ActionDispatcher<void, FlowState> {
  return modifyNodeResponsibleUnit(
    (ids, id, active) => (active && ids.includes(id) ? ids.filter((item) => id !== item) : ids),
    nodeId,
    unit,
  );
}

export function toggleSingleNodeResponsibleUnit(nodeId: Uuid, unit: SlimUnit): ActionDispatcher<void, FlowState> {
  return modifyNodeResponsibleUnit((ids, id, active) => (active ? (ids.includes(id) ? [] : [id]) : []), nodeId, unit);
}

export function toggleNodeResponsibleUnit(nodeId: Uuid, unit: SlimUnit): ActionDispatcher<void, FlowState> {
  return modifyNodeResponsibleUnit(
    (ids, id, active) => (active ? (ids.includes(id) ? ids.filter((item) => id !== item) : [...ids, id]) : ids),
    nodeId,
    unit,
  );
}

export function makeNodeInheritResponsible(nodeId: Uuid): ActionDispatcher<void, FlowState> {
  return patchNodesResponsibilities({[nodeId]: {definesOwnResponsible: false}});
}

function reduceNodes(
  state: FlowState,
  patches: Record<Uuid, Partial<ResponsibleProps>>,
): [FlowState, ReadonlySet<Uuid>] {
  let newState = state;
  const nodeIdsWithDepth: Array<{id: Uuid; depth: number}> = [];
  const getDepth = getDepthSelector(state);
  const nodeTreeInverted = nodeTreeInvertedSelector(state);

  const uniq = (ids: Uuid[]): Uuid[] => [...new Set(ids)].sort(); //copy + unique + sort

  const updatedNodeIds = new Set<Uuid>();

  const nodes = nodeEntitySelector(state);
  Object.entries(patches).forEach(([nodeId, partial]) => {
    if (nodes[nodeId]) {
      //If any property is set, the other's must be set, too. If definesOwnResponsible is set to false, then
      // the groups/users are overwritten by the inheritance rebuild afterwards:
      newState = mergeStates(newState, {
        entities: {
          node: {
            [nodeId]: {
              definesOwnResponsible: partial.definesOwnResponsible ?? true,
              responsibleGroups: uniq(partial.responsibleGroups ?? []),
              responsibleUsers: uniq(partial.responsibleUsers ?? []),
            },
          },
        },
      });
      nodeIdsWithDepth.push({id: nodeId, depth: getDepth(nodeId)});
      updatedNodeIds.add(nodeId);
    }
  });

  //Sort the node ids so that the ones closer to the root of the tree come first:
  nodeIdsWithDepth.sort((a, b) => a.depth - b.depth);

  nodeIdsWithDepth.forEach(({id}) => {
    const node = nodeEntitySelector(newState)[id];
    if (isResponsibleNode(node)) {
      //If the node doesn't define own responsibles, replace them with parent ones. The parent should be loaded
      // because we always load all parents for a node when loading. If there is no more parent which is also
      // a ResponsibleNode, then the arrays are rightfully set to empty arrays:
      if (!node.definesOwnResponsible) {
        const parent = getNextAncestorResponsibleNodeSelector(newState)(id);
        newState = mergeStates(newState, {
          entities: {
            node: {
              [id]: {
                responsibleGroups: uniq(parent?.responsibleGroups ?? []),
                responsibleUsers: uniq(parent?.responsibleUsers ?? []),
              },
            },
          },
        });
        updatedNodeIds.add(id);
      }

      //Now iterate the children and recurse, stepping over any level which is not a ResponsibleNode type
      // (e.g. TaskSections may make a hole in the inheritance).
      //If a child is not loaded, we don't need to go further because that means that none of their descendants
      // is loaded, either, because we always load all ancestors when loading nodes.
      //If we hit a child that defines own responsible, we don't need to recurse into its children, either, because
      // from that node on, the inheritance is set. If that child was changed, too, it will be in a next iteration
      // of `nodeIdsWithDepth`.
      const recurseChildren = (parentNodeId: Uuid, parentResponsibleNodeId: Uuid): void => {
        (nodeTreeInverted.get(parentNodeId) || []).forEach((childId) => {
          const child = nodeEntitySelector(newState)[childId];
          if (child) {
            if (isResponsibleNode(child)) {
              if (!child.definesOwnResponsible) {
                //Take arrays from parent:
                const parent = nodeEntitySelector(newState)[parentResponsibleNodeId] as ResponsibleProps;
                newState = mergeStates(newState, {
                  entities: {
                    node: {
                      [childId]: {
                        responsibleGroups: uniq(parent?.responsibleGroups ?? []),
                        responsibleUsers: uniq(parent?.responsibleUsers ?? []),
                      },
                    },
                  },
                });
                updatedNodeIds.add(childId);
                //And recurse:
                recurseChildren(childId, childId);
              } //else do NOT recurse - inheritance is set from here on
            } else {
              //recurse (might be a non-ResponsibleNode-type node like task section)
              recurseChildren(childId, parentResponsibleNodeId);
            }
          }
        });
      };

      recurseChildren(id, id);
    }
  });

  return [newState, updatedNodeIds];
}

function addToSearch(results: UuidSearchResults, key: string, nodeId: Uuid): UuidSearchResults {
  const set = new Set(results[key] || []);
  if (!set.has(nodeId)) {
    set.add(nodeId);
    return {...results, [key]: [...set].sort()};
  }
  return results;
}

function rmFromSearch(results: UuidSearchResults, key: string, nodeId: Uuid): UuidSearchResults {
  const set = new Set(results[key] || []);
  if (set.has(nodeId)) {
    set.delete(nodeId);
    return {...results, [key]: [...set].sort()};
  }
  return results;
}

function reduceNodeSearchs(oldState: FlowState, newState: FlowState, updatedNodeIds: ReadonlySet<Uuid>): FlowState {
  const getAllAffectedUnitIdsForResponsibleNode = getAllAffectedUnitIdsForResponsibleNodeSelector(newState);
  let newSearchResults: UuidSearchResults = nodeSearchSelector(newState);

  updatedNodeIds.forEach((nodeId) => {
    const oldNode = nodeEntitySelector(oldState)[nodeId];
    const newNode = nodeEntitySelector(newState)[nodeId];
    if (isResponsibleNode(oldNode) && isResponsibleNode(newNode)) {
      const [added, removed] = getUnitChangeSet(
        getAllAffectedUnitIdsForResponsibleNode(oldNode),
        getAllAffectedUnitIdsForResponsibleNode(newNode),
      );

      added.forEach((unitId) => {
        const key = getNodeSearchKey('responsibleUnitId', unitId);
        newSearchResults = addToSearch(newSearchResults, key, nodeId);
      });

      removed.forEach((unitId) => {
        const key = getNodeSearchKey('responsibleUnitId', unitId);
        newSearchResults = rmFromSearch(newSearchResults, key, nodeId);
      });

      {
        const key = getNodeSearchKey('responsibleByAny');
        if (newNode.responsibleGroups.length || newNode.responsibleUsers.length) {
          newSearchResults = addToSearch(newSearchResults, key, nodeId);
        } else {
          newSearchResults = rmFromSearch(newSearchResults, key, nodeId);
        }
      }
    }
  });

  return mergeStates(newState, {nodeSearch: {searchResults: newSearchResults}});
}

const reducers = createReducerCollection<FlowState>({} as FlowState);

reducers.add<NodesResponsibilitiesBulkPatchEvent | NodesResponsibilitiesBulkPatchedEvent>(
  [FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST, 'flow.NodesResponsibilitiesBulkPatchedEvent'],
  (state, {patches}) => {
    const [newState, updatedNodeIds] = reduceNodes(state, patches);
    return reduceNodeSearchs(state, newState, updatedNodeIds);
  },
);

export const responsibleNodeBulkPatchRootReducer = reducers.reducer;
