import Ellipsis from '@fortawesome/fontawesome-pro/svgs/solid/ellipsis-h.svg';
import classnames from 'classnames';
import * as React from 'react';
import {ComponentType, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useState} from 'react';
import {List, Transition} from 'semantic-ui-react';
import styleContext from './StyleContext';
import {AllowedTreeIdTypes, TreeNodeData} from './Tree';
import context from './TreeContext';

interface NodeDataProps<idType extends AllowedTreeIdTypes> {
  nodeData: TreeNodeData<idType>;
}

export default function TreeNodeContent<idType extends AllowedTreeIdTypes>({
  nodeData,
}: NodeDataProps<idType>): React.ReactElement {
  const node: TreeNodeData<idType> = nodeData;

  const {
    selectedNode,
    selectNode,
    expandable,
    icons,
    openTopLevel,
    openNodes,
    nodeLimitBeforeExpansion,
    setOpenNodes,
    isSelectableNode = () => true,
    contentComponent: ContentComponent,
    menuComponent: MenuComponent,
  } = useContext(context);
  const open = openNodes.includes(node.id);
  const {cssPrefix} = useContext(styleContext);
  const [showAll, setShowAll] = useState(false);
  const [children, setChildren] = useState(() => {
    return node.children.slice(0, nodeLimitBeforeExpansion);
  });

  useEffect(() => {
    const children = showAll ? node.children : node.children.slice(0, nodeLimitBeforeExpansion);
    setChildren(children);
  }, [node.children, nodeLimitBeforeExpansion, showAll]);

  const openNode = useCallback((): void => {
    setOpenNodes((nodes) => {
      if (nodes.includes(node.id)) {
        return nodes;
      }
      return [...nodes, node.id];
    });
  }, [node.id, setOpenNodes]);

  const closeNode = useCallback((): void => {
    setOpenNodes((nodes) => {
      if (!nodes.includes(node.id)) {
        return nodes;
      }
      return nodes.filter((id) => id !== node.id);
    });
  }, [node.id, setOpenNodes]);

  useLayoutEffect(() => {
    if (openTopLevel && !node.parentNode) {
      openNode();
    }
  }, [openTopLevel, node.parentNode, openNode]);
  useLayoutEffect(() => {
    if (selectedNode !== null && node.allDescendantIds.includes(selectedNode as idType)) {
      openNode();
    }
  }, [node.allDescendantIds, openNode, selectedNode]);
  const Icon = node.icon || icons.node;
  const icon: ReactNode = typeof Icon === 'function' ? <Icon /> : Icon;
  const selectable = isSelectableNode(node);

  return (
    <>
      <div className={`${cssPrefix} flexItemInner`} data-selenium-id={`tree-node-id-${node.id}`}>
        {expandable && node.children.length > 0 && (
          <div
            className={`${cssPrefix} foldIndicator ${open ? 'open' : ''}`}
            onClick={() => {
              if (open) {
                closeNode();
              } else {
                openNode();
              }
            }}
          >
            <span className={`${cssPrefix} folderIndicatorIcon`}>
              {typeof icons.angle === 'function' ? React.createElement(icons.angle as ComponentType) : icons.angle}
            </span>
          </div>
        )}
        {expandable && node.children.length == 0 && (
          <div
            className={`${cssPrefix} foldIndicator ${open ? 'open' : ''}`}
            onClick={() => {
              if (open) {
                closeNode();
              } else {
                openNode();
              }
            }}
          >
            <span className={`${cssPrefix} folderIndicatorIcon`}></span>
          </div>
        )}
        <List.Content
          className={classnames(cssPrefix, 'treeNode', {
            '!bg-red-100': !selectable && node.id === selectedNode,
            '!cursor-not-allowed opacity-50': !selectable,
            selected: node.id === selectedNode,
          })}
          onClick={() => selectNode && selectable && selectNode(node.id)}
          onDoubleClick={() => {
            if (open) {
              closeNode();
            } else {
              openNode();
            }
          }}
        >
          {ContentComponent && <ContentComponent treeNode={node} icon={icon} cssPrefix={cssPrefix} />}
          {!ContentComponent && (
            <>
              <span className={`${cssPrefix} nodeIcon`}>{icon}</span>
              <div className={`${cssPrefix} nodeContent`}>{node.name}</div>
            </>
          )}
          {MenuComponent && <MenuComponent treeNode={node} />}
        </List.Content>
      </div>
      {open && (
        <Transition.Group as={List} className={cssPrefix} animation={'slide down'} duration={120}>
          <>
            {children.map((node) => (
              <List.Item key={node.id} className={`flex ${cssPrefix}`}>
                <TreeNodeContent nodeData={node} />
              </List.Item>
            ))}
            {children.length < node.children.length && (
              <List.Item key={node.id}>
                <div className={`${cssPrefix} flexItemInner`}>
                  <List.Content
                    className={classnames(cssPrefix, 'treeNode')}
                    onClick={() => {
                      setChildren(node.children);
                      setShowAll(true);
                    }}
                  >
                    <Ellipsis className={`${cssPrefix} nodeIconMore`} />
                    {node.id}
                  </List.Content>
                </div>
              </List.Item>
            )}
          </>
        </Transition.Group>
      )}
    </>
  );
}
