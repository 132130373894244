import {ReactElement} from 'react';
import {SearchCategoryProps} from 'semantic-ui-react';

export default function FlowLabelCategoryResultRenderer({
  categoryContent,
  resultsContent,
}: SearchCategoryProps): ReactElement {
  return (
    <div>
      <div className={'labelFolder'}>{categoryContent}</div>
      <div className={'results'}>{resultsContent}</div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .labelFolder {
          background-color: #fff;
          padding: 0.75rem 1.25rem;
        }

        .results {
          border: none !important;
          display: flex !important;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 1.25rem 0.4375rem 1.25rem;
          grid-gap: 5px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
