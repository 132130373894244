import {AnimatePresence, motion, Variants} from 'framer-motion';
import {CSSProperties, ReactElement, ReactNode, useMemo} from 'react';

interface VariantProps {
  overflow?: CSSProperties['overflow'];
}

function useVariants({overflow = 'inherit'}: VariantProps): Variants {
  return useMemo(
    () => ({
      hidden: {opacity: 0, height: 0, overflow: 'hidden', transition: {duration: 0.24}},
      visible: {opacity: 1, height: 'auto', transition: {duration: 0.24}, transitionEnd: {overflow}},
    }),
    [overflow],
  );
}

interface Props extends VariantProps {
  className?: string;
  visible: boolean;
  children: ReactNode;
}

export default function SlideToggleElement({className, visible, children, overflow}: Props): ReactElement {
  const variants = useVariants({overflow});
  return (
    <AnimatePresence initial={false}>
      {visible && (
        <motion.div
          key={'slideContainer'}
          variants={variants}
          initial={'hidden'}
          animate={'visible'}
          exit={'hidden'}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
