import dayjs from 'dayjs';
import {Disposable} from '../../Disposable';
import {CalendarContext} from './CalendarContext';

interface CanvasViewProps {
  canvas: HTMLDivElement;
  ctx: CalendarContext;
}

export class CanvasView extends Disposable {
  #canvas: HTMLDivElement;
  readonly #ctx: CalendarContext;
  #dateStart: dayjs.Dayjs | null = null;
  #dateEnd: dayjs.Dayjs | null = null;

  readonly maxHeight = 5000;

  constructor({canvas, ctx}: CanvasViewProps) {
    super();
    this.#ctx = ctx;
    this.#canvas = canvas;

    this.disposables.push(
      this.#ctx.eventEmitter.on('scroll', this.#recalc),
      this.#ctx.eventEmitter.on('resize', this.#recalc),
    );
  }

  init(): void {
    this.#recalc();
  }

  #recalc = (): void => {
    const daysLeft = Math.floor(this.#ctx.scrolling.scrollLeft / this.#ctx.columnWidth);
    const daysRight = Math.ceil((this.#ctx.scrolling.scrollLeft + this.width) / this.#ctx.columnWidth);

    this.#dateStart = this.#ctx.calendarView.dateStart.add(daysLeft, 'd');
    this.#dateEnd = this.#ctx.calendarView.dateStart.add(daysRight, 'd');
  };

  get width(): number {
    return this.#canvas.clientWidth;
  }

  get height(): number {
    return this.#canvas.clientHeight;
  }

  get dateStart(): dayjs.Dayjs {
    if (!this.#dateStart) {
      throw Error('not initialized');
    }
    return this.#dateStart;
  }

  get dateEnd(): dayjs.Dayjs {
    if (!this.#dateEnd) {
      throw Error('not initialized');
    }
    return this.#dateEnd;
  }

  get canvas(): HTMLDivElement {
    return this.#canvas;
  }
}
