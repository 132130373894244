import {Dragging, DraggingImpl} from './Dragging';
import {HorizontalDragging} from './HorizontalDragging';

export class DefaultDragging extends Dragging {
  #hDragging: HorizontalDragging | null = null;

  protected getDragImpl(): DraggingImpl {
    if (!this.#hDragging) {
      this.#hDragging = new HorizontalDragging({ctx: this.ctx, setDragMode: this.setDragMode});
    }
    return this.#hDragging;
  }
}
