import {mergeStates} from '@octaved/store/src/MergeStates';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {FlowState} from '../../../Modules/State';
import {reduceUiStatePatch, UiState} from '../../../Modules/Ui';

export interface AccountSetup {
  //The ids created during the account setup, in order of the contents in base.yml at the time
  boardPosts?: Uuid[];
  customers?: Uuid[];
  groups?: Uuid[];
  kanbanBoards?: Uuid[];
  labels?: Uuid[];
  projects?: Uuid[];
  roles?: Uuid[];
  tasks?: Uuid[];
  timeTrackingRecords?: Uuid[];
  workPackages?: Uuid[];
}

interface GetUiPatch {
  (accountSetup: AccountSetup, state: FlowState): DeepPartial<UiState> | null;
}

const setups: GetUiPatch[] = [
  //organizeBoardsGroupsSelectedBoard
  (accountSetup): DeepPartial<UiState> | null => {
    const boardId = accountSetup.kanbanBoards?.[0]; //"Prioritäten" in base.yml
    return boardId ? {pages: {organizeBoardsGroups: {selectedKanbanBoard: boardId}}} : null;
  },
  //organizeBoardsGroupsSelectedProject:
  (accountSetup): DeepPartial<UiState> | null => {
    const projectId = accountSetup.projects?.[0]; //"Mein erstes Projekt" in base.yml
    return projectId ? {pages: {organizeBoardsGroups: {leftDrawer: {selected: projectId}}}} : null;
  },
  //organizeBoardsProjectsSelectedBoard:
  (accountSetup): DeepPartial<UiState> | null => {
    const boardId = accountSetup.kanbanBoards?.[0]; //"Prioritäten" in base.yml
    return boardId ? {pages: {organizeBoardsProjects: {selectedKanbanBoard: boardId}}} : null;
  },
  //organizeBoardsTasksSelectedBoard:
  (accountSetup): DeepPartial<UiState> | null => {
    const boardId = accountSetup.kanbanBoards?.[0]; //"Prioritäten" in base.yml
    return boardId ? {pages: {organizeBoardsTasks: {selectedKanbanBoard: boardId}}} : null;
  },
  //organizeBoardsTasksSelectedProject:
  (accountSetup): DeepPartial<UiState> | null => {
    const projectId = accountSetup.projects?.[0]; //"Mein erstes Projekt" in base.yml
    return projectId ? {pages: {organizeBoardsTasks: {leftDrawer: {selected: projectId}}}} : null;
  },
  //organizeBoardsWorkPackagesSelectedBoard:
  (accountSetup): DeepPartial<UiState> | null => {
    const boardId = accountSetup.kanbanBoards?.[0]; //"Prioritäten" in base.yml
    return boardId ? {pages: {organizeBoardsWorkPackages: {selectedKanbanBoard: boardId}}} : null;
  },
  //organizeBoardsWorkPackagesSelectedProject
  (accountSetup): DeepPartial<UiState> | null => {
    const projectId = accountSetup.projects?.[0]; //"Mein erstes Projekt" in base.yml
    return projectId ? {pages: {organizeBoardsWorkPackages: {leftDrawer: {selected: projectId}}}} : null;
  },
  //projectPlanningSelectedProjects
  (accountSetup): DeepPartial<UiState> | null => {
    const projectId = accountSetup.projects?.[0]; //"Beispiel für Projektplanung" in base.yml
    if (projectId) {
      return {
        pages: {
          planning: {
            projectPlanningExtendedNodes: {
              [accountSetup.customers?.[1] || '']: true,
              [projectId]: true,
              [accountSetup.workPackages?.[0] || '']: false,
              [accountSetup.workPackages?.[1] || '']: false,
              [accountSetup.workPackages?.[2] || '']: false,
            },
            projectPlanningSelectedProjects: [projectId],
          },
        },
      };
    }
    return null;
  },
  //teamPlanningSelectSelf
  (_, state): DeepPartial<UiState> | null => {
    const unitId = currentOrgUserIdSelector(state);
    return {
      pages: {
        teamPlanning: {
          extendedNodes: [unitId],
          selectedUnits: [{unitId, unitType: SimpleUnitType.user}],
        },
      },
    };
  },
  //workspaceBoardsSelectedBoard
  (accountSetup): DeepPartial<UiState> | null => {
    const boardId = accountSetup.kanbanBoards?.[0]; //"Prioritäten" in base.yml
    return boardId ? {pages: {boards: {selectedKanbanBoard: boardId}}} : null;
  },
  //extend edit projects
  (accountSetup): DeepPartial<UiState> | null => {
    const projectId = accountSetup.projects?.[0];
    return projectId ? {pages: {projects: {openNodes: [projectId]}}} : null;
  },
  //activate demo data
  (): DeepPartial<UiState> | null => {
    return {
      pages: {
        myTasks: {showConnectedCalendarDemoData: true},
        projects: {projectControlling: {demoMode: true}},
        reporting: {demoMode: true},
      },
    };
  },
];

const storageKey = 'registration.accountSetup';

export function storeAccountSetup(accountSetup: AccountSetup): void {
  sessionStorage.setItem(storageKey, JSON.stringify(accountSetup));
}

export function reduceAccountSetup(state: FlowState): FlowState {
  const accountSetupRaw = sessionStorage.getItem(storageKey);
  if (accountSetupRaw) {
    sessionStorage.removeItem(storageKey);
    const accountSetup = JSON.parse(accountSetupRaw) as AccountSetup;
    let uiPatch: DeepPartial<UiState> = {};
    for (const getUiPatch of setups) {
      const patch = getUiPatch(accountSetup, state);
      if (patch) {
        // @ts-ignore it is too deep
        uiPatch = mergeStates(uiPatch, patch);
      }
    }
    return {...state, ui: reduceUiStatePatch(state.ui as FlowState['ui'], uiPatch) as FlowState['ui']};
  }

  return state;
}
