import {error, warning} from '@octaved/env/src/Logger';
import {
  AnyCondition,
  CombinersShape,
  isAndCondition,
  isCustomCombiner,
  isFixResult,
  isNotCondition,
  isOrCondition,
  isTransformer,
} from '@octaved/utilities/src/Condition/Types';
import {validateFunction} from '@octaved/validation';

export function resolveBoolCondition<C, T = string, Combiners extends CombinersShape<T> = never>(
  condition: AnyCondition<C, T, Combiners>,
  callback: (cond: C) => boolean,
): boolean {
  validateFunction(callback);
  let result = null;

  if (isNotCondition(condition)) {
    // Condition := {not: Condition}
    result = !resolveBoolCondition(condition.not, callback);
  } else if (isAndCondition(condition)) {
    // Condition := {and: Condition[]}
    result = condition.and.every((element) => resolveBoolCondition(element, callback));
  } else if (isOrCondition(condition)) {
    // Condition := {or: Condition[]}
    result = condition.or.some((element) => resolveBoolCondition(element, callback));
  } else if (isTransformer(condition)) {
    warning('transform not implemented for resolveBoolCondition()');
    result = resolveBoolCondition(condition.transform[0], callback);
  } else if (isCustomCombiner(condition)) {
    warning('combine not implemented for resolveBoolCondition()');
    result = false;
  } else if (isFixResult(condition)) {
    error('fixResult makes no sense for resolveBoolCondition()');
  }

  if (result === null) {
    // pass other conditions to callback
    result = callback(condition as C);
  }

  return result;
}
