import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

interface WithOrWithoutLimitProps {
  token: string;
  withoutLimit?: boolean;
}

export default function WithOrWithoutLimit({token, withoutLimit}: WithOrWithoutLimitProps): ReactElement {
  return (
    <span>
      <span>
        <Trans i18nKey={token} />
      </span>{' '}
      <span className={'text-sm'}>
        (
        <span>
          <Trans
            i18nKey={
              withoutLimit
                ? 'nodeDetails:field.controllingTimeTracking.withLimitNote'
                : 'nodeDetails:field.controllingTimeTracking.withoutLimitNote'
            }
          />
        </span>
        )
      </span>
    </span>
  );
}
