import {Tooltip} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../../EntityInterfaces/SubWorkPackage';
import {isCustomerBillableSelector} from '../../../../Modules/Selectors/CustomerSelectors';
import {useNodeAncestry} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {useTimeEffortPriceSumPerBillingTypeForWorkPackage} from '../../../../Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {isWorkPackage} from '../../../../Node/NodeIdentifiers';
import {getBillingTypeTranslatable} from '../../../../WorkPackage/BillingType';
import {getEffectiveMaxEffort} from '../../../../WorkPackage/MaxEffort';
import SingleBillingTypeProgress from '../../../BillingType/SingleBillingTypeProgress';
import DecimalHoursDuration from '../../../Duration/DecimalHoursDuration';

interface Props {
  node: WorkPackage | SubWorkPackage;
}

export default function TrackedTimeProgress({node}: Props): ReactElement | null {
  const {project, workPackage} = useNodeAncestry(node.id, true);
  const {t} = useTranslation();
  const sum = useTimeEffortPriceSumPerBillingTypeForWorkPackage(node.id);
  const customerBillableSelector = useSelector(isCustomerBillableSelector);

  if (!project || !workPackage || !workPackage.billingType) {
    return null;
  }

  const billingType = workPackage.billingType;
  const isCustomerBillable = Boolean(customerBillableSelector(project.flowCustomer));
  const billingTypeTranslation = getBillingTypeTranslatable(billingType, isCustomerBillable);
  const maxEffort = isWorkPackage(node) ? getEffectiveMaxEffort(node) : node.maxEffort;
  const billedHours = node.trackedMinutes.billed / 60;

  if (maxEffort === null) {
    if (node.trackedMinutes.billed === 0) {
      return null;
    }

    return (
      <Tooltip
        toolTipTranslation={''}
        content={
          <>
            {!isCustomerBillable && <>{t('workPackage:timeLimit')}</>}
            {isCustomerBillable && <>{t('workPackage:billingType')}</>}
            :&nbsp;
            <Trans i18nKey={billingTypeTranslation} />
          </>
        }
      >
        <div className={'progress'}>
          <DecimalHoursDuration to={billedHours} />
          {/*language=scss*/}
          <style jsx>{`
            .progress {
              min-width: 40px;
              display: flex;
              justify-content: flex-end;
            }
          `}</style>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      toolTipTranslation={''}
      content={
        <>
          <span>
            <Trans i18nKey={billingTypeTranslation} />
          </span>
          :&nbsp;
          <span>
            {t('general:xOfYHours', {
              count: Math.round(maxEffort),
              hours: formatDecimal(billedHours),
              total: formatDecimal(maxEffort),
            })}
          </span>
        </>
      }
    >
      <div className={'progress'}>
        <SingleBillingTypeProgress sum={sum} customer={workPackage.flowCustomer} billingType={billingType} />
        {/*language=scss*/}
        <style jsx>{`
          .progress {
            --billing-type-progress-width: 40px;
          }
        `}</style>
      </div>
    </Tooltip>
  );
}
