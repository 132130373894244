import ConfirmDialog from '@octaved/flow/src/Components/Dialog/ConfirmDialog';
import TaskListReadonlyContext from '@octaved/flow/src/Components/Task/TaskListReadonlyContext';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {TaskPatchData} from '@octaved/flow/src/EntityInterfaces/Task';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {patchTaskWithTaskPatchData} from '@octaved/flow/src/Modules/Tasks';
import {deleteTaskSection} from '@octaved/flow/src/Modules/TaskSections';
import {isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {Trash} from 'lucide-react';
import {lazy, ReactElement, ReactNode, Suspense, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

export type DialogType =
  | 'notes'
  | 'moveWorkPackageTasks'
  | 'copyWorkPackageTasks'
  | 'selectPredecessor'
  | 'baselineComparison'
  | 'burndownChart'
  | 'addLogicalDependency'
  | 'delete'
  | 'workPackageStatusBatchTool'
  | 'newProject'
  | 'newTemplate'
  | 'newGroup'
  | 'newGroupBefore'
  | 'newGroupAfter'
  | 'newWorkPackage'
  | 'newWorkPackageBefore'
  | 'newSubWorkPackage'
  | 'newSubWorkPackageBefore'
  | 'duplicatePid'
  | 'changeProjectFolder'
  | 'confirmDeleteTaskSection'
  | 'customizeColor';
interface ContextMenuDialogsProps {
  dialogType: undefined | DialogType;
  onClose: () => void;
  node?: NodeType;
}

const LazyEditNoteDialog = lazy(() => import('@octaved/flow/src/Dialogs/EditNoteDialog'));
const LazyDeleteTaskDialog = lazy(() => import('@octaved/flow/src/Dialogs/DeleteTaskDialog'));
const LazyMoveWorkPackageTasksDialog = lazy(
  () => import('@octaved/flow/src/Components/Task/MoveWorkPackageTasks/MoveWorkPackageTasks'),
);
const LazyCopyWorkPackageTasksDialog = lazy(
  () => import('@octaved/flow/src/Components/Task/CopyWorkPackageTasks/CopyWorkPackageTasks'),
);
const LazyAddLogicalDependencyDialog = lazy(
  () => import('@octaved/flow/src/Components/Task/TaskListForNode/Task/AddLogicalDependency'),
);
const LazyBaselineComparisonDrawer = lazy(
  () => import('@octaved/planning/src/Components/BaselineComparison/BaselineComparisonDrawer'),
);
const LazyBurnDownChartDialog = lazy(
  () => import('@octaved/flow/src/Pages/Projects/Drawer/TaskBurnDown/BurnDownChartDrawer'),
);
const LazyWorkPackageStatusBatchTool = lazy(
  () => import('@octaved/flow/src/Dialogs/WorkPackageStatusBatchTool/WorkPackageStatusBatchTool'),
);
const LazyNewProjectDialog = lazy(() => import('@octaved/flow/src/Dialogs/NewProject/NewProject'));
const LazyNewGroupDialog = lazy(() => import('@octaved/flow/src/Dialogs/NewGroupPid/NewGroup'));
const LazyNewWorkPackageDialog = lazy(() => import('@octaved/flow/src/Dialogs/NewWorkPackage/NewWorkPackage'));
const LazyNewSubWorkPackageDialog = lazy(() => import('@octaved/flow/src/Dialogs/NewSubWorkPackage/NewSubWorkPackage'));
const LazyDuplicatePidDialog = lazy(() => import('@octaved/flow/src/Dialogs/DuplicatePid/DuplicatePid'));
const LazyChangeProjectFolderDialog = lazy(
  () => import('@octaved/flow/src/Dialogs/ChangeProjectFolder/ChangeProjectFolder'),
);
const LazyTaskSectionColorPicker = lazy(
  () => import('@octaved/flow/src/Components/Task/TaskGroup/TaskSectionColorPicker'),
);

function ContextMenuDialogs({dialogType, onClose, node}: ContextMenuDialogsProps): ReactElement {
  const dispatch = useDispatch();
  const ancestry = useNodeAncestry(typeof node?.id === 'string' ? node.id : null, true);
  const {t} = useTranslation();
  const parent = ancestry.ancestors[1] as NodeType | undefined;
  const triggerRef = useRef<HTMLDivElement>(null);

  const currentDialog = useMemo((): ReactNode => {
    switch (dialogType) {
      case 'notes':
        if (!isTask(node)) {
          return null;
        }
        return (
          <TaskListReadonlyContext readonly={false} canManageTasks={true}>
            <LazyEditNoteDialog
              onClose={onClose}
              taskName={node.name}
              description={node.description}
              patch={(data: Partial<TaskPatchData>) => dispatch(patchTaskWithTaskPatchData(node.id, data))}
            />
          </TaskListReadonlyContext>
        );
      case 'moveWorkPackageTasks':
        if (!node?.id) {
          return null;
        }

        return <LazyMoveWorkPackageTasksDialog setOpen={onClose} workPackageId={node.id} open />;
      case 'copyWorkPackageTasks':
        if (!node?.id) {
          return null;
        }

        return <LazyCopyWorkPackageTasksDialog setOpen={onClose} workPackageId={node.id} open />;
      case 'selectPredecessor':
        if (!isTask(node)) {
          return null;
        }

        return <LazyAddLogicalDependencyDialog task={node} onClose={onClose} />;
      case 'baselineComparison':
        if (!node?.id) {
          return null;
        }

        return <LazyBaselineComparisonDrawer close={onClose} rootNodeId={node.id} visible />;
      case 'burndownChart': {
        if (!node?.id) {
          return null;
        }
        const nodeId = isTask(node) ? parent?.id : node.id;

        return <LazyBurnDownChartDialog nodeId={nodeId} visible close={onClose} />;
      }
      case 'delete':
        if (!node?.id) {
          return null;
        }
        if (isTask(node)) {
          return <LazyDeleteTaskDialog task={node} close={onClose} />;
        }

        return null;
      case 'addLogicalDependency':
        if (!isTask(node)) {
          return null;
        }

        return <LazyAddLogicalDependencyDialog task={node} onClose={onClose} />;
      case 'workPackageStatusBatchTool':
        if (!node?.id) {
          return null;
        }

        return <LazyWorkPackageStatusBatchTool statusBatchToolNodeId={node.id} close={onClose} />;
      case 'newProject':
        if (!node?.id) {
          return null;
        }

        return <LazyNewProjectDialog relativeNodeId={node.id} close={onClose} />;
      case 'newGroup':
        if (!node?.id) {
          return null;
        }

        return <LazyNewGroupDialog relativeNodeId={node.id} close={onClose} />;
      case 'newGroupBefore':
        if (!node?.id) {
          return null;
        }

        return <LazyNewGroupDialog relativeNodeId={node.id} close={onClose} before />;
      case 'newGroupAfter':
        if (!node?.id) {
          return null;
        }

        return <LazyNewGroupDialog relativeNodeId={node.id} close={onClose} after />;
      case 'newWorkPackage':
        if (!node?.id) {
          return null;
        }

        return <LazyNewWorkPackageDialog relativeNodeId={node.id} close={onClose} />;
      case 'newWorkPackageBefore':
        if (!node?.id) {
          return null;
        }

        return <LazyNewWorkPackageDialog relativeNodeId={node.id} close={onClose} before />;
      case 'newSubWorkPackage':
        if (!node?.id) {
          return null;
        }

        return <LazyNewSubWorkPackageDialog relativeNodeId={node.id} close={onClose} />;
      case 'newSubWorkPackageBefore':
        if (!node?.id) {
          return null;
        }

        return <LazyNewSubWorkPackageDialog relativeNodeId={node.id} close={onClose} before />;
      case 'newTemplate':
        if (!node?.id) {
          return null;
        }

        return <LazyNewProjectDialog relativeNodeId={node.id} close={onClose} isTemplate />;
      case 'duplicatePid':
        if (!node?.id) {
          return null;
        }

        return <LazyDuplicatePidDialog nodeId={node.id} close={onClose} />;
      case 'changeProjectFolder':
        if (!node?.id) {
          return null;
        }

        return <LazyChangeProjectFolderDialog nodeId={node.id} close={onClose} />;
      case 'confirmDeleteTaskSection':
        if (!node?.id) {
          return null;
        }

        return (
          <ConfirmDialog
            title={'taskList:confirmDeleteSection.title'}
            buttonLabel={'taskList:confirmDeleteSection.buttonLabel'}
            buttonIcon={<Trash />}
            buttonColorScheme={'delete'}
            cancelCallback={() => onClose()}
            submitCallback={() => {
              dispatch(deleteTaskSection(node.id));
              onClose();
            }}
          >
            <span>{t('taskList:confirmDeleteSection.text')}</span>
          </ConfirmDialog>
        );
      case 'customizeColor':
        if (!node?.id) {
          return null;
        }

        return (
          <>
            <div ref={triggerRef} />
            <LazyTaskSectionColorPicker sectionId={node.id} onClose={onClose} triggerRef={triggerRef} />
          </>
        );
      default:
        return null;
    }
  }, [dialogType, dispatch, node, onClose, parent?.id, t]);

  return <Suspense fallback={null}>{currentDialog}</Suspense>;
}

export default ContextMenuDialogs;
