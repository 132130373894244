import {BoardPostType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {BoardPost, BoardPostApproval, BoardPostFYI, BoardPostQuestion} from '../../EntityInterfaces/BoardPost';
import {NodeEntity} from '../../EntityInterfaces/NodeEntity';
import {Group, Project, WorkPackage} from '../../EntityInterfaces/Pid';
import {Task} from '../../EntityInterfaces/Task';
import {nodeEntitySelector} from './NodeSelectors';
import {getNodeAncestrySelector} from './NodeTreeSelectors';

export function isBoardPost(node: NodeEntity | undefined | null): node is BoardPost {
  return Boolean(node && node.nodeType === EnumFlowNodeType.VALUE_BOARD_POST);
}

export function isBoardPostApproval(node: NodeEntity | undefined | null): node is BoardPostApproval {
  return isBoardPost(node) && node.boardPostType === BoardPostType.approval;
}

export function isBoardPostFYI(node: NodeEntity | undefined | null): node is BoardPostFYI {
  return isBoardPost(node) && node.boardPostType === BoardPostType.fyi;
}

export function isBoardPostQuestion(node: NodeEntity | undefined | null): node is BoardPostQuestion {
  return isBoardPost(node) && node.boardPostType === BoardPostType.question;
}

export const boardPostSelector = createSelector(nodeEntitySelector, (nodes) =>
  memoize((id: Uuid): BoardPost | undefined => {
    const node = nodes[id];
    if (node && !isBoardPost(node)) {
      throw new Error(`PID Type is request but '${node?.nodeType}' type is returned`);
    }
    return node;
  }),
);

export const geBoardPostParentSelector = createSelector(
  getNodeAncestrySelector,
  (getNodeAncestry) =>
    (id: MaybeUuid): Task | WorkPackage | Group | Project | null => {
      const ancestry = getNodeAncestry(id);
      return ancestry.tasks[0] ?? ancestry.workPackage ?? ancestry.groups[0] ?? ancestry.project ?? null;
    },
);
