import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {FullUnit, SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {groupsSelector} from '@octaved/users/src/Selectors/GroupSelectors';
import {getUsersIdsForGroupSelector} from '@octaved/users/src/Selectors/GroupUserSelectors';
import {userEntitiesSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {isResponsibleNode, ResponsibleNode, ResponsibleProps} from '../../EntityInterfaces/ResponsibleNode';
import {getNodeSearchQueryResultsSelector} from './NodeSearchSelectors';
import {getNodeSelector} from './NodeSelectors';
import {getNodeAncestrySelector} from './NodeTreeSelectors';
import {rootFoldersUserFolderSelector} from './RootFolderSelectors';

export const getNextAncestorResponsibleNodeSelector = createSelector(getNodeAncestrySelector, (getNodeAncestry) =>
  memoize(
    (nodeId: Uuid, {self, defining}: {self?: boolean; defining?: boolean} = {}): ResponsibleNode | null => {
      const ancestors = getNodeAncestry(nodeId, self).responsibleNodes;
      return (defining ? ancestors.find(({definesOwnResponsible}) => definesOwnResponsible) : ancestors[0]) || null;
    },
    (...args) => JSON.stringify(args),
  ),
);

export const isUnitResponsibleForNodeSelector = createSelector(
  getNodeSearchQueryResultsSelector,
  currentOrgUserIdSelector,
  rootFoldersUserFolderSelector,
  (getNodeSearchQueryResults, currentUserId, currentUserNodeId) => (unitId: Uuid, nodeId: Uuid) => {
    const personal: NodeSearchCondition[] =
      currentUserId === unitId && currentUserNodeId ? [withDescendants(currentUserNodeId, true)] : [];
    return getNodeSearchQueryResults({
      or: [...personal, ['responsibleUnitId', currentUserId]],
    }).includes(nodeId);
  },
);

export const getAllAffectedUnitIdsForResponsibleNodeSelector = createSelector(
  getUsersIdsForGroupSelector,
  (getUsersIdsForGroup) => (node: Partial<ResponsibleProps>) => {
    const unitIds = new Set<Uuid>();
    (node.responsibleGroups || []).forEach((groupId) => {
      unitIds.add(groupId);
      getUsersIdsForGroup(groupId).forEach((id) => unitIds.add(id));
    });
    (node.responsibleUsers || []).forEach((userId) => unitIds.add(userId));
    return unitIds;
  },
);

export function getUnitChangeSet(
  previous: ReadonlySet<Uuid>,
  next: ReadonlySet<Uuid>,
): [ReadonlySet<Uuid>, ReadonlySet<Uuid>] {
  const added = new Set([...next].filter((id) => !previous.has(id)));
  const removed = new Set([...previous].filter((id) => !next.has(id)));

  //If unit appears in both sets, they might have been added via a group and removed via another,
  // but eventually for the searches we don't do anything in this case, so we just drop the id:
  added.forEach((id) => {
    if (removed.has(id)) {
      added.delete(id);
      removed.delete(id);
    }
  });
  removed.forEach((id) => {
    if (added.has(id)) {
      added.delete(id);
      removed.delete(id);
    }
  });

  return [added, removed];
}

export const getResponsibleFullUnitsForNodeSelector = createSelector(
  groupsSelector,
  userEntitiesSelector,
  (groups, users) =>
    memoize((node: NodeType | null | undefined): ReadonlyArray<FullUnit> => {
      if (isResponsibleNode(node)) {
        return [
          ...(node.responsibleGroups || []).map<FullUnit>((unitId) => ({
            unitId,
            unitName: groups[unitId]?.name || '',
            unitType: SimpleUnitType.group,
          })),
          ...(node.responsibleUsers || []).map<FullUnit>((unitId) => ({
            unitId,
            unitName: users[unitId]?.name || '',
            unitType: SimpleUnitType.user,
          })),
        ].sort((a, b) => a.unitName.localeCompare(b.unitName));
      }
      return [];
    }),
);

export function getUnitIdsForResponsibleNode(
  node: ResponsibleNode | ReadonlyArray<ResponsibleNode> | ReadonlySet<ResponsibleNode>,
): Set<Uuid> {
  const nodes: ResponsibleNode[] = isResponsibleNode(node) ? [node] : [...node];
  const set = new Set<Uuid>();
  nodes.forEach((n) => {
    (n?.responsibleGroups || []).forEach((id) => set.add(id));
    (n?.responsibleUsers || []).forEach((id) => set.add(id));
  });
  return set;
}

export function createUnitsForResponsibleNode(node: ResponsibleNode): SlimUnit[] {
  return [
    ...(node?.responsibleGroups || []).map<SlimUnit>((unitId) => ({
      unitId,
      unitType: SimpleUnitType.group,
    })),
    ...(node?.responsibleUsers || []).map<SlimUnit>((unitId) => ({unitId, unitType: SimpleUnitType.user})),
  ];
}

export const getNodeResponsibleUnitsSelector = createSelector(getNodeSelector, (getNode) =>
  memoize((nodeId: Uuid) => {
    const node = getNode(nodeId);
    return isResponsibleNode(node) ? createUnitsForResponsibleNode(node) : [];
  }),
);
