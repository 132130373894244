export const IDENTITY_PATCH_FAILURE = 'IDENTITY_PATCH_FAILURE';
export const IDENTITY_PATCH_REQUEST = 'IDENTITY_PATCH_REQUEST';
export const IDENTITY_PATCH_SUCCESS = 'IDENTITY_PATCH_SUCCESS';

export const LOAD_CURRENT_2FA_REVOKE_FAILURE = 'LOAD_CURRENT_2FA_REVOKE_FAILURE';
export const LOAD_CURRENT_2FA_REVOKE_REQUEST = 'LOAD_CURRENT_2FA_REVOKE_REQUEST';
export const LOAD_CURRENT_2FA_REVOKE_SUCCESS = 'LOAD_CURRENT_2FA_REVOKE_SUCCESS';

export const LOAD_CURRENT_2FA_VERIFICATION_FAILURE = 'LOAD_CURRENT_2FA_VERIFICATION_FAILURE';
export const LOAD_CURRENT_2FA_VERIFICATION_REQUEST = 'LOAD_CURRENT_2FA_VERIFICATION_REQUEST';
export const LOAD_CURRENT_2FA_VERIFICATION_SUCCESS = 'LOAD_CURRENT_2FA_VERIFICATION_SUCCESS';

export const START_SMS_2FA_VERIFICATION_FAILURE = 'START_SMS_2FA_VERIFICATION_FAILURE';
export const START_SMS_2FA_VERIFICATION_REQUEST = 'START_SMS_2FA_VERIFICATION_REQUEST';
export const START_SMS_2FA_VERIFICATION_SUCCESS = 'START_SMS_2FA_VERIFICATION_SUCCESS';

export const VERIFY_SMS_2FA_VERIFICATION_FAILURE = 'VERIFY_SMS_2FA_VERIFICATION_FAILURE';
export const VERIFY_SMS_2FA_VERIFICATION_REQUEST = 'VERIFY_SMS_2FA_VERIFICATION_REQUEST';
export const VERIFY_SMS_2FA_VERIFICATION_SUCCESS = 'VERIFY_SMS_2FA_VERIFICATION_SUCCESS';
