import {ResponsibleProps} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {getResponsibleArrayKeyForUnitType, patchNodesResponsibilities} from '@octaved/flow/src/Modules/ResponsibleNode';
import {Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {BarManipulatingContext} from './BarManipulatingContext';

interface AssigneUnitProps {
  ctx: BarManipulatingContext;
}

export const barDropzoneClassName = 'js-barDropZone';

export default class AssigneUnit {
  readonly #ctx: BarManipulatingContext;

  constructor({ctx: context}: AssigneUnitProps) {
    this.#ctx = context;
  }

  updateResponsibleNode(
    sourceUnit: SlimUnit | null,
    targetUnit: SlimUnit | null,
    node: ResponsibleProps & {id: Uuid},
  ): void {
    const responsible = {
      responsibleGroups: [...node.responsibleGroups],
      responsibleUsers: [...node.responsibleUsers],
    };

    const remove = ({unitId, unitType}: SlimUnit): void => {
      const key = getResponsibleArrayKeyForUnitType(unitType);
      responsible[key] = responsible[key].filter((id) => id !== unitId);
    };

    const add = ({unitId, unitType}: SlimUnit): void => {
      const key = getResponsibleArrayKeyForUnitType(unitType);
      if (!responsible[key].includes(unitId)) {
        responsible[key].push(unitId);
      }
    };

    if (sourceUnit && !targetUnit) {
      responsible.responsibleGroups = [];
      responsible.responsibleUsers = [];
    } else if (sourceUnit && targetUnit) {
      remove(sourceUnit);
      add(targetUnit);
    } else if (!sourceUnit && targetUnit) {
      add(targetUnit);
    }

    this.#ctx.store.dispatch(patchNodesResponsibilities({[node.id]: responsible}));
  }

  getDropZoneFromTarget(target: EventTarget | null): HTMLElement | null {
    let element: HTMLElement | EventTarget | null = target;
    while (element) {
      if (element instanceof Element) {
        if (element instanceof HTMLElement && element.classList.contains(barDropzoneClassName)) {
          return element;
        }
        element = element.parentElement;
      } else {
        return null;
      }
    }
    return null;
  }

  createUnitFromTarget(target: HTMLElement): SlimUnit | null {
    const unitId = target?.getAttribute('data-unit-id');
    const unitType = target?.getAttribute('data-unit-type') as SimpleUnitType | null;

    if (unitId && unitType && Object.values(SimpleUnitType).includes(unitType)) {
      return {unitId, unitType};
    }
    return null;
  }
}
