export const dialogBaseRoute = 'dialog';
export const exportProjectRoute = 'exportProject';
export const newProjectDialogRoute = 'newProject';
export const newGroupDialogRoute = 'newGroup';
export const newWorkPackageDialogRoute = 'newWorkPackage';
export const newSubWorkPackageDialogRoute = 'newSubWorkPackage';
export const changeCustomerRoute = 'changeCustomer';
export const changeProjectFolderRoute = 'changeProjectFolder';
export const demoWelcomeDialogRoute = 'demoWelcome';
export const deletePidDialogRoute = 'deletePid';
export const responsibleBatchToolRoute = 'responsibleBatchTool';
export const restoreNodesFromTrashDialogRoute = 'restoreNodesFromTrash';
export const restoreTimeRecordsFromTrashDialogRoute = 'restoreTimeRecordsFromTrash';
export const nodeBoardDialogRoute = 'board';
export const workPackageStatusBatchToolRoute = 'workPackageStatusBatchTool';
export const closeDialogRoute = '../..';
