import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export type IsoWorkWeekday = 1 | 2 | 3 | 4 | 5;
export type IsoWeekendWeekday = 6 | 7;
export type IsoWeekday = IsoWorkWeekday | IsoWeekendWeekday;

export const isoWorkWeekdays: IsoWorkWeekday[] = [1, 2, 3, 4, 5];
export const isoWeekendWeekdays: IsoWeekendWeekday[] = [6, 7];
export const isoWeekdays: IsoWeekday[] = [...isoWorkWeekdays, ...isoWeekendWeekdays];

export const isoWeekdayShortTokens: Record<IsoWeekday, string> = {
  1: 'generalCore:culture.shortWeekDays.monday',
  2: 'generalCore:culture.shortWeekDays.tuesday',
  3: 'generalCore:culture.shortWeekDays.wednesday',
  4: 'generalCore:culture.shortWeekDays.thursday',
  5: 'generalCore:culture.shortWeekDays.friday',
  6: 'generalCore:culture.shortWeekDays.saturday',
  7: 'generalCore:culture.shortWeekDays.sunday',
};

export const isoWeekdayLongTokens: Record<IsoWeekday, string> = {
  1: 'generalCore:culture.weekDays.monday',
  2: 'generalCore:culture.weekDays.tuesday',
  3: 'generalCore:culture.weekDays.wednesday',
  4: 'generalCore:culture.weekDays.thursday',
  5: 'generalCore:culture.weekDays.friday',
  6: 'generalCore:culture.weekDays.saturday',
  7: 'generalCore:culture.weekDays.sunday',
};
