import {useEffect, useMemo, useRef, useState} from 'react';

/**
 * Hook to keep a value until the new value is loaded
 *
 * Use when e.g. switching filters, but keep the displayed data until the new data arrived
 */
export function useLoadedValue<T>(isLoading: boolean, value: T, resetKey: unknown = ''): T {
  const valueRef = useRef(value);
  const resetKeyRef = useRef(resetKey);
  return useMemo(() => {
    if (!isLoading || resetKey !== resetKeyRef.current) {
      valueRef.current = value;
      resetKeyRef.current = resetKey;
    }
    return valueRef.current;
  }, [isLoading, resetKey, value]);
}

/**
 * Returns the value and keeps it the same once it was loaded.
 */
export function useFirstLoadedValue<T>(value: T, hasLoaded: boolean): T {
  const [inner, setInner] = useState<T>(value);
  const hasBeenSetOnce = useRef(false);
  useEffect(() => {
    if (hasLoaded && !hasBeenSetOnce.current) {
      hasBeenSetOnce.current = true;
      setInner(value);
    }
  }, [hasLoaded, value]);
  return inner;
}
