import {dialogRouteObjects} from '@octaved/flow/src/Routing/Routes/Dialogs';
import {lazy} from 'react';
import {RouteObject} from 'react-router-dom';
import {
  advancedRoute,
  generalRoute,
  guestUserRoute,
  historyRoute,
  planningRoute,
  responsibleRoute,
  settingsRoute,
  timeAndMoneyRoute,
} from '../RoutePaths';

const RedirectToGeneralWithLastPage = lazy(() => import('./RedirectToGeneralWithLastPage'));
const ResolveLargeViewDetailsPage = lazy(() => import('./ResolveLargeViewDetailsPage'));

export const largeViewDetailsRoutes: RouteObject[] = [
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={generalRoute} />,
    path: `${generalRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={responsibleRoute} />,
    path: `${responsibleRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={planningRoute} />,
    path: `${planningRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={timeAndMoneyRoute} />,
    path: `${timeAndMoneyRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={guestUserRoute} />,
    path: `${guestUserRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={settingsRoute} />,
    path: `${settingsRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={advancedRoute} />,
    path: `${advancedRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewDetailsPage page={historyRoute} />,
    path: `${historyRoute}/*`,
  },
  {
    element: <RedirectToGeneralWithLastPage />,
    path: '*',
  },
];
