import {DateStr} from '@octaved/typescript';
import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {debounce} from 'lodash';
import {createContext, RefObject, useCallback, useEffect, useMemo, useRef} from 'react';

export const CALENDAR_ROW_HEIGHT = 36;

export enum ZoomLevel {
  '25%',
  '50%',
  '75%',
  '100%',
  '150%',
  '200%',
  '400%',
}

const cellWidths = [5, 10, 15, 20, 30, 40, 80];

export interface CalendarContainerContext {
  cellHeight: number;
  cellWidth: number;
  zoomLevel: ZoomLevel;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  today: dayjs.Dayjs;
  canZoom: boolean;
  isMiniView: boolean;
  inlineTextMaxLength?: number;
  visibleDays: number;
  zoomIn(): void;
  zoomOut(): void;
  setSideScrollRef(ref: RefObject<HTMLElement>): void;
  removeSideScrollRef(): void;
  scrollToToday(): void;
  resetView(): void;
}

const defaultContext: CalendarContainerContext = {
  canZoom: false,
  cellHeight: CALENDAR_ROW_HEIGHT,
  cellWidth: 20,
  endDate: dayjs(),
  isMiniView: false,
  removeSideScrollRef() {
    //nothing to do here
  },
  resetView() {
    //nothing to do here
  },
  scrollToToday() {
    //nothing to do here
  },
  setSideScrollRef() {
    //nothing to do here
  },
  startDate: dayjs(),
  today: dayjs(),
  visibleDays: 0,
  zoomIn() {
    //nothing to do here
  },
  zoomLevel: ZoomLevel['100%'],
  zoomOut() {
    //nothing to do here
  },
} as CalendarContainerContext;

export const calendarContainerContext = createContext<CalendarContainerContext>(defaultContext);

interface CreateCalendarProps {
  startDate: string;
  endDate: string;
  zoomLevel?: ZoomLevel;
  setZoomLevel?(zoom: ZoomLevel): void;
  getCellWidth?(zoom: ZoomLevel): number;
  todayOffset?: number | null;
  onViewStartDateChange?(newStartDate: string): void;
  viewStartDate?: DateStr;
  inlineTextMaxLength?: number;
  isMiniView?: boolean;
}

function getDefaultCellWidth(zoom: ZoomLevel): number {
  return cellWidths[zoom];
}

export function useCreateCalendarContainerContext({
  startDate: dataStartDate,
  endDate: dataEndDate,
  zoomLevel = ZoomLevel['100%'],
  setZoomLevel,
  getCellWidth = getDefaultCellWidth,
  todayOffset = null,
  onViewStartDateChange,
  viewStartDate,
  inlineTextMaxLength,
  isMiniView = false,
}: CreateCalendarProps): CalendarContainerContext {
  const today = useMemo(() => dayjs().startOf('D'), []);
  const {startDate, endDate, visibleDays} = useMemo(() => {
    const startDate = dayjs(dataStartDate).add(-3, 'M').startOf('month');
    const endDate = dayjs(dataEndDate).add(6, 'M').endOf('month');
    const visibleDays = endDate.diff(startDate, 'd') + 1;
    return {startDate, endDate, visibleDays};
  }, [dataStartDate, dataEndDate]);

  const sideScrollRef = useRef<RefObject<HTMLElement | null>>();
  const lastStartDateRef = useRef(toIsoFormat(startDate));
  const lastViewStartDateRef = useRef<DateStr | undefined>();
  const isInScrollingRef = useRef<boolean>(false);
  const getCellWidthRef = useRef(getCellWidth);
  getCellWidthRef.current = getCellWidth;

  const scrollToDate = useCallback(
    (targetDate: dayjs.Dayjs) => {
      const ref = sideScrollRef.current?.current;
      isInScrollingRef.current = true;
      const cellWidth = getCellWidthRef.current(zoomLevel);
      if (ref && cellWidth > 0) {
        const currentStartDate = lastStartDateRef.current;
        const diff = targetDate.diff(fromIsoFormat(currentStartDate), 'd');
        const offset = todayOffset === null ? 12 - zoomLevel * 2 : todayOffset;
        ref.scrollLeft = (diff - offset) * cellWidth;

        requestAnimationFrame(() => {
          isInScrollingRef.current = false;
        });
        lastViewStartDateRef.current = toIsoFormat(targetDate);
      } else {
        requestAnimationFrame(() => {
          scrollToDate(targetDate);
        });
      }
    },
    [todayOffset, zoomLevel],
  );

  useEffect(() => {
    const isoStartDate = toIsoFormat(startDate);
    if (lastViewStartDateRef.current && lastStartDateRef.current !== isoStartDate) {
      lastStartDateRef.current = isoStartDate;
      scrollToDate(fromIsoFormat(lastViewStartDateRef.current));
    }
  }, [startDate, scrollToDate]);

  useEffect(() => {
    //update the scroll position whenever the the viewStartDate is updated from outsite the component, e.g. store
    if (viewStartDate && lastViewStartDateRef.current !== viewStartDate) {
      lastViewStartDateRef.current = viewStartDate;
      scrollToDate(fromIsoFormat(viewStartDate));
    }
  }, [scrollToDate, viewStartDate]);

  useEffect(() => {
    //should only scoll on first rendering to initial date
    scrollToDate(viewStartDate ? fromIsoFormat(viewStartDate) : today);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today]);

  return useMemo(() => {
    const cellWidth = getCellWidth(zoomLevel);

    function scroll(to: number): void {
      const ref = sideScrollRef.current?.current;
      if (ref) {
        ref.scrollLeft = to;
      }
    }
    const debouncedOnScroll = debounce((event: Event) => {
      const left = (event.target as HTMLElement).scrollLeft;
      const daysFromLeft = Math.floor(left / cellWidth);
      const isoDate = toIsoFormat(startDate.add(daysFromLeft, 'd'));
      if (onViewStartDateChange && lastViewStartDateRef.current !== isoDate) {
        onViewStartDateChange(isoDate);
      }
      lastViewStartDateRef.current = isoDate;
    }, 300);

    function onScroll(event: Event): void {
      if (isInScrollingRef.current) {
        return;
      }
      debouncedOnScroll(event);
    }

    function fixScrollOnZoom(newZoom: number): void {
      const ref = sideScrollRef.current?.current;
      if (ref) {
        const scrollLeft = ref.scrollLeft;
        requestAnimationFrame(() => {
          scroll((scrollLeft / cellWidth) * getCellWidth(newZoom));
        });
      }
    }

    const context: CalendarContainerContext = {
      cellWidth,
      endDate,
      inlineTextMaxLength,
      isMiniView,
      startDate,
      today,
      visibleDays,
      zoomLevel,
      canZoom: Boolean(setZoomLevel),
      cellHeight: 36,
      removeSideScrollRef() {
        sideScrollRef.current?.current?.removeEventListener('scroll', onScroll);
        sideScrollRef.current = undefined;
      },
      resetView() {
        setZoomLevel?.(ZoomLevel['100%']);
        requestAnimationFrame(() => {
          context.scrollToToday();
        });
      },
      scrollToToday() {
        scrollToDate(today);
        onViewStartDateChange?.(toIsoFormat(today));
      },
      setSideScrollRef(ref) {
        sideScrollRef.current = ref;
        ref?.current?.addEventListener('scroll', onScroll);
      },
      zoomIn() {
        const newZoom = Math.max(zoomLevel - 1, 0);
        fixScrollOnZoom(newZoom);
        setZoomLevel?.(newZoom);
      },
      zoomOut() {
        const newZoom = Math.min(zoomLevel + 1, ZoomLevel['400%']);
        fixScrollOnZoom(newZoom);
        setZoomLevel?.(newZoom);
      },
    };
    return context;
  }, [
    isMiniView,
    getCellWidth,
    zoomLevel,
    endDate,
    startDate,
    today,
    setZoomLevel,
    onViewStartDateChange,
    scrollToDate,
    inlineTextMaxLength,
    visibleDays,
  ]);
}
