import FlowOutlet from '@octaved/flow/src/Components/FlowOutlet';
import {dialogRouteObjects} from '@octaved/flow/src/Routing/Routes/Dialogs';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {largeViewControllingRoutes} from './Controlling/ControllingRoutes';
import RedirectToOverviewWithLastPage from './Controlling/RedirectToOverviewWithLastPage';
import {largeViewDetailsRoutes} from './Details/DetailsRoutes';
import {
  advancedRoute,
  boardRoute,
  controllingRoute,
  detailsRoute,
  guestUserRoute,
  historyRoute,
  kanbanRoute,
  overviewRoute,
  planningRoute,
  responsibleRoute,
  settingsRoute,
  tasksRoute,
  timeAndMoneyRoute,
  timeSheetRoute,
} from './RoutePaths';

const ResolveLargeViewPage = lazy(() => import('./ResolveLargeViewPage'));

export type LargeViewPages =
  | 'overview'
  | 'details'
  | 'general'
  | 'tasks'
  | 'board'
  | 'responsibility'
  | 'planning'
  | 'kanban'
  | 'timeAndMoney'
  | 'settings'
  | 'advanced'
  | 'controlling'
  | 'guests'
  | 'history';

export const largeViewsRoutes: RouteObject[] = [
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewPage page={'overview'} />,
    path: `${overviewRoute}/*`,
  },
  {
    children: largeViewDetailsRoutes,
    element: <ResolveLargeViewPage page={'details'} />,
    path: `${detailsRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewPage page={'tasks'} />,
    path: `${tasksRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewPage page={'board'} />,
    path: `${boardRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${responsibleRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewPage page={'planning'} />,
    path: `${planningRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${kanbanRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${timeAndMoneyRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${settingsRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${advancedRoute}/*`,
  },
  {
    children: largeViewControllingRoutes,
    element: <ResolveLargeViewPage page={'controlling'} />,
    path: `${controllingRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${guestUserRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: FlowOutlet,
    path: `${historyRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewPage page={'timeSheet'} />,
    path: `${timeSheetRoute}/*`,
  },
  {
    element: <RedirectToOverviewWithLastPage />,
    path: '*',
  },
];
