import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {ReactElement} from 'react';
import {useMarkNodeDoneWorkflowControlModal} from '../../../Dialogs/WorkflowControl/MarkWorkPackageDoneWorkflowControl';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {patchSubWorkPackage} from '../../../Modules/SubWorkPackages';
import {patchWorkPackage} from '../../../Modules/WorkPackages';
import CheckButton from './CheckButton';

interface Props {
  node: WorkPackage | SubWorkPackage;
}

export default function IsCompletedButton({node}: Props): ReactElement {
  const patchFN = node.nodeType === 'workPackage' ? patchWorkPackage : patchSubWorkPackage;
  const {confirmCheckedModal, setChecked} = useMarkNodeDoneWorkflowControlModal(node.id, patchFN);
  return (
    <>
      <CheckButton
        checked={node.isCompleted}
        label={'pages:projects.inspector.header.workPackageDone'}
        setChecked={setChecked}
        tooltip={
          node.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE
            ? 'pages:projects.inspector.header.workPackageMarkAsDoneTooltip'
            : 'pages:projects.inspector.header.subWorkPackageMarkAsDoneTooltip'
        }
      />
      {confirmCheckedModal}
    </>
  );
}
