import {Nodes} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {UnknownAction, isAction} from 'redux';
import {setSimulationReplayAction} from '../Components/SimulatedPlanningStore/ReplaySimulationMiddleware';
import {setSimulatedAction} from '../Components/SimulatedPlanningStore/SimulatedMiddleware';
import {projectPlanningSelectedProjectsSelector} from '../Selectors/UiSelectors';
import {FLOW_PLANNING_REINITIALIZE_SIMULATION, FLOW_PLANNING_RESET_SIMULATION_NODES} from './ActionTypes';

interface ReinitializeSimulationAction {
  type: typeof FLOW_PLANNING_REINITIALIZE_SIMULATION;
  projectPlanningSelectedProjects: Uuid[];
}
interface ResetSimulationNodesAction {
  type: typeof FLOW_PLANNING_RESET_SIMULATION_NODES;
  nodes: Nodes;
}

function isReinitializeSimulationAction(action: unknown): action is ReinitializeSimulationAction {
  return isAction(action) && action.type === FLOW_PLANNING_REINITIALIZE_SIMULATION;
}
function isResetSimulationNodesAction(action: unknown): action is ResetSimulationNodesAction {
  return isAction(action) && action.type === FLOW_PLANNING_RESET_SIMULATION_NODES;
}

export function reinitializeSimulationReducer(state: FlowState, action: UnknownAction): FlowState {
  if (isResetSimulationNodesAction(action)) {
    return {
      ...state,
      entities: {...state.entities, node: {...state.entities.node, ...action.nodes}},
    };
  } else if (isReinitializeSimulationAction(action)) {
    return {
      ...state,
      ui: {
        ...state.ui,
        pages: {
          ...state.ui.pages,
          planning: {
            ...state.ui.pages.planning,
            projectPlanningSelectedProjects: action.projectPlanningSelectedProjects,
          },
        },
      },
    };
  }
  return state;
}

export function patchSimulationState(state: FlowState): ActionDispatcher<void, FlowState> {
  return (dispatch, _getState) => {
    const action = {
      projectPlanningSelectedProjects: projectPlanningSelectedProjectsSelector(state),
      type: FLOW_PLANNING_REINITIALIZE_SIMULATION,
    } as ReinitializeSimulationAction;
    setSimulatedAction(action);
    setSimulationReplayAction(action);
    dispatch(action);
  };
}

export function resetSimulationNodes(state: FlowState): ActionDispatcher<void, FlowState> {
  return (dispatch, _getState) => {
    const action = {
      nodes: state.entities.node,
      type: FLOW_PLANNING_RESET_SIMULATION_NODES,
    } as ResetSimulationNodesAction;
    setSimulatedAction(action);
    setSimulationReplayAction(action);
    dispatch(action);
  };
}
