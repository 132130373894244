import {useInterval} from '@octaved/hooks/src/Interval';
import {DateTimeStr} from '@octaved/typescript';
import {toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs, {Dayjs} from 'dayjs';
import {createContext, PropsWithChildren, ReactElement, useContext, useState} from 'react';

const context = createContext<Dayjs>(dayjs());

function useStandaloneClock(): Dayjs {
  const [now, setNow] = useState<Dayjs>(dayjs());
  useInterval(() => {
    setNow(dayjs());
  }, 5000);
  return now;
}

function ClockContextProvider({children}: PropsWithChildren<unknown>): ReactElement {
  const now = useStandaloneClock();
  return <context.Provider value={now}>{children}</context.Provider>;
}

function useClock(): Dayjs {
  return useContext(context);
}

export function useClockDateTime(): DateTimeStr {
  return toIsoDateTimeFormat(useClock());
}

function useShiftedUnixClock(): number {
  return dayjs.utc(useContext(context).format('HH:mm'), 'HH:mm').unix();
}

export {ClockContextProvider, useClock, useShiftedUnixClock, useStandaloneClock};
