import {searchTimeTrackingRecordsByIdentBulk} from '@octaved/flow-api';
import {post} from '@octaved/network/src/Network';
import {ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {multiAction} from '@octaved/store/src/MultiAction';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {asDateStr, DateStr} from '@octaved/typescript';
import {List, Uuid} from '@octaved/typescript/src/lib';
import {USER_RIGHTS_CHANGED} from '@octaved/users/src/ActionTypes';
import {getDaysInRange} from '@octaved/users/src/Culture/DateFormatFunctions';
import {OrgUserGroupsBulkPatched, UserGroupMembersBulkPatched} from '@octaved/users/src/Event/Events';
import {createSearchKeyReducerFactory} from '@octaved/utilities/src/Search/SearchReducers';
import {debounce} from 'lodash';
import {TimeRecord} from '../EntityInterfaces/TimeRecords';
import {
  FLOW_CREATE_TIME_RECORD_REQUEST,
  FLOW_SEARCH_TIME_RECORD_REQUEST,
  FLOW_SEARCH_TIME_RECORD_SUCCESS,
} from './ActionTypes';
import {InternalRoleRightMatrixChangedEvent, NodeRestoredFromTrashEvent, ProjectPatchedEvent} from './Events';
import {
  getIsLoadedSelector,
  getTimeRecordSearchKey,
  TimeRecordSearchIdent,
} from './Selectors/TimeRecordSearchSelectors';
import {FlowState} from './State';

//#region Entitiy Reducer
const reducerMap = new Map();
reducerMap.set(
  FLOW_SEARCH_TIME_RECORD_SUCCESS,
  (
    state: FlowState['timeRecordSearch'],
    {key, keys, response}: {key: string; keys: string[]; response: Uuid[] | List<Uuid[]>},
  ) => {
    if (key.startsWith('dateRange.grouped')) {
      const dateRangeKey = key.replace('.grouped', '');
      const keysSet = new Set(keys);
      return Object.entries(response as List<Uuid[]>).reduce<List<Uuid[] | undefined>>(
        (state, [day, ids]) => {
          state[dateRangeKey]!.push(...ids);
          const dayKey = getTimeRecordSearchKey('date', day);
          if (keysSet.has(dayKey)) {
            state[dayKey] = ids;
          }
          return state;
        },
        {...state, [dateRangeKey]: []},
      );
    }
    return mergeStates(state, {[key]: response as Uuid[]});
  },
);

reducerMap.set(
  FLOW_CREATE_TIME_RECORD_REQUEST,
  (
    state: FlowState['timeRecordSearch'],
    {
      day,
      options,
    }: ServerRequestAction<{
      data: TimeRecord;
    }>,
  ) => {
    const data = options?.data;
    if (data) {
      const dateKey = getTimeRecordSearchKey('date', day);
      const userKey = getTimeRecordSearchKey('orgUserId', data.user);
      const dateIds = state[dateKey] || [];
      const userIds = state[userKey] || [];
      return {
        ...state,
        [dateKey]: [...dateIds, data.id],
        [userKey]: [...userIds, data.id],
      };
    }
    return state;
  },
);

export const timeRecordSearchReducer = ReduceFromMap(reducerMap);
//#endregion

//#region State Reducer
const stateReducers = createReducerCollection<EntityStates>({});
stateReducers.add(FLOW_SEARCH_TIME_RECORD_REQUEST, createTimestampReducer('keys', LOADING));
stateReducers.add(FLOW_SEARCH_TIME_RECORD_SUCCESS, createTimestampReducer('keys', LOADED));
stateReducers.add(USER_RIGHTS_CHANGED, () => ({}));
stateReducers.add<NodeRestoredFromTrashEvent>('flow.NodeRestoredFromTrashEvent', (state, action) => {
  return action.timeRecordsRestoredEvents.length ? {} : state;
});
stateReducers.add(
  [
    'flow.GuestRoleRightMatrixChangedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.myAccessibleTimeTrackingsChanged',
  ],
  (): EntityStates => ({}),
);

stateReducers.add(
  'flow.InternalRoleRightMatrixChangedEvent',
  (state: EntityStates, action: InternalRoleRightMatrixChangedEvent): EntityStates => {
    if (action.affectedRightIdents.includes('FLOW_NODE_PROJECT_TIME_TRACKING_READ_OTHERS_FULL')) {
      return {};
    }
    return state;
  },
);

const reducerFactory = createSearchKeyReducerFactory<TimeRecordSearchIdent>();
const clearIdents = reducerFactory();

stateReducers.add<OrgUserGroupsBulkPatched | UserGroupMembersBulkPatched>(
  ['OrgUserGroupsBulkPatched', 'UserGroupMembersBulkPatched'],
  clearIdents(['userGroupId']),
);

stateReducers.add<ProjectPatchedEvent>('flow.ProjectPatchedEvent', (state, action): EntityStates => {
  if (action.patchedKeys.includes('flowCustomer')) {
    return clearIdents(['customerId'])(state, action);
  }
  return state;
});

export const timeRecordSearchStateReducer = stateReducers.reducer;

//#endregion

function rangeToSperateDates(range: string): [DateStr, DateStr] {
  return [asDateStr(range.slice(0, 10)), asDateStr(range.slice(11))];
}

interface SearchRequest {
  key: string;
  keys: string[];
  ident: string;
  value: string;
}

const searchDebounceStack = new Map<string, SearchRequest>();
const searchDebounced = debounce(async (dispatch: Dispatch) => {
  const jobs = [...searchDebounceStack.values()];
  searchDebounceStack.clear();
  const data = jobs.map(({key, ident, value}) => ({key, ident, value}));
  const result = await post<Record<string, string[] | List<Uuid[]>>>(searchTimeTrackingRecordsByIdentBulk, {data});
  dispatch(
    multiAction(jobs.map(({key, keys}) => ({key, keys, response: result[key], type: FLOW_SEARCH_TIME_RECORD_SUCCESS}))),
  );
}, 5);

export function searchTimeRecords(
  searches: ReadonlyArray<[ident: TimeRecordSearchIdent, value?: string]>,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const keysToLoad: string[] = [];
    searches.forEach(([ident, value]) => {
      let key = getTimeRecordSearchKey(ident, value);
      const getIsLoaded = getIsLoadedSelector(getState());
      if (!getIsLoaded(ident, value)) {
        const keys = [key];
        let identToLoad: string = ident;
        if (ident === 'dateRange') {
          const days = getDaysInRange(...rangeToSperateDates(value!), true);
          const daysToLoad = days.filter((day) => !getIsLoaded('date', day));
          keys.push(...daysToLoad.map((day) => getTimeRecordSearchKey('date', day)));
          key = `dateRange.grouped-${value}`;
          identToLoad = `dateRange.grouped`;
        }
        keysToLoad.push(...keys);
        searchDebounceStack.set(key, {key, keys, ident: identToLoad, value: value || ''});
        searchDebounced(dispatch);
      }
    });
    if (keysToLoad.length) {
      dispatch({keys: keysToLoad, type: FLOW_SEARCH_TIME_RECORD_REQUEST});
    }
  };
}
