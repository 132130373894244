import {settingsSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isWithin} from '@octaved/flow/src/TimeTracking/BookTimeDaysTolerance';
import {EntityStates} from '@octaved/store/src/EntityState';
import {asDateTimeStr, DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {TimeStrM, toTimeStrM} from '@octaved/typescript/src/TimeStr';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {isoToUnix, unixToIsoDate} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {
  WorkingTimeEntriesForUserOnDate,
  WorkingTimeEntriesForUsersOnDates,
  WorkingTimeEntry,
  WorkingTimeEntryRevision,
} from '../Interfaces/WorkingTimeEntries';

export function getOrgUserDateStoreKey(userId: Uuid, date: DateStr): string {
  return `${userId}-${date}`;
}

export function getLastRev({revisions}: WorkingTimeEntry): WorkingTimeEntryRevision {
  return revisions[revisions.length - 1];
}

export function getLastRevsOfEntries(entries: ReadonlyArray<WorkingTimeEntry> | undefined): WorkingTimeEntryRevision[] {
  return entries ? entries.map(getLastRev) : [];
}

export function toNonDeletedRevs(revisions: ReadonlyArray<WorkingTimeEntryRevision>): WorkingTimeEntryRevision[] {
  return revisions.filter(({isDeletion}) => !isDeletion);
}

export function getLastNotDeletedRevOfEntries(
  entries: ReadonlyArray<WorkingTimeEntry> | undefined,
): WorkingTimeEntryRevision | null {
  return toNonDeletedRevs(getLastRevsOfEntries(entries)).slice(-1)[0] || null;
}

export function hasOpenEntry(entries: ReadonlyArray<WorkingTimeEntry> | undefined): boolean {
  return !!toNonDeletedRevs(getLastRevsOfEntries(entries)).find(({endDateTime}) => !endDateTime);
}

export function revisionOverlapsWith(
  revision: WorkingTimeEntryRevision,
  startTime: TimeStrM,
  endTime: TimeStrM,
): boolean {
  const entryStartTime = toTimeStrM(revision.startDateTime);
  const entryEndTime = toTimeStrM(revision.endDateTime);
  return entryStartTime < endTime && (entryEndTime || entryStartTime) > startTime;
}

export function getOpenSecondsToAdd(onDate: WorkingTimeEntriesForUserOnDate | undefined, nowUnix: number): number {
  const lastNotDeletedRevOfDay = getLastNotDeletedRevOfEntries(onDate?.entries);
  //Only add the extra "open" time if the open entry is the last one of today:
  if (unixToIsoDate(nowUnix) === onDate?.date && lastNotDeletedRevOfDay?.endDateTime === null) {
    const startUnix = isoToUnix(lastNotDeletedRevOfDay.startDateTime);
    return startUnix < nowUnix ? nowUnix - isoToUnix(lastNotDeletedRevOfDay.startDateTime) : 0;
  }
  return 0;
}

export const workingTimeEntriesForUsersOnDatesSelector = (s: FlowState): WorkingTimeEntriesForUsersOnDates =>
  s.workingTime.entriesForUsersOnDates;
export const workingTimeEntriesForUsersOnDateStateSelector = (s: FlowState): EntityStates =>
  s.workingTime.entriesForUsersOnDatesState;

export const getWorkingTimeEntriesSelector = createSelector(workingTimeEntriesForUsersOnDatesSelector, (onDates) =>
  memoize(
    (userId: Uuid, date: DateStr): WorkingTimeEntriesForUserOnDate | undefined =>
      onDates[getOrgUserDateStoreKey(userId, date)],
    (userId: Uuid, date: DateStr) => `${userId}+${date}`,
  ),
);

export const getWorkingTimeEntriesOnDatesSelector = createSelector(
  getWorkingTimeEntriesSelector,
  (getWorkingTimeEntriesForUserOnDate) =>
    memoize(
      (userId: Uuid, dates: ReadonlyArray<DateStr>): ReadonlyArray<WorkingTimeEntriesForUserOnDate> =>
        boolFilter(dates.map((date) => getWorkingTimeEntriesForUserOnDate(userId, date))),
      (userId: Uuid, dates: ReadonlyArray<DateStr>) => `${userId}++${dates.toSorted().join('+')}`,
    ),
);

const emptyEntries: WorkingTimeEntry[] = [];

export const getNonDeletedWorkingTimeEntriesForUserOnDateSelector = createSelector(
  getWorkingTimeEntriesSelector,
  (getWorkingTimeEntries) =>
    memoize(
      (userId: Uuid, date: DateStr): ReadonlyArray<WorkingTimeEntry> => {
        return (getWorkingTimeEntries(userId, date)?.entries || emptyEntries).filter(
          (entry) => !getLastRev(entry).isDeletion,
        );
      },
      (userId: Uuid, date: DateStr) => `${userId}++${date}`,
    ),
);

export const canManageWorkingTimeEntrySelector = createSelector(
  isGrantedSelector,
  currentOrgUserIdSelector,
  settingsSelector,
  (isGranted, currentOrgUserId, settings) =>
    memoize(
      (userId: Uuid, date: DateStr) => {
        const isCurrentUser = currentOrgUserId === userId;
        const canManage = isGranted(
          isCurrentUser
            ? 'FLOW_GLOBAL_WORKING_TIME_TRACKING_MANAGE_OWN'
            : 'FLOW_GLOBAL_WORKING_TIME_TRACKING_MANAGE_OTHER',
        );
        const canExceedTolerance = isGranted('FLOW_GLOBAL_WORKING_TIME_TRACKING_MANAGE_NON_BOOKABLE');
        return canManage && (isWithin(settings, asDateTimeStr(`${date} 00:00:00`), null) || canExceedTolerance);
      },
      (userId: Uuid, date: DateStr) => `${userId}+${date}`,
    ),
);
