import {DeepPartial} from '@octaved/typescript/src/lib';
import {PickerState} from '@octaved/ui';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {FilterValue} from '@octaved/working-time/src/WorkingTimeWarning/Warnings';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';

export type WorkingTimeExportType = 'withSubtotals' | 'continuousList' | 'perUserOneRow';

export interface WorkingTimeMonitoringUiState {
  export: {
    type: WorkingTimeExportType;
    withAbsences: boolean;
  };
  selectedUnits: SlimUnit[];
  timeFrame: PickerState;
  timePeriodPage: {
    warningFilter: FilterValue;
  };
  userPage: {
    warningFilter: FilterValue;
  };
}

export const initialWorkingTimeMonitoringUiState: WorkingTimeMonitoringUiState = {
  export: {
    type: 'withSubtotals',
    withAbsences: true,
  },
  selectedUnits: [],
  timeFrame: {
    dateFrom: null,
    dateTo: null,
    preset: 'last7days',
  },
  timePeriodPage: {
    warningFilter: null,
  },
  userPage: {
    warningFilter: null,
  },
};

export function patchWorkingTimeMonitoringUiState(
  workingTimeMonitoring: DeepPartial<WorkingTimeMonitoringUiState>,
): ThunkAct<void> {
  return setUiState({pages: {workingTimeMonitoring}});
}

export function patchWorkingTimeMonitoringTimePeriodWarningFilter(warningFilter: FilterValue): ThunkAct<void> {
  return setUiState({pages: {workingTimeMonitoring: {timePeriodPage: {warningFilter}}}});
}

export function patchWorkingTimeMonitoringTimeFrame(timeFrame: Partial<PickerState>): ThunkAct<void> {
  return setUiState({pages: {workingTimeMonitoring: {timeFrame}}});
}

export function patchWorkingTimeMonitoringUserWarningFilter(warningFilter: FilterValue): ThunkAct<void> {
  return setUiState({pages: {workingTimeMonitoring: {userPage: {warningFilter}}}});
}
