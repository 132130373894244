import useKeyboardEventListener from '@octaved/ui-components/src/Hooks/UseKeyboardEventListener';
import classNames from 'classnames';
import {Check} from 'lucide-react';
import {ReactElement} from 'react';
import {Input, InputProps} from 'semantic-ui-react';
import {IconButton} from '../Button';

interface Props extends InputProps {
  hasChanges?: boolean;
}

function InlineEditInput({hasChanges, ...props}: Props): ReactElement {
  let ic: ReactElement | null = null;
  if (hasChanges) {
    ic = (
      <IconButton
        size={'sm'}
        colorScheme={'green'}
        icon={<Check />}
        iconColor={'white'}
        iconStrokeWidth={1.5}
        className={'inlineEditInputIcon'}
      />
    );
  }

  const onKeyDownListener = useKeyboardEventListener<HTMLInputElement>({
    Enter: (e) => {
      e.currentTarget.blur();
    },
  });

  return (
    <Input
      {...props}
      className={classNames('inlineEditInput', props.className, {hasChanges})}
      icon={ic}
      onKeyDown={onKeyDownListener}
    />
  );
}

export {InlineEditInput};
