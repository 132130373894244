import {ReactElement, ReactNode} from 'react';

export default function TaskListStyle({children}: {children: ReactNode}): ReactElement {
  return (
    <div className={'taskList'}>
      {children}
      {/*language=scss*/}
      <style jsx>{`
        .taskList {
          --drag-handle-width: 24px;
          --row-height: 37px;

          --cell-padding: 8px;
          --cell-width-normal: 150px;
          --cell-width-wider: 186px;
          --cell-width-smaller: 120px;

          --check-circle-size: 18px;
          --check-circle-spacing: 1px;

          --done-opacity: 0.4;

          --color-bg: var(--color-white);
          --color-text: var(--color-grey-900);
          --color-text-lighter: var(--color-grey-600);

          --cell-border: var(--color-grey-100);

          --color-border-hover: var(--color-grey-200);
          --color-border-focus: var(--color-blue-400);
          --color-hover-bg: var(--color-grey-50);
          --color-selectedBg: var(--color-blue-50);

          --color-error: var(--color-red-700);
          --color-success: var(--color-green-700);

          --color-task-status-border: var(--color-grey-200);
          --color-task-status-done: var(--color-green-500);
        }
      `}</style>
    </div>
  );
}
