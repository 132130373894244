import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useContext, useState} from 'react';
import {flowStyleContext} from '../../../Styles/StyleContext';
import DialogAndDrawerHeader from '../../Layout/DialogAndDrawerHeader';
import {ProjectTreeTaskListContextReset} from '../../ProjectTree/ProjectTreeTaskListContext';
import ProjectList from '../DialogComponents/ProjectList';
import TaskPreview from '../DialogComponents/TaskPreview';
import Footer from './Footer';

interface Props {
  close: () => void;
  workPackageId: Uuid;
}

export default function Content({close, workPackageId}: Props): ReactElement {
  const {
    COLORS: {BACKGROUND},
  } = useContext(flowStyleContext);
  const [selectedPid, selectPid] = useState<Uuid | null>(null);

  return (
    <div className={'selectWorkpackage'}>
      <div className={'selectPanel'}>
        <div className={'preview'}>
          <TaskPreview
            nodeId={workPackageId}
            headline={<DialogAndDrawerHeader header={'pages:workspace.tasks.moveTasks.taskPreviewTitle'} />}
          />
        </div>
        <div className={'projects'}>
          <ProjectTreeTaskListContextReset>
            <ProjectList selectPid={selectPid} selectedPid={selectedPid} />
          </ProjectTreeTaskListContextReset>
        </div>
      </div>

      <Footer selectedPid={selectedPid} close={close} workPackageId={workPackageId} />

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .selectWorkpackage {
          position: relative;
          display: flex;
          flex-direction: column;
          height: calc(100vh - 180px);
          margin: -43px -20px 0 -20px;
          z-index: 0;
        }

        .selectPanel {
          display: flex;
          flex-direction: row;
          flex: 1;
          height: 1%;
        }

        .projects,
        .preview {
          height: 100%;
          overflow-y: auto;
          padding: 20px;
          width: 50%;
        }

        .preview {
          background-color: ${BACKGROUND.ALTERNATIVE};
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
