import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {dowoadBlob} from '@octaved/hooks/src/DownloadBlob';
import {IconButton} from '@octaved/ui';
import {Download} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {timeSheetExportsConfigSelector} from '@octaved/users/src/Selectors/OrgUserSettingSelectors';
import {ExportDetails} from '../ExportDetails';

interface Props {
  exportDetails: ExportDetails;
  label: string;
  route: string;
  type: 'excelTimeSheet' | 'pdfTimeSheet';
}

export default function DownloadButton({exportDetails, label, route, type}: Props): ReactElement {
  const exportsConfig = useSelector(timeSheetExportsConfigSelector);
  const [download, isDownloading] = useAsyncExecution(() =>
    dowoadBlob(
      route,
      {},
      {
        columns: exportsConfig?.[type]?.columns,
        grouping: exportsConfig?.[type as 'excelTimeSheet']?.grouping,
        projectId: exportDetails.projectId,
        timeTrackingRecordIds: exportDetails.timeRecordIds,
        workPackageIds: exportDetails.workPackageIds,
      },
      {method: 'POST'},
    ),
  );

  return (
    <IconButton
      icon={<Download />}
      onClick={download}
      isDisabled={isDownloading}
      loading={isDownloading}
      label={label}
    />
  );
}
