import {ReactElement, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  defaultProjectsTreeComponentContext,
  projectsTreeComponentContext,
  ProjectsTreeComponentContext,
} from '../../../../Components/Tree/ProjectTreeComponentContext';
import {useProject} from '../../../../Modules/Hooks/Nodes';
import {isProject} from '../../../../Node/NodeIdentifiers';
import {projectsRearrangeProjectSelector} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {rearrangeProject} from '../../../../Modules/UiPages/Projects';
import DndGroup from './DndGroup';
import DndGroupAndWorkPackages from './DndGroupAndWorkPackages';
import DndWorkPackage from './DndWorkpackage';
import Header from './Header';
import RearrangeProjectTree from './RearrangeProjectTree';

export default function RearrangeProject(): ReactElement | null {
  const pid = useSelector(projectsRearrangeProjectSelector);
  const project = useProject(pid);

  const componentContext = useMemo<ProjectsTreeComponentContext>(
    () => ({
      ...defaultProjectsTreeComponentContext,
      group: DndGroup,
      groupsAndWorkPackages: DndGroupAndWorkPackages,
      workPackage: DndWorkPackage,
    }),
    [],
  );
  const dispatch = useDispatch();

  if (isProject(project)) {
    return (
      <div className={'rearrangeProjects'}>
        <Header project={project} />
        <div className={'content'}>
          <projectsTreeComponentContext.Provider value={componentContext}>
            <RearrangeProjectTree projectId={project.id} />
          </projectsTreeComponentContext.Provider>
        </div>

        {/*language=SCSS*/}
        <style jsx>{`
          .rearrangeProjects {
            display: flex;
            align-items: stretch;
            height: 100%;
            flex-direction: column;
          }

          .content {
            flex-grow: 1;
            overflow-y: scroll;
            padding: 20px 30px;
          }
        `}</style>
      </div>
    );
  }

  dispatch(rearrangeProject(null));
  return null;
}
