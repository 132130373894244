import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {nodeSearchSelector} from '../Selectors/NodeSearchSelectors';
import {FlowState} from '../State';

export function rootReduceSearchResults(state: FlowState, nextResults: UuidSearchResults): FlowState {
  const prevResults = nodeSearchSelector(state);
  return prevResults !== nextResults
    ? {...state, nodeSearch: {...state.nodeSearch, searchResults: nextResults}}
    : state;
}
