interface Options {
  skipUnauthorized: boolean;
}

export const unauthorized = {
  catch: (response: Response, options: Options): Promise<Response> => {
    // check for TypeMismatchError as Edge does not return a response in case of an 401
    // @ts-ignore name dows not exists on response
    if ((response.status === 401 || response.name === 'TypeMismatchError') && !options.skipUnauthorized) {
      // When unauthorized, open the editor login dialog to log in again.
      // The promise is never fulfilled, because this will stop further execution.
      // The editor login dialog is reloading or redirecting the page anyway.
      window.location.reload();
    }
    return Promise.reject(response);
  },
};
