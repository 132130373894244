import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';

import {AuditGroup} from '../../../../EntityInterfaces/Audit/AuditGroup';
import DateStringCell from '../Table/Cells/DateStringCell';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import type {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {pidColumns} from './Pid';

const groupTypeTranslations = new Map<EnumFlowGroupType, string>([
  [EnumFlowGroupType.VALUE_GROUP, 'pages:projects.inspector.manage.general.groupTypes.group'],
  [EnumFlowGroupType.VALUE_SPRINT, 'pages:projects.inspector.manage.general.groupTypes.sprint'],
  [EnumFlowGroupType.VALUE_BACKLOG, 'pages:projects.inspector.manage.general.groupTypes.backlog'],
]);

const config: AuditTableConfiguration<AuditGroup> = {
  columns: [
    ...pidColumns,
    createColumnDef('plannedStart', 'systemSettings:audit.tables.group.plannedStartHeader', DateStringCell),
    createColumnDef('plannedEnd', 'systemSettings:audit.tables.group.plannedEndHeader', DateStringCell),
    createColumnDef('groupType', 'systemSettings:audit.tables.group.groupTypeHeader', ({cell: {value}}) => {
      return <EnumTranslationCell value={value} translationMapping={groupTypeTranslations} />;
    }),
  ],
  tableIdent: 'groups',
  tableNameTranslationToken: 'systemSettings:audit.groupTableName',
};
export default config;
