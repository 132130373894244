import {EntityState, hasLoadedOnce} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {FlowState} from '../../State';

export const emptyIds: Uuid[] = [];

export const deprecatedUserIdsSelector = (state: FlowState): Uuid[] => state.deprecatedUserIds.data;
export const deprecatedUserIdsSetSelector = createSelector(deprecatedUserIdsSelector, (ids) => new Set(ids));
export const deprecatedUserIdsStateSelector = (state: FlowState): EntityState => state.deprecatedUserIds.state;
export const deprecatedUserIdsLoadedOnceSelector = createSelector(deprecatedUserIdsStateSelector, (state) => {
  return !!state && hasLoadedOnce(state);
});
