import {Icon} from '@octaved/ui';
import {PlayCircle} from 'lucide-react';
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react';

interface Props {
  videoIdentifier: string;
  videoUrl: string;
}

export default function PlayVideo({videoIdentifier, videoUrl}: Props): ReactElement {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const isEnded = useRef(false);
  const prefix = `tutorialVideosPages-${videoIdentifier}-video-`;
  const onEvent = useCallback((name: string) => window.heap?.track(`${prefix}${name}`), [prefix]);

  const onReplay = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setShowPlayButton(false);
    }
  }, []);

  useEffect(() => {
    onEvent('play');

    return () => {
      if (!isEnded.current) {
        onEvent('aborted');
      }
    };
  }, [onEvent]);

  return (
    <>
      <video
        src={videoUrl}
        autoPlay
        playsInline
        muted
        onEnded={() => {
          setShowPlayButton(true);
          isEnded.current = true;
        }}
        ref={videoRef}
        controls
      />
      {showPlayButton && (
        <div className={'playOverlay'} onClick={onReplay} data-analytics-id={`${prefix}replayed`}>
          <Icon iconColor={'white'} size={'massive'}>
            <PlayCircle strokeWidth={'1'} />
          </Icon>
        </div>
      )}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        video {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          overflow: hidden;
          background-color: #fff;
        }

        .playOverlay {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          top: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          & :global(svg) {
            transition: transform 120ms ease-in-out;
          }

          &:hover :global(svg) {
            transform: scale(1.4);
          }
        }
      `}</style>
      {/*language=scss*/}
    </>
  );
}
