import {Variants} from 'framer-motion';
import {ReactElement} from 'react';
import Pop, {PopProps} from '../Pop';

const variants: Variants = {
  hidden: {marginTop: -20, opacity: 0, transition: {duration: 0.12}},
  visible: {marginTop: 0, opacity: 1, transition: {duration: 0.12}},
};

type PopdownProps = Omit<PopProps, 'variants'>;

const Popdown = function Popdown(props: PopdownProps): ReactElement {
  return (
    <Pop {...props} variants={variants} open={props.open} setOpen={props.setOpen}>
      {props.children}
    </Pop>
  );
};

export {Popdown};
