import {cn, Icon, Tooltip} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ShieldCheck} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export interface ReliabilityMeterTokens {
  all?: string; //separate text for 100%
  default: string;
  toolTip?: string;
  zero?: string; //separate text for 0%
}

export interface ReliabilityMeterProps {
  className?: string;
  count: number;
  hideZero?: boolean;
  tokens: ReliabilityMeterTokens;
  total: number;
}

export function ReliabilityMeter({
  className,
  count,
  hideZero,
  tokens,
  total,
}: ReliabilityMeterProps): ReactElement | null {
  const {t} = useTranslation();
  const percentage = total > 0 ? count / total : 0;
  let label = tokens.zero || tokens.default;
  let formattedPercentage = '0%';
  let greenDots = 0;
  if (percentage === 1) {
    label = tokens.all || tokens.default;
    formattedPercentage = '100%';
    greenDots = 3;
  } else if (percentage >= 0.9) {
    label = tokens.default;
    formattedPercentage = '>90%';
    greenDots = 2;
  } else if (percentage >= 0.5) {
    label = tokens.default;
    formattedPercentage = '>50%';
    greenDots = 1;
  } else if (percentage > 0) {
    label = tokens.default;
    formattedPercentage = '<50%';
  }

  if (hideZero && !percentage) {
    return null;
  }

  return (
    <div className={cn('flex items-center gap-x-2', className)}>
      <Tooltip toolTipTranslation={tokens.toolTip}>
        <div className={'flex items-center'}>
          <Icon noMargin iconColor={'white'} size={'larger'}>
            <ShieldCheck fill={greenDots >= 1 ? '#3db372' : '#e7eaee'} strokeWidth={1.5} />
          </Icon>
          <Icon noMargin iconColor={'white'} size={'larger'}>
            <ShieldCheck fill={greenDots >= 2 ? '#3db372' : '#e7eaee'} strokeWidth={1.5} />
          </Icon>
          <Icon noMargin iconColor={'white'} size={'larger'}>
            <ShieldCheck fill={greenDots >= 3 ? '#3db372' : '#e7eaee'} strokeWidth={1.5} />
          </Icon>
        </div>
      </Tooltip>
      <div className={'label'}>
        {t(label, {
          count,
          countFormatted: formatDecimal(count),
          percentage: formattedPercentage,
          total: formatDecimal(total),
        })}
      </div>
    </div>
  );
}
