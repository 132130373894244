import SimulatedPlanningStore from '@octaved/planning/src/Components/SimulatedPlanningStore/SimulatedPlanningStore';
import {ReactElement, lazy} from 'react';
import FlowOutlet from '../../../Components/FlowOutlet';
import {useInspectorContext} from '../../../Drawer/InspectorContext/InspectorContext';
import {isGroup, isProject, isSubWorkPackage, isTask, isWorkPackage} from '../../../Node/NodeIdentifiers';

const SpecialPlanningWorkPackage = lazy(
  () => import('@octaved/node-details/src/InspectorView/Views/WorkPackage/SpecialPlanning'),
);

const SpecialPlanningSubWorkPackage = lazy(
  () => import('@octaved/node-details/src/InspectorView/Views/SubWorkPackage/SpecialPlanning'),
);
const SpecialPlanningTask = lazy(() => import('@octaved/node-details/src/InspectorView/Views/Task/SpecialPlanning'));
const SpecialPlanningGroup = lazy(() => import('@octaved/node-details/src/InspectorView/Views/Group/SpecialPlanning'));
const SpecialPlanningProject = lazy(
  () => import('@octaved/node-details/src/InspectorView/Views/Project/SpecialPlanning'),
);

export default function SpecialPlanningInspector(): ReactElement | null {
  const context = useInspectorContext();
  if (context.selectedEntity) {
    return (
      <SimulatedPlanningStore>
        {isWorkPackage(context.selectedEntity) && <SpecialPlanningWorkPackage node={context.selectedEntity} />}
        {isSubWorkPackage(context.selectedEntity) && <SpecialPlanningSubWorkPackage node={context.selectedEntity} />}
        {isTask(context.selectedEntity) && <SpecialPlanningTask node={context.selectedEntity} />}
        {isGroup(context.selectedEntity) && <SpecialPlanningGroup node={context.selectedEntity} />}
        {isProject(context.selectedEntity) && <SpecialPlanningProject node={context.selectedEntity} />}
        <FlowOutlet />
      </SimulatedPlanningStore>
    );
  }
  return null;
}
