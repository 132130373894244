import {getNodeColorSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {getDarkenColor} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import {BarRendererPlugin} from './BarRendererPlugin';

export class WorkPackageBarRenderPlugin extends BarRendererPlugin {
  protected getStyleColor(state: FlowState): string {
    return getNodeColorSelector(state)(this.nodeId);
  }

  protected style(wpColor: string): void {
    const topColor = getDarkenColor(wpColor, 5);

    this.rect.fillLinearGradientColorStops([0, `${topColor}`, 1, `#${wpColor}`]);
    this.rect.fillLinearGradientStartPoint({x: 0, y: this.bar.height});
    this.rect.fillLinearGradientEndPoint({x: this.bar.width, y: 0});
    this.rect.stroke(`#${wpColor}`);
  }

  onBarUpdated(): void {
    super.onBarUpdated();
    this.rect.fillLinearGradientStartPoint({x: 0, y: this.bar.height});
    this.rect.fillLinearGradientEndPoint({x: this.bar.width, y: 0});
  }
}
