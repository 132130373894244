import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {HStack} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement, useState} from 'react';
import {useDefaultProjectTreeOptions} from '../../../Modules/Projects/DefaultProjectTree';
import {ProjectTreeOptions} from '../../../Modules/Projects/ProjectTreeInterfaces';
import Search from '../../Form/Search/Search';
import DialogAndDrawerHeader from '../../Layout/DialogAndDrawerHeader';
import ProjectTree from '../../ProjectTree/ProjectTree';

interface Props {
  selectedPid: Uuid | null;
  selectPid: (s: Uuid | null) => void;
  treeOptions?: Partial<ProjectTreeOptions>;
}

export default function ProjectList({selectedPid, selectPid, treeOptions}: Props): ReactElement {
  const [search, setSearch] = useState('');
  const defaultTreeOptions = useDefaultProjectTreeOptions();
  return (
    <>
      <DialogAndDrawerHeader header={'pages:workspace.tasks.copyTasks.projectsTitle'} />
      <HStack>
        <div>
          <Search onEnterKey={setSearch} onLeave={setSearch} />
        </div>
      </HStack>
      <Spacer />
      <ProjectTree
        treeOptions={{
          ...defaultTreeOptions,
          hideEmptyParents: true,
          searchTerm: search,
          selectableNodeTypes: EnumFlowNodeType.VALUE_WORK_PACKAGE,
          selectedNodeId: selectedPid,
          selectNodeId: selectPid,
          ...(treeOptions || {}),
        }}
      />
    </>
  );
}
