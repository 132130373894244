import {mergeStates} from '@octaved/store/src/MergeStates';
import {DeepImmutable, DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {generateUuid} from '@octaved/utilities';
import {useCallback, useRef, useState} from 'react';

export type PatchableState<T extends object> = [
  T,
  (partial: DeepPartial<T> | ((obj: DeepImmutable<T>) => DeepPartial<T>)) => void,
  DeepImmutable<{current: T}>,
];

/**
 * Just like useState(), but returns a patch-method instead of a setState method.
 */
export function usePatchableState<T extends object>(initial: T | (() => T)): PatchableState<T> {
  const [obj, setObj] = useState<T>(initial);
  const objRef = useRef<T>(obj);
  const patch = useCallback((partial: DeepPartial<T> | ((obj: DeepImmutable<T>) => DeepPartial<T>)) => {
    const part = typeof partial === 'function' ? partial(objRef.current as DeepImmutable<T>) : partial;
    const newObj = mergeStates(objRef.current, part);
    objRef.current = newObj;
    setObj(newObj);
  }, []);
  return [obj, patch, objRef as DeepImmutable<{current: T}>];
}

export function useNewPatchableStateForm<T extends object>(
  initial: T,
  doSave: (id: Uuid, data: DeepImmutable<T>) => Promise<boolean> | boolean,
  onSuccess: () => void,
): {
  form: PatchableState<T>[0];
  formRef: PatchableState<T>[2];
  id: Uuid;
  isSaving: boolean;
  patch: PatchableState<T>[1];
  save: () => void;
} {
  const [id] = useState(generateUuid);
  const [form, patch, formRef] = usePatchableState<T>(initial);
  const [isSaving, setSaving] = useState(false);
  const isSavingRef = useRef(false);
  const save = useCallback(async () => {
    if (isSavingRef.current) {
      return;
    }
    isSavingRef.current = true;
    setSaving(true);
    let success = false;
    try {
      success = await doSave(id, formRef.current);
    } finally {
      isSavingRef.current = false;
      setSaving(false);
    }
    if (success) {
      onSuccess();
    }
  }, [onSuccess, doSave, formRef, id]);
  return {
    form,
    formRef,
    id,
    isSaving,
    patch,
    save,
  };
}
