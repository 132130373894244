import Konva from 'konva';
import {RootContainer} from '../RootContainer';

export default class TodayLine extends RootContainer {
  onCalenderDatesChanged(): void {
    this.root.destroyChildren();

    this.#createTodayLine();
  }

  #createTodayLine(): void {
    const diff = this.ctx.today.diff(this.ctx.calendarView.dateStart, 'd');
    const x = this.ctx.columnWidth * diff + this.ctx.columnWidth * 0.5;

    this.root.add(
      new Konva.Line({
        hitStrokeWidth: 0,
        listening: false,
        offsetX: -0.5,
        perfectDrawEnabled: false,
        points: [x, 0, x, this.ctx.canvasView.maxHeight],
        shadowForStrokeEnabled: false,
        stroke: '#aa18ff',
        strokeScaleEnabled: false,
        strokeWidth: 1,
      }),
    );
  }
}
