import {AnimatePresence, motion, useAnimationControls, Variants} from 'framer-motion';
import {ReactElement, ReactNode, useEffect} from 'react';
import {cn} from '../../Hooks';

const parentVariants: (collapsedWidth: string, defaultWidth: string) => Variants = (collapsedWidth, defaultWidth) => ({
  collapsed: {width: collapsedWidth, overflow: 'hidden', transition: {duration: 0.24}},
  default: {width: defaultWidth, transition: {duration: 0.24}, transitionEnd: {overflow: 'inherit'}},
});

const contentVariants: Variants = {
  collapsed: {opacity: 0, transition: {duration: 0.24}},
  default: {opacity: 1, transition: {duration: 0.24}},
};

interface Props {
  children: ReactNode;
  collapsed: boolean;
  collapsedWidth: string;
  collapsibleIcon: ReactElement;
  defaultWidth: string;
  isFixedHeight?: boolean;
  reversed?: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export default function VerticalCollapsible({
  children,
  collapsed,
  collapsedWidth,
  collapsibleIcon,
  defaultWidth,
  isFixedHeight,
  reversed,
  setCollapsed,
}: Props): ReactElement {
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start(collapsed ? 'collapsed' : 'default');
  }, [collapsed, controls]);

  useEffect(() => {
    if (!collapsed) {
      controls.start({width: defaultWidth, transition: {duration: 0.24}});
    }
  }, [defaultWidth, controls, collapsed]);

  return (
    <AnimatePresence>
      <motion.div
        initial={collapsed ? 'collapsed' : 'default'}
        variants={parentVariants(collapsedWidth, defaultWidth)}
        animate={controls}
        onClick={() => collapsed && setCollapsed(false)}
        className={cn(
          'max-h-full flex-grow overflow-y-auto',
          isFixedHeight && 'h-full',
          collapsed && 'cursor-pointer transition-colors hover:bg-slate-100',
        )}
      >
        <motion.div variants={contentVariants} className={cn('h-full', collapsed && 'pointer-events-none')}>
          {children}
        </motion.div>
        <div
          className={cn(
            'pointer-events-none sticky bottom-1 flex justify-end px-2 transition-all',
            collapsed && 'justify-center px-0',
            reversed && 'justify-start',
          )}
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className={'pointer-events-auto'}>{collapsibleIcon}</div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
