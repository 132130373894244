import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import useKeyboardEventListener from '@octaved/ui-components/src/Hooks/UseKeyboardEventListener';
import classNames from 'classnames';
import {CheckCircle2, Circle} from 'lucide-react';
import {ReactElement, useCallback} from 'react';
import {useMarkParentTasksDoneWorkflowControlModal} from '../../Dialogs/WorkflowControl/MarkParentTasksDoneWorkflowControl';
import {TaskPatchData} from '../../EntityInterfaces/Task';
import {useCanChangeTaskStatus} from '../../Modules/Tasks';
import {useTaskListContext} from './TaskListContext';
import {ShallowTask} from './TaskListForNode/Task/ShallowTask';

interface Props {
  patch: (data: Partial<TaskPatchData>) => void;
  readonly: boolean;
  task: ShallowTask;
  inline?: boolean;
}

export default function TaskStatus({patch, readonly: parentReadonly, task, inline}: Props): ReactElement {
  const {displayDoneTasksAsOpen} = useTaskListContext();
  const {confirmCheckedModal, setChecked} = useMarkParentTasksDoneWorkflowControlModal(task.id, (checked) => {
    patch({status: checked ? EnumFlowTaskStatus.VALUE_COMPLETE : EnumFlowTaskStatus.VALUE_OPEN});
  });
  const onToggleDone = useCallback(
    async (e): Promise<void> => {
      e.stopPropagation();
      await setChecked(task.status === EnumFlowTaskStatus.VALUE_OPEN);
    },
    [setChecked, task.status],
  );
  const onKeyDown = useKeyboardEventListener({
    Enter: onToggleDone,
  });
  const canChange = useCanChangeTaskStatus(task.id);
  const readonly = parentReadonly || !canChange;

  return (
    <>
      {!readonly && confirmCheckedModal}
      <div
        className={classNames('status', {
          inline,
          readonly,
          complete: !displayDoneTasksAsOpen && task.status === EnumFlowTaskStatus.VALUE_COMPLETE,
          open: task.status === EnumFlowTaskStatus.VALUE_OPEN,
        })}
        onClick={readonly ? undefined : onToggleDone}
        tabIndex={0}
        onKeyDown={readonly ? undefined : onKeyDown}
      >
        {displayDoneTasksAsOpen || task.status === EnumFlowTaskStatus.VALUE_OPEN ? <Circle /> : <CheckCircle2 />}
      </div>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .status {
          cursor: pointer;
          margin: -3px 1px;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-task-status-border);
          transition: color 170ms ease-in-out;
        }

        .inline {
          display: inline-flex;
          margin: 0;
          padding: 0;
          height: 16px;
          width: 16px;
          vertical-align: -0.185rem;
        }

        .readonly {
          cursor: default;
          pointer-events: none;
        }

        .status :global(svg) {
          width: var(--check-circle-size);
          height: var(--check-circle-size);
          transition: fill 170ms ease-in-out;
        }

        .status.open:hover,
        .status.open:focus {
          color: var(--color-task-status-done);
        }

        .status.complete {
          color: var(--color-bg);

          & :global(svg) {
            fill: var(--color-task-status-done);

            & :global(path:first-child) {
              stroke: var(--color-task-status-done);
            }
          }
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
