import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {workdays} from '../../../../Calculations/WorkdayCalculations';
import {
  calculateMinMaxPlanningDatesInSubtreeSelector,
  getMinMaxPlanningDatesSelector,
} from '../../../../Selectors/PlanningDateSelectors';
import {createStoreSubscription} from '../../../StoreSubscription';
import {SimpleTextCell} from './SimpleTextCell';

export class DurationCell extends SimpleTextCell {
  protected nodeEntity: NodeEntity | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (state) => getMinMaxPlanningDatesSelector(state)(this.nodeEntity?.id),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (state) => {
          if (!this.nodeEntity) {
            return null;
          }
          return calculateMinMaxPlanningDatesInSubtreeSelector(state)(this.nodeEntity.id);
        },
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
      createStoreSubscription(this.ctx.store, getOrgWorkMinutesAtDateSelector, () => {
        this.render();
      }),
    );
  }

  protected getText(): string {
    if (this.nodeEntity) {
      let minMax;
      if (isTask(this.nodeEntity) || isWorkPackage(this.nodeEntity)) {
        minMax = getMinMaxPlanningDatesSelector(this.ctx.store.getState())(this.nodeEntity.id);
      } else {
        minMax = calculateMinMaxPlanningDatesInSubtreeSelector(this.ctx.store.getState())(this.nodeEntity.id);
      }

      if (minMax && minMax.plannedStart && minMax.plannedEnd) {
        const getWorkingTimeAtDate = getOrgWorkMinutesAtDateSelector(this.ctx.store.getState());
        const days = workdays(
          fromIsoDateTimeFormat(minMax.plannedStart),
          fromIsoDateTimeFormat(minMax.plannedEnd),
          getWorkingTimeAtDate,
        );
        return this.ctx.t('general:date.days', {
          count: days,
          countFormatted: formatDecimal(days),
        });
      }
    }
    return '';
  }
}
