import {TWAutosaveInput} from '@octaved/ui';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from './Type';

export interface NodeNameProps {
  name: string;
  readonly?: boolean;
  setName: (name: string) => void;
}

export default function NodeName({
  frame: Frame,
  readonly,
  name,
  setName,
}: NodeNameProps & FramedSectionOrTileProps): ReactElement {
  return (
    <Frame labelToken={'nodeDetails:field.name.label'}>
      {!readonly && <TWAutosaveInput value={name} onSave={setName} requiredUntouched />}
      {readonly && <div>{name}</div>}
    </Frame>
  );
}
