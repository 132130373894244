import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useOnOutsideClick} from '@octaved/hooks/src/OnOutsideClick';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {Check} from 'lucide-react';
import {ReactElement, useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {TaskCreationData} from '../../../../EntityInterfaces/Task';
import {getTaskDepthSelector} from '../../../../Modules/Selectors/TaskSelectors';
import {FlowState} from '../../../../Modules/State';
import {createTask, getDefaultTaskCreationData} from '../../../../Modules/Tasks';
import {useTaskListContext} from '../../TaskListContext';
import {OnEnterKeyDownOptions} from '../EditName';
import {useNewRowFeatures} from '../NewItemHooks';
import {NewSortOrder} from '../NewSortOrder';
import NameCell from './TaskCell/NameCell';
import ShowSubTaskToggle from './TaskCell/ShowSubTaskToggle';

export interface NewTaskRowOnEnterKeyDown {
  (options: OnEnterKeyDownOptions & {task: TaskCreationData}): void;
}

interface Props {
  hasAnySubTasks: boolean;
  onEnterKeyDown?: NewTaskRowOnEnterKeyDown;
  onFinish: () => void;
  parentId: string;
  previousSiblingId: Uuid | undefined;
}

export default function NewTaskRow({
  hasAnySubTasks,
  onEnterKeyDown,
  parentId,
  onFinish,
  previousSiblingId,
}: Props): ReactElement {
  const parentDepth = useSelector((s: FlowState) => getTaskDepthSelector(s)(parentId));
  const [depth, setDepth] = useState(parentDepth);
  const createAtParentId = previousSiblingId && depth === parentDepth + 1 ? previousSiblingId : parentId;
  const {visibleColumns} = useTaskListContext();
  const colSpan = visibleColumns.size;
  const {useOnBeforeCreateTask} = useTaskListContext();
  const onBeforeCreateTask = useOnBeforeCreateTask();

  const {patch, refs, save, snapshot} = useNewRowFeatures<TaskCreationData>(
    getDefaultTaskCreationData,
    createTask,
    createAtParentId,
    depth === parentDepth ? previousSiblingId : NewSortOrder.last,
    EnumFlowNodeType.VALUE_TASK,
    useCallback(
      (task) => onBeforeCreateTask(task, {parentId: createAtParentId}),
      [onBeforeCreateTask, createAtParentId],
    ),
  );

  //Save and refocus the newly created task
  const saveAndExit = useCallback<(data?: Partial<TaskCreationData>) => void>(
    (data) => {
      save(data);
      onFinish();
    },
    [onFinish, save],
  );

  const onOutsideClickRef = useRef<HTMLDivElement>(null);
  useOnOutsideClick(onOutsideClickRef, saveAndExit);

  const enhancedOnEnterKeyDown = useCallback(
    (options: OnEnterKeyDownOptions) => {
      if (onEnterKeyDown) {
        onEnterKeyDown({
          ...options,
          task: refs.snapshot.current,
        });
      }
      save();
    },
    [onEnterKeyDown, refs.snapshot, save],
  );

  const patchName = useCallback((name: string) => patch({name}), [patch]);

  const tabListener = {
    Escape: onFinish,
    Tab: () => {
      if (previousSiblingId && parentDepth === 0) {
        setDepth(depth === parentDepth ? 1 : 0);
      }
    },
  };

  return (
    <div className={'taskRow'} ref={onOutsideClickRef}>
      <NameCell
        autoFocus
        depth={depth}
        extendKeyDownMap={tabListener}
        name={snapshot.name}
        onBlur={saveAndExit}
        onEnterKeyDown={enhancedOnEnterKeyDown}
        patch={patch}
        patchName={patchName}
        rightStruct={<IconButton colorScheme={'add'} size={'xs'} icon={<Check />} iconStrokeWidth={'2'} />}
        toggle={hasAnySubTasks && <ShowSubTaskToggle hasSubTasks={false} />}
      />
      <div className={'rightStructure'} />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .taskRow {
          height: var(--row-height);
          transition: background-color 170ms ease-in-out;
          margin: -1px 0;
          position: relative;
          background-color: var(--color-selectedBg);
          --drag-handle-width: ${parentDepth === 0 && depth > 0 ? '0px' : 'inherit'};
          grid-gap: 10px;
        }

        .taskRow,
        .rightStructure {
          display: flex;
          align-items: stretch;
        }

        .rightStructure {
          align-items: center;
          padding-right: var(--drag-handle-width);
          width: ${colSpan * 200 + 10}px;
          flex-shrink: 0;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
