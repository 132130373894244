import {getAllAncestorsForNodeId} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import {ProjectTreeOptions, ProjectTreeProjectsGroup} from './ProjectTreeInterfaces';

/**
 * Makes sure the selected node id is visible, so all its ancestors are expanded
 */
export function useExpandToSelection(
  options: ProjectTreeOptions,
  setExpandedNodeIds: Dispatch<SetStateAction<ReadonlySet<Uuid>>>,
  grouped: ProjectTreeProjectsGroup[],
  isFullyGrouped: boolean,
): void {
  const treeRef = useRef(options.nodeTree);
  treeRef.current = options.nodeTree;
  const groupedRef = useRef(grouped);
  groupedRef.current = grouped;

  useEffect(() => {
    void options.projectFolder; //trigger only to open to selection after changing the folder

    if (options.selectedNodeId && isFullyGrouped) {
      const trace = getAllAncestorsForNodeId(treeRef.current, options.selectedNodeId);
      setExpandedNodeIds((prev) => {
        let changed = false;
        const newSet = new Set(prev);
        trace.forEach((id) => {
          if (!newSet.has(id)) {
            newSet.add(id);
            changed = true;
          }
        });

        //Also open the grouping node:
        groupedRef.current.forEach(({id: groupingId, children}) => {
          children.forEach(({id: projectId}) => {
            if ((trace.has(projectId) || options.selectedNodeId === projectId) && !newSet.has(groupingId)) {
              newSet.add(groupingId);
              changed = true;
            }
          });
        });

        return changed ? newSet : prev;
      });
    }
  }, [isFullyGrouped, options.projectFolder, options.selectedNodeId, setExpandedNodeIds]);
}
