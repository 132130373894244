import {QuickNote} from '@octaved/flow/src/EntityInterfaces/QuickNote';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {OrgUserSettings} from '../EntityInterfaces/OrgUserSettings';
import {currentOrgUserSettingsSelector} from './CurrentOrgUserSelectors';

// noinspection FunctionNamingConventionJS
function us<R>(selector: (state: OrgUserSettings) => R): (rootState: FlowState) => R {
  return (rootState) => selector(currentOrgUserSettingsSelector(rootState));
}

const emptyArray: unknown[] = [];

export const quickNotesSelector = us((s) => s.quickNotes || (emptyArray as QuickNote[]));
export const timeSheetExportsConfigSelector = us((s) => s.timeSheetExportsConfig);
export const uiSyncSelector = us((s) => s.uiSync);
