import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import ProjectControllingHours from '@octaved/flow/src/Pages/Projects/Components/ProjectControllingHours';
import {getBillingTypeTranslatable} from '@octaved/flow/src/WorkPackage/BillingType';
import {ReactElement} from 'react';
import {RowProps} from './Interfaces';
import WithOrWithoutLimit from './WithOrWithoutLimit';

export default function FixedPriceRowWithoutMaxEffortRow({billable = false, sum}: RowProps): ReactElement {
  const token = getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_FIXED_PRICE, billable);
  return (
    <>
      <WithOrWithoutLimit token={token} withoutLimit />
      <div />
      <div className={'text-right'}>
        <ProjectControllingHours hours={sum.billableHoursWithoutMaxEffort} shortUnit />
      </div>
    </>
  );
}
