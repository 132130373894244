import {FieldCondition} from '@octaved/store/src/Notifications';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {removeErrorForField} from '../Modules/Ui';

export function useUnsetErrorsOnLeave(fields: FieldCondition[]): void {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(removeErrorForField(fields));
    };
  }, [dispatch, fields]);
}
