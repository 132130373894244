import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {isNodeDone} from '@octaved/flow/src/Node/IsNodeDone';
import {Image} from 'konva/lib/shapes/Image';
import {CheckCircle2} from 'lucide';
import {createStoreSubscription} from '../../../StoreSubscription';
import {NodeCell} from './NodeCell';

export class DoneCell extends NodeCell {
  #icon: null | Image = null;
  #isDone = false;

  init(): void {
    super.init();

    const y = (this.ctx.rowHeight - this.imageSize) * 0.5;
    this.iconToImage(CheckCircle2, {
      height: this.imageSize,
      imageConfig: {
        y,
        name: 'checkIcon',
        visible: false,
      },
      style: {
        fill: '#3dd245',
        stroke: 'white',
      },
      width: this.imageSize,
    }).then((icon) => {
      this.root.add(icon);
      this.#icon = icon;

      this.render();
      this.render.flush();
    });

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => !!isNodeDone(getNodeSelector(store)(this.node.id)),
        (isDone) => {
          this.#isDone = isDone;
          this.render();
        },
      ),
    );
  }

  protected doRender(): void {
    const {x, isVisble} = this.renderProps;
    this.#icon?.visible(isVisble && this.#isDone);
    this.#icon?.x(x + this.imageSize + this.padding);
  }
}
