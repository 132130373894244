import {error} from '@octaved/env/src/Logger';
import {deletePriceCategory, patchPriceCategory as patchPriceCategoryRoute, putPriceCategory} from '@octaved/flow-api';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {optimisticAdd, optimisticUpdate, setToReducerMap} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {
  createTextInputVariableRules,
  RulesList,
  validateDecimalIfValidStringFormat,
  validateLength,
  validateNumberStringFormat,
} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {generateUuid} from '@octaved/utilities';
import {ObjectContains} from '@octaved/validation/src';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {PriceCategories, PriceCategory, PriceCategoryPatchData} from '../EntityInterfaces/PriceCategories';
import {
  FLOW_CREATE_PRICE_CATEGORY_FAILURE,
  FLOW_CREATE_PRICE_CATEGORY_REQUEST,
  FLOW_CREATE_PRICE_CATEGORY_SUCCESS,
  FLOW_PATCH_PRICE_CATEGORY_FAILURE,
  FLOW_PATCH_PRICE_CATEGORY_REQUEST,
  FLOW_PATCH_PRICE_CATEGORY_SUCCESS,
  FLOW_REMOVE_PRICE_CATEGORY_FAILURE,
  FLOW_REMOVE_PRICE_CATEGORY_REQUEST,
  FLOW_REMOVE_PRICE_CATEGORY_SUCCESS,
} from './ActionTypes';
import {priceCategoryEntitiesSelector} from './Selectors/PriceCategorySelectors';
import {FlowState} from './State';
import {parseNullableNumber} from './TransformPatches';
import {validateErrorRules} from './Ui';

const reducerMap = new Map();
type Request = ServerRequestAction<{
  data: PriceCategory;
  urlParams: {priceCategoryId: Uuid};
}>;
setToReducerMap<PriceCategories, Request>(
  optimisticAdd,
  reducerMap,
  FLOW_CREATE_PRICE_CATEGORY_REQUEST,
  FLOW_CREATE_PRICE_CATEGORY_FAILURE,
  FLOW_CREATE_PRICE_CATEGORY_SUCCESS,
  ({options}) => options.urlParams.priceCategoryId,
);
setToReducerMap<PriceCategories, Request>(
  optimisticUpdate,
  reducerMap,
  FLOW_PATCH_PRICE_CATEGORY_REQUEST,
  FLOW_PATCH_PRICE_CATEGORY_FAILURE,
  FLOW_PATCH_PRICE_CATEGORY_SUCCESS,
  ({options}) => options.urlParams.priceCategoryId,
);
//No optimistic DELETE because the conflict/in-use check is done server-side
reducerMap.set(
  FLOW_REMOVE_PRICE_CATEGORY_SUCCESS,
  (
    state: PriceCategories,
    {
      options: {
        urlParams: {priceCategoryId},
      },
    }: Request,
  ): PriceCategories => {
    if (state[priceCategoryId]) {
      const newState = {...state};
      delete newState[priceCategoryId];
      return newState;
    }
    return state;
  },
);

export const priceCategoryReducer = ReduceFromMap(reducerMap);

function getValidationRules(id: Uuid, data: DeepPartial<PriceCategoryPatchData>, isCreation: boolean): RulesList {
  const rules: RulesList = [];
  if (isCreation || data.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        data.name!,
        'systemSettings:priceCategory.error.nameEmpty',
        'systemSettings:priceCategory.error.nameTooLong',
        `name_${id}`,
      ),
    );
  }
  if (data.hourlyRate) {
    rules.push([validateNumberStringFormat, data.hourlyRate, 'general:error.invalidNumberFormat', `hourlyRate_${id}`]);
    rules.push([
      validateDecimalIfValidStringFormat,
      data.hourlyRate,
      'general:error.decimalOutOfRange',
      `hourlyRate_${id}`,
    ]);
  }
  if (data.apiKey) {
    rules.push([validateLength, data.apiKey, 'systemSettings:priceCategory.error.apiKeyTooLong', `apiKey_${id}`]);
  }
  return rules;
}

export function createPriceCategoryEntity(): PriceCategory {
  return {
    apiKey: '',
    hourlyRate: null,
    id: generateUuid(),
    isLocked: false,
    name: '',
  };
}

export function transformPriceCategoryToPatchData(priceCategory: PriceCategory): PriceCategoryPatchData {
  return {
    ...priceCategory,
    hourlyRate: priceCategory.hourlyRate ? formatDecimal(priceCategory.hourlyRate) : null,
  };
}

function transformPatchDataToPriceCategory(data: DeepPartial<PriceCategoryPatchData>): DeepPartial<PriceCategory> {
  const entity = {...data} as DeepPartial<PriceCategory>;
  parseNullableNumber(data, entity, 'hourlyRate');
  return entity;
}

export function useCreatePriceCategory(): (data: PriceCategoryPatchData) => Promise<boolean> {
  const dispatch = useDispatch();
  return useCallback(
    async (data) => {
      if (!validateErrorRules(getValidationRules(data.id, data, true), dispatch)) {
        return false;
      }
      try {
        await dispatch({
          [CALL_API]: {
            endpoint: putPriceCategory,
            method: 'put',
            options: {
              data: transformPatchDataToPriceCategory(data),
              urlParams: {
                priceCategoryId: data.id,
              },
            },
            throwNetworkError: true,
            types: {
              failureType: FLOW_CREATE_PRICE_CATEGORY_FAILURE,
              requestType: FLOW_CREATE_PRICE_CATEGORY_REQUEST,
              successType: FLOW_CREATE_PRICE_CATEGORY_SUCCESS,
            },
          },
        });
        return true;
      } catch (e) {
        error(e);
        return false;
      }
    },
    [dispatch],
  );
}

export function patchPriceCategory(
  priceCategoryId: Uuid,
  data: Partial<PriceCategoryPatchData>,
): ActionDispatcher<Promise<boolean | null>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(priceCategoryId, data, false), dispatch)) {
      return false;
    }
    const patchData = transformPatchDataToPriceCategory(data);
    const priceCategory = priceCategoryEntitiesSelector(getState())[priceCategoryId];
    if (!priceCategory) {
      throw new Error('Missing priceCategory');
    }
    if (!ObjectContains(priceCategory, patchData)) {
      try {
        await dispatch({
          [CALL_API]: {
            endpoint: patchPriceCategoryRoute,
            method: 'patch',
            options: {
              data: patchData,
              urlParams: {priceCategoryId},
            },
            throwNetworkError: true,
            types: {
              failureType: FLOW_PATCH_PRICE_CATEGORY_FAILURE,
              requestType: FLOW_PATCH_PRICE_CATEGORY_REQUEST,
              successType: FLOW_PATCH_PRICE_CATEGORY_SUCCESS,
            },
          },
        });
      } catch (e) {
        error(e);
        return false;
      }
    }
    return true;
  };
}

export function removePriceCategory(priceCategoryId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return (dispatch: Dispatch, _getState) => {
    return dispatch({
      [CALL_API]: {
        endpoint: deletePriceCategory,
        method: 'del',
        options: {
          urlParams: {priceCategoryId},
        },
        throwNetworkError: true,
        types: {
          failureType: FLOW_REMOVE_PRICE_CATEGORY_FAILURE,
          requestType: FLOW_REMOVE_PRICE_CATEGORY_REQUEST,
          successType: FLOW_REMOVE_PRICE_CATEGORY_SUCCESS,
        },
      },
    });
  };
}
