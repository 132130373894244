import {asDateStr, DateStr} from '@octaved/typescript';
import {ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {ArrowLeftCircle, ArrowRightCircle} from 'lucide-react';
import {ReactElement, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TWButton} from '../../../TWButton';
import {IconButton} from '../../Button';

const monthOptions = [
  'generalCore:culture.months.january',
  'generalCore:culture.months.february',
  'generalCore:culture.months.march',
  'generalCore:culture.months.april',
  'generalCore:culture.months.may',
  'generalCore:culture.months.june',
  'generalCore:culture.months.july',
  'generalCore:culture.months.august',
  'generalCore:culture.months.september',
  'generalCore:culture.months.october',
  'generalCore:culture.months.november',
  'generalCore:culture.months.december',
];

interface MonthPresetPickerProps {
  value: DateStr | null;
  onChange: (value: DateStr) => void;
  readonly?: boolean;
}

export default function MonthPresetPicker({onChange, readonly, value}: MonthPresetPickerProps): ReactElement {
  const {t} = useTranslation();
  const month = useMemo(() => dayjs(value || undefined).month(), [value]);
  const year = useMemo(() => dayjs(value || undefined).year(), [value]);
  const [currentYear, setCurrentYear] = useState(dayjs(value).year());

  //update displayed year when value changes externally:
  useEffect(() => setCurrentYear(dayjs(value).year()), [value]);

  return (
    <div className={'flex min-w-[300px] flex-col gap-y-4'}>
      <div className={'flex items-center justify-between py-2'}>
        <IconButton
          size={'sm'}
          onClick={() => setCurrentYear((old) => old - 1)}
          iconSize={'bigger'}
          iconColor={'grey'}
          icon={<ArrowLeftCircle className={'h-8 w-8'} />}
          variant={'ghost'}
        />
        <div className={'text-sm text-stone-500'}>{currentYear}</div>
        <IconButton
          size={'sm'}
          onClick={() => setCurrentYear((old) => old + 1)}
          iconSize={'bigger'}
          iconColor={'grey'}
          icon={<ArrowRightCircle className={'h-8 w-8'} />}
          variant={'ghost'}
        />
      </div>

      <div className={'grid grid-cols-3 gap-4'}>
        {monthOptions.map((monthOption, idx) => (
          <TWButton
            disabled={readonly}
            key={monthOption}
            size={'md'}
            colorScheme={year === currentYear && idx === month ? 'primary' : 'slate'}
            variant={'solid'}
            onClick={() => onChange(asDateStr(dayjs().year(currentYear).month(idx).format(ISO_DATE)))}
          >
            {t(monthOption)}
          </TWButton>
        ))}
      </div>
    </div>
  );
}
