import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {Calendar} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {MenuRootItem} from '../FlowRoutes';
import {planningDateInspectorRoutes} from './Inspectors/PlanningDateInspector';
import {planningInspectorRoutes} from './Inspectors/PlanningInspector';

const GanttProjectPlanning = lazy(
  () => import('@octaved/planning/src/Pages/Gantt/ProjectPlanning/Project/GanttProjectPlanning'),
);
const TeamProjectPlanning = lazy(() => import('@octaved/planning/src/Pages/Team/Project/TeamProjectPlanning'));
const MaterialResources = lazy(() => import('@octaved/planning/src/Pages/Gantt/MaterialResources/MaterialResources'));

const projectPlanningRoute = 'projectPlanning';
const teamProjectPlanningRoute = 'teamPlanning';
const materialResourcesRoute = 'materialResources';

export function getPlanningRoutesWithoutStandardInspector(): string[] {
  return [materialResourcesRoute];
}

export const planningRoutes: RouteObject[] = [
  {
    children: [...planningInspectorRoutes, ...planningDateInspectorRoutes],
    element: <GanttProjectPlanning />,
    path: `${projectPlanningRoute}/*`,
  },
  {
    children: planningInspectorRoutes,
    element: <TeamProjectPlanning />,
    path: `${teamProjectPlanningRoute}/*`,
  },
  {
    children: planningDateInspectorRoutes,
    element: <MaterialResources />,
    path: `${materialResourcesRoute}/*`,
  },
];

export function getProjectPlanningRoute(): string {
  return projectPlanningRoute;
}

export const planningMenu: MenuRootItem = {
  access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
  groupToken: 'pageMenu:planningGroup',
  icon: Calendar,
  id: 'planning',
  items: [
    {
      nameToken: 'pageMenu:projectsAndTasks',
      path: projectPlanningRoute,
    },
    {
      access: ({orgUserType}) => isInternalUserType(orgUserType),
      nameToken: 'pageMenu:teamPlanning',
      path: teamProjectPlanningRoute,
    },
    {
      access: ({orgUserType, settings}) => isInternalUserType(orgUserType) && settings.hasMaterialResources,
      nameToken: 'pageMenu:materialResource',
      path: materialResourcesRoute,
    },
    {
      disabled: true,
      nameToken: 'pageMenu:roadmap',
      path: 'roadmap',
    },
  ],
};
