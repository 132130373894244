import {error} from '@octaved/env/src/Logger';
import {getInitData} from '@octaved/flow-api';
import {IdentityDeclinedOrgInvitationEvent} from '@octaved/identity/src/Event/Events';
import {clearStoredOrganization, getOrganizationHeaders} from '@octaved/identity/src/Modules/OrganizationSwitcher';
import {organizationSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {
  createFlatTimestampReducer,
  EntityState as SingleEntityState,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {getInstanceUuid} from '@octaved/utilities';
import {normalize} from 'normalizr';
import {isAction, UnknownAction} from 'redux';
import {getMyOrgsRoute} from '../Routing/Routes/MyOrganizations';
import {FLOW_INIT_INVALIDATED, FLOW_INIT_LOAD_REQUEST, FLOW_INIT_LOAD_SUCCESS} from './ActionTypes';
import {InitAction, NormalizedInitResponse} from './Initialization/Actions';
import {customer, label, priceCategory, priceSurcharge} from './Schema';
import {initDataStateSelector} from './Selectors/InitDataSelectors';
import {EntityState, FlowState} from './State';

interface RawResponse {
  data: Record<string, unknown>;
  isAuthenticated: true;
  isInOrganization: true;
}

const stateReducerMap = createReducerCollection<SingleEntityState>({});

stateReducerMap.add(FLOW_INIT_LOAD_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.add(FLOW_INIT_LOAD_SUCCESS, createFlatTimestampReducer(LOADED));
stateReducerMap.add(
  [
    'currentUserChanged',
    'flow.IdentityJoinedOrgEvent',
    'flow.OrganizationPatchedEvent',
    'flow.PriceCategoryCreatedEvent',
    'flow.PriceCategoryPatchedEvent',
    'flow.PriceCategoryRemovedEvent',
    'flow.PriceSurchargeCreatedEvent',
    'flow.PriceSurchargePatchedEvent',
    'flow.PriceSurchargeRemovedEvent',
    'flow.SettingsChangedEvent',
    'IdentityInvitedToOrganizationEvent',
    FLOW_INIT_INVALIDATED,
  ],
  createFlatTimestampReducer(INVALIDATED),
);
stateReducerMap.add(
  ['flow.CustomerCreatedEvent', 'flow.CustomerPatchedEvent', 'flow.CustomerRemovedEvent'],
  createFlatTimestampReducer(INVALIDATED, true),
);

stateReducerMap.add<IdentityDeclinedOrgInvitationEvent>('flow.IdentityDeclinedOrgInvitationEvent', (state, action) => {
  if (action.responsibleInstanceId !== getInstanceUuid()) {
    return createFlatTimestampReducer(INVALIDATED)(state, action);
  }
  return state; //otherwise handled optimistically in Identity.ts
});

export const initDataStateReducer = stateReducerMap.reducer;

const initDataRootReducers = createReducerCollection<FlowState>({} as FlowState);
initDataRootReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) => {
  if (!action.isAuthenticated) {
    return mergeStates(state, {loginForm: action.response.result.loginForm});
  }
  return state;
});
export const initDataRootReducer = initDataRootReducers.reducer;

function isInitaction(action: unknown): action is InitAction {
  return isAction(action) && action.type === FLOW_INIT_LOAD_SUCCESS;
}

/**
 * Removes all superfluous entities from the store. The init data load a complete list of existing entities.
 * The normal entity reducer will only merge, but not remove.
 */
export function initDataEntitiesReducer(state: EntityState, action: UnknownAction): EntityState {
  if (isInitaction(action)) {
    const newState = {...state};
    let changed = false;
    Object.entries(action.response.entities).forEach(([_key, _entities]) => {
      const entityName = _key as keyof NormalizedInitResponse['entities'];
      const entities = _entities as NormalizedInitResponse['entities'][typeof entityName];
      const inStore = newState[entityName];
      Object.keys(inStore).forEach((_id) => {
        const id = _id as keyof NormalizedInitResponse['entities'][keyof NormalizedInitResponse['entities']];
        if (!entities[id]) {
          //typescript errors here due to the union type - maybe in the future this isn't necessary anymore:
          newState[entityName as 'customer'] = {...newState[entityName as 'customer']};
          delete newState[entityName][id];
          changed = true;
        } else if (JSON.stringify(entities[id]) !== JSON.stringify(newState[entityName][id])) {
          newState[entityName as 'customer'] = {...newState[entityName as 'customer']};
          newState[entityName][id] = entities[id];
          changed = true;
        }
      });
    });
    return changed ? newState : state;
  }
  return state || null; //must return NULL to be ignored in redux init
}

export function loadInitializeData(): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const initDataState = initDataStateSelector(state);
    const currentOrgId = organizationSelector(state)?.id;
    if (!initDataState || isOutdated(initDataState)) {
      const requestTime = Date.now();

      await dispatch({requestTime, type: FLOW_INIT_LOAD_REQUEST});

      // Must use plain fetch to work with a preload
      // see https://stackoverflow.com/questions/52635660/can-link-rel-preload-be-made-to-work-with-fetch
      const response = await fetch(getInitData, {
        credentials: 'include',
        headers: getOrganizationHeaders(),
        method: 'GET',
        // mode: 'no-cors', //cannot use "no-cors" with "headers"
      });

      if (response.status === 200) {
        const json = (await response.json()) as RawResponse;

        const normalizedResponse = normalize(json.data, {
          customers: [customer],
          labels: [label],
          priceCategories: [priceCategory],
          priceSurcharges: [priceSurcharge],
        });

        await dispatch({
          requestTime,
          isAuthenticated: json.isAuthenticated,
          isInOrganization: json.isInOrganization,
          response: normalizedResponse,
          type: FLOW_INIT_LOAD_SUCCESS,
        });

        if (!json.isInOrganization && currentOrgId) {
          //The user might have been deleted or deactivated, so we have to force reload to show the organization
          // select:
          window.location.href = getMyOrgsRoute();
        }
        return;
      }

      if (response.status === 400) {
        try {
          const json = await response.json();
          if (json && typeof json === 'object' && 'error' in json && json.error === 'invalidOrganizationSpecified') {
            clearStoredOrganization();
            window.location.href = getMyOrgsRoute();
          }
        } catch {
          //nothing
        }
      }

      error('Error loading init data');
    }
  };
}
