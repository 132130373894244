import {NoNullFields} from '@octaved/typescript/src/lib';
import {asTimeStrM} from '@octaved/typescript/src/TimeStr';
import {seperateDateTimeStringsToUnix, unixToIsoDate} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities/src/Uuid';
import {TimeRecord} from '../../EntityInterfaces/TimeRecords';

const lunchHourStart = asTimeStrM('11:45');
const lunchHourEnd = asTimeStrM('13:45');

export type UnconfirmedHoleEntry = NoNullFields<Pick<TimeRecord, 'id' | 'workTimeStart' | 'workTimeEnd'>> & {
  isHole: true;
};
export type OverlappingTimeRecord = TimeRecord & {
  isOverlapping: boolean;
};
export type TimeRecordWithDetails = TimeRecord | UnconfirmedHoleEntry | OverlappingTimeRecord;

export function isTimeRecord(timeRecord: TimeRecordWithDetails): timeRecord is TimeRecord {
  return !timeRecord.hasOwnProperty('isHole') && !timeRecord.hasOwnProperty('isOverlapping');
}

function setIsOverlapping(record: TimeRecordWithDetails): void {
  const current = record as OverlappingTimeRecord;
  current.isOverlapping = true;
}

function hasHole(currentRecord: TimeRecord, prevRecord: TimeRecordWithDetails): boolean {
  return Boolean(
    currentRecord.workTimeStart && prevRecord.workTimeEnd && currentRecord.workTimeStart - prevRecord.workTimeEnd > 300,
  );
}

function isOverlapping(currentRecord: TimeRecord, prevRecord: TimeRecordWithDetails): boolean {
  return Boolean(
    currentRecord.workTimeStart &&
      prevRecord.workTimeStart &&
      (prevRecord.workTimeStart >= currentRecord.workTimeStart ||
        currentRecord.workTimeStart < prevRecord.workTimeEnd!),
  );
}

function calculateRecordDetails(
  currentRecord: TimeRecord,
  prevRecord: TimeRecordWithDetails | null,
  resultList: TimeRecordWithDetails[],
  holes: UnconfirmedHoleEntry[],
  calculateHoles: boolean,
): void {
  if (prevRecord) {
    if (calculateHoles && hasHole(currentRecord, prevRecord)) {
      const hole: UnconfirmedHoleEntry = {
        id: generateUuid(),
        isHole: true,
        workTimeEnd: currentRecord.workTimeStart!,
        workTimeStart: prevRecord.workTimeEnd!,
      };
      resultList.push(hole);
      holes.push(hole);
    } else {
      if (isOverlapping(currentRecord, prevRecord)) {
        setIsOverlapping(currentRecord);
        setIsOverlapping(prevRecord);
      }
    }
  }
  resultList.push(currentRecord);
}

function handleLaunchHourHoles(
  resultList: TimeRecordWithDetails[],
  holes: UnconfirmedHoleEntry[],
): TimeRecordWithDetails[] {
  const day = unixToIsoDate(resultList[0].workTimeStart!);
  const launchHourStartTs = seperateDateTimeStringsToUnix(day, lunchHourStart);
  const launchHourEndTs = seperateDateTimeStringsToUnix(day, lunchHourEnd);
  const launchHoles = holes.filter(
    (hole) => hole.workTimeStart >= launchHourStartTs && hole.workTimeEnd <= launchHourEndTs,
  );
  if (launchHoles.length === 1) {
    //only reomve hole if there is one between the launch hours
    const id = launchHoles[0].id;
    return resultList.filter((result) => result.id !== id);
  }
  return resultList;
}

export function calculateDetails(records: TimeRecord[], calculateHoles: boolean): TimeRecordWithDetails[] {
  records.sort((a, b) => a.workTimeStart! - b.workTimeStart!);
  let resultList: TimeRecordWithDetails[] = [];
  const holes: UnconfirmedHoleEntry[] = [];
  let prevIndex = 0;
  for (let i = 0; i < records.length; ++i) {
    calculateRecordDetails({...records[i]}, resultList[prevIndex], resultList, holes, calculateHoles);
    prevIndex = resultList.length - 1;
  }
  if (holes.length) {
    resultList = handleLaunchHourHoles(resultList, holes);
  }
  return resultList;
}

export function isHoleEntry(entry: TimeRecordWithDetails): entry is UnconfirmedHoleEntry {
  return entry.hasOwnProperty('isHole');
}

export function isOverlappingTimeRecord(entry: TimeRecordWithDetails): entry is OverlappingTimeRecord {
  return entry.hasOwnProperty('isOverlapping');
}
