import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {nodeStatusLabels, sortedNodeStatus} from '@octaved/flow/src/Node/NodeStatus/NodeStatus';
import {nodeStatusColors, nodeStatusIcons} from '@octaved/flow/src/Node/NodeStatus/NodeStatusIcons';
import {nodeStatusQueries} from '@octaved/flow/src/Node/NodeStatus/NodeStatusQueries';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {PieChartWithLegend, PieChartWithLegendEntry} from '@octaved/ui';
import {boolFilter} from '@octaved/utilities';
import {ReactElement, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {controllingIncludeArchivedInChartsSelector} from '../../../../Selectors/Ui/UiStateSelectors';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeWorkPackagesStatusProps {
  nodeId: Uuid;
  valueOverride?: Readonly<[number, number, number, number]>; //for demo mode
}

export function NodeWorkPackagesStatus({
  frame: Frame,
  nodeId,
  valueOverride,
}: NodeWorkPackagesStatusProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const includeArchived = useSelector(controllingIncludeArchivedInChartsSelector);

  const filtered = useMemo(
    () => (useTimeTracking ? sortedNodeStatus : sortedNodeStatus.filter((s) => s !== 'isApprovedForBilling')),
    [useTimeTracking],
  );

  const queries = useMemo(() => {
    const baseConditions: NodeSearchCondition[] = [
      withDescendants({fixResult: [nodeId]}, false),
      ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
      {not: ['wpIsOffer']},
    ];
    if (!includeArchived) {
      baseConditions.push({not: ['isArchived']});
    }
    return filtered.map<NodeSearchCondition>((status) => ({and: [nodeStatusQueries[status], ...baseConditions]}));
  }, [filtered, includeArchived, nodeId]);

  //The `results` array is not cached and will be a new instance every run, so we have to extract the values
  // and provide a different trigger to update the pieces:
  const results = useCombinedNodeSearches({}, ...queries);
  const values = results.map(({ids}) => ids.length);
  const valuesRef = useRef(values);
  valuesRef.current = values;
  const valuesCacheKey = JSON.stringify(values);

  const pieces = useMemo((): PieChartWithLegendEntry[] => {
    // noinspection BadExpressionStatementJS
    void valuesCacheKey; //trigger
    return boolFilter(
      filtered.map<PieChartWithLegendEntry | null>((status, idx) => {
        const Icon = nodeStatusIcons[status];
        const value = valueOverride?.[idx] ?? valuesRef.current[idx] ?? 0;
        return {
          value,
          color: nodeStatusColors[status],
          legendIcon: <Icon />,
          name: t(nodeStatusLabels[status]),
        };
      }),
    );
  }, [filtered, t, valueOverride, valuesCacheKey]);

  return (
    <Frame labelToken={'nodeDetails:field.workpackageStatus.label'}>
      <PieChartWithLegend limit={pieces.length} pieces={pieces} />
    </Frame>
  );
}
