/**
 * @see {http://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax}
 */
export default class RuntimeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.stack = new Error(message).stack;
  }
}
