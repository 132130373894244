import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {ReactNode} from 'react';
import {createSelector} from 'reselect';
import {PriceCategories, PriceCategory} from '../../EntityInterfaces/PriceCategories';
import {FlowState} from '../State';
import {priceCategoryOverridesSelector} from './CustomerSelectors';

export const priceCategoryEntitiesSelector = (state: FlowState): PriceCategories => state.entities.priceCategory;

export const priceCategoryListSelector = createSelector(priceCategoryEntitiesSelector, (priceCategories) =>
  boolFilter(Object.values(priceCategories)).sort((a, b) => a.name.localeCompare(b.name)),
);

export const showPriceCategorySelectionSelector = createSelector(
  priceCategoryListSelector,
  // See https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/510 only show drop downs if there are 2 or more:
  (priceCategories) => priceCategories.length > 1,
);

export const customerpriceCategoryListSelector = createSelector(
  priceCategoryListSelector,
  priceCategoryOverridesSelector,
  (priceCategoryList, getOverrides) =>
    memoize((customerId: Uuid): PriceCategory[] => {
      const overrides = getOverrides(customerId);
      if (!overrides) {
        //if there no overrides the original list can be returned
        return priceCategoryList;
      }
      const result: PriceCategory[] = [];
      for (const priceCategory of priceCategoryList) {
        const currentOverride = overrides[priceCategory.id];
        if (!currentOverride || !currentOverride.categoryDisabled) {
          result.push({
            ...priceCategory,
            hourlyRate: currentOverride?.overrideIsActive ? currentOverride.hourlyRate : priceCategory.hourlyRate,
          });
        }
      }
      return result;
    }),
);

export const getSelectablePriceCategoriesSelector = createSelector(
  priceCategoryListSelector,
  priceCategoryOverridesSelector,
  (priceCategoryList, getOverrides) =>
    memoize(
      (flowCustomer: Uuid, selectedPriceCategory: Uuid | null) => {
        const result: PriceCategory[] = [];
        const overrides = getOverrides(flowCustomer) || {};
        for (const priceCategory of priceCategoryList) {
          const currentOverride = overrides[priceCategory.id];
          if (
            (!priceCategory.isLocked && //hide price category if it is locked
              (!currentOverride || !currentOverride.categoryDisabled)) || //overrides can hide a price category for a customer
            priceCategory.id === selectedPriceCategory //the current price category is always visible
          ) {
            result.push({
              ...priceCategory,
              hourlyRate: currentOverride?.overrideIsActive ? currentOverride.hourlyRate : priceCategory.hourlyRate,
            });
          }
        }
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      },
      (flowCustomer: Uuid, selectedPriceCategory: Uuid | null) => `${flowCustomer}-${selectedPriceCategory}`,
    ),
);

export const getPriceCategoryDropdownOptionsSelector = createSelector(
  getSelectablePriceCategoriesSelector,
  (getSelectablePriceCategories) =>
    memoize(
      (flowCustomer: Uuid, selectedPriceCategory: Uuid | null) => {
        const categories = getSelectablePriceCategories(flowCustomer, selectedPriceCategory);
        return categories.map<{text: ReactNode; value: string}>(({id, name}) => ({
          text: name,
          value: id,
        }));
      },
      (flowCustomer: Uuid, selectedPriceCategory: Uuid | null) => `${flowCustomer}-${selectedPriceCategory}`,
    ),
);

export const getHourlyRateWithOverrideSelector = createSelector(
  priceCategoryEntitiesSelector,
  priceCategoryOverridesSelector,
  (priceCategories, getOverrides) =>
    memoize(
      (priceCategoryId: Uuid, flowCustomerId: Uuid): number => {
        const override = (getOverrides(flowCustomerId) || {})[priceCategoryId];
        return (override?.overrideIsActive ? override.hourlyRate : priceCategories[priceCategoryId]?.hourlyRate) || 0;
      },
      (priceCategoryId: Uuid, flowCustomerId: Uuid) => `${priceCategoryId}-${flowCustomerId}`,
    ),
);
