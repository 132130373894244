import {getPlanningDatesForNodeSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PlanningDatesRow from './PlanningDatesRow';

interface PlanningDatesProps {
  nodeId: Uuid;
}

export default function PlanningDates({nodeId}: PlanningDatesProps): ReactElement {
  const {t} = useTranslation();
  const planningDates = useSelector(getPlanningDatesForNodeSelector)(nodeId);

  return (
    <div>
      <div className={'timePeriodGrid'}>
        <div className={'header'}>&nbsp;</div>
        <div className={'header'}>{t('pages:projects.inspector.manage.planning.timePlanning')}</div>
        <div className={'header duration'}>{t('pages:projects.inspector.manage.planning.duration')}</div>
        {planningDates.map((planningDate, index) => (
          <PlanningDatesRow
            planningDate={planningDate}
            key={planningDate.id}
            planningDates={planningDates}
            index={index}
          />
        ))}
      </div>
      {/*language=SCSS*/}
      <style jsx>{`
        .timePeriodGrid {
          display: grid;
          grid-template-columns: max-content 1fr 60px;
          grid-row-gap: 0;
          align-items: center;
          max-width: 100%;
        }

        .header {
          padding: 6px 12px;
          font-weight: 500;
          border-bottom: 1px solid rgba(34, 36, 38, 0.1);
          font-size: 12px;
          color: var(--color-grey-700);
        }

        .duration {
          text-align: right;
        }
      `}</style>
    </div>
  );
}
