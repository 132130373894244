import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {hasPidHours, hasPrice} from '@octaved/flow/src/Modules/Pid';
import {generateLargeViewUrl} from '@octaved/flow/src/Routing/Routes/Node';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Message, TWButton} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import NodeBillingTypeSection from '../../../Components/Fields/TimeAndMoney/Section/NodeBillingTypeSection';
import NodeFreeOfChargeReasonSection from '../../../Components/Fields/TimeAndMoney/Section/NodeFreeOfChargeReasonSection';
import NodePriceCategorySection from '../../../Components/Fields/TimeAndMoney/Section/NodePriceCategorySection';
import NodePriceSection from '../../../Components/Fields/TimeAndMoney/Section/NodePriceSection';
import WorkPackageEffortSection from '../../../Components/Fields/TimeAndMoney/Section/WorkPackage/Section/WorkPackageEffortSection';
import {
  requiredEffortField,
  RequiredEffortFields,
  useTimeAndMoneyValidationPatch,
} from '../../../Components/Fields/TimeAndMoney/Values/TimeAndMoneyPatch';
import {timeSheetRoute} from '../../../LargeView/RoutePaths';
import InspectorViewFrame from '../../InspectorViewFrame';
import {WorkPackageDetailsProps} from './General';

export default function TimeAndMoney({node: liveNode}: WorkPackageDetailsProps): ReactElement {
  const {t} = useTranslation();
  const {patch, requiredFields, node, isUnsaved} = useTimeAndMoneyValidationPatch(liveNode);
  const canManage = useIsGranted('FLOW_NODE_MANAGE_PRICES', node.id) && !node.isArchived;

  return (
    <InspectorViewFrame help={'nodeDetails:view.timeAndMoney.help'}>
      <NodeBillingTypeSection node={node} readonly={!canManage} patch={patch} />
      <NodePriceCategorySection
        key={node.billingType}
        node={node}
        readonly={!canManage}
        patch={patch}
        requiredByAutosave={requiredFields.includes('priceCategory')}
      />
      {hasPrice(node) && (
        <NodePriceSection
          node={node}
          patch={patch}
          readonly={!canManage}
          requiredByAutosave={requiredFields.includes('fixedPrice')}
        />
      )}
      {node.billingType === EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE && node.freeOfChargeReason && (
        <NodeFreeOfChargeReasonSection
          reason={node.freeOfChargeReason}
          setReason={(freeOfChargeReason) => patch({freeOfChargeReason})}
          readonly={!canManage}
        />
      )}
      {hasPidHours(node) && (
        <WorkPackageEffortSection
          includeSubWorkPackages
          node={node}
          patch={patch}
          readonly={!canManage}
          requiredFieldsByAutosave={
            requiredFields.filter((field) => requiredEffortField.has(field)) as RequiredEffortFields[]
          }
        />
      )}

      {isUnsaved && (
        <Message colorScheme={'autoSaveWarning'} textSize={'sm'}>
          {t('general:autosaveConditionWarning')}
        </Message>
      )}
      <div className={'flex'}>
        <Link to={generateLargeViewUrl(node.nodeType, node.id, timeSheetRoute)}>
          <TWButton variant={'outline'} size={'md'} colorScheme={'primary'}>
            {t('nodeDetails:field.timeTracking.timeSheet')}
          </TWButton>
        </Link>
      </div>
    </InspectorViewFrame>
  );
}
