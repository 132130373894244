import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createPromise} from '@octaved/utilities';
import get from 'lodash/get';
import {Dispatch as ReduxDispatch, UnknownAction} from 'redux';
import {EnhancedStore} from './CreateStore';
import {readonly} from './Readonly';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type Store<S extends object = {}> = EnhancedStore<S>;

interface Action<T = string> {
  type: T;
  [CALL_API]?: never; //creates an XOR for Action/CallAction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export type SomeAction = Action | CallAction | ThunkAct<void> | Promise<void>;

export interface Dispatch<A extends SomeAction = SomeAction> {
  <T = A extends CallAction ? Promise<void> : undefined>(action: A): T;

  <R, S extends object = object>(asyncAction: ActionDispatcher<R, S>): R;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ActionDispatcher<Result = unknown, S = any> {
  (dispatch: Dispatch, getState: () => S): Result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ThunkDispatch<State = any> {
  <R, S = State>(asyncAction: ActionDispatcher<R, S>): R;

  <T>(normalAction: Action<T>): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface Reducer<T = any, A = UnknownAction> {
  (state: T, action: A, entities?: object): T;

  fieldsToCopy?: Set<string>;
  reducers?: Array<Reducer<T, A>>;
}

export type ReducerList<Entities extends object> = {
  [k in keyof Entities]: Reducer;
};

export interface ReducerObject {
  [x: string]: Reducer | ReducerObject;

  [x: number]: Reducer | ReducerObject;
}

export type ReducerMap<T extends object = object, A = UnknownAction> = Map<string, Reducer<T, A>>;

let store: Store;

const [storeSetPromise, storeSetResolve] = createPromise();

export function setStore(defaultStore: Store): void {
  store = defaultStore;
  storeSetResolve();
}

export function dispatch<ReturnType = void>(action: SomeAction): ReturnType {
  return store.dispatch(action as UnknownAction) as unknown as ReturnType;
}

//Our custom dispatch-type is not compatible with the ThunkAction from redux-thunk
export const nativeDispatch: ReduxDispatch = dispatch as ReduxDispatch;

export function getState<State>(): State {
  return readonly(store.getState()) as State;
}

export function subscribe(cb: () => void): () => void {
  let subscription: (() => void) | null = null;
  let canceled = false;
  storeSetPromise.then(() => {
    if (!canceled) {
      subscription = store.subscribe(cb);
    }
  });
  return () => {
    if (subscription) {
      subscription();
    }
    canceled = true;
  };
}

export function onChange<T>(path: string, cb: (newValue: T, lastValue: T) => void): () => void {
  let lastValue: T;
  return subscribe(() => {
    const state = getState();
    const newValue = get(state, path);
    if (lastValue !== newValue) {
      cb(newValue, lastValue);
      lastValue = newValue;
    }
  });
}

export function onSelectorChange<T, State>(
  selector: (state: State) => T,
  leading: true,
  cb: (newValue: T, lastValue: T | undefined) => void,
): () => void;
export function onSelectorChange<T, State>(
  selector: (state: State) => T,
  leading: false,
  cb: (newValue: T, lastValue: T) => void,
): () => void;
export function onSelectorChange<T, State>(
  selector: (state: State) => T,
  leading: boolean,
  cb: (newValue: T, lastValue: T | undefined) => void,
): () => void {
  let lastValue: T | undefined = undefined;
  storeSetPromise.then(() => {
    try {
      lastValue = selector(getState());
    } catch (e) {
      //In tests we often have an incomplete mocked state, leading to the selector not being usable in a global scope:
      if (
        process.env.NODE_ENV !== 'test' ||
        !(e instanceof TypeError) ||
        !e.message.startsWith('Cannot read properties of undefined')
      ) {
        throw e;
      }
      return;
    }
    if (leading) {
      cb(lastValue, undefined);
    }
  });
  return subscribe(() => {
    const newValue = selector(getState());
    if (lastValue !== newValue) {
      cb(newValue, lastValue);
      lastValue = newValue;
    }
  });
}

/**
 * Adds a new reducer to the store
 *
 * @param {Object<string, function>} reducers
 * @return {Promise<function>} dispose callback, which removes the added reducers again
 */
export async function addReducers(reducers: ReducerObject): Promise<() => void> {
  await storeSetPromise;
  return store.addReducers(reducers);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function replaceReducer(nextReducer: any): void {
  store.replaceReducer(nextReducer);
}
