import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import type {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';

type NSC = NodeSearchCondition;

const isClosed: NSC = {
  and: [
    {
      or: [
        withDescendants(['prIsClosed'], true),
        withDescendants(['grIsClosed'], true),
        withDescendants(['wpIsClosed'], true),
      ],
    },
  ],
};

const isApprovedForBilling: NSC = {
  and: [withDescendants(['wpIsApprovedForBilling'], true), {not: isClosed}],
};

const isLocked: NSC = {
  and: [
    {
      or: [
        withDescendants(['prIsLocked'], true),
        withDescendants(['grIsLocked'], true),
        withDescendants(['wpIsLocked'], true),
        withDescendants(['swpIsLocked'], true),
      ],
    },
    {not: isApprovedForBilling},
    {not: isClosed},
  ],
};

const isOpen: NSC = {
  and: [
    {
      or: [
        ['nodeType', EnumFlowNodeType.VALUE_PROJECT],
        ['nodeType', EnumFlowNodeType.VALUE_GROUP],
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
      ],
    },
    {not: isLocked},
    {not: isApprovedForBilling},
    {not: isClosed},
  ],
};

export const nodeStatusQueries = {
  isApprovedForBilling,
  isClosed,
  isLocked,
  isOpen,
};
