import {CalendarView} from '../../Calendar/Context/CalendarView';
import {GanttContext} from './GanttContext';

const paddingStartInMonths = 3;
const paddingEndInMonths = 24;

export class GanttCalendarView extends CalendarView {
  #ctx: GanttContext | null = null;

  init(ctx: GanttContext): void {
    this.#ctx = ctx;
  }

  getPaddingStartInMonths(): number {
    return paddingStartInMonths;
  }

  getPaddingEndInMonths(): number {
    return paddingEndInMonths;
  }

  get ctx(): GanttContext {
    if (!this.#ctx) {
      throw Error('not initialized');
    }
    return this.#ctx;
  }

  get viewWidth(): number {
    return this.ctx.canvasView.width - this.ctx.calculateTableWidth();
  }
}
