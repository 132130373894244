import {getSupportedLanguageWithFallback, SupportedLanguage} from '@octaved/i18n/src/Language/Languages';
import {namespaces, translationImports} from '@octaved/i18n/translations';
import {createPromise} from '@octaved/utilities';
import i18next from 'i18next';
import once from 'lodash/once';
import {initReactI18next} from 'react-i18next';
import {initializeDayjsLocale} from './InitializeDayjs';

export const initI18next = once(async (languageCode: string): Promise<void> => {
  const supportedLanguage = getSupportedLanguageWithFallback(languageCode);
  const [loadedPromise, loadedResolve] = createPromise();

  //Must register events before the init to be before the react bindings that trigger the re-renders:

  const initDayjs = (): void => initializeDayjsLocale(i18next.t, i18next.language as SupportedLanguage);
  i18next.on('initialized', initDayjs);
  i18next.on('languageChanged', initDayjs);

  const initPromies = i18next
    .use(initReactI18next)
    .use({
      create(_languages: string[], _namespace: string, _key: string, _fallbackValue: string) {
        // Will be called if a token is used but does not exist in the resource files.
        // Maybe not wanted anymore, since it will cause duplicated hot relaod
      },
      async read(
        language: string,
        namespace: string,
        callback: (failureData: unknown, translationNamespace: Record<string, string>) => void,
      ) {
        const getter = translationImports[getSupportedLanguageWithFallback(language)]; //just for casting
        const translations = await getter();
        callback(null, translations.default[namespace]);
      },
      type: 'backend',
    })
    .init({
      defaultNS: 'core',
      fallbackLng: supportedLanguage,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      lng: supportedLanguage,
      ns: namespaces,
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em'],
      },
      returnNull: false,
      saveMissing: false, // Change to true as soon this functionality is implemented.
    });

  i18next.on('loaded', loadedResolve);

  await Promise.all([initPromies, loadedPromise]);
});
