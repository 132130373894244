import {DateStr} from '@octaved/typescript';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

export interface BaselineSchemaFormData {
  name: string;
  option: 'current' | 'custom';
  selectedCustomDate: DateStr;
  customTime: string;
}

export function useBaselineSchema(): Yup.ObjectSchema<
  {
    name: string | undefined;
    option: string | undefined;
  },
  Yup.AnyObject,
  {
    name: undefined;
  },
  ''
> {
  const {t} = useTranslation();
  return useMemo(
    () =>
      Yup.object().shape({
        customTime: Yup.string().when('option', {
          is(value: string) {
            return value === 'custom';
          },
          then(schema) {
            return schema.required({
              i18nKey: 'general:validationError.validTime',
              values: {
                field: t('pages:planning.baselineComparison.newBaseline.time'),
                max: 191,
              },
            });
          },
        }),
        name: Yup.string().max(191, {
          i18nKey: 'general:validationError.maxLength',
          values: {field: t('pages:planning.baselineComparison.name'), max: 191},
        }),
        option: Yup.string().required(),
      }),
    [t],
  );
}
