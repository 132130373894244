import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

export interface ComplexTransValues {
  [x: string]: string | number | undefined | null;
  COUNT?: never;
  count?: number;
}

export type ComplexTranslatable =
  | string
  | {
      components?: Record<string, ReactElement>;
      i18nKey: string;
      values?: ComplexTransValues;
    }
  | undefined
  | null;

export default function ComplexTrans({translatable}: {translatable: ComplexTranslatable}): ReactElement {
  if (typeof translatable === 'string') {
    return <Trans i18nKey={translatable} />;
  }
  return <Trans {...translatable} />;
}
