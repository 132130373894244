import Notifications, {Notification} from '@octaved/store/src/Notifications';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {CREATE_GROUP_FAILURE, PATCH_GROUP_FAILURE} from './ActionTypes';

export interface UiPagesUserState {
  notifications: {
    error: Notification[];
  };
}

const initialUiState: UiPagesUserState = {
  notifications: {
    error: [],
  },
};

export const reducerMap = new Map();
const {actions, reduceResponseFailure} = Notifications('ui.pages.users', reducerMap);
export const {setErrors, addError, removeError, addErrors, removeErrorForField} = actions;

addMultiToReducerMap(reducerMap, [PATCH_GROUP_FAILURE, CREATE_GROUP_FAILURE], reduceResponseFailure);

export const reducer = ReduceFromMap(reducerMap, initialUiState);
