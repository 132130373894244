import {DateStr} from '@octaved/typescript';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {VisibleTasks} from '../../Components/Task/VisibleTasks';
import {MyTasksFilterSet} from '../../EntityInterfaces/Filter/MyWorkPackagesFilters';
import {ThunkAct} from '../../Store/Thunk';
import {MyTasksWorkPackageGroupAndSortBy} from '../MyTasks/WpOrSwpGrouping';
import {setUiState} from '../Ui';

export type MyTasksListSection = 'calendarEvents' | 'inTimeFrame' | 'notPlanned' | 'workload';
export type MyTasksListTimeFrame = 'today' | '7days' | '30days' | 'all';

export type FilterPreset = number | 'mine' | 'advanced';

export interface MyTasksTimelineExpandedLanes {
  idLane: Record<string, boolean>; //show/hide lane by an arbitrary/dynamic id
  personalTasks: boolean;
  workLoad: boolean;
  workPackages: boolean;
}

export interface MyTasksUiState {
  advancedFilters: MyTasksFilterSet['filters'];
  closedWorkPackageGroups: Record<string, boolean | undefined>; //{groupKey: isClosed}
  filterPreset: FilterPreset;
  listExpandedSections: Record<MyTasksListSection, boolean>;
  listSectionOrder: MyTasksListSection[];
  listShowTasks: boolean;
  listTimeFrame: MyTasksListTimeFrame;
  manualWorkPackageSortOrder: Uuid[];
  search: string;
  showBlockedTasks: boolean;
  showConnectedCalendarDemoData: boolean;
  showEmptyTaskSections: boolean;
  showDueDates: boolean;
  showTasksOfOtherUsers: boolean;
  timelineExpandedLanes: MyTasksTimelineExpandedLanes;
  timelineShowTasks: boolean;
  timelineStartDate: DateStr;
  visibleTasks: VisibleTasks;
  workPackageGroupAndSortBy: MyTasksWorkPackageGroupAndSortBy;
}

export const initialMyTasksUiState: MyTasksUiState = {
  advancedFilters: {},
  closedWorkPackageGroups: {},
  filterPreset: -1, //overwritten by workspaceActivePresetSelector
  listExpandedSections: {
    calendarEvents: true,
    inTimeFrame: true,
    notPlanned: true,
    workload: true,
  },
  listSectionOrder: ['inTimeFrame', 'notPlanned', 'calendarEvents', 'workload'],
  listShowTasks: true,
  listTimeFrame: 'today',
  manualWorkPackageSortOrder: [],
  search: '',
  showBlockedTasks: true,
  showConnectedCalendarDemoData: false,
  showDueDates: true,
  showEmptyTaskSections: true,
  showTasksOfOtherUsers: true,
  timelineExpandedLanes: {
    idLane: {},
    personalTasks: true,
    workLoad: true,
    workPackages: true,
  },
  timelineShowTasks: true,
  timelineStartDate: toIsoFormat(dayjs()),
  visibleTasks: 'all',
  workPackageGroupAndSortBy: 'alphabetical',
};

export function patchMyTasksUiState(patch: DeepPartial<MyTasksUiState>, force = false): ThunkAct<void> {
  return setUiState({pages: {myTasks: patch}}, force);
}

export function usePatchMyTasksUiState(): (patch: DeepPartial<MyTasksUiState>, force?: boolean) => void {
  const dispatch = useDispatch();
  return useCallback((myTasks, force = false) => dispatch(patchMyTasksUiState(myTasks, force)), [dispatch]);
}
