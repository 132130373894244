import {TaskGroupProps} from '@octaved/flow/src/Components/Task/TaskGroup/TaskGroup';
import {getTaskSectionSelector} from '@octaved/flow/src/Modules/Selectors/TaskSectionSelectors';
import {taskListSectionColorSelector} from '@octaved/flow/src/Modules/Selectors/Ui/TaskListSelectors';
import {useDarkenColor, useHexContrast} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import {forwardRef, ReactElement} from 'react';
import {useSelector} from 'react-redux';

export default forwardRef<HTMLDivElement, TaskGroupProps>(function TaskGroup({id, children}, ref): ReactElement {
  const sectionColors = useSelector(taskListSectionColorSelector);
  const hasIndividualColor = Boolean(sectionColors[id]);
  const bottomColor = sectionColors[id] ?? '#FFF';
  const topColor = useDarkenColor(bottomColor, 5);
  const gradient = `linear-gradient(0deg, ${topColor} 0%, ${bottomColor} 100%)`;
  const contrastColor = useHexContrast(bottomColor);
  const section = useSelector(getTaskSectionSelector)(id);

  return (
    <div className={'taskGroup'} ref={ref}>
      <div className={'groupHeader'}>
        <div className={'groupName'}>{section?.name}</div>
      </div>
      <div className={'contents'}>{children}</div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .taskGroup {
          border-radius: 8px;
          background: #fff;
        }

        .groupName {
          font-weight: 500;
          color: ${hasIndividualColor ? contrastColor : '#333'};
        }

        .groupHeader {
          padding: 6px 8px;
          display: flex;
          grid-gap: 4px;
          align-items: center;
          line-height: 16px;
          border-radius: 4px;
          color: ${hasIndividualColor ? contrastColor : '#333'};
          background: ${hasIndividualColor ? gradient : '#ECF0F0'};
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
});
