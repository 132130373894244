import {isEqual} from 'lodash';
import {PlanningPatchData} from '../../Modules/Planning';
import UpdatePlanningState from './UpdatePlanningState';

export function updateLogicalDependencies(state: UpdatePlanningState, updateData: PlanningPatchData): void {
  if (updateData.logicalDependencies) {
    const node = state.getNode(updateData.nodeId);

    if (node) {
      const oldPlanningLogicalPredecessors = node.planningLogicalPredecessors;
      const logicalDependencies = updateData.logicalDependencies;
      const add = logicalDependencies.filter((id) => !oldPlanningLogicalPredecessors.includes(id));
      const remove = oldPlanningLogicalPredecessors.filter((id) => !logicalDependencies.includes(id));
      for (const id of add) {
        const oldNode = state.getNode(id);
        if (oldNode) {
          state.setNode({
            ...oldNode,
            planningLogicalSuccessors: [...oldNode.planningLogicalSuccessors, updateData.nodeId],
          });
        }
      }
      for (const id of remove) {
        const oldNode = state.getNode(id);
        if (oldNode) {
          state.setNode({
            ...oldNode,
            planningLogicalSuccessors: oldNode.planningLogicalSuccessors.filter((c) => c !== updateData.nodeId),
          });
        }
      }

      if (!isEqual(node.planningLogicalPredecessors, logicalDependencies)) {
        state.setNode({
          ...node,
          planningLogicalPredecessors: logicalDependencies,
        });
      }
    }
  }
}
