import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {useBuildProjectTree} from './BuildProjectTree';
import {useExpandedNodesPools} from './ExpandedNodesPools';
import {useExpandToSelection} from './ExpandToSelection';
import {useLoadExpandedNodes} from './LoadExpandedNodes';
import {useProjectsGrouping} from './ProjectsGrouping';
import {useSetProjectTreeApi} from './ProjectTreeApi';
import {useProjectTreeFilters} from './ProjectTreeFilters';
import {ProjectTree, ProjectTreeOptions} from './ProjectTreeInterfaces';
import {useSortedTreeProjects} from './SortedTreeProjects';

/**
 * @param options object itself does not have to be memo'ed, but all the top level keys should.
 */
export function useProjectTree(options: ProjectTreeOptions): ProjectTree {
  const {
    groupIds,
    directMatchingParentIds,
    isLoading: areFiltersLoading,
    nodeIds,
    projectIds,
    searchedNodeIds,
    searchedNodeIdsForConcealedProperties,
    searchedNodeIdsTrace,
    workPackageIds,
  } = useProjectTreeFilters(options);

  const [expandedNodeIds, setExpandedNodeIds, setSearchExpandedNodeIds] = useExpandedNodesPools(
    options,
    areFiltersLoading,
    searchedNodeIdsTrace,
  );

  const {isLoading: areNodesLoading, assignmentsOnNodes} = useLoadExpandedNodes(
    options,
    expandedNodeIds,
    nodeIds,
    projectIds,
    groupIds,
    workPackageIds,
  );
  const {allNodes, treeProjects} = useBuildProjectTree(
    options,
    areFiltersLoading, //we want the tree structure to build already withouth the entities
    projectIds,
    groupIds,
    workPackageIds,
    searchedNodeIds,
    searchedNodeIdsForConcealedProperties,
    directMatchingParentIds,
    assignmentsOnNodes,
    expandedNodeIds,
    setExpandedNodeIds,
  );

  const canReadCustomers = useIsGranted('FLOW_GLOBAL_CUSTOMERS_READ');
  let sortBy = options.sortBy || 'alphanumeric';
  if (!canReadCustomers && sortBy === 'customer') {
    sortBy = 'alphanumeric';
  }

  const sortedTreeProjects = useSortedTreeProjects(treeProjects, sortBy);

  const {flat, grouped, isFullyGrouped} = useProjectsGrouping(
    sortedTreeProjects,
    options.useGrouping,
    sortBy,
    options.selectNodeId,
    options.selectedNodeId,
    expandedNodeIds,
    setExpandedNodeIds,
    areFiltersLoading,
    options.searchTerm,
    searchedNodeIds,
    searchedNodeIdsTrace,
    setSearchExpandedNodeIds,
    allNodes,
  );

  useExpandToSelection(options, setExpandedNodeIds, grouped, isFullyGrouped);

  useSetProjectTreeApi(options, projectIds, groupIds, workPackageIds);

  const isLoading = areFiltersLoading || areNodesLoading;
  const hasLoadedOnce = useLoadedValue(isLoading, !isLoading);

  return {
    allNodes,
    flat,
    grouped,
    hasLoadedOnce,
    isLoading,
  };
}
