import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {FieldCondition, Notification, notificationFieldMatches} from '@octaved/store/src/Notifications';
import {cn} from '@octaved/ui';
import {ReactElement, useMemo} from 'react';
import {Message} from 'semantic-ui-react';

interface Props {
  className?: string;
  errors: Notification[];
  includeFields?: FieldCondition[];
  noMargin?: boolean;
  warning?: boolean;
}

export default function FormErrors({className, errors, includeFields, noMargin, warning}: Props): ReactElement | null {
  const filtered = useMemo(() => {
    //Filter out non-included fields and also limit the messages to be unique because we only show the message itself
    const msgMap = new Map<ComplexTranslatable, Notification>();
    errors.forEach((item) => {
      if (!item.field || !includeFields || notificationFieldMatches(item, includeFields)) {
        msgMap.set(item.message, item);
      }
    });
    return [...msgMap.values()];
  }, [errors, includeFields]);

  if (filtered.length > 0) {
    return (
      <div className={cn('mb-5', noMargin && 'mb-0', className)}>
        <Message error={!warning} warning={warning} visible>
          {/* Errors */}
          {filtered.length > 1 && (
            <Message.List>
              {filtered.map(({message, field}) => (
                <Message.Item key={field! + message}>
                  <ComplexTrans translatable={message} />
                </Message.Item>
              ))}
            </Message.List>
          )}
          {filtered.length === 1 && <ComplexTrans translatable={filtered[0].message} />}
        </Message>
      </div>
    );
  }

  return null;
}
