import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {KeyDownListenerMap} from '@octaved/ui-components/src/Hooks/UseKeyboardEventListener';
import classNames from 'classnames';
import {MutableRefObject, ReactElement, ReactNode, useState} from 'react';
import {useSelector} from 'react-redux';
import {TaskPatchData} from '../../../../../EntityInterfaces/Task';
import {getTaskIsAutoChainedSelector} from '../../../../../Modules/Selectors/TaskSelectors';
import {useTaskListReadonlyContext} from '../../../TaskListReadonlyContext';
import TaskStatus from '../../../TaskStatus';
import {useStaticDragContext} from '../../DragAndDrop/DragContext';
import DragHandle from '../../DragAndDrop/DragHandle';
import {OnEnterKeyDown} from '../../EditName';
import {ShallowTask} from '../ShallowTask';
import LogicalDependency from './LogicalDependency/LogicalDependency';
import EditTaskName from './NameCell/EditTaskName';

const TASK_ROW_INDENT = 47;

interface Props {
  autoFocus?: boolean;
  depth: number;
  dragRef?: MutableRefObject<HTMLDivElement | null>;
  extendKeyDownMap?: KeyDownListenerMap;
  isSingleColumn?: boolean;
  name: string;
  note?: ReactNode;
  onBlur?: () => void;
  onEnterKeyDown?: OnEnterKeyDown;
  patch: (data: Partial<TaskPatchData>) => void;
  patchName: (name: string) => void;
  rightStruct: ReactNode;
  task?: ShallowTask; // Only used for task status
  toggle?: ReactNode;
}

export default function NameCell({
  autoFocus = false,
  depth,
  dragRef,
  extendKeyDownMap,
  isSingleColumn,
  name,
  note,
  onBlur,
  onEnterKeyDown,
  patch,
  patchName,
  rightStruct,
  task,
  toggle,
}: Props): ReactElement {
  const {readonly, readonlyOrNotManageable} = useTaskListReadonlyContext();
  const [focused, setFocused] = useState(autoFocus);
  const {isSet} = useStaticDragContext();
  const isAutoChainActive = useSelector(getTaskIsAutoChainedSelector)(task?.id);

  return (
    <div
      className={classNames('cell nameCell', {
        focused,
        isSingleColumn,
        draggable: !readonlyOrNotManageable,
        readonly: readonlyOrNotManageable,
      })}
    >
      <div className={'leftStructure'}>
        {isSet && <DragHandle dragRef={dragRef} />}
        {toggle}
        {task && <TaskStatus task={task} patch={patch} readonly={readonly} />}
        <EditTaskName
          name={name}
          setFocused={setFocused}
          focused={focused}
          patch={patchName}
          onBlur={onBlur}
          onEnterKeyDown={onEnterKeyDown}
          extendKeyDownMap={extendKeyDownMap}
        />
      </div>

      <div className={'rightStructure'}>
        <div className={'rightDetailsStructure'}>{rightStruct}</div>
        {note}
        {task &&
          Boolean(
            task.planningLogicalSuccessors.length || task.planningLogicalPredecessors.length || isAutoChainActive,
          ) && (
            <LogicalDependency
              taskId={task.id}
              taskIsDone={task.status === EnumFlowTaskStatus.VALUE_COMPLETE}
              hasDependency
              planningLogicalPredecessors={task.planningLogicalPredecessors}
              planningLogicalSuccessors={task.planningLogicalSuccessors}
            />
          )}
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .nameCell {
          --drag-handle-vertical-border-width: 1px;

          &.draggable:hover {
            --drag-handle-vertical-border-color: transparent;
          }
        }

        .leftStructure {
          overflow: hidden;
          flex-grow: 1;
        }

        .cell,
        .leftStructure,
        .rightStructure,
        .rightDetailsStructure {
          display: flex;
          align-items: center;
          grid-gap: 0.25rem;
        }

        .rightDetailsStructure {
          opacity: 0;
        }

        .cell {
          justify-content: space-between;
          border: 1px solid var(--cell-border);
          border-left: none;
          margin-right: -1px;
          padding: 0 var(--cell-padding) 0 ${`calc(var(--drag-handle-width) + ${depth * TASK_ROW_INDENT}px)`};
          font-size: 12px;
          transition: border-color 170ms ease-in-out;
          flex-grow: 1;
          min-width: 450px;
          grid-gap: 10px;

          &.isSingleColumn {
            border-right: none;
          }
        }

        .cell.focused,
        .cell.focused:not(.readonly):hover,
        .cell:focus-within {
          background-color: var(--color-bg);
          border-top-color: var(--color-border-focus);
          border-bottom-color: var(--color-border-focus);
          border-right-color: var(--color-border-focus);
          position: relative;
          z-index: 2;
        }

        .cell.focused .rightDetailsStructure,
        .cell.focused:not(.readonly):hover .rightDetailsStructure,
        .cell:focus-within .rightDetailsStructure,
        .cell:hover .rightDetailsStructure {
          display: flex;
          opacity: 1;
          align-items: center;
          grid-gap: 0.25rem;
        }

        .cell:not(.readonly):hover {
          position: relative;
          z-index: 1;
          border-color: var(--color-border-hover);

          &.isSingleColumn {
            margin-right: -24px;
          }
        }

        .cell :global(.onHover) {
          visibility: hidden;
          pointer-events: none;
        }

        .cell.focused :global(.onHover),
        .cell:hover :global(.onHover),
        .cell:focus-within :global(.onHover) {
          visibility: visible;
          pointer-events: all;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
