import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {searchNodesByIdentBulk} from '@octaved/flow-api';
import {post} from '@octaved/network/src/Network';
import {ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {multiAction} from '@octaved/store/src/MultiAction';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {USER_RIGHTS_CHANGED} from '@octaved/users/src/ActionTypes';
import {
  concatReducers,
  createSearchKeyReducerFactory,
  reduceUuidSearchResponse,
  UuidSearchResults,
} from '@octaved/utilities/src/Search/SearchReducers';
import {debounce} from 'lodash';
import {Action} from 'redux';
import {NodeCreationOptions} from '../EntityInterfaces/Nodes';
import {NodeSearchIdent, NodeSearchIdentWithoutValue} from '../EntityInterfaces/NodeSearch';
import {
  FLOW_COPY_PID_REQUEST,
  FLOW_COPY_PID_SUCCESS,
  FLOW_CREATE_GROUP_SUCCESS,
  FLOW_CREATE_NODE_REQUEST,
  FLOW_CREATE_PROJECT_SUCCESS,
  FLOW_CREATE_TIME_RECORD_SUCCESS,
  FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
  FLOW_DELETE_BILLING_SUCCESS,
  FLOW_PATCH_TIME_RECORD_SUCCESS,
  FLOW_SEARCH_NODE_REQUEST,
  FLOW_SEARCH_NODE_SUCCESS,
  FLOW_TODAY_CHANGED,
} from './ActionTypes';
import {
  CopyPidRequestEvent,
  CustomerPatchedEvent,
  NodeArchivedEvent,
  NodePatchedEvent,
  NodeRoleAssignmentEvent,
  NodesRearrangeEvent,
  NodesRemovedEvent,
  TaskSectionsMovedEvent,
  TasksMovedEvent,
  WorkPackagesFlagsChangedEvent,
} from './Events';
import {NodeSearchSuccess, reduceAddOptional} from './NodeSearchReducers/GenericAddRemove';
import {reduceRemoveIdsByIdentValue, reduceRemoveIdsFromAllSearches} from './NodeSearchReducers/GenericRemove';
import {rootReduceSearchResults} from './NodeSearchReducers/RootReducers';
import {getNodeSearchKey, nodeSearchSelector, nodeSearchStateSelector} from './Selectors/NodeSearchSelectors';
import {getAllDescendantIdsSelector} from './Selectors/NodeTreeSelectors';
import {FlowState} from './State';

export const nodeSearchResultsReducers = createReducerCollection<UuidSearchResults>({});
nodeSearchResultsReducers.add<NodeSearchSuccess>(FLOW_SEARCH_NODE_SUCCESS, reduceUuidSearchResponse);
nodeSearchResultsReducers.add<NodesRemovedEvent>('flow.NodesRemovedEvent', (state, {nodeIds}) =>
  reduceRemoveIdsFromAllSearches(state, nodeIds),
);
export const nodeSearchResultsReducer = nodeSearchResultsReducers.reducer;

nodeSearchResultsReducers.add<ServerRequestAction<NodeCreationOptions>>(
  FLOW_CREATE_NODE_REQUEST,
  (state, {options: {data}}) => {
    return reduceAddOptional(state, data.id, 'nodeType', data.nodeType);
  },
);

nodeSearchResultsReducers.add<CopyPidRequestEvent>(FLOW_COPY_PID_REQUEST, (state, {copyNode: {id, nodeType}}) => {
  return reduceAddOptional(state, id, 'nodeType', nodeType);
});

const createReducer = createSearchKeyReducerFactory<NodeSearchIdent>();
export const reduceClearKeys = createReducer<{type: string}, never>();

export const nodeSearchStateReducers = createReducerCollection<EntityStates>({});
nodeSearchStateReducers.add(FLOW_SEARCH_NODE_REQUEST, createTimestampReducer('keys', LOADING));
nodeSearchStateReducers.add(FLOW_SEARCH_NODE_SUCCESS, createTimestampReducer('key', LOADED));
nodeSearchStateReducers.add(USER_RIGHTS_CHANGED, () => ({}));

const reduceNodePatched = createReducer<NodePatchedEvent, 'patchedKeys'>('patchedKeys');
nodeSearchStateReducers.add(
  'flow.NodePatchedEvent',
  concatReducers([
    reduceNodePatched(['name'], ['name']),
    reduceNodePatched(['labelId'], ['labels']),
    reduceNodePatched(['referenceNumber'], ['referenceNumber']),
  ]),
);

//Board post specials - would be better to have the board post type here and a dedicated event:
nodeSearchStateReducers.add<NodePatchedEvent>(
  'flow.NodePatchedEvent',
  concatReducers([reduceNodePatched(['bpWaitingForMyResponse'], ['data'])]),
);

nodeSearchStateReducers.add('flow.NodesRemovedEvent', reduceClearKeys(['taskBlockedByDependency']));
nodeSearchStateReducers.add<NodeRoleAssignmentEvent>('flow.NodeRoleAssignmentEvent', (state, action) => {
  if (action.roleType === EnumFlowNodeRoleType.VALUE_PROJECT) {
    return reduceClearKeys(['assignedAnyProjectRole', 'assignedProjectRoleId', 'assignedProjectRoleType'])(
      state,
      action,
    );
  }
  return state;
});
nodeSearchStateReducers.add(
  ['flow.BillingCreatedEvent', 'flow.BillingRemovedEvent', FLOW_DELETE_BILLING_SUCCESS],
  reduceClearKeys([
    'trRefHasNonBilledTimeTrackingRecords',
    'trRefHasNonBilledTimeTrackingRecordsInDateRange',
    'wpHasBillingsInPeriod',
    'wpHasFinalBillings',
  ]),
);

nodeSearchStateReducers.add(
  ['flow.MaterialResourcePatchedEvent', 'flow.MaterialResourceCreatedEvent'],
  reduceClearKeys(['mrIsActive']),
);
nodeSearchStateReducers.add(
  ['flow.TimeRecordCreatedEvent', 'flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  reduceClearKeys([
    'trRefHasNonBilledTimeTrackingRecords',
    'trRefHasNonBilledTimeTrackingRecordsInDateRange',
    'trRefHasOpenTimeTrackingRecords',
    'trRefHasOpenTimeTrackingRecordsInDateRange',
    'trRefHasTimeTrackingRecords',
    'trRefHasTimeTrackingRecordsInDateRange',
    'wpTaskTimePrognosis',
    'wpTimeTrackingLimit', //depends on trackedMinutes.billed to determine if a wp has started
  ]),
);
nodeSearchStateReducers.add(FLOW_TODAY_CHANGED, reduceClearKeys(['timeControlExceeded', 'timeControlNotStarted']));
nodeSearchStateReducers.add('flow.SettingsChangedEvent', reduceClearKeys(['wpTimeTrackingLimit']));

nodeSearchStateReducers.add<WorkPackagesFlagsChangedEvent>('flow.WorkPackagesFlagsChangedEvent', (state, action) => {
  const keys: NodeSearchIdentWithoutValue[] = [];
  if (typeof action.flags.isApprovedForBilling === 'boolean') {
    keys.push('wpIsApprovedForBilling');
  }
  if (typeof action.flags.isLocked === 'boolean') {
    keys.push('wpIsLocked');
  }
  if (typeof action.flags.isOffer === 'boolean') {
    keys.push('wpIsOffer');
  }
  return reduceClearKeys(keys)(state, action);
});

nodeSearchStateReducers.add(
  'UserGroupDelegationEvent',
  reduceClearKeys([
    'assignedAnyProjectRole',
    'assignedProjectRoleId',
    'assignedProjectRoleType',
    'responsibleByAny',
    'responsibleUnitId',
  ]),
);
nodeSearchStateReducers.add(
  'flow.RoleRemovedEvent',
  reduceClearKeys(['assignedAnyProjectRole', 'assignedProjectRoleId', 'assignedProjectRoleType']),
);
nodeSearchStateReducers.add('flow.RolePatchedEvent', reduceClearKeys(['assignedProjectRoleType']));

nodeSearchStateReducers.add(
  ['flow.TasksMovedEvent', 'flow.TaskSectionsMovedEvent'],
  (state: EntityStates, action: TasksMovedEvent | TaskSectionsMovedEvent) => {
    let newState = state;
    newState = reduceClearKeys(['taskBlockedByDependency'])(newState, action);
    if (Object.keys(action.changedRootNodes).length) {
      newState = reduceClearKeys([
        'assignedAnyProjectRole',
        'assignedProjectRoleId',
        'assignedProjectRoleType',
        'responsibleByAny',
        'responsibleUnitId',
      ])(newState, action);
    }
    return newState;
  },
);
nodeSearchStateReducers.add<NodesRearrangeEvent>('flow.NodesRearrangeEvent', (state, action) => {
  let newState = state;
  if (action.oldParentNodeId !== action.newParentNodeId) {
    newState = reduceClearKeys([
      'assignedAnyProjectRole',
      'assignedProjectRoleId',
      'assignedProjectRoleType',
      'responsibleByAny',
      'responsibleUnitId',
    ])(newState, action);
  }
  return newState;
});

//Must reload in addition to the optimistic reducer because that reducer only works for loaded nodes:
nodeSearchStateReducers.add(
  ['flow.NodesResponsibilitiesBulkPatchedEvent'],
  reduceClearKeys(['responsibleByAny', 'responsibleUnitId']),
);

nodeSearchStateReducers.add(
  [FLOW_CREATE_TIME_RECORD_SUCCESS, FLOW_PATCH_TIME_RECORD_SUCCESS],
  reduceClearKeys(['trRefUserRecentlyBookedOn', 'wpUserRecentlyBookedOn']),
);

nodeSearchStateReducers.add(
  [FLOW_CREATE_PROJECT_SUCCESS, FLOW_CREATE_GROUP_SUCCESS, FLOW_CREATE_WORK_PAGKAGE_SUCCESS, FLOW_COPY_PID_SUCCESS],
  reduceClearKeys(['pidPid']),
);

const reduceCustomerPatched = createReducer<CustomerPatchedEvent, 'patchedProperties'>('patchedProperties');
nodeSearchStateReducers.add(
  'flow.CustomerPatchedEvent',
  concatReducers([
    reduceCustomerPatched(['customerIsBillable'], ['isInternal', 'requiresInternalCharge']),
    reduceCustomerPatched(['prCustomerLocationId', 'prCustomerLocationNumber'], ['locations']),
  ]),
);

export const nodeSearchStateReducer = nodeSearchStateReducers.reducer;

const nodeSearchRootReducers = createReducerCollection<FlowState>({} as FlowState);

nodeSearchRootReducers.add<NodeArchivedEvent>('flow.NodeArchivedEvent', (state, {nodeId, archived}) => {
  const descendantIds = getAllDescendantIdsSelector(state)(nodeId, true);
  let nextResults = nodeSearchSelector(state);
  if (archived) {
    nextResults = reduceAddOptional(nextResults, descendantIds, 'isArchived');
  } else {
    nextResults = reduceRemoveIdsByIdentValue(nextResults, descendantIds, 'isArchived');
  }
  return rootReduceSearchResults(state, nextResults);
});

export const nodeSearchRootReducer = (state: FlowState, action: Action): FlowState => {
  return nodeSearchRootReducers.reducer(state, action);
};

type R = ActionDispatcher<void, FlowState>;

interface SearchRequest {
  key: string;
  ident: string;
  value: string;
}

const searchNodesDebounceStack = new Map<string, SearchRequest>();
const searchNodesDebounced = debounce(async (dispatch: Dispatch) => {
  const jobs = [...searchNodesDebounceStack.values()];
  searchNodesDebounceStack.clear();
  const result = await post<Record<string, string[]>>(searchNodesByIdentBulk, {data: jobs});
  dispatch(
    multiAction(Object.entries(result).map(([key, response]) => ({key, response, type: FLOW_SEARCH_NODE_SUCCESS}))),
  );
}, 5);

export function searchNodes(searches: ReadonlyArray<[ident: NodeSearchIdent, value?: string]>): R {
  return (dispatch, getState) => {
    const keysToLoad: string[] = [];
    searches.forEach(([ident, value]) => {
      const key = getNodeSearchKey(ident, value);
      if (isOutdated(nodeSearchStateSelector(getState())[key] || {})) {
        keysToLoad.push(key);
        searchNodesDebounceStack.set(key, {key, ident, value: value || ''});
        searchNodesDebounced(dispatch);
      }
    });
    if (keysToLoad.length) {
      dispatch({keys: keysToLoad, type: FLOW_SEARCH_NODE_REQUEST});
    }
  };
}
