//@see https://css-tricks.com/converting-color-spaces-in-javascript/#hex-to-hsl

import {useMemo} from 'react';

interface Params {
  h: number;
  s: number;
  l: number;
}

function hexToHsl(H: string): Params {
  // Convert hex to RGB first
  let r = '',
    g = '',
    b = '';
  if (H.length == 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length == 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  const rV = ((r as unknown as number) /= 255);
  const gV = ((g as unknown as number) /= 255);
  const bV = ((b as unknown as number) /= 255);
  const cmin = Math.min(rV, gV, bV);
  const cmax = Math.max(rV, gV, bV);
  const delta = cmax - cmin;
  let h, s, l;

  if (delta == 0) {
    h = 0;
  } else if (cmax === rV) {
    h = ((gV - bV) / delta) % 6;
  } else if (cmax === gV) {
    h = (bV - rV) / delta + 2;
  } else {
    h = (rV - gV) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    h,
    l,
    s,
  };
}

export function requireLightColor(inputColor: string): boolean {
  const color = inputColor.charAt(0) === '#' ? inputColor.substring(1, 7) : inputColor;
  //The formula is not 100%, these known label values being manually adjusted
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB

  // Gitlab solution
  return r + g + b <= 500;

  /*
   * Calculates contrast based on w3c recommendations
   * Not used because some contrast were not good with healthy eyes. they may be better for sick eyes
   *
   * https://www.w3.org/TR/WCAG20/ -> "contrast ratio"
   * https://www.w3.org/TR/WCAG20/#relativeluminancedef
   * https://en.wikipedia.org/wiki/Luma_(video)#Rec._601_luma_versus_Rec._709_luma_coefficients

    const sRGBColors = [r / 255, g / 255, b / 255]; // rgb to sRGB
    const [sR, sG, sB] = sRGBColors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = (0.2126 * sR) + (0.7152 * sG) + (0.0722 * sB);
    return L <= 0.179;
    */
}
export function getContrastColor(color: string): string {
  return requireLightColor(color) ? '#fff' : '#000';
}

//@deprecated - use getContrastColor
export function useHexContrast(color: string): string {
  return requireLightColor(color) ? '#fff' : '#000';
}

export function getDarkenColor(color: string, lChange = 10): string {
  const code = color.startsWith('#') ? color : '#' + color;
  const {h, s, l} = hexToHsl(code);
  return `hsl(${h}, ${s}%, ${l - lChange}%)`;
}

export function useDarkenColor(color: string, lChange = 10): string {
  return useMemo(() => getDarkenColor(color, lChange), [color, lChange]);
}
