import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {
  InvertedNodeTree,
  nodeTreeInvertedSelector,
  nodeTreeSelector,
} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {setUiState, UiState} from '@octaved/flow/src/Modules/Ui';
import {isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {
  getExtendedNodesForBaselineSelector,
  projectPlanningExtendedNodesSelector,
  projectPlanningGroupByCustomerSelector,
  projectPlanningSelectedProjectsSelector,
} from '../Selectors/UiSelectors';
import {setBaselineSettingsForNode} from './Ui';

export enum ExpandCollapseStrategy {
  expandAll,
  collapseAll,
  collapseBelowWorkPackage,
}

function getNodeIdsToOpen(
  parentNodeId: Uuid,
  nodeTreeInverted: InvertedNodeTree,
  getNode: ReturnType<typeof getNodeSelector>,
  result: Uuid[] = [],
): Uuid[] {
  const childIds = nodeTreeInverted.get(parentNodeId);
  if (childIds) {
    result.push(parentNodeId); // if there are no children there is nothing to open
    for (const childId of childIds) {
      getNodeIdsToOpen(childId, nodeTreeInverted, getNode, result);
    }
  }
  const node = getNode(parentNodeId);
  if (node && node.assignedPlanningDates.length > 0) {
    result.push(parentNodeId);
  }
  return result;
}

export function updateProjectsForProjectPlanning(
  projectIds: readonly string[] | null,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    if (!projectIds) {
      dispatch(setUiState({pages: {planning: {projectPlanningSelectedProjects: null}}}));
      return;
    }
    const state = getState();

    //Filter out non-existant node ids to self-heal localstorage:
    let uiPatch: DeepPartial<UiState> = {
      pages: {planning: {projectPlanningSelectedProjects: projectIds}},
    };

    const projectPlanningExtendedNodes: Record<Uuid, boolean> = {};
    for (const projectId of projectIds) {
      extendInitialSubNodeTree(projectId, state, projectPlanningExtendedNodes);
    }
    uiPatch = mergeStates(uiPatch, {pages: {planning: {projectPlanningExtendedNodes}}});

    dispatch(setUiState(uiPatch));
  };
}

export function selectProjectForProjectPlanning(projectId: Uuid, customerId: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    //Add the selected project:
    const current = new Set(projectPlanningSelectedProjectsSelector(state));
    if (current.size === 0) {
      // If the filter is not active, we don't need to add the project
      return;
    }
    current.add(projectId);

    //Filter out non-existant node ids to self-heal localstorage, but keep the just created project even if it is not in the tree, yet:
    const tree = nodeTreeSelector(state);
    let uiPatch: DeepPartial<UiState> = {
      pages: {
        planning: {
          projectPlanningSelectedProjects: [...current].filter((id) => projectId === id || !!tree[id]).toSorted(),
        },
      },
    };

    const projectPlanningExtendedNodes: Record<Uuid, boolean> = {};
    const groupByCustomer = projectPlanningGroupByCustomerSelector(state);
    extendInitialSubNodeTree(projectId, state, projectPlanningExtendedNodes);
    if (groupByCustomer) {
      projectPlanningExtendedNodes[customerId] = true;
    }
    uiPatch = mergeStates(uiPatch, {pages: {planning: {projectPlanningExtendedNodes}}});

    dispatch(setUiState(uiPatch));
  };
}

function extendInitialSubNodeTree(rootNodeId: Uuid, state: FlowState, extendedNodes: Record<Uuid, boolean>): void {
  const initialExtendedNodes = projectPlanningExtendedNodesSelector(state) || {};
  const nodeTreeInverted = nodeTreeInvertedSelector(state);
  const getNode = getNodeSelector(state);
  const nodeIdsToOpen = getNodeIdsToOpen(rootNodeId, nodeTreeInverted, getNode);

  for (const id of nodeIdsToOpen) {
    if (!(id in initialExtendedNodes)) {
      const extend = extendNode(id, ExpandCollapseStrategy.collapseBelowWorkPackage, getNode, true);
      extendedNodes[id] = extend;
    }
  }
}

function extendNode(
  nodeId: Uuid,
  strategy: ExpandCollapseStrategy,
  getNode: ReturnType<typeof getNodeSelector>,
  initialValue: boolean | undefined,
): boolean {
  if (strategy === ExpandCollapseStrategy.expandAll || strategy === ExpandCollapseStrategy.collapseAll) {
    return strategy === ExpandCollapseStrategy.expandAll;
  }
  const node = getNode(nodeId);
  if (node) {
    if (
      [
        EnumFlowNodeType.VALUE_WORK_PACKAGE,
        EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
        EnumFlowNodeType.VALUE_TASK,
        EnumFlowNodeType.VALUE_TASK_SECTION,
        EnumFlowNodeType.VALUE_MATERIAL_RESOURCE,
      ].includes(node.nodeType)
    ) {
      //only collapse below workpackage, ignore the rest
      return false;
    }
    return Boolean(initialValue);
  }
  return false;
}

function extendSubNodeTree(
  rootNodeId: Uuid,
  strategy: ExpandCollapseStrategy,
  state: FlowState,
  extendedNodes: Record<Uuid, boolean>,
  initialExtendedNodes: Record<Uuid, boolean>,
): void {
  const nodeTreeInverted = nodeTreeInvertedSelector(state);
  const getNode = getNodeSelector(state);
  const nodeIdsToOpen = getNodeIdsToOpen(rootNodeId, nodeTreeInverted, getNode);

  for (const id of nodeIdsToOpen) {
    const extend = extendNode(id, strategy, getNode, initialExtendedNodes[id]);
    extendedNodes[id] = extend;
  }
}

export function expandCollapseProjectPlanningNodes(
  strategy: ExpandCollapseStrategy,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProjects = projectPlanningSelectedProjectsSelector(state);
    const getNode = getNodeSelector(state);
    const groupByCustomer = projectPlanningGroupByCustomerSelector(state);
    const initialExtendedNodes = projectPlanningExtendedNodesSelector(state) || {};

    const projectPlanningExtendedNodes: Record<Uuid, boolean> = {};
    for (const projectId of selectedProjects) {
      const project = getNode(projectId);
      if (isProject(project)) {
        extendSubNodeTree(projectId, strategy, state, projectPlanningExtendedNodes, initialExtendedNodes);
        if (groupByCustomer && strategy === ExpandCollapseStrategy.expandAll) {
          projectPlanningExtendedNodes[project.flowCustomer] = true;
        }
      }
    }

    const uiPatch: DeepPartial<UiState> = {pages: {planning: {projectPlanningExtendedNodes}}};
    dispatch(setUiState(uiPatch));
  };
}

export function expandBaselinePlanningNodes(
  nodeId: Uuid,
  strategy: ExpandCollapseStrategy,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const initialExtendedNodes = getExtendedNodesForBaselineSelector(state)(nodeId) || {};

    const extendedNodes: Record<Uuid, boolean> = {};
    extendSubNodeTree(nodeId, strategy, state, extendedNodes, initialExtendedNodes);
    dispatch(setBaselineSettingsForNode(nodeId, {extendedNodes}));
  };
}
