import {INVALIDATED, reduceStateIds} from '@octaved/store/src/EntityState';
import {WorkPackagePatchedEvent} from '../Events';
import {responsibleProps} from '../ResponsibleNode';
import {getNodeSearchKey} from '../Selectors/NodeSearchSelectors';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<WorkPackagePatchedEvent>('flow.WorkPackagePatchedEvent', {
  assignedAnyProjectRole: 'ignored',
  assignedProjectRoleId: 'ignored',
  assignedProjectRoleType: 'ignored',
  bpWaitingForMyResponse: 'ignored',
  customerId: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  customerIsBillable: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  customerName: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  customerNumber: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  favorite: 'ignored',
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: (action) => ({
    states: (state) => {
      if (action.patchedKeys.includes('isCompleted')) {
        const key = getNodeSearchKey('hasLogicalPredecessor', 'incomplete');
        return reduceStateIds(state, action, INVALIDATED, [key]);
      }
      return state;
    },
  }),
  hasTimeBasedPredecessor: (action) => ({
    states: (state) => {
      if (action.patchedKeys.includes('isCompleted')) {
        return reduceStateIds(state, action, INVALIDATED, [
          getNodeSearchKey('hasTimeBasedPredecessor', 'incomplete'),
          getNodeSearchKey('hasTimeBasedPredecessor', 'incomplete:blocking'),
        ]);
      }
      return state;
    },
  }),
  isArchived: 'ignored',
  labelId: ({patchedKeys}) => (patchedKeys.includes('labels') ? 'clear' : 'ignored'),
  mrIsActive: 'ignored',
  name: ({patchedKeys}) => (patchedKeys.includes('name') ? 'clear' : 'ignored'),
  nodeNotPlanned: 'ignored',
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: 'ignored',
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: ({patchedKeys}) => (patchedKeys.includes('referenceNumber') ? 'clear' : 'ignored'),
  responsibleByAny: ({patchedKeys}) => (responsibleProps.some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored'),
  responsibleUnitId: ({patchedKeys}) => (responsibleProps.some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored'),
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: ({patchedKeys}) => (patchedKeys.includes('isAutoChainActive') ? 'clear' : 'ignored'),
  taskCompletedInDateRange: 'ignored',
  taskStatus: 'ignored',
  timeControlExceeded: ({patchedKeys}) => (patchedKeys.includes('timeControl') ? 'clear' : 'ignored'),
  timeControlNotStarted: ({patchedKeys}) => (patchedKeys.includes('timeControl') ? 'clear' : 'ignored'),
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: ({patchedKeys}) => (patchedKeys.includes('billingType') ? 'clear' : 'ignored'),
  wpCompletedInDateRange: ({patchedKeys}) => (patchedKeys.includes('isCompleted') ? 'clear' : 'ignored'),
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: ({patchedKeys}) => (patchedKeys.includes('isApprovedForBilling') ? 'clear' : 'ignored'),
  wpIsClosed: ({patchedKeys}) => (patchedKeys.includes('isClosed') ? 'clear' : 'ignored'),
  wpIsCompleted: ({patchedKeys}) => (patchedKeys.includes('isCompleted') ? 'clear' : 'ignored'),
  wpIsLocked: ({patchedKeys}) => (patchedKeys.includes('isLocked') ? 'clear' : 'ignored'),
  wpIsOffer: ({patchedKeys}) => (patchedKeys.includes('isOffer') ? 'clear' : 'ignored'),
  wpPriceCategory: ({patchedKeys}) => (patchedKeys.includes('priceCategory') ? 'clear' : 'ignored'),
  wpTaskTimePrognosis: ({patchedKeys}) =>
    (['billingType', 'effortTo', 'maxEffort'] as const).some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored',
  wpTimeTrackingLimit: ({patchedKeys}) =>
    (['billingType', 'effortTo', 'maxEffort'] as const).some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored',
  wpTimeTrackingReferenceNodeType: ({patchedKeys}) =>
    patchedKeys.includes('timeTrackingReferenceNodeType') ? 'clear' : 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
