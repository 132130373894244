import {useDebounce} from '@octaved/hooks';
import useResizeObserver from '@octaved/ui-components/src/Hooks/UseResizeObserver';
import {RefObject, useCallback, useEffect, useRef, useState} from 'react';

export function useElementClientRectCb(
  onChange: (rect: DOMRect) => void,
  container: RefObject<HTMLElement> | undefined | null,
  debounceWait = 300,
  dependencies: unknown[] = [],
): void {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const debouncedUpdate = useDebounce(
    useCallback(() => {
      if (container?.current) {
        onChangeRef.current(container.current.getBoundingClientRect());
      }
    }, [container]),
    debounceWait,
  );

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdate);
    return () => window.removeEventListener('resize', debouncedUpdate);
  }, [debouncedUpdate]);

  //Manually triggered update via dependencies
  useEffect(() => {
    debouncedUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdate, ...dependencies]);

  useResizeObserver(container?.current, debouncedUpdate, 0);
}

export function useElementClientRect(
  container: RefObject<HTMLElement> | undefined | null,
  debounceWait = 300,
  dependencies: unknown[] = [],
): DOMRect | null {
  const [domRect, setDomRect] = useState<DOMRect | null>(null);
  useElementClientRectCb(setDomRect, container, debounceWait, dependencies);
  return domRect;
}
