import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import {PlanningRealizationNode} from '@octaved/planning/src/EntityInterfaces/PlanningRealizationNode';
import {removeLogicalDependency} from '@octaved/planning/src/Modules/LogicalDependencies';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWButton} from '@octaved/ui';
import {MoreHorizontal, Trash} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useDispatch} from 'react-redux';

interface MenuProps {
  node: PlanningRealizationNode;
  predecessorId: Uuid;
}

export default function Menu({node, predecessorId}: MenuProps): ReactElement {
  const dispatch = useDispatch();

  const items = useMemo(() => {
    const items: DropdownPopupItemOptions[] = [
      {
        className: 'hover:!bg-red-50',
        icon: <Trash />,
        iconColor: 'red',
        onClick: () => {
          dispatch(removeLogicalDependency(node.id, predecessorId));
        },
        textColor: 'red',
        token: 'pages:projects.inspector.manage.planning.deleteDependency',
        type: DropdownPopupItemType.item,
      },
    ];

    return items;
  }, [dispatch, node.id, predecessorId]);

  return (
    <DropdownPopup items={items} position={'bottom left'}>
      <TWButton variant={'ghost'} size={'md'} iconOnly>
        <MoreHorizontal className={'size-4'} />
      </TWButton>
    </DropdownPopup>
  );
}
