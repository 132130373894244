import {ReactElement, SVGProps} from 'react';
import {curve, joinMovements, line, move} from './Movements';

export function rect(
  x: number,
  y: number,
  width: number,
  height: number,
  radiusTopLeft = 0,
  radiusTopRight = 0,
  radiusBottomLeft = 0,
  radiusBottomRight = 0,
): string {
  return joinMovements([
    move(x + radiusTopLeft, y), // move top left
    line(x + width - radiusTopRight, y), //top line
    radiusTopRight ? curve(x + width, y + radiusTopRight, x + width, y, x + width, y) : null, // top right corner
    line(x + width, y + height - radiusBottomRight), //right line
    radiusBottomRight
      ? curve(x + width - radiusBottomRight, y + height, x + width, y + height, x + width, y + height)
      : null, // bottom right corner
    line(x + radiusBottomLeft, y + height), //bottom line
    radiusBottomLeft ? curve(x, y + radiusBottomLeft - height, x, y + height, x, y + height) : null, // bottom left corner
    line(x, y + radiusTopLeft), //left line
    radiusTopLeft ? curve(x + radiusTopLeft, y, x, y, x, y) : null, // top left corner
  ]);
}

interface RoundedRectProps extends Omit<SVGProps<SVGPathElement>, 'd'> {
  x: number;
  y: number;
  width: number;
  height: number;
  radiusTopLeft?: number;
  radiusTopRight?: number;
  radiusBottomLeft?: number;
  radiusBottomRight?: number;
}

export function RoundedRect({
  x,
  y,
  width,
  height,
  radiusBottomLeft,
  radiusTopRight,
  radiusBottomRight,
  radiusTopLeft,
  ...pathProps
}: RoundedRectProps): ReactElement {
  return (
    <path
      d={rect(x, y, width, height, radiusTopLeft, radiusTopRight, radiusBottomLeft, radiusBottomRight)}
      {...pathProps}
    />
  );
}
