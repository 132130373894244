import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import WpOrSwpTimeBookingSection from '../../../Components/Fields/General/NodeType/WorkPackage/Section/WpOrSwpTimeBookingSection';
import NodeBoardStatsSection from '../../../Components/Fields/General/Section/NodeBoardStatsSection';
import NodeColorSection from '../../../Components/Fields/General/Section/NodeColorSection';
import NodeDescriptionSection from '../../../Components/Fields/General/Section/NodeDescriptionSection';
import NodeGuestAccessSection from '../../../Components/Fields/General/Section/NodeGuestAccessSection';
import NodeLabelSection from '../../../Components/Fields/General/Section/NodeLabelSection';
import NodeNameSection from '../../../Components/Fields/General/Section/NodeNameSection';
import NodePlanningSection from '../../../Components/Fields/General/Section/NodePlanningSection';
import NodeReferenceNumberSection from '../../../Components/Fields/General/Section/NodeReferenceNumberSection';
import NodeResponsibilitySection from '../../../Components/Fields/General/Section/NodeResponsibilitySection';
import {NodeTasksSection} from '../../../Components/Fields/General/Section/NodeTasksSection';
import NodeWPAndSWPStatsSection from '../../../Components/Fields/General/Section/NodeWPAndSWPStatsSection';
import InspectorViewFrame from '../../InspectorViewFrame';

export interface WorkPackageDetailsProps {
  node: WorkPackage;
}

export default function General({node}: WorkPackageDetailsProps): ReactElement {
  const patch = useWorkPackagePatch(node.id);
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const canReadDescription = useIsGranted('FLOW_NODE_READ_DESCRIPTION', node.id);
  const canReadRefNumber = useIsGranted('FLOW_NODE_READ_REFERENCE_NUMBER', node.id);
  const optionals = useOptionalFields(node.id, {
    color: node.color,
    description: node.description,
    referenceNumber: node.referenceNumber,
  });

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      {canReadRefNumber && (
        <EnableFieldButton
          field={optionals.referenceNumber}
          label={'nodeDetails:field.referenceNumber.enable'}
          tooltip={'nodeDetails:field.referenceNumber.enableTooltip'}
        />
      )}
      {canReadDescription && (
        <EnableFieldButton
          field={optionals.description}
          label={'nodeDetails:field.description.enable'}
          tooltip={'nodeDetails:field.description.enableTooltip'}
        />
      )}
      <EnableFieldButton
        field={optionals.color}
        label={'nodeDetails:field.color.enable'}
        tooltip={'nodeDetails:field.color.enableTooltip'}
      />
    </div>
  );

  return (
    <InspectorViewFrame footer={canManageBasic ? footer : undefined}>
      <NodeNameSection name={node.name} setName={(name) => patch({name})} readonly={!canManageBasic} />
      {optionals.referenceNumber.show && canReadRefNumber && (
        <NodeReferenceNumberSection
          nodeId={node.id}
          referenceNumber={node.referenceNumber || ''}
          setReferenceNumber={(referenceNumber) => patch({referenceNumber})}
          readonly={!canManageBasic}
        />
      )}
      {optionals.description.show && canReadDescription && (
        <NodeDescriptionSection
          description={node.description}
          setDescription={(description) => patch({description})}
          readonly={!canManageBasic}
        />
      )}
      <NodeResponsibilitySection nodeId={node.id} nodeType={node.nodeType} />
      <NodePlanningSection node={node} />
      <NodeWPAndSWPStatsSection nodeId={node.id} nodeType={node.nodeType} />
      <NodeTasksSection node={node} />
      <NodeBoardStatsSection nodeId={node.id} nodeType={node.nodeType} />
      <WpOrSwpTimeBookingSection node={node} />
      {optionals.color.show && (
        <NodeColorSection
          color={node.color}
          nodeType={node.nodeType}
          setColor={(color) => patch({color})}
          readonly={!canManageBasic}
        />
      )}
      <NodeLabelSection
        nodeId={node.id}
        labels={node.labels}
        nodeType={node.nodeType}
        readonly={!canManageBasic}
        setLabels={(labels) => patch({labels})}
      />
      <NodeGuestAccessSection nodeId={node.id} />
    </InspectorViewFrame>
  );
}
