import {Variants} from 'framer-motion';
import {noop} from 'lodash';
import {ReactElement} from 'react';
import Pop, {PopProps} from '../Pop';

const variants: Variants = {
  hidden: {marginTop: 10, opacity: 0, transition: {duration: 0.12}},
  visible: {marginTop: 0, opacity: 1, transition: {duration: 0.12}},
};

//`size` property is broken (changes font-size, not width)
type PopdownProps = Omit<PopProps, 'variants' | 'size'>;

const Popup = function Popup(props: PopdownProps): ReactElement {
  return (
    <Pop {...props} variants={variants} open={props.open} setOpen={props.setOpen || noop}>
      {props.children}
    </Pop>
  );
};

export {Popup};
