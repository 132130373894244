import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {useIsMobileSafari} from '@octaved/ui-components/src/BrowserDetect/IsMobileSafari';
import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';
import {Popup, StrictPopupProps} from 'semantic-ui-react';
import css from 'styled-jsx/css';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .tooltip {
    z-index: 2060;
  }
`;

//#endregion

export interface TooltipProps extends StrictPopupProps {
  children: ReactNode;
  toolTipTranslation: ComplexTranslatable;
  offset?: StrictPopupProps['offset'];
  position?: StrictPopupProps['position'];
}

export const MouseEnterDelayLong = 1250;
export const MouseEnterDelayShort = 250;

function Tooltip({
  children,
  toolTipTranslation,
  offset = [0],
  position = 'top center',
  mouseEnterDelay = MouseEnterDelayLong,
  ...props
}: TooltipProps): ReactElement {
  const isIpad = useIsMobileSafari();

  if (isIpad || (!toolTipTranslation && !props.content)) {
    return <>{children}</>;
  }

  return (
    <>
      <Popup
        className={classNames(className, 'tooltip', '!text-xs')}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: window,
            },
          },
        ]}
        popperDependencies={[toolTipTranslation]}
        pinned
        inverted
        offset={offset}
        size={'mini'}
        position={position}
        on={'hover'}
        content={<ComplexTrans translatable={toolTipTranslation} />}
        trigger={children}
        wide
        disabled={!toolTipTranslation && !props.content}
        mouseEnterDelay={mouseEnterDelay}
        {...props}
      />
      {styles}
    </>
  );
}

export {Tooltip};
