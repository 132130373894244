import {INVALIDATED, reduceStateIds} from '@octaved/store/src/EntityState';
import {SubWorkPackagePatchedEvent} from '../Events';
import {responsibleProps} from '../ResponsibleNode';
import {getNodeSearchKey} from '../Selectors/NodeSearchSelectors';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<SubWorkPackagePatchedEvent>('flow.SubWorkPackagePatchedEvent', {
  assignedAnyProjectRole: 'ignored',
  assignedProjectRoleId: 'ignored',
  assignedProjectRoleType: 'ignored',
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored',
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: (action) => ({
    states: (state) => {
      if (action.patchedKeys.includes('isCompleted')) {
        const key = getNodeSearchKey('hasLogicalPredecessor', 'incomplete');
        return reduceStateIds(state, action, INVALIDATED, [key]);
      }
      return state;
    },
  }),
  hasTimeBasedPredecessor: (action) => ({
    states: (state) => {
      if (action.patchedKeys.includes('isCompleted')) {
        return reduceStateIds(state, action, INVALIDATED, [
          getNodeSearchKey('hasTimeBasedPredecessor', 'incomplete'),
          getNodeSearchKey('hasTimeBasedPredecessor', 'incomplete:blocking'),
        ]);
      }
      return state;
    },
  }),
  isArchived: 'ignored',
  labelId: ({patchedKeys}) => (patchedKeys.includes('labels') ? 'clear' : 'ignored'),
  mrIsActive: 'ignored',
  name: ({patchedKeys}) => (patchedKeys.includes('name') ? 'clear' : 'ignored'),
  nodeNotPlanned: 'ignored',
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: 'ignored',
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: ({patchedKeys}) => (patchedKeys.includes('referenceNumber') ? 'clear' : 'ignored'),
  responsibleByAny: ({patchedKeys}) => (responsibleProps.some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored'),
  responsibleUnitId: ({patchedKeys}) => (responsibleProps.some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored'),
  swpCompletedInDateRange: ({patchedKeys}) => (patchedKeys.includes('isCompleted') ? 'clear' : 'ignored'),
  swpIsCompleted: ({patchedKeys}) => (patchedKeys.includes('isCompleted') ? 'clear' : 'ignored'),
  swpIsLocked: ({patchedKeys}) => (patchedKeys.includes('isLocked') ? 'clear' : 'ignored'),
  taskBlockedByDependency: 'ignored',
  taskCompletedInDateRange: 'ignored',
  taskStatus: 'ignored',
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
