import ConfirmDialog from '@octaved/flow/src/Components/Dialog/ConfirmDialog';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useTimeRecordSearch} from '@octaved/flow/src/Modules/Hooks/TimeRecordSearch';
import {priceCategoryEntitiesSelector} from '@octaved/flow/src/Modules/Selectors/PriceCategorySelectors';
import {Message} from '@octaved/ui';
import {CheckCircle} from 'lucide-react';
import {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

export type ConfirmPerTimeTracking = Pick<WorkPackage, 'priceCategory' | 'usePriceCategoryPerTimeTracking'>;

export default function PerTimeTrackingConfirmDialog({
  close,
  patch,
  toPatch,
  node,
}: {
  close: () => void;
  patch: (data: Partial<WorkPackage>) => void;
  toPatch: ConfirmPerTimeTracking;
  node: WorkPackage;
}): ReactElement | null {
  const {t} = useTranslation();
  const orgPriceCat = useSelector(priceCategoryEntitiesSelector)[node.priceCategory || ''];

  const {ids, isLoading} = useTimeRecordSearch('workPackageId', node.id, true, true);

  useEffect(() => {
    if (!isLoading && !ids.length) {
      patch(toPatch);
      close();
    }
  }, [close, ids.length, isLoading, patch, toPatch]);

  if (isLoading || !ids.length) {
    return null;
  }

  return (
    <ConfirmDialog
      title={
        toPatch.usePriceCategoryPerTimeTracking
          ? 'nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.titleEnable'
          : 'nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.titleDisable'
      }
      buttonLabel={'nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.confirmButton'}
      buttonIcon={<CheckCircle />}
      cancelCallback={close}
      submitCallback={() => {
        patch(toPatch);
        close();
      }}
    >
      <div className={'flex flex-col gap-y-4'}>
        <div>{t('nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.workPackageHasTimeRecords')}</div>

        {toPatch.usePriceCategoryPerTimeTracking && (
          <>
            {!orgPriceCat && (
              <Message colorScheme={'error'}>
                {t('nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.timeRecordPriceCategoriesMissing')}
              </Message>
            )}
            {!!orgPriceCat && (
              <Message colorScheme={'warning'}>
                {t('nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.timeRecordPriceCategoriesSet', {
                  priceCategory: orgPriceCat.name,
                })}
              </Message>
            )}
          </>
        )}

        {!toPatch.usePriceCategoryPerTimeTracking && (
          <Message colorScheme={'warning'}>
            {t('nodeDetails:field.priceCategory.categoryPerTimeBooking.confirm.timeRecordPriceCategoriesRemoved')}
          </Message>
        )}
      </div>
    </ConfirmDialog>
  );
}
