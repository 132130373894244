import dayjs from 'dayjs';

export const timeRegex = new RegExp('^([0-1][0-9]|2[0-3]):[0-5][0-9]$');
const hourMinutesRegex = /^(-)?(\d+):([0-5]\d)$/;
export const durationMax24hRegex = /^(24:00|(2[0-3]|1[0-9]|[0-9]):[0-5][0-9])$/;

/**
 * Validates a given number prior the formatting, used for validation rules
 *
 * @param {String} formattedTime
 * @return {Boolean}
 */
export function validateFormattedTime(formattedTime: string): boolean {
  return timeRegex.test(formattedTime);
}

export interface FormatTimeOptions {
  leading?: boolean; //Leading zero - changes the format from "h:mm" to "hh:mm"
  signed?: boolean; //Adds a plus sign if positive. The minus sign is always there if negative.
}

/**
 * Formats minutes to the "h:mm" format ("12:34")
 * With option {leading:true}, the format is "hh:mm"
 * With option {signed:true}, the format is "+h:mm" (negative minutes always produce "-h:mm")
 * Note that hours can take more than 2 characters, starting with 6000 minutes.
 */
export function formatMinutesToHourMinutes(minutes: number, {leading, signed}: FormatTimeOptions = {}): string {
  const abs = Math.abs(minutes);
  const sign = minutes < 0 ? '-' : signed ? '+' : '';
  const pureMinutes = Math.floor(abs % 60);
  const hours = Math.floor((abs - pureMinutes) / 60);
  const formattedHours = leading ? hours.toString().padStart(2, '0') : hours.toString();
  return `${sign}${formattedHours}:${pureMinutes.toString().padStart(2, '0')}`;
}

export function formatHoursToHourMinutes(hours: number, options: FormatTimeOptions = {}): string {
  return formatMinutesToHourMinutes(hours * 60, options);
}

/**
 * Parses a "11:45" string into 705 minutes
 */
export function parseHourMinutesToMinutes(value: string): number {
  const matches = value.match(hourMinutesRegex);
  if (matches) {
    const [, sign, hours, minutes] = matches;
    return (parseInt(hours) * 60 + parseInt(minutes)) * (sign === '-' ? -1 : 1);
  }
  return 0;
}

/**
 * Formats like:
 *  - "7" -> "7:00"
 *  - "7.5" -> "7:30"
 *  - "7,5" -> "7:30"
 *  - "7:" -> "7:00"
 *  - "7:4" -> "7:40"
 *  - "740" -> "7:40"
 *  - "1235" -> "12:35"
 */
export function autoFormatHourMinutes(raw: string, options: FormatTimeOptions = {}): string {
  //3-4 digits only:
  const matchDigits = raw.match(/^(-)?(\d{1,2})([0-5][0-9])$/);
  if (matchDigits) {
    // eslint-disable-next-line prefer-const
    let [, sign, hours, minutes] = matchDigits;
    if (options.leading) {
      hours = hours.padStart(2, '0');
    }
    sign = sign || (options.signed && '+') || '';
    return `${sign}${hours}:${minutes}`;
  }

  //Int or decimal:
  if (/^(-)?\d{1,2}([,.]\d+)?$/.test(raw)) {
    //Assume hours, decimal or not, there shouldn't be a thousands separator:
    return formatHoursToHourMinutes(parseFloat(raw.replace(',', '.')), options);
  }

  //With colon:
  const match = raw.match(/^(-)?(\d{1,2}):([0-5][0-9]?)?$/);
  if (match) {
    // eslint-disable-next-line prefer-const
    let [, sign, hours, minutes] = match;
    if (options.leading) {
      hours = hours.padStart(2, '0');
    }
    sign = sign || (options.signed && '+') || '';
    return `${sign}${hours}:${(minutes || '').padEnd(2, '0')}`;
  }

  return raw;
}

export function createAutoFormatHourMinutes<R>(
  cb: (formatted: string) => R,
  options: FormatTimeOptions = {},
): (raw: string) => boolean {
  return (raw) => {
    const formatted = autoFormatHourMinutes(raw, options);
    if (formatted !== raw) {
      cb(formatted);
      return true;
    }
    return false;
  };
}

export function formatTimeDurationFromTo(from: dayjs.Dayjs | number, to: dayjs.Dayjs | number): string {
  const fromUnix = dayjs.isDayjs(from) ? from.unix() : from;
  const toUnix = dayjs.isDayjs(to) ? to.unix() : to;
  const diff = (toUnix - fromUnix) / 60;
  return formatMinutesToHourMinutes(diff);
}
