export const INTEGRATION_PATCH_CONNECTED_SERVICE_FAILURE = 'INTEGRATION_PATCH_CONNECTED_SERVICE_FAILURE';
export const INTEGRATION_PATCH_CONNECTED_SERVICE_REQUEST = 'INTEGRATION_PATCH_CONNECTED_SERVICE_REQUEST';
export const INTEGRATION_PATCH_CONNECTED_SERVICE_SUCCESS = 'INTEGRATION_PATCH_CONNECTED_SERVICE_SUCCESS';

export const INTEGRATION_DELETE_CONNECTED_SERVICE_FAILURE = 'INTEGRATION_DELETE_CONNECTED_SERVICE_FAILURE';
export const INTEGRATION_DELETE_CONNECTED_SERVICE_REQUEST = 'INTEGRATION_DELETE_CONNECTED_SERVICE_REQUEST';
export const INTEGRATION_DELETE_CONNECTED_SERVICE_SUCCESS = 'INTEGRATION_DELETE_CONNECTED_SERVICE_SUCCESS';

export const INTEGRATION_LOAD_CALENDAR_EVENTS_FAILURE = 'INTEGRATION_LOAD_CALENDAR_EVENTS_FAILURE';
export const INTEGRATION_LOAD_CALENDAR_EVENTS_REQUEST = 'INTEGRATION_LOAD_CALENDAR_EVENTS_REQUEST';
export const INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS = 'INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS';

export const INTEGRATION_REFRESH_CALENDAR_EVENTS = 'INTEGRATION_REFRESH_CALENDAR_EVENTS';
