import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {timeTrackingSuperUserModeIsActiveSelector} from '../../Authorization/Virtual/TimeTrackingSuperUserMode';
import {
  CreateTimeRecordData,
  PatchTimeRecordData,
  TimeRecordCreate,
  TimeRecordPatch,
} from '../../EntityInterfaces/TimeRecords';
import {getClosestTimeLimitReachedAncestor} from '../../TimeTracking/OverBooking';
import {
  canEditTimeRecord,
  canStartBookingOnNode,
  validateCreate,
  validatePatch,
  validateRemove,
} from '../../TimeTracking/TimeTrackingWriteValidator';
import {timeTrackingErrorFields, TimeTrackingErrors} from '../../TimeTracking/WarningErrorFields';
import {validateUserAccess} from '../../TimeTracking/WriteValidatorFunctions';
import {getNodeAncestrySelector, getNodeAncestryWithWp} from './NodeTreeSelectors';
import {settingsSelector} from './SettingSelectors';
import {getTimeRecordSelector} from './TimeRecordsSelectors';
import {Dispatch} from 'redux';
import {createPatchData} from '../TimeRecords';
import {addErrors, removeErrorForField} from '../Ui';
import {calculateBillingTimes} from '../../TimeTracking/Rounding';

export const getIsNodeTimeLimitReachedSelector = createSelector(
  settingsSelector,
  getNodeAncestrySelector,
  (settings, getNodeAncestry) =>
    (nodeId: Uuid): boolean => {
      const ancestry = getNodeAncestryWithWp(getNodeAncestry(nodeId, true));
      return ancestry ? !!getClosestTimeLimitReachedAncestor(settings, ancestry) : false;
    },
);

export const validateCreateTimeRecordSelector = createSelector(
  isGrantedSelector,
  timeTrackingSuperUserModeIsActiveSelector,
  settingsSelector,
  currentOrgUserIdSelector,
  getNodeAncestrySelector,
  (isGranted, timeTrackingSuperUserModeIsActive, settings, currentUserId, getNodeAncestry) =>
    (patch: TimeRecordCreate, allowStartBeforeEnd: boolean = false): TimeTrackingErrors =>
      validateCreate(
        isGranted,
        timeTrackingSuperUserModeIsActive,
        settings,
        currentUserId,
        getNodeAncestry,
        patch,
        allowStartBeforeEnd,
      ),
);

export const validatePatchTimeRecordSelector = createSelector(
  isGrantedSelector,
  timeTrackingSuperUserModeIsActiveSelector,
  settingsSelector,
  currentOrgUserIdSelector,
  getNodeAncestrySelector,
  getTimeRecordSelector,
  (isGranted, timeTrackingSuperUserModeIsActive, settings, currentUserId, getNodeAncestry, getTimeRecord) =>
    (id: Uuid, patch: TimeRecordPatch, allowStartBeforeEnd: boolean = false): TimeTrackingErrors => {
      const record = getTimeRecord(id);
      if (!record) {
        return [];
      }
      return validatePatch(
        isGranted,
        timeTrackingSuperUserModeIsActive,
        settings,
        currentUserId,
        getNodeAncestry,
        record,
        patch,
        allowStartBeforeEnd,
      );
    },
);

/**
 * Just a subset of what validateRemoveTimeRecordSelector will check. This selector ONLY checks for permission.
 */
export const isAllowedToRemoveTimeRecordSelector = createSelector(
  isGrantedSelector,
  currentOrgUserIdSelector,
  getTimeRecordSelector,
  (isGranted, currentUserId, getTimeRecord) =>
    (timeRecordId: Uuid): boolean => {
      const errors: TimeTrackingErrors = [];
      const record = getTimeRecord(timeRecordId);
      if (record && record.user) {
        validateUserAccess(isGranted, errors, currentUserId, record.workPackage, record.user);
        return errors.length === 0;
      }
      return false;
    },
);

export const validateRemoveTimeRecordSelector = createSelector(
  isGrantedSelector,
  timeTrackingSuperUserModeIsActiveSelector,
  settingsSelector,
  currentOrgUserIdSelector,
  getNodeAncestrySelector,
  getTimeRecordSelector,
  (isGranted, timeTrackingSuperUserModeIsActive, settings, currentUserId, getNodeAncestry, getTimeRecord) =>
    (timeRecordId: Uuid): TimeTrackingErrors => {
      return validateRemove(
        isGranted,
        timeTrackingSuperUserModeIsActive,
        settings,
        currentUserId,
        getNodeAncestry,
        getTimeRecord,
        timeRecordId,
      );
    },
);

export const canStartTimeRecordOnNodeSelector = createSelector(
  getNodeAncestrySelector,
  isGrantedSelector,
  timeTrackingSuperUserModeIsActiveSelector,
  settingsSelector,
  (getNodeAncestry, isGranted, timeTrackingSuperUserModeIsActive, settings) =>
    memoize((nodeId: Uuid): boolean =>
      canStartBookingOnNode(isGranted, timeTrackingSuperUserModeIsActive, settings, getNodeAncestry, nodeId),
    ),
);

export const canEditTimeRecordSelector = createSelector(
  isGrantedSelector,
  timeTrackingSuperUserModeIsActiveSelector,
  settingsSelector,
  currentOrgUserIdSelector,
  getNodeAncestrySelector,
  getTimeRecordSelector,
  (isGranted, timeTrackingSuperUserModeIsActive, settings, currentUserId, getNodeAncestry, getTimeRecord) =>
    memoize((timeRecordId: Uuid): boolean => {
      return canEditTimeRecord(
        isGranted,
        timeTrackingSuperUserModeIsActive,
        settings,
        currentUserId,
        getNodeAncestry,
        getTimeRecord,
        timeRecordId,
      );
    }),
);

export const validateCreateOrPatchTimeRecordSelector = createSelector(
  validateCreateTimeRecordSelector,
  validatePatchTimeRecordSelector,
  getNodeAncestrySelector,
  settingsSelector,
  (validateCreate, validatePatch, getNodeAncestry, settings) =>
    (dispatch: Dispatch, id: Uuid, data: CreateTimeRecordData | PatchTimeRecordData, isNew: boolean): boolean => {
      let errors: TimeTrackingErrors = [];
      const record = {
        ...createPatchData(data, getNodeAncestry),
        id,
      };
      calculateBillingTimes(settings, record);
      if (isNew) {
        errors = validateCreate(record, true);
      } else {
        errors = validatePatch(id, record, true);
      }
      if (errors.length) {
        dispatch(addErrors(errors));
        return false;
      } else {
        dispatch(removeErrorForField(timeTrackingErrorFields));
        return true;
      }
    },
);
