import {ReactElement, useContext} from 'react';
import {ProjectTreeProject} from '../../Modules/Projects/ProjectTreeInterfaces';
import {projectsTreeComponentContext} from '../Tree/ProjectTreeComponentContext';

export default function Flat({flat}: {flat: ProjectTreeProject[]}): ReactElement {
  const {project: Project} = useContext(projectsTreeComponentContext);

  return (
    <div>
      {flat.map((node) => (
        <Project key={node.id} node={node} />
      ))}
    </div>
  );
}
