import {lazy} from 'react';
import {RouteObject} from 'react-router';
import LastVisitRedirect from '../LastVisitRedirect';
import {getStandardInspectorRoutes} from './Inspectors/StandardInspector';

const ListView = lazy(() => import('../../Pages/MyTasks/ListView'));
const TimelineView = lazy(() => import('../../Pages/MyTasks/TimelineView'));

export type MyTasksView = 'list' | 'timeline';

export const myTasksListRoute = 'list';
export const myTasksTimelineRoute = 'timeline';

export const myTasksRoutes: RouteObject[] = [
  {
    children: getStandardInspectorRoutes({}),
    element: <ListView />,
    path: `${myTasksListRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({}),
    element: <TimelineView />,
    path: `${myTasksTimelineRoute}/*`,
  },
  {path: '*', element: <LastVisitRedirect default={myTasksListRoute} />},
];

export function getMyTasksListRoute(): string {
  return myTasksListRoute;
}
