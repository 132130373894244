import {FlowState} from '../../State';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['projectPlanning']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.projectPlanning);
}

export const projectPlanningZoomSelector = ui((state) => state.ganttZoom);
export const projectPlanningColumnWidthSelector = ui((state) => state.ganttColumnWidth);
export const projectPlanningHoveringNodeIdSelector = ui((state) => state.hoveringNodeId);
export const projectPlanningShowNodeInfoSelector = ui((state) => state.showHoveringNodeInfo);
