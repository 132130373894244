import * as SelectPrimitive from '@radix-ui/react-select';
import {Check, ChevronDown} from 'lucide-react';
import * as React from 'react';
import {cn} from './Hooks';

interface TWSelectProps<V extends string> extends Omit<SelectPrimitive.SelectProps, 'value'> {
  defaultValue?: V;
  onValueChange?(value: V): void;
  value?: V;
}

const TWSelect = SelectPrimitive.Root;

const TWSelectGroup = SelectPrimitive.Group;

const TWSelectValue = SelectPrimitive.Value;

const TWSelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {hasWarning?: boolean; noChevron?: boolean}
>(({className, children, hasWarning, noChevron, ...props}, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'border-input h-8 overflow-hidden rounded border bg-transparent px-3 py-2 ring-offset-2 placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[placeholder]:italic data-[placeholder]:text-slate-400',
      hasWarning && 'border-orange-400 bg-orange-50',
      className,
    )}
    {...props}
  >
    <div className={'flex h-full w-full items-center justify-between gap-x-2'}>
      <div className={'flex-grow overflow-hidden truncate text-left'}>{children}</div>
      {!noChevron && (
        <SelectPrimitive.Icon asChild>
          <ChevronDown className={'h-4 w-4 flex-shrink-0 opacity-50'} />
        </SelectPrimitive.Icon>
      )}
    </div>
  </SelectPrimitive.Trigger>
));
TWSelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const TWSelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({className, children, position = 'popper', ...props}, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-50 min-w-[8rem] overflow-hidden rounded border bg-white text-slate-800 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
TWSelectContent.displayName = SelectPrimitive.Content.displayName;

const TWSelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({className, ...props}, ref) => (
  <SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} {...props} />
));
TWSelectLabel.displayName = SelectPrimitive.Label.displayName;

interface TWSelectItemProps<V extends string> extends Omit<SelectPrimitive.SelectItemProps, 'value'> {
  value: V;
}

const TWSelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({className, children, ...props}, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 outline-none focus:bg-slate-50 focus:text-slate-800 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  >
    <span className={'absolute left-2 flex h-3.5 w-3.5 items-center justify-center'}>
      <SelectPrimitive.ItemIndicator>
        <Check className={'h-4 w-4'} />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText className={'w-full'}>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
TWSelectItem.displayName = SelectPrimitive.Item.displayName;

const TWSelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({className, ...props}, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-slate-200', className)} {...props} />
));
TWSelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  TWSelect,
  TWSelectContent,
  TWSelectGroup,
  TWSelectItem,
  TWSelectLabel,
  TWSelectSeparator,
  TWSelectTrigger,
  TWSelectValue,
};

export function getTypedSelect<V extends string>(): {
  Content: typeof TWSelectContent;
  Group: typeof TWSelectGroup;
  Item: React.ForwardRefExoticComponent<TWSelectItemProps<V> & React.RefAttributes<HTMLDivElement>>;
  Label: typeof TWSelectLabel;
  Select: React.FC<TWSelectProps<V>>;
  Separator: typeof TWSelectSeparator;
  Trigger: typeof TWSelectTrigger;
  Value: typeof TWSelectValue;
} {
  return {
    Content: TWSelectContent,
    Group: TWSelectGroup,
    Item: TWSelectItem as React.ForwardRefExoticComponent<TWSelectItemProps<V> & React.RefAttributes<HTMLDivElement>>,
    Label: TWSelectLabel,
    Select: TWSelect as React.FC<TWSelectProps<V>>,
    Separator: TWSelectSeparator,
    Trigger: TWSelectTrigger,
    Value: TWSelectValue,
  };
}
