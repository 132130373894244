import {EnumFlowPidFreeOfChargeReason} from '@octaved/env/src/dbalEnumTypes';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown} from 'semantic-ui-react';
import {DropdownItemProps} from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import {WorkPackagePatchData} from '../../EntityInterfaces/Pid';

interface Props {
  patch: (pid: Partial<WorkPackagePatchData>) => void;
  pid: WorkPackagePatchData;
  readonly?: boolean;
}

export default function PidFreeOfChargeReason({pid, patch, readonly}: Props): ReactElement {
  const {t} = useTranslation();
  const options: DropdownItemProps[] = [
    {
      text: <span>{t('general:pid.freeOfChargeReason.aquisition')}</span>,
      value: EnumFlowPidFreeOfChargeReason.VALUE_AQUISITION,
    },
    {
      text: <span>{t('general:pid.freeOfChargeReason.warranty')}</span>,
      value: EnumFlowPidFreeOfChargeReason.VALUE_WARRANTY,
    },
    {
      text: <span>{t('general:pid.freeOfChargeReason.service')}</span>,
      value: EnumFlowPidFreeOfChargeReason.VALUE_SERVICE,
    },
  ];

  return (
    <Dropdown
      disabled={readonly}
      upward
      selection
      fluid
      value={pid.freeOfChargeReason ?? undefined}
      options={options}
      onChange={(_e, {value}) => patch({freeOfChargeReason: value as EnumFlowPidFreeOfChargeReason})}
    />
  );
}
