import {IconButton, Tooltip} from '@octaved/ui';
import {Filter} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AdvancedFilterWrapper from '../../../Components/AdvancedFilter/AdvancedFilterWrapper';
import ProjectFiltersContext from '../../../Modules/Projects/Filters/ProjectFiltersContext';
import {projectsAdvancedFilterIsActiveSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {FlowState} from '../../../Modules/State';
import {setInspector} from '../../../Modules/Ui';
import {useProjectContext} from '../ProjectContext';
import AdvancedFilter from './AdvancedFilter';

export default function AdvancedFilterButton(): ReactElement {
  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
  const {page} = useProjectContext();
  const isActive = useSelector((s: FlowState) => projectsAdvancedFilterIsActiveSelector(s)(page));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Tooltip toolTipTranslation={'tooltips:components.pageHeader.buttonFilter'} position={'bottom center'}>
        <IconButton
          variant={'ghost'}
          size={'sm'}
          icon={<Filter />}
          isUnderlined={isActive}
          isActive={isActive}
          onClick={() => {
            if (advancedFilterOpen) {
              setAdvancedFilterOpen(false);
            } else {
              setAdvancedFilterOpen(true);
              //close any inspector
              navigate('');
              dispatch(setInspector({}));
            }
          }}
        />
      </Tooltip>

      <AdvancedFilterWrapper active={advancedFilterOpen}>
        <ProjectFiltersContext>
          <AdvancedFilter active={advancedFilterOpen} setAdvancedFilterOpen={setAdvancedFilterOpen} />
        </ProjectFiltersContext>
      </AdvancedFilterWrapper>
    </>
  );
}
