import {SupportedLanguage} from '@octaved/i18n/src/Language/Languages';
import {TFunction} from 'i18next';

export function getLocaleTranslations(t: TFunction, language: SupportedLanguage): ILocale {
  return {
    formats: {},
    months: [
      t('generalCore:culture.months.january'),
      t('generalCore:culture.months.february'),
      t('generalCore:culture.months.march'),
      t('generalCore:culture.months.april'),
      t('generalCore:culture.months.may'),
      t('generalCore:culture.months.june'),
      t('generalCore:culture.months.july'),
      t('generalCore:culture.months.august'),
      t('generalCore:culture.months.september'),
      t('generalCore:culture.months.october'),
      t('generalCore:culture.months.november'),
      t('generalCore:culture.months.december'),
    ],
    monthsShort: [
      t('generalCore:culture.shortMonths.january'),
      t('generalCore:culture.shortMonths.february'),
      t('generalCore:culture.shortMonths.march'),
      t('generalCore:culture.shortMonths.april'),
      t('generalCore:culture.shortMonths.may'),
      t('generalCore:culture.shortMonths.june'),
      t('generalCore:culture.shortMonths.july'),
      t('generalCore:culture.shortMonths.august'),
      t('generalCore:culture.shortMonths.september'),
      t('generalCore:culture.shortMonths.october'),
      t('generalCore:culture.shortMonths.november'),
      t('generalCore:culture.shortMonths.december'),
    ],
    name: `octaved-${language}`,
    relativeTime: {
      d: t('generalCore:culture.relativeTime.day'),
      dd: t('generalCore:culture.relativeTime.days'),
      future: t('generalCore:culture.relativeTime.future'),
      h: t('generalCore:culture.relativeTime.hour'),
      hh: t('generalCore:culture.relativeTime.hours'),
      m: t('generalCore:culture.relativeTime.minute'),
      M: t('generalCore:culture.relativeTime.month'),
      mm: t('generalCore:culture.relativeTime.minutes'),
      MM: t('generalCore:culture.relativeTime.months'),
      past: t('generalCore:culture.relativeTime.past'),
      s: t('generalCore:culture.relativeTime.seconds'),
      y: t('generalCore:culture.relativeTime.year'),
      yy: t('generalCore:culture.relativeTime.years'),
    },
    weekdays: [
      t('generalCore:culture.weekDays.sunday'),
      t('generalCore:culture.weekDays.monday'),
      t('generalCore:culture.weekDays.tuesday'),
      t('generalCore:culture.weekDays.wednesday'),
      t('generalCore:culture.weekDays.thursday'),
      t('generalCore:culture.weekDays.friday'),
      t('generalCore:culture.weekDays.saturday'),
    ],
    weekdaysMin: [
      t('generalCore:culture.shortWeekDays.sunday'),
      t('generalCore:culture.shortWeekDays.monday'),
      t('generalCore:culture.shortWeekDays.tuesday'),
      t('generalCore:culture.shortWeekDays.wednesday'),
      t('generalCore:culture.shortWeekDays.thursday'),
      t('generalCore:culture.shortWeekDays.friday'),
      t('generalCore:culture.shortWeekDays.saturday'),
    ],
    weekdaysShort: [
      t('generalCore:culture.shortWeekDays.sunday'),
      t('generalCore:culture.shortWeekDays.monday'),
      t('generalCore:culture.shortWeekDays.tuesday'),
      t('generalCore:culture.shortWeekDays.wednesday'),
      t('generalCore:culture.shortWeekDays.thursday'),
      t('generalCore:culture.shortWeekDays.friday'),
      t('generalCore:culture.shortWeekDays.saturday'),
    ],
    weekStart: 1,
  };
}
