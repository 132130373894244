import ComplexTrans from '@octaved/i18n/src/ComplexTrans';
import classNames from 'classnames';
import {FormikProps} from 'formik/dist/types';
import {get} from 'lodash';
import {ReactElement, useMemo} from 'react';
import {Message} from 'semantic-ui-react';

interface Props<FormikValues> {
  className?: string;
  formik: FormikProps<FormikValues>;
  noMargin?: boolean;
  warning?: boolean;
}

export default function ErrorList<FormikValues>({
  className,
  formik,
  noMargin,
  warning,
}: Props<FormikValues>): ReactElement | null {
  const errors = useMemo(() => {
    return Object.entries<string>(formik.errors).filter(([fieldName]) => get(formik.touched, fieldName));
  }, [formik]);

  if (errors.length > 0) {
    return (
      <div className={classNames(className, {margin: !noMargin})}>
        <Message error={!warning} warning={warning} visible>
          {/* Errors */}
          {errors.length > 1 && (
            <Message.List>
              {errors.map(([field, message]) => (
                <Message.Item key={field + message}>
                  <ComplexTrans translatable={message} />
                </Message.Item>
              ))}
            </Message.List>
          )}
          {errors.length === 1 && (
            <div>
              <ComplexTrans translatable={errors[0][1]} />
            </div>
          )}
        </Message>

        {/*language=SCSS*/}
        <style jsx>{`
          .margin {
            margin-bottom: 20px;
          }
        `}</style>
      </div>
    );
  }

  return null;
}
