import {useCurrentOrgUserRights} from '@octaved/users/src/Modules/CurrentOrgUserRights';
import {PropsWithChildren, ReactElement, useEffect} from 'react';
import {useNodeTree} from './Modules/NodeTree';
import {useLoadRoles} from './Modules/Roles';
import {initTodayCheck} from './Today';

export default function MasterData({children}: PropsWithChildren<unknown>): ReactElement | null {
  useEffect(() => {
    initTodayCheck();
  }, []);

  useCurrentOrgUserRights();
  useNodeTree();
  useLoadRoles();

  return <>{children}</>;
}
