import {getSortWeights} from '@octaved/utilities/src/Sorting';

export const planningStatusOverall = [
  'none',
  'notYetActive',
  'active',
  'overdue',
  'blocked',
  'betweenBars',
  'done',
] as const;
export type PlanningStatusOverall = (typeof planningStatusOverall)[number];
export const planningStatusOverallSortWeights = getSortWeights(planningStatusOverall);

export const planningStatusTime = ['none', 'inPast', 'active', 'inFuture', 'betweenBars'] as const;
export type PlanningStatusTime = (typeof planningStatusTime)[number];

export const planningStatusPredecessors = ['none', 'allDone', 'notDone'] as const;
export type PlanningStatusPredecessors = (typeof planningStatusPredecessors)[number];

export interface PlanningStatus {
  isDone: boolean;
  logicalPredecessors: PlanningStatusPredecessors;
  overall: PlanningStatusOverall;
  planningPredecessors: PlanningStatusPredecessors;
  time: PlanningStatusTime;
}

export const emptyPlanningStatus: PlanningStatus = {
  isDone: false,
  logicalPredecessors: 'none',
  overall: 'none',
  planningPredecessors: 'none',
  time: 'none',
};
