import {ReactElement, useContext} from 'react';
import {ProjectTreeProject} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import FlowLabels from '../../../Label/FlowLabel/FlowLabels';
import ProjectManagerUnitsTextList from '../../../Node/ProjectManagerUnitsTextList';
import {projectsTreeComponentContext} from '../../ProjectTreeComponentContext';
import CustomerName from './CustomerName';

export interface ProjectDetailsProps {
  node: ProjectTreeProject;
}

export default function ProjectDetails({node}: ProjectDetailsProps): ReactElement {
  const {projectPageDetails: PageDetails} = useContext(projectsTreeComponentContext);
  const project = node.entity;

  return (
    <>
      <div className={'grow'}>
        <CustomerName node={node} />
        <div className={'responsibleUnits'}>
          <ProjectManagerUnitsTextList nodeId={node.id} />
        </div>
      </div>

      {!PageDetails && project && project.labels.length > 0 && <FlowLabels labels={project.labels} small noMargin />}

      {PageDetails && <PageDetails node={node} />}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .grow {
          flex-grow: 1;
          display: flex;
          align-items: center;
        }

        .responsibleUnits {
          font-size: 12px;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
