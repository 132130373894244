import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {useLoadNode} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {nodeEntitySelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getProjectForNodeSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {canManageNodeRoleAssignmentsSelector} from '@octaved/flow/src/Modules/Selectors/RoleAssignments/NodeRoleAssignmentSelectors';
import {getRolesDropdownOptionsWithNone} from '@octaved/flow/src/Modules/Selectors/RoleSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {Uuid} from '@octaved/typescript/src/lib';
import {Message} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Loader} from 'semantic-ui-react';
import AssignmentsTable from './AssignmentsTable';
import {useSimpleRoleAssignments} from './Hook';
import NoRolesMessage from './NoRolesMessage';

interface BaseProps {
  headerIfAssignments?: ReactNode;
  headerIfNoAssignments?: ReactNode;
  nodeId: Uuid;
  onSave?: () => void;
  readonly?: boolean;
}

interface PermissionProps extends BaseProps {
  isGuestRole: boolean;
  type: EnumFlowNodeRoleType.VALUE_PERMISSION;
}

interface ProjectProps extends BaseProps {
  isGuestRole?: never;
  type: EnumFlowNodeRoleType.VALUE_PROJECT;
}

type Props = PermissionProps | ProjectProps;

export default function SimpleRoleAssignment({
  headerIfAssignments,
  headerIfNoAssignments,
  isGuestRole = false,
  nodeId,
  readonly,
  type,
}: Props): ReactElement | null {
  const {t} = useTranslation();
  const {
    isLoading,
    isSaving,
    addUnit,
    assignments,
    changeRole,
    restoreInheritance,
    showSelfLockoutError,
    takenRolesPerUnit,
  } = useSimpleRoleAssignments(type, !!isGuestRole, nodeId);
  const hasLoadedOnce = useLoadedValue(isLoading, !isLoading);
  useLoadNode(nodeId);
  const node = useSelector((s: FlowState) => nodeEntitySelector(s)[nodeId]);
  const canManage =
    useSelector((s: FlowState) => canManageNodeRoleAssignmentsSelector(s)(type, !!isGuestRole, nodeId)) &&
    !readonly &&
    !!node &&
    !node.isArchived;
  const customerId = useSelector((s: FlowState) => getProjectForNodeSelector(s)(nodeId)?.flowCustomer || null);

  const noneRoleText = t(
    type === EnumFlowNodeRoleType.VALUE_PROJECT
      ? 'roles:projectRoles.none'
      : isGuestRole
        ? 'roles:permissionRoles.none.guest'
        : 'roles:permissionRoles.none.internal',
  );

  const availableRoles = useSelector((s: FlowState) =>
    getRolesDropdownOptionsWithNone(s)(type, isGuestRole, noneRoleText),
  ); //has always the "no" role entry

  if (isGuestRole && type !== EnumFlowNodeRoleType.VALUE_PERMISSION) {
    throw new Error('Guest roles are for permission roles only!');
  }

  if (isGuestRole && !customerId) {
    return null; //guest roles may only be assigned to
  }

  return (
    <>
      {hasLoadedOnce && takenRolesPerUnit.size > 0 && headerIfAssignments}
      {hasLoadedOnce && takenRolesPerUnit.size === 0 && headerIfNoAssignments}
      <div className={'relative'}>
        <Loader active={isLoading || isSaving} />
        <div className={'flex flex-col gap-y-6'}>
          {showSelfLockoutError && <Message colorScheme={'warning'}>{t('core:roles.selfLockOutErrorMessage')}</Message>}
          {availableRoles.length <= 1 && (
            <Message>
              <NoRolesMessage type={type} isGuestRole={isGuestRole} />
            </Message>
          )}
          {availableRoles.length > 1 && (
            <AssignmentsTable
              addUnit={addUnit}
              assignments={assignments}
              availableRoles={availableRoles}
              changeRole={changeRole}
              customerId={customerId}
              isGuestRole={isGuestRole}
              readonly={!canManage}
              restoreInheritance={restoreInheritance}
              takenRolesPerUnit={takenRolesPerUnit}
              type={type}
            />
          )}
        </div>
      </div>
    </>
  );
}
