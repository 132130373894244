import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {PropsWithChildren, ReactElement, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Pid} from '../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../EntityInterfaces/SubWorkPackage';
import {loadNodes} from '../../Modules/Nodes';
import {billingSelector} from '../../Modules/Selectors/Billing/BillingSelectors';
import {getFlowCustomerSelector} from '../../Modules/Selectors/CustomerSelectors';
import {isPidOrSubWOrkPackage, isTask} from '../../Node/NodeIdentifiers';
import {getNodeSelector} from '../../Modules/Selectors/NodeSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  InspectorContextProps,
  InspectorContextSelectedTypes,
  InspectorParameterNames,
  inspectorContext,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IPidInspectorContext extends BaseInspectorContext {
  selectedEntity: Pid | SubWorkPackage;
  selectedType:
    | EnumFlowNodeType.VALUE_PROJECT
    | EnumFlowNodeType.VALUE_GROUP
    | EnumFlowNodeType.VALUE_WORK_PACKAGE
    | EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE;
}

function isPidInspectorContext(context: InspectorContext | null): context is IPidInspectorContext {
  const allowedTypes: Array<string | undefined> = [
    EnumFlowNodeType.VALUE_PROJECT,
    EnumFlowNodeType.VALUE_GROUP,
    EnumFlowNodeType.VALUE_WORK_PACKAGE,
    EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
  ];
  return allowedTypes.includes(context?.selectedType);
}

export function usePidInspectorContext(): IPidInspectorContext {
  const context = useInspectorContext();
  if (!isPidInspectorContext(context)) {
    throw new Error('is not pid inspector');
  }
  return context;
}

function useCreateInspectorContext(
  parameterName: InspectorParameterNames,
  type?: InspectorContextSelectedTypes,
): InspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const id = baseParams?.selectedId;

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(loadNodes([id]));
    }
  }, [dispatch, id]);

  const node = useSelector(getNodeSelector)(id);
  const flowCustomer = useSelector(getFlowCustomerSelector)(id);
  const billing = useSelector(billingSelector)[id!];

  return useMemo<InspectorContext | null>(() => {
    if (!baseParams) {
      return null;
    }

    if (type === 'timesheet' && baseParams.selectedId) {
      return {
        ...baseParams,
        selectedEntity: null,
        selectedType: type,
      };
    }
    if (isPidOrSubWOrkPackage(node)) {
      return {
        ...baseParams,
        selectedEntity: node,
        selectedType: node.nodeType,
      };
    }
    if (flowCustomer) {
      return {
        ...baseParams,
        selectedEntity: flowCustomer,
        selectedType: 'flowCustomer',
      };
    }
    if (isTask(node)) {
      return {
        ...baseParams,
        selectedEntity: node,
        selectedType: node.nodeType,
      };
    }
    if (billing) {
      return {
        ...baseParams,
        selectedEntity: billing,
        selectedType: 'billing',
      };
    }
    return null;
  }, [type, node, flowCustomer, billing, baseParams]);
}

export default function StandardInspectorContext({
  children,
  parameterName = 'inspectId',
  type,
}: PropsWithChildren<InspectorContextProps>): ReactElement | null {
  const inspectorContextProps = useCreateInspectorContext(parameterName, type);
  if (!inspectorContextProps) {
    return null;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
