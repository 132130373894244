import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import classNames from 'classnames';
import {ReactElement} from 'react';
import {Loader} from 'semantic-ui-react';

interface AppLoaderProps {
  relative?: boolean;
  isLoading?: boolean;
}

export default function AppLoader({relative = true, isLoading = true}: AppLoaderProps): ReactElement | null {
  const hasLoadedOnce = useLoadedValue(isLoading, !isLoading);

  if (hasLoadedOnce) {
    return null;
  }
  return (
    <div className={classNames('loader', {relative})}>
      <Loader active />
      {/*language=SCSS*/}
      <style jsx>{`
        .loader {
          height: 100%;
        }

        .relative {
          position: relative;
        }
      `}</style>
    </div>
  );
}
