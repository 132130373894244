import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import UserInspectorContext from '../../../../Drawer/InspectorContext/UserInpsectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const userRoute = 'user';

const EditUser = lazy(() => import('../../../../Pages/SystemSettings/User/Inspector/EditUser'));

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['group']: [
    {
      path: userRoute,
      token: '',
    },
  ],
};
//endregion

export const userInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <EditUser />
        </InspectorContextLoadingWrapper>
      ),
      path: userRoute,
    },
    {
      element: <Navigate to={userRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: UserInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
