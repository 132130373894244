import {sentryDsnDesktopSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {identityIdSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {isResponse} from '@octaved/network/src/Response';
import {organizationSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {getState, onSelectorChange} from '@octaved/store/src/Store';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {NIL} from '@octaved/utilities';
import {writeJsLogSio} from '../config/routes';
import {isDebug} from './Environment';
import {versionSelector} from './EnvironmentState';
import {LogLevel, registerLogHandler} from './Logger';
import {writeError, writeErrorEvent, writeMessage} from './LogWriter';
import {User} from '@sentry/react';

const reportableLevels = new Set<LogLevel>(['error', 'warning']);

let inErrorHandling = false;
window.addEventListener('error', (error) => {
  if (!inErrorHandling) {
    inErrorHandling = true;
    writeErrorEvent(error).finally(() => (inErrorHandling = false));
    if (error.message.includes('ChunkLoadError')) {
      window.location.reload();
    }
  }
  return false;
});

registerLogHandler((level, ...args) => {
  const firstArg = args.shift();
  if (firstArg && reportableLevels.has(level)) {
    if (firstArg instanceof ErrorEvent) {
      writeErrorEvent(firstArg, args);
    } else if (firstArg instanceof Error) {
      writeError(firstArg, args);
    } else if (typeof firstArg === 'string') {
      writeMessage(level, firstArg, args);
    } else {
      const msg = 'CRICIAL: Could not determine logged error type';
      writeMessage('error', msg, [firstArg, ...args]);
      // eslint-disable-next-line no-console
      console.error(msg, [firstArg, ...args]);
    }
  }
});

function tryStringify<V>(args: V): V | string {
  try {
    JSON.stringify(args);
    return args;
  } catch {
    return 'error-not-serializable';
  }
}

onSelectorChange(sentryDsnDesktopSelector, true, (newValue, lastValue) => {
  if (!lastValue && newValue) {
    import('@sentry/react').then(({init, captureException, captureMessage, setUser, setTag, withScope}) => {
      const state = getState<FlowState>();
      const {branch, release, revision} = versionSelector(state);
      init({
        async beforeSend(event, hint) {
          const error = hint.originalException;
          if (isResponse(error)) {
            let body = '';
            try {
              body = await error.clone().text();
            } catch (_e) {
              body = '[error retrieving response body]';
            }
            event.extra = {
              body,
              headers: error.headers,
              status: error.status,
              type: error.type,
              url: error.url,
            };
          }
          return event;
        },
        dsn: newValue,
        environment: isDebug
          ? 'development'
          : window.location.hostname.match(/(^|\.)int\./)
            ? 'integration'
            : 'production',
        ignoreErrors: [
          //Advice from https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
          // Also see https://stackoverflow.com/a/70452078 for the three variations here:
          'TypeError: Failed to fetch',
          'TypeError: NetworkError when attempting to fetch resource.',
          'TypeError: cancelled',
        ],
        integrations: [],
        maxBreadcrumbs: 10,
        release: `octaved-flow@${release}`,
        tracesSampleRate: 1.0,
        tunnel: writeJsLogSio,
      });
      setTag('branch', branch);
      setTag('revision', revision);
      setTag('server_name', window.location.hostname);

      const user: User = {ip_address: '###HC_CLIENT_IP###'}; //replaced by the proxy
      const identityId = identityIdSelector(state);
      if (identityId && identityId !== NIL) {
        user.id = identityId;
      }
      const userId = currentOrgUserIdSelector(state);
      if (userId && userId !== NIL) {
        user.userId = userId;
        user.organizationId = organizationSelector(state)?.id || null;
      }
      setUser(user);

      registerLogHandler((level, ...args) => {
        withScope((scope) => {
          scope.setLevel(level);
          const firstArg = args.shift();
          scope.setExtras({loggerArguments: tryStringify(args)});
          if (firstArg && reportableLevels.has(level)) {
            if (firstArg instanceof Error) {
              captureException(firstArg);
            } else if (typeof firstArg === 'string') {
              captureMessage(firstArg);
            }
          }
        });
      });
    });
  }
});
