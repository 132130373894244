import {DateTimeStr, toDateTimeStr} from '@octaved/typescript';
import {ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {Settings} from '../Modules/Settings';
import {BillingStartEnd} from './BillingStartEnd';

const compareDate = `${ISO_DATE} HH:mm`;

/**
 * Because the booking times are timezone independent, the server's "now" must respect the timezone Flow operates in
 */
function getTimeZonedNow(settings: Settings): dayjs.Dayjs {
  return dayjs().tz(settings.timezone);
}

function removeSeconds(dateTime: DateTimeStr | null): string | null {
  if (dateTime) {
    return dateTime.slice(0, -3); //cut of seconds
  }
  return null;
}

export function isWithin(
  settings: Settings,
  startDateTime: DateTimeStr | null,
  endDateTime: DateTimeStr | null,
): boolean {
  const toleranceDays = settings.timeRecordingBookDaysTolerance;
  const from = getTimeZonedNow(settings).subtract(toleranceDays, 'day').startOf('day').format(compareDate);
  const to = getTimeZonedNow(settings).add(toleranceDays, 'day').startOf('day').format(compareDate);

  const start = removeSeconds(startDateTime);
  const end = removeSeconds(endDateTime);

  return (!start || (start >= from && start <= to)) && (!end || (end >= from && end <= to));
}

export function isWithinBillingStartEnd(settings: Settings, startEnd: BillingStartEnd): boolean {
  return isWithin(settings, toDateTimeStr(startEnd.billingStart), toDateTimeStr(startEnd.billingEnd));
}
