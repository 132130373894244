import {EntityStates} from '@octaved/store/src/EntityState';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {TaskStatusHistories, TaskStatusHistory} from '../../EntityInterfaces/TaskStatusHistory';
import {FlowState} from '../State';

export const taskStatusHistoryStatesSelector = (state: FlowState): EntityStates => state.entityStates.taskStatusHistory;
const taskStatusHistorySelector = (state: FlowState): TaskStatusHistories => state.entities.taskStatusHistory;

export const getTastStatusHistory = createSelector(
  taskStatusHistorySelector,
  (taskStatusHistory) =>
    (taskId: MaybeUuid): TaskStatusHistory[] | null =>
      taskStatusHistory[taskId!] || null,
);
