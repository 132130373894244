import {error} from '@octaved/env/src/Logger';
import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {reduceAccountSetup} from '@octaved/flow/src/Pages/Setup/Components/AccountSetup';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {NIL} from '@octaved/utilities';
import {validateArray, validateFunction, validateInteger, validateString} from '@octaved/validation';
import {clone, get, setWith} from 'lodash';
import {Action, Middleware} from 'redux';

const lastData = Symbol('lastData');

export interface StorageMiddlewareTransformer<V, T> {
  decode: (serialized: T) => V;
  encode: (value: V) => T;
}

interface BaseDefinition {
  default?: unknown;
  doNotRenewSavedOn?: boolean;
  getSavedOnTimestamp?: () => number;
  isValid?: (savedOn: number) => boolean;
  path: string;
  storage: 'localStorage' | 'sessionStorage';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer?: StorageMiddlewareTransformer<any, any>;
  ttl?: number;
  version?: number;
}

export type StorageMiddlewareStateDefinition = BaseDefinition | string;

export interface PreparedStateDefinition extends Omit<BaseDefinition, 'transformer'> {
  [lastData]?: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformer: StorageMiddlewareTransformer<any, any>;
  version: number;
}

const defaultTransformer: PreparedStateDefinition['transformer'] = {
  decode: (value) => value,
  encode: (value) => value,
};

const NAMESPACE = 'reduxLocalStorage';
let stateDefinitions: StorageMiddlewareStateDefinition[] = [];
let preparedDefinitions: PreparedStateDefinition[] | null = null;
let currentOrgUserId = NIL;

//Migrations will only migrate from one to the next version:
const versions = [
  {
    getKey: (path: string, version: number) => `${NAMESPACE}_v2:${currentOrgUserId}:${path}:${version}`,
    migrateJson: (json: string, _key: string) =>
      json.replace(/"intranetGroups"/g, '"group"').replace(/"internalUsers"/g, '"user"'),
  },
  {
    getKey: (path: string, version: number) => `${NAMESPACE}_v3:${currentOrgUserId}:${path}:${version}`,
    migrateJson: (json: string, _key: string) => json, //last version, nothing to migrate to
  },
] as const;
const oldVersions = versions.slice(0, -1);
const lastVersion = versions[versions.length - 1];

export function setStateDefinitions(defs: StorageMiddlewareStateDefinition[]): void {
  stateDefinitions = defs;
}

interface StoredState {
  savedOn: number;
  data: unknown;
}

export interface StorageMiddlewareParams {
  states?: StorageMiddlewareStateDefinition[];
  debounceTime?: number;
  storage?: Storage;
  // inititialState?: S;
}

function prepareStates(defs: StorageMiddlewareStateDefinition[]): PreparedStateDefinition[] {
  validateArray(defs);
  return defs.map((def) => {
    let preparedDef: PreparedStateDefinition;
    if (typeof def !== 'string') {
      preparedDef = {
        ...def,
        transformer: defaultTransformer,
        version: def.version || 1,
      };
      validateString(preparedDef.path);
      if (preparedDef.ttl) {
        validateInteger(preparedDef.ttl);
      }
      if (preparedDef.isValid) {
        validateFunction(preparedDef.isValid);
      }
    } else {
      validateString(def);
      preparedDef = {
        path: def,
        storage: 'localStorage',
        transformer: defaultTransformer,
        ttl: 0,
        version: 1,
      };
    }
    return preparedDef;
  });
}

/**
 * Saves specified parts of the Redux state tree into storage
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStorageMiddleware(): Middleware<any, FlowState, any> {
  function write(reduxState: FlowState): void {
    if (preparedDefinitions) {
      preparedDefinitions.forEach((state) => {
        const data = state.transformer.encode(get(reduxState, state.path));
        if (state[lastData] !== data) {
          setState(state, data);
          state[lastData] = data;
        }
      });
    }
  }

  return (store) => (next) => (action) => {
    const result = next(action);
    write(store.getState());
    return result;
  };
}

export function storageMiddlwareRootReducer(state: FlowState, action: Action): FlowState {
  if (state && action.type === FLOW_INIT_LOAD_SUCCESS) {
    const orgUserId = currentOrgUserIdSelector(state);
    if (orgUserId !== NIL && orgUserId !== currentOrgUserId) {
      currentOrgUserId = orgUserId;
      preparedDefinitions = prepareStates(stateDefinitions);
      return reduceAccountSetup(preparedDefinitions.reduce(getState, state));
    }
  }
  return state;
}

function isStoredStateValid(storedState: StoredState, state: PreparedStateDefinition): boolean {
  if (typeof state.isValid === 'function') {
    return state.isValid(storedState.savedOn);
  }
  if (state.ttl) {
    return storedState.savedOn > Date.now() - state.ttl * 1000;
  }
  return true;
}

function setImmutable<S extends object>(state: S, path: string, value: unknown): S {
  return setWith(clone(state), path, value, clone);
}

function getMigratedJson(def: PreparedStateDefinition): string {
  const storage = window[def.storage];

  let stored = '';
  const migrations: [string, (json: string, key: string) => string][] = [];

  for (let i = versions.length - 1; i >= 0; i--) {
    const version = versions[i];
    const key = version.getKey(def.path, def.version);
    migrations.unshift([key, version.migrateJson]);
    stored = storage[key];
    if (stored) {
      return migrations.reduce((json, [key, migrate]) => migrate(json, key), stored);
    }
  }

  return '';
}

function getState(state: FlowState, def: PreparedStateDefinition): FlowState {
  const stored = getMigratedJson(def);
  if (stored) {
    const storedState = JSON.parse(stored);
    if (isStoredStateValid(storedState, def)) {
      return setImmutable(state, def.path, def.transformer.decode(storedState.data));
    }
  }

  if (def.hasOwnProperty('default')) {
    const pathState = def.transformer.encode(get(state, def.path));
    if (pathState === null || pathState === undefined) {
      return setImmutable(state, def.path, def.default);
    }
  }

  return state;
}

function setState(def: PreparedStateDefinition, data: unknown): void {
  const storage = window[def.storage];

  oldVersions.forEach((version) => {
    const key = version.getKey(def.path, def.version);
    //remove deprecated fallback upon write to avoid leakage when deleting the `ident` from storage:
    delete storage[key];
  });

  const key = lastVersion.getKey(def.path, def.version);
  const storedState = storage[key] && JSON.parse(storage[key]);
  const dataString = JSON.stringify(data);
  const isDefault = JSON.stringify(def.default) === dataString;
  if ((storedState && JSON.stringify(storedState.data) === dataString) || isDefault) {
    //only generate new ttl if the state had changed and is not the default
    if (isDefault) {
      delete storage[key];
    }
    return;
  }

  const savedOn =
    def.doNotRenewSavedOn && storedState && isStoredStateValid(storedState, def)
      ? storedState.savedOn
      : (def.getSavedOnTimestamp || Date.now)();

  try {
    storage[key] = JSON.stringify({data, savedOn});
  } catch (e) {
    error('StorageMiddlware', e);
  }
}

/**
 * Clears all Redux state tree data from storage
 */
export function clear({storage}: {storage: Storage}): void {
  Object.keys(storage).forEach((key) => {
    if (key.startsWith(NAMESPACE)) {
      storage.removeItem(key);
    }
  });
}
