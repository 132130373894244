import {Uuid} from '@octaved/typescript/src/lib';
import classNames from 'classnames';
import {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {customterSearchDropdownSelector} from '../../../Modules/Selectors/CustomerSelectors';
import {removeErrorForField} from '../../../Modules/Ui';
import {FlowStyleBuilder, useFlowStyle} from '../../../Styles/StyleContext';

//#region <styles>
/*language=SCSS*/
const style: FlowStyleBuilder = () => css.resolve`
  .pageHeaderStyle.customerSelectorDropdown {
    min-height: 29px !important;
    position: relative;
    border: 1px solid #ccc !important;
  }

  .pageHeaderStyle.customerSelectorDropdown,
  .pageHeaderStyle.customerSelectorDropdown :global(input) {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pageHeaderStyle.customerSelectorDropdown :global(input) {
    line-height: 14px !important;
  }

  .pageHeaderStyle.customerSelectorDropdown :global(.dropdown.icon) {
    top: -1px !important;
    bottom: -1px !important;
    margin: 0 !important;
    right: -1px !important;
    padding: 0 !important;
    width: 2.2em !important;
    border-radius: 0 4px 4px 0;
    transition: background-color 120ms ease-in-out;
    opacity: 1 !important;
    border: 1px solid #ccc;
    border-left-width: 0;
  }

  .pageHeaderStyle.customerSelectorDropdown :global(.dropdown.icon::before) {
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .pageHeaderStyle.customerSelectorDropdown :global(.text) {
    vertical-align: middle;
    top: 2px;
  }

  .truncateOverlow {
    width: 100% !important;
    min-width: auto !important;
  }

  .truncateOverlow :global(.text[role='alert']) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

//#endregion

interface Props {
  canSelectAll?: boolean;
  pageHeaderStyle?: boolean;
  onChange: (customer: 'all' | Uuid) => void;
  value: 'all' | Uuid;
  fieldName?: string;
  fluid?: boolean;
  showLockedCustomers?: boolean;
  truncate?: boolean;
}

export default function SearchableCustomerSelector({
  canSelectAll,
  pageHeaderStyle,
  onChange,
  value,
  fieldName,
  fluid,
  showLockedCustomers = false,
  truncate = false,
}: Props): ReactElement {
  const {t} = useTranslation();
  const {className, styles} = useFlowStyle(style);
  const [search, setSearch] = useState('');
  const {customerList} = useSelector(customterSearchDropdownSelector)(search, showLockedCustomers);
  const dispatch = useDispatch();

  const dropdownCustomerList = useMemo(() => {
    const items = [];
    if (canSelectAll) {
      items.push({value: 'all', text: t('pages:projects.header.allCustomers')});
    }
    return items.concat(
      ...customerList.map(({id, name, customerNumber}) => ({
        'data-selenium-customer-id': id,
        text: customerNumber ? `${name} (${customerNumber})` : name,
        value: id,
      })),
    );
  }, [canSelectAll, customerList, t]);

  return (
    <>
      <Dropdown
        fluid={fluid}
        className={classNames(className, 'customerSelectorDropdown', {pageHeaderStyle, truncateOverlow: truncate})}
        selection
        onChange={(_e, {value}) => {
          onChange(value as 'all' | Uuid);
          setSearch('');
          if (fieldName) {
            dispatch(removeErrorForField(fieldName));
          }
        }}
        value={value}
        options={dropdownCustomerList}
        onSearchChange={(_e, {searchQuery}) => setSearch(searchQuery)}
        searchQuery={search}
        search
      />
      {styles}
    </>
  );
}
