import {NodeTimeControlMode} from '@octaved/env/src/dbalEnumTypes';
import dayjs, {Dayjs} from 'dayjs';
import {TimeControlledEntity} from '../EntityInterfaces/TimeControlledEntity';
import {BillingStartEnd} from './BillingStartEnd';

function isActive({timeControl}: TimeControlledEntity): boolean {
  return !!timeControl && timeControl.from && (timeControl.mode !== NodeTimeControlMode.custom || !!timeControl.to);
}

export function isWithin(entity: TimeControlledEntity, startEnd: BillingStartEnd): boolean {
  if (isActive(entity)) {
    return judgeWithin(entity, startEnd);
  }
  return true;
}

function judgeWithin(entity: TimeControlledEntity, startEnd: BillingStartEnd): boolean {
  const timeControl = entity.timeControl;
  if (!timeControl) {
    return true;
  }
  let result = true;
  switch (timeControl.mode) {
    case NodeTimeControlMode.yearly:
      result = judgeYearly(startEnd, timeControl.from);
      break;
    case NodeTimeControlMode.quarterly:
      result = judgeQuarterly(startEnd, timeControl.from);
      break;
    case NodeTimeControlMode.monthly:
      result = judgeMonthly(startEnd, timeControl.from);
      break;
    case NodeTimeControlMode.custom:
      result = judgeCustom(startEnd, timeControl.from, timeControl.to);
      break;
    default:
      throw new Error('Invalid mode');
  }
  return result;
}

function judgeYearly(startEnd: BillingStartEnd, from: string): boolean {
  const fromDate = dayjs.utc(from).startOf('year');
  const toDate = fromDate.clone().add(1, 'year');
  return judgeInDateRange(startEnd, fromDate, toDate);
}

function judgeQuarterly(startEnd: BillingStartEnd, from: string): boolean {
  const fromDate = dayjs.utc(from).startOf('quarter');
  const toDate = fromDate.clone().add(3, 'month');
  return judgeInDateRange(startEnd, fromDate, toDate);
}

function judgeMonthly(startEnd: BillingStartEnd, from: string): boolean {
  const fromDate = dayjs.utc(from).startOf('month');
  const toDate = fromDate.clone().add(1, 'month');
  return judgeInDateRange(startEnd, fromDate, toDate);
}

function judgeCustom(startEnd: BillingStartEnd, from: string, to: string): boolean {
  const fromDate = dayjs.utc(from).startOf('day');
  const toDate = dayjs.utc(to).startOf('day').add(1, 'day');
  return judgeInDateRange(startEnd, fromDate, toDate);
}

function judgeInDateRange(startEnd: BillingStartEnd, from: Dayjs, to: Dayjs): boolean {
  const fromUnix = from.unix();
  const toUnix = to.unix();
  const start = startEnd.billingStart;
  const end = startEnd.billingEnd;
  return (!start || (start >= fromUnix && start < toUnix)) && (!end || (end >= fromUnix && end <= toUnix));
}
