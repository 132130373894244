import {isDebug} from '@octaved/env/src/Environment';
import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import FlowCustomerInspectorContext from '../../../../Drawer/InspectorContext/FlowCustomerInspectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const generalRoute = 'general';
const financialRoute = 'financial';
const locationsRoute = 'locations';
const guestAccessRoute = 'guestAccess';
const exportRoute = 'export';

const General = lazy(() => import('../../../../Pages/SystemSettings/Customer/Inspector/General/General'));
const Financial = lazy(() => import('../../../../Pages/SystemSettings/Customer/Inspector/Financial/Financial'));
const Locations = lazy(() => import('../../../../Pages/SystemSettings/Customer/Inspector/Locations/Locations'));
const GuestAccess = lazy(() => import('../../../../Pages/SystemSettings/Customer/Inspector/GuestAccess/GuestAccess'));
const Export = lazy(() => import('../../../../Pages/SystemSettings/Customer/Inspector/Export/Export'));

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['flowCustomer']: [
    {
      path: generalRoute,
      token: 'drawer:customer.tab.general',
    },
    {
      path: financialRoute,
      token: 'drawer:customer.tab.financial',
    },
    {
      path: locationsRoute,
      token: 'drawer:customer.tab.locations',
    },
    {
      access: () => isDebug,
      path: guestAccessRoute,
      token: 'guestUser:customerPage.inspector.tab',
    },
    {
      path: exportRoute,
      token: 'drawer:customer.tab.exports',
    },
  ],
};
//endregion

export const customerInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <General />
        </InspectorContextLoadingWrapper>
      ),
      path: generalRoute,
    },
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <Financial />
        </InspectorContextLoadingWrapper>
      ),
      path: financialRoute,
    },
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <Locations />
        </InspectorContextLoadingWrapper>
      ),
      path: locationsRoute,
    },
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <GuestAccess />
        </InspectorContextLoadingWrapper>
      ),
      path: guestAccessRoute,
    },
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <Export />
        </InspectorContextLoadingWrapper>
      ),
      path: exportRoute,
    },
    {
      element: <Navigate to={generalRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: FlowCustomerInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
