import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {ComponentType} from 'react';
import {ViewTabs} from '../../Components/ViewTabs';
import {
  advancedRoute,
  controllingRoute,
  detailsRoute,
  generalRoute,
  guestUserRoute,
  overviewRoute,
  planningRoute,
  responsibleRoute,
  settingsRoute,
  specialPlanningRoute,
  specialQuoteRoute,
} from '../../LargeView/RoutePaths';
import {LargeViewPages} from '../../LargeView/Routes';
import Advanced from '../Views/Group/Advanced';
import Controlling from '../Views/Group/Controlling';
import General, {GroupDetailsProps} from '../Views/Group/General';
import Guests from '../Views/Group/Guests';
import Planning from '../Views/Group/Planning';
import Responsibility from '../Views/Group/Responsibility';
import Settings from '../Views/Group/Settings';
import SpecialQuote from '../Views/Group/SpecialQuote';

export type GroupInpsectorTabs =
  | Extract<
      LargeViewPages,
      'general' | 'responsibility' | 'planning' | 'controlling' | 'guests' | 'settings' | 'advanced'
    >
  | typeof specialQuoteRoute
  | typeof specialPlanningRoute;

export const groupInspectorTabs: ViewTabs<GroupInpsectorTabs>[] = [
  {
    access: () => {
      return location.pathname.startsWith(`/${specialQuoteRoute}`);
    },
    path: specialQuoteRoute,
    token: 'nodeDetails:tab.createOffer',
    value: specialQuoteRoute,
  },
  {
    access: ({isSimulationActive}) => {
      return (
        (location.pathname.startsWith('/projectPlanning') || location.pathname.startsWith('/teamPlanning')) &&
        isSimulationActive
      );
    },
    path: specialPlanningRoute,
    token: 'nodeDetails:tab.specialPlanning',
    value: specialPlanningRoute,
  },
  {
    path: generalRoute,
    token: 'nodeDetails:tab.general',
    value: 'general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibleRoute,
    token: 'nodeDetails:tab.responsibility',
    value: 'responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'nodeDetails:tab.planning',
    value: 'planning',
  },
  {
    path: controllingRoute,
    token: 'nodeDetails:tab.controlling',
    value: 'controlling',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_GUEST_PERMISSION_ROLE_ASSIGNMENTS', anyNode),
    path: guestUserRoute,
    token: 'nodeDetails:tab.guestUser',
    value: 'guests',
  },
  {
    path: settingsRoute,
    token: 'nodeDetails:tab.settings',
    value: 'settings',
  },
  {
    path: advancedRoute,
    token: 'nodeDetails:tab.advanced',
    value: 'advanced',
  },
];

export const groupInspectorTabViews: Record<GroupInpsectorTabs, ComponentType<GroupDetailsProps>> = {
  advanced: Advanced,
  controlling: Controlling,
  createOffer: SpecialQuote,
  general: General,
  guests: Guests,
  planning: Planning,
  responsibility: Responsibility,
  settings: Settings,
  specialPlanning: () => <div>specialPlanning</div>,
};

const groupInspectorTabsToLargeViews = {
  advanced: `${detailsRoute}/${advancedRoute}`,
  controlling: `${controllingRoute}/${overviewRoute}`,
  createOffer: overviewRoute,
  general: overviewRoute,
  guests: `${detailsRoute}/${guestUserRoute}`,
  planning: `${detailsRoute}/${planningRoute}`,
  responsibility: `${detailsRoute}/${responsibleRoute}`,
  settings: `${detailsRoute}/${settingsRoute}`,
  specialPlanning: overviewRoute,
} as const;

type value = keyof typeof groupInspectorTabsToLargeViews;
export type GroupLargeViewPaths = (typeof groupInspectorTabsToLargeViews)[value];

export function getLargeViewPageFromInspectorTab(tab: value): GroupLargeViewPaths {
  return groupInspectorTabsToLargeViews[tab];
}
