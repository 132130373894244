import {ButtonColorScheme, ButtonVariant, IconButton} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Input} from 'semantic-ui-react';
import {DialogContent, DialogFrame, DialogFrameProps, DialogTitle} from '../../Dialogs/DialogFrame';
import DialogAndDrawerHeader from '../Layout/DialogAndDrawerHeader';

interface ConfirmDialogProps {
  title?: string;
  children: ReactNode;
  buttonLabel: string;
  buttonIcon: ReactElement;
  buttonColorScheme?: ButtonColorScheme;
  buttonVariant?: ButtonVariant;
  size?: DialogFrameProps['size'];
  noConfirm?: boolean | (() => boolean);
  confirmationText?: string | null;

  cancelCallback?: () => void;
  submitCallback?: () => void;
}

export default function ConfirmDialog({
  buttonColorScheme,
  buttonIcon,
  buttonLabel,
  buttonVariant,
  children,
  title,
  cancelCallback,
  submitCallback,
  size = 'mini',
  noConfirm = false,
  confirmationText = '',
}: ConfirmDialogProps): ReactElement {
  const {t} = useTranslation();
  const [confirmation, setConfirmation] = useState('');
  const expectedConfirmation = confirmationText && t(confirmationText);

  return (
    <DialogFrame size={size}>
      <DialogTitle onClose={cancelCallback}>{title && <DialogAndDrawerHeader header={title} noPadding />}</DialogTitle>

      <DialogContent>
        <div className={'form'}>
          <div className={'desc'}>{children}</div>
          {confirmationText && (
            <>
              <Spacer />
              <Input
                value={confirmation}
                onChange={(_e, data) => {
                  setConfirmation(data.value);
                }}
              />
            </>
          )}
          {(noConfirm === false || (typeof noConfirm === 'function' && noConfirm() === false)) && (
            <>
              <Spacer />
              <IconButton
                colorScheme={buttonColorScheme}
                variant={buttonVariant}
                icon={buttonIcon}
                label={buttonLabel}
                onClick={submitCallback}
                isDisabled={expectedConfirmation !== confirmation}
                type={'button'}
              />
            </>
          )}
          {/*#region styles*/}
          {/*language=SCSS*/}
          <style jsx>{`
            .form {
              padding: 10px 0;
              display: flex;
              align-items: center;
              flex-direction: column;
            }

            .desc {
              text-align: left;
              width: 100%;
            }
          `}</style>
          {/*#endregion*/}
        </div>
      </DialogContent>
    </DialogFrame>
  );
}
