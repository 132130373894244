/* istanbul ignore file */

import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';

//No role but the "none"-roles should be needed in the client - all other roles will be freely configurable

export const project = {
  none: 'FE0E0DB4653011EA88D30242AC160004',
};

export const permission = {
  full: '12FD142CF7584A57A4876FA09EB1A4F0',
  none: 'F6FA07F0653011EA88D30242AC160004',
  reader: 'EB843DF693334ACC934A6FBBF458CD31',
  standard: '1DEAB528631D42EDADA11EFE975C7D80',
};

export const globalPermission = {
  adminAdvanced: 'E34B187985FD4D07AEE33F4A124D9B41',
  adminFull: '7598AAEF8050469D80B923D63679A55B',
  adminLabelKanbanBoard: '2F4893CF0D494CCF8758D7B92D8E5FF5',
  adminProjectFolders: '63D321DB5E1347069BC5806FEBFA1CEB',
  customersManager: 'E60788263CE846A798138612F4C3E519',
  customWorkingTimesManager: 'D7B7DF8898F147E2B27C4E9F566CC19A',
  customWorkingTimesReader: '203D9878412849F2811755AB75C6FC63',
  customWorkingTimesReaderVacation: '99C4E5AE4CD243C3928FAC5ADED39C33',
  guestUser: '047DDBBBA92F4EA893E71F0DE7535CE8',
  guestUsersManager: '78EABA44C6714344B076E526A8EF4E63',
  internalUser: 'F61168327E7E421A994E9B4349A3D4D2',
  internalUsersManager: '1E9639D63D574D5CBBAD7A6E3B84A174',
  otherUserTimeSheetReader: '72934D888EC549039E186E943CACEE13',
  priceCategoriesManager: 'E768FFE5B8394B3EAF27D5484B0C0FBF',
  weeklyWorkingTimesManager: '7181835908C640BFB179E920D6639001',
  weeklyWorkingTimesReader: 'E1302F3627F34918A6857C4016C05486',
  workingTimeTrackingManager: '1A7A6EAE4CDE45E48E5F95B9803126FC',
  workingTimeTrackingReader: '24B0819E3CF1471E809BF3AB27EA0758',
};

export const noneRoles = {
  [EnumFlowNodeRoleType.VALUE_PERMISSION]: permission.none,
  [EnumFlowNodeRoleType.VALUE_PROJECT]: project.none,
};

export const isRemovedRole = (role: string): boolean => role === permission.none || role === project.none;

const nonAssignableRoleIds = new Set<string>([]);

export function isAssignableRole(roleId: string): boolean {
  return !nonAssignableRoleIds.has(roleId);
}
