import {useChildPropsFilter} from '@octaved/hooks';
import {FlexItem, Stack} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';
import PageContent, {PageContentProps} from './PageContent';
import PageContentHeader, {PageContentHeaderProps} from './PageContentHeader';
import PageContentHelp, {PageContentHelpProps} from './PageContentHelp';

interface StandardPageFrameChildProps {
  PageContent: PageContentProps;
  PageContentHeader?: PageContentHeaderProps;
  PageContentHelp?: PageContentHelpProps;
}

interface Props {
  children: ReactNode;
  relative?: boolean;
}

export default function StandardPageContent({children, relative}: Props): ReactElement {
  const props: StandardPageFrameChildProps = useChildPropsFilter(children, [
    'PageContentHeader',
    'PageContentHelp',
    'PageContent',
  ]);

  return (
    <Stack alignItems={'stretch'} overflow={'hidden'} height={'100%'} position={relative ? 'relative' : undefined}>
      {props.PageContentHeader && (
        <FlexItem flexShrink={0}>
          <PageContentHeader {...props.PageContentHeader} />
        </FlexItem>
      )}

      {props.PageContentHelp && (
        <FlexItem flexShrink={0}>
          <PageContentHelp {...props.PageContentHelp} />
        </FlexItem>
      )}

      <FlexItem flexGrow={1} overflow={'hidden'} height={'100%'}>
        <PageContent {...props.PageContent} />
      </FlexItem>
    </Stack>
  );
}
