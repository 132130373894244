import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {cn} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';

interface Props {
  addition?: ReactNode;
  bigger?: boolean;
  capitalize?: boolean;
  className?: string;
  for?: string;
  inline?: boolean;
  lessWeight?: boolean;
  noBotMargin?: boolean;
  token: ComplexTranslatable;
  uppercase?: boolean;
  withColon?: boolean;
}

function Label({
  addition,
  bigger,
  capitalize,
  className,
  inline,
  lessWeight,
  noBotMargin,
  token,
  uppercase,
  withColon,
  ...props
}: Props): ReactElement {
  return (
    <label
      htmlFor={props.for}
      className={cn('label text-sky-800', {bigger, inline, lessWeight, capitalize, uppercase, noBotMargin}, className)}
    >
      <ComplexTrans translatable={token} />
      {withColon && ':'}

      {addition}
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .label {
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          grid-gap: 6px;
          margin-bottom: 3px;
        }

        .noBotMargin {
          margin-bottom: 0;
        }

        .inline {
          display: inline-flex;
          margin-bottom: 0;
        }

        .capitalize {
          text-transform: capitalize;
        }

        .uppercase {
          text-transform: uppercase;
        }

        .lessWeight {
          font-weight: 500;
        }

        .bigger {
          font-size: 16px;
        }
      `}</style>
      {/*#endregion*/}
    </label>
  );
}

export {Label};
