import {hasFeature} from '@octaved/env/src/Features';
import InboxSystemNotifications from '@octaved/inbox/src/InboxSystemNotifications';
import {useIsMobileSafari} from '@octaved/ui-components/src/BrowserDetect/IsMobileSafari';
import classNames from 'classnames';
import {lazy, ReactElement, Suspense} from 'react';
import {Toaster} from 'react-hot-toast';
import {PageLockContextProvider} from './Components/PageLock/PageLockContext';
import Initialization from './Initialization';
import './jsx';
import './main.css';
import EnvironmentListener from './Maintenance/EnvironmentListener';
import MasterData from './MasterData';
import PageWrapper from './Pages/PageWrapper';
import './semantic-ui/semantic.less';
import {COLORS, GENERAL_DESIGN} from './Styles/Default';
import ContextProvider from './Styles/StyleContext';

const DevLanguageSwitcher = lazy(() => import('@octaved/i18n/src/Language/DevLanguageSwitcher'));

export default function App(): ReactElement | null {
  const ipad = useIsMobileSafari();

  return (
    <PageLockContextProvider>
      <Suspense fallback={<></>}>
        <div className={classNames('flow text-base text-slate-800', {ipad})}>
          <Initialization>
            <MasterData>
              <ContextProvider initialState={{ANIMATIONS: GENERAL_DESIGN.ANIMATION, COLORS}}>
                <PageWrapper />
              </ContextProvider>
              <EnvironmentListener />
              <Toaster position={'bottom-left'} />
              <Suspense fallback={null}>
                {/* Must use <Suspense /> because the useTranslation-hook causes errors on page load otherwise */}
                <InboxSystemNotifications />
              </Suspense>
            </MasterData>
            {hasFeature('devLanguageSwitcher') && <DevLanguageSwitcher />}
          </Initialization>
        </div>
      </Suspense>

      {/*language=SCSS*/}
      <style jsx global>{`
        :root {
          --fs-lg: 16px;

          --color-white: #fff;
          --color-black: #000;

          --color-grey-25: #f4f4f4;
          --color-grey-50: rgba(0, 0, 0, 0.03);
          --color-grey-100: rgba(209, 215, 224, 0.25);
          --color-grey-125: #ebebeb;
          --color-grey-150: rgba(209, 215, 224, 0.65);

          --color-grey-200: #bbb;
          --color-grey-300: #afafaf;
          --color-grey-400: #a0aec0;
          --color-grey-500: #718096;
          --color-grey-600: #888;
          --color-grey-700: #666;

          --color-grey-900: #333;

          --color-blue-50: #edf8ff;
          --color-blue-100: rgba(44, 123, 229, 0.35);
          --color-blue-400: #58aeff;
          --color-blue-600: #2c7be5;
          --color-blue-700: #2c5282;
          --color-blue-800: #2a4365;

          --color-green-50: #f0fff4;
          --color-green-100: #c5fdd8;
          --color-green-500: #01d43c;
          --color-green-700: #00b83f;
          --color-green-800: #02ab3c;

          --color-red-50: #fef2f2;
          --color-red-100: #ffb8b8;
          --color-red-400: #e52c2c;
          --color-red-700: #b82200;

          --color-yellow-100: #fff597;
          --color-yellow-400: #ecc94b;

          --color-orange-50: #fffaf0;

          --color-purple-300: #aa18ff;
          --color-purple-500: #7c00c0;
          --color-purple-600: #6a2273;
          --color-purple-700: #540080;
        }

        .transAction {
          color: #0b66a7;
          cursor: pointer;
          user-select: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .spin,
        .spin > svg {
          animation-name: spin;
          animation-duration: 500ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        /* see https://github.com/recharts/recharts/issues/3170 tracked by https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/922 */
        .recharts-layer.recharts-pie,
        .recharts-pie-sector:focus,
        .recharts-sector:focus {
          outline: none !important;
        }
      `}</style>

      {/*language=SCSS*/}
      <style jsx>{`
        .flow {
          height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          transition: filter 350ms ease-in-out;

          &:after {
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 350ms ease-in-out;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            content: ' ';
            pointer-events: none;
            z-index: 2000;
          }
        }

        @supports (-webkit-touch-callout: none) {
          //noinspection CssInvalidPropertyValue
          .flow {
            height: -webkit-fill-available;
          }
        }

        .backdrop {
          filter: grayscale(80%);

          &:after {
            background-color: rgba(0, 0, 0, 0.1);
            pointer-events: all;
          }
        }

        //This does push the dialog + dimmer below the header, we cannot use pageBody as this renders too
        //late when using reload
        .flow :global(.ui.modals.dimmer) {
          top: 80px !important;
          position: absolute !important;
        }
      `}</style>
    </PageLockContextProvider>
  );
}
