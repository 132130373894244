import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {PagesSubMenuOption} from '../../Pages/PagesSubMenu';
import LastVisitRedirect from '../LastVisitRedirect';
import {planningRoute} from './Inspectors/InspectorRoutes';
import {getStandardInspectorRoutes} from './Inspectors/StandardInspector';

const KanbanGroups = lazy(() => import('../../Pages/Planning/Pages/Kanban/Groups/Groups'));
const KanbanProjects = lazy(() => import('../../Pages/Planning/Pages/Kanban/Projects/Projects'));
const KanbanTasks = lazy(() => import('../../Pages/Planning/Pages/Kanban/Tasks/Tasks'));
const KanbanWorkPackages = lazy(() => import('../../Pages/Planning/Pages/Kanban/WorkPackages/WorkPackages'));

const kanbanProjectRoute = 'kanbanProjects';
const kanbanGroupRoute = 'kanbanGroups';
const kanbanWorkPackageRoute = 'kanbanWorkPackages';
const kanbanTaskRoute = 'kanbanTasks';

export const boardRoutes: RouteObject[] = [
  {
    children: getStandardInspectorRoutes({defaultRoute: planningRoute}),
    element: <KanbanProjects />,
    path: `${kanbanProjectRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({defaultRoute: planningRoute}),
    element: <KanbanGroups />,
    path: `${kanbanGroupRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({defaultRoute: planningRoute}),
    element: <KanbanWorkPackages />,
    path: `${kanbanWorkPackageRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({defaultRoute: planningRoute}),
    element: <KanbanTasks />,
    path: `${kanbanTaskRoute}/*`,
  },
  {path: '*', element: <LastVisitRedirect default={kanbanProjectRoute} />},
];

export const boardsMenu: PagesSubMenuOption[] = [
  {
    header: true,
    name: 'pages:planning.menu.kanbanBoard',
  },
  {
    name: 'pages:planning.menu.kanbanBoardProjects',
    path: kanbanProjectRoute,
  },
  {
    name: 'pages:planning.menu.kanbanBoardGroups',
    path: kanbanGroupRoute,
  },
  {
    name: 'pages:planning.menu.kanbanBoardWorkPackages',
    path: kanbanWorkPackageRoute,
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_TASK_READ_BASIC', anyNode),
    name: 'pages:planning.menu.kanbanBoardTasks',
    path: kanbanTaskRoute,
  },
];
