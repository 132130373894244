import classNames from 'classnames';
import {memo, ReactElement, useState} from 'react';
import {ColorChangeHandler, SketchPicker} from 'react-color';
import {Popup} from 'semantic-ui-react';
import {PopupProps} from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import css from 'styled-jsx/css';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .popup {
    z-index: 2002;
  }
`;

//#endregion
interface Props extends PopupProps {
  color: string;
  setColor: ColorChangeHandler;
  disableAlpha?: boolean;
  presetColors?: string[];
  borderColor?: string;
  width?: string;
  height?: string;
  readonly?: boolean;
}

const ColorPicker = memo(function Picker({
  color,
  setColor,
  disableAlpha = true,
  presetColors,
  borderColor = '#CCC',
  width = '32px',
  height = '32px',
  readonly = false,
  ...rest
}: Props): ReactElement {
  const [showPicker, setShowPicker] = useState(false);
  return (
    <>
      <Popup
        className={classNames(className, 'popup')}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: window,
            },
          },
        ]}
        trigger={
          <div
            className={classNames('colorPicker', {readonly})}
            style={{backgroundColor: color}}
            onClick={() => {
              if (!readonly) {
                setShowPicker(!showPicker);
              }
            }}
          />
        }
        on={'click'}
        onOpen={() => {
          if (!readonly) {
            setShowPicker(true);
          }
        }}
        open={showPicker}
        onClose={() => setShowPicker(false)}
        {...rest}
      >
        <div className={'picker'}>
          <SketchPicker presetColors={presetColors} color={color} disableAlpha={disableAlpha} onChange={setColor} />
        </div>
      </Popup>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .colorPicker {
          height: ${height};
          width: ${width};
          border: 1px solid ${borderColor};
          position: relative;
          cursor: pointer;

          &.readonly {
            cursor: default;
          }
        }

        .picker :global(.sketch-picker) {
          box-shadow: none !important;
        }
      `}</style>
      {styles}
      {/*#endregion*/}
    </>
  );
});

export {ColorPicker};
