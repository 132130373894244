import {ReactElement} from 'react';
import {Progress, StrictProgressProps} from 'semantic-ui-react';

export type ExtendedProgressProps = Omit<StrictProgressProps, 'color'> & {
  color?: StrictProgressProps['color'] | 'darkRed';
};

export default function ExtendedProgress({children, color, ...rest}: ExtendedProgressProps): ReactElement {
  return (
    <div className={color === 'darkRed' ? 'darkRed' : undefined}>
      <Progress {...rest} color={color === 'darkRed' ? 'red' : color}>
        {children}
      </Progress>
      {/*language=scss*/}
      <style jsx>{`
        .darkRed :global(.bar) {
          background-color: #9d001f !important;
        }
      `}</style>
    </div>
  );
}
