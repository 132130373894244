import {ReactElement} from 'react';

interface Props {
  from: number | null;
  to: number | null;
  showAlwaysMinutes?: boolean;
}

interface DateDuration {
  seconds: number;
  minutes: number;
  hours: number;
}

export default function Duration({from, to, showAlwaysMinutes = false}: Props): ReactElement | null {
  if (from !== null && to !== null) {
    const diff = to - from;

    const duration: DateDuration = {
      hours: Math.floor(diff / 3600),
      minutes: Math.floor((diff % 3600) / 60),
      seconds: Math.round(diff % 60),
    };

    return (
      <span>
        {duration.hours > 0 && <>{duration.hours}h</>}
        {(duration.minutes > 0 || (showAlwaysMinutes && diff > 0)) && (
          <>
            {duration.hours > 0 && <>&nbsp;</>}
            {duration.minutes < 10 && duration.hours > 0 ? '0' + duration.minutes : duration.minutes}m
          </>
        )}
        {diff === 0 && <>0h</>}
      </span>
    );
  }

  return null;
}
