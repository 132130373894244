import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {PlanningEvent} from '@octaved/flow/src/Modules/Events';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isSubWorkPackage, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {createTimestampReducer, EntityStates, INVALIDATED, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import {getPlanningDatesByNodeIds} from '../../config/routes';
import {maxDate, minDate} from '../Calculations/DateCalculations';
import {calculateOffset} from '../Calculations/Offset';
import {addWorkdays, workdays, workdaysBetween} from '../Calculations/WorkdayCalculations';
import {PlanningDate, PlanningDates} from '../EntityInterfaces/PlanningDates';
import {PlanningDependency} from '../EntityInterfaces/PlanningDependency';
import {
  getMinMaxPlanningDatesSelector,
  getPlanningDatesForNodeSelector,
  planningDateEntityStatesSelector,
  planningDatesSelector,
  sortPlanningDates,
} from '../Selectors/PlanningDateSelectors';
import {
  getIsPlannedRelativeToParentSelector,
  getPlanningPredecessorsSelector,
  TYPE_FF,
  TYPE_FS,
  TYPE_SS,
} from '../Selectors/PlanningDependencySelectors';
import {
  FLOW_GET_PLANNING_DATES_START,
  FLOW_GET_PLANNING_DATES_SUCCESS,
  FLOW_GET_PLANNING_FOR_NODES_REQUEST,
  FLOW_GET_PLANNING_FOR_NODES_SUCCESS,
  FLOW_PATCH_PLANNING_REQUEST,
} from './ActionTypes';
import {patchPlanning, PatchPlanningRequest, PlanningPatchData} from './Planning';

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_GET_PLANNING_FOR_NODES_REQUEST, createTimestampReducer('options.data.ids', LOADING));
stateReducerMap.set(FLOW_GET_PLANNING_FOR_NODES_SUCCESS, createTimestampReducer('options.data.ids', LOADED));
stateReducerMap.set('flow.PlanningEvent', createTimestampReducer('updatedPlanningDateNodeIds', INVALIDATED));
stateReducerMap.set(FLOW_PATCH_PLANNING_REQUEST, (state: EntityStates, {planningDates}: PatchPlanningRequest) => {
  if (planningDates) {
    const ids = Object.values(planningDates).map((p) => p?.nodeId);
    return createTimestampReducer('ids', LOADED)(state, {ids});
  }
  return state;
});
export const planningDateEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion

export function copyPlanningDates(source: PlanningDate[]): PlanningDate[] {
  return source.map((s) => ({...s, id: generateUuid()}));
}

//#region Reducer

const planningDatesEntityReducers = createReducerCollection<PlanningDates>({});

planningDatesEntityReducers.add(
  FLOW_PATCH_PLANNING_REQUEST,
  (state: PlanningDates, {planningDates, removedPlanningDates}: PatchPlanningRequest): PlanningDates => {
    if (planningDates || removedPlanningDates.length) {
      let newState: PlanningDates;
      if (planningDates) {
        newState = {...state, ...planningDates};
      } else {
        newState = {...state};
      }
      for (const id of removedPlanningDates) {
        delete newState[id];
      }
      return newState;
    }
    return state;
  },
);

planningDatesEntityReducers.add<PlanningEvent>('flow.PlanningEvent', (state, action) => {
  const newState: PlanningDates = {...state};
  let hasChanged = false;

  for (const planningDates of Object.values(action.updatedNodePlanningDates)) {
    if (planningDates) {
      for (const planningDate of planningDates) {
        const entity = newState[planningDate.id];
        if (entity) {
          const newEntity = mergeStates(entity, planningDate);
          if (entity !== newEntity) {
            newState[planningDate.id] = newEntity;
            hasChanged = true;
          }
        }
      }
    }
  }
  return hasChanged ? newState : state;
});

export const planningDateReducer = planningDatesEntityReducers.reducer;

//#endregion

export const [useLoadPlanningDates, useLoadedPlanningDates] = createUseEntityHook<FlowState, PlanningDate>(
  FLOW_GET_PLANNING_DATES_START,
  FLOW_GET_PLANNING_DATES_SUCCESS,
  getPlanningDatesByNodeIds,
  planningDatesSelector,
  planningDateEntityStatesSelector,
  planningDatesEntityReducers,
  stateReducerMap,
);

export function createPatchData(
  state: FlowState,
  node: NodeEntity,
  planningDates: PlanningDate[],
  forceRelativeToParent = false,
): PlanningPatchData {
  const sortedPlanningDates = sortPlanningDates(planningDates);
  const patchData: PlanningPatchData = {
    nodeId: node.id,
    planningDates: sortedPlanningDates,
  };

  const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(state);
  const isPlannedRelative = getIsPlannedRelativeToParentSelector(state)(node.id);
  if (planningDates.length > 0) {
    const newMinDate = sortedPlanningDates[0].plannedStart;
    const newMaxDate = sortedPlanningDates[sortedPlanningDates.length - 1].plannedEnd;

    const predecessors = getPlanningPredecessorsSelector(state)(node.id, false);
    if (isPlannedRelative || forceRelativeToParent) {
      //adjust offset to work package
      const getNode = getNodeSelector(state);

      const parentIndex = predecessors.findIndex(
        ({predecessor}) => isWorkPackage(getNode(predecessor)) || isSubWorkPackage(getNode(predecessor)),
      );
      const nodeAnchestry = getNodeAncestrySelector(state)(node.id);
      const parent = nodeAnchestry.subWorkPackage ?? nodeAnchestry.workPackage;
      if (parent) {
        const {plannedStart: parentPlannedStart} = getMinMaxPlanningDatesSelector(state)(parent.id);
        if (parentPlannedStart) {
          const offset = workdaysBetween(
            fromIsoDateTimeFormat(parentPlannedStart),
            fromIsoDateTimeFormat(newMinDate),
            getWorkMinutesAtDate,
          );
          if (parentIndex >= 0) {
            const dependency = predecessors[parentIndex];
            if (dependency.offset !== offset) {
              const dependencies = [...predecessors];
              dependencies[parentIndex] = {...dependency, offset};
              patchData.dependencies = dependencies;
            }
          } else if (forceRelativeToParent) {
            const dependencies: PlanningDependency[] = [
              ...predecessors,
              {
                offset,
                predecessor: parent.id,
                successor: node.id,
                type: EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR,
              },
            ];
            patchData.dependencies = dependencies;
          }
        }
      }
    } else if (predecessors.length > 0) {
      // Adjust all offsets to fit the new date
      const newDependencies: PlanningDependency[] = [];
      let offsetChanged = false;
      const oldMinMax = getMinMaxPlanningDatesSelector(state)(node.id);
      const minChanged = oldMinMax.plannedStart !== newMinDate;
      const maxChanged = oldMinMax.plannedEnd !== newMaxDate;
      const shouldAdjustOffset = (type: EnumFlowPlanningDependencyType): boolean => {
        return (minChanged && (type === TYPE_FS || type === TYPE_SS)) || (maxChanged && type === TYPE_FF);
      };

      predecessors.forEach((dependency) => {
        const predMinMax = getMinMaxPlanningDatesSelector(state)(dependency.predecessor);
        if (predMinMax.plannedStart && predMinMax.plannedEnd && shouldAdjustOffset(dependency.type)) {
          const newOffset = calculateOffset(
            dependency.type,
            getWorkMinutesAtDate,
            predMinMax.plannedStart,
            predMinMax.plannedEnd,
            newMinDate,
            newMaxDate,
            true,
          );
          if (newOffset !== dependency.offset) {
            newDependencies.push({...dependency, offset: newOffset});
            offsetChanged = true;
            return;
          }
        }

        //Add dependency unchanged if it wasn't updated above:
        newDependencies.push(dependency);
      });

      if (offsetChanged) {
        patchData.dependencies = newDependencies;
      }
    }
  }
  return patchData;
}

export function addPlanningDate(
  nodeId: Uuid,
  planningDateId: Uuid,
  plannedStart: DateTimeStr,
  plannedEnd: DateTimeStr,
  forceRelativeToParent: boolean,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    if (node) {
      const newDate: PlanningDate = {
        nodeId,
        plannedEnd,
        plannedStart,
        assignedNodeId: null,
        id: planningDateId,
        name: '',
      };
      const planningDates = getPlanningDatesForNode(nodeId);
      dispatch(patchPlanning([createPatchData(state, node, [...planningDates, newDate], forceRelativeToParent)]));
    }
  };
}

export function updatePlanningDate(
  nodeId: Uuid,
  planningDateId: Uuid,
  data: Partial<PlanningDate>,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    if (node) {
      const newPlanningDates = [...getPlanningDatesForNode(nodeId)];
      const index = newPlanningDates.findIndex(({id}) => id === planningDateId);
      if (index >= 0) {
        const oldPlanningDate = newPlanningDates[index];
        newPlanningDates[index] = {
          ...oldPlanningDate,
          ...data,
        };
        dispatch(patchPlanning([createPatchData(state, node, newPlanningDates)]));
      }
    }
  };
}

export function removePlanningDate(nodeId: Uuid, planningDateId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    const planningDates = getPlanningDatesForNode(nodeId);
    const index = planningDates.findIndex(({id}) => id === planningDateId);
    if (node && index >= 0) {
      dispatch(
        patchPlanning([
          createPatchData(
            state,
            node,
            planningDates.filter(({id}) => id !== planningDateId),
          ),
        ]),
      );
    }
  };
}

export function mergePlanningDate(
  nodeId: Uuid,
  planningDateId: Uuid,
  otherPlanningDateId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    const newPlanningDates = [...getPlanningDatesForNode(nodeId)];
    const index = newPlanningDates.findIndex(({id}) => id === planningDateId);
    const otherPlanningDate = newPlanningDates.find(({id}) => id === otherPlanningDateId);
    if (node && index >= 0 && otherPlanningDate) {
      const oldPlanningDate = newPlanningDates[index];
      newPlanningDates[index] = {
        ...oldPlanningDate,
        plannedEnd: maxDate(oldPlanningDate.plannedEnd, otherPlanningDate.plannedEnd),
        plannedStart: minDate(oldPlanningDate.plannedStart, otherPlanningDate.plannedStart),
      };
      dispatch(
        patchPlanning([
          createPatchData(
            state,
            node,
            newPlanningDates.filter(({id}) => id !== otherPlanningDateId),
          ),
        ]),
      );
    }
  };
}

export function mergeAllPlanningDates(nodeId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    const planningDates = getPlanningDatesForNode(nodeId);
    if (node && planningDates.length > 1) {
      const firstPlanningDate = planningDates[0];
      let minPlannedStart = firstPlanningDate.plannedStart;
      let maxPlannedEnd = firstPlanningDate.plannedEnd;

      for (let i = 1; i < planningDates.length; ++i) {
        maxPlannedEnd = maxDate(maxPlannedEnd, planningDates[i].plannedEnd);
        minPlannedStart = minDate(minPlannedStart, planningDates[i].plannedStart);
      }

      dispatch(
        patchPlanning([
          createPatchData(state, node, [
            {...firstPlanningDate, plannedEnd: maxPlannedEnd, plannedStart: minPlannedStart},
          ]),
        ]),
      );
    }
  };
}

export function splitPlanningDate(nodeId: Uuid, planningDateId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const getOrgWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(state);
    const node = getNode(nodeId);

    const newPlanningDates = [...getPlanningDatesForNode(nodeId)];
    const index = newPlanningDates.findIndex(({id}) => id === planningDateId);

    if (node && index >= 0) {
      const oldPlanningDate = newPlanningDates[index];
      const dayJsPlannedStart = fromIsoDateTimeFormat(oldPlanningDate.plannedStart);
      const days = workdays(
        dayJsPlannedStart,
        fromIsoDateTimeFormat(oldPlanningDate.plannedEnd),
        getOrgWorkMinutesAtDate,
      );
      if (days > 1) {
        const halfDays = Math.floor(days / 2);
        const newPlannedEnd = toIsoDateTimeFormat(
          addWorkdays(dayJsPlannedStart, halfDays - 1, getOrgWorkMinutesAtDate),
        );
        const newPlannedStart = toIsoDateTimeFormat(addWorkdays(dayJsPlannedStart, halfDays, getOrgWorkMinutesAtDate));
        newPlanningDates[index] = {
          ...oldPlanningDate,
          plannedEnd: newPlannedEnd,
        };
        newPlanningDates.push({
          ...oldPlanningDate,
          id: generateUuid(),
          plannedStart: newPlannedStart,
        });
        dispatch(patchPlanning([createPatchData(state, node, newPlanningDates)]));
      }
    }
  };
}
