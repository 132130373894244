import {Uuid} from '@octaved/typescript/src/lib';
import isPlainObject from 'lodash/isPlainObject';
import InvalidArgumentError from './InvalidArgumentError';

export const UUID_NIL = '00000000000000000000000000000000';

const isoDateRegexp = /^\d{4}-\d{2}-\d{2}$/;
const isoDateRangeRegexp = /^\d{4}-\d{2}-\d{2}-\d{4}-\d{2}-\d{2}$/;

//RFC2822 email validation
export const validEmailRegExp =
  /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
export const validHttpUrlRegExp = /^http(s)?:\/\/[-a-zA-Z0-9@:%._+~#=]+\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

/**
 * @param {Array} value
 * @param {function} [itemValidator]
 * @param {*} rest passed along to the itemValidator
 */
export function validateArray(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: ReadonlyArray<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemValidator?: (value: any, ...rest: any[]) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...rest: any[]
): void {
  if (!Array.isArray(value)) {
    throw new InvalidArgumentError(`Invalid array: '${value}'`);
  }
  if (typeof itemValidator === 'function') {
    value.forEach((item, index) => {
      try {
        itemValidator(item, ...rest);
      } catch (e) {
        if (e instanceof InvalidArgumentError) {
          throw new InvalidArgumentError(`${e.message} at array index ${index}`);
        } else {
          throw e;
        }
      }
    });
  }
}

/**
 * @param value
 * @param keyToValidator List of properties with their validators.
 *                                                        Extra arguments can be provided as array to allow nesting.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateObject(value: {[x: string]: any}, keyToValidator: object = {}): void {
  if (!isPlainObject(value)) {
    throw new InvalidArgumentError(`Invalid object: '${value}'`);
  }

  for (const [key, validator] of Object.entries(keyToValidator)) {
    if (!value.hasOwnProperty(key)) {
      throw new InvalidArgumentError(`Missing key: '${key}'`);
    }

    let validateFn = validator;
    let validateArgs = [];
    if (Array.isArray(validator)) {
      validateFn = validator[0];
      validateArgs = validator.slice(1);
    }

    try {
      validateFn(value[key], ...validateArgs);
    } catch (e) {
      if (e instanceof InvalidArgumentError) {
        throw new InvalidArgumentError(`${e.message} at key ${key}`);
      } else {
        throw e;
      }
    }
  }
}

export function isObject<T>(val: unknown): val is T {
  return isPlainObject(val);
}

export function isString(val: unknown): val is string {
  return typeof val === 'string';
}

export function isUuid(value: unknown): value is Uuid {
  return isString(value) && value.length === 32 && /^[a-fA-F0-9]{32}$/.test(value);
}

export function isPidNumber(value: unknown): value is string {
  return isString(value) && /^[123456789ABCDEFGHKLMNPQRSTUVWXYZ]{3,10}$/.test(value);
}

export function validateIsoDateString(value: unknown): void {
  if (typeof value !== 'string' || !isoDateRegexp.test(value)) {
    throw new InvalidArgumentError(`Invalid iso date string: '${value}'`);
  }
}

export function isValidIsoDateString(value: unknown): boolean {
  return typeof value === 'string' && isoDateRegexp.test(value);
}

export function validateIsoDateRangeString(value: unknown): void {
  if (typeof value !== 'string' || !isoDateRangeRegexp.test(value)) {
    throw new InvalidArgumentError(`Invalid iso date range string: '${value}'`);
  }
}

export function validateUuid(value: unknown): void {
  if (!isUuid(value)) {
    throw new InvalidArgumentError(`Invalid UUID: '${value}'`);
  }
}

export function validateInteger(value: unknown): void {
  if (!Number.isInteger(value as number)) {
    throw new InvalidArgumentError(`Invalid integer: '${value}'`);
  }
}

export function validateFloat(value: unknown): void {
  if (Number.parseFloat(value as string) !== value) {
    throw new InvalidArgumentError(`Invalid float: '${value}'`);
  }
}

export function validateId(value: unknown): void {
  validateInteger(value);
  if ((value as number) < 1) {
    throw new InvalidArgumentError(`Invalid id: '${value}'`);
  }
}

export function validateBool(value: unknown): void {
  if (typeof value !== 'boolean') {
    throw new InvalidArgumentError(`Invalid boolean: '${value}'`);
  }
}

export function validateString(value: unknown): void {
  if (typeof value !== 'string') {
    throw new InvalidArgumentError(`Invalid string: '${value}'`);
  }
}

export function validateFunction(value: unknown): void {
  if (typeof value !== 'function') {
    throw new InvalidArgumentError(`Invalid function: '${value}'`);
  }
}

export function validateMap(value: unknown): void {
  if (!(value instanceof Map)) {
    throw new InvalidArgumentError(`Invalid Map: '${value}'`);
  }
}

export function validateSet(value: unknown): void {
  if (!(value instanceof Set)) {
    throw new InvalidArgumentError(`Invalid Set: '${value}'`);
  }
}

export function validateEnum(value: string | number | null, allowedValues: Array<string | number | null>): void {
  validateArray(allowedValues);
  if (!allowedValues.includes(value)) {
    throw new InvalidArgumentError(`Invalid value: '${value}', allowed values '${allowedValues.join(', ')}'`);
  }
}

export function validateRegex(value: string, regex: RegExp): void {
  if (!regex.test(value)) {
    throw new InvalidArgumentError(`Invalid value: '${value}' does not match regex '${regex.toString()}'`);
  }
}

export function validateNullable(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemValidator: (value: any, ...rest: any[]) => void,
): void {
  if (value !== null) {
    itemValidator(value);
  }
}

export function isValidEmail(email: unknown): email is string {
  return typeof email === 'string' && validEmailRegExp.test(email);
}
