import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {ComponentType} from 'react';
import {ViewTabs} from '../../Components/ViewTabs';
import {
  advancedRoute,
  detailsRoute,
  generalRoute,
  guestUserRoute,
  overviewRoute,
  planningRoute,
  responsibleRoute,
  settingsRoute,
  specialPlanningRoute,
  specialQuoteRoute,
  timeAndMoneyRoute,
} from '../../LargeView/RoutePaths';
import {LargeViewPages} from '../../LargeView/Routes';
import Advanced from '../Views/WorkPackage/Advanced';
import General, {WorkPackageDetailsProps} from '../Views/WorkPackage/General';
import GuestUsers from '../Views/WorkPackage/GuestUser';
import Planning from '../Views/WorkPackage/Planning';
import Responsibility from '../Views/WorkPackage/Responsibility';
import Settings from '../Views/WorkPackage/Settings';
import SpecialQuote from '../Views/WorkPackage/SpecialQuote';
import TimeAndMoney from '../Views/WorkPackage/TimeAndMoney';

export type WorkPackageInpsectorTabs =
  | Extract<
      LargeViewPages,
      'general' | 'responsibility' | 'planning' | 'timeAndMoney' | 'guests' | 'settings' | 'advanced'
    >
  | typeof specialQuoteRoute
  | typeof specialPlanningRoute;

export const workPackageInspectorTabs: ViewTabs<WorkPackageInpsectorTabs>[] = [
  {
    access: () => {
      return location.pathname.startsWith('/createOffer');
    },
    path: specialQuoteRoute,
    token: 'nodeDetails:tab.createOffer',
    value: specialQuoteRoute,
  },
  {
    access: ({isSimulationActive}) => {
      return (
        (location.pathname.startsWith('/projectPlanning') || location.pathname.startsWith('/teamPlanning')) &&
        isSimulationActive
      );
    },
    path: specialPlanningRoute,
    token: 'nodeDetails:tab.specialPlanning',
    value: specialPlanningRoute,
  },
  {
    path: generalRoute,
    token: 'nodeDetails:tab.general',
    value: 'general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibleRoute,
    token: 'nodeDetails:tab.responsibility',
    value: 'responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'nodeDetails:tab.planning',
    value: 'planning',
  },
  {
    access: ({isGranted}) =>
      isGranted('FLOW_NODE_WORK_PACKAGE_READ_BILLING', anyNode) &&
      isGranted('FLOW_NODE_WORK_PACKAGE_READ_EFFORT', anyNode),
    path: timeAndMoneyRoute,
    token: 'nodeDetails:tab.timeAndFinances',
    value: 'timeAndMoney',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_GUEST_PERMISSION_ROLE_ASSIGNMENTS', anyNode),
    path: guestUserRoute,
    token: 'nodeDetails:tab.guestUser',
    value: 'guests',
  },
  {
    path: settingsRoute,
    token: 'nodeDetails:tab.settings',
    value: 'settings',
  },
  {
    path: advancedRoute,
    token: 'nodeDetails:tab.advanced',
    value: 'advanced',
  },
];

export const workpackageInspectorTabViews: Record<WorkPackageInpsectorTabs, ComponentType<WorkPackageDetailsProps>> = {
  advanced: Advanced,
  createOffer: SpecialQuote,
  general: General,
  guests: GuestUsers,
  planning: Planning,
  responsibility: Responsibility,
  settings: Settings,
  specialPlanning: () => <div>special planning</div>,
  timeAndMoney: TimeAndMoney,
};

const workPackageInspectorTabsToLargeViews = {
  advanced: `${detailsRoute}/${advancedRoute}`,
  createOffer: overviewRoute,
  general: overviewRoute,
  guests: `${detailsRoute}/${guestUserRoute}`,
  planning: `${detailsRoute}/${planningRoute}`,
  responsibility: `${detailsRoute}/${responsibleRoute}`,
  settings: `${detailsRoute}/${settingsRoute}`,
  specialPlanning: `${planningRoute}`,
  timeAndMoney: `${detailsRoute}/${timeAndMoneyRoute}`,
} as const;

type value = keyof typeof workPackageInspectorTabsToLargeViews;
export type WorkPackageLargeViewPaths = (typeof workPackageInspectorTabsToLargeViews)[value];

export function getLargeViewPageFromInspectorTab(tab: value): WorkPackageLargeViewPaths {
  return workPackageInspectorTabsToLargeViews[tab];
}
