import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {identityEmailSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {useSelector} from 'react-redux';
import {isUserAdminLicense, License} from './LicenseInterfaces';
import {licenseSelector} from './Selectors/LicenseSelectors';

export function useHasLicenseBar(): boolean {
  const currentUserEmail = useSelector(identityEmailSelector);
  const license = useSelector(licenseSelector);
  return license.level === 'trial' && currentUserEmail !== 'octaved.demo.001@hcom.de';
}

export function canCreateGuestCustomerUser(license: License): boolean {
  if (!isUserAdminLicense(license)) {
    return false;
  }
  return license.guestUserCustomerDynamic || license.orgUserCounts.guestCustomer.total < license.guestUserCustomerLimit;
}

export function getRelevantOrgUserCount(license: License, orgUserType: UserType): number {
  if (!isUserAdminLicense(license) || orgUserType === UserType.system) {
    return 0;
  }
  if (orgUserType === UserType.guestCustomer) {
    return license.orgUserCounts.guestCustomer.total;
  }
  switch (license.level) {
    case 'trial':
    case 'starter':
      return license.orgUserCounts[orgUserType].total;
    default:
      return license.orgUserCounts[orgUserType].active;
  }
}

export function getOrgUserLimit(license: License, orgUserType: UserType): number {
  if (!isUserAdminLicense(license)) {
    return 0;
  }
  return {
    [UserType.api]: () => license.apiUserLimit,
    [UserType.dummy]: () => license.dummyUserLimit,
    [UserType.guestCustomer]: () => license.guestUserCustomerLimit,
    [UserType.guestOther]: () => license.guestUserOtherLimit,
    [UserType.light]: () => license.lightUserLimit,
    [UserType.regular]: () => license.userLimit,
    [UserType.system]: () => 0,
  }[orgUserType]();
}

function canCreateApiUser(license: License): boolean {
  switch (license.level) {
    case 'trial':
      return getRelevantOrgUserCount(license, UserType.api) < getOrgUserLimit(license, UserType.api);
    case 'business':
    case 'enterprise':
    case 'onPremise':
      return true;
    default:
      return false;
  }
}

function canCreateUserWithSoftLimit(license: License, orgUserType: UserType): boolean {
  switch (license.level) {
    case 'trial':
    case 'starter':
      return getRelevantOrgUserCount(license, orgUserType) < getOrgUserLimit(license, orgUserType);
    default:
      return true;
  }
}

export function canCreateOrgUser(license: License, orgUserType: UserType): boolean {
  switch (orgUserType) {
    case UserType.api:
      return canCreateApiUser(license);
    case UserType.guestCustomer:
      return canCreateGuestCustomerUser(license);
    case UserType.guestOther:
    case UserType.regular:
      return canCreateUserWithSoftLimit(license, orgUserType);
    default:
      return false;
  }
}

export function canActivateUser(license: License, orgUserType: UserType): boolean {
  switch (orgUserType) {
    case UserType.api:
      return false; // API users cannot be activated or deactivated
    case UserType.guestCustomer:
      return canCreateGuestCustomerUser(license);
    case UserType.guestOther:
    case UserType.regular:
      return getRelevantOrgUserCount(license, orgUserType) < getOrgUserLimit(license, orgUserType);
    default:
      return false;
  }
}
