import {createContext, PropsWithChildren, ReactElement, useContext} from 'react';

const context = createContext(false);

export default function NonInteractiveContext({children}: PropsWithChildren<unknown>): ReactElement {
  return <context.Provider value>{children}</context.Provider>;
}

export function useIsNonInteractive(): boolean {
  return useContext(context);
}
