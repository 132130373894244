import StickyNoteSvg from '@fortawesome/fontawesome-pro/svgs/solid/sticky-note.svg';
import {CircleIconButton, Tooltip} from '@octaved/ui';
import {ReactElement, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {showQuickNotesSelector} from '../../../Modules/Selectors/UiSelectors';
import {showQuickNotes} from '../../../Modules/Ui';

export default function QuickNoteButton(): ReactElement {
  const showQuickNote = useSelector(showQuickNotesSelector);
  const dispatch = useDispatch();
  const openQuickNotes = useCallback(() => {
    dispatch(showQuickNotes(!showQuickNote));
  }, [dispatch, showQuickNote]);

  return (
    <Tooltip toolTipTranslation={'tooltips:components.pageHeader.quickAccessButtonNotes'} position={'bottom right'}>
      <div className={'qickNotesButton'} onClick={openQuickNotes}>
        <CircleIconButton iconColor={'yellow'} className={'quickNote'}>
          <StickyNoteSvg />
        </CircleIconButton>

        {/*#region styles*/}
        {/*language=SCSS*/}
        <style jsx>{`
          .qickNotesButton {
            & :global(svg) {
              fill: #ffcf00;
            }
          }
        `}</style>
        {/*#endregion*/}
      </div>
    </Tooltip>
  );
}
