import {useCallback} from 'react';
import {useResolvedPath} from './ResolvedPath';

interface LinkItem {
  path?: string;
}

export function useGetActiveLink<Item extends LinkItem>(): (items: Item[], locationPathname: string) => Item | null {
  const resolvePath = useResolvedPath();

  return useCallback(
    (items: Item[], locationPathname: string): Item | null => {
      for (const item of items) {
        if (item.path) {
          const path = resolvePath(item.path);
          const toPathname = path.pathname;
          if (
            locationPathname === toPathname ||
            (locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === '/')
          ) {
            return item;
          }
        }
      }
      return null;
    },
    [resolvePath],
  );
}
