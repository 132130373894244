import {initialNodeDetailsUiState, NodeDetailsUiState} from '@octaved/node-details/src/Interfaces/Ui/UiState';
import {initialNodeTablesUiState, NodeTablesUiState} from '@octaved/node-table/src/Ui/NodeTableUiStateInterface';
import type {NodeTableView} from '@octaved/node-table/src/Views/NodeTableView';
import {initialOrganizationUiState, UiPagesOrganizationState} from '@octaved/organization/src/Modules/UiPage';
import {initialProjectPlanningUiState, ProjectPlanningUiState} from '@octaved/planning/src/Modules/ProjectPlanning';
import {initialTeamPlanningUiState} from '@octaved/planning/src/Modules/TeamPlanning';
import {initialPlanningState} from '@octaved/planning/src/Modules/Ui';
import {RootPlanningUiState} from '@octaved/planning/src/PlanningState';
import {mergeStates} from '@octaved/store/src/MergeStates';

import Notifications, {Notification} from '@octaved/store/src/Notifications';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {RulesList, validateRules} from '@octaved/store/src/Validation';
import {DateStr} from '@octaved/typescript';
import {DeepPartial, DeepPartialObject, Uuid} from '@octaved/typescript/src/lib';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import objectContains from '@octaved/validation/src/ObjectContains';
import {SelectWpOrSwpTab} from '../Components/Dialog/SelectWpOrSwp/SelectWpOrSwpDialog';
import {TimeRecord} from '../EntityInterfaces/TimeRecords';
import {FLOW_SET_UI} from './Actions';
import {
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_FAILURE,
  FLOW_CHANGE_PID_FAILURE,
  FLOW_CREATE_CUSTOMER_FAILURE,
  FLOW_EDIT_NODE_ROLE_FAILURE,
  FLOW_PATCH_CUSTOMER_FAILURE,
  FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_FAILURE,
} from './ActionTypes';
import {replaceMyTasksFilters} from './MyTasks/MyTasksFilterSets';
import {collapsedInspectorSectionsSelector, pageMenuExpandedGroupsSelector, uiSelector} from './Selectors/UiSelectors';
import {FlowState} from './State';
import {BoardPostsUiState, initialBoardPostsUiState} from './Ui/BoardPosts';
import {HelpUiState, initialHelpUiState} from './Ui/Help';
import {initialState as taskListState, TaskListUiState} from './Ui/TaskList';
import {BillingsListUiState, initialBillingsListUiState} from './UiPages/BillingsList';
import {BoardsUiState, initialUiState as boards} from './UiPages/Boards';
import {CustomWorkingTimesUiState, initialCustomWorkingTimesUiState} from './UiPages/CustomWorkingTimes';
import {initialMyTasksUiState, MyTasksUiState} from './UiPages/MyTasks';
import {initialOrganizeBoardsGroupsUiState, OrganizeBoardsGroupsUiState} from './UiPages/OrganizeBoardsGroups';
import {initialOrganizeBoardsProjectsUiState, OrganizeBoardsProjectsUiState} from './UiPages/OrganizeBoardsProjects';
import {initialOrganizeBoardsTasksUiState, OrganizeBoardsTasksUiState} from './UiPages/OrganizeBoardsTasks';
import {
  initialOrganizeBoardsWorkPackagesUiState,
  OrganizeBoardsWorkPackagesUiState,
} from './UiPages/OrganizeBoardsWorkPackages';
import {initialPersonalTasksUiState, PersonalTasksUiState} from './UiPages/PersonalTasks';
import {initialState as projects, ProjectsUiState} from './UiPages/Projects';
import {initialState as initialProjectTreeTrash, ProjectTreeTrashUiState} from './UiPages/ProjectTreeTrash';
import {initialState as reporting, ReportingUiState} from './UiPages/Reporting';
import {initialState as systemSettings, SystemSettingsUiState} from './UiPages/SystemSettings';
import {initialTeamTasksUiState, TeamTasksUiState} from './UiPages/TeamTasks';
import {initialState as timeRecords, TimeRecordsUiState} from './UiPages/TimeRecordState';
import {initialTrackTimeUiState, TrackTimeUiState} from './UiPages/TrackTime';
import {initialWeeklyWorkingTimesUiState, WeeklyWorkingTimesUiState} from './UiPages/WeeklyWorkingTimes';
import {initialWorkingTimeMonitoringUiState, WorkingTimeMonitoringUiState} from './UiPages/WorkingTimeMonitoring';

//#region interfaces

export interface UnitFilterUIState<UT extends SimpleUnitType = SimpleUnitType> {
  unitId: Uuid;
  unitType: UT;
}

type PayloadTimeRecordType = 'create' | 'createFromDrop' | 'createFromQuickNote' | 'copy' | 'edit' | 'groupBooking';

interface PayloadTimeRecordShowInitialNodeSelection {
  search?: string;
  nodeTableView?: Partial<Omit<NodeTableView, 'id' | 'name'>>;
  tab?: SelectWpOrSwpTab;
}

export interface PayloadTimeRecord extends Partial<Omit<TimeRecord, 'user' | 'workPackage'>> {
  day?: DateStr | null;
  initialSelectPid?: boolean | PayloadTimeRecordShowInitialNodeSelection;
  reference?: Uuid | null;
  type: PayloadTimeRecordType;
  user?: Uuid | null;
}

export type UiState = RootPlanningUiState & {
  boardPosts: BoardPostsUiState;
  collapsedTasks: Uuid[];
  collapsedInspectorSections: string[];
  drawerPinned: boolean;
  drawerWidth: number | null;
  expandedPageMenu: boolean;
  help: HelpUiState;
  nodeTables: NodeTablesUiState;
  notifications: {
    error: Notification[];
    warning: Notification[];
  };
  pageMenu: {
    expandedGroups: string[];
    isExpanded: boolean;
  };
  pages: {
    billingsList: BillingsListUiState;
    boards: BoardsUiState;
    customWorkingTimes: CustomWorkingTimesUiState;
    myTasks: MyTasksUiState;
    nodeDetails: NodeDetailsUiState;
    organization: UiPagesOrganizationState;
    organizeBoardsGroups: OrganizeBoardsGroupsUiState;
    organizeBoardsProjects: OrganizeBoardsProjectsUiState;
    organizeBoardsTasks: OrganizeBoardsTasksUiState;
    organizeBoardsWorkPackages: OrganizeBoardsWorkPackagesUiState;
    personalTasks: PersonalTasksUiState;
    projectPlanning: ProjectPlanningUiState;
    projects: ProjectsUiState;
    projectTreeTrash: ProjectTreeTrashUiState;
    reporting: ReportingUiState;
    systemSettings: SystemSettingsUiState;
    teamTasks: TeamTasksUiState;
    timeRecords: TimeRecordsUiState;
    trackTime: TrackTimeUiState;
    weeklyWorkingTimes: WeeklyWorkingTimesUiState;
    workingTimeMonitoring: WorkingTimeMonitoringUiState;
  };
  search: string;
  showPageFilter: boolean;
  taskView: {
    showEffort: boolean;
    showLabels: boolean;
    showPlanning: boolean;
    showResponsibility: boolean;
  };
  editTimeRecord: PayloadTimeRecord | null;
  showInbox: boolean;
  showProjectFolder: boolean;
  showQuickNotes: boolean;
  taskList: TaskListUiState;
  trackTimeSelectWorkPackageTab: 'mine' | 'last' | 'search';
  viewNodeTimeTracking: {
    export: {
      format: 'excel' | 'pdf';
    };
  };
  workpackageView: {
    progressBar: 'completedTasks' | 'trackedTime';
    showPath: boolean;
    showLabels: boolean;
    showPlanning: boolean;
    showStatusIcon: boolean;
    showTasks: boolean;
  };
};

//#endregion

export const initialUiState: UiState = {
  boardPosts: initialBoardPostsUiState,
  collapsedInspectorSections: [],
  collapsedTasks: [],
  drawerPinned: false,
  drawerWidth: null,
  editTimeRecord: null,
  expandedPageMenu: true,
  help: initialHelpUiState,
  nodeTables: initialNodeTablesUiState,
  notifications: {
    error: [],
    warning: [],
  },
  pageMenu: {
    expandedGroups: ['workspace', 'organize', 'planning'],
    isExpanded: true,
  },
  pages: {
    boards,
    projects,
    reporting,
    systemSettings,
    timeRecords,
    billingsList: initialBillingsListUiState,
    customWorkingTimes: initialCustomWorkingTimesUiState,
    myTasks: initialMyTasksUiState,
    nodeDetails: initialNodeDetailsUiState,
    organization: initialOrganizationUiState,
    organizeBoardsGroups: initialOrganizeBoardsGroupsUiState,
    organizeBoardsProjects: initialOrganizeBoardsProjectsUiState,
    organizeBoardsTasks: initialOrganizeBoardsTasksUiState,
    organizeBoardsWorkPackages: initialOrganizeBoardsWorkPackagesUiState,
    personalTasks: initialPersonalTasksUiState,
    planning: initialPlanningState,
    projectPlanning: initialProjectPlanningUiState,
    projectTreeTrash: initialProjectTreeTrash,
    teamPlanning: initialTeamPlanningUiState,
    teamTasks: initialTeamTasksUiState,
    trackTime: initialTrackTimeUiState,
    weeklyWorkingTimes: initialWeeklyWorkingTimesUiState,
    workingTimeMonitoring: initialWorkingTimeMonitoringUiState,
  },
  search: '',
  showInbox: false,
  showPageFilter: false,
  showProjectFolder: false,
  showQuickNotes: false,
  taskList: taskListState,
  taskView: {
    showEffort: true,
    showLabels: true,
    showPlanning: true,
    showResponsibility: true,
  },
  trackTimeSelectWorkPackageTab: 'mine',
  viewNodeTimeTracking: {
    export: {
      format: 'pdf',
    },
  },
  workpackageView: {
    progressBar: 'trackedTime',
    showLabels: true,
    showPath: true,
    showPlanning: true,
    showStatusIcon: true,
    showTasks: false,
  },
};

//#region reducer
export const reducerMap = new Map();

const {actions, reduceResponseFailure} = Notifications('ui', reducerMap);
export const {
  setErrors,
  addError,
  removeError,
  addErrors,
  removeErrorForField,
  addWarning,
  removeWarning,
  removeWarningForField,
} = actions;

addMultiToReducerMap(
  reducerMap,
  [
    FLOW_EDIT_NODE_ROLE_FAILURE,
    FLOW_CREATE_CUSTOMER_FAILURE,
    FLOW_PATCH_CUSTOMER_FAILURE,
    FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_FAILURE,
    FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_FAILURE,
    FLOW_CHANGE_PID_FAILURE,
  ],
  reduceResponseFailure,
);

export function reduceUiStatePatch(state: UiState, partialState: DeepPartial<UiState>): UiState {
  let newState = mergeStates(state, partialState);
  newState = replaceMyTasksFilters(newState, partialState, 'advancedFilters');
  return newState;
}

reducerMap.set(FLOW_SET_UI, (state: UiState, {partialState}: {partialState: DeepPartial<UiState>}): UiState => {
  return reduceUiStatePatch(state, partialState);
});

export const reducer = ReduceFromMap(reducerMap, initialUiState);

//#endregion

export function setUiState(
  partialState: DeepPartial<UiState> | DeepPartialObject<UiState>,
  force = false,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    if (force || !objectContains(uiSelector(getState()), partialState)) {
      dispatch({partialState, type: FLOW_SET_UI});
    }
  };
}

export function toggleExpandedMenuGroups(groupId: string, expand?: boolean): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const expandedGroups = new Set(pageMenuExpandedGroupsSelector(getState()));
    const has = expandedGroups.has(groupId);
    const explicit = typeof expand === 'boolean';
    const add = explicit ? expand && !has : !has;
    const del = explicit ? !expand && has : has;
    if (add) {
      expandedGroups.add(groupId);
    } else if (del) {
      expandedGroups.delete(groupId);
    }
    if (add || del) {
      dispatch(setUiState({pageMenu: {expandedGroups: [...expandedGroups]}}));
    }
  };
}

export function toggleCollapsedInspectorSections(ident: string): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const collapsedInspectorSections = [...collapsedInspectorSectionsSelector(getState())];
    const has = collapsedInspectorSections.includes(ident);
    if (has) {
      collapsedInspectorSections.splice(collapsedInspectorSections.indexOf(ident), 1);
    } else {
      collapsedInspectorSections.push(ident);
    }
    dispatch(setUiState({collapsedInspectorSections}));
  };
}

export function validateErrorRules(rules: RulesList, dispatch: Dispatch): boolean {
  const errors = validateRules(rules);
  if (errors.length) {
    dispatch(setErrors(errors));
    return false;
  }
  return true;
}

export function showInbox(showInbox: boolean): ActionDispatcher<void, FlowState> {
  return setInspector({showInbox});
}

export function showQuickNotes(showQuickNotes: boolean): ActionDispatcher<void, FlowState> {
  return setInspector({showQuickNotes});
}

export function showProjectFolder(showProjectFolder: boolean): ActionDispatcher<void, FlowState> {
  return setInspector({showProjectFolder});
}

export function createInspectorPatch(state: DeepPartial<UiState> = {}): DeepPartial<UiState> {
  const emptyInspectorState: DeepPartial<UiState> = {
    editTimeRecord: null,
    pages: {
      projects: {
        moveGroupOrWorkPackage: null,
        rearrangeProject: null,
      },
    },
    showInbox: false,
    showProjectFolder: false,
    showQuickNotes: false,
  };
  return mergeStates(emptyInspectorState as UiState, state) as DeepPartial<UiState>;
}

export function setInspector(state: DeepPartial<UiState> = {}): ActionDispatcher<void, FlowState> {
  return setUiState(createInspectorPatch(state));
}
