import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {ComponentType} from 'react';
import {ViewTabs} from '../../Components/ViewTabs';
import {
  detailsRoute,
  generalRoute,
  historyRoute,
  overviewRoute,
  planningRoute,
  specialPlanningRoute,
} from '../../LargeView/RoutePaths';
import {LargeViewPages} from '../../LargeView/Routes';
import General, {TaskDetailsProps} from '../Views/Task/General';
import History from '../Views/Task/History';
import Planning from '../Views/Task/Planning';
import SpecialPlanning from '../Views/Task/SpecialPlanning';

export type TaskInpsectorTabs =
  | Extract<LargeViewPages, 'general' | 'planning' | 'history'>
  | typeof specialPlanningRoute;

export const taskInspectorTabs: ViewTabs<TaskInpsectorTabs>[] = [
  {
    access: ({isSimulationActive}) => {
      return (
        (location.pathname.startsWith('/projectPlanning') || location.pathname.startsWith('/teamPlanning')) &&
        isSimulationActive
      );
    },
    path: specialPlanningRoute,
    token: 'nodeDetails:tab.specialPlanning',
    value: specialPlanningRoute,
  },
  {
    path: generalRoute,
    token: 'nodeDetails:tab.general',
    value: 'general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'nodeDetails:tab.planning',
    value: 'planning',
  },
  {
    path: historyRoute,
    token: 'nodeDetails:tab.history',
    value: 'history',
  },
];

export const taskInspectorTabViews: Record<TaskInpsectorTabs, ComponentType<TaskDetailsProps>> = {
  general: General,
  history: History,
  planning: Planning,
  specialPlanning: SpecialPlanning,
};

const taskInspectorTabsToLargeViews = {
  general: overviewRoute,
  history: `${detailsRoute}/${historyRoute}`,
  planning: `${detailsRoute}/${planningRoute}`,
  specialPlanning: `${detailsRoute}/${planningRoute}`,
} as const;

type value = keyof typeof taskInspectorTabsToLargeViews;
export type TaskLargeViewPaths = (typeof taskInspectorTabsToLargeViews)[value];

export function getLargeViewPageFromInspectorTab(tab: value): TaskLargeViewPaths {
  return taskInspectorTabsToLargeViews[tab];
}
