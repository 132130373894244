import {cn} from '@octaved/ui';
import {HelpCircle, LucideIcon} from 'lucide-react';
import {ReactElement} from 'react';
import {ButtonSize, IconButton} from '../../Form';
import {Tooltip, TooltipProps} from './Tooltip';

interface Props extends Pick<TooltipProps, 'content' | 'position' | 'toolTipTranslation' | 'wide'> {
  buttonSize?: ButtonSize;
  className?: string;
  icon?: LucideIcon;
  inline?: boolean;
  noHelpCursor?: boolean;
}

function HelpTooltip({
  buttonSize = 'xs',
  className,
  icon: Icon = HelpCircle,
  inline,
  noHelpCursor = false,
  ...props
}: Props): ReactElement {
  return (
    <Tooltip basic position={'top center'} mouseEnterDelay={0} {...props} on={'click'}>
      <div
        className={cn(
          !noHelpCursor && 'cursor-help',
          inline && 'ml-1 inline-flex items-center align-[-0.1em]',
          className,
        )}
      >
        <IconButton
          iconColor={'darkGrey'}
          icon={<Icon />}
          size={buttonSize}
          variant={'ghost'}
          cursor={!noHelpCursor ? 'help' : undefined}
        />
      </div>
    </Tooltip>
  );
}

export {HelpTooltip};
