import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {getPlanningPredecessorsSelector} from '../../../Selectors/PlanningDependencySelectors';
import AddTimeBasedDependency from './AddTimeBasedDependency';
import DependencyList from './List/DependencyList';
import PredecessorDependencyListRow from './List/PredessorDependencyListRow';

interface DependencyProps {
  readonly: boolean;
  node: PlanningRealizationNode;
}

export default function Dependency({readonly, node}: DependencyProps): ReactElement {
  const getPlanningPredecessors = useSelector(getPlanningPredecessorsSelector);
  const dependencies = getPlanningPredecessors(node.id, false);
  return (
    <div>
      <DependencyList
        node={node}
        readonly={readonly}
        row={PredecessorDependencyListRow}
        getKey={(dependency) => dependency.predecessor}
        dependencies={dependencies}
      />
      {!readonly && <AddTimeBasedDependency node={node} />}
    </div>
  );
}
