export interface LeftDrawerUiState {
  columnCount?: 1 | 2 | 3; //used for column version, defaults to 1
  isOpen: boolean;
  keepOpenOnSelect?: boolean; //only used in combination with option `closeOnSelect`
  search: string;
}

export const initialLeftDrawerUiState: LeftDrawerUiState = {
  isOpen: false,
  search: '',
};
