import {FLOW_CREATE_TASK_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {PlanningEvent, TaskCreatedEvent, TaskCreateEvent} from '@octaved/flow/src/Modules/Events';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, filterIdsToReload, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {getInstanceUuid} from '@octaved/utilities';
import {getWorkload as getWorkloadRoute} from '../../config/routes';
import {Workloads} from '../EntityInterfaces/Workload';
import {PlanningState} from '../PlanningState';
import {workloadStatesSelector} from '../Selectors/WorkloadSelectors';
import {
  FLOW_GET_WORKLOAD_FAILURE,
  FLOW_GET_WORKLOAD_REQUEST,
  FLOW_GET_WORKLOAD_SUCCESS,
  FLOW_UPDATE_WORKLOAD,
} from './ActionTypes';
import {workload} from './Schema';

//#region state reduce
const workloadEntityStateReducers = createReducerCollection<EntityStates>({});
workloadEntityStateReducers.add(FLOW_GET_WORKLOAD_REQUEST, createTimestampReducer('options.urlParams.ids', LOADING));
workloadEntityStateReducers.add(FLOW_GET_WORKLOAD_SUCCESS, createTimestampReducer('options.urlParams.ids', LOADED));
workloadEntityStateReducers.add(FLOW_CREATE_TASK_SUCCESS, (state, {task: {plannedTime}}: TaskCreateEvent) => {
  return plannedTime ? {} : state; //only invalidate if the task was created with planned time
});
workloadEntityStateReducers.add(
  ['flow.TaskCreatedEvent', 'flow.PlanningEvent'],
  (state, {responsibleInstanceId}: TaskCreatedEvent | PlanningEvent) => {
    //for the own instance FLOW_CREATE_TASK_SUCCESS above handles this, it knows more about the task
    return responsibleInstanceId !== getInstanceUuid() ? {} : state;
  },
);
workloadEntityStateReducers.add(
  [
    'flow.GroupPatchedEvent', //required for agile groups
    'flow.NodeRestoredFromTrashEvent',
    'flow.ProjectCustomerChangedEvent',
    'flow.ProjectPatchedEvent',
    'flow.SubWorkPackagePatchedEvent',
    'flow.TaskPatchedEvent',
    'flow.WorkPackagePatchedEvent',
    'flow.WorkTimeManagedEvent',
  ],
  () => ({}),
);
workloadEntityStateReducers.add('flow.NodeRoleAssignmentEvent', () => ({}));
export const workloadStateReducer = workloadEntityStateReducers.reducer;
//#endregion

//#region reducer
const reducerMap = new Map();
reducerMap.set(
  FLOW_UPDATE_WORKLOAD,
  (_state: Workloads, {updatedWorkload}: {updatedWorkload: Workloads}): Workloads => {
    return updatedWorkload;
  },
);
export const workloadReducer = ReduceFromMap(reducerMap);
workloadReducer.fieldsToCopy = new Set(['plannedNodes']);

//#endregion

export function getWorkload(userIds: Uuid[]): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const toLoad = filterIdsToReload(workloadStatesSelector(state), userIds);

    if (toLoad.length) {
      await dispatch({
        [CALL_API]: {
          endpoint: getWorkloadRoute,
          method: 'get',
          options: {
            urlParams: {ids: toLoad},
          },
          schema: [workload],
          types: {
            failureType: FLOW_GET_WORKLOAD_FAILURE,
            requestType: FLOW_GET_WORKLOAD_REQUEST,
            successType: FLOW_GET_WORKLOAD_SUCCESS,
          },
        },
      });
    }
  };
}
