import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {labelSelector} from '../../../Modules/Selectors/LabelSelectors';
import FlowLabel, {FlowLabelProps} from './FlowLabel';

interface Props extends Omit<FlowLabelProps, 'label'> {
  id: Uuid;
}

export default function FlowLabelById({id, ...props}: Props): ReactElement | null {
  const label = useSelector(labelSelector)(id);

  if (label) {
    return <FlowLabel label={label} {...props} />;
  }

  return null;
}
