import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {PatchableState, usePatchableState} from '@octaved/hooks/src/PatchableState';
import {Uuid} from '@octaved/typescript/src/lib';
import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {useLoadedUserNames} from '@octaved/users/src/Modules/OrgUser';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import NodeDrawerHeader from '../../../../Components/Drawer/NodeDrawerHeader';
import Placeholder from '../../../../Components/Feedback/Placeholder/Placeholder';
import {TimeRecord} from '../../../../EntityInterfaces/TimeRecords';
import {createDefaultTimePeriod, TimePeriod} from '../../../../Modules/Filter/TimePeriod';
import {useLoadedNodes, useNode} from '../../../../Modules/Hooks/Nodes';
import {useLoadedTimeRecords} from '../../../../Modules/Hooks/TimeRecords';
import {useCombinedTimeRecordSearch} from '../../../../Modules/Hooks/TimeRecordSearch';
import {getDepthSelector, useNodeAncestry} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {TimeRecordSearchCondition} from '../../../../Modules/Selectors/TimeRecordSearchSelectors';
import {isSubWorkPackage} from '../../../../Node/NodeIdentifiers';
import Filter, {FilterObject} from './Filter';
import Footer from './Footer';
import Table from './TimeTrackings/Table';

export function useTimeRecords(
  filter: FilterObject,
  nodeId: Uuid | null,
  workPackageIds?: Uuid[],
): {
  hasLoadedOnce: boolean;
  isLoading: boolean;
  timeRecordIds: Uuid[];
  timeRecords: TimeRecord[];
  userIds: Uuid[];
} {
  const query = useMemo<TimeRecordSearchCondition | null>(() => {
    if (!nodeId) {
      return null;
    }
    const conditions: TimeRecordSearchCondition[] = [['rootNodeId', nodeId]];

    //If the number of filtered work packages is low, it's best to search for those record ids specifically.
    // Otherwise load all records for the node. The record entities are filtered by workPackageIds in any case.
    if (workPackageIds && workPackageIds.length < 4) {
      conditions.push({or: workPackageIds.map((wpId) => ['workPackageId', wpId])});
    }

    if (filter.inPeriod) {
      conditions.push(['dateRange', `${filter.period.from}-${filter.period.to}`]);
    }
    return {and: conditions};
  }, [filter.inPeriod, filter.period.from, filter.period.to, nodeId, workPackageIds]);

  const {isLoading: searchIsLoading, ids} = useCombinedTimeRecordSearch(query, true);
  const {isLoading: recordsAreLoading, timeRecords} = useLoadedTimeRecords(ids);

  const userIds = useMemo<Uuid[]>(() => {
    const allUserIds = timeRecords.reduce<Set<Uuid | undefined>>((acc, {user}) => acc.add(user), new Set());
    allUserIds.delete(undefined);
    return [...(allUserIds as Set<Uuid>)];
  }, [timeRecords]);

  //Because these filters are not saved or pre-filled we can assume they are initially off and so we can filter them
  // client side without performance issues - also we can extract all user ids for the user filter this way.
  const [filteredRecords, filteredRecordIds] = useMemo(() => {
    let filtered = timeRecords;

    if (filter.onlyOpenRecords) {
      filtered = filtered.filter(({billingStart, billingEnd}) => !billingStart || !billingEnd);
    }

    if (filter.userId) {
      filtered = filtered.filter(({user}) => user === filter.userId);
    }

    if (filter.messageSearch) {
      const lowerSearch = filter.messageSearch.toLocaleLowerCase();
      filtered = filtered.filter(({message}) => message.toLocaleLowerCase().includes(lowerSearch));
    }

    if (workPackageIds) {
      const wpIdsSet = new Set(workPackageIds);
      filtered = filtered.filter(({workPackage}) => workPackage && wpIdsSet.has(workPackage));
    }

    return [filtered, filtered.map(({id}) => id)];
  }, [filter.messageSearch, filter.onlyOpenRecords, filter.userId, timeRecords, workPackageIds]);

  const isLoading = searchIsLoading || recordsAreLoading;
  return {
    isLoading,
    userIds,
    hasLoadedOnce: useLoadedValue(isLoading, !isLoading),
    timeRecordIds: useLoadedValue(isLoading, filteredRecordIds),
    timeRecords: useLoadedValue(isLoading, filteredRecords),
  };
}

export function useTimeRecordStats(
  nodeId: Uuid,
  timeRecords: TimeRecord[],
): {
  hasBilledRecords: boolean;
  hasJourneys: boolean;
  hasSurcharges: boolean;
  showGroupsColumn: boolean;
  showWorkPackageColumn: boolean;
  hours: number;
  minutes: number;
  referenceNodeIds: ReadonlySet<Uuid>;
  workPackageIds: Uuid[];
} {
  const getDepth = useSelector(getDepthSelector);
  const selectedNodeDepth = getDepth(nodeId);
  return useMemo(() => {
    const referenceNodeIds = new Set<Uuid>();
    const workPackageIds = new Set<Uuid>();
    let seconds = 0;
    let showGroupsColumn = false;
    let showWorkPackageColumn = false;
    let hasBilledRecords = false;
    let hasJourneys = false;
    let hasSurcharges = false;
    timeRecords.forEach(
      ({billingStart, billingEnd, billedOn, isJourney, priceSurcharge, referenceNode, workPackage}) => {
        if (billingStart && billingEnd) {
          seconds += billingEnd - billingStart;
        }
        if (billedOn) {
          hasBilledRecords = true;
        }
        const timeRecordWpDepthDiff = getDepth(workPackage) - selectedNodeDepth;
        if (timeRecordWpDepthDiff > 0) {
          showWorkPackageColumn = true;
        }
        if (timeRecordWpDepthDiff > 1) {
          showGroupsColumn = true;
        }
        if (referenceNode) {
          referenceNodeIds.add(referenceNode);
        }
        if (workPackage) {
          workPackageIds.add(workPackage);
        }
        if (isJourney) {
          hasJourneys = true;
        }
        if (priceSurcharge) {
          hasSurcharges = true;
        }
      },
    );
    return {
      hasBilledRecords,
      hasJourneys,
      hasSurcharges,
      referenceNodeIds,
      showGroupsColumn,
      showWorkPackageColumn,
      hours: Math.floor(seconds / 3600),
      minutes: Math.floor((seconds % 3600) / 60),
      workPackageIds: [...workPackageIds],
    };
  }, [getDepth, selectedNodeDepth, timeRecords]);
}

export function useFilter(initialPeriod?: TimePeriod): {
  filter: FilterObject;
  patchFilter: PatchableState<FilterObject>[1];
} {
  const [filter, patchFilter] = usePatchableState<FilterObject>(() => {
    return {
      inPeriod: !!initialPeriod,
      messageSearch: '',
      onlyOpenRecords: false,
      period: initialPeriod || createDefaultTimePeriod(),
      userId: null,
    };
  });
  return {filter, patchFilter};
}

interface Props {
  initialPeriod?: TimePeriod;
  nodeId: Uuid;
  selectedTimeRecordId: Uuid | null;
  setSelectedTimeRecordId: (id: Uuid | null) => void;
  workPackageIds?: Uuid[];
}

export default function MainContent({
  initialPeriod,
  nodeId,
  selectedTimeRecordId,
  setSelectedTimeRecordId,
  workPackageIds,
}: Props): ReactElement | null {
  const {filter, patchFilter} = useFilter(initialPeriod);
  const {
    isLoading,
    hasLoadedOnce: hasLoadedRecords,
    timeRecordIds,
    timeRecords,
    userIds,
  } = useTimeRecords(filter, nodeId, workPackageIds);
  const hasTimeRecords = timeRecords.length > 0;
  useLoadedUserNames(userIds);
  const stats = useTimeRecordStats(nodeId, timeRecords);
  const {hasLoadedOnce: hasLoadedRefNodes, nodes: refNodes} = useLoadedNodes(stats.referenceNodeIds);
  const node = useNode(nodeId);
  const project = useNodeAncestry(nodeId, true).project;

  const hasLoadedOnce = hasLoadedRecords && hasLoadedRefNodes;

  if (!node) {
    return null;
  }

  return (
    <div className={'mainContent'}>
      <NodeDrawerHeader title={'drawer:viewNodeTimeTracking.title'} nodeId={nodeId} />

      <Filter filter={filter} patchFilter={patchFilter} node={node} userIds={userIds} />

      <div className={'content'}>
        <div className={'tableContent'}>
          {!hasLoadedOnce && <AppLoader />}
          {hasLoadedOnce && hasTimeRecords && (
            <Table
              hasBilledRecords={stats.hasBilledRecords}
              hasJourneys={stats.hasJourneys}
              hasSurcharges={stats.hasSurcharges}
              selected={selectedTimeRecordId}
              setSelected={setSelectedTimeRecordId}
              showGroups={stats.showGroupsColumn}
              showSubWorkPackages={!isSubWorkPackage(node) && !!refNodes.find((refNode) => isSubWorkPackage(refNode))}
              showWorkPackages={stats.showWorkPackageColumn}
              timeRecords={timeRecords}
            />
          )}
          {!isLoading && !hasTimeRecords && (
            <Placeholder
              label={'drawer:viewNodeTimeTracking.noRecordsInfo'}
              direction={'column'}
              textTransform={'uppercase'}
              size={'small'}
              topSpacing
            />
          )}
        </div>
      </div>

      {project && (
        <Footer
          exportDetails={{projectId: project.id, timeRecordIds, workPackageIds: stats.workPackageIds}}
          hours={stats.hours}
          minutes={stats.minutes}
        />
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .content {
          flex-grow: 1;
          overflow-y: scroll;
        }

        .tableContent {
          flex-grow: 1;
          padding: 14px 14px;
        }

        .mainContent {
          height: 100%;
          display: flex;
          align-items: stretch;
          flex-direction: column;
          overflow: hidden;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
