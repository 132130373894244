export const FLOW_RECONNECT = 'FLOW_RECONNECT';

export const FLOW_LOAD_NODES_FAILURE = 'FLOW_LOAD_NODES_FAILURE';
export const FLOW_LOAD_NODES_REQUEST = 'FLOW_LOAD_NODES_REQUEST';
export const FLOW_LOAD_NODES_SUCCESS = 'FLOW_LOAD_NODES_SUCCESS';

export const FLOW_LOAD_NODE_HISTORY_START = 'FLOW_LOAD_NODE_HISTORY_START';
export const FLOW_LOAD_NODE_HISTORY_SUCCESS = 'FLOW_LOAD_NODE_HISTORY_SUCCESS';

export const FLOW_LOAD_ANCESTOR_BUDGET_SUMS_START = 'FLOW_LOAD_ANCESTOR_BUDGET_SUMS_START';
export const FLOW_LOAD_ANCESTOR_BUDGET_SUMS_SUCCESS = 'FLOW_LOAD_ANCESTOR_BUDGET_SUMS_SUCCESS';

export const FLOW_ARCHIVE_NODE_FAILURE = 'FLOW_ARCHIVE_NODE_FAILURE';
export const FLOW_ARCHIVE_NODE_REQUEST = 'FLOW_ARCHIVE_NODE_REQUEST';
export const FLOW_ARCHIVE_NODE_SUCCESS = 'FLOW_ARCHIVE_NODE_SUCCESS';

export const FLOW_MOVE_NODE_FAILURE = 'FLOW_MOVE_NODE_FAILURE';
export const FLOW_MOVE_NODE_REQUEST = 'FLOW_MOVE_NODE_REQUEST';
export const FLOW_MOVE_NODE_SUCCESS = 'FLOW_MOVE_NODE_SUCCESS';

export const FLOW_LOAD_NODE_TREE_FAILURE = 'FLOW_LOAD_NODE_TREE_FAILURE';
export const FLOW_LOAD_NODE_TREE_REQUEST = 'FLOW_LOAD_NODE_TREE_REQUEST';
export const FLOW_LOAD_NODE_TREE_SUCCESS = 'FLOW_LOAD_NODE_TREE_SUCCESS';

export const FLOW_CREATE_BOARD_POST_FAILURE = 'FLOW_CREATE_BOARD_POST_FAILURE';
export const FLOW_CREATE_BOARD_POST_REQUEST = 'FLOW_CREATE_BOARD_POST_REQUEST';
export const FLOW_CREATE_BOARD_POST_SUCCESS = 'FLOW_CREATE_BOARD_POST_SUCCESS';

export const FLOW_CHANGE_BOARD_POST_FAILURE = 'FLOW_CHANGE_BOARD_POST_FAILURE';
export const FLOW_CHANGE_BOARD_POST_REQUEST = 'FLOW_CHANGE_BOARD_POST_REQUEST';
export const FLOW_CHANGE_BOARD_POST_SUCCESS = 'FLOW_CHANGE_BOARD_POST_SUCCESS';

export const FLOW_REMOVE_BOARD_POST_FAILURE = 'FLOW_REMOVE_BOARD_POST_FAILURE';
export const FLOW_REMOVE_BOARD_POST_REQUEST = 'FLOW_REMOVE_BOARD_POST_REQUEST';
export const FLOW_REMOVE_BOARD_POST_SUCCESS = 'FLOW_REMOVE_BOARD_POST_SUCCESS';

export const FLOW_RESORT_BOARD_POST_FAILURE = 'FLOW_RESORT_BOARD_POST_FAILURE';
export const FLOW_RESORT_BOARD_POST_REQUEST = 'FLOW_RESORT_BOARD_POST_REQUEST';
export const FLOW_RESORT_BOARD_POST_SUCCESS = 'FLOW_RESORT_BOARD_POST_SUCCESS';

export const FLOW_CREATE_PROJECT_FAILURE = 'FLOW_CREATE_PROJECT_FAILURE';
export const FLOW_CREATE_PROJECT_REQUEST = 'FLOW_CREATE_PROJECT_REQUEST';
export const FLOW_CREATE_PROJECT_SUCCESS = 'FLOW_CREATE_PROJECT_SUCCESS';

export const FLOW_PATCH_PROJECT_FAILURE = 'FLOW_PATCH_PROJECT_FAILURE';
export const FLOW_PATCH_PROJECT_REQUEST = 'FLOW_PATCH_PROJECT_REQUEST';
export const FLOW_PATCH_PROJECT_SUCCESS = 'FLOW_PATCH_PROJECT_SUCCESS';

export const FLOW_CREATE_GROUP_FAILURE = 'FLOW_CREATE_GROUP_FAILURE';
export const FLOW_CREATE_GROUP_REQUEST = 'FLOW_CREATE_GROUP_REQUEST';
export const FLOW_CREATE_GROUP_SUCCESS = 'FLOW_CREATE_GROUP_SUCCESS';

export const FLOW_CREATE_WORK_PAGKAGE_FAILURE = 'FLOW_CREATE_WORK_PAGKAGE_FAILURE';
export const FLOW_CREATE_WORK_PAGKAGE_REQUEST = 'FLOW_CREATE_WORK_PAGKAGE_REQUEST';
export const FLOW_CREATE_WORK_PAGKAGE_SUCCESS = 'FLOW_CREATE_WORK_PAGKAGE_SUCCESS';

export const FLOW_CHANGE_PID_FAILURE = 'FLOW_CHANGE_PID_FAILURE';
export const FLOW_CHANGE_PID_REQUEST = 'FLOW_CHANGE_PID_REQUEST';
export const FLOW_CHANGE_PID_SUCCESS = 'FLOW_CHANGE_PID_SUCCESS';

export const FLOW_REARRANGE_PIDS_FAILURE = 'FLOW_REARRANGE_PIDS_FAILURE';
export const FLOW_REARRANGE_PIDS_REQUEST = 'FLOW_REARRANGE_PIDS_REQUEST';
export const FLOW_REARRANGE_PIDS_SUCCESS = 'FLOW_REARRANGE_PIDS_SUCCESS';

export const FLOW_CREATE_PROJECT_FOLDER_FAILURE = 'FLOW_CREATE_PROJECT_FOLDER_FAILURE';
export const FLOW_CREATE_PROJECT_FOLDER_REQUEST = 'FLOW_CREATE_PROJECT_FOLDER_REQUEST';
export const FLOW_CREATE_PROJECT_FOLDER_SUCCESS = 'FLOW_CREATE_PROJECT_FOLDER_SUCCESS';

export const FLOW_CHANGE_PROJECT_FOLDER_FAILURE = 'FLOW_CHANGE_PROJECT_FOLDER_FAILURE';
export const FLOW_CHANGE_PROJECT_FOLDER_REQUEST = 'FLOW_CHANGE_PROJECT_FOLDER_REQUEST';
export const FLOW_CHANGE_PROJECT_FOLDER_SUCCESS = 'FLOW_CHANGE_PROJECT_FOLDER_SUCCESS';

export const FLOW_REMOVE_PROJECT_FOLDER_FAILURE = 'FLOW_REMOVE_PROJECT_FOLDER_FAILURE';
export const FLOW_REMOVE_PROJECT_FOLDER_REQUEST = 'FLOW_REMOVE_PROJECT_FOLDER_REQUEST';
export const FLOW_REMOVE_PROJECT_FOLDER_SUCCESS = 'FLOW_REMOVE_PROJECT_FOLDER_SUCCESS';

export const FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_FAILURE = 'FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_FAILURE';
export const FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_REQUEST = 'FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_REQUEST';
export const FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_SUCCESS = 'FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_SUCCESS';

export const FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_FAILURE = 'FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_FAILURE';
export const FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_REQUEST = 'FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_REQUEST';
export const FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_SUCCESS = 'FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_SUCCESS';

export const FLOW_REMOVE_MATERIAL_RESOURCE_FOLDER_FAILURE = 'FLOW_REMOVE_MATERIAL_RESOURCE_FOLDER_FAILURE';
export const FLOW_REMOVE_MATERIAL_RESOURCE_FOLDER_REQUEST = 'FLOW_REMOVE_MATERIAL_RESOURCE_FOLDER_REQUEST';
export const FLOW_REMOVE_MATERIAL_RESOURCE_FOLDER_SUCCESS = 'FLOW_REMOVE_MATERIAL_RESOURCE_FOLDER_SUCCESS';

export const FLOW_CREATE_MATERIAL_RESOURCE_FAILURE = 'FLOW_CREATE_MATERIAL_RESOURCE_FAILURE';
export const FLOW_CREATE_MATERIAL_RESOURCE_REQUEST = 'FLOW_CREATE_MATERIAL_RESOURCE_REQUEST';
export const FLOW_CREATE_MATERIAL_RESOURCE_SUCCESS = 'FLOW_CREATE_MATERIAL_RESOURCE_SUCCESS';

export const FLOW_CHANGE_MATERIAL_RESOURCE_FAILURE = 'FLOW_CHANGE_MATERIAL_RESOURCE_FAILURE';
export const FLOW_CHANGE_MATERIAL_RESOURCE_REQUEST = 'FLOW_CHANGE_MATERIAL_RESOURCE_REQUEST';
export const FLOW_CHANGE_MATERIAL_RESOURCE_SUCCESS = 'FLOW_CHANGE_MATERIAL_RESOURCE_SUCCESS';

export const FLOW_REMOVE_MATERIAL_RESOURCE_FAILURE = 'FLOW_REMOVE_MATERIAL_RESOURCE_FAILURE';
export const FLOW_REMOVE_MATERIAL_RESOURCE_REQUEST = 'FLOW_REMOVE_MATERIAL_RESOURCE_REQUEST';
export const FLOW_REMOVE_MATERIAL_RESOURCE_SUCCESS = 'FLOW_REMOVE_MATERIAL_RESOURCE_SUCCESS';

export const FLOW_PATCH_SETTINGS_FAILURE = 'FLOW_PATCH_SETTINGS_FAILURE';
export const FLOW_PATCH_SETTINGS_REQUEST = 'FLOW_PATCH_SETTINGS_REQUEST';
export const FLOW_PATCH_SETTINGS_SUCCESS = 'FLOW_PATCH_SETTINGS_SUCCESS';

export const FLOW_GET_LABEL_FAILURE = 'FLOW_GET_LABEL_FAILURE';
export const FLOW_GET_LABEL_REQUEST = 'FLOW_GET_LABEL_REQUEST';
export const FLOW_GET_LABEL_SUCCESS = 'FLOW_GET_LABEL_SUCCESS';

export const FLOW_CREATE_CUSTOMER_FAILURE = 'FLOW_CREATE_CUSTOMER_FAILURE';
export const FLOW_CREATE_CUSTOMER_REQUEST = 'FLOW_CREATE_CUSTOMER_REQUEST';
export const FLOW_CREATE_CUSTOMER_SUCCESS = 'FLOW_CREATE_CUSTOMER_SUCCESS';

export const FLOW_PATCH_CUSTOMER_FAILURE = 'FLOW_PATCH_CUSTOMER_FAILURE';
export const FLOW_PATCH_CUSTOMER_REQUEST = 'FLOW_PATCH_CUSTOMER_REQUEST';
export const FLOW_PATCH_CUSTOMER_SUCCESS = 'FLOW_PATCH_CUSTOMER_SUCCESS';

export const FLOW_REMOVE_CUSTOMER_FAILURE = 'FLOW_REMOVE_CUSTOMER_FAILURE';
export const FLOW_REMOVE_CUSTOMER_REQUEST = 'FLOW_REMOVE_CUSTOMER_REQUEST';
export const FLOW_REMOVE_CUSTOMER_SUCCESS = 'FLOW_REMOVE_CUSTOMER_SUCCESS';

export const FLOW_CUSTOMER_PROVIDER_SEARCH_FAILURE = 'FLOW_CUSTOMER_PROVIDER_SEARCH_FAILURE';
export const FLOW_CUSTOMER_PROVIDER_SEARCH_REQUEST = 'FLOW_CUSTOMER_PROVIDER_SEARCH_REQUEST';
export const FLOW_CUSTOMER_PROVIDER_SEARCH_SUCCESS = 'FLOW_CUSTOMER_PROVIDER_SEARCH_SUCCESS';

export const FLOW_CREATE_PRICE_CATEGORY_FAILURE = 'FLOW_CREATE_PRICE_CATEGORY_FAILURE';
export const FLOW_CREATE_PRICE_CATEGORY_REQUEST = 'FLOW_CREATE_PRICE_CATEGORY_REQUEST';
export const FLOW_CREATE_PRICE_CATEGORY_SUCCESS = 'FLOW_CREATE_PRICE_CATEGORY_SUCCESS';

export const FLOW_PATCH_PRICE_CATEGORY_FAILURE = 'FLOW_PATCH_PRICE_CATEGORY_FAILURE';
export const FLOW_PATCH_PRICE_CATEGORY_REQUEST = 'FLOW_PATCH_PRICE_CATEGORY_REQUEST';
export const FLOW_PATCH_PRICE_CATEGORY_SUCCESS = 'FLOW_PATCH_PRICE_CATEGORY_SUCCESS';

export const FLOW_REMOVE_PRICE_CATEGORY_FAILURE = 'FLOW_REMOVE_PRICE_CATEGORY_FAILURE';
export const FLOW_REMOVE_PRICE_CATEGORY_REQUEST = 'FLOW_REMOVE_PRICE_CATEGORY_REQUEST';
export const FLOW_REMOVE_PRICE_CATEGORY_SUCCESS = 'FLOW_REMOVE_PRICE_CATEGORY_SUCCESS';

export const FLOW_CREATE_PRICE_SURCHARGE_FAILURE = 'FLOW_CREATE_PRICE_SURCHARGE_FAILURE';
export const FLOW_CREATE_PRICE_SURCHARGE_REQUEST = 'FLOW_CREATE_PRICE_SURCHARGE_REQUEST';
export const FLOW_CREATE_PRICE_SURCHARGE_SUCCESS = 'FLOW_CREATE_PRICE_SURCHARGE_SUCCESS';

export const FLOW_PATCH_PRICE_SURCHARGE_FAILURE = 'FLOW_PATCH_PRICE_SURCHARGE_FAILURE';
export const FLOW_PATCH_PRICE_SURCHARGE_REQUEST = 'FLOW_PATCH_PRICE_SURCHARGE_REQUEST';
export const FLOW_PATCH_PRICE_SURCHARGE_SUCCESS = 'FLOW_PATCH_PRICE_SURCHARGE_SUCCESS';

export const FLOW_REMOVE_PRICE_SURCHARGE_FAILURE = 'FLOW_REMOVE_PRICE_SURCHARGE_FAILURE';
export const FLOW_REMOVE_PRICE_SURCHARGE_REQUEST = 'FLOW_REMOVE_PRICE_SURCHARGE_REQUEST';
export const FLOW_REMOVE_PRICE_SURCHARGE_SUCCESS = 'FLOW_REMOVE_PRICE_SURCHARGE_SUCCESS';

export const FLOW_CREATE_LABEL_FAILURE = 'FLOW_CREATE_LABEL_FAILURE';
export const FLOW_CREATE_LABEL_REQUEST = 'FLOW_CREATE_LABEL_REQUEST';
export const FLOW_CREATE_LABEL_SUCCESS = 'FLOW_CREATE_LABEL_SUCCESS';

export const FLOW_PATCH_LABEL_FAILURE = 'FLOW_PATCH_LABEL_FAILURE';
export const FLOW_PATCH_LABEL_REQUEST = 'FLOW_PATCH_LABEL_REQUEST';
export const FLOW_PATCH_LABEL_SUCCESS = 'FLOW_PATCH_LABEL_SUCCESS';

export const FLOW_REMOVE_LABEL_FAILURE = 'FLOW_REMOVE_LABEL_FAILURE';
export const FLOW_REMOVE_LABEL_REQUEST = 'FLOW_REMOVE_LABEL_REQUEST';
export const FLOW_REMOVE_LABEL_SUCCESS = 'FLOW_REMOVE_LABEL_SUCCESS';

export const FLOW_CREATE_SUB_WORK_PAGKAGE_FAILURE = 'FLOW_CREATE_SUB_WORK_PAGKAGE_FAILURE';
export const FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST = 'FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST';
export const FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS = 'FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS';

export const FLOW_PATCH_SUB_WORK_PACKAGE_FAILURE = 'FLOW_PATCH_SUB_WORK_PACKAGE_FAILURE';
export const FLOW_PATCH_SUB_WORK_PACKAGE_REQUEST = 'FLOW_PATCH_SUB_WORK_PACKAGE_REQUEST';
export const FLOW_PATCH_SUB_WORK_PACKAGE_SUCCESS = 'FLOW_PATCH_SUB_WORK_PACKAGE_SUCCESS';

export const FLOW_REMOVE_SUB_WORK_PACKAGE_FAILURE = 'FLOW_REMOVE_SUB_WORK_PACKAGE_FAILURE';
export const FLOW_REMOVE_SUB_WORK_PACKAGE_REQUEST = 'FLOW_REMOVE_SUB_WORK_PACKAGE_REQUEST';
export const FLOW_REMOVE_SUB_WORK_PACKAGE_SUCCESS = 'FLOW_REMOVE_SUB_WORK_PACKAGE_SUCCESS';

export const FLOW_CREATE_TASK_FAILURE = 'FLOW_CREATE_TASK_FAILURE';
export const FLOW_CREATE_TASK_REQUEST = 'FLOW_CREATE_TASK_REQUEST';
export const FLOW_CREATE_TASK_SUCCESS = 'FLOW_CREATE_TASK_SUCCESS';

export const FLOW_CHANGE_TASK_FAILURE = 'FLOW_CHANGE_TASK_FAILURE';
export const FLOW_CHANGE_TASK_REQUEST = 'FLOW_CHANGE_TASK_REQUEST';
export const FLOW_CHANGE_TASK_SUCCESS = 'FLOW_CHANGE_TASK_SUCCESS';

export const FLOW_MOVE_TASKS_FAILURE = 'FLOW_MOVE_TASKS_FAILURE';
export const FLOW_MOVE_TASKS_REQUEST = 'FLOW_MOVE_TASKS_REQUEST';
export const FLOW_MOVE_TASKS_SUCCESS = 'FLOW_MOVE_TASKS_SUCCESS';

export const FLOW_REMOVE_TASK_FAILURE = 'FLOW_REMOVE_TASK_FAILURE';
export const FLOW_REMOVE_TASK_REQUEST = 'FLOW_REMOVE_TASK_REQUEST';
export const FLOW_REMOVE_TASK_SUCCESS = 'FLOW_REMOVE_TASK_SUCCESS';

export const FLOW_COPY_TASKS_FAILURE = 'FLOW_COPY_TASKS_FAILURE';
export const FLOW_COPY_TASKS_REQUEST = 'FLOW_COPY_TASKS_REQUEST';
export const FLOW_COPY_TASKS_SUCCESS = 'FLOW_COPY_TASKS_SUCCESS';

export const FLOW_MOVE_TASKS_BETWEEN_WPS_FAILURE = 'FLOW_MOVE_TASKS_BETWEEN_WPS_FAILURE';
export const FLOW_MOVE_TASKS_BETWEEN_WPS_REQUEST = 'FLOW_MOVE_TASKS_BETWEEN_WPS_REQUEST';
export const FLOW_MOVE_TASKS_BETWEEN_WPS_SUCCESS = 'FLOW_MOVE_TASKS_BETWEEN_WPS_SUCCESS';

export const FLOW_PATCH_TASK_SECTION_FAILURE = 'FLOW_PATCH_TASK_SECTION_FAILURE';
export const FLOW_PATCH_TASK_SECTION_REQUEST = 'FLOW_PATCH_TASK_SECTION_REQUEST';
export const FLOW_PATCH_TASK_SECTION_SUCCESS = 'FLOW_PATCH_TASK_SECTION_SUCCESS';

export const FLOW_CREATE_TASK_SECTION_FAILURE = 'FLOW_CREATE_TASK_SECTION_FAILURE';
export const FLOW_CREATE_TASK_SECTION_REQUEST = 'FLOW_CREATE_TASK_SECTION_REQUEST';
export const FLOW_CREATE_TASK_SECTION_SUCCESS = 'FLOW_CREATE_TASK_SECTION_SUCCESS';

export const FLOW_MOVE_TASK_SECTIONS_FAILURE = 'FLOW_MOVE_TASK_SECTIONS_FAILURE';
export const FLOW_MOVE_TASK_SECTIONS_REQUEST = 'FLOW_MOVE_TASK_SECTIONS_REQUEST';
export const FLOW_MOVE_TASK_SECTIONS_SUCCESS = 'FLOW_MOVE_TASK_SECTIONS_SUCCESS';

export const FLOW_REMOVE_TASK_SECTION_FAILURE = 'FLOW_REMOVE_TASK_SECTION_FAILURE';
export const FLOW_REMOVE_TASK_SECTION_REQUEST = 'FLOW_REMOVE_TASK_SECTION_REQUEST';
export const FLOW_REMOVE_TASK_SECTION_SUCCESS = 'FLOW_REMOVE_TASK_SECTION_SUCCESS';

export const FLOW_COPY_PID_FAILURE = 'FLOW_COPY_PID_FAILURE';
export const FLOW_COPY_PID_REQUEST = 'FLOW_COPY_PID_REQUEST';
export const FLOW_COPY_PID_SUCCESS = 'FLOW_COPY_PID_SUCCESS';

export const FLOW_CREATE_PROJECT_FROM_TEMPLATE_FAILURE = 'FLOW_CREATE_PROJECT_FROM_TEMPLATE_FAILURE';
export const FLOW_CREATE_PROJECT_FROM_TEMPLATE_REQUEST = 'FLOW_CREATE_PROJECT_FROM_TEMPLATE_REQUEST';
export const FLOW_CREATE_PROJECT_FROM_TEMPLATE_SUCCESS = 'FLOW_CREATE_PROJECT_FROM_TEMPLATE_SUCCESS';

export const FLOW_REMOVE_NODES_FAILURE = 'FLOW_REMOVE_NODES_FAILURE';
export const FLOW_REMOVE_NODES_REQUEST = 'FLOW_REMOVE_NODES_REQUEST';
export const FLOW_REMOVE_NODES_SUCCESS = 'FLOW_REMOVE_NODES_SUCCESS';

export const FLOW_INIT_LOAD_REQUEST = 'FLOW_INIT_LOAD_REQUEST';
export const FLOW_INIT_LOAD_SUCCESS = 'FLOW_INIT_LOAD_SUCCESS';
export const FLOW_INIT_INVALIDATED = 'FLOW_INIT_INVALIDATED';

export const FLOW_LOAD_TIME_RECORDS_START = 'FLOW_LOAD_TIME_RECORDS_START';
export const FLOW_LOAD_TIME_RECORDS_FAILURE = 'FLOW_LOAD_TIME_RECORDS_FAILURE';
export const FLOW_LOAD_TIME_RECORDS_REQUEST = 'FLOW_LOAD_TIME_RECORDS_REQUEST';
export const FLOW_LOAD_TIME_RECORDS_SUCCESS = 'FLOW_LOAD_TIME_RECORDS_SUCCESS';

export const FLOW_REMOVE_TIME_RECORDS_FAILURE = 'FLOW_REMOVE_TIME_RECORDS_FAILURE';
export const FLOW_REMOVE_TIME_RECORDS_REQUEST = 'FLOW_REMOVE_TIME_RECORDS_REQUEST';
export const FLOW_REMOVE_TIME_RECORDS_SUCCESS = 'FLOW_REMOVE_TIME_RECORDS_SUCCESS';

export const FLOW_CREATE_TIME_RECORD_FAILURE = 'FLOW_CREATE_TIME_RECORD_FAILURE';
export const FLOW_CREATE_TIME_RECORD_REQUEST = 'FLOW_CREATE_TIME_RECORD_REQUEST';
export const FLOW_CREATE_TIME_RECORD_SUCCESS = 'FLOW_CREATE_TIME_RECORD_SUCCESS';

export const FLOW_SEARCH_NODE_REQUEST = 'FLOW_SEARCH_NODE_REQUEST';
export const FLOW_SEARCH_NODE_SUCCESS = 'FLOW_SEARCH_NODE_SUCCESS';

export const FLOW_SEARCH_TIME_RECORD_REQUEST = 'FLOW_SEARCH_TIME_RECORD_REQUEST';
export const FLOW_SEARCH_TIME_RECORD_SUCCESS = 'FLOW_SEARCH_TIME_RECORD_SUCCESS';

export const FLOW_PATCH_TIME_RECORD_FAILURE = 'FLOW_PATCH_TIME_RECORD_FAILURE';
export const FLOW_PATCH_TIME_RECORD_REQUEST = 'FLOW_PATCH_TIME_RECORD_REQUEST';
export const FLOW_PATCH_TIME_RECORD_SUCCESS = 'FLOW_PATCH_TIME_RECORD_SUCCESS';

export const FLOW_PATCH_USER_SETTINGS_FAILURE = 'FLOW_PATCH_USER_SETTINGS_FAILURE';
export const FLOW_PATCH_USER_SETTINGS_REQUEST = 'FLOW_PATCH_USER_SETTINGS_REQUEST';
export const FLOW_PATCH_USER_SETTINGS_SUCCESS = 'FLOW_PATCH_USER_SETTINGS_SUCCESS';

export const FLOW_SUBSCRIBE_TO_NODE_FAILURE = 'FLOW_SUBSCRIBE_TO_NODE_FAILURE';
export const FLOW_SUBSCRIBE_TO_NODE_REQUEST = 'FLOW_SUBSCRIBE_TO_NODE_REQUEST';
export const FLOW_SUBSCRIBE_TO_NODE_SUCCESS = 'FLOW_SUBSCRIBE_TO_NODE_SUCCESS';

export const FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE = 'FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE';
export const FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST = 'FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST';
export const FLOW_UNSUBSCRIBE_FROM_NODE_SUCCESS = 'FLOW_UNSUBSCRIBE_FROM_NODE_SUCCESS';

export const FLOW_BULK_UNSUBSCRIBE_FROM_WPS_FAILURE = 'FLOW_BULK_UNSUBSCRIBE_FROM_WPS_FAILURE';
export const FLOW_BULK_UNSUBSCRIBE_FROM_WPS_REQUEST = 'FLOW_BULK_UNSUBSCRIBE_FROM_WPS_REQUEST';
export const FLOW_BULK_UNSUBSCRIBE_FROM_WPS_SUCCESS = 'FLOW_BULK_UNSUBSCRIBE_FROM_WPS_SUCCESS';

export const FLOW_LOAD_ROLES_FAILURE = 'FLOW_LOAD_ROLES_FAILURE';
export const FLOW_LOAD_ROLES_REQUEST = 'FLOW_LOAD_ROLES_REQUEST';
export const FLOW_LOAD_ROLES_SUCCESS = 'FLOW_LOAD_ROLES_SUCCESS';

export const FLOW_CREATE_ROLE_FAILURE = 'FLOW_CREATE_ROLE_FAILURE';
export const FLOW_CREATE_ROLE_REQUEST = 'FLOW_CREATE_ROLE_REQUEST';
export const FLOW_CREATE_ROLE_SUCCESS = 'FLOW_CREATE_ROLE_SUCCESS';

export const FLOW_PATCH_ROLE_FAILURE = 'FLOW_PATCH_ROLE_FAILURE';
export const FLOW_PATCH_ROLE_REQUEST = 'FLOW_PATCH_ROLE_REQUEST';
export const FLOW_PATCH_ROLE_SUCCESS = 'FLOW_PATCH_ROLE_SUCCESS';

export const FLOW_RESORT_ROLES_FAILURE = 'FLOW_RESORT_ROLES_FAILURE';
export const FLOW_RESORT_ROLES_REQUEST = 'FLOW_RESORT_ROLES_REQUEST';
export const FLOW_RESORT_ROLES_SUCCESS = 'FLOW_RESORT_ROLES_SUCCESS';

export const FLOW_REMOVE_ROLE_FAILURE = 'FLOW_REMOVE_ROLE_FAILURE';
export const FLOW_REMOVE_ROLE_REQUEST = 'FLOW_REMOVE_ROLE_REQUEST';
export const FLOW_REMOVE_ROLE_SUCCESS = 'FLOW_REMOVE_ROLE_SUCCESS';

export const FLOW_PATCH_NODES_RESPONSIBILITIES_FAILURE = 'FLOW_PATCH_NODES_RESPONSIBILITIES_FAILURE';
export const FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST = 'FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST';
export const FLOW_PATCH_NODES_RESPONSIBILITIES_SUCCESS = 'FLOW_PATCH_NODES_RESPONSIBILITIES_SUCCESS';

export const FLOW_LOAD_GUEST_ROLE_RIGHTS_FAILURE = 'FLOW_LOAD_GUEST_ROLE_RIGHTS_FAILURE';
export const FLOW_LOAD_GUEST_ROLE_RIGHTS_REQUEST = 'FLOW_LOAD_GUEST_ROLE_RIGHTS_REQUEST';
export const FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS = 'FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS';

export const FLOW_PATCH_GUEST_ROLE_RIGHTS_FAILURE = 'FLOW_PATCH_GUEST_ROLE_RIGHTS_FAILURE';
export const FLOW_PATCH_GUEST_ROLE_RIGHTS_REQUEST = 'FLOW_PATCH_GUEST_ROLE_RIGHTS_REQUEST';
export const FLOW_PATCH_GUEST_ROLE_RIGHTS_SUCCESS = 'FLOW_PATCH_GUEST_ROLE_RIGHTS_SUCCESS';

export const FLOW_LOAD_INTERNAL_ROLE_RIGHTS_FAILURE = 'FLOW_LOAD_INTERNAL_ROLE_RIGHTS_FAILURE';
export const FLOW_LOAD_INTERNAL_ROLE_RIGHTS_REQUEST = 'FLOW_LOAD_INTERNAL_ROLE_RIGHTS_REQUEST';
export const FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS = 'FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS';

export const FLOW_PATCH_INTERNAL_ROLE_RIGHTS_FAILURE = 'FLOW_PATCH_INTERNAL_ROLE_RIGHTS_FAILURE';
export const FLOW_PATCH_INTERNAL_ROLE_RIGHTS_REQUEST = 'FLOW_PATCH_INTERNAL_ROLE_RIGHTS_REQUEST';
export const FLOW_PATCH_INTERNAL_ROLE_RIGHTS_SUCCESS = 'FLOW_PATCH_INTERNAL_ROLE_RIGHTS_SUCCESS';

export const FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_FAILURE = 'FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_FAILURE';
export const FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_REQUEST = 'FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_REQUEST';
export const FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS = 'FLOW_LOAD_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS';

export const FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_FAILURE = 'FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_FAILURE';
export const FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_REQUEST = 'FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_REQUEST';
export const FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS = 'FLOW_PATCH_GLOBAL_ROLE_ASSIGNMENTS_SUCCESS';

export const FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_FAILURE = 'FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_FAILURE';
export const FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_REQUEST = 'FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_REQUEST';
export const FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_SUCCESS = 'FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_SUCCESS';

export const FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_FAILURE = 'FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_FAILURE';
export const FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_REQUEST = 'FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_REQUEST';
export const FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_SUCCESS = 'FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_SUCCESS';

export const FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_FAILURE = 'FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_FAILURE';
export const FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_REQUEST = 'FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_REQUEST';
export const FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_SUCCESS = 'FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_SUCCESS';
export const FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS = 'FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS';

export const FLOW_LOAD_NODE_GUEST_PERMISSION_ROLE_ASSIGNMENTS_FAILURE =
  'FLOW_LOAD_NODE_GUEST_PERMISSION_ROLE_ASSIGNMENTS_FAILURE';
export const FLOW_LOAD_NODE_GUEST_PERMISSION_ROLE_ASSIGNMENTS_REQUEST =
  'FLOW_LOAD_NODE_GUEST_PERMISSION_ROLE_ASSIGNMENTS_REQUEST';
export const FLOW_LOAD_NODE_GUEST_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS =
  'FLOW_LOAD_NODE_GUEST_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS';

export const FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_FAILURE =
  'FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_FAILURE';
export const FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_REQUEST =
  'FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_REQUEST';
export const FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS =
  'FLOW_LOAD_NODE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS_SUCCESS';

export const FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS = 'FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS';

export const FLOW_SET_NODE_PROJECT_ROLE_FAILURE = 'FLOW_SET_NODE_PROJECT_ROLE_FAILURE';
export const FLOW_SET_NODE_PROJECT_ROLE_REQUEST = 'FLOW_SET_NODE_PROJECT_ROLE_REQUEST';
export const FLOW_SET_NODE_PROJECT_ROLE_SUCCESS = 'FLOW_SET_NODE_PROJECT_ROLE_SUCCESS';

export const FLOW_REMOVE_NODE_PROJECT_ROLE_FAILURE = 'FLOW_REMOVE_NODE_PROJECT_ROLE_FAILURE';
export const FLOW_REMOVE_NODE_PROJECT_ROLE_REQUEST = 'FLOW_REMOVE_NODE_PROJECT_ROLE_REQUEST';
export const FLOW_REMOVE_NODE_PROJECT_ROLE_SUCCESS = 'FLOW_REMOVE_NODE_PROJECT_ROLE_SUCCESS';

export const FLOW_EDIT_NODE_ROLE_FAILURE = 'FLOW_EDIT_NODE_ROLE_FAILURE';
export const FLOW_EDIT_NODE_ROLE_REQUEST = 'FLOW_EDIT_NODE_ROLE_REQUEST';
export const FLOW_EDIT_NODE_ROLE_SUCCESS = 'FLOW_EDIT_NODE_ROLE_SUCCESS';

export const FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_FAILURE = 'FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_FAILURE';
export const FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_REQUEST = 'FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_REQUEST';
export const FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_SUCCESS = 'FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_SUCCESS';

export const FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_FAILURE =
  'FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_FAILURE';
export const FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_REQUEST =
  'FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_REQUEST';
export const FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_SUCCESS =
  'FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_SUCCESS';

export const FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_FAILURE =
  'FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_FAILURE';
export const FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_REQUEST =
  'FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_REQUEST';
export const FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_SUCCESS =
  'FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_SUCCESS';

export const FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_FAILURE = 'FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_FAILURE';
export const FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_REQUEST = 'FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_REQUEST';
export const FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_SUCCESS = 'FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_SUCCESS';

export const FLOW_LOAD_BOOKED_HOURS_STATISTIC_FAILURE = 'FLOW_LOAD_BOOKED_HOURS_STATISTIC_FAILURE';
export const FLOW_LOAD_BOOKED_HOURS_STATISTIC_REQUEST = 'FLOW_LOAD_BOOKED_HOURS_STATISTIC_REQUEST';
export const FLOW_LOAD_BOOKED_HOURS_STATISTIC_SUCCESS = 'FLOW_LOAD_BOOKED_HOURS_STATISTIC_SUCCESS';

export const FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_FAILURE = 'FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_FAILURE';
export const FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_REQUEST = 'FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_REQUEST';
export const FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_SUCCESS = 'FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_SUCCESS';

export const FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_FAILURE = 'FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_FAILURE';
export const FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_REQUEST = 'FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_REQUEST';
export const FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_SUCCESS = 'FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_SUCCESS';

export const FLOW_LOAD_SUBTREE_STATS_FAILURE = 'FLOW_LOAD_SUBTREE_STATS_FAILURE';
export const FLOW_LOAD_SUBTREE_STATS_REQUEST = 'FLOW_LOAD_SUBTREE_STATS_REQUEST';
export const FLOW_LOAD_SUBTREE_STATS_SUCCESS = 'FLOW_LOAD_SUBTREE_STATS_SUCCESS';

export const FLOW_LOAD_BILLING_START = 'FLOW_LOAD_BILLING_START';
export const FLOW_LOAD_BILLING_SUCCESS = 'FLOW_LOAD_BILLING_SUCCESS';

export const FLOW_SEARCH_BILLING_START = 'FLOW_SEARCH_BILLING_START';
export const FLOW_SEARCH_BILLING_SUCCESS = 'FLOW_SEARCH_BILLING_SUCCESS';

export const FLOW_CREATE_BILLING_FAILURE = 'FLOW_CREATE_BILLING_FAILURE';
export const FLOW_CREATE_BILLING_REQUEST = 'FLOW_CREATE_BILLING_REQUEST';
export const FLOW_CREATE_BILLING_SUCCESS = 'FLOW_CREATE_BILLING_SUCCESS';

export const FLOW_PATCH_BILLING_FAILURE = 'FLOW_PATCH_BILLING_FAILURE';
export const FLOW_PATCH_BILLING_REQUEST = 'FLOW_PATCH_BILLING_REQUEST';
export const FLOW_PATCH_BILLING_SUCCESS = 'FLOW_PATCH_BILLING_SUCCESS';

export const FLOW_DELETE_BILLING_FAILURE = 'FLOW_DELETE_BILLING_FAILURE';
export const FLOW_DELETE_BILLING_REQUEST = 'FLOW_DELETE_BILLING_REQUEST';
export const FLOW_DELETE_BILLING_SUCCESS = 'FLOW_DELETE_BILLING_SUCCESS';

export const FLOW_CREATE_KANBAN_BOARD_FAILURE = 'FLOW_CREATE_KANBAN_BOARD_FAILURE';
export const FLOW_CREATE_KANBAN_BOARD_REQUEST = 'FLOW_CREATE_KANBAN_BOARD_REQUEST';
export const FLOW_CREATE_KANBAN_BOARD_SUCCESS = 'FLOW_CREATE_KANBAN_BOARD_SUCCESS';

export const FLOW_PATCH_KANBAN_BOARD_FAILURE = 'FLOW_PATCH_KANBAN_BOARD_FAILURE';
export const FLOW_PATCH_KANBAN_BOARD_REQUEST = 'FLOW_PATCH_KANBAN_BOARD_REQUEST';
export const FLOW_PATCH_KANBAN_BOARD_SUCCESS = 'FLOW_PATCH_KANBAN_BOARD_SUCCESS';

export const FLOW_REMOVE_KANBAN_BOARD_FAILURE = 'FLOW_REMOVE_KANBAN_BOARD_FAILURE';
export const FLOW_REMOVE_KANBAN_BOARD_REQUEST = 'FLOW_REMOVE_KANBAN_BOARD_REQUEST';
export const FLOW_REMOVE_KANBAN_BOARD_SUCCESS = 'FLOW_REMOVE_KANBAN_BOARD_SUCCESS';

export const FLOW_GET_KANBAN_BOARD_FAILURE = 'FLOW_GET_KANBAN_BOARD_FAILURE';
export const FLOW_GET_KANBAN_BOARD_REQUEST = 'FLOW_GET_KANBAN_BOARD_REQUEST';
export const FLOW_GET_KANBAN_BOARD_SUCCESS = 'FLOW_GET_KANBAN_BOARD_SUCCESS';

export const FLOW_GET_TASK_STATUS_HISTORY_FAILURE = 'FLOW_GET_TASK_STATUS_HISTORY_FAILURE';
export const FLOW_GET_TASK_STATUS_HISTORY_REQUEST = 'FLOW_GET_TASK_STATUS_HISTORY_REQUEST';
export const FLOW_GET_TASK_STATUS_HISTORY_SUCCESS = 'FLOW_GET_TASK_STATUS_HISTORY_SUCCESS';
export const FLOW_GET_TASK_STATUS_HISTORY_START = 'FLOW_GET_TASK_STATUS_HISTORY_START';

export const FLOW_GET_AUDIT_FAILURE = 'FLOW_GET_AUDIT_FAILURE';
export const FLOW_GET_AUDIT_REQUEST = 'FLOW_GET_AUDIT_REQUEST';
export const FLOW_GET_AUDIT_SUCCESS = 'FLOW_GET_AUDIT_SUCCESS';
export const FLOW_GET_AUDIT_RELOAD = 'FLOW_GET_AUDIT_RELOAD';

export const FLOW_LOAD_WORK_PACKAGE_BILLING_START = 'FLOW_LOAD_WORK_PACKAGE_BILLING_START';
export const FLOW_LOAD_WORK_PACKAGE_BILLING_FAILURE = 'FLOW_LOAD_WORK_PACKAGE_BILLING_FAILURE';
export const FLOW_LOAD_WORK_PACKAGE_BILLING_REQUEST = 'FLOW_LOAD_WORK_PACKAGE_BILLING_REQUEST';
export const FLOW_LOAD_WORK_PACKAGE_BILLING_SUCCESS = 'FLOW_LOAD_WORK_PACKAGE_BILLING_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_FAILURE =
  'FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_FAILURE';
export const FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_REQUEST =
  'FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_REQUEST';
export const FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_SUCCESS';

export const FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_FAILURE = 'FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_FAILURE';
export const FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_REQUEST = 'FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_REQUEST';
export const FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_SUCCESS = 'FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_FAILURE =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_FAILURE';
export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_REQUEST =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_REQUEST';
export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_FAILURE =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_FAILURE';
export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_REQUEST =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_REQUEST';
export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_FAILURE =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_FAILURE';
export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_REQUEST =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_REQUEST';
export const FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_FAILURE =
  'FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_FAILURE';
export const FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_REQUEST =
  'FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_REQUEST';
export const FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_START =
  'FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_START';
export const FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_START =
  'FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_START';
export const FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_SUCCESS';

export const FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_START =
  'FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_START';
export const FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_SUCCESS =
  'FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_SUCCESS';

export const FLOW_LOAD_TRACKED_TIME_SUMS_START = 'FLOW_LOAD_TRACKED_TIME_SUMS_START';
export const FLOW_LOAD_TRACKED_TIME_SUMS_SUCCESS = 'FLOW_LOAD_TRACKED_TIME_SUMS_SUCCESS';

export const FLOW_LOAD_TASK_SUMS_PER_WP_START = 'FLOW_LOAD_TASK_SUMS_PER_WP_START';
export const FLOW_LOAD_TASK_SUMS_PER_WP_SUCCESS = 'FLOW_LOAD_TASK_SUMS_PER_WP_SUCCESS';

export const FLOW_LOAD_DEPRECATED_USER_IDS_FAILURE = 'FLOW_LOAD_DEPRECATED_USER_IDS_FAILURE';
export const FLOW_LOAD_DEPRECATED_USER_IDS_REQUEST = 'FLOW_LOAD_DEPRECATED_USER_IDS_REQUEST';
export const FLOW_LOAD_DEPRECATED_USER_IDS_SUCCESS = 'FLOW_LOAD_DEPRECATED_USER_IDS_SUCCESS';

export const FLOW_BUMP_WP_MANUAL_TT_END_DATE_FAILURE = 'FLOW_BUMP_WP_MANUAL_TT_END_DATE_FAILURE';
export const FLOW_BUMP_WP_MANUAL_TT_END_DATE_REQUEST = 'FLOW_BUMP_WP_MANUAL_TT_END_DATE_REQUEST';
export const FLOW_BUMP_WP_MANUAL_TT_END_DATE_SUCCESS = 'FLOW_BUMP_WP_MANUAL_TT_END_DATE_SUCCESS';

export const FLOW_TODAY_CHANGED = 'FLOW_TODAY_CHANGED';

export const FLOW_LOAD_WORK_TIMES_START = 'FLOW_LOAD_WORK_TIMES_START';
export const FLOW_LOAD_WORK_TIMES_SUCCESS = 'FLOW_LOAD_WORK_TIMES_SUCCESS';

export const FLOW_SAVE_CUSTOM_WORK_TIME_FAILURE = 'FLOW_SAVE_CUSTOM_WORK_TIME_FAILURE';
export const FLOW_SAVE_CUSTOM_WORK_TIME_REQUEST = 'FLOW_SAVE_CUSTOM_WORK_TIME_REQUEST';
export const FLOW_SAVE_CUSTOM_WORK_TIME_SUCCESS = 'FLOW_SAVE_CUSTOM_WORK_TIME_SUCCESS';

export const FLOW_SAVE_VACATION_ENTITLEMENT_FAILURE = 'FLOW_SAVE_VACATION_ENTITLEMENT_FAILURE';
export const FLOW_SAVE_VACATION_ENTITLEMENT_REQUEST = 'FLOW_SAVE_VACATION_ENTITLEMENT_REQUEST';
export const FLOW_SAVE_VACATION_ENTITLEMENT_SUCCESS = 'FLOW_SAVE_VACATION_ENTITLEMENT_SUCCESS';

export const FLOW_SAVE_VACATION_CARRYOVER_FAILURE = 'FLOW_SAVE_VACATION_CARRYOVER_FAILURE';
export const FLOW_SAVE_VACATION_CARRYOVER_REQUEST = 'FLOW_SAVE_VACATION_CARRYOVER_REQUEST';
export const FLOW_SAVE_VACATION_CARRYOVER_SUCCESS = 'FLOW_SAVE_VACATION_CARRYOVER_SUCCESS';

export const FLOW_DELETE_CUSTOM_WORK_TIME_FAILURE = 'FLOW_DELETE_CUSTOM_WORK_TIME_FAILURE';
export const FLOW_DELETE_CUSTOM_WORK_TIME_REQUEST = 'FLOW_DELETE_CUSTOM_WORK_TIME_REQUEST';
export const FLOW_DELETE_CUSTOM_WORK_TIME_SUCCESS = 'FLOW_DELETE_CUSTOM_WORK_TIME_SUCCESS';

export const FLOW_PUT_STANDARD_WORK_TIME_FAILURE = 'FLOW_PUT_STANDARD_WORK_TIME_FAILURE';
export const FLOW_PUT_STANDARD_WORK_TIME_REQUEST = 'FLOW_PUT_STANDARD_WORK_TIME_REQUEST';
export const FLOW_PUT_STANDARD_WORK_TIME_SUCCESS = 'FLOW_PUT_STANDARD_WORK_TIME_SUCCESS';

export const FLOW_DELETE_STANDARD_WORK_TIME_FAILURE = 'FLOW_DELETE_STANDARD_WORK_TIME_FAILURE';
export const FLOW_DELETE_STANDARD_WORK_TIME_REQUEST = 'FLOW_DELETE_STANDARD_WORK_TIME_REQUEST';
export const FLOW_DELETE_STANDARD_WORK_TIME_SUCCESS = 'FLOW_DELETE_STANDARD_WORK_TIME_SUCCESS';

export const FLOW_LOAD_RESTORABLE_NODES_FAILURE = 'FLOW_LOAD_RESTORABLE_NODES_FAILURE';
export const FLOW_LOAD_RESTORABLE_NODES_REQUEST = 'FLOW_LOAD_RESTORABLE_NODES_REQUEST';
export const FLOW_LOAD_RESTORABLE_NODES_SUCCESS = 'FLOW_LOAD_RESTORABLE_NODES_SUCCESS';

export const FLOW_LOAD_RESTORABLE_NODE_COUNTS_FAILURE = 'FLOW_LOAD_RESTORABLE_NODE_COUNTS_FAILURE';
export const FLOW_LOAD_RESTORABLE_NODE_COUNTS_REQUEST = 'FLOW_LOAD_RESTORABLE_NODE_COUNTS_REQUEST';
export const FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS = 'FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS';

export const FLOW_LOAD_RESTORABLE_TIME_RECORDS_FAILURE = 'FLOW_LOAD_RESTORABLE_TIME_RECORDS_FAILURE';
export const FLOW_LOAD_RESTORABLE_TIME_RECORDS_REQUEST = 'FLOW_LOAD_RESTORABLE_TIME_RECORDS_REQUEST';
export const FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS = 'FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS';

export const FLOW_CREATE_NODE_FAILURE = [
  FLOW_CREATE_BOARD_POST_FAILURE,
  FLOW_CREATE_GROUP_FAILURE,
  FLOW_CREATE_MATERIAL_RESOURCE_FAILURE,
  FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_FAILURE,
  FLOW_CREATE_PROJECT_FAILURE,
  FLOW_CREATE_PROJECT_FOLDER_FAILURE,
  FLOW_CREATE_SUB_WORK_PAGKAGE_FAILURE,
  FLOW_CREATE_TASK_FAILURE,
  FLOW_CREATE_TASK_SECTION_FAILURE,
  FLOW_CREATE_WORK_PAGKAGE_FAILURE,
];
export const FLOW_CREATE_NODE_REQUEST = [
  FLOW_CREATE_BOARD_POST_REQUEST,
  FLOW_CREATE_GROUP_REQUEST,
  FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_REQUEST,
  FLOW_CREATE_MATERIAL_RESOURCE_REQUEST,
  FLOW_CREATE_PROJECT_FOLDER_REQUEST,
  FLOW_CREATE_PROJECT_REQUEST,
  FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST,
  FLOW_CREATE_TASK_REQUEST,
  FLOW_CREATE_TASK_SECTION_REQUEST,
  FLOW_CREATE_WORK_PAGKAGE_REQUEST,
];
export const FLOW_CREATE_NODE_SUCCESS = [
  FLOW_CREATE_BOARD_POST_SUCCESS,
  FLOW_CREATE_GROUP_SUCCESS,
  FLOW_CREATE_MATERIAL_RESOURCE_FOLDER_SUCCESS,
  FLOW_CREATE_MATERIAL_RESOURCE_SUCCESS,
  FLOW_CREATE_PROJECT_FOLDER_SUCCESS,
  FLOW_CREATE_PROJECT_SUCCESS,
  FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS,
  FLOW_CREATE_TASK_SECTION_SUCCESS,
  FLOW_CREATE_TASK_SUCCESS,
  FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
];

export const FLOW_CHANGE_NODE_FAILURE = [
  FLOW_CHANGE_BOARD_POST_FAILURE,
  FLOW_CHANGE_MATERIAL_RESOURCE_FAILURE,
  FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_FAILURE,
  FLOW_CHANGE_PID_FAILURE,
  FLOW_CHANGE_PROJECT_FOLDER_FAILURE,
  FLOW_CHANGE_TASK_FAILURE,
  FLOW_PATCH_PROJECT_FAILURE,
  FLOW_PATCH_SUB_WORK_PACKAGE_FAILURE,
  FLOW_PATCH_TASK_SECTION_FAILURE,
];
export const FLOW_CHANGE_NODE_REQUEST = [
  FLOW_CHANGE_BOARD_POST_REQUEST,
  FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_REQUEST,
  FLOW_CHANGE_MATERIAL_RESOURCE_REQUEST,
  FLOW_CHANGE_PID_REQUEST,
  FLOW_CHANGE_PROJECT_FOLDER_REQUEST,
  FLOW_CHANGE_TASK_REQUEST,
  FLOW_PATCH_PROJECT_REQUEST,
  FLOW_PATCH_SUB_WORK_PACKAGE_REQUEST,
  FLOW_PATCH_TASK_SECTION_REQUEST,
];
export const FLOW_CHANGE_NODE_SUCCESS = [
  FLOW_CHANGE_BOARD_POST_SUCCESS,
  FLOW_CHANGE_MATERIAL_RESOURCE_FOLDER_SUCCESS,
  FLOW_CHANGE_MATERIAL_RESOURCE_SUCCESS,
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_CHANGE_PROJECT_FOLDER_SUCCESS,
  FLOW_CHANGE_TASK_SUCCESS,
  FLOW_PATCH_PROJECT_SUCCESS,
  FLOW_PATCH_SUB_WORK_PACKAGE_SUCCESS,
  FLOW_PATCH_TASK_SECTION_SUCCESS,
];
