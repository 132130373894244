import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {RulesList, validateLength} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {pick} from 'lodash';
import {NodesMove, NodesResort} from '../EntityInterfaces/Nodes';
import {TaskSection, TaskSectionPatchData} from '../EntityInterfaces/TaskSection';
import {
  FLOW_CREATE_TASK_SECTION_FAILURE,
  FLOW_CREATE_TASK_SECTION_REQUEST,
  FLOW_CREATE_TASK_SECTION_SUCCESS,
  FLOW_MOVE_TASK_SECTIONS_FAILURE,
  FLOW_MOVE_TASK_SECTIONS_REQUEST,
  FLOW_MOVE_TASK_SECTIONS_SUCCESS,
  FLOW_PATCH_TASK_SECTION_FAILURE,
  FLOW_PATCH_TASK_SECTION_REQUEST,
  FLOW_PATCH_TASK_SECTION_SUCCESS,
  FLOW_REMOVE_TASK_SECTION_FAILURE,
  FLOW_REMOVE_TASK_SECTION_REQUEST,
  FLOW_REMOVE_TASK_SECTION_SUCCESS,
} from './ActionTypes';
import {EventData, TaskSectionsMovedEvent, TaskSectionsMoveEvent} from './Events';
import {
  createNodeEntity,
  getNodeEntityCopiedFields,
  nodeEntityReducers,
  transformNodeEntityToPatchData,
  transformPatchDataToNodeEntity,
} from './Nodes';
import {removeNotAllowedLabels} from './ReduceRemovedLabels';
import {labelIdsSelector} from './Selectors/LabelSelectors';
import {isTaskSection, isWpOrSwp} from '../Node/NodeIdentifiers';
import {nodeEntitySelector} from './Selectors/NodeSelectors';
import {rootFoldersUserFolderSelector} from './Selectors/RootFolderSelectors';
import {getTaskSectionSelector} from './Selectors/TaskSectionSelectors';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

nodeEntityReducers.add<TaskSectionsMoveEvent | TaskSectionsMovedEvent>(
  ['flow.TaskSectionsMovedEvent', FLOW_MOVE_TASK_SECTIONS_REQUEST],
  (state, {resorts}) => {
    let changed = false;
    const newState = {...state};
    resorts.forEach(({sortedSiblingNodeIds}) => {
      sortedSiblingNodeIds.forEach((taskSectionId, index) => {
        const taskSection = newState[taskSectionId];
        if (isTaskSection(taskSection) && taskSection.sortOrder !== index) {
          newState[taskSectionId] = {...taskSection, sortOrder: index};
          changed = true;
        }
      });
    });
    return changed ? newState : state;
  },
);

export function createTaskSectionEntity(): TaskSection {
  return {
    ...createNodeEntity(EnumFlowNodeType.VALUE_TASK_SECTION),
    nodeType: EnumFlowNodeType.VALUE_TASK_SECTION,
    sortOrder: 0,
  };
}

export function getTaskSectionEntityCopiedFields(): Array<keyof TaskSection> {
  return getNodeEntityCopiedFields();
}

export function copyTaskSectionEntity(source: TaskSection): TaskSection {
  return {
    ...createTaskSectionEntity(),
    ...pick(source, getTaskSectionEntityCopiedFields()),
  };
}

function transformTaskSectionToPatchData(taskSection: TaskSection): TaskSectionPatchData {
  return {
    ...transformNodeEntityToPatchData<TaskSectionPatchData>(taskSection),
  };
}

export function getDefaultTaskSectionCreationData(): TaskSectionPatchData {
  return transformTaskSectionToPatchData(createTaskSectionEntity());
}

function getValidationRules(id: Uuid, data: Partial<TaskSectionPatchData>): RulesList {
  return [[validateLength, data.name, 'general:taskSection.nameTooLong', `name_${id}`]];
}

function transformPatchDataToTaskSection(partial: DeepPartial<TaskSectionPatchData>): DeepPartial<TaskSection> {
  return transformPatchDataToNodeEntity<TaskSection>(partial);
}

export function createTaskSection(
  data: TaskSectionPatchData,
  parentNodeId: Uuid,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    if (!validateErrorRules(getValidationRules(data.id, data), dispatch)) {
      return false;
    }
    const userNodeId = rootFoldersUserFolderSelector(state);
    const parentNode = nodeEntitySelector(state)[parentNodeId];
    if (userNodeId !== parentNodeId && !isWpOrSwp(parentNode) && !isTaskSection(parentNode)) {
      throw new Error(`Invalid parent '${parentNodeId}' given`);
    }
    const patch = removeNotAllowedLabels(transformPatchDataToTaskSection(data), labelIdsSelector(state));
    await dispatch({
      [CALL_API]: {
        endpoint: routes.putTaskSection,
        method: 'put',
        options: {
          data: {
            ...patch,
            parentNodeId,
          },
          urlParams: {taskSectionId: data.id},
        },
        types: {
          failureType: FLOW_CREATE_TASK_SECTION_FAILURE,
          requestType: FLOW_CREATE_TASK_SECTION_REQUEST,
          successType: FLOW_CREATE_TASK_SECTION_SUCCESS,
        },
      },
    });

    return true;
  };
}

export function patchTaskSection(
  taskSectionId: Uuid,
  partial: Partial<TaskSectionPatchData>,
): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    if (!validateErrorRules(getValidationRules(taskSectionId, partial), dispatch)) {
      return false;
    }
    const oldEntity = getTaskSectionSelector(state)(taskSectionId);
    const patch = removeNotAllowedLabels(transformPatchDataToTaskSection(partial), labelIdsSelector(state));
    if (oldEntity && !objectContains(oldEntity, patch, false, true)) {
      patch.lastChangedBy = currentOrgUserIdSelector(state);
      patch.lastChangedOn = unix();
      dispatch({
        [CALL_API]: {
          endpoint: routes.patchTaskSection,
          method: 'patch',
          options: {
            data: patch,
            urlParams: {taskSectionId},
          },
          types: {
            failureType: FLOW_PATCH_TASK_SECTION_FAILURE,
            requestType: FLOW_PATCH_TASK_SECTION_REQUEST,
            successType: FLOW_PATCH_TASK_SECTION_SUCCESS,
          },
        },
        patchedNodeId: taskSectionId, //for the optimistic patch
      });
    }
    return true;
  };
}

export function moveTaskSections(moves: NodesMove[], resorts: NodesResort[]): ActionDispatcher<void, FlowState> {
  const data: EventData<TaskSectionsMoveEvent> = {moves, resorts};
  return (dispatch) => {
    dispatch({
      ...data,
      [CALL_API]: {
        endpoint: routes.moveTaskSections,
        method: 'patch',
        options: {data},
        types: {
          failureType: FLOW_MOVE_TASK_SECTIONS_FAILURE,
          requestType: FLOW_MOVE_TASK_SECTIONS_REQUEST,
          successType: FLOW_MOVE_TASK_SECTIONS_SUCCESS,
        },
      },
    });
  };
}

export function deleteTaskSection(taskSectionId: Uuid): ActionDispatcher<boolean, FlowState> {
  return (dispatch) => {
    dispatch({
      [CALL_API]: {
        endpoint: routes.deleteTaskSection,
        method: 'del',
        options: {
          urlParams: {taskSectionId},
        },
        types: {
          failureType: FLOW_REMOVE_TASK_SECTION_FAILURE,
          requestType: FLOW_REMOVE_TASK_SECTION_REQUEST,
          successType: FLOW_REMOVE_TASK_SECTION_SUCCESS,
        },
      },
      nodeIds: [taskSectionId], //for the optimistic remove
    });
    return true;
  };
}
