import isPlainObject from 'lodash/isPlainObject';

/**
 * Taken from redux-concatenate-reducers
 * @param {Object} previousState
 * @param {Object} nextState
 * @return {Object}
 */
function applyNextState(previousState, nextState) {
  if (isPlainObject(previousState) && isPlainObject(nextState)) {
    return Object.assign(previousState, nextState);
  } else {
    return nextState;
  }
}

/**
 * Taken from redux-concatenate-reducers
 * @param {Object} previousState
 * @param {Object} nextState
 * @return {boolean}
 */
function checkHasChanged(previousState, nextState) {
  if (isPlainObject(previousState) && isPlainObject(nextState)) {
    if (previousState === nextState) {
      return false;
    } else {
      return Object.keys(nextState).some((key) => previousState[key] !== nextState[key]);
    }
  } else {
    return previousState !== nextState;
  }
}

/**
 * Taken and adjusted from redux/combineReducers
 * @param {Object} nextStateForKey
 * @param {String} key
 * @param {Object} action
 */
function throwUndefinedStateError(nextStateForKey, key, action) {
  if (typeof nextStateForKey === 'undefined') {
    const actionType = action && action.type;
    const actionName = (actionType && `"${actionType.toString()}"`) || 'an action';
    throw new Error(
      `Given action ${actionName}, reducer "${key}" returned undefined. ` +
        'To ignore an action, you must explicitly return the previous state. ' +
        'If you want this reducer to hold no value, you can return null instead of undefined.',
    );
  }
}

function combineReducersArray(reducers) {
  if (reducers.length === 0) {
    throw new Error('Empty reducers array');
  }
  return (state, action) => {
    let finalNextState = isPlainObject(state) ? Object.assign({}, state) : state;
    let hasChanged = false;
    const getPreviousState = () => (typeof state === 'undefined' ? state : finalNextState);
    reducers.forEach((reducer) => {
      const nextState = combiner(reducer)(getPreviousState(), action);
      if (checkHasChanged(finalNextState, nextState)) {
        hasChanged = true;
        finalNextState = applyNextState(finalNextState, nextState);
      }
    });
    return hasChanged ? finalNextState : state;
  };
}

function combineReducersObject(reducers) {
  if (Object.keys(reducers).length === 0) {
    throw new Error('Empty reducers object');
  }
  return (state = {}, action) => {
    let hasChanged = false;
    const nextState = {...state};
    Object.entries(reducers).forEach(([key, reducer]) => {
      const previousStateForKey = state[key];
      const nextStateForKey = combiner(reducer)(previousStateForKey, action);
      throwUndefinedStateError(nextStateForKey, key, action);
      nextState[key] = nextStateForKey;
      hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
    });
    return hasChanged ? nextState : state;
  };
}

/**
 * Combines multiple reducers. Either
 * @param {Object|Array} reducers
 * @return {function(state: Object, action: Object): Object}
 */
export function combiner(reducers) {
  if (Array.isArray(reducers)) {
    return combineReducersArray(reducers);
  } else if (isPlainObject(reducers)) {
    return combineReducersObject(reducers);
  } else if (typeof reducers === 'function') {
    return reducers;
  }
  throw new Error('Invalid reducers type');
}
