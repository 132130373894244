import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {timeRecordUnitFilterSelector} from '../Selectors/UiSelectors';
import {FlowState} from '../State';
import {PayloadTimeRecord, setInspector, setUiState, UnitFilterUIState} from '../Ui';
import {TimeFilter, TimeRecordsUiState, TimeRecordTimeFilter} from './TimeRecordState';

export type TimeRecordWithTimeAndCustomerFilter = keyof Pick<TimeRecordsUiState, 'myRecordings' | 'projects' | 'users'>;
export type TimeRecordWithUnitsFilter = keyof Pick<TimeRecordsUiState, 'projects' | 'users'>;

export function setTimeRecordTimeFilter(
  timeFilter: TimeFilter,
  filterSelector: (state: FlowState) => (page: TimeRecordWithTimeAndCustomerFilter) => TimeRecordTimeFilter,
  page: TimeRecordWithTimeAndCustomerFilter,
  start?: string | null,
  end?: string | null,
): ActionDispatcher<void, FlowState> {
  return async (dispatch, getState) => {
    const currentFilter = filterSelector(getState())(page);

    if (currentFilter.type !== timeFilter || currentFilter.from !== start || currentFilter.to !== end) {
      await dispatch(
        setUiState({
          pages: {
            timeRecords: {
              [page]: {
                timeFilter: {
                  from: start === null ? null : start || currentFilter.from,
                  to: end === null ? null : end || currentFilter.to,
                  type: timeFilter,
                } as TimeRecordTimeFilter,
              },
            },
          },
        }),
      );
    }
  };
}

export function addTimeRecordAllUserUnitFilter(
  unit: UnitFilterUIState,
  page: TimeRecordWithUnitsFilter,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const units = timeRecordUnitFilterSelector(state)(page);

    if (!units.find((filteredUnit) => filteredUnit.unitId === unit.unitId)) {
      dispatch(
        setUiState({
          pages: {
            timeRecords: {
              [page]: {
                unitFilter: [...units, unit],
              },
            },
          },
        }),
      );
    }
  };
}

export function removeTimeRecordAllUserUnitFilter(
  unitId: Uuid,
  page: TimeRecordWithUnitsFilter,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const units = timeRecordUnitFilterSelector(state)(page);

    if (units.find((filterdUnit) => filterdUnit.unitId === unitId)) {
      dispatch(
        setUiState({
          pages: {
            timeRecords: {
              [page]: {
                unitFilter: units.filter((filterdUnit) => filterdUnit.unitId !== unitId),
              },
            },
          },
        }),
      );
    }
  };
}

export function editTimeRecord(editTimeRecord: PayloadTimeRecord | null): ActionDispatcher<void, FlowState> {
  return setInspector({editTimeRecord});
}
