import {isRemovedRole} from '@octaved/flow/src/Authorization/DefaultRoles';
import {RoleDropdownOption} from '@octaved/flow/src/Modules/Selectors/RoleSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import {Dropdown} from 'semantic-ui-react';

interface Props {
  availableRoles: RoleDropdownOption[];
  className?: string;
  disabled?: boolean;
  excludedRoles?: Uuid[];
  onChange: (roleId: Uuid) => void;
  placeholder?: string;
  roleId?: Uuid;
}

export default function RoleDropdown({
  availableRoles,
  className,
  disabled,
  excludedRoles,
  onChange,
  placeholder,
  roleId,
}: Props): ReactElement {
  const filteredOptions = useMemo(() => {
    const excludedRolesSet = excludedRoles ? new Set(excludedRoles) : null;
    return availableRoles.filter(
      ({value}) =>
        (value === roleId || !excludedRolesSet || !excludedRolesSet.has(value as string)) &&
        ((roleId && isRemovedRole(roleId)) || !isRemovedRole(value)),
    );
  }, [excludedRoles, availableRoles, roleId]);
  return (
    <Dropdown
      className={className}
      placeholder={placeholder}
      selection
      selectOnBlur={false}
      fluid
      wrapSelection
      options={filteredOptions}
      value={roleId}
      disabled={disabled}
      onChange={(_e, {value}) => {
        if (value) {
          onChange(value as Uuid);
        }
      }}
    />
  );
}
