import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {RulesList, validatePositiveIntegerStringFormat, validateMinMax} from '@octaved/store/src/Validation';
import {DeepPartial, NumberToString} from '@octaved/typescript/src/lib';
import {t} from 'i18next';
import {getBillingTypeTranslatable} from '../../WorkPackage/BillingType';

export interface BillingTypeBookLimits {
  maxPercentage: number | null;
  redFrom100: boolean;
  warningPercentage: number | null;
}

export interface BillingTypesBookLimits {
  [EnumFlowPidBillingType.VALUE_CONTINGENT]: BillingTypeBookLimits;
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP]: BillingTypeBookLimits;
  [EnumFlowPidBillingType.VALUE_EFFORT_EST]: BillingTypeBookLimits;
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]: BillingTypeBookLimits;
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: BillingTypeBookLimits;
}

function generateBillingType(warn: number | null, max: number | null, redFrom100: boolean): BillingTypeBookLimits {
  return {
    redFrom100,
    maxPercentage: max,
    warningPercentage: warn,
  };
}

export const initialBillingTypesBookLimits: BillingTypesBookLimits = {
  [EnumFlowPidBillingType.VALUE_CONTINGENT]: generateBillingType(90, 100, false),
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP]: generateBillingType(90, 100, false),
  [EnumFlowPidBillingType.VALUE_EFFORT_EST]: generateBillingType(120, null, false),
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]: generateBillingType(110, null, false),
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: generateBillingType(90, 110, true),
};

export type BookLimitedBillingTypes = keyof BillingTypesBookLimits;

export const bookLimitedBillingTypes: BookLimitedBillingTypes[] = [
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_FIXED_PRICE,
  EnumFlowPidBillingType.VALUE_CONTINGENT,
];

export function isBookLimitedBillingType(billingType: EnumFlowPidBillingType): billingType is BookLimitedBillingTypes {
  return bookLimitedBillingTypes.includes(billingType as keyof BillingTypesBookLimits);
}

const MAX_VALUE = 1000;
const MIN_WARNING_VALUE = 50;
const MIN_MAX_VALUE = 100;

function validateBillingTypeBookLimits(
  rules: RulesList,
  billingType: BookLimitedBillingTypes,
  data: Partial<NumberToString<BillingTypeBookLimits>> | undefined,
): void {
  if (data) {
    if (data.warningPercentage !== null) {
      if (data.warningPercentage!.length > 0) {
        rules.push([
          validateMinMax,
          data.warningPercentage,
          {
            i18nKey: 'systemSettings:overrun.validationError',
            values: {
              max: MAX_VALUE,
              min: MIN_WARNING_VALUE,
              priceType: [getBillingTypeTranslatable(billingType, true)],
              setting: t('systemSettings:overrun.warningFrom'),
            },
          },
          `billingType-warning-${billingType}`,
          MIN_WARNING_VALUE,
          MAX_VALUE,
        ]);
      }
      rules.push([
        validatePositiveIntegerStringFormat,
        data.warningPercentage,
        {
          i18nKey: 'systemSettings:overrun.validationErrorNaN',
          values: {
            priceType: [getBillingTypeTranslatable(billingType, true)],
            setting: t('systemSettings:overrun.warningFrom'),
          },
        },
        `billingType-warning-${billingType}`,
      ]);
    }
    if (data.maxPercentage !== null) {
      const setting = t('systemSettings:overrun.noRecordingAt');
      if (data.maxPercentage!.length > 0) {
        rules.push([
          validateMinMax,
          data.maxPercentage,
          {
            i18nKey: 'systemSettings:overrun.validationError',
            values: {
              setting,
              max: MAX_VALUE,
              min: MIN_MAX_VALUE,
              priceType: [getBillingTypeTranslatable(billingType, true)],
            },
          },
          `billingType-max-${billingType}`,
          MIN_MAX_VALUE,
          MAX_VALUE,
        ]);
      }
      rules.push([
        validatePositiveIntegerStringFormat,
        data.maxPercentage,
        {
          i18nKey: 'systemSettings:overrun.validationErrorNaN',
          values: {
            setting,
            priceType: [getBillingTypeTranslatable(billingType, true)],
          },
        },
        `billingType-max-${billingType}`,
      ]);
    }
  }
}

export function getBillingTypesBookLimitsValidationRules(
  billingTypesBookLimits: DeepPartial<NumberToString<BillingTypesBookLimits>>,
  rules: RulesList,
): void {
  validateBillingTypeBookLimits(rules, EnumFlowPidBillingType.VALUE_CONTINGENT, billingTypesBookLimits.contingent);
  validateBillingTypeBookLimits(rules, EnumFlowPidBillingType.VALUE_EFFORT_CAP, billingTypesBookLimits.effortCap);
  validateBillingTypeBookLimits(rules, EnumFlowPidBillingType.VALUE_EFFORT_EST, billingTypesBookLimits.effortEst);
  validateBillingTypeBookLimits(
    rules,
    EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
    billingTypesBookLimits.effortFromTo,
  );
  validateBillingTypeBookLimits(rules, EnumFlowPidBillingType.VALUE_FIXED_PRICE, billingTypesBookLimits.fixedPrice);
}

export function transformBillingTypesBookLimitsToPatchData(
  data: BillingTypesBookLimits,
): NumberToString<BillingTypesBookLimits> {
  const convertedData = data as NumberToString<BillingTypesBookLimits>;
  const limitList = Object.entries(data) as Array<[BookLimitedBillingTypes, NumberToString<BillingTypeBookLimits>]>;
  for (const [key, limits] of limitList) {
    if (limits.maxPercentage) {
      convertedData[key]!.maxPercentage = limits.maxPercentage.toString();
    }
    if (limits.warningPercentage) {
      convertedData[key]!.warningPercentage = limits.warningPercentage.toString();
    }
  }
  return convertedData;
}

export function transformPatchDataToBillingTypesBookLimits(
  data: DeepPartial<NumberToString<BillingTypesBookLimits>>,
): DeepPartial<BillingTypesBookLimits> {
  const convertedData = data as DeepPartial<BillingTypesBookLimits>;
  const limitList = Object.entries(data) as Array<[BookLimitedBillingTypes, NumberToString<BillingTypeBookLimits>]>;
  for (const [key, limits] of limitList) {
    if (limits.maxPercentage) {
      convertedData[key]!.maxPercentage = parseInt(limits.maxPercentage, 10);
    }
    if (limits.warningPercentage) {
      convertedData[key]!.warningPercentage = parseInt(limits.warningPercentage, 10);
    }
  }
  return convertedData;
}
