import classNames from 'classnames';
import {forwardRef, HTMLProps, ReactElement, ReactNode, Ref} from 'react';
import {useTaskListReadonlyContext} from '../../../../TaskListReadonlyContext';

interface Props extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  focused?: boolean;
  hasFocusElement?: boolean;
  setFocused: (focus: boolean) => void;
  wider?: boolean;
  smaller?: boolean;
}

export default forwardRef(function TaskCell(
  {children, focused, setFocused, hasFocusElement, wider, smaller, ...props}: Props,
  ref: Ref<HTMLDivElement> | null,
): ReactElement {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  return (
    <div
      className={classNames('cell', {focused, readonly: readonlyOrNotManageable, wider, smaller})}
      ref={ref}
      onClick={() => setFocused(!focused)}
      {...props}
    >
      {hasFocusElement && !focused && <div tabIndex={0} onFocus={() => setFocused(true)} className={'focusElement'} />}
      {children}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .cell {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: 1px solid var(--cell-border);
          margin-right: -1px;
          padding: 0 var(--cell-padding);
          font-size: 12px;
          transition: border-color 170ms ease-in-out;
          width: var(--cell-width-normal);
          justify-content: space-between;
          outline: transparent;
          line-height: 1.2;
        }

        .wider {
          width: var(--cell-width-wider);
        }

        .smaller {
          width: var(--cell-width-smaller);
        }

        .readonly {
          cursor: default;
        }

        .cell.focused,
        .cell.focused:hover {
          background-color: var(--color-bg);
          border-color: var(--color-border-focus);
          position: relative;
          z-index: 2;
        }

        .cell:hover {
          position: relative;
          z-index: 1;
          border-color: var(--color-border-hover);
        }

        .cell:last-child {
          border-right: none;
        }

        .cell :global(.onHover) {
          visibility: hidden;
          pointer-events: none;
        }

        .cell.focused :global(.onHover),
        .cell:hover :global(.onHover) {
          visibility: visible;
          pointer-events: all;
        }

        .focusElement {
          position: absolute;
        }

        :global(.done:not(:hover)) .cell:not(.focused) > :global(*) {
          opacity: var(--done-opacity);
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
});
