import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import ProjectRoleInspectorContext from '../../../../Drawer/InspectorContext/ProjectRoleInspectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const projectRoleRoute = 'projectRole';

const ProjectRoleInspector = lazy(
  () => import('../../../../Pages/SystemSettings/ProjectRole/Inspector/ProjectRoleInspector'),
);

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['group']: [
    {
      path: projectRoleRoute,
      token: '',
    },
  ],
};
//endregion

export const projectRoleInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <ProjectRoleInspector />
        </InspectorContextLoadingWrapper>
      ),
      path: projectRoleRoute,
    },
    {
      element: <Navigate to={projectRoleRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: ProjectRoleInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
