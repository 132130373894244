import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {nodeEntitySelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {
  GantProjectPlanningDataProps,
  ProjectBasedGanttDataLoader,
} from '../Canvas/ProjectBasedGantt/ProjectBasedGanttDataLoader';
import {baselineComparisonSearchSelector} from '../Selectors/UiSelectors';

interface SingleNodePlanningDataLoaderProps extends GantProjectPlanningDataProps {
  nodeId: Uuid;
}

export class SingleNodePlanningData extends ProjectBasedGanttDataLoader {
  #nodeId: Uuid;

  constructor(props: SingleNodePlanningDataLoaderProps) {
    super(props);
    this.#nodeId = props.nodeId;
  }

  protected getSearchTermSelector(state: FlowState): string {
    return baselineComparisonSearchSelector(state);
  }

  protected getGroupByCustomerSelector(_state: FlowState): boolean {
    return false;
  }

  protected showMaterialRessourcesSelector(_state: FlowState): boolean {
    return false;
  }

  protected getRootNodes(): NodeType[] {
    const state = this.ctx.store.getState();
    const nodes = nodeEntitySelector(state);
    return boolFilter<NodeType>([this.#nodeId].map((id) => nodes[id]));
  }
}
