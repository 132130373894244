import {error} from '@octaved/env/src/Logger';
import {getTreeStructure} from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createFlatTimestampReducer,
  EntityState,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NodeCreationOptions} from '../EntityInterfaces/Nodes';
import {NodeTree} from '../EntityInterfaces/NodeTree';
import {
  FLOW_COPY_PID_REQUEST,
  FLOW_CREATE_NODE_FAILURE,
  FLOW_CREATE_NODE_REQUEST,
  FLOW_INIT_LOAD_REQUEST,
  FLOW_INIT_LOAD_SUCCESS,
  FLOW_LOAD_NODE_TREE_FAILURE,
  FLOW_LOAD_NODE_TREE_REQUEST,
  FLOW_LOAD_NODE_TREE_SUCCESS,
  FLOW_MOVE_TASK_SECTIONS_REQUEST,
  FLOW_MOVE_TASKS_REQUEST,
  FLOW_REMOVE_TASK_REQUEST,
} from './ActionTypes';
import {
  CopyPidRequestEvent,
  GroupCreatedEvent,
  NodeCreatedEvent,
  NodeMovedEvent,
  NodeRestoredFromTrashEvent,
  NodesRearrangeEvent,
  PidCopiedEvent,
  ProjectCreatedEvent,
  TaskCreatedEvent,
  TasksCopiedEvent,
  TaskSectionsMovedEvent,
  TasksMovedEvent,
  WorkPackageCreatedEvent,
} from './Events';

import {InitAction} from './Initialization/Actions';
import {nodeTreeStateSelector} from './Selectors/NodeTreeSelectors';
import {FlowState} from './State';

interface AddAction {
  options: NodeCreationOptions;
  type: string;
}

const reducerMap = new Map();

reducerMap.set(FLOW_LOAD_NODE_TREE_SUCCESS, (_state: NodeTree, {response}: {response: NodeTree}): NodeTree => response);
reducerMap.set(FLOW_INIT_LOAD_SUCCESS, (state: NodeTree, action: InitAction): NodeTree => {
  if (action.isInOrganization) {
    return action.response.result.nodeTreeStructure;
  }
  return state;
});

function onNodeAddRequest(state: NodeTree, {options, type}: AddAction): NodeTree {
  const parentNodeId = options.urlParams.parentNodeId || options.data.parentNodeId;
  if (!parentNodeId) {
    error(`Missing 'parentNodeId' in ${type}`);
    return state;
  }
  return {
    ...state,
    [options.data.id]: parentNodeId,
  };
}

function onNodeAddFailure(state: NodeTree, {options}: AddAction): NodeTree {
  if (state[options.data.id]) {
    const newState = {...state};
    delete newState[options.data.id];
    return newState;
  }
  return state;
}

function reduceNewParentId(state: NodeTree, parentId: Uuid | null, childIds: Uuid[]): NodeTree {
  let changed = false;
  const newState = {...state};
  childIds.forEach((id) => {
    if (newState[id] !== parentId) {
      newState[id] = parentId;
      changed = true;
    }
  });
  return changed ? newState : state;
}

addMultiToReducerMap(reducerMap, FLOW_CREATE_NODE_REQUEST, onNodeAddRequest);
addMultiToReducerMap(reducerMap, FLOW_CREATE_NODE_FAILURE, onNodeAddFailure);

addMultiToReducerMap(
  reducerMap,
  [
    'flow.GroupCreatedEvent',
    'flow.MaterialResourceCreatedEvent',
    'flow.NodeCreatedEvent',
    'flow.ProjectCreatedEvent',
    'flow.SubWorkPackageCreatedEvent',
    'flow.TaskCreatedEvent',
    'flow.WorkPackageCreatedEvent',
  ],
  (
    state: NodeTree,
    {
      nodeId,
      parentNodeId,
    }: GroupCreatedEvent | NodeCreatedEvent | ProjectCreatedEvent | TaskCreatedEvent | WorkPackageCreatedEvent,
  ): NodeTree => {
    return reduceNewParentId(state, parentNodeId, [nodeId]);
  },
);
reducerMap.set(
  FLOW_COPY_PID_REQUEST,
  (state: NodeTree, {copyNode: {id}, targetParentNodeId}: CopyPidRequestEvent): NodeTree => {
    return reduceNewParentId(state, targetParentNodeId, [id]);
  },
);
reducerMap.set(
  'flow.NodesRearrangeEvent',
  (state: NodeTree, {movedNodeId, newParentNodeId}: NodesRearrangeEvent): NodeTree => {
    return reduceNewParentId(state, newParentNodeId, [movedNodeId]);
  },
);

reducerMap.set('flow.NodeMovedEvent', (state: NodeTree, {nodeId, newParentNodeId}: NodeMovedEvent): NodeTree => {
  return reduceNewParentId(state, newParentNodeId, [nodeId]);
});

addMultiToReducerMap<NodeTree, TasksMovedEvent | TaskSectionsMovedEvent>(
  reducerMap,
  ['flow.TasksMovedEvent', FLOW_MOVE_TASKS_REQUEST, 'flow.TaskSectionsMovedEvent', FLOW_MOVE_TASK_SECTIONS_REQUEST],
  (state, {moves}) =>
    moves.reduce(
      (acc, {movedNodeIds, targetParentNodeId}) => reduceNewParentId(acc, targetParentNodeId, movedNodeIds),
      state,
    ),
);

addMultiToReducerMap<NodeTree, TasksCopiedEvent>(
  reducerMap,
  ['flow.TasksCopiedEvent'],
  (state, {newTaskIdsToParentIds}) => mergeStates(state, newTaskIdsToParentIds),
);

const remove = (state: NodeTree, {nodeIds}: {nodeIds: Uuid[]}): NodeTree => {
  let changed = false;
  const newState = {...state};
  nodeIds.forEach((id) => {
    if (newState[id]) {
      delete newState[id];
      changed = true;
    }
  });
  return changed ? newState : state;
};
reducerMap.set(FLOW_REMOVE_TASK_REQUEST, remove);
reducerMap.set('flow.NodesRemovedEvent', remove);

reducerMap.set('flow.NodeRestoredFromTrashEvent', (state: NodeTree, action: NodeRestoredFromTrashEvent) => {
  return mergeStates(state, action.nodeSubtree);
});

reducerMap.set('flow.PidCopiedEvent', (state: NodeTree, action: PidCopiedEvent) => {
  return mergeStates(state, action.copiedNodeTree);
});

export const nodeTreeReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();

stateReducerMap.set(FLOW_INIT_LOAD_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_INIT_LOAD_SUCCESS, (state: EntityState, action: InitAction) => {
  if (action.isInOrganization) {
    return createFlatTimestampReducer(LOADED)(state, action);
  }
  return state;
});
stateReducerMap.set(FLOW_LOAD_NODE_TREE_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_LOAD_NODE_TREE_SUCCESS, createFlatTimestampReducer(LOADED));

export const nodeTreeStateReducer = ReduceFromMap(stateReducerMap);

function loadNodeTree(): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    if (isOutdated(nodeTreeStateSelector(getState()))) {
      await dispatch({
        [CALL_API]: {
          endpoint: getTreeStructure,
          types: {
            failureType: FLOW_LOAD_NODE_TREE_FAILURE,
            requestType: FLOW_LOAD_NODE_TREE_REQUEST,
            successType: FLOW_LOAD_NODE_TREE_SUCCESS,
          },
        },
      });
    }
  };
}

export function useNodeTree(): boolean {
  const dispatch = useDispatch();
  const state = useSelector(nodeTreeStateSelector);
  useEffect(() => {
    dispatch(loadNodeTree());
  }, [dispatch, state]);
  return isLoaded(state);
}
