import {createOfferRoute} from '@octaved/flow/src/Routing/Routes/Routes';
import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {ComponentType} from 'react';
import {ViewTabs} from '../../Components/ViewTabs';
import {
  advancedRoute,
  controllingRoute,
  detailsRoute,
  generalRoute,
  guestUserRoute,
  overviewRoute,
  planningRoute,
  responsibleRoute,
  settingsRoute,
  specialPlanningRoute,
  specialQuoteRoute,
  timeAndMoneyRoute,
} from '../../LargeView/RoutePaths';
import {LargeViewPages} from '../../LargeView/Routes';
import {ProjectLargeViewProps} from '../../LargeView/Views/Project/Overview';
import Advanced from '../Views/Project/Advanced';
import Controlling from '../Views/Project/Controlling';
import General from '../Views/Project/General';
import Guests from '../Views/Project/Guests';
import Planning from '../Views/Project/Planning';
import Responsibility from '../Views/Project/Responsibility';
import Settings from '../Views/Project/Settings';
import SpecialPlanning from '../Views/Project/SpecialPlanning';
import SpecialQuote from '../Views/Project/SpecialQuote';

export type ProjectInpsectorTabs =
  | Extract<
      LargeViewPages,
      'general' | 'responsibility' | 'planning' | 'controlling' | 'guests' | 'settings' | 'advanced'
    >
  | typeof specialQuoteRoute
  | typeof specialPlanningRoute;

export const projectInspectorTabs: ViewTabs<ProjectInpsectorTabs>[] = [
  {
    access: () => {
      return location.pathname.startsWith(`/${createOfferRoute}`);
    },
    path: specialQuoteRoute,
    token: 'nodeDetails:tab.createOffer',
    value: specialQuoteRoute,
  },
  {
    access: ({isSimulationActive}) => {
      return (
        (location.pathname.startsWith('/projectPlanning') || location.pathname.startsWith('/teamPlanning')) &&
        isSimulationActive
      );
    },
    path: specialPlanningRoute,
    token: 'nodeDetails:tab.specialPlanning',
    value: specialPlanningRoute,
  },
  {
    path: generalRoute,
    token: 'nodeDetails:tab.general',
    value: 'general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibleRoute,
    token: 'nodeDetails:tab.responsibility',
    value: 'responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'nodeDetails:tab.planning',
    value: 'planning',
  },
  {
    path: controllingRoute,
    token: 'nodeDetails:tab.controlling',
    value: 'controlling',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_GUEST_PERMISSION_ROLE_ASSIGNMENTS', anyNode),
    path: guestUserRoute,
    token: 'nodeDetails:tab.guestUser',
    value: 'guests',
  },
  {
    path: settingsRoute,
    token: 'nodeDetails:tab.settings',
    value: 'settings',
  },
  {
    path: advancedRoute,
    token: 'nodeDetails:tab.advanced',
    value: 'advanced',
  },
];

export const projectInspectorTabViews: Record<ProjectInpsectorTabs, ComponentType<ProjectLargeViewProps>> = {
  advanced: Advanced,
  controlling: Controlling,
  createOffer: SpecialQuote,
  general: General,
  guests: Guests,
  planning: Planning,
  responsibility: Responsibility,
  settings: Settings,
  specialPlanning: SpecialPlanning,
};

const projectInspectorTabsToLargeViews = {
  advanced: `${detailsRoute}/${advancedRoute}`,
  controlling: `${controllingRoute}/${overviewRoute}`,
  createOffer: overviewRoute,
  general: overviewRoute,
  guests: `${detailsRoute}/${guestUserRoute}`,
  planning: `${detailsRoute}/${planningRoute}`,
  responsibility: `${detailsRoute}/${responsibleRoute}`,
  settings: `${detailsRoute}/${settingsRoute}`,
  specialPlanning: overviewRoute,
  timeAndMoney: `${detailsRoute}/${timeAndMoneyRoute}`,
} as const;

type value = keyof typeof projectInspectorTabsToLargeViews;
export type ProjectLargeViewPaths = (typeof projectInspectorTabsToLargeViews)[value];

export function getLargeViewPageFromInspectorTab(tab: value): ProjectLargeViewPaths {
  return projectInspectorTabsToLargeViews[tab];
}
