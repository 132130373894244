import {EnumRevisionType} from '@octaved/env/src/dbalEnumTypes';

import {Audit} from '../../../../EntityInterfaces/Audit/Audit';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import FirstCell from '../Table/Cells/FirstCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import TimeStampCell from '../Table/Cells/TimeStampCell';
import {AuditTableColumn} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';

const revisionTypeTranslations = new Map<EnumRevisionType, string>([
  [EnumRevisionType.VALUE_INSERT, 'systemSettings:audit.tables.history.enumRevisionTypeInsert'],
  [EnumRevisionType.VALUE_UPDATE, 'systemSettings:audit.tables.history.enumRevisionTypeUpdate'],
  [EnumRevisionType.VALUE_DELETE, 'systemSettings:audit.tables.history.enumRevisionTypeDelete'],
]);

export const historyColumns: AuditTableColumn<Audit>[] = [
  {
    Cell: FirstCell,
    headerTranslationToken: 'systemSettings:audit.tables.history.revisionHeader',
    id: 'revision',
  },
  createColumnDef('revisionType', 'systemSettings:audit.tables.history.revisionTypeHeader', ({cell: {value}}) => {
    return <EnumTranslationCell value={value} translationMapping={revisionTypeTranslations} />;
  }),
  createColumnDef('revisionUser', 'systemSettings:audit.tables.history.revisionUserHeader', ({cell}) => {
    return <ReferenceCell cell={cell} disableLink />;
  }),
  createColumnDef('revisionStartDate', 'systemSettings:audit.tables.history.revisionStartDateHeader', TimeStampCell),
];
