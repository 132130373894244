import {isInDateRange} from '@octaved/planning/src/Calculations/DateCalculations';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {getPlanningDateSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {Icon} from '@octaved/ui';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../../../../EntityInterfaces/Pid';
import {useFormattedDateTextRange} from '../../../../../Hooks/FormattedDateText';
import {getMaterialResourceSelector} from '../../../../../Modules/Selectors/MaterialResourceSelectors';

interface MaterialResourceProps {
  workPackage: WorkPackage;
  planningDateId: Uuid;
  materialResourceId: Uuid;
}

export default function MaterialResource({
  materialResourceId,
  planningDateId,
  workPackage,
}: MaterialResourceProps): ReactElement | null {
  const materialResource = useSelector(getMaterialResourceSelector)(materialResourceId);
  const planningDate = useSelector(getPlanningDateSelector)(planningDateId);

  const formatDate = useFormattedDateTextRange();
  const {plannedEnd: wpPlannedEnd, plannedStart: wpPlannedStart} = useMinMaxPlanningDates(workPackage.id);

  if (!materialResource || !planningDate) {
    return null;
  }
  const hasWarning = isInDateRange(wpPlannedStart, wpPlannedEnd, planningDate.plannedStart, planningDate.plannedEnd);

  return (
    <>
      <div className={'dateRow'}>
        {formatDate(isoDateTimeToIsoFormat(planningDate.plannedStart), isoDateTimeToIsoFormat(planningDate.plannedEnd))}
      </div>
      <div className={'burningIcon'}>
        {hasWarning && (
          <Icon iconColor={'red'} size={'big'} noMargin>
            <Flame />
          </Icon>
        )}
      </div>

      <div>{materialResource.name}</div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .dateRow {
          margin: -6px 0;
        }
        .burningIcon {
          & :global(svg) {
            fill: #f00;
          }
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
