import {cn} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

interface Props {
  className?: string;
  header?: string;
  headerComponent?: ReactElement;
  inverted?: boolean;
  noPadding?: boolean;
}

export default function DialogAndDrawerHeader({
  className,
  header,
  headerComponent,
  inverted,
  noPadding,
}: Props): ReactElement {
  return (
    <h2
      className={cn(
        'mb-0 pb-5 text-lg font-medium text-slate-600',
        inverted && 'text-white',
        noPadding && 'pb-0',
        className,
      )}
    >
      {!headerComponent && <Trans i18nKey={header} />}
      {headerComponent}
    </h2>
  );
}
