import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import PlanningOption from '@octaved/planning/src/Components/PlanningRealization/PlanningType/PlanningOption';
import {PlanningType} from '@octaved/planning/src/Components/PlanningRealization/PlanningType/PlanningType';
import {Dispatch, ReactElement, SetStateAction} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodePlanningOptionProps {
  labelToken?: string;
  node: NodeType;
  readonly: boolean;
  planningType: PlanningType;
  setPlanningType: Dispatch<SetStateAction<PlanningType>>;
}

export default function NodePlanningOption({
  frame: Frame,
  labelToken = 'pages:projects.inspector.manage.planning.planning',
  node,
  readonly,
  planningType,
  setPlanningType,
}: NodePlanningOptionProps & FramedSectionOrTileProps): ReactElement {
  return (
    <Frame labelToken={labelToken}>
      <PlanningOption node={node} readonly={readonly} planningType={planningType} setPlanningType={setPlanningType} />
    </Frame>
  );
}
