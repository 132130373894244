import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import FlowUnitSearch from '@octaved/flow/src/Components/Unit/FlowUnitSearch';
import UnitNameWithIcon from '@octaved/flow/src/Components/Unit/UnitNameWithIcon';
import {ResponsibleNode, ResponsibleNodeType} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {useNodeResponsibleFullUnits} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {
  addNodeResponsibleUnit,
  manageRights,
  readRights,
  removeNodeResponsibleUnit,
} from '@octaved/flow/src/Modules/ResponsibleNode';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getNextAncestorResponsibleNodeSelector} from '@octaved/flow/src/Modules/Selectors/ResponsibleNodeSelectors';
import {isGroup, isProject, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {HelpPopup, IconButton} from '@octaved/ui';
import {responsibleUnitTypes} from '@octaved/users/src/UnitType';
import {Trash} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../General/Common/Type';
import Placeholder from '../PlaceholderText';
import InheritingTooltip from './InheritingTooltip';

const labels: Record<ResponsibleNodeType, string> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'pages:projects.inspector.responsibleUnits.label.project',
  [EnumFlowNodeType.VALUE_PROJECT]: 'pages:projects.inspector.responsibleUnits.label.project',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'pages:projects.inspector.responsibleUnits.label.subWorkPackage',
  [EnumFlowNodeType.VALUE_TASK]: 'pages:projects.inspector.responsibleUnits.label.task',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'pages:projects.inspector.responsibleUnits.label.workPackage',
};

export interface ResponsibleUnitsProps {
  node: ResponsibleNode;
  readonly?: boolean;
}

export default function ResponsibleUnits({
  frame: Frame,
  node,
  readonly,
}: ResponsibleUnitsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const source = useSelector(getNextAncestorResponsibleNodeSelector)(node.id, {defining: true});
  const units = useNodeResponsibleFullUnits(node.id);
  const unitIds = useMemo(() => units.map(({unitId}) => unitId), [units]);
  const canRead = useIsGranted(readRights[node.nodeType], node.id);
  const canManage = useIsGranted(manageRights[node.nodeType], node.id) && !readonly && !node.isArchived;
  const dispatch = useDispatch();
  const {project} = useNodeAncestry(node.id, true);

  if (!canRead) {
    return null;
  }

  return (
    <Frame
      labelToken={labels[node.nodeType]}
      labelAddition={
        isWorkPackage(node) && (
          <HelpPopup
            text={'pages:projects.inspector.responsibleUnits.labelHelp.groupAndProject'}
            iconButtonSize={'xs'}
          />
        )
      }
      labelRightContent={<InheritingTooltip node={node} readonly={!canManage} source={source} />}
    >
      <div className={'flex flex-col gap-y-2'}>
        {units.length > 0 && (
          <div className={'grid max-w-full grid-cols-1 items-center gap-x-2 gap-y-1'}>
            {units.map((unit) => {
              return (
                <div key={unit.unitId} className={'group/unitRow flex items-center justify-between gap-x-2'}>
                  <UnitNameWithIcon className={'px-0'} {...unit} />
                  {canManage && (
                    <IconButton
                      className={'invisible group-hover/unitRow:visible'}
                      variant={'ghost'}
                      onClick={() => {
                        //prevent unrendering when deleting the last unit
                        dispatch(removeNodeResponsibleUnit(node.id, unit));
                      }}
                      icon={<Trash />}
                      iconColor={'red'}
                      size={'sm'}
                      isDisabled={readonly}
                    />
                  )}
                  {!canManage && <div />}
                </div>
              );
            })}
          </div>
        )}

        {units.length === 0 && (
          <Placeholder
            text={
              !canManage
                ? 'pages:projects.inspector.responsibleUnits.noUnitsText'
                : 'pages:projects.inspector.responsibleUnits.noResponsibleUsers'
            }
          />
        )}

        {canManage && (
          <>
            <div />
            <FlowUnitSearch
              excludedUnits={unitIds}
              filterGuestUserByCustomerId={project?.flowCustomer}
              fluid
              onSelect={(unitId, unitType) => {
                dispatch(addNodeResponsibleUnit(node.id, {unitId, unitType}));
              }}
              placeholder={'roles:addUnitSearchPlaceholder'}
              unitTypes={responsibleUnitTypes}
              upwards={isTask(node)}
            />
          </>
        )}

        {(isGroup(node) || isProject(node)) && (
          <div className={'flex items-center gap-x-2 text-sm'}>
            {t('pages:projects.inspector.responsibleUnits.bottomInfo.projectShort')}
            <HelpPopup text={'pages:projects.inspector.responsibleUnits.bottomInfo.project'} iconButtonSize={'xs'} />
          </div>
        )}
      </div>
    </Frame>
  );
}
