import {ReactElement} from 'react';
import {Navigate} from 'react-router-dom';
import {useGetLastVisitedUrl} from './UrlWatcher';

interface LastVisitRedirectProps {
  default: string;
}

export default function LastVisitRedirect({default: defaultUrl}: LastVisitRedirectProps): ReactElement {
  const lastVisitedUrl = useGetLastVisitedUrl(defaultUrl);
  return <Navigate to={lastVisitedUrl} replace />;
}
