import {createPlanningErrorSnackbar} from '@octaved/flow/src/Components/Snackbar/PlanningSnackbar';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {validateDependenciesSelector} from '../Selectors/Validation/ValidateDependencies';
import {patchPlanning} from './Planning';

export function addLogicalDependency(
  nodeId: Uuid,
  logicalDependencyId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const node = getNode(nodeId);
    const validateDependencies = validateDependenciesSelector(state);
    if (node && !node.planningLogicalPredecessors.includes(logicalDependencyId)) {
      const logicalDependencies = [...node.planningLogicalPredecessors, logicalDependencyId];
      const errors = validateDependencies(nodeId, logicalDependencies, 'logical');
      if (errors.length) {
        createPlanningErrorSnackbar(errors);
        return;
      }
      dispatch(patchPlanning([{nodeId: node.id, logicalDependencies}]));
    }
  };
}

export function removeLogicalDependency(
  nodeId: Uuid,
  logicalDependencyId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const node = getNode(nodeId);

    if (node && node.planningLogicalPredecessors.includes(logicalDependencyId)) {
      dispatch(
        patchPlanning([
          {
            logicalDependencies: node.planningLogicalPredecessors.filter((id) => id !== logicalDependencyId),
            nodeId: node.id,
          },
        ]),
      );
    }
  };
}
