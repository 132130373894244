import {DeepPartial} from '@octaved/typescript/src/lib';
import {OrgUserSettings} from '@octaved/users/src/EntityInterfaces/OrgUserSettings';
import {patchUserSettings} from '@octaved/users/src/Modules/OrgUserSettings';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

export function usePatchUserSettings(): (patch: DeepPartial<OrgUserSettings>) => void {
  const dispatch = useDispatch();
  return useCallback((patch) => dispatch(patchUserSettings(patch)), [dispatch]);
}
