import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {toChildren} from '@octaved/node-search/src/Factories/Tree';
import {useMinMaxPlanningDateInSubtree} from '@octaved/planning/src/Modules/MinMaxPlanningDateInSubtrees';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWButton, TWLabel} from '@octaved/ui';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Errors from '../../Components/Feedback/Notifications/Errors';
import {Project} from '../../EntityInterfaces/Pid';
import {CopyPidOptions} from '../../Modules/CopyPid';
import {useCreateProjectFromTemplate} from '../../Modules/CreateFromTemplate';
import {useLoadedNodes} from '../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {formatTimeControl} from '../../Modules/TimeControlledEntity';
import {useAutoTimeControl} from '../DuplicatePid/AutoTimeControl';
import CopyDetailsForm from '../DuplicatePid/CopyDetailsForm';

interface TemplateFormProps {
  color: string;
  copyOptions: CopyPidOptions;
  customerId: Uuid;
  customerLocationId: Uuid | null;
  name: string;
  onFinish: (newNodeId: Uuid) => void;
  projectFolderId: Uuid;
  setCopyAssignments: (copyAssignments: boolean) => void;
  setCopyTasks: (copyTasks: boolean) => void;
  templateProject: Project;
}

export default function TemplateForm({
  color,
  copyOptions,
  customerId,
  customerLocationId,
  name,
  onFinish,
  projectFolderId,
  setCopyAssignments,
  setCopyTasks,
  templateProject,
}: TemplateFormProps): ReactElement | null {
  const {nodeIds: siblingNodeIds} = useCombinedNodeSearch({
    and: [toChildren(projectFolderId), ['nodeType', EnumFlowNodeType.VALUE_PROJECT]],
  });
  const {nodes: siblings} = useLoadedNodes<Project>(siblingNodeIds);
  const {minMaxPlanning} = useMinMaxPlanningDateInSubtree(templateProject.id);
  const {autoTimeControl, autoTimeControlIsPossible, setAutoTimeControl, pidTimeControl} = useAutoTimeControl(
    templateProject,
    siblings,
    copyOptions,
  );
  const {t} = useTranslation();
  const dateFormat = useSelector(dateFormatSelector);
  const {create, errorFields, isCreating} = useCreateProjectFromTemplate(templateProject.id);

  return (
    <div className={'mb-8 flex flex-col gap-y-8'}>
      <Errors includeFields={errorFields} noMargin />
      <div className={'flex flex-col gap-y-1'}>
        <TWLabel>{t('dialogs:createProject.name')}</TWLabel>

        <div className={'flex w-full items-center gap-x-2'}>
          {name}&nbsp;{pidTimeControl && <>({formatTimeControl(t, pidTimeControl, dateFormat, true)})</>}
        </div>
      </div>
      <CopyDetailsForm
        autoTimeControl={autoTimeControl}
        autoTimeControlIsPossible={autoTimeControlIsPossible}
        copyPidOptions={copyOptions}
        initialCopyPlaning
        minMaxPlanning={minMaxPlanning}
        pid={templateProject}
        setAutoTimeControl={setAutoTimeControl}
        setCopyAssignments={setCopyAssignments}
        setCopyTasks={setCopyTasks}
      />

      <div className={'flex justify-center'}>
        <TWButton
          colorScheme={'green'}
          withIcon
          size={'lg'}
          variant={'solid'}
          isLoading={isCreating}
          onClick={async () => {
            const newNodeId = await create({
              color,
              copyOptions,
              customerId,
              customerLocationId,
              name,
              projectFolderId,
            });
            if (newNodeId) {
              onFinish(newNodeId);
            }
          }}
        >
          <PlusCircle className={'size-4'} />
          {t('general:create')}
        </TWButton>
      </div>
    </div>
  );
}
