import {MiddlewareAPI, isAction} from 'redux';

const isSimulatedActionSymbol = Symbol('isSimulatedAction');

export function setSimulatedAction<T = unknown>(action: T): T {
  if (action && typeof action === 'object') {
    Object.defineProperty(action, isSimulatedActionSymbol, {value: true, enumerable: true});
  }
  return action;
}
export function isSimulatedAction(action: unknown): boolean {
  return Boolean(action && typeof action === 'object' && isSimulatedActionSymbol in action);
}

export default function simulatedMiddleware(baseApi: MiddlewareAPI) {
  return function () {
    return function (next: (a: unknown) => unknown) {
      return (action: unknown): Promise<unknown> | unknown => {
        const wasSimulated = isSimulatedAction(action);
        setSimulatedAction(action);
        if (!wasSimulated && isAction(action)) {
          baseApi.dispatch(action);
        }
        return next(action);
      };
    };
  };
}
