import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';

export interface OrganizeBoardsProjectsUiState {
  selectedKanbanBoard: Uuid | null;
}

export const initialOrganizeBoardsProjectsUiState: OrganizeBoardsProjectsUiState = {
  selectedKanbanBoard: null,
};

export function patchOrganizeBoardsProjectsUiState(
  organizeBoardsProjects: DeepPartial<OrganizeBoardsProjectsUiState>,
): ThunkAct<void> {
  return setUiState({pages: {organizeBoardsProjects}});
}

export function setOrganizeBoardsProjectsKanbanBoard(selectedKanbanBoard: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsProjectsUiState({selectedKanbanBoard});
}

export function usePatchOrganizeBoardsProjectsUiState(): (patch: DeepPartial<OrganizeBoardsProjectsUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((patch) => dispatch(patchOrganizeBoardsProjectsUiState(patch)), [dispatch]);
}
