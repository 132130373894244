import {unixToIsoDate} from '@octaved/users/src/Culture/DateFormatFunctions';
import {WorkPackage} from '../EntityInterfaces/Pid';
import {BillingStartEnd} from './BillingStartEnd';

function maximum(a: string | null, b: string | null): string | null {
  return ((a || '') < (b || '') ? b : a) || null;
}

type WP = Pick<WorkPackage, 'lastBillingPeriodEnd' | 'manualTimeTrackingEnd'>;

export function getFrozenUntil({lastBillingPeriodEnd, manualTimeTrackingEnd}: WP): string | null {
  return maximum(lastBillingPeriodEnd, manualTimeTrackingEnd);
}

export function isFrozen(wp: WP, startEnd: BillingStartEnd, ref: {frozenUntil?: string} = {}): boolean {
  const frozenUntil = getFrozenUntil(wp);
  if (frozenUntil) {
    ref.frozenUntil = frozenUntil;
    const start = unixToIsoDate(startEnd.billingStart || null);
    const end = unixToIsoDate(startEnd.billingEnd || null);
    return !!((start && start <= frozenUntil) || (end && end <= frozenUntil));
  }
  return false;
}
