import {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {useKeyboardListener} from '@octaved/ui-components/src/Hooks/UseKeydownListener';
import {format, unformat} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement, RefObject, useCallback, useMemo, useState} from 'react';
import {TaskPatchData} from '../../../../../../EntityInterfaces/Task';
import DropdownPopup from '../../../../../Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
  isDropdownPopupItem,
} from '../../../../../Form/Dropdown/DropdownPopup/Types';

const dropdownOptions = new Map<number | null, ComplexTranslatable>();
dropdownOptions.set(0.25, {
  i18nKey: 'general:date.minutes',
  values: {count: 15},
});
dropdownOptions.set(1, {i18nKey: 'general:date.hours', values: {count: 1, countFormatted: 1}});
dropdownOptions.set(2, {i18nKey: 'general:date.hours', values: {count: 2, countFormatted: 2}});
dropdownOptions.set(4, 'general:date.halfDay');
dropdownOptions.set(8, {i18nKey: 'general:date.1dayCustomHours', values: {count: 8}});
dropdownOptions.set(12, 'general:date.oneAndAHalfDay');
dropdownOptions.set(16, {i18nKey: 'general:date.days', values: {count: 2, countFormatted: 2}});
dropdownOptions.set(24, {i18nKey: 'general:date.days', values: {count: 3, countFormatted: 3}});
dropdownOptions.set(32, {i18nKey: 'general:date.days', values: {count: 4, countFormatted: 4}});
dropdownOptions.set(40, {i18nKey: 'general:date.days', values: {count: 5, countFormatted: 5}});
dropdownOptions.set(null, 'general:task.defineHours');

function useDropdownOptions(
  setCustomHours: () => void,
  patch: (data: Partial<TaskPatchData>) => void,
): DropdownPopupItemOptions[] {
  return useMemo(() => {
    return [...dropdownOptions.entries()].reduce<DropdownPopupItemOptions[]>((acc, [value, token]) => {
      if (value) {
        acc.push({
          token,
          id: value.toString(),
          onMouseDown: () => patch({plannedTime: format(value)}),
          type: DropdownPopupItemType.item,
        });
      } else {
        acc.push({
          type: DropdownPopupItemType.divider,
        });
        acc.push({
          id: 'custom',
          onMouseDown: setCustomHours,
          token: 'general:task.defineHours',
          type: DropdownPopupItemType.item,
        });
      }

      return acc;
    }, []);
  }, [patch, setCustomHours]);
}

export function hasCustomTaskDurationHours(plannedTime?: string | null): boolean {
  return !!plannedTime && !dropdownOptions.has(unformat(plannedTime));
}

interface Props {
  contextRef: RefObject<HTMLDivElement>;
  enableCustomHours: () => void;
  plannedTime?: string | null;
  open: boolean;
  patch: (data: Partial<TaskPatchData>) => void;
}

export default function DefaultOptionsDropdown({contextRef, enableCustomHours, open, patch}: Props): ReactElement {
  const options = useDropdownOptions(enableCustomHours, patch);
  const selectableOptions = useMemo(() => options.filter(isDropdownPopupItem), [options]);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [hovering, setHovering] = useState<undefined | string>();

  const changeHovering = useCallback(
    (change: number) => {
      const newIndex = hoverIndex + change;
      const max = selectableOptions.length - 1;

      if (change > 0) {
        const newMaxIndex = Math.min(newIndex, max);
        const item = selectableOptions[newMaxIndex];
        setHoverIndex(newMaxIndex);
        setHovering(item.id);
      } else {
        const newMinIndex = Math.max(newIndex, 0);
        const item = selectableOptions[newMinIndex];
        setHoverIndex(newMinIndex);
        setHovering(item.id);
      }
    },
    [hoverIndex, selectableOptions],
  );

  const onSaveHovering = useCallback(() => {
    if (hoverIndex !== -1) {
      const item = selectableOptions[hoverIndex];
      item.onMouseDown?.();
    }
  }, [selectableOptions, hoverIndex]);

  useKeyboardListener((e) => {
    if (open) {
      if (e.key === 'ArrowDown') {
        changeHovering(1);
      } else if (e.key === 'ArrowUp') {
        changeHovering(-1);
      } else if (e.key === 'Enter') {
        onSaveHovering();
      }
    }
  });

  return (
    <DropdownPopup
      position={'bottom left'}
      items={options}
      context={contextRef}
      open={open}
      scrollOverflow
      noIcon
      hovering={hovering}
      mountNode={contextRef.current}
      onOpen={() => false}
      onClose={() => false}
    />
  );
}
