import {useHoverShapeIndex, useSetHoverShapeIndex} from '@octaved/ui';
import {FunctionComponent, ReactElement} from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';
import {ContentType} from 'recharts/types/component/DefaultLegendContent';
import {PieSectorDataItem} from 'recharts/types/polar/Pie';
import {ActiveShape} from 'recharts/types/util/types';
import LabelLineRenderer from './Components/LabelLineRenderer';
import {PieChartLabelRenderProps} from './Components/PercentageWithAbsoluteLabel';

export interface ReChartPieChartDataSet {
  name: string;
  value: number;
  color: string;
}

interface Props {
  ActiveShapeRenderer?: ActiveShape<PieSectorDataItem>;
  data: ReChartPieChartDataSet[];
  LabelRenderer?: FunctionComponent<PieChartLabelRenderProps>;
  labelFormatter?: (v: number) => string;
  legend?: boolean;
  legendContentRenderer?: ContentType;
  strokeWidth?: string;
}

export default function SimplePieChart({
  ActiveShapeRenderer,
  data,
  labelFormatter,
  LabelRenderer,
  legendContentRenderer,
  legend = false,
  strokeWidth = '1px',
}: Props): ReactElement {
  const setHoverShape = useSetHoverShapeIndex();
  const hoverIndex = useHoverShapeIndex();
  return (
    <ResponsiveContainer width={'100%'} height={'100%'} debounce={300}>
      <PieChart>
        <Pie data={[{value: 100}]} dataKey={'value'} isAnimationActive={false} cx={'50%'} cy={'50%'}>
          <Cell fill={'#e7eaee'} strokeWidth={'0px'} />
        </Pie>
        <Pie
          activeIndex={Array.from(Array(data.length).keys())}
          data={data}
          animationDuration={240}
          animationBegin={0}
          dataKey={'value'}
          nameKey={'name'}
          cx={'50%'}
          cy={'50%'}
          fill={'#8884d8'}
          labelLine={LabelLineRenderer}
          label={LabelRenderer ? (props) => <LabelRenderer {...props} formatter={labelFormatter} /> : undefined}
          activeShape={ActiveShapeRenderer}
          onMouseOver={(_e, idx) => setHoverShape(idx)}
          onMouseLeave={() => setHoverShape(null)}
        >
          {data.map(({color}, index) => (
            <Cell
              key={`cell-${index}`}
              fill={color}
              strokeWidth={strokeWidth}
              opacity={hoverIndex !== null && hoverIndex !== index ? 0.2 : 1}
            />
          ))}
        </Pie>

        {legend && (
          <Legend
            iconType={'square'}
            layout={'vertical'}
            align={'right'}
            verticalAlign={'middle'}
            content={legendContentRenderer}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}
