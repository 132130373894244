import LinkSvg from '@fortawesome/fontawesome-pro/svgs/solid/external-link-alt.svg';
import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getBillingSumsTotal} from '@octaved/flow/src/Billing/SumsTotal';
import CurrencySymbol from '@octaved/flow/src/Components/CurrencySymbol/CurrencySymbol';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useBillings} from '@octaved/flow/src/Modules/Billing/Billings';
import {useBillingSearch} from '@octaved/flow/src/Modules/Billing/BillingSearch';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {renderBillingTimePeriod} from '@octaved/flow/src/Pages/Projects/Pages/BillingsList/TimePeriodFormatted';
import {getBillingListRoute} from '@octaved/flow/src/Routing/Routes/Billing';
import {withAncestors, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {cn, HelpTooltip, Icon, Message, Tooltip} from '@octaved/ui';
import {timestampToFormatString} from '@octaved/users/src/Culture/DateFormatFunctions';
import {format} from '@octaved/users/src/Culture/NumberFormatter';
import {dateTimeFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Loader} from 'semantic-ui-react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Placeholder from '../../PlaceholderText';

const helpToken: Record<string, string> = {
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'nodeDetails:field.billings.label.help.workPackage',
  [EnumFlowNodeType.VALUE_GROUP]: 'nodeDetails:field.billings.label.help.group',
  [EnumFlowNodeType.VALUE_PROJECT]: 'nodeDetails:field.billings.label.help.project',
};

export interface NodeLastBillingsProps {
  node: NodeType;
}

export default function NodeBillings({
  frame: Frame,
  node,
}: NodeLastBillingsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const dateTimeFormat = useSelector(dateTimeFormatSelector);
  const {hasLoadedOnce: hasLoadedBillingIds, ids} = useBillingSearch(['rootNodeId', node.id]);
  const {hasLoadedOnce: hasLoadedNonBilled, nodeIds: nonBilledWpIds} = useCombinedNodeSearch({
    and: [
      withDescendants(node.id, true),
      {
        and: [
          ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
          withAncestors(['trRefHasNonBilledTimeTrackingRecords'], true),
        ],
      },
    ],
  });
  const {hasLoadedOnce: hasLoadedBillings, entries} = useBillings(ids);
  const hasLoadedOnce = hasLoadedBillingIds && hasLoadedNonBilled && hasLoadedBillings;
  const useTimeTracking = useSelector(useTimeTrackingSelector);

  if (!useTimeTracking) {
    return null;
  }

  const showSums = isProject(node);
  const sorted = entries.toSorted((a, b) => a.createdOn - b.createdOn);
  const hasFinal = !!entries.find(({interval}) => interval === null);
  const hasNonBilled = nonBilledWpIds.length > 0;

  return (
    <Frame
      labelToken={'nodeDetails:field.billings.label'}
      labelAddition={<HelpTooltip toolTipTranslation={helpToken[node.nodeType]} />}
    >
      <Loader active={!hasLoadedOnce} />
      {hasLoadedOnce && entries.length === 0 && <Placeholder text={'nodeDetails:field.billings.placeholder'} />}
      {sorted.length > 0 && (
        <div
          className={cn(
            'grid grid-cols-[repeat(3,max-content)_1fr] items-center gap-x-4 gap-y-3',
            showSums && 'grid-cols-[repeat(4,max-content)_1fr]',
          )}
        >
          {sorted.map((billing) => {
            return (
              <div key={billing.id} className={'group/row contents'}>
                <div>{timestampToFormatString(billing.createdOn, dateTimeFormat)}</div>
                <div>
                  {billing.interval &&
                    t('nodeDetails:field.billings.partialBilling') + ' - ' + renderBillingTimePeriod(billing)}
                  {!billing.interval && t('nodeDetails:field.billings.finalBilling')}
                </div>
                {showSums && (
                  <div className={'text-right'}>
                    {format(getBillingSumsTotal(billing.sums), 2)}&nbsp;
                    <CurrencySymbol />
                  </div>
                )}
                <div className={'linkColumn'}>
                  <Tooltip toolTipTranslation={'nodeDetails:field.billings.openBillingTooltip'}>
                    <Link className={'invisible group-hover/row:visible'} to={getBillingListRoute(billing.id)}>
                      <Icon noMargin>
                        <LinkSvg />
                      </Icon>
                    </Link>
                  </Tooltip>
                </div>
                <div />
              </div>
            );
          })}
        </div>
      )}
      {hasLoadedOnce && (
        <>
          {!hasFinal && hasNonBilled && <Message>{t('nodeDetails:field.billings.openRecordsInfo')}</Message>}
          {hasFinal && hasNonBilled && (
            <Message colorScheme={'error'}>
              {t('nodeDetails:field.billings.openRecordsWithFinalBillingsWarning')}
            </Message>
          )}
        </>
      )}
    </Frame>
  );
}
