import {joinMovements, line, move} from './Movements';
import {Directions} from './Types';

export const arrowSize = 5;
export function drawArrow(x: number, y: number, direction: Directions): string {
  let lineString = '';
  if (direction === 'down') {
    lineString = joinMovements([
      move(x, y),
      line(x + arrowSize, y - arrowSize - 1), //to top right
      line(x - arrowSize, y - arrowSize - 1), //to top left
      line(x, y), //back to bottom
    ]);
  } else if (direction === 'up') {
    lineString = joinMovements([
      move(x, y),
      line(x + arrowSize, y + arrowSize + 1), //to bottom right
      line(x - arrowSize, y + arrowSize + 1), //to bottom left
      line(x, y), //back to top
    ]);
  } else if (direction === 'right') {
    lineString = joinMovements([
      move(x + 3, y),
      line(x - arrowSize - 1 + 3, y - arrowSize), //to top left
      line(x - arrowSize - 1 + 3, y + arrowSize), //to bottom left
      line(x + 3, y), //back to center
    ]);
  } else if (direction === 'left') {
    lineString = joinMovements([
      move(x - 3, y),
      line(x + arrowSize + 1, y - arrowSize), //to top right
      line(x + arrowSize + 1, y + arrowSize), //to bottom right
      line(x - 3, y), //back to center
    ]);
  }

  return lineString;
}
