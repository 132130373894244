import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {getTaskSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {useTaskPatch} from '@octaved/flow/src/Modules/Tasks';
import {
  PlanningType,
  usePlanningType,
} from '@octaved/planning/src/Components/PlanningRealization/PlanningType/PlanningType';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import TaskEstimationSection from '../../../Components/Fields/General/NodeType/Task/Section/TaskEstimationSection';
import NodeDependencySection from '../../../Components/Fields/Planning/Section/NodeDependencySection';
import NodeLogicalDependenciesSection from '../../../Components/Fields/Planning/Section/NodeLogicalDependenciesSection';
import NodePlanningOptionSection from '../../../Components/Fields/Planning/Section/NodePlanningOptionSection';
import NodePlanningStatusSection from '../../../Components/Fields/Planning/Section/NodePlanningStatusSection';
import NodeSuccessorsSection from '../../../Components/Fields/Planning/Section/NodeSuccessorsSection';
import NodeTimePeriodSection from '../../../Components/Fields/Planning/Section/NodeTimePeriodSection';
import ResponsibleUnits from '../../../Components/Fields/Responsible/ResponsibleUnits';
import Section from '../../../Components/Fields/Section';
import InspectorViewFrame from '../../InspectorViewFrame';
import {TaskDetailsProps} from './General';

export default function SpecialPlanning({node: initialNode}: TaskDetailsProps): ReactElement {
  const node = useSelector(getTaskSelector)(initialNode.id) || initialNode;
  const readonly = !useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) || node.isArchived;
  const rootGroupType = useSelector(getRootGroupTypeSelector)(node.id);
  const isUserStory = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
  const isBacklog = rootGroupType === EnumFlowGroupType.VALUE_BACKLOG;
  const canPlan = !isUserStory && !isBacklog;
  const [planningType, setPlanningType] = usePlanningType(node);
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const canReadEffort = useIsGranted('FLOW_NODE_TASK_READ_EFFORT', node.id);
  const canReadAssignments = useIsGranted('FLOW_NODE_TASK_READ_RESPONSIBLE', node.id);
  const patch = useTaskPatch(node.id);

  return (
    <InspectorViewFrame>
      <NodePlanningStatusSection node={node} />

      <NodePlanningOptionSection
        node={node}
        readonly={!canPlan || readonly}
        planningType={planningType}
        setPlanningType={setPlanningType}
      />

      {planningType === PlanningType.dependency && (
        <NodeDependencySection node={node} readonly={!canPlan || readonly} />
      )}
      {planningType !== PlanningType.none && <NodeTimePeriodSection node={node} readonly={!canPlan || readonly} />}

      {/* TODO: re-enable once the feature is implemented #1721 */}
      {/* {planningType === PlanningType.relativeToParent && <NodeParentDependencyTypeSection node={node} />} */}

      <NodeLogicalDependenciesSection node={node} readonly={!canPlan || readonly} />
      <NodeSuccessorsSection node={node} />
      {canReadAssignments && <ResponsibleUnits node={node} frame={Section} />}
      {canReadEffort && (
        <TaskEstimationSection
          id={node.id}
          readonly={!canManageBasic}
          plannedTime={node.plannedTime}
          patch={(plannedTime) => patch({plannedTime})}
        />
      )}
    </InspectorViewFrame>
  );
}
