import {bulkPatchNodesResponsibilities} from '@octaved/flow-api';
import {EventData, NodesResponsibilitiesBulkPatchEvent} from '@octaved/flow/src/Modules/Events';
import {ResponsiblePatches} from '@octaved/flow/src/Modules/ResponsibleNode';
import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {PlanningSimulationSnapshot} from '../../Modules/SimulationSnapshot';
import {ActionMap} from './MergeSimulationActions';

type PatchNodesResponsibilitiesRequest = CallAction<{data: ResponsiblePatches}> &
  EventData<NodesResponsibilitiesBulkPatchEvent>;

export function isPatchNodesResponsibilitiesRequest(
  action: CallAction | undefined,
): action is PatchNodesResponsibilitiesRequest {
  return action?.[CALL_API].endpoint === bulkPatchNodesResponsibilities;
}

export function mergePatchNodesResponsibilitiesRequest(
  actionMap: ActionMap,
  snapshot: PlanningSimulationSnapshot,
  action: CallAction,
): boolean {
  if (!isPatchNodesResponsibilitiesRequest(action)) {
    return false;
  }
  const {
    [CALL_API]: {endpoint: key},
  } = action;
  const prevAction = actionMap.get(key);
  let mergeResult: PatchNodesResponsibilitiesRequest;
  if (isPatchNodesResponsibilitiesRequest(prevAction)) {
    mergeResult = createMergedAction(prevAction, action);
  } else {
    mergeResult = action;
  }
  for (const nodeId of Object.keys(action[CALL_API].options!.data)) {
    if (!snapshot.changedNodeIds.includes(nodeId)) {
      snapshot.changedNodeIds.push(nodeId);
    }
  }
  actionMap.set(key, mergeResult);

  return true;
}

function createMergedAction(
  actionA: PatchNodesResponsibilitiesRequest,
  actionB: PatchNodesResponsibilitiesRequest,
): PatchNodesResponsibilitiesRequest {
  return {
    ...actionB,
    [CALL_API]: {
      ...actionB[CALL_API],
      options: {
        ...actionB[CALL_API].options,
        data: {...actionA[CALL_API].options!.data, ...actionB[CALL_API].options!.data},
      },
    },
    patches: {...actionA.patches, ...actionB.patches},
  };
}
