import {isDebug, isDevLocal} from '@octaved/env/src/Environment';
import {hasFeature} from '@octaved/env/src/Features';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {AccessibleItem} from '../../AccessibleItem';
import SystemSettingsRedirect from '../../Pages/SystemSettings/SystemSettingsRedirect';
import {dialogRouteObjects} from './Dialogs';
import {groupInspectorRoutes} from './Inspectors/SystemSettings/GroupInspector';
import {guestRoleInspectorRoutes} from './Inspectors/SystemSettings/GuestRoleInspector';
import {materialResourcesInspectorRoutes} from './Inspectors/SystemSettings/MaterialResourcesInspector';
import {permissionInspectorRoutes} from './Inspectors/SystemSettings/PermissionInspector';
import {priceCategoryInspectorRoutes} from './Inspectors/SystemSettings/PriceCategoryInspector';
import {priceSurchargeInspectorRoutes} from './Inspectors/SystemSettings/PriceSurchargeInspector';
import {projectFolderInspectorRoutes} from './Inspectors/SystemSettings/ProjectFolderInspector';
import {projectRoleInspectorRoutes} from './Inspectors/SystemSettings/ProjectRoleInspector';
import {securityInspectorRoutes} from './Inspectors/SystemSettings/SecurityInspector';

const paths = {
  audit: 'audit',
  billingGeneral: 'billingGeneral',
  docs: 'docs',
  fileExport: 'fileExport',
  general: 'general',
  group: 'group',
  guestRoles: 'guestRoles',
  ldap: 'ldap',
  license: 'license',
  materialResources: 'materialResources',
  overrun: 'overrun',
  permissionMatrix: 'permissionMatrix',
  permissions: 'permissions',
  priceCategories: 'priceCategories',
  priceSurcharge: 'priceSurcharge',
  projectFolders: 'projectFolders',
  projectRole: 'projectRole',
  security: 'security',
  version: 'version',
  webhooks: 'webhooks',
  workflows: 'workflows',
};

export type SystemSettingsPage = keyof typeof paths;

export function getSystemSettingsPagePath(page: SystemSettingsPage): string {
  return paths[page];
}

const Audit = lazy(() => import('../../Pages/SystemSettings/Audit/Audit'));
const BillingGeneral = lazy(() => import('../../Pages/SystemSettings/BillingGeneral/BillingGeneral'));
const Docs = lazy(() => import('../../Pages/SystemSettings/Docs/Docs'));
const FileExport = lazy(() => import('../../Pages/SystemSettings/FileExport/FileExport'));
const General = lazy(() => import('../../Pages/SystemSettings/General/General'));
const Group = lazy(() => import('../../Pages/SystemSettings/Group/Group'));
const LdapSystemSettingsPage = lazy(() => import('@octaved/ldap/src/SystemSettings/LdapSystemSettingsPage'));
const LicenseSystemSettingsPage = lazy(() => import('@octaved/license/src/SystemSettings/LicenseSystemSettingsPage'));
const VersionInfoSettingsPage = lazy(() => import('@octaved/license/src/SystemSettings/VersionInfoSettingsPage'));
const MaterialResources = lazy(() => import('../../Pages/SystemSettings/MaterialResources/MaterialResources'));
const Overrun = lazy(() => import('../../Pages/SystemSettings/Overrun/Overrun'));
const Permission = lazy(() => import('../../Pages/SystemSettings/Permission/Permission'));
const PermissionMatrix = lazy(() => import('../../Pages/SystemSettings/PermissionMatrix/PermissionMatrix'));
const PriceCategories = lazy(() => import('../../Pages/SystemSettings/PriceCategories/PriceCategories'));
const PriceSurcharges = lazy(() => import('../../Pages/SystemSettings/PriceSurcharges/PriceSurcharges'));
const ProjectFolders = lazy(() => import('../../Pages/SystemSettings/ProjectFolders/ProjectFolders'));
const ProjectRole = lazy(() => import('../../Pages/SystemSettings/ProjectRole/ProjectRole'));
const GuestRole = lazy(() => import('../../Pages/SystemSettings/GuestRole/GuestRole'));
const Security = lazy(() => import('../../Pages/SystemSettings/Security/Security'));
const Webhooks = lazy(() => import('../../Pages/SystemSettings/Webhooks/Webhooks'));

export const systemSettingsRoutes: RouteObject[] = [
  {
    children: groupInspectorRoutes,
    element: <Group />,
    path: `${paths.group}/*`,
  },
  {
    children: priceCategoryInspectorRoutes,
    element: <PriceCategories />,
    path: `${paths.priceCategories}/*`,
  },
  {
    children: priceSurchargeInspectorRoutes,
    element: <PriceSurcharges />,
    path: `${paths.priceSurcharge}/*`,
  },
  {
    children: projectFolderInspectorRoutes,
    element: <ProjectFolders />,
    path: `${paths.projectFolders}/*`,
  },
  {
    children: materialResourcesInspectorRoutes,
    element: <MaterialResources />,
    path: `${paths.materialResources}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <General />,
    path: `${paths.general}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <BillingGeneral />,
    path: `${paths.billingGeneral}/*`,
  },
  {
    children: projectRoleInspectorRoutes,
    element: <ProjectRole />,
    path: `${paths.projectRole}/*`,
  },
  {
    children: guestRoleInspectorRoutes,
    element: <GuestRole />,
    path: `${paths.guestRoles}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <Overrun />,
    path: `${paths.overrun}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <FileExport />,
    path: `${paths.fileExport}/*`,
  },
  {
    children: securityInspectorRoutes,
    element: <Security />,
    path: `${paths.security}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <LdapSystemSettingsPage />,
    path: `${paths.ldap}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <LicenseSystemSettingsPage />,
    path: `${paths.license}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <VersionInfoSettingsPage />,
    path: `${paths.version}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <Webhooks />,
    path: `${paths.webhooks}/*`,
  },
  {
    children: permissionInspectorRoutes,
    element: <Permission />,
    path: `${paths.permissions}/*`,
  },
  {
    element: <PermissionMatrix />,
    path: `${paths.permissionMatrix}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <Docs />,
    path: `${paths.docs}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <Audit />,
    path: `${paths.audit}/*`,
  },
  {path: '*', element: <SystemSettingsRedirect />},
];

export interface SystemSettingsMenuOption extends AccessibleItem {
  disabled?: boolean;
  header?: boolean;
  name: string;
  path?: string;
}

export const menuOptions: SystemSettingsMenuOption[] = [
  {
    access: ({isGranted, settings}) =>
      isGranted('FLOW_GLOBAL_GUEST_USERS_MANAGE') ||
      isGranted('FLOW_GLOBAL_INTERNAL_USERS_MANAGE') ||
      isGranted('FLOW_GLOBAL_PROJECT_ROLES_MANAGE') ||
      isGranted('FLOW_GLOBAL_GUEST_PERMISSION_ROLES_READ') ||
      isGranted('FLOW_GLOBAL_GLOBAL_PERMISSIONS_MANAGE') ||
      isGranted('FLOW_GLOBAL_PROJECT_FOLDERS_MANAGE') ||
      isGranted('FLOW_GLOBAL_MATERIAL_RESOURCES_MANAGE') ||
      (settings.webhooksEnabled && isGranted('FLOW_GLOBAL_WEBHOOKS_MANAGE')) ||
      (isDevLocal && isGranted('FLOW_GLOBAL_AUDIT_READ')) ||
      true, // TODO: To be removed when docs is moved to website
    header: true,
    name: 'systemSettings:menu.header.system',
  },
  {
    access: 'FLOW_GLOBAL_GENERAL_SETTINGS_MANAGE',
    name: 'systemSettings:menu.general',
    path: paths.general,
  },
  {
    access: 'FLOW_GLOBAL_USER_GROUPS_MANAGE',
    name: 'systemSettings:menu.group',
    path: paths.group,
  },
  {
    access: 'FLOW_GLOBAL_PROJECT_ROLES_MANAGE',
    name: 'systemSettings:menu.projectRole',
    path: paths.projectRole,
  },
  {
    access: 'FLOW_GLOBAL_GUEST_PERMISSION_ROLES_READ',
    name: 'guestUser:rolesPage.menuName',
    path: paths.guestRoles,
  },
  {
    access: 'FLOW_GLOBAL_GLOBAL_PERMISSIONS_MANAGE',
    name: 'systemSettings:menu.permissions',
    path: paths.permissions,
  },
  {
    access: ({isGranted}) => hasFeature('permissionMatrix') && isGranted('FLOW_GLOBAL_INTERNAL_PERMISSION_ROLES_READ'),
    name: 'Perm-Matrix',
    path: paths.permissionMatrix,
  },
  {
    access: 'FLOW_GLOBAL_PROJECT_FOLDERS_MANAGE',
    name: 'systemSettings:menu.flowOrganizations',
    path: paths.projectFolders,
  },
  {
    access: 'FLOW_GLOBAL_MATERIAL_RESOURCES_MANAGE',
    name: 'systemSettings:menu.materialResources',
    path: paths.materialResources,
  },
  {
    access: ({isGranted, settings}) => settings.webhooksEnabled && isGranted('FLOW_GLOBAL_WEBHOOKS_MANAGE'),
    name: 'systemSettings:webhooks.title',
    path: paths.webhooks,
  },
  {
    //Temporarily disabled, see https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/401
    access: ({isGranted}) => isDebug && isGranted('FLOW_GLOBAL_SECURITY_SETTINGS_MANAGE'),
    name: 'systemSettings:menu.security',
    path: paths.security,
  },
  {
    access: ({isGranted, settings: {isLdapEnabled}}) => isLdapEnabled && isGranted('FLOW_GLOBAL_LDAP_MANAGE'),
    name: 'systemSettings:menu.ldap',
    path: paths.ldap,
  },
  {
    name: 'systemSettings:menu.docs',
    path: paths.docs,
  },
  {
    access: ({isGranted}) => isDevLocal && isGranted('FLOW_GLOBAL_AUDIT_READ'),
    name: 'systemSettings:menu.audit',
    path: paths.audit,
  },
  {
    access: 'FLOW_GLOBAL_LICENSE_READ',
    name: 'systemSettings:menu.license',
    path: paths.license,
  },
  {
    access: ({licenseLevel}) => licenseLevel === 'onPremise',
    name: 'systemSettings:menu.version',
    path: paths.version,
  },
  {
    access: ({isGranted, settings: {useTimeTracking}}) =>
      useTimeTracking &&
      (isGranted('FLOW_GLOBAL_GENERAL_SETTINGS_MANAGE') || isGranted('FLOW_GLOBAL_PRICE_CATEGORIES_MANAGE')),
    header: true,
    name: 'systemSettings:menu.header.billing',
  },
  {
    access: ({isGranted, settings: {useTimeTracking}}) =>
      useTimeTracking && isGranted('FLOW_GLOBAL_GENERAL_SETTINGS_MANAGE'),
    name: 'systemSettings:menu.billingGeneral',
    path: paths.billingGeneral,
  },
  {
    access: ({isGranted, settings: {useTimeTracking}}) =>
      useTimeTracking && isGranted('FLOW_GLOBAL_GENERAL_SETTINGS_MANAGE'),
    name: 'systemSettings:menu.fileExport',
    path: paths.fileExport,
  },
  {
    access: ({isGranted, settings: {useTimeTracking}}) =>
      useTimeTracking && isGranted('FLOW_GLOBAL_PRICE_CATEGORIES_MANAGE'),
    name: 'systemSettings:menu.hourlyRates',
    path: paths.priceCategories,
  },
  {
    access: ({isGranted, settings: {useTimeTracking}}) =>
      useTimeTracking && isGranted('FLOW_GLOBAL_PRICE_CATEGORIES_MANAGE'),
    name: 'systemSettings:menu.priceSurcharges',
    path: paths.priceSurcharge,
  },
  {
    access: ({isGranted, settings: {useTimeTracking}}) =>
      useTimeTracking && isGranted('FLOW_GLOBAL_GENERAL_SETTINGS_MANAGE'),
    name: 'systemSettings:menu.overrun',
    path: paths.overrun,
  },
];
