import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Circle, Folder, LucideIcon, LucideProps, Squircle} from 'lucide-react';
import {ReactElement} from 'react';
import {ProjectIcon, SubProjectIcon, SubWorkPackageIcon, TaskIcon, WorkPackageIcon} from '.';
import {cn} from '../Hooks';

const iconMap: Record<string, LucideIcon> = {
  [EnumFlowNodeType.VALUE_PROJECT_FOLDER]: Folder,
  [EnumFlowNodeType.VALUE_PROJECT]: ProjectIcon,
  [EnumFlowNodeType.VALUE_GROUP]: SubProjectIcon,
  [EnumFlowNodeType.VALUE_TASK]: TaskIcon,
  [EnumFlowNodeType.VALUE_TASK_SECTION]: Squircle,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: WorkPackageIcon,
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: SubWorkPackageIcon,
};

export interface NodeIconProps extends LucideProps {
  className?: string;
  nodeType: EnumFlowNodeType;
}

export function NodeIcon({className, nodeType, ...props}: NodeIconProps): ReactElement {
  const NodeTypeIcon = iconMap[nodeType] || Circle;

  return <NodeTypeIcon className={cn('size-4', className)} strokeWidth={1.5} {...props} />;
}
