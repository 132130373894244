import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';

export const nodeTypeTokens: Record<EnumFlowNodeType, string> = {
  [EnumFlowNodeType.VALUE_BOARD_POST]: 'general:nodeType.boardPost',
  [EnumFlowNodeType.VALUE_GROUP]: 'general:nodeType.group',
  [EnumFlowNodeType.VALUE_MATERIAL_RESOURCE]: 'general:nodeType.materialResource',
  [EnumFlowNodeType.VALUE_MATERIAL_RESOURCE_FOLDER]: 'general:nodeType.materialResourceFolder',
  [EnumFlowNodeType.VALUE_PROJECT]: 'general:nodeType.project',
  [EnumFlowNodeType.VALUE_PROJECT_FOLDER]: 'general:nodeType.projectFolder',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'general:nodeType.subWorkPackage',
  [EnumFlowNodeType.VALUE_TASK]: 'general:nodeType.task',
  [EnumFlowNodeType.VALUE_TASK_SECTION]: 'general:nodeType.taskSection',
  [EnumFlowNodeType.VALUE_USER_NODE]: 'general:nodeType.userNode',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'general:nodeType.workPackage',
};

export const nodeTypeCountTokens: Record<EnumFlowNodeType, string> = {
  [EnumFlowNodeType.VALUE_BOARD_POST]: 'general:counts.boardPosts',
  [EnumFlowNodeType.VALUE_GROUP]: 'general:counts.subprojects',
  [EnumFlowNodeType.VALUE_MATERIAL_RESOURCE]: '',
  [EnumFlowNodeType.VALUE_MATERIAL_RESOURCE_FOLDER]: '',
  [EnumFlowNodeType.VALUE_PROJECT]: 'general:counts.projects',
  [EnumFlowNodeType.VALUE_PROJECT_FOLDER]: 'general:counts.projectFolders',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'general:counts.subWorkPackages',
  [EnumFlowNodeType.VALUE_TASK]: 'general:counts.tasks',
  [EnumFlowNodeType.VALUE_TASK_SECTION]: 'general:counts.taskSections',
  [EnumFlowNodeType.VALUE_USER_NODE]: '',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'general:counts.workPackages',
};

export const nodeTypeSet: ReadonlySet<EnumFlowNodeType> = new Set(Object.values(EnumFlowNodeType));
