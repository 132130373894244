import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {
  EnumExecutionStatus,
  executionStatusTokens,
  executionStatusTooltipTokensSelector,
  isWorkPackageNotStartedQuerySelector,
  isWorkPackageStartedQuerySelector,
} from '@octaved/flow/src/WorkPackage/ExecutionStatus';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {PieChartWithLegend, PieChartWithLegendEntry} from '@octaved/ui';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {controllingIncludeArchivedInChartsSelector} from '../../../../Selectors/Ui/UiStateSelectors';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

function useQueries(nodeId: Uuid): {
  executedIds: ReadonlyArray<Uuid>;
  notStartedIds: ReadonlyArray<Uuid>;
  startedIds: ReadonlyArray<Uuid>;
} {
  const isWorkPackageStartedQuery = useSelector(isWorkPackageStartedQuerySelector);
  const isWorkPackageNotStartedQuery = useSelector(isWorkPackageNotStartedQuerySelector);
  const includeArchived = useSelector(controllingIncludeArchivedInChartsSelector);
  const baseConditions: NodeSearchCondition[] = [
    withDescendants(nodeId, true),
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    {not: ['wpIsOffer']},
  ];
  if (!includeArchived) {
    baseConditions.push({not: ['isArchived']});
  }
  const notStartedQuery: NodeSearchCondition = {and: [...baseConditions, isWorkPackageNotStartedQuery]};
  const startedQuery: NodeSearchCondition = {and: [...baseConditions, isWorkPackageStartedQuery]};
  const exectuedQuery: NodeSearchCondition = {and: [...baseConditions, ['wpIsCompleted']]};
  const [{ids: executedIds}, {ids: notStartedIds}, {ids: startedIds}] = useCombinedNodeSearches(
    undefined,
    exectuedQuery,
    notStartedQuery,
    startedQuery,
  );
  return {executedIds, notStartedIds, startedIds};
}

export interface NodeWorkPackagesProgressProps {
  nodeId: Uuid;
}

export function NodeWorkPackagesProgress({
  frame: Frame,
  nodeId,
}: NodeWorkPackagesProgressProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const {executedIds, notStartedIds, startedIds} = useQueries(nodeId);
  const toolTips = useSelector(executionStatusTooltipTokensSelector);

  const pieces = useMemo((): PieChartWithLegendEntry[] => {
    return [
      {
        color: '#808080',
        name: t(executionStatusTokens[EnumExecutionStatus.notStarted]),
        toolTip: toolTips[EnumExecutionStatus.notStarted],
        value: notStartedIds.length,
      },
      {
        color: '#2970A3',
        name: t(executionStatusTokens[EnumExecutionStatus.started]),
        toolTip: toolTips[EnumExecutionStatus.started],
        value: startedIds.length,
      },
      {
        color: '#3cb371',
        name: t(executionStatusTokens[EnumExecutionStatus.executed]),
        toolTip: toolTips[EnumExecutionStatus.executed],
        value: executedIds.length,
      },
    ];
  }, [t, toolTips, notStartedIds.length, startedIds.length, executedIds.length]);

  return (
    <Frame labelToken={'nodeDetails:field.workpackageProgress.label'}>
      <PieChartWithLegend pieces={pieces} />
    </Frame>
  );
}
