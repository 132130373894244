import {cn} from '@octaved/ui';
import SimplePieChart, {
  ReChartPieChartDataSet,
} from '@octaved/ui-components/src/React/ReCharts/PieChart/SimplePieChart';
import {PropsWithChildren, ReactElement} from 'react';
import {HoverableShapeContextProvider} from '../HoverShapeContext';

export type ChartContainerPieSizes = 'sm' | 'md' | 'lg';

export interface ChartContainerProps {
  data: ReChartPieChartDataSet[];
  size?: ChartContainerPieSizes;
}

export default function ChartContainer({
  children,
  data,
  size = 'md',
}: PropsWithChildren<ChartContainerProps>): ReactElement {
  return (
    <HoverableShapeContextProvider>
      <div className={'flex items-center gap-x-4'}>
        <div
          className={cn(
            'flex-shrink-0 -rotate-90',
            size === 'sm' && '-ml-2 h-10 w-10',
            size === 'md' && '-ml-3 h-16 w-16',
            size === 'lg' && '-ml-4 h-28 w-28',
          )}
        >
          <SimplePieChart data={data} strokeWidth={'.5px'} />
        </div>
        <div>{children}</div>
      </div>
    </HoverableShapeContextProvider>
  );
}
