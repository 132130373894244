import {FLOW_REMOVE_TASK_REQUEST} from '@octaved/flow/src/Modules/ActionTypes';
import {TaskRemoveEvent} from '@octaved/flow/src/Modules/Events';
import {UnknownAction, isAction} from 'redux';
import UpdateWorkloadContext from './UpdateWorkloadContext';

function isRemoveTaskRequestAction(action: unknown): action is TaskRemoveEvent {
  return isAction(action) && action.type === FLOW_REMOVE_TASK_REQUEST;
}

export function handleRemoveRequestAction(context: UpdateWorkloadContext, action: UnknownAction): boolean {
  const canHandle = isRemoveTaskRequestAction(action);
  if (isRemoveTaskRequestAction(action)) {
    for (const nodeId of action.nodeIds) {
      context.updateWorkloadForNode(nodeId);
    }
  }
  return canHandle;
}
