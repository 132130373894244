import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateTimeStr} from '@octaved/typescript';
import {isoDateTimeToIsoFormat, isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import {Rect} from 'konva/lib/shapes/Rect';
import {calculateDateLimitsByDate, resizeBar, ResizeStartpoint} from '../../../../Calculations/BarMovement';
import {addPlanningDate} from '../../../../Modules/PlanningDates';
import {getPlanningDatesForNodeSelector} from '../../../../Selectors/PlanningDateSelectors';
import {Bar} from '../../../Bars/Bar';
import {BarPlugin} from '../../../Bars/Plugins/BarPlugin';
import {setMouseHoverEffect} from '../../../MouseHoverCursor';
import {MouseMove, MouseMoveEvent} from '../../../MouseMove';
import {GhostBar} from '../GhostBar';

function getDirection(startPosition: number, currentPosition: number): ResizeStartpoint {
  return startPosition > currentPosition ? ResizeStartpoint.left : ResizeStartpoint.right;
}

export class GhostDraggerPlugin extends BarPlugin {
  #isDragging = false;
  #limitStart: null | DateTimeStr = null;
  #limitEnd: null | DateTimeStr = null;
  #plannedStart: DateTimeStr | null = null;
  #mouseMove: MouseMove | null = null;

  static readonly lockIdent = 'ghost-';

  #rect = new Rect({fill: 'transparent'});

  init(bar: Bar): void {
    super.init(bar);

    this.root.add(this.#rect);
    setMouseHoverEffect(this.#rect, 'pointer');

    const mouseMove = new MouseMove({
      eventNode: this.#rect,
      onEnd: this.#onFinish,
      onMove: this.#onMove,
      onStart: this.#onStart,
    });
    mouseMove.init();
    this.#mouseMove = mouseMove;

    this.disposables.push(() => mouseMove.dispose());

    this.#rect.height(this.bar.height + this.bar.barPaddingY * 2);
    this.#rect.width(this.bar.width + this.bar.barPaddingX * 2);
    this.#rect.offsetX(this.bar.barPaddingX);
    this.#rect.offsetY(this.bar.barPaddingY);
  }

  getLockIdent(): string {
    return `${GhostDraggerPlugin.lockIdent}${this.nodeId}`;
  }

  onBarUpdated(): void {
    this.#rect.width(this.bar.width + this.bar.barPaddingX * 2);
  }

  #onStart = (): void => {
    if (this.ctx.hasEditLock()) {
      this.#mouseMove?.cancel();
      return;
    }
    if (this.bar instanceof GhostBar) {
      const state = this.ctx.store.getState();
      const planningDates = getPlanningDatesForNodeSelector(state)(this.nodeId);
      const {end, start} = calculateDateLimitsByDate(this.bar.plannedStart, this.bar.plannedStart, planningDates);
      if (end && end === start) {
        // prevent creating of new bars if there is no space
        return;
      }

      this.#limitStart = start;
      this.#limitEnd = end;
      this.#plannedStart = this.bar.plannedStart;

      this.ctx.onMoveCloseInspector(this.nodeId);
      this.ctx.editLockIdent = this.getLockIdent();
    }
  };

  #onMove = (e: MouseMoveEvent): void => {
    if (this.ctx.editLockIdent === this.getLockIdent()) {
      const result = this.#getMoveBarResult(e);
      if (result) {
        this.bar.setDates(result.plannedStart, result.plannedEnd);
      }
    } else {
      this.#mouseMove?.cancel();
    }
  };
  #onFinish = async (e: MouseMoveEvent): Promise<void> => {
    const result = this.#getMoveBarResult(e);
    if (result) {
      const planningDateId = generateUuid();
      await this.ctx.store.dispatch(
        addPlanningDate(this.nodeId, planningDateId, result.plannedStart, result.plannedEnd, true),
      );
      if (this.bar.treeNode.type === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
        this.ctx.setInspectorNodeId(planningDateId, this.bar.treeNode.type);
      }
      this.bar.root.hide();
    }
    requestAnimationFrame(() => {
      //delay reset so that the click is handeld
      this.ctx.editLockIdent = null;
    });
  };

  #getMoveBarResult(e: MouseMoveEvent): {
    plannedStart: DateTimeStr;
    plannedEnd: DateTimeStr;
  } | null {
    const state = this.ctx.store.getState();
    const getWorkHoursAtDate = getOrgWorkMinutesAtDateSelector(state);
    if (this.#plannedStart) {
      const direction = getDirection(e.startX, e.mouseX);
      const result = resizeBar(
        e.startX,
        e.mouseX,
        direction,
        isoDateTimeToIsoFormat(this.#plannedStart),
        isoDateTimeToIsoFormat(this.#plannedStart),
        this.ctx.columnWidth,
        this.#limitStart,
        this.#limitEnd,
        getWorkHoursAtDate,
      );
      return {
        plannedEnd: isoToIsoDateTimeFormat(result.plannedEnd),
        plannedStart: isoToIsoDateTimeFormat(result.plannedStart),
      };
    }
    return null;
  }

  get isDragging(): boolean {
    return this.#isDragging;
  }
}
