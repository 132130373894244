import {useCallback, useEffect, useRef, useState} from 'react';

export function useAsyncExecution<R, A extends unknown[]>(
  cb: (...rest: A) => R | Promise<R>,
): [(...rest: A) => Promise<R | undefined>, boolean, unknown] {
  const [isExecuting, setExecuting] = useState<boolean>(false);
  const isExecutingRef = useRef(false);
  const [error, setError] = useState<unknown>('');

  const cbRef = useRef(cb);
  cbRef.current = cb;

  const disposed = useRef(false);
  useEffect(() => {
    return () => {
      disposed.current = true;
    };
  }, []);

  const execute = useCallback(async (...rest: A): Promise<undefined | R> => {
    if (isExecutingRef.current) {
      return;
    }
    isExecutingRef.current = true;
    setExecuting(isExecutingRef.current);
    try {
      return await cbRef.current(...rest);
    } catch (e) {
      if (!disposed.current) {
        setError(e);
      }
    } finally {
      if (!disposed.current) {
        isExecutingRef.current = false;
        setExecuting(isExecutingRef.current);
      }
    }
    return;
  }, []);

  return [execute, isExecuting, error];
}
