import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import classNames from 'classnames';
import {forwardRef, Fragment, ReactElement, useContext} from 'react';
import {
  ProjectTreeGroup,
  ProjectTreeProject,
  ProjectTreeWorkPackage,
} from '../../../Modules/Projects/ProjectTreeInterfaces';
import {useProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';
import CanvasAddWorkPackageButton from '../Components/CanvasAddWorkPackageButton';
import {projectsTreeComponentContext} from '../ProjectTreeComponentContext';

export interface GroupsAndWorkPackagesProps {
  parentNode: ProjectTreeProject | ProjectTreeGroup;
}

export default forwardRef<HTMLDivElement, GroupsAndWorkPackagesProps>(function GroupsAndWorkPackages(
  {parentNode}: GroupsAndWorkPackagesProps,
  ref,
): ReactElement {
  const {compactView} = useProjectTreeContext();
  const {group: Group, workPackage: WorkPackage} = useContext(projectsTreeComponentContext);
  const children = parentNode.children;

  return (
    <div className={classNames('groupsAndWorkPackages', {compactView})} ref={ref} data-id={parentNode.id}>
      {children.map((node, index) => {
        let result: ReactElement | null = null;
        if (node.type === EnumFlowNodeType.VALUE_GROUP) {
          result = (
            <Fragment key={node.id}>
              <Group node={node as ProjectTreeGroup} index={index} parentId={parentNode.id} />
            </Fragment>
          );
        }
        if (node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
          result = (
            <WorkPackage key={node.id} node={node as ProjectTreeWorkPackage} index={index} parentId={parentNode.id} />
          );
        }
        return result;
      })}

      {children.length === 0 && parentNode.entity && (
        <CanvasAddWorkPackageButton parent={parentNode.entity} relativeNodeId={parentNode.id} />
      )}

      {/**#region css */}
      {/*language=SCSS*/}
      <style jsx>{`
        .groupsAndWorkPackages {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding-left: 18px;
          min-height: 10px;
        }

        .compactView {
          min-height: initial;
        }
      `}</style>
      {/**#endregion */}
    </div>
  );
});
