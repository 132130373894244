import {DateStr} from '@octaved/typescript';
import {fromIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import {ReactElement} from 'react';

interface DateStringCellProps {
  cell: {value: DateStr | null};
}

export default function DateStringCell({cell: {value}}: DateStringCellProps): ReactElement | null {
  const formateDate = useFormatInWords();
  if (!value) {
    return null;
  }

  return <span>{formateDate(fromIsoFormat(value), 'datetime')}</span>;
}
