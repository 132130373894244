import {UnknownAction} from 'redux';
import {Reducer, ReducerMap} from '../Store';

export default function <T = unknown>(reducerMap: ReducerMap, defaultState: T = {} as T): Reducer {
  if (!(reducerMap instanceof Map)) {
    throw new Error('reducerMap must be a Map!');
  }
  // @ts-ignore this can happen when the action type is imported circular
  if (reducerMap.has(undefined)) {
    throw new Error(
      'This reducerMap has an undefined action type. ' +
        'Maybe an imported action name is not yet available due to circular imports?',
    );
  }

  return (state = defaultState, action: UnknownAction = {type: ''}, entities: object = {}) => {
    let newState = state;
    const types = Array.isArray(action.type) ? action.type : [action.type];
    types.forEach((type) => {
      const reducer = reducerMap.get(type);
      if (reducer) {
        newState = reducer(newState, action, entities);
      }
    });
    return newState;
  };
}

export function addMultiToReducerMap<T extends object = object, A = UnknownAction>(
  reducerMap: ReducerMap<T, A>,
  actionType: string | string[],
  reducer: Reducer<T, A>,
): void {
  const actionTypes = Array.isArray(actionType) ? actionType : [actionType];
  actionTypes.forEach((type) => reducerMap.set(type, reducer));
}
