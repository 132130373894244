import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {HStack, Stack} from '../../../../Layout';

export default function PickerViewLegend(): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'legend'}>
      <Stack spacing={'12px'}>
        <HStack spacing={'8px'}>
          <div className={'square white'} />
          <span>{t('taskList:planning.editPopup.legendWhite')}</span>
        </HStack>
        <HStack spacing={'8px'}>
          <div className={'square gray'} />
          <span>{t('taskList:planning.editPopup.legendGray')}</span>
        </HStack>
        <HStack spacing={'8px'}>
          <div className={'square blue'} />
          <span>{t('taskList:planning.editPopup.legendBlue')}</span>
        </HStack>
        <HStack spacing={'8px'}>
          <div className={'square red'} />
          <span>{t('taskList:planning.editPopup.legendRed')}</span>
        </HStack>
      </Stack>
      <style jsx>{`
        .legend {
          font-size: 10px;
        }

        .square {
          height: 1.2em;
          width: 1.2em;
          border-radius: 2px;
          flex-shrink: 0;
          margin-top: 1px;
        }

        .white {
          background-color: #fff;
        }

        .gray {
          background-color: #ccc;
        }

        .blue {
          background-color: #2c7be5;
        }

        .red {
          background-color: #e52c2c;
        }
      `}</style>
    </div>
  );
}
