import {Stack} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

export default function CopyPlanningHelp(): ReactElement {
  return (
    <Stack spacing={'10px'}>
      <b>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.date.head'} />
      </b>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.date.text-1'} />
      </div>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.date.text-2'} />
      </div>

      <b>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.head'} />
      </b>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.text-1'} />
      </div>
      <ul>
        <li>
          <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.li-1'} />
        </li>
        <li>
          <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.li-2'} />
        </li>
        <li>
          <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.li-3'} />
        </li>
        <li>
          <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.li-4'} />
        </li>
        <li>
          <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.changes.li-5'} />
        </li>
      </ul>

      <b>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.holidays.head'} />
      </b>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.holidays.text-1'} />
      </div>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.holidays.text-2'} />
      </div>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.holidays.text-3'} />
      </div>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.holidays.text-4'} />
      </div>

      <b>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.dependencies.head'} />
      </b>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.dependencies.text-1'} />
      </div>
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.dependencies.text-2'} />
      </div>

      <hr style={{width: '100%'}} />
      <div>
        <Trans i18nKey={'dialogs:duplicatePid.copyPlanning.help.footer'} />
      </div>
    </Stack>
  );
}
