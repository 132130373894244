import {ComponentType, createContext, ReactElement, ReactNode, RefObject, useContext, useRef} from 'react';

interface ProjectFrameContext {
  advancedFilter?: ComponentType;
  contentHeader?: ComponentType;
  contentHeaderVertical?: boolean;
  contentHeaderRef?: RefObject<HTMLDivElement>;
  noProjectFolder?: boolean;
  showCreationButtons?: boolean;
}

export type ProjectFrameDynamicContext = ProjectFrameContext;

const context = createContext<ProjectFrameContext>({});

export function useProjectFrameContext(): ProjectFrameContext {
  return useContext(context);
}

// noinspection FunctionNamingConventionJS
export function ProjectFrameContextProvider({
  children,
  ...dynamicProps
}: ProjectFrameDynamicContext & {children: ReactNode}): ReactElement {
  const contentHeaderRef = useRef<HTMLDivElement>(null);
  return <context.Provider value={{contentHeaderRef, ...dynamicProps}}>{children}</context.Provider>;
}
