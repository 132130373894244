import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {Group, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {isGroup, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, generateUuid} from '@octaved/utilities';
import {isEqual} from 'lodash';
import {PlanningDate, PlanningDatesList} from '../../EntityInterfaces/PlanningDates';
import {PlanningPatchData} from '../../Modules/Planning';
import UpdatePlanningState from './UpdatePlanningState';

export function updatePlanningDates(state: UpdatePlanningState, updateData: PlanningPatchData): void {
  if (updateData.planningDates) {
    state.planningDatesUpdated(updateData.nodeId);
    let node = state.getNode(updateData.nodeId);

    if (node) {
      const oldPlanningDates = node.planningDates;
      const newPlanningIds = updateData.planningDates.map(({id}) => id);
      const removedPlanningDates = oldPlanningDates.filter((id) => !newPlanningIds.includes(id));
      if (!isEqual(node.planningDates, newPlanningIds)) {
        state.setNode({
          ...node,
          planningDates: newPlanningIds,
        });
      }

      for (const planningDate of updateData.planningDates) {
        const oldPlanningDate = state.getPlanningDate(planningDate.id);
        updateAssignedNodes(state, oldPlanningDate, planningDate);
        if (!isEqual(oldPlanningDate, planningDate)) {
          state.setPlanningDate(planningDate);
        }
      }

      for (const removedId of removedPlanningDates) {
        const oldPlanningDate = state.getPlanningDate(removedId);
        removeAssignedNodeId(state, oldPlanningDate);
        state.removePlanningDate(removedId);
      }

      node = state.getNode(updateData.nodeId);
      if (isGroup(node) && node.groupType === EnumFlowGroupType.VALUE_SPRINT) {
        updateGroupChilds(state, node);
      }
    }
  }
}

function updateAssignedNodes(
  state: UpdatePlanningState,
  oldPlanningDate: PlanningDate | null,
  planningDate: PlanningDate,
): void {
  const oldAssignedNodeId = oldPlanningDate?.assignedNodeId || null;
  if (oldAssignedNodeId !== planningDate.assignedNodeId) {
    if (planningDate.assignedNodeId) {
      addAssignedNodeId(state, planningDate);
    }
    if (oldAssignedNodeId) {
      removeAssignedNodeId(state, oldPlanningDate);
    }
  }
}

function removeAssignedNodeId(state: UpdatePlanningState, planningDateToRemove: PlanningDate | null): void {
  const oldAssignedNode = state.getNode(planningDateToRemove?.assignedNodeId);
  if (oldAssignedNode) {
    state.setNode({
      ...oldAssignedNode,
      assignedPlanningDates: oldAssignedNode.assignedPlanningDates.filter(({id}) => id !== planningDateToRemove?.id),
    });
  }
}

function addAssignedNodeId(state: UpdatePlanningState, planningDateToAdd: PlanningDate): void {
  const newAssignedNode = state.getNode(planningDateToAdd.assignedNodeId);
  if (newAssignedNode) {
    state.setNode({
      ...newAssignedNode,
      assignedPlanningDates: [
        ...newAssignedNode.assignedPlanningDates,
        {id: planningDateToAdd.id, nodeId: planningDateToAdd.nodeId},
      ],
    });
  }
}

function getNodesPlanningDatesSorted(state: UpdatePlanningState, node: NodeType): PlanningDatesList {
  return boolFilter(node.planningDates.map((id) => state.getPlanningDate(id))).sort((a, b) =>
    a.plannedStart.localeCompare(b.plannedStart),
  );
}

function updateGroupChilds(state: UpdatePlanningState, node: Group): void {
  const childIds = state.getAllDescendantIds(node.id);
  const planningDates = getNodesPlanningDatesSorted(state, node);
  for (const childId of childIds) {
    const child = state.getNode(childId);
    if (isWorkPackage(child)) {
      updateChild(state, planningDates, child);
    }
  }
}

function updateChild(
  state: UpdatePlanningState,
  sourcePlanningDates: PlanningDatesList,
  targetNode: Task | WorkPackage,
): void {
  const targetPlanningDates = getNodesPlanningDatesSorted(state, targetNode);
  const planningDateIds: Uuid[] = [];
  for (let i = 0; i < sourcePlanningDates.length; ++i) {
    const sourcePlanningDate = sourcePlanningDates[i];
    const targetPlanningDate = targetPlanningDates[i] || null;
    if (targetPlanningDate) {
      const newTargetPlanningDate: PlanningDate = {
        ...targetPlanningDate,
        plannedEnd: sourcePlanningDate.plannedEnd,
        plannedStart: sourcePlanningDate.plannedStart,
      };
      if (!isEqual(newTargetPlanningDate, targetPlanningDate)) {
        state.setPlanningDate(newTargetPlanningDate);
      }
      planningDateIds.push(targetPlanningDate.id);
    } else {
      const id = generateUuid();
      planningDateIds.push(id);
      state.setPlanningDate({
        ...sourcePlanningDate,
        id,
        nodeId: targetNode.id,
      });
    }
  }
  if (!isEqual(planningDateIds, targetNode.planningDates)) {
    state.setNode({
      ...targetNode,
      planningDates: planningDateIds,
    });
    for (const oldPlanningDateId of targetNode.planningDates) {
      if (!planningDateIds.includes(oldPlanningDateId)) {
        state.removePlanningDate(oldPlanningDateId);
      }
    }
  }
}
