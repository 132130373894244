import {DateTimeStr} from '@octaved/typescript';
import {Bar} from '../../Bars/Bar';

export class GhostBar extends Bar {
  #plannedStart: DateTimeStr | null = null;
  #plannedEnd: DateTimeStr | null = null;

  setDates(plannedStart: DateTimeStr, plannedEnd: DateTimeStr): void {
    this.#plannedStart = plannedStart;
    this.#plannedEnd = plannedEnd;
    super.setDates(plannedStart, plannedEnd);
  }

  get plannedStart(): DateTimeStr | null {
    return this.#plannedStart;
  }

  get plannedEnd(): DateTimeStr | null {
    return this.#plannedEnd;
  }
}
