import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {customerByNodeSelector} from '../../../Modules/Selectors/CustomerSelectors';
import {FlowState} from '../../../Modules/State';
import {SearchHighlightText} from '../../Search/SearchHighlight';

interface CustomerNameProps {
  pidId: Uuid | null;
  searchable?: boolean;
}

export default function CustomerNameByPid({pidId, searchable}: CustomerNameProps): ReactElement {
  const name = useSelector((s: FlowState) => {
    const customer = customerByNodeSelector(s)(pidId);
    const name = customer?.name || '';
    const number = customer?.customerNumber || '';
    return number ? `${name} (${number})` : name;
  });
  return searchable ? <SearchHighlightText text={name} /> : <span>{name}</span>;
}
