import {cn} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';

export interface PageContentProps {
  children?: ReactNode;
  contentPadding?: boolean;
  noScroll?: boolean;
  className?: string;
}

export default function PageContent({
  className,
  children,
  contentPadding = false,
  noScroll = false,
}: PageContentProps): ReactElement {
  return (
    <div
      className={cn(
        'relative h-full w-full overflow-y-auto',
        noScroll && 'overflow-y-hidden',
        contentPadding && 'px-[25px] py-[20px]',
        className,
      )}
    >
      {children}
    </div>
  );
}

PageContent.displayName = 'PageContent';
