import css from 'styled-jsx/css';
import {FlowStyleBuilder} from '../../../../Styles/StyleContext';

/*language=SCSS*/
export const style: (maxHeight?: string) => FlowStyleBuilder =
  (maxHeight = '260px') =>
  ({COLORS: {BORDER, LEVITATION, FONT, POPDOWN}}) => css.resolve`
    .popup {
      padding: 0;
      border-radius: 0 !important;
      border: none;
      box-shadow: 2px 2px 15px ${LEVITATION.LEVEL_2};
      z-index: 20;
    }

    .popup.scrollOverflow {
      max-height: ${maxHeight};
      overflow-y: auto;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      cursor: pointer;
      transition: background-color 120ms ease-in-out;
      border-left: 3px solid transparent;
      justify-content: space-between;

      .itemLabel {
        color: ${FONT.STANDARD};

        &.color-red {
          color: #cf1e4d;
        }
      }

      &.disabled {
        cursor: default;
        pointer-events: none;

        .itemLabel {
          color: ${FONT.LIGHT};
        }
      }

      &.selected {
        cursor: default;
        font-weight: bold;
        border-left: 3px solid ${FONT.HIGHLIGHTED};

        .itemLabel {
          color: ${FONT.HIGHLIGHTED};
        }
      }
    }

    .itemLabel {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .keyboardShortCut {
        color: ${FONT.LIGHT};
        font-size: 0.9rem;
        text-align: right;
      }
    }

    .icon {
      width: 20px;
      text-align: center;
      flex-shrink: 0;
    }

    .icon.customIcon {
      width: auto;
    }

    .item:hover,
    .item.hovering {
      background-color: ${POPDOWN.DROPDOWN.ITEM_HOVER};
    }

    .divider {
      padding: 0 4px;
      background-color: ${BORDER.WEAK};
      height: 1px;
      margin: 4px 0;
    }

    .header {
      padding: 12px 12px 5px 12px;
    }

    .header :global(.label) {
      font-size: 12px;
    }

    .subHeader {
      color: var(--color-grey-600);
      margin-top: -4px;
      text-transform: initial;
    }

    .item + .header {
      margin-top: 15px;
    }
  `;
