import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import {ReactElement} from 'react';
import {DndProvider} from 'react-dnd-multi-backend';
import App from './App';

export default function AppWrapper(): ReactElement {
  return (
    <DndProvider options={HTML5toTouch}>
      <App />
    </DndProvider>
  );
}
