import PageStatusBar from '@octaved/flow/src/Pages/PageStatusBar';
import classNames from 'classnames';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import css from 'styled-jsx/css';
import {useHasLicenseBar} from '../License';
import {trialDaysSelector} from '../Selectors/LicenseSelectors';
import LinkToLicensePage from './LinkToLicensePage';

/*language=SCSS*/
const style = css.resolve`
  .row {
    align-items: center;
    color: white;
    display: flex;
    grid-gap: 0.4375rem;
    justify-content: center;
  }
`;

export default function LicenseTrialStatus(): ReactElement | null {
  const {t} = useTranslation();
  const hasLicenseBar = useHasLicenseBar();
  const {daysLeftInTrial, daysLeftToDisplay} = useSelector(trialDaysSelector);

  if (!hasLicenseBar) {
    return null;
  }

  return (
    <PageStatusBar color={'#6b7280'}>
      <LinkToLicensePage className={classNames(style.className, 'row')}>
        {daysLeftInTrial > 0 && (
          <div>
            <span>{t('general:license.trialStatus.inTrial')}</span>
            {' - '}
            <span>{t('general:license.trialStatus.nDaysRemaining', {count: daysLeftToDisplay})}</span>
          </div>
        )}
        {daysLeftInTrial <= 0 && <div>{t('general:license.trialStatus.trialExpired')}</div>}
      </LinkToLicensePage>
      {style.styles}
    </PageStatusBar>
  );
}
