import {cn} from '@octaved/ui';
import {MouseEvent, PropsWithChildren, ReactElement} from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onDark?: boolean;
  underline?: boolean;
  weak?: boolean;
}

export default function Link({
  children,
  className,
  disabled,
  onClick,
  onDark,
  underline,
  weak,
}: PropsWithChildren<Props>): ReactElement {
  return (
    <span
      className={cn(
        'cursor-pointer select-none text-sky-600 hover:underline',
        disabled && 'pointer-events-none cursor-none text-slate-600',
        weak && 'text-slate-600',
        onDark && 'text-white',
        underline && 'underline',
        className,
      )}
      onClick={(e) => !disabled && onClick && onClick(e)}
    >
      {children}
    </span>
  );
}
