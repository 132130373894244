import {Reducer, UnknownAction, isAction} from 'redux';

const MULTI_ACTION = 'MULTI_ACTION';

interface MultiAction extends UnknownAction {
  actions: UnknownAction[];
  type: typeof MULTI_ACTION;
}

function isMultiAction(action: unknown): action is MultiAction {
  return isAction(action) && action.type === MULTI_ACTION;
}

export function createMultiActionReducer(reducer: Reducer): Reducer {
  return (state, action) => {
    if (isMultiAction(action)) {
      return action.actions.reduce((newState, act) => reducer(newState, act), state);
    }
    return reducer(state, action);
  };
}

export function multiAction(actions: UnknownAction[]): MultiAction {
  return {type: MULTI_ACTION, actions};
}
