import {Uuid} from '@octaved/typescript/src/lib';
import * as React from 'react';
import {ReactElement} from 'react';
import {useWorkPackage} from '../../../Modules/Hooks/Nodes';
import WpOrSwpTile, {WpOrSwpTileProps} from './WpOrSwpTile';

interface Props extends WpOrSwpTileProps {
  id: Uuid;
}

export default React.forwardRef<HTMLDivElement, Props>(function WorkPackageTile(
  {id, ...rest}: Props,
  ref,
): ReactElement | null {
  const workPackage = useWorkPackage(id);
  if (!workPackage) {
    return null;
  }
  return <WpOrSwpTile ref={ref} node={workPackage} {...rest} />;
});
