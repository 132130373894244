import {Uuid} from '@octaved/typescript/src/lib';
import {UnitFilterUIState} from '../Ui';

export enum TimeFilter {
  automatic,
  lastWeek,
  lastTwoWeeks,
  custom,
}

export interface TimeRecordTimeFilter {
  type: TimeFilter;
  from: string | null;
  to: string | null;
}

interface TimeRecordingPageFilter {
  customerFilter: Uuid | null;
  search: string;
  timeFilter: TimeRecordTimeFilter;
}

interface TimeRecordingPageFilterWithUnits extends TimeRecordingPageFilter {
  unitFilter: UnitFilterUIState[];
}

export interface TimeRecordsUiState {
  myRecordings: TimeRecordingPageFilter;
  pageSize: 10 | 25 | 100;
  projects: TimeRecordingPageFilterWithUnits;
  showBilledRecords: boolean;
  showOnlyOpen: boolean;
  users: TimeRecordingPageFilterWithUnits;
}

export const initialState: TimeRecordsUiState = {
  myRecordings: {
    customerFilter: null,
    search: '',
    timeFilter: {from: null, to: null, type: TimeFilter.automatic},
  },
  pageSize: 10,
  projects: {
    customerFilter: null,
    search: '',
    timeFilter: {from: null, to: null, type: TimeFilter.automatic},
    unitFilter: [],
  },
  showBilledRecords: false,
  showOnlyOpen: false,
  users: {
    customerFilter: null,
    search: '',
    timeFilter: {from: null, to: null, type: TimeFilter.automatic},
    unitFilter: [],
  },
};
