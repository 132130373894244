import {IconButton, Tooltip} from '@octaved/ui';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import DeactivatableLink from '../../../Components/DeactivatableLink';
import {canCreateGroupSelector} from '../../../Modules/Selectors/GroupSelectors';
import {useGetNewGroupPidDialogRoute} from '../../../Routing/Routes/Dialogs';
import {useInspectorId} from '@octaved/hooks';

export default function CreateGroupButton(): ReactElement | null {
  const inspectorId = useInspectorId();
  const canCreateGroup = useSelector(canCreateGroupSelector)(inspectorId);
  const getNewGroupPidDialogRoute = useGetNewGroupPidDialogRoute();
  return (
    <DeactivatableLink disabled={!canCreateGroup} to={getNewGroupPidDialogRoute(inspectorId!)}>
      <Tooltip disabled={canCreateGroup} toolTipTranslation={'pages:projects.inspector.newGroupTooltip'}>
        <div>
          <IconButton
            size={'sm'}
            icon={<PlusCircle />}
            label={'pages:projects.inspector.newGroup'}
            isDisabled={!canCreateGroup}
            colorScheme={'add'}
          />
        </div>
      </Tooltip>
    </DeactivatableLink>
  );
}
