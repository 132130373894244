import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {EntityState, EntityStates, hasLoadedOnce, isLoaded} from '@octaved/store/src/EntityState';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FlowState} from '../State';

export interface LoadingOnce {
  hasLoadedOnce: boolean;
}

export interface LoadingTracked extends LoadingOnce {
  isLoading: boolean;
}

export function useIsLoading<TrackIsLoading extends boolean>(
  ids: ReadonlyArray<string> | ReadonlySet<string>,
  stateSelector: (state: FlowState) => EntityStates,
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? LoadingTracked : LoadingOnce {
  return useSelector(
    useMemo(() => {
      let loadedOnce = false;
      let lastResult: LoadingTracked;
      return (s: FlowState): LoadingTracked => {
        const state = stateSelector(s);
        const array = [...ids];
        const isLoading = trackIsLoading ? array.length > 0 && array.some((id) => !isLoaded(state[id] || {})) : false;
        loadedOnce = loadedOnce || array.every((id) => hasLoadedOnce(state[id] || {}));
        if (!lastResult || lastResult.hasLoadedOnce !== loadedOnce || lastResult.isLoading !== isLoading) {
          lastResult = {hasLoadedOnce: loadedOnce, isLoading};
        }
        return lastResult;
      };
    }, [ids, stateSelector, trackIsLoading]),
  );
}

export function useLoading<TrackIsLoading extends boolean>(
  ids: ReadonlyArray<string> | ReadonlySet<string>,
  stateSelector: (state: FlowState) => EntityStates,
  loader: (ids: string[]) => ActionDispatcher,
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? LoadingTracked : LoadingOnce {
  useStoreEffect((dispatch) => dispatch(loader([...ids])), [ids, loader], stateSelector);
  return useIsLoading(ids, stateSelector, trackIsLoading);
}

export function useLoadingWithoutIds(
  stateSelector: (state: FlowState) => EntityState,
  loader: () => ActionDispatcher,
): boolean {
  const dispatch = useDispatch();
  const state = useSelector(stateSelector);
  useEffect(() => {
    // noinspection BadExpressionStatementJS
    void state; //reload-dependency
    dispatch(loader());
  }, [dispatch, state, loader]);
  return useMemo(() => !state || isLoaded(state), [state]);
}
