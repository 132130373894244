import DotSvg from '@fortawesome/fontawesome-pro/svgs/solid/circle.svg';
import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import FlowSearch from '@octaved/flow/src/Components/Form/Search/Search';
import {Uuid} from '@octaved/typescript/src/lib';
import {ActionTrigger} from '@octaved/ui';
import {Check, Eye} from 'lucide-react';
import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {BarTextDisplayment, setBaselineComparisonState} from '../../../Modules/Ui';
import {ExpandCollapseStrategy, expandBaselinePlanningNodes} from '../../../Modules/UpdateExpandedTreeNodes';
import {
  baselineComparisonBarTextDisplaymentSelector,
  baselineComparisonSearchSelector,
  baselineComparisonShowTasksSelector,
} from '../../../Selectors/UiSelectors';

interface FilterProps {
  rootNodeId: Uuid;
}

export default function Filter({rootNodeId}: FilterProps): ReactElement {
  const {t} = useTranslation();
  const storedSearch = useSelector(baselineComparisonSearchSelector);
  const showTasks = useSelector(baselineComparisonShowTasksSelector);
  const barTextDisplayment = useSelector(baselineComparisonBarTextDisplaymentSelector);
  const [search, setSearch] = useState(storedSearch);
  useEffect(() => setSearch(storedSearch), [storedSearch]);
  const dispatch = useDispatch();
  const updateSearch = useCallback(
    (search: string) => {
      dispatch(setBaselineComparisonState({search}));
    },
    [dispatch],
  );

  const options = useMemo<DropdownPopupItemOptions[]>(() => {
    return [
      {
        icon: <Check />,
        iconColor: showTasks ? 'green' : 'brightGrey',
        onMouseDown: () => {
          dispatch(setBaselineComparisonState({showTasks: !showTasks}));
        },
        token: 'pages:planning.ganttChart.showTasks',
        type: DropdownPopupItemType.item,
      },
      {type: DropdownPopupItemType.divider},
      {
        onMouseDown: () => {
          dispatch(expandBaselinePlanningNodes(rootNodeId, ExpandCollapseStrategy.expandAll));
        },
        token: 'pages:planning.ganttChart.expandAll',
        type: DropdownPopupItemType.item,
      },
      {
        onMouseDown: () => {
          dispatch(expandBaselinePlanningNodes(rootNodeId, ExpandCollapseStrategy.collapseAll));
        },
        token: 'pages:planning.ganttChart.collapseAll',
        type: DropdownPopupItemType.item,
      },
      {
        onMouseDown: () => {
          dispatch(expandBaselinePlanningNodes(rootNodeId, ExpandCollapseStrategy.collapseBelowWorkPackage));
        },
        token: 'pages:planning.ganttChart.collapseAllBelowWP',
        type: DropdownPopupItemType.item,
      },
      {
        icon: <DotSvg />,
        iconColor: barTextDisplayment === BarTextDisplayment.always ? 'green' : 'brightGrey',
        onMouseDown: () => {
          dispatch(setBaselineComparisonState({barTextDisplayment: BarTextDisplayment.always}));
        },
        token: 'pages:planning.ganttChart.bars.showBarTextAlways',
        type: DropdownPopupItemType.item,
      },
      {
        icon: <DotSvg />,
        iconColor: barTextDisplayment === BarTextDisplayment.onlyOnHiddenTable ? 'green' : 'brightGrey',
        onMouseDown: () => {
          dispatch(setBaselineComparisonState({barTextDisplayment: BarTextDisplayment.onlyOnHiddenTable}));
        },
        token: 'pages:planning.ganttChart.bars.showBarTextWithoutTable',
        type: DropdownPopupItemType.item,
      },
    ];
  }, [dispatch, showTasks, barTextDisplayment, rootNodeId]);

  return (
    <div className={'flex items-center gap-x-2'}>
      <FlowSearch
        search={search}
        setSearch={setSearch}
        onEnterKey={updateSearch}
        onLeave={updateSearch}
        canClearSearch={storedSearch.length > 0}
        onClearSearch={() => updateSearch('')}
      />
      <DropdownPopup items={options} position={'bottom left'} closeOnSelect>
        <ActionTrigger icon={Eye} labelValue={t('general:view')} />
      </DropdownPopup>
    </div>
  );
}
