import StringPath from '@octaved/flow/src/Components/Node/StringPath';
import {useLoadNode} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {useDisposedSafeCallback} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {ActionTrigger, Popup} from '@octaved/ui';
import {Folder} from 'lucide-react';
import {ReactElement, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Content from './Content';

interface Props {
  selectFolderId: (id: Uuid | null) => void;
  selectedFolderId: Uuid | null;
  isActive?: boolean;
}

export default function ProjectFolderSelector({isActive, selectedFolderId, selectFolderId}: Props): ReactElement {
  const {t} = useTranslation();
  const [open, _setOpen] = useState(false);
  const setOpen = useDisposedSafeCallback(_setOpen);
  const close = useCallback(() => setOpen(false), [setOpen]);
  useLoadNode(selectedFolderId);
  return (
    <Popup
      className={'!z-[2050]'}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={close}
      position={'bottom left'}
      trigger={
        <ActionTrigger
          icon={Folder}
          isActive={isActive}
          label={t('nodeTable:quickFilter.projectFolder.label')}
          labelValue={
            selectedFolderId === null ? (
              <span className={'text-slate-400'}>-</span>
            ) : (
              <StringPath nodeId={selectedFolderId} />
            )
          }
        />
      }
    >
      <div className={'p-2'}>
        <Content close={close} selectedFolderId={selectedFolderId} selectFolderId={selectFolderId} />
      </div>
    </Popup>
  );
}
