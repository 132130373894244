import {error} from '@octaved/env/src/Logger';
import Close from '@octaved/flow/src/Dialogs/Components/Close';
import {DateStr, toDateStr} from '@octaved/typescript';
import {
  DATE,
  formatCollapsingDateRange,
  fromIsoFormat,
  toIsoFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import {ucfirst} from '@octaved/utilities';
import dayjs from 'dayjs';
import {TFunction} from 'i18next';
import {Trash} from 'lucide-react';
import {ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FocusedInputShape} from 'react-dates';
import {useTranslation} from 'react-i18next';
import {cn} from '../../Hooks';
import MonthPresetPicker from './Internal/month-preset-picker';
import PickerView from './Internal/PickerView';

export type PickerPreset =
  | 'customDate'
  | 'customMonth'
  | 'customRange'
  | 'customWeek'
  | 'last7days'
  | 'last30days'
  | 'lastMonth'
  | 'lastQuarter'
  | 'lastWeek'
  | 'next7days'
  | 'next30days'
  | 'nextMonth'
  | 'nextQuarter'
  | 'nextWeek'
  | 'none'
  | 'secondLastQuarter'
  | 'thirdLastQuarter'
  | 'thisMonth'
  | 'thisQuarter'
  | 'thisWeek'
  | 'today'
  | 'tomorrow'
  | 'yesterday';

export interface PickerState<P extends PickerPreset = PickerPreset> {
  preset: P;
  dateFrom?: DateStr | null;
  dateTo?: DateStr | null;
}

export type PickerPresetOptions = ReadonlyArray<PickerPreset | 'divider'>;

export type CustomPickerState<O extends PickerPresetOptions> = PickerState<Exclude<O[number], 'divider'>>;

const presetTranslation: Record<PickerPreset, (t: TFunction) => string> = {
  customDate: (t) => t('generalCore:datePicker.preset.customDate'),
  customMonth: (t) => t('generalCore:datePicker.preset.customMonth'),
  customRange: (t) => t('generalCore:datePicker.preset.customRange'),
  customWeek: (t) => t('generalCore:datePicker.preset.customWeek'),
  last30days: (t) => t('generalCore:datePicker.preset.last30Days'),
  last7days: (t) => t('generalCore:datePicker.preset.last7Days'),
  lastMonth: (t) => t('generalCore:datePicker.preset.lastMonth'),
  lastQuarter: () => dayjs().subtract(1, 'quarter').format('[Q]Q YYYY'),
  lastWeek: (t) => t('generalCore:datePicker.preset.lastWeek'),
  next30days: (t) => t('generalCore:datePicker.preset.next30Days'),
  next7days: (t) => t('generalCore:datePicker.preset.next7Days'),
  nextMonth: (t) => t('generalCore:datePicker.preset.nextMonth'),
  nextQuarter: () => dayjs().add(1, 'quarter').format('[Q]Q YYYY'),
  nextWeek: (t) => t('generalCore:datePicker.preset.nextWeek'),
  none: (t) => t('generalCore:datePicker.preset.none'),
  secondLastQuarter: () => dayjs().subtract(2, 'quarter').format('[Q]Q YYYY'),
  thirdLastQuarter: () => dayjs().subtract(3, 'quarter').format('[Q]Q YYYY'),
  thisMonth: (t) => t('generalCore:datePicker.preset.thisMonth'),
  thisQuarter: () => dayjs().format('[Q]Q YYYY'),
  thisWeek: (t) => t('generalCore:datePicker.preset.thisWeek'),
  today: (t) => t('general:today'),
  tomorrow: (t) => t('generalCore:culture.days.tomorrow'),
  yesterday: (t) => t('general:yesterday'),
};

export function usePresetValueTranslation(): (
  preset: PickerPreset,
  dateFrom?: DateStr | null,
  dateTo?: DateStr | null,
) => string {
  const {t} = useTranslation();
  const formatInWords = useFormatInWords({type: DATE});
  return useCallback(
    (preset, dateFrom, dateTo) => {
      if (preset === 'customDate') {
        return formatInWords(dayjs(dateFrom));
      } else if (preset === 'customMonth') {
        return dayjs(dateFrom).format('MMMM YYYY');
      } else if (preset === 'customWeek' || preset === 'customRange') {
        return formatCollapsingDateRange(
          dayjs(dateFrom),
          dayjs(dateTo),
          {
            day: 'DD.',
            dayMonth: 'DD.MM.',
            full: 'DD.MM.',
          },
          ' - ',
        );
      }

      return presetTranslation[preset](t);
    },
    [t, formatInWords],
  );
}

const rangeGenerators: Record<
  PickerPreset,
  (options: Pick<PickerState, 'dateFrom' | 'dateTo'>) => [DateStr | null, DateStr | null]
> = {
  customDate: ({dateFrom, dateTo}) => [dateFrom ?? null, dateTo ?? null],
  customMonth: ({dateFrom, dateTo}) => [dateFrom ?? null, dateTo ?? null],
  customRange: ({dateFrom, dateTo}) => [dateFrom ?? null, dateTo ?? null],
  customWeek: ({dateFrom, dateTo}) => [dateFrom ?? null, dateTo ?? null],
  last30days: () => [toDateStr(dayjs().subtract(30, 'day')), toDateStr(dayjs())],
  last7days: () => [toDateStr(dayjs().subtract(7, 'day')), toDateStr(dayjs())],
  lastMonth: () => [
    toDateStr(dayjs().subtract(1, 'month').startOf('month')),
    toDateStr(dayjs().subtract(1, 'month').endOf('month')),
  ],
  lastQuarter: () => [
    toDateStr(dayjs().subtract(1, 'quarter').startOf('quarter')),
    toDateStr(dayjs().subtract(1, 'quarter').endOf('quarter')),
  ],
  lastWeek: () => [
    toDateStr(dayjs().subtract(1, 'week').startOf('week')),
    toDateStr(dayjs().subtract(1, 'week').endOf('week')),
  ],
  next30days: () => [toDateStr(dayjs()), toDateStr(dayjs().add(30, 'day'))],
  next7days: () => [toDateStr(dayjs()), toDateStr(dayjs().add(7, 'day'))],
  nextMonth: () => [
    toDateStr(dayjs().add(1, 'month').startOf('month')),
    toDateStr(dayjs().add(1, 'month').endOf('month')),
  ],
  nextQuarter: () => [
    toDateStr(dayjs().add(1, 'quarter').startOf('quarter')),
    toDateStr(dayjs().add(1, 'quarter').endOf('quarter')),
  ],
  nextWeek: () => [toDateStr(dayjs().add(1, 'week').startOf('week')), toDateStr(dayjs().add(1, 'week').endOf('week'))],
  none: () => [null, null],
  secondLastQuarter: () => [
    toDateStr(dayjs().subtract(2, 'quarter').startOf('quarter')),
    toDateStr(dayjs().subtract(2, 'quarter').endOf('quarter')),
  ],
  thirdLastQuarter: () => [
    toDateStr(dayjs().subtract(3, 'quarter').startOf('quarter')),
    toDateStr(dayjs().subtract(3, 'quarter').endOf('quarter')),
  ],
  thisMonth: () => [toDateStr(dayjs().startOf('month')), toDateStr(dayjs().endOf('month'))],
  thisQuarter: () => [toDateStr(dayjs().startOf('quarter')), toDateStr(dayjs().endOf('quarter'))],
  thisWeek: () => [toDateStr(dayjs().startOf('week')), toDateStr(dayjs().endOf('week'))],
  today: () => [toDateStr(dayjs()), toDateStr(dayjs())],
  tomorrow: () => [toDateStr(dayjs().add(1, 'day')), toDateStr(dayjs().add(1, 'day'))],
  yesterday: () => [toDateStr(dayjs().subtract(1, 'day')), toDateStr(dayjs().subtract(1, 'day'))],
};

export function pickerStateToDateRange({preset, dateFrom, dateTo}: PickerState): [DateStr | null, DateStr | null] {
  return rangeGenerators[preset]({dateFrom, dateTo});
}

const singleDayPresets: Set<PickerPreset> = new Set(['today', 'yesterday', 'tomorrow', 'customDate']);
const weekPrestes: Set<PickerPreset> = new Set(['thisWeek', 'lastWeek', 'nextWeek', 'customWeek']);
const monthPrestes: Set<PickerPreset> = new Set(['thisMonth', 'lastMonth', 'nextMonth', 'customMonth']);
const rangePresets: Set<PickerPreset> = new Set(['last30days', 'next30days', 'last7days', 'next7days', 'customRange']);

export interface PickerWithPresetsProps<P extends PickerPreset> {
  calendarFooter?: ReactNode;
  dateFrom?: DateStr | null;
  dateTo?: DateStr | null;
  onChange: (preset: P, dateFrom?: DateStr | null, dateTo?: DateStr | null) => void;
  onClose: VoidFunction;
  preset: P;
  presetOptions: ReadonlyArray<P | 'divider'>;
}

export function PickerWithPresets<P extends PickerPreset>({
  calendarFooter,
  dateFrom,
  dateTo,
  onChange: _onChange,
  onClose,
  preset: storedPreset,
  presetOptions,
}: PickerWithPresetsProps<P>): ReactElement {
  const {t} = useTranslation();
  const [start, end] = useMemo(() => {
    return pickerStateToDateRange({preset: storedPreset, dateFrom, dateTo});
  }, [dateFrom, dateTo, storedPreset]);
  const [focused, setFocused] = useState<FocusedInputShape | null>('startDate');
  const canSelectCustomDate = !!presetOptions.find((preset) => preset === 'customDate');
  const canSelectCustomWeek = !!presetOptions.find((preset) => preset === 'customWeek');

  const canSelectCustomMonth = !!presetOptions.find((preset) => preset === 'customMonth');
  const canSelectCustomRange = !!presetOptions.find((preset) => preset === 'customRange');

  const now = dayjs();
  const nowIso = toIsoFormat(now);

  // Allow setting any PickerPreset internally while keeping "P" for the outside usage valid:
  const onChange = _onChange as (preset: PickerPreset, dateFrom?: DateStr | null, dateTo?: DateStr | null) => void;
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    if (!presetOptions.includes(storedPreset)) {
      error(`Invalid preset option '${storedPreset}' received`, {allowed: presetOptions});
      const first = presetOptions.find((p) => p !== 'divider') as PickerPreset | undefined;
      if (first) {
        const [from, to] = pickerStateToDateRange({preset: first});
        onChangeRef.current(first, from, to);
      }
    }
  }, [presetOptions, storedPreset]);

  // see https://github.com/react-dates/react-dates/issues/2232 - use a key to update the displayed months:
  const pickerViewKey = storedPreset;

  return (
    <div className={'flex items-start gap-x-4 p-2'}>
      <div className={'flex w-44 flex-shrink-0 flex-col'}>
        {presetOptions.map((preset, idx) => {
          if (preset === 'divider') {
            return <div key={idx} className={'my-1 h-px bg-slate-200'} />;
          }
          return (
            <div
              key={idx}
              onClick={() => {
                if (preset === 'customDate') {
                  const date = start ?? end ?? nowIso;
                  onChange(preset, date, date);
                } else if (preset === 'customRange') {
                  onChange(preset, start ?? nowIso, end ?? nowIso);
                } else if (preset === 'customWeek') {
                  onChange(
                    preset,
                    toIsoFormat(dayjs(start ?? now).startOf('week')),
                    toIsoFormat(dayjs(start ?? now).endOf('week')),
                  );
                } else if (preset === 'customMonth') {
                  onChange(
                    preset,
                    toIsoFormat(dayjs(start ?? now).startOf('month')),
                    toIsoFormat(dayjs(start ?? now).endOf('month')),
                  );
                } else {
                  onChange(preset);
                }
              }}
              className={cn(
                'flex cursor-pointer items-center gap-x-2 rounded px-2 py-1.5 text-sm transition-all hover:bg-slate-50 active:scale-95',
                storedPreset === preset && 'bg-sky-50 font-medium',
                preset === 'none' && 'bg-transparent text-red-600 hover:bg-red-50',
              )}
            >
              {preset === 'none' && <Trash className={'size-4'} />}
              {ucfirst(presetTranslation[preset](t))}
            </div>
          );
        })}
      </div>
      <div className={'flex flex-col justify-between gap-y-4 self-stretch'}>
        <div>
          {(singleDayPresets.has(storedPreset) || storedPreset === 'none') && (
            <PickerView<DateStr, false>
              key={pickerViewKey}
              start={start}
              end={end}
              isRange={false}
              canClear={false}
              numberOfMonth={2}
              onChangeDates={(start, end) => canSelectCustomDate && onChange('customDate', start || end, start || end)}
              readonly={!canSelectCustomDate}
              focused={null}
              setFocused={() => false}
              convertFrom={fromIsoFormat}
              convertTo={toIsoFormat}
              snapToStartDateMonth
            />
          )}
          {rangePresets.has(storedPreset) && (
            <PickerView<DateStr, false>
              key={pickerViewKey}
              start={start}
              end={end}
              isRange
              canClear={false}
              numberOfMonth={2}
              readonly={!canSelectCustomRange}
              onChangeDates={(start, end) => canSelectCustomRange && onChange('customRange', start, end)}
              focused={focused}
              setFocused={setFocused}
              convertFrom={fromIsoFormat}
              convertTo={toIsoFormat}
              snapToStartDateMonth
            />
          )}
          {weekPrestes.has(storedPreset) && (
            <PickerView<DateStr, false>
              key={pickerViewKey}
              start={start}
              end={end}
              isRange
              canClear={false}
              numberOfMonth={2}
              readonly={!canSelectCustomWeek}
              onChangeDates={(start, end) => canSelectCustomWeek && onChange('customWeek', start, end)}
              focused={focused}
              setFocused={setFocused}
              convertFrom={fromIsoFormat}
              convertTo={toIsoFormat}
              snapToStartDateMonth
              startDateOffset={(day) => toIsoFormat(dayjs(day).startOf('week'))}
              endDateOffset={(day) => toIsoFormat(dayjs(day).endOf('week'))}
            />
          )}
          {monthPrestes.has(storedPreset) && (
            <MonthPresetPicker
              readonly={!canSelectCustomMonth}
              value={start}
              onChange={(date) => {
                const start = toIsoFormat(dayjs(date).startOf('month'));
                const end = toIsoFormat(dayjs(date).endOf('month'));
                if (canSelectCustomMonth) {
                  onChange('customMonth', start, end);
                }
              }}
            />
          )}
        </div>
        {calendarFooter}
      </div>
      <Close close={onClose} />
    </div>
  );
}
