import {getProjectControllingDueDatePerNode} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {EntityStates} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {
  FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_START,
  FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_SUCCESS,
} from '../../ActionTypes';
import {PlanningEvent} from '../../Events';
import {
  dueDatePerNodeSelector,
  dueDatePerNodeStatesSelector,
} from '../../Selectors/Projects/ProjectControlling/DueDatePerNodeSelectors';
import {FlowState} from '../../State';

const dueDatePerNodeReducers = createReducerCollection<Record<Uuid, string | null | undefined>>({});
export const dueDatePerNodeReducer = dueDatePerNodeReducers.reducer;

dueDatePerNodeReducers.add<PlanningEvent>(
  'flow.PlanningEvent',
  (state, {updatedDueDates, updatedNodePlanningDates}) => {
    const newState = {...state};
    let hasChanged = false;
    Object.entries(updatedDueDates).forEach(([id, {dueDate}]) => {
      if (id in newState && newState[id] !== dueDate) {
        newState[id] = dueDate;
        hasChanged = true;
      }
    });
    Object.entries(updatedNodePlanningDates).forEach(([id, planningDates]) => {
      let plannedEnd: null | string = null;
      if (planningDates) {
        const planningDate = planningDates.sort((a, b) => b.plannedEnd.localeCompare(a.plannedEnd))[0];
        plannedEnd = isoDateTimeToIsoFormat(planningDate?.plannedEnd || null);
      }
      if (id in newState && newState[id] !== plannedEnd) {
        newState[id] = plannedEnd;
        hasChanged = true;
      }
    });
    return hasChanged ? newState : state;
  },
);

const stateReducers = createReducerCollection<EntityStates>({});
export const dueDatePerNodeStateReducer = stateReducers.reducer;

interface DueDatePerNode {
  id: Uuid;
  dueDate: string | null;
}

export const [, useDueDatePerNode] = createUseEntityHook<FlowState, DueDatePerNode, string | null>(
  FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_START,
  FLOW_LOAD_PROJECT_CONTROLLING_DUE_DATE_PER_NODE_SUCCESS,
  getProjectControllingDueDatePerNode,
  dueDatePerNodeSelector,
  dueDatePerNodeStatesSelector,
  dueDatePerNodeReducers,
  stateReducers,
  {normalizer: ({dueDate}) => dueDate},
);
