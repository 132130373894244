import {ReactElement} from 'react';

const LoadingDots = (): ReactElement => {
  return (
    <span className={'loadingDots'}>
      <span className={'bg-current'} />
      <span className={'bg-current'} />
      <span className={'bg-current'} />
    </span>
  );
};

export {LoadingDots};
