import {error} from '@octaved/env/src/Logger';
import {hasMovedSufficientlyY} from '@octaved/hooks/src/ReactDnd/Movement';
import {Uuid} from '@octaved/typescript/src/lib';
import {arrayMoveImmutable as arrayMove} from 'array-move';
import throttle from 'lodash/throttle';
import {MutableRefObject, RefObject, useCallback, useMemo, useRef} from 'react';
import {ConnectDragPreview, DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';
import {Task} from '../../../../EntityInterfaces/Task';
import {TaskSection} from '../../../../EntityInterfaces/TaskSection';
import {moveTasks} from '../../../../Modules/Tasks';
import {moveTaskSections} from '../../../../Modules/TaskSections';
import {useShowTaskGroup} from '../../../../Modules/Ui/TaskList';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';
import {useStaticDragContext} from './DragContext';

const hoverThrottleDelay = 100; //ms
const hoverOpenGroupAtTickCount = 10; // opens the group after (hoverOpenGroupAtTickCount * hoverThrottleDelay) ms

let hoverId: Uuid | null = null;
let hoverTickCount = 0;

type Dragged = 'section' | 'task';

interface DraggedItem {
  currentIndex: number;
  currentParentId: Uuid;
  hasSubTasks: boolean | null;
  id: Uuid;
  lastIndex?: number;
  startingIndex: number;
  startingParentId: Uuid;
}

interface Collect {
  isDragging: boolean;
}

interface Dragging {
  dragPreviewRef: ConnectDragPreview;
  dragRef: MutableRefObject<HTMLDivElement | null>;
  isDragging: boolean;
}

interface Dropping {
  dropRef: MutableRefObject<HTMLDivElement | null>;
}

function useDragging(
  type: Dragged,
  id: Uuid,
  readonly: boolean,
  index: number,
  parentNodeId: Uuid,
  save: typeof moveTasks,
  hasSubTasks: boolean | null = null,
): Dragging {
  const {isDraggingRef, getSortedIdsRef, isSet} = useStaticDragContext();
  const dispatch = useDispatch();
  const dragRef = useRef<HTMLDivElement>(null);
  const [{isDragging}, drag, dragPreviewRef] = useDrag<DraggedItem, unknown, Collect>({
    type,
    canDrag: !readonly && isSet,
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging() || isDraggingRef.current === id,
      };
    },
    end(item) {
      isDraggingRef.current = null;

      if (item.startingIndex === item.currentIndex && item.startingParentId === item.currentParentId) {
        return;
      }

      const currentSiblingIds = getSortedIdsRef(type, item.currentParentId);
      if (!currentSiblingIds) {
        error('currentSiblingIds empty');
        return; //should not happen
      }

      if (item.startingParentId === item.currentParentId) {
        dispatch(
          save(
            [],
            [
              {
                parentNodeId: item.currentParentId,
                sortedSiblingNodeIds: currentSiblingIds,
              },
            ],
          ),
        );
      } else {
        const previousSiblingIds = getSortedIdsRef(type, item.startingParentId) || [];
        dispatch(
          save(
            [{movedNodeIds: [item.id], targetParentNodeId: item.currentParentId}],
            [
              {
                parentNodeId: item.currentParentId,
                sortedSiblingNodeIds: currentSiblingIds,
              },
              {
                parentNodeId: item.startingParentId,
                sortedSiblingNodeIds: previousSiblingIds,
              },
            ],
          ),
        );
      }
    },
    item() {
      isDraggingRef.current = id;
      return {
        hasSubTasks,
        id,
        currentIndex: index,
        currentParentId: parentNodeId,
        startingIndex: index,
        startingParentId: parentNodeId,
      };
    },
  });
  drag(dragRef);
  return {isDragging, dragRef, dragPreviewRef};
}

function canMove(
  dropRef: MutableRefObject<HTMLDivElement | null>,
  index: number,
  parentNodeId: Uuid,
  item: DraggedItem,
  monitor: DropTargetMonitor,
): boolean {
  const dropRefCurrent = dropRef.current;
  const isSameParent = item.currentParentId === parentNodeId;
  return (
    !!dropRefCurrent &&
    monitor.canDrop() &&
    item.id !== parentNodeId &&
    (!isSameParent || item.currentIndex !== index) &&
    monitor.isOver({shallow: true}) &&
    hasMovedSufficientlyY(dropRefCurrent, monitor)
  );
}

function onHoverExec(hoveredNodeId: Uuid, item: DraggedItem, onHover?: (item: DraggedItem) => void): void {
  if (hoverId !== hoveredNodeId) {
    hoverId = hoveredNodeId;
    hoverTickCount = 1;
  } else {
    hoverTickCount++;
  }
  if (onHover) {
    onHover(item);
  }
}

function useHover(
  hoveredNodeId: Uuid,
  parentNodeId: Uuid,
  index: number,
  dropRef: MutableRefObject<HTMLDivElement | null>,
  onHover?: (item: DraggedItem) => void,
): (item: DraggedItem, monitor: DropTargetMonitor) => void {
  const {patchSortedIds, patchTree, getSortedIdsRef} = useStaticDragContext();

  return useMemo(
    () =>
      throttle(
        (item: DraggedItem, monitor: DropTargetMonitor) => {
          onHoverExec(hoveredNodeId, item, onHover);

          const itemType = monitor.getItemType() as Dragged | null;
          if (!itemType || !canMove(dropRef, index, parentNodeId, item, monitor)) {
            return;
          }

          const currentSiblingIds = getSortedIdsRef(itemType, item.currentParentId);
          if (!currentSiblingIds) {
            error('currentSiblingIds empty');
            return;
          }

          if (item.currentParentId === parentNodeId) {
            patchSortedIds(itemType, {[item.currentParentId]: arrayMove(currentSiblingIds, item.currentIndex, index)});
          } else {
            const previousSiblingIds = currentSiblingIds.slice(0);
            previousSiblingIds.splice(item.currentIndex, 1);

            const nextSiblingIds = (getSortedIdsRef(itemType, parentNodeId) || []).slice(0);
            //In manual sort order we put the item at the position of the item we hover over, just like when staying
            // in the same parent:
            nextSiblingIds.splice(index, 0, item.id);

            patchSortedIds(itemType, {
              [item.currentParentId]: previousSiblingIds,
              [parentNodeId]: nextSiblingIds,
            });
            patchTree({[item.id]: parentNodeId});
          }

          item.lastIndex = item.currentIndex;
          item.currentIndex = index;
          item.currentParentId = parentNodeId;
        },
        hoverThrottleDelay,
        {leading: false},
      ),
    [hoveredNodeId, dropRef, index, parentNodeId, patchSortedIds, patchTree, getSortedIdsRef, onHover],
  );
}

export function useDropOnEmptyParent(parentNodeId: Uuid, active: boolean, accept: Dragged): RefObject<HTMLDivElement> {
  const dropRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DraggedItem, undefined, Collect>({
    accept,
    canDrop(_, monitor) {
      return active && monitor.getItemType() === accept;
    },
    hover: useHover(parentNodeId, parentNodeId, 0, dropRef),
  });

  drop(dropRef);

  return dropRef;
}

export function useDragTask(
  task: Pick<Task, 'id'>,
  hasSubTasks: boolean,
  depth: number,
  index: number,
  parentNodeId: Uuid,
): Dragging & Dropping {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {isDragging, dragRef, dragPreviewRef} = useDragging(
    'task',
    task.id,
    readonlyOrNotManageable,
    index,
    parentNodeId,
    moveTasks,
    hasSubTasks,
  );
  const dropRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DraggedItem, undefined, Collect>({
    accept: readonlyOrNotManageable ? 'nothing' : 'task',
    canDrop(item, _monitor) {
      return depth === 0 || !item.hasSubTasks;
    },
    hover: useHover(task.id, parentNodeId, index, dropRef),
  });

  drop(dropRef);

  return {
    dragPreviewRef,
    dragRef,
    dropRef,
    isDragging,
  };
}

function useOnHoverOpenGroup(id: Uuid): (item: DraggedItem) => void {
  const {showRef, setShow} = useShowTaskGroup(id);
  return useCallback(
    (item) => {
      //wait n hover ticks (see useHover throttle delay for tick length):
      if (!showRef.current && hoverTickCount >= hoverOpenGroupAtTickCount && item.id !== id) {
        setShow(true);
      }
    },
    [setShow, showRef, id],
  );
}

export function useDragTaskSection(section: TaskSection, index: number, parentNodeId: Uuid): Dragging & Dropping {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const id = section.id;
  const {isDragging, dragRef, dragPreviewRef} = useDragging(
    'section',
    id,
    readonlyOrNotManageable,
    index,
    parentNodeId,
    moveTaskSections,
  );
  const dropRef = useRef<HTMLDivElement>(null);

  const onHover = useOnHoverOpenGroup(id);

  const [, drop] = useDrop<DraggedItem, undefined, Collect>({
    accept: readonlyOrNotManageable ? 'nothing' : ['section', 'task'],
    canDrop(_, monitor) {
      //we only accept sections, but we want the onHover to trigger nevertheless to open the section
      return !readonlyOrNotManageable && monitor.getItemType() === 'section';
    },
    hover: useHover(id, parentNodeId, index, dropRef, onHover),
  });

  drop(dropRef);

  return {
    dragPreviewRef,
    dragRef,
    dropRef,
    isDragging,
  };
}

export function useOnHoverOpenGroupDropRef(nodeId: Uuid, readonly: boolean): RefObject<HTMLDivElement> {
  const dropRef = useRef<HTMLDivElement>(null);
  const onHover = useOnHoverOpenGroup(nodeId);
  const [, drop] = useDrop<DraggedItem, undefined, Collect>({
    accept: readonly ? 'nothing' : ['section', 'task'],
    canDrop() {
      return false;
    },
    hover: useMemo(
      () =>
        throttle(
          (item) => {
            onHoverExec(nodeId, item, onHover);
          },
          hoverThrottleDelay,
          {leading: false},
        ),
      [nodeId, onHover],
    ),
  });
  drop(dropRef);
  return dropRef;
}
