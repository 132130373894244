import {forwardRef, ReactElement} from 'react';
import {useProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';
import {useProjectTreeTaskListContext} from '../../ProjectTree/ProjectTreeTaskListContext';
import WpOrSwpGroup from '../../Task/TaskGroup/WpOrSwpGroup';
import {WorkPackageParams} from './WorkPackageAsTile';

export default forwardRef<HTMLDivElement, WorkPackageParams>(function WorkPackageAsTaskList(
  {node}: WorkPackageParams,
  ref,
): ReactElement | null {
  const {selectableNodeTypes} = useProjectTreeContext();
  const {taskFilterQuerySelector} = useProjectTreeTaskListContext();
  const isSelectable = selectableNodeTypes.has(node.type);
  const isSelected = isSelectable && node.isSelected;
  const wp = node.entity;

  if (!wp) {
    return null;
  }

  return (
    <div className={'workPackage'} ref={ref}>
      <WpOrSwpGroup
        select={isSelectable ? node.select : undefined}
        selected={isSelected}
        taskFilterQuerySelector={taskFilterQuerySelector}
        node={wp}
      />

      {/**#region css */}
      {/*language=SCSS*/}
      <style jsx>{`
        .workPackage {
          flex: 1 1 100%;
          padding: 5px;
        }
      `}</style>
      {/**#endregion */}
    </div>
  );
});
