import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {toDateStr} from '@octaved/typescript/src';
import {cn, Message, tableGridCellVariant, TWAutosaveInput, TWButton} from '@octaved/ui';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {MoreHorizontal} from 'lucide-react';
import {ReactElement, useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {workdaysBetween} from '../../../Calculations/WorkdayCalculations';
import {PlanningDate, PlanningDatesList} from '../../../EntityInterfaces/PlanningDates';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {updatePlanningDate} from '../../../Modules/PlanningDates';
import {PlanningStatus} from '../../../PlanningStatus/PlanningStatus';
import {canChangeBarSelector, canDeleteBarSelector} from '../../../Selectors/PlanningDependencySelectors';
import {DurationPatch, useDuration} from '../Duration';
import Menu from './Menu';
import TimePeriodDatePicker from './TimePeriodDatePicker';
import {useTranslation} from 'react-i18next';

interface TimePeriodRowProps {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  node: PlanningRealizationNode;
  planningDate: PlanningDate;
  planningDates: PlanningDatesList;
  readonly: boolean;
  status: PlanningStatus;
}

export default function TimePeriodRow({
  index,
  isFirst,
  isLast,
  node,
  planningDate,
  planningDates,
  readonly,
  status,
}: TimePeriodRowProps): ReactElement {
  const canChangeBar = useSelector(canChangeBarSelector)(node.id);
  const canDeleteBar = useSelector(canDeleteBarSelector)(node.id, isFirst, isLast);
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);

  const dispatch = useDispatch();
  const saveChange = useCallback<DurationPatch>(
    (plannedStart, plannedEnd) => {
      dispatch(
        updatePlanningDate(node.id, planningDate.id, {
          plannedEnd,
          plannedStart,
        }),
      );
    },
    [dispatch, node.id, planningDate.id],
  );

  const {t} = useTranslation();

  const {duration, onChange} = useDuration(node, planningDate, saveChange, isFirst, isLast);
  const [durationError, setDurationError] = useState(false);
  const previousPlanningDate = planningDates[index - 1] || null;
  const nextPlanningDate = planningDates[index + 1] || null;

  const maxDuration = useMemo(() => {
    const currentStart = planningDate.plannedStart;
    const nextStart = nextPlanningDate?.plannedStart;
    const previousEnd = previousPlanningDate?.plannedEnd;

    if (currentStart && nextStart) {
      return workdaysBetween(
        fromIsoDateTimeFormat(currentStart),
        fromIsoDateTimeFormat(nextStart),
        getOrgWorkMinutesAtDate,
      );
    }
    if (!canChangeBar && !nextStart && previousEnd) {
      //the last one expands to the front
      return workdaysBetween(
        fromIsoDateTimeFormat(previousEnd),
        fromIsoDateTimeFormat(planningDate.plannedEnd),
        getOrgWorkMinutesAtDate,
      );
    }

    return 365 * 2;
  }, [
    canChangeBar,
    getOrgWorkMinutesAtDate,
    nextPlanningDate?.plannedStart,
    planningDate.plannedEnd,
    planningDate.plannedStart,
    previousPlanningDate?.plannedEnd,
  ]);

  const todayIso = useSelector(todayIsoDateSelector);
  const todayIsOnPeriod =
    todayIso >= toDateStr(planningDate.plannedStart) && todayIso <= toDateStr(planningDate.plannedEnd);

  return (
    <>
      <div className={tableGridCellVariant({position: 'left'})} data-testid={`timePeriodRow-${index}-index`}>
        {planningDates.length > 1 && index + 1}
      </div>
      <div
        className={cn(tableGridCellVariant({position: isFirst && isLast ? 'left' : 'center'}))}
        data-testid={`timePeriodRow-${index}-dateSelection`}
      >
        <TimePeriodDatePicker
          node={node}
          planningDate={planningDate}
          planningDates={planningDates}
          readonly={readonly}
          saveChange={saveChange}
          triggerButtonClassName={cn(
            status.overall === 'overdue' && 'text-red-700',
            status.overall === 'active' && todayIsOnPeriod && 'text-sky-700',
          )}
        />
      </div>
      <div className={tableGridCellVariant()} data-testid={`timePeriodRow-${index}-duration`}>
        <div className={'flex w-28 items-center gap-2'}>
          <TWAutosaveInput
            className={'w-full'}
            value={duration.toString()}
            hasError={durationError}
            onSave={(v) => {
              const parsedInt = Number(v.trim());
              if (
                v.trim() !== '' &&
                Number.isFinite(parsedInt) &&
                parsedInt % 1 === 0 &&
                parsedInt > 0 &&
                (!maxDuration || parsedInt <= maxDuration)
              ) {
                setDurationError(false);
                onChange(parsedInt);
              } else {
                setDurationError(true);
              }
            }}
            disabled={readonly}
            readOnly={readonly}
          />
          {t('general:days', {count: duration})}
        </div>
      </div>
      <div data-testid={`timePeriodRow-${index}-menu`}>
        {!readonly && (
          <Menu
            trigger={
              <TWButton variant={'ghost'} size={'xs'} iconOnly>
                <MoreHorizontal className={'size-4'} />
              </TWButton>
            }
            node={node}
            planningDate={planningDate}
            canDelete={canDeleteBar}
            nextPlanningDate={nextPlanningDate}
            previousPlanningDate={previousPlanningDate}
          />
        )}
      </div>
      {durationError && (
        <Message colorScheme={'error'} className={'col-span-4'}>
          {t('general:autosaveConditionError')}
        </Message>
      )}
    </>
  );
}
