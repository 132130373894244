import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {ListPlus} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {MenuRootItem} from '../FlowRoutes';
import {createOfferInspectorRoutes} from './Inspectors/CreateOfferInspector';
import {createOfferRoute, projectTreeTrashRoute} from './Routes';

const CreateOffer = lazy(() => import('../../Pages/CreateOffer/CreateOffer'));
const ProjectTreeTrash = lazy(() => import('../../Pages/ProjectTreeTrash/ProjectTreeTrash'));

export const advancedRoutes: RouteObject[] = [
  {
    children: createOfferInspectorRoutes,
    element: <CreateOffer />,
    path: `${createOfferRoute}/*`,
  },
  {
    element: <ProjectTreeTrash />,
    path: `${projectTreeTrashRoute}/*`,
  },
];

export const advancedMenu: MenuRootItem = {
  access: ({orgUserType}) => isInternalUserType(orgUserType),
  groupToken: 'pageMenu:advancedGroup',
  icon: ListPlus,
  id: 'advanced',
  items: [
    {
      access: ({settings: {useTimeTracking}}) => useTimeTracking,
      nameToken: 'pageMenu:createOffer',
      path: createOfferRoute,
    },
    {
      access: ({isGranted}) =>
        isGranted('FLOW_GLOBAL_PROJECT_FOLDERS_RESTORE_FROM_TRASH') ||
        isGranted('FLOW_NODE_PID_RESTORE_FROM_TRASH', anyNode),
      nameToken: 'pageMenu:projectTreeTrash',
      path: projectTreeTrashRoute,
    },
  ],
};
