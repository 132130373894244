import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../General/Common/Type';
import NodeAncestorBudgetSums from './NodeAncestorBudgetSums';

interface Props {
  nodeId: Uuid;
}

export default function NodeAncestorsBudgetSums({frame, nodeId}: Props & FramedSectionOrTileProps): ReactElement {
  const ancestry = useNodeAncestry(nodeId, true);
  const ancestorsWithBudget = boolFilter([...ancestry.groups, ancestry.project]).filter(
    ({maxEffort, maxPrice}) => maxEffort || maxPrice,
  );
  return (
    <>
      {ancestorsWithBudget.map((ancestor) => (
        <NodeAncestorBudgetSums key={ancestor.id} ancestor={ancestor} frame={frame} />
      ))}
    </>
  );
}
