import {SearchHighlightText} from '@octaved/flow/src/Components/Search/SearchHighlight';
import useMouseMoveHook, {MouseResizeEvents} from '@octaved/flow/src/Hooks/MouseMove';
import {Uuid} from '@octaved/typescript/src/lib';
import {Icon} from '@octaved/ui';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import classNames from 'classnames';
import {ArrowLeft, ArrowRight} from 'lucide-react';
import {ReactElement, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getDaysFromStart, getWidth} from '../../../../../Calculations/DateCalculations';
import {PlanningDate} from '../../../../../EntityInterfaces/PlanningDates';
import {setHoveringNodeId} from '../../../../../Modules/TeamPlanning';
import {DragMode} from '../../../../../Modules/Ui';
import {canPlanNodeSelector} from '../../../../../Selectors/PlanningDependencySelectors';
import {teamPlanningDragModeSelector} from '../../../../../Selectors/UiSelectors';
import {getRootRowClassName} from '../../../HoveredRow';
import {ZoomLevel, calendarContainerContext} from '../../CalendarContainerContext';
import DndDependency from './DndDependency/DndDependency';
import {useBarManipulatingContext} from './FunctionalBar/Context/BarManipulatingContext';

interface ActionBarProps {
  id: Uuid;
  text: string;
  showText: boolean;
  startCanBeResized: boolean;
  endCanBeResized: boolean;
  canBeDragged?: boolean;
  children: ReactNode;
  hasLessPadding?: boolean;
  canDndDependency?: boolean;
  planningDate: PlanningDate;
  isCreating?: boolean;
  isIndirectAssigned?: boolean;
  readonly?: boolean;
}

enum ResizeStartpoint {
  left,
  right,
}

export default function ActionBar({
  id,
  endCanBeResized,
  startCanBeResized,
  text,
  showText,
  children,
  canBeDragged: overrideCanBeDragged,
  hasLessPadding,
  canDndDependency,
  planningDate,
  isCreating,
  isIndirectAssigned = false,
  readonly,
}: ActionBarProps): ReactElement {
  const {cellWidth, zoomLevel, inlineTextMaxLength = 250, startDate, cellHeight} = useContext(calendarContainerContext);
  const [adjustedPlannedStart, setAdjustedPlannedStart] = useState(() =>
    isoDateTimeToIsoFormat(planningDate.plannedStart),
  );
  const [adjustedPlannedEnd, setAdjustedPlannedEnd] = useState(() => isoDateTimeToIsoFormat(planningDate.plannedEnd));
  const [top, setTop] = useState(0);
  const barManipulatingContext = useBarManipulatingContext();
  const dragMode = useSelector(teamPlanningDragModeSelector);
  const canPlanNode = useSelector(canPlanNodeSelector);
  const dispatch = useDispatch();

  useEffect(
    () => setAdjustedPlannedStart(isoDateTimeToIsoFormat(planningDate.plannedStart)),
    [planningDate.plannedStart],
  );
  useEffect(() => setAdjustedPlannedEnd(isoDateTimeToIsoFormat(planningDate.plannedEnd)), [planningDate.plannedEnd]);

  const startInDays = useMemo(
    () => getDaysFromStart(adjustedPlannedStart, startDate),
    [adjustedPlannedStart, startDate],
  );
  const widthInDays = useMemo(
    () => getWidth(adjustedPlannedStart, adjustedPlannedEnd),
    [adjustedPlannedEnd, adjustedPlannedStart],
  );

  const onMouseDownResize = useMouseMoveHook(barManipulatingContext.resize.onMove, barManipulatingContext.resize.onEnd);
  const onMouseDownCreate = useMouseMoveHook(barManipulatingContext.create.onMove, barManipulatingContext.create.onEnd);
  const onMouseDownDragging = useMouseMoveHook(
    barManipulatingContext.dragging.onMove,
    barManipulatingContext.dragging.onEnd,
  );

  let canBeDragged: boolean;
  if (dragMode === DragMode.horizontal) {
    canBeDragged = Boolean((startCanBeResized && endCanBeResized) || overrideCanBeDragged) && !readonly;
  } else {
    canBeDragged = !isIndirectAssigned && canPlanNode(id) && !readonly;
  }

  const onStart = (event: MouseResizeEvents, dir: ResizeStartpoint): void => {
    onMouseDownResize(event);
    barManipulatingContext.resize.onStart({
      setAdjustedPlannedEnd,
      setAdjustedPlannedStart,
      createOnDrag: planningDate.name === 'dummyPlanningDate',
      direction: dir,
      nodeId: id,
      plannedEnd: isoDateTimeToIsoFormat(planningDate.plannedEnd),
      plannedStart: isoDateTimeToIsoFormat(planningDate.plannedStart),
      planningDateId: planningDate.id,
    });
  };
  const _onStartDragging = (event: MouseResizeEvents): void => {
    if (isCreating) {
      onMouseDownCreate(event);
      barManipulatingContext.create.onStart({
        setAdjustedPlannedEnd,
        setAdjustedPlannedStart,
        nodeId: id,
        plannedStart: isoDateTimeToIsoFormat(planningDate.plannedStart),
      });
    } else {
      onMouseDownDragging(event);
      barManipulatingContext.dragging.onStart({
        event,
        setAdjustedPlannedEnd,
        setAdjustedPlannedStart,
        setTop,
        createOnDrag: planningDate.name === 'dummyPlanningDate',
        nodeId: id,
        plannedEnd: isoDateTimeToIsoFormat(planningDate.plannedEnd),
        plannedStart: isoDateTimeToIsoFormat(planningDate.plannedStart),
        planningDateId: planningDate.id,
      });
    }
  };
  return (
    <div
      style={{left: cellWidth * startInDays, width: cellWidth * (widthInDays + 1), top, height: cellHeight}}
      onMouseOver={() => dispatch(setHoveringNodeId(id))}
      onMouseLeave={() => dispatch(setHoveringNodeId(null))}
      className={classNames(
        'normalBarRoot absolute ml-px px-0.5 py-[3px] group-[.isVerticalDragging]:pointer-events-none',
        {
          canBeDragged,
          endCanBeResized,
          hasLessPadding,
          startCanBeResized,
          smallView: zoomLevel <= ZoomLevel['50%'],
        },
      )}
    >
      {canDndDependency && <DndDependency id={id} />}
      <div
        className={'arrowLeft arrow'}
        onMouseDown={(event) => {
          if (startCanBeResized) {
            onStart(event, ResizeStartpoint.left);
          }
        }}
        onTouchStart={(event) => {
          if (startCanBeResized) {
            onStart(event, ResizeStartpoint.left);
          }
        }}
      >
        <Icon iconColor={'darkGrey'}>
          <ArrowLeft />
        </Icon>
      </div>
      <div
        className={'content'}
        onMouseDown={(event) => {
          if (canBeDragged) {
            _onStartDragging(event);
          }
        }}
        onTouchStart={(event) => {
          if (canBeDragged) {
            _onStartDragging(event);
          }
        }}
      >
        {children}
      </div>
      <div
        className={'arrowRight arrow'}
        onMouseDown={(event) => {
          if (endCanBeResized) {
            onStart(event, ResizeStartpoint.right);
          }
        }}
        onTouchStart={(event) => {
          if (endCanBeResized) {
            onStart(event, ResizeStartpoint.right);
          }
        }}
      >
        <Icon iconColor={'darkGrey'}>
          <ArrowRight />
        </Icon>
      </div>
      {showText && (
        <div
          className={'text'}
          style={{width: `${inlineTextMaxLength - 20}px`, right: `-${inlineTextMaxLength - 10}px`}}
        >
          <SearchHighlightText text={text} />
        </div>
      )}
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .hasLessPadding {
          padding: 1px 2px;
        }

        .normalBarRoot:hover {
          z-index: 10;
        }

        .smallView {
          padding: 5px 0;
        }

        .content {
          height: 100%;
          position: relative;
          z-index: 1;
        }

        .canBeDragged .content {
          cursor: grab;
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          visibility: hidden;
          transition: visibility 120ms ease-out;
          cursor: ew-resize;
        }

        .normalBarRoot:hover.startCanBeResized .arrowLeft,
        :global(.${getRootRowClassName(id)}) .startCanBeResized .arrowLeft,
        .normalBarRoot:hover.endCanBeResized .arrowRight,
        :global(.${getRootRowClassName(id)}) .endCanBeResized .arrowRight {
          visibility: visible;
        }

        .arrowLeft {
          left: -10px;
        }

        .arrowRight {
          right: -16px;
        }

        .text {
          position: absolute;
          top: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 36px;
          pointer-events: none;
          font-size: 12px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
