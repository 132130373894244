import {createDefaultTimePeriod, TimePeriod} from '../../../Modules/Filter/TimePeriod';

export interface AdvancedBillingInPeriod {
  onlyProjectsWithNonBilledWorkPackagesInPeriod: boolean;
}

export const advancedBillingInPeriodDefault: AdvancedBillingInPeriod = {
  onlyProjectsWithNonBilledWorkPackagesInPeriod: true,
};

export interface QuickBillingInPeriod {
  period: TimePeriod;
}

export const quickBillingInPeriodDefault: QuickBillingInPeriod = {
  period: createDefaultTimePeriod(1),
};
