export const defaultAttributes = {
  fill: 'none',
  height: 24,
  stroke: 'currentColor',
  'stroke-linecap': 'round',
  'stroke-linejoin': 'round',
  'stroke-width': 2,
  viewBox: '0 0 24 24',
  width: 24,
  xmlns: 'http://www.w3.org/2000/svg',
};

type SVGProps = Record<string, string | number>;
type IconNodeChild = readonly [tag: string, attrs: SVGProps];
export type SVGIconNode = readonly [tag: string, attrs: SVGProps, children?: IconNodeChild[]];
