import {Uuid} from '@octaved/typescript/src/lib';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {Wallet} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import StandardInspectorSubMenu from '../../Drawer/StandardInspector/StandardInspectorSubMenu';
import {MenuRootItem} from '../FlowRoutes';
import {billingListInspectorRoutes} from './Inspectors/BillingListInspector';
import {getStandardInspectorRoutes} from './Inspectors/StandardInspector';

export const billingPreparationRoute = 'prepare';
export const billingRoute = 'billing';
const billingPartialRoute = 'billingPartial';
const billingListRoute = 'billingsList';

export function getBillingRoutesWithoutStandardInspector(): string[] {
  return [billingListRoute];
}

const Billing = lazy(() => import('../../Pages/Projects/Pages/Billing/Billing'));
const BillingPreparation = lazy(() => import('../../Pages/Projects/Pages/BillingPreparation/BillingPreparation'));
const BillingInPeriod = lazy(() => import('../../Pages/Projects/Pages/BillingInPeriod/BillingInPeriod'));
const BillingsList = lazy(() => import('../../Pages/Projects/Pages/BillingsList/BillingsList'));

export const billingRoutes: RouteObject[] = [
  {
    children: getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu}),
    element: <BillingPreparation />,
    path: `${billingPreparationRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu}),
    element: <Billing />,
    path: `${billingRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu}),
    element: <BillingInPeriod />,
    path: `${billingPartialRoute}/*`,
  },
  {
    children: billingListInspectorRoutes,
    element: <BillingsList />,
    path: `${billingListRoute}/*`,
  },
];

export const billingMenu: MenuRootItem = {
  access: ({orgUserType, settings: {useTimeTracking}}) => isInternalUserType(orgUserType) && useTimeTracking,
  groupToken: 'pageMenu:billingGroup',
  icon: Wallet,
  id: 'billing',
  items: [
    {
      nameToken: 'pageMenu:billingPartial',
      path: billingPartialRoute,
    },
    {
      nameToken: 'pageMenu:prepare',
      path: billingPreparationRoute,
    },
    {
      nameToken: 'pageMenu:billing',
      path: billingRoute,
    },
    {
      nameToken: 'pageMenu:billingList',
      path: billingListRoute,
    },
  ],
};

export function getBillingListRoute(billingId: Uuid | null): string {
  return `/${billingListRoute}/${billingId || '-'}`;
}
