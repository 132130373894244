import classNames from 'classnames';
import {ReactElement, ReactNode, useContext} from 'react';
import FlowOutlet from '../../Components/FlowOutlet';
import {flowStyleContext} from '../../Styles/StyleContext';
import {inspectorContext} from '../InspectorContext/InspectorContext';

export const DEFAULT_INSPECTOR_WIDTH = '550px';

interface Props {
  alternativeBackground?: boolean;
  children?: ReactNode;
  fullHeight?: boolean;
  noPadding?: boolean;
  ownScrolling?: boolean;
  width?: string;
}

export default function StandardInspectorPage({
  alternativeBackground,
  children,
  noPadding,
  ownScrolling,
  width = DEFAULT_INSPECTOR_WIDTH,
  fullHeight,
}: Props): ReactElement {
  const {
    COLORS: {BACKGROUND},
  } = useContext(flowStyleContext);
  const context = useContext(inspectorContext);
  if (!context) {
    return <FlowOutlet />;
  }

  return (
    <div className={classNames('inspectorPage', {alternativeBackground, noPadding, ownScrolling, fullHeight})}>
      {children}

      <FlowOutlet />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .inspectorPage {
          padding: 20px 25px;
          width: ${width};
          flex-shrink: 0;
        }

        .ownScrolling {
          overflow: hidden;
          flex-grow: 1;
          flex-shrink: 1;
        }

        .alternativeBackground {
          background-color: ${BACKGROUND.ALTERNATIVE_LIGHT};
          flex-grow: 1;
        }

        .noPadding {
          padding: 0;
        }

        .fullHeight {
          min-height: 100%;
          display: flex;
          align-items: stretch;
          flex-direction: column;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
