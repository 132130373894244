import {FilterState} from '@octaved/flow/src/EntityInterfaces/Filter/FilterState';
import {PlanningFilterStates} from '@octaved/flow/src/EntityInterfaces/Filter/PlanningFilters';

export type PlanningPages = 'ganttProjectPlanning' | 'teamPlanning';

function active<V>(value: V): FilterState<V> {
  return {isActive: true, value};
}

function inactive<V>(value: V): FilterState<V> {
  return {isActive: false, value};
}

export const defaultPlanningFilterStates: {
  [P in PlanningPages]: Partial<PlanningFilterStates>;
} = {
  ganttProjectPlanning: {
    labelIds: inactive([]),
    responsibleUnits: inactive([]),
    show3MonthPlanning: active(true),
    taskIsDone: inactive(false),
    unitRoleIds: inactive([]),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(true),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
  },
  teamPlanning: {
    customerIds: inactive([]),
    labelIds: inactive([]),
    projectFolderIds: inactive([]),
    projectIds: inactive([]),
    show3MonthPlanning: active(true),
    workPackageIsApprovedForBilling: inactive(false),
    workPackageIsArchived: active(false),
    workPackageIsCompleted: inactive(true),
    workPackageIsLocked: inactive(false),
    workPackageIsOffer: inactive(false),
  },
};
