import {error} from '@octaved/env/src/Logger';
import {ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {RightsExport} from '@octaved/security/src/Authorization/Authorization';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {Uuid} from '@octaved/typescript/src/lib';
import {rightsReducers} from '@octaved/users/src/Modules/CurrentOrgUserRights';
import {NodeCreationOptions} from '../EntityInterfaces/Nodes';
import {FLOW_COPY_PID_REQUEST, FLOW_CREATE_NODE_REQUEST} from './ActionTypes';
import {
  CopyPidRequestEvent,
  GroupCreatedEvent,
  MaterialResourceCreatedEvent,
  NodeCreatedEvent,
  ProjectCreatedEvent,
  SubWorkPackageCreatedEvent,
  TaskCreatedEvent,
  TasksCopiedEvent,
  WorkPackageCreatedEvent,
} from './Events';

/**
 * Takes the right definition node id of the parentNodeId and sets it as right definition node id for nodeId
 */
function reduceTakeParentRights(state: RightsExport, nodeId: Uuid | Uuid[], parentNodeId: Uuid): RightsExport {
  const rightSetIdent = state.nodeRights.nodeIdToRightSetId[parentNodeId];
  if (rightSetIdent) {
    const patch: Record<string, string> = {};
    (Array.isArray(nodeId) ? nodeId : [nodeId]).forEach((id) => (patch[id] = rightSetIdent));
    return mergeStates(state, {nodeRights: {nodeIdToRightSetId: patch}});
  }
  return state;
}

function reduceCreateNodeRequest(state: RightsExport, action: ServerRequestAction<NodeCreationOptions>): RightsExport {
  const parentNodeId = action.options.urlParams.parentNodeId || action.options.data.parentNodeId;
  if (!parentNodeId) {
    error(`Missing 'parentNodeId' in ${action.type}`);
  }
  const newId = action.options.data.id;
  return newId ? reduceTakeParentRights(state, newId, parentNodeId) : state;
}

rightsReducers.add<
  | GroupCreatedEvent
  | MaterialResourceCreatedEvent
  | NodeCreatedEvent
  | ProjectCreatedEvent
  | SubWorkPackageCreatedEvent
  | TaskCreatedEvent
  | WorkPackageCreatedEvent
>(
  [
    'flow.GroupCreatedEvent',
    'flow.MaterialResourceCreatedEvent',
    'flow.NodeCreatedEvent',
    'flow.ProjectCreatedEvent',
    'flow.SubWorkPackageCreatedEvent',
    'flow.TaskCreatedEvent',
    'flow.WorkPackageCreatedEvent',
  ],
  (state, action) => reduceTakeParentRights(state, action.nodeId, action.parentNodeId),
);

rightsReducers.add<TasksCopiedEvent>('flow.TasksCopiedEvent', (state, action) => {
  return reduceTakeParentRights(state, Object.keys(action.newTaskIdsToParentIds), action.toWorkPackageId);
});

rightsReducers.add(FLOW_CREATE_NODE_REQUEST, reduceCreateNodeRequest);

rightsReducers.add<CopyPidRequestEvent>(FLOW_COPY_PID_REQUEST, (state, action) => {
  //This is just temporary until the rights are reloaded, which we must do because project role assignments are
  // copied aswell, leading to different definition ids here:
  return reduceTakeParentRights(state, action.copyNode.id, action.targetParentNodeId);
});
