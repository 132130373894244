import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {useNodeAncestry} from '../../../Modules/Selectors/NodeTreeSelectors';
import {useTimeTrackingSelector} from '../../../Modules/Selectors/SettingSelectors';
import {workPackageOptionsUiSelector} from '../../../Modules/Selectors/UiSelectors';
import TaskProgress from './Progress/TaskProgress';
import TrackedTimeProgress from './Progress/TrackedTimeProgress';

interface Props {
  node: WorkPackage | SubWorkPackage;
}

export default function Progress({node}: Props): ReactElement | null {
  const {project, workPackage} = useNodeAncestry(node.id, true);
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const {progressBar} = useSelector(workPackageOptionsUiSelector);
  const showTaskProgress = !useTimeTracking || progressBar === 'completedTasks';
  if (project?.isTemplate || workPackage?.isOffer) {
    return null;
  }
  if (showTaskProgress) {
    return <TaskProgress nodeId={node.id} />;
  }
  return <TrackedTimeProgress node={node} />;
}
