import {nodeIdInTree, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import ProjectFolderSelector from '@octaved/node-table/src/QuickFilters/Definitions/Components/ProjectFolderSelector/ProjectFolderSelector';
import {Uuid} from '@octaved/typescript/src/lib';
import {BillingQuickFilterConfig, NodeQuickFilterConfig, QuickFilterConfig} from '../Config';

const projectFolderQuickFilterConfig = {
  access: ({settings}) => settings.useProjectFolders,
  defaultValue: null,
  Filter: ({setValue, value, isActive}) => (
    <ProjectFolderSelector selectedFolderId={value} selectFolderId={setValue} isActive={isActive} />
  ),
  ident: 'projectFolder',
} as const satisfies QuickFilterConfig<Uuid | null>;

export const billingProjectFolderQuickFilterConfig = {
  ...projectFolderQuickFilterConfig,
  toSearchQuery: (value) => (value ? ['rootNodeId', value] : null),
} as const satisfies BillingQuickFilterConfig<Uuid | null>;

export const nodeProjectFolderQuickFilterConfig = {
  ...projectFolderQuickFilterConfig,
  toSearchQuery: (value) => (value ? withDescendants(nodeIdInTree(value), false) : null),
} as const satisfies NodeQuickFilterConfig<Uuid | null>;
