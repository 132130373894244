import {ReactElement, useContext, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {AccessibleItem, isItemAccessibleSelector} from '../../AccessibleItem';

import {specialPlanningRoute} from '@octaved/node-details/src/LargeView/RoutePaths';
import {useIsPlanningPage} from '@octaved/planning/src/Hooks/UseIsPlanningPage';
import {simulationModeActiveSelector} from '@octaved/planning/src/Selectors/UiSelectors';
import LinkTab from '../../Components/Layout/Tabs/LinkTab';
import {LinkTabItem} from '../../Components/Layout/Tabs/LinkTabs';
import {pageLockContext} from '../../Components/PageLock/PageLockContext';
import {useGetActiveLink} from '../../Routing/ActiveLink';
import {inspectorContext, InspectorContextSelectedTypes} from '../InspectorContext/InspectorContext';

export type InspectorTabItem = LinkTabItem & AccessibleItem;
export type TabRecords = Partial<Record<InspectorContextSelectedTypes, InspectorTabItem[]>>;

function useInspectorPageIsValid(tabs: InspectorTabItem[]): boolean {
  const location = useLocation();
  const getActiveLink = useGetActiveLink();
  return useMemo(() => {
    if (!tabs.length) {
      return true;
    }
    return Boolean(getActiveLink(tabs, location.pathname));
  }, [tabs, getActiveLink, location.pathname]);
}

interface Props {
  tabConfig?: TabRecords;
}

export default function StandardInspectorTabs({tabConfig}: Props): ReactElement | null {
  const context = useContext(inspectorContext);
  const isItemAccessible = useSelector(isItemAccessibleSelector);
  const selectedType = context?.selectedType;
  const tabs = useMemo(() => {
    let tabs: InspectorTabItem[] = [];
    if (tabConfig && selectedType && tabConfig[selectedType]) {
      tabs = (tabConfig[selectedType] as InspectorTabItem[]).filter(isItemAccessible);
    }
    return tabs;
  }, [tabConfig, selectedType, isItemAccessible]);

  const navigate = useNavigate();
  const {isLocked} = useContext(pageLockContext);
  const isPlanningPage = useIsPlanningPage();
  const isInSimulationMode = useSelector(simulationModeActiveSelector);

  const inspectorPageIsValid = useInspectorPageIsValid(tabs);
  useEffect(() => {
    if (!inspectorPageIsValid && tabs.length) {
      navigate(tabs[0].path, {replace: true});
    }
  }, [inspectorPageIsValid, navigate, tabs]);

  if (tabs.length === 1) {
    return null;
  }

  return (
    <div className={'relative flex w-full max-w-[550px] flex-shrink-0 flex-wrap overflow-hidden'}>
      <div className={'absolute bottom-0 left-0 right-0 flex flex-col gap-y-[39px]'}>
        <div className={'h-px w-full bg-slate-200'} />
        <div className={'h-px w-full bg-slate-200'} />
        <div className={'h-px w-full bg-slate-200'} />
      </div>
      {tabs.map(({token, path, tooltipTranslation}) => (
        <LinkTab
          disabled={isLocked || (isInSimulationMode && isPlanningPage && path !== specialPlanningRoute)}
          key={path}
          token={token}
          lessHorizontalPadding={false}
          to={path}
          tooltipTranslation={tooltipTranslation}
        />
      ))}
    </div>
  );
}
