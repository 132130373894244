import {isInMaintenanceSelector} from '@octaved/env/src/EnvironmentState';
import {error, info} from '@octaved/env/src/Logger';
import {checkIsInMaintenanceMode} from '@octaved/env/src/Maintenance';
import {identityJwtSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {multiAction} from '@octaved/store/src/MultiAction';
import {ActionDispatcher, dispatch, onSelectorChange} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {getInstanceUuid} from '@octaved/utilities';
import debounce from 'lodash/debounce';
import type {Action} from 'redux';
import {io, Socket} from 'socket.io-client';

export interface WebsocketEvent {
  responsibleInstanceId: Uuid;
}

type Listener<D = unknown> = ((data: D) => Promise<void> | Action | ActionDispatcher) | ((data: D) => void);

interface EventMetas {
  emittedAt: number;
  responsibleInstanceId: Uuid | null;
}

const eventListeners = new Map<string, Set<Listener>>();
const reduxListeners = new Map<string, Set<Listener>>();

export function onEvent<D extends object>(event: string, listener: Listener<D>): () => boolean {
  const set = eventListeners.set(event, eventListeners.get(event) || new Set()).get(event)!;
  set.add(listener as (data: unknown) => undefined);
  return () => set.delete(listener as (data: unknown) => undefined); //unsubscribe
}

export function onReduxAction<D extends Action>(type: D['type'], listener: Listener<D>): () => boolean {
  const set = reduxListeners.set(type, reduxListeners.get(type) || new Set()).get(type)!;
  set.add(listener as (data: unknown) => undefined);
  return () => set.delete(listener as (data: unknown) => undefined); //unsubscribe
}

function execListeners(listeners: Set<Listener> | undefined, data: unknown): void {
  if (listeners) {
    listeners.forEach(async (listener) => {
      try {
        const result = await listener(data);
        if (result) {
          dispatch(result);
        }
      } catch (e) {
        error(e);
      }
    });
  }
}

const reduxActionsStack: Action[] = [];
const dispatchDebounced = debounce(() => {
  const stack = reduxActionsStack.slice(0);
  reduxActionsStack.length = 0;
  if (stack.length) {
    dispatch(stack.length > 1 ? multiAction(stack) : stack[0]);
  }
}, 10);

let lastReceivedEventEmittedAt = Date.now();

interface Auth {
  token: string;
}

type AuthSocket = Socket & {auth: Auth};

let socket: AuthSocket | null = null;

function connect(jwt: string): void {
  const url = `${location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}`;
  socket = io(url, {
    auth: {token: jwt} satisfies Auth,
    path: '/_ws',
    reconnection: true,
    transports: ['websocket'],
  }) as AuthSocket;

  socket.on('redux', (action: Action, metas: EventMetas) => {
    if (socket) {
      lastReceivedEventEmittedAt = metas.emittedAt;
    }
    reduxActionsStack.push({...metas, ...action});
    dispatchDebounced();
    execListeners(reduxListeners.get(action.type), action);
  });

  socket.onAny((event: string, data: unknown) => execListeners(eventListeners.get(event), data));

  socket.on('connect', () => {
    info('socket:connect', {recovered: socket?.recovered});
    if (socket?.recovered !== true && lastReceivedEventEmittedAt > 0) {
      socket?.emit('lastReceivedEventEmittedAt', lastReceivedEventEmittedAt);
    }
  });
  socket.on('disconnect', () => info('socket:disconnect'));

  socket.on('connect_error', (err) => {
    const jwtErrorPrefix = 'JWT not decodable: ';
    if (err.message.startsWith(jwtErrorPrefix)) {
      try {
        const details = JSON.parse(err.message.slice(jwtErrorPrefix.length));
        if (details?.err?.name === 'TokenExpiredError') {
          window.location.reload();
          return;
        }
      } catch (e) {
        error(e);
      }
    }
    if ((err as Error & {type: string}).type === 'TransportError') {
      //This error occurs if the websocket server is not reachable. We don't want to log that.
      //But this could mean we are in maintenance:
      checkIsInMaintenanceMode();
      return;
    }
    error(err);
    //We could reload here, too, but it's not clear this will solve the problem as to why the connection failed.
  });
}

//Connect as soon as there is a JWT in the store and keep it updated:
onSelectorChange(identityJwtSelector, true, (jwt) => {
  if (jwt) {
    if (socket) {
      socket.auth.token = jwt;
    } else {
      connect(jwt);
    }
  }
});

onSelectorChange(isInMaintenanceSelector, true, (inMaintenance) => {
  if (inMaintenance) {
    socket?.disconnect();
  } else {
    socket?.connect();
  }
});

export function reduceForeignEvent<T, A extends WebsocketEvent>(
  fn: (state: T, action: A) => T,
): (state: T, action: A) => T {
  return (state, action) => (action.responsibleInstanceId === getInstanceUuid() ? state : fn(state, action));
}
