import {ReactElement, ReactNode, RefObject, useEffect, useRef} from 'react';

export function useOnOutsideClick(ref: RefObject<HTMLElement>, onClick: () => void): void {
  const cbRef = useRef(onClick);
  cbRef.current = onClick;
  useEffect(() => {
    const handle = (event: MouseEvent): void => {
      if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
        cbRef.current();
      }
    };
    document.addEventListener('click', handle, true);
    return () => {
      document.removeEventListener('click', handle, true);
    };
  }, [ref]);
}

// noinspection FunctionNamingConventionJS
export function OnOutsideClick({
  as = 'div',
  children,
  onClick,
}: {
  as?: 'div' | 'span';
  children: ReactNode | ReactNode[];
  onClick: () => void;
}): ReactElement {
  const ref = useRef(null);
  useOnOutsideClick(ref, onClick);
  if (as === 'div') {
    return <div ref={ref}>{children}</div>;
  }
  return <span ref={ref}>{children}</span>;
}
