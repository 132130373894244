import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import StandardInspectorHeaderSubRow from '../../../Drawer/StandardInspector/StandardInspectorHeaderSubRow';
import {useWorkPackage} from '../../../Modules/Hooks/Nodes';
import IsCompletedButton from './IsCompletedButton';

interface Props {
  workPackageId: string;
}

export default function InspectorWorkflowStatus({workPackageId}: Props): ReactElement | null {
  const workPackage = useWorkPackage(workPackageId);
  const canManage = useIsGranted('FLOW_NODE_WORK_PACKAGE_MANAGE_PROGRESS', workPackageId) && !workPackage?.isArchived;
  if (!canManage || !workPackage) {
    return null;
  }
  return (
    <StandardInspectorHeaderSubRow>
      <IsCompletedButton node={workPackage} />
    </StandardInspectorHeaderSubRow>
  );
}
