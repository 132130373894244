import {initialLargeViewTaskUiState, LargeViewTasksUiState} from './LargeViewTasks';

export interface NodeDetailsUiState {
  controlling: {
    includeArchivedInCharts: boolean;
  };
  largeViewTasks: LargeViewTasksUiState;
  lastInsectorTab: string | null;
}

export const initialNodeDetailsUiState: NodeDetailsUiState = {
  controlling: {
    includeArchivedInCharts: false,
  },
  largeViewTasks: initialLargeViewTaskUiState,
  lastInsectorTab: null,
};
