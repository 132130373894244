import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {validateIsoDateString} from '@octaved/validation';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {StoreWorkPackageBilling, WorkPackageBilling} from '../../EntityInterfaces/WorkPackageBilling';
import {FlowState} from '../State';
import {getProjectsFilterValueSelector} from './UiPages/ProjectsSelector';

export function getWorkPackageBillingStoreKeyMapper(from: string, to: string): (workPackageId: Uuid) => string {
  if (from && to) {
    validateIsoDateString(from);
    validateIsoDateString(to);
    return (workPackageId) => `${workPackageId}-${from}-${to}`;
  }
  if (from || to) {
    throw new Error('from and to must be set both or neither');
  }
  return (workPackageId) => workPackageId;
}

const workPackageBillingSelector = (state: FlowState): StoreWorkPackageBilling => state.entities.workPackageBilling;
export const workPackageBillingStateSelector = (state: FlowState): EntityStates =>
  state.entityStates.workPackageBilling;

const workPackageBillingPeriodSelector = createSelector(
  getProjectsFilterValueSelector,
  (getProjectsFilterValue) => getProjectsFilterValue('billingInPeriod', 'quickBillingInPeriod').period,
);

export const getWorkPackageBillingActivePeriodFromToSelector = createSelector(
  workPackageBillingPeriodSelector,
  ({from, to}) =>
    (isOnBillingInPeriod: boolean) =>
      isOnBillingInPeriod ? [from, to] : ['', ''],
);

export const getWorkPackageBillingForActivePeriodSelector = createSelector(
  workPackageBillingSelector,
  getWorkPackageBillingActivePeriodFromToSelector,
  (storeBilling, getWorkPackageBillingActivePeriodFromTo) =>
    memoize(
      (workPackageId: Uuid, isOnBillingInPeriod: boolean): WorkPackageBilling | undefined => {
        const [from, to] = getWorkPackageBillingActivePeriodFromTo(isOnBillingInPeriod);
        const key = getWorkPackageBillingStoreKeyMapper(from, to)(workPackageId);
        return storeBilling[key];
      },
      (...args) => args.join(''),
    ),
);
