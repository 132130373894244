import {NodeType as EnumNodeType, NodeTimeControlMode} from '@octaved/env/src/dbalEnumTypes';
import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {DropdownPopupItem, DropdownPopupItemType} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import {NodeTimeControl} from '@octaved/flow/src/EntityInterfaces/TimeControlledEntity';
import {TimeControlledNode, isProject, isTimeControlledNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {
  createNodeTimeControl,
  isOverlappingTimeControl,
  isTimeControlActive,
} from '@octaved/flow/src/Modules/TimeControlledEntity';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  IconButton,
  Message,
  TWLabel,
  TWSelect,
  TWSelectContent,
  TWSelectItem,
  TWSelectTrigger,
  TWSelectValue,
  cn,
} from '@octaved/ui';
import {castFilter} from '@octaved/utilities';
import {MoreHorizontal, PlusCircle, Trash2, TriangleAlert} from 'lucide-react';
import {Fragment, ReactElement, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Checkbox} from 'semantic-ui-react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Custom from './Components/Custom';
import Months from './Components/Months';
import Quarters from './Components/Quarters';
import Year from './Components/Year';
import TimeControlFormatted from './TimeControlFormatted';

const parentLabel: Record<TimeControlledNode['nodeType'], string> = {
  [EnumNodeType.project]: 'nodeDetails:field.timeControl.parent.project',
  [EnumNodeType.group]: 'nodeDetails:field.timeControl.parent.subProject',
  [EnumNodeType.workPackage]: '', //Will never be the case
};

const typeLabel = {
  [EnumNodeType.project]: '', //Will never be the case
  [EnumNodeType.group]: 'nodeDetails:field.timeControl.type.subProject',
  [EnumNodeType.workPackage]: 'nodeDetails:field.timeControl.type.workPackage',
};

const options = [
  {
    nameToken: 'pages:projects.inspector.manage.general.monthProject',
    value: NodeTimeControlMode.monthly,
  },
  {
    nameToken: 'pages:projects.inspector.manage.general.quarterPid',
    value: NodeTimeControlMode.quarterly,
  },
  {
    nameToken: 'pages:projects.inspector.manage.general.yearPid',
    value: NodeTimeControlMode.yearly,
  },
  {
    nameToken: 'pages:projects.inspector.manage.general.customFromTo',
    value: NodeTimeControlMode.custom,
  },
];

export function useTimeControlledAncestors(nodeId: Uuid): TimeControlledNode[] {
  const {ancestors} = useNodeAncestry(nodeId, false);
  const timeControlledAncestorNodes = castFilter(ancestors, isTimeControlledNode);
  return timeControlledAncestorNodes.filter((node) => isTimeControlActive(node));
}

export interface PatchPartial {
  (partial: Partial<Pick<NodeTimeControl, 'from' | 'to' | 'showAfterName'>>): void;
}

export interface NodeTimeControlSettingsProps {
  patch: (data: NodeTimeControl | null) => void;
  readonly: boolean;
  node: TimeControlledNode;
  useToggleForSettings?: boolean;
}

export default function NodeTimeControlSettings({
  frame: Frame,
  node,
  patch,
  readonly,
}: NodeTimeControlSettingsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const [showOwnTimeControl, setShowOwnTimeControl] = useState<boolean>(Boolean(node.timeControl) || isProject(node));
  const timeControlledAncestors = useTimeControlledAncestors(node.id);
  const localTimeControl = node.timeControl || null;
  const patchPartial: PatchPartial = (partial) => patch({...localTimeControl, ...partial} as NodeTimeControl);

  useEffect(() => {
    if (timeControlledAncestors.length === 0) {
      setShowOwnTimeControl(true);
    }
  }, [timeControlledAncestors]);

  const overlapsParent =
    localTimeControl &&
    timeControlledAncestors.find((parentTimeControlEntity) =>
      isOverlappingTimeControl({timeControl: localTimeControl}, parentTimeControlEntity),
    );

  const removeItem = useMemo(() => {
    return [
      {
        hidden: !node.timeControl && timeControlledAncestors.length === 0,
        icon: <Trash2 />,
        iconColor: 'red',
        onMouseDown: () => {
          if (node.timeControl) {
            patch(null);
          }
          if (!isProject(node)) {
            setShowOwnTimeControl(false);
          }
        }, //If there is no time control, we just hide the own settings
        textColor: 'red',
        token: 'general:delete',
        type: DropdownPopupItemType.item,
      },
    ] as DropdownPopupItem[];
  }, [patch, node, timeControlledAncestors]);

  const addItem = useMemo(() => {
    return [
      {
        icon: <PlusCircle />,
        onMouseDown: () => setShowOwnTimeControl(true),
        token: 'nodeDetails:field.timeControl.menu.addOwnSettings',
        type: DropdownPopupItemType.item,
      },
    ] as DropdownPopupItem[];
  }, []);

  const useTimeTracking = useSelector(useTimeTrackingSelector);
  if (!useTimeTracking || (readonly && !node.timeControl && timeControlledAncestors.length === 0)) {
    return null;
  }

  return (
    <Frame
      labelToken={'nodeDetails:field.timeControl.label'}
      labelAddition={
        !readonly &&
        (isProject(node) || timeControlledAncestors.length === 0 ? (
          <DropdownPopup items={removeItem} inDrawer closeOnSelect>
            <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'xs'} />
          </DropdownPopup>
        ) : !showOwnTimeControl ? (
          <DropdownPopup items={addItem} inDrawer closeOnSelect>
            <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'xs'} />
          </DropdownPopup>
        ) : undefined)
      }
    >
      <div className={'flex flex-col gap-y-4'}>
        {timeControlledAncestors.length > 0 && (
          <>
            <div className={'grid grid-cols-[max-content_1fr] gap-x-4'}>
              {timeControlledAncestors.toReversed().map((ancestor) => (
                <Fragment key={ancestor.id}>
                  <div>{t(parentLabel[ancestor.nodeType])}:</div>
                  <TimeControlFormatted entity={ancestor} />
                </Fragment>
              ))}
            </div>
          </>
        )}

        {(!readonly || node.timeControl) && showOwnTimeControl && (
          <div className={'flex flex-col gap-y-2'}>
            {timeControlledAncestors.length > 0 && (
              <div className={cn('mt-2 flex items-center gap-x-2')}>
                <TWLabel className={'text-slate-600'}>{t(typeLabel[node.nodeType])}</TWLabel>
                {!readonly && (
                  <DropdownPopup items={removeItem} inDrawer closeOnSelect>
                    <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'xs'} />
                  </DropdownPopup>
                )}
              </div>
            )}
            <div className={'flex flex-col gap-y-2'}>
              <TWSelect
                value={localTimeControl?.mode || undefined}
                disabled={readonly}
                onValueChange={(value) =>
                  patch(createNodeTimeControl(value as NodeTimeControlMode, localTimeControl || undefined))
                }
              >
                <TWSelectTrigger className={cn('w-1/2', !localTimeControl && 'border-orange-400 bg-orange-50')}>
                  <TWSelectValue placeholder={t('general:dropdownSelectPlaceholder')} />
                </TWSelectTrigger>
                <TWSelectContent>
                  {options.map(({nameToken, value}) => (
                    <TWSelectItem value={value} key={value}>
                      {t(nameToken)}
                    </TWSelectItem>
                  ))}
                </TWSelectContent>
              </TWSelect>

              {localTimeControl && (
                <>
                  <div className={'grid w-1/2 grid-cols-[1fr_80px] gap-x-5 gap-y-2'}>
                    {localTimeControl.mode === NodeTimeControlMode.monthly && (
                      <>
                        <Months patch={patchPartial} readonly={readonly} timeControl={localTimeControl} />
                        <Year patch={patchPartial} readonly={readonly} timeControl={localTimeControl} />
                      </>
                    )}

                    {localTimeControl.mode === NodeTimeControlMode.quarterly && (
                      <>
                        <Quarters patch={patchPartial} readonly={readonly} timeControl={localTimeControl} />
                        <Year patch={patchPartial} readonly={readonly} timeControl={localTimeControl} />
                      </>
                    )}

                    {localTimeControl.mode === NodeTimeControlMode.yearly && (
                      <Year patch={patchPartial} readonly={readonly} timeControl={localTimeControl} />
                    )}

                    {localTimeControl.mode === NodeTimeControlMode.custom && (
                      <div className={'col-span-2'}>
                        <Custom patch={patchPartial} readonly={readonly} timeControl={localTimeControl} />
                      </div>
                    )}
                  </div>

                  <Checkbox
                    label={<label>{t('nodeDetails:field.timeControl.extendName')}</label>}
                    checked={localTimeControl.showAfterName}
                    onChange={(_e, {checked}) => patchPartial({showAfterName: !!checked})}
                    disabled={readonly}
                  />
                  {!!overlapsParent && (
                    <Message
                      colorScheme={'warning'}
                      icon={TriangleAlert}
                      size={'sm'}
                      className={'mt-2 items-start gap-x-2'}
                    >
                      {t('nodeDetails:field.timeControl.overlappingWarning')}
                    </Message>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Frame>
  );
}
