import {FlowState} from '@octaved/flow/src/Modules/State';
import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {createUseCombinedSearch, SearchResult, SearchResultWithLoading} from '@octaved/hooks/src/CombinedSearch';
import {addReducerToMapOrCollection, ReducerMapOrCollection} from '@octaved/hooks/src/Factories/ReducerAbstraction';
import {get, NetworkMethod} from '@octaved/network/src/Network';
import {UrlParams} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {Reducer} from '@octaved/store/src/Store';
import {AnyCondition} from '@octaved/utilities/src/Condition/Types';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

interface SearchSelectorResults<IdType = string> {
  [key: string]: IdType[] | undefined;
}

function getRowSearchKey<SearchIdent extends string>(ident: SearchIdent, value?: string): string {
  return value ? `${ident}-${value}` : ident;
}

export function getRowSortKey<SortBy extends string>(sortBy: SortBy): string {
  return `sort-${sortBy}`;
}

function queryRows(
  get: NetworkMethod,
  searchStateSelector: (state: FlowState) => EntityStates,
  startAction: string,
  successAction: string,
  key: string,
  route: string,
  urlParams: UrlParams,
): ThunkAct<void> {
  return (dispatch, getState) => {
    if (isOutdated(searchStateSelector(getState())[key] || {})) {
      dispatch({type: startAction, key});
      get(route, {urlParams}).then((response) => dispatch({type: successAction, response, key}));
    }
  };
}

const emptyIds: unknown[] = [];

function createUseRowTableSortBy<SortBy extends string, IdType = string>(
  searchSelector: (state: FlowState) => SearchSelectorResults<IdType>,
  searchStateSelector: (state: FlowState) => EntityStates,
  startAction: string,
  successAction: string,
  listRoute: string,
): (sortBy: SortBy) => {
  ids: IdType[];
  isLoading: boolean;
} {
  return (sortBy) => {
    const key = getRowSortKey(sortBy);
    const ids = useSelector(searchSelector)[key] || (emptyIds as IdType[]);
    const searchState = useSelector(searchStateSelector)[key];
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(queryRows(get, searchStateSelector, startAction, successAction, key, listRoute, {orderBy: sortBy}));
    }, [
      dispatch,
      key,
      sortBy,
      searchState, //searchState for reload dependency
    ]);

    return {
      ids,
      isLoading: !searchState || !isLoaded(searchState),
    };
  };
}

function createUseSearchCallback<SearchIdent extends string>(
  searchStateSelector: (state: FlowState) => EntityStates,
  startAction: string,
  successAction: string,
  searchRoute: string,
): () => (searches: ReadonlyArray<[ident: SearchIdent, value?: string]>) => ThunkAct<void> {
  return () => {
    return useCallback((searches) => {
      return (dispatch) => {
        searches.forEach(([ident, value]) => {
          const key = getRowSearchKey<SearchIdent>(ident, value);
          dispatch(
            queryRows(get, searchStateSelector, startAction, successAction, key, searchRoute, {
              ident,
              value,
            }),
          );
        });
      };
    }, []);
  };
}

export function createUseSearch<SearchIdent extends string, IdType = string>(
  searchSelector: (state: FlowState) => SearchSelectorResults<IdType>,
  searchStateSelector: (state: FlowState) => EntityStates,
  startAction: string,
  successAction: string,
  searchRoute: string,
): <TrackIsLoading extends boolean>(
  query: AnyCondition<[SearchIdent, string], IdType> | null,
  trackIsLoading?: TrackIsLoading,
) => TrackIsLoading extends true ? SearchResultWithLoading<IdType> : SearchResult<IdType> {
  const useSearchCallback = createUseSearchCallback(searchStateSelector, startAction, successAction, searchRoute);
  return (query, trackIsLoading) => {
    const search = useSearchCallback();
    const useCombinedSearch = createUseCombinedSearch<IdType, [SearchIdent, string], FlowState>(
      getRowSearchKey,
      searchSelector,
      searchStateSelector,
      search,
    );
    return useCombinedSearch({trackIsLoading: trackIsLoading as true}, query)[0];
  };
}

export function createUseRowTableList<
  StoreState extends FlowState,
  SortBy extends string,
  SearchIdent extends string,
  IdType = string,
  SuccessEventType = string,
  SuccessEvent extends {key: string; response: IdType[]; type: SuccessEventType} = {
    key: string;
    response: IdType[];
    type: SuccessEventType;
  },
>(
  searchSelector: (state: FlowState) => SearchSelectorResults<IdType>,
  searchStateSelector: (state: FlowState) => EntityStates,
  searchQuerySelector: (state: StoreState) => AnyCondition<[SearchIdent, string], IdType> | null,
  sortBySelector: (state: StoreState) => SortBy,
  startAction: string,
  successAction: string,
  listRoute: string,
  searchRoute: string,
  searchReducerMap: ReducerMapOrCollection<Reducer<SearchSelectorResults<IdType>, SuccessEvent>>,
  searchStateReducerMap: ReducerMapOrCollection<Reducer>,
): () => {
  ids: IdType[]; //resulting filtered and sorted ids
  isLoaded: boolean;
  sortedIds: IdType[]; //all sorted ids, but not filtered by any filter
} {
  addReducerToMapOrCollection(searchReducerMap, successAction, (state, {key, response}) => ({
    ...state,
    [key]: response,
  }));
  addReducerToMapOrCollection(searchStateReducerMap, startAction, createTimestampReducer('key', LOADING));
  addReducerToMapOrCollection(searchStateReducerMap, successAction, createTimestampReducer('key', LOADED));

  const useSearch = createUseSearch(searchSelector, searchStateSelector, startAction, successAction, searchRoute);

  const useRowTableSortBy = createUseRowTableSortBy<SortBy, IdType>(
    searchSelector,
    searchStateSelector,
    startAction,
    successAction,
    listRoute,
  );

  return () => {
    const sortBy = useSelector(sortBySelector);
    const {isLoading: listIsLoading, ids: sortedIds} = useRowTableSortBy(sortBy);
    const searchQuery = useSelector(searchQuerySelector);
    const query: AnyCondition<[SearchIdent, string], IdType> | null = searchQuery && {
      and: [{fixResult: sortedIds}, searchQuery],
    };
    const {isActive, isLoading: filterIsLoading, ids: filteredIds} = useSearch(query, true);
    const ids = useMemo(() => {
      if (isActive) {
        const filteredIdsSet = new Set(filteredIds);
        return sortedIds.filter((id) => filteredIdsSet.has(id));
      }
      return sortedIds;
    }, [isActive, sortedIds, filteredIds]);
    return {
      ids,
      sortedIds,
      isLoaded: !listIsLoading && !filterIsLoading,
    };
  };
}
