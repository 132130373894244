import {createContext, PropsWithChildren, ReactElement, useContext} from 'react';

interface Ctx {
  nodeId: string;
  offset?: number;
}

const ctx = createContext<Ctx | null>(null);

export function useStringPathStartFromContext(): Ctx | null {
  return useContext(ctx);
}

export default function StringPathStartFromContext({children, nodeId, offset}: PropsWithChildren<Ctx>): ReactElement {
  return <ctx.Provider value={{nodeId, offset}}>{children}</ctx.Provider>;
}
