import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {patchPlanning as patchPlanningRoute} from '../../../config/routes';
import {PlanningPatchData} from '../../Modules/Planning';
import {PlanningSimulationSnapshot} from '../../Modules/SimulationSnapshot';
import {ActionMap} from './MergeSimulationActions';

type PatchPlanningRequest = CallAction<{data: PlanningPatchData[]}>;

export function isPatchPlanningRequest(action: CallAction | undefined): action is PatchPlanningRequest {
  return action?.[CALL_API].endpoint === patchPlanningRoute;
}

export function mergePatchPlanningRequest(
  actionMap: ActionMap,
  snapshot: PlanningSimulationSnapshot,
  action: CallAction,
): boolean {
  if (!isPatchPlanningRequest(action)) {
    return false;
  }
  const {
    [CALL_API]: {endpoint: key},
  } = action;
  const prevAction = actionMap.get(key);
  let mergeResult: PatchPlanningRequest;
  if (isPatchPlanningRequest(prevAction)) {
    mergeResult = createMergedAction(prevAction, action);
  } else {
    mergeResult = action;
  }
  for (const {nodeId} of action[CALL_API].options!.data) {
    if (!snapshot.changedNodeIds.includes(nodeId)) {
      snapshot.changedNodeIds.push(nodeId);
    }
  }
  actionMap.set(key, mergeResult);

  return true;
}

function createMergedAction(actionA: PatchPlanningRequest, actionB: PatchPlanningRequest): PatchPlanningRequest {
  const data = mergePlanningData(actionA[CALL_API].options?.data, actionB[CALL_API].options?.data);
  return {
    ...actionB,
    [CALL_API]: {
      ...actionB[CALL_API],
      options: {
        ...actionB[CALL_API].options,
        data,
      },
    },
  };
}

function mergePlanningData(dataA: PlanningPatchData[] = [], dataB: PlanningPatchData[] = []): PlanningPatchData[] {
  const mergedData = [...dataA];
  for (const data of dataB) {
    const index = mergedData.findIndex((d) => d.nodeId === data.nodeId);
    if (index === -1) {
      mergedData.push(data);
    } else {
      mergedData[index] = {
        ...mergedData[index],
        ...data,
      };
    }
  }
  return mergedData;
}
