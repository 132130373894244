import {ActionDispatcher} from '@octaved/store/src/Store';
import {ButtonColorScheme, ButtonVariant} from '@octaved/ui';
import {ReactElement, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {DialogFrameProps} from '../../Dialogs/DialogFrame';
import {FlowState} from '../../Modules/State';
import ConfirmDialog from './ConfirmDialog';

export interface ConfirmPopupProps {
  title: string;
  content: ReactNode;
  buttonLabel: string;
  buttonIcon: ReactElement;
  buttonColorScheme?: ButtonColorScheme;
  buttonVariant?: ButtonVariant;
  onConfirm: () => Promise<boolean> | boolean;
  onCancel?: () => void;
  children: ReactNode;
  size?: DialogFrameProps['size'];
  afterSubmit?: () => ActionDispatcher<void, FlowState>;
  noConfirm?: boolean | (() => boolean);
  confirmationText?: string;
}

export default function ConfirmPopup({
  buttonColorScheme,
  buttonIcon,
  buttonLabel,
  buttonVariant,
  children,
  content,
  onCancel,
  onConfirm,
  title,
  size = 'mini',
  afterSubmit,
  noConfirm,
  confirmationText = '',
}: ConfirmPopupProps): ReactElement {
  const [challenge, setChallenge] = useState(false);
  const setChallengeRef = useRef<typeof setChallenge | undefined>(setChallenge);
  setChallengeRef.current = setChallenge;
  useEffect(() => () => (setChallengeRef.current = undefined), []);
  const dispatch = useDispatch();

  const cancelCallback = useCallback(() => {
    setChallenge(false);
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const submitCallback = useCallback(async () => {
    if (await onConfirm()) {
      if (setChallengeRef.current) {
        setChallengeRef.current(false);
      }
      if (afterSubmit) {
        dispatch(afterSubmit());
      }
    }
  }, [dispatch, afterSubmit, onConfirm]);

  return (
    <>
      <div className={'trigger'} onClick={() => setChallenge(true)}>
        {children}
      </div>
      {challenge && (
        <ConfirmDialog
          buttonColorScheme={buttonColorScheme}
          title={title}
          buttonLabel={buttonLabel}
          buttonIcon={buttonIcon}
          buttonVariant={buttonVariant}
          confirmationText={confirmationText}
          size={size}
          noConfirm={noConfirm}
          cancelCallback={cancelCallback}
          submitCallback={submitCallback}
        >
          {content}
        </ConfirmDialog>
      )}
    </>
  );
}
