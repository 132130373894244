import {DateTimeStr} from '@octaved/typescript';
import {Icon, Tooltip} from '@octaved/ui';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Link} from 'lucide-react';
import {ReactElement} from 'react';
import {useFormattedDateText} from '../../Hooks/FormattedDateText';

interface Props {
  plannedStart: DateTimeStr | null;
  plannedEnd: DateTimeStr | null;
  hasGoToProjectPlanningInfo?: boolean;
  showTwoLines?: boolean;
}

export default function PlanningDate({
  hasGoToProjectPlanningInfo = false,
  plannedEnd,
  plannedStart,
  showTwoLines,
}: Props): ReactElement {
  const formatDate = useFormattedDateText();

  return (
    <>
      {plannedStart && plannedStart !== plannedEnd && (
        <>
          {formatDate(isoDateTimeToIsoFormat(plannedStart))} -{showTwoLines ? <br /> : ' '}
          {formatDate(isoDateTimeToIsoFormat(plannedEnd))}
        </>
      )}
      {plannedStart && plannedStart === plannedEnd && <>{formatDate(isoDateTimeToIsoFormat(plannedEnd))}</>}

      {hasGoToProjectPlanningInfo && (
        <Tooltip toolTipTranslation={'taskList:dependentTask'}>
          <div className={'linkIcon'}>
            <Icon iconColor={'blue'} inline>
              <Link />
            </Icon>
          </div>
        </Tooltip>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .linkIcon {
          display: inline-flex;
          padding: 5px 8px;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
