import {createSelector} from 'reselect';
import {FlowState} from '../../State';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['teamTasks']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.teamTasks);
}

export const teamTasksCollapsedUnitsSelector = ui((state) => state.collapsedUnits);
export const teamTasksLeftDrawerUiStateSelector = ui((state) => state.leftDrawer);
export const teamTasksSearchSelector = ui((state) => state.search);
export const teamTasksShowTasksOfOtherUsersSelector = ui((state) => state.showTasksOfOtherUsers);
export const teamTasksUnitsSelector = ui((state) => state.units);
export const teamTasksVisibleTasksSelector = ui((state) => state.visibleTasks);
export const teamTasksUnitIdsSelector = createSelector(teamTasksUnitsSelector, (units) =>
  units.map(({unitId}) => unitId),
);
