import {identityIsEmailVerifiedSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {useHasLicenseBar} from '@octaved/license/src/License';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {useMatch} from 'react-router-dom';
import css from 'styled-jsx/css';
import {projectsSubMenuCollapsedSelector} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {pageMenuExpandedSelector} from '../../Modules/Selectors/UiSelectors';
import {EXPANDED_MENU_WIDTH, PAGE_MENU_WIDTH} from '../../Pages/PageMenu';
import {FlowStyleBuilder, useFlowStyle} from '../../Styles/StyleContext';
//#region <styles>
/*language=SCSS*/
const getStyle: FlowStyleBuilder = () => css.resolve`
  .popdown {
    position: fixed;
    background-color: #fff;
    bottom: 0;
    z-index: 6;
    top: 70px;
  }

  .popdown.userIsNotVerified,
  .popdown.isTrial {
    top: 110px;
  }

  .popdown.userIsNotVerified.isTrial {
    top: 150px;
  }
`;

//#endregion

interface Props {
  active: boolean;
}

const variants = {
  hidden: {y: '-100vw', opacity: 0, transition: {duration: 0.48, type: 'tween'}},
  visible: {y: 0, opacity: 1, transition: {duration: 0.24}},
};

export default function AdvancedFilterWrapper({active, children}: PropsWithChildren<Props>): ReactElement {
  const {styles, className} = useFlowStyle(getStyle);
  const expandedPageMenu = useSelector(pageMenuExpandedSelector);
  const collapsedSideMenu = useSelector(projectsSubMenuCollapsedSelector);

  const routeMatch = useMatch<'subPage', string>('/:subPage/*');
  const pageHasSubMenu = Boolean(routeMatch && routeMatch.params.subPage === 'projectControlling');
  const userIsNotVerified = !useSelector(identityIsEmailVerifiedSelector);
  const isTrial = useHasLicenseBar();
  const style = useMemo(
    () => ({
      bottom: '0px',
      left:
        (expandedPageMenu ? EXPANDED_MENU_WIDTH : PAGE_MENU_WIDTH) +
        (pageHasSubMenu ? (collapsedSideMenu ? 29 : 211) : 0) +
        'px',
      right: 0,
    }),
    [expandedPageMenu, pageHasSubMenu, collapsedSideMenu],
  );

  return createPortal(
    <AnimatePresence>
      {active && (
        <motion.div
          key={'popdown-advanced'}
          initial={'hidden'}
          className={classNames(className, 'popdown', {isTrial, userIsNotVerified})}
          animate={'visible'}
          variants={variants}
          exit={'hidden'}
          style={style}
        >
          {children}
        </motion.div>
      )}
      {styles}
    </AnimatePresence>,
    document.querySelector('.flow')!,
  );
}
