import {useStandaloneClock} from '@octaved/flow/src/Components/WorkingTime/ClockContext';
import {ReactElement} from 'react';
import {TIME} from '../DateFormatFunctions';
import {useFormatInWords} from '../DateFormatHooks/FormatInWords';

export default function Now(): ReactElement {
  const formatInWords = useFormatInWords({type: TIME});
  const now = useStandaloneClock();
  return <>{formatInWords(now)}</>;
}
