import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWAutosaveInput} from '@octaved/ui';
import {format, parse} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement, useEffect, useState} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodePriceProps {
  node: WorkPackage;
  patch: (pid: Partial<WorkPackage>) => void;
  readonly?: boolean;
  requiredByAutosave?: boolean;
}

export default function NodePrice({
  frame: Frame,
  node,
  patch,
  readonly,
  requiredByAutosave,
}: NodePriceProps & FramedSectionOrTileProps): ReactElement | null {
  const canRead = useIsGranted('FLOW_NODE_READ_PRICES', node.id);
  const priceFormatted = node.fixedPrice ? format(node.fixedPrice, 2) : '';
  const [value, setValue] = useState(priceFormatted);
  useEffect(() => setValue(priceFormatted), [priceFormatted]);
  if (canRead) {
    return (
      <Frame labelToken={'nodeDetails:field.price.label'}>
        {readonly && <>{priceFormatted} €</>}
        {!readonly && (
          <TWAutosaveInput
            hasWarning={requiredByAutosave && !value}
            hasChanges={value !== priceFormatted}
            wrapperClassname={'w-numberInput'}
            value={value || ''}
            setValue={setValue}
            onSave={(fixedPrice) => patch({fixedPrice: fixedPrice ? parse(fixedPrice) : null})}
          />
        )}
      </Frame>
    );
  }
  return null;
}
