import {FlowState} from '@octaved/flow/src/Modules/State';
import {PlanningPatchData} from '../../Modules/Planning';
import {updateDependentDependencies} from './DependentDependenciesUpdater';
import {updateLogicalDependencies} from './LogicalDependencyUpdater';
import {updateMilestones} from './MilestoneUpdater';
import {updateNodes} from './NodeUpdate';
import {updatePlanningDates} from './PlanningDatesUpdater';
import {updatePlanningDependencies} from './PlanningDependencyUpdater';
import UpdatePlanningState, {UpdatePlanningResult} from './UpdatePlanningState';

export function updatePlanning(
  state: FlowState,
  updateDataList: PlanningPatchData[],
  isSimulation = false,
): UpdatePlanningResult {
  const updateState = new UpdatePlanningState(state, isSimulation);

  for (const updateData of updateDataList) {
    updateNodes(updateState, updateData);
    updateMilestones(updateState, updateData);
    updatePlanningDates(updateState, updateData);
    updateLogicalDependencies(updateState, updateData);
    updatePlanningDependencies(updateState, updateData);
  }
  updateDependentDependencies(updateState);
  return updateState.getResult();
}
