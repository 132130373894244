import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {
  getAllDescendantIdsSelector,
  getNodeAncestrySelector,
} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getTaskSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {EntityStates} from '@octaved/store/src/EntityState';
import {DateTimeStr} from '@octaved/typescript';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {getMinMaxFromPlanningDates} from '../Calculations/DateCalculations';
import {PlanningDates, PlanningDatesList} from '../EntityInterfaces/PlanningDates';
import {PlanningState} from '../PlanningState';

export const planningDateEntityStatesSelector = (state: PlanningState): EntityStates => state.entityStates.planningDate;
export const planningDatesSelector = (state: PlanningState): PlanningDates => state.entities.planningDate;

export const getPlanningDateSelector = createSelector(
  planningDatesSelector,
  (planningDates) => (id: MaybeUuid) => (id && planningDates[id]) || null,
);

export function sortPlanningDates(planningDates: PlanningDatesList): PlanningDatesList {
  return planningDates.toSorted((a, b) => a.plannedStart.localeCompare(b.plannedStart));
}

export const getPlanningDatesForNodeSelector = createSelector(
  getPlanningDateSelector,
  getNodeSelector,
  (getPlanningDate, getNode) =>
    memoize((nodeId: MaybeUuid): PlanningDatesList => {
      const node = getNode(nodeId);
      if (node) {
        return sortPlanningDates(boolFilter(node.planningDates.map((id) => getPlanningDate(id))));
      }
      return [];
    }),
);

export const getInheritedPlanningDatesForNodeSelector = createSelector(
  getNodeSelector,
  getNodeAncestrySelector,
  getPlanningDatesForNodeSelector,
  (getNode, getNodeAncestry, getPlanningDatesForNode) =>
    memoize((nodeId: MaybeUuid): PlanningDatesList => {
      const node = getNode(nodeId);
      let planningDateNodeId = nodeId;
      if (isTask(node) && node.planningDates.length === 0) {
        const nodeAncestry = getNodeAncestry(nodeId);
        planningDateNodeId = nodeAncestry.subWorkPackage?.id || nodeAncestry.workPackage?.id;
      }
      return getPlanningDatesForNode(planningDateNodeId);
    }),
);

export const getAssignedPlanningDatesForNodeSelector = createSelector(
  getPlanningDateSelector,
  getNodeSelector,
  (getPlanningDate, getNode) =>
    memoize((nodeId: MaybeUuid): PlanningDatesList => {
      const node = getNode(nodeId);
      if (node) {
        return boolFilter(node.assignedPlanningDates.map(({id}) => getPlanningDate(id)));
      }
      return [];
    }),
);

export const getNodeHasGapsSelector = createSelector(getNodeSelector, (getNode) => (nodeId: MaybeUuid) => {
  const node = getNode(nodeId);
  if (node) {
    return node.planningDates.length > 1;
  }
  return false;
});

interface MinMaxPlanningDatesResultNullable {
  plannedEnd: null;
  plannedStart: null;
}

export interface MinMaxPlanningDatesResultNotNullable {
  plannedEnd: DateTimeStr;
  plannedStart: DateTimeStr;
}
export type MinMaxPlanningDatesResult = MinMaxPlanningDatesResultNullable | MinMaxPlanningDatesResultNotNullable;

export const getMinMaxPlanningDatesSelector = createSelector(
  getPlanningDatesForNodeSelector,
  (getPlanningDatesForNode) =>
    memoize((nodeId: MaybeUuid): MinMaxPlanningDatesResult => {
      const planningDates = getPlanningDatesForNode(nodeId);
      if (planningDates.length > 0) {
        return {
          plannedEnd: planningDates[planningDates.length - 1].plannedEnd,
          plannedStart: planningDates[0].plannedStart,
        };
      }
      return {plannedEnd: null, plannedStart: null};
    }),
);

export const getInheritedMinMaxPlanningDatesSelector = createSelector(
  getPlanningDatesForNodeSelector,
  getNodeAncestrySelector,
  (getPlanningDatesForNode, getNodeAncestry) =>
    memoize((nodeId: MaybeUuid): MinMaxPlanningDatesResult => {
      const planningDates = getPlanningDatesForNode(nodeId);
      if (planningDates.length > 0) {
        return {
          plannedEnd: planningDates[planningDates.length - 1].plannedEnd,
          plannedStart: planningDates[0].plannedStart,
        };
      } else {
        const nodeAncestry = getNodeAncestry(nodeId);
        const parentId = nodeAncestry.subWorkPackage?.id || nodeAncestry.workPackage?.id;
        const planningDates = getPlanningDatesForNode(parentId);
        if (planningDates.length > 0) {
          return {
            plannedEnd: planningDates[planningDates.length - 1].plannedEnd,
            plannedStart: planningDates[0].plannedStart,
          };
        }
      }
      return {plannedEnd: null, plannedStart: null};
    }),
);

export const calculateMinMaxPlanningDatesInSubtreeSelector = createSelector(
  getAllDescendantIdsSelector,
  getPlanningDatesForNodeSelector,
  (getAllDescendantIds, getPlanningDatesForNode) =>
    memoize((nodeId: MaybeUuid): MinMaxPlanningDatesResult => {
      const planningDates: PlanningDatesList = [];
      if (nodeId) {
        const descendants = getAllDescendantIds(nodeId, true);
        for (const descendentId of descendants) {
          planningDates.push(...getPlanningDatesForNode(descendentId));
        }
      }
      return getMinMaxFromPlanningDates(planningDates);
    }),
);

export enum TaskPlanningStatus {
  unplanned = 'unplanned',
  upcoming = 'upcoming',
  dueToday = 'dueToday',
  overdue = 'overdue',
  outSideOfWorkpackagePlanning = 'outSideOfWorkpackagePlanning',
  done = 'done',
}

export const taskPlanningStatusSelector = createSelector(
  getMinMaxPlanningDatesSelector,
  getNodeAncestrySelector,
  getTaskSelector,
  todayIsoDateSelector,
  (getMinMaxPlanningDates, getNodeAncestry, getTask, todayIsoDate) =>
    memoize((taskId: Uuid) => {
      const today = isoToIsoDateTimeFormat(todayIsoDate);
      const task = getTask(taskId);
      const {workPackage} = getNodeAncestry(taskId);
      const {plannedStart: taskPlannedStart, plannedEnd: taskPlannedEnd} = getMinMaxPlanningDates(taskId);
      const {plannedStart: wpPlannedStart, plannedEnd: wpPlannedEnd} = getMinMaxPlanningDates(workPackage?.id);

      if (task?.status === EnumFlowTaskStatus.VALUE_COMPLETE) {
        return TaskPlanningStatus.done;
      }

      if (!taskPlannedStart || !taskPlannedEnd) {
        return TaskPlanningStatus.unplanned;
      }

      if (wpPlannedStart && wpPlannedEnd && (wpPlannedStart > taskPlannedStart || wpPlannedEnd < taskPlannedEnd)) {
        return TaskPlanningStatus.outSideOfWorkpackagePlanning;
      } else if (taskPlannedEnd < today) {
        return TaskPlanningStatus.overdue;
      } else if (taskPlannedStart <= today && taskPlannedEnd >= today) {
        return TaskPlanningStatus.dueToday;
      } else if (today < taskPlannedStart) {
        return TaskPlanningStatus.upcoming;
      }

      return TaskPlanningStatus.unplanned;
    }),
);
