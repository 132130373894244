import {isDevLocal} from '@octaved/env/src/Environment';
import {DateStr, DateTimeStr, isDateStr, isDateTimeStr} from '@octaved/typescript';
import {TimeStrM} from '@octaved/typescript/src/TimeStr';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import {TFunction} from 'i18next';

dayjs.extend(calendar);
dayjs.extend(utc);

export const ISO_DATE = 'YYYY-MM-DD';
export const ISO_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const ISO_DATE_RANGE_REGEX = /^(\d{4}-\d{2}-\d{2})-(\d{4}-\d{2}-\d{2})$/;

export const DATETIME = 'datetime';
export const DATE = 'date';
export const MONTH = 'month';
export const DAY = 'day';
export const TIME = 'time';
export const SEPARATEDDATETIME = 'separateddatetime';
export const DATE_AND_TIME_FOR_RELATIVES = 'dateandtimeforrelatives';
export type FormatTypes =
  | typeof DATETIME
  | typeof DATE
  | typeof MONTH
  | typeof DAY
  | typeof TIME
  | typeof SEPARATEDDATETIME
  | typeof DATE_AND_TIME_FOR_RELATIVES;

/**
 * Returns a unix timestamp in seconds
 */
export function unix(): number {
  return Math.floor(Date.now() / 1000);
}

function castToUnixSeconds<T = number | dayjs.Dayjs>(date: T): T {
  if (typeof date === 'number' && date.toString().length > 12) {
    return Math.floor(date / 1000) as unknown as T;
  }
  return date;
}

function dayJsWithTimezone(date: Date, timezone?: string): dayjs.Dayjs {
  return dayjs(date.toLocaleString('en', {timeZone: timezone}));
}

export function timestampToFormatString(timestamp: number, format?: string, timezone?: string): string {
  if (timezone) {
    const date = dayjs.unix(timestamp).toDate();
    const withTz = dayJsWithTimezone(date, timezone);
    return withTz.format(format);
  }
  return dayjs.unix(timestamp).format(format);
}

export function seperateDateTimeStringsToUnix(dateString: DateStr, timeString: TimeStrM): number {
  return dayjs.utc(`${dateString} ${timeString}`, `${ISO_DATE} HH:mm`).unix();
}

export function dateTimeStrToUnix(dateTimeString: DateTimeStr): number {
  return dayjs.utc(`${dateTimeString}`, `${ISO_DATE} HH:mm`).unix();
}

export const translationTokens = {
  content: {
    now: 'generalCore:culture.now',
    today: 'generalCore:culture.days.today',
    tomorrow: 'generalCore:culture.days.tomorrow',
    yesterday: 'generalCore:culture.days.yesterday',
  },
};

export interface LocaleOptions {
  dateFormat: string;
  timeFormat: string;
}

export interface FormatOptions {
  noYearNumber?: boolean;
  nextWeekShowDayOfTheWeek?: boolean;
  nextWeekShowOnlyDayOfTheWeek?: boolean;
  lastWeekShowDayOfTheWeek?: boolean;
  showDayShortNames?: boolean;
  isUtc?: boolean;
}

/**
 * Format a date. Use keywords like "Yesterday" and "Today", if appropriate.
 *
 * @see DateFormatter#format()
 */
export function formatInWords(
  t: TFunction,
  date: dayjs.Dayjs | number, //unix seconds!
  type: FormatTypes,
  options: FormatOptions = {},
  {dateFormat, timeFormat}: LocaleOptions,
): string {
  const tokens = translationTokens.content;

  // if the given date was a dayjs object, explicitly create the new dayjs from the olds timestamp,
  // as it will otherwise copy its locale settings as well, which might result in another format as defined above
  let dateClone = dayjs.unix(dayjs.isDayjs(date) ? date.unix() : castToUnixSeconds(date));
  if (options.isUtc) {
    dateClone = dateClone.utc();
  }
  let config: Record<string, string>;
  const nextWeekDateFormat = options.nextWeekShowOnlyDayOfTheWeek ? 'dddd' : 'dddd, ';
  if (type === DATE) {
    config = {
      lastDay: `[${t(tokens.yesterday)}]`,
      lastWeek: (options.lastWeekShowDayOfTheWeek ? 'dddd, ' : options.showDayShortNames ? 'dd. ' : '') + dateFormat,
      nextDay: `[${t(tokens.tomorrow)}]`,
      nextWeek:
        (options.nextWeekShowDayOfTheWeek ? nextWeekDateFormat : options.showDayShortNames ? 'dd. ' : '') +
        (options.nextWeekShowOnlyDayOfTheWeek ? '' : dateFormat),
      sameDay: `[${t(tokens.today)}]`,
      sameElse: (options.showDayShortNames ? 'dd. ' : '') + dateFormat,
    };
  } else if (type === DATETIME) {
    config = {
      lastDay: `[${t(tokens.yesterday)}], ${timeFormat}`,
      lastWeek: (options.lastWeekShowDayOfTheWeek ? 'dddd, ' : '') + `${dateFormat}, ${timeFormat}`,
      nextDay: `[${t(tokens.tomorrow)}], ${timeFormat}`,
      nextWeek:
        (options.nextWeekShowDayOfTheWeek ? nextWeekDateFormat : '') +
        (options.nextWeekShowOnlyDayOfTheWeek ? '' : `${dateFormat}, ${timeFormat}`),
      sameDay: `[${t(tokens.today)}], ${timeFormat}`,
      sameElse: `${dateFormat}, ${timeFormat}`,
    };
  } else if (type === DATE_AND_TIME_FOR_RELATIVES) {
    config = {
      lastDay: `[${t(tokens.yesterday)}], ${timeFormat}`,
      lastWeek: (options.lastWeekShowDayOfTheWeek ? 'dddd, ' : '') + `${dateFormat}`,
      nextDay: `[${t(tokens.tomorrow)}], ${timeFormat}`,
      nextWeek:
        (options.nextWeekShowDayOfTheWeek ? nextWeekDateFormat : '') +
        (options.nextWeekShowOnlyDayOfTheWeek ? '' : `${dateFormat}`),
      sameDay: `[${t(tokens.today)}], ${timeFormat}`,
      sameElse: `${dateFormat}`,
    };
  } else {
    // type === TIME
    return dateClone.format(timeFormat);
  }

  // Return date / datetime by set calendar
  return dateClone.calendar(undefined, config);
}

export function toIsoFormat(date: dayjs.Dayjs): DateStr;
export function toIsoFormat(date: null | undefined): null;
export function toIsoFormat(date: dayjs.Dayjs | null | undefined): DateStr | null;
export function toIsoFormat(date: dayjs.Dayjs | null | undefined): string | null {
  if (date) {
    return date.format(ISO_DATE);
  }
  return null;
}

export function toIsoDateTimeFormat(date: dayjs.Dayjs): DateTimeStr;
export function toIsoDateTimeFormat(date: null | undefined): null;
export function toIsoDateTimeFormat(date: dayjs.Dayjs | null | undefined): DateTimeStr | null;
export function toIsoDateTimeFormat(date: dayjs.Dayjs | null | undefined): string | null {
  if (date) {
    return date.format(ISO_DATE_TIME);
  }
  return null;
}

export function fromIsoFormat(date: DateStr): dayjs.Dayjs;
export function fromIsoFormat(date: null): null;
export function fromIsoFormat(date: DateStr | null): dayjs.Dayjs | null;
export function fromIsoFormat(date: DateStr | null): dayjs.Dayjs | null {
  if (date) {
    if (isDevLocal && !isDateStr(date)) {
      throw new Error(`'${date}' is not a valid date`);
    }
    return dayjs(date, ISO_DATE);
  }
  return null;
}

export function fromIsoDateTimeFormat(date: DateTimeStr, asUtc?: boolean): dayjs.Dayjs;
export function fromIsoDateTimeFormat(date: null, asUtc?: boolean): null;
export function fromIsoDateTimeFormat(date: DateTimeStr | null, asUtc?: boolean): dayjs.Dayjs | null;
export function fromIsoDateTimeFormat(date: DateTimeStr | null, asUtc = false): dayjs.Dayjs | null {
  if (date) {
    if (isDevLocal && !isDateTimeStr(date)) {
      throw new Error(`'${date}' is not a valid date time`);
    }
    if (asUtc) {
      return dayjs.utc(date, ISO_DATE_TIME);
    }
    return dayjs(date, ISO_DATE_TIME);
  }
  return null;
}

export function isoToIsoDateTimeFormat(date: DateStr, time?: string): DateTimeStr;
export function isoToIsoDateTimeFormat(date: null, time?: string): null;
export function isoToIsoDateTimeFormat(date: DateStr | null, time?: string): DateTimeStr | null;
export function isoToIsoDateTimeFormat(date: DateStr | null, time = '00:00:00'): string | null {
  if (date) {
    const dateTimeStr = `${date} ${time}`;
    if (isDevLocal && !isDateTimeStr(dateTimeStr)) {
      throw new Error(`'${dateTimeStr}' is no valid date time`);
    }
    return dateTimeStr;
  }
  return null;
}

export function isoDateTimeToIsoFormat(date: DateTimeStr): DateStr;
export function isoDateTimeToIsoFormat(date: null): null;
export function isoDateTimeToIsoFormat(date: DateTimeStr | null): DateStr | null;
export function isoDateTimeToIsoFormat(date: DateTimeStr | null): string | null {
  if (date) {
    const dateStr = date.substring(0, 10);
    if (isDevLocal && !isDateStr(dateStr)) {
      throw new Error(`'${dateStr}' is no valid date`);
    }
    return dateStr;
  }
  return null;
}

export function isoToUnix(date: DateStr | DateTimeStr): number;
export function isoToUnix(date: null): null;
export function isoToUnix(date: DateStr | DateTimeStr | null): number | null;
export function isoToUnix(date: DateStr | DateTimeStr | null): number | null {
  if (date) {
    if (isDateTimeStr(date)) {
      return fromIsoDateTimeFormat(date, true).unix();
    }
    return fromIsoFormat(date).unix();
  }
  return null;
}

export function unixToIsoDate(ts: number): DateStr;
export function unixToIsoDate(ts: null): null;
export function unixToIsoDate(ts: number | null): DateStr | null;
export function unixToIsoDate(ts: number | null): DateStr | null {
  if (ts === null) {
    return null;
  }
  return toIsoFormat(dayjs.unix(ts));
}

export function unixToIsoDateTime(ts: number): DateTimeStr;
export function unixToIsoDateTime(ts: null): null;
export function unixToIsoDateTime(ts: number | null): DateTimeStr | null;
export function unixToIsoDateTime(ts: number | null): DateTimeStr | null {
  if (ts === null) {
    return null;
  }
  return toIsoDateTimeFormat(dayjs.unix(ts));
}

export function getDaysInRange(
  start: DateStr | null | undefined,
  end: DateStr | null | undefined,
  reverse = false,
): DateStr[] {
  if (start && end) {
    let iteratingDate = dayjs(start);
    const days = [];
    let isoDateString = null;

    do {
      isoDateString = toIsoFormat(iteratingDate);
      days.push(isoDateString);
      iteratingDate = iteratingDate.add(1, 'day');
    } while (end > isoDateString);

    return reverse ? days.reverse() : days;
  }

  return [];
}

/**
 * Gets all dates of the given `unit` where `date` is part of
 */
export function getDaysInUnit(date: DateStr, unit: 'isoWeek' | 'month' | 'year'): DateStr[] {
  const from = fromIsoFormat(date).startOf(unit);
  const to = from.endOf(unit);
  return getDaysInRange(toIsoFormat(from), toIsoFormat(to));
}

export function formatCollapsingDateRange(
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  formats = {
    day: 'dd DD.',
    dayMonth: 'dd DD.MM.',
    full: 'dd DD.MM.YYYY',
  },
  separator = ' - ',
): string {
  if (from.format(ISO_DATE) === to.format(ISO_DATE)) {
    return from.format(formats.full);
  }
  if (from.format('YYYY-MM') === to.format('YYYY-MM')) {
    return `${from.format(formats.day)}${separator}${to.format(formats.full)}`;
  }
  if (from.format('YYYY') === to.format('YYYY')) {
    return `${from.format(formats.dayMonth)}${separator}${to.format(formats.full)}`;
  }
  return `${from.format(formats.full)}${separator}${to.format(formats.full)}`;
}

export function isISODateString(date: string): boolean {
  return ISO_DATE_REGEX.test(date);
}
