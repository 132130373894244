import {List} from '@octaved/typescript/src/lib';
import isPlainObject from 'lodash/isPlainObject';

function doFlatten<T>(result: List<T>, obj: List<T | List<T>>, parentKey: string): void {
  Object.entries(obj).forEach(([key, value]) => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;
    if (isPlainObject(value)) {
      doFlatten(result, value as List<T | List<T>>, fullKey);
    } else {
      result[fullKey] = value as T;
    }
  });
}

/**
 * Flattens a deeply nested object into flat object where the keys represent the original path of the values
 */
export function flattenObject<T>(obj: List<T | List<T>>): List<T> {
  const result = {};
  doFlatten(result, obj, '');
  return result;
}
