import {Uuid} from '@octaved/typescript/src/lib';
import {cn} from '@octaved/ui';
import {unitIsOrgUser, useUnitEntity} from '@octaved/users/src/Hooks/UnitEntity';
import {publicUserFields} from '@octaved/users/src/Modules/OrgUser';
import {normalizeToSimpleUnitType, SimpleUnitType} from '@octaved/users/src/UnitType';
import {LucideIcon, Users} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import normalizeShortName from '../../Hooks/ShortnameNormalizer';
import {getUnitColor} from '../UnitColor';

export type AvatarSizes = 'small' | 'normal' | 'huge' | 'big';

const avatarSizeMap: Record<AvatarSizes, string> = {
  big: 'h-[40px] w-[40px] text-[1.2em]',
  huge: 'h-[60px] w-[60px] text-[2em]',
  normal: 'h-[30px] w-[30px]',
  small: 'h-[22px] w-[22px] text-[11px]',
};

const iconSizeMap: Record<AvatarSizes, string> = {
  big: 'h-[30px] w-[30px]',
  huge: 'h-[50px] w-[50px]',
  normal: 'h-[20px] w-[20px]',
  small: 'h-[16px] w-[16px]',
};

interface Props {
  className?: string;
  icon?: LucideIcon;
  id: Uuid;
  imageUrl?: string | null;
  noYMargin?: boolean;
  size?: AvatarSizes;
  type: SimpleUnitType;
}

export default function BaseFlowAvatar({
  className,
  id,
  noYMargin,
  type,
  size = 'normal',
  icon: Icon = Users,
}: Props): ReactElement {
  const simpleType = normalizeToSimpleUnitType(type);
  const unit = useUnitEntity(id, simpleType, publicUserFields);
  const name = unit?.name || '';
  const initials = useMemo<string>(() => normalizeShortName(name), [name]);
  const style = useMemo(() => {
    if (unit && unitIsOrgUser(unit, simpleType) && !unit.avatarUrl) {
      return {backgroundColor: getUnitColor([id], 40, 50)};
    }
    return undefined;
  }, [id, unit, simpleType]);

  return (
    <div
      className={cn(
        'flex flex-shrink-0 items-center justify-center overflow-hidden rounded-full font-bold leading-none text-white',
        simpleType === SimpleUnitType.group && 'bg-slate-300 text-slate-800',
        avatarSizeMap[size],
        !noYMargin && 'my-2',
        className,
      )}
      style={style}
      data-background={style?.backgroundColor}
    >
      {unit && unitIsOrgUser(unit, simpleType) && (
        <>
          {!unit.avatarUrl && initials}
          {Boolean(unit.avatarUrl) && (
            <img
              className={'h-full w-full object-cover'}
              src={unit.avatarUrl!}
              sizes={'60px' /* smallest default variant is 200px */}
              alt={name + '-avatar'}
            />
          )}
        </>
      )}
      {simpleType === SimpleUnitType.group && <Icon className={iconSizeMap[size]} strokeWidth={1.5} />}
    </div>
  );
}
