import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {
  ModalDialog,
  showInfoMessages,
  showModal,
} from '@octaved/planning/src/Components/PlanningRealization/PlanningType/Messages';
import {PlanningType} from '@octaved/planning/src/Components/PlanningRealization/PlanningType/PlanningType';
import SwitchPlanningTypeModal from '@octaved/planning/src/Components/PlanningRealization/PlanningType/SwitchPlanningTypeModal';
import {setNodeToUnplanned} from '@octaved/planning/src/Modules/PlanningOptions';
import {TWButton} from '@octaved/ui';
import {Trash} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface DeletePlanningProps {
  node: NodeType;
  planningType: PlanningType;
  setPlanningType: React.Dispatch<React.SetStateAction<PlanningType>>;
}

export function DeletePlanning({node, planningType, setPlanningType}: DeletePlanningProps): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [dialogStatus, setDialogStatus] = useState<{
    dialogStatus: ModalDialog;
    nextType: PlanningType | null;
    action: (() => void) | null;
  }>({dialogStatus: ModalDialog.none, nextType: null, action: null});
  function resetDialogStatus(): void {
    setDialogStatus({dialogStatus: ModalDialog.none, nextType: null, action: null});
  }

  const onDeletePlanning = (): void => {
    const nextType = PlanningType.none;
    const dialogStatus = showModal(planningType, nextType, node);
    const action = (): void => {
      setPlanningType(nextType);
      dispatch(setNodeToUnplanned(node.id, true));
    };

    if (dialogStatus === ModalDialog.none) {
      showInfoMessages(planningType, nextType, node);
      action();
    } else {
      setDialogStatus({dialogStatus, nextType, action});
    }
  };
  return (
    <div className={'flex justify-center'}>
      <TWButton colorScheme={'red'} variant={'outline'} size={'md'} withIcon onClick={onDeletePlanning}>
        <Trash className={'size-4'} />
        {t('nodeDetails:field.planningStatus.deletePlanning.button')}
      </TWButton>

      {dialogStatus.dialogStatus === ModalDialog.switchPlanningType && (
        <SwitchPlanningTypeModal
          deleteType={'all'}
          nodeId={node.id}
          onConfirm={() => {
            dialogStatus.action?.();
            resetDialogStatus();
          }}
          onCancel={resetDialogStatus}
        />
      )}
    </div>
  );
}
