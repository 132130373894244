import {Uuid} from '@octaved/typescript/src/lib';
import {arrayMoveImmutable as arrayMove} from 'array-move';
import {
  ProjectTreeGroup,
  ProjectTreeNode,
  ProjectTreeProject,
} from '../../../../Modules/Projects/ProjectTreeInterfaces';

export function getSiblings(parentId: Uuid, allNodes: Map<Uuid, ProjectTreeNode>): ProjectTreeNode[] {
  return (allNodes.get(parentId) as ProjectTreeProject | ProjectTreeGroup).children.slice(0);
}

export function createNewSiblingsList(
  movedNodeId: Uuid,
  oldIndex: number,
  newIndex: number,
  oldParentId: Uuid | null,
  newParentId: Uuid,
  allNodes: Map<Uuid, ProjectTreeNode>,
  getMovedNode: (movedNodeId: Uuid) => ProjectTreeNode | undefined,
): ProjectTreeNode[] {
  const siblings = getSiblings(newParentId, allNodes);
  let from = oldIndex;
  if (oldParentId !== newParentId) {
    const nodeToAdd = getMovedNode(movedNodeId);
    if (nodeToAdd) {
      //on quick rearrange the node is sometimes missing
      from = siblings.push(nodeToAdd);
    } else {
      throw new Error(
        `node ('${movedNodeId}') to insert not found on moving into '${newParentId}' from '${oldParentId}'`,
      );
    }
  }
  from = Math.min(from, siblings.length - 1);
  const to = Math.min(newIndex, siblings.length - 1);

  return arrayMove(siblings, from, to);
}
