import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M19 7H5C3.89543 7 3 7.89543 3 9V15C3 16.1046 3.89543 17 5 17H19C20.1046 17 21 16.1046 21 15V9C21 7.89543 20.1046 7 19 7Z',
      key: '1',
      strokeWidth: '1.5',
    },
  ],
];

export const WorkPackageIconSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const WorkPackageIcon = createLucideIcon('WorkPackageIcon', iconDrawing);
