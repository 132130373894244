import {Point} from '@octaved/svg';
import {ReactElement} from 'react';
import {calculateLine} from './CalculateLine';

interface DragableLineProps {
  current: Point;
  start: Point;
}

export default function DragableLine({current, start}: DragableLineProps): ReactElement {
  const {linePath, style} = calculateLine(start, current);
  return (
    <svg pointerEvents={'none'} style={style}>
      <path d={linePath} stroke={'#7a7a7a'} strokeWidth={'2'} fill={'none'} />
    </svg>
  );
}
