import {ReactElement} from 'react';
import FullContainerLoader from './Components/Feedback/Loader/FullContainerLoader';

export default function PageLoadingWrapper(): ReactElement {
  return (
    <div className={'page'}>
      <FullContainerLoader />
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .page {
          height: 100vh;
          width: 100vw;
          display: flex;
          align-items: stretch;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
