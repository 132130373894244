import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  defaultProjectsTreeComponentContext,
  projectsTreeComponentContext,
  ProjectsTreeComponentContext,
} from '../../../../Components/Tree/ProjectTreeComponentContext';
import {usePid} from '../../../../Modules/Hooks/Nodes';
import {isGroup, isWorkPackage} from '../../../../Node/NodeIdentifiers';
import {
  projectsMovePidSelector,
  projectsSelectedProjectFolderSelector,
} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {projectsFilteredCustomerSelector} from '../../../../Modules/Selectors/UiSelectors';
import {moveGroupOrWorkPackage} from '../../../../Modules/UiPages/Projects';
import {flowStyleContext} from '../../../../Styles/StyleContext';
import {useProjectUiStatePatch} from '../../Projects';
import ProjectsVerticalFolder from '../../ProjectsFolder/ProjectsVerticalFolder';
import DraggablePid from './DraggablePid';
import DropGroup from './DropGroup';
import DropGroupAndWorkPackages from './DropGroupAndWorkPackages';
import DropWorkPackage from './DropWorkpackage';
import Header from './Header';
import MoveGroupOrWorkPackageTree, {MoveGroupOrWorkPackageTreeRef} from './MoveGroupOrWorkPackageTree';
import SearchAndSort from './SearchAndSort';

export default function MoveGroupOrWorkPackage(): ReactElement | null {
  const {
    COLORS: {BORDER},
  } = useContext(flowStyleContext);
  const pidId = useSelector(projectsMovePidSelector);
  const pid = usePid(pidId);
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState(false);
  const [search, setSearch] = useState('');
  const [priceCategoryIssue, setPriceCategoryIssue] = useState(false);
  const [hasPlanningDependencyIssue, setHasPlanningDependencyIssue] = useState(false);
  const treeRef = useRef<MoveGroupOrWorkPackageTreeRef>(null);
  const patchProjectsUiState = useProjectUiStatePatch();
  const projectsCustomerFilter = useSelector(projectsFilteredCustomerSelector) || 'all';
  const projectsCustomerFilterRef = useRef(projectsCustomerFilter);
  projectsCustomerFilterRef.current = projectsCustomerFilter;
  const selectedFolder = useSelector(projectsSelectedProjectFolderSelector);
  const [selectedCustomer, setSelectedCustomer] = useState(projectsCustomerFilter);
  const selectedCustomerRef = useRef(selectedCustomer);
  selectedCustomerRef.current = selectedCustomer;
  const [selectedProjectFolder, setSelectedProjectFolder] = useState<Uuid>(selectedFolder);

  const componentContext = useMemo<ProjectsTreeComponentContext>(
    () => ({
      ...defaultProjectsTreeComponentContext,
      group: DropGroup,
      groupsAndWorkPackages: DropGroupAndWorkPackages,
      workPackage: DropWorkPackage,
    }),
    [],
  );

  const save = useCallback(async () => {
    await treeRef.current?.save();
    if (
      projectsCustomerFilterRef.current !== 'all' &&
      selectedCustomerRef.current !== projectsCustomerFilterRef.current
    ) {
      patchProjectsUiState({
        filteredCustomer: selectedCustomerRef.current === 'all' ? null : selectedCustomerRef.current,
      });
    }
  }, [patchProjectsUiState]);

  if (pidId && (isGroup(pid) || isWorkPackage(pid))) {
    return (
      <div className={'moveGroupOrWorkPackage'}>
        <Header
          pid={pid}
          hasChanges={hasChanges}
          save={save}
          priceCategoryIssue={priceCategoryIssue}
          hasPlanningDependencyIssue={hasPlanningDependencyIssue}
        />

        <SearchAndSort
          search={search}
          setSearch={setSearch}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />

        <div className={'moveGroupWorkPackageContent'}>
          <ProjectsVerticalFolder
            selectedProjectFolder={selectedProjectFolder}
            setSelectedProjectFolder={setSelectedProjectFolder}
          />
          <div className={'content'}>
            <DraggablePid />
            <projectsTreeComponentContext.Provider value={componentContext}>
              <MoveGroupOrWorkPackageTree
                setHasChanges={setHasChanges}
                ref={treeRef}
                pidId={pidId}
                search={search}
                setPriceCategoryIssue={setPriceCategoryIssue}
                setHasPlanningDependencyIssue={setHasPlanningDependencyIssue}
                selectedCustomer={selectedCustomer}
                selectedProjectFolder={selectedProjectFolder}
              />
            </projectsTreeComponentContext.Provider>
          </div>
        </div>

        {/*language=SCSS*/}
        <style jsx>{`
          .moveGroupOrWorkPackage {
            display: flex;
            align-items: stretch;
            flex-direction: column;
            height: 100vh;
            overflow: hidden;
          }

          .moveGroupWorkPackageContent {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            overflow: hidden;
          }

          .projectFolderFilter {
            height: 50px;
            border-bottom: 1px solid ${BORDER.WEAK};
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .content {
            flex-grow: 1;
            overflow-y: auto;
            padding: 15px 20px;
            position: relative;
          }
        `}</style>
      </div>
    );
  }

  dispatch(moveGroupOrWorkPackage(null));
  return null;
}
