import {mergeStates} from '@octaved/store/src/MergeStates';
import {DeepPartial} from '@octaved/typescript/src/lib';
import {useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {
  ProjectFilterArrayKeys,
  ProjectFilterStates,
  ProjectFilterStatesPage,
} from '../../../EntityInterfaces/Filter/ProjectFilters';
import {useProjectContext} from '../../../Pages/Projects/ProjectContext';
import {useArrayFilter, UseArrayFilter, UseArrayFilterKeyGenArg} from '../../Filter/ArrayFilter';
import {UseEditFilterState, useEditFilterState, UseEditFilterStateResult} from '../../Filter/FilterState';
import {usePatchUserSettings} from '../../Hooks/UserSettings';
import {
  getProjectsFilterStatesSelector,
  getProjectsFilterValueSelector,
  requireDefaultValue,
} from '../../Selectors/UiPages/ProjectsSelector';
import {defaultProjectFilterStates} from './ProjectDefaultFilters';
import {useProjectFiltersContext} from './ProjectFiltersContext';

type Value<K extends keyof ProjectFilterStates> = ProjectFilterStates[K]['value'];
type ArrayMember<T> = T extends (infer U)[] ? U : T;

export function useEditProjectFilter<K extends keyof ProjectFilterStates>(
  ident: K,
): UseEditFilterStateResult<Value<K>> {
  const {page} = useProjectContext();
  const defaultValue = requireDefaultValue(page, ident) as Value<K>;
  const {filterStates, setFilterStates} = useProjectFiltersContext();
  return useEditFilterState(filterStates, setFilterStates, ident, defaultValue);
}

export function useEditProjectArrayFilter<K extends ProjectFilterArrayKeys>(
  ident: K,
  keyGenerator?: UseArrayFilterKeyGenArg<ArrayMember<Value<K>>>,
): UseArrayFilter<ArrayMember<Value<K>>> {
  return useArrayFilter<ArrayMember<Value<K>>, K>(
    useEditProjectFilter as UseEditFilterState<ArrayMember<Value<K>>[], K>,
    ident,
    keyGenerator,
  );
}

export function useApplyCurrentFilters(): {
  hasChanges: boolean;
  onClick: () => void;
} {
  const {page} = useProjectContext();
  const patch = usePatchUserSettings();
  const {hasChanges, filterStates} = useProjectFiltersContext();
  const filterStatesRef = useRef(filterStates);
  filterStatesRef.current = filterStates;
  return {
    hasChanges,
    onClick: useCallback(
      () => patch({projects: {advancedFilterStates: {[page]: filterStatesRef.current}}}),
      [page, patch],
    ),
  };
}

export function useResetCurrentFilters(): () => void {
  const {page} = useProjectContext();
  const patch = usePatchUserSettings();
  const {setFilterStates} = useProjectFiltersContext();

  const advancedFilterStates = useSelector(getProjectsFilterStatesSelector)(page);
  const advancedFilterStatesRef = useRef<ProjectFilterStatesPage>(advancedFilterStates);
  advancedFilterStatesRef.current = advancedFilterStates;

  return useCallback(() => {
    const newStates: ProjectFilterStatesPage = {...defaultProjectFilterStates[page]};

    //Do not reset the quick filters - copy them over:
    const cur = advancedFilterStatesRef.current;
    Object.keys(newStates).forEach((key) => {
      const ident = key as keyof ProjectFilterStates;
      if (ident.startsWith('quick') && cur) {
        // @ts-ignore same key, same type
        newStates[ident] = cur[ident];
      }
    });

    setFilterStates(newStates);
    patch({projects: {advancedFilterStates: {[page]: newStates}}});
  }, [page, patch, setFilterStates]);
}

export function usePatchProjectCustomFilterValue<I extends keyof ProjectFilterStates>(
  page: string,
  ident: I,
): (data: DeepPartial<ProjectFilterStates[I]['value']>) => void {
  const value = useSelector(getProjectsFilterValueSelector)(page, ident);
  const valueRef = useRef(value);
  valueRef.current = value;
  const patch = usePatchUserSettings();
  return useCallback(
    (data) => {
      patch({
        projects: {
          advancedFilterStates: {
            [page]: {
              [ident]: {
                isActive: true,
                value: mergeStates(valueRef.current, data),
              },
            },
          },
        },
      });
    },
    [ident, page, patch],
  );
}
