import QuestionCircleSvg from '@fortawesome/fontawesome-pro/svgs/solid/question-circle.svg';
import {Icon, IconButton, Tooltip} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {CheckCircle} from 'lucide-react';
import {ReactElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Project} from '../../../../EntityInterfaces/Pid';
import {setInspector} from '../../../../Modules/Ui';
import {flowStyleContext} from '../../../../Styles/StyleContext';
import LogoSvg from '../../../../svgs/logo.svg?tsx';
import ProjectView from '../ProjectView';

interface Props {
  project: Project;
}

export default function Header({project}: Props): ReactElement {
  const {t} = useTranslation();
  const {
    COLORS: {BORDER},
  } = useContext(flowStyleContext);
  const dispatch = useDispatch();

  return (
    <div className={'header'}>
      <div className={'leftHeader'}>
        <div className={'logo'}>
          <LogoSvg />
        </div>
        <div className={'headerTitle'}>
          <span>{t('pages:projects.drawer.rearrangeProject.rearrangeProject')}</span>
        </div>
        <Tooltip
          position={'bottom left'}
          offset={[-7, 0]}
          toolTipTranslation={'pages:projects.drawer.rearrangeProject.rearrangeInfo'}
        >
          <div className={'hint'}>
            <Icon>
              <QuestionCircleSvg />
            </Icon>
          </div>
        </Tooltip>
      </div>

      <Spacer spacing={40} />
      <ProjectView project={project} />
      <Spacer spacing={30} />

      <IconButton
        onClick={() => dispatch(setInspector({}))}
        icon={<CheckCircle />}
        label={'pages:projects.drawer.rearrangeProject.finish'}
      />

      {/*language=SCSS*/}
      <style jsx>{`
        .header {
          height: 50px;
          border-bottom: 1px solid ${BORDER.WEAK};
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          flex-shrink: 0;
        }

        .logo {
          width: 34px;
          overflow: hidden;
        }

        .logo > :global(svg) {
          height: 24px;
          margin-left: -10px;
        }

        .leftHeader {
          display: flex;
          align-items: center;
          font-size: 16px;
        }

        .hint {
          cursor: help;
          margin-left: 14px;
        }
      `}</style>
    </div>
  );
}
