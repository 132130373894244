import {Uuid} from '@octaved/typescript/src/lib';
import {Icon} from '@octaved/ui';
import {useDropdownHoverIndex} from '@octaved/ui-components/src/Hooks/UseDropdownHoverIndex';
import {useKeyboardListener} from '@octaved/ui-components/src/Hooks/UseKeydownListener';
import classNames from 'classnames';
import {Check} from 'lucide-react';
import {ReactElement, RefObject, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {TaskPatchData} from '../../../../../../EntityInterfaces/Task';
import {handleXOrLabelsOnAdd} from '../../../../../../Hooks/Label';
import {getLabelsForSelectionSelector, LabelScope} from '../../../../../../Modules/Selectors/LabelSelectors';
import {getProjectFolderForNodeSelector} from '../../../../../../Modules/Selectors/ProjectFolderSelectors';
import {rootFoldersProjectFolderSelector} from '../../../../../../Modules/Selectors/RootFolderSelectors';
import {FlowState} from '../../../../../../Modules/State';
import FlowLabel from '../../../../../Label/FlowLabel/FlowLabel';

interface Props {
  cellRef: RefObject<HTMLDivElement>;
  id: string;
  labels: Uuid[];
  patch: (data: Partial<TaskPatchData>) => void;
  onAfterChange?: () => void;
}

export default function LabelPopupContent({cellRef, labels, patch, id, onAfterChange}: Props): ReactElement {
  const hasLabels = useMemo(() => labels.length > 0, [labels]);
  const rootProjectFolder = useSelector(rootFoldersProjectFolderSelector); //UserNode falls back to root folder
  const projectFolderId = useSelector(
    (s: FlowState) => getProjectFolderForNodeSelector(s)(id)?.id || rootProjectFolder,
  );
  const getLabelsForSelection = useSelector(getLabelsForSelectionSelector);

  const availabelLabels = useMemo(
    () =>
      projectFolderId
        ? getLabelsForSelection([LabelScope.tasks], projectFolderId).sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [getLabelsForSelection, projectFolderId],
  );

  const {hoverIndex, setHoverIndex, changeIndex} = useDropdownHoverIndex(availabelLabels.length - 1, cellRef, 26);

  const toggleLabel = useCallback(
    (id: Uuid) => {
      if ((labels || []).includes(id)) {
        patch({labels: labels.filter((labelId) => labelId !== id)});
      } else {
        const toBeRemoved = handleXOrLabelsOnAdd(availabelLabels, id);
        patch({labels: labels.filter((id) => !toBeRemoved.includes(id)).concat(id)});
      }
      onAfterChange?.();
    },
    [availabelLabels, labels, patch, onAfterChange],
  );

  useKeyboardListener((e) => {
    if (e.code === 'ArrowDown') {
      changeIndex(1);
    } else if (e.code === 'ArrowUp') {
      changeIndex(-1);
    } else if (e.code === 'Enter') {
      const entry = availabelLabels[hoverIndex];
      if (entry) {
        toggleLabel(entry.id);
      }
    }
  });

  return (
    <div className={'js-dropdownList'}>
      {availabelLabels.map((label, idx) => (
        <div
          key={label.id}
          className={classNames('labelRow', {active: hoverIndex === idx})}
          onMouseEnter={() => setHoverIndex(idx)}
          onClick={() => toggleLabel(label.id)}
        >
          {hasLabels && (
            <Icon iconColor={labels.includes(label.id) ? 'green' : 'white'}>
              <Check />
            </Icon>
          )}
          <FlowLabel label={label} />
        </div>
      ))}
      {/*language=scss*/}
      <style jsx>{`
        .js-dropdownList {
          max-height: 250px;
          overflow-y: scroll;
        }

        .labelRow {
          padding: 0.35rem 0.4375rem;
          display: flex;
          align-items: center;
          grid-gap: 0.4375rem;
          height: 26px;
        }

        .labelRow.active {
          background-color: var(--color-hover-bg);
        }
      `}</style>
    </div>
  );
}
