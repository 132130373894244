import {isGroup, isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {Rect} from 'konva/lib/shapes/Rect';
import {getMaxDateFromList} from '../../../../Calculations/DateCalculations';
import {Bar} from '../../../../Canvas/Bars/Bar';
import {BarPlugin, BarPluginProps} from '../../../../Canvas/Bars/Plugins/BarPlugin';
import {createStoreSubscription} from '../../../../Canvas/StoreSubscription';
import {Milestone} from '../../../../EntityInterfaces/Milestones';
import {getSelectedPlanningNodeBaselineHistoriesSelector} from '../../../../Selectors/PlanningBaselineHistorySelectors';

interface ColoredMaturityPluginProps extends BarPluginProps {
  isBaseline?: boolean;
  rootNodeId: Uuid;
  milestones: Milestone[];
}

export class ColoredMaturityPlugin extends BarPlugin {
  #rect: Rect | null = null;
  readonly #colorBaseline = '#71717a';
  readonly #colorOnTime = '#10b981';
  readonly #colorDelayed = '#ef4444';

  #isBaseline: boolean;
  #rootNodeId: Uuid;
  #milestones: Milestone[];

  constructor(props: ColoredMaturityPluginProps) {
    super(props);
    this.#isBaseline = props.isBaseline ?? false;
    this.#rootNodeId = props.rootNodeId;
    this.#milestones = props.milestones;
  }

  init(bar: Bar): void {
    super.init(bar);
    this.#rect = this.#createRect(bar.height - 4);
    this.root.add(this.#rect);

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        () => this.#getColor(),
        (color) => this.style(color),
      ),
    );
  }

  #getColor(): string {
    if (this.#isBaseline) {
      return this.#colorBaseline;
    }
    const isDelayed =
      this.#milestones.length === 0 ? this.#isPlannedDueDateDeplayed() : this.#arePlannedMilestonesDelayed();
    return isDelayed ? this.#colorDelayed : this.#colorOnTime;
  }

  #createRect(size: number): Rect {
    return new Rect({
      cornerRadius: 2,
      fill: this.#getColor(),
      height: size,
      rotation: 45,
      width: size,
      x: this.bar.width + this.bar.barPaddingX,
    });
  }

  protected style(color: string): void {
    this.#rect?.fill(color);
  }

  onBarUpdated(): void {
    this.#rect?.x(this.bar.width + this.bar.barPaddingX);
  }

  #isPlannedDueDateDeplayed(): boolean {
    const state = this.ctx.store.getState();
    const node = getNodeSelector(state)(this.nodeId);

    if ((isProject(node) || isGroup(node)) && node.dueDate) {
      const selectedNodeBaselineHistories = getSelectedPlanningNodeBaselineHistoriesSelector(state)(
        this.#rootNodeId,
        this.nodeId,
      );
      const baselineMax = getMaxDateFromList(boolFilter(selectedNodeBaselineHistories.map(({dueDate}) => dueDate)));
      if (baselineMax) {
        return baselineMax < node.dueDate;
      }
    }
    return false;
  }

  #arePlannedMilestonesDelayed(): boolean {
    const firstMilestone = this.#milestones[0];

    const selectedNodeBaselineHistories = getSelectedPlanningNodeBaselineHistoriesSelector(this.ctx.store.getState())(
      this.#rootNodeId,
      this.nodeId,
    );
    const baselineMax = getMaxDateFromList(
      boolFilter(
        selectedNodeBaselineHistories.map(
          ({milestones}) => milestones.find(({id}) => id === firstMilestone.id)?.milestoneDate,
        ),
      ),
    );
    if (baselineMax) {
      return baselineMax < firstMilestone.milestoneDate;
    }
    return false;
  }
}
