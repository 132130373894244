import {isInMaintenanceSelector, versionRevisionSelector} from '@octaved/env/src/EnvironmentState';
import {ReactElement, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import InMaintenancePopup from './InMaintenancePopup';
import RevisionChangedPopup from './RevisionChangedPopup';

let lastRevision: string | null = null;

export default function EnvironmentListener(): ReactElement {
  const isInMaintenance = useSelector(isInMaintenanceSelector);
  const revision = useSelector(versionRevisionSelector);
  const [revisionChanged, setRevisionChanged] = useState(false);

  useEffect(() => {
    if (revision) {
      if (lastRevision) {
        setRevisionChanged(true);
      }
      lastRevision = revision;
    }
  }, [revision]);

  return (
    <>
      {isInMaintenance && <InMaintenancePopup />}
      {revisionChanged && !isInMaintenance && <RevisionChangedPopup />}
    </>
  );
}
