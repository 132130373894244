import dayjs from '@octaved/dayjs-mutable';
import {FlowState} from '@octaved/flow/src/Modules/State';

import {getTotalPlanningDurationSelector} from '@octaved/planning/src/Selectors/PlanningDurationSelectors';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn} from '@octaved/ui';
import {formatCollapsingDateRange} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

interface PlanningDateProps {
  plannedEnd: DateTimeStr | null;
  plannedStart: DateTimeStr | null;
  isOverdue?: boolean;
  nodeId: Uuid;
  showDays?: boolean;
}

export function PlanningDate({isOverdue, plannedStart, plannedEnd, nodeId, showDays}: PlanningDateProps): ReactElement {
  return (
    <div className={cn('flex items-center gap-x-2', isOverdue && 'text-red-700')}>
      <div>{formatCollapsingDateRange(dayjs(plannedStart), dayjs(plannedEnd))}</div>
      {showDays && (
        <div>
          (<WorkDays nodeId={nodeId} />)
        </div>
      )}
      {isOverdue && <Flame className={'-mt-[0.3em] inline-flex h-4 w-4 text-red-700'} />}
    </div>
  );
}

interface WorkdaysProps {
  nodeId: Uuid;
}

export function WorkDays({nodeId}: WorkdaysProps): ReactElement {
  const {t} = useTranslation();
  const workDays = useSelector((s: FlowState) => getTotalPlanningDurationSelector(s)(nodeId));
  return (
    <>
      {t('general:date.days', {
        count: workDays,
        countFormatted: workDays,
      })}
    </>
  );
}
