import {TWButton} from '@octaved/ui';
import {MoreHorizontal} from 'lucide-react';
import {forwardRef, ReactElement} from 'react';

const ContextMenuTrigger = forwardRef<HTMLButtonElement>((_props, ref): ReactElement => {
  return (
    <TWButton
      ref={ref}
      variant={'ghost'}
      iconOnly
      size={'sm'}
      colorScheme={'slate'}
      asSpan
      onClick={(e) => e.stopPropagation()}
    >
      <MoreHorizontal className={'size-4'} />
    </TWButton>
  );
});

ContextMenuTrigger.displayName = 'ContextMenuTrigger';

export {ContextMenuTrigger};
