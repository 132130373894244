import {MaybeUuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {ExtendedNodeStatus} from './NodeStatus';
import {NodeStatusIcon} from './NodeStatusIcon';

interface PropsA {
  nodeId: MaybeUuid;
  status?: never;
}

interface PropsB {
  nodeId?: never;
  status: ExtendedNodeStatus;
}

export default function NodeStatusLabel({nodeId, status}: PropsA | PropsB): ReactElement {
  return (
    <div
      className={
        'w-fit rounded border border-slate-200 bg-slate-100 py-0.5 pl-0.5 pr-3 text-sm font-medium text-slate-700'
      }
    >
      {status ? <NodeStatusIcon status={status} withText /> : <NodeStatusIcon nodeId={nodeId} withText />}
    </div>
  );
}
