import dayjs from '@octaved/dayjs-mutable';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isSubWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useLoadPlanningDates} from '@octaved/planning/src/Modules/PlanningDates';
import {getMinMaxPlanningDatesSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {Message} from '@octaved/ui';
import {formatCollapsingDateRange} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

interface NodeParentWorkPackagePlanningMessageProps {
  node: Task | SubWorkPackage;
}

export default function NodeParentWorkPackagePlanningMessage({
  node,
}: NodeParentWorkPackagePlanningMessageProps): ReactElement | null {
  const {t} = useTranslation();
  const getMinMaxPlanning = useSelector(getMinMaxPlanningDatesSelector);
  const nodeAncestor = useSelector((s: FlowState) => {
    const ancestry = getNodeAncestrySelector(s)(node.id);
    if (ancestry.subWorkPackage) {
      return ancestry.subWorkPackage;
    } else if (ancestry.workPackage) {
      return ancestry.workPackage;
    }
    return null;
  });
  useLoadPlanningDates(nodeAncestor ? [nodeAncestor.id] : []);
  const hasTimeBasedPlanning = (nodeAncestor?.planningDates.length || 0) > 0;

  if (!nodeAncestor) {
    return null;
  }

  if (!hasTimeBasedPlanning) {
    let token = 'nodeDetails:field.planningStatus.details.info.taskParentWorkPackageUnplanned';
    if (isSubWorkPackage(nodeAncestor)) {
      token = 'nodeDetails:field.planningStatus.details.info.taskParentSubWorkPackageUnplanned';
    } else if (isSubWorkPackage(node)) {
      token = 'nodeDetails:field.planningStatus.details.info.subWorkPackageParentWorkPackageUnplanned';
    }

    return (
      <Message textSize={'sm'}>
        <Trans i18nKey={token} />
      </Message>
    );
  }

  const {plannedEnd, plannedStart} = getMinMaxPlanning(nodeAncestor.id);

  let token = 'nodeDetails:field.planningStatus.details.info.taskParentWorkPackagePlanned';
  if (isSubWorkPackage(nodeAncestor)) {
    token = 'nodeDetails:field.planningStatus.details.info.taskParentSubWorkPackagePlanned';
  } else if (isSubWorkPackage(node)) {
    token = 'nodeDetails:field.planningStatus.details.info.subWorkPackageParentWorkPackagePlanned';
  }

  return (
    <Message textSize={'sm'}>
      {t(token)} {formatCollapsingDateRange(dayjs(plannedStart), dayjs(plannedEnd))}
    </Message>
  );
}
