import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo, useRef} from 'react';
import {useDynamicDragContext, useStaticDragContext} from './DragContext';

interface Item {
  id: Uuid;
  sortOrder: number;
}

function mapToSortedIds(array: Item[]): Uuid[] {
  return [...array].sort((a, b) => a.sortOrder - b.sortOrder).map(({id}) => id);
}

const empty: Record<Uuid, number | undefined> = {};

export function useManuallySortedIndexes(
  parentId: Uuid,
  type: 'section' | 'task',
  items: Item[],
): Record<Uuid, number | undefined> {
  const {isDraggingRef, patchSortedIds, getSortedIdsIndexesRef} = useStaticDragContext();
  const {getSortedIdsIndexes} = useDynamicDragContext();

  const itemsRef = useRef<typeof items | null>(null);
  const parentIdRef = useRef<typeof parentId | null>(null);
  const typeRef = useRef<typeof type | null>(null);

  return useMemo(() => {
    //We cannot use useEffect() to call patchSortedIds(), so we have to detect the relevant update conditions ourselves:
    if (items !== itemsRef.current || parentId !== parentIdRef.current || type !== typeRef.current) {
      if (!isDraggingRef.current) {
        const indexes = mapToSortedIds(items);
        patchSortedIds(type, {[parentId]: indexes}, false);
      }
    }

    itemsRef.current = items;
    parentIdRef.current = parentId;
    typeRef.current = type;

    // noinspection BadExpressionStatementJS
    void getSortedIdsIndexes; //force re-eval: when this changes the sort order changed

    // Since we are calling patchSortedIds() synchronous, we can always use the refs:
    return getSortedIdsIndexesRef(type, parentId) || empty;
  }, [getSortedIdsIndexes, getSortedIdsIndexesRef, isDraggingRef, items, parentId, patchSortedIds, type]);
}
