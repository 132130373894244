import {Icon, cn} from '@octaved/ui';
import {CSSProperties, ReactElement, useMemo} from 'react';
import {Trans} from 'react-i18next';

interface Props {
  icon: ReactElement;
  text: string;
  subText?: string;
  top?: boolean;
  topMargin?: number;
}

export default function PagePlaceholder({icon, subText, text, top, topMargin = 10}: Props): ReactElement {
  const style = useMemo<CSSProperties>(() => ({marginTop: topMargin ? `${topMargin}rem` : undefined}), [topMargin]);
  return (
    <div
      className={cn('flex w-full flex-col items-center justify-center gap-y-5', top && 'justify-start', {top})}
      style={style}
    >
      <Icon iconColor={'brightGrey'} size={'massive'} noMargin>
        {icon}
      </Icon>
      <div className={'flex flex-col items-center gap-y-2'}>
        <div className={'text-lg text-slate-600'}>
          <Trans i18nKey={text} />
        </div>
        {subText && (
          <div className={'max-w-sm text-balance text-center text-sm text-slate-500'}>
            <Trans i18nKey={subText} />
          </div>
        )}
      </div>
    </div>
  );
}
