import {ProjectFolderTreeNode} from '@octaved/flow/src/Modules/Selectors/ProjectFolderSelectors';
import {ReactElement} from 'react';
import Node from './Node';

interface Props {
  nodes: ReadonlyArray<ProjectFolderTreeNode>;
}

export default function Children({nodes}: Props): ReactElement {
  return (
    <>
      {nodes.map((node) => (
        <Node key={node.id} node={node} />
      ))}
    </>
  );
}
