import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {Role} from '../../EntityInterfaces/Role';
import {roleSelector} from '../../Modules/Selectors/RoleSelectors';
import {FlowState} from '../../Modules/State';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IGuestRoleInspectorContext extends BaseInspectorContext {
  selectedEntity: Role;
  selectedType: 'guestRole';
}

function isGuestRoleInspectorContext(context: InspectorContext | null): context is IGuestRoleInspectorContext {
  return context?.selectedType === 'guestRole';
}

export function useGuestRoleInspectorContext(): IGuestRoleInspectorContext {
  const context = useInspectorContext();
  if (!isGuestRoleInspectorContext(context)) {
    throw new Error('is not guest role inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IGuestRoleInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector((s: FlowState) => {
    const stored = roleSelector(s)[EnumFlowRoleType.VALUE_PERMISSION][baseParams?.selectedId || ''];
    return stored && stored.isGuestRole ? stored : null;
  });

  return useMemo<IGuestRoleInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'guestRole',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function GuestRoleInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
