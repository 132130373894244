import StringPath from '@octaved/flow/src/Components/Node/StringPath';
import {useWorkPackage} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {Stack} from '@octaved/ui';
import {ReactElement} from 'react';
import {usePlanningDateInspectorContext} from '../../../../Inspector/PlanningDateInspectorContext';

export default function PlanningDateHeader(): ReactElement {
  const {materialResource, selectedEntity} = usePlanningDateInspectorContext();
  const wp = useWorkPackage(selectedEntity?.assignedNodeId);
  const hasOwnName = Boolean(wp?.name || selectedEntity?.name);
  return (
    <Stack maxW={'450px'} overflow={'hidden'}>
      <div className={'name'}>
        <span className={'material'}>
          {materialResource.name}
          {hasOwnName ? ': ' : ''}
        </span>
        {wp?.name || selectedEntity?.name}
      </div>
      <div className={'path'}>
        <StringPath nodeId={materialResource.id} excludeProject />
      </div>

      <style jsx>{`
        .path {
          font-size: 12px;
          display: flex;
          flex-wrap: wrap;
        }

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 450px;
        }

        .material {
          font-size: 14px;
          color: #707070;
          margin-bottom: 0;
          font-weight: 600;
        }
      `}</style>
    </Stack>
  );
}
