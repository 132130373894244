import {FLOW_CHANGE_PID_REQUEST} from '@octaved/flow/src/Modules/ActionTypes';
import {WorkPackagePatchAction} from '@octaved/flow/src/Modules/Events';
import {UnknownAction, isAction} from 'redux';
import UpdateWorkloadContext from './UpdateWorkloadContext';

function isPatchWorkPackageRequestAction(action: unknown): action is WorkPackagePatchAction {
  return isAction(action) && action.type === FLOW_CHANGE_PID_REQUEST;
}

export function handlePatchWorkPackageAction(context: UpdateWorkloadContext, action: UnknownAction): boolean {
  const canHandle = isPatchWorkPackageRequestAction(action);
  if (canHandle && action.options.urlParams.workPackageId) {
    context.updateWorkloadForNode(action.options.urlParams.workPackageId);
  }
  return canHandle;
}
