import {EnumFlowBillingInterval} from '@octaved/env/src/dbalEnumTypes';
import {formatCollapsingDateRange, fromIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {Billing, BillingInPeriod} from '../../../../EntityInterfaces/Billing/Billings';

const renderer: Record<EnumFlowBillingInterval, (from: dayjs.Dayjs, to: dayjs.Dayjs) => string> = {
  [EnumFlowBillingInterval.VALUE_MONTH]: (from) => from.format('MMMM YYYY'),
  [EnumFlowBillingInterval.VALUE_QUARTER]: (from) => `Q${from.quarter()} ${from.format('YYYY')}`,
  [EnumFlowBillingInterval.VALUE_YEAR]: (from) => from.format('YYYY'),
  [EnumFlowBillingInterval.VALUE_CUSTOM]: (from, to) => formatCollapsingDateRange(from, to),
};

export function renderBillingTimePeriod(billing: BillingInPeriod): string {
  return renderer[billing.interval](fromIsoFormat(billing.intervalFrom), fromIsoFormat(billing.intervalTo));
}

export default function TimePeriodFormatted({billing}: {billing: Billing}): ReactElement | null {
  return <>{billing.interval && renderBillingTimePeriod(billing)}</>;
}
