import {Popdown} from '@octaved/ui';
import {ReactElement, RefObject, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Close from '../../../Dialogs/Components/Close';
import {taskListSectionColorSelector} from '../../../Modules/Selectors/Ui/TaskListSelectors';
import {patchTaskListUiState} from '../../../Modules/Ui/TaskList';
import ColorPickerWithPalette from '../../Form/ColorPicker/ColorPickerWithPalette';
import DialogAndDrawerHeader from '../../Layout/DialogAndDrawerHeader';
import Link from '../../Link';

interface Props {
  onClose: () => void;
  sectionId: string;
  triggerRef?: RefObject<HTMLDivElement>;
}

export default function TaskSectionColorPicker({onClose, sectionId, triggerRef}: Props): ReactElement {
  const {t} = useTranslation();
  const colors = useSelector(taskListSectionColorSelector);
  const [color, setColor] = useState<string | null>(colors[sectionId]);
  useEffect(() => setColor(colors[sectionId]), [colors, sectionId]);
  const dispatch = useDispatch();
  const updateColor = useCallback(
    (color: string | null) => {
      setColor(color);
      dispatch(patchTaskListUiState({taskSectionColor: {[sectionId]: color}}));
    },
    [dispatch, sectionId],
  );

  return (
    <>
      <Popdown open setOpen={onClose} context={triggerRef}>
        <div className={'container'}>
          <div className={'close'}>
            <Close close={onClose} />
          </div>
          <DialogAndDrawerHeader header={'taskList:chooseColorForSection'} />
          <ColorPickerWithPalette color={color} setColor={updateColor} />

          <br />
          <Link onClick={() => updateColor(null)}>
            <span>{t('taskList:deleteSelectedColor')}</span>
          </Link>

          {/*#region styles*/}
          {/*language=scss*/}
          <style jsx>{`
            .container {
              width: auto;
              max-width: 350px;
              padding: 20px;
            }

            .close {
              position: absolute;
              top: 0.4375rem;
              right: 0.4375rem;
            }
          `}</style>
          {/*#endregion*/}
        </div>
      </Popdown>
    </>
  );
}
