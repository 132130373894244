import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import StandardInspectorSubMenu from '../../../Drawer/StandardInspector/StandardInspectorSubMenu';
import {InspectorTabItem, TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import {projectControllingDemoModeSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {setProjectControllingDemoMode} from '../../../Modules/UiPages/Projects';
import ProjectControllingInspectorSubMenu from '../../../Pages/Projects/Pages/ControlCommon/ProjectControllingInspectorSubMenu';
import {dialogRouteObjects} from '../Dialogs';
import {quotasRoute, subInspectorRoute} from './InspectorRoutes';
import {quotasRouteObject, subInspectorWrapper} from './Inspectors';
import ProjectControllingDemoModeWrapper from './ProjectControllingDemoModeWrapper';
import {getStandardInspectorRoutes, getWrappedStandardInspectorRoutes} from './StandardInspector';

const sumsRoute = 'sums';
const moreRoute = 'more';
const MoreTab = lazy(() => import('../../../Pages/Projects/Pages/ControlTrackedTime/Inspector/MoreTab'));
const SumsTab = lazy(() => import('../../../Pages/Projects/Pages/ControlTrackedTime/Inspector/SumsTab'));
const DemoSumsTab = lazy(() => import('@octaved/demo-mode').then((module) => ({default: module.SumTab})));
const DemoMoreTab = lazy(() => import('@octaved/demo-mode').then((module) => ({default: module.TrackedTimeMoreTab})));

//#region <inspector tabs>
const wpTabs: InspectorTabItem[] = [
  {
    path: sumsRoute,
    token: 'pages:projects.inspector.menu.sums',
  },
  {
    path: quotasRoute,
    token: 'pages:projects.inspector.menu.quotas',
  },
  {
    path: moreRoute,
    token: 'pages:projects.inspector.menu.more',
  },
];

const tabs: InspectorTabItem[] = [
  {
    path: sumsRoute,
    token: 'pages:projects.inspector.menu.sums',
  },
  {
    path: quotasRoute,
    token: 'pages:projects.inspector.menu.quotas',
  },
  {
    path: moreRoute,
    token: 'pages:projects.inspector.menu.more',
  },
];

const inspectorTabConfig: TabRecords = {
  [EnumFlowNodeType.VALUE_PROJECT]: tabs,
  [EnumFlowNodeType.VALUE_GROUP]: tabs,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: wpTabs,
};

//#endregion

const sumsRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<DemoSumsTab />}>
      <SumsTab />
    </ProjectControllingDemoModeWrapper>
  ),
  path: sumsRoute,
});
const moreRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<DemoMoreTab />}>
      <MoreTab />
    </ProjectControllingDemoModeWrapper>
  ),
  path: moreRoute,
});

export const controlTrackedTimeInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: subInspectorWrapper(
    [
      moreRouteObject(),
      quotasRouteObject(),
      sumsRouteObject(),
      {
        element: <Navigate to={sumsRoute} replace />,
        path: '*',
      },
    ],
    getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu, useOwnProjectsPid: false}),
    subInspectorRoute,
  ),
  standardInspectorProps: {
    actionMenu: ProjectControllingInspectorSubMenu,
    demoModeSelector: projectControllingDemoModeSelector,
    demoModeToggle: setProjectControllingDemoMode,
    tabConfig: inspectorTabConfig,
  },
});
