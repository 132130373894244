import {MenuItem, VerticalCollapsibleContent, cn} from '@octaved/ui';
import classNames from 'classnames';
import {ReactElement, useCallback, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AccessibleItem, isItemAccessibleSelector} from '../AccessibleItem';

type RenderMenuItem = (
  isItemAccessible: (item: AccessibleItem) => boolean,
  pathDecorator?: (path: string) => string,
) => (option: PagesSubMenuOption, idx: number) => ReactElement | null;

// eslint-disable-next-line react/display-name
export const renderMenuItem: RenderMenuItem = (isItemAccessible, pathDecorator) => (option, idx) => {
  if (!isItemAccessible(option)) {
    return null;
  }

  const {disabled, path, name, header} = option;
  if (header) {
    return (
      <MenuItem
        key={idx}
        width={'w-[210px]'}
        className={
          'cursor-default pb-2 pt-8 text-sm font-semibold uppercase text-slate-500 hover:bg-white hover:text-slate-500'
        }
      >
        <Trans i18nKey={name} />
      </MenuItem>
    );
  }
  const to = path || '';
  return (
    <MenuItem
      width={'w-[210px]'}
      key={idx}
      isDisable={disabled}
      path={pathDecorator ? pathDecorator(to) : to}
      label={name}
    />
  );
};

export interface PagesSubMenuOption extends AccessibleItem {
  disabled?: boolean;
  path?: string;
  name: string;
  header?: boolean;
}

interface Props {
  menuOptions: PagesSubMenuOption[];
  alternativeBackground?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
}

export default function PagesSubMenu({
  menuOptions,
  alternativeBackground = false,
  collapsed = false,
  setCollapsed,
}: Props): ReactElement {
  const [collapsedMenu, setCollapsedMenu] = useState(collapsed);
  useEffect(() => setCollapsedMenu(collapsed), [collapsed]);
  const collapseMenu = useCallback(
    (collapse: boolean) => {
      if (setCollapsed) {
        setCollapsed(collapse);
      }
      setCollapsedMenu(collapse);
    },
    [setCollapsed],
  );
  const isItemAccessible = useSelector(isItemAccessibleSelector);
  const renderMenuItemWithAccess = renderMenuItem(isItemAccessible);

  return (
    <div
      className={classNames('relative flex h-full overflow-hidden border-r border-r-slate-200', {
        alternativeBackground,
      })}
    >
      <VerticalCollapsibleContent defaultWidth={'210px'} collapsed={collapsedMenu} setCollapsed={collapseMenu}>
        <div className={'h-full flex-grow overflow-y-auto overflow-x-hidden'}>
          <div className={cn('flex flex-grow flex-col items-stretch gap-y-1', !menuOptions[0].header && 'pt-8')}>
            {menuOptions.map(renderMenuItemWithAccess)}
          </div>
        </div>
      </VerticalCollapsibleContent>
    </div>
  );
}
