import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {TranslatedRole} from '../../EntityInterfaces/Role';
import {getTranslatedRolesSelector} from '../../Modules/Selectors/RoleSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IProjectRoleInspectorContext extends BaseInspectorContext {
  selectedEntity: TranslatedRole;
  selectedType: 'projectRole';
}

function isProjectRoleInspectorContext(context: InspectorContext | null): context is IProjectRoleInspectorContext {
  return context?.selectedType === 'projectRole';
}

export function useProjectRoleInspectorContext(): IProjectRoleInspectorContext {
  const context = useInspectorContext();
  if (!isProjectRoleInspectorContext(context)) {
    throw new Error('is not price surcharge inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IProjectRoleInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity =
    useSelector(getTranslatedRolesSelector)[EnumFlowRoleType.VALUE_PROJECT][baseParams!.selectedId];

  return useMemo<IProjectRoleInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'projectRole',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function ProjectRoleInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
