import {BoardPostType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {error, warning} from '@octaved/env/src/Logger';
import {toChildren, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {castFilter} from '@octaved/utilities';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {BoardPostSaveState, PostWithState} from '../../Components/Board/BoardPost/BoardPostContext';
import {BoardPost} from '../../EntityInterfaces/BoardPost';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {createBoardPostEntity} from '../BoardPosts/BoardPosts';
import {isBoardPost} from '../Selectors/BoardPostSelectors';
import {BoardPostsSortBy} from '../Ui/BoardPosts';
import {useLoadedNodes} from './Nodes';
import {useCombinedNodeSearch} from './NodeSearch';

function getUnsavedKey(pid: Uuid): string {
  return `flow_unsavedBoardPosts_${pid}`;
}

function getUnsavedPosts(pid: Uuid): BoardPost[] {
  const item = localStorage.getItem(getUnsavedKey(pid));
  try {
    const parsed = item && JSON.parse(item);
    if (Array.isArray(parsed)) {
      const bpTypes = new Set<BoardPostType>(Object.values(BoardPostType));
      return castFilter<BoardPost>(parsed, (post) => isBoardPost(post) && bpTypes.has(post.boardPostType)).map(
        (post) => {
          // Make sure new properties on board posts do not crash the app - this might have to be done deep for the
          // data object, too.
          const newEntity = createBoardPostEntity<BoardPost>(post.boardPostType, post.lastContentChangeBy);
          (Object.keys(post) as Array<keyof BoardPost>).forEach((key) => {
            if (newEntity.hasOwnProperty(key)) {
              // @ts-ignore same entity
              newEntity[key] = post[key];
            } else {
              warning(`Dropping property '${key}' from unsaved board post`, {post});
            }
          });
          return newEntity;
        },
      );
    }
  } catch (e) {
    error(e);
  }
  return [];
}

const sortFn: Record<BoardPostsSortBy, (sortAsc: boolean) => (a: PostWithState, b: PostWithState) => number> = {
  createdOn: (sortAsc) => (a, b) => {
    return (sortAsc ? b : a).post.createdOn - (sortAsc ? a : b).post.createdOn;
  },
  lastChange: (sortAsc) => (a, b) => {
    if (sortAsc) {
      return b.post.lastContentChangeOn - a.post.lastContentChangeOn;
    }
    return a.post.lastContentChangeOn - b.post.lastContentChangeOn;
  },
  manual: (sortAsc) => (a, b) => {
    return (sortAsc ? b : a).post.sortOrder - (sortAsc ? a : b).post.sortOrder;
  },
};

interface Options {
  deep?: boolean;
  labels?: ReadonlyArray<Uuid>;
  includeUnsaved?: boolean;
  sortBy?: BoardPostsSortBy;
  types?: ReadonlyArray<BoardPostType>;
  sortAsc?: boolean;
}

export function useBoardPosts(
  nodeId: Uuid,
  {deep = true, labels = [], includeUnsaved = false, sortBy = 'lastChange', types = [], sortAsc = false}: Options = {},
): {
  combined: PostWithState[];
  setUnsaved: (p: BoardPost[]) => void;
  unsavedPosts: BoardPost[];
} {
  const query = useMemo<NodeSearchCondition>(() => {
    return {
      and: [deep ? withDescendants(nodeId, true) : toChildren(nodeId), ['nodeType', EnumFlowNodeType.VALUE_BOARD_POST]],
    };
  }, [deep, nodeId]);

  const {nodeIds: boardPostIds} = useCombinedNodeSearch(query);
  const {nodes: boardPosts} = useLoadedNodes<BoardPost>(boardPostIds);

  //Only fetch the unsaved posts from the direct pid:
  const [unsavedPosts, setUnsavedPosts] = useState<BoardPost[]>([]);

  useEffect(() => {
    setUnsavedPosts(getUnsavedPosts(nodeId));
  }, [nodeId]);

  const setUnsaved = useCallback(
    (newUnsavedPosts: BoardPost[]): void => {
      setUnsavedPosts(newUnsavedPosts);
      localStorage.setItem(getUnsavedKey(nodeId), JSON.stringify(newUnsavedPosts));
    },
    [nodeId],
  );

  const combined = useMemo(() => {
    let posts = boardPosts.map((post) => ({
      post,
      state: post.isPersonal ? BoardPostSaveState.isSaved : BoardPostSaveState.isPosted,
    }));
    if (includeUnsaved) {
      posts.push(
        ...unsavedPosts.map((post) => ({
          post,
          state: BoardPostSaveState.isUnsaved,
        })),
      );
    }
    if (labels.length) {
      const labelsSet = new Set(labels);
      posts = posts.filter(({post}) => post.labels.length && post.labels.some((label) => labelsSet.has(label)));
    }
    if (types.length) {
      const typesSet = new Set(types);
      posts = posts.filter(({post}) => typesSet.has(post.boardPostType));
    }
    return posts.sort(sortFn[sortBy](sortAsc));
  }, [boardPosts, labels, includeUnsaved, sortBy, types, unsavedPosts, sortAsc]);

  return {combined, unsavedPosts, setUnsaved};
}
