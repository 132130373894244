import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {notNIL, RulesList} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {CommonPidNode, CommonPidNodePatchData} from '../EntityInterfaces/Pid';
import {FLOW_REMOVE_NODES_FAILURE, FLOW_REMOVE_NODES_REQUEST, FLOW_REMOVE_NODES_SUCCESS} from './ActionTypes';
import {
  createNodeEntity,
  createNodeErrorFields,
  getNodeEntityCopiedFields,
  transformNodeEntityToPatchData,
  transformPatchDataToNodeEntity,
} from './Nodes';
import {FlowState} from './State';
import {getNodeNameValidationRules} from './Validation/NodeNameValidationRules';

export function createCommonPidNode(
  nodeType: EnumFlowNodeType.VALUE_PROJECT | EnumFlowNodeType.VALUE_GROUP | EnumFlowNodeType.VALUE_WORK_PACKAGE,
  flowCustomer: Uuid,
): CommonPidNode {
  return {
    ...createNodeEntity(nodeType),
    flowCustomer,
    nodeType,
    description: '',
    dueDate: null,
    pid: '',
    sortOrder: 0,
    trackedMinutes: {
      billed: 0,
      billedJourney: 0,
      recorded: 0,
      recordedJourney: 0,
    },
  };
}

export function getCommonPidNodeCopiedFields(): Array<keyof CommonPidNode> {
  return [...getNodeEntityCopiedFields(), 'description'];
}

export function transformCommonPidNodeToPatchData<R extends CommonPidNodePatchData>(pidNode: CommonPidNode): R {
  return transformNodeEntityToPatchData<R>(pidNode);
}

export function transformPatchDataToCommonPidNode<R extends CommonPidNode>(
  pidNode: DeepPartial<CommonPidNodePatchData>,
): DeepPartial<R> {
  return transformPatchDataToNodeEntity<R>(pidNode);
}

export function getCommonPidNodeValidationRules(
  id: Uuid,
  patch: DeepPartial<CommonPidNodePatchData>,
  isCreation: boolean,
  texts: {nameEmpty?: string; nameTooLong?: string} = {},
): RulesList {
  const rules: RulesList = getNodeNameValidationRules(id, patch, isCreation, texts);
  if (patch.flowCustomer) {
    rules.push([notNIL, patch.flowCustomer, 'general:pid.validationErrors.flowCustomer', `customer_${id}`]);
  }
  return rules;
}

export function createCommonPidNodeErrorFields(id: Uuid): string[] {
  return [...createNodeErrorFields(id), `dueDate_${id}`, `pid_${id}`];
}

export interface DeleteCommonPidNode {
  (id: Uuid): ActionDispatcher<boolean, FlowState>;
}

export function createDeleteCommonPidNode(
  urlField: 'projectId' | 'groupId' | 'workPackageId',
  endpoint: string,
): DeleteCommonPidNode {
  return (id) => (dispatch) => {
    dispatch({
      [CALL_API]: {
        endpoint,
        method: 'del',
        options: {
          urlParams: {[urlField]: id},
        },
        types: {
          failureType: FLOW_REMOVE_NODES_FAILURE,
          requestType: FLOW_REMOVE_NODES_REQUEST,
          successType: FLOW_REMOVE_NODES_SUCCESS,
        },
      },
      nodeIds: [id],
    });
    return true;
  };
}
