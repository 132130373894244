import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';

export const projectHasNoWorkPackages: NodeSearchCondition = {
  and: [
    ['nodeType', EnumFlowNodeType.VALUE_PROJECT],
    {not: withAncestors(['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], true)},
  ],
};

export const projectIsNotArchived: NodeSearchCondition = {
  and: [['nodeType', EnumFlowNodeType.VALUE_PROJECT], {not: ['isArchived']}],
};
