import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {useSelector} from 'react-redux';
import {SemanticCOLORS} from 'semantic-ui-react/dist/commonjs/generic';
import {NodeTrackedMinutes} from '../EntityInterfaces/NodeTrackedMinutes';
import {WorkPackage} from '../EntityInterfaces/Pid';
import {billingTypesBookLimitsSelector} from '../Modules/Selectors/SettingSelectors';
import {BillingTypesBookLimits, isBookLimitedBillingType} from '../Modules/Settings/BillingTypeBookLimits';
import {getEffectiveMaxEffort} from './MaxEffort';

export function determineProgressColor(
  {
    billingType,
    effortTo,
    maxEffort,
    trackedMinutes: {billed},
  }: Pick<WorkPackage, 'billingType' | 'maxEffort' | 'effortTo'> & {trackedMinutes: Pick<NodeTrackedMinutes, 'billed'>},
  billingTypesBookLimits: BillingTypesBookLimits,
): SemanticCOLORS | 'darkRed' {
  if (billingType && isBookLimitedBillingType(billingType) && billed) {
    const billedHours = billed / 60;
    const limits = billingTypesBookLimits[billingType];
    const maxValue = getEffectiveMaxEffort({billingType, maxEffort, effortTo});

    if (maxValue) {
      if (limits.maxPercentage && billedHours >= maxValue * (limits.maxPercentage / 100)) {
        return 'darkRed';
      } else if (limits.redFrom100 && billedHours >= maxValue) {
        return 'red';
      } else if (limits.warningPercentage && billedHours >= maxValue * (limits.warningPercentage / 100)) {
        return 'orange';
      }
    }
  }

  return 'green';
}

export function useWorkPackageProgressColor(
  workPackage: Pick<WorkPackage, 'billingType' | 'trackedMinutes' | 'maxEffort' | 'effortTo'> | null | undefined,
): SemanticCOLORS | 'darkRed' {
  const billingTypesBookLimits = useSelector(billingTypesBookLimitsSelector);
  return workPackage ? determineProgressColor(workPackage, billingTypesBookLimits) : 'green';
}

export function useProgressColor(
  billingType: EnumFlowPidBillingType,
  billedHours: number | null = null,
  maxEffort: number | null = null,
  effortTo: number | null = null,
): SemanticCOLORS | 'darkRed' {
  const billingTypesBookLimits = useSelector(billingTypesBookLimitsSelector);
  return determineProgressColor(
    {billingType, trackedMinutes: {billed: (billedHours || 0) * 60}, maxEffort, effortTo},
    billingTypesBookLimits,
  );
}
