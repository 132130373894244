import {ButtonColorScheme, IconButton} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {FormikProps} from 'formik/dist/types';
import {CheckCircle} from 'lucide-react';
import {Children, PropsWithChildren, ReactElement} from 'react';
import ErrorList from './ErrorList';

interface Props<FormikValues> {
  formik: FormikProps<FormikValues>;
  noTopSpacing?: boolean;
  submitButtonColor?: ButtonColorScheme;
  submitIcon?: ReactElement;
  submitLabel?: string;
}

export default function VerticalForm<FormikValues>({
  children,
  formik,
  noTopSpacing = false,
  submitButtonColor = 'add',
  submitIcon = <CheckCircle />,
  submitLabel = '',
}: PropsWithChildren<Props<FormikValues>>): ReactElement {
  const childFragments = Children.toArray(children);
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!noTopSpacing && <Spacer />}
      <ErrorList formik={formik} />

      {childFragments.map((field, index) => (
        <div className={'formField'} key={index}>
          {field}
        </div>
      ))}

      <Spacer spacing={30} />

      <div className={'submit'}>
        <IconButton label={submitLabel} icon={submitIcon} colorScheme={submitButtonColor} type={'submit'} />
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .formField {
          width: 100%;
          margin-bottom: 35px;
        }

        .submit {
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}</style>
    </form>
  );
}
