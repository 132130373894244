import {getProjectControllingTrackedTimePerWorkPackage} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, filterIdsToReload, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {TrackedTimePerWorkPackage} from '../../../EntityInterfaces/Projects/ProjectControlling/TrackedTime';
import {
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_FAILURE,
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_REQUEST,
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_SUCCESS,
} from '../../ActionTypes';
import {
  TimeRecordCreatedEvent,
  TimeRecordPatchedEvent,
  TimeRecordRemovedEvent,
  TimeRecordsRestoredFromTrashEvent,
} from '../../Events';
import {
  getTrackedTimePerWorkPackageKeySelector,
  trackedTimePerWorkPackageStatesSelector,
  trackedTimeRequestFilterOptionsSelector,
} from '../../Selectors/Projects/ProjectControlling/TrackedTimeSelectors';
import {FlowState} from '../../State';
import {useMainProjectTreeUnfilteredWorkPackageIds} from '../ProjectTreeUnfiltered';

interface LoadAction {
  getKey: (wpId: Uuid) => string;
  response: TrackedTimePerWorkPackage;
}

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_SUCCESS,
  (state: TrackedTimePerWorkPackage, {getKey, response}: LoadAction): TrackedTimePerWorkPackage => {
    const newState = {...state};
    Object.entries(response).forEach(([wpId, sum]) => {
      newState[getKey(wpId)] = sum;
    });
    return newState;
  },
);
export const trackedTimePerWorkPackageReducer = ReduceFromMap(reducerMap);

function reduceInvalidateStateForIds(state: EntityStates, ids: Uuid | ReadonlyArray<Uuid>): EntityStates {
  const now = Date.now();
  const set = new Set<Uuid>(boolFilter(Array.isArray(ids) ? ids : [ids]));
  const newState = {...state};
  let changed = false;
  Object.keys(newState).forEach((storeId) => {
    const wpId = storeId.split('@').pop();
    if (set.has(wpId || '')) {
      newState[storeId] = {
        ...newState[storeId],
        invalidated: now,
      };
      changed = true;
    }
  });
  return changed ? newState : state;
}

const stateReducerMap = createReducerCollection<EntityStates>({});
stateReducerMap.add(FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_REQUEST, createTimestampReducer('keys', LOADING));
stateReducerMap.add(FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_SUCCESS, createTimestampReducer('keys', LOADED));
stateReducerMap.add<TimeRecordCreatedEvent | TimeRecordPatchedEvent | TimeRecordRemovedEvent>(
  ['flow.TimeRecordCreatedEvent', 'flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  (state, {affectedTimeTrackingNodeIds}) => reduceInvalidateStateForIds(state, affectedTimeTrackingNodeIds),
);
stateReducerMap.add<TimeRecordsRestoredFromTrashEvent>(
  'flow.TimeRecordsRestoredFromTrashEvent',
  (state, {workPackageIds}) => reduceInvalidateStateForIds(state, workPackageIds),
);
export const trackedTimePerWorkPackageStateReducer = stateReducerMap.reducer;

function loadTrackedTimePerWorkPackage(workPackageIds: ReadonlyArray<Uuid>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const flowState = getState();
    const {from, labelIds, to, unitIds} = trackedTimeRequestFilterOptionsSelector(flowState);
    const getKey = getTrackedTimePerWorkPackageKeySelector(flowState);
    const state = trackedTimePerWorkPackageStatesSelector(flowState);
    const idsToLoad = filterIdsToReload(state, workPackageIds, null, getKey);
    if (idsToLoad.length) {
      dispatch({
        getKey,
        [CALL_API]: {
          endpoint: getProjectControllingTrackedTimePerWorkPackage,
          method: 'post',
          options: {
            data: {
              from,
              labelIds,
              to,
              unitIds,
              workPackageIds: idsToLoad,
            },
          },
          types: {
            failureType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_FAILURE,
            requestType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_REQUEST,
            successType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_WP_SUCCESS,
          },
        },
        keys: idsToLoad.map(getKey),
      });
    }
  };
}

function useLoadTrackedTimePerWorkPackage(workPackageIds: ReadonlyArray<Uuid>): void {
  useStoreEffect(
    (dispatch) => dispatch(loadTrackedTimePerWorkPackage(workPackageIds)),
    [workPackageIds],
    trackedTimeRequestFilterOptionsSelector,
    trackedTimePerWorkPackageStatesSelector,
  );
}

export function useLoadTrackedTimePerWorkPackageForTree(): void {
  const workPackageIds = useMainProjectTreeUnfilteredWorkPackageIds();
  useLoadTrackedTimePerWorkPackage(workPackageIds);
}
