import {Label, TWInput} from '@octaved/ui';
import ErrorList from '@octaved/ui-components/src/React/Formik/ErrorList';
import {FormikHelpers, FormikProps} from 'formik';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import SelectCustomer from '../../Components/Node/SelectCustomer';
import PidColor from '../../Components/Pid/PidColor';
import FolderForNewProject from '../../Components/ProjectFolder/FolderForNewProject';
import {customerEntitiesSelector, formatCustomerLocation} from '../../Modules/Selectors/CustomerSelectors';
import {NewProjectFormik} from './NewProjectForm';
import TemplateSelector from './TemplateSelector';

interface DetailsFormProps {
  formik: FormikHelpers<NewProjectFormik> & FormikProps<NewProjectFormik>;
}

export default function DetailsForm({formik}: DetailsFormProps): ReactElement {
  const customers = useSelector(customerEntitiesSelector);
  const {t} = useTranslation();
  const customerLocationOptions = useMemo((): DropdownItemProps[] => {
    const customer = customers[formik.values.customerId || ''];
    return (customer?.locations || []).map((location) => ({
      text: formatCustomerLocation(location),
      value: location.id,
    }));
  }, [customers, formik.values.customerId]);

  return (
    <>
      <ErrorList formik={formik} noMargin />

      <div className={'grid grid-cols-2 gap-x-6 gap-y-8'}>
        <SelectCustomer
          autoSelectInitialCustomer
          error={!!formik.errors.customerId}
          hideLockedCustomers
          onChange={(customerId) => {
            if (customerId) {
              formik.setFieldValue('customerId', customerId);
              formik.setFieldValue('customerLocationId', null);
            }
          }}
          placeholder={t('dialogs:createProject.customer') || undefined}
          selectedCustomerId={formik.values.customerId}
        />

        {customerLocationOptions.length > 0 && (
          <div className={'flex flex-col justify-stretch'}>
            <Label token={'dialogs:createProject.customerLocation'} />
            <Dropdown
              error={Boolean(formik.touched.customerLocationId && formik.errors.customerLocationId)}
              selection
              options={customerLocationOptions}
              selectOnBlur={false}
              value={formik.values.customerLocationId || ''}
              onChange={(_e, {value}) => {
                formik.setFieldValue('customerLocationId', value || null);
              }}
            />
          </div>
        )}
      </div>

      <TemplateSelector formik={formik} />

      <div className={'w-3/4'}>
        <TWInput
          hasError={Boolean(formik.touched.name && formik.errors.name)}
          label={t('dialogs:createProject.name')}
          maxLength={191}
          onChange={(e) => formik.setFieldValue('name', e.target.value)}
          required
          value={formik.values.name}
        />
      </div>

      <PidColor color={formik.values.color} patch={(color) => formik.setFieldValue('color', color)} isEditing />

      <FolderForNewProject
        folderId={formik.values.projectFolderId}
        setFolderId={(id) => formik.setFieldValue('projectFolderId', id)}
      />
    </>
  );
}
