import RuntimeError from './RuntimeError';

export default class NetworkError<ResponseData = string> extends RuntimeError {
  data: ResponseData;
  response: Response;
  responseStatus: number;

  constructor(data: ResponseData, response: Response) {
    super(data as unknown as string);
    this.data = data;
    this.response = response;
    this.responseStatus = response.status;
  }
}

export function isNetworkError<Data = string>(err: unknown, status?: number): err is NetworkError<Data> {
  return err instanceof NetworkError && (typeof status === 'undefined' || err.responseStatus === status);
}

type RestfulNetworkError<E extends string> = NetworkError<{error: E}>;

/**
 * Detects this PHP-side shape: `throw new ClientValidationException(['error' => 'xxx']);`
 * or similar looking responses.
 */
export function isClientValidationError<E extends string>(
  err: unknown,
  errors: ReadonlyArray<E>,
  status: number | number[] = 400,
): err is RestfulNetworkError<E> {
  const statuses = Array.isArray(status) ? status : [status];
  return (
    err instanceof NetworkError &&
    statuses.includes(err.responseStatus) &&
    err.data &&
    typeof err.data === 'object' &&
    typeof err.data.error === 'string' &&
    errors.includes(err.data.error)
  );
}
