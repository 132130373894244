import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {NodeType} from '../EntityInterfaces/Nodes';
import {patchGroup} from './Groups';
import {patchProjectFolder} from './ProjectFolders';
import {patchProject} from './Projects';
import {FlowState} from './State';
import {patchSubWorkPackage} from './SubWorkPackages';
import {patchWorkPackage} from './WorkPackages';

type Patchers = {
  [T in EnumFlowNodeType]: (id: Uuid, patch: Partial<NodeType & {nodeType: T}>) => ActionDispatcher<void, FlowState>;
};

const patchers = {
  [EnumFlowNodeType.VALUE_GROUP]: patchGroup,
  [EnumFlowNodeType.VALUE_PROJECT]: patchProject,
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: patchSubWorkPackage,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: patchWorkPackage,
  [EnumFlowNodeType.VALUE_PROJECT_FOLDER]: patchProjectFolder,
} as const satisfies Partial<Patchers>;

export function patchNode<T extends keyof typeof patchers>(
  nodeType: T,
  id: Uuid,
  patch: Partial<NodeType & {nodeType: T}>,
): ActionDispatcher<void, FlowState> {
  const patcher = patchers[nodeType];
  if (patcher) {
    // @ts-ignore this is the right patch
    return patcher(id, patch);
  }
  throw new Error(`Missing patcher for node type '${nodeType}'`);
}
