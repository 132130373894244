import {anyNode, useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {createContext, ReactElement, ReactNode, useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {concatQuerySearchStrings} from '../../Modules/Filter/StringsSearch';
import {taskListVisibleColumnsSetSelector} from '../../Modules/Selectors/Ui/TaskListSelectors';
import {getProjectsFilterStatesSelector} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {workPackageOptionsUiSelector} from '../../Modules/Selectors/UiSelectors';
import {FlowState} from '../../Modules/State';
import {useToggleVisibleColumn} from '../../Modules/Ui/TaskList';
import {useProjectContext} from '../../Pages/Projects/ProjectContext';
import TaskListContext, {TaskListContextReset} from '../Task/TaskListContext';
import WorkPackageAsTaskList from '../Tree/Defaults/WorkPackageAsTaskList';
import {
  defaultProjectsTreeComponentContext,
  ProjectsTreeComponentContext,
  projectsTreeComponentContext,
} from '../Tree/ProjectTreeComponentContext';

const components: ProjectsTreeComponentContext = {
  ...defaultProjectsTreeComponentContext,
  workPackage: WorkPackageAsTaskList,
};

interface Context {
  canUseTasksView: boolean;
  taskFilterQuerySelector?: (state: FlowState) => NodeSearchCondition | null;
  useTasksView: boolean;
}

const defaultContext: Context = {canUseTasksView: false, useTasksView: false};
const context = createContext<Context>(defaultContext);
const canUseDisabled: Context = {canUseTasksView: true, useTasksView: false};

export function useProjectTreeTaskListContext(): Context {
  return useContext(context);
}

/**
 * Takes what task filters are available from the project tree filter states and prepares them for the task list
 */
function useProjectTreeTaskListFilterQuery(): NodeSearchCondition | null {
  const {page} = useProjectContext();
  const {taskLabelIds} = useSelector((s: FlowState) => getProjectsFilterStatesSelector(s)(page));
  return useMemo<NodeSearchCondition | null>(() => {
    const conditions: NodeSearchCondition[] = [];

    if (taskLabelIds && taskLabelIds.isActive && taskLabelIds.value.length) {
      conditions.push(concatQuerySearchStrings('labelId', taskLabelIds.value));
    }

    return conditions.length ? {and: conditions} : null;
  }, [taskLabelIds]);
}

// noinspection FunctionNamingConventionJS
function WithTasks({children}: {children: ReactNode}): ReactElement {
  const toggleVisibleColumn = useToggleVisibleColumn();
  const visibleColumns = useSelector(taskListVisibleColumnsSetSelector);
  const taskFilterQuery = useProjectTreeTaskListFilterQuery();
  const ctx = useMemo(() => {
    return {
      canUseTasksView: true,
      taskFilterQuerySelector: () => taskFilterQuery,
      useTasksView: true,
    };
  }, [taskFilterQuery]);
  return (
    <context.Provider value={ctx}>
      <projectsTreeComponentContext.Provider value={components}>
        <TaskListContext
          allowCreationOfTaskSections
          allowCreationOfTasks
          showAdvancedPlanning
          showCopyTasks
          showMoveTasks
          showNotes
          showOpenTaskInspector
          showOpenWorkPackageInspector
          toggleVisibleColumn={toggleVisibleColumn}
          visibleColumns={visibleColumns}
        >
          {children}
        </TaskListContext>
      </projectsTreeComponentContext.Provider>
    </context.Provider>
  );
}

export default function ProjectTreeTaskListContext({children}: {children: ReactNode}): ReactElement {
  const useTasksView = useSelector((s: FlowState) => workPackageOptionsUiSelector(s).showTasks);
  const canReadTasks = useIsGranted('FLOW_NODE_TASK_READ_BASIC', anyNode);
  if (useTasksView && canReadTasks) {
    return <WithTasks>{children}</WithTasks>;
  }
  return <context.Provider value={canUseDisabled}>{children}</context.Provider>;
}

// noinspection FunctionNamingConventionJS
export function ProjectTreeTaskListContextReset({children}: {children: ReactNode}): ReactElement {
  return (
    <context.Provider value={defaultContext}>
      <projectsTreeComponentContext.Provider value={defaultProjectsTreeComponentContext}>
        <TaskListContextReset>{children}</TaskListContextReset>
      </projectsTreeComponentContext.Provider>
    </context.Provider>
  );
}
