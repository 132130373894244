import {Uuid} from '@octaved/typescript/src/lib';
import {Disposable} from '../../Disposable';
import {TreeNode} from './TreeNode';

export type GanttDataProps = Record<never, never>;

export abstract class GanttDataLoader extends Disposable {
  constructor(_props: GanttDataProps) {
    super();
  }

  abstract get flatTree(): TreeNode[];

  getRowIndex(id: Uuid): number {
    return this.flatTree.findIndex((node) => node.id === id);
  }

  abstract isTreeTruncated(): boolean;
}
