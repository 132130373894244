import Konva from 'konva';
import {Group} from 'konva/lib/Group';
import {CalendarContext} from './Calendar/Context/CalendarContext';
import {Disposable} from './Disposable';

export interface RootContainerProps<CTX extends CalendarContext = CalendarContext> {
  ctx: CTX;
}

export class RootContainer<CTX extends CalendarContext = CalendarContext> extends Disposable {
  protected readonly ctx: CTX;
  readonly #root = new Konva.Group({name: this.constructor.name});

  constructor({ctx}: RootContainerProps<CTX>) {
    super();
    this.ctx = ctx;
  }

  get root(): Group {
    return this.#root;
  }

  dispose(): void {
    super.dispose();
    this.root.destroyChildren();
  }
}
