import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {DependencyModalProps} from '@octaved/planning/src/Components/AddDependencyModal/DependencyModal';
import DependencyModalWithTrigger from '@octaved/planning/src/Components/AddDependencyModal/DependencyModalWithTrigger';
import {addLogicalDependency} from '@octaved/planning/src/Modules/LogicalDependencies';
import {
  allLogicalPredecessorsCompletedSelector,
  getAutoChainLogicalPredecessorSelector,
} from '@octaved/planning/src/Selectors/LogicalDependencySelector';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton, Stack, Tooltip} from '@octaved/ui';
import classNames from 'classnames';
import {Link} from 'lucide-react';
import {ReactElement, useCallback, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  getTaskDepthSelector,
  getTaskIsAutoChainedSelector,
  getTaskSelector,
} from '../../../../../../Modules/Selectors/TaskSelectors';
import {FlowState} from '../../../../../../Modules/State';
import {useTaskListReadonlyContext} from '../../../../TaskListReadonlyContext';

function useDependencyNames(taskIds: Uuid[]): string {
  return useSelector((s: FlowState) => {
    const getTask = getTaskSelector(s);
    return taskIds
      .map((id) => {
        const task = getTask(id);
        if (task) {
          return task.name;
        }
        return null;
      })
      .filter(Boolean)
      .join(', ');
  });
}

export function useLogicalDepndencyModalForTask(
  taskId: Uuid,
  planningLogicalPredecessors: Uuid[],
): Pick<DependencyModalProps, 'onSelect' | 'excludedNodeIds' | 'node'> {
  const dispatch = useDispatch();
  const onSelect = useCallback(
    (predecessor: Uuid | null) => {
      if (predecessor) {
        dispatch(addLogicalDependency(taskId, predecessor));
        return true;
      }
      return false;
    },
    [taskId, dispatch],
  );

  const excludedNodeIds = useMemo<Set<Uuid>>(
    () => new Set([taskId, ...planningLogicalPredecessors]),
    [taskId, planningLogicalPredecessors],
  );

  const node = useMemo(
    () => ({
      planningLogicalPredecessors,
      id: taskId,
      nodeType: EnumFlowNodeType.VALUE_TASK,
    }),
    [taskId, planningLogicalPredecessors],
  );

  return {
    excludedNodeIds,
    node,
    onSelect,
  };
}
interface LogicalDependencyProps {
  hasDependency?: boolean;
  planningLogicalPredecessors: Uuid[];
  planningLogicalSuccessors: Uuid[];
  taskId: Uuid;
  taskIsDone?: boolean;
}

export default function LogicalDependency({
  hasDependency,
  planningLogicalPredecessors,
  planningLogicalSuccessors,
  taskId,
  taskIsDone = false,
}: LogicalDependencyProps): ReactElement | null {
  const {t} = useTranslation();
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const readyToBeDone = useSelector(
    (s: FlowState) => allLogicalPredecessorsCompletedSelector(s)(taskId) && !taskIsDone && hasDependency,
  );

  const predecessorNames = useDependencyNames(planningLogicalPredecessors);
  const successorNames = useDependencyNames(planningLogicalSuccessors);
  const isAutoChainActive = useSelector((s: FlowState) => getTaskIsAutoChainedSelector(s)(taskId));
  const isInAutoChain = useSelector((s: FlowState) => getTaskDepthSelector(s)(taskId) === 1);
  const logicalDependencyModalProps = useLogicalDepndencyModalForTask(taskId, planningLogicalPredecessors);
  const isNextUp = useSelector((s: FlowState) => {
    const previousTask = getAutoChainLogicalPredecessorSelector(s)(taskId);
    return (!previousTask || previousTask.status === EnumFlowTaskStatus.VALUE_COMPLETE) && !taskIsDone;
  });

  if (isAutoChainActive) {
    if (!isInAutoChain) {
      return null;
    }

    return (
      <Tooltip toolTipTranslation={'pages:projects.inspector.manage.planning.autoChainTooltip'}>
        <IconButton
          iconColor={isNextUp ? 'green' : 'black'}
          variant={'ghost'}
          icon={<Link className={'linkIcon'} />}
          size={'sm'}
          iconSize={'small'}
          cursor={'help'}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      toolTipTranslation={''}
      content={
        hasDependency ? (
          <Stack spacing={'4px'}>
            {planningLogicalPredecessors.length > 0 && (
              <div>
                <Trans
                  i18nKey={'pages:projects.inspector.manage.planning.logicalDependencyTooltipSuccessor'}
                  values={{tasks: predecessorNames}}
                />
              </div>
            )}
            {planningLogicalSuccessors.length > 0 && (
              <div>
                <Trans
                  i18nKey={'pages:projects.inspector.manage.planning.logicalDependencyTooltipPredecessor'}
                  values={{tasks: successorNames}}
                />
              </div>
            )}
            {readyToBeDone && <div>{t('pages:projects.inspector.manage.planning.logicalDependencyTooltipInfo')}</div>}
          </Stack>
        ) : (
          <div>{t('pages:projects.inspector.manage.planning.logicalDependencyTooltipNoDependency')}</div>
        )
      }
    >
      <div className={'logicalDependency'}>
        <DependencyModalWithTrigger
          trigger={
            <IconButton
              isDisabled={readonlyOrNotManageable}
              iconColor={readyToBeDone ? 'green' : 'black'}
              variant={'ghost'}
              icon={<Link className={'linkIcon'} />}
              size={'sm'}
              iconSize={'small'}
              className={classNames({onHover: !hasDependency && !readonlyOrNotManageable})}
            />
          }
          dialogHeaderToken={'pages:projects.inspector.manage.planning.newDependencyPredecessor'}
          dialogInfo={'pages:projects.inspector.manage.planning.newDependencyLogicalInfo'}
          plannedOnly={false}
          dependencyType={'logical'}
          {...logicalDependencyModalProps}
        />

        <style jsx>{`
          .logicalDependency :global(.ui.button.flowButton svg) {
            width: 16px;
            height: 16px;
          }
        `}</style>
      </div>
    </Tooltip>
  );
}
