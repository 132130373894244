import '@fortawesome/fontawesome-pro/css/solid.min.css';
import classNames from 'classnames';
import {ReactElement} from 'react';

interface Props {
  color: string;
}

export default function ProjectIconView({color}: Props): ReactElement {
  return (
    <div className={classNames('projectInitials')}>
      {/*language=SCSS*/}
      <style jsx>{`
        .projectInitials {
          height: 4px;
          width: 15px;
          border-radius: 2px;
          background-color: ${'#' + color};
        }
      `}</style>
    </div>
  );
}
