import {FlowState} from '@octaved/flow/src/Modules/State';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';
import {useLoadPlanningDates} from '../Modules/PlanningDates';
import {getMinMaxPlanningDatesSelector, MinMaxPlanningDatesResult} from '../Selectors/PlanningDateSelectors';

export function useMinMaxPlanningDates(nodeId: MaybeUuid): MinMaxPlanningDatesResult {
  useLoadPlanningDates(nodeId);
  return useSelector(
    (s: FlowState) => getMinMaxPlanningDatesSelector(s)(nodeId),
    (a, b) => isEqual(a, b),
  );
}
