import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {createInheritingReducer} from '@octaved/utilities/src/Search/SearchReducers';
import {ProjectCreatedEvent} from '../Events';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<ProjectCreatedEvent>('flow.ProjectCreatedEvent', {
  assignedAnyProjectRole: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedAnyProjectRole', parentNodeId, nodeId),
  }),
  assignedProjectRoleId: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedProjectRoleId', parentNodeId, nodeId),
  }),
  assignedProjectRoleType: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedProjectRoleType', parentNodeId, nodeId),
  }),
  bpWaitingForMyResponse: 'ignored',
  customerId: ({customerId, nodeId}) => ({
    results: (s) => (customerId ? reduceAddOptional(s, nodeId, 'customerId', customerId) : s),
  }),
  customerIsBillable: 'clear',
  customerName: 'clear', //free text search too complex to reduce here
  customerNumber: 'clear',
  favorite: 'ignored', //new nodes can't be favorites, yet
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: 'ignored',
  hasTimeBasedPredecessor: 'ignored',
  isArchived: 'ignored',
  labelId: 'clear', //new node can be created with labels
  mrIsActive: 'ignored',
  name: 'clear', //name not exposed in event
  nodeNotPlanned: 'ignored',
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: ({nodeId}) => ({
    results: (s) => reduceAddOptional(s, nodeId, 'nodeType', EnumFlowNodeType.VALUE_PROJECT),
  }),
  pidHasDueDate: 'clear',
  pidHasMilestoneDate: 'clear',
  pidPid: 'clear', //not exposed in event
  prCustomerLocationId: 'clear',
  prCustomerLocationNumber: 'clear',
  prIsClosed: 'clear', //not exposed in event
  prIsLocked: 'clear', //not exposed in event
  prIsTemplate: 'clear', //not exposed in event
  referenceNumber: 'clear',
  responsibleByAny: ({definesOwnResponsible, parentNodeId, nodeId}) =>
    definesOwnResponsible ? 'clear' : {results: createInheritingReducer('responsibleByAny', parentNodeId, nodeId)},
  responsibleUnitId: ({definesOwnResponsible, parentNodeId, nodeId}) =>
    definesOwnResponsible ? 'clear' : {results: createInheritingReducer('responsibleUnitId', parentNodeId, nodeId)},
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: 'ignored',
  taskCompletedInDateRange: 'ignored', //not exposed in event
  taskStatus: 'ignored', //not exposed in event
  timeControlExceeded: 'clear',
  timeControlNotStarted: 'clear',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
