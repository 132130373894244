import {UserType} from '@octaved/env/src/dbalEnumTypes';

export enum ExtendedUnitType {
  apiUser = 'apiUser',
  customerUser = 'customerUser',
  dummyUser = 'dummyUser',
  generalGuestUser = 'generalGuestUser',
  internalUserGroup = 'internalUserGroup', //This could be extended for internal/guest users, too, at some point
  lightUser = 'lightUser',
  regularUser = 'regularUser',
  systemUser = 'systemUser',
}

export const responsibleUnitTypes = [
  ExtendedUnitType.customerUser,
  ExtendedUnitType.dummyUser,
  ExtendedUnitType.generalGuestUser,
  ExtendedUnitType.internalUserGroup,
  ExtendedUnitType.lightUser,
  ExtendedUnitType.regularUser,
] as const;

export const teamPlanningUnitTypes = [
  ExtendedUnitType.dummyUser,
  ExtendedUnitType.internalUserGroup,
  ExtendedUnitType.lightUser,
  ExtendedUnitType.regularUser,
] as const;

export const projectRoleUnitTypes = [ExtendedUnitType.internalUserGroup, ExtendedUnitType.regularUser] as const;

export const permissionUnitTypes = [
  ExtendedUnitType.apiUser,
  ExtendedUnitType.internalUserGroup,
  ExtendedUnitType.regularUser,
] as const;

export const timeTrackingUserUnitTypes = [ExtendedUnitType.regularUser] as const;
export const timeTrackingForUnitTypes = [ExtendedUnitType.internalUserGroup, ExtendedUnitType.regularUser] as const;

//NOTE: Currently I'm not adding the light user to permissions or time tracking. That should be something for a regular user.

const userToExtended = {
  [UserType.api]: ExtendedUnitType.apiUser,
  [UserType.guestCustomer]: ExtendedUnitType.customerUser,
  [UserType.dummy]: ExtendedUnitType.dummyUser,
  [UserType.guestOther]: ExtendedUnitType.generalGuestUser,
  [UserType.light]: ExtendedUnitType.lightUser,
  [UserType.regular]: ExtendedUnitType.regularUser,
  [UserType.system]: ExtendedUnitType.systemUser,
} as const satisfies Record<UserType, ExtendedUnitType>;
export type ExtendedUnitTypeForUser = (typeof userToExtended)[keyof typeof userToExtended];
export const extendedUnitTypesForUser = Object.values(userToExtended) as ReadonlyArray<ExtendedUnitTypeForUser>;

export const extendedUnitTypesForGroup = [ExtendedUnitType.internalUserGroup] as const;

const extendedToUser = Object.fromEntries(Object.entries(userToExtended).map(([e, u]) => [u, e])) as Record<
  ExtendedUnitType,
  UserType | undefined
>;

export enum SimpleUnitType {
  group = 'group',
  user = 'user',
}

export type SimpleFromExtendedUnitType<UT extends ExtendedUnitType> = UT extends ExtendedUnitType.internalUserGroup
  ? SimpleUnitType.group
  : SimpleUnitType.user;

export function extendedToSimpleUnitType(type: ExtendedUnitType): SimpleUnitType {
  return type === ExtendedUnitType.internalUserGroup ? SimpleUnitType.group : SimpleUnitType.user;
}

export function normalizeToSimpleUnitType(type: ExtendedUnitType | SimpleUnitType): SimpleUnitType {
  if (type === SimpleUnitType.group || SimpleUnitType.user) {
    return type as SimpleUnitType;
  }
  return extendedToSimpleUnitType(type as ExtendedUnitType);
}

export function userToExtendedType(type: UserType): ExtendedUnitTypeForUser {
  return userToExtended[type];
}

export function extendedToUserTypes(types: ReadonlyArray<ExtendedUnitType>): UserType[] {
  return types.reduce<UserType[]>((acc, type) => {
    const uType = extendedToUser[type];
    if (uType) {
      acc.push(uType);
    }
    return acc;
  }, []);
}
