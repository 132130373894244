import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat, isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {ArrowLeft, ArrowRight} from 'lucide';
import {calculateDateLimits, resizeBar, ResizeStartpoint} from '../../../Calculations/BarMovement';
import {updatePlanningDate} from '../../../Modules/PlanningDates';
import {getPlanningDateSelector, getPlanningDatesForNodeSelector} from '../../../Selectors/PlanningDateSelectors';
import {canChangeBarSelector} from '../../../Selectors/PlanningDependencySelectors';
import {HoverRowChangedEvent} from '../../Calendar/Context/CalendarContext';
import {setMouseHoverEffect} from '../../MouseHoverCursor';
import {MouseMove, MouseMoveEvent} from '../../MouseMove';
import {createStoreSubscription} from '../../StoreSubscription';
import {Bar} from '../Bar';
import {BarPlugin, BarPluginProps} from './BarPlugin';

interface ResizeBarPluginProps extends BarPluginProps {
  isFirst: boolean;
  isLast: boolean;
  planningDateId: Uuid;
}
export class ResizeBarPlugin extends BarPlugin {
  static readonly imageSize = 14;

  #leftArrow: Konva.Image | null = null;
  #rightArrow: Konva.Image | null = null;
  protected readonly arrowDistance = 0;

  #endCanBeResized = false;
  #startCanBeResized = false;

  readonly #planningDateId: Uuid;

  #mouseMoveHandlerLeft: MouseMove | null = null;
  #mouseMoveHandlerRight: MouseMove | null = null;

  #limitEnd: null | DateTimeStr = null;
  #limitStart: null | DateTimeStr = null;

  constructor(props: ResizeBarPluginProps) {
    super(props);
    this.#planningDateId = props.planningDateId;
  }

  init(bar: Bar): void {
    super.init(bar);
    this.#createArrows();

    this.disposables.push(
      this.ctx.eventEmitter.on('hoverRowChanged', this.#onHover),
      createStoreSubscription(
        this.ctx.store,
        (s) => canChangeBarSelector(s)(this.nodeId),
        (canChangeBar) => {
          this.#startCanBeResized = canChangeBar;
          this.#endCanBeResized = canChangeBar;
          if (canChangeBar) {
            this.#mouseMoveHandlerLeft?.enable();
            this.#mouseMoveHandlerRight?.enable();
          } else {
            this.#mouseMoveHandlerLeft?.disable();
            this.#mouseMoveHandlerRight?.disable();
            //only hide visible arrow otherwise it will override hover
            this.#leftArrow?.visible(canChangeBar);
            this.#rightArrow?.visible(canChangeBar);
          }
        },
      ),
    );
  }

  #createArrows(): void {
    Promise.all([
      this.iconToImage(ArrowRight, {
        height: ResizeBarPlugin.imageSize,
        imageConfig: {visible: false},
        width: ResizeBarPlugin.imageSize,
      }),
      this.iconToImage(ArrowLeft, {
        height: ResizeBarPlugin.imageSize,
        imageConfig: {visible: false},
        width: ResizeBarPlugin.imageSize,
      }),
    ]).then(([arrowRight, arrowLeft]) => {
      this.#leftArrow = arrowLeft;
      this.bar.menuRoot.add(this.#leftArrow);
      setMouseHoverEffect(arrowLeft, 'ew-resize');

      this.#mouseMoveHandlerLeft = new MouseMove({
        eventNode: this.#leftArrow,
        onEnd: (e) => this.#onFinish(e, ResizeStartpoint.left),
        onMove: (e) => this.#onMove(e, ResizeStartpoint.left),
        onStart: () => this.#onStart(),
      });
      this.#mouseMoveHandlerLeft.init();
      this.disposables.push(
        () => this.#mouseMoveHandlerLeft?.dispose(),
        () => this.#leftArrow?.destroy(),
      );

      this.#rightArrow = arrowRight;
      this.bar.menuRoot.add(this.#rightArrow);
      setMouseHoverEffect(arrowRight, 'ew-resize');

      this.#mouseMoveHandlerRight = new MouseMove({
        eventNode: this.#rightArrow,
        onEnd: (e) => this.#onFinish(e, ResizeStartpoint.right),
        onMove: (e) => this.#onMove(e, ResizeStartpoint.right),
        onStart: () => this.#onStart(),
      });
      this.#mouseMoveHandlerRight.init();
      this.disposables.push(
        () => this.#mouseMoveHandlerRight?.dispose(),
        () => this.#rightArrow?.destroy(),
      );

      this.onBarUpdated();
      this.#onHover(this.ctx.hoveredRow);
    });
  }

  getLockIdent(): string {
    return `resize-${this.nodeId}`;
  }

  #onStart(): void {
    if (this.ctx.hasEditLock()) {
      this.#mouseMoveHandlerLeft?.cancel();
      this.#mouseMoveHandlerRight?.cancel();
      return;
    }
    const state = this.ctx.store.getState();
    const planningDates = getPlanningDatesForNodeSelector(state)(this.nodeId);
    const {end, start} = calculateDateLimits(this.#planningDateId, planningDates);
    this.#limitEnd = end;
    this.#limitStart = start;
    this.ctx.onMoveCloseInspector(this.nodeId);
    this.ctx.editLockIdent = this.getLockIdent();
  }
  #onMove(event: MouseMoveEvent, dir: ResizeStartpoint): void {
    if (this.ctx.editLockIdent === this.getLockIdent()) {
      const result = this.#getResizeBarResult(event, dir);
      if (result) {
        this.bar.setDates(result.plannedStart, result.plannedEnd);
      }
    } else {
      this.#mouseMoveHandlerLeft?.cancel();
      this.#mouseMoveHandlerRight?.cancel();
    }
  }
  #onFinish(event: MouseMoveEvent, dir: ResizeStartpoint): void {
    const result = this.#getResizeBarResult(event, dir);
    if (result) {
      this.ctx.store.dispatch(updatePlanningDate(this.nodeId, this.#planningDateId, result));
    }
    this.ctx.editLockIdent = null;
  }

  #getResizeBarResult(
    e: MouseMoveEvent,
    dir: ResizeStartpoint,
  ): {
    plannedStart: DateTimeStr;
    plannedEnd: DateTimeStr;
  } | null {
    const state = this.ctx.store.getState();
    const getWorkHoursAtDate = getOrgWorkMinutesAtDateSelector(state);
    const planningDate = getPlanningDateSelector(state)(this.#planningDateId);
    if (planningDate?.plannedStart) {
      const result = resizeBar(
        e.startX,
        e.mouseX,
        dir,
        isoDateTimeToIsoFormat(planningDate.plannedStart),
        isoDateTimeToIsoFormat(planningDate.plannedEnd),
        this.ctx.columnWidth,
        this.#limitStart,
        this.#limitEnd,
        getWorkHoursAtDate,
      );
      return {
        plannedEnd: isoToIsoDateTimeFormat(result.plannedEnd),
        plannedStart: isoToIsoDateTimeFormat(result.plannedStart),
      };
    }
    return null;
  }

  #onHover = (value: HoverRowChangedEvent | null): void => {
    if (value?.nodeId === this.nodeId) {
      this.#rightArrow?.visible(this.#endCanBeResized);
      this.#leftArrow?.visible(this.#startCanBeResized);
    } else {
      this.#rightArrow?.visible(false);
      this.#leftArrow?.visible(false);
    }
  };

  onBarUpdated(): void {
    super.onBarUpdated();
    this.#rightArrow?.x(this.bar.x + this.bar.width + this.arrowDistance);
    this.#leftArrow?.x(this.bar.x + -ResizeBarPlugin.imageSize - this.arrowDistance);

    const y = this.bar.height / 2 - ResizeBarPlugin.imageSize / 2 + this.bar.absolutY;
    this.#rightArrow?.y(y);
    this.#leftArrow?.y(y);
  }
}
