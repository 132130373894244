import PlaceholderText from '@octaved/flow/src/Components/Feedback/Placeholder/PlaceholderText';
import {isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {cn, tableGrid, tableGridCellVariant} from '@octaved/ui';
import {ComponentType, Fragment, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {PlanningRealizationNode} from '../../../../EntityInterfaces/PlanningRealizationNode';
import {PatchPlanningDependency} from '../../../../Modules/Planning';

export interface DependencyListRowProps<Dependency extends PatchPlanningDependency> {
  node: PlanningRealizationNode;
  readonly: boolean;
  dependency: Dependency;
  hasMoreDependencies: boolean;
}

interface DependencyListProps<Dependency extends PatchPlanningDependency> {
  dependencies: Dependency[];
  getKey(dependency: Dependency): string;
  headlineToken?: string;
  node: PlanningRealizationNode;
  readonly: boolean;
  row: ComponentType<DependencyListRowProps<Dependency>>;
}

export default function DependencyList<Dependency extends PatchPlanningDependency>({
  dependencies,
  getKey,
  headlineToken,
  node,
  readonly,
  row: Row,
}: DependencyListProps<Dependency>): ReactElement | null {
  const {t} = useTranslation();

  if (dependencies.length === 0) {
    return <PlaceholderText>{t('pages:projects.inspector.manage.planning.noDependenciesPlaceholder')}</PlaceholderText>;
  }
  const hasMoreDependencies = dependencies.length > 1;

  return (
    <div
      className={cn(
        tableGrid({className: 'grid-cols-[minmax(120px,1fr)_100px_minmax(120px,max-content)_max-content] gap-y-2'}),
      )}
    >
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'left'}))}>
        {headlineToken && t(headlineToken)}
        {!headlineToken && (
          <>
            {isWorkPackage(node) && t('pages:projects.inspector.manage.planning.headlineWorkPackage')}
            {isSubWorkPackage(node) && t('pages:projects.inspector.manage.planning.headlineWorkPackage')}
            {isTask(node) && t('pages:projects.inspector.manage.planning.headlineTask')}
          </>
        )}
      </div>
      <div className={cn(tableGridCellVariant({isHeader: true}))}>
        {t('pages:projects.inspector.manage.planning.headlineType')}
      </div>
      <div className={cn(tableGridCellVariant({isHeader: true}))}>
        {t('pages:projects.inspector.manage.planning.headlineOffset')}
      </div>
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'right'}))}>&nbsp;</div>
      {dependencies.map((dependency) => (
        <Fragment key={getKey(dependency)}>
          <Row
            key={getKey(dependency)}
            node={node}
            readonly={readonly}
            dependency={dependency}
            hasMoreDependencies={hasMoreDependencies}
          />
          <div className={'col-span-4 h-px bg-slate-100'} />
        </Fragment>
      ))}
    </div>
  );
}
