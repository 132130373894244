import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useNodeTaskPrognosis} from '../../Values/NodeTasksValue';

export const hiddenBillingTypesForExceedingTaskEffort = new Set<EnumFlowPidBillingType>([
  EnumFlowPidBillingType.VALUE_EFFORT,
  EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE,
]);

interface ExceedsTaskEffortSectionProps {
  node: NodeType;
}

export default function ExceedsTaskEffortSection({node}: ExceedsTaskEffortSectionProps): ReactElement | null {
  const {t} = useTranslation();
  const {isExceeding, prognosis} = useNodeTaskPrognosis(node.id);
  const hiddenByBillingType =
    isWorkPackage(node) && node.billingType && hiddenBillingTypesForExceedingTaskEffort.has(node.billingType);
  if (isExceeding && !hiddenByBillingType) {
    return (
      <div>
        <div className={'text-red-600'}>
          <Flame className={'-mt-[0.6] mr-1 inline-flex h-5 w-5 text-red-500'} strokeWidth={1.5} />
          {t('nodeDetails:field.tasks.tasksHours.exceedsRemainingEffort')}
        </div>
        <div className={'mt-1 text-sm'}>
          {t('nodeDetails:field.tasks.tasksHours.exceedsRemainingEffort.remainingTaskEffort', {
            count: prognosis?.remainingPlannedTime,
          })}
          {', '}
          {t('nodeDetails:field.tasks.tasksHours.exceedsRemainingEffort.remainingTrackedTime', {
            count: prognosis?.remainingTrackedTime,
          })}
        </div>
      </div>
    );
  }
  return null;
}
