import {Uuid} from '@octaved/typescript/src/lib';
import UnitSearch, {UnitSearchProps} from '@octaved/users/src/Components/UnitSearch';
import {ExtendedUnitType} from '@octaved/users/src/UnitType';
import {ReactElement, useMemo} from 'react';
import {useDeprecatedUserIds} from '../../Modules/Users/DeprecatedUsers';
import UnitNameWithIcon from './UnitNameWithIcon';

export interface FlowUnitSearchProps<T extends ExtendedUnitType = ExtendedUnitType>
  extends Omit<UnitSearchProps<T>, 'closeOnSelect' | 'ResultItemRenderer'> {
  includeDeprecatedUsers?: boolean;
}

export default function FlowUnitSearch<T extends ExtendedUnitType = ExtendedUnitType>({
  excludedUnits,
  includeDeprecatedUsers,
  ...rest
}: FlowUnitSearchProps<T>): ReactElement {
  const {userIds: deprecatedUserIds} = useDeprecatedUserIds(!includeDeprecatedUsers);

  const excludedUnitIds = useMemo<Uuid[]>(() => {
    const ids = new Set(excludedUnits || []);
    if (!includeDeprecatedUsers) {
      deprecatedUserIds.forEach((id) => ids.add(id));
    }
    return [...ids];
  }, [deprecatedUserIds, excludedUnits, includeDeprecatedUsers]);

  return (
    <UnitSearch<T>
      {...(rest as UnitSearchProps<T>)}
      closeOnSelect
      excludedUnits={excludedUnitIds}
      ResultItemRenderer={({result}) => <UnitNameWithIcon className={'-mx-3'} avatarSize={'small'} {...result} />}
    />
  );
}
