/**
 * This is a port of WriteValidatorFunctions.php and should remain that way!
 */
import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {IsGranted} from '@octaved/security/src/Authorization/Authorization';
import {asDateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {t} from 'i18next';
import {TimeRecord} from '../EntityInterfaces/TimeRecords';
import {NodeAncestryWithWp} from '../Modules/Selectors/NodeTreeSelectors';
import {Settings} from '../Modules/Settings';
import {
  CloseableNode,
  isCloseableNode,
  isGroup,
  isLockingNode,
  isProject,
  isSubWorkPackage,
  isTimeControlledNode,
  isWorkPackage,
  LockingNode,
  TimeControlledNode,
} from '../Node/NodeIdentifiers';
import {getEffectiveMaxEffort} from '../WorkPackage/MaxEffort';
import {timeTrackingWillExceedBudget} from './AncestorBudget';
import {BillingStartEnd} from './BillingStartEnd';
import {isWithinBillingStartEnd as isWithinBookTimeDaysTolerance} from './BookTimeDaysTolerance';
import {canManageNonBookable} from './CanManage';
import {timeTrackingWillExceedLimit, WillExceedSuggestion} from './OverBooking';
import {isWithin as isWithinTimeControl} from './TimeControl';
import {TimeTrackingErrors} from './WarningErrorFields';
import {isFrozen} from './WorkPackageFrozenUntil';

export function validateAlreadyBilled(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  record: TimeRecord,
  isRemove: boolean,
): void {
  if (
    record.billedOn &&
    record.workPackage &&
    (isRemove || !canManageNonBookable(isGranted, superUserActive, record.workPackage))
  ) {
    errors.push({
      field: 'timeTracking_notAllowedAlreadyMarkedAsBilled',
      message: 'timeRecordings:errors.notAllowedAlreadyMarkedAsBilled',
    });
  }
}

export function validateBookingTimeWithinTolerance(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  settings: Settings,
  {ancestors}: NodeAncestryWithWp,
  startEnd: BillingStartEnd,
): void {
  if (canManageNonBookable(isGranted, superUserActive, ancestors[0].id)) {
    return;
  }
  if (!isWithinBookTimeDaysTolerance(settings, startEnd)) {
    errors.push({
      field: 'timeTracking_notAllowedExceedingBookTimeDaysTolerance',
      message: {
        i18nKey: 'timeRecordings:errors.notAllowedBookTimeDaysTolerance',
        values: {
          count: settings.timeRecordingBookDaysTolerance,
        },
      },
    });
  }
}

export function validateTimeControl(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  {ancestors}: NodeAncestryWithWp,
  startEnd: BillingStartEnd,
): void {
  if (canManageNonBookable(isGranted, superUserActive, ancestors[0].id)) {
    return;
  }
  for (const ancestor of ancestors) {
    if (isTimeControlledNode(ancestor) && !isWithinTimeControl(ancestor, startEnd)) {
      errors.push({
        field: 'timeTracking_notAllowedExceedingTimeControl',
        message: (
          {
            group: 'timeRecordings:errors.notAllowedExceedingTimeControl.group',
            project: 'timeRecordings:errors.notAllowedExceedingTimeControl.project',
            workPackage: 'timeRecordings:errors.notAllowedExceedingTimeControl.workPackage',
          } satisfies Record<TimeControlledNode['nodeType'], string>
        )[ancestor.nodeType],
      });
    }
  }
}

function validateOverbooking(
  errors: TimeTrackingErrors,
  settings: Settings,
  billingType: EnumFlowPidBillingType,
  maxEffort: number | null,
  billedMinutes: number,
  previous: BillingStartEnd,
  patch: BillingStartEnd,
): boolean {
  const suggestion: WillExceedSuggestion = {};
  if (timeTrackingWillExceedLimit(settings, billingType, maxEffort, billedMinutes, previous, patch, suggestion)) {
    const message: string[] = [];

    if (suggestion.maxEnd && suggestion.maxEndRounded) {
      //space left
      message.push(t('timeRecordings:errors.notAllowedOverbooked-1'));
      message.push(
        t('timeRecordings:errors.notAllowedOverbooked-2', {
          endTime: dayjs.unix(suggestion.maxEndRounded).utc().format('HH:mm'),
        }),
      );
      if (suggestion.maxEnd === suggestion.maxEndRounded) {
        message.push(t('timeRecordings:errors.notAllowedOverbooked-3'));
      }
    } else {
      //completely full
      message.push(t('timeRecordings:errors.notAllowedFull'));
    }

    errors.push({field: 'timeTracking_notAllowedToOverbook', message: message.join(' ')});

    return true;
  }
  return false;
}

export function validateTimeBudgets(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  settings: Settings,
  prevAncestry: NodeAncestryWithWp | null,
  nextAncestry: NodeAncestryWithWp,
  prevPeriod: BillingStartEnd,
  nextPeriod: BillingStartEnd,
  prevIsJourney: boolean,
  nextIsJourney: boolean,
): void {
  if (nextIsJourney || canManageNonBookable(isGranted, superUserActive, nextAncestry.ancestors[0].id)) {
    return;
  }
  const emptyPeriod: BillingStartEnd = {billingStart: null, billingEnd: null};

  //If the record was a journey previously, then we cannot subtract the previous time from any node
  // because it wasn't counted anywhere, so we reset the prev start/end:
  const countingPrevPeriod = prevIsJourney ? emptyPeriod : prevPeriod;

  const prevAncestors = Object.fromEntries(prevAncestry?.ancestors.map((a) => [a.id, a]) || []);

  for (const ancestor of nextAncestry.ancestors) {
    //If we keep this ancestor, use the prevPeriod to subtract from the previous pool of time, otherwise use empty:
    const curPrev = prevAncestors[ancestor.id] ? countingPrevPeriod : emptyPeriod;

    if (isProject(ancestor) && timeTrackingWillExceedBudget(ancestor, curPrev, nextPeriod)) {
      errors.push({
        field: 'timeTracking_notAllowedToExceedProjectBudget',
        message: 'timeRecordings:errors.notAllowedToExceedProjectBudget',
      });
      return;
    }

    if (isGroup(ancestor) && timeTrackingWillExceedBudget(ancestor, curPrev, nextPeriod)) {
      errors.push({
        field: 'timeTracking_notAllowedToExceedGroupBudget',
        message: 'timeRecordings:errors.notAllowedToExceedGroupBudget',
      });
      return;
    }

    if (
      isWorkPackage(ancestor) &&
      ancestor.billingType &&
      validateOverbooking(
        errors,
        settings,
        ancestor.billingType,
        getEffectiveMaxEffort(ancestor),
        ancestor.trackedMinutes.billed,
        curPrev,
        nextPeriod,
      )
    ) {
      return;
    }

    if (
      isSubWorkPackage(ancestor) &&
      nextAncestry.workPackage.billingType &&
      validateOverbooking(
        errors,
        settings,
        nextAncestry.workPackage.billingType,
        ancestor.maxEffort,
        ancestor.trackedMinutes.billed,
        curPrev,
        nextPeriod,
      )
    ) {
      return;
    }
  }
}

export function validateApprovedForBilling(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  {workPackage}: NodeAncestryWithWp,
): void {
  if (canManageNonBookable(isGranted, superUserActive, workPackage.id)) {
    return;
  }
  if (workPackage.isApprovedForBilling) {
    errors.push({
      field: 'timeTracking_notAllowedApprovedForBilling',
      message: 'timeRecordings:errors.notAllowedApprovedForBilling',
    });
  }
}

export function validateArchived(errors: TimeTrackingErrors, {ancestors}: NodeAncestryWithWp): void {
  if (ancestors[0].isArchived) {
    errors.push({
      field: 'timeTracking_notAllowedArchived',
      message: 'timeRecordings:errors.notAllowedArchived',
    });
  }
}

export function validateLocked(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  {ancestors}: NodeAncestryWithWp,
): void {
  if (canManageNonBookable(isGranted, superUserActive, ancestors[0].id)) {
    return;
  }
  for (const ancestor of ancestors) {
    if (isLockingNode(ancestor) && ancestor.isLocked) {
      errors.push({
        field: 'timeTracking_notAllowedAncestorLocked',
        message: (
          {
            group: 'timeRecordings:errors.notAllowedAncestorLocked.group',
            project: 'timeRecordings:errors.notAllowedAncestorLocked.project',
            subWorkPackage: 'timeRecordings:errors.notAllowedAncestorLocked.subWorkPackage',
            workPackage: 'timeRecordings:errors.notAllowedAncestorLocked.workPackage',
          } satisfies Record<LockingNode['nodeType'], string>
        )[ancestor.nodeType],
      });
      return;
    }
  }
}

export function validateClosed(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  {ancestors}: NodeAncestryWithWp,
): void {
  if (canManageNonBookable(isGranted, superUserActive, ancestors[0].id)) {
    return;
  }
  for (const ancestor of ancestors) {
    if (isCloseableNode(ancestor) && ancestor.isClosed) {
      errors.push({
        field: 'timeTracking_notAllowedAncestorClosed',
        message: (
          {
            group: 'timeRecordings:errors.notAllowedAncestorClosed.group',
            project: 'timeRecordings:errors.notAllowedAncestorClosed.project',
            workPackage: 'timeRecordings:errors.notAllowedAncestorClosed.workPackage',
          } satisfies Record<CloseableNode['nodeType'], string>
        )[ancestor.nodeType],
      });
      return;
    }
  }
}

export function validateTemplate(errors: TimeTrackingErrors, {project}: NodeAncestryWithWp): void {
  if (project.isTemplate) {
    errors.push({
      field: 'timeTracking_notAllowedTemplate',
      message: 'timeRecordings:errors.notAllowedTemplate',
    });
  }
}

export function validateUserAccess(
  isGranted: IsGranted,
  errors: TimeTrackingErrors,
  currentUserId: Uuid,
  nodeId: Uuid | null,
  userId: Uuid | null | undefined,
): void {
  if (!userId) {
    errors.push({
      field: 'timeTracking_missingUser',
      message: 'timeRecordings:errors.missingUser',
    });
    return;
  }
  if (currentUserId === userId) {
    if (nodeId && !isGranted('FLOW_NODE_PROJECT_TIME_TRACKING_MANAGE_BOOKABLE_OWN', nodeId)) {
      errors.push({
        field: 'timeTracking_notAllowedToBookForSelf',
        message: 'timeRecordings:errors.notAllowedOwn',
      });
    }
  } else if (nodeId) {
    if (!isGranted('FLOW_NODE_PROJECT_TIME_TRACKING_MANAGE_BOOKABLE_OTHERS', nodeId)) {
      errors.push({
        field: 'timeTracking_notAllowedToBookForOthers',
        message: 'timeRecordings:errors.notAllowedOthers',
      });
    }
  } else {
    errors.push({
      field: 'timeTracking_notAllowedToBookForOthersWithoutWorkPackage',
      message: 'timeRecordings:errors.notAllowedOthersWithoutWorkPackage',
    });
  }
}

export function validateWorkPackageFrozen(
  isGranted: IsGranted,
  superUserActive: boolean,
  errors: TimeTrackingErrors,
  {ancestors, workPackage}: NodeAncestryWithWp,
  startEnd: BillingStartEnd,
): void {
  if (canManageNonBookable(isGranted, superUserActive, ancestors[0].id)) {
    return;
  }
  const ref: {frozenUntil?: string} = {};
  if (isFrozen(workPackage, startEnd, ref)) {
    errors.push({
      field: 'timeTracking_notAllowedWorkPackageIsFrozen',
      message: {
        i18nKey: 'timeRecordings:errors.notAllowedWorkPackageIsFrozen',
        values: {endDate: fromIsoFormat(asDateStr(ref.frozenUntil!)).format('DD.MM.YYYY')},
      },
    });
  }
}

export function validateUsePriceCategory(
  errors: TimeTrackingErrors,
  {workPackage}: NodeAncestryWithWp,
  priceCategoryId: Uuid | null,
): void {
  if (workPackage.usePriceCategoryPerTimeTracking && !priceCategoryId) {
    errors.push({
      field: 'timeTracking_notAllowedMissingPriceCategory',
      message: 'timeRecordings:errors.notAllowedMissingPriceCategory',
    });
  }
}

export function validateStartBeforeEnd(errors: TimeTrackingErrors, {billingStart, billingEnd}: BillingStartEnd): void {
  if (billingStart && billingEnd && billingStart > billingEnd) {
    errors.push({
      field: 'timeTracking_endBeforeStart',
      message: 'timeRecordings:errors.endBeforeStart',
    });
  }
}
