import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {getAutoChainLogicalPredecessorSelector} from '@octaved/planning/src/Selectors/LogicalDependencySelector';
import {unixToIsoDate} from '@octaved/users/src/Culture/DateFormatFunctions';
import {createInheritingReducer} from '@octaved/utilities/src/Search/SearchReducers';
import {FLOW_CREATE_TASK_REQUEST} from '../ActionTypes';
import {TaskCreateEvent} from '../Events';
import {dateInRange, reduceMatchesDateRange} from '../NodeSearchReducers/DateSearches';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {getAllAffectedUnitIdsForResponsibleNodeSelector} from '../Selectors/ResponsibleNodeSelectors';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<TaskCreateEvent>(FLOW_CREATE_TASK_REQUEST, {
  assignedAnyProjectRole: ({parentNodeId, task}) => ({
    results: createInheritingReducer('assignedAnyProjectRole', parentNodeId, task.id),
  }),
  assignedProjectRoleId: ({parentNodeId, task}) => ({
    results: createInheritingReducer('assignedProjectRoleId', parentNodeId, task.id),
  }),
  assignedProjectRoleType: ({parentNodeId, task}) => ({
    results: createInheritingReducer('assignedProjectRoleType', parentNodeId, task.id),
  }),
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored', //tasks can't be favorites
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: 'ignored',
  hasTimeBasedPredecessor: 'ignored',
  isArchived: 'ignored',
  labelId: ({task}) => ({
    results: (state) => {
      let newState = state;
      task.labels.forEach((labelId) => {
        newState = reduceAddOptional(newState, task.id, 'labelId', labelId);
      });
      return newState;
    },
  }),
  mrIsActive: 'ignored',
  name: 'ignored', //cleared post-save
  nodeNotPlanned: ({task}) => ({
    results: (s) => reduceAddOptional(s, task.id, 'nodeNotPlanned'),
  }),
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: ({task}) => ({
    results: (s) => reduceAddOptional(s, task.id, 'nodeType', EnumFlowNodeType.VALUE_TASK),
  }),
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: 'ignored',
  responsibleByAny: ({task}) => ({
    results: (s) =>
      task.responsibleGroups.length || task.responsibleUsers.length
        ? reduceAddOptional(s, task.id, 'responsibleByAny')
        : s,
  }),
  responsibleUnitId: ({task}, fullState) => ({
    results: (state) => {
      let newState = state;
      getAllAffectedUnitIdsForResponsibleNodeSelector(fullState)(task).forEach((unitId) => {
        newState = reduceAddOptional(newState, task.id, 'responsibleUnitId', unitId);
      });
      return newState;
    },
  }),
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: ({task}, fullState) => {
    const autoChainPredecessor = getAutoChainLogicalPredecessorSelector(fullState)(task.id);
    if (autoChainPredecessor && autoChainPredecessor.status === EnumFlowTaskStatus.VALUE_OPEN) {
      return {
        results: (s) => reduceAddOptional(s, task.id, 'taskBlockedByDependency'),
      };
    } else {
      //All other cases like personal tasks,
      return 'ignored';
    }
  },
  taskCompletedInDateRange: ({task}) => ({
    results: (s) =>
      task.completedOn
        ? reduceMatchesDateRange(s, task.id, 'taskCompletedInDateRange', dateInRange(unixToIsoDate(task.completedOn)))
        : s,
  }),
  taskStatus: ({task}) => ({
    results: (s) => reduceAddOptional(s, task.id, 'taskStatus', task.status),
  }),
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
