import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {groupInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/GroupTabs';
import {projectInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/ProjectTabs';
import {workPackageInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/WorkPackageTabs';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import NodeFooter from '../../../Drawer/StandardInspector/NodeFooter';
import {TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import ProjectControllingInspectorSubMenu from '../../../Pages/Projects/Pages/ControlCommon/ProjectControllingInspectorSubMenu';
import {dialogRouteObjects} from '../Dialogs';
import {createOfferRoute} from '../Routes';
import {
  advancedRouteObject,
  controllingRouteObject,
  generalRouteObject,
  guestUserNewInspectorRouteObject,
  offerSumsRouteObject,
  planningRouteObject,
  responsibilityRouteObject,
  settingsRouteObject,
  timeAndMoneyRouteObject,
} from './Inspectors';
import SpecialQuoteInspector from './SpecialQuoteInspector';
import {getWrappedStandardInspectorRoutes} from './StandardInspector';

const inspectorTabConfig: TabRecords = {
  [EnumFlowNodeType.VALUE_PROJECT]: projectInspectorTabs,
  [EnumFlowNodeType.VALUE_GROUP]: groupInspectorTabs,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: workPackageInspectorTabs,
};

const specialQuoteRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <SpecialQuoteInspector />,
  path: createOfferRoute,
});

export const createOfferInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    specialQuoteRouteObject(),
    generalRouteObject(),
    responsibilityRouteObject(),
    planningRouteObject(),
    timeAndMoneyRouteObject(),
    guestUserNewInspectorRouteObject(),
    settingsRouteObject(),
    advancedRouteObject(),
    offerSumsRouteObject(),
    controllingRouteObject(),
    {
      element: <Navigate to={createOfferRoute} replace />,
      path: '*',
    },
  ],
  standardInspectorProps: {
    actionMenu: ProjectControllingInspectorSubMenu,
    footerElement: <NodeFooter />,
    tabConfig: inspectorTabConfig,
  },
});
