import {
  AnyCondition,
  CombinersShape,
  isAndCondition,
  isCustomCombiner,
  isFixResult,
  isNotCondition,
  isOrCondition,
  isTransformer,
} from '@octaved/utilities/src/Condition/Types';

export function flattenCondition<C, T = string, Combiners extends CombinersShape<T> = never>(
  condition: AnyCondition<C, T, Combiners>,
): C[] {
  const all: C[] = [];

  if (isNotCondition(condition)) {
    all.push(...flattenCondition(condition.not));
  } else if (isAndCondition(condition)) {
    condition.and.forEach((cond) => {
      all.push(...flattenCondition(cond));
    });
  } else if (isOrCondition(condition)) {
    condition.or.forEach((cond) => {
      all.push(...flattenCondition(cond));
    });
  } else if (isTransformer(condition)) {
    all.push(...flattenCondition(condition.transform[0]));
  } else if (isCustomCombiner(condition)) {
    Object.values(condition.combine.sources).forEach((cond) => {
      all.push(...flattenCondition(cond));
    });
  } else if (isFixResult(condition)) {
    // nothing to do, this is not a condition
  } else {
    all.push(condition);
  }

  return all;
}
