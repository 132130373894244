import {bulkPatchAccessibleTimeTrackings, getAllAccessibleTimeTrackings} from '@octaved/flow-api';
import {ObjectSnapshot, useObjectSnapshot} from '@octaved/hooks';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createFlatTimestampReducer,
  INVALIDATED,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {MergeStates} from '@octaved/store/src/MergeStates';
import {optimisticUpdate, setToReducerMap} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {useLoadedGroups} from '@octaved/users/src/Modules/Group';
import {useLoadedUserNames} from '@octaved/users/src/Modules/OrgUser';
import {orgUserNameSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {castFilter} from '@octaved/utilities';
import {ObjectContains} from '@octaved/validation';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AccessibleTimeTrackings, AccessibleTimeTrackingsEntry} from '../EntityInterfaces/AccessibleTimeTrackings';
import {
  FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_FAILURE,
  FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_REQUEST,
  FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_SUCCESS,
  FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_FAILURE,
  FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_REQUEST,
  FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_SUCCESS,
} from './ActionTypes';
import {
  accessibleTimeTrackingAllUnitIdsAreLoadingSelector,
  accessibleTimeTrackingAllUnitIdsSelector,
  accessibleTimeTrackingsSelector,
  accessibleTimeTrackingsStatesStateSelector,
} from './Selectors/AccessibleTImeTrackingsSelectors';
import {FlowState} from './State';

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_SUCCESS,
  (_state: AccessibleTimeTrackings, {response}: {response: AccessibleTimeTrackings}): AccessibleTimeTrackings => {
    return response;
  },
);

function myMerge(state: AccessibleTimeTrackings, patch: AccessibleTimeTrackings): AccessibleTimeTrackings {
  const newState: AccessibleTimeTrackings = {...state};
  Object.entries(patch).forEach(([userId, units]) => {
    if (units && units.length) {
      newState[userId] = [...units];
    } else {
      delete newState[userId];
    }
  });
  return newState;
}

setToReducerMap<AccessibleTimeTrackings, ServerRequestAction<{data: AccessibleTimeTrackings}>>(
  optimisticUpdate,
  reducerMap,
  FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_REQUEST,
  FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_FAILURE,
  FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_SUCCESS,
  null,
  undefined,
  myMerge as MergeStates<AccessibleTimeTrackings>,
);
export const accessibleTimeTrackingsReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_SUCCESS, createFlatTimestampReducer(LOADED));
addMultiToReducerMap(
  stateReducerMap,
  ['flow.AccessibleTimeTrackingsChangedEvent'],
  createFlatTimestampReducer(INVALIDATED),
);
export const accessibleTimeTrackingsStateReducer = ReduceFromMap(stateReducerMap);

function loadAllAccessibleTimeTrackings(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = accessibleTimeTrackingsStatesStateSelector(getState());
    if (isOutdated(state)) {
      dispatch({
        [CALL_API]: {
          endpoint: getAllAccessibleTimeTrackings,
          types: {
            failureType: FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_FAILURE,
            requestType: FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_REQUEST,
            successType: FLOW_LOAD_ACCESSIBLE_TIME_TRACKINGS_SUCCESS,
          },
        },
      });
    }
  };
}

function patchAccessibleTimeTrackings(data: AccessibleTimeTrackings): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    const current = accessibleTimeTrackingsSelector(getState());
    if (!ObjectContains(current, data)) {
      dispatch({
        [CALL_API]: {
          endpoint: bulkPatchAccessibleTimeTrackings,
          method: 'patch',
          options: {data},
          types: {
            failureType: FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_FAILURE,
            requestType: FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_REQUEST,
            successType: FLOW_PATCH_ACCESSIBLE_TIME_TRACKINGS_SUCCESS,
          },
        },
      });
    }
    return true;
  };
}

export function useAllAccessibleTimeTrackings(): {
  accessibleTimeTrackings: AccessibleTimeTrackings;
  isLoading: boolean;
} {
  const dispatch = useDispatch();
  const state = useSelector(accessibleTimeTrackingsStatesStateSelector);
  useEffect(() => {
    // noinspection BadExpressionStatementJS
    void state; //reload dependency
    dispatch(loadAllAccessibleTimeTrackings());
  }, [dispatch, state]);

  //load the users:
  const {groupIds, userIds} = useSelector(accessibleTimeTrackingAllUnitIdsSelector);
  useLoadedGroups(groupIds);
  useLoadedUserNames(userIds);
  const namesLoading = useSelector(accessibleTimeTrackingAllUnitIdsAreLoadingSelector);

  return {
    accessibleTimeTrackings: useSelector(accessibleTimeTrackingsSelector),
    isLoading: !isLoaded(state) || namesLoading,
  };
}

export type AccessibleTimeTrackingsSnap = ObjectSnapshot<AccessibleTimeTrackings, AccessibleTimeTrackings>;

export function useEditAccessibleTimeTrackings(): {
  hasChanges: boolean;
  save: () => Promise<boolean>;
  search: string;
  setSearch: (s: string) => void;
  users: AccessibleTimeTrackingsEntry[];
  patch: AccessibleTimeTrackingsSnap['patch'];
  refs: AccessibleTimeTrackingsSnap['refs'];
  snapshot: AccessibleTimeTrackingsSnap['snapshot'];
} {
  const [search, setSearch] = useState('');
  const {isLoading, accessibleTimeTrackings} = useAllAccessibleTimeTrackings();
  const loadedOnce = useRef(false);
  const {hasChanges, reset, patch, snapshot, refs} = useObjectSnapshot(() => accessibleTimeTrackings);
  useEffect(() => {
    if (!isLoading && !loadedOnce.current) {
      reset(true);
      loadedOnce.current = true;
    }
  }, [isLoading, reset]);
  const getUserDisplayName = useSelector(orgUserNameSelector);
  const sortedEntries = useMemo(() => {
    const entries = castFilter<AccessibleTimeTrackingsEntry>(
      Object.entries(snapshot),
      ([userId, units]) => !!units && (!search || getUserDisplayName(userId).toLocaleLowerCase().includes(search)),
    );
    entries.sort((a, b) => getUserDisplayName(a[0]).localeCompare(getUserDisplayName(b[0])));
    return entries;
  }, [getUserDisplayName, search, snapshot]);

  const dispatch = useDispatch();
  const save = useCallback(async (): Promise<boolean> => {
    const data: AccessibleTimeTrackings = {};
    Object.entries(refs.changeSet.current).forEach(([userId, units]) => {
      //Map the "null"s to empty arrays - the backend will remove those entries:
      data[userId] = (units || []) as SlimUnit[];
    });
    if (dispatch(patchAccessibleTimeTrackings(data))) {
      reset();
      return true;
    }
    return false;
  }, [dispatch, refs.changeSet, reset]);

  return {
    hasChanges,
    patch,
    refs,
    save,
    search,
    setSearch,
    snapshot,
    users: sortedEntries,
  };
}
