import {customerEntitiesSelector, formatCustomer} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {Building} from 'lucide-react';
import {useSelector} from 'react-redux';
import {BaseDropdownItem} from '../Components/BaseDropdown';
import MultiDropdown from '../Components/MultiDropdown';
import {BillingQuickFilterConfig, NodeQuickFilterConfig, QuickFilterConfig} from '../Config';

const customersQuickFilterConfig = {
  access: 'FLOW_GLOBAL_CUSTOMERS_READ',
  defaultValue: [],
  Filter: ({setValue, value, isActive}) => {
    const customers = useSelector(customerEntitiesSelector);
    const items: BaseDropdownItem<Uuid, string>[] = [];
    Object.values(customers).forEach((customer) => {
      if (customer && !customer.isLocked) {
        items.push({
          text: formatCustomer(customer),
          value: customer.id,
        });
      }
    });
    items.sort((a, b) => a.text.localeCompare(b.text));
    return (
      <MultiDropdown
        actionTriggerIcon={Building}
        items={items}
        label={'nodeTable:quickFilter.customers.label'}
        searchable
        setValue={setValue}
        value={value}
        isActive={isActive}
      />
    );
  },
  ident: 'customers',
} as const satisfies QuickFilterConfig<readonly Uuid[]>;

export const billingCustomersQuickFilterConfig = {
  ...customersQuickFilterConfig,
  toSearchQuery: (ids) => (ids.length ? {or: ids.map((id) => ['customerId', id])} : null),
} as const satisfies BillingQuickFilterConfig<readonly Uuid[]>;

export const nodeCustomersQuickFilterConfig = {
  ...customersQuickFilterConfig,
  toSearchQuery: (ids) => (ids.length ? withDescendants({or: ids.map((id) => ['customerId', id])}, true) : null),
} as const satisfies NodeQuickFilterConfig<readonly Uuid[]>;
