import {createContext, PropsWithChildren, ReactElement, useContext} from 'react';

const context = createContext(2);

export function useCloseDialogRoute(): string {
  const level = useContext(context);
  return Array(level).fill('..').join('/');
}

interface Props {
  level: number;
}

export default function CloseDialogRouteLevelContext({children, level}: PropsWithChildren<Props>): ReactElement {
  return <context.Provider value={level}>{children}</context.Provider>;
}
