import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface PlaceholderProps {
  text: string;
}

export default function Placeholder({text}: PlaceholderProps): ReactElement {
  const {t} = useTranslation();
  return <div className={'italic text-slate-500'}>{t(text)}</div>;
}
