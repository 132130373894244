import {Uuid} from '@octaved/typescript/src/lib';
import {ProjectTreeOptions} from './ProjectTreeInterfaces';

export interface ProjectTreeApi {
  greyedOutNodeIds: ReadonlyArray<Uuid>;
  groupIds: ReadonlyArray<Uuid>;
  projectIds: ReadonlyArray<Uuid>;
  workPackageIds: ReadonlyArray<Uuid>;
}

export const defaultProjectTreeApi: ProjectTreeApi = {
  greyedOutNodeIds: [],
  groupIds: [],
  projectIds: [],
  workPackageIds: [],
};

const cache = new WeakMap<(api: ProjectTreeApi) => void, string>();

export function useSetProjectTreeApi(
  {onProjectTreeApiChange, greyedOutNodeIds}: ProjectTreeOptions,
  projectIds: ReadonlySet<Uuid>,
  groupIds: ReadonlySet<Uuid>,
  workPackageIds: ReadonlySet<Uuid>,
): void {
  if (onProjectTreeApiChange) {
    const newApi = {
      greyedOutNodeIds: greyedOutNodeIds ? [...greyedOutNodeIds].sort() : [],
      groupIds: [...groupIds].sort(),
      projectIds: [...projectIds].sort(),
      workPackageIds: [...workPackageIds].sort(),
    };
    const newApiString = JSON.stringify(newApi);
    const oldApiString = cache.get(onProjectTreeApiChange);
    if (oldApiString !== newApiString) {
      cache.set(onProjectTreeApiChange, newApiString);
      onProjectTreeApiChange(newApi);
    }
  }
}
