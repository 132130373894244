import {useProjectPatch} from '@octaved/flow/src/Modules/Projects';
import {getProjectSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import ShowMovePlanningButton from '@octaved/planning/src/Dialogs/MovePlanning/ShowMovePlanningButton';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import NodeDueDateSection from '../../../Components/Fields/Planning/Section/NodeDueDateSection';
import NodeSubProjectsDueDatesSection from '../../../Components/Fields/Planning/Section/NodeSubProjectsDueDatesSection';
import ResponsibleUnits from '../../../Components/Fields/Responsible/ResponsibleUnits';
import Section from '../../../Components/Fields/Section';
import InspectorViewFrame from '../../InspectorViewFrame';
import {ProjectDetailsProps} from './General';

export default function SpecialPlanning({node: initialNode}: ProjectDetailsProps): ReactElement {
  const node = useSelector(getProjectSelector)(initialNode.id) || initialNode;
  const patch = useProjectPatch(node.id);
  const readonly = !useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) || node.isArchived;

  return (
    <InspectorViewFrame
      footer={
        <div className={'flex justify-center gap-x-2'}>
          <ShowMovePlanningButton node={node} />
        </div>
      }
    >
      <NodeDueDateSection node={node} readonly={readonly} onChangeDueDate={(dueDate) => patch({dueDate})} />
      <NodeSubProjectsDueDatesSection nodeId={node.id} />

      <ResponsibleUnits node={node} frame={Section} />
    </InspectorViewFrame>
  );
}
