import classNames from 'classnames';
import * as React from 'react';
import {CSSProperties, ReactElement, RefObject, useMemo} from 'react';

interface Props extends React.HTMLProps<HTMLDivElement> {
  alignItems?: CSSProperties['alignItems'];
  flexDirection?: CSSProperties['flexDirection'];
  flexWrap?: CSSProperties['flexWrap'];
  forwardedRef?: RefObject<HTMLDivElement>;
  fullHeight?: boolean;
  fullWidth?: boolean;
  grow?: boolean;
  inline?: boolean;
  justifyContent?: CSSProperties['justifyContent'];
  style?: CSSProperties;
}

export default React.memo(function Flex({
  alignItems = 'center',
  children,
  flexDirection = 'row',
  flexWrap = 'nowrap',
  forwardedRef = undefined,
  fullHeight = false,
  fullWidth = false,
  grow,
  inline = false,
  justifyContent = 'flex-start',
  style = {},
  ...htmlDivElementProps
}: Props): ReactElement {
  const localStyles = useMemo<CSSProperties>(
    () => ({
      alignItems,
      flexDirection,
      flexWrap,
      justifyContent,
      ...style,
    }),
    [style, alignItems, flexDirection, flexWrap, justifyContent],
  );

  return (
    <div
      {...htmlDivElementProps}
      ref={forwardedRef}
      style={localStyles}
      className={classNames('flexContainer', {fullHeight, fullWidth, inline, grow}, htmlDivElementProps.className)}
    >
      {children}

      {/*language=SCSS*/}
      <style jsx>{`
        .flexContainer {
          display: flex;
        }

        .inline {
          display: inline-flex;
        }

        .fullWidth {
          width: 100%;
        }

        .fullHeight {
          height: 100%;
        }

        .grow {
          flex-grow: 1;
        }
      `}</style>
    </div>
  );
});
