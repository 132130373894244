import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Group, Pid, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {TaskSection} from '@octaved/flow/src/EntityInterfaces/TaskSection';
import {isProject} from '@octaved/flow/src/Node/NodeIdentifiers';

export const duplicateNodeToken = (node: Pid | SubWorkPackage): string => {
  if (isProject(node) && node.isTemplate) {
    return 'components:contextMenu.duplicateTemplate';
  }
  switch (node.nodeType) {
    case EnumFlowNodeType.VALUE_GROUP:
      return 'components:contextMenu.duplicateGroup';
    case EnumFlowNodeType.VALUE_PROJECT:
      return 'components:contextMenu.duplicateProject';
    case EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE:
      return 'components:contextMenu.duplicateSubWorkPackage';
    case EnumFlowNodeType.VALUE_WORK_PACKAGE:
      return 'components:contextMenu.duplicateWorkPackage';
  }
};

export const deleteNodeToken = (node: Pid | SubWorkPackage | Task | TaskSection): string => {
  if (isProject(node) && node.isTemplate) {
    return 'components:contextMenu.deleteTemplate';
  }
  switch (node.nodeType) {
    case EnumFlowNodeType.VALUE_GROUP:
      return 'components:contextMenu.deleteGroup';
    case EnumFlowNodeType.VALUE_PROJECT:
      return 'components:contextMenu.deleteProject';
    case EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE:
      return 'components:contextMenu.deleteSubWorkPackage';
    case EnumFlowNodeType.VALUE_WORK_PACKAGE:
      return 'components:contextMenu.deleteWorkPackage';
    case EnumFlowNodeType.VALUE_TASK:
      return 'components:contextMenu.deleteTask';
    case EnumFlowNodeType.VALUE_TASK_SECTION:
      return 'components:contextMenu.deleteTaskSection';
  }
};

export const newSameNodeSubMenu = (node: Group | WorkPackage | SubWorkPackage | Task): string => {
  switch (node.nodeType) {
    case EnumFlowNodeType.VALUE_GROUP:
      return 'components:contextMenu.newGroupSubMenu';
    case EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE:
      return 'components:contextMenu.newSubWorkPackageSubMenu';
    case EnumFlowNodeType.VALUE_WORK_PACKAGE:
      return 'components:contextMenu.newWorkPackageSubMenu';
    case EnumFlowNodeType.VALUE_TASK:
      return 'components:contextMenu.newTaskSubMenu';
  }
};
