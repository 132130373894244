import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import BaseFlowAvatar from '@octaved/flow/src/Components/Avatar/BaseFlowAvatar';
import FlowUnitSearch, {FlowUnitSearchProps} from '@octaved/flow/src/Components/Unit/FlowUnitSearch';
import {RoleAssignmentOnNode} from '@octaved/flow/src/EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {useExcludedUnitIdsForAdd} from '@octaved/flow/src/Modules/Hooks/NodeRoleAssignments';
import {RoleDropdownOption} from '@octaved/flow/src/Modules/Selectors/RoleSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn, IconButton, tableGridCellVariant, Tooltip} from '@octaved/ui';
import {FullUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {ExtendedUnitType, SimpleUnitType} from '@octaved/users/src/UnitType';
import {X} from 'lucide-react';
import {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SimpleRileAssignmentAddUnitCB} from './Hook';
import RoleDropdown from './RoleDropdown';

const roleDropdownPlaceholder: Record<EnumFlowNodeRoleType, string> = {
  [EnumFlowNodeRoleType.VALUE_PROJECT]: 'roles:addUnitSelectRole',
  [EnumFlowNodeRoleType.VALUE_PERMISSION]: 'roles:addUnitSelectRight',
};

interface Props<RAN extends RoleAssignmentOnNode> {
  addUnit: SimpleRileAssignmentAddUnitCB;
  assignments: RAN[];
  availableRoles: RoleDropdownOption[];
  customerId: Uuid | null;
  fluid?: boolean;
  isGuestRole: boolean;
  roleType: EnumFlowNodeRoleType;
  takenRolesPerUnit: Map<Uuid, Uuid[]>;
}

export default function AddUnit<RAN extends RoleAssignmentOnNode>({
  addUnit,
  assignments,
  availableRoles,
  customerId,
  fluid,
  isGuestRole,
  roleType,
  takenRolesPerUnit,
}: Props<RAN>): ReactElement | null {
  const {t} = useTranslation();
  const excludedUnits = useExcludedUnitIdsForAdd(roleType, assignments);
  const [unit, setUnit] = useState<null | FullUnit>(null);
  const [roleId, setRoleId] = useState<null | Uuid>(null);

  const props = useMemo(
    (): Partial<FlowUnitSearchProps> =>
      isGuestRole
        ? {
            filterGuestUserByCustomerId: customerId!,
            unitTypes: [ExtendedUnitType.customerUser, ExtendedUnitType.generalGuestUser],
          }
        : {
            unitTypes: [ExtendedUnitType.apiUser, ExtendedUnitType.internalUserGroup, ExtendedUnitType.regularUser],
          },
    [customerId, isGuestRole],
  );

  if (isGuestRole && !customerId) {
    return null; //guest roles may only be assigned to customer nodes
  }

  return (
    <>
      <div className={cn(tableGridCellVariant({position: 'left', isFooter: true}))}>
        {!unit && (
          <FlowUnitSearch
            {...(props as FlowUnitSearchProps)}
            onSelect={(unitId: Uuid, unitType: SimpleUnitType, unitName: string) =>
              setUnit({unitId, unitType, unitName})
            }
            fluid={fluid}
            placeholder={'roles:addUnitSearchPlaceholder'}
            excludedUnits={excludedUnits}
          />
        )}
        {unit && (
          <div className={'flex items-center gap-x-2 overflow-hidden'}>
            <BaseFlowAvatar id={unit.unitId} type={unit.unitType} className={'my-0'} />
            <div className={'truncate'}>{unit.unitName}</div>
          </div>
        )}
      </div>

      <Tooltip disabled={!!unit} toolTipTranslation={'roles:addUnitSelectRoleTooltip'}>
        <div className={cn(tableGridCellVariant({isFooter: true}))}>
          <RoleDropdown
            className={cn(!!unit && 'no-weak-placeholder')}
            availableRoles={availableRoles}
            disabled={!unit}
            excludedRoles={takenRolesPerUnit.get(unit?.unitId || '') || []}
            onChange={async (roleId) => {
              if (unit) {
                await addUnit(unit.unitId, unit.unitType, unit.unitName, roleId);
                setUnit(null);
                setRoleId(null);
              }
            }}
            placeholder={t(roleDropdownPlaceholder[roleType])}
            roleId={roleId || undefined}
          />
        </div>
      </Tooltip>
      {unit ? (
        <div className={'pt-1'}>
          <IconButton
            variant={'ghost'}
            size={'xs'}
            icon={<X />}
            isDisabled={!unit}
            onClick={() => {
              setUnit(null);
              setRoleId(null);
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
