import {PropsWithChildren, ReactElement, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {KanbanBoard} from '../../EntityInterfaces/KanbanBoards';
import {getKanbanBoards} from '../../Modules/KanbanBoard';
import {kanbanBoardEntitiesSelector} from '../../Modules/Selectors/KanbanBoardSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IKanbanBoardInspectorContext extends BaseInspectorContext {
  selectedEntity: KanbanBoard;
  selectedType: 'kanbanBoard';
}

function isKanbanBoardInspectorContext(context: InspectorContext | null): context is IKanbanBoardInspectorContext {
  return context?.selectedType === 'kanbanBoard';
}

export function useKanbanBoardInspectorContext(): IKanbanBoardInspectorContext {
  const context = useInspectorContext();
  if (!isKanbanBoardInspectorContext(context)) {
    throw new Error('is not kanban board inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IKanbanBoardInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector(kanbanBoardEntitiesSelector)[baseParams!.selectedId];

  return useMemo<IKanbanBoardInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'kanbanBoard',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function KanbanBoardInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getKanbanBoards());
  }, [dispatch]);

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
