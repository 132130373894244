import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {FlowState} from '../../State';
import {ReportingUiState} from '../../UiPages/Reporting';

function reporting<R>(selector: (state: FlowState['ui']['pages']['reporting']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.reporting);
}

export const reportingDemoModeSelector = reporting((state) => state.demoMode);
export const chargeableQuotaCompareSelector = reporting((state) => state.chargeableQuota.compare);
export const chargeableQuotaChartViewSelector = reporting((state) => state.chargeableQuota.chartView);
export const chargeableQuotaBreakdownIndexSelector = reporting((state) => state.chargeableQuota.breakdownIndex);
export const chargeableQuotaFilterSelector = reporting((state) => state.chargeableQuota.filter);
export const chargeableQuotaCompareFilterSelector = reporting((state) => state.chargeableQuota.compareFilter);
export const bookedTimesCompareSelector = reporting((state) => state.bookedTimes.compare);
export const bookedTimesCompareFilterSelector = reporting((state) => state.bookedTimes.compareFilter);
export const bookedTimesFilterSelector = reporting((state) => state.bookedTimes.filter);
export const bookedTimesShowDaysSelector = reporting((state) => state.bookedTimes.showDaysForBookingTimes);
export const billableTypesCompareSelector = reporting((state) => state.billingTypes.compare);
export const billableTypesBreakdownSelector = reporting((state) => state.billingTypes.breakdown);
export const billableTypesFilterSelector = reporting((state) => state.billingTypes.filter);
export const billableTypesCompareFilterSelector = reporting((state) => state.billingTypes.compareFilter);
export const billableTypesShowInternalNoChargeSelector = reporting((state) => state.billingTypes.showInternalNoCharge);
export const noChargeCompareSelector = reporting((state) => state.noCharge.compare);
export const noChargeFilterSelector = reporting((state) => state.noCharge.filter);
export const noChargeCompareFilterSelector = reporting((state) => state.noCharge.compareFilter);
export const noChargeBreakdownSelector = reporting((state) => state.noCharge.breakdown);
export const noChargeShowInternalNoChargeSelector = reporting((state) => state.noCharge.showInternalNoCharge);
export const roundingGainCompareSelector = reporting((state) => state.roundingGain.compare);
export const roundingGainChartViewSelector = reporting((state) => state.roundingGain.chartView);
export const roundingGainBreakdownIndexSelector = reporting((state) => state.roundingGain.breakdownIndex);
export const roundingGainFilterSelector = reporting((state) => state.roundingGain.filter);
export const roundingGainCompareFilterSelector = reporting((state) => state.roundingGain.compareFilter);

export type StdReportingFilterPage = keyof Pick<
  ReportingUiState,
  'chargeableQuota' | 'bookedTimes' | 'billingTypes' | 'noCharge' | 'roundingGain'
>;

const reportingUiStateSelector = (state: FlowState): ReportingUiState => state.ui.pages.reporting;
const reportingPageStandardFilterSelector = createSelector(
  reportingUiStateSelector,
  (reportingUiState) => (page: StdReportingFilterPage) => {
    return reportingUiState[page];
  },
);

export const reportingChartViewSelector = createSelector(reportingPageStandardFilterSelector, (stateSelector) =>
  memoize((page: StdReportingFilterPage) => stateSelector(page).chartView),
);

export const reportingActiveCompareSelector = createSelector(reportingPageStandardFilterSelector, (stateSelector) =>
  memoize((page: StdReportingFilterPage) => stateSelector(page).compare),
);

export const reportingCompareStartSelector = createSelector(reportingPageStandardFilterSelector, (stateSelector) =>
  memoize((page: StdReportingFilterPage) => stateSelector(page).compareFilter.start),
);

export const reportingProjectOuPageFilterSelector = createSelector(
  reportingPageStandardFilterSelector,
  (stateSelector) => memoize((page: StdReportingFilterPage) => stateSelector(page).filter.projectAndOUFilter),
);

export const reportingCompareProjectOuPageFilterSelector = createSelector(
  reportingPageStandardFilterSelector,
  (stateSelector) => memoize((page: StdReportingFilterPage) => stateSelector(page).compareFilter.projectAndOUFilter),
);

export const reportingCustomersFilterSelector = createSelector(reportingPageStandardFilterSelector, (stateSelector) =>
  memoize((page: StdReportingFilterPage) => stateSelector(page).filter.customersFilter),
);

export const reportingIncludeArchivedFilterSelector = createSelector(
  reportingPageStandardFilterSelector,
  (stateSelector) => memoize((page: StdReportingFilterPage) => stateSelector(page).filter.includeArchived),
);

export const reportingCompareCustomersFilterSelector = createSelector(
  reportingPageStandardFilterSelector,
  (stateSelector) => memoize((page: StdReportingFilterPage) => stateSelector(page).compareFilter.customersFilter),
);

export const reportingCompareIncludeArchivedFilterSelector = createSelector(
  reportingPageStandardFilterSelector,
  (stateSelector) => memoize((page: StdReportingFilterPage) => stateSelector(page).compareFilter.includeArchived),
);
