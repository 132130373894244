import {useInspectorId} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

export function useSelectProjectId(): (selectedId: Uuid | null) => void {
  const navigate = useNavigate();
  const inspectorId = useInspectorId();

  return useCallback(
    (selectedId) => {
      if (inspectorId !== selectedId) {
        let target = '';
        if (selectedId) {
          target = selectedId;
        }
        navigate(target);
      } else {
        navigate('.');
      }
    },
    [inspectorId, navigate],
  );
}
