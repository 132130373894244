import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {toChildren} from '@octaved/node-search/src/Factories/Tree';
import type {FieldCondition} from '@octaved/store/src/Notifications';
import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Group, Pid, WorkPackage} from '../EntityInterfaces/Pid';
import {useLoadedNodes} from '../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../Modules/Hooks/NodeSearch';
import {removeErrorForField} from '../Modules/Ui';

export function useSiblings(parent: Pid): Array<Group | WorkPackage> {
  const {nodeIds: siblingPidIds} = useCombinedNodeSearch({
    and: [
      toChildren(parent.id),
      {
        or: [
          //projects are not sorted by the sortOrder
          ['nodeType', EnumFlowNodeType.VALUE_GROUP],
          ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        ],
      },
    ],
  });
  const {nodes: siblingPids} = useLoadedNodes<Group | WorkPackage>(siblingPidIds);
  return useMemo(() => [...siblingPids].sort((a, b) => a.sortOrder - b.sortOrder), [siblingPids]);
}

export function useClearErrorFields(id: Uuid, getErrorFields: (id: Uuid) => FieldCondition[]): FieldCondition[] {
  const fields = useMemo(() => getErrorFields(id), [id, getErrorFields]);
  const dispatch = useDispatch();
  useEffect(() => {
    //Callback when the component closes, remove any error which is involved in the form
    return () => {
      dispatch(removeErrorForField(fields));
    };
  }, [dispatch, fields]);
  return fields;
}
