import {Pencil} from 'lucide-react';

import {IconButton, TextLink, cn} from '@octaved/ui';
import {ReactElement, ReactNode, useState} from 'react';
import {SketchPicker} from 'react-color';
import {useTranslation} from 'react-i18next';

const LabelColors = [
  '#009966',
  '#8fbc8f',
  '#3cb371',
  '#00b140',
  '#013220',
  '#6699cc',
  '#0000ff',
  '#e6e6fa',
  '#9400d3',
  '#330066',
  '#808080',
  '#36454f',
  '#f7e7ce',
  '#c21e56',
  '#cc338b',
  '#dc143c',
  '#ff0000',
  '#cd5b45',
  '#eee600',
  '#ed9121',
  '#c39953',
];

interface TWColorPickerProps {
  additionalLinks?: ReactNode;
  color: string | null;
  isEditing?: boolean;
  palette?: string[];
  readonly?: boolean;
  setColor: (color: string) => void;
}

export function TWColorPicker({
  additionalLinks,
  color,
  isEditing,
  palette = LabelColors,
  readonly = false,
  setColor,
}: TWColorPickerProps): ReactElement {
  const {t} = useTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [edit, setEdit] = useState(Boolean(isEditing));
  const canEdit = edit && !readonly;
  return (
    <div className={'flex flex-col gap-y-2'}>
      <div className={'flex items-center gap-x-3'}>
        <div
          className={cn(
            'size-7 cursor-pointer rounded-sm',
            !color && 'border border-slate-200',
            (readonly || !edit) && 'cursor-default',
          )}
          key={color}
          style={{backgroundColor: color ?? '#FFF'}}
        />
        {!edit && !readonly && (
          <IconButton icon={<Pencil />} onClick={() => setEdit(true)} variant={'ghost'} size={'sm'} />
        )}
      </div>

      {canEdit && palette.length > 0 && (
        <div className={'flex flex-wrap gap-2'}>
          {palette.map((code) => (
            <div
              className={'h-5 w-5 cursor-pointer rounded-sm'}
              key={code}
              style={{backgroundColor: code}}
              onClick={() => !readonly && setColor(code)}
            />
          ))}
        </div>
      )}

      {((!showColorPicker && canEdit) || additionalLinks) && (
        <div className={'flex items-center gap-x-4'}>
          {!showColorPicker && canEdit && (
            <TextLink onClick={() => setShowColorPicker(true)}>
              <span>{t('systemSettings:labels.moreColors')}</span>
            </TextLink>
          )}
          {additionalLinks}
        </div>
      )}

      {canEdit && showColorPicker && (
        <div className={'picker'}>
          <SketchPicker
            color={color ?? '#FFF'}
            disableAlpha
            onChange={({hex}) => !readonly && setColor(hex)}
            presetColors={[]}
          />
        </div>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .picker :global(.sketch-picker) {
          box-shadow: none !important;
          padding: 0 !important;
        }

        .picker :global(.sketch-picker .flexbox-fix + .flexbox-fix) {
          display: none !important;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
