import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {PropsWithChildren, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {generateSystemSettingsRoute} from '@octaved/flow/src/Routing/Routes/Configure';

interface Props {
  className: string;
}

export default function LinkToLicensePage({children, className}: PropsWithChildren<Props>): ReactElement {
  const canReadLicense = useSelector((s: FlowState) => isGrantedSelector(s)('FLOW_GLOBAL_LICENSE_READ'));
  return (
    <>
      {canReadLicense && (
        <Link className={className} to={generateSystemSettingsRoute('license')}>
          {children}
        </Link>
      )}
      {!canReadLicense && <span className={className}>{children}</span>}
    </>
  );
}
