import {FlowState} from '@octaved/flow/src/Modules/State';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {debounce} from 'lodash';

interface Options<V, C> {
  delay?: number;
  createValuesCache: () => C;
  mergeValues: (cache: C, values: V) => void;
  getValuesFromCache: (cache: C) => V;
}

/**
 * Creates a debounced version of the given redux action, which is called with an accumulated set of the first argument.
 */
export function debounceReduxAction<V, C, R extends unknown[], State = FlowState>(
  action: (ids: V, ...rest: R) => ActionDispatcher<void, State>,
  options: Options<V, C>,
): (ids: V, ...rest: R) => ActionDispatcher<void, State> {
  const cache = new Map<string, [C, R]>();

  const executor = debounce((dispatch) => {
    [...cache.entries()].forEach(([cacheKey, [set, rest]]) => {
      cache.delete(cacheKey);
      dispatch(action(options.getValuesFromCache(set), ...rest));
    });
  }, options.delay ?? 5);

  return (ids, ...rest) => {
    const cacheKey = JSON.stringify(rest);

    if (!cache.has(cacheKey)) {
      cache.set(cacheKey, [options.createValuesCache(), rest]);
    }
    const set = cache.get(cacheKey)![0];
    options.mergeValues(set, ids);

    return executor;
  };
}

/**
 * Creates a debounced version of the given redux action, which is called with an accumulated set of the first argument.
 */
export function debounceReduxIdsAction<V, R extends unknown[], State = FlowState>(
  action: (ids: ReadonlyArray<V>, ...rest: R) => ActionDispatcher<void, State>,
  delay = 5,
): (ids: ReadonlyArray<V>, ...rest: R) => ActionDispatcher<void, State> {
  return debounceReduxAction<ReadonlyArray<V>, Set<V>, R, State>(action, {
    delay,
    createValuesCache: () => new Set<V>(),
    getValuesFromCache: (set) => [...set],
    mergeValues: (set, ids) => ids.forEach((id) => set.add(id)),
  });
}
