import {Uuid} from '@octaved/typescript/src/lib';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ThunkAct} from '../../Store/Thunk';
import {rootFoldersProjectFolderSelector} from '../Selectors/RootFolderSelectors';
import {
  projectTreeTrashCheckedDeletedNodesSelector,
  projectTreeTrashExpandedNodesSelector,
} from '../Selectors/UiPages/ProjectTreeTrashSelectors';
import {FlowState} from '../State';
import {setUiState} from '../Ui';

export interface ProjectTreeTrashUiState {
  checkedDeletedNodes: Uuid[];
  expandedNodes: Record<Uuid, boolean>;
}

export const initialState: ProjectTreeTrashUiState = {
  checkedDeletedNodes: [],
  expandedNodes: {},
};

function setProjectTreeTrashUiState(projectTreeTrash: Partial<ProjectTreeTrashUiState>): ThunkAct<void> {
  return setUiState({pages: {projectTreeTrash}});
}

function resetForPageStart(): ThunkAct<void> {
  return (dispatch, getState) => {
    const rootFolderId = rootFoldersProjectFolderSelector(getState());
    dispatch(setProjectTreeTrashUiState({checkedDeletedNodes: [], expandedNodes: {[rootFolderId]: true}}));
  };
}

function toggleExpandedNode(nodeId: Uuid): ThunkAct<void> {
  return (dispatch, getState) => {
    const isOpen = projectTreeTrashExpandedNodesSelector(getState())[nodeId] ?? false;
    dispatch(setProjectTreeTrashUiState({expandedNodes: {[nodeId]: !isOpen}}));
  };
}

function toggleCheckedDeletedNode(nodeId: Uuid): ThunkAct<void> {
  return (dispatch, getState) => {
    const checkedNodeIds = projectTreeTrashCheckedDeletedNodesSelector(getState());
    const checkedDeletedNodes = checkedNodeIds.includes(nodeId)
      ? [...checkedNodeIds].filter((id) => id !== nodeId)
      : [...checkedNodeIds, nodeId];
    dispatch(setUiState({pages: {projectTreeTrash: {checkedDeletedNodes}}}));
  };
}

export function useResetForPageStart(): void {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetForPageStart());
  }, [dispatch]);
}

export function useToggleExpandedNode(nodeId: Uuid): {isOpen: boolean; toggleOpen: () => void} {
  const dispatch = useDispatch();
  const isOpen = useSelector((s: FlowState) => projectTreeTrashExpandedNodesSelector(s)[nodeId] ?? false);
  const toggleOpen = useCallback(() => dispatch(toggleExpandedNode(nodeId)), [dispatch, nodeId]);
  return {isOpen, toggleOpen};
}

export function useToggleCheckedDeletedNode(nodeId: Uuid): {isChecked: boolean; toggleChecked: () => void} {
  const dispatch = useDispatch();
  const isChecked = useSelector((s: FlowState) => projectTreeTrashCheckedDeletedNodesSelector(s).includes(nodeId));
  const toggleChecked = useCallback(() => dispatch(toggleCheckedDeletedNode(nodeId)), [dispatch, nodeId]);
  return {isChecked, toggleChecked};
}

export function useResetCheckedDeletedNodes(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(setProjectTreeTrashUiState({checkedDeletedNodes: []})), [dispatch]);
}
