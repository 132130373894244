import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {DateStr, toDateStr} from '@octaved/typescript';
import {cn, NodeIcon} from '@octaved/ui';
import {formatCollapsingDateRange, fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {workdays} from '../../../Calculations/WorkdayCalculations';
import {getMinMaxPlanningDatesSelector} from '../../../Selectors/PlanningDateSelectors';

interface PlanningNodeDueDateRowProps {
  node: WorkPackage | SubWorkPackage | Task;
  parentEndDate?: DateStr;
}

export default function PlanningNodeDueDateRow({node, parentEndDate}: PlanningNodeDueDateRowProps): ReactElement {
  const {t} = useTranslation();
  const {plannedStart, plannedEnd} = useSelector(getMinMaxPlanningDatesSelector)(node.id);
  let label = '';
  if (isTask(node)) {
    label = 'nodeDetails:field.planning.taskPlanning';
  } else if (isWorkPackage(node)) {
    label = 'nodeDetails:field.planning.parentWorkPackagePlanning';
  } else if (isSubWorkPackage(node)) {
    label = 'nodeDetails:field.planning.subWorkPackagePlanning';
  }
  const isMissconfigured = parentEndDate && plannedEnd && parentEndDate > toDateStr(plannedEnd);
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const workDays =
    plannedStart &&
    plannedEnd &&
    workdays(fromIsoDateTimeFormat(plannedStart), fromIsoDateTimeFormat(plannedEnd), getOrgWorkMinutesAtDate);
  return (
    <>
      <div className={cn('flex items-center gap-x-2', isMissconfigured && 'text-red-700')}>
        <NodeIcon nodeType={node.nodeType} /> {t(label)}
      </div>
      <div
        className={cn(
          'flex items-center gap-x-2',
          !plannedEnd && 'italic text-slate-500',
          plannedEnd && 'tabular-nums',
          isMissconfigured && 'text-red-700',
        )}
      >
        {plannedEnd && plannedStart ? (
          <div>
            {formatCollapsingDateRange(fromIsoDateTimeFormat(plannedStart), fromIsoDateTimeFormat(plannedEnd), {
              day: 'DD.',
              dayMonth: 'DD.MM.',
              full: 'DD.MM.YYYY',
            })}{' '}
            ({workDays} {t('general:days', {count: workDays || 0})})
          </div>
        ) : (
          t('nodeDetails:field.planning.workPackagePlanning.placeholder')
        )}
        {isMissconfigured && <Flame className={'size-4'} fill={'#b91c1c'} stroke={'none'} />}
      </div>
    </>
  );
}
