import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {ReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {inspectorContext} from '../../../Drawer/InspectorContext/InspectorContext';
import {
  createTrackedTimeRequestFilterKey,
  TrackedTimeRequestFilterOptions,
} from '../../Selectors/Projects/ProjectControlling/TrackedTimeSelectors';
import {workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector} from '../../Selectors/UiPages/ProjectsSelector';
import {FlowState} from '../../State';

type Stored<D> = Record<string, D | undefined>;

interface LoadAction<D> {
  key: string;
  response: D;
  type: string;
}

export function createUseTrackedTimeInspectorData<D>(
  dataSelector: (state: FlowState) => Stored<D>,
  dataStatesSelector: (state: FlowState) => EntityStates,
  endpoint: string,
  types: {
    failureType: string;
    requestType: string;
    successType: string;
  },
  reducers: ReducerCollection<Stored<D>>,
  stateReducers: ReducerCollection<EntityStates>,
  loadingValue: D = {} as D,
  emptyValue: D = {} as D,
): (options: TrackedTimeRequestFilterOptions) => {
  hasLoadedOnce: boolean;
  isLoading: boolean;
  data: D;
} {
  reducers.add<LoadAction<D>>(types.successType, (state, {key, response}) => {
    return {...state, [key]: response};
  });
  stateReducers.add(types.requestType, createTimestampReducer('key', LOADING));
  stateReducers.add(types.successType, createTimestampReducer('key', LOADED));
  stateReducers.add(
    [
      'flow.TimeRecordCreatedEvent',
      'flow.TimeRecordPatchedEvent',
      'flow.TimeRecordRemovedEvent',
      'flow.TimeRecordsRestoredFromTrashEvent',
    ],
    () => ({}),
  );
  return (options) => {
    const {from, labelIds, to, unitIds} = options;
    const filterKey = createTrackedTimeRequestFilterKey(options);
    const context = useContext(inspectorContext);
    const nodeId = context?.selectedId;
    const {key, workPackageIds} = useSelector((s: FlowState) =>
      workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector(s)(nodeId),
    );
    const fullKey = `${filterKey}@${nodeId}@${key}`;
    const dispatch = useDispatch();
    const state = useSelector((s: FlowState) => dataStatesSelector(s)[fullKey]);
    useEffect(() => {
      if (nodeId && (!state || isOutdated(state))) {
        dispatch({
          [CALL_API]: {
            endpoint,
            types,
            method: 'post',
            options: {data: {workPackageIds, from, to, labelIds, unitIds}, urlParams: {nodeId}},
          },
          key: fullKey,
        });
      }
    }, [dispatch, fullKey, state, labelIds, unitIds, workPackageIds, from, to, nodeId]);
    const isLoading = !!nodeId && (!state || !isLoaded(state));
    const data = useSelector((s: FlowState) => dataSelector(s)[fullKey]) || (isLoading ? loadingValue : emptyValue);
    return {
      data,
      isLoading,
      hasLoadedOnce: useLoadedValue(isLoading, !isLoading, fullKey),
    };
  };
}
