import {Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {CompletableNode} from '../EntityInterfaces/CompletableNode';

export function createCompletableNode(): CompletableNode {
  return {
    completedBy: null,
    completedOn: null,
    isCompleted: false,
  };
}

export function setCompletedOn(patch: Partial<CompletableNode>, userId: Uuid): void {
  //This is only for the optimistic reducers - the values are written php-side, too:
  if (typeof patch.isCompleted === 'boolean') {
    if (patch.isCompleted) {
      patch.completedBy = userId;
      patch.completedOn = unix();
    } else {
      patch.completedBy = null;
      patch.completedOn = null;
    }
  }
}
