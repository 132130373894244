import {TWButton} from '@octaved/ui';
import {X} from 'lucide-react';
import {ReactElement} from 'react';
import {PropsWithAnalyticId} from '../../Analytics/Heap';

interface Props {
  className?: string;
  close: () => void;
}

export default function Close({analyticId, className, close}: PropsWithAnalyticId<Props>): ReactElement {
  return (
    <TWButton
      size={'sm'}
      iconOnly
      variant={'ghost'}
      onClick={close}
      withIcon
      data-analytics-id={analyticId}
      className={className}
    >
      <X className={'size-4'} />
    </TWButton>
  );
}
