import {Uuid} from '@octaved/typescript/src/lib';
import {getPlanningDateSelector} from '../../Selectors/PlanningDateSelectors';
import {createStoreSubscription} from '../StoreSubscription';
import {Bar, BarPluginList, BarProps} from './Bar';

export interface PlanningBarProps extends BarProps {
  planningDateId: Uuid;
}

export class PlanningDateBar extends Bar {
  protected planningDateId: Uuid;

  constructor(props: PlanningBarProps) {
    super(props);

    this.planningDateId = props.planningDateId;
  }

  init(...plugins: BarPluginList): void {
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getPlanningDateSelector(s)(this.planningDateId),
        (planningDate) => {
          if (planningDate) {
            this.setDates(planningDate.plannedStart, planningDate.plannedEnd);
          }
        },
        (a, b) => a?.plannedStart === b?.plannedStart && a?.plannedEnd === b?.plannedEnd,
      ),
    );
    super.init(...plugins);
  }
}
