import {Node} from 'konva/lib/Node';

export function setMouseHoverEffect(
  node: Node,
  cursor: string | undefined,
  onIsHover?: (isHovering: boolean) => void,
): void {
  node.addEventListener('mouseover', () => {
    if (cursor) {
      document.body.style.cursor = cursor;
    }
    onIsHover?.(true);
  });
  node.addEventListener('mouseout', () => {
    if (cursor) {
      document.body.style.cursor = 'default';
    }
    onIsHover?.(false);
  });
}

export function resetCursor(): void {
  document.body.style.cursor = 'default';
}
