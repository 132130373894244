import {EntityStates, hasLoadedOnce, isLoaded} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {GroupEntities, GroupEntity} from '../EntityInterfaces/GroupEntity';
import {GroupState} from '../GroupState';

export const groupsSelector = (state: GroupState): GroupEntities => state.entities.userGroup;
export const groupStateSelector = (state: GroupState): EntityStates => state.entityStates.userGroup;

export const areGroupsLoadedOnceSelector = createSelector(groupStateSelector, (groupState) => (ids: Uuid[]) => {
  return ids.every((id) => groupState[id] && hasLoadedOnce(groupState[id]!));
});
export const areGroupsLoadedSelector = createSelector(groupStateSelector, (groupState) => (ids: Uuid[]) => {
  return ids.every((id) => groupState[id] && isLoaded(groupState[id]!));
});

export const groupNameSelector = createSelector(
  groupsSelector,
  (groupEntities) => (id: Uuid) => groupEntities[id]?.name || null,
);

export function sortGroups(a: GroupEntity, b: GroupEntity): number {
  if (a.isSystemControlled && !b.isSystemControlled) {
    return -1;
  }
  if (!a.isSystemControlled && b.isSystemControlled) {
    return 1;
  }
  return a.name.localeCompare(b.name);
}
