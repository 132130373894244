import {GetWorkingTimeAtDate} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';

export function isWorkday(date: dayjs.Dayjs, getWorkingTimeAtDate: GetWorkingTimeAtDate): boolean {
  return getWorkingTimeAtDate(toIsoFormat(date)).project > 0;
}

export function workdaysBetween(
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  getWorkMinutesAtDate: GetWorkingTimeAtDate,
): number {
  let _start = start.clone();
  let days = 0;

  if (_start.isBefore(end, 'day')) {
    while (_start.isBefore(end, 'day')) {
      if (isWorkday(_start, getWorkMinutesAtDate)) {
        ++days;
      }
      _start = _start.add(1, 'day');
    }
  } else {
    //end date is before start date
    while (end.isBefore(_start, 'day')) {
      if (isWorkday(_start, getWorkMinutesAtDate)) {
        --days;
      }
      _start = _start.add(-1, 'day');
    }
  }
  return days;
}

/**
 * If both `from` and `to` fall into the same off-day period, the result is 0! (e.g. Saturday-Sunday or Sunday-Sunday)
 *
 * Beware that if `from` and `to` are the same workday, you will always get a positive 1 and never a negative -1.
 * Depending on the shift direction in your calling logic, this might be important!
 * Consider using absoluteWorkdaysInRange() instead and apply your shift direction manually.
 */
export function workdays(from: dayjs.Dayjs, to: dayjs.Dayjs, getWorkMinutesAtDate: GetWorkingTimeAtDate): number {
  const fromAfterTo = from.isAfter(to);
  const start = fromAfterTo ? to : from;
  const end = fromAfterTo ? from : to;

  const isWorkDay = (day: dayjs.Dayjs): boolean => isWorkday(day, getWorkMinutesAtDate);

  let days = isWorkDay(start) ? 1 : 0;
  let current = start.add(1, 'day');
  while (!current.isAfter(end)) {
    if (isWorkDay(current)) {
      days++;
    }
    current = current.add(1, 'day');
  }

  return fromAfterTo ? -days : days;
}

/**
 * If both `from` and `to` fall into the same off-day period, the result is 0! (e.g. Saturday-Sunday or Sunday-Sunday)
 */
export function absoluteWorkdaysInRange(
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  getWorkMinutesAtDate: GetWorkingTimeAtDate,
): number {
  return Math.abs(workdays(from, to, getWorkMinutesAtDate));
}

export function addWorkdays(start: dayjs.Dayjs, days: number, getWorkMinutesAtDate: GetWorkingTimeAtDate): dayjs.Dayjs {
  let currentDay = 0;
  const isNegativ = days < 0;
  while (currentDay < Math.abs(days)) {
    start = start.add(isNegativ ? -1 : 1, 'day');
    if (isWorkday(start, getWorkMinutesAtDate)) {
      ++currentDay;
    }
  }
  return start;
}

export function closestWorkDay(start: dayjs.Dayjs, getWorkMinutesAtDate: GetWorkingTimeAtDate): dayjs.Dayjs {
  if (isWorkday(start, getWorkMinutesAtDate)) {
    return start;
  }

  for (let i = 1; i <= 15; ++i) {
    if (isWorkday(start.add(i, 'd'), getWorkMinutesAtDate)) {
      return start.add(i, 'd');
    }
    if (isWorkday(start.subtract(i, 'd'), getWorkMinutesAtDate)) {
      return start.subtract(i, 'd');
    }
  }

  return start;
}

export function ensureWorkDay(
  from: dayjs.Dayjs,
  getWorkMinutesAtDate: GetWorkingTimeAtDate,
  forward = true,
): dayjs.Dayjs {
  let current = from.clone();
  while (!isWorkday(current, getWorkMinutesAtDate)) {
    if (forward) {
      current = current.add(1, 'd');
    } else {
      current = current.subtract(1, 'd');
    }
  }
  return current;
}
