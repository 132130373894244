import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {planningNodeTypes} from '../../Node/NodeIdentifiers';
import {PidCopiedEvent} from '../Events';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<PidCopiedEvent>('flow.PidCopiedEvent', {
  assignedAnyProjectRole: 'clear', //required due to inheritance
  assignedProjectRoleId: 'clear', //required due to inheritance
  assignedProjectRoleType: 'clear', //required due to inheritance
  bpWaitingForMyResponse: 'ignored', //board is not copied
  customerId: 'clear',
  customerIsBillable: 'clear',
  customerName: 'clear',
  customerNumber: 'clear',
  favorite: 'ignored', //new nodes can't be favorites, yet
  grIsClosed: 'ignored', //not copied, default is false
  grIsLocked: 'ignored', //not copied, default is false
  grType: 'clear',
  hasLogicalPredecessor: 'clear', //can be copied
  hasTimeBasedPredecessor: 'clear', //can be copied
  isArchived: 'ignored', //flag not copied
  labelId: 'clear', //labels are copied
  mrIsActive: 'ignored',
  name: 'clear', //name not exposed in event
  nodeNotPlanned: ({copiedNodeTypes, planningWasCopied}) => {
    if (planningWasCopied) {
      return 'clear';
    }
    return {
      results: (state) => {
        const nodeIds = planningNodeTypes.reduce<Uuid[]>((acc, type) => [...acc, ...(copiedNodeTypes[type] || [])], []);
        //planning not copied, so all are unplanned
        return reduceAddOptional(state, nodeIds, 'nodeNotPlanned');
      },
    };
  },
  nodePlannedAroundDateRange: ({planningWasCopied}) => (planningWasCopied ? 'clear' : 'ignored'),
  nodePlanningEndsAfterDate: ({planningWasCopied}) => (planningWasCopied ? 'clear' : 'ignored'),
  nodePlanningEndsBeforeDate: ({planningWasCopied}) => (planningWasCopied ? 'clear' : 'ignored'),
  nodePlanningStartsAfterDate: ({planningWasCopied}) => (planningWasCopied ? 'clear' : 'ignored'),
  nodePlanningStartsBeforeDate: ({planningWasCopied}) => (planningWasCopied ? 'clear' : 'ignored'),
  nodeType: ({copiedNodeTypes}) => {
    return {
      results: (state) => {
        return Object.entries(copiedNodeTypes).reduce<UuidSearchResults>((acc, [type, ids]) => {
          return reduceAddOptional(acc, ids as Uuid[], 'nodeType', type as EnumFlowNodeType);
        }, state);
      },
    };
  },
  pidHasDueDate: 'clear',
  pidHasMilestoneDate: 'clear',
  pidPid: 'clear', //pid not exposed in event
  prCustomerLocationId: ({copiedNodeTypes}) => (copiedNodeTypes[EnumFlowNodeType.VALUE_PROJECT] ? 'clear' : 'ignored'),
  prCustomerLocationNumber: ({copiedNodeTypes}) =>
    copiedNodeTypes[EnumFlowNodeType.VALUE_PROJECT] ? 'clear' : 'ignored',
  prIsClosed: 'ignored', //not copied, default is false
  prIsLocked: 'ignored', //not copied, default is false
  prIsTemplate: 'ignored', //not copied, default is false
  referenceNumber: 'ignored', //not copied, default is empty
  responsibleByAny: 'clear', //required due to inheritance
  responsibleUnitId: 'clear', //required due to inheritance
  swpCompletedInDateRange: 'ignored', //not copied, default is not complete
  swpIsCompleted: 'ignored', //not copied, default is false
  swpIsLocked: 'ignored', //not copied, default is false
  taskBlockedByDependency: 'clear', //copied tasks are not completed, and the isAutoChainActive is copied, too
  taskCompletedInDateRange: 'ignored', //copied tasks are not completed
  taskStatus: ({copiedNodeTypes}) => {
    return {
      results: (state) => {
        const nodeIds = copiedNodeTypes[EnumFlowNodeType.VALUE_TASK] || [];
        //All copied tasks are open:
        return reduceAddOptional(state, nodeIds, 'taskStatus', EnumFlowTaskStatus.VALUE_OPEN);
      },
    };
  },
  timeControlExceeded: 'clear', //time control is copied
  timeControlNotStarted: 'clear', //time control is copied
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'clear',
  wpCompletedInDateRange: 'ignored', //not copied, default is not complete
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored', //not copied
  wpIsClosed: 'ignored', //not copied, default is false
  wpIsCompleted: 'ignored', //not copied, default is false
  wpIsLocked: 'ignored', //not copied, default is false
  wpIsOffer: 'clear', //copied
  wpPriceCategory: 'clear', //copied
  wpTaskTimePrognosis: 'clear',
  wpTimeTrackingLimit: 'clear',
  wpTimeTrackingReferenceNodeType: 'clear',
  wpUserRecentlyBookedOn: 'ignored',
});
