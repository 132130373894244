import {EnumFlowNodeRoleType, EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {EntityStates, hasLoadedOnce, isLoaded} from '@octaved/store/src/EntityState';
import {SubType, Uuid} from '@octaved/typescript/src/lib';
import {mapCache} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {isRemovedRole} from '../../../Authorization/DefaultRoles';
import {
  EffectiveRoleAssignment,
  RoleAssignmentOnNode,
  StoreNodeRoleAssignment,
  StoreNodeRoleAssignmentEntry,
  StoreNodeRoleAssignments,
} from '../../../EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {FlowState} from '../../State';
import {createTranslatedDummyRole, getTranslatedRolesSelector} from '../RoleSelectors';

function sortJoinIds(nodeIds: ReadonlyArray<Uuid>): string {
  return [...nodeIds].sort().join(',');
}

export function getRoleAssignmentsLoadedSelectorFactory(
  nodeRoleAssignmentsStateSelector: (state: FlowState) => EntityStates,
): (state: FlowState) => (nodeIds: Uuid[]) => boolean {
  return createSelector(nodeRoleAssignmentsStateSelector, (state) =>
    memoize((nodeIds: Uuid[]): boolean => {
      return nodeIds.every((nodeId) => {
        const nodeState = state[nodeId];
        return Boolean(nodeState && isLoaded(nodeState));
      });
    }, sortJoinIds),
  );
}

export function getRoleAssignmentsLoadedOnceSelectorFactory(
  nodeRoleAssignmentsStateSelector: (state: FlowState) => EntityStates,
): (state: FlowState) => (nodeIds: Uuid[]) => boolean {
  return createSelector(nodeRoleAssignmentsStateSelector, (state) =>
    memoize((nodeIds: Uuid[]): boolean => {
      return nodeIds.every((nodeId) => {
        const nodeState = state[nodeId];
        return Boolean(nodeState && hasLoadedOnce(nodeState));
      });
    }, sortJoinIds),
  );
}

function calculateRoleAssigmentsOnNode<
  Entry extends StoreNodeRoleAssignmentEntry,
  MappedBy extends keyof SubType<Entry, string>,
  RAN extends RoleAssignmentOnNode,
>(target: StoreNodeRoleAssignment<Entry>, parent: StoreNodeRoleAssignment<Entry> | null, mappedBy: MappedBy): RAN[] {
  const mapByEntryId = new Map<Uuid, RoleAssignmentOnNode>();

  if (parent) {
    Object.values(parent.entries).forEach((entry) => {
      if (entry && !isRemovedRole(entry.flowRoleId)) {
        const mapKey = entry[mappedBy] as unknown as Uuid;
        mapByEntryId.set(mapKey, {
          ...entry,
          definingTargetId: entry.definingTargetId || parent.flowNodeId,
          isAdded: false,
          isInherited: true,
          isOverridden: false,
          isRemoved: false,
          parentDefiningTargetId: entry.definingTargetId || parent.flowNodeId,
          parentRole: entry.flowRoleId,
          uniqueId: mapKey,
        });
      }
    });
  }

  Object.values(target.entries).forEach((entry) => {
    if (!entry) {
      return;
    }
    const mapKey = entry[mappedBy] as unknown as Uuid;
    const {isInherited, flowRoleId} = entry;
    const isRemoved = isRemovedRole(flowRoleId);
    if (!isInherited || !isRemoved) {
      //removed roles are still inherited onto the right def nodes, but must be ignored
      const parentEntry = mapByEntryId.get(mapKey);
      const hasParentEntry = !!parentEntry;
      mapByEntryId.set(mapKey, {
        ...entry,
        definingTargetId: entry.definingTargetId || target.flowNodeId,
        isAdded: !hasParentEntry && !isInherited && !isRemoved,
        isOverridden: hasParentEntry && !isInherited && !isRemoved,
        isRemoved: !isInherited && isRemoved,
        parentDefiningTargetId: parentEntry ? parentEntry.definingTargetId : null,
        parentRole: parentEntry ? parentEntry.flowRoleId : null,
        uniqueId: mapKey,
      });
    }
  });

  const result: RAN[] = [];
  [...mapByEntryId.values()]
    .sort((a, b) => a.unitName.localeCompare(b.unitName))
    .forEach((value) => result.push(value as RAN));
  return result;
}

const emptyResult: unknown[] = [];

export function getRoleAssignmentsOnNodeSelectorFactory<
  Entry extends StoreNodeRoleAssignmentEntry,
  MappedBy extends keyof SubType<Entry, string>,
  RAN extends RoleAssignmentOnNode,
>(
  rawSelector: (state: FlowState) => StoreNodeRoleAssignments<Entry>,
  mappedBy: MappedBy,
): (state: FlowState) => (nodeId: Uuid) => RAN[] {
  return createSelector(rawSelector, (roleAssignments) =>
    memoize((nodeId: Uuid): RAN[] => {
      const assignments = roleAssignments[nodeId];
      if (
        !assignments ||
        (assignments.parentRightDefinitionTargetId && !roleAssignments[assignments.parentRightDefinitionTargetId])
      ) {
        return emptyResult as RAN[];
      }
      const parentRoleAssignments =
        (assignments.parentRightDefinitionTargetId && roleAssignments[assignments.parentRightDefinitionTargetId]) ||
        null;
      return calculateRoleAssigmentsOnNode(assignments, parentRoleAssignments, mappedBy);
    }),
  );
}

export function getEffectiveRoleAssignmentsForNodeSelectorFactory<RAN extends RoleAssignmentOnNode>(
  assignmentsOnNodeSelector: (state: FlowState) => (nodeId: Uuid) => RAN[],
  roleType: EnumFlowRoleType,
): (state: FlowState) => (nodeId: Uuid) => ReadonlyArray<EffectiveRoleAssignment> {
  return createSelector(assignmentsOnNodeSelector, getTranslatedRolesSelector, (assignmentsOnNode, roles) =>
    memoize((nodeId: Uuid): ReadonlyArray<EffectiveRoleAssignment> => {
      return assignmentsOnNode(nodeId)
        .map((asmt) => ({
          ...asmt,
          //The `createTranslatedDummyRole` prevents broken references if a role gets deleted and isn't there anymore:
          role: roles[roleType][asmt.flowRoleId] || createTranslatedDummyRole(roleType),
        }))
        .filter(({isRemoved, role}) => !isRemoved && role)
        .sort((a, b) => a.unitName.localeCompare(b.unitName));
    }),
  );
}

export function getEffectiveRoleAssignmentsForNodesSelectorFactory<RAN extends RoleAssignmentOnNode>(
  assignmentsOnNodeSelector: (state: FlowState) => (nodeId: Uuid) => RAN[],
  roleType: EnumFlowRoleType,
): (state: FlowState) => (nodeIds: ReadonlyArray<Uuid>) => Record<Uuid, EffectiveRoleAssignment[]> {
  return createSelector(assignmentsOnNodeSelector, getTranslatedRolesSelector, (assignmentsOnNode, roles) =>
    memoize((nodeIds: ReadonlyArray<Uuid>): Record<Uuid, EffectiveRoleAssignment[]> => {
      const record: Record<Uuid, EffectiveRoleAssignment[]> = {};
      nodeIds.forEach((nodeId) => {
        const assignments = assignmentsOnNode(nodeId);
        record[nodeId] = assignments
          .map((asmt) => ({
            ...asmt,
            role: roles[roleType][asmt.flowRoleId]!,
          }))
          .filter(({isRemoved, role}) => !isRemoved && role)
          .sort((a, b) => a.unitName.localeCompare(b.unitName));
      });
      return record;
    }, sortJoinIds),
  );
}

export const canReadNodeRoleAssignmentsSelector = createSelector(isGrantedSelector, (isGranted) =>
  mapCache((type: EnumFlowNodeRoleType, isGuestRole: boolean, nodeId: Uuid | null | undefined) => {
    if (!nodeId) {
      return false;
    }
    const right =
      type === EnumFlowNodeRoleType.VALUE_PROJECT
        ? 'FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS'
        : isGuestRole
          ? 'FLOW_NODE_READ_GUEST_PERMISSION_ROLE_ASSIGNMENTS'
          : 'FLOW_NODE_READ_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS';
    return isGranted(right, nodeId);
  }),
);

export const canManageNodeRoleAssignmentsSelector = createSelector(isGrantedSelector, (isGranted) =>
  mapCache((type: EnumFlowNodeRoleType, isGuestRole: boolean, nodeId: Uuid | null | undefined) => {
    if (!nodeId) {
      return false;
    }
    const right =
      type === EnumFlowNodeRoleType.VALUE_PROJECT
        ? 'FLOW_NODE_MANAGE_PROJECT_ROLE_ASSIGNMENTS'
        : isGuestRole
          ? 'FLOW_NODE_MANAGE_GUEST_PERMISSION_ROLE_ASSIGNMENTS'
          : 'FLOW_NODE_MANAGE_INTERNAL_PERMISSION_ROLE_ASSIGNMENTS';
    return isGranted(right, nodeId);
  }),
);
