import {EnumFlowPidFreeOfChargeReason} from '@octaved/env/src/dbalEnumTypes';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

const freeOfChargeReasonTokens: Record<EnumFlowPidFreeOfChargeReason, string> = {
  [EnumFlowPidFreeOfChargeReason.VALUE_AQUISITION]: 'nodeDetails:field.freeOfChargeReason.reason.aquisition',
  [EnumFlowPidFreeOfChargeReason.VALUE_WARRANTY]: 'nodeDetails:field.freeOfChargeReason.reason.warranty',
  [EnumFlowPidFreeOfChargeReason.VALUE_SERVICE]: 'nodeDetails:field.freeOfChargeReason.reason.service',
};

export interface NodeFreeOfChargeReasonProps {
  readonly?: boolean;
  reason: EnumFlowPidFreeOfChargeReason;
  setReason: (reason: EnumFlowPidFreeOfChargeReason) => void;
}

export default function NodeFreeOfChargeReason({
  frame: Frame,
  readonly,
  reason,
  setReason,
}: NodeFreeOfChargeReasonProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Frame labelToken={'nodeDetails:field.freeOfChargeReason.label'}>
      {readonly && <>{t(freeOfChargeReasonTokens[reason])}</>}
      {!readonly && (
        <TWSelect value={reason} onValueChange={setReason}>
          <TWSelectTrigger>
            <TWSelectValue placeholder={t('nodeDetails:field.priceCategory.placeholder')} />
          </TWSelectTrigger>
          <TWSelectContent>
            {Object.entries<string>(freeOfChargeReasonTokens).map(([value, text]) => (
              <TWSelectItem key={value} value={value}>
                {t(text)}
              </TWSelectItem>
            ))}
          </TWSelectContent>
        </TWSelect>
      )}
    </Frame>
  );
}
