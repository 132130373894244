import {createPlanningWarningSnackbar} from '@octaved/flow/src/Components/Snackbar/PlanningSnackbar';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {PlanningType} from './PlanningType';

export enum ModalDialog {
  none,
  selectDependency,
  switchPlanningType,
}

export function showModal(
  currentType: PlanningType,
  nextType: PlanningType,
  node: PlanningRealizationNode,
): ModalDialog {
  if (nextType === PlanningType.dependency) {
    return ModalDialog.selectDependency;
  }

  return (
    //show messages only if date were selected
    (currentType === PlanningType.date && nextType === PlanningType.none && node.planningDates.length > 0) ||
      //show messages only if dependencies were selected
      (currentType === PlanningType.dependency &&
        nextType === PlanningType.none &&
        node.planningPredecessors.length > 0)
      ? ModalDialog.switchPlanningType
      : ModalDialog.none
  );
}

export function showInfoMessages(
  currentType: PlanningType,
  nextType: PlanningType,
  node: PlanningRealizationNode,
): void {
  let message = '';
  if (currentType === PlanningType.date && nextType === PlanningType.dependency) {
    message = 'pages:projects.inspector.manage.planning.planningTypeSwitch.periodToDependencyInfo';
  } else if (
    currentType === PlanningType.dependency &&
    nextType === PlanningType.date &&
    node.planningPredecessors.length
  ) {
    message = 'pages:projects.inspector.manage.planning.planningTypeSwitch.dependencyToPeriodInfo';
  }

  if (message) {
    createPlanningWarningSnackbar([
      {
        message,
        field: node.id,
      },
    ]);
  }
}
