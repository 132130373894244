/**
 * The network module of HcCom.
 *
 * The following methods are supported:
 * - GET
 * - POST
 * - DELETE
 * Each of them using the json format.
 *
 * Fetch does not support MSIE 8:
 * @link {https://github.com/github/fetch/issues/2}
 *
 * The methods of Network return a Promise object:
 * @link {http://www.html5rocks.com/en/tutorials/es6/promises/}
 *
 * Example:
 * {{{
 *  Network.get('/this/is/any/url/with/{a_parameter}', {
 *    urlParams: { 'a_parameter': 'paramvalue' }
 *  });
 *
 *  Network.post('/this/is/any/url', {
 *    data: { id: 2 }
 *  });
 * }}}
 */
import {getOrganizationHeaders} from '@octaved/identity/src/Modules/OrganizationSwitcher';
import {getInstanceUuid} from '@octaved/utilities';
import {validateFunction} from '@octaved/validation';
import {extend} from 'lodash';
import {generateUrl} from './UrlGenerator';

export {generateUrl} from './UrlGenerator';

let eventManager;

export function setEventManager(_eventManager) {
  eventManager = _eventManager;
}

/**
 * promise where all fetch requests are added to get a callback method when all requests are resolved
 * @type Promise
 */
let requestFinishPromise = Promise.resolve();

/**
 * The default options for the Network methods.
 */
const defaultOptions = {
  ignoreNotFound: false,
  skipUnauthorized: false,
  urlParams: {},
};

/**
 * Applies a response filter on the given promise.
 *
 * @param {Promise} promiseSoFar
 * @param {Object} filter
 * @param {Object} fetchSettings
 * @param {Object} options
 * @param {String} url
 * @returns {Promise}
 */
function addResponseFilter(promiseSoFar, filter, fetchSettings, options, url) {
  let result = promiseSoFar;
  if (filter.then) {
    result = result.then((arg) => filter.then(arg, options, fetchSettings, url));
  }
  if (filter.catch) {
    result = result.catch((arg) => filter.catch(arg, options, fetchSettings, url));
  }
  return result;
}

/**
 * Executes the query and appends the given response filters.
 *
 * @param {String} url
 * @param {Object} fetchSettings
 * @param {Object} options
 * @param {Array} filters An array of response filters.
 * @returns {Promise}
 */
function executeQueryWithResponseFilters(url, fetchSettings, options, filters) {
  let prom = fetch(url, fetchSettings);
  filters.forEach((filter) => {
    prom = addResponseFilter(prom, filter, fetchSettings, options, url);
  });

  //add the request promise to the requestFinishPromise
  //catch also the exceptions from the fetch promise as they are chained here and not
  //always catched by an implementation
  requestFinishPromise = requestFinishPromise
    .then(() => {
      eventManager.trigger('fetch.request.handled', url);
      return prom.then(() => true).catch(() => false);
    })
    .catch(() => prom.then(() => true).catch(() => false));

  return prom;
}

/**
 * Handles a request submitting a payload either as FormData or JSON
 *
 * @param {String} url
 * @param {Object} options
 * @param {String} method The HTTP method (GET|POST|...)
 * @param {Object} header
 * @returns {Promise}
 */
function submit(url, options, method, header) {
  const fetchSettings = {
    method,
    headers: header,
  };

  if (options.data instanceof FormData) {
    fetchSettings.body = options.data;
    fetchSettings.headers = {
      Accept: 'application/json',
    };
  } else {
    fetchSettings.body = JSON.stringify(options.data);
  }

  return query(url, options, fetchSettings, method);
}

export function getDefaultHeader() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

/**
 * Handles a POST request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {Object} header [optional]
 * @returns {Promise}
 */
export function post(url, options = {}, header = getDefaultHeader()) {
  return submit(url, options, 'post', header);
}

/**
 * Handles a PUT request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function put(url, options = {}, header = getDefaultHeader()) {
  return submit(url, options, 'put', header);
}

/**
 * Handles a PATCH request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function patch(url, options = {}, header = getDefaultHeader()) {
  return submit(url, options, 'patch', header);
}

/**
 * Handles a DELETE request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function del(url, options = {}, header = getDefaultHeader()) {
  return submit(url, options, 'delete', header);
}

/**
 * Handles a HEAD request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function head(url, options = {}, header = getDefaultHeader()) {
  return query(url, options, header, 'head');
}

/**
 * Handles a LOCK request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function lock(url, options = {}, header = getDefaultHeader()) {
  return query(url, options, header, 'lock');
}

/**
 * Handles an UNLOCK request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function unlock(url, options = {}, header = getDefaultHeader()) {
  return query(url, options, header, 'unlock');
}

/**
 * Handles a GET request
 *
 * @param {String} url
 * @param {Object} options [optional]
 * @param {{}} header
 * @returns {Promise}
 */
export function get(url, options = {}, header = getDefaultHeader()) {
  return query(url, options, header);
}

let getResponseFilters = (_contentType, _stream) => [];

/**
 * @param {function} getter
 */
export function setResponseFilters(getter) {
  validateFunction(getter);
  getResponseFilters = getter;
}

/**
 * Load content
 *
 * @param {String} url
 * @param {Object} queryOptions
 * @param {Object} header
 * @param {String} method
 * @returns {Promise}
 */
function query(url, queryOptions, header, method = 'get') {
  const options = extend({}, defaultOptions, queryOptions);

  if (typeof url !== 'string' || !url) {
    throw new Error('Invalid URL, expected type string, got ' + typeof url);
  }
  let fetchSettings = {
    method,
    credentials: 'include',
  };
  let contentType = header.Accept;

  if (header.headers) {
    fetchSettings = extend({}, fetchSettings, header);
    contentType = fetchSettings.headers.Accept;
  } else {
    fetchSettings.headers = header;
  }

  fetchSettings.headers = {
    ...fetchSettings.headers,
    ...getOrganizationHeaders(),
    'octaved-instance-id': getInstanceUuid(),
  };

  const filters = getResponseFilters(contentType, options.stream);

  return executeQueryWithResponseFilters(generateUrl(url, options.urlParams), fetchSettings, options, filters);
}

/**
 * Creates a callback for catching a status code response other than 200/204
 *
 * @param {Number|[]} statusCode the status code to handle e.g. 404
 * @param {Function} callback the handler for the status code
 * @returns {Function} the callback to give to catch() to handle the status code
 */
export function getStatusCodeHandler(statusCode, callback) {
  return (response) => {
    if (response.status === statusCode || (Array.isArray(statusCode) && statusCode.indexOf(response.status) !== -1)) {
      return response.json().then(callback);
    }
    return Promise.reject(response);
  };
}

/**
 * a promise where all fetch requests are added to get a callback method when all requests are resolved
 * network request executed after getting this promise will be ignored
 * @returns {Promise}
 */
export function getRequestFinishPromise() {
  return requestFinishPromise;
}
