import {getProjectControllingTrackedTimePerDay} from '@octaved/flow-api';
import {EntityStates} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {StoreTrackedTimePerDay} from '../../../EntityInterfaces/Projects/ProjectControlling/TrackedTime';
import {
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_FAILURE,
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_REQUEST,
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_SUCCESS,
} from '../../ActionTypes';
import {
  trackedTimePerDaySelector,
  trackedTimePerDayStatesSelector,
} from '../../Selectors/Projects/ProjectControlling/TrackedTimeSelectors';
import {createUseTrackedTimeInspectorData} from './TrackedTimeInspector';

const reducers = createReducerCollection<StoreTrackedTimePerDay>({});
export const trackedTimePerDayReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityStates>({});
export const trackedTimePerDayStateReducer = stateReducers.reducer;

export const useTrackedTimePerDay = createUseTrackedTimeInspectorData(
  trackedTimePerDaySelector,
  trackedTimePerDayStatesSelector,
  getProjectControllingTrackedTimePerDay,
  {
    failureType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_FAILURE,
    requestType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_REQUEST,
    successType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_PER_DAY_SUCCESS,
  },
  reducers,
  stateReducers,
);
