import {measureDuration} from '@octaved/env/src/Logger';
import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {EntityStates, reduceAllAreLoaded} from '@octaved/store/src/EntityState';
import {createSubRecordSelector} from '@octaved/store/src/Selectors/CreateSubRecordSelector';
import {getState} from '@octaved/store/src/Store';
import {combineConditionResults} from '@octaved/utilities/src/Condition/CombineConditionResults';
import {flattenCondition} from '@octaved/utilities/src/Condition/FlattenCondition';
import {hashCondition} from '@octaved/utilities/src/Condition/HashCondition';
import {AnyCondition, CombinersShape} from '@octaved/utilities/src/Condition/Types';
import {persistScalarArray} from '@octaved/utilities/src/PersistScalarArray';
import {intersection} from 'lodash';
import debounce from 'lodash/debounce';
import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';

const emptyIds: unknown[] = [];

const emptyResult: SearchResultWithLoading<unknown> = {
  hasLoadedOnce: true, //if we don't query anything, don't pretend to load something
  ids: emptyIds,
  isActive: false,
  isLoading: false,
};

export interface SearchResult<idType> {
  hasLoadedOnce: boolean; //resets together with the cache, see option `invalidateCacheOn`
  isActive: boolean;
  ids: ReadonlyArray<idType>;
}

export interface SearchResultWithLoading<idType> extends SearchResult<idType> {
  isLoading: boolean;
}

type BaseSearchTuple<SearchIdent extends string = string> = [SearchIdent] | [SearchIdent, string];

export function createDebugCombinedSearch<
  idType,
  SearchTuple extends BaseSearchTuple,
  State,
  Combiners extends CombinersShape<idType>,
>(
  getResultKey: (ident: SearchTuple[0], value?: SearchTuple[1]) => string,
  resultSelector: (state: State) => {[key: string]: ReadonlyArray<idType> | undefined},
  combinersSelector: Combiners extends CombinersShape<idType>
    ? (state: State) => Combiners
    : never = (() => ({})) as never,
): (query: AnyCondition<SearchTuple, idType, Combiners>) => ReadonlyArray<idType> {
  return (query) => {
    const search = resultSelector(getState());
    const combiners = combinersSelector(getState()) as Combiners;
    return combineConditionResults<SearchTuple, idType, Combiners>(
      query,
      ([ident, value]) => search[getResultKey(ident, value)] || (emptyIds as ReadonlyArray<idType>),
      combiners,
    );
  };
}

const allMissing = new Set<string>();
const complainDebounced = debounce(() => {
  // eslint-disable-next-line no-console
  console.error('[TEST] combineConditionResults was not executed because there are missing search keys in the state', [
    ...allMissing,
  ]);
  allMissing.clear();
});
function notifyMissingKeys(notLoadedKeys: ReadonlySet<string>): void {
  if (process.env.NODE_ENV === 'test') {
    notLoadedKeys.forEach((k) => allMissing.add(k));
    complainDebounced();
  }
}

interface Caches<idType> {
  condition: Array<Array<ReadonlyArray<idType> | undefined>>;
  result: SearchResultWithLoading<idType>[];
  resultIds: ReadonlyArray<idType>[];
}

interface ResultRetriever<idType, SearchTuple extends BaseSearchTuple> {
  (tuple: SearchTuple): ReadonlyArray<idType>;
}

const cacheResultRetrievers = new WeakMap();

function getCachedResultRetriever<idType, SearchTuple extends BaseSearchTuple>(
  search: Record<string, ReadonlyArray<idType> | undefined>,
  getResultKey: (ident: SearchTuple[0], value?: SearchTuple[1]) => string,
): ResultRetriever<idType, SearchTuple> {
  let retriever: ResultRetriever<idType, SearchTuple> = cacheResultRetrievers.get(search);
  if (!retriever) {
    // console.log('creating new results retriever', search);
    retriever = ([ident, value]: SearchTuple) =>
      search[getResultKey(ident, value)] || (emptyIds as ReadonlyArray<idType>);
    cacheResultRetrievers.set(search, retriever);
  }
  return retriever;
}

interface BaseOptions {
  invalidateCacheOn?: boolean | number | string | object; //can be whatever - triggers the cache to be reset
}

interface OptionsWithoutLoading extends BaseOptions {
  trackIsLoading?: false;
}

interface OptionsWithLoading extends BaseOptions {
  trackIsLoading: true;
}

interface UseCombinedSearch<idType, SearchTuple extends BaseSearchTuple, Combiners extends CombinersShape<idType>> {
  (
    options: OptionsWithoutLoading | undefined,
    ...queries: Array<AnyCondition<SearchTuple, idType, Combiners> | null>
  ): SearchResult<idType>[];

  (
    options: OptionsWithLoading | undefined,
    ...queries: Array<AnyCondition<SearchTuple, idType, Combiners> | null>
  ): SearchResultWithLoading<idType>[];
}

const emptyCombiners = {};

//localStorage.setItem('debug:search', 'true');
const debugSearch = !!localStorage.getItem('debug:search');

function getCallerName(): string {
  if (!debugSearch) {
    return '';
  }
  const stack = new Error().stack?.split('\n');
  if (!stack) {
    return '';
  }
  const names = stack.map((line) => line.trim().split(' ')[1] || '');
  const startNames = [
    'useCombinedNodeSearch',
    'useCombinedNodeSearches',
    'useCombinedTimeRecordSearch',
    'useCombinedTimeRecordSearches',
  ];
  let idxStart = 3;
  for (const startName of startNames) {
    const idx = names.indexOf(startName);
    if (idx > -1) {
      idxStart = idx + 1;
      break;
    }
  }
  const idxStop = names.indexOf('renderWithHooks');
  return names
    .slice(idxStart, idxStop > -1 ? idxStop : undefined)
    .toReversed()
    .join('>');
}

/**
 * NOTE: The resulting array is NOT memoed! Only the individual query results are!
 */
export function createUseCombinedSearch<
  idType,
  SearchTuple extends BaseSearchTuple,
  State,
  Combiners extends CombinersShape<idType>,
>(
  getResultKey: (ident: SearchTuple[0], value?: SearchTuple[1]) => string,
  resultSelector: (state: State) => Record<string, ReadonlyArray<idType> | undefined>,
  resultStateSelector: (state: State) => EntityStates,
  searchAction: (searches: ReadonlyArray<SearchTuple>) => ThunkAct<void>,
  combinersSelector: (state: State) => Combiners,
): UseCombinedSearch<idType, SearchTuple, Combiners>;
export function createUseCombinedSearch<idType, SearchTuple extends BaseSearchTuple, State>(
  getResultKey: (ident: SearchTuple[0], value?: SearchTuple[1]) => string,
  resultSelector: (state: State) => Record<string, ReadonlyArray<idType> | undefined>,
  resultStateSelector: (state: State) => EntityStates,
  searchAction: (searches: ReadonlyArray<SearchTuple>) => ThunkAct<void>,
  combinersSelector?: never,
): UseCombinedSearch<idType, SearchTuple, never>;
export function createUseCombinedSearch<
  idType,
  SearchTuple extends BaseSearchTuple,
  State,
  Combiners extends CombinersShape<idType> = never,
>(
  getResultKey: (ident: SearchTuple[0], value?: SearchTuple[1]) => string,
  resultSelector: (state: State) => Record<string, ReadonlyArray<idType> | undefined>,
  resultStateSelector: (state: State) => EntityStates,
  searchAction: (searches: ReadonlyArray<SearchTuple>) => ThunkAct<void, State>,
  combinersSelector: Combiners extends CombinersShape<idType> ? (state: State) => Combiners : never = (() =>
    emptyCombiners) as never,
): UseCombinedSearch<idType, SearchTuple, Combiners> {
  return ({invalidateCacheOn = '', trackIsLoading} = {}, ...queries) => {
    const callerName = getCallerName();

    const combiners = useSelector(combinersSelector) as Combiners;
    const queriesHash = queries.map((query) => hashCondition(combiners, query)).join('+');
    const lastQueriesHash = useRef<string | null>(null);

    const caches = useMemo<Caches<idType>>(() => {
      // noinspection BadExpressionStatementJS
      void invalidateCacheOn; //trigger
      return {condition: [], result: [], resultIds: []};
    }, [invalidateCacheOn]);

    const [queriesAllConditions, queriesAllKeys, totalAllConditions, totalAllKeys] = useMemo(() => {
      const total: SearchTuple[] = [];
      const totalKeys: string[] = [];
      const mapped: SearchTuple[][] = [];
      const mappedKeys: ReadonlyArray<string>[] = [];
      queries.forEach((q) => {
        const conds = q ? flattenCondition(q) : [];
        const keys = conds.map(([k, v]) => getResultKey(k, v)).sort();
        total.push(...conds);
        totalKeys.push(...keys);
        mapped.push(conds);
        mappedKeys.push(keys);
      });
      totalKeys.sort();
      //Persist the array instances if the content is identical to give createSubRecordSelector the same arrays:
      return [mapped, mappedKeys, total, persistScalarArray(totalKeys)];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queriesHash]);

    useStoreEffect((dispatch) => dispatch(searchAction(totalAllConditions)), [totalAllConditions], resultStateSelector);

    const resultsSelector = useMemo(
      () => {
        const searchResultsSelector = createSubRecordSelector(resultSelector, totalAllKeys);
        const searchStateSelector = createSubRecordSelector(resultStateSelector, totalAllKeys);
        let lastQueryResults: SearchResultWithLoading<idType>[];
        return createSelector(
          searchResultsSelector,
          searchStateSelector,
          combinersSelector as (state: State) => Combiners,
          (storeResults, storeStates, combiners) => {
            const resultRetriever = getCachedResultRetriever(storeResults, getResultKey);
            let queryResultsChanged = false;
            const queryResults = queries.map<SearchResultWithLoading<idType>>((query, queryIndex) => {
              if (!query) {
                caches.result[queryIndex] = emptyResult as SearchResultWithLoading<idType>;
                return emptyResult as SearchResultWithLoading<idType>;
              }

              const [allAreLoaded, notLoadedKeys] = reduceAllAreLoaded(storeStates, queriesAllKeys[queryIndex]);
              const isLoading = !allAreLoaded;

              //Determine whether any of the individual conditions have changed their results between two "loaded"
              // states:
              let hasResultChanged = lastQueriesHash.current !== queriesHash;

              if (!isLoading) {
                const lastQueryConditionResult = caches.condition[queryIndex] || (emptyIds as ReadonlyArray<idType>[]);
                const conditionResults: Array<ReadonlyArray<idType> | undefined> = [];
                queriesAllConditions[queryIndex].forEach((tuple, conditionIndex) => {
                  const conditionResult = resultRetriever(tuple);
                  if (conditionResult !== lastQueryConditionResult[conditionIndex]) {
                    hasResultChanged = true;
                  }
                  conditionResults.push(conditionResult);
                });
                caches.condition[queryIndex] = conditionResults;
              }

              //Calculate the combined result ids, but only when all are loaded and there are individual
              // changes to the conditions:
              let resultingIds = caches.resultIds[queryIndex];
              if (!isLoading && (!resultingIds || hasResultChanged)) {
                const measure = debugSearch ? measureDuration('combineConditionResults') : undefined;
                resultingIds = combineConditionResults<SearchTuple, idType, Combiners>(
                  query,
                  resultRetriever,
                  combiners,
                );
                if (debugSearch) {
                  // eslint-disable-next-line no-console
                  console.log(callerName, measure!.stop().duration);
                }
                caches.resultIds[queryIndex] = resultingIds;
              } else {
                notifyMissingKeys(notLoadedKeys);
              }

              //Update the return object for each query:
              let resulting = caches.result[queryIndex];
              const nextIds = isLoading ? resulting?.ids || emptyIds : resultingIds;
              const nextHasLoadedOnce = resulting?.hasLoadedOnce || !isLoading;
              const nextIsLoading = trackIsLoading ? isLoading : false;
              if (
                !resulting ||
                resulting.ids !== nextIds ||
                resulting.hasLoadedOnce !== nextHasLoadedOnce ||
                resulting.isLoading !== nextIsLoading
              ) {
                resulting = {
                  hasLoadedOnce: nextHasLoadedOnce,
                  ids: nextIds,
                  isActive: true,
                  isLoading: nextIsLoading,
                };
                caches.result[queryIndex] = resulting;
                queryResultsChanged = true;
              }
              return resulting;
            });

            lastQueriesHash.current = queriesHash;

            //Return the same reference if nothing has changed:
            if (!lastQueryResults || queryResultsChanged) {
              lastQueryResults = queryResults;
            }

            return lastQueryResults;
          },
          {devModeChecks: {inputStabilityCheck: 'never'}},
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        // We use explicitly only these dependencies:
        queriesHash, //depends on the query
        trackIsLoading,
      ],
    );
    return useSelector(resultsSelector);
  };
}

export function forEachQueryIntersect<T>(
  searchResults: SearchResult<T>[],
  fullResult: ReadonlyArray<T>,
  length: number,
  extender: (ids: ReadonlyArray<T>) => ReadonlyArray<T> = (ids) => ids,
): ReadonlyArray<T> {
  let result = fullResult;
  for (let i = 0; i < length; i++) {
    const filterIds = searchResults.shift()!.ids;
    const extended = extender(filterIds);
    result = intersection(result, extended);
  }
  return result;
}

export function searchResultsToIdSet<T>(searchResults: SearchResult<T>[]): Set<T> {
  return searchResults.reduce((outerSet, {ids}) => {
    return ids.reduce((innerSet, id) => innerSet.add(id), outerSet);
  }, new Set<T>());
}
