import Flex from '@octaved/ui-components/src/Flex';
import classNames from 'classnames';
import {memo, MouseEventHandler, ReactElement, ReactNode, useContext} from 'react';
import css from 'styled-jsx/css';
import {FlowColors, FlowTransitions} from '../Styles/Default';
import {flowStyleContext} from '../Styles/StyleContext';
import AngleRightSvg from '../svgs/angleRight.svg?tsx';
import BinSvg from '../svgs/bin.svg?tsx';
import BookSvg from '../svgs/book.svg?tsx';
import CalendarSvg from '../svgs/calendar.svg?tsx';
import CarSvg from '../svgs/car.svg?tsx';
import CheckSvg from '../svgs/check.svg?tsx';
import CheckCircleSvg from '../svgs/checkCircle.svg?tsx';
import CircleSvg from '../svgs/circle.svg?tsx';
import ClockFilledSvg from '../svgs/clockFilled.svg?tsx';
import CopySvg from '../svgs/copy.svg?tsx';
import CrossSvg from '../svgs/cross.svg?tsx';
import CrossCircleSvg from '../svgs/crossCircle.svg?tsx';
import CursorSvg from '../svgs/cursor.svg?tsx';
import DiamondSvg from '../svgs/diamond.svg?tsx';
import DragSvg from '../svgs/drag.svg?tsx';
import FolderSvg from '../svgs/folder.svg?tsx';
import GroupSvg from '../svgs/group.svg?tsx';
import HistorySvg from '../svgs/history.svg?tsx';
import HorizontalEllipsisSvg from '../svgs/horizontalEllipsis.svg?tsx';
import ImageSvg from '../svgs/image.svg?tsx';
import InboxSvg from '../svgs/inbox.svg?tsx';
import LinkSvg from '../svgs/link.svg?tsx';
import LinkRemoveSvg from '../svgs/linkRemove.svg?tsx';
import LockSvg from '../svgs/lock.svg?tsx';
import OldPaperSvg from '../svgs/oldPaper.svg?tsx';
import PauseCircleSvg from '../svgs/pauseCircle.svg?tsx';
import PencilSvg from '../svgs/pencil.svg?tsx';
import PlayCircleSvg from '../svgs/playCircle.svg?tsx';
import PlusSvg from '../svgs/plus.svg?tsx';
import SandClockSvg from '../svgs/sandClock.svg?tsx';
import StackFilledSvg from '../svgs/stackFilled.svg?tsx';
import StarSvg from '../svgs/star.svg?tsx';
import StarHalfFilledSvg from '../svgs/starHalfFilled.svg?tsx';
import StarFilledSvg from '../svgs/startFilled.svg?tsx';
import StopWatchSvg from '../svgs/stopWatch.svg?tsx';
import ThumbsUpSvg from '../svgs/thumbsUp.svg?tsx';
import TriangleSvg from '../svgs/triangle.svg?tsx';
import UserSvg from '../svgs/user.svg?tsx';

//#region <styles>
function getStyle(
  ICON: FlowColors['ICON'],
  PLACEHOLDER: FlowColors['PLACEHOLDER'],
  {FAST}: FlowTransitions,
): {className: string; styles: JSX.Element} {
  /*language=SCSS*/
  return css.resolve`
    .icon {
      width: 1em;
      fill: ${ICON.FILL.DEFAULT};
      flex-shrink: 0;
    }

    .heightLimitation {
      height: 1em;
      width: auto;
    }

    .buttonIcon {
      .icon {
        height: 16px;
        width: 16px;

        &.bigButtonIcon {
          height: 21px;
          width: 21px;
        }
      }
    }

    .big .icon {
      width: 1.4em;

      &.heightLimitation {
        height: 1.4em;
        width: auto;
      }
    }

    .large .icon {
      width: 1.2em;

      &.heightLimitation {
        height: 1.2em;
        width: auto;
      }
    }

    .big.angleRightThin .icon {
      width: 1.2em;
    }

    .textCircle .icon,
    .hashtagCircle .icon {
      width: 19px;
      height: 19px;
    }

    .small .icon {
      width: 0.7em;

      &.heightLimitation {
        height: 0.7em;
        width: auto;
      }
    }

    .small.circle .icon {
      width: 0.5em;
      height: 0.5em;
    }

    .stroke {
      stroke: ${ICON.STROKE.DEFAULT};
      fill: none;
    }

    .fillAndStroke {
      stroke: ${ICON.STROKE.DEFAULT};
    }

    .animated .icon {
      transition: all ${FAST};
    }

    .rotate90 .icon {
      transform: rotate(90deg);
    }

    .rotate180 .icon {
      transform: rotate(180deg);
    }

    .rotate270 .icon {
      transform: rotate(270deg);
    }

    .strokeWidth2 {
      stroke-width: 2px;
    }

    .cross.big .strokeWidth2 {
      stroke-width: 1px;
    }

    .strokeWidth3 {
      stroke-width: 3px;
    }

    .rotateX-180 {
      transform: rotateX(180deg);
    }

    .rotateY-180 {
      transform: rotateY(180deg);
    }

    .iconWrapper {
      margin-right: 8px;
    }

    .noMargin {
      margin-right: 0;
    }

    .marginLeft {
      margin-right: 0;
      margin-left: 8px;
    }

    .clickAble {
      cursor: pointer;
    }

    .currentColor {
      & .icon {
        fill: currentColor;
      }

      & .stroke {
        stroke: currentColor;
        fill: none;
      }

      & .fillAndStroke {
        stroke: currentColor;
        fill: currentColor;
      }
    }

    .blue {
      & .icon {
        fill: ${ICON.FILL.BLUE.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.BLUE.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.BLUE.DEFAULT};
        fill: ${ICON.FILL.BLUE.DEFAULT};
      }
    }

    .red {
      & .icon {
        fill: ${ICON.FILL.RED.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.RED.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.RED.DEFAULT};
        fill: ${ICON.FILL.RED.DEFAULT};
      }
    }

    .orange {
      & .icon {
        fill: ${ICON.FILL.ORANGE.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.ORANGE.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.ORANGE.DEFAULT};
        fill: ${ICON.FILL.ORANGE.DEFAULT};
      }
    }

    .light {
      & .icon {
        fill: ${ICON.FILL.LIGHT.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.LIGHT.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.LIGHT.DEFAULT};
        fill: ${ICON.FILL.LIGHT.DEFAULT};
      }
    }

    .darkBlue {
      & .icon {
        fill: ${ICON.FILL.BLUE.ALTERNATIVE};
      }

      & .stroke {
        stroke: ${ICON.STROKE.BLUE.ALTERNATIVE};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.BLUE.ALTERNATIVE};
        fill: ${ICON.FILL.BLUE.ALTERNATIVE};
      }
    }

    .green {
      & .icon {
        fill: ${ICON.FILL.GREEN.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.GREEN.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.GREEN.DEFAULT};
        fill: ${ICON.FILL.GREEN.DEFAULT};
      }
    }

    .darkGreen {
      & .icon {
        fill: ${ICON.FILL.GREEN.ALTERNATIVE};
      }

      & .stroke {
        stroke: ${ICON.STROKE.GREEN.ALTERNATIVE};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.GREEN.ALTERNATIVE};
        fill: ${ICON.FILL.GREEN.ALTERNATIVE};
      }
    }

    .brightGreen {
      & .icon {
        fill: ${ICON.FILL.GREEN.BRIGHT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.GREEN.BRIGHT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.GREEN.BRIGHT};
        fill: ${ICON.FILL.GREEN.BRIGHT};
      }
    }

    .brightGrey {
      & .icon {
        fill: ${ICON.FILL.GREY.BRIGHT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.GREY.BRIGHT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.GREY.BRIGHT};
        fill: ${ICON.FILL.GREY.BRIGHT};
      }
    }

    .white {
      & .icon {
        fill: ${ICON.FILL.WHITE.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.WHITE.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.WHITE.DEFAULT};
        fill: ${ICON.FILL.WHITE.DEFAULT};
      }
    }

    .darkGrey {
      & .icon {
        fill: ${ICON.FILL.GREY.DARK};
      }

      & .stroke {
        stroke: ${ICON.STROKE.GREY.DARK};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.GREY.DARK};
        fill: ${ICON.FILL.GREY.DARK};
      }
    }

    .yellow {
      & .icon {
        fill: ${ICON.FILL.YELLOW.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.YELLOW.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.YELLOW.DEFAULT};
        fill: ${ICON.FILL.YELLOW.DEFAULT};
      }
    }

    .purple {
      & .icon {
        fill: ${ICON.FILL.PURPLE.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.PURPLE.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.PURPLE.DEFAULT};
        fill: ${ICON.STROKE.PURPLE.DEFAULT};
      }
    }

    .favorite {
      & .icon {
        fill: ${ICON.FILL.FAVORITE.DEFAULT};
      }

      & .stroke {
        stroke: ${ICON.STROKE.FAVORITE.DEFAULT};
        fill: none;
      }

      & .fillAndStroke {
        stroke: ${ICON.STROKE.FAVORITE.DEFAULT};
        fill: ${ICON.STROKE.FAVORITE.DEFAULT};
      }
    }

    .oldPaper {
      width: 16px;
      height: 19px;
      margin-left: -1px;
    }

    .horizontalEllipsis {
      width: 18px;
    }

    .noAllowedDefinition {
      fill: none !important;
      stroke: none !important;
    }

    .pageMenu.infoCircleFilled .icon,
    .pageMenu.stackFilled .icon,
    .pageMenu.taskSolid .icon {
      width: 18px;
    }

    .pageMenu.clockFilled .icon,
    .pageMenu.historyFilled .icon,
    .pageMenu.cogFilled .icon {
      width: 16px;
    }

    .pageMenu.ruler .icon {
      width: 23px;
    }

    .placeholder .icon {
      width: 50px;
      fill: ${PLACEHOLDER.ICON};
    }

    .big.placeholder .icon {
      width: 200px;
    }

    .small.placeholder .icon {
      width: 40px;
    }

    .darkGrey.placeholder .icon {
      fill: ${PLACEHOLDER.ICON_ALTERNATIVE_BACKGROUND};
    }

    .inline {
      display: inline-block;
    }

    .angleRight.icon {
      height: 10px;
      width: 5px;
    }
  `;
}

//#endregion

//#region <icon names>
export type IconNames =
  | 'add'
  | 'angleRight'
  | 'bin'
  | 'book'
  | 'calendar'
  | 'car'
  | 'check'
  | 'checkCircle'
  | 'circle'
  | 'clockFilled'
  | 'copy'
  | 'cross'
  | 'crossCircle'
  | 'cursor'
  | 'diamond'
  | 'drag'
  | 'folder'
  | 'group'
  | 'history'
  | 'horizontalEllipsis'
  | 'image'
  | 'inbox'
  | 'link'
  | 'linkRemove'
  | 'lock'
  | 'oldPaper'
  | 'pauseCircle'
  | 'pencil'
  | 'playCircle'
  | 'sandClock'
  | 'stackFilled'
  | 'star'
  | 'starFilled'
  | 'starHalfFilled'
  | 'stopWatch'
  | 'thumbsUp'
  | 'triangle'
  | 'user';

//#endregion

//#region <icons>
const icons: Record<IconNames, (className: string) => ReactNode> = {
  add(className: string): ReactElement {
    return <PlusSvg className={classNames(className, 'icon')} />;
  },
  angleRight(className: string): ReactElement {
    return <AngleRightSvg className={classNames(className, 'icon', 'strokeWidth2', 'stroke')} />;
  },
  bin(className: string): ReactElement {
    return <BinSvg className={classNames(className, 'icon')} />;
  },
  book(className: string): ReactElement {
    return <BookSvg className={classNames(className, 'icon')} />;
  },
  calendar(className: string): ReactElement {
    return <CalendarSvg className={classNames(className, 'icon')} />;
  },
  car(className: string): ReactElement {
    return <CarSvg className={classNames(className, 'icon')} />;
  },
  check(className: string): ReactElement {
    return <CheckSvg className={classNames(className, 'icon')} />;
  },
  checkCircle(className: string): ReactElement {
    return <CheckCircleSvg className={classNames(className, 'icon')} />;
  },
  circle(className: string): ReactElement {
    return <CircleSvg className={classNames(className, 'icon')} />;
  },
  clockFilled(className: string): ReactElement {
    return <ClockFilledSvg className={classNames(className, 'icon')} />;
  },
  copy(className: string): ReactElement {
    return <CopySvg className={classNames(className, 'icon')} />;
  },
  cross(className: string): ReactElement {
    return <CrossSvg className={classNames(className, 'icon', 'stroke', 'strokeWidth2')} />;
  },
  crossCircle(className: string): ReactElement {
    return <CrossCircleSvg className={classNames(className, 'icon')} />;
  },
  cursor(className: string): ReactElement {
    return <CursorSvg className={classNames(className, 'icon', 'heightLimitation')} />;
  },
  diamond(className: string): ReactElement {
    return <DiamondSvg className={classNames(className, 'icon')} />;
  },
  drag(className: string): ReactElement {
    return <DragSvg className={classNames(className, 'icon')} />;
  },
  folder(className: string): ReactElement {
    return <FolderSvg className={classNames(className, 'icon')} />;
  },
  group(className: string): ReactElement {
    return <GroupSvg className={classNames(className, 'icon', 'heightLimitation')} />;
  },
  history(className: string): ReactElement {
    return <HistorySvg className={classNames(className, 'icon')} />;
  },
  horizontalEllipsis(className: string): ReactElement {
    return (
      <HorizontalEllipsisSvg
        className={classNames(className, 'icon', 'horizontalEllipsis', 'fillAndStroke', 'strokeWidth3')}
      />
    );
  },
  image(className: string): ReactElement {
    return <ImageSvg className={classNames(className, 'icon')} />;
  },
  inbox(className: string): ReactElement {
    return <InboxSvg className={classNames(className, 'icon')} />;
  },
  link(className: string): ReactElement {
    return <LinkSvg className={classNames(className, 'icon')} />;
  },
  linkRemove(className: string): ReactElement {
    return <LinkRemoveSvg className={classNames(className, 'icon')} />;
  },
  lock(className: string): ReactElement {
    return <LockSvg className={classNames(className, 'icon')} />;
  },
  oldPaper(className: string): ReactElement {
    return <OldPaperSvg className={classNames(className, 'icon', 'oldPaper')} />;
  },
  pauseCircle(className: string): ReactElement {
    return <PauseCircleSvg className={classNames(className, 'icon')} />;
  },
  pencil(className: string): ReactElement {
    return <PencilSvg className={classNames(className, 'icon')} />;
  },
  playCircle(className: string): ReactElement {
    return <PlayCircleSvg className={classNames(className, 'icon')} />;
  },
  sandClock(className: string): ReactElement {
    return <SandClockSvg className={classNames(className, 'icon')} />;
  },
  stackFilled(className: string): ReactElement {
    return <StackFilledSvg className={classNames(className, 'icon')} />;
  },
  star(className: string): ReactElement {
    return <StarSvg className={classNames(className, 'icon')} />;
  },
  starFilled(className: string): ReactElement {
    return <StarFilledSvg className={classNames(className, 'icon')} />;
  },
  starHalfFilled(className: string): ReactElement {
    return <StarHalfFilledSvg className={classNames(className, 'icon')} />;
  },
  stopWatch(className: string): ReactElement {
    return <StopWatchSvg className={classNames(className, 'icon')} />;
  },
  thumbsUp(className: string): ReactElement {
    return <ThumbsUpSvg className={classNames(className, 'icon')} />;
  },
  triangle(className: string): ReactElement {
    return <TriangleSvg className={classNames(className, 'icon')} />;
  },
  user(className: string): ReactElement {
    return <UserSvg className={classNames(className, 'icon')} />;
  },
};
//#endregion

export type IconColors =
  | 'currentColor'
  | 'grey'
  | 'blue'
  | 'green'
  | 'darkGreen'
  | 'darkBlue'
  | 'white'
  | 'darkGrey'
  | 'light'
  | 'yellow'
  | 'orange'
  | 'purple'
  | 'red'
  | 'brightGreen'
  | 'brightGrey'
  | 'favorite';

export interface IconProps {
  className?: string;
  name: IconNames;
  color?: IconColors;
  noMargin?: boolean;
  marginLeft?: boolean;
  buttonIcon?: boolean;
  size?: 'normal' | 'big' | 'pageMenu' | 'small' | 'large';
  onClick?: MouseEventHandler<HTMLDivElement>;
  placeholder?: boolean;
  animated?: boolean;
  rotate?: null | 90 | 180 | 270;
  inline?: boolean;
}

/**
 * @deprecated Use the component Icon instead
 */
export default memo(function SvgIcon({
  className: passedThroughClassName,
  name,
  color,
  marginLeft,
  noMargin,
  buttonIcon,
  onClick,
  size = 'normal',
  placeholder,
  animated = false,
  rotate = null,
  inline = false,
}: IconProps): ReactElement {
  const {
    ANIMATIONS,
    COLORS: {ICON, PLACEHOLDER},
  } = useContext(flowStyleContext);
  const {className, styles} = getStyle(ICON, PLACEHOLDER, ANIMATIONS);
  return (
    <Flex
      className={classNames(
        className,
        passedThroughClassName,
        'iconWrapper',
        color,
        size,
        name,
        rotate ? {[`rotate${rotate}`]: true} : null,
        {animated, buttonIcon, marginLeft, noMargin, placeholder, clickAble: !!onClick, inline},
      )}
      onClick={onClick}
    >
      {icons[name](className)}
      {styles}
    </Flex>
  );
});
