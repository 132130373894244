import classnames from 'classnames';
import {ReactElement, ReactNode} from 'react';

export default function Text({
  children,
  ...rest
}: {
  bold?: boolean;
  children: ReactNode;
  italic?: boolean;
  smaller?: boolean;
  inline?: boolean;
}): ReactElement {
  return (
    <div className={classnames(rest)}>
      {children}
      {/*language=scss*/}
      <style jsx>{`
        .bold {
          font-weight: bold;
        }

        .inline {
          display: inline;
        }

        .italic {
          font-style: italic;
        }

        .smaller {
          font-size: 0.85em;
          line-height: 1.2em;
        }
      `}</style>
    </div>
  );
}
