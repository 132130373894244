import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import IsCompletedButton from '@octaved/flow/src/Components/WorkPackage/WorkflowStatus/IsCompletedButton';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {useIsPlanningPage} from '@octaved/planning/src/Hooks/UseIsPlanningPage';
import {simulationModeActiveSelector} from '@octaved/planning/src/Selectors/UiSelectors';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {NodeRight} from '@octaved/security/src/Authorization/Rights';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';

const nodeManageProgressRight: Record<
  EnumFlowNodeType.VALUE_WORK_PACKAGE | EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
  NodeRight
> = {
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'FLOW_NODE_WORK_PACKAGE_MANAGE_PROGRESS',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_PROGRESS',
};

interface NodeToggleDoneProps {
  node: WorkPackage | SubWorkPackage;
}

export default function NodeToggleDone({node}: NodeToggleDoneProps): ReactElement | null {
  const canManage = useIsGranted(nodeManageProgressRight[node.nodeType], node.id);

  const isInSimulationMode = useSelector(simulationModeActiveSelector);
  const buttonHidden = useIsPlanningPage() && isInSimulationMode;

  if (!canManage || node.isArchived || buttonHidden) {
    return null;
  }
  return <IsCompletedButton node={node} />;
}
