import {
  FLOW_INIT_LOAD_SUCCESS,
  FLOW_PATCH_USER_SETTINGS_FAILURE,
  FLOW_PATCH_USER_SETTINGS_REQUEST,
  FLOW_PATCH_USER_SETTINGS_SUCCESS,
} from '@octaved/flow/src/Modules/ActionTypes';
import {UserSettingsChangedEvent} from '@octaved/flow/src/Modules/Events';

import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {
  ConnectedServiceCreatedEvent,
  ConnectedServiceDeletedEvent,
  ConnectedServicePatchedEvent,
} from '@octaved/integration/src/Event/Events';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DeepPartial} from '@octaved/typescript/src/lib';
import {getInstanceUuid} from '@octaved/utilities';
import objectContains from '@octaved/validation/src/ObjectContains';
import {patchOrgUserSettings} from '../../config/routes';
import {OrgUserSettings, PatchableOrgUserSettings} from '../EntityInterfaces/OrgUserSettings';
import {currentOrgUserIdSelector, currentOrgUserSettingsSelector} from '../Selectors/CurrentOrgUserSelectors';

export const reducers = createReducerCollection<OrgUserSettings>({
  connectedServices: [],
});

reducers.add(FLOW_INIT_LOAD_SUCCESS, (state, action: InitAction) => {
  if (action.isInOrganization) {
    return action.response.result.orgUser.settings;
  }
  return state;
});

type PatchAction = ServerRequestAction<{
  data: Partial<PatchableOrgUserSettings>;
  type: typeof FLOW_PATCH_USER_SETTINGS_REQUEST;
}>;

reducers.add<PatchAction>(FLOW_PATCH_USER_SETTINGS_REQUEST, (state, {options: {data}}) => {
  return mergeStates(state, data);
});

reducers.add<
  ConnectedServiceCreatedEvent | ConnectedServiceDeletedEvent | ConnectedServicePatchedEvent | UserSettingsChangedEvent
>(
  [
    'integration.ConnectedServiceCreatedEvent',
    'integration.ConnectedServiceDeletedEvent',
    'integration.ConnectedServicePatchedEvent',
    'flow.UserSettingsChangedEvent',
  ],
  (state, {responsibleInstanceId, type, userSettings}) => {
    //the user settings are updated optimistically in the same instance:
    if (type !== 'flow.UserSettingsChangedEvent' || responsibleInstanceId !== getInstanceUuid()) {
      return userSettings;
    }
    return state;
  },
);

export const orgUserSettingsReducer = reducers.reducer;

export function patchUserSettings(data: DeepPartial<PatchableOrgUserSettings>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const settings = currentOrgUserSettingsSelector(getState());
    const currentUserId = currentOrgUserIdSelector(getState());
    if (!objectContains(settings, data)) {
      dispatch({
        [CALL_API]: {
          endpoint: patchOrgUserSettings,
          method: 'patch',
          options: {data, urlParams: {orgUserId: currentUserId}},
          types: {
            failureType: FLOW_PATCH_USER_SETTINGS_FAILURE,
            requestType: FLOW_PATCH_USER_SETTINGS_REQUEST,
            successType: FLOW_PATCH_USER_SETTINGS_SUCCESS,
          },
        },
      });
    }
  };
}
