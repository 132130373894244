import {
  TWButton,
  TWDialog,
  TWDialogBody,
  TWDialogContent,
  TWDialogFooter,
  TWDialogHeader,
  TWDialogTitle,
} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import LogoSvg from '../svgs/logo.svg?tsx';

export default function RevisionChangedPopup(): ReactElement {
  const {t} = useTranslation();
  return (
    <TWDialog open>
      <TWDialogContent size={'md'}>
        <LogoSvg />
        <TWDialogHeader noClose>
          <TWDialogTitle>{t('dialogs:revisionChanged.title')}</TWDialogTitle>
        </TWDialogHeader>
        <TWDialogBody>
          <Trans i18nKey={'dialogs:revisionChanged.description'} />
        </TWDialogBody>
        <TWDialogFooter>
          <TWButton colorScheme={'primary'} onClick={() => window.location.reload()} size={'lg'} variant={'solid'}>
            {t('dialogs:revisionChanged.reloadPage')}
          </TWButton>
        </TWDialogFooter>
      </TWDialogContent>
    </TWDialog>
  );
}
