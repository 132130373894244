import {Uuid} from '@octaved/typescript/src/lib';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {Clock} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import FlowOutlet from '../../Components/FlowOutlet';
import StandardInspector from '../../Drawer/StandardInspector/StandardInspector';
import MenuPage from '../../Pages/PageFrame/MenuPage';
import {PagesSubMenuOption} from '../../Pages/PagesSubMenu';
import {MenuRootItem} from '../FlowRoutes';
import LastVisitRedirect from '../LastVisitRedirect';
import {timesheetExtrenalInspectorRoutes} from './Inspectors/TimesheetExtrenalInspector';

const customWorkingTimesRoute = 'customWorkingTimes';
const weeklyWorkingTimesRoute = 'weeklyWorkingTimes';
const workingTimeMonitoringRoute = 'workingTimeMonitoring';
const workingTimeMonitoringTimePeriodRoute = 'timePeriod';
const workingTimeMonitoringUserRoute = 'user';
const unitInspectorRoute = 'unit';
const timesheetsExternalRoute = 'timesheetsExternal';

export function createCustomWorkingTimesInspectorRoute(unitId: Uuid): string {
  return `${unitInspectorRoute}/${unitId}`;
}

export function createWeeklyWorkingTimesInspectorRoute(unitId: Uuid): string {
  return `${unitInspectorRoute}/${unitId}`;
}

export function getWorkingTimeRoutesWithoutStandardInspector(): string[] {
  return [customWorkingTimesRoute, weeklyWorkingTimesRoute, workingTimeMonitoringRoute];
}

const CustomWorkingTimes = lazy(() => import('../../Pages/CustomWorkingTimes/CustomWorkingTimes'));
const CustomWorkingTimesInspector = lazy(
  () => import('../../Pages/CustomWorkingTimes/Inspector/CustomWorkingTimesInspector'),
);
const CustomWorkingTimesInspectorParent = lazy(
  () => import('../../Pages/CustomWorkingTimes/Inspector/CustomWorkingTimesInspectorParent'),
);
const WeeklyWorkingTimesInspector = lazy(
  () => import('../../Pages/WeeklyWorkingTimes/Inspector/WeeklyWorkingTimesInspector'),
);
const WeeklyWorkingTimesInspectorContext = lazy(
  () => import('../../Pages/WeeklyWorkingTimes/Inspector/WeeklyWorkingTimesInspectorContext'),
);
const WeeklyWorkingTimesInspectorHeader = lazy(
  () => import('../../Pages/WeeklyWorkingTimes/Inspector/WeeklyWorkingTimesInspectorHeader'),
);
const WeeklyWorkingTimes = lazy(() => import('../../Pages/WeeklyWorkingTimes/WeeklyWorkingTimes'));
const TimePeriod = lazy(() => import('../../Pages/WorkingTimeMonitoring/TimePeriod/WorkingTimeTimePeriod'));
const WorkingTimeUser = lazy(() => import('../../Pages/WorkingTimeMonitoring/User/WorkingTimeUser'));
const TimesheetsExternal = lazy(() => import('../../Pages/Projects/Pages/TimesheetsExternal/TimesheetsExternal'));

//#region menu
const workingTimeMonitoringMenuOptions: PagesSubMenuOption[] = [
  {
    name: 'workingTime:workingTime.page.timePeriod.menuName',
    path: workingTimeMonitoringTimePeriodRoute,
  },
  {
    name: 'workingTime:workingTime.page.user.menuName',
    path: workingTimeMonitoringUserRoute,
  },
];
//#endregion

export const workingTimeRoutes: RouteObject[] = [
  {
    children: [
      {
        children: [
          {
            element: <CustomWorkingTimesInspector />,
            path: '*',
          },
        ],
        element: <CustomWorkingTimesInspectorParent />,
        path: `${unitInspectorRoute}/:unitId`,
      },
    ],
    element: <CustomWorkingTimes />,
    path: `${customWorkingTimesRoute}/*`,
  },
  {
    children: [
      {
        children: [
          {
            element: <WeeklyWorkingTimesInspector />,
            path: '*',
          },
        ],
        element: (
          <WeeklyWorkingTimesInspectorContext>
            <StandardInspector headerTitle={<WeeklyWorkingTimesInspectorHeader />} />
          </WeeklyWorkingTimesInspectorContext>
        ),
        path: `${unitInspectorRoute}/:unitId`,
      },
    ],
    element: <WeeklyWorkingTimes />,
    path: `${weeklyWorkingTimesRoute}/*`,
  },
  {
    children: [
      {
        children: [
          {
            element: <TimePeriod />,
            path: `${workingTimeMonitoringTimePeriodRoute}/*`,
          },
          {
            element: <WorkingTimeUser />,
            path: `${workingTimeMonitoringUserRoute}/*`,
          },
        ],
        element: <MenuPage menuOptions={workingTimeMonitoringMenuOptions} />,
      },
      {path: '*', element: <LastVisitRedirect default={workingTimeMonitoringTimePeriodRoute} />},
    ],
    element: <FlowOutlet />,
    path: `${workingTimeMonitoringRoute}/*`,
  },
  {
    children: timesheetExtrenalInspectorRoutes,
    element: <TimesheetsExternal />,
    path: `${timesheetsExternalRoute}/*`,
  },
];

export const workingTimeMenu: MenuRootItem = {
  access: ({orgUserType}) => isInternalUserType(orgUserType),
  groupToken: 'pageMenu:workingTimeGroup',
  icon: Clock,
  id: 'workingTime',
  items: [
    {
      access: ({orgUserType}) => isInternalUserType(orgUserType),
      nameToken: 'pageMenu:customWorkingTimes',
      path: customWorkingTimesRoute,
    },
    {
      access: ({orgUserType}) => isInternalUserType(orgUserType),
      nameToken: 'pageMenu:weeklyWorkingTimes',
      path: weeklyWorkingTimesRoute,
    },
    {
      access: ({isGranted, settings: {useWorkingTimeTracking}}) =>
        useWorkingTimeTracking && isGranted('FLOW_GLOBAL_WORKING_TIME_TRACKING_READ_OTHER'),
      nameToken: 'pageMenu:workingTimeMonitoring',
      path: workingTimeMonitoringRoute,
    },
    {
      nameToken: 'pages:projects.subMenu.billingExternal',
      path: timesheetsExternalRoute,
    },
  ],
};
