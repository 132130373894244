import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {organizationSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {NIL} from '@octaved/utilities';
import {identityIdSelector} from '../Selectors/IdentitySelectors';

const storageKey = 'identity_last_organization_mapping';

type OrgIdMapping = Record<string, number | null | undefined>;

function getFromStorage(storage: Storage): OrgIdMapping | null {
  const raw = storage.getItem(storageKey);
  if (raw) {
    try {
      const parsed = JSON.parse(raw);
      if (parsed && typeof parsed === 'object') {
        return parsed;
      }
    } catch {
      //ignored
    }
  }
  return null;
}

function getStored(): OrgIdMapping {
  return getFromStorage(window.sessionStorage) || getFromStorage(window.localStorage) || {};
}

function store(identityId: Uuid, organizationId: number | null): void {
  const newValue = {...getStored(), [identityId]: organizationId};
  window.sessionStorage.setItem(storageKey, JSON.stringify(newValue));
  window.localStorage.setItem(storageKey, JSON.stringify(newValue));
}

let cacheHeaderValue: string | null = null;

/**
 * Returns either a single organization id if there is only one, to keep the header small, or the full mapping.
 */
function getOrganizationHeaderValue(): string {
  if (!cacheHeaderValue) {
    const stored = getStored();
    const filtered = Object.fromEntries(Object.entries(stored).filter(([_, v]) => typeof v === 'number')) as Record<
      string,
      number
    >;
    const values = Object.values(filtered);
    cacheHeaderValue = values.length === 1 ? values[0].toString() : values.length ? JSON.stringify(filtered) : '';
  }
  return cacheHeaderValue;
}

export function getOrganizationHeaders(): Record<string, string> {
  const value = getOrganizationHeaderValue();
  return value ? {['octaved-organization']: value} : {};
}

export function setXhrOrganizationHeader(xhr: XMLHttpRequest): void {
  const value = getOrganizationHeaderValue();
  if (value) {
    xhr.setRequestHeader('octaved-organization', value);
  }
}

export function storeCurrentOrganization(): ThunkAct<void> {
  return (_, getState) => {
    const state = getState();
    const currentOrgId = organizationSelector(state)?.id;
    if (currentOrgId) {
      const identityId = identityIdSelector(state);
      store(identityId, currentOrgId);
    }
  };
}

export function switchOrganization(
  organizationId: number,
  forceReload: boolean,
  target = '/',
  identityId?: Uuid,
): ThunkAct<void> {
  return (_, getState) => {
    const state = getState();
    const currentOrgId = organizationSelector(state)?.id;
    if (currentOrgId !== organizationId || forceReload) {
      const forIdentityId = identityId || identityIdSelector(state);
      if (forIdentityId !== NIL) {
        store(forIdentityId, organizationId);
      }
      window.location.href = target;
    }
  };
}

export function clearStoredOrganization(): void {
  window.sessionStorage.removeItem(storageKey);
  window.localStorage.removeItem(storageKey);
}
