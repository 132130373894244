import {isDevLocal} from '@octaved/env/src/Environment';
import {error} from '@octaved/env/src/Logger';
import {getState} from '@octaved/store/src/Store';
import {onEvent} from '@octaved/websocket';
import {get, set} from 'lodash';
import {isAction, UnknownAction} from 'redux';
import {FLOW_RECONNECT} from './ActionTypes';
import {FlowState} from './State';

interface ReconnectAction {
  type: typeof FLOW_RECONNECT;
}

//these paths are used to invalidate the state on reconnect
const invalidateStatePaths: string[] = [
  'ancestorBudgetSums.state',
  'billing.search.state',
  'currentOrgUser.rights.state',
  'customerWebhookSearch.state',
  'deprecatedUserIds.state',
  'entityStates.billing',
  'entityStates.inbox',
  'entityStates.kanbanBoard',
  'entityStates.milestone',
  'entityStates.node',
  'entityStates.nodeHistory',
  'entityStates.orgUser',
  'entityStates.planningBaseline',
  'entityStates.planningDate',
  'entityStates.planningDependency',
  'entityStates.taskStatusHistory',
  'entityStates.timeRecord',
  'entityStates.userGroup',
  'entityStates.workloadState',
  'entityStates.workPackageBilling',
  'identity.accessKeys.state',
  'identity.accessTokens.state',
  'inboxSearch.searchState',
  'initDataState',
  'integration.calendarEvents.searchState',
  'minMaxPlanningDateInSubtrees.state',
  'nodeSearch.searchState',
  'nodeTreeState',
  'projectControlling.criticalTimePrognosis.state',
  'projectControlling.degreeOfCompletion.state',
  'projectControlling.dueDatePerNode.state',
  'projectControlling.lastActivities.state',
  'projectControlling.lastActivitiesPerWorkPackage.state',
  'projectControlling.trackedTimePerDay.state',
  'projectControlling.trackedTimePerWorkPackage.state',
  'projectControlling.trackedTimeQuotas.state',
  'restoreFromTrash.nodeCounts.state',
  'restoreFromTrash.nodes.state',
  'restoreFromTrash.timeRecords.state',
  'roles.accessibleTimeTrackingsStates',
  'roles.globalRoleAssignmentStates',
  'roles.nodeGuestPermissionRoleAssignmentsStates',
  'roles.nodeInternalPermissionRoleAssignmentsStates',
  'roles.nodeProjectRoleAssignmentsStates',
  'roles.rights.state',
  'roles.roleRights.guest.state',
  'roles.roleRights.internal.state',
  'roles.rolesState',
  'statistics.bookedHoursByBillingType.state',
  'statistics.bookingUserCounts.state',
  'statistics.chargableHoursState',
  'statistics.dailyAverageUserBookedHours.state',
  'statistics.nonBillableHoursByReasonByDate.state',
  'statistics.subtreeStats.state',
  'statistics.taskBurnDown.state',
  'statistics.timeEffortPriceSumsPerBillingType.state',
  'timeRecordSearchState',
  'trackedTimeSumsInSubtree.state',
  'unitLists.states.group',
  'unitLists.states.user',
  'userGroups.states',
  'userTimeSheetInPeriodSums.state',
  'workingTime.daysState',
  'workingTime.entriesForUsersOnDatesState',
  'workTime.state',
];
//these paths are ignored when searching for state paths
const ignoredPaths: string[] = ['entityStates'];
//these paths and subpaths are ignored when searching for state paths
const ignoredSubPaths: string[] = [
  'currentOrgUser.settings',
  'entityStates.planningBaselineHistory', //as it is history it does not need to be updated
  'nodeTree',
  'ui.nodeTables.pages', //all have a "gridStates" object
];

function validateInvalidateStatePathConfig(): void {
  const state = getState<Record<string, unknown>>();

  function checkIfPathExists(paths: string[]): void {
    for (const path of paths) {
      const target = get(state, path);
      if (!target) {
        error(`The path '${path}' which is configured is not available in the state. Please check the configuration.`);
      }
    }
  }

  checkIfPathExists(invalidateStatePaths);
  checkIfPathExists(ignoredPaths);
  checkIfPathExists(ignoredSubPaths);

  function searchForState(subState: Record<string, unknown>, subPath: string): void {
    for (const key in subState) {
      if (subState.hasOwnProperty(key)) {
        const value = subState[key];
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          const currentPath = `${subPath}${key}`;
          if (!ignoredSubPaths.includes(currentPath)) {
            if (
              currentPath.match(/state/i) &&
              !(
                ignoredPaths.some((ignoredPath) => ignoredPath.startsWith(currentPath)) ||
                invalidateStatePaths.some((invalidateStatePath) => invalidateStatePath.startsWith(currentPath))
              )
            ) {
              error(`The path '${currentPath}' is not configured to be invalidated or ignored.`);
            }
            if (!invalidateStatePaths.includes(currentPath)) {
              searchForState(value as Record<string, unknown>, currentPath + '.');
            }
          }
        }
      }
    }
  }

  searchForState(state, '');
}

if (isDevLocal) {
  setTimeout(validateInvalidateStatePathConfig, 2500);
}

function isReconnectAction(action: unknown): action is ReconnectAction {
  return isAction(action) && action.type === FLOW_RECONNECT;
}

export function reconnectReducer(state: FlowState, action: UnknownAction): FlowState {
  if (isReconnectAction(action)) {
    const newState = {...state};

    for (const path of invalidateStatePaths) {
      const target = get(newState, path);
      if (target) {
        const subPaths = path.split('.');
        subPaths.pop(); //remove last one as it is the target
        let currentPath = '';
        for (const subPath of subPaths) {
          currentPath += '.' + subPath;
          const current = get(newState, currentPath);
          if (current && typeof current === 'object' && !Array.isArray(current)) {
            set(newState, subPath, {...current});
          }
        }
        set(newState, path, {});
      }
    }
    return newState;
  }
  return state;
}

onEvent('eventsWereMissed', () => ({type: FLOW_RECONNECT}));
