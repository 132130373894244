import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {EntityStates} from '@octaved/store/src/EntityState';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {Billings} from '../../../EntityInterfaces/Billing/Billings';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {FlowState} from '../../State';
import {customerEntitiesSelector} from '../CustomerSelectors';
import {getNodeSearchQueryResultsSelector} from '../NodeSearchSelectors';
import {getNodeAncestrySelector} from '../NodeTreeSelectors';
import {journeyBillingModeSelector, settingsSelector} from '../SettingSelectors';
import {billingPeriodSelector} from '../UiPages/Projects/BillingSelectors';

export const billingSelector = (state: FlowState): Billings => state.entities.billing;
export const billingStateSelector = (state: FlowState): EntityStates => state.entityStates.billing;

export const hasTransmitBillingWebhookSelector = createSelector(
  settingsSelector,
  ({webhooks: {transmitBilling}}) => !!transmitBilling,
);

export const canTransmitBillingSelector = createSelector(
  hasTransmitBillingWebhookSelector,
  isGrantedSelector,
  (hasWebhook, isGranted) =>
    memoize((projectId: Uuid | null): boolean => {
      return !!(hasWebhook && projectId && isGranted('FLOW_NODE_TRANSMIT_BILLINGS', projectId, 'allInTree+self'));
    }),
);

export const wpsWithFinalBillingQuery: NodeSearchCondition = ['wpHasFinalBillings'];

export const wpsWithBillingInPeriodQuerySelector = createSelector(
  billingPeriodSelector,
  ({from, to}): NodeSearchCondition => {
    return ['wpHasBillingsInPeriod', `${from}-${to}`];
  },
);

export const wpsWithFinalBillingSelector = createSelector(
  getNodeSearchQueryResultsSelector,
  (getNodeSearchQueryResults): ReadonlySet<Uuid> => {
    return new Set(getNodeSearchQueryResults(wpsWithFinalBillingQuery));
  },
);

export const wpsWithBillingInPeriodSelector = createSelector(
  getNodeSearchQueryResultsSelector,
  wpsWithBillingInPeriodQuerySelector,
  (getNodeSearchQueryResults, query): ReadonlySet<Uuid> => {
    return new Set(getNodeSearchQueryResults(query));
  },
);

export const wpsWithOpenTimeTrackingQuery: NodeSearchCondition = {
  and: [['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], withAncestors(['trRefHasOpenTimeTrackingRecords'], true)],
};

export const wpsWithOpenTimeTrackingInPeriodQuerySelector = createSelector(
  billingPeriodSelector,
  ({from, to}): NodeSearchCondition => {
    return {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        withAncestors(['trRefHasOpenTimeTrackingRecordsInDateRange', `${from}-${to}`], true),
      ],
    };
  },
);

export const wpsWithOpenRecordsInPeriodSelector = createSelector(
  getNodeSearchQueryResultsSelector,
  wpsWithOpenTimeTrackingInPeriodQuerySelector,
  (getNodeSearchQueryResults, query): ReadonlySet<Uuid> => {
    return new Set(getNodeSearchQueryResults(query));
  },
);

export const wpsWithOpenRecordsSelector = createSelector(
  getNodeSearchQueryResultsSelector,
  (getNodeSearchQueryResults): ReadonlySet<Uuid> => {
    return new Set(getNodeSearchQueryResults(wpsWithOpenTimeTrackingQuery));
  },
);

export const getJourneyBillingModeForNodeSelector = createSelector(
  journeyBillingModeSelector,
  customerEntitiesSelector,
  getNodeAncestrySelector,
  (system, customers, getNodeAncestry) => (nodeId: MaybeUuid) => {
    const {project} = getNodeAncestry(nodeId, true);
    return customers[project?.flowCustomer ?? '']?.journeyBillingMode ?? system;
  },
);
