export const generalRoute = 'general';
export const statusRoute = 'status';
export const timeTrackingRoute = 'timeTracking';
export const responsibilityRoute = 'responsibility';
export const boardRoute = 'board';
export const offerSumsRoute = 'offerSums';
export const planningRoute = 'planning';
export const milestonesRoute = 'milestones';
export const tasksRoute = 'tasks';
export const timeAndMoneyRoute = 'timeAndMoney';
export const quotasRoute = 'quotas';
export const billingsRoute = 'billings';
export const advancedRoute = 'advanced';
export const customerRoute = 'customer';
export const historyRoute = 'history';
export const moreRoute = 'more';
export const guestUserRoute = 'guestUser';

export const subInspectorRoute = 'i';
