import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {isGroup} from '@octaved/flow/src/Node/NodeIdentifiers';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {HelpPopup, ReliabilityMeter, ReliabilityMeterTokens} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import NodeScopeDuration from './Components/Scope/NodeScopeDuration';
import NodeScopeEffort from './Components/Scope/NodeScopeEffort';
import NodeScopeHelp from './Components/Scope/NodeScopeHelp';
import NodeScopeInvolvedUsers from './Components/Scope/NodeScopeInvolvedUsers';
import {useSums} from './Components/Scope/Sums';

const labels = {
  group: 'nodeDetails:field.nodeScope.label.group',
  project: 'nodeDetails:field.nodeScope.label.project',
};

const reliabilityLabels: ReliabilityMeterTokens = {
  all: 'nodeDetails:field.nodeScope.reliabilityMeter.all',
  default: 'nodeDetails:field.nodeScope.reliabilityMeter.default',
  toolTip: 'nodeDetails:field.nodeScope.reliabilityMeter.toolTip',
};

export interface NodeScopeProps {
  node: Project | Group;
}

export function NodeScope({frame: Frame, node}: NodeScopeProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const wpsQuery: NodeSearchCondition = {
    and: [withDescendants(node.id, true), ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE]],
  };
  const [{ids: allWpIds}, {ids: nonOfferIds}, {ids: offerIds}] = useCombinedNodeSearches(
    {},
    wpsQuery,
    {and: [wpsQuery, {not: ['wpIsOffer']}]},
    {and: [wpsQuery, ['wpIsOffer']]},
  );
  const nonOfferCount = nonOfferIds.length;
  const offerCount = offerIds.length;

  const nonOfferSums = useSums(nonOfferIds);
  const offerSums = useSums(offerIds);

  return (
    <Frame
      labelToken={labels[node.nodeType]}
      labelAddition={<HelpPopup content={<NodeScopeHelp />} position={'bottom right'} contentClassName={'max-w-3xl'} />}
    >
      <div className={'grid grid-cols-[max-content_auto] gap-x-5'}>
        <div>
          {t(
            isGroup(node) && node.groupType === EnumFlowGroupType.VALUE_SPRINT
              ? 'nodeDetails:field.nodeScope.userStories'
              : 'nodeDetails:field.nodeScope.workPackages',
          )}
          :
        </div>
        <div>
          {nonOfferCount}
          {offerCount > 0 && ` (${t('nodeDetails:field.nodeScope.andNOffers', {count: offerCount})})`}
        </div>

        <div>{t('nodeDetails:field.nodeScope.usersInvolved')}:</div>
        <NodeScopeInvolvedUsers workPackageIds={allWpIds} />

        <div>{t('nodeDetails:field.nodeScope.duration')}:</div>
        <NodeScopeDuration node={node} />

        {nonOfferSums.priceIsReadable && (
          <>
            <div>{t('nodeDetails:field.nodeScope.financial')}:</div>
            <div>
              {nonOfferSums.price > 0 ? formatDecimal(nonOfferSums.price, 0) + ' €' : '-'}
              {offerSums.price > 0
                ? ` (${t('nodeDetails:field.nodeScope.andPriceInOffers', {price: formatDecimal(offerSums.price, 0) + ' €'})})`
                : ''}
            </div>
          </>
        )}

        <NodeScopeEffort label={'nodeDetails:field.nodeScope.effort'} sums={nonOfferSums} />
        {offerSums.price > 0 && (
          <NodeScopeEffort label={'nodeDetails:field.nodeScope.effortInOffers'} sums={offerSums} />
        )}
      </div>

      <ReliabilityMeter
        count={nonOfferSums.countHasMaxEffort}
        hideZero
        tokens={reliabilityLabels}
        total={nonOfferCount}
      />
    </Frame>
  );
}
