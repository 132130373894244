import {
  getOrgWorkMinutesAtDateSelector,
  GetWorkingTimeAtDate,
} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {addWorkdays, workdays} from '../../Calculations/WorkdayCalculations';
import {PlanningDate} from '../../EntityInterfaces/PlanningDates';
import {PlanningRealizationNode} from '../../EntityInterfaces/PlanningRealizationNode';
import {getPlanningPredecessorsSelector, TYPE_FF} from '../../Selectors/PlanningDependencySelectors';

export interface DurationPatch {
  (plannedStart: DateTimeStr, plannedEnd: DateTimeStr): void;
}

function calculateDuration(
  plannedStart: DateTimeStr | null,
  plannedEnd: DateTimeStr | null,
  getWorkingTimeAtDate: GetWorkingTimeAtDate,
  oldDuration = 0,
): number {
  let newDuration = oldDuration;
  if (plannedEnd && plannedStart) {
    const duration = workdays(
      fromIsoDateTimeFormat(plannedStart),
      fromIsoDateTimeFormat(plannedEnd),
      getWorkingTimeAtDate,
    );
    newDuration = duration;
  } else if (!plannedEnd && !plannedStart) {
    newDuration = 0;
  }
  return newDuration;
}

function useOnChangeDuration(): (
  nodeId: Uuid,
  duration: number,
  plannedStart: DateTimeStr,
  plannedEnd: DateTimeStr,
  patch: DurationPatch,
  isFirst: boolean,
  isLast: boolean,
) => void {
  const getWorkHoursAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const getPlanningPredecessors = useSelector(getPlanningPredecessorsSelector);
  return useCallback(
    (
      nodeId: Uuid,
      duration: number,
      plannedStart: DateTimeStr,
      plannedEnd: DateTimeStr,
      patch: DurationPatch,
      _isFirst,
      isLast,
    ) => {
      let newPlannedStart = plannedStart;
      let newPlannedEnd = plannedEnd;
      const dependencies = getPlanningPredecessors(nodeId, false);
      const hasEndsWithDependency = dependencies.some(({type}) => type === TYPE_FF);

      if (hasEndsWithDependency && isLast) {
        newPlannedStart = toIsoDateTimeFormat(
          addWorkdays(fromIsoDateTimeFormat(plannedEnd), -duration + 1, getWorkHoursAtDate),
        );
      } else {
        newPlannedEnd = toIsoDateTimeFormat(
          addWorkdays(fromIsoDateTimeFormat(plannedStart), duration - 1, getWorkHoursAtDate),
        );
      }
      patch(newPlannedStart, newPlannedEnd);
    },
    [getWorkHoursAtDate, getPlanningPredecessors],
  );
}

export function useDuration(
  node: PlanningRealizationNode,
  planningDate: PlanningDate,
  patch: DurationPatch,
  isFirst: boolean,
  isLast: boolean,
): {
  duration: number;
  onChange(val: number): void;
} {
  const [duration, setDuration] = useState(0);
  const getWorkHoursAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const onChangeDuration = useOnChangeDuration();

  const onChange = useCallback(
    (duration: number) => {
      onChangeDuration(node.id, duration, planningDate.plannedStart, planningDate.plannedEnd, patch, isFirst, isLast);
      setDuration(duration);
    },
    [onChangeDuration, node.id, planningDate.plannedStart, planningDate.plannedEnd, patch, isFirst, isLast],
  );

  useEffect(() => {
    setDuration((oldDuration) => {
      return calculateDuration(planningDate.plannedStart, planningDate.plannedEnd, getWorkHoursAtDate, oldDuration);
    });
  }, [planningDate.plannedEnd, planningDate.plannedStart, getWorkHoursAtDate]);

  return {
    duration,
    onChange,
  };
}
