import {mergeStates} from '@octaved/store/src/MergeStates';
import {type ThunkDispatch} from '@octaved/store/src/Store';
import {type DeepPartial} from '@octaved/typescript/src/lib';
import {patchUserSettings} from '@octaved/users/src/Modules/OrgUserSettings';
import {uiSyncSelector} from '@octaved/users/src/Selectors/OrgUserSettingSelectors';
import {type WebsocketEvent} from '@octaved/websocket';
import {throttle} from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import {type AnyAction, type Middleware} from 'redux';
import {FLOW_INIT_LOAD_SUCCESS} from '../Modules/ActionTypes';
import {uiSelector} from '../Modules/Selectors/UiSelectors';
import {type FlowState} from '../Modules/State';
import {type UiState} from '../Modules/Ui';

const lastData = Symbol('lastData');

export interface UserSettingsMiddlewareConfig {
  path: string;
  [lastData]?: unknown;
}

let uiPaths: UserSettingsMiddlewareConfig[] = [];

export function registerUserSettingsMiddlwareConfigs(cfgs: UserSettingsMiddlewareConfig[]): void {
  uiPaths = cfgs;
}

let prevUiSync: DeepPartial<UiState> | undefined;

export function userSettingsMiddleWareRootReducer(state: FlowState, action: AnyAction): FlowState {
  let newState = state;
  if (action.type === FLOW_INIT_LOAD_SUCCESS || action.type === 'flow.UserSettingsChangedEvent') {
    const uiSync = uiSyncSelector(state) as DeepPartial<UiState> | undefined;
    if (uiSync && prevUiSync !== uiSync) {
      const patch: DeepPartial<UiState> = {};
      let changed = false;
      uiPaths.forEach((cfg) => {
        const last = prevUiSync && get(prevUiSync, cfg.path);
        const next = get(uiSync, cfg.path);
        if (typeof next !== 'undefined' && !isEqual(last, next)) {
          set(patch, cfg.path, next);
          changed = true;
        }
      });
      if (changed) {
        newState = mergeStates(state, {ui: patch});
      }
    }
    prevUiSync = uiSync;
  }
  return newState;
}

let prevUi: UiState;
let nextUiPatch: DeepPartial<UiState> = {};

const patchUserSettingsThrottled = throttle(
  (dispatch: ThunkDispatch) => {
    if (Object.keys(nextUiPatch).length) {
      dispatch(patchUserSettings({uiSync: nextUiPatch}));
      nextUiPatch = {};
    }
  },
  100,
  {leading: false},
);

const UserSettingsMiddleware: Middleware<Record<string, unknown>, FlowState> = (store) => {
  return (next) => {
    return (action) => {
      const nextResult = next(action);

      //Only sync to server if the action is local (not from a websocket event):
      const isLocalAction = !!action && typeof action === 'object' && !(action as WebsocketEvent).responsibleInstanceId;

      const ui = uiSelector(store.getState());
      if (prevUi && prevUi !== ui) {
        uiPaths.forEach((cfg) => {
          const nextValue = get(ui, cfg.path);
          if (isLocalAction) {
            const lastValue = cfg[lastData];
            if (
              typeof lastValue !== 'undefined' &&
              lastValue !== nextValue &&
              JSON.stringify(lastValue) !== JSON.stringify(nextValue)
            ) {
              set(nextUiPatch, cfg.path, nextValue);
            }
          }
          cfg[lastData] = nextValue;
        });
        if (isLocalAction) {
          patchUserSettingsThrottled(store.dispatch);
        }
      }
      prevUi = ui;

      return nextResult;
    };
  };
};
export default UserSettingsMiddleware;
