import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {generateLargeViewUrl} from '@octaved/flow/src/Routing/Routes/Node';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWButton} from '@octaved/ui';
import {type ComponentType, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {tasksRoute} from '../../../../LargeView/RoutePaths';
import Section from '../../Section';
import OfferTasks from '../Common/OfferTasks';
import {NonOfferTasks} from './Components/NonOfferTasks';

interface NodeTasksSectionProps {
  frame?: ComponentType<{labelToken: string}>;
  node: NodeType;
  showReliabilityMeter?: boolean;
}

export function NodeTasksSection({
  frame: Frame = Section,
  node,
  showReliabilityMeter,
}: NodeTasksSectionProps): ReactElement | null {
  const {t} = useTranslation();
  const {workPackage} = useNodeAncestry(node.id, true);
  if (!useIsGranted('FLOW_NODE_TASK_READ_BASIC', node.id)) {
    return null;
  }
  return (
    <Frame labelToken={'nodeDetails:field.tasks.label'}>
      {!workPackage || !workPackage.isOffer ? (
        <NonOfferTasks node={node} showReliabilityMeter={showReliabilityMeter} />
      ) : (
        <OfferTasks node={node} showReliabilityMeter={showReliabilityMeter} />
      )}

      <div className={'mt-1 flex'}>
        <Link to={generateLargeViewUrl(node.nodeType, node.id, tasksRoute)}>
          <TWButton variant={'solid'} size={'md'} colorScheme={'primary'}>
            {t('nodeDetails:field.tasks.openTasks')}
          </TWButton>
        </Link>
      </div>
    </Frame>
  );
}
