import {Uuid} from '@octaved/typescript/src/lib';
import {CalendarContext} from '../../../Calendar/Context/CalendarContext';
import {RootContainer, RootContainerProps} from '../../../RootContainer';
import {Bar} from '../../Bar';
import {IconListBarPlugin} from './IconListBarPlugin';

export interface BarIconProps<CTX extends CalendarContext = CalendarContext> extends RootContainerProps<CTX> {
  bar: Bar;
}

export class BarIcon<CTX extends CalendarContext = CalendarContext> extends RootContainer<CTX> {
  protected visible = false;
  #iconList: IconListBarPlugin | null = null;
  readonly #bar: Bar;

  constructor(props: BarIconProps<CTX>) {
    super(props);
    this.#bar = props.bar;
  }

  init(iconList: IconListBarPlugin): void {
    this.#iconList = iconList;
  }

  protected notifyIconList(): void {
    this.#iconList?.onIconsChanges();
  }

  protected getIconY(): number {
    return (this.#bar.height - this.height) / 2;
  }

  get isVisible(): boolean {
    return this.visible;
  }

  get height(): number {
    return this.#bar.height - 2;
  }

  get nodeId(): Uuid {
    return this.#bar.nodeId;
  }
}
