import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {useSingleSubtreeStat} from '@octaved/flow/src/Modules/Statistics/SubtreeStats';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWProgress} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import NodeTasksReliabilityMeter from '../../../Controlling/Common/Components/NodeTasks/NodeTasksReliabilityMeter';
import Placeholder from '../../../PlaceholderText';
import ExceedsTaskEffortFlame from './ExceedsTaskEffortFlame';
import TaskEffortExceedsTimeLimitFlame from './TaskEffortExceedsTimeLimitFlame';

interface NonOfferTasksProps {
  node: NodeType;
  showReliabilityMeter?: boolean;
}

export function NonOfferTasks({node, showReliabilityMeter}: NonOfferTasksProps): ReactElement | null {
  const {t} = useTranslation();
  const stats = useSingleSubtreeStat('taskCountsAndTimeSums', node.id);
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const canReadTaskEffort = useIsGranted('FLOW_NODE_TASK_READ_EFFORT', node.id);
  if (!stats) {
    return null;
  }
  return (
    <>
      <div className={'grid grid-cols-progressWithLabel items-center gap-x-3 gap-y-1'}>
        <TWProgress progress={(stats.completeCount / stats.totalCount) * 100} />
        {stats.totalCount > 0 && (
          <div>
            {t('nodeDetails:field.tasks.tasksDone', {
              count: stats.totalCount,
              countFormatted: formatDecimal(stats.totalCount),
              value: formatDecimal(stats.completeCount),
            })}
          </div>
        )}
        {stats.totalCount === 0 && <Placeholder text={'nodeDetails:field.tasks.noTask'} />}

        {canReadTaskEffort && (
          <>
            <TWProgress progress={(stats.completeTimeSum / stats.totalTimeSum) * 100} />
            <div>
              {stats.totalTimeSum > 0 && (
                <div className={'flex items-center gap-x-2'}>
                  {t('nodeDetails:field.tasks.tasksHoursDone', {
                    count: stats.totalTimeSum,
                    countFormatted: formatDecimal(stats.totalTimeSum, stats.totalTimeSum < 100 ? 2 : 0),
                    value: formatDecimal(stats.completeTimeSum, stats.completeTimeSum < 100 ? 2 : 0),
                  })}
                  {useTimeTracking && <ExceedsTaskEffortFlame node={node} />}
                  {node.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE && (
                    <TaskEffortExceedsTimeLimitFlame node={node} totalTaskSum={stats.totalTimeSum} />
                  )}
                </div>
              )}
              {stats.totalCount !== 0 && stats.totalTimeSum === 0 && (
                <Placeholder text={'nodeDetails:field.tasks.noTaskHours'} />
              )}
              {stats.totalCount === 0 && stats.totalTimeSum === 0 && <div>&nbsp;</div>}
            </div>
          </>
        )}
      </div>

      {canReadTaskEffort && showReliabilityMeter && <NodeTasksReliabilityMeter className={'mt-1'} stats={stats} />}
    </>
  );
}
