import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {PlanningDate} from '@octaved/node-details/src/Components/Fields/General/Values/NodePlanningValue';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn} from '@octaved/ui';
import dayjs from 'dayjs';
import {Calendar, CheckCircle, Flame, XCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getMinMaxPlanningDatesSelector} from '../../../Selectors/PlanningDateSelectors';

interface PredecessorStatusProps {
  nodeId: Uuid;
}

export default function PredecessorStatus({nodeId}: PredecessorStatusProps): ReactElement {
  const {t} = useTranslation();
  const predecessor = useSelector(getNodeSelector)(nodeId);
  const {plannedEnd, plannedStart} = useSelector((s: FlowState) => getMinMaxPlanningDatesSelector(s)(nodeId));
  const isDone =
    (isWorkPackage(predecessor) && predecessor.isCompleted) ||
    (isTask(predecessor) && predecessor.status === EnumFlowTaskStatus.VALUE_COMPLETE);

  const DoneIcon = isDone ? CheckCircle : XCircle;
  const isOverdue = !isDone && Boolean(plannedEnd && dayjs().isAfter(plannedEnd, 'day'));

  return (
    <div className={'col-span-4 -mt-1 flex items-center gap-x-6 text-sm'}>
      <div className={cn('flex items-center gap-x-1.5', isOverdue && 'text-red-700')}>
        <DoneIcon
          className={cn('size-3.5 text-slate-500', isOverdue && 'text-red-700', isDone && 'text-emerald-600')}
        />
        {t(isDone ? 'general:nodeStatus.isCompleted.label' : 'general:nodeStatus.isNotCompleted.label')}
      </div>
      {plannedStart && plannedEnd && (
        <div className={cn('flex items-center gap-x-1.5', isOverdue && 'text-red-700')}>
          <Calendar className={cn('size-3.5 text-slate-500', isOverdue && 'text-red-700')} />
          <PlanningDate plannedStart={plannedStart} plannedEnd={plannedEnd} nodeId={nodeId} />
          {isOverdue && <Flame fill={'#b91c1c'} stroke={'none'} className={'size-4'} />}
        </div>
      )}
    </div>
  );
}
