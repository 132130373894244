import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {SingleSelectionUiState} from '../../Pages/Components/LeftDrawer/Selection';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';
import {initialLeftDrawerUiState, LeftDrawerUiState} from '../Ui/LeftDrawer';

export interface OrganizeBoardsGroupsUiState {
  leftDrawer: LeftDrawerUiState & SingleSelectionUiState;
  selectedKanbanBoard: Uuid | null;
}

export const initialOrganizeBoardsGroupsUiState: OrganizeBoardsGroupsUiState = {
  leftDrawer: {
    ...initialLeftDrawerUiState,
    selected: null,
  },
  selectedKanbanBoard: null,
};

export function patchOrganizeBoardsGroupsUiState(
  organizeBoardsGroups: DeepPartial<OrganizeBoardsGroupsUiState>,
): ThunkAct<void> {
  return setUiState({pages: {organizeBoardsGroups}});
}

export function setOrganizeBoardsGroupsKanbanBoard(selectedKanbanBoard: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsGroupsUiState({selectedKanbanBoard});
}

export function usePatchOrganizeBoardsGroupsUiState(): (patch: DeepPartial<OrganizeBoardsGroupsUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((patch) => dispatch(patchOrganizeBoardsGroupsUiState(patch)), [dispatch]);
}

export function patchOrganizeBoardsGroupsLeftDrawerUiState(
  patch: Partial<OrganizeBoardsGroupsUiState['leftDrawer']>,
): ThunkAct<void> {
  return patchOrganizeBoardsGroupsUiState({leftDrawer: patch});
}

export function setOrganizeBoardsGroupsSelectedProject(selected: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsGroupsLeftDrawerUiState({selected});
}
