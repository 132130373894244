import {AuditProject} from '../../../../EntityInterfaces/Audit/AuditProject';
import BooleanCell from '../Table/Cells/BooleanCell';
import type {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {pidColumns} from './Pid';

const config: AuditTableConfiguration<AuditProject> = {
  columns: [
    ...pidColumns,
    createColumnDef('color', 'systemSettings:audit.tables.project.colorHeader'),
    createColumnDef('enforceMaxEffort', 'systemSettings:audit.tables.project.enforceMaxEffortHeader'),
    createColumnDef('maxEffort', 'systemSettings:audit.tables.project.maxEffortHeader'),
    createColumnDef(
      'showMaxEffortInDays',
      'systemSettings:audit.tables.project.showMaxEffortInDaysHeader',
      BooleanCell,
    ),
    createColumnDef('maxPrice', 'systemSettings:audit.tables.project.maxPriceHeader'),
  ],
  tableIdent: 'projects',
  tableNameTranslationToken: 'systemSettings:audit.projectTableName',
};
export default config;
