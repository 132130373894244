import {ReactElement} from 'react';
import Section from '../../../Components/Fields/Section';
import NodeStatus from '../../../Components/Fields/Settings/Common/NodeStatus';
import ArchiveNodeSection from '../../../Components/Fields/Settings/Section/ArchiveNodeSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {SubWorkPackageDetailsProps} from './General';

export default function Settings({node}: SubWorkPackageDetailsProps): ReactElement {
  return (
    <InspectorViewFrame help={'nodeDetails:view.settings.help'}>
      <NodeStatus frame={Section} node={node} />
      <ArchiveNodeSection node={node} />
    </InspectorViewFrame>
  );
}
