import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton, Tooltip} from '@octaved/ui';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {Star} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StrictPopupProps} from 'semantic-ui-react';
import {subscribeToNode, unsubscribeFromNode} from '../../Modules/FavoriteNodes';
import {useCombinedNodeSearch, useNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {isProject, isSubWorkPackage, isWorkPackage} from '../../Node/NodeIdentifiers';
import {getIsResponsible} from '../../Modules/Selectors/NodeSearchSelectors';
import {nodeEntitySelector} from '../../Modules/Selectors/NodeSelectors';
import {FlowState} from '../../Modules/State';

interface FavoriteStarParams {
  nodeId: Uuid;
  toolTipPosition?: StrictPopupProps['position'];
  toolTipOverride?: string;
}

export default function FavoriteStar({
  nodeId,
  toolTipPosition = 'top center',
  toolTipOverride,
}: FavoriteStarParams): ReactElement | null {
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const {hasLoadedOnce: isFavoriteLoadedOnce, nodeIds: favoriteNodeIds} = useNodeSearch('favorite', currentUserId);
  const {hasLoadedOnce: isAssignedLoadedOnce, nodeIds: executiveNodeIds} = useCombinedNodeSearch(
    getIsResponsible(currentUserId),
  );
  const hasLoadedOnce = isFavoriteLoadedOnce && isAssignedLoadedOnce;
  const isFavorite = useMemo(() => favoriteNodeIds.includes(nodeId), [favoriteNodeIds, nodeId]);
  const isAssigned = useMemo(() => executiveNodeIds.includes(nodeId), [executiveNodeIds, nodeId]);
  const node = useSelector((s: FlowState) => nodeEntitySelector(s)[nodeId]);
  const dispatch = useDispatch();
  const nodeIsProject = isProject(node);
  const nodeIsWpOrSwp = isWorkPackage(node) || isSubWorkPackage(node);

  const [icon, pidStatusDescription] = useMemo<[ReactElement, string]>(() => {
    let icon: ReactElement = <Star />;
    let translation = 'general:pid.favoriteStar.noFavorite';
    if (isFavorite) {
      icon = <Star fill={'currentColor'} />;
      translation = 'general:pid.favoriteStar.favorite';
    } else if (nodeIsWpOrSwp && isAssigned) {
      icon = (
        <svg width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
          <path
            d={'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'}
            strokeLinecap={'round'}
            fill={'none'}
            strokeLinejoin={'round'}
            stroke={'currentColor'}
          />
          <path
            d={'M12 17.8L5.8 21L7 14.1L2 9.3L9 8.3L12 2'}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            fill={'currentColor'}
          />
        </svg>
      );
      translation = 'general:pid.favoriteStar.assigned';
    }
    return [icon, translation];
  }, [isFavorite, nodeIsWpOrSwp, isAssigned]);

  if ((!nodeIsProject && !nodeIsWpOrSwp) || !hasLoadedOnce) {
    return null;
  }

  return (
    <Tooltip
      position={toolTipPosition}
      toolTipTranslation={toolTipOverride || pidStatusDescription}
      popperDependencies={[isFavorite]}
    >
      <IconButton
        variant={'ghost'}
        onClick={(e) => {
          e.stopPropagation();
          if (isFavorite) {
            dispatch(unsubscribeFromNode(nodeId));
          } else {
            dispatch(subscribeToNode(nodeId));
          }
        }}
        iconColor={isFavorite || isAssigned ? (nodeIsWpOrSwp ? 'favorite' : 'blue') : 'darkGrey'}
        iconSize={'bigger'}
        size={'sm'}
        icon={icon}
      />
    </Tooltip>
  );
}
