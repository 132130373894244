import {DropdownPopupItemOptions, DropdownPopupItemType} from './Types';

export function appendDropdownOptions(
  items: DropdownPopupItemOptions[],
  next?: ReadonlyArray<DropdownPopupItemOptions>,
): void {
  if (!next || !next.length) {
    return;
  }
  //Add a divider if the next block doesn't provide a divider or header:
  if (items.length && ![DropdownPopupItemType.divider, DropdownPopupItemType.header].includes(next[0].type)) {
    items.push({type: DropdownPopupItemType.divider});
  }
  items.push(...next);
}
