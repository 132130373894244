import {PlanningNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {PlanningStatus} from '@octaved/planning/src/PlanningStatus/PlanningStatus';
import {cn, FormatDateFormats} from '@octaved/ui';
import dayjs from 'dayjs';
import {ArrowLeftToLine, ArrowRightToLine, PlayCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface PlanningDateRowProps {
  node: PlanningNode;
  status: PlanningStatus;
}

export default function PLanningDateRow({node, status}: PlanningDateRowProps): ReactElement | null {
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(node.id);

  const {t} = useTranslation();
  if (status.time === 'none' || !plannedStart || !plannedEnd) {
    return null;
  }

  return (
    <>
      <div className={'col-span-2 h-px w-full bg-slate-200'} />
      <div>{t('nodeDetails:field.planningStatus.details.timebasedPlanning.label')}</div>
      <div className={'flex flex-col'}>
        {status.time === 'inFuture' && (
          <div className={'flex items-center gap-x-2'}>
            <ArrowLeftToLine className={'size-4 text-slate-500'} />
            {t('nodeDetails:field.planningStatus.details.timebasedPlanning.startInFuture')}&nbsp;(
            {dayjs(plannedStart).format(FormatDateFormats.short_with_year)})
          </div>
        )}
        {(status.time === 'active' || status.time === 'betweenBars') && (
          <>
            <div className={'flex items-center gap-x-2'}>
              <PlayCircle
                className={cn(
                  'size-4',
                  status.time === 'active' && 'text-sky-600',
                  status.time === 'betweenBars' && 'text-slate-500',
                )}
              />
              {t(
                status.time === 'active'
                  ? 'nodeDetails:field.planningStatus.details.timebasedPlanning.active'
                  : 'nodeDetails:field.planningStatus.details.timebasedPlanning.betweenBars',
              )}
            </div>
            <div className={'flex items-center gap-x-2'}>
              <ArrowLeftToLine className={'size-4 text-sky-600'} />
              {dayjs(plannedStart).isSame(dayjs(), 'day') ? (
                <>{t('nodeDetails:field.planningStatus.details.timebasedPlanning.startToday')}</>
              ) : (
                <>
                  {t('nodeDetails:field.planningStatus.details.timebasedPlanning.startInPast')}&nbsp;(
                  {dayjs(plannedStart).format(FormatDateFormats.short_with_year)})
                </>
              )}
            </div>
            <div className={'flex items-center gap-x-2'}>
              <ArrowRightToLine className={'size-4 text-slate-500'} />
              {dayjs(plannedEnd).isSame(dayjs(), 'day') ? (
                <>{t('nodeDetails:field.planningStatus.details.timebasedPlanning.dueToday')}</>
              ) : (
                <>
                  {t('nodeDetails:field.planningStatus.details.timebasedPlanning.dueInFuture')}&nbsp;(
                  {dayjs(plannedEnd).format(FormatDateFormats.short_with_year)})
                </>
              )}
            </div>
          </>
        )}
        {status.time === 'inPast' && (
          <>
            <div className={cn('flex items-center gap-x-2', status.overall === 'overdue' && 'text-red-700')}>
              <ArrowRightToLine
                className={cn('size-4', status.overall === 'overdue' ? 'text-red-700' : 'text-slate-500')}
              />
              {t('nodeDetails:field.planningStatus.details.timebasedPlanning.dueInPast')}&nbsp;(
              {dayjs(plannedEnd).format(FormatDateFormats.short_with_year)})
            </div>
          </>
        )}
      </div>
    </>
  );
}
