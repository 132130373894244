import {useCallback, useEffect, useRef, useState} from 'react';

export function useNameSnapshot<T extends {name: string}>(
  entity: T,
  patch: (data: Partial<{name: string}>) => void,
): {
  commit: () => void;
  patch: (name: string) => void;
  value: string;
} {
  const patchRef = useRef(patch);
  patchRef.current = patch;
  const [name, setName] = useState(entity.name);
  const nameRef = useRef(name);
  const patchName = useCallback<(name: string) => void>(
    (name) =>
      setName(() => {
        nameRef.current = name;
        return name;
      }),
    [],
  );
  useEffect(() => patchName(entity.name), [patchName, entity.name]);
  return {
    commit: useCallback(() => patchRef.current({name: nameRef.current}), []),
    patch: patchName,
    value: name,
  };
}
