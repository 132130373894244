import {ComponentType, ReactElement, ReactNode} from 'react';
import Styles from './Styles';
import treeContext, {TreeConfig, useCreateTreeContext} from './TreeContext';
import TreeNode from './TreeNode';

export type AllowedTreeIdTypes = string | number;

export interface TreeNodeData<idType extends AllowedTreeIdTypes> {
  id: idType;
  name: string | ReactElement;
  allDescendantIds: idType[]; //all children, grand-children etc., exclusive own id
  children: Array<TreeNodeData<idType>>;
  parentNode: TreeNodeData<idType> | null;
  icon?: ComponentType | ReactNode;
}

export default function Tree<idType extends AllowedTreeIdTypes>(treeConfig: Partial<TreeConfig<idType>>): ReactElement {
  const config = useCreateTreeContext(treeConfig);
  return (
    <treeContext.Provider value={config}>
      <Styles>
        <TreeNode />
      </Styles>
    </treeContext.Provider>
  );
}
