import FlowLabel from '@octaved/flow/src/Components/Label/FlowLabel/FlowLabel';
import {handleXOrLabelsOnAdd} from '@octaved/flow/src/Hooks/Label';
import {getTranslatedLabelsForSelectionSelector, LabelScope} from '@octaved/flow/src/Modules/Selectors/LabelSelectors';
import {type FlowState} from '@octaved/flow/src/Modules/State';
import {type Uuid} from '@octaved/typescript/src/lib';
import {TWSearchDropdownFilter, TWSearchDropdownFilterTranslations} from '@octaved/ui';
import {Tag} from 'lucide-react';
import type {ReactElement, ReactNode} from 'react';
import {useSelector} from 'react-redux';

const translations: TWSearchDropdownFilterTranslations = {
  actionTriggerEmptyLabel: 'general:all',
  actionTriggerLabel: 'nodeDetails:field.labels.selectLabel',
  actionTriggerSelectedLabel: 'nodeDetails:field.labels.selectLabel.selected',
  clearSelectionLinkLabel: 'general:showAll',
  noItemsFoundPlaceholder: 'nodeDetails:field.labels.noLabelsFound',
  notItemsAvailablePlaceholder: 'nodeDetails:field.labels.noAvailableLabels',
  searchLabel: 'general:search',
};

interface LabelFilterProps {
  actionTrigger?: ReactNode;
  allowMultipleFromSet?: boolean;
  clearSelectionLinkLabel?: string;
  labels: ReadonlyArray<Uuid>;
  isActive?: boolean;
  nodeId?: Uuid | null; //limits the labels to those available for this node
  scope?: LabelScope | null | ReadonlyArray<LabelScope>; //limits to this scope
  setLabels: (ids: Uuid[]) => void;
  upwards?: boolean;
}

export function LabelFilter({
  actionTrigger,
  allowMultipleFromSet,
  clearSelectionLinkLabel,
  labels,
  isActive,
  nodeId,
  scope,
  setLabels,
  upwards,
}: LabelFilterProps): ReactElement {
  const availabelLabels = useSelector((s: FlowState) =>
    getTranslatedLabelsForSelectionSelector(s)({
      nodeId,
      scope,
      selected: labels,
    }),
  );

  return (
    <TWSearchDropdownFilter
      isActive={isActive}
      actionTrigger={actionTrigger}
      actionTriggerIcon={Tag}
      className={'!z-[2050]'}
      clearSelection={() => setLabels([])}
      isActiveFN={(label) => labels.includes(label.id)}
      itemRenderer={({item}) => <FlowLabel label={item} />}
      items={availabelLabels}
      labelItemRenderer={(item) => <FlowLabel label={item} />}
      searchFilterFN={(label, search) => label.name.toLowerCase().includes(search.toLowerCase())}
      selectionCount={labels.length}
      toggleSelection={(label) => {
        if (labels.includes(label.id)) {
          setLabels([...labels.filter((id) => id !== label.id)]);
        } else {
          const toKeep = allowMultipleFromSet
            ? labels
            : labels.filter(
                (() => {
                  const toRemove = new Set(handleXOrLabelsOnAdd(availabelLabels, label.id));
                  return (id: string) => !toRemove.has(id);
                })(),
              );
          setLabels([...toKeep, label.id]);
        }
      }}
      translations={clearSelectionLinkLabel ? {...translations, clearSelectionLinkLabel} : translations}
      triggerClass={'w-fit'}
      upwards={upwards}
    />
  );
}
