import Konva from 'konva';
import {Text, TextConfig} from 'konva/lib/shapes/Text';

export const defaultFontSize = 12;
export const defaultLineHeight = defaultFontSize * 1.5;

export function createText({fontSize = defaultFontSize, lineHeight = defaultLineHeight, ...config}: TextConfig): Text {
  return new Konva.Text({
    fontSize,
    lineHeight,
    fill: '#555',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    height: lineHeight,
    listening: false,
    verticalAlign: 'middle',
    ...config,
  });
}
