import {Uuid} from '@octaved/typescript/src/lib';
import {cn} from '@octaved/ui';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {ReactElement} from 'react';
import BaseFlowAvatar, {AvatarSizes} from '../Avatar/BaseFlowAvatar';
import TooltipWrapper from './TooltipWrapper';

type Status = 'added' | 'edited' | 'removed' | 'none';

const statusColorMap: Record<Status, string> = {
  added: 'text-emerald-700',
  edited: 'text-blue-700',
  none: 'text-slate-800',
  removed: 'text-red-700',
};

interface Props {
  unitId: Uuid;
  status?: Status;
  unitName: string;
  unitType: SimpleUnitType;
  roleId?: Uuid | null;
  className?: string;
  avatarSize?: AvatarSizes;
}

export default function UnitNameWithIcon({
  unitId,
  status = 'none',
  unitName,
  unitType,
  roleId = null,
  className,
  avatarSize,
}: Props): ReactElement {
  return (
    <TooltipWrapper roleId={roleId}>
      <div className={cn('flex items-center gap-x-2 px-2', className)}>
        <BaseFlowAvatar id={unitId} type={unitType} className={'my-0'} size={avatarSize} />
        <div className={statusColorMap[status]}>{unitName}</div>
      </div>
    </TooltipWrapper>
  );
}
