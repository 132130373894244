import {useOnFocusOutside} from '@octaved/hooks/src/OnFocusOutside';
import {useOnOutsideClick} from '@octaved/hooks/src/OnOutsideClick';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {useLoadPlanningDates} from '@octaved/planning/src/Modules/PlanningDates';
import {
  TaskPlanningStatus,
  getNodeHasGapsSelector,
  taskPlanningStatusSelector,
} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {getPlanningPredecessorsSelector} from '@octaved/planning/src/Selectors/PlanningDependencySelectors';
import {Icon} from '@octaved/ui';
import classNames from 'classnames';
import {ChevronDown, CornerDownRight, Flame} from 'lucide-react';
import {ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {TaskPatchData} from '../../../../../EntityInterfaces/Task';
import {FlowState} from '../../../../../Modules/State';
import {generateStandardInspectorRoute} from '../../../../../Routing/Routes/Inspectors/StandardInspector';
import PlanningDate from '../../../../Node/PlanningDate';
import {useTaskListReadonlyContext} from '../../../TaskListReadonlyContext';
import {useTaskListNodeContext} from '../../TaskListForNodeContext';
import CellActionButton from './Components/CellActionButton';
import CellPlaceholder from './Components/CellPlaceholder';
import TaskCell from './Components/TaskCell';
import EditPopup from './PlanningCell/EditPopup';
import GapsPopup from './PlanningCell/GapsPopup';
import PlanningCellTooltip from './PlanningCell/PlanningCellTooltip';

interface Props {
  onClose?: () => void;
  task: Pick<
    TaskPatchData,
    'id' | 'status' | 'planningPredecessors' | 'planningLogicalPredecessors' | 'planningLogicalSuccessors'
  >;
}

export default function PlanningCell({onClose, task}: Props): ReactElement {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {workPackage} = useTaskListNodeContext();
  useLoadPlanningDates(
    useMemo(() => {
      if (!workPackage) {
        return [task.id];
      }
      return [task.id, workPackage.id];
    }, [task.id, workPackage]),
  );
  const hasPredecessors = useSelector((s: FlowState) => getPlanningPredecessorsSelector(s)(task.id, true).length > 0);

  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const closeCbRef = useRef<(() => void) | null>(null);

  const toggleOpen = useCallback(() => {
    setFocused(!focused);
    if (focused && onClose) {
      onClose();
    }
  }, [focused, onClose]);

  useOnOutsideClick(ref, () => {
    if (focused) {
      closeCbRef.current?.();
      setFocused(false);
    }
  });

  useOnFocusOutside(ref, () => {
    if (focused) {
      closeCbRef.current?.();
      setFocused(false);
    }
  });

  const planningStatus = useSelector((s: FlowState) => taskPlanningStatusSelector(s)(task.id));
  const hasGaps = useSelector((s: FlowState) => getNodeHasGapsSelector(s)(task.id));
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(task.id);
  const hasPlanning = Boolean(plannedStart || hasPredecessors);
  const useGapBarPopup = hasGaps;

  const openInspector = useCallback(() => {
    if (task.id) {
      navigate(generateStandardInspectorRoute(task.id, 'planning'));
    }
    toggleOpen();
  }, [navigate, task.id, toggleOpen]);

  return (
    <TaskCell focused={focused} ref={ref} setFocused={setFocused} hasFocusElement>
      {hasPlanning && (
        <PlanningCellTooltip planningStatus={planningStatus}>
          <div className={'planning'}>
            {planningStatus === TaskPlanningStatus.overdue && (
              <Icon iconColor={'red'} size={'big'} inline>
                <Flame fill={'#FFF'} />
              </Icon>
            )}
            <div className={classNames('planningDate', planningStatus)}>
              <PlanningDate plannedStart={plannedStart} plannedEnd={plannedEnd} showTwoLines />
            </div>
          </div>
        </PlanningCellTooltip>
      )}

      {!hasPlanning && (
        <CellPlaceholder placeholder={'taskList:planning.noPlanning'} tooltip={'taskList:planning.noPlanningTooltip'} />
      )}

      {!readonlyOrNotManageable && (
        <CellActionButton
          focused={focused}
          hasContent={false}
          Icon={hasPredecessors ? CornerDownRight : ChevronDown}
          onReset={() => false}
          onToggleFocus={toggleOpen}
          resetInfo={'taskList:planning.removePlanning'}
          alwaysVisible={hasPredecessors}
        />
      )}

      {!readonlyOrNotManageable && focused && !useGapBarPopup && (
        <EditPopup
          closeCbRef={closeCbRef}
          id={task.id}
          contextRef={ref}
          open={focused}
          toggleOpen={toggleOpen}
          plannedEnd={plannedEnd}
          plannedStart={plannedStart}
          hasPredecessors={hasPredecessors}
          onOpenAdvanced={openInspector}
        />
      )}

      {focused && useGapBarPopup && (
        <GapsPopup id={task.id} contextRef={ref} open={focused} onOpenInspector={openInspector} onToggle={toggleOpen} />
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .planning {
          display: flex;
          align-items: center;
        }
        .planningDate {
          line-height: 14px;
        }

        .outSideOfWorkpackagePlanning,
        .overdue {
          color: var(--color-error);
        }

        .dueToday {
          color: var(--color-success);
        }

        .overdue {
          display: flex;
          align-items: center;
        }
      `}</style>
      {/*#endregion*/}
    </TaskCell>
  );
}
