import {
  IsoWeekdayMinutes,
  UnitCustomWorkingTime,
  UnitWeeklyWorkingTime,
  UnitWeeklyWorkingTimeGeneration,
  UnitWorkingTimeSetting,
} from '@octaved/flow/src/EntityInterfaces/WorkTime';
import {isoWeekdays} from '@octaved/flow/src/IsoWeekday';
import {defaultWorkingTimeGenerationDate} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateStr} from '@octaved/typescript';
import {pushOnArrayMap} from '@octaved/utilities';

export function getSystemDefaultMinutes(): IsoWeekdayMinutes {
  return {1: 480, 2: 480, 3: 480, 4: 480, 5: 480, 6: 0, 7: 0};
}

export function getSystemDefaultStandardGeneration(): UnitWeeklyWorkingTimeGeneration {
  return {
    projectMinutes: getSystemDefaultMinutes(),
    validFrom: defaultWorkingTimeGenerationDate,
    workingMinutes: getSystemDefaultMinutes(),
  };
}

export function resolveToNearest<T extends UnitWorkingTimeSetting>(settings: T[], skipSelf = false): T[] {
  const perPathLegnth = new Map<number, T[]>();
  settings.forEach((time) => pushOnArrayMap(perPathLegnth, time.pathLength, time));
  if (skipSelf) {
    perPathLegnth.delete(0);
  }
  return [...perPathLegnth.entries()].sort((a, b) => a[0] - b[0])[0]?.[1] || [];
}

export function getNearestWeeklyGenerations(settings: UnitWeeklyWorkingTime[]): UnitWeeklyWorkingTimeGeneration[] {
  return resolveToNearest(settings)[0]?.generations || [getSystemDefaultStandardGeneration()];
}

export function findGenerationAtDate(
  generations: UnitWeeklyWorkingTimeGeneration[],
  isoDate: DateStr,
): UnitWeeklyWorkingTimeGeneration {
  const count = generations.length;
  for (let i = count - 1; i >= 0; i--) {
    if (generations[i].validFrom <= isoDate) {
      return generations[i];
    }
  }
  return generations[0] || getSystemDefaultStandardGeneration();
}

export function generationHasEqualProjectAndWorkingTime(generation: UnitWeeklyWorkingTimeGeneration): boolean {
  return isoWeekdays.every((day) => generation.workingMinutes[day] === generation.projectMinutes[day]);
}

/**
 * Takes an array of work times and yields the lowest amount of hours defined for the lowest path length.
 * E.g.:
 *  - If there is a work time for a group and a user, the user's work time will take precedence.
 *  - If there are work times for two groups of the same distance, the lower overall working time takes precendence.
 */
export function resolveToNearestLowestCustomWorkTime(
  settings: UnitCustomWorkingTime[],
  isNonStdWorkTime: boolean | null = null,
): UnitCustomWorkingTime | null {
  let nearest = resolveToNearest(settings);
  if (typeof isNonStdWorkTime === 'boolean') {
    nearest = nearest.filter((def) => isNonStdWorkTime === def.isNonStdWorkTime);
  }
  return (
    nearest.toSorted((a, b) => a.workingMinutes + a.projectMinutes - (b.workingMinutes + b.projectMinutes))[0] || null
  );
}
