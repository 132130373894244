import {FlowState} from '@octaved/flow/src/Modules/State';
import {EntityStates} from '@octaved/store/src/EntityState';
import {List, Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {UserGroups, UserGroupsGroups} from '../EntityInterfaces/UserGroups';

export const userGroupsSelector = (state: FlowState): UserGroupsGroups | undefined => state.userGroups?.groups;
export const userGroupsStatesSelector = (state: FlowState): EntityStates | undefined => state.userGroups?.states;

export const emptyUserGroups: UserGroups = [];

export const getUserGroupsForUserIdsSelector = createSelector(userGroupsSelector, (userGroups) =>
  memoize((userIds: Uuid[]): List<UserGroups> => {
    const storeList = userGroups || {};
    return userIds.reduce<List<UserGroups>>((acc, userId) => {
      acc[userId] = storeList[userId] || emptyUserGroups;
      return acc;
    }, {});
  }),
);
