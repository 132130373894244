import {AuditPid} from '../../../../EntityInterfaces/Audit/AuditPid';
import DateStringCell from '../Table/Cells/DateStringCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import {AuditTableColumn} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {nodeColumns} from './Node';

export const pidColumns: AuditTableColumn<AuditPid>[] = [
  ...nodeColumns,
  createColumnDef('flowCustomer', 'systemSettings:audit.tables.pid.flowCustomerHeader', ReferenceCell),
  createColumnDef('pid', 'systemSettings:audit.tables.pid.pidHeader'),
  createColumnDef('dueDate', 'systemSettings:audit.tables.pid.dueDateHeader', DateStringCell),
  createColumnDef('description', 'systemSettings:audit.tables.pid.descriptionHeader'),
  createColumnDef('sortOrder', 'systemSettings:audit.tables.pid.sortOrderHeader'),
];
