import dayjs from '@octaved/dayjs-mutable';
import {useFormattedDateText} from '@octaved/flow/src/Hooks/FormattedDateText';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton, StopClickPropagation, TWButton, Tooltip, cn} from '@octaved/ui';
import {TIME, fromIsoDateTimeFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import {Check, Pen, Plus, Trash} from 'lucide-react';
import {Dispatch, ReactElement, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {setBaselineSettingsForNode} from '../../../Modules/Ui';
import {
  getPlanningBaselineDisplayTimestampSelector,
  getPlanningBaselinesForNodeSelector,
} from '../../../Selectors/PlanningBaselineSelectors';
import {getSelectedBaselineIdsSelector} from '../../../Selectors/UiSelectors';
import {HeaderPopups} from './DrawerHeader';

interface BaselineSelectorContentProps {
  setHeaderPopups: Dispatch<SetStateAction<HeaderPopups>>;
  rootNodeId: Uuid;
}

export default function BaselineSelectorContent({
  setHeaderPopups,
  rootNodeId,
}: BaselineSelectorContentProps): ReactElement {
  const {t} = useTranslation();
  const baselines = useSelector((s: FlowState) => getPlanningBaselinesForNodeSelector(s)(rootNodeId));
  const selectedBaselines = useSelector(getSelectedBaselineIdsSelector)(rootNodeId);
  const getPlanningBaselineDisplayTimestamp = useSelector(getPlanningBaselineDisplayTimestampSelector);
  const formatDate = useFormattedDateText();
  const formatInWords = useFormatInWords();
  const dispatch = useDispatch();

  return (
    <div className={'w-96'}>
      <div className={'max-h-96 overflow-y-auto py-1'}>
        {baselines.map(({id, name}) => {
          const timestamp = getPlanningBaselineDisplayTimestamp(rootNodeId, id);
          const dayjsTimestamp = fromIsoDateTimeFormat(timestamp);
          return (
            <div
              key={id}
              className={cn(
                'group/row flex cursor-pointer justify-between gap-x-3 overflow-hidden px-3 py-2 text-sm hover:bg-slate-100',
                selectedBaselines.includes(id) && 'bg-sky-50 hover:bg-sky-100',
              )}
              onClick={() => {
                dispatch(
                  setBaselineSettingsForNode(rootNodeId, {
                    selectedBaselines: selectedBaselines.includes(id)
                      ? selectedBaselines.filter((baselineId) => baselineId !== id)
                      : [...selectedBaselines, id],
                  }),
                );
              }}
            >
              <div className={'flex flex-grow items-center gap-x-3 overflow-hidden'}>
                <Check
                  className={cn(
                    'h-4 w-4 flex-shrink-0 text-white',
                    selectedBaselines.includes(id) && 'text-emerald-600',
                  )}
                />
                <div className={'flex w-full flex-col overflow-hidden'}>
                  <div className={cn('truncate', !name && 'italic text-slate-600')}>
                    {name ? name : t('pages:planning.baselineComparison.noName')}
                  </div>
                  <div className={'text-xs text-slate-500'}>
                    {formatDate(toIsoFormat(dayjsTimestamp))}
                    {dayjsTimestamp && dayjs().diff(dayjsTimestamp, 'week') < 1 && (
                      <>
                        {', '}
                        {formatInWords(dayjsTimestamp, TIME)}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <StopClickPropagation>
                <div
                  className={
                    'flex max-w-0 flex-shrink-0 items-center gap-x-1 overflow-hidden transition-all group-hover/row:max-w-72'
                  }
                >
                  <Tooltip toolTipTranslation={'general:rename'}>
                    <IconButton
                      iconColor={'darkGrey'}
                      icon={<Pen />}
                      size={'sm'}
                      variant={'ghost'}
                      onClick={() => {
                        setHeaderPopups({renameBaseLine: id});
                      }}
                    />
                  </Tooltip>

                  <Tooltip toolTipTranslation={'general:delete'}>
                    <IconButton
                      iconColor={'red'}
                      icon={<Trash />}
                      size={'sm'}
                      variant={'ghost'}
                      onClick={() => {
                        setHeaderPopups({deleteBaseline: id});
                      }}
                    />
                  </Tooltip>
                </div>
              </StopClickPropagation>
            </div>
          );
        })}
      </div>
      <div className={'h-px bg-slate-200'} />
      <div className={'flex items-center px-3 py-2'}>
        <TWButton
          variant={'solid'}
          colorScheme={'primary'}
          onClick={() => {
            setHeaderPopups({createBaseline: true});
          }}
          size={'md'}
          withIcon
        >
          <Plus className={'h-4 w-4'} strokeWidth={1.5} />
          {t('pages:planning.baselineComparison.addBaseline')}
        </TWButton>
      </div>
    </div>
  );
}
