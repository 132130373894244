import {asDateStr} from '@octaved/typescript';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {NodeSearchIdentWithoutValue, NodeSearchIdentWithValue} from '../../EntityInterfaces/NodeSearch';
import {PlanningEvent, PlanningEventPlanningData} from '../Events';
import {
  dateAfterDate,
  dateBeforeDate,
  dateInRange,
  DateMatcher,
  dateRangesOverlaps,
  reduceMatchesDate,
  reduceMatchesDateRange,
} from '../NodeSearchReducers/DateSearches';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {reduceRemoveIdsByIdentPrefix, reduceRemoveIdsByIdentValue} from '../NodeSearchReducers/GenericRemove';
import {registerListeners} from './Common';

function reduceNotPlanned<I extends NodeSearchIdentWithoutValue>(
  state: UuidSearchResults,
  updatedPlanningDates: Record<string, PlanningEventPlanningData[] | null>,
  ident: I,
): UuidSearchResults {
  return Object.entries(updatedPlanningDates).reduce((acc, [id, planningDates]) => {
    const isPlanned = planningDates !== null;
    return isPlanned ? reduceRemoveIdsByIdentValue(acc, [id], ident) : reduceAddOptional(acc, id, ident);
  }, state);
}

function reducePlannedAroundDateRange(
  state: UuidSearchResults,
  updatedPlanningDates: Record<string, PlanningEventPlanningData[] | null>,
  ident: NodeSearchIdentWithValue,
): UuidSearchResults {
  return Object.entries(updatedPlanningDates).reduce((acc, [id, planningDates]) => {
    if (planningDates) {
      const overlaps = dateRangesOverlaps(planningDates);
      return reduceMatchesDateRange(acc, id, ident, overlaps);
    } else {
      return reduceRemoveIdsByIdentPrefix(acc, [id], ident);
    }
  }, state);
}

function reduceNodePlanning(
  state: UuidSearchResults,
  updatedPlanningDates: Record<string, PlanningEventPlanningData[] | null>,
  ident:
    | 'nodePlanningStartsAfterDate'
    | 'nodePlanningStartsBeforeDate'
    | 'nodePlanningEndsAfterDate'
    | 'nodePlanningEndsBeforeDate',
): UuidSearchResults {
  return Object.entries(updatedPlanningDates).reduce((acc, [id, planningDates]) => {
    if (planningDates && planningDates.length > 0) {
      let min = asDateStr('9999-99-99');
      let max = asDateStr('0000-00-00');
      planningDates.forEach(({plannedStart, plannedEnd}) => {
        const start = isoDateTimeToIsoFormat(plannedStart);
        const end = isoDateTimeToIsoFormat(plannedEnd);
        if (start < min) {
          min = start;
        }
        if (end > max) {
          max = end;
        }
      });
      let matcher: DateMatcher;
      switch (ident) {
        case 'nodePlanningStartsAfterDate':
          matcher = dateAfterDate(min);
          break;
        case 'nodePlanningStartsBeforeDate':
          matcher = dateBeforeDate(min);
          break;
        case 'nodePlanningEndsAfterDate':
          matcher = dateAfterDate(max);
          break;
        case 'nodePlanningEndsBeforeDate':
          matcher = dateBeforeDate(max);
          break;
      }
      return reduceMatchesDate(acc, id, ident, matcher);
    } else {
      return reduceRemoveIdsByIdentPrefix(acc, [id], ident);
    }
  }, state);
}

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<PlanningEvent>('flow.PlanningEvent', {
  assignedAnyProjectRole: 'ignored',
  assignedProjectRoleId: 'ignored',
  assignedProjectRoleType: 'ignored',
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored',
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: ({isServerSide}) => (isServerSide ? 'clear' : 'ignored'),
  hasTimeBasedPredecessor: ({isServerSide}) => (isServerSide ? 'clear' : 'ignored'),
  isArchived: 'ignored',
  labelId: 'ignored',
  mrIsActive: 'ignored',
  name: 'ignored',
  nodeNotPlanned: ({updatedNodePlanningDates}) => {
    return {
      results: (state) => reduceNotPlanned(state, updatedNodePlanningDates, 'nodeNotPlanned'),
    };
  },
  nodePlannedAroundDateRange: ({updatedNodePlanningDates}) => {
    return {
      results: (state) => reducePlannedAroundDateRange(state, updatedNodePlanningDates, 'nodePlannedAroundDateRange'),
    };
  },
  nodePlanningEndsAfterDate: ({updatedNodePlanningDates}) => {
    return {
      results: (state) => reduceNodePlanning(state, updatedNodePlanningDates, 'nodePlanningEndsAfterDate'),
    };
  },
  nodePlanningEndsBeforeDate: ({updatedNodePlanningDates}) => {
    return {
      results: (state) => reduceNodePlanning(state, updatedNodePlanningDates, 'nodePlanningEndsBeforeDate'),
    };
  },
  nodePlanningStartsAfterDate: ({updatedNodePlanningDates}) => {
    return {
      results: (state) => reduceNodePlanning(state, updatedNodePlanningDates, 'nodePlanningStartsAfterDate'),
    };
  },
  nodePlanningStartsBeforeDate: ({updatedNodePlanningDates}) => {
    return {
      results: (state) => reduceNodePlanning(state, updatedNodePlanningDates, 'nodePlanningStartsBeforeDate'),
    };
  },
  nodeType: 'ignored',
  pidHasDueDate: ({updatedDueDates}) => {
    return {
      results: (state) =>
        Object.entries(updatedDueDates).reduce((acc, [id, patch]) => {
          if (patch.dueDate) {
            return reduceMatchesDateRange(acc, id, 'pidHasDueDate', dateInRange(patch.dueDate));
          } else {
            return reduceRemoveIdsByIdentPrefix(acc, [id], 'pidHasDueDate');
          }
        }, state),
    };
  },
  pidHasMilestoneDate: ({updatedMilestoneNodeIds}) => {
    return updatedMilestoneNodeIds.length ? 'clear' : 'ignored';
  },
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: 'ignored',
  responsibleByAny: 'ignored',
  responsibleUnitId: 'ignored',
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: ({updatedLogicalDependencies: {tasks}}) => {
    return Object.keys(tasks).length ? 'clear' : 'ignored';
  },
  taskCompletedInDateRange: 'ignored',
  taskStatus: 'ignored',
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
