import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement} from 'react';
import HRule from './Filter/HRule';
import Header from './Header';

/**
 * This is more of a template than a default - all UI-filters need to be configured in the ProjectDefaultFilters.ts
 */
export default function DefaultAdvancedFilter(): ReactElement {
  return (
    <>
      <Header token={'pages:projects.filter.filterForWorkpackage'} />
      <Spacer />

      <HRule />
      <Spacer />

      <Header token={'pages:projects.filter.filterForProjects'} />
      <Spacer />
    </>
  );
}
