import Konva from 'konva';
import {MinusCircle, PlusCircle} from 'lucide';
import {ColumnWidthChangedEvent} from '../Calendar/Context/CalendarContext';
import {setMouseHoverEffect} from '../MouseHoverCursor';
import {RootContainer} from '../RootContainer';
import {defaultShadow} from '../Shadow';
import {createText} from './Text';

const cellWidths = [5, 10, 15, 20, 30, 40, 80];
const zoomText = new Map([
  [cellWidths[0], '25%'],
  [cellWidths[1], '50%'],
  [cellWidths[2], '75%'],
  [cellWidths[3], '100%'],
  [cellWidths[4], '150%'],
  [cellWidths[5], '200%'],
  [cellWidths[6], '400%'],
]);

export class Zoom extends RootContainer {
  readonly iconSize = 16;
  readonly height = 28;
  readonly width = 80;

  readonly #rect = new Konva.Rect({
    fill: 'white',
    height: this.height,
    width: this.width,
    ...defaultShadow,
  });
  #text: Konva.Text | null = null;
  #minusIcon: Konva.Image | null = null;
  #plusIcon: Konva.Image | null = null;

  init(): void {
    this.disposables.push(this.ctx.eventEmitter.on('columnWidthChanged', this.#updateZoomText));
    this.#text = createText({
      align: 'center',
      fontSize: 14,
      height: this.height,
      lineHeight: this.height,
      offsetY: -1,
      text: zoomText.get(this.ctx.columnWidth),
      verticalAlign: 'middle',
      width: this.width,
    });
    this.root.add(this.#rect, this.#text);
    this.#createIcons();

    this.root.width(this.#rect.width());
    this.root.height(this.#rect.height());
  }

  #updateZoomText = ({width}: ColumnWidthChangedEvent): void => {
    this.#text?.text(zoomText.get(width)!);

    this.#minusIcon?.opacity(width <= cellWidths[0] ? 0.5 : 1);
    this.#plusIcon?.opacity(width >= cellWidths[5] ? 0.5 : 1);
  };

  async #createIcons(): Promise<void> {
    const y = (this.height - this.iconSize) / 2;

    const [minusIcon, plusIcon] = await Promise.all([
      this.iconToImage(MinusCircle, {
        height: this.iconSize,
        imageConfig: {
          y,
          x: 4,
        },
        style: {
          stroke: '#9ca0b9',
        },
        width: this.iconSize,
      }),
      this.iconToImage(PlusCircle, {
        height: this.iconSize,
        imageConfig: {
          y,
          x: this.width - this.iconSize - 4,
        },
        style: {
          stroke: '#9ca0b9',
        },
        width: this.iconSize,
      }),
    ]);

    setMouseHoverEffect(minusIcon, 'pointer');
    setMouseHoverEffect(plusIcon, 'pointer');

    minusIcon.on('click tap', () => {
      const index = cellWidths.findIndex((width) => width === this.ctx.columnWidth);
      if (index > -1) {
        this.ctx.columnWidth = cellWidths[Math.max(index - 1, 0)];
      }
    });
    plusIcon.on('click tap', () => {
      const index = cellWidths.findIndex((width) => width === this.ctx.columnWidth);
      if (index > -1) {
        this.ctx.columnWidth = cellWidths[Math.min(index + 1, cellWidths.length - 1)];
      }
    });

    this.#minusIcon = minusIcon;
    this.#plusIcon = plusIcon;

    this.root.add(minusIcon, plusIcon);
  }
}
