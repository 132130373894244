import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {error} from '@octaved/env/src/Logger';
import NetworkError from '@octaved/network/src/NetworkError';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {EntityStates, filterIdsToReload} from '@octaved/store/src/EntityState';
import {multiAction} from '@octaved/store/src/MultiAction';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {generateUuid} from '@octaved/utilities';
import {debounceReduxIdsAction} from '@octaved/utilities/src/DebounceReduxAction';
import isEqual from 'lodash/isEqual';
import once from 'lodash/once';
import {
  FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS,
  FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS,
} from '../ActionTypes';
import {NodeRoleAssignmentEvent, NodesRearrangeEvent} from '../Events';
import {extendWithDescendantsSelector} from '../Selectors/NodeTreeSelectors';
import {FlowState} from '../State';
import {removeErrorForField} from '../Ui';

export const subscribeToNodeRoleAssignmentEvent = once((dispatch: Dispatch) => {
  subscribe('flow.NodeRoleAssignmentEvent', ({nodeId, roleType}: NodeRoleAssignmentEvent) => {
    dispatch((disp, getState) => {
      const withDescendants = extendWithDescendantsSelector(getState())([nodeId]);
      disp({
        nodeIds: withDescendants,
        type:
          roleType === EnumFlowNodeRoleType.VALUE_PERMISSION
            ? FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS
            : FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS,
      });
    });
  });

  subscribe('flow.NodesRearrangeEvent', ({nodeIds}: NodesRearrangeEvent) => {
    dispatch((disp, getState) => {
      const withDescendants = extendWithDescendantsSelector(getState())(nodeIds);
      disp(
        multiAction([
          {
            nodeIds: withDescendants,
            type: FLOW_INVALIDATE_NODE_PERMISSION_ROLE_ASSIGNMENTS,
          },
          {
            nodeIds: withDescendants,
            type: FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS,
          },
        ]),
      );
    });
  });
});

export const unitToIdName = {
  [SimpleUnitType.group]: 'groupId',
  [SimpleUnitType.user]: 'userId',
};

interface StoreNodeRoleAssignments {
  [flowNodeId: string]: object;
}

export function reduceNodeRoleAssignmentLoadSuccess(
  state: StoreNodeRoleAssignments,
  {
    response,
  }: {
    response: StoreNodeRoleAssignments;
  },
): StoreNodeRoleAssignments {
  const newState = {...state};
  let changed = false;
  Object.entries(response).forEach(([nodeId, roleAssignments]) => {
    if (!newState[nodeId] || !isEqual(newState[nodeId], roleAssignments)) {
      newState[nodeId] = roleAssignments;
      changed = true;
    }
  });
  return changed ? newState : state;
}

export function createLoadNodeRoleAssignmentsAction(
  stateSelector: (state: FlowState) => EntityStates,
  endpoint: string,
  failureType: string,
  requestType: string,
  successType: string,
): (nodeIds: ReadonlyArray<Uuid>) => ActionDispatcher<void, FlowState> {
  return debounceReduxIdsAction((nodeIds: ReadonlyArray<Uuid>): ActionDispatcher<void, FlowState> => {
    return (dispatch, getState) => {
      subscribeToNodeRoleAssignmentEvent(dispatch);
      const toLoad = filterIdsToReload(stateSelector(getState()), nodeIds);
      if (toLoad.length) {
        dispatch({
          [CALL_API]: {
            endpoint,
            method: 'post',
            options: {data: {nodeIds: toLoad}},
            types: {failureType, requestType, successType},
          },
        });
      }
    };
  });
}

export interface BulkPatchAction<S, R, T extends string> {
  options: {
    data: {
      toRemove: R[];
      toSet: S[];
    };
    urlParams: {
      nodeId: Uuid;
    };
  };
  requestId: Uuid;
  type: T;
}

export function createBulkPatchNodeRoleAssignmentsAction<S, R>(
  type: EnumFlowNodeRoleType,
  endpoint: string,
  failureType: string,
  requestType: string,
  successType: string,
): (nodeId: Uuid, toSet: S[], toRemove: R[]) => ActionDispatcher<Promise<boolean>, FlowState> {
  return (nodeId, toSet, toRemove): ActionDispatcher<Promise<boolean>, FlowState> => {
    return async (dispatch) => {
      try {
        await dispatch({
          [CALL_API]: {
            endpoint,
            method: 'patch',
            options: {data: {toSet, toRemove}, urlParams: {nodeId}},
            throwNetworkError: true,
            types: {failureType, requestType, successType},
          },
          requestId: generateUuid(), //for optimistic reducer
        });
        dispatch(removeErrorForField(`role.${type}.${nodeId}.self-lock-out`));
        return true;
      } catch (e) {
        if (!(e instanceof NetworkError) || e.responseStatus !== 409) {
          error(e);
        }
        return false;
      }
    };
  };
}
