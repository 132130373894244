import {GhostButtonWithHoverText, cn} from '@octaved/ui';
import {ArrowLeftRight, LucideIcon} from 'lucide-react';
import {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';

export type HistoryColor = 'bg-green-700' | 'bg-orange-600' | 'bg-sky-700' | 'bg-slate-700';

interface Props {
  children: ReactNode;
  canCompareToPrevious: boolean;
  onCompare?: () => void;
  compare?: boolean;
  isFirst: boolean;
  istLastElementInHistory: boolean;
  circleColor: HistoryColor;
  entryHeader: ReactNode;
  entryIcon: LucideIcon;
}

const gradientFromMap: Record<HistoryColor, string> = {
  'bg-green-700': 'from-green-700',
  'bg-orange-600': 'from-orange-600',
  'bg-sky-700': 'from-sky-700',
  'bg-slate-700': 'from-slate-700',
};

export default function HistoryEntry({
  canCompareToPrevious,
  children,
  compare,
  onCompare,
  isFirst,
  istLastElementInHistory,
  circleColor = 'bg-sky-700',
  entryIcon: Icon,
  entryHeader,
}: Props): ReactElement {
  const {t} = useTranslation();
  return (
    <div
      className={cn(
        'relative flex items-stretch gap-x-4 py-2 pr-8',
        compare && 'bg-slate-50',
        !istLastElementInHistory && 'min-h-[80px]',
      )}
    >
      {!istLastElementInHistory && (
        <div className={cn('absolute -bottom-2 left-[16px] top-0 w-1 bg-slate-100', isFirst && 'top-2')}>
          <div
            className={cn(
              'absolute left-0 right-0 top-12 h-10 bg-gradient-to-b to-slate-100',
              isFirst && 'top-10',
              gradientFromMap[circleColor],
            )}
          />
        </div>
      )}
      <div
        className={cn('relative z-10 inline-flex h-10 w-10 items-center justify-center rounded-full p-2', circleColor)}
      >
        <Icon className={'h-6 w-6 text-white'} strokeWidth={1.5} />
      </div>
      <div className={'flex flex-col gap-y-1'}>
        <div className={'flex h-4 items-center gap-x-4'}>
          <div className={'text-sm leading-none text-slate-500'}>{entryHeader}</div>
          {canCompareToPrevious && (
            <GhostButtonWithHoverText onClick={onCompare} icon={ArrowLeftRight}>
              {t('boardPosts:history.compare')}
            </GhostButtonWithHoverText>
          )}
        </div>

        <div>{children}</div>
      </div>
    </div>
  );
}
