import {isDevLocal} from '@octaved/env/src/Environment';
import dayjs, {Dayjs} from 'dayjs';
import {DateTimeStr, isDateTimeStr} from './DateTimeStr';

export type DateStr = string & {__brand: 'DateStr'};
export type DateStrRange = `${DateStr}-${DateStr}`;

export function isDateStr(date: string | null | undefined): date is DateStr {
  return !!date && date.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

function isDateStrAssertion(date: string | null | undefined): date is DateStr {
  return isDevLocal ? isDateStr(date) : !!date;
}

export function asDateStr(date: string): DateStr;
export function asDateStr(date: null | undefined): null;
export function asDateStr(date: string | null | undefined): DateStr | null;
export function asDateStr(date: string | null | undefined): DateStr | null {
  if (isDateStrAssertion(date)) {
    return date;
  }
  if (isDevLocal && date) {
    throw new Error(`'${date}' is not a valid date`);
  }
  return null;
}

export function toDateStr(date: Dayjs | DateStr | DateTimeStr): DateStr {
  if (dayjs.isDayjs(date)) {
    return asDateStr(date.format('YYYY-MM-DD'));
  }
  if (isDateTimeStr(date)) {
    return asDateStr(date.slice(0, 10));
  }
  return date;
}
