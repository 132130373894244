import {Uuid} from '@octaved/typescript/src/lib';
import {LabelRemovedEvent} from './Events';

interface LabelUser {
  labels: Uuid[];
  id: Uuid;
}

function reduceLabelRemovedOnRecords<T extends Record<string, LabelUser | undefined>>(
  state: T,
  {id}: LabelRemovedEvent,
): T {
  const newState = {...state};
  let changed = false;
  for (const record of Object.values<LabelUser | undefined>(newState)) {
    if (record && record.labels.includes(id)) {
      // @ts-ignore T extends [x:string]
      newState[record.id] = {
        ...record,
        labels: record.labels.filter((cId) => cId !== id),
      };
      changed = true;
    }
  }
  return changed ? newState : state;
}

export function createLabelRemovedOnRecordsReducer<T extends Record<string, LabelUser | undefined>>(): (
  state: T,
  action: LabelRemovedEvent,
) => T {
  return reduceLabelRemovedOnRecords; //for some reason this cannot be used statically. The record type won't be detected properly.
}

export function addLabelRemovedEventToReducer(reducerMap: Map<string, unknown>): void {
  reducerMap.set('flow.LabelRemovedEvent', reduceLabelRemovedOnRecords);
}

export function removeNotAllowedLabels<T extends {labels?: Uuid[]}>(saveData: T, allowedLabelIds: Uuid[]): T {
  if (saveData.labels) {
    return {
      ...saveData,
      labels: saveData.labels.filter((id) => allowedLabelIds.includes(id)),
    };
  }
  return saveData;
}
