import Konva from 'konva';
import {RootContainer, RootContainerProps} from '../RootContainer';
import {ScrollEvent} from './Context/CalendarContext';
import GridLines from './GridLines';
import {Header} from './Header';
import {OffDays} from './OffDays';
import TodayLine from './TodayLine';

export class Calendar extends RootContainer {
  readonly #staticOverlay = new Konva.Group({id: 'calendarStaticOverlay'});
  readonly #scrollableOverlay = new Konva.Group({id: 'calendarScrollableOverlay'});

  #gridLines: GridLines | null = null;
  #todayLine: TodayLine | null = null;
  #offDays: OffDays | null = null;
  #header: Header | null = null;

  constructor(props: RootContainerProps) {
    super(props);
    //set name as it is used for position calculation of the today buttons
    this.root.name('Calendar');
    this.root.add(this.#scrollableOverlay);
    this.root.add(this.#staticOverlay);
  }

  init(): void {
    this.#createBackground();
    this.#createHeader();
    this.#createTodayLine();

    this.disposables.push(
      this.ctx.eventEmitter.on('scroll', this.#onScroll),
      this.ctx.eventEmitter.on('calenderDatesChanged', this.#onCalenderDatesChanged),
      this.ctx.eventEmitter.on('columnWidthChanged', this.#onCalenderDatesChanged),
    );
    this.#onCalenderDatesChanged();
  }

  #onCalenderDatesChanged = (): void => {
    this.#gridLines?.onCalenderDatesChanged();
    this.#offDays?.onCalenderDatesChanged();
    this.#header?.onCalenderDatesChanged();
    this.#todayLine?.onCalenderDatesChanged();
  };

  #createBackground(): void {
    const gridLines = new GridLines({ctx: this.ctx});
    const offDays = new OffDays({ctx: this.ctx});

    this.#scrollableOverlay.add(offDays.root);
    this.#scrollableOverlay.add(gridLines.root);

    this.#gridLines = gridLines;
    this.#offDays = offDays;

    this.disposables.push(() => {
      gridLines.dispose();
      offDays.dispose();
    });
  }

  #createTodayLine(): void {
    const todayLine = new TodayLine({ctx: this.ctx});

    const placeholder = this.root.getStage()?.findOne('#todayPlaceholder');
    if (placeholder instanceof Konva.Group) {
      placeholder.add(todayLine.root);
    }

    this.#todayLine = todayLine;

    this.disposables.push(() => {
      todayLine.dispose();
    });
  }

  #createHeader(): void {
    const header = new Header({ctx: this.ctx});
    this.#scrollableOverlay.add(header.root);
    this.#staticOverlay.add(header.staticOverlay);
    header.init();
    this.#header = header;

    this.disposables.push(() => {
      header.dispose();
    });
  }

  #onScroll = ({scrollLeft}: ScrollEvent): void => {
    this.#scrollableOverlay.offsetX(scrollLeft);
  };
}
