import {ReactElement, useState} from 'react';
import {Input} from 'semantic-ui-react';

interface Props {
  disabled?: boolean;
  year: number;
  setYear: (year: number) => void;
}

//See DateSchema.json:
const validYearRegex = /^20[0-9]{2}$/;

const YearInput = function YearInput({disabled, year, setYear}: Props): ReactElement {
  const [value, setValue] = useState(year.toString());
  const [invalid, setInvalid] = useState(false);
  return (
    <div className={'yearInput'}>
      <Input
        fluid
        error={invalid}
        disabled={disabled}
        value={value}
        onChange={(_e, {value}) => {
          setValue(value);
          if (validYearRegex.test(value)) {
            setYear(+value);
            setInvalid(false);
          } else {
            setInvalid(true);
          }
        }}
        type={'number'}
        min={'2000'}
        max={'2099'}
        step={'1'}
      />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .yearInput {
          width: 55px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
};

export {YearInput};
