import {AuditProjectFolder} from '../../../../EntityInterfaces/Audit/AuditProjectFolder';
import {AuditTableConfiguration} from './Confgurations';
import {nodeColumns} from './Node';

const config: AuditTableConfiguration<AuditProjectFolder> = {
  columns: nodeColumns,
  tableIdent: 'projectFolders',
  tableNameTranslationToken: 'systemSettings:audit.projectFolderTableName',
};
export default config;
