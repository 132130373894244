import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {RulesList, validateRules} from '@octaved/store/src/Validation';
import {DeepPartial, DeepPartialObject, Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {pick} from 'lodash';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {NewLabelColors} from '../Components/Form/ColorPicker/ColorPickerWithPalette';
import {NodeType} from '../EntityInterfaces/Nodes';
import {CommonPidNodePatchData, Project, ProjectPatchData} from '../EntityInterfaces/Pid';
import {
  FLOW_CREATE_PROJECT_FAILURE,
  FLOW_CREATE_PROJECT_REQUEST,
  FLOW_CREATE_PROJECT_SUCCESS,
  FLOW_PATCH_PROJECT_FAILURE,
  FLOW_PATCH_PROJECT_REQUEST,
  FLOW_PATCH_PROJECT_SUCCESS,
} from './ActionTypes';
import {
  createBudgetEntity,
  getBudgetEntityCopiedFields,
  getBudgetEntityValidationRules,
  transformBudgetEntityToPatchData,
  transformPatchDataToBudgetEntity,
} from './BudgetEntity';
import {
  createCommonPidNode,
  createDeleteCommonPidNode,
  getCommonPidNodeCopiedFields,
  getCommonPidNodeValidationRules,
  transformCommonPidNodeToPatchData,
  transformPatchDataToCommonPidNode,
} from './CommonPidNodes';
import {EventData, ProjectCreateEvent, ProjectPatchEvent} from './Events';
import {useLoadNodes} from './Hooks/Nodes';
import {useNodeSearch} from './Hooks/NodeSearch';
import {removeNotAllowedLabels} from './ReduceRemovedLabels';
import {copyResponsibleNode, createResponsibleNode, onResponsibleNodeCreation} from './ResponsibleNode';
import {labelIdsSelector} from './Selectors/LabelSelectors';
import {getNodeSearchSelector} from './Selectors/NodeSearchSelectors';
import {nodeEntitySelector} from './Selectors/NodeSelectors';
import {getProjectSelector} from './Selectors/PidSelectors';
import {FlowState} from './State';
import {setErrors} from './Ui';

export function createProjectEntity(flowCustomer: Uuid): Project {
  return {
    ...createCommonPidNode(EnumFlowNodeType.VALUE_PROJECT, flowCustomer),
    ...createBudgetEntity(),
    ...createResponsibleNode(),
    color: NewLabelColors[Math.floor(Math.random() * NewLabelColors.length)].substr(1),
    customerLocation: null,
    isClosed: false,
    isLocked: false,
    isTemplate: false,
    nodeType: EnumFlowNodeType.VALUE_PROJECT,
    timeControl: null,
  };
}

function getProjectEntityCopiedFields(): Array<keyof Project> {
  return [...getCommonPidNodeCopiedFields(), ...getBudgetEntityCopiedFields(), 'color', 'timeControl'];
}

export function copyProjectEntity(source: Project, targetNode: NodeType): Project {
  return {
    ...createProjectEntity(source.flowCustomer),
    ...pick(source, getProjectEntityCopiedFields()),
    ...copyResponsibleNode(source, targetNode),
  };
}

export function transformProjectToPatchData<T extends CommonPidNodePatchData = ProjectPatchData>(project: Project): T {
  return {
    ...transformCommonPidNodeToPatchData<T>(project),
    ...transformBudgetEntityToPatchData(project),
  };
}

function transformPatchDataToProject(patch: ProjectPatchData): Project;
function transformPatchDataToProject(patch: Partial<ProjectPatchData>): Partial<Project>;
function transformPatchDataToProject(patch: DeepPartial<ProjectPatchData>): DeepPartial<Project>;
function transformPatchDataToProject(patch: DeepPartialObject<ProjectPatchData>): DeepPartialObject<Project>;
function transformPatchDataToProject(patch: DeepPartial<ProjectPatchData>): DeepPartial<Project> {
  return {
    ...transformPatchDataToCommonPidNode<Project>(patch),
    ...transformPatchDataToBudgetEntity(patch),
  };
}

const validationTexts = {
  nameEmpty: 'general:pid.validationErrors.projectNameNotEmpty',
  nameTooLong: 'general:pid.validationErrors.projectNameTooLong',
};

function getProjectValidationRules(
  id: Uuid,
  patch: DeepPartialObject<ProjectPatchData>,
  isCreation: boolean,
  previous?: ProjectPatchData,
): RulesList {
  return [
    ...getCommonPidNodeValidationRules(id, patch, isCreation, validationTexts),
    ...getBudgetEntityValidationRules(id, patch, previous),
  ];
}

export function createProject(project: Project, parentNodeId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    onResponsibleNodeCreation(project, parentNodeId, state);
    const event: EventData<ProjectCreateEvent> = {
      parentNodeId,
      project,
    };
    await dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.putProject,
        method: 'put',
        options: {
          data: {
            ...project,
            parentNodeId,
          },
          urlParams: {projectId: project.id},
        },
        types: {
          failureType: FLOW_CREATE_PROJECT_FAILURE,
          requestType: FLOW_CREATE_PROJECT_REQUEST,
          successType: FLOW_CREATE_PROJECT_SUCCESS,
        },
      },
    });
  };
}

export function patchProject(id: Uuid, partial: Partial<Project>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const prevProject = getProjectSelector(state)(id);
    if (prevProject) {
      const patchData = removeNotAllowedLabels(partial, labelIdsSelector(state));
      if (!objectContains(prevProject, patchData)) {
        const patch = {...patchData, lastChangedBy: currentOrgUserIdSelector(state), lastChangedOn: unix()};
        const event: EventData<ProjectPatchEvent> = {
          patch,
          patchedKeys: Object.keys(patchData) as Array<keyof Project>,
          projectId: id,
        };
        dispatch({
          ...event,
          [CALL_API]: {
            endpoint: routes.patchProject,
            method: 'patch',
            options: {
              data: {...patch},
              urlParams: {projectId: id},
            },
            types: {
              failureType: FLOW_PATCH_PROJECT_FAILURE,
              requestType: FLOW_PATCH_PROJECT_REQUEST,
              successType: FLOW_PATCH_PROJECT_SUCCESS,
            },
          },
          patchedNodeId: id, //for the optimistic patch
        });
      }
    }
  };
}

/**
 * @deprecated use patchProject & auto-saving and local form validation
 */
export function patchProjectDeprecated(
  id: Uuid,
  partial: DeepPartialObject<ProjectPatchData>,
): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const prevProject = getProjectSelector(state)(id);
    if (prevProject) {
      const rules: RulesList = getProjectValidationRules(
        id,
        partial,
        false,
        transformProjectToPatchData<ProjectPatchData>(prevProject),
      );
      const errors = validateRules(rules);
      if (errors.length) {
        dispatch(setErrors(errors));
        return false;
      }

      const transformed = transformPatchDataToProject(partial);
      const patchData = removeNotAllowedLabels(transformed, labelIdsSelector(state));
      if (!objectContains(prevProject, patchData)) {
        const patch = {
          ...patchData,
          lastChangedBy: currentOrgUserIdSelector(state),
          lastChangedOn: unix(),
        } as DeepPartialObject<Project>;
        const event: EventData<ProjectPatchEvent> = {
          patch,
          patchedKeys: Object.keys(patchData) as Array<keyof Project>,
          projectId: id,
        };
        dispatch({
          ...event,
          [CALL_API]: {
            endpoint: routes.patchProject,
            method: 'patch',
            options: {
              data: {...patch},
              urlParams: {projectId: id},
            },
            types: {
              failureType: FLOW_PATCH_PROJECT_FAILURE,
              requestType: FLOW_PATCH_PROJECT_REQUEST,
              successType: FLOW_PATCH_PROJECT_SUCCESS,
            },
          },
          patchedNodeId: id, //for the optimistic patch
        });
      }
    }
    return true;
  };
}

export const deleteProject = createDeleteCommonPidNode('projectId', routes.deleteProject);

export function useProjectPatch(nodeId: Uuid): (data: Partial<Project>) => void {
  const dispatch = useDispatch();
  return useCallback((data: Partial<Project>) => dispatch(patchProject(nodeId, data)), [dispatch, nodeId]);
}

const canCreateProjectsSomewhereSelector = createSelector(
  isGrantedSelector,
  getNodeSearchSelector('nodeType', EnumFlowNodeType.VALUE_PROJECT_FOLDER),
  nodeEntitySelector,
  (isGranted, projectFolderIds, nodes) =>
    [...projectFolderIds].some((id) => nodes[id]?.isArchived === false && isGranted('FLOW_NODE_PID_MANAGE_BASIC', id)),
);

export function useCanCreateProjectsSomewhere(): boolean {
  const {nodeIds} = useNodeSearch('nodeType', EnumFlowNodeType.VALUE_PROJECT_FOLDER);
  useLoadNodes(nodeIds);
  return useSelector(canCreateProjectsSomewhereSelector);
}
