import {EnvironmentState, patchEnvironmentState} from '@octaved/env/src/EnvironmentState';
import debounce from 'lodash/debounce';
import {loadVersion, VersionInfo} from './Version/Version';

async function check(): Promise<boolean> {
  let inMaintenance: boolean | null = null;
  let version: VersionInfo | null = null;
  try {
    version = await loadVersion();
    inMaintenance = false;
  } catch (err) {
    //503 is our explicit response code during maintenance
    if (err instanceof Response && err.status === 503) {
      inMaintenance = true;
    } //else do not log anything, the ParseResponse.ts has already logged this
  }

  //A 502 or 504 status can happen during maintenance, but we've also seen a 502 it in the middle of the day and
  // we don't want to display the maintenance window in that case.

  if (typeof inMaintenance === 'boolean') {
    const patch: Partial<EnvironmentState> = {isInMaintenance: inMaintenance};
    if (version) {
      patch.version = version;
    }
    patchEnvironmentState(patch);
  }

  return inMaintenance === true || inMaintenance === null; //more a "maybe in maintenance" - only the ws uses the result
}

let timer: ReturnType<typeof setTimeout> | undefined;

//Debounce in case "online" and "visibilitychange" are fired at once:
export const checkIsInMaintenanceMode = debounce(async () => {
  if (await check()) {
    clearTimeout(timer);
    timer = setTimeout(checkIsInMaintenanceMode, 10000);
  }
}, 100);

window.addEventListener('online', checkIsInMaintenanceMode, {passive: true});

document.addEventListener(
  'visibilitychange',
  () => {
    if (document.visibilityState === 'visible') {
      checkIsInMaintenanceMode();
    }
  },
  {passive: true},
);
