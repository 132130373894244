import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {DialogContent, DialogFrame, DialogTitle} from '../../../Dialogs/DialogFrame';
import Content from './Content';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  workPackageId: Uuid;
}

export default function MoveWorkPackageTasks({open, setOpen, workPackageId}: Props): ReactElement {
  return (
    <>
      {open && (
        <DialogFrame size={'fullscreen'} open noOwnForm>
          <DialogTitle onClose={() => setOpen(false)} />
          <DialogContent>
            <Content workPackageId={workPackageId} close={() => setOpen(false)} />
          </DialogContent>
        </DialogFrame>
      )}
    </>
  );
}
