import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {Disposable} from '../../Disposable';
import {GanttDataLoader} from '../../Gantt/Data/GanttDataLoader';
import {CalendarContext} from './CalendarContext';

export interface CalendarViewProps {
  dataLoader: GanttDataLoader;
  dateStart?: dayjs.Dayjs;
  dateEnd?: dayjs.Dayjs;
  today: dayjs.Dayjs;
}

function calculateDatesWithPadding(
  dateStart: dayjs.Dayjs,
  dateEnd: dayjs.Dayjs,
  today: dayjs.Dayjs,
  paddingStartInMonths: number,
  paddingEndInMonths: number,
): {newStartDate: dayjs.Dayjs; newEndDate: dayjs.Dayjs} {
  const todayStart = today.subtract(paddingStartInMonths, 'M').startOf('M');

  let newStartDate = dateStart.subtract(paddingStartInMonths, 'M').startOf('M');
  const newEndDate = dateEnd.add(paddingEndInMonths, 'M').endOf('M');

  if (newStartDate.isAfter(todayStart)) {
    newStartDate = todayStart;
  }

  return {newStartDate, newEndDate};
}

export abstract class CalendarView extends Disposable {
  #dateStart: dayjs.Dayjs;
  #dateEnd: dayjs.Dayjs;
  #today: dayjs.Dayjs;
  #days = 0;
  #dataLoader: GanttDataLoader;

  constructor({dataLoader, dateEnd, dateStart, today}: CalendarViewProps) {
    super();
    const {newStartDate, newEndDate} = calculateDatesWithPadding(
      dateStart || today,
      dateEnd || today,
      today,
      dateStart ? 0 : this.getPaddingStartInMonths(),
      dateStart ? 0 : this.getPaddingEndInMonths(),
    );
    this.#dateStart = newStartDate;
    this.#dateEnd = newEndDate;
    this.#today = today;

    this.#dataLoader = dataLoader;
    this.updateDays();
  }

  protected abstract getPaddingStartInMonths(): number;
  protected abstract getPaddingEndInMonths(): number;
  protected abstract get ctx(): CalendarContext;

  abstract init(_ctx: CalendarContext): void;

  updateDates(dateStart: dayjs.Dayjs, dateEnd: dayjs.Dayjs): void {
    const {newStartDate, newEndDate} = calculateDatesWithPadding(
      dateStart,
      dateEnd,
      this.#today,
      this.getPaddingStartInMonths(),
      this.getPaddingEndInMonths(),
    );
    const startChanged = !newStartDate.isSame(this.#dateStart, 'd');
    const endChanged = !newEndDate.isSame(this.#dateEnd, 'd');

    if (startChanged || endChanged) {
      this.#setDate(newStartDate, newEndDate, startChanged, endChanged);
    }
  }

  #setDate(newStartDate: dayjs.Dayjs, newEndDate: dayjs.Dayjs, startChanged: boolean, endChanged: boolean): void {
    this.#dateStart = newStartDate;
    this.#dateEnd = newEndDate;
    this.updateDays();
    requestAnimationFrame(() => {
      this.ctx.eventEmitter.emit('calenderDatesChanged', {
        endChanged,
        startChanged,
        currentScrollDate: this.ctx.canvasView.dateStart,
        end: this.dateEnd,
        isoEnd: toIsoFormat(this.dateEnd),
        isoStart: toIsoFormat(this.dateStart),
        start: this.dateStart,
      });
    });
  }

  updateDays(): void {
    this.#days = this.#dateEnd.diff(this.#dateStart, 'd') + 1;
  }

  get dateStart(): dayjs.Dayjs {
    return this.#dateStart;
  }

  get dateEnd(): dayjs.Dayjs {
    return this.#dateEnd;
  }

  get height(): number {
    return this.#dataLoader.flatTree.length * this.ctx.rowHeight + this.ctx.headerHeight;
  }

  get width(): number {
    return this.#days * this.ctx.columnWidth;
  }

  get viewWidth(): number {
    return this.ctx.canvasView.width;
  }

  get days(): number {
    return this.#days;
  }
}
