import {getBillingTypeTranslatable} from '@octaved/flow/src/WorkPackage/BillingType';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import Hours from './Hours';
import {RowProps} from './Interfaces';

export default function RowWithoutMaxEffort({billable = false, sum, type}: RowProps): ReactElement {
  return (
    <>
      <div>
        <Trans i18nKey={getBillingTypeTranslatable(type, billable)} />
      </div>
      <div />
      <div className={'text-right'}>
        <Hours hours={sum.billableHours} shortUnit />
      </div>
    </>
  );
}
