import {Uuid} from '@octaved/typescript/src/lib';
import {createContext, ReactElement, ReactNode, useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {isWorkPackage} from '../../../Node/NodeIdentifiers';
import {nodeEntitySelector} from '../../../Modules/Selectors/NodeSelectors';
import {getNodeAncestrySelector} from '../../../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../../../Modules/State';

interface TaskListIdsContext {
  matchingTaskIds: Set<Uuid>;
  visibleTaskIds: Set<Uuid>;
}

interface TaskListNodeContext {
  workPackage: WorkPackage | null;
}

const taskListIdsContext = createContext<TaskListIdsContext>({} as TaskListIdsContext);

export function useTaskListForNodeContext(): TaskListIdsContext {
  return useContext(taskListIdsContext);
}

const taskListNodeContext = createContext<TaskListNodeContext>({} as TaskListNodeContext);

export function useTaskListNodeContext(): TaskListNodeContext {
  return useContext(taskListNodeContext);
}

export default function TaskListForNodeContext({
  children,
  filterMatchingTaskIds,
  filterVisibleTaskIds,
  nodeId,
}: {
  children: ReactNode;
  filterMatchingTaskIds: ReadonlyArray<Uuid>;
  filterVisibleTaskIds: ReadonlyArray<Uuid>;
  nodeId: Uuid;
}): ReactElement {
  const workPackage = useSelector((state: FlowState) => {
    const node = nodeEntitySelector(state)[nodeId];
    return isWorkPackage(node) ? node : getNodeAncestrySelector(state)(nodeId).workPackage;
  });

  const idsCtx = useMemo((): TaskListIdsContext => {
    return {
      matchingTaskIds: new Set(filterMatchingTaskIds),
      visibleTaskIds: new Set(filterVisibleTaskIds),
    };
  }, [filterMatchingTaskIds, filterVisibleTaskIds]);

  const nodeCtx = useMemo((): TaskListNodeContext => {
    return {
      workPackage,
    };
  }, [workPackage]);

  return (
    <taskListIdsContext.Provider value={idsCtx}>
      <taskListNodeContext.Provider value={nodeCtx}>{children}</taskListNodeContext.Provider>
    </taskListIdsContext.Provider>
  );
}
