import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {isNodeStatusNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {ExtendedNodeStatus, nodeStatusLabels} from '@octaved/flow/src/Node/NodeStatus/NodeStatus';
import {NodeStatusIcon} from '@octaved/flow/src/Node/NodeStatus/NodeStatusIcon';
import {useNodeStatus} from '@octaved/flow/src/Node/NodeStatus/NodeStatusSelectors';
import {cn} from '@octaved/ui';
import {cva} from 'class-variance-authority';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export function useNodeInfoBars(node: NodeType): ReadonlyArray<ExtendedNodeStatus> {
  const {project, workPackage} = useNodeAncestry(node.id, true);
  const status = useNodeStatus(node.id);
  return useMemo(() => {
    const result: ExtendedNodeStatus[] = [];
    if (node.isArchived) {
      result.push('isArchived');
    }
    if (isNodeStatusNode(node)) {
      if (project?.isTemplate) {
        result.push('isTemplate');
      }
      if (workPackage?.isOffer) {
        result.push('isOffer');
      } else if (status !== 'isOpen') {
        result.push(status);
      }
    }
    return result;
  }, [node, project?.isTemplate, status, workPackage?.isOffer]);
}

const statusBarVariants = cva('h-10 py-2 flex flex-shrink-0 items-center justify-center font-medium gap-x-2 border-y', {
  variants: {
    status: {
      isApprovedForBilling: 'bg-emerald-50 text-emerald-700 border-y-emerald-500',
      isArchived: 'bg-slate-50 text-slate-700 border-y-slate-400',
      isClosed: 'bg-zinc-50 text-zinc-700 border-y-zinc-600',
      isLocked: 'bg-amber-50 text-amber-700 border-y-amber-600',
      isOffer: 'bg-cyan-50 text-cyan-700 border-y-cyan-500 ',
      isOpen: '',
      isTemplate: 'bg-purple-50 text-purple-600 border-y-purple-500 ',
    } satisfies Record<ExtendedNodeStatus, string>,
  },
});

interface NodeTypeInfoBarProps {
  bars: ReadonlyArray<ExtendedNodeStatus>;
}

export default function NodeTypeInfoBar({bars}: NodeTypeInfoBarProps): ReactElement | null {
  const {t} = useTranslation();
  if (!bars.length) {
    return null;
  }

  const baseStatus = bars[0];
  const hasLockedStatus = bars.includes('isLocked') && baseStatus !== 'isLocked';

  return (
    <div className={cn(statusBarVariants({status: baseStatus}), 'relative')}>
      <NodeStatusIcon status={baseStatus} />
      <span className={'leading-3'}>{t(nodeStatusLabels[baseStatus])}</span>

      {hasLockedStatus && (
        <div className={'absolute right-2 top-1/2 -translate-y-1/2'}>
          <NodeStatusIcon status={'isLocked'} />
        </div>
      )}
    </div>
  );
}
