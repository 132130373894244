import {FLOW_TODAY_CHANGED} from '@octaved/flow/src/Modules/ActionTypes';
import {
  TimeRecordCreatedEvent,
  TimeRecordPatchedEvent,
  TimeRecordRemovedEvent,
  WorkTimeManagedEvent,
} from '@octaved/flow/src/Modules/Events';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {reduceRemoveByPrefix} from '@octaved/utilities/src/Search/SearchReducers';
import * as routes from '../../config/routes';
import {WorkingTimesChangedEvent} from '../Event/Events';
import {WorkingTimeDay, WorkingTimeDays} from '../Interfaces/WorkingTimeDays';
import {workingTimeDaysStateSelector} from '../Selectors/WorkingTimeDaysSelectors';
import {getOrgUserDateStoreKey} from '../Selectors/WorkingTimeEntriesSelectors';
import {
  FLOW_LOAD_WORKING_TIME_DAYS_FAILURE,
  FLOW_LOAD_WORKING_TIME_DAYS_REQUEST,
  FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS,
} from './ActionTypes';

interface LoadAction {
  keys: string[];
  response: WorkingTimeDay[];
  type: typeof FLOW_LOAD_WORKING_TIME_DAYS_REQUEST | typeof FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS;
}

type ScalarOrArrayLike<T> = T | ReadonlyArray<T> | ReadonlySet<T>;

const reducers = createReducerCollection<WorkingTimeDays>({});
export const workingTimeDaysReducer = reducers.reducer;
reducers.add<LoadAction>(FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS, (state, {response}) => {
  const newState = {...state};
  response.forEach((day) => {
    newState[getOrgUserDateStoreKey(day.userId, day.date)] = day;
  });
  return newState;
});

const stateReducers = createReducerCollection<EntityStates>({});
export const workingTimeDaysStateReducer = stateReducers.reducer;
stateReducers.add<LoadAction>(FLOW_LOAD_WORKING_TIME_DAYS_REQUEST, createTimestampReducer('keys', LOADING));
stateReducers.add<LoadAction>(FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS, createTimestampReducer('keys', LOADED));
stateReducers.add<TimeRecordCreatedEvent | TimeRecordPatchedEvent | TimeRecordRemovedEvent>(
  ['flow.TimeRecordCreatedEvent', 'flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  (state, action) => {
    const keys = action.affectedDates.map((date) => getOrgUserDateStoreKey(action.orgUserId, date));
    if (action.type === 'flow.TimeRecordPatchedEvent' && action.previousUserId) {
      keys.push(...action.affectedDates.map((date) => getOrgUserDateStoreKey(action.previousUserId!, date)));
    }
    return createTimestampReducer('keys', INVALIDATED)(state, {keys});
  },
);
stateReducers.add<WorkingTimesChangedEvent>(['flow.WorkingTimesChangedEvent'], (state, action) => {
  const keys: string[] = [];
  Object.entries(action.datesByOrgUsers).forEach(([orgUserId, dates]) => {
    dates.forEach((date) => {
      keys.push(getOrgUserDateStoreKey(orgUserId, date));
    });
  });
  return createTimestampReducer('keys', INVALIDATED)(state, {keys});
});

//Dynamic warnings depend on the work time definitions and are calculated server-side:
stateReducers.add<WorkTimeManagedEvent>(['flow.WorkTimeManagedEvent'], (state, action) => {
  let newState = state;
  action.affectedUnitIds.forEach((unitId) => {
    newState = reduceRemoveByPrefix(newState, unitId);
  });
  return newState;
});

//Clear when today changes - the server-side warnings depend on what is "today":
stateReducers.add(FLOW_TODAY_CHANGED, () => ({}));

function loadWorkingTimeDays(
  orgUserIds: ScalarOrArrayLike<Uuid>,
  dates: ScalarOrArrayLike<DateStr>,
): ActionDispatcher<void> {
  return (dispatch, getState) => {
    const state = workingTimeDaysStateSelector(getState());
    const userIdsToLoad = new Set<Uuid>();
    const datesToLoad = new Set<DateStr>();
    const keysToLoad = new Set<string>();
    (typeof orgUserIds === 'string' ? [orgUserIds] : orgUserIds).forEach((userId) => {
      // noinspection SuspiciousTypeOfGuard
      (typeof dates === 'string' ? [dates] : dates).forEach((date) => {
        const key = getOrgUserDateStoreKey(userId, date);
        const keyState = state[key];
        if (!keyState || isOutdated(keyState)) {
          userIdsToLoad.add(userId);
          datesToLoad.add(date);
          keysToLoad.add(key);
        }
      });
    });

    if (keysToLoad.size) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.getWorkingTimeDays,
          method: 'post',
          options: {
            data: {userIds: [...userIdsToLoad], dates: [...datesToLoad]},
          },
          types: {
            failureType: FLOW_LOAD_WORKING_TIME_DAYS_FAILURE,
            requestType: FLOW_LOAD_WORKING_TIME_DAYS_REQUEST,
            successType: FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS,
          },
        },
        keys: [...keysToLoad],
      });
    }
  };
}

export function useLoadWorkingTimeDays(
  orgUserIds: ScalarOrArrayLike<Uuid> | null,
  dates: ScalarOrArrayLike<DateStr> | null,
): void {
  useStoreEffect(
    (dispatch) => {
      if (orgUserIds && dates) {
        dispatch(loadWorkingTimeDays(orgUserIds, dates));
      }
    },
    [dates, orgUserIds],
    workingTimeDaysStateSelector,
  );
}
