import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {TasksCopiedEvent} from '../Events';
import {getNodeSearchKey} from '../Selectors/NodeSearchSelectors';
import {getWorkPackageSelector} from '../Selectors/PidSelectors';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<TasksCopiedEvent>('flow.TasksCopiedEvent', {
  assignedAnyProjectRole: 'clear', //required due to inheritance
  assignedProjectRoleId: 'clear', //required due to inheritance
  assignedProjectRoleType: 'clear', //required due to inheritance
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored', //new nodes can't be favorites, yet
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: 'clear',
  hasTimeBasedPredecessor: 'clear',
  isArchived: 'ignored', //not copied
  labelId: 'clear', //labels are copied
  mrIsActive: 'ignored',
  name: 'clear', //name not exposed in event
  nodeNotPlanned: 'ignored',
  nodePlannedAroundDateRange: 'ignored', //copied tasks have no planning date
  nodePlanningEndsAfterDate: 'ignored', //copied tasks have no planning date
  nodePlanningEndsBeforeDate: 'ignored', //copied tasks have no planning date
  nodePlanningStartsAfterDate: 'ignored', //copied tasks have no planning date
  nodePlanningStartsBeforeDate: 'ignored', //copied tasks have no planning date
  nodeType: {
    states: (state) => {
      const keys = [
        getNodeSearchKey('nodeType', EnumFlowNodeType.VALUE_TASK),
        getNodeSearchKey('nodeType', EnumFlowNodeType.VALUE_TASK_SECTION),
      ];
      if (keys.some((key) => !!state[key])) {
        const newState = {...state};
        keys.forEach((key) => delete newState[key]);
        return newState;
      }
      return state;
    },
  },
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: 'ignored', //not copied
  responsibleByAny: 'clear', //required due to inheritance
  responsibleUnitId: 'clear', //required due to inheritance
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: ({toWorkPackageId}, fullState) => {
    const workPackage = getWorkPackageSelector(fullState)(toWorkPackageId);
    if (workPackage) {
      return workPackage.isAutoChainActive ? 'clear' : 'ignored';
    } else {
      //Else we don't know whether the work package is auto chained or not, so we must clear:
      return 'clear';
    }
  },
  taskCompletedInDateRange: 'ignored', //copied tasks are not completed
  taskStatus: 'clear',
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
