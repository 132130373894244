import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {DropdownPopupItemType} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import InheritanceInfoPopup from '@octaved/flow/src/Components/InheritanceInfoPopup';
import {ResponsibleNode} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {makeNodeInheritResponsible} from '@octaved/flow/src/Modules/ResponsibleNode';
import {IconButton} from '@octaved/ui';
import {Import, MoreHorizontal} from 'lucide-react';
import {ReactElement, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

interface Props {
  node: ResponsibleNode;
  readonly: boolean;
  source: ResponsibleNode | null;
}

export default function InheritingTooltip({node, readonly, source}: Props): ReactElement | null {
  const sourceHasUnits = source?.responsibleGroups.length || source?.responsibleUsers.length;
  const [showInherited, setShowInherited] = useState(false);
  const infoPopupContext = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  if (!sourceHasUnits || !source) {
    return null;
  }

  return (
    <div ref={infoPopupContext}>
      <DropdownPopup
        items={[
          {
            icon: <Import />,
            iconColor: 'darkGrey',
            onClick: () => setShowInherited(true),
            token: 'general:showInheritance',
            type: DropdownPopupItemType.item,
          },
        ]}
        position={'bottom left'}
        closeOnSelect
      >
        <IconButton size={'xs'} icon={<MoreHorizontal />} variant={'ghost'} />
      </DropdownPopup>
      {showInherited && (
        <InheritanceInfoPopup
          inheritanceCase={node.definesOwnResponsible ? 'notInheriting' : 'inheriting'}
          mountContext={infoPopupContext}
          onClose={() => setShowInherited(false)}
          onRestoreInheritance={() => {
            dispatch(makeNodeInheritResponsible(node.id));
            setShowInherited(false);
          }}
          parentNodeId={source.id}
          readonly={readonly}
        />
      )}
    </div>
  );
}
