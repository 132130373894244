import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {AnyRight, isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {NodeRight} from '@octaved/security/src/Authorization/Rights';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {boolFilter} from '@octaved/utilities';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Label} from '../EntityInterfaces/Labels';
import {NodeEntity} from '../EntityInterfaces/NodeEntity';
import {isBoardPost} from '../Modules/Selectors/BoardPostSelectors';
import {labelSelector} from '../Modules/Selectors/LabelSelectors';
import {FlowState} from '../Modules/State';

function isXOrLabel(name: string): boolean {
  return name.includes('::');
}

function getLabelMainPart(name: string): string {
  return name.substr(0, name.indexOf('::'));
}

export function handleXOrLabelsOnAdd(labels: Label[], idToAdd: Uuid): Uuid[] {
  const labelToAdd = labels.find(({id}) => idToAdd === id);
  if (labelToAdd && isXOrLabel(labelToAdd.name)) {
    const labelNameMainPart = getLabelMainPart(labelToAdd.name);
    return labels
      .filter(({id, name}) => id !== idToAdd && isXOrLabel(name) && getLabelMainPart(name) === labelNameMainPart)
      .map(({id}) => id);
  }
  return [];
}

export function useAddXORLabel(): (newLabelId: Uuid, labels?: Uuid[]) => Uuid[] {
  const getLabelEntity = useSelector(labelSelector);

  return useCallback(
    (newLabelId, labels = []) => {
      if (!labels.includes(newLabelId)) {
        labels.push(newLabelId);
      }
      const labelEntities = boolFilter(labels.map(getLabelEntity));
      const toBeRemoved = handleXOrLabelsOnAdd(labelEntities, newLabelId);
      return labels.filter((id) => !toBeRemoved.includes(id));
    },
    [getLabelEntity],
  );
}

const manageLabelPermissions: Partial<Record<EnumFlowNodeType, AnyRight>> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'FLOW_NODE_GROUP_MANAGE_LABELS',
  [EnumFlowNodeType.VALUE_MATERIAL_RESOURCE]: 'FLOW_GLOBAL_MATERIAL_RESOURCES_MANAGE',
  [EnumFlowNodeType.VALUE_MATERIAL_RESOURCE_FOLDER]: 'FLOW_GLOBAL_MATERIAL_RESOURCES_MANAGE',
  [EnumFlowNodeType.VALUE_PROJECT]: 'FLOW_NODE_PROJECT_MANAGE_LABELS',
  [EnumFlowNodeType.VALUE_PROJECT_FOLDER]: 'FLOW_GLOBAL_PROJECT_FOLDERS_MANAGE',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_LABELS',
  [EnumFlowNodeType.VALUE_TASK]: 'FLOW_NODE_TASK_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_TASK_SECTION]: 'FLOW_NODE_TASK_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'FLOW_NODE_WORK_PACKAGE_MANAGE_LABELS',
};

export function useCanEditNodeLabels(node: NodeEntity): boolean {
  return useSelector((s: FlowState) => {
    let right: AnyRight | '' = '';
    if (isBoardPost(node)) {
      right =
        node.createdBy === currentOrgUserIdSelector(s)
          ? 'FLOW_NODE_BOARD_POST_EDIT_OWN'
          : 'FLOW_NODE_BOARD_POST_EDIT_OTHER';
    } else {
      right = manageLabelPermissions[node.nodeType] || '';
    }
    return !!right && isGrantedSelector(s)(right as NodeRight, node.id);
  });
}
