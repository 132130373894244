import {BillingSearchCondition} from '../EntityInterfaces/Billing/BillingSearch';
import {NodeType} from '../EntityInterfaces/Nodes';
import {useBillingSearch} from '../Modules/Billing/BillingSearch';
import {isGroup, isProject, isSubWorkPackage, isWorkPackage} from '../Node/NodeIdentifiers';

export function useHasBillingsAttached(node: NodeType | null | undefined): {
  hasLoadedOnce: boolean;
  hasBilling: boolean;
} {
  let query: BillingSearchCondition | null = null;
  if (isProject(node)) {
    query = ['projectId', node.id];
  } else if (isGroup(node)) {
    query = ['rootNodeId', node.id];
  } else if (isWorkPackage(node)) {
    query = ['workPackageId', node.id];
  } else if (isSubWorkPackage(node)) {
    query = ['timeTrackingReferenceNodeId', node.id];
  }
  const {hasLoadedOnce, ids} = useBillingSearch(query);
  return {
    hasLoadedOnce,
    hasBilling: ids.length > 0,
  };
}
