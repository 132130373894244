import {NodeTimeControlStandard} from '@octaved/flow/src/EntityInterfaces/TimeControlledEntity';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PatchPartial} from '../NodeTimeControlSettings';

function createMonthOptions(): {text: string; value: string}[] {
  const options = [];
  const day = dayjs();
  for (let i = 0; i < 12; i++) {
    options.push({text: day.month(i).format('MMMM'), value: i + ''});
  }
  return options;
}

interface Props {
  timeControl: NodeTimeControlStandard;
  patch: PatchPartial;
  readonly: boolean;
}

export default function Months({patch, timeControl, readonly}: Props): ReactElement {
  const {t} = useTranslation();
  const day = dayjs(timeControl.from);
  const [options] = useState(() => createMonthOptions());
  return (
    <TWSelect
      value={day.month() + ''}
      disabled={readonly}
      onValueChange={(value) => patch({from: toIsoFormat(day.startOf('month').month(+value))})}
    >
      <TWSelectTrigger className={'w-full'}>
        <TWSelectValue placeholder={t('general:dropdownSelectPlaceholder')} />
      </TWSelectTrigger>
      <TWSelectContent>
        {options.map(({text, value}) => (
          <TWSelectItem value={value} key={value}>
            {text}
          </TWSelectItem>
        ))}
      </TWSelectContent>
    </TWSelect>
  );
}
