import FlowOutlet from '@octaved/flow/src/Components/FlowOutlet';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from '@octaved/flow/src/Drawer/InspectorContext/InspectorContext';
import {MaterialResource} from '@octaved/flow/src/EntityInterfaces/MaterialResource';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useLoadNodes, useWorkPackage} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {getMaterialResourceSelector} from '@octaved/flow/src/Modules/Selectors/MaterialResourceSelectors';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {PlanningDate} from '../EntityInterfaces/PlanningDates';
import {useLoadPlanningDates} from '../Modules/PlanningDates';
import {getPlanningDateSelector} from '../Selectors/PlanningDateSelectors';

export interface IPlanningDateInspectorContext extends BaseInspectorContext {
  selectedEntity: PlanningDate;
  materialResource: MaterialResource;
  assignedNode: WorkPackage | null;
  selectedType: 'planningDate';
}

export function isPlanningDateInspectorContext(
  context: InspectorContext | null,
): context is IPlanningDateInspectorContext {
  return context?.selectedType === 'planningDate';
}

export function usePlanningDateInspectorContext(): IPlanningDateInspectorContext {
  const context = useInspectorContext();
  if (!isPlanningDateInspectorContext(context)) {
    throw new Error('is not materialResource inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IPlanningDateInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector(getPlanningDateSelector)(baseParams!.selectedId);
  const assignedNode = useWorkPackage(selectedEntity?.assignedNodeId) || null;
  const materialResource = useSelector(getMaterialResourceSelector)(selectedEntity?.nodeId);

  return useMemo<IPlanningDateInspectorContext | null>(() => {
    if (baseParams && selectedEntity && materialResource && (selectedEntity.assignedNodeId === null || assignedNode)) {
      return {
        ...baseParams,
        assignedNode,
        materialResource,
        selectedEntity,
        selectedType: 'planningDate',
      };
    }
    return null;
  }, [assignedNode, baseParams, materialResource, selectedEntity]);
}

export default function PlanningDateInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  const nodesToLoad = useMemo(() => {
    const toLoad = [];
    if (inspectorContextProps?.selectedEntity?.assignedNodeId) {
      toLoad.push(inspectorContextProps.selectedEntity.assignedNodeId);
    }
    if (inspectorContextProps?.selectedEntity?.nodeId) {
      toLoad.push(inspectorContextProps.selectedEntity.nodeId);
    }
    return toLoad;
  }, [inspectorContextProps?.selectedEntity?.assignedNodeId, inspectorContextProps?.selectedEntity?.nodeId]);

  const {isLoading: isLoadingNodes} = useLoadNodes(nodesToLoad, true);
  const {isLoading: isLoadingPlanningDates} = useLoadPlanningDates(nodesToLoad, true);
  const isLoading = isLoadingNodes || isLoadingPlanningDates;
  const hasLoadedOnce = useLoadedValue(isLoading, !isLoading);

  if (!hasLoadedOnce) {
    return <AppLoader />;
  }

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
