import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {AdvancedCreateOffer} from '../../../../EntityInterfaces/Filter/Custom/CreateOffer';
import {ProjectFilterQueries} from '../../../../EntityInterfaces/Filter/ProjectFilters';

export function advancedCreateOfferQueryGenerator(
  queries: ProjectFilterQueries,
  {hideProjectsWithoutOffers}: AdvancedCreateOffer,
): void {
  if (hideProjectsWithoutOffers) {
    queries.projects.push({
      and: [['nodeType', EnumFlowNodeType.VALUE_PROJECT], withAncestors(['wpIsOffer'], true)],
    });
  }
}
