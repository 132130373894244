import * as React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import {ChevronRight, Circle, Lock, Square, SquareCheck} from 'lucide-react';
import {cn, Tooltip} from '.';

const TWDropdownMenu = DropdownMenuPrimitive.Root;

const TWDropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const TWDropdownMenuGroup = DropdownMenuPrimitive.Group;

const TWDropdownMenuPortal = DropdownMenuPrimitive.Portal;

const TWDropdownMenuSub = DropdownMenuPrimitive.Sub;

const TWDropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

const TWDropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
    isLocked?: boolean;
  }
>(({className, isLocked, inset, children, ...props}, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none hover:bg-slate-100 focus:bg-slate-100 data-[state=open]:bg-slate-100',
      inset && 'pl-8',
      isLocked && '!pointer-events-auto cursor-auto opacity-50 hover:bg-inherit',
      className,
    )}
    {...props}
  >
    {children}
    {isLocked && <Lock className={'ml-auto mr-0.5 size-3'} />}
    <ChevronRight className={cn('h-4 w-4', !isLocked && 'ml-auto')} />
  </DropdownMenuPrimitive.SubTrigger>
));
TWDropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

const TWDropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({className, ...props}, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      'z-50 min-w-[8rem] overflow-hidden bg-white p-1 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));
TWDropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

const TWDropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({className, sideOffset = 4, ...props}, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'pointer-events-none z-50 min-w-[8rem] overflow-hidden bg-white p-1 shadow-oldPopups data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
TWDropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const TWDropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
    colorScheme?: 'green' | 'red' | 'default';
    isLocked?: boolean;
  }
>(({className, inset, colorScheme, isLocked, ...props}, ref) => (
  <Tooltip position={'top right'} toolTipTranslation={isLocked ? 'nodeDetails:cannotViewDetails' : undefined}>
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={cn(
        'relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-slate-100 focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        inset && 'pl-8',
        colorScheme === 'green' && 'text-emerald-600 hover:bg-emerald-100 focus:bg-emerald-100',
        colorScheme === 'red' && 'text-red-600 hover:bg-red-100 focus:bg-red-100',
        isLocked && '!pointer-events-auto cursor-auto opacity-50 hover:bg-inherit',
        className,
      )}
      disabled={isLocked}
      {...props}
      onClick={(e) => (isLocked ? e.preventDefault() : props.onClick && props.onClick(e))}
    >
      <div className={'flex w-full items-center justify-between gap-2'}>
        <div className={'flex'}>{props.children}</div>
        {isLocked && <Lock className={'mr-0.5 size-3'} />}
      </div>
    </DropdownMenuPrimitive.Item>
  </Tooltip>
));
TWDropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const TWDropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem> & {isLocked?: boolean}
>(({className, children, checked, ...props}, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
      props.isLocked && 'opacity-50',
    )}
    checked={checked}
    disabled={props.isLocked ?? props.disabled}
    {...props}
    onClick={(e) => {
      e.preventDefault();
      props.onClick?.(e);
    }}
  >
    <span className={'absolute left-2 flex h-3.5 w-3.5 items-center justify-center'}>
      {checked ? <SquareCheck className={'h-4 w-4'} /> : <Square className={'h-4 w-4'} />}
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
TWDropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

const TWDropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({className, children, ...props}, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-slate-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  >
    <span className={'absolute left-2 flex h-3.5 w-3.5 items-center justify-center'}>
      <DropdownMenuPrimitive.ItemIndicator>
        <Circle className={'h-2 w-2 fill-current'} />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
));
TWDropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

const TWDropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({className, inset, ...props}, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn('px-1.5 pb-0.5 pt-1.5 text-sm font-semibold uppercase tracking-wide', inset && 'pl-8', className)}
    {...props}
  />
));
TWDropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const TWDropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({className, ...props}, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px group-first:hidden', className)}
    {...props}
  />
));
TWDropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const TWDropdownMenuShortcut = ({className, ...props}: React.HTMLAttributes<HTMLSpanElement>): React.ReactElement => {
  return <span className={cn('ml-auto text-xs tracking-widest opacity-60', className)} {...props} />;
};
TWDropdownMenuShortcut.displayName = 'DropdownMenuShortcut';

export {
  TWDropdownMenu,
  TWDropdownMenuTrigger,
  TWDropdownMenuContent,
  TWDropdownMenuItem,
  TWDropdownMenuCheckboxItem,
  TWDropdownMenuRadioItem,
  TWDropdownMenuLabel,
  TWDropdownMenuSeparator,
  TWDropdownMenuShortcut,
  TWDropdownMenuGroup,
  TWDropdownMenuPortal,
  TWDropdownMenuSub,
  TWDropdownMenuSubContent,
  TWDropdownMenuSubTrigger,
  TWDropdownMenuRadioGroup,
};
