import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {deleteProjectFolder, putProjectFolder} from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {createTextInputVariableRules, RulesList} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {ObjectContains} from '@octaved/validation/src';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ProjectFolder, ProjectFolderPatchData} from '../EntityInterfaces/ProjectFolder';
import {useProjectUiStatePatch} from '../Pages/Projects/Projects';
import {
  FLOW_CHANGE_PROJECT_FOLDER_FAILURE,
  FLOW_CHANGE_PROJECT_FOLDER_REQUEST,
  FLOW_CHANGE_PROJECT_FOLDER_SUCCESS,
  FLOW_CREATE_PROJECT_FOLDER_FAILURE,
  FLOW_CREATE_PROJECT_FOLDER_REQUEST,
  FLOW_CREATE_PROJECT_FOLDER_SUCCESS,
  FLOW_REMOVE_PROJECT_FOLDER_FAILURE,
  FLOW_REMOVE_PROJECT_FOLDER_REQUEST,
  FLOW_REMOVE_PROJECT_FOLDER_SUCCESS,
} from './ActionTypes';
import {createNodeEntity, transformNodeEntityToPatchData, transformPatchDataToNodeEntity} from './Nodes';
import {getProjectFolderSelector} from './Selectors/ProjectFolderSelectors';
import {projectsSelectedProjectFolderSelector} from './Selectors/UiPages/ProjectsSelector';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

export function createProjectFolderEntity(): ProjectFolder {
  return {
    ...createNodeEntity(EnumFlowNodeType.VALUE_PROJECT_FOLDER),
    data: {},
    nodeType: EnumFlowNodeType.VALUE_PROJECT_FOLDER,
  };
}

export function transformProjectFolderToPatchData(projectFolder: ProjectFolder): ProjectFolderPatchData {
  return transformNodeEntityToPatchData<ProjectFolderPatchData>(projectFolder);
}

function transformPatchDataToProjectFolder(partial: DeepPartial<ProjectFolderPatchData>): DeepPartial<ProjectFolder> {
  return transformPatchDataToNodeEntity<ProjectFolder>(partial);
}

function getValidationRules(id: Uuid, data: DeepPartial<ProjectFolderPatchData>, isCreation: boolean): RulesList {
  const rules: RulesList = [];
  if (isCreation || data.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        data.name!,
        'systemSettings:ou.error.ouNameEmpty',
        'systemSettings:ou.error.ouNameTooLong',
        `name_${id}`,
      ),
    );
  }
  return rules;
}

export function useCreateProjectFolder(): (parentNodeId: Uuid, data: ProjectFolderPatchData) => boolean {
  const dispatch = useDispatch();
  return useCallback(
    (
      parentNodeId,
      {
        planningPredecessors: _planningPredecessors, //not allowed to patch
        planningSuccessors: _planningSuccessors, //not allowed to patch
        ...data
      },
    ) => {
      if (!validateErrorRules(getValidationRules(data.id, data, true), dispatch)) {
        return false;
      }
      dispatch({
        [CALL_API]: {
          endpoint: putProjectFolder,
          method: 'put',
          options: {
            data: {
              ...transformPatchDataToProjectFolder(data),
              parentNodeId,
            },
            urlParams: {projectFolderId: data.id},
          },
          types: {
            failureType: FLOW_CREATE_PROJECT_FOLDER_FAILURE,
            requestType: FLOW_CREATE_PROJECT_FOLDER_REQUEST,
            successType: FLOW_CREATE_PROJECT_FOLDER_SUCCESS,
          },
        },
      });
      return true;
    },
    [dispatch],
  );
}

export function patchProjectFolder(
  projectFolderId: Uuid,
  partial: Partial<ProjectFolder>,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const projectFolder = getProjectFolderSelector(getState())(projectFolderId);
    if (projectFolder) {
      if (!ObjectContains(projectFolder, partial)) {
        await dispatch({
          [CALL_API]: {
            endpoint: routes.patchProjectFolder,
            method: 'patch',
            options: {
              data: partial,
              urlParams: {projectFolderId},
            },
            types: {
              failureType: FLOW_CHANGE_PROJECT_FOLDER_FAILURE,
              requestType: FLOW_CHANGE_PROJECT_FOLDER_REQUEST,
              successType: FLOW_CHANGE_PROJECT_FOLDER_SUCCESS,
            },
          },
          patchedNodeId: projectFolderId, //for the optimistic patch
        });
      }
    }
  };
}

export function useDeleteProjectFolder(): (projectFolderId: Uuid) => void {
  const dispatch = useDispatch();
  const projectsSelectedFolder = useSelector(projectsSelectedProjectFolderSelector);
  const patchProjectsUi = useProjectUiStatePatch();
  return useCallback(
    (projectFolderId) => {
      dispatch({
        [CALL_API]: {
          endpoint: deleteProjectFolder,
          method: 'del',
          options: {
            urlParams: {projectFolderId},
          },
          types: {
            failureType: FLOW_REMOVE_PROJECT_FOLDER_FAILURE,
            requestType: FLOW_REMOVE_PROJECT_FOLDER_REQUEST,
            successType: FLOW_REMOVE_PROJECT_FOLDER_SUCCESS,
          },
        },
        nodeIds: [projectFolderId], //for the optimistic remove
      });
      if (projectFolderId === projectsSelectedFolder) {
        patchProjectsUi({selectedProjectFolder: null});
      }
    },
    [dispatch, patchProjectsUi, projectsSelectedFolder],
  );
}
