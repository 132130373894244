import {HelpTooltip, TooltipProps} from '@octaved/ui';
import {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import {flowStyleContext} from '../../../../Styles/StyleContext';

type HelpTooltipProps = Pick<TooltipProps, 'content' | 'toolTipTranslation' | 'wide'>;

interface Props {
  helpTooltip?: HelpTooltipProps;
  token: string;
}

export default function Header({helpTooltip, token}: Props): ReactElement {
  const {
    COLORS: {FONT},
  } = useContext(flowStyleContext);
  return (
    <div className={'header'}>
      <span className={'span'}>
        <Trans i18nKey={token} />
      </span>
      {!!helpTooltip && <HelpTooltip {...helpTooltip} inline />}
      {/*language=SCSS*/}
      <style jsx>{`
        .header {
          display: flex;
          grid-gap: 8px;
          align-items: center;
        }

        .span {
          font-size: 16px;
          color: ${FONT.HIGHLIGHTED};
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
