import CurrencySymbol from '@octaved/flow/src/Components/CurrencySymbol/CurrencySymbol';
import {TWProgress} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

interface NodeSumOfPriceLimitsProps {
  countWorkPackages: number;
  countWorkPackagesWithPrice: number;
  headline: string;
  isLoading: boolean;
  maxPrice: number | null;
  sumPriceLimits: number;
}

export default function NodeSumOfPriceLimits({
  frame: Frame,
  countWorkPackages,
  countWorkPackagesWithPrice,
  headline,
  maxPrice,
  sumPriceLimits,
}: NodeSumOfPriceLimitsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();

  if (!maxPrice) {
    return null;
  }

  return (
    <Frame labelToken={headline}>
      <div className={'flex flex-col gap-y-1'}>
        {!!maxPrice && (
          <div className={'grid grid-cols-2 gap-x-3'}>
            <TWProgress
              progress={(sumPriceLimits * 100) / maxPrice}
              color={sumPriceLimits > maxPrice ? 'red' : 'green'}
              size={'xl'}
            />
            <span>
              <span>
                {t('general:ranges.nOfM', {
                  m: formatDecimal(maxPrice),
                  n: formatDecimal(sumPriceLimits),
                })}
              </span>{' '}
              <CurrencySymbol />
            </span>
          </div>
        )}

        {!maxPrice && (
          <div className={'font-bold leading-8'}>
            <span>
              {formatDecimal(sumPriceLimits)} <CurrencySymbol />
            </span>
          </div>
        )}

        <div className={'text-sm'}>
          {t('pages:projects.inspector.timeAndFinancesProject.workPackagesWithPricesLabel')}
          {': '}

          {t('general:ranges.nOfM', {
            m: countWorkPackages,
            n: countWorkPackagesWithPrice,
          })}
        </div>
      </div>
    </Frame>
  );
}
