import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {CalendarViewProps} from '../../../Canvas/Calendar/Context/CalendarView';
import {ProjectBasedCalendarView} from '../../../Canvas/ProjectBasedGantt/ProjectBasedCalendarView';

interface GanttComparisonCalendarViewProps extends CalendarViewProps {
  rootNodeId: Uuid;
}

export class GanttComparisonCalendarView extends ProjectBasedCalendarView {
  #rootNodeIds: Uuid[];
  constructor(props: GanttComparisonCalendarViewProps) {
    super(props);
    this.#rootNodeIds = [props.rootNodeId];
  }

  protected projectPlanningSelectedProjectsSelector(_state: FlowState): Uuid[] {
    return this.#rootNodeIds;
  }
}
