import {useIsMobileSafari} from '@octaved/ui-components/src/BrowserDetect/IsMobileSafari';
import classNames from 'classnames';
import {ReactElement} from 'react';

interface Props {
  children: ReactElement;
}

export default function ViewportHeight({children}: Props): ReactElement {
  const isIpad = useIsMobileSafari();
  return (
    <div className={classNames('viewportHeight', {isIpad})}>
      {children}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .viewportHeight {
          height: 100vh;
          overflow: hidden;
        }

        //noinspection CssInvalidPropertyValue
        .isIpad {
          height: 100dvh;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
