import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {toChildren, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {useCombinedNodeSearch} from '../Hooks/NodeSearch';
import {
  projectsSelectedProjectFolderSelector,
  projectsShowSubFolderProjectsSelector,
} from '../Selectors/UiPages/ProjectsSelector';

const pids: NodeSearchCondition = {
  or: [
    ['nodeType', EnumFlowNodeType.VALUE_PROJECT],
    ['nodeType', EnumFlowNodeType.VALUE_GROUP],
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
  ],
};

export function getInFolderCondition(projectFolder: Uuid, includeSubProjectFolders: boolean): NodeSearchCondition {
  if (includeSubProjectFolders) {
    return withDescendants(projectFolder, true);
  }
  return withDescendants(
    {
      and: [toChildren(projectFolder), ['nodeType', EnumFlowNodeType.VALUE_PROJECT]],
    },
    true,
  );
}

export function useInFolderCondition(): NodeSearchCondition {
  const projectFolder = useSelector(projectsSelectedProjectFolderSelector);
  const includeSubProjectFolders = useSelector(projectsShowSubFolderProjectsSelector);
  return getInFolderCondition(projectFolder, includeSubProjectFolders);
}

/**
 * Gets the unfiltered ids that are theoretically available in the entire selected project folder.
 */
export function useMainProjectTreeUnfilteredPidIds(type?: EnumFlowNodeType): ReadonlyArray<Uuid> {
  return useCombinedNodeSearch({and: [type ? ['nodeType', type] : pids, useInFolderCondition()]}).nodeIds;
}

/**
 * Gets the unfiltered work package ids that are theoretically available in the entire selected project folder.
 */
export function useMainProjectTreeUnfilteredWorkPackageIds(): ReadonlyArray<Uuid> {
  return useMainProjectTreeUnfilteredPidIds(EnumFlowNodeType.VALUE_WORK_PACKAGE);
}
