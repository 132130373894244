import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {
  TimeEffortPriceSumPerBillingType,
  TimeEffortPriceSumsPerBillingType,
} from '../../../../../EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';

export interface Totals {
  billableHoursWithMaxEffort: number;
  billableHoursWithoutMaxEffort: number;
  maxEffortHours: number;
  neverBillableHours: number;
  totalBillableHours: number;
}

type TotalComputer = (totals: Totals, sum: TimeEffortPriceSumPerBillingType) => void;

const computeWithMaxEffort: TotalComputer = (totals, sum) => {
  totals.billableHoursWithMaxEffort += sum.billableHours;
  totals.maxEffortHours += sum.maxEffortHours;
};

const totalComputers: Record<EnumFlowPidBillingType, TotalComputer> = {
  [EnumFlowPidBillingType.VALUE_CONTINGENT]: computeWithMaxEffort,
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: (totals, sum) => {
    totals.billableHoursWithMaxEffort += sum.billableHoursWithMaxEffort;
    totals.billableHoursWithoutMaxEffort += sum.billableHoursWithoutMaxEffort;
    totals.maxEffortHours += sum.maxEffortHours;
  },
  [EnumFlowPidBillingType.VALUE_EFFORT]: (totals, sum) => {
    totals.billableHoursWithoutMaxEffort += sum.billableHours;
  },
  [EnumFlowPidBillingType.VALUE_EFFORT_EST]: computeWithMaxEffort,
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP]: computeWithMaxEffort,
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]: computeWithMaxEffort,
  [EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE]: (totals, sum) => {
    totals.neverBillableHours += sum.billableHours;
  },
};

/**
 * "sum.billableHours" is the normal sum of all `wp.trackedMinutes.billed / 60`
 * This sum needs to be counted towards billing types that are either with or without limit.
 * However the "fixedPrice" type can have both. For that type the distinction between "sum.billableHoursWithMaxEffort"
 * and "sum.billableHoursWithoutMaxEffort" is made by whether or not "wp.maxEffort > 0".
 * This distinction however must not be used for all the other types because they could also have a 0 there if they
 * are misconfigured.
 */
export function createTotals(sums: TimeEffortPriceSumsPerBillingType): Totals {
  const totals: Totals = {
    billableHoursWithMaxEffort: 0,
    billableHoursWithoutMaxEffort: 0,
    maxEffortHours: 0,
    neverBillableHours: 0,
    totalBillableHours: 0,
  };
  Object.entries(sums).forEach(([key, value]) => {
    totalComputers[key as EnumFlowPidBillingType](totals, value as TimeEffortPriceSumPerBillingType);
  });
  totals.totalBillableHours =
    totals.billableHoursWithoutMaxEffort + totals.billableHoursWithMaxEffort + totals.neverBillableHours;
  return totals;
}
