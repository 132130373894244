import {cn} from '@octaved/ui';
import {CheckCircle, LucideIcon} from 'lucide-react';
import {ReactElement} from 'react';
import {ExtendedNodeStatus, NodeCompletionStatus} from './NodeStatus';

export const nodeStatusColors = {
  isApprovedForBilling: '#019669',
  isArchived: '#334155',
  isClosed: '#71717A',
  isCompleted: '#019669',
  isLocked: '#F59E0B',
  isNotCompleted: '#595E65',
  isOffer: '#0BB6D4',
  isOpen: '#0284C7',
  isTemplate: '#7E22CE',
} satisfies Record<NodeCompletionStatus | ExtendedNodeStatus, string>;

export interface StatusIconProps {
  className?: string;
  small?: boolean;
}

function StatusIcon({className, icon: Icon, small}: StatusIconProps & {icon: LucideIcon}): ReactElement {
  return (
    <div
      className={cn(
        'flex size-6 shrink-0 items-center justify-center rounded-full text-base leading-none',
        small && 'size-4 text-sm',
        className,
      )}
    >
      <Icon className={cn('size-4', small && 'size-2.5')} />
    </div>
  );
}

export function IsApprovedForBillingIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isApprovedForBilling} />
      <path d={'M6 10.5H14'} stroke={'white'} strokeWidth={'1.25'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <path d={'M6 13.5H12'} stroke={'white'} strokeWidth={'1.25'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <path
        d={
          'M16 7.87502C15.0149 6.98653 13.7309 6.49606 12.4002 6.50002C11.682 6.509 10.9726 6.65838 10.3125 6.93965C9.65242 7.22091 9.05461 7.62855 8.55318 8.13928C8.05176 8.65002 7.65655 9.25384 7.39013 9.91627C7.12371 10.5787 6.99129 11.2868 7.00044 12C7.00044 15.025 9.4234 17.5 12.4002 17.5C13.7847 17.5 15.0308 16.95 16 16.125'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export function IsArchivedIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isArchived} />
      <path
        d={
          'M16.95 7H7.05C6.74624 7 6.5 7.17909 6.5 7.4V8.6C6.5 8.82091 6.74624 9 7.05 9H16.95C17.2538 9 17.5 8.82091 17.5 8.6V7.4C17.5 7.17909 17.2538 7 16.95 7Z'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M7 9V15.7692C7 16.0957 7.1317 16.4087 7.36612 16.6395C7.60054 16.8703 7.91848 17 8.25 17H15.75C16.0815 17 16.3995 16.8703 16.6339 16.6395C16.8683 16.4087 17 16.0957 17 15.7692V9'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={'M10.5 12H13.5'}
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export function IsClosedIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isClosed} />
      <path
        d={
          'M7 14.3571C7 14.3571 7.625 13.7143 9.5 13.7143C11.375 13.7143 12.625 15 14.5 15C16.375 15 17 14.3571 17 14.3571V6.64286C17 6.64286 16.375 7.28571 14.5 7.28571C12.625 7.28571 11.375 6 9.5 6C7.625 6 7 6.64286 7 6.64286V14.3571Z'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path d={'M7 18V14'} stroke={'white'} strokeWidth={'1.25'} strokeLinecap={'round'} strokeLinejoin={'round'} />
    </svg>
  );
}

export function IsCompletedIcon({className, small}: StatusIconProps): ReactElement {
  return <StatusIcon className={cn('text-emerald-600', className)} icon={CheckCircle} small={small} />;
}

export function IsLockedIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isLocked} />
      <path
        d={
          'M15.8889 10H8.11111C7.49746 10 7 10.5698 7 11.2727V15.7273C7 16.4302 7.49746 17 8.11111 17H15.8889C16.5025 17 17 16.4302 17 15.7273V11.2727C17 10.5698 16.5025 10 15.8889 10Z'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M9.5 10V7.77778C9.5 7.04107 9.76339 6.33453 10.2322 5.81359C10.7011 5.29266 11.337 5 12 5C12.663 5 13.2989 5.29266 13.7678 5.81359C14.2366 6.33453 14.5 7.04107 14.5 7.77778V10'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export function IsNotCompletedIcon({className, small}: StatusIconProps): ReactElement {
  return <StatusIcon className={cn('text-slate-600', className)} icon={CheckCircle} small={small} />;
}

export function IsOfferIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isOffer} />
      <path d={'M16 8L8 16'} stroke={'white'} strokeWidth={'1.25'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <path
        d={
          'M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M15.5 17C16.3284 17 17 16.3284 17 15.5C17 14.6716 16.3284 14 15.5 14C14.6716 14 14 14.6716 14 15.5C14 16.3284 14.6716 17 15.5 17Z'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export function IsOpenIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isOpen} />
      <path
        d={'M8.5 6L17.5 12L8.5 18V6Z'}
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export function IsTemplateIcon({small}: StatusIconProps): ReactElement {
  return (
    <svg
      className={cn('size-6 shrink-0', small && 'size-4')}
      viewBox={'0 0 24 24'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle cx={'12'} cy={'12'} r={'11'} fill={nodeStatusColors.isTemplate} />
      <path d={'M13.5 12V15'} stroke={'white'} strokeWidth={'1.25'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <path d={'M12 13.5H15'} stroke={'white'} strokeWidth={'1.25'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      <path
        d={
          'M16.3571 9.5H10.6429C10.0117 9.5 9.5 10.0117 9.5 10.6429V16.3571C9.5 16.9883 10.0117 17.5 10.6429 17.5H16.3571C16.9883 17.5 17.5 16.9883 17.5 16.3571V10.6429C17.5 10.0117 16.9883 9.5 16.3571 9.5Z'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
      <path
        d={
          'M7.64286 14.5C7.01429 14.5 6.5 13.9857 6.5 13.3571V7.64286C6.5 7.01429 7.01429 6.5 7.64286 6.5H13.3571C13.9857 6.5 14.5 7.01429 14.5 7.64286'
        }
        stroke={'white'}
        strokeWidth={'1.25'}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
}

export const nodeStatusIcons = {
  isApprovedForBilling: IsApprovedForBillingIcon,
  isArchived: IsArchivedIcon,
  isClosed: IsClosedIcon,
  isCompleted: IsCompletedIcon,
  isLocked: IsLockedIcon,
  isNotCompleted: IsNotCompletedIcon,
  isOffer: IsOfferIcon,
  isOpen: IsOpenIcon,
  isTemplate: IsTemplateIcon,
} satisfies Record<NodeCompletionStatus | ExtendedNodeStatus, (p: StatusIconProps) => ReactElement>;
