import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Dispatch, SetStateAction} from 'react';
import {calculateDateLimits, moveBar} from '../../../../../../../Calculations/BarMovement';
import {addPlanningDate, updatePlanningDate} from '../../../../../../../Modules/PlanningDates';
import {DragMode} from '../../../../../../../Modules/Ui';
import {getPlanningDatesForNodeSelector} from '../../../../../../../Selectors/PlanningDateSelectors';
import {DragStart, DraggingImpl} from './Dragging';

interface DragState {
  setAdjustedPlannedStart: Dispatch<SetStateAction<DateStr>>;
  setAdjustedPlannedEnd: Dispatch<SetStateAction<DateStr>>;
  nodeId: Uuid;
  planningDateId: Uuid;
  limitEnd: DateTimeStr | null;
  limitStart: DateTimeStr | null;
  isDragging: boolean;
  plannedStart: DateStr;
  plannedEnd: DateStr;
  createOnDrag?: boolean;
}

export class HorizontalDragging extends DraggingImpl<DragState> {
  onEnd = (
    clientX: number,
    _clientY: number,
    _event: MouseEvent | TouchEvent,
    clientStartX: number,
    _clientStartY: number,
  ): void => {
    if (!this.state?.isDragging) {
      this.cancel();
      return;
    }
    const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.ctx.store.getState());
    const moveResult = moveBar(
      clientStartX,
      clientX,
      this.state.plannedStart,
      this.state.plannedEnd,
      this.ctx.calendarContext.cellWidth,
      this.state.limitStart,
      this.state.limitEnd,
      getWorkMinutesAtDate,
    );
    if (this.state.createOnDrag) {
      this.ctx.store.dispatch(
        addPlanningDate(
          this.state.nodeId,
          this.state.planningDateId,
          isoToIsoDateTimeFormat(moveResult.plannedStart),
          isoToIsoDateTimeFormat(moveResult.plannedEnd),
          true,
        ),
      );
    } else {
      this.ctx.store.dispatch(
        updatePlanningDate(this.state.nodeId, this.state.planningDateId, {
          plannedEnd: isoToIsoDateTimeFormat(moveResult.plannedEnd),
          plannedStart: isoToIsoDateTimeFormat(moveResult.plannedStart),
        }),
      );
    }

    this.delayedCancel();
  };

  onMove = (
    clientX: number,
    _clientY: number,
    _event: MouseEvent | TouchEvent,
    clientStartX: number,
    _clientStartY: number,
  ): void => {
    if (this.state) {
      const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.ctx.store.getState());
      const result = moveBar(
        clientStartX,
        clientX,
        this.state.plannedStart,
        this.state.plannedEnd,
        this.ctx.calendarContext.cellWidth,
        this.state.limitStart,
        this.state.limitEnd,
        getWorkMinutesAtDate,
      );
      const movement = clientStartX - clientX;
      if (movement > 10 || movement < -10 || this.state.isDragging) {
        this.state.setAdjustedPlannedStart(result.plannedStart);
        this.state.setAdjustedPlannedEnd(result.plannedEnd);
        this.closeInspector();
        this.state.isDragging = true;
        this.setDragMode?.(DragMode.horizontal);
      }
    }
  };

  closeInspector(): void {
    if (this.state && !this.state.isDragging) {
      //this.ctx.closeInspector();
    }
  }

  onStart = ({
    nodeId,
    planningDateId,
    plannedEnd,
    plannedStart,
    setAdjustedPlannedEnd,
    setAdjustedPlannedStart,
    createOnDrag,
  }: DragStart): void => {
    const state = this.ctx.store.getState();
    const planningDates = getPlanningDatesForNodeSelector(state)(nodeId);
    const {end, start} = calculateDateLimits(planningDateId, planningDates);
    this.state = {
      createOnDrag,
      nodeId,
      plannedEnd,
      plannedStart,
      planningDateId,
      setAdjustedPlannedEnd,
      setAdjustedPlannedStart,
      isDragging: false,
      limitEnd: end,
      limitStart: start,
    };
  };
}
