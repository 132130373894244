import {UnknownAction} from 'redux';

type ActionInterface = {
  [x: string]: string[] | number[];
  [x: number]: string[] | number[];
} & UnknownAction;

export default function <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  State extends {[x: string]: any; [x: number]: any},
  Action extends ActionInterface,
>(actionKey: string): (state: State, action: Action) => State {
  return (state: State, action: Action) => {
    let changed = false;
    const newState = {...state};
    action[actionKey].forEach((id: string | number) => {
      if (newState.hasOwnProperty(id)) {
        delete newState[id];
        changed = true;
      }
    });
    return changed ? newState : state;
  };
}
