import {ReactElement} from 'react';
import {ProjectTreeGroup} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import FlowLabels from '../../../Label/FlowLabel/FlowLabels';

export interface GroupDetailsProps {
  node: ProjectTreeGroup;
}

export default function GroupDetails({node}: GroupDetailsProps): ReactElement | null {
  const group = node.entity;
  return <>{group && group.labels.length > 0 && <FlowLabels labels={group.labels} small noMargin />}</>;
}
