import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {IconButton} from '@octaved/ui';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import DeactivatableLink from '../../../Components/DeactivatableLink';
import {projectsSelectedProjectFolderSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useGetNewProjectDialogRoute} from '../../../Routing/Routes/Dialogs';

export default function CreateProjectButton(): ReactElement {
  const isGranted = useSelector(isGrantedSelector);
  const projectFolderId = useSelector(projectsSelectedProjectFolderSelector);
  const canCreateProject = isGranted('FLOW_NODE_PID_MANAGE_BASIC', projectFolderId);
  const getNewProjectDialogRoute = useGetNewProjectDialogRoute();

  return (
    <DeactivatableLink disabled={!canCreateProject} to={getNewProjectDialogRoute()}>
      <IconButton
        size={'sm'}
        icon={<PlusCircle />}
        label={'general:newProject'}
        colorScheme={'add'}
        isDisabled={!canCreateProject}
      />
    </DeactivatableLink>
  );
}
