import {EntityStates} from '@octaved/store/src/EntityState';
import {combiner} from '@octaved/store/src/Reducer/Combiner';
import {Reducer} from 'react';
import {AnyAction} from 'redux';
import {StoreBookedHours} from '../../EntityInterfaces/Statistics/BookedHours';
import {SubtreeStats} from '../../EntityInterfaces/Statistics/SubtreeStats';
import {StoreStatisticsTaskBurnDown} from '../../EntityInterfaces/Statistics/TaskBurnDown';
import {StoreTimeEffortPriceSumsPerBillingType} from '../../EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';
import {bookedHoursReducer} from './BookedHours';
import {chargableHoursReducers, StoreChargableHours} from './BookedTimeRecords';
import {subtreeStatsReducer, subtreeStatsStateReducer} from './SubtreeStats';
import {taskBurnDownReducers} from './TaskBurnDown';
import {
  timeEffortPriceSumsPerBillingTypeReducer,
  timeEffortPriceSumsPerBillingTypeStateReducer,
} from './TimeEffortPriceSumsPerBillingType';

export type Statistics = StoreChargableHours &
  StoreBookedHours & {
    subtreeStats: {
      data: SubtreeStats;
      state: EntityStates;
    };
    taskBurnDown: {
      data: StoreStatisticsTaskBurnDown;
      state: EntityStates;
    };
    timeEffortPriceSumsPerBillingType: {
      data: StoreTimeEffortPriceSumsPerBillingType;
      state: EntityStates;
    };
  };

export const statisticsReducer = combiner<Statistics>([
  {
    ...chargableHoursReducers,
    subtreeStats: {
      data: subtreeStatsReducer,
      state: subtreeStatsStateReducer,
    },
    taskBurnDown: taskBurnDownReducers,
    timeEffortPriceSumsPerBillingType: {
      data: timeEffortPriceSumsPerBillingTypeReducer,
      state: timeEffortPriceSumsPerBillingTypeStateReducer,
    },
  } as unknown as Reducer<Statistics, AnyAction>,
  bookedHoursReducer as unknown as Reducer<Statistics, AnyAction>,
]);
