import {IconButton} from '@octaved/ui';
import {Check, MoreHorizontal} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DropdownPopup from '../../../../Components/Form/Dropdown/DropdownPopup';
import {appendDropdownOptions} from '../../../../Components/Form/Dropdown/DropdownPopup/AppendOptions';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '../../../../Components/Form/Dropdown/DropdownPopup/Types';
import {projectControllingDemoModeSelector} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {setProjectControllingDemoMode} from '../../../../Modules/UiPages/Projects';
import {useMenuOptions} from './ProjectControllingProjectsFrame';

export default function ProjectControllingInspectorSubMenu(): ReactElement {
  const menuOptions = useMenuOptions();
  const items: DropdownPopupItemOptions[] = [];
  const demoMode = useSelector(projectControllingDemoModeSelector);
  const {t} = useTranslation();

  appendDropdownOptions(items, menuOptions?.prepend);
  appendDropdownOptions(items, [
    {type: DropdownPopupItemType.divider},
    {
      icon: <Check />,
      iconColor: demoMode ? 'green' : 'white',
      onClick: () => setProjectControllingDemoMode(!demoMode),
      token: t('pages:projects.inspector.demoMode'),
      type: DropdownPopupItemType.item,
    },
  ]);

  return (
    <DropdownPopup items={items} closeOnSelect position={'bottom right'}>
      <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
    </DropdownPopup>
  );
}
