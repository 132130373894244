import {SubtreeTaskCountsAndTimeSums} from '@octaved/flow/src/EntityInterfaces/Statistics/SubtreeStats';
import {ReliabilityMeter, ReliabilityMeterTokens} from '@octaved/ui';
import {ReactElement} from 'react';

const reliabilityLabels: ReliabilityMeterTokens = {
  all: 'general:pid.status.taskProgress.reliabilityMeter.all',
  default: 'general:pid.status.taskProgress.reliabilityMeter.default',
  toolTip: 'general:pid.status.taskProgress.reliabilityMeter.toolTip',
};

interface Props {
  className?: string;
  stats: Pick<SubtreeTaskCountsAndTimeSums, 'totalCount' | 'withTimeCount'>;
}

export default function NodeTasksReliabilityMeter({className, stats}: Props): ReactElement {
  return (
    <ReliabilityMeter
      className={className}
      tokens={reliabilityLabels}
      count={stats.withTimeCount}
      total={stats.totalCount}
      hideZero
    />
  );
}
