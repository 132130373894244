import {BillingWorkflowStatus} from '@octaved/env/src/dbalEnumTypes';
import {Billing} from '@octaved/flow/src/EntityInterfaces/Billing/Billings';
import MultiDropdown from '@octaved/quick-filters/src/Components/MultiDropdown';
import {BillingQuickFilterConfig} from '@octaved/quick-filters/src/Config';
import {getTypedSelect} from '@octaved/ui';
import {Check, CircleDot, Play, Redo2} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

const icons: Record<BillingWorkflowStatus, ReactElement> = {
  [BillingWorkflowStatus.hasIssues]: (
    <div className={'flex size-[22px] max-h-[22px] shrink-0 items-center justify-center rounded-full bg-amber-500'}>
      <Redo2 className={'mt-[-1px] size-4 text-white'} />
    </div>
  ),
  [BillingWorkflowStatus.invoiced]: (
    <div className={'flex size-[22px] max-h-[22px] shrink-0 items-center justify-center rounded-full bg-emerald-600'}>
      <Check className={'mt-[1px] size-4 text-white'} />
    </div>
  ),
  [BillingWorkflowStatus.open]: (
    <div className={'flex size-[22px] max-h-[22px] shrink-0 items-center justify-center rounded-full bg-sky-600'}>
      <Play className={'ml-[1px] mt-[-1px] size-4 text-white'} />
    </div>
  ),
};

const texts: Record<BillingWorkflowStatus, string> = {
  [BillingWorkflowStatus.hasIssues]: 'general:billing.workflowStatus.hasIssues',
  [BillingWorkflowStatus.invoiced]: 'general:billing.workflowStatus.invoiced',
  [BillingWorkflowStatus.open]: 'general:billing.workflowStatus.open',
};

const sorted: readonly BillingWorkflowStatus[] = [
  BillingWorkflowStatus.open,
  BillingWorkflowStatus.hasIssues,
  BillingWorkflowStatus.invoiced,
];

export function WorkflowStatusWithIcon({status}: {status: Billing['workflowStatus']}): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'flex items-center gap-x-2'}>
      {icons[status]}
      <span className={'truncate'}>{t(texts[status])}</span>
    </div>
  );
}

export interface WorkflowStatusDropdown {
  readonly?: boolean;
  setStatus: (status: BillingWorkflowStatus) => void;
  status: BillingWorkflowStatus;
}

const select = getTypedSelect<BillingWorkflowStatus>();

export function WorkflowStatusDropdown({readonly, setStatus, status}: WorkflowStatusDropdown): ReactElement {
  const {t} = useTranslation();
  return (
    <select.Select disabled={readonly} onValueChange={setStatus} value={status}>
      <select.Trigger className={'w-1/2'}>
        <select.Value placeholder={t('general:dropdownSelectPlaceholder')} />
      </select.Trigger>
      <select.Content>
        {sorted.map((value) => {
          return (
            <select.Item value={value} key={value}>
              <WorkflowStatusWithIcon status={value} />
            </select.Item>
          );
        })}
      </select.Content>
    </select.Select>
  );
}

const items = sorted.map((value) => {
  return {
    value,
    text: <WorkflowStatusWithIcon status={value} />,
  };
});

export const billingWorkflowStatusQuickFilterConfig = {
  defaultValue: [],
  Filter: ({setValue, value, isActive}) => (
    <MultiDropdown
      actionTriggerIcon={CircleDot}
      items={items}
      label={'general:billing.workflowStatus.label'}
      setValue={setValue}
      value={value}
      isActive={isActive}
    />
  ),
  ident: 'workflowStatus',
  toSearchQuery: (status) => (status.length ? {or: status.map((s) => ['workflowStatus', s])} : null),
} as const satisfies BillingQuickFilterConfig<readonly BillingWorkflowStatus[]>;
