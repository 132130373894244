import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getTaskDepthSelector, getTaskIsAutoChainedSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import Konva from 'konva';
import {
  allLogicalPredecessorsCompletedSelector,
  getAutoChainLogicalPredecessorSelector,
} from '../../../Selectors/LogicalDependencySelector';
import {createStoreSubscription} from '../../StoreSubscription';
import {Bar} from '../Bar';
import {BarPlugin, BarPluginProps} from './BarPlugin';

interface OpacityPluginProps extends BarPluginProps {
  isGhost?: boolean;
  opacity?: number;
}

export class OpacityPlugin extends BarPlugin {
  protected readonly isGhost: boolean;
  protected readonly opacity: number;

  constructor(props: OpacityPluginProps) {
    super(props);
    this.isGhost = props.isGhost || false;
    this.opacity = props.opacity || 0.2;
  }

  init(bar: Bar): void {
    super.init(bar);

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => {
          if (this.isGhost) {
            return true;
          }
          const node = getNodeSelector(s)(this.nodeId);
          if (isWorkPackage(node)) {
            return Boolean(node?.isCompleted);
          }
          if (isTask(node)) {
            const completedAllPredecessors = allLogicalPredecessorsCompletedSelector(s)(this.nodeId);
            const isAutoChainActive = getTaskIsAutoChainedSelector(s)(this.nodeId);
            const isInAutoChain = getTaskDepthSelector(s)(this.nodeId) === 1;
            const showLinkIconBecauseOfAutoChain = isInAutoChain && isAutoChainActive;
            const isCompleted = node.status === EnumFlowTaskStatus.VALUE_COMPLETE;
            const previousTask = getAutoChainLogicalPredecessorSelector(s)(this.nodeId);
            const isNextUp = !previousTask || previousTask.status === EnumFlowTaskStatus.VALUE_COMPLETE;

            return (
              !completedAllPredecessors || (showLinkIconBecauseOfAutoChain && !isCompleted && !isNextUp) || isCompleted
            );
          }
          return false;
        },
        (hasOpacity) => this.#updateOpacity(hasOpacity),
      ),
    );
  }

  #updateOpacity(hasOpacity: boolean): void {
    const root = this.root.findAncestor('.rootBar');
    if (root instanceof Konva.Group) {
      for (const opacityNode of root.find('.opacity')) {
        if (opacityNode instanceof Konva.Group || opacityNode instanceof Konva.Shape) {
          opacityNode.opacity(hasOpacity ? this.opacity : 1);
        }
      }
    }
  }
}
