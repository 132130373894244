import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import Konva from 'konva';
import {CheckCircle2} from 'lucide';
import {createStoreSubscription} from '../../../StoreSubscription';
import {BarIcon} from './BarIcon';
import {IconListBarPlugin} from './IconListBarPlugin';

export class DoneIcon extends BarIcon {
  #icon: Konva.Image | null = null;

  init(iconList: IconListBarPlugin): void {
    super.init(iconList);
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => {
          const node = getNodeSelector(s)(this.nodeId);
          if (isTask(node)) {
            return node.status === EnumFlowTaskStatus.VALUE_COMPLETE;
          }
          if (isWorkPackage(node)) {
            return node.isCompleted;
          }
          return false;
        },
        (status) => {
          this.#updateIcon(status);
        },
      ),
    );
  }

  #updateIcon(visible: boolean): void {
    this.visible = visible;
    if (!this.#icon && this.visible) {
      this.#createIcon().then((icon) => {
        icon.y(this.getIconY());
        this.#icon = icon;
        this.root.add(icon);
      });
    }
    this.notifyIconList();
  }

  #createIcon(): Promise<Konva.Image> {
    return this.iconToImage(CheckCircle2, {
      height: this.height,
      style: {
        fill: '#3dd245',
        stroke: 'white',
      },
      width: this.height,
    });
  }
}
