import {Uuid} from '@octaved/typescript/src/lib';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement, useCallback} from 'react';
import {useSelector} from 'react-redux';
import SearchableCustomerSelector from '../../../Components/Customer/CustomerSelector/SearchableCustomerSelector';
import {projectsFilteredCustomerSelector} from '../../../Modules/Selectors/UiSelectors';
import {useProjectUiStatePatch} from '../Projects';

export default function CustomerSelector(): ReactElement | null {
  const canRead = useIsGranted('FLOW_GLOBAL_CUSTOMERS_READ');
  const customer = useSelector(projectsFilteredCustomerSelector) || 'all';
  const patchProjectsUiState = useProjectUiStatePatch();

  const onChange = useCallback(
    (value: 'all' | Uuid) => {
      patchProjectsUiState({
        filteredCustomer: value === 'all' ? null : value,
        ...(value !== 'all' ? {selectedCustomer: value} : {}),
      });
    },
    [patchProjectsUiState],
  );

  if (!canRead) {
    return null;
  }

  return (
    <SearchableCustomerSelector onChange={onChange} value={customer} pageHeaderStyle canSelectAll showLockedCustomers />
  );
}
