import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {useWorkingTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {RightsExport} from '@octaved/security/src/Authorization/Authorization';
import {EntityState} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {OrgUserSettings} from '../EntityInterfaces/OrgUserSettings';

export const currentOrgUserIdSelector = (state: FlowState): Uuid => state.currentOrgUser.id;
export const currentOrgUserNameSelector = (state: FlowState): string => state.currentOrgUser.name;
export const currentOrgUserRightsSelector = (state: FlowState): RightsExport => state.currentOrgUser.rights.data;
export const currentOrgUserRightsStateSelector = (state: FlowState): EntityState => state.currentOrgUser.rights.state;
export const currentOrgUserSettingsSelector = (state: FlowState): OrgUserSettings => state.currentOrgUser.settings;
export const currentOrgUserTypeSelector = (state: FlowState): UserType => state.currentOrgUser.type;

//Currently not changeable by the user:
const dateTimeFormat = {dateFormat: 'DD.MM.YYYY', timeFormat: 'HH:mm'};
export const dateFormatSelector = (_: FlowState): string => dateTimeFormat.dateFormat;
export const timeFormatSelector = (_: FlowState): string => dateTimeFormat.timeFormat;
export const dateTimeFormatSelector = (_: FlowState): string =>
  `${dateTimeFormat.dateFormat} ${dateTimeFormat.timeFormat}`;

export const useWorkingTimeTrackingForCurrentUserSelector = createSelector(
  useWorkingTimeTrackingSelector,
  currentOrgUserSettingsSelector,
  (useWorkingTimeTracking, {workingTimeTrackingDisabled}) => useWorkingTimeTracking && !workingTimeTrackingDisabled,
);

export const useAutoWorkingTimeTrackingForCurrentUserSelector = createSelector(
  useWorkingTimeTrackingSelector,
  currentOrgUserSettingsSelector,
  (useWorkingTimeTracking, {workingTimeTrackingAutoEnabled, workingTimeTrackingDisabled}) =>
    useWorkingTimeTracking && !workingTimeTrackingDisabled && !!workingTimeTrackingAutoEnabled,
);
