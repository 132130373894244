import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getTaskDepthSelector, getTaskIsAutoChainedSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import Konva from 'konva';
import {Link} from 'lucide';
import {
  allLogicalPredecessorsCompletedSelector,
  getAutoChainLogicalPredecessorSelector,
} from '../../../../Selectors/LogicalDependencySelector';
import {defaultLineHeight} from '../../../CommonComponents/Text';
import {Tooltip} from '../../../CommonComponents/Tooltip';
import {createStoreSubscription} from '../../../StoreSubscription';
import {BarIcon} from './BarIcon';
import {IconListBarPlugin} from './IconListBarPlugin';

export class LogicalDependencyIcon extends BarIcon {
  #iconGreen: Konva.Image | null = null;
  #iconBlue: Konva.Image | null = null;
  #tooltip: Tooltip | null = null;

  init(iconList: IconListBarPlugin): void {
    super.init(iconList);

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => {
          const node = getNodeSelector(s)(this.nodeId);

          if (isTask(node)) {
            const hasLogicalDependencies = Boolean(
              node?.planningLogicalPredecessors.length || node?.planningLogicalSuccessors.length,
            );
            const isAutoChainActive = getTaskIsAutoChainedSelector(s)(this.nodeId);
            const isInAutoChain = getTaskDepthSelector(s)(this.nodeId) === 1;
            const showLinkIconBecauseOfAutoChain = isInAutoChain && isAutoChainActive;

            const completedAllPredecessors = allLogicalPredecessorsCompletedSelector(s)(this.nodeId);
            const previousTask = getAutoChainLogicalPredecessorSelector(s)(this.nodeId);
            const isNextUp = !previousTask || previousTask.status === EnumFlowTaskStatus.VALUE_COMPLETE;

            if (hasLogicalDependencies || showLinkIconBecauseOfAutoChain) {
              return completedAllPredecessors &&
                node.status !== EnumFlowTaskStatus.VALUE_COMPLETE &&
                (!showLinkIconBecauseOfAutoChain || isNextUp)
                ? 'green'
                : 'blue';
            }
          }

          return null;
        },
        (status) => {
          this.#updateIcon(status);
        },
      ),
    );
    this.root.name('opacity');
  }

  #updateIcon(color: 'green' | 'blue' | null): void {
    const showGreenIcon = color === 'green';
    const showBlueIcon = color === 'blue';
    this.visible = showGreenIcon || showBlueIcon;
    if (!this.#iconGreen && showGreenIcon) {
      this.#createIcon('green').then((icon) => {
        this.#iconGreen = icon;
        this.root.add(icon);
      });
    }
    if (!this.#iconBlue && showBlueIcon) {
      this.#createIcon('blue').then((icon) => {
        this.#iconBlue = icon;
        this.root.add(icon);
      });
    }
    const isAutoChainActive = getTaskIsAutoChainedSelector(this.ctx.store.getState())(this.nodeId);
    if (!this.#tooltip && this.visible && isAutoChainActive) {
      this.#tooltip = new Tooltip({
        labelProps: {x: -125, y: -defaultLineHeight * 1.5},
        root: this.root,
        text: this.ctx.t('pages:projects.inspector.manage.planning.autoChainTooltip'),
      });
      this.disposables.push(() => this.#tooltip?.dispose());
    }
    this.#iconBlue?.visible(showBlueIcon);
    this.#iconGreen?.visible(showGreenIcon);
    this.notifyIconList();
  }

  #createIcon(color: 'green' | 'blue'): Promise<Konva.Image> {
    const y = this.getIconY();
    return this.iconToImage(Link, {
      height: this.height,
      imageConfig: {
        y,
      },
      style: {
        stroke: color,
      },
      width: this.height,
    });
  }

  get height(): number {
    return 14;
  }
}
