import {ReactElement, useContext} from 'react';
import {List} from 'semantic-ui-react';
import styleContext from './StyleContext';
import treeContext from './TreeContext';
import TreeNodeContent from './TreeNodeContent';

export default function TreeNode(): ReactElement {
  const {cssPrefix} = useContext(styleContext);
  const {padded, nodes} = useContext(treeContext);
  return (
    <List className={`${cssPrefix} ${padded ? 'padded' : ''} tree`}>
      {nodes.map((nodeData) => (
        <List.Item key={nodeData.id} data-id={nodeData.id} className={`flex ${cssPrefix}`}>
          <TreeNodeContent nodeData={nodeData} />
        </List.Item>
      ))}
    </List>
  );
}
