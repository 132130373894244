import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {memoize} from 'lodash';
import {AdvancedBilling, ContingentBehavior} from '../../../../EntityInterfaces/Filter/Custom/Billing';
import {ProjectFilterQueries} from '../../../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition} from '../../../../EntityInterfaces/NodeSearch';
import {bulkBillableTypesCondition, hourlyBillableTypesCondition} from '../../../../WorkPackage/BillingType';

const isClosedLike: NodeSearchCondition = {
  or: [
    ['wpIsApprovedForBilling'],
    {
      and: [['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], withDescendants(['timeControlExceeded'], true)],
    },
  ],
};

export const getBillingWorkPackageCondition = memoize((behavior: ContingentBehavior): NodeSearchCondition => {
  const billingTypeConditions: NodeSearchCondition[] = [
    {
      and: [
        hourlyBillableTypesCondition, //efforts
        isClosedLike,
      ],
    },
  ];

  if (behavior === 'showWhenApproved') {
    billingTypeConditions.push({
      and: [
        bulkBillableTypesCondition, //contingent/fixedPrice
        {not: ['wpHasFinalBillings']},
        isClosedLike,
      ],
    });
  }

  if (behavior === 'alwaysShow') {
    billingTypeConditions.push({
      and: [
        bulkBillableTypesCondition, //contingent/fixedPrice
        {not: ['wpHasFinalBillings']},
      ],
    });
  }

  return {and: [{or: billingTypeConditions}, {not: ['wpIsOffer']}]};
});

export function advancedBillingQueryGenerator(
  queries: ProjectFilterQueries,
  {contingentAndFixedPriceBehavior}: AdvancedBilling,
): void {
  //Customer must always be billable:
  queries.projects.push(['customerIsBillable']);

  queries.descendants.push(getBillingWorkPackageCondition(contingentAndFixedPriceBehavior));
}
