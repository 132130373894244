import {EntityStates} from '@octaved/store/src/EntityState';
import {DateTimeStr} from '@octaved/typescript';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {PlanningBaseline, PlanningBaselines} from '../EntityInterfaces/PlanningBaselines';
import {PlanningState} from '../PlanningState';
import {getSelectedBaselineIdsSelector} from './UiSelectors';

export const planningBaselineEntityStatesSelector = (state: PlanningState): EntityStates =>
  state.entityStates.planningBaseline;
export const planningBaselinesSelector = (state: PlanningState): PlanningBaselines => state.entities.planningBaseline;

export const getNodeIdForPlanningBaselineSelector = createSelector(
  planningBaselinesSelector,
  (planningBaselines) => (baselineId: MaybeUuid) => {
    if (!baselineId) {
      return null;
    }

    for (const [nodeId, baseLines] of Object.entries(planningBaselines)) {
      if (baseLines!.some((baseline) => baseline.id === baselineId)) {
        return nodeId;
      }
    }
    return null;
  },
);
const defaultBaselines: PlanningBaseline[] = [];
export const getPlanningBaselinesForNodeSelector = createSelector(
  planningBaselinesSelector,
  (planningBaselines) => (nodeId: MaybeUuid) =>
    planningBaselines[nodeId!]?.sort((a, b) => a.timestamp.localeCompare(b.timestamp)) || defaultBaselines,
);

export const getSelectedBaselinesSelector = createSelector(
  getPlanningBaselinesForNodeSelector,
  getSelectedBaselineIdsSelector,
  (getPlanningBaselinesForNode, getSelectedBaselineIds) =>
    memoize((nodeId: MaybeUuid) => {
      const baselines = getPlanningBaselinesForNode(nodeId);
      const selectedBaselineIds = getSelectedBaselineIds(nodeId);
      return baselines.filter((baseline) => selectedBaselineIds.includes(baseline.id));
    }),
);

export const getPlanningBaselineSelector = createSelector(
  getPlanningBaselinesForNodeSelector,
  (getPlanningBaselinesForNode) => (nodeId: MaybeUuid, baselineId: MaybeUuid) => {
    const baselines = getPlanningBaselinesForNode(nodeId);
    return baselines.find((baseline) => baseline.id === baselineId) || null;
  },
);

export const getPlanningBaselineDisplayTimestampSelector = createSelector(
  getPlanningBaselineSelector,
  (getPlanningBaseline) =>
    (nodeId: MaybeUuid, baselineId: MaybeUuid): DateTimeStr | null => {
      const baseline = getPlanningBaseline(nodeId, baselineId);
      if (!baseline) {
        return null;
      }
      const dayjsTimestamp = fromIsoDateTimeFormat(baseline.timestamp, true).local();
      return toIsoDateTimeFormat(dayjsTimestamp);
    },
);
