import {Uuid} from '@octaved/typescript/src/lib';
import {DatePicker, Label} from '@octaved/ui';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {FormikProps} from 'formik';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getMinMaxPlanningDatesSelector} from '../../../Selectors/PlanningDateSelectors';
import {canChangeBarSelector} from '../../../Selectors/PlanningDependencySelectors';
import {RecurringPlanningData} from './RecurringPlanningContent';

interface FirstLastDateSelectionProps {
  disabled: boolean;
  formik: FormikProps<RecurringPlanningData>;
  nodeId: Uuid;
}

export default function FirstLastDateSelection({disabled, formik, nodeId}: FirstLastDateSelectionProps): ReactElement {
  const canChangeBar = useSelector(canChangeBarSelector)(nodeId);
  const {plannedEnd, plannedStart} = useSelector(getMinMaxPlanningDatesSelector)(nodeId);
  const datePickerOverride = useMemo(() => {
    const today = dayjs();
    let minDate: dayjs.Dayjs | null = null;
    let maxDate: dayjs.Dayjs | null = null;
    if (!canChangeBar) {
      minDate = fromIsoDateTimeFormat(plannedStart);
      maxDate = fromIsoDateTimeFormat(plannedEnd);
    }
    if (!minDate) {
      minDate = today.subtract(1, 'y');
    }
    if (!maxDate) {
      maxDate = today.add(2, 'y');
    }

    return {minDate, maxDate, strictBlockedDates: true};
  }, [canChangeBar, plannedEnd, plannedStart]);

  return (
    <div className={'flex gap-x-8'}>
      <div>
        <Label token={'pages:projects.inspector.manage.planning.recurringPlanning.labelFirst'} />
        <DatePicker
          date={formik.values.first}
          setDate={(date) => formik.setFieldValue('first', date)}
          readonly={disabled}
          datePickerOverride={datePickerOverride}
        />
      </div>

      <div>
        <Label token={'pages:projects.inspector.manage.planning.recurringPlanning.labelLast'} />
        <DatePicker
          date={formik.values.last}
          setDate={(date) => formik.setFieldValue('last', date)}
          readonly={disabled}
          datePickerOverride={datePickerOverride}
        />
      </div>
    </div>
  );
}
