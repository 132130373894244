import {EnumFlowNodeType, EnumFlowPidBillingType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {useDisposedSafeCallback} from '@octaved/hooks';
import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn, messageVariants, TWButton} from '@octaved/ui';
import {objectEntries} from '@octaved/utilities/src/Object';
import {MutableRefObject, ReactElement, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DialogContent, DialogFrame, DialogTitle} from '../../Dialogs/DialogFrame';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {useAsyncNodeSearch, useCombinedNodeSearches} from '../../Modules/Hooks/NodeSearch';
import {ExtendedNodeStatus} from './NodeStatus';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';

type NSC = NodeSearchCondition;

const warningsConfig = {
  hasIncompleteSwps: {
    query: (ids: ReadonlyArray<Uuid>): NSC => ({
      and: [
        withDescendants({fixResult: ids}, true),
        ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
        {not: ['swpIsCompleted']},
      ],
    }),
    text: (nodeType: EnumFlowNodeType) =>
      nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE
        ? 'general:nodeStatus.warning.isIncompleteSwp'
        : 'general:nodeStatus.warning.hasIncompleteSwps',
  },
  hasIncompleteTasks: {
    query: (ids: ReadonlyArray<Uuid>): NSC => ({
      and: [withDescendants({fixResult: ids}, true), ['taskStatus', EnumFlowTaskStatus.VALUE_OPEN]],
    }),
    text: () => 'general:nodeStatus.warning.hasIncompleteTasks',
  },
  hasIncompleteWps: {
    query: (ids: ReadonlyArray<Uuid>): NSC => ({
      and: [
        withDescendants({fixResult: ids}, true),
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        {not: ['wpIsCompleted']},
      ],
    }),
    text: (nodeType: EnumFlowNodeType) =>
      nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE
        ? 'general:nodeStatus.warning.isIncompleteWp'
        : 'general:nodeStatus.warning.hasIncompleteWps',
  },
  hasOpenTrackedTime: {
    query: (ids: ReadonlyArray<Uuid>): NSC => ({
      and: [withDescendants({fixResult: ids}, true), ['trRefHasOpenTimeTrackingRecords']],
    }),
    text: () => 'general:nodeStatus.warning.hasOpenTrackedTime',
  },
  hasUnbilledTrackedTime: {
    query: (ids: ReadonlyArray<Uuid>): NSC => ({
      and: [
        withDescendants({fixResult: ids}, true),
        ['trRefHasNonBilledTimeTrackingRecords'],
        withDescendants(['customerIsBillable'], true),
        {not: withDescendants(['wpBillingType', EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE], true)},
      ],
    }),
    text: () => 'general:nodeStatus.isArchived.warning.hasUnbilledTrackedTime',
  },
} as const;

type Key = keyof typeof warningsConfig;

const statusConfig = {
  isApprovedForBilling: {
    commitAnywayButton: 'general:nodeStatus.isApprovedForBilling.warning.approveAnywayButton',
    header: 'general:nodeStatus.isApprovedForBilling.warning.header',
    info: () => 'general:nodeStatus.isApprovedForBilling.warning.info',
    keys: ['hasOpenTrackedTime', 'hasIncompleteWps', 'hasIncompleteSwps', 'hasIncompleteTasks'],
  },
  isArchived: {
    commitAnywayButton: 'general:nodeStatus.isArchived.warning.archiveAnywayButton',
    header: 'general:nodeStatus.isArchived.warning.header',
    info: () => 'general:nodeStatus.isArchived.warning.info',
    keys: [
      'hasOpenTrackedTime',
      'hasUnbilledTrackedTime',
      'hasIncompleteWps',
      'hasIncompleteSwps',
      'hasIncompleteTasks',
    ],
  },
  isClosed: {
    commitAnywayButton: 'general:nodeStatus.isClosed.warning.closeAnywayButton',
    header: 'general:nodeStatus.isClosed.warning.header',
    info: (nodeType) => {
      const texts = {
        [EnumFlowNodeType.VALUE_PROJECT]: 'general:nodeStatus.isClosed.warning.info.project',
        [EnumFlowNodeType.VALUE_GROUP]: 'general:nodeStatus.isClosed.warning.info.group',
        [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'general:nodeStatus.isClosed.warning.info.workPackage',
      } as Partial<Record<EnumFlowNodeType, string>>;
      return texts[nodeType] ?? '';
    },
    keys: ['hasOpenTrackedTime', 'hasIncompleteWps', 'hasIncompleteSwps', 'hasIncompleteTasks'],
  },
  isLocked: {
    commitAnywayButton: 'general:nodeStatus.isLocked.warning.lockAnywayButton',
    header: 'general:nodeStatus.isLocked.warning.header',
    info: (nodeType) => {
      const texts = {
        [EnumFlowNodeType.VALUE_PROJECT]: 'general:nodeStatus.isLocked.warning.info.project',
        [EnumFlowNodeType.VALUE_GROUP]: 'general:nodeStatus.isLocked.warning.info.group',
        [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'general:nodeStatus.isLocked.warning.info.workPackage',
        [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'general:nodeStatus.isLocked.warning.info.subWorkPackage',
      } as Partial<Record<EnumFlowNodeType, string>>;
      return texts[nodeType] ?? '';
    },
    keys: ['hasOpenTrackedTime'],
  },
} as const satisfies Partial<
  Record<
    ExtendedNodeStatus,
    {commitAnywayButton: string; header: string; info: (nodeType: EnumFlowNodeType) => string; keys: Key[]}
  >
>;

export type Stats = Record<Key, boolean>;
export type StatusWithWarning = keyof typeof statusConfig;

function isStatusWithWarning(status: ExtendedNodeStatus): status is StatusWithWarning {
  return status in statusConfig;
}

function getStatsFromResults(keys: Key[], results: ReadonlyArray<ReadonlyArray<Uuid>>): Stats | null {
  const stats = Object.fromEntries(Object.keys(warningsConfig).map((k) => [k, false])) as Stats;
  let hasOneWarning = false;
  keys.forEach((key, index) => {
    if (results[index]!.length > 0) {
      stats[key] = true;
      hasOneWarning = true;
    }
  });
  return hasOneWarning ? stats : null;
}

export function useAsyncStatusWarnings(nodeId: Uuid): {
  close: () => void;
  commit: MutableRefObject<() => Promise<void>>;
  hasWarnings: (status: ExtendedNodeStatus) => Promise<boolean>;
  stats: Stats | null;
  status: MutableRefObject<StatusWithWarning>;
} {
  const [stats, setStats] = useState<Stats | null>(null);
  const close = useDisposedSafeCallback(() => setStats(null));
  const commit = useRef<() => Promise<void>>(() => Promise.resolve());
  const statusRef = useRef<StatusWithWarning>('isClosed');
  const asyncNodeSearch = useAsyncNodeSearch();
  const hasWarnings = useCallback(
    async (status: ExtendedNodeStatus) => {
      if (!isStatusWithWarning(status)) {
        return false;
      }
      statusRef.current = status;
      const keys = statusConfig[status].keys;
      const queries = keys.map((key) => asyncNodeSearch(warningsConfig[key].query([nodeId])));
      const results = await Promise.all(queries);
      const stats = getStatsFromResults(keys, results);
      setStats(stats);
      return !!stats;
    },
    [asyncNodeSearch, nodeId],
  );
  return {close, commit, hasWarnings, stats, status: statusRef};
}

export function useBatchStatusWarnings(
  statuses: Partial<Record<StatusWithWarning, ReadonlyArray<Uuid>>>,
): {keys: Key[]; stats: Stats} | null {
  const nodeIdsPerKey = new Map<Key, Set<Uuid>>();
  objectEntries(statuses).forEach(([stat, ids]) => {
    if (isStatusWithWarning(stat) && ids.length > 0) {
      statusConfig[stat].keys.forEach((key) => {
        const set = nodeIdsPerKey.get(key) || new Set();
        ids.forEach((id) => set.add(id));
        nodeIdsPerKey.set(key, set);
      });
    }
  });
  const keys = [...nodeIdsPerKey.keys()];
  const queries = keys.map((key) => warningsConfig[key].query([...nodeIdsPerKey.get(key)!]));
  const results = useCombinedNodeSearches({}, ...queries);
  const stats = getStatsFromResults(
    keys,
    results.map(({ids}) => ids),
  );
  return stats ? {keys, stats} : null;
}

interface StatusWarningsListProps {
  keys: ReadonlyArray<Key>;
  nodeType: EnumFlowNodeType;
  stats: Stats;
}

export function StatusWarningsList({keys, nodeType, stats}: StatusWarningsListProps): ReactElement {
  const {t} = useTranslation();
  const activeWarnings = keys.filter((k) => stats[k]);
  return (
    <div className={'flex flex-col gap-y-2'}>
      <div>{t('general:nodeStatus.warning.listLabel')}:</div>
      <ul className={cn(messageVariants({colorScheme: 'warning'}), 'list-disc pl-8')}>
        {activeWarnings.map((key) => {
          return <li key={key}>{t(warningsConfig[key].text(nodeType))}</li>;
        })}
      </ul>
    </div>
  );
}

interface StatusWarningsPopupProps {
  close: () => void;
  commit: MutableRefObject<() => Promise<void>>;
  nodeType: EnumFlowNodeType;
  stats: Stats;
  status: MutableRefObject<StatusWithWarning>;
}

export function StatusWarningsPopup({close, commit, nodeType, stats, status}: StatusWarningsPopupProps): ReactElement {
  const {t} = useTranslation();
  const {commitAnywayButton, header, info, keys} = statusConfig[status.current];
  const [save, isSaving] = useAsyncExecution(async () => {
    await commit.current();
    close();
  });
  return (
    <DialogFrame size={'mini'} noOwnForm>
      <DialogTitle onClose={close}>
        <DialogAndDrawerHeader header={header} noPadding />
      </DialogTitle>
      <DialogContent>
        <div className={'mb-4 flex flex-col gap-y-4'}>
          {!!info && <div>{t(info(nodeType))}</div>}
          <StatusWarningsList keys={keys} nodeType={nodeType} stats={stats} />
          <div className={'flex justify-around'}>
            <TWButton size={'md'} variant={'outline'} onClick={close}>
              {t('general:cancel')}
            </TWButton>
            <TWButton
              colorScheme={'primary'}
              disabled={isSaving}
              isLoading={isSaving}
              onClick={save}
              size={'md'}
              variant={'solid'}
            >
              {t(commitAnywayButton)}
            </TWButton>
          </div>
        </div>
      </DialogContent>
    </DialogFrame>
  );
}
