import {createSelector} from 'reselect';
import {FlowState} from '../../State';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['myTasks']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.myTasks);
}

export const myTasksAdvancedFiltersSelector = ui((state) => state.advancedFilters);
export const myTasksClosedWorkPackageGroupsSelector = ui((state) => state.closedWorkPackageGroups);
export const myTasksFilterPresetSelector = ui(({filterPreset}) => {
  //Prevent bad preset from leaking from the local storage:
  return typeof filterPreset === 'number' || ['mine', 'advanced'].includes(filterPreset) ? filterPreset : -1;
});
export const myTasksListExpandedSectionsSelector = ui((state) => state.listExpandedSections);
const listSectionOrderSelector = ui((state) => state.listSectionOrder);
export const myTasksListSectionOrderSelector = createSelector(listSectionOrderSelector, (order) => {
  const indexed = new Map(order.map((ident, index) => [ident, index + 1])); //+1 to be truthy for the "|| inf" below
  const inf = 999;
  return [...(['inTimeFrame', 'notPlanned', 'calendarEvents', 'workload'] as const)].sort(
    (a, b) => (indexed.get(a) || inf) - (indexed.get(b) || inf),
  );
});
export const myTasksListShowTasksSelector = ui((state) => state.listShowTasks);
export const myTasksShowEmptyTaskSectionsSelector = ui((state) => state.showEmptyTaskSections);
export const myTasksListTimeFrameSelector = ui((state) => state.listTimeFrame);
export const myTasksManualWorkPackageSortOrderSelector = ui((state) => state.manualWorkPackageSortOrder);
export const myTasksSearchSelector = ui((state) => state.search);
export const myTasksShowBlockedTasksSelector = ui((state) => state.showBlockedTasks);
export const myTasksShowDueDatesSelector = ui((state) => state.showDueDates);
export const myTasksShowConnectedCalendarDemoDataSelector = ui((state) => state.showConnectedCalendarDemoData);
export const myTasksShowTasksOfOtherUsersSelector = ui((state) => state.showTasksOfOtherUsers);
export const myTasksTimelineExpandedLanesSelector = ui((state) => state.timelineExpandedLanes);
export const myTasksTimelineShowTasksSelector = ui((state) => state.timelineShowTasks);
export const myTasksTimelineStartDateSelector = ui((state) => state.timelineStartDate);
export const myTasksVisibleTasksSelector = ui((state) => state.visibleTasks);
export const myTasksWorkPackageGroupAndSortBySelector = ui((state) => state.workPackageGroupAndSortBy);
