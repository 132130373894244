import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import IntranetGroupInspectorContext from '../../../../Drawer/InspectorContext/IntranetGroupInspectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const groupRoute = 'group';

const EditGroup = lazy(() => import('../../../../Pages/SystemSettings/Group/Inspector/EditGroup'));

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['group']: [
    {
      path: groupRoute,
      token: '',
    },
  ],
};
//endregion

export const groupInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <EditGroup />
        </InspectorContextLoadingWrapper>
      ),
      path: groupRoute,
    },
    {
      element: <Navigate to={groupRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: IntranetGroupInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
