import CaretDown from '@fortawesome/fontawesome-pro/svgs/solid/caret-down.svg';
import CaretRight from '@fortawesome/fontawesome-pro/svgs/solid/caret-right.svg';
import {IconButton, StopClickPropagation} from '@octaved/ui';
import classNames from 'classnames';
import * as React from 'react';
import {ReactElement} from 'react';

interface Props {
  isExpanded: boolean;
  toggleExpansion: (event: React.MouseEvent<HTMLButtonElement>) => void;
  alignSelfCenter?: boolean;
}

export default function ToggleSubPids({alignSelfCenter = false, isExpanded, toggleExpansion}: Props): ReactElement {
  return (
    <div className={classNames('toggleButton', {alignSelfCenter})}>
      <StopClickPropagation>
        <IconButton
          variant={'ghost'}
          icon={isExpanded ? <CaretDown /> : <CaretRight />}
          size={'xs'}
          onClick={toggleExpansion}
        />
      </StopClickPropagation>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .toggleButton {
          align-self: flex-start;
        }

        .alignSelfCenter {
          align-self: center;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
