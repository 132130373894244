import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {IsOfferIcon} from '@octaved/flow/src/Node/NodeStatus/NodeStatusIcons';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue, WorkPackageIcon} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';

export interface WorkPackageTypeProps {
  node: WorkPackage;
  readonly?: boolean;
  setIsOffer: (isOffer: boolean) => void;
}

export default function WorkPackageType({
  frame: Frame,
  node,
  readonly,
  setIsOffer,
}: WorkPackageTypeProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Frame labelToken={'nodeDetails:field.workPackageType.label'}>
      <div className={'flex flex-col gap-y-2'}>
        <TWSelect
          value={node.isOffer ? 'offer' : 'normal'}
          disabled={readonly}
          onValueChange={(value) => setIsOffer(value === 'offer')}
        >
          <TWSelectTrigger className={'w-1/2'}>
            <TWSelectValue placeholder={t('general:dropdownSelectPlaceholder')} />
          </TWSelectTrigger>
          <TWSelectContent>
            <TWSelectItem value={'normal'}>
              <div className={'flex items-center gap-x-2'}>
                <div className={'flex size-6 items-center justify-center'}>
                  <WorkPackageIcon className={'size-5'} />
                </div>
                {t('nodeDetails:field.workPackageType.normal')}
              </div>
            </TWSelectItem>
            <TWSelectItem value={'offer'}>
              <div className={'flex items-center gap-x-2'}>
                <IsOfferIcon />
                {t('nodeDetails:field.workPackageType.offer')}
              </div>
            </TWSelectItem>
          </TWSelectContent>
        </TWSelect>
      </div>
    </Frame>
  );
}
