import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {useFormattedDateTextRange} from '@octaved/flow/src/Hooks/FormattedDateText';
import {getRootGroupSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../../Common/Type';

export interface WorkPackageSprintInfoProps {
  id: Uuid;
}

export default function WorkPackageSprintInfo({
  frame: Frame,
  id,
}: WorkPackageSprintInfoProps & FramedSectionOrTileProps): ReactElement | null {
  const rootGroup = useSelector(getRootGroupSelector)(id);
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(rootGroup?.id);
  const format = useFormattedDateTextRange();
  if (rootGroup && rootGroup.groupType === EnumFlowGroupType.VALUE_SPRINT) {
    return (
      <Frame labelToken={'pages:projects.inspector.manage.planning.sprintPlanning'}>
        {format(
          isoDateTimeToIsoFormat(plannedStart),
          isoDateTimeToIsoFormat(plannedEnd),
          'pages:projects.inspector.manage.planning.none',
        )}
      </Frame>
    );
  }

  return null;
}
