import LicenseTrialStatus from '@octaved/license/src/StatusBar/LicenseTrialStatus';
import {FlexItem, HStack, Stack} from '@octaved/ui';
import {ReactElement} from 'react';
import FlowOutlet from '../Components/FlowOutlet';
import PageLockWrapper from '../Components/PageLock/PageLockWrapper';
import UserVerificationStatus from '../Components/Unit/UserVerificationStatus';
import EntryPoint from '../Onboarding/EntryPoint';
import PageMenu from './PageMenu';

export default function AuthPageWrapper(): ReactElement {
  return (
    <Stack spacing={0} alignItems={'stretch'} height={'100%'} width={'100vw'} overflow={'hidden'}>
      <FlexItem flexShrink={0} width={'100%'} overflow={'hidden'}>
        <PageLockWrapper>
          <UserVerificationStatus />
          <LicenseTrialStatus />
        </PageLockWrapper>
      </FlexItem>
      <FlexItem flexGrow={1} width={'100%'} overflow={'hidden'}>
        <HStack spacing={0} alignItems={'stretch'} height={'100%'} width={'100vw'} overflow={'hidden'}>
          <FlexItem flexShrink={0}>
            <PageLockWrapper>
              <PageMenu />
            </PageLockWrapper>
          </FlexItem>
          <FlexItem flexGrow={1} width={'100%'} overflow={'hidden'}>
            <EntryPoint />
            <FlowOutlet />
          </FlexItem>
        </HStack>
      </FlexItem>
    </Stack>
  );
}
