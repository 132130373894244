import {MonthPicker, QuarterPicker, RangePicker, YearInput} from '@octaved/ui';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {TimePeriod} from '../../../Modules/Filter/TimePeriod';
import Interval from './PeriodFilter/Interval';

interface Props {
  period: TimePeriod;
  setPeriod: (p: TimePeriod) => void;
}

function getPeriodForInterval(day: dayjs.Dayjs, interval: 'month' | 'quarter' | 'year'): TimePeriod {
  const fromDay = dayjs(day).startOf(interval);
  const from = toIsoFormat(fromDay);
  const to = toIsoFormat(fromDay.endOf(interval));
  return {interval, from, to};
}

export default function PeriodFilter({period, setPeriod}: Props): ReactElement {
  const startDate = dayjs(period.from);

  return (
    <>
      <Interval
        interval={period.interval}
        setInterval={(interval) => {
          setPeriod(
            interval === 'custom'
              ? {interval, from: period.from, to: period.to}
              : getPeriodForInterval(startDate, interval),
          );
        }}
      />

      {period.interval === 'month' && (
        <>
          <MonthPicker
            month={startDate.month()}
            setMonth={(month) => {
              const fromDay = dayjs(startDate).month(month).startOf('month');
              const from = toIsoFormat(fromDay);
              const to = toIsoFormat(fromDay.endOf('month'));
              setPeriod({interval: 'month', from, to});
            }}
            fluid={false}
          />
          <YearInput
            year={startDate.year()}
            setYear={(year) => {
              const fromDay = dayjs(startDate).year(year).startOf('month');
              const from = toIsoFormat(fromDay);
              const to = toIsoFormat(fromDay.endOf('month'));
              setPeriod({interval: 'month', from, to});
            }}
          />
        </>
      )}

      {period.interval === 'quarter' && (
        <>
          <QuarterPicker
            quarter={startDate.quarter()}
            setQuarter={(quarter) => {
              const fromDay = dayjs(startDate).quarter(quarter).startOf('quarter');
              const from = toIsoFormat(fromDay);
              const to = toIsoFormat(fromDay.endOf('quarter'));
              setPeriod({interval: 'quarter', from, to});
            }}
            fluid={false}
            optionToken={'pages:projects.pages.billingInPeriod.filter.quarterOption'}
          />
          <YearInput
            year={startDate.year()}
            setYear={(year) => {
              const fromDay = dayjs(startDate).year(year).startOf('quarter');
              const from = toIsoFormat(fromDay);
              const to = toIsoFormat(fromDay.endOf('quarter'));
              setPeriod({interval: 'quarter', from, to});
            }}
          />
        </>
      )}

      {period.interval === 'year' && (
        <>
          <YearInput
            year={startDate.year()}
            setYear={(year) => {
              const fromDay = dayjs().year(year).startOf('year');
              const from = toIsoFormat(fromDay);
              const to = toIsoFormat(fromDay.endOf('year'));
              setPeriod({interval: 'year', from, to});
            }}
          />
        </>
      )}

      {period.interval === 'custom' && (
        <div className={'customPicker'}>
          <RangePicker
            start={period.from}
            end={period.to}
            onChange={(start, end) => {
              setPeriod({interval: 'custom', from: start || period.from, to: end || period.to});
            }}
          />
        </div>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .customPicker {
          width: 280px;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
