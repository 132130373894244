import {lazy} from 'react';
import {RouteObject} from 'react-router-dom';
import RegisterWrapper from '../../Pages/Register/RegisterWrapper';

const loginRoute = 'login';
const emailConfirmationRoute = 'email-confirmation';
const invitationRoute = 'invitation';
const registerRoute = 'register';

const Login = lazy(() => import('../../Pages/Login/Login'));
const EmailConfirmation = lazy(() => import('../../Pages/Register/EmailConfirmation'));
const OrganizationInvitation = lazy(() => import('../../Pages/MultiOrganization/OrganizationInvitation'));

export const publicRoutes: RouteObject[] = [
  {
    element: <Login />,
    path: `${loginRoute}/*`,
  },
  {
    element: <EmailConfirmation />,
    path: emailConfirmationRoute,
  },
  {
    element: <OrganizationInvitation />,
    path: `${invitationRoute}/:invitationToken`,
  },
  {
    element: <RegisterWrapper />,
    path: `${registerRoute}/*`,
  },
];

export function getLoginRoute(): string {
  return `/${loginRoute}`;
}

export function getInvitationRoute(token: string): string {
  return `/${invitationRoute}/${token}`;
}
