import {
  identityHiddenOrganizationIdsSelector,
  identityOrganizationsSelector,
} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {generateUrl} from '@octaved/network/src/Network';
import {logout} from '@octaved/security/config/routes';
import {Popup} from '@octaved/ui';
import {Cog, LogOut} from 'lucide-react';
import {ReactElement, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {mySettingsRoute} from '../../Routing/Routes/Configure';
import UserMenuOrganizationSwitch from './OrganizationSwitch/UserMenuOrganizationSwitch';

interface Props {
  children: ReactNode;
}

export default function UserMenuPopup({children}: Props): ReactElement {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const hiddenOrgs = useSelector(identityHiddenOrganizationIdsSelector);
  const organizations = useSelector(identityOrganizationsSelector);
  const [open, setOpen] = useState(false);

  return (
    <Popup
      trigger={<div>{children}</div>}
      position={'right center'}
      offset={[0, -10]}
      open={open}
      setOpen={setOpen}
      popperDependencies={[hiddenOrgs, organizations]}
    >
      <div className={'flex max-w-[280px] flex-col bg-white'}>
        <UserMenuOrganizationSwitch closePopup={() => setOpen(false)} />

        <button
          onClick={() => {
            navigate(mySettingsRoute);
            setOpen(false);
          }}
          className={'flex h-10 cursor-pointer items-center gap-x-3 px-3 transition-all duration-150 hover:bg-slate-50'}
        >
          <Cog className={'h-5 w-5 flex-shrink-0 text-slate-400'} strokeWidth={1.5} />
          <div className={'truncate text-slate-800'}>{t('pageHeader:hamburgerMenu.mySettings')}</div>
        </button>

        <div className={'h-px w-full bg-slate-200'} />

        <a
          href={generateUrl(logout)}
          className={'flex h-10 cursor-pointer items-center gap-x-3 px-3 transition-all duration-150 hover:bg-slate-50'}
        >
          <LogOut className={'h-5 w-5 flex-shrink-0 text-slate-400'} strokeWidth={1.5} />
          <div className={'truncate text-slate-800 hover:text-slate-800'}>{t('pageHeader:hamburgerMenu.logout')}</div>
        </a>
      </div>
    </Popup>
  );
}
