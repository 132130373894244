export const generalRoute = 'general';
export const overviewRoute = 'overview';
export const detailsRoute = 'details';
export const tasksRoute = 'tasks';
export const boardRoute = 'board';
export const responsibleRoute = 'responsibility';
export const planningRoute = 'planning';
export const kanbanRoute = 'kanban';
export const timeAndMoneyRoute = 'timeAndMoney';
export const settingsRoute = 'settings';
export const advancedRoute = 'advanced';
export const controllingRoute = 'controlling';
export const timeSheetRoute = 'timeSheet';
export const guestUserRoute = 'guests';
export const historyRoute = 'history';
export const timeTrackingRoute = 'timeTracking';
export const specialPlanningRoute = 'specialPlanning';
export const specialQuoteRoute = 'createOffer';
