import {NodeTrackedMinutes} from '../EntityInterfaces/NodeTrackedMinutes';
import {BillingStartEnd, calcBillingDuration} from './BillingStartEnd';

export interface AncestorBudgetEntity {
  enforceMaxEffort: boolean;
  maxEffort: number | null;
  trackedMinutes: Pick<NodeTrackedMinutes, 'billed'>;
}

export function timeTrackingBudgetExceeded(entity: AncestorBudgetEntity): boolean {
  if (!entity.enforceMaxEffort || !entity.maxEffort) {
    return false;
  }
  return entity.trackedMinutes.billed / 60 > entity.maxEffort;
}

export function timeTrackingWillExceedBudget(
  entity: AncestorBudgetEntity,
  previous: BillingStartEnd,
  next: BillingStartEnd,
): boolean {
  if (!entity.enforceMaxEffort || !entity.maxEffort) {
    return false;
  }

  const oldDurationMinutes = calcBillingDuration(previous) / 60;
  const newDurationMinutes = calcBillingDuration(next) / 60;
  const remainingBilledMinutes = entity.trackedMinutes.billed - oldDurationMinutes;
  const newBilledMinutes = remainingBilledMinutes + newDurationMinutes;
  return newBilledMinutes / 60 > entity.maxEffort;
}
