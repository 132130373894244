import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {generateStandardInspectorRoute} from '@octaved/flow/src/Routing/Routes/Inspectors/StandardInspector';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import {Dispatch, SetStateAction} from 'react';
import {
  DateLimitsResult,
  ResizeStartpoint,
  calculateDateLimitsByDate,
  resizeBar,
} from '../../../../../../../Calculations/BarMovement';
import {addPlanningDate} from '../../../../../../../Modules/PlanningDates';
import {getPlanningDatesForNodeSelector} from '../../../../../../../Selectors/PlanningDateSelectors';
import {BarManipulatingContext} from './BarManipulatingContext';

interface CreateProps {
  ctx: BarManipulatingContext;
}

interface CreateStart {
  setAdjustedPlannedStart: Dispatch<SetStateAction<DateStr>>;
  setAdjustedPlannedEnd: Dispatch<SetStateAction<DateStr>>;
  nodeId: Uuid;
  plannedStart: DateStr;
}

interface CreateState {
  setAdjustedPlannedStart: Dispatch<SetStateAction<DateStr>>;
  setAdjustedPlannedEnd: Dispatch<SetStateAction<DateStr>>;
  nodeId: Uuid;
  limitEnd: DateTimeStr | null;
  limitStart: DateTimeStr | null;
  isResizing: boolean;
  plannedStart: DateStr;
}

function getDirection(startPosition: number, currentPosition: number): ResizeStartpoint {
  return startPosition > currentPosition ? ResizeStartpoint.left : ResizeStartpoint.right;
}

export class Create {
  readonly #ctx: BarManipulatingContext;
  #state: CreateState | null = null;

  constructor({ctx: context}: CreateProps) {
    this.#ctx = context;
  }

  onEnd = (
    clientX: number,
    _clientY: number,
    _event: MouseEvent | TouchEvent,
    clientStartX: number,
    _clientStartY: number,
  ): void => {
    const state = this.#state;
    if (state) {
      const direction = getDirection(clientStartX, clientX);
      const storeState = this.#ctx.store.getState();
      const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(storeState);
      const resizeResult = resizeBar(
        clientStartX,
        clientX,
        direction,
        state.plannedStart,
        state.plannedStart,
        this.#ctx.calendarContext.cellWidth,
        state.limitStart,
        state.limitEnd,
        getWorkMinutesAtDate,
      );
      const planningDateId = generateUuid();
      this.#ctx.store.dispatch(
        addPlanningDate(
          state.nodeId,
          planningDateId,
          isoToIsoDateTimeFormat(resizeResult.plannedStart),
          isoToIsoDateTimeFormat(resizeResult.plannedEnd),
          true,
        ),
      );
      const node = getNodeSelector(storeState)(state.nodeId);
      if (node?.nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
        this.#ctx.navigate(generateStandardInspectorRoute(planningDateId));
      }
      this.#state = null;
    }
  };

  onMove = (
    clientX: number,
    _clientY: number,
    _event: MouseEvent | TouchEvent,
    clientStartX: number,
    _clientStartY: number,
  ): void => {
    if (this.#state) {
      const direction = getDirection(clientStartX, clientX);
      const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.#ctx.store.getState());
      const result = resizeBar(
        clientStartX,
        clientX,
        direction,
        this.#state.plannedStart,
        this.#state.plannedStart,
        this.#ctx.calendarContext.cellWidth,
        this.#state.limitStart,
        this.#state.limitEnd,
        getWorkMinutesAtDate,
      );
      this.#state.setAdjustedPlannedStart(result.plannedStart);
      this.#state.setAdjustedPlannedEnd(result.plannedEnd);
    }
  };

  onStart = ({nodeId, plannedStart, setAdjustedPlannedEnd, setAdjustedPlannedStart}: CreateStart): void => {
    const {end, start} = this.getDateLimits(nodeId, plannedStart);
    if (end && end === start) {
      // prevent creating of new bars if there is no space
      return;
    }
    this.#state = {
      nodeId,
      plannedStart,
      setAdjustedPlannedEnd,
      setAdjustedPlannedStart,
      isResizing: false,
      limitEnd: end,
      limitStart: start,
    };
    this.#ctx.closeInspector();
  };

  protected getDateLimits(nodeId: Uuid, plannedStart: DateStr): DateLimitsResult {
    const state = this.#ctx.store.getState();
    const planningDates = getPlanningDatesForNodeSelector(state)(nodeId);
    const date = isoToIsoDateTimeFormat(plannedStart);
    return calculateDateLimitsByDate(date, date, planningDates);
  }

  isCreating(): boolean {
    return Boolean(this.#state?.isResizing);
  }

  barIsUpdating(): boolean {
    return Boolean(this.#state);
  }

  cancel(): void {
    this.#state = null;
  }
}
