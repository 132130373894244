import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {generateSystemSettingsRoute} from '@octaved/flow/src/Routing/Routes/Configure';
import IsGranted from '@octaved/security/src/Authorization/IsGranted';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

interface Props {
  type: EnumFlowNodeRoleType;
  isGuestRole: boolean;
}

export default function NoRolesMessage({isGuestRole, type}: Props): ReactElement {
  const {t} = useTranslation();
  if (type === EnumFlowNodeRoleType.VALUE_PROJECT) {
    return (
      <div className={'flex flex-col gap-y-2'}>
        <div>{t('roles:noProjectRoles')}</div>
        <IsGranted right={'FLOW_GLOBAL_PROJECT_ROLES_MANAGE'}>
          <NavLink to={generateSystemSettingsRoute('projectRole')} className={'underline hover:underline'}>
            {t('roles:noProjectRoles.openProjectRolesPage')}
          </NavLink>
        </IsGranted>
      </div>
    );
  }
  if (isGuestRole) {
    return (
      <div className={'flex flex-col gap-y-2'}>
        <div>{t('guestUser:inspector.noGuestUserRoles')}</div>
        <IsGranted right={'FLOW_GLOBAL_GUEST_PERMISSION_ROLES_READ'}>
          <NavLink to={generateSystemSettingsRoute('guestRoles')} className={'underline hover:underline'}>
            {t('guestUser:inspector.noGuestUserRoles.openGuestRolePage')}
          </NavLink>
        </IsGranted>
      </div>
    );
  }
  return <div>{t('roles:noPermissionRoles')}</div>;
}
