import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import {isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getProjectForNodeSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {getTasksRootSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn, HStack, IconButton, Tooltip} from '@octaved/ui';
import {HelpCircle} from 'lucide-react';
import {ReactElement, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useStore} from 'react-redux';
import {Message, MessageContent} from 'semantic-ui-react';
import {PlanningRealizationNode} from '../../EntityInterfaces/PlanningRealizationNode';
import {
  DependencyType,
  validateCircularDependenciesSelector,
} from '../../Selectors/Validation/ValidateCircularDependencySelector';
import TaskDependencySelector from './TaskDependencySelector';
import WorkPackageDependencySelector from './WorkPackageDependencySelector';

export interface DependencyModalProps {
  dependencyType: DependencyType;
  dialogHeaderToken: string;
  dialogInfo?: string;
  excludedNodeIds: Set<Uuid>;
  helpInfo?: string;
  node: Pick<PlanningRealizationNode, 'id' | 'nodeType' | 'planningLogicalPredecessors'>;
  onClose: () => void;
  onSelect: (id: Uuid | null) => boolean;
  plannedOnly: boolean;
}

export default function DependencyDialog({
  dependencyType,
  dialogHeaderToken,
  dialogInfo,
  excludedNodeIds,
  helpInfo,
  node,
  onClose,
  onSelect,
  plannedOnly,
}: DependencyModalProps): ReactElement | null {
  const {t} = useTranslation();
  const [error, setError] = useState<boolean>(false);

  const isWorkPackageOrSubWorkPackage = isWorkPackage(node) || isSubWorkPackage(node);
  const selectorRootNodeId = useSelector((s: FlowState) => {
    let rootNodeId = null;
    if (isWorkPackageOrSubWorkPackage) {
      rootNodeId = getProjectForNodeSelector(s)(node.id)?.id || null;
    } else if (isTask(node)) {
      rootNodeId = getTasksRootSelector(s)(node.id)?.id || null;
    }
    return rootNodeId;
  });

  const {getState} = useStore<FlowState>();

  const _onSelect = useCallback(
    (id: Uuid | null): void => {
      const validateDependencies = validateCircularDependenciesSelector(getState());
      setError(false);
      if (id) {
        const error = validateDependencies(node.id, [...node.planningLogicalPredecessors, id], dependencyType);
        if (error.length) {
          setError(true);
          return;
        }
      }
      const close = onSelect(id);
      if (close) {
        onClose();
      }
    },
    [getState, onSelect, node.id, node.planningLogicalPredecessors, dependencyType, onClose],
  );

  if (!selectorRootNodeId) {
    return null;
  }

  return (
    <>
      <DialogFrame size={'large'}>
        <DialogTitle
          growTitle
          onClose={() => {
            setError(false);
            onClose();
          }}
        >
          <DialogAndDrawerHeader header={dialogHeaderToken} noPadding />
        </DialogTitle>

        <DialogContent>
          <div className={cn('content', `dependencyType-${dependencyType}`)}>
            {dialogInfo && (
              <div className={'info'}>
                <HStack spacing={'6px'} alignItems={'center'}>
                  <div>{t(dialogInfo)}</div>
                  {helpInfo && (
                    <Tooltip toolTipTranslation={helpInfo}>
                      <IconButton variant={'ghost'} icon={<HelpCircle />} size={'sm'} />
                    </Tooltip>
                  )}
                </HStack>
              </div>
            )}
            {error && (
              <Message visible error>
                <MessageContent>
                  <div>
                    {isWorkPackageOrSubWorkPackage &&
                      t('pages:projects.inspector.manage.planning.newDependencyCircularErrorForWorkpackage')}
                    {isTask(node) && t('pages:projects.inspector.manage.planning.newDependencyCircularErrorForTask')}
                  </div>
                </MessageContent>
              </Message>
            )}
            {isWorkPackageOrSubWorkPackage && (
              <WorkPackageDependencySelector
                projectId={selectorRootNodeId}
                excludedNodeIds={excludedNodeIds}
                onSelect={_onSelect}
                plannedOnly={plannedOnly}
              />
            )}
            {isTask(node) && (
              <TaskDependencySelector
                workPackageId={selectorRootNodeId}
                excludedNodeIds={excludedNodeIds}
                onSelect={_onSelect}
                plannedOnly={plannedOnly}
                dependencyType={dependencyType}
              />
            )}
          </div>
        </DialogContent>
      </DialogFrame>
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .close {
          position: absolute;
          right: 2px;
          cursor: pointer;
          top: 2px;
        }

        .changeProject {
          cursor: pointer;

          &.disabled {
            cursor: default;
          }
        }

        .info,
        .content {
          padding-bottom: 10px;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
