import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {combiner} from '@octaved/store/src/Reducer/Combiner';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {formatCollapsingDateRange, ISO_DATE, ISO_DATE_TIME} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import {Action} from 'redux';
import {getMyCalendarEnabledAccountEvents} from '../../config/routes';
import {CalendarEvent, CalendarEvents, StoreCalendarEvents} from '../EntityInterfaces/CalendarEvents';
import {ConnectedServiceDeletedEvent, ConnectedServicePatchedEvent} from '../Event/Events';
import {
  createTimeFrameLoadPeriods,
  EventLoadType,
  getCalendarEventsStoreKey,
  searchStateSelector,
} from '../Selectors/CalendarEventSelectors';
import {hasMyCalendarEnabledServicesSelector} from '../Selectors/ConnectedServiceSelectors';
import {
  INTEGRATION_LOAD_CALENDAR_EVENTS_FAILURE,
  INTEGRATION_LOAD_CALENDAR_EVENTS_REQUEST,
  INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS,
  INTEGRATION_REFRESH_CALENDAR_EVENTS,
} from './ActionTypes';

interface LoadAction {
  endIsoDate: string;
  key: string;
  response: CalendarEvent[];
  startIsoDate: string;
  type: typeof INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS;
}

type RefreshAction = Action<typeof INTEGRATION_REFRESH_CALENDAR_EVENTS>;

const eventReducers = createReducerCollection<CalendarEvents>({});
eventReducers.add(INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS, (state, {response}: LoadAction) => {
  const newState = {...state};
  response.forEach((event) => {
    newState[event.id] = event;
  });
  return newState;
});

const searchResultReducers = createReducerCollection<Record<string, string[] | undefined>>({});
searchResultReducers.add(INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS, (state, {key, response}: LoadAction) => {
  const newState = {...state};
  newState[key] = response.map(({id}) => id);
  return newState;
});

const searchStateReducers = createReducerCollection<EntityStates>({});
searchStateReducers.add(INTEGRATION_LOAD_CALENDAR_EVENTS_REQUEST, createTimestampReducer('key', LOADING));
searchStateReducers.add(INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS, createTimestampReducer('key', LOADED));
searchStateReducers.add<ConnectedServicePatchedEvent | ConnectedServiceDeletedEvent>(
  ['integration.ConnectedServicePatchedEvent', 'integration.ConnectedServiceDeletedEvent'],
  () => ({}),
);
searchStateReducers.add<RefreshAction>(INTEGRATION_REFRESH_CALENDAR_EVENTS, () => ({}));

export const calendarEventsReducer = combiner<StoreCalendarEvents>({
  events: eventReducers.reducer,
  searchResult: searchResultReducers.reducer,
  searchState: searchStateReducers.reducer,
});

const endpoints: Record<EventLoadType, string> = {
  [EventLoadType.myCalendar]: getMyCalendarEnabledAccountEvents,
};

export function refreshCalendarEvents(): RefreshAction {
  return {type: INTEGRATION_REFRESH_CALENDAR_EVENTS};
}

function loadCalendarEvents(
  type: EventLoadType,
  startIsoDate: string,
  endIsoDate: string,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const key = getCalendarEventsStoreKey(type, startIsoDate, endIsoDate);
    const state = searchStateSelector(getState())[key];
    if (!state || isOutdated(state)) {
      await dispatch({
        endIsoDate,
        key,
        startIsoDate,
        [CALL_API]: {
          endpoint: endpoints[type],
          method: 'get',
          options: {
            urlParams: {
              endIsoDate,
              startIsoDate,
            },
          },
          types: {
            failureType: INTEGRATION_LOAD_CALENDAR_EVENTS_FAILURE,
            requestType: INTEGRATION_LOAD_CALENDAR_EVENTS_REQUEST,
            successType: INTEGRATION_LOAD_CALENDAR_EVENTS_SUCCESS,
          },
        },
      });
    }
  };
}

/**
 * Loads the week containing `myCalendarTimeFrameStartDate`, plus one before and one after if we are on week view.
 */
export function useLoadMyCalendarEnabledAccountEvents(timeFrame: 'day' | 'week', startDate: DateStr): void {
  const loadPeriods = createTimeFrameLoadPeriods(timeFrame, startDate);
  const hasMyCalendarEnabledAccounts = useSelector(hasMyCalendarEnabledServicesSelector);
  const dispatch = useDispatch();

  useStoreEffect(
    () => {
      if (!hasMyCalendarEnabledAccounts) {
        return;
      }
      loadPeriods.forEach(({start, end}) => {
        dispatch(loadCalendarEvents(EventLoadType.myCalendar, start, end));
      });
    },
    [dispatch, hasMyCalendarEnabledAccounts, loadPeriods],
    searchStateSelector,
  );
}

export function formatCalendarEventDate(event: CalendarEvent): string {
  const start = dayjs(event.start, ISO_DATE_TIME);
  const end = dayjs(event.end, ISO_DATE_TIME);
  if (event.isAllDay) {
    return formatCollapsingDateRange(start, end, undefined, ' - ');
  }
  const startTime = start.format('HH:mm');
  const endTime = end.format('HH:mm');
  if (start.format(ISO_DATE) === end.format(ISO_DATE)) {
    return `${startTime} - ${endTime}`;
  }
  return `${start.format('DD.MM.')} ${startTime} - ${end.format('DD.MM.')} ${endTime}`;
}
