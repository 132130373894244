import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {areOrgUserNamesLoadedSelector, orgUserNameSelector} from '@octaved/users/src/Selectors/UserSelectors';
import classNames from 'classnames';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FlowState} from '../../Modules/State';
import TextSkeleton from '../Skeleton/TextSkeleton';

interface Props {
  userId: Uuid;
  inline?: boolean;
  noYou?: boolean;
}

export default function UserName({inline, userId, noYou}: Props): ReactElement {
  const {t} = useTranslation();
  const name = useSelector((s: FlowState) => orgUserNameSelector(s)(userId));
  const isLoading = useSelector((s: FlowState) => !areOrgUserNamesLoadedSelector(s)([userId]));
  const currentUser = useSelector(currentOrgUserIdSelector);
  const extendedName = useMemo<string>(() => {
    const you = t('general:you');
    return name + (currentUser === userId && !noYou ? ' (' + you + ')' : '');
  }, [noYou, name, userId, t, currentUser]);

  if (isLoading) {
    return (
      <div className={classNames('skeleton', {inline})}>
        <TextSkeleton />
        {/*#region styles*/}
        {/*language=SCSS*/}
        <style jsx>{`
          .skeleton {
            width: 200px;
            display: flex;
            align-items: center;
          }

          .inline {
            display: inline-block;
          }
        `}</style>
        {/*#endregion*/}
      </div>
    );
  }
  return <>{extendedName}</>;
}
