import {CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {PlanningSimulationSnapshot} from '../../Modules/SimulationSnapshot';
import {mergePatchNodesResponsibilitiesRequest} from './MergePatchNodesResponsibilities';
import {mergePatchPlanningRequest} from './MergePatchPlanningRequest';
import {mergePatchTaskRequest} from './MergePatchTaskRequest';
import {mergePatchWorkPackageRequest} from './MergePatchWorkPackageRequest';

export type ActionMap = Map<string, CallAction>;

const mergeFnList = [
  mergePatchPlanningRequest,
  mergePatchWorkPackageRequest,
  mergePatchNodesResponsibilitiesRequest,
  mergePatchTaskRequest,
];

export function mergeSimulationActions(snapshot: PlanningSimulationSnapshot, action: CallAction): CallAction[] {
  const unmergeableActions: CallAction[] = [];
  const actionMap: ActionMap = new Map();
  const actions = snapshot.actions.concat(action);
  for (const currentAction of actions) {
    if (!handleMergeableRequests(actionMap, snapshot, currentAction)) {
      unmergeableActions.push(currentAction);
    }
  }

  return Array.from(actionMap.values()).concat(...unmergeableActions);
}

function handleMergeableRequests(
  actionMap: ActionMap,
  snapshot: PlanningSimulationSnapshot,
  action: CallAction,
): boolean {
  for (const fn of mergeFnList) {
    if (fn(actionMap, snapshot, action)) {
      return true;
    }
  }
  return false;
}
