import {isSubWorkPackage, isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useStore} from 'react-redux';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {getIsPlannedRelativeToParentSelector} from '../../../Selectors/PlanningDependencySelectors';

export enum PlanningType {
  none,
  date,
  relativeToParent,
  dependency,
}

function getPlanningType(node: PlanningRealizationNode, state: FlowState): PlanningType {
  if (isTask(node) || isSubWorkPackage(node)) {
    if (node.planningPredecessors.length === 1) {
      const isRelative = getIsPlannedRelativeToParentSelector(state)(node.id);
      if (isRelative) {
        return PlanningType.relativeToParent;
      }
    }
  }

  if (node.planningPredecessors.length > 0) {
    return PlanningType.dependency;
  }

  if (node.planningDates.length > 0) {
    return PlanningType.date;
  }

  return PlanningType.none;
}

export function usePlanningType(node: PlanningRealizationNode): [PlanningType, Dispatch<SetStateAction<PlanningType>>] {
  const {getState} = useStore<FlowState>();
  const [planningType, setPlanningType] = useState(() => getPlanningType(node, getState()));
  useEffect(() => {
    setPlanningType(getPlanningType(node, getState()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.id, node.planningDates.join('-'), getState, node.planningPredecessors.join('-')]);
  return [planningType, setPlanningType];
}
