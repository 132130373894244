import {Uuid} from '@octaved/typescript/src/lib';
import {Trash} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {TaskSection} from '../../../EntityInterfaces/TaskSection';
import {deleteTaskSection, patchTaskSection} from '../../../Modules/TaskSections';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import {useTaskListContext} from '../TaskListContext';
import {useDragTaskSection} from '../TaskListForNode/DragAndDrop/DragItems';
import {OnEnterKeyDown} from '../TaskListForNode/EditName';
import {useNameSnapshot} from '../TaskListForNode/NameSnapshot';
import TaskList from '../TaskListForNode/Task/TaskList';
import TaskSectionName from '../TaskListForNode/TaskSection/TaskSectionName';
import BaseTaskGroup from './TaskGroup';
import TaskSectionColorPicker from './TaskSectionColorPicker';

const onEnterKeyDown: OnEnterKeyDown = ({blur}) => {
  blur();
};

interface Props {
  index: number;
  parentNodeId: Uuid;
  taskSection: TaskSection;
}

export default function TaskSectionGroup({index, parentNodeId, taskSection}: Props): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const {dragPreviewRef, dragRef, dropRef, isDragging} = useDragTaskSection(taskSection, index, parentNodeId);

  const name = useNameSnapshot(taskSection, (partial) => dispatch(patchTaskSection(taskSection.id, partial)));
  const {TaskGroup = BaseTaskGroup} = useTaskListContext();

  return (
    <>
      <TaskGroup
        id={taskSection.id}
        isDragging={isDragging}
        dragRef={dragRef}
        dropRef={dropRef}
        ref={dragPreviewRef}
        name={
          <TaskSectionName onBlur={name.commit} name={name.value} patch={name.patch} onEnterKeyDown={onEnterKeyDown} />
        }
      >
        <TaskList parentId={taskSection.id} />
      </TaskGroup>

      {showConfirmDelete && (
        <ConfirmDialog
          title={'taskList:confirmDeleteSection.title'}
          buttonLabel={'taskList:confirmDeleteSection.buttonLabel'}
          buttonIcon={<Trash />}
          buttonColorScheme={'delete'}
          cancelCallback={() => setShowConfirmDelete(false)}
          submitCallback={() => {
            dispatch(deleteTaskSection(taskSection.id));
            setShowConfirmDelete(false);
          }}
        >
          <span>{t('taskList:confirmDeleteSection.text')}</span>
        </ConfirmDialog>
      )}

      {showColorPicker && (
        <TaskSectionColorPicker
          onClose={() => setShowColorPicker(false)}
          sectionId={taskSection.id}
          triggerRef={dragRef}
        />
      )}
    </>
  );
}
