import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import UnitNameWithIcon from '@octaved/flow/src/Components/Unit/UnitNameWithIcon';
import {useEffectiveRoleAssignmentsOnNode} from '@octaved/flow/src/Modules/Hooks/NodeRoleAssignments';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import Placeholder from '../../PlaceholderText';
import Section from '../../Section';
import {OptionalNoLabelProps} from '../Common/Type';

interface NodeProjectManagerSectionProps {
  nodeId: Uuid;
}

export default function NodeProjectManagerSection({
  nodeId,
  noLabel,
}: NodeProjectManagerSectionProps & OptionalNoLabelProps): ReactElement | null {
  const {assignments} = useEffectiveRoleAssignmentsOnNode(EnumFlowNodeRoleType.VALUE_PROJECT, nodeId);
  const projectManager = useMemo(() => assignments.filter(({role}) => role.isProjectManagerRole), [assignments]);
  return (
    <Section labelToken={'nodeDetails:field.projectManager.label'} noLabel={noLabel}>
      <div className={'flex flex-wrap gap-x-2 gap-y-1'}>
        {projectManager.map((unit, idx) => (
          <div key={unit.unitId} className={'flex'}>
            <UnitNameWithIcon className={'gap-x-1 px-0'} key={unit.unitId} avatarSize={'small'} {...unit} />
            {projectManager.length - 1 !== idx && <div className={'text-slate-800'}>,</div>}
          </div>
        ))}
        {projectManager.length === 0 && <Placeholder text={'nodeDetails:field.projectManager.placeholder'} />}
      </div>
    </Section>
  );
}
