import {lazy} from 'react';
import {RouteObject} from 'react-router-dom';
import FlowOutlet from '../../Components/FlowOutlet';
import {
  organizationNameRoute,
  projectNameRoute,
  setupRoute,
  setupRoutes,
  startingPointRoute,
  tasksRoute,
  typeOfWorkRoute,
  workPackagesRoute,
} from './Setup';

const myOrganizations = 'myOrganizations';

const OrganizationSelect = lazy(() => import('../../Pages/MultiOrganization/OrganizationSelect'));

export const myOrgsRoutes: RouteObject[] = [
  {
    children: [...setupRoutes, {path: '*', element: <OrganizationSelect />}],
    element: <FlowOutlet />,
    path: `${myOrganizations}/*`,
  },
];

export function getMyOrgsRoute(): string {
  return `/${myOrganizations}`;
}

export function getMyOrgsSetupRoute(): string {
  return `${getMyOrgsRoute()}/${setupRoute}`;
}

export function getMyOrgsSetupOrganizationNameRoute(): string {
  return `${getMyOrgsSetupRoute()}/${organizationNameRoute}`;
}

export function getMyOrgsSetupTypeOfWorkRoute(): string {
  return `${getMyOrgsSetupRoute()}/${typeOfWorkRoute}`;
}

export function getMyOrgsSetupStartingPointRoute(): string {
  return `${getMyOrgsSetupRoute()}/${startingPointRoute}`;
}

export function getMyOrgsSetupProjectNameRoute(): string {
  return `${getMyOrgsSetupRoute()}/${projectNameRoute}`;
}

export function getMyOrgsSetupWorkPackagesRoute(): string {
  return `${getMyOrgsSetupRoute()}/${workPackagesRoute}`;
}

export function getMyOrgsSetupTasksRoute(): string {
  return `${getMyOrgsSetupRoute()}/${tasksRoute}`;
}
