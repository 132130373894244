import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import MaterialResourceInspectorContext from '../../../../Drawer/InspectorContext/MaterialResourceInspectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const materialResourcesRoute = 'materialResources';

const MaterialResourcesInspector = lazy(
  () => import('../../../../Pages/SystemSettings/MaterialResources/Inspector/MaterialResourceInspector'),
);

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['materialResource']: [
    {
      path: materialResourcesRoute,
      token: '',
    },
  ],
};
//endregion

export const materialResourcesInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <MaterialResourcesInspector />
        </InspectorContextLoadingWrapper>
      ),
      path: materialResourcesRoute,
    },
    {
      element: <Navigate to={materialResourcesRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: MaterialResourceInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
