import {useCallback, useEffect, useMemo, useState} from 'react';

export function useBoolean(
  defaultValue: boolean,
): [value: boolean, setValue: {on: () => void; off: () => void; toggle: () => void; set: (v: boolean) => void}] {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue]);
  const on = useCallback(() => setValue(true), []);
  const off = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((v) => !v), []);
  const set = useCallback((v) => setValue(v), []);
  const setter = useMemo(() => ({on, off, toggle, set}), [on, off, toggle, set]);
  return [value, setter];
}
