import {ComponentType, ReactElement, ReactNode, useContext} from 'react';
import css from 'styled-jsx/css';
import context from './StyleContext';
import treeContext from './TreeContext';

export interface Icons {
  angle: ComponentType | ReactNode;
  node: ComponentType | ReactNode;
}

export interface StyleParameters {
  selectedBackgroundColor: string;
}

export default function Styles({children}: {children: ReactElement}): ReactElement {
  const {style} = useContext(treeContext);

  /*language=SCSS*/
  const {className, styles} = css.resolve`
    .ui.list .list:not(.icon) {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 28px;
    }

    .ui.list .item.flex {
      display: flex;
      width: fit-content;
      flex-direction: column;
    }

    .ui.list .item.flex .flexItemInner {
      display: flex;
      width: fit-content;
      flex-direction: row;
    }

    .ui.list .item.flex .foldIndicator .folderIndicatorIcon {
      transition: all 120ms ease-in-out;
      transform: rotate(-90deg);
      display: flex;
      width: 11px;
      height: 11px;
    }

    .ui.list .item.flex .foldIndicator.open .folderIndicatorIcon {
      transform: rotate(0deg);
    }

    .folderIndicatorIcon,
    .nodeIcon {
      margin-right: 6px;
      width: 21px;
      height: 21px;
      display: flex;
      align-items: center;
    }

    .ui.list .item .flexItemInner .content.treeNode {
      display: flex;
      flex-direction: row;
      padding: 5px 15px 5px 10px;
      width: auto;
      align-items: center;
      height: 100%;
      user-select: none;
      margin-left: 14px;

      .nodeContent {
        width: 100%;
      }

      .ui.dropdown :global(.icon.ellipsis) {
        margin-left: 5px;
        display: none;
        cursor: pointer;
      }

      button.dropdownMenu {
        visibility: hidden;
        cursor: pointer;
        pointer-events: none;
      }

      &.selected button.dropdownMenu,
      &:hover button.dropdownMenu {
        pointer-events: all;
        visibility: visible;
      }

      &.selected .ui.dropdown :global(.icon.ellipsis),
      &:hover .ui.dropdown :global(.icon.ellipsis) {
        display: inline-block;
      }
    }

    .nodeIconMore {
      width: 15px;
      height: 19px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .ui.list .item.flex .foldIndicator {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .ui.list .item.flex .foldIndicator ~ .content.treeNode {
      margin-left: 0;
    }

    .tree {
      &.ui.list .item .flexItemInner .content.treeNode,
      .ui.list .item .flexItemInner .content.treeNode {
        cursor: pointer;
      }

      .selected {
        font-weight: bold;
        background: ${style.selectedBackgroundColor};
      }
    }

    .ui.list.padded.tree {
      padding: 0;
    }
  `;

  return (
    <context.Provider value={{cssPrefix: className}}>
      {children}
      {styles}
    </context.Provider>
  );
}
