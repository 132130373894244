import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWProgress} from '@octaved/ui';
import {format} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import Placeholder from '../../PlaceholderText';
import {FramedSectionOrTileProps} from './Type';

export function useWpAndSwpStats(nodeId: Uuid): {
  swpIds: readonly Uuid[];
  wpIds: readonly Uuid[];
  doneSwpIds: readonly Uuid[];
  doneWpIds: readonly Uuid[];
} {
  const descendants = withDescendants(nodeId, true);
  const [{ids: swpIds}, {ids: doneSwpIds}, {ids: wpIds}, {ids: doneWpIds}] = useCombinedNodeSearches(
    {},
    {
      and: [descendants, ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]],
    },
    {
      and: [descendants, ['swpIsCompleted']],
    },
    {
      and: [descendants, ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE]],
    },
    {
      and: [descendants, ['wpIsCompleted']],
    },
  );
  return {doneSwpIds, doneWpIds, swpIds, wpIds};
}

type AllowedNodeTypes =
  | EnumFlowNodeType.VALUE_GROUP
  | EnumFlowNodeType.VALUE_PROJECT
  | EnumFlowNodeType.VALUE_WORK_PACKAGE;

export const labelTokensMap: Record<AllowedNodeTypes, string> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'nodeDetails:field.workPackageStats.labelGroup',
  [EnumFlowNodeType.VALUE_PROJECT]: 'nodeDetails:field.workPackageStats.labelProject',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'nodeDetails:field.workPackageStats.labelWorkPackage',
};

export interface NodeWPAndSWPStatsProps {
  nodeId: Uuid;
  nodeType: AllowedNodeTypes;
}

export default function NodeWPAndSWPStats({
  frame: Frame,
  nodeId,
  nodeType,
}: NodeWPAndSWPStatsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const {doneSwpIds, doneWpIds, swpIds, wpIds} = useWpAndSwpStats(nodeId);

  if (nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE && swpIds.length === 0) {
    return null;
  }

  return (
    <Frame labelToken={labelTokensMap[nodeType]}>
      <div className={'grid grid-cols-progressWithLabel items-center gap-x-3 gap-y-1'}>
        {nodeType !== EnumFlowNodeType.VALUE_WORK_PACKAGE && (
          <>
            <TWProgress progress={(doneWpIds.length / wpIds.length) * 100} />

            {wpIds.length !== 0 && (
              <div>
                {t('nodeDetails:field.workPackageStats.wpsDone', {
                  count: wpIds.length,
                  countFormatted: format(wpIds.length, 0),
                  value: format(doneWpIds.length, 0),
                })}
              </div>
            )}
            {wpIds.length === 0 && <Placeholder text={'nodeDetails:field.workPackageStats.noWorkPackages'} />}
          </>
        )}
        {swpIds.length > 0 && (
          <>
            <TWProgress progress={(doneSwpIds.length / swpIds.length) * 100} />
            <div>
              {t('nodeDetails:field.workPackageStats.swpsDone', {
                count: swpIds.length,
                countFormatted: format(swpIds.length, 0),
                value: format(doneSwpIds.length, 0),
              })}
            </div>
          </>
        )}
      </div>
    </Frame>
  );
}
