import {EntityStates, filterIdsToReload, hasLoadedOnce, isLoaded, missesField} from '@octaved/store/src/EntityState';
import {dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {OrgUserEntities, OrgUserEntity} from '../EntityInterfaces/UserEntity';
import {loadUserDisplayNames} from '../Modules/OrgUser';
import {UserState} from '../UserState';

export const userEntitiesSelector = (state: UserState): OrgUserEntities => state.entities.orgUser;
export const userEntityStatesSelector = (state: UserState): EntityStates => state.entityStates.orgUser;

function hashUserIdsWithFields(userIds: ReadonlyArray<Uuid>, wantedFields: string[] | null): string {
  return userIds.join('-') + (wantedFields ? `@${wantedFields.join('-')}` : '');
}

export const getUserIdsToLoadSelector = createSelector(userEntityStatesSelector, (userEntityStates) =>
  memoize((userIds: ReadonlyArray<Uuid>, wantedFields: string[] | null) => {
    const unique = [...new Set(userIds)];
    return filterIdsToReload(userEntityStates, unique, wantedFields);
  }, hashUserIdsWithFields),
);

export const haveUsersLoadedOnceSelector = createSelector(userEntityStatesSelector, (userEntityStates) =>
  memoize((userIds: ReadonlyArray<Uuid>, wantedFields: string[] | null) => {
    return userIds.every((userId) => {
      const state = userEntityStates[userId];
      return state && hasLoadedOnce(state) && !missesField(state, wantedFields);
    });
  }, hashUserIdsWithFields),
);
export const areOrgUsersLoadedSelector = createSelector(userEntityStatesSelector, (userEntityStates) =>
  memoize((userIds: ReadonlyArray<Uuid>, wantedFields: string[] | null) => {
    return userIds.every((userId) => {
      const state = userEntityStates[userId];
      return state && isLoaded(state) && !missesField(state, wantedFields);
    });
  }, hashUserIdsWithFields),
);

export const haveUserNamesLoadedOnceSelector = createSelector(
  haveUsersLoadedOnceSelector,
  (areUsersLoadedSelector) => (userIds: ReadonlyArray<Uuid>) => areUsersLoadedSelector(userIds, ['name']),
);
export const areOrgUserNamesLoadedSelector = createSelector(
  areOrgUsersLoadedSelector,
  (areUsersLoadedSelector) => (userIds: ReadonlyArray<Uuid>) => areUsersLoadedSelector(userIds, ['name']),
);

export const orgUserNameSelector = createSelector(userEntitiesSelector, (userEntities) =>
  memoize((id: Uuid): string => {
    const user = userEntities[id];
    if (!user) {
      setTimeout(() => dispatch(loadUserDisplayNames([id])), 0);
      return '';
    }
    return user.name;
  }),
);

export const userSelector = createSelector(
  userEntitiesSelector,
  (userEntities) =>
    (id: Uuid): OrgUserEntity | null =>
      userEntities[id] || null,
);
