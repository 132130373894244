import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {DropdownItemProps} from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

function yearOptions(): DropdownItemProps[] {
  const options = new Array(5).fill(undefined);
  const currentYear = dayjs().year();
  return options.map((_e, idx) => ({text: currentYear - idx, value: currentYear - idx}));
}

interface Props {
  disabled?: boolean;
  year: number;
  setYear: (year: number) => void;
}

function YearPicker({disabled, year, setYear}: Props): ReactElement {
  return (
    <div className={'yearInput'}>
      <Dropdown
        disabled={disabled}
        selection
        fluid
        options={yearOptions()}
        value={year}
        onChange={(_e, {value}) => setYear(+value!)}
      />

      <style jsx>{`
        .yearInput {
          max-width: 130px;
        }
      `}</style>
    </div>
  );
}

export {YearPicker};
