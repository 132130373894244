import {FlowStyleBuilder, useFlowStyle} from '@octaved/flow/src/Styles/StyleContext';
import classNames from 'classnames';
import {AnimatePresence, motion, Variants} from 'framer-motion';
import {ReactElement, ReactNode} from 'react';
import {Popup, PopupProps} from 'semantic-ui-react';
import css from 'styled-jsx/css';

//#region <styles>
/*language=SCSS*/
const getStyle: FlowStyleBuilder = ({COLORS: {LEVITATION}}) => css.resolve`
  .popup {
    padding: 0;
    border-radius: 0 !important;
    border: none;
    box-shadow: 2px 2px 15px ${LEVITATION.LEVEL_2};
  }
`;

//#endregion

export interface PopProps extends PopupProps {
  children: ReactNode;
  additionalClassName?: string;
  open: boolean;
  position?: PopupProps['position'];
  setOpen: (open: boolean) => void;
  trigger?: ReactNode;
  variants: Variants;
}

export default function Pop({
  additionalClassName,
  children,
  open,
  position = 'bottom left',
  setOpen,
  trigger,
  variants,
  ...props
}: PopProps): ReactElement {
  const {className, styles} = useFlowStyle(getStyle);

  return (
    <>
      <AnimatePresence>
        <Popup
          mountNode={document.querySelector('body')}
          className={classNames(className, 'popup', additionalClassName)}
          basic
          position={position}
          on={'click'}
          as={motion.div}
          trigger={trigger}
          initial={'hidden'}
          animate={open ? 'visible' : 'hidden'}
          variants={variants}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          flowing
          {...props}
        >
          {children}
        </Popup>
      </AnimatePresence>
      {styles}
    </>
  );
}
