import Search from '@octaved/semantic-ui/src/Search';
import {Uuid} from '@octaved/typescript/src/lib';
import * as React from 'react';
import {ReactElement} from 'react';
import {FullUnit} from '../EntityInterfaces/UnitLists';
import {UnitListsOptions} from '../Hooks/UnitLists';
import {useUnitSearch} from '../Hooks/UnitSearch';
import {ExtendedUnitType, SimpleFromExtendedUnitType, SimpleUnitType} from '../UnitType';

type ResultItemRenderer<ResultingUnitType extends SimpleUnitType = SimpleUnitType> = React.FunctionComponent<{
  result: FullUnit<ResultingUnitType>;
}>;

const defaultRenderer: ResultItemRenderer = ({result}) => <div>{result.unitName}</div>;

export interface UnitSearchProps<ResultingUnitType extends ExtendedUnitType = ExtendedUnitType>
  extends Pick<UnitListsOptions, 'excludeSystemControlledGroups' | 'filterGuestUserByCustomerId'> {
  autoFocus?: boolean;
  className?: string;
  closeOnSelect?: boolean;
  excludedUnits?: ReadonlySet<Uuid> | ReadonlyArray<Uuid>;
  fluid?: boolean;
  includedUnits?: ReadonlySet<Uuid> | ReadonlyArray<Uuid>;
  onSelect: (unitId: Uuid, unitType: SimpleFromExtendedUnitType<ResultingUnitType>, unitName: string) => void;
  placeholder?: string;
  ResultItemRenderer?: ResultItemRenderer<SimpleFromExtendedUnitType<ResultingUnitType>>;
  unitTypes: ReadonlyArray<ResultingUnitType>;
  upwards?: boolean;
}

export default function UnitSearch<ResultingUnitType extends ExtendedUnitType = ExtendedUnitType>({
  autoFocus,
  className,
  closeOnSelect = false,
  excludedUnits,
  excludeSystemControlledGroups,
  filterGuestUserByCustomerId,
  fluid,
  includedUnits,
  onSelect,
  placeholder,
  ResultItemRenderer = defaultRenderer as ResultItemRenderer<SimpleFromExtendedUnitType<ResultingUnitType>>,
  unitTypes,
  upwards,
}: UnitSearchProps<ResultingUnitType>): ReactElement {
  const {isLoading, results, searchValue, setSearchValue} = useUnitSearch({
    excludeSystemControlledGroups,
    filterGuestUserByCustomerId,
    unitTypes,
    excludedUnitIds: excludedUnits,
    includedUnitIds: includedUnits,
  });
  return (
    <Search<FullUnit<SimpleFromExtendedUnitType<ResultingUnitType>>>
      className={className}
      resultKey={({unitId}) => unitId}
      isLoading={isLoading}
      onSelect={({unitId, unitType, unitName}) => onSelect(unitId, unitType, unitName)}
      onSearch={setSearchValue}
      value={searchValue}
      placeholder={placeholder}
      results={results}
      autoFocus={autoFocus}
      ResultItemRenderer={ResultItemRenderer}
      closeOnSelect={closeOnSelect}
      fluid={fluid}
      upwards={upwards}
    />
  );
}
