import classNames from 'classnames';
import {FormEvent, ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {Checkbox, CheckboxProps} from 'semantic-ui-react';
import css from 'styled-jsx/css';

//#region <styles>

interface ToggleStyles {
  fontColor?: string;
  containerBackgroundColorNotActive?: string;
  containerBackgroundColorActive?: string;
  containerBorderColor?: string;
  containerShadow?: string;
  slideBackgroundColor?: string;
  small?: boolean;
}

function getStyles({
  fontColor = '#111',
  containerBackgroundColorNotActive = 'rgba(0, 0, 0, 0.05)',
  containerBackgroundColorActive = '#66c010',
  containerBorderColor = 'initial',
  containerShadow = 'none',
  slideBackgroundColor = '#fff linear-gradient(transparent,rgba(0,0,0,.05))',
}: ToggleStyles): {className: string; styles: JSX.Element} {
  /*language=SCSS*/
  return css.resolve`
    .container {
      display: flex;
      width: fit-content;
    }

    .ui.fitted.toggle.checkbox.toggle {
      width: 34px;
    }

    .ui.toggle.checkbox .label {
      padding-left: 46px;
      color: ${fontColor};

      &::before {
        width: 34px;
        border-radius: 13px;
        background-color: ${containerBackgroundColorNotActive};
      }

      &::after {
        background: ${slideBackgroundColor};
        border: 1px solid ${containerBorderColor};
        box-shadow: 0 1px 2px ${containerShadow};
        height: 20px;
        width: 20px;
        left: -2px;
      }
    }

    .ui.toggle.checkbox.small {
      min-height: 14px;
      font-size: 14px;
    }

    .ui.toggle.checkbox.small .label {
      padding-left: 32px;
      padding-top: 0;
      color: ${fontColor};
      display: flex;
      align-items: center;

      &::before {
        top: 3px;
        width: 24px;
        height: 14.5px;
        border-radius: 12px;
        background-color: ${containerBackgroundColorNotActive};
      }

      &::after {
        top: 3px;
        background: ${slideBackgroundColor};
        height: 14px;
        width: 14px;
        box-shadow: 0 1px 2px ${containerShadow};
        border: none;
        left: 0;
      }
    }

    .ui.toggle.checkbox.checked label.checked:before {
      background-color: ${containerBackgroundColorActive} !important;
    }

    .ui.toggle.checkbox.checked label.checked:after {
      left: 18px;
    }

    .ui.toggle.checkbox.small.checked label.checked:after {
      left: 11px;
    }

    .description {
      font-size: 0.9em;
      margin-top: 8px;

      &:not(.noDescriptionIdent) {
        margin-left: 46px;
      }
    }

    .normalDescriptionSize {
      font-size: 1em;
    }
  `;
}

//#endregion

interface Props {
  checked: boolean;
  description?: string;
  disabled?: boolean;
  label?: string | ReactElement;
  noDescriptionIdent?: boolean;
  normalDescriptionSize?: boolean;
  onChange?: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  prefix?: ReactElement;
  setChecked?: (v: boolean) => void;
  showDescriptionWhenUnchecked?: boolean;
  small?: boolean;
  styleVariables?: ToggleStyles;
  suffix?: ReactElement;
}

export default function Toggle({
  checked,
  description,
  disabled,
  label = '',
  noDescriptionIdent,
  normalDescriptionSize,
  onChange,
  prefix,
  setChecked,
  showDescriptionWhenUnchecked,
  small,
  styleVariables = {},
  suffix,
}: Props): ReactElement {
  const {className, styles} = getStyles(styleVariables);
  return (
    <div className={classNames(className, 'container')} onClick={() => !disabled && setChecked && setChecked(!checked)}>
      <Checkbox
        className={classNames(className, 'toggle', {small})}
        toggle
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        label={
          <label className={classNames(className, 'label', {checked})}>
            {prefix}
            {typeof label === 'string' ? <Trans i18nKey={label} /> : label}
            {suffix}
          </label>
        }
      />
      {/* TODO: Tailwind - description not displayed (token !== i18nKey)*/}
      {!!description && (checked || showDescriptionWhenUnchecked) && (
        <div className={classNames(className, 'description', {normalDescriptionSize, noDescriptionIdent})}>
          <Trans token={description} />
        </div>
      )}
      {styles}
    </div>
  );
}
