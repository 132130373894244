import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {get, memoize} from 'lodash';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {createSelector} from 'reselect';
import {hasConfiguration} from '../../Pages/SystemSettings/Audit/TableConfigurations/Confgurations';
import type {AuditSearchParams, AuditState, AuditTables, Connection} from '../Audit';
import {FlowState} from '../State';

const auditSelector = (state: FlowState): AuditState => state.audit;
const selectedAuditRevisionUserSelector = (state: FlowState): Uuid | null =>
  state.ui.pages.systemSettings.selectedAuditRevisionUser;

export const generateAuditSearchKey = memoize(
  ({id, revisionUserId}: AuditSearchParams): string => {
    return boolFilter([id, revisionUserId]).join('-');
  },
  ({id, revisionUserId}: AuditSearchParams): string => `${id}-${revisionUserId}`,
);

export function useSelectedAuditTable(): AuditTables {
  const [searchParams] = useSearchParams();
  const selectedTable = searchParams.get('selectedTable');
  if (hasConfiguration(selectedTable)) {
    return selectedTable;
  }
  return 'workPackages';
}

export function useAuditIdFilter(): Uuid | null {
  const [searchParams] = useSearchParams();
  return searchParams.get('idFilter');
}

export function useAuditBaseSearchParams(): AuditSearchParams {
  const idFilter = useAuditIdFilter();
  const revisionUserId = useSelector(selectedAuditRevisionUserSelector);

  return useMemo(() => {
    const filter: AuditSearchParams = {};
    if (idFilter) {
      filter.id = idFilter;
    }
    if (revisionUserId) {
      filter.revisionUserId = revisionUserId;
    }
    return filter;
  }, [idFilter, revisionUserId]);
}

export const getAuditConnectionSelector = createSelector(
  auditSelector,
  (audit) =>
    <T>(tableName: AuditTables, searchParams: AuditSearchParams = {}): Connection<T> | undefined => {
      const searchKey = generateAuditSearchKey(searchParams);
      return get(audit, [tableName, searchKey]) as Connection<T> | undefined;
    },
);

export const auditIsLoadingSelector = createSelector(
  getAuditConnectionSelector,
  (getAuditConnection) => (tableName: AuditTables, searchParams: AuditSearchParams) => {
    const connection = getAuditConnection(tableName, searchParams);
    if (connection) {
      return connection.pageInfo.isLoading;
    }
    return false;
  },
);

export const auditHasLoadedSelector = createSelector(
  getAuditConnectionSelector,
  (getAuditConnection) => (tableName: AuditTables, searchParams: AuditSearchParams) => {
    const connection = getAuditConnection(tableName, searchParams);
    if (connection) {
      return connection.pageInfo.hasLoaded;
    }
    return false;
  },
);
