import {identityIdSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {organizationSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {NIL} from '@octaved/utilities';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import {getMyOrgsRoute} from './Routes/MyOrganizations';
import {getLoginRoute} from './Routes/Public';

interface RequireAuthProps {
  children: JSX.Element;
}

export default function RequireAuth({children}: RequireAuthProps): JSX.Element {
  const identityId = useSelector(identityIdSelector);
  const location = useLocation();
  const organization = useSelector(organizationSelector);
  if (identityId === NIL) {
    return <Navigate to={getLoginRoute()} state={{from: location}} replace />;
  }
  if (!organization && !location.pathname.startsWith(getMyOrgsRoute())) {
    return <Navigate to={getMyOrgsRoute()} state={{from: location}} replace />;
  }
  return children;
}
