import {patchWorkPackage} from '@octaved/flow-api';
import {WorkPackagePatchData} from '@octaved/flow/src/EntityInterfaces/Pid';
import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {PlanningSimulationSnapshot} from '../../Modules/SimulationSnapshot';
import {ActionMap} from './MergeSimulationActions';

type PatchWorkPackageRequest = CallAction<{data: Partial<WorkPackagePatchData>; urlParams: {workPackageId: Uuid}}>;

export function isPatchWorkPackageRequest(action: CallAction | undefined): action is PatchWorkPackageRequest {
  return action?.[CALL_API].endpoint === patchWorkPackage;
}

export function mergePatchWorkPackageRequest(
  actionMap: ActionMap,
  snapshot: PlanningSimulationSnapshot,
  action: CallAction,
): boolean {
  if (!isPatchWorkPackageRequest(action)) {
    return false;
  }

  const {
    [CALL_API]: {endpoint},
  } = action;
  const nodeId = action[CALL_API].options!.urlParams.workPackageId;
  const key = `${endpoint}-${nodeId}`;

  const prevAction = actionMap.get(key);
  let mergeResult: PatchWorkPackageRequest;
  if (isPatchWorkPackageRequest(prevAction)) {
    mergeResult = createMergedAction(prevAction, action);
  } else {
    mergeResult = action;
  }
  if (!snapshot.changedNodeIds.includes(nodeId)) {
    snapshot.changedNodeIds.push(nodeId);
  }
  actionMap.set(key, mergeResult);

  return true;
}

function createMergedAction(
  actionA: PatchWorkPackageRequest,
  actionB: PatchWorkPackageRequest,
): PatchWorkPackageRequest {
  return {
    ...actionB,
    [CALL_API]: {
      ...actionB[CALL_API],
      options: {
        ...actionB[CALL_API].options!,
        data: {...actionA[CALL_API].options!.data, ...actionB[CALL_API].options!.data},
      },
    },
  };
}
