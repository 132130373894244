import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import ProtectedPageRedirect from '../ProtectedPageRedirect';
import {userInspectorRoutes} from './Inspectors/SystemSettings/UserInspector';
import {SystemSettingsMenuOption} from './SystemSettings';

const paths = {
  api: 'api',
  customer: 'customer',
  guest: 'guest',
  regular: 'regular',
};

const RegularUser = lazy(() => import('../../Pages/SystemSettings/User/RegularUser/RegularUser'));
const Guest = lazy(() => import('../../Pages/SystemSettings/User/GuestUser/GuestUser'));
const Customer = lazy(() => import('../../Pages/SystemSettings/User/CustomerUser/CustomerUser'));
const ApiUser = lazy(() => import('../../Pages/SystemSettings/User/ApiUser/ApiUser'));
const ApiUserInspector = lazy(() => import('../../Pages/SystemSettings/User/ApiUser/ApiUserInspector'));

const apiUserMenu = {
  access: ({isGranted, licensedFeatures}) =>
    licensedFeatures.apiUsage && isGranted('FLOW_GLOBAL_INTERNAL_USERS_MANAGE'),
  name: 'systemSettings:menu.user.api',
  path: paths.api,
} as const satisfies SystemSettingsMenuOption;

export const userSettingsRoutes: RouteObject[] = [
  {
    children: userInspectorRoutes,
    element: <RegularUser />,
    path: `${paths.regular}/*`,
  },
  {
    children: userInspectorRoutes,
    element: <Guest />,
    path: `${paths.guest}/*`,
  },
  {
    children: userInspectorRoutes,
    element: <Customer />,
    path: `${paths.customer}/*`,
  },
  {
    children: [
      {
        element: <ApiUserInspector />,
        path: ':apiUserId',
      },
    ],
    element: (
      <ProtectedPageRedirect access={apiUserMenu.access}>
        <ApiUser />
      </ProtectedPageRedirect>
    ),
    path: `${paths.api}/*`,
  },
  {
    element: <Navigate to={paths.regular} replace />,
    path: '*',
  },
];

export const menuOptions: SystemSettingsMenuOption[] = [
  {
    access: 'FLOW_GLOBAL_INTERNAL_USERS_MANAGE',
    name: 'systemSettings:menu.user.regular',
    path: paths.regular,
  },
  {
    access: 'FLOW_GLOBAL_GUEST_USERS_MANAGE',
    name: 'guestUser:usersPage.menuGuestUser',
    path: paths.guest,
  },
  {
    access: 'FLOW_GLOBAL_GUEST_USERS_MANAGE',
    name: 'guestUser:usersPage.menuGuestUserCusotmer',
    path: paths.customer,
  },
  apiUserMenu,
];
