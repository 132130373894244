import {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {Uuid} from '@octaved/typescript/src/lib';
import {unformat, validateLocaleFormat} from '@octaved/users/src/Culture/NumberFormatter';
import {validateFormattedTime} from '@octaved/users/src/Culture/TimeFormatter';
import {NIL} from '@octaved/utilities';
import {
  validateArray,
  validateFunction,
  validateObject,
  validEmailRegExp,
  validHttpUrlRegExp,
} from '@octaved/validation';
import dayjs from 'dayjs';
import {Notification} from './Notifications';
import {isDateTimeStr} from '@octaved/typescript/src';

export type RuleResult = Notification[];

export interface Rule {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  (...args: any[]): RuleResult;
}

export type RulesList = Array<
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  | [Rule, any, any]
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  | [Rule, any, any, any]
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  | [Rule, any, any, any, any | undefined]
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  | [Rule, any, any, any, any, any | undefined]
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  | [Rule, any, any, any, any, any | undefined, any | undefined]
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  | [Rule, any, any, any, any, any | undefined, any | undefined, any | undefined]
  | false
>;

export type RuleEntry<T extends Rule> = [T, ...Parameters<T>];

export function validateRules(rules: RulesList): RuleResult {
  validateArray(rules);

  return rules.reduce<RuleResult>((acc, ruleDefinition) => {
    if (ruleDefinition) {
      validateArray(ruleDefinition);
      const [rule, ...args] = ruleDefinition;
      validateFunction(rule);
      const result = rule(...args);
      validateArray(result);
      acc.push(...result);
    }
    return acc;
  }, []);
}

export function notEmpty(
  value: number | string | null,
  translationToken: ComplexTranslatable,
  field = 'name',
): RuleResult {
  if (!value || !String(value).length) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function validateTimeFormat(value: string, translationToken: ComplexTranslatable, field = 'name'): RuleResult {
  if (!/^([01]\d|2[0-3]):[0-5]\d$/.test(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function notNull(value: string | null, translationToken: ComplexTranslatable, field = 'name'): RuleResult {
  if (value === null) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function notNIL(value: Uuid, translationToken: ComplexTranslatable, field = 'name'): RuleResult {
  if (value === NIL) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates whether a given value is not empty
 */
export function notNullOrUndefined(
  value: string | number,
  translationToken: ComplexTranslatable,
  field = 'name',
): RuleResult {
  if (value === null || value === undefined) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates whether a given name is not too long
 */
export function validateLength(
  value: string,
  translationToken: ComplexTranslatable,
  field = 'name',
  length = 191,
): RuleResult {
  if (value === null || value.length > length) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function notTruthy(value: unknown, translationToken: ComplexTranslatable, field = 'name'): RuleResult {
  if (value) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates 2 strings are matching, if not the passed errors are thrown
 */
export function validateEqual(value: string, matching: string, errors: RuleResult): RuleResult {
  validateArray(errors, validateObject);
  if (value !== matching) {
    return errors;
  }
  return [];
}

/**
 * Validates 2 strings are different, if not the passed errors are thrown
 */
export function validateDifferent(
  valueA: string,
  valueB: string,
  translationToken: ComplexTranslatable,
  fieldA: string,
  fieldB: string,
): RuleResult {
  if (valueA === valueB) {
    return [
      {
        field: fieldA,
        message: translationToken,
      },
      {
        field: fieldB,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * validates an email address
 */
export function validateEmailAddress(value: string, translationToken: ComplexTranslatable, field = 'name'): RuleResult {
  //RFC2822 email validation
  return validateMatchRegex(value, validEmailRegExp, translationToken, field);
}

/**
 * validates an http(s) url
 */
export function validateHttpUrl(value: string, translationToken: ComplexTranslatable, field: string): RuleResult {
  return validateMatchRegex(value, validHttpUrlRegExp, translationToken, field);
}

/**
 * Validates a non empty string does match a regex
 */
export function validateMatchRegex(
  value: string,
  regex: RegExp,
  translationToken: ComplexTranslatable,
  field = 'name',
): RuleResult {
  if (value && !regex.test(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates a non empty string does not match a regex
 */
export function validateNotMatchRegex(
  value: string,
  regex: RegExp,
  translationToken: ComplexTranslatable,
  field = 'name',
): RuleResult {
  if (value && regex.test(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates a property is not yet in an object
 */
export function validateNotInProps(
  name: string,
  object: Record<string, unknown>,
  translationToken: ComplexTranslatable,
  field = 'name',
): RuleResult {
  if (object && object.hasOwnProperty(name)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates a number is not less than minValue
 */
export function validateMinimum(
  value: number,
  translationToken: ComplexTranslatable,
  field: string,
  minValue = 0,
  exclusive = false,
): RuleResult {
  if (exclusive ? value <= minValue : value < minValue) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates a number is not less than minValue
 */
export function validateMaximum(
  value: number,
  translationToken: ComplexTranslatable,
  field: string,
  maxValue: number,
): RuleResult {
  if (value > maxValue) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function validateMinMax(
  value: number,
  translationToken: ComplexTranslatable,
  field: string,
  minValue: number,
  maxValue: number,
): RuleResult {
  if (value < minValue || value > maxValue) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

function matchesPrecision(value: number, precision: number): boolean {
  return value === parseFloat(value.toFixed(precision));
}

export interface ValidateDecimalOptions {
  factorForMessageValues?: number;
  maxValue?: number;
  maxValueExclusive?: boolean;
  minValue?: number;
  minValueExclusive?: boolean;
  precision?: number;
}

export function validateDecimal(
  value: number,
  translationToken: ComplexTranslatable,
  field: string,
  {
    factorForMessageValues = 1,
    precision = 2,
    minValue = 0,
    minValueExclusive = false,
    maxValue = 1000000,
    maxValueExclusive = true,
  }: ValidateDecimalOptions = {},
): RuleResult {
  if (
    (minValueExclusive && value <= minValue) ||
    (!minValueExclusive && value < minValue) ||
    (maxValueExclusive && value >= maxValue) ||
    (!maxValueExclusive && value > maxValue) ||
    !matchesPrecision(value, precision)
  ) {
    return [
      {
        field,
        message:
          typeof translationToken === 'string'
            ? {
                i18nKey: translationToken,
                values: {
                  precision,
                  exclusiveMax: maxValueExclusive ? maxValue * factorForMessageValues : '',
                  exclusiveMin: minValueExclusive ? minValue * factorForMessageValues : '',
                  inclusiveMax: maxValueExclusive ? '' : maxValue * factorForMessageValues,
                  inclusiveMin: minValueExclusive ? '' : minValue * factorForMessageValues,
                },
              }
            : translationToken,
      },
    ];
  }
  return [];
}

export function validateDecimalIfValidStringFormat(
  value: string,
  translationToken: ComplexTranslatable,
  field: string,
  options?: ValidateDecimalOptions,
): RuleResult {
  const number = unformat(value);
  if (number !== null) {
    return validateDecimal(number, translationToken, field, options);
  }
  return [];
}

const integerStringRegExp = /^-?\d+$/;

/**
 * Validates the format of an integer string
 */
export function validateIntegerStringFormat(
  value: string,
  translationToken: ComplexTranslatable,
  field: string,
): RuleResult {
  if (!integerStringRegExp.test(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

const positiveIntegerStringRegExp = /^\d+$/;

/**
 * Validates the format of an integer string
 */
export function validatePositiveIntegerStringFormat(
  value: string,
  translationToken: ComplexTranslatable,
  field: string,
): RuleResult {
  if (!positiveIntegerStringRegExp.test(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

/**
 * Validates the format a number string
 */
export function validateNumberStringFormat(
  value: string,
  translationToken: ComplexTranslatable,
  field: string,
): RuleResult {
  if (!validateLocaleFormat(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function validateNumber(value: number, translationToken: ComplexTranslatable, field: string): RuleResult {
  if (isNaN(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function validateMultipleOf(
  value: string,
  multiple: number,
  translationToken: ComplexTranslatable,
  field: string,
): RuleResult {
  const number = unformat(value);
  if (number !== null) {
    const divided = number / multiple;
    if (Math.floor(divided) !== divided) {
      return [{field, message: translationToken}];
    }
  }
  return [];
}

export function validateDateTime(
  value: string,
  translationToken: ComplexTranslatable,
  emptyTranslationToken: ComplexTranslatable,
  field: string,
): RuleResult {
  const emptyValidation = notEmpty(value, emptyTranslationToken, field);
  if (emptyValidation.length) {
    return emptyValidation;
  }
  if (!isDateTimeStr(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function validateTime(
  value: string,
  translationToken: ComplexTranslatable,
  emptyTranslationToken: ComplexTranslatable,
  field: string,
): RuleResult {
  const emptyValidation = notEmpty(value, emptyTranslationToken, field);
  if (emptyValidation.length) {
    return emptyValidation;
  }
  if (!validateFormattedTime(value)) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function validateDate(
  value: string,
  translationToken: ComplexTranslatable,
  emptyTranslationToken: ComplexTranslatable,
  field: string,
  dateFormat: string,
): RuleResult {
  const emptyValidation = notEmpty(value, emptyTranslationToken, field);
  if (emptyValidation.length) {
    return emptyValidation;
  }
  if (dayjs(value, dateFormat).format(dateFormat) !== value) {
    return [
      {
        field,
        message: translationToken,
      },
    ];
  }
  return [];
}

export function createTextInputVariableRules(
  value: string,
  emptyToken: ComplexTranslatable,
  tooLongToken: ComplexTranslatable,
  field = 'name',
  length = 191,
): RulesList {
  return [
    [notEmpty, value, emptyToken, field],
    [validateLength, value, tooLongToken, field, length],
  ];
}

export function validatePassword(
  value: string,
  notLongEnoughToken: string,
  missingLettersToken: string,
  field: string,
  minLength: number,
  extendedChecksEnabled: boolean,
): RuleResult {
  const rules: RuleResult = [];
  if (value === null || value.length < minLength) {
    rules.push({
      field,
      message: notLongEnoughToken,
    });
  }
  if (extendedChecksEnabled) {
    rules.push(...validateMatchRegex(value, /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])^/, missingLettersToken, field));
  }
  return rules;
}
