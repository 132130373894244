import {Uuid} from '@octaved/typescript/src/lib';
import {GroupedMilestoneResult} from '../../../Selectors/MilestoneSelectors';
import {Bar, BarProps} from '../../Bars/Bar';
import {PlanningBarProps} from '../../Bars/PlanningDateBar';
import {CalendarContext} from '../../Calendar/Context/CalendarContext';
import {Disposable} from '../../Disposable';
import {BarRow, BarRowProps} from './BarRow';

export interface BarFactoryProps<CTX extends CalendarContext = CalendarContext> {
  ctx: CTX;
}

export type CreateRowComponentProps = Omit<BarRowProps, 'height' | 'yOffset'>;

export abstract class BarFactory<CTX extends CalendarContext = CalendarContext> extends Disposable {
  protected readonly ctx: CTX;

  constructor({ctx}: BarFactoryProps<CTX>) {
    super();
    this.ctx = ctx;
  }

  init(): void {
    //impl in sub classes
  }

  abstract createRowComponent(props: CreateRowComponentProps): BarRow;
  abstract createMaterialResourceBar(barProps: PlanningBarProps): Bar | null;
  abstract createTaskBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar | null;
  abstract createWorkPackageBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar | null;
  abstract createSubWorkPackageBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar | null;
  abstract createSprintBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar | null;
  abstract createGroupBar(barProps: BarProps): Bar | null;
  abstract createProjectBar(barProps: BarProps): Bar | null;
  abstract createGroupedWorkPackageBar(barProps: BarProps): Bar | null;
  abstract createGroupedSubWorkPackageBar(barProps: BarProps): Bar | null;
  abstract createTaskSectionBar(barProps: BarProps): Bar | null;
  abstract createMaturityBar(barProps: BarProps, maturity: GroupedMilestoneResult): Bar | null;
  abstract createPlaceholder(barProps: BarProps): Bar;
}
