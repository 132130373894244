import {Uuid} from '@octaved/typescript/src/lib';
import classNames from 'classnames';
import {ReactElement} from 'react';
import {useNodeResponsibleFullUnits} from '../../../../../../Modules/Hooks/Nodes';
import CellPlaceholder from '../Components/CellPlaceholder';
import ResponsibleUnits from './ResponsibleUnits';

interface Props {
  readonly?: boolean;
  taskId: Uuid;
}

export default function SelectedContent({readonly, taskId}: Props): ReactElement {
  const responsibleUnits = useNodeResponsibleFullUnits(taskId);

  return (
    <div className={classNames('assignments', {readonly})}>
      {responsibleUnits.length > 0 && <ResponsibleUnits responsibleUnits={responsibleUnits} />}
      {responsibleUnits.length === 0 && <CellPlaceholder placeholder={'taskList:assignments.noAssignment'} />}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .assignments {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .readonly {
          cursor: default;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
