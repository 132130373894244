import {BarRow, BarRowProps} from '../../../Canvas/Gantt/Bars/BarRow';

interface GanttComparisonGroupedBarRowProps extends BarRowProps {
  subRows: BarRow[];
}

export class GanttComparisonGroupedRowBar extends BarRow {
  #subRows: BarRow[];

  constructor(props: GanttComparisonGroupedBarRowProps) {
    super(props);
    this.#subRows = props.subRows;
  }

  init(): void {
    super.init();
    for (const row of this.#subRows) {
      this.root.add(row.root);
      row.init();
      this.disposables.push(() => row.dispose());
    }
  }
}
