import {IconButton, IconColor, Tooltip} from '@octaved/ui';
import {Check, MoreHorizontal} from 'lucide-react';
import {ReactElement} from 'react';
import {taskListVisibleColumnsSetSelector} from '../../../Modules/Selectors/Ui/TaskListSelectors';
import {FlowState} from '../../../Modules/State';
import {TaskListColumn, toggleVisibleColumn} from '../../../Modules/Ui/TaskList';
import DropdownPopup from '../../Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Form/Dropdown/DropdownPopup/Types';

const createVisibleColumnColorSelector =
  (col: TaskListColumn) =>
  (s: FlowState): IconColor =>
    taskListVisibleColumnsSetSelector(s).has(col) ? 'green' : 'white';

function getColumns(personalTasksOnly: boolean): ReadonlyArray<DropdownPopupItemOptions> {
  const columns: DropdownPopupItemOptions[] = [
    {
      token: 'taskList:tableMenu.displayedColumns.header',
      type: DropdownPopupItemType.header,
    },
    {
      icon: <Check />,
      iconColor: createVisibleColumnColorSelector('effort'),
      iconSize: 'small',
      onMouseDown: () => toggleVisibleColumn('effort'),
      token: 'taskList:tableMenu.displayedColumns.effort',
      type: DropdownPopupItemType.item,
    },
    {
      icon: <Check />,
      iconColor: createVisibleColumnColorSelector('planing'),
      iconSize: 'small',
      onMouseDown: () => toggleVisibleColumn('planing'),
      token: 'taskList:tableMenu.displayedColumns.planning',
      type: DropdownPopupItemType.item,
    },
  ];
  if (!personalTasksOnly) {
    columns.push({
      icon: <Check />,
      iconColor: createVisibleColumnColorSelector('assignments'),
      iconSize: 'small',
      onMouseDown: () => toggleVisibleColumn('assignments'),
      token: 'taskList:tableMenu.displayedColumns.responsibility',
      type: DropdownPopupItemType.item,
    });
  }
  columns.push({
    icon: <Check />,
    iconColor: createVisibleColumnColorSelector('labels'),
    iconSize: 'small',
    onMouseDown: () => toggleVisibleColumn('labels'),
    token: 'taskList:tableMenu.displayedColumns.labels',
    type: DropdownPopupItemType.item,
  });
  return columns;
}

export const columns = getColumns(false);
export const personalColumns = getColumns(true);

interface Props {
  options: DropdownPopupItemOptions[];
}

export default function TableOptions({options}: Props): ReactElement {
  return (
    <DropdownPopup
      items={options}
      position={'bottom right'}
      closeOnSelect
      scrollOverflow
      maxHeight={'calc(100vh - 100px)'}
    >
      <Tooltip toolTipTranslation={'tooltips:components.pageHeader.buttonEllispsis'} position={'bottom center'}>
        <IconButton variant={'ghost'} icon={<MoreHorizontal />} size={'sm'} />
      </Tooltip>
    </DropdownPopup>
  );
}
