import {PropsWithChildren, ReactElement, useMemo} from 'react';
import FlowOutlet from '../../Components/FlowOutlet';
import {
  getSystemSettingsRowById,
  SystemSettingsPermissionRole,
} from '../../Modules/Selectors/SystemSettingPermissionSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IPermissionInspectorContext extends BaseInspectorContext {
  selectedEntity: SystemSettingsPermissionRole;
  selectedType: 'permission';
}

function isPermissionInspectorContext(context: InspectorContext | null): context is IPermissionInspectorContext {
  return context?.selectedType === 'permission';
}

export function usePermissionInspectorContext(): IPermissionInspectorContext {
  const context = useInspectorContext();
  if (!isPermissionInspectorContext(context)) {
    throw new Error('is not permission inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IPermissionInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = getSystemSettingsRowById(baseParams?.selectedId || '');

  return useMemo<IPermissionInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'permission',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function PermissionInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
