import {hasFeature} from '@octaved/env/src/Features';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {useLoadNode} from '../../Modules/Hooks/Nodes';
import {useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {closeDialogRoute} from '../../Routing/Routes/DialogRoutes';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';
import NewSubWorkPackageForm from './NewSubWorkPackageForm';

export default function NewSubWorkPackage({
  relativeNodeId,
  close,
  before,
}: {
  relativeNodeId?: string;
  close?: () => void;
  before?: boolean;
}): ReactElement | null {
  const newSwpRelativeNodeId = useParams<'newSwpRelativeNodeId'>().newSwpRelativeNodeId || relativeNodeId;
  const {hasLoadedOnce} = useLoadNode(newSwpRelativeNodeId);
  const {workPackage, subWorkPackage} = useNodeAncestry(newSwpRelativeNodeId, true);
  const canCreate = useIsGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', newSwpRelativeNodeId);
  const navigate = useNavigate();
  const relativeNode = subWorkPackage || workPackage;

  useEffect(() => {
    if ((hasLoadedOnce && !relativeNode) || !canCreate || !hasFeature('subWorkPackages')) {
      navigate(closeDialogRoute);
    }
  }, [canCreate, navigate, hasLoadedOnce, relativeNode]);

  if (!relativeNode || !workPackage) {
    return null;
  }

  return (
    <DialogFrame size={'mini'} noOwnForm onClose={close}>
      <DialogTitle onClose={close}>
        <DialogAndDrawerHeader header={'dialogs:createSubWorkpackage.title'} />
      </DialogTitle>
      <DialogContent>
        <NewSubWorkPackageForm workPackage={workPackage} relativeNode={relativeNode} before={before} />
      </DialogContent>
    </DialogFrame>
  );
}
