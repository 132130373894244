import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getTimeEffortPriceSumsPerBillingType} from '@octaved/flow-api';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {createTimestampReducer, isLoaded, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  StoreTimeEffortPriceSumsPerBillingType,
  TimeEffortPriceSumPerBillingType,
  TimeEffortPriceSumsPerBillingType,
} from '../../EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';
import {getEffectiveMaxEffort} from '../../WorkPackage/MaxEffort';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_FAILURE,
  FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_REQUEST,
  FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_SUCCESS,
} from '../ActionTypes';
import {useCombinedNodeSearch} from '../Hooks/NodeSearch';
import {NodeAncestry, useNodeAncestry} from '../Selectors/NodeTreeSelectors';
import {
  timeLimitSumsPerBillingTypeSelector,
  timeLimitSumsPerBillingTypeStatesSelector,
} from '../Selectors/Statistics/TimeEffortPriceSumsPerBillingTypeSelectors';
import {FlowState} from '../State';

interface LoadAction {
  key: string;
  response: TimeEffortPriceSumsPerBillingType;
}

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_SUCCESS,
  (
    state: StoreTimeEffortPriceSumsPerBillingType,
    {key, response}: LoadAction,
  ): StoreTimeEffortPriceSumsPerBillingType => {
    return {...state, [key]: response};
  },
);
export const timeEffortPriceSumsPerBillingTypeReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_SUCCESS, createTimestampReducer('key', LOADED));
addMultiToReducerMap(
  stateReducerMap,
  [
    FLOW_CHANGE_PID_SUCCESS,
    'flow.ProjectCustomerChangedEvent', //TODO: not sure if needed
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.WorkPackageCreatedEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  () => ({}),
);
export const timeEffortPriceSumsPerBillingTypeStateReducer = ReduceFromMap(stateReducerMap);

export function loadtimeEffortPriceSumsPerBillingType(
  key: string,
  workPackageIds: ReadonlyArray<Uuid>,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = timeLimitSumsPerBillingTypeStatesSelector(getState())[key];
    if (!state || isOutdated(state)) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: getTimeEffortPriceSumsPerBillingType,
          method: 'post',
          options: {data: {workPackageIds}},
          types: {
            failureType: FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_FAILURE,
            requestType: FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_REQUEST,
            successType: FLOW_LOAD_TIME_EFFORT_PRICE_SUMS_PER_BT_SUCCESS,
          },
        },
      });
    }
  };
}

const empty: TimeEffortPriceSumsPerBillingType = {};

interface TimeEffortPriceSumsPerBillingTypeResult {
  hasLoadedOnce: boolean;
  isLoading: boolean;
  key: string;
  sums: TimeEffortPriceSumsPerBillingType;
}

export function useTimeEffortPriceSumsPerBillingType(
  workPackageIds: ReadonlyArray<Uuid>,
): TimeEffortPriceSumsPerBillingTypeResult {
  const key = useMemo(() => [...workPackageIds].sort().join('-'), [workPackageIds]);
  const dispatch = useDispatch();
  const state = useSelector(timeLimitSumsPerBillingTypeStatesSelector)[key];
  const sums = useSelector(timeLimitSumsPerBillingTypeSelector)[key];
  useEffect(() => {
    // noinspection BadExpressionStatementJS
    void state; //reload dep
    if (workPackageIds.length) {
      dispatch(loadtimeEffortPriceSumsPerBillingType(key, workPackageIds));
    }
  }, [dispatch, key, state, workPackageIds]);
  const isLoading = workPackageIds.length > 0 && (!state || !isLoaded(state));
  return {
    isLoading,
    key,
    hasLoadedOnce: useLoadedValue(isLoading, !isLoading, key),
    sums: sums || empty,
  };
}

export function useTimeEffortPriceSumsPerBillingTypeInSubtree(
  rootNodeId: Uuid,
): TimeEffortPriceSumsPerBillingTypeResult {
  const {isLoading: isSearchLoading, nodeIds} = useCombinedNodeSearch(
    {
      and: [withDescendants(rootNodeId, true), ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE]],
    },
    true,
  );
  const result = useTimeEffortPriceSumsPerBillingType(nodeIds);
  const isLoading = isSearchLoading || result.isLoading;
  return {
    isLoading,
    hasLoadedOnce: useLoadedValue(isLoading, !isLoading, result.key),
    key: result.key,
    sums: result.sums,
  };
}

export function createSumForWpOrSwp(ancestry: NodeAncestry | undefined): TimeEffortPriceSumPerBillingType {
  const workPackage = ancestry?.workPackage;
  const subWorkPackage = ancestry?.subWorkPackage;
  const wpOrSwp = subWorkPackage || workPackage;
  const maxEffort = subWorkPackage ? subWorkPackage.maxEffort : workPackage ? getEffectiveMaxEffort(workPackage) : null;
  const billedHours = (wpOrSwp?.trackedMinutes.billed || 0) / 60;
  return {
    billableHours: billedHours,
    billableHoursWithMaxEffort: maxEffort ? billedHours : 0,
    billableHoursWithoutMaxEffort: maxEffort ? 0 : billedHours,
    count: wpOrSwp ? 1 : 0,
    countHasMaxEffort: maxEffort ? 1 : 0,
    fixedPrice: typeof workPackage?.fixedPrice === 'undefined' ? undefined : workPackage?.fixedPrice || 0,
    maxEffortHours: maxEffort || 0,
    maxEffortPrice: -1, //Unused - implement if needed
    minEffortHours: subWorkPackage ? 0 : workPackage?.effortFrom || 0,
  };
}

export function useTimeEffortPriceSumPerBillingTypeForWorkPackage(
  nodeId: Uuid | null | undefined,
): TimeEffortPriceSumPerBillingType {
  const ancestry = useNodeAncestry(nodeId, true);
  return useMemo(() => createSumForWpOrSwp(ancestry), [ancestry]);
}
