import {FlexItem} from '@octaved/ui';
import {FormikProps} from 'formik/dist/types';
import {get} from 'lodash';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Radio, StrictRadioProps} from 'semantic-ui-react';

interface Props<FormikValues> extends Omit<StrictRadioProps, 'name'> {
  label: string;
  formik: FormikProps<FormikValues>;
  name: keyof FormikValues;
}

export default function RadioInput<FormikValues>({
  name,
  label,
  formik,
  value,
  ...radioProps
}: Props<FormikValues>): ReactElement {
  const formValue = get(formik.values, name);
  const {t} = useTranslation();

  return (
    <FlexItem width={'100%'}>
      <Radio
        label={t(label)}
        value={value}
        checked={value === formValue}
        name={name.toString()}
        onClick={() => formik.setFieldValue(name.toString(), value)}
        {...radioProps}
      />
    </FlexItem>
  );
}
