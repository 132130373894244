import {TaskPlanningStatus} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {Tooltip} from '@octaved/ui';
import {PropsWithChildren, ReactElement} from 'react';
import PlanningCellTooltipContent from './PlanningCellTooltipContent';

interface Props {
  planningStatus: TaskPlanningStatus;
}

export default function PlanningCellTooltip({children, planningStatus}: PropsWithChildren<Props>): ReactElement {
  const showTooltip =
    planningStatus === TaskPlanningStatus.overdue || planningStatus === TaskPlanningStatus.outSideOfWorkpackagePlanning;
  return (
    <Tooltip
      toolTipTranslation={''}
      disabled={!showTooltip}
      position={'top left'}
      flowing
      wide={'very'}
      content={<PlanningCellTooltipContent planningStatus={planningStatus} />}
    >
      {children}
    </Tooltip>
  );
}
