import {PropsWithChildren, ReactElement, ReactNode, useCallback, useState} from 'react';
import FullScreenDrawer from './FullScreenDrawer';

interface Props {
  aboveDialog?: boolean;
  trigger: ReactNode;
}

export default function FullScreenDrawerWithTrigger({
  aboveDialog,
  trigger,
  children,
}: PropsWithChildren<Props>): ReactElement {
  const [visible, setVisible] = useState(false);
  const close = useCallback(() => setVisible(false), []);

  return (
    <FullScreenDrawer
      trigger={
        <div onClick={() => setVisible(true)} className={'w-fit'}>
          {trigger}
        </div>
      }
      aboveDialog={aboveDialog}
      close={close}
      visible={visible}
    >
      {children}
    </FullScreenDrawer>
  );
}
