import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {createStoreSubscription} from '../../../../Canvas/StoreSubscription';
import {calculateMinMaxPlanningDatesForSelectedBaselineNodeSelector} from '../../../../Selectors/PlanningBaselineHistorySelectors';
import {MinMaxPlanningDatesResult} from '../../../../Selectors/PlanningDateSelectors';
import {GanttComparisonPlanningTextCell} from './GanttComparisonTextCell';

abstract class GanttComparisonPlanningCell extends GanttComparisonPlanningTextCell {
  protected minMaxDate: MinMaxPlanningDatesResult | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (state) => {
          return calculateMinMaxPlanningDatesForSelectedBaselineNodeSelector(state)(this.baselineId, this.node.id);
        },
        (minMaxDate) => {
          this.minMaxDate = minMaxDate;
          this.render();
        },
      ),
    );
  }
}

export class GanttComparisonPlannedStartCell extends GanttComparisonPlanningCell {
  protected getText(): string {
    if (this.minMaxDate) {
      if (this.minMaxDate && this.minMaxDate.plannedStart) {
        return fromIsoDateTimeFormat(this.minMaxDate.plannedStart).format('dd. DD.MM.');
      }
    }
    return '';
  }
}

export class GanttComparisonPlannedEndCell extends GanttComparisonPlanningCell {
  protected getText(): string {
    if (this.minMaxDate) {
      if (this.minMaxDate && this.minMaxDate.plannedEnd) {
        return fromIsoDateTimeFormat(this.minMaxDate.plannedEnd).format('dd. DD.MM.');
      }
    }
    return '';
  }
}
