import {NodeTimeControlMode} from '@octaved/env/src/dbalEnumTypes';
import {DateStr} from '@octaved/typescript';
import {
  fromIsoFormat,
  isoToUnix,
  timestampToFormatString,
  toIsoFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {TFunction} from 'i18next';
import {NodeType} from '../EntityInterfaces/Nodes';
import {NodeTimeControl, TimeControlledEntity} from '../EntityInterfaces/TimeControlledEntity';
import {isTimeControlledNode} from '../Node/NodeIdentifiers';

export function createNodeTimeControl(val: NodeTimeControlMode, prev?: NodeTimeControl): NodeTimeControl {
  let from: DateStr;
  let to: DateStr | null = null;
  switch (val) {
    case NodeTimeControlMode.monthly:
      from = toIsoFormat(dayjs().startOf('month')); //set to first day of current month
      break;
    case NodeTimeControlMode.quarterly:
      from = toIsoFormat(dayjs().startOf('quarter')); //set to first day of current quarter
      break;
    case NodeTimeControlMode.yearly:
      from = toIsoFormat(dayjs().startOf('year')); //set to first day of current year
      break;
    case NodeTimeControlMode.custom:
      from = toIsoFormat(dayjs());
      to = toIsoFormat(dayjs().add(7, 'day'));
      break;
    default:
      throw new Error('Invalid mode');
  }
  return {mode: val, from, to, showAfterName: prev?.showAfterName ?? true} as NodeTimeControl;
}

export function isTimeControlActive({timeControl}: TimeControlledEntity): boolean {
  return !!timeControl;
}

export function formatTimeControl(
  t: TFunction,
  {timeControl}: TimeControlledEntity,
  dateFormat: string,
  short: boolean,
): string {
  if (!timeControl) {
    return '';
  }
  let result = '';
  switch (timeControl.mode) {
    case NodeTimeControlMode.monthly:
      result = fromIsoFormat(timeControl.from).format(short ? 'MMM YYYY' : 'MMMM YYYY');
      break;
    case NodeTimeControlMode.quarterly: {
      const date = fromIsoFormat(timeControl.from);
      result = short
        ? `Q${date.quarter()} ${date.format('YYYY')}`
        : `${t('general:quarter', {quarter: date.quarter()})} ${date.format('YYYY')}`;
      break;
    }
    case NodeTimeControlMode.yearly:
      result = fromIsoFormat(timeControl.from).format('YYYY');
      break;
    case NodeTimeControlMode.custom: {
      const startTs = isoToUnix(timeControl.from);
      const endTs = isoToUnix(timeControl.to);
      const start = timestampToFormatString(startTs, dateFormat);
      const end = timestampToFormatString(endTs, dateFormat);
      result = `${start} - ${end}`;
      break;
    }
  }
  return result;
}

export function formatNameWithTimeControl(t: TFunction, dateFormat: string, node: NodeType): string {
  const timeControlString =
    isTimeControlledNode(node) && node.timeControl?.showAfterName ? formatTimeControl(t, node, dateFormat, true) : '';
  return timeControlString ? `${node.name} (${timeControlString})` : node.name;
}

export function isEqualTimeControl(
  {timeControl: a}: TimeControlledEntity,
  {timeControl: b}: TimeControlledEntity,
): boolean {
  return b?.mode === a?.mode && b?.from === a?.from && b?.to === a?.to;
}

export function getStartEnd({timeControl}: TimeControlledEntity): [dayjs.Dayjs | null, dayjs.Dayjs | null] {
  if (!timeControl) {
    return [null, null];
  }
  const start = fromIsoFormat(timeControl.from);
  let end: dayjs.Dayjs | null = null;
  switch (timeControl.mode) {
    case NodeTimeControlMode.yearly:
      end = start && start.endOf('year');
      break;
    case NodeTimeControlMode.quarterly:
      end = start && start.endOf('quarter');
      break;
    case NodeTimeControlMode.monthly:
      end = start && start.endOf('month');
      break;
    case NodeTimeControlMode.custom:
      end = fromIsoFormat(timeControl.to);
      break;
  }
  return [start, end];
}

export function isWithinTimeControl(parent: TimeControlledEntity, child: TimeControlledEntity): boolean {
  const [parentStart, parentEnd] = getStartEnd(parent);
  const [childStart, childEnd] = getStartEnd(child);
  return (
    !parentStart ||
    !parentEnd ||
    ((!childStart || childStart.isBetween(parentStart, parentEnd, 'day', '[]')) &&
      (!childEnd || childEnd.isBetween(parentStart, parentEnd, 'day', '[]')))
  );
}

export function isOverlappingTimeControl(
  timeControl: TimeControlledEntity,
  parentTimeControl: TimeControlledEntity,
): boolean {
  const [parentStart, parentEnd] = getStartEnd(parentTimeControl);
  const [childStart, childEnd] = getStartEnd(timeControl);
  if (parentStart && parentEnd && childStart && childEnd) {
    return childStart.isBefore(parentStart) || childEnd.isAfter(parentEnd);
  }
  return false;
}

export function shiftTimeControl<E extends TimeControlledEntity>(entity: Readonly<E>): E {
  const {timeControl} = entity;
  if (!timeControl) {
    return entity;
  }
  const oldFrom = fromIsoFormat(timeControl.from);
  let newFrom = '' as DateStr;
  switch (timeControl.mode) {
    case NodeTimeControlMode.yearly:
      newFrom = toIsoFormat(oldFrom.add(1, 'year').startOf('year'));
      break;
    case NodeTimeControlMode.quarterly:
      newFrom = toIsoFormat(oldFrom.add(1, 'quarter').startOf('quarter'));
      break;
    case NodeTimeControlMode.monthly:
      newFrom = toIsoFormat(oldFrom.add(1, 'month').startOf('month'));
      break;
    //We don't shift custom time controls
  }
  return newFrom ? {...entity, timeControl: {...timeControl, from: newFrom}} : entity;
}

export function isTimeControlInThePast({timeControl}: Readonly<TimeControlledEntity>): boolean {
  if (!timeControl) {
    return false;
  }
  let isInPast = false;
  const today = dayjs();
  const from = timeControl.from;
  switch (timeControl.mode) {
    case NodeTimeControlMode.yearly:
      isInPast = from < toIsoFormat(today.startOf('year'));
      break;
    case NodeTimeControlMode.quarterly:
      isInPast = from < toIsoFormat(today.startOf('quarter'));
      break;
    case NodeTimeControlMode.monthly:
      isInPast = from < toIsoFormat(today.startOf('month'));
      break;
    case NodeTimeControlMode.custom:
      isInPast = (timeControl.to || '') < toIsoFormat(today);
      break;
  }
  return isInPast;
}
