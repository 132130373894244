import classNames from 'classnames';
import {PropsWithChildren, ReactElement} from 'react';

export interface StandardPageFrameContentProps {
  noBackground?: boolean;
}

export default function StandardPageFrameContent({
  children,
  noBackground,
}: PropsWithChildren<StandardPageFrameContentProps>): ReactElement {
  return (
    <div className={classNames({noBackground})}>
      {children}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        div {
          height: 100%;
          width: 100%;
          background: #f9fafa;
        }

        .noBackground {
          background: none;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}

StandardPageFrameContent.displayName = 'StandardPageFrameContent';
