import * as routes from '@octaved/flow-api';
import {post} from '@octaved/network/src/Network';
import {List, Uuid} from '@octaved/typescript/src/lib';
import {WebhookCallResult} from '@octaved/webservice/src/Modules/Webhooks';

export function transmitOffers(pidId: Uuid, includeTasks: boolean): Promise<List<WebhookCallResult>> {
  return post<List<WebhookCallResult>>(routes.transmitOffers, {
    data: {
      includeTasks,
    },
    urlParams: {pidId},
  });
}
