import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import ClearIdsFromState from '@octaved/store/src/Reducer/ClearIdsFromState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import once from 'lodash/once';
import * as routes from '../../config/routes';
import {OrgUserDeletedEvent} from '../EntityInterfaces/Events';
import {GroupUserEntities} from '../EntityInterfaces/GroupUserEntity';
import {groupUsers} from '../Schema';
import {groupUsersSelector} from '../Selectors/GroupUserSelectors';
import {UserState} from '../UserState';
import {
  GROUP_USERS_CHANGED,
  GROUP_USERS_CLEAR,
  LOAD_GROUP_USERS_FAILURE,
  LOAD_GROUP_USERS_REQUEST,
  LOAD_GROUP_USERS_SUCCESS,
} from './ActionTypes';

interface GroupUsersChangedAction {
  type: typeof GROUP_USERS_CHANGED;
  groupIds: Uuid[];
}

//#region reducer
const reducers = createReducerCollection<GroupUserEntities>({});

reducers.add(GROUP_USERS_CHANGED, ClearIdsFromState('groupIds'));
reducers.add(GROUP_USERS_CLEAR, () => ({}));
reducers.add('flow.LdapSynchronizedEvent', () => ({}));
reducers.add<OrgUserDeletedEvent>('OrgUserDeletedEvent', (state, action) => {
  const newState = {...state};
  let changed = false;
  Object.entries(newState).forEach(([userGroupId, orgUserIds]) => {
    let idsChanged = false;
    const newOrgUserIds = orgUserIds.filter((id) => {
      if (action.orgUserId === id) {
        idsChanged = false;
        changed = true;
        return false;
      }
      return true;
    });
    if (idsChanged) {
      newState[userGroupId] = newOrgUserIds;
    }
  });
  return changed ? newState : state;
});

export const entityReducer = reducers.reducer;

//#endregion

const onGroupUsersChanged = ({groupIds}: {groupIds: Uuid[]}): GroupUsersChangedAction => ({
  groupIds,
  type: GROUP_USERS_CHANGED,
});
const onUserGroupChanged = ({userGroupId}: {userGroupId: Uuid}): GroupUsersChangedAction => ({
  groupIds: [userGroupId],
  type: GROUP_USERS_CHANGED,
});

const init = once(async () => {
  subscribe('UserGroupMembersBulkPatched', onUserGroupChanged);
  subscribe('UserCreatedEvent', onGroupUsersChanged);
  subscribe('OrgUserGroupsBulkPatched', () => ({type: GROUP_USERS_CLEAR}));
});

function loadGroupUsersAction(groupIds: Uuid[]): CallAction {
  return {
    [CALL_API]: {
      _debounceSimultaneousCalls: true,
      endpoint: routes.loadGroupUsers,
      options: {
        urlParams: {
          groupIds,
        },
      },
      schema: [groupUsers],
      types: {
        failureType: LOAD_GROUP_USERS_FAILURE,
        requestType: LOAD_GROUP_USERS_REQUEST,
        successType: LOAD_GROUP_USERS_SUCCESS,
      },
    },
  };
}

/**
 * loads group users into the store if they had not been loaded yet
 *
 * @return {function.<Promise>}
 */
export function loadGroupUsers(groupIds: Uuid[]): ActionDispatcher<Promise<void>, UserState> {
  return (dispatch, getState) => {
    init();
    const currentGroupUsers = groupUsersSelector(getState());
    const groupUsersToLoad = groupIds.filter((groupId) => !currentGroupUsers.hasOwnProperty(groupId));

    if (groupUsersToLoad.length === 0) {
      return Promise.resolve();
    }

    return dispatch(loadGroupUsersAction(groupUsersToLoad));
  };
}
