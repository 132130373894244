import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {toChildren} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useCallback, useRef} from 'react';
import {Task} from '../../../EntityInterfaces/Task';
import {useLoadedNodes} from '../../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../../Modules/Hooks/NodeSearch';

export enum NewSortOrder {
  last,
}

export function useGetNewSortOrder(
  parentNodeId: Uuid,
  type: EnumFlowNodeType,
  previousSiblingNodeId: Uuid | undefined | NewSortOrder,
): (newEntityId: Uuid) => {
  sortOrder: number;
  sortedSiblingIds: Uuid[];
} {
  const {nodeIds: siblingIds} = useCombinedNodeSearch({
    and: [toChildren(parentNodeId), ['nodeType', type]],
  });
  const {nodes: siblings} = useLoadedNodes<Task>(siblingIds);
  const siblingsRef = useRef(siblings);
  siblingsRef.current = siblings;

  return useCallback(
    (newEntityId: Uuid) => {
      const sortedSiblingIds = siblingsRef.current
        .slice(0)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(({id}) => id);
      let newSortOrder: number;
      if (previousSiblingNodeId === NewSortOrder.last) {
        newSortOrder = sortedSiblingIds.length;
      } else {
        const previousSiblingIndex = sortedSiblingIds.findIndex((id) => id === previousSiblingNodeId);
        newSortOrder = previousSiblingIndex + 1;
      }
      const newSortedSiblingIds = sortedSiblingIds.slice(0);
      newSortedSiblingIds.splice(newSortOrder, 0, newEntityId);

      return {
        sortedSiblingIds: newSortedSiblingIds,
        sortOrder: newSortOrder,
      };
    },
    [previousSiblingNodeId],
  );
}
