import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import NodeName from '@octaved/flow/src/Components/Node/NodeName';
import StringPath from '@octaved/flow/src/Components/Node/StringPath';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import Menu from './Menu';

interface NodeListRowProps {
  node: PlanningRealizationNode;
  readonly: boolean;
  predecessorId: Uuid;
}

export default function NodeListRow({predecessorId, readonly, node}: NodeListRowProps): ReactElement {
  return (
    <div className={'row'}>
      <div className={'pid cell'}>
        <div className={'pidName'}>
          <NodeName nodeId={predecessorId} />
        </div>
        {node.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE && (
          <div className={'pidPath'}>
            <StringPath nodeId={predecessorId} excludeSelf />
          </div>
        )}
      </div>
      <div className={'pid cell'}>{!readonly && <Menu node={node} predecessorId={predecessorId} />}</div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .row {
          display: contents;
        }

        .row :global(.onHover) {
          opacity: 0;
          pointer-events: none;
        }

        .row:hover :global(.onHover) {
          opacity: 1;
          pointer-events: all;
        }

        .pid {
          justify-content: space-between;
          align-items: flex-start;
        }

        .pidPath {
          font-size: 12px;
          line-height: 14px;
          color: var(--color-grey-600);
        }

        .pidName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
