import {isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import NodeList from '@octaved/planning/src/Components/LogicalDependencies/List/NodeList';
import DependencyList from '@octaved/planning/src/Components/PlanningRealization/Dependency/List/DependencyList';
import SuccessorDependencyListRow from '@octaved/planning/src/Components/PlanningRealization/Dependency/List/SuccessorDependencyListRow';
import {PlanningRealizationNode} from '@octaved/planning/src/EntityInterfaces/PlanningRealizationNode';
import {getPlanningDependencySelector} from '@octaved/planning/src/Selectors/PlanningDependencySelectors';
import {boolFilter} from '@octaved/utilities';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Placeholder from '../../PlaceholderText';

export interface NodeSuccessorsProps {
  node: PlanningRealizationNode;
}

export default function NodeSuccessors({
  frame: Frame,
  node,
}: NodeSuccessorsProps & FramedSectionOrTileProps): ReactElement | null {
  const getPlanningDependency = useSelector(getPlanningDependencySelector);
  const getNode = useSelector(getNodeSelector);

  const nodeIsTask = isTask(node);
  const planningDependencies = boolFilter(
    node.planningSuccessors.map((id) => {
      const dependency = getPlanningDependency(id, node.id);
      const successor = getNode(dependency?.successor);
      if (!nodeIsTask && isTask(successor)) {
        return null;
      }

      return dependency;
    }),
  );
  const hasPlanningSuccessors = planningDependencies.length > 0;
  const hasLogicalSuccessors = node.planningLogicalSuccessors.length > 0;

  return (
    <Frame labelToken={'pages:planning.inspector.showSuccessors'}>
      {hasPlanningSuccessors && (
        <div>
          <DependencyList
            dependencies={planningDependencies}
            node={node}
            readonly
            row={SuccessorDependencyListRow}
            getKey={(dependency) => dependency.successor}
            headlineToken={nodeIsTask ? 'pages:projects.inspector.manage.planning.headlineSuccessorTask' : undefined}
          />
        </div>
      )}
      {hasLogicalSuccessors && (
        <div>
          <NodeList
            node={node}
            readonly
            dependencyList={node.planningLogicalSuccessors}
            headlineToken={'pages:projects.inspector.manage.planning.logicalDependency.successorHeadline'}
          />
        </div>
      )}
      {!hasPlanningSuccessors && !hasLogicalSuccessors && (
        <Placeholder text={'nodeDetails:field.successors.placeholder'} />
      )}
    </Frame>
  );
}
