import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn} from '@octaved/ui';
import cs from 'classnames';
import {ChevronRight} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {rootFoldersProjectFolderSelector} from '../../Modules/Selectors/RootFolderSelectors';
import {isProject, isProjectFolder} from '../../Node/NodeIdentifiers';
import CustomerNameByPid from '../Customer/CustomerName/CustomerNameByPid';
import {IconColors} from '../SvgIcon';
import NodeName from './NodeName';
import {useStringPathStartFromContext} from './StringPathStartFromContext';

interface Props {
  className?: string;
  dividerIconColor?: IconColors;
  excludeProject?: boolean;
  excludeProjectFolder?: boolean;
  excludeSelf?: boolean;
  highlightSelf?: boolean;
  includeCustomer?: boolean;
  includeRootFolder?: boolean;
  includeLastDivider?: boolean;
  inline?: boolean;
  moreLineHeight?: boolean;
  nodeId: Uuid | null;
  notSearchable?: boolean;
  onlyProjectFolder?: boolean;
  startFrom?: Uuid;
  topMargin?: boolean;
}

export default function StringPath({
  className,
  dividerIconColor = 'darkGrey',
  excludeProject = false,
  excludeProjectFolder = false,
  excludeSelf = false,
  highlightSelf,
  includeCustomer = false,
  includeRootFolder,
  includeLastDivider = false,
  inline,
  moreLineHeight,
  nodeId,
  notSearchable,
  onlyProjectFolder = false,
  startFrom,
  topMargin,
}: Props): ReactElement {
  const canReadCustomers = useIsGranted('FLOW_GLOBAL_CUSTOMERS_READ');
  const withCustomer = includeCustomer && canReadCustomers;
  const ancestors = useNodeAncestry(nodeId, !excludeSelf).ancestors;
  const startFromContext = useStringPathStartFromContext();

  const fullPath = useMemo(() => {
    let filtered = ancestors.toReversed();
    if (startFrom || startFromContext) {
      const fromId = startFrom || startFromContext!.nodeId;
      const index = filtered.findIndex(({id}) => id === fromId);
      if (index > -1) {
        const offset = startFromContext?.offset ?? 0;
        filtered = filtered.slice(index + offset);
      }
    }
    return filtered;
  }, [ancestors, startFrom, startFromContext]);

  const projectPath = useMemo(
    () => fullPath.filter((node) => !isProjectFolder(node) && (!excludeProject || !isProject(node))),
    [excludeProject, fullPath],
  );
  const rootProjectFolder = useSelector(rootFoldersProjectFolderSelector);

  const projectFolderPath = useMemo(
    () => fullPath.filter((node) => isProjectFolder(node) && (!!includeRootFolder || node.id !== rootProjectFolder)),
    [fullPath, includeRootFolder, rootProjectFolder],
  );

  return (
    <>
      <div className={cn('row wrap', {topMargin, inline, moreLineHeight}, className)}>
        {!excludeProjectFolder &&
          projectFolderPath.map(({id}, index) => (
            <span key={id} className={cs({highlight: highlightSelf && id === nodeId})}>
              <NodeName nodeId={id} notSearchable={notSearchable} />
              {(includeLastDivider || index !== projectFolderPath.length - 1) && (
                <i className={cs('dividerIcon', `color-${dividerIconColor}`)}>
                  <ChevronRight className={'inline'} />
                </i>
              )}
            </span>
          ))}

        {withCustomer && !onlyProjectFolder && (
          <>
            {!includeLastDivider && !excludeProjectFolder && projectFolderPath.length > 0 && (
              <i className={cs('dividerIcon', `color-${dividerIconColor}`)}>
                <ChevronRight className={'inline'} />
              </i>
            )}
            <span className={'italic'}>
              <CustomerNameByPid pidId={nodeId} searchable={!notSearchable} />
            </span>
            {projectPath.length > 0 && (
              <i className={cs('dividerIcon', `color-${dividerIconColor}`)}>
                <ChevronRight className={'inline'} />
              </i>
            )}
          </>
        )}
        {!onlyProjectFolder &&
          !withCustomer &&
          projectFolderPath.length > 0 &&
          projectPath.length > 0 &&
          !excludeProjectFolder && (
            <i className={cs('dividerIcon', `color-${dividerIconColor}`)}>
              <ChevronRight className={'inline'} />
            </i>
          )}

        {!onlyProjectFolder &&
          projectPath.map(({id}, index) => (
            <span key={id} className={cs({highlight: highlightSelf && id === nodeId})}>
              <NodeName nodeId={id} notSearchable={notSearchable} />
              {(includeLastDivider || index !== projectPath.length - 1) && (
                <i className={cs('dividerIcon', `color-${dividerIconColor}`)}>
                  <ChevronRight className={'inline'} />
                </i>
              )}
            </span>
          ))}
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        i :global(svg) {
          height: 1em;
          margin: -3px 0.15rem 0 0.15rem;
          vertical-align: middle;
          stroke: currentColor;
          width: 0.75rem;
        }

        .topMargin {
          margin-top: 10px;
        }

        .highlight {
          font-weight: bold;
        }

        .moreLineHeight {
          line-height: 16px;
        }

        .inline {
          display: inline;
        }
      `}</style>
    </>
  );
}
