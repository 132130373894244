import {DATE, FormatOptions, isISODateString} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import {ucfirst} from '@octaved/utilities';
import dayjs from 'dayjs';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const formatDateOptions = {
  noYearNumber: true,
  showDayShortNames: true,
};

type DateType = string | null | undefined;

export function useFormattedDateText(
  formatOptions: FormatOptions = formatDateOptions,
  dateFormat = 'DD.MM.',
): (date: DateType) => DateType {
  const formatDate = useFormatInWords({type: DATE});
  return useCallback(
    (date: DateType) => {
      if (!isISODateString(date || '')) {
        return date;
      }
      return ucfirst(formatDate(dayjs(date), undefined, formatOptions, {dateFormat}));
    },
    [dateFormat, formatDate, formatOptions],
  );
}

export function useFormattedDateTextRange(
  formatOptions: FormatOptions = formatDateOptions,
): (startDate: DateType, endDate: DateType, unplannedToken?: string) => DateType {
  const formatDate = useFormattedDateText(formatOptions);
  const {t} = useTranslation();

  return useCallback(
    (
      startDate: DateType,
      endDate: DateType,
      unplannedToken = 'pages:projects.inspector.general.dueDateWorkPackageNotPlanned',
    ): DateType => {
      if (endDate && startDate) {
        if (endDate === startDate) {
          return formatDate(startDate);
        }
        return `${formatDate(startDate)} - ${formatDate(endDate)}`;
      }
      return t(unplannedToken);
    },
    [formatDate, t],
  );
}
