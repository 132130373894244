import {Uuid} from '@octaved/typescript/src/lib';
import {TWLabel, TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {boolFilter, NIL} from '@octaved/utilities';
import {FormikHelpers, FormikValues} from 'formik';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import NodeName from '../../Components/Node/NodeName';
import StringPath from '../../Components/Node/StringPath';
import {Project} from '../../EntityInterfaces/Pid';
import {ProjectFolder} from '../../EntityInterfaces/ProjectFolder';
import {useLoadNodes} from '../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {getProjectSelector} from '../../Modules/Selectors/PidSelectors';
import {getProjectFolderForNodeSelector} from '../../Modules/Selectors/ProjectFolderSelectors';
import {NewProjectFormik} from './NewProjectForm';

interface TemplateSelectorProps {
  formik: FormikHelpers<NewProjectFormik> & FormikValues;
}

export default function TemplateSelector({formik}: TemplateSelectorProps): ReactElement | null {
  const {nodeIds} = useCombinedNodeSearch({and: [['prIsTemplate'], {not: ['isArchived']}]});
  useLoadNodes(nodeIds);
  const getProject = useSelector(getProjectSelector);
  const getProjectFolder = useSelector(getProjectFolderForNodeSelector);
  const {t} = useTranslation();

  if (nodeIds.length === 0) {
    return null;
  }
  const templates = boolFilter<Project>(nodeIds.map(getProject));
  const projectFolders = boolFilter<ProjectFolder>(nodeIds.map(getProjectFolder)).reduce((acc, projectFolder) => {
    acc.add(projectFolder.id);
    return acc;
  }, new Set<Uuid>());
  const requiresPath = projectFolders.size > 1;

  return (
    <div className={'flex w-3/4 flex-col gap-y-1'}>
      <TWLabel>{t('dialogs:createProject.template.label')}</TWLabel>

      <TWSelect
        value={formik.values.templateNodeId ?? NIL}
        onValueChange={(templateNodeId) =>
          formik.setFieldValue('templateNodeId', templateNodeId === NIL ? null : templateNodeId)
        }
      >
        <TWSelectTrigger>
          <TWSelectValue />
        </TWSelectTrigger>
        <TWSelectContent className={'z-[2050]'}>
          <TWSelectItem key={'null'} value={NIL}>
            <span className={'italic text-slate-500'}>{t('dialogs:createProject.template.placeholder')}</span>
          </TWSelectItem>
          {templates.map((template) => (
            <TWSelectItem key={template.id} value={template.id}>
              {requiresPath ? (
                <StringPath nodeId={template.id} includeCustomer />
              ) : (
                <NodeName nodeId={template.id} notSearchable />
              )}
            </TWSelectItem>
          ))}
        </TWSelectContent>
      </TWSelect>
    </div>
  );
}
