import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {Popup, TWButton} from '@octaved/ui';
import {ReactElement, RefObject} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Close from '../Dialogs/Components/Close';
import {getNodeSelector} from '../Modules/Selectors/NodeSelectors';
import {FlowState} from '../Modules/State';
import {nodeTypeTokens} from '../Node/NodeType';
import InheritanceColorLegend from './InheritanceColorLegend';
import DialogAndDrawerHeader from './Layout/DialogAndDrawerHeader';

type CASES = 'inheriting' | 'notInheriting' | 'setHere' | 'noneWithoutParent';

const tokens: Record<CASES, {hasParent?: true; changeInfo?: string; info: string}> = {
  inheriting: {
    changeInfo: 'roles:permissionRoles.showInheritance.inheritance.changeInfo',
    hasParent: true,
    info: 'roles:permissionRoles.showInheritance.inheritance.parentInfo',
  },
  noneWithoutParent: {
    info: 'roles:permissionRoles.showInheritance.noInheritanceNoneRole.parentInfo',
  },
  notInheriting: {
    changeInfo: 'roles:permissionRoles.showInheritance.noInheritance.changeInfo',
    hasParent: true,
    info: 'roles:permissionRoles.showInheritance.noInheritance.parentInfo',
  },
  setHere: {
    info: 'roles:permissionRoles.showInheritance.added.parentInfo',
  },
};

interface InheritanceInfoPopupProps {
  inheritanceCase: CASES;
  mountContext: RefObject<HTMLDivElement>;
  onClose: () => void;
  onRestoreInheritance: () => void;
  parentNodeId: Uuid | null;
  readonly: boolean;
  showInheritanceColorLegend?: boolean;
}

export default function InheritanceInfoPopup({
  inheritanceCase,
  mountContext,
  onClose,
  onRestoreInheritance,
  parentNodeId,
  readonly,
  showInheritanceColorLegend,
}: InheritanceInfoPopupProps): ReactElement {
  const {t} = useTranslation();
  const parentNode = useSelector((state: FlowState) => getNodeSelector(state)(parentNodeId));
  const parentTypeName = parentNode ? t(nodeTypeTokens[parentNode.nodeType]) : '';
  const parentName =
    parentNode && [EnumFlowNodeType.VALUE_PROJECT_FOLDER, EnumFlowNodeType.VALUE_GROUP].includes(parentNode.nodeType)
      ? `${parentTypeName} "${parentNode.name}"`
      : parentTypeName;

  const {hasParent, info, changeInfo} = tokens[inheritanceCase];

  return (
    <Popup open setOpen={onClose} basic context={mountContext.current} flowing>
      <div className={'relative max-w-lg px-6 py-4'}>
        <div className={'absolute right-1 top-1'}>
          <Close close={onClose} />
        </div>
        <DialogAndDrawerHeader header={'general:showInheritance'} className={'my-0'} />
        <div className={'flex flex-col gap-y-4'}>
          <div className={'flex flex-col gap-y-2'}>
            <div>{t(info)}</div>
            {hasParent && <b>{parentName}</b>}
          </div>

          {inheritanceCase === 'inheriting' && changeInfo && <div>{t(changeInfo)}</div>}

          {inheritanceCase === 'notInheriting' && changeInfo && !readonly && (
            <div className={'flex flex-col gap-y-2'}>
              <div>{t(changeInfo)}</div>
              <div className={'flex justify-center'}>
                <TWButton variant={'outline'} size={'md'} onClick={onRestoreInheritance}>
                  {t('roles:permissionRoles.showInheritance.noInheritance.restore')}
                </TWButton>
              </div>
            </div>
          )}

          {inheritanceCase === 'noneWithoutParent' && !readonly && (
            <div className={'flex justify-center'}>
              <TWButton variant={'outline'} size={'md'} onClick={onRestoreInheritance}>
                {t('roles:permissionRoles.showInheritance.noInheritanceNoneRole.button')}
              </TWButton>
            </div>
          )}

          {showInheritanceColorLegend && (
            <>
              <div className={'h-px w-full bg-gray-200'} />
              <InheritanceColorLegend />
            </>
          )}
        </div>
      </div>
    </Popup>
  );
}
