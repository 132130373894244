import {Store, Unsubscribe} from 'redux';

type Selector<S, T> = (state: S) => T;
type ChangeCallback<T> = (selectedData: T) => void;
const initialSymbol = Symbol('initial');

export function createStoreSubscription<S, T>(
  store: Store<S>,
  selector: Selector<S, T>,
  onChange: ChangeCallback<T>,
  compare: (currentData: T, previousDate: T) => boolean = (currentData, previousDate) => currentData === previousDate,
  getInitialValue: (state: S) => T | symbol = () => initialSymbol,
): Unsubscribe {
  let previousSelectedData: T | symbol = getInitialValue(store.getState());

  const handleStoreChange = (): void => {
    const currentState = store.getState();
    const selectedData = selector(currentState);

    if (typeof previousSelectedData === 'symbol' || !compare(selectedData, previousSelectedData)) {
      onChange(selectedData);
      previousSelectedData = selectedData;
    }
  };

  const unsubscribe = store.subscribe(handleStoreChange);

  // Initial invocation to capture the initial selected data
  handleStoreChange();

  return unsubscribe;
}
