import {FlowState} from '@octaved/flow/src/Modules/State';
import {createSelector} from 'reselect';
import {OrganizationAllowedIPEntities, OrganizationAllowedIPEntity} from '../EntityInterfaces/OrganizationAllowedIP';

export const organizationAllowedLoginIPSelector = (state: FlowState): OrganizationAllowedIPEntities =>
  state.entities.organizationAllowedLoginIP;

export const organizationAllowedLoginIPListSelector = createSelector(
  organizationAllowedLoginIPSelector,
  (organizationAllowedLoginIP): OrganizationAllowedIPEntity[] => Object.values(organizationAllowedLoginIP),
);

export const getOrganizationAllowedLoginIPSelector = createSelector(
  organizationAllowedLoginIPSelector,
  (organizationAllowedLoginIP) =>
    (id: number | string | null): OrganizationAllowedIPEntity | null =>
      organizationAllowedLoginIP[id! as number] || null,
);
