import {createContext, useContext} from 'react';

export type ProjectPages =
  | 'billing'
  | 'billingInPeriod'
  | 'billingPreparation'
  | 'billingsList'
  | 'controlActivity'
  | 'controlCriticalTimePrognosis'
  | 'controlDegreeOfCompletion'
  | 'controlFixedPriceAnalysis'
  | 'controlInProgress'
  | 'controlPlanningTransgression'
  | 'controlTimeTransgression'
  | 'controlTrackedTime'
  | 'createOffer'
  | 'edit'
  | 'timesheetsExternal';

export interface ProjectContext {
  page: ProjectPages;
}

export const projectContext = createContext<ProjectContext>(null as unknown as ProjectContext);

export function useProjectContext(optional: true): ProjectContext | null;
export function useProjectContext(): ProjectContext;
export function useProjectContext(optional = false): ProjectContext {
  const ctx = useContext(projectContext);
  if (!ctx && !optional) {
    throw new Error('projectContext not set');
  }
  return ctx;
}
