import {FlowState} from '@octaved/flow/src/Modules/State';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {Uuid} from '@octaved/typescript/src/lib';
import {splitUnitIds} from '@octaved/users/src/Modules/UnitLists';
import {useEffect, useMemo, useState} from 'react';
import {Selector, useSelector} from 'react-redux';
import {SlimUnit} from '../EntityInterfaces/UnitLists';
import {loadGroupUsers} from '../Modules/GroupUsers';
import {groupUsersSelector} from '../Selectors/GroupUserSelectors';

function reduceNewSet(ids: Uuid[], list: Record<Uuid, Uuid[]>): (set: Set<Uuid>) => Set<Uuid> {
  return (prevSet) => {
    let changed = false;
    const oldSet = new Set(prevSet);
    const newSet = new Set<Uuid>();
    ids.forEach((id) => {
      if (list[id]) {
        list[id].forEach((childId) => {
          newSet.add(childId);
          if (oldSet.has(childId)) {
            oldSet.delete(childId); //remove from oldSet to track superfluous ids in the previous state
          } else {
            changed = true;
          }
        });
      }
    });
    if (oldSet.size) {
      changed = true; //old set has remaining entries, which are not part of newSet anymore
    }
    return changed ? newSet : prevSet;
  };
}

type GroupUserIds = Map<Uuid, Set<Uuid>>;

interface ResolvedGroupUsers {
  groupIds: Set<Uuid>;
  groupUserIds: GroupUserIds; //maps each group in groupIds to all its contained user ids
  hasLoadedOnce: boolean;
  isLoading: boolean;
  userIds: Set<Uuid>;
}

/**
 * Finds all descending/nested groupIds/userIds within the given groupId
 *
 * Currently this is a recursive process - if we have a loaded group tree in the future, this could be optimized
 */
export function useResolvedGroupUsers(groupId: Uuid | Uuid[]): ResolvedGroupUsers {
  const [userIds, setUserIds] = useState<Set<Uuid>>(new Set());
  const [groupIds, setGroupIds] = useState<Set<Uuid>>(new Set());
  const [groupUserIds, setGroupUserIds] = useState<GroupUserIds>(new Map());

  useEffect(() => {
    setGroupIds(new Set(Array.isArray(groupId) ? groupId : [groupId]));
  }, [groupId]);

  //Load groups' users and keep extending the userIds set:
  useStoreEffect(
    (dispatch, _, groupUsers) => {
      const groupIdsArray = [...groupIds];
      dispatch(loadGroupUsers(groupIdsArray));
      setUserIds(reduceNewSet(groupIdsArray, groupUsers));
    },
    [groupIds],
    groupUsersSelector,
  );

  //Fill the mapping for [groupId => allUserIds]
  useStoreEffect(
    (_d, _g, groupUsers) => {
      setGroupUserIds(() => {
        return new Map(
          [...groupIds].map((groupId): [Uuid, Set<Uuid>] => {
            return [groupId, new Set<Uuid>(groupUsers[groupId] || [])];
          }),
        );
      });
    },
    [groupIds],
    groupUsersSelector as Selector<FlowState, ReturnType<typeof groupUsersSelector>>,
  );

  const isLoading = useSelector((s: FlowState) => {
    const groupUsers = groupUsersSelector(s);
    return [...groupIds].some((groupId) => !groupUsers.hasOwnProperty(groupId));
  });

  return {
    isLoading,
    groupIds: useLoadedValue(isLoading, groupIds, groupId),
    groupUserIds: useLoadedValue(isLoading, groupUserIds, groupId),
    hasLoadedOnce: useLoadedValue(isLoading, !isLoading, groupId),
    userIds: useLoadedValue(isLoading, userIds, groupId),
  };
}

export function useResolvedGroupUsersFromUnits(units: SlimUnit[]): ResolvedGroupUsers {
  const {groupIds, userIds} = useMemo(() => splitUnitIds(units), [units]);
  const result = useResolvedGroupUsers(groupIds);
  return {
    ...result,
    userIds: useMemo(() => new Set([...userIds, ...result.userIds]), [result.userIds, userIds]),
  };
}
