import {ReactElement} from 'react';

interface JsonCellProps {
  cell: {value: unknown | null};
}

export default function JsonCell({cell: {value}}: JsonCellProps): ReactElement | null {
  if (!value) {
    return null;
  }

  return <span>{JSON.stringify(value)}</span>;
}
