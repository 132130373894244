import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {useLoading} from '@octaved/flow/src/Modules/Hooks/Loading';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useLoadWorkTimeDefinitions} from '@octaved/flow/src/Modules/WorkTimes/WorkTimeDefinitions';
import {todayDayjsSelector} from '@octaved/flow/src/Today';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import dayjs from 'dayjs';
import {ReactElement, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useStore} from 'react-redux';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import CanvasRenderer from '../../Canvas/CanvasRenderer';
import {EventEmitter} from '../../Canvas/EventEmitter';
import {GanttEvents} from '../../Canvas/Gantt/Context/GanttContext';
import {
  createFullQuery,
  useProjectBaseGanttDataLoader,
} from '../../Canvas/ProjectBasedGantt/ProjectBasedGanttDataLoader';
import {getPlanningBaselineHistory} from '../../Modules/PlanningBaselineHistory';
import {planningBaselineHistoryEntityStatesSelector} from '../../Selectors/PlanningBaselineHistorySelectors';
import {baselineComparisonShowTasksSelector, getSelectedBaselineIdsSelector} from '../../Selectors/UiSelectors';
import {SingleNodePlanningData} from '../SingleNodePlanningDataLoader';
import {GanttComparisonBarFactory} from './Comparison/GanttComparisonBarFactory';
import {GanttComparisonCalendarView} from './Comparison/GanttComparisonCalendarView';
import {GanttComparisonContext} from './Comparison/GanttComparisonContext';
import {GanttComparisonPlanning} from './Comparison/GanttComparisonPlanning';
import {GanttComparisonTableFactory} from './Comparison/GanttComparisonTableFactory';

function createQuery(rootNodeId: Uuid, showTasks: boolean): NodeSearchCondition {
  const query: Array<NodeSearchCondition> = [];
  if (showTasks) {
    query.push({
      or: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
        ['nodeType', EnumFlowNodeType.VALUE_TASK],
      ],
    });
    query.push(withDescendants(rootNodeId, true));
  } else {
    query.push({
      or: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
      ],
    });
  }
  return {and: query};
}

export function useGantComparisonPlanningDataLoader(
  planningDataLoader: SingleNodePlanningData | null,
  rootNodeId: Uuid,
): void {
  const showTasks = useSelector(baselineComparisonShowTasksSelector);
  useProjectBaseGanttDataLoader(planningDataLoader, {
    fullQuery: createFullQuery([rootNodeId], showTasks),
    query: createQuery(rootNodeId, showTasks),
    showMaterialResources: false,
  });

  const selectedBaselineIds = useSelector(getSelectedBaselineIdsSelector)(rootNodeId);
  useLoading(selectedBaselineIds, planningBaselineHistoryEntityStatesSelector, getPlanningBaselineHistory);
}

const scrollbarSize = 13;

interface RestoreData {
  calendarDateStart: dayjs.Dayjs;
  calendarDateEnd: dayjs.Dayjs;
  canvasDateStart: dayjs.Dayjs;
}

interface CanvasProps {
  nodeId: Uuid;
}

export default function Canvas({nodeId}: CanvasProps): ReactElement {
  const canvasRef = useRef<HTMLDivElement>(null);
  const vScrollBarRef = useRef<HTMLDivElement>(null);
  const hScrollBarRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const store = useStore<FlowState>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  useLoadWorkTimeDefinitions();

  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const [ctx, setCtx] = useState<GanttComparisonContext | null>(null);
  const [planningDataLoader, setPlanningDataLoader] = useState<SingleNodePlanningData | null>(null);
  const [eventEmitter] = useState(() => new EventEmitter<GanttEvents>());
  useGantComparisonPlanningDataLoader(planningDataLoader, nodeId);

  const navRef = useRef<NavigateFunction>(navigate);
  navRef.current = navigate;

  const restoreRef = useRef<RestoreData | null>(null);

  useEffect(() => {
    if (!canvasRef.current || !vScrollBarRef.current || !hScrollBarRef.current || !scrollRef.current) {
      return;
    }
    const _planningDataLoader = new SingleNodePlanningData({nodeId});
    const today = todayDayjsSelector(store.getState());
    const calendarView = new GanttComparisonCalendarView({
      today,
      dataLoader: _planningDataLoader,
      dateEnd: restoreRef.current?.calendarDateEnd,
      dateStart: restoreRef.current?.calendarDateStart,
      rootNodeId: nodeId,
    });

    const _ctx = new GanttComparisonContext({
      calendarView,
      eventEmitter,
      scrollbarSize,
      store,
      t,
      canvas: canvasRef.current,
      hScrollBar: hScrollBarRef.current,
      navigate: navRef.current,
      rootNodeId: nodeId,
      scrollWheel: scrollRef.current,
      vScrollBar: vScrollBarRef.current,
    });
    _ctx.init();

    _planningDataLoader.init(_ctx);
    setCtx(_ctx);
    setPlanningDataLoader(_planningDataLoader);

    return () => {
      restoreRef.current = {
        calendarDateEnd: _ctx.calendarView.dateEnd,
        calendarDateStart: _ctx.calendarView.dateStart,
        canvasDateStart: _ctx.canvasView.dateStart,
      };
      _ctx.dispose();
      _planningDataLoader.dispose();
    };
  }, [eventEmitter, store, t, nodeId]);

  useEffect(() => {
    if (!canvasRef.current || !ctx || !planningDataLoader) {
      return;
    }

    const barFactory = new GanttComparisonBarFactory({ctx});
    barFactory.init();
    const tableFactory = new GanttComparisonTableFactory({ctx});
    tableFactory.init();
    ctx.setTableFactory(tableFactory);
    const ganttProjectPlanning = new GanttComparisonPlanning({
      barFactory,
      ctx,
      planningDataLoader,
      setScrollHeight,
      setScrollWidth,
      tableFactory,
      canvas: canvasRef.current,
    });
    ganttProjectPlanning.init({initialScrollDate: restoreRef.current?.canvasDateStart});

    return () => {
      barFactory.dispose();
      ganttProjectPlanning.dispose();
    };
  }, [ctx, planningDataLoader, t]);

  return (
    <CanvasRenderer
      canvasRef={canvasRef}
      hScrollBarRef={hScrollBarRef}
      scrollbarSize={scrollbarSize}
      scrollHeight={scrollHeight}
      scrollRef={scrollRef}
      scrollWidth={scrollWidth}
      vScrollBarRef={vScrollBarRef}
    />
  );
}
