import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {TaskListColumn} from '../../../Modules/Ui/TaskList';
import TaskListContext from '../TaskListContext';
import TaskListForNode from '../TaskListForNode/TaskListForNode';

interface Props {
  displayDoneTasksAsOpen?: boolean;
  headline: ReactElement;
  nodeId: Uuid;
}

const visibleColumns = new Set<TaskListColumn>();

export default function TaskPreview({displayDoneTasksAsOpen, headline, nodeId}: Props): ReactElement {
  return (
    <>
      {headline}
      <TaskListContext
        readonly
        showNotes
        hideTrackTime
        visibleColumns={visibleColumns}
        displayDoneTasksAsOpen={displayDoneTasksAsOpen}
      >
        <TaskListForNode nodeId={nodeId} />
      </TaskListContext>
    </>
  );
}
