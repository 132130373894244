import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  WorkPackageSingleSelectLeftDrawerUiState,
  workPackageSingleSelectLeftDrawerUiState,
} from '../../Pages/Components/LeftDrawer/ItemTypes/WorkPackage/WorkPackageLeftDrawer';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';
import {initialSuitcaseDrawerState, SuitcaseDrawer} from '../Ui/SuitcaseDrawer';

export interface BoardsUiState {
  leftDrawer: WorkPackageSingleSelectLeftDrawerUiState;
  search: string;
  selectedKanbanBoard: null | Uuid;
  suitcaseDrawer: SuitcaseDrawer;
}

export const initialUiState: BoardsUiState = {
  leftDrawer: workPackageSingleSelectLeftDrawerUiState,
  search: '',
  selectedKanbanBoard: null,
  suitcaseDrawer: initialSuitcaseDrawerState,
};

export function usePatchBoardsUiState(): (patch: DeepPartial<BoardsUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((boards) => dispatch(setUiState({pages: {boards}})), [dispatch]);
}

export function patchBoardsLeftDrawerUiState(patch: Partial<WorkPackageSingleSelectLeftDrawerUiState>): ThunkAct<void> {
  return setUiState({pages: {boards: {leftDrawer: patch}}});
}
