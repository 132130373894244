import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {passwordPolicySelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import type {FlowState} from '@octaved/flow/src/Modules/State';
import {getSupportedBrowserLanguage} from '@octaved/i18n/src/Language/Languages';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {validateRules} from '@octaved/store/src/Validation';
import {
  PATCH_CURRENT_USER_PASSWORD_FAILURE,
  PATCH_CURRENT_USER_PASSWORD_REQUEST,
  PATCH_CURRENT_USER_PASSWORD_SUCCESS,
} from '@octaved/users/src/Modules/ActionTypes';
import {getPasswordValidationRules} from '@octaved/users/src/Modules/OrgUser';
import {setErrors} from '@octaved/users/src/Modules/UiPage';
import {NIL} from '@octaved/utilities';
import {ObjectContains} from '@octaved/validation';
import * as routes from '../../config/routes';
import {
  IdentityDeclinedOrgInvitationEvent,
  IdentityEmailVerifiedEvent,
  IdentityPatchedEvent,
  IdentityTwoFaEnabledEvent,
  IdentityTwoFaRevokedEvent,
} from '../Event/Events';
import {Identity, PatchableIdentity} from '../Interfaces/Identity';
import {identitySelector} from '../Selectors/IdentitySelectors';
import {IDENTITY_PATCH_FAILURE, IDENTITY_PATCH_REQUEST, IDENTITY_PATCH_SUCCESS} from './ActionTypes';
import './JwtWatchdog';

const reducers = createReducerCollection<Identity>({
  email: '',
  hiddenOrganizationIds: [],
  id: NIL,
  identifier: '',
  isEmailVerified: false,
  isLdapUser: false,
  jwt: '',
  jwtExpiresAt: 0,
  language: getSupportedBrowserLanguage(),
  name: '',
  organizations: {
    invited: [],
    joined: [],
  },
  twoFaType: 'disabled',
});
export const identityReducer = reducers.reducer;

reducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) => {
  if (action.isAuthenticated) {
    return mergeStates(state, action.response.result.identity);
  }
  return state;
});

reducers.add<IdentityDeclinedOrgInvitationEvent>(
  'flow.IdentityDeclinedOrgInvitationEvent',
  (state, {organizationId}) => {
    const invited = state.organizations.invited.filter((org) => org.organizationId !== organizationId);
    return mergeStates(state, {organizations: {invited}});
  },
);

reducers.add<IdentityEmailVerifiedEvent>('flow.IdentityEmailVerifiedEvent', (state) => {
  return mergeStates(state, {isEmailVerified: true});
});

reducers.add<IdentityPatchedEvent>('flow.IdentityPatchedEvent', (state, {patch}) => {
  return mergeStates(state, patch);
});

reducers.add<IdentityTwoFaEnabledEvent>('flow.IdentityTwoFaEnabledEvent', (state, {twoFaType}) => {
  return mergeStates(state, {twoFaType});
});

reducers.add<IdentityTwoFaRevokedEvent>('flow.IdentityTwoFaRevokedEvent', (state) => {
  return mergeStates(state, {twoFaType: 'disabled'});
});

export function patchIdentity(data: Partial<PatchableIdentity>): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const current = identitySelector(getState());
    if (!ObjectContains(current, data)) {
      await dispatch({
        [CALL_API]: {
          endpoint: routes.patchIdentity,
          method: 'patch',
          options: {data},
          types: {
            failureType: IDENTITY_PATCH_FAILURE,
            requestType: IDENTITY_PATCH_REQUEST,
            successType: IDENTITY_PATCH_SUCCESS,
          },
        },
      });
    }
  };
}

export function changePassword(
  oldPassword: string,
  confirm: string,
  newPassword: string,
): ActionDispatcher<Promise<boolean>> {
  return async (dispatch, getState): Promise<boolean> => {
    const state = getState();
    const policy = passwordPolicySelector(state);

    const rules = getPasswordValidationRules(
      {
        confirm,
        plainPassword: newPassword,
      },
      policy,
    );

    const errors = validateRules(rules);
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    return dispatch({
      [CALL_API]: {
        endpoint: routes.changePassword,
        method: 'patch',
        options: {
          data: {newPassword, oldPassword},
        },
        types: {
          failureType: PATCH_CURRENT_USER_PASSWORD_FAILURE,
          requestType: PATCH_CURRENT_USER_PASSWORD_REQUEST,
          successType: PATCH_CURRENT_USER_PASSWORD_SUCCESS,
        },
      },
    });
  };
}
