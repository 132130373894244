import {cn} from '@octaved/ui';
import './context-menu-section.css';
import {PropsWithChildren, ReactElement} from 'react';

export default function ContextMenuSection({
  children,
  className,
}: PropsWithChildren<{className?: string}>): ReactElement {
  return <div className={cn('contextMenuSection group', className)}>{children}</div>;
}
