import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import UnitNameWithIcon from '@octaved/flow/src/Components/Unit/UnitNameWithIcon';
import {RoleAssignmentOnNode} from '@octaved/flow/src/EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {RoleDropdownOption} from '@octaved/flow/src/Modules/Selectors/RoleSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn, tableGrid, tableGridCellVariant} from '@octaved/ui';
import {Fragment, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import AddUnit from './AddUnit';
import AssignmentRowMenu from './AssignmentRowMenu';
import {SimpleRileAssignmentAddUnitCB} from './Hook';
import RoleDropdown from './RoleDropdown';

interface Props<RAN extends RoleAssignmentOnNode> {
  addUnit: SimpleRileAssignmentAddUnitCB;
  assignments: RAN[];
  availableRoles: RoleDropdownOption[];
  changeRole: (assignment: RAN, roleId: Uuid) => void;
  customerId: Uuid | null;
  isGuestRole: boolean;
  readonly: boolean;
  restoreInheritance: (assignment: RAN) => void;
  takenRolesPerUnit: Map<Uuid, Uuid[]>;
  type: EnumFlowNodeRoleType;
}

export default function AssignmentsTable<RAN extends RoleAssignmentOnNode>({
  addUnit,
  assignments,
  availableRoles,
  changeRole,
  customerId,
  isGuestRole,
  readonly,
  restoreInheritance,
  takenRolesPerUnit,
  type,
}: Props<RAN>): ReactElement {
  const {t} = useTranslation();

  return (
    <div className={cn(tableGrid({className: 'grid-cols-[1fr_1fr_max-content]'}))}>
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'left'}))}>
        {t(
          type === EnumFlowNodeRoleType.VALUE_PROJECT
            ? 'roles:tableHeader.unit.project'
            : isGuestRole
              ? 'roles:tableHeader.unit.guest'
              : 'roles:tableHeader.unit.permission',
        )}
      </div>
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'center'}))}>
        {t(
          type === EnumFlowNodeRoleType.VALUE_PROJECT
            ? 'roles:tableHeader.role.project'
            : isGuestRole
              ? 'roles:tableHeader.role.guest'
              : 'roles:tableHeader.role.permission',
        )}
      </div>
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'right'}))}>&nbsp;</div>

      {assignments.map((assignment) => {
        const {uniqueId, unitId, unitName, unitType, flowRoleId, isRemoved, isOverridden, isAdded} = assignment;
        return (
          <Fragment key={uniqueId}>
            <UnitNameWithIcon
              className={cn(tableGridCellVariant({position: 'left'}))}
              status={isAdded ? 'added' : isOverridden ? 'edited' : isRemoved ? 'removed' : 'none'}
              unitId={unitId}
              unitName={unitName}
              unitType={unitType}
            />
            <div className={cn(tableGridCellVariant({position: 'center'}))}>
              <RoleDropdown
                availableRoles={availableRoles}
                disabled={readonly}
                excludedRoles={takenRolesPerUnit.get(unitId)}
                onChange={(roleId) => changeRole(assignment, roleId)}
                roleId={flowRoleId}
              />
            </div>
            <AssignmentRowMenu
              assignment={assignment}
              type={type}
              restoreInheritance={restoreInheritance}
              changeRole={changeRole}
              readonly={readonly}
            />
          </Fragment>
        );
      })}

      {!readonly && (
        <AddUnit
          addUnit={addUnit}
          assignments={assignments}
          customerId={customerId}
          fluid
          isGuestRole={isGuestRole}
          roleType={type}
          availableRoles={availableRoles}
          takenRolesPerUnit={takenRolesPerUnit}
        />
      )}
    </div>
  );
}
