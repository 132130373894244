import {ReactElement, ReactNode} from 'react';

import {FlexSpacer, cn} from '@octaved/ui';
import QuickAccessButtons from '../../PageHeader/Components/QuickAccessButtons';

export interface StandardPageFrameHeaderProps {
  centerHeader?: boolean;
  children?: ReactNode;
  hasSuitcaseDrawer?: boolean;
  noBottomBorder?: boolean;
  noBottomPadding?: boolean;
  noSpacer?: boolean;
  width?: 'auto';
}

export default function StandardPageFrameHeader({
  centerHeader,
  children,
  hasSuitcaseDrawer,
  noBottomBorder,
  noBottomPadding,
  noSpacer,
  width,
}: StandardPageFrameHeaderProps): ReactElement {
  return (
    <div
      className={cn(
        'relative z-[7] h-[70px] border-b border-b-slate-200 bg-white px-6 py-5',
        noBottomPadding && 'pb-0',
        noBottomBorder && 'border-b-0',
        hasSuitcaseDrawer && 'pl-leftDrawerPadding',
      )}
    >
      <div className={cn('flex h-full items-center', noSpacer && 'justify-between')}>
        {centerHeader && (
          <>
            <div className={'w-[126px]'} />
            <FlexSpacer />
          </>
        )}
        <div
          className={cn('flex items-center gap-x-2', !width && noSpacer && 'w-full')}
          style={width ? {width} : undefined}
        >
          {children}
        </div>
        {!noSpacer && <FlexSpacer />}
        <QuickAccessButtons />
      </div>
    </div>
  );
}

StandardPageFrameHeader.displayName = 'StandardPageFrameHeader';
