import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '../EntityInterfaces/Pid';

export function getEffectiveMaxEffort({
  billingType,
  maxEffort,
  effortTo,
}: Pick<WorkPackage, 'billingType' | 'maxEffort' | 'effortTo'>): number | null {
  let effectiveMaxEffort: number | null = null;
  switch (billingType) {
    case EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO:
      effectiveMaxEffort = effortTo;
      break;
    case EnumFlowPidBillingType.VALUE_CONTINGENT:
    case EnumFlowPidBillingType.VALUE_EFFORT_CAP:
    case EnumFlowPidBillingType.VALUE_EFFORT_EST:
    case EnumFlowPidBillingType.VALUE_FIXED_PRICE:
      effectiveMaxEffort = maxEffort;
      break;
    default:
    //null for VALUE_EFFORT and VALUE_FREE_OF_CHARGE
  }
  return effectiveMaxEffort;
}
