import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import dayjs from 'dayjs';
import {ReactElement} from 'react';

interface TimeStampCellProps {
  cell: {value: number | null};
}

export default function TimeStampCell({cell: {value}}: TimeStampCellProps): ReactElement | null {
  const formateDate = useFormatInWords();
  if (!value) {
    return null;
  }
  return <span>{formateDate(dayjs.unix(value), 'datetime')}</span>;
}
