import {Car, Moon} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {TimeRecord} from '../../../../../../EntityInterfaces/TimeRecords';
import PriceSurchargeName from './PriceSurchargeName';

export default function Type({record}: {record: TimeRecord}): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'items'}>
      {record.isJourney && (
        <div className={'item'}>
          <Car className={'h-4 w-4 text-sky-600'} />
          <span>{t('pages:projects.drawer.pidViewTimeTrackings.table.journey')}</span>
        </div>
      )}
      {record.priceSurcharge && (
        <div className={'item'}>
          <Moon className={'h-4 w-4 text-sky-700'} />
          <PriceSurchargeName id={record.priceSurcharge} />
        </div>
      )}
      {/*language=scss*/}
      <style jsx>{`
        .items {
          display: flex;
          grid-gap: 14px;
        }

        .item {
          display: flex;
        }
      `}</style>
    </div>
  );
}
