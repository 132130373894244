import {Bar} from '../../Bar';
import {BarPlugin, BarPluginProps} from '../BarPlugin';
import {BarIcon} from './BarIcon';

interface IconListBarPluginProps extends BarPluginProps {
  icons: BarIcon[];
}

export class IconListBarPlugin extends BarPlugin {
  #icons: BarIcon[] = [];

  constructor(props: IconListBarPluginProps) {
    super(props);
    this.#icons = props.icons;
  }

  init(bar: Bar): void {
    super.init(bar);
    this.#icons.forEach((icon) => {
      icon.init(this);
      this.root.add(icon.root);
    });
    this.#renderIcons.flush();
  }

  #renderIcons = this.debouncedAction((): void => {
    let x = this.bar.width - this.bar.barPaddingY;

    for (const icon of this.#icons) {
      if (icon.isVisible) {
        x -= icon.height;
        icon.root.x(x);
        icon.root.show();
      } else {
        icon.root.hide();
      }
    }
  });

  onIconsChanges(): void {
    this.#renderIcons();
  }

  onBarUpdated(): void {
    super.onBarUpdated();
    this.#renderIcons();
  }
}
