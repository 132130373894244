import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect} from 'react';
import {useStore} from 'react-redux';
import {extendWithAncestorsSelector} from '../../../Modules/Selectors/NodeTreeSelectors';
import {taskListClosedGroupsSelector} from '../../../Modules/Selectors/Ui/TaskListSelectors';
import {FlowState} from '../../../Modules/State';
import {usePatchTaskListUiState} from '../../../Modules/Ui/TaskList';

export function useOpenSearchedTasksAndGroups(
  searchTerm: string,
  hasLoadedOnce: boolean,
  taskIds: ReadonlyArray<Uuid>,
): void {
  const {getState} = useStore<FlowState>();
  const patch = usePatchTaskListUiState();

  useEffect(() => {
    if (searchTerm && hasLoadedOnce) {
      const state = getState();
      const ancestors = extendWithAncestorsSelector(state)(taskIds);
      const record: Record<string, boolean> = {};
      let mustPatch = false;
      const isClosedRecord = taskListClosedGroupsSelector(state);
      ancestors.forEach((ancestorId) => {
        if (isClosedRecord[ancestorId]) {
          record[ancestorId] = false;
          mustPatch = true;
        }
      });
      if (mustPatch) {
        patch({closedGroups: record});
      }
    }
  }, [hasLoadedOnce, searchTerm, patch, taskIds, getState]);
}
