import css from 'styled-jsx/css';
import {FlowStyleBuilder, useFlowStyle} from '../../Styles/StyleContext';

//#region <styles>
/*language=SCSS*/
const getStyle: FlowStyleBuilder = ({ANIMATIONS: {FAST}, COLORS: {TABLE}}) => css.resolve`
  .controlledReactTableContainer {
    position: relative;

    .table {
      margin-top: 0;
    }
  }

  .table {
    margin-bottom: 5px;
    background-color: transparent !important;
    border: none !important;
    table-layout: fixed;
  }

  :global(.noFixedTable) .table {
    table-layout: auto;
  }

  .headerCell {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid ${TABLE.BORDER} !important;
    color: #707070 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .footerCell {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    background-color: transparent !important;
    border: none !important;
    border-top: 1px solid ${TABLE.BORDER} !important;
    font-size: 12px !important;
  }

  .footerCell.noTopBorder {
    border-top: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global(.ui.sortable.table) > thead > tr > th.noSort {
    cursor: auto;
  }

  .cell {
    font-size: 12px;
    line-height: 16px;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border: none !important;

    &.main {
      font-size: 14px;
      line-height: 20px;
    }

    &.onlyIcon {
      width: 50px;
    }
  }

  .row {
    transition:
      opacity ${FAST},
      filter ${FAST},
      background-color ${FAST};
  }

  .row:not(:last-child) .cell {
    border-bottom: 1px solid ${TABLE.WEAK_BORDER} !important;
  }

  .row:hover {
    cursor: pointer;
    background-color: ${TABLE.ROW.HOVER};
  }

  .row.active {
    background-color: ${TABLE.ROW.SELECTED} !important;
  }

  .row.greyOut .cell {
    opacity: 0.5;
    filter: grayscale(0.8);
  }
`;

//#endregion

export function useFlowTableStyle(): ReturnType<typeof css.resolve> {
  return useFlowStyle(getStyle);
}
