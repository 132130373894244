import {isDevLocal} from '@octaved/env/src/Environment';
import dayjs, {Dayjs} from 'dayjs';

export type DateTimeStr = string & {__brand: 'DateTimeStr'};

export function isDateTimeStr(date: string | null | undefined): date is DateTimeStr {
  return !!date && date.match(/^\d{4}-\d{2}-\d{2} ([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/) !== null;
}

function isDateTimeStrAssertion(date: string | null | undefined): date is DateTimeStr {
  return isDevLocal ? isDateTimeStr(date) : !!date;
}

export function asDateTimeStr(date: string): DateTimeStr;
export function asDateTimeStr(date: null | undefined): null;
export function asDateTimeStr(date: string | null | undefined): DateTimeStr | null;
export function asDateTimeStr(date: string | null | undefined): DateTimeStr | null {
  if (isDateTimeStrAssertion(date)) {
    return date;
  }
  if (isDevLocal && date) {
    throw new Error(`'${date}' is not a valid date time`);
  }
  return null;
}

export function toDateTimeStr(date: Dayjs | DateTimeStr | number): DateTimeStr;
export function toDateTimeStr(date: null | undefined): null;
export function toDateTimeStr(date: Dayjs | DateTimeStr | number | null | undefined): DateTimeStr | null;
export function toDateTimeStr(date: Dayjs | DateTimeStr | number | null | undefined): DateTimeStr | null {
  if (dayjs.isDayjs(date)) {
    return asDateTimeStr(date.format('YYYY-MM-DD HH:mm:ss'));
  }
  if (typeof date === 'number') {
    return asDateTimeStr(dayjs.unix(date).format('YYYY-MM-DD HH:mm:ss'));
  }
  return asDateTimeStr(date);
}
