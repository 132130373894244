import {Uuid} from '@octaved/typescript/src/lib';
import Flex from '@octaved/ui-components/src/Flex';
import classNames from 'classnames';
import {ChevronRight} from 'lucide-react';
import {Fragment, ReactElement, useContext, useMemo} from 'react';
import css from 'styled-jsx/css';
import {ProjectFolder} from '../../EntityInterfaces/ProjectFolder';
import {useNodePath} from '../../Modules/Hooks/NodeTree';
import {FlowColors} from '../../Styles/Default';
import {flowStyleContext} from '../../Styles/StyleContext';
import FolderSvg from '../../svgs/folder.svg?tsx';

//#region <styles>
function getStyle(
  TREE_PATH: FlowColors['TREE_PATH'],
  FONT: FlowColors['FONT'],
  inverted: boolean,
  clickAble: boolean,
): {className: string; styles: JSX.Element} {
  /*language=SCSS*/
  return css.resolve`
    .treePath {
      line-height: 1em;

      .nameLabel {
        margin-left: 5px;
        margin-right: 10px;
      }

      &.noIcon .nameLabel {
        margin-left: 0;
        margin-right: 5px;
      }

      a.nameLabel {
        cursor: ${clickAble ? 'pointer' : 'default'};
        color: ${inverted ? FONT.INVERTED : FONT.STANDARD};

        &.groupped {
          color: ${TREE_PATH.ICONS};
        }

        &:hover {
          text-decoration: ${clickAble ? 'underline' : 'none'};
        }
      }

      & .typeIcon {
        margin: 0 5px;

        &:first-child {
          margin-left: 0;
        }

        & .icon {
          width: 16px;
          height: 16px;
          stroke: ${TREE_PATH.ICONS};
          stroke-width: 1.5px;
        }
      }

      &.insideLabel a.nameLabel {
        color: rgba(0, 0, 0, 0.6);
      }

      & .dividerIcon {
        margin-right: 5px;

        & .icon {
          width: 14px;
          height: 14px;
          stroke: ${TREE_PATH.ICONS};
        }
      }
    }
  `;
}

//#endregion

type ExtendedProjectFolder = ProjectFolder & {isCollapse?: boolean};

interface Props {
  clickAble?: boolean;
  projectFolder: Uuid | null;
  inverted?: boolean;
  noIcon?: boolean;
  noCollapse?: boolean;
  insideLabel?: boolean;
  wrapAble?: boolean;
}

export default function TreePath({
  projectFolder,
  clickAble = false,
  inverted = false,
  noIcon = false,
  noCollapse = false,
  insideLabel = false,
  wrapAble = false,
}: Props): ReactElement {
  const {
    COLORS: {FONT, TREE_PATH},
  } = useContext(flowStyleContext);
  const {className, styles} = getStyle(TREE_PATH, FONT, inverted, clickAble);
  const {nodes: folders} = useNodePath(projectFolder);

  const pathOfInterest = useMemo<ExtendedProjectFolder[]>(() => {
    if (folders.length <= 3 || noCollapse) {
      return folders as ExtendedProjectFolder[];
    }
    return [
      folders[0],
      {id: '', name: '...', isCollapse: true},
      folders[folders.length - 1],
    ] as ExtendedProjectFolder[];
  }, [noCollapse, folders]);

  return (
    <Flex className={classNames(className, 'treePath', {insideLabel, noIcon})} flexWrap={wrapAble ? 'wrap' : 'nowrap'}>
      {pathOfInterest.map(({id, name, isCollapse}, index) => (
        <Fragment key={id}>
          {!noIcon && (
            <div className={classNames(className, 'typeIcon centerCenter')}>
              <FolderSvg className={classNames(className, 'icon')} />
            </div>
          )}
          <a className={classNames(className, 'nameLabel', {grouped: isCollapse})}>{name}</a>
          {/*show divider icon except for the last element*/}
          {index + 1 !== pathOfInterest.length && (
            <Flex justifyContent={'center'} className={classNames(className, 'dividerIcon')}>
              <ChevronRight className={classNames(className, 'icon')} />
            </Flex>
          )}
        </Fragment>
      ))}
      {styles}
    </Flex>
  );
}
