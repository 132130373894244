import PlaceholderText from '@octaved/flow/src/Components/Feedback/Placeholder/PlaceholderText';
import {useFormattedDateText} from '@octaved/flow/src/Hooks/FormattedDateText';
import {Calendar} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Tooltip, TooltipProps} from '../../../Feedback';
import {cn} from '../../../Hooks';
import {TWButton} from '../../../TWButton';
import {ButtonSize} from '../../Button';

export interface StandardDatePickerTriggerProps {
  buttonSize?: ButtonSize;
  date: string | null;
  isActive?: boolean; //disables tooltip, the datepicker is already visible
  labelOverride?: ReactElement;
  onToggle: () => void;
  readonly?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  triggerClassName?: string;
  placeholderToken?: string;
}

export default function StandardDatePickerTrigger({
  date,
  isActive,
  labelOverride,
  onToggle,
  readonly,
  tooltipProps,
  triggerClassName,
  placeholderToken = 'generalCore:datePicker.noDateSelected', //use generalCore:datePicker.selectDate when non-optional
}: StandardDatePickerTriggerProps): ReactElement {
  const {t} = useTranslation();
  const formatDate = useFormattedDateText();
  const dateString = formatDate(date);

  return (
    <Tooltip toolTipTranslation={''} {...tooltipProps} disabled={!tooltipProps || isActive}>
      <TWButton
        type={'button'}
        withIcon
        variant={'outline'}
        onClick={onToggle}
        disabled={readonly}
        className={cn(
          'h-8 justify-start border-slate-200 py-0 pl-2 pr-3 text-left text-base font-normal',
          triggerClassName,
        )}
      >
        {!readonly && <Calendar className={'h-4 w-4 text-slate-600'} />}
        {!labelOverride && date && <div className={'date'}>{dateString}</div>}
        {!labelOverride && !date && <PlaceholderText italic>{t(placeholderToken)}</PlaceholderText>}
        {labelOverride && <div>{labelOverride}</div>}
      </TWButton>
    </Tooltip>
  );
}
