import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {
  ProjectRoleAssignmentOnNode,
  StoreNodeProjectRoleAssignmentEntry,
  StoreNodeProjectRoleAssignments,
} from '../../../EntityInterfaces/RoleAssignments/ProjectRoleAssignments';
import {FlowState} from '../../State';
import {
  getEffectiveRoleAssignmentsForNodeSelectorFactory,
  getEffectiveRoleAssignmentsForNodesSelectorFactory,
  getRoleAssignmentsLoadedOnceSelectorFactory,
  getRoleAssignmentsLoadedSelectorFactory,
  getRoleAssignmentsOnNodeSelectorFactory,
} from './NodeRoleAssignmentSelectors';

export const nodeProjectRoleAssignmentsSelector = (state: FlowState): StoreNodeProjectRoleAssignments =>
  state.roles.nodeProjectRoleAssignments;
export const nodeProjectRoleAssignmentsStateSelector = (state: FlowState): EntityStates =>
  state.roles.nodeProjectRoleAssignmentsStates;

export const nodeProjectRoleAssignmentEntrySelector = createSelector(
  nodeProjectRoleAssignmentsSelector,
  (assignments) => (nodeId: Uuid, entryId: Uuid) => assignments[nodeId]?.entries[entryId],
);

export const getProjectRoleAssignmentsLoadedSelector = getRoleAssignmentsLoadedSelectorFactory(
  nodeProjectRoleAssignmentsStateSelector,
);
export const getProjectRoleAssignmentsLoadedOnceSelector = getRoleAssignmentsLoadedOnceSelectorFactory(
  nodeProjectRoleAssignmentsStateSelector,
);

export const getProjectRoleAssignmentsOnNodeSelector = getRoleAssignmentsOnNodeSelectorFactory<
  StoreNodeProjectRoleAssignmentEntry,
  'entryId',
  ProjectRoleAssignmentOnNode
>(nodeProjectRoleAssignmentsSelector, 'entryId');

export const getEffectiveProjectRoleAssignmentsForNodeSelector = getEffectiveRoleAssignmentsForNodeSelectorFactory(
  getProjectRoleAssignmentsOnNodeSelector,
  EnumFlowRoleType.VALUE_PROJECT,
);

export const getEffectiveProjectRoleAssignmentsForNodesSelector = getEffectiveRoleAssignmentsForNodesSelectorFactory(
  getProjectRoleAssignmentsOnNodeSelector,
  EnumFlowRoleType.VALUE_PROJECT,
);
