import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateStr} from '@octaved/typescript';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {RootContainer, RootContainerProps} from '../RootContainer';
import {createStoreSubscription} from '../StoreSubscription';

export class OffDays extends RootContainer {
  constructor(props: RootContainerProps) {
    super(props);

    this.disposables.push(
      createStoreSubscription(this.ctx.store, getOrgWorkMinutesAtDateSelector, () => {
        this.onCalenderDatesChanged();
      }),
    );
  }

  onCalenderDatesChanged(): void {
    this.root.destroyChildren();
    this.#createOffDays();
  }

  #isOffDay(date: DateStr): boolean {
    return getOrgWorkMinutesAtDateSelector(this.ctx.store.getState())(date).project === 0;
  }

  #createOffDays(): void {
    const headerLineHeight = this.ctx.headerHeight / 2;
    const width = this.ctx.columnWidth;
    for (let i = 0; i <= this.ctx.calendarView.days; ++i) {
      const day = this.ctx.calendarView.dateStart.add(i, 'd');
      const isoDate = toIsoFormat(day);
      if (this.#isOffDay(isoDate)) {
        this.root.add(
          new Konva.Rect({
            width,
            fill: '#f8f8f8',
            height: this.ctx.canvasView.maxHeight,
            listening: false,
            offsetX: -0.5,
            perfectDrawEnabled: false,
            x: i * width,
            y: headerLineHeight,
          }),
        );
      }
    }
  }
}
