import {ActionDispatcher} from '@octaved/store/src/Store';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {
  BookedHoursByBillingTypeKeys,
  NonBillableHoursByReasonKeys,
} from '../../EntityInterfaces/Statistics/BookedHours';
import {FlowState} from '../State';
import {setUiState} from '../Ui';
import {DemoData, initDemoData} from './ReportingDemoData';

export enum ChartViews {
  absolute = 'absolute',
  percent = 'percent',
  curvedLines = 'curvedLines',
}

export interface StdReportingFilterState {
  customersFilter: Uuid[];
  includeArchived: boolean;
  projectAndOUFilter: null | Uuid;
  start: string;
}

export interface PageReportingFilterState {
  compare: boolean;
  filter: Omit<StdReportingFilterState, 'start'>;
  compareFilter: StdReportingFilterState;
  chartView: ChartViews;
}

export interface ReportingUiState {
  billingTypes: PageReportingFilterState & {
    breakdown: BookedHoursByBillingTypeKeys | null;
    showInternalNoCharge: boolean;
  };

  bookedTimes: PageReportingFilterState & {
    showDaysForBookingTimes: boolean;
  };

  chargeableQuota: PageReportingFilterState & {
    breakdownIndex: number | null;
  };

  demoData: DemoData;
  demoMode: boolean;

  noCharge: PageReportingFilterState & {
    breakdown: NonBillableHoursByReasonKeys | null;
    showInternalNoCharge: boolean;
  };

  roundingGain: PageReportingFilterState & {
    breakdownIndex: number | null;
  };
}

export const initialState: ReportingUiState = {
  billingTypes: {
    breakdown: null,
    chartView: ChartViews.absolute,
    compare: false,
    compareFilter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
      start: '',
    },
    filter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
    },
    showInternalNoCharge: false,
  },
  bookedTimes: {
    chartView: ChartViews.absolute,
    compare: false,
    compareFilter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
      start: '',
    },
    filter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
    },
    showDaysForBookingTimes: false,
  },
  chargeableQuota: {
    breakdownIndex: null,
    chartView: ChartViews.absolute,
    compare: false,
    compareFilter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
      start: '',
    },
    filter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
    },
  },
  demoData: {},
  demoMode: false,
  noCharge: {
    breakdown: null,
    chartView: ChartViews.absolute,
    compare: false,
    compareFilter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
      start: '',
    },
    filter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
    },
    showInternalNoCharge: false,
  },
  roundingGain: {
    breakdownIndex: null,
    chartView: ChartViews.absolute,
    compare: false,
    compareFilter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
      start: '',
    },
    filter: {
      customersFilter: [],
      includeArchived: false,
      projectAndOUFilter: null,
    },
  },
};

export function setReportingUiState(reporting: DeepPartial<ReportingUiState>): ActionDispatcher<void, FlowState> {
  return setUiState({pages: {reporting}});
}

initDemoData();
