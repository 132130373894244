import {createContext, ReactElement, ReactNode, useContext, useMemo} from 'react';

interface TaskListReadonlyContext {
  canManageTasks: boolean;
  readonly: boolean;
  readonlyOrNotManageable: boolean;
}

const defaultValue: TaskListReadonlyContext = {
  canManageTasks: false,
  readonly: true,
  readonlyOrNotManageable: true,
};
const context = createContext<TaskListReadonlyContext>(defaultValue);

export function useTaskListReadonlyContext(): TaskListReadonlyContext {
  return useContext(context);
}

export default function TaskListReadonlyContext({
  canManageTasks,
  children,
  readonly,
}: {
  children: ReactNode;
  canManageTasks: boolean;
  readonly: boolean;
}): ReactElement {
  const value = useMemo(
    () => ({canManageTasks, readonly, readonlyOrNotManageable: readonly || !canManageTasks}),
    [canManageTasks, readonly],
  );
  return <context.Provider value={value}>{children}</context.Provider>;
}

// noinspection FunctionNamingConventionJS
export function TaskListReadonlyReset({children}: {children: ReactNode}): ReactElement {
  const {canManageTasks} = useTaskListReadonlyContext();
  return (
    <TaskListReadonlyContext canManageTasks={canManageTasks} readonly>
      {children}
    </TaskListReadonlyContext>
  );
}
