import {CALL_API, ServerRequestAction, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {LOADED, LOADING, createTimestampReducer, filterIdsToReload} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  createPlanningBaseline as createPlanningBaselineRoute,
  getPlanningBaselineForNodes as getPlanningBaselineForNodesRoute,
  patchPlanningBaseline as patchPlanningBaselineRoute,
  removePlanningBaseline as removePlanningBaselineRoute,
} from '../../config/routes';
import {PlanningBaseline, PlanningBaselines} from '../EntityInterfaces/PlanningBaselines';
import {PlanningState} from '../PlanningState';
import {
  getNodeIdForPlanningBaselineSelector,
  planningBaselineEntityStatesSelector,
} from '../Selectors/PlanningBaselineSelectors';
import {getSelectedBaselineIdsSelector} from '../Selectors/UiSelectors';
import {
  FLOW_CREATE_PLANNING_BASELINE_FAILURE,
  FLOW_CREATE_PLANNING_BASELINE_REQUEST,
  FLOW_CREATE_PLANNING_BASELINE_SUCCESS,
  FLOW_GET_PLANNING_BASELINE_FAILURE,
  FLOW_GET_PLANNING_BASELINE_REQUEST,
  FLOW_GET_PLANNING_BASELINE_SUCCESS,
  FLOW_PATCH_PLANNING_BASELINE_FAILURE,
  FLOW_PATCH_PLANNING_BASELINE_REQUEST,
  FLOW_PATCH_PLANNING_BASELINE_SUCCESS,
  FLOW_REMOVE_PLANNING_BASELINE_FAILURE,
  FLOW_REMOVE_PLANNING_BASELINE_REQUEST,
  FLOW_REMOVE_PLANNING_BASELINE_SUCCESS,
} from './ActionTypes';
import {setBaselineSettingsForNode} from './Ui';

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_GET_PLANNING_BASELINE_REQUEST, createTimestampReducer('options.urlParams.ids', LOADING));
stateReducerMap.set(FLOW_GET_PLANNING_BASELINE_SUCCESS, createTimestampReducer('options.urlParams.ids', LOADED));
export const planningBaselinesEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion

//#region Reducer

const planningBaselineEntityReducers = createReducerCollection<PlanningBaselines>({});
planningBaselineEntityReducers.add(FLOW_GET_PLANNING_BASELINE_SUCCESS, (state, action: GetPlanningBaselineRequest) => {
  return {...state, ...action.response};
});
planningBaselineEntityReducers.add(
  FLOW_CREATE_PLANNING_BASELINE_REQUEST,
  (state, action: CreatePlanningBaselineRequest) => {
    const nodeId = action.options.data.nodeId;
    return {...state, [nodeId]: [...(state[nodeId] || []), action.options.data]};
  },
);
planningBaselineEntityReducers.add(
  FLOW_PATCH_PLANNING_BASELINE_REQUEST,
  (state, action: UpdatePlanningBaselineRequest) => {
    const nodeId = action.nodeId;

    return {
      ...state,
      [nodeId]: (state[nodeId] || []).map((baseline) =>
        baseline.id === action.options.urlParams.planningBaselineId ? {...baseline, ...action.options.data} : baseline,
      ),
    };
  },
);
planningBaselineEntityReducers.add(
  FLOW_REMOVE_PLANNING_BASELINE_REQUEST,
  (state, action: DeletePlanningBaselineRequest) => {
    const nodeId = action.nodeId;
    return {
      ...state,
      [nodeId]: (state[nodeId] || []).filter((baseline) => baseline.id !== action.options.urlParams.planningBaselineId),
    };
  },
);
export const planningBaselineReducer = planningBaselineEntityReducers.reducer;

//#endregion

type GetPlanningBaselineRequest = ServerResponseAction<PlanningBaselines>;
type CreatePlanningBaselineRequest = ServerRequestAction<{
  data: PlanningBaseline;
}>;
type UpdatePlanningBaselineRequest = ServerRequestAction<{
  data: Pick<PlanningBaseline, 'name'>;
  urlParams: {planningBaselineId: Uuid};
}> & {nodeId: Uuid};
type DeletePlanningBaselineRequest = ServerRequestAction<{
  urlParams: {planningBaselineId: Uuid};
}> & {nodeId: Uuid};

export function getPlanningBaseline(nodeIds: Uuid[]): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const ids = filterIdsToReload(planningBaselineEntityStatesSelector(getState()), nodeIds);

    if (ids.length === 0) {
      return;
    }
    dispatch({
      [CALL_API]: {
        endpoint: getPlanningBaselineForNodesRoute,
        method: 'get',
        options: {
          urlParams: {ids},
        },
        types: {
          failureType: FLOW_GET_PLANNING_BASELINE_FAILURE,
          requestType: FLOW_GET_PLANNING_BASELINE_REQUEST,
          successType: FLOW_GET_PLANNING_BASELINE_SUCCESS,
        },
      },
    });
  };
}

export function createPlanningBaseline(data: PlanningBaseline): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const selectedBaselines = getSelectedBaselineIdsSelector(getState())(data.nodeId);
    await dispatch({
      [CALL_API]: {
        endpoint: createPlanningBaselineRoute,
        method: 'put',
        options: {
          data,
        },
        types: {
          failureType: FLOW_CREATE_PLANNING_BASELINE_FAILURE,
          requestType: FLOW_CREATE_PLANNING_BASELINE_REQUEST,
          successType: FLOW_CREATE_PLANNING_BASELINE_SUCCESS,
        },
      },
    });
    await dispatch(
      setBaselineSettingsForNode(data.nodeId, {
        selectedBaselines: [...selectedBaselines, data.id],
      }),
    );
  };
}

export function updatePlanningBaseline(
  planningBaselineId: Uuid,
  data: Pick<PlanningBaseline, 'name'>,
): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const nodeId = getNodeIdForPlanningBaselineSelector(getState())(planningBaselineId);
    if (!nodeId) {
      return;
    }
    dispatch({
      nodeId,
      [CALL_API]: {
        endpoint: patchPlanningBaselineRoute,
        method: 'patch',
        options: {
          data,
          urlParams: {planningBaselineId},
        },
        types: {
          failureType: FLOW_PATCH_PLANNING_BASELINE_FAILURE,
          requestType: FLOW_PATCH_PLANNING_BASELINE_REQUEST,
          successType: FLOW_PATCH_PLANNING_BASELINE_SUCCESS,
        },
      },
    });
  };
}

export function removePlanningBaseline(planningBaselineId: Uuid): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const nodeId = getNodeIdForPlanningBaselineSelector(getState())(planningBaselineId);
    const selectedBaselines = getSelectedBaselineIdsSelector(getState())(nodeId);
    if (!nodeId) {
      return;
    }
    dispatch({
      nodeId,
      [CALL_API]: {
        endpoint: removePlanningBaselineRoute,
        method: 'del',
        options: {
          urlParams: {planningBaselineId},
        },
        types: {
          failureType: FLOW_REMOVE_PLANNING_BASELINE_FAILURE,
          requestType: FLOW_REMOVE_PLANNING_BASELINE_REQUEST,
          successType: FLOW_REMOVE_PLANNING_BASELINE_SUCCESS,
        },
      },
    });
    await dispatch(
      setBaselineSettingsForNode(nodeId, {
        selectedBaselines: selectedBaselines.filter((id) => id !== planningBaselineId),
      }),
    );
  };
}
