import {anyNode, useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {IconButton, Tooltip} from '@octaved/ui';
import {ListChecks} from 'lucide-react';
import {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useProjectTreeTaskListContext} from '../../Components/ProjectTree/ProjectTreeTaskListContext';
import {workPackageOptionsUiSelector} from '../../Modules/Selectors/UiSelectors';
import {setUiState} from '../../Modules/Ui';

export default function ShowTasksToggle(): ReactElement | null {
  const {canUseTasksView} = useProjectTreeTaskListContext();
  const {showTasks} = useSelector(workPackageOptionsUiSelector);
  const canReadTasks = useIsGranted('FLOW_NODE_TASK_READ_BASIC', anyNode);
  const dispatch = useDispatch();

  if (!canUseTasksView || !canReadTasks) {
    return null;
  }
  return (
    <Tooltip toolTipTranslation={'workPackage:displayOptions.tasks'} position={'bottom center'}>
      <IconButton
        variant={'ghost'}
        isUnderlined={showTasks}
        isActive={showTasks}
        icon={<ListChecks />}
        size={'sm'}
        onClick={() => dispatch(setUiState({workpackageView: {showTasks: !showTasks}}))}
      />
    </Tooltip>
  );
}
