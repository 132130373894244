import dayjs from 'dayjs';
import {useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {dateFormatSelector, timeFormatSelector} from '../../Selectors/CurrentOrgUserSelectors';
import {formatInWords, FormatOptions, FormatTypes, LocaleOptions} from '../DateFormatFunctions';

interface UseFormatInWordsProps {
  options?: FormatOptions;
  type?: FormatTypes;
}

interface UseFormatInWordsPropsWithType extends UseFormatInWordsProps {
  type: FormatTypes;
}

interface UseFormatInWordsPropsWithOutType extends UseFormatInWordsProps {
  type?: never;
}

/**
 * The overloads make it possible to pre-define the date-type in the hook so that the returned callback won't need
 * this argument anymore.
 */

export function useFormatInWords(): (
  date: dayjs.Dayjs | number,
  type: FormatTypes,
  options?: FormatOptions,
  localeOverideOptions?: Partial<LocaleOptions>,
) => string;
export function useFormatInWords<T extends UseFormatInWordsPropsWithType>(
  hook: T,
): (
  date: dayjs.Dayjs | number,
  type?: FormatTypes,
  options?: FormatOptions,
  localeOverideOptions?: Partial<LocaleOptions>,
) => string;
export function useFormatInWords<T extends UseFormatInWordsPropsWithOutType>(
  hook: T,
): (
  date: dayjs.Dayjs | number,
  type: FormatTypes,
  options?: FormatOptions,
  localeOverideOptions?: Partial<LocaleOptions>,
) => string;
export function useFormatInWords<T extends UseFormatInWordsProps>(
  hook?: T | undefined,
): (
  date: dayjs.Dayjs | number,
  type?: FormatTypes,
  options?: FormatOptions,
  localeOverideOptions?: Partial<LocaleOptions>,
) => string {
  const {t} = useTranslation();
  const hookRef = useRef(hook);
  hookRef.current = hook;
  const dateFormat = useSelector(dateFormatSelector);
  const timeFormat = useSelector(timeFormatSelector);
  return useCallback(
    (
      date,
      type = hookRef.current?.type,
      options = hookRef.current?.options,
      localeOverideOptions?: Partial<LocaleOptions>,
    ): string => {
      return formatInWords(t, date, type!, options, {dateFormat, timeFormat, ...localeOverideOptions});
    },
    [dateFormat, t, timeFormat],
  );
}
