import {restorableNodeCountsReducer, restorableNodeCountsStateReducer} from './RestorableNodeCounts';
import {restorableNodesReducer, restorableNodesStateReducer} from './RestorableNodes';
import {restorableTimeRecordsReducer, restorableTimeRecordsStateReducer} from './RestorableTimeRecords';

export const restoreFromTrashReducer = {
  nodeCounts: {
    results: restorableNodeCountsReducer,
    state: restorableNodeCountsStateReducer,
  },
  nodes: {
    results: restorableNodesReducer,
    state: restorableNodesStateReducer,
  },
  timeRecords: {
    results: restorableTimeRecordsReducer,
    state: restorableTimeRecordsStateReducer,
  },
};
