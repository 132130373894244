import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import type {FieldCondition} from '@octaved/store/src/Notifications';
import {Notification} from '@octaved/store/src/Notifications';
import {IconButton} from '@octaved/ui';
import FormErrors from '@octaved/ui-components/src/React/Form/FormErrors';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {CheckCircle} from 'lucide-react';
import {Children, ReactElement, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {useUnsetErrorsOnLeave} from '../../../Hooks/UnsetErrorsOnLeave';
import {errorsSelector} from '../../../Modules/Selectors/UiSelectors';
import {FlowState} from '../../../Modules/State';

interface Props {
  children: ReactNode[] | ReactNode;
  onSubmit?: (() => void) | (() => Promise<void>);
  submitLabel?: string;
  submitIcon?: ReactElement;
  noSubmit?: boolean;
  includeErrorFields: FieldCondition[];
  errorSelector?: (state: FlowState) => Notification[];
  noTopSpacing?: boolean;
}

export default function VerticalForm({
  children,
  onSubmit = () => false,
  submitLabel = '',
  noSubmit = false,
  includeErrorFields,
  submitIcon = <CheckCircle />,
  errorSelector = errorsSelector,
  noTopSpacing = false,
}: Props): ReactElement {
  const errors = useSelector(errorSelector);
  useUnsetErrorsOnLeave(includeErrorFields || []);
  const [submit, isSubmitting] = useAsyncExecution(onSubmit);
  const childFragments = Children.toArray(children);
  return (
    <>
      {!noTopSpacing && <Spacer />}
      {errors.length > 0 && (
        <>
          <FormErrors errors={errors} includeFields={includeErrorFields} />
          <Spacer />
        </>
      )}

      {childFragments.map((field, index) => (
        <div className={'formField'} key={index}>
          {field}
        </div>
      ))}

      {!noSubmit && (
        <>
          <Spacer spacing={30} />

          <div className={'submit'}>
            <IconButton
              colorScheme={'add'}
              icon={submitIcon}
              isDisabled={isSubmitting}
              label={submitLabel}
              loading={isSubmitting}
              onClick={submit}
            />
          </div>
        </>
      )}

      {/*language=SCSS*/}
      <style jsx>{`
        .formField {
          width: 100%;
          margin-bottom: 35px;
        }

        .submit {
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      `}</style>
    </>
  );
}
