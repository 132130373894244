import {error} from '@octaved/env/src/Logger';

/**
 * Default depth for preloading
 */
const DEFAULT_DEPTH = 2;

export type IdsToParentIdsMap<T> = Map<T, T | null>;
export type ParentToChildIdsMap<T> = Map<T | null, T[]>;

export interface IdsToParentIdsList<T> {
  [x: string]: T | null;
}

export type IdsToParentIds<idType> = IdsToParentIdsList<idType> | IdsToParentIdsMap<idType>;

type FilteredSet<T> = Set<T> | null;
type IsParentMap<T> = Map<T | null, boolean>;

function canAddSiblingOrChild<idType>(
  filteredSet: FilteredSet<idType>,
  isParentMap: IsParentMap<idType>,
): (id: idType) => boolean {
  return (id: idType): boolean => !filteredSet || filteredSet.has(id) || !!isParentMap.get(id);
}

export function addChildren<idType>(
  parentToChildIds: ParentToChildIdsMap<idType>,
  filteredSet: FilteredSet<idType>,
  isParentMap: IsParentMap<idType>,
  setToFill: Set<idType>,
  depth: number | null = DEFAULT_DEPTH,
): (level: number) => (nodeId: idType) => void {
  const canAdd = canAddSiblingOrChild<idType>(filteredSet, isParentMap);
  return (level: number) => (nodeId: idType) => {
    const childIds = parentToChildIds.get(nodeId) || [];
    childIds.forEach((childId) => {
      if (canAdd(childId)) {
        setToFill.add(childId);
        if (depth === null || level < depth - 1) {
          addChildren(parentToChildIds, filteredSet, isParentMap, setToFill, depth)(level + 1)(childId);
        }
      }
    });
  };
}

/**
 * @type {Map<IdsToParentIdsList, IdsToParentIdsMap>}
 */
const cacheIdsToParentIdsListToMap = new WeakMap();

/**
 * Converts a list of ids to parent ids into a map, casting the ids to integers if needed.
 * The converted map is cached to allow quick return of the map.
 */
export function requireIdsToParentIdsMap<idType>(idsToParentIds: IdsToParentIds<idType>): IdsToParentIdsMap<idType> {
  if (idsToParentIds instanceof Map) {
    return idsToParentIds;
  }
  if (!cacheIdsToParentIdsListToMap.has(idsToParentIds)) {
    //Object keys are always strings, but in the map we want pure integers if the ids are integers:
    const idCastFn = (id: string): string | number => (id.length !== 32 && /^\d+$/.test(id) ? +id : id);
    const map: IdsToParentIdsMap<idType> = new Map();
    Object.keys(idsToParentIds).forEach((id: string) => {
      const childId = idCastFn(id) as unknown as idType;
      const parentId = idsToParentIds[id];
      if (childId === parentId) {
        error('Child points to itself as parent', {childId, idsToParentIds});
      } else {
        map.set(childId, parentId);
      }
    });
    cacheIdsToParentIdsListToMap.set(idsToParentIds, map);
  }
  return cacheIdsToParentIdsListToMap.get(idsToParentIds);
}

/**
 * @type {Map<IdsToParentIdsMap, ParentToChildIdsMap>}
 */
const cacheParentsToChildrenMap = new WeakMap();

/**
 * Flips the object of ids to parent ids into a map of parent ids to children ids
 */
export function getParentsToChildrenMap<idType>(idsToParentIds: IdsToParentIds<idType>): ParentToChildIdsMap<idType> {
  const idsToParentIdsMap = requireIdsToParentIdsMap(idsToParentIds);
  let parentToChildIds = cacheParentsToChildrenMap.get(idsToParentIdsMap);
  if (!parentToChildIds) {
    parentToChildIds = new Map();
    idsToParentIdsMap.forEach((parentId, id) => {
      let children = parentToChildIds.get(parentId);
      if (!children) {
        children = [];
        parentToChildIds.set(parentId, children);
      }
      children.push(id);
    });
    cacheParentsToChildrenMap.set(idsToParentIdsMap, parentToChildIds);
  }
  return parentToChildIds;
}

const ancestryCache = new WeakMap<IdsToParentIdsMap<string>, Map<string, Set<string>>>();

function getCachedAncestry<idType>(
  type: 'ancestors' | 'descendants',
  build: (map: IdsToParentIdsMap<idType>) => Set<idType>,
  idsToParentIds: IdsToParentIds<idType>,
  nodeId: idType,
  includeSelf = false,
): Set<idType> {
  if (typeof nodeId !== 'number' && typeof nodeId !== 'string') {
    throw new Error(`Invalid rootId type '${typeof nodeId}'`);
  }
  const idsToParentIdsMap = requireIdsToParentIdsMap(idsToParentIds);

  let cache = ancestryCache.get(idsToParentIdsMap as IdsToParentIdsMap<string>);
  if (!cache) {
    cache = new Map();
    ancestryCache.set(idsToParentIdsMap as IdsToParentIdsMap<string>, cache);
  }

  const cacheKey = `${type}:${nodeId}:${+includeSelf}`;
  let cached = cache.get(cacheKey) as Set<idType> | undefined;
  if (!cached) {
    cached = build(idsToParentIdsMap);
    cache.set(cacheKey, cached as Set<string>);
  }

  return cached as Set<idType>;
}

export function getAllDescendantsForRootId<idType>(
  idsToParentIds: IdsToParentIds<idType>,
  rootId: idType,
  includeSelf = false,
): Set<idType> {
  return getCachedAncestry(
    'descendants',
    (map) => {
      const parentsToChildrenMap = getParentsToChildrenMap(map);
      const descendants = new Set<idType>();
      if (includeSelf) {
        descendants.add(rootId);
      }
      addChildren(parentsToChildrenMap, null, new Map(), descendants, null)(0)(rootId);
      return descendants;
    },
    idsToParentIds,
    rootId,
    includeSelf,
  );
}

export function getAllDescendantsForRootIds<idType>(
  idsToParentIds: IdsToParentIds<idType>,
  rootIds: ReadonlyArray<idType>,
  includeSelf = false,
): Set<idType> {
  return new Set<idType>(
    rootIds.reduce(
      (acc: ReadonlyArray<idType>, rootId: idType) => [
        ...acc,
        ...getAllDescendantsForRootId<idType>(idsToParentIds, rootId, includeSelf),
      ],
      [],
    ),
  );
}

export function getAllAncestorsForNodeId<idType>(
  idsToParentIds: IdsToParentIds<idType>,
  nodeId: idType,
  includeSelf = false,
): Set<idType> {
  return getCachedAncestry(
    'ancestors',
    (map) => {
      const set = new Set<idType>();
      if (includeSelf) {
        set.add(nodeId);
      }
      let curNodeId = map.get(nodeId);
      while (curNodeId) {
        set.add(curNodeId);
        curNodeId = map.get(curNodeId);
      }
      return set;
    },
    idsToParentIds,
    nodeId,
    includeSelf,
  );
}

export function getAllAncestorsForNodeIds<idType>(
  idsToParentIds: IdsToParentIds<idType>,
  nodeIds: ReadonlyArray<idType>,
  includeSelf = false,
): Set<idType> {
  return nodeIds.reduce<Set<idType>>((acc, id) => {
    const idAncestors = getAllAncestorsForNodeId(idsToParentIds, id, includeSelf);
    idAncestors.forEach((anc) => acc.add(anc));
    return acc;
  }, new Set());
}
