import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {DateStr} from '@octaved/typescript';

export type LicenseLevel = 'trial' | 'starter' | 'professional' | 'business' | 'enterprise' | 'onPremise';
export type LicenseFeature = 'apiUsage';

export interface OrgUserCount {
  activePerMonth: Record<string, number | undefined>;
  active: number;
  total: number;
}

export type OrgUserCounts = Record<Exclude<UserType, 'system'>, OrgUserCount>;

interface BaseLicense {
  features: Record<LicenseFeature, boolean>;
  level: LicenseLevel;
  trialDuration: number; //in days
}

//Available for everyone allowed to see the user lists (FLOW_GLOBAL_USER_LISTS_READ)
interface UserListLicense {
  orgUserCounts: OrgUserCounts;
}

//Available for user admins (FLOW_GLOBAL_INTERNAL_USERS_MANAGE || FLOW_GLOBAL_GUEST_USERS_MANAGE)
interface UserAdminLicense extends UserListLicense {
  apiUserLimit: number;
  dummyUserLimit: number;
  guestUserCustomerDynamic: boolean;
  guestUserCustomerLimit: number;
  guestUserOtherLimit: number;
  lightUserLimit: number;
  userLimit: number;
}

//Available for admins allowed to see the license page (FLOW_GLOBAL_LICENSE_READ)
interface LicenseAdminLicense extends UserAdminLicense {
  contactFormEnabled: boolean;
  fileStorageLimitOverride: number | null; // in GB
  renewalDay: number; //1 = 1st of month
  renewalEndsOn: DateStr | null;
  renewalMonth: number; //1 = January
  renewalType: 'yearly' | 'monthly';
}

//Only the base license is exported to all users - the rest depends on permissions:
export type License = BaseLicense & Partial<LicenseAdminLicense>;
export type FullLicense = BaseLicense & LicenseAdminLicense;

export function isUserAdminLicense(license: License): license is BaseLicense & UserAdminLicense {
  return 'userLimit' in license;
}

export function isFullLicense(license: License): license is FullLicense {
  return 'contactFormEnabled' in license;
}
