import classNames from 'classnames';
import {ReactElement} from 'react';

interface Props {
  breakAll?: boolean;
  text: string;
}

export default function MultiLineTextString({breakAll, text}: Props): ReactElement {
  return (
    <span className={classNames('text', {breakAll})}>
      {text}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .text {
          white-space: pre-line;
        }

        .breakAll {
          word-break: break-all;
        }
      `}</style>
      {/*#endregion*/}
    </span>
  );
}
