import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {ReactElement} from 'react';

export default function FullContainerLoader(): ReactElement {
  return (
    <div className={'loadingWrapper'}>
      <AppLoader />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .loadingWrapper {
          display: flex;
          align-items: stretch;
          flex-grow: 1;
          flex-direction: column;
          height: 100%;
          width: 100%;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
