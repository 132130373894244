import {isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback, useMemo} from 'react';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import DependencyDialog from '../../AddDependencyModal/DependencyModal';
import {PlanningType} from './PlanningType';

interface DependencySelectorModalProps {
  node: PlanningRealizationNode;
  planningType: PlanningType;
  onConfirm(predecessor: Uuid): void;
  onCancel(): void;
}

export default function DependencySelectorModal({
  node,
  onCancel,
  onConfirm,
  planningType,
}: DependencySelectorModalProps): ReactElement {
  const onSelect = useCallback(
    (predecessor: Uuid | null) => {
      if (predecessor) {
        onConfirm(predecessor);
        return true;
      }
      return false;
    },
    [onConfirm],
  );

  const excludedNodeIds = useMemo<Set<Uuid>>(() => {
    if (planningType === PlanningType.dependency) {
      return new Set([node.id, ...node.planningPredecessors]);
    }
    return new Set([node.id]);
  }, [node.id, node.planningPredecessors, planningType]);

  return (
    <DependencyDialog
      dialogInfo={
        isWorkPackage(node)
          ? 'pages:projects.inspector.manage.planning.newDependencyTimeBasedInfoForWorkpackages'
          : 'pages:projects.inspector.manage.planning.newDependencyTimeBasedInfoForTasks'
      }
      helpInfo={
        isWorkPackage(node)
          ? 'pages:projects.inspector.manage.planning.newDependencyHelpForWorkpackages'
          : 'pages:projects.inspector.manage.planning.newDependencyHelpForTasks'
      }
      excludedNodeIds={excludedNodeIds}
      dialogHeaderToken={'pages:projects.inspector.manage.planning.newDependency'}
      node={node}
      onSelect={onSelect}
      plannedOnly
      dependencyType={'planning'}
      onClose={onCancel}
    />
  );
}
