import CaretDown from '@fortawesome/fontawesome-pro/svgs/solid/caret-down.svg';
import CaretRight from '@fortawesome/fontawesome-pro/svgs/solid/caret-right.svg';
import {HStack, IconButton, NodeIcon} from '@octaved/ui';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import classNames from 'classnames';
import {MutableRefObject, ReactElement, ReactNode, useEffect, useMemo, useRef} from 'react';
import {Popup} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {useNodeBackground} from '../../../Modules/Hooks/Nodes';
import {useShowTaskGroup} from '../../../Modules/Ui/TaskList';
import NodeName from '../../Node/NodeName';
import WpOrSwpTile from '../../WorkPackage/Tile/WpOrSwpTile';
import {useTaskListContext} from '../TaskListContext';
import {TaskGroupContext, dummyRef, taskGroupContext, useTaskGroupContext} from './TaskGroupContext';
import WorkPackageDetails from './WpOrSwpDetails';
import {ContextMenu} from '@octaved/node-details/src/Components/ContextMenu/ContextMenu';
import {ContextMenuTrigger} from '@octaved/node-details/src/Components/ContextMenu/ContextMenuTrigger';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .ui.popup {
    padding: 0;
    background: none;
    border: none;
  }
`;

//#endregion

interface Props {
  children?: ReactNode;
  dropRef?: MutableRefObject<HTMLDivElement | null>;
  node: WorkPackage | SubWorkPackage;
  select?: () => void;
  selected?: boolean;
}

export default function WpOrSwpTaskGroup({children, dropRef, node, select, selected}: Props): ReactElement {
  const {id} = node;
  const {focusNewTaskRefs} = useTaskListContext();
  const {focusNewTaskSection: parentFocusNewTaskSection} = useTaskGroupContext();
  const {show, toggle} = useShowTaskGroup(id);
  const focusNewTask = useRef<() => void>();
  const focusNewTaskSection = useRef<() => void>();
  const ctx = useMemo(
    (): TaskGroupContext => ({
      focusNewTask,
      id,
      focusNewTaskSection: parentFocusNewTaskSection !== dummyRef ? parentFocusNewTaskSection : focusNewTaskSection,
    }),
    [id, parentFocusNewTaskSection],
  );
  const nodeBackgroundStyle = useNodeBackground(node.id);

  useEffect(() => {
    focusNewTaskRefs[id] = focusNewTask;
    return () => {
      if (focusNewTaskRefs[id] === focusNewTask) {
        focusNewTaskRefs[id] = undefined;
      }
    };
  }, [focusNewTaskRefs, id]);

  return (
    <taskGroupContext.Provider value={ctx}>
      <div className={classNames('taskGroup', {selected})}>
        <div className={'absolute -left-1 bottom-0 top-0 w-1 rounded-l-[4px]'} style={nodeBackgroundStyle} />
        <div
          className={classNames('groupHeader', {expanded: !show, selectable: typeof select === 'function'})}
          ref={dropRef}
          onClick={select}
        >
          <HStack spacing={'20px'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <HStack spacing={'4px'} alignItems={'center'}>
              <IconButton variant={'ghost'} icon={show ? <CaretDown /> : <CaretRight />} size={'xs'} onClick={toggle} />
              <Popup
                className={classNames(className, 'tooltip')}
                pinned
                size={'mini'}
                on={'hover'}
                trigger={
                  <div className={'name'}>
                    <NodeIcon nodeType={node.nodeType} className={'-mt-0.5 mr-1 inline'} />
                    <NodeName nodeId={id} />
                  </div>
                }
                mouseEnterDelay={750}
                wide
              >
                <WpOrSwpTile node={node} />
              </Popup>

              <div className={'headerOptions'}>
                <ContextMenu nodeId={id}>
                  <ContextMenuTrigger />
                </ContextMenu>
              </div>
            </HStack>
            <WorkPackageDetails node={node} />
          </HStack>
        </div>

        <SlideToggleElement visible={show}>
          <div className={'contents'}>{children}</div>
        </SlideToggleElement>
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .taskGroup {
          border-radius: 4px;
          background-color: #fff;
          border: 1px solid #cccccc;
          border-left: 4px solid #cccccc;
          position: relative;
        }

        .taskGroup.isDragging {
          opacity: 0.2;
        }

        .taskGroup.selected {
          background-color: var(--color-bg-selected, #edf5fb);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);

          .groupHeader {
            background-color: var(--color-bg-selected, #edf5fb);
          }
        }

        .groupHeader {
          display: flex;
          grid-gap: 4px;
          align-items: center;
          line-height: 16px;
          border-radius: 4px;
          background-color: #fff;
          padding: 6px 8px;
          transition: border-radius 170ms ease-in-out;

          .alignTop & {
            align-items: flex-start;
          }

          &:hover .headerOptions {
            visibility: visible;
            pointer-events: all;
          }

          &:not(.expanded) {
            border-radius: 4px 4px 0 0;
            border-bottom: 1px solid #ccc;
          }

          &.selectable {
            cursor: pointer;
          }
        }

        .name {
          line-height: 16px;
          color: #333;
          font-weight: 500;
        }

        .headerOptions {
          visibility: hidden;
          pointer-events: none;
        }

        .contents {
          border-radius: 0 0 4px 4px;
        }

        .planning {
          font-size: 12px;
          line-height: 16px;
          color: var(--color-font-workpackage-minorDetails, #888);
        }
      `}</style>
      {styles}
      {/*#endregion*/}
    </taskGroupContext.Provider>
  );
}
