import {Icon} from '@octaved/ui';
import {ChevronDown, ChevronRight} from 'lucide-react';
import {ReactElement} from 'react';
import {useDispatch} from 'react-redux';
import {CellProps} from 'react-table';
import {loadNextAuditPage} from '../../../../../Modules/Audit';
import {useAuditBaseSearchParams, useSelectedAuditTable} from '../../../../../Modules/Selectors/AuditSelectors';
import {PrepareTableData} from '../PrepareTableRows';

export default function FirstCell({row, cell: {value}}: CellProps<PrepareTableData>): ReactElement | string | null {
  const tableName = useSelectedAuditTable();
  const searchParams = useAuditBaseSearchParams();
  const dispatch = useDispatch();
  return (
    <div className={'firstCell'}>
      {row.canExpand && (
        <span
          onClick={(e) => {
            dispatch(loadNextAuditPage(tableName, {id: row.original.id, ...searchParams}));
            e.stopPropagation();
          }}
        >
          <span {...row.getToggleRowExpandedProps()} title={''}>
            {!row.isExpanded && (
              <Icon size={'large'} noMargin>
                <ChevronRight />
              </Icon>
            )}
            {row.isExpanded && (
              <Icon size={'large'} noMargin>
                <ChevronDown />
              </Icon>
            )}
          </span>
        </span>
      )}
      {value}
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .firstCell {
          display: flex;
          align-items: center;
          grid-gap: 0.25rem;
          padding-left: ${row.depth * 1.2}em;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
