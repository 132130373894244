import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import Close from '@octaved/flow/src/Dialogs/Components/Close';
import {HelpCircleIcon, LucideIcon} from 'lucide-react';
import {ReactElement, ReactNode, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {PopupProps} from 'semantic-ui-react';
import {IconButton, IconButtonProps} from '../../Form';
import {cn} from '../../Hooks';
import {Popup} from '../../Motion';
import {StopClickPropagation} from '../../Util';
import HelpLink, {HelpLinkProps} from './HelpLink';

interface Props extends PopupProps {
  content?: ReactNode;
  contentClassName?: string;
  helpLinks?: HelpLinkProps[];
  icon?: LucideIcon;
  iconButtonInline?: boolean;
  iconButtonSize?: IconButtonProps['size'];
  text?: string;
  title?: string;
}

export function HelpPopup({
  content,
  contentClassName,
  helpLinks = [],
  icon: Icon = HelpCircleIcon,
  iconButtonInline,
  iconButtonSize = 'sm',
  text,
  title = 'general:help',
  ...props
}: Props): ReactElement {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  return (
    <Popup
      open={open}
      setOpen={setOpen}
      basic
      hideOnScroll
      trigger={
        <div className={cn(iconButtonInline && 'inline')}>
          <StopClickPropagation>
            <IconButton
              iconColor={'darkGrey'}
              icon={<Icon />}
              size={iconButtonSize}
              variant={'ghost'}
              onClick={() => setOpen(true)}
            />
          </StopClickPropagation>
        </div>
      }
      {...props}
    >
      <div className={cn('relative max-h-[40vh] max-w-lg overflow-auto px-6 py-4', contentClassName)}>
        <div className={'absolute right-1 top-1'}>
          <StopClickPropagation>
            <Close close={() => setOpen(false)} />
          </StopClickPropagation>
        </div>
        <DialogAndDrawerHeader header={t(title)} className={'mt-0'} />

        <div className={'flex flex-col gap-y-2'}>
          {text && (
            <div>
              <Trans
                i18nKey={text}
                components={{
                  b: <b />,
                  br: <br />,
                  i: <i />,
                  li: <li />,
                  ul: <ul className={'my-3 list-disc pl-5'} />,
                }}
              />
            </div>
          )}
          {content && <div>{content}</div>}
          {helpLinks.length === 1 && <HelpLink {...helpLinks[0]} />}
          {helpLinks.length > 1 && (
            <ul className={'my-3 list-disc pl-5'}>
              {helpLinks.map((helpLink) => (
                <li key={helpLink.routeIdent}>
                  <HelpLink {...helpLink} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Popup>
  );
}
