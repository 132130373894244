import {useGroupPatch} from '@octaved/flow/src/Modules/Groups';
import ShowMovePlanningButton from '@octaved/planning/src/Dialogs/MovePlanning/ShowMovePlanningButton';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import GroupBacklog from '../../../Components/Fields/Planning/NodeType/Group/Common/GroupBacklog';
import GroupPlanningSection from '../../../Components/Fields/Planning/NodeType/Group/Section/GroupPlanningSection';
import GroupSprintSection from '../../../Components/Fields/Planning/NodeType/Group/Section/GroupSprintSection';
import NodeDueDateSection from '../../../Components/Fields/Planning/Section/NodeDueDateSection';
import NodeMilestonesSection from '../../../Components/Fields/Planning/Section/NodeMilestonesSection';
import NodeSubProjectsDueDatesSection from '../../../Components/Fields/Planning/Section/NodeSubProjectsDueDatesSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {GroupDetailsProps} from './General';

export default function Planning({node}: GroupDetailsProps): ReactElement {
  const patch = useGroupPatch(node.id);
  const readonly = !useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) || node.isArchived;
  return (
    <InspectorViewFrame
      footer={
        <div className={'flex justify-center gap-x-2'}>
          <ShowMovePlanningButton node={node} />
        </div>
      }
    >
      <GroupBacklog nodeId={node.id} />
      <GroupSprintSection node={node} readonly={readonly} />
      <NodeDueDateSection node={node} readonly={readonly} onChangeDueDate={(dueDate) => patch({dueDate})} />
      <GroupPlanningSection nodeId={node.id} noLabel />
      <NodeSubProjectsDueDatesSection nodeId={node.id} />
      <NodeMilestonesSection nodeId={node.id} readonly={readonly} />
    </InspectorViewFrame>
  );
}
