import {PropsWithChildren, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {projectControllingDemoModeSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';

interface Props {
  demoModePage: ReactElement;
}

export default function ProjectControllingDemoModeWrapper({
  children,
  demoModePage,
}: PropsWithChildren<Props>): ReactElement {
  const demoMode = useSelector(projectControllingDemoModeSelector);
  return <>{demoMode && demoModePage ? demoModePage : children}</>;
}
