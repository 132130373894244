import {warning} from '@octaved/env/src/Logger';
import {AnyObject, DeepPartial, DeepPartialObject} from '@octaved/typescript/src/lib';
import isPlainObject from 'lodash/isPlainObject';
import {validateObject} from './Validation';

/**
 * Returns TRUE if haystack contains the same data as needle does
 *
 * Basically this is a partial compare.
 */
export default function objectContains<TNeedle extends AnyObject, THaystack extends TNeedle = TNeedle>(
  haystack: THaystack,
  needle: DeepPartial<THaystack> | DeepPartialObject<THaystack>,
  fullMatchArray = true,
  arrayLengthMustBeEqual = false,
): boolean {
  validateObject(haystack);
  validateObject(needle);
  return Object.keys(needle).every((key: keyof TNeedle) => {
    const haystackValue = haystack[key];
    const needleValue = needle[key];
    if (needleValue === haystackValue) {
      return true;
    }
    if (isPlainObject(haystackValue) && isPlainObject(needleValue)) {
      return objectContains(haystackValue, needleValue as DeepPartial<TNeedle[keyof TNeedle]>, fullMatchArray);
    }
    if (Array.isArray(needleValue) && Array.isArray(haystackValue)) {
      if (fullMatchArray) {
        return false;
      }
      if (arrayLengthMustBeEqual && needleValue.length !== haystackValue.length) {
        return false;
      }
      for (const needlArrayeValue of needleValue as unknown[]) {
        if (isPlainObject(needlArrayeValue) || Array.isArray(needlArrayeValue)) {
          warning('objectContains() does currently not support complex array values.', needleValue);
        }
        if (!haystackValue.includes(needlArrayeValue)) {
          return false;
        }
      }
      return true;
    }
    return false;
  });
}
