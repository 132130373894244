import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  WorkPackageSingleSelectLeftDrawerUiState,
  workPackageSingleSelectLeftDrawerUiState,
} from '../../Pages/Components/LeftDrawer/ItemTypes/WorkPackage/WorkPackageLeftDrawer';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState, UnitFilterUIState} from '../Ui';

export interface OrganizeBoardsWorkPackagesUiState {
  leftDrawer: WorkPackageSingleSelectLeftDrawerUiState;
  selectedKanbanBoard: Uuid | null;
  selectedUnits: UnitFilterUIState[];
}

export const initialOrganizeBoardsWorkPackagesUiState: OrganizeBoardsWorkPackagesUiState = {
  leftDrawer: workPackageSingleSelectLeftDrawerUiState,
  selectedKanbanBoard: null,
  selectedUnits: [],
};

export function patchOrganizeBoardsWorkPackagesUiState(
  organizeBoardsWorkPackages: DeepPartial<OrganizeBoardsWorkPackagesUiState>,
): ThunkAct<void> {
  return setUiState({pages: {organizeBoardsWorkPackages}});
}

export function setOrganizeBoardsWorkPackagesKanbanBoard(selectedKanbanBoard: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsWorkPackagesUiState({selectedKanbanBoard});
}

export function usePatchOrganizeBoardsWorkPackagesUiState(): (
  patch: DeepPartial<OrganizeBoardsWorkPackagesUiState>,
) => void {
  const dispatch = useDispatch();
  return useCallback((patch) => dispatch(patchOrganizeBoardsWorkPackagesUiState(patch)), [dispatch]);
}

export function patchOrganizeBoardsWorkPackagesLeftDrawerUiState(
  patch: Partial<WorkPackageSingleSelectLeftDrawerUiState>,
): ThunkAct<void> {
  return patchOrganizeBoardsWorkPackagesUiState({leftDrawer: patch});
}

export function setOrganizeBoardsWorkPackagesSelectedPid(selected: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsWorkPackagesLeftDrawerUiState({selected});
}
