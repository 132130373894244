import classNames from 'classnames';
import {ReactElement} from 'react';
import css from 'styled-jsx/css';
import {transformEffort} from '../../../Modules/Hooks/EffortTransformer';
import ValidationInputField from '../../Form/Input/ValidationInputField';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .wrapper {
    width: 75px;
  }

  .semanticInput > :global(input) {
    text-align: right;
  }
`;

//#endregion

interface Props {
  fieldName: string;
  maxEffort: string | null;
  readonly?: boolean;
  setMaxEffort: (maxEffort: string | null) => void;
  onBlur?: () => void;
}

export default function MaxEffortField({fieldName, maxEffort, readonly, setMaxEffort, onBlur}: Props): ReactElement {
  return (
    <div className={classNames('wrapper', className)}>
      <ValidationInputField
        className={classNames('semanticInput', className)}
        value={maxEffort || ''}
        disabled={readonly}
        setValue={(val) => {
          let effort = null;
          if (val) {
            effort = transformEffort(val);
            if (!effort.match(/^(\d+)$/)) {
              //allow only numbers
              return;
            }
          }
          setMaxEffort(effort);
        }}
        fieldName={fieldName}
        onBlur={onBlur}
      />
      {styles}
    </div>
  );
}
