import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import isPlainObject from 'lodash/isPlainObject';
import {createSelector} from 'reselect';
import {
  Customer,
  CustomerLocation,
  Customers,
  PriceCategoryOverrides,
  PriceSurchargeOverrides,
} from '../../EntityInterfaces/Customers';
import {FlowState} from '../State';
import {isPid} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector} from './NodeSelectors';
import {getParentPidSelector} from './PidSelectors';

export function isCustomer(item: unknown): item is Customer {
  return (
    isPlainObject(item) && (item as object).hasOwnProperty('id') && (item as object).hasOwnProperty('customerNumber')
  );
}

export function formatCustomer(customer: Customer): string {
  if (customer.name) {
    return customer.name + (customer.customerNumber ? ` (${customer.customerNumber})` : '');
  }
  if (customer.customerNumber) {
    return customer.customerNumber;
  }
  return customer.id;
}

export function formatCustomerLocation(location: CustomerLocation): string {
  if (location.name) {
    return location.name + (location.number ? ` (${location.number})` : '');
  }
  if (location.number) {
    return location.number;
  }
  return location.id;
}

export function formatCustomerAndLocation(customer: Customer, location: CustomerLocation): string {
  return `${formatCustomer(customer)} - ${formatCustomerLocation(location)}`;
}

export function sortLocations(locations: ReadonlyArray<CustomerLocation>): CustomerLocation[] {
  return locations.toSorted((a, b) => a.name.localeCompare(b.name) || (a.number || '').localeCompare(b.number || ''));
}

export const customerEntitiesSelector = (state: FlowState): Customers => state.entities.customer;
export const getFlowCustomerSelector = createSelector(
  customerEntitiesSelector,
  (customerEntities) =>
    (customerId: Uuid | undefined): Customer | undefined =>
      customerEntities[customerId!],
);

export const allCustomerIdsSelector = createSelector(
  customerEntitiesSelector,
  (customerEntities) => new Set(Object.keys(customerEntities)),
);

export function isCustomerBillable(customer: Customer): boolean {
  return !customer.isInternal || customer.requiresInternalCharge;
}

export const isCustomerBillableSelector = createSelector(customerEntitiesSelector, (customerEntities) =>
  memoize((customerId: Uuid | null | undefined): boolean => {
    const customer = customerId && customerEntities[customerId];
    return customer ? isCustomerBillable(customer) : true;
  }),
);

export const sortedCustomersSelector = createSelector(customerEntitiesSelector, (customerEntities): Customer[] =>
  boolFilter(Object.values(customerEntities)).sort((a, b) => {
    const cmp = a.name.localeCompare(b.name);
    if (cmp === 0) {
      return a.customerNumber.localeCompare(b.customerNumber);
    }
    return cmp;
  }),
);

export const nonLockedCustomersArraySelector = createSelector(sortedCustomersSelector, (customers) =>
  customers.filter(({isLocked}) => !isLocked),
);

export const customerByNodeSelector = createSelector(
  nodeEntitySelector,
  customerEntitiesSelector,
  getParentPidSelector,
  (nodeEntities, customerEntities, getParentPid) =>
    memoize((id: Uuid | null | undefined): Customer | null => {
      if (id) {
        let node = nodeEntities[id] || null;

        if (!isPid(node)) {
          node = getParentPid(id);
        }

        return isPid(node) ? customerEntities[node.flowCustomer] || null : null;
      }
      return null;
    }),
);

export const customerNamesLowerCaseSelector = createSelector(customerEntitiesSelector, (entities) => {
  return new Set(boolFilter(Object.values(entities)).map(({name}) => name.toLocaleLowerCase()));
});

export const priceCategoryOverridesSelector = createSelector(customerEntitiesSelector, (customerEntities) =>
  memoize(
    (customerId: Uuid): PriceCategoryOverrides | null => customerEntities[customerId]?.priceCategoryOverrides || null,
  ),
);

export const priceSurchargesOverridesSelector = createSelector(customerEntitiesSelector, (customerEntities) =>
  memoize(
    (customerId: Uuid): PriceSurchargeOverrides | null => customerEntities[customerId]?.priceSurchargeOverrides || null,
  ),
);

export const getSearchResultCustomersSelector = createSelector(sortedCustomersSelector, (customerEntities) =>
  customerEntities.map(({id, name: title}) => ({id, title})),
);

export const customterSearchDropdownSelector = createSelector(sortedCustomersSelector, (customers) =>
  memoize(
    (search: string, includeLockedCustomers: boolean) => {
      const hasCustomers = customers.length > 0;
      let customerList = customers;
      if (!includeLockedCustomers) {
        customerList = customerList.filter(({isLocked}) => !isLocked);
      }
      if (search.length) {
        customerList = customerList.filter(({name, customerNumber}) => {
          if (customerNumber.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          return name.toLowerCase().includes(search.toLowerCase());
        });
      }

      return {
        customerList,
        hasCustomers,
      };
    },
    (...args) => args.join('-'),
  ),
);
