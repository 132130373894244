import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {useTaskPatch} from '@octaved/flow/src/Modules/Tasks';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import TaskEstimationSection from '../../../Components/Fields/General/NodeType/Task/Section/TaskEstimationSection';
import TaskNoteSection from '../../../Components/Fields/General/NodeType/Task/Section/TaskNoteSection';
import NodeBoardStatsSection from '../../../Components/Fields/General/Section/NodeBoardStatsSection';
import NodeGuestAccessSection from '../../../Components/Fields/General/Section/NodeGuestAccessSection';
import NodeLabelSection from '../../../Components/Fields/General/Section/NodeLabelSection';
import NodeNameSection from '../../../Components/Fields/General/Section/NodeNameSection';
import NodeReferenceNumberSection from '../../../Components/Fields/General/Section/NodeReferenceNumberSection';
import ResponsibleUnits from '../../../Components/Fields/Responsible/ResponsibleUnits';
import Section from '../../../Components/Fields/Section';
import InspectorViewFrame from '../../InspectorViewFrame';

export interface TaskDetailsProps {
  node: Task;
}

export default function General({node}: TaskDetailsProps): ReactElement {
  const patch = useTaskPatch(node.id);
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const canReadEffort = useIsGranted('FLOW_NODE_TASK_READ_EFFORT', node.id);
  const canReadAssignments = useIsGranted('FLOW_NODE_TASK_READ_RESPONSIBLE', node.id);
  const canReadLabels = useIsGranted('FLOW_NODE_READ_LABELS', node.id);
  const canReadRefNumber = useIsGranted('FLOW_NODE_READ_REFERENCE_NUMBER', node.id);
  const optionals = useOptionalFields(node.id, {
    referenceNumber: node.referenceNumber,
  });

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      {canReadRefNumber && (
        <EnableFieldButton
          field={optionals.referenceNumber}
          label={'nodeDetails:field.referenceNumber.enable'}
          tooltip={'nodeDetails:field.referenceNumber.enableTooltip'}
        />
      )}
    </div>
  );

  const canReadDescription = useIsGranted('FLOW_NODE_READ_DESCRIPTION', node.id);

  return (
    <InspectorViewFrame footer={canManageBasic ? footer : undefined}>
      <NodeNameSection name={node.name} setName={(name) => patch({name})} readonly={!canManageBasic} />
      {optionals.referenceNumber.show && canReadRefNumber && (
        <NodeReferenceNumberSection
          nodeId={node.id}
          referenceNumber={node.referenceNumber || ''}
          setReferenceNumber={(referenceNumber) => patch({referenceNumber})}
          readonly={!canManageBasic}
        />
      )}
      {canReadDescription && (
        <TaskNoteSection
          id={node.id}
          readonly={!canManageBasic}
          note={node.description}
          patch={(description) => patch({description})}
        />
      )}
      <NodeBoardStatsSection nodeId={node.id} nodeType={node.nodeType} />
      {canReadEffort && (
        <TaskEstimationSection
          id={node.id}
          readonly={!canManageBasic}
          plannedTime={node.plannedTime}
          patch={(plannedTime) => patch({plannedTime})}
        />
      )}
      {canReadAssignments && <ResponsibleUnits node={node} frame={Section} />}
      {canReadLabels && (
        <NodeLabelSection
          nodeId={node.id}
          labels={node.labels}
          nodeType={node.nodeType}
          readonly={!canManageBasic}
          setLabels={(labels) => patch({labels})}
        />
      )}
      <NodeGuestAccessSection nodeId={node.id} />
    </InspectorViewFrame>
  );
}
