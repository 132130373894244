import {error} from '@octaved/env/src/Logger';
import {nodeSearchCustomCombinersSelector} from '@octaved/node-search/src/Selectors/NodeSearchCombinersSelector';
import type {EntityStates} from '@octaved/store/src/EntityState';
import type {Uuid} from '@octaved/typescript/src/lib';
import {combineConditionResults} from '@octaved/utilities/src/Condition/CombineConditionResults';
import {hashCondition} from '@octaved/utilities/src/Condition/HashCondition';
import type {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {toCachedSet} from '@octaved/utilities/src/Set';
import {debounce, memoize} from 'lodash';
import {createSelector, Selector} from 'reselect';
import type {
  NodeSearch,
  NodeSearchCondition,
  NodeSearchIdent,
  NodeSearchIdentWithoutValue,
} from '../../EntityInterfaces/NodeSearch';
import {NodeSearchTuple} from '../../EntityInterfaces/NodeSearch';
import type {FlowState} from '../State';

const usedSearchIdents = new Set<string>();
const complainMissingSearchIdents = debounce(() => {
  if (usedSearchIdents.size) {
    error('Missing search keys after used from selector', ...usedSearchIdents.keys());
  }
}, 10000);

function notifySearchKeyUsedFromSelector(key: string, isInStore: boolean): void {
  if (isInStore) {
    usedSearchIdents.delete(key);
  } else {
    usedSearchIdents.add(key);
    complainMissingSearchIdents();
  }
}

export const nodeSearchSelector = (state: FlowState): UuidSearchResults => state.nodeSearch.searchResults;
export const nodeSearchStateSelector = (state: FlowState): EntityStates => state.nodeSearch.searchState;

export function getNodeSearchKey<I extends NodeSearchIdentWithoutValue>(ident: I): string;
// eslint-disable-next-line @typescript-eslint/unified-signatures
export function getNodeSearchKey<I extends NodeSearchIdent>(ident: I, value: NodeSearch[I]): string;
export function getNodeSearchKey<I extends NodeSearchIdent>(ident: I, value?: NodeSearch[I]): string {
  return value ? `${ident}-${value}` : ident;
}

export function splitNodeSearchKey(key: string): [NodeSearchIdent, string | undefined] {
  const split = key.split('-');
  const ident = split.shift() as NodeSearchIdent;
  const value = split.length ? split.join('-') : undefined;
  return [ident, value];
}

export const getIsResponsible = memoize((unitId: Uuid): NodeSearchCondition => {
  return ['responsibleUnitId', unitId];
});

const emptyResult: Uuid[] = [];

type SearchSelector = Selector<FlowState, ReadonlySet<Uuid>>;

const cacheNodeSearchSelectors = new Map<string, SearchSelector>();

export function getNodeSearchSelector<I extends NodeSearchIdentWithoutValue>(ident: I): SearchSelector;
// eslint-disable-next-line @typescript-eslint/unified-signatures
export function getNodeSearchSelector<I extends NodeSearchIdent>(ident: I, value: NodeSearch[I]): SearchSelector;
export function getNodeSearchSelector<I extends NodeSearchIdent>(ident: I, value?: NodeSearch[I]): SearchSelector {
  const key = getNodeSearchKey(ident, value as NodeSearch[I]);
  if (!cacheNodeSearchSelectors.has(key)) {
    cacheNodeSearchSelectors.set(
      key,
      createSelector(nodeSearchSelector, (nodeSearch): ReadonlySet<Uuid> => {
        notifySearchKeyUsedFromSelector(key, !!nodeSearch[key]);
        return toCachedSet(nodeSearch[key] || emptyResult);
      }),
    );
  }
  return cacheNodeSearchSelectors.get(key)!;
}

const nodeSearchResultRetrieverSelector = createSelector(
  nodeSearchSelector,
  (search) =>
    ([ident, value]: NodeSearchTuple) => {
      const key = getNodeSearchKey(ident, value);
      const stored = search[key];
      notifySearchKeyUsedFromSelector(key, !!stored);
      return stored || emptyResult;
    },
);

export const getNodeSearchQueryResultsSelector = createSelector(
  nodeSearchResultRetrieverSelector,
  nodeSearchCustomCombinersSelector,
  (nodeSearchResultRetriever, combiners) =>
    memoize(
      (query: NodeSearchCondition) => {
        return combineConditionResults(query, nodeSearchResultRetriever, combiners);
      },
      (query: NodeSearchCondition) => hashCondition(combiners, query),
    ),
);
