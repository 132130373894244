/**
 * This is a port of TimeTrackingWriteValidator.php and should remain that way!
 */
import {isDevLocal} from '@octaved/env/src/Environment';
import {error} from '@octaved/env/src/Logger';
import {IsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {TimeRecord, TimeRecordCreate, TimeRecordPatch} from '../EntityInterfaces/TimeRecords';
import {GetNodeAncestry, getNodeAncestryWithWp} from '../Modules/Selectors/NodeTreeSelectors';
import {Settings} from '../Modules/Settings';
import {BillingStartEnd} from './BillingStartEnd';
import {canManageNonBookable} from './CanManage';
import {getClosestTimeLimitReachedAncestor} from './OverBooking';
import {TimeTrackingErrors} from './WarningErrorFields';
import {
  validateAlreadyBilled,
  validateApprovedForBilling,
  validateArchived,
  validateBookingTimeWithinTolerance,
  validateClosed,
  validateLocked,
  validateStartBeforeEnd,
  validateTemplate,
  validateTimeBudgets,
  validateTimeControl,
  validateUsePriceCategory,
  validateUserAccess,
  validateWorkPackageFrozen,
} from './WriteValidatorFunctions';

function complainMissingReferenceNode(patch: TimeRecordPatch): void {
  if (typeof patch.referenceNode === 'undefined' && typeof patch.workPackage !== 'undefined') {
    const err = new Error('Missing referenceNode');
    if (isDevLocal || process.env.NODE_ENV === 'test') {
      throw err;
    }
    error(err);
    patch.referenceNode = null;
    patch.workPackage = null;
  }
}

export function validateCreate(
  isGranted: IsGranted,
  superUserActive: boolean,
  settings: Settings,
  currentUserId: Uuid,
  getNodeAncestry: GetNodeAncestry,
  patch: TimeRecordCreate,
  allowStartBeforeEnd: boolean = false,
): TimeTrackingErrors {
  complainMissingReferenceNode(patch);
  const ancestry = getNodeAncestryWithWp(getNodeAncestry(patch.referenceNode, true));
  const errors: TimeTrackingErrors = [];
  if (ancestry) {
    validateTimeBudgets(
      isGranted,
      superUserActive,
      errors,
      settings,
      null,
      ancestry,
      {},
      patch,
      false,
      patch.isJourney ?? false,
    );
    validateApprovedForBilling(isGranted, superUserActive, errors, ancestry);
    validateArchived(errors, ancestry);
    validateLocked(isGranted, superUserActive, errors, ancestry);
    validateClosed(isGranted, superUserActive, errors, ancestry);
    validateTemplate(errors, ancestry);
    validateBookingTimeWithinTolerance(isGranted, superUserActive, errors, settings, ancestry, patch);
    validateTimeControl(isGranted, superUserActive, errors, ancestry, patch);
    validateWorkPackageFrozen(isGranted, superUserActive, errors, ancestry, patch);
    validateUsePriceCategory(errors, ancestry, patch.priceCategory || null);
  }
  validateUserAccess(isGranted, errors, currentUserId, patch.referenceNode || null, patch.user);
  if (!allowStartBeforeEnd) {
    validateStartBeforeEnd(errors, patch);
  }
  return errors;
}

export function createBillingStartEndWithFallback(record: BillingStartEnd, patch: BillingStartEnd): BillingStartEnd {
  return {
    billingEnd: patch.hasOwnProperty('billingEnd') ? patch.billingEnd : record.billingEnd,
    billingStart: patch.hasOwnProperty('billingStart') ? patch.billingStart : record.billingStart,
  };
}

export function validatePatch(
  isGranted: IsGranted,
  superUserActive: boolean,
  settings: Settings,
  currentUserId: Uuid,
  getNodeAncestry: GetNodeAncestry,
  record: TimeRecord,
  patch: TimeRecordPatch,
  allowStartBeforeEnd: boolean = false,
): TimeTrackingErrors {
  complainMissingReferenceNode(patch);
  const errors: TimeTrackingErrors = [];
  const billingStartEndPatch = createBillingStartEndWithFallback(record, patch);
  const prevAncestry = getNodeAncestryWithWp(getNodeAncestry(record.referenceNode, true));
  const patchAncestry = getNodeAncestryWithWp(getNodeAncestry(patch.referenceNode, true));

  if (prevAncestry || patchAncestry) {
    validateTimeBudgets(
      isGranted,
      superUserActive,
      errors,
      settings,
      prevAncestry,
      (patchAncestry ?? prevAncestry)!,
      record,
      billingStartEndPatch,
      record.isJourney,
      patch.isJourney ?? record.isJourney,
    );
  }

  if (patchAncestry && (!prevAncestry || prevAncestry.ancestors[0].id !== patchAncestry.ancestors[0].id)) {
    validateApprovedForBilling(isGranted, superUserActive, errors, patchAncestry);
    validateArchived(errors, patchAncestry);
    validateLocked(isGranted, superUserActive, errors, patchAncestry);
    validateClosed(isGranted, superUserActive, errors, patchAncestry);
    validateTemplate(errors, patchAncestry);
    validateBookingTimeWithinTolerance(
      isGranted,
      superUserActive,
      errors,
      settings,
      patchAncestry,
      billingStartEndPatch,
    );
    validateTimeControl(isGranted, superUserActive, errors, patchAncestry, billingStartEndPatch);
    validateWorkPackageFrozen(isGranted, superUserActive, errors, patchAncestry, billingStartEndPatch);
    validateUsePriceCategory(
      errors,
      patchAncestry,
      typeof patch.priceCategory !== 'undefined' ? patch.priceCategory : record.priceCategory,
    );
  } else if (prevAncestry) {
    validateBookingTimeWithinTolerance(
      isGranted,
      superUserActive,
      errors,
      settings,
      prevAncestry,
      billingStartEndPatch,
    );
    validateTimeControl(isGranted, superUserActive, errors, prevAncestry, billingStartEndPatch);
    validateWorkPackageFrozen(isGranted, superUserActive, errors, prevAncestry, billingStartEndPatch);
    validateUsePriceCategory(
      errors,
      prevAncestry,
      typeof patch.priceCategory !== 'undefined' ? patch.priceCategory : record.priceCategory,
    );
  }
  if (prevAncestry) {
    validateApprovedForBilling(isGranted, superUserActive, errors, prevAncestry);
    validateArchived(errors, prevAncestry);
    validateLocked(isGranted, superUserActive, errors, prevAncestry);
    validateClosed(isGranted, superUserActive, errors, prevAncestry);
    validateTemplate(errors, prevAncestry);

    //Check previous booking time on old wp and old start/end:
    validateBookingTimeWithinTolerance(isGranted, superUserActive, errors, settings, prevAncestry, record);
    validateTimeControl(isGranted, superUserActive, errors, prevAncestry, record);
    validateWorkPackageFrozen(isGranted, superUserActive, errors, prevAncestry, record);
  }

  //Check user access on both prev and next node - if empty ancestry, the check muss error for foreign user edits:
  validateUserAccess(isGranted, errors, currentUserId, prevAncestry?.ancestors[0].id || null, record.user);
  if ('referenceNode' in patch) {
    validateUserAccess(isGranted, errors, currentUserId, patchAncestry?.ancestors[0].id || null, record.user);
  }

  validateAlreadyBilled(isGranted, superUserActive, errors, record, false);
  if (!allowStartBeforeEnd) {
    validateStartBeforeEnd(errors, patch);
  }
  return errors;
}

export function validateRemove(
  isGranted: IsGranted,
  superUserActive: boolean,
  settings: Settings,
  currentUserId: Uuid,
  getNodeAncestry: GetNodeAncestry,
  getTimeRecord: (id: Uuid) => TimeRecord | undefined,
  timeRecordId: Uuid,
): TimeTrackingErrors {
  const errors: TimeTrackingErrors = [];
  const record = getTimeRecord(timeRecordId);
  if (record) {
    const ancestry = getNodeAncestryWithWp(getNodeAncestry(record.referenceNode, true));
    if (ancestry) {
      validateApprovedForBilling(isGranted, superUserActive, errors, ancestry);
      validateArchived(errors, ancestry);
      validateLocked(isGranted, superUserActive, errors, ancestry);
      validateClosed(isGranted, superUserActive, errors, ancestry);
      validateTemplate(errors, ancestry);
      validateBookingTimeWithinTolerance(isGranted, superUserActive, errors, settings, ancestry, record);
      validateTimeControl(isGranted, superUserActive, errors, ancestry, record);
      validateWorkPackageFrozen(isGranted, superUserActive, errors, ancestry, record);
    }
    validateUserAccess(isGranted, errors, currentUserId, record.referenceNode, record.user);
    validateAlreadyBilled(isGranted, superUserActive, errors, record, true);
  }
  return errors;
}

export function canStartBookingOnNode(
  isGranted: IsGranted,
  superUserActive: boolean,
  settings: Settings,
  getNodeAncestry: GetNodeAncestry,
  nodeId: Uuid,
): boolean {
  const ancestry = getNodeAncestryWithWp(getNodeAncestry(nodeId, true));
  if (ancestry) {
    const errors: TimeTrackingErrors = [];
    validateApprovedForBilling(isGranted, superUserActive, errors, ancestry);
    validateArchived(errors, ancestry);
    validateLocked(isGranted, superUserActive, errors, ancestry);
    validateClosed(isGranted, superUserActive, errors, ancestry);
    validateTemplate(errors, ancestry);
    return (
      (!errors.length &&
        !getClosestTimeLimitReachedAncestor(settings, ancestry) &&
        isGranted('FLOW_NODE_PROJECT_TIME_TRACKING_MANAGE_BOOKABLE_OWN', nodeId)) ||
      canManageNonBookable(isGranted, superUserActive, nodeId)
    );
  }
  return false;
}

/**
 * Determines whether the record can be edited at all
 */
export function canEditTimeRecord(
  isGranted: IsGranted,
  superUserActive: boolean,
  settings: Settings,
  currentUserId: Uuid,
  getNodeAncestry: GetNodeAncestry,
  getTimeRecord: (timeRecordId: Uuid) => TimeRecord | undefined,
  timeRecordId: Uuid,
): boolean {
  const record = getTimeRecord(timeRecordId);
  if (!record) {
    return false;
  }
  const ancestry = getNodeAncestryWithWp(getNodeAncestry(record.referenceNode, true));
  const errors: TimeTrackingErrors = [];
  validateUserAccess(isGranted, errors, currentUserId, record.referenceNode, record.user);
  validateAlreadyBilled(isGranted, superUserActive, errors, record, false);
  if (ancestry) {
    validateApprovedForBilling(isGranted, superUserActive, errors, ancestry);
    validateArchived(errors, ancestry);
    validateLocked(isGranted, superUserActive, errors, ancestry);
    validateClosed(isGranted, superUserActive, errors, ancestry);
    validateBookingTimeWithinTolerance(isGranted, superUserActive, errors, settings, ancestry, record);
    validateTemplate(errors, ancestry);
    validateTimeControl(isGranted, superUserActive, errors, ancestry, record);
    validateWorkPackageFrozen(isGranted, superUserActive, errors, ancestry, record);
  }
  return errors.length === 0;
}
