import {useScrollIntoView} from '@octaved/hooks/src/ScrollIntoView';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import classNames from 'classnames';
import {ReactElement, useCallback, useContext} from 'react';
import {ProjectTreeProjectsGroup} from '../../../Modules/Projects/ProjectTreeInterfaces';
import {flowStyleContext} from '../../../Styles/StyleContext';
import {useProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';
import {projectsTreeComponentContext} from '../ProjectTreeComponentContext';
import Alphanumeric from './ProjectGrouping/Alphanumeric';
import Customer from './ProjectGrouping/Customer';
import ProjectManager from './ProjectGrouping/ProjectManager';
import ToggleSubPids from './ToggleSubPids';

export default function ProjectGrouping({node}: {node: ProjectTreeProjectsGroup}): ReactElement {
  const {
    COLORS: {
      PAGES: {PROJECTS},
    },
  } = useContext(flowStyleContext);
  const {compactView: compact, selectableNodeTypes} = useProjectTreeContext();
  const isSelectable = selectableNodeTypes.has(node.type);
  const isSelected = isSelectable && node.isSelected;
  const groupingRef = useScrollIntoView<HTMLDivElement>(isSelected, 250);
  const {project: Project} = useContext(projectsTreeComponentContext);

  const onClickCustomerRow = useCallback(
    (e) => {
      if (isSelectable) {
        node.select();
      } else {
        node.toggleExpansion(e);
      }
    },
    [node, isSelectable],
  );

  return (
    <div className={classNames('projectGrouping', {isSelectable, compact, selected: isSelected})} ref={groupingRef}>
      <div className={classNames('projectGroupingGrid')} onClick={onClickCustomerRow}>
        <ToggleSubPids isExpanded={node.isExpanded} toggleExpansion={node.toggleExpansion} alignSelfCenter />
        {node.groupedBy === 'alphanumeric' && <Alphanumeric node={node} />}
        {node.groupedBy === 'customer' && <Customer node={node} />}
        {node.groupedBy === 'pm' && <ProjectManager node={node} />}
      </div>

      <SlideToggleElement visible={node.isExpanded}>
        <div className={classNames('projectsContainer bordered')}>
          {node.children.map((childNode) => (
            <Project key={childNode.id} node={childNode} />
          ))}
        </div>
      </SlideToggleElement>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .projectGrouping {
          margin-bottom: 12px;

          &.compact {
            margin-bottom: 10px;
          }
        }

        .projectGroupingGrid {
          display: grid;
          padding: 12px 0;
          grid-template-columns: 15px 48px 1fr max-content;
          grid-column-gap: 10px;
          align-items: center;

          .isSelectable & {
            cursor: pointer;
          }

          .compact & {
            grid-template-columns: 15px 24px 1fr max-content;
            grid-column-gap: 5px;
          }
        }

        .selected .projectGroupingGrid {
          background-color: ${PROJECTS.SELECTION_BACKGROUND};
        }

        .projectsContainer {
          margin-left: 25px;

          .compact & {
            margin-left: 15px;
          }
        }

        .bordered {
          border-top: none;

          .compact & {
            border-top: none;
          }
        }

        .toggle {
          margin: -10px;
          padding: 10px;
          cursor: pointer;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
