import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';

interface Props {
  children: ReactNode;
  leftMargin?: boolean;
}

export default function GroupingName({children, leftMargin}: Props): ReactElement {
  return (
    <>
      <div className={classNames('projectGroupingName', {leftMargin})}>{children}</div>

      {/*language=SCSS*/}
      <style jsx>{`
        .projectGroupingName {
          font-weight: 600;
          font-size: 18px;

          .compact & {
            font-size: 16px;
          }
        }

        .leftMargin {
          margin-left: 14px;
        }
      `}</style>
    </>
  );
}
