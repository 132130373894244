import {getDeprecatedUserIds} from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createFlatTimestampReducer,
  INVALIDATED,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  FLOW_LOAD_DEPRECATED_USER_IDS_FAILURE,
  FLOW_LOAD_DEPRECATED_USER_IDS_REQUEST,
  FLOW_LOAD_DEPRECATED_USER_IDS_SUCCESS,
} from '../ActionTypes';
import {
  deprecatedUserIdsSelector,
  deprecatedUserIdsStateSelector,
  emptyIds,
} from '../Selectors/Users/DeprecatedUsersSelectors';
import {FlowState} from '../State';

const reducerMap = new Map();
const stateReducerMap = new Map();

export const deprecatedUserIdsReducers = {
  data: ReduceFromMap(reducerMap, emptyIds),
  state: ReduceFromMap(stateReducerMap),
};

reducerMap.set(FLOW_LOAD_DEPRECATED_USER_IDS_SUCCESS, (_: Uuid[], {response}: {response: Uuid[]}) => response);
stateReducerMap.set(FLOW_LOAD_DEPRECATED_USER_IDS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_LOAD_DEPRECATED_USER_IDS_SUCCESS, createFlatTimestampReducer(LOADED));
stateReducerMap.set('flow.deprecatedUsersChanged', createFlatTimestampReducer(INVALIDATED));

function loadDeprecatedUserIds(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = deprecatedUserIdsStateSelector(getState());
    if (!state || isOutdated(state)) {
      dispatch({
        [CALL_API]: {
          endpoint: getDeprecatedUserIds,
          types: {
            failureType: FLOW_LOAD_DEPRECATED_USER_IDS_FAILURE,
            requestType: FLOW_LOAD_DEPRECATED_USER_IDS_REQUEST,
            successType: FLOW_LOAD_DEPRECATED_USER_IDS_SUCCESS,
          },
        },
      });
    }
  };
}

const emptyUserIds: Uuid[] = [];

export function useDeprecatedUserIds(active = true): {isLoading: boolean; userIds: Uuid[]} {
  const userIds = useSelector(deprecatedUserIdsSelector);
  const state = useSelector(deprecatedUserIdsStateSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (active) {
      // noinspection BadExpressionStatementJS
      void state; //reload dependency
      dispatch(loadDeprecatedUserIds());
    }
  }, [active, dispatch, state]);

  if (active) {
    return {userIds, isLoading: !state || !isLoaded(state)};
  }
  return {isLoading: false, userIds: emptyUserIds};
}
