import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {DateOrRangePicker, OnChangeFn, PublicDatePickerOverride, Tooltip} from '@octaved/ui';
import {fromIsoDateTimeFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {boolFilter} from '@octaved/utilities';
import {Dayjs} from 'dayjs';
import {CornerDownRight} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {dateIsPlanned} from '../../../Calculations/DateCalculations';
import {PlanningDate, PlanningDatesList} from '../../../EntityInterfaces/PlanningDates';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {useLoadPlanningDates} from '../../../Modules/PlanningDates';
import {getMinMaxPlanningDatesSelector} from '../../../Selectors/PlanningDateSelectors';
import {canChangeBarSelector, getPlanningDateDependenceSelector} from '../../../Selectors/PlanningDependencySelectors';

interface TimePeriodDatePickerProps {
  node: PlanningRealizationNode;
  planningDate: PlanningDate | null;
  planningDates: PlanningDatesList;
  readonly: boolean;
  saveChange: OnChangeFn<DateTimeStr, false>;
  triggerButtonClassName?: string;
}

function useEffectiveMinMaxDates(nodeId: Uuid): {maxDate: Dayjs | undefined; minDate: Dayjs | undefined} {
  const canChangeBar = useSelector(canChangeBarSelector)(nodeId);
  const getMinMaxPlanning = useSelector(getMinMaxPlanningDatesSelector);
  const nodeMinMax = getMinMaxPlanning(nodeId);
  const {workPackage, subWorkPackage} = useNodeAncestry(nodeId, false);
  const nodeAncestors = boolFilter([subWorkPackage, workPackage]);
  useLoadPlanningDates(nodeAncestors.map(({id}) => id));

  let maxDate = !canChangeBar && nodeMinMax.plannedEnd ? fromIsoDateTimeFormat(nodeMinMax.plannedEnd) : undefined;
  let minDate = !canChangeBar && nodeMinMax.plannedStart ? fromIsoDateTimeFormat(nodeMinMax.plannedStart) : undefined;

  if (!nodeAncestors) {
    return {
      maxDate,
      minDate,
    };
  }

  const ancestorMinMax = nodeAncestors
    .filter((node) => {
      const minMax = getMinMaxPlanning(node.id);
      return minMax.plannedStart || minMax.plannedEnd;
    })
    .map((node) => getMinMaxPlanning(node.id))[0];
  if (ancestorMinMax && ancestorMinMax.plannedEnd && (!maxDate || maxDate?.isAfter(ancestorMinMax.plannedEnd, 'day'))) {
    maxDate = fromIsoDateTimeFormat(ancestorMinMax.plannedEnd);
  }
  if (
    ancestorMinMax &&
    ancestorMinMax.plannedStart &&
    (!minDate || minDate?.isBefore(ancestorMinMax.plannedStart, 'day'))
  ) {
    minDate = fromIsoDateTimeFormat(ancestorMinMax.plannedStart);
  }

  return {
    maxDate,
    minDate,
  };
}

export default function TimePeriodDatePicker({
  planningDate,
  planningDates,
  node,
  readonly,
  saveChange,
  triggerButtonClassName,
}: TimePeriodDatePickerProps): ReactElement {
  const canChangeBar = useSelector(canChangeBarSelector)(node.id);
  const {maxDate, minDate} = useEffectiveMinMaxDates(node.id);

  const planningDateId = planningDate?.id || null;
  const datePickerOverride = useMemo<PublicDatePickerOverride<DateTimeStr, false>>(() => {
    return {
      maxDate,
      minDate,
      isDayBlocked(date: DateTimeStr) {
        return dateIsPlanned(planningDates, date, planningDateId);
      },
      strictBlockedDates: true,
    };
  }, [maxDate, minDate, planningDateId, planningDates]);

  const isDependentOfPredecessor = useSelector((s: FlowState) =>
    planningDate
      ? getPlanningDateDependenceSelector(s)(planningDate.nodeId, planningDate.id).isDependentOfPredecessor
      : false,
  );

  return (
    <div className={'flex items-center gap-x-2'}>
      <DateOrRangePicker
        readonly={readonly || !canChangeBar}
        end={planningDate?.plannedEnd || null}
        start={planningDate?.plannedStart || null}
        onChange={saveChange}
        isRangeDefault
        footerOverride={{canClear: false}}
        datePickerOverride={datePickerOverride}
        convertFrom={fromIsoDateTimeFormat}
        convertTo={toIsoDateTimeFormat}
        placeholderToken={'pages:projects.inspector.manage.planning.selectDate'}
        triggerButtonClassName={triggerButtonClassName}
      />
      {isDependentOfPredecessor && (
        <Tooltip toolTipTranslation={'tooltips:components.planning.bar.isDependentOfPredecessor'}>
          <CornerDownRight className={'size-4 text-slate-400'} />
        </Tooltip>
      )}
    </div>
  );
}
