import {useCloseContextMenu} from '@octaved/flow/src/Components/Form/Dropdown/ContextMenuRoot';
import {useDisposedSafeCallback} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {Popup} from '@octaved/ui';
import {Dispatch, PropsWithChildren, ReactElement, SetStateAction, useState} from 'react';
import BaselineSelectorContent from './BaselineSelectorContent';
import {HeaderPopups} from './DrawerHeader';

interface BaselineSelectorProps {
  setHeaderPopups: Dispatch<SetStateAction<HeaderPopups>>;
  rootNodeId: Uuid;
}

export default function BaselineSelector({
  children,
  setHeaderPopups,
  rootNodeId,
}: PropsWithChildren<BaselineSelectorProps>): ReactElement {
  const [open, _setOpen] = useState(false);
  const setOpen = useDisposedSafeCallback(_setOpen);
  const closeContextMenu = useCloseContextMenu();

  return (
    <Popup
      position={'bottom right'}
      trigger={children}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        closeContextMenu();
      }}
    >
      <BaselineSelectorContent setHeaderPopups={setHeaderPopups} rootNodeId={rootNodeId} />
    </Popup>
  );
}
