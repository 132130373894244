import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {SubWorkPackage} from '../EntityInterfaces/SubWorkPackage';
import {
  FLOW_CREATE_SUB_WORK_PAGKAGE_FAILURE,
  FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST,
  FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS,
  FLOW_PATCH_SUB_WORK_PACKAGE_FAILURE,
  FLOW_PATCH_SUB_WORK_PACKAGE_REQUEST,
  FLOW_PATCH_SUB_WORK_PACKAGE_SUCCESS,
  FLOW_REMOVE_SUB_WORK_PACKAGE_FAILURE,
  FLOW_REMOVE_SUB_WORK_PACKAGE_REQUEST,
  FLOW_REMOVE_SUB_WORK_PACKAGE_SUCCESS,
} from './ActionTypes';
import {createCompletableNode, setCompletedOn} from './CompletableNodes';
import {
  EventData,
  SubWorkPackageCreatedEvent,
  SubWorkPackageCreateEvent,
  SubWorkPackagePatchedEvent,
  SubWorkPackageRemoveEvent,
} from './Events';
import {createNodeEntity, nodeEntityReducers} from './Nodes';
import {createResponsibleNode, onResponsibleNodeCreation} from './ResponsibleNode';
import {isSubWorkPackage} from '../Node/NodeIdentifiers';
import {getSubWorkPackageSelector} from './Selectors/PidSelectors';
import {reduceSortedSiblingNodeIds} from './SortedSiblingIds';
import {FlowState} from './State';

nodeEntityReducers.add<SubWorkPackageCreateEvent | SubWorkPackageCreatedEvent | SubWorkPackagePatchedEvent>(
  [FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST, 'flow.SubWorkPackageCreatedEvent', 'flow.SubWorkPackagePatchedEvent'],
  reduceSortedSiblingNodeIds(isSubWorkPackage),
);

export function createSubWorkPackageEntity(): SubWorkPackage {
  return {
    ...createNodeEntity(EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE),
    ...createCompletableNode(),
    ...createResponsibleNode(),
    defaultPriceCategory: null,
    description: '',
    isLocked: false,
    maxEffort: null,
    nodeType: EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
    sortOrder: 0,
    trackedMinutes: {
      billed: 0,
      billedJourney: 0,
      recorded: 0,
      recordedJourney: 0,
    },
  };
}

export function createSubWorkPackage(
  subWorkPackage: SubWorkPackage,
  parentNodeId: Uuid,
  sortedSiblingIds?: Uuid[],
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();

    setCompletedOn(subWorkPackage, currentOrgUserIdSelector(state));
    onResponsibleNodeCreation(subWorkPackage, parentNodeId, state);

    if (sortedSiblingIds) {
      const sortOrder = sortedSiblingIds.findIndex((id) => id === subWorkPackage.id);
      subWorkPackage.sortOrder = sortOrder > -1 ? sortOrder : 0;
    }

    const event: EventData<SubWorkPackageCreateEvent> = {
      parentNodeId,
      sortedSiblingIds,
      subWorkPackage,
    };

    await dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.putSubWorkPackage,
        method: 'put',
        options: {
          data: {
            ...subWorkPackage,
            parentNodeId,
            sortedSiblingIds,
          },
          urlParams: {subWorkPackageId: subWorkPackage.id},
        },
        types: {
          failureType: FLOW_CREATE_SUB_WORK_PAGKAGE_FAILURE,
          requestType: FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST,
          successType: FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS,
        },
      },
    });
  };
}

export function patchSubWorkPackage(id: Uuid, data: Partial<SubWorkPackage>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const oldSwp = getSubWorkPackageSelector(state)(id);
    if (oldSwp && !objectContains(oldSwp, data)) {
      data.lastChangedBy = currentOrgUserIdSelector(state);
      data.lastChangedOn = unix();
      setCompletedOn(data, currentOrgUserIdSelector(state));
      dispatch({
        [CALL_API]: {
          endpoint: routes.patchSubWorkPackage,
          method: 'patch',
          options: {data, urlParams: {subWorkPackageId: id}},
          types: {
            failureType: FLOW_PATCH_SUB_WORK_PACKAGE_FAILURE,
            requestType: FLOW_PATCH_SUB_WORK_PACKAGE_REQUEST,
            successType: FLOW_PATCH_SUB_WORK_PACKAGE_SUCCESS,
          },
        },
        //e.g. for NodeSearch reducer that need to updated since no return event is coming:
        patchedKeys: Object.keys(data),
        patchedNodeId: id, //for the optimistic patch
      });
    }
  };
}

export function deleteSubWorkPackage(subWorkPackageId: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch) => {
    const event: EventData<SubWorkPackageRemoveEvent> = {nodeIds: [subWorkPackageId]};
    dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.deleteSubWorkPackage,
        method: 'del',
        options: {urlParams: {subWorkPackageId}},
        types: {
          failureType: FLOW_REMOVE_SUB_WORK_PACKAGE_FAILURE,
          requestType: FLOW_REMOVE_SUB_WORK_PACKAGE_REQUEST,
          successType: FLOW_REMOVE_SUB_WORK_PACKAGE_SUCCESS,
        },
      },
    });
    return true;
  };
}
