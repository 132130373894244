import {Group} from 'konva/lib/Group';
import {DisposableArray} from '../../Disposable';
import {RootContainer, RootContainerProps} from '../../RootContainer';
import {GanttContext} from '../Context/GanttContext';
import {GanttDataLoader} from '../Data/GanttDataLoader';
import {TreeNode} from '../Data/TreeNode';
import {BarFactory} from './BarFactory';

export interface BarRowProps<CTX extends GanttContext = GanttContext> extends RootContainerProps<CTX> {
  menuRoot: Group;
  node: TreeNode;
  dependencyRoot: Group;
  rowIndex: number;
  planningDataLoader: GanttDataLoader;
  barFactory: BarFactory;
  height: number;
  yOffset: number;
}

export class BarRow<CTX extends GanttContext = GanttContext> extends RootContainer<CTX> {
  protected readonly menuRoot: Group;
  protected readonly node: TreeNode;
  protected readonly dependencyRoot: Group;
  protected readonly rowIndex: number;
  protected readonly planningDataLoader: GanttDataLoader;
  protected readonly barFactory: BarFactory;

  protected readonly height: number;
  protected readonly yOffset: number;
  protected disposableRows: DisposableArray = [];

  constructor(props: BarRowProps<CTX>) {
    super(props);

    this.menuRoot = props.menuRoot;
    this.node = props.node;
    this.dependencyRoot = props.dependencyRoot;
    this.rowIndex = props.rowIndex;
    this.planningDataLoader = props.planningDataLoader;
    this.barFactory = props.barFactory;
    this.height = props.height;
    this.yOffset = props.yOffset;
  }

  init(): void {
    this.disposables.push(() => this.disposeRows());
  }

  protected disposeRows(): void {
    this.disposeList(this.disposableRows);
    this.disposableRows = [];
  }
}
