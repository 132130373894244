import {useDaysInsteadOfHours} from '@octaved/flow/src/Components/DaysOrHoursContext';
import {roundSecondsToHoursOrDays} from '@octaved/flow/src/Formatter/Decimal';
import {useTrackedTimeQuotasForNode} from '@octaved/flow/src/Modules/Projects/ProjectControlling/TrackedTimeQuotas';
import NoTimeRecords from '@octaved/flow/src/Pages/Projects/Pages/ControlTrackedTime/Inspector/NoTimeRecords';
import {Uuid} from '@octaved/typescript/src/lib';
import {PieChartWithLegend, PieChartWithLegendEntry} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeBillableQuotaChartProps {
  nodeId: Uuid;
  workPackageIds: readonly Uuid[];
}

export default function NodeBillableQuotaChart({
  frame: Frame,
  nodeId,
  workPackageIds,
}: NodeBillableQuotaChartProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const showDays = useDaysInsteadOfHours();
  const {
    data: {billable},
  } = useTrackedTimeQuotasForNode(nodeId, workPackageIds);

  const pieces = useMemo((): PieChartWithLegendEntry[] => {
    const billableSeconds = billable?.find(({isBillable}) => isBillable)?.billingSeconds || 0;
    const nonBillableSeconds = billable?.find(({isBillable}) => !isBillable)?.billingSeconds || 0;
    return [
      {
        color: '#2388de',
        name: t('nodeDetails:field.billableQuota.isBillableLabel'),
        value: billableSeconds,
      },
      {
        color: '#982bc6',
        name: t('nodeDetails:field.billableQuota.notBillableLabel'),
        value: nonBillableSeconds,
      },
    ];
  }, [billable, t]);

  const valueFormatter = useCallback(
    (value: number): string => formatDecimal(roundSecondsToHoursOrDays(value, showDays)),
    [showDays],
  );

  return (
    <Frame labelToken={'nodeDetails:field.billableQuota.label'}>
      <PieChartWithLegend
        pieces={pieces}
        valueFormatter={valueFormatter}
        noData={<NoTimeRecords />}
        unit={showDays ? 'd' : 'h'}
      />
    </Frame>
  );
}
