import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Dispatch, SetStateAction} from 'react';
import {
  DateLimitsResult,
  ResizeStartpoint,
  calculateDateLimits,
  resizeBar,
} from '../../../../../../../Calculations/BarMovement';
import {addPlanningDate, updatePlanningDate} from '../../../../../../../Modules/PlanningDates';
import {getPlanningDatesForNodeSelector} from '../../../../../../../Selectors/PlanningDateSelectors';
import {BarManipulatingContext} from './BarManipulatingContext';

interface ResizeProps {
  ctx: BarManipulatingContext;
}

interface ResizeStart {
  setAdjustedPlannedStart: Dispatch<SetStateAction<DateStr>>;
  setAdjustedPlannedEnd: Dispatch<SetStateAction<DateStr>>;
  nodeId: Uuid;
  planningDateId: Uuid;
  plannedStart: DateStr;
  plannedEnd: DateStr;
  direction: ResizeStartpoint;
  createOnDrag?: boolean;
}

interface ResizeState {
  setAdjustedPlannedStart: Dispatch<SetStateAction<DateStr>>;
  setAdjustedPlannedEnd: Dispatch<SetStateAction<DateStr>>;
  nodeId: Uuid;
  planningDateId: Uuid;
  limitEnd: DateTimeStr | null;
  limitStart: DateTimeStr | null;
  isResizing: boolean;
  plannedStart: DateStr;
  plannedEnd: DateStr;
  direction: ResizeStartpoint;
  createOnDrag: boolean;
}

export class Resize {
  readonly #ctx: BarManipulatingContext;
  #state: ResizeState | null = null;

  constructor({ctx: context}: ResizeProps) {
    this.#ctx = context;
  }

  onEnd = (
    clientX: number,
    _clientY: number,
    _event: MouseEvent | TouchEvent,
    clientStartX: number,
    _clientStartY: number,
  ): void => {
    const state = this.#state;
    if (state) {
      const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.#ctx.store.getState());
      const resizeResult = resizeBar(
        clientStartX,
        clientX,
        state.direction,
        state.plannedStart,
        state.plannedEnd,
        this.#ctx.calendarContext.cellWidth,
        state.limitStart,
        state.limitEnd,
        getWorkMinutesAtDate,
      );

      if (state.createOnDrag) {
        this.#ctx.store.dispatch(
          addPlanningDate(
            state.nodeId,
            state.planningDateId,
            isoToIsoDateTimeFormat(resizeResult.plannedStart),
            isoToIsoDateTimeFormat(resizeResult.plannedEnd),
            true,
          ),
        );
      } else {
        this.#ctx.store.dispatch(
          updatePlanningDate(state.nodeId, state.planningDateId, {
            plannedEnd: isoToIsoDateTimeFormat(resizeResult.plannedEnd),
            plannedStart: isoToIsoDateTimeFormat(resizeResult.plannedStart),
          }),
        );
      }
      this.#state = null;
    }
  };

  onMove = (
    clientX: number,
    _clientY: number,
    _event: MouseEvent | TouchEvent,
    clientStartX: number,
    _clientStartY: number,
  ): void => {
    if (this.#state) {
      const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.#ctx.store.getState());
      const result = resizeBar(
        clientStartX,
        clientX,
        this.#state.direction,
        this.#state.plannedStart,
        this.#state.plannedEnd,
        this.#ctx.calendarContext.cellWidth,
        this.#state.limitStart,
        this.#state.limitEnd,
        getWorkMinutesAtDate,
      );
      this.#state.setAdjustedPlannedStart(result.plannedStart);
      this.#state.setAdjustedPlannedEnd(result.plannedEnd);
    }
  };

  onStart = ({
    createOnDrag,
    direction,
    nodeId,
    plannedEnd,
    plannedStart,
    planningDateId,
    setAdjustedPlannedEnd,
    setAdjustedPlannedStart,
  }: ResizeStart): void => {
    const {end, start} = this.getDateLimits(nodeId, planningDateId);
    this.#state = {
      direction,
      nodeId,
      plannedEnd,
      plannedStart,
      planningDateId,
      setAdjustedPlannedEnd,
      setAdjustedPlannedStart,
      createOnDrag: Boolean(createOnDrag),
      isResizing: false,
      limitEnd: end,
      limitStart: start,
    };
    this.#ctx.closeInspector();
  };

  protected getDateLimits(nodeId: Uuid, planningDateId: Uuid): DateLimitsResult {
    const state = this.#ctx.store.getState();
    const planningDates = getPlanningDatesForNodeSelector(state)(nodeId);
    return calculateDateLimits(planningDateId, planningDates);
  }

  isResizing(): boolean {
    return Boolean(this.#state?.isResizing);
  }

  barIsUpdating(): boolean {
    return Boolean(this.#state);
  }

  cancel(): void {
    this.#state = null;
  }
}
