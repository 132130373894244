import {lazy, ReactElement, Suspense} from 'react';
import {Loader} from 'semantic-ui-react';

const JsonPreview = lazy(() => import('./JsonPreview'));

export default function JsonPreviewLazy(props: {obj: object}): ReactElement | null {
  return (
    <Suspense fallback={<Loader active />}>
      <JsonPreview {...props} />
    </Suspense>
  );
}
