import {ReactElement} from 'react';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';
import NewWorkPackageForm from './NewWorkPackageForm';

export default function NewWorkPackage({
  relativeNodeId,
  close,
  before,
}: {
  relativeNodeId?: string;
  close?: () => void;
  before?: boolean;
}): ReactElement | null {
  return (
    <DialogFrame size={'mini'} onClose={close}>
      <DialogTitle onClose={close}>
        <DialogAndDrawerHeader header={'dialogs:createWorkpackage.title'} />
      </DialogTitle>
      <DialogContent>
        <NewWorkPackageForm relativeNodeId={relativeNodeId} before={before} />
      </DialogContent>
    </DialogFrame>
  );
}
