import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {getEffectiveMaxEffort} from '@octaved/flow/src/WorkPackage/MaxEffort';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface TaskEffortExceedsTimeLimitSectionProps {
  node: WorkPackage;
  totalTaskSum: number;
}

export default function TaskEffortExceedsTimeLimitSection({
  node,
  totalTaskSum,
}: TaskEffortExceedsTimeLimitSectionProps): ReactElement | null {
  const {t} = useTranslation();
  const maxEffort = getEffectiveMaxEffort(node);
  if (maxEffort && maxEffort < totalTaskSum) {
    return (
      <div>
        <div className={'text-red-600'}>
          <Flame className={'-mt-[0.6] mr-1 inline-flex h-5 w-5 text-red-500'} strokeWidth={1.5} />
          {t('nodeDetails:field.tasks.tasksHours.exceedsTimeLimit')}
        </div>
        <div className={'mt-1 text-sm'}>
          {t('nodeDetails:field.tasks.tasksHours.exceedsTimeLimit.taskEffort', {count: totalTaskSum})}
          {', '}
          {t('nodeDetails:field.tasks.tasksHours.exceedsTimeLimit.timeLimit', {count: maxEffort})}
        </div>
      </div>
    );
  }
  return null;
}
