/*eslint sort-keys-shorthand/sort-keys-shorthand: ["error", "asc", {caseSensitive: false}]*/
import {environmentStateReducer} from '@octaved/env/src/EnvironmentState';
import {accessKeysReducer, accessKeysStateReducer} from '@octaved/identity/src/Modules/AccessKeys';
import {accessTokensReducer, accessTokensStateReducer} from '@octaved/identity/src/Modules/AccessTokens';
import {identityReducer} from '@octaved/identity/src/Modules/Identity';
import {inboxReducer, inboxSearchReducer, inboxSearchStateReducer, inboxStateReducer} from '@octaved/inbox/src/Inbox';
import {integrationStateReducers} from '@octaved/integration/src/Modules/IntegrationState';
import {reducer as quota} from '@octaved/media-files/src/Modules/Quota';
import {organizationReducer} from '@octaved/organization/src/Modules/Organization';
import {reducer as organizationAllowedLoginIP} from '@octaved/organization/src/Modules/OrganizationAllowedLoginIP';
import {reducer as uiPagesOrganizationReducer} from '@octaved/organization/src/Modules/UiPage';
import {milestoneEntityStateReducer, milestoneReducer} from '@octaved/planning/src/Modules/Milestones';
import {
  minMaxPlanningDateInSubtreesReducer,
  minMaxPlanningDateInSubtreesRootReducer,
  minMaxPlanningDateInSubtreesStateReducer,
} from '@octaved/planning/src/Modules/MinMaxPlanningDateInSubtrees';
import {
  planningBaselineHistoryEntityStateReducer,
  planningBaselineHistoryReducer,
} from '@octaved/planning/src/Modules/PlanningBaselineHistory';
import {
  planningBaselineReducer,
  planningBaselinesEntityStateReducer,
} from '@octaved/planning/src/Modules/PlanningBaselines';
import {planningDateEntityStateReducer, planningDateReducer} from '@octaved/planning/src/Modules/PlanningDates';
import {
  planningDependencyEntityStateReducer,
  planningDependencyReducer,
} from '@octaved/planning/src/Modules/PlanningDependencies';
import {reinitializeSimulationReducer} from '@octaved/planning/src/Modules/ReinitializeSimulation';
import {uiPlanningReducer} from '@octaved/planning/src/Modules/Ui';
import {workloadReducer, workloadStateReducer} from '@octaved/planning/src/Modules/Workload';
import {rightsReducer, rightsStateReducer} from '@octaved/security/src/Modules/Rights';
import {
  guestRoleRightsReducer,
  guestRoleRightsStateReducer,
} from '@octaved/security/src/Modules/RoleRights/GuestRoleRights';
import {
  reducer as internalRoleRightMatrix,
  stateReducer as internalRoleRightMatrixState,
} from '@octaved/security/src/Modules/RoleRights/InternalRoleRights';
import {combiner} from '@octaved/store/src/Reducer/Combiner';
import {combineEntitiesReducers, simpleReducer} from '@octaved/store/src/Reducer/ReduxEntities';
import {storageMiddlwareRootReducer} from '@octaved/store/src/StorageMiddleware';
import {currentOrgUserReducer} from '@octaved/users/src/Modules/CurrentOrgUser';
import {entityReducer as groupEntityReducer, groupEntityStateReducer} from '@octaved/users/src/Modules/Group';
import {entityReducer as groupUserReducer} from '@octaved/users/src/Modules/GroupUsers';
import {orgUserReducer, orgUserStateReducer} from '@octaved/users/src/Modules/OrgUser';
import {reducer as uiPagesUserReducer} from '@octaved/users/src/Modules/UiPage';
import {unitListsReducer, unitListsStatesReducer} from '@octaved/users/src/Modules/UnitLists';
import {userGroupsReducers} from '@octaved/users/src/Modules/UserGroups';
import {workingTimeDaysReducer, workingTimeDaysStateReducer} from '@octaved/working-time/src/Modules/WorkingTimeDays';
import {
  workingTimeEntryForUserOnDateReducer,
  workingTimeEntryForUserOnDateStateReducer,
} from '@octaved/working-time/src/Modules/WorkingTimeEntriesForUserOnDate';
import {userSettingsMiddleWareRootReducer} from '../Store/UserSettingsMiddleware';
import {todaytodayIsoDateReduer} from '../Today';
import {accessibleTimeTrackingsReducer, accessibleTimeTrackingsStateReducer} from './AccessibleTimeTrackings';
import {auditReducer} from './Audit';
import {billingReducer, billingRootReducer, billingStateReducer} from './Billing/Billings';
import {billingSearchResultsReducer, billingSearchStateReducer} from './Billing/BillingSearch';
import {workPackageBillingReducer, workPackageBillingStateReducer} from './Billing/WorkPackageBilling';
import {customerReducer} from './Customers';
import {customerWebhookSearch} from './CustomerWebhookSearch';
import {globalRoleAssignmentsReducer, globalRoleAssignmentsStateReducer} from './GlobalRoleAssignments';
import {initDataEntitiesReducer, initDataRootReducer, initDataStateReducer} from './Initialization';
import {kanbanBoardEntityStateReducer, reducer as kanbanBoardReducer} from './KanbanBoard';
import {reducer as labelsReducer} from './Labels';
import {nodeHistoryEntityReducer, nodeHistoryEntityStateReducer} from './NodeHistorys';
import {nodeEntityReducer, nodeEntityStateReducer, nodesRootReducer} from './Nodes';
import {nodeSearchResultsReducer, nodeSearchRootReducer, nodeSearchStateReducer} from './NodeSearch';
import {nodeSearchListenersRootReducer} from './NodeSearchListeners/Common';
import {nodeTreeReducer, nodeTreeStateReducer} from './NodeTree';
import {priceCategoryReducer} from './PriceCategories';
import {priceSurchargeReducer} from './PriceSurcharges';
import {ancestorBudgetSumsReducer, ancestorBudgetSumsStateReducer} from './Projects/AncestorBudgetSums';
import {
  criticalTimePrognosisReducer,
  criticalTimePrognosisStateReducer,
} from './Projects/ProjectControlling/CriticalTimePrognosis';
import {
  degreeOfCompletionReducer,
  degreeOfCompletionStateReducer,
} from './Projects/ProjectControlling/DegreeOfCompletion';
import {dueDatePerNodeReducer, dueDatePerNodeStateReducer} from './Projects/ProjectControlling/DueDatePerNode';
import {lastActivitiesReducer, lastActivitiesStateReducer} from './Projects/ProjectControlling/LastActivities';
import {
  lastActivitiesPerWorkPackageReducer,
  lastActivitiesPerWorkPackageStateReducer,
} from './Projects/ProjectControlling/LastActivitiesPerWorkPackage';
import {trackedTimePerDayReducer, trackedTimePerDayStateReducer} from './Projects/ProjectControlling/TrackedTimePerDay';
import {
  trackedTimePerWorkPackageReducer,
  trackedTimePerWorkPackageStateReducer,
} from './Projects/ProjectControlling/TrackedTimePerWorkPackage';
import {trackedTimeQuotasReducer, trackedTimeQuotasStateReducer} from './Projects/ProjectControlling/TrackedTimeQuotas';
import {reconnectReducer} from './Reconnect';
import {responsibleNodeBulkPatchRootReducer} from './ResponsibleNode';
import {restoreFromTrashReducer} from './RestoreFromTrash/RestoreFromTrash';
import {
  nodeGuestPermissionRoleAssignmentsReducer,
  nodeGuestPermissionRoleAssignmentsStatesReducer,
} from './RoleAssignments/NodeGuestPermissionRoleAssignments';
import {
  nodeInternalPermissionRoleAssignmentsReducer,
  nodeInternalPermissionRoleAssignmentsStatesReducer,
} from './RoleAssignments/NodeInternalPermissionRoleAssignments';
import {
  nodeProjectRoleAssignmentsReducer,
  nodeProjectRoleAssignmentsStateReducer,
} from './RoleAssignments/NodeProjectRoleAssignments';
import {roleReducer, roleStateReducer} from './Roles';
import {rootFoldersReducer} from './RootFolders';
import {reducer as settings} from './Settings';
import {EntityState, FlowState} from './State';
import {statisticsReducer} from './Statistics';
import {subtreeStatsRootReducer} from './Statistics/SubtreeStats';
import {taskStatusHistoryReducer, taskStatusHistoryStateReducer} from './TaskStatusHistory';
import {timeRecordEntityReducer, timeRecordEntityStateReducer} from './TimeRecords';
import {timeRecordSearchReducer, timeRecordSearchStateReducer} from './TimeRecordSearch';
import {trackedTimeSumsInSubtreeReducer, trackedTimeSumsInSubtreeStateReducer} from './TrackedTimeSumsInSubtree';
import {reducer as uiReducer} from './Ui';
import {projectsUiRootReducer} from './UiPages/Projects';
import {deprecatedUserIdsReducers} from './Users/DeprecatedUsers';
import {userTimeSheetInPeriodSumsReducer, userTimeSheetInPeriodSumsStateReducer} from './UserTimeSheetInPeriodSums';
import {workTimesReducer, workTimesStateReducer} from './WorkTimes/WorkTimeDefinitions';

const [entityReducer, addEntityReducers, removeEntityReducers] = combineEntitiesReducers<EntityState>({
  billing: billingReducer,
  customer: customerReducer,
  groupUsers: groupUserReducer,
  inbox: inboxReducer,
  kanbanBoard: kanbanBoardReducer,
  label: labelsReducer,
  milestone: milestoneReducer,
  node: nodeEntityReducer,
  nodeHistory: nodeHistoryEntityReducer,
  nodeRole: simpleReducer,
  organizationAllowedLoginIP,
  orgUser: orgUserReducer,
  planningBaseline: planningBaselineReducer,
  planningBaselineHistory: planningBaselineHistoryReducer,
  planningDate: planningDateReducer,
  planningDependency: planningDependencyReducer,
  priceCategory: priceCategoryReducer,
  priceSurcharge: priceSurchargeReducer,
  taskStatusHistory: taskStatusHistoryReducer,
  timeRecord: timeRecordEntityReducer,
  userGroup: groupEntityReducer,
  workload: workloadReducer,
  workPackageBilling: workPackageBillingReducer,
});

export {addEntityReducers, removeEntityReducers};

export const reducer = combiner<FlowState>([
  {
    ancestorBudgetSums: {
      data: ancestorBudgetSumsReducer,
      state: ancestorBudgetSumsStateReducer,
    },
    audit: auditReducer,
    billing: {
      search: {
        results: billingSearchResultsReducer,
        state: billingSearchStateReducer,
      },
    },
    currentIdentity: identityReducer,
    currentOrganization: organizationReducer,
    currentOrgUser: currentOrgUserReducer,
    customerWebhookSearch,
    deprecatedUserIds: deprecatedUserIdsReducers,
    entities: combiner([entityReducer, initDataEntitiesReducer]),
    entityStates: {
      billing: billingStateReducer,
      inbox: inboxStateReducer,
      kanbanBoard: kanbanBoardEntityStateReducer,
      milestone: milestoneEntityStateReducer,
      node: nodeEntityStateReducer,
      nodeHistory: nodeHistoryEntityStateReducer,
      orgUser: orgUserStateReducer,
      planningBaseline: planningBaselinesEntityStateReducer,
      planningBaselineHistory: planningBaselineHistoryEntityStateReducer,
      planningDate: planningDateEntityStateReducer,
      planningDependency: planningDependencyEntityStateReducer,
      taskStatusHistory: taskStatusHistoryStateReducer,
      timeRecord: timeRecordEntityStateReducer,
      userGroup: groupEntityStateReducer,
      workloadState: workloadStateReducer,
      workPackageBilling: workPackageBillingStateReducer,
    },
    environment: environmentStateReducer,
    identity: {
      accessKeys: {
        data: accessKeysReducer,
        state: accessKeysStateReducer,
      },
      accessTokens: {
        data: accessTokensReducer,
        state: accessTokensStateReducer,
      },
    },
    inboxSearch: {
      searchResults: inboxSearchReducer,
      searchState: inboxSearchStateReducer,
    },
    initDataState: initDataStateReducer,
    integration: integrationStateReducers,
    minMaxPlanningDateInSubtrees: {
      result: minMaxPlanningDateInSubtreesReducer,
      state: minMaxPlanningDateInSubtreesStateReducer,
    },
    nodeSearch: {
      searchResults: nodeSearchResultsReducer,
      searchState: nodeSearchStateReducer,
    },
    nodeTree: nodeTreeReducer,
    nodeTreeState: nodeTreeStateReducer,
    projectControlling: {
      criticalTimePrognosis: {
        data: criticalTimePrognosisReducer,
        state: criticalTimePrognosisStateReducer,
      },
      degreeOfCompletion: {
        data: degreeOfCompletionReducer,
        state: degreeOfCompletionStateReducer,
      },
      dueDatePerNode: {
        data: dueDatePerNodeReducer,
        state: dueDatePerNodeStateReducer,
      },
      lastActivities: {
        data: lastActivitiesReducer,
        state: lastActivitiesStateReducer,
      },
      lastActivitiesPerWorkPackage: {
        data: lastActivitiesPerWorkPackageReducer,
        state: lastActivitiesPerWorkPackageStateReducer,
      },
      trackedTimePerDay: {
        data: trackedTimePerDayReducer,
        state: trackedTimePerDayStateReducer,
      },
      trackedTimePerWorkPackage: {
        data: trackedTimePerWorkPackageReducer,
        state: trackedTimePerWorkPackageStateReducer,
      },
      trackedTimeQuotas: {
        data: trackedTimeQuotasReducer,
        state: trackedTimeQuotasStateReducer,
      },
    },
    quota,
    restoreFromTrash: restoreFromTrashReducer,
    roles: {
      accessibleTimeTrackings: accessibleTimeTrackingsReducer,
      accessibleTimeTrackingsStates: accessibleTimeTrackingsStateReducer,
      globalRoleAssignments: globalRoleAssignmentsReducer,
      globalRoleAssignmentStates: globalRoleAssignmentsStateReducer,
      nodeGuestPermissionRoleAssignments: nodeGuestPermissionRoleAssignmentsReducer,
      nodeGuestPermissionRoleAssignmentsStates: nodeGuestPermissionRoleAssignmentsStatesReducer,
      nodeInternalPermissionRoleAssignments: nodeInternalPermissionRoleAssignmentsReducer,
      nodeInternalPermissionRoleAssignmentsStates: nodeInternalPermissionRoleAssignmentsStatesReducer,
      nodeProjectRoleAssignments: nodeProjectRoleAssignmentsReducer,
      nodeProjectRoleAssignmentsStates: nodeProjectRoleAssignmentsStateReducer,
      rights: {
        data: rightsReducer,
        state: rightsStateReducer,
      },
      roleRights: {
        guest: {
          matrix: guestRoleRightsReducer,
          state: guestRoleRightsStateReducer,
        },
        internal: {
          matrix: internalRoleRightMatrix,
          state: internalRoleRightMatrixState,
        },
      },
      roles: roleReducer,
      rolesState: roleStateReducer,
    },
    rootFolders: rootFoldersReducer,
    settings,
    statistics: statisticsReducer,
    timeRecordSearch: timeRecordSearchReducer,
    timeRecordSearchState: timeRecordSearchStateReducer,
    todayIsoDate: todaytodayIsoDateReduer,
    trackedTimeSumsInSubtree: {
      data: trackedTimeSumsInSubtreeReducer,
      state: trackedTimeSumsInSubtreeStateReducer,
    },
    ui: combiner([
      {pages: {organization: uiPagesOrganizationReducer, planning: uiPlanningReducer, users: uiPagesUserReducer}},
      uiReducer,
    ]),
    unitLists: {
      lists: unitListsReducer,
      states: unitListsStatesReducer,
    },
    userGroups: userGroupsReducers,
    userTimeSheetInPeriodSums: {
      data: userTimeSheetInPeriodSumsReducer,
      state: userTimeSheetInPeriodSumsStateReducer,
    },
    workingTime: {
      days: workingTimeDaysReducer,
      daysState: workingTimeDaysStateReducer,
      entriesForUsersOnDates: workingTimeEntryForUserOnDateReducer,
      entriesForUsersOnDatesState: workingTimeEntryForUserOnDateStateReducer,
    },
    workTime: {
      data: workTimesReducer,
      state: workTimesStateReducer,
    },
  },
  initDataRootReducer,
  projectsUiRootReducer,
  nodesRootReducer,
  nodeSearchRootReducer,
  nodeSearchListenersRootReducer,
  subtreeStatsRootReducer,
  responsibleNodeBulkPatchRootReducer,
  billingRootReducer,
  minMaxPlanningDateInSubtreesRootReducer,
  userSettingsMiddleWareRootReducer,
  storageMiddlwareRootReducer,
  reconnectReducer,
  reinitializeSimulationReducer,
]);
