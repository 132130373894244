import {FlexItem, HStack} from '@octaved/ui';
import {ReactElement, ReactNode, useMemo} from 'react';
import {PageHelpIdents} from '../../../Modules/Ui/Help';
import PageContent from '../../PageFrame/PageContent/PageContent';
import PageContentHeader from '../../PageFrame/PageContent/PageContentHeader';
import PageContentHelp, {PageContentHelpProps} from '../../PageFrame/PageContent/PageContentHelp';
import StandardPageContent from '../../PageFrame/PageContent/StandardPageContent';
import StandardPageFrame from '../../PageFrame/StandardPageFrame';
import StandardPageFrameContent from '../../PageFrame/StandardPageFrameContent';
import StandardPageFrameHeader from '../../PageFrame/StandardPageFrameHeader';
import ProjectsContentHeader from '../ProjectsContentHeader';
import ProjectsHeader from '../ProjectsHeader';
import {ProjectsHeaderFilters, ProjectsHeaderMenuOptions} from '../ProjectsHeaderFilters';
import {ProjectFrameContextProvider, ProjectFrameDynamicContext} from './ProjectsFrameContext';
import ProjectsProjectFolderContext from './ProjectsProjectFolderContext';

export interface ProjectContentFrameProps extends ProjectFrameDynamicContext, ProjectsHeaderMenuOptions {
  children: ReactNode;
  fixedMinWidth?: boolean;
  headerFilters?: ProjectsHeaderFilters;
  helpId: PageHelpIdents;
  helpFooterRows?: ReactElement;
}

export default function ProjectContentFrame({
  advancedFilter,
  children,
  contentHeader,
  contentHeaderVertical,
  headerFilters,
  helpId,
  helpFooterRows,
  infoToken,
  menuOptions,
  noProjectFolder,
  showCreationButtons,
}: ProjectContentFrameProps & PageContentHelpProps): ReactElement {
  const hasContent = useMemo(() => !!contentHeader || !!showCreationButtons, [contentHeader, showCreationButtons]);
  return (
    <ProjectFrameContextProvider
      advancedFilter={advancedFilter}
      contentHeader={contentHeader}
      showCreationButtons={showCreationButtons}
      contentHeaderVertical={contentHeaderVertical}
    >
      <ProjectsProjectFolderContext isDisabled={!!noProjectFolder}>
        <StandardPageFrame>
          <StandardPageFrameHeader>
            <ProjectsHeader Filters={headerFilters} menuOptions={menuOptions} />
          </StandardPageFrameHeader>
          <StandardPageFrameContent>
            <StandardPageContent>
              {hasContent && (
                <PageContentHeader>
                  <ProjectsContentHeader />
                </PageContentHeader>
              )}
              <PageContentHelp infoToken={infoToken} helpId={helpId} footerRows={helpFooterRows} />
              <PageContent noScroll>
                <HStack height={'100%'} width={'100%'} alignItems={'stretch'} overflow={'hidden'}>
                  <FlexItem flexGrow={1} width={'100%'} overflow={'hidden'}>
                    <PageContent contentPadding>{children}</PageContent>
                  </FlexItem>
                </HStack>
              </PageContent>
            </StandardPageContent>
          </StandardPageFrameContent>
        </StandardPageFrame>
      </ProjectsProjectFolderContext>
    </ProjectFrameContextProvider>
  );
}
