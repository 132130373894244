import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import Konva from 'konva';
import {Rect} from 'konva/lib/shapes/Rect';
import {Flame} from 'lucide';
import {DueDateStatus, getDueDateStatusSelector} from '../../../Selectors/PlanningDependencySelectors';
import {TreeNodeEnumFlowNodeType} from '../../Gantt/Data/TreeNode';
import {createStoreSubscription} from '../../StoreSubscription';
import {Bar} from '../Bar';
import {BarPlugin, BarPluginProps} from './BarPlugin';

interface DueDateRenderPluginProps extends BarPluginProps {
  hasMilestone: boolean;
  dueDateId: Uuid;
  nodeType: TreeNodeEnumFlowNodeType;
}

export class DueDateRenderPlugin extends BarPlugin {
  hasMilestone: boolean;
  dueDateId: Uuid;
  #iconSize = 0;
  #nodeType: TreeNodeEnumFlowNodeType;

  static createDueDateRect(
    size: number,
    hasMilestone = false,
    nodeType: TreeNodeEnumFlowNodeType = EnumFlowNodeType.VALUE_PROJECT,
  ): Rect {
    let fillLinearGradientColorStops: [number, string, number, string];
    if (nodeType === EnumFlowNodeType.VALUE_PROJECT) {
      if (hasMilestone) {
        fillLinearGradientColorStops = [0, 'rgba(132, 5, 250, 0)', 1, '#ae58fe'];
      } else {
        fillLinearGradientColorStops = [0, '#8405fa', 1, '#ae58fe'];
      }
    } else {
      if (hasMilestone) {
        fillLinearGradientColorStops = [0, '#25B3FE', 1, '#6A04C8'];
      } else {
        fillLinearGradientColorStops = [0, '#6A04C8', 1, '#9525FE'];
      }
    }

    return new Rect({
      fillLinearGradientColorStops,
      cornerRadius: 2,
      fillLinearGradientEndPoint: {x: 0, y: 0},
      fillLinearGradientStartPoint: {x: 0, y: size},
      height: size,
      rotation: 45,
      width: size,
    });
  }

  #rect: Rect | null = null;
  #icon: Konva.Image | null = null;

  constructor(props: DueDateRenderPluginProps) {
    super(props);
    this.hasMilestone = props.hasMilestone;
    this.dueDateId = props.dueDateId;
    this.#nodeType = props.nodeType;
  }

  init(bar: Bar): void {
    this.#iconSize = bar.height - 2;
    this.#rect = DueDateRenderPlugin.createDueDateRect(bar.height - 4, this.hasMilestone, this.#nodeType);
    super.init(bar);
    this.root.add(this.#rect);

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getDueDateStatusSelector(s)(this.dueDateId),
        () => this.#updateIcons(),
      ),
    );

    if (this.#icon === null) {
      this.#createIcon().then((icon) => {
        this.#icon = icon;
        this.root.add(icon);

        this.#updateIcons();
        this.onBarUpdated();
      });
    }
  }

  #createIcon(): Promise<Konva.Image> {
    return this.iconToImage(Flame, {
      height: this.#iconSize,
      imageConfig: {
        y: 0,
      },
      style: {
        fill: '#f00',
        stroke: '#fff',
        strokeWidth: '1px',
      },
      width: this.#iconSize,
    });
  }

  #updateIcons(): void {
    const dueDateStatus = getDueDateStatusSelector(this.ctx.store.getState())(this.dueDateId);
    if (dueDateStatus === DueDateStatus.allWorkPackagesDone) {
      this.#rect?.visible(false);
      this.#icon?.visible(false);
    } else if (dueDateStatus === DueDateStatus.inFuture) {
      this.#rect?.visible(true);
      this.#icon?.visible(false);
    } else if (dueDateStatus === DueDateStatus.notAllWorkPackagesDone) {
      this.#rect?.visible(false);
      this.#icon?.visible(true);
    }
  }

  onBarUpdated(): void {
    const baseX = this.bar.width + this.bar.barPaddingX;
    this.#rect?.x(baseX);
    this.#icon?.x(baseX - this.#iconSize * 0.5);
  }
}
