import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {ObjectSnapshot} from '@octaved/hooks';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {MutableRefObject, createContext} from 'react';
import {BaseBoardPost, BoardPost} from '../../../EntityInterfaces/BoardPost';
import {NodeHistoryDiffFactory} from '../../../EntityInterfaces/NodeHistory';
import {BoardPostTypeConfig} from '../../../Modules/BoardPosts/BoardPostTypeRegistry';
import {BoardPostData} from '../../../Modules/BoardPosts/BoardPosts';
import {RichtTextEditorRef} from '../../Editor/RichTextEditor';

export enum BoardPostSaveState {
  isUnsaved,
  isSaved,
  isPosted,
}

export enum BoardPostViewMode {
  view,
  editing,
  fullHistory,
}

export interface GetTextStateFn<BP extends BaseBoardPost = BaseBoardPost> {
  (editor: RichtTextEditorRef | null): DeepPartial<BoardPostData<BP>>;
}

export interface BoardPostContext<BP extends BaseBoardPost = BaseBoardPost> {
  boardPost: NodeHistoryDiffFactory<BP>;

  cancelEditing(): void;

  config: BoardPostTypeConfig<BaseBoardPost>;
  editorRef: MutableRefObject<RichtTextEditorRef | null>;
  getTextStateRef: MutableRefObject<GetTextStateFn<BP> | null>;
  id: Uuid;
  noRemove?: boolean;
  objectSnapshot: ObjectSnapshot<BoardPostData<BP>, BoardPostData<BP>>;
  parentNodeId: Uuid;
  preview?: boolean;
  publish: () => boolean;
  removePost: () => boolean;
  rightLookupNodeId: Uuid;
  save: () => boolean;
  saveState: BoardPostSaveState;
  setShowPersonalVersions: (show: boolean) => void;
  showPersonalVersions: boolean;

  startEditing(): void;

  startEditingNoReset(): void;

  viewMode: BoardPostViewMode;
  viewingNodeId: Uuid;
  viewingNodeType: EnumFlowNodeType;
  isFirst: boolean;
  isLast: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const boardPostContext = createContext<BoardPostContext<any>>({} as unknown as BoardPostContext);

export interface PostWithState {
  post: BoardPost;
  state: BoardPostSaveState;
}
