import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {SubtreeTaskCountsAndTimeSums} from '@octaved/flow/src/EntityInterfaces/Statistics/SubtreeStats';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {useLoadedNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import NodeTasksReliabilityMeter from '../../Controlling/Common/Components/NodeTasks/NodeTasksReliabilityMeter';
import TaskEffortExceedsTimeLimitSection from '../Tiles/Components/TaskEffortExceedsTimeLimitSection';

function useOfferTaskStats(
  nodeId: Uuid,
): Pick<SubtreeTaskCountsAndTimeSums, 'totalCount' | 'totalTimeSum' | 'withTimeCount'> {
  const {nodeIds} = useCombinedNodeSearch({
    and: [withDescendants(nodeId, true), ['nodeType', EnumFlowNodeType.VALUE_TASK]],
  });
  const {nodes} = useLoadedNodes<Task>(nodeIds);
  const sum = {totalCount: 0, totalTimeSum: 0, withTimeCount: 0};
  nodes.forEach((task) => {
    sum.totalCount++;
    if (task.plannedTime) {
      sum.totalTimeSum += task.plannedTime;
      sum.withTimeCount++;
    }
  });
  return sum;
}

interface NodeTasksTileProps {
  node: NodeType;
  showReliabilityMeter?: boolean;
}

export default function OfferTasks({node, showReliabilityMeter}: NodeTasksTileProps): ReactElement | null {
  const {t} = useTranslation();
  const stats = useOfferTaskStats(node.id);
  const canReadTaskEffort = useIsGranted('FLOW_NODE_TASK_READ_EFFORT', node.id);
  if (!stats) {
    return null;
  }
  return (
    <>
      <div className={'grid grid-cols-[max-content_auto] gap-x-5'}>
        <div>{t('nodeDetails:field.tasks.count.label')}:</div>
        <div>{formatDecimal(stats.totalCount)}</div>

        {canReadTaskEffort && (
          <>
            <div>{t('nodeDetails:field.tasks.hours.label')}:</div>
            <div>
              {t('general:xHours', {
                count: Math.round(stats.totalTimeSum),
                hours: formatDecimal(stats.totalTimeSum, stats.totalTimeSum < 100 ? 2 : 0),
              })}
            </div>
          </>
        )}
      </div>

      {canReadTaskEffort && (
        <>
          {node.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE && (
            <TaskEffortExceedsTimeLimitSection node={node} totalTaskSum={stats.totalTimeSum} />
          )}
          {showReliabilityMeter && <NodeTasksReliabilityMeter className={'mt-1'} stats={stats} />}
        </>
      )}
    </>
  );
}
