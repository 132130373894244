import {workdays} from '@octaved/planning/src/Calculations/WorkdayCalculations';
import {PlanningDate, PlanningDatesList} from '@octaved/planning/src/EntityInterfaces/PlanningDates';
import {fromIsoDateTimeFormat, isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useFormattedDateTextRange} from '../../../../../../Hooks/FormattedDateText';
import {getOrgWorkMinutesAtDateSelector} from '../../../../../../Modules/Selectors/WorkTimeSelectors';

interface PlanningDatesRowProps {
  planningDate: PlanningDate;
  planningDates: PlanningDatesList;
  index: number;
}

export default function PlanningDatesRow({planningDate, planningDates, index}: PlanningDatesRowProps): ReactElement {
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const format = useFormattedDateTextRange();
  return (
    <>
      <div className={'content'}>{planningDates.length > 1 && index + 1}</div>
      <div className={'content'}>
        {format(isoDateTimeToIsoFormat(planningDate.plannedStart), isoDateTimeToIsoFormat(planningDate.plannedEnd))}
      </div>
      <div className={'content workDays'}>
        {workdays(
          fromIsoDateTimeFormat(planningDate.plannedStart),
          fromIsoDateTimeFormat(planningDate.plannedEnd),
          getOrgWorkMinutesAtDate,
        )}
      </div>
      {/*language=SCSS*/}
      <style jsx>{`
        .content {
          padding: 6px 12px;
          font-size: 13px;
        }

        .workDays {
          text-align: right;
        }
      `}</style>
    </>
  );
}
