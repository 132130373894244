import classNames from 'classnames';
import {cloneElement, forwardRef, HTMLAttributes, memo, ReactElement, useMemo} from 'react';
import css from 'styled-jsx/css';

import {FlowStyleBuilder, useFlowStyle} from '@octaved/flow/src/Styles/StyleContext';

//#region <styles>
/*language=SCSS*/
const style: FlowStyleBuilder = ({ANIMATIONS: {FAST}, COLORS: {ICON}}) => css.resolve`
  .flowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${ICON.FILL.DEFAULT};
    margin-right: 0.5em;
    flex-shrink: 0;
    height: 1em;
    width: auto;
    height: 1em;
    width: 1em;
    object-fit: cover;

    &.inline {
      display: inline-flex;
      align-items: center;
      vertical-align: -0.175rem;
    }
  }

  .animated {
    transition: rotate ${FAST};
  }

  .flowColor-blue {
    color: ${ICON.FILL.BLUE.DEFAULT};
  }

  .flowColor-brightBlue {
    color: ${ICON.FILL.BLUE.BRIGHT};
  }

  .flowColor-orange {
    color: ${ICON.FILL.ORANGE.DEFAULT};
  }

  .flowColor-green {
    color: ${ICON.FILL.GREEN.DEFAULT};
  }

  .flowColor-darkGreen {
    color: ${ICON.FILL.GREEN.ALTERNATIVE};
  }

  .flowColor-darkBlue {
    color: ${ICON.FILL.BLUE.ALTERNATIVE};
  }

  .flowColor-white {
    color: ${ICON.FILL.WHITE.DEFAULT};
  }

  .flowColor-darkGrey {
    color: ${ICON.FILL.GREY.DARK};
  }

  .flowColor-light {
    color: ${ICON.FILL.LIGHT.DEFAULT};
  }

  .flowColor-yellow {
    color: ${ICON.FILL.YELLOW.DEFAULT};
  }

  .flowColor-purple {
    color: ${ICON.FILL.PURPLE.DEFAULT};
  }

  .flowColor-darkPurple {
    color: ${ICON.FILL.PURPLE.DARK};
  }

  .flowColor-darkerPurple {
    color: #65077b;
  }

  .flowColor-red {
    color: ${ICON.FILL.RED.DEFAULT};
  }

  .flowColor-brightGreen {
    color: ${ICON.FILL.GREEN.BRIGHT};
  }

  .flowColor-brightGrey {
    color: ${ICON.FILL.GREY.BRIGHT};
  }

  .flowColor-black {
    color: ${ICON.FILL.BLACK.DEFAULT};
  }

  .flowColor-pageMenuBlue {
    color: #2c5282;
  }

  .flowColor-favorite {
    color: #f0ae04;
  }

  .flowIcon.flowSize-large {
    font-size: 1.2em;
  }

  .flowIcon.flowSize-big {
    font-size: 1.3em;
  }

  .flowIcon.flowSize-larger {
    font-size: 1.6em;
  }

  .flowIcon.flowSize-bigger {
    font-size: 2em;
  }

  .flowIcon.flowSize-huge {
    font-size: 3em;
  }

  .flowIcon.flowSize-massive {
    font-size: 5em;
  }

  .flowIcon.flowSize-small {
    font-size: 0.8em;
  }

  .flowIcon.flowSize-tiny {
    font-size: 0.4em;
  }

  .flowIcon.flowSize-pagemenu {
    font-size: 21px;
  }

  .noMargin {
    margin-right: 0;
  }

  .marginLeft {
    margin-right: 0;
    margin-left: 0.5em;
  }

  .faIcon {
    fill: currentColor;
  }

  .flowIcon[rotate='90deg'] {
    transform: rotate(90deg);
  }
`;

export type IconColor =
  | 'black'
  | 'blue'
  | 'brightBlue'
  | 'brightGreen'
  | 'brightGrey'
  | 'darkBlue'
  | 'darkerPurple'
  | 'darkGreen'
  | 'darkGrey'
  | 'darkPurple'
  | 'favorite'
  | 'green'
  | 'grey'
  | 'light'
  | 'orange'
  | 'pageMenuBlue'
  | 'purple'
  | 'red'
  | 'white'
  | 'yellow';

export type IconSize =
  | 'normal'
  | 'large'
  | 'larger'
  | 'big'
  | 'bigger'
  | 'massive'
  | 'huge'
  | 'small'
  | 'pagemenu'
  | 'tiny';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
  children: ReactElement;
  iconColor?: IconColor;
  inline?: boolean;
  noMargin?: boolean;
  marginLeft?: boolean;
  size?: IconSize;
  rotate?: number;
  animated?: boolean;
}

const Icon = memo(
  forwardRef<HTMLDivElement, Props>(function Icon(
    {
      children,
      className: transferedClassName,
      iconColor = 'grey',
      inline = false,
      noMargin = false,
      marginLeft = false,
      size = 'normal',
      animated = false,
      rotate,
      ...divProps
    },
    ref,
  ): ReactElement {
    const {className, styles} = useFlowStyle(style);
    const props = useMemo(() => {
      return {
        ref,
        className: classNames(
          className,
          transferedClassName,
          'flowIcon',
          `flowSize-${size}`,
          `flowColor-${iconColor}`,
          {
            animated,
            inline,
            marginLeft,
            noMargin,
            //Only font awesome icons have propTypes set, lucide-dev have undefined prop types
            faIcon: !(children.type as {displayName?: string}).displayName,
          },
        ),
        style: {
          transform: rotate ? `rotate(${rotate}deg)` : undefined,
        },
        ...divProps,
      };
    }, [
      className,
      children,
      animated,
      ref,
      divProps,
      iconColor,
      inline,
      marginLeft,
      noMargin,
      rotate,
      size,
      transferedClassName,
    ]);

    return (
      <>
        {cloneElement(children, props)}
        {styles}
      </>
    );
  }),
);

export {Icon};
