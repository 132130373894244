import {ReactElement} from 'react';

function FlexSpacer(): ReactElement {
  return (
    <div className={'spacer'}>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .spacer {
          flex: 1;
          justify-self: stretch;
          align-self: stretch;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}

export {FlexSpacer};
