import {mergeStates} from '@octaved/store/src/MergeStates';
import {DeepPartial} from '@octaved/typescript/src/lib';
import {useCallback, useMemo, useRef} from 'react';
import {FilterState} from '../../EntityInterfaces/Filter/FilterState';

//Allow deep-patching with setValue if value is an object:
type SetValueArg<T> = T extends unknown[] ? T : T extends Record<keyof T, unknown> ? DeepPartial<T> : T;

export interface UseEditFilterStateResult<T> {
  isActive: boolean;
  setValue: (value: SetValueArg<T>, forceActive?: boolean) => void;
  toggleActive: (active?: boolean) => void;
  value: T;
}

export interface UseEditFilterState<T, Ident extends string> {
  (ident: Ident): UseEditFilterStateResult<T>;
}

export function useEditFilterState<K extends string, S extends Partial<Record<K, FilterState<unknown>>>, V = S[K]>(
  filterStates: S,
  setFilterStates: (filterStates: S) => void,
  ident: K,
  defaultValue: V,
): UseEditFilterStateResult<V> {
  const filterStatesRef = useRef(filterStates);
  filterStatesRef.current = filterStates;

  const filterState = useMemo(
    () =>
      (filterStates[ident] || {
        isActive: false,
        value: defaultValue,
      }) as FilterState<V>,
    [filterStates, defaultValue, ident],
  );
  const filterStateRef = useRef(filterState);
  filterStateRef.current = filterState;

  const patch = useCallback(
    (partial: Partial<FilterState<V>>) => {
      filterStateRef.current = mergeStates(filterStateRef.current, partial as DeepPartial<FilterState<V>>);
      filterStatesRef.current = mergeStates<S>(filterStatesRef.current, {
        [ident]: filterStateRef.current,
      } as unknown as DeepPartial<S>);
      setFilterStates(filterStatesRef.current);
    },
    [ident, setFilterStates],
  );

  const setValue = useCallback(
    (partial: SetValueArg<V>, forceActive?: boolean) => {
      const patchObj = {value: partial} as Partial<FilterState<V>>;
      if (forceActive) {
        patchObj.isActive = true;
      }
      patch(patchObj);
    },
    [patch],
  );

  const toggleActive = useCallback(
    (active?: boolean) => {
      const isActive = typeof active === 'boolean' ? active : !filterStateRef.current.isActive;
      const partial: Partial<FilterState<V>> = {isActive};
      if (!isActive) {
        partial.value = defaultValue;
      }
      patch(partial);
    },
    [defaultValue, patch],
  );

  return {
    setValue,
    toggleActive,
    isActive: filterState.isActive,
    value: filterState.value,
  };
}
