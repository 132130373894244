import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {QuickControlActivityDomain} from '../../../../EntityInterfaces/Filter/Custom/ControlActivity';
import {StoreLastActivities} from '../../../../EntityInterfaces/Projects/ProjectControlling/LastActivities';
import {
  LastActivitiesPerWorkPackage,
  StoreLastActivitiesPerWorkPackage,
} from '../../../../EntityInterfaces/Projects/ProjectControlling/LastActivitiesPerWorkPackage';
import {FlowState} from '../../../State';
import {useTimeTrackingSelector} from '../../SettingSelectors';
import {
  getProjectsFilterValueSelector,
  getWorkPackageIdsForRootNodeIdInMainProjectTreeSelector,
} from '../../UiPages/ProjectsSelector';

export const lastActivitiesSelector = (state: FlowState): StoreLastActivities =>
  state.projectControlling.lastActivities.data;
export const lastActivitiesStatesSelector = (state: FlowState): EntityStates =>
  state.projectControlling.lastActivities.state;
export const lastActivitiesPerWorkPackageSelector = (state: FlowState): StoreLastActivitiesPerWorkPackage =>
  state.projectControlling.lastActivitiesPerWorkPackage.data;
export const lastActivitiesPerWorkPackageStatesSelector = (state: FlowState): EntityStates =>
  state.projectControlling.lastActivitiesPerWorkPackage.state;

export function getRelevantActivityMaxDateForActiveDomains(
  domains: ReadonlySet<QuickControlActivityDomain>,
  activities: LastActivitiesPerWorkPackage,
): number {
  return Math.max(
    (domains.has('tasks') && activities.lastCompletedTaskDate) || 0,
    (domains.has('board') && activities.lastEditedBoardPostDate) || 0,
    (domains.has('execution') && activities.lastExecutedDate) || 0,
    (domains.has('timeTracking') && activities.lastTimeRecordDate) || 0,
  );
}

export const quickControlActivityDomainsSelector = createSelector(
  getProjectsFilterValueSelector,
  useTimeTrackingSelector,
  (getProjectsFilterValue, useTimeTracking): Set<QuickControlActivityDomain> => {
    const domains = getProjectsFilterValue('controlActivity', 'quickControlActivity').domains;
    return new Set(useTimeTracking ? domains : domains.filter((d) => d !== 'timeTracking'));
  },
);

/**
 * Gets the latest activity date as unix timestamp in the visible subtree
 */
export const getLatestActivityDateAmongDescendantsSelector = createSelector(
  getWorkPackageIdsForRootNodeIdInMainProjectTreeSelector,
  lastActivitiesPerWorkPackageSelector,
  quickControlActivityDomainsSelector,
  (getWorkPackageIds, activitiesPerWp, domains) =>
    memoize((nodeId: Uuid): number => {
      let latestTimestamp = 0;
      getWorkPackageIds(nodeId).forEach((wpId) => {
        const activities = activitiesPerWp[wpId];
        if (activities) {
          const max = getRelevantActivityMaxDateForActiveDomains(domains, activities);
          if (max > latestTimestamp) {
            latestTimestamp = max;
          }
        }
      });
      return latestTimestamp;
    }),
);
