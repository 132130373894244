import PlaceholderText from '@octaved/flow/src/Components/Feedback/Placeholder/PlaceholderText';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn, tableGrid, tableGridCellVariant} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import NodeListRow from './NodeListRow';

interface NodeListProps {
  dependencyList: Uuid[];
  headlineToken?: string;
  node: PlanningRealizationNode;
  readonly: boolean;
}

export default function NodeList({
  dependencyList,
  headlineToken = 'pages:projects.inspector.manage.planning.logicalDependency.headline',
  node,
  readonly,
}: NodeListProps): ReactElement {
  const {t} = useTranslation();
  if (dependencyList.length === 0) {
    return <PlaceholderText>{t('pages:projects.inspector.manage.planning.noDependenciesPlaceholder')}</PlaceholderText>;
  }
  return (
    <div className={cn(tableGrid({className: 'grid-cols-[1fr_max-content]', isBelowLabel: true}))}>
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'left'}))}>{t(headlineToken)}</div>
      <div className={cn(tableGridCellVariant({isHeader: true, position: 'right'}))}>&nbsp;</div>
      {dependencyList.map((predecessorId) => (
        <NodeListRow node={node} readonly={readonly} key={predecessorId} predecessorId={predecessorId} />
      ))}
    </div>
  );
}
