import {ReactElement} from 'react';
import ProjectsHeaderDefaultFilters from './ProjectsHeaderDefaultFilters';
import {ProjectsHeaderFilters, ProjectsHeaderMenuOptions} from './ProjectsHeaderFilters';

export default function ProjectsHeader({
  Filters = ProjectsHeaderDefaultFilters,
  menuOptions,
}: {Filters?: ProjectsHeaderFilters} & ProjectsHeaderMenuOptions): ReactElement {
  return <Filters menuOptions={menuOptions} />;
}
