import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWButton} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {NodeEntity} from '../../../../EntityInterfaces/NodeEntity';
import {editTimeRecord} from '../../../../Modules/UiPages/TimeRecords';
import {isWpOrSwp} from '../../../../Node/NodeIdentifiers';

interface Props {
  node: NodeEntity;
}

export default function CreateForOtherButton({node}: Props): ReactElement | null {
  const {t} = useTranslation();
  const canManageOthers = useIsGranted(
    'FLOW_NODE_PROJECT_TIME_TRACKING_MANAGE_BOOKABLE_OTHERS',
    node.id,
    'anyInTree+self',
  );
  const dispatch = useDispatch();
  if (!canManageOthers) {
    return null;
  }
  return (
    <>
      <TWButton
        onClick={() => {
          dispatch(
            editTimeRecord({
              referenceNode: isWpOrSwp(node) ? node.id : null,
              type: 'create',
              user: null,
            }),
          );
        }}
        variant={'outline'}
        size={'md'}
      >
        {t('pages:projects.drawer.pidViewTimeTrackings.createForOtherUser')}
      </TWButton>
    </>
  );
}
