import {EnumFlowNodeRoleType, EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {EntityState as SingleEntityState} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, mapCache, NIL} from '@octaved/utilities';
import {t} from 'i18next';
import {createSelector} from 'reselect';
import {StrictDropdownItemProps} from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';
import {isAssignableRole, noneRoles} from '../../Authorization/DefaultRoles';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {StoreRoles, TranslatedRole, TranslatedRoles, TranslatedStoreRoles} from '../../EntityInterfaces/Role';
import {FlowState} from '../State';

export const roleSelector = (state: FlowState): StoreRoles => state.roles.roles;
export const roleStateSelector = (state: FlowState): SingleEntityState => state.roles.rolesState;

export function createTranslatedDummyRole(type: EnumFlowRoleType, isGuestRole = false): TranslatedRole {
  return {
    isGuestRole,
    type,
    id: NIL,
    isBigBang: false,
    isProjectManagerRole: false,
    isTranslated: true,
    name: '',
    projectRolePermissionRole: null,
    weight: -1,
  };
}

export const getTranslatedRolesSelector = createSelector(roleSelector, (roles): TranslatedStoreRoles => {
  const newRoles: TranslatedStoreRoles = {} as unknown as TranslatedStoreRoles;
  Object.keys(roles).forEach((type) => {
    const tType = type as EnumFlowRoleType;
    newRoles[tType] = {...roles[tType]} as unknown as TranslatedRoles;
    Object.keys(newRoles[tType]).forEach((id) => {
      const role = roles[tType][id]!;
      newRoles[tType][id] = {
        ...role!,
        isTranslated: true,
        name: role.isBigBang ? t(role.name) : role.name,
      };
    });
  });
  return newRoles;
});

export const getSortedRolesSelector = createSelector(getTranslatedRolesSelector, (roles) =>
  mapCache((type: EnumFlowRoleType | EnumFlowNodeRoleType, isGuestRole: boolean): TranslatedRole[] => {
    return boolFilter(Object.values(roles[type]))
      .filter((role) => role.isGuestRole === isGuestRole && isAssignableRole(role.id))
      .sort((a, b) => {
        if (a.weight < b.weight) {
          return -1;
        }
        if (a.weight > b.weight) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
  }),
);

export const sortedProjectRolesSelector = createSelector(getSortedRolesSelector, (getSortedRoles) =>
  getSortedRoles(EnumFlowRoleType.VALUE_PROJECT, false),
);

export const sortedGuestPermissionRolesSelector = createSelector(getSortedRolesSelector, (getSortedRoles) =>
  getSortedRoles(EnumFlowRoleType.VALUE_PERMISSION, true),
);

export const sortedInternalPermissionRolesSelector = createSelector(getSortedRolesSelector, (getSortedRoles) =>
  getSortedRoles(EnumFlowRoleType.VALUE_PERMISSION, false),
);

export const sortedGlobalPermissionRolesSelector = createSelector(getSortedRolesSelector, (getSortedRoles) =>
  getSortedRoles(EnumFlowRoleType.VALUE_GLOBAL_PERMISSION, false),
);

const projectRolesArraySelector = createSelector(getTranslatedRolesSelector, (roles) =>
  boolFilter(Object.values(roles[EnumFlowNodeRoleType.VALUE_PROJECT])),
);

export const getDefaultRoleIdSelector = createSelector(getSortedRolesSelector, (getSortedRoles) =>
  mapCache(
    (type: EnumFlowNodeRoleType, isGuestRole: boolean): Uuid | undefined => getSortedRoles(type, isGuestRole)[0]?.id,
  ),
);

export const defaultProjectRoleIdSelector = createSelector(getDefaultRoleIdSelector, (getDefaultRoleId) =>
  getDefaultRoleId(EnumFlowNodeRoleType.VALUE_PROJECT, false),
);

export type RoleDropdownOption = Omit<StrictDropdownItemProps, 'value'> & {value: string};

export const getRolesDropdownOptionsSelector = createSelector(getSortedRolesSelector, (getSortedRoles) =>
  mapCache((type: EnumFlowNodeRoleType, isGuestRole: boolean): RoleDropdownOption[] => {
    return getSortedRoles(type, isGuestRole).map((role) => ({
      'data-role-id': role.id,
      text: role.name,
      value: role.id,
    }));
  }),
);

export const getRolesDropdownOptionsWithNone = createSelector(
  getRolesDropdownOptionsSelector,
  (getRolesDropdownOptions) =>
    mapCache((type: EnumFlowNodeRoleType, isGuestRole: boolean, noRoleText: string): RoleDropdownOption[] => {
      return [
        {
          text: noRoleText,
          value: noneRoles[type],
        },
        ...getRolesDropdownOptions(type, isGuestRole),
      ];
    }),
);

export const getSearchResultProjectRoleSelector = createSelector(projectRolesArraySelector, (projectRoles) =>
  projectRoles.map(({id, name}) => ({id, title: name})),
);

export const getProjectManagerRolesNodeSearchQuerySelector = createSelector(
  getSortedRolesSelector,
  (getSortedRoles) =>
    (unitId: Uuid): NodeSearchCondition => {
      return {
        or: getSortedRoles(EnumFlowRoleType.VALUE_PROJECT, false)
          .filter(({isProjectManagerRole}) => isProjectManagerRole)
          .map(({id}) => ['assignedProjectRoleId', `${unitId}@${id}`]),
      };
    },
);
