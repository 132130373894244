import {OptionalField, optionalFieldLoading} from '@octaved/hooks/src/OptionalFields';
import {Tooltip, TWButton} from '@octaved/ui';
import {Plus} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface EnableFieldButtonProps {
  field: OptionalField;
  label: string;
  tooltip?: string;
}

export default function EnableFieldButton({field, label, tooltip}: EnableFieldButtonProps): ReactElement | null {
  const {t} = useTranslation();

  //Do not render the button if the field is already shown or currently loading
  if (field.show === true || field.show === optionalFieldLoading) {
    return null;
  }

  return (
    <Tooltip toolTipTranslation={tooltip}>
      <TWButton size={'md'} withIcon onClick={field.enable} variant={'outline'}>
        <Plus size={16} />
        {t(label)}
      </TWButton>
    </Tooltip>
  );
}
