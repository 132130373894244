import {cn} from '@octaved/ui';
import {ReactElement} from 'react';

export interface PageContentHeaderProps {
  borderTop?: boolean;
  children?: ReactElement;
}

export default function PageContentHeader({borderTop, children}: PageContentHeaderProps): ReactElement {
  return (
    <div
      className={cn('border-b border-b-slate-200 bg-slate-50 px-6 py-2', borderTop && 'border-t border-t-slate-200')}
    >
      {children}
    </div>
  );
}

PageContentHeader.displayName = 'PageContentHeader';
