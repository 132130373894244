import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useLoadNode} from '../../Modules/Hooks/Nodes';
import {isGroup, isProject} from '../../Node/NodeIdentifiers';
import {getNodeSelector} from '../../Modules/Selectors/NodeSelectors';
import NewGroupFormBody from './NewGroupFormBody';

export default function NewGroupForm({
  relativeNodeId,
  after,
  before,
}: {
  relativeNodeId?: string;
  after?: boolean;
  before?: boolean;
}): ReactElement | null {
  const {newGroupRelativeNodeId} = useParams<'newGroupRelativeNodeId'>();
  useLoadNode(newGroupRelativeNodeId || relativeNodeId);
  const relative = useSelector(getNodeSelector)(newGroupRelativeNodeId || relativeNodeId);
  if (!relative) {
    return null;
  }
  if (!isProject(relative) && !isGroup(relative)) {
    throw new Error('Must know parent to copy data');
  }
  return <NewGroupFormBody relative={relative} after={after} before={before} />;
}
