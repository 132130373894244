import {PropsWithChildren, ReactElement} from 'react';
import {toast} from 'react-hot-toast';
import Close from '../../Dialogs/Components/Close';

interface Props {
  noClose?: boolean;
  toastId: string;
}

export default function SnackbarWrapper({children, noClose, toastId}: PropsWithChildren<Props>): ReactElement {
  return (
    <div className={'toastContent'}>
      {!noClose && (
        <div className={'close'}>
          <Close close={() => toast.dismiss(toastId)} />
        </div>
      )}
      {children}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .toastContent {
          padding: 0.4375rem 0;
        }

        .close {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
