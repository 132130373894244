import DiamondSvg from '@fortawesome/fontawesome-pro/svgs/solid/diamond.svg';
import {Icon} from '@octaved/ui';
import {ReactElement} from 'react';

interface Props {
  noMargin?: boolean;
}

export default function BilledIcon({noMargin}: Props): ReactElement {
  return (
    <div className={'icon'}>
      <Icon iconColor={'blue'} inline noMargin={noMargin}>
        <DiamondSvg />
      </Icon>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .icon {
          display: inline-block;
          vertical-align: middle;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
