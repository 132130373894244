import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {DateStr} from '@octaved/typescript';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {memoize} from 'lodash';
import {NodeSearchCondition} from '../../EntityInterfaces/NodeSearch';
import {FlowState} from '../../Modules/State';
import {ThunkAct} from '../../Store/Thunk';
import {createCircleOption} from '../Form/Dropdown/DropdownPopup/CircleOption';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../Form/Dropdown/DropdownPopup/Types';

export type VisibleTasks = 'onlyUnfinished' | 'doneToday' | 'all';

export function createVisibleTasksDropdownPopupItemOptions(
  selector: (state: FlowState) => VisibleTasks,
  setter: (state: VisibleTasks) => void | ThunkAct<void>,
): DropdownPopupItemOptions[] {
  return [
    {
      token: 'taskList:options.visibleTasks.header',
      type: DropdownPopupItemType.header,
    },
    createCircleOption('onlyUnfinished', selector, setter, 'taskList:options.visibleTasks.option.onlyUnfinished'),
    createCircleOption('doneToday', selector, setter, 'taskList:options.visibleTasks.option.doneToday'),
    createCircleOption('all', selector, setter, 'taskList:options.visibleTasks.option.showAll'),
  ];
}

export const getVisibleTasksSearchCondition = memoize(
  (visibleTasks: VisibleTasks, todayIsoDate: DateStr): NodeSearchCondition => {
    let condition: NodeSearchCondition;
    switch (visibleTasks) {
      case 'onlyUnfinished':
        condition = ['taskStatus', EnumFlowTaskStatus.VALUE_OPEN];
        break;
      case 'doneToday':
        condition = {
          or: [
            ['taskStatus', EnumFlowTaskStatus.VALUE_OPEN],
            ['taskCompletedInDateRange', `${todayIsoDate}-${todayIsoDate}`],
          ],
        };
        break;
      case 'all':
      default: {
        // "All tasks" should not include tasks completed over 60 days ago
        // see https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/363
        const past60days = toIsoFormat(dayjs(`${todayIsoDate}T00:00:00.000Z`).subtract(60, 'day'));
        condition = {
          or: [
            ['taskStatus', EnumFlowTaskStatus.VALUE_OPEN],
            ['taskCompletedInDateRange', `${past60days}-${todayIsoDate}`],
          ],
        };
      }
    }
    return condition;
  },
  (visibleTasks: VisibleTasks, todayIsoDate: string): string => `${visibleTasks}-${todayIsoDate}`,
);
