import {getStatisticsTaskBurnDown} from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getStatisticConditionsKey, StatisticConditions} from '../../EntityInterfaces/Statistics/Conditions';
import {
  StatisticsTaskBurnDownByDate,
  StoreStatisticsTaskBurnDown,
} from '../../EntityInterfaces/Statistics/TaskBurnDown';
import {
  FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_FAILURE,
  FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_REQUEST,
  FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_SUCCESS,
} from '../ActionTypes';
import {TaskPatchedEvent} from '../Events';
import {statisticsTaskBurnDownStateSelector} from '../Selectors/Statistics';
import {FlowState} from '../State';

interface LoadAction {
  key: string;
  response: StatisticsTaskBurnDownByDate;
}

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_SUCCESS,
  (state: StoreStatisticsTaskBurnDown, action: LoadAction): StoreStatisticsTaskBurnDown => {
    return mergeStates(state, {[action.key]: action.response});
  },
);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_SUCCESS, createTimestampReducer('key', LOADED));
stateReducerMap.set('flow.TaskCreatedEvent', (): EntityStates => ({}));
stateReducerMap.set('flow.TaskPatchedEvent', (state: EntityStates, action: TaskPatchedEvent): EntityStates => {
  if (action.patchedKeys.includes('status')) {
    return {};
  }
  return state;
});
stateReducerMap.set('flow.NodeArchivedEvent', (): EntityStates => ({}));
stateReducerMap.set('flow.NodesRemovedEvent', (): EntityStates => ({}));
stateReducerMap.set('flow.NodeRestoredFromTrashEvent', (): EntityStates => ({}));

export const taskBurnDownReducers = {
  data: ReduceFromMap(reducerMap),
  state: ReduceFromMap(stateReducerMap),
};

function loadTaskBurnDown(conditions: StatisticConditions): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const key = getStatisticConditionsKey(conditions);
    const state = statisticsTaskBurnDownStateSelector(getState())[key];
    if (!state || isOutdated(state)) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: getStatisticsTaskBurnDown,
          options: {urlParams: {...conditions, includeArchived: +conditions.includeArchived}},
          types: {
            failureType: FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_FAILURE,
            requestType: FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_REQUEST,
            successType: FLOW_LOAD_TASK_BURN_DOWN_STATISTIC_SUCCESS,
          },
        },
      });
    }
  };
}

export function useLoadTaskBurnDownStatistics(
  conditions: StatisticConditions | null, //null if in demo mode
): void {
  const dispatch = useDispatch();
  const state = useSelector(statisticsTaskBurnDownStateSelector);
  useEffect(() => {
    void state; //reload dependency
    if (conditions) {
      dispatch(loadTaskBurnDown(conditions));
    }
  }, [conditions, dispatch, state]);
}
