import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {ReactElement} from 'react';
import ArchiveNode from '../Common/ArchiveNode';

interface Props {
  node: NodeType;
}

export default function ArchiveNodeSection({node}: Props): ReactElement {
  // We don't use a section header for the inspector
  return <ArchiveNode node={node} />;
}
