import {
  getInboxEntries,
  listInboxEntries,
  markEntireInboxRead,
  patchInboxEntry,
  removeInboxEntries,
  searchInboxEntries,
} from '@octaved/flow-api';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {createUseRowTableList, createUseSearch, getRowSortKey} from '@octaved/hooks/src/Factories/TableHookFactory';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {EntityStates} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {ObjectContains} from '@octaved/validation';
import {useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {
  InboxEntriesRemovedEvent,
  InboxEntriesRemoveEvent,
  InboxEntryChangedEvent,
  InboxEntryCreatedEvent,
  InboxMarkedReadEvent,
  InboxMarkReadEvent,
} from './Events';
import {
  InboxEntries,
  InboxEntry,
  InboxSearchIdent,
  InboxSearchResults,
  InboxSearchTuple,
  InboxSortBy,
} from './InboxInterfaces';
import {
  inboxSearchQuerySelector,
  inboxSearchSelector,
  inboxSearchStateSelector,
  inboxSelector,
  inboxSortBySelector,
  inboxStateSelector,
} from './InboxSelectors';

export const FLOW_LOAD_INBOX_START = 'FLOW_LOAD_INBOX_START';
export const FLOW_LOAD_INBOX_SUCCESS = 'FLOW_LOAD_INBOX_SUCCESS';
export const FLOW_SEARCH_INBOX_START = 'FLOW_SEARCH_INBOX_START';
export const FLOW_SEARCH_INBOX_SUCCESS = 'FLOW_SEARCH_INBOX_SUCCESS';
export const FLOW_PATCH_INBOX_ENTRY_FAILURE = 'FLOW_PATCH_INBOX_ENTRY_FAILURE';
export const FLOW_PATCH_INBOX_ENTRY_REQUEST = 'FLOW_PATCH_INBOX_ENTRY_REQUEST';
export const FLOW_PATCH_INBOX_ENTRY_SUCCESS = 'FLOW_PATCH_INBOX_ENTRY_SUCCESS';
export const FLOW_MARK_ENTIRE_INBOX_READ_FAILURE = 'FLOW_MARK_ENTIRE_INBOX_READ_FAILURE';
export const FLOW_MARK_ENTIRE_INBOX_READ_REQUEST = 'FLOW_MARK_ENTIRE_INBOX_READ_REQUEST';
export const FLOW_MARK_ENTIRE_INBOX_READ_SUCCESS = 'FLOW_MARK_ENTIRE_INBOX_READ_SUCCESS';
export const FLOW_REMOVE_INBOX_ENTRIES_FAILURE = 'FLOW_REMOVE_INBOX_ENTRIES_FAILURE';
export const FLOW_REMOVE_INBOX_ENTRIES_REQUEST = 'FLOW_REMOVE_INBOX_ENTRIES_REQUEST';
export const FLOW_REMOVE_INBOX_ENTRIES_SUCCESS = 'FLOW_REMOVE_INBOX_ENTRIES_SUCCESS';

type PatchAction = ServerRequestAction<{data: Partial<InboxEntry>; urlParams: {entryId: Uuid}}>;

function reduceEntityOnPatchRequest(
  state: InboxEntries,
  {
    options: {
      data,
      urlParams: {entryId},
    },
  }: PatchAction,
): InboxEntries {
  if (state[entryId]) {
    return mergeStates(state, {[entryId]: data});
  }
  return state;
}

function reduceEntityOnPatchEvent(state: InboxEntries, {id, isRead}: InboxEntryChangedEvent): InboxEntries {
  if (state[id]) {
    return mergeStates(state, {[id]: {isRead}});
  }
  return state;
}

function reduceMarkAllReadInEntities(state: InboxEntries): InboxEntries {
  let changed = false;
  const newState = {...state};
  boolFilter(Object.values(newState)).forEach((entry) => {
    if (!entry.isRead) {
      newState[entry.id] = {...entry, isRead: true};
      changed = true;
    }
  });
  return changed ? newState : state;
}

function reduceMarkAllReadInSearch(state: InboxSearchResults): InboxSearchResults {
  if (state.isNotRead && state.isNotRead.length) {
    return {...state, isNotRead: []};
  }
  return state;
}

function reduceMarkReadInSearch(state: InboxSearchResults, {id, isRead}: InboxEntryChangedEvent): InboxSearchResults {
  if (state.isNotRead) {
    const isNotReadSet = new Set(state.isNotRead);
    if (isRead && isNotReadSet.has(id)) {
      isNotReadSet.delete(id);
      return {...state, isNotRead: [...isNotReadSet]};
    }
    if (!isRead && !isNotReadSet.has(id)) {
      isNotReadSet.add(id);
      return {...state, isNotRead: [...isNotReadSet]};
    }
  }
  return state;
}

function reduceOnEntriesRemovedInSearch(state: InboxSearchResults, {ids}: {ids: Uuid[]}): InboxSearchResults {
  let changed = false;
  const newState = {...state};
  Object.entries(newState).forEach(([key, result]) => {
    const resultSet = new Set(result);
    ids.forEach((id) => {
      if (resultSet.has(id)) {
        resultSet.delete(id);
        changed = true;
      }
    });
    newState[key] = [...resultSet];
  });
  return changed ? newState : state;
}

const entityReducerMap = createReducerCollection<InboxEntries>({});
entityReducerMap.add(FLOW_MARK_ENTIRE_INBOX_READ_REQUEST, reduceMarkAllReadInEntities);
entityReducerMap.add('flow.InboxMarkedReadEvent', reduceMarkAllReadInEntities);
entityReducerMap.add(FLOW_PATCH_INBOX_ENTRY_REQUEST, reduceEntityOnPatchRequest);
entityReducerMap.add('flow.InboxEntryChangedEvent', reduceEntityOnPatchEvent);
entityReducerMap.add<InboxEntryCreatedEvent>('flow.InboxEntryCreatedEvent', (state, {notification}) => {
  const entry = {...notification, isRead: false};
  return {...state, [notification.id]: entry as InboxEntry};
});
export const inboxReducer = entityReducerMap.reducer;

const entityStateReducerMap = createReducerCollection<EntityStates>({});
entityStateReducerMap.add<InboxEntryCreatedEvent>('flow.InboxEntryCreatedEvent', (state, {notification}) => {
  return {...state, [notification.id]: {loaded: Date.now(), loadedAllFields: true}};
});
export const inboxStateReducer = entityStateReducerMap.reducer;

const searchReducerMap = createReducerCollection<UuidSearchResults>({});
searchReducerMap.add<InboxMarkReadEvent>(FLOW_MARK_ENTIRE_INBOX_READ_REQUEST, reduceMarkAllReadInSearch);
searchReducerMap.add<InboxMarkedReadEvent>('flow.InboxMarkedReadEvent', reduceMarkAllReadInSearch);
searchReducerMap.add<InboxEntryChangedEvent>('flow.InboxEntryChangedEvent', reduceMarkReadInSearch);
searchReducerMap.add<InboxEntriesRemoveEvent>(FLOW_REMOVE_INBOX_ENTRIES_REQUEST, reduceOnEntriesRemovedInSearch);
searchReducerMap.add<InboxEntriesRemovedEvent>('flow.InboxEntriesRemovedEvent', reduceOnEntriesRemovedInSearch);
searchReducerMap.add<InboxEntryCreatedEvent>('flow.InboxEntryCreatedEvent', (state, {notification}) => {
  const newState = {...state};
  const notReadKey: InboxSearchIdent = 'isNotRead';
  if (newState[notReadKey]) {
    newState[notReadKey] = [...newState[notReadKey], notification.id]; //new notifications won't be read
  }
  const sortKey = getRowSortKey<InboxSortBy>('createdOn');
  if (newState[sortKey]) {
    newState[sortKey] = [notification.id, ...newState[sortKey]!]; //the list is descending
  }
  return newState;
});
export const inboxSearchReducer = searchReducerMap.reducer;

const searchStateReducers = createReducerCollection<EntityStates>({});
searchStateReducers.add('flow.NodesRemovedEvent', () => ({})); //clear, we could only handle this explicitly if
// the inbox entities were all loaded, but they most likely are not
export const inboxSearchStateReducer = searchStateReducers.reducer;

export const [, useInboxEntries] = createUseEntityHook<FlowState, InboxEntry>(
  FLOW_LOAD_INBOX_START,
  FLOW_LOAD_INBOX_SUCCESS,
  getInboxEntries,
  inboxSelector,
  inboxStateSelector,
  entityReducerMap,
  entityStateReducerMap,
);

export const useInboxTableList = createUseRowTableList<FlowState, InboxSortBy, InboxSearchIdent>(
  inboxSearchSelector,
  inboxSearchStateSelector,
  inboxSearchQuerySelector,
  inboxSortBySelector,
  FLOW_SEARCH_INBOX_START,
  FLOW_SEARCH_INBOX_SUCCESS,
  listInboxEntries,
  searchInboxEntries,
  searchReducerMap,
  searchStateReducers,
);

const useInboxSearch = createUseSearch<InboxSearchIdent>(
  inboxSearchSelector,
  inboxSearchStateSelector,
  FLOW_SEARCH_INBOX_START,
  FLOW_SEARCH_INBOX_SUCCESS,
  searchInboxEntries,
);

/**
 * Patches an inbox entry. Currently only `isRead` can be patched.
 * If at some point more properties need to be patched, then the state reducer needs to be adjusted to invalidate
 * the entity. For now no invalidation is needed because the `isRead` info comes back through the websocket.
 */
export function usePatchInboxEntry(): (id: Uuid, patch: Partial<Pick<InboxEntry, 'isRead'>>) => void {
  const {getState} = useStore<FlowState>();
  const dispatch = useDispatch();
  return useCallback(
    (id, patch) => {
      const entity = inboxSelector(getState())[id];
      if (entity && !ObjectContains(entity, patch)) {
        dispatch({
          [CALL_API]: {
            endpoint: patchInboxEntry,
            method: 'patch',
            options: {
              data: patch,
              urlParams: {entryId: id},
            },
            types: {
              failureType: FLOW_PATCH_INBOX_ENTRY_FAILURE,
              requestType: FLOW_PATCH_INBOX_ENTRY_REQUEST,
              successType: FLOW_PATCH_INBOX_ENTRY_SUCCESS,
            },
          },
        });
      }
    },
    [dispatch, getState],
  );
}

export function useMarkEntireInboxRead(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch({
      [CALL_API]: {
        endpoint: markEntireInboxRead,
        method: 'patch',
        types: {
          failureType: FLOW_MARK_ENTIRE_INBOX_READ_FAILURE,
          requestType: FLOW_MARK_ENTIRE_INBOX_READ_REQUEST,
          successType: FLOW_MARK_ENTIRE_INBOX_READ_SUCCESS,
        },
      },
    });
  }, [dispatch]);
}

export function useRemoveInboxEntries(): (ids: Uuid[]) => void {
  const dispatch = useDispatch();
  return useCallback(
    (ids) => {
      dispatch({
        ids,
        [CALL_API]: {
          endpoint: removeInboxEntries,
          method: 'del',
          options: {
            data: {ids},
          },
          types: {
            failureType: FLOW_REMOVE_INBOX_ENTRIES_FAILURE,
            requestType: FLOW_REMOVE_INBOX_ENTRIES_REQUEST,
            successType: FLOW_REMOVE_INBOX_ENTRIES_SUCCESS,
          },
        },
      });
    },
    [dispatch],
  );
}

const isNotReadQuery: InboxSearchTuple = ['isNotRead', ''];

export function useUnreadInboxCount(): number | undefined {
  const {ids, isLoading} = useInboxSearch(isNotReadQuery, true);
  const loadedOnce = useLoadedValue(isLoading, !isLoading);
  return loadedOnce ? ids.length : undefined;
}
