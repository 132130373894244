import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {FlowState} from '../State';
import {setInspector} from '../Ui';

export interface SystemSettingsUiState {
  guestRoleActiveSorting: boolean;
  guestRoleReSortingOrder: Uuid[];
  guestRoleSearch: string;
  projectFolderCollapsed: boolean;
  projectFolderLocation: 'top' | 'left';
  projectRoleActiveSorting: boolean;
  projectRoleReSortingOrder: Uuid[];
  projectRoleSearch: string;
  selectedProjectFolder: Uuid | null;
  selectedAuditRevisionUser: Uuid | null;
}

export const initialState: SystemSettingsUiState = {
  guestRoleActiveSorting: false,
  guestRoleReSortingOrder: [],
  guestRoleSearch: '',
  projectFolderCollapsed: false,
  projectFolderLocation: 'top',
  projectRoleActiveSorting: false,
  projectRoleReSortingOrder: [],
  projectRoleSearch: '',
  selectedAuditRevisionUser: null,
  selectedProjectFolder: null,
};

export function systemSettingsSelectAuditRevisionUser(
  selectedAuditRevisionUser: Uuid | null,
): ActionDispatcher<void, FlowState> {
  return setInspector({pages: {systemSettings: {selectedAuditRevisionUser}}});
}
