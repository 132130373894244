import {getUserTimesheetInPeriodSums} from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {USER_RIGHTS_CHANGED} from '@octaved/users/src/ActionTypes';
import {
  currentOrgUserIdSelector,
  currentOrgUserNameSelector,
} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StoreUserTimeSheetInPeriodSums, UserTimeSheetInPeriodSum} from '../EntityInterfaces/UserTimeSheetInPeriodSums';
import {
  FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_FAILURE,
  FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_REQUEST,
  FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_SUCCESS,
} from './ActionTypes';
import {TimePeriod} from './Filter/TimePeriod';
import {
  userTimeSheetInPeriodSumsSelector,
  userTimeSheetInPeriodSumsStateSelector,
} from './Selectors/UserTimeSheetInPeriodSumsSelectors';
import {FlowState} from './State';

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_SUCCESS,
  (
    state: StoreUserTimeSheetInPeriodSums,
    {key, response}: {key: string; response: UserTimeSheetInPeriodSum[]},
  ): StoreUserTimeSheetInPeriodSums => ({
    ...state,
    [key]: response,
  }),
);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_SUCCESS, createTimestampReducer('key', LOADED));

export const userTimeSheetInPeriodSumsReducer = ReduceFromMap(reducerMap);
export const userTimeSheetInPeriodSumsStateReducer = ReduceFromMap(stateReducerMap);

const clear = (): EntityStates => ({});
addMultiToReducerMap(
  stateReducerMap,
  [
    USER_RIGHTS_CHANGED,
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
  ],
  clear,
);

function load({from, to}: TimePeriod): ActionDispatcher<void, FlowState> {
  const key = `${from}-${to}`;
  return (dispatch, getState) => {
    const state = userTimeSheetInPeriodSumsStateSelector(getState())[key];
    if (!state || isOutdated(state)) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: getUserTimesheetInPeriodSums,
          options: {
            urlParams: {from, to},
          },
          types: {
            failureType: FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_FAILURE,
            requestType: FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_REQUEST,
            successType: FLOW_LOAD_BOOKED_HOURS_IN_PERIOD_PER_USER_SUCCESS,
          },
        },
      });
    }
  };
}

const empty: UserTimeSheetInPeriodSum[] = [];

export function useUserTimeSheetInPeriodSums(period: TimePeriod): {
  entries: UserTimeSheetInPeriodSum[];
  isLoading: boolean;
} {
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const currentUserDisplayName = useSelector(currentOrgUserNameSelector);
  const key = `${period.from}-${period.to}`;
  const dispatch = useDispatch();
  const entries = useSelector(userTimeSheetInPeriodSumsSelector)[key] || empty;
  const state = useSelector(userTimeSheetInPeriodSumsStateSelector)[key];
  const entriesWithCurrentUser = useMemo(() => {
    if (!entries.find(({id}) => id === currentUserId)) {
      return [
        ...entries,
        {
          billableHours: 0,
          id: currentUserId,
          userName: currentUserDisplayName,
          workHours: 0,
        },
      ];
    }
    return entries;
  }, [currentUserDisplayName, currentUserId, entries]);
  useEffect(() => {
    // noinspection BadExpressionStatementJS
    void state; //reload-dependency
    dispatch(load(period));
  }, [dispatch, period, state]);
  return {
    entries: entriesWithCurrentUser,
    isLoading: !state || !isLoaded(state),
  };
}
