import {error} from '@octaved/env/src/Logger';
import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {markTaskDone} from '@octaved/planning/src/Calculations/MarkTaskDone';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {RulesList, validateDecimal, validateLength, validateNumber} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {pick} from 'lodash';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NodeEntityPatchData} from '../EntityInterfaces/NodeEntity';
import {NodeType, NodesMove, NodesResort} from '../EntityInterfaces/Nodes';
import {Task, TaskCreationData, TaskPatchData} from '../EntityInterfaces/Task';
import {
  FLOW_CHANGE_TASK_FAILURE,
  FLOW_CHANGE_TASK_REQUEST,
  FLOW_CHANGE_TASK_SUCCESS,
  FLOW_COPY_TASKS_FAILURE,
  FLOW_COPY_TASKS_REQUEST,
  FLOW_COPY_TASKS_SUCCESS,
  FLOW_CREATE_TASK_FAILURE,
  FLOW_CREATE_TASK_REQUEST,
  FLOW_CREATE_TASK_SUCCESS,
  FLOW_MOVE_TASKS_BETWEEN_WPS_FAILURE,
  FLOW_MOVE_TASKS_BETWEEN_WPS_REQUEST,
  FLOW_MOVE_TASKS_BETWEEN_WPS_SUCCESS,
  FLOW_MOVE_TASKS_FAILURE,
  FLOW_MOVE_TASKS_REQUEST,
  FLOW_MOVE_TASKS_SUCCESS,
  FLOW_REMOVE_TASK_FAILURE,
  FLOW_REMOVE_TASK_REQUEST,
  FLOW_REMOVE_TASK_SUCCESS,
} from './ActionTypes';
import {
  EventData,
  TaskCreateEvent,
  TaskCreatedEvent,
  TaskPatchedEvent,
  TaskRemoveEvent,
  TasksMoveEvent,
  TasksMovedEvent,
} from './Events';
import {useCombinedNodeSearch} from './Hooks/NodeSearch';
import {
  createNodeEntity,
  getNodeEntityCopiedFields,
  nodeEntityReducers,
  transformNodeEntityToPatchData,
  transformPatchDataToNodeEntity,
} from './Nodes';
import {removeNotAllowedLabels} from './ReduceRemovedLabels';
import {copyResponsibleNode, createResponsibleNode, onResponsibleNodeCreation} from './ResponsibleNode';
import {labelIdsSelector} from './Selectors/LabelSelectors';
import {isSubWorkPackage, isTask, isTaskSection, isWorkPackage} from '../Node/NodeIdentifiers';
import {getIsResponsible} from './Selectors/NodeSearchSelectors';
import {nodeEntitySelector} from './Selectors/NodeSelectors';
import {rootFoldersUserFolderSelector} from './Selectors/RootFolderSelectors';
import {canChangeTaskStatusSelector, getTaskSelector} from './Selectors/TaskSelectors';
import {reduceSortedSiblingNodeIds} from './SortedSiblingIds';
import {FlowState} from './State';
import {parseNullableNumber} from './TransformPatches';
import {validateErrorRules} from './Ui';

nodeEntityReducers.add<TaskCreateEvent | TaskCreatedEvent | TaskPatchedEvent>(
  [FLOW_CREATE_TASK_REQUEST, 'flow.TaskCreatedEvent', 'flow.TaskPatchedEvent'],
  reduceSortedSiblingNodeIds(isTask),
);

nodeEntityReducers.add<TasksMoveEvent | TasksMovedEvent>(
  ['flow.TasksMovedEvent', FLOW_MOVE_TASKS_REQUEST],
  (state, {resorts}) => {
    let changed = false;
    const newState = {...state};
    resorts.forEach(({sortedSiblingNodeIds}) => {
      sortedSiblingNodeIds.forEach((taskId, index) => {
        const task = newState[taskId];
        if (isTask(task) && task.sortOrder !== index) {
          newState[taskId] = {...task, sortOrder: index};
          changed = true;
        }
      });
    });
    return changed ? newState : state;
  },
);

export function createTaskEntity(): Task {
  return {
    ...createNodeEntity(EnumFlowNodeType.VALUE_TASK),
    ...createResponsibleNode(),
    completedBy: null,
    completedOn: null,
    description: '',
    nodeType: EnumFlowNodeType.VALUE_TASK,
    plannedTime: null,
    sortOrder: 0,
    status: EnumFlowTaskStatus.VALUE_OPEN,
  };
}

function getTaskEntityCopiedFields(): Array<keyof Task> {
  return [...getNodeEntityCopiedFields(), 'description', 'plannedTime'];
}

export function copyTaskEntity(source: Task, targetNode: NodeType): Task {
  return {
    ...createTaskEntity(),
    ...pick(source, getTaskEntityCopiedFields()),
    ...copyResponsibleNode(source, targetNode),
  };
}

export function transformTaskToPatchData<T extends NodeEntityPatchData = TaskPatchData>(task: Task): T {
  return {
    ...transformNodeEntityToPatchData<T>(task),
    plannedTime: formatDecimal(task.plannedTime),
    useDependencies: task.planningPredecessors.length > 0,
  };
}

export function getDefaultTaskCreationData(): TaskCreationData {
  return transformTaskToPatchData<TaskCreationData>(createTaskEntity());
}

function getValidationRules(
  id: Uuid,
  data: Partial<Task> | Partial<TaskPatchData> | DeepPartial<Task>,
  validatePlannedTime = true,
): RulesList {
  return [
    data.hasOwnProperty('name') && [validateLength, data.name, 'general:task.nameTooLong', `name_${id}`],
    Boolean(validatePlannedTime && data.plannedTime) && [
      validateNumber,
      data.plannedTime,
      'general:error.invalidNumberFormat',
      `plannedTime_${id}`,
    ],
    Boolean(validatePlannedTime && data.plannedTime) && [
      validateDecimal,
      data.plannedTime,
      'general:error.decimalOutOfRange',
      `plannedTime_${id}`,
    ],
  ];
}

function transformPatchDataToTask(data: TaskCreationData): Task;
function transformPatchDataToTask(data: DeepPartial<TaskPatchData>): DeepPartial<Task>;
function transformPatchDataToTask(data: DeepPartial<TaskPatchData>): DeepPartial<Task> {
  const cleaned = {...data};
  delete cleaned.dependencies;
  delete cleaned.useDependencies;
  const task = transformPatchDataToNodeEntity<Task>(cleaned);
  parseNullableNumber(data, task, 'plannedTime');
  return task;
}

export function createTask(
  data: TaskCreationData,
  parentNodeId: Uuid,
  sortedSiblingIds?: Uuid[],
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    if (!validateErrorRules(getValidationRules(data.id, data), dispatch)) {
      return false;
    }
    const userNodeId = rootFoldersUserFolderSelector(state);
    const parentNode = nodeEntitySelector(state)[parentNodeId];
    if (
      userNodeId !== parentNodeId &&
      !isWorkPackage(parentNode) &&
      !isSubWorkPackage(parentNode) &&
      !isTask(parentNode) &&
      !isTaskSection(parentNode)
    ) {
      throw new Error(`Invalid parentId '${parentNodeId}' given`);
    }

    const task = removeNotAllowedLabels(transformPatchDataToTask(data), labelIdsSelector(state));

    setCompletedOn(task, currentOrgUserIdSelector(state));
    onResponsibleNodeCreation(task, parentNodeId, state);

    if (sortedSiblingIds) {
      const sortOrder = sortedSiblingIds.findIndex((id) => id === data.id);
      task.sortOrder = sortOrder > -1 ? sortOrder : 0;
    }

    const event: EventData<TaskCreateEvent> = {
      parentNodeId,
      sortedSiblingIds,
      task,
    };

    await dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.putTask,
        method: 'put',
        options: {
          data: {
            ...task,
            parentNodeId,
            sortedSiblingIds,
          },
          urlParams: {taskId: data.id},
        },
        types: {
          failureType: FLOW_CREATE_TASK_FAILURE,
          requestType: FLOW_CREATE_TASK_REQUEST,
          successType: FLOW_CREATE_TASK_SUCCESS,
        },
      },
    });
    return true;
  };
}

function setCompletedOn(patch: DeepPartial<Task>, userId: Uuid): void {
  //This is only for the optimistic reducers - the values are written php-side, too:
  if (patch.status) {
    if (patch.status === EnumFlowTaskStatus.VALUE_OPEN) {
      patch.completedBy = null;
      patch.completedOn = null;
    } else {
      patch.completedBy = userId;
      patch.completedOn = unix();
    }
  }
}

export function patchTaskWithTaskPatchData(
  taskId: Uuid,
  partial: Partial<TaskPatchData>,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return patchTask(taskId, transformPatchDataToTask(partial));
}

export function patchTask(
  taskId: Uuid,
  partial: Partial<Task> | DeepPartial<Task>,
  validatePlannedTime = true,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const getTask = getTaskSelector(state);
    const oldEntity = getTask(taskId);
    if (!oldEntity) {
      error(`Missing task entity '${taskId}'`);
      return false;
    }

    if (!validateErrorRules(getValidationRules(taskId, partial, validatePlannedTime), dispatch)) {
      return false;
    }

    if (partial.status === EnumFlowTaskStatus.VALUE_COMPLETE && oldEntity.status !== partial.status) {
      if (!markTaskDone(taskId, state)) {
        return false;
      }
    }

    const patch = removeNotAllowedLabels(partial, labelIdsSelector(state));
    if (oldEntity && !objectContains(oldEntity, patch, false, true)) {
      patch.lastChangedBy = currentOrgUserIdSelector(state);
      patch.lastChangedOn = unix();
      setCompletedOn(patch, currentOrgUserIdSelector(state));

      await dispatch({
        [CALL_API]: {
          endpoint: routes.patchTask,
          method: 'patch',
          options: {
            data: patch,
            urlParams: {taskId},
          },
          types: {
            failureType: FLOW_CHANGE_TASK_FAILURE,
            requestType: FLOW_CHANGE_TASK_REQUEST,
            successType: FLOW_CHANGE_TASK_SUCCESS,
          },
        },
        patchedNodeId: taskId, //for the optimistic patch
        previous: oldEntity, //for the optimistic search reducer (TaskPatchEvent)
      });
    }
    return true;
  };
}

export function useTaskPatch(nodeId: Uuid): (data: Partial<Task>) => void {
  const dispatch = useDispatch();
  return useCallback((data: Partial<Task>) => dispatch(patchTask(nodeId, data, false)), [dispatch, nodeId]);
}

export function moveTasks(moves: NodesMove[], resorts: NodesResort[]): ActionDispatcher<void, FlowState> {
  const data: EventData<TasksMoveEvent> = {moves, resorts};
  return (dispatch) => {
    dispatch({
      ...data,
      [CALL_API]: {
        endpoint: routes.moveTasks,
        method: 'patch',
        options: {data},
        types: {
          failureType: FLOW_MOVE_TASKS_FAILURE,
          requestType: FLOW_MOVE_TASKS_REQUEST,
          successType: FLOW_MOVE_TASKS_SUCCESS,
        },
      },
    });
  };
}

export function deleteTask(taskId: Uuid): ActionDispatcher<boolean, FlowState> {
  const event: EventData<TaskRemoveEvent> = {nodeIds: [taskId]};
  return (dispatch, _getState) => {
    dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.deleteTask,
        method: 'del',
        options: {
          urlParams: {taskId},
        },
        types: {
          failureType: FLOW_REMOVE_TASK_FAILURE,
          requestType: FLOW_REMOVE_TASK_REQUEST,
          successType: FLOW_REMOVE_TASK_SUCCESS,
        },
      },
    });
    return true;
  };
}

export function useCopyTasksBetweenWorkPackages(): (fromId: Uuid, toId: Uuid) => void {
  const dispatch = useDispatch();
  return useCallback(
    (fromId, toId) => {
      dispatch({
        [CALL_API]: {
          endpoint: routes.copyTasksBetweenWorkPackages,
          method: 'post',
          options: {
            urlParams: {
              fromId,
              toId,
            },
          },
          types: {
            failureType: FLOW_COPY_TASKS_FAILURE,
            requestType: FLOW_COPY_TASKS_REQUEST,
            successType: FLOW_COPY_TASKS_SUCCESS,
          },
        },
      });
    },
    [dispatch],
  );
}

export function useMoveTasksBetweenWorkPackages(): (fromId: Uuid, toId: Uuid) => void {
  const dispatch = useDispatch();
  return useCallback(
    (fromId, toId) => {
      dispatch({
        [CALL_API]: {
          endpoint: routes.moveTasksBetweenWorkPackages,
          method: 'patch',
          options: {
            urlParams: {
              fromId,
              toId,
            },
          },
          types: {
            failureType: FLOW_MOVE_TASKS_BETWEEN_WPS_FAILURE,
            requestType: FLOW_MOVE_TASKS_BETWEEN_WPS_REQUEST,
            successType: FLOW_MOVE_TASKS_BETWEEN_WPS_SUCCESS,
          },
        },
      });
    },
    [dispatch],
  );
}

export function useCanChangeTaskStatus(taskId: Uuid | null | undefined): boolean {
  const orgUserId = useSelector(currentOrgUserIdSelector);
  useCombinedNodeSearch(taskId ? getIsResponsible(orgUserId) : null);
  return useSelector((s: FlowState) => (taskId ? canChangeTaskStatusSelector(s)(taskId) : false));
}
