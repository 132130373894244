import {CheckCircle, HelpCircle, Trash} from 'lucide-react';
import {MouseEvent, ReactElement} from 'react';
import {Tooltip} from '../../../Feedback';
import {IconButton} from '../../Button';
import PickerViewFooter from './Components/PickerViewFooter';
import PickerViewLegend from './Components/PickerViewLegend';

export interface StandardDateOrRangePickerFooterProps {
  canClear?: boolean;
  clear: () => void;
  close: (event: MouseEvent<HTMLButtonElement>) => void;
  footerAddition?: ReactElement;
  footerAdditionRight?: ReactElement;
  showLegend?: boolean;
}

export default function StandardDateOrRangePickerFooter({
  canClear = true,
  clear,
  close,
  footerAddition,
  footerAdditionRight,
  showLegend,
}: StandardDateOrRangePickerFooterProps): ReactElement {
  return (
    <PickerViewFooter>
      <div className={'flex items-center justify-between'}>
        <div className={'flex items-center gap-x-2'}>
          {footerAddition && <>{footerAddition}</>}
          {canClear && (
            <IconButton
              variant={'ghost'}
              size={'sm'}
              icon={<Trash />}
              iconColor={'red'}
              onClick={clear}
              className={'hover:!bg-red-50'}
            />
          )}
          {showLegend && (
            <Tooltip toolTipTranslation={''} content={<PickerViewLegend />} position={'top right'} offset={[6, 2]}>
              <IconButton variant={'ghost'} size={'sm'} icon={<HelpCircle />} cursor={'help'} />
            </Tooltip>
          )}
          {footerAdditionRight && <div>{footerAdditionRight}</div>}
        </div>
        <IconButton icon={<CheckCircle />} onClick={close} label={'OK'} size={'sm'} />
      </div>
    </PickerViewFooter>
  );
}
