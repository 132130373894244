import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isSubWorkPackage, isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {workdaysBetween} from '../Calculations/WorkdayCalculations';
import {PlanningDate} from '../EntityInterfaces/PlanningDates';
import {getMinMaxPlanningDatesSelector, getPlanningDatesForNodeSelector} from '../Selectors/PlanningDateSelectors';
import {PlanningPatchData, patchPlanning} from './Planning';

export function setNodeToUnplanned(
  nodeId: Uuid,
  includeLogicalDependencies: boolean = false,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, _getState) => {
    const data: PlanningPatchData = {
      nodeId,
      dependencies: [],
      planningDates: [],
    };
    if (includeLogicalDependencies) {
      data.logicalDependencies = [];
    }
    return dispatch(patchPlanning([data]));
  };
}

type DependencyTypes =
  | EnumFlowPlanningDependencyType.VALUE_ENDS_WITH_PREDECESSOR
  | EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR;

export function setNodeToRelative(
  nodeId: Uuid,
  planningDate: PlanningDate | null = null,
  type: DependencyTypes | undefined = EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const node = getNodeSelector(state)(nodeId);
    let data: PlanningPatchData = {nodeId, dependencies: []};

    if (isTask(node) || isSubWorkPackage(node)) {
      const nodeAncestry = getNodeAncestrySelector(state)(nodeId);
      const parent = nodeAncestry.subWorkPackage || nodeAncestry.workPackage;
      const getOrgWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(state);
      const getMinMaxPlanningDates = getMinMaxPlanningDatesSelector(state);

      const {plannedStart: wpPlannedStart, plannedEnd: wpPlannedEnd} = getMinMaxPlanningDates(parent?.id);
      let {plannedStart: taskPlannedStart, plannedEnd: taskPlannedEnd} = getMinMaxPlanningDates(nodeId);
      taskPlannedStart = planningDate?.plannedStart || taskPlannedStart;
      taskPlannedEnd = planningDate?.plannedEnd || taskPlannedEnd;
      if (!taskPlannedStart) {
        return;
      }
      const planningDates = planningDate ? [planningDate] : getPlanningDatesForNodeSelector(state)(nodeId);
      const dependencies = [];
      if (
        parent &&
        type === EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR &&
        wpPlannedStart &&
        taskPlannedStart
      ) {
        const offset = workdaysBetween(
          fromIsoDateTimeFormat(wpPlannedStart),
          fromIsoDateTimeFormat(taskPlannedStart),
          getOrgWorkMinutesAtDate,
        );
        dependencies.push({
          offset,
          type,
          predecessor: parent.id,
        });
      } else if (
        parent &&
        type === EnumFlowPlanningDependencyType.VALUE_ENDS_WITH_PREDECESSOR &&
        wpPlannedEnd &&
        taskPlannedEnd
      ) {
        const offset = workdaysBetween(
          fromIsoDateTimeFormat(wpPlannedEnd),
          fromIsoDateTimeFormat(taskPlannedEnd),
          getOrgWorkMinutesAtDate,
        );
        dependencies.push({
          offset,
          type,
          predecessor: parent.id,
        });
      }
      data = {
        dependencies,
        nodeId,
        planningDates,
      };
    }
    return dispatch(patchPlanning([data]));
  };
}
export function setNodeToAbsolut(nodeId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, _getState) => {
    return dispatch(patchPlanning([{nodeId, dependencies: []}]));
  };
}
