import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import {mergeStates} from '../MergeStates';

/**
 *
 * @param {Object} action
 * @param {string} name name of the entity
 * @return {?Object}
 */
function selectEntities(action, name) {
  return get(action, `response.entities.${name}`, null);
}

/**
 * @param {Object} previous
 * @param {Object} incoming
 * @param {?Set} fieldsToCopy
 * @return {{}}
 */
function mergeEntities(previous, incoming, fieldsToCopy) {
  const newState = {...previous};
  let changed = false;
  for (const [key, value] of Object.entries(incoming)) {
    if (isPlainObject(value)) {
      const merged = mergeStates(newState[key], value, {fieldsToCopy, skipLastChangedIfNotDirty: true});
      if (merged !== newState[key]) {
        newState[key] = merged;
        changed = true;
      }
    } else {
      newState[key] = value;
      changed = true;
    }
  }
  return changed ? newState : previous;
}

/**
 *
 * @param {function} reducer
 * @param {string} entityName
 * @return {function} a reducer function
 */
export function entitiesReducer(reducer, entityName) {
  return (state = {}, action = {}, globalState = {}) => {
    let newState = state;
    const incommingEntities = selectEntities(action, entityName);

    if (incommingEntities) {
      newState = mergeEntities(newState, incommingEntities, reducer.fieldsToCopy);
    }

    return reducer(newState, action, globalState);
  };
}

/**
 * @param {Map} reducerMap
 * @return {function}
 */
function reduceEntityReducerMap(reducerMap) {
  return (state = {}, action = {}) => {
    const newState = {...state};
    let changed = false;
    reducerMap.forEach((reducer, key) => {
      const newEntities = entitiesReducer(reducer, key)(newState[key], action, newState);
      if (newState[key] !== newEntities) {
        newState[key] = newEntities;
        changed = true;
      }
    });
    return changed ? newState : state;
  };
}

/**
 *
 * @param {Object} reducers key/value list of reducer functions
 * @return {function[]} a reducer function
 */
export function combineEntitiesReducers(reducers) {
  const reducerMap = new Map(Object.entries(reducers));
  return [
    reduceEntityReducerMap(reducerMap),
    (newReducers) => Object.entries(newReducers).forEach(([k, r]) => reducerMap.set(k, r)),
    (removedReducers) => removedReducers.forEach((k) => reducerMap.delete(k)),
  ];
}

/**
 * dummy default function for simple merged entities
 * @param {Object} state the entity state
 * @return {Object}
 */
export function simpleReducer(state = {}) {
  return state;
}
