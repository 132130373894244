import {EntityState} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {areGroupsLoadedSelector, groupsSelector} from '@octaved/users/src/Selectors/GroupSelectors';
import {areOrgUserNamesLoadedSelector, userEntitiesSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {boolFilter} from '@octaved/utilities';
import {createSelector} from 'reselect';
import {GlobalRoleAssignments, GlobalRoleAssignmentUnitLists} from '../../EntityInterfaces/GlobalRoleAssignments';
import {FlowState} from '../State';

const globalRoleAssignmentsSelector = (state: FlowState): GlobalRoleAssignments => state.roles.globalRoleAssignments;
export const globalRoleAssignmentStatesStateSelector = (state: FlowState): EntityState =>
  state.roles.globalRoleAssignmentStates;

export const globalRoleAssignmentAllUnitIdsSelector = createSelector(
  globalRoleAssignmentsSelector,
  (
    assignments,
  ): {
    groupIds: Uuid[];
    userIds: Uuid[];
  } => {
    const allGroupIds: Uuid[] = [];
    const allUserIds: Uuid[] = [];
    boolFilter(Object.values(assignments)).forEach(({groupIds, userIds}) => {
      allGroupIds.push(...(groupIds || []));
      allUserIds.push(...(userIds || []));
    });
    return {groupIds: [...new Set(allGroupIds)], userIds: [...new Set(allUserIds)]};
  },
);

export const globalRoleAssignmentUnitListsSelector = createSelector(
  globalRoleAssignmentsSelector,
  groupsSelector,
  userEntitiesSelector,
  globalRoleAssignmentAllUnitIdsSelector,
  areGroupsLoadedSelector,
  areOrgUserNamesLoadedSelector,
  (
    assignments,
    groups,
    users,
    {groupIds, userIds},
    areGroupsLoaded,
    areUserDisplayNamesLoaded,
  ): {
    isLoading: boolean;
    unitLists: GlobalRoleAssignmentUnitLists;
  } => {
    const result: GlobalRoleAssignmentUnitLists = {};
    Object.entries(assignments).forEach(([roleId, groupUserIds]) => {
      if (groupUserIds) {
        result[roleId] = [];
        (groupUserIds.groupIds || []).forEach((id) => {
          result[roleId]!.push({
            unitId: id,
            unitName: groups[id]?.name || '',
            unitType: SimpleUnitType.group,
          });
        });
        (groupUserIds.userIds || []).forEach((id) => {
          const user = users[id];
          if (user) {
            result[roleId]!.push({unitId: id, unitType: SimpleUnitType.user, unitName: user.name});
          }
        });
        result[roleId]!.sort((a, b) => a.unitName.localeCompare(b.unitName));
      }
    });
    return {
      isLoading: !areUserDisplayNamesLoaded(userIds) || !areGroupsLoaded(groupIds),
      unitLists: result,
    };
  },
);
