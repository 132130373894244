import {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {AuditReference} from '../../../../../EntityInterfaces/Audit/AuditReference';
import {hasConfiguration} from '../../TableConfigurations/Confgurations';

export interface ReferenceCellProps {
  cell: {value: AuditReference | null};
  disableLink?: boolean;
}

export default function ReferenceCell({cell: {value}, disableLink}: ReferenceCellProps): ReactElement | null {
  if (!value) {
    return null;
  }

  if (disableLink || !value.id || !hasConfiguration(value.__typeName)) {
    return <span>{value.displayName}</span>;
  }
  return <Link to={{search: `selectedTable=${value.__typeName}&idFilter=${value.id}`}}>{value.displayName}</Link>;
}
