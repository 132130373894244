import {deleteBilling, getBillings, patchBilling, postBilling} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {generateUrl} from '@octaved/network/src/Network';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, INVALIDATED} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {ObjectContains} from '@octaved/validation';
import {useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {Billing, BillingCreationData, BillingPatchData} from '../../EntityInterfaces/Billing/Billings';
import {WorkPackage} from '../../EntityInterfaces/Pid';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_CREATE_BILLING_FAILURE,
  FLOW_CREATE_BILLING_REQUEST,
  FLOW_CREATE_BILLING_SUCCESS,
  FLOW_DELETE_BILLING_FAILURE,
  FLOW_DELETE_BILLING_REQUEST,
  FLOW_DELETE_BILLING_SUCCESS,
  FLOW_LOAD_BILLING_START,
  FLOW_LOAD_BILLING_SUCCESS,
  FLOW_PATCH_BILLING_FAILURE,
  FLOW_PATCH_BILLING_REQUEST,
  FLOW_PATCH_BILLING_SUCCESS,
} from '../ActionTypes';
import {
  BillingRemovedEvent,
  ChangePidSuccessEvent,
  EventData,
  ProjectCustomerChangedEvent,
  TimeRecordEvent,
  WorkPackagePatchedEvent,
} from '../Events';
import {billingSelector, billingStateSelector} from '../Selectors/Billing/BillingSelectors';
import {FlowState} from '../State';

const reduceRemove = <T>(state: Record<Uuid, T>, action: BillingRemovedEvent): Record<Uuid, T> => {
  if (state[action.billingId]) {
    const newState = {...state};
    delete newState[action.billingId];
    return newState;
  }
  return state;
};

const billingReducerMap = new Map();
export const billingReducer = ReduceFromMap(billingReducerMap);

addMultiToReducerMap(billingReducerMap, ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_REQUEST], reduceRemove);

const billingStateReducerMap = new Map();
export const billingStateReducer = ReduceFromMap(billingStateReducerMap);

billingStateReducerMap.set('flow.BillingPatchedEvent', createTimestampReducer('billingId', INVALIDATED));
addMultiToReducerMap(billingStateReducerMap, ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_REQUEST], reduceRemove);

addMultiToReducerMap(
  billingStateReducerMap,
  ['flow.CustomerPatchedEvent', 'flow.PriceCategoryPatchedEvent'],
  () => ({}),
);

const billingRootReducerMap = new Map();
export const billingRootReducer = ReduceFromMap(billingRootReducerMap);

const affectingWpProps = ['billingType', 'fixedPrice', 'maxEffort', 'priceCategory'] as const satisfies Array<
  keyof WorkPackage
>;

function reduceInvalidateIdsOnRootState(state: FlowState, nodeId: Uuid | ReadonlyArray<Uuid>): FlowState {
  const nodeIdsSet = new Set(Array.isArray(nodeId) ? nodeId : [nodeId]);
  const affectedBillingIds: Uuid[] = [];
  boolFilter(Object.values(billingSelector(state))).forEach(({id, workPackageIds}) => {
    if (workPackageIds && workPackageIds.length && workPackageIds.some((id) => nodeIdsSet.has(id))) {
      affectedBillingIds.push(id);
    }
  });
  return invalidateBillingIdsOnRootState(state, affectedBillingIds);
}

addMultiToReducerMap(
  billingRootReducerMap,
  ['flow.WorkPackagePatchedEvent', FLOW_CHANGE_PID_SUCCESS],
  (state: FlowState, action: WorkPackagePatchedEvent | ChangePidSuccessEvent): FlowState => {
    if (affectingWpProps.some((prop) => (action.patchedKeys as string[]).includes(prop as string))) {
      const nodeId = (action as WorkPackagePatchedEvent).nodeId || (action as ChangePidSuccessEvent).patchedNodeId;
      return reduceInvalidateIdsOnRootState(state, nodeId);
    }
    return state;
  },
);

addMultiToReducerMap(
  billingRootReducerMap,
  'flow.ProjectCustomerChangedEvent',
  (state: FlowState, {affectedNodeIds}: ProjectCustomerChangedEvent): FlowState =>
    reduceInvalidateIdsOnRootState(state, affectedNodeIds),
);

addMultiToReducerMap(
  billingRootReducerMap,
  ['flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  (state: FlowState, {timeRecordId}: TimeRecordEvent): FlowState => {
    const affectedBillingIds: Uuid[] = [];
    boolFilter(Object.values(billingSelector(state))).forEach(({id, timeRecordIds}) => {
      if (timeRecordIds && timeRecordIds.includes(timeRecordId)) {
        affectedBillingIds.push(id);
      }
    });
    return invalidateBillingIdsOnRootState(state, affectedBillingIds);
  },
);

function invalidateBillingIdsOnRootState(state: FlowState, ids: Uuid[]): FlowState {
  if (ids.length) {
    return mergeStates(state, {
      entityStates: {
        billing: createTimestampReducer('ids', INVALIDATED)(billingStateSelector(state), {ids}),
      },
    });
  }
  return state;
}

export const [, useBillings] = createUseEntityHook<FlowState, Billing>(
  FLOW_LOAD_BILLING_START,
  FLOW_LOAD_BILLING_SUCCESS,
  generateUrl(getBillings, {withSums: '1', withTimeRecordIds: '1', withWorkPackageIds: '1'}),
  billingSelector,
  billingStateSelector,
  billingReducerMap,
  billingStateReducerMap,
);

// export const useBillingSearch = createUseSearch<BillingSearchIdent>(
//   billingSearchSelector,
//   billingSearchStateSelector,
//   FLOW_SEARCH_BILLING_START,
//   FLOW_SEARCH_BILLING_SUCCESS,
//   searchBillingsByIdent,
// );

function createBilling(data: BillingCreationData): ActionDispatcher<Promise<Uuid>, FlowState> {
  return async (dispatch) => {
    const response = await dispatch<{id: Uuid}>({
      [CALL_API]: {
        endpoint: postBilling,
        method: 'post',
        options: {data},
        throwNetworkError: true,
        types: {
          failureType: FLOW_CREATE_BILLING_FAILURE,
          requestType: FLOW_CREATE_BILLING_REQUEST,
          successType: FLOW_CREATE_BILLING_SUCCESS,
        },
      },
    });
    return response.id;
  };
}

export function useCreateBilling(): (data: BillingCreationData) => Promise<Uuid> {
  const dispatch = useDispatch();
  return useCallback((data) => dispatch(createBilling(data)), [dispatch]);
}

export function usePatchBilling(): (id: Uuid, patch: Partial<BillingPatchData>) => void {
  const {getState} = useStore<FlowState>();
  const dispatch = useDispatch();
  return useCallback(
    (billingId, patch) => {
      const entity = billingSelector(getState())[billingId];
      if (entity && !ObjectContains(entity, patch)) {
        dispatch({
          [CALL_API]: {
            endpoint: patchBilling,
            method: 'patch',
            options: {
              data: patch,
              urlParams: {billingId},
            },
            types: {
              failureType: FLOW_PATCH_BILLING_FAILURE,
              requestType: FLOW_PATCH_BILLING_REQUEST,
              successType: FLOW_PATCH_BILLING_SUCCESS,
            },
          },
        });
      }
    },
    [dispatch, getState],
  );
}

function doDeleteBilling(billingId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const billing = billingSelector(getState())[billingId];
    if (billing) {
      const removedEvent: EventData<BillingRemovedEvent> = {
        billingId: billing.id,
        timeRecordIds: billing.timeRecordIds || [],
        workPackageIds: billing.workPackageIds || [],
      };
      await dispatch<Promise<void>>({
        ...removedEvent,
        [CALL_API]: {
          endpoint: deleteBilling,
          method: 'del',
          options: {urlParams: {billingId}},
          types: {
            failureType: FLOW_DELETE_BILLING_FAILURE,
            requestType: FLOW_DELETE_BILLING_REQUEST,
            successType: FLOW_DELETE_BILLING_SUCCESS,
          },
        },
      });
    }
  };
}

export function useDeleteBilling(): (id: Uuid) => Promise<void> {
  const dispatch = useDispatch();
  return useCallback((billingId) => dispatch(doDeleteBilling(billingId)), [dispatch]);
}
