import {Uuid} from '@octaved/typescript/src/lib';
import {isUuid} from '@octaved/validation';
import {withValidation} from '@octaved/validation/src/ValidationField';
import {ReactElement, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, StrictDropdownProps} from 'semantic-ui-react';
import {WorkPackagePatchData} from '../../../EntityInterfaces/Pid';
import {useWorkPackage} from '../../../Modules/Hooks/Nodes';
import {hasPriceCategory} from '../../../Modules/Pid';
import {isCustomerBillableSelector} from '../../../Modules/Selectors/CustomerSelectors';
import {
  getPriceCategoryDropdownOptionsSelector,
  showPriceCategorySelectionSelector,
} from '../../../Modules/Selectors/PriceCategorySelectors';
import {errorsSelector} from '../../../Modules/Selectors/UiSelectors';
import {removeErrorForField} from '../../../Modules/Ui';
import {hourlyBillableTypesSet} from '../../../WorkPackage/BillingType';
import PerTimeTrackingConfirmDialog, {ConfirmPerTimeTracking} from './PerTimeTrackingConfirmDialog';

const ValidationDropdown = withValidation(Dropdown);

interface Props extends Omit<StrictDropdownProps, 'value' | 'loading' | 'onChange' | 'options'> {
  editAble?: boolean;
  isNewWorkPackage?: boolean;
  label?: ReactNode;
  patch: (pid: Partial<WorkPackagePatchData>) => void;
  snapshot: WorkPackagePatchData;
}

const perTimeTrackingValue = 'usePriceCategoryPerTimeTracking';

export default function WorkPackagePriceCategory({
  editAble = false,
  isNewWorkPackage,
  label,
  patch,
  snapshot,
  ...props
}: Props): ReactElement | null {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const errors = useSelector(errorsSelector);
  const original = useWorkPackage(snapshot.id);
  const getPriceCategoryDropdownOptions = useSelector(getPriceCategoryDropdownOptionsSelector);
  const showPriceCategorySelection = useSelector(showPriceCategorySelectionSelector);
  const isCustomerBillable = useSelector(isCustomerBillableSelector)(snapshot.flowCustomer);
  const billingTypeNeedsPriceCategory = hasPriceCategory(snapshot);
  const priceCategoryIsRequired = isCustomerBillable && billingTypeNeedsPriceCategory;

  const hadUsePriceCategoryPerTimeTracking =
    original?.usePriceCategoryPerTimeTracking || snapshot.usePriceCategoryPerTimeTracking;

  const options = useMemo(() => {
    const opts = getPriceCategoryDropdownOptions(snapshot.flowCustomer, original?.priceCategory || null).slice(0);
    const availableCatOpts = getPriceCategoryDropdownOptions(snapshot.flowCustomer, null); //determine pure available
    if (
      snapshot.billingType &&
      hourlyBillableTypesSet.has(snapshot.billingType) &&
      (hadUsePriceCategoryPerTimeTracking || (isCustomerBillable && availableCatOpts.length > 1))
    ) {
      opts.push({
        text: (
          <i>
            <span>{t('workPackage:priceCategorySelection.priceCategoryPerTimeTracking')}</span>
          </i>
        ),
        value: perTimeTrackingValue,
      });
    }
    return opts;
  }, [
    getPriceCategoryDropdownOptions,
    hadUsePriceCategoryPerTimeTracking,
    isCustomerBillable,
    t,
    original?.priceCategory,
    snapshot.billingType,
    snapshot.flowCustomer,
  ]);

  const fieldName = `priceCategory_${snapshot.id}`;

  useEffect(() => {
    if (
      priceCategoryIsRequired &&
      (isNewWorkPackage || (editAble && !showPriceCategorySelection)) &&
      !snapshot.priceCategory &&
      options.length === 1
    ) {
      patch({priceCategory: options[0].value as Uuid});
    }
  }, [
    editAble,
    isNewWorkPackage,
    options,
    patch,
    priceCategoryIsRequired,
    showPriceCategorySelection,
    snapshot.priceCategory,
  ]);

  const [confirmPerTimeTracking, setConfirmPerTimeTracking] = useState<null | ConfirmPerTimeTracking>(null);
  const closeConfirm = useCallback(() => setConfirmPerTimeTracking(null), []);

  if (!priceCategoryIsRequired || !showPriceCategorySelection) {
    return null;
  }

  return (
    <>
      {label}
      <ValidationDropdown
        selection
        loading={!!confirmPerTimeTracking}
        errors={errors}
        fieldName={fieldName}
        value={snapshot.usePriceCategoryPerTimeTracking ? perTimeTrackingValue : snapshot.priceCategory || ''}
        field={'priceCategory'}
        options={options}
        disabled={!editAble || !!confirmPerTimeTracking}
        selectOnBlur={false}
        fluid
        placeholder={t('general:dropdownSelectPlaceholder') || undefined}
        onChange={(_e, {value: newVal}) => {
          dispatch(removeErrorForField(fieldName));
          if (newVal === perTimeTrackingValue) {
            const toPatch = {priceCategory: null, usePriceCategoryPerTimeTracking: true};
            if (isNewWorkPackage || original?.usePriceCategoryPerTimeTracking) {
              patch(toPatch);
            } else {
              setConfirmPerTimeTracking(toPatch);
            }
          } else if (isUuid(newVal)) {
            const toPatch = {priceCategory: newVal, usePriceCategoryPerTimeTracking: false};
            if (isNewWorkPackage || !original?.usePriceCategoryPerTimeTracking) {
              patch(toPatch);
            } else {
              setConfirmPerTimeTracking(toPatch);
            }
          }
        }}
        {...props}
      />
      {confirmPerTimeTracking && (
        <PerTimeTrackingConfirmDialog
          close={closeConfirm}
          patch={patch}
          toPatch={confirmPerTimeTracking}
          workPackageId={snapshot.id}
        />
      )}
    </>
  );
}
