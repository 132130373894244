import {useLoadedNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {sortNodesByPathSelector} from '@octaved/flow/src/Modules/Selectors/NodeSortSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import Node from './Node';

interface Props {
  childIds: ReadonlyArray<Uuid>;
}

export default function Children({childIds}: Props): ReactElement {
  const {nodes} = useLoadedNodes(childIds);
  const sorted = useSelector((s: FlowState) => sortNodesByPathSelector(s)(nodes));
  return (
    <div className={'pl-[27px]'}>
      {sorted.map((child) => (
        <Node key={child.id} node={child} />
      ))}
    </div>
  );
}
