import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createFlatTimestampReducer,
  createTimestampReducer,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher, Reducer} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {Schema} from 'normalizr';
import {isAction, UnknownAction} from 'redux';
import {listGroups, listOrgUsers} from '../../config/routes';
import {OrgUserDeletedEvent} from '../EntityInterfaces/Events';
import {SlimUnit, UnitListsLists, UnitListsState, UnitListsStates} from '../EntityInterfaces/UnitLists';
import {orgUser as orgUserSchema, userGroup} from '../Schema';
import {unitListsStatesSelector} from '../Selectors/UnitListSelectors';
import {SimpleUnitType} from '../UnitType';
import {
  CREATE_GROUP_SUCCESS,
  CREATE_USERS_SUCCESS,
  URM_GROUP_ADDED,
  URM_GROUP_CHANGED,
  URM_GROUP_DELETED,
  USERS_CREATED,
} from './ActionTypes';
import {userGroupStateReducers} from './Group';
import {orgUserStateReducers, publicUserFields} from './OrgUser';

const LOAD_UNIT_LIST_REQUESTS = 'LOAD_UNIT_LIST_REQUESTS';
const LOAD_UNIT_LIST_FAILURE = 'LOAD_UNIT_LIST_FAILURE';
const LOAD_UNIT_LIST_SUCCESS = 'LOAD_UNIT_LIST_SUCCESS';
const INVALIDATE_UNIT_LIST = 'INVALIDATE_UNIT_LIST';

function isUnitTypeAction(action: unknown): action is UnknownAction & {unitType: SimpleUnitType} {
  return isAction(action) && 'unitType' in action;
}

function reduceState(reducer: Reducer, unitType?: SimpleUnitType): Reducer {
  return (state, action) => {
    const type = isUnitTypeAction(action) ? action.unitType : unitType;
    if (!type) {
      throw new Error('UnitType is required');
    }
    return mergeStates(state, {[type]: reducer(state[type], action)});
  };
}

interface UnitListLoadedEvent {
  requestTime: number;
  type: typeof LOAD_UNIT_LIST_SUCCESS;
  response: {result: Uuid[]};
  unitType: SimpleUnitType;
}

const reducers = createReducerCollection<UnitListsLists>({
  group: [],
  user: [],
});

reducers.add<OrgUserDeletedEvent>('OrgUserDeletedEvent', (state, action) => {
  const newState = {...state};
  newState.user = newState.user.filter((id) => id !== action.orgUserId);
  return newState;
});

reducers.add(
  LOAD_UNIT_LIST_SUCCESS,
  (state: UnitListsLists, {unitType, response}: UnitListLoadedEvent): UnitListsLists => {
    if (unitType === SimpleUnitType.group) {
      return {
        ...state,
        group: response.result,
      };
    }
    if (unitType === SimpleUnitType.user) {
      return {
        ...state,
        user: response.result,
      };
    }
    return state;
  },
);

export const unitListsReducer = reducers.reducer;

const stateReducerMap = createReducerCollection<UnitListsStates>({
  group: {},
  user: {},
});
stateReducerMap.add(LOAD_UNIT_LIST_REQUESTS, reduceState(createFlatTimestampReducer(LOADING)));
stateReducerMap.add(LOAD_UNIT_LIST_SUCCESS, reduceState(createFlatTimestampReducer(LOADED)));
stateReducerMap.add(INVALIDATE_UNIT_LIST, reduceState(createFlatTimestampReducer(INVALIDATED)));
stateReducerMap.add(USERS_CREATED, reduceState(createFlatTimestampReducer(INVALIDATED), SimpleUnitType.user));
stateReducerMap.add(CREATE_USERS_SUCCESS, reduceState(createFlatTimestampReducer(INVALIDATED), SimpleUnitType.user));
stateReducerMap.add(CREATE_GROUP_SUCCESS, reduceState(createFlatTimestampReducer(INVALIDATED), SimpleUnitType.group));
stateReducerMap.add(URM_GROUP_ADDED, reduceState(createFlatTimestampReducer(INVALIDATED), SimpleUnitType.group));
stateReducerMap.add(URM_GROUP_DELETED, reduceState(createFlatTimestampReducer(INVALIDATED), SimpleUnitType.group));
stateReducerMap.add(URM_GROUP_CHANGED, reduceState(createFlatTimestampReducer(INVALIDATED), SimpleUnitType.group));
export const unitListsStatesReducer = stateReducerMap.reducer;

userGroupStateReducers.add(LOAD_UNIT_LIST_SUCCESS, (state, action: UnitListLoadedEvent) => {
  if (action.unitType === SimpleUnitType.group) {
    return createTimestampReducer('ids', LOADED)(state, {
      ids: action.response.result,
      requestTime: action.requestTime,
    });
  }
  return state;
});

orgUserStateReducers.add(LOAD_UNIT_LIST_SUCCESS, (state, action: UnitListLoadedEvent) => {
  if (action.unitType === SimpleUnitType.user) {
    return createTimestampReducer('ids', LOADED)(state, {
      fieldsToLoad: publicUserFields,
      ids: action.response.result,
      requestTime: action.requestTime,
    });
  }
  return state;
});

const routes: Record<SimpleUnitType, string> = {
  [SimpleUnitType.group]: listGroups,
  [SimpleUnitType.user]: listOrgUsers,
};

const schemas: Record<SimpleUnitType, Schema> = {
  [SimpleUnitType.group]: userGroup,
  [SimpleUnitType.user]: orgUserSchema,
};

export function loadUnitList(unitType: SimpleUnitType): ActionDispatcher<Promise<void>, UnitListsState> {
  return async (dispatch, getState) => {
    const state = unitListsStatesSelector(getState());
    if (!state || isOutdated(state[unitType])) {
      await dispatch({
        unitType,
        [CALL_API]: {
          endpoint: routes[unitType],
          schema: [schemas[unitType]],
          types: {
            failureType: LOAD_UNIT_LIST_FAILURE,
            requestType: LOAD_UNIT_LIST_REQUESTS,
            successType: LOAD_UNIT_LIST_SUCCESS,
          },
        },
      });
    }
  };
}

export function splitUnitIds<U extends SlimUnit>(
  units: U[],
): {
  groupIds: Uuid[];
  userIds: Uuid[];
} {
  const usersSet = new Set<Uuid>();
  const groupsSet = new Set<Uuid>();
  units.forEach((unit) => {
    if (unit.unitType === SimpleUnitType.group) {
      groupsSet.add(unit.unitId);
    } else {
      usersSet.add(unit.unitId);
    }
  });
  return {groupIds: [...groupsSet], userIds: [...usersSet]};
}

function mappify<U extends {unitId: Uuid}>(units: ReadonlyArray<U>): Map<Uuid, U> {
  return units.reduce<Map<Uuid, U>>((acc, u) => acc.set(u.unitId, u), new Map());
}

export function addUnit<U extends {unitId: Uuid}>(units: U[], unit: U): U[] {
  const map = mappify(units);
  if (!map.has(unit.unitId)) {
    map.set(unit.unitId, unit);
    return [...map.values()];
  }
  return units;
}

export function hasUnit<U extends {unitId: Uuid}>(units: U[], unit: U): boolean {
  const map = mappify(units);
  return map.has(unit.unitId);
}

export function removeUnit<U extends {unitId: Uuid}, A extends U[] | ReadonlyArray<U>>(
  units: A,
  unit: Pick<U, 'unitId'> & Partial<Omit<U, 'unitId'>>,
): A {
  const map = mappify(units);
  if (map.has(unit.unitId)) {
    map.delete(unit.unitId);
    return [...map.values()] as A;
  }
  return units;
}
