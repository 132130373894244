import {warning} from '@octaved/env/src/Logger';
import {cva, VariantProps} from 'class-variance-authority';
import * as React from 'react';
import {cn} from './Hooks';
import {TWLabeledInput} from './TWLabeledInput';

const twInputFieldVariants = cva(
  'border-input bg-white ring-offset-2 focus-visible:ring-sky-700 text-md flex h-10 w-full rounded border px-3 py-2 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-slate-400 placeholder:italic',
  {
    defaultVariants: {
      hasError: false,
      hasWarning: false,
      size: 'default',
    },
    variants: {
      hasError: {
        false: '',
        true: 'border-red-300 bg-red-50',
      },
      hasWarning: {
        false: '',
        true: 'border-orange-400 bg-orange-50',
      },
      size: {
        default: 'h-8',
        sm: 'h-6',
      },
    },
  },
);

export type TWInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: React.ReactNode;
  hasError?: boolean;
} & VariantProps<typeof twInputFieldVariants>;

export const TWInput = React.forwardRef<HTMLInputElement, TWInputProps>((props, ref) => {
  if (props.label) {
    if (props.className?.includes('hidden')) {
      warning(`Using 'hidden' with a label doesn't work as expected! The label will not be hidden!`);
    }
    return <TWLabeledInput {...props} />;
  }
  const {type, hasError, size, className, hasWarning, ...rest} = props;
  return (
    <input
      type={type}
      ref={ref}
      className={cn(twInputFieldVariants({className, hasError, hasWarning, size}))}
      autoComplete={'off'}
      {...rest}
    />
  );
});
TWInput.displayName = 'TWInput';
