import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {transformEffort} from '@octaved/flow/src/Modules/Hooks/EffortTransformer';
import {TWAutosaveInput} from '@octaved/ui';
import {format, formatDecimal, parse, validateLocaleFormat} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement, useCallback} from 'react';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';

const percentageRegex = /^(\d+)%$/;

export function getPercentageEffort(val: string, baseValue: number): string {
  if (percentageRegex.test(val)) {
    return format(Math.round(baseValue * (parseInt(val.replace('%', ''), 10) / 100)));
  }
  return transformEffort(val);
}

export interface SubWorkPackageEffortProps {
  node: SubWorkPackage;
  readonly?: boolean;
  patch: (data: Partial<SubWorkPackage>) => void;
  workPackage: WorkPackage;
}

export default function SubWorkPackageEffort({
  frame: Frame,
  node,
  readonly,
  patch,
  workPackage,
}: SubWorkPackageEffortProps & FramedSectionOrTileProps): ReactElement {
  const effortPatchField =
    workPackage.billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO ? 'effortTo' : 'maxEffort';
  const wpEffort = workPackage[effortPatchField];
  const valueTransformer = useCallback(
    (val: string) => (wpEffort ? getPercentageEffort(val, wpEffort) : val),
    [wpEffort],
  );
  return (
    <Frame labelToken={'nodeDetails:field.effort.subWorkPackage.label'}>
      <div className={'flex items-center gap-x-2'}>
        {readonly && <>{format(node.maxEffort)}</>}
        {!readonly && (
          <TWAutosaveInput
            wrapperClassname={'w-shortNumberInput'}
            value={node.maxEffort ? formatDecimal(node.maxEffort) : ''}
            onSave={(maxEffort) => {
              if (!maxEffort || validateLocaleFormat(maxEffort)) {
                patch({maxEffort: maxEffort !== '' ? parse(maxEffort) : null});
              }
            }}
            disabled={readonly}
            valueTransformer={valueTransformer}
          />
        )}
      </div>
    </Frame>
  );
}
