import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {INVALIDATED, LOADED, LOADING, createTimestampReducer} from '@octaved/store/src/EntityState';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import {addWorkdays, workdaysBetween} from '../Calculations/WorkdayCalculations';
import {PlanningDate, PlanningDatesList} from '../EntityInterfaces/PlanningDates';
import {PlanningDependencies, PlanningDependency} from '../EntityInterfaces/PlanningDependency';
import {getMinMaxPlanningDatesSelector} from '../Selectors/PlanningDateSelectors';
import {getPlanningPredecessorsSelector} from '../Selectors/PlanningDependencySelectors';
import {
  FLOW_GET_PLANNING_FOR_NODES_REQUEST,
  FLOW_GET_PLANNING_FOR_NODES_SUCCESS,
  FLOW_PATCH_PLANNING_REQUEST,
} from './ActionTypes';
import {PatchPlanningRequest, patchPlanning} from './Planning';

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_GET_PLANNING_FOR_NODES_REQUEST, createTimestampReducer('options.data.ids', LOADING));
stateReducerMap.set(FLOW_GET_PLANNING_FOR_NODES_SUCCESS, createTimestampReducer('options.data.ids', LOADED));
stateReducerMap.set('flow.PlanningEvent', createTimestampReducer('changedDependencies', INVALIDATED));
stateReducerMap.set('flow.NodesRemovedEvent', createTimestampReducer('nodeIds', INVALIDATED));
stateReducerMap.set('flow.NodeRestoredFromTrashEvent', createTimestampReducer('allAffectedNodeIds', INVALIDATED));
export const planningDependencyEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion
//#region Reducer

const reducerMap = new Map();
reducerMap.set(
  FLOW_PATCH_PLANNING_REQUEST,
  (
    state: PlanningDependencies,
    {planningDependencies, removedPlanningDependencies}: PatchPlanningRequest,
  ): PlanningDependencies => {
    if (planningDependencies || removedPlanningDependencies.length) {
      let newState: PlanningDependencies;
      if (planningDependencies) {
        newState = {...state, ...planningDependencies};
      } else {
        newState = {...state};
      }
      for (const id of removedPlanningDependencies) {
        delete newState[id];
      }
      return newState;
    }
    return state;
  },
);
reducerMap.set(
  'flow.NodesRemovedEvent',
  (state: PlanningDependencies, {nodeIds}: {nodeIds: Uuid[]}): PlanningDependencies => {
    const newState = {...state};
    let hasChanges = false;
    for (const [id, planningDependency] of Object.entries(state)) {
      if (
        planningDependency &&
        (nodeIds.includes(planningDependency.predecessor) || nodeIds.includes(planningDependency.successor))
      ) {
        delete newState[id];
        hasChanges = true;
      }
    }

    return hasChanges ? newState : state;
  },
);
export const planningDependencyReducer = ReduceFromMap(reducerMap);

//#endregion

function getOffsetAndPlanningDates(
  nodeId: Uuid,
  predecessorId: Uuid,
  state: FlowState,
): {offset: number; planningDates: PlanningDatesList | undefined} {
  const {plannedStart: nodePlannedStart} = getMinMaxPlanningDatesSelector(state)(nodeId);
  const {plannedEnd: predecessorPlannedEnd} = getMinMaxPlanningDatesSelector(state)(predecessorId);

  let offset = 0;
  let planningDates: PlanningDatesList | undefined;
  if (predecessorPlannedEnd && nodePlannedStart) {
    offset =
      workdaysBetween(
        fromIsoDateTimeFormat(predecessorPlannedEnd),
        fromIsoDateTimeFormat(nodePlannedStart),
        getOrgWorkMinutesAtDateSelector(state),
      ) - 1;
  } else if (!nodePlannedStart && predecessorPlannedEnd) {
    //Create a 1 day date as all dependent nodes needs a planning
    const startDate = toIsoDateTimeFormat(
      addWorkdays(fromIsoDateTimeFormat(predecessorPlannedEnd), 1, getOrgWorkMinutesAtDateSelector(state)),
    );
    const planningDate: PlanningDate = {
      nodeId,
      assignedNodeId: null,
      id: generateUuid(),
      name: '',
      plannedEnd: startDate,
      plannedStart: startDate,
    };
    planningDates = [planningDate];
  }
  return {offset, planningDates};
}

export function setDependency(nodeId: Uuid, predecessorId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const {offset, planningDates} = getOffsetAndPlanningDates(nodeId, predecessorId, getState());
    return dispatch(
      patchPlanning([
        {
          nodeId,
          planningDates,
          dependencies: [
            {
              offset,
              predecessor: predecessorId,
              type: EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR,
            },
          ],
        },
      ]),
    );
  };
}
export function addDependency(nodeId: Uuid, predecessorId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getPlanningPredecessors = getPlanningPredecessorsSelector(state);
    const dependencies = getPlanningPredecessors(nodeId, true);

    const {offset, planningDates} = getOffsetAndPlanningDates(nodeId, predecessorId, getState());
    return dispatch(
      patchPlanning([
        {
          nodeId,
          planningDates,
          dependencies: [
            ...dependencies,
            {
              offset,
              predecessor: predecessorId,
              type: EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR,
            },
          ],
        },
      ]),
    );
  };
}

export function updateDependency(
  nodeId: Uuid,
  predecessorId: Uuid,
  data: Omit<Partial<PlanningDependency>, 'successor' | 'predecessor'>,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getPlanningPredecessors = getPlanningPredecessorsSelector(state);
    const dependencies = getPlanningPredecessors(nodeId, false);

    return dispatch(
      patchPlanning([
        {
          nodeId,
          dependencies: dependencies.map((oldDependency) => {
            if (oldDependency.predecessor !== predecessorId) {
              return oldDependency;
            }
            return {
              ...oldDependency,
              ...data,
            };
          }),
        },
      ]),
    );
  };
}

export function removeDependency(nodeId: Uuid, predecessorId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getPlanningPredecessors = getPlanningPredecessorsSelector(state);
    const dependencies = getPlanningPredecessors(nodeId, false);

    return dispatch(
      patchPlanning([
        {
          nodeId,
          dependencies: dependencies.filter(({predecessor}) => predecessor !== predecessorId),
        },
      ]),
    );
  };
}
