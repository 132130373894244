import {Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {IsTemplateIcon} from '@octaved/flow/src/Node/NodeStatus/NodeStatusIcons';
import {ProjectIcon, TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';

export interface ProjectTypeProps {
  node: Project;
  readonly?: boolean;
  setIsTemplate: (isTemplate: boolean) => void;
}

export default function ProjectType({
  frame: Frame,
  node,
  readonly,
  setIsTemplate,
}: ProjectTypeProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Frame labelToken={'nodeDetails:field.projectType.label'}>
      <div className={'flex flex-col gap-y-2'}>
        <TWSelect
          value={node.isTemplate ? 'template' : 'normal'}
          disabled={readonly}
          onValueChange={(value) => setIsTemplate(value === 'template')}
        >
          <TWSelectTrigger className={'w-1/2'}>
            <TWSelectValue placeholder={t('general:dropdownSelectPlaceholder')} />
          </TWSelectTrigger>
          <TWSelectContent>
            <TWSelectItem value={'normal'}>
              <div className={'flex items-center gap-x-2'}>
                <div className={'flex size-6 items-center justify-center'}>
                  <ProjectIcon className={'size-5'} />
                </div>
                {t('nodeDetails:field.projectType.normal')}
              </div>
            </TWSelectItem>
            <TWSelectItem value={'template'}>
              <div className={'flex items-center gap-x-2'}>
                <IsTemplateIcon />
                {t('nodeDetails:field.projectType.template')}
              </div>
            </TWSelectItem>
          </TWSelectContent>
        </TWSelect>
      </div>
    </Frame>
  );
}
