import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {Customer} from '../../EntityInterfaces/Customers';
import {getFlowCustomerSelector} from '../../Modules/Selectors/CustomerSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IFlowCustomerInspectorContext extends BaseInspectorContext {
  selectedEntity: Customer;
  selectedType: 'flowCustomer';
}

function isFlowCustomerInspectorContext(context: InspectorContext | null): context is IFlowCustomerInspectorContext {
  return context?.selectedType === 'flowCustomer';
}

export function useFlowCustomerInspectorContext(): IFlowCustomerInspectorContext {
  const context = useInspectorContext();
  if (!isFlowCustomerInspectorContext(context)) {
    throw new Error('is not flow customer inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IFlowCustomerInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const flowCustomer = useSelector(getFlowCustomerSelector)(baseParams?.selectedId);

  return useMemo<IFlowCustomerInspectorContext | null>(() => {
    if (baseParams && flowCustomer) {
      return {
        ...baseParams,
        selectedEntity: flowCustomer,
        selectedType: 'flowCustomer',
      };
    }
    return null;
  }, [baseParams, flowCustomer]);
}

export default function FlowCustomerInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);
  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
