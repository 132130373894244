import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useIsPlanningPage} from '@octaved/planning/src/Hooks/UseIsPlanningPage';
import {simulationModeActiveSelector} from '@octaved/planning/src/Selectors/UiSelectors';
import {Button} from '@octaved/ui';
import {ReactElement, useContext} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import ViewNodeTimeTracking from '../../Pages/Projects/Drawer/ViewNodeTimeTracking/ViewNodeTimeTracking';
import {subInspectorRoute} from '../../Routing/Routes/Inspectors/InspectorRoutes';
import {InspectorContextSelectedTypes, inspectorContext} from '../InspectorContext/InspectorContext';

type FooterNodeTypes =
  | EnumFlowNodeType.VALUE_TASK
  | EnumFlowNodeType.VALUE_GROUP
  | EnumFlowNodeType.VALUE_PROJECT
  | EnumFlowNodeType.VALUE_WORK_PACKAGE;

const pidTranslationTokens: Record<FooterNodeTypes, string> = {
  [EnumFlowNodeType.VALUE_TASK]: 'pages:projects.inspector.inspectTask',
  [EnumFlowNodeType.VALUE_GROUP]: 'pages:projects.inspector.inspectGroup',
  [EnumFlowNodeType.VALUE_PROJECT]: 'pages:projects.inspector.inspectProject',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'pages:projects.inspector.inspectWorkPackage',
};

function isFooterNodeType(type: InspectorContextSelectedTypes | undefined): type is FooterNodeTypes {
  return (
    type === EnumFlowNodeType.VALUE_TASK ||
    type === EnumFlowNodeType.VALUE_GROUP ||
    type === EnumFlowNodeType.VALUE_PROJECT ||
    type === EnumFlowNodeType.VALUE_WORK_PACKAGE
  );
}

export interface NodeFooterProps {
  useSelectedIdForLink?: boolean;
  showTimeTracking?: boolean;
}

export default function NodeFooter({
  useSelectedIdForLink = false,
  showTimeTracking = false,
}: NodeFooterProps): ReactElement | null {
  const location = useLocation();
  const context = useContext(inspectorContext);
  const selectedType = context?.selectedType;

  const isInSimulationMode = useSelector(simulationModeActiveSelector);
  const subInspectorDisabled = useIsPlanningPage() && isInSimulationMode;

  if (isFooterNodeType(selectedType)) {
    return (
      <div className={'flex w-full flex-col items-center gap-y-2'}>
        <div className={'flex items-center gap-x-4'}>
          {context?.selectedId && showTimeTracking && (
            <ViewNodeTimeTracking
              nodeId={context?.selectedId}
              Trigger={
                <Button variant={'outline'} size={'sm'}>
                  <Trans i18nKey={'pages:projects.inspector.openTimeSheet'} />
                </Button>
              }
            />
          )}
          {!subInspectorDisabled && (
            <Link to={`${location.pathname}/${useSelectedIdForLink ? context?.selectedId : subInspectorRoute}`}>
              <Button variant={'outline'} size={'sm'}>
                <Trans i18nKey={pidTranslationTokens[selectedType]} />
              </Button>
            </Link>
          )}
          {subInspectorDisabled && (
            <Button variant={'outline'} size={'sm'} isDisabled>
              <Trans i18nKey={pidTranslationTokens[selectedType]} />
            </Button>
          )}
        </div>
      </div>
    );
  }
  return null;
}
