import {EnumFlowPidBillingType, TimeTrackingReferenceNodeType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {hasPidHours, hasPrice} from '@octaved/flow/src/Modules/Pid';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {useSubWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import {generateLargeViewUrl} from '@octaved/flow/src/Routing/Routes/Node';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Message, TWButton} from '@octaved/ui';
import {t} from 'i18next';
import {noop} from 'lodash';
import {Info} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import NodeBillingTypeSection from '../../../Components/Fields/TimeAndMoney/Section/NodeBillingTypeSection';
import NodeFreeOfChargeReasonSection from '../../../Components/Fields/TimeAndMoney/Section/NodeFreeOfChargeReasonSection';
import NodePriceCategorySection from '../../../Components/Fields/TimeAndMoney/Section/NodePriceCategorySection';
import NodePriceSection from '../../../Components/Fields/TimeAndMoney/Section/NodePriceSection';
import SubWorkPackageEffortSection from '../../../Components/Fields/TimeAndMoney/Section/SubWorkPackage/Section/SubWorkPackageEffortSection';
import SubWorkPackagePriceCategorySection from '../../../Components/Fields/TimeAndMoney/Section/SubWorkPackage/Section/SubWorkPackagePriceCategorySection';
import WorkPackageEffortSection from '../../../Components/Fields/TimeAndMoney/Section/WorkPackage/Section/WorkPackageEffortSection';
import {timeSheetRoute} from '../../../LargeView/RoutePaths';
import InspectorViewFrame from '../../InspectorViewFrame';
import {SubWorkPackageDetailsProps} from './General';

export default function TimeAndMoney({node}: SubWorkPackageDetailsProps): ReactElement | null {
  const workPackage: WorkPackage | null = useNodeAncestry(node.id, true).workPackage;
  const patch = useSubWorkPackagePatch(node.id);
  const canManage = useIsGranted('FLOW_NODE_MANAGE_PRICES', node.id) && !node.isArchived;
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  if (!workPackage) {
    return null;
  }

  return (
    <InspectorViewFrame help={'nodeDetails:view.timeAndMoney.help'}>
      <NodeBillingTypeSection node={workPackage} readonly patch={noop} />
      {workPackage.usePriceCategoryPerTimeTracking ? (
        <SubWorkPackagePriceCategorySection node={node} readonly={!canManage} patch={patch} workPackage={workPackage} />
      ) : (
        <NodePriceCategorySection node={workPackage} readonly patch={noop} />
      )}

      {hasPrice(workPackage) && <NodePriceSection node={workPackage} patch={noop} readonly />}
      {workPackage.billingType === EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE && workPackage.freeOfChargeReason && (
        <NodeFreeOfChargeReasonSection reason={workPackage.freeOfChargeReason} setReason={noop} readonly />
      )}
      {hasPidHours(workPackage) && (
        <>
          <WorkPackageEffortSection
            node={workPackage}
            patch={noop}
            readonly
            labelToken={'nodeDetails:field.effort.workPackage.label'}
          />
          <SubWorkPackageEffortSection node={node} patch={patch} readonly={!canManage} workPackage={workPackage} />

          {useTimeTracking &&
            workPackage.timeTrackingReferenceNodeType === TimeTrackingReferenceNodeType.workPackages && (
              <Message colorScheme={'sky'} size={'sm'} textSize={'sm'} icon={Info} className={'items-start'}>
                {t('nodeDetails:field.effort.subWorkPackage.timeTrackingInfo')}
              </Message>
            )}
        </>
      )}
      <div className={'flex'}>
        <Link to={generateLargeViewUrl(node.nodeType, node.id, timeSheetRoute)}>
          <TWButton variant={'outline'} size={'md'} colorScheme={'primary'}>
            {t('nodeDetails:field.timeTracking.timeSheet')}
          </TWButton>
        </Link>
      </div>
    </InspectorViewFrame>
  );
}
