import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {debug} from '@octaved/env/src/Logger';
import {createLogicalDependencyWorkflowControlSnackbar} from '@octaved/flow/src/Components/Snackbar/LogicalDependencyWorkflowControlSnackbar';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {logicalDependencyWorkflowControlSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {
  getTaskDepthSelector,
  getTaskIsAutoChainedSelector,
  getTaskSelector,
} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {StrictWorkflowControl} from '@octaved/flow/src/Modules/Settings';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {getAutoChainLogicalPredecessorSelector} from '../Selectors/LogicalDependencySelector';

export function markTaskDone(taskId: Uuid, state: FlowState): boolean {
  const workflowControl = logicalDependencyWorkflowControlSelector(state);
  if (workflowControl === StrictWorkflowControl.relaxed) {
    return true;
  }

  const getTask = getTaskSelector(state);
  const taskIsAutoChained = getTaskIsAutoChainedSelector(state)(taskId);
  const depth = getTaskDepthSelector(state)(taskId);
  const getAutoChainLogicalPredecessor = getAutoChainLogicalPredecessorSelector(state);

  const oldEntity = getTask(taskId);

  if (!oldEntity) {
    throw new Error('task not found');
  }

  if (taskIsAutoChained && depth === 1) {
    // auto chain is only for tasks on lvl 1
    const previousTask = getAutoChainLogicalPredecessor(taskId);
    if (isTask(previousTask) && previousTask.status === EnumFlowTaskStatus.VALUE_OPEN) {
      return handleUnfinishedPredecessor(workflowControl, taskId, [previousTask]);
    }
  } else {
    const unfinishedTasks = boolFilter(oldEntity.planningLogicalPredecessors.map((id) => getTask(id))).filter(
      (task) => task.status === EnumFlowTaskStatus.VALUE_OPEN,
    );
    if (unfinishedTasks.length > 0) {
      return handleUnfinishedPredecessor(workflowControl, taskId, unfinishedTasks);
    }
  }

  return true;
}

function handleUnfinishedPredecessor(
  workflowControl: StrictWorkflowControl,
  taskId: Uuid,
  unfinishedTasks: Task[],
): boolean {
  if (workflowControl === StrictWorkflowControl.medium) {
    createLogicalDependencyWorkflowControlSnackbar(taskId, unfinishedTasks);
  } else if (workflowControl === StrictWorkflowControl.strict) {
    debug(
      `Task '${taskId}' could not closed because of logical dependency to '${unfinishedTasks
        .map(({name}) => name)
        .join(', ')}'`,
    );
    return false;
  }
  return true;
}
