import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {DropdownItemProps} from 'semantic-ui-react';

export function useDropdownOptions(
  map: Map<string | number | boolean, string>,
  sort = false,
  textTransform: (text: string) => string = (s) => s,
): DropdownItemProps[] {
  const {t} = useTranslation();
  return useMemo(() => {
    const result: DropdownItemProps[] = [];
    map.forEach((name, key) => result.push({text: textTransform(t(name)), value: key}));
    if (sort) {
      result.sort((a, b) => (a.text as string).localeCompare(b.text as string));
    }
    return result;
  }, [map, sort, textTransform, t]);
}
