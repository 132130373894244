export const statusOk = {
  /**
   * Performs a status check
   *
   * @param {Response} response
   * @returns {Promise}
   */
  then: (response: Response): Promise<Response> => {
    if (200 <= response.status && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
};
