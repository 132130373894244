import {Uuid} from '@octaved/typescript/src/lib';
import VerticalForm from '@octaved/ui-components/src/React/Formik/VerticalForm';
import {useFormik} from 'formik';
import {PlusCircleIcon} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import {
  MonthlyRecurringDates,
  WeeklyRecurringDates,
  addMonthlyRecurringDates,
  addWeeklyRecurringDates,
} from '../../../Modules/PlanningDatesRecurring';
import MonthlyConfig from './MonthlyConfig';
import WeeklyConfig from './WeeklyConfig';

interface RecurringPlanningContentProps {
  closeDialog(): void;
  nodeId: Uuid;
}

export type RecurringPlanningData = WeeklyRecurringDates & MonthlyRecurringDates;

export default function RecurringPlanningContent({nodeId, closeDialog}: RecurringPlanningContentProps): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const recurringPlanningSchema = useMemo(() => {
    const field = t('pages:projects.inspector.manage.planning.recurringPlanning.durationFieldName');
    return Yup.object().shape({
      duration: Yup.number().when('type', {
        is: 'monthly',
        then: (schema) =>
          schema
            .typeError({i18nKey: 'general:validationError.integer', values: {field}})
            .max(365, {i18nKey: 'general:validationError.max', values: {field, max: 365}})
            .min(1, {i18nKey: 'general:validationError.min', values: {field, min: 1}})
            .integer({i18nKey: 'general:validationError.integer', values: {field}})
            .required({i18nKey: 'general:validationError.required', values: {field}}),
      }),
      type: Yup.string(),
    });
  }, [t]);

  const formik = useFormik<RecurringPlanningData>({
    initialValues: {
      duration: 1,
      first: null,
      friday: false,
      last: null,
      monday: false,
      saturday: false,
      sunday: false,
      thursday: false,
      tuesday: false,
      type: 'weekly',
      useFirstOfMonth: true,
      wednesday: false,
      weekday: 'monday',
    },

    onSubmit: async (values) => {
      if (values.type === 'weekly') {
        await dispatch(addWeeklyRecurringDates(nodeId, values));
      } else {
        await dispatch(addMonthlyRecurringDates(nodeId, values));
      }
      closeDialog();
    },
    validationSchema: recurringPlanningSchema,
  });

  return (
    <VerticalForm submitLabel={'general:save'} submitIcon={<PlusCircleIcon />} formik={formik}>
      <WeeklyConfig formik={formik} disabled={formik.values.type !== 'weekly'} nodeId={nodeId} />
      <MonthlyConfig formik={formik} disabled={formik.values.type !== 'monthly'} nodeId={nodeId} />
    </VerticalForm>
  );
}
