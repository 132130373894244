import {Uuid} from '@octaved/typescript/src/lib';
import classNames from 'classnames';
import {memo, ReactElement, useCallback, useState} from 'react';
import {useShowTaskGroup} from '../../../../Modules/Ui/TaskList';
import {useTaskListContext} from '../../TaskListContext';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';
import {useDragTask} from '../DragAndDrop/DragItems';
import NewTaskRow, {NewTaskRowOnEnterKeyDown} from './NewTaskRow';
import {ShallowTask} from './ShallowTask';
import TaskList from './TaskList';
import BaseTaskRow from './TaskRow';

interface Props {
  depth: number;
  hasSiblingsWithSubTasks: boolean;
  hasSubTasks: boolean;
  index: number;
  parentId: string;
  previousSiblingId: Uuid | undefined;
  task: ShallowTask;
}

export default memo(function TaskRowBox({
  depth,
  hasSiblingsWithSubTasks,
  hasSubTasks,
  index,
  parentId,
  previousSiblingId,
  task,
}: Props): ReactElement | null {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {allowCreationOfTasks, showNewTaskRowOnceAfterTaskIds, TaskRow = BaseTaskRow} = useTaskListContext();
  const showNext = showNewTaskRowOnceAfterTaskIds.has(task.id);
  showNewTaskRowOnceAfterTaskIds.delete(task.id);

  const [showNewSiblingTask, setShowNewSiblingTask] = useState<null | 'prev' | 'next'>(showNext ? 'next' : null);
  const {show: showSubTasks, toggle: toggleSubTasks} = useShowTaskGroup(task.id);

  const {dragPreviewRef, dragRef, dropRef, isDragging} = useDragTask(task, hasSubTasks, depth, index, parentId);

  const closeNewTaskRow = useCallback(() => setShowNewSiblingTask(null), []);

  const onEnterKeyDownNext = useCallback<NewTaskRowOnEnterKeyDown>(
    ({task}) => {
      if (task.name) {
        showNewTaskRowOnceAfterTaskIds.add(task.id);
        setShowNewSiblingTask(null);
      }
    },
    [showNewTaskRowOnceAfterTaskIds],
  );

  return (
    <div className={classNames('taskRowBox', {isDragging})} ref={dragPreviewRef}>
      {allowCreationOfTasks && !readonlyOrNotManageable && showNewSiblingTask === 'prev' && (
        <NewTaskRow
          hasAnySubTasks={hasSiblingsWithSubTasks}
          onFinish={closeNewTaskRow}
          parentId={parentId}
          previousSiblingId={previousSiblingId}
        />
      )}

      <TaskRow
        depth={depth}
        dragRef={dragRef}
        dropRef={dropRef}
        hasSiblingsWithSubTasks={hasSiblingsWithSubTasks}
        hasSubTasks={hasSubTasks}
        previousSiblingId={previousSiblingId}
        setShowNewSiblingTask={setShowNewSiblingTask}
        showSubTasks={showSubTasks}
        task={task}
        toggleSubTasks={toggleSubTasks}
      />

      {showSubTasks && hasSubTasks && <TaskList depth={depth + 1} parentId={task.id} />}

      {allowCreationOfTasks && !readonlyOrNotManageable && showNewSiblingTask === 'next' && (
        <NewTaskRow
          hasAnySubTasks={hasSiblingsWithSubTasks}
          onFinish={closeNewTaskRow}
          onEnterKeyDown={onEnterKeyDownNext}
          parentId={parentId}
          previousSiblingId={task.id}
        />
      )}

      {/*language=scss*/}
      <style jsx>{`
        .taskRowBox.isDragging {
          opacity: 0.2;
        }
      `}</style>
    </div>
  );
});
