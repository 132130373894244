import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import Konva from 'konva';
import {calculateDependency} from '../../../../Components/Calendar/CalendarContainer/Content/Bar/DndDependency/CalculateDependency';
import {addDependency} from '../../../../Modules/PlanningDependencies';
import {isPlanningDependencyNode} from '../../../../Selectors/PlanningSelector';
import {Bar} from '../../../Bars/Bar';
import {BarPlugin} from '../../../Bars/Plugins/BarPlugin';
import {ResizeBarPlugin} from '../../../Bars/Plugins/ResizeBarPlugin';
import {HoverRowChangedEvent} from '../../../Calendar/Context/CalendarContext';
import {setMouseHoverEffect} from '../../../MouseHoverCursor';
import {MouseMove, MouseMoveEvent} from '../../../MouseMove';

function getTargetId(node: Konva.Shape | null): Uuid | null {
  const rootBar = node?.findAncestor('.rootBar');
  if (rootBar instanceof Konva.Group) {
    const name = rootBar.name();
    const match = name.match(/nodeId_(?<id>[A-F0-9]{32})/);
    if (match?.groups?.id) {
      return match.groups.id;
    }
  }
  return null;
}

export class DependencyCreatePlugin extends BarPlugin {
  static readonly radius = 4;
  protected readonly distance = 5;

  #circle = new Konva.Circle({
    fill: '#fff',
    radius: DependencyCreatePlugin.radius,
    stroke: '#7a7a7a',
    strokeWidth: 1,
    visible: false,
    x: -ResizeBarPlugin.imageSize - this.distance,
  });
  #line = new Konva.Line({strokeWidth: 1, stroke: '#4A5568'});

  #lineX = 0;
  #lineY = 0;

  init(bar: Bar): void {
    super.init(bar);
    this.root.add(this.#circle);
    const y = this.bar.height / 2;
    this.#circle.y(y);
    setMouseHoverEffect(this.#circle, 'pointer');

    const mouseMove = new MouseMove({
      eventNode: this.#circle,
      onEnd: this.#onFinish,
      onMove: this.#onMove,
      onStart: this.#onStart,
    });
    mouseMove.init();

    this.disposables.push(
      this.ctx.eventEmitter.on('hoverRowChanged', this.#onHover),
      () => mouseMove.dispose(),
      () => this.#line.destroy(),
    );
    this.#onHover(this.ctx.hoveredRow);
  }

  #onStart = (e: MouseMoveEvent): void => {
    this.#lineX = this.bar.x + this.#circle.x();
    this.#lineY = this.bar.absolutY + this.#circle.y();

    this.bar.menuRoot.add(this.#line);
    this.#drawLine(e);
  };

  #onMove = (e: MouseMoveEvent): void => {
    this.#drawLine(e);
  };

  #onFinish = (e: MouseMoveEvent): void => {
    const stage = this.bar.menuRoot.getStage();
    if (stage) {
      const targetNode = stage.getIntersection({x: e.mouseX, y: e.mouseY});
      const targetId = getTargetId(targetNode);

      if (targetId) {
        const state = this.ctx.store.getState();
        const getNode = getNodeSelector(state);
        const sourceNode = getNode(this.nodeId);
        const targetNode = getNode(targetId);
        if (this.nodeId !== targetId && isPlanningDependencyNode(sourceNode) && isPlanningDependencyNode(targetNode)) {
          const draggedUp = e.startY > e.mouseY;
          const dependency = calculateDependency(sourceNode, targetNode, draggedUp, state);
          if (dependency) {
            this.ctx.store.dispatch(addDependency(dependency.nodeId, dependency.predecessorId));
          }
        }
      }
    }
    this.#line.remove();
  };

  #drawLine(e: MouseMoveEvent): void {
    const endX = e.movementX + this.#lineX;
    const endY = e.movementY + this.#lineY;
    const points: number[] = [this.bar.x, this.#lineY];
    points.push(endX, this.#lineY);
    points.push(endX, endY);
    this.#line.points(points);
  }

  #onHover = (value: HoverRowChangedEvent | null): void => {
    this.#circle.visible(value?.nodeId === this.nodeId);
  };
}
