import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {
  emptyNodeSearchResult,
  toParents,
  withAncestors,
  withDescendants,
} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {ProjectFilterQueries} from '../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition, NodeSearchTuple} from '../../EntityInterfaces/NodeSearch';
import {createStringsSearchQueryGenerator} from './StringsSearch';

const labelQueryGen = createStringsSearchQueryGenerator('labelId');

export interface ScopedLabelIds {
  labelIds: Uuid[];
  nodeTypes: EnumFlowNodeType[];
}

export const scopedLabelIdsDefault: ScopedLabelIds = {
  labelIds: [],
  nodeTypes: [EnumFlowNodeType.VALUE_PROJECT, EnumFlowNodeType.VALUE_GROUP, EnumFlowNodeType.VALUE_WORK_PACKAGE],
};

/**
 * Possibly deprecated - only used in the billing tree
 */
export function createScopedLabelIdsQuery({labelIds, nodeTypes}: ScopedLabelIds): NodeSearchCondition | null {
  const cond = labelQueryGen(labelIds);
  if (cond) {
    if (nodeTypes.length) {
      const nodeTypesQuery = nodeTypes.map<NodeSearchTuple>((nodeType) => ['nodeType', nodeType]);
      return {and: [{or: nodeTypesQuery}, cond]};
    } else {
      return emptyNodeSearchResult; //pseudo-filter, find nothing
    }
  }
  return null;
}

export function scopedLabelIdsQueryGenerator(
  queries: ProjectFilterQueries,
  {labelIds, nodeTypes}: ScopedLabelIds,
): void {
  const cond = labelQueryGen(labelIds);
  if (cond) {
    if (nodeTypes.length) {
      const nodeTypesQuery = nodeTypes.map<NodeSearchTuple>((nodeType) => ['nodeType', nodeType]);
      const allTypeLabels: NodeSearchCondition = {and: [{or: nodeTypesQuery}, cond]};
      queries.descendants.push(allTypeLabels);

      nodeTypes.forEach((nodeType) => {
        const curTypeLabels: NodeSearchCondition = {and: [['nodeType', nodeType], cond]};
        if (nodeType === EnumFlowNodeType.VALUE_BOARD_POST) {
          const parents: NodeSearchCondition = toParents(curTypeLabels);
          queries.indicatingDirectMatchForParents.push(parents);
          queries.subprojects.push(withAncestors(withDescendants(parents, true), true));
          queries.workPackages.push(parents);
        } else if (nodeType === EnumFlowNodeType.VALUE_PROJECT) {
          queries.indicatingDirectMatchForParents.push(curTypeLabels);
          //As soon as there are queries pushed onto `descendants`, the wrapDescendantsWithEmptyProjects causes
          // empty projects to appear, too. The following would counteract that and only show matching projects:
          // queries.projects.push({transform: [allTypeLabels, 'extendWithAncestors']});
        } else if (nodeType === EnumFlowNodeType.VALUE_GROUP) {
          queries.indicatingDirectMatchForParents.push(curTypeLabels);
          //Filter out groups that are not on the trace of matching labels:
          queries.subprojects.push(withAncestors(withDescendants(allTypeLabels, true), true));
        } else if (nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
          //This is required because if we match a parent's label, we still want to see all of their work packages.
          // But if we match a work package directly, we only keep exaclty those:
          queries.workPackages.push(withDescendants(allTypeLabels, true));
        }
      });
    } else {
      queries.projects.push(emptyNodeSearchResult); //pseudo-filter, find nothing
    }
  }
}

export function createNodeTypeLabelIdsQueryGenerator(
  nodeTypes: EnumFlowNodeType[],
): (queries: ProjectFilterQueries, labelIds: Uuid[]) => void {
  return (queries, labelIds) =>
    scopedLabelIdsQueryGenerator(queries, {
      labelIds,
      nodeTypes,
    });
}
