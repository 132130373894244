import {Group} from 'konva/lib/Group';
import {defaultFontSize} from '../../CommonComponents/Text';
import {RootContainer, RootContainerProps} from '../../RootContainer';
import {GanttContext} from '../Context/GanttContext';
import {Cell} from './Cells/Cell';
import {TableFactory} from './TableFactory';

export interface BaseRowProps extends RootContainerProps<GanttContext> {
  menuRoot?: Group;
  tableFactory: TableFactory;
}

export interface CellRendererProps {
  x: number;
  width: number;
  isVisble: boolean;
  menuRoot: Group | null;
  columnName: string;
}
export abstract class BaseRow extends RootContainer<GanttContext> {
  protected readonly padding = 5;
  protected readonly imageSize = 13;
  protected height = 0;
  protected readonly tableFactory: TableFactory;
  readonly #createdCells = new Map<string, Cell | null>();
  protected readonly menuRoot: Group | null;

  constructor(props: BaseRowProps) {
    super(props);
    this.height = this.ctx.rowHeight;
    this.menuRoot = props.menuRoot || null;
    this.tableFactory = props.tableFactory;
  }

  protected get textLineOffset(): number {
    return (this.height - defaultFontSize) * -0.5 + 2;
  }

  init(): void {
    this.disposables.push(
      this.ctx.eventEmitter.on('visibleColumnsChanged', (): void => {
        this.updateCells();
      }),
    );
    this.updateCells();
  }

  protected updateCells(): void {
    if (this.ctx.visibleColumns.includes('hideAll')) {
      return;
    }
    let x = 0;
    const columnNames = this.tableFactory.getColumnNames();
    for (const columnName of columnNames) {
      const isVisble = this.tableFactory.isColumnVisible(this.ctx.visibleColumns, columnName);
      const width = this.tableFactory.getColumnWidth(columnName);
      this.updateCell({x, width, isVisble, columnName, menuRoot: this.menuRoot});
      if (isVisble) {
        x += width;
      }
    }
    for (const [columnName, cell] of this.#createdCells) {
      if (cell && !columnNames.includes(columnName)) {
        this.#createdCells.get(columnName)?.dispose();
        this.#createdCells.delete(columnName);
      }
    }
  }

  protected updateCell(props: CellRendererProps): void {
    if (!this.#createdCells.has(props.columnName) && props.isVisble) {
      const cell = this.createCell(props);
      if (cell) {
        cell.init();
        this.disposables.push(() => cell?.dispose());
        this.root.add(cell.root);
      }
      this.#createdCells.set(props.columnName, cell);
    }
    this.#createdCells.get(props.columnName)?.update(props);
  }

  protected abstract createCell(props: CellRendererProps): Cell | null;
}
