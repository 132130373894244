import {isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {Point} from '@octaved/svg';
import {Uuid} from '@octaved/typescript/src/lib';
import {toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import dayjs from 'dayjs';
import {Dispatch, SetStateAction} from 'react';
import {addPlanningDate} from '../../../../../../../Modules/PlanningDates';
import {DragMode} from '../../../../../../../Modules/Ui';
import AssigneUnit from './AssigneUnit';
import {DraggingImpl, DraggingProps} from './Dragging';

interface AddStart {
  setPosition: Dispatch<SetStateAction<Point | null>>;
  nodeId: Uuid;
  today: dayjs.Dayjs;
}

interface AddState {
  setPosition: Dispatch<SetStateAction<Point | null>>;
  nodeId: Uuid;
  isDragging: boolean;
  today: dayjs.Dayjs;
}

export class Add extends DraggingImpl<AddState, AddStart> {
  readonly #assigneUnits: AssigneUnit;

  constructor(props: DraggingProps) {
    super(props);
    this.#assigneUnits = new AssigneUnit({ctx: props.ctx});
  }

  onEnd = (
    _clientX: number,
    _clientY: number,
    event: MouseEvent | TouchEvent,
    _clientStartX: number,
    _clientStartY: number,
  ): void => {
    const state = this.state;
    if (state) {
      const target = this.#assigneUnits.getDropZoneFromTarget(event.target);
      if (target) {
        const targetUnit = this.#assigneUnits.createUnitFromTarget(target);
        const planningDateId = generateUuid();
        this.ctx.store.dispatch(
          addPlanningDate(
            state.nodeId,
            planningDateId,
            toIsoDateTimeFormat(state.today),
            toIsoDateTimeFormat(state.today),
            true,
          ),
        );
        const node = getNodeSelector(this.ctx.store.getState())(state.nodeId);
        if (isWorkPackage(node)) {
          this.#assigneUnits.updateResponsibleNode(null, targetUnit, node);
        }
      }
    }
    this.cancel();
  };

  onMove = (
    clientX: number,
    clientY: number,
    _event: MouseEvent | TouchEvent,
    _clientStartX: number,
    _clientStartY: number,
  ): void => {
    if (this.state) {
      this.state.setPosition({x: clientX, y: clientY});
    }
  };

  onStart = ({nodeId, setPosition, today}: AddStart): void => {
    this.state = {
      nodeId,
      setPosition,
      today,
      isDragging: true,
    };
    this.setDragMode?.(DragMode.vertical);
    this.ctx.closeInspector();
  };

  cancel(): void {
    this.state?.setPosition(null);
    super.cancel();
  }
}
