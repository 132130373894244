import dayjsMutable from '@octaved/dayjs-mutable';
import {isDebug} from '@octaved/env/src/Environment';
import {error} from '@octaved/env/src/Logger';
import dayjs from 'dayjs';

export type Moment = dayjsMutable.Moment;

export function dayjsToMoment(day: dayjs.Dayjs): Moment {
  return dayjsMutable(day.toISOString()) as unknown as Moment;
}

export function momentToDayjs(mom: Moment): dayjs.Dayjs {
  return dayjs(mom.toISOString());
}

type Nil = null | undefined;

export function optionalMomentToDayjs<O extends Nil>(mom: Moment | O): dayjs.Dayjs | O {
  return mom ? momentToDayjs(mom) : mom;
}

export function optionalDayjsToMoment<O extends Nil>(day: dayjs.Dayjs | O): Moment | O {
  return day ? dayjsToMoment(day) : day;
}

if (isDebug) {
  //Check to see if this is actually correct when loading in a browser
  setTimeout(() => {
    const format = 'YYYY-MM-DD';
    const day = dayjs('2020-01-01');
    day.add(5, 'day');
    if (day.format(format) !== '2020-01-01') {
      error('dayjs is mutable!');
    }

    const dayMutable = dayjsMutable('2020-01-01');
    dayMutable.add(5, 'day');
    if (dayMutable.format(format) !== '2020-01-06') {
      error('@octaved/dayjs-mutable is not mutable!');
    }
  }, 5000);
}
