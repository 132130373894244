import {EnumFlowNodeRoleType, EnumFlowNodeType, EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import type {License} from '@octaved/license/src/LicenseInterfaces';
import type {DateStr, DateTimeStr} from '@octaved/typescript';
import type {DeepPartialObject, Uuid} from '@octaved/typescript/src/lib';
import type {OrgUserSettings} from '@octaved/users/src/EntityInterfaces/OrgUserSettings';
import type {WebsocketEvent} from '@octaved/websocket';
import type {Action, UnknownAction} from 'redux';
import type {BillingPatchKeys} from '../EntityInterfaces/Billing/Billings';
import type {BoardPost} from '../EntityInterfaces/BoardPost';
import type {CustomerPatchData} from '../EntityInterfaces/Customers';
import type {NodesMoveOrResort, NodeType} from '../EntityInterfaces/Nodes';
import type {NodeTrackedMinutes} from '../EntityInterfaces/NodeTrackedMinutes';
import type {Group, Project, WorkPackage} from '../EntityInterfaces/Pid';
import type {ProjectFolder} from '../EntityInterfaces/ProjectFolder';
import type {SubWorkPackage} from '../EntityInterfaces/SubWorkPackage';
import type {Task} from '../EntityInterfaces/Task';
import type {TaskSection} from '../EntityInterfaces/TaskSection';
import {FLOW_NODE_TAG_ADDED, FLOW_NODE_TAG_REMOVED} from './Actions';
import {
  FLOW_CHANGE_PID_REQUEST,
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_CHANGE_TASK_FAILURE,
  FLOW_CHANGE_TASK_REQUEST,
  FLOW_CHANGE_TASK_SUCCESS,
  FLOW_COPY_PID_REQUEST,
  FLOW_CREATE_GROUP_REQUEST,
  FLOW_CREATE_GROUP_SUCCESS,
  FLOW_CREATE_PROJECT_REQUEST,
  FLOW_CREATE_PROJECT_SUCCESS,
  FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST,
  FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS,
  FLOW_CREATE_TASK_REQUEST,
  FLOW_CREATE_TASK_SUCCESS,
  FLOW_CREATE_WORK_PAGKAGE_REQUEST,
  FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
  FLOW_MOVE_TASK_SECTIONS_REQUEST,
  FLOW_MOVE_TASK_SECTIONS_SUCCESS,
  FLOW_MOVE_TASKS_REQUEST,
  FLOW_MOVE_TASKS_SUCCESS,
  FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST,
  FLOW_PATCH_PROJECT_FAILURE,
  FLOW_PATCH_PROJECT_REQUEST,
  FLOW_PATCH_PROJECT_SUCCESS,
  FLOW_REMOVE_BOARD_POST_REQUEST,
  FLOW_REMOVE_BOARD_POST_SUCCESS,
  FLOW_REMOVE_PROJECT_FOLDER_REQUEST,
  FLOW_REMOVE_PROJECT_FOLDER_SUCCESS,
  FLOW_REMOVE_SUB_WORK_PACKAGE_REQUEST,
  FLOW_REMOVE_SUB_WORK_PACKAGE_SUCCESS,
  FLOW_REMOVE_TASK_REQUEST,
  FLOW_REMOVE_TASK_SECTION_REQUEST,
  FLOW_REMOVE_TASK_SECTION_SUCCESS,
  FLOW_REMOVE_TASK_SUCCESS,
  FLOW_RESORT_BOARD_POST_REQUEST,
  FLOW_SUBSCRIBE_TO_NODE_FAILURE,
  FLOW_SUBSCRIBE_TO_NODE_REQUEST,
  FLOW_SUBSCRIBE_TO_NODE_SUCCESS,
  FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE,
  FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST,
  FLOW_UNSUBSCRIBE_FROM_NODE_SUCCESS,
} from './ActionTypes';
import {ResponsiblePatches} from './ResponsibleNode';

export interface NodeEvent {
  nodeId: Uuid;
}

export interface TagEvent {
  tagId: Uuid;
}

export interface GroupEvent {
  groupId: Uuid;
}

export interface UserEvent {
  userId: Uuid;
}

type NodeTagEvent = NodeEvent & TagEvent;

export interface BoardPostRemoveEvent {
  nodeIds: Uuid[];
  type: typeof FLOW_REMOVE_BOARD_POST_REQUEST | typeof FLOW_REMOVE_BOARD_POST_SUCCESS;
}

export interface LabelRemovedEvent {
  id: Uuid; //label id
  type: 'flow.LabelRemovedEvent';
}

export interface LdapSettingsChangedEvent extends WebsocketEvent {
  affectedGroupIds: Uuid[];
  type: 'flow.LdapSettingsChangedEvent';
}

export interface LdapSynchronizedEvent extends WebsocketEvent {
  type: 'flow.LdapSynchronizedEvent';
}

export interface LicenseChangedEvent extends WebsocketEvent {
  license: License;
  organizationId: number;
  type: 'flow.LicenseChangedEvent';
}

export interface NodeArchivedEvent extends NodeEvent, WebsocketEvent {
  archived: boolean;
  type: 'flow.NodeArchivedEvent';
}

export interface NodeRoleAssignmentEvent extends NodeEvent {
  isGuestRole: boolean;
  type: 'flow.NodeRoleAssignmentEvent';
  roleType: EnumFlowNodeRoleType;
}

export interface NodeCreatedEvent extends NodeEvent, WebsocketEvent {
  definesOwnResponsible: boolean;
  type: 'flow.NodeCreatedEvent';
  nodeType:
    | EnumFlowNodeType.VALUE_BOARD_POST
    | EnumFlowNodeType.VALUE_PROJECT_FOLDER
    | EnumFlowNodeType.VALUE_TASK_SECTION
    | EnumFlowNodeType.VALUE_USER_NODE;
  parentNodeId: Uuid;
  customerId: Uuid | null; //only for pids
}

interface NodesResponsibilitiesBulkPatch {
  patches: ResponsiblePatches;
}

export interface NodesResponsibilitiesBulkPatchEvent extends NodesResponsibilitiesBulkPatch, UnknownAction {
  type: typeof FLOW_PATCH_NODES_RESPONSIBILITIES_REQUEST;
}

export interface NodesResponsibilitiesBulkPatchedEvent extends NodesResponsibilitiesBulkPatch, WebsocketEvent {
  affectedUserIds: Uuid[];
  type: 'flow.NodesResponsibilitiesBulkPatchedEvent';
}

export interface ProjectCreateEvent {
  parentNodeId: Uuid;
  project: Project;
  type: typeof FLOW_CREATE_PROJECT_REQUEST | typeof FLOW_CREATE_PROJECT_SUCCESS;
}

export interface ProjectCreatedEvent extends NodeEvent, WebsocketEvent {
  definesOwnResponsible: boolean;
  customerId: Uuid;
  parentNodeId: Uuid;
  type: 'flow.ProjectCreatedEvent';
}

export interface MaterialResourceCreatedEvent extends NodeEvent, WebsocketEvent {
  parentNodeId: Uuid;
  type: 'flow.MaterialResourceCreatedEvent';
}

export interface GroupCreateEvent {
  group: Group;
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | undefined;
  type: typeof FLOW_CREATE_GROUP_REQUEST | typeof FLOW_CREATE_GROUP_SUCCESS;
}

export interface GroupCreatedEvent extends NodeEvent, WebsocketEvent {
  customerId: Uuid;
  definesOwnResponsible: boolean;
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.GroupCreatedEvent';
}

export interface WorkPackageCreateEvent {
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | undefined;
  type: typeof FLOW_CREATE_WORK_PAGKAGE_REQUEST | typeof FLOW_CREATE_WORK_PAGKAGE_SUCCESS;
  workPackage: WorkPackage;
}

export interface WorkPackageCreatedEvent extends NodeEvent, WebsocketEvent {
  definesOwnResponsible: boolean;
  customerId: Uuid;
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.WorkPackageCreatedEvent';
}

export interface SubWorkPackageCreateEvent {
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | undefined;
  type: typeof FLOW_CREATE_SUB_WORK_PAGKAGE_REQUEST | typeof FLOW_CREATE_SUB_WORK_PAGKAGE_SUCCESS;
  subWorkPackage: SubWorkPackage;
}

export interface SubWorkPackageCreatedEvent extends NodeEvent, WebsocketEvent {
  definesOwnResponsible: boolean;
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.SubWorkPackageCreatedEvent';
}

export interface SubWorkPackagePatchedEvent extends NodeEvent, WebsocketEvent {
  patchedKeys: Array<keyof SubWorkPackage>;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.SubWorkPackagePatchedEvent';
}

export interface SubWorkPackageRemoveEvent {
  nodeIds: Uuid[];
  type: typeof FLOW_REMOVE_SUB_WORK_PACKAGE_REQUEST | typeof FLOW_REMOVE_SUB_WORK_PACKAGE_SUCCESS;
}

export interface TaskCreateEvent {
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | undefined;
  task: Task;
  type: typeof FLOW_CREATE_TASK_REQUEST | typeof FLOW_CREATE_TASK_SUCCESS;
}

export interface TaskCreatedEvent extends NodeEvent, WebsocketEvent {
  definesOwnResponsible: boolean;
  parentNodeId: Uuid;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.TaskCreatedEvent';
}

export interface NodesRearrangeEvent extends TrackedTimeAffectingEvent, WebsocketEvent {
  changedFlowCustomerPidIds: Uuid[];
  movedNodeId: Uuid;
  newFlowCustomerId: Uuid | null;
  newParentNodeId: Uuid;
  nodeIds: Uuid[];
  oldParentNodeId: Uuid;
  type: 'flow.NodesRearrangeEvent';
}

export interface NodesResortedEvent extends WebsocketEvent {
  nodeIds: Uuid[];
  type: 'flow.NodesResortedEvent';
}

export interface BoardPostsResortedEvent {
  nodeIds: Uuid[];
  type: typeof FLOW_RESORT_BOARD_POST_REQUEST;
}

/**
 * @deprecated use dedicated event per node type if available
 */
export interface NodePatchedEvent extends NodeEvent, WebsocketEvent {
  type: 'flow.NodePatchedEvent';
  nodeType: EnumFlowNodeType;
  patchedKeys: Array<keyof BoardPost | keyof ProjectFolder | keyof TaskSection>;
}

export interface ProjectPatchEvent {
  patch: DeepPartialObject<Project>;
  patchedKeys: Array<keyof Project>;
  projectId: Uuid;
  type: typeof FLOW_PATCH_PROJECT_FAILURE | typeof FLOW_PATCH_PROJECT_REQUEST | typeof FLOW_PATCH_PROJECT_SUCCESS;
}

export interface ProjectPatchedEvent extends NodeEvent, WebsocketEvent {
  patchedKeys: Array<keyof Project>;
  type: 'flow.ProjectPatchedEvent';
}

export interface ProjectCustomerChangedEvent extends WebsocketEvent {
  affectedNodeIds: ReadonlyArray<Uuid>;
  customerId: Uuid;
  projectId: Uuid;
  type: 'flow.ProjectCustomerChangedEvent';
}

export interface GroupPatchedEvent extends NodeEvent, WebsocketEvent {
  patchedKeys: Array<keyof Group>;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.GroupPatchedEvent';
}

export interface WorkPackagePatchedEvent extends NodeEvent, WebsocketEvent {
  patchedKeys: Array<keyof WorkPackage>;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.WorkPackagePatchedEvent';
}

export interface TaskPatchEvent {
  options: {
    data: DeepPartialObject<Task>;
    urlParams: {
      taskId: Uuid;
    };
  };
  patchedNodeId: Uuid;
  previous: Task;
  type: typeof FLOW_CHANGE_TASK_FAILURE | typeof FLOW_CHANGE_TASK_REQUEST | typeof FLOW_CHANGE_TASK_SUCCESS;
}

export interface WorkPackagePatchAction {
  options: {
    urlParams: {
      groupId?: Uuid;
      workPackageId?: Uuid;
    };
  };
  type: typeof FLOW_CHANGE_PID_REQUEST | typeof FLOW_CHANGE_PID_SUCCESS;
}

export interface TaskPatchedEvent extends NodeEvent, WebsocketEvent {
  patchedKeys: Array<keyof Task>;
  sortedSiblingIds: Uuid[] | null;
  type: 'flow.TaskPatchedEvent';
}

export interface PlanningEventPlanningData {
  id: Uuid;
  plannedStart: DateTimeStr;
  plannedEnd: DateTimeStr;
}

export interface PlanningEvent extends WebsocketEvent {
  isServerSide: boolean;
  type: 'flow.PlanningEvent';
  changedDependencies: Uuid[];
  updatedDependencies: {
    planningSuccessors: Record<Uuid, Uuid[]>;
    planningPredecessors: Record<Uuid, Uuid[]>;
  };
  updatedLogicalDependencies: {
    tasks: Record<Uuid, Uuid[]>; // {successorId => predecessorId[]}
    workPackages: Record<Uuid, Uuid[]>; // {successorId => predecessorId[]}
  };
  updatedDueDates: Record<Uuid, {dueDate: string | null}>;
  updatedNodeIds: Uuid[];
  updatedPlanningDateNodeIds: Uuid[];
  updatedNodePlanningDates: Record<string, PlanningEventPlanningData[] | null>;
  updatedMilestoneNodeIds: Uuid[];
  source:
    | 'GroupTypeChanged'
    | 'Cleanup'
    | 'AutoChain'
    | 'Update'
    | 'NodeMoved'
    | 'NodeRemoved'
    | 'OnCopyTasks'
    | 'WorkpackageCreated';
}

export interface ChangePidSuccessEvent {
  type: typeof FLOW_CHANGE_PID_SUCCESS;
  patchedNodeId: Uuid;
  patchedKeys: Array<keyof Project | keyof Group | keyof WorkPackage>;
}

export interface NodeMovedEvent extends NodeEvent {
  type: 'flow.NodeMovedEvent';
  newParentNodeId: Uuid;
}

export interface NodesMoveOrResortEvent extends WebsocketEvent, NodesMoveOrResort {
  //Maps nodes that have their root changed - what's a root depends on the node type (e.g. wp/userNode for tasks):
  changedRootNodes: Record<
    Uuid,
    {
      newRootId: Uuid;
      oldRootId: Uuid;
    }
  >;
}

export interface NodeTagAddedEvent extends NodeTagEvent {
  type: typeof FLOW_NODE_TAG_ADDED;
}

export interface NodeTagRemovedEvent extends NodeTagEvent {
  type: typeof FLOW_NODE_TAG_REMOVED;
}

export interface PidCopiedEvent extends WebsocketEvent {
  copiedNodeId: Uuid;
  copiedNodeIds: Record<Uuid, Uuid>; // {sourceNodeId => copiedNodeId}
  copiedNodeTree: Record<Uuid, Uuid>; // {copiedNodeId => parentNodeId}
  copiedNodeType: EnumFlowNodeType;
  copiedNodeTypes: Partial<Record<EnumFlowNodeType, Uuid[]>>;
  planningWasCopied: boolean;
  sourceNodeId: Uuid;
  targetParentNodeId: Uuid;
  type: 'flow.PidCopiedEvent';
  updatedSortOrders: Record<Uuid, number>;
}

export interface ProjectFolderRemoveEvent {
  nodeIds: Uuid[];
  type: typeof FLOW_REMOVE_PROJECT_FOLDER_REQUEST | typeof FLOW_REMOVE_PROJECT_FOLDER_SUCCESS;
}

export interface RoleEvent {
  roleId: Uuid;
  roleType: EnumFlowRoleType;
}

export interface RoleRemovedEvent extends RoleEvent, WebsocketEvent {
  type: 'flow.RoleRemovedEvent';
}

export interface UserSettingsChangedEvent extends WebsocketEvent {
  type: 'flow.UserSettingsChangedEvent';
  userSettings: OrgUserSettings;
}

export interface TrackedTimeAffectingEvent {
  affectedTimeTrackingNodeIds: ReadonlyArray<Uuid>;
  affectedTimeTrackingNodes: ReadonlyArray<{id: Uuid} & NodeTrackedMinutes>;
}

export interface TimeRecordEvent extends TrackedTimeAffectingEvent {
  affectedDates: DateStr[];
  orgUserId: Uuid;
  timeRecordId: Uuid;
  workingTimeAutoUpdated: boolean;
}

export interface TimeRecordCreatedEvent extends TimeRecordEvent, WebsocketEvent {
  type: 'flow.TimeRecordCreatedEvent';
}

export interface TimeRecordPatchedEvent extends TimeRecordEvent, WebsocketEvent {
  previousUserId?: Uuid; //only if changed
  type: 'flow.TimeRecordPatchedEvent';
}

export interface TimeRecordRemovedEvent extends TimeRecordEvent, WebsocketEvent {
  type: 'flow.TimeRecordRemovedEvent';
}

export interface RolesReSortedEvent extends WebsocketEvent {
  type: 'flow.RolesReSortedEvent';
  roleType: EnumFlowRoleType;
  roleIds: Uuid[];
}

export interface CopyPidRequestEvent {
  type: typeof FLOW_COPY_PID_REQUEST;
  copyNode: NodeType;
  incrementSortOrderPidIds: Uuid[];
  targetParentNodeId: Uuid;
}

interface BillingWithReferencesEvent {
  billingId: Uuid;
  timeRecordIds: Uuid[];
  workPackageIds: Uuid[];
}

export interface BillingCreatedEvent extends BillingWithReferencesEvent, WebsocketEvent {
  type: 'flow.BillingCreatedEvent';
}

export interface BillingRemovedEvent extends BillingWithReferencesEvent, WebsocketEvent {
  type: 'flow.BillingRemovedEvent';
}

export interface BillingPatchedEvent extends WebsocketEvent {
  type: 'flow.BillingPatchedEvent';
  billingId: Uuid;
  patchedProperties: BillingPatchKeys;
}

export interface CustomerPatchedEvent extends WebsocketEvent {
  type: 'flow.CustomerPatchedEvent';
  customerId: Uuid;
  patchedProperties: Array<keyof CustomerPatchData>;
}

export interface TasksCopiedEvent extends WebsocketEvent {
  fromWorkPackageId: Uuid;
  newTaskIdsToParentIds: Record<Uuid, Uuid>;
  toWorkPackageId: Uuid;
  type: 'flow.TasksCopiedEvent';
}

export interface TasksMoveEvent extends NodesMoveOrResort {
  type: typeof FLOW_MOVE_TASKS_REQUEST | typeof FLOW_MOVE_TASKS_SUCCESS;
}

export interface TasksMovedEvent extends NodesMoveOrResortEvent {
  type: 'flow.TasksMovedEvent';
}

export interface TaskRemoveEvent {
  nodeIds: Uuid[];
  type: typeof FLOW_REMOVE_TASK_REQUEST | typeof FLOW_REMOVE_TASK_SUCCESS;
}

export interface TaskSectionsMoveEvent extends NodesMoveOrResort {
  type: typeof FLOW_MOVE_TASK_SECTIONS_REQUEST | typeof FLOW_MOVE_TASK_SECTIONS_SUCCESS;
}

export interface TaskSectionsMovedEvent extends NodesMoveOrResortEvent {
  type: 'flow.TaskSectionsMovedEvent';
}

export interface TaskSectionRemoveEvent {
  nodeIds: Uuid[];
  type: typeof FLOW_REMOVE_TASK_SECTION_REQUEST | typeof FLOW_REMOVE_TASK_SECTION_SUCCESS;
}

export interface TimeRecordsPriceCategoryForWorkPackageChangedEvent {
  priceCategoryId: Uuid | null;
  workPackageId: Uuid;
}

export type NodesRemoveEvent =
  | BoardPostRemoveEvent
  | ProjectFolderRemoveEvent
  | TaskRemoveEvent
  | TaskSectionRemoveEvent;

export interface NodesRemovedEvent extends TrackedTimeAffectingEvent, WebsocketEvent {
  nodeIds: Uuid[];
  type: 'flow.NodesRemovedEvent';
}

export interface WorkPackagesFlagsChangedEvent extends WebsocketEvent {
  nodeIds: Uuid[];
  flags: {
    isApprovedForBilling?: boolean;
    isLocked?: boolean;
    isOffer?: boolean;
  };
  type: 'flow.WorkPackagesFlagsChangedEvent';
}

interface TimeRecordsRestored {
  referenceNodeIds: Uuid[];
  timeRecordIds: Uuid[];
  workPackageIds: Uuid[];
}

export interface NodeRestoredFromTrashEvent extends WebsocketEvent {
  affectedOtherNodeIds: Uuid[];
  allAffectedNodeIds: Uuid[];
  nodeId: Uuid;
  nodeSubtree: Record<Uuid, Uuid>;
  nodeType: EnumFlowNodeType;
  nodeTypes: Partial<Record<EnumFlowNodeType, Uuid[]>>;
  parentNodeId: Uuid;
  restoredNodeIds: Uuid[];
  timeRecordsRestoredEvents: TimeRecordsRestored[];
  type: 'flow.NodeRestoredFromTrashEvent';
}

export interface TimeRecordsRestoredFromTrashEvent extends WebsocketEvent, TimeRecordsRestored {
  type: 'flow.TimeRecordsRestoredFromTrashEvent';
}

export interface NodeSubscribeEvent {
  key: string; // "favorite-{USERID}"
  nodeId: Uuid;
  type:
    | typeof FLOW_SUBSCRIBE_TO_NODE_REQUEST
    | typeof FLOW_SUBSCRIBE_TO_NODE_SUCCESS
    | typeof FLOW_SUBSCRIBE_TO_NODE_FAILURE;
}

export interface NodeSubscribedEvent extends WebsocketEvent {
  key: string; // "favorite-{USERID}"
  nodeId: Uuid;
  type: 'flow.NodeSubscribedEvent';
}

export interface NodeUnsubscribeEvent {
  key: string; // "favorite-{USERID}"
  nodeId: Uuid;
  type:
    | typeof FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST
    | typeof FLOW_UNSUBSCRIBE_FROM_NODE_SUCCESS
    | typeof FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE;
}

export interface NodeUnsubscribedEvent extends WebsocketEvent {
  key: string; // "favorite-{USERID}"
  nodeId: Uuid;
  type: 'flow.NodeUnsubscribedEvent';
}

export interface GuestRoleRightMatrixChangedEvent extends WebsocketEvent {
  roleId: Uuid;
  rights: string[];
  type: 'flow.GuestRoleRightMatrixChangedEvent';
}

export interface InternalRoleRightMatrixChangedEvent extends WebsocketEvent {
  affectedRoleIds: Uuid[];
  affectedRightIdents: string[];
  type: 'flow.InternalRoleRightMatrixChangedEvent';
}

export interface WorkTimeManagedEvent extends WebsocketEvent {
  affectedUnitIds: Uuid[];
  type: 'flow.WorkTimeManagedEvent';
}

/**
 * Strips the event from the properties the server adds when firing the event
 */
export type EventData<E extends Partial<Action & WebsocketEvent>> = Omit<E, 'responsibleInstanceId' | 'type'>;

export function isEvent<Event extends Action>(type: Event['type'], action: Action): action is Event {
  return action.type === type;
}
