import {DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import {ucfirst} from '@octaved/utilities';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {TimeRecord} from '../../../../../EntityInterfaces/TimeRecords';

interface DateCellParams {
  timeRecord: TimeRecord;
}

export default function DateCell({timeRecord}: DateCellParams): ReactElement | null {
  const formatInWords = useFormatInWords();
  if (timeRecord.workTimeStart) {
    return <span>{ucfirst(formatInWords(dayjs.unix(timeRecord.workTimeStart), DATE))}</span>;
  }
  return null;
}
