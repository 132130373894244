import {ReactElement, lazy} from 'react';
import FlowOutlet from '../../../Components/FlowOutlet';
import {useInspectorContext} from '../../../Drawer/InspectorContext/InspectorContext';
import {isGroup, isProject, isWorkPackage} from '../../../Node/NodeIdentifiers';

const SpecialQuoteWorkPackage = lazy(
  () => import('@octaved/node-details/src/InspectorView/Views/WorkPackage/SpecialQuote'),
);
const SpecialQuoteGroup = lazy(() => import('@octaved/node-details/src/InspectorView/Views/Group/SpecialQuote'));
const SpecialQuoteProject = lazy(() => import('@octaved/node-details/src/InspectorView/Views/Project/SpecialQuote'));

export default function SpecialQuoteInspector(): ReactElement | null {
  const context = useInspectorContext();
  if (context.selectedEntity) {
    return (
      <>
        {isWorkPackage(context.selectedEntity) && <SpecialQuoteWorkPackage node={context.selectedEntity} />}
        {isGroup(context.selectedEntity) && <SpecialQuoteGroup node={context.selectedEntity} />}
        {isProject(context.selectedEntity) && <SpecialQuoteProject node={context.selectedEntity} />}
        <FlowOutlet />
      </>
    );
  }
  return null;
}
