import {Uuid} from '@octaved/typescript/src/lib';
import classNames from 'classnames';
import {ReactElement, useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import normalizeShortName from '../../Hooks/ShortnameNormalizer';
import {customerEntitiesSelector} from '../../Modules/Selectors/CustomerSelectors';
import {flowStyleContext} from '../../Styles/StyleContext';

interface Props {
  flowCustomer: Uuid | null;
  size?: 'normal' | 'small' | 'boxed' | '' | 'verySmall' | 'smallAvatar';
  noLeftMargin?: boolean;
  noMargin?: boolean;
  imageSize: string;
  initialsSize: string;
  centered?: boolean;
}

export default function CustomerAvatar({
  flowCustomer,
  size = 'normal',
  noLeftMargin = false,
  noMargin = false,
  imageSize = '60px',
  initialsSize = '30px',
  centered,
}: Props): ReactElement {
  const {
    COLORS: {AVATAR},
  } = useContext(flowStyleContext);
  const customers = useSelector(customerEntitiesSelector);

  const customerInitials = useMemo(() => {
    const customer = flowCustomer && customers[flowCustomer];
    if (customer) {
      return normalizeShortName(customer.name);
    }

    return '';
  }, [flowCustomer, customers]);

  const logoUrl = (flowCustomer && customers[flowCustomer]?.logoUrl) || null;

  return (
    <div className={classNames('customerAvatar', size, {noLeftMargin, noMargin, centered, image: !!logoUrl})}>
      {!logoUrl && <div className={'initials'}>{customerInitials}</div>}
      {!!logoUrl && (
        <img
          className={'imageAvatar'}
          src={logoUrl}
          sizes={'60px' /* smallest default variant is 200px */}
          alt={((flowCustomer && customers[flowCustomer]?.name) || flowCustomer) + '-avatar'}
        />
      )}

      {/*language=SCSS*/}
      <style jsx>{`
        .customerAvatar {
          width: ${logoUrl ? imageSize : initialsSize};
          height: ${logoUrl ? imageSize : initialsSize};
          margin: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${AVATAR.FONT};
          background-color: #666;
          border-radius: 5px;
          color: ${AVATAR.FONT};
          font-size: 14px;
          font-weight: bold;

          &.image {
            background-color: transparent;
          }
        }

        .initials {
          font-size: 16px;
          line-height: 15px;
          font-weight: bold;
          margin-top: -2px;
        }

        .smallAvatar {
          width: 22px;
          height: 22px;
          margin-left: 0;
          border-radius: 6px;

          & .initials {
            margin-top: 0px;
            font-size: 12px;
            line-height: 22px;
          }
        }

        .small {
          width: 24px;
          height: 24px;
          margin-left: 0;
          border-radius: 6px;

          & .initials {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .verySmall {
          margin-right: 5px;

          & .initials {
            font-size: 15px;
            line-height: 15px;
          }
        }

        .boxed {
          height: 40px;
          width: 150px;
          border-radius: 4px;
          margin: 0;
        }

        .noLeftMargin {
          margin-left: 0;
        }

        .noMargin {
          margin: 0;
        }

        .centered {
          margin: 0 auto 5px auto;
        }

        .imageAvatar {
          object-fit: cover;
          width: 100%;
          max-height: 100%;
        }
      `}</style>
    </div>
  );
}
