import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useProjectUiStatePatch} from '../../Pages/Projects/Projects';
import {nodeTreeSelector} from '../Selectors/NodeTreeSelectors';
import {projectsOpenNodesSetSelector} from '../Selectors/UiSelectors';
import {ProjectTreeOptions} from './ProjectTreeInterfaces';

export function useDefaultProjectTreeOptions(): ProjectTreeOptions {
  const patchUi = useProjectUiStatePatch();
  return {
    expandedNodeIds: useSelector(projectsOpenNodesSetSelector),
    nodeTree: useSelector(nodeTreeSelector),
    setExpandedNodeIds: useCallback((ids) => patchUi({openNodes: [...ids]}), [patchUi]),
  };
}
