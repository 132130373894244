import {isLoaded} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {FullUnit, UnitListsLists, UnitListsState, UnitListsStates} from '../EntityInterfaces/UnitLists';
import {SimpleUnitType} from '../UnitType';
import {groupsSelector} from './GroupSelectors';
import {userEntitiesSelector} from './UserSelectors';

export const unitListsSelector = (state: UnitListsState): UnitListsLists | undefined => state.unitLists?.lists;
export const unitListsStatesSelector = (state: UnitListsState): UnitListsStates | undefined => state.unitLists?.states;

export const isUnitListLoadedSelector = createSelector(unitListsStatesSelector, (unitListsStates) =>
  memoize((unitType: SimpleUnitType) => {
    const state = unitListsStates && unitListsStates[unitType];
    return Boolean(state && isLoaded(state));
  }),
);

export function createSortFullUnitsByType(groupsFirst: boolean): (a: FullUnit, b: FullUnit) => number {
  const ab = groupsFirst ? 1 : -1;
  const ba = groupsFirst ? -1 : 1;
  return (a, b) => {
    const aIsGroup = a.unitType === SimpleUnitType.group;
    const bIsGroup = b.unitType === SimpleUnitType.group;
    if (!aIsGroup && bIsGroup) {
      return ab;
    }
    if (aIsGroup && !bIsGroup) {
      return ba;
    }
    return a.unitName.localeCompare(b.unitName);
  };
}

export const sortFullUnitsGroupsFirst = createSortFullUnitsByType(true);
export const sortFullUnitsGroupsLast = createSortFullUnitsByType(false);

export const getFullUnitFromIdSelector = createSelector(groupsSelector, userEntitiesSelector, (groups, users) =>
  memoize((unitId: Uuid): FullUnit | null => {
    const group = groups[unitId];
    const user = users[unitId];
    if (group) {
      return {
        unitId,
        unitName: group.name,
        unitType: SimpleUnitType.group,
      };
    }
    if (user) {
      return {
        unitId,
        unitName: user.name,
        unitType: SimpleUnitType.user,
      };
    }
    return null;
  }),
);
