import {TWButton, Tooltip, cn} from '@octaved/ui';
import {CheckCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  checked: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  label: string;
  setChecked: (checked: boolean) => void;
  tooltip?: string;
}

export default function CheckButton({checked, disabled, highlighted, label, setChecked, tooltip}: Props): ReactElement {
  const {t} = useTranslation();

  return (
    <>
      <Tooltip position={'bottom center'} toolTipTranslation={tooltip || ''}>
        <TWButton
          withIcon
          size={'sm'}
          variant={checked || highlighted ? 'solid' : 'outline'}
          colorScheme={checked ? 'green' : 'slate'}
          disabled={disabled}
          onClick={async () => await setChecked(!checked)}
        >
          <CheckCircle className={cn('size-4 text-current')} />
          {t(label)}
        </TWButton>
      </Tooltip>
    </>
  );
}
