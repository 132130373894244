import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {DateStr} from '@octaved/typescript';
import {Bar, BarPluginList, BarProps} from '../../../Canvas/Bars/Bar';
import {MaturityDateBar} from '../../../Canvas/Bars/MaturityDateBar';
import {GroupedBarRenderPlugin} from '../../../Canvas/Bars/Plugins/GroupedBarRenderPlugin';
import {MaturityPopupPlugin} from '../../../Canvas/Bars/Plugins/MaturityClickPlugin';
import {BarRow, BarRowProps} from '../../../Canvas/Gantt/Bars/BarRow';
import {createStoreSubscription} from '../../../Canvas/StoreSubscription';
import {PlanningNodeBaselineHistory} from '../../../EntityInterfaces/PlanningBaselineHistory';
import {GroupedMilestoneResult, MaturityDayData} from '../../../Selectors/MilestoneSelectors';
import {getSelectedPlanningNodeBaselineHistoriesSelector} from '../../../Selectors/PlanningBaselineHistorySelectors';
import {ColoredBarRenderPlugin} from './BarPlugins/ColoredBarRenderPlugin';
import {ColoredMaturityPlugin} from './BarPlugins/ColoredMaturityPlugin';
import {BaselineBarProps, BaselineDateBar} from './BaselineDateBar';
import {GanttComparisonContext} from './GanttComparisonContext';

export type GanttComparisonRowBarProps = BarRowProps<GanttComparisonContext>;

export class GanttComparisonRowBar extends BarRow<GanttComparisonContext> {
  #nodeBaselineHistories: PlanningNodeBaselineHistory[] = [];

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getSelectedPlanningNodeBaselineHistoriesSelector(s)(this.ctx.rootNodeId, this.node.id),
        (nodeBaselineHistory) => {
          this.#nodeBaselineHistories = nodeBaselineHistory;
          this.#renderBars();
        },
      ),
    );
  }

  #renderBars = this.debouncedAction((): void => {
    this.root.destroyChildren();
    this.disposeRows();
    if (this.#nodeBaselineHistories) {
      for (const baselineHistory of this.#nodeBaselineHistories) {
        const planningDates = baselineHistory.planningDates;
        for (const planningDate of planningDates) {
          const barProps: BaselineBarProps = {
            ctx: this.ctx,
            height: this.height,
            isFirst: true,
            isLast: true,
            menuRoot: this.menuRoot,
            plannedEnd: planningDate.plannedEnd,
            plannedStart: planningDate.plannedStart,
            rowIndex: this.rowIndex,
            rowRoot: this.root,
            treeNode: this.node,
            yOffset: this.yOffset,
          };
          let bar: Bar | null = null;
          if (this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
            bar = this.#createWorkPackageBar(barProps);
          } else if (this.node.type === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
            bar = this.#createSubWorkPackageBar(barProps);
          } else if (this.node.type === EnumFlowNodeType.VALUE_TASK) {
            bar = this.#createTaskBar(barProps);
          } else if (this.node.type === EnumFlowNodeType.VALUE_GROUP) {
            bar = this.#createSprintBar(barProps);
          }
          this.disposableRows.push(() => bar?.dispose());
        }
        if (this.node.type === EnumFlowNodeType.VALUE_GROUP || this.node.type === EnumFlowNodeType.VALUE_PROJECT) {
          //render milestones and due dates
          const maturities: GroupedMilestoneResult[] = this.#getGroupedMilestonesForNodeSelector(baselineHistory);
          for (const maturity of maturities) {
            const barProps: BarProps = {
              ctx: this.ctx,
              height: this.height,
              isFirst: false,
              isLast: false,
              menuRoot: this.menuRoot,
              rowIndex: this.rowIndex,
              rowRoot: this.root,
              treeNode: this.node,
              yOffset: this.yOffset,
            };
            const bar = this.#createMaturityBar(barProps, maturity);
            this.disposableRows.push(() => bar?.dispose());
          }
        }
      }
    }
  });

  #createTaskBar(barProps: BaselineBarProps): Bar | null {
    const bar = new BaselineDateBar(barProps);

    bar.init(new ColoredBarRenderPlugin({ctx: this.ctx, isBaseline: true, rootNodeId: this.ctx.rootNodeId}));
    return bar;
  }

  #createWorkPackageBar(barProps: BaselineBarProps): Bar {
    const bar = new BaselineDateBar(barProps);

    const plugins: BarPluginList = [
      new ColoredBarRenderPlugin({ctx: this.ctx, isBaseline: true, rootNodeId: this.ctx.rootNodeId}),
    ];

    bar.init(...plugins);
    return bar;
  }

  #createSubWorkPackageBar(barProps: BaselineBarProps): Bar {
    const bar = new BaselineDateBar(barProps);

    const plugins: BarPluginList = [
      new ColoredBarRenderPlugin({ctx: this.ctx, isBaseline: true, rootNodeId: this.ctx.rootNodeId}),
    ];

    bar.init(...plugins);
    return bar;
  }

  #createSprintBar(barProps: BaselineBarProps): Bar {
    //is always sprint
    const bar = new BaselineDateBar(barProps);

    let barRenderer;
    if (bar.treeNode.isExpanded) {
      barRenderer = new ColoredBarRenderPlugin({ctx: this.ctx, isBaseline: true, rootNodeId: this.ctx.rootNodeId});
    } else {
      barRenderer = new GroupedBarRenderPlugin({ctx: this.ctx});
    }
    bar.init(barRenderer);
    return bar;
  }

  #createMaturityBar(barProps: BarProps, maturity: GroupedMilestoneResult): Bar {
    const bar = new MaturityDateBar({...barProps, maturity});

    bar.init(
      new ColoredMaturityPlugin({
        ctx: this.ctx,
        isBaseline: true,
        milestones: maturity.maturityData.milestones,
        rootNodeId: this.ctx.rootNodeId,
      }),
      new MaturityPopupPlugin({ctx: this.ctx, maturity}),
    );

    return bar;
  }

  #getGroupedMilestonesForNodeSelector(nodeBaseline: PlanningNodeBaselineHistory): GroupedMilestoneResult[] {
    const result = new Map<DateStr, MaturityDayData>();

    function getMaturityDayData(date: DateStr): MaturityDayData {
      return result.get(date) || {dueDateNodeIds: [], milestones: []};
    }

    for (const milestone of nodeBaseline.milestones) {
      const maturityDayData = getMaturityDayData(milestone.milestoneDate);
      maturityDayData.milestones.push(milestone);
      result.set(milestone.milestoneDate, maturityDayData);
    }
    if (nodeBaseline.dueDate) {
      const maturityDayData = getMaturityDayData(nodeBaseline.dueDate);
      maturityDayData.dueDateNodeIds.push(this.node.id);
      result.set(nodeBaseline.dueDate, maturityDayData);
    }
    return Array.from(result.entries()).map(([date, maturityData]) => ({date, maturityData}));
  }
}
