import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {NodeEntity} from '../../EntityInterfaces/NodeEntity';
import {BaseNodeHistory, NodeHistories, NodeHistoryFactory} from '../../EntityInterfaces/NodeHistory';
import {FlowState} from '../State';

export const nodeHistoryEntityStatesSelector = (state: FlowState): EntityStates => state.entityStates.nodeHistory;
export const nodeHistoryEntitySelector = (state: FlowState): NodeHistories => state.entities.nodeHistory;

interface PropertyChanges<N extends NodeEntity, P extends keyof N> {
  changes: NodeHistoryFactory<N>[];
  lastChange: NodeHistoryFactory<N> | null;
  lastChangePerValue: Map<N[P], NodeHistoryFactory<N>>;
}

const empty: PropertyChanges<NodeEntity, keyof NodeEntity> = {
  changes: [],
  lastChange: null,
  lastChangePerValue: new Map(),
};

//separated from the public getNodePropertyChangesSelector because memoize does not work with generics in its callback
const getPropertyChangesSelector = createSelector(nodeHistoryEntitySelector, (histories) =>
  memoize(
    (nodeId: Uuid, property: string): PropertyChanges<NodeEntity, keyof NodeEntity> => {
      const entries = histories[nodeId];
      if (entries) {
        let lastChange: BaseNodeHistory | null = null;
        let lastValue: unknown;
        const lastChangePerValue = new Map<NodeEntity[keyof NodeEntity], BaseNodeHistory>();
        const changes: BaseNodeHistory[] = [];
        entries.forEach((entry) => {
          const newValue = entry[property as keyof BaseNodeHistory] as NodeEntity[keyof NodeEntity];
          if (lastValue !== newValue) {
            changes.push(entry);
            lastChange = entry;
            lastChangePerValue.set(newValue, entry);
          }
          lastValue = newValue;
        });
        return {changes, lastChange, lastChangePerValue};
      }
      return empty;
    },
    (nodeId: Uuid, property: string): string => `${nodeId}-${property}`,
  ),
);

export function getNodePropertyChangesSelector(
  state: unknown,
): <N extends NodeEntity, P extends keyof N>(nodeId: Uuid, property: P) => PropertyChanges<N, P> {
  return <N extends NodeEntity, P extends keyof N>(nodeId: Uuid, property: P) =>
    getPropertyChangesSelector(state as FlowState)(nodeId, property as string) as PropertyChanges<N, P>;
}
