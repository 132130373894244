import {globalPermission} from '../../Authorization/DefaultRoles';

export enum SystemSettingsPermissionRowInspector {
  GLOBAL_ROLE,
  TIME_RECORDING_LIST,
}

export interface SystemSettingsPermissionRole {
  id: string; //might be role id or ident for special roles
  info: string[];
  inspectorType: SystemSettingsPermissionRowInspector;
  name: string;
}

export interface SystemSettingsPermissionSection {
  id: string;
  info?: string[];
  name: string;
  rows: SystemSettingsPermissionRole[];
}

export const systemSettingsPermissionSections: SystemSettingsPermissionSection[] = [
  {
    id: 'admin',
    name: 'roles:globalPermissions.sections.administration',
    rows: [
      {
        id: globalPermission.adminFull,
        info: [
          'roles:globalPermissions.roles.adminFull.info-1',
          'roles:globalPermissions.roles.adminFull.info-2',
          'roles:globalPermissions.roles.adminFull.info-3',
          'roles:globalPermissions.roles.adminFull.info-4',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.adminFull.name',
      },
    ],
  },
  {
    id: 'systemSettings',
    name: 'roles:globalPermissions.sections.systemSettings',
    rows: [
      {
        id: globalPermission.adminAdvanced,
        info: [
          'roles:globalPermissions.roles.adminAdvanced.info-1',
          'roles:globalPermissions.roles.adminAdvanced.info-2',
          'roles:globalPermissions.roles.adminAdvanced.info-3',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.adminAdvanced.name',
      },
      {
        id: globalPermission.adminProjectFolders,
        info: [
          'roles:globalPermissions.roles.adminProjectFolders.info-1',
          'roles:globalPermissions.roles.adminProjectFolders.info-2',
          'roles:globalPermissions.roles.adminProjectFolders.info-3',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.adminProjectFolders.name',
      },
      {
        id: globalPermission.internalUsersManager,
        info: [
          'roles:globalPermissions.roles.internalUsersManager.info-1',
          'roles:globalPermissions.roles.internalUsersManager.info-2',
          'roles:globalPermissions.roles.internalUsersManager.info-3',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.internalUsersManager.name',
      },
      {
        id: globalPermission.guestUsersManager,
        info: [
          'roles:globalPermissions.roles.guestUsersManager.info-1',
          'roles:globalPermissions.roles.guestUsersManager.info-2',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.guestUsersManager.name',
      },
      {
        id: globalPermission.customersManager,
        info: [
          'roles:globalPermissions.roles.customersManager.info-1',
          'roles:globalPermissions.roles.customersManager.info-2',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.customersManager.name',
      },
      {
        id: globalPermission.adminLabelKanbanBoard,
        info: ['roles:globalPermissions.roles.adminLabelKanbanBoard.info-1'],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.adminLabelKanbanBoard.name',
      },
      {
        id: globalPermission.priceCategoriesManager,
        info: [
          'roles:globalPermissions.roles.priceCategoriesManager.info-1',
          'roles:globalPermissions.roles.priceCategoriesManager.info-2',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.priceCategoriesManager.name',
      },
    ],
  },
  {
    id: 'customWorkingTimes',
    name: 'roles:globalPermissions.sections.customWorkingTimes',
    rows: [
      {
        id: globalPermission.customWorkingTimesManager,
        info: [
          'roles:globalPermissions.roles.customWorkingTimesManager.info-1',
          'roles:globalPermissions.roles.customWorkingTimesManager.info-2',
          'roles:globalPermissions.roles.customWorkingTimesManager.info-3',
          'roles:globalPermissions.roles.customWorkingTimesManager.info-4',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.customWorkingTimesManager.name',
      },
      {
        id: globalPermission.customWorkingTimesReaderVacation,
        info: [
          'roles:globalPermissions.roles.customWorkingTimesReaderVacation.info-1',
          'roles:globalPermissions.roles.customWorkingTimesReaderVacation.info-2',
          'roles:globalPermissions.roles.customWorkingTimesReaderVacation.info-3',
          'roles:globalPermissions.roles.customWorkingTimesReaderVacation.info-4',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.customWorkingTimesReaderVacation.name',
      },
      {
        id: globalPermission.customWorkingTimesReader,
        info: [
          'roles:globalPermissions.roles.customWorkingTimesReader.info-1',
          'roles:globalPermissions.roles.customWorkingTimesReader.info-2',
          'roles:globalPermissions.roles.customWorkingTimesReader.info-3',
          'roles:globalPermissions.roles.customWorkingTimesReader.info-4',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.customWorkingTimesReader.name',
      },
    ],
  },
  {
    id: 'projectTimeTracking',
    name: 'roles:globalPermissions.sections.projectTimeTracking',
    rows: [
      {
        id: globalPermission.otherUserTimeSheetReader,
        info: [
          'roles:globalPermissions.roles.otherUserTimeSheetReader.info-1',
          'roles:globalPermissions.roles.otherUserTimeSheetReader.info-2',
          'roles:globalPermissions.roles.otherUserTimeSheetReader.info-3',
          'roles:globalPermissions.roles.otherUserTimeSheetReader.info-4',
          'roles:globalPermissions.roles.otherUserTimeSheetReader.info-5',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.otherUserTimeSheetReader.name',
      },
      {
        id: 'TIME_RECORDING_LIST',
        info: ['roles:globalPermissions.roles.timeRecordingList.info-1'],
        inspectorType: SystemSettingsPermissionRowInspector.TIME_RECORDING_LIST,
        name: 'roles:globalPermissions.roles.timeRecordingList.name',
      },
    ],
  },
  {
    id: 'weeklyWorkingTimes',
    info: [
      'roles:globalPermissions.sections.weeklyWorkingTimes.info-1',
      'roles:globalPermissions.sections.weeklyWorkingTimes.info-2',
    ],
    name: 'roles:globalPermissions.sections.weeklyWorkingTimes',
    rows: [
      {
        id: globalPermission.weeklyWorkingTimesManager,
        info: [
          'roles:globalPermissions.roles.weeklyWorkingTimesManager.info-1',
          'roles:globalPermissions.roles.weeklyWorkingTimesManager.info-2',
          'roles:globalPermissions.roles.weeklyWorkingTimesManager.info-3',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.weeklyWorkingTimesManager.name',
      },
      {
        id: globalPermission.weeklyWorkingTimesReader,
        info: [
          'roles:globalPermissions.roles.weeklyWorkingTimesReader.info-1',
          'roles:globalPermissions.roles.weeklyWorkingTimesReader.info-2',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.weeklyWorkingTimesReader.name',
      },
    ],
  },
  {
    id: 'workingTimeTracking',
    info: [
      'roles:globalPermissions.sections.workingTimeTracking.info-1',
      'roles:globalPermissions.sections.workingTimeTracking.info-2',
    ],
    name: 'roles:globalPermissions.sections.workingTimeTracking',
    rows: [
      {
        id: globalPermission.workingTimeTrackingManager,
        info: [
          'roles:globalPermissions.roles.workingTimeTrackingManager.info-1',
          'roles:globalPermissions.roles.workingTimeTrackingManager.info-2',
          'roles:globalPermissions.roles.workingTimeTrackingManager.info-3',
          'roles:globalPermissions.roles.workingTimeTrackingManager.info-4',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.workingTimeTrackingManager.name',
      },
      {
        id: globalPermission.workingTimeTrackingReader,
        info: [
          'roles:globalPermissions.roles.workingTimeTrackingReader.info-1',
          'roles:globalPermissions.roles.workingTimeTrackingReader.info-2',
          'roles:globalPermissions.roles.workingTimeTrackingReader.info-3',
        ],
        inspectorType: SystemSettingsPermissionRowInspector.GLOBAL_ROLE,
        name: 'roles:globalPermissions.roles.workingTimeTrackingReader.name',
      },
    ],
  },
];

const rowsById = systemSettingsPermissionSections.reduce(
  (acc, {rows}) => rows.reduce((acc, row) => acc.set(row.id, row), acc),
  new Map<string, SystemSettingsPermissionRole>(),
);

export function getSystemSettingsRowById(id: string): SystemSettingsPermissionRole | null {
  return rowsById.get(id) || null;
}
