import {PatchableState} from '@octaved/hooks/src/PatchableState';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {userEntitiesSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {MoreHorizontal, Trash2} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Dropdown} from 'semantic-ui-react';
import {useGenerateDynamicLink} from '../../../../Components/DynamicLink';
import DropdownPopup from '../../../../Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '../../../../Components/Form/Dropdown/DropdownPopup/Types';
import Search from '../../../../Components/Form/Search/Search';
import Toggle from '../../../../Components/Form/Toggle/Toggle';
import {NodeEntity} from '../../../../EntityInterfaces/NodeEntity';
import {TimePeriod} from '../../../../Modules/Filter/TimePeriod';
import {isWpOrSwp} from '../../../../Node/NodeIdentifiers';
import {FlowState} from '../../../../Modules/State';
import {getRestoreTimeRecordsFromTrashRoute} from '../../../../Routing/Routes/Dialogs';
import PeriodFilter from '../../Components/PeriodFilter';
import CreateForOtherButton from './CreateForOtherButton';

export interface FilterObject {
  inPeriod: boolean;
  messageSearch: string;
  onlyOpenRecords: boolean;
  period: TimePeriod;
  userId: Uuid | null;
}

interface Props {
  filter: FilterObject;
  node: NodeEntity;
  patchFilter: PatchableState<FilterObject>[1];
  userIds: Uuid[];
}

export default function Filter({filter, node, patchFilter, userIds}: Props): ReactElement {
  const {t} = useTranslation();
  const users = useSelector(userEntitiesSelector);
  const userOptions = useMemo(
    () => userIds.map((value) => ({text: users[value]?.name || '', value})),
    [userIds, users],
  );

  const linkGenerator = useGenerateDynamicLink();
  const navigate = useNavigate();
  const canRestoreTrash = useSelector(
    (s: FlowState) =>
      isWpOrSwp(node) && isGrantedSelector(s)('FLOW_NODE_PROJECT_TIME_TRACKING_RESTORE_FROM_TRASH', node.id),
  );

  const moreOptions = useMemo(() => {
    const options: DropdownPopupItemOptions[] = [];
    if (canRestoreTrash) {
      options.push({
        icon: <Trash2 />,
        iconColor: 'darkGrey',
        onMouseDown: () => navigate(linkGenerator('dialog', getRestoreTimeRecordsFromTrashRoute(node.id))),
        token: 'pages:projects.inspector.header.trash',
        type: DropdownPopupItemType.item,
      });
    }
    return options;
  }, [canRestoreTrash, linkGenerator, navigate, node.id]);
  return (
    <div className={'flex min-h-12 items-center gap-x-4 px-3.5'}>
      <div>
        <Search
          debounce={300}
          search={filter.messageSearch}
          setSearch={(messageSearch) => patchFilter({messageSearch})}
          autoFocus
          canClearSearch={filter.messageSearch.length > 0}
          onClearSearch={() => patchFilter({messageSearch: ''})}
          placeholder={t('pages:projects.drawer.pidViewTimeTrackings.filterActivity') || undefined}
        />
      </div>

      <div className={'w-[200px]'}>
        <Dropdown
          disabled={userOptions.length === 0}
          className={'headerFilterDropdown'}
          clearable
          selection
          selectOnBlur={false}
          options={userOptions}
          value={filter.userId || undefined}
          onChange={(_, {value}) => patchFilter({userId: (value as Uuid) || null})}
          compact
          placeholder={t('pages:projects.drawer.pidViewTimeTrackings.filterUser') || undefined}
          fluid
        />
      </div>

      <Toggle
        checked={filter.onlyOpenRecords}
        setChecked={(onlyOpenRecords) => patchFilter({onlyOpenRecords})}
        label={'pages:projects.drawer.pidViewTimeTrackings.filterOpenTimeTrackings'}
      />
      <Toggle
        checked={filter.inPeriod}
        setChecked={(inPeriod) => patchFilter({inPeriod})}
        label={'pages:projects.drawer.pidViewTimeTrackings.filterPeriod'}
      />

      {filter.inPeriod && <PeriodFilter period={filter.period} setPeriod={(period) => patchFilter({period})} />}

      <DropdownPopup items={moreOptions} inDrawer closeOnSelect>
        <IconButton variant={'ghost'} icon={<MoreHorizontal />} />
      </DropdownPopup>

      <CreateForOtherButton node={node} />
    </div>
  );
}
