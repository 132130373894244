import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import NodeAncestorsBudgetSums from '../../../Components/Fields/Advanced/NodeAncestorsBudgetSums';
import WorkPackageFreezeTimeTrackingSection from '../../../Components/Fields/Advanced/NodeType/WorkPackage/Section/WorkPackageFreezeTimeTrackingSection';
import NodeBillingsSection from '../../../Components/Fields/Advanced/Section/NodeBillingsSection';
import NodeIdSection from '../../../Components/Fields/Advanced/Section/NodeIdSection';
import NodeKeyValueStoreSection from '../../../Components/Fields/Advanced/Section/NodeKeyValueStoreSection';
import NodePidSection from '../../../Components/Fields/Advanced/Section/NodePidSection';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import Section from '../../../Components/Fields/Section';
import InspectorViewFrame from '../../InspectorViewFrame';
import {WorkPackageDetailsProps} from './General';

export default function Advanced({node}: WorkPackageDetailsProps): ReactElement {
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const optionals = useOptionalFields(node.id, {
    technical: false,
  });
  const canReadPrices = useIsGranted('FLOW_NODE_READ_PRICES', node.id);
  const canReadKVStore = useIsGranted('FLOW_NODE_READ_KEY_VALUE_STORE', node.id);

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      <EnableFieldButton field={optionals.technical} label={'nodeDetails:field.synthetic.technical.enable'} />
    </div>
  );

  return (
    <InspectorViewFrame footer={canManageBasic ? footer : undefined}>
      {canReadPrices && <NodeBillingsSection node={node} />}
      <WorkPackageFreezeTimeTrackingSection node={node} />
      <NodePidSection pid={node.pid} />
      {canReadPrices && <NodeAncestorsBudgetSums frame={Section} nodeId={node.id} />}
      {optionals.technical.show && (
        <>
          <NodeIdSection nodeId={node.id} />
          {canReadKVStore && <NodeKeyValueStoreSection keyValueStore={node.keyValueStore} />}
        </>
      )}
    </InspectorViewFrame>
  );
}
