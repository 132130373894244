import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import Section from '../../Section';
import NodeSumFreeOfCharge from '../Common/NodeSumFreeOfCharge';

export interface NodeSumFreeOfChargeProps {
  workPackageIds: Uuid[];
}

export default function NodeSumFreeOfChargeSection(props: NodeSumFreeOfChargeProps): ReactElement | null {
  return <NodeSumFreeOfCharge frame={Section} {...props} />;
}
