import {createTextInputVariableRules, RulesList} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {NodeEntityPatchData} from '../../EntityInterfaces/NodeEntity';

export function getNodeNameValidationRules(
  id: Uuid,
  node: DeepPartial<NodeEntityPatchData>,
  isCreation: boolean,
  texts: {nameEmpty?: string; nameTooLong?: string} = {},
): RulesList {
  const rules: RulesList = [];
  if (isCreation || node.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        node.name!,
        texts.nameEmpty || 'general:node.nameEmptyError',
        texts.nameTooLong || 'general:node.nameTooLongError',
        `name_${id}`,
      ),
    );
  }
  return rules;
}
