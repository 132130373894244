import {Uuid} from '@octaved/typescript/src/lib';
import {NodeCellProps} from '../../../../Canvas/Gantt/Table/Cells/NodeCell';
import {SimpleTextCell} from '../../../../Canvas/Gantt/Table/Cells/SimpleTextCell';

export interface GanttComparisonPlanningTextCellProps extends NodeCellProps {
  baselineId: Uuid;
}

export abstract class GanttComparisonPlanningTextCell extends SimpleTextCell {
  protected readonly baselineId: Uuid;

  constructor(props: GanttComparisonPlanningTextCellProps) {
    super(props);
    this.baselineId = props.baselineId;
  }
}
