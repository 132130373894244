import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {noneRoles} from '@octaved/flow/src/Authorization/DefaultRoles';
import {RoleAssignmentOnNode} from '@octaved/flow/src/EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {useNodeRoleAssignments} from '@octaved/flow/src/Modules/Hooks/NodeRoleAssignments';
import {
  removeNodePermissionRoleAssignment,
  setNodePermissionRoleAssignment,
} from '@octaved/flow/src/Modules/RoleAssignments/NodePermissionRoleAssignments';
import {
  removeNodeProjectRoleAssignment,
  setNodeProjectRoleAssignment,
} from '@octaved/flow/src/Modules/RoleAssignments/NodeProjectRoleAssignments';
import {getDefaultRoleIdSelector, getSortedRolesSelector} from '@octaved/flow/src/Modules/Selectors/RoleSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {isClientValidationError} from '@octaved/network/src/NetworkError';
import {Uuid} from '@octaved/typescript/src/lib';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {generateUuid, pushOnArrayMap} from '@octaved/utilities';
import {useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export type SimpleRileAssignmentAddUnitCB = (
  unitId: Uuid,
  unitType: SimpleUnitType,
  unitName: string,
  roleId?: Uuid,
) => Promise<void>;

export function useSimpleRoleAssignments<RAN extends RoleAssignmentOnNode>(
  roleType: EnumFlowNodeRoleType,
  isGuestRole: boolean,
  nodeId: Uuid,
): {
  addUnit: SimpleRileAssignmentAddUnitCB;
  assignments: RAN[];
  changeRole: (assignment: RAN, roleId: Uuid) => Promise<void>;
  isLoading: boolean;
  isSaving: boolean;
  restoreInheritance: (assignment: RAN) => Promise<void>;
  showSelfLockoutError: boolean;
  takenRolesPerUnit: Map<Uuid, Uuid[]>;
} {
  const isProjectRole = roleType === EnumFlowNodeRoleType.VALUE_PROJECT;
  const dispatch = useDispatch();
  const sortedRoles = useSelector((s: FlowState) => getSortedRolesSelector(s)(roleType, isGuestRole));
  const defaultRoleId = useSelector((s: FlowState) => getDefaultRoleIdSelector(s)(roleType, isGuestRole));
  const {isLoaded, assignments} = useNodeRoleAssignments<RAN>(roleType, isGuestRole, nodeId);
  const [isSaving, setIsSaving] = useState(0);
  const assignmentsRef = useRef<RAN[]>(assignments);
  assignmentsRef.current = assignments;
  const [showSelfLockoutError, setShowSelfLockoutError] = useState(false);

  const withLockout = useCallback(
    async (action: ThunkAct<unknown>) => {
      try {
        await dispatch(action);
      } catch (e) {
        if (isClientValidationError(e, ['selfLockout'], [409])) {
          setShowSelfLockoutError(true);
          setTimeout(() => setShowSelfLockoutError(false), 5000);
        } else {
          throw e;
        }
      }
    },
    [dispatch],
  );

  const takenRolesPerUnit = useMemo(() => {
    const mapped = new Map<Uuid, Uuid[]>();
    assignments.forEach(({unitId, flowRoleId}) => {
      if (flowRoleId !== noneRoles[roleType]) {
        pushOnArrayMap(mapped, unitId, flowRoleId);
      }
    });
    return mapped;
  }, [assignments, roleType]);
  const takenRolesPerUnitRef = useRef(takenRolesPerUnit);
  takenRolesPerUnitRef.current = takenRolesPerUnit;

  const addUnit = useCallback(
    async (unitId: Uuid, unitType: SimpleUnitType, _unitName, roleId) => {
      setIsSaving((cur) => ++cur);
      let newRoleId: Uuid | undefined = roleId;

      if (!newRoleId) {
        if (isProjectRole) {
          //Project roles can technically be assigned multiple times, but the UI should prevent this for better UX
          const takenRoles = takenRolesPerUnitRef.current.get(unitId);
          if (defaultRoleId && (!takenRoles || !takenRoles.includes(defaultRoleId))) {
            newRoleId = defaultRoleId;
          } else {
            const availableRoles = sortedRoles.filter(
              ({id}) => id !== noneRoles[roleType] && (!takenRoles || !takenRoles.includes(id)),
            );
            newRoleId = availableRoles[0]?.id;
          }
        } else if (!assignmentsRef.current.find((assignment) => assignment.unitId === unitId)) {
          //type "permission" and unit not yet assigned
          newRoleId = defaultRoleId;
        }
      }

      if (newRoleId) {
        if (isProjectRole) {
          await withLockout(setNodeProjectRoleAssignment(nodeId, generateUuid(), unitType, unitId, newRoleId));
        } else {
          await withLockout(setNodePermissionRoleAssignment(isGuestRole, unitType, unitId, nodeId, newRoleId));
        }
      }
      setIsSaving((cur) => --cur);
    },
    [defaultRoleId, withLockout, isGuestRole, isProjectRole, nodeId, roleType, sortedRoles],
  );

  const changeRole = useCallback(
    async (asmt: RAN, roleId: Uuid) => {
      setIsSaving((cur) => ++cur);
      //If the assignment was added here and the role is set to "none", we remove the assignment instead, or, if
      // the same role is assigned as the parent has, we remove the assignment, too, switching back to inheritance:
      if (((asmt.isAdded || !asmt.parentRole) && roleId === noneRoles[roleType]) || asmt.parentRole === roleId) {
        if (isProjectRole) {
          await withLockout(removeNodeProjectRoleAssignment(nodeId, asmt.uniqueId, asmt.unitType, asmt.unitId));
        } else {
          await withLockout(removeNodePermissionRoleAssignment(isGuestRole, asmt.unitType, asmt.unitId, nodeId));
        }
      } else {
        if (isProjectRole) {
          await withLockout(setNodeProjectRoleAssignment(nodeId, asmt.uniqueId, asmt.unitType, asmt.unitId, roleId));
        } else {
          await withLockout(setNodePermissionRoleAssignment(isGuestRole, asmt.unitType, asmt.unitId, nodeId, roleId));
        }
      }
      setIsSaving((cur) => --cur);
    },
    [withLockout, isGuestRole, isProjectRole, nodeId, roleType],
  );

  const restoreInheritance = useCallback(
    async (asmt: RAN) => {
      setIsSaving((cur) => ++cur);
      //If the assignment was added here and the role is set to "none", we remove the assignment instead:
      if (asmt.isOverridden || asmt.isRemoved) {
        if (isProjectRole) {
          await withLockout(removeNodeProjectRoleAssignment(nodeId, asmt.uniqueId, asmt.unitType, asmt.unitId));
        } else {
          await withLockout(removeNodePermissionRoleAssignment(isGuestRole, asmt.unitType, asmt.unitId, nodeId));
        }
      }
      setIsSaving((cur) => --cur);
    },
    [withLockout, isGuestRole, isProjectRole, nodeId],
  );

  return {
    addUnit,
    assignments,
    changeRole,
    restoreInheritance,
    showSelfLockoutError,
    takenRolesPerUnit,
    isLoading: !isLoaded,
    isSaving: isSaving > 0,
  };
}
