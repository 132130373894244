import {error} from '@octaved/env/src/Logger';
import {
  createPriceSurcharge,
  patchPriceSurcharge as patchPriceSurchargeRoute,
  removePriceSurcharge as removePriceSurchargeRoute,
} from '@octaved/flow-api';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  optimisticAdd,
  optimisticDelete,
  optimisticUpdate,
  setToReducerMap,
} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {
  createTextInputVariableRules,
  RuleEntry,
  RulesList,
  validateDifferent,
  validateIntegerStringFormat,
  validateLength,
  validateTime,
  validateMinMax,
} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {parse} from '@octaved/users/src/Culture/NumberFormatter';
import {ObjectContains} from '@octaved/validation/src';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  PriceSurcharge,
  PriceSurchargePatchData,
  PriceSurcharges,
  PriceSurchargeTimeControl,
} from '../EntityInterfaces/PriceSurcharges';
import {
  FLOW_CREATE_PRICE_SURCHARGE_FAILURE,
  FLOW_CREATE_PRICE_SURCHARGE_REQUEST,
  FLOW_CREATE_PRICE_SURCHARGE_SUCCESS,
  FLOW_PATCH_PRICE_SURCHARGE_FAILURE,
  FLOW_PATCH_PRICE_SURCHARGE_REQUEST,
  FLOW_PATCH_PRICE_SURCHARGE_SUCCESS,
  FLOW_REMOVE_PRICE_SURCHARGE_FAILURE,
  FLOW_REMOVE_PRICE_SURCHARGE_REQUEST,
  FLOW_REMOVE_PRICE_SURCHARGE_SUCCESS,
} from './ActionTypes';
import {priceSurchargesEntitiesSelector} from './Selectors/PriceSurchargeSelectors';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

const reducerMap = new Map();
type Request = ServerRequestAction<{
  data: PriceSurcharge;
  urlParams: {priceSurchargeId: Uuid};
}>;
setToReducerMap<PriceSurcharges, Request>(
  optimisticAdd,
  reducerMap,
  FLOW_CREATE_PRICE_SURCHARGE_REQUEST,
  FLOW_CREATE_PRICE_SURCHARGE_FAILURE,
  FLOW_CREATE_PRICE_SURCHARGE_SUCCESS,
  ({options}) => options.data.id,
  (action) => ({...action.options.data, timeRecordUsage: 0}),
);
setToReducerMap<PriceSurcharges, Request>(
  optimisticUpdate,
  reducerMap,
  FLOW_PATCH_PRICE_SURCHARGE_REQUEST,
  FLOW_PATCH_PRICE_SURCHARGE_FAILURE,
  FLOW_PATCH_PRICE_SURCHARGE_SUCCESS,
  ({options}) => options.urlParams.priceSurchargeId,
);
setToReducerMap<PriceSurcharges, Request>(
  optimisticDelete,
  reducerMap,
  FLOW_REMOVE_PRICE_SURCHARGE_REQUEST,
  FLOW_REMOVE_PRICE_SURCHARGE_FAILURE,
  FLOW_REMOVE_PRICE_SURCHARGE_SUCCESS,
  ({options}) => options.urlParams.priceSurchargeId,
);

export const priceSurchargeDays: [keyof PriceSurchargeTimeControl['days'], string, string][] = [
  ['monday', 'generalCore:culture.shortWeekDays.monday', 'generalCore:culture.weekDays.monday'],
  ['tuesday', 'generalCore:culture.shortWeekDays.tuesday', 'generalCore:culture.weekDays.tuesday'],
  ['wednesday', 'generalCore:culture.shortWeekDays.wednesday', 'generalCore:culture.weekDays.wednesday'],
  ['thursday', 'generalCore:culture.shortWeekDays.thursday', 'generalCore:culture.weekDays.thursday'],
  ['friday', 'generalCore:culture.shortWeekDays.friday', 'generalCore:culture.weekDays.friday'],
  ['saturday', 'generalCore:culture.shortWeekDays.saturday', 'generalCore:culture.weekDays.saturday'],
  ['sunday', 'generalCore:culture.shortWeekDays.sunday', 'generalCore:culture.weekDays.sunday'],
];

export const priceSurchargeReducer = ReduceFromMap(reducerMap);

function getValidationRules(id: Uuid, data: DeepPartial<PriceSurchargePatchData>, isCreation: boolean): RulesList {
  const rules: RulesList = [];
  if (isCreation || data.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        data.name!,
        'systemSettings:priceSurcharge.error.nameEmpty',
        'systemSettings:priceSurcharge.error.nameTooLong',
        `name_${id}`,
      ),
    );
  }
  if (data.hasOwnProperty('surcharge')) {
    rules.push([validateIntegerStringFormat, data.surcharge, 'general:error.invalidNumberFormat', `surcharge_${id}`]);
    rules.push([validateMinMax, data.surcharge, 'general:error.invalidNumberFormat', `surcharge_${id}`, -100, 1000]);
  }
  if (data.timeControl?.times?.fullday === false) {
    rules.push([
      validateTime,
      data.timeControl.times.startTime,
      'systemSettings:priceSurcharge.error.invalidendStartTime',
      'systemSettings:priceSurcharge.error.emptyStartTime',
      `startTime_${id}`,
    ]);
    rules.push([
      validateTime,
      data.timeControl.times.endTime,
      'systemSettings:priceSurcharge.error.invalidEndTime',
      'systemSettings:priceSurcharge.error.emptyEndTime',
      `endTime_${id}`,
    ]);
    rules.push([
      validateDifferent,
      data.timeControl.times.startTime!,
      data.timeControl.times.endTime!,
      'systemSettings:priceSurcharge.error.sameStartAndEndTime',
      `endTime_${id}`,
      `startTime_${id}`,
    ] satisfies RuleEntry<typeof validateDifferent>);
  }
  if (data.apiKey) {
    rules.push([validateLength, data.apiKey, 'systemSettings:priceSurcharge.error.apiKeyTooLong', `apiKey_${id}`]);
  }
  return rules;
}

function patchDataToEntity(data: DeepPartial<PriceSurchargePatchData>): DeepPartial<PriceSurcharge> {
  const entity = {...data} as DeepPartial<PriceSurcharge>;
  if (typeof data.surcharge !== 'undefined') {
    const rate = data.surcharge.trim();
    entity.surcharge = parse(rate);
  }
  return entity;
}

export function useCreatePriceSurcharge(): (id: Uuid, data: DeepPartial<PriceSurchargePatchData>) => Promise<boolean> {
  const dispatch = useDispatch();
  return useCallback(
    async (id, data) => {
      if (!validateErrorRules(getValidationRules(id, data, true), dispatch)) {
        return false;
      }
      try {
        await dispatch({
          [CALL_API]: {
            endpoint: createPriceSurcharge,
            method: 'put',
            options: {
              data: {
                ...patchDataToEntity(data),
                id,
              },
            },
            throwNetworkError: true,
            types: {
              failureType: FLOW_CREATE_PRICE_SURCHARGE_FAILURE,
              requestType: FLOW_CREATE_PRICE_SURCHARGE_REQUEST,
              successType: FLOW_CREATE_PRICE_SURCHARGE_SUCCESS,
            },
          },
        });
        return true;
      } catch (e) {
        error(e);
        return false;
      }
    },
    [dispatch],
  );
}

export function patchPriceSurcharge(
  priceSurchargeId: Uuid,
  data: Partial<PriceSurchargePatchData>,
): ActionDispatcher<Promise<boolean | null>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(priceSurchargeId, data, false), dispatch)) {
      return false;
    }
    const patchData = patchDataToEntity(data);
    const priceSurcharge = priceSurchargesEntitiesSelector(getState())[priceSurchargeId];
    if (!priceSurcharge) {
      throw new Error('Missing priceSurcharge');
    }
    if (!ObjectContains(priceSurcharge, patchData)) {
      try {
        await dispatch({
          [CALL_API]: {
            endpoint: patchPriceSurchargeRoute,
            method: 'patch',
            options: {
              data: patchData,
              urlParams: {priceSurchargeId},
            },
            throwNetworkError: true,
            types: {
              failureType: FLOW_PATCH_PRICE_SURCHARGE_FAILURE,
              requestType: FLOW_PATCH_PRICE_SURCHARGE_REQUEST,
              successType: FLOW_PATCH_PRICE_SURCHARGE_SUCCESS,
            },
          },
        });
      } catch (e) {
        error(e);
        return false;
      }
    }
    return true;
  };
}

export function removePriceSurcharge(priceSurchargeId: Uuid): ActionDispatcher<Promise<undefined | null>, FlowState> {
  return (dispatch: Dispatch, _getState) => {
    return dispatch({
      [CALL_API]: {
        endpoint: removePriceSurchargeRoute,
        method: 'del',
        options: {
          urlParams: {priceSurchargeId},
        },
        types: {
          failureType: FLOW_REMOVE_PRICE_SURCHARGE_FAILURE,
          requestType: FLOW_REMOVE_PRICE_SURCHARGE_REQUEST,
          successType: FLOW_REMOVE_PRICE_SURCHARGE_SUCCESS,
        },
      },
    });
  };
}
