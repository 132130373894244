import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {hasPidHours} from '@octaved/flow/src/Modules/Pid';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getSubWorkPackageSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {useSubWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import {
  PlanningType,
  usePlanningType,
} from '@octaved/planning/src/Components/PlanningRealization/PlanningType/PlanningType';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {noop} from 'lodash';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import Placeholder from '../../../Components/Fields/PlaceholderText';
import NodeDependencySection from '../../../Components/Fields/Planning/Section/NodeDependencySection';
import NodePlanningOptionSection from '../../../Components/Fields/Planning/Section/NodePlanningOptionSection';
import NodePlanningStatusSection from '../../../Components/Fields/Planning/Section/NodePlanningStatusSection';
import NodeSuccessorsSection from '../../../Components/Fields/Planning/Section/NodeSuccessorsSection';
import NodeTimePeriodSection from '../../../Components/Fields/Planning/Section/NodeTimePeriodSection';
import ResponsibleUnits from '../../../Components/Fields/Responsible/ResponsibleUnits';
import Section from '../../../Components/Fields/Section';
import NodeBillingTypeSection from '../../../Components/Fields/TimeAndMoney/Section/NodeBillingTypeSection';
import SubWorkPackageEffortSection from '../../../Components/Fields/TimeAndMoney/Section/SubWorkPackage/Section/SubWorkPackageEffortSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {SubWorkPackageDetailsProps} from './General';

export default function SpecialPlanning({node: initialNode}: SubWorkPackageDetailsProps): ReactElement | null {
  const node = useSelector(getSubWorkPackageSelector)(initialNode.id) || initialNode;
  const readonly = !useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) || node.isArchived;
  const rootGroupType = useSelector(getRootGroupTypeSelector)(node.id);
  const isUserStory = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
  const isBacklog = rootGroupType === EnumFlowGroupType.VALUE_BACKLOG;
  const canPlan = !isUserStory && !isBacklog;
  const [planningType, setPlanningType] = usePlanningType(node);
  const patch = useSubWorkPackagePatch(node.id);
  const canManage = useIsGranted('FLOW_NODE_MANAGE_PRICES', node.id) && !node.isArchived;
  const workPackage: WorkPackage | null = useNodeAncestry(node.id, true).workPackage;
  if (!workPackage) {
    return null;
  }

  return (
    <InspectorViewFrame>
      <NodePlanningStatusSection node={node} />
      <NodePlanningOptionSection
        node={node}
        readonly={!canPlan || readonly}
        planningType={planningType}
        setPlanningType={setPlanningType}
      />

      {planningType === PlanningType.dependency && (
        <NodeDependencySection node={node} readonly={!canPlan || readonly} />
      )}
      {planningType !== PlanningType.none && <NodeTimePeriodSection node={node} readonly={!canPlan || readonly} />}
      <NodeSuccessorsSection node={node} />
      <ResponsibleUnits node={node} frame={Section} />

      {hasPidHours(workPackage) ? (
        <SubWorkPackageEffortSection workPackage={workPackage} node={node} patch={patch} readonly={!canManage} />
      ) : (
        <Section labelToken={'nodeDetails:field.effort.label'}>
          <Placeholder text={'nodeDetails:field.effort.noEstimation'} />
        </Section>
      )}
      <NodeBillingTypeSection node={workPackage} readonly patch={noop} />
    </InspectorViewFrame>
  );
}
