import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import CurrencySymbol from '@octaved/flow/src/Components/CurrencySymbol/CurrencySymbol';
import Duration from '@octaved/flow/src/Components/Duration/Duration';
import {TimeEffortPriceSumPerBillingType} from '@octaved/flow/src/EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {countDescendantOffersSelector, hasOffersSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {useTimeEffortPriceSumsPerBillingType} from '@octaved/flow/src/Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {getBillingTypeTranslatable} from '@octaved/flow/src/WorkPackage/BillingType';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWLabel} from '@octaved/ui';
import {format} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Placeholder from '../../PlaceholderText';
import Section from '../../Section';

interface NodeOfferSumsSectionProps {
  nodeId: Uuid;
}

export default function NodeOfferSumsSection({nodeId}: NodeOfferSumsSectionProps): ReactElement {
  const {t} = useTranslation();
  const hasOffers = useSelector(hasOffersSelector)(nodeId);
  const workPackagesWithOfferStatus = useSelector(countDescendantOffersSelector)(nodeId);
  const {nodeIds: workPackageIds} = useCombinedNodeSearch(
    nodeId ? {and: [withDescendants(nodeId, true), ['wpIsOffer']]} : null,
  );
  const {sums} = useTimeEffortPriceSumsPerBillingType(workPackageIds);

  const {min, max} = useMemo(() => {
    return Object.entries<TimeEffortPriceSumPerBillingType>(sums).reduce(
      (acc, [type, data]) => {
        if (type === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO) {
          acc.min += data.minEffortHours;
          acc.max += data.maxEffortHours;
        } else {
          acc.min += data.maxEffortHours;
          acc.max += data.maxEffortHours;
        }
        return acc;
      },
      {min: 0, max: 0},
    );
  }, [sums]);

  return (
    <>
      <Section labelToken={'nodeDetails:field.offer.label'}>
        <div>
          {!hasOffers && <Placeholder text={'pages:projects.offer.noOfferinSubset'} />}
          {hasOffers && (
            <div className={'grid max-w-full grid-cols-[1fr_max-content_max-content] gap-x-10 gap-y-0'}>
              <TWLabel className={'text-sm font-semibold text-slate-600'}>
                {t('pages:projects.offer.billingTypeCell')}
              </TWLabel>

              {(sums[EnumFlowPidBillingType.VALUE_FIXED_PRICE]?.maxEffortHours || 0) > 0 && (
                <TWLabel className={'text-right text-sm font-semibold text-slate-600'}>
                  {t('pages:projects.offer.price')}
                </TWLabel>
              )}
              {(sums[EnumFlowPidBillingType.VALUE_FIXED_PRICE]?.maxEffortHours || 0) === 0 && <div />}

              <TWLabel className={'text-right text-sm font-semibold text-slate-600'}>
                {t('pages:projects.offer.hours')}
              </TWLabel>

              <div className={'col-span-3 my-1 h-px bg-slate-200'} />

              {(sums[EnumFlowPidBillingType.VALUE_EFFORT]?.count || 0) > 0 && (
                <>
                  <span>{t(getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_EFFORT, true))}</span>
                  <div />
                  <div className={'text-right'}>
                    {sums[EnumFlowPidBillingType.VALUE_EFFORT]?.count || 0}&nbsp;
                    {t('pages:projects.inspector.offer.export.offerItems', {
                      count: sums[EnumFlowPidBillingType.VALUE_EFFORT]?.count || 0,
                    })}
                  </div>
                </>
              )}
              {(sums[EnumFlowPidBillingType.VALUE_EFFORT_EST]?.maxEffortHours || 0) > 0 && (
                <>
                  <div>{t(getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_EFFORT_EST, true))}</div>
                  <div />
                  <div className={'text-right'}>
                    <Duration
                      showAlwaysMinutes
                      from={0}
                      to={(sums[EnumFlowPidBillingType.VALUE_EFFORT_EST]?.maxEffortHours || 0) * 3600}
                    />
                  </div>
                </>
              )}
              {(sums[EnumFlowPidBillingType.VALUE_EFFORT_CAP]?.maxEffortHours || 0) > 0 && (
                <>
                  <div>{t(getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_EFFORT_CAP, true))}</div>
                  <div />
                  <div className={'text-right'}>
                    <Duration
                      showAlwaysMinutes
                      from={0}
                      to={(sums[EnumFlowPidBillingType.VALUE_EFFORT_CAP]?.maxEffortHours || 0) * 3600}
                    />
                  </div>
                </>
              )}
              {(sums[EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]?.minEffortHours || 0) > 0 && (
                <>
                  <div>{t(getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO, true))}</div>
                  <div />
                  <div className={'text-right'}>
                    <Duration
                      showAlwaysMinutes
                      from={0}
                      to={(sums[EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]?.minEffortHours || 0) * 3600}
                    />{' '}
                    -
                    <Duration
                      showAlwaysMinutes
                      from={0}
                      to={(sums[EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]?.maxEffortHours || 0) * 3600}
                    />
                  </div>
                </>
              )}
              {(sums[EnumFlowPidBillingType.VALUE_FIXED_PRICE]?.maxEffortHours || 0) > 0 && (
                <>
                  <div>{t('pages:projects.offer.fixedPrices')}</div>
                  <div>
                    {format(sums[EnumFlowPidBillingType.VALUE_FIXED_PRICE]?.fixedPrice || 0, 2)}&nbsp;
                    <CurrencySymbol />
                  </div>
                  <div className={'text-right'}>
                    <Duration
                      showAlwaysMinutes
                      from={0}
                      to={(sums[EnumFlowPidBillingType.VALUE_FIXED_PRICE]?.maxEffortHours || 0) * 3600}
                    />
                  </div>
                </>
              )}
              {(sums[EnumFlowPidBillingType.VALUE_CONTINGENT]?.maxEffortHours || 0) > 0 && (
                <>
                  <div>{t(getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_CONTINGENT, true))}</div>
                  <div />
                  <div className={'text-right'}>
                    <Duration
                      showAlwaysMinutes
                      from={0}
                      to={(sums[EnumFlowPidBillingType.VALUE_CONTINGENT]?.maxEffortHours || 0) * 3600}
                    />
                  </div>
                </>
              )}
              {min > 0 && (
                <>
                  <div className={'col-span-3 my-1 h-px bg-slate-200'} />
                  <div className={''}>
                    <span>{t('pages:projects.inspector.offer.export.totalHours')}</span>
                  </div>
                  <div className={''} />
                  <div className={'text-right font-semibold'}>
                    {min > 0 && min !== max && (
                      <>
                        <Duration from={0} to={min * 3600} showAlwaysMinutes /> -{' '}
                      </>
                    )}
                    {max > 0 && <Duration from={0} to={max * 3600} showAlwaysMinutes />}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Section>
      <div>
        {t('pages:projects.inspector.offer.workPackagesAsOffer', {
          count: workPackagesWithOfferStatus,
        })}
      </div>
    </>
  );
}
