import {dialogRouteObjects} from '@octaved/flow/src/Routing/Routes/Dialogs';
import {lazy} from 'react';
import {RouteObject} from 'react-router-dom';
import {overviewRoute, timeTrackingRoute} from '../RoutePaths';

const RedirectToOverviewWithLastPage = lazy(() => import('./RedirectToOverviewWithLastPage'));
const ResolveLargeViewControllingPage = lazy(() => import('./ResolveLargeViewControllingPage'));

export const largeViewControllingRoutes: RouteObject[] = [
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewControllingPage page={overviewRoute} />,
    path: `${overviewRoute}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <ResolveLargeViewControllingPage page={timeTrackingRoute} />,
    path: `${timeTrackingRoute}/*`,
  },
  {
    element: <RedirectToOverviewWithLastPage />,
    path: '*',
  },
];
