import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

interface EnumTranslationCellProps<T extends string> {
  value: T | null;
  translationMapping: Map<T, string> | Record<T, string>;
}

export default function EnumTranslationCell<T extends string>({
  value,
  translationMapping,
}: EnumTranslationCellProps<T>): ReactElement | null {
  if (!value) {
    return null;
  }
  const token = translationMapping instanceof Map ? translationMapping.get(value) : translationMapping[value];
  if (!token) {
    return null;
  }
  return (
    <span>
      <Trans i18nKey={token} />
    </span>
  );
}
