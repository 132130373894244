import {isGroup, isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {EntityStates} from '@octaved/store/src/EntityState';
import {DateStr} from '@octaved/typescript';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {Milestone, Milestones} from '../EntityInterfaces/Milestones';
import {PlanningState} from '../PlanningState';

export const milestoneEntityStatesSelector = (state: PlanningState): EntityStates => state.entityStates.milestone;
const milestoneEntitiesSelector = (state: PlanningState): Milestones => state.entities.milestone;

export const getMilestonesForNodeSelector = createSelector(milestoneEntitiesSelector, (milestoneEntities) =>
  memoize((nodeId: MaybeUuid): Milestone[] => {
    return milestoneEntities[nodeId!]?.sort((a, b) => a.milestoneDate.localeCompare(b.milestoneDate)) || [];
  }),
);
export const getMilestonesIdsForNodeSelector = createSelector(getMilestonesForNodeSelector, (getMilestonesForNode) =>
  memoize((nodeId: MaybeUuid): Uuid[] => {
    return getMilestonesForNode(nodeId).map(({id}) => id);
  }),
);

export const getMilestoneSelector = createSelector(getMilestonesForNodeSelector, (getMilestonesForNode) =>
  memoize(
    (nodeId: MaybeUuid, milestoneId: Uuid): Milestone | null => {
      const milestones = getMilestonesForNode(nodeId);
      return milestones.find(({id}) => id === milestoneId) || null;
    },
    (...args) => args.join('-'),
  ),
);

export interface MaturityDayData {
  milestones: Milestone[];
  dueDateNodeIds: Uuid[];
}

export interface GroupedMilestoneResult {
  date: DateStr;
  maturityData: MaturityDayData;
}

export const getGroupedMilestonesForNodeSelector = createSelector(
  getMilestonesForNodeSelector,
  getNodeSelector,
  (getMilestonesForNode, getNode) =>
    memoize((nodeId: MaybeUuid): GroupedMilestoneResult[] => {
      const milestones = getMilestonesForNode(nodeId);
      const result = new Map<DateStr, MaturityDayData>();

      function getMaturityDayData(date: DateStr): MaturityDayData {
        return result.get(date) || {dueDateNodeIds: [], milestones: []};
      }

      for (const milestone of milestones) {
        const maturityDayData = getMaturityDayData(milestone.milestoneDate);
        maturityDayData.milestones.push(milestone);
        result.set(milestone.milestoneDate, maturityDayData);
      }
      const node = getNode(nodeId);
      if ((isProject(node) || isGroup(node)) && node.dueDate) {
        const maturityDayData = getMaturityDayData(node.dueDate);
        maturityDayData.dueDateNodeIds.push(node.id);
        result.set(node.dueDate, maturityDayData);
      }
      return Array.from(result.entries()).map(([date, maturityData]) => ({date, maturityData}));
    }),
);
