import {createUseCombinedSearch, SearchResult, SearchResultWithLoading} from '@octaved/hooks/src/CombinedSearch';
import {Uuid} from '@octaved/typescript/src/lib';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {AnyCondition} from '@octaved/utilities/src/Condition/Types';
import dayjs from 'dayjs';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {TimeRecord} from '../../EntityInterfaces/TimeRecords';
import {todayIsoDateSelector} from '../../Today';
import {
  getTimeRecordSearchKey,
  TimeRecordSearchIdent,
  timeRecordSearchSelector,
  timeRecordSearchStateSelector,
  TimeRecordSearchTuple,
} from '../Selectors/TimeRecordSearchSelectors';
import {FlowState} from '../State';
import {searchTimeRecords} from '../TimeRecordSearch';
import {useLoadedTimeRecords} from './TimeRecords';

const useCombinedTimeRecordSearches = createUseCombinedSearch<Uuid, TimeRecordSearchTuple, FlowState>(
  getTimeRecordSearchKey,
  timeRecordSearchSelector,
  timeRecordSearchStateSelector,
  searchTimeRecords,
);

export function useCombinedTimeRecordSearch<TrackIsLoading extends boolean>(
  query: AnyCondition<TimeRecordSearchTuple> | null, //null to disable/inactivate the search
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? SearchResultWithLoading<Uuid> : SearchResult<Uuid> {
  return useCombinedTimeRecordSearches({trackIsLoading: trackIsLoading as true}, query)[0];
}

export function useTimeRecordSearch<TrackIsLoading extends boolean>(
  ident: TimeRecordSearchIdent,
  value?: string,
  active = true,
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? SearchResultWithLoading<Uuid> : SearchResult<Uuid> {
  const query = useMemo<TimeRecordSearchTuple | null>(
    () => (active ? ([ident, value] as TimeRecordSearchTuple) : null),
    [ident, value, active],
  );
  return useCombinedTimeRecordSearch(query, trackIsLoading);
}

function useEdgeTimeRecord(
  searchIsLoading: boolean,
  ids: ReadonlyArray<Uuid>,
  last: boolean,
): {
  isLoading: boolean;
  timeRecord: TimeRecord | null;
} {
  const oneIdArray = useMemo(() => (last ? ids.slice(-1) : ids.slice(0, 1)), [ids, last]);
  const {isLoading: recordLoading, timeRecords} = useLoadedTimeRecords(oneIdArray);
  return {
    isLoading: searchIsLoading || recordLoading,
    timeRecord: timeRecords[0] || null,
  };
}

export function useFirstTimeRecordOfWorkPackage(workPackageId: Uuid | null): {
  isLoading: boolean;
  timeRecord: TimeRecord | null;
} {
  const {isLoading, ids} = useTimeRecordSearch('workPackageId', workPackageId || '', !!workPackageId, true);
  return useEdgeTimeRecord(isLoading, ids, false);
}

export function useLastOpenTimeRecordOfCurrentUser(): {
  isLoading: boolean;
  timeRecord: TimeRecord | null;
} {
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const {isLoading, ids} = useCombinedTimeRecordSearch(
    useMemo(
      () => ({
        and: [['orgUserId', currentUserId], ['isOpen']],
      }),
      [currentUserId],
    ),
    true,
  );
  return useEdgeTimeRecord(isLoading, ids, true);
}

export function useCurrentUserOpenTimeRecords(): {
  fromToday: Uuid[];
  fromPrior: Uuid[];
  isLoading: boolean;
} {
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const {isLoading, ids} = useCombinedTimeRecordSearch(
    useMemo(
      () => ({
        and: [['orgUserId', currentUserId], ['isOpen']],
      }),
      [currentUserId],
    ),
    true,
  );
  const {timeRecords, isLoading: isLoadingTimeRecords} = useLoadedTimeRecords(ids);
  const todayIsoDate = useSelector(todayIsoDateSelector);

  const {fromToday, fromPrior} = useMemo(() => {
    return timeRecords.reduce(
      (acc, record) => {
        if (record.workTimeStart || record.workTimeEnd) {
          const ts: number = record.workTimeStart || record.workTimeEnd!;
          const recordStartOrEndDate = dayjs.unix(ts).utc();
          recordStartOrEndDate.format('HH:mm');
          const recordDate = toIsoFormat(recordStartOrEndDate);
          if (todayIsoDate === recordDate) {
            acc.fromToday.push(record.id);
          } else {
            acc.fromPrior.push(record.id);
          }
        }
        return acc;
      },
      {fromToday: [], fromPrior: []} as {fromToday: Uuid[]; fromPrior: Uuid[]},
    );
  }, [timeRecords, todayIsoDate]);

  return {
    fromPrior,
    fromToday,
    isLoading: isLoading || isLoadingTimeRecords,
  };
}

export function useAllCurrentUserOpenTimeRecords(): {
  timeRecords: TimeRecord[];
  isLoading: boolean;
} {
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const {isLoading, ids} = useCombinedTimeRecordSearch(
    useMemo(
      () => ({
        and: [['orgUserId', currentUserId], ['isOpen']],
      }),
      [currentUserId],
    ),
    true,
  );
  const {timeRecords, isLoading: isLoadingTimeRecords} = useLoadedTimeRecords(ids);
  return {
    timeRecords,
    isLoading: isLoading || isLoadingTimeRecords,
  };
}
