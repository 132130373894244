import {IconButton, Tooltip} from '@octaved/ui';
import {Eye} from 'lucide-react';
import {ReactElement} from 'react';
import {useWorkpackageOptions} from '../../../Hooks/WorkPackageOptions';
import DropdownPopup from '../../Form/Dropdown/DropdownPopup';
import {appendDropdownOptions} from '../../Form/Dropdown/DropdownPopup/AppendOptions';
import {DropdownPopupItemOptions} from '../../Form/Dropdown/DropdownPopup/Types';
import {columns} from '../../Task/Header/TableOptions';
import {useProjectTreeTaskListContext} from '../ProjectTreeTaskListContext';

export default function ProjectTreeDisplayOptions(): ReactElement {
  const {useTasksView} = useProjectTreeTaskListContext();
  const workPackageOptionItems = useWorkpackageOptions();

  const items: DropdownPopupItemOptions[] = [];
  appendDropdownOptions(items, workPackageOptionItems);
  if (useTasksView) {
    appendDropdownOptions(items, columns);
  }

  return (
    <DropdownPopup items={items} closeOnSelect position={'bottom right'}>
      <Tooltip toolTipTranslation={'tooltips:components.pageHeader.buttonDisplayOptions'} position={'bottom center'}>
        <IconButton variant={'ghost'} icon={<Eye />} size={'sm'} />
      </Tooltip>
    </DropdownPopup>
  );
}
