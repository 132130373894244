import {useScrollIntoView} from '@octaved/hooks/src/ScrollIntoView';
import {Uuid} from '@octaved/typescript/src/lib';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import classNames from 'classnames';
import {forwardRef, ReactElement, ReactNode, useContext} from 'react';
import NodeName from '../../Node/NodeName';
import {ProjectTreeGroup} from '../../../Modules/Projects/ProjectTreeInterfaces';
import OpenInspector from '../../Pid/OpenInspector';
import {useProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';
import ToggleSubPids from '../Components/ToggleSubPids';
import {projectsTreeComponentContext} from '../ProjectTreeComponentContext';
import {ContextMenu} from '@octaved/node-details/src/Components/ContextMenu/ContextMenu';
import {ContextMenuTrigger} from '@octaved/node-details/src/Components/ContextMenu/ContextMenuTrigger';

export interface GroupParams {
  node: ProjectTreeGroup;
  className?: string;
  children?: ReactNode;
  index: number;
  parentId: Uuid;
}

export default forwardRef<HTMLDivElement, GroupParams>(function Group(
  {node, className, children}: GroupParams,
  ref,
): ReactElement {
  const {groupsAndWorkPackages: GroupsAndWorkPackages} = useContext(projectsTreeComponentContext);
  const {
    canOpenPidInspector,
    compactView: compact,
    selectableNodeTypes,
    showPidInspectorMenu,
  } = useProjectTreeContext();
  const isSelectable = selectableNodeTypes.has(node.type);
  const isSelected = isSelectable && node.isSelected;
  const groupRef = useScrollIntoView<HTMLDivElement>(isSelected, 250);
  const {groupDetails: GroupDetails} = useContext(projectsTreeComponentContext);

  return (
    <div className={'groupWrapper'} ref={ref}>
      <div
        className={classNames('group', className, {
          compact,
          isSelectable,
          isHighlightSearch: node.isConcealedPropertySearchMatch,
          selected: isSelected,
        })}
        ref={groupRef}
      >
        <div
          className={classNames('rowGrid', {isSelectable, isExpanded: node.isExpanded})}
          onClick={(e) => {
            e.stopPropagation();
            if (isSelectable) {
              node.select();
            } else {
              node.toggleExpansion(e);
            }
          }}
        >
          <ToggleSubPids isExpanded={node.isExpanded} toggleExpansion={node.toggleExpansion} />

          <div className={'groupName'}>
            <span className={'name'}>
              <NodeName nodeId={node.id} />
            </span>
            {canOpenPidInspector && (
              <span className={'openPidInspector'}>
                <OpenInspector id={node.id} rectangle />
              </span>
            )}
            {showPidInspectorMenu && (
              <div className={'groupMenu'}>
                <ContextMenu nodeId={node.id}>
                  <ContextMenuTrigger />
                </ContextMenu>
              </div>
            )}
          </div>

          {node.entity?.color && node.entity.color !== '' ? <div className={'groupColor'} /> : <div />}

          <div className={'details'}>
            <GroupDetails node={node} />
          </div>
        </div>

        <SlideToggleElement visible={node.isExpanded}>
          <GroupsAndWorkPackages parentNode={node} />
        </SlideToggleElement>
      </div>
      {/**#region css */}
      {/*language=SCSS*/}
      <style jsx>{`
        .groupWrapper {
          width: 100%;
          padding-left: 5px;
        }

        .group {
          position: relative;
        }

        .rowGrid {
          position: relative;
          display: grid;
          padding: 5px 5px 5px 0;
          grid-template-columns: 21px 0.3fr 30px 0.7fr 28px;
          grid-column-gap: 8px;
          align-items: center;
          transition: padding-bottom 120ms ease-in-out;
          cursor: pointer;

          &.isSelectable:hover {
            background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.05));
          }

          .selected > & {
            background-color: var(--color-bg-selected, #edf5fb);
          }

          .compact & {
            grid-template-columns: 21px 1fr 30px;
            grid-column-gap: 8px;
          }
        }

        .isHighlightSearch {
          --groupBorderSearchColor: #e89902;
          --groupBorderSelectionColor: var(--groupBorderSearchColor);
        }

        .groupName {
          user-select: none;
          font-weight: 600;
          color: var(--color-font-group-name, #707070);
          margin-left: -6px;
          line-height: 21px;

          .compact & {
            font-size: 12px;
          }
        }

        .groupColor {
          height: 4px;
          width: 15px;
          border-radius: 2px;
          background-color: ${'#' + node.entity?.color};
        }

        .toggleWrapper {
          height: calc(100% + 22px);
          margin: -11px -8px -11px -15px;
          padding: 11px 8px 11px 15px;
          cursor: pointer;
        }

        .iconWrapper {
          display: flex;
          width: 19px;
          height: 19px;
          box-sizing: border-box;
        }

        .details {
          justify-self: flex-end;
          align-items: center;
          display: inline-flex;
        }

        .name {
          vertical-align: top;
        }

        .groupMenu {
          display: inline-flex;
        }

        .openPidInspector,
        .groupMenu {
          margin-left: 5px;
          visibility: hidden;
          pointer-events: none;
          vertical-align: -0.25em;
        }

        .rowGrid:hover .openPidInspector,
        .rowGrid:hover .groupMenu {
          visibility: visible;
          pointer-events: all;
        }
      `}</style>
      {/**#endregion */}
      {children}
    </div>
  );
});
