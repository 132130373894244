import {forwardRef, ReactElement, Ref} from 'react';
import {Stack, StackProps} from './Stack';

const HStack = forwardRef(function HStack(
  {direction = 'row', ...props}: StackProps,
  ref: Ref<HTMLDivElement>,
): ReactElement {
  return <Stack {...props} direction={direction} ref={ref} />;
});
HStack.displayName = 'HStack';
export {HStack};
