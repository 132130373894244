import {emptyNodeSearchResult} from '@octaved/node-search/src/Factories/Tree';
import {QuickControlCriticalTimePrognosis} from '../../../../EntityInterfaces/Filter/Custom/ControlCriticalTimePrognosis';
import {ProjectFilterQueries} from '../../../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition} from '../../../../EntityInterfaces/NodeSearch';
import {getTaskPrognosisQuery, prognosisLevels} from '../../../Hooks/Projects/ProjectControlling/TaskTimePrognosis';

export function controlCriticalTimePrognosisQueryGenerator(
  queries: ProjectFilterQueries,
  {levels}: QuickControlCriticalTimePrognosis,
): void {
  if (levels.length === 0) {
    queries.projects.push(emptyNodeSearchResult); //force no result
  } else if (levels.length < prognosisLevels.length) {
    const conditions: NodeSearchCondition = {
      or: levels.map<NodeSearchCondition>((level) => getTaskPrognosisQuery(level)),
    };
    queries.descendants.push(conditions);
    queries.workPackages.push(conditions);
  }
}
