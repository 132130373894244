import QuestionCircle from '@fortawesome/fontawesome-pro/svgs/light/question-circle.svg';
import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {HStack, IconButton, Stack, Tooltip} from '@octaved/ui';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import classNames from 'classnames';
import {ChevronRight} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Close from '../../../Dialogs/Components/Close';
import {hiddenForPagesSelector} from '../../../Modules/Selectors/Ui/HelpSelectors';
import {FlowState} from '../../../Modules/State';
import {setUiState} from '../../../Modules/Ui';
import {PageHelpIdents} from '../../../Modules/Ui/Help';

let currentHelpIdentifier: PageHelpIdents | null = null;

export function resetHelp(): ActionDispatcher<void, FlowState> {
  if (currentHelpIdentifier) {
    return setUiState({help: {hiddenForPages: {[currentHelpIdentifier]: false}}});
  }
  return () => false;
}

export interface PageContentHelpProps {
  footerRows?: ReactElement;
  helpId: PageHelpIdents;
  infoToken: ComplexTranslatable;
}

export default function PageContentHelp({footerRows, helpId, infoToken}: PageContentHelpProps): ReactElement | null {
  const [collapsed, setCollapsed] = useState(true);
  const {t} = useTranslation();
  currentHelpIdentifier = helpId;
  const hiddenPages = useSelector(hiddenForPagesSelector);
  const dispatch = useDispatch();

  if (currentHelpIdentifier && !hiddenPages[currentHelpIdentifier]) {
    return (
      <div className={classNames('helpBox', {collapsed})} id={'pageHelp'}>
        <Tooltip toolTipTranslation={'pageHelp:closeXTooltip'} position={'bottom right'}>
          <div className={'close'}>
            <Close close={() => dispatch(setUiState({help: {hiddenForPages: {[currentHelpIdentifier!]: true}}}))} />
          </div>
        </Tooltip>

        <SlideToggleElement visible={collapsed}>
          <HStack spacing={'8px'} alignItems={'center'}>
            <IconButton
              icon={<ChevronRight />}
              onClick={() => setCollapsed(!collapsed)}
              variant={'ghost'}
              size={'sm'}
            />
            <div className={'preview'}>
              {t('pageHelp:header')} - <ComplexTrans translatable={infoToken} />
            </div>
          </HStack>
        </SlideToggleElement>

        <SlideToggleElement visible={!collapsed}>
          <HStack width={'100%'} spacing={'25px'}>
            <div className={'icon'}>
              <QuestionCircle />
            </div>
            <div className={'helpDetails'}>
              <Stack spacing={'21px'}>
                <div>
                  <div className={'header'}>
                    <span>{t('pageHelp:header')}</span>
                  </div>

                  <div className={'description'}>
                    <span>
                      <ComplexTrans translatable={infoToken} />
                    </span>
                  </div>
                </div>

                {footerRows && <HStack spacing={'20px'}>{footerRows}</HStack>}
              </Stack>
            </div>
          </HStack>
        </SlideToggleElement>

        {/*#region styles*/}
        {/*language=scss*/}
        <style jsx>{`
          .helpBox {
            background: #edf2f7;
            position: relative;
            padding: 15px 25px;
            transition: padding 0.24s ease-in-out;
          }

          .collapsed {
            padding: 10px 45px 10px 25px;
          }

          .helpBox :global(strong) {
            color: #444;
            font-weight: bold;
          }

          .close {
            position: absolute;
            top: 2px;
            right: 2px;
          }

          .header {
            font-size: 16px;
            color: #333;
            font-weight: 500;
            color: #2c5282;
            margin-bottom: 14px;
          }

          .icon {
            height: 70px;
            width: 70px;
            fill: #fff;
            flex-shrink: 0;
          }

          .preview {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        `}</style>
        {/*#endregion*/}
      </div>
    );
  }

  return null;
}

PageContentHelp.displayName = 'PageContentHelp';
