import {BoardPostType} from '@octaved/env/src/dbalEnumTypes';
import {RulesList} from '@octaved/store/src/Validation';
import {Uuid} from '@octaved/typescript/src/lib';
import {LucideIcon} from 'lucide-react';
import * as React from 'react';
import {DropdownPopupItemOptions} from '../../Components/Form/Dropdown/DropdownPopup/Types';
import {BaseBoardPost} from '../../EntityInterfaces/BoardPost';

export interface BoardPostTypeConfig<BP extends BaseBoardPost> {
  defaultDataFactory: () => BP['data'];
  getDataValidationRules?: (id: Uuid, data: Partial<BP['data']>, isCreation: boolean) => RulesList;
  displayName: string;
  icon: LucideIcon;
  component: React.ComponentType;
  hasPersonalVersions?: boolean;
  hasVersions?: boolean;
  useMenuOptions?: (defaultItems: DropdownPopupItemOptions[]) => DropdownPopupItemOptions[];
}

type Configs = Map<BoardPostType, BoardPostTypeConfig<BaseBoardPost>>;

const configs: Configs = new Map();

export function registerBoardPostType<BP extends BaseBoardPost>(
  type: BP['boardPostType'],
  config: BoardPostTypeConfig<BP>,
): void {
  configs.set(type, config as BoardPostTypeConfig<BaseBoardPost>);
}

export function getBoardPostTypeConfig(type: BoardPostType): BoardPostTypeConfig<BaseBoardPost> {
  if (!configs.has(type)) {
    throw new Error(`Missing configuration for board post type '${type}'`);
  }
  return configs.get(type)!;
}

export function getAllBoardPostTypeConfigs(): Configs {
  return configs;
}
