import {addEntityReducers, reducer, removeEntityReducers} from '@octaved/flow/src/Modules/RootReducer';
import {FlowState} from '@octaved/flow/src/Modules/State';
import CreateStore, {EnhancedStore} from '@octaved/store/src/CreateStore';
import {Dispatch, onSelectorChange} from '@octaved/store/src/Store';
import {debounce} from 'lodash';
import {createContext, PropsWithChildren, ReactElement, useContext, useEffect, useMemo, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {thunk} from 'redux-thunk';
import planningMiddleware from '../../Calculations/PlanningMiddleware';
import {patchSimulationState} from '../../Modules/ReinitializeSimulation';
import {
  projectPlanningSelectedProjectsSelector,
  selectedSimulationSnapshotIdSelector,
  simulationModeActiveSelector,
} from '../../Selectors/UiSelectors';
import replaySimulationkMiddleware from './ReplaySimulationMiddleware';
import simulatedMiddleware from './SimulatedMiddleware';
import simulatedNetworkMiddleware from './SimulatedNetworkMiddleware';

interface ISimulationContext {
  simulatedStore: EnhancedStore<FlowState>;
  isSimulating: boolean;
  baseDispatch: Dispatch;
  instanceId?: number; //used for debugging
}

let instanceId = 0;
const simulationContext = createContext<ISimulationContext | null>(null);

export function useSimulationContext(): ISimulationContext {
  const ctx = useContext(simulationContext);
  if (!ctx) {
    throw new Error('useSimulationContext must be used within a SimulationContextProvider');
  }
  return ctx;
}

export function SimulationContextProvider({children}: PropsWithChildren<unknown>): ReactElement {
  const defaultStore = useStore<FlowState>() as EnhancedStore<FlowState>;
  const selectedSimulationSnapshotId = useSelector(selectedSimulationSnapshotIdSelector);
  const simulationModeActive = useSelector(simulationModeActiveSelector);

  const value = useMemo<ISimulationContext>(() => {
    ++instanceId;
    const baseDispatch: Dispatch = defaultStore.dispatch;
    if (!simulationModeActive || !selectedSimulationSnapshotId) {
      return {simulatedStore: defaultStore, isSimulating: false, baseDispatch, instanceId};
    }
    const inititialState = {...defaultStore.getState()};
    const simulatedStore = CreateStore(
      [
        thunk,
        replaySimulationkMiddleware(defaultStore),
        simulatedNetworkMiddleware(),
        simulatedMiddleware(defaultStore),
        planningMiddleware,
      ],
      reducer,
      addEntityReducers,
      removeEntityReducers,
      inititialState,
      'SimulatedPlanningStore',
    );

    return {
      baseDispatch,
      instanceId,
      simulatedStore,
      isSimulating: true,
    };
  }, [defaultStore, selectedSimulationSnapshotId, simulationModeActive]);

  const contextRef = useRef(value);
  contextRef.current = value;

  useEffect(() => {
    const onChange = debounce((): void => {
      if (contextRef.current.isSimulating) {
        contextRef.current.simulatedStore.dispatch(patchSimulationState(defaultStore.getState()));
      }
    }, 200);
    const subs = [onSelectorChange(projectPlanningSelectedProjectsSelector, false, onChange)];
    return () => {
      onChange.cancel();
      subs.forEach((sub) => sub());
    };
  }, [defaultStore]);

  return <simulationContext.Provider value={value}>{children}</simulationContext.Provider>;
}
