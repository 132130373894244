import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {Tooltip} from '@octaved/ui';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import {PlanningRealizationNode} from '../../../../EntityInterfaces/PlanningRealizationNode';
import {PatchPlanningDependency} from '../../../../Modules/Planning';
import {updateDependency} from '../../../../Modules/PlanningDependencies';
import {dependencyNames} from '../../../DependencyNames';

interface DependencyTypeSeletionProps {
  node: PlanningRealizationNode;
  readonly?: boolean;
  dependency: PatchPlanningDependency;
}

export default function DependencyTypeSeletion({
  node,
  readonly,
  dependency,
}: DependencyTypeSeletionProps): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const options = useMemo<DropdownItemProps[]>(() => {
    return [...dependencyNames.entries()].map(([value, {long}]) => ({
      value,
      text: t(long),
    }));
  }, [t]);

  if (readonly) {
    return (
      <div className={'cell'}>
        <Tooltip toolTipTranslation={dependencyNames.get(dependency.type)!.long}>
          <div>{t(dependencyNames.get(dependency.type)!.short)}</div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={'cell'}>
      <Dropdown
        direction={'left'}
        fluid
        selection
        text={t(dependencyNames.get(dependency.type)!.short) || undefined}
        options={options}
        value={dependency.type}
        onChange={(_, {value}) => {
          if (value) {
            dispatch(
              updateDependency(node.id, dependency.predecessor, {
                type: value as EnumFlowPlanningDependencyType,
              }),
            );
          }
        }}
      />

      <style jsx>{`
        .cell {
          padding: 1px 0;
        }

        .cell :global(.ui.selection.dropdown),
        .cell :global(.ui.selection.dropdown > .dropdown.icon) {
          min-height: 1.5em;
          padding-top: 6px;
          padding-bottom: 4px;
          padding-right: 25px;
        }

        .cell :global(.ui.selection.dropdown > .dropdown.icon) {
          padding-right: 5px;
        }

        .cell :global(.left.visible.menu) {
          width: 140px;
          border-top-width: 1px !important;
        }

        .cell :global(.upward .left.visible.menu) {
          border-bottom-width: 1px !important;
          max-height: 140px;
        }
      `}</style>
      {/*#region styles*/}
    </div>
  );
}
