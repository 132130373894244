import {AuditFlowCustomer} from '../../../../EntityInterfaces/Audit/AuditFlowCustomer';
import {tokensForBillableCustomer} from '../../../../WorkPackage/BillingType';
import BooleanCell from '../Table/Cells/BooleanCell';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditFlowCustomer> = {
  columns: [
    ...historyColumns,
    createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
    createColumnDef('name', 'systemSettings:audit.tables.nameHeader'),
    createColumnDef('customerNumber', 'systemSettings:audit.tables.flowCustomer.customerNumberHeader'),
    createColumnDef('isInternal', 'systemSettings:audit.tables.flowCustomer.isInternalHeader', BooleanCell),
    createColumnDef(
      'requiresInternalCharge',
      'systemSettings:audit.tables.flowCustomer.requiresInternalChargeHeader',
      BooleanCell,
    ),
    createColumnDef('isLocked', 'systemSettings:audit.tables.flowCustomer.isLockedHeader', BooleanCell),
    createColumnDef('logoFileId', 'systemSettings:audit.tables.flowCustomer.logoFileHeader'),
    createColumnDef(
      'defaultBillingType',
      'systemSettings:audit.tables.flowCustomer.defaultBillingTypeHeader',
      ({cell: {value}}) => {
        return <EnumTranslationCell value={value} translationMapping={tokensForBillableCustomer} />;
      },
    ),
    createColumnDef('fileExportLanguage', 'systemSettings:audit.tables.flowCustomer.fileExportLanguageHeader'),
  ],
  tableIdent: 'flowCustomers',
  tableNameTranslationToken: 'systemSettings:audit.flowCustomersTableName',
};
export default config;
