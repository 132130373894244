import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {FlowState} from '../../State';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['trackTime']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.trackTime);
}

export const trackTimeDescriptionDisplaySelector = ui((state) => state.descriptionDisplay);
export const trackTimeExpandedTeamUserIdsSelector = ui((state) => state.expandedTeamUserIds);
export const trackTimeLeftDrawerSelectedSelector = ui((state) => state.leftDrawer.selected);
export const trackTimeLeftDrawerUiStateSelector = ui((state) => state.leftDrawer);
export const trackTimeSelectedViewSelector = ui((state) => state.selectedView);
export const trackTimeShowBillableInfoSelector = ui((state) => state.showBillableInfo);
export const createTimeTrackingDraggingSelector = ui((state) => state.createTimeTrackingDragging);
export const trackTimeShowBillableTimesSelector = ui((state) => state.showBillableTimes);
export const trackTimeShowCustomerColumnSelector = ui((state) => state.table.showCustomerColumn);
export const trackTimeTeamUserIdsSelector = ui((state) => state.teamUserIds);
export const trackTimeTeamUnitSelector = ui<ReadonlyArray<SlimUnit<SimpleUnitType.user>>>((state) =>
  state.teamUserIds.map((unitId) => ({unitId, unitType: SimpleUnitType.user})),
);
