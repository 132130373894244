import {isEqual} from 'lodash';
import {PropsWithChildren, ReactElement, createContext, useContext, useMemo, useState} from 'react';

interface FilterContext<T extends object> {
  state: T;
  patchState: (patch: Partial<T>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ctxt = createContext<FilterContext<any> | null>(null);

interface FilterContextProviderProps<T extends object> {
  defaultState: T;
}

export function FilterContextProvider<T extends object>({
  children,
  defaultState,
}: PropsWithChildren<FilterContextProviderProps<T>>): ReactElement {
  const [state, setState] = useState<T>(defaultState);

  return (
    <ctxt.Provider
      value={useMemo(
        () => ({
          state,
          patchState: (patch: Partial<T>) =>
            setState((s) => {
              const newState = {...s, ...patch};
              if (!isEqual(newState, s)) {
                return newState;
              }
              return s;
            }),
        }),
        [state],
      )}
    >
      {children}
    </ctxt.Provider>
  );
}

export function useFilterContext<T extends object>(): FilterContext<T> {
  const context = useContext(ctxt);

  if (!context) {
    throw new Error('FilterContextProvider is missing');
  }

  return context;
}
