import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {PlanningStatusIconWithText} from '@octaved/planning/src/PlanningStatus/PlanningStatusIcon';
import {useNodePlanningStatus} from '@octaved/planning/src/PlanningStatus/PlanningStatusQueries';
import {ReactElement} from 'react';
import Section from '../../Section';
import {PlanningDate} from '../Values/NodePlanningValue';

interface NodePlanningSectionProps {
  node: WorkPackage | SubWorkPackage | Task;
}

export default function NodePlanningSection({node}: NodePlanningSectionProps): ReactElement | null {
  const status = useNodePlanningStatus(node.id);
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(node.id);

  return (
    <Section labelToken={'nodeDetails:field.planningStatus.label'}>
      <PlanningStatusIconWithText status={status.overall} />
      {plannedStart && (
        <PlanningDate
          plannedStart={plannedStart}
          plannedEnd={plannedEnd}
          isOverdue={status.overall === 'overdue'}
          nodeId={node.id}
          showDays
        />
      )}
    </Section>
  );
}
