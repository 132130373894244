import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {ComponentType} from 'react';
import {TimeEffortPriceSumPerBillingType} from '../../EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';
import BilledFixedPrice from './BillingTypeViews/BilledFixedPrice';
import BilledFromTo from './BillingTypeViews/BilledFromTo';
import BilledMax from './BillingTypeViews/BilledMax';
import BilledOnly from './BillingTypeViews/BilledOnly';

export interface BillingTypeComponentProps {
  billingType: EnumFlowPidBillingType;
  noIndicator?: boolean;
  showBillingType?: boolean;
  showProgressLabel?: boolean;
  sum: TimeEffortPriceSumPerBillingType;
  typeTranslationToken: string;
}

export const billingTypeComponents: Record<EnumFlowPidBillingType, ComponentType<BillingTypeComponentProps>> = {
  [EnumFlowPidBillingType.VALUE_CONTINGENT]: BilledMax,
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: BilledFixedPrice,
  [EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO]: BilledFromTo,
  [EnumFlowPidBillingType.VALUE_EFFORT_CAP]: BilledMax,
  [EnumFlowPidBillingType.VALUE_EFFORT_EST]: BilledMax,
  [EnumFlowPidBillingType.VALUE_EFFORT]: BilledOnly,
  [EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE]: BilledOnly,
};
