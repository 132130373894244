import {AuditPriceSurcharge} from '../../../../EntityInterfaces/Audit/AuditPriceSurcharge';
import BooleanCell from '../Table/Cells/BooleanCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditPriceSurcharge> = {
  columns: [
    ...historyColumns,
    createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
    createColumnDef('name', 'systemSettings:audit.tables.nameHeader'),
    createColumnDef('surcharge', 'systemSettings:audit.tables.priceSurcharge.surchargeHeader'),
    createColumnDef('isActive', 'systemSettings:audit.tables.priceSurcharge.isActiveHeader', BooleanCell),
    createColumnDef('apiKey', 'systemSettings:audit.tables.priceSurcharge.apiKeyHeader'),
  ],
  tableIdent: 'priceSurcharges',
  tableNameTranslationToken: 'systemSettings:audit.priceSurchargeTableName',
};
export default config;
