import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {TaskSection} from '../../EntityInterfaces/TaskSection';
import {isTaskSection} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector} from './NodeSelectors';

export const getTaskSectionSelector = createSelector(nodeEntitySelector, (nodes) =>
  memoize((id: Uuid): TaskSection | undefined => {
    const node = nodes[id];
    if (node && !isTaskSection(node)) {
      throw new Error(`taskSection type is requested but '${node?.nodeType}' type is returned`);
    }
    return node;
  }),
);
