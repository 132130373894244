import {Notification, NotificationRemoveTypes} from '@octaved/store/src/Notifications';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {withValidation} from '@octaved/validation/src/ValidationField';
import {ReactElement, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Input} from 'semantic-ui-react';
import {InputProps} from 'semantic-ui-react/dist/commonjs/elements/Input/Input';

interface Props extends Omit<InputProps, 'onChange'> {
  fieldName: string;
  setValue: (v: string) => void;
  inputType?: string;

  removeError?(fieldName: NotificationRemoveTypes): ActionDispatcher<void>;

  getErrorSelector?(state: unknown): Notification[];
}

const InputField = withValidation(Input);

export default function ValidationInputField({
  fieldName,
  setValue,
  inputType = 'text',
  removeError,
  getErrorSelector = () => [],
  input = <input type={inputType} />,
  value,
  ...props
}: Props): ReactElement {
  const dispatch = useDispatch();
  const errors = useSelector(getErrorSelector);

  const onChange = (newValue: string): void => {
    if (removeError) {
      dispatch(removeError(fieldName));
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (removeError) {
      dispatch(removeError(fieldName));
    }
    void value; //remove errors when value changes
  }, [dispatch, fieldName, value, removeError]);

  //Clear error upon disposing:
  useEffect(
    () => () => {
      if (removeError) {
        dispatch(removeError(fieldName));
      }
    },
    [dispatch, fieldName, removeError],
  );

  return (
    <>
      <InputField
        error
        fluid
        errors={errors}
        fieldName={fieldName}
        input={input}
        onChange={(_e, {value: fieldValue}) => onChange(fieldValue)}
        value={value}
        {...props}
      />
    </>
  );
}
