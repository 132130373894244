import {useEffect} from 'react';
import {RouteMatch} from 'react-router-dom';
import {useExtendedLocation} from './ExtendedLocation';

const visitedUrls = new Map<string, string>();

function getBasePathName(match: RouteMatch): string {
  if (match.pathnameBase.endsWith('/')) {
    return match.pathnameBase.slice(0, -1);
  }
  return match.pathnameBase;
}

export function useUrlWatcher(): void {
  const location = useExtendedLocation();

  useEffect(() => {
    const lastMatch = location.matches[location.matches.length - 1];
    if (lastMatch) {
      visitedUrls.set(getBasePathName(lastMatch), lastMatch.pathname);
    }
  }, [location.matches]);
}

export function useGetLastVisitedUrl(defaultUrl: string): string {
  const location = useExtendedLocation();
  const lastMatch = location.matches[location.matches.length - 1];
  return visitedUrls.get(getBasePathName(lastMatch)) || defaultUrl;
}
