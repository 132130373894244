import {Nodes, NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {PlanningEventPlanningData} from '@octaved/flow/src/Modules/Events';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getAllDescendantIdsSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {DateTimeStr} from '@octaved/typescript';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {boolFilter} from '@octaved/utilities';
import {isEqual} from 'lodash';
import {Milestone, Milestones} from '../../EntityInterfaces/Milestones';
import {PlanningDate, PlanningDates, PlanningDatesList} from '../../EntityInterfaces/PlanningDates';
import {PlanningDependencies, PlanningDependency} from '../../EntityInterfaces/PlanningDependency';
import {
  getPlanningDateSelector,
  MinMaxPlanningDatesResult,
  sortPlanningDates,
} from '../../Selectors/PlanningDateSelectors';
import {
  generatePlanningDependencyId,
  getPlanningDependencySelector,
  TYPE_FF,
  TYPE_FS,
} from '../../Selectors/PlanningDependencySelectors';
import {addWorkdays, ensureWorkDay, workdaysBetween} from '../WorkdayCalculations';

export interface UpdatePlanningResult {
  planningDates: PlanningDates | null;
  nodes: Nodes | null;
  milestones: Milestones | null;
  planningDependencies: PlanningDependencies | null;
  removedPlanningDates: Uuid[];
  removedPlanningDependencies: string[];
  updatedNodePlanningDates: Record<string, PlanningEventPlanningData[] | null> | null;
}

export interface QueueItem {
  id: Uuid;
  type: 'start' | 'end';
}

export default class UpdatePlanningState {
  readonly #state: FlowState;
  readonly #planningDates: PlanningDates = {};
  readonly #nodes: Nodes = {};
  readonly #milestones: Milestones = {};
  readonly #planningDependencies: PlanningDependencies = {};
  readonly #removedPlanningDependencies: string[] = [];
  readonly #removedPlanningDates: Uuid[] = [];
  readonly #queue: QueueItem[] = [];
  readonly #handledNodes: Map<Uuid, number> = new Map();

  readonly #updatedNodePlanningDates: Record<string, PlanningEventPlanningData[] | null> = {};
  readonly #forceDependencyRecalculation: boolean;

  constructor(state: FlowState, forceDependencyRecalculation = false) {
    this.#state = state;
    this.#forceDependencyRecalculation = forceDependencyRecalculation;
  }

  getResult(): UpdatePlanningResult {
    return {
      milestones: getResultObject(this.#milestones),
      nodes: getResultObject(this.#nodes),
      planningDates: getResultObject(this.#planningDates),
      planningDependencies: getResultObject(this.#planningDependencies),
      removedPlanningDates: this.#removedPlanningDates,
      removedPlanningDependencies: this.#removedPlanningDependencies,
      updatedNodePlanningDates: getResultObject(this.#updatedNodePlanningDates),
    };
  }

  #addToQueue(item: QueueItem): void {
    const entry = this.#queue.find(({id, type}) => id === item.id && type === item.type);
    if (!entry) {
      this.#queue.push(item);
    }
  }

  setMilestones(nodeId: Uuid, milestones: Milestone[]): void {
    this.#milestones[nodeId] = milestones;
  }

  #updateUpdatedPlanningDates(nodeId: Uuid): void {
    const node = this.getNode(nodeId);
    if (isWorkPackage(node) || isTask(node) || isSubWorkPackage(node)) {
      const list = this.#updatedNodePlanningDates;
      const planningDates = boolFilter(node.planningDates.map((id) => this.getPlanningDate(id)));
      if (planningDates.length > 0) {
        list[nodeId] = planningDates.map(({id, plannedEnd, plannedStart}) => ({
          id,
          plannedEnd,
          plannedStart,
        }));
      } else {
        list[nodeId] = null;
      }
    }
  }

  planningDatesUpdated(nodeId: Uuid): void {
    if (this.#forceDependencyRecalculation) {
      this.#addToQueue({id: nodeId, type: 'start'});
      this.#addToQueue({id: nodeId, type: 'end'});
    }
  }

  setPlanningDate(planningDate: PlanningDate): void {
    const id = planningDate.id;
    const prevPlanningDate = this.getPlanningDate(id);

    if (prevPlanningDate?.plannedStart !== planningDate.plannedStart) {
      this.#addToQueue({id: planningDate.nodeId, type: 'start'});
    }
    if (prevPlanningDate?.plannedEnd !== planningDate.plannedEnd) {
      this.#addToQueue({id: planningDate.nodeId, type: 'end'});
    }
    this.#planningDates[id] = planningDate;

    this.#updateUpdatedPlanningDates(planningDate.nodeId);
  }

  getPlanningDate(id: Uuid): PlanningDate | null {
    return this.#planningDates[id!] || getPlanningDateSelector(this.#state)(id);
  }

  removePlanningDate(id: Uuid): void {
    const planningDate = this.getPlanningDate(id);
    this.#removedPlanningDates.push(id);
    if (planningDate) {
      this.#addToQueue({id: planningDate.nodeId, type: 'start'});
      this.#addToQueue({id: planningDate.nodeId, type: 'end'});
      this.#updateUpdatedPlanningDates(planningDate.nodeId);
    }
  }

  setNode(node: NodeType): void {
    const oldNode = this.getNode(node.id);

    this.#nodes[node.id] = node;

    if (oldNode && !isEqual(node.planningDates, oldNode.planningDates)) {
      this.#updateUpdatedPlanningDates(node.id);

      if (node.planningDates.length === 0) {
        this.#updatePlanningSuccessorsOnRemovedDates(node.id);
      }
    }
  }

  #updatePlanningSuccessorsOnRemovedDates(nodeId: Uuid): void {
    const node = this.getNode(nodeId);
    if (node && node.planningSuccessors.length > 0) {
      for (const successorId of node.planningSuccessors) {
        const successor = this.getNode(successorId);
        if (successor?.planningPredecessors.includes(node.id)) {
          const planningPredecessors = successor.planningPredecessors.filter((id) => id !== node.id);
          this.setNode({
            ...successor,
            planningPredecessors,
          });
          this.removedPlanningDependency(node.id, successorId);
        }
      }
      this.#nodes[node.id] = {
        ...node,
        planningSuccessors: [],
      };
    }
  }

  getNode(id: MaybeUuid): NodeType | undefined {
    return this.#nodes[id!] || (getNodeSelector(this.#state)(id) as NodeType);
  }

  setPlanningDependency(dependency: PlanningDependency): void {
    const id = generatePlanningDependencyId(dependency.successor, dependency.predecessor);
    this.#planningDependencies[id] = dependency;
    if (dependency.type === TYPE_FF || dependency.type === TYPE_FS) {
      this.#addToQueue({id: dependency.predecessor, type: 'end'});
    } else {
      this.#addToQueue({id: dependency.predecessor, type: 'start'});
    }
  }

  getPlanningDependency(successorId: Uuid, predecessorId: Uuid): PlanningDependency | undefined {
    const id = generatePlanningDependencyId(successorId, predecessorId);
    return this.#planningDependencies[id!] || getPlanningDependencySelector(this.#state)(successorId, predecessorId);
  }

  removedPlanningDependency(successorId: Uuid, predecessorId: Uuid): void {
    const id = generatePlanningDependencyId(successorId, predecessorId);
    this.#removedPlanningDependencies.push(id);
  }

  isNodeHandled(id: Uuid): boolean {
    //prevent endless loops
    return this.#handledNodes.has(id) && this.#handledNodes.get(id)! > 10;
  }

  setHandledNode(id: Uuid): void {
    if (!this.#handledNodes.has(id)) {
      this.#handledNodes.set(id, 0);
    } else {
      this.#handledNodes.set(id, this.#handledNodes.get(id)! + 1);
    }
  }

  getNextQueueItem(): QueueItem | undefined {
    return this.#queue.shift();
  }

  addWorkDays(date: DateTimeStr, offset: number): DateTimeStr {
    const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.#state);
    return toIsoDateTimeFormat(addWorkdays(fromIsoDateTimeFormat(date), offset, getWorkMinutesAtDate));
  }

  ensureWorkDay(date: DateTimeStr, forward: boolean): DateTimeStr {
    const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.#state);
    return toIsoDateTimeFormat(ensureWorkDay(fromIsoDateTimeFormat(date), getWorkMinutesAtDate, forward));
  }

  workdaysBetween(start: DateTimeStr, end: DateTimeStr): number {
    const getWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(this.#state);
    return workdaysBetween(fromIsoDateTimeFormat(start), fromIsoDateTimeFormat(end), getWorkMinutesAtDate);
  }

  getAllDescendantIds(nodeId: Uuid): Set<Uuid> {
    return getAllDescendantIdsSelector(this.#state)(nodeId);
  }

  #getSortedPlanningDates(nodeId: Uuid): PlanningDatesList {
    const node = this.getNode(nodeId);
    if (node) {
      const planningDates = boolFilter(node.planningDates.map((id) => this.getPlanningDate(id)));
      return sortPlanningDates(planningDates);
    }
    return [];
  }

  getMinMaxPlanningDates(nodeId: Uuid): MinMaxPlanningDatesResult {
    const planningDates = this.#getSortedPlanningDates(nodeId);
    if (planningDates.length > 0) {
      return {
        plannedEnd: planningDates[planningDates.length - 1].plannedEnd,
        plannedStart: planningDates[0].plannedStart,
      };
    }
    return {plannedEnd: null, plannedStart: null};
  }
}

function getResultObject<T extends Record<string, unknown>>(obj: T): T | null {
  return Object.keys(obj).length > 0 ? obj : null;
}
