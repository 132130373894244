import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {Group} from '@octaved/flow/src/EntityInterfaces/Pid';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import TimePeriod from '@octaved/planning/src/Components/PlanningRealization/TimePeriod/TimePeriod';
import {HelpTooltip} from '@octaved/ui';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';

export interface GroupSprintProps {
  node: Group;
  readonly: boolean;
}

export default function GroupSprint({
  frame: Frame,
  node,
  readonly,
}: GroupSprintProps & FramedSectionOrTileProps): ReactElement | null {
  const groupType = useSelector(getRootGroupTypeSelector)(node.id);
  const isAgileBacklog = groupType === EnumFlowGroupType.VALUE_SPRINT;
  if (!isAgileBacklog) {
    return null;
  }

  return (
    <Frame
      labelToken={'pages:projects.inspector.manage.planning.planningSprint'}
      labelAddition={<HelpTooltip toolTipTranslation={'tooltips:drawer.groupPlanning.helpIconPlanSprint'} />}
    >
      <TimePeriod node={node} readonly={readonly} />
    </Frame>
  );
}
