export default function normalizeShortName(name: string): string {
  const parts = name.split(' ');
  if (parts.length === 1) {
    return name.replace(/\W/g, '').substring(0, 2).toUpperCase();
  }

  return parts
    .reduce<string>((acc, partOfName) => (acc.length < 2 ? acc + partOfName.replace(/\W/g, '').substr(0, 1) : acc), '')
    .toUpperCase();
}
