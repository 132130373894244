import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {Group} from '../../EntityInterfaces/Pid';
import {isGroup, isProject} from '../../Node/NodeIdentifiers';
import {getNodeSelector} from './NodeSelectors';
import {getAllDescendantIdsSelector, getDepthSelector, getNodeAncestrySelector} from './NodeTreeSelectors';
import {getProjectForPidSelector} from './PidSelectors';

export const groupMaxDepth = 2;

/**
 * Gets the group depth relative to its project
 * Project > Group: 1
 * Project > Group > Group: 2
 *
 * Returns 0 for non-groups.
 */
export const getGroupDepthSelector = createSelector(
  getNodeSelector,
  getProjectForPidSelector,
  getDepthSelector,
  (getNode, getProjectForPid, getDepth) => (nodeId?: Uuid) => {
    const node = getNode(nodeId);
    return isGroup(node) ? getDepth(node.id) - getDepth(getProjectForPid(node.id)?.id) : 0;
  },
);

export const canCreateGroupSelector = createSelector(
  getNodeSelector,
  isGrantedSelector,
  (getNode, isGranted) => (parentNodeId: Uuid | null | undefined) => {
    const node = parentNodeId && getNode(parentNodeId);
    return (isProject(node) || isGroup(node)) && !node.isArchived && isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id);
  },
);

/**
 * Gets the nesting level of groups starting with [nodeId]:
 * Project > [Group]: 1
 * Project > [Group] > Group: 2
 * Project > Group > [Group]: 1
 * Project > Group > [Group] > Group: 2
 * Project > [Group] > Group > Group: 3
 *
 * Returns 0 if nodeId is not a group.
 */
export const getGroupNestingLevelSelector = createSelector(
  getNodeSelector,
  getDepthSelector,
  getAllDescendantIdsSelector,
  (getNode, getDepth, getAllDescendantIds) => (nodeId: Uuid | null | undefined) => {
    const node = getNode(nodeId || null);
    if (!isGroup(node)) {
      return 0;
    }
    const nodeDepth = getDepth(node.id) - 1;
    return [...getAllDescendantIds(node.id, true)].reduce((acc, descendantId) => {
      const descendant = getNode(descendantId);
      if (isGroup(descendant)) {
        return Math.max(acc, getDepth(descendantId) - nodeDepth);
      }
      return acc;
    }, 0);
  },
);

export const getRootGroupSelector = createSelector(
  getNodeAncestrySelector,
  (getNodeAncestry) =>
    (id: Uuid | null): Group | null => {
      const {groups} = getNodeAncestry(id, true);
      return groups[groups.length - 1] || null;
    },
);

export const getRootGroupTypeSelector = createSelector(getRootGroupSelector, (getRootGroup) =>
  memoize((id: Uuid | null): EnumFlowGroupType => {
    const rootGroup = getRootGroup(id);
    if (!rootGroup) {
      return EnumFlowGroupType.VALUE_GROUP;
    }
    return rootGroup.groupType;
  }),
);
