import {ReactElement} from 'react';
import {DialogContent, DialogFrame, DialogTitle} from './DialogFrame';
import DialogAndDrawerHeader from '../Components/Layout/DialogAndDrawerHeader';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import SimpleNote from '../Components/Task/TaskListForNode/Task/TaskCell/NameCell/Description/SimpleNote';
import {TaskPatchData} from '../EntityInterfaces/Task';

interface EditNoteDialogProps {
  description: string;
  patch: (data: Partial<TaskPatchData>) => void;
  taskName: string;
  onClose: () => void;
}

function EditNoteDialog(props: EditNoteDialogProps): ReactElement {
  const {description, patch, taskName, onClose} = props;

  return (
    <DialogFrame size={'large'} noOwnForm>
      <DialogTitle onClose={onClose}>
        <DialogAndDrawerHeader
          headerComponent={
            <div className={'flex items-center gap-x-2'}>
              {t('taskList:note.note')}
              <Trans i18nKey={'taskList:note.noteHeaderForTask'} values={{TASKNAME: taskName}} />
            </div>
          }
          noPadding
        />
      </DialogTitle>

      <DialogContent>
        <SimpleNote description={description} patch={patch} onFinish={onClose} />
      </DialogContent>
    </DialogFrame>
  );
}

export default EditNoteDialog;
