import {CALL_API, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {LOADED, LOADING, createTimestampReducer, filterIdsToReload} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {getPlanningBaselineHistory as getPlanningBaselineHistoryRoute} from '../../config/routes';
import {PlanningBaselineHistories} from '../EntityInterfaces/PlanningBaselineHistory';
import {PlanningState} from '../PlanningState';
import {planningBaselineHistoryEntityStatesSelector} from '../Selectors/PlanningBaselineHistorySelectors';
import {
  FLOW_GET_PLANNING_BASELINE_HISTORY_FAILURE,
  FLOW_GET_PLANNING_BASELINE_HISTORY_REQUEST,
  FLOW_GET_PLANNING_BASELINE_HISTORY_SUCCESS,
} from './ActionTypes';

type GetPlanningBaselineHistoryRequest = ServerResponseAction<PlanningBaselineHistories>;

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(
  FLOW_GET_PLANNING_BASELINE_HISTORY_REQUEST,
  createTimestampReducer('options.urlParams.ids', LOADING),
);
stateReducerMap.set(
  FLOW_GET_PLANNING_BASELINE_HISTORY_SUCCESS,
  createTimestampReducer('options.urlParams.ids', LOADED),
);
export const planningBaselineHistoryEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion

//#region Reducer

const planningBaselineHistoryEntityReducers = createReducerCollection<PlanningBaselineHistories>({});
planningBaselineHistoryEntityReducers.add(
  FLOW_GET_PLANNING_BASELINE_HISTORY_SUCCESS,
  (state, action: GetPlanningBaselineHistoryRequest) => {
    return {...state, ...action.response};
  },
);
export const planningBaselineHistoryReducer = planningBaselineHistoryEntityReducers.reducer;

//#endregion

export function getPlanningBaselineHistory(baselineIds: Uuid[]): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const ids = filterIdsToReload(planningBaselineHistoryEntityStatesSelector(getState()), baselineIds);

    if (ids.length === 0) {
      return;
    }

    dispatch({
      [CALL_API]: {
        endpoint: getPlanningBaselineHistoryRoute,
        method: 'get',
        options: {
          urlParams: {ids},
        },
        types: {
          failureType: FLOW_GET_PLANNING_BASELINE_HISTORY_FAILURE,
          requestType: FLOW_GET_PLANNING_BASELINE_HISTORY_REQUEST,
          successType: FLOW_GET_PLANNING_BASELINE_HISTORY_SUCCESS,
        },
      },
    });
  };
}
