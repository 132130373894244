import {NodeTimeControlMode} from '@octaved/env/src/dbalEnumTypes';
import {castFilter} from '@octaved/utilities';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Group, Project, WorkPackage} from '../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../EntityInterfaces/SubWorkPackage';
import {TimeControlledEntity} from '../../EntityInterfaces/TimeControlledEntity';
import {CopyPidOptions} from '../../Modules/CopyPid';
import {
  isEqualTimeControl,
  isTimeControlActive,
  isTimeControlInThePast,
  shiftTimeControl,
} from '../../Modules/TimeControlledEntity';
import {isTimeControlledNode} from '../../Node/NodeIdentifiers';

interface NamedTimeControlledEntity extends TimeControlledEntity {
  name: string;
}

function hasEquivalentSibling(timeControl: NamedTimeControlledEntity, siblings: NamedTimeControlledEntity[]): boolean {
  return siblings.some((sibling) => {
    return isEqualTimeControl(sibling, timeControl) && sibling.name === timeControl.name;
  });
}

function createNextTimeControlled<N extends TimeControlledEntity>(
  autoTimeControl: boolean,
  node: N,
  siblings: NamedTimeControlledEntity[],
  name: string,
): NamedTimeControlledEntity {
  let entity: NamedTimeControlledEntity = {name, timeControl: node.timeControl ? {...node.timeControl} : null};
  if (autoTimeControl && entity.timeControl?.mode !== NodeTimeControlMode.custom) {
    while (isTimeControlInThePast(entity) || hasEquivalentSibling(entity, siblings)) {
      entity = shiftTimeControl(entity);
    }
  }
  return entity;
}

export function useAutoTimeControl<N extends Project | Group | WorkPackage | SubWorkPackage>(
  node: N,
  siblings: N[],
  copyPidOptions: CopyPidOptions,
): {
  autoTimeControl: boolean;
  autoTimeControlIsPossible: boolean;
  name: string;
  pidTimeControl: TimeControlledEntity | null;
  setAutoTimeControl: (autoTimeControl: boolean) => void;
  setName: (name: string) => void;
} {
  const {t} = useTranslation();

  const [autoTimeControl, setAutoTimeControl] = useState(true);

  const isActive = isTimeControlledNode(node) && isTimeControlActive(node);
  const isAutoUpdatable = isActive && node.timeControl?.mode !== NodeTimeControlMode.custom;

  const genName = (autoTimeControl = true): string =>
    isAutoUpdatable && autoTimeControl ? node.name : `${node.name} - ${t('dialogs:duplicatePid.copySuffix')}`;

  const [name, setName] = useState(genName);

  const value = isActive
    ? createNextTimeControlled(autoTimeControl, node, castFilter(siblings, isTimeControlledNode), name)
    : null;
  const wasAutoUpdated = isActive && !!value && !isEqualTimeControl(node, value);

  if (wasAutoUpdated && value?.timeControl?.from) {
    copyPidOptions.timeControlFromOverrides = {[node.id]: value.timeControl.from};
  }

  return {
    autoTimeControl,
    name,
    setName,
    autoTimeControlIsPossible: isAutoUpdatable,
    pidTimeControl: value,
    setAutoTimeControl: (autoTimeControl) => {
      setAutoTimeControl(autoTimeControl);
      setName(genName(autoTimeControl));
    },
  };
}
