import {useSingleSubtreeStat} from '@octaved/flow/src/Modules/Statistics/SubtreeStats';
import {Uuid} from '@octaved/typescript/src/lib';
import {HelpPopup} from '@octaved/ui';
import {Users2} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface NodeGuestAccessSectionProps {
  nodeId: Uuid;
}

export default function NodeGuestAccessSection({nodeId}: NodeGuestAccessSectionProps): ReactElement | null {
  const {t} = useTranslation();
  const stat = useSingleSubtreeStat('hasGuestAccess', nodeId);
  const hasGuestAccess = stat?.customer.direct || stat?.other.direct;

  if (!hasGuestAccess) {
    return null;
  }

  return (
    <div className={'flex items-center gap-x-2'}>
      <Users2 className={'h-4 w-4 text-orange-600'} />
      <div className={'text-orange-600'}>{t('nodeDetails:field.guestAccess.active')}</div>
      <HelpPopup text={t('nodeDetails:field.guestAccess.info')} iconButtonSize={'sm'} />
    </div>
  );
}
