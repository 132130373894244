import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {labelEntitiesSelector} from '@octaved/flow/src/Modules/Selectors/LabelSelectors';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getContrastColor} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import {boolFilter} from '@octaved/utilities';
import {Group} from 'konva/lib/Group';
import {Rect} from 'konva/lib/shapes/Rect';
import {createText, defaultLineHeight} from '../../../CommonComponents/Text';
import {Tooltip} from '../../../CommonComponents/Tooltip';
import {createStoreSubscription} from '../../../StoreSubscription';
import {NodeCell} from './NodeCell';

export class LabelCell extends NodeCell {
  protected nodeEntity: NodeEntity | null = null;

  protected readonly labelHeight = defaultLineHeight;
  #disposeableTooltip: Array<() => void> = [];

  init(): void {
    this.root.y((this.ctx.rowHeight - this.labelHeight) * 0.5);
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => labelEntitiesSelector(store),
        () => {
          this.render();
        },
      ),
      () => this.#disposeTooltip(),
    );
  }

  #disposeTooltip(): void {
    this.#disposeableTooltip.forEach((dispose) => dispose());
    this.#disposeableTooltip = [];
  }

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;
    this.root.destroyChildren();
    this.#disposeTooltip();
    if (!isVisble || !this.nodeEntity || this.nodeEntity.labels.length === 0) {
      return;
    }

    this.root.x(x);

    const labelEntities = labelEntitiesSelector(this.ctx.store.getState());
    const assignedLabels = boolFilter(this.nodeEntity.labels.map((labelId) => labelEntities[labelId])).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    const maxLabels = Math.floor(width / (this.labelHeight + this.padding));
    const visibleLabels = Math.min(maxLabels, assignedLabels.length);
    const labelWidth = Math.floor((width - visibleLabels * this.padding) / visibleLabels);

    const labels = assignedLabels.slice(0, visibleLabels).map(({name, color}, i) => {
      const x = i * (labelWidth + this.padding);
      if (name.includes('::')) {
        return this.#createMultiLabel(name, color, labelWidth, x);
      }
      return this.#createLabel(name, color, labelWidth, x);
    });
    this.root.add(...labels);
  }

  #createLabel(name: string, color: string, maxWidth: number, x: number): Group {
    const label = new Group({x, y: 0});
    const radius = Math.round(this.labelHeight * 0.5);
    const text = createText({
      ellipsis: true,
      fill: getContrastColor(color),
      text: name,
      x: radius,
      y: 0,
    });
    const textWidth = Math.floor(text.getTextWidth() + this.labelHeight);

    if (textWidth > maxWidth) {
      text.width(maxWidth - this.labelHeight);
    }
    label.add(
      new Rect({
        cornerRadius: radius,
        fill: `#${color}`,
        height: this.labelHeight,
        width: Math.min(textWidth, maxWidth),
        x: 0,
        y: 0,
      }),
      text,
    );

    const tooltip = new Tooltip({root: label, text: name, labelProps: {x: 0, y: -defaultLineHeight * 1.5}});
    this.#disposeableTooltip.push(() => tooltip.dispose());
    return label;
  }

  #createMultiLabel(name: string, color: string, maxWidth: number, x: number): Group {
    const label = new Group({x, y: 0});
    const radius = Math.round(this.labelHeight * 0.5);

    const labelName = name.substring(0, name.indexOf('::'));
    const multiLabelValue = name.substring(name.indexOf('::') + 2);

    const labelNameText = createText({
      ellipsis: true,
      fill: getContrastColor(color),
      text: labelName,
      x: radius,
      y: 0,
    });
    const multiLabelValueText = createText({
      ellipsis: true,
      fill: `#${color}`,
      text: multiLabelValue,
      x: radius,
      y: 0,
    });

    const labelNameTextWidth = Math.floor(labelNameText.getTextWidth());
    const multiLabelValueTextWidth = Math.floor(multiLabelValueText.getTextWidth());
    const fullTextWidth = Math.floor(labelNameTextWidth + multiLabelValueTextWidth + this.labelHeight + this.padding);

    if (fullTextWidth > maxWidth) {
      const p = maxWidth / fullTextWidth;
      labelNameText.width(Math.round(labelNameTextWidth * p));
      multiLabelValueText.width(Math.round(multiLabelValueTextWidth * p));
    }

    const labelNameEnd = labelNameText.x() + labelNameText.width();
    multiLabelValueText.x(labelNameEnd + this.padding);

    const rectWidth = Math.min(fullTextWidth, maxWidth);
    const coloredBackgroundWidth = labelNameEnd + this.padding * 0.5;
    const height = this.labelHeight - 2; // remove the stroke
    label.add(
      //colored background
      new Rect({
        height,
        cornerRadius: [radius, 0, 0, radius],
        fill: `#${color}`,
        width: coloredBackgroundWidth,
        x: 0,
        y: 0,
      }),
      //white background
      new Rect({
        height,
        cornerRadius: [0, radius, radius, 0],
        fill: '#fff',
        width: rectWidth - coloredBackgroundWidth,
        x: labelNameEnd + this.padding * 0.5,
        y: 0,
      }),
      //border
      new Rect({
        height,
        cornerRadius: radius,
        stroke: `#${color}`,
        strokeWidth: 1,
        width: rectWidth,
        x: 0,
        y: 0,
      }),
      labelNameText,
      multiLabelValueText,
    );
    const tooltip = new Tooltip({root: label, text: name, labelProps: {x: 0, y: -defaultLineHeight * 1.5}});
    this.#disposeableTooltip.push(() => tooltip.dispose());
    return label;
  }
}
