import {FlowState} from '@octaved/flow/src/Modules/State';
import {licenseSelector} from '@octaved/license/src/Selectors/LicenseSelectors';
import {post} from '@octaved/network/src/Network';
import {CALL_API, UrlParams} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import objectContains from '@octaved/validation/src/ObjectContains';
import {AccessToken, AccessTokenPatch, AccessTokens} from '../Interfaces/AccessTokens';
import {accessTokensSelector, accessTokensStateSelector} from '../Selectors/AccessTokensSelectors';

const LOAD_ACCESS_TOKENS_REQUESTS = 'LOAD_ACCESS_TOKENS_REQUESTS';
const LOAD_ACCESS_TOKENS_FAILURE = 'LOAD_ACCESS_TOKENS_FAILURE';
const LOAD_ACCESS_TOKENS_SUCCESS = 'LOAD_ACCESS_TOKENS_SUCCESS';
const PATCH_ACCESS_TOKEN_REQUESTS = 'PATCH_ACCESS_TOKEN_REQUESTS';
const PATCH_ACCESS_TOKEN_FAILURE = 'PATCH_ACCESS_TOKEN_FAILURE';
const PATCH_ACCESS_TOKEN_SUCCESS = 'PATCH_ACCESS_TOKEN_SUCCESS';
const DELETE_ACCESS_TOKEN_REQUESTS = 'DELETE_ACCESS_TOKEN_REQUESTS';
const DELETE_ACCESS_TOKEN_FAILURE = 'DELETE_ACCESS_TOKEN_FAILURE';
const DELETE_ACCESS_TOKEN_SUCCESS = 'DELETE_ACCESS_TOKEN_SUCCESS';

export interface CreatedAccessToken {
  id: Uuid;
  token: string;
}

interface LoadAction {
  storeId: Uuid;
  response: AccessToken[];
  type: typeof LOAD_ACCESS_TOKENS_SUCCESS;
}

interface PatchAction {
  storeId: Uuid;
  tokenId: Uuid;
  patch: AccessTokenPatch;
  type: typeof PATCH_ACCESS_TOKEN_REQUESTS | typeof PATCH_ACCESS_TOKEN_FAILURE | typeof PATCH_ACCESS_TOKEN_SUCCESS;
}

interface DeleteAction {
  storeId: Uuid;
  tokenId: Uuid;
  type: typeof DELETE_ACCESS_TOKEN_REQUESTS | typeof DELETE_ACCESS_TOKEN_FAILURE | typeof DELETE_ACCESS_TOKEN_SUCCESS;
}

export interface UseAccessTokens {
  accessTokens: ReadonlyArray<AccessToken>;
  createAccessToken: (validUntil: DateStr) => Promise<CreatedAccessToken>;
  deleteAccessToken: (tokenId: Uuid) => void;
  hasLoadedOnce: boolean;
  organizationIdForHeader: number | null; //only needed for personal use if multi-org-identity
  patchAccessToken: (tokenId: Uuid, patch: AccessTokenPatch) => void;
}

const reducers = createReducerCollection<AccessTokens>({});
reducers.add<LoadAction>(LOAD_ACCESS_TOKENS_SUCCESS, (state, action) => ({
  ...state,
  [action.storeId]: action.response,
}));
reducers.add<PatchAction>(PATCH_ACCESS_TOKEN_REQUESTS, (state, {storeId, tokenId, patch}) => {
  const newState = {...state};
  const tokens = newState[storeId] ?? [];
  const index = tokens.findIndex((token) => token.id === tokenId);
  if (index > -1) {
    newState[storeId] = tokens.toSpliced(index, 1, {...tokens[index], ...patch});
    return newState;
  }
  return state;
});
reducers.add<DeleteAction>(DELETE_ACCESS_TOKEN_REQUESTS, (state, {storeId, tokenId}) => {
  const newState = {...state};
  const tokens = newState[storeId] ?? [];
  const index = tokens.findIndex((token) => token.id === tokenId);
  if (index > -1) {
    newState[storeId] = tokens.toSpliced(index, 1);
    return newState;
  }
  return state;
});
export const accessTokensReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityStates>({});
stateReducers.add(LOAD_ACCESS_TOKENS_REQUESTS, createTimestampReducer('storeId', LOADING));
stateReducers.add(LOAD_ACCESS_TOKENS_SUCCESS, createTimestampReducer('storeId', LOADED));
stateReducers.add(
  [PATCH_ACCESS_TOKEN_FAILURE, DELETE_ACCESS_TOKEN_FAILURE],
  createTimestampReducer('storeId', INVALIDATED),
);
stateReducers.add(
  [
    'flow.IdentityAccessTokenCreatedEvent',
    'flow.IdentityAccessTokenDeletedEvent',
    'flow.IdentityAccessTokenPatchedEvent',
  ],
  createTimestampReducer('identityId', INVALIDATED),
);
stateReducers.add(
  ['flow.ApiUserAccessTokenCreatedEvent', 'flow.ApiUserAccessTokenDeletedEvent', 'flow.ApiUserAccessTokenPatchedEvent'],
  createTimestampReducer('apiUserId', INVALIDATED),
);
export const accessTokensStateReducer = stateReducers.reducer;

export function loadAccessTokens(
  storeId: Uuid,
  endpoint: string,
  urlParams: UrlParams,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const {features} = licenseSelector(state);
    if (!features.apiUsage) {
      return;
    }
    const tokensState = accessTokensStateSelector(state)[storeId];
    if (!tokensState || isOutdated(tokensState)) {
      dispatch({
        storeId,
        [CALL_API]: {
          endpoint,
          options: {urlParams},
          types: {
            failureType: LOAD_ACCESS_TOKENS_FAILURE,
            requestType: LOAD_ACCESS_TOKENS_REQUESTS,
            successType: LOAD_ACCESS_TOKENS_SUCCESS,
          },
        },
      });
    }
  };
}

export function createAccessToken(
  endpoint: string,
  urlParams: UrlParams,
  validUntil: DateStr,
): Promise<CreatedAccessToken> {
  return post<CreatedAccessToken>(endpoint, {data: {validUntil}, urlParams});
}

export function patchAccessToken(
  storeId: Uuid,
  endpoint: string,
  urlParams: UrlParams,
  tokenId: Uuid,
  patch: AccessTokenPatch,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const current = (accessTokensSelector(getState())[storeId] ?? []).find((token) => token.id === tokenId);
    if (current && !objectContains(patch, current)) {
      dispatch({
        patch,
        storeId,
        tokenId,
        [CALL_API]: {
          endpoint,
          method: 'patch',
          options: {data: patch, urlParams: {...urlParams, tokenId}},
          types: {
            failureType: PATCH_ACCESS_TOKEN_FAILURE,
            requestType: PATCH_ACCESS_TOKEN_REQUESTS,
            successType: PATCH_ACCESS_TOKEN_SUCCESS,
          },
        },
      });
    }
  };
}

export function deleteAccessToken(
  storeId: Uuid,
  endpoint: string,
  urlParams: UrlParams,
  tokenId: Uuid,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const current = (accessTokensSelector(getState())[storeId] ?? []).find((token) => token.id === tokenId);
    if (current) {
      dispatch({
        storeId,
        tokenId,
        [CALL_API]: {
          endpoint,
          method: 'del',
          options: {urlParams: {...urlParams, tokenId}},
          types: {
            failureType: DELETE_ACCESS_TOKEN_FAILURE,
            requestType: DELETE_ACCESS_TOKEN_REQUESTS,
            successType: DELETE_ACCESS_TOKEN_SUCCESS,
          },
        },
      });
    }
  };
}
