import {createContext, ReactElement, ReactNode, useContext, useMemo} from 'react';
import css from 'styled-jsx/css';
import {COLORS, FlowColors, FlowTransitions, GENERAL_DESIGN} from './Default';

export interface FlowStyleContext {
  ANIMATIONS: FlowTransitions;
  COLORS: FlowColors;
}

export const flowStyleContext = createContext<FlowStyleContext>({ANIMATIONS: GENERAL_DESIGN.ANIMATION, COLORS});

interface Props {
  initialState: FlowStyleContext;
  children: ReactNode;
}

export default function ContextProvider({initialState, children}: Props): ReactElement {
  return <flowStyleContext.Provider value={initialState}>{children}</flowStyleContext.Provider>;
}

export interface FlowStyleBuilder {
  (ctx: FlowStyleContext): ReturnType<typeof css.resolve>;
}

export function useFlowStyle(fn: FlowStyleBuilder): ReturnType<typeof css.resolve> {
  const ctx = useContext(flowStyleContext);
  return useMemo(() => fn(ctx), [fn, ctx]);
}
