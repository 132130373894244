import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {TFunction} from 'i18next';
import {ReactNode} from 'react';
import {KeyValueStoreValue} from '../EntityInterfaces/NodeEntity';

export function renderKeyValueStoreValue(value: KeyValueStoreValue, t: TFunction): ReactNode {
  if (typeof value === 'boolean') {
    return (
      <span className={'italic'}>{t(value ? 'nodeTable:column.boolean.yes' : 'nodeTable:column.boolean.no')}</span>
    );
  }
  if (typeof value === 'number') {
    return formatDecimal(value, 10);
  }
  if (typeof value === 'string') {
    return value;
  }
  return JSON.stringify(value);
}
