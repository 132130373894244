import {Label} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Radio} from 'semantic-ui-react';
import {Group} from '../../EntityInterfaces/Pid';

interface Props {
  relative: Group;
  below: boolean;
  inside: boolean;
  setBelow: () => void;
  setInside: () => void;
}

export default function GroupParent({below, inside, relative, setBelow, setInside}: Props): ReactElement | null {
  const {t} = useTranslation();

  return (
    <div>
      <Spacer />
      <Label token={'dialogs:createGroup.selectParent.label'} />

      <Radio
        label={t('dialogs:createGroup.selectParent.belowProject', {groupName: relative.name})}
        checked={below}
        onChange={setBelow}
      />

      <Radio
        label={t('dialogs:createGroup.selectParent.inside', {groupName: relative.name})}
        checked={inside}
        onChange={setInside}
      />
    </div>
  );
}
