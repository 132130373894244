import {RefObject, useEffect, useRef} from 'react';

export function useOnFocusOutside(ref: RefObject<HTMLElement>, callback: () => void): void {
  const cbRef = useRef(callback);
  cbRef.current = callback;
  useEffect(() => {
    const handle = (event: FocusEvent): void => {
      if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
        cbRef.current();
      }
    };
    document.addEventListener('focus', handle, true);
    return () => {
      document.removeEventListener('focus', handle, true);
    };
  }, [ref]);
}
