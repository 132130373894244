import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {NodeRestoredFromTrashEvent} from '../Events';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<NodeRestoredFromTrashEvent>('flow.NodeRestoredFromTrashEvent', {
  assignedAnyProjectRole: 'clear',
  assignedProjectRoleId: 'clear',
  assignedProjectRoleType: 'clear',
  bpWaitingForMyResponse: 'clear',
  customerId: 'clear',
  customerIsBillable: 'clear',
  customerName: 'clear',
  customerNumber: 'clear',
  favorite: 'clear',
  grIsClosed: 'clear',
  grIsLocked: 'clear',
  grType: 'clear',
  hasLogicalPredecessor: 'clear',
  hasTimeBasedPredecessor: 'clear',
  isArchived: 'clear',
  labelId: 'clear',
  mrIsActive: 'clear',
  name: 'clear',
  nodeNotPlanned: 'clear',
  nodePlannedAroundDateRange: 'clear',
  nodePlanningEndsAfterDate: 'clear',
  nodePlanningEndsBeforeDate: 'clear',
  nodePlanningStartsAfterDate: 'clear',
  nodePlanningStartsBeforeDate: 'clear',
  nodeType: ({nodeTypes}) => ({
    results: (s) =>
      Object.entries(nodeTypes).reduce(
        (acc, [nodeType, ids]) => reduceAddOptional(acc, ids as Uuid[], 'nodeType', nodeType as EnumFlowNodeType),
        s,
      ),
  }),
  pidHasDueDate: 'clear',
  pidHasMilestoneDate: 'clear',
  pidPid: 'clear',
  prCustomerLocationId: 'clear',
  prCustomerLocationNumber: 'clear',
  prIsClosed: 'clear',
  prIsLocked: 'clear',
  prIsTemplate: 'clear',
  referenceNumber: 'clear',
  responsibleByAny: 'clear',
  responsibleUnitId: 'clear',
  swpCompletedInDateRange: 'clear',
  swpIsCompleted: 'clear',
  swpIsLocked: 'clear',
  taskBlockedByDependency: 'clear',
  taskCompletedInDateRange: 'clear',
  taskStatus: 'clear',
  timeControlExceeded: 'clear',
  timeControlNotStarted: 'clear',
  trRefHasNonBilledTimeTrackingRecords: 'clear',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'clear',
  trRefHasOpenTimeTrackingRecords: 'clear',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'clear',
  trRefHasTimeTrackingRecords: 'clear',
  trRefHasTimeTrackingRecordsInDateRange: 'clear',
  trRefUserRecentlyBookedOn: 'clear',
  wpBillingType: 'clear',
  wpCompletedInDateRange: 'clear',
  wpHasBillingsInPeriod: 'clear',
  wpHasFinalBillings: 'clear',
  wpIsApprovedForBilling: 'clear',
  wpIsClosed: 'clear',
  wpIsCompleted: 'clear',
  wpIsLocked: 'clear',
  wpIsOffer: 'clear',
  wpPriceCategory: 'clear',
  wpTaskTimePrognosis: 'clear',
  wpTimeTrackingLimit: 'clear',
  wpTimeTrackingReferenceNodeType: 'clear',
  wpUserRecentlyBookedOn: 'clear',
});
