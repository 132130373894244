import {AuditCustomerPriceCategoryOverride} from '../../../../EntityInterfaces/Audit/AuditCustomerPriceCategoryOverride';
import BooleanCell from '../Table/Cells/BooleanCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditCustomerPriceCategoryOverride> = {
  columns: [
    ...historyColumns,
    createColumnDef('hourlyRate', 'systemSettings:audit.tables.customerPriceCategoryOverride.hourlyRateHeader'),
    createColumnDef(
      'flowCustomer',
      'systemSettings:audit.tables.customerPriceCategoryOverride.flowCustomerHeader',
      ReferenceCell,
    ),
    createColumnDef(
      'priceCategory',
      'systemSettings:audit.tables.customerPriceCategoryOverride.priceCategoryHeader',
      ReferenceCell,
    ),
    createColumnDef(
      'categoryDisabled',
      'systemSettings:audit.tables.customerPriceCategoryOverride.categoryDisabledHeader',
      BooleanCell,
    ),
    createColumnDef(
      'overrideIsActive',
      'systemSettings:audit.tables.customerPriceCategoryOverride.overrideIsActiveHeader',
      BooleanCell,
    ),
  ],
  tableIdent: 'customerPriceCategoryOverrides',
  tableNameTranslationToken: 'systemSettings:audit.customerPriceCategoryOverridesTableName',
};
export default config;
