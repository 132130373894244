import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {createTimestampReducer, EntityStates, INVALIDATED} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {Uuid} from '@octaved/typescript/src/lib';
import * as routes from '@octaved/working-time/config/routes';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {StoreUnitWorkTimes, UnitWorkTimes} from '../../EntityInterfaces/WorkTime';
import {FLOW_LOAD_WORK_TIMES_START, FLOW_LOAD_WORK_TIMES_SUCCESS} from '../ActionTypes';
import {
  workTimeOrganizationLevelUnitSelector,
  workTimeSelector,
  workTimeStateSelector,
} from '../Selectors/WorkTimeSelectors';
import {FlowState} from '../State';

const reducers = createReducerCollection<StoreUnitWorkTimes>({});
export const workTimesReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityStates>({});
stateReducers.add('flow.WorkTimeManagedEvent', createTimestampReducer('affectedUnitIds', INVALIDATED));
stateReducers.add(
  ['UserGroupDelegationEvent', 'flow.InternalRoleRightMatrixChangedEvent', 'flow.GlobalRolesBulkChangedEvent'],
  () => ({}),
);
export const workTimesStateReducer = stateReducers.reducer;

const [_useLoadWorkTimes] = createUseEntityHook<FlowState, UnitWorkTimes>(
  FLOW_LOAD_WORK_TIMES_START,
  FLOW_LOAD_WORK_TIMES_SUCCESS,
  routes.getWorkingTimeSettings,
  workTimeSelector,
  workTimeStateSelector,
  reducers,
  stateReducers,
);

const emptyIds: ReadonlyArray<Uuid> = [];

export function useLoadWorkTimeDefinitions(
  unitIds: Uuid | ReadonlyArray<Uuid> | ReadonlySet<Uuid> = emptyIds,
): ReturnType<typeof _useLoadWorkTimes> {
  const orgUnit = useSelector(workTimeOrganizationLevelUnitSelector);
  const canRead = useIsGranted('FLOW_GLOBAL_CUSTOM_WORKING_TIMES_READ_BASIC');
  //Alwas add the organization level - it's used explicitly for jumping dates
  const idsToLoad = useMemo(() => {
    if (canRead) {
      const unitIdsIterable = typeof unitIds === 'string' ? [unitIds] : unitIds;
      return [...new Set([...unitIdsIterable, orgUnit.unitId])];
    }
    return [];
  }, [canRead, unitIds, orgUnit.unitId]);
  return _useLoadWorkTimes(idsToLoad);
}
