import {error} from '@octaved/env/src/Logger';
import {LoadingOnce, useIsLoading} from '@octaved/flow/src/Modules/Hooks/Loading';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {filterIdsToReload} from '@octaved/store/src/EntityState';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {debounceReduxIdsAction} from '@octaved/utilities/src/DebounceReduxAction';
import {
  getPlanningsForNodes as getPlanningsForNodesRoute,
  patchPlanning as patchPlanningRoute,
} from '../../config/routes';
import {UpdatePlanningResult} from '../Calculations/UpdatePlanning/UpdatePlanningState';
import {Milestone} from '../EntityInterfaces/Milestones';
import {PlanningDatesList} from '../EntityInterfaces/PlanningDates';
import {PlanningDependency} from '../EntityInterfaces/PlanningDependency';
import {PlanningState} from '../PlanningState';
import {milestoneEntityStatesSelector} from '../Selectors/MilestoneSelectors';
import {planningDateEntityStatesSelector} from '../Selectors/PlanningDateSelectors';
import {planningDependencyEntityStatesSelector} from '../Selectors/PlanningDependencySelectors';
import {
  FLOW_GET_PLANNING_FOR_NODES_FAILURE,
  FLOW_GET_PLANNING_FOR_NODES_REQUEST,
  FLOW_GET_PLANNING_FOR_NODES_SUCCESS,
  FLOW_PATCH_PLANNING_FAILURE,
  FLOW_PATCH_PLANNING_REQUEST,
  FLOW_PATCH_PLANNING_SUCCESS,
} from './ActionTypes';
import {planningDate, planningDependency} from './Schema';

export type PatchPlanningDependency = Omit<PlanningDependency, 'successor'>;

export interface PlanningPatchData {
  nodeId: Uuid;
  dueDate?: DateStr | null;
  dependencies?: PatchPlanningDependency[];
  planningDates?: PlanningDatesList;
  logicalDependencies?: Uuid[];
  milestones?: Milestone[];
}

export type PatchPlanningRequest = ServerRequestAction<{
  data: PlanningPatchData[];
}> &
  UpdatePlanningResult;

export const getPlanningsForNodes = debounceReduxIdsAction(
  (ids: ReadonlyArray<Uuid>): ActionDispatcher<Promise<void>, PlanningState> => {
    return async (dispatch: Dispatch, getState) => {
      const pdToLoad = filterIdsToReload(planningDependencyEntityStatesSelector(getState()), ids);
      const mToLoad = filterIdsToReload(milestoneEntityStatesSelector(getState()), ids);
      const dToLoad = filterIdsToReload(planningDateEntityStatesSelector(getState()), ids);
      const toLoad = Array.from(new Set([...pdToLoad, ...mToLoad, ...dToLoad]));

      if (toLoad.length === 0) {
        return;
      }

      await dispatch({
        [CALL_API]: {
          endpoint: getPlanningsForNodesRoute,
          method: 'post',
          options: {data: {ids: toLoad}},
          schema: {
            planningDates: [planningDate],
            planningDependencies: [planningDependency],
          },
          types: {
            failureType: FLOW_GET_PLANNING_FOR_NODES_FAILURE,
            requestType: FLOW_GET_PLANNING_FOR_NODES_REQUEST,
            successType: FLOW_GET_PLANNING_FOR_NODES_SUCCESS,
          },
        },
      });
    };
  },
);

export function useLoadPlanningsForNodes(ids: ReadonlyArray<Uuid> | ReadonlySet<Uuid>): LoadingOnce {
  useStoreEffect(
    (dispatch) => dispatch(getPlanningsForNodes([...ids])),
    [ids],
    planningDateEntityStatesSelector,
    planningDependencyEntityStatesSelector,
    milestoneEntityStatesSelector,
  );
  return useIsLoading(ids, planningDateEntityStatesSelector);
}

export function patchPlanning(data: PlanningPatchData[]): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, _getState) => {
    const responsePromise = dispatch<Promise<{[x: string]: string}>>({
      [CALL_API]: {
        endpoint: patchPlanningRoute,
        method: 'patch',
        options: {
          data,
        },
        types: {
          failureType: FLOW_PATCH_PLANNING_FAILURE,
          requestType: FLOW_PATCH_PLANNING_REQUEST,
          successType: FLOW_PATCH_PLANNING_SUCCESS,
        },
      },
    });

    const response = await responsePromise;
    if (Object.values(response || {}).some((v) => v !== 'OK')) {
      error('Error patching planning', response);
    }
  };
}
