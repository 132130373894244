import {HStack, Icon} from '@octaved/ui';
import {PlayCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import Link from '../Link';

interface Props {
  label?: string;
}

export default function VideoTrigger({label = 'pageHelp:pages.videoLabel'}: Props): ReactElement {
  return (
    <Link>
      <HStack spacing={'5px'} alignItems={'center'}>
        <Icon iconColor={'darkGrey'} noMargin size={'large'}>
          <PlayCircle />
        </Icon>
        <span>
          <Trans i18nKey={label} />
        </span>
      </HStack>
    </Link>
  );
}
