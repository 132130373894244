import {
  getAllDescendantIdsSelector,
  nodeTreeInvertedSelector,
  nodeTreeSelector,
} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getAllAncestorsForNodeIds, getAllDescendantsForRootIds} from '@octaved/trees/src/GenericTreeBuilder';
import type {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, numberComparators, NumberCompareOperators} from '@octaved/utilities';
import type {CombinersShape} from '@octaved/utilities/src/Condition/Types';
import {createSelector} from 'reselect';

export const nodeSearchCustomCombinersSelector = createSelector(
  nodeTreeSelector,
  nodeTreeInvertedSelector,
  getAllDescendantIdsSelector,
  (nodeTree, nodeTreeInverted, getAllDescendantIds) => {
    return {
      descendantsCount: (
        sources: {roots: ReadonlyArray<Uuid>; descendants: ReadonlyArray<Uuid>},
        options: {operator: NumberCompareOperators; value: number},
      ): ReadonlyArray<Uuid> => {
        return sources.roots.filter((rootId) => {
          const descendants = getAllDescendantIds(rootId, false);
          const count = sources.descendants.filter((id) => descendants.has(id)).length;
          return numberComparators[options.operator](count, options.value);
        });
      },
      descendantsRatio: (
        sources: {roots: ReadonlyArray<Uuid>; subset: ReadonlyArray<Uuid>; total: ReadonlyArray<Uuid>},
        options: {operator: NumberCompareOperators; value: number},
      ): ReadonlyArray<Uuid> => {
        return sources.roots.filter((rootId) => {
          const descendants = getAllDescendantIds(rootId, false);
          const subsetDescendants = sources.subset.filter((id) => descendants.has(id)).length;
          const totalDescendants = sources.total.filter((id) => descendants.has(id)).length;
          const ratio = subsetDescendants > 0 && totalDescendants > 0 ? subsetDescendants / totalDescendants : 0;
          return numberComparators[options.operator](ratio, options.value);
        });
      },
      descendantsRatioRange: (
        sources: {roots: ReadonlyArray<Uuid>; subset: ReadonlyArray<Uuid>; total: ReadonlyArray<Uuid>},
        options: {min: number; max: number},
      ): ReadonlyArray<Uuid> => {
        return sources.roots.filter((rootId) => {
          const descendants = getAllDescendantIds(rootId, false);
          const subsetDescendants = sources.subset.filter((id) => descendants.has(id)).length;
          const totalDescendants = sources.total.filter((id) => descendants.has(id)).length;
          const ratio = subsetDescendants > 0 && totalDescendants > 0 ? subsetDescendants / totalDescendants : 0;
          return ratio >= options.min && ratio <= options.max;
        });
      },
      nodeIdInTree: (_sources: Record<never, never>, options: {nodeId: Uuid}): ReadonlyArray<Uuid> =>
        nodeTree[options.nodeId] ? [options.nodeId] : [],
      toChildren: (sources: {parents: ReadonlyArray<Uuid>}): ReadonlyArray<Uuid> => [
        ...new Set(sources.parents.reduce<Uuid[]>((ids, id) => [...ids, ...(nodeTreeInverted.get(id) || [])], [])),
      ],
      toParents: (sources: {children: ReadonlyArray<Uuid>}): ReadonlyArray<Uuid> => [
        ...new Set(boolFilter(sources.children.map((id) => nodeTree[id]))),
      ],
      withAncestors: (
        sources: {descendants: ReadonlyArray<Uuid>},
        options: {includeSelf: boolean},
      ): ReadonlyArray<Uuid> => [...getAllAncestorsForNodeIds(nodeTree, sources.descendants, options.includeSelf)],
      withDescendants: (
        sources: {ancestors: ReadonlyArray<Uuid>},
        options: {includeSelf: boolean},
      ): ReadonlyArray<Uuid> => [...getAllDescendantsForRootIds(nodeTree, sources.ancestors, options.includeSelf)],
    } as const satisfies CombinersShape<Uuid>;
  },
);

export type NodeSearchCustomCombiners = ReturnType<typeof nodeSearchCustomCombinersSelector>;
