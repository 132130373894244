import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';
import 'react-dates/lib/css/_datepicker.css';
import {StrictPopupProps} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {Popup} from '../../../Motion';
import {StopClickPropagation} from '../../../Util';
import {ConvertFromFn, ConvertToFn, OnChangeFn} from '../DateOrRangePicker';
import PickerViewWrapper from './Components/PickerViewWrapper';
import PickerView, {PickerViewProps} from './PickerView';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .dayPickerPopup {
    padding: 0;
    z-index: 2010;
  }
`;

//#endregion

interface Props<DATE, CAN_CLEAR extends boolean> {
  end: DATE | null;
  footer?: ReactNode;
  onChangeDate: OnChangeFn<DATE, CAN_CLEAR>;
  start: DATE | null;
  popupProps?: StrictPopupProps;
  datePickerProps?: Partial<Omit<PickerViewProps<DATE, CAN_CLEAR>, 'start' | 'end' | 'onChangeDate'>> &
    Pick<PickerViewProps<DATE, CAN_CLEAR>, 'isRange'>;
  convertFrom: ConvertFromFn<DATE>;
  convertTo: ConvertToFn<DATE>;
  canClear?: CAN_CLEAR;
}

export default function PopupPickerView<DATE, CAN_CLEAR extends boolean>({
  datePickerProps = {isRange: false},
  end,
  footer,
  onChangeDate,
  popupProps,
  start,
  convertFrom = fromIsoFormat as ConvertFromFn<DATE>,
  convertTo = toIsoFormat as ConvertToFn<DATE>,
}: Props<DATE, CAN_CLEAR>): ReactElement {
  return (
    <>
      <Popup
        basic
        on={'click'}
        position={'bottom left'}
        additionalClassName={classNames(className, 'dayPickerPopup')}
        {...popupProps}
      >
        <div>
          <StopClickPropagation>
            <PickerViewWrapper>
              <PickerView
                start={start}
                end={end}
                onChangeDates={onChangeDate}
                focused={null}
                setFocused={() => false}
                convertFrom={convertFrom}
                convertTo={convertTo}
                {...datePickerProps}
              />
            </PickerViewWrapper>
            {footer}
          </StopClickPropagation>
        </div>
      </Popup>

      {styles}
    </>
  );
}
