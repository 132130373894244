import {Uuid} from '@octaved/typescript/src/lib';
import {isNode} from '../Node/NodeIdentifiers';
import {NodeType} from './Nodes';

export interface ResponsibleProps {
  definesOwnResponsible: boolean;
  responsibleGroups: Uuid[];
  responsibleUsers: Uuid[];
}

export type ResponsibleNode = Extract<NodeType, ResponsibleProps>;
export type ResponsibleNodeType = ResponsibleNode['nodeType'];

export function isResponsibleNode(node: unknown): node is ResponsibleNode {
  return isNode(node) && 'definesOwnResponsible' in node && 'responsibleGroups' in node && 'responsibleUsers' in node;
}
