import {useEffect, useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {DropdownItemProps} from 'semantic-ui-react';

export const fallbackLanguage = 'en';
export const supportedLanguageCodes = ['de', 'en', 'es', 'fr', 'it', 'pt'] as const;
export type SupportedLanguage = (typeof supportedLanguageCodes)[number];

export function isSupportedLanguage(code: string): code is SupportedLanguage {
  return supportedLanguageCodes.includes(code as SupportedLanguage);
}

export function getSupportedLanguageWithFallback(code: string): SupportedLanguage {
  const base = code.slice(0, 2);
  return isSupportedLanguage(base) ? base : fallbackLanguage;
}

export function getSupportedBrowserLanguage(): SupportedLanguage {
  for (const fullCode of navigator.languages) {
    const baseCode = fullCode.slice(0, 2);
    if (isSupportedLanguage(baseCode)) {
      return baseCode;
    }
  }
  return fallbackLanguage;
}

export function useSupportedLanguageFromContext(): SupportedLanguage {
  const {i18n} = useTranslation();
  return getSupportedLanguageWithFallback(i18n.language);
}

export function useOnLanguageChange(cb: (lang: SupportedLanguage) => void): void {
  const lang = useSupportedLanguageFromContext();
  const langRef = useRef(lang);
  const cbRef = useRef(cb);
  cbRef.current = cb;
  useEffect(() => {
    if (lang !== langRef.current) {
      langRef.current = lang;
      cbRef.current(lang);
    }
  }, [lang]);
}

interface SupportedLanguageConfig {
  i18nKey: string;
  nativeName: string;
}

export const supportedLanguageConfigs: Record<SupportedLanguage, SupportedLanguageConfig> = {
  de: {
    i18nKey: 'general:languages.de',
    nativeName: 'Deutsch',
  },
  en: {
    i18nKey: 'general:languages.en',
    nativeName: 'English',
  },
  es: {
    i18nKey: 'general:languages.es',
    nativeName: 'Español',
  },
  fr: {
    i18nKey: 'general:languages.fr',
    nativeName: 'Français',
  },
  it: {
    i18nKey: 'general:languages.it',
    nativeName: 'Italiano',
  },
  pt: {
    i18nKey: 'general:languages.pt',
    nativeName: 'Português',
  },
};

export const supportedLanguageDropdownOptions: DropdownItemProps[] = supportedLanguageCodes.map((code) => ({
  text: (
    <span>
      <Trans i18nKey={supportedLanguageConfigs[code].i18nKey} />
    </span>
  ),
  value: code,
}));
