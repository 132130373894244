import {BoardPostType} from '@octaved/env/src/dbalEnumTypes';

export const boardPostTypeTranslations: Record<BoardPostType, string> = {
  [BoardPostType.approval]: 'boardPosts:approval.displayName',
  [BoardPostType.changeRequest]: 'boardPosts:changeRequest.displayName',
  [BoardPostType.concept]: 'boardPosts:concept.displayName',
  [BoardPostType.documentation]: 'boardPosts:documentation.displayName',
  [BoardPostType.file]: 'boardPosts:file.displayName',
  [BoardPostType.fyi]: 'boardPosts:fyi.displayName',
  [BoardPostType.image]: 'boardPosts:image.displayName',
  [BoardPostType.meetingReport]: 'boardPosts:meetingReport.displayName',
  [BoardPostType.phoneNote]: 'boardPosts:phoneNote.displayName',
  [BoardPostType.question]: 'boardPosts:question.title',
  [BoardPostType.text]: 'boardPosts:text.displayName',
};

export const boardPostTypeTranslationsMap = new Map(Object.entries(boardPostTypeTranslations)) as Map<
  BoardPostType,
  string
>;
