import {FilterStatesValueArray, isComplete} from '../../../Modules/Filter/MergeFilterStatesWithDefaults';

export type QuickControlPlanningTransgressionThreshold = 'transgressed' | 'adhered' | 'upcoming' | 'withoutDueDate';
export type QuickControlPlanningTransgressionUpcoming =
  | 'dueInFuture'
  | 'dueToday'
  | 'dueWithin7days'
  | 'dueWithin30days'
  | 'dueInCustomPeriod';

export interface QuickControlPlanningTransgression {
  thresholds: FilterStatesValueArray<QuickControlPlanningTransgressionThreshold>;
  upcomingCustomFrom: string; //iso date
  upcomingCustomTo: string; //iso date
  upcomingOption: QuickControlPlanningTransgressionUpcoming;
}

export const quickControlPlanningTransgressionDefault: QuickControlPlanningTransgression = {
  thresholds: ['transgressed', 'adhered', 'upcoming', 'withoutDueDate'],
  upcomingCustomFrom: '',
  upcomingCustomTo: '',
  upcomingOption: 'dueInFuture',
};

quickControlPlanningTransgressionDefault.thresholds[isComplete] = true;
