import {IconButton, Tooltip} from '@octaved/ui';
import {Star} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {projectsShowOnlyFavoriteProjectsSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useProjectUiStatePatch} from '../../../Pages/Projects/Projects';

export default function ProjectFavoritesToggle(): ReactElement {
  const isActive = useSelector(projectsShowOnlyFavoriteProjectsSelector);
  const patchUi = useProjectUiStatePatch();

  return (
    <Tooltip toolTipTranslation={'tooltips:components.pageHeader.buttonStar'} position={'bottom center'}>
      <IconButton
        variant={'ghost'}
        size={'sm'}
        icon={<Star />}
        isUnderlined={isActive}
        isActive={isActive}
        onClick={() => patchUi({showOnlyFavoriteProjects: !isActive})}
      />
    </Tooltip>
  );
}
