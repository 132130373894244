import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {dispatch, getState} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {createSelector} from 'reselect';
import {FLOW_TODAY_CHANGED} from './Modules/ActionTypes';
import {settingsSelector} from './Modules/Selectors/SettingSelectors';
import {FlowState} from './Modules/State';

//Arbitrary ealiest date before Flow existed. Used e.g. for date ranges in the past:
export const earliestIsoDate = '2000-01-01' as DateStr;
//Arbitrary latest date. Used e.g. for date ranges in the future:
export const latestIsoDate = '2099-12-31' as DateStr;

const timeout = 10000;
let previous: DateStr;

const reducerMap = new Map();
reducerMap.set(FLOW_TODAY_CHANGED, (_: DateStr, {today}: {today: DateStr}): DateStr => today);
export const todaytodayIsoDateReduer = ReduceFromMap(reducerMap, dayjs().format(ISO_DATE));

export const todayIsoDateSelector = (state: FlowState): DateStr => state.todayIsoDate;
export const todayDayjsSelector = createSelector(todayIsoDateSelector, (today) => dayjs(`${today}T00:00:00.000Z`));

/**
 * Checks regularily whether the current day in flow's timezone changed and dispatches an event if so.
 * This is required for day-sensitive data in the store to be invalidated, like project time controlling.
 */
function checkTodayChanged(): void {
  const timezone = settingsSelector(getState()).timezone;
  const today = dayjs().tz(timezone).format(ISO_DATE) as DateStr;
  if (previous && previous !== today) {
    dispatch({type: FLOW_TODAY_CHANGED, today});
  }
  previous = today;
  setTimeout(checkTodayChanged, timeout);
}

export function initTodayCheck(): void {
  setTimeout(checkTodayChanged, timeout);
}
