import {createContext, PropsWithChildren, ReactElement, useContext, useMemo, useState} from 'react';
import {NodeTableRow} from './RowTypes';
import {NodeTablesPage} from './Ui/NodeTableUiStateInterface';

interface Props {
  page: NodeTablesPage;
  selectedRowId?: string | null;
  setSelectedRowId?: (id: string | null) => void;
}

export interface NodeTableContext extends Props {
  selectedRow: NodeTableRow | null;
  setSelectedRow: (row: NodeTableRow | null) => void;
}

const context = createContext<NodeTableContext | null>(null);

export function useNodeTableContextOptional(): NodeTableContext | null {
  return useContext(context);
}

export function useNodeTableContext(): NodeTableContext {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('Missing required NodeTableContext');
  }
  return ctx;
}

export default function NodeTableContextProvider({
  children,
  page,
  selectedRowId,
  setSelectedRowId,
}: PropsWithChildren<Props>): ReactElement {
  const [selectedRow, setSelectedRow] = useState<NodeTableRow | null>(null);
  const ctx = useMemo(
    () => ({
      page,
      selectedRow,
      selectedRowId,
      setSelectedRow,
      setSelectedRowId,
    }),
    [page, selectedRow, selectedRowId, setSelectedRowId],
  );
  return <context.Provider value={ctx}>{children}</context.Provider>;
}
