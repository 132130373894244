import {addReducerToMapOrCollection, ReducerMapOrCollection} from '@octaved/hooks/src/Factories/ReducerAbstraction';
import {get, NetworkMethod} from '@octaved/network/src/Network';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  hasLoadedOnce as hasStateLoadedOnce,
  isLoaded as isStateLoaded,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createSubRecordSelector} from '@octaved/store/src/Selectors/CreateSubRecordSelector';
import {dispatch, getState} from '@octaved/store/src/Store';
import {chunk, debounce} from 'lodash';
import {Reducer, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {useStoreEffect} from '../StoreEffect';

function chunkedGet<R>(route: string, get: NetworkMethod, ids: ReadonlyArray<string>): Promise<R[]> {
  const chunked = chunk(ids, 100); //chunk to not exceed the URL length limit
  return Promise.all<R[]>(chunked.map((chunkedIds) => get<R[]>(route, {urlParams: {ids: chunkedIds}}))).then<R[]>(
    (all) => ([] as R[]).concat(...all),
  );
}

interface Entries<Response extends {id: string | number}, Entity = Response> {
  entries: Entity[];
  entriesMapped: Map<Response['id'], Entity>;
}

interface HookResult {
  hasLoadedOnce: boolean;
}

interface HookResultLoading extends HookResult {
  isLoading: boolean;
}

interface LoadHook<Response extends {id: string | number}> {
  <TrackIsLoading extends boolean>(
    ids: Response['id'] | null | undefined | ReadonlyArray<Response['id']>,
    trackIsLoading?: TrackIsLoading,
  ): TrackIsLoading extends true ? HookResultLoading : HookResult;
}

interface LoadedEntriesHook<Response extends {id: string | number}, Entity = Response> {
  <TrackIsLoading extends boolean>(
    ids: Response['id'] | null | undefined | ReadonlyArray<Response['id']>,
    trackIsLoading?: TrackIsLoading,
  ): (TrackIsLoading extends true ? HookResultLoading : HookResult) & Entries<Response, Entity>;
}

export function createUseEntityHook<StoreState, Response extends {id: string | number}, Entity = Response>(
  startActionType: string,
  successActionType: string,
  route: string,
  entitiesSelector: (s: StoreState) => Record<Response['id'], Entity | undefined>,
  entityStatesSelector: (s: StoreState) => EntityStates,
  reducers: ReducerMapOrCollection<
    Reducer<Record<Response['id'], Entity | undefined>, {ids: Response['id'][]; result: Response[]; type: string}>
  >,
  stateReducers: ReducerMapOrCollection<
    Reducer<EntityStates, {ids: Response['id'][]; result: Response[]; type: string}>
  >,
  {
    debounceDelay = 0,
    normalizer = (e) => e as unknown as Entity,
  }: {
    debounceDelay?: number;
    normalizer?: (result: Response) => Entity;
  } = {},
): [LoadHook<Response>, LoadedEntriesHook<Response, Entity>] {
  addReducerToMapOrCollection(reducers, successActionType, (state, {ids, result}) => {
    const newState = {...state};

    const resultMapped = new Map(result.map((entity) => [entity.id as Response['id'], normalizer(entity)]));

    //Take all entities from the result with their ids.
    // In case of PlanningDates the entity id is not the same as the id it was loaded with, so `ids` are different:
    resultMapped.forEach((entity, id) => {
      newState[id] = entity;
    });

    //Clear out any loaded ids, which have not returned any result:
    // This will only work if the `ids` are the same as `entity.id` of course, but doesn't do anything if they differ.
    ids.forEach((id) => {
      if (!resultMapped.has(id)) {
        delete newState[id];
      }
    });

    return newState;
  });
  addReducerToMapOrCollection(stateReducers, startActionType, createTimestampReducer('ids', LOADING));
  addReducerToMapOrCollection(stateReducers, successActionType, createTimestampReducer('ids', LOADED));

  const debounceStack = new Set<string>();
  const loadDebounced = debounce(() => {
    const ids = [...debounceStack];
    debounceStack.clear();
    const entityStates = entityStatesSelector(getState());
    const toLoad = filterIdsToReload(entityStates, ids);
    if (toLoad.length) {
      dispatch({type: startActionType, ids: toLoad});
      chunkedGet<Response>(route, get, toLoad).then((result) => {
        //Must be all `toLoad` ids, which are not necessarily all included in the result:
        dispatch({type: successActionType, result, ids: toLoad});
      });
    }
  }, debounceDelay);

  const hasLoadedOnceSelector = createSelector(entityStatesSelector, (states) => (ids: Readonly<Response['id'][]>) => {
    return ids.every((id) => hasStateLoadedOnce(states[id] || {}));
  });

  const isLoadingSelector = createSelector(entityStatesSelector, (states) => (ids: Readonly<Response['id'][]>) => {
    return ids.length > 0 && ids.some((id) => !isStateLoaded(states[id] || {}));
  });

  const useLoad: LoadHook<Response> = (_ids, trackIsLoading) => {
    const ids = useMemo((): ReadonlyArray<Response['id']> => (Array.isArray(_ids) ? _ids : _ids ? [_ids] : []), [_ids]);

    const hasLoadedOnce = useSelector(
      useMemo(() => {
        let loadedOnce = false;
        return (s: StoreState) => {
          loadedOnce = loadedOnce || hasLoadedOnceSelector(s)(ids);
          return loadedOnce;
        };
      }, [ids]),
    );

    const isLoading = useSelector((s: StoreState) => (trackIsLoading ? isLoadingSelector(s)(ids) : false));

    useStoreEffect(
      () => {
        (ids as string[]).forEach((id) => debounceStack.add(id));
        loadDebounced();
      },
      [ids],
      entityStatesSelector,
    );

    return {
      hasLoadedOnce,
      isLoading,
    };
  };

  const useLoadedEntries: LoadedEntriesHook<Response, Entity> = (_ids, trackIsLoading) => {
    const ids = useMemo((): ReadonlyArray<Response['id']> => (Array.isArray(_ids) ? _ids : _ids ? [_ids] : []), [_ids]);

    const {hasLoadedOnce, isLoading} = useLoad(ids, trackIsLoading as true);

    const subRecord = useSelector(
      createSubRecordSelector<Entity, StoreState>(entitiesSelector, ids as string[]) as (
        s: StoreState,
      ) => Record<Response['id'], Entity | undefined>,
    );
    const [entries, entriesMapped] = useMemo<[Entity[], Map<Response['id'], Entity>]>(() => {
      const flat: Entity[] = Object.values(subRecord);
      const map = new Map<Response['id'], Entity>(Object.entries(subRecord));
      return [flat, map];
    }, [subRecord]);

    return {
      entries,
      entriesMapped,
      hasLoadedOnce,
      isLoading,
    };
  };

  return [useLoad, useLoadedEntries];
}
