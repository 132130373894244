import {parse, roundToFactor} from '@octaved/users/src/Culture/NumberFormatter';
import {useEffect, useState} from 'react';

/**
 * Turns a raw input value from a decimal input field into a number
 */
export function useRawDecimal(rawInput: string | null): number | null {
  const [parsed, setParsed] = useState<number | null>(null);
  useEffect(() => {
    try {
      setParsed(rawInput ? parse(rawInput) : null);
    } catch (_e) {
      //ignore parsing errors, keep old value until value was corrected
    }
  }, [rawInput]);
  return parsed;
}

/**
 * Special rounding to half days, not rounding down to zero
 */
export function roundToHalfDays(days: number): number {
  if (days === 0) {
    return 0;
  }
  if (days < 0.5) {
    return 0.5;
  }
  return roundToFactor(days, 0.5);
}

export function roundSecondsToHoursOrDays(seconds: number, useDays: boolean): number {
  //See https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/891 - hours are rouned to .25 and days to .5:
  return useDays ? roundToHalfDays(seconds / (3600 * 8)) : roundToFactor(seconds / 3600, 0.25);
}
