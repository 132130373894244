import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {LicenseFeature, LicenseLevel} from '@octaved/license/src/LicenseInterfaces';
import {licenseSelector} from '@octaved/license/src/Selectors/LicenseSelectors';
import {IsGranted, isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {GlobalRight} from '@octaved/security/src/Authorization/Rights';
import {OrgUserSettings} from '@octaved/users/src/EntityInterfaces/OrgUserSettings';
import {
  currentOrgUserSettingsSelector,
  currentOrgUserTypeSelector,
} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {createSelector} from 'reselect';
import {settingsSelector} from './Modules/Selectors/SettingSelectors';
import {Settings} from './Modules/Settings';
import {simulationModeActiveSelector} from '@octaved/planning/src/Selectors/UiSelectors';

export interface AccessibleItemAccessOptions {
  isGranted: IsGranted;
  licensedFeatures: Record<LicenseFeature, boolean>;
  licenseLevel: LicenseLevel;
  orgUserType: UserType;
  settings: Settings;
  userSettings: OrgUserSettings;
  isSimulationActive: boolean;
}

export type AccessibleItemAccess = GlobalRight | ((options: AccessibleItemAccessOptions) => boolean);

export interface AccessibleItem {
  access?: AccessibleItemAccess;
}

export const isItemAccessibleSelector = createSelector(
  isGrantedSelector,
  settingsSelector,
  currentOrgUserSettingsSelector,
  currentOrgUserTypeSelector,
  licenseSelector,
  simulationModeActiveSelector,
  (isGranted, settings, userSettings, orgUserType, license, isSimulationActive) =>
    ({access}: AccessibleItem) =>
      !access ||
      (typeof access === 'string' && isGranted(access)) ||
      (typeof access === 'function' &&
        access({
          isGranted,
          isSimulationActive,
          orgUserType,
          settings,
          userSettings,
          licensedFeatures: license.features,
          licenseLevel: license.level,
        })),
);
