import Close from '@octaved/flow/src/Dialogs/Components/Close';
import {useDisposedSafeCallback} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {Popup, TWButton} from '@octaved/ui';
import {ListTree} from 'lucide-react';
import {ReactElement, useCallback, useState} from 'react';
import Content from './Content';

interface Props {
  nodeId: Uuid;
}

export default function ProjectTreeSelector({nodeId}: Props): ReactElement {
  const [open, _setOpen] = useState(false);
  const setOpen = useDisposedSafeCallback(_setOpen);
  const close = useCallback(() => setOpen(false), [setOpen]);
  return (
    <Popup
      open={open}
      onOpen={() => setOpen(true)}
      onClose={close}
      position={'bottom left'}
      trigger={
        <TWButton variant={'outline'} size={'sm'} iconOnly colorScheme={'slate'}>
          <ListTree className={'size-4'} />
        </TWButton>
      }
    >
      <div className={'max-h-[70vh] w-[540px] overflow-y-auto p-2'}>
        <div className={'absolute right-2 top-2 z-10'}>
          <Close close={close} />
        </div>
        <Content close={close} nodeId={nodeId} />
      </div>
    </Popup>
  );
}
