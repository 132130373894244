import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {isNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {isoToIsoDateTimeFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {throttle} from 'lodash';
import {ReactElement, RefObject, useCallback, useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {dateIsPlanned} from '../../../../Calculations/DateCalculations';
import {closestWorkDay} from '../../../../Calculations/WorkdayCalculations';
import {calendarContainerContext} from '../../../../Components/Calendar/CalendarContainer/CalendarContainerContext';
import {useBarManipulatingContext} from '../../../../Components/Calendar/CalendarContainer/Content/Bar/FunctionalBar/Context/BarManipulatingContext';
import {PlanningDatesList} from '../../../../EntityInterfaces/PlanningDates';
import {
  getMinMaxPlanningDatesSelector,
  getPlanningDatesForNodeSelector,
} from '../../../../Selectors/PlanningDateSelectors';
import {canChangeBarSelector} from '../../../../Selectors/PlanningDependencySelectors';
import {BaseTreeData} from '../BaseTreeData';
import PlaceholderBar from './PlaceholderBar';

interface Props {
  rowRef: RefObject<HTMLDivElement>;
  rowData: BaseTreeData;
}

export function dateCanBePlanned(
  placeholderDate: DateTimeStr,
  planningDates: PlanningDatesList,
  isLimited: boolean,
  plannedStart: DateTimeStr | null,
  plannedEnd: DateTimeStr | null,
): boolean {
  if (isLimited && plannedStart && plannedStart >= placeholderDate) {
    return false;
  }
  if (isLimited && plannedEnd && plannedEnd <= placeholderDate) {
    return false;
  }
  return !dateIsPlanned(planningDates, placeholderDate);
}

export default function PlaceholderBarWrapper({rowRef, rowData}: Props): ReactElement | null {
  const {cellWidth, startDate} = useContext(calendarContainerContext);
  const getWorkingTimeAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const getPlanningDatesForNode = useSelector(getPlanningDatesForNodeSelector);
  const [placeholderPlannedStart, setPlaceholderPlannedStart] = useState<DateStr | null>(null);

  const canChangeBar = useSelector(canChangeBarSelector)(rowData.id);
  const {plannedEnd, plannedStart} = useSelector(getMinMaxPlanningDatesSelector)(rowData.id);
  const barManipulatingContext = useBarManipulatingContext();

  const cancel = useCallback(() => {
    setPlaceholderPlannedStart(null);
    barManipulatingContext.cancel();
  }, [barManipulatingContext]);

  if (placeholderPlannedStart && rowRef.current && !rowRef.current.matches(':hover')) {
    //onMouseLeave is not always working correct if mouse is moving fast
    cancel();
  }

  useEffect(() => {
    const ref = rowRef.current;
    const onMouseLeave = (): void => {
      cancel();
    };
    const planningDates = getPlanningDatesForNode(rowData.id);

    const onMouseMove = throttle((event: MouseEvent) => {
      if (ref && !barManipulatingContext.isUpdating() && !barManipulatingContext.create.isCreating()) {
        const rect = ref.getBoundingClientRect();
        const daysFromStart = Math.floor((event.clientX - rect.left) / cellWidth);
        const placeholderDay = toIsoFormat(closestWorkDay(startDate.add(daysFromStart, 'd'), getWorkingTimeAtDate));
        if (
          isNode(rowData.data) &&
          !dateCanBePlanned(
            isoToIsoDateTimeFormat(placeholderDay),
            planningDates,
            !canChangeBar,
            plannedStart,
            plannedEnd,
          )
        ) {
          cancel();
        } else {
          setPlaceholderPlannedStart(placeholderDay);
        }
      }
    }, 30);

    if (ref) {
      ref.addEventListener('mousemove', onMouseMove);
      ref.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      if (ref) {
        ref.removeEventListener('mousemove', onMouseMove);
        ref.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [
    barManipulatingContext,
    cancel,
    cellWidth,
    canChangeBar,
    getPlanningDatesForNode,
    getWorkingTimeAtDate,
    plannedEnd,
    plannedStart,
    rowData.data,
    rowData.id,
    rowRef,
    startDate,
  ]);
  if (!placeholderPlannedStart) {
    return null;
  }

  return <PlaceholderBar data={rowData} plannedStart={placeholderPlannedStart} />;
}
