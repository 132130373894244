import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';

import {AuditTask} from '../../../../EntityInterfaces/Audit/AuditTask';
import DateStringCell from '../Table/Cells/DateStringCell';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {nodeColumns} from './Node';

const statusTranslations = new Map<EnumFlowTaskStatus, string>([
  [EnumFlowTaskStatus.VALUE_OPEN, 'systemSettings:audit.tables.task.enumStatusOpen'],
  [EnumFlowTaskStatus.VALUE_COMPLETE, 'systemSettings:audit.tables.task.enumStatusComplete'],
]);

const config: AuditTableConfiguration<AuditTask> = {
  columns: [
    ...nodeColumns,
    createColumnDef('description', 'systemSettings:audit.tables.task.descriptionHeader'),
    createColumnDef('plannedStart', 'systemSettings:audit.tables.task.plannedStartHeader', DateStringCell),
    createColumnDef('plannedEnd', 'systemSettings:audit.tables.task.plannedEndHeader', DateStringCell),
    createColumnDef('plannedTime', 'systemSettings:audit.tables.task.plannedTimeHeader'),
    createColumnDef('status', 'systemSettings:audit.tables.task.statusHeader', ({cell: {value}}) => {
      return <EnumTranslationCell value={value} translationMapping={statusTranslations} />;
    }),
    createColumnDef('sortOrder', 'systemSettings:audit.tables.task.sortOrderHeader'),
    createColumnDef('completedOn', 'systemSettings:audit.tables.task.completedHeader', DateStringCell),
  ],
  tableIdent: 'tasks',
  tableNameTranslationToken: 'systemSettings:audit.taskTableName',
};
export default config;
