import {ReactElement} from 'react';
import ExtendedProgress, {ExtendedProgressProps} from '../../../semantic-ui/ExtendedProgress';

export default function BillingTypeProgressBar(props: ExtendedProgressProps): ReactElement {
  return (
    <div className={'progress'}>
      <ExtendedProgress {...props} size={'tiny'} />

      {/*language=scss*/}
      <style jsx>{`
        .progress {
          flex-shrink: 0;
          width: var(--billing-type-progress-width, 75px);
        }
      `}</style>
    </div>
  );
}
