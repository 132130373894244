import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {IconButton} from '@octaved/ui';
import {CheckCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useDispatch} from 'react-redux';
import {useMarkParentTasksDoneWorkflowControlModal} from '../../Dialogs/WorkflowControl/MarkParentTasksDoneWorkflowControl';
import StandardInspectorHeaderSubRow from '../../Drawer/StandardInspector/StandardInspectorHeaderSubRow';
import {useTask} from '../../Modules/Hooks/Nodes';
import {patchTaskWithTaskPatchData, useCanChangeTaskStatus} from '../../Modules/Tasks';

interface Props {
  taskId: string;
}

export default function MarkTaskFinished({taskId}: Props): ReactElement | null {
  const task = useTask(taskId);
  const dispatch = useDispatch();
  const canManage = useCanChangeTaskStatus(taskId);
  const {confirmCheckedModal, setChecked} = useMarkParentTasksDoneWorkflowControlModal(taskId, (checked) => {
    dispatch(
      patchTaskWithTaskPatchData(taskId, {
        status: checked ? EnumFlowTaskStatus.VALUE_COMPLETE : EnumFlowTaskStatus.VALUE_OPEN,
      }),
    );
  });

  if (canManage) {
    const isDone = task?.status === EnumFlowTaskStatus.VALUE_COMPLETE;
    return (
      <StandardInspectorHeaderSubRow>
        <IconButton
          size={'sm'}
          icon={<CheckCircle />}
          iconColor={isDone ? 'white' : 'darkGrey'}
          label={isDone ? 'pages:projects.inspector.header.taskDone' : 'pages:projects.inspector.header.taskMarkAsDone'}
          variant={isDone ? 'solid' : 'outline'}
          colorScheme={isDone ? 'green' : 'gray'}
          onClick={() => setChecked(!isDone)}
        />
        {confirmCheckedModal}
      </StandardInspectorHeaderSubRow>
    );
  }
  return null;
}
