import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {GetWorkingTimeAtDate} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {isPid} from '@octaved/flow/src/Node/NodeIdentifiers';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  fromIsoDateTimeFormat,
  fromIsoFormat,
  isoDateTimeToIsoFormat,
  toIsoFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {PlanningDatesList} from '../EntityInterfaces/PlanningDates';
import {
  getMinMaxPlanningDatesSelector,
  MinMaxPlanningDatesResult,
  MinMaxPlanningDatesResultNotNullable,
} from '../Selectors/PlanningDateSelectors';
import {isPlanningDependencyNode} from '../Selectors/PlanningSelector';
import {workdaysBetween} from './WorkdayCalculations';

export function maxDate(a: null, b: null): DateStr;
export function maxDate(a: DateStr | null, b: DateStr | null): DateStr;
export function maxDate(a: DateTimeStr | null, b: DateTimeStr | null): DateTimeStr;
export function maxDate(
  a: DateTimeStr | DateStr | null,
  b: DateTimeStr | DateStr | null,
): DateTimeStr | DateStr | null {
  if (a === null && b === null) {
    return null;
  }
  if (a === null) {
    return b;
  }
  if (b === null || a > b) {
    return a;
  }
  return b;
}

export function minDate(a: null, b: null): null;
export function minDate(a: DateStr | null, b: DateStr | null): DateStr;
export function minDate(a: DateTimeStr | null, b: DateTimeStr | null): DateTimeStr;
export function minDate(
  a: DateTimeStr | DateStr | null,
  b: DateTimeStr | DateStr | null,
): DateTimeStr | DateStr | null {
  if (a === null && b === null) {
    return null;
  }
  if (a === null) {
    return b;
  }
  if (b === null || a < b) {
    return a;
  }
  return b;
}

interface PlanningTreeNode {
  plannedStart: null | DateStr;
  plannedEnd: DateStr | null;
  subRows?: PlanningTreeNode[];
}

interface StartEndResult {
  startDate: DateStr;
  endDate: DateStr;
}

const todayIsoString = toIsoFormat(dayjs());
const startEndEmptyResult: StartEndResult = {startDate: todayIsoString, endDate: todayIsoString};

export function calculateStartEnd(
  tree: PlanningTreeNode[],
  initialStart: DateStr | null = null,
  initialEnd: DateStr | null = null,
): StartEndResult {
  let startDate = initialStart ?? todayIsoString;
  let endDate = initialEnd ?? todayIsoString;
  for (const treeEntry of tree) {
    if ((!treeEntry.plannedStart || !treeEntry.plannedEnd) && treeEntry.subRows) {
      for (const subTreeEntry of treeEntry.subRows) {
        startDate = minDate(startDate, subTreeEntry.plannedStart);
        endDate = maxDate(endDate, subTreeEntry.plannedEnd);
      }
    } else {
      startDate = minDate(startDate, treeEntry.plannedStart);
      endDate = maxDate(endDate, treeEntry.plannedEnd);
    }
  }

  return {startDate, endDate};
}

export function getMinMaxFromPlanningDates(
  planningDates: MinMaxPlanningDatesResultNotNullable[],
): MinMaxPlanningDatesResult {
  let plannedStart: DateTimeStr | null = null;
  let plannedEnd: DateTimeStr | null = null;
  for (const {plannedEnd: end, plannedStart: start} of planningDates) {
    plannedStart = minDate(plannedStart, start);
    plannedEnd = maxDate(plannedEnd, end);
  }

  if (plannedStart && plannedEnd) {
    return {plannedEnd, plannedStart};
  }
  return {plannedEnd: null, plannedStart: null};
}

export function useCalculatePlanningStartEnd(nodeIds: Uuid[], hasLoadedOnce: boolean): StartEndResult {
  const getNode = useSelector(getNodeSelector);
  const lastResult = useRef<StartEndResult>(startEndEmptyResult);
  const getMinMaxPlanningDates = useSelector(getMinMaxPlanningDatesSelector);

  return useMemo(() => {
    if (hasLoadedOnce) {
      let startDate = todayIsoString;
      let endDate = todayIsoString;
      for (const nodeId of nodeIds) {
        const node = getNode(nodeId);
        if (isPlanningDependencyNode(node) && node.planningDates.length) {
          const {plannedEnd, plannedStart} = getMinMaxPlanningDates(nodeId);
          startDate = minDate(startDate, isoDateTimeToIsoFormat(plannedStart));
          endDate = maxDate(endDate, isoDateTimeToIsoFormat(plannedEnd));
        }
        if (isPid(node) && node.dueDate) {
          startDate = minDate(startDate, node.dueDate);
          endDate = maxDate(endDate, node.dueDate);
        }
      }
      lastResult.current = {startDate, endDate};
    }
    return lastResult.current;
  }, [getMinMaxPlanningDates, getNode, hasLoadedOnce, nodeIds]);
}

export function getDaysFromStart(plannedStart: DateStr, startDate: dayjs.Dayjs): number {
  return getDayDiff(startDate, fromIsoFormat(plannedStart));
}

export function getWidth(plannedStart: DateStr, plannedEnd: DateStr): number {
  return getDayDiff(fromIsoFormat(plannedStart), fromIsoFormat(plannedEnd));
}

export function getDayDiff(plannedStart: dayjs.Dayjs, plannedEnd: dayjs.Dayjs): number {
  return plannedEnd.diff(plannedStart, 'd');
}

export function dateIsPlanned(
  planningDates: PlanningDatesList,
  date: DateTimeStr,
  ignoredId: Uuid | null = null,
): boolean {
  for (const {plannedEnd, plannedStart, id} of planningDates) {
    if (id !== ignoredId && plannedStart <= date && plannedEnd >= date) {
      return true;
    }
  }
  return false;
}

export function isInDateRange(
  outerPlannedStart: DateTimeStr | null,
  outerPlannedEnd: DateTimeStr | null,
  innerPlannedStart: DateTimeStr | null,
  innerPlannedEnd: DateTimeStr | null,
): boolean {
  if (!outerPlannedStart || !outerPlannedEnd || !innerPlannedStart || !innerPlannedEnd) {
    return false;
  }
  return (
    outerPlannedStart > innerPlannedStart ||
    outerPlannedEnd < innerPlannedStart ||
    outerPlannedStart > innerPlannedEnd ||
    outerPlannedEnd < innerPlannedEnd
  );
}

export function getMaxDateFromList(dates: DateStr[]): DateStr | null;
export function getMaxDateFromList(dates: DateTimeStr[]): DateTimeStr | null;
export function getMaxDateFromList(dates: DateStr[] | DateTimeStr[]): DateStr | DateTimeStr | null {
  return dates.sort().pop() || null;
}

export function getEndDateDiffBetweenDateLists(
  planningDates: MinMaxPlanningDatesResultNotNullable[],
  baselineDates: MinMaxPlanningDatesResultNotNullable[],
  getWorkMinutesAtDate: GetWorkingTimeAtDate,
): number | null {
  const maxPlanningEndDate = getMaxDateFromList(planningDates.map(({plannedEnd}) => plannedEnd));
  const maxBaselineEndDate = getMaxDateFromList(baselineDates.map(({plannedEnd}) => plannedEnd));

  if (!maxPlanningEndDate || !maxBaselineEndDate) {
    return null;
  }

  return workdaysBetween(
    fromIsoDateTimeFormat(maxPlanningEndDate),
    fromIsoDateTimeFormat(maxBaselineEndDate),
    getWorkMinutesAtDate,
  );
}
