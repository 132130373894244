import {useInspectorId} from '@octaved/hooks';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {cn, NodeIcon} from '@octaved/ui';
import classNames from 'classnames';
import * as React from 'react';
import {forwardRef, ReactElement, ReactNode, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {useNodeBillingStatus} from '../../../Modules/Hooks/NodeBillingStatus';
import {useNodeBackground} from '../../../Modules/Hooks/Nodes';
import {workPackageOptionsUiSelector} from '../../../Modules/Selectors/UiSelectors';
import {flowStyleContext} from '../../../Styles/StyleContext';
import NodeName from '../../Node/NodeName';
import FavoriteStar from '../../Pid/FavoriteStar';
import OpenInspector from '../../Pid/OpenInspector';
import LabelView from '../../Task/TaskListForNode/Task/TaskCell/LabelCell/LabelView';
import Path from './Path';
import Planning from './Planning';
import Progress from './Progress';
import StatusIcons from './StatusIcons';

export interface WpOrSwpTileProps {
  actionButton?: ReactElement;
  actionButtonAlwayVisible?: boolean;
  canOpenPidInspector?: boolean;
  canOpenPidInspectorWithSelect?: boolean;
  canShrink?: boolean;
  children?: ReactNode;
  className?: string;
  compact?: boolean;
  draggable?: boolean;
  focusFavoriteButton?: boolean;
  fullHeight?: boolean;
  isSelectable?: boolean;
  lessPadding?: boolean;
  noDetails?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onParentHoverCssClass?: string; //a parent css class upon a hover should mark this tile hovered
  opaque?: boolean;
  row?: boolean;
  selected?: boolean;
}

interface Props extends WpOrSwpTileProps {
  node: WorkPackage | SubWorkPackage;
}

export default forwardRef<HTMLDivElement, Props>(function WpOrSwpTile(
  {
    actionButton,
    actionButtonAlwayVisible,
    canOpenPidInspector = false,
    canOpenPidInspectorWithSelect = true,
    canShrink = false,
    children,
    className,
    compact,
    draggable,
    focusFavoriteButton = false,
    fullHeight,
    isSelectable,
    lessPadding,
    node,
    noDetails,
    onClick,
    onParentHoverCssClass,
    opaque,
    row,
    selected,
  }: Props,
  ref,
): ReactElement {
  const {
    ANIMATIONS: {FAST},
    COLORS: {BORDER, PAGES},
  } = useContext(flowStyleContext);
  const billingStatus = useNodeBillingStatus(node.id);
  const options = useSelector(workPackageOptionsUiSelector);
  const nodeBackgroundStyle = useNodeBackground(node.id);
  const cols = Object.values(options).filter(Boolean).length;
  const navigate = useNavigate();
  const inspectingId = useInspectorId();
  const {plannedStart} = useMinMaxPlanningDates(node.id);

  if (!actionButton && canOpenPidInspector) {
    actionButton = <OpenInspector id={node.id} />;
  }

  return (
    <div
      className={classNames(className, 'workPackageTile group/workPackageTile', billingStatus, {
        canShrink,
        compact,
        draggable,
        fullHeight,
        isSelectable,
        lessPadding,
        noDetails,
        opaque,
        selected,
        tileAsRow: row,
      })}
      onClick={(e) => {
        if (canOpenPidInspector && canOpenPidInspectorWithSelect && inspectingId && inspectingId !== node.id) {
          navigate(node.id);
        }
        if (onClick) {
          onClick(e);
        }
      }}
      ref={ref}
    >
      <div className={'tileColor'} style={nodeBackgroundStyle} />
      {actionButton && (
        <div
          className={cn(
            'absolute right-0.5 top-0.5',
            row && 'top-2.5',
            !actionButtonAlwayVisible &&
              'pointer-events-none opacity-0 group-hover/workPackageTile:pointer-events-auto group-hover/workPackageTile:opacity-100',
          )}
        >
          {actionButton}
        </div>
      )}
      <div className={'header'}>
        <div className={'pidName'}>
          <NodeIcon nodeType={node.nodeType} className={'-mt-0.5 mr-1 inline'} />
          <NodeName nodeId={node.id} />
        </div>
      </div>

      {options.showPath && <Path autoHeight={noDetails} id={node.id} />}

      {children}

      {!noDetails && (
        <>
          {options.showLabels && (!canShrink || node.labels.length > 0) && (
            <div className={'labels'}>{node.labels.length > 0 && <LabelView labels={node.labels} />}</div>
          )}

          {options.showPlanning && (!canShrink || plannedStart) && (
            <div className={'row planning'}>
              <Planning id={node.id} />
            </div>
          )}

          {(options.showStatusIcon || focusFavoriteButton) && (
            <div className={'footer'}>
              <div className={'row between'}>
                {options.showStatusIcon ? <StatusIcons node={node} /> : <div />}
                <div className={'row'}>
                  {options.showStatusIcon && <Progress node={node} />}
                  <div className={'favoriteIcon'}>
                    <FavoriteStar nodeId={node.id} toolTipPosition={'right center'} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .workPackageTile {
          font-weight: 400;
          border-radius: 3px;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: stretch;
          grid-gap: 8px;
          min-height: 75px;
          padding: 8px 10px 8px 16px;
          width: 100%;
          position: relative;
          transition:
            background-color ${FAST},
            box-shadow ${FAST},
            border-color ${FAST};
          border: 1px solid ${BORDER.NORMAL};
          background-color: var(--color-bg-workpackage, #fff);
          color: var(--wpTextColor);

          &.opaque {
            opacity: 0.5;
            filter: grayscale(0.5);
            background-color: rgba(0, 0, 0, 0.05);
          }

          &.tileAsRow {
            display: grid;
            grid-template-columns: repeat(${cols + 1}, minmax(200px, 280px));
            grid-gap: 20px;
            flex-direction: row;
            min-height: 48px;
            padding-right: 40px;

            & .footer {
              align-self: center;
            }
          }

          &.canShrink {
            min-height: auto;
          }
        }

        .tileColor {
          position: absolute;
          left: 5px;
          width: 3px;
          top: 5px;
          border-radius: 2px;
          bottom: 5px;
        }

        .isSelectable {
          cursor: pointer;
        }

        :global(.${onParentHoverCssClass}):hover .workPackageTile,
        .isSelectable:hover {
          background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.05));
        }

        :global(.${onParentHoverCssClass}):hover .selected, /* added to override the high specifity above */
        .selected {
          background-color: var(--color-bg-selected, #edf5fb);
          border-color: #aaa;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);
        }

        .header {
          display: flex;
          justify-content: space-between;
        }

        .pidName {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          padding-right: 14px;
          color: var(--color-font-workpackage-name, #111);
          height: 32px;

          .noDetails & {
            height: auto;
          }

          .canShrink & {
            height: auto;
            max-height: 32px;
          }

          .compact & {
            font-size: 12px;
            font-weight: 600;
            line-height: 130%;
            margin-bottom: 0;
          }
        }

        .internal,
        .freeOfCharge {
          --wpBorderStatusColor: ${PAGES.PROJECTS.WORKPACKAGE.FREE_OF_CHARGE_BORDER};
        }

        .isHighlightSearch {
          --wpBorderSearchColor: #e89902;
          --wpBorderSelectionColor: var(--wpBorderSearchColor);
        }

        .draggable {
          cursor: grab;
        }

        .pidName {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .footer {
          display: flex;
          align-items: flex-end;
          flex-grow: 1;
          height: 22px;
        }

        .row,
        .favoriteIcon {
          display: flex;
          align-items: center;
          grid-column-gap: 11px;
        }

        .between {
          justify-content: space-between;
          width: 100%;
        }

        .favoriteIcon :global(svg) {
          font-size: 1.3rem;
        }

        .labels {
          height: 16px;
        }

        .planning {
          font-size: 12px;
          color: var(--color-font-workpackage-minorDetails, #888);
          display: grid;
          grid-template-columns: max-content 40px;
          grid-column-gap: 11px;
          height: 19px;
        }

        .progress {
          width: 40px;
          text-align: right;
          font-size: 12px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
});
