import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {workdays} from '@octaved/planning/src/Calculations/WorkdayCalculations';
import {calculateMinMaxPlanningDatesInSubtreeSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {FormatDate, FormatDateFormats, NodeIcon} from '@octaved/ui';
import {formatCollapsingDateRange, fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';
import Placeholder from '../../../../PlaceholderText';

export interface ProjectPlanningProps {
  nodeId: Uuid;
  projectDueDate: DateStr | null;
}

export default function ProjectPlanning({
  frame: Frame,
  nodeId,
  projectDueDate,
  noLabel,
}: ProjectPlanningProps & FramedSectionOrTileProps): ReactElement | null {
  const minMax = useSelector(calculateMinMaxPlanningDatesInSubtreeSelector)(nodeId);
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const {t} = useTranslation();
  const workDays =
    minMax.plannedStart &&
    minMax.plannedEnd &&
    workdays(
      fromIsoDateTimeFormat(minMax.plannedStart),
      fromIsoDateTimeFormat(minMax.plannedEnd),
      getOrgWorkMinutesAtDate,
    );
  const canReadPlanning = useIsGranted('FLOW_NODE_READ_PLANNING', nodeId);
  if (!canReadPlanning) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.planning.label'} noLabel={noLabel}>
      <div className={'grid grid-cols-[max-content_1fr] gap-x-3'}>
        <div className={'flex items-center gap-x-1.5'}>
          <NodeIcon nodeType={EnumFlowNodeType.VALUE_PROJECT} />
          {t('nodeDetails:field.planning.projectDueDate')}:
        </div>
        <div>
          {projectDueDate ? (
            <FormatDate date={projectDueDate} format={FormatDateFormats.short_with_year} />
          ) : (
            <Placeholder text={t('nodeDetails:field.planning.projectDueDate.placeholder')} />
          )}
        </div>
        <div className={'flex items-center gap-x-1.5'}>
          <NodeIcon nodeType={EnumFlowNodeType.VALUE_WORK_PACKAGE} />
          {t('nodeDetails:field.planning.workPackagePlanning')}:
        </div>
        <div>
          {minMax.plannedStart && minMax.plannedEnd ? (
            <div>
              {formatCollapsingDateRange(
                fromIsoDateTimeFormat(minMax.plannedStart),
                fromIsoDateTimeFormat(minMax.plannedEnd),
                {
                  day: 'DD.',
                  dayMonth: 'DD.MM.',
                  full: 'DD.MM.YYYY',
                },
              )}{' '}
              ({workDays} {t('general:days', {count: workDays || 0})})
            </div>
          ) : (
            <Placeholder text={t('nodeDetails:field.planning.workPackagePlanning.placeholder')} />
          )}
        </div>
      </div>
    </Frame>
  );
}
