import {Uuid} from '@octaved/typescript/src/lib';
import {VerticalCollapsibleContent} from '@octaved/ui';
import {ReactElement, useContext} from 'react';
import {useIsUsingProjectFolders} from '../../Modules/Hooks/ProjectFolders';
import {flowStyleContext} from '../../Styles/StyleContext';
import ProjectFolderTree from './ProjectFolderTree';

interface Props {
  active?: boolean;
  collapsed?: boolean;
  selectedFolder: Uuid | null;
  selectFolder: (folderId: Uuid | null) => void;
  setCollapsed: (collapsed: boolean) => void;
}

export default function VerticalFolder({
  active,
  collapsed,
  setCollapsed,
  selectedFolder,
  selectFolder,
}: Props): ReactElement | null {
  const {
    COLORS: {BORDER},
  } = useContext(flowStyleContext);
  const hasMoreThanOneProjectFolder = useIsUsingProjectFolders();

  if (active && hasMoreThanOneProjectFolder) {
    return (
      <div className={'projectFolders'}>
        <VerticalCollapsibleContent collapsed={collapsed || false} setCollapsed={setCollapsed} defaultWidth={'250px'}>
          <div className={'folderView'}>
            <ProjectFolderTree selectedNode={selectedFolder} selectNode={selectFolder} />
          </div>
        </VerticalCollapsibleContent>

        {/*language=SCSS*/}
        <style jsx>{`
          .projectFolders {
            border-right: 1px solid ${BORDER.WEAK};
            position: relative;
            overflow: auto;
            display: flex;
            flex-shrink: 0;
            height: 100%;
            background: #fff;
          }

          .folderView {
            padding: 18px 16px;
          }
        `}</style>
      </div>
    );
  }

  return null;
}
