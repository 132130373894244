import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useTimeTrackingSelector} from '../../../Modules/Selectors/SettingSelectors';
import {useProgressColor} from '../../../WorkPackage/ProgressColor';
import {BillingTypeComponentProps} from '../Components';
import BillingTypeProgressBar from './BillingTypeProgressBar';

export default function BilledMax({
  sum: {billableHours, maxEffortHours},
  billingType,
  showProgressLabel,
  showBillingType,
  typeTranslationToken,
}: BillingTypeComponentProps): ReactElement | null {
  const {t} = useTranslation();
  const color = useProgressColor(billingType, billableHours, maxEffortHours, null);
  const usingTimeTracking = useSelector(useTimeTrackingSelector);

  if (maxEffortHours) {
    return (
      <>
        {usingTimeTracking && <BillingTypeProgressBar value={billableHours} total={maxEffortHours} color={color} />}

        {showProgressLabel && (
          <span>
            {usingTimeTracking &&
              t('general:xOfYHours', {
                count: Math.round(maxEffortHours),
                hours: formatDecimal(billableHours),
                total: formatDecimal(maxEffortHours),
              })}
            {!usingTimeTracking &&
              t('general:xHours', {
                count: Math.round(maxEffortHours),
                hours: formatDecimal(maxEffortHours),
              })}
          </span>
        )}
        {showBillingType && (
          <span>
            (<Trans i18nKey={typeTranslationToken} />)
          </span>
        )}
        {!usingTimeTracking && <div />}
      </>
    );
  }

  return null;
}
