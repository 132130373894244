import {ActionDispatcher} from '@octaved/store/src/Store';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import objectContains from '@octaved/validation/src/ObjectContains';
import {MouseEvent, RefObject, useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  taskListClosedGroupsSelector,
  taskListStateSelector,
  taskListVisibleColumnsSetSelector,
} from '../Selectors/Ui/TaskListSelectors';
import {FlowState} from '../State';
import {setUiState} from '../Ui';

export const taskListColumns = ['effort', 'planing', 'assignments', 'labels'] as const;
export type TaskListColumn = (typeof taskListColumns)[number];

export interface TaskListUiState {
  closedGroups: Record<Uuid, boolean>;
  taskSectionColor: Record<Uuid, string | null>;
  tile: {
    showLabels: boolean;
    showStatusIcons: boolean;
    taskPath: 'text' | 'color' | 'hidden';
  };
  visibleColumns: ReadonlyArray<TaskListColumn>;
}

export const initialState: TaskListUiState = {
  closedGroups: {},
  taskSectionColor: {},
  tile: {
    showLabels: false,
    showStatusIcons: true,
    taskPath: 'text',
  },
  visibleColumns: taskListColumns,
};

export function patchTaskListUiState(partialState: DeepPartial<TaskListUiState>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const currentValue = taskListStateSelector(getState());
    if (!objectContains(currentValue, partialState)) {
      dispatch(setUiState({taskList: partialState}));
    }
  };
}

export function toggleVisibleColumn(col: TaskListColumn, show?: boolean): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const visibleColumns = taskListVisibleColumnsSetSelector(state);
    const newSet = new Set(visibleColumns);
    const doShow = typeof show === 'boolean' ? show : !visibleColumns.has(col);
    if (doShow && !visibleColumns.has(col)) {
      newSet.add(col);
      dispatch(patchTaskListUiState({visibleColumns: [...newSet]}));
    } else if (!doShow && visibleColumns.has(col)) {
      newSet.delete(col);
      dispatch(patchTaskListUiState({visibleColumns: [...newSet]}));
    }
  };
}

export function useToggleVisibleColumn(): (col: TaskListColumn, show?: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (col: TaskListColumn, show?: boolean) => {
      dispatch(toggleVisibleColumn(col, show));
    },
    [dispatch],
  );
}

export function usePatchTaskListUiState(): (patch: DeepPartial<TaskListUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((partial) => dispatch(patchTaskListUiState(partial)), [dispatch]);
}

export function useShowTaskGroup(id: Uuid): {
  show: boolean;
  showRef: RefObject<boolean>;
  setShow: (show: boolean) => void;
  toggle: (event?: MouseEvent) => void;
} {
  const isClosed = useSelector((s: FlowState) => taskListClosedGroupsSelector(s)[id] ?? false);
  const show = !isClosed;
  const showRef = useRef(show);
  showRef.current = show;
  const patch = usePatchTaskListUiState();
  const setShow = useCallback((show: boolean): void => patch({closedGroups: {[id]: !show}}), [patch, id]);
  const toggle = useCallback(
    (event?: MouseEvent): void => {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      patch({closedGroups: {[id]: showRef.current}});
    },
    [patch, id],
  );

  return {show, showRef, setShow, toggle};
}
