import {MaybeUuid} from '@octaved/typescript/src/lib';
import {currentOrgUserSettingsSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {createSelector} from 'reselect';
import {PlanningSimulationSnapshot, PlanningSimulationSnapshots} from '../Modules/SimulationSnapshot';
import {selectedSimulationSnapshotIdSelector, simulationModeActiveSelector} from './UiSelectors';

export const planningSimulationSnapshotsSelector = createSelector(
  currentOrgUserSettingsSelector,
  (currentOrgUserSettings): PlanningSimulationSnapshots => currentOrgUserSettings?.planningSimulationSnapshots || [],
);

export const getPlanningSimulationSnapshotSelector = createSelector(
  planningSimulationSnapshotsSelector,
  (planningSimulationSnapshots) => (id: MaybeUuid) =>
    planningSimulationSnapshots.find((snapshot) => snapshot.id === id) || null,
);

export const getCurrentPlanningSimulationSnapshotSelector = createSelector(
  simulationModeActiveSelector,
  selectedSimulationSnapshotIdSelector,
  getPlanningSimulationSnapshotSelector,
  (
    simulationModeActive,
    selectedSimulationSnapshotId,
    getPlanningSimulationSnapshot,
  ): PlanningSimulationSnapshot | null => {
    if (simulationModeActive && selectedSimulationSnapshotId) {
      return getPlanningSimulationSnapshot(selectedSimulationSnapshotId);
    }
    return null;
  },
);
