import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, generateUuid} from '@octaved/utilities';
import {PlanningDependency} from '../../EntityInterfaces/PlanningDependency';
import {TYPE_FF, TYPE_FS, TYPE_SS} from '../../Selectors/PlanningDependencySelectors';
import UpdatePlanningState, {QueueItem} from './UpdatePlanningState';

export function updateDependentDependencies(state: UpdatePlanningState): void {
  let currentNode: QueueItem | undefined;
  while ((currentNode = state.getNextQueueItem())) {
    if (currentNode.type === 'start') {
      handleStartDate(currentNode.id, state);
    } else {
      handleEndDate(currentNode.id, state);
    }
  }
}

function handleStartDate(predecessorId: Uuid, state: UpdatePlanningState): void {
  const sucessors = getSuccessors(predecessorId, [TYPE_SS], state);
  for (const {successor: successorId} of sucessors) {
    if (!state.isNodeHandled(successorId)) {
      handleStartDateSuccessor(successorId, state);
    }
  }
}

function handleStartDateSuccessor(successorId: Uuid, state: UpdatePlanningState): void {
  const predecessors = getPredecessors(successorId, state);
  const planningNode = state.getNode(successorId);
  if (planningNode) {
    const {plannedStart, plannedEnd} = getStartEndDate(planningNode, state);
    const {maxPlannedStart, maxPlannedEnd} = getMaxDateForDependency(predecessors, state);

    if (hasDependencyType(predecessors, TYPE_FF)) {
      updateByStretch(successorId, maxPlannedStart, maxPlannedEnd, plannedStart!, plannedEnd!, state);
    } else {
      updateByMove(successorId, maxPlannedStart, plannedStart, state);
    }
  }
}

function handleEndDate(predecessorId: Uuid, state: UpdatePlanningState): void {
  const successors = getSuccessors(predecessorId, [TYPE_FS, TYPE_FF], state);
  for (const {successor: successorId, type} of successors) {
    if (!state.isNodeHandled(successorId)) {
      handleEndDateSuccessor(successorId, type, state);
    }
  }
}

function handleEndDateSuccessor(
  successorId: Uuid,
  type: EnumFlowPlanningDependencyType,
  state: UpdatePlanningState,
): void {
  const predecessors = getPredecessors(successorId, state);
  const {maxPlannedEnd, maxPlannedStart} = getMaxDateForDependency(predecessors, state);
  const planningNode = state.getNode(successorId);
  if (planningNode) {
    const {plannedStart, plannedEnd} = getStartEndDate(planningNode, state);
    if (type === TYPE_FS) {
      if (hasDependencyType(predecessors, TYPE_FF)) {
        updateByStretch(successorId, maxPlannedStart, maxPlannedEnd, plannedStart!, plannedEnd!, state);
      } else {
        updateByMove(successorId, maxPlannedStart, plannedStart, state);
      }
    } else if (type === TYPE_FF && plannedEnd !== maxPlannedEnd) {
      if (allDependencyHasType(predecessors, TYPE_FF)) {
        updateByMove(successorId, maxPlannedEnd, plannedEnd, state);
      } else {
        updateByStretch(successorId, maxPlannedStart, maxPlannedEnd, plannedStart!, plannedEnd!, state);
      }
    }
  }
}

function getMaxDateForDependency(
  predecessors: PlanningDependency[],
  state: UpdatePlanningState,
): {maxPlannedStart: DateTimeStr | null; maxPlannedEnd: DateTimeStr | null} {
  const startDates: DateTimeStr[] = [];
  const endDates: DateTimeStr[] = [];

  for (const {predecessor: predecessorId, offset, type} of predecessors) {
    const predecessor = state.getNode(predecessorId);
    if (predecessor) {
      for (const planningDateId of predecessor.planningDates) {
        const planningDate = state.getPlanningDate(planningDateId);
        if (planningDate) {
          if (type === TYPE_FS) {
            const adjustedPlannedEnd = state.ensureWorkDay(planningDate.plannedEnd, false);
            startDates.push(state.addWorkDays(adjustedPlannedEnd, offset + 1));
          } else if (type === TYPE_SS) {
            const adjustedPlannedStart = state.ensureWorkDay(planningDate.plannedStart, true);
            startDates.push(state.addWorkDays(adjustedPlannedStart, offset));
          } else if (type === TYPE_FF) {
            const adjustedPlannedEnd = state.ensureWorkDay(planningDate.plannedEnd, true);
            endDates.push(state.addWorkDays(adjustedPlannedEnd, offset));
          }
        }
      }
    }
  }
  const maxPlannedStart =
    (allDependencyHasType(predecessors, TYPE_SS) ? startDates.sort().shift() : startDates.sort().pop()) || null;
  const maxPlannedEnd = endDates.sort().pop() || null;
  return {maxPlannedStart, maxPlannedEnd};
}

function getStartEndDate(
  planningNode: NodeType,
  state: UpdatePlanningState,
): {plannedStart: DateTimeStr | null; plannedEnd: DateTimeStr | null} {
  const startDates: DateTimeStr[] = [];
  const endDates: DateTimeStr[] = [];

  for (const planningDateId of planningNode.planningDates) {
    const planningDate = state.getPlanningDate(planningDateId);
    if (planningDate) {
      startDates.push(planningDate.plannedStart);
      endDates.push(planningDate.plannedEnd);
    }
  }

  const plannedStart = startDates.sort().shift() || null;
  const plannedEnd = endDates.sort().pop() || null;

  return {plannedStart, plannedEnd};
}

function getSuccessors(
  id: Uuid,
  types: EnumFlowPlanningDependencyType[],
  state: UpdatePlanningState,
): PlanningDependency[] {
  const node = state.getNode(id);
  if (node) {
    const dependencies = node.planningSuccessors.map((successorId) => state.getPlanningDependency(successorId, id));
    return boolFilter(dependencies).filter(({type}) => types.includes(type));
  }
  return [];
}

function getPredecessors(id: Uuid, state: UpdatePlanningState): PlanningDependency[] {
  const node = state.getNode(id);
  if (node) {
    const dependencies = node.planningPredecessors.map((predecessorId) =>
      state.getPlanningDependency(id, predecessorId),
    );
    return boolFilter(dependencies);
  }
  return [];
}

function hasDependencyType(dependencies: PlanningDependency[], type: EnumFlowPlanningDependencyType): boolean {
  return dependencies.some(({type: currentType}) => currentType === type);
}

function allDependencyHasType(dependencies: PlanningDependency[], type: EnumFlowPlanningDependencyType): boolean {
  return dependencies.every(({type: currentType}) => currentType === type);
}

function updateByMove(
  id: Uuid,
  newDate: DateTimeStr | null,
  orgDate: DateTimeStr | null,
  state: UpdatePlanningState,
): void {
  if (!newDate) {
    throw Error(`'newDate' could not get calculated for '${id}'`);
  }
  if (newDate === orgDate) {
    return;
  }
  const node = state.getNode(id);
  if (node) {
    if (orgDate) {
      const diff = state.workdaysBetween(orgDate, newDate);

      for (const planningDateId of node.planningDates) {
        const planningDate = state.getPlanningDate(planningDateId);
        if (planningDate) {
          const newEndDate = state.addWorkDays(planningDate.plannedEnd, diff);
          const newStartDate = state.addWorkDays(planningDate.plannedStart, diff);
          state.setPlanningDate({...planningDate, plannedEnd: newEndDate, plannedStart: newStartDate});
        }
      }
    } else {
      const planningDateId = generateUuid();
      state.setPlanningDate({
        assignedNodeId: null,
        id: planningDateId,
        name: '',
        nodeId: id,
        plannedEnd: newDate,
        plannedStart: newDate,
      });
      state.setNode({...node, planningDates: [...node.planningDates, planningDateId]});
    }
    state.setHandledNode(id);
  }
}

function updateByStretch(
  id: Uuid,
  startDate: DateTimeStr | null,
  endDate: DateTimeStr | null,
  orgStartDate: DateTimeStr,
  orgEndDate: DateTimeStr,
  state: UpdatePlanningState,
): void {
  if (!startDate) {
    throw Error(`'startDate' could not get calculated for '${id}'`);
  }
  if (!endDate) {
    throw Error(`'endDate' could not get calculated for '${id}'`);
  }
  if (startDate === orgStartDate && endDate === orgEndDate) {
    return;
  }
  const diff = state.workdaysBetween(orgStartDate, startDate);
  const node = state.getNode(id);
  const fixEndDay = endDate;
  const planningDateIds = node?.planningDates || [];

  for (let i = 0; i < planningDateIds.length; ++i) {
    const planningDate = state.getPlanningDate(planningDateIds[i]);
    if (planningDate) {
      let newPlannedEnd = state.addWorkDays(planningDate.plannedEnd, diff);
      let newPlannedStart = state.addWorkDays(planningDate.plannedStart, diff);

      if (newPlannedStart > fixEndDay && i > 0) {
        //planningDate start after max end so it can be deleted
        state.removePlanningDate(planningDate.id);
      } else {
        if (newPlannedStart > fixEndDay) {
          //first planningDate start after max end, but we fix it in this case as we want a bar with at least one day
          newPlannedStart = fixEndDay;
        }
        if (newPlannedEnd > fixEndDay || planningDateIds.length - 1 === i) {
          //planningDate ends after fix or is last one
          newPlannedEnd = fixEndDay;
        }
        if (planningDate.plannedStart !== newPlannedStart || planningDate.plannedEnd !== newPlannedEnd) {
          state.setPlanningDate({...planningDate, plannedEnd: newPlannedEnd, plannedStart: newPlannedStart});
        }
      }
    }
  }

  state.setHandledNode(id);
}
