import {EnumFlowNodeRoleType, EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {
  bulkPatchNodeProjectRoleAssignments,
  getNodeProjectRoleAssignments,
  removeGroupNodeProjectRole,
  removeUserNodeProjectRole,
  setGroupNodeProjectRole,
  setUserNodeProjectRole,
} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, INVALIDATED, LOADED, LOADING} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {generateUuid} from '@octaved/utilities';
import {useSelector} from 'react-redux';
import {StoreNodeProjectRoleAssignments} from '../../EntityInterfaces/RoleAssignments/ProjectRoleAssignments';
import {
  FLOW_COPY_PID_REQUEST,
  FLOW_CREATE_NODE_REQUEST,
  FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS,
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_FAILURE,
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_REQUEST,
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_SUCCESS,
  FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_FAILURE,
  FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_REQUEST,
  FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_SUCCESS,
  FLOW_REMOVE_NODE_PROJECT_ROLE_FAILURE,
  FLOW_REMOVE_NODE_PROJECT_ROLE_REQUEST,
  FLOW_REMOVE_NODE_PROJECT_ROLE_SUCCESS,
  FLOW_SET_NODE_PROJECT_ROLE_FAILURE,
  FLOW_SET_NODE_PROJECT_ROLE_REQUEST,
  FLOW_SET_NODE_PROJECT_ROLE_SUCCESS,
} from '../ActionTypes';
import {RoleEvent} from '../Events';
import {
  getProjectRoleAssignmentsLoadedOnceSelector,
  nodeProjectRoleAssignmentEntrySelector,
  nodeProjectRoleAssignmentsStateSelector,
} from '../Selectors/RoleAssignments/NodeProjectRoleAssignmentSelectors';
import {FlowState} from '../State';
import {
  BulkPatchAction,
  createBulkPatchNodeRoleAssignmentsAction,
  createLoadNodeRoleAssignmentsAction,
  reduceNodeRoleAssignmentLoadSuccess,
  unitToIdName,
} from './NodeRoleAssignments';

export interface RemoveProjectRoleAssignment {
  entryId: Uuid;
  unitId: Uuid;
  unitType: SimpleUnitType;
}

export interface SetProjectRoleAssignment extends RemoveProjectRoleAssignment {
  roleId: Uuid;
}

export type BulkPatchProjectRolesAction = BulkPatchAction<
  SetProjectRoleAssignment,
  RemoveProjectRoleAssignment,
  | typeof FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_FAILURE
  | typeof FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_REQUEST
  | typeof FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_SUCCESS
>;

export interface SetNodeProjectRoleAssignmentAction {
  nodeId: Uuid;
  requestId: Uuid;
  set: SetProjectRoleAssignment;
  type:
    | typeof FLOW_SET_NODE_PROJECT_ROLE_FAILURE
    | typeof FLOW_SET_NODE_PROJECT_ROLE_REQUEST
    | typeof FLOW_SET_NODE_PROJECT_ROLE_SUCCESS;
}

export interface RemoveNodeProjectRoleAssignmentAction {
  nodeId: Uuid;
  remove: RemoveProjectRoleAssignment;
  requestId: Uuid;
  type:
    | typeof FLOW_REMOVE_NODE_PROJECT_ROLE_FAILURE
    | typeof FLOW_REMOVE_NODE_PROJECT_ROLE_REQUEST
    | typeof FLOW_REMOVE_NODE_PROJECT_ROLE_SUCCESS;
}

const initialState = {};

const reducerMap = new Map();
reducerMap.set(FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_SUCCESS, reduceNodeRoleAssignmentLoadSuccess);
export const nodeProjectRoleAssignmentsReducer = ReduceFromMap<StoreNodeProjectRoleAssignments>(reducerMap);

const clear = (): EntityStates => initialState;
const stateReducerMap = new Map();
stateReducerMap.set(
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_REQUEST,
  createTimestampReducer('options.data.nodeIds', LOADING),
);
stateReducerMap.set(
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_SUCCESS,
  createTimestampReducer('options.data.nodeIds', LOADED),
);
stateReducerMap.set(FLOW_INVALIDATE_NODE_PROJECT_ROLE_ASSIGNMENTS, createTimestampReducer('nodeIds', INVALIDATED));
stateReducerMap.set('UserGroupDelegationEvent', clear);
stateReducerMap.set('flow.RoleRemovedEvent', (state: EntityStates, action: RoleEvent): EntityStates => {
  if (action.roleType === EnumFlowRoleType.VALUE_PROJECT) {
    return initialState;
  }
  return state;
});

//Mark the role assignments loading temporarily between request and ws-event to prevent premature loading:
stateReducerMap.set(FLOW_COPY_PID_REQUEST, createTimestampReducer('copyNode.id', LOADING));
stateReducerMap.set('flow.PidCopiedEvent', createTimestampReducer('copiedNodeId', INVALIDATED));

//Mark the role assignments loading temporarily between request and ws-event to prevent premature loading:
addMultiToReducerMap(stateReducerMap, FLOW_CREATE_NODE_REQUEST, createTimestampReducer('options.data.id', LOADING));
addMultiToReducerMap(
  stateReducerMap,
  [
    'flow.GroupCreatedEvent',
    'flow.NodeCreatedEvent',
    'flow.ProjectCreatedEvent',
    'flow.SubWorkPackageCreatedEvent',
    'flow.TaskCreatedEvent',
    'flow.WorkPackageCreatedEvent',
  ],
  createTimestampReducer('nodeId', INVALIDATED),
);
addMultiToReducerMap(
  stateReducerMap,
  ['flow.NodeRestoredFromTrashEvent'],
  createTimestampReducer('restoredNodeIds', INVALIDATED),
);
export const nodeProjectRoleAssignmentsStateReducer = ReduceFromMap(stateReducerMap, initialState);

export const loadNodeProjectRoleAssignments = createLoadNodeRoleAssignmentsAction(
  nodeProjectRoleAssignmentsStateSelector,
  getNodeProjectRoleAssignments,
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_FAILURE,
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_REQUEST,
  FLOW_LOAD_NODE_PROJECT_ROLE_ASSIGNMENTS_SUCCESS,
);

export function setNodeProjectRoleAssignment(
  nodeId: Uuid,
  entryId: Uuid,
  unitType: SimpleUnitType,
  unitId: Uuid,
  roleId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const asmt = nodeProjectRoleAssignmentEntrySelector(getState())(nodeId, entryId);
    if (asmt && (asmt.unitId !== unitId || asmt.unitType !== unitType)) {
      throw new Error('Not allowed to change the unit for an entry!');
    }
    if (!asmt || asmt.isInherited || asmt.flowRoleId !== roleId) {
      const set: SetProjectRoleAssignment = {entryId, unitId, unitType, roleId};
      await dispatch({
        nodeId, //for optimistic reducer
        set, //for optimistic reducer
        [CALL_API]: {
          endpoint: {
            [SimpleUnitType.group]: setGroupNodeProjectRole,
            [SimpleUnitType.user]: setUserNodeProjectRole,
          }[unitType],
          method: 'put',
          options: {urlParams: {nodeId, entryId, [unitToIdName[unitType]]: unitId, roleId}},
          throwNetworkError: true,
          types: {
            failureType: FLOW_SET_NODE_PROJECT_ROLE_FAILURE,
            requestType: FLOW_SET_NODE_PROJECT_ROLE_REQUEST,
            successType: FLOW_SET_NODE_PROJECT_ROLE_SUCCESS,
          },
        },
        requestId: generateUuid(), //for optimistic reducer
      });
    }
  };
}

export function removeNodeProjectRoleAssignment(
  nodeId: Uuid,
  entryId: Uuid,
  unitType: SimpleUnitType,
  unitId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const currentEntry = nodeProjectRoleAssignmentEntrySelector(getState())(nodeId, entryId);
    if (currentEntry) {
      const remove: RemoveProjectRoleAssignment = {entryId, unitId, unitType};
      await dispatch({
        nodeId, //for optimistic reducer
        remove, //for optimistic reducer
        [CALL_API]: {
          endpoint: {
            [SimpleUnitType.group]: removeGroupNodeProjectRole,
            [SimpleUnitType.user]: removeUserNodeProjectRole,
          }[unitType],
          method: 'del',
          options: {urlParams: {nodeId, entryId, [unitToIdName[unitType]]: unitId}},
          throwNetworkError: true,
          types: {
            failureType: FLOW_REMOVE_NODE_PROJECT_ROLE_FAILURE,
            requestType: FLOW_REMOVE_NODE_PROJECT_ROLE_REQUEST,
            successType: FLOW_REMOVE_NODE_PROJECT_ROLE_SUCCESS,
          },
        },
        requestId: generateUuid(), //for optimistic reducer
      });
    }
  };
}

export const bulkPatchNodeProjectRoles = createBulkPatchNodeRoleAssignmentsAction<
  SetProjectRoleAssignment,
  RemoveProjectRoleAssignment
>(
  EnumFlowNodeRoleType.VALUE_PROJECT,
  bulkPatchNodeProjectRoleAssignments,
  FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_FAILURE,
  FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_REQUEST,
  FLOW_PATCH_MULTI_NODE_PROJECT_ROLES_SUCCESS,
);

export function useLoadNodeProjectRoleAssignments(nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>): void {
  useStoreEffect(
    (dispatch) => dispatch(loadNodeProjectRoleAssignments([...nodeIds])),
    [nodeIds],
    nodeProjectRoleAssignmentsStateSelector,
  );
}

export function useNodeProjectRoleAssignmentsLoadedOnce(nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>): boolean {
  return useSelector((s: FlowState) => getProjectRoleAssignmentsLoadedOnceSelector(s)([...nodeIds]));
}
