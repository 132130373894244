import {SupportedLanguage, useSupportedLanguageFromContext} from '@octaved/i18n/src/Language/Languages';
import {useMemo} from 'react';

//This is probably a subset of Flow's supported languages:
const websiteLanguages = ['en', 'de'] as const;
export type WebsiteLanguage = (typeof websiteLanguages)[number];

export function isWebsiteLanguage(code: SupportedLanguage): code is WebsiteLanguage {
  return websiteLanguages.includes(code as WebsiteLanguage);
}

export function getWebsiteLanguage(code: SupportedLanguage): WebsiteLanguage {
  return isWebsiteLanguage(code) ? code : 'en';
}

export function useWebsiteLanguageFromContext(): WebsiteLanguage {
  return getWebsiteLanguage(useSupportedLanguageFromContext());
}

export const websiteLinks = {
  contact: {
    de: 'https://flow.octaved.com/kontakt',
    en: 'https://flow.octaved.com/en/contact',
  },
  dataPolicy: {
    de: 'https://flow.octaved.com/datenschutz',
    en: 'https://flow.octaved.com/en/data-policy',
  },
  impressum: {
    de: 'https://flow.octaved.com/impressum',
    en: 'https://flow.octaved.com/en/about',
  },
  menuItemsAndPurpose: {
    de: 'https://flow.octaved.com/tutorials/menuepunkte-kurz-erklaert',
    en: 'https://flow.octaved.com/en/tutorials/menu-items-explained-briefly',
  },
  prices: {
    de: 'https://flow.octaved.com/preise',
    en: 'https://flow.octaved.com/en/prices',
  },
  tutorials: {
    de: 'https://flow.octaved.com/tutorials',
    en: 'https://flow.octaved.com/en/tutorials',
  },
  userAgreement: {
    de: 'https://flow.octaved.com/nutzungsvereinbarung',
    en: 'https://flow.octaved.com/en/user-agreement',
  },
} satisfies Record<string, Record<WebsiteLanguage, string>>;

type Resolved = Record<keyof typeof websiteLinks, string>;

export function useWebsiteLinks(): Resolved {
  const language = useWebsiteLanguageFromContext();
  return useMemo(() => {
    return Object.fromEntries(
      Object.entries(websiteLinks).map(([key, value]) => [key, (value as Record<WebsiteLanguage, string>)[language]]),
    ) as Resolved;
  }, [language]);
}
