import {error} from '@octaved/env/src/Logger';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {DeepPartial} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import cloneDeep from 'lodash/cloneDeep';
import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';

export type ObjectSnapshotPatch<O> = (partial: DeepPartial<O> | ((s: O) => DeepPartial<O>)) => void;

export interface ObjectSnapshot<O, S> {
  changeSet: DeepPartial<O>;
  hasChanges: boolean;
  patch: ObjectSnapshotPatch<O>;
  refs: {
    //these refs contain the latest values immediately - this helps if you need to patch and save at once
    changeSet: MutableRefObject<DeepPartial<O>>;
    snapshot: MutableRefObject<S>;
    hasChanges: MutableRefObject<boolean>;
  };

  /**
   * @param refreshFromOriginal if true, fetches the fresh original and updates the snapshot with it. If you set this
   *                              make sure the getOriginal-callback can retrieve an up-to-date version, e.g. fresh
   *                              from the store. A value from a useSelector will probably be out of date!
   */
  reset: (refreshFromOriginal?: boolean) => void;

  snapshot: S;
}

function prefixKey(key: string): string {
  return `flow_objectSnapshot_${key}`;
}

function getInitialChangeSet<O>(localStorageKey?: string): () => DeepPartial<O> {
  if (localStorageKey) {
    return () => {
      const item = localStorage.getItem(prefixKey(localStorageKey));
      try {
        const parsed = item && JSON.parse(item);
        if (parsed && parsed.changeSet) {
          return parsed.changeSet;
        }
      } catch (e) {
        error(e);
      }
      return {};
    };
  }
  return () => ({});
}

function createSnapshot<O>(getOriginal: () => O | undefined, changeSet: DeepPartial<O>): O | undefined {
  const original = getOriginal();
  if (original) {
    const copy = cloneDeep<O>(original);
    return mergeStates<O>(copy, changeSet);
  }
  return undefined;
}

export function isObjectSnapshotReady<O>(
  objectSnapshot: ObjectSnapshot<O, O | undefined>,
): objectSnapshot is ObjectSnapshot<O, O> {
  return typeof objectSnapshot.snapshot !== 'undefined';
}

interface ObjectSnapshotOptions {
  localStorageKey?: string;
  resetOn?: unknown;
}

export function useObjectSnapshot<O>(getOriginal: () => O, options?: ObjectSnapshotOptions): ObjectSnapshot<O, O>;
export function useObjectSnapshot<O>(
  getOriginal: () => O | undefined,
  options?: ObjectSnapshotOptions,
): ObjectSnapshot<O, O | undefined>;
export function useObjectSnapshot<O>(
  getOriginal: () => O | undefined,
  {localStorageKey, resetOn}: ObjectSnapshotOptions = {},
): ObjectSnapshot<O, O | undefined> {
  const getOriginalRef = useRef(getOriginal);
  getOriginalRef.current = getOriginal;
  const [changeSet, setChangeSet] = useState<DeepPartial<O>>(getInitialChangeSet(localStorageKey));
  const [snapshot, setSnapshot] = useState<O | undefined>(() => createSnapshot(getOriginalRef.current, changeSet));
  const [hasChanges, setHasChanges] = useState(false);
  const changeSetRef = useRef(changeSet);
  const snapshotRef = useRef(snapshot);
  const originalRef = useRef(snapshot);
  const hasChangesRef = useRef(false);
  const lastResetOnRef = useRef(resetOn);

  useEffect(() => {
    void getOriginal; // has to trigger always, to initially fill the snapshot
    if (!snapshot) {
      const snap = createSnapshot(getOriginalRef.current, changeSet);
      if (snap) {
        snapshotRef.current = snap;
        originalRef.current = snap;
        setSnapshot(snap);
      }
    }
  }, [changeSet, getOriginal, snapshot]);

  const patch = useCallback(
    (partial: DeepPartial<O> | ((s: O) => DeepPartial<O>)): void => {
      const prev = snapshotRef.current;
      if (prev) {
        const part: DeepPartial<O> = typeof partial === 'function' ? partial(prev) : partial;
        const newSnapshot = mergeStates<O>(prev, part);
        if (snapshotRef.current !== newSnapshot) {
          snapshotRef.current = newSnapshot;
          setSnapshot(newSnapshot);
          const newChangeSet = mergeStates(changeSetRef.current, part as DeepPartial<DeepPartial<O>>);
          changeSetRef.current = newChangeSet;
          setChangeSet(newChangeSet);
          hasChangesRef.current = JSON.stringify(snapshotRef.current) !== JSON.stringify(originalRef.current);
          setHasChanges(hasChangesRef.current);
          if (localStorageKey) {
            localStorage.setItem(
              prefixKey(localStorageKey),
              JSON.stringify({changedOn: unix(), changeSet: newChangeSet}),
            );
          }
        }
      }
    },
    [localStorageKey],
  );

  const reset = useCallback(
    (refreshFromOriginal = false) => {
      const newChangeSet = {};
      changeSetRef.current = newChangeSet;
      setChangeSet(newChangeSet);
      hasChangesRef.current = false;
      setHasChanges(hasChangesRef.current);
      if (localStorageKey) {
        localStorage.removeItem(prefixKey(localStorageKey));
      }
      if (refreshFromOriginal) {
        //this only works if getOriginal can retrieve the up-to-date version!
        const snap = createSnapshot(getOriginalRef.current, newChangeSet);
        if (snap) {
          snapshotRef.current = snap;
          setSnapshot(snap);
        }
      }
      originalRef.current = snapshotRef.current;
    },
    [localStorageKey],
  );

  useEffect(() => {
    if (lastResetOnRef.current !== resetOn) {
      lastResetOnRef.current = resetOn;
      reset(true);
    }
  }, [resetOn, reset]);

  const refs = useMemo(() => ({changeSet: changeSetRef, hasChanges: hasChangesRef, snapshot: snapshotRef}), []);

  return useMemo(
    () => ({changeSet, hasChanges, patch, refs, reset, snapshot}),
    [changeSet, hasChanges, patch, refs, reset, snapshot],
  );
}
