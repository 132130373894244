import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import objectContains from '@octaved/validation/src/ObjectContains';
import {Nodes} from '../EntityInterfaces/Nodes';
import {
  CommonPidNodePatchData,
  GroupPatchData,
  Pid,
  ProjectPatchData,
  WorkPackagePatchData,
} from '../EntityInterfaces/Pid';
import {
  FLOW_COPY_PID_SUCCESS,
  FLOW_CREATE_GROUP_SUCCESS,
  FLOW_CREATE_PROJECT_SUCCESS,
  FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
  FLOW_REARRANGE_PIDS_FAILURE,
  FLOW_REARRANGE_PIDS_REQUEST,
  FLOW_REARRANGE_PIDS_SUCCESS,
} from './ActionTypes';
import {WorkPackagesFlagsChangedEvent} from './Events';
import {patchGroupDeprecated, transformGroupToPatchData} from './Groups';
import {nodeEntityReducers} from './Nodes';
import {patchProjectDeprecated, transformProjectToPatchData} from './Projects';
import {isGroup, isProject, isWorkPackage} from '../Node/NodeIdentifiers';
import {FlowState} from './State';
import {patchWorkPackageDeprecated, transformWorkPackageToPatchData} from './WorkPackages'; //#region
// reducer

//#region reducer
nodeEntityReducers.add(
  [FLOW_CREATE_PROJECT_SUCCESS, FLOW_CREATE_GROUP_SUCCESS, FLOW_CREATE_WORK_PAGKAGE_SUCCESS, FLOW_COPY_PID_SUCCESS],
  (state: Nodes, {response}: ServerResponseAction<{id: Uuid; pid: string}>): Nodes => {
    if (response) {
      const {id, pid} = response;
      return mergeStates(state, {[id]: {pid}});
    }
    return state;
  },
);

nodeEntityReducers.add(
  'flow.WorkPackagesFlagsChangedEvent',
  (state, {nodeIds, flags}: WorkPackagesFlagsChangedEvent) => {
    let changed = false;
    const newState = {...state};
    nodeIds.forEach((id) => {
      const node = newState[id];
      if (isWorkPackage(node) && !objectContains(node, flags)) {
        newState[id] = {...node, ...flags};
        changed = true;
      }
    });
    return changed ? newState : state;
  },
);

//#endregion

export function hasPidHours({billingType}: {billingType: EnumFlowPidBillingType | null}): boolean {
  return (
    billingType !== null &&
    billingType !== EnumFlowPidBillingType.VALUE_EFFORT &&
    billingType !== EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE
  );
}

export function hasPriceCategory({billingType}: {billingType: EnumFlowPidBillingType | null}): boolean {
  return (
    billingType !== null &&
    billingType !== EnumFlowPidBillingType.VALUE_FIXED_PRICE &&
    billingType !== EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE
  );
}

export function hasPrice({billingType}: {billingType: EnumFlowPidBillingType | null}): boolean {
  return billingType === EnumFlowPidBillingType.VALUE_FIXED_PRICE;
}

export function transformAnyCommonPidNodeToPidData<P extends CommonPidNodePatchData>(pid: Pid): P {
  if (isProject(pid)) {
    return transformProjectToPatchData(pid) as unknown as P;
  }
  if (isGroup(pid)) {
    return transformGroupToPatchData(pid) as unknown as P;
  }
  if (isWorkPackage(pid)) {
    return transformWorkPackageToPatchData(pid) as unknown as P;
  }
  throw new Error('Invalid nodeType');
}

/**
 * @deprecated use auto-saving and local form validation
 */
export function patchAnyCommonPidNode<P extends CommonPidNodePatchData>(
  pid: Pid,
  partial: Partial<P>,
): ActionDispatcher<Promise<boolean> | boolean, FlowState> {
  if (isProject(pid)) {
    return patchProjectDeprecated(pid.id, partial as Partial<ProjectPatchData>);
  }
  if (isGroup(pid)) {
    return patchGroupDeprecated(pid.id, partial as Partial<GroupPatchData>);
  }
  if (isWorkPackage(pid)) {
    return patchWorkPackageDeprecated(pid.id, partial as Partial<WorkPackagePatchData>);
  }
  throw new Error('Invalid nodeType');
}

export function rearrangePids(
  parentPidId: Uuid,
  movedPidId: Uuid,
  nodeIds: Uuid[],
): ActionDispatcher<boolean, FlowState> {
  return (dispatch) => {
    dispatch({
      nodeIds,
      [CALL_API]: {
        endpoint: routes.rearrangePids,
        method: 'patch',
        options: {
          data: {nodeIds},
          urlParams: {
            movedPidId,
            parentPidId,
          },
        },
        types: {
          failureType: FLOW_REARRANGE_PIDS_FAILURE,
          requestType: FLOW_REARRANGE_PIDS_REQUEST,
          successType: FLOW_REARRANGE_PIDS_SUCCESS,
        },
      },
    });
    return true;
  };
}
