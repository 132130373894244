import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {createInheritingReducer} from '@octaved/utilities/src/Search/SearchReducers';
import {TaskCreatedEvent} from '../Events';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {getNodeAncestrySelector} from '../Selectors/NodeTreeSelectors';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<TaskCreatedEvent>('flow.TaskCreatedEvent', {
  assignedAnyProjectRole: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedAnyProjectRole', parentNodeId, nodeId),
  }),
  assignedProjectRoleId: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedProjectRoleId', parentNodeId, nodeId),
  }),
  assignedProjectRoleType: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedProjectRoleType', parentNodeId, nodeId),
  }),
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored', //tasks can't be favorites
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: 'ignored',
  hasTimeBasedPredecessor: 'ignored',
  isArchived: 'ignored',
  labelId: 'clearIfNotInitiator', //new node can be created with labels
  mrIsActive: 'ignored',
  name: 'clear', //name not exposed in event
  nodeNotPlanned: ({nodeId}) => ({
    results: (s) => reduceAddOptional(s, nodeId, 'nodeNotPlanned'),
  }),
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: ({nodeId}) => ({
    results: (s) => reduceAddOptional(s, nodeId, 'nodeType', EnumFlowNodeType.VALUE_TASK),
  }),
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: 'clear',
  responsibleByAny: ({definesOwnResponsible, parentNodeId, nodeId}) =>
    definesOwnResponsible ? 'clear' : {results: createInheritingReducer('responsibleByAny', parentNodeId, nodeId)},
  responsibleUnitId: ({definesOwnResponsible, parentNodeId, nodeId}) =>
    definesOwnResponsible ? 'clear' : {results: createInheritingReducer('responsibleUnitId', parentNodeId, nodeId)},
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: ({parentNodeId}, fullState) => {
    const {userNode, workPackage} = getNodeAncestrySelector(fullState)(parentNodeId, true);
    if (workPackage) {
      //Only auto chained tasks can be blocked upon creation:
      return workPackage.isAutoChainActive ? 'clearIfNotInitiator' : 'ignored';
    } else {
      //else we don't know and must clear
      return userNode ? 'ignored' : 'clearIfNotInitiator';
    }
  },
  taskCompletedInDateRange: 'clearIfNotInitiator', //not exposed in event
  taskStatus: 'clearIfNotInitiator', //not exposed in event
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
