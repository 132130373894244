import {GroupPatchedEvent} from '../Events';
import {responsibleProps} from '../ResponsibleNode';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<GroupPatchedEvent>('flow.GroupPatchedEvent', {
  assignedAnyProjectRole: 'ignored',
  assignedProjectRoleId: 'ignored',
  assignedProjectRoleType: 'ignored',
  bpWaitingForMyResponse: 'ignored',
  customerId: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  customerIsBillable: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  customerName: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  customerNumber: ({patchedKeys}) => (patchedKeys.includes('flowCustomer') ? 'clear' : 'ignored'),
  favorite: 'ignored',
  grIsClosed: ({patchedKeys}) => (patchedKeys.includes('isClosed') ? 'clear' : 'ignored'),
  grIsLocked: ({patchedKeys}) => (patchedKeys.includes('isLocked') ? 'clear' : 'ignored'),
  grType: ({patchedKeys}) => (patchedKeys.includes('groupType') ? 'clear' : 'ignored'),
  hasLogicalPredecessor: 'ignored',
  hasTimeBasedPredecessor: 'ignored',
  isArchived: 'ignored',
  labelId: ({patchedKeys}) => (patchedKeys.includes('labels') ? 'clear' : 'ignored'),
  mrIsActive: 'ignored',
  name: ({patchedKeys}) => (patchedKeys.includes('name') ? 'clear' : 'ignored'),
  nodeNotPlanned: 'ignored',
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: 'ignored',
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: ({patchedKeys}) => (patchedKeys.includes('referenceNumber') ? 'clear' : 'ignored'),
  responsibleByAny: ({patchedKeys}) => (responsibleProps.some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored'),
  responsibleUnitId: ({patchedKeys}) => (responsibleProps.some((k) => patchedKeys.includes(k)) ? 'clear' : 'ignored'),
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: 'ignored',
  taskCompletedInDateRange: 'ignored',
  taskStatus: 'ignored',
  timeControlExceeded: ({patchedKeys}) => (patchedKeys.includes('timeControl') ? 'clear' : 'ignored'),
  timeControlNotStarted: ({patchedKeys}) => (patchedKeys.includes('timeControl') ? 'clear' : 'ignored'),
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
