import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {NodeSearch, NodeSearchCondition} from '../../../../EntityInterfaces/NodeSearch';
import {projectsMainProjectTreeApiSelector} from '../../../Selectors/UiPages/ProjectsSelector';
import {useCombinedNodeSearches} from '../../NodeSearch';

export type PrognosisLevel = NodeSearch['wpTaskTimePrognosis'] | 'noEffortLimits';
type IdsRecord = Record<PrognosisLevel, ReadonlyArray<Uuid>>;

export interface PrognosisIds extends IdsRecord {
  isLoading: boolean;
}

export const prognosisLevels: PrognosisLevel[] = [
  'exceedsRemainingEffort',
  'enoughTimeLeft',
  'noEffortLimits',
  'noTaskTime',
];

export function getTaskPrognosisQuery(level: PrognosisLevel): NodeSearchCondition {
  return level === 'noEffortLimits' ? ['wpTimeTrackingLimit', 'none'] : ['wpTaskTimePrognosis', level];
}

export function useWpTaskTimePrognosisInSubtree(nodeId: Uuid): PrognosisIds {
  const {workPackageIds} = useSelector(projectsMainProjectTreeApiSelector);
  const queries = prognosisLevels.map<NodeSearchCondition>((level) => ({
    and: [withDescendants(nodeId, true), {fixResult: workPackageIds}, getTaskPrognosisQuery(level)],
  }));
  const results = useCombinedNodeSearches({trackIsLoading: true}, ...queries);
  const isLoading = results.reduce((acc, {isLoading}) => acc || isLoading, false);
  const ids = useMemo(
    () => {
      return prognosisLevels.reduce<IdsRecord>((acc, level, idx) => {
        acc[level] = results[idx].ids;
        return acc;
      }, {} as IdsRecord);
    },
    // dependency on all ids, the full results-array cannot be used:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    results.map(({ids}) => ids),
  );
  return {isLoading, ...useLoadedValue(isLoading, ids)};
}

export function useHighestTaskTimePrognosisLevel(prognosisIds: PrognosisIds): PrognosisLevel {
  for (const level of prognosisLevels) {
    if (prognosisIds[level].length) {
      return level;
    }
  }
  return 'noEffortLimits';
}
