import {EntityState} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {KanbanBoard, KanbanBoards} from '../../EntityInterfaces/KanbanBoards';
import {FlowState} from '../State';
import {getAllAncestorsIdsSelector} from './NodeTreeSelectors';

export enum KanbanBoardScope {
  projects = 'useForProjects',
  groups = 'useForGroups',
  workPackages = 'useForWorkPackages',
  tasks = 'useForTasks',
}

export const kanbanBoardEntityStatesSelector = (state: FlowState): EntityState => state.entityStates.kanbanBoard;

export const kanbanBoardEntitiesSelector = (state: FlowState): KanbanBoards => state.entities.kanbanBoard;
export const kanbanBoardSelector = createSelector(
  kanbanBoardEntitiesSelector,
  (kanbanBoards) =>
    (id: Uuid | null): KanbanBoard | undefined =>
      kanbanBoards[id!],
);

export const getKanbanBoardsForProjectFolderSelector = createSelector(
  kanbanBoardEntitiesSelector,
  getAllAncestorsIdsSelector,
  (kanbanBoardEntities, getAllAncestorsIds) =>
    memoize((projectFolderId: Uuid | null | undefined): KanbanBoard[] => {
      if (projectFolderId) {
        const parentIds = getAllAncestorsIds(projectFolderId, true);
        return boolFilter(Object.values(kanbanBoardEntities)).filter(({rootFolder}) => parentIds.has(rootFolder));
      }
      return [];
    }),
);

export const getKanbanBoardsScopedForProjectFolderSelector = createSelector(
  getKanbanBoardsForProjectFolderSelector,
  (getKanbanBoardsForProjectFolder) =>
    memoize(
      (scope: KanbanBoardScope, projectFolderId: Uuid | null): KanbanBoard[] => {
        const labels = projectFolderId ? getKanbanBoardsForProjectFolder(projectFolderId) : [];
        return labels.filter((kanban) => kanban[scope]);
      },
      (scopes: KanbanBoardScope, projectFolderId: Uuid | null) => `${scopes}-${projectFolderId}`,
    ),
);

export const hasKanbanBordsScopedForProjectFolderSelector = createSelector(
  getKanbanBoardsScopedForProjectFolderSelector,
  (getKanbanBoardsScopedForProjectFolder) => (scope: KanbanBoardScope, projectFolderId: Uuid | null) =>
    getKanbanBoardsScopedForProjectFolder(scope, projectFolderId).length > 0,
);
