import {useContext, useMemo} from 'react';
import {UNSAFE_RouteContext} from 'react-router';
import {Location, RouteMatch, useLocation} from 'react-router-dom';

interface ExtendedLocation extends Location {
  matches: RouteMatch<string>[];
}

export function useExtendedLocation(): ExtendedLocation {
  const {matches} = useContext(UNSAFE_RouteContext);
  const location = useLocation();
  return useMemo<ExtendedLocation>(() => ({...location, matches}), [location, matches]);
}
