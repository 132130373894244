import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import {Uuid} from '@octaved/typescript/src/lib';
import {StopClickPropagation} from '@octaved/ui';
import {ReactElement} from 'react';
import RecurringPlanningContent from './RecurringPlanningContent';

interface RecurringPlanningDialogProps {
  nodeId: Uuid;
  onClose: () => void;
}

export default function RecurringPlanningDialog({nodeId, onClose}: RecurringPlanningDialogProps): ReactElement {
  return (
    <DialogFrame noOwnForm overflowVisible>
      <DialogTitle onClose={onClose}>
        <DialogAndDrawerHeader header={'pages:projects.inspector.manage.planning.recurringPlanning.title'} noPadding />
      </DialogTitle>
      <DialogContent>
        <StopClickPropagation>
          <RecurringPlanningContent closeDialog={onClose} nodeId={nodeId} />
        </StopClickPropagation>
      </DialogContent>
    </DialogFrame>
  );
}
