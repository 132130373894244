import {ComponentType, createContext} from 'react';
import {ProjectTreeWorkPackage} from '../../Modules/Projects/ProjectTreeInterfaces';
import Project, {ProjectParams} from './Components/Project';
import GroupDetails, {GroupDetailsProps} from './Defaults/Details/GroupDetails';
import ProjectDetails, {ProjectDetailsProps} from './Defaults/Details/ProjectDetails';
import Group, {GroupParams} from './Defaults/Group';
import GroupsAndWorkPackages, {GroupsAndWorkPackagesProps} from './Defaults/GroupsAndWorkPackages';
import WorkPackageAsTile, {WorkPackageParams} from './Defaults/WorkPackageAsTile';

export interface WorkPackageContextDetailsProps {
  node: ProjectTreeWorkPackage;
}

export interface ProjectsTreeComponentContext {
  groupsAndWorkPackages: ComponentType<GroupsAndWorkPackagesProps>;
  group: ComponentType<GroupParams>;
  groupDetails: ComponentType<GroupDetailsProps>;
  project: ComponentType<ProjectParams>;
  projectDetails: ComponentType<ProjectDetailsProps>;
  projectPageDetails?: ComponentType<ProjectDetailsProps>;
  workPackage: ComponentType<WorkPackageParams>;
  workPackageContextDetails?: ComponentType<WorkPackageContextDetailsProps>;
}

export const defaultProjectsTreeComponentContext: ProjectsTreeComponentContext = {
  group: Group,
  groupDetails: GroupDetails,
  groupsAndWorkPackages: GroupsAndWorkPackages,
  project: Project,
  projectDetails: ProjectDetails,
  workPackage: WorkPackageAsTile,
};

export const projectsTreeComponentContext = createContext<ProjectsTreeComponentContext>(
  defaultProjectsTreeComponentContext,
);
