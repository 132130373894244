import {error} from '@octaved/env/src/Logger';
import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {
  FLOW_BULK_UNSUBSCRIBE_FROM_WPS_FAILURE,
  FLOW_BULK_UNSUBSCRIBE_FROM_WPS_REQUEST,
  FLOW_BULK_UNSUBSCRIBE_FROM_WPS_SUCCESS,
  FLOW_SUBSCRIBE_TO_NODE_FAILURE,
  FLOW_SUBSCRIBE_TO_NODE_REQUEST,
  FLOW_SUBSCRIBE_TO_NODE_SUCCESS,
  FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE,
  FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST,
  FLOW_UNSUBSCRIBE_FROM_NODE_SUCCESS,
} from './ActionTypes';
import {NodeSubscribedEvent, NodeSubscribeEvent, NodeUnsubscribedEvent, NodeUnsubscribeEvent} from './Events';
import {nodeSearchResultsReducers, nodeSearchStateReducers, reduceClearKeys} from './NodeSearch';
import {reduceAddToNodeSearch} from './NodeSearchReducers/GenericAddRemove';
import {reduceRemoveIdsByKeyDEPRECATED} from './NodeSearchReducers/GenericRemove';
import {getNodeSearchKey, nodeSearchSelector} from './Selectors/NodeSearchSelectors';
import {FlowState} from './State';

nodeSearchResultsReducers.add<NodeSubscribeEvent>(FLOW_SUBSCRIBE_TO_NODE_REQUEST, reduceAddToNodeSearch);
nodeSearchResultsReducers.add<NodeSubscribeEvent>(FLOW_SUBSCRIBE_TO_NODE_FAILURE, (state, {key, nodeId}) =>
  reduceRemoveIdsByKeyDEPRECATED(state, [nodeId], key),
);
nodeSearchResultsReducers.add<NodeUnsubscribeEvent>(FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST, (state, {key, nodeId}) =>
  reduceRemoveIdsByKeyDEPRECATED(state, [nodeId], key),
);
nodeSearchResultsReducers.add<NodeUnsubscribeEvent>(FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE, reduceAddToNodeSearch);
nodeSearchResultsReducers.add<NodeSubscribedEvent>('flow.NodeSubscribedEvent', reduceAddToNodeSearch);
nodeSearchResultsReducers.add<NodeUnsubscribedEvent>('flow.NodeUnsubscribedEvent', (state, {key, nodeId}) =>
  reduceRemoveIdsByKeyDEPRECATED(state, [nodeId], key),
);

nodeSearchStateReducers.add('flow.BulkUnsubscribedFromWorkPackagesEvent', reduceClearKeys(['favorite']));

function validateFavoritesLoaded(favorites: string[] | undefined): favorites is string[] {
  if (!favorites) {
    error('Node search favorites not loaded');
    return false;
  }
  return true;
}

export function subscribeToNode(nodeId: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const currentUserId = currentOrgUserIdSelector(state);
    const key = getNodeSearchKey('favorite', currentUserId);
    const favoriteNodeIds = nodeSearchSelector(state)[key];
    if (validateFavoritesLoaded(favoriteNodeIds) && !favoriteNodeIds.includes(nodeId)) {
      dispatch({
        key,
        nodeId,
        [CALL_API]: {
          endpoint: routes.subscribeToNode,
          method: 'put',
          options: {
            urlParams: {nodeId},
          },
          types: {
            failureType: FLOW_SUBSCRIBE_TO_NODE_FAILURE,
            requestType: FLOW_SUBSCRIBE_TO_NODE_REQUEST,
            successType: FLOW_SUBSCRIBE_TO_NODE_SUCCESS,
          },
        },
      });
    }
  };
}

export function unsubscribeFromNode(nodeId: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const currentUserId = currentOrgUserIdSelector(state);
    const key = getNodeSearchKey('favorite', currentUserId);
    const favoriteNodeIds = nodeSearchSelector(state)[key];
    if (validateFavoritesLoaded(favoriteNodeIds) && favoriteNodeIds.includes(nodeId)) {
      dispatch({
        key,
        nodeId,
        [CALL_API]: {
          endpoint: routes.unsubscribeFromNode,
          method: 'del',
          options: {
            urlParams: {nodeId},
          },
          types: {
            failureType: FLOW_UNSUBSCRIBE_FROM_NODE_FAILURE,
            requestType: FLOW_UNSUBSCRIBE_FROM_NODE_REQUEST,
            successType: FLOW_UNSUBSCRIBE_FROM_NODE_SUCCESS,
          },
        },
      });
    }
  };
}

export function bulkUnsubscribeFromWorkPackages(completedOnly: boolean): ActionDispatcher<Promise<number>, FlowState> {
  return async (dispatch) => {
    const response = await dispatch<{count: number}>({
      [CALL_API]: {
        endpoint: routes.bulkUnsubscribeFromWorkPackages,
        method: 'del',
        options: {urlParams: {completedOnly: +completedOnly}},
        types: {
          failureType: FLOW_BULK_UNSUBSCRIBE_FROM_WPS_FAILURE,
          requestType: FLOW_BULK_UNSUBSCRIBE_FROM_WPS_REQUEST,
          successType: FLOW_BULK_UNSUBSCRIBE_FROM_WPS_SUCCESS,
        },
      },
    });
    return response.count;
  };
}
