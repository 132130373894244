import {GuestRoleRights, InternalRoleRights} from '@octaved/security/src/EntityInterfaces/RoleRights';
import {EntityState, isLoaded} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {FlowState} from '../../State';

export const guestRoleRightMatrixSelector = (state: FlowState): GuestRoleRights => state.roles.roleRights.guest.matrix;

export const getGuestRoleRightsSetSelector = createSelector(guestRoleRightMatrixSelector, (matrix) =>
  memoize((roleId: Uuid | null): ReadonlySet<string> => new Set((roleId && matrix[roleId]) || [])),
);

export const guestRoleRightMatrixStateSelector = (state: FlowState): EntityState => state.roles.roleRights.guest.state;
export const guestRoleRightMatrixIsLoadedSelector = (state: FlowState): boolean =>
  isLoaded(state.roles.roleRights.guest.state);

export const internalRoleRightMatrixSelector = (state: FlowState): InternalRoleRights =>
  state.roles.roleRights.internal.matrix;
export const internalRoleRightMatrixStateSelector = (state: FlowState): EntityState =>
  state.roles.roleRights.internal.state;
export const internalRoleRightMatrixIsLoadedSelector = (state: FlowState): boolean =>
  isLoaded(state.roles.roleRights.internal.state);
