import {useEffect, useRef} from 'react';
import {useStore} from 'react-redux';
import {UnknownAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {Selector} from 'reselect';

// Copied and adjusted from reselect - cannot import because it is not exported from their main module
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractReturnType<T extends readonly ((...args: any[]) => any)[]> = {
  [index in keyof T]: T[index] extends T[number] ? ReturnType<T[index]> : never;
};

/**
 * Creates an effect that will execute `callback` whenever one of the additional given selectors change their
 * value, but **without** triggering a re-render of the calling component!
 */
export function useStoreEffect<State, S1, SN extends Selector<State, unknown>[]>(
  callback: (
    dispatch: ThunkDispatch<State, never, UnknownAction>,
    getState: () => State,
    s1: S1,
    ...sn: ExtractReturnType<SN>
  ) => void,
  callbackDependencies: unknown[],
  selector1: Selector<State, S1>, //at least 1 selector is required or else you could just use useEffect() directly
  ...selectors: SN
): void {
  const cbRef = useRef(callback);
  cbRef.current = callback;
  const {getState, dispatch, subscribe} = useStore<State>();
  useEffect(() => {
    const values: unknown[] = [];
    const listener = (): void => {
      const state = getState();
      let changed = false;
      [selector1, ...selectors].forEach((selector, index) => {
        const value = selector(state);
        if (values.length <= index || value !== values[index]) {
          changed = true;
        }
        values[index] = value;
      });
      if (changed) {
        cbRef.current(dispatch, getState, values[0] as S1, ...(values.slice(1) as ExtractReturnType<SN>));
      }
    };
    listener();
    return subscribe(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getState, subscribe, ...callbackDependencies, selector1, ...selectors]);
}
