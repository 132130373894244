import {isDevLocal} from '@octaved/env/src/Environment';
import '@octaved/env/src/GlobalErrorHandler';
import {setLogLevel} from '@octaved/env/src/Logger';
import * as Network from '@octaved/network/src/Network';
import {statusOk} from '@octaved/network/src/ResponseFilters/Ok';
import {getResponseFilter} from '@octaved/network/src/ResponseFilters/ParseResponse';
import {unauthorized} from '@octaved/network/src/ResponseFilters/Unauthorized';
import {type PlanningPatchData} from '@octaved/planning/src/Modules/Planning';
import {dispatch} from '@octaved/store/src/Store';
import {DeepPartialObject} from '@octaved/typescript/src/lib';
import '@octaved/websocket';
import 'react-dates/initialize';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import AppWrapper from './AppWrapper';
import {WorkPackage} from './EntityInterfaces/Pid';
import {executeDemoData} from './Modules/DemoData';
import './Modules/NodeSearchListeners';
import './Modules/Rights';
import store from './Modules/Store';

if (process.env.NODE_ENV === 'development') {
  setLogLevel('debug');
}

Network.setResponseFilters((contentType: string, stream: Network.StreamOptions) => [
  statusOk,
  unauthorized,
  getResponseFilter(contentType, stream),
]);

ReactDOM.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.getElementById('root'),
);

if (isDevLocal) {
  // @ts-ignore exposed API for development
  window.devLocal = {
    executeDemoData,
    fillWorkingTimes: () =>
      import('@octaved/working-time/tests/EndToEnd/FillTestDbWithWorkingTimes').then(({fillTestDbWithWorkingTimes}) =>
        fillTestDbWithWorkingTimes(),
      ),
    patchPlanning: async (data: PlanningPatchData[]) => {
      const {patchPlanning} = await import('@octaved/planning/src/Modules/Planning');
      dispatch(patchPlanning(data));
    },
    patchWorkPackage: async (id: string, patch: DeepPartialObject<WorkPackage>) => {
      const {patchWorkPackage} = await import('./Modules/WorkPackages');
      dispatch(patchWorkPackage(id, patch));
    },
    showMaintenanceDialog: async () => {
      const {patchEnvironmentState} = await import('@octaved/env/src/EnvironmentState');
      patchEnvironmentState({isInMaintenance: true});
    },
    showRevisionChangedDialog: async () => {
      const {patchEnvironmentState} = await import('@octaved/env/src/EnvironmentState');
      patchEnvironmentState({version: {revision: 'foo'}});
    },
  };
}

declare global {
  interface Window {
    awaitPerformanceIdle?: () => Promise<void>;
    setupAwaitPerformanceIdle: () => Promise<void>;
  }
}

window.setupAwaitPerformanceIdle = async (): Promise<void> => {
  if (window.awaitPerformanceIdle) {
    return;
  }
  const {default: debounce} = await import('lodash/debounce');
  const {createPromise} = await import('@octaved/utilities');
  const promises = new Map<Promise<void>, () => void>();
  const resolveDebounced = debounce(() => {
    promises.forEach((resolve) => resolve());
    promises.clear();
  }, 100);
  const observer = new PerformanceObserver(resolveDebounced);
  observer.observe({entryTypes: ['longtask', 'long-animation-frame', 'event', 'resource']});
  window.awaitPerformanceIdle = () => {
    const [prom, resolve] = createPromise();
    promises.set(prom, resolve);
    resolveDebounced();
    return prom;
  };
};
