import {EnumFlowNodeType, EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {getAncestorBudgetSums} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {useSingleValueArray} from '@octaved/hooks/src/SingleValueArray';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {EntityStates} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_ANCESTOR_BUDGET_SUMS_START,
  FLOW_LOAD_ANCESTOR_BUDGET_SUMS_SUCCESS,
} from '../ActionTypes';
import {ChangePidSuccessEvent, WorkPackagePatchedEvent} from '../Events';
import {useCombinedNodeSearch} from '../Hooks/NodeSearch';
import {FlowState} from '../State';

interface AncestorBudgetSums {
  id: Uuid;
  sumPriceLimits: number;
  sumTimeLimits: number;
}

export type StoreAncestorBudgetSums = Record<Uuid, AncestorBudgetSums | undefined>;

const reducerMap = new Map();
export const ancestorBudgetSumsReducer = ReduceFromMap(reducerMap);

const dependingWpProperties = ['billingType', 'maxEffort', 'effortTo', 'fixedPrice', 'priceCategory'] as const;

const stateReducerMap = new Map();
addMultiToReducerMap(
  stateReducerMap,
  [
    'flow.CustomerPatchedEvent', //price category overrides
    'flow.NodesRearrangeEvent',
    'flow.NodesRemovedEvent',
    'flow.PriceCategoryPatchedEvent',
    'flow.WorkPackageCreatedEvent',
  ],
  () => ({}),
);
addMultiToReducerMap(
  stateReducerMap,
  ['flow.WorkPackagePatchedEvent', FLOW_CHANGE_PID_SUCCESS],
  (state: EntityStates, action: WorkPackagePatchedEvent | ChangePidSuccessEvent) => {
    if (dependingWpProperties.some((key) => action.patchedKeys.includes(key))) {
      return {};
    }
    return state;
  },
);
export const ancestorBudgetSumsStateReducer = ReduceFromMap(stateReducerMap);

// private selectors - not used anywhere else
const ancestorBudgetSumsSelector = (state: FlowState): StoreAncestorBudgetSums => state.ancestorBudgetSums.data;
const ancestorBudgetSumsStatesSelector = (state: FlowState): EntityStates => state.ancestorBudgetSums.state;

const [, useLoadedAncestorBudgetSums] = createUseEntityHook<FlowState, AncestorBudgetSums>(
  FLOW_LOAD_ANCESTOR_BUDGET_SUMS_START,
  FLOW_LOAD_ANCESTOR_BUDGET_SUMS_SUCCESS,
  getAncestorBudgetSums,
  ancestorBudgetSumsSelector,
  ancestorBudgetSumsStatesSelector,
  reducerMap,
  stateReducerMap,
);

export function useAncestorBudgetSums(ancestorId: Uuid): {
  countWorkPackages: number;
  countWorkPackagesWithLimit: number;
  countWorkPackagesWithPrice: number;
  isLoading: boolean;
  sumPriceLimits: number;
  sumTimeLimits: number;
} {
  const {isLoading: sumsLoading, entriesMapped} = useLoadedAncestorBudgetSums(useSingleValueArray(ancestorId), true);
  const sums = entriesMapped.get(ancestorId);

  const {nodeIds: allWpIds, isLoading: allWpIdsLoading} = useCombinedNodeSearch(
    {
      and: [['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], withDescendants(ancestorId, true)],
    },
    true,
  );

  const {nodeIds: wpIdsWithLimit, isLoading: wpIdsWithLimitLoading} = useCombinedNodeSearch(
    {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        withDescendants(ancestorId, true),
        {not: ['wpTimeTrackingLimit', 'none']},
      ],
    },
    true,
  );

  const {nodeIds: wpIdsWithPrice, isLoading: wpIdsWithPriceLoading} = useCombinedNodeSearch(
    {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        withDescendants(ancestorId, true),
        {
          or: [
            ['wpBillingType', EnumFlowPidBillingType.VALUE_CONTINGENT],
            ['wpBillingType', EnumFlowPidBillingType.VALUE_FIXED_PRICE],
          ],
        },
      ],
    },
    true,
  );

  return {
    countWorkPackages: allWpIds.length,
    countWorkPackagesWithLimit: wpIdsWithLimit.length,
    countWorkPackagesWithPrice: wpIdsWithPrice.length,
    isLoading: sumsLoading || allWpIdsLoading || wpIdsWithLimitLoading || wpIdsWithPriceLoading,
    sumPriceLimits: sums ? sums.sumPriceLimits : 0,
    sumTimeLimits: sums ? sums.sumTimeLimits : 0,
  };
}
