import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import classNames from 'classnames';
import {ReactElement, useRef} from 'react';
import {useSelector} from 'react-redux';
import css from 'styled-jsx/css';
import Group, {GroupParams} from '../../../../Components/Tree/Defaults/Group';
import {projectsDraggedPidIdSelector} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {getSelfWithChilds, useProjectSortable} from '../Components/ProjectSortable';

//#region <styles>
/*language=SCSS*/
const style = css.resolve`
  .isDragging {
    opacity: 0.2;
  }

  .canBeDragged {
    cursor: grab;
  }
`;
//#endregion

export default function DndGroup(props: GroupParams): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const isDragging = useSelector(projectsDraggedPidIdSelector) === props.node.id;
  const idsToIgnore = useSelector(getSelfWithChilds)(props.node.id);

  const isGranted = useSelector(isGrantedSelector);
  const canBeDragged = isGranted('FLOW_NODE_PID_MANAGE_BASIC', props.parentId);

  useProjectSortable(ref, ref, props.node, props.index, props.parentId, idsToIgnore);
  return (
    <Group {...props} ref={ref} className={classNames(style.className, {canBeDragged, isDragging})}>
      {style.styles}
    </Group>
  );
}
