import {cloneDeep} from 'lodash';
import {createContext, ReactElement, ReactNode, useContext, useMemo, useState} from 'react';
import {useStore} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {MyTasksFilterStates} from '../../../../../EntityInterfaces/Filter/MyWorkPackagesFilters';
import {doFilterStatesDeviateFromDefaults} from '../../../../../Modules/Filter/CompareFilterStates';
import {
  myTasksActiveFiltersSelector,
  myTasksActivePresetSelector,
  myTasksFilterSetsSelector,
} from '../../../../../Modules/Selectors/Pages/MyTasksSelectors';
import {FlowState} from '../../../../../Modules/State';
import {setInspector} from '../../../../../Modules/Ui';
import {usePatchMyTasksUiState} from '../../../../../Modules/UiPages/MyTasks';
import {patchUserSettings} from '@octaved/users/src/Modules/OrgUserSettings';

interface MyTasksFiltersContext {
  filterStates: Partial<MyTasksFilterStates>;
  onCancel: () => void;
  onPresetClick: (preset: number | 'mine') => () => void;
  onReset: () => void;
  onSearch: () => void;
  overwriteCurrentPreset: () => void;
  setFilterStates: (p: Partial<MyTasksFilterStates>) => void;
  showAdvancedFilter: boolean;
  toggleShowAdvancedFilter: () => void;
}

const context = createContext<MyTasksFiltersContext>(null as unknown as MyTasksFiltersContext);

export function useMyTasksFiltersContext(): MyTasksFiltersContext {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('MyTasksFiltersContext not set');
  }
  return ctx;
}

export default function MyTasksFiltersContext({children}: {children: ReactNode}): ReactElement {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [filterStates, setFilterStates] = useState<Partial<MyTasksFilterStates>>({});
  const navigate = useNavigate();
  const {getState, dispatch} = useStore<FlowState>();
  const patchUi = usePatchMyTasksUiState();

  const ctx = useMemo((): MyTasksFiltersContext => {
    const onPresetClick = (filterPreset: number | 'mine') => () => {
      setShowAdvancedFilter(false);
      patchUi({filterPreset, search: ''}, true);
    };
    return {
      filterStates,
      onPresetClick,
      setFilterStates,
      showAdvancedFilter,
      onCancel: () => {
        setShowAdvancedFilter(false);
      },
      onReset: onPresetClick('mine'),
      onSearch: () => {
        const storeStates = myTasksActiveFiltersSelector(getState());
        //Only if the current local filters differ from the ones in store do we switch to the advanced preset:
        if (doFilterStatesDeviateFromDefaults(filterStates, storeStates)) {
          patchUi({filterPreset: 'advanced', advancedFilters: filterStates}, true);
        }
        setShowAdvancedFilter(false);
      },
      overwriteCurrentPreset: () => {
        const state = getState();
        const activePreset = myTasksActivePresetSelector(state);
        if (typeof activePreset === 'number') {
          const filterSets = myTasksFilterSetsSelector(state).slice(0);
          filterSets[activePreset] = {
            ...filterSets[activePreset],
            filters: cloneDeep(filterStates),
          };
          dispatch(patchUserSettings({workspace: {filterSets}}));
          setShowAdvancedFilter(false);
        }
      },
      toggleShowAdvancedFilter: () => {
        setShowAdvancedFilter((show) => {
          //When we open the advanced filter, copy the current filters into the local state:
          if (!show) {
            setFilterStates(myTasksActiveFiltersSelector(getState()));
          }
          return !show;
        });
        //close any inspector
        navigate('');
        dispatch(setInspector({}));
      },
    };
  }, [filterStates, showAdvancedFilter, patchUi, getState, dispatch, navigate]);
  return <context.Provider value={ctx}>{children}</context.Provider>;
}
