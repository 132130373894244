import {IconButton} from '@octaved/ui';
import {motion} from 'framer-motion';
import {CheckCircle} from 'lucide-react';
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import useBackdropShaking from '../../Drawer/BackdropShaking';

export interface SaveButtonProps {
  disabled?: boolean;
  onSave?: () => Promise<unknown> | unknown;
  label?: string;
  type?: 'button' | 'submit';
  isLoading?: boolean;
  useDrawerBackdropShaking?: boolean;
}

export default function SaveButton({
  disabled,
  label = 'general:save',
  onSave,
  type = 'button',
  isLoading,
  useDrawerBackdropShaking,
}: SaveButtonProps): ReactElement {
  const {controls} = useBackdropShaking(!!useDrawerBackdropShaking && !disabled);
  const [loading, _setLoading] = useState(false);
  const setLoading = useRef(_setLoading);
  useEffect(
    () => () => {
      //Set noop on dispose:
      setLoading.current = () => undefined;
    },
    [],
  );

  const save = useCallback(async () => {
    setLoading.current(true);
    await onSave?.();
    setLoading.current(false);
  }, [onSave]);

  return (
    <motion.div animate={controls}>
      <IconButton
        label={label}
        onClick={save}
        icon={<CheckCircle />}
        loading={loading || isLoading}
        isDisabled={disabled || loading || isLoading}
        type={type}
      />
    </motion.div>
  );
}
