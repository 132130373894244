import {useTimeEffortPriceSumsPerBillingType} from '@octaved/flow/src/Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {createTotals} from '@octaved/flow/src/Pages/Projects/Inspector/Common/ParentTimeSums/TotalComputers';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Hours from './Components/TimeSums/Hours';

export interface NodeSumTotalProps {
  workPackageIds: Uuid[];
}

export default function NodeSumTotal({
  frame: Frame,
  workPackageIds,
}: NodeSumTotalProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const {sums} = useTimeEffortPriceSumsPerBillingType(workPackageIds);
  const totals = useMemo(() => createTotals(sums), [sums]);

  return (
    <Frame labelToken={''}>
      <div className={'flex items-center justify-between'}>
        <div className={'font-semibold'}>{t('nodeDetails:field.totalTrackedTime.label')}</div>
        <Hours hours={totals.totalBillableHours} shortUnit />
      </div>
    </Frame>
  );
}
