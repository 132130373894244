import {FlowState} from '../State';
import {Statistics} from '../Statistics';

function stats<R>(selector: (state: Statistics) => R): (rootState: FlowState) => R {
  return (rootState: FlowState) => selector(rootState.statistics);
}

export const statisticsSelector = stats((state) => state);

export const statisticsChargableHoursSelector = stats((state) => state.chargableHours);
export const statisticsChargableHoursStateSelector = stats((state) => state.chargableHoursState);

export const bookedHoursByBillingTypeSelector = stats((state) => state.bookedHoursByBillingType.data);
export const nonBillableHoursByReasonByDateSelector = stats((state) => state.nonBillableHoursByReasonByDate.data);

export const statisticsTaskBurnDownSelector = stats((state) => state.taskBurnDown.data);
export const statisticsTaskBurnDownStateSelector = stats((state) => state.taskBurnDown.state);
