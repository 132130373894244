import Link from '@octaved/flow/src/Components/Link';
import {cn} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {lazy, ReactElement, ReactNode, Suspense, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHoverShapeIndex, useSetHoverShapeIndex} from './HoverShapeContext';
import ChartContainer, {ChartContainerPieSizes} from './Internal/ChartContainer';

const AllPiecesPopup = lazy(() => import('./AllPiecesPopup'));

const defaultColors = ['#2388de', '#3CB371', '#ED9121', '#013220', '#9400D3', '#DC143C'];

function sum(entries: PieChartWithLegendEntry[]): number {
  return entries.reduce((acc, {value}) => acc + value, 0);
}

export interface PieChartWithLegendEntry {
  color?: string;
  legendIcon?: ReactElement;
  name: string;
  nameComponent?: ReactNode;
  toolTip?: string;
  value: number;
}

interface RowEntryProps {
  piece: PieChartWithLegendEntry & {color: string; percentage: number};
  pieceIndex: number;
  unit?: string;
  valueFormatter?: (value: number) => string;
}

export function RowEntry({piece, pieceIndex, unit, valueFormatter = formatDecimal}: RowEntryProps): ReactElement {
  const hoverIdx = useHoverShapeIndex();
  const setHoverIdx = useSetHoverShapeIndex();
  return (
    <div
      className={'group contents cursor-default rounded-md'}
      onMouseEnter={() => setHoverIdx(pieceIndex)}
      onMouseLeave={() => setHoverIdx(null)}
    >
      <div
        className={cn(
          'flex items-center gap-x-2 rounded-l-md px-3 py-0.5 group-hover:bg-slate-100',
          pieceIndex === hoverIdx && 'bg-slate-100',
        )}
      >
        {piece.legendIcon ? (
          <div className={'flex-shrink-0'}>{piece.legendIcon}</div>
        ) : (
          <div className={'h-2.5 w-2.5 rounded-sm'} style={{backgroundColor: piece.color}} />
        )}
        <div className={'font-medium text-slate-800'}>{piece.nameComponent ?? piece.name}</div>
      </div>
      <div className={cn('px-3 py-0.5 text-right group-hover:bg-slate-100', pieceIndex === hoverIdx && 'bg-slate-100')}>
        {valueFormatter(piece.value)} {unit ? ` ${unit}` : ''}
      </div>
      <div
        className={cn(
          'rounded-r-md px-3 py-0.5 text-right group-hover:bg-slate-100',
          pieceIndex === hoverIdx && 'bg-slate-100',
        )}
      >
        {piece.percentage}%
      </div>
    </div>
  );
}

interface PieChartWithLegendProps {
  limit?: number;
  pieces: PieChartWithLegendEntry[];
  noData?: ReactNode;
  pieChartSize?: ChartContainerPieSizes;
  unit?: string;
  valueFormatter?: (value: number) => string;
}

export function PieChartWithLegend({
  limit = 6,
  pieces,
  noData,
  unit,
  valueFormatter,
  pieChartSize = 'lg',
}: PieChartWithLegendProps): ReactElement {
  const [showAll, setShowAll] = useState(false);
  const {t} = useTranslation();
  const othersName = t('pages:projects.pages.controlTrackedTime.inspector.quotas.pieChartOthersLabel');

  const data = useMemo(() => {
    const total = sum(pieces);
    const rows: PieChartWithLegendEntry[] =
      pieces.length > limit
        ? [...pieces.slice(0, limit - 1), {name: othersName, value: sum(pieces.slice(limit - 1))}]
        : pieces;
    return rows.map((piece, idx) => ({
      ...piece,
      color: piece.color || defaultColors[idx % defaultColors.length],
      percentage: total ? Math.round((piece.value / total) * 100) : 0,
    }));
  }, [pieces, limit, othersName]);

  if (!data.length) {
    return <>{noData}</>;
  }

  return (
    <ChartContainer data={data} size={pieChartSize}>
      <div className={'grid grid-cols-[repeat(3,max-content)] items-center'}>
        {data.map((piece, idx) => (
          <RowEntry piece={piece} pieceIndex={idx} unit={unit} valueFormatter={valueFormatter} key={idx} />
        ))}
        {pieces.length > limit && (
          <>
            <Link onClick={() => setShowAll(true)} className={'col-span-3 px-3 text-sm'}>
              {t('pages:projects.inspector.controlling.showAll')}
            </Link>
            {showAll && (
              <Suspense fallback={<></>}>
                <AllPiecesPopup
                  pieces={pieces}
                  onClose={() => setShowAll(false)}
                  unit={unit}
                  valueFormatter={valueFormatter}
                />
              </Suspense>
            )}
          </>
        )}
      </div>
    </ChartContainer>
  );
}
