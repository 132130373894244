import {anyNode, useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {HStack} from '@octaved/ui';
import {currentOrgUserTypeSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {memo, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useTimeTrackingSelector} from '../../Modules/Selectors/SettingSelectors';
import InboxButton from './QuickAccessButtons/InboxButton';
import ProjectFolderButton from './QuickAccessButtons/ProjectFolderButton';
import QuickNoteButton from './QuickAccessButtons/QuickNoteButton';
import TimeRecordingsButtons from './QuickAccessButtons/TimeRecordingsButtons';

export default memo(function QuickAccessButtons(): ReactElement {
  const canReadProjectFolder = useIsGranted('FLOW_NODE_PROJECT_FOLDER_READ_BASIC', anyNode);
  const isInternal = isInternalUserType(useSelector(currentOrgUserTypeSelector));
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  return (
    <HStack spacing={'15px'} alignItems={'center'}>
      {canReadProjectFolder && <ProjectFolderButton />}
      <InboxButton />
      {isInternal && <QuickNoteButton />}
      {isInternal && useTimeTracking && <TimeRecordingsButtons />}
    </HStack>
  );
});
