/**
 * Ceils num to its second highest digit.
 * E.g. 1236.1 is ceiled to 1300, 53.3 is ceiled to 54.
 */
export function ceilToSecondDigit(num: number): number {
  //Get digit count, see https://stackoverflow.com/a/14879700/12614999:

  const significantDigitCount = ((Math.log(Math.ceil(num)) * Math.LOG10E + 1) | 0) - 1;
  return Math.ceil(num / Math.pow(10, significantDigitCount)) * Math.pow(10, significantDigitCount);
}

/**
 * Ceils num to its second or third highest digit.
 * E.g. 1236.1 is ceiled to 1300, 53.3 is ceiled to 54.
 */
export function ceilToDynamicDigit(num: number): number {
  //Get digit count, see https://stackoverflow.com/a/14879700/12614999:

  let significantDigitCount = ((Math.log(Math.ceil(num)) * Math.LOG10E + 1) | 0) - 1;
  const ceilTo = Math.ceil(num / Math.pow(10, significantDigitCount)) * Math.pow(10, significantDigitCount);

  //check if ceilTo is too far from our actual max, we should jump to third digit
  if (ceilTo - num > num / 2) {
    significantDigitCount -= 1;
    return Math.ceil(num / Math.pow(10, significantDigitCount)) * Math.pow(10, significantDigitCount);
  }

  return ceilTo;
}

export const numberComparators = {
  eq: (a: number, b: number) => a === b,
  gt: (a: number, b: number) => a > b,
  gte: (a: number, b: number) => a >= b,
  lt: (a: number, b: number) => a < b,
  lte: (a: number, b: number) => a <= b,
} as const;

export type NumberCompareOperators = keyof typeof numberComparators;

export const numberComparatorOperators: Record<NumberCompareOperators, string> = {
  eq: '===',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
} as const satisfies Record<NumberCompareOperators, string>;
