import {TimeRecord} from '../EntityInterfaces/TimeRecords';

export type BillingStartEnd = Pick<Partial<TimeRecord>, 'billingStart' | 'billingEnd'>;

export interface FullBillingStartEnd {
  billingEnd: number;
  billingStart: number;
}

export function calcBillingDuration(startEnd: BillingStartEnd): number {
  const start = startEnd.billingStart;
  const end = startEnd.billingEnd;
  if (start && end) {
    return end - start;
  }
  return 0;
}
