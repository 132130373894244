import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import PlaceholderText from '@octaved/flow/src/Components/Feedback/Placeholder/PlaceholderText';
import PlanningDate from '@octaved/flow/src/Components/Node/PlanningDate';
import {useTaskListContext} from '@octaved/flow/src/Components/Task/TaskListContext';
import CellPlaceholder from '@octaved/flow/src/Components/Task/TaskListForNode/Task/TaskCell/Components/CellPlaceholder';
import {TaskRowProps} from '@octaved/flow/src/Components/Task/TaskListForNode/Task/TaskRow';
import {useTaskListForNodeContext} from '@octaved/flow/src/Components/Task/TaskListForNode/TaskListForNodeContext';
import {Icon} from '@octaved/ui';
import classNames from 'classnames';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useMinMaxPlanningDates} from '../../Hooks/MinMaxPlanningDates';
import {TaskPlanningStatus, taskPlanningStatusSelector} from '../../Selectors/PlanningDateSelectors';
import {getPlanningPredecessorsSelector} from '../../Selectors/PlanningDependencySelectors';

export default function TaskRow({task, depth}: TaskRowProps): ReactElement {
  const {matchingTaskIds} = useTaskListForNodeContext();
  const {selectTaskId} = useTaskListContext();
  const hasPredecessors = useSelector(getPlanningPredecessorsSelector)(task.id, true).length > 0;
  const planningStatus = useSelector(taskPlanningStatusSelector)(task.id);
  const {plannedEnd, plannedStart} = useMinMaxPlanningDates(task.id);
  const hasPlanning = Boolean(plannedStart || plannedEnd || hasPredecessors);
  const structureOnlyTask = !matchingTaskIds.has(task.id);

  return (
    <div
      className={classNames('taskRow', `depth-${depth}`, {
        structureOnlyTask,
        done: task.status === EnumFlowTaskStatus.VALUE_COMPLETE,
      })}
      onClick={() => matchingTaskIds.has(task.id) && selectTaskId?.(task.id)}
    >
      {structureOnlyTask && <PlaceholderText italic>{task.name}</PlaceholderText>}
      {!structureOnlyTask && <div>{task.name}</div>}

      <div className={'date'}>
        <div className={'planning'}>
          {hasPlanning && (
            <div className={classNames('planningCell', planningStatus)}>
              {planningStatus === TaskPlanningStatus.overdue && (
                <div className={'overdueIcon'}>
                  <Icon iconColor={'red'} size={'big'}>
                    <Flame fill={'#FFF'} />
                  </Icon>
                </div>
              )}
              <div className={'planningDate'}>
                <PlanningDate plannedStart={plannedStart} plannedEnd={plannedEnd} />
              </div>
            </div>
          )}

          {!hasPlanning && (
            <CellPlaceholder
              placeholder={'taskList:planning.noPlanning'}
              tooltip={'taskList:planning.noPlanningTooltip'}
            />
          )}
        </div>
      </div>

      <style jsx>{`
        .taskRow {
          display: grid;
          grid-template-columns: 1fr 180px;
          align-items: center;
          height: 37px;
          cursor: pointer;
          padding: 0 12px;
          border-bottom: 1px solid rgba(209, 215, 224, 0.25);
        }

        .structureOnlyTask {
          cursor: not-allowed;
        }

        .depth-1 {
          padding-left: 32px;
        }

        .taskRow:not(.structureOnlyTask):hover {
          background-color: var(--color-hover-bg);
        }

        .planningCell {
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
}
