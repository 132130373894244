import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown} from 'semantic-ui-react';

const monthDropdownOptions = [
  {token: 'generalCore:culture.months.january', value: 0},
  {token: 'generalCore:culture.months.february', value: 1},
  {token: 'generalCore:culture.months.march', value: 2},
  {token: 'generalCore:culture.months.april', value: 3},
  {token: 'generalCore:culture.months.may', value: 4},
  {token: 'generalCore:culture.months.june', value: 5},
  {token: 'generalCore:culture.months.july', value: 6},
  {token: 'generalCore:culture.months.august', value: 7},
  {token: 'generalCore:culture.months.september', value: 8},
  {token: 'generalCore:culture.months.october', value: 9},
  {token: 'generalCore:culture.months.november', value: 10},
  {token: 'generalCore:culture.months.december', value: 11},
];

function useMonthDropdownOptions(): Array<{text: string; value: number}> {
  const {t} = useTranslation();
  return useMemo(() => monthDropdownOptions.map(({token, value}) => ({value, text: t(token)})), [t]);
}

interface Props {
  month: number;
  setMonth: (m: number) => void;
  disabled?: boolean;
  fluid?: boolean;
}

function MonthPicker({month, setMonth, disabled, fluid = true}: Props): ReactElement {
  const options = useMonthDropdownOptions();
  return (
    <Dropdown
      disabled={disabled}
      selection
      fluid={fluid}
      options={options}
      value={month}
      compact={!fluid}
      onChange={(_e, {value}) => setMonth(+value!)}
    />
  );
}

export {MonthPicker, useMonthDropdownOptions};
