import {Group} from 'konva/lib/Group';
import {Label, LabelConfig, Tag} from 'konva/lib/shapes/Label';
import {createText} from './Text';

interface TooltipProps {
  text: string;
  root: Group;
  labelProps?: LabelConfig;
}

export class Tooltip {
  #text: string;
  #root: Group;
  #label: Label | null = null;
  #labelProps: LabelConfig | undefined;

  constructor({root, text, labelProps}: TooltipProps) {
    this.#text = text;
    this.#root = root;
    this.#labelProps = labelProps;

    this.#root.on('mouseenter', this.show);
    this.#root.on('mouseleave', this.hide);
  }

  show = (): void => {
    this.#label = new Label(this.#labelProps);

    this.#label.add(
      new Tag({
        fill: 'white',
        stroke: '#000',
        strokeWidth: 1,
      }),
      createText({
        padding: 5,
        text: this.#text,
      }),
    );
    this.#root.add(this.#label);
  };

  hide = (): void => {
    this.#label?.destroy();
  };

  dispose(): void {
    this.#root.off('mouseenter', this.show);
    this.#root.off('mouseleave', this.hide);
  }
}
