import classNames from 'classnames';
import {ReactElement, useRef} from 'react';
import {useSelector} from 'react-redux';
import css from 'styled-jsx/css';
import WorkPackageAsTile, {WorkPackageParams} from '../../../../Components/Tree/Defaults/WorkPackageAsTile';
import {projectsDraggedPidIdSelector} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useProjectDrop} from '../Components/ProjectSortable';

//#region <styles>
/*language=SCSS*/
const style = css.resolve`
  .isDragging {
    opacity: 0.2;
  }
`;
//#endregion

export default function DropWorkPackage(props: WorkPackageParams): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const isDragging = useSelector(projectsDraggedPidIdSelector) === props.node.id;
  useProjectDrop(ref, props.index, props.parentId, props.node.id);
  return (
    <WorkPackageAsTile {...props} ref={ref} className={classNames(style.className, {isDragging})}>
      {style.styles}
    </WorkPackageAsTile>
  );
}
