import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {PlanningNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useLoadPlanningDependencies} from '@octaved/planning/src/Components/PlanningRealization/LoadPlanningDependencies';
import {PlanningDependency} from '@octaved/planning/src/EntityInterfaces/PlanningDependency';
import {PlanningStatus} from '@octaved/planning/src/PlanningStatus/PlanningStatus';
import {getPlanningDependencySelector} from '@octaved/planning/src/Selectors/PlanningDependencySelectors';
import {cn, HelpPopup} from '@octaved/ui';
import {CheckCircle2, XCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

interface Props {
  node: PlanningNode;
  status: PlanningStatus;
}

export default function PredecessorsRow({node, status}: Props): ReactElement | null {
  const {t} = useTranslation();
  const hasPrecedessors = status.logicalPredecessors !== 'none' || status.planningPredecessors !== 'none';
  useLoadPlanningDependencies(node.id);

  const getPlanningDependency = useSelector(getPlanningDependencySelector);
  const relevantTimeBasedDependencies = node.planningPredecessors.reduce<PlanningDependency[]>((acc, id) => {
    const dep = getPlanningDependency(node.id, id);
    if (dep && dep.type === EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR && dep.offset >= 0) {
      acc.push(dep);
    }
    return acc;
  }, []);

  if (!hasPrecedessors) {
    return null;
  }

  const done =
    ['none', 'allDone'].includes(status.logicalPredecessors) &&
    ['none', 'allDone'].includes(status.planningPredecessors);

  const causeForFlame =
    (status.logicalPredecessors === 'notDone' || status.planningPredecessors === 'notDone') &&
    ['blocked', 'overdue'].includes(status.overall);

  const count = node.planningLogicalPredecessors.length + relevantTimeBasedDependencies.length;

  const Icon = done ? CheckCircle2 : XCircle;

  return (
    <>
      <div className={'col-span-2 h-px w-full bg-slate-200'} />
      <>
        <div className={cn(causeForFlame && 'text-red-700')}>
          {t('nodeDetails:field.planningStatus.details.predecessors.label')}
        </div>
        <div className={'flex items-center justify-between'}>
          <div className={cn('flex items-center gap-x-2', causeForFlame && !done && 'text-red-700')}>
            <Icon className={cn('size-4', done && 'text-emerald-600', !done && !causeForFlame && 'text-slate-500')} />
            {t(
              done
                ? 'nodeDetails:field.planningStatus.details.predecessors.done'
                : 'nodeDetails:field.planningStatus.details.predecessors.notDone',
            )}
            {count > 1 && <>&nbsp;({t('nodeDetails:field.planningStatus.details.predecessors.count', {count})})</>}
          </div>
          <div>
            <HelpPopup
              iconButtonSize={'xs'}
              position={'top right'}
              text={'nodeDetails:field.planningStatus.details.predecessors.help'}
            />
          </div>
        </div>
      </>
    </>
  );
}
