import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {ReactElement} from 'react';
import {useLoadPlanningDependenciesForSubtreeMove} from '../../Components/PlanningRealization/LoadPlanningDependencies';
import MovePlanningBody from './MovePlanningBody';

interface Props {
  close: () => void;
  node: Project | Group;
}

export default function MovePlanning({close, node}: Props): ReactElement | null {
  const {descendants, hasLoaded} = useLoadPlanningDependenciesForSubtreeMove(node.id);
  return (
    <DialogFrame size={'small'} noOwnForm>
      <DialogTitle onClose={close}>
        <DialogAndDrawerHeader
          noPadding
          header={
            {
              [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:movePlanning.title.project',
              [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:movePlanning.title.group',
            }[node.nodeType]
          }
        />
      </DialogTitle>
      <DialogContent>
        {hasLoaded && <MovePlanningBody close={close} descendants={descendants} node={node} />}
      </DialogContent>
    </DialogFrame>
  );
}
