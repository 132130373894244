import {EnumFlowNodeRoleType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Customer} from '@octaved/flow/src/EntityInterfaces/Customers';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useEffectiveRoleAssignmentsOnNodes} from '@octaved/flow/src/Modules/Hooks/NodeRoleAssignments';
import {useLoadNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {getFlowCustomerSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {nodeEntitySelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {splitSearchTerm} from '@octaved/utilities/src/Search/SearchTerm';
import {debounce, isEqual} from 'lodash';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMinMaxPlanningDateInSubtrees} from '../../Modules/MinMaxPlanningDateInSubtrees';
import {useLoadPlanningsForNodes} from '../../Modules/Planning';
import {GanttContext} from '../Gantt/Context/GanttContext';
import {GanttDataLoader, GanttDataProps} from '../Gantt/Data/GanttDataLoader';
import {
  CommonTreeNode,
  CustomerTreeNode,
  isCommonTreeNodeNodeType,
  ProjectTreeNode,
  TreeNode,
} from '../Gantt/Data/TreeNode';
import {createStoreSubscription} from '../StoreSubscription';

export type GantProjectPlanningDataProps = GanttDataProps;
export const TABLE_ROW_LIMIT = 500;

export abstract class ProjectBasedGanttDataLoader extends GanttDataLoader {
  #nodeIds: Readonly<Uuid[]> = [];
  #waitForCtxNodeIds: Readonly<Uuid[]> | null = null;
  #flatTree: TreeNode[] = [];
  #tree: TreeNode[] = [];

  #ctx: GanttContext | null = null;

  init(ctx: GanttContext): void {
    this.#ctx = ctx;
    this.disposables.push(
      this.#ctx.eventEmitter.on('extendedNodesChanged', () => this.#setFlattenTree(this.#tree)),
      createStoreSubscription(
        this.ctx.store,
        (s) => this.getSearchTermSelector(s),
        () => this.#setFlattenTree(this.#tree),
        undefined,
        (s) => this.getSearchTermSelector(s),
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => this.getGroupByCustomerSelector(s),
        () => this.#updateNodes(),
        undefined,
        (s) => this.getGroupByCustomerSelector(s),
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => this.showMaterialRessourcesSelector(s),
        () => this.#updateNodes(),
        undefined,
        (s) => this.showMaterialRessourcesSelector(s),
      ),
    );

    if (this.#waitForCtxNodeIds) {
      this.updateNodes(this.#waitForCtxNodeIds, false);
    } else if (this.#nodeIds.length) {
      this.#updateNodes();
    }
  }

  protected abstract getSearchTermSelector(state: FlowState): string;
  protected abstract getGroupByCustomerSelector(state: FlowState): boolean;
  protected abstract showMaterialRessourcesSelector(state: FlowState): boolean;
  protected abstract getRootNodes(): NodeType[];

  protected get ctx(): GanttContext {
    if (!this.#ctx) {
      throw new Error('ProjectBasedGanttDataLoader: ctx is not set');
    }
    return this.#ctx;
  }

  updateNodes(nodeIds: Readonly<Uuid[]>, isLoading: boolean): void {
    if (!this.#ctx) {
      //wait for context
      this.#waitForCtxNodeIds = nodeIds;
      return;
    }
    this.#debouncedUpdateNodes(nodeIds, isLoading);
  }

  #debouncedUpdateNodes = debounce((nodeIds: Readonly<Uuid[]>, isLoading: boolean): void => {
    if (isLoading || isEqual(nodeIds, this.#nodeIds)) {
      //do not recalc if node ids didn't changed
      return;
    }
    this.#waitForCtxNodeIds = null;
    this.#nodeIds = nodeIds;

    this.#updateNodes();
  }, 50);

  #updateNodes(): void {
    const groupByCustomer = this.getGroupByCustomerSelector(this.ctx.store.getState());
    const canReadCustomers = isGrantedSelector(this.ctx.store.getState())('FLOW_GLOBAL_CUSTOMERS_READ');

    let tree: TreeNode[];
    if (groupByCustomer && canReadCustomers) {
      tree = this.#createTreeByCustomers();
    } else {
      tree = this.#createTreeByProjects();
    }
    this.#tree = tree;
    this.#setFlattenTree(tree);
  }

  #setFlattenTree(tree: TreeNode[]): void {
    this.#flatTree = this.#buildFlatTree(tree);
    this.#ctx?.eventEmitter.emit('flatTreeChanged', {flatTree: this.#flatTree});
  }

  #getCustomer(customerId: Uuid): Customer | undefined {
    const getFlowCustomer = getFlowCustomerSelector(this.ctx.store.getState());
    return getFlowCustomer(customerId);
  }

  #createTreeByCustomers(): TreeNode[] {
    const rootNodes = this.getRootNodes();

    const tree: TreeNode[] = [];
    const customerProjects = new Map<Uuid, Project[]>();
    for (const node of rootNodes) {
      if (isProject(node)) {
        if (!customerProjects.has(node.flowCustomer)) {
          customerProjects.set(node.flowCustomer, []);
        }
        customerProjects.get(node.flowCustomer)?.push(node);
      }
    }
    const showMaterialRessources = this.showMaterialRessourcesSelector(this.ctx.store.getState());
    for (const [customerId, projects] of customerProjects) {
      const customer = this.#getCustomer(customerId);
      if (customer && this.ctx) {
        const node = new CustomerTreeNode({
          projects,
          showMaterialRessources,
          ctx: this.ctx,
          node: customer,
          parentNodeId: null,
          visibleNodeIds: this.#nodeIds,
        });
        tree.push(node);
      }
    }
    return tree;
  }

  #createTreeByProjects(): TreeNode[] {
    const rootNodes = this.getRootNodes();

    const tree: TreeNode[] = [];
    const showMaterialRessources = this.showMaterialRessourcesSelector(this.ctx.store.getState());
    for (const node of rootNodes) {
      if (isProject(node) && this.ctx) {
        const treeNode = new ProjectTreeNode({
          node,
          showMaterialRessources,
          ctx: this.ctx,
          parentNodeId: null,
          visibleNodeIds: this.#nodeIds,
        });
        tree.push(treeNode);
      } else if (isCommonTreeNodeNodeType(node) && this.ctx) {
        const treeNode = new CommonTreeNode({
          node,
          showMaterialRessources,
          ctx: this.ctx,
          parentNodeId: null,
          visibleNodeIds: this.#nodeIds,
        });
        tree.push(treeNode);
      }
    }
    return tree;
  }

  #buildFlatTree(tree: TreeNode[]): TreeNode[] {
    const search = this.getSearchTermSelector(this.ctx.store.getState());
    const searchTerm = splitSearchTerm(search);
    return tree.reduce<TreeNode[]>((acc, node) => node.buildFlatTree(acc, searchTerm), []);
  }

  get flatTree(): TreeNode[] {
    return this.#flatTree.slice(0, TABLE_ROW_LIMIT);
  }

  isTreeTruncated(): boolean {
    return this.#flatTree.length > TABLE_ROW_LIMIT;
  }
}

interface ProjectBaseGanttDataLoaderOptions {
  showMaterialResources?: boolean;
  query: NodeSearchCondition;
  fullQuery: NodeSearchCondition;
}

export function createFullQuery(rootNodeIds: Uuid[] | readonly Uuid[], showTasks: boolean): NodeSearchCondition {
  const nodeTypes: NodeSearchCondition[] = [
    ['nodeType', EnumFlowNodeType.VALUE_PROJECT],
    ['nodeType', EnumFlowNodeType.VALUE_GROUP],
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
  ];
  if (showTasks) {
    nodeTypes.push(['nodeType', EnumFlowNodeType.VALUE_TASK]);
  }
  return {
    or: [
      {
        and: [
          {or: nodeTypes},
          {
            or: rootNodeIds.map((id) => withDescendants(id, true)),
          },
        ],
      },
      {
        fixResult: rootNodeIds,
      },
    ],
  };
}

export function useProjectBaseGanttDataLoader(
  dataLoader: ProjectBasedGanttDataLoader | null,
  {showMaterialResources = false, query, fullQuery}: ProjectBaseGanttDataLoaderOptions,
): void {
  const {isLoading: isSearchLoading, nodeIds} = useCombinedNodeSearch(query, true);
  const {isLoading: isFullSearchLoading, nodeIds: allNodeIds} = useCombinedNodeSearch(fullQuery, true);
  const nodes = useSelector(nodeEntitySelector);
  const {isLoading: isLoadingNodes} = useLoadNodes(allNodeIds, true);
  useMinMaxPlanningDateInSubtrees(nodeIds);
  useEffectiveRoleAssignmentsOnNodes(EnumFlowNodeRoleType.VALUE_PROJECT, nodeIds);

  const materialResourceIds = useMemo(() => {
    const materialResourceIds: Uuid[] = [];
    if (showMaterialResources) {
      for (const nodeId of nodeIds) {
        const node = nodes[nodeId];
        if (node) {
          for (const {nodeId} of node.assignedPlanningDates) {
            materialResourceIds.push(nodeId);
          }
        }
      }
    }
    return materialResourceIds;
  }, [nodeIds, nodes, showMaterialResources]);

  const planningDateNodeIds = useMemo(
    () => new Set([...nodeIds, ...allNodeIds, ...materialResourceIds]),
    [nodeIds, allNodeIds, materialResourceIds],
  );
  const {hasLoadedOnce: hasLoadedPlanning} = useLoadPlanningsForNodes(planningDateNodeIds);

  const {isLoading: isLoadingMaterialResources} = useLoadNodes(materialResourceIds, true);

  const isLoading =
    isLoadingNodes || isSearchLoading || !hasLoadedPlanning || isLoadingMaterialResources || isFullSearchLoading;

  useEffect(() => {
    dataLoader?.updateNodes(nodeIds, isLoading);
  }, [dataLoader, nodeIds, isLoading, showMaterialResources]);
}
