import {AuditLabel} from '../../../../EntityInterfaces/Audit/AuditLabel';
import BooleanCell from '../Table/Cells/BooleanCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditLabel> = {
  columns: [
    ...historyColumns,
    createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
    createColumnDef('name', 'systemSettings:audit.tables.nameHeader'),
    createColumnDef('color', 'systemSettings:audit.tables.label.colorHeader'),
    createColumnDef('isActive', 'systemSettings:audit.tables.label.isActiveHeader', BooleanCell),
    createColumnDef('isActiveForProjects', 'systemSettings:audit.tables.label.isActiveForProjectsHeader', BooleanCell),
    createColumnDef('isActiveForGroups', 'systemSettings:audit.tables.label.isActiveForGroupsHeader', BooleanCell),
    createColumnDef(
      'isActiveForWorkPackages',
      'systemSettings:audit.tables.label.isActiveForWorkPackagesHeader',
      BooleanCell,
    ),
    createColumnDef('isActiveForTasks', 'systemSettings:audit.tables.label.isActiveForTasksHeader', BooleanCell),
    createColumnDef('isActiveForBoards', 'systemSettings:audit.tables.label.isActiveForBoardsHeader', BooleanCell),
    createColumnDef(
      'isActiveForTimeRecords',
      'systemSettings:audit.tables.label.isActiveForTimeRecordsHeader',
      BooleanCell,
    ),
    createColumnDef(
      'autoAssignToTimeRecords',
      'systemSettings:audit.tables.label.autoAssignToTimeRecordsHeader',
      BooleanCell,
    ),
    createColumnDef('rootFolder', 'systemSettings:audit.tables.label.assignedProjectFolderHeader', ReferenceCell),
  ],
  tableIdent: 'labels',
  tableNameTranslationToken: 'systemSettings:audit.labelTableName',
};
export default config;
