import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getProjectForPidSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {getTaskWorkPackageSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {Notification} from '@octaved/store/src/Notifications';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {isPlanningDependencyNode} from '../PlanningSelector';
import {DependencyType} from './ValidateCircularDependencySelector';

const validateDependencyNodeSelector = createSelector(
  getNodeSelector,
  getRootGroupTypeSelector,
  getTaskWorkPackageSelector,
  getProjectForPidSelector,
  (getNode, getRootGroupType, getTaskWp, getProjectForPid) =>
    (
      successorId: Uuid,
      dependencyPredecessorId: Uuid,
      dependencyPredecessorIds: Uuid[],
      dependencyType: DependencyType,
    ): Notification | null => {
      const predecessorNode = getNode(dependencyPredecessorId);
      const successorNode = getNode(successorId);
      if (isPlanningDependencyNode(predecessorNode) && isPlanningDependencyNode(successorNode)) {
        if (
          getRootGroupType(dependencyPredecessorId) !== EnumFlowGroupType.VALUE_GROUP ||
          getRootGroupType(successorNode.id) !== EnumFlowGroupType.VALUE_GROUP
        ) {
          return {
            field: `planningDependency_${dependencyPredecessorId}`,
            message: {
              i18nKey: 'pages:projects.inspector.manage.planning.predecessorIsAgil',
              values: {predecessorName: predecessorNode.name},
            },
          };
        }
        if (predecessorNode.planningDates.length === 0 && dependencyType === 'planning') {
          return {
            field: `planningDependency_${dependencyPredecessorId}`,
            message: {
              i18nKey: 'pages:projects.inspector.manage.planning.unplannedPredecessor',
              values: {predecessorName: predecessorNode.name},
            },
          };
        }
        if (isTask(successorNode) || isTask(predecessorNode)) {
          // validation for drag and drop
          // this case is not possible with normal UI
          const successorWp = isTask(successorNode) ? getTaskWp(successorNode.id) : successorNode;
          const predecessorWp = isTask(predecessorNode) ? getTaskWp(dependencyPredecessorId) : predecessorNode;
          if (successorWp?.id !== predecessorWp?.id) {
            return {
              field: `planningDependency_${dependencyPredecessorId}`,
              message: 'pages:projects.inspector.manage.planning.error.notSameWorkPackage',
            };
          }
        }
        if (isWorkPackage(successorNode) && isWorkPackage(predecessorNode)) {
          // validation for drag and drop
          // this case is not possible with normal UI
          const successorProject = getProjectForPid(successorNode.id);
          const predecessorProject = getProjectForPid(dependencyPredecessorId);
          if (successorProject?.id !== predecessorProject?.id) {
            return {
              field: `planningDependency_${dependencyPredecessorId}`,
              message: 'pages:projects.inspector.manage.planning.error.notSameProject',
            };
          }
        }

        if (dependencyPredecessorIds.filter((id) => id === dependencyPredecessorId).length > 1) {
          // validation for drag and drop
          // this case is not possible with normal UI
          return {
            field: `planningDependency_${dependencyPredecessorId}`,
            message: 'pages:projects.inspector.manage.planning.error.predecessorIsSuccessor',
          };
        }
      } else {
        throw new Error(`predecessor ${dependencyPredecessorId} is not a PlanningDependencyNode`);
      }
      return null;
    },
);

export const validateDependencyNodesSelector = createSelector(
  validateDependencyNodeSelector,
  (validateDependency) =>
    (successorId: Uuid, dependencyPredecessorIds: Uuid[], dependencyType: DependencyType): Notification[] => {
      const result: Notification[] = [];
      for (const dependencyPredecessorId of dependencyPredecessorIds) {
        const validationResult = validateDependency(
          successorId,
          dependencyPredecessorId,
          dependencyPredecessorIds,
          dependencyType,
        );
        if (validationResult) {
          result.push(validationResult);
        }
      }
      return result;
    },
);
