import {Popup, StopClickPropagation} from '@octaved/ui';
import classNames from 'classnames';
import {ReactElement, ReactNode, RefObject} from 'react';

interface Props {
  children: ReactNode;
  contextRef: RefObject<HTMLDivElement>;
  footer?: ReactNode;
  minWidth?: number;
  noFooterWrap?: boolean;
  noYPadding?: boolean;
  open: boolean;
}

export default function CellEditPopup({
  children,
  contextRef,
  footer,
  minWidth = 200,
  noFooterWrap,
  noYPadding,
  open,
}: Props): ReactElement {
  return (
    <>
      <Popup open={open} context={contextRef} mountNode={contextRef.current}>
        <div>
          <StopClickPropagation>
            <div className={classNames('wrapper', {footer, noYPadding})}>{children}</div>
            {footer && !noFooterWrap && <div className={'popupFooter'}>{footer}</div>}
            {footer && noFooterWrap && <>{footer}</>}
          </StopClickPropagation>
        </div>
      </Popup>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .wrapper,
        .popupFooter {
          padding: 0.4375rem 0.75rem;
        }

        .popupFooter {
          border-top: 1px solid var(--cell-border);
        }

        .wrapper {
          min-width: ${minWidth}px;
        }

        .wrapper.noYPadding {
          padding-bottom: 0;
          padding-top: 0;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
