import {TWButton} from '@octaved/ui';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {DependencyModalProps} from './DependencyModal';
import DependencyModalWithTrigger from './DependencyModalWithTrigger';

interface AddDependencyModalProps extends Omit<DependencyModalProps, 'onClose'> {
  buttonToken: string;
}

export default function AddDependencyModal({buttonToken, ...props}: AddDependencyModalProps): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'gao-x-3 mt-0 flex items-center'}>
      <DependencyModalWithTrigger
        trigger={
          <TWButton size={'md'} colorScheme={'primary'} variant={'outline'} withIcon className={'mt-2'}>
            <PlusCircle className={'size-4 text-current'} />
            {t(buttonToken)}
          </TWButton>
        }
        {...props}
      />
    </div>
  );
}
