import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {Button, HStack, IconButton} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {CheckCircle} from 'lucide-react';
import {ReactElement, ReactNode, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Selector} from 'reselect';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {useAsyncNodeSearch} from '../../Modules/Hooks/NodeSearch';
import {StrictWorkflowControl} from '../../Modules/Settings';
import {FlowState} from '../../Modules/State';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';

export interface WorkflowControlTranslations {
  headline: string;
  content: string;
  close: string;
  pass?: string;
}

export type WorkflowControlTranslationsMap = Record<
  Exclude<StrictWorkflowControl, StrictWorkflowControl.relaxed>,
  WorkflowControlTranslations
>;

interface WorkflowControlDialogProps {
  handleCancel: () => void;
  handlePass: () => void;
  translations: WorkflowControlTranslations;
}

// noinspection FunctionNamingConventionJS
function WorkflowControlDialog({handleCancel, handlePass, translations}: WorkflowControlDialogProps): ReactElement {
  const {t} = useTranslation();
  return (
    <DialogFrame size={'tiny'} noOwnForm>
      <DialogTitle onClose={handleCancel}>
        <DialogAndDrawerHeader header={translations.headline} noPadding />
      </DialogTitle>

      <DialogContent>
        <p>{t(translations.content)}</p>
        <Spacer />
        <HStack justifyContent={'space-around'} spacing={'20px'}>
          <Button colorScheme={'cancel'} variant={'outline'} onClick={handleCancel}>
            {t(translations.close)}
          </Button>
          {translations.pass && (
            <IconButton
              icon={<CheckCircle />}
              iconColor={'white'}
              label={translations.pass}
              colorScheme={'green'}
              onClick={handlePass}
            />
          )}
        </HStack>
        <Spacer spacing={10} />
      </DialogContent>
    </DialogFrame>
  );
}

function useAsyncHasOpenSubTasks(nodeId: Uuid): () => Promise<boolean> {
  const asyncNodeSearch = useAsyncNodeSearch();
  return useCallback(async () => {
    return (
      (
        await asyncNodeSearch({
          and: [withDescendants({fixResult: [nodeId]}, false), ['taskStatus', EnumFlowTaskStatus.VALUE_OPEN]],
        })
      ).length > 0
    );
  }, [asyncNodeSearch, nodeId]);
}

export function useWorkflowControlDialog(
  nodeId: Uuid,
  controlSelector: Selector<FlowState, StrictWorkflowControl>,
  patchChecked: (checked: boolean) => void,
  translationMap: WorkflowControlTranslationsMap,
): {
  setChecked: (checked: boolean) => Promise<void>;
  confirmCheckedModal: ReactNode;
} {
  const [showModal, setShowModal] = useState(false);
  const currentControl = useSelector(controlSelector);
  const hasOpenSubTasks = useAsyncHasOpenSubTasks(nodeId);
  const patchCheckedRef = useRef(patchChecked);
  patchCheckedRef.current = patchChecked;

  const setChecked = useCallback(
    async (checked) => {
      if (!checked) {
        patchCheckedRef.current(checked);
        return;
      }
      if (
        (currentControl === StrictWorkflowControl.strict || currentControl === StrictWorkflowControl.medium) &&
        (await hasOpenSubTasks())
      ) {
        //show dialog
        setShowModal(true);
      } else {
        //patch at once:
        patchCheckedRef.current(checked);
      }
    },
    [hasOpenSubTasks, currentControl],
  );

  return {
    setChecked,
    confirmCheckedModal: (
      <>
        {showModal && currentControl !== StrictWorkflowControl.relaxed && (
          <WorkflowControlDialog
            handleCancel={() => setShowModal(false)}
            handlePass={() => {
              patchCheckedRef.current(true);
              setShowModal(false);
            }}
            translations={translationMap[currentControl]}
          />
        )}
      </>
    ),
  };
}
