import * as React from 'react';
import {ReactElement, ReactNode} from 'react';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

const StopClickPropagation = function StopClickPropagation({children, onClick, ...props}: Props): ReactElement {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...props}
    >
      {children}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        div {
          display: inline-block;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
};

export {StopClickPropagation};
