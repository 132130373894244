import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {Uuid} from '@octaved/typescript/src/lib';
import {PickerView, TWLabeledInput} from '@octaved/ui';
import VerticalForm from '@octaved/ui-components/src/React/Formik/VerticalForm';
import {
  fromIsoDateTimeFormat,
  fromIsoFormat,
  isoToIsoDateTimeFormat,
  toIsoDateTimeFormat,
  toIsoFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {noop} from 'lodash';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Radio} from 'semantic-ui-react';
import {createPlanningBaseline} from '../../../Modules/PlanningBaselines';
import {BaselineSchemaFormData, useBaselineSchema} from './BaselineSchema';

interface NewBaselineDialogProps {
  close(): void;
  rootNodeId: Uuid;
}

export default function NewBaselineDialog({close, rootNodeId}: NewBaselineDialogProps): ReactElement {
  const {t} = useTranslation();
  const validationSchema = useBaselineSchema();
  const today = useSelector(todayIsoDateSelector);
  const dispatch = useDispatch();

  const formik = useFormik<BaselineSchemaFormData>({
    validationSchema,
    initialValues: {
      customTime: '00:00',
      name: '',
      option: 'current',
      selectedCustomDate: today,
    },
    onSubmit: async (values) => {
      close();
      const timestamp =
        values.option === 'current'
          ? isoToIsoDateTimeFormat(today, dayjs().format('HH:mm:ss'))
          : isoToIsoDateTimeFormat(values.selectedCustomDate, `${values.customTime}:00`);

      dispatch(
        createPlanningBaseline({
          id: generateUuid(),
          name: values.name,
          nodeId: rootNodeId,
          timestamp: toIsoDateTimeFormat(fromIsoDateTimeFormat(timestamp).utc()),
        }),
      );
    },
  });

  return (
    <DialogFrame noOwnForm size={'tiny'}>
      <DialogTitle growTitle onClose={close}>
        <DialogAndDrawerHeader header={'pages:planning.baselineComparison.addBaseline'} noPadding />
      </DialogTitle>
      <DialogContent>
        <VerticalForm submitLabel={'general:create'} formik={formik}>
          <TWLabeledInput
            label={t('pages:planning.baselineComparison.name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            id={'name'}
            hasError={formik.touched.name && Boolean(formik.errors.name)}
          />

          <div className={'flex gap-x-6'}>
            <Radio
              label={t('pages:planning.baselineComparison.newBaseline.option1')}
              name={'current'}
              checked={formik.values.option === 'current'}
              onChange={() => formik.setFieldValue('option', 'current')}
            />
            <Radio
              label={t('pages:planning.baselineComparison.newBaseline.option2')}
              name={'custom'}
              checked={formik.values.option === 'custom'}
              onChange={() => formik.setFieldValue('option', 'custom')}
            />
          </div>
          {formik.values.option === 'custom' && (
            <div className={'flex flex-col items-start gap-y-4'}>
              <PickerView
                className={'border border-slate-100'}
                start={formik.values.selectedCustomDate}
                end={formik.values.selectedCustomDate}
                onChangeDates={(data) => formik.setFieldValue('selectedCustomDate', data)}
                setFocused={noop}
                isRange={false}
                focused={null}
                convertFrom={fromIsoFormat}
                convertTo={toIsoFormat}
                displayMonth={today}
                maxDate={fromIsoFormat(today)}
              />
              <div className={'max-w-sm'}>
                <TWLabeledInput
                  label={t('pages:planning.baselineComparison.newBaseline.time')}
                  value={formik.values.customTime}
                  onChange={(e) => {
                    formik.setFieldValue('customTime', (e.target as HTMLInputElement).value);
                  }}
                  id={'customTime'}
                  type={'time'}
                  hasError={formik.touched.customTime && Boolean(formik.errors.customTime)}
                />
              </div>
            </div>
          )}
        </VerticalForm>
      </DialogContent>
    </DialogFrame>
  );
}
