import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {NodeSearch, NodeSearchCondition} from '../../../../EntityInterfaces/NodeSearch';
import {projectsMainProjectTreeApiSelector} from '../../../Selectors/UiPages/ProjectsSelector';
import {useCombinedNodeSearches} from '../../NodeSearch';

export type TransgressionLevel = NodeSearch['wpTimeTrackingLimit'];
export type TransgressionLevelIssue = 'closed' | 'exceeded' | 'warning';
type TransgressionLevelOkay = 'ok' | 'notStarted' | 'none';
type IdsRecord = Record<TransgressionLevel, ReadonlyArray<Uuid>>;

export interface TransgressionIds extends IdsRecord {
  isLoading: boolean;
}

export const transgressionLevelsIssue: TransgressionLevelIssue[] = ['closed', 'exceeded', 'warning'];
const transgressionLevelsOkay: TransgressionLevelOkay[] = ['ok', 'notStarted', 'none'];
export const transgressionLevels: TransgressionLevel[] = [...transgressionLevelsIssue, ...transgressionLevelsOkay];

export function isTransgressionIssue(level: TransgressionLevel): boolean {
  return transgressionLevelsIssue.includes(level as TransgressionLevelIssue);
}

export function useWpTimeTrackingLimitsInSubtree(nodeId: Uuid): TransgressionIds {
  const {workPackageIds} = useSelector(projectsMainProjectTreeApiSelector);
  const queries = transgressionLevels.map<NodeSearchCondition>((level) => ({
    and: [withDescendants(nodeId, true), {fixResult: workPackageIds}, ['wpTimeTrackingLimit', level]],
  }));
  const results = useCombinedNodeSearches({trackIsLoading: true}, ...queries);
  const isLoading = results.reduce((acc, {isLoading}) => acc || isLoading, false);
  const ids = useMemo(
    () => {
      return transgressionLevels.reduce<IdsRecord>((acc, level, idx) => {
        acc[level] = results[idx].ids;
        return acc;
      }, {} as IdsRecord);
    },
    // dependency on all ids, the full results-array cannot be used:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    results.map(({ids}) => ids),
  );
  return {isLoading, ...useLoadedValue(isLoading, ids)};
}

export function useHighestTimeTrackingLimitTransgressionLevel(transgressionIds: TransgressionIds): TransgressionLevel {
  for (const level of transgressionLevels) {
    if (transgressionIds[level].length) {
      return level;
    }
  }
  return 'none';
}
