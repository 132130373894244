import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {Dropdown} from 'semantic-ui-react';

interface Props {
  quarter: number;
  setQuarter: (q: number) => void;
  readonly?: boolean;
  optionToken?: string;
  fluid?: boolean;
}

function QuarterPicker({
  quarter,
  readonly,
  setQuarter,
  optionToken = 'general:date.quarter',
  fluid = true,
}: Props): ReactElement {
  const quarterDropdownOptions = [...new Array(4).keys()].map((key) => ({
    text: (
      <span>
        <Trans i18nKey={optionToken} values={{quarter: key + 1}} />
      </span>
    ),
    value: key + 1,
  }));

  return (
    <div className={'quarterInput'}>
      <Dropdown
        disabled={readonly}
        selection
        fluid={fluid}
        compact={!fluid}
        options={quarterDropdownOptions}
        value={quarter}
        onChange={(_e, {value}) => setQuarter(+value!)}
      />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .quarterInput {
          max-width: 130px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}

export {QuarterPicker};
