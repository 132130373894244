import {HStack, IconButton} from '@octaved/ui';
import {Filter, X, XCircle} from 'lucide-react';
import {PropsWithChildren, ReactElement} from 'react';
import DialogAndDrawerHeader from '../Layout/DialogAndDrawerHeader';

interface Props {
  headerButtons?: ReactElement;
  onCancel: () => void;
  onReset: () => void;
  onSearch: () => void;
}

export default function FilterContent({
  children,
  headerButtons,
  onReset,
  onSearch,
  onCancel,
}: PropsWithChildren<Props>): ReactElement {
  return (
    <div className={'filter'}>
      <div className={'filterHeader'}>
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <DialogAndDrawerHeader header={'pages:advancedFilter.headline'} noPadding />

          <HStack spacing={'8px'} alignItems={'center'}>
            {headerButtons}
            <IconButton
              size={'sm'}
              onClick={onReset}
              icon={<XCircle />}
              label={'pages:advancedFilter.reset'}
              variant={'outline'}
            />
            <IconButton onClick={onSearch} icon={<Filter />} label={'pages:advancedFilter.apply'} size={'sm'} />
            <IconButton onClick={onCancel} icon={<X />} variant={'ghost'} size={'sm'} />
          </HStack>
        </HStack>
      </div>
      <div className={'filterContent'}>{children}</div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .filter {
          display: flex;
          align-items: stretch;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
        }

        .filterHeader {
          padding: 15px;
          flex-shrink: 0;
          border-bottom: 1px solid var(--color-grey-125);
        }

        .filterContent {
          padding: 15px 20px;
          flex-grow: 1;
          overflow-y: auto;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
