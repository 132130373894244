import UnitNameWithIcon from '@octaved/flow/src/Components/Unit/UnitNameWithIcon';
import {useNodeResponsibleFullUnits} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import Placeholder from '../../PlaceholderText';

interface NodeResponsibilityValueProps {
  nodeId: Uuid;
}

export default function NodeResponsibilityValue({nodeId}: NodeResponsibilityValueProps): ReactElement | null {
  const units = useNodeResponsibleFullUnits(nodeId);

  return (
    <>
      {units.map((unit) => (
        <UnitNameWithIcon className={'gap-x-1 px-0'} key={unit.unitId} avatarSize={'small'} {...unit} />
      ))}
      {units.length === 0 && <Placeholder text={'nodeDetails:field.responsibility.placeholder'} />}
    </>
  );
}
