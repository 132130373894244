import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {DeepPartial} from '@octaved/typescript/src/lib';
import {HStack} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {WorkPackagePatchData} from '../../../EntityInterfaces/Pid';
import MaxEffortField from './MaxEffortField';

const suffixTexts = new Map<EnumFlowPidBillingType | null, string>();
suffixTexts.set(EnumFlowPidBillingType.VALUE_FIXED_PRICE, 'general:pid.billingType.fixedPriceInfo');
suffixTexts.set(EnumFlowPidBillingType.VALUE_EFFORT_EST, 'general:pid.billingType.estimation');

interface Props {
  patch: (pid: DeepPartial<WorkPackagePatchData>) => void;
  readonly?: boolean;
  snapshot: WorkPackagePatchData;
  onBlur?: () => void;
}

export default function MaxEffort({patch, readonly, snapshot, onBlur}: Props): ReactElement {
  const {t} = useTranslation();
  const suffixText = suffixTexts.get(snapshot.billingType);

  return (
    <div>
      <HStack alignItems={'center'}>
        <MaxEffortField
          fieldName={`maxEffort_${snapshot.id}`}
          maxEffort={snapshot.maxEffort}
          readonly={readonly}
          setMaxEffort={(maxEffort) => patch({maxEffort})}
          onBlur={onBlur}
        />
        {suffixText && <span>&nbsp;({t(suffixText)})</span>}
      </HStack>
    </div>
  );
}
