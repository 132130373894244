import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {WorkPackagePatchData} from '../../../EntityInterfaces/Pid';
import {transformEffort} from '../../../Modules/Hooks/EffortTransformer';
import ValidationInputField from '../../Form/Input/ValidationInputField';

interface Props {
  id: Uuid;
  updatePid: (pid: DeepPartial<WorkPackagePatchData>) => void;
  effortFrom?: string | null;
  readonly?: boolean;
  onBlur?: () => void;
}

export default function PidEffortFrom({id, effortFrom, updatePid, readonly, onBlur}: Props): ReactElement {
  return (
    <ValidationInputField
      value={effortFrom || ''}
      disabled={readonly}
      setValue={(val) => {
        let effort = null;
        if (val) {
          effort = transformEffort(val);
          if (!effort.match(/^(\d+)$/)) {
            //allow only numbers
            return;
          }
        }
        updatePid({effortFrom: effort});
      }}
      fieldName={`effortFrom_${id}`}
      onBlur={onBlur}
    />
  );
}
