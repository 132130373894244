import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import Flex from '@octaved/ui-components/src/Flex';
import classNames from 'classnames';
import {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Form} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {WorkPackagePatchData} from '../../EntityInterfaces/Pid';
import {removeErrorForField} from '../../Modules/Ui';
import MaxEffort from './EffortHours/MaxEffort';
import PidEffortFrom from './EffortHours/PidEffortFrom';
import PidEffortTo from './EffortHours/PidEffortTo';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .centered {
    text-align: center;
  }
`;
//#endregion

interface Props {
  'data-selenium-id'?: string;
  id: Uuid;
  patch: (data: DeepPartial<WorkPackagePatchData>) => void;
  readonly?: boolean;
  snapshot: WorkPackagePatchData;
  onBlur?: () => void;
}

export default function EffortHours({id, snapshot, patch, readonly, onBlur, ...props}: Props): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (snapshot.billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO) {
      dispatch(removeErrorForField(`effortFrom_${id}`));
      dispatch(removeErrorForField(`effortTo_${id}`));
    }
  }, [id, dispatch, snapshot.effortFrom, snapshot.billingType, snapshot.effortTo]);

  return (
    <Flex fullWidth data-selenium-type={props['data-selenium-id']}>
      {snapshot.billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO && (
        <>
          <Form.Field width={4}>
            <PidEffortFrom
              id={id}
              readonly={readonly}
              updatePid={(data) =>
                patch({
                  ...data,
                  billingType: snapshot.billingType,
                  effortTo: snapshot.effortTo,
                })
              }
              effortFrom={snapshot.effortFrom}
              onBlur={onBlur}
            />
          </Form.Field>
          <Form.Field width={2} className={classNames(className, 'centered')}>
            <span>{t('general:to')}</span>
          </Form.Field>
          <Form.Field width={4}>
            <PidEffortTo
              id={id}
              readonly={readonly}
              updatePid={(data) =>
                patch({
                  ...data,
                  billingType: snapshot.billingType,
                  effortFrom: snapshot.effortFrom,
                })
              }
              onBlur={onBlur}
              effortTo={snapshot.effortTo}
            />
          </Form.Field>
        </>
      )}
      {snapshot.billingType !== EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO && (
        <MaxEffort patch={patch} readonly={readonly} snapshot={snapshot} onBlur={onBlur} />
      )}
      {styles}
    </Flex>
  );
}
