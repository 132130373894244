import {cn} from '@octaved/ui';
import {ReactElement} from 'react';
import {TIME} from '../DateFormatFunctions';
import {useFormatInWords} from '../DateFormatHooks/FormatInWords';
import Now from './Now';

interface Props {
  end?: number | null;
  errorEnd?: boolean;
  errorStart?: boolean;
  isUtc?: boolean;
  showOpenTillNow?: boolean;
  start?: number | null;
  warningEnd?: boolean;
  warningStart?: boolean;
}

export default function TimeRange({
  end,
  errorEnd,
  errorStart,
  isUtc,
  showOpenTillNow,
  start,
  warningEnd,
  warningStart,
}: Props): ReactElement {
  const formatInWords = useFormatInWords({type: TIME, options: {isUtc}});
  return (
    <>
      <span className={cn(errorStart && 'text-red-600', !errorStart && warningStart && 'text-orange-600')}>
        {start ? formatInWords(start) : ''}
      </span>
      {' - '}
      <span
        className={cn(
          errorEnd && 'text-red-600',
          !errorEnd && warningEnd && 'text-orange-600',
          !warningEnd && !errorEnd && showOpenTillNow && !end && 'italic text-green-600',
        )}
      >
        {end ? formatInWords(end) : showOpenTillNow ? <Now /> : ''}
      </span>
    </>
  );
}
