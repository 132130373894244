import {FlowState} from '@octaved/flow/src/Modules/State';
import {licenseSelector} from '@octaved/license/src/Selectors/LicenseSelectors';
import {post} from '@octaved/network/src/Network';
import {CALL_API, UrlParams} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import objectContains from '@octaved/validation/src/ObjectContains';
import {AccessKey, AccessKeyPatch, AccessKeys} from '../Interfaces/AccessKeys';
import {accessKeysSelector, accessKeysStateSelector} from '../Selectors/AccessKeysSelectors';

const LOAD_ACCESS_KEYS_REQUESTS = 'LOAD_ACCESS_KEYS_REQUESTS';
const LOAD_ACCESS_KEYS_FAILURE = 'LOAD_ACCESS_KEYS_FAILURE';
const LOAD_ACCESS_KEYS_SUCCESS = 'LOAD_ACCESS_KEYS_SUCCESS';
const PATCH_ACCESS_KEY_REQUESTS = 'PATCH_ACCESS_KEY_REQUESTS';
const PATCH_ACCESS_KEY_FAILURE = 'PATCH_ACCESS_KEY_FAILURE';
const PATCH_ACCESS_KEY_SUCCESS = 'PATCH_ACCESS_KEY_SUCCESS';
const DELETE_ACCESS_KEY_REQUESTS = 'DELETE_ACCESS_KEY_REQUESTS';
const DELETE_ACCESS_KEY_FAILURE = 'DELETE_ACCESS_KEY_FAILURE';
const DELETE_ACCESS_KEY_SUCCESS = 'DELETE_ACCESS_KEY_SUCCESS';

export interface AddedAccessKey {
  id: Uuid;
}

export interface CreatedAccessKey {
  id: Uuid;
  privateKey: string;
}

interface LoadAction {
  storeId: Uuid;
  response: AccessKey[];
  type: typeof LOAD_ACCESS_KEYS_SUCCESS;
}

interface PatchAction {
  storeId: Uuid;
  keyId: Uuid;
  patch: AccessKeyPatch;
  type: typeof PATCH_ACCESS_KEY_REQUESTS | typeof PATCH_ACCESS_KEY_FAILURE | typeof PATCH_ACCESS_KEY_SUCCESS;
}

interface DeleteAction {
  storeId: Uuid;
  keyId: Uuid;
  type: typeof DELETE_ACCESS_KEY_REQUESTS | typeof DELETE_ACCESS_KEY_FAILURE | typeof DELETE_ACCESS_KEY_SUCCESS;
}

export interface UseAccessKeys {
  accessKeys: ReadonlyArray<AccessKey>;
  addAccessKey: (publicKey: string) => Promise<AddedAccessKey>;
  createAccessKey: () => Promise<CreatedAccessKey>;
  deleteAccessKey: (keyId: Uuid) => void;
  hasLoadedOnce: boolean;
  organizationIdForHeader: number | null; //only needed for personal use if multi-org-identity
  patchAccessKey: (keyId: Uuid, patch: AccessKeyPatch) => void;
}

const reducers = createReducerCollection<AccessKeys>({});
reducers.add<LoadAction>(LOAD_ACCESS_KEYS_SUCCESS, (state, action) => ({...state, [action.storeId]: action.response}));
reducers.add<PatchAction>(PATCH_ACCESS_KEY_REQUESTS, (state, {storeId, keyId, patch}) => {
  const newState = {...state};
  const keys = newState[storeId] ?? [];
  const index = keys.findIndex((key) => key.id === keyId);
  if (index > -1) {
    newState[storeId] = keys.toSpliced(index, 1, {...keys[index], ...patch});
    return newState;
  }
  return state;
});
reducers.add<DeleteAction>(DELETE_ACCESS_KEY_REQUESTS, (state, {storeId, keyId}) => {
  const newState = {...state};
  const keys = newState[storeId] ?? [];
  const index = keys.findIndex((key) => key.id === keyId);
  if (index > -1) {
    newState[storeId] = keys.toSpliced(index, 1);
    return newState;
  }
  return state;
});
export const accessKeysReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityStates>({});
stateReducers.add(LOAD_ACCESS_KEYS_REQUESTS, createTimestampReducer('storeId', LOADING));
stateReducers.add(LOAD_ACCESS_KEYS_SUCCESS, createTimestampReducer('storeId', LOADED));
stateReducers.add(
  [PATCH_ACCESS_KEY_FAILURE, DELETE_ACCESS_KEY_FAILURE],
  createTimestampReducer('storeId', INVALIDATED),
);
stateReducers.add(
  ['flow.IdentityAccessKeyCreatedEvent', 'flow.IdentityAccessKeyDeletedEvent', 'flow.IdentityAccessKeyPatchedEvent'],
  createTimestampReducer('identityId', INVALIDATED),
);
stateReducers.add(
  ['flow.ApiUserAccessKeyCreatedEvent', 'flow.ApiUserAccessKeyDeletedEvent', 'flow.ApiUserAccessKeyPatchedEvent'],
  createTimestampReducer('apiUserId', INVALIDATED),
);
export const accessKeysStateReducer = stateReducers.reducer;

export function loadAccessKeys(
  storeId: Uuid,
  endpoint: string,
  urlParams: UrlParams,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const {features} = licenseSelector(state);
    if (!features.apiUsage) {
      return;
    }
    const keysState = accessKeysStateSelector(state)[storeId];
    if (!keysState || isOutdated(keysState)) {
      dispatch({
        storeId,
        [CALL_API]: {
          endpoint,
          options: {urlParams},
          types: {
            failureType: LOAD_ACCESS_KEYS_FAILURE,
            requestType: LOAD_ACCESS_KEYS_REQUESTS,
            successType: LOAD_ACCESS_KEYS_SUCCESS,
          },
        },
      });
    }
  };
}

export function addAccessKey(endpoint: string, urlParams: UrlParams, publicKey: string): Promise<AddedAccessKey> {
  return post<AddedAccessKey>(endpoint, {data: {publicKey}, urlParams});
}

export function createAccessKey(endpoint: string, urlParams: UrlParams): Promise<CreatedAccessKey> {
  return post<CreatedAccessKey>(endpoint, {urlParams});
}

export function patchAccessKey(
  storeId: Uuid,
  endpoint: string,
  urlParams: UrlParams,
  keyId: Uuid,
  patch: AccessKeyPatch,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const current = (accessKeysSelector(getState())[storeId] ?? []).find((key) => key.id === keyId);
    if (current && !objectContains(patch, current)) {
      dispatch({
        keyId,
        patch,
        storeId,
        [CALL_API]: {
          endpoint,
          method: 'patch',
          options: {data: patch, urlParams: {...urlParams, keyId}},
          types: {
            failureType: PATCH_ACCESS_KEY_FAILURE,
            requestType: PATCH_ACCESS_KEY_REQUESTS,
            successType: PATCH_ACCESS_KEY_SUCCESS,
          },
        },
      });
    }
  };
}

export function deleteAccessKey(
  storeId: Uuid,
  endpoint: string,
  urlParams: UrlParams,
  keyId: Uuid,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const current = (accessKeysSelector(getState())[storeId] ?? []).find((key) => key.id === keyId);
    if (current) {
      dispatch({
        keyId,
        storeId,
        [CALL_API]: {
          endpoint,
          method: 'del',
          options: {urlParams: {...urlParams, keyId}},
          types: {
            failureType: DELETE_ACCESS_KEY_FAILURE,
            requestType: DELETE_ACCESS_KEY_REQUESTS,
            successType: DELETE_ACCESS_KEY_SUCCESS,
          },
        },
      });
    }
  };
}
