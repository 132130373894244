import {Uuid} from '@octaved/typescript/src/lib';
import {createDummyRef} from '@octaved/utilities/src/React';
import {createContext, MutableRefObject, useContext} from 'react';

export interface TaskGroupContext {
  focusNewTask: MutableRefObject<(() => void) | undefined>;
  focusNewTaskSection: MutableRefObject<(() => void) | undefined>;
  id: Uuid;
}

export const dummyRef = createDummyRef<(() => void) | undefined>(undefined);

export const taskGroupContext = createContext<TaskGroupContext>({
  focusNewTask: dummyRef,
  focusNewTaskSection: dummyRef,
  id: '',
});

export function useTaskGroupContext(): TaskGroupContext {
  return useContext(taskGroupContext);
}
