import {Notification} from '@octaved/store/src/Notifications';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {DependencyType, validateCircularDependenciesSelector} from './ValidateCircularDependencySelector';
import {validateDependencyNodesSelector} from './ValidateDependencyNodeSelector';

export const validateDependenciesSelector = createSelector(
  validateDependencyNodesSelector,
  validateCircularDependenciesSelector,
  (validateDependencyNodes, validateCircularDependencies) =>
    (successorId: Uuid, dependencyPredecessorIds: Uuid[], dependencyType: DependencyType): Notification[] => {
      return [
        ...validateDependencyNodes(successorId, dependencyPredecessorIds, dependencyType),
        ...validateCircularDependencies(successorId, dependencyPredecessorIds, dependencyType),
      ];
    },
);
