import {error} from '@octaved/env/src/Logger';
import {debounce} from 'lodash';
import {MiddlewareAPI} from 'redux';
import {resetSimulationNodes} from '../../Modules/ReinitializeSimulation';
import {getCurrentPlanningSimulationSnapshotSelector} from '../../Selectors/SimulationSnapshotSelectors';

const isSimulationReplayNetworkCallSymbol = Symbol('isSimulationReplayNetworkCall');
const isSimulationReplayActionSymbol = Symbol('isSimulationReplayAction');

export function setSimulationReplayNetworkCall<T = unknown>(action: T): T {
  if (action && typeof action === 'object') {
    Object.defineProperty(action, isSimulationReplayNetworkCallSymbol, {value: true, enumerable: true});
  }
  return action;
}
export function setSimulationReplayAction<T = unknown>(action: T): T {
  if (action && typeof action === 'object') {
    Object.defineProperty(action, isSimulationReplayActionSymbol, {value: true, enumerable: true});
  }
  return action;
}
export function isSimulationReplayNetworkCall(action: unknown): boolean {
  return Boolean(action && typeof action === 'object' && isSimulationReplayNetworkCallSymbol in action);
}
function isSimulationReplayAction(action: unknown): boolean {
  return Boolean(action && typeof action === 'object' && isSimulationReplayActionSymbol in action);
}

let errorCount = 0;
const debouncedReplaySimulation = debounce(replaySimulation, 250);
async function replaySimulation(next: (a: unknown) => unknown, baseApi: MiddlewareAPI): Promise<void> {
  const baseState = baseApi.getState();
  const snapshot = getCurrentPlanningSimulationSnapshotSelector(baseState);
  await resetSimulationNodes(baseState)(next, baseApi.getState);
  const errors = [];
  if (snapshot) {
    for (const action of snapshot.actions) {
      try {
        setSimulationReplayNetworkCall(action);
        await next(action);
      } catch (e) {
        errors.push(e);
      }
    }
  }
  if (errors.length === 0) {
    errorCount = 0;
  } else {
    errorCount++;
    if (errorCount < 10) {
      setTimeout(() => {
        debouncedReplaySimulation(next, baseApi);
      }, 500);
    } else {
      errorCount = 0;
      error('replaySimulation', errors);
    }
  }
}

export default function replaySimulationkMiddleware(baseApi: MiddlewareAPI) {
  return function () {
    return function (next: (a: unknown) => unknown) {
      debouncedReplaySimulation(next, baseApi);
      return (action: unknown): Promise<unknown> | unknown => {
        const result = next(action);
        if (isSimulationReplayAction(action)) {
          debouncedReplaySimulation(next, baseApi);
        }

        return result;
      };
    };
  };
}
