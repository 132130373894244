export const UPDATE_CUSOTMER_REQUEST = 'UPDATE_CUSOTMER_REQUEST';
export const UPDATE_CUSOTMER_SUCCESS = 'UPDATE_CUSOTMER_SUCCESS';
export const UPDATE_CUSOTMER_FAILURE = 'UPDATE_CUSOTMER_FAILURE';

export const LOAD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE = 'LOAD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE';
export const LOAD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST = 'LOAD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST';
export const LOAD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS = 'LOAD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS';

export const ADD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE = 'ADD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE';
export const ADD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST = 'ADD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST';
export const ADD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS = 'ADD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS';

export const UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE = 'UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE';
export const UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST = 'UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST';
export const UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS = 'UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS';

export const REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE = 'REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE';
export const REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST = 'REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST';
export const REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS = 'REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS';

export const ORGANIZATION_ALLOWED_IP_CLEAR = 'ORGANIZATION_ALLOWED_IP_CLEAR';
export const ORGANIZATION_ALLOWED_IP_DELETED = 'ORGANIZATION_ALLOWED_IP_DELETED';
