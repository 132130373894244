import {useChildPropsFilter} from '@octaved/hooks';
import {cn} from '@octaved/ui';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {createContext, ReactElement, ReactNode, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, Modal, ModalProps} from 'semantic-ui-react';
import css from 'styled-jsx/css';
import {flowStyleContext} from '../Styles/StyleContext';
import Close from './Components/Close';
import {useCloseDialogRoute} from './Components/CloseDialogRouteLevelContext';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .modal {
    overflow: hidden;
    margin: 0 !important;
  }

  .overflowVisible {
    overflow: visible;
  }

  .fullHeight {
    min-height: calc(100% - 112px);
  }

  .noScroll {
    overflow: initial;
  }

  .alternateBg {
    background: #f7f8f8;

    & > .header,
    & > .content {
      background: #f7f8f8;
    }
  }

  .dialogHeader {
    padding: 10px 20px 5px 20px !important;
    border-bottom: none !important;
  }

  .dialogContent {
    padding: 0 !important;
  }

  .dialogContent.overflowX {
    padding: 0 !important;
    min-height: calc(100vh - 112px - 55px);
    display: flex;
    align-items: stretch;
  }

  .dialogContent.fullHeight {
    min-height: calc(100vh - 112px - 55px);
    display: flex;
    align-items: stretch;
  }

  .noPadding.dialogHeader {
    padding: 0 !important;
  }
`;

//#endregion

export const dialogAnimation = {
  hidden: {opacity: 0.5, scale: 0.5},
  visible: {opacity: 1, scale: 1},
};

interface Props {
  children?: ReactNode;
  noClose?: boolean;
}

interface DialogContentProps extends Props {
  noPadding?: boolean;
  minHeight?: boolean;
  fullHeight?: boolean;
  className?: string;
  overflowX?: boolean;
}

interface ChildProps {
  DialogTitle: Props;
  DialogContent: DialogContentProps;
  DialogFooter?: Props;
}

interface DialogTitleProps extends Props {
  center?: boolean;
  growTitle?: boolean;
  invertedClose?: boolean;
  onClose?: () => void;
}

// noinspection FunctionNamingConventionJS
export function DialogTitle({
  center,
  children,
  growTitle,
  invertedClose,
  onClose,
  noClose,
}: DialogTitleProps): ReactElement {
  const {
    COLORS: {FONT},
  } = useContext(flowStyleContext);
  const navigate = useNavigate();
  const closeDialogRoute = useCloseDialogRoute();

  const close = (): void => {
    navigate(closeDialogRoute + window.location.search);
  };

  return (
    <div className={classNames('header', {center})}>
      <div className={classNames('title', {growTitle})}>{children || <div />}</div>

      {!noClose && (
        <div className={classNames('relative z-50', {invertedClose})}>
          <Close close={onClose ?? close} className={cn(invertedClose && 'text-white')} />
        </div>
      )}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          grid-gap: 2rem;
        }

        .center {
          justify-content: center;
        }

        .title {
          color: ${FONT.HIGHLIGHTED};
          font-size: 14px;
        }

        .growTitle {
          flex-grow: 1;
        }

        :global(.dialogHeader.noPadding) .close {
          margin: 14px;
        }

        .invertedClose :global(svg),
        .invertedClose :global(button) {
          height: 48px !important;
          width: 48px !important;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}

DialogTitle.displayName = 'DialogTitle';

// noinspection FunctionNamingConventionJS
function DialogFooter({children}: Props): ReactElement {
  return <>{children}</>;
}

DialogFooter.displayName = 'DialogFooter';

// noinspection FunctionNamingConventionJS
export function DialogContent({
  children,
  minHeight = false,
  noPadding = false,
  fullHeight = false,
  className,
  overflowX = false,
}: DialogContentProps): ReactElement {
  return (
    <div className={classNames('dialogPage', {fullHeight, minHeight, noPadding, overflowX}, className)}>
      {children}
      {/*language=SCSS*/}
      <style jsx>{`
        .dialogPage {
          padding: 5px 20px;
        }

        .noPadding {
          padding: 0;
        }

        .minHeight {
          min-height: 250px;
        }

        .fullHeight {
          min-height: 100%;
          width: 100%;
        }

        .overflowX {
          width: 100%;
          overflow-x: auto;
          min-height: 100%;
        }
      `}</style>
    </div>
  );
}

DialogContent.displayName = 'DialogContent';

export interface DialogFrameProps extends Omit<ModalProps, 'onClose'> {
  mountBelowFlow?: boolean; //This does push the dialog below the header, we cannot use pageBody as this renders too
  // late when using reload
  alternateBg?: boolean;
  children?: ReactNode;
  fullHeight?: boolean;
  isLoading?: boolean;
  noOwnForm?: boolean;
  noPadding?: boolean;
  noScroll?: boolean;
  overflowVisible?: boolean;
  scrolling?: boolean;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  onClose?: () => void;
}

interface ModalContext {
  close?: () => void;
}
const modalContext = createContext<ModalContext | undefined>(undefined);
export const useModalContext = (): ModalContext | undefined => {
  const context = useContext(modalContext);
  return context;
};

// noinspection FunctionNamingConventionJS
export function DialogFrame({
  alternateBg = false,
  children,
  className: propClassName,
  fullHeight = false,
  isLoading = false,
  mountBelowFlow = false,
  noClose = false,
  noOwnForm = false,
  noPadding = false,
  noScroll = false,
  overflowVisible,
  size = 'small',
  ...modalProps
}: DialogFrameProps): ReactElement {
  const props: ChildProps = useChildPropsFilter(children, ['DialogTitle', 'DialogContent', 'DialogFooter']);
  return (
    <modalContext.Provider value={{close: modalProps.onClose}}>
      <Modal
        className={classNames(className, propClassName, 'modal', {
          alternateBg,
          fullHeight,
          noPadding,
          noScroll,
          overflowVisible,
        })}
        mountNode={document.querySelector(mountBelowFlow ? '.flow' : '#root')}
        {...modalProps}
        as={motion.div}
        initial={'hidden'}
        animate={'visible'}
        variants={dialogAnimation}
        open
        size={size}
      >
        <Modal.Header className={classNames(className, 'dialogHeader', {noPadding})}>
          <DialogTitle noClose={noClose} {...props.DialogTitle} />
        </Modal.Header>
        <Modal.Content
          className={classNames(className, 'dialogContent', {
            fullHeight: props.DialogContent.fullHeight,
            overflowX: props.DialogContent.overflowX,
          })}
        >
          {!noOwnForm && (
            <Form loading={isLoading}>
              <DialogContent {...props.DialogContent} />
            </Form>
          )}
          {noOwnForm && <DialogContent {...props.DialogContent} />}
        </Modal.Content>
        {props.DialogFooter && (
          <Modal.Actions>
            <DialogFooter {...props.DialogFooter} />
          </Modal.Actions>
        )}
        {styles}
      </Modal>
    </modalContext.Provider>
  );
}
