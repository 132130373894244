import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {ObjectSnapshot, ObjectSnapshotPatch, useObjectSnapshot} from '@octaved/hooks';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {MutableRefObject, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {FlowState} from '../../../Modules/State';
import {NewSortOrder, useGetNewSortOrder} from './NewSortOrder';

export function useShowNewRow(
  focusRef: MutableRefObject<(() => void) | undefined>,
  showInitially?: boolean,
): {
  scrollToRef: RefObject<HTMLDivElement>;
  show: boolean;
  setShow: (show: boolean) => void;
} {
  const [show, setShow] = useState(showInitially || false);
  const scrollToRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    focusRef.current = () => {
      setShow(true);
      if (scrollToRef.current) {
        if (scrollToRef.current.scrollIntoViewIfNeeded) {
          scrollToRef.current.scrollIntoViewIfNeeded(true);
        } else {
          scrollToRef.current.scrollIntoView(false);
        }
      }
    };
    return () => (focusRef.current = undefined);
  }, [focusRef]);

  return {scrollToRef, setShow, show};
}

export function useNewRowFeatures<D extends {id: Uuid}>(
  createData: () => D,
  createEntity: (data: D, parentId: Uuid, sortedSiblingIds: Uuid[]) => ActionDispatcher<Promise<boolean>, FlowState>,
  createAtParentId: Uuid,
  previousSiblingId: Uuid | undefined | NewSortOrder,
  nodeType: EnumFlowNodeType,
  beforeSave: (entity: D) => D = (e) => e,
): {
  patch: ObjectSnapshotPatch<D>;
  refs: ObjectSnapshot<D, D>['refs'];
  save: (data?: DeepPartial<D>) => void;
  snapshot: D;
} {
  const {snapshot, patch, reset, refs} = useObjectSnapshot(createData);
  const dispatch = useDispatch();
  const getNewSortOrder = useGetNewSortOrder(createAtParentId, nodeType, previousSiblingId);

  const save = useCallback(
    (data?: DeepPartial<D>) => {
      if (data) {
        patch(data);
      }
      if (refs.hasChanges.current) {
        const {sortedSiblingIds, sortOrder} = getNewSortOrder(refs.snapshot.current.id);
        const node = beforeSave({...refs.snapshot.current, sortOrder});
        dispatch(createEntity(node, createAtParentId, sortedSiblingIds));
      }
      reset(true);
    },
    [
      refs.hasChanges,
      refs.snapshot,
      reset,
      patch,
      beforeSave,
      getNewSortOrder,
      dispatch,
      createEntity,
      createAtParentId,
    ],
  );

  return {save, patch, refs, snapshot};
}
