import {ReactElement} from 'react';
import {AuditTableColumn} from './Confgurations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createColumnDef<T extends Record<string, any>, K extends keyof T>(
  id: K,
  headerTranslationToken: string,
  render?: (c: {cell: {value: T[K]}}) => ReactElement | null,
): AuditTableColumn<T> {
  return {
    headerTranslationToken,
    Cell: render,
    id: id as string,
  };
}
