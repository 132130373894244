import {ReactElement} from 'react';

interface Props {
  rows?: number;
}

export default function TextSkeleton({rows = 1}: Props): ReactElement {
  return (
    <>
      {[...Array(rows).keys()].map((idx) => (
        <div className={'loader'} key={idx} />
      ))}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .loader {
          height: 1em;
          background-color: #eee;
          background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
          background-size: 200px 100%;
          background-repeat: no-repeat;
          border-radius: 4px;
          display: inline-block;
          line-height: 1;
          width: 100%;
          animation: load 2s ease-in-out infinite;
        }

        @keyframes load {
          0% {
            background-position: -200px 0;
          }
          100% {
            background-position: calc(200px + 100%) 0;
          }
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
