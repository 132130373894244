import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import Search from '@octaved/flow/src/Components/Form/Search/Search';
import SortAndGroupOptions from '@octaved/flow/src/Components/ProjectTree/Controls/SortAndGroupOptions';
import ProjectTree from '@octaved/flow/src/Components/ProjectTree/ProjectTree';
import PlanningDependentWorkPackage from '@octaved/flow/src/Components/ProjectTree/WorkPackage';
import {
  defaultProjectsTreeComponentContext,
  ProjectsTreeComponentContext,
  projectsTreeComponentContext,
} from '@octaved/flow/src/Components/Tree/ProjectTreeComponentContext';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {useDefaultProjectTreeOptions} from '@octaved/flow/src/Modules/Projects/DefaultProjectTree';
import {ProjectTreeNode} from '@octaved/flow/src/Modules/Projects/ProjectTreeInterfaces';
import {
  projectsGroupProjectsSelector,
  projectsSortProjectsBySelector,
} from '@octaved/flow/src/Modules/Selectors/UiPages/ProjectsSelector';
import {Uuid} from '@octaved/typescript/src/lib';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {getEffectiveSwpsNotPlanned, getEffectiveWpsNotPlanned} from '../../NodeSearchPlanningFactories';
import NoWorkpackagesPlaceholder from './NoWorkpackagesPlaceholder';

interface WorkPackageDependencySelectorProps {
  projectId: Uuid;
  excludedNodeIds: Set<Uuid>;
  onSelect: (id: Uuid | null) => void;
  plannedOnly: boolean;
}

const wpsNotArchived: NodeSearchCondition = {
  and: [['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], {not: ['isArchived']}],
};
const swpsNotArchived: NodeSearchCondition = {
  and: [['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE], {not: ['isArchived']}],
};
const query: NodeSearchCondition = {
  or: [wpsNotArchived, swpsNotArchived],
};
const queryPlannedOnly: NodeSearchCondition = {
  or: [
    {and: [wpsNotArchived, {not: getEffectiveWpsNotPlanned()}]},
    {and: [swpsNotArchived, {not: getEffectiveSwpsNotPlanned()}]},
  ],
};

const selectableNodeTypes = new Set<ProjectTreeNode['type']>([EnumFlowNodeType.VALUE_WORK_PACKAGE]);

export default function WorkPackageDependencySelector({
  excludedNodeIds,
  projectId,
  onSelect,
  plannedOnly,
}: WorkPackageDependencySelectorProps): ReactElement {
  const [search, setSearch] = useState('');
  const extraBaseQuery: NodeSearchCondition = plannedOnly ? queryPlannedOnly : query;

  const treeOptions = useDefaultProjectTreeOptions();
  treeOptions.compactView = true;
  treeOptions.hideEmptyParents = true;
  treeOptions.searchTerm = search;
  treeOptions.selectNodeId = onSelect;
  treeOptions.selectableNodeTypes = selectableNodeTypes;
  treeOptions.extraQueries = {
    descendants: [extraBaseQuery],
    projects: [{fixResult: [projectId]}],
    workPackages: [extraBaseQuery],
  };
  treeOptions.sortBy = useSelector(projectsSortProjectsBySelector);
  treeOptions.useGrouping = useSelector(projectsGroupProjectsSelector);
  treeOptions.excludedNodeIds = excludedNodeIds;

  const componentContext = useMemo<ProjectsTreeComponentContext>(
    () => ({
      ...defaultProjectsTreeComponentContext,
      workPackage: PlanningDependentWorkPackage,
    }),
    [],
  );

  return (
    <>
      <div className={'flex items-center gap-x-2'}>
        <Search onEnterKey={setSearch} onLeave={setSearch} autoFocus fullWidth />
        <SortAndGroupOptions withGrouping />
      </div>
      <Spacer />
      <projectsTreeComponentContext.Provider value={componentContext}>
        <ProjectTree treeOptions={treeOptions} placeholder={<NoWorkpackagesPlaceholder />} />
      </projectsTreeComponentContext.Provider>
    </>
  );
}
