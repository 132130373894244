import {Uuid} from '@octaved/typescript/src/lib';
import {TWAutosaveTextarea} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../../Common/Type';

export interface TaskNoteProps {
  id: Uuid;
  readonly?: boolean;
  note: string;
  patch: (description: string) => void;
}

export default function TaskNote({
  frame: Frame,
  note,
  patch,
  readonly,
}: TaskNoteProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Frame labelToken={'nodeDetails:field.note.label'}>
      {readonly && <div>{note}</div>}
      {!readonly && (
        <TWAutosaveTextarea
          value={note}
          onSave={patch}
          required={false}
          placeholder={t('nodeDetails:field.note.placeholder')}
          maxLength={5000}
        />
      )}
    </Frame>
  );
}
