import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import TimePeriod from '@octaved/planning/src/Components/PlanningRealization/TimePeriod/TimePeriod';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeTimePeriodProps {
  forceRelativeToParent?: boolean;
  node: NodeType;
  readonly: boolean;
}

export default function NodeTimePeriod({
  forceRelativeToParent = false,
  frame: Frame,
  node,
  readonly,
}: NodeTimePeriodProps & FramedSectionOrTileProps): ReactElement {
  return (
    <Frame labelToken={'pages:projects.inspector.manage.planning.bars'}>
      <TimePeriod node={node} readonly={readonly} forceRelativeToParent={forceRelativeToParent} />
    </Frame>
  );
}
