import {getClientXY} from '@octaved/flow/src/Hooks/MouseMove';
import dayjs from 'dayjs';
import {throttle} from 'lodash';
import {Disposable} from '../../Disposable';
import {CalendarContext, CalendarDatesChangedEvent, ColumnWidthChangedEvent} from './CalendarContext';

export interface ScrollCoords {
  scrollTop: number;
  scrollLeft: number;
}

interface TouchPosition {
  clientX: number;
  clientY: number;
}

export type ScrollState = ScrollCoords;

interface ScrollPorps {
  vScrollBar: HTMLDivElement;
  hScrollBar: HTMLDivElement;
  scrollWheel: HTMLDivElement;
  ctx: CalendarContext;
}

export class Scroll extends Disposable {
  readonly #scrollState: ScrollState = {
    scrollLeft: 0,
    scrollTop: 0,
  };
  readonly #vScrollBar: HTMLDivElement;
  readonly #hScrollBar: HTMLDivElement;
  readonly #scrollWheel: HTMLDivElement;

  readonly #ctx: CalendarContext;
  readonly #todayOffset: null | number = null;
  #touchPosition: TouchPosition | null = null;

  constructor({vScrollBar, hScrollBar, scrollWheel, ctx}: ScrollPorps) {
    super();
    this.#vScrollBar = vScrollBar;
    this.#hScrollBar = hScrollBar;
    this.#scrollWheel = scrollWheel;
    this.#ctx = ctx;
  }

  init(): void {
    this.#vScrollBar.addEventListener('scroll', this.#onVScroll);
    this.#hScrollBar.addEventListener('scroll', this.#onHScroll);
    this.#scrollWheel.addEventListener('wheel', this.#onWheel, {passive: false});
    this.#scrollWheel.addEventListener('touchstart', this.#touchStart);
    this.disposables.push(
      this.#ctx.eventEmitter.on('calenderDatesChanged', this.#onCalenderDatesChanged),
      this.#ctx.eventEmitter.on('columnWidthChanged', this.#onClumnWidthChanged),
      () => this.#vScrollBar.removeEventListener('scroll', this.#onVScroll),
      () => this.#hScrollBar.removeEventListener('scroll', this.#onHScroll),
      () => this.#scrollWheel.removeEventListener('wheel', this.#onWheel),
    );
  }

  get scrollLeft(): number {
    return this.#hScrollBar.scrollLeft;
  }

  get scrollTop(): number {
    return this.#vScrollBar.scrollTop;
  }

  #onCalenderDatesChanged = ({currentScrollDate, startChanged}: CalendarDatesChangedEvent): void => {
    if (startChanged) {
      requestAnimationFrame(() => {
        this.scrollToDate(currentScrollDate);
      });
    }
  };

  #onClumnWidthChanged = ({currentScrollDate}: ColumnWidthChangedEvent): void => {
    requestAnimationFrame(() => {
      this.scrollToDate(currentScrollDate);
    });
  };

  #onHScroll = ({currentTarget}: Event): void => {
    const scrollLeft = (currentTarget as HTMLDivElement).scrollLeft;
    this.scrollTo({scrollLeft});
  };

  #onVScroll = ({currentTarget}: Event): void => {
    const scrollTop = (currentTarget as HTMLDivElement).scrollTop;
    this.scrollTo({scrollTop});
  };

  #touchStart = (e: TouchEvent): void => {
    const [clientX, clientY] = getClientXY(e);
    this.#touchPosition = {clientX, clientY};

    document.addEventListener('touchmove', this.#touchMove);
    document.addEventListener('touchend', this.#touchEnd);
  };
  #touchMove = (e: TouchEvent): void => {
    if (this.#touchPosition) {
      const [clientX, clientY] = getClientXY(e);
      const movementX = this.#touchPosition.clientX - clientX;
      const movementY = this.#touchPosition.clientY - clientY;
      this.#touchPosition = {clientX, clientY};
      this.#hScrollBar.scrollLeft += movementX;
      this.#vScrollBar.scrollTop += movementY;
    }
  };
  #touchEnd = (): void => {
    this.#removeTouchEvents();
  };

  #removeTouchEvents(): void {
    document.removeEventListener('touchmove', this.#touchMove);
    document.removeEventListener('touchend', this.#touchEnd);
    this.#touchPosition = null;
  }

  #lastScrollState: ScrollState | null = null;
  #emitScroll = throttle(() => {
    if (
      this.#lastScrollState?.scrollTop !== this.#scrollState.scrollTop ||
      this.#lastScrollState?.scrollLeft !== this.#scrollState.scrollLeft
    ) {
      this.#ctx.eventEmitter.emit('scroll', this.#scrollState);
      this.#lastScrollState = {...this.#scrollState};
    }
  }, 30);

  #wheelRef: number | null = null;
  #onWheel = (event: WheelEvent): void => {
    /* If user presses shift key, scroll horizontally */
    const isScrollingHorizontally = event.shiftKey;

    /* Prevent browser back in Mac */
    event.preventDefault();
    const {deltaX, deltaY, deltaMode} = event;
    /* Scroll natively */
    if (this.#wheelRef) {
      return;
    }

    const dx = isScrollingHorizontally ? deltaY : deltaX;
    let dy = deltaY;

    /* Scroll only in one direction */
    const isHorizontal = isScrollingHorizontally || Math.abs(dx) > Math.abs(dy);

    if (deltaMode === 1) {
      dy = dy * this.#ctx.scrollbarSize;
    }
    const currentScroll = isHorizontal ? this.#hScrollBar.scrollLeft : this.#vScrollBar.scrollTop;
    this.#wheelRef = window.requestAnimationFrame(() => {
      this.#wheelRef = null;
      if (isHorizontal) {
        this.#hScrollBar.scrollLeft = currentScroll + dx;
      } else {
        this.#vScrollBar.scrollTop = currentScroll + dy;
      }
    });
  };

  scrollTo({scrollLeft, scrollTop}: Partial<ScrollCoords>, updateBar = false): void {
    if (scrollLeft !== undefined) {
      this.#scrollState.scrollLeft = scrollLeft;
      if (updateBar) {
        this.#hScrollBar.scrollLeft = scrollLeft;
      }
    }
    if (scrollTop !== undefined) {
      this.#scrollState.scrollTop = scrollTop;
      if (updateBar) {
        this.#vScrollBar.scrollTop = scrollTop;
      }
    }
    if (scrollLeft !== undefined || scrollTop !== undefined) {
      this.#emitScroll();
    }
  }

  scrollToToday(): void {
    const offset = this.#todayOffset === null ? 3.5 : this.#todayOffset;
    this.scrollToDate(this.#ctx.today, offset);
  }

  scrollToDate(date: dayjs.Dayjs, offset = 0): void {
    const diff = date.diff(this.#ctx.calendarView.dateStart, 'd');
    this.scrollTo({scrollLeft: (diff - offset) * this.#ctx.columnWidth}, true);
  }

  dispose(): void {
    super.dispose();
    this.#removeTouchEvents();
    if (this.#wheelRef) {
      cancelAnimationFrame(this.#wheelRef);
    }
  }
}
