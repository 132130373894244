import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {emptyNodeSearchResult} from '@octaved/node-search/src/Factories/Tree';
import {QuickControlTimeTransgression} from '../../../../EntityInterfaces/Filter/Custom/ControlTimeTransgression';
import {ProjectFilterQueries} from '../../../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition} from '../../../../EntityInterfaces/NodeSearch';
import {transgressionLevels} from '../../../Hooks/Projects/ProjectControlling/TimeTrackingLimits';

export function controlTimeTransgressionQueryGenerator(
  queries: ProjectFilterQueries,
  {executions, levels}: QuickControlTimeTransgression,
): void {
  if (executions.length === 0) {
    queries.projects.push(emptyNodeSearchResult); //force no result
  } else if (executions.length < 2) {
    const conditions: NodeSearchCondition[] = [];
    if (executions.includes('executed')) {
      conditions.push(['wpIsCompleted']);
    }
    if (executions.includes('notExecuted')) {
      conditions.push({
        and: [['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], {not: ['wpIsCompleted']}],
      });
    }
    queries.descendants.push({or: conditions});
    queries.workPackages.push({or: conditions});
  }

  if (levels.length === 0) {
    queries.projects.push(emptyNodeSearchResult); //force no result
  } else if (levels.length < transgressionLevels.length) {
    const conditions: NodeSearchCondition = {
      or: levels.map<NodeSearchCondition>((level) => ['wpTimeTrackingLimit', level]),
    };
    queries.descendants.push(conditions);
    queries.workPackages.push(conditions);
  }
}
