import {CellProps, Renderer} from 'react-table';
import {AuditTables} from '../../../../Modules/Audit';
import Billing from './Billing';
import BoardPost from './BoardPost';
import CustomerPriceCategoryOverride from './CustomerPriceCategoryOverride';
import CustomerPriceSurchargeOverride from './CustomerPriceSurchargeOverride';
import FlowCustomer from './FlowCustomer';
import Group from './Group';
import Label from './Label';
import PriceCategory from './PriceCategory';
import PriceSurcharge from './PriceSurcharge';
import Project from './Project';
import ProjectFolder from './ProjectFolder';
import Tasks from './Tasks';
import TaskSection from './TaskSection';
import User from './User';
import UserNode from './UserNode';
import WorkPackage from './WorkPackage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AuditTableColumn<T extends Record<string, any>> {
  headerTranslationToken: string;
  id: string;
  Cell?: Renderer<CellProps<T>>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AuditTableConfiguration<T extends Record<string, any> = Record<string, unknown>> {
  tableIdent: AuditTables;
  tableNameTranslationToken: string;
  columns: AuditTableColumn<T>[];
}

const configurations = new Map<AuditTables, AuditTableConfiguration>([
  [WorkPackage.tableIdent, WorkPackage],
  [User.tableIdent, User],
  [Label.tableIdent, Label],
  [FlowCustomer.tableIdent, FlowCustomer],
  [PriceCategory.tableIdent, PriceCategory],
  [Tasks.tableIdent, Tasks],
  [BoardPost.tableIdent, BoardPost],
  [Project.tableIdent, Project],
  [ProjectFolder.tableIdent, ProjectFolder],
  [Group.tableIdent, Group],
  [Billing.tableIdent, Billing],
  [CustomerPriceCategoryOverride.tableIdent, CustomerPriceCategoryOverride],
  [CustomerPriceSurchargeOverride.tableIdent, CustomerPriceSurchargeOverride],
  [PriceSurcharge.tableIdent, PriceSurcharge],
  [TaskSection.tableIdent, TaskSection],
  [UserNode.tableIdent, UserNode],
]);

export function hasConfiguration(ident: string | null): ident is AuditTables {
  return configurations.has(ident as AuditTables);
}

export function getConfiguration(ident: AuditTables): AuditTableConfiguration {
  if (!configurations.has(ident)) {
    throw Error(`Ident '${ident}' is unknown`);
  }
  return configurations.get(ident)!;
}

export function getConfigurations(): AuditTableConfiguration[] {
  return [...configurations.values()];
}
