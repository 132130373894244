import type {SupportedLanguage} from '@octaved/i18n/src/Language/Languages';

type ImportedTranslations = {default: Record<string, Record<string, string>>};
export const translationImports: Record<SupportedLanguage, () => Promise<ImportedTranslations>> = {
  de: () => import('@octaved/i18n/translations/de/index') as unknown as Promise<ImportedTranslations>,
  en: () => import('@octaved/i18n/translations/en/index') as unknown as Promise<ImportedTranslations>,
  es: () => import('@octaved/i18n/translations/es/index') as unknown as Promise<ImportedTranslations>,
  fr: () => import('@octaved/i18n/translations/fr/index') as unknown as Promise<ImportedTranslations>,
  it: () => import('@octaved/i18n/translations/it/index') as unknown as Promise<ImportedTranslations>,
  pt: () => import('@octaved/i18n/translations/pt/index') as unknown as Promise<ImportedTranslations>,
};

export const namespaces = [
  'boardPosts',
  'components',
  'core',
  'dialogs',
  'drawer',
  'editor',
  'general',
  'generalCore',
  'guestUser',
  'inbox',
  'kanbanBoards',
  'license',
  'multiOrganizations',
  'nodeDetails',
  'nodeTable',
  'onboarding',
  'pageHeader',
  'pageHelp',
  'pageMenu',
  'pages',
  'pidColumn',
  'projectsTree',
  'roles',
  'sideSteps',
  'snackbars',
  'systemSettings',
  'taskList',
  'timeRecordings',
  'tooltips',
  'unsupportedBrowser',
  'weekSelection',
  'workingTime',
  'workPackage',
];
