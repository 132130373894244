import {ActionDispatcher} from '@octaved/store/src/Store';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Selector} from 'reselect';
import Link from '../../Components/Link';
import {FlowState} from '../../Modules/State';

interface Props {
  demoModeSelector: Selector<FlowState, boolean>;
  demoModeToggle: (demoMode: boolean) => ActionDispatcher<void, FlowState>;
}

export default function DemoMode({demoModeSelector, demoModeToggle}: Props): ReactElement | null {
  const {t} = useTranslation();
  const active = useSelector(demoModeSelector);
  const dispatch = useDispatch();

  if (active) {
    return (
      <div
        className={'flex items-center justify-center gap-x-2 border-b border-blue-400 bg-blue-100 px-5 py-2 text-sm'}
      >
        <span className={'font-semibold uppercase text-slate-700'}>{t('pages:projects.inspector.demoMode')}</span>
        <Link underline onClick={() => dispatch(demoModeToggle(false))} className={'text-xs !text-slate-800'}>
          {t('pages:projects.inspector.demoModeDisable')}
        </Link>
      </div>
    );
  }

  return null;
}
