import {initI18next} from '@octaved/i18n/src/I18next';
import {
  identityLanguageSelector,
  identityUseDecimalPeriodSelector,
} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {isLoaded} from '@octaved/store/src/EntityState';
import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import accounting from 'accounting';
import {PropsWithChildren, ReactElement, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadInitializeData} from './Modules/Initialization';
import {initDataStateSelector} from './Modules/Selectors/InitDataSelectors';

export default function Initialization({children}: PropsWithChildren<unknown>): ReactElement {
  const dispatch = useDispatch();
  const initDataState = useSelector(initDataStateSelector);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [areTranslationsLoaded, setAreTranslationsLoaded] = useState(false);

  const language = useSelector(identityLanguageSelector);
  const useDecimalPeriod = useSelector(identityUseDecimalPeriodSelector);

  useEffect(() => setInitialDataLoaded((loaded) => loaded || isLoaded(initDataState)), [initDataState]);

  useEffect(() => {
    dispatch(loadInitializeData());
    // noinspection BadExpressionStatementJS
    void initDataState; //required as reload-dependency
  }, [dispatch, initDataState]);

  useEffect(() => {
    if (isLoaded(initDataState)) {
      initI18next(language).then(() => {
        setAreTranslationsLoaded(true);
      });
    }
  }, [language, initDataState]);

  useEffect(() => {
    const precision = 2;
    accounting.settings.number = useDecimalPeriod
      ? {decimal: '.', precision, thousand: ','}
      : {decimal: ',', precision, thousand: '.'};
  }, [useDecimalPeriod]);

  if (!initialDataLoaded || !areTranslationsLoaded) {
    return <AppLoader />;
  }

  return <>{children}</>;
}
