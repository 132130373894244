import {
  TimeEffortPriceSumPerBillingType,
  TimeEffortPriceSumsPerBillingType,
} from '@octaved/flow/src/EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';
import {useTimeEffortPriceSumsPerBillingType} from '@octaved/flow/src/Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {Uuid} from '@octaved/typescript/src/lib';
import {roundToFactor} from '@octaved/users/src/Culture/NumberFormatter';

export interface Sums {
  countHasMaxEffort: number;
  effortHours: number;
  effortDays: number;
  priceIsReadable: boolean;
  price: number;
}

function sumUp(sums: TimeEffortPriceSumsPerBillingType): Sums {
  let countHasMaxEffort = 0;
  let hours = 0;
  let priceIsReadable = false;
  let price = 0;
  Object.values<TimeEffortPriceSumPerBillingType>(sums).forEach((sum) => {
    countHasMaxEffort = countHasMaxEffort + sum.countHasMaxEffort;
    hours = hours + sum.maxEffortHours;
    if (typeof sum.fixedPrice === 'number' && typeof sum.maxEffortPrice === 'number') {
      priceIsReadable = true;
      price = price + sum.fixedPrice + sum.maxEffortPrice;
    }
  });
  return {
    countHasMaxEffort,
    price,
    priceIsReadable,
    effortDays: roundToFactor(hours / 8, 0.5),
    effortHours: roundToFactor(hours, 0.25),
  };
}

export function useSums(ids: ReadonlyArray<Uuid>): Sums {
  return sumUp(useTimeEffortPriceSumsPerBillingType(ids).sums);
}
