import {useEffect} from 'react';

export function useMutationObserver(
  element: HTMLElement | undefined | null,
  callback: MutationCallback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
): void {
  useEffect(() => {
    if (element) {
      const observer = new MutationObserver(callback);
      observer.observe(element, options);
      return () => observer.disconnect();
    }
    return () => false;
  }, [callback, options, element]);
}
