import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import StandardInspectorSubMenu from '../../../Drawer/StandardInspector/StandardInspectorSubMenu';
import {InspectorTabItem, TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import {projectControllingDemoModeSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {setProjectControllingDemoMode} from '../../../Modules/UiPages/Projects';
import {dialogRouteObjects} from '../Dialogs';
import ProjectControllingDemoModeWrapper from './ProjectControllingDemoModeWrapper';
import ProjectControllingInspectorSubMenu from './ProjectControllingSubMenu';
import {getStandardInspectorRoutes, getWrappedStandardInspectorRoutes} from './StandardInspector';

const statusRoute = 'status';

const Status = lazy(() => import('../../../Pages/Projects/Pages/ControlActivity/Inspector/Status'));
const ControlActivityStatusTab = lazy(() =>
  import('@octaved/demo-mode').then((module) => ({default: module.ControlActivityStatusTab})),
);

//#region <inspector tabs>
const tabs: InspectorTabItem[] = [
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
  },
];

const inspectorTabConfig: TabRecords = {
  [EnumFlowNodeType.VALUE_PROJECT]: tabs,
  [EnumFlowNodeType.VALUE_GROUP]: tabs,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: tabs,
};
//#endregion

const statusRouteObject = (): RouteObject => ({
  children: [
    ...dialogRouteObjects,
    ...getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu, parameterName: 'inspectL2Id'}),
  ],
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<ControlActivityStatusTab />}>
      <Status />
    </ProjectControllingDemoModeWrapper>
  ),
  path: statusRoute,
});

export const controlActivityInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    statusRouteObject(),
    {
      element: <Navigate to={statusRoute} replace />,
      path: '*',
    },
  ],
  standardInspectorProps: {
    actionMenu: ProjectControllingInspectorSubMenu,
    demoModeSelector: projectControllingDemoModeSelector,
    demoModeToggle: setProjectControllingDemoMode,
    tabConfig: inspectorTabConfig,
  },
});
