import {Uuid} from '@octaved/typescript/src/lib';
import {DATE, formatInWords, fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ucfirst} from '@octaved/utilities';
import {MenuItems} from '../../../../Canvas/CommonComponents/Menu';
import {CellProps} from '../../../../Canvas/Gantt/Table/Cells/Cell';
import {HeaderMenuCell, createMenuItem} from '../../../../Canvas/Gantt/Table/Cells/HeaderMenuCell';
import {PlanningBaseline} from '../../../../EntityInterfaces/PlanningBaselines';
import {
  getPlanningBaselineDisplayTimestampSelector,
  getSelectedBaselinesSelector,
} from '../../../../Selectors/PlanningBaselineSelectors';

interface GanttComparisonHeaderMenuCellProps extends CellProps {
  rootNodeId: Uuid;
}

export class GanttComparisonHeaderMenuCell extends HeaderMenuCell {
  #rootNodeId: Uuid;
  constructor(props: GanttComparisonHeaderMenuCellProps) {
    super(props);
    this.#rootNodeId = props.rootNodeId;
  }

  protected getMenuItems(): MenuItems {
    const baseEntries = super.getMenuItems();

    const baselines = getSelectedBaselinesSelector(this.ctx.store.getState())(this.#rootNodeId);
    for (const baseline of baselines) {
      baseEntries.push(createMenuItem(this.ctx, `baseline-${baseline.id}`, this.#getBaselineName(baseline)));
    }

    return baseEntries;
  }

  #getBaselineName(baseline: PlanningBaseline): string {
    if (baseline.name) {
      return baseline.name;
    }
    const timestamp = getPlanningBaselineDisplayTimestampSelector(this.ctx.store.getState())(
      this.#rootNodeId,
      baseline.id,
    );
    if (!timestamp) {
      return '';
    }
    return ucfirst(
      formatInWords(this.ctx.t, fromIsoDateTimeFormat(timestamp), DATE, undefined, {
        dateFormat: 'DD.MM.',
        timeFormat: 'HH:mm',
      }),
    );
  }
}
