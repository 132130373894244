import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  fromIsoDateTimeFormat,
  isoDateTimeToIsoFormat,
  isoToIsoDateTimeFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {ChevronsRightLeft, Scissors, Trash} from 'lucide';
import {calculateDateLimits, moveBar} from '../../../Calculations/BarMovement';
import {workdays} from '../../../Calculations/WorkdayCalculations';
import {
  mergeAllPlanningDates,
  mergePlanningDate,
  removePlanningDate,
  splitPlanningDate,
  updatePlanningDate,
} from '../../../Modules/PlanningDates';
import {canAddGapBarSelector} from '../../../Selectors/CanAddGapBarSelector';
import {getPlanningDateSelector, getPlanningDatesForNodeSelector} from '../../../Selectors/PlanningDateSelectors';
import {canChangeBarSelector, canDeleteBarSelector} from '../../../Selectors/PlanningDependencySelectors';
import {Menu, MENU_DIVIDER, MenuItems} from '../../CommonComponents/Menu';
import {MouseMove, MouseMoveEvent} from '../../MouseMove';
import {createStoreSubscription} from '../../StoreSubscription';
import {Bar} from '../Bar';
import {ClickPlugin, ClickPluginProps} from './ClickPlugin';

interface PlanningDateClickPluginProps extends ClickPluginProps {
  isFirst: boolean;
  isLast: boolean;
  prevPlanningDateId: Uuid | null;
  nextPlanningDateId: Uuid | null;
}

function getRelativeMousePosition(root: Konva.Rect, e: MouseEvent): {x: number; y: number} {
  const content = root.getStage()?.content;
  if (!content) {
    throw new Error('content missing');
  }
  const {height, top, width, left} = content.getBoundingClientRect();
  const scaleY = height / content.clientHeight || 1;
  const scaleX = width / content.clientWidth || 1;
  const mouseY = (e.clientY - top) / scaleY;
  const mouseX = (e.clientX - left) / scaleX;

  const rootRect = root.getClientRect({skipShadow: true});
  const y = mouseY - rootRect.y;
  const x = mouseX - rootRect.x;
  return {x, y};
}

export class PlanningDateClickPlugin extends ClickPlugin {
  readonly #isFirst: boolean;
  readonly #isLast: boolean;
  readonly #prevPlanningDateId: Uuid | null;
  readonly #nextPlanningDateId: Uuid | null;
  protected menu: Menu | null = null;

  #limitStart: null | DateTimeStr = null;
  #limitEnd: null | DateTimeStr = null;
  #mouseMove: MouseMove | null = null;

  constructor(props: PlanningDateClickPluginProps) {
    super(props);
    this.#isFirst = props.isFirst;
    this.#isLast = props.isLast;
    this.#prevPlanningDateId = props.prevPlanningDateId;
    this.#nextPlanningDateId = props.nextPlanningDateId;
  }

  init(bar: Bar): void {
    super.init(bar);
    this.#initContextMenu();

    if (this.planningDateId) {
      const mouseMove = new MouseMove({
        eventNode: this.rect,
        onEnd: this.#onFinish,
        onMove: this.#onMove,
        onStart: this.#onStart,
      });
      mouseMove.init();
      this.disposables.push(() => mouseMove.dispose());
      this.#mouseMove = mouseMove;
    }

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => canChangeBarSelector(s)(this.nodeId),
        (canBeDragged) => {
          if (canBeDragged) {
            this.#mouseMove?.enable();
          } else {
            this.#mouseMove?.disable();
          }
        },
      ),
    );
  }

  getLockIdent(): string {
    return `move-${this.nodeId}`;
  }

  #closeInspector = false;
  #onStart = (): void => {
    if (this.ctx.hasEditLock()) {
      this.#mouseMove?.cancel();
      return;
    }
    const planningDates = getPlanningDatesForNodeSelector(this.ctx.store.getState())(this.nodeId);
    const {end, start} = calculateDateLimits(this.planningDateId, planningDates);
    this.#limitEnd = end;
    this.#limitStart = start;
    this.#closeInspector = true;
  };

  #onMove = (e: MouseMoveEvent): void => {
    if (this.ctx.hasEditLock() && this.ctx.editLockIdent !== this.getLockIdent()) {
      this.#mouseMove?.cancel();
      return;
    }
    if (e.movementX > 10 || e.movementX < -10 || this.ctx.editLockIdent === this.getLockIdent()) {
      if (this.ctx.canInspectRow && this.#closeInspector) {
        this.ctx.onMoveCloseInspector(this.nodeId);
        this.#closeInspector = false;
      }
      const result = this.#getMoveBarResult(e);
      if (result) {
        this.bar.setDates(result.plannedStart, result.plannedEnd);
      }
      this.ctx.editLockIdent = this.getLockIdent();
    }
  };
  #onFinish = (e: MouseMoveEvent): void => {
    if (this.ctx.editLockIdent !== this.getLockIdent()) {
      //dragging only starts after moving some pixels to differentiate from click
      this.#mouseMove?.cancel();
      return;
    }
    if (!this.planningDateId) {
      throw new Error('planningDateId missing');
    }
    const result = this.#getMoveBarResult(e);
    if (result) {
      this.ctx.store.dispatch(updatePlanningDate(this.nodeId, this.planningDateId, result));
    }
    requestAnimationFrame(() => {
      //delay reset so that the click is handeld
      this.ctx.editLockIdent = null;
    });
  };

  #getMoveBarResult(e: MouseMoveEvent): {
    plannedStart: DateTimeStr;
    plannedEnd: DateTimeStr;
  } | null {
    const state = this.ctx.store.getState();
    const getWorkHoursAtDate = getOrgWorkMinutesAtDateSelector(state);
    const planningDate = getPlanningDateSelector(state)(this.planningDateId);
    if (planningDate?.plannedStart) {
      const result = moveBar(
        e.startX,
        e.mouseX,
        isoDateTimeToIsoFormat(planningDate.plannedStart),
        isoDateTimeToIsoFormat(planningDate.plannedEnd),
        this.ctx.columnWidth,
        this.#limitStart,
        this.#limitEnd,
        getWorkHoursAtDate,
      );
      return {
        plannedEnd: isoToIsoDateTimeFormat(result.plannedEnd),
        plannedStart: isoToIsoDateTimeFormat(result.plannedStart),
      };
    }
    return null;
  }

  #initContextMenu(): void {
    const menuRoot = this.bar.menuRoot;
    this.rect.on('contextmenu', (e) => {
      const menuItems = this.#createContextMenuItems();
      if (menuItems.length > 0) {
        e.evt.preventDefault();
        e.evt.stopPropagation();
        if (!this.menu) {
          this.menu = new Menu({ctx: this.ctx});
          this.disposables.push(() => this.menu?.dispose());
          menuRoot.add(this.menu.root);
        }
        //get the transparent hoverlistener as root as it gives a better position
        const r = menuRoot.getStage()?.findOne('.hoverListener');
        if (r instanceof Konva.Rect) {
          const {x, y} = getRelativeMousePosition(r, e.evt);

          this.menu?.root.x(x);
          this.menu?.root.y(y);
          this.menu?.show(this.#createContextMenuItems());
        }
      }
    });
  }

  #createContextMenuItems(): MenuItems {
    const menuItems: MenuItems = [];
    const state = this.ctx.store.getState();
    const getOrgWorkHoursAtDate = getOrgWorkMinutesAtDateSelector(state);
    const canAddGapBar = canAddGapBarSelector(state)(this.nodeId);
    const planningDate = getPlanningDateSelector(state)(this.planningDateId);

    if (!this.planningDateId) {
      throw new Error('planningDateId missing');
    }

    if (
      canAddGapBar &&
      planningDate &&
      workdays(
        fromIsoDateTimeFormat(planningDate.plannedStart),
        fromIsoDateTimeFormat(planningDate.plannedEnd),
        getOrgWorkHoursAtDate,
      ) > 1
    ) {
      menuItems.push({
        icon: Scissors,
        iconColor: 'darkGrey',
        onClick: () => {
          this.ctx.store.dispatch(splitPlanningDate(this.nodeId, this.planningDateId!));
        },
        token: 'pages:projects.inspector.manage.planning.splitGapBar',
      });
    }

    if (this.#prevPlanningDateId) {
      menuItems.push({
        icon: ChevronsRightLeft,
        iconColor: 'darkGrey',
        onClick: () => {
          this.ctx.store.dispatch(mergePlanningDate(this.nodeId, this.planningDateId!, this.#prevPlanningDateId!));
        },
        token: 'pages:projects.inspector.manage.planning.mergeGapBarWithPrevious',
      });
    }

    if (this.#nextPlanningDateId) {
      menuItems.push({
        icon: ChevronsRightLeft,
        iconColor: 'darkGrey',
        onClick: () => {
          this.ctx.store.dispatch(mergePlanningDate(this.nodeId, this.planningDateId!, this.#nextPlanningDateId!));
        },
        token: 'pages:projects.inspector.manage.planning.mergeGapBarWithNext',
      });
    }

    if (this.#nextPlanningDateId || this.#prevPlanningDateId) {
      menuItems.push({
        icon: ChevronsRightLeft,
        iconColor: 'darkGrey',
        onClick: () => {
          this.ctx.store.dispatch(mergeAllPlanningDates(this.nodeId));
        },
        token: 'pages:projects.inspector.manage.planning.mergeAllGapBars',
      });
    }

    if (canDeleteBarSelector(state)(this.nodeId, this.#isFirst, this.#isLast)) {
      menuItems.push(MENU_DIVIDER);
      menuItems.push({
        icon: Trash,
        iconColor: 'darkGrey',
        onClick: () => {
          this.ctx.store.dispatch(removePlanningDate(this.nodeId, this.planningDateId!));
        },
        token: 'pages:projects.inspector.manage.planning.deleteGapBar',
      });
    }
    return menuItems;
  }
}
