import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {getTransTokenForNodeType} from '@octaved/flow/src/Components/Node/NodeTypeName';
import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {createStoreSubscription} from '../../../StoreSubscription';
import {SimpleTextCell} from './SimpleTextCell';

export class TypeCell extends SimpleTextCell {
  protected nodeEntity: NodeEntity | null = null;
  #rootGroupType: EnumFlowGroupType | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getRootGroupTypeSelector(store)(this.node.id),
        (rootGroupType) => {
          this.#rootGroupType = rootGroupType;
          this.render();
        },
      ),
    );
  }

  protected getText(): string {
    if (this.node.type === 'customer' || !this.#rootGroupType) {
      return '';
    }
    return this.ctx.t(getTransTokenForNodeType(this.node.type, this.#rootGroupType));
  }
}
