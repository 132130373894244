import {CriticalTimePrognosis} from '@octaved/flow/src/EntityInterfaces/Projects/ProjectControlling/CriticalTimePrognosis';
import {useSingleCriticalTimePrognosis} from '@octaved/flow/src/Modules/Projects/ProjectControlling/CriticalTimePrognosis';
import {Uuid} from '@octaved/typescript/src/lib';

export function useNodeTaskPrognosis(nodeId: Uuid): {
  isExceeding: boolean;
  prognosis: CriticalTimePrognosis | undefined;
} {
  const {prognosis} = useSingleCriticalTimePrognosis(nodeId);
  return {
    prognosis,
    isExceeding: prognosis.remainingPlannedTime > 0 && prognosis?.remainingTrackedTime < prognosis.remainingPlannedTime,
  };
}
