import {setStateProperty} from './SetStateProperty';
import {isPlainObject} from 'lodash';
import get from 'lodash/get';

/**
 * Creates a new object, sorted by keys
 */
export function sortObject<T extends object>(obj: T): T {
  return Object.entries(obj)
    .sort(([a], [b]) => (a < b ? -1 : 1))
    .reduce<T>((sorted, [key, value]) => {
      sorted[key as keyof T] = value;
      return sorted;
    }, {} as T);
}

/**
 * Sorts obj and its parents starting with path
 */
export function sortUpToRoot<T extends object>(path: string[], obj: T): T {
  let newObj = obj;
  while (path.pop()) {
    if (path.length) {
      const parent = get(newObj, path);
      newObj = setStateProperty(newObj, path, isPlainObject(parent) ? sortObject(parent) : parent);
    } else {
      newObj = sortObject(newObj);
    }
  }
  return newObj;
}
