import {type FlowState} from '@octaved/flow/src/Modules/State';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {ViewTabs} from '../../Components/ViewTabs';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['nodeDetails']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.nodeDetails);
}

export const controllingIncludeArchivedInChartsSelector = ui((state) => state.controlling.includeArchivedInCharts);
export const largeViewTasksfilterValuesSelector = ui((state) => state.largeViewTasks.filterValues);
export const lastInspectorTabSelector = ui((state) => state.lastInsectorTab);

export const intializeLastSelectorInspectorTabSelector = createSelector(
  lastInspectorTabSelector,
  (lastSelectedInspectorTab) =>
    memoize(
      <T extends string>(inspectorTabs: ViewTabs<T>[], defaultTab: T): T => {
        return inspectorTabs.find(({value}) => value === lastSelectedInspectorTab)?.value ?? defaultTab;
      },
      (inspectorTabs, defaultTab) => inspectorTabs.map(({value}) => value).join('-') + '-' + defaultTab,
    ),
);
