import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {acceptInvitationById, declineInvitationById} from '@octaved/identity/config/routes';
import {IdentityOrganizationInvited} from '@octaved/identity/src/Interfaces/Identity';
import {storeCurrentOrganization} from '@octaved/identity/src/Modules/OrganizationSwitcher';
import {post} from '@octaved/network/src/Network';
import {Check, X} from 'lucide-react';
import {Fragment, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface Props {
  organization: IdentityOrganizationInvited;
}

export default function InvitationRow({organization}: Props): ReactElement {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [accept, isAccepting] = useAsyncExecution(async () => {
    dispatch(storeCurrentOrganization()); //make sure there is one selected before we accept a second
    await post(acceptInvitationById, {urlParams: {organizationId: organization.organizationId}});
  });

  const [decline, isDeclining] = useAsyncExecution(async () => {
    await post(declineInvitationById, {urlParams: {organizationId: organization.organizationId}});
  });

  return (
    <Fragment key={organization.organizationId}>
      <div className={'flex h-16 w-full items-center justify-between gap-x-4 px-4'}>
        <div className={'flex flex-grow flex-col overflow-hidden'}>
          <div className={'truncate font-medium text-slate-800'}>{organization.organizationName}</div>
          <div className={'truncate text-sm italic text-green-600'}>
            {t('multiOrganizations:userMenu.organization.invitation', {name: organization.invitedBy})}
          </div>
        </div>
        <div className={'flex flex-shrink-0 items-center gap-x-2'}>
          <button
            onClick={accept}
            disabled={isAccepting || isDeclining}
            className={
              'flex h-9 w-9 items-center justify-center rounded-md bg-green-600 transition-all duration-150 hover:bg-green-700 active:scale-95'
            }
          >
            <Check className={'h-7 w-7 text-white'} />
          </button>
          <button
            onClick={decline}
            disabled={isAccepting || isDeclining}
            className={
              'flex h-9 w-9 items-center justify-center rounded-md bg-red-600 transition-all duration-150 hover:bg-red-700 active:scale-95'
            }
          >
            <X className={'h-7 w-7 text-white'} />
          </button>
        </div>
      </div>
      <div className={'h-px w-full bg-slate-200'} />
    </Fragment>
  );
}
