import {isDevLocal} from '@octaved/env/src/Environment';
import {error} from '@octaved/env/src/Logger';
import {
  deleteTimeTrackingRecord,
  getTimeTrackingRecords,
  patchTimeTrackingRecord,
  putTimeTrackingRecord,
} from '@octaved/flow-api';
import {getDefaultHeader} from '@octaved/network/src/Network';
import NetworkError, {isNetworkError} from '@octaved/network/src/NetworkError';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  INVALIDATED,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {
  optimisticAdd,
  optimisticDelete,
  optimisticUpdate,
  setToReducerMap,
} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch, ThunkDispatch} from '@octaved/store/src/Store';
import {notEmpty, RulesList, validateDateTime, validateLength, validateRules} from '@octaved/store/src/Validation';
import {isDateTimeStr} from '@octaved/typescript/src';
import {Uuid} from '@octaved/typescript/src/lib';
import {dateTimeStrToUnix, ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import objectContains from '@octaved/validation/src/ObjectContains';
import dayjs from 'dayjs';
import {chunk} from 'lodash';
import {extendTimeTrackingSuperUserModeHeaders} from '../Authorization/Virtual/TimeTrackingSuperUserMode';
import {
  CreateTimeRecordData,
  PatchTimeRecordData,
  TimeRecord,
  TimeRecordCreate,
  TimeRecordPatch,
  TimeRecords,
} from '../EntityInterfaces/TimeRecords';
import {isSubWorkPackage, isWorkPackage} from '../Node/NodeIdentifiers';
import {calculateBillingTimes} from '../TimeTracking/Rounding';
import {TimeTrackingErrorField, timeTrackingErrorFields} from '../TimeTracking/WarningErrorFields';
import {
  FLOW_CREATE_TIME_RECORD_FAILURE,
  FLOW_CREATE_TIME_RECORD_REQUEST,
  FLOW_CREATE_TIME_RECORD_SUCCESS,
  FLOW_DELETE_BILLING_SUCCESS,
  FLOW_LOAD_TIME_RECORDS_FAILURE,
  FLOW_LOAD_TIME_RECORDS_REQUEST,
  FLOW_LOAD_TIME_RECORDS_START,
  FLOW_LOAD_TIME_RECORDS_SUCCESS,
  FLOW_PATCH_TIME_RECORD_FAILURE,
  FLOW_PATCH_TIME_RECORD_REQUEST,
  FLOW_PATCH_TIME_RECORD_SUCCESS,
  FLOW_REMOVE_TIME_RECORDS_FAILURE,
  FLOW_REMOVE_TIME_RECORDS_REQUEST,
  FLOW_REMOVE_TIME_RECORDS_SUCCESS,
} from './ActionTypes';
import {
  InternalRoleRightMatrixChangedEvent,
  NodeRestoredFromTrashEvent,
  TimeRecordsPriceCategoryForWorkPackageChangedEvent,
} from './Events';
import {addLabelRemovedEventToReducer} from './ReduceRemovedLabels';
import {timeRecord} from './Schema';
import {GetNodeAncestry, getNodeAncestrySelector, isNodeAncestryWithWp} from './Selectors/NodeTreeSelectors';
import {getWorkPackageSelector} from './Selectors/PidSelectors';
import {forceDescriptionSelector, settingsSelector} from './Selectors/SettingSelectors';
import {
  validateCreateTimeRecordSelector,
  validatePatchTimeRecordSelector,
  validateRemoveTimeRecordSelector,
} from './Selectors/TimeRecordingValidationSelectors';
import {getTimeRecordSelector, timeRecordsEntityStatesSelector} from './Selectors/TimeRecordsSelectors';
import {FlowState} from './State';
import {addError, addErrors, removeErrorForField} from './Ui';

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_TIME_RECORDS_START, createTimestampReducer('ids', LOADING));
stateReducerMap.set(FLOW_LOAD_TIME_RECORDS_SUCCESS, createTimestampReducer('options.urlParams.ids', LOADED));
stateReducerMap.set(FLOW_CREATE_TIME_RECORD_REQUEST, createTimestampReducer('options.data.id', LOADED));
stateReducerMap.set('flow.BillingCreatedEvent', createTimestampReducer('timeRecordIds', INVALIDATED));
addMultiToReducerMap(
  stateReducerMap,
  ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_SUCCESS],
  createTimestampReducer('timeRecordIds', INVALIDATED),
);
stateReducerMap.set('flow.TimeRecordPatchedEvent', createTimestampReducer('timeRecordId', INVALIDATED));
stateReducerMap.set('flow.TimeRecordsRestoredFromTrashEvent', createTimestampReducer('timeRecordIds', INVALIDATED));
stateReducerMap.set('flow.NodeRestoredFromTrashEvent', (state: EntityStates, action: NodeRestoredFromTrashEvent) => {
  const allTimeRecordIds = action.timeRecordsRestoredEvents.reduce<string[]>(
    (acc, {timeRecordIds}) => acc.concat(...timeRecordIds),
    [],
  );
  if (allTimeRecordIds.length) {
    return createTimestampReducer('allTimeRecordIds', INVALIDATED)(state, {allTimeRecordIds});
  }
  return state;
});

//clear because any record could now be fully readable or anonymized:
addMultiToReducerMap(
  stateReducerMap,
  ['flow.GuestRoleRightMatrixChangedEvent', 'flow.myAccessibleTimeTrackingsChanged'],
  (): EntityStates => ({}),
);

stateReducerMap.set(
  'flow.InternalRoleRightMatrixChangedEvent',
  (state: EntityStates, action: InternalRoleRightMatrixChangedEvent): EntityStates => {
    if (action.affectedRightIdents.includes('FLOW_NODE_PROJECT_TIME_TRACKING_READ_OTHERS_FULL')) {
      return {};
    }
    return state;
  },
);

export const timeRecordEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion
//#region Entitiy Reducer
const entityReducerMap = new Map();

setToReducerMap(
  optimisticUpdate,
  entityReducerMap,
  FLOW_PATCH_TIME_RECORD_REQUEST,
  FLOW_PATCH_TIME_RECORD_FAILURE,
  FLOW_PATCH_TIME_RECORD_SUCCESS,
  ({
    options,
  }: ServerRequestAction<{
    urlParams: {timeTrackingRecordId: Uuid};
  }>) => options.urlParams.timeTrackingRecordId,
);
setToReducerMap(
  optimisticAdd,
  entityReducerMap,
  FLOW_CREATE_TIME_RECORD_REQUEST,
  FLOW_CREATE_TIME_RECORD_FAILURE,
  FLOW_CREATE_TIME_RECORD_SUCCESS,
  ({
    options,
  }: ServerRequestAction<{
    data: TimeRecord;
  }>) => options.data.id,
  (action): TimeRecord | null => action?.options?.data || null,
);
setToReducerMap(
  optimisticDelete,
  entityReducerMap,
  FLOW_REMOVE_TIME_RECORDS_REQUEST,
  FLOW_REMOVE_TIME_RECORDS_FAILURE,
  FLOW_REMOVE_TIME_RECORDS_SUCCESS,
  ({
    options,
  }: ServerRequestAction<{
    urlParams: {timeTrackingRecordId: Uuid};
  }>) => options.urlParams.timeTrackingRecordId,
);

entityReducerMap.set('flow.NodesRemovedEvent', (state: TimeRecords, {nodeIds}: {nodeIds: Uuid[]}): TimeRecords => {
  let changed = false;
  const newState = {...state};
  for (const timeRecord of Object.values(state)) {
    if (timeRecord?.workPackage && nodeIds.includes(timeRecord.workPackage)) {
      delete newState[timeRecord.id];
      changed = true;
    }
  }
  return changed ? newState : state;
});

entityReducerMap.set(
  'flow.TimeRecordsPriceCategoryForWorkPackageChangedEvent',
  (
    state: TimeRecords,
    {workPackageId, priceCategoryId}: TimeRecordsPriceCategoryForWorkPackageChangedEvent,
  ): TimeRecords => {
    let changed = false;
    const newState = {...state};
    Object.values(state).forEach((timeRecord) => {
      if (timeRecord && timeRecord.workPackage === workPackageId && timeRecord.priceCategory !== priceCategoryId) {
        newState[timeRecord.id] = {
          ...newState[timeRecord.id]!,
          priceCategory: priceCategoryId,
        };
        changed = true;
      }
    });
    return changed ? newState : state;
  },
);

entityReducerMap.set(
  FLOW_LOAD_TIME_RECORDS_SUCCESS,
  (
    state: TimeRecords,
    {
      response: {
        entities: {timeRecord},
      },
    }: {response: {entities: {timeRecord: TimeRecords | undefined}}},
  ): TimeRecords => {
    if (!timeRecord) {
      return state;
    }
    const newState = {...state};
    let changed = false;
    Object.values(timeRecord).forEach((record) => {
      if (record && record.user !== newState[record.id]?.user) {
        newState[record.id] = record; //make sure the user is also unset in the store
        changed = true;
      }
    });
    return changed ? newState : state;
  },
);

addLabelRemovedEventToReducer(entityReducerMap);

export const timeRecordEntityReducer = ReduceFromMap(entityReducerMap);

//#endregion

export function getTimeTrackingRecordFormValidationErrorFields(_timeRecordId: Uuid): string[] {
  return ['recordDate', 'workTimeStart', 'workTimeEnd', 'message'];
}

//region validation
function getValidationRules(record: PatchTimeRecordData, forceMessage: boolean): RulesList {
  return [
    [
      validateDateTime,
      record.workTimeStart,
      'timeRecordings:invalidRecordStart',
      'timeRecordings:emptyRecordStart',
      'workTimeStart',
    ],
    Boolean(record.workTimeEnd) && [
      validateDateTime,
      record.workTimeEnd,
      'timeRecordings:invalidRecordEnd',
      '', //cannot be empty
      'workTimeEnd',
    ],
    Boolean(record.message) && [validateLength, record.message, 'timeRecordings:messageToLong', 'message', 5000],
    Boolean(record.hasOwnProperty('message') && forceMessage) && [
      notEmpty,
      record.message,
      'timeRecordings:errors.messageMissing',
      'message',
    ],
  ];
}

interface ValidationBadRequest {
  code: TimeTrackingErrorField;
  message: string;
}

function isValidationBadRequest(e: unknown): e is NetworkError<ValidationBadRequest> {
  type Data = Partial<ValidationBadRequest>;
  return (
    isNetworkError(e, 400) &&
    !!e.data &&
    typeof e.data === 'object' &&
    typeof (e.data as Data).code === 'string' &&
    typeof (e.data as Data).code!.startsWith('timeTracking_') &&
    typeof (e.data as Data).message === 'string'
  );
}

async function trySave(dispatch: Dispatch, fn: () => Promise<void>): Promise<boolean> {
  try {
    await fn();
    return true;
  } catch (e) {
    if (isValidationBadRequest(e)) {
      dispatch(addError({field: e.data.code, message: e.data.message}));
    } else {
      error(e);
    }
    return false;
  }
}

export function loadTimeRecordsById(timeRecordIds: ReadonlyArray<Uuid>): ActionDispatcher<void, FlowState> {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const toLoad = filterIdsToReload(timeRecordsEntityStatesSelector(state), timeRecordIds);
    if (toLoad.length === 0) {
      return;
    }
    dispatch({ids: toLoad, type: FLOW_LOAD_TIME_RECORDS_START});
    const chunks = chunk(toLoad, 100);
    for (const ids of chunks) {
      dispatch({
        [CALL_API]: {
          endpoint: getTimeTrackingRecords,
          options: {
            urlParams: {ids},
          },
          schema: [timeRecord],
          types: {
            failureType: FLOW_LOAD_TIME_RECORDS_FAILURE,
            requestType: FLOW_LOAD_TIME_RECORDS_REQUEST,
            successType: FLOW_LOAD_TIME_RECORDS_SUCCESS,
          },
        },
      });
    }
  };
}

function getSaveHeaders(): Record<string, string> {
  return extendTimeTrackingSuperUserModeHeaders(getDefaultHeader());
}

export function removeTimeRecord(timeTrackingRecordId: Uuid): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const errors = validateRemoveTimeRecordSelector(state)(timeTrackingRecordId);
    if (errors.length) {
      dispatch(addErrors(errors));
      return false;
    } else {
      dispatch(removeErrorForField(timeTrackingErrorFields));
    }
    return await trySave(dispatch, () =>
      dispatch({
        [CALL_API]: {
          endpoint: deleteTimeTrackingRecord,
          headers: getSaveHeaders(),
          method: 'del',
          options: {
            urlParams: {timeTrackingRecordId},
          },
          throwNetworkError: true,
          types: {
            failureType: FLOW_REMOVE_TIME_RECORDS_FAILURE,
            requestType: FLOW_REMOVE_TIME_RECORDS_REQUEST,
            successType: FLOW_REMOVE_TIME_RECORDS_SUCCESS,
          },
        },
      }),
    );
  };
}

export function createPatchData(data: PatchTimeRecordData, getNodeAncestry: GetNodeAncestry): TimeRecordPatch {
  let workTimeEnd: number | null = null;
  let workTimeStart: number | null = null;

  if (isDateTimeStr(data.workTimeEnd)) {
    workTimeEnd = dateTimeStrToUnix(data.workTimeEnd);
  }

  if (isDateTimeStr(data.workTimeStart)) {
    workTimeStart = dateTimeStrToUnix(data.workTimeStart);
  }

  const patch: TimeRecordPatch = {
    workTimeEnd,
    workTimeStart,
  };

  if (data.hasOwnProperty('isJourney')) {
    patch.isJourney = data.isJourney;
  }
  if (data.hasOwnProperty('referenceNode')) {
    patch.referenceNode = data.referenceNode ?? null;
    const {workPackage} = getNodeAncestry(data.referenceNode, true);
    patch.workPackage = workPackage?.id ?? null;
    if (patch.referenceNode && !patch.workPackage) {
      throw new Error(`Invalid reference node for time record - work package missing!`);
    }
  }
  if (data.hasOwnProperty('message')) {
    patch.message = data.message;
  }
  if (data.hasOwnProperty('labels')) {
    patch.labels = data.labels;
  }
  if (data.hasOwnProperty('priceSurcharge')) {
    patch.priceSurcharge = data.priceSurcharge;
  }
  if (data.hasOwnProperty('priceCategory')) {
    patch.priceCategory = data.priceCategory;
  }
  if (data.isGroupBooking) {
    patch.isGroupBooking = data.isGroupBooking;
    if (data.bookingFor) {
      patch.bookingFor = data.bookingFor;
    }
  }
  if (data.user) {
    patch.user = data.user;
  }
  return patch;
}

function validateFormRules(recordId: Uuid, patch: PatchTimeRecordData): ActionDispatcher<boolean, FlowState> {
  return (dispatch: ThunkDispatch, getState) => {
    const state = getState();
    const forceDescription = forceDescriptionSelector(state);
    const rules: RulesList = getValidationRules(patch, forceDescription);
    const errors = validateRules(rules);
    if (errors.length) {
      dispatch(addErrors(errors));
      return false;
    }
    dispatch(removeErrorForField(getTimeTrackingRecordFormValidationErrorFields(recordId)));
    return true;
  };
}

function throwInDevLocal(msg: string): void {
  if (isDevLocal) {
    throw new Error(msg);
  } else {
    error(msg);
  }
}

function syncWpAndReferenceNode(data: PatchTimeRecordData, state: FlowState): void {
  if (typeof data.referenceNode !== 'undefined') {
    if (data.referenceNode) {
      const ancestry = getNodeAncestrySelector(state)(data.referenceNode, true);
      const refNode = ancestry.ancestors[0];
      if (!isWorkPackage(refNode) && !isSubWorkPackage(refNode)) {
        throwInDevLocal('The referenced node must be a work package or sub work package');
      }
      if (isNodeAncestryWithWp(ancestry)) {
        return;
      }
    }

    //If the above doesn't return by finding a wp, remove both:
    data.referenceNode = null;
  }
}

export function createTimeRecord(
  timeTrackingRecordId: Uuid,
  createData: CreateTimeRecordData,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    syncWpAndReferenceNode(createData, state);

    if (!dispatch(validateFormRules(timeTrackingRecordId, createData))) {
      return false;
    }

    const record = {
      ...createPatchData(createData, getNodeAncestrySelector(state)),
      id: timeTrackingRecordId,
    };

    const settings = settingsSelector(state);
    calculateBillingTimes(settings, record);

    return dispatchCreateRequest(record)(dispatch, getState);
  };
}

function createTimeRecordEntity(record: TimeRecordCreate): TimeRecord {
  return {
    billedOn: null,
    billingEnd: null,
    billingStart: null,
    isGroupBooking: false,
    isJourney: false,
    labels: [],
    message: '',
    priceCategory: null,
    priceSurcharge: null,
    referenceNode: null,
    workPackage: null,
    workTimeEnd: null,
    workTimeStart: null,
    ...record,
  };
}

function dispatchCreateRequest(record: TimeRecordCreate): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const errors = validateCreateTimeRecordSelector(state)(record);
    if (errors.length) {
      dispatch(addErrors(errors));
      return false;
    } else {
      dispatch(removeErrorForField(timeTrackingErrorFields));
    }

    const timeRecord = createTimeRecordEntity(record);

    const day = dayjs.unix(record.workTimeStart!).format(ISO_DATE);
    return await trySave(dispatch, () =>
      dispatch({
        day,
        [CALL_API]: {
          endpoint: putTimeTrackingRecord,
          headers: getSaveHeaders(),
          method: 'put',
          options: {
            data: timeRecord,
            urlParams: {
              timeTrackingRecordId: record.id,
            },
          },
          throwNetworkError: true,
          types: {
            failureType: FLOW_CREATE_TIME_RECORD_FAILURE,
            requestType: FLOW_CREATE_TIME_RECORD_REQUEST,
            successType: FLOW_CREATE_TIME_RECORD_SUCCESS,
          },
        },
      }),
    );
  };
}

export function patchTimeRecord(
  timeTrackingRecordId: Uuid,
  patchData: PatchTimeRecordData,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch: ThunkDispatch, getState) => {
    const state = getState();
    syncWpAndReferenceNode(patchData, state);

    if (!dispatch(validateFormRules(timeTrackingRecordId, patchData))) {
      return false;
    }

    const fullRecord = getTimeRecordSelector(state)(timeTrackingRecordId);
    if (!fullRecord) {
      return false;
    }

    const record = createPatchData(patchData, getNodeAncestrySelector(state));

    const settings = settingsSelector(state);
    calculateBillingTimes(settings, record);

    return dispatch(doPatchTimeRecord(timeTrackingRecordId, record));
  };
}

function removePriceCategoryIfNotRequired(state: FlowState, patchData: TimeRecordPatch, record: TimeRecord): void {
  const workPackageId = typeof patchData.workPackage !== 'undefined' ? patchData.workPackage : record.workPackage;
  const workPackage = workPackageId ? getWorkPackageSelector(state)(workPackageId) : null;
  const priceCategoryId =
    typeof patchData.priceCategory !== 'undefined' ? patchData.priceCategory : record.priceCategory;
  const usePriceCategory = workPackage && workPackage.usePriceCategoryPerTimeTracking;
  if (!usePriceCategory && priceCategoryId) {
    patchData.priceCategory = null;
  }
}

function doPatchTimeRecord(
  timeTrackingRecordId: Uuid,
  patchData: TimeRecordPatch,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const errors = validatePatchTimeRecordSelector(state)(timeTrackingRecordId, patchData);
    if (errors.length) {
      dispatch(addErrors(errors));
      return false;
    } else {
      dispatch(removeErrorForField(timeTrackingErrorFields));
    }
    const record = getTimeRecordSelector(state)(timeTrackingRecordId);
    if (record && !objectContains(record, patchData)) {
      removePriceCategoryIfNotRequired(state, patchData, record);

      return await trySave(dispatch, () =>
        dispatch({
          [CALL_API]: {
            endpoint: patchTimeTrackingRecord,
            headers: getSaveHeaders(),
            method: 'patch',
            options: {
              data: patchData,
              urlParams: {
                timeTrackingRecordId,
              },
            },
            throwNetworkError: true,
            types: {
              failureType: FLOW_PATCH_TIME_RECORD_FAILURE,
              requestType: FLOW_PATCH_TIME_RECORD_REQUEST,
              successType: FLOW_PATCH_TIME_RECORD_SUCCESS,
            },
          },
        }),
      );
    }
    return true;
  };
}
