import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import TaskListContext from '@octaved/flow/src/Components/Task/TaskListContext';
import TaskListForNode from '@octaved/flow/src/Components/Task/TaskListForNode/TaskListForNode';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {TaskListColumn} from '@octaved/flow/src/Modules/Ui/TaskList';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import {DependencyType} from '../../Selectors/Validation/ValidateCircularDependencySelector';
import NoLogicalTaskPlaceholder from './NoLogicalTaskPlaceholder';
import NoTasksPlaceholder from './NoTasksPlaceholder';
import TaskGroup from './TaskGroup';
import TaskRow from './TaskRow';

interface TaskDependencySelectorProps {
  dependencyType?: DependencyType;
  excludedNodeIds: Set<Uuid>;
  onSelect: (id: Uuid | null) => void;
  plannedOnly: boolean;
  workPackageId: Uuid;
}

const visibleColumns = new Set<TaskListColumn>();

export default function TaskDependencySelector({
  dependencyType,
  excludedNodeIds,
  onSelect,
  plannedOnly,
  workPackageId,
}: TaskDependencySelectorProps): ReactElement {
  const taskFilterQuery = useMemo((): NodeSearchCondition => {
    const conditions: NodeSearchCondition[] = [['nodeType', EnumFlowNodeType.VALUE_TASK]];
    if (plannedOnly) {
      conditions.push({not: ['nodeNotPlanned']});
    }
    if (excludedNodeIds.size) {
      conditions.push({not: {fixResult: [...excludedNodeIds]}});
    }
    return {
      and: conditions,
    };
  }, [excludedNodeIds, plannedOnly]);

  return (
    <div className={'taskList'}>
      <TaskListContext
        readonly
        selectTaskId={onSelect}
        showAdvancedPlanning
        visibleColumns={visibleColumns}
        allowCreationOfTasks={false}
        TaskRow={TaskRow}
        TaskGroup={TaskGroup}
        hideEmptyTaskSections
        hideEmptyTaskList
        EmptyTaskRowBoxPlaceholder={dependencyType === 'logical' ? NoLogicalTaskPlaceholder : NoTasksPlaceholder}
      >
        <TaskListForNode nodeId={workPackageId} taskFilterQuerySelector={() => taskFilterQuery} />
      </TaskListContext>

      <style jsx>{`
        .taskList :global(.tableHeader) {
          --drag-handle-width: 0px;
        }

        .taskList :global(.tableHeader .nameCellContent) {
          padding: 0 12px;
        }
      `}</style>
    </div>
  );
}
