import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserSettingsSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import intersection from 'lodash/intersection';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {ProjectFilterStates} from '../../../EntityInterfaces/Filter/ProjectFilters';
import {ProjectPages} from '../../../Pages/Projects/ProjectContext';
import {doFilterStatesDeviateFromDefaults} from '../../Filter/CompareFilterStates';
import {mergeFilterStatesWithDefaults} from '../../Filter/MergeFilterStatesWithDefaults';
import {defaultProjectFilterStates} from '../../Projects/Filters/ProjectDefaultFilters';
import {FlowState} from '../../State';
import {getAllDescendantIdsSelector} from '../NodeTreeSelectors';
import {rootFoldersProjectFolderSelector} from '../RootFolderSelectors';

export function projectUi<R>(
  selector: (state: FlowState['ui']['pages']['projects']) => R,
): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.projects);
}

export const showProjectFolderSelector = projectUi((state) => state.showProjectFolder);
export const projctsleftProjectsFolderCollapsedSelector = projectUi((state) => state.collapsedLeftFolder);
export const projectsSubMenuCollapsedSelector = projectUi((state) => state.collapsedSubMenu);

export const projectsMovePidSelector = projectUi((state) => state.moveGroupOrWorkPackage);

export const projectsGroupProjectsSelector = projectUi((state) => state.groupProjects);
export const projectsInspectorTimeTrackingShowDaysInsteadOfHoursSelector = projectUi(
  (state) => state.inspector.timeTracking.showDaysInsteadOfHours,
);
export const projectsSortProjectsBySelector = projectUi((state) => state.sortProjectsBy);
export const projectsRearrangeProjectSelector = projectUi((state) => state.rearrangeProject);
export const projectsDraggedPidIdSelector = projectUi((state) => state.draggedPidId);
export const projectsMainProjectTreeApiSelector = projectUi((state) => state.mainProjectTreeApi);

export const projectsShowOnlyFavoriteProjectsSelector = projectUi((state) => state.showOnlyFavoriteProjects);
export const projectsShowSubFolderProjectsSelector = projectUi((state) => state.showSubFolderProjects);
export const projectsTimesheetsExternalPeriodSelector = projectUi((state) => state.timesheetsExternal.period);
export const projectsTimesheetsExternalSearchSelector = projectUi((state) => state.timesheetsExternal.search);

export const projectControllingShowDaysInsteadHoursSelector = projectUi(
  (state) => state.projectControlling.showDaysInsteadHours,
);
export const projectControllingDemoModeSelector = projectUi((state) => state.projectControlling.demoMode);

const projectsAdvancedFilterStatesSelector = createSelector(
  currentOrgUserSettingsSelector,
  (userSettings) => userSettings.projects?.advancedFilterStates,
);

export const getProjectsFilterStatesSelector = createSelector(
  projectsAdvancedFilterStatesSelector,
  (advancedFilterStates) =>
    memoize((page: string): Partial<ProjectFilterStates> => {
      return mergeFilterStatesWithDefaults(advancedFilterStates, defaultProjectFilterStates, page);
    }),
);

export function requireDefaultValue(page: string, ident: keyof ProjectFilterStates): unknown {
  const filter = defaultProjectFilterStates[page][ident];
  if (!filter || !filter.hasOwnProperty('value')) {
    throw new Error(`Missing default project filter value for page '${page}' and ident '${ident}'`);
  }
  return filter.value;
}

export const getProjectsFilterValueSelector = createSelector(
  getProjectsFilterStatesSelector,
  (getProjectsFilterStates) =>
    memoize(
      (page: string, ident: keyof ProjectFilterStates) =>
        getProjectsFilterStates(page)[ident]?.value || requireDefaultValue(page, ident),
      (page: string, ident: keyof ProjectFilterStates) => `${page}-${ident}`,
    ),
) as (
  state: FlowState,
) => <I extends keyof ProjectFilterStates>(page: string, ident: I) => ProjectFilterStates[I]['value'];

export const projectsAdvancedFilterIsActiveSelector = createSelector(
  getProjectsFilterStatesSelector,
  (getProjectsFilterStates) =>
    memoize((page: ProjectPages): boolean => {
      return doFilterStatesDeviateFromDefaults(getProjectsFilterStates(page), defaultProjectFilterStates[page]);
    }),
);

const selectedProjectFolderSelector = projectUi((state) => state.selectedProjectFolder);
export const projectsSelectedProjectFolderSelector = createSelector(
  selectedProjectFolderSelector,
  rootFoldersProjectFolderSelector,
  isGrantedSelector,
  (selected, rootProjectFolder, isGranted) => {
    //If we have a selection and can still read it, use it:
    if (selected && isGranted('FLOW_NODE_PROJECT_FOLDER_READ_BASIC', selected)) {
      return selected;
    }
    //Fall back to root-folder:
    return rootProjectFolder;
  },
);

export const projectsMainProjectTreeVisibleNodeIdsSetSelector = createSelector(
  projectsMainProjectTreeApiSelector,
  ({projectIds, groupIds, workPackageIds}): ReadonlySet<Uuid> => {
    return new Set([...projectIds, ...groupIds, ...workPackageIds]);
  },
);

export const getWorkPackageIdsForRootNodeIdInMainProjectTreeSelector = createSelector(
  projectsMainProjectTreeApiSelector,
  getAllDescendantIdsSelector,
  ({workPackageIds}, getAllDescendantIds) =>
    memoize((rootNodeId: Uuid): Uuid[] => intersection(workPackageIds, [...getAllDescendantIds(rootNodeId, true)])),
);

const emptyIds: Uuid[] = [];

export const workPackageIdsBeneathSelectedNodeInMainProjectTreeSelector = createSelector(
  getWorkPackageIdsForRootNodeIdInMainProjectTreeSelector,
  (getWorkPackageIdsForRootNodeIdInMainProjectTree) =>
    memoize((projectsSelectedId: Uuid | undefined) => {
      return projectsSelectedId ? getWorkPackageIdsForRootNodeIdInMainProjectTree(projectsSelectedId) : emptyIds;
    }),
);

export const workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector = createSelector(
  workPackageIdsBeneathSelectedNodeInMainProjectTreeSelector,
  (getWorkPackageIds) =>
    memoize((nodeId: Uuid | undefined): {key: string; workPackageIds: Uuid[]} => {
      const workPackageIds = getWorkPackageIds(nodeId);
      return {
        workPackageIds,
        key: [...workPackageIds].sort().join('-'),
      };
    }),
);
