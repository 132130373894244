import {getNodeColorSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import Konva from 'konva';
import {ArrowDown} from 'lucide';
import {HoverRowChangedEvent} from '../../Calendar/Context/CalendarContext';
import {resetCursor, setMouseHoverEffect} from '../../MouseHoverCursor';
import {Bar} from '../Bar';
import {BarPlugin} from './BarPlugin';

export class ExpandArrowPlugin extends BarPlugin {
  static readonly imageSize = 14;
  #leftArrow: Konva.Image | null = null;
  #rightArrow: Konva.Image | null = null;
  #clickArea = new Konva.Rect({fill: 'transparent'});

  init(bar: Bar): void {
    super.init(bar);

    //no need for updates as the row will get re-rendered if the tree node is expanded
    if (!this.bar.treeNode.isExpanded) {
      this.#createIcons();
      this.disposables.push(this.ctx.eventEmitter.on('hoverRowChanged', this.#onHover));
      this.#clickArea.on('click', () => {
        this.bar.treeNode.isExpanded = true;
        resetCursor();
      });
      this.root.add(this.#clickArea);
      setMouseHoverEffect(this.#clickArea, 'pointer');
    }
  }

  #createIcons(): void {
    this.#createIcon().then((icon) => {
      this.#leftArrow = icon.clone();
      this.#rightArrow = icon;
      this.root.add(this.#leftArrow!);
      this.root.add(this.#rightArrow);
      this.#updatePosition();
    });
  }

  #createIcon(): Promise<Konva.Image> {
    const y = 3;
    const stroke = getNodeColorSelector(this.ctx.store.getState())(this.nodeId);

    return this.iconToImage(ArrowDown, {
      height: ExpandArrowPlugin.imageSize,
      imageConfig: {
        y,
        listening: false,
        visible: false,
      },
      style: {
        stroke: `#${stroke}`,
      },
      width: ExpandArrowPlugin.imageSize,
    });
  }

  #onHover = (value: HoverRowChangedEvent | null): void => {
    if (value?.nodeId === this.nodeId) {
      this.#rightArrow?.visible(this.bar.width > 50);
      this.#leftArrow?.visible(true);
    } else {
      this.#rightArrow?.visible(false);
      this.#leftArrow?.visible(false);
    }
  };

  #updatePosition(): void {
    if (!this.bar.treeNode.isExpanded) {
      if (this.bar.width > 50) {
        //show both arrows
        const distance = this.bar.width / 3;
        this.#leftArrow?.x(distance);
        this.#rightArrow?.x(this.bar.width - distance);
      } else {
        //show only left arrow
        const distance = this.bar.width / 3;
        this.#leftArrow?.x(distance);
      }
      this.#clickArea.width(this.bar.width);
      this.#clickArea.height(this.bar.height);
    }
  }

  onBarUpdated(): void {
    super.onBarUpdated();
    this.#updatePosition();
  }
}
