import {cn} from '@octaved/ui';
import {ReactElement, ReactNode} from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  fixedHeight?: boolean;
  noPadding?: boolean;
  width: string;
}

export default function InspectorDrawerPage({children, className, fixedHeight, noPadding, width}: Props): ReactElement {
  return (
    <div
      className={cn('flex-shrink-0 px-5 py-6', noPadding && 'p-0', fixedHeight && 'h-full overflow-hidden', className)}
      style={{width}}
    >
      {children}
    </div>
  );
}
