import {Uuid} from '@octaved/typescript/src/lib';
import {generateUuid, pushOnArrayMap} from '@octaved/utilities';
import {Dispatch, SetStateAction, useEffect, useMemo, useRef} from 'react';
import {ProjectsUiState} from '../UiPages/Projects';
import {createProjectTreeParentNode, createProjectTreeSelectableNode} from './BuildProjectTree';
import {ProjectTreeNode, ProjectTreeProject, ProjectTreeProjectsGroup} from './ProjectTreeInterfaces';

type GroupConfig = {
  [K in ProjectsUiState['sortProjectsBy']]: {
    getGroupDisplayName: (groupKey: string, project: ProjectTreeProject) => string;
    getGroupKey: (treeProject: ProjectTreeProject) => string;
    getGroupUuid?: (groupKey: string) => Uuid;
  };
};

const groupConfigs: GroupConfig = {
  alphanumeric: {
    getGroupDisplayName: (groupKey) => groupKey,
    getGroupKey: (treeProject) => treeProject.entity?.name?.[0] || '',
  },
  customer: {
    getGroupDisplayName: (_groupKey, treeProject) => treeProject.customer?.name || '',
    getGroupKey: (treeProject) => treeProject.customer?.id || '',
    getGroupUuid: (groupKey) => groupKey,
  },
  pm: {
    getGroupDisplayName: (groupKey) => groupKey,
    getGroupKey: (treeProject) => treeProject.projectManagersJoined,
  },
};

const idCache = new Map<string, Uuid>();

/**
 * Generates a new unique id for the grouping. Grouping ids are not shareable.
 */
function getTempUuid(groupBy: ProjectsUiState['sortProjectsBy'], groupKey: string): Uuid {
  const cacheKey = `${groupBy}-${groupKey}`;
  if (!idCache.has(cacheKey)) {
    idCache.set(cacheKey, generateUuid());
  }
  return idCache.get(cacheKey)!;
}

function doGroup(
  treeProjects: ProjectTreeProject[],
  groupBy: ProjectsUiState['sortProjectsBy'],
  selectNodeId: ((id: string) => void) | undefined,
  selectedNodeId: string | null | undefined,
  expandedNodeIds: ReadonlySet<Uuid>,
  setExpandedNodeIds: Dispatch<SetStateAction<ReadonlySet<Uuid>>>,
  allNodes: Map<Uuid, ProjectTreeNode>,
): {isFullyGrouped: boolean; grouped: ProjectTreeProjectsGroup[]} {
  const groupConfig = groupConfigs[groupBy];
  const map = new Map<string, ProjectTreeProject[]>();
  const isFullyGrouped = treeProjects.reduce((isGroupedComplete, node) => {
    const groupKey = groupConfig.getGroupKey(node);
    pushOnArrayMap(map, groupKey, node);
    return !!groupKey && isGroupedComplete;
  }, true);
  const grouped = [...map]
    .map(([groupKey, grouped]) => {
      const id = groupConfig.getGroupUuid ? groupConfig.getGroupUuid(groupKey) : getTempUuid(groupBy, groupKey);
      const projectsGroup = {
        ...createProjectTreeSelectableNode(selectNodeId, selectedNodeId, id),
        ...createProjectTreeParentNode(expandedNodeIds, setExpandedNodeIds, id, grouped),
        id,
        groupDisplayName: groupConfig.getGroupDisplayName(groupKey, grouped[0]),
        groupedBy: groupBy,
        type: 'projectsGrouping' as const,
      };
      allNodes.set(id, projectsGroup);
      return projectsGroup;
    })
    .sort((a, b) => a.groupDisplayName.localeCompare(b.groupDisplayName));
  return {
    grouped,
    isFullyGrouped,
  };
}

const emptyProjects: ProjectTreeProject[] = [];
const emptyGroups: {isFullyGrouped: boolean; grouped: ProjectTreeProjectsGroup[]} = {
  grouped: [],
  isFullyGrouped: true,
};

export function useProjectsGrouping(
  treeProjects: ProjectTreeProject[],
  useGrouping: boolean | undefined,
  groupBy: ProjectsUiState['sortProjectsBy'],
  selectNodeId: ((id: string) => void) | undefined,
  selectedNodeId: string | null | undefined,
  expandedNodeIds: ReadonlySet<Uuid>,
  setExpandedNodeIds: Dispatch<SetStateAction<ReadonlySet<Uuid>>>,
  isSearchLoading: boolean,
  searchTerm: string | undefined,
  searchedNodeIds: ReadonlySet<Uuid> | null,
  searchedNodeIdsTrace: ReadonlySet<Uuid> | null,
  setSearchExpandedNodeIds: Dispatch<SetStateAction<ReadonlySet<Uuid>>>,
  allNodes: Map<Uuid, ProjectTreeNode>,
): {
  flat: ProjectTreeProject[];
  grouped: ProjectTreeProjectsGroup[];
  isFullyGrouped: boolean;
} {
  const useGroupingRef = useRef(useGrouping);
  useGroupingRef.current = useGrouping;

  const {isFullyGrouped, grouped} = useMemo(
    () =>
      useGrouping
        ? doGroup(treeProjects, groupBy, selectNodeId, selectedNodeId, expandedNodeIds, setExpandedNodeIds, allNodes)
        : emptyGroups,
    [allNodes, useGrouping, treeProjects, groupBy, selectNodeId, selectedNodeId, expandedNodeIds, setExpandedNodeIds],
  );

  const groupedRef = useRef(grouped);
  groupedRef.current = grouped;

  //When the search changes, add the group ids to the expansion set:
  useEffect(() => {
    if (useGroupingRef.current && searchTerm && !isSearchLoading && searchedNodeIds && searchedNodeIdsTrace) {
      setSearchExpandedNodeIds((set) => {
        const newSet = new Set(set);
        groupedRef.current.forEach(({id, children}) => {
          if (children.some(({id}) => searchedNodeIds.has(id) || searchedNodeIdsTrace.has(id))) {
            newSet.add(id);
          }
        });
        return newSet;
      });
    }
  }, [isSearchLoading, searchTerm, searchedNodeIds, searchedNodeIdsTrace, setSearchExpandedNodeIds]);

  return {
    grouped,
    isFullyGrouped,
    flat: useGrouping ? emptyProjects : treeProjects,
  };
}
