import {objectEntries} from '../Object';
import {
  AnyCondition,
  CombinersShape,
  isAndCondition,
  isCustomCombiner,
  isFixResult,
  isNotCondition,
  isOrCondition,
  isTransformer,
} from './Types';

let objectIdentityCount = 0;
const objectIdentityMap = new WeakMap();

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
function getObjectIdentity(fn: Function | object): number {
  let num = objectIdentityMap.get(fn);
  if (!num) {
    objectIdentityCount++;
    num = objectIdentityCount;
    objectIdentityMap.set(fn, num);
    // console.log('using new object identity', fn);
  }
  return num;
}

type CachableCondition = AnyCondition<object>;
const cache = new WeakMap<CachableCondition, string>();

/**
 * The `soft` flag produces a key that only looks at the structure of the query, not the content.
 * Meaning, the same and/or-structures and the same search idents, but not the same transform-functions, fixed results,
 * or search values.
 */
function hashRecursive<C, T, Combiners extends CombinersShape<T>>(
  combiners: Combiners,
  condition: AnyCondition<C, T, Combiners>,
): string {
  if (isNotCondition(condition)) {
    return `[!${doHashCondition(combiners, condition.not)}]`;
  } else if (isAndCondition(condition)) {
    //Must have a leading "&" to differentiate an empty `and` from an empty `or`:
    return `[&${condition.and.map((c) => doHashCondition(combiners, c)).join('&')}]`;
  } else if (isOrCondition(condition)) {
    //Must have a leading "|" to differentiate an empty `and` from an empty `or`:
    return `[|${condition.or.map((c) => doHashCondition(combiners, c)).join('|')}]`;
  } else if (isTransformer(condition)) {
    const transform = condition.transform[1];
    return `[${doHashCondition(combiners, condition.transform[0])}>${
      typeof transform === 'function' ? `fn[${getObjectIdentity(transform)}]` : transform
    }]`;
  } else if (isCustomCombiner(condition)) {
    const {ident, options, sources} = condition.combine;
    const opts = options ? JSON.stringify(options) : '';
    const src = JSON.stringify(
      Object.fromEntries(objectEntries(sources).map(([k, v]) => [k, doHashCondition(combiners, v)])),
    );
    return `[cb:${String(ident)}:${getObjectIdentity(combiners)}:${opts}:${src}]`;
  } else if (isFixResult(condition)) {
    //Must have a leading character to differentiate from empty `and`/`or`:
    return `[x${condition.fixResult.join(',')}]`;
  } else {
    return JSON.stringify(condition);
  }
}

function doHashCondition<C, T, Combiners extends CombinersShape<T>>(
  combiners: Combiners,
  condition: AnyCondition<C, T, Combiners> | null,
): string {
  if (condition === null) {
    return '';
  }
  if (typeof condition !== 'object') {
    return JSON.stringify(condition);
  }
  let hash = cache.get(condition as CachableCondition);
  if (!hash) {
    hash = hashRecursive(combiners, condition);
    cache.set(condition as CachableCondition, hash);
  }
  return hash;
}

export function hashCondition<C, T, Combiners extends CombinersShape<T>>(
  combiners: Combiners,
  condition: AnyCondition<C, T, Combiners> | null,
): string {
  return doHashCondition(combiners, condition);
}
