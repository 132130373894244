import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import PermissionInspectorContext from '../../../../Drawer/InspectorContext/PermissionInspectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const permissionRoute = 'permission';

const EditPermission = lazy(() => import('../../../../Pages/SystemSettings/Permission/Inspector/EditPermission'));

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['group']: [
    {
      path: permissionRoute,
      token: '',
    },
  ],
};
//endregion

export const permissionInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <EditPermission />
        </InspectorContextLoadingWrapper>
      ),
      path: permissionRoute,
    },
    {
      element: <Navigate to={permissionRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: PermissionInspectorContext,
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
