import PlaceholderText from '@octaved/flow/src/Components/Feedback/Placeholder/PlaceholderText';
import {useFormattedDateText} from '@octaved/flow/src/Hooks/FormattedDateText';
import {TWButton, cn} from '@octaved/ui';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Calendar} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Tooltip, TooltipProps} from '../../../Feedback';
import {ConvertFromFn} from '../DateOrRangePicker';

export interface StandardRangePickerTriggerProps<DATE> {
  convertFrom: ConvertFromFn<DATE>;
  dateFormat?: string;
  end: DATE | null;
  triggerClassName?: string;
  isActive?: boolean; //disables tooltip, the datepicker is already visible
  labelOverride?: ReactElement;
  onToggle: () => void;
  placeholderToken?: string;
  readonly?: boolean;
  start: DATE | null;
  tooltipProps?: Omit<TooltipProps, 'children'>;
}

export default function StandardRangePickerTrigger<DATE extends string>({
  convertFrom,
  dateFormat,
  end,
  triggerClassName,
  isActive,
  labelOverride,
  onToggle,
  placeholderToken = 'generalCore:datePicker.noDateSelected', //use generalCore:datePicker.selectDate when non-optional
  readonly,
  start,
  tooltipProps,
}: StandardRangePickerTriggerProps<DATE>): ReactElement {
  const {t} = useTranslation();
  const formatDate = useFormattedDateText(undefined, dateFormat);

  return (
    <Tooltip toolTipTranslation={''} {...tooltipProps} disabled={!tooltipProps || isActive}>
      <TWButton
        type={'button'}
        withIcon
        variant={'outline'}
        onClick={onToggle}
        disabled={readonly}
        className={cn(
          'h-8 justify-start border-slate-200 py-0 pl-2 pr-3 text-left text-base font-normal',
          triggerClassName,
        )}
      >
        {!readonly && <Calendar className={'h-4 w-4 text-slate-600'} />}
        {!labelOverride && (start || end) && (
          <div className={'date'}>
            {start === end && formatDate(toIsoFormat(convertFrom(start)))}
            {start !== end && (
              <>
                {start ? formatDate(toIsoFormat(convertFrom(start))) : ''} -{' '}
                {end ? formatDate(toIsoFormat(convertFrom(end))) : ''}
              </>
            )}
          </div>
        )}
        {!labelOverride && !start && !end && <PlaceholderText italic>{t(placeholderToken)}</PlaceholderText>}
        {labelOverride && <div>{labelOverride}</div>}
      </TWButton>
    </Tooltip>
  );
}
