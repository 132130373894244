import {FieldCondition} from '@octaved/store/src/Notifications';
import FormErrors from '@octaved/ui-components/src/React/Form/FormErrors';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useUnsetErrorsOnLeave} from '../../../Hooks/UnsetErrorsOnLeave';
import {errorsSelector} from '../../../Modules/Selectors/UiSelectors';

interface Props {
  className?: string;
  includeFields: FieldCondition[];
  noMargin?: boolean;
}

export default function Errors({className, includeFields, noMargin}: Props): ReactElement | null {
  const errors = useSelector(errorsSelector);
  useUnsetErrorsOnLeave(includeFields);
  return <FormErrors className={className} errors={errors} includeFields={includeFields} noMargin={noMargin} />;
}
