import {TFunction} from 'i18next';
import {ReactElement, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import BaseDropdownDropdown, {BaseDropdownItem, PublicBaseDropdownProps} from './BaseDropdown';

interface Props<V extends string> extends PublicBaseDropdownProps<V> {
  setValue: (v: V[]) => void;
  value: ReadonlyArray<V>;
  itemRenderer?: (item: BaseDropdownItem<V>, t: TFunction) => ReactNode;
}

const render = <V extends string>({text}: BaseDropdownItem<V>, t: TFunction): ReactNode =>
  typeof text === 'string' ? t(text) : text;

export default function MultiDropdown<V extends string>({
  setValue,
  value,
  itemRenderer = render,
  ...props
}: Props<V>): ReactElement {
  const {t} = useTranslation();
  return (
    <BaseDropdownDropdown<V>
      {...props}
      clearSelection={() => setValue([])}
      isActiveFN={(item) => value.includes(item.value)}
      itemRenderer={({item}) => <div>{itemRenderer(item, t)}</div>}
      labelItemRenderer={(item) => itemRenderer(item, t)}
      selectionCount={value.length}
      toggleSelection={(item) => {
        const next = new Set(value);
        if (next.has(item.value)) {
          next.delete(item.value);
        } else {
          next.add(item.value);
        }
        setValue([...next]);
      }}
    />
  );
}
