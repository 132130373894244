import {HStack} from '@octaved/ui';
import {ReactElement} from 'react';
import ProjectFavoritesToggle from '../../Components/ProjectTree/Controls/ProjectFavoritesToggle';
import ProjectTreeDisplayOptions from '../../Components/ProjectTree/Controls/ProjectTreeDisplayOptions';
import ProjectTreeEllipsisMenu from '../../Components/ProjectTree/Controls/ProjectTreeEllipsisMenu';
import SortAndGroupOptions from '../../Components/ProjectTree/Controls/SortAndGroupOptions';
import AdvancedFilterButton from './ProjectsHeader/AdvancedFilterButton';
import CustomerSelector from './ProjectsHeader/CustomerSelector';
import Search from './ProjectsHeader/Search';
import {ProjectsHeaderMenuOptions} from './ProjectsHeaderFilters';
import ShowTasksToggle from './ShowTasksToggle';

export default function ProjectsHeaderDefaultFilters({menuOptions}: ProjectsHeaderMenuOptions): ReactElement {
  return (
    <HStack alignItems={'center'} spacing={'8px'}>
      <Search />
      <CustomerSelector />
      <ShowTasksToggle />
      <ProjectTreeDisplayOptions />
      <AdvancedFilterButton />
      <SortAndGroupOptions withGrouping />
      <ProjectFavoritesToggle />
      <ProjectTreeEllipsisMenu {...(menuOptions || {})} />
    </HStack>
  );
}
