import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {
  GroupLargeViewTabs,
  ProjectLargeViewTabs,
  SubWorkPackageLargeViewTabs,
  TaskLargeViewTabs,
  WorkPackageLargeViewTabs,
} from '@octaved/node-details';
import {GroupLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/GroupTabs';
import {ProjectLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/ProjectTabs';
import {SubWorkPackageLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/SubWorkPackageTabs';
import {TaskLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/TaskTabs';
import {WorkPackageLargeViewPaths} from '@octaved/node-details/src/InspectorView/Config/WorkPackageTabs';
import {largeViewsRoutes} from '@octaved/node-details/src/LargeView/Routes';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {castFilter} from '@octaved/utilities';
import {lazy, useCallback} from 'react';
import {RouteObject} from 'react-router';
import {useLocation, useNavigate, useParams, useResolvedPath} from 'react-router-dom';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {isNode} from '../../Node/NodeIdentifiers';

type Type = EnumFlowNodeType; //for now, but might be 'customer', 'timeRecord' or whatever we need full page details for

interface LargeViewParams {
  largeViewNodeId: Uuid;
  largeViewNodeType: Type;
}

const nodeRoute = 'node';

const LargeViewOutlet = lazy(() =>
  import('@octaved/node-details').then((module) => ({default: module.LargeViewOutlet})),
);

export const nodeRoutes: RouteObject[] = [
  {
    children: largeViewsRoutes,
    element: <LargeViewOutlet />,
    path: `${nodeRoute}/:largeViewNodeType/:largeViewNodeId/*`,
  },
];

export function useLargeViewParams(): Partial<LargeViewParams> {
  return useParams<LargeViewParams & {[x: string]: string}>();
}

function generateLargeViewBaseUrl(type: Type, id: Uuid): string {
  return `/${nodeRoute}/${type}/${id}`;
}

type LargeViewTabs =
  | GroupLargeViewTabs
  | ProjectLargeViewTabs
  | SubWorkPackageLargeViewTabs
  | TaskLargeViewTabs
  | WorkPackageLargeViewTabs;
type LargeViewSubPage =
  | GroupLargeViewPaths
  | ProjectLargeViewPaths
  | SubWorkPackageLargeViewPaths
  | TaskLargeViewPaths
  | WorkPackageLargeViewPaths;

export type LargeViewTargetPage = LargeViewTabs | LargeViewSubPage;

export function generateLargeViewUrl(
  type: EnumFlowNodeType.VALUE_TASK,
  id: Uuid,
  targetPage: TaskLargeViewPaths | TaskLargeViewTabs,
): string;
export function generateLargeViewUrl(
  type: EnumFlowNodeType.VALUE_WORK_PACKAGE,
  id: Uuid,
  targetPage: WorkPackageLargeViewPaths | WorkPackageLargeViewTabs,
): string;
export function generateLargeViewUrl(
  type: EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
  id: Uuid,
  targetPage: SubWorkPackageLargeViewPaths | SubWorkPackageLargeViewTabs,
): string;
export function generateLargeViewUrl(
  type: EnumFlowNodeType.VALUE_GROUP,
  id: Uuid,
  targetPage: GroupLargeViewPaths | GroupLargeViewTabs,
): string;
export function generateLargeViewUrl(
  type: EnumFlowNodeType.VALUE_PROJECT,
  id: Uuid,
  targetPage: ProjectLargeViewPaths | ProjectLargeViewTabs,
): string;
export function generateLargeViewUrl(type: Type, id: Uuid, targetPage?: LargeViewTargetPage): string;
export function generateLargeViewUrl(type: Type, id: Uuid, targetPage: LargeViewTargetPage = 'overview'): string {
  const curPath = window.location.pathname;
  const appendLastPage = !curPath.startsWith('/node/');
  const query = appendLastPage ? `?lastPage=${encodeURIComponent(curPath)}` : '';
  return `${generateLargeViewBaseUrl(type, id)}/${targetPage}${query}`;
}

const largeViewNodeTypes = [
  EnumFlowNodeType.VALUE_GROUP,
  EnumFlowNodeType.VALUE_PROJECT,
  EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
  EnumFlowNodeType.VALUE_TASK,
  EnumFlowNodeType.VALUE_WORK_PACKAGE,
] as const;
type LargeViewNodeType = (typeof largeViewNodeTypes)[number];
const largeViewNodeTypesSet = new Set<EnumFlowNodeType>(largeViewNodeTypes);

export function nodeTypeHasLargeView(nodeType: EnumFlowNodeType): nodeType is LargeViewNodeType {
  return largeViewNodeTypesSet.has(nodeType);
}

export function isLargeViewNode(node: unknown): node is Extract<NodeType, {nodeType: LargeViewNodeType}> {
  return isNode(node) && nodeTypeHasLargeView(node.nodeType);
}

export function useClosestAncestorForLargeView(
  nodeId: MaybeUuid,
): Extract<NodeType, {nodeType: LargeViewNodeType}> | null {
  const {ancestors} = useNodeAncestry(nodeId, true);
  return castFilter(ancestors, isLargeViewNode)[0] ?? null;
}

/**
 * Changes the node of the large view while keeping the same tab.
 * If the tab doesn't exist for the type, it should redirect to the overview then.
 *
 * The `at` argument depends on the routing-depth from where you call this hook.
 *  If you are inside a tab, you probably want to use `..`.
 *  Outside the tabs, you should be fine with `.`.
 */
export function useChangeLargeViewNode(at = '.'): (node: NodeType) => void {
  const {pathname: fullPath, search} = useLocation();
  const ownPath = useResolvedPath(at).pathname;
  const navigate = useNavigate();
  return useCallback(
    (node: NodeType) => {
      const url = fullPath.replace(ownPath, generateLargeViewBaseUrl(node.nodeType, node.id));
      navigate(`${url}${search}`);
    },
    [fullPath, navigate, ownPath, search],
  );
}
