import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {passwordPolicySelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {BaseSettings} from '@octaved/flow/src/Modules/Settings';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {getMyOrgsRoute} from '@octaved/flow/src/Routing/Routes/MyOrganizations';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {SupportedLanguage} from '@octaved/i18n/src/Language/Languages';
import {isNetworkError} from '@octaved/network/src/NetworkError';
import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, INVALIDATED, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, Dispatch, getState} from '@octaved/store/src/Store';
import {
  createTextInputVariableRules,
  notNullOrUndefined,
  RulesList,
  validateEmailAddress,
  validateEqual,
  validatePassword,
  validateRules,
} from '@octaved/store/src/Validation';
import {Uuid} from '@octaved/typescript/src/lib';
import {generateUuid} from '@octaved/utilities';
import {debounceReduxIdsAction} from '@octaved/utilities/src/DebounceReduxAction';
import {validateArray, validateString, validateUuid} from '@octaved/validation';
import {chunk, omit} from 'lodash';
import once from 'lodash/once';
import * as routes from '../../config/routes';
import {OrgUserDeletedEvent} from '../EntityInterfaces/Events';
import {OrgUserEntities, OrgUserEntity} from '../EntityInterfaces/UserEntity';
import {orgUser as userSchema} from '../Schema';
import {currentOrgUserIdSelector} from '../Selectors/CurrentOrgUserSelectors';
import {getUserIdsToLoadSelector, userEntityStatesSelector} from '../Selectors/UserSelectors';
import {
  CREATE_USERS_FAILURE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
  LOAD_USERS_REQUEST,
  LOAD_USERS_START,
  LOAD_USERS_SUCCESS,
  PATCH_USERS_FAILURE,
  PATCH_USERS_REQUEST,
  PATCH_USERS_SUCCESS,
  USERS_CHANGED,
  USERS_CREATED,
} from './ActionTypes';
import {setErrors} from './UiPage';

/**
 * These fields must be set to TRUE on FIELDS_REQUIRING_ADMIN_RIGHTS and also added to the "listOrgUsers"-export!
 */
export const publicUserFields: Array<keyof OrgUserEntity> = [
  'avatarUrl',
  'customerId',
  'name',
  'type',
  'workingTimeTrackingDisabled',
];

const orgUserReducers = createReducerCollection<OrgUserEntities>({});
export const orgUserStateReducers = createReducerCollection<EntityStates>({});

orgUserReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) => {
  if (action.isInOrganization) {
    const {orgUser} = action.response.result;
    return mergeStates(state, {
      [orgUser.id]: {
        avatarUrl: orgUser.avatarUrl,
        id: orgUser.id,
        name: orgUser.name,
      },
    });
  }
  return state;
});

orgUserStateReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) => {
  if (action.isInOrganization) {
    const {orgUser} = action.response.result;
    return createTimestampReducer('ids', LOADED)(state, {
      fieldsToLoad: publicUserFields,
      ids: [orgUser.id],
      requestTime: Date.now(),
    });
  }
  return state;
});

export const orgUserReducer = orgUserReducers.reducer;

orgUserStateReducers.add(LOAD_USERS_START, createTimestampReducer('ids', LOADING));
orgUserStateReducers.add(LOAD_USERS_SUCCESS, createTimestampReducer('options.urlParams.ids', LOADED));
orgUserStateReducers.add(USERS_CHANGED, createTimestampReducer('userIds', INVALIDATED));
orgUserStateReducers.add(USERS_CREATED, createTimestampReducer('userIds', INVALIDATED));
orgUserStateReducers.add('OrgUserIdentityPatchedEvent', createTimestampReducer('orgUserId', INVALIDATED));
orgUserStateReducers.add('flow.OrgUserInvitationAcceptedEvent', createTimestampReducer('orgUserId', INVALIDATED));
orgUserStateReducers.add('flow.OrgUserInvitationDeclinedEvent', createTimestampReducer('orgUserId', INVALIDATED));
orgUserStateReducers.add('flow.OrgUserInvitationResentEvent', createTimestampReducer('orgUserId', INVALIDATED));
export const orgUserStateReducer = orgUserStateReducers.reducer;

interface UserIdsParams {
  userIds: ReadonlyArray<Uuid>;
}

subscribe('OrgUserDeletedEvent', ({orgUserId}: OrgUserDeletedEvent) => {
  if (currentOrgUserIdSelector(getState()) === orgUserId) {
    window.location.href = getMyOrgsRoute();
  }
});

const init = once(async (getState: () => FlowState) => {
  subscribe('UserCreatedEvent', ({userIds}: UserIdsParams) => ({userIds, type: USERS_CREATED}));
  subscribe('usersChanged', ({userIds}: UserIdsParams) => ({userIds, type: USERS_CHANGED}));
  const currentUserId = currentOrgUserIdSelector(getState());
  subscribe('currentUserChanged', () => ({userIds: [currentUserId], type: USERS_CHANGED}));
});

interface LoadStartAction {
  fieldsToLoad: string[] | null;
  type: typeof LOAD_USERS_START;
  ids: ReadonlyArray<Uuid>;
}

/**
 * Deprecated because this does not debounce calls and returns the promise.
 *
 * Use loadUsers() instead.
 */
export function loadUsersDeprecated(
  ids: ReadonlyArray<Uuid>,
  wantedFields: Array<keyof OrgUserEntity> | null = null,
): ActionDispatcher<Promise<void>> {
  validateArray(ids, validateUuid);
  if (wantedFields) {
    validateArray(wantedFields, validateString);
  }

  return async (dispatch: Dispatch<CallAction | LoadStartAction>, getState) => {
    await init(getState);

    const toLoad = getUserIdsToLoadSelector(getState())(ids, wantedFields);
    if (toLoad.length === 0) {
      return;
    }

    //Must set the state to "loading" before the chunking starts, otherwise multiple loads may happen for one id:
    await dispatch({fieldsToLoad: wantedFields, type: LOAD_USERS_START, ids: toLoad});

    //Chunk to 100, otherwise the request uri will get too long:
    await Promise.all(
      chunk(toLoad, 100).map((ids) =>
        dispatch({
          [CALL_API]: {
            endpoint: routes.exportOrgUsers,
            options: {
              urlParams: {
                ids,
                fields: wantedFields || undefined, //undefined will prevent the param from being sent to the server!
              },
            },
            schema: {
              users: [userSchema],
            },
            types: {
              failureType: LOAD_USERS_FAILURE,
              requestType: LOAD_USERS_REQUEST,
              successType: LOAD_USERS_SUCCESS,
            },
          },
          fieldsToLoad: wantedFields,
        }),
      ),
    );
  };
}

export const loadUsers = debounceReduxIdsAction(loadUsersDeprecated);

/**
 * @param {string[]} ids a list of user uuids
 */
export function loadUserDisplayNames(ids: ReadonlyArray<Uuid>): ActionDispatcher<void> {
  return loadUsers(ids, publicUserFields); //we load avatar always
}

export function useLoadedUserNames(userIds: ReadonlyArray<Uuid> | ReadonlySet<Uuid> | null | undefined): void {
  useStoreEffect(
    (dispatch) => {
      if (userIds) {
        dispatch(loadUserDisplayNames([...userIds]));
      }
    },
    [userIds],
    userEntityStatesSelector,
  );
}

export interface PutOrgUserPayload {
  confirm: string; //only for validation, is not sent to server
  customerId: Uuid | null;
  identifier: string;
  language: SupportedLanguage;
  name: string;
  orgUserType: UserType.api | UserType.regular | UserType.guestCustomer | UserType.guestOther;
  plainPassword: string;
  sendInvitation: boolean;
}

function getOrgUserValidationRules(
  userData: Partial<Pick<PutOrgUserPayload, 'customerId' | 'orgUserType' | 'identifier' | 'name' | 'sendInvitation'>>,
): RulesList {
  const rules: RulesList = [];
  if (userData.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        userData.name!,
        'core:newUserForm.error.nameEmpty',
        'core:newUserForm.error.nameLength',
        'name',
      ),
    );
  }
  if (userData.hasOwnProperty('identifier') && userData.orgUserType !== UserType.api) {
    rules.push(
      ...createTextInputVariableRules(
        userData.identifier!,
        'core:newUserForm.error.emptyEmail',
        'core:newUserForm.error.emailLength',
        'identifier',
      ),
    );
    if (userData.sendInvitation) {
      rules.push([validateEmailAddress, userData.identifier, 'core:newUserForm.error.invalidEmail', 'identifier']);
    }
  }
  if (userData.orgUserType === UserType.guestCustomer) {
    rules.push([notNullOrUndefined, userData.customerId, 'core:newUserForm.error.missingCustomer', 'customerId']);
  }
  return rules;
}

export function getPasswordValidationRules(
  userData: {plainPassword: string; confirm: string},
  policy: BaseSettings['passwordPolicy'],
): RulesList {
  return [
    [
      validatePassword,
      userData.plainPassword,
      'core:newUserForm.error.passwordLength',
      'core:newUserForm.error.passwordPolicyMissingLetters',
      'plainPassword',
      policy.minLength,
      policy.extendedChecksEnabled,
    ],
    [
      validateEqual,
      userData.plainPassword,
      userData.confirm,
      [
        {
          field: 'confirm',
          message: 'core:newUserForm.error.passwordsMismatch',
        },
      ],
    ],
  ];
}

interface OrgUserExists {
  error: 'orgUserAlreadyExists';
}

interface InvitationExists {
  error: 'invitationAlreadyExists';
}

type PutOrgUserConflict = OrgUserExists | InvitationExists;

export function putOrgUser(data: PutOrgUserPayload): ActionDispatcher<Promise<false | string>> {
  return async (dispatch, getState) => {
    const rules: RulesList = getOrgUserValidationRules(data);
    if (!data.sendInvitation && data.orgUserType !== UserType.api) {
      const policy = passwordPolicySelector(getState());
      rules.push(...getPasswordValidationRules(data, policy));
    }

    const errors = validateRules(rules);
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    const orgUserId = generateUuid();

    try {
      await dispatch({
        [CALL_API]: {
          endpoint: routes.putOrgUser,
          method: 'put',
          options: {data: omit(data, ['confirm']), urlParams: {orgUserId}},
          throwNetworkError: true,
          types: {
            failureType: CREATE_USERS_FAILURE,
            requestType: CREATE_USERS_REQUEST,
            successType: CREATE_USERS_SUCCESS,
          },
        },
      });
    } catch (e) {
      if (isNetworkError<PutOrgUserConflict>(e, 409)) {
        if (e.data.error === 'orgUserAlreadyExists') {
          dispatch(
            setErrors([
              {
                field: 'identifier',
                message: 'systemSettings:user.form.error.orgUserAlreadyExists',
              },
            ]),
          );
          return false;
        }
        if (e.data.error === 'invitationAlreadyExists') {
          dispatch(
            setErrors([
              {
                field: 'identifier',
                message: 'systemSettings:user.form.error.invitationAlreadyExists',
              },
            ]),
          );
          return false;
        }
      }
      if (isNetworkError<{error: string}>(e, 402)) {
        if (e.data.error === 'userLimitReached') {
          dispatch(setErrors([{field: 'userLimitReached', message: 'userLimitReached'}])); //handled statically
        }
        return false;
      }
      throw e;
    }

    return orgUserId;
  };
}

export interface PatchOrgUser {
  hiddenInSelections: boolean;
  isActive: boolean;
  name: string;
  workingTimeTrackingDisabled: boolean;
}

export function patchOrgUser(orgUserId: Uuid, data: Partial<PatchOrgUser>): ActionDispatcher<Promise<boolean>> {
  return async (dispatch) => {
    const userData = {...data};
    const rules: RulesList = getOrgUserValidationRules(userData);

    const errors = validateRules(rules);
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    await dispatch({
      [CALL_API]: {
        endpoint: routes.patchOrgUser,
        method: 'patch',
        options: {data, urlParams: {orgUserId}},
        throwNetworkError: true,
        types: {
          failureType: PATCH_USERS_FAILURE,
          requestType: PATCH_USERS_REQUEST,
          successType: PATCH_USERS_SUCCESS,
        },
      },
    });

    return true;
  };
}

export function deleteOrgUser(orgUserId: Uuid): ActionDispatcher<Promise<void>> {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        endpoint: routes.deleteOrgUser,
        method: 'del',
        options: {urlParams: {orgUserId}},
        types: {
          failureType: DELETE_USERS_FAILURE,
          requestType: DELETE_USERS_REQUEST,
          successType: DELETE_USERS_SUCCESS,
        },
      },
    });
  };
}
