import {Icon} from '@octaved/ui';
import {PlayCircle} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AccessibleItem, isItemAccessibleSelector} from '../../AccessibleItem';
import PopupVideo, {EmbeddedHelpVideo} from '../../Components/EmbeddedHelp/PopupVideo';
import SectionHeader from './SectionHeader';

interface TutorialVideo extends AccessibleItem {
  description?: string;
  headline: string;
  identifier: EmbeddedHelpVideo;
}

const tutorialVideos: TutorialVideo[] = [
  {
    description: 'onboarding:videoTutorials.createTasksInfo',
    headline: 'onboarding:videoTutorials.createTasksHeadline',
    identifier: 'createTask',
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    description: 'onboarding:videoTutorials.trackTimeInfo',
    headline: 'onboarding:videoTutorials.trackTimeHeadline',
    identifier: 'trackTime',
  },
  {
    description: 'onboarding:videoTutorials.createInternalProjectAndWorkPackageInfo',
    headline: 'onboarding:videoTutorials.createInternalProjectAndWorkPackageHeadline',
    identifier: 'internalProject',
  },
  {
    description: 'onboarding:videoTutorials.createCustomerProjectAndWorkPackageInfo',
    headline: 'onboarding:videoTutorials.createCustomerProjectAndWorkPackageHeadline',
    identifier: 'customerProject',
  },
  {
    description: 'onboarding:videoTutorials.assignResponsibilityInfo',
    headline: 'onboarding:videoTutorials.assignResponsibilityHeadline',
    identifier: 'assignResponsibility',
  },
  {
    description: 'onboarding:videoTutorials.planProjectsInfo',
    headline: 'onboarding:videoTutorials.planProjectsHeadline',
    identifier: 'projectPlanning',
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    description: 'onboarding:videoTutorials.copyTimeRecordingInfo',
    headline: 'onboarding:videoTutorials.copyTimeRecordingHeadline',
    identifier: 'copyTimeTrackingEntry',
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    description: 'onboarding:videoTutorials.billingAndTimeSheetExportInfo',
    headline: 'onboarding:videoTutorials.billingAndTimeSheetExportHeadline',
    identifier: 'billingAndTimeSheetsExport',
  },
  {
    description: 'onboarding:videoTutorials.addTaskSectionInfo',
    headline: 'onboarding:videoTutorials.addTaskSectionHeadline',
    identifier: 'createTaskSection',
  },
];

export default function VideoTutorials(): ReactElement {
  const {t} = useTranslation();
  const isItemAccessible = useSelector(isItemAccessibleSelector);

  const sortedTutorials = useMemo(() => {
    return tutorialVideos
      .filter(isItemAccessible)
      .map((cfg) => ({...cfg, headline: t(cfg.headline), description: cfg.description && t(cfg.description)}))
      .sort(({headline: a}, {headline: b}) => a.localeCompare(b));
  }, [isItemAccessible, t]);

  return (
    <>
      <SectionHeader color={'purple'} header={'onboarding:videoTutorials.headline'} />

      <div className={'tutorials'}>
        {sortedTutorials.map(({description, headline, identifier}) => (
          <PopupVideo
            key={identifier}
            videoIdentifier={identifier}
            videoTrigger={
              <div className={'tutorial'}>
                <Icon noMargin size={'bigger'} iconColor={'darkPurple'}>
                  <PlayCircle strokeWidth={1} />
                </Icon>
                <div className={'details'}>
                  <div className={'title'}>{headline}</div>
                  {description && <div className={'description'}>{description}</div>}
                </div>
              </div>
            }
          />
        ))}
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .tutorials {
          display: grid;
          grid-template-columns: 0.5fr 0.5fr;
          grid-gap: 15px;
        }

        .tutorial {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          grid-gap: 20px;
          background: rgb(246, 246, 246);
          border: 1px solid rgb(220, 217, 215);
          padding: 10px 12px;
          border-radius: 6px;
          cursor: pointer;
          transition: transform 120ms ease-in-out;

          &:hover {
            transform: scale(1.025);
          }
        }

        .details {
          flex: 1;
        }

        .title {
          font-weight: 600;
          font-size: 16px;
          color: #333;
          margin-bottom: 6px;
        }

        .description {
          font-size: 14px;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
