import ProjectDiagramSvg from '@fortawesome/fontawesome-pro/svgs/duotone/project-diagram.svg';
import {memo, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {projectsFilteredCustomerSelector, projectsSearchSelector} from '../../Modules/Selectors/UiSelectors';
import PagePlaceholder from '../Feedback/Placeholder/PagePlaceholder';

export default memo(function DefaultPlaceholder(): ReactElement {
  const search = useSelector(projectsSearchSelector);
  const customer = useSelector(projectsFilteredCustomerSelector);

  const text = useMemo(() => {
    if (search.length === 0 && !customer) {
      return 'pages:projects.noProjectInOU';
    }

    return 'pages:projects.noProjects';
  }, [search, customer]);

  return <PagePlaceholder text={text} icon={<ProjectDiagramSvg />} />;
});
