import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {allowOrganizationSetupSelector} from '../../Modules/Selectors/SettingSelectors';
import {getMyOrgsRoute} from '../../Routing/Routes/MyOrganizations';
import {Views} from '../Login/LoginContext';

function getUTMTrackingParams(): Record<string, string> {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return [...urlSearchParams.keys()]
    .filter((key) => key.startsWith('utm_'))
    .reduce<Record<string, string>>((acc, key) => {
      const value = urlSearchParams.get(key);
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});
}

export default function RegisterWrapper(): null {
  const navigate = useNavigate();
  const allowOrganizationSetup = useSelector(allowOrganizationSetupSelector);
  useEffect(() => {
    if (allowOrganizationSetup) {
      sessionStorage.removeItem('registration.setupData'); //clear previous data if you navigate from "/register"
      sessionStorage.setItem('registration.utm', JSON.stringify(getUTMTrackingParams()));
      sessionStorage.setItem('login.initialView', 'signUpIdentity' satisfies Views);
      sessionStorage.setItem('myOrgs.wantsToRegister', '1');
    }
    navigate(getMyOrgsRoute(), {replace: true});
  }, [allowOrganizationSetup, navigate]);
  return null;
}
