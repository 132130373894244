import {calculateMinMaxPlanningDatesInSubtreeSelector} from '../../Selectors/PlanningDateSelectors';
import {createStoreSubscription} from '../StoreSubscription';
import {Bar, BarPluginList} from './Bar';

export class MinMaxDateBar extends Bar {
  init(...plugins: BarPluginList): void {
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => calculateMinMaxPlanningDatesInSubtreeSelector(s)(this.treeNode.id),
        (planningDate) => {
          if (planningDate.plannedStart) {
            this.setDates(planningDate.plannedStart, planningDate.plannedEnd);
            this.root.visible(true);
          } else {
            this.root.visible(false);
          }
        },
        (a, b) => a?.plannedStart === b?.plannedStart && a?.plannedEnd === b?.plannedEnd,
      ),
    );
    super.init(...plugins);
  }
}
