import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useLoadNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {useNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {projectFolderTreeNodesSelector} from '@octaved/flow/src/Modules/Selectors/ProjectFolderSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import Children from './Children';
import {ProjectFolderSelectorContextProvider} from './ProjectFolderSelectorContext';

interface Props {
  close: () => void;
  selectFolderId: (id: Uuid | null) => void;
  selectedFolderId: Uuid | null;
}

export default function Content({close, selectedFolderId, selectFolderId}: Props): ReactElement | null {
  const {nodeIds} = useNodeSearch('nodeType', EnumFlowNodeType.VALUE_PROJECT_FOLDER);
  const {hasLoadedOnce} = useLoadNodes(nodeIds);
  const nodes = useSelector(projectFolderTreeNodesSelector);
  if (!hasLoadedOnce) {
    return null;
  }
  return (
    <ProjectFolderSelectorContextProvider
      close={close}
      selectedFolderId={selectedFolderId}
      selectFolderId={selectFolderId}
    >
      <Children nodes={nodes} />
    </ProjectFolderSelectorContextProvider>
  );
}
