import {addMilestone} from '@octaved/planning/src/Modules/Milestones';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {DatePicker, TWAutosaveInput, TWButton, cn, tableGridCellVariant} from '@octaved/ui';
import dayjs from 'dayjs';
import {PlusCircle} from 'lucide-react';
import {ReactElement, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface NewMilestoneProps {
  nodeId: Uuid;
  readonly: boolean;
}

export default function NewMilestone({nodeId, readonly}: NewMilestoneProps): ReactElement {
  const [isAdding, setIsAdding] = useState(false);
  const [date, setDate] = useState<DateStr | null>(null);
  const [name, setName] = useState('');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    if (date && name) {
      dispatch(addMilestone(nodeId, date, name));
      setIsAdding(false);
      setDate(null);
      setName('');
    }
  }, [date, dispatch, name, nodeId]);

  if (isAdding) {
    return (
      <>
        <div className={cn(tableGridCellVariant({position: 'left'}))}>
          <DatePicker
            defaulOpen
            date={date || null}
            triggerClassName={cn('w-full', !date && 'border-orange-400 bg-orange-50')}
            setDate={setDate}
            footerOverride={{canClear: false}}
            readonly={readonly}
            triggerOverride={{placeholderToken: 'pages:projects.inspector.manage.planning.selectDate'}}
            datePickerOverride={{
              minDate: dayjs(),
            }}
          />
        </div>
        <div className={cn(tableGridCellVariant({position: 'center'}))}>
          <TWAutosaveInput
            type={'text'}
            value={name}
            onSave={setName}
            disabled={readonly}
            hasWarning={!name}
            placeholder={t('nodeDetails:field.milestones.name.placeholder')}
          />
        </div>
      </>
    );
  }

  return (
    <div className={'col-span-3'}>
      <TWButton withIcon variant={'outline'} onClick={() => setIsAdding(true)} size={'md'} colorScheme={'primary'}>
        <PlusCircle className={'size-4 text-current'} />
        {t('nodeDetails:field.milestones.newMilestone')}
      </TWButton>
    </div>
  );
}
