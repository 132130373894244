import {ReactElement} from 'react';
import {Curve} from 'recharts';
import {Props} from 'recharts/types/shape/Curve';

interface LabelLineRendererProps extends Omit<Props, 'ref'> {
  value: number;
}

export default function LabelLineRenderer(props: LabelLineRendererProps): ReactElement {
  if (props.value > 0) {
    return <Curve {...props} />;
  }

  return <></>;
}
