import type {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {Uuid} from '@octaved/typescript/src/lib';

export function nodeIdInTree(nodeId: Uuid): NodeSearchCondition {
  return {
    combine: {
      ident: 'nodeIdInTree',
      options: {nodeId},
      sources: {},
    },
  };
}
export function toChildren(cond: NodeSearchCondition | Uuid): NodeSearchCondition {
  return {
    combine: {
      ident: 'toChildren',
      options: undefined,
      sources: {parents: typeof cond === 'string' ? {fixResult: [cond]} : cond},
    },
  };
}

export function toParents(cond: NodeSearchCondition | Uuid): NodeSearchCondition {
  return {
    combine: {
      ident: 'toParents',
      options: undefined,
      sources: {children: typeof cond === 'string' ? {fixResult: [cond]} : cond},
    },
  };
}

export function withAncestors(cond: NodeSearchCondition | Uuid, includeSelf: boolean): NodeSearchCondition {
  return {
    combine: {
      ident: 'withAncestors',
      options: {includeSelf},
      sources: {descendants: typeof cond === 'string' ? {fixResult: [cond]} : cond},
    },
  };
}

export function withDescendants(cond: NodeSearchCondition | Uuid, includeSelf: boolean): NodeSearchCondition {
  return {
    combine: {
      ident: 'withDescendants',
      options: {includeSelf},
      sources: {ancestors: typeof cond === 'string' ? {fixResult: [cond]} : cond},
    },
  };
}

export const emptyNodeSearchResult: NodeSearchCondition = {fixResult: []};
