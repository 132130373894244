import {UnknownAction} from 'redux';
import {FLOW_PATCH_PLANNING_REQUEST} from '../../Modules/ActionTypes';
import {PatchPlanningRequest} from '../../Modules/Planning';
import UpdateWorkloadContext from './UpdateWorkloadContext';

function isPlanningRequestAction(action: UnknownAction): action is PatchPlanningRequest {
  return action.type === FLOW_PATCH_PLANNING_REQUEST;
}

export function handlePlanningRequestAction(context: UpdateWorkloadContext, action: UnknownAction): boolean {
  const canHandle = isPlanningRequestAction(action);
  if (isPlanningRequestAction(action) && action.updatedNodePlanningDates) {
    for (const nodeId of Object.keys(action.updatedNodePlanningDates)) {
      context.updateWorkloadForNode(nodeId);
    }
  }
  return canHandle;
}
