import {patchIdentity} from '@octaved/identity/src/Modules/Identity';
import {
  identityHiddenOrganizationIdsSetSelector,
  identityOrganizationsSelector,
} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {Tooltip} from '@octaved/ui';
import {HelpCircleIcon} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import InvitationRow from './InvitationRow';
import OrganizationRow from './OrganizationRow';

interface Props {
  closePopup: () => void;
}
export default function UserMenuOrganizationSwitch(_: Props): ReactElement | null {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {invited, joined} = useSelector(identityOrganizationsSelector);
  const hiddenOrgIdsSet = useSelector(identityHiddenOrganizationIdsSetSelector);
  const nonHiddenOrganizations = useMemo(
    () => joined.filter(({organizationId}) => !hiddenOrgIdsSet.has(organizationId)),
    [hiddenOrgIdsSet, joined],
  );

  // User has only one organization, so no need to show the organization switcher
  if (invited.length === 0 && joined.length === 1) {
    return null;
  }

  return (
    <>
      <div className={'flex w-[280px] items-center justify-between bg-slate-200 px-3 py-1'}>
        <div className={'text-sm text-slate-600'}>{t('multiOrganizations:userMenu.organization.header')}</div>
        <Tooltip toolTipTranslation={'multiOrganizations:userMenu.organization.headerHelp'}>
          <HelpCircleIcon className={'h-4 w-4 text-slate-400'} />
        </Tooltip>
      </div>

      {nonHiddenOrganizations.map((organization) => (
        <OrganizationRow key={organization.organizationId} organization={organization} />
      ))}

      {invited.map((organization) => (
        <InvitationRow key={organization.organizationId} organization={organization} />
      ))}

      {nonHiddenOrganizations.length !== joined.length && (
        <div className={'flex w-full justify-end gap-x-2 px-3 py-1'}>
          <div
            onClick={() => dispatch(patchIdentity({hiddenOrganizationIds: []}))}
            className={
              'cursor-pointer text-xs italic leading-4 text-slate-400 underline transition-colors duration-150 hover:text-slate-500'
            }
          >
            {t('multiOrganizations:userMenu.organization.showAll')}
          </div>
        </div>
      )}

      <div className={'w-full bg-slate-200 px-3 py-1'}>
        <div className={'text-sm text-slate-600'}>{t('multiOrganizations:userMenu.profile.header')}</div>
      </div>
    </>
  );
}
