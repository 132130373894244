import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import type {DateStr} from '@octaved/typescript';
import isPlainObject from 'lodash/isPlainObject';
import type {MaterialResource} from '../EntityInterfaces/MaterialResource';
import type {MaterialResourceFolder} from '../EntityInterfaces/MaterialResourceFolder';
import type {NodeType} from '../EntityInterfaces/Nodes';
import {NodeSearchCondition} from '../EntityInterfaces/NodeSearch';
import type {NodeTrackedMinutes} from '../EntityInterfaces/NodeTrackedMinutes';
import {
  Group,
  GroupPatchData,
  Pid,
  Project,
  ProjectPatchData,
  WorkPackage,
  WorkPackagePatchData,
} from '../EntityInterfaces/Pid';
import type {ProjectFolder} from '../EntityInterfaces/ProjectFolder';
import {SubWorkPackage} from '../EntityInterfaces/SubWorkPackage';
import type {Task} from '../EntityInterfaces/Task';
import type {TaskSection} from '../EntityInterfaces/TaskSection';
import type {TimeControlledEntity} from '../EntityInterfaces/TimeControlledEntity';

export function isNode(node: unknown): node is NodeType {
  return isPlainObject(node) && (node as object).hasOwnProperty('nodeType');
}

export function isProjectFolder(node: unknown): node is ProjectFolder {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_PROJECT_FOLDER;
}

export function isMaterialResourceFolder(node: unknown): node is MaterialResourceFolder {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE_FOLDER;
}

export function isMaterialResource(node: unknown): node is MaterialResource {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE;
}

export function isProject(node: unknown): node is Project {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_PROJECT;
}

export function isProjectPatchData(node: unknown): node is ProjectPatchData {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_PROJECT;
}

export function isGroup(node: unknown): node is Group {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_GROUP;
}

export function isGroupPatchData(node: unknown): node is GroupPatchData {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_GROUP;
}

export function isWorkPackage(node: unknown): node is WorkPackage {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE;
}

export function isWorkPackagePatchData(node: unknown): node is WorkPackagePatchData {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE;
}

export function isPid(node: unknown): node is Pid {
  return isProject(node) || isGroup(node) || isWorkPackage(node);
}

export function isPidOrSubWOrkPackage(node: unknown): node is Pid | SubWorkPackage {
  return isPid(node) || isSubWorkPackage(node);
}

export function isSubWorkPackage(node: unknown): node is SubWorkPackage {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE;
}

export function isWpOrSwp(node: unknown): node is WorkPackage | SubWorkPackage {
  return isWorkPackage(node) || isSubWorkPackage(node);
}

export function isTask(node: unknown): node is Task {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_TASK;
}

export function isTaskSection(node: unknown): node is TaskSection {
  return isNode(node) && node.nodeType === EnumFlowNodeType.VALUE_TASK_SECTION;
}

export function isNodeWithDueDate(node: unknown): node is Extract<NodeType, {dueDate: DateStr | null}> {
  return isNode(node) && 'dueDate' in node;
}

export type CloseableNode = Extract<NodeType, {isClosed: boolean}>;

export function isCloseableNode(node: unknown): node is CloseableNode {
  return isNode(node) && 'isClosed' in node;
}

export type LockingNode = Extract<NodeType, {isLocked: boolean}>;

export function isLockingNode(node: unknown): node is LockingNode {
  return isNode(node) && 'isLocked' in node;
}

export const planningNodeTypes = [
  EnumFlowNodeType.VALUE_GROUP,
  EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
  EnumFlowNodeType.VALUE_TASK,
  EnumFlowNodeType.VALUE_WORK_PACKAGE,
] as const;

export const planningNodeTypesQuery: NodeSearchCondition = {
  or: [
    {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_GROUP],
        ['grType', EnumFlowGroupType.VALUE_SPRINT],
      ],
    },
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_TASK],
  ],
};

export type PlanningNode = (Group & {groupType: EnumFlowGroupType.VALUE_SPRINT}) | SubWorkPackage | Task | WorkPackage;

export function isPlanningNode(node: unknown): node is PlanningNode {
  return (
    isWorkPackage(node) ||
    isSubWorkPackage(node) ||
    isTask(node) ||
    (isGroup(node) && node.groupType === EnumFlowGroupType.VALUE_SPRINT)
  );
}

export function isSortableNode(node: unknown): node is Extract<NodeType, {sortOrder: number}> {
  return isNode(node) && 'sortOrder' in node;
}

export type TimeControlledNode = Extract<NodeType, TimeControlledEntity>;

export function isTimeControlledNode(node: unknown): node is TimeControlledNode {
  return isNode(node) && 'timeControl' in node;
}

export function isTimeTrackingNode(node: unknown): node is Extract<NodeType, {trackedMinutes: NodeTrackedMinutes}> {
  return isNode(node) && 'trackedMinutes' in node;
}

// All nodes that we calculate the node status for:
export type NodeStatusNode = Project | Group | WorkPackage | SubWorkPackage;

export function isNodeStatusNode(node: unknown): node is NodeStatusNode {
  return isProject(node) || isGroup(node) || isWorkPackage(node) || isSubWorkPackage(node);
}
