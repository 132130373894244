import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeIdProps {
  nodeId: string;
}

export default function NodeId({frame: Frame, nodeId}: NodeIdProps & FramedSectionOrTileProps): ReactElement {
  return <Frame labelToken={'nodeDetails:field.id.label'}>{nodeId}</Frame>;
}
