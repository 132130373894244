import {cn} from '@octaved/ui';
import {SearchIcon} from 'lucide-react';
import * as React from 'react';
import {ReactElement, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Search as SemanticSearch} from 'semantic-ui-react';
import {SearchProps} from 'semantic-ui-react/dist/commonjs/modules/Search/Search';
import css from 'styled-jsx/css';
import ValidationInputField from './ValidationInputField';

//#region <styles>
/*language=SCSS*/
const {className: classNameLegacy, styles} = css.resolve`
  .search {
    :global(.ui.input.input:not(.mini):not(.small) > input[type]) {
      height: 35px;
      border-color: #e2e8f0;
    }
    :global(.ui.icon.input) {
      width: 100%;
    }

    :global(.ui.icon.input input) {
      padding-right: 30px !important;
    }

    :global(.results) {
      overflow: auto;
      max-height: 200px;
    }
  }

  .search.upwards {
    :global(.results) {
      top: auto !important;
      bottom: 48px !important;
    }
  }
`;

//#endregion

interface Props<Result extends object> {
  autoFocus?: boolean;
  className?: string;
  clearOnSelect?: boolean;
  closeOnSelect?: boolean;
  fieldName?: string;
  fluid?: boolean;
  isLoading?: boolean;
  onSearch: (value: string) => void;
  onSelect: (result: Result) => void;
  placeholder?: string;
  ResultItemRenderer: React.FunctionComponent<{result: Result}>;
  resultKey: (result: Result) => string;
  results: ReadonlyArray<Result>;
  upwards?: boolean;
  value?: string;
}

export default function Search<Result extends object>({
  className,
  isLoading,
  resultKey,
  results,
  ResultItemRenderer,
  onSearch,
  onSelect,
  placeholder,
  clearOnSelect = true,
  closeOnSelect = false,
  autoFocus = false,
  fluid = false,
  value,
  fieldName,
  upwards = false,
}: Props<Result>): ReactElement {
  const {t} = useTranslation();

  //Semantic's Search requires a "description" and a "title", otherwise there are lots of errors. But since we
  // require a custom ResultItemRenderer here, we don't need those.

  interface SemanticSearchEntry {
    result: Result;
    title: string;
    description: string;
  }

  const innerResults = useMemo(
    () =>
      results.map<SemanticSearchEntry>((result) => ({
        result,
        description: '',
        id: resultKey(result),
        title: '',
      })),
    [resultKey, results],
  );

  const [open, setOpen] = useState(false);
  const onFocus = useCallback(
    (_event: React.MouseEvent<HTMLElement>, {value}: SearchProps) => {
      setOpen(true);
      onSearch(value || '');
    },
    [onSearch],
  );
  const onBlur = useCallback(() => setOpen(false), []);

  return (
    <>
      <SemanticSearch
        className={cn(classNameLegacy, 'search', {upwards}, className)}
        placeholder={(placeholder && t(placeholder)) || undefined}
        loading={isLoading}
        open={open}
        icon={
          <SearchIcon
            className={'absolute right-2 top-[50%] h-5 w-5 -translate-y-1/2 text-slate-400'}
            strokeWidth={1.5}
          />
        }
        onFocus={onFocus}
        onBlur={onBlur}
        onResultSelect={(_event, {result: {result}}: {result: SemanticSearchEntry}) => {
          onSelect(result);
          if (clearOnSelect) {
            onSearch('');
          }
          if (closeOnSelect) {
            setOpen(false);
            //remove focus from input so that next click will open input again
            (document.activeElement as HTMLInputElement)?.blur();
          }
        }}
        onSearchChange={(_event, {value}) => {
          setOpen(true);
          onSearch(value || '');
        }}
        results={innerResults}
        showNoResults={false}
        // @ts-ignore result types do not match
        resultRenderer={({result}: SemanticSearchEntry): ReactElement => <ResultItemRenderer result={result} />}
        autoFocus={autoFocus}
        fluid={fluid}
        value={value}
        input={
          fieldName ? (
            <ValidationInputField
              fieldName={fieldName}
              value={value}
              setValue={(val) => {
                setOpen(true);
                onSearch(val || '');
              }}
            />
          ) : undefined
        }
        id={fieldName}
      />
      {styles}
    </>
  );
}
