import UnitHStack from '@octaved/users/src/Components/UnitHStack';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useTaskListContext} from '../../../../TaskListContext';
import OnlySelfAssigned from './OnlySelfAssigned';

interface Props {
  responsibleUnits: SlimUnit[];
}

export default function ResponsibleUnits({responsibleUnits}: Props): ReactElement {
  const {showCurrentUserAsYou} = useTaskListContext();
  const currentUserId = useSelector(currentOrgUserIdSelector);

  if (responsibleUnits.length === 1 && responsibleUnits[0].unitId === currentUserId && showCurrentUserAsYou) {
    return <OnlySelfAssigned />;
  }

  return <UnitHStack units={responsibleUnits} />;
}
