import {Label} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement, useMemo} from 'react';
import {Trans} from 'react-i18next';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import {Group, WorkPackage} from '../../EntityInterfaces/Pid';

interface Props {
  siblings: Array<Group | WorkPackage>;
  position: number;
  setPosition: (position: number) => void;
}

export default function GroupPosition({setPosition, siblings, position}: Props): ReactElement | null {
  const options = useMemo<DropdownItemProps[]>(() => {
    return [
      {
        text: <Trans i18nKey={'dialogs:createGroup.selectPosition.asFirstGroup'} />,
        value: -1,
      },
      ...siblings.map(({name}, index) => ({
        text: name,
        value: index,
      })),
    ];
  }, [siblings]);

  return (
    <div>
      <Spacer />
      <Label token={'dialogs:createGroup.selectPosition.label'} />
      <Dropdown
        selection
        wrapSelection
        selectOnBlur={false}
        options={options}
        value={position}
        onChange={(_e, {value}) => {
          if (typeof value === 'number') {
            setPosition(value);
          }
        }}
      />
    </div>
  );
}
