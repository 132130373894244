import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {useWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import Section from '../../../Components/Fields/Section';
import NodeStatus from '../../../Components/Fields/Settings/Common/NodeStatus';
import {useTimeControlledAncestors} from '../../../Components/Fields/Settings/Common/NodeTimeControlSettings';
import WorkPackageTypeSection from '../../../Components/Fields/Settings/NodeType/WorkPackage/Section/WorkPackageTypeSection';
import ArchiveNodeSection from '../../../Components/Fields/Settings/Section/ArchiveNodeSection';
import NodeTimeControlSettingsSection from '../../../Components/Fields/Settings/Section/NodeTimeControlSettingsSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {WorkPackageDetailsProps} from './General';

export default function Settings({node}: WorkPackageDetailsProps): ReactElement {
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const patch = useWorkPackagePatch(node.id);
  const hasTimeControlledAncestos = useTimeControlledAncestors(node.id).length > 0;
  const optionals = useOptionalFields(node.id, {
    timeControl: hasTimeControlledAncestos || Boolean(node.timeControl),
  });
  const useTimeTracking = useSelector(useTimeTrackingSelector);

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      <EnableFieldButton field={optionals.timeControl} label={'nodeDetails:field.timeControl.enable'} />
    </div>
  );

  return (
    <InspectorViewFrame
      footer={canManageBasic && useTimeTracking ? footer : undefined}
      help={'nodeDetails:view.settings.help'}
    >
      <WorkPackageTypeSection node={node} setIsOffer={(isOffer) => patch({isOffer})} readonly={!canManageBasic} />
      {!node.isOffer && <NodeStatus frame={Section} node={node} />}
      <ArchiveNodeSection node={node} />
      {optionals.timeControl.show && (
        <NodeTimeControlSettingsSection
          node={node}
          readonly={!canManageBasic}
          patch={(timeControl) => patch({timeControl})}
        />
      )}
    </InspectorViewFrame>
  );
}
