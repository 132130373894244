import {isDebug} from '@octaved/env/src/Environment';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {Settings} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import HiddenFeatures from '../../Pages/SystemSettings/HiddenFeatures';
import {MenuRootItem} from '../FlowRoutes';
import {customerInspectorRoutes} from './Inspectors/SystemSettings/CustomerInspector';
import {kanbanBoardInspectorRoutes} from './Inspectors/SystemSettings/KanbanBoardInspector';
import {labelInspectorRoutes} from './Inspectors/SystemSettings/LabelInspector';
import {mySettingsRoutes} from './MySettings';
import {getSystemSettingsPagePath, SystemSettingsPage, systemSettingsRoutes} from './SystemSettings';
import {userSettingsRoutes} from './User';

const systemSettingsRoute = 'settings';
const userRoute = 'user';
const customerRoute = 'customer';
const labelsRoute = 'labels';
const kanbanBoardsRoute = 'kanbanBoards';
const hiddenFeatures = 'hidden-features';
export const mySettingsRoute = 'mySettings';

export function getConfigureRoutesWithoutStandardInspector(): string[] {
  return [systemSettingsRoute, userRoute, customerRoute, labelsRoute, kanbanBoardsRoute, mySettingsRoute];
}

export function generateSystemSettingsRoute(page: SystemSettingsPage): string {
  return `/${systemSettingsRoute}/${getSystemSettingsPagePath(page)}`;
}

const User = lazy(() => import('../../Pages/SystemSettings/User/User'));
const Customer = lazy(() => import('../../Pages/SystemSettings/Customer/Customer'));
const Labels = lazy(() => import('../../Pages/SystemSettings/Labels/Labels'));
const KanbanBoards = lazy(() => import('../../Pages/SystemSettings/KanbanBoard/KanbanBoards'));
const SystemSettings = lazy(() => import('../../Pages/SystemSettings/SystemSettings'));
const MySettings = lazy(() => import('../../Pages/MySettings/MySettings'));

export const configRoutes: RouteObject[] = [
  {
    children: systemSettingsRoutes,
    element: <SystemSettings />,
    path: `${systemSettingsRoute}/*`,
  },
  {
    children: userSettingsRoutes,
    element: <User />,
    path: `${userRoute}/*`,
  },
  {
    children: customerInspectorRoutes,
    element: <Customer />,
    path: `${customerRoute}/*`,
  },
  {
    children: labelInspectorRoutes,
    element: <Labels />,
    path: `${labelsRoute}/*`,
  },
  {
    children: kanbanBoardInspectorRoutes,
    element: <KanbanBoards />,
    path: `${kanbanBoardsRoute}/*`,
  },
  {
    children: systemSettingsRoutes,
    element: <SystemSettings />,
    path: `${systemSettingsRoute}/*`,
  },
  {
    children: mySettingsRoutes,
    element: <MySettings />,
    path: `${mySettingsRoute}/*`,
  },
  {
    element: <HiddenFeatures />,
    path: `${hiddenFeatures}/*`,
  },
];

export const configureMenu: MenuRootItem = {
  access: ({orgUserType}) => isInternalUserType(orgUserType),
  groupToken: 'pageMenu:configureGroup',
  icon: Settings,
  id: 'configure',
  items: [
    {
      access: ({isGranted}) =>
        isGranted('FLOW_GLOBAL_INTERNAL_USERS_MANAGE') || isGranted('FLOW_GLOBAL_GUEST_USERS_MANAGE'),
      nameToken: 'systemSettings:menu.user',
      path: userRoute,
    },
    {
      access: 'FLOW_GLOBAL_CUSTOMERS_MANAGE',
      nameToken: 'systemSettings:menu.customer',
      path: customerRoute,
    },
    {
      access: 'FLOW_GLOBAL_LABELS_MANAGE',
      nameToken: 'systemSettings:menu.labels',
      path: labelsRoute,
    },
    {
      access: 'FLOW_GLOBAL_KANBAN_BOARDS_MANAGE',
      nameToken: 'systemSettings:menu.kanbanBoards',
      path: kanbanBoardsRoute,
    },
    {
      nameToken: 'pageMenu:systemSettings',
      path: systemSettingsRoute,
      testId: 'page-menu-settings-settings',
    },
    {
      access: () => isDebug,
      nameToken: '[DEV] Hidden Features',
      path: hiddenFeatures,
    },
  ],
  testId: 'page-menu-settings',
};
