import {FilterStatesValueArray, isComplete} from '../../../Modules/Filter/MergeFilterStatesWithDefaults';
import {
  TransgressionLevel,
  transgressionLevels,
} from '../../../Modules/Hooks/Projects/ProjectControlling/TimeTrackingLimits';

export type QuickControlTimeTransgressionExecution = 'executed' | 'notExecuted';

export interface QuickControlTimeTransgression {
  executions: FilterStatesValueArray<QuickControlTimeTransgressionExecution>;
  levels: FilterStatesValueArray<TransgressionLevel>;
}

export const quickControlTimeTransgressionDefault: QuickControlTimeTransgression = {
  executions: ['executed', 'notExecuted'],
  levels: transgressionLevels,
};

quickControlTimeTransgressionDefault.executions[isComplete] = true;
quickControlTimeTransgressionDefault.levels[isComplete] = true;
