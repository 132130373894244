import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {GroupUserEntities} from '../EntityInterfaces/GroupUserEntity';
import {UserState} from '../UserState';

export const groupUsersSelector = (state: UserState): GroupUserEntities => state.entities.groupUsers;
export const getUsersIdsForGroupSelector = createSelector(
  groupUsersSelector,
  (groupUsers) =>
    (groupId: Uuid): Uuid[] =>
      groupUsers[groupId] || [],
);
