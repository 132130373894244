import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {Tooltip} from '@octaved/ui';
import {CornerDownRight} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {getPlanningDateDependenceSelector} from '../../../../../Selectors/PlanningDependencySelectors';

interface Props {
  nodeId: Uuid;
  planningDateId: Uuid;
}

export default function DependencyIcon({nodeId, planningDateId}: Props): ReactElement | null {
  const {isDependentOfPredecessor} = useSelector((s: FlowState) =>
    getPlanningDateDependenceSelector(s)(nodeId, planningDateId),
  );
  if (!isDependentOfPredecessor) {
    return null;
  }
  return (
    <Tooltip toolTipTranslation={'tooltips:components.planning.bar.isDependentOfPredecessor'}>
      <CornerDownRight className={'mr-0.5 size-5 text-white'} />
    </Tooltip>
  );
}
