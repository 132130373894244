import {PropsWithChildren, ReactElement} from 'react';
import {DEFAULT_INSPECTOR_WIDTH} from './StandardInspectorPage';

export default function StandardInspectorHeaderSubRow({children}: PropsWithChildren<unknown>): ReactElement {
  return (
    <div className={'px-5 pb-2'} style={{width: DEFAULT_INSPECTOR_WIDTH}}>
      {children}
    </div>
  );
}
