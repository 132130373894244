import {isTask} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {PlannableNode} from '../../../../../../Selectors/PlanningSelector';
import {validateDependenciesSelector} from '../../../../../../Selectors/Validation/ValidateDependencies';

interface DependencyResult {
  nodeId: Uuid;
  predecessorId: Uuid;
}

export function calculateDependency(
  sourceNode: PlannableNode,
  targetNode: PlannableNode,
  draggedUp: boolean,
  state: FlowState,
): null | DependencyResult {
  const predecessor = draggedUp ? targetNode : sourceNode;
  const successor = sourceNode === predecessor ? targetNode : sourceNode;

  const predecessorIsTask = isTask(predecessor);
  const successorIsTask = isTask(successor);

  let dependencies: Uuid[] = [...successor.planningPredecessors];

  if (predecessorIsTask !== successorIsTask) {
    //dependencies between tasks and workpackages cannot be created with drag and drop
    return null;
  }

  if (successorIsTask) {
    const getNode = getNodeSelector(state);
    dependencies = dependencies.filter((predecessor) => {
      const node = getNode(predecessor);
      return !node || isTask(node);
    });
  }

  dependencies.push(predecessor.id);
  const nodeId = successor.id;

  const validateDependencies = validateDependenciesSelector(state);

  const errors = validateDependencies(nodeId, dependencies, 'planning');
  if (errors.length) {
    return null;
  }
  return {
    nodeId,
    predecessorId: predecessor.id,
  };
}
