import type {GridState} from 'ag-grid-community';
import type {DefaultViewId} from '../Views/DefaultViews';
import type {NodeTableView} from '../Views/NodeTableView';

//Outsourced here because this file gets imported in the root reducer - this also avoids circular imports

export type NodeTablePickedGridState = Pick<GridState, 'rowGroupExpansion' | 'scroll'> & {
  rowSelection?: string[]; //we don't use server side row model, so only `string[]` is possible!
};

export interface NodeTableGridState {
  gridState: NodeTablePickedGridState;
  viewHash: string; //state is only re-applied if the hash still matches
}

export interface NodeTableUiState {
  currentViewId: string | null;
  gridStates: Partial<Record<string, NodeTableGridState>>; //mapped by view id
  hiddenDefaultViews: ReadonlyArray<DefaultViewId>;
  savedViews: ReadonlyArray<NodeTableView>;
  search: string;
  temporaryViews: ReadonlyArray<NodeTableView>;
}

export interface NodeTablesUiState {
  pages: {
    projects: NodeTableUiState;
    timeTrackingReferenceNodeSelection: NodeTableUiState;
  };
}

export type NodeTablesPage = keyof NodeTablesUiState['pages'];

const initialNodeTableUiState: NodeTableUiState = {
  currentViewId: null,
  gridStates: {},
  hiddenDefaultViews: [],
  savedViews: [],
  search: '',
  temporaryViews: [],
};

export const initialNodeTablesUiState: NodeTablesUiState = {
  pages: {
    projects: initialNodeTableUiState,
    timeTrackingReferenceNodeSelection: initialNodeTableUiState,
  },
};
