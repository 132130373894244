import {useInspectorId} from '@octaved/hooks';
import {ReactElement, useCallback} from 'react';
import {useSelector} from 'react-redux';
import ProjectTree from '../../Components/ProjectTree/ProjectTree';
import {useDefaultProjectTreeOptions} from '../../Modules/Projects/DefaultProjectTree';
import {ProjectTreeOptions} from '../../Modules/Projects/ProjectTreeInterfaces';
import {
  getProjectsFilterStatesSelector,
  projectsGroupProjectsSelector,
  projectsSelectedProjectFolderSelector,
  projectsShowOnlyFavoriteProjectsSelector,
  projectsShowSubFolderProjectsSelector,
  projectsSortProjectsBySelector,
} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {projectsFilteredCustomerSelector, projectsSearchSelector} from '../../Modules/Selectors/UiSelectors';
import {FlowState} from '../../Modules/State';
import {useProjectContext} from './ProjectContext';
import {useProjectUiStatePatch} from './Projects';
import {useSelectProjectId} from './ProjectsHooks';

export default function ProjectsList({treeOptions = {}}: {treeOptions?: Partial<ProjectTreeOptions>}): ReactElement {
  const inspectorId = useInspectorId();
  const {page} = useProjectContext();

  const options = useDefaultProjectTreeOptions();
  options.filterStates = useSelector((s: FlowState) => getProjectsFilterStatesSelector(s)(page));
  const hideEmptyFilter = options.filterStates.hideParentsWithoutMatches;
  if (hideEmptyFilter) {
    options.hideEmptyParents = hideEmptyFilter.value;
  }
  options.includeCustomerId = useSelector(projectsFilteredCustomerSelector);
  options.includeSubProjectFolders = useSelector(projectsShowSubFolderProjectsSelector);
  options.projectFolder = useSelector(projectsSelectedProjectFolderSelector);
  options.searchTerm = useSelector(projectsSearchSelector);
  options.selectNodeId = useSelectProjectId();
  options.selectedNodeId = inspectorId;
  options.showOnlyFavoriteProjects = useSelector(projectsShowOnlyFavoriteProjectsSelector);
  options.showPidInspectorMenu = true;
  options.sortBy = useSelector(projectsSortProjectsBySelector);
  options.useGrouping = useSelector(projectsGroupProjectsSelector);

  const patchUi = useProjectUiStatePatch();
  options.onProjectTreeApiChange = useCallback(
    (mainProjectTreeApi) => {
      setTimeout(() => patchUi({mainProjectTreeApi}), 0);
    },
    [patchUi],
  );

  Object.assign(options, treeOptions);

  return <ProjectTree treeOptions={options} />;
}
