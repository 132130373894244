import {Tooltip, cn} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {NavLink} from 'react-router-dom';

interface Props {
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  lessHorizontalPadding?: boolean;
  to: string;
  token: string;
  tooltipTranslation?: string;
}

export default function LinkTab({
  className,
  disabled = false,
  hidden,
  lessHorizontalPadding,
  to,
  token,
  tooltipTranslation,
}: Props): ReactElement {
  return (
    <Tooltip
      basic
      toolTipTranslation={tooltipTranslation || ''}
      disabled={!tooltipTranslation}
      position={'bottom center'}
    >
      <NavLink
        to={to + window.location.search}
        className={({isActive}) =>
          cn(
            'relative block h-10 px-3 text-center text-slate-800 transition-all duration-150 hover:cursor-pointer hover:bg-black/5 hover:text-slate-800',
            'z-10 after:absolute after:bottom-0 after:left-6 after:right-6 after:h-0.5 after:transition-all after:duration-150 after:content-[""]',
            isActive &&
              'font-semibold text-slate-700 after:bg-purple-700 hover:cursor-default hover:after:left-0 hover:after:right-0',
            disabled && 'pointer-events-none cursor-none text-slate-400',
            lessHorizontalPadding && 'px-0',
            hidden && 'none',
            className,
          )
        }
      >
        <div className={'flex h-full items-center justify-center text-base'}>
          <Trans i18nKey={token} />
        </div>
        <span className={'invisible block h-0 px-3 font-semibold'}>
          <Trans i18nKey={token} />
        </span>
      </NavLink>
    </Tooltip>
  );
}
