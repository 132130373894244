import {error} from '@octaved/env/src/Logger';
import {ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {DeepPartial, List} from '@octaved/typescript/src/lib';
import {MergeStates, mergeStates} from '../MergeStates';
import {Reducer, ReducerMap} from '../Store';
import {addMultiToReducerMap} from './ReduceFromMap';

export interface OptimisticReduceResult<State extends List<unknown>, Action extends ServerRequestAction> {
  onRequest: Reducer<State, Action>;
  onRevert: Reducer<State, Action>;
  onSuccess: Reducer<State, Action>;
}

function getDefaultData<Action extends ServerRequestAction>(action: Action): object | null {
  return action?.options?.data || null;
}

function requireId<Action extends ServerRequestAction>(action: Action, getId: (action: Action) => string): string {
  const id = getId(action);
  if (!id) {
    error('No id found in action', action);
  }
  return id;
}
export function optimisticAdd<State extends List<unknown>, Action extends ServerRequestAction>(
  getId: (action: Action) => string,
  getData: (action: Action, state: State) => object | null = getDefaultData,
  _mergeFn: MergeStates<State> = mergeStates,
): OptimisticReduceResult<State, Action> {
  return {
    onRequest(state: State, action: Action) {
      const id = requireId(action, getId);
      const data = getData(action, state);
      if (data && !state[id]) {
        return {
          ...state,
          [id]: data,
        };
      }
      return state;
    },
    onRevert(state: State, action: Action) {
      const id = requireId(action, getId);
      if (state[id]) {
        const newState = {...state};
        delete newState[id];
        return newState;
      }
      return state;
    },
    onSuccess(state: State, _action: Action) {
      return state;
    },
  };
}

export function optimisticUpdate<State extends List<unknown>, Action extends ServerRequestAction>(
  getId: ((action: Action) => string) | null,
  getData: (action: Action, state: State) => object | null = getDefaultData,
  mergeFn: MergeStates<State> = mergeStates,
): OptimisticReduceResult<State, Action> {
  const map = new Map<number, unknown>();
  return {
    onRequest(state: State, action: Action) {
      const id = getId ? requireId(action, getId) : null;
      if (id && !state[id]) {
        return state;
      }
      const data = getData(action, state);
      if (data) {
        map.set(action.requestTime, id ? state[id] : state);
        return mergeFn(state, (id ? {[id]: data} : data) as DeepPartial<State>);
      }
      return state;
    },
    onRevert(state: State, action: Action) {
      const id = getId ? requireId(action, getId) : null;
      if (id && !state[id]) {
        return state;
      }
      if (map.has(action.requestTime)) {
        const previousState = map.get(action.requestTime);
        map.delete(action.requestTime);
        return id ? {...state, [id]: previousState} : (previousState as State);
      }
      return state;
    },
    onSuccess(state: State, action: Action) {
      if (map.has(action.requestTime)) {
        map.delete(action.requestTime);
      }
      return state;
    },
  };
}

export function optimisticDelete<State extends List<unknown>, Action extends ServerRequestAction>(
  getId: (action: Action) => string,
): OptimisticReduceResult<State, Action> {
  const map = new Map<number, unknown>();
  return {
    onRequest(state: State, action: Action) {
      const id = requireId(action, getId);
      if (state[id]) {
        map.set(action.requestTime, state[id]);
        const newState = {...state};
        delete newState[id];
        return newState;
      }
      return state;
    },
    onRevert(state: State, action: Action) {
      const id = requireId(action, getId);
      if (!state[id] && map.has(action.requestTime)) {
        const previousState = map.get(action.requestTime);
        map.delete(action.requestTime);
        return {...state, [id]: previousState};
      }
      return state;
    },
    onSuccess(state: State, action: Action) {
      if (map.has(action.requestTime)) {
        map.delete(action.requestTime);
      }
      return state;
    },
  };
}

export function setToReducerMap<State extends List<unknown>, Action extends ServerRequestAction>(
  optimisticReducer: (
    getId: null,
    getData?: (action: Action, state: State) => object | null,
    mergeFn?: MergeStates<State>,
  ) => OptimisticReduceResult<State, Action>,
  reducerMap: ReducerMap<State, Action>,
  onRequestType: string | string[],
  onRevertType: string | string[],
  onSuccessType: string | string[],
  getId: null,
  getData?: (action: Action, state: State) => object | null,
  mergeFn?: MergeStates<State>,
): void;
export function setToReducerMap<State extends List<unknown>, Action extends ServerRequestAction>(
  optimisticReducer: (
    getId: (action: Action) => string,
    getData?: (action: Action, state: State) => object | null,
    mergeFn?: MergeStates<State>,
  ) => OptimisticReduceResult<State, Action>,
  reducerMap: ReducerMap<State, Action>,
  onRequestType: string | string[],
  onRevertType: string | string[],
  onSuccessType: string | string[],
  getId: (action: Action) => string,
  getData?: (action: Action, state: State) => object | null,
  mergeFn?: MergeStates<State>,
): void;
export function setToReducerMap<
  State extends List<unknown>,
  Action extends ServerRequestAction,
  GID = ((action: Action) => string) | null,
>(
  optimisticReducer: (
    getId: GID,
    getData?: (action: Action, state: State) => object | null,
    mergeFn?: MergeStates<State>,
  ) => OptimisticReduceResult<State, Action>,
  reducerMap: ReducerMap<State, Action>,
  onRequestType: string | string[],
  onRevertType: string | string[],
  onSuccessType: string | string[],
  getId: GID,
  getData: (action: Action, state: State) => object | null = getDefaultData,
  mergeFn: MergeStates<State> = mergeStates,
): void {
  const reducer = optimisticReducer(getId, getData, mergeFn);
  addMultiToReducerMap(reducerMap, onRequestType, reducer.onRequest);
  addMultiToReducerMap(reducerMap, onRevertType, reducer.onRevert);
  addMultiToReducerMap(reducerMap, onSuccessType, reducer.onSuccess);
}
