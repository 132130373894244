import {isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {GroupedMilestoneResult} from '../../Selectors/MilestoneSelectors';
import {Bar, BarPluginList, BarProps} from './Bar';

interface MaturityDateBarProps extends BarProps {
  maturity: GroupedMilestoneResult;
}

export class MaturityDateBar extends Bar {
  readonly #maturity: GroupedMilestoneResult;

  constructor(props: MaturityDateBarProps) {
    super(props);
    this.#maturity = props.maturity;
  }

  init(...plugins: BarPluginList): void {
    const isoDateTime = isoToIsoDateTimeFormat(this.#maturity.date);
    this.setDates(isoDateTime, isoDateTime);
    super.init(...plugins);
  }
}
