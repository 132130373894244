import {Check, X} from 'lucide-react';
import {ReactElement} from 'react';

interface BooleanCellProps {
  cell: {value: boolean};
}

export default function BooleanCell({cell: {value}}: BooleanCellProps): ReactElement | null {
  if (value) {
    return <Check />;
  }
  return <X />;
}
