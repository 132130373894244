import {billingListQuickFilterConfigs} from '@octaved/billing/src/QuickFilters';
import {EnumFlowBillingInterval, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {AnyQuickFilterConfig} from '@octaved/quick-filters/src/Config';
import {Uuid} from '@octaved/typescript/src/lib';
import {mapCache} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {isItemAccessibleSelector} from '../../../../AccessibleItem';
import {BillingSearchCondition} from '../../../../EntityInterfaces/Billing/BillingSearch';
import {ProjectPages} from '../../../../Pages/Projects/ProjectContext';
import {TimePeriodInterval} from '../../../Filter/TimePeriod';
import {FlowState} from '../../../State';
import {getNodeSearchSelector} from '../../NodeSearchSelectors';
import {getAllDescendantIdsSelector} from '../../NodeTreeSelectors';
import {rootFoldersProjectFolderSelector} from '../../RootFolderSelectors';
import {projectsSearchSelector} from '../../UiSelectors';
import {getProjectsFilterValueSelector, projectUi} from '../ProjectsSelector';

function billingsList<R>(
  selector: (state: FlowState['ui']['pages']['billingsList']) => R,
): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.billingsList);
}

export const checkedWorkPackageIdsSelector = projectUi((state) => state.billing.checkedWorkPackageIds);
const checkedWorkPackageIdsInPeriodSelector = projectUi((state) => state.billingInPeriod.checkedWorkPackageIds);
export const checkedWorkPackageIdsPreparationSelector = projectUi(
  (state) => state.billingPreparation.checkedWorkPackageIds,
);

export const checkedWorkPackageIdsSetSelector = createSelector(checkedWorkPackageIdsSelector, (ids) => new Set(ids));
export const checkedWorkPackageIdsSetInPeriodSelector = createSelector(
  checkedWorkPackageIdsInPeriodSelector,
  (ids) => new Set(ids),
);
export const checkedWorkPackageIdsSetPreparationSelector = createSelector(
  checkedWorkPackageIdsPreparationSelector,
  (ids) => new Set(ids),
);

export const newBillingOptionsSelector = projectUi((state) => state.billing.newBilling);
export const billingsListExportCsvTypeSelector = billingsList((state) => state.export.csv.type);
export const billingsListExportTimeSheetAdvancedIsOpenSelector = billingsList(
  (state) => state.export.timeSheet.advancedIsOpen,
);
export const billingsListQuickFilterValuesSelector = billingsList((state) => state.quickFilterValues);

export const billingsListTableQuerySelector = createSelector(
  projectsSearchSelector,
  billingsListQuickFilterValuesSelector,
  isItemAccessibleSelector,
  rootFoldersProjectFolderSelector,
  (search, quickFilterValues, isItemAccessible, rootFolder): [BillingSearchCondition | null, string] => {
    const conditions: BillingSearchCondition[] = [];

    if (search) {
      conditions.push({
        or: [
          ['customerName', search],
          ['projectName', search],
          ['createdByUserName', search],
          ['internalComment', search],
        ],
      });
    }

    let projectFolderId: string | null = null;
    billingListQuickFilterConfigs.forEach((config) => {
      const anyConfig = config as AnyQuickFilterConfig<BillingSearchCondition>;
      if (isItemAccessible(anyConfig)) {
        const value = (quickFilterValues as Record<string, unknown>)[config.ident] ?? config.defaultValue;
        const query = anyConfig.toSearchQuery(value);
        if (query) {
          conditions.push(query);
          if (config.ident === 'projectFolder') {
            projectFolderId = value as string;
          }
        }
      }
    });

    if (!projectFolderId) {
      projectFolderId = rootFolder;
      conditions.push(['rootNodeId', rootFolder]);
    }

    return [conditions.length ? {and: conditions} : null, projectFolderId];
  },
);

const nodeTypeWpSelector = getNodeSearchSelector('nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE);
export const getDescendantWpIdsSelector = createSelector(
  getAllDescendantIdsSelector,
  nodeTypeWpSelector,
  (getAllDescendantIds, allWorkPackageIds) =>
    memoize((id: Uuid): Uuid[] => {
      const descendantIds = getAllDescendantIds(id);
      return [...descendantIds].filter((id) => allWorkPackageIds.has(id));
    }),
);

export const getDescendantCheckedWpIdsSelector = createSelector(getDescendantWpIdsSelector, (getDescendantWpIds) =>
  mapCache((id: Uuid, checkedIds: Set<Uuid>): Uuid[] => {
    const descendantWpIds = getDescendantWpIds(id);
    return descendantWpIds.filter((id) => checkedIds.has(id));
  }),
);

export const billingPeriodSelector = createSelector(
  getProjectsFilterValueSelector,
  (getProjectsFilterValue) => getProjectsFilterValue('billingInPeriod', 'quickBillingInPeriod').period,
);

const timePeriodIntervalToBillingInterval: Record<TimePeriodInterval, EnumFlowBillingInterval> = {
  custom: EnumFlowBillingInterval.VALUE_CUSTOM,
  month: EnumFlowBillingInterval.VALUE_MONTH,
  quarter: EnumFlowBillingInterval.VALUE_QUARTER,
  year: EnumFlowBillingInterval.VALUE_YEAR,
};

export const billingIntervalDataSelector = createSelector(billingPeriodSelector, (period) =>
  memoize((page: ProjectPages) => {
    if (page === 'billingInPeriod') {
      return {
        interval: timePeriodIntervalToBillingInterval[period.interval],
        intervalFrom: period.from,
        intervalTo: period.to,
      };
    }
    return {};
  }),
);
