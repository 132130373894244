import {BillingErpStatus} from '@octaved/env/src/dbalEnumTypes';
import {AuditBilling} from '../../../../EntityInterfaces/Audit/AuditBilling';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const statusTranslations = new Map<BillingErpStatus, string>([
  [BillingErpStatus.processedSuccessful, 'systemSettings:audit.tables.billing.enumStatusProcessedSuccessful'],
  [BillingErpStatus.none, 'systemSettings:audit.tables.billing.enumStatusNone'],
  [BillingErpStatus.processedWithError, 'systemSettings:audit.tables.billing.enumStatusProcessedWithError'],
  [BillingErpStatus.processedWithWarning, 'systemSettings:audit.tables.billing.enumStatusProcessedWithWarning'],
  [BillingErpStatus.transmittedFailed, 'systemSettings:audit.tables.billing.enumStatusTransmittedFailed'],
  [BillingErpStatus.processing, 'systemSettings:audit.tables.billing.enumStatusProcessing'],
  [BillingErpStatus.transmittedSuccessful, 'systemSettings:audit.tables.billing.enumStatusSuccessful'],
]);

const config: AuditTableConfiguration<AuditBilling> = {
  columns: [
    ...historyColumns,
    createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
    createColumnDef('internalComment', 'systemSettings:audit.tables.billing.internalCommentHeader'),
    createColumnDef('erpStatus', 'systemSettings:audit.tables.billing.erpStatusHeader', ({cell: {value}}) => {
      return <EnumTranslationCell value={value} translationMapping={statusTranslations} />;
    }),
    createColumnDef('erpReference', 'systemSettings:audit.tables.billing.erpReferenceHeader'),
    createColumnDef('erpMessage', 'systemSettings:audit.tables.billing.erpMessageHeader'),
  ],
  tableIdent: 'billings',
  tableNameTranslationToken: 'systemSettings:audit.billingTableName',
};
export default config;
