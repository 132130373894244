import ClockSvg from '@fortawesome/fontawesome-pro/svgs/solid/clock.svg';
import {Icon, Tooltip} from '@octaved/ui';
import classNames from 'classnames';
import {memo, ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useLastOpenTimeRecordOfCurrentUser} from '../../../Modules/Hooks/TimeRecordSearch';
import {useNodeAncestry} from '../../../Modules/Selectors/NodeTreeSelectors';
import {editingTimeRecordSelector} from '../../../Modules/Selectors/UiSelectors';
import {editTimeRecord} from '../../../Modules/UiPages/TimeRecords';
import {getReferenceNodeFromAncestry} from '../../../TimeTracking/ReferenceNode';
import {useTimeTrackingReferenceNodeFromCanvas} from './TimeRecordingDrawerContext';

export default memo(function TimeRecordingsButtons(): ReactElement {
  const dispatch = useDispatch();
  const {inspectId, largeViewNodeId} = useParams<'inspectId' | 'largeViewNodeId'>();
  const ancestryFromUrl = useNodeAncestry(inspectId ?? largeViewNodeId, true);
  const refNodeFromUrl = getReferenceNodeFromAncestry(ancestryFromUrl);
  const nodeFromCanvas = useTimeTrackingReferenceNodeFromCanvas();
  const {timeRecord} = useLastOpenTimeRecordOfCurrentUser();
  const isEditingTimeRecord = useSelector(editingTimeRecordSelector) === null;

  return (
    <Tooltip toolTipTranslation={'tooltips:components.pageHeader.quickAccessButtonTrackTime'} position={'bottom right'}>
      <div
        onClick={() => {
          if (isEditingTimeRecord) {
            const type = timeRecord ? 'edit' : 'create';
            dispatch(
              editTimeRecord({
                type,
                reference: timeRecord?.id || null,
                referenceNode: refNodeFromUrl?.id ?? nodeFromCanvas?.id,
              }),
            );
          } else {
            dispatch(editTimeRecord(null));
          }
        }}
      >
        <div className={classNames('timeRecordingButton', {play: !timeRecord})}>
          <Icon iconColor={'white'} noMargin>
            <ClockSvg />
          </Icon>

          {timeRecord && <div className={'dot'} />}
        </div>

        {/*#region styles*/}
        {/*language=SCSS*/}
        <style jsx>{`
          .timeRecordingButton {
            background: linear-gradient(225deg, #78c900 0%, #68ad00 100%);
            background-color: #609f00;
            border: 1px solid #609f00;
            height: 32px;
            min-width: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;

            & :global(.flowColor-brightGreen svg) {
              fill: #fff;
            }

            .dot {
              position: absolute;
              background-color: #ff0000;
              color: #ff0000;
              height: 6px;
              min-width: 6px;
              border-radius: 4px;
              z-index: 2;
              top: 6px;
              right: 6px;
            }
          }
        `}</style>
      </div>
    </Tooltip>
  );
});
