import {DateStr, DateTimeStr, isDateStr} from '@octaved/typescript';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {cn} from '../Hooks';

enum FormatDateFormats {
  short = 'DD.MM.',
  short_with_time = 'DD.MM. HH:mm',
  short_with_day = 'dddd, DD.MM.',
  short_with_day_short = 'dd. DD.MM.',
  short_with_year = 'DD.MM.YYYY',
  short_with_year_with_day = 'dd. DD.MM.YYYY',
  time_m = 'HH:mm',
  long_with_year = 'dddd, DD.MM.YYYY',
  long_with_year_and_time = 'dddd, DD.MM.YYYY HH:mm',
}

interface PropsWithTime {
  date: DateTimeStr;
  format: FormatDateFormats.short_with_time | FormatDateFormats.long_with_year_and_time | FormatDateFormats.time_m;
}

interface PropsWithoutTime {
  date: DateStr | DateTimeStr;
  format?:
    | FormatDateFormats.short
    | FormatDateFormats.short_with_day
    | FormatDateFormats.short_with_day_short
    | FormatDateFormats.short_with_year_with_day
    | FormatDateFormats.long_with_year
    | FormatDateFormats.short_with_year;
}

type Props = PropsWithTime | PropsWithoutTime;

function FormatDate({date, format = FormatDateFormats.short}: Props): ReactElement {
  return <span className={cn(isDateStr(date) && 'tabular-nums')}>{dayjs(date).format(format)}</span>;
}

export {FormatDate, FormatDateFormats};
