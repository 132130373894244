import {ReactElement} from 'react';
import {OptionalNoLabelProps} from '../../../../General/Common/Type';
import Section from '../../../../Section';
import ProjectPlanning, {ProjectPlanningProps} from '../Common/ProjectPlanning';

export default function ProjectPlanningSection(
  props: ProjectPlanningProps & OptionalNoLabelProps,
): ReactElement | null {
  return <ProjectPlanning frame={Section} {...props} />;
}
