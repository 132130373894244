import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useAncestorBudgetSums} from '@octaved/flow/src/Modules/Projects/AncestorBudgetSums';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../General/Common/Type';
import NodeSumOfPriceLimits from './Common/NodeSumOfPriceLimits';
import NodeSumOfTimeLimits from './Common/NodeSumOfTimeLimits';

interface Translations {
  timeLimitsHeadline: string;
  priceLimitsHeadline: string;
}

const translations: Record<EnumFlowNodeType.VALUE_PROJECT | EnumFlowNodeType.VALUE_GROUP, Translations> = {
  [EnumFlowNodeType.VALUE_PROJECT]: {
    priceLimitsHeadline: 'pages:projects.inspector.timeAndFinancesWorkPackage.projectBudget.sumOfPricesHeadline',
    timeLimitsHeadline: 'pages:projects.inspector.timeAndFinancesWorkPackage.projectBudget.sumOfTimeLimitsHeadline',
  },
  [EnumFlowNodeType.VALUE_GROUP]: {
    priceLimitsHeadline: 'pages:projects.inspector.timeAndFinancesWorkPackage.groupBudget.sumOfPricesHeadline',
    timeLimitsHeadline: 'pages:projects.inspector.timeAndFinancesWorkPackage.groupBudget.sumOfTimeLimitsHeadline',
  },
};

interface NodeAncesorsBudgetSumsProps {
  ancestor: Project | Group;
}

export default function NodeAncestorBudgetSums({
  frame,
  ancestor,
}: NodeAncesorsBudgetSumsProps & FramedSectionOrTileProps): ReactElement | null {
  const {
    countWorkPackages,
    countWorkPackagesWithLimit,
    countWorkPackagesWithPrice,
    isLoading,
    sumPriceLimits,
    sumTimeLimits,
  } = useAncestorBudgetSums(ancestor.id);

  return (
    <>
      <NodeSumOfTimeLimits
        frame={frame}
        countWorkPackages={countWorkPackages}
        countWorkPackagesWithLimit={countWorkPackagesWithLimit}
        isLoading={isLoading}
        maxEffort={ancestor.maxEffort}
        sumTimeLimits={sumTimeLimits}
        useDays={false}
        headline={translations[ancestor.nodeType].timeLimitsHeadline}
      />
      <NodeSumOfPriceLimits
        frame={frame}
        countWorkPackages={countWorkPackages}
        isLoading={isLoading}
        maxPrice={ancestor.maxPrice}
        sumPriceLimits={sumPriceLimits}
        countWorkPackagesWithPrice={countWorkPackagesWithPrice}
        headline={translations[ancestor.nodeType].priceLimitsHeadline}
      />
    </>
  );
}
