import {getBookedTimeRecordStatistic} from '@octaved/flow-api';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {CALL_API, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {List, Uuid} from '@octaved/typescript/src/lib';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_FAILURE,
  FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_REQUEST,
  FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_SUCCESS,
} from '../ActionTypes';
import {statisticsChargableHoursSelector, statisticsChargableHoursStateSelector} from '../Selectors/Statistics';

interface BookedTimeRecords {
  external: number;
  freeOfChargeAquisition: number;
  freeOfChargeService: number;
  freeOfChargeWarranty: number;
  internal: number;
  internalChargable: number;
}

type ChargableHoursList = List<BookedTimeRecords | undefined>;

export interface StoreChargableHours {
  chargableHours: ChargableHoursList;
  chargableHoursState: EntityStates;
}

interface LoadAction extends ServerResponseAction<BookedTimeRecords> {
  key: string;
}

const emptyHours: BookedTimeRecords = {
  external: 0,
  freeOfChargeAquisition: 0,
  freeOfChargeService: 0,
  freeOfChargeWarranty: 0,
  internal: 0,
  internalChargable: 0,
};

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_SUCCESS,
  (state: ChargableHoursList, {key, response}: LoadAction): ChargableHoursList => mergeStates(state, {[key]: response}),
);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_SUCCESS, createTimestampReducer('key', LOADED));
addMultiToReducerMap(
  stateReducerMap,
  [
    FLOW_CHANGE_PID_SUCCESS,
    'flow.ProjectCustomerChangedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  () => ({}),
);

export const chargableHoursReducers = {
  chargableHours: ReduceFromMap(reducerMap),
  chargableHoursState: ReduceFromMap(stateReducerMap),
};

export function useBookedTimeRecordStatistic(
  rootNodeId: Uuid | null,
  mode: 'recorded' | 'chargable',
  from: string,
  to: string,
): {isLoading: boolean; hours: BookedTimeRecords} {
  const dispatch = useDispatch();
  const key = `${rootNodeId}:${mode}:${from}:${to}`;
  const hours = useSelector(statisticsChargableHoursSelector)[key] || emptyHours;
  const state = useSelector(statisticsChargableHoursStateSelector)[key];
  useEffect(() => {
    if (rootNodeId && (!state || isOutdated(state))) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: getBookedTimeRecordStatistic,
          options: {urlParams: {nodeId: rootNodeId, mode, from, to}},
          types: {
            failureType: FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_FAILURE,
            requestType: FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_REQUEST,
            successType: FLOW_LOAD_CHARGABLE_HOURS_STATISTIC_SUCCESS,
          },
        },
      });
    }
  }, [dispatch, from, key, mode, rootNodeId, state, to]);
  const isLoading = !rootNodeId || !state || !isLoaded(state);
  return {
    isLoading,
    hours: useLoadedValue(isLoading, hours),
  };
}
