import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export default function OnlySelfAssigned(): ReactElement {
  const {t} = useTranslation();
  return (
    <span className={'name'}>
      <span>{t('pages:myTasks.selfAssigned')}</span>
      <style jsx>{`
        .name {
          color: var(--color-grey-600);
        }
      `}</style>
    </span>
  );
}
