import classNames from 'classnames';
import {HTMLProps, ReactElement, ReactNode} from 'react';

interface Props extends HTMLProps<HTMLSpanElement> {
  children: ReactNode;
  italic?: boolean;
}

export default function PlaceholderText({children, italic = true, ...props}: Props): ReactElement {
  return (
    <span {...props} className={classNames(props.className, 'placeholderText', {italic})}>
      {children}

      {/*language=SCSS*/}
      <style jsx>{`
        .placeholderText {
          color: var(--color-grey-500);
        }

        .italic {
          font-style: italic;
        }
      `}</style>
    </span>
  );
}
