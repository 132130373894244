import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {Message} from 'semantic-ui-react';

export default function AutChain(): ReactElement {
  return (
    <Message visible info>
      <Trans i18nKey={'pages:projects.inspector.manage.planning.logicalDependency.autoChain'} />
    </Message>
  );
}
