import {createContext, PropsWithChildren, ReactElement, useCallback, useContext, useState} from 'react';

interface State {
  init?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const context = createContext<State>({
  init: false,
  open: false,
  setOpen: () => undefined,
});

export default function ContextMenuRoot({children}: PropsWithChildren<unknown>): ReactElement {
  const [open, setOpen] = useState(false);

  return <context.Provider value={{open, setOpen, init: true}}>{children}</context.Provider>;
}

export function useContextMenuRequired(): State {
  const ctxt = useContext(context);
  if (!ctxt.init) {
    throw new Error('Missing context menu root context');
  }
  return ctxt;
}

export function useCloseContextMenu(): () => void {
  const ctxt = useContext(context);

  return useCallback(() => {
    if (ctxt.init && ctxt.open) {
      ctxt.setOpen(false);
    }
  }, [ctxt]);
}
