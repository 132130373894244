import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {getBillingTypeTranslatable} from '@octaved/flow/src/WorkPackage/BillingType';
import {TWProgress} from '@octaved/ui';
import {ReactElement} from 'react';
import HoursOfTotal from './HoursOfTotal';
import {RowProps} from './Interfaces';
import WithOrWithoutLimit from './WithOrWithoutLimit';

export default function FixedPriceWithMaxEffortRow({billable = false, sum}: RowProps): ReactElement {
  const token = getBillingTypeTranslatable(EnumFlowPidBillingType.VALUE_FIXED_PRICE, billable);
  return (
    <>
      <WithOrWithoutLimit token={token} />
      <TWProgress
        progress={(sum.billableHoursWithMaxEffort * 100) / sum.maxEffortHours}
        color={sum.billableHoursWithMaxEffort > sum.maxEffortHours ? 'red' : 'blue'}
        size={'lg'}
      />
      <HoursOfTotal hours={sum.billableHoursWithMaxEffort} total={sum.maxEffortHours} shortUnit />
    </>
  );
}
