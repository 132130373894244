import {useOnFocusOutside} from '@octaved/hooks/src/OnFocusOutside';
import {useOnOutsideClick} from '@octaved/hooks/src/OnOutsideClick';
import {Uuid} from '@octaved/typescript/src/lib';
import {Icon, Popup, StopClickPropagation} from '@octaved/ui';
import {useDropdownHoverIndex} from '@octaved/ui-components/src/Hooks/UseDropdownHoverIndex';
import {FullUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {useUnitSearch} from '@octaved/users/src/Hooks/UnitSearch';
import {responsibleUnitTypes} from '@octaved/users/src/UnitType';
import classNames from 'classnames';
import {Check} from 'lucide-react';
import {ReactElement, RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNodeResponsibleFullUnits} from '../../../../../../Modules/Hooks/Nodes';
import {
  patchNodesResponsibilities,
  toggleNodeResponsibleUnit,
  toggleSingleNodeResponsibleUnit,
} from '../../../../../../Modules/ResponsibleNode';
import {getProjectForNodeSelector} from '../../../../../../Modules/Selectors/PidSelectors';
import {FlowState} from '../../../../../../Modules/State';
import {useDeprecatedUserIds} from '../../../../../../Modules/Users/DeprecatedUsers';
import BaseFlowAvatar from '../../../../../Avatar/BaseFlowAvatar';
import Link from '../../../../../Link';
import {SearchHighlightContext, SearchHighlightText} from '../../../../../Search/SearchHighlight';

function useUnitSearchWithExternalAndAssigned(
  taskId: Uuid,
  assignments: FullUnit[],
): {
  searchValue: string;
  setSearchValue: (s: string) => void;
  results: ReadonlyArray<FullUnit>;
} {
  const customerId = useSelector((s: FlowState) => getProjectForNodeSelector(s)(taskId)?.flowCustomer);
  const {userIds: excludedUnitIds} = useDeprecatedUserIds();
  const {searchValue, setSearchValue, results} = useUnitSearch({
    excludedUnitIds,
    extraUnits: assignments,
    filterGuestUserByCustomerId: customerId,
    unitTypes: responsibleUnitTypes,
  });
  return {searchValue, setSearchValue, results};
}

interface Props {
  containerRef: RefObject<HTMLDivElement>;
  setFocused: (focus: boolean | ((f: boolean) => boolean)) => void;
  taskId: Uuid;
}

export default function Focused({containerRef, setFocused, taskId}: Props): ReactElement {
  const responsibleUnits = useNodeResponsibleFullUnits(taskId);
  const responsibleUnitIds = useMemo(() => new Set(responsibleUnits.map(({unitId}) => unitId)), [responsibleUnits]);
  const {t} = useTranslation();
  const [singleSelect, setSingleSelect] = useState(responsibleUnitIds.size <= 1);
  const {searchValue, setSearchValue, results} = useUnitSearchWithExternalAndAssigned(taskId, responsibleUnits);
  const dispatch = useDispatch();

  const onSelect = (selected: FullUnit): void => {
    const action = singleSelect ? toggleSingleNodeResponsibleUnit : toggleNodeResponsibleUnit;
    dispatch(action(taskId, selected));
    setSearchValue('');
    if (singleSelect) {
      setSearchValue('');
      setFocused(false);
    }
  };

  const {hoverIndex, setHoverIndex, keyDownListener} = useDropdownHoverIndex(
    (results || []).length - 1,
    containerRef,
    47,
    250,
    (index: number) => {
      if (index !== -1 && results[index]) {
        onSelect(results[index]);
      }
      setSearchValue('');
    },
  );

  useEffect(() => setSearchValue(''), [setSearchValue]);

  const close = useCallback(() => {
    setHoverIndex(-1);
    setSearchValue('');
    setFocused(false);
  }, [setFocused, setHoverIndex, setSearchValue]);
  useOnOutsideClick(containerRef, close);
  useOnFocusOutside(containerRef, close);

  return (
    <>
      <input
        autoFocus
        className={'unitSearch'}
        type={'text'}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setHoverIndex(0);
        }}
        placeholder={
          responsibleUnits.length > 0
            ? responsibleUnits.map(({unitName}) => unitName).join(', ')
            : t('taskList:assignments.placeholder')
        }
        onKeyDown={keyDownListener}
      />

      {/*<ResetAssignmentButton focused patch={patch} setFocused={setFocused} task={task} />*/}

      <Popup open context={containerRef} mountNode={containerRef.current} position={'bottom right'}>
        <StopClickPropagation>
          <div className={'flex max-h-[460px] w-[280px] flex-col overflow-hidden'}>
            <SearchHighlightContext searchTerm={searchValue}>
              <div className={'js-dropdownList overflow-y-scroll'}>
                {results.map((unit, idx) => {
                  const {unitId, unitName, unitType} = unit;
                  const selected = responsibleUnitIds.has(unitId);
                  return (
                    <div
                      key={unitId}
                      className={classNames('unitRow', unitType, {active: hoverIndex === idx})}
                      onClick={() => onSelect(unit)}
                      onMouseEnter={() => setHoverIndex(idx)}
                    >
                      <Icon iconColor={selected ? 'green' : 'white'}>
                        <Check />
                      </Icon>
                      <BaseFlowAvatar key={unitId} id={unitId} type={unitType} size={'small'} />
                      <div className={'unitName'}>
                        <SearchHighlightText text={unitName} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </SearchHighlightContext>
            <div className={'border-t-1 flex justify-between border-slate-300 bg-slate-100 px-2 pb-1 pt-0.5 text-sm'}>
              {singleSelect ? (
                <Link underline className={'!text-slate-800'} onClick={() => setSingleSelect(false)}>
                  {t('taskList:assignments.enableMultiSelect')}
                </Link>
              ) : (
                <div />
              )}
              {!singleSelect && (
                <Link
                  underline
                  className={'!text-red-700'}
                  onClick={() => dispatch(patchNodesResponsibilities({[taskId]: {}}))}
                >
                  {t('taskList:assignments.deleteAll')}
                </Link>
              )}
            </div>
          </div>
        </StopClickPropagation>
      </Popup>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .unitSearch {
          height: 100%;
          width: 100%;
          border: none;
          outline: transparent;
        }

        .unitRow {
          display: flex;
          align-items: center;
          grid-gap: 0.4375rem;
          padding: 0.25rem 0.4375rem;
        }

        .unitRow + .unitRow.external {
          border-top: 1px solid var(--color-grey-300);
        }

        .unitRow:first-child {
          flex-shrink: 0;
        }

        .unitDescription {
          font-size: 12px;
        }

        .unitRow.active {
          background-color: rgba(0, 0, 0, 0.05);
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
