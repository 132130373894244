import {Schema} from 'normalizr';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

export const NETWORK_REQUEST = 'NETWORK_REQUEST';
export const NETWORK_SUCCESS = 'NETWORK_SUCCESS';
export const NETWORK_FAILURE = 'NETWORK_FAILURE';

export type NETWORK_METHOD = 'get' | 'post' | 'patch' | 'del' | 'put';

export interface UrlParams {
  [x: string]: string | number | ReadonlyArray<string> | ReadonlyArray<number> | boolean | null | undefined;
}

export interface RequestOptions {
  skipUnauthorized?: boolean;
  urlParams?: UrlParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>;
  ignoreNotFound?: boolean;
}

export interface CallAction<OPTIONS = RequestOptions> {
  type?: never; //this is filled by the middleware
  [CALL_API]: {
    _debounceSimultaneousCalls?: boolean;
    endpoint: string;
    headers?: Record<string, string>;
    method?: NETWORK_METHOD;
    schema?: Schema;
    options?: OPTIONS;
    throwNetworkError?: boolean;
    types: {
      requestType: string;
      successType: string;
      failureType: string;
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export interface ServerRequestAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Options extends Record<string, any> = {
    urlParams?: UrlParams;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: Record<string, any>;
  },
> {
  type: string;
  endpoint: string;
  method: NETWORK_METHOD;
  requestTime: number;
  options: Options;
  networkType: NetworkType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export type NetworkType = typeof NETWORK_REQUEST | typeof NETWORK_SUCCESS | typeof NETWORK_FAILURE;

export interface ServerResponseAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Response extends Record<string, any> = {
    entities?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [s: string]: Record<string, any>;
    };
    result?: {
      [s: string]: Array<string | number>;
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Options extends Record<string, any> = {
    urlParams?: UrlParams;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: Record<string, any>;
  },
> {
  type: string;
  endpoint: string;
  method: NETWORK_METHOD;
  requestTime: number;
  options?: Options;
  response?: Response;
  responseStatus?: number;
  networkType: NetworkType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}
