import {useElementClientRect} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import classNames from 'classnames';
import {ReactElement, ReactNode, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Label} from '../../../EntityInterfaces/Labels';
import {labelEntitiesSelector, LabelScope} from '../../../Modules/Selectors/LabelSelectors';
import AddFlowLabels from './AddFlowLabel';
import FlowLabel from './FlowLabel';

interface Props {
  asRow?: boolean;
  canHaveMultipleXOrLabels?: boolean;
  children?: ReactNode;
  className?: string;
  excludeLabels?: Uuid[];
  labels: Uuid[];
  labelScope?: never;
  limitedInputWidth?: boolean;
  noMargin?: boolean;
  noWrap?: boolean;
  placeholder?: string;
  projectFolder?: never;
  readonly?: never;
  searchResultUpwards?: boolean;
  setLabels?: never;
  showDots?: boolean;
  small?: boolean;
}

interface PropsWriteable {
  asRow?: boolean;
  canHaveMultipleXOrLabels?: boolean;
  children?: ReactNode;
  className?: string;
  excludeLabels?: Uuid[];
  labels: ReadonlyArray<Uuid>;
  labelScope: LabelScope | LabelScope[];
  limitedInputWidth?: boolean;
  noMargin?: boolean;
  noWrap?: boolean;
  placeholder?: string;
  projectFolder: Uuid | null;
  readonly?: boolean;
  searchResultUpwards?: boolean;
  setLabels: (labels: Uuid[]) => void;
  showDots?: never;
  small?: boolean;
}

export default function FlowLabels({
  asRow = false,
  canHaveMultipleXOrLabels = false,
  children,
  className,
  excludeLabels,
  labels,
  labelScope,
  limitedInputWidth,
  noMargin,
  noWrap,
  placeholder,
  projectFolder,
  readonly,
  searchResultUpwards,
  setLabels,
  showDots,
  small,
}: PropsWriteable | Props): ReactElement | null {
  const labelEntities = useSelector(labelEntitiesSelector);
  const assignedLabels = useMemo(
    () =>
      boolFilter(
        labels.filter((id) => !excludeLabels || !excludeLabels.includes(id)).map((labelId) => labelEntities[labelId]),
      ).sort((a, b) => a.name.localeCompare(b.name)),
    [labelEntities, labels, excludeLabels],
  );
  const labelScopes = useMemo<LabelScope[]>(
    () => (labelScope ? (Array.isArray(labelScope) ? labelScope : [labelScope]) : []),
    [labelScope],
  );
  const ref = useRef<HTMLDivElement>(null);
  const rect = useElementClientRect(ref, 0, [assignedLabels.length]);
  const dots = useMemo(() => !!(showDots && (rect?.height || 0) > 19), [showDots, rect]);

  function createOnRemove(label: Label): (() => void) | undefined {
    return !readonly && setLabels ? () => setLabels(labels.filter((id) => id !== label.id)) : undefined;
  }

  return (
    <div className={classNames('container', {asRow})}>
      {(assignedLabels.length > 0 || children) && (
        <div className={classNames('labelGrid', {hasAssignedLabels: assignedLabels.length > 0, noMargin, noWrap})}>
          {assignedLabels.map((label) => (
            <FlowLabel dotOnly={dots} small={small} label={label} key={label.id} onRemove={createOnRemove(label)} />
          ))}
          {children}
        </div>
      )}
      {showDots && (
        <div
          className={classNames('labelGrid hidden', {hasAssignedLabels: assignedLabels.length > 0, noMargin})}
          ref={ref}
        >
          {assignedLabels.map((label) => (
            <FlowLabel small={small} label={label} key={label.id} onRemove={createOnRemove(label)} />
          ))}
          {children}
        </div>
      )}

      {setLabels && !readonly && labelScopes.length > 0 && typeof projectFolder !== 'undefined' && (
        <AddFlowLabels
          className={className}
          placeholder={placeholder}
          assignedIds={labels}
          onAdd={(labelId, labelsToRemove) => {
            const labels = assignedLabels.map(({id}) => id);
            if (canHaveMultipleXOrLabels) {
              setLabels([...labels, labelId]);
            } else {
              setLabels([...labels.filter((id) => !labelsToRemove.includes(id)), labelId]);
            }
          }}
          labelScopes={labelScopes}
          projectFolder={projectFolder}
          limitedWidth={limitedInputWidth}
          upwards={searchResultUpwards}
        />
      )}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .container {
          width: auto;
          position: relative;
        }

        .container.asRow {
          display: flex;
          column-gap: 8px;
          flex-direction: row-reverse;
          align-items: center;
          max-width: 600px;
        }

        .labelGrid {
          display: flex;
          grid-template-columns: repeat(auto-fit, minmax(20px, max-content));
          grid-gap: 5px;
          align-items: center;
          width: 100%;
          flex-wrap: wrap;
        }

        .noWrap {
          flex-wrap: nowrap;
        }

        .hasAssignedLabels:not(.noMargin):not(.noWrap) {
          margin-bottom: 10px;
        }

        .link {
          cursor: pointer;
        }

        .hidden {
          visibility: hidden;
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          right: 0;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
