import {PropsWithChildren, ReactElement, ReactNode} from 'react';

export interface TWTwoColProps {
  headerContent: ReactNode;
}

export function TWCol({children, headerContent}: PropsWithChildren<TWTwoColProps>): ReactElement {
  return (
    <div className={'flex h-fit max-h-filterPopup flex-col overflow-hidden'}>
      <div className={'flex h-12 flex-shrink-0 items-center border-b border-b-slate-300 bg-slate-100 px-3 py-2'}>
        {headerContent}
      </div>
      <div className={'max-h-full overflow-y-auto'}>{children}</div>
    </div>
  );
}
