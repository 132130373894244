import FolderSvg from '@fortawesome/fontawesome-pro/svgs/solid/folder.svg';
import {CircleIconButton} from '@octaved/ui';
import {ReactElement, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHasProjectFolderContext} from '../../../Drawer/ProjectFolder/ProjectFolderContext';
import {useIsUsingProjectFolders} from '../../../Modules/Hooks/ProjectFolders';
import {showProjectFolderSelector} from '../../../Modules/Selectors/UiSelectors';
import {showProjectFolder} from '../../../Modules/Ui';

export default function ProjectFolderButton(): ReactElement | null {
  const visible = useSelector(showProjectFolderSelector);
  const dispatch = useDispatch();
  const toggleProjectFolder = useCallback(() => {
    dispatch(showProjectFolder(!visible));
  }, [dispatch, visible]);
  const hasProjectFolderContext = useHasProjectFolderContext();
  const hasMoreThanOneProjectFolder = useIsUsingProjectFolders();

  if (!hasProjectFolderContext || !hasMoreThanOneProjectFolder) {
    return null;
  }

  return (
    <div onClick={toggleProjectFolder}>
      <CircleIconButton iconColor={'grey'}>
        <FolderSvg />
      </CircleIconButton>
    </div>
  );
}
