import CheckSvg from '@fortawesome/fontawesome-pro/svgs/solid/check.svg';
import ExclamationSvg from '@fortawesome/fontawesome-pro/svgs/solid/exclamation.svg';
import HourglassHalfSvg from '@fortawesome/fontawesome-pro/svgs/solid/hourglass-half.svg';
import PaperPlaneSvg from '@fortawesome/fontawesome-pro/svgs/solid/paper-plane.svg';
import QuestionSvg from '@fortawesome/fontawesome-pro/svgs/solid/question.svg';
import TimesSvg from '@fortawesome/fontawesome-pro/svgs/solid/times.svg';
import {BillingErpStatus} from '@octaved/env/src/dbalEnumTypes';
import {Billing} from '@octaved/flow/src/EntityInterfaces/Billing/Billings';
import MultiDropdown from '@octaved/quick-filters/src/Components/MultiDropdown';
import {BillingQuickFilterConfig} from '@octaved/quick-filters/src/Config';
import {Icon} from '@octaved/ui';
import {ReceiptText} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

const erpStatusTexts: Record<BillingErpStatus, string> = {
  [BillingErpStatus.none]: 'general:billing.erpStatus.none',
  [BillingErpStatus.processedSuccessful]: 'general:billing.erpStatus.processedSuccessful',
  [BillingErpStatus.processedWithError]: 'general:billing.erpStatus.processedWithError',
  [BillingErpStatus.processedWithWarning]: 'general:billing.erpStatus.processedWithWarning',
  [BillingErpStatus.processing]: 'general:billing.erpStatus.processing',
  [BillingErpStatus.transmittedFailed]: 'general:billing.erpStatus.transmittedFailed',
  [BillingErpStatus.transmittedSuccessful]: 'general:billing.erpStatus.transmittedSuccessful',
};

const erpStatusIcons: Record<BillingErpStatus, ReactElement> = {
  [BillingErpStatus.none]: (
    <Icon iconColor={'blue'}>
      <QuestionSvg />
    </Icon>
  ),
  [BillingErpStatus.processedSuccessful]: (
    <Icon iconColor={'green'}>
      <CheckSvg />
    </Icon>
  ),
  [BillingErpStatus.processedWithError]: (
    <Icon iconColor={'red'}>
      <TimesSvg />
    </Icon>
  ),
  [BillingErpStatus.processedWithWarning]: (
    <Icon iconColor={'blue'}>
      <ExclamationSvg />
    </Icon>
  ),
  [BillingErpStatus.processing]: (
    <Icon iconColor={'grey'}>
      <HourglassHalfSvg />
    </Icon>
  ),
  [BillingErpStatus.transmittedFailed]: (
    <Icon iconColor={'red'}>
      <TimesSvg />
    </Icon>
  ),
  [BillingErpStatus.transmittedSuccessful]: (
    <Icon iconColor={'blue'}>
      <PaperPlaneSvg />
    </Icon>
  ),
};

const erpStatusSorted: readonly BillingErpStatus[] = [
  BillingErpStatus.none,
  BillingErpStatus.transmittedFailed,
  BillingErpStatus.transmittedSuccessful,
  BillingErpStatus.processing,
  BillingErpStatus.processedWithWarning,
  BillingErpStatus.processedWithError,
  BillingErpStatus.processedSuccessful,
];

export function ErpStatusWithIcon({status}: {status: Billing['erpStatus']}): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'flex items-center'}>
      {erpStatusIcons[status]}
      <span>{t(erpStatusTexts[status])}</span>
    </div>
  );
}

const erpStatusDropdownOptions = erpStatusSorted.map((value) => {
  return {
    value,
    text: <ErpStatusWithIcon status={value} />,
  };
});

export const billingErpStatusQuickFilterConfig = {
  defaultValue: [],
  Filter: ({setValue, value, isActive}) => (
    <MultiDropdown
      actionTriggerIcon={ReceiptText}
      items={erpStatusDropdownOptions}
      label={'pages:projects.pages.billingsList.filters.erpStatusFilterPlaceholder'}
      setValue={setValue}
      value={value}
      isActive={isActive}
    />
  ),
  ident: 'erpStatus',
  toSearchQuery: (status) => (status.length ? {or: status.map((s) => ['erpStatus', s])} : null),
} as const satisfies BillingQuickFilterConfig<readonly BillingErpStatus[]>;
