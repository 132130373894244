import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router-dom';
import type {MenuWithinPageOption} from '../../Pages/PageFrame/MenuWithinPage';
import {dialogRouteObjects} from './Dialogs';

const paths = {
  account: 'account',
  advanced: 'advanced',
  security: 'security',
  workingTimeTracking: 'workingTimeTracking',
};

export type MySettingsPage = keyof typeof paths;

export function getMySettingsPagePagePath(page: MySettingsPage): string {
  return paths[page];
}

const Account = lazy(() => import('../../Pages/MySettings/Account'));
const Security = lazy(() => import('../../Pages/MySettings/Security'));
const Advanced = lazy(() => import('../../Pages/MySettings/Advanced'));
const WorkingTimeTracking = lazy(() => import('../../Pages/MySettings/WorkingTimeTracking'));

export const mySettingsRoutes: RouteObject[] = [
  {
    children: dialogRouteObjects,
    element: <Account />,
    path: `${paths.account}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <Security />,
    path: `${paths.security}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <WorkingTimeTracking />,
    path: `${paths.workingTimeTracking}/*`,
  },
  {
    children: dialogRouteObjects,
    element: <Advanced />,
    path: `${paths.advanced}/*`,
  },
  {
    element: <Navigate to={paths.account} replace />,
    path: '*',
  },
];

export const menuOptions: MenuWithinPageOption[] = [
  {
    name: 'pages:mySettings.account',
    path: paths.account,
  },
  {
    name: 'pages:mySettings.security',
    path: paths.security,
  },
  {
    access: (options) => {
      return options.settings.useTimeTracking && !options.userSettings.workingTimeTrackingDisabled;
    },
    name: 'pages:mySettings.workingTimeTracking',
    path: paths.workingTimeTracking,
  },
  {
    name: 'pages:mySettings.advanced',
    path: paths.advanced,
  },
];
