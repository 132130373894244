import {cn} from '@octaved/ui';
import {ReactElement, RefObject} from 'react';

interface CanvasRendererProps {
  canvasRef: RefObject<HTMLDivElement>;
  hScrollBarRef: RefObject<HTMLDivElement>;
  scrollbarSize: number;
  scrollHeight: number;
  scrollRef: RefObject<HTMLDivElement>;
  scrollWidth: number;
  vScrollBarRef: RefObject<HTMLDivElement>;
}

export default function CanvasRenderer({
  canvasRef,
  hScrollBarRef,
  scrollbarSize,
  scrollHeight,
  scrollRef,
  scrollWidth,
  vScrollBarRef,
}: CanvasRendererProps): ReactElement {
  return (
    <div className={cn('size-full bg-white')} ref={scrollRef}>
      <div ref={canvasRef} className={'size-full'}></div>
      <div
        tabIndex={-1}
        className={'absolute right-0 top-0 h-full overflow-y-scroll'}
        style={{width: scrollbarSize}}
        ref={vScrollBarRef}
      >
        <div className={'absolute w-px'} style={{height: scrollHeight}} />
      </div>
      <div
        tabIndex={-1}
        className={'absolute bottom-0 left-0 size-full overflow-scroll'}
        style={{height: scrollbarSize}}
        ref={hScrollBarRef}
      >
        <div className={'absolute h-px'} style={{width: scrollWidth}} />
      </div>
    </div>
  );
}
