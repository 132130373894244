import {getProjectControllingLastActivitiesPerWorkPackage} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {LastActivitiesPerWorkPackage} from '../../../EntityInterfaces/Projects/ProjectControlling/LastActivitiesPerWorkPackage';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_START,
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_SUCCESS,
} from '../../ActionTypes';
import {
  lastActivitiesPerWorkPackageSelector,
  lastActivitiesPerWorkPackageStatesSelector,
} from '../../Selectors/Projects/ProjectControlling/ActivitySelectors';
import {FlowState} from '../../State';
import {useMainProjectTreeUnfilteredWorkPackageIds} from '../ProjectTreeUnfiltered';

const reducerMap = new Map();
export const lastActivitiesPerWorkPackageReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
addMultiToReducerMap(
  stateReducerMap,
  [
    FLOW_CHANGE_PID_SUCCESS,
    'flow.NodeCreatedEvent', //board posts really
    'flow.NodeRestoredFromTrashEvent',
    'flow.NodePatchedEvent', //board posts really
    'flow.ProjectCustomerChangedEvent', //TODO: not sure if needed
    'flow.TaskCreatedEvent',
    'flow.TaskPatchedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  () => ({}),
);
export const lastActivitiesPerWorkPackageStateReducer = ReduceFromMap(stateReducerMap);

export const [, useLastActivitiesPerWorkPackage] = createUseEntityHook<FlowState, LastActivitiesPerWorkPackage>(
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_START,
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_PER_WP_SUCCESS,
  getProjectControllingLastActivitiesPerWorkPackage,
  lastActivitiesPerWorkPackageSelector,
  lastActivitiesPerWorkPackageStatesSelector,
  reducerMap,
  stateReducerMap,
);

/**
 * Loads the last activities for all work packages in the subtree so they are available for the filter
 */
export function useLoadLastActivitiesPerWorkPackageForTree(): ReturnType<typeof useLastActivitiesPerWorkPackage> {
  const workPackageIds = useMainProjectTreeUnfilteredWorkPackageIds();
  return useLastActivitiesPerWorkPackage(workPackageIds);
}
