import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import FlowLabel from '@octaved/flow/src/Components/Label/FlowLabel/FlowLabel';
import {Label} from '@octaved/flow/src/EntityInterfaces/Labels';
import {getScopeForNodeType, labelEntitiesSelector} from '@octaved/flow/src/Modules/Selectors/LabelSelectors';
import {LabelFilter} from '@octaved/quick-filters/src/LabelFilter';
import {Uuid} from '@octaved/typescript/src/lib';
import {ActionTrigger} from '@octaved/ui';
import {boolFilter} from '@octaved/utilities';
import {Tag} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Placeholder from '../../PlaceholderText';
import {FramedSectionOrTileProps} from './Type';

export interface NodeLabelsProps {
  labels: Uuid[];
  nodeId: Uuid;
  nodeType: EnumFlowNodeType;
  readonly?: boolean;
  setLabels: (ids: Uuid[]) => void;
}

export default function NodeLabels({
  frame: Frame,
  labels,
  nodeId,
  nodeType,
  readonly,
  setLabels,
}: NodeLabelsProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const labelEntities = useSelector(labelEntitiesSelector);
  const assignedLabelEntities = boolFilter<Label>(labels.map((label) => labelEntities[label]));
  const hasLabels = labels.length > 0;

  return (
    <Frame labelToken={'nodeDetails:field.labels.label'}>
      <div className={'flex flex-col gap-y-2'}>
        <div className={'flex flex-wrap gap-x-2 gap-y-1'}>
          {!hasLabels && <Placeholder text={'nodeDetails:field.labels.placeholder'} />}
          {assignedLabelEntities.map((label) => (
            <FlowLabel key={label.id} label={label} />
          ))}
        </div>
        {!readonly && (
          <LabelFilter
            actionTrigger={<ActionTrigger icon={Tag} labelValue={t('nodeDetails:field.labels.selectLabel')} />}
            clearSelectionLinkLabel={'nodeDetails:field.labels.clearSelection'}
            labels={labels}
            nodeId={nodeId}
            scope={getScopeForNodeType(nodeType)}
            setLabels={setLabels}
            upwards
          />
        )}
      </div>
    </Frame>
  );
}
