import {ReactElement, ReactNode} from 'react';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

export function TextLink({children, onClick}: Props): ReactElement {
  return (
    <a
      className={'cursor-pointer text-sky-700 underline transition-colors hover:text-sky-600 hover:underline'}
      onClick={onClick}
    >
      {children}
    </a>
  );
}
