import accounting from 'accounting';

/**
 * @return accounting
 * @deprecated import accounting directly
 */
export function getNumberFormatter(): accounting.Static {
  // accounting documentation: http://openexchangerates.github.io/accounting.js/#documentation
  return accounting;
}
