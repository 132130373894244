import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import ValidationInputField from '../Form/Input/ValidationInputField';
import {settingsSelector} from '../../Modules/Selectors/SettingSelectors';

interface Props {
  setPrice: (price: string) => void;
  price?: string | null;
  fieldName?: string;
  readonly?: boolean;
  'data-selenium-id'?: string;
}

export default function PidPrice({fieldName = 'price', price = '', setPrice, readonly, ...props}: Props): ReactElement {
  return (
    <ValidationInputField
      disabled={readonly}
      value={price || ''}
      label={useSelector(settingsSelector).currencySymbol}
      setValue={(val) => setPrice(val || '')}
      fieldName={fieldName}
      data-selenium-id={props['data-selenium-id']}
    />
  );
}
