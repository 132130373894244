import {currentOrgUserTypeSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {isGuestUserType} from '@octaved/users/src/UserType';
import {LucideIcon} from 'lucide-react';
import type {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Outlet, RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import {AccessibleItem} from '../AccessibleItem';
import AuthPageWrapper from '../Pages/AuthPageWrapper';
import RequireAuth from './RequireAuth';
import {advancedMenu, advancedRoutes} from './Routes/Advanced';
import {billingMenu, billingRoutes} from './Routes/Billing';
import {configRoutes, configureMenu} from './Routes/Configure';
import {controllingMenu, controllingRoutes} from './Routes/Controlling';
import {myOrgsRoutes} from './Routes/MyOrganizations';
import {nodeRoutes} from './Routes/Node';
import {organizeMenu, organizeRoutes} from './Routes/Organize';
import {planningMenu, planningRoutes} from './Routes/Planning';
import {publicRoutes} from './Routes/Public';
import {editProjectsRoute} from './Routes/Routes';
import {workingTimeMenu, workingTimeRoutes} from './Routes/WorkingTime';
import {myTasksRoute, workspaceMenu, workspaceRoutes} from './Routes/Workspace';

// noinspection FunctionNamingConventionJS
function InitialPage(): ReactElement {
  const type = useSelector(currentOrgUserTypeSelector);
  if (isGuestUserType(type)) {
    return <Navigate to={`${editProjectsRoute}`} replace />;
  }
  return <Navigate to={`${myTasksRoute}`} replace />;
}

export const routeObjects: RouteObject[] = [
  ...publicRoutes,
  {
    children: [
      {
        children: [
          ...advancedRoutes,
          ...billingRoutes,
          ...configRoutes,
          ...controllingRoutes,
          ...nodeRoutes,
          ...organizeRoutes,
          ...planningRoutes,
          ...workingTimeRoutes,
          ...workspaceRoutes,
        ],
        element: <AuthPageWrapper />,
      },

      ...myOrgsRoutes,
    ],
    element: (
      <RequireAuth>
        <Outlet />
      </RequireAuth>
    ),
  },
  {path: '/*', element: <InitialPage />},
];

//#region menu

export interface MenuItem extends AccessibleItem {
  className?: string;
  disabled?: boolean;
  nameToken: string;
  path: string;
  testId?: string;
}

export interface MenuRootItem extends AccessibleItem {
  className?: string;
  groupToken: string;
  icon: LucideIcon;
  id: string;
  items: MenuItem[];
  testId?: string;
}

export const menuStructure: MenuRootItem[] = [
  workspaceMenu,
  planningMenu,
  organizeMenu,
  billingMenu,
  controllingMenu,
  workingTimeMenu,
  advancedMenu,
  configureMenu,
];

//#endregion
