import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {getMinMaxFromPlanningDates} from '../../Calculations/DateCalculations';
import {GanttComparisonContext} from '../../Components/BaselineComparison/Comparison/GanttComparisonContext';
import {
  MinMaxPlanningDatesResultNotNullable,
  calculateMinMaxPlanningDatesInSubtreeSelector,
} from '../../Selectors/PlanningDateSelectors';
import {GanttCalendarView} from '../Gantt/Context/GanttCalendarView';
import {createStoreSubscription} from '../StoreSubscription';

export abstract class ProjectBasedCalendarView extends GanttCalendarView {
  init(ctx: GanttComparisonContext): void {
    super.init(ctx);
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => this.projectPlanningSelectedProjectsSelector(s),
        () => this.#calculateMinMaxDates(),
      ),
      createStoreSubscription(this.ctx.store, calculateMinMaxPlanningDatesInSubtreeSelector, () =>
        this.#calculateMinMaxDates(),
      ),
    );
  }

  #lastMinMaxResult: MinMaxPlanningDatesResultNotNullable | null = null;
  #calculateMinMaxDates(): void {
    const state = this.ctx.store.getState();
    const projectIds = this.projectPlanningSelectedProjectsSelector(state);
    const calculateMinMaxPlanningDates = calculateMinMaxPlanningDatesInSubtreeSelector(state);

    const minMaxList: MinMaxPlanningDatesResultNotNullable[] = [];
    for (const projectId of projectIds) {
      const minMax = calculateMinMaxPlanningDates(projectId);
      if (minMax.plannedStart) {
        minMaxList.push(minMax);
      }
    }

    const minMax = getMinMaxFromPlanningDates(minMaxList);

    if (
      minMax.plannedStart &&
      (this.#lastMinMaxResult?.plannedEnd !== minMax.plannedEnd ||
        minMax.plannedStart !== this.#lastMinMaxResult.plannedStart)
    ) {
      this.#lastMinMaxResult = minMax;
      const max = dayjs(minMax.plannedEnd).isBefore(dayjs()) ? dayjs() : dayjs(minMax.plannedEnd);
      this.updateDates(fromIsoDateTimeFormat(minMax.plannedStart), max);
    }
  }

  protected abstract projectPlanningSelectedProjectsSelector(state: FlowState): readonly Uuid[];
}
