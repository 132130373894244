import {ReactElement, useMemo, useState} from 'react';
import {DialogContent, DialogFrame, DialogTitle} from '../../Dialogs/DialogFrame';
import {useWebsiteLanguageFromContext} from '../../WebsiteLinks';
import DialogAndDrawerHeader from '../Layout/DialogAndDrawerHeader';
import PlayVideo from './PlayVideo';
import VideoTrigger from './VideoTrigger';

export type EmbeddedHelpVideo =
  | 'assignResponsibility'
  | 'balanceWorkloads'
  | 'billingAndTimeSheetsExport'
  | 'copyTimeTrackingEntry'
  | 'createKanbanBoard'
  | 'createTask'
  | 'createTaskSection'
  | 'customerProject'
  | 'internalProject'
  | 'kanbanBoards'
  | 'myCalendarProjectPlanning'
  | 'projectPlanning'
  | 'setFavorite'
  | 'teamPlanning'
  | 'trackTime';

export interface VideoHelpProps {
  videoHeadline?: string;
  videoIdentifier: EmbeddedHelpVideo;
  videoTrigger?: ReactElement;
}

const cdnBasePath = 'https://flowcdn.octaved.com/shared';

const cdnVideoMap: Record<string, Record<EmbeddedHelpVideo, string>> = {
  de: {
    assignResponsibility: '/FirstStepsTutorialsShortVideos/zustaendige-zu-arbeitspaket-zuweisen-de.mp4',
    balanceWorkloads: '/FirstStepsTutorialsShortVideos/auslastung-in-team-planung-ausbalancieren-de.mp4',
    billingAndTimeSheetsExport: '/FirstStepsTutorialsShortVideos/abrechnen-und-time-sheets-exportieren-de.mp4',
    copyTimeTrackingEntry: '/FirstStepsTutorialsShortVideos/zeitbuchung-kopieren-de.mp4',
    createKanbanBoard: '/FirstStepsTutorialsShortVideos/labels-und-kanban-boards-erstellen-de.mp4',
    createTask: '/FirstStepsTutorialsShortVideos/aufgaben-erstellen-de.mp4',
    createTaskSection: '/FirstStepsTutorialsShortVideos/abschnitt-hinzufuegen-de.mp4',
    customerProject: '/FirstStepsTutorialsShortVideos/projekte-und-arbeitspakete-erstellen-kundenprojekt-de.mp4',
    internalProject: '/FirstStepsTutorialsShortVideos/projekte-und-arbeitspakete-erstellen-intern-de.mp4',
    kanbanBoards: '/FirstStepsTutorialsShortVideos/kanban-boards-im-workspace-de.mp4',
    myCalendarProjectPlanning: '/FirstStepsTutorialsShortVideos/projektplanung-und-mein-kalender-de.mp4',
    projectPlanning: '/FirstStepsTutorialsShortVideos/projekte-planen-de.mp4',
    setFavorite: '/FirstStepsTutorialsShortVideos/arbeitspakete-zu-favoriten-machen-de.mp4',
    teamPlanning: '/FirstStepsTutorialsShortVideos/team-planung-anzeigen-de.mp4',
    trackTime: '/FirstStepsTutorialsShortVideos/zeiterfassung-de.mp4',
  },
  en: {
    assignResponsibility: '/FirstStepsTutorialsShortVideos/zustaendige-zu-arbeitspaket-zuweisen-de.mp4',
    balanceWorkloads: '/FirstStepsTutorialsShortVideos/auslastung-in-team-planung-ausbalancieren-de.mp4',
    billingAndTimeSheetsExport: '/FirstStepsTutorialsShortVideos/abrechnen-und-time-sheets-exportieren-de.mp4',
    copyTimeTrackingEntry: '/FirstStepsTutorialsShortVideos/zeitbuchung-kopieren-de.mp4',
    createKanbanBoard: '/FirstStepsTutorialsShortVideos/labels-und-kanban-boards-erstellen-de.mp4',
    createTask: '/FirstStepsTutorialsShortVideos/aufgaben-erstellen-de.mp4',
    createTaskSection: '/FirstStepsTutorialsShortVideos/abschnitt-hinzufuegen-de.mp4',
    customerProject: '/FirstStepsTutorialsShortVideos/projekte-und-arbeitspakete-erstellen-kundenprojekt-de.mp4',
    internalProject: '/FirstStepsTutorialsShortVideos/projekte-und-arbeitspakete-erstellen-intern-de.mp4',
    kanbanBoards: '/FirstStepsTutorialsShortVideos/kanban-boards-im-workspace-de.mp4',
    myCalendarProjectPlanning: '/FirstStepsTutorialsShortVideos/projektplanung-und-mein-kalender-de.mp4',
    projectPlanning: '/FirstStepsTutorialsShortVideos/projekte-planen-de.mp4',
    setFavorite: '/FirstStepsTutorialsShortVideos/arbeitspakete-zu-favoriten-machen-de.mp4',
    teamPlanning: '/FirstStepsTutorialsShortVideos/team-planung-anzeigen-de.mp4',
    trackTime: '/FirstStepsTutorialsShortVideos/zeiterfassung-de.mp4',
  },
};

export default function PopupVideo({
  videoHeadline = 'pageHelp:pages.videoLabel',
  videoIdentifier,
  videoTrigger = <VideoTrigger />,
}: VideoHelpProps): ReactElement {
  const languageCode = useWebsiteLanguageFromContext();
  const videoUrl = useMemo(() => {
    return `${cdnBasePath}${cdnVideoMap[languageCode][videoIdentifier]}`;
  }, [languageCode, videoIdentifier]);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <div onClick={() => setShowVideo(true)}>{videoTrigger}</div>

      {showVideo && (
        <DialogFrame size={'fullscreen'} open className={'videoHelpPopup'} noOwnForm>
          <DialogTitle onClose={() => setShowVideo(false)} invertedClose>
            <DialogAndDrawerHeader header={videoHeadline} inverted />
          </DialogTitle>
          <DialogContent>
            <PlayVideo videoUrl={videoUrl} videoIdentifier={videoIdentifier} />
          </DialogContent>
        </DialogFrame>
      )}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx global>{`
        .videoHelpPopup {
          height: calc(100% - 8rem);
          width: calc(100% - 8rem) !important;
          inset: 4rem !important;
          overflow: hidden;
          background-color: #41464d !important;
          color: #fff;
        }

        .videoHelpPopup .dialogHeader,
        .videoHelpPopup .dialogContent {
          background-color: #41464d !important;
          color: #fff !important;
        }

        .videoHelpPopup .dialogContent {
          width: 100%;
          height: 100%;
          max-height: calc(100% - 10vh);
          overflow: hidden;
        }

        .videoHelpPopup .dialogPage {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
