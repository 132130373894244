import StatusCircle from '@octaved/flow/src/Components/Status/StatusCircle';
import classNames from 'classnames';
import {FormEvent, ReactElement} from 'react';
import {Tooltip, TooltipProps} from '../../Feedback';

interface Props {
  isActive?: boolean;
  label: ReactElement;
  onToggle: (e: FormEvent<HTMLButtonElement>) => void;
  tooltipProps?: Partial<TooltipProps>;
  tooltipToken?: string;
  color?: 'brightGrey' | 'grey' | 'blue' | 'green' | 'orange' | 'red' | 'darkRed' | 'white' | 'darkBlue';
}

function ToggleButton({color = 'grey', isActive, label, onToggle, tooltipToken, tooltipProps}: Props): ReactElement {
  return (
    <Tooltip toolTipTranslation={tooltipToken || ''} disabled={!tooltipToken} {...tooltipProps}>
      <button className={classNames(`color-${color}`, {isActive})} onClick={onToggle}>
        <StatusCircle color={isActive ? 'white' : color} type={isActive ? 'donut' : 'circle'} />
        <span className={'label'}>{label}</span>

        {/*#region styles*/}
        {/*language=scss*/}
        <style jsx>{`
          button {
            -webkit-appearance: none;
            border: 1px solid var(--accent-color);
            color: var(--accent-text-color, var(--accent-color));
            padding: 6px 10px;
            display: flex;
            align-items: center;
            gap: 6px;
            background-color: #fff;
            box-shadow: none;
            outline: none;
            border-radius: 4px;
            cursor: pointer;

            &.isActive {
              background-color: var(--accent-color);
              --accent-text-color: #fff;
            }
          }

          .ring {
            height: 14px;
            width: 14px;
            border-radius: 7px;
            background-color: var(--accent-color);

            &.donutIcon {
              border: 2px solid var(--accent-color);
              background-color: #fff;
            }
          }

          .color-brightGrey {
            --accent-color: #aaaaaa;
            --accent-text-color: #808080;
          }

          .color-grey {
            --accent-color: #666666;
          }

          .color-blue {
            --accent-color: #6699cc;
            --accent-text-color: #538cc6;
          }

          .color-green {
            --accent-color: #3cb371;
            --accent-text-color: #2f8d59;
          }

          .color-orange {
            --accent-color: #ed9121;
            --accent-text-color: #cb7710;
          }

          .color-red {
            --accent-color: #fe4d58;
          }

          .color-darkRed {
            --accent-color: #cc0000;
          }
        `}</style>
        {/*#endregion*/}
      </button>
    </Tooltip>
  );
}

export {ToggleButton};
