import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import merge from 'lodash/merge';
import {createContext, ReactElement, ReactNode, useContext, useMemo} from 'react';
import {ProjectTreeContext, ProjectTreeNode, ProjectTreeOptions} from '../../Modules/Projects/ProjectTreeInterfaces';

const allNodeTypes = new Set<ProjectTreeNode['type']>([
  'projectsGrouping',
  EnumFlowNodeType.VALUE_PROJECT,
  EnumFlowNodeType.VALUE_GROUP,
  EnumFlowNodeType.VALUE_WORK_PACKAGE,
]);
const noNodeTypes = new Set<ProjectTreeNode['type']>();

const defaultContext: ProjectTreeContext = {
  compactView: false,
  greyedOutNodeIds: null,
  greyOutProjects: true,
  isInProjectTree: false,
  selectableNodeTypes: allNodeTypes,
};

const context = createContext<ProjectTreeContext>(defaultContext);

export function useProjectTreeContext(): ProjectTreeContext {
  return useContext(context);
}

export function useInProjectTreeContext(): boolean {
  return useContext(context).isInProjectTree;
}

// noinspection FunctionNamingConventionJS
export function ProjectTreeContextProvider({
  children,
  options: {
    canOpenPidInspector,
    compactView,
    focusWorkPackageFavorites,
    greyedOutNodeIds,
    selectableNodeTypes,
    showPidInspectorMenu,
  },
}: {
  children: ReactNode;
  options: ProjectTreeOptions;
}): ReactElement {
  const ctx = useMemo<ProjectTreeContext>(() => {
    return merge({}, defaultContext, {
      canOpenPidInspector,
      compactView,
      focusWorkPackageFavorites,
      greyedOutNodeIds,
      showPidInspectorMenu,
      isInProjectTree: true,
      selectableNodeTypes:
        typeof selectableNodeTypes === 'string'
          ? new Set([selectableNodeTypes])
          : selectableNodeTypes === null
            ? noNodeTypes
            : selectableNodeTypes,
    });
  }, [
    canOpenPidInspector,
    compactView,
    focusWorkPackageFavorites,
    greyedOutNodeIds,
    selectableNodeTypes,
    showPidInspectorMenu,
  ]);
  return <context.Provider value={ctx}>{children}</context.Provider>;
}
