import {NodeTimeControlStandard} from '@octaved/flow/src/EntityInterfaces/TimeControlledEntity';
import {TWAutosaveInput} from '@octaved/ui';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {PatchPartial} from '../NodeTimeControlSettings';

interface Props {
  timeControl: NodeTimeControlStandard;
  patch: PatchPartial;
  readonly: boolean;
}

export default function Year({timeControl, patch, readonly}: Props): ReactElement {
  const day = dayjs(timeControl.from);
  return (
    <TWAutosaveInput
      value={day.year().toString()}
      className={'max-w-[80px]'}
      onSave={(val) => {
        if (/^\d{4}$/.test(val)) {
          patch({
            from: toIsoFormat(day.startOf('year').year(+val)),
          });
        }
      }}
      disabled={readonly}
      maxLength={4}
    />
  );
}
