import {cva, type VariantProps} from 'class-variance-authority';
import {LucideIcon} from 'lucide-react';
import {PropsWithChildren, ReactNode, forwardRef} from 'react';
import {cn} from './Hooks';

export const messageVariants = cva('border rounded px-4 py-3', {
  defaultVariants: {
    colorScheme: 'slate',
    size: 'default',
    textSize: 'default',
  },
  variants: {
    colorScheme: {
      autoSaveWarning: 'bg-orange-50 text-orange-700 border-orange-400',
      error: 'bg-red-50 text-red-700 border-red-600',
      sky: 'bg-sky-50 text-sky-700 border-sky-600',
      slate: 'bg-slate-50 text-slate-600 border-slate-300',
      success: 'bg-emerald-50 text-emerald-700 border-emerald-600',
      warning: 'bg-yellow-50 text-yellow-600 border-yellow-300',
    },
    size: {
      default: 'px-4 py-3 gap-x-3',
      sm: 'px-2 py-1.5 gap-x-3',
    },
    textSize: {
      default: 'text-base',
      sm: 'text-sm',
    },
  },
});

const messageIconVariant = cva('h-5 w-5 flex-shrink-0');

interface MessageProps extends VariantProps<typeof messageVariants> {
  icon?: LucideIcon;
  className?: string;
}

export const Message = forwardRef<HTMLDivElement, PropsWithChildren<MessageProps>>(
  ({children, className, icon: Icon, ...variantProps}, ref) => {
    return (
      <div ref={ref} className={cn(Icon && 'flex items-center', messageVariants({className, ...variantProps}))}>
        {Icon && <Icon className={messageIconVariant()} strokeWidth={1.5} />}
        {children}
      </div>
    );
  },
);
Message.displayName = 'Message';

interface MessageIconListProps extends VariantProps<typeof messageVariants> {
  items: {icon: LucideIcon; content: ReactNode}[];
  className?: string;
}

export const MessageIconList = forwardRef<HTMLUListElement, MessageIconListProps>(
  ({className, items, ...variantProps}, ref) => {
    return (
      <ul ref={ref} className={cn('list-none', messageVariants({className, ...variantProps}))}>
        {items.map(({icon: Icon, content}, idx) => (
          <li className={'flex items-center gap-x-2'} key={idx}>
            <Icon className={messageIconVariant()} strokeWidth={1.5} /> {content}
          </li>
        ))}
      </ul>
    );
  },
);
MessageIconList.displayName = 'MessageIconList';

export type {MessageIconListProps, MessageProps};
