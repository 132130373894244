import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {GroupEntity} from '@octaved/users/src/EntityInterfaces/GroupEntity';
import {useUnitLists} from '@octaved/users/src/Hooks/UnitLists';
import {groupsSelector} from '@octaved/users/src/Selectors/GroupSelectors';
import {extendedUnitTypesForGroup} from '@octaved/users/src/UnitType';
import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IIntranetGroupInspectorContext extends BaseInspectorContext {
  selectedEntity: GroupEntity;
  selectedType: 'intranetGroup';
}

function isIntranetGroupInspectorContext(context: InspectorContext | null): context is IIntranetGroupInspectorContext {
  return context?.selectedType === 'intranetGroup';
}

export function useIntranetGroupInspectorContext(): IIntranetGroupInspectorContext {
  const context = useInspectorContext();
  if (!isIntranetGroupInspectorContext(context)) {
    throw new Error('is not intranet group inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IIntranetGroupInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const group = useSelector(groupsSelector)[baseParams!.selectedId];

  return useMemo<IIntranetGroupInspectorContext | null>(() => {
    if (baseParams && group) {
      return {
        ...baseParams,
        selectedEntity: group,
        selectedType: 'intranetGroup',
      };
    }
    return null;
  }, [baseParams, group]);
}

export default function IntranetGroupInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);
  const {isLoaded} = useUnitLists(extendedUnitTypesForGroup);

  if (!isLoaded) {
    return <AppLoader />;
  }

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
