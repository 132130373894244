import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {ComponentType} from 'react';
import {ViewTabs} from '../../Components/ViewTabs';
import {
  detailsRoute,
  generalRoute,
  overviewRoute,
  planningRoute,
  responsibleRoute,
  settingsRoute,
  specialPlanningRoute,
  timeAndMoneyRoute,
} from '../../LargeView/RoutePaths';
import {LargeViewPages} from '../../LargeView/Routes';
import General, {SubWorkPackageDetailsProps} from '../Views/SubWorkPackage/General';
import Planning from '../Views/SubWorkPackage/Planning';
import Responsibility from '../Views/SubWorkPackage/Responsibility';
import Settings from '../Views/SubWorkPackage/Settings';
import SpecialPlanning from '../Views/SubWorkPackage/SpecialPlanning';
import TimeAndMoney from '../Views/SubWorkPackage/TimeAndMoney';

export type SubWorkPackageInpsectorTabs =
  | Extract<LargeViewPages, 'general' | 'responsibility' | 'planning' | 'timeAndMoney' | 'settings'>
  | typeof specialPlanningRoute;

export const subWorkPackageInspectorTabs: ViewTabs<SubWorkPackageInpsectorTabs>[] = [
  {
    access: ({isSimulationActive}) => {
      return (
        (location.pathname.startsWith('/projectPlanning') || location.pathname.startsWith('/teamPlanning')) &&
        isSimulationActive
      );
    },
    path: specialPlanningRoute,
    token: 'nodeDetails:tab.specialPlanning',
    value: specialPlanningRoute,
  },
  {
    path: generalRoute,
    token: 'nodeDetails:tab.general',
    value: 'general',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PROJECT_ROLE_ASSIGNMENTS', anyNode),
    path: responsibleRoute,
    token: 'nodeDetails:tab.responsibility',
    value: 'responsibility',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_READ_PLANNING', anyNode),
    path: planningRoute,
    token: 'nodeDetails:tab.planning',
    value: 'planning',
  },
  {
    access: ({isGranted}) => isGranted('FLOW_NODE_SUB_WORK_PACKAGE_READ_EFFORT', anyNode),
    path: timeAndMoneyRoute,
    token: 'nodeDetails:tab.timeAndFinances',
    value: 'timeAndMoney',
  },
  {
    path: settingsRoute,
    token: 'nodeDetails:tab.settings',
    value: 'settings',
  },
];

export const subWorkpackageInspectorTabViews: Record<
  SubWorkPackageInpsectorTabs,
  ComponentType<SubWorkPackageDetailsProps>
> = {
  general: General,
  planning: Planning,
  responsibility: Responsibility,
  settings: Settings,
  specialPlanning: SpecialPlanning,
  timeAndMoney: TimeAndMoney,
};

const subWorkPackageInspectorTabsToLargeViews = {
  general: overviewRoute,
  planning: `${detailsRoute}/${planningRoute}`,
  responsibility: `${detailsRoute}/${responsibleRoute}`,
  settings: `${detailsRoute}/${settingsRoute}`,
  specialPlanning: `${detailsRoute}/${planningRoute}`,
  timeAndMoney: `${detailsRoute}/${timeAndMoneyRoute}`,
} as const;

type value = keyof typeof subWorkPackageInspectorTabsToLargeViews;
export type SubWorkPackageLargeViewPaths = (typeof subWorkPackageInspectorTabsToLargeViews)[value];

export function getLargeViewPageFromInspectorTab(tab: value): SubWorkPackageLargeViewPaths {
  return subWorkPackageInspectorTabsToLargeViews[tab];
}
