import {getRestorableNodeCount} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createFlatTimestampReducer, EntityState, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {RestorableNodeCounts} from '../../EntityInterfaces/RestoreFromTrash';
import {
  FLOW_LOAD_RESTORABLE_NODE_COUNTS_FAILURE,
  FLOW_LOAD_RESTORABLE_NODE_COUNTS_REQUEST,
  FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS,
} from '../ActionTypes';
import {NodeRestoredFromTrashEvent, NodesRemovedEvent} from '../Events';
import {restorableNodeCountsStateSelector} from '../Selectors/RestoreFromTrashSelectors';
import {FlowState} from '../State';

interface LoadAction {
  response: RestorableNodeCounts;
  type: typeof FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS;
}

const reducers = createReducerCollection<RestorableNodeCounts>({});
export const restorableNodeCountsReducer = reducers.reducer;
const stateReducers = createReducerCollection<EntityState>({});
export const restorableNodeCountsStateReducer = stateReducers.reducer;

reducers.add<LoadAction>(FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS, (_, {response}) => response);

stateReducers.add(FLOW_LOAD_RESTORABLE_NODE_COUNTS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducers.add(FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS, createFlatTimestampReducer(LOADED));

stateReducers.add<NodesRemovedEvent | NodeRestoredFromTrashEvent>(
  ['flow.NodesRemovedEvent', 'flow.NodeRestoredFromTrashEvent'],
  () => ({}),
);

function loadRestorableNodeCounts(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = restorableNodeCountsStateSelector(getState());
    if (!state || isOutdated(state)) {
      dispatch({
        [CALL_API]: {
          endpoint: getRestorableNodeCount,
          types: {
            failureType: FLOW_LOAD_RESTORABLE_NODE_COUNTS_FAILURE,
            requestType: FLOW_LOAD_RESTORABLE_NODE_COUNTS_REQUEST,
            successType: FLOW_LOAD_RESTORABLE_NODE_COUNTS_SUCCESS,
          },
        },
      });
    }
  };
}

export function useLoadRestorableNodeCounts(): void {
  useStoreEffect((d) => d(loadRestorableNodeCounts()), [], restorableNodeCountsStateSelector);
}
