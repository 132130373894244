import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export default function InheritanceColorLegend(): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'text-sm leading-5'}>
      <div className={'flex flex-col gap-y-3'}>
        <div className={'flex gap-x-2'}>
          <div className={'mt-1 h-3 w-3 flex-shrink-0 rounded bg-slate-800'} />
          <span>{t('roles:permissionRoles.showInheritance.legend.inherited')}</span>
        </div>
        <div className={'flex gap-x-2'}>
          <div className={'mt-1 h-3 w-3 flex-shrink-0 rounded bg-blue-700'} />
          <span>{t('roles:permissionRoles.showInheritance.legend.overriden')}</span>
        </div>
        <div className={'flex gap-x-2'}>
          <div className={'mt-1 h-3 w-3 flex-shrink-0 rounded bg-emerald-600'} />
          <span>{t('roles:permissionRoles.showInheritance.legend.added')}</span>
        </div>
        <div className={'flex gap-x-2'}>
          <div className={'mt-1 h-3 w-3 flex-shrink-0 rounded bg-red-600'} />
          <span>{t('roles:permissionRoles.showInheritance.legend.removed')}</span>
        </div>
      </div>
    </div>
  );
}
