import {
  myTasksShowConnectedCalendarDemoDataSelector,
  myTasksTimelineStartDateSelector,
} from '@octaved/flow/src/Modules/Selectors/UiPages/MyTasksSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {CalendarEvent} from '@octaved/integration/src/EntityInterfaces/CalendarEvents';
import {ConnectedService} from '@octaved/integration/src/EntityInterfaces/ConnectedService';
import {getDemoAzureCalendarService, getDemoCalendarEvents} from '@octaved/integration/src/Selectors/CalendarDemoData';
import {
  hasConnectedCalendarServicesSelector,
  myCalendarEnabledCalendarIdsSelector,
  myCalendarEnabledServicesSelector,
} from '@octaved/integration/src/Selectors/ConnectedServiceSelectors';
import {LaneNode} from '@octaved/planning/src/Calculations/CalculateLanes';
import {DateStr} from '@octaved/typescript';
import {fromIsoFormat, isoDateTimeToIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {boolFilter, mapCache, pushOnArrayMap} from '@octaved/utilities';
import {Dayjs} from 'dayjs';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';

// noinspection FunctionNamingConventionJS
function ev<R>(selector: (state: FlowState['integration']['calendarEvents']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.integration.calendarEvents);
}

export const eventsSelector = ev((state) => state.events);
export const searchResultSelector = ev((state) => state.searchResult);
export const searchStateSelector = ev((state) => state.searchState);

export enum EventLoadType {
  myCalendar = 'myCalendar',
}

export function getCalendarEventsStoreKey(type: EventLoadType, startIsoDate: string, endIsoDate: string): string {
  return `${type}:${startIsoDate}-${endIsoDate}`;
}

interface LoadPeriod {
  end: string;
  key: string;
  start: string;
}

function createPeriod(type: EventLoadType, date: Dayjs): LoadPeriod {
  const start = toIsoFormat(date);
  const end = toIsoFormat(date.endOf('week'));
  return {start, end, key: getCalendarEventsStoreKey(type, start, end)};
}

export const createTimeFrameLoadPeriods = mapCache(
  (timeFrame: 'day' | 'week', timeFrameStart: DateStr): LoadPeriod[] => {
    const start = fromIsoFormat(timeFrameStart);
    const startOfWeek = start.startOf('week');
    const startEnds = [createPeriod(EventLoadType.myCalendar, startOfWeek)];
    if (timeFrame === 'week') {
      //In week view we load the previous and next week, too:
      startEnds.push(createPeriod(EventLoadType.myCalendar, startOfWeek.subtract(1, 'week')));
      startEnds.push(createPeriod(EventLoadType.myCalendar, startOfWeek.add(1, 'week')));
    }
    return startEnds;
  },
);

export type CalendarEventLaneNode = CalendarEvent & LaneNode;

type ServiceGroupedEvents<E extends CalendarEvent> = Array<{
  events: E[];
  service: ConnectedService;
}>;

/**
 * Expands the event object to be compatible with `calculateLanes`
 */
function createEventLaneNode(event: CalendarEvent): CalendarEventLaneNode {
  return {
    ...event,
    plannedEnd: isoDateTimeToIsoFormat(event.end),
    plannedStart: isoDateTimeToIsoFormat(event.start),
  };
}

function mapToServices<E extends CalendarEvent>(
  enabledServices: ConnectedService[],
  events: E[],
): ServiceGroupedEvents<E> {
  const services = new Map<string, E[]>();
  events.forEach((event) => {
    pushOnArrayMap(services, event.connectedServiceId, event);
  });
  return enabledServices.reduce<ServiceGroupedEvents<E>>((acc, service) => {
    acc.push({service, events: services.get(service.id) || []});
    return acc;
  }, []);
}

const getEnabledEventsInTimeFrameSelector = createSelector(
  eventsSelector,
  searchResultSelector,
  myTasksTimelineStartDateSelector,
  myCalendarEnabledCalendarIdsSelector,
  (events, results, timeFrameStart, enabledCalendarIds): ((timeFrame: 'day' | 'week') => CalendarEvent[]) => {
    return memoize((timeFrame) => {
      const periods = createTimeFrameLoadPeriods(timeFrame, timeFrameStart);
      //Fetch all event ids (might be duplicates in there due to long running events):
      const eventIds = periods.reduce<Set<string>>((acc, {key}) => {
        (results[key] || []).forEach((eventId) => acc.add(eventId));
        return acc;
      }, new Set());
      //Map to events, filter and sort:
      const enabledEvents = boolFilter([...eventIds].map((id) => events[id])).filter(({calendarId}) =>
        enabledCalendarIds.has(calendarId),
      );
      enabledEvents.sort((a, b) => a.start.localeCompare(b.start));
      return enabledEvents;
    });
  },
);

export const myTasksCalendarDemoDatesAreShownSelector = createSelector(
  hasConnectedCalendarServicesSelector,
  myTasksShowConnectedCalendarDemoDataSelector,
  (hasConnectedServices, showDemoData) => {
    return !hasConnectedServices && showDemoData;
  },
);

const demoServiceEvents = createSelector(myTasksCalendarDemoDatesAreShownSelector, (calendarDemoDatesAreShown) => {
  if (calendarDemoDatesAreShown) {
    return {services: [getDemoAzureCalendarService()], events: getDemoCalendarEvents()};
  }
  return null;
});

export const myTasksTimelineEventsSelector = createSelector(
  getEnabledEventsInTimeFrameSelector,
  myCalendarEnabledServicesSelector,
  demoServiceEvents,
  (getEnabledEventsInTimeFrame, enabledServices, demoServiceEvents): ServiceGroupedEvents<CalendarEventLaneNode> => {
    const services = demoServiceEvents?.services || enabledServices;
    const events = demoServiceEvents?.events || getEnabledEventsInTimeFrame('week');
    return mapToServices(services, events.map(createEventLaneNode));
  },
);

export const myTasksListEventsSelector = createSelector(
  getEnabledEventsInTimeFrameSelector,
  todayIsoDateSelector,
  myCalendarEnabledServicesSelector,
  demoServiceEvents,
  (
    getEnabledEventsInTimeFrame,
    todayIsoDate,
    enabledServices,
    demoServiceEvents,
  ): ServiceGroupedEvents<CalendarEvent> => {
    const services = demoServiceEvents?.services || enabledServices;
    const events = demoServiceEvents?.events || getEnabledEventsInTimeFrame('day');
    return mapToServices(
      services,
      events.filter(({start, end}) => {
        const startIsoDate = start.split(' ')[0];
        const endIsoDate = end.split(' ')[0];
        return startIsoDate <= todayIsoDate && endIsoDate >= todayIsoDate;
      }),
    );
  },
);
