import {OrganizationAllowedIPEntity} from '@octaved/organization/src/EntityInterfaces/OrganizationAllowedIP';
import {loadOrganizationAllowedLoginIPs} from '@octaved/organization/src/Modules/OrganizationAllowedLoginIP';
import {getOrganizationAllowedLoginIPSelector} from '@octaved/organization/src/Selectors/OrganizationAllowedLoginIpSelectors';
import {loadedOrganizationAllowedLoginIPsSelector} from '@octaved/organization/src/Selectors/UiPageSelectors';
import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {PropsWithChildren, ReactElement, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface ISecurityInspectorContext extends BaseInspectorContext {
  selectedEntity: OrganizationAllowedIPEntity;
  selectedType: 'security';
}

function isSecurityInspectorContext(context: InspectorContext | null): context is ISecurityInspectorContext {
  return context?.selectedType === 'security';
}

export function useSecurityInspectorContext(): ISecurityInspectorContext {
  const context = useInspectorContext();
  if (!isSecurityInspectorContext(context)) {
    throw new Error('is not security inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): ISecurityInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector(getOrganizationAllowedLoginIPSelector)(baseParams!.selectedId);

  return useMemo<ISecurityInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'security',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function SecurityInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);
  const dispatch = useDispatch();
  const loadedOrganizationAllowedLoginIPs = useSelector(loadedOrganizationAllowedLoginIPsSelector);
  useEffect(() => {
    dispatch(loadOrganizationAllowedLoginIPs());
  }, [dispatch]);

  if (!loadedOrganizationAllowedLoginIPs) {
    return <AppLoader />;
  }

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
