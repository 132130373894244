import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {isCustomerBillableSelector} from '../../Modules/Selectors/CustomerSelectors';
import {FlowState} from '../../Modules/State';
import {getBillingTypeTranslatable} from '../../WorkPackage/BillingType';
import {BillingTypeComponentProps, billingTypeComponents} from './Components';

interface Props extends Omit<BillingTypeComponentProps, 'typeTranslationToken'> {
  customer: string;
}

export default function SingleBillingTypeProgress({billingType, customer, ...props}: Props): ReactElement {
  const isCustomerBillable = useSelector((s: FlowState) => isCustomerBillableSelector(s)(customer));
  const translationToken = getBillingTypeTranslatable(billingType, isCustomerBillable);
  const Component = billingTypeComponents[billingType];

  return <Component typeTranslationToken={translationToken} {...props} billingType={billingType} />;
}
