import {EntityState} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {splitUnitIds} from '@octaved/users/src/Modules/UnitLists';
import {areGroupsLoadedSelector, groupsSelector} from '@octaved/users/src/Selectors/GroupSelectors';
import {areOrgUserNamesLoadedSelector, userEntitiesSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {createSelector} from 'reselect';
import {AccessibleTimeTrackingMapped, AccessibleTimeTrackings} from '../../EntityInterfaces/AccessibleTimeTrackings';
import {FlowState} from '../State';

export const accessibleTimeTrackingsSelector = (state: FlowState): AccessibleTimeTrackings =>
  state.roles.accessibleTimeTrackings;
export const accessibleTimeTrackingsStatesStateSelector = (state: FlowState): EntityState =>
  state.roles.accessibleTimeTrackingsStates;

export const accessibleTimeTrackingAllUnitIdsSelector = createSelector(
  accessibleTimeTrackingsSelector,
  (
    entries,
  ): {
    groupIds: Uuid[];
    userIds: Uuid[];
  } => {
    const allGroupIds: Uuid[] = [];
    const allUserIds: Uuid[] = [];
    Object.entries(entries).forEach(([userId, units]) => {
      allUserIds.push(userId);
      if (units) {
        const {groupIds, userIds} = splitUnitIds(units);
        allGroupIds.push(...groupIds);
        allUserIds.push(...userIds);
      }
    });
    return {groupIds: [...new Set(allGroupIds)], userIds: [...new Set(allUserIds)]};
  },
);

export const accessibleTimeTrackingAllUnitIdsAreLoadingSelector = createSelector(
  accessibleTimeTrackingAllUnitIdsSelector,
  areGroupsLoadedSelector,
  areOrgUserNamesLoadedSelector,
  ({groupIds, userIds}, areGroupsLoaded, areUserDisplayNamesLoaded): boolean => {
    return !areUserDisplayNamesLoaded(userIds) || !areGroupsLoaded(groupIds);
  },
);

export const accessibleTimeTrackingsMappedSelector = createSelector(
  accessibleTimeTrackingsSelector,
  userEntitiesSelector,
  groupsSelector,
  (accessibleTimeTrackings, users, groups): AccessibleTimeTrackingMapped[] => {
    const result: AccessibleTimeTrackingMapped[] = [];
    Object.entries(accessibleTimeTrackings).forEach(([userId, units]) => {
      if (units) {
        const mappedUnits = units.map(({unitId, unitType}) => {
          return {
            unitId,
            unitType,
            unitName: (unitType === SimpleUnitType.user ? users[unitId]?.name : groups[unitId]?.name) || '',
          };
        });
        mappedUnits.sort((a, b) => a.unitName.localeCompare(b.unitName));
        result.push({
          userId,
          name: users[userId]?.name || '',
          units: mappedUnits,
        });
      }
    });
    result.sort((a, b) => a.name.localeCompare(b.name));
    return result;
  },
);
