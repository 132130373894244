import {TimeRecordPatch} from '../EntityInterfaces/TimeRecords';
import {Settings} from '../Modules/Settings';

export function calculateBillingTimes(
  settings: Pick<Settings, 'billingRoundingMinutes'>,
  record: TimeRecordPatch,
): void {
  if (record.workTimeStart !== undefined) {
    if (record.workTimeStart === null) {
      record.billingStart = null;
    } else {
      record.billingStart = roundTimestamp(settings, record.workTimeStart);
    }
  }
  if (record.workTimeEnd !== undefined) {
    if (record.workTimeEnd === null) {
      record.billingEnd = null;
    } else {
      record.billingEnd = roundTimestamp(settings, record.workTimeEnd, true);
    }
  }
}

export function roundTimestamp(
  settings: Pick<Settings, 'billingRoundingMinutes'>,
  timestamp: number,
  roundUp = false,
): number {
  const roundTime = settings.billingRoundingMinutes * 60;

  if (roundUp) {
    return Math.ceil(timestamp / roundTime) * roundTime;
  }

  return Math.floor(timestamp / roundTime) * roundTime;
}
