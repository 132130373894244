import {useCallback, useMemo, useRef} from 'react';
import {FilterState} from '../../EntityInterfaces/Filter/FilterState';
import {UseEditFilterState} from './FilterState';

export interface UseArrayFilter<T> {
  add: (item: T) => void;
  isActive: boolean;
  keys: ReadonlySet<string>;
  map: ReadonlyMap<string, T>;
  remove: (item: T) => void;
  set: (items: T[]) => void;
  toggle: (item: T) => void;
  toggleActive: () => void;
  values: ReadonlySet<T>;
}

type KeyGen<T> = (item: T) => string;
export type UseArrayFilterKeyGenArg<T> = T extends string ? undefined : KeyGen<T>;

export function isArrayFilter(filter: FilterState<unknown>): filter is FilterState<Array<unknown>> {
  return Array.isArray(filter.value);
}

export function useArrayFilter<T, Ident extends string>(
  useEdit: UseEditFilterState<T[], Ident>,
  ident: Ident,
  keyGenerator?: UseArrayFilterKeyGenArg<T>,
): UseArrayFilter<T> {
  const keyGeneratorRef = useRef<KeyGen<T>>((keyGenerator || ((item: T) => item as unknown as string)) as KeyGen<T>);
  const {isActive, setValue, toggleActive, value} = useEdit(ident);
  const map = useMemo<Map<string, T>>(
    () => new Map(value.map((item) => [keyGeneratorRef.current(item), item])),
    [value],
  );
  const mapRef = useRef(map);
  mapRef.current = map;
  const keys = useMemo<Set<string>>(() => new Set(map.keys()), [map]);
  const values = useMemo<Set<T>>(() => new Set(map.values()), [map]);

  const set = useCallback(
    (items: T[]) => {
      setValue(items);
      mapRef.current = new Map(items.map((item) => [keyGeneratorRef.current(item), item]));
    },
    [setValue],
  );

  const add = useCallback(
    (item: T) => {
      const newMap = new Map(mapRef.current);
      newMap.set(keyGeneratorRef.current(item), item);
      setValue([...newMap.values()]);
      mapRef.current = newMap;
    },
    [setValue],
  );

  const remove = useCallback(
    (item: T) => {
      const newMap = new Map(mapRef.current);
      newMap.delete(keyGeneratorRef.current(item));
      setValue([...newMap.values()]);
      mapRef.current = newMap;
    },
    [setValue],
  );

  const toggle = useCallback(
    (item: T) => {
      const newMap = new Map(mapRef.current);
      const key = keyGeneratorRef.current(item);
      if (newMap.has(key)) {
        newMap.delete(key);
      } else {
        newMap.set(key, item);
      }
      setValue([...newMap.values()]);
      mapRef.current = newMap;
    },
    [setValue],
  );

  return {
    add,
    isActive,
    keys,
    map,
    remove,
    set,
    toggle,
    toggleActive,
    values,
  };
}
