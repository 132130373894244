import HoursDisplay from '@octaved/flow/src/Components/Task/HoursDisplay';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  TWSelect,
  TWSelectContent,
  TWSelectItem,
  TWSelectSeparator,
  TWSelectTrigger,
  TWSelectValue,
  cn,
} from '@octaved/ui';
import {format, formatDynamicPrecision} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Placeholder from '../../../../PlaceholderText';
import {FramedSectionOrTileProps} from '../../../Common/Type';
import CustomTaskEstimation from './CustomTaskEstimation';

const predefinedTaskEffortOptions = [
  {
    i18nKey: 'general:date.minutes',
    i18nParams: {count: 15},
    value: 0.25,
  },
  {
    i18nKey: 'general:date.hours',
    i18nParams: {count: 1, countFormatted: 1},
    value: 1,
  },
  {
    i18nKey: 'general:date.hours',
    i18nParams: {count: 2, countFormatted: 2},
    value: 2,
  },
  {
    i18nKey: 'general:date.halfDay',
    i18nParams: {},
    value: 4,
  },
  {
    i18nKey: 'general:date.1dayCustomHours',
    i18nParams: {count: 8},
    value: 8,
  },
  {
    i18nKey: 'general:date.oneAndAHalfDay',
    i18nParams: {},
    value: 12,
  },
  {
    i18nKey: 'general:date.days',
    i18nParams: {count: 2, countFormatted: 2},
    value: 16,
  },
  {
    i18nKey: 'general:date.days',
    i18nParams: {count: 3, countFormatted: 3},
    value: 24,
  },
  {
    i18nKey: 'general:date.days',
    i18nParams: {count: 4, countFormatted: 4},
    value: 32,
  },
  {
    i18nKey: 'general:date.days',
    i18nParams: {count: 5, countFormatted: 5},
    value: 40,
  },
];

export function hasCustomTaskDurationHours(plannedTime?: number | null): boolean {
  return !!plannedTime && !predefinedTaskEffortOptions.find(({value}) => value === plannedTime);
}

export interface TaskEstimationProps {
  id: Uuid;
  readonly?: boolean;
  plannedTime: number | null;
  patch: (newPlannedTime: number | null) => void;
}

export default function TaskEstimation({
  frame: Frame,
  readonly,
  plannedTime,
  patch,
}: TaskEstimationProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const [showCustomHours, setShowCustomInput] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const customHours = hasCustomTaskDurationHours(plannedTime);
  const [open, setOpen] = useState(false);

  return (
    <Frame labelToken={'nodeDetails:field.taskEffort.label'}>
      {readonly && !plannedTime && <Placeholder text={t('nodeDetails:field.taskEffort.placeholder')} />}
      {readonly && plannedTime && <HoursDisplay hours={plannedTime} showMinutes />}
      {!readonly && (
        <div ref={ref}>
          {showCustomHours && (
            <CustomTaskEstimation
              contextRef={ref}
              open={showCustomHours}
              close={() => setShowCustomInput(false)}
              toggleToPresets={() => {
                setShowCustomInput(false);
                setOpen(true);
              }}
              customHours={plannedTime}
              setCustomHours={patch}
            />
          )}

          <TWSelect
            open={open}
            onOpenChange={(open) => {
              if (open && customHours) {
                setShowCustomInput(open);
              } else {
                setOpen(open);
              }
            }}
            value={customHours ? 'custom' : plannedTime ? '' + plannedTime : 'none'}
            onValueChange={(plannedTime) => {
              if (plannedTime === 'none') {
                patch(null);
              } else if (plannedTime === 'custom') {
                setShowCustomInput(true);
              } else {
                patch(plannedTime === '0.25' ? 0.25 : parseInt(plannedTime, 10));
              }
            }}
          >
            <TWSelectTrigger className={cn('w-1/2', plannedTime === null && 'italic text-slate-400')}>
              {customHours ? (
                <>
                  {t('general:xHours', {
                    count: plannedTime || 0,
                    hours:
                      plannedTime && plannedTime % 1 !== 0
                        ? formatDynamicPrecision(plannedTime)
                        : format(plannedTime, 0),
                  })}
                </>
              ) : (
                <TWSelectValue placeholder={t('nodeDetails:field.taskEffort.placeholder')} />
              )}
            </TWSelectTrigger>
            <TWSelectContent>
              <TWSelectItem value={'none'}>{t('nodeDetails:field.taskEffort.placeholder')}</TWSelectItem>
              <TWSelectSeparator />
              {predefinedTaskEffortOptions.map(({value, i18nKey, i18nParams}) => (
                <TWSelectItem key={value} value={'' + value}>
                  {t(i18nKey, i18nParams)}
                </TWSelectItem>
              ))}
              <TWSelectSeparator />
              <TWSelectItem value={'custom'}>{t('nodeDetails:field.taskEffort.customHours')}</TWSelectItem>
            </TWSelectContent>
          </TWSelect>
        </div>
      )}
    </Frame>
  );
}
