import Konva from 'konva';
import {Circle} from 'konva/lib/shapes/Circle';
import {Rect} from 'konva/lib/shapes/Rect';
import {createText} from '../CommonComponents/Text';
import {RootContainer} from '../RootContainer';
import {TodayButtons} from './TodayButtons';

export class Header extends RootContainer {
  #lineHeight = 0;

  #daysRoot = new Konva.Group();
  #todayCircle: Circle = new Konva.Circle({fill: '#aa18ff'});
  #todayRect: Rect = new Konva.Rect({fill: '#aa18ff'});
  #fontSize = 11;

  readonly staticOverlay = new Konva.Group();

  init(): void {
    this.#lineHeight = this.ctx.headerHeight / 2;
    this.#daysRoot.y(this.#lineHeight);
    this.#createSeperator();
    this.#createTodayButtons();
  }

  onCalenderDatesChanged(): void {
    this.#todayCircle.remove(); //remove cirlce from old parent as it should not get destroyed
    this.#todayRect.remove(); //remove cirlce from old parent as it should not get destroyed
    this.root.destroyChildren();

    this.#createMonths();
    this.#createWeeks();
    this.#createTodayCircle();
    this.#createDays();

    this.root.add(this.#daysRoot);
  }

  #createTodayCircle(): void {
    const diff = this.ctx.today.diff(this.ctx.calendarView.dateStart, 'd');
    const radius = this.#lineHeight * 0.5 - 1;

    if (this.ctx.columnWidth > 100) {
      this.#daysRoot.add(this.#todayCircle);
      this.#todayCircle.radius(radius);
      this.#todayCircle.x(this.ctx.columnWidth * diff + this.ctx.columnWidth * 0.5);
      this.#todayCircle.y(radius + 2);
    } else {
      this.#daysRoot.add(this.#todayRect);
      this.#todayRect.width(this.ctx.columnWidth);
      this.#todayRect.height(this.#lineHeight);
      this.#todayRect.x(this.ctx.columnWidth * diff);
    }
  }

  #createSeperator(): void {
    const line = new Konva.Line({
      offsetY: -0.5,
      points: [0, this.#lineHeight, this.ctx.canvasView.width, this.#lineHeight],
      stroke: '#ccc',
      strokeWidth: 1,
    });
    this.staticOverlay.add(line);
  }

  async #createTodayButtons(): Promise<void> {
    const button = new TodayButtons({ctx: this.ctx});
    this.staticOverlay.add(button.root);

    button.init(this.#todayCircle, this.#todayRect);

    this.disposables.push(() => button.dispose());
  }

  #createDays(): void {
    let width = this.ctx.columnWidth;
    let dateFormat = 'MMM DD';

    if (width > 200) {
      dateFormat = 'dddd, DD.MM.';
    } else if (width > 60) {
      dateFormat = 'dd, DD.MM.';
    } else if (width > 10) {
      dateFormat = 'D';
    }

    if (width > 10) {
      for (let i = 0; i <= this.ctx.calendarView.days; ++i) {
        const current = this.ctx.calendarView.dateStart.add(i, 'd');
        const text = current.format(dateFormat);

        this.#daysRoot.add(
          createText({
            text,
            width,
            align: 'center',
            fill: current.isSame(this.ctx.today, 'd') ? '#fff' : '#555',
            fontSize: this.#fontSize,
            x: i * width,
            y: 2,
          }),
        );
      }
    } else {
      width *= 7;
      for (let i = 0; i <= this.ctx.calendarView.days; ++i) {
        const current = this.ctx.calendarView.dateStart.add(i, 'w');
        const text = current.format(dateFormat);

        this.#daysRoot.add(
          createText({
            text,
            width,
            fill: current.isSame(this.ctx.today, 'd') ? '#fff' : '#555',
            fontSize: this.#fontSize,
            x: i * width,
          }),
        );
      }
    }
  }

  #createWeeks(): void {
    const weekRoot = new Konva.Group();
    let current = this.ctx.calendarView.dateStart;
    const width = this.ctx.columnWidth * 7;
    const day = current.day() - 1;
    let x = width * 0.5;

    weekRoot.offsetX(day * this.ctx.columnWidth);
    while (!current.isAfter(this.ctx.calendarView.dateEnd)) {
      const text = createText({
        x,
        align: 'center',
        fontSize: this.#fontSize,
        text: `KW ${current.format('W')}`,
        y: 1,
      });
      text.offsetX(text.width() * 0.5);
      const rect = new Konva.Rect({
        fill: '#ffffff',
        height: text.height(),
        offsetX: text.offsetX(),
        width: text.width() + 10,
        x: text.x() - 5,
        y: text.y(),
      });

      weekRoot.add(rect);
      weekRoot.add(text);
      current = current.add(1, 'w');
      x += width;
    }

    this.root.add(weekRoot);
  }

  #createMonths(): void {
    const monthRoot = new Konva.Group();
    let current = this.ctx.calendarView.dateStart;
    const width = this.ctx.columnWidth;
    const date = current.date() - 1;
    let x = 0;

    monthRoot.offsetX(date * this.ctx.columnWidth);
    while (!current.isAfter(this.ctx.calendarView.dateEnd)) {
      const days = current.daysInMonth();
      monthRoot.add(
        createText({
          x,
          align: 'center',
          fontSize: this.#fontSize,
          text: current.format('MMM YYYY'),
          width: width * days,
          y: 1,
        }),
      );
      x += width * days;
      current = current.add(1, 'M');
    }

    this.root.add(monthRoot);
  }
}
