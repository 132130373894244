import {ReactElement, ReactNode} from 'react';
import {Loader} from 'semantic-ui-react';
import {useProjectTree} from '../../Modules/Projects/ProjectTree';
import {ProjectTreeOptions} from '../../Modules/Projects/ProjectTreeInterfaces';
import {SearchHighlightContext} from '../Search/SearchHighlight';
import DefaultPlaceholder from './DefaultPlaceholder';
import Flat from './Flat';
import Grouped from './Grouped';
import {ProjectTreeContextProvider} from './ProjectTreeContext';

interface Props {
  placeholder?: ReactNode;
  treeOptions: ProjectTreeOptions; //object itself does not have to be memo'ed, but all the top level keys should.
}

export default function ProjectTree({treeOptions, placeholder = <DefaultPlaceholder />}: Props): ReactElement {
  const {hasLoadedOnce, isLoading, flat, grouped} = useProjectTree(treeOptions);

  return (
    <div className={'projectTree'}>
      <Loader active={!hasLoadedOnce} />
      {!isLoading && flat.length === 0 && grouped.length === 0 && <>{placeholder}</>}

      <ProjectTreeContextProvider options={treeOptions}>
        <SearchHighlightContext searchTerm={treeOptions.searchTerm}>
          <Flat flat={flat} />
          <Grouped grouped={grouped} />
        </SearchHighlightContext>
      </ProjectTreeContextProvider>

      {/*language=SCSS*/}
      <style jsx>{`
        .relative {
          position: relative;
        }

        .projectTree {
          --color-bg-hover: rgba(0, 0, 0, 0.05);
          --color-bg-selected: #edf5fb;
          --color-bg-workpackage: #fff;

          --color-border-project: rgba(209, 215, 224, 0.5);

          --color-font-workpackage-name: #111;
          --color-font-workpackage-minorDetails: #888;

          --color-font-group-name: #707070;

          --color-font-project-minorDetails: #666;
          --color-font-project-name: #333;
        }
      `}</style>
    </div>
  );
}
