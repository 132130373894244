import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {isMaterialResource, isSubWorkPackage, isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getDueDateSelector} from '@octaved/flow/src/Modules/Selectors/PidSelectors';
import {getTaskWorkPackageSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {createSelector} from 'reselect';
import {isInDateRange} from '../Calculations/DateCalculations';
import {getMinMaxPlanningDatesSelector, getPlanningDateSelector} from './PlanningDateSelectors';

const getHasDeadlineWarningSelector = createSelector(
  getMinMaxPlanningDatesSelector,
  getDueDateSelector,
  (getMinMaxPlanningDates, getDueDate) => (nodeId: Uuid) => {
    const {plannedEnd} = getMinMaxPlanningDates(nodeId);
    const deadline = getDueDate(nodeId);
    const isoPlannedEnd = isoDateTimeToIsoFormat(plannedEnd);
    return Boolean(isoPlannedEnd && deadline && isoPlannedEnd > deadline);
  },
);

export const getHasWarningSelector = createSelector(
  todayIsoDateSelector,
  getNodeSelector,
  getTaskWorkPackageSelector,
  getMinMaxPlanningDatesSelector,
  getNodeAncestrySelector,
  getHasDeadlineWarningSelector,
  getPlanningDateSelector,
  (today, getNode, getTasksPid, getMinMaxPlanningDates, getNodeAncestry, getHasDeadlineWarning, getPlanningDate) =>
    (nodeId: Uuid, planningDateId: MaybeUuid) => {
      const node = getNode(nodeId);
      const {plannedEnd} = getMinMaxPlanningDates(nodeId);
      const isoPlannedEnd = isoDateTimeToIsoFormat(plannedEnd);
      const isInPast = Boolean(isoPlannedEnd && today > isoPlannedEnd);

      if (isTask(node)) {
        const pid = getTasksPid(node.id);
        const {plannedEnd: pidPlannedEnd} = getMinMaxPlanningDates(pid?.id);
        const endsAfterWp = Boolean(pidPlannedEnd && plannedEnd && pidPlannedEnd < plannedEnd);

        const status = node.status;
        const isInPastWarning = isInPast && status === EnumFlowTaskStatus.VALUE_OPEN;
        return isInPastWarning || getHasDeadlineWarning(nodeId) || endsAfterWp;
      }
      if (isWorkPackage(node)) {
        const isInPastWarning = isInPast && !node.isCompleted;
        return isInPastWarning || getHasDeadlineWarning(nodeId);
      }
      if (isSubWorkPackage(node)) {
        const wp = getNodeAncestry(node.id).workPackage;
        const {plannedEnd: pidPlannedEnd} = getMinMaxPlanningDates(wp?.id);
        const endsAfterWp = Boolean(pidPlannedEnd && plannedEnd && pidPlannedEnd < plannedEnd);
        const isInPastWarning = isInPast && !node.isCompleted;
        return isInPastWarning || getHasDeadlineWarning(nodeId) || endsAfterWp;
      }
      if (isMaterialResource(node)) {
        const planningDate = getPlanningDate(planningDateId);
        if (planningDate) {
          const {plannedEnd: wpPlannedEnd, plannedStart: wpPlannedStart} = getMinMaxPlanningDates(
            planningDate.assignedNodeId,
          );
          return isInDateRange(wpPlannedStart, wpPlannedEnd, planningDate.plannedStart, planningDate.plannedEnd);
        }
      }
      return false;
    },
);
