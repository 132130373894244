import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {getPidSelector, getProjectForPidSelector} from '../../../../Modules/Selectors/PidSelectors';

export function useCheckPlanningDependencyIssues(movedPidId: Uuid): (droppedPidId: Uuid | null) => boolean {
  const getProjectForPid = useSelector(getProjectForPidSelector);
  const getPid = useSelector(getPidSelector);
  return function checkPriceCategories(droppedParentPidId: Uuid | null): boolean {
    if (droppedParentPidId && getProjectForPid(movedPidId) !== getProjectForPid(droppedParentPidId)) {
      const pid = getPid(movedPidId);
      if (pid) {
        return pid.planningSuccessors.length > 0 || pid.planningPredecessors.length > 0;
      }
    }
    return false;
  };
}
