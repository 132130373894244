import classNames from 'classnames';
import {PropsWithChildren, ReactElement, useContext} from 'react';
import {pageLockContext} from './PageLockContext';

export default function PageLockWrapper({children}: PropsWithChildren<unknown>): ReactElement {
  const {isLocked} = useContext(pageLockContext);

  return (
    <div className={classNames('pageLock', {isLocked})}>
      {children}
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .pageLock {
          height: 100%;
          width: 100%;
          transition: filter 350ms ease-in-out;

          &:after {
            background-color: rgba(0, 0, 0, 0);
            transition: background-color 350ms ease-in-out;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            content: ' ';
            pointer-events: none;
            z-index: 3000;
          }

          &.isLocked {
            filter: grayscale(80%);

            &:after {
              background-color: rgba(0, 0, 0, 0.1);
              pointer-events: all;
            }
          }
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
