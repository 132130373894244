import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {ReactElement, useContext, useRef} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {usePid} from '../../../../Modules/Hooks/Nodes';
import {isGroup, isWorkPackage} from '../../../../Node/NodeIdentifiers';
import {getParentIdSelector} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {projectsMovePidSelector} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {flowStyleContext} from '../../../../Styles/StyleContext';
import {getSelfWithChilds, useProjectDrag} from '../Components/ProjectSortable';
import NodeTile from '../NodeTile';
import {moveGroupOrWorkPackageContext} from './MoveGroupOrWorkPackageContext';

const infoTranslationToken = {
  [EnumFlowNodeType.VALUE_GROUP]: 'pages:projects.drawer.movePid.infoGroup',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'pages:projects.drawer.movePid.infoWorkpackage',
};

export default function DraggablePid(): ReactElement | null {
  const {
    COLORS: {BACKGROUND, FONT, LEVITATION},
  } = useContext(flowStyleContext);
  const pidId = useSelector(projectsMovePidSelector)!;
  const pid = usePid(pidId)!;
  const parentId = useSelector(getParentIdSelector)(pidId)!;
  const idsToIgnore = useSelector(getSelfWithChilds)(pidId);
  const ref = useRef<HTMLDivElement | null>(null);
  const moveCtx = useContext(moveGroupOrWorkPackageContext);
  useProjectDrag(ref, pid, () => moveCtx.getCurrentIndex?.(parentId, pidId) || -1, parentId, idsToIgnore);

  if (isGroup(pid) || isWorkPackage(pid)) {
    return (
      <div className={'draggableContainer'}>
        <div ref={ref} className={'draggable'}>
          <NodeTile node={pid} draggable />
        </div>
        <div className={'info'}>
          <span>
            <Trans i18nKey={infoTranslationToken[pid.nodeType]} />
          </span>
        </div>

        {/*language=SCSS*/}
        <style jsx>{`
          .draggableContainer {
            position: fixed;
            top: 127px;
            right: 0;
            background-color: ${BACKGROUND.STANDARD};
            padding: 10px 15px;
            box-shadow: -2px 5px 15px ${LEVITATION.LEVEL_2};
            z-index: 10;
            max-width: 400px;
          }

          .info {
            color: ${FONT.LIGHT};
            text-align: center;
            margin-top: 10px;
          }

          .draggable {
            cursor: grab;
          }
        `}</style>
      </div>
    );
  }

  return null;
}
