import {
  FLOW_LOAD_GUEST_ROLE_RIGHTS_FAILURE,
  FLOW_LOAD_GUEST_ROLE_RIGHTS_REQUEST,
  FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS,
  FLOW_PATCH_GUEST_ROLE_RIGHTS_FAILURE,
  FLOW_PATCH_GUEST_ROLE_RIGHTS_REQUEST,
  FLOW_PATCH_GUEST_ROLE_RIGHTS_SUCCESS,
} from '@octaved/flow/src/Modules/ActionTypes';
import {GuestRoleRightMatrixChangedEvent} from '@octaved/flow/src/Modules/Events';
import {
  guestRoleRightMatrixSelector,
  guestRoleRightMatrixStateSelector,
} from '@octaved/flow/src/Modules/Selectors/RoleRightMatrix/RoleRightMatrixSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createFlatTimestampReducer, EntityState, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import * as routes from '../../../config/routes';
import {GuestRoleRights} from '../../EntityInterfaces/RoleRights';

interface LoadSuccessAction {
  response: GuestRoleRights;
  type: typeof FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS;
}

interface PatchAction {
  roleId: Uuid;
  rights: string[];
  type: typeof FLOW_PATCH_GUEST_ROLE_RIGHTS_REQUEST;
}

const reducers = createReducerCollection<GuestRoleRights>({});
reducers.add(FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS, (_, action: LoadSuccessAction) => action.response);
reducers.add<GuestRoleRightMatrixChangedEvent | PatchAction>(
  [FLOW_PATCH_GUEST_ROLE_RIGHTS_REQUEST, 'flow.GuestRoleRightMatrixChangedEvent'],
  (state, action) => {
    return {...state, [action.roleId]: action.rights};
  },
);
export const guestRoleRightsReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityState>({});
stateReducers.add(FLOW_LOAD_GUEST_ROLE_RIGHTS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducers.add(FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS, createFlatTimestampReducer(LOADED));
export const guestRoleRightsStateReducer = stateReducers.reducer;

function getGuestRoleRights(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = guestRoleRightMatrixStateSelector(getState());
    if (isOutdated(state)) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.getGuestRoleRightMatrix,
          types: {
            failureType: FLOW_LOAD_GUEST_ROLE_RIGHTS_FAILURE,
            requestType: FLOW_LOAD_GUEST_ROLE_RIGHTS_REQUEST,
            successType: FLOW_LOAD_GUEST_ROLE_RIGHTS_SUCCESS,
          },
        },
      });
    }
  };
}

export function patchGuestRoleRights(roleId: Uuid, rights: string[]): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const current = guestRoleRightMatrixSelector(getState())[roleId] || []; //store is always sorted
    const sorted = rights.toSorted();
    if (JSON.stringify(sorted) !== JSON.stringify(current)) {
      await dispatch({
        roleId,
        [CALL_API]: {
          endpoint: routes.patchGuestRoleRightMatrix,
          method: 'patch',
          options: {data: {rights: sorted}, urlParams: {roleId}},
          types: {
            failureType: FLOW_PATCH_GUEST_ROLE_RIGHTS_FAILURE,
            requestType: FLOW_PATCH_GUEST_ROLE_RIGHTS_REQUEST,
            successType: FLOW_PATCH_GUEST_ROLE_RIGHTS_SUCCESS,
          },
        },
        rights: sorted,
      });
    }
  };
}

export function useLoadGuestRoleRights(): void {
  useStoreEffect((dispatch) => dispatch(getGuestRoleRights()), [], guestRoleRightMatrixStateSelector);
}
