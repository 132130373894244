import {getClientXY} from '@octaved/flow/src/Hooks/MouseMove';
import Konva from 'konva';
import {Disposable} from './Disposable';

type MouseTouchEvent = MouseEvent | TouchEvent;

export interface MouseMoveEvent {
  movementX: number;
  movementY: number;
  startX: number;
  startY: number;
  mouseX: number;
  mouseY: number;
  evt: MouseTouchEvent;
}

interface MouseMoveProps {
  onStart: (event: MouseMoveEvent) => void;
  onMove: (event: MouseMoveEvent) => void;
  onEnd: (event: MouseMoveEvent) => void;
  eventNode: Konva.Node;
}

export class MouseMove extends Disposable {
  readonly #onStart: (event: MouseMoveEvent) => void;
  readonly #onMove: (event: MouseMoveEvent) => void;
  readonly #onEnd: (event: MouseMoveEvent) => void;
  readonly #eventNode: Konva.Node;
  #stage: Konva.Stage | null = null;
  #isDisabled = false;

  #startX = 0;
  #startY = 0;
  #currentX = 0;
  #currentY = 0;
  #scaleX = 0;
  #scaleY = 0;
  #rootTop = 0;
  #rootLeft = 0;

  constructor({onEnd, onMove, onStart, eventNode}: MouseMoveProps) {
    super();
    this.#onStart = onStart;
    this.#onMove = onMove;
    this.#onEnd = onEnd;
    this.#eventNode = eventNode;
  }

  init(): void {
    this.#eventNode.on('mousedown touchstart', this.#start);

    this.disposables.push(
      () => this.#removeDocumentEvents(),
      () => this.#eventNode.off('mousedown touchstart', this.#start),
    );
  }

  #createBaseEvent(
    event: Omit<MouseMoveEvent, 'mouseX' | 'mouseY' | 'startX' | 'startY' | 'movementX' | 'movementY'>,
  ): MouseMoveEvent {
    return {
      ...event,
      mouseX: this.#currentX,
      mouseY: this.#currentY,
      movementX: this.#currentX - this.#startX,
      movementY: this.#currentY - this.#startY,
      startX: this.#startX,
      startY: this.#startY,
    };
  }

  #start = (e: Konva.KonvaEventObject<MouseTouchEvent>): void => {
    if (this.#isDisabled) {
      return;
    }
    const content = this.#getStage().content;
    const rect = content.getBoundingClientRect();
    this.#scaleX = rect.width / content.clientWidth || 1;
    this.#scaleY = rect.height / content.clientHeight || 1;
    this.#rootTop = rect.top;
    this.#rootLeft = rect.left;

    const {x, y} = this.#getMousePosition(e.evt);
    this.#startX = x;
    this.#startY = y;
    this.#currentX = x;
    this.#currentY = y;

    document.addEventListener('mousemove', this.#move);
    document.addEventListener('touchmove', this.#move);
    document.addEventListener('mouseup', this.#end);
    document.addEventListener('touchend', this.#end);
    this.#onStart(this.#createBaseEvent({evt: e.evt}));
  };

  #move = (e: MouseTouchEvent): void => {
    const {x, y} = this.#getMousePosition(e);
    this.#currentX = x;
    this.#currentY = y;
    this.#onMove(this.#createBaseEvent({evt: e}));
  };

  #end = (e: MouseTouchEvent): void => {
    this.#removeDocumentEvents();
    const {x, y} = this.#getMousePosition(e);
    this.#currentX = x;
    this.#currentY = y;
    this.#onEnd(this.#createBaseEvent({evt: e}));
  };

  #removeDocumentEvents(): void {
    document.removeEventListener('mousemove', this.#move);
    document.removeEventListener('touchmove', this.#move);
    document.removeEventListener('mouseup', this.#end);
    document.removeEventListener('touchend', this.#end);
  }

  #getMousePosition(e: MouseTouchEvent): {x: number; y: number} {
    const [clientX, clientY] = getClientXY(e);
    const x = (clientX - this.#rootLeft) / this.#scaleX;
    const y = (clientY - this.#rootTop) / this.#scaleY;
    return {x, y};
  }

  #getStage(): Konva.Stage {
    const stage = this.#stage || this.#eventNode.getStage();
    if (!stage) {
      throw new Error('Stage not found');
    }
    this.#stage = stage;
    return stage;
  }

  cancel(): void {
    this.#removeDocumentEvents();
  }

  get isDisabled(): boolean {
    return this.#isDisabled;
  }

  enable(): void {
    this.#isDisabled = false;
  }

  disable(): void {
    this.#isDisabled = true;
    this.#removeDocumentEvents();
  }
}
