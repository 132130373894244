import {AuditUser} from '../../../../EntityInterfaces/Audit/AuditUser';
import BooleanCell from '../Table/Cells/BooleanCell';
import JsonCell from '../Table/Cells/JsonCell';
import type {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditUser> = {
  columns: [
    ...historyColumns,
    createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
    createColumnDef('name', 'systemSettings:audit.tables.user.nameHeader'),
    createColumnDef('isActive', 'systemSettings:audit.tables.user.isActiveHeader', BooleanCell),
    createColumnDef('data', 'systemSettings:audit.tables.user.dataHeader', JsonCell),
  ],
  tableIdent: 'users',
  tableNameTranslationToken: 'systemSettings:audit.userTableName',
};
export default config;
