import ConfirmDialog from '@octaved/flow/src/Components/Dialog/ConfirmDialog';
import {useFormattedDateText} from '@octaved/flow/src/Hooks/FormattedDateText';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Trash} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {removePlanningBaseline} from '../../../Modules/PlanningBaselines';
import {
  getPlanningBaselineDisplayTimestampSelector,
  getPlanningBaselineSelector,
} from '../../../Selectors/PlanningBaselineSelectors';

interface DeleteDialogProps {
  close: () => void;
  baselineId: Uuid;
  rootNodeId: Uuid;
}

export default function DeleteDialog({close, baselineId, rootNodeId}: DeleteDialogProps): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const baseline = useSelector(getPlanningBaselineSelector)(rootNodeId, baselineId);
  const timestamp = useSelector(getPlanningBaselineDisplayTimestampSelector)(rootNodeId, baselineId);
  const formatDate = useFormattedDateText();
  return (
    <ConfirmDialog
      title={'general:delete.header'}
      buttonLabel={'general:delete'}
      buttonIcon={<Trash />}
      buttonColorScheme={'delete'}
      submitCallback={() => {
        dispatch(removePlanningBaseline(baselineId));
        close();
      }}
      cancelCallback={close}
    >
      <div>
        {t('general:delete.text')}{' '}
        {t('pages:planning.baselineComparison.deleteText', {
          name: baseline?.name || formatDate(isoDateTimeToIsoFormat(timestamp ? timestamp : null)),
        })}
      </div>
      <div>{t('general:delete.question')}</div>
    </ConfirmDialog>
  );
}
