import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWLabel} from '@octaved/ui';
import {Pencil} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {nodeEntitySelector} from '../../Modules/Selectors/NodeSelectors';
import {useProjectFoldersSelector} from '../../Modules/Selectors/SettingSelectors';
import StringPath from '../Node/StringPath';
import ProjectFolderTree from './ProjectFolderTree';

interface Props {
  folderId: Uuid;
  setFolderId: (id: Uuid) => void;
}

export default function FolderForNewProject({folderId, setFolderId}: Props): ReactElement | null {
  const {t} = useTranslation();
  const isGranted = useSelector(isGrantedSelector);
  const nodes = useSelector(nodeEntitySelector);
  const [editFolder, setEditFolder] = useState(
    () => !(isGranted('FLOW_NODE_PID_MANAGE_BASIC', folderId) && nodes[folderId]?.isArchived === false),
  );
  const usesFolders = useSelector(useProjectFoldersSelector);

  if (!usesFolders) {
    return null;
  }

  return (
    <div className={'min-h-11' /*min-height so that the UI won't collapse before the tree loads when switching*/}>
      <TWLabel>{t('dialogs:importProjects.projectFolder.label')}</TWLabel>
      {editFolder ? (
        <ProjectFolderTree
          isSelectableNode={({id}) => isGranted('FLOW_NODE_PID_MANAGE_BASIC', id) && nodes[id]?.isArchived === false}
          selectedNode={folderId}
          selectNode={setFolderId}
        />
      ) : (
        <div className={'flex cursor-pointer items-center gap-x-2'} onClick={() => setEditFolder(true)}>
          <StringPath includeRootFolder nodeId={folderId} />
          <Pencil className={'size-3.5 text-slate-600'} />
        </div>
      )}
    </div>
  );
}
