import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M21 7.99999C20.9996 7.64927 20.9071 7.3048 20.7315 7.00116C20.556 6.69751 20.3037 6.44536 20 6.26999L13 2.26999C12.696 2.09446 12.3511 2.00204 12 2.00204C11.6489 2.00204 11.304 2.09446 11 2.26999L4 6.26999C3.69626 6.44536 3.44398 6.69751 3.26846 7.00116C3.09294 7.3048 3.00036 7.64927 3 7.99999V16C3.00036 16.3507 3.09294 16.6952 3.26846 16.9988C3.44398 17.3025 3.69626 17.5546 4 17.73L11 21.73C11.304 21.9055 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9055 13 21.73L20 17.73C20.3037 17.5546 20.556 17.3025 20.7315 16.9988C20.9071 16.6952 20.9996 16.3507 21 16V7.99999Z',
      key: '1',
      strokeWidth: '1.5',
    },
  ],
  [
    'path',
    {
      d: 'M3.29999 7L12 12L20.7 7',
      key: '2',
      strokeWidth: '1.5',
    },
  ],
  [
    'path',
    {
      d: 'M12 22V12',
      key: '3',
      strokeWidth: '1.5',
    },
  ],
];

export const ProjectIconSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const ProjectIcon = createLucideIcon('ProjectIcon', iconDrawing);
