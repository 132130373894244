import {Uuid} from '@octaved/typescript/src/lib';

export function getUnitColor(userIds: Uuid[], s = 40, l = 50): string {
  let hash = 0;
  const str = userIds.join();
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}
