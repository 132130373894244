import {Uuid} from '@octaved/typescript/src/lib';
import {lazy, useCallback} from 'react';
import {RouteObject} from 'react-router';
import {useLocation} from 'react-router-dom';
import DeletePid from '../../Dialogs/DeletePid/DeletePid';
import DemoWelcome from '../../Dialogs/DemoWelcome/DemoWelcome';
import NewGroup from '../../Dialogs/NewGroupPid/NewGroup';
import NewProject from '../../Dialogs/NewProject/NewProject';
import NewSubWorkPackage from '../../Dialogs/NewSubWorkPackage/NewSubWorkPackage';
import NewWorkPackage from '../../Dialogs/NewWorkPackage/NewWorkPackage';
import type {RestoreNodeType} from '../../Dialogs/RestoreFromTrash/RestorableNodes';
import {
  changeCustomerRoute,
  changeProjectFolderRoute,
  deletePidDialogRoute,
  demoWelcomeDialogRoute,
  dialogBaseRoute,
  exportProjectRoute,
  newGroupDialogRoute,
  newProjectDialogRoute,
  newSubWorkPackageDialogRoute,
  newWorkPackageDialogRoute,
  nodeBoardDialogRoute,
  responsibleBatchToolRoute,
  restoreNodesFromTrashDialogRoute,
  restoreTimeRecordsFromTrashDialogRoute,
  workPackageStatusBatchToolRoute,
} from './DialogRoutes';

const ChangeCustomer = lazy(() => import('../../Dialogs/ChangeCustomer/ChangeCustomer'));
const ChangeProjectFolder = lazy(() => import('../../Dialogs/ChangeProjectFolder/ChangeProjectFolder'));
const ExportProject = lazy(() => import('../../Dialogs/ExportProject/ExportProject'));
const ResponsibleBatchTool = lazy(() => import('../../Dialogs/ResponsibleBatchTool/ResponsibleBatchTool'));
const RestorableNodes = lazy(() => import('../../Dialogs/RestoreFromTrash/RestorableNodes'));
const RestorableTimeRecords = lazy(() => import('../../Dialogs/RestoreFromTrash/RestorableTimeRecords'));
const NodeBoardDialog = lazy(() => import('../../Dialogs/NodeBoard/NodeBoardDialog'));
const WorkPackageStatusBatchTool = lazy(
  () => import('../../Dialogs/WorkPackageStatusBatchTool/WorkPackageStatusBatchTool'),
);

export const dialogRouteObjects: RouteObject[] = [
  {
    children: [
      {
        element: <ChangeCustomer />,
        path: `${changeCustomerRoute}/:changeCustomerProjectId`,
      },
      {
        element: <ChangeProjectFolder />,
        path: `${changeProjectFolderRoute}/:changeProjectFolderProjectId`,
      },
      {
        element: <DemoWelcome />,
        path: demoWelcomeDialogRoute,
      },
      {
        element: <ExportProject />,
        path: `${exportProjectRoute}/:exportProjectNodeId`,
      },
      {
        element: <NewProject />,
        path: `${newProjectDialogRoute}/:newProjectRelativeNodeId?`,
      },
      {
        element: <NewGroup />,
        path: `${newGroupDialogRoute}/:newGroupRelativeNodeId`,
      },
      {
        element: <NewWorkPackage />,
        path: `${newWorkPackageDialogRoute}/:newWpRelativeNodeId`,
      },
      {
        element: <NewSubWorkPackage />,
        path: `${newSubWorkPackageDialogRoute}/:newSwpRelativeNodeId`,
      },
      {
        element: <DeletePid />,
        path: `${deletePidDialogRoute}/:pidNodeId`,
      },
      {
        element: <ResponsibleBatchTool />,
        path: `${responsibleBatchToolRoute}/:responsibleBatchToolNodeId`,
      },
      {
        element: <RestorableNodes />,
        path: `${restoreNodesFromTrashDialogRoute}/:parentNodeId/:restoreNodeType`,
      },
      {
        element: <RestorableTimeRecords />,
        path: `${restoreTimeRecordsFromTrashDialogRoute}/:referenceNodeId`,
      },
      {
        element: <NodeBoardDialog />,
        path: `${nodeBoardDialogRoute}/:nodeId`,
      },
      {
        element: <WorkPackageStatusBatchTool />,
        path: `${workPackageStatusBatchToolRoute}/:statusBatchToolNodeId`,
      },
    ],
    path: dialogBaseRoute,
  },
];

export function getDemoWelcomeDialogRoute(): string {
  return `${dialogBaseRoute}/${demoWelcomeDialogRoute}`;
}

export function getExportProjectDialogRoute(pidNodeId: Uuid): string {
  return `${dialogBaseRoute}/${exportProjectRoute}/${pidNodeId}`;
}

export function getNewProjectDialogRoute(relativeNodeId?: Uuid): string {
  return `${dialogBaseRoute}/${newProjectDialogRoute}/${relativeNodeId ?? ''}`;
}

export function getNewGroupDialogRoute(parentNodeId: Uuid): string {
  return `${dialogBaseRoute}/${newGroupDialogRoute}/${parentNodeId}`;
}

export function getNewWorkPackageDialogRoute(nodeId?: Uuid | undefined): string {
  let path = `${dialogBaseRoute}/${newWorkPackageDialogRoute}`;
  if (nodeId) {
    path += `/${nodeId}`;
  }
  return path;
}

export function getNewSubWorkPackageDialogRoute(swpId: Uuid): string {
  return `${dialogBaseRoute}/${newSubWorkPackageDialogRoute}/${swpId}`;
}

export function getRestoreNodesFromTrashRoute(nodeId: Uuid, restoreNodeType: RestoreNodeType): string {
  return `${restoreNodesFromTrashDialogRoute}/${nodeId}/${restoreNodeType}`;
}

export function getRestoreTimeRecordsFromTrashRoute(referenceNodeId: Uuid): string {
  return `${restoreTimeRecordsFromTrashDialogRoute}/${referenceNodeId}`;
}

export function getNodeBoardRoute(nodeId: Uuid): string {
  return `${dialogBaseRoute}/${nodeBoardDialogRoute}/${nodeId}`;
}

export function useGetNewWorkPackageDialogRoute(): (nodeId: Uuid | undefined, createOffer?: boolean) => string {
  const location = useLocation();
  return useCallback(
    (nodeId: Uuid | undefined, createOffer = false) => {
      let path = `${location.pathname}/${getNewWorkPackageDialogRoute(nodeId)}${location.search}`;
      if (createOffer) {
        path += '?createOffer=true';
      }
      return path;
    },
    [location.pathname, location.search],
  );
}

export function useGetNewSubWorkPackageDialogRoute(): (nodeId: Uuid) => string {
  const location = useLocation();
  return useCallback(
    (nodeId) => {
      return `${location.pathname}/${getNewSubWorkPackageDialogRoute(nodeId)}${location.search}`;
    },
    [location.pathname, location.search],
  );
}

export function useGetNewProjectDialogRoute(): (relativeNodeId?: Uuid) => string {
  const location = useLocation();
  return useCallback(
    (relativeNodeId?: Uuid) => {
      return `${location.pathname}/${getNewProjectDialogRoute(relativeNodeId)}${location.search}`;
    },
    [location.pathname, location.search],
  );
}

export function useGetNewGroupPidDialogRoute(): (parentNodeId: Uuid) => string {
  const location = useLocation();
  return useCallback(
    (parentNodeId: Uuid) => {
      return `${location.pathname}/${getNewGroupDialogRoute(parentNodeId)}${location.search}`;
    },
    [location.pathname, location.search],
  );
}
