import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import Close from '../Dialogs/Components/Close';
import {DialogContent, DialogFrame} from '../Dialogs/DialogFrame';
import Support from './TourSelection/Support';
import VideoTutorials from './TourSelection/VideoTutorials';
import WebsiteTutorials from './TourSelection/WebsiteTutorials';

interface TourSelectionProps {
  close: () => void;
}

export default function TourSelection({close}: TourSelectionProps): ReactElement {
  const {t} = useTranslation();
  return (
    <DialogFrame size={'large'} noOwnForm noClose>
      <DialogContent>
        <div className={'close'}>
          <Close close={close} />
        </div>

        <div className={'content'}>
          <div className={'header'}>
            <span>{t('onboarding:octavedFlowHelpPopup.header')}</span>
          </div>
          <div className={'subHeader'}>
            <span>{t('onboarding:octavedFlowHelpPopup.subHeader')}</span>
          </div>

          <VideoTutorials />
          <WebsiteTutorials />
          <Support />
        </div>

        {/*language=scss*/}
        <style jsx>{`
          .close {
            position: absolute;
            top: 0.4375rem;
            right: 0.75rem;
          }

          .content {
            padding: 20px 15px 25px 15px;
          }

          .header {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            text-align: center;
            padding-bottom: 0.25rem;
          }

          .subHeader {
            font-size: 16px;
            text-align: center;
          }
        `}</style>
      </DialogContent>
    </DialogFrame>
  );
}
