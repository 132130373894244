import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {createInheritingReducer} from '@octaved/utilities/src/Search/SearchReducers';
import {SubWorkPackageCreatedEvent} from '../Events';
import {reduceAddOptional} from '../NodeSearchReducers/GenericAddRemove';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<SubWorkPackageCreatedEvent>('flow.SubWorkPackageCreatedEvent', {
  assignedAnyProjectRole: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedAnyProjectRole', parentNodeId, nodeId),
  }),
  assignedProjectRoleId: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedProjectRoleId', parentNodeId, nodeId),
  }),
  assignedProjectRoleType: ({parentNodeId, nodeId}) => ({
    results: createInheritingReducer('assignedProjectRoleType', parentNodeId, nodeId),
  }),
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored',
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: 'ignored',
  hasTimeBasedPredecessor: 'ignored',
  isArchived: 'ignored',
  labelId: 'clear', //new node can be created with labels
  mrIsActive: 'ignored',
  name: 'clear', //name not exposed in event
  nodeNotPlanned: ({nodeId}) => ({
    results: (s) => reduceAddOptional(s, nodeId, 'nodeNotPlanned'),
  }),
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: ({nodeId}) => ({
    results: (s) => reduceAddOptional(s, nodeId, 'nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE),
  }),
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: 'clear',
  responsibleByAny: ({definesOwnResponsible, parentNodeId, nodeId}) =>
    definesOwnResponsible ? 'clear' : {results: createInheritingReducer('responsibleByAny', parentNodeId, nodeId)},
  responsibleUnitId: ({definesOwnResponsible, parentNodeId, nodeId}) =>
    definesOwnResponsible ? 'clear' : {results: createInheritingReducer('responsibleUnitId', parentNodeId, nodeId)},
  swpCompletedInDateRange: 'clear',
  swpIsCompleted: 'clear',
  swpIsLocked: 'clear',
  taskBlockedByDependency: 'ignored',
  taskCompletedInDateRange: 'ignored',
  taskStatus: 'ignored',
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'ignored',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasOpenTimeTrackingRecords: 'ignored',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'ignored',
  trRefHasTimeTrackingRecords: 'ignored',
  trRefHasTimeTrackingRecordsInDateRange: 'ignored',
  trRefUserRecentlyBookedOn: 'ignored',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'ignored',
  wpTimeTrackingLimit: 'ignored',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'ignored',
});
