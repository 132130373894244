import {useOnFocusOutside} from '@octaved/hooks/src/OnFocusOutside';
import {useOnOutsideClick} from '@octaved/hooks/src/OnOutsideClick';
import {Uuid} from '@octaved/typescript/src/lib';
import {Popup} from '@octaved/ui';
import {ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import {TaskPatchData} from '../../../../../EntityInterfaces/Task';
import {useTaskListReadonlyContext} from '../../../TaskListReadonlyContext';
import CellActionButton from './Components/CellActionButton';
import CellPlaceholder from './Components/CellPlaceholder';
import TaskCell from './Components/TaskCell';
import LabelPopupContent from './LabelCell/LabelPopupContent';
import LabelView from './LabelCell/LabelView';

interface Props {
  id: string;
  labels: Uuid[];
  patch: (data: Partial<TaskPatchData>) => void;
}

export default function LabelCell({labels, patch, id}: Props): ReactElement {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const hasLabels = useMemo(() => labels.length > 0, [labels]);
  const [focused, setFocused] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleOpen = useCallback(() => {
    setFocused((f) => !f);
  }, []);

  useOnOutsideClick(ref, () => {
    if (focused) {
      toggleOpen();
    }
  });

  useOnFocusOutside(ref, () => {
    if (focused) {
      toggleOpen();
    }
  });

  return (
    <TaskCell focused={focused} ref={ref} setFocused={setFocused} hasFocusElement wider>
      <div className={'labelCell'}>
        {hasLabels && <LabelView labels={labels} />}
        {!hasLabels && (
          <CellPlaceholder placeholder={'taskList:labels.noLabels'} tooltip={'taskList:labels.assignLabel'} />
        )}
      </div>

      {!readonlyOrNotManageable && (
        <>
          <CellActionButton
            focused={focused}
            hasContent={hasLabels}
            onToggleFocus={toggleOpen}
            onReset={() => {
              patch({labels: []});
              setFocused(false);
            }}
            resetInfo={'taskList:labels.removeLabels'}
          />

          <Popup open={focused} context={ref} setOpen={() => false} mountNode={ref.current}>
            <div className={'wrapper'} onClick={(e) => e.stopPropagation()}>
              <LabelPopupContent id={id} labels={labels} patch={patch} cellRef={ref} onAfterChange={toggleOpen} />
            </div>
          </Popup>
        </>
      )}

      {/*language=scss*/}
      <style jsx>{`
        .labelCell {
          overflow: hidden;
          display: flex;
          flex-grow: 1;
          grid-gap: 0.25rem;
        }
      `}</style>
    </TaskCell>
  );
}
