import {KeyboardEvent, useCallback, useRef} from 'react';

export type EventListener<E extends HTMLElement> = KeyboardEvent<E>;

export type KeyDownListenerMap<E extends HTMLElement = HTMLTextAreaElement> = Record<
  string,
  (e: EventListener<E>) => void
>;

export default function useKeyboardEventListener<E extends HTMLElement>(
  keydownListenerMap: KeyDownListenerMap<E>,
): (e: EventListener<E>) => void {
  const keyMap = useRef(keydownListenerMap);
  keyMap.current = keydownListenerMap;

  return useCallback((e) => {
    if (keyMap.current[e.key]) {
      e.stopPropagation();
      e.preventDefault();
      keyMap.current[e.key](e);
    }
  }, []);
}
