import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useChangeLargeViewNode} from '@octaved/flow/src/Routing/Routes/Node';
import {Uuid} from '@octaved/typescript/src/lib';
import {createContext, PropsWithChildren, ReactElement, useCallback, useContext, useMemo, useState} from 'react';

interface Props {
  close: () => void;
  initiallyExpandedNodeIds: ReadonlyArray<Uuid> | ReadonlySet<Uuid>;
  selectedNodeId: Uuid;
}

interface ProjectTreeSelectorContext {
  expand: (nodeId: Uuid, open?: boolean) => void;
  isExpanded: (nodeId: Uuid) => boolean;
  isSelected: (nodeId: Uuid) => boolean;
  select: (node: NodeType) => void;
}

const context = createContext<ProjectTreeSelectorContext>({
  expand: () => undefined,
  isExpanded: () => false,
  isSelected: () => false,
  select: () => undefined,
});

export function useProjectTreeSelectorContext(): ProjectTreeSelectorContext {
  return useContext(context);
}

// noinspection FunctionNamingConventionJS
export function ProjectTreeSelectorContextProvider({
  children,
  close,
  initiallyExpandedNodeIds,
  selectedNodeId,
}: PropsWithChildren<Props>): ReactElement {
  const [expanded, setExpanded] = useState(
    () => new Set(initiallyExpandedNodeIds ? [...initiallyExpandedNodeIds] : []),
  );

  const expand = useCallback((nodeId: Uuid, open?: boolean) => {
    setExpanded((cur) => {
      const isOpen = cur.has(nodeId);
      if (isOpen && open !== true) {
        const next = new Set(cur);
        next.delete(nodeId);
        return next;
      }
      if (!isOpen && open !== false) {
        return new Set(cur).add(nodeId);
      }
      return cur;
    });
  }, []);

  const changeNode = useChangeLargeViewNode();

  const ctx = useMemo(
    (): ProjectTreeSelectorContext => ({
      expand,
      isExpanded: (id) => expanded.has(id),
      isSelected: (id) => selectedNodeId === id,
      select: (node) => {
        changeNode(node);
        close();
      },
    }),
    [expand, expanded, selectedNodeId, changeNode, close],
  );

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
