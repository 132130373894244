import {isDebug} from '@octaved/env/src/Environment';
import * as Logger from '@octaved/env/src/Logger';
import {i18n} from 'i18next';
import {Component, ErrorInfo, ReactNode} from 'react';
import {I18nContext} from 'react-i18next';
import {Icon} from 'semantic-ui-react';

interface State {
  hasError: boolean;
  error: Error | null;
}

const translations = {
  de: {
    reloadPage: 'Bitte die Seite neu laden.',
    unhandledReactError: 'Ein Fehler beim Anzeigen einer Komponente ist aufgetreten.',
  },
  en: {
    reloadPage: 'Please reload the page.',
    unhandledReactError: 'An error occurred on showing a component.',
  },
};

export default class ErrorBoundary extends Component<{children: ReactNode}, State> {
  private isFullyMounted = false;
  static contextType = I18nContext;

  constructor(props: {children: ReactNode}) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  getI18n(): i18n | undefined {
    return this.context?.i18n;
  }

  public get isMounted(): boolean {
    return this.isFullyMounted;
  }

  public set isMounted(status: boolean) {
    this.isFullyMounted = status;
  }

  static getDerivedStateFromError(error: Error): Partial<State> {
    return {
      error,
      hasError: true,
    };
  }

  componentDidMount(): void {
    this.isMounted = true;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Logger.error(error, errorInfo.componentStack);
  }

  componentWillUnmount(): void {
    this.isMounted = false;
  }

  getUsedTranslationLanguage(): keyof typeof translations {
    const langCode = this.getI18n()?.language.slice(0, 2);
    return translations.hasOwnProperty(langCode || '') ? (langCode as keyof typeof translations) : 'en';
  }

  render(): ReactNode {
    if (this.state.hasError) {
      const langCode = this.getUsedTranslationLanguage();
      return (
        <div className={'error'}>
          <div className={'section'}>
            <Icon name={'attention'} color={'grey'} size={'big'} />
            <div className={'message'}>{translations[langCode].unhandledReactError}</div>
            <div>{translations[langCode].reloadPage}</div>
            {isDebug && (
              <span
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(this.state.error!.stack).replace(/\\n|"/g, '<br>'),
                }}
              ></span>
            )}
          </div>
          {/*#region styles*/}
          {/*language=SCSS*/}
          <style jsx>{`
            .error {
              height: 100%;
              width: 100%;
              background-color: #f1f1f1;
              color: #7b7b7b;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .message {
              margin-top: 10px;
            }

            .section {
              display: flex;
              align-items: center;
              flex-direction: column;
            }
          `}</style>
          {/*#endregion*/}
        </div>
      );
    }
    return this.props.children;
  }
}
