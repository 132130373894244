import {createFileStorageQuotaExceededSnackbar} from '@octaved/flow/src/Components/Snackbar/FileStorageQuotaExceededSnackbar';
import type {OwnerQuota} from '@octaved/media-files/src/EntityInterfaces/Quota';
import isPlainObject from 'lodash/isPlainObject';
import type NetworkError from '../NetworkError';

interface ErrorData {
  mediaFileQuotaExceeded: {
    decisiveFileSize: number;
    owner: 'organization' | 'identity';
    quota: OwnerQuota;
  };
}

function isMediaFileQuotaExceeded(data: unknown): data is ErrorData {
  return isPlainObject(data) && (data as object).hasOwnProperty('mediaFileQuotaExceeded');
}

export function handleMediaFileQuotaExceeded({data, responseStatus}: NetworkError<unknown>): void {
  if (responseStatus === 402 && isMediaFileQuotaExceeded(data)) {
    const {decisiveFileSize, owner, quota} = data.mediaFileQuotaExceeded;
    createFileStorageQuotaExceededSnackbar(owner, quota, decisiveFileSize);
  }
}
