import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {createSelector} from 'reselect';
import {isItemAccessibleSelector} from '../../AccessibleItem';
import {menuOptions} from '../../Routing/Routes/SystemSettings';
import {useGetLastVisitedUrl} from '../../Routing/UrlWatcher';

const systemSettingsFirstAvailableEntrySelector = createSelector(isItemAccessibleSelector, (isItemAccessible) => {
  return menuOptions.filter((option) => !option.header && isItemAccessible(option))[0];
});

export default function SystemSettingsRedirect(): ReactElement {
  const firstAvailable = useSelector(systemSettingsFirstAvailableEntrySelector);
  const to = useGetLastVisitedUrl(firstAvailable?.path || '');
  if (!to) {
    //if no redirect path return to parent
    return <Navigate to={'..'} replace />;
  }
  return <Navigate to={to} replace />;
}
