import {TimeRecordsRestoredFromTrashEvent} from '../Events';
import {registerListeners} from './Common';

//This record makes sure every search ident is at least thought about
//If you set this to a non-reducer, please leave a comment why!
registerListeners<TimeRecordsRestoredFromTrashEvent>('flow.TimeRecordsRestoredFromTrashEvent', {
  assignedAnyProjectRole: 'ignored',
  assignedProjectRoleId: 'ignored',
  assignedProjectRoleType: 'ignored',
  bpWaitingForMyResponse: 'ignored',
  customerId: 'ignored',
  customerIsBillable: 'ignored',
  customerName: 'ignored',
  customerNumber: 'ignored',
  favorite: 'ignored',
  grIsClosed: 'ignored',
  grIsLocked: 'ignored',
  grType: 'ignored',
  hasLogicalPredecessor: 'ignored',
  hasTimeBasedPredecessor: 'ignored',
  isArchived: 'ignored',
  labelId: 'ignored',
  mrIsActive: 'ignored',
  name: 'ignored',
  nodeNotPlanned: 'ignored',
  nodePlannedAroundDateRange: 'ignored',
  nodePlanningEndsAfterDate: 'ignored',
  nodePlanningEndsBeforeDate: 'ignored',
  nodePlanningStartsAfterDate: 'ignored',
  nodePlanningStartsBeforeDate: 'ignored',
  nodeType: 'ignored',
  pidHasDueDate: 'ignored',
  pidHasMilestoneDate: 'ignored',
  pidPid: 'ignored',
  prCustomerLocationId: 'ignored',
  prCustomerLocationNumber: 'ignored',
  prIsClosed: 'ignored',
  prIsLocked: 'ignored',
  prIsTemplate: 'ignored',
  referenceNumber: 'ignored',
  responsibleByAny: 'ignored',
  responsibleUnitId: 'ignored',
  swpCompletedInDateRange: 'ignored',
  swpIsCompleted: 'ignored',
  swpIsLocked: 'ignored',
  taskBlockedByDependency: 'ignored',
  taskCompletedInDateRange: 'ignored',
  taskStatus: 'ignored',
  timeControlExceeded: 'ignored',
  timeControlNotStarted: 'ignored',
  trRefHasNonBilledTimeTrackingRecords: 'clear',
  trRefHasNonBilledTimeTrackingRecordsInDateRange: 'clear',
  trRefHasOpenTimeTrackingRecords: 'clear',
  trRefHasOpenTimeTrackingRecordsInDateRange: 'clear',
  trRefHasTimeTrackingRecords: 'clear',
  trRefHasTimeTrackingRecordsInDateRange: 'clear',
  trRefUserRecentlyBookedOn: 'clear',
  wpBillingType: 'ignored',
  wpCompletedInDateRange: 'ignored',
  wpHasBillingsInPeriod: 'ignored',
  wpHasFinalBillings: 'ignored',
  wpIsApprovedForBilling: 'ignored',
  wpIsClosed: 'ignored',
  wpIsCompleted: 'ignored',
  wpIsLocked: 'ignored',
  wpIsOffer: 'ignored',
  wpPriceCategory: 'ignored',
  wpTaskTimePrognosis: 'clear',
  wpTimeTrackingLimit: 'clear',
  wpTimeTrackingReferenceNodeType: 'ignored',
  wpUserRecentlyBookedOn: 'clear',
});
