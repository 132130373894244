import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {BarPluginProps} from '../../../../Canvas/Bars/Plugins/BarPlugin';
import {BarRendererPlugin} from '../../../../Canvas/Bars/Plugins/BarRendererPlugin';
import {isPlanningBehindSelectedBaselinesSelector} from '../../../../Selectors/PlanningBaselineHistorySelectors';

interface ColoredBarRenderPluginProps extends BarPluginProps {
  isBaseline?: boolean;
  rootNodeId: Uuid;
}

export class ColoredBarRenderPlugin extends BarRendererPlugin {
  readonly #colorBaseline = '#71717a';
  readonly #colorOnTime = '#10b981';
  readonly #colorDelayed = '#ef4444';

  #isBaseline: boolean;
  #rootNodeId: Uuid;

  constructor(props: ColoredBarRenderPluginProps) {
    super(props);
    this.#isBaseline = props.isBaseline ?? false;
    this.#rootNodeId = props.rootNodeId;
  }

  protected getStyleColor(state: FlowState): string {
    if (this.#isBaseline) {
      return this.#colorBaseline;
    }
    const isDelayed = isPlanningBehindSelectedBaselinesSelector(state)(this.#rootNodeId, this.nodeId);
    return isDelayed ? this.#colorDelayed : this.#colorOnTime;
  }

  protected style(color: string): void {
    this.rect.fill(color);
  }
}
