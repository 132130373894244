import {useSupportedLanguageFromContext} from '@octaved/i18n/src/Language/Languages';
import {requireLightColor, useHexContrast} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import classNames from 'classnames';
import {ReactElement, useContext, useMemo} from 'react';
import {Icon} from 'semantic-ui-react';
import {Label as LabelEntity} from '../../../EntityInterfaces/Labels';
import {flowStyleContext} from '../../../Styles/StyleContext';

export interface FlowLabelProps {
  label: LabelEntity;
  onClick?: () => void;
  isSelected?: boolean;
  onRemove?: () => void;
  small?: boolean;
  dotOnly?: boolean;
  noEllipsis?: boolean;
  noTranslation?: boolean;
}

export default function FlowLabel({
  label,
  onClick,
  onRemove,
  isSelected,
  small = true,
  dotOnly,
  noEllipsis = false,
  noTranslation,
}: FlowLabelProps): ReactElement {
  const {
    COLORS: {BACKGROUND, FONT, LEVITATION},
  } = useContext(flowStyleContext);
  const lightColor = requireLightColor(label.color);
  const fontColor = useHexContrast(label.color);
  const languageCode = useSupportedLanguageFromContext();
  const name = noTranslation ? label.name : label.translations?.[languageCode] || label.name;
  const multiLabel = name.includes('::');
  const style = useMemo(
    () => ({backgroundColor: '#' + (label.color || 'CCC'), color: fontColor}),
    [label.color, fontColor],
  );
  const labelName = multiLabel ? name.substring(0, name.indexOf('::')) : name;
  const multiLabelValue = multiLabel ? name.substring(name.indexOf('::') + 2) : null;

  return (
    <div
      className={classNames('label', {
        dotOnly,
        isSelected,
        multiLabel,
        noEllipsis,
        small,
        clickAble: onClick,
        colorForValue: lightColor,
      })}
      onClick={onClick}
      style={multiLabel && !dotOnly ? undefined : style}
    >
      {!dotOnly && (
        <>
          {!multiLabel && (
            <>
              <span className={'value'} title={labelName}>
                {labelName}
              </span>
              {onRemove && (
                <div className={'icon'}>
                  <Icon name={'delete'} onClick={onRemove} fitted />
                </div>
              )}
            </>
          )}

          {multiLabel && (
            <>
              <div className={'multiLabelName'} style={style} title={labelName ?? undefined}>
                {labelName}
              </div>
              <div className={'multiLabelValue'}>
                <span className={'value'} title={multiLabelValue ?? undefined}>
                  {multiLabelValue}
                </span>
                {onRemove && (
                  <div className={'icon'}>
                    <Icon name={'delete'} onClick={onRemove} fitted />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .label {
          line-height: 16px;
          align-items: center;
          background-color: #fff;
          overflow: hidden;
          display: inline-flex;
          position: relative;
          max-width: 100%;
          box-shadow: inset 0 0 0 2px #${label.color};
          padding: 2px 8px;
          font-size: 14px;
          border-radius: 12px;

          &.small {
            box-shadow: inset 0 0 0 1px #${label.color};
            font-size: 12px;
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .multiLabel {
          padding: 0;
          justify-content: flex-start;
          max-width: 100%;
        }

        :not(.noEllipsis) .multiLabelName,
        :not(.noEllipsis) .multiLabelValue {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          flex-grow: 1;
        }

        .multiLabelName {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          padding: 2px 4px 2px 8px;

          .small & {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .multiLabelValue {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          display: flex;
          align-items: center;
          color: ${FONT.STANDARD};
          padding: 2px 8px 2px 4px;

          .colorForValue & {
            color: #${label.color || 'CCC'};
          }

          .small & {
            padding-top: 0;
            padding-bottom: 0;
          }
        }

        .value {
          flex-grow: 1;
          max-width: 100%;
        }

        :not(.noEllipsis) .value {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .clickAble {
          cursor: pointer;
        }

        .isSelected {
          box-shadow: 0 0 10px ${LEVITATION.LEVEL_4};
          border: 1px solid ${BACKGROUND.STANDARD};
        }

        .icon {
          margin-left: 8px;
          cursor: pointer;
          margin-bottom: -1px;
          flex-shrink: 0;
        }

        .dotOnly {
          height: 19px;
          width: 19px;
          border-radius: 50%;
          content: ' ';
        }

        .noEllipsis {
          flex-shrink: 0;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
