import {ResponsibleNodeType} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {readRights} from '@octaved/flow/src/Modules/ResponsibleNode';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import Section from '../../Section';
import {OptionalNoLabelProps} from '../Common/Type';
import NodeResponsibilityValue from '../Values/NodeResponsibilityValue';

interface NodeResponsibilitySectionProps {
  nodeId: Uuid;
  nodeType: ResponsibleNodeType;
}

export default function NodeResponsibilitySection({
  nodeId,
  nodeType,
  noLabel,
}: NodeResponsibilitySectionProps & OptionalNoLabelProps): ReactElement | null {
  const canRead = useIsGranted(readRights[nodeType], nodeId);

  if (!canRead) {
    return null;
  }

  return (
    <Section labelToken={'nodeDetails:field.responsibility.label'} noLabel={noLabel}>
      <div className={'flex flex-wrap gap-x-4 gap-y-1'}>
        <NodeResponsibilityValue nodeId={nodeId} />
      </div>
    </Section>
  );
}
