import * as React from 'react';
import {cn} from './Hooks';

export type TWTextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  autoHeight?: boolean; //experimental (requires CSS `field-sizing`)
  autoHeightMax?: number;
  autoHeightMin?: number;
  hasError?: boolean;
};

const TWTextArea = React.forwardRef<HTMLTextAreaElement, TWTextareaProps>((props, ref) => {
  const {autoHeight, autoHeightMax, autoHeightMin, rows = 3, hasError, className, ...rest} = props;
  return (
    <>
      <textarea
        className={cn(
          'border-input bg-background placeholder:text-muted-foreground text-md flex w-full rounded border px-3 py-2 ring-offset-2 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-sky-700 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className,
          hasError && 'border-red-300 bg-red-50',
          autoHeight && 'field-sizing-content',
          autoHeightMin && `min-h-[calc(${autoHeightMin}lh + 1rem)]`, //1rem is for `py-2` * 2
          autoHeightMax && `max-h-[calc(${autoHeightMax}lh + 1rem)]`, //1rem is for `py-2` * 2
        )}
        ref={ref}
        rows={autoHeight ? undefined : rows}
        {...rest}
      />
      {/*language=scss*/}
      <style jsx>{`
        .field-sizing-content {
          field-sizing: content; //experimental: https://developer.mozilla.org/en-US/docs/Web/CSS/field-sizing
        }
      `}</style>
    </>
  );
});

TWTextArea.displayName = 'TWTextArea';

export {TWTextArea};
