import {isDevLocal} from '@octaved/env/src/Environment';
import {validateFunction} from '@octaved/validation';
import get from 'lodash/get';
import setWith from 'lodash/setWith';
import {
  Middleware,
  Store as ReduxStore,
  UnknownAction,
  applyMiddleware,
  compose,
  legacy_createStore as reduxCreateStore,
} from 'redux';
import {flattenObject} from './FlattenObject';
import {createMultiActionReducer} from './MultiAction';
import {AddEntityReducers, RemoveEntityReducers} from './Reducer/ReduxEntities';
import {setStateProperty, setWithCopyCustomizer} from './SetStateProperty';
import {sortUpToRoot} from './SortObject';
import {Reducer, ReducerList, ReducerObject} from './Store';

const REDUCERS_ADDED = '@REDUCERS_ADDED';
const REDUCERS_REMOVED = '@REDUCERS_REMOVED';

// must be any (plain) object

type EmptyObject = object;

type AsyncReducers<S extends EmptyObject> = Map<string, Reducer<S>>;

interface AddAction extends UnknownAction {
  paths: string[];
}

function reduceReducerAdded<S extends EmptyObject>(asyncReducers: AsyncReducers<S>, state: S, action: AddAction): S {
  return action.paths.reduce((curState, path) => {
    //Lazy-added entity-reducers always have {} as initial state and are not part of the asyncReducers:
    const asyncNewState = asyncReducers.has(path) ? asyncReducers.get(path)!(get(state, path) as S, action) : {};
    const newState = setStateProperty(curState, path, asyncNewState);
    return sortUpToRoot(path.split('.'), newState);
  }, state);
}

function reduceWithAsyncReducers<S extends EmptyObject>(
  rootReducer: Reducer<S>,
  asyncReducers: AsyncReducers<S>,
  state: S,
  action: UnknownAction,
): S {
  let newState = rootReducer(state, action);
  asyncReducers.forEach((reducer, path) => {
    const asyncOldState = get(state, path) as S;
    const asyncNewState = reducer(asyncOldState, action);
    if (asyncOldState !== asyncNewState) {
      if (state === newState) {
        newState = {...newState};
      }
      setWith(newState, path, asyncNewState, setWithCopyCustomizer);
    }
  });
  return newState;
}

function decorateAsyncReducers<S extends EmptyObject>(
  rootReducer: Reducer<S>,
  asyncReducers: AsyncReducers<S>,
): Reducer<S> {
  return (state, action = {} as UnknownAction) => {
    if (action.type === REDUCERS_REMOVED) {
      return (action as AddAction).paths.reduce((newState, path) => setStateProperty(newState, path, undefined), state);
    } else if (action.type === REDUCERS_ADDED) {
      return reduceReducerAdded(asyncReducers, state, action as AddAction);
    } else {
      return reduceWithAsyncReducers(rootReducer, asyncReducers, state, action);
    }
  };
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (options: {
      features?: {
        reorder?: boolean;
        test?: boolean;
      };
      maxAge?: number;
      name?: string;
      trace?: boolean;
      traceLimit?: number;
    }) => typeof compose;
    reduxStoreExport: () => void;
  }
}

export interface EnhancedStore<S extends EmptyObject> extends ReduxStore<S> {
  addReducers(reducers: ReducerObject): () => void;
}

function createExport<S>(store: ReduxStore<S>): void {
  window.reduxStoreExport = () => {
    const state = store.getState();
    const json = JSON.stringify(state);
    const downloadLink = window.document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([json], {type: 'application/json'}));
    downloadLink.download = 'state.json';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
}

export default function CreateStore<S extends EmptyObject, Entities extends EmptyObject = EmptyObject>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  middlewares: Array<Middleware<any, S, any>>,
  rootReducer: Reducer<S>,
  addEntityReducers: AddEntityReducers<Entities> | null = null,
  removeEntityReducers: RemoveEntityReducers | null = null,
  initialState: S = {} as S,
  name = 'Octaved',
): EnhancedStore<S> {
  validateFunction(rootReducer);
  let composeEnhancers = compose;

  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && isDevLocal) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name,
      features: {
        reorder: false,
        test: false,
      },
      maxAge: 50,
      trace: true,
      traceLimit: 25,
    });
  }

  const asyncReducers: AsyncReducers<S> = new Map();

  const store = reduxCreateStore(
    // @ts-ignore trying to cast this to the original Redux-Reducer<S> will result in a TS2321 error
    createMultiActionReducer(decorateAsyncReducers(rootReducer, asyncReducers)),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  ) as unknown as EnhancedStore<S>;

  store.addReducers = (reducers: ReducerObject) => {
    const newReducers = flattenObject(reducers) as ReducerList<Entities>;
    const paths = Object.keys(newReducers);

    let entityPaths: string[] = [];
    const usesEntityReducers = Boolean(addEntityReducers && removeEntityReducers);
    let reducersToAdd = Object.entries(newReducers);
    if (usesEntityReducers && reducers.entities) {
      addEntityReducers!(reducers.entities as ReducerList<Entities>);
      entityPaths = Object.keys(reducers.entities);
      reducersToAdd = reducersToAdd.filter(([p]) => !p.startsWith('entities.'));
    }
    reducersToAdd.forEach(([p, r]) => asyncReducers.set(p, r as Reducer<S>));
    store.dispatch({paths, type: REDUCERS_ADDED});

    createExport(store);

    return () => {
      let pathsToRemove = paths;
      if (usesEntityReducers) {
        removeEntityReducers!(entityPaths);
        pathsToRemove = paths.filter((p) => !p.startsWith('entities.'));
      }
      pathsToRemove.forEach((p) => asyncReducers.delete(p));
      store.dispatch({paths, type: REDUCERS_REMOVED});
    };
  };

  return store;
}
