import {CheckCircle, Trash} from 'lucide-react';
import {ReactElement} from 'react';
import {HStack} from '../../../Layout';
import {IconButton} from '../../Button';
import PickerViewFooter from './Components/PickerViewFooter';

export interface StandardRangePickerFooterProps {
  canClear?: boolean;
  clear: () => void;
  close: () => void;
  footerAddition?: ReactElement;
}

export default function StandardRangePickerFooter({
  canClear,
  clear,
  close,
  footerAddition,
}: StandardRangePickerFooterProps): ReactElement {
  return (
    <PickerViewFooter>
      <HStack justifyContent={'space-between'}>
        <div>{footerAddition}</div>
        <HStack spacing={'10px'}>
          {canClear && (
            <IconButton
              variant={'ghost'}
              size={'sm'}
              icon={<Trash />}
              iconColor={'red'}
              onClick={clear}
              className={'hover:!bg-red-50'}
            />
          )}

          <IconButton icon={<CheckCircle />} onClick={close} label={'OK'} size={'sm'} />
        </HStack>
      </HStack>
    </PickerViewFooter>
  );
}
