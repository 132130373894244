import {createSelector} from 'reselect';
import {RestorableNodeFromTrash} from '../../../EntityInterfaces/RestoreFromTrash';
import {FlowState} from '../../State';
import {restorableNodesSelector} from '../RestoreFromTrashSelectors';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: FlowState['ui']['pages']['projectTreeTrash']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.pages.projectTreeTrash);
}

export const projectTreeTrashCheckedDeletedNodesSelector = ui((state) => state.checkedDeletedNodes);
export const projectTreeTrashExpandedNodesSelector = ui((state) => state.expandedNodes);

export const checkedNodesSelector = createSelector(
  projectTreeTrashCheckedDeletedNodesSelector,
  restorableNodesSelector,
  (checkedIds, allNodes): RestorableNodeFromTrash[] => {
    const checkedNodeIds = new Set(checkedIds);
    return Object.values(allNodes).reduce<RestorableNodeFromTrash[]>((acc, nodes) => {
      if (nodes) {
        nodes.forEach((node) => {
          if (checkedNodeIds.has(node.id)) {
            acc.push(node);
          }
        });
      }
      return acc;
    }, []);
  },
);
