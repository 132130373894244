import {error} from '@octaved/env/src/Logger';
import util from 'util';
import {objectEntries} from '../Object';
import {toCachedSet} from '../Set';
import {hashCondition} from './HashCondition';
import {
  AnyCondition,
  CombinersShape,
  isAndCondition,
  isCustomCombiner,
  isFixResult,
  isNotCondition,
  isOrCondition,
  isTransformer,
} from './Types';

export function arrayAndCombiner<T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>): ReadonlyArray<T> {
  if (!a.length) {
    return a;
  }
  if (!b.length) {
    return b;
  }
  //See CombineConditionResults.md for a benchmark
  // Resulting judgement: If a < b then this is only faster if the set for b is cached. However, with most of our
  //  queries being very similar, most sub-results will be identical, so they ought to be cached. For large sub-results
  //  this makes a huge difference in the benchmark.
  if (a.length < b.length) {
    const bSet = toCachedSet(b);
    return a.filter((i) => bSet.has(i));
  }
  const aSet = toCachedSet(a);
  return b.filter((i) => aSet.has(i));
}

function arrayNotCombiner<T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>): ReadonlyArray<T> {
  if (!a.length || !b.length) {
    return a;
  }
  const bSet = toCachedSet(b);
  return a.filter((i) => !bSet.has(i));
}

export function arrayOrCombiner<T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>): ReadonlyArray<T> {
  return [...new Set([...a, ...b])];
}

const emptyResult: unknown[] = [];

/**
 * Sots the not-conditions to the end - they do not work up front
 */
function sortNotConditionsLast<C, T, Combiners extends CombinersShape<T>>(
  conditions: ReadonlyArray<AnyCondition<C, T, Combiners>>,
): AnyCondition<C, T, Combiners>[] {
  return conditions.toSorted((a, b) => {
    const aIsNot = isNotCondition(a);
    const bIsNot = isNotCondition(b);
    if (aIsNot && !bIsNot) {
      return 1;
    }
    if (bIsNot && !aIsNot) {
      return -1;
    }
    return 0;
  });
}

//Set `localStorage.setItem('HC_DEBUG_COMBINE_CONDITION_RESULTS', '1')` in your browser:
const debugCombineConditionResults: boolean = ['1', 'true'].includes(
  localStorage.getItem('HC_DEBUG_COMBINE_CONDITION_RESULTS') || '',
);

function recordResults<C, T, Combiners extends CombinersShape<T>>(
  condition: AnyCondition<C, T, Combiners>,
  result: ReadonlyArray<T> | undefined,
): void {
  if (debugCombineConditionResults && typeof condition === 'object') {
    // @ts-ignore debug only
    condition._result = result;
  }
}

function combine<C, T, Combiners extends CombinersShape<T>>(
  condition: AnyCondition<C, T, Combiners>,
  resultRetriever: (c: C) => ReadonlyArray<T>,
  combiners: Combiners,
  base: ReadonlyArray<T> | undefined = undefined,
): ReadonlyArray<T> | undefined {
  let result: ReadonlyArray<T> | undefined = undefined;

  if (isNotCondition(condition)) {
    if (!base) {
      error('A not condition makes no sense alone!', condition);
    }
    const combined = combineCached(condition.not, resultRetriever, combiners, result || base);
    result = base && combined ? arrayNotCombiner(base, combined) : (emptyResult as ReadonlyArray<T>);
  } else if (isAndCondition(condition)) {
    sortNotConditionsLast(condition.and).forEach((cond) => {
      const combined = combineCached(cond, resultRetriever, combiners, result || base);
      result = result && combined ? arrayAndCombiner(result, combined) : result || combined;
    });
  } else if (isOrCondition(condition)) {
    result = [];
    condition.or.forEach((cond) => {
      const combined = combineCached(cond, resultRetriever, combiners, result || base);
      result = result && combined ? arrayOrCombiner(result, combined) : result || combined;
    });
  } else if (isTransformer(condition)) {
    const combined = combineCached(condition.transform[0], resultRetriever, combiners, result || base);
    result = combined ? condition.transform[1](combined) : (emptyResult as ReadonlyArray<T>);
  } else if (isCustomCombiner(condition)) {
    const {ident, options, sources} = condition.combine;
    let allSourcesAvailable = true;
    const sourceResults: Record<string, ReadonlyArray<T>> = {};
    objectEntries(sources).forEach(([key, sourceCondition]) => {
      const combined = combineCached(sourceCondition, resultRetriever, combiners, result || base);
      if (combined) {
        sourceResults[key as string] = combined;
      } else {
        allSourcesAvailable = false;
      }
    });
    result = allSourcesAvailable ? combiners[ident](sourceResults, options) : (emptyResult as ReadonlyArray<T>);
  } else if (isFixResult(condition)) {
    result = condition.fixResult;
  } else {
    result = resultRetriever(condition);
  }

  recordResults(condition, result);

  return result;
}

const cacheResults = new WeakMap<() => void, Map<string, ReadonlyArray<string>>>();

function combineCached<C, T, Combiners extends CombinersShape<T>>(
  condition: AnyCondition<C, T, Combiners>,
  resultRetriever: (c: C) => ReadonlyArray<T>,
  combiners: Combiners,
  base: ReadonlyArray<T> | undefined = undefined,
): ReadonlyArray<T> | undefined {
  const retriever = resultRetriever as unknown as () => void;
  let cache = cacheResults.get(retriever);
  if (!cache) {
    // console.log('using new cache', 'retriever');
    cache = new Map();
    cacheResults.set(retriever, cache);
  }

  const cacheKey = hashCondition(combiners, condition) + JSON.stringify(base);
  let cached = cache.get(cacheKey) as ReadonlyArray<T> | undefined;
  if (!cached) {
    // console.log('using new cache', 'key');
    cached = combine(condition, resultRetriever, combiners, base);
    cache.set(cacheKey, cached as ReadonlyArray<string>);
  }

  return cached;
}

export function combineConditionResults<C, T>(
  condition: AnyCondition<C, T, never>,
  resultRetriever: (c: C) => ReadonlyArray<T>,
  combiners?: never,
): ReadonlyArray<T>;
export function combineConditionResults<C, T, Combiners extends CombinersShape<T>>(
  condition: AnyCondition<C, T, Combiners>,
  resultRetriever: (c: C) => ReadonlyArray<T>,
  combiners: Combiners,
): ReadonlyArray<T>;
export function combineConditionResults<C, T, Combiners extends CombinersShape<T> = never>(
  condition: AnyCondition<C, T, Combiners>,
  resultRetriever: (c: C) => ReadonlyArray<T>,
  combiners: Combiners extends CombinersShape<T> ? Combiners : never = {} as never,
): ReadonlyArray<T> {
  const result = combineCached(condition, resultRetriever, combiners) || (emptyResult as ReadonlyArray<T>);
  if (debugCombineConditionResults) {
    if (process.env.NODE_ENV === 'test') {
      //In jest the objects are truncated
      // eslint-disable-next-line no-console
      console.log(util.inspect(condition, {showHidden: false, depth: null, colors: true}));
    } else {
      //But in the browser we want the object raw to collapse/expand levels in the dev tools
      // eslint-disable-next-line no-console
      console.log(condition);
    }
  }
  return result;
}
