import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import classNames from 'classnames';
import {omit} from 'lodash';
import {ReactElement, ReactNode} from 'react';
import {Radio, StrictRadioProps} from 'semantic-ui-react';

interface Props {
  checked: boolean;
  children?: ReactNode;
  label?: ComplexTranslatable;
  labelOverride?: ReactElement;
  setChecked: () => void;
}

/**
 * Simplifies setChecked, label and nested children
 */
export default function SimpleRadio(props: StrictRadioProps & Props): ReactElement {
  return (
    <>
      <Radio
        {...omit(props, ['children', 'setChecked', 'label', 'labelOverride'])}
        onChange={(_e, {checked}) => checked && props.setChecked()}
        label={
          <label>
            {props.labelOverride ? <>{props.labelOverride}</> : <ComplexTrans translatable={props.label} />}
          </label>
        }
      />
      {!!props.children && <div className={classNames('children', {disabled: props.disabled})}>{props.children}</div>}
      {/*language=SCSS*/}
      <style jsx>{`
        .children {
          padding: 10px 26px;

          &.disabled {
            opacity: 0.5;
          }
        }
      `}</style>
    </>
  );
}
