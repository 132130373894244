import MaterialResource from '@octaved/flow/src/Drawer/StandardInspector/Views/Planning/MaterialResources/MaterialResource';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useLoadNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useLoadPlanningDates} from '@octaved/planning/src/Modules/PlanningDates';
import {getAssignedPlanningDatesForNodeSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeMaterialResourcesProps {
  node: NodeType;
}

export default function NodeMaterialResources({
  frame: Frame,
  node,
}: NodeMaterialResourcesProps & FramedSectionOrTileProps): ReactElement | null {
  const getAssignedPlanningDatesForNode = useSelector(getAssignedPlanningDatesForNodeSelector);

  const materialResourceIds = useMemo(() => {
    const materialResourceIds: Uuid[] = [];
    for (const {nodeId} of node.assignedPlanningDates) {
      materialResourceIds.push(nodeId);
    }
    return materialResourceIds;
  }, [node.assignedPlanningDates]);

  useLoadNodes(materialResourceIds);
  useLoadPlanningDates(materialResourceIds);

  if (!isWorkPackage(node) || node.assignedPlanningDates.length === 0) {
    return null;
  }
  const planningNodes = getAssignedPlanningDatesForNode(node.id);
  const sortedPlanningNodes = [...planningNodes].sort((a, b) => a.plannedStart.localeCompare(b.plannedStart));
  return (
    <Frame labelToken={'pages:projects.inspector.planningDates.materialResources'}>
      <div className={'grid max-w-full grid-cols-[repeat(2,max-content)_1fr] items-center gap-y-2.5 overflow-hidden'}>
        {sortedPlanningNodes.map(({id, nodeId}) => (
          <MaterialResource materialResourceId={nodeId} planningDateId={id} key={id} workPackage={node} />
        ))}
      </div>
    </Frame>
  );
}
