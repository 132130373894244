export type PageHelpIdents =
  | 'billing'
  | 'billingPartial'
  | 'billingsList'
  | 'controlActivity'
  | 'controlCriticalTimePrognosis'
  | 'controlDegreeOfCompletion'
  | 'controlFixedPriceAnalysis'
  | 'controlInProgress'
  | 'controlPlanningTransgression'
  | 'controlTimeTransgression'
  | 'controlTrackedTime'
  | 'createOffer'
  | 'editProjects'
  | 'myCalendar'
  | 'mySettings'
  | 'myTasks'
  | 'myWorkPackages'
  | 'organizeBoards'
  | 'personalTasks'
  | 'prepare'
  | 'projectPlanning'
  | 'projectTreeTrash'
  | 'settings'
  | 'simulationMode'
  | 'teamAnalyzeBillingTypes'
  | 'teamAnalyzeBookedTimes'
  | 'teamAnalyzeChargeableQuota'
  | 'teamAnalyzeNoCharge'
  | 'teamAnalyzeRoundingGain'
  | 'teamAnalyzeUserTimeBookings'
  | 'teamPlanning'
  | 'teamTasks'
  | 'timesheetsExternal'
  | 'trackTime'
  | 'workspaceBoards'
  | 'workTimes';

export interface HelpUiState {
  hiddenForPages: Partial<Record<PageHelpIdents, boolean>>;
}

export const initialHelpUiState: HelpUiState = {
  hiddenForPages: {},
};
