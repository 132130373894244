import {Trash} from 'lucide-react';
import {ReactElement} from 'react';
import {HStack} from '../../../Layout';
import {IconButton} from '../../Button';
import PickerViewFooter from './Components/PickerViewFooter';

export interface StandardDatePickerFooterProps {
  canClear?: boolean;
  clear: () => void;
  footerAddition?: ReactElement;
}

export default function StandardDatePickerFooter({
  canClear = true,
  clear,
  footerAddition,
}: StandardDatePickerFooterProps): ReactElement | null {
  if (canClear || footerAddition) {
    return (
      <PickerViewFooter>
        <HStack justifyContent={'space-between'}>
          <div>{footerAddition}</div>
          <HStack spacing={'10px'}>
            {canClear && (
              <IconButton variant={'ghost'} size={'sm'} icon={<Trash />} iconColor={'red'} onClick={clear} />
            )}
          </HStack>
        </HStack>
      </PickerViewFooter>
    );
  }

  return null;
}
