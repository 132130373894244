import {Uuid} from '@octaved/typescript/src/lib';
import {Reducer} from 'react';
import {Nodes} from '../EntityInterfaces/Nodes';

interface WithSortOrder {
  sortOrder: number;
}

interface IsWithSortOrder<N extends WithSortOrder> {
  (entity: unknown): entity is N;
}

interface SortedSiblingIdsAction {
  sortedSiblingIds: Uuid[] | null | undefined;
}

export function reduceSortedSiblingNodeIds<Sibling extends WithSortOrder>(
  isWithSortOrder: IsWithSortOrder<Sibling>,
): Reducer<Nodes, SortedSiblingIdsAction> {
  return (state, {sortedSiblingIds}) => {
    if (sortedSiblingIds) {
      let changed = false;
      const newState = {...state};
      sortedSiblingIds.forEach((siblingId, index) => {
        const entity = newState[siblingId];
        if (isWithSortOrder(entity) && entity.sortOrder !== index) {
          newState[siblingId] = {...entity, sortOrder: index};
          changed = true;
        }
      });
      return changed ? newState : state;
    }
    return state;
  };
}
