import {ActionDispatcher} from '@octaved/store/src/Store';
import {Selector} from '@octaved/typescript/src/lib';
import {FlexItem, HStack} from '@octaved/ui';
import {ReactElement} from 'react';
import FlowOutlet from '../../Components/FlowOutlet';
import PageLockWrapper from '../../Components/PageLock/PageLockWrapper';
import {FlowState} from '../../Modules/State';
import {PagesSubMenuOption} from '../PagesSubMenu';
import SubMenu from '../Projects/SubMenu';

interface MenuPageProps {
  collapsedSelector?: Selector<boolean>;
  menuOptions: PagesSubMenuOption[];
  patchUi?: (data: {collapsedSubMenu: boolean}) => ActionDispatcher<void, FlowState>;
}

export default function MenuPage({collapsedSelector, menuOptions, patchUi}: MenuPageProps): ReactElement {
  return (
    <HStack width={'100%'} overflow={'hidden'} height={'100%'} alignItems={'stretch'}>
      <FlexItem flexShrink={0} height={'100%'}>
        <PageLockWrapper>
          <SubMenu menuOptions={menuOptions} collapsedSelector={collapsedSelector} patchUi={patchUi} />
        </PageLockWrapper>
      </FlexItem>
      <FlexItem flexGrow={1} overflow={'hidden'} width={'100%'}>
        <FlowOutlet />
      </FlexItem>
    </HStack>
  );
}
