import {ReactElement} from 'react';
import TaskHistory from '../../../Components/Fields/History/NodeType/Task/TaskHistory';
import InspectorViewFrame from '../../InspectorViewFrame';
import {TaskDetailsProps} from './General';

export default function History({node}: TaskDetailsProps): ReactElement {
  return (
    <InspectorViewFrame>
      <div className={'flex w-full flex-col items-stretch'}>
        <TaskHistory nodeId={node.id} />
      </div>
    </InspectorViewFrame>
  );
}
