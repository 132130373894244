import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {useWebsiteLanguageFromContext, websiteLinks} from '../../WebsiteLinks';
import SectionHeader from './SectionHeader';

const tutorials = [
  {
    description: 'onboarding:octavedFlowHelpPopup.tutorialsOnWebsite.menuItemsAndPurposeInfo',
    link: websiteLinks.menuItemsAndPurpose,
    name: 'onboarding:octavedFlowHelpPopup.tutorialsOnWebsite.menuItemsAndPurpose',
  },
  {
    description: 'onboarding:octavedFlowHelpPopup.tutorialsOnWebsite.moreTutorialsInfo',
    link: websiteLinks.tutorials,
    name: 'onboarding:octavedFlowHelpPopup.tutorialsOnWebsite.moreTutorials',
  },
];

export default function WebsiteTutorials(): ReactElement {
  const websiteLanguage = useWebsiteLanguageFromContext();
  return (
    <>
      <SectionHeader color={'orange'} header={'onboarding:octavedFlowHelpPopup.tutorialsOnWebsite.header'} />

      <div className={'tutorials'}>
        {tutorials.map(({description, link, name}) => (
          <a href={link[websiteLanguage]} target={'_blank'} rel={'noreferrer'} className={'tutorial'} key={name}>
            <div className={'tutorialDetails'}>
              <div className={'tutorialTitle'}>
                <span>
                  <Trans i18nKey={name} />
                </span>
              </div>
              <div className={'tutorialDescription'}>
                <span>
                  <Trans i18nKey={description} />
                </span>
              </div>
            </div>
          </a>
        ))}

        {/*#region styles*/}
        {/*language=scss*/}
        <style jsx>{`
          .tutorials {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            grid-gap: 10px;
          }

          .tutorial {
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            grid-gap: 20px;
            background: rgb(246, 246, 246);
            border: 1px solid rgb(220, 217, 215);
            padding: 10px 12px;
            border-radius: 6px;
            cursor: pointer;
            transition: transform 120ms ease-in-out;

            &:hover {
              transform: scale(1.025);
            }
          }

          .tutorialDetails {
            flex: 1;
          }

          .tutorialTitle {
            font-weight: 600;
            font-size: 16px;
            color: #333;
            margin-bottom: 6px;
          }

          .tutorialDescription {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
          }

          .openTutorial {
            flex-shrink: 0;
            width: 150px;
            text-align: right;
          }
        `}</style>
        {/*#endregion*/}
      </div>
    </>
  );
}
