import {Uuid} from '@octaved/typescript/src/lib';
import {CheckCircle} from 'lucide-react';
import {ReactElement, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Message} from 'semantic-ui-react';
import {WorkPackagePatchData} from '../../../EntityInterfaces/Pid';
import {useWorkPackage} from '../../../Modules/Hooks/Nodes';
import {useTimeRecordSearch} from '../../../Modules/Hooks/TimeRecordSearch';
import {priceCategoryEntitiesSelector} from '../../../Modules/Selectors/PriceCategorySelectors';
import ConfirmDialog from '../../Dialog/ConfirmDialog';

export type ConfirmPerTimeTracking = Pick<WorkPackagePatchData, 'priceCategory' | 'usePriceCategoryPerTimeTracking'>;

export default function PerTimeTrackingConfirmDialog({
  close,
  patch,
  toPatch,
  workPackageId,
}: {
  close: () => void;
  patch: (pid: Partial<WorkPackagePatchData>) => void;
  toPatch: ConfirmPerTimeTracking;
  workPackageId: Uuid;
}): ReactElement | null {
  const {t} = useTranslation();
  const original = useWorkPackage(workPackageId);
  const orgPriceCat = useSelector(priceCategoryEntitiesSelector)[original?.priceCategory || ''];

  const {ids, isLoading} = useTimeRecordSearch('workPackageId', workPackageId, true, true);

  useEffect(() => {
    if (!isLoading && !ids.length) {
      patch(toPatch);
      close();
    }
  }, [close, ids.length, isLoading, patch, toPatch]);

  if (isLoading || !ids.length) {
    return null;
  }

  return (
    <ConfirmDialog
      title={
        toPatch.usePriceCategoryPerTimeTracking
          ? 'workPackage:priceCategorySelection.confirm.titleEnable'
          : 'workPackage:priceCategorySelection.confirm.titleDisable'
      }
      buttonLabel={'workPackage:priceCategorySelection.confirm.confirmButton'}
      buttonIcon={<CheckCircle />}
      cancelCallback={close}
      submitCallback={() => {
        patch(toPatch);
        close();
      }}
    >
      <span>{t('workPackage:priceCategorySelection.confirm.workPackageHasTimeRecords')}</span>

      {toPatch.usePriceCategoryPerTimeTracking && (
        <>
          {!orgPriceCat && (
            <Message visible negative>
              <span>{t('workPackage:priceCategorySelection.confirm.timeRecordPriceCategoriesMissing')}</span>
            </Message>
          )}
          {!!orgPriceCat && (
            <Message visible warning>
              <span>
                {t('workPackage:priceCategorySelection.confirm.timeRecordPriceCategoriesSet', {
                  priceCategory: orgPriceCat.name,
                })}
              </span>
            </Message>
          )}
        </>
      )}

      {!toPatch.usePriceCategoryPerTimeTracking && (
        <Message visible warning>
          <span>{t('workPackage:priceCategorySelection.confirm.timeRecordPriceCategoriesRemoved')}</span>
        </Message>
      )}
    </ConfirmDialog>
  );
}
