import {HStack, Label} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import Link from '../../Components/Link';
import StringPath from '../../Components/Node/StringPath';
import {Group, Project} from '../../EntityInterfaces/Pid';

interface Props {
  canEditLocation: boolean;
  edit: () => void;
  parent: Project | Group;
}

export default function GroupLocation({canEditLocation, edit, parent}: Props): ReactElement {
  return (
    <div>
      <Spacer />
      <HStack justifyContent={'space-between'}>
        <Label token={'dialogs:createGroup.location.label'} />
        {canEditLocation && (
          <Link onClick={edit}>
            <Trans i18nKey={'dialogs:createGroup.location.edit'} />
          </Link>
        )}
      </HStack>
      <div>
        <StringPath nodeId={parent.id} inline includeLastDivider includeCustomer />
        <Trans i18nKey={'dialogs:createGroup.location.thisNewGroup'} />
      </div>
    </div>
  );
}
