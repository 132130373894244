import {useDaysInsteadOfHours} from '@octaved/flow/src/Components/DaysOrHoursContext';
import {roundToHalfDays} from '@octaved/flow/src/Formatter/Decimal';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface HoursOfTotalProps {
  hours: number;
  shortUnit?: boolean; //if true, "h"/"d" is used instead of "hours"/"days"
  total: number;
}

export default function HoursOfTotal({hours, shortUnit, total}: HoursOfTotalProps): ReactElement {
  const {t} = useTranslation();
  const showDays = useDaysInsteadOfHours();
  let n = hours;
  let count = total;
  let token = 'general:ranges.nOfCountHours';
  let unitString = 'h';
  if (showDays) {
    n = roundToHalfDays(hours / 8);
    count = roundToHalfDays(total / 8);
    token = 'general:ranges.nOfCountDays';
    unitString = 'd';
  }
  return (
    <div className={'text-right'}>
      {shortUnit && t('general:ranges.nOfM', {n: formatDecimal(n), m: formatDecimal(count)}) + ` ${unitString}`}
      {!shortUnit && t(token, {count, n: formatDecimal(n), countFormatted: formatDecimal(count)})}
    </div>
  );
}
