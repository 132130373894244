import {useLoadPlanningDates} from '@octaved/planning/src/Modules/PlanningDates';
import {getMinMaxPlanningDatesSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {useFormattedDateTextRange} from '../../../Hooks/FormattedDateText';
import {FlowState} from '../../../Modules/State';

interface Props {
  id: Uuid;
}

export default function Planning({id}: Props): ReactElement | null {
  const formatDate = useFormattedDateTextRange();
  const {plannedEnd, plannedStart} = useSelector((s: FlowState) => getMinMaxPlanningDatesSelector(s)(id));
  useLoadPlanningDates(id);
  if (plannedStart && plannedEnd) {
    return <>{formatDate(isoDateTimeToIsoFormat(plannedStart), isoDateTimeToIsoFormat(plannedEnd))}</>;
  }

  return <div />;
}
