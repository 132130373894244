import * as Logger from '@octaved/env/src/Logger';
import {Dispatch} from '@octaved/store/src/Store';
import {omit} from 'lodash';
import {normalize} from 'normalizr';
import {isAction, UnknownAction} from 'redux';
import * as Network from './Network';
import NetworkError from './NetworkError';
import {handleMediaFileQuotaExceeded} from './NetworkErrorListeners/MediaFileQuotaExceeded';
import {
  CALL_API,
  CallAction,
  NETWORK_FAILURE,
  NETWORK_REQUEST,
  NETWORK_SUCCESS,
  NetworkType,
  ServerResponseAction,
} from './NetworkMiddlewareTypes';

export const activeRequest = new Map();

export default function networkMiddleware() {
  return function (next: Dispatch) {
    return function (action: unknown): Promise<unknown> {
      if (isAction(action) && !(CALL_API in action && typeof action[CALL_API] === 'undefined')) {
        return next(action);
      }

      return executeNetworkMiddlewareFetch(action as CallAction, next);
    };
  };
}

export function executeNetworkMiddlewareFetch(action: CallAction, next: Dispatch): Promise<unknown> {
  const callAPI = action[CALL_API];
  const {
    _debounceSimultaneousCalls,
    endpoint,
    schema,
    types,
    headers = Network.getDefaultHeader(),
    options = {},
    method = 'get',
  } = callAPI;
  if (typeof types !== 'object') {
    throw new Error('Types are not given');
  }
  const {requestType, successType, failureType} = types;
  const endPointUrl = endpoint;

  validateParameter(endPointUrl, requestType, successType, failureType);

  const hash = generateRequestHash({
    endPointUrl,
    headers,
    method,
    options,
    types,
  });

  if (_debounceSimultaneousCalls && activeRequest.has(hash) && method === 'get') {
    return activeRequest.get(hash);
  }

  const requestTime = Date.now();

  function actionWith(data: UnknownAction & {networkType: NetworkType}): ServerResponseAction {
    return {
      method,
      options,
      requestTime,
      endpoint: endPointUrl,
      ...omit(action, CALL_API),
      ...data,
    };
  }

  next(actionWith({networkType: NETWORK_REQUEST, type: requestType}));

  const request = Network[method](endPointUrl, options, headers).then(
    (response) => {
      activeRequest.delete(hash);
      const normalizedResponse = schema ? normalize(response, schema) : response;
      next(actionWith({networkType: NETWORK_SUCCESS, response: normalizedResponse, type: successType}));
      return normalizedResponse;
    },
    (error) => handleErrorResponse(error, next, actionWith, failureType, callAPI.throwNetworkError),
  );
  activeRequest.set(hash, request);
  return request;
}

const networkErrorListeners: Array<(err: NetworkError) => void> = [handleMediaFileQuotaExceeded];

async function handleErrorResponse(
  error: Response | Error,
  next: Dispatch,
  actionWith: (data: UnknownAction & {networkType: NetworkType}) => ServerResponseAction,
  failureType: string,
  throwNetworkError?: boolean,
): Promise<NetworkError> {
  if (error instanceof Error) {
    //Network error, timeout etc.:
    next(
      actionWith({
        error,
        networkType: NETWORK_FAILURE,
        response: error.stack,
        type: failureType,
      }),
    );
    throw error;
  }
  //Server error - response code >= 400:
  const respType = error.headers.get('Content-Type')!;
  const isJson = respType.indexOf('application/json') !== -1 || respType.indexOf('application/javascript') !== -1;
  let responseData;
  try {
    responseData = isJson ? await error.json() : await error.text();
  } catch (e) {
    responseData = e;
  }
  next(
    actionWith({
      networkType: NETWORK_FAILURE,
      response: responseData,
      responseStatus: error.status,
      type: failureType,
    }),
  );
  const networkError = new NetworkError(responseData, error);
  networkErrorListeners.forEach((listener) => {
    try {
      listener(networkError);
    } catch (e) {
      Logger.error(e);
    }
  });
  if (throwNetworkError) {
    throw networkError;
  } else {
    return networkError;
  }
}

function validateParameter(endpoint: unknown, requestType: unknown, successType: unknown, failureType: unknown): void {
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (
    typeof requestType !== 'string' ||
    !(typeof successType === 'string' || Array.isArray(successType)) ||
    typeof failureType !== 'string'
  ) {
    throw new Error('Expected action types to be strings.');
  }
}

function generateRequestHash(object: Record<string, unknown>): string {
  return JSON.stringify(object);
}

export function handleResponseStatus(
  statuses: number[],
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  callback: Function,
): (res: ServerResponseAction) => ServerResponseAction {
  return (res) => {
    if (res && statuses.includes(res.responseStatus!)) {
      return callback(res.response);
    }
    return res;
  };
}
