import {createContext, ReactElement, ReactNode, useState} from 'react';

interface PageLockContext {
  isLocked: boolean;
  setLocked: (locked: boolean) => void;
}

export const pageLockContext = createContext<PageLockContext>({
  isLocked: false,
  setLocked: () => false,
});

interface Props {
  children: ReactNode;
}

export function PageLockContextProvider({children}: Props): ReactElement {
  const [locked, setLocked] = useState(false);
  return <pageLockContext.Provider value={{isLocked: locked, setLocked}}>{children}</pageLockContext.Provider>;
}
