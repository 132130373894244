import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton, IconColor, StopClickPropagation, Tooltip} from '@octaved/ui';
import {Airplay} from 'lucide-react';
import {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {useInspectorId} from '../../Routing/Routes/Inspectors/StandardInspector';

interface Props {
  iconColor?: IconColor;
  id: Uuid;
  inline?: boolean;
  rectangle?: boolean;
}

export default function OpenInspector({iconColor = 'darkGrey', id}: Props): ReactElement {
  const inspectId = useInspectorId();
  return (
    <StopClickPropagation>
      <Link to={inspectId !== id ? id : '.'}>
        <Tooltip toolTipTranslation={'general:showDetails'}>
          <IconButton variant={'ghost'} icon={<Airplay rotate={'90deg'} />} iconColor={iconColor} size={'xs'} />
        </Tooltip>
      </Link>
    </StopClickPropagation>
  );
}
