import {ReactElement} from 'react';
import {Link, LinkProps} from 'react-router-dom';

interface DeactivatableLinkProps extends LinkProps {
  disabled?: boolean;
}

export default function DeactivatableLink({disabled, children, ...linkProps}: DeactivatableLinkProps): ReactElement {
  if (disabled) {
    return <>{children}</>;
  }
  return <Link {...linkProps}>{children}</Link>;
}
