import {createSelector} from 'reselect';
import {PlanningState} from '../PlanningState';

// noinspection FunctionNamingConventionJS
function ui<R>(selector: (state: PlanningState['ui']['pages']['teamPlanning']) => R): (rootState: PlanningState) => R {
  return (rootState) => selector(rootState.ui.pages.teamPlanning);
}

export const teamPlanningExtendedNodesSelector = ui((state) => state.extendedNodes);
export const teamPlanningSelectedUnitsSelector = ui((state) => state.selectedUnits);
export const teamPlanningSelectedUnitIdsSelector = createSelector(
  teamPlanningSelectedUnitsSelector,
  (units) => new Set(units.map(({unitId}) => unitId)),
);

export const teamPlanningZoomSelector = ui((state) => state.ganttZoom);
export const teamPlanningHoveringNodeIdSelector = ui((state) => state.hoveringNodeId);
export const teamPlanningShowNodeInfoSelector = ui((state) => state.showHoveringNodeInfo);
