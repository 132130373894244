import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition, NodeSearchWithoutValue} from '../../EntityInterfaces/NodeSearch';

/**
 * Boolean conditions are inversed with a {not: [...]} rule, but those can only work if they have a base set of ids
 * to diff from. This method takes all node ids of a specific nodeType as basis for the negation.
 */
export function createBooleanNodeTypeSearchQueryGenerator(
  searchIdent: keyof NodeSearchWithoutValue,
  nodeType: EnumFlowNodeType,
  searchIsPositive: boolean,
): (value: boolean) => NodeSearchCondition | null {
  return (value) => {
    if ((value && searchIsPositive) || (!value && !searchIsPositive)) {
      return [searchIdent];
    }
    return {and: [['nodeType', nodeType], {not: [searchIdent]}]};
  };
}
