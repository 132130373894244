export const FLOW_GET_WORKLOAD_FAILURE = 'FLOW_GET_WORKLOAD_FAILURE';
export const FLOW_GET_WORKLOAD_REQUEST = 'FLOW_GET_WORKLOAD_REQUEST';
export const FLOW_GET_WORKLOAD_SUCCESS = 'FLOW_GET_WORKLOAD_SUCCESS';
export const FLOW_UPDATE_WORKLOAD = 'FLOW_UPDATE_WORKLOAD';

export const FLOW_GET_PLANNING_FOR_NODES_FAILURE = 'FLOW_GET_PLANNING_FOR_NODES_FAILURE';
export const FLOW_GET_PLANNING_FOR_NODES_REQUEST = 'FLOW_GET_PLANNING_FOR_NODES_REQUEST';
export const FLOW_GET_PLANNING_FOR_NODES_SUCCESS = 'FLOW_GET_PLANNING_FOR_NODES_SUCCESS';

export const FLOW_GET_MILESTONES_FAILURE = 'FLOW_GET_MILESTONES_FAILURE';
export const FLOW_GET_MILESTONES_REQUEST = 'FLOW_GET_MILESTONES_REQUEST';
export const FLOW_GET_MILESTONES_SUCCESS = 'FLOW_GET_MILESTONES_SUCCESS';
export const FLOW_GET_MILESTONES_START = 'FLOW_GET_MILESTONES_START';

export const FLOW_PATCH_PLANNING_FAILURE = 'FLOW_PATCH_PLANNING_FAILURE';
export const FLOW_PATCH_PLANNING_REQUEST = 'FLOW_PATCH_PLANNING_REQUEST';
export const FLOW_PATCH_PLANNING_SUCCESS = 'FLOW_PATCH_PLANNING_SUCCESS';

export const FLOW_GET_PLANNING_DATES_SUCCESS = 'FLOW_GET_PLANNING_DATES_SUCCESS';
export const FLOW_GET_PLANNING_DATES_START = 'FLOW_GET_PLANNING_DATES_START';

export const FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_START = 'FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_START';
export const FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_SUCCESS = 'FLOW_GET_MIN_MAX_PLANNING_DATE_IN_SUBTREES_SUCCESS';

export const FLOW_PLANNING_EXTENDED_NODES = 'FLOW_PLANNING_EXTENDED_NODES';

export const FLOW_GET_PLANNING_BASELINE_HISTORY_FAILURE = 'FLOW_GET_PLANNING_BASELINE_HISTORY_FAILURE';
export const FLOW_GET_PLANNING_BASELINE_HISTORY_REQUEST = 'FLOW_GET_PLANNING_BASELINE_HISTORY_REQUEST';
export const FLOW_GET_PLANNING_BASELINE_HISTORY_SUCCESS = 'FLOW_GET_PLANNING_BASELINE_HISTORY_SUCCESS';

export const FLOW_GET_PLANNING_BASELINE_FAILURE = 'FLOW_GET_PLANNING_BASELINE_FAILURE';
export const FLOW_GET_PLANNING_BASELINE_REQUEST = 'FLOW_GET_PLANNING_BASELINE_REQUEST';
export const FLOW_GET_PLANNING_BASELINE_SUCCESS = 'FLOW_GET_PLANNING_BASELINE_SUCCESS';

export const FLOW_CREATE_PLANNING_BASELINE_FAILURE = 'FLOW_CREATE_PLANNING_BASELINE_FAILURE';
export const FLOW_CREATE_PLANNING_BASELINE_REQUEST = 'FLOW_CREATE_PLANNING_BASELINE_REQUEST';
export const FLOW_CREATE_PLANNING_BASELINE_SUCCESS = 'FLOW_CREATE_PLANNING_BASELINE_SUCCESS';

export const FLOW_PATCH_PLANNING_BASELINE_FAILURE = 'FLOW_PATCH_PLANNING_BASELINE_FAILURE';
export const FLOW_PATCH_PLANNING_BASELINE_REQUEST = 'FLOW_PATCH_PLANNING_BASELINE_REQUEST';
export const FLOW_PATCH_PLANNING_BASELINE_SUCCESS = 'FLOW_PATCH_PLANNING_BASELINE_SUCCESS';

export const FLOW_REMOVE_PLANNING_BASELINE_FAILURE = 'FLOW_REMOVE_PLANNING_BASELINE_FAILURE';
export const FLOW_REMOVE_PLANNING_BASELINE_REQUEST = 'FLOW_REMOVE_PLANNING_BASELINE_REQUEST';
export const FLOW_REMOVE_PLANNING_BASELINE_SUCCESS = 'FLOW_REMOVE_PLANNING_BASELINE_SUCCESS';

export const FLOW_PLANNING_REINITIALIZE_SIMULATION = 'FLOW_PLANNING_REINITIALIZE_SIMULATION';
export const FLOW_PLANNING_RESET_SIMULATION_NODES = 'FLOW_PLANNING_RESET_SIMULATION_NODES';
