import {isDevLocal} from '@octaved/env/src/Environment';
import {isDateTimeStr} from './DateTimeStr';

export type TimeStrM = string & {__brand: 'TimeStrM'};
export type TimeStrS = string & {__brand: 'TimeStrS'};

export function isTimeStrM(date: string | null | undefined): date is TimeStrM {
  return !!date && date.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/) !== null;
}

export function isTimeStrS(date: string | null | undefined): date is TimeStrS {
  return !!date && date.match(/^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/) !== null;
}

function isTimeStrMAssertion(date: string | null | undefined): date is TimeStrM {
  return isDevLocal ? isTimeStrM(date) : !!date;
}

function isTimeStrSAssertion(date: string | null | undefined): date is TimeStrS {
  return isDevLocal ? isTimeStrS(date) : !!date;
}

export function asTimeStrM(date: string): TimeStrM;
export function asTimeStrM(date: null | undefined): null;
export function asTimeStrM(date: string | null | undefined): TimeStrM | null;
export function asTimeStrM(date: string | null | undefined): TimeStrM | null {
  if (isTimeStrMAssertion(date)) {
    return date;
  }
  if (isDevLocal && date) {
    throw new Error(`'${date}' is not a valid time`);
  }
  return null;
}

export function asTimeStrS(date: string): TimeStrS;
export function asTimeStrS(date: null | undefined): null;
export function asTimeStrS(date: string | null | undefined): TimeStrS | null;
export function asTimeStrS(date: string | null | undefined): TimeStrS | null {
  if (isTimeStrSAssertion(date)) {
    return date;
  }
  if (isDevLocal && date) {
    throw new Error(`'${date}' is not a valid time`);
  }
  return null;
}

export function toTimeStrM(date: string): TimeStrM;
export function toTimeStrM(date: null | undefined): null;
export function toTimeStrM(date: string | null | undefined): TimeStrM | null;
export function toTimeStrM(date: string | null | undefined): TimeStrM | null {
  if (isTimeStrM(date)) {
    return date;
  }
  if (isTimeStrS(date)) {
    return asTimeStrM(date.slice(0, 5));
  }
  if (isDateTimeStr(date)) {
    return asTimeStrM(date.slice(11, 16));
  }
  if (isDevLocal && date) {
    throw new Error(`'${date}' is not a valid time`);
  }
  return null;
}

export function toTimeStrS(date: string): TimeStrS;
export function toTimeStrS(date: null | undefined): null;
export function toTimeStrS(date: string | null | undefined): TimeStrS | null;
export function toTimeStrS(date: string | null | undefined): TimeStrS | null {
  if (isTimeStrS(date)) {
    return date;
  }
  if (isTimeStrM(date)) {
    return asTimeStrS(`${date}:00`);
  }
  if (isDateTimeStr(date)) {
    return asTimeStrS(date.slice(11));
  }
  if (isDevLocal && date) {
    throw new Error(`'${date}' is not a valid time`);
  }
  return null;
}
