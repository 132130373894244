import {Circle} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import DaysOrHoursContext from '../../../../Components/DaysOrHoursContext';
import {DropdownPopupItemType} from '../../../../Components/Form/Dropdown/DropdownPopup/Types';
import {
  ProjectsTreeComponentContext,
  projectsTreeComponentContext,
} from '../../../../Components/Tree/ProjectTreeComponentContext';
import {ProjectTreeOptions} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {projectControllingShowDaysInsteadHoursSelector} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useProjectUiStatePatch} from '../../Projects';
import ProjectsList from '../../ProjectsList';
import ProjectContentFrame, {ProjectContentFrameProps} from '../ProjectContentFrame';

interface Props extends Omit<ProjectContentFrameProps, 'children'> {
  treeComponents: ProjectsTreeComponentContext;
  treeOptions?: Partial<ProjectTreeOptions>;
  infoToken: string;
}

export function useMenuOptions(): ProjectContentFrameProps['menuOptions'] {
  const showDays = useSelector(projectControllingShowDaysInsteadHoursSelector);
  const patchProjectsUi = useProjectUiStatePatch();
  return useMemo((): ProjectContentFrameProps['menuOptions'] => {
    return {
      prepend: [
        {
          token: 'pages:projects.pages.controlCommon.menu.showTimesHeadline',
          type: DropdownPopupItemType.header,
        },
        {
          icon: <Circle fill={'currentColor'} strokeWidth={0} />,
          iconColor: showDays ? 'white' : 'black',
          iconSize: 'small',
          onMouseDown: () => patchProjectsUi({projectControlling: {showDaysInsteadHours: false}}),
          token: 'pages:projects.pages.controlCommon.menu.showTimesInHours',
          type: DropdownPopupItemType.item,
        },
        {
          icon: <Circle fill={'currentColor'} strokeWidth={0} />,
          iconColor: showDays ? 'black' : 'white',
          iconSize: 'small',
          onMouseDown: () => patchProjectsUi({projectControlling: {showDaysInsteadHours: true}}),
          token: 'pages:projects.pages.controlCommon.menu.showTimesInDays',
          type: DropdownPopupItemType.item,
        },
      ],
    };
  }, [patchProjectsUi, showDays]);
}

export default function ProjectControllingProjectsFrame({
  treeComponents,
  treeOptions,
  infoToken,
  ...rest
}: Props): ReactElement {
  const menuOptions = useMenuOptions();
  return (
    <DaysOrHoursContext selector={projectControllingShowDaysInsteadHoursSelector}>
      <ProjectContentFrame {...rest} menuOptions={menuOptions} infoToken={infoToken}>
        <projectsTreeComponentContext.Provider value={treeComponents}>
          <ProjectsList treeOptions={treeOptions} />
        </projectsTreeComponentContext.Provider>
      </ProjectContentFrame>
    </DaysOrHoursContext>
  );
}
