import Close from '@octaved/flow/src/Dialogs/Components/Close';
import {isTransformable, transformEffort} from '@octaved/flow/src/Modules/Hooks/EffortTransformer';
import {Popup, TWAutosaveInput, TWButton, TWLabel} from '@octaved/ui';
import {format, formatDynamicPrecision, parse} from '@octaved/users/src/Culture/NumberFormatter';
import {Undo} from 'lucide-react';
import {ReactElement, RefObject} from 'react';
import {useTranslation} from 'react-i18next';

interface CustomTaskEstimationProps {
  contextRef: RefObject<HTMLDivElement>;
  open: boolean;
  customHours: number | null;
  setCustomHours: (newValue: number | null) => void;
  close: () => void;
  toggleToPresets: () => void;
}

export default function CustomTaskEstimation({
  contextRef,
  customHours,
  setCustomHours,
  open,
  close,
  toggleToPresets,
}: CustomTaskEstimationProps): ReactElement {
  const {t} = useTranslation();

  return (
    <Popup open={open} context={contextRef} mountNode={contextRef.current} closeOnDocumentClick>
      <div className={'flex min-w-48 flex-col gap-y-1 px-3 py-2 pt-4'}>
        <div className={'absolute right-1 top-1 flex items-center gap-x-2'}>
          <TWButton size={'md'} iconOnly variant={'ghost'} onClick={toggleToPresets} withIcon>
            <Undo className={'size-4'} />
          </TWButton>
          <Close close={close} />
        </div>
        <TWLabel>{t('taskList:effort.hours')}</TWLabel>
        <div className={'w-numberInput'}>
          <TWAutosaveInput
            hasWarning={customHours === null}
            autoFocus
            value={
              customHours
                ? customHours % 1 !== 0
                  ? formatDynamicPrecision(customHours)
                  : format(customHours, 0) + ''
                : ''
            }
            onSave={(value) => {
              const transformed = (isTransformable(value) && transformEffort(value)) || parse(value);
              setCustomHours(value ? +transformed : null);
              close();
            }}
          />
        </div>
      </div>
    </Popup>
  );
}
