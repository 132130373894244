import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {useInspectorId} from '@octaved/hooks';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {IconButton, Tooltip} from '@octaved/ui';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import DeactivatableLink from '../../../Components/DeactivatableLink';
import {isGroup, isProject, isWorkPackage} from '../../../Node/NodeIdentifiers';
import {getNodeAncestrySelector} from '../../../Modules/Selectors/NodeTreeSelectors';
import {useGetNewWorkPackageDialogRoute} from '../../../Routing/Routes/Dialogs';
import {useProjectContext} from '../../Projects/ProjectContext';

export default function CreateWorkPackageButton(): ReactElement {
  const {page} = useProjectContext();
  const isGranted = useSelector(isGrantedSelector);
  const inspectorId = useInspectorId();
  const ancestry = useSelector(getNodeAncestrySelector)(inspectorId, true);
  const selectedNode = ancestry.ancestors[0];
  const selectedParent = ancestry.ancestors[1];
  const creationParent = isWorkPackage(selectedNode)
    ? selectedParent
    : isProject(selectedNode) || isGroup(selectedNode)
      ? selectedNode
      : null;
  const canCreateWorkPackage =
    !!creationParent && !creationParent.isArchived && isGranted('FLOW_NODE_PID_MANAGE_BASIC', creationParent.id);
  const rootGroup = ancestry.groups[ancestry.groups.length - 1];
  const isUserStory = rootGroup && rootGroup.groupType === EnumFlowGroupType.VALUE_SPRINT;
  const getNewWorkpackageDialogRoute = useGetNewWorkPackageDialogRoute();
  return (
    <DeactivatableLink
      disabled={!canCreateWorkPackage}
      to={getNewWorkpackageDialogRoute(selectedNode?.id, page === 'createOffer')}
    >
      <Tooltip disabled={canCreateWorkPackage} toolTipTranslation={'pages:projects.inspector.newWorkPackageTooltip'}>
        <div>
          <IconButton
            size={'sm'}
            colorScheme={'add'}
            icon={<PlusCircle />}
            label={isUserStory ? 'pages:projects.inspector.newUserStory' : 'pages:projects.inspector.newWorkPackage'}
            isDisabled={!canCreateWorkPackage}
          />
        </div>
      </Tooltip>
    </DeactivatableLink>
  );
}
