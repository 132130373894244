import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {nodeStatusColors} from '@octaved/flow/src/Node/NodeStatus/NodeStatusIcons';
import NOfMPie from '@octaved/flow/src/Pages/Projects/Pages/ControlCommon/Inspector/PieChart/NOfMPie';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeWorkPackagesArchivedProps {
  nodeId: Uuid;
  valueOverride?: Readonly<[number, number]>; //for demo mode
}

export function NodeWorkPackagesArchived({
  frame: Frame,
  nodeId,
  valueOverride,
}: NodeWorkPackagesArchivedProps & FramedSectionOrTileProps): ReactElement | null {
  const [{ids: archivedWpIds}, {ids: totalWpIds}] = useCombinedNodeSearches(
    {},
    valueOverride
      ? null
      : {
          and: [withDescendants(nodeId, true), ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], ['isArchived']],
        },
    valueOverride
      ? null
      : {
          and: [withDescendants(nodeId, true), ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE]],
        },
  );

  const archived = valueOverride?.[0] ?? archivedWpIds.length;
  const total = valueOverride?.[1] ?? totalWpIds.length;

  if (archived === 0) {
    return null;
  }

  return (
    <Frame labelToken={''}>
      <NOfMPie
        label={'nodeDetails:field.workPackagesArchived.label'}
        fillColor={nodeStatusColors.isOffer}
        n={archived}
        m={total}
        showPercentage
        showNOfM
      />
    </Frame>
  );
}
