import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import * as React from 'react';
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Loader} from 'semantic-ui-react';
import DefaultPlaceholder from '../../../../Components/ProjectTree/DefaultPlaceholder';
import Flat from '../../../../Components/ProjectTree/Flat';
import {ProjectTreeContextProvider} from '../../../../Components/ProjectTree/ProjectTreeContext';
import {SearchHighlightContext} from '../../../../Components/Search/SearchHighlight';
import {projectIsNotArchived} from '../../../../Modules/Projects/Filters/Generic';
import {
  ProjectTreeGroup,
  ProjectTreeNode,
  ProjectTreeOptions,
  ProjectTreeProject,
} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {
  projectsShowOnlyFavoriteProjectsSelector,
  projectsShowSubFolderProjectsSelector,
  projectsSortProjectsBySelector,
} from '../../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useCheckPlanningDependencyIssues} from '../Components/CheckPlanningDependencyIssues';
import {useCheckPriceCategories} from '../Components/CheckPriceCategories';
import {useCreateDnDTree} from '../Components/CreateTree';
import {projectDndContext} from '../Components/ProjectDndContext';
import {ProjectDragObject, saveDrag} from '../Components/ProjectSortable';
import {moveGroupOrWorkPackageContext} from './MoveGroupOrWorkPackageContext';

interface MoveGroupOrWorkPackageTreeProps {
  setHasChanges: React.Dispatch<SetStateAction<boolean>>;
  setPriceCategoryIssue: React.Dispatch<SetStateAction<boolean>>;
  setHasPlanningDependencyIssue: React.Dispatch<SetStateAction<boolean>>;
  pidId: Uuid;
  search: string;
  selectedCustomer: string;
  selectedProjectFolder: string;
}

export interface MoveGroupOrWorkPackageTreeRef {
  save(): Promise<void>;
}

const getExtraQueries = memoize((pidId: Uuid): ProjectTreeOptions['extraQueries'] => {
  return {
    projects: [
      {
        or: [
          withAncestors({fixResult: [pidId]}, true), //always show the pid that is being moved
          projectIsNotArchived,
        ],
      },
    ],
  };
});

export default React.forwardRef<MoveGroupOrWorkPackageTreeRef, MoveGroupOrWorkPackageTreeProps>(
  function MoveGroupOrWorkPackageTree(
    {
      search,
      setHasChanges,
      pidId,
      setPriceCategoryIssue,
      setHasPlanningDependencyIssue,
      selectedCustomer,
      selectedProjectFolder,
    },
    ref,
  ): ReactElement {
    const dropRef = useRef<ProjectDragObject | null>(null);
    const dispatch = useDispatch();
    const checkPriceCategories = useCheckPriceCategories(pidId);
    const checkPlanningDependencyIssues = useCheckPlanningDependencyIssues(pidId);
    const [draggedPid, setDraggedPid] = useState<ProjectTreeNode | undefined>(undefined);
    const {flat, hasLoadedOnce, isLoading, move, resetNodeTree, resetSortOrder, treeOptions, allNodes} =
      useCreateDnDTree(
        {
          extraQueries: getExtraQueries(pidId),
          includeCustomerId: selectedCustomer === 'all' ? null : selectedCustomer,
          includeSubProjectFolders: useSelector(projectsShowSubFolderProjectsSelector),
          projectFolder: selectedProjectFolder,
          searchTerm: search,
          showOnlyFavoriteProjects: useSelector(projectsShowOnlyFavoriteProjectsSelector),
          sortBy: useSelector(projectsSortProjectsBySelector),
        },
        draggedPid,
      );

    const moveGroupOrWorkPackage = useContext(moveGroupOrWorkPackageContext);
    moveGroupOrWorkPackage.getCurrentIndex = (parentId: Uuid, id: Uuid) => {
      if (allNodes.has(parentId)) {
        return (allNodes.get(parentId) as ProjectTreeProject | ProjectTreeGroup).children.findIndex(
          (child) => child.id === id,
        );
      }
      return -1;
    };

    useEffect(() => {
      if (allNodes.has(pidId)) {
        setDraggedPid(allNodes.get(pidId));
      }
    }, [allNodes, pidId]);

    const save = useCallback(async () => {
      if (dropRef.current) {
        await saveDrag(dropRef.current, allNodes, dispatch, resetSortOrder, resetNodeTree);
      }
    }, [allNodes, dispatch, resetSortOrder, resetNodeTree]);

    const onDrop = useCallback(
      (dragObject: ProjectDragObject) => {
        dropRef.current = dragObject;
        setHasChanges(true);
        const priceCategoriesAllowed = checkPriceCategories(dragObject.parent);
        setPriceCategoryIssue(!priceCategoriesAllowed);
        const hasPlanningDependencyIssue = checkPlanningDependencyIssues(dragObject.parent);
        setHasPlanningDependencyIssue(hasPlanningDependencyIssue);
      },
      [
        setHasChanges,
        checkPriceCategories,
        setPriceCategoryIssue,
        checkPlanningDependencyIssues,
        setHasPlanningDependencyIssue,
      ],
    );

    const onDragStart = useCallback(() => {
      setPriceCategoryIssue(false);
      setHasPlanningDependencyIssue(false);
    }, [setPriceCategoryIssue, setHasPlanningDependencyIssue]);

    useImperativeHandle(
      ref,
      () => ({
        save,
      }),
      [save],
    );

    return (
      <div className={'relative'}>
        <Loader active={!hasLoadedOnce} />
        {!isLoading && flat.length === 0 && <DefaultPlaceholder />}

        <projectDndContext.Provider value={{move, onDrop, onDragStart}}>
          <ProjectTreeContextProvider options={treeOptions}>
            <SearchHighlightContext searchTerm={treeOptions.searchTerm}>
              <Flat flat={flat} />
            </SearchHighlightContext>
          </ProjectTreeContextProvider>
        </projectDndContext.Provider>
        {/**#region css */}
        {/*language=SCSS*/}
        <style jsx>{`
          .relative {
            position: relative;
          }
        `}</style>
        {/**#endregion */}
      </div>
    );
  },
);
