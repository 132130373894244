import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {hasPriceCategory} from '@octaved/flow/src/Modules/Pid';
import {isCustomerBillableSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {
  getPriceCategoryDropdownOptionsSelector,
  showPriceCategorySelectionSelector,
} from '@octaved/flow/src/Modules/Selectors/PriceCategorySelectors';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';

const noDefault = 'noDefault';

export interface SubWorkPackagePriceCategoryProps {
  node: SubWorkPackage;
  readonly?: boolean;
  patch: (data: Partial<SubWorkPackage>) => void;
  workPackage: WorkPackage;
}

export default function SubWorkPackagePriceCategory({
  frame: Frame,
  node,
  workPackage,
  readonly,
  patch,
}: SubWorkPackagePriceCategoryProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const getPriceCategoryDropdownOptions = useSelector(getPriceCategoryDropdownOptionsSelector);
  const showPriceCategorySelection = useSelector(showPriceCategorySelectionSelector);
  const isCustomerBillable = useSelector(isCustomerBillableSelector)(workPackage.flowCustomer);
  const billingTypeNeedsPriceCategory = hasPriceCategory(workPackage);
  const priceCategoryIsRequired = isCustomerBillable && billingTypeNeedsPriceCategory;

  const options = useMemo(() => {
    const opts = getPriceCategoryDropdownOptions(workPackage.flowCustomer, node?.defaultPriceCategory || null).slice(0);
    opts.push({
      text: <span className={'italic'}>{t('nodeDetails:field.priceCategory.subWorkPackage.noDefault')}</span>,
      value: noDefault,
    });
    return opts;
  }, [getPriceCategoryDropdownOptions, t, node.defaultPriceCategory, workPackage.flowCustomer]);

  if (!priceCategoryIsRequired || !showPriceCategorySelection) {
    return null;
  }

  const selected = options.find((o) => o.value === node.defaultPriceCategory);
  const value = node.defaultPriceCategory || noDefault;

  return (
    <Frame labelToken={'nodeDetails:field.priceCategory.subWorkPackage.label'}>
      {readonly && <div>{selected?.text || t('workPackage:priceCategorySelection.priceCategoryPerTimeTracking')}</div>}
      {!readonly && (
        <TWSelect
          value={value}
          onValueChange={(priceCategory) => {
            patch({defaultPriceCategory: priceCategory === noDefault ? null : priceCategory});
          }}
        >
          <TWSelectTrigger>
            <TWSelectValue placeholder={t('nodeDetails:field.priceCategory.placeholder')} />
          </TWSelectTrigger>
          <TWSelectContent>
            {options.map(({value, text}) => (
              <TWSelectItem key={value} value={value}>
                {text}
              </TWSelectItem>
            ))}
          </TWSelectContent>
        </TWSelect>
      )}
    </Frame>
  );
}
