import {isDebug} from '@octaved/env/src/Environment';

const _proxy = Symbol('proxy');
const _proxyTarget = Symbol('proxyTarget');

/**
 * dummy function for the proxy to throw exceptions when the object is modified
 */
function nope() {
  throw new Error("can't modify read-only view");
}

const handler = {
  defineProperty: (target, propKey, descriptor) => {
    if (propKey === Symbol.iterator) {
      Reflect.defineProperty(target, propKey, descriptor);
      return true;
    }

    return false;
  },
  deleteProperty: nope,
  get: (target, key, receiver) => {
    // Start by just doing the default behavior.
    const result = Reflect.get(target, key, receiver);
    const config = Object.getOwnPropertyDescriptor(target, key); // Make sure not to return a mutable object!

    if (config && config.writable && key !== _proxy && Object(result) === result) {
      // result is an object.
      return readonly(result);
    } // result is a primitive, so already immutable.

    return result;
  },
  preventExtensions: nope,
  // Override all five mutating methods.
  set: (target, propKey, value, receiver) => {
    if (propKey === Symbol.iterator) {
      return Reflect.set(target, propKey, value, receiver);
    }

    return nope();
  },
  setPrototypeOf: nope,
};

/**
 * creates a proxy around the object in debug mode
 * @param {Object} target
 * @return {Object}
 */
export function readonly(target) {
  if (isDebug) {
    if (target[_proxy] && target[_proxyTarget] === target) {
      return target[_proxy];
    }
    const readonlyObject = new Proxy(target, handler);
    target[_proxy] = readonlyObject;
    target[_proxyTarget] = target;
    return readonlyObject;
  }
  /* istanbul ignore next */
  return target;
}
