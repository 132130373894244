import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {Label} from '../../EntityInterfaces/Labels';
import {labelEntitiesSelector} from '../../Modules/Selectors/LabelSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface ILabelInspectorContext extends BaseInspectorContext {
  selectedEntity: Label;
  selectedType: 'label';
}

function isLabelInspectorContext(context: InspectorContext | null): context is ILabelInspectorContext {
  return context?.selectedType === 'label';
}

export function useLabelInspectorContext(): ILabelInspectorContext {
  const context = useInspectorContext();
  if (!isLabelInspectorContext(context)) {
    throw new Error('is not label inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): ILabelInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector(labelEntitiesSelector)[baseParams!.selectedId];

  return useMemo<ILabelInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'label',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function LabelInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
