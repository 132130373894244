import {FlowState} from '@octaved/flow/src/Modules/State';
import {OrganizationState} from '../EntityInterfaces/OrganizationState';

export const organizationSelector = (state: FlowState): OrganizationState | null => state.currentOrganization;

export const requiredOrganizationSelector = (state: FlowState): OrganizationState => {
  const org = state.currentOrganization;
  if (!org) {
    throw new Error('Organization unknown at this point!');
  }
  return org;
};

// noinspection FunctionNamingConventionJS
function org<R>(selector: (state: OrganizationState) => R): (rootState: FlowState) => R {
  return (rootState) => selector(requiredOrganizationSelector(rootState));
}

export const allOrgUserGroupIdSelector = org((s) => s.allOrgUserGroupId);
export const organizationCreatedOnSelector = org((s) => s.createdOn);
export const organizationIdSelector = org((s) => s.id);
export const organizationLanuguageSelector = org((s) => s.language);
export const organizationLicenseSelector = org((s) => s.license);
