import {DropTargetMonitor, XYCoord} from 'react-dnd';

function hasMovedX(hoverBoundingRect: DOMRect, clientOffset: XYCoord | null, borderX: number): boolean {
  const x = clientOffset?.x || 0;
  return hoverBoundingRect.left + borderX < x && hoverBoundingRect.right - borderX > x;
}

function hasMovedY(hoverBoundingRect: DOMRect, clientOffset: XYCoord | null, borderY: number): boolean {
  const y = clientOffset?.y || 0;
  return hoverBoundingRect.top + borderY < y && hoverBoundingRect.bottom - borderY > y;
}

export function hasMovedSufficientlyX(hoverElement: HTMLElement, monitor: DropTargetMonitor, borderX = 0): boolean {
  return hasMovedX(hoverElement.getBoundingClientRect(), monitor.getClientOffset(), borderX);
}

export function hasMovedSufficientlyY(hoverElement: HTMLElement, monitor: DropTargetMonitor, borderY = 0): boolean {
  return hasMovedY(hoverElement.getBoundingClientRect(), monitor.getClientOffset(), borderY);
}

export function hasMovedSufficientlyXY(
  hoverElement: HTMLElement,
  monitor: DropTargetMonitor,
  borderX = 0,
  borderY = 0,
): boolean {
  const hoverBoundingRect = hoverElement.getBoundingClientRect();
  const clientOffset = monitor.getClientOffset();
  return hasMovedX(hoverBoundingRect, clientOffset, borderX) && hasMovedY(hoverBoundingRect, clientOffset, borderY);
}
