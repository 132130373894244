import {IconNode, createLucideIcon} from 'lucide-react';
import {SVGIconNode, defaultAttributes} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z',
      key: '1',
      strokeWidth: '1.5',
    },
  ],
];

export const TaskIconSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const TaskIcon = createLucideIcon('TaskIcon', iconDrawing);
