import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useLoadPlanningDependencies} from '@octaved/planning/src/Components/PlanningRealization/LoadPlanningDependencies';
import {
  PlanningType,
  usePlanningType,
} from '@octaved/planning/src/Components/PlanningRealization/PlanningType/PlanningType';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Message} from '@octaved/ui';
import {Loader} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import WorkPackageSprintInfoSection from '../../../Components/Fields/General/NodeType/WorkPackage/Section/WorkPackageSprintInfoSection';
import NodeParentWorkPackagePlanningMessage from '../../../Components/Fields/Planning/Common/Components/NodeParentWorkPackagePlanningMessage';
import NodeDependencySection from '../../../Components/Fields/Planning/Section/NodeDependencySection';
import NodeDueDatesInParentsSection from '../../../Components/Fields/Planning/Section/NodeDueDatesInParentsSection';
import NodeLogicalDependenciesSection from '../../../Components/Fields/Planning/Section/NodeLogicalDependenciesSection';
import NodeMaterialResourcesSection from '../../../Components/Fields/Planning/Section/NodeMaterialResourcesSection';
import NodeParentDependencyTypeSection from '../../../Components/Fields/Planning/Section/NodeParentDependencyTypeSection';
import NodePlanningOptionSection from '../../../Components/Fields/Planning/Section/NodePlanningOptionSection';
import NodePlanningStatusSection from '../../../Components/Fields/Planning/Section/NodePlanningStatusSection';
import NodeSuccessorsSection from '../../../Components/Fields/Planning/Section/NodeSuccessorsSection';
import NodeTimePeriodSection from '../../../Components/Fields/Planning/Section/NodeTimePeriodSection';
import {DeletePlanning} from '../../Components/DeletePlanning';
import InspectorViewFrame from '../../InspectorViewFrame';
import {SubWorkPackageDetailsProps} from './General';

export default function Planning({node}: SubWorkPackageDetailsProps): ReactElement {
  const {t} = useTranslation();
  const optionals = useOptionalFields(node.id, {
    successors: false,
  });
  const hasLoadedOnce = useLoadPlanningDependencies(node.id);
  const readonly = !useIsGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', node.id) || node.isArchived;

  const rootGroupType = useSelector(getRootGroupTypeSelector)(node.id);
  const isUserStory = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
  const isBacklog = rootGroupType === EnumFlowGroupType.VALUE_BACKLOG;
  const canPlan = !isUserStory && !isBacklog;
  const [planningType, setPlanningType] = usePlanningType(node);

  return (
    <InspectorViewFrame
      help={'nodeDetails:view.planning.help'}
      footer={
        <div className={'flex justify-center gap-x-2'}>
          <EnableFieldButton field={optionals.successors} label={'nodeDetails:field.successors.enable'} tooltip={''} />
          {!readonly &&
          canPlan &&
          (planningType !== PlanningType.none || node.planningLogicalPredecessors.length > 0) ? (
            <DeletePlanning node={node} planningType={planningType} setPlanningType={setPlanningType} />
          ) : undefined}
        </div>
      }
    >
      {!hasLoadedOnce && (
        <div className={'absolute inset-0 flex items-center justify-center bg-white/50'}>
          <Loader />
        </div>
      )}

      <NodePlanningStatusSection node={node} />

      <NodePlanningOptionSection
        node={node}
        readonly={!canPlan || readonly}
        planningType={planningType}
        setPlanningType={setPlanningType}
      />

      {planningType === PlanningType.none && <NodeParentWorkPackagePlanningMessage node={node} />}

      {planningType === PlanningType.dependency && (
        <NodeDependencySection node={node} readonly={!canPlan || readonly} />
      )}
      {planningType !== PlanningType.none && (
        <NodeTimePeriodSection
          forceRelativeToParent={planningType === PlanningType.relativeToParent}
          node={node}
          readonly={!canPlan || readonly}
        />
      )}
      {planningType === PlanningType.relativeToParent && <NodeParentDependencyTypeSection node={node} />}

      <NodeLogicalDependenciesSection node={node} readonly={!canPlan || readonly} />

      {optionals.successors.show && <NodeSuccessorsSection node={node} />}
      <NodeDueDatesInParentsSection node={node} readonly={readonly} />
      <NodeMaterialResourcesSection node={node} />
      <WorkPackageSprintInfoSection id={node.id} />

      {isUserStory && <Message colorScheme={'slate'}>{t('pages:planning.inspector.userStoryWorkPackageInfo')}</Message>}
    </InspectorViewFrame>
  );
}
