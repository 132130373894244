import {ArrowLeftFromLine, Check, Columns2} from 'lucide';
import {GhostButton} from '../../../CommonComponents/GhostButton';
import {Menu, MenuItem, MenuItems} from '../../../CommonComponents/Menu';
import {GanttContext} from '../../Context/GanttContext';
import {Cell} from './Cell';

export function createMenuItem(ctx: GanttContext, columnName: string, token: string): MenuItem {
  return {
    token,
    icon: Check,
    iconColor: ctx.visibleColumns.includes(columnName) ? '#219653' : '#d1d7e080',
    onClick: () => {
      ctx.setVisibleColumns(
        ctx.visibleColumns.includes(columnName)
          ? ctx.visibleColumns.filter((c) => c !== columnName)
          : ctx.visibleColumns.concat(columnName),
      );
    },
  };
}

export class HeaderMenuCell extends Cell {
  readonly buttonSize = 24;
  protected menu: Menu | null = null;

  #menuButton: GhostButton | null = null;
  #toggleButton: GhostButton | null = null;

  init(): void {
    super.init();
    const menuButton = this.#createMenuButton();
    this.#menuButton = menuButton;
    this.root.add(menuButton.root);
    menuButton.init();

    const toggleButton = this.#createToggleButton();
    this.#toggleButton = toggleButton;
    this.root.add(toggleButton.root);
    toggleButton.init();

    this.render();
    this.render.flush();
  }

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;

    if (!isVisble) {
      return;
    }

    this.root.x(x + width - this.buttonSize * 2 - this.padding * 2);
    this.#toggleButton?.root.x(this.buttonSize + this.padding);
    this.#menuButton?.root.x(0);
    this.#menuButton?.root.visible(this.getMenuItems().length > 0);
  }

  #createMenuButton(): GhostButton {
    const button = new GhostButton({
      buttonSize: this.buttonSize,
      ctx: this.ctx,
      icon: Columns2,
      imageSize: this.imageSize,
      onClick: (e) => {
        if (this.menuRoot?.parent) {
          const {x, y} = e.target.getClientRect({relativeTo: this.menuRoot.parent});
          this.menu?.root.x(x + 12);
          this.menu?.root.y(y + this.buttonSize);
          this.menu?.toggle(this.getMenuItems());
        }
      },
    });
    button.init();
    const y = (this.height - this.imageSize) * 0.5;
    this.menu = new Menu({ctx: this.ctx, outsideClickRoot: button.root});
    this.disposables.push(() => this.menu?.dispose());

    this.menuRoot?.add(this.menu.root);

    button.root.y(y);
    this.disposables.push(() => button.dispose());
    return button;
  }

  #createToggleButton(): GhostButton {
    const imageSize = this.imageSize + 2;
    const y = (this.height - imageSize) * 0.5;

    const button = new GhostButton({
      buttonSize: this.buttonSize,
      ctx: this.ctx,
      icon: ArrowLeftFromLine,
      imageSize: this.imageSize,
      onClick: () => {
        if (!this.ctx.visibleColumns.includes('hideAll')) {
          this.ctx.setVisibleColumns([...this.ctx.visibleColumns, 'hideAll']);
        }
      },
    });
    button.root.y(y);
    this.disposables.push(() => button.dispose());
    button.init();
    return button;
  }

  protected getMenuItems(): MenuItems {
    return [
      createMenuItem(this.ctx, 'customer', 'general:customer'),
      createMenuItem(this.ctx, 'type', 'pages:planning.ganttChart.type'),
      createMenuItem(this.ctx, 'plannedStart', 'pages:planning.ganttChart.start'),
      createMenuItem(this.ctx, 'plannedEnd', 'pages:planning.ganttChart.end'),
      createMenuItem(this.ctx, 'duration', 'pages:planning.ganttChart.duration'),
      createMenuItem(this.ctx, 'executive', 'pages:planning.ganttChart.executive'),
      createMenuItem(this.ctx, 'done', 'pages:planning.ganttChart.done'),
      createMenuItem(this.ctx, 'plannedEffort', 'pages:planning.ganttChart.plannedEffort'),
      createMenuItem(this.ctx, 'labels', 'pages:planning.ganttChart.labels'),
    ];
  }
}
