import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {castFilter} from '@octaved/utilities';
import {useCallback, useRef} from 'react';
import {useStore} from 'react-redux';
import {NodesMove, NodesResort} from '../EntityInterfaces/Nodes';
import {isTask} from '../Node/NodeIdentifiers';
import {getChildNodesSelector, nodeTreeSelector} from '../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../Modules/State';
import {moveTasks} from '../Modules/Tasks';

export function useTaskIndentation(
  taskId: MaybeUuid,
  depth: number,
  previousSiblingId?: Uuid,
): {
  canIndent: boolean;
  canOutdent: boolean;
  canToggleIndentation: boolean;
  isIndented: boolean;
  toggleIndentation: () => void;
} {
  const {getState, dispatch} = useStore<FlowState>();
  const previousSiblingIdRef = useRef(previousSiblingId);
  previousSiblingIdRef.current = previousSiblingId;

  const canIndent = depth === 0 && !!previousSiblingId;
  const canOutdent = depth === 1;
  const canToggleIndentation = canIndent || canOutdent;

  const toggleIndentation = useCallback(() => {
    const state = getState();
    const tree = nodeTreeSelector(state);
    const oldParentId = taskId && tree[taskId];
    if (!oldParentId) {
      return;
    }
    const grandParentId = tree[oldParentId];
    const prevSiblingId = previousSiblingIdRef.current;

    const getIds = (nodeId: Uuid): Uuid[] => {
      return castFilter(getChildNodesSelector(state)(nodeId), isTask)
        .slice(0)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(({id}) => id);
    };

    const moves: NodesMove[] = [];
    const resorts: NodesResort[] = [];

    if (depth === 0 && prevSiblingId) {
      //When indenting, the previous children become subsequent siblings, so we must make two moves:
      // 1. move the task to its previous sibling
      // 1. move the task's previous children also to its previous sibling

      const childIds = getIds(taskId);
      const oldSiblingIds = getIds(oldParentId).filter((siblignId) => taskId !== siblignId);
      const newSiblingIds = getIds(prevSiblingId);
      newSiblingIds.push(taskId);

      moves.push({movedNodeIds: [taskId], targetParentNodeId: prevSiblingId});

      if (childIds.length) {
        moves.push({movedNodeIds: childIds, targetParentNodeId: prevSiblingId});
        newSiblingIds.push(...childIds);
      }

      resorts.push({parentNodeId: oldParentId, sortedSiblingNodeIds: oldSiblingIds});
      resorts.push({parentNodeId: prevSiblingId, sortedSiblingNodeIds: newSiblingIds});
    } else if (depth === 1 && grandParentId) {
      //When outdenting, the task should keep its position in the list. This means we have to make two moves:
      // 1. move the task to its grandparent at the position below its previous parent
      // 2. move all of the tasks previous subsequent siblings below this task

      const oldSiblingIds = getIds(oldParentId);
      const oldSiblingIndex = oldSiblingIds.indexOf(taskId);
      const subsequentSiblingIds = oldSiblingIds.slice(oldSiblingIndex + 1);

      const newSiblingIds = getIds(grandParentId);
      const oldParentIndex = newSiblingIds.indexOf(oldParentId);
      newSiblingIds.splice(oldParentIndex + 1, 0, taskId);

      moves.push({movedNodeIds: [taskId], targetParentNodeId: grandParentId});
      resorts.push({parentNodeId: grandParentId, sortedSiblingNodeIds: newSiblingIds});

      if (subsequentSiblingIds.length) {
        moves.push({movedNodeIds: subsequentSiblingIds, targetParentNodeId: taskId});
        resorts.push({parentNodeId: taskId, sortedSiblingNodeIds: subsequentSiblingIds});
      }
    }

    dispatch(moveTasks(moves, resorts));
  }, [getState, taskId, depth, dispatch]);

  return {
    canIndent,
    canOutdent,
    canToggleIndentation,
    toggleIndentation,
    isIndented: depth !== 0,
  };
}
