/**
 * This is a port of TimeRecordingLimit.php and should remain that way!
 */
import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {Group, Project, WorkPackage} from '../EntityInterfaces/Pid';
import {SubWorkPackage} from '../EntityInterfaces/SubWorkPackage';
import {NodeAncestryWithWp} from '../Modules/Selectors/NodeTreeSelectors';
import {Settings} from '../Modules/Settings';
import {isGroup, isProject, isSubWorkPackage, isWorkPackage} from '../Node/NodeIdentifiers';
import {getEffectiveMaxEffort} from '../WorkPackage/MaxEffort';
import {timeTrackingBudgetExceeded} from './AncestorBudget';
import {BillingStartEnd, calcBillingDuration, FullBillingStartEnd} from './BillingStartEnd';
import {roundTimestamp} from './Rounding';

const affectedBillingTypes = [
  EnumFlowPidBillingType.VALUE_CONTINGENT,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
  EnumFlowPidBillingType.VALUE_FIXED_PRICE,
];

function isFullBillingStartEnd(startEnd: BillingStartEnd): startEnd is FullBillingStartEnd {
  return !!startEnd.billingStart && !!startEnd.billingEnd;
}

export function isAffectedBillingType(
  billingType: EnumFlowPidBillingType,
): billingType is keyof Settings['billingTypesBookLimits'] {
  return affectedBillingTypes.includes(billingType);
}

function hasReachedLimit(
  settings: Settings,
  billingType: EnumFlowPidBillingType,
  maxEffortHours: number | null,
  billedMinutes: number,
): boolean {
  if (!isAffectedBillingType(billingType)) {
    return false;
  }

  const percentageLimit = settings.billingTypesBookLimits[billingType].maxPercentage;
  if (maxEffortHours === null || percentageLimit === null) {
    return false;
  }

  return (100 * (billedMinutes / 60)) / maxEffortHours >= percentageLimit;
}

export function getClosestTimeLimitReachedAncestor(
  settings: Settings,
  ancestry: NodeAncestryWithWp,
): Project | Group | WorkPackage | SubWorkPackage | null {
  for (const ancestor of ancestry.ancestors) {
    if ((isProject(ancestor) || isGroup(ancestor)) && timeTrackingBudgetExceeded(ancestor)) {
      return ancestor;
    }
    if (
      isWorkPackage(ancestor) &&
      ancestor.billingType &&
      hasReachedLimit(settings, ancestor.billingType, getEffectiveMaxEffort(ancestor), ancestor.trackedMinutes.billed)
    ) {
      return ancestor;
    }
    if (
      isSubWorkPackage(ancestor) &&
      ancestry.workPackage.billingType &&
      hasReachedLimit(settings, ancestry.workPackage.billingType, ancestor.maxEffort, ancestor.trackedMinutes.billed)
    ) {
      return ancestor;
    }
  }
  return null;
}

export interface WillExceedSuggestion {
  maxEnd?: number;
  maxEndRounded?: number;
}

function fillSuggestion(
  suggestion: WillExceedSuggestion,
  settings: Settings,
  next: FullBillingStartEnd,
  availableHours: number,
): void {
  if (availableHours > 0) {
    const availableSeconds = availableHours * 3600;
    suggestion.maxEnd = next.billingStart + availableSeconds;
    //Must round down, otherwise the time might be too big again:
    suggestion.maxEndRounded = roundTimestamp(settings, suggestion.maxEnd, false);
  }
}

export function timeTrackingWillExceedLimit(
  settings: Settings,
  billingType: EnumFlowPidBillingType,
  maxEffort: number | null,
  billedMinutes: number,
  previous: BillingStartEnd,
  next: BillingStartEnd,
  suggestion: WillExceedSuggestion = {},
): boolean {
  if (!isFullBillingStartEnd(next) || !isAffectedBillingType(billingType)) {
    return false;
  }

  const percentageLimit = settings.billingTypesBookLimits[billingType].maxPercentage;
  if (maxEffort === null || percentageLimit === null) {
    return false;
  }

  const oldDurationMinutes = calcBillingDuration(previous) / 60;
  const newDurationMinutes = calcBillingDuration(next) / 60;
  const remainingBilledMinutes = billedMinutes - oldDurationMinutes;
  const newBilledMinutes = remainingBilledMinutes + newDurationMinutes;
  const newPercentage = (100 * (newBilledMinutes / 60)) / maxEffort;
  const willExceed = newPercentage > percentageLimit;

  if (willExceed) {
    fillSuggestion(suggestion, settings, next, maxEffort - remainingBilledMinutes / 60);
  }

  return willExceed;
}
