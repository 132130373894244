import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {generateLargeViewUrl} from '@octaved/flow/src/Routing/Routes/Node';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWButton} from '@octaved/ui';
import {AlertTriangle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {boardRoute} from '../../../../LargeView/RoutePaths';
import Section from '../../Section';
import {useNodeBoardStats} from '../Values/NodeBoardValue';

interface NodeBoardStatsSectionProps {
  nodeId: Uuid;
  nodeType: EnumFlowNodeType;
}

export default function NodeBoardStatsSection({nodeId, nodeType}: NodeBoardStatsSectionProps): ReactElement {
  const {t} = useTranslation();
  const {boardPosts, requireAction} = useNodeBoardStats(nodeId);

  return (
    <Section labelToken={'nodeDetails:field.boardPosts.label'}>
      <div>
        <span>{t('nodeDetails:field.boardPosts.entry', {count: boardPosts})}</span>
        {requireAction > 0 && (
          <span>
            {', '}

            <span className={'text-red-600'}>
              <AlertTriangle className={'-mt-[0.3em] inline-flex h-4 w-4'} />{' '}
              {t('nodeDetails:field.boardPosts.requireAction', {count: requireAction})}
            </span>
          </span>
        )}
      </div>

      <div className={'flex'}>
        <Link to={generateLargeViewUrl(nodeType, nodeId, boardRoute)}>
          <TWButton variant={'solid'} size={'md'} colorScheme={'primary'}>
            {t('nodeDetails:field.boardPosts.openBoard')}
          </TWButton>
        </Link>
      </div>
    </Section>
  );
}
