import {todayDayjsSelector} from '@octaved/flow/src/Today';
import {
  organizationCreatedOnSelector,
  organizationLicenseSelector,
} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import dayjs from 'dayjs';
import {createSelector} from 'reselect';

export const licenseSelector = organizationLicenseSelector;

export const trialDaysSelector = createSelector(
  organizationCreatedOnSelector,
  organizationLicenseSelector,
  todayDayjsSelector,
  (createdOn, license, todayDayjs) => {
    const created = dayjs.unix(createdOn);
    const daysSinceCreation = todayDayjs.diff(created, 'day');
    const daysLeftInTrial = license.trialDuration - daysSinceCreation;
    const daysLeftToDisplay = Math.max(daysLeftInTrial, 0);
    return {daysLeftInTrial, daysLeftToDisplay};
  },
);
