import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {StatusCircleProps} from '../Components/Status/StatusCircle';
import {NodeHistoryFactory} from '../EntityInterfaces/NodeHistory';
import {NodeSearchCondition} from '../EntityInterfaces/NodeSearch';
import {WorkPackage} from '../EntityInterfaces/Pid';
import {useCombinedNodeSearch, useNodeSearch} from '../Modules/Hooks/NodeSearch';
import {useNodeHistory} from '../Modules/NodeHistorys';
import {isNodeExecutedSelector} from '../Modules/Selectors/ExecutionStatusSelectors';
import {useTimeTrackingSelector} from '../Modules/Selectors/SettingSelectors';

export enum EnumExecutionStatus {
  'notStarted' = 'notStarted',
  'started' = 'started',
  'executed' = 'executed',
}

export const executionStatusIconColors: Record<EnumExecutionStatus, StatusCircleProps['color']> = {
  [EnumExecutionStatus.notStarted]: 'grey',
  [EnumExecutionStatus.started]: 'blue',
  [EnumExecutionStatus.executed]: 'green',
};

export const executionStatusTokens: Record<EnumExecutionStatus, string> = {
  [EnumExecutionStatus.notStarted]: 'general:workPackage.executionStatus.notStarted',
  [EnumExecutionStatus.started]: 'general:workPackage.executionStatus.started',
  [EnumExecutionStatus.executed]: 'general:workPackage.executionStatus.executed',
};

const tooltipTokensWithTimeTracking: Record<EnumExecutionStatus, string> = {
  [EnumExecutionStatus.notStarted]: 'general:workPackage.executionStatus.tooltip.notStarted.withTimeTracking',
  [EnumExecutionStatus.started]: 'general:workPackage.executionStatus.tooltip.started.withTimeTracking',
  [EnumExecutionStatus.executed]: 'general:workPackage.executionStatus.tooltip.executed',
};

const tooltipTokensWithoutTimeTracking: Record<EnumExecutionStatus, string> = {
  [EnumExecutionStatus.notStarted]: 'general:workPackage.executionStatus.tooltip.notStarted.withoutTimeTracking',
  [EnumExecutionStatus.started]: 'general:workPackage.executionStatus.tooltip.started.withoutTimeTracking',
  [EnumExecutionStatus.executed]: 'general:workPackage.executionStatus.tooltip.executed',
};

export const executionStatusTooltipTokensSelector = createSelector(useTimeTrackingSelector, (useTimeTracking) =>
  useTimeTracking ? tooltipTokensWithTimeTracking : tooltipTokensWithoutTimeTracking,
);

type RequiredWorkPackage = Pick<WorkPackage, 'isCompleted' | 'trackedMinutes'>;

export function isWorkPackageExecuted(workPackage: {isCompleted: boolean}): boolean {
  return workPackage.isCompleted;
}

export function getWorkPackageExecutionStatus(workPackage: RequiredWorkPackage): EnumExecutionStatus {
  if (workPackage.isCompleted) {
    return EnumExecutionStatus.executed;
  }
  if (workPackage.trackedMinutes.billed > 0) {
    return EnumExecutionStatus.started;
  }
  return EnumExecutionStatus.notStarted;
}

const hasCompletedTasks: NodeSearchCondition = {
  and: [
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    withAncestors(['taskStatus', EnumFlowTaskStatus.VALUE_COMPLETE], true),
  ],
};
const hasTimeTrackingRecords: NodeSearchCondition = {
  and: [['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE], withAncestors(['trRefHasTimeTrackingRecords'], true)],
};

const isWorkPackageStartIndicatedQuerySelector = createSelector(
  useTimeTrackingSelector,
  (useTimeTracking): NodeSearchCondition => {
    return useTimeTracking
      ? {
          or: [hasCompletedTasks, hasTimeTrackingRecords],
        }
      : hasCompletedTasks;
  },
);

export const isWorkPackageStartedQuerySelector = createSelector(
  isWorkPackageStartIndicatedQuerySelector,
  (isWorkPackageStartIndicated): NodeSearchCondition => {
    //"started" means start is indicated and wp is not "exectued":
    return {
      and: [isWorkPackageStartIndicated, {not: ['wpIsCompleted']}],
    };
  },
);

export const isWorkPackageNotStartedQuerySelector = createSelector(
  isWorkPackageStartIndicatedQuerySelector,
  (isWorkPackageStartIndicated): NodeSearchCondition => {
    //"not started" means start is not indicated and wp is not "exectued":
    return {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        {not: isWorkPackageStartIndicated},
        {not: ['wpIsCompleted']},
      ],
    };
  },
);

export function useWorkPackageExecutedHistoryEntry(workPackageId: Uuid): {
  isLoading: boolean;
  entry: NodeHistoryFactory<WorkPackage> | undefined;
} {
  const {isLoading, entries} = useNodeHistory<WorkPackage>(workPackageId);
  const entry = entries.find(({isCompleted}) => isCompleted);
  return {isLoading, entry};
}

/**
 * A node is considered executed if it has no descendant work packages that are not executed.
 * So an empty project is considered executed, too.
 */
export function useIsNodeExecuted(): {
  isLoading: boolean;
  isNodeExecuted: (nodeId: Uuid) => boolean;
} {
  const {isLoading: typeLoading} = useNodeSearch('nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE, true, true);
  const {isLoading: execLoading} = useCombinedNodeSearch(['wpIsCompleted'], true);
  const isLoading = typeLoading || execLoading;
  const isNodeExecuted = useSelector(isNodeExecutedSelector);
  return {
    isLoading,
    isNodeExecuted: useLoadedValue(isLoading, isNodeExecuted),
  };
}
