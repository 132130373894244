import {FilterStatesValueArray, isComplete} from '../../../Modules/Filter/MergeFilterStatesWithDefaults';

export type QuickControlFixedPriceAnalysisThreshold = 'targetValueUndershot' | 'targetValueAdhered' | 'noTargetRate';

export interface QuickControlFixedPriceAnalysis {
  option: 'lessThan' | 'greaterThan' | 'fromTo' | 'all';
  thresholds: FilterStatesValueArray<QuickControlFixedPriceAnalysisThreshold>;
  value: string;
  valueTo: string;
}

export const quickControlFixedPriceAnalysisDefault: QuickControlFixedPriceAnalysis = {
  option: 'all',
  thresholds: ['targetValueUndershot', 'targetValueAdhered', 'noTargetRate'],
  value: '',
  valueTo: '',
};

quickControlFixedPriceAnalysisDefault.thresholds[isComplete] = true;
