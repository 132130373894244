import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {SubtreeStatsBoard, subtreeStatsBoardDefault} from '@octaved/flow/src/EntityInterfaces/Statistics/SubtreeStats';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {useSingleSubtreeStat} from '@octaved/flow/src/Modules/Statistics/SubtreeStats';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';

interface NodeBoardStats extends SubtreeStatsBoard {
  boardPosts: number;
  requireAction: number;
}

export function useNodeBoardStats(nodeId: Uuid): NodeBoardStats {
  const [{ids: totalIds}, {ids: waitingForResponseIds}] = useCombinedNodeSearches(
    {},
    {
      and: [withDescendants(nodeId, true), ['nodeType', EnumFlowNodeType.VALUE_BOARD_POST]],
    },
    {
      and: [withDescendants(nodeId, true), ['bpWaitingForMyResponse']],
    },
  );
  const stat = useSingleSubtreeStat('board', nodeId) || subtreeStatsBoardDefault;
  return {
    ...stat,
    boardPosts: totalIds.length,
    requireAction: waitingForResponseIds.length,
  };
}
