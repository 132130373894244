/* eslint-disable no-console */

const logLevels = ['debug', 'info', 'warning', 'error'] as const;

export type LogLevel = (typeof logLevels)[number];
type LoggerFunction = (...args: unknown[]) => void;
type LogHandler = (level: LogLevel, ...args: unknown[]) => void;

const handlers = new Set<LogHandler>();

const WARN = logLevels.indexOf('warning');
let currentLogLevel = WARN;

function proxyConsole(level: LogLevel): LoggerFunction {
  const logLevel = logLevels.indexOf(level);
  if (logLevel < 0) {
    return console[level === 'warning' ? 'warn' : level].bind(console);
  }
  return (...args) => {
    if (currentLogLevel <= logLevel) {
      handlers.forEach((handler) => handler(level, ...args));
      console[level === 'warning' ? 'warn' : level].call(console, ...args);
    }
  };
}

export const debug = proxyConsole('debug');
export const info = proxyConsole('info');
export const warning = proxyConsole('warning');
export const error = proxyConsole('error');

export function setLogLevel(level: LogLevel): void {
  currentLogLevel = logLevels.indexOf(level);
}

export function registerLogHandler(callback: LogHandler): () => void {
  handlers.add(callback);
  return () => handlers.delete(callback);
}

export function measureDuration(ident: string): {stop: () => PerformanceMeasure} {
  const start = `${ident}-start`;
  const stop = `${ident}-stop`;
  performance.mark(start);
  return {
    stop: () => {
      performance.mark(stop);
      return performance.measure(ident, start, stop);
    },
  };
}
