import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import Text from '../../../../../Components/Text/Text';

export default function NoTimeRecords(): ReactElement {
  const {t} = useTranslation();
  return (
    <Text italic>
      <span>{t('pages:projects.pages.controlTrackedTime.inspector.noTimeRecords')}</span>
    </Text>
  );
}
