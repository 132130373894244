import {error} from '@octaved/env/src/Logger';
import {asDateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {ISO_DATE_RANGE_REGEX, isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {NodeSearchIdent} from '../../EntityInterfaces/NodeSearch';
import {PlanningEventPlanningData} from '../Events';
import {splitNodeSearchKey} from '../Selectors/NodeSearchSelectors';

export type DateMatcher = (searchDate: string) => boolean;
type RangeMatcher = (searchFrom: string, searchTo: string) => boolean;

/**
 * Matches if date is before the search date
 */
export const dateAfterDate: (date: string) => DateMatcher = (date) => (searchDate) => date > searchDate;

/**
 * Matches if date is before the search date
 */
export const dateBeforeDate: (date: string) => DateMatcher = (date) => (searchDate) => date < searchDate;

/**
 * Matches if date is within from/to
 */
export const dateInRange: (date: string) => RangeMatcher = (date) => (searchFrom, searchTo) =>
  date >= searchFrom && date <= searchTo;

/**
 * Matches if the start/end overlaps with from/to
 */
export const dateRangeOverlaps: (start: string, end: string) => RangeMatcher = (start, end) => (searchFrom, searchTo) =>
  end >= searchFrom && start <= searchTo;

export const dateRangesOverlaps: (ranges: PlanningEventPlanningData[]) => RangeMatcher =
  (ranges) => (searchFrom, searchTo) => {
    const from = isoToIsoDateTimeFormat(asDateStr(searchFrom));
    const to = isoToIsoDateTimeFormat(asDateStr(searchTo));
    return ranges.some(({plannedEnd, plannedStart}) => plannedEnd >= from && plannedStart <= to);
  };

function reduce<Matcher extends DateMatcher | RangeMatcher, I extends NodeSearchIdent>(
  getMatcherArgs: (value: string) => Parameters<Matcher> | null,
  state: UuidSearchResults,
  id: Uuid,
  ident: I,
  matcher: Matcher,
): UuidSearchResults {
  let newState = state;
  Object.keys(state).forEach((key) => {
    const [storeIdent, value] = splitNodeSearchKey(key);
    if (storeIdent === ident) {
      if (value) {
        const args = getMatcherArgs(value);
        if (args) {
          const set = new Set(newState[key]);
          // @ts-ignore args is a tuple of Parameters<Matcher>
          if (matcher(...args)) {
            // if (date >= from && date <= to) {
            if (!set.has(id)) {
              set.add(id);
              newState = {...newState, [key]: [...set]};
            }
          } else {
            if (set.has(id)) {
              set.delete(id);
              newState = {...newState, [key]: [...set]};
            }
          }
        } else {
          error(`The search key '${key}' does not contain the expected value`);
        }
      } else {
        error(`Missing value in search key '${key}'`);
      }
    }
  });
  return newState;
}

function getDateFromValue(value: string): [string] {
  return [value];
}

export function reduceMatchesDate<I extends NodeSearchIdent>(
  state: UuidSearchResults,
  id: Uuid,
  ident: I,
  matcher: DateMatcher,
): UuidSearchResults {
  return reduce(getDateFromValue, state, id, ident, matcher);
}

function getRangeFromValue(value: string): [string, string] | null {
  const match = value.match(ISO_DATE_RANGE_REGEX);
  if (match) {
    return [match[1], match[2]];
  }
  return null;
}

export function reduceMatchesDateRange<I extends NodeSearchIdent>(
  state: UuidSearchResults,
  id: Uuid,
  ident: I,
  matcher: RangeMatcher,
): UuidSearchResults {
  return reduce(getRangeFromValue, state, id, ident, matcher);
}
