import {getRestorableTimeTrackingRecordsFromTrash} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {
  RestorableTimeRecordFromTrash,
  StoreRestorableTimeRecordFromTrash,
} from '../../EntityInterfaces/RestoreFromTrash';
import {
  FLOW_LOAD_RESTORABLE_TIME_RECORDS_FAILURE,
  FLOW_LOAD_RESTORABLE_TIME_RECORDS_REQUEST,
  FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS,
} from '../ActionTypes';
import {TimeRecordRemovedEvent, TimeRecordsRestoredFromTrashEvent} from '../Events';
import {
  restorableTimeRecordsSelector,
  restorableTimeRecordsStateSelector,
} from '../Selectors/RestoreFromTrashSelectors';
import {FlowState} from '../State';

interface LoadAction {
  referenceNodeId: Uuid;
  response: RestorableTimeRecordFromTrash[];
  type: typeof FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS;
}

const reducers = createReducerCollection<StoreRestorableTimeRecordFromTrash>({});
export const restorableTimeRecordsReducer = reducers.reducer;
const stateReducers = createReducerCollection<EntityStates>({});
export const restorableTimeRecordsStateReducer = stateReducers.reducer;

reducers.add<LoadAction>(FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS, (state, {referenceNodeId, response}) => {
  return {...state, [referenceNodeId]: response.sort((a, b) => b.revisionStartDate - a.revisionStartDate)};
});

stateReducers.add(FLOW_LOAD_RESTORABLE_TIME_RECORDS_REQUEST, createTimestampReducer('referenceNodeId', LOADING));
stateReducers.add(FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS, createTimestampReducer('referenceNodeId', LOADED));

stateReducers.add<TimeRecordRemovedEvent | TimeRecordsRestoredFromTrashEvent>(
  ['flow.TimeRecordRemovedEvent', 'flow.TimeRecordsRestoredFromTrashEvent'],
  () => ({}),
);

function loadRestorableTimeRecords(referenceNodeId: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = restorableTimeRecordsStateSelector(getState())[referenceNodeId];
    if (!state || isOutdated(state)) {
      dispatch({
        referenceNodeId,
        [CALL_API]: {
          endpoint: getRestorableTimeTrackingRecordsFromTrash,
          options: {urlParams: {referenceNodeId}},
          types: {
            failureType: FLOW_LOAD_RESTORABLE_TIME_RECORDS_FAILURE,
            requestType: FLOW_LOAD_RESTORABLE_TIME_RECORDS_REQUEST,
            successType: FLOW_LOAD_RESTORABLE_TIME_RECORDS_SUCCESS,
          },
        },
      });
    }
  };
}

export function useRestorableTimeRecords(referenceNodeId: MaybeUuid): RestorableTimeRecordFromTrash[] | undefined {
  useStoreEffect(
    (dispatch) => referenceNodeId && dispatch(loadRestorableTimeRecords(referenceNodeId)),
    [referenceNodeId],
    restorableTimeRecordsStateSelector,
  );
  return useSelector((s: FlowState) => restorableTimeRecordsSelector(s)[referenceNodeId || '']);
}
