import {useSingleSubtreeStat} from '@octaved/flow/src/Modules/Statistics/SubtreeStats';
import {ReactElement} from 'react';
import NodeGuestUserAccessMessage from '../../../Components/Fields/GuestUser/Common/GuestUserAccessMessage';
import NodeGuestUserAssignementSection from '../../../Components/Fields/GuestUser/Section/NodeGuestUserAssignementSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {ProjectDetailsProps} from './General';

export default function Guests({node}: ProjectDetailsProps): ReactElement {
  const stat = useSingleSubtreeStat('hasGuestAccess', node.id);
  const hasDirectAccess = Boolean(stat?.customer.direct || stat?.other.direct);
  const hasSubtreeAccess = Boolean(stat?.customer.subtree || stat?.other.subtree);
  return (
    <InspectorViewFrame help={'nodeDetails:view.guestUser.help'}>
      <div />
      <NodeGuestUserAccessMessage hasDirectAccess={hasDirectAccess} hasSubtreeAccess={hasSubtreeAccess} />
      <NodeGuestUserAssignementSection nodeId={node.id} />
    </InspectorViewFrame>
  );
}
