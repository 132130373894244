import {ReactElement, ReactNode, useCallback} from 'react';
import {Link, useLocation} from 'react-router-dom';

interface Props {
  children: ReactNode;
  path: string;
  type: 'dialog' | 'sideStep' | 'drawer';
  closeIfOpen?: boolean;
  disabled?: boolean;
  className?: string;
}

export type LinkGeneratorFN = (type: Props['type'], path: string, closeIfOpen?: boolean) => string;

export function useGenerateDynamicLink(): LinkGeneratorFN {
  const location = useLocation();
  return useCallback(
    (type, path, closeIfOpen = false) => {
      const link = type === 'dialog' ? `/${type}/` : `/${type}`;

      const completePath = `${link}${path}`;
      let linkPath = location.pathname;
      if (linkPath.includes(link)) {
        linkPath = linkPath.slice(0, linkPath.lastIndexOf(link));
      }
      if (linkPath.includes('/drawer') && type === 'sideStep') {
        linkPath = linkPath.slice(0, linkPath.lastIndexOf('/drawer'));
      }
      if (!(closeIfOpen && location.pathname.includes(completePath))) {
        linkPath += completePath;
      }
      return linkPath + window.location.search;
    },
    [location],
  );
}

export default function DynamicLink({
  children,
  path,
  type,
  closeIfOpen = false,
  disabled,
  className,
}: Props): ReactElement {
  const linkPath = useGenerateDynamicLink()(type, path, closeIfOpen);
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Link to={linkPath} className={className}>
      {children}
    </Link>
  );
}
