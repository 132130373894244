import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {TWButton} from '@octaved/ui';
import {ChartBar} from 'lucide-react';
import {lazy, ReactElement, useState} from 'react';
import {Trans} from 'react-i18next';

const LazyBaselineComparisonDrawer = lazy(
  () => import('@octaved/planning/src/Components/BaselineComparison/BaselineComparisonDrawer'),
);

export default function BaselineComparisonButton({node}: {node: NodeType}): ReactElement | null {
  const [showBaselineComparison, setShowBaselineComparison] = useState(false);

  if (isProject(node) && node.isTemplate) {
    return null;
  }

  return (
    <>
      <TWButton onClick={() => setShowBaselineComparison(true)} withIcon>
        <ChartBar className={'h-4 w-4'} />
        <Trans i18nKey={'pages:planning.inspector.openBaselineComparison'} />
      </TWButton>
      {showBaselineComparison && (
        <LazyBaselineComparisonDrawer close={() => setShowBaselineComparison(false)} rootNodeId={node.id} visible />
      )}
    </>
  );
}
