import {ReactElement, useContext} from 'react';
import {useSelector} from 'react-redux';
import {ProjectTreeProjectsGroup} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {customerEntitiesSelector} from '../../../../Modules/Selectors/CustomerSelectors';
import {flowStyleContext} from '../../../../Styles/StyleContext';
import CustomerAvatar from '../../../Node/CustomerAvatar';
import {useProjectTreeContext} from '../../../ProjectTree/ProjectTreeContext';
import {SearchHighlightText} from '../../../Search/SearchHighlight';
import GroupingName from './GroupingName';

export default function Customer({node}: {node: ProjectTreeProjectsGroup}): ReactElement {
  const {
    COLORS: {FONT},
  } = useContext(flowStyleContext);
  const {compactView: compact} = useProjectTreeContext();
  const customer = useSelector(customerEntitiesSelector)[node.id];

  return (
    <>
      <CustomerAvatar
        imageSize={compact ? '24px' : '48px'}
        initialsSize={compact ? '24px' : '36px'}
        flowCustomer={node.id}
        noMargin
        size={compact ? 'small' : 'normal'}
      />
      <GroupingName>
        <SearchHighlightText text={node.groupDisplayName} />
      </GroupingName>
      <div className={'customerNumber'}>{customer?.customerNumber}</div>

      {/*language=SCSS*/}
      <style jsx>{`
        .customerNumber {
          padding: 0 10px;
          color: ${FONT.WEAK};
        }
      `}</style>
    </>
  );
}
