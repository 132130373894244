import {EnumFlowTaskStatus, EnumRevisionType} from '@octaved/env/src/dbalEnumTypes';
import UserName from '@octaved/flow/src/Components/Unit/UserName';
import {useLoading} from '@octaved/flow/src/Modules/Hooks/Loading';
import {
  getTastStatusHistory,
  taskStatusHistoryStatesSelector,
} from '@octaved/flow/src/Modules/Selectors/TaskStatusHistorySelectors';
import {getTaskStatusChangeHistory} from '@octaved/flow/src/Modules/TaskStatusHistory';
import {Uuid} from '@octaved/typescript/src/lib';
import {DATETIME, fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {useFormatInWords} from '@octaved/users/src/Culture/DateFormatHooks/FormatInWords';
import {CheckCircle, Circle, LucideIcon, PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import HistoryEntry, {HistoryColor} from './Components/HistoryEntry';

const statusIconMap: Record<EnumFlowTaskStatus, LucideIcon> = {
  [EnumFlowTaskStatus.VALUE_OPEN]: Circle,
  [EnumFlowTaskStatus.VALUE_COMPLETE]: CheckCircle,
};

const colorMap: Record<EnumFlowTaskStatus, HistoryColor> = {
  [EnumFlowTaskStatus.VALUE_OPEN]: 'bg-slate-700',
  [EnumFlowTaskStatus.VALUE_COMPLETE]: 'bg-green-700',
};

export interface TaskHistoryProps {
  nodeId: Uuid;
}

export default function TaskHistory({nodeId}: TaskHistoryProps): ReactElement {
  const formatInWords = useFormatInWords({type: DATETIME});
  useLoading([nodeId], taskStatusHistoryStatesSelector, getTaskStatusChangeHistory);
  const taskStatusHistory = useSelector(getTastStatusHistory)(nodeId) || [];
  return (
    <div>
      {taskStatusHistory.map((history, idx) => {
        const Icon = statusIconMap[history.status];
        const color = colorMap[history.status];
        if (history.revisionType === EnumRevisionType.VALUE_INSERT) {
          return (
            <HistoryEntry
              key={idx}
              entryHeader={
                <div className={'capitalize'}>{formatInWords(fromIsoDateTimeFormat(history.date, true))}</div>
              }
              entryIcon={PlusCircle}
              circleColor={'bg-green-700'}
              canCompareToPrevious={false}
              istLastElementInHistory
              isFirst={false}
            >
              <Trans
                i18nKey={'taskList:historyEntry.taskCreated'}
                components={{name: <UserName userId={history.userId} />}}
              />
            </HistoryEntry>
          );
        }

        return (
          <HistoryEntry
            key={idx}
            entryHeader={<div className={'capitalize'}>{formatInWords(fromIsoDateTimeFormat(history.date, true))}</div>}
            entryIcon={Icon}
            circleColor={color}
            canCompareToPrevious={false}
            isFirst={idx === 0}
            istLastElementInHistory={false}
          >
            <Trans
              i18nKey={
                history.status === EnumFlowTaskStatus.VALUE_OPEN
                  ? 'taskList:historyEntry.taskNotMarkedDone'
                  : 'taskList:historyEntry.taskMarkedDone'
              }
              components={{name: <UserName userId={history.userId} />}}
            />
          </HistoryEntry>
        );
      })}
    </div>
  );
}
