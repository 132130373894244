import {
  getKanbanBoards as getKanbanBoardsRoute,
  patchKanbanBoard as patchKanbanBoardRoute,
  putKanbanBoard,
  removeKanbanBoard as removeKanbanBoardRoute,
} from '@octaved/flow-api';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createFlatTimestampReducer, INVALIDATED, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {
  optimisticAdd,
  optimisticDelete,
  optimisticUpdate,
  setToReducerMap,
} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {createTextInputVariableRules, RulesList} from '@octaved/store/src/Validation';
import {Uuid} from '@octaved/typescript/src/lib';
import {generateUuid} from '@octaved/utilities';
import objectContains from '@octaved/validation/src/ObjectContains';
import {KanbanBoard} from '../EntityInterfaces/KanbanBoards';
import {Labels} from '../EntityInterfaces/Labels';
import {
  FLOW_CREATE_KANBAN_BOARD_FAILURE,
  FLOW_CREATE_KANBAN_BOARD_REQUEST,
  FLOW_CREATE_KANBAN_BOARD_SUCCESS,
  FLOW_GET_KANBAN_BOARD_FAILURE,
  FLOW_GET_KANBAN_BOARD_REQUEST,
  FLOW_GET_KANBAN_BOARD_SUCCESS,
  FLOW_PATCH_KANBAN_BOARD_FAILURE,
  FLOW_PATCH_KANBAN_BOARD_REQUEST,
  FLOW_PATCH_KANBAN_BOARD_SUCCESS,
  FLOW_REMOVE_KANBAN_BOARD_FAILURE,
  FLOW_REMOVE_KANBAN_BOARD_REQUEST,
  FLOW_REMOVE_KANBAN_BOARD_SUCCESS,
} from './ActionTypes';
import {addLabelRemovedEventToReducer, removeNotAllowedLabels} from './ReduceRemovedLabels';
import {kanbanBoard} from './Schema';
import {kanbanBoardEntityStatesSelector, kanbanBoardSelector} from './Selectors/KanbanBoardSelectors';
import {labelIdsSelector} from './Selectors/LabelSelectors';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

export type KanbanBoardPatchData = Pick<
  KanbanBoard,
  'name' | 'useForGroups' | 'useForProjects' | 'useForTasks' | 'useForWorkPackages' | 'showDone' | 'labels'
>;

//#region interfaces
type Request = ServerRequestAction<{
  data: KanbanBoard;
  urlParams: {kanbanBoardId: Uuid};
}>;
//#endregion

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_GET_KANBAN_BOARD_REQUEST, createFlatTimestampReducer(LOADING));
stateReducerMap.set(FLOW_GET_KANBAN_BOARD_SUCCESS, createFlatTimestampReducer(LOADED));
stateReducerMap.set('flow.KanbanBoardPatchedEvent', createFlatTimestampReducer(INVALIDATED));
stateReducerMap.set('flow.KanbanBoardCreatedEvent', createFlatTimestampReducer(INVALIDATED));
stateReducerMap.set('flow.KanbanBoardRemovedEvent', createFlatTimestampReducer(INVALIDATED));

export const kanbanBoardEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion

//#region reducer
const reducerMap = new Map();

setToReducerMap<Labels, Request>(
  optimisticUpdate,
  reducerMap,
  FLOW_PATCH_KANBAN_BOARD_REQUEST,
  FLOW_PATCH_KANBAN_BOARD_FAILURE,
  FLOW_PATCH_KANBAN_BOARD_SUCCESS,
  ({options}) => options.urlParams.kanbanBoardId,
);
setToReducerMap<Labels, Request>(
  optimisticAdd,
  reducerMap,
  FLOW_CREATE_KANBAN_BOARD_REQUEST,
  FLOW_CREATE_KANBAN_BOARD_FAILURE,
  FLOW_CREATE_KANBAN_BOARD_SUCCESS,
  ({options}) => options.urlParams.kanbanBoardId,
  (action): KanbanBoard | null => {
    const data = action?.options?.data;
    if (data) {
      return {...data, labels: []};
    }
    return null;
  },
);

setToReducerMap<Labels, Request>(
  optimisticDelete,
  reducerMap,
  FLOW_REMOVE_KANBAN_BOARD_REQUEST,
  FLOW_REMOVE_KANBAN_BOARD_FAILURE,
  FLOW_REMOVE_KANBAN_BOARD_SUCCESS,
  ({options}) => options.urlParams.kanbanBoardId,
);

addLabelRemovedEventToReducer(reducerMap);

export const reducer = ReduceFromMap(reducerMap);

//#endregion

export function createKanbanBoardEntity(rootFolder: Uuid): KanbanBoard {
  return {
    rootFolder,
    id: generateUuid(),
    labels: [],
    name: '',
    showDone: true,
    useForGroups: true,
    useForProjects: true,
    useForTasks: true,
    useForWorkPackages: true,
  };
}

export function getKanbanBoards(): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    if (isOutdated(kanbanBoardEntityStatesSelector(getState()))) {
      await dispatch({
        [CALL_API]: {
          endpoint: getKanbanBoardsRoute,
          method: 'get',
          options: {},
          schema: [kanbanBoard],
          types: {
            failureType: FLOW_GET_KANBAN_BOARD_FAILURE,
            requestType: FLOW_GET_KANBAN_BOARD_REQUEST,
            successType: FLOW_GET_KANBAN_BOARD_SUCCESS,
          },
        },
      });
    }
  };
}

function getValidationRules(data: Partial<KanbanBoardPatchData>, isCreation: boolean): RulesList {
  const rules: RulesList = [];
  if (isCreation || data.hasOwnProperty('name')) {
    rules.push(
      ...createTextInputVariableRules(
        data.name!,
        'pages:planning.kanbanBoard.nameEmptyError',
        'pages:planning.kanbanBoard.nameTooLongError',
        'boardName',
      ),
    );
  }
  return rules;
}

export function createKanbanBoard(kanbanBoard: KanbanBoard): ActionDispatcher<Promise<Uuid | null>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(kanbanBoard, true), dispatch)) {
      return null;
    }

    const cleanedEntity = removeNotAllowedLabels(kanbanBoard, labelIdsSelector(getState()));
    dispatch({
      [CALL_API]: {
        endpoint: putKanbanBoard,
        method: 'put',
        options: {
          data: cleanedEntity,
          urlParams: {kanbanBoardId: cleanedEntity.id},
        },
        types: {
          failureType: FLOW_CREATE_KANBAN_BOARD_FAILURE,
          requestType: FLOW_CREATE_KANBAN_BOARD_REQUEST,
          successType: FLOW_CREATE_KANBAN_BOARD_SUCCESS,
        },
      },
    });
    return cleanedEntity.id;
  };
}

export function updateKanbanBoard(
  kanbanBoardId: Uuid,
  data: Partial<KanbanBoardPatchData>,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const board = kanbanBoardSelector(state)(kanbanBoardId);

    if (!validateErrorRules(getValidationRules(data, false), dispatch)) {
      return false;
    }
    const patch = removeNotAllowedLabels(data, labelIdsSelector(state));
    if (board && !objectContains(board, patch)) {
      dispatch({
        [CALL_API]: {
          endpoint: patchKanbanBoardRoute,
          method: 'patch',
          options: {
            data: patch,
            urlParams: {
              kanbanBoardId,
            },
          },
          types: {
            failureType: FLOW_PATCH_KANBAN_BOARD_FAILURE,
            requestType: FLOW_PATCH_KANBAN_BOARD_REQUEST,
            successType: FLOW_PATCH_KANBAN_BOARD_SUCCESS,
          },
        },
      });
    }
    return true;
  };
}

export function removeKanbanBoard(kanbanBoardId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const board = kanbanBoardSelector(getState())(kanbanBoardId);
    if (board) {
      dispatch({
        [CALL_API]: {
          endpoint: removeKanbanBoardRoute,
          method: 'del',
          options: {
            urlParams: {
              kanbanBoardId,
            },
          },
          types: {
            failureType: FLOW_REMOVE_KANBAN_BOARD_FAILURE,
            requestType: FLOW_REMOVE_KANBAN_BOARD_REQUEST,
            successType: FLOW_REMOVE_KANBAN_BOARD_SUCCESS,
          },
        },
      });
    }
  };
}
