export const URM_USER_OUS_CHANGED = 'URM_USER_OUS_CHANGED';

export const URM_GROUP_ADDED = 'URM_GROUP_ADDED';
export const URM_GROUP_MOVED = 'URM_GROUP_MOVED';
export const URM_GROUP_DELETED = 'URM_GROUP_DELETED';
export const URM_GROUP_CHANGED = 'URM_GROUP_CHANGED';

export const LOAD_GROUPS_REQUEST = 'LOAD_GROUPS_REQUEST';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_FAILURE = 'LOAD_GROUPS_FAILURE';

export const LOAD_GROUP_TREE_REQUEST = 'LOAD_GROUP_TREE_REQUEST';
export const LOAD_GROUP_TREE_SUCCESS = 'LOAD_GROUP_TREE_SUCCESS';
export const LOAD_GROUP_TREE_FAILURE = 'LOAD_GROUP_TREE_FAILURE';
export const INVALIDATE_GROUP_TREE = 'INVALIDATE_GROUP_TREE';

export const LOAD_GROUP_USERS_REQUEST = 'LOAD_GROUP_USERS_REQUEST';
export const LOAD_GROUP_USERS_SUCCESS = 'LOAD_GROUP_USERS_SUCCESS';
export const LOAD_GROUP_USERS_FAILURE = 'LOAD_GROUP_USERS_FAILURE';
export const GROUP_USERS_CHANGED = 'GROUP_USERS_CHANGED';
export const GROUP_USERS_CLEAR = 'GROUP_USERS_CLEAR';

export const USERS_CREATED = 'USERS_CREATED';
export const USERS_CHANGED = 'USERS_CHANGED';

export const LOAD_USERS_START = 'LOAD_USERS_START';
export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';

export const PATCH_USERS_REQUEST = 'PATCH_USERS_REQUEST';
export const PATCH_USERS_SUCCESS = 'PATCH_USERS_SUCCESS';
export const PATCH_USERS_FAILURE = 'PATCH_USERS_FAILURE';

export const REVOKE_TWO_FA_USERS_REQUEST = 'REVOKE_TWO_FA_USERS_REQUEST';
export const REVOKE_TWO_FA_USERS_SUCCESS = 'REVOKE_TWO_FA_USERS_SUCCESS';
export const REVOKE_TWO_FA_USERS_FAILURE = 'REVOKE_TWO_FA_USERS_FAILURE';

export const CREATE_USERS_REQUEST = 'CREATE_USERS_REQUEST';
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS';
export const CREATE_USERS_FAILURE = 'CREATE_USERS_FAILURE';

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE';

export const LOAD_RIGHTS_REQUEST = 'LOAD_RIGHTS_REQUEST';
export const LOAD_RIGHTS_SUCCESS = 'LOAD_RIGHTS_SUCCESS';
export const LOAD_RIGHTS_FAILURE = 'LOAD_RIGHTS_FAILURE';
export const INVALIDATE_RIGHTS = 'INVALIDATE_RIGHTS';

export const PATCH_CURRENT_USER_PASSWORD_FAILURE = 'PATCH_CURRENT_USER_PASSWORD_FAILURE';
export const PATCH_CURRENT_USER_PASSWORD_REQUEST = 'PATCH_CURRENT_USER_PASSWORD_REQUEST';
export const PATCH_CURRENT_USER_PASSWORD_SUCCESS = 'PATCH_CURRENT_USER_PASSWORD_SUCCESS';

export const BULK_PATCH_INTRANET_GROUP_MEMBERS_FAILURE = 'BULK_PATCH_INTRANET_GROUP_MEMBERS_FAILURE';
export const BULK_PATCH_INTRANET_GROUP_MEMBERS_REQUEST = 'BULK_PATCH_INTRANET_GROUP_MEMBERS_REQUEST';
export const BULK_PATCH_INTRANET_GROUP_MEMBERS_SUCCESS = 'BULK_PATCH_INTRANET_GROUP_MEMBERS_SUCCESS';

export const BULK_PATCH_USER_INTRANET_GROUPS_FAILURE = 'BULK_PATCH_USER_INTRANET_GROUPS_FAILURE';
export const BULK_PATCH_USER_INTRANET_GROUPS_REQUEST = 'BULK_PATCH_USER_INTRANET_GROUPS_REQUEST';
export const BULK_PATCH_USER_INTRANET_GROUPS_SUCCESS = 'BULK_PATCH_USER_INTRANET_GROUPS_SUCCESS';

export const PATCH_GROUP_FAILURE = 'PATCH_GROUP_FAILURE';
export const PATCH_GROUP_REQUEST = 'PATCH_GROUP_REQUEST';
export const PATCH_GROUP_SUCCESS = 'PATCH_GROUP_SUCCESS';

export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
