import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {isTask, PlanningNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {PlanningStatus} from '@octaved/planning/src/PlanningStatus/PlanningStatus';
import {Message} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';

interface InfoProps {
  node: PlanningNode;
  status: PlanningStatus;
}

export default function Info({node, status}: InfoProps): ReactElement | null {
  const {t} = useTranslation();

  const isNotTask = !isTask(node);
  const {nodeIds: completedTaskIds} = useCombinedNodeSearch(
    isNotTask
      ? {
          and: [withDescendants(node.id, true), ['taskStatus', EnumFlowTaskStatus.VALUE_COMPLETE]],
        }
      : null,
  );

  if (status.overall === 'done') {
    return (
      <Message className={'max-w-md'} textSize={'sm'}>
        {t('nodeDetails:field.planningStatus.details.info.done')}
      </Message>
    );
  }

  if (status.overall === 'overdue') {
    return (
      <Message colorScheme={'error'} className={'mt-4 max-w-md'} textSize={'sm'}>
        {t('nodeDetails:field.planningStatus.details.info.overdueWarning')}
      </Message>
    );
  }

  if (status.logicalPredecessors === 'notDone' && status.time === 'none') {
    return (
      <Message className={'mt-4 max-w-md'} textSize={'sm'}>
        <Trans i18nKey={'nodeDetails:field.planningStatus.details.info.waitingForPredecessors'} />
      </Message>
    );
  }

  if (status.time === 'active') {
    if (status.overall === 'blocked') {
      return (
        <Message colorScheme={'error'} className={'mt-4 max-w-md'} textSize={'sm'}>
          {t('nodeDetails:field.planningStatus.details.info.cannotBeStarted')}
        </Message>
      );
    }

    if (isNotTask) {
      if (node.trackedMinutes.recorded > 0 || completedTaskIds.length > 0) {
        return (
          <Message className={'mt-4 max-w-md'} textSize={'sm'}>
            {t('nodeDetails:field.planningStatus.details.info.activeAndStarted')}
          </Message>
        );
      }

      return (
        <Message className={'mt-4 max-w-md'} textSize={'sm'}>
          {t('nodeDetails:field.planningStatus.details.info.activeNotStarted')}
        </Message>
      );
    }
  }

  return null;
}
