import {MutableRefObject, useEffect, useMemo, useRef} from 'react';
import {TableInstance} from 'react-table';
import {BaseRow} from './BaseRow';

/**
 * @param hiddenRecord a record of column ids to their hidden status. This object must not change its properties!!
 */
export function useReactTableHiddenColumns<D extends BaseRow, N extends string = string>(
  hiddenRecord: Record<N, boolean>,
): {
  hiddenColumns: string[];
  hiddenColumnInstanceRef: MutableRefObject<TableInstance<D> | null>;
} {
  const hiddenColumns = useMemo<string[]>(() => {
    return Object.keys(hiddenRecord).filter((key) => hiddenRecord[key as keyof typeof hiddenRecord]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(hiddenRecord));

  const hiddenColumnInstanceRef = useRef<TableInstance<D> | null>(null);

  useEffect(() => {
    if (hiddenColumnInstanceRef.current) {
      hiddenColumnInstanceRef.current.setHiddenColumns(hiddenColumns);
    }
  }, [hiddenColumns]);

  return {hiddenColumns, hiddenColumnInstanceRef};
}
