import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {CALL_API, ServerRequestAction, UrlParams} from '@octaved/network/src/NetworkMiddlewareTypes';
import {addPlanningToNodeEntityReducer} from '@octaved/planning/src/Modules/ExtendedReducer/NodeEntityReducer';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  INVALIDATED,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {optimisticAdd, optimisticUpdate} from '@octaved/store/src/Reducer/OptimisticReduce';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, getState} from '@octaved/store/src/Store';
import {getAllAncestorsForNodeIds, getAllDescendantsForRootIds} from '@octaved/trees/src/GenericTreeBuilder';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {USER_RIGHTS_CHANGED} from '@octaved/users/src/ActionTypes';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {generateUuid, getInstanceUuid} from '@octaved/utilities';
import {debounceReduxIdsAction} from '@octaved/utilities/src/DebounceReduxAction';
import {omit, once} from 'lodash';
import intersection from 'lodash/intersection';
import isPlainObject from 'lodash/isPlainObject';
import {NodeEntity, NodeEntityPatchData} from '../EntityInterfaces/NodeEntity';
import {NodeCreationOptions, Nodes, NodeType} from '../EntityInterfaces/Nodes';
import {Pid} from '../EntityInterfaces/Pid';
import {isPid, isSortableNode, isTimeTrackingNode} from '../Node/NodeIdentifiers';
import {
  FLOW_ARCHIVE_NODE_FAILURE,
  FLOW_ARCHIVE_NODE_REQUEST,
  FLOW_ARCHIVE_NODE_SUCCESS,
  FLOW_CHANGE_NODE_FAILURE,
  FLOW_CHANGE_NODE_REQUEST,
  FLOW_CHANGE_NODE_SUCCESS,
  FLOW_COPY_PID_REQUEST,
  FLOW_CREATE_GROUP_FAILURE,
  FLOW_CREATE_GROUP_REQUEST,
  FLOW_CREATE_GROUP_SUCCESS,
  FLOW_CREATE_NODE_FAILURE,
  FLOW_CREATE_NODE_REQUEST,
  FLOW_CREATE_NODE_SUCCESS,
  FLOW_CREATE_PROJECT_FAILURE,
  FLOW_CREATE_PROJECT_REQUEST,
  FLOW_CREATE_PROJECT_SUCCESS,
  FLOW_CREATE_WORK_PAGKAGE_FAILURE,
  FLOW_CREATE_WORK_PAGKAGE_REQUEST,
  FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
  FLOW_DELETE_BILLING_SUCCESS,
  FLOW_LOAD_NODES_FAILURE,
  FLOW_LOAD_NODES_REQUEST,
  FLOW_LOAD_NODES_SUCCESS,
  FLOW_MOVE_NODE_FAILURE,
  FLOW_MOVE_NODE_REQUEST,
  FLOW_MOVE_NODE_SUCCESS,
  FLOW_REMOVE_BOARD_POST_REQUEST,
  FLOW_REMOVE_PROJECT_FOLDER_REQUEST,
  FLOW_REMOVE_TASK_REQUEST,
  FLOW_REMOVE_TASK_SECTION_REQUEST,
  FLOW_RESORT_BOARD_POST_REQUEST,
} from './ActionTypes';
import {
  BoardPostsResortedEvent,
  CopyPidRequestEvent,
  GroupPatchedEvent,
  NodeArchivedEvent,
  NodeMovedEvent,
  NodeRestoredFromTrashEvent,
  NodesRearrangeEvent,
  NodesRemovedEvent,
  NodesRemoveEvent,
  NodesResortedEvent,
  PidCopiedEvent,
  PlanningEvent,
  ProjectPatchedEvent,
  SubWorkPackagePatchedEvent,
  TaskPatchedEvent,
  TimeRecordCreatedEvent,
  TimeRecordPatchedEvent,
  TimeRecordRemovedEvent,
  TimeRecordsRestoredFromTrashEvent,
  TrackedTimeAffectingEvent,
  WorkPackagePatchedEvent,
} from './Events';
import {createLabelRemovedOnRecordsReducer} from './ReduceRemovedLabels';
import {responsibleNodeTypes, responsibleProps} from './ResponsibleNode';
import {node as nodeSchema} from './Schema';
import {nodeEntitySelector, nodeEntityStatesSelector} from './Selectors/NodeSelectors';
import {
  extendWithAncestorsSelector,
  getAllDescendantIdsSelector,
  nodeTreeSelector,
} from './Selectors/NodeTreeSelectors';
import {FlowState} from './State'; //#region setCreatedNodeRights

//The keyValueStore should not patch deep:
const nodeFieldsToCopy = new Set(['keyValueStore.*']);

//#region State Reducer
export const nodeEntityStateReducers = createReducerCollection<EntityStates>({});
nodeEntityStateReducers.add(FLOW_LOAD_NODES_REQUEST, createTimestampReducer('options.data.ids', LOADING));
nodeEntityStateReducers.add(FLOW_LOAD_NODES_SUCCESS, createTimestampReducer('options.data.ids', LOADED));
nodeEntityStateReducers.add('flow.BillingCreatedEvent', createTimestampReducer('workPackageIds', INVALIDATED));
nodeEntityStateReducers.add(
  ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_SUCCESS],
  createTimestampReducer('workPackageIds', INVALIDATED),
);

nodeEntityStateReducers.add(
  [
    //NOTE: Prefer to add your events one reducer below to only invalidate if this wasn't the same tab instance!
    'flow.GroupPatchedEvent',
    'flow.NodePatchedEvent',
    'flow.MaterialResourcePatchedEvent',
    'flow.TaskPatchedEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  createTimestampReducer('nodeId', INVALIDATED),
);
nodeEntityStateReducers.add<ProjectPatchedEvent | SubWorkPackagePatchedEvent>(
  ['flow.ProjectPatchedEvent', 'flow.SubWorkPackagePatchedEvent'],
  createTimestampReducer('nodeId', INVALIDATED, true),
);

const INVALIDATE_NODE_IDS = 'INVALIDATE_NODE_IDS';

nodeEntityStateReducers.add(INVALIDATE_NODE_IDS, createTimestampReducer('nodeIds', INVALIDATED));

function clearAllDescendants(
  nodeIds: Uuid[],
  nodeTypes: ReadonlyArray<EnumFlowNodeType> | null = null,
  includeSelf = false,
): ActionDispatcher {
  return (dispatch, getState) => {
    let descendants = [...getAllDescendantsForRootIds(nodeTreeSelector(getState()), nodeIds, includeSelf)];
    if (nodeTypes) {
      const nodes = nodeEntitySelector(getState());
      descendants = descendants.filter((id) => {
        const node = nodes[id];
        return node && nodeTypes.includes(node.nodeType);
      });
    }
    dispatch({
      nodeIds: descendants,
      type: INVALIDATE_NODE_IDS,
    });
  };
}

subscribe('flow.ProjectPatchedEvent', (action: ProjectPatchedEvent) => {
  if (action.patchedKeys.includes('flowCustomer')) {
    //Changing the customer might remove users from the responsibleUsers:
    return clearAllDescendants([action.nodeId], null, true);
  }
  return undefined;
});

subscribe('flow.GroupPatchedEvent', (action: GroupPatchedEvent) => {
  if (responsibleProps.some((key) => action.patchedKeys.includes(key))) {
    //responsibleGroups/responsibleUsers may inherit differently now:
    return clearAllDescendants([action.nodeId], responsibleNodeTypes);
  }
  return undefined;
});

subscribe('flow.WorkPackagePatchedEvent', (action: WorkPackagePatchedEvent) => {
  if (responsibleProps.some((key) => action.patchedKeys.includes(key))) {
    //responsibleGroups/responsibleUsers may inherit differently now:
    return clearAllDescendants([action.nodeId], responsibleNodeTypes);
  }
  return undefined;
});

subscribe('flow.SubWorkPackagePatchedEvent', (action: SubWorkPackagePatchedEvent) => {
  if (responsibleProps.some((key) => action.patchedKeys.includes(key))) {
    //responsibleGroups/responsibleUsers may inherit differently now:
    return clearAllDescendants([action.nodeId], responsibleNodeTypes);
  }
  return undefined;
});

subscribe('flow.TaskPatchedEvent', (action: TaskPatchedEvent) => {
  if (responsibleProps.some((key) => action.patchedKeys.includes(key))) {
    //responsibleGroups/responsibleUsers may inherit differently now:
    return clearAllDescendants([action.nodeId], responsibleNodeTypes);
  }
  return undefined;
});

subscribe('flow.NodesRearrangeEvent', (action: NodesRearrangeEvent) => {
  if (action.oldParentNodeId !== action.newParentNodeId) {
    //responsibleGroups/responsibleUsers may inherit differently now:
    return clearAllDescendants([action.movedNodeId], null, true);
  }
  return undefined;
});

subscribe('flow.NodeMovedEvent', (action: NodeMovedEvent) => {
  //responsibleGroups/responsibleUsers may inherit differently now:
  return clearAllDescendants([action.nodeId], null, true);
});

//Invalidate the copied node because not everything is optimistically known client-side (e.g. planning dates):
nodeEntityStateReducers.add('flow.PidCopiedEvent', createTimestampReducer('copiedNodeId', INVALIDATED));
nodeEntityStateReducers.add(FLOW_CREATE_NODE_REQUEST, createTimestampReducer('options.data.id', LOADED));
nodeEntityStateReducers.add(FLOW_COPY_PID_REQUEST, createTimestampReducer('copyNode.id', LOADED));

nodeEntityStateReducers.add(USER_RIGHTS_CHANGED, () => ({}));

export const nodeEntityStateReducer = nodeEntityStateReducers.reducer;
//#endregion
//#region Entitiy Reducer
export const nodeEntityReducers = createReducerCollection<Nodes>({});

nodeEntityReducers.add<PlanningEvent>('flow.PlanningEvent', (state, action) => {
  if (
    action.responsibleInstanceId === getInstanceUuid() &&
    !['NodeMoved', 'NodeRemoved', 'OnCopyTasks', 'WorkpackageCreated'].includes(action.source)
  ) {
    return state;
  }
  const newState = {...state};
  let hasChanged = false;

  Object.entries(action.updatedDueDates).forEach(([id, patch]) => {
    const entity = newState[id];
    if (entity) {
      const newEntity = mergeStates(entity, patch);
      if (entity !== newEntity) {
        newState[id] = newEntity;
        hasChanged = true;
      }
    }
  });

  for (const [id, planningDates] of Object.entries(action.updatedNodePlanningDates)) {
    const entity = newState[id];
    if (entity) {
      const newEntity = mergeStates(entity, {
        planningDates: planningDates?.map(({id}) => id) || [],
      });
      if (entity !== newEntity) {
        newState[id] = newEntity;
        hasChanged = true;
      }
    }
  }

  for (const [id, planningPredecessors] of [...Object.entries(action.updatedDependencies.planningPredecessors)]) {
    const entity = newState[id];
    if (entity) {
      const newEntity = mergeStates(entity, {planningPredecessors});
      if (entity !== newEntity) {
        newState[id] = newEntity;
        hasChanged = true;
      }
    }
  }

  for (const [id, planningSuccessors] of [...Object.entries(action.updatedDependencies.planningSuccessors)]) {
    const entity = newState[id];
    if (entity) {
      const newEntity = mergeStates(entity, {planningSuccessors});
      if (entity !== newEntity) {
        newState[id] = newEntity;
        hasChanged = true;
      }
    }
  }
  return hasChanged ? newState : state;
});

export function createNodeEntity(nodeType: EnumFlowNodeType): NodeEntity {
  const userId = currentOrgUserIdSelector(getState());
  const now = unix();
  return {
    nodeType,
    assignedPlanningDates: [],
    color: '',
    createdBy: userId,
    createdOn: now,
    id: generateUuid(),
    isArchived: false,
    keyValueStore: {},
    labels: [],
    lastChangedBy: userId,
    lastChangedOn: now,
    name: '',
    planningDates: [],
    planningLogicalPredecessors: [],
    planningLogicalSuccessors: [],
    planningPredecessors: [],
    planningSuccessors: [],
    referenceNumber: '',
  };
}

export function getNodeEntityCopiedFields(): Array<keyof NodeEntity> {
  return ['color', 'keyValueStore', 'labels', 'name'];
}

export function transformNodeEntityToPatchData<R extends NodeEntityPatchData>(nodeEntity: NodeEntity): R {
  return {
    ...omit(nodeEntity, ['planningDates', 'assignedPlanningDates']),
  } as R;
}

export function transformPatchDataToNodeEntity<R extends NodeEntity>(
  nodeEntity: DeepPartial<NodeEntityPatchData>,
): DeepPartial<R> {
  return {
    ...nodeEntity,
  } as DeepPartial<R>;
}

export function createNodeErrorFields(id: Uuid): string[] {
  return [`name_${id}`, `referenceNumber_${id}`];
}

{
  const reducer = optimisticUpdate<Nodes, ServerRequestAction<{patchedNodeId: Uuid; urlParams: UrlParams}>>(
    ({patchedNodeId}) => patchedNodeId,
    undefined,
    (a, b, opts) =>
      mergeStates(a, b, {...(opts || {}), fieldsToCopy: new Set([...(opts?.fieldsToCopy || []), ...nodeFieldsToCopy])}),
  );
  nodeEntityReducers.add(FLOW_CHANGE_NODE_REQUEST, reducer.onRequest);
  nodeEntityReducers.add(FLOW_CHANGE_NODE_FAILURE, reducer.onRevert);
  nodeEntityReducers.add(FLOW_CHANGE_NODE_SUCCESS, reducer.onSuccess);
}

{
  const reducer = optimisticAdd<Nodes, ServerRequestAction<NodeCreationOptions>>(
    ({options}) => options.data.id,
    (action) => {
      const data = action?.options?.data || null;
      if (data) {
        return {
          ...data,
          assignedPlanningDates: [],
          planningDates: [],
          planningPredecessors: [],
          planningSuccessors: [],
        };
      }
      return data;
    },
  );
  //handle pid seperatly to add missing default data
  const nonPid = (ident: string): boolean => !ident.startsWith('FLOW_CREATE_PID');
  nodeEntityReducers.add(FLOW_CREATE_NODE_REQUEST.filter(nonPid), reducer.onRequest);
  nodeEntityReducers.add(FLOW_CREATE_NODE_FAILURE.filter(nonPid), reducer.onRevert);
  nodeEntityReducers.add(FLOW_CREATE_NODE_SUCCESS.filter(nonPid), reducer.onSuccess);
}

{
  const reducer = optimisticAdd<Nodes, ServerRequestAction<NodeCreationOptions>>(
    ({options}) => options.data.id,
    (action) => {
      const data = action?.options?.data || null;
      if (data) {
        return {
          ...data,
          planningPredecessors: [],
          planningSuccessors: [],
          timeRecords: [],
        };
      }
      return data;
    },
  );
  //handle pid seperatly to add missing default data
  nodeEntityReducers.add(
    [FLOW_CREATE_PROJECT_REQUEST, FLOW_CREATE_GROUP_REQUEST, FLOW_CREATE_WORK_PAGKAGE_REQUEST],
    reducer.onRequest,
  );
  nodeEntityReducers.add(
    [FLOW_CREATE_PROJECT_FAILURE, FLOW_CREATE_GROUP_FAILURE, FLOW_CREATE_WORK_PAGKAGE_FAILURE],
    reducer.onRevert,
  );
  nodeEntityReducers.add(
    [FLOW_CREATE_PROJECT_SUCCESS, FLOW_CREATE_GROUP_SUCCESS, FLOW_CREATE_WORK_PAGKAGE_SUCCESS],
    reducer.onSuccess,
  );
}

const nodesRootReducers = createReducerCollection<FlowState>({} as FlowState);
export const nodesRootReducer = nodesRootReducers.reducer;

function reduceInvalidateAncestry(state: FlowState, nodeIds: Uuid[]): FlowState {
  const ancestorIds = extendWithAncestorsSelector(state)(nodeIds);
  const newNodeStates = createTimestampReducer('ancestorIds', INVALIDATED)(state.entityStates.node, {ancestorIds});
  return {...state, entityStates: {...state.entityStates, node: newNodeStates}};
}

function reduceNodesFromRoot(state: FlowState, cb: (n: NodeType) => NodeType): FlowState {
  const prevNodes = nodeEntitySelector(state);
  const nextNodes = {...prevNodes};
  let changed = false;
  Object.values(prevNodes).forEach((prev) => {
    if (prev) {
      const next = cb(prev);
      if (next !== prev) {
        changed = true;
        nextNodes[prev.id] = next;
      }
    }
  });
  return changed ? {...state, entities: {...state.entities, node: nextNodes}} : state;
}

nodesRootReducers.add<NodeArchivedEvent>('flow.NodeArchivedEvent', (state, {nodeId, archived}) => {
  const descendantIds = getAllDescendantIdsSelector(state)(nodeId, true);
  return reduceNodesFromRoot(state, (node) => {
    if (descendantIds.has(node.id) && node.isArchived !== archived) {
      return {...node, isArchived: archived};
    }
    return node;
  });
});

nodesRootReducers.add<NodeRestoredFromTrashEvent>('flow.NodeRestoredFromTrashEvent', (state, action) => {
  //must invalidate entire ancestry to reload the calculated time tracking values
  return reduceInvalidateAncestry(state, [action.parentNodeId, ...action.allAffectedNodeIds]);
});

nodesRootReducers.add<TimeRecordsRestoredFromTrashEvent>('flow.TimeRecordsRestoredFromTrashEvent', (state, action) => {
  //must invalidate entire ancestry to reload the calculated time tracking values
  return reduceInvalidateAncestry(state, action.referenceNodeIds);
});

const init = once(() => {
  addPlanningToNodeEntityReducer(nodeEntityReducers);
});

function hasSortOrder(node: unknown): node is {sortOrder: number} {
  return isPlainObject(node) && (node as Record<string, unknown>).hasOwnProperty('sortOrder');
}

const resort = (state: Nodes, {nodeIds}: NodesResortedEvent | NodesRearrangeEvent | BoardPostsResortedEvent): Nodes => {
  let changed = false;
  const newState = {...state};
  nodeIds.forEach((id, index) => {
    const node = newState[id];
    if (hasSortOrder(node) && node.sortOrder !== index) {
      newState[id] = {...node, sortOrder: index};
      changed = true;
    }
  });
  return changed ? newState : state;
};
nodeEntityReducers.add(['flow.NodesResortedEvent', FLOW_RESORT_BOARD_POST_REQUEST], resort);

function patchFlowCustomers(state: Nodes, nodeIds: ReadonlyArray<Uuid>, flowCustomerId: Uuid): Nodes {
  let changed = false;
  const newState = {...state};
  nodeIds.forEach((id) => {
    const node = newState[id];
    if (isPid(node) && node.flowCustomer !== flowCustomerId) {
      newState[id] = {...node, flowCustomer: flowCustomerId};
      changed = true;
    }
  });
  return changed ? newState : state;
}

nodeEntityReducers.add('flow.NodesRearrangeEvent', (state: Nodes, action: NodesRearrangeEvent): Nodes => {
  let newState = state;
  newState = resort(newState, action);
  newState = reduceAffectedTrackedTime(newState, action);
  if (action.newFlowCustomerId) {
    newState = patchFlowCustomers(newState, action.changedFlowCustomerPidIds, action.newFlowCustomerId);
  }
  return newState;
});

nodeEntityReducers.add(
  [
    FLOW_REMOVE_BOARD_POST_REQUEST,
    FLOW_REMOVE_PROJECT_FOLDER_REQUEST,
    FLOW_REMOVE_TASK_REQUEST,
    FLOW_REMOVE_TASK_SECTION_REQUEST,
    'flow.NodesRemovedEvent',
  ],
  (state, {nodeIds}: NodesRemoveEvent | NodesRemovedEvent) => {
    let changed = false;
    const newState = {...state};
    const nodeIdsSet = new Set(nodeIds);

    function removeFromArray(
      node: NodeType,
      field:
        | 'planningLogicalPredecessors'
        | 'planningLogicalSuccessors'
        | 'planningPredecessors'
        | 'planningSuccessors',
    ): void {
      const array = node[field];
      if (intersection(array, nodeIds).length) {
        changed = true;
        newState[node.id] = {...node, [field]: array.filter((id) => !nodeIdsSet.has(id))};
      }
    }

    (Object.values(newState) as NodeType[]).forEach((node) => {
      removeFromArray(node, 'planningLogicalPredecessors');
      removeFromArray(node, 'planningLogicalSuccessors');
      removeFromArray(node, 'planningPredecessors');
      removeFromArray(node, 'planningSuccessors');
    });

    return changed ? newState : state;
  },
);

function onNodesRemoved<S extends EntityStates | Nodes>(state: S, {nodeIds}: NodesRemovedEvent): S {
  let changed = false;
  const newState = {...state};
  nodeIds.forEach((nodeId) => {
    if (newState[nodeId]) {
      delete newState[nodeId];
      changed = true;
    }
  });
  return changed ? newState : state;
}

nodeEntityReducers.add<NodesRemovedEvent>('flow.NodesRemovedEvent', onNodesRemoved);
nodeEntityStateReducers.add<NodesRemovedEvent>('flow.NodesRemovedEvent', onNodesRemoved);

nodeEntityReducers.add(FLOW_COPY_PID_REQUEST, (state: Nodes, action: CopyPidRequestEvent): Nodes => {
  const newState: Nodes = {
    ...state,
    [action.copyNode.id]: action.copyNode,
  };

  action.incrementSortOrderPidIds.forEach((id) => {
    newState[id] = {
      ...newState[id],
      sortOrder: (newState[id] as Pid).sortOrder + 1,
    } as Pid;
  });

  return newState;
});

nodeEntityReducers.add('flow.PidCopiedEvent', (state: Nodes, action: PidCopiedEvent): Nodes => {
  const newState = {...state};
  let changed = false;
  Object.entries(action.updatedSortOrders).forEach(([id, sortOrder]) => {
    const node = newState[id];
    if (isSortableNode(node) && node.sortOrder !== sortOrder) {
      newState[id] = {...node, sortOrder};
      changed = true;
    }
  });
  return changed ? newState : state;
});
nodeEntityReducers.add('flow.LabelRemovedEvent', createLabelRemovedOnRecordsReducer());

function reduceAffectedTrackedTime(state: Nodes, {affectedTimeTrackingNodes}: TrackedTimeAffectingEvent): Nodes {
  if (!affectedTimeTrackingNodes.length) {
    return state;
  }
  const newState = {...state};
  affectedTimeTrackingNodes.forEach(({id, ...trackedMinutes}) => {
    const node = newState[id];
    if (isTimeTrackingNode(node)) {
      newState[id] = {...node, trackedMinutes};
    }
  });
  return newState;
}

nodeEntityReducers.add<NodesRemovedEvent | TimeRecordCreatedEvent | TimeRecordPatchedEvent | TimeRecordRemovedEvent>(
  [
    'flow.NodesRemovedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
  ],
  reduceAffectedTrackedTime,
);

export const nodeEntityReducer = nodeEntityReducers.reducer;
nodeEntityReducer.fieldsToCopy = nodeFieldsToCopy;

//#endregion

export const loadNodes = debounceReduxIdsAction((nodeIds: ReadonlyArray<Uuid>) => {
  return (dispatch, getState) => {
    init();
    const state = getState();
    const nodesWithAncestors = getAllAncestorsForNodeIds(nodeTreeSelector(state), nodeIds, true);
    const toLoad = filterIdsToReload(nodeEntityStatesSelector(state), [...nodesWithAncestors]);
    if (toLoad.length === 0) {
      return;
    }
    dispatch({
      [CALL_API]: {
        endpoint: routes.getNodes,
        method: 'post',
        options: {
          data: {ids: toLoad},
        },
        schema: [nodeSchema],
        types: {
          failureType: FLOW_LOAD_NODES_FAILURE,
          requestType: FLOW_LOAD_NODES_REQUEST,
          successType: FLOW_LOAD_NODES_SUCCESS,
        },
      },
    });
  };
});

export function archiveNode(nodeId: Uuid, archive: boolean): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch) => {
    await dispatch({
      [CALL_API]: {
        endpoint: routes.archiveNode,
        method: 'post',
        options: {
          urlParams: {nodeId, archive: +archive},
        },
        types: {
          failureType: FLOW_ARCHIVE_NODE_FAILURE,
          requestType: FLOW_ARCHIVE_NODE_REQUEST,
          successType: FLOW_ARCHIVE_NODE_SUCCESS,
        },
      },
    });
  };
}

export function moveNode(nodeId: Uuid, parentNodeId: Uuid | null): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch) => {
    await dispatch({
      [CALL_API]: {
        endpoint: routes.moveNode,
        method: 'patch',
        options: {
          urlParams: {nodeId, parentNodeId},
        },
        types: {
          failureType: FLOW_MOVE_NODE_FAILURE,
          requestType: FLOW_MOVE_NODE_REQUEST,
          successType: FLOW_MOVE_NODE_SUCCESS,
        },
      },
    });
  };
}
