import type {FlowState} from '@octaved/flow/src/Modules/State';
import {createSelector, Selector} from 'reselect';

type RecordSelector<T, S> = Selector<S, Record<string, T | undefined>>;

type Cache<T, S> = WeakMap<ReadonlyArray<string> | ReadonlySet<string>, RecordSelector<T, S>>;

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
const caches = new WeakMap<Function, Cache<unknown, unknown>>();

export function createSubRecordSelector<T, S = FlowState>(
  selector: RecordSelector<T, S>,
  ids: ReadonlyArray<string> | ReadonlySet<string>,
): RecordSelector<T, S> {
  let cache = caches.get(selector) as Cache<T, S> | undefined;
  if (!cache) {
    cache = new WeakMap();
    caches.set(selector, cache as Cache<unknown, unknown>);
  }

  let subRecordSelector = cache.get(ids);
  if (!subRecordSelector) {
    let lastSubRecord: Record<string, T | undefined> = {};
    subRecordSelector = createSelector(selector, (fullRecord) => {
      let hasChanged = false;
      const newSubRecord: Record<string, T | undefined> = {};
      ids.forEach((id) => {
        if (fullRecord[id]) {
          newSubRecord[id] = fullRecord[id];
        }
        if (lastSubRecord[id] !== fullRecord[id]) {
          hasChanged = true;
        }
      });
      if (hasChanged) {
        lastSubRecord = newSubRecord;
      }
      return lastSubRecord;
    });
    cache.set(ids, subRecordSelector);
  }

  return subRecordSelector;
}
