import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {canReadNodeRoleAssignmentsSelector} from '@octaved/flow/src/Modules/Selectors/RoleAssignments/NodeRoleAssignmentSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import ResponsibleUnits from '../../../Components/Fields/Responsible/ResponsibleUnits';
import SimpleRoleAssignment from '../../../Components/Fields/Responsible/SimpleRoleAssignment/SimpleRoleAssignment';
import SimpleRoleAssignmentHelpPopup from '../../../Components/Fields/Responsible/SimpleRoleAssignment/SimpleRoleAssignmentHelpPopup';
import Section from '../../../Components/Fields/Section';
import InspectorViewFrame from '../../InspectorViewFrame';
import {SubWorkPackageDetailsProps} from './General';

export default function Responsibility({node}: SubWorkPackageDetailsProps): ReactElement {
  const canReadRoleAssignments = useSelector((s: FlowState) =>
    canReadNodeRoleAssignmentsSelector(s)(EnumFlowNodeRoleType.VALUE_PROJECT, false, node.id),
  );
  return (
    <InspectorViewFrame>
      <ResponsibleUnits node={node} frame={Section} />

      {canReadRoleAssignments && (
        <Section
          labelToken={'pages:projects.inspector.projectRoles.label'}
          labelAddition={<SimpleRoleAssignmentHelpPopup roleType={EnumFlowNodeRoleType.VALUE_PROJECT} />}
        >
          <SimpleRoleAssignment nodeId={node.id} type={EnumFlowNodeRoleType.VALUE_PROJECT} />
        </Section>
      )}
    </InspectorViewFrame>
  );
}
