import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeResponsibleUnitsSelector} from '@octaved/flow/src/Modules/Selectors/ResponsibleNodeSelectors';
import {canReadNodeRoleAssignmentsSelector} from '@octaved/flow/src/Modules/Selectors/RoleAssignments/NodeRoleAssignmentSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import ResponsibleUnits from '../../../Components/Fields/Responsible/ResponsibleUnits';
import SimpleRoleAssignment from '../../../Components/Fields/Responsible/SimpleRoleAssignment/SimpleRoleAssignment';
import SimpleRoleAssignmentHelpPopup from '../../../Components/Fields/Responsible/SimpleRoleAssignment/SimpleRoleAssignmentHelpPopup';
import Section from '../../../Components/Fields/Section';
import InspectorViewFrame from '../../InspectorViewFrame';
import {ProjectDetailsProps} from './General';

export default function Responsibility({node}: ProjectDetailsProps): ReactElement {
  const canReadRoleAssignments = useSelector((s: FlowState) =>
    canReadNodeRoleAssignmentsSelector(s)(EnumFlowNodeRoleType.VALUE_PROJECT, false, node.id),
  );
  const responsibleUnits = useSelector((s: FlowState) => getNodeResponsibleUnitsSelector(s)(node.id));
  const optionals = useOptionalFields(node.id, {
    responsibleUnits: responsibleUnits.length,
  });

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      <EnableFieldButton field={optionals.responsibleUnits} label={'nodeDetails:field.responisbility.enable'} />
    </div>
  );

  return (
    <InspectorViewFrame footer={footer}>
      {canReadRoleAssignments && (
        <Section
          labelToken={'pages:projects.inspector.projectRoles.label'}
          labelAddition={<SimpleRoleAssignmentHelpPopup roleType={EnumFlowNodeRoleType.VALUE_PROJECT} />}
        >
          <SimpleRoleAssignment nodeId={node.id} type={EnumFlowNodeRoleType.VALUE_PROJECT} />
        </Section>
      )}

      {optionals.responsibleUnits.show && <ResponsibleUnits node={node} frame={Section} />}
    </InspectorViewFrame>
  );
}
