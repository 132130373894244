import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {getEffectiveMaxEffort} from '@octaved/flow/src/WorkPackage/MaxEffort';
import {Tooltip} from '@octaved/ui';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';

interface TaskEffortExceedsTimeLimitFlameProps {
  node: WorkPackage;
  totalTaskSum: number;
}

export default function TaskEffortExceedsTimeLimitFlame({
  node,
  totalTaskSum,
}: TaskEffortExceedsTimeLimitFlameProps): ReactElement | null {
  const maxEffort = getEffectiveMaxEffort(node);

  if (maxEffort && maxEffort < totalTaskSum) {
    return (
      <Tooltip toolTipTranslation={'nodeDetails:field.tasks.tasksHours.exceedsTimeLimit'} position={'top right'}>
        <Flame className={'h-4 w-4 text-red-500'} />
      </Tooltip>
    );
  }
  return null;
}
