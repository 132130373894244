import {Uuid} from '@octaved/typescript/src/lib';
import {PropsWithChildren, ReactElement, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {ProjectFolderContextProvider} from '../../../Drawer/ProjectFolder/ProjectFolderContext';
import {projectsSelectedProjectFolderSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useProjectUiStatePatch} from '../Projects';

interface Props {
  isDisabled: boolean;
}
export default function ProjectsProjectFolderContext({children, isDisabled}: PropsWithChildren<Props>): ReactElement {
  const patchProjectsUi = useProjectUiStatePatch();
  const selectedFolder = useSelector(projectsSelectedProjectFolderSelector);
  const save = useCallback(
    (selectedProjectFolder: Uuid | null) => patchProjectsUi({selectedProjectFolder}),
    [patchProjectsUi],
  );

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <ProjectFolderContextProvider canSelectSubFolder selected={selectedFolder} setSelected={save}>
      {children}
    </ProjectFolderContextProvider>
  );
}
