import classNames from 'classnames';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

interface Props {
  color: 'purple' | 'orange' | 'teal';
  header: string;
  subHeader?: string;
}

export default function SectionHeader({color, header, subHeader}: Props): ReactElement {
  return (
    <div className={classNames('header', color, {noSubHeader: !subHeader})}>
      <div className={'sectionHeader'}>
        <span>
          <Trans i18nKey={header} />
        </span>
      </div>
      {subHeader && (
        <div className={'sectionSubHeader'}>
          <span>
            <Trans i18nKey={subHeader} />
          </span>
        </div>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .header {
          margin: 40px 0 20px 0;
        }

        .sectionHeader {
          margin-bottom: 14px;
          font-weight: 600;
          color: #333;
          font-size: 18px;
          position: relative;
        }

        .sectionHeader::after {
          position: absolute;
          bottom: -7px;
          left: 0;
          content: ' ';
          height: 2px;
          width: 50px;
        }

        .purple .sectionHeader::after {
          background-color: rgb(185, 64, 203);
        }

        .orange .sectionHeader::after {
          background-color: rgb(231, 128, 0);
        }

        .teal .sectionHeader::after {
          background-color: rgb(0, 208, 179);
        }

        .sectionSubHeader {
          font-size: 14px;
          line-height: 16px;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
