import {PropsWithChildren, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {cn} from './Hooks';

interface Props {
  actions?: ReactElement;
  contentClassName?: string;
  headerClassName?: string;
  title: string;
}

type TileProps = PropsWithChildren<Props>;

function Tile({actions, contentClassName, children, headerClassName, title}: TileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'flex flex-col rounded border border-slate-300 bg-white'}>
      <div
        className={cn('flex h-14 items-center justify-between border-b border-slate-300 px-4 py-2', headerClassName)}
      >
        <h2 className={'mb-0 text-lg font-semibold text-slate-600'}>{t(title)}</h2>
        {actions}
      </div>
      <div className={cn('p-4', contentClassName)}>{children}</div>
    </div>
  );
}

export {Tile};
export type {TileProps};
