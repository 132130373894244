import Konva from 'konva';
import {KonvaEventObject} from 'konva/lib/Node';
import {setMouseHoverEffect} from '../MouseHoverCursor';
import {RootContainer, RootContainerProps} from '../RootContainer';
import {IconNode, ImageOptions} from '../SvgToCanvas';

type GhostButtonImageOptions = Omit<ImageOptions, 'width' | 'height'>;
interface GhostButtonProps extends RootContainerProps {
  icon: IconNode;
  imageSize?: number;
  imageOptions?: GhostButtonImageOptions;
  buttonSize?: number;
  onClick(e: KonvaEventObject<MouseEvent>): void;
}

export class GhostButton extends RootContainer {
  readonly #icon: IconNode;
  readonly #imageSize: number;
  readonly #buttonSize: number;
  readonly #onClick: (e: KonvaEventObject<MouseEvent>) => void;
  readonly #imageOptions: GhostButtonImageOptions;

  constructor(props: GhostButtonProps) {
    super(props);
    this.#icon = props.icon;
    this.#imageSize = props.imageSize ?? 16;
    this.#buttonSize = props.buttonSize ?? 24;
    this.#onClick = props.onClick;
    this.#imageOptions = props.imageOptions ?? {};
  }

  init(): void {
    this.iconToImage(this.#icon, {
      height: this.#imageSize,
      imageConfig: {listening: false},
      width: this.#imageSize,
      ...this.#imageOptions,
    }).then((image) => {
      this.root.add(image);
    });

    const background = new Konva.Rect({
      cornerRadius: 5,
      fill: '#00000012',
      height: this.#buttonSize,
      name: 'hoverBackground',
      opacity: 0,
      width: this.#buttonSize,
      x: (this.#buttonSize - this.#imageSize) * -0.5,
      y: (this.#buttonSize - this.#imageSize) * -0.5,
    });
    this.root.add(background);

    setMouseHoverEffect(background, 'pointer');
    background.on('click tap', (e) => {
      this.#onClick(e);
    });
    background.on('mouseover', () => {
      background.opacity(1);
    });
    background.on('mouseout', () => {
      background.opacity(0);
    });
  }

  get imageSize(): number {
    return this.#imageSize;
  }

  get buttonSize(): number {
    return this.#buttonSize;
  }
}
