import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {MaterialResource} from '../../EntityInterfaces/MaterialResource';
import {isMaterialResource} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector} from './NodeSelectors';

export const getMaterialResourceSelector = createSelector(
  nodeEntitySelector,
  (nodes) =>
    (id: Uuid | undefined | null): MaterialResource | undefined => {
      const node = nodes[id!];
      if (node && !isMaterialResource(node)) {
        throw new Error(`Material Resource requested, but '${node?.nodeType}' type found`);
      }
      return node;
    },
);
