import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';

export type IndicatorColor = 'grey' | 'blue' | 'red' | 'orange' | 'green' | 'yellow' | 'greenAlt' | 'purple';

interface Props {
  color: IndicatorColor;
  children?: ReactNode;
  size?: 'normal' | 'small';
}

export default function Indicator({color, children, size = 'normal'}: Props): ReactElement {
  return (
    <div className={classNames('indicator', color, size)}>
      {children}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .indicator {
          height: var(--indicator-size, 20px);
          width: var(--indicator-size, 20px);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .small {
          height: var(--indicator-size, 8px);
          width: var(--indicator-size, 8px);
        }

        .grey {
          background-color: #ccc;
        }

        .green {
          background-color: #219653;
        }

        .greenAlt {
          background-color: rgb(99, 160, 8);
        }

        .orange {
          background-color: #f7821b;
        }

        .yellow {
          background-color: rgb(255, 168, 0);
        }

        .red {
          background-color: #cf1e4d;
        }

        .blue {
          background-color: #2388de;
        }

        .purple {
          background-color: #982bc6;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
