import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';

export const dependencyNames = new Map<EnumFlowPlanningDependencyType, {short: string; long: string}>([
  [
    EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR,
    {
      long: 'pages:projects.inspector.manage.planning.startAfterPredecessor',
      short: 'pages:projects.inspector.manage.planning.startAfterPredecessorShort',
    },
  ],
  [
    EnumFlowPlanningDependencyType.VALUE_ENDS_WITH_PREDECESSOR,
    {
      long: 'pages:projects.inspector.manage.planning.endsWithPredecessor',
      short: 'pages:projects.inspector.manage.planning.endsWithPredecessorShort',
    },
  ],
  [
    EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR,
    {
      long: 'pages:projects.inspector.manage.planning.startsWithPredecessor',
      short: 'pages:projects.inspector.manage.planning.startsWithPredecessorShort',
    },
  ],
]);
