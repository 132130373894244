import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Group} from 'konva/lib/Group';
import {getDaysFromStart, getWidth} from '../../Calculations/DateCalculations';
import {CalendarContext} from '../Calendar/Context/CalendarContext';
import {TreeNode} from '../Gantt/Data/TreeNode';
import {RootContainer, RootContainerProps} from '../RootContainer';
import {BarPlugin} from './Plugins/BarPlugin';

export interface BarProps extends RootContainerProps {
  isFirst: boolean;
  isLast: boolean;
  rowIndex: number;
  rowRoot: Group;
  menuRoot: Group;
  treeNode: TreeNode;
  height: number;
  yOffset: number;
}

export type BarPluginList = Array<BarPlugin | undefined | null | false>;

export class Bar extends RootContainer {
  protected id: Uuid;
  protected readonly isFirst: boolean;
  protected readonly isLast: boolean;
  readonly treeNode: TreeNode;

  #width = 0;
  #height: number;
  #yOffset: number;

  #plannedStart: DateTimeStr | null = null;
  #plannedEnd: DateTimeStr | null = null;
  #menuRoot: Group;

  protected readonly plugins: BarPlugin[] = [];
  #rowIndex: number;

  constructor(props: BarProps) {
    super(props);

    this.treeNode = props.treeNode;
    this.id = props.treeNode.id;
    this.isFirst = props.isFirst;
    this.isLast = props.isLast;
    this.#rowIndex = props.rowIndex;
    this.#menuRoot = props.menuRoot;
    this.#height = props.height;
    this.#yOffset = props.yOffset;

    props.rowRoot.add(this.root);
  }

  init(...plugins: BarPluginList): void {
    this.root.name(`${this.root.name()} rootBar nodeId_${this.treeNode.id}`);
    this.root.y(this.y);
    this.#addPlugin(...plugins);

    this.disposables.push(
      this.ctx.eventEmitter.on('calenderDatesChanged', (e) => {
        if (e.startChanged && this.#plannedStart && this.#plannedEnd) {
          this.setDates(this.#plannedStart, this.#plannedEnd);
        }
      }),
    );
  }

  #addPlugin(...plugins: BarPluginList): void {
    for (const plugin of plugins) {
      if (plugin) {
        this.root.add(plugin.root);
        plugin.init(this);
        this.disposables.push(() => plugin.dispose());
        this.plugins.push(plugin);
      }
    }
  }

  #updatePlugins(): void {
    this.plugins.forEach((p) => {
      if (!p.disabled) {
        p.onBarUpdated();
      }
    });
  }

  setDates(plannedStart: DateTimeStr, plannedEnd: DateTimeStr): void {
    this.#plannedStart = plannedStart;
    this.#plannedEnd = plannedEnd;
    const startInDays = getDaysFromStart(isoDateTimeToIsoFormat(plannedStart), this.ctx.calendarView.dateStart);
    const widthInDays = getWidth(isoDateTimeToIsoFormat(plannedStart), isoDateTimeToIsoFormat(plannedEnd)) + 1;
    this.root.x(startInDays * this.ctx.columnWidth + this.barPaddingX - 0.5);
    this.#width = widthInDays * this.ctx.columnWidth - this.barPaddingX * 2;
    this.#updatePlugins();
  }

  get width(): number {
    return this.#width;
  }

  get height(): number {
    return this.#height - this.barPaddingY * 2;
  }

  get x(): number {
    return this.root.x();
  }

  get absolutY(): number {
    return this.#rowIndex * this.ctx.rowHeight + this.barPaddingY + this.#yOffset;
  }

  get y(): number {
    return this.barPaddingY + this.#yOffset;
  }

  set rowIndex(value: number) {
    this.#rowIndex = value;
  }

  get barPaddingY(): number {
    return Bar.barPaddingY(this.ctx);
  }

  get barPaddingX(): number {
    return Bar.barPaddingX(this.ctx);
  }

  get nodeId(): Uuid {
    return this.id;
  }

  get menuRoot(): Group {
    return this.#menuRoot;
  }

  static barPaddingY(ctx: CalendarContext): number {
    return ctx.columnWidth >= 20 ? 3 : 5;
  }

  static barPaddingX(ctx: CalendarContext): number {
    return ctx.columnWidth >= 20 ? 3 : 1;
  }
}
