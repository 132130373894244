import {
  RulesList,
  validateDecimalIfValidStringFormat,
  ValidateDecimalOptions,
  validateNumberStringFormat,
} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {BudgetEntity, BudgetEntityPatchData} from '../EntityInterfaces/BudgetEntity';
import {parseNullableNumber} from './TransformPatches';

export function createBudgetEntity(): BudgetEntity {
  return {
    enforceMaxEffort: true,
    maxEffort: null,
    maxPrice: null,
    showMaxEffortInDays: true,
  };
}

export function getBudgetEntityCopiedFields(): Array<keyof BudgetEntity> {
  return ['enforceMaxEffort', 'maxEffort', 'maxPrice', 'showMaxEffortInDays'];
}

export function transformBudgetEntityToPatchData(entity: BudgetEntity): BudgetEntityPatchData {
  return {
    enforceMaxEffort: entity.enforceMaxEffort,
    maxEffort: formatDecimal(entity.maxEffort) || '',
    maxPrice: formatDecimal(entity.maxPrice) || '',
    showMaxEffortInDays: entity.showMaxEffortInDays,
  };
}

export function transformPatchDataToBudgetEntity(data: DeepPartial<BudgetEntityPatchData>): DeepPartial<BudgetEntity> {
  const entity: DeepPartial<BudgetEntity> = {};
  parseNullableNumber(data, entity, 'maxEffort');
  parseNullableNumber(data, entity, 'maxPrice');
  return entity;
}

function fallback<K extends keyof BudgetEntityPatchData>(
  patch: DeepPartial<BudgetEntityPatchData>,
  previous: BudgetEntityPatchData | undefined,
  key: K,
): DeepPartial<BudgetEntityPatchData>[K] | undefined {
  const patched = patch[key];
  return typeof patched === 'undefined' ? previous?.[key] : patched;
}

function getMaxEffortValidationRules(
  id: Uuid,
  patch: DeepPartial<BudgetEntityPatchData>,
  previous?: BudgetEntityPatchData,
): RulesList {
  if (!patch.maxEffort) {
    return [];
  }
  const useDays = fallback(patch, previous, 'showMaxEffortInDays');
  return [
    [validateNumberStringFormat, patch.maxEffort, 'general:error.invalidNumberFormat', `maxEffort_${id}`],
    [
      validateDecimalIfValidStringFormat,
      patch.maxEffort,
      useDays
        ? 'general:pid.validationErrors.limitDaysOutOfRange'
        : 'general:pid.validationErrors.limitHoursOutOfRange',
      `maxEffort_${id}`,
      {
        factorForMessageValues: useDays ? 1 / 8 : 1,
        minValueExclusive: true,
      } as ValidateDecimalOptions,
    ],
  ];
}

function getMaxPriceValidationRules(id: Uuid, patch: DeepPartial<BudgetEntityPatchData>): RulesList {
  if (!patch.maxPrice) {
    return [];
  }
  return [
    [validateNumberStringFormat, patch.maxPrice, 'general:error.invalidNumberFormat', `maxPrice_${id}`],
    [validateDecimalIfValidStringFormat, patch.maxPrice, 'general:error.decimalOutOfRange', `maxPrice_${id}`],
  ];
}

export function getBudgetEntityValidationRules(
  id: Uuid,
  patch: DeepPartial<BudgetEntityPatchData>,
  previous?: BudgetEntityPatchData,
): RulesList {
  return [...getMaxEffortValidationRules(id, patch, previous), ...getMaxPriceValidationRules(id, patch)];
}

export function createBudgetEntityErrorFields(id: Uuid): string[] {
  return [`maxEffort_${id}`, `maxPrice_${id}`];
}
