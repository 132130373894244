import {Uuid} from '@octaved/typescript/src/lib';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {useReactTableHiddenColumns} from '@octaved/ui-components/src/React/Table/HiddenColumns';
import TimeRange from '@octaved/users/src/Culture/React/TimeRange';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {ColumnWithLooseAccessor, useSortBy, useTable} from 'react-table';
import Duration from '../../../../../Components/Duration/Duration';
import FlowLabels from '../../../../../Components/Label/FlowLabel/FlowLabels';
import MultiLineTextString from '../../../../../Components/MultiLineTextString';
import NodeName from '../../../../../Components/Node/NodeName';
import StringPath from '../../../../../Components/Node/StringPath';
import TableRenderer from '../../../../../Components/Table/TableRenderer';
import UserName from '../../../../../Components/Unit/UserName';
import {TimeRecord} from '../../../../../EntityInterfaces/TimeRecords';
import {useNode} from '../../../../../Modules/Hooks/Nodes';
import {isSubWorkPackage} from '../../../../../Node/NodeIdentifiers';
import DateCell from '../../../../TimeRecordings/Pages/UserTimeBookings/BookingTable/DateCell';
import {isTimeRecord} from '../../../../TimeRecordings/TimeRecordDetails';
import LastExportOrPartialSettlementCell from './Cells/LastExportOrPartialSettlementCell';
import Type from './Cells/Type';

const columns: ColumnWithLooseAccessor<TimeRecord>[] = [
  {
    Cell({row: {original}}) {
      return <StringPath nodeId={original.workPackage} excludeProject excludeSelf />;
    },
    disableSortBy: true,
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerGroup'} />
        </span>
      );
    },
    id: 'group',
  },
  {
    Cell({row: {original}}) {
      if (original.workPackage) {
        return <NodeName nodeId={original.workPackage} />;
      }
      return null;
    },
    disableSortBy: true,
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerWorkPackage'} />
        </span>
      );
    },
    id: 'workPackage',
  },
  {
    Cell({row: {original}}) {
      const node = useNode(original.referenceNode);
      if (isSubWorkPackage(node)) {
        return <NodeName nodeId={node.id} />;
      }
      return null;
    },
    disableSortBy: true,
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerSubWorkPackage'} />
        </span>
      );
    },
    id: 'subWorkPackage',
  },
  {
    accessor: ({user}) => user,
    Cell({row: {original}}) {
      return original.user ? <UserName userId={original.user} /> : '';
    },
    disableSortBy: true,
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerUser'} />
        </span>
      );
    },
    id: 'user',
  },
  {
    accessor: ({workTimeStart}) => workTimeStart,
    Cell({row: {original}}) {
      return <DateCell timeRecord={original} />;
    },
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerDate'} />
        </span>
      );
    },
    id: 'date',
  },
  {
    accessor: ({workTimeStart}) => workTimeStart,
    Cell({row: {original}}) {
      return <TimeRange start={original.billingStart} end={original.billingEnd} isUtc />;
    },
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerFromTo'} />
        </span>
      );
    },
    id: 'range',
  },
  {
    accessor(rowData) {
      if (isTimeRecord(rowData)) {
        return (rowData?.billingEnd || 0) - (rowData?.billingStart || 0);
      }
      return 0;
    },
    Cell({row: {original}}) {
      return <Duration from={original.billingStart} to={original.billingEnd} showAlwaysMinutes />;
    },
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerDuration'} />
        </span>
      );
    },
    id: 'duration',
  },
  {
    Cell({row: {original}}) {
      return <Type record={original} />;
    },
    disableSortBy: true,
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerType'} />
        </span>
      );
    },
    id: 'type',
  },
  {
    Cell({row: {original}}) {
      return (
        <>
          <MultiLineTextString text={original.message || ''} />
          {original.labels.length > 0 && (
            <>
              <Spacer spacing={10} />
              <FlowLabels labels={original.labels} small noMargin />
            </>
          )}
        </>
      );
    },
    disableSortBy: true,
    Header() {
      return (
        <span>
          <Trans i18nKey={'pages:projects.drawer.pidViewTimeTrackings.table.headerDescriptionActivity'} />
        </span>
      );
    },
    id: 'message',
  },
  {
    Cell({
      row: {
        original: {billedOn},
      },
    }) {
      if (billedOn) {
        return <LastExportOrPartialSettlementCell billedOn={billedOn} />;
      }
      return null;
    },
    disableSortBy: true,
    Header() {
      return <></>;
    },
    id: 'isExportedOrBilled',
  },
];

interface Props {
  hasBilledRecords: boolean;
  hasJourneys: boolean;
  hasSurcharges: boolean;
  selected: Uuid | null;
  setSelected: (id: Uuid | null) => void;
  showGroups: boolean;
  showSubWorkPackages: boolean;
  showWorkPackages: boolean;
  timeRecords: TimeRecord[];
}

const initialState = {sortBy: [{desc: false, id: 'date'}]};

export default function Table({
  hasBilledRecords,
  hasJourneys,
  hasSurcharges,
  selected,
  setSelected,
  showGroups,
  showSubWorkPackages,
  showWorkPackages,
  timeRecords,
}: Props): ReactElement {
  const {hiddenColumns, hiddenColumnInstanceRef} = useReactTableHiddenColumns<TimeRecord>({
    group: !showGroups,
    isExportedOrBilled: !hasBilledRecords,
    subWorkPackage: !showSubWorkPackages,
    type: !hasJourneys && !hasSurcharges,
    workPackage: !showWorkPackages,
  });

  const instance = useTable<TimeRecord>(
    {
      columns,
      data: timeRecords,
      initialState: {...initialState, hiddenColumns},
    },
    useSortBy,
  );
  hiddenColumnInstanceRef.current = instance;

  return <TableRenderer<TimeRecord> {...instance} sortable canSelect selected={selected} setSelected={setSelected} />;
}
