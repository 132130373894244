import {isGroup, isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {PlanningPatchData} from '../../Modules/Planning';
import UpdatePlanningState from './UpdatePlanningState';

export function updateNodes(state: UpdatePlanningState, updateData: PlanningPatchData): void {
  if ('dueDate' in updateData) {
    const node = state.getNode(updateData.nodeId);

    if (node) {
      const updatedNode = {...node};
      let hasChanges = false;
      if (
        updateData.dueDate !== undefined &&
        (isGroup(updatedNode) || isProject(updatedNode)) &&
        updatedNode.dueDate !== updateData.dueDate
      ) {
        hasChanges = true;
        updatedNode.dueDate = updateData.dueDate;
      }
      if (hasChanges) {
        state.setNode(updatedNode);
      }
    }
  }
}
