import {ActionDispatcher, dispatch, onSelectorChange} from '@octaved/store/src/Store';
import {
  BookedHoursByBillingTypeKeys,
  NonBillableHoursByReasonKeys,
} from '../../EntityInterfaces/Statistics/BookedHours';
import {DualValueData, MultiValuesData, SingleValueData} from '../../EntityInterfaces/Statistics/Charts';
import {DemoPieWithHistoryChartData} from '../Selectors/ReportingCharts/TimeTracking/PieWithHIstorySelectors';
import {reportingDemoModeSelector} from '../Selectors/UiPages/ReportingSelectors';
import {FlowState} from '../State';
import {setUiState} from '../Ui';

export interface DemoData {
  averageWorkHours?: {demoData: SingleValueData[]; demoDataCompare: SingleValueData[]};
  billingTypes?: {
    demoData: DemoPieWithHistoryChartData<BookedHoursByBillingTypeKeys>;
    demoDataCompare: DemoPieWithHistoryChartData<BookedHoursByBillingTypeKeys>;
  };
  bookedHours?: {demoData: SingleValueData[]; demoDataCompare: SingleValueData[]};
  chargableQuota?: {demoData: DualValueData[]; demoDataCompare: DualValueData[]};
  employeeCount?: {demoData: SingleValueData[]; demoDataCompare: SingleValueData[]};
  noCharge?: {
    demoData: DemoPieWithHistoryChartData<NonBillableHoursByReasonKeys>;
    demoDataCompare: DemoPieWithHistoryChartData<NonBillableHoursByReasonKeys>;
  };
  roundingGain?: {demoData: DualValueData[]; demoDataCompare: DualValueData[]};
  taskBurnDown?: {
    demoData: MultiValuesData[];
    demoDataCompare: MultiValuesData[];
    foreCastDemoData: MultiValuesData[];
    foreCastDemoDataCompare: MultiValuesData[];
  };
}

let loaded = false;

function load(): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch) => {
    if (loaded) {
      return;
    }
    loaded = true;
    dispatch(setUiState({pages: {reporting: {demoData: (await import('./Reporting/DemoData')).default}}}));
  };
}

export function initDemoData(): void {
  onSelectorChange(reportingDemoModeSelector, true, (newValue) => {
    if (newValue) {
      dispatch(load());
    }
  });
}
