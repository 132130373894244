import {FlowState} from '../State';

function settings<R>(selector: (state: FlowState['settings']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.settings);
}

export const settingsSelector = settings((state) => state);
export const aggridLicenseKeySelector = settings((state) => state.aggridLicenseKey);
export const aggridIsLicensedSelector = settings((state) => !!state.aggridLicenseKey);
export const allowIdentitySignUpSelector = settings((state) => state.allowIdentitySignUp);
export const allowOrganizationSetupSelector = settings((state) => state.allowOrganizationSetup);
export const billingTypesBookLimitsSelector = settings((state) => state.billingTypesBookLimits);
export const defaultBillableBillingTypeSelector = settings((state) => state.defaultBillableBillingType);
export const journeyBillingModeSelector = settings((state) => state.journeyBillingMode);
export const defaultBillingTypesBookLimitsSelector = settings(
  (state) => state.defaults?.billingTypesBookLimits || null,
);
export const forceDescriptionSelector = settings((state) => state.forceDescription);
export const hasMaterialResourcesSelector = settings((state) => state.hasMaterialResources);
export const logicalDependencyWorkflowControlSelector = settings((state) => state.logicalDependencyWorkflowControl);
export const markParentTasksDoneWorkflowControlSelector = settings((state) => state.markParentTasksDoneWorkflowControl);
export const markWorkPackagesDoneWorkflowControlSelector = settings(
  (state) => state.markWorkPackagesDoneWorkflowControl,
);
export const passwordPolicySelector = settings((state) => state.passwordPolicy);
export const sentryDsnDesktopSelector = settings((state) => state.sentryDsnDesktop);
export const startOfFiscalYearSelector = settings((state) => state.startFiscalYear);
export const useProjectFoldersSelector = settings((state) => state.useProjectFolders);
export const useTimeTrackingSelector = settings((state) => state.useTimeTracking);
export const useWorkingTimeTrackingSelector = settings((state) => state.useWorkingTimeTracking);
export const webhooksEnabledSelector = settings((state) => state.webhooksEnabled);
