import {error, warning} from '@octaved/env/src/Logger';
import {getInstanceUuid} from '@octaved/utilities';
import {validateArray, validateEnum, validateFunction, validateString} from '@octaved/validation';
import get from 'lodash/get';
import {Reducer} from './Store';

export interface EntityState {
  invalidated?: number;
  loading?: number;
  loaded?: number;
  loadingFields?: ReadonlyArray<string>;
  loadedFields?: ReadonlyArray<string>;
  loadingAllFields?: boolean;
  loadedAllFields?: boolean;
}

export interface EntityStates {
  [x: number | string]: EntityState | undefined;
}

export interface EntityStateAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;

  fieldsToLoad?: ReadonlyArray<string> | null;
  requestTime?: number;
}

export const INVALIDATED = 'invalidated';
export const LOADING = 'loading';
export const LOADED = 'loaded';
export type StateValue = 'invalidated' | 'loading' | 'loaded';

const stateFields = [INVALIDATED, LOADING, LOADED];

/**
 * Puts a time stamp on the given stampField
 */
export function createFlatTimestampReducer<T extends EntityState = EntityState>(
  stampField: StateValue,
  ignoreInstanceUpdates = false,
): Reducer<T, EntityStateAction> {
  validateEnum(stampField, stateFields);
  return (state, action) => {
    if (ignoreInstanceUpdates && action.responsibleInstanceId === getInstanceUuid()) {
      return state;
    }
    //prefer the requestTime even for the LOADED state to avoid race conditions after invalidation:
    const now = action.requestTime || Date.now();
    return {
      ...state,
      [stampField]: now,
    };
  };
}

function setFieldsToLoad(
  stampField: keyof EntityState,
  target: EntityState,
  fieldsToLoad?: ReadonlyArray<string> | null,
): void {
  if (fieldsToLoad) {
    if (stampField === LOADING) {
      target.loadingFields = fieldsToLoad;
    }
    if (stampField === LOADED) {
      target.loadedFields = fieldsToLoad;
    }
    if (stampField === INVALIDATED) {
      target.loadingFields = [];
      target.loadedFields = [];
    }
  } else {
    if (stampField === LOADING) {
      target.loadingAllFields = true;
    }
    if (stampField === LOADED) {
      target.loadedAllFields = true;
    }
    if (stampField === INVALIDATED) {
      target.loadingAllFields = false;
      target.loadedAllFields = false;
    }
  }
}

/**
 * Puts a time stamp on the given stampField for every entry in actionKey
 */
export function reduceStateIds<T extends EntityStates = EntityStates>(
  state: T,
  action: EntityStateAction,
  stampField: StateValue,
  ids: string[],
  ignoreInstanceUpdates = false,
): T {
  if (ignoreInstanceUpdates && action.responsibleInstanceId === getInstanceUuid()) {
    return state;
  }
  //prefer the requestTime even for the LOADED state to avoid race conditions after invalidation:
  const now = action.requestTime || Date.now();
  const newState = {...state};
  (ids as Array<keyof T>).forEach((id) => {
    const prev = newState[id];
    newState[id] = {
      ...(prev || ({} as T[keyof T])),
      [stampField]: now,
    };
    if (stampField === LOADED && prev && prev[LOADED]! > now) {
      warning('Newer entity was overwritten with older request', {
        ids,
        now,
        stampField,
        action: {
          endpoint: action.endpoint,
          requestTime: action.requestTime,
        },
      });
      //Reset the `loading` timestamp - otherwise there will be an infinite !isLoaded() condition. This also
      // makes sure, that the request is re-issued if there is a younger `invalidated` timestamp:
      if (newState[id]![LOADING]! > now) {
        newState[id]![LOADING] = now;
      }
    }
    setFieldsToLoad(stampField, newState[id] as EntityState, action.fieldsToLoad);
  });
  return newState;
}

/**
 * Puts a time stamp on the given stampField for every entry in actionKey
 */
export function createTimestampReducer<T extends EntityStates = EntityStates>(
  actionKey: string,
  stampField: StateValue,
  ignoreInstanceUpdates = false,
): Reducer<T, EntityStateAction> {
  validateString(actionKey);
  validateEnum(stampField, stateFields);
  return (state, action) => {
    let ids = get(action, actionKey);
    if (typeof ids === 'undefined') {
      error(`Missing '${actionKey}' in action!`, action);
      return state;
    }
    ids = Array.isArray(ids) ? ids : [ids];
    return reduceStateIds(state, action, stampField, ids, ignoreInstanceUpdates);
  };
}

function reduceAll(
  fn: (s: EntityState) => boolean,
  states: EntityStates,
  keys: ReadonlyArray<string>,
): [boolean, Set<string>] {
  const notLoadedKeys = new Set<string>();
  const allAreLoaded = keys.reduce<boolean>((acc, key) => {
    if (!fn(states[key] || {})) {
      notLoadedKeys.add(key);
      return false;
    }
    return acc;
  }, true);
  return [allAreLoaded, notLoadedKeys];
}

/**
 * Determines whether the state needs reloading
 */
export function isOutdated(state: EntityState): boolean {
  const invalidatedTime = state[INVALIDATED] || 0;
  const loadingTime = state[LOADING] || -1;
  const loadedTime = state[LOADED] || -1;
  //The initial values are so that if the state was never loaded, this method returns TRUE.
  // We cannot use ">=" instead without risking excessive loading.
  return invalidatedTime > loadedTime && invalidatedTime > loadingTime;
}

/**
 * Determines whether the current state is loaded and not invalidated/loading
 */
export function isLoaded(state: EntityState): boolean {
  const invalidatedTime = state[INVALIDATED] || 0;
  const loadingTime = state[LOADING] || -1;
  const loadedTime = state[LOADED] || -1;
  return invalidatedTime < loadedTime && loadingTime <= loadedTime;
}

/**
 * @return [allAreLoaded, notLoadedKeys]
 */
export function reduceAllAreLoaded(states: EntityStates, keys: ReadonlyArray<string>): [boolean, Set<string>] {
  return reduceAll(isLoaded, states, keys);
}

/**
 * Determines whether the current state is currently loading
 *
 * NOTE: This is not `!isLoaded()`! This method is still FALSE when the state is missing or was invalidated and will
 * be reloaded!
 * This method is solely here to determine if the the entity is being loaded right now as in, the request has already
 * been started!
 * For most cases in your UI display, use `isLoaded()`!
 */
export function isLoading(state: EntityState): boolean {
  const loadingTime = state[LOADING] || -1;
  const loadedTime = state[LOADED] || -1;
  return loadingTime > loadedTime;
}

/**
 * Determines whether the state has loaded once and thus the data exists in the store
 */
export function hasLoadedOnce(state: EntityState): boolean {
  return Boolean(state[LOADED]);
}

/**
 * @return [allHaveLoadedOnce, notLoadedKeys]
 */
export function reduceAllHaveLoadedOnce(states: EntityStates, keys: ReadonlyArray<string>): [boolean, Set<string>] {
  return reduceAll(hasLoadedOnce, states, keys);
}

export function missesField(state: EntityState, wantedFields: ReadonlyArray<string> | null): boolean {
  const loadedOrLoadingAllFields = state.loadedAllFields || state.loadingAllFields;
  if (!wantedFields) {
    return !loadedOrLoadingAllFields;
  }
  if (loadedOrLoadingAllFields) {
    return false; //all fields either loading or loaded, so the wantedFields are covered
  }
  const loadedOrLodingFields = new Set([...(state.loadingFields || []), ...(state.loadedFields || [])]);
  return wantedFields.some((wanted) => !loadedOrLodingFields.has(wanted));
}

export function filterIdsToReload<idType extends string>(
  dataStates: EntityStates,
  ids: ReadonlyArray<idType>,
  wantedFields?: ReadonlyArray<string> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyFn?: (id: idType, ...rest: any[]) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...keyFnExtraArgs: any[]
): ReadonlyArray<idType>;
export function filterIdsToReload(
  dataStates: {[x: number]: EntityState | undefined},
  ids: number[],
  wantedFields?: ReadonlyArray<string> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyFn?: (id: number, ...rest: any[]) => number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...keyFnExtraArgs: any[]
): number[];
export function filterIdsToReload<idType extends string | number>(
  dataStates: EntityStates,
  ids: ReadonlyArray<idType>,
  wantedFields: ReadonlyArray<string> | null = null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyFn: (id: idType, ...rest: any[]) => idType = (id) => id,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...keyFnExtraArgs: any[]
): ReadonlyArray<idType> {
  validateArray(ids);
  validateFunction(keyFn);
  return ids.filter((id) => {
    const key = keyFn(id, ...keyFnExtraArgs);
    const state = dataStates[key] as EntityState | undefined;
    if (state) {
      return isOutdated(state) || missesField(state, wantedFields);
    }
    return true;
  });
}

export function entityStateIsOutdated(
  dataStates: EntityStates,
  id: string,
  wantedFields?: ReadonlyArray<string> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyFn?: (id: string, ...rest: any[]) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...keyFnExtraArgs: any[]
): boolean;
export function entityStateIsOutdated(
  dataStates: {[x: number]: EntityState | undefined},
  id: number,
  wantedFields?: ReadonlyArray<string> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyFn?: (id: number, ...rest: any[]) => number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...keyFnExtraArgs: any[]
): boolean;
export function entityStateIsOutdated<idType extends string | number>(
  dataStates: EntityStates,
  id: idType,
  wantedFields: ReadonlyArray<string> | null = null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyFn: (id: idType, ...rest: any[]) => idType = (id) => id,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...keyFnExtraArgs: any[]
): boolean {
  validateFunction(keyFn);
  const key = keyFn(id, ...keyFnExtraArgs);
  const state = dataStates[key] as EntityState | undefined;
  if (state) {
    return isOutdated(state) || missesField(state, wantedFields);
  }
  return true;
}
