import {OwnerQuota} from '@octaved/media-files/src/EntityInterfaces/Quota';
import {formatBytes} from '@octaved/media-files/src/FileInfo';
import {Icon} from '@octaved/ui';
import {generateUuid} from '@octaved/utilities';
import {Upload} from 'lucide-react';
import {toast} from 'react-hot-toast';
import {Trans} from 'react-i18next';
import SnackbarWrapper from './SnackbarWrapper';

const title = {
  identity: 'snackbars:fileStorageQuotaWillExceed.title.identity',
  organization: 'snackbars:fileStorageQuotaWillExceed.title.organization',
};

export function createFileStorageQuotaExceededSnackbar(
  owner: 'organization' | 'identity',
  quota: OwnerQuota,
  decisiveFileSize: number,
): void {
  toast(
    (toastInstance) => (
      <SnackbarWrapper toastId={toastInstance.id}>
        <div className={'content'}>
          <div>
            <b>
              <Trans i18nKey={title[owner]} />
            </b>
          </div>
          <div className={'detail'}>
            <div>
              <Trans i18nKey={'snackbars:fileStorageQuotaWillExceed.title.currentUsage'} />
            </div>
            <div>
              {formatBytes(quota.usage)}
              {' / '}
              {formatBytes(quota.limit)}
            </div>
          </div>
          <div className={'detail'}>
            <div>
              <Trans i18nKey={'snackbars:fileStorageQuotaWillExceed.title.yourUpload'} />
            </div>
            <div>{formatBytes(decisiveFileSize)}</div>
          </div>
        </div>
        {/*language=scss*/}
        <style jsx>{`
          .content {
            display: flex;
            flex-direction: column;
            grid-gap: 0.5em;
          }

          .detail {
            display: flex;
            grid-gap: 0.5em;
            justify-content: space-between;
          }
        `}</style>
      </SnackbarWrapper>
    ),
    {
      duration: Infinity,
      icon: (
        <Icon iconColor={'red'} size={'big'} noMargin>
          <Upload />
        </Icon>
      ),
      id: generateUuid(),
    },
  );
}
