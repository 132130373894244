import {error} from '@octaved/env/src/Logger';
import {once} from 'lodash';
import {createContext, PropsWithChildren, ReactElement, useContext} from 'react';
import {useSelector} from 'react-redux';
import {FlowState} from '../Modules/State';

type Selector = (state: FlowState) => boolean;

const context = createContext<Selector | null>(null);

const complain = once(() => error('DaysOrHoursContext is missing'));

export function useDaysInsteadOfHours(): boolean {
  const selector = useContext(context);
  return useSelector(
    selector ||
      (() => {
        complain();
        return false;
      }),
  );
}

interface Props {
  selector: Selector;
}

export default function DaysOrHoursContext({children, selector}: PropsWithChildren<Props>): ReactElement {
  return <context.Provider value={selector}>{children}</context.Provider>;
}
