import {schema} from 'normalizr';
import {generatePlanningDependencyId} from '../Selectors/PlanningDependencySelectors';

export const workload = new schema.Entity(
  'workload',
  {},
  {
    idAttribute: (entity) => entity.userId,
  },
);

export const planningDate = new schema.Entity('planningDate');

export const planningDependency = new schema.Entity(
  'planningDependency',
  {},
  {
    idAttribute: (entity) => generatePlanningDependencyId(entity.successor, entity.predecessor),
  },
);
