import {Uuid} from '@octaved/typescript/src/lib';
import {createContext} from 'react';
import {ProjectDragObject} from './ProjectSortable';

export interface ProjectDndMoveFn {
  (oldIndex: number, newIndex: number, oldParent: Uuid | null, newParent: Uuid | null, data: ProjectDragObject): void;
}

interface ProjectsTreeComponentDndContext {
  move?: ProjectDndMoveFn;

  onDrop?(data: ProjectDragObject): void;

  onDragStart?(): void;
}

const defaultProjectDndContext: ProjectsTreeComponentDndContext = {};

export const projectDndContext = createContext<ProjectsTreeComponentDndContext>(defaultProjectDndContext);
