import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import {useEffectiveRoleAssignmentsOnNode} from '../../Modules/Hooks/NodeRoleAssignments';
import UnitTextList, {UnitTextListProps} from './UnitTextList';

interface ResponsibleUnitsProps extends Omit<UnitTextListProps, 'units'> {
  nodeId: Uuid;
}

export default function ProjectManagerUnitsTextList({nodeId, ...props}: ResponsibleUnitsProps): ReactElement | null {
  const {assignments} = useEffectiveRoleAssignmentsOnNode(EnumFlowNodeRoleType.VALUE_PROJECT, nodeId);
  const filtered = useMemo(() => assignments.filter(({role}) => role.isProjectManagerRole), [assignments]);
  return <UnitTextList units={filtered} {...props} />;
}
