import {getNodeHistory} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {useSingleValueArray} from '@octaved/hooks/src/SingleValueArray';
import {createTimestampReducer, INVALIDATED} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {Uuid} from '@octaved/typescript/src/lib';
import {NodeEntity} from '../EntityInterfaces/NodeEntity';
import {NodeHistory, NodeHistoryFactory} from '../EntityInterfaces/NodeHistory';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_DELETE_BILLING_SUCCESS,
  FLOW_LOAD_NODE_HISTORY_START,
  FLOW_LOAD_NODE_HISTORY_SUCCESS,
} from './ActionTypes';
import {nodeHistoryEntitySelector, nodeHistoryEntityStatesSelector} from './Selectors/NodeHistorySelectors';
import {FlowState} from './State';

export const stateReducerMap = new Map();
addMultiToReducerMap(
  stateReducerMap,
  [
    'flow.GroupPatchedEvent',
    'flow.NodePatchedEvent',
    'flow.ProjectPatchedEvent',
    'flow.SubWorkPackagePatchedEvent',
    'flow.TaskPatchedEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  createTimestampReducer('nodeId', INVALIDATED),
);
stateReducerMap.set('flow.BillingCreatedEvent', createTimestampReducer('workPackageIds', INVALIDATED));
addMultiToReducerMap(
  stateReducerMap,
  ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_SUCCESS],
  createTimestampReducer('workPackageIds', INVALIDATED),
);
stateReducerMap.set(FLOW_CHANGE_PID_SUCCESS, createTimestampReducer('patchedNodeId', INVALIDATED));
addMultiToReducerMap(stateReducerMap, 'flow.NodesRemovedEvent', createTimestampReducer('nodeIds', INVALIDATED));
addMultiToReducerMap(
  stateReducerMap,
  'flow.ProjectCustomerChangedEvent',
  createTimestampReducer('affectedNodeIds', INVALIDATED),
);
export const nodeHistoryEntityStateReducer = ReduceFromMap(stateReducerMap);

const nodeHistoryReducerMap = new Map();
export const nodeHistoryEntityReducer = ReduceFromMap(nodeHistoryReducerMap);

const [, useNodeHistories] = createUseEntityHook<FlowState, {id: Uuid; entries: NodeHistory[]}, NodeHistory[]>(
  FLOW_LOAD_NODE_HISTORY_START,
  FLOW_LOAD_NODE_HISTORY_SUCCESS,
  getNodeHistory,
  nodeHistoryEntitySelector,
  nodeHistoryEntityStatesSelector,
  nodeHistoryReducerMap,
  stateReducerMap,
  {normalizer: (r) => r.entries},
);

const empty: unknown = [];

export function useNodeHistory<N extends NodeEntity>(
  id: Uuid,
): {
  isLoading: boolean;
  entries: NodeHistoryFactory<N>[];
} {
  const {isLoading, entriesMapped} = useNodeHistories(useSingleValueArray(id), true);
  return {
    isLoading,
    entries: (entriesMapped.get(id) || empty) as NodeHistoryFactory<N>[],
  };
}
