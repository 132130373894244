import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import Link from '../../Components/Link';
import {useWebsiteLinks} from '../../WebsiteLinks';
import SectionHeader from './SectionHeader';

export default function Support(): ReactElement {
  const {t} = useTranslation();
  const websiteLinks = useWebsiteLinks();
  return (
    <>
      <SectionHeader color={'teal'} header={'onboarding:octavedFlowHelpPopup.support.header'} />

      <div className={'support'}>
        <div className={'supportDetails'}>
          <span>{t('onboarding:octavedFlowHelpPopup.support.info')}</span>
          &nbsp;
          <a href={websiteLinks.contact} target={'_blank'} rel={'noreferrer'}>
            <Link>
              <span>{t('onboarding:octavedFlowHelpPopup.support.openContactFormular')}</span>
            </Link>
          </a>
        </div>
      </div>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .support {
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          grid-gap: 20px;
        }

        .supportDetails {
          flex: 1;
          font-size: 14px;
        }

        .openContactForm {
          flex-shrink: 0;
          width: 150px;
          text-align: right;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
