import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import Node from './Node';
import {ProjectTreeSelectorContextProvider} from './ProjectTreeSelectorContext';

interface Props {
  close: () => void;
  nodeId: Uuid;
}

export default function Content({close, nodeId}: Props): ReactElement | null {
  const ancestry = useNodeAncestry(nodeId, true);
  if (!ancestry.project) {
    return null;
  }
  return (
    <ProjectTreeSelectorContextProvider
      close={close}
      initiallyExpandedNodeIds={ancestry.ancestors.map(({id}) => id)}
      selectedNodeId={nodeId}
    >
      <Node node={ancestry.project} />
    </ProjectTreeSelectorContextProvider>
  );
}
