import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';

interface Props {
  children?: ReactNode;
  className: string;
  item: {
    textColor?: 'red';
    token: ComplexTranslatable;
  };
}

export default function DropdownLabel({children, className, item}: Props): ReactElement {
  return (
    <div className={classNames(className, 'itemLabel', `color-${item.textColor}`)}>
      <span>
        <ComplexTrans translatable={item.token} />
      </span>
      {children}
    </div>
  );
}
