import {Button, Label} from '@octaved/ui';
import useKeyboardEventListener from '@octaved/ui-components/src/Hooks/UseKeyboardEventListener';
import {ReactElement, RefObject} from 'react';
import {Form, Input} from 'semantic-ui-react';
import {transformEffort} from '../../../../../../Modules/Hooks/EffortTransformer';
import CellEditPopup from '../Components/CellEditPopup';

interface Props {
  contextRef: RefObject<HTMLDivElement>;
  open: boolean;
  customHours: string;
  setCustomHours: (newValue: string) => void;
  toggleOpen: () => void;
}

export default function CustomHoursPopup({
  contextRef,
  customHours,
  setCustomHours,
  open,
  toggleOpen,
}: Props): ReactElement {
  const keyDownListener = useKeyboardEventListener({
    Enter: toggleOpen,
  });

  return (
    <>
      <CellEditPopup
        open={open}
        contextRef={contextRef}
        footer={
          <div className={'footer'}>
            <Button variant={'ghost'} label={'taskList:ok'} onClick={toggleOpen} />
          </div>
        }
      >
        <Label token={'taskList:effort.hours'} lessWeight />
        <div className={'inputField'}>
          <Form size={'small'}>
            <Input
              onKeyDown={keyDownListener}
              autoFocus
              value={customHours}
              onChange={(_e, {value}) => setCustomHours(transformEffort(value))}
            />
          </Form>
        </div>
      </CellEditPopup>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .wrapper {
          display: flex;
          align-items: center;
          grid-gap: 14px;
        }

        .inputField {
          width: 80px;
        }

        .inputField :global(input) {
          text-align: right;
        }

        .footer {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
