import {CalendarContext} from '../../Calendar/Context/CalendarContext';
import {Disposable} from '../../Disposable';
import {Cell} from './Cells/Cell';
import {HeaderCellProps} from './Cells/HeaderCell';
import {NodeCell, NodeCellProps} from './Cells/NodeCell';

export interface TableFactoryProps<CTX extends CalendarContext = CalendarContext> {
  ctx: CTX;
}

export type CellCtr = new (_: NodeCellProps) => NodeCell;

export interface CellConfigProps {
  columnName: string;
}

export abstract class TableFactory<CTX extends CalendarContext = CalendarContext> extends Disposable {
  protected readonly ctx: CTX;

  readonly hideAllColumnWidth = 30;

  constructor({ctx}: TableFactoryProps<CTX>) {
    super();
    this.ctx = ctx;
  }

  init(): void {
    //impl in sub classes
  }

  abstract getColumnNames(): string[];

  abstract getHeaderCellRenderClass(
    cellConfigProps: CellConfigProps,
    cellProps: Omit<HeaderCellProps, 'token'>,
  ): Cell | null;
  abstract getDataCellRenderClass(cellConfigProps: CellConfigProps, cellProps: NodeCellProps): NodeCell | null;

  abstract getColumnWidth(column: string): number;
  abstract calculateColumnsWidth(visibleColumns: string[]): number;
  abstract isColumnVisible(visibleColumns: string[], columnName: string): boolean;
}
