import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Customer} from '@octaved/flow/src/EntityInterfaces/Customers';
import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {getFlowCustomerSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {
  isGroup,
  isMaterialResource,
  isNode,
  isProject,
  isSubWorkPackage,
  isTask,
  isWorkPackage,
} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {formatNameWithTimeControl} from '@octaved/flow/src/Modules/TimeControlledEntity';
import {
  ChevronSvg,
  MaterialRessourceIconSvg,
  ProjectIconSvg,
  SubProjectIconSvg,
  SubWorkPackageIconSvg,
  TaskIconSvg,
  WorkPackageIconSvg,
} from '@octaved/ui';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {Node} from 'konva/lib/Node';
import {Image} from 'konva/lib/shapes/Image';
import {Text} from 'konva/lib/shapes/Text';
import {GhostButton} from '../../../CommonComponents/GhostButton';
import {createText} from '../../../CommonComponents/Text';
import {createStoreSubscription} from '../../../StoreSubscription';
import {NodeCell} from './NodeCell';

abstract class NameCell extends NodeCell {
  protected abstract nodeEntity: Customer | NodeEntity | null;

  #expandButton: GhostButton | null = null;
  #collapseButton: GhostButton | null = null;

  #loadIcons(y: number): Promise<Image[]> {
    return Promise.all([
      this.iconToImage(ProjectIconSvg, {
        height: this.imageSize,
        imageConfig: {
          y,
          name: 'projectIcon',
          visible: false,
        },
        style: {color: '#222222'},
        width: this.imageSize,
      }),
      this.iconToImage(SubProjectIconSvg, {
        height: this.imageSize,
        imageConfig: {
          y,
          name: 'subProjectIcon',
          visible: false,
        },
        style: {color: '#333333'},
        width: this.imageSize,
      }),
      this.iconToImage(SubWorkPackageIconSvg, {
        height: this.imageSize,
        imageConfig: {
          y,
          name: 'subWorkPackageIcon',
          visible: false,
        },
        width: this.imageSize,
      }),
      this.iconToImage(WorkPackageIconSvg, {
        height: this.imageSize,
        imageConfig: {
          y,
          name: 'workPackageIcon',
          visible: false,
        },
        width: this.imageSize,
      }),
      this.iconToImage(MaterialRessourceIconSvg, {
        height: this.imageSize,
        imageConfig: {
          y,
          name: 'materialRessourceIcon',
          visible: false,
        },
        width: this.imageSize,
      }),
      this.iconToImage(TaskIconSvg, {
        height: this.imageSize,
        imageConfig: {
          y,
          name: 'taskIcon',
          visible: false,
        },
        width: this.imageSize,
      }),
    ]);
  }

  init(): void {
    this.imageSize = 16;
    super.init();
    const y = (this.ctx.rowHeight - this.imageSize) * 0.5;
    this.#loadIcons(y).then((icon) => {
      this.root.add(...icon);

      this.render();
      this.render.flush();
    });
    this.#createGhostButtons(y);

    const node = createText({
      ellipsis: true,
      fontStyle: this.node.type === EnumFlowNodeType.VALUE_PROJECT ? 'bold' : undefined,
      name: 'text',
      offsetY: this.textLineOffset,
    });
    this.root.add(node);
  }

  #createGhostButtons(y: number): void {
    this.#collapseButton = new GhostButton({
      ctx: this.ctx,
      icon: ChevronSvg,
      imageOptions: {style: {color: '#475569'}},
      imageSize: this.imageSize,
      onClick: () => {
        this.node.isExpanded = false;
      },
    });

    this.#expandButton = new GhostButton({
      ctx: this.ctx,
      icon: ChevronSvg,
      imageOptions: {style: {color: '#475569', transform: 'rotate(-90deg)'}},
      imageSize: this.imageSize,
      onClick: () => {
        this.node.isExpanded = true;
      },
    });

    this.#collapseButton.init();
    this.#expandButton.init();
    this.#collapseButton.root.y(y);
    this.#expandButton.root.y(y);

    this.root.add(this.#collapseButton.root, this.#expandButton.root);
    this.disposables.push(() => {
      this.#collapseButton?.dispose();
      this.#expandButton?.dispose();
    });
  }

  protected doRender(): void {
    let {x} = this.renderProps;
    const canExpand = this.node.canExpand;
    const name = this.root.findOne('.text');

    const indent = this.node.depth * 20;

    this.root.x(x + indent + this.padding);
    x += this.imageSize + this.padding;
    let icon: Node | undefined;
    if (isTask(this.nodeEntity)) {
      icon = this.root.findOne('.taskIcon');
    } else if (isMaterialResource(this.nodeEntity)) {
      icon = this.root.findOne('.materialRessourceIcon');
    } else if (isProject(this.nodeEntity)) {
      icon = this.root.findOne('.projectIcon');
    } else if (isGroup(this.nodeEntity)) {
      icon = this.root.findOne('.subProjectIcon');
    } else if (isSubWorkPackage(this.nodeEntity)) {
      icon = this.root.findOne('.subWorkPackageIcon');
    } else if (isWorkPackage(this.nodeEntity)) {
      icon = this.root.findOne('.workPackageIcon');
    }
    if (icon) {
      icon.visible(true);
      icon.x(x);
      x += this.imageSize + this.padding;
    }
    if (name instanceof Text) {
      name.text(this.#getText());
      name.width(this.renderProps.width - indent - this.padding * 2 - x);
      name.x(x);
      name.fill(this.getFontColor());
      name.fontVariant(this.getFontVariant());
    }

    this.#collapseButton?.root.visible(canExpand && this.node.isExpanded);
    this.#expandButton?.root.visible(canExpand && !this.node.isExpanded);
  }

  #getText(): string {
    if (!this.nodeEntity) {
      return '';
    }
    const dateFormat = dateFormatSelector(this.ctx.store.getState());
    return isNode(this.nodeEntity)
      ? formatNameWithTimeControl(this.ctx.t, dateFormat, this.nodeEntity)
      : this.nodeEntity.name;
  }
}

export class ProjectEntityNameCell extends NameCell {
  protected nodeEntity: NodeEntity | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
    );
  }
}

export class CustomerEntityNameCell extends NameCell {
  protected nodeEntity: Customer | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getFlowCustomerSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
    );
  }
}
