import {exportTimeTrackingRecordsTimeSheetAsExcel, exportTimeTrackingRecordsTimeSheetAsPdf} from '@octaved/flow-api';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import ConfigureTimeSheetExports from '../../../../Dialogs/ConfigureTimeSheetExports/ConfigureTimeSheetExports';
import {ExportDetails} from './ExportDetails';
import DownloadButton from './Footer/DownloadButton';

interface Props {
  hours: number;
  minutes: number;
  exportDetails: ExportDetails;
}

export default function Footer({hours, minutes, exportDetails}: Props): ReactElement {
  const {t} = useTranslation();
  //Both of these permissions are checked server-side:
  const canExport = useIsGranted('FLOW_NODE_PROJECT_TIME_TRACKING_EXPORT', exportDetails.projectId);
  const canRead = useIsGranted('FLOW_NODE_PROJECT_TIME_TRACKING_READ_OTHERS_ANONYMOUS', exportDetails.workPackageIds);
  const isGranted = canExport && canRead;

  return (
    <div className={'flex flex-shrink-0 items-center justify-between gap-3 bg-gray-50 px-5 py-4'}>
      <div className={'flex-shrink-0'}>
        <span>
          {t('general:counts.timeRecords', {
            count: exportDetails.timeRecordIds.length,
          })}
        </span>
        <span>{` (${hours}h ${minutes}m)`}</span>
      </div>

      {isGranted && (
        <div className={'flex flex-wrap-reverse items-center gap-3.5'}>
          <ConfigureTimeSheetExports />

          <DownloadButton
            exportDetails={exportDetails}
            label={'PDF'}
            route={exportTimeTrackingRecordsTimeSheetAsPdf}
            type={'pdfTimeSheet'}
          />

          <DownloadButton
            exportDetails={exportDetails}
            label={'Excel'}
            route={exportTimeTrackingRecordsTimeSheetAsExcel}
            type={'excelTimeSheet'}
          />
        </div>
      )}
    </div>
  );
}
