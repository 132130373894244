import {useNode} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {ComponentProps, ReactElement, ReactNode, useState} from 'react';
import ContextMenuDialogs, {DialogType} from './ContextMenuDialogs';
import {ContextMenuProvider} from './contextMenuContext';
import {
  TWDropdownMenu,
  TWDropdownMenuContent,
  TWDropdownMenuLabel,
  TWDropdownMenuSeparator,
  TWDropdownMenuTrigger,
} from '@octaved/ui';
import {
  AssignResponsible,
  BaselineComparison,
  BurndownChart,
  CopyTasksFromOtherWork,
  Delete,
  Duplicate,
  Favorite,
  Indent,
  MoreSubMenu,
  MoveTasksToOtherWorkPackage,
  MoveToTarget,
  NewProject,
  NewProjectFromTemplate,
  NewSameNodeAfter,
  NewSameNodeBefore,
  NewSameNodeInside,
  NewSameNodeSubMenu,
  NewGroup,
  NewSubWorkPackage,
  NewTaskSection,
  NewTemplate,
  NewWorkPackage,
  OpenBoard,
  OpenDetails,
  OpenLargeDetailsView,
  OpenNotes,
  OpenReferenceNumber,
  OpenTasks,
  OpenTimeSheet,
  Outdent,
  Rearrange,
  SetStatus,
  TrackTime,
  ExportProject,
  AutoChain,
  SelectPredecessorForLogicalDependency,
  NewTask,
  CustomizeColor,
} from './ContextMenuItems';
import {Trans} from 'react-i18next';
import {isUuid} from '@octaved/validation';
import ContextMenuSection from './ContextMenuSection';
import {isPidOrSubWOrkPackage, isProjectFolder, isTask, isTaskSection} from '@octaved/flow/src/Node/NodeIdentifiers';
import {isUserNode} from '@octaved/flow/src/Modules/Selectors/UserNodeSelectors';

interface ContextMenuProps {
  nodeId: string | number | undefined | null;
  isOpen?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  depth?: number;
  previousSiblingId?: string;
  hideOpenDetails?: boolean;
  hideOpen?: boolean;
  openDetails?: (nodeId: string | null) => void;
  setShowNewSiblingTask?: (show: null | 'prev' | 'next') => void;
}

function ContextMenu({
  nodeId,
  isOpen,
  onClose,
  children,
  depth,
  previousSiblingId,
  hideOpenDetails,
  hideOpen,
  openDetails,
  setShowNewSiblingTask,
}: ContextMenuProps): ReactElement | null {
  const node = useNode(isUuid(nodeId) ? nodeId : undefined);

  const [dialog, setDialog] = useState<DialogType | undefined>(undefined);
  const onCloseDialog = (): void => setDialog(undefined);
  const dropdownPopupProps: ComponentProps<typeof TWDropdownMenu> = {};
  if (typeof isOpen !== 'undefined' && onClose) {
    dropdownPopupProps.open = isOpen;
    dropdownPopupProps.onOpenChange = onClose;
  }

  const hasContextMenu =
    isPidOrSubWOrkPackage(node) || isTask(node) || isProjectFolder(node) || isUserNode(node) || isTaskSection(node);
  if (!hasContextMenu) {
    return null;
  }

  return (
    <>
      <TWDropdownMenu {...dropdownPopupProps}>
        <TWDropdownMenuTrigger>{children}</TWDropdownMenuTrigger>
        <TWDropdownMenuContent align={'start'} onClick={(e) => e.stopPropagation()}>
          <ContextMenuProvider
            openDialog={setDialog}
            node={node}
            depth={depth}
            previousSiblingId={previousSiblingId}
            openDetails={openDetails}
            setShowNewSiblingTask={setShowNewSiblingTask}
          >
            {!hideOpenDetails && !hideOpen && <OpenDetails />}
            {!hideOpen && <OpenLargeDetailsView />}
            <Indent />
            <Outdent />
            <TrackTime />
            {!hideOpen && <OpenBoard />}
            {!hideOpen && <OpenNotes />}
            {!hideOpen && <OpenTasks />}
            <OpenReferenceNumber />
            <ContextMenuSection>
              <TWDropdownMenuSeparator />
              <TWDropdownMenuLabel>
                <Trans i18nKey={'components:contextMenu.header.new'} />
              </TWDropdownMenuLabel>
            </ContextMenuSection>
            <NewProjectFromTemplate />
            <NewProject />
            <NewTemplate />
            <NewSameNodeSubMenu>
              <NewSameNodeBefore />
              <NewSameNodeAfter />
              <NewSameNodeInside />
            </NewSameNodeSubMenu>
            <NewGroup />
            <NewWorkPackage />
            <NewSubWorkPackage />
            <NewTask />
            <NewTaskSection />
            <ContextMenuSection>
              <TWDropdownMenuSeparator />
              <TWDropdownMenuLabel>
                <Trans i18nKey={'components:contextMenu.header.actions'} />
              </TWDropdownMenuLabel>
            </ContextMenuSection>
            <CustomizeColor />
            <Duplicate />
            <Rearrange />
            <CopyTasksFromOtherWork />
            <Favorite />
            <Delete />
            <MoreSubMenu>
              <div className={'mt-[calc(-1px-0.5rem)]'} />
              {!hideOpen && (
                <>
                  <ContextMenuSection>
                    <TWDropdownMenuSeparator />
                    <TWDropdownMenuLabel>
                      <Trans i18nKey={'components:contextMenu.open'} />
                    </TWDropdownMenuLabel>
                  </ContextMenuSection>
                  <OpenTimeSheet />
                </>
              )}
              <ContextMenuSection>
                <TWDropdownMenuSeparator />
                <TWDropdownMenuLabel>
                  <Trans i18nKey={'components:contextMenu.actions'} />
                </TWDropdownMenuLabel>
              </ContextMenuSection>
              <MoveToTarget />
              <MoveTasksToOtherWorkPackage />
              <AutoChain />
              <ExportProject />
              <SelectPredecessorForLogicalDependency />
              <ContextMenuSection>
                <TWDropdownMenuSeparator />
                <TWDropdownMenuLabel>
                  <Trans i18nKey={'components:contextMenu.batchTool'} />
                </TWDropdownMenuLabel>
              </ContextMenuSection>
              <AssignResponsible />
              <SetStatus />
              <ContextMenuSection>
                <TWDropdownMenuSeparator />
                <TWDropdownMenuLabel>
                  <Trans i18nKey={'components:contextMenu.analysis'} />
                </TWDropdownMenuLabel>
              </ContextMenuSection>
              <BaselineComparison />
              <BurndownChart />
            </MoreSubMenu>
          </ContextMenuProvider>
        </TWDropdownMenuContent>
      </TWDropdownMenu>
      <ContextMenuDialogs dialogType={dialog} onClose={onCloseDialog} node={node} />
    </>
  );
}

export {ContextMenu};
