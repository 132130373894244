import {NodeTimeControlStandard} from '@octaved/flow/src/EntityInterfaces/TimeControlledEntity';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import {TFunction} from 'i18next';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PatchPartial} from '../NodeTimeControlSettings';

function createQuarterOptions(t: TFunction): {text: string; value: string}[] {
  const options = [];
  for (let i = 1; i < 5; i++) {
    options.push({
      text: t('general:quarter', {quarter: i}),
      value: i + '',
    });
  }
  return options;
}

interface Props {
  timeControl: NodeTimeControlStandard;
  patch: PatchPartial;
  readonly: boolean;
}

export default function Quarters({timeControl, patch, readonly}: Props): ReactElement {
  const day = dayjs(timeControl.from);
  const {t} = useTranslation();
  const [options] = useState(() => createQuarterOptions(t));
  return (
    <TWSelect
      value={day.quarter() + ''}
      disabled={readonly}
      onValueChange={(value) => patch({from: toIsoFormat(day.startOf('quarter').quarter(+value))})}
    >
      <TWSelectTrigger className={'w-full'}>
        <TWSelectValue placeholder={t('general:dropdownSelectPlaceholder')} />
      </TWSelectTrigger>
      <TWSelectContent>
        {options.map(({text, value}) => (
          <TWSelectItem value={value} key={value}>
            {text}
          </TWSelectItem>
        ))}
      </TWSelectContent>
    </TWSelect>
  );
}
