import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {workdays} from '@octaved/planning/src/Calculations/WorkdayCalculations';
import {calculateMinMaxPlanningDatesInSubtreeSelector} from '@octaved/planning/src/Selectors/PlanningDateSelectors';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {FormatDate, FormatDateFormats, NodeIcon} from '@octaved/ui';
import {formatCollapsingDateRange, fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {Fragment, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';
import Placeholder from '../../../../PlaceholderText';

export interface GroupPlanningProps {
  includeSelf?: boolean;
  nodeId: Uuid;
}

export default function GroupPlanning({
  frame: Frame,
  includeSelf = false,
  nodeId,
  noLabel,
}: GroupPlanningProps & FramedSectionOrTileProps): ReactElement | null {
  const minMax = useSelector(calculateMinMaxPlanningDatesInSubtreeSelector)(nodeId);
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const {t} = useTranslation();
  const workDays =
    minMax.plannedStart &&
    minMax.plannedEnd &&
    workdays(
      fromIsoDateTimeFormat(minMax.plannedStart),
      fromIsoDateTimeFormat(minMax.plannedEnd),
      getOrgWorkMinutesAtDate,
    );
  const canReadPlanning = useIsGranted('FLOW_NODE_READ_PLANNING', nodeId);
  const ancestry = useSelector((state: FlowState) => getNodeAncestrySelector(state)(nodeId, includeSelf));
  if (!ancestry.project) {
    throw Error('Project not found for group');
  }

  if (!canReadPlanning) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.planning.label'} noLabel={noLabel}>
      <div className={'grid grid-cols-[max-content_1fr] gap-x-3'}>
        <div className={'flex items-center gap-x-1.5'}>
          <NodeIcon nodeType={EnumFlowNodeType.VALUE_PROJECT} /> {t('nodeDetails:field.planning.projectDueDate')}:
        </div>
        <div>
          {ancestry.project.dueDate ? (
            <FormatDate date={ancestry.project.dueDate} format={FormatDateFormats.short_with_year} />
          ) : (
            <Placeholder text={t('nodeDetails:field.planning.projectDueDate.placeholder')} />
          )}
        </div>
        {ancestry.groups.toReversed().map((group) => (
          <Fragment key={group.id}>
            <div className={'flex items-center gap-x-1.5'}>
              <NodeIcon nodeType={EnumFlowNodeType.VALUE_GROUP} />
              {t('nodeDetails:field.planning.subProjectDueDate')}:
            </div>
            <div>
              {group.dueDate ? (
                <FormatDate date={group.dueDate} format={FormatDateFormats.short_with_year} />
              ) : (
                <Placeholder text={t('nodeDetails:field.planning.projectDueDate.placeholder')} />
              )}
            </div>
          </Fragment>
        ))}
        <div className={'flex items-center gap-x-1.5'}>
          <NodeIcon nodeType={EnumFlowNodeType.VALUE_WORK_PACKAGE} />
          {t('nodeDetails:field.planning.workPackagePlanning')}:
        </div>
        <div>
          {minMax.plannedStart && minMax.plannedEnd ? (
            <div>
              {formatCollapsingDateRange(
                fromIsoDateTimeFormat(minMax.plannedStart),
                fromIsoDateTimeFormat(minMax.plannedEnd),
                {
                  day: 'DD.',
                  dayMonth: 'DD.MM.',
                  full: 'DD.MM.YYYY',
                },
              )}{' '}
              ({workDays} {t('general:days', {count: workDays || 0})})
            </div>
          ) : (
            <Placeholder text={t('nodeDetails:field.planning.workPackagePlanning.placeholder')} />
          )}
        </div>
      </div>
    </Frame>
  );
}
