import {LdapSettingsChangedEvent} from '@octaved/flow/src/Modules/Events';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  INVALIDATED,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import ClearIdsFromState from '@octaved/store/src/Reducer/ClearIdsFromState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {createTextInputVariableRules, RulesList, validateLength, validateRules} from '@octaved/store/src/Validation';
import {Uuid} from '@octaved/typescript/src/lib';
import {USER_RIGHTS_CHANGED} from '@octaved/users/src/ActionTypes';
import {
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  LOAD_GROUPS_FAILURE,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_SUCCESS,
  PATCH_GROUP_FAILURE,
  PATCH_GROUP_REQUEST,
  PATCH_GROUP_SUCCESS,
  URM_GROUP_ADDED,
  URM_GROUP_CHANGED,
  URM_GROUP_DELETED,
  URM_GROUP_MOVED,
} from '@octaved/users/src/Modules/ActionTypes';
import {userGroup} from '@octaved/users/src/Schema';
import {generateUuid} from '@octaved/utilities';
import {validateArray, validateUuid} from '@octaved/validation';
import {once} from 'lodash';
import {useMemo} from 'react';
import * as routes from '../../config/routes';
import {GroupEntities, GroupEntity} from '../EntityInterfaces/GroupEntity';
import {groupStateSelector} from '../Selectors/GroupSelectors';
import {setErrors} from './UiPage';

//#region Reducer
export const userGroupStateReducers = createReducerCollection<EntityStates>({});
export const groupEntityStateReducer = userGroupStateReducers.reducer;

userGroupStateReducers.add(LOAD_GROUPS_REQUEST, createTimestampReducer('options.urlParams.groupIds', LOADING));
userGroupStateReducers.add(LOAD_GROUPS_SUCCESS, createTimestampReducer('options.urlParams.groupIds', LOADED));
userGroupStateReducers.add(URM_GROUP_CHANGED, createTimestampReducer('groupIds', INVALIDATED));
userGroupStateReducers.add(USER_RIGHTS_CHANGED, () => ({}));
userGroupStateReducers.add(URM_GROUP_DELETED, ClearIdsFromState('groupIds'));

userGroupStateReducers.add<LdapSettingsChangedEvent>(
  'flow.LdapSettingsChangedEvent',
  createTimestampReducer('affectedGroupIds', INVALIDATED),
);

const reducers = createReducerCollection<GroupEntities>({});
export const entityReducer = reducers.reducer;

//#endregion

interface GroupWithParentEvent {
  groupId: Uuid;
  parentGroupId: Uuid | null;
}

const init = once(() => {
  subscribe('GroupAddedEvent', ({groupId, parentGroupId}: GroupWithParentEvent) => ({
    groupId,
    parentGroupId,
    type: URM_GROUP_ADDED,
  }));
  subscribe('GroupMovedEvent', ({groupId, parentGroupId}: GroupWithParentEvent) => ({
    groupId,
    parentGroupId,
    type: URM_GROUP_MOVED,
  }));
  subscribe('GroupRemovedEvent', ({groupIds}: {groupIds: Uuid[]}) => ({groupIds, type: URM_GROUP_DELETED}));
  subscribe('GroupChangedEvent', ({groupId}: {groupId: Uuid}) => ({groupIds: [groupId], type: URM_GROUP_CHANGED}));
});

/**
 * loads groups into the store if they had not been loaded yet
 *
 * @return {function.<Promise>}
 */
export function loadGroups(groupIds: Uuid[]): ActionDispatcher<void> {
  validateArray(groupIds, validateUuid);
  return (dispatch, getState) => {
    init();
    const toLoad = filterIdsToReload(groupStateSelector(getState()), groupIds);
    if (toLoad.length) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.loadGroups,
          options: {
            urlParams: {
              groupIds: toLoad,
            },
          },
          schema: [userGroup],
          types: {
            failureType: LOAD_GROUPS_FAILURE,
            requestType: LOAD_GROUPS_REQUEST,
            successType: LOAD_GROUPS_SUCCESS,
          },
        },
      });
    }
  };
}

export function useLoadedGroups(groupIds: ReadonlyArray<Uuid> | ReadonlySet<Uuid>): void {
  useStoreEffect(
    (dispatch) => {
      dispatch(loadGroups([...groupIds]));
    },
    [groupIds],
    groupStateSelector,
  );
}

export function useLoadedGroup(groupId: Uuid): void {
  useLoadedGroups(useMemo(() => [groupId], [groupId]));
}

function getGroupValidationRules(data: Partial<Omit<GroupEntity, 'id'>>, create = false): RulesList {
  const rules: RulesList = [];
  if (data.hasOwnProperty('name') || create) {
    rules.push(
      ...createTextInputVariableRules(
        data.name!,
        'core:groups.errors.nameEmpty',
        'core:groups.errors.nameLength',
        'name',
      ),
    );
  }
  if (data.hasOwnProperty('description') || create) {
    rules.push([validateLength, data.description!, 'core:groups.errors.descriptionLength', 'description', 5000]);
  }
  return rules;
}

export function createGroup(): GroupEntity {
  return {
    description: '',
    id: generateUuid(),
    isSynchronizedWithLdapGroup: false,
    isSystemControlled: false,
    name: '',
  };
}

export function putGroup(group: GroupEntity, parentGroupId: Uuid | null = null): ActionDispatcher<Promise<boolean>> {
  return async (dispatch) => {
    init();
    const errors = validateRules(getGroupValidationRules(group));
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    await dispatch({
      [CALL_API]: {
        endpoint: routes.createGroup,
        method: 'put',
        options: {
          data: {...group, parentGroupId},
          urlParams: {groupId: group.id},
        },
        types: {
          failureType: CREATE_GROUP_FAILURE,
          requestType: CREATE_GROUP_REQUEST,
          successType: CREATE_GROUP_SUCCESS,
        },
      },
    });

    return true;
  };
}

export function patchGroup(
  groupId: Uuid,
  data: Partial<Pick<GroupEntity, 'name' | 'description'>>,
): ActionDispatcher<Promise<boolean>> {
  return async (dispatch, _getState) => {
    init();
    const errors = validateRules(getGroupValidationRules(data));
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    const result = await dispatch<Error | undefined>({
      [CALL_API]: {
        endpoint: routes.patchGroup,
        method: 'patch',
        options: {
          data,
          urlParams: {
            groupId,
          },
        },
        types: {
          failureType: PATCH_GROUP_FAILURE,
          requestType: PATCH_GROUP_REQUEST,
          successType: PATCH_GROUP_SUCCESS,
        },
      },
    });
    return !result;
  };
}

export function deleteGroup(groupId: Uuid): ActionDispatcher<Promise<void>> {
  return (dispatch) => {
    init();
    return dispatch({
      [CALL_API]: {
        endpoint: routes.deleteGroup,
        method: 'del',
        options: {
          urlParams: {
            groupId,
          },
        },
        throwNetworkError: true,
        types: {
          failureType: DELETE_GROUP_FAILURE,
          requestType: DELETE_GROUP_REQUEST,
          successType: DELETE_GROUP_SUCCESS,
        },
      },
    });
  };
}
