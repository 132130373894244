import {getOrganizationHeaders} from '@octaved/identity/src/Modules/OrganizationSwitcher';
import {generateUrl} from '@octaved/network/src/Network';
import StackTrace from 'stacktrace-js';
import {writeJsLog} from '../config/routes';
import {LogLevel} from './Logger';

// See JsLogginSchema.json
interface JsLogginSchema {
  colno: number;
  context: string; //Arbitrary string, can be json encoding some additional details
  filename: string;
  level: LogLevel;
  lineno: number;
  location: string;
  message: string;
}

async function writeSchema(schema: JsLogginSchema): Promise<void> {
  // Some network error is lying about its error.message type...
  // noinspection SuspiciousTypeOfGuard
  if (typeof schema.message !== 'string') {
    schema.message = tryStringify(schema.message);
  }
  const fetchSettings: RequestInit = {
    body: JSON.stringify(schema),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...getOrganizationHeaders(),
    },
    method: 'post',
  };
  //do not use Networks post here to prevent recursive error handling
  await fetch(generateUrl(writeJsLog), fetchSettings);
}

function tryStringify(arg: unknown): string {
  try {
    return typeof arg === 'undefined' ? '' : JSON.stringify(arg);
  } catch {
    return '';
  }
}

export async function writeErrorEvent(error: ErrorEvent, context?: unknown): Promise<void> {
  await writeSchema({
    colno: error.colno,
    context: tryStringify(context),
    filename: error.filename,
    level: 'error',
    lineno: error.lineno,
    location: window.location.toString(),
    message: error.message,
  });
}

export async function writeError(error: Error, context?: unknown): Promise<void> {
  const stack = (await StackTrace.fromError(error))[0];
  await writeSchema({
    colno: stack?.columnNumber || 0,
    context: tryStringify(context),
    filename: stack?.fileName || '',
    level: 'error',
    lineno: stack?.lineNumber || 0,
    location: window.location.toString(),
    message: error.message,
  });
}

export async function writeMessage(level: LogLevel, message: string, context?: unknown): Promise<void> {
  const stack = (await StackTrace.get())[2]; //0 is this, 1 the Logger
  await writeSchema({
    level,
    message,
    colno: stack?.columnNumber || 0,
    context: tryStringify(context),
    filename: stack?.fileName || '',
    lineno: stack?.lineNumber || 0,
    location: window.location.toString(),
  });
}
