import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {VisibleTasks} from '../../Components/Task/VisibleTasks';
import {ThunkAct} from '../../Store/Thunk';
import {teamTasksUnitsSelector} from '../Selectors/UiPages/TeamTasksSelectors';
import {setUiState} from '../Ui';
import {initialLeftDrawerUiState, LeftDrawerUiState} from '../Ui/LeftDrawer';

export interface TeamTasksUiState {
  collapsedUnits: Record<Uuid, boolean>;
  leftDrawer: LeftDrawerUiState;
  search: string;
  showTasksOfOtherUsers: boolean;
  units: SlimUnit[];
  visibleTasks: VisibleTasks;
}

export const initialTeamTasksUiState: TeamTasksUiState = {
  collapsedUnits: {},
  leftDrawer: initialLeftDrawerUiState,
  search: '',
  showTasksOfOtherUsers: false,
  units: [],
  visibleTasks: 'all',
};

export function patchTeamTasksUiState(teamTasks: DeepPartial<TeamTasksUiState>): ThunkAct<void> {
  return setUiState({pages: {teamTasks}});
}

export function usePatchTeamTasksUiState(): (patch: DeepPartial<TeamTasksUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((patch) => dispatch(patchTeamTasksUiState(patch)), [dispatch]);
}

export function patchTeamTasksLeftDrawerUiState(patch: Partial<LeftDrawerUiState>): ThunkAct<void> {
  return patchTeamTasksUiState({leftDrawer: patch});
}

type ToggleUnit = <A extends boolean>(add: A, unitId: Uuid, unitType?: A extends true ? SimpleUnitType : never) => void;

export function useToggleUnit(): [SlimUnit[], ToggleUnit] {
  const patch = usePatchTeamTasksUiState();
  const units = useSelector(teamTasksUnitsSelector);
  const unitsRef = useRef(units);
  unitsRef.current = units;

  const toggle = useCallback<ToggleUnit>(
    (add, unitId, unitType) => {
      const index = unitsRef.current.findIndex(({unitId: id}) => id === unitId);
      if (add && index === -1) {
        patch({units: [...unitsRef.current, {unitId, unitType}]});
      }
      if (!add && index > -1) {
        const newUnits = [...unitsRef.current];
        newUnits.splice(index, 1);
        patch({units: newUnits});
      }
    },
    [patch],
  );

  //until we can set the initial data properly on the user, we add the current user once when the list is empty:
  const currentUserId = useSelector(currentOrgUserIdSelector);
  useEffect(() => {
    if (unitsRef.current.length === 0) {
      toggle(true, currentUserId, SimpleUnitType.user);
    }
  }, [currentUserId, toggle]);

  return [units, toggle];
}
