import {Uuid} from '@octaved/typescript/src/lib';

export interface StatisticConditions {
  customerIds: Uuid[];
  from: string;
  includeArchived: boolean;
  interval: 'day' | 'month' | 'quarter';
  rootNodeId: Uuid;
  to: string;
}

export function getStatisticConditionsKey(conditions: StatisticConditions): string {
  return `${conditions.rootNodeId}+${conditions.from}+${conditions.to}+${
    conditions.interval
  }+${conditions.customerIds.join('-')}+${+conditions.includeArchived}`;
}
