import {ReactElement} from 'react';
import {SearchCategoryProps} from 'semantic-ui-react/dist/commonjs/modules/Search/SearchCategory';
import TreePath from '../../../../PageHeader/Components/TreePath';

export default function FlowLabelCategoryRenderer({name}: SearchCategoryProps): ReactElement {
  if (name) {
    return <TreePath projectFolder={name} wrapAble />;
  }
  return <></>;
}
