import {useDaysInsteadOfHours} from '@octaved/flow/src/Components/DaysOrHoursContext';
import {roundToHalfDays} from '@octaved/flow/src/Formatter/Decimal';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  hours: number | undefined;
  shortUnit?: boolean; //if true, "h"/"d" is used instead of "hours"/"days", implies unit=true
  unit?: boolean;
}

export default function Hours({hours, shortUnit, unit}: Props): ReactElement {
  const {t} = useTranslation();
  const showDays = useDaysInsteadOfHours();
  let value = hours;
  let token = 'general:date.hours';
  let unitString = 'h';
  if (showDays) {
    value = typeof hours === 'number' ? roundToHalfDays(hours / 8) : undefined;
    token = 'general:date.days';
    unitString = 'd';
  }
  const formatted = typeof value === 'number' ? formatDecimal(value) : '';

  if (shortUnit || unit) {
    return (
      <span>
        {shortUnit && `${formatted} ${unitString}`}
        {!shortUnit && t(token, {count: value ?? 0, countFormatted: formatted})}
      </span>
    );
  }

  return <span>{formatted}</span>;
}
