import {Text, TextConfig} from 'konva/lib/shapes/Text';
import {createText} from '../../../CommonComponents/Text';
import {Cell, CellProps} from './Cell';

export interface HeaderCellProps extends CellProps {
  token: string;
}

export class HeaderCell extends Cell {
  #textNode: Text | null = null;
  protected textConfig: TextConfig = {};
  #token: string;

  constructor(props: HeaderCellProps) {
    super(props);
    this.#token = props.token;
  }

  init(): void {
    super.init();
    this.#textNode = createText({
      ellipsis: true,
      fontStyle: '500',
      offsetY: this.textLineOffset,
    });
    this.root.add(this.#textNode);
  }

  protected getText(): string {
    return this.ctx.t(this.#token);
  }

  protected doRender(): void {
    const {x, width, isVisble, columnName} = this.renderProps;
    this.root.visible(isVisble);
    if (!isVisble) {
      return;
    }
    if (!this.#textNode) {
      throw new Error('Text node is not initialized');
    }

    this.#textNode.width(width - this.padding * 2);
    this.#textNode.text(this.getText());
    this.#textNode.fill(this.getFontColor());
    this.#textNode.fontVariant(this.getFontVariant());
    this.#textNode.x(columnName === 'name' ? x + this.padding : x);
  }

  protected getFontColor(): string {
    return '#707070';
  }
}
