import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {useNodeTree} from '../NodeTree';
import {idPathSelector} from '../Selectors/NodeTreeSelectors';
import {ProjectFolderTreeNode, projectFolderTreeNodesSelector} from '../Selectors/ProjectFolderSelectors';
import {useLoadedNodes, useLoadNodes} from './Nodes';
import {useNodeSearch} from './NodeSearch';

export function useOuTreeNodes(): {isLoading: boolean; nodes: ProjectFolderTreeNode[]} {
  const isTreeLoaded = useNodeTree();
  const {isLoading: isTypeLoading, nodeIds: ouIds} = useNodeSearch(
    'nodeType',
    EnumFlowNodeType.VALUE_PROJECT_FOLDER,
    true,
    true,
  );
  const {isLoading: ousLoading} = useLoadNodes(ouIds, true);
  const nodes = useSelector(projectFolderTreeNodesSelector);
  return {
    nodes,
    isLoading: !isTreeLoaded || isTypeLoading || ousLoading,
  };
}

export function useNodePath(id: Uuid | null): {nodes: NodeType[]} {
  const ids = useSelector(idPathSelector)(id);
  return useLoadedNodes(ids);
}
