import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {Group, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {isResponsibleNode} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';

export type PlannableNode = WorkPackage | Task | Group | SubWorkPackage;

export function isPlanningDependencyNode(node: unknown): node is PlannableNode {
  return (
    !!node &&
    [
      EnumFlowNodeType.VALUE_WORK_PACKAGE,
      EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
      EnumFlowNodeType.VALUE_TASK,
      EnumFlowNodeType.VALUE_GROUP,
    ].includes((node as NodeType).nodeType)
  );
}

export const isIndirectAssignedNodeSelector = createSelector(getNodeSelector, (getNode) =>
  memoize(
    (nodeId: Uuid, requestedUnitId: Uuid) => {
      const node = getNode(nodeId);
      if (isResponsibleNode(node)) {
        return !(
          node.responsibleGroups.find((id) => id === requestedUnitId) ||
          node.responsibleUsers.find((id) => id === requestedUnitId)
        );
      }
      return true;
    },
    (...args) => args.join('-'),
  ),
);
