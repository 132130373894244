import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {BarTextDisplayment} from '../../../Modules/Ui';
import {CalendarContext, CalendarContextProps, CalendarEvents} from '../../Calendar/Context/CalendarContext';
import {TreeNode} from '../Data/TreeNode';
import {TableFactory} from '../Table/TableFactory';

export interface GanttEvents extends CalendarEvents {
  flatTreeChanged: {flatTree: Readonly<TreeNode[]>};
  visibleColumnsChanged: string[];
  extendedNodesChanged: Record<string, boolean> | null;
  selectedNodeIdChanged: Uuid | null;
}

export type GanttContextProps = CalendarContextProps<GanttEvents>;

export abstract class GanttContext extends CalendarContext<GanttEvents> {
  abstract get visibleColumns(): string[];
  abstract setVisibleColumns(visibleGanttColumns: string[]): void;

  abstract get extendedNodes(): Record<string, boolean> | null;
  abstract setExtendedNodes(extendedNodes: Record<string, boolean>): void;
  abstract getBarTextDisplaymentSelector(state: FlowState): BarTextDisplayment;

  #tableFactory: TableFactory | null = null;

  #lastSelectedNodeId: Uuid | null = null;
  setSelectedNodeId(nodeId: Uuid | null): void {
    if (this.#lastSelectedNodeId !== nodeId) {
      this.#lastSelectedNodeId = nodeId;
      this.eventEmitter.emit('selectedNodeIdChanged', nodeId);
    }
  }

  get selectedNodeId(): Uuid | null {
    return this.#lastSelectedNodeId;
  }

  setTableFactory(tableFactory: TableFactory): void {
    this.#tableFactory = tableFactory;
  }

  calculateTableWidth(): number {
    if (!this.#tableFactory) {
      throw new Error('TableFactory is not set');
    }
    return this.#tableFactory.calculateColumnsWidth(this.visibleColumns);
  }

  canAddNodes(): boolean {
    return false;
  }

  canAddRootNodes(): boolean {
    return false;
  }
}
