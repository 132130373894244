import {useChildPropsFilter, useElementClientRectCb} from '@octaved/hooks';
import {FlexItem, HStack, Stack} from '@octaved/ui';
import {ReactElement, ReactNode, useRef} from 'react';
import FlowOutlet from '../../Components/FlowOutlet';
import PageLockWrapper from '../../Components/PageLock/PageLockWrapper';
import InspectorDrawerRouter from '../../Drawer/InspectorDrawerRouter';
import StandardPageFrameContent, {StandardPageFrameContentProps} from './StandardPageFrameContent';
import StandardPageFrameHeader, {StandardPageFrameHeaderProps} from './StandardPageFrameHeader';

interface StandardPageFrameChildProps {
  StandardPageFrameContent: StandardPageFrameContentProps;
  StandardPageFrameHeader: StandardPageFrameHeaderProps;
}

interface StandardPageFrameProps {
  children?: ReactNode;
  leftDrawer?: ReactNode;
  leftDrawerSpacing?: boolean;
}

export default function StandardPageFrame({
  children,
  leftDrawer,
  leftDrawerSpacing = true,
}: StandardPageFrameProps): ReactElement {
  const props: StandardPageFrameChildProps = useChildPropsFilter(children, [
    'StandardPageFrameContent',
    'StandardPageFrameHeader',
  ]);

  const containerRef = useRef<HTMLDivElement>(null);

  useElementClientRectCb((rect) => {
    if (containerRef.current) {
      containerRef.current.style.setProperty('--standard-page-frame-width', rect.width - 55 + 'px');
    }
  }, containerRef);

  //position=relative for the full screen left drawer
  return (
    <HStack
      alignItems={'stretch'}
      width={'100%'}
      height={'100%'}
      overflow={'hidden'}
      position={'relative'}
      ref={containerRef}
    >
      {leftDrawer}
      <FlexItem flexGrow={1} height={'100%'} overflow={'hidden'}>
        <Stack alignItems={'stretch'} height={'100%'} overflow={'hidden'}>
          <FlexItem flexShrink={0}>
            <PageLockWrapper>
              <StandardPageFrameHeader
                hasSuitcaseDrawer={!!leftDrawer && leftDrawerSpacing}
                {...props.StandardPageFrameHeader}
              />
            </PageLockWrapper>
          </FlexItem>
          <FlexItem flexGrow={1} height={'100%'} overflow={'hidden'}>
            <HStack alignItems={'stretch'} width={'100%'} height={'100%'} overflow={'hidden'}>
              <FlexItem flexGrow={1} width={'100%'} overflow={'hidden'}>
                <PageLockWrapper>
                  <StandardPageFrameContent {...props.StandardPageFrameContent} />
                </PageLockWrapper>
              </FlexItem>
              <FlexItem flexShrink={0}>
                <FlowOutlet />
              </FlexItem>
            </HStack>
          </FlexItem>
        </Stack>
      </FlexItem>
      <InspectorDrawerRouter />
    </HStack>
  );
}
