import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {hasLoadedOnce as hasLoadedOnceFn} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {TimeRecord} from '../../EntityInterfaces/TimeRecords';
import {
  getTimeRecordsForIdsSelector,
  timeRecordsEntityStatesSelector,
  timeRecordsSelector,
} from '../Selectors/TimeRecordsSelectors';
import {FlowState} from '../State';
import {loadTimeRecordsById} from '../TimeRecords';
import {useLoadNodes} from './Nodes';
import {useTimeRecordSearch} from './TimeRecordSearch';

export function useLoadedTimeRecords(ids: ReadonlyArray<Uuid>): {
  isLoading: boolean; // deprecated, use hasLoadedOnce
  hasLoadedOnce: boolean;
  timeRecords: TimeRecord[];
} {
  const entities = useSelector(timeRecordsSelector);

  useStoreEffect((dispatch) => dispatch(loadTimeRecordsById(ids)), [ids], timeRecordsEntityStatesSelector);

  const [timeRecords, wpIds] = useMemo(() => {
    const trs: TimeRecord[] = [];
    const wpIds = new Set<Uuid>();
    [...new Set(ids)].forEach((id) => {
      const tr = entities[id];
      if (tr) {
        trs.push(tr);
        if (tr.workPackage) {
          wpIds.add(tr.workPackage);
        }
      }
    });
    trs.sort((a, b) => (a.workTimeStart || 0) - (b.workTimeStart || 0));
    return [trs, [...wpIds]];
  }, [entities, ids]);

  const {hasLoadedOnce: haveNodesLoadedOnce} = useLoadNodes(wpIds);

  const hasLoadedOnce = useSelector((s: FlowState) => {
    const trState = timeRecordsEntityStatesSelector(s);
    return haveNodesLoadedOnce && ids.every((id) => hasLoadedOnceFn(trState[id] || {}));
  });

  return {
    hasLoadedOnce,
    timeRecords,
    isLoading: !hasLoadedOnce, //incorrect actually... deprecated
  };
}

export function useLoadedTimeRecord(id: Uuid | null | undefined): {
  isLoading: boolean;
  timeRecord: TimeRecord | undefined;
} {
  const {isLoading, timeRecords} = useLoadedTimeRecords(useMemo(() => (id ? [id] : []), [id]));
  return {
    isLoading,
    timeRecord: timeRecords[0],
  };
}

export function useReferenceNodeTimeRecords(
  referenceNodeId: Uuid | null | undefined,
  search = '',
): {
  hasLoadedOnce: boolean;
  hasTimeRecords: boolean;
  isLoading: boolean;
  timeRecords: TimeRecord[];
} {
  const getTimeRecordsForIds = useSelector(getTimeRecordsForIdsSelector);

  const {isLoading: searchPidRecordsLoading, ids: trIds} = useTimeRecordSearch(
    'referenceNodeId',
    referenceNodeId ?? '',
    !!referenceNodeId,
    true,
  );
  const {isLoading: timeRecordsAreLoading} = useLoadedTimeRecords(trIds);
  const isLoading = searchPidRecordsLoading || timeRecordsAreLoading;

  const timeRecords = useMemo<TimeRecord[]>(() => {
    let recordings: TimeRecord[];
    const lowerSearch = search.toLocaleLowerCase();
    const allTimeRecords = getTimeRecordsForIds(trIds);
    if (lowerSearch) {
      recordings = allTimeRecords.filter((timeRecord) => timeRecord.message.toLocaleLowerCase().includes(lowerSearch));
    } else {
      recordings = allTimeRecords;
    }
    recordings.sort((a, b) => b.workTimeStart! - a.workTimeStart!);
    return recordings;
  }, [trIds, getTimeRecordsForIds, search]);

  const loadedTimeRecords = useLoadedValue(isLoading, timeRecords);

  return {
    isLoading,
    hasLoadedOnce: useLoadedValue(isLoading, !isLoading),
    hasTimeRecords: loadedTimeRecords.length > 0,
    timeRecords: loadedTimeRecords,
  };
}

export function useLastTimeRecordForUser(userId: Uuid): {
  isLoading: boolean;
  timeRecord: TimeRecord | undefined;
} {
  const {isLoading: isSearching, ids} = useTimeRecordSearch('orgUserId', userId, true, true);
  //All searches are sorted by workTimeStart so we can just use the last search result:
  const {isLoading, timeRecords} = useLoadedTimeRecords(useMemo(() => ids.slice(-1), [ids]));
  useLoadedTimeRecord(ids[ids.length - 1]);
  return {
    isLoading: isSearching || isLoading,
    timeRecord: timeRecords[0],
  };
}
