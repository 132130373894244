import {
  createLabel as createLabelRoute,
  getLabels as getLabelsRoute,
  patchLabel,
  removeLabel as removeLabelRoute,
} from '@octaved/flow-api';
import {CALL_API, ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  optimisticAdd,
  optimisticDelete,
  optimisticUpdate,
  setToReducerMap,
} from '@octaved/store/src/Reducer/OptimisticReduce';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {RulesList} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {generateUuid} from '@octaved/utilities';
import objectContains from '@octaved/validation/src/ObjectContains';
import {Label, Labels} from '../EntityInterfaces/Labels';
import {
  FLOW_CREATE_LABEL_FAILURE,
  FLOW_CREATE_LABEL_REQUEST,
  FLOW_CREATE_LABEL_SUCCESS,
  FLOW_GET_LABEL_FAILURE,
  FLOW_GET_LABEL_REQUEST,
  FLOW_GET_LABEL_SUCCESS,
  FLOW_PATCH_LABEL_FAILURE,
  FLOW_PATCH_LABEL_REQUEST,
  FLOW_PATCH_LABEL_SUCCESS,
  FLOW_REMOVE_LABEL_FAILURE,
  FLOW_REMOVE_LABEL_REQUEST,
  FLOW_REMOVE_LABEL_SUCCESS,
} from './ActionTypes';
import {label} from './Schema';
import {labelSelector} from './Selectors/LabelSelectors';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';
import {getNodeNameValidationRules} from './Validation/NodeNameValidationRules';

export type LabelPatchData = Omit<Label, 'id' | 'rootFolder'>;

//#region reducer
export const reducerMap = new Map();
type Request = ServerRequestAction<{
  data: Label;
  urlParams: {labelId: Uuid};
}>;
setToReducerMap<Labels, Request>(
  optimisticUpdate,
  reducerMap,
  FLOW_PATCH_LABEL_REQUEST,
  FLOW_PATCH_LABEL_FAILURE,
  FLOW_PATCH_LABEL_SUCCESS,
  ({options}) => options.urlParams.labelId,
);
setToReducerMap<Labels, Request>(
  optimisticAdd,
  reducerMap,
  FLOW_CREATE_LABEL_REQUEST,
  FLOW_CREATE_LABEL_FAILURE,
  FLOW_CREATE_LABEL_SUCCESS,
  ({options}) => options.data.id,
);
setToReducerMap<Labels, Request>(
  optimisticDelete,
  reducerMap,
  FLOW_REMOVE_LABEL_REQUEST,
  FLOW_REMOVE_LABEL_FAILURE,
  FLOW_REMOVE_LABEL_SUCCESS,
  ({options}) => options.urlParams.labelId,
);
reducerMap.set('flow.LabelRemovedEvent', (labels: Labels, {id}: {id: Uuid}) => {
  if (labels[id]) {
    const copiedLabels = {...labels};
    delete copiedLabels[id];
    return copiedLabels;
  }
  return labels;
});

export const reducer = ReduceFromMap(reducerMap);

//#endregion

function getValidationRules(id: Uuid, data: DeepPartial<LabelPatchData>, isCreation: boolean): RulesList {
  if (data.hasOwnProperty('name')) {
    return getNodeNameValidationRules(id, data, isCreation);
  }
  return [];
}

function getLabels(): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, _getState) => {
    dispatch({
      [CALL_API]: {
        _debounceSimultaneousCalls: true,
        endpoint: getLabelsRoute,
        method: 'get',
        schema: [label],
        types: {
          failureType: FLOW_GET_LABEL_FAILURE,
          requestType: FLOW_GET_LABEL_REQUEST,
          successType: FLOW_GET_LABEL_SUCCESS,
        },
      },
    });
  };
}

export function createLabel(data: LabelPatchData, rootFolder: Uuid): ActionDispatcher<Promise<Uuid | null>, FlowState> {
  return async (dispatch: Dispatch, _getState) => {
    if (!validateErrorRules(getValidationRules('', data, true), dispatch)) {
      return null;
    }

    const id = generateUuid();
    await dispatch({
      [CALL_API]: {
        endpoint: createLabelRoute,
        method: 'put',
        options: {
          data: {
            ...data,
            id,
            rootFolder,
          },
        },
        types: {
          failureType: FLOW_CREATE_LABEL_FAILURE,
          requestType: FLOW_CREATE_LABEL_REQUEST,
          successType: FLOW_CREATE_LABEL_SUCCESS,
        },
      },
    });

    return id;
  };
}

export function updateLabel(
  labelId: Uuid,
  data: DeepPartial<LabelPatchData>,
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(labelId, data, true), dispatch)) {
      return false;
    }

    const label = labelSelector(getState())(labelId);
    if (label && !objectContains(label, data)) {
      dispatch({
        [CALL_API]: {
          endpoint: patchLabel,
          method: 'patch',
          options: {
            data,
            urlParams: {
              labelId,
            },
          },
          types: {
            failureType: FLOW_PATCH_LABEL_FAILURE,
            requestType: FLOW_PATCH_LABEL_REQUEST,
            successType: FLOW_PATCH_LABEL_SUCCESS,
          },
        },
      });
    }
    return true;
  };
}

export function removeLabel(labelId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, _getState) => {
    dispatch({
      [CALL_API]: {
        endpoint: removeLabelRoute,
        method: 'del',
        options: {
          urlParams: {
            labelId,
          },
        },
        types: {
          failureType: FLOW_REMOVE_LABEL_FAILURE,
          requestType: FLOW_REMOVE_LABEL_REQUEST,
          successType: FLOW_REMOVE_LABEL_SUCCESS,
        },
      },
    });
  };
}

subscribe('flow.LabelCreatedEvent', getLabels);
subscribe('flow.LabelPatchedEvent', getLabels);
