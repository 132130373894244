import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {useExtendUnitsWithName} from '@octaved/users/src/Hooks/UnitLists';
import cs from 'classnames';
import {ReactElement, useMemo} from 'react';
import BaseFlowAvatar from './BaseFlowAvatar';

interface Props {
  avatars: SlimUnit[];
  countBehind?: boolean;
}

export default function AvatarStack({avatars, countBehind}: Props): ReactElement {
  const count = useMemo(() => avatars.length, [avatars]);
  const units = useExtendUnitsWithName(avatars);
  const visibleAvatars = useMemo(() => units.slice(0, 3), [units]);

  return (
    <div className={'stack'}>
      <div className={cs('row', `count-${count}`)}>
        {!countBehind && count > 3 && <div className={'count'}>+{count - 3}</div>}
        <div className={'avatarStack'}>
          {visibleAvatars.map(({unitId, unitType}) => (
            <div key={unitId} className={'avatar'}>
              <BaseFlowAvatar id={unitId} type={unitType} />
            </div>
          ))}
        </div>
        {countBehind && count > 3 && <div className={'count'}>+{count - 3}</div>}
      </div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .stack {
          height: 40px;
        }

        .count {
          margin-right: 5px;
          margin-left: 5px;
        }

        .avatarStack {
          position: relative;
          height: 40px;
          width: 45px;

          .count-1 & {
            width: 30px;
          }

          .count-2 & {
            width: 38px;
          }
        }

        .avatar {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
        }

        .avatar + .avatar {
          right: 8px;
          z-index: 1;
        }

        .avatar + .avatar + .avatar {
          right: 16px;
          z-index: 0;
        }

        .row {
          display: flex;
          align-items: center;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
