import {EnumFlowNodeType, EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {transformEffort} from '@octaved/flow/src/Modules/Hooks/EffortTransformer';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {TWAutosaveInput} from '@octaved/ui';
import {formatDecimal, parse, validateLocaleFormat} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';
import SubWorkPackageEffort from './SubWorkPackageEffort';

const ownLabelBillingTypes: Record<string, string> = {
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE]: 'nodeDetails:field.effort.fixedPrice.label',
};

type RequiredFields = keyof Pick<WorkPackage, 'effortFrom' | 'effortTo' | 'maxEffort'>;

export interface WorkPackageEffortProps {
  includeSubWorkPackages?: boolean;
  labelToken?: string;
  node: WorkPackage;
  patch: (data: Partial<WorkPackage>) => void;
  readonly?: boolean;
  requiredFieldsByAutosave?: RequiredFields[];
}

export default function WorkPackageEffort({
  frame: Frame,
  includeSubWorkPackages = false,
  labelToken = 'nodeDetails:field.effort.label',
  node,
  patch,
  readonly,
  requiredFieldsByAutosave = [],
}: WorkPackageEffortProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const sectionLabel = ownLabelBillingTypes[node.billingType ?? ''] ?? labelToken;
  const hasFromField = node.billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO;
  const effortPatchField = node.billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO ? 'effortTo' : 'maxEffort';
  const subWorkPackageIds = useCombinedNodeSearch({
    and: [withDescendants(node.id, true), ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]],
  });

  return (
    <>
      <Frame labelToken={sectionLabel}>
        <div className={'flex items-center gap-x-2'}>
          {readonly && (
            <>
              {hasFromField && (
                <>
                  {formatDecimal(node.effortFrom)}
                  {'h '}
                  {t('nodeDetails:field.effort.to')}
                </>
              )}
              {formatDecimal(node[effortPatchField])}h
            </>
          )}
          {!readonly && (
            <>
              {hasFromField && (
                <>
                  <TWAutosaveInput
                    wrapperClassname={'w-shortNumberInput'}
                    value={node.effortFrom ? formatDecimal(node.effortFrom) + '' : ''}
                    onSave={(effortFrom) => {
                      if (!effortFrom || validateLocaleFormat(effortFrom)) {
                        patch({effortFrom: effortFrom !== '' ? parse(effortFrom) : null});
                      }
                    }}
                    disabled={readonly}
                    hasWarning={requiredFieldsByAutosave.includes('effortFrom') && !node.effortFrom}
                    valueTransformer={transformEffort}
                  />
                  <div>{t('nodeDetails:field.effort.to')}</div>
                </>
              )}
              <TWAutosaveInput
                wrapperClassname={'w-shortNumberInput'}
                value={node[effortPatchField] ? formatDecimal(node[effortPatchField]) + '' : ''}
                onSave={(maxEffort) => {
                  if (!maxEffort || validateLocaleFormat(maxEffort)) {
                    patch({[effortPatchField]: maxEffort !== '' ? parse(maxEffort) : null});
                  }
                }}
                hasWarning={requiredFieldsByAutosave.includes(effortPatchField) && !node[effortPatchField]}
                disabled={readonly}
                valueTransformer={transformEffort}
              />
            </>
          )}
        </div>
      </Frame>

      {includeSubWorkPackages && subWorkPackageIds.nodeIds.length > 0 && (
        <Frame labelToken={'nodeDetails:field.effort.subWorkPackages.label'}>
          <SubWorkPackageEffort swpsIds={subWorkPackageIds.nodeIds} wpEffort={node[effortPatchField]} />
        </Frame>
      )}
    </>
  );
}
