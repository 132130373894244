import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {mapCache} from '@octaved/utilities';
import {createSelector} from 'reselect';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {customerEntitiesSelector, formatCustomer, formatCustomerLocation} from './CustomerSelectors';
import {isProject, TimeControlledNode} from '../../Node/NodeIdentifiers';
import {getNodeAncestrySelector} from './NodeTreeSelectors';

/**
 * Sort function for projects. Sorts by name or time control if names are equal.
 */
export function sortTimeControlledNode<N extends Pick<TimeControlledNode, 'name' | 'timeControl'>>(
  a: N | null | undefined,
  b: N | null | undefined,
): number {
  return (
    (a?.name || '').localeCompare(b?.name || '') ||
    (a?.timeControl?.from || '').localeCompare(b?.timeControl?.from || '')
  );
}

type SortNodeType = EnumFlowNodeType | 'customer' | 'customerLocation';
export type SortNodeEntity =
  | NodeType
  | {nodeType: 'customer'; name: string}
  | {
      nodeType: 'customerLocation';
      name: string;
    };

const sortName = (a: {name: string}, b: {name: string}): number => a.name.localeCompare(b.name);

const sortSortOrder = (a: {sortOrder: number}, b: {sortOrder: number}): number => a.sortOrder - b.sortOrder;

const sorters: Partial<{
  [A in SortNodeType]: (a: SortNodeEntity & {nodeType: A}, b: SortNodeEntity & {nodeType: A}) => number;
}> = {
  [EnumFlowNodeType.VALUE_BOARD_POST]: (a, b) => b.lastChangedOn - a.lastChangedOn,
  [EnumFlowNodeType.VALUE_GROUP]: sortSortOrder,
  customer: sortName,
  customerLocation: sortName,
  [EnumFlowNodeType.VALUE_PROJECT]: sortTimeControlledNode,
  [EnumFlowNodeType.VALUE_PROJECT_FOLDER]: sortName,
  [EnumFlowNodeType.VALUE_TASK]: sortSortOrder,
  [EnumFlowNodeType.VALUE_TASK_SECTION]: sortSortOrder,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: sortSortOrder,
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: sortSortOrder,
};

const sortTypeOrder = (
  [
    EnumFlowNodeType.VALUE_PROJECT_FOLDER,
    'customer',
    'customerLocation',
    EnumFlowNodeType.VALUE_PROJECT,
    EnumFlowNodeType.VALUE_GROUP,
    EnumFlowNodeType.VALUE_WORK_PACKAGE,
    EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
    EnumFlowNodeType.VALUE_TASK,
    EnumFlowNodeType.VALUE_TASK_SECTION,
    EnumFlowNodeType.VALUE_BOARD_POST,
  ] as const
).reduce((acc, type, index) => acc.set(type, index), new Map<SortNodeType, number>());

export function compareNodes(a: SortNodeEntity, b: SortNodeEntity): number {
  if (a.nodeType === b.nodeType) {
    const sorter = sorters[a.nodeType];
    // @ts-ignore too complex types !?
    return sorter ? sorter(a, b) : 0;
  }
  return (sortTypeOrder.get(a.nodeType) || -1) - (sortTypeOrder.get(b.nodeType) || -1);
}

const getPathWithCustomerBeforeProjectSelector = createSelector(customerEntitiesSelector, (customers) =>
  mapCache((path: ReadonlyArray<NodeType>): SortNodeEntity[] => {
    const result: SortNodeEntity[] = [];
    path.forEach((node) => {
      if (isProject(node)) {
        const customer = customers[node.flowCustomer];
        if (customer) {
          result.push({nodeType: 'customer', name: formatCustomer(customer)});
          const location = customer.locations.find(({id}) => id === node.customerLocation);
          if (location) {
            result.push({nodeType: 'customerLocation', name: formatCustomerLocation(location)});
          }
        }
      }
      result.push(node);
    });
    return result;
  }),
);

/**
 * Sorts nodes by their full path, including project folders.
 */
export const sortNodesByPathSelector = createSelector(
  getNodeAncestrySelector,
  getPathWithCustomerBeforeProjectSelector,
  (getNodeAncestry, getPathWithCustomerBeforeProject) =>
    mapCache(<NT extends NodeType>(nodes: ReadonlyArray<NT>, includeCustomer = false): ReadonlyArray<NT> => {
      return nodes.toSorted((a, b) => {
        const aPath = getNodeAncestry(a.id, true).path;
        const bPath = getNodeAncestry(b.id, true).path;
        const aPathExt = includeCustomer ? getPathWithCustomerBeforeProject(aPath) : aPath;
        const bPathExt = includeCustomer ? getPathWithCustomerBeforeProject(bPath) : bPath;
        let i = 0;

        while (true) {
          const aAnc = aPathExt[i];
          const bAnc = bPathExt[i];
          if (aAnc && bAnc) {
            const comp = compareNodes(aAnc, bAnc);
            if (comp) {
              return comp;
            } else {
              i++;
            }
          } else if (aAnc && !bAnc) {
            return 1;
          } else if (!aAnc && bAnc) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    }),
);
