import {PropsWithChildren, ReactElement, createContext, useContext, useState} from 'react';

const context = createContext<{
  value: number | null;
  setValue: (value: number | null) => void;
}>({
  setValue: () => undefined,
  value: null,
});

export function HoverableShapeContextProvider({children}: PropsWithChildren<unknown>): ReactElement {
  const [hovering, setHovering] = useState<number | null>(null);

  return (
    <context.Provider
      value={{
        setValue: setHovering,
        value: hovering,
      }}
    >
      {children}
    </context.Provider>
  );
}

export function useHoverShapeIndex(): number | null {
  return useContext(context).value;
}

export function useSetHoverShapeIndex(): (value: number | null) => void {
  return useContext(context).setValue;
}
