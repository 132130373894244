import {useBoolean} from '@octaved/hooks';
import {DateStr} from '@octaved/typescript';
import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {isObject} from 'lodash';
import {ReactElement, memo} from 'react';
import {PublicDatePickerOverride} from './DateOrRangePicker';
import PopupPickerView from './Internal/PopupPickerView';
import StandardDatePickerFooter, {StandardDatePickerFooterProps} from './Internal/StandardDatePickerFooter';
import StandardDatePickerTrigger, {StandardDatePickerTriggerProps} from './Internal/StandardDatePickerTrigger';

export interface DatePickerProps {
  datePickerOverride?: PublicDatePickerOverride<DateStr, true>;
  date: DateStr | null;
  defaulOpen?: boolean;
  footerOverride?: Omit<StandardDatePickerFooterProps, 'clear'> | ReactElement;
  triggerClassName?: string;
  isDayBlocked?: (date: DateStr) => boolean;
  readonly?: boolean;
  setDate: (date: DateStr | null) => void;
  strictBlockedDates?: boolean;
  triggerOverride?: Partial<Omit<StandardDatePickerTriggerProps, 'onToggle' | 'isActive'>> | ReactElement;
}

//Wrapper to pick only a single day
const DatePicker = memo(function DatePicker({
  datePickerOverride,
  date,
  defaulOpen = false,
  footerOverride,
  isDayBlocked,
  triggerClassName = '',
  readonly,
  setDate,
  strictBlockedDates,
  triggerOverride,
}: DatePickerProps): ReactElement {
  const [open, {on, off, toggle}] = useBoolean(defaulOpen);

  let trigger = (
    <div>
      <StandardDatePickerTrigger
        triggerClassName={triggerClassName}
        date={date}
        onToggle={toggle}
        readonly={readonly}
        isActive={open}
        {...(isObject(triggerOverride) ? triggerOverride : {})}
      />
    </div>
  );

  if (typeof triggerOverride === 'function') {
    trigger = triggerOverride;
  }

  let footer = (
    <StandardDatePickerFooter
      clear={() => {
        setDate(null);
        off();
      }}
      {...(isObject(footerOverride) ? footerOverride : {})}
    />
  );

  if (typeof footerOverride === 'function') {
    footer = footerOverride;
  }

  return (
    <PopupPickerView
      datePickerProps={{
        isDayBlocked,
        strictBlockedDates,
        isRange: false,
        ...datePickerOverride,
      }}
      popupProps={{
        trigger,
        onClose: off,
        onOpen: on,
        open: !readonly && open,
      }}
      start={date}
      end={date}
      onChangeDate={(_startDate, date) => {
        setDate(date);
        off();
      }}
      footer={footer}
      convertFrom={fromIsoFormat}
      convertTo={toIsoFormat}
    />
  );
});

export {DatePicker};
