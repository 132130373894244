import {EnumFlowRoleType} from '@octaved/env/src/dbalEnumTypes';
import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {
  PermissionRoleAssignmentOnNode,
  StoreNodePermissionRoleAssignmentEntry,
  StoreNodePermissionRoleAssignments,
} from '../../../EntityInterfaces/RoleAssignments/PermissionRoleAssignments';
import {FlowState} from '../../State';
import {
  getEffectiveRoleAssignmentsForNodesSelectorFactory,
  getRoleAssignmentsLoadedOnceSelectorFactory,
  getRoleAssignmentsLoadedSelectorFactory,
  getRoleAssignmentsOnNodeSelectorFactory,
} from './NodeRoleAssignmentSelectors';

const nodeGuestPermissionRoleAssignmentsSelector = (state: FlowState): StoreNodePermissionRoleAssignments =>
  state.roles.nodeGuestPermissionRoleAssignments;
export const nodeGuestPermissionRoleAssignmentsStateSelector = (state: FlowState): EntityStates =>
  state.roles.nodeGuestPermissionRoleAssignmentsStates;

const nodeInternalPermissionRoleAssignmentsSelector = (state: FlowState): StoreNodePermissionRoleAssignments =>
  state.roles.nodeInternalPermissionRoleAssignments;
export const nodeInternalPermissionRoleAssignmentsStateSelector = (state: FlowState): EntityStates =>
  state.roles.nodeInternalPermissionRoleAssignmentsStates;

export const nodeGuestPermissionRoleAssignmentEntrySelector = createSelector(
  nodeGuestPermissionRoleAssignmentsSelector,
  (assignments) => (nodeId: Uuid, unitId: Uuid) => assignments[nodeId]?.entries[unitId],
);
export const nodeInternalPermissionRoleAssignmentEntrySelector = createSelector(
  nodeInternalPermissionRoleAssignmentsSelector,
  (assignments) => (nodeId: Uuid, unitId: Uuid) => assignments[nodeId]?.entries[unitId],
);
export const nodePermissionRoleAssignmentEntrySelector = createSelector(
  nodeGuestPermissionRoleAssignmentEntrySelector,
  nodeInternalPermissionRoleAssignmentEntrySelector,
  (nodeGuestPermissionRoleAssignmentEntry, nodeInternalPermissionRoleAssignments) =>
    (isGuestRole: boolean, nodeId: Uuid, unitId: Uuid) =>
      (isGuestRole ? nodeGuestPermissionRoleAssignmentEntry : nodeInternalPermissionRoleAssignments)(nodeId, unitId),
);

export const getGuestPermissionRoleAssignmentsLoadedSelector = getRoleAssignmentsLoadedSelectorFactory(
  nodeGuestPermissionRoleAssignmentsStateSelector,
);
export const getInternalPermissionRoleAssignmentsLoadedSelector = getRoleAssignmentsLoadedSelectorFactory(
  nodeInternalPermissionRoleAssignmentsStateSelector,
);

export const getGuestPermissionRoleAssignmentsLoadedOnceSelector = getRoleAssignmentsLoadedOnceSelectorFactory(
  nodeGuestPermissionRoleAssignmentsStateSelector,
);
export const getInternalPermissionRoleAssignmentsLoadedOnceSelector = getRoleAssignmentsLoadedOnceSelectorFactory(
  nodeInternalPermissionRoleAssignmentsStateSelector,
);

export const getGuestPermissionRoleAssignmentsOnNodeSelector = getRoleAssignmentsOnNodeSelectorFactory<
  StoreNodePermissionRoleAssignmentEntry,
  'unitId',
  PermissionRoleAssignmentOnNode
>(nodeGuestPermissionRoleAssignmentsSelector, 'unitId');

export const getInternalPermissionRoleAssignmentsOnNodeSelector = getRoleAssignmentsOnNodeSelectorFactory<
  StoreNodePermissionRoleAssignmentEntry,
  'unitId',
  PermissionRoleAssignmentOnNode
>(nodeInternalPermissionRoleAssignmentsSelector, 'unitId');

export const getEffectiveGuestPermissionRoleAssignmentsSelector = getEffectiveRoleAssignmentsForNodesSelectorFactory(
  getGuestPermissionRoleAssignmentsOnNodeSelector,
  EnumFlowRoleType.VALUE_PERMISSION,
);

export const getEffectiveInternalPermissionRoleAssignmentsSelector = getEffectiveRoleAssignmentsForNodesSelectorFactory(
  getInternalPermissionRoleAssignmentsOnNodeSelector,
  EnumFlowRoleType.VALUE_PERMISSION,
);
