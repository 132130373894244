import {error} from '@octaved/env/src/Logger';
import {createSubRecordSelector} from '@octaved/store/src/Selectors/CreateSubRecordSelector';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {getDarkenColor} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import {FullUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {useExtendUnitsWithName} from '@octaved/users/src/Hooks/UnitLists';
import {boolFilter} from '@octaved/utilities';
import {CSSProperties, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Nodes, NodeType} from '../../EntityInterfaces/Nodes';
import {loadNodes} from '../Nodes';
import {
  isGroup,
  isPid,
  isPidOrSubWOrkPackage,
  isProject,
  isSubWorkPackage,
  isTask,
  isTaskSection,
  isWorkPackage,
} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector, nodeEntityStatesSelector} from '../Selectors/NodeSelectors';
import {getNodeColorSelector} from '../Selectors/NodeTreeSelectors';
import {getNodeResponsibleUnitsSelector} from '../Selectors/ResponsibleNodeSelectors';
import {FlowState} from '../State';
import {LoadingOnce, LoadingTracked, useLoading} from './Loading';

type Ids = ReadonlyArray<Uuid> | ReadonlySet<Uuid>;

export function useLoadNodes<TrackIsLoading extends boolean>(
  nodeIds: Ids,
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? LoadingTracked : LoadingOnce {
  return useLoading(nodeIds, nodeEntityStatesSelector, loadNodes, trackIsLoading);
}

export function useLoadNode<TrackIsLoading extends boolean>(
  nodeId: Uuid | null | undefined,
  trackIsLoading?: TrackIsLoading,
): TrackIsLoading extends true ? LoadingTracked : LoadingOnce {
  return useLoadNodes(
    useMemo(() => (nodeId ? [nodeId] : []), [nodeId]),
    trackIsLoading,
  );
}

interface LoadedNodes<T extends NodeType> {
  hasLoadedOnce: boolean;
  nodes: T[];
}

interface LoadedNodesTracked<T extends NodeType> extends LoadedNodes<T> {
  isLoading: boolean;
}

const emptyLoading: LoadedNodesTracked<NodeType> = {
  hasLoadedOnce: true,
  isLoading: false,
  nodes: [],
};

export function useLoadedNodes<T extends NodeType>(
  nodeIds: Ids, //should be memo'd!
  options: {
    sorted?: boolean;
    trackIsLoading: true;
  },
): LoadedNodesTracked<T>;
export function useLoadedNodes<T extends NodeType>(
  nodeIds: Ids, //should be memo'd!
  options?: {
    sorted?: boolean;
    trackIsLoading?: boolean;
  },
): LoadedNodes<T>;
export function useLoadedNodes<T extends NodeType, TrackIsLoading extends boolean>(
  nodeIds: Ids, //should be memo'd!
  {
    sorted,
    trackIsLoading,
  }: {
    sorted?: boolean;
    trackIsLoading?: TrackIsLoading;
  } = {},
): TrackIsLoading extends true ? LoadedNodesTracked<T> : LoadedNodes<T> {
  const loadNodes = useLoadNodes(nodeIds, trackIsLoading as true);
  const resultRef = useRef<LoadedNodesTracked<T>>();
  const entitiesRef = useRef<Nodes>();
  return useSelector((s: FlowState): LoadedNodesTracked<T> => {
    const nodeIdsArray = [...nodeIds];
    if (!nodeIdsArray.length) {
      return emptyLoading as LoadedNodesTracked<T>;
    }
    const nodeEntities = createSubRecordSelector(nodeEntitySelector, nodeIds)(s);
    const entitiesChanged = entitiesRef.current !== nodeEntities;
    entitiesRef.current = nodeEntities;
    if (
      !resultRef.current ||
      entitiesChanged ||
      resultRef.current!.hasLoadedOnce !== loadNodes.hasLoadedOnce ||
      resultRef.current!.isLoading !== loadNodes.isLoading
    ) {
      resultRef.current = {
        hasLoadedOnce: loadNodes.hasLoadedOnce,
        isLoading: loadNodes.isLoading,
        nodes:
          entitiesChanged || !resultRef.current
            ? (() => {
                const nodes = boolFilter(nodeIdsArray.map((id) => nodeEntities[id]));
                if (sorted) {
                  nodes.sort((a, b) => a.name.localeCompare(b.name));
                }
                return nodes as T[];
              })()
            : resultRef.current!.nodes,
      };
    }
    return resultRef.current;
  });
}

function createUseNode<T>(
  validator: (node: unknown) => node is T,
  expected: string,
): (id: Uuid | undefined | null) => T | undefined {
  return (id) => {
    return useSelector((state: FlowState) => {
      const node = id ? nodeEntitySelector(state)[id] : undefined;
      if (node && !validator(node)) {
        error(new Error(`${expected} is requested but '${node?.nodeType}' type is returned`));
        return undefined;
      }
      return node;
    });
  };
}

export function useNode(id: MaybeUuid): NodeType | undefined;
export function useNode<N extends NodeType>(id: MaybeUuid, validator: (n: unknown) => n is N): N | undefined;
export function useNode<N extends NodeType>(id: MaybeUuid, validator?: (n: unknown) => n is N): N | undefined {
  if (validator) {
    return createUseNode(validator, 'Node')(id);
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSelector((s: FlowState) => (id ? nodeEntitySelector(s)[id] : undefined)) as N | undefined;
}

export const usePid = createUseNode(isPid, 'Pid');
export const usePidOrSubWorkPackage = createUseNode(isPidOrSubWOrkPackage, 'Pid or SubWorkPackage');
export const useProject = createUseNode(isProject, 'Project');
export const useGroup = createUseNode(isGroup, 'Group');
export const useWorkPackage = createUseNode(isWorkPackage, 'Work package');
export const useTask = createUseNode(isTask, 'Task');
export const useTaskSection = createUseNode(isTaskSection, 'Task section');
export const useSubWorkPackage = createUseNode(isSubWorkPackage, 'Sub Work package');

export function useNodeResponsibleFullUnits(nodeId: Uuid): FullUnit[] {
  useLoadNode(nodeId);
  const responsibleUnits = useSelector((s: FlowState) => getNodeResponsibleUnitsSelector(s)(nodeId));
  return useExtendUnitsWithName(responsibleUnits);
}

export function useNodeColor(id: MaybeUuid): string {
  return useSelector((s: FlowState) => getNodeColorSelector(s)(id));
}

export function useNodeBackground(nodeId: MaybeUuid): CSSProperties {
  const color = useNodeColor(nodeId);
  const node = useNode(nodeId);
  const isSwp = isSubWorkPackage(node);
  return useMemo(() => {
    const darkenColor = getDarkenColor(color, -35);
    return {
      background: isSwp
        ? `repeating-linear-gradient(45deg, ${darkenColor}, ${darkenColor} 5px, #${color} 5px, #${color} 10px)`
        : `#${color}`,
    };
  }, [color, isSwp]);
}
