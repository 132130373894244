import {FlowState} from '@octaved/flow/src/Modules/State';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {notEmpty, RuleResult, RulesList, validateRules} from '@octaved/store/src/Validation';
import once from 'lodash/once';
import {createAllowedLoginIp, deleteAllowedLoginIp, getAllowedLoginIps, patchAllowedLoginIp} from '../../config/routes';
import {OrganizationAllowedIPEntities} from '../EntityInterfaces/OrganizationAllowedIP';
import {
  ADD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
  ADD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
  ADD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
  LOAD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
  LOAD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
  LOAD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
  ORGANIZATION_ALLOWED_IP_CLEAR,
  ORGANIZATION_ALLOWED_IP_DELETED,
  REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
  REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
  REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
  UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
  UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
  UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
} from './ActionTypes';
import {organizationAllowedLoginIP} from './Schema';
import {setErrors} from './UiPage';

//# region <Interfaces>
export interface SystemManagerOrganizationAllowedIPClear {
  type: typeof ORGANIZATION_ALLOWED_IP_CLEAR;
}

export interface SystemManagerOrganizationAllowedIPDeleted {
  type: typeof ORGANIZATION_ALLOWED_IP_DELETED;
  organizationAllowedIPId: number;
}

//#endregion

//#region <reducer>
const reducerMap = new Map();
reducerMap.set(
  ORGANIZATION_ALLOWED_IP_DELETED,
  (
    state: OrganizationAllowedIPEntities,
    {organizationAllowedIPId}: SystemManagerOrganizationAllowedIPDeleted,
  ): OrganizationAllowedIPEntities => {
    const newState = {...state};
    delete newState[organizationAllowedIPId];
    return newState;
  },
);
export const reducer = ReduceFromMap(reducerMap, {});

//#endregion

const init = once(() => {
  subscribe('flow.OrganizationAllowedLoginIPCreated', reduxSubscriptionOrganizationAllowedIPUpdated);
  subscribe('flow.OrganizationAllowedLoginIPUpdated', reduxSubscriptionOrganizationAllowedIPUpdated);
  subscribe('flow.OrganizationAllowedLoginIPDeleted', reduxSubscriptionOrganizationAllowedIPDeleted);
});

const ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const ipv6SyntaxRegex = /^(([0-9a-fA-F]{0,4}:){1,7}[0-9a-fA-F]{0,4})$/;

function validateIPSyntax(cidrNotation: string, field: string): RuleResult {
  const ip = cidrNotation.split('/')[0];
  if (
    ip &&
    ((ip.includes('.') && !ip.match(ipv4Regex)) ||
      (ip.includes(':') && !ip.match(ipv6SyntaxRegex)) ||
      (!ip.includes('.') && !ip.includes(':')))
  ) {
    return [
      {
        field,
        message: 'core:ipRestriction.errors.ipNotValid',
      },
    ];
  }
  return [];
}

export function loadOrganizationAllowedLoginIPs(): ActionDispatcher {
  return async (dispatch: Dispatch, getState: () => FlowState) => {
    init();
    if (!getState().ui.pages.organization.loadedOrganizationAllowedLoginIPs) {
      await dispatch({
        [CALL_API]: {
          _debounceSimultaneousCalls: true,
          endpoint: getAllowedLoginIps,
          schema: [organizationAllowedLoginIP],
          types: {
            failureType: LOAD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
            requestType: LOAD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
            successType: LOAD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
          },
        },
      });
    }
  };
}

function getValidationRules(ip: string): RulesList {
  return [
    [notEmpty, ip, 'core:ipRestriction.errors.ipNotEmptyError', 'ip'],
    [validateIPSyntax, ip, 'ip'],
  ];
}

export function addOrganizationAllowedLoginIP(ip: string, description: string): ActionDispatcher<Promise<boolean>> {
  return async (dispatch: Dispatch) => {
    const errors = validateRules(getValidationRules(ip));
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }
    await dispatch({
      [CALL_API]: {
        endpoint: createAllowedLoginIp,
        method: 'post',
        options: {data: {ip, description}},
        types: {
          failureType: ADD_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
          requestType: ADD_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
          successType: ADD_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
        },
      },
    });

    return true;
  };
}

export function updateOrganizationAllowedLoginIPs(
  organizationAllowedLoginIPId: number,
  ip: string,
  description: string,
): ActionDispatcher<Promise<boolean>> {
  return async (dispatch: Dispatch, getState: () => FlowState) => {
    const errors = validateRules(getValidationRules(ip));
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    } else {
      const oldEntity = getState().entities.organizationAllowedLoginIP[organizationAllowedLoginIPId];
      if (oldEntity && (oldEntity.ip !== ip || oldEntity.description !== description)) {
        await dispatch({
          [CALL_API]: {
            endpoint: patchAllowedLoginIp,
            method: 'patch',
            options: {urlParams: {organizationAllowedLoginIPId}, data: {ip, description}},
            types: {
              failureType: UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
              requestType: UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
              successType: UPDATE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
            },
          },
        });
      }
    }
    return true;
  };
}

export function removeOrganizationAllowedLoginIPs(organizationAllowedLoginIPId: number): ActionDispatcher {
  return async (dispatch: Dispatch, getState: () => FlowState) => {
    if (getState().entities.organizationAllowedLoginIP[organizationAllowedLoginIPId]) {
      await dispatch({
        [CALL_API]: {
          endpoint: deleteAllowedLoginIp,
          method: 'del',
          options: {urlParams: {organizationAllowedLoginIPId}},
          types: {
            failureType: REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_FAILURE,
            requestType: REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_REQUEST,
            successType: REMOVE_CUSOTMER_ALLOWED_LOGIN_IP_SUCCESS,
          },
        },
      });
    }
  };
}

export function reduxSubscriptionOrganizationAllowedIPUpdated(): SystemManagerOrganizationAllowedIPClear {
  return {
    type: ORGANIZATION_ALLOWED_IP_CLEAR,
  };
}

export function reduxSubscriptionOrganizationAllowedIPDeleted({
  organizationAllowedIPId,
}: {
  organizationAllowedIPId: number;
}): SystemManagerOrganizationAllowedIPDeleted {
  return {
    organizationAllowedIPId,
    type: ORGANIZATION_ALLOWED_IP_DELETED,
  };
}
