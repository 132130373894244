import {Uuid} from '@octaved/typescript/src/lib';
import {markParentTasksDoneWorkflowControlSelector} from '../../Modules/Selectors/SettingSelectors';
import {StrictWorkflowControl} from '../../Modules/Settings';
import {useWorkflowControlDialog, WorkflowControlTranslationsMap} from './WorkflowControlDialog';

const translationMap: WorkflowControlTranslationsMap = {
  [StrictWorkflowControl.medium]: {
    close: 'general:cancel',
    content: 'taskList:markAsDoneControl.medium.content',
    headline: 'taskList:markAsDoneControl.medium.headline',
    pass: 'taskList:markAsDoneControl.medium.pass',
  },
  [StrictWorkflowControl.strict]: {
    close: 'general:close',
    content: 'taskList:markAsDoneControl.strict.content',
    headline: 'taskList:markAsDoneControl.strict.headline',
  },
};

export function useMarkParentTasksDoneWorkflowControlModal(
  taskId: Uuid,
  patchChecked: (checked: boolean) => void,
): ReturnType<typeof useWorkflowControlDialog> {
  return useWorkflowControlDialog(taskId, markParentTasksDoneWorkflowControlSelector, patchChecked, translationMap);
}
