import {RefObject, useCallback, useState} from 'react';
import useKeyboardEventListener, {EventListener} from './UseKeyboardEventListener';

export function useDropdownHoverIndex(
  max: number,
  scrollContainerRef: RefObject<HTMLDivElement>,
  rowHeight = 37,
  scrollHeight = 250,
  onSelect?: (index: number) => void,
): {
  changeIndex: (change: number) => void;
  hoverIndex: number;
  keyDownListener: (e: EventListener<HTMLElement>) => void;
  setHoverIndex: (hover: number) => void;
} {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const changeIndex = useCallback(
    (change: number) => {
      const possibleNewIndex = hoverIndex + change;

      let newIndex = Math.max(possibleNewIndex, 0);
      if (change === 1) {
        newIndex = Math.min(possibleNewIndex, max);
      }

      setHoverIndex(newIndex);

      if (scrollContainerRef.current && newIndex !== -1) {
        const itemOffsetTop = rowHeight * hoverIndex;
        const itemOffsetBottom = itemOffsetTop + rowHeight;
        const scrollContainer = scrollContainerRef.current.querySelector('.js-dropdownList')!;
        const containerOffsetTop = scrollContainer.scrollTop;
        const containerOffsetBottom = containerOffsetTop + scrollHeight;

        if (containerOffsetBottom < itemOffsetBottom + rowHeight) {
          scrollContainer.scrollTop = itemOffsetBottom - scrollHeight + rowHeight;
        } else if (containerOffsetTop > itemOffsetTop - rowHeight) {
          scrollContainer.scrollTop = itemOffsetTop - rowHeight;
        }
      }
    },
    [hoverIndex, scrollContainerRef, max, rowHeight, scrollHeight],
  );

  const keyDownListener = useKeyboardEventListener({
    ArrowDown: () => changeIndex(1),
    ArrowUp: () => changeIndex(-1),
    Enter: () => onSelect?.(hoverIndex),
  });

  return {
    changeIndex,
    hoverIndex,
    keyDownListener,
    setHoverIndex,
  };
}
