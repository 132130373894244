import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {DropdownPopupItem, DropdownPopupItemType} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import {isSubWorkPackage, isTask, isWorkPackage, PlanningNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {PlanningStatusIconWithText} from '@octaved/planning/src/PlanningStatus/PlanningStatusIcon';
import {useNodePlanningStatus} from '@octaved/planning/src/PlanningStatus/PlanningStatusQueries';
import {StopClickPropagation, TWButton} from '@octaved/ui';
import {Info, MoreHorizontal} from 'lucide-react';
import {ReactElement, useMemo, useRef, useState} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import NodePlanningStatusDetails from './Components/NodePlanningStatusDetails';
import {useIsNonInteractive} from '@octaved/env/src/NonInteractiveContext';

export interface NodePlanningStatusProps {
  node: PlanningNode;
}

export default function NodePlanningStatus({
  frame: Frame,
  node,
}: NodePlanningStatusProps & FramedSectionOrTileProps): ReactElement | null {
  const status = useNodePlanningStatus(node.id);
  const [showDetails, setShowDetails] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const items = useMemo(
    () =>
      [
        {
          icon: <Info />,
          iconColor: 'darkBlue',
          onClick: () => setShowDetails(true),
          token: 'nodeDetails:field.planningStatus.details.show',
          type: DropdownPopupItemType.item,
        },
      ] as DropdownPopupItem[],
    [],
  );
  const interactive = !useIsNonInteractive();

  if (!isWorkPackage(node) && !isSubWorkPackage(node) && !isTask(node)) {
    return null;
  }

  return (
    <StopClickPropagation>
      <Frame
        labelToken={'nodeDetails:field.planningStatus.label'}
        labelAddition={
          status.overall !== 'none' && interactive ? (
            <div ref={ref}>
              <DropdownPopup items={items} closeOnSelect position={'bottom left'}>
                <TWButton variant={'ghost'} size={'md'} iconOnly>
                  <MoreHorizontal className={'size-4'} />
                </TWButton>
              </DropdownPopup>
            </div>
          ) : undefined
        }
      >
        <PlanningStatusIconWithText status={status.overall} />

        {status.overall !== 'none' && showDetails && (
          <NodePlanningStatusDetails
            node={node}
            mountContext={ref}
            onClose={() => setShowDetails(false)}
            status={status}
          />
        )}
      </Frame>
    </StopClickPropagation>
  );
}
