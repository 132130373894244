import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {useLoadNode, useNode} from '../../Modules/Hooks/Nodes';
import {rootFoldersProjectFolderSelector} from '../../Modules/Selectors/RootFolderSelectors';
import {projectsSelectedProjectFolderSelector} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';
import NewProjectForm from './NewProjectForm';

interface Props {
  close?: () => void;
  initialCustomerId?: Uuid; //Can be omitted if the relative node has a customer.
  initialCustomerLocationId?: Uuid; //Can be omitted if the relative node has a customer.
  relativeNodeId?: Uuid; //The id of the relative node to which this new project should be created. Can be any node type. Used to determine the initial project folder and customer.
  isTemplate?: boolean;
}

export default function NewProject({
  close,
  initialCustomerId,
  initialCustomerLocationId,
  relativeNodeId: fromProps,
  isTemplate = false,
}: Props): ReactElement {
  const {newProjectRelativeNodeId: fromUrl} = useParams<{newProjectRelativeNodeId: Uuid}>();

  //TODO: Remove this with the new context menus - the UI doesn't always have a selector for this value:
  const fromUiSelector = useSelector(projectsSelectedProjectFolderSelector);

  const rootFolderId = useSelector(rootFoldersProjectFolderSelector);

  const relativeNodeId = fromProps || fromUrl || fromUiSelector || rootFolderId;
  useLoadNode(relativeNodeId);
  const relativeNode = useNode(relativeNodeId); //must be loaded before the form is initialized so that formik can use this in its initial values

  const [dialogTitleToken, setDialogTitleToken] = useState(
    isTemplate ? 'dialogs:createProject.titleTemplate' : 'dialogs:createProject.title',
  );
  return (
    <DialogFrame size={'small'} noOwnForm onClose={close}>
      <DialogTitle onClose={close}>
        <DialogAndDrawerHeader header={dialogTitleToken} />
      </DialogTitle>
      <DialogContent>
        {relativeNode && (
          <NewProjectForm
            changeDialogTitleToken={setDialogTitleToken}
            initialCustomerId={initialCustomerId}
            initialCustomerLocationId={initialCustomerLocationId}
            relativeNode={relativeNode}
            isTemplate={isTemplate}
          />
        )}
      </DialogContent>
    </DialogFrame>
  );
}
