import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {DateStr} from '@octaved/typescript';
import {DatePicker, TWButton, TWLabel} from '@octaved/ui';
import {fromIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Radio} from 'semantic-ui-react';
import {createMoveMultiplePlanningChangeSet} from '../../Calculations/MoveMultiple';
import {NodeWithPlannableThings} from '../../Components/PlanningRealization/LoadPlanningDependencies';
import {patchPlanning} from '../../Modules/Planning';
import {getMilestonesForNodeSelector} from '../../Selectors/MilestoneSelectors';
import {getPlanningDatesForNodeSelector} from '../../Selectors/PlanningDateSelectors';
import {canPlanNodeSelector, getPlanningDependencySelector} from '../../Selectors/PlanningDependencySelectors';
import {MoveHorizontal} from 'lucide-react';

interface Props {
  close: () => void;
  descendants: readonly NodeWithPlannableThings[];
  node: Project | Group;
}

export default function MovePlanningBody({close, descendants, node}: Props): ReactElement | null {
  const {t} = useTranslation();
  const dateFormat = useSelector(dateFormatSelector);
  const dispatch = useDispatch();
  const getNode = useSelector(getNodeSelector);
  const getPlanningDatesForNode = useSelector(getPlanningDatesForNodeSelector);
  const getPlanningDependency = useSelector(getPlanningDependencySelector);
  const getMilestonesForNode = useSelector(getMilestonesForNodeSelector);
  const getWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const canPlanNode = useSelector(canPlanNodeSelector);
  const canMoveSubtree = useMemo(() => descendants.every(({id}) => canPlanNode(id)), [descendants, canPlanNode]);

  const [alignToStart, setAlignToStart] = useState(true);
  const [targetDate, setTargetDate] = useState<DateStr | null>();

  const {sourceMinMax, updates, shiftIsNeeded, summary} = createMoveMultiplePlanningChangeSet(
    {
      getMilestonesForNode,
      getNode,
      getPlanningDatesForNode,
      getPlanningDependency,
      getWorkMinutesAtDate,
    },
    descendants,
    alignToStart,
    targetDate,
  );

  const minStart = sourceMinMax?.start;
  useEffect(() => setTargetDate(minStart ?? null), [minStart]);

  const [save, isSaving] = useAsyncExecution(async () => {
    await dispatch(patchPlanning(updates));
    close();
  });

  const isDayBlocked = useCallback(
    (date: DateStr): boolean => getWorkMinutesAtDate(date).project <= 0,
    [getWorkMinutesAtDate],
  );

  if (typeof targetDate === 'undefined') {
    return null;
  }

  if (!targetDate || !sourceMinMax) {
    return <div className={'mb-5'}>{t('dialogs:movePlanning.noPlanningData')}</div>;
  }

  if (!canMoveSubtree) {
    return <div className={'mb-5'}>{t('dialogs:movePlanning.notAllowedToMoveSubtree')}</div>;
  }

  return (
    <div className={'flex flex-col gap-y-6'}>
      <div className={'text-sm'}>
        {t(
          {
            [EnumFlowNodeType.VALUE_PROJECT]: 'dialogs:movePlanning.intro.project',
            [EnumFlowNodeType.VALUE_GROUP]: 'dialogs:movePlanning.intro.group',
          }[node.nodeType],
        )}
      </div>
      <div className={'flex flex-col gap-y-1'}>
        <TWLabel>{t('dialogs:movePlanning.minMax.label')}</TWLabel>
        <div>
          {t('dialogs:duplicatePid.copyPlanning.minMaxPlanningDate.value', {
            endDate: fromIsoFormat(sourceMinMax.end).format(dateFormat),
            startDate: fromIsoFormat(sourceMinMax.start).format(dateFormat),
          })}
        </div>
      </div>
      <div className={'flex flex-col gap-y-1'}>
        <TWLabel>{t('dialogs:movePlanning.dateAlignment.label')}</TWLabel>
        <div className={'flex items-center gap-x-4'}>
          <Radio
            label={t('dialogs:movePlanning.dateAlignment.start')}
            checked={alignToStart}
            onChange={() => setAlignToStart(true)}
          />
          <Radio
            label={t('dialogs:movePlanning.dateAlignment.end')}
            checked={!alignToStart}
            onChange={() => setAlignToStart(false)}
          />
        </div>
      </div>
      <div className={'flex flex-col gap-y-1'}>
        <TWLabel>
          {alignToStart
            ? t('dialogs:movePlanning.targetDate.label.start')
            : t('dialogs:movePlanning.targetDate.label.end')}
        </TWLabel>
        <div>
          <DatePicker
            date={targetDate}
            footerOverride={{canClear: false}}
            isDayBlocked={isDayBlocked}
            setDate={setTargetDate}
            strictBlockedDates
            triggerOverride={{labelOverride: <>{fromIsoFormat(targetDate).format('DD.MM.YYYY')}</>}}
          />
        </div>
      </div>
      <div>
        <TWLabel className={'mb-1.5 block'}>{t('dialogs:movePlanning.summary.label')}</TWLabel>
        {t('dialogs:movePlanning.summary.dateShifts', {count: summary.dateShifts})} |{' '}
        {t('dialogs:movePlanning.summary.offsetShifts', {count: summary.offsetShifts})} |{' '}
        {t('dialogs:movePlanning.summary.milestoneShifts', {count: summary.milestoneShifts})} |{' '}
        {t('dialogs:movePlanning.summary.dueDateShifts', {count: summary.dueDateShifts})}
        <div className={'mt-2 text-sm'}>{t('dialogs:movePlanning.summary.successors')}</div>
      </div>

      <div className={'mb-4 flex justify-center'}>
        <TWButton
          size={'lg'}
          disabled={!shiftIsNeeded || !updates.length || isSaving}
          onClick={save}
          isLoading={isSaving}
          colorScheme={'primary'}
          variant={'solid'}
          withIcon
        >
          <MoveHorizontal size={16} />
          {t('dialogs:movePlanning.button.move')}
        </TWButton>
      </div>
    </div>
  );
}
