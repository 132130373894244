import cloneDeep from 'lodash/cloneDeep';
import {ChevronDown, Folder} from 'lucide-react';
import * as React from 'react';
import {ComponentType, ReactNode, SetStateAction, useEffect, useMemo, useState} from 'react';
import {Icons, StyleParameters} from './Styles';
import {AllowedTreeIdTypes, TreeNodeData} from './Tree';

export interface ContentComponentProps<idType extends AllowedTreeIdTypes> {
  treeNode: TreeNodeData<idType>;
  icon: ReactNode;
  cssPrefix: string;
}

export interface MenuComponentProps<idType extends AllowedTreeIdTypes> {
  treeNode: TreeNodeData<idType>;
}

export interface TreeConfig<idType extends AllowedTreeIdTypes> {
  expandable?: boolean;
  icons?: Icons;
  nodes?: Array<TreeNodeData<idType>>;
  openTopLevel?: boolean;
  padded?: boolean;
  selectedNode?: idType | null;
  openNodes?: idType[];

  selectNode?(nodeId: idType): void;

  style?: StyleParameters;

  contentComponent?: ComponentType<ContentComponentProps<idType>>;
  menuComponent?: ComponentType<MenuComponentProps<idType>>;

  isSelectableNode?(node: TreeNodeData<idType>): boolean;

  nodeLimitBeforeExpansion?: number;
}

interface TreeContext {
  expandable: boolean;
  icons: Icons;
  nodes: Array<TreeNodeData<AllowedTreeIdTypes>>;
  openTopLevel: boolean;
  padded: boolean;
  selectedNode: AllowedTreeIdTypes | null;

  selectNode?(nodeId: AllowedTreeIdTypes): void;

  style: StyleParameters;
  openNodes: AllowedTreeIdTypes[];

  contentComponent?: ComponentType<ContentComponentProps<AllowedTreeIdTypes>>;
  menuComponent?: ComponentType<MenuComponentProps<AllowedTreeIdTypes>>;

  isSelectableNode?(node: TreeNodeData<AllowedTreeIdTypes>): boolean;

  nodeLimitBeforeExpansion: number;
  setOpenNodes: React.Dispatch<SetStateAction<AllowedTreeIdTypes[]>>;
}

const defaultTreeContext = {
  expandable: true,
  icons: {
    angle: <ChevronDown className={'size-4'} />,
    node: <Folder className={'size-4'} />,
  },
  nodeLimitBeforeExpansion: Number.MAX_SAFE_INTEGER,
  nodes: [],
  openNodes: [],
  openTopLevel: true,
  padded: false,
  selectedNode: null,
  selectNode: () => undefined,
  setOpenNodes() {
    //no implementation
  },
  style: {
    selectedBackgroundColor: '#E7F1FE',
  },
};

export default React.createContext<TreeContext>({
  ...defaultTreeContext,
});

export function useCreateTreeContext<idType extends AllowedTreeIdTypes>(config: TreeConfig<idType> = {}): TreeContext {
  const [openNodes, setOpenNodes] = useState<AllowedTreeIdTypes[]>([]);

  useEffect(() => {
    const openNodes = config.openNodes;
    if (openNodes) {
      setOpenNodes((nodes) => {
        return [...new Set([...nodes, ...openNodes])];
      });
    }
  }, [config.openNodes]);

  return useMemo(() => {
    const treeConfig: TreeConfig<AllowedTreeIdTypes> = config as unknown as TreeConfig<AllowedTreeIdTypes>;
    const source = cloneDeep(defaultTreeContext);
    return {
      ...source,
      ...treeConfig,
      openNodes,
      setOpenNodes,
    };
  }, [config, openNodes]);
}
