import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {workdays} from '../Calculations/WorkdayCalculations';
import {getPlanningDatesForNodeSelector} from './PlanningDateSelectors';

export const getTotalPlanningDurationSelector = createSelector(
  getPlanningDatesForNodeSelector,
  getOrgWorkMinutesAtDateSelector,
  (getPlanningDatesForNode, getOrgWorkMinutesAtDate) =>
    memoize((nodeId: MaybeUuid): number => {
      const planningDates = getPlanningDatesForNode(nodeId);
      return planningDates.reduce((acc, {plannedStart, plannedEnd}) => {
        const workDays = workdays(
          fromIsoDateTimeFormat(plannedStart),
          fromIsoDateTimeFormat(plannedEnd),
          getOrgWorkMinutesAtDate,
        );
        return acc + workDays;
      }, 0);
    }),
);
