import {DeepPartial} from '@octaved/typescript/src/lib';
import {AllOrSpecificUnits} from '@octaved/users/src/Components/AllOrSpecificUnitsSelector';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';

export interface CustomWorkingTimesUiState {
  search: string;
  selectedUnits: AllOrSpecificUnits;
}

export const initialCustomWorkingTimesUiState: CustomWorkingTimesUiState = {
  search: '',
  selectedUnits: 'users',
};

export function patchCustomWorkingTimesUiState(
  customWorkingTimes: DeepPartial<CustomWorkingTimesUiState>,
): ThunkAct<void> {
  return setUiState({pages: {customWorkingTimes}});
}
