import {NodesRemovedEvent} from '@octaved/flow/src/Modules/Events';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {setUiState} from '@octaved/flow/src/Modules/Ui';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {isEqual} from 'lodash';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useStore} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PlanningState} from '../PlanningState';
import {planningSimulationSnapshotsSelector} from '../Selectors/SimulationSnapshotSelectors';
import {
  getBaselineComparisionSettingForNodeSelector,
  materialResourcesExtendedNodesSelector,
  projectPlanningExtendedNodesSelector,
  simulationModeActiveSelector,
  taskPlanningSelector,
  teamPlanningCollapsedWorkPackagesSelector,
} from '../Selectors/UiSelectors';
import {FLOW_PLANNING_EXTENDED_NODES} from './ActionTypes';
import {createSimulationSnapshot} from './SimulationSnapshot';

export interface ProjectFilter {
  search: string;
  advancedFilterIsOpen: boolean;
}

export interface MaterialResourceFilter {
  search: string;
}

export enum WorkloadDisplayment {
  workloadFromTask,
  workloadFromWorkpackages,
  workloadCombined,
}

export enum BarTextDisplayment {
  always,
  onlyOnHiddenTable,
}

export enum DragMode {
  vertical,
  horizontal,
}

export interface BaselineComparisonNodeSetting {
  selectedBaselines: Uuid[];
  extendedNodes: Record<Uuid, boolean> | null;
  visibleColumns: string[];
}

export interface BaselineComparison {
  nodeSetting: Record<Uuid, BaselineComparisonNodeSetting | undefined>;
  columnWidth: number;
  search: string;
  showTasks: boolean;
  barTextDisplayment: BarTextDisplayment;
}

export interface SimulationExcecutionLogEntry {
  action: 'planning' | 'responsibilities' | 'timeMoney';
  result: 'success' | 'error' | 'pending';
  nodeId: Uuid;
  id: Uuid;
}

export type PlanningContextMenu =
  | {
      x: number;
      y: number;
      show: true;
      nodeId: string;
    }
  | {
      x?: undefined;
      y?: undefined;
      nodeId?: undefined;
      show: false;
    };

export interface PlanningUiState {
  contextMenu: PlanningContextMenu;
  collapsedSubMenu: boolean;
  extendedNodes: Uuid[];
  groupProjectPlanningByCustomer: boolean;

  myPlanningWorkloadDisplayment: WorkloadDisplayment;
  projectFilter: ProjectFilter;
  materialResourceFilter: MaterialResourceFilter;
  projectPlanningExtendedNodes: Record<Uuid, boolean> | null;
  projectPlanningBarTextDisplayment: BarTextDisplayment;
  materialResourcesExtendedNodes: Record<Uuid, boolean> | null;
  projectPlanningSelectedProjects: readonly Uuid[] | null;
  projectPlanningShowMaterialResources: boolean;
  teamFilter: ProjectFilter;
  teamPlanningCollapsedWorkPackages: Uuid[];
  teamPlanningShowCompletedTasks: boolean;
  teamPlanningShowTasks: boolean;
  teamPlanningShowTasksWithoutOwnPlanning: boolean;
  teamPlanningShowWorkload: boolean;
  teamPlanningDragMode: DragMode;
  teamPlanningWorkloadDisplayment: WorkloadDisplayment;
  visibleGanttColumns: string[];
  visibleMaterialResourceColumns: string[];
  baselineComparison: BaselineComparison;
  simulationModeActive: boolean;
  simulationExcecutionLog: SimulationExcecutionLogEntry[];
  selectedSimulationSnapshotId: Uuid | null;
}

export const initialPlanningState: PlanningUiState = {
  baselineComparison: {
    barTextDisplayment: BarTextDisplayment.always,
    columnWidth: 20,
    nodeSetting: {},
    search: '',
    showTasks: false,
  },
  collapsedSubMenu: false,
  contextMenu: {
    show: false,
  },
  extendedNodes: [],
  groupProjectPlanningByCustomer: true,
  materialResourceFilter: {
    search: '',
  },
  materialResourcesExtendedNodes: null,
  myPlanningWorkloadDisplayment: WorkloadDisplayment.workloadCombined,
  projectFilter: {
    advancedFilterIsOpen: false,
    search: '',
  },
  projectPlanningBarTextDisplayment: BarTextDisplayment.always,
  projectPlanningExtendedNodes: null,
  projectPlanningSelectedProjects: null,
  projectPlanningShowMaterialResources: false,
  selectedSimulationSnapshotId: null,
  simulationExcecutionLog: [],
  simulationModeActive: false,
  teamFilter: {
    advancedFilterIsOpen: false,
    search: '',
  },
  teamPlanningCollapsedWorkPackages: [],
  teamPlanningDragMode: DragMode.horizontal,
  teamPlanningShowCompletedTasks: false,
  teamPlanningShowTasks: true,
  teamPlanningShowTasksWithoutOwnPlanning: false,
  teamPlanningShowWorkload: true,
  teamPlanningWorkloadDisplayment: WorkloadDisplayment.workloadCombined,
  visibleGanttColumns: ['name', 'plannedStart', 'plannedEnd', 'menu'],
  visibleMaterialResourceColumns: ['name'],
};

interface ExtendedNodesAction {
  type: typeof FLOW_PLANNING_EXTENDED_NODES;
  projectPlanningExtendedNodes?: Record<Uuid, boolean>;
  materialResourcesExtendedNodes?: Record<Uuid, boolean>;
}

const reducers = createReducerCollection<PlanningUiState>(initialPlanningState);
reducers.add<ExtendedNodesAction>(
  FLOW_PLANNING_EXTENDED_NODES,
  (state: PlanningUiState, {projectPlanningExtendedNodes, materialResourcesExtendedNodes}): PlanningUiState => {
    let newState = {...state};
    if (projectPlanningExtendedNodes) {
      newState = {...newState, projectPlanningExtendedNodes};
    }
    if (materialResourcesExtendedNodes) {
      newState = {...newState, materialResourcesExtendedNodes};
    }
    return newState;
  },
);

reducers.add<NodesRemovedEvent>('flow.NodesRemovedEvent', (state, {nodeIds}) => {
  let changed = false;
  const newState = {...state};
  const removedSet = new Set(nodeIds);
  if (newState.projectPlanningSelectedProjects) {
    newState.projectPlanningSelectedProjects = newState.projectPlanningSelectedProjects.filter((id) => {
      if (removedSet.has(id)) {
        changed = true;
        return false;
      }
      return true;
    });
  }
  return changed ? newState : state;
});

export const uiPlanningReducer = reducers.reducer;

type IdPlanningKey = 'projectPlanningSelectedProjects';

export function removePlanningId(id: Uuid, key: IdPlanningKey): ActionDispatcher<void, PlanningState> {
  return (dispatch, getState) => {
    const currentUnits = taskPlanningSelector(getState())[key];
    if (currentUnits && currentUnits.includes(id)) {
      dispatch(
        setUiState({
          pages: {
            planning: {
              [key]: currentUnits.filter((unitId) => unitId !== id),
            },
          },
        }),
      );
    }
  };
}

export function expandTeamPlanningWorkPackage(workPackageId: Uuid): ActionDispatcher<void, PlanningState> {
  return (dispatch, getState) => {
    const collapsedWorkPackages = teamPlanningCollapsedWorkPackagesSelector(getState());
    if (collapsedWorkPackages.includes(workPackageId)) {
      dispatch(
        setUiState({
          pages: {
            planning: {teamPlanningCollapsedWorkPackages: collapsedWorkPackages.filter((id) => workPackageId !== id)},
          },
        }),
      );
    }
  };
}

export function collapseTeamPlanningWorkPackage(workPackageId: Uuid): ActionDispatcher<void, PlanningState> {
  return (dispatch, getState) => {
    const collapsedWorkPackages = teamPlanningCollapsedWorkPackagesSelector(getState());
    if (!collapsedWorkPackages.includes(workPackageId)) {
      dispatch(
        setUiState({
          pages: {planning: {teamPlanningCollapsedWorkPackages: [...collapsedWorkPackages, workPackageId]}},
        }),
      );
    }
  };
}

export function setExtendedProjectPlanningNodes(
  projectPlanningExtendedNodes: Record<Uuid, boolean>,
): ActionDispatcher<void, PlanningState> {
  return (dispatch, getState) => {
    const prevState = projectPlanningExtendedNodesSelector(getState());
    if (!prevState || !isEqual(prevState, projectPlanningExtendedNodes)) {
      dispatch({projectPlanningExtendedNodes, type: FLOW_PLANNING_EXTENDED_NODES});
    }
  };
}

export function setExtendedMaterialResourcesNodes(
  materialResourcesExtendedNodes: Record<Uuid, boolean>,
): ActionDispatcher<void, PlanningState> {
  return (dispatch, getState) => {
    const prevState = materialResourcesExtendedNodesSelector(getState());
    if (!prevState || !isEqual(prevState, materialResourcesExtendedNodes)) {
      dispatch({materialResourcesExtendedNodes, type: FLOW_PLANNING_EXTENDED_NODES});
    }
  };
}

export function setTeamPlanningExtendedNodes(extendedNodes: Array<Uuid | null>): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {teamPlanning: {extendedNodes}},
  });
}

export function setPlanningState(state: Partial<PlanningUiState>): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {
      planning: state,
    },
  });
}

export function setBaselineComparisonState(state: Partial<BaselineComparison>): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {
      planning: {baselineComparison: state},
    },
  });
}

export function useToggleSimulationMode(): () => void {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const store = useStore<FlowState>();
  const navigate = useNavigate();
  return useCallback(() => {
    const state = store.getState();
    const simulationModeActive = simulationModeActiveSelector(state);
    const snapshots = planningSimulationSnapshotsSelector(state);

    if (!simulationModeActive && snapshots.length === 0) {
      const name = t('pages:planning.simulationMode.defaultSimulationName');
      dispatch(createSimulationSnapshot({name}, null));
    }
    if (!simulationModeActive) {
      navigate('.');
    }
    dispatch(setPlanningState({simulationModeActive: !simulationModeActive}));
  }, [dispatch, store, t, navigate]);
}

export function setVisibleGanttColumns(visibleGanttColumns: string[]): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {planning: {visibleGanttColumns}},
  });
}

export function setVisibleMaterialResourceColumns(
  visibleMaterialResourceColumns: string[],
): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {planning: {visibleMaterialResourceColumns}},
  });
}

export function setProjectFilter(projectFilter: Partial<ProjectFilter>): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {planning: {projectFilter}},
  });
}

export function setMaterialResourceFilter(
  materialResourceFilter: Partial<MaterialResourceFilter>,
): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {planning: {materialResourceFilter}},
  });
}

export function setTeamFilter(teamFilter: Partial<ProjectFilter>): ActionDispatcher<void, FlowState> {
  return setUiState({
    pages: {planning: {teamFilter}},
  });
}

export function setBaselineSettingsForNode(
  nodeId: Uuid,
  settings: Partial<BaselineComparisonNodeSetting>,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    setUiState({
      pages: {
        planning: {
          baselineComparison: {
            nodeSetting: {
              [nodeId]: mergeStates(getBaselineComparisionSettingForNodeSelector(getState())(nodeId), settings),
            },
          },
        },
      },
    })(dispatch, getState);
  };
}

export function setPlanningContextMenu(contextMenu: PlanningContextMenu): ActionDispatcher<void, FlowState> {
  return setUiState({pages: {planning: {contextMenu}}});
}
