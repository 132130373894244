import {createStoreSubscription} from '../../../../Canvas/StoreSubscription';
import {getPlanningBaselinesDiffSelector} from '../../../../Selectors/PlanningBaselineHistorySelectors';
import {GanttComparisonPlanningTextCell} from './GanttComparisonTextCell';

export class GanttComparisonDiffCell extends GanttComparisonPlanningTextCell {
  #diff: number | null = null;
  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (state) => {
          return getPlanningBaselinesDiffSelector(state)(this.baselineId, this.node.id);
        },
        (diff) => {
          this.#diff = diff;
          this.render();
        },
      ),
    );
  }

  protected getText(): string {
    if (this.#diff !== null) {
      return this.#diff.toString();
    }
    return '';
  }
}
