import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {OrgUserEntity} from './EntityInterfaces/UserEntity';

export const humanUserTypes = [UserType.guestCustomer, UserType.guestOther, UserType.light, UserType.regular] as const;
export const internalUserTypes = [UserType.api, UserType.light, UserType.regular] as const;
export const guestUserTypes = [UserType.guestCustomer, UserType.guestOther] as const;

export type HumanUserType = (typeof humanUserTypes)[number];
export type InternalUserType = (typeof internalUserTypes)[number];
export type GuestUserType = (typeof guestUserTypes)[number];

export function isApiUser(user: OrgUserEntity | null | undefined): user is OrgUserEntity & {type: UserType.api} {
  return !!user && user.type === UserType.api;
}

export function isRegularUser(
  user: OrgUserEntity | null | undefined,
): user is OrgUserEntity & {type: UserType.regular} {
  return !!user && user.type === UserType.regular;
}

export function isHumanUserType(type: UserType): boolean {
  return humanUserTypes.includes(type as HumanUserType);
}

export function isInternalUserType(type: UserType): boolean {
  return internalUserTypes.includes(type as InternalUserType);
}

export function isGuestUserType(type: UserType): boolean {
  return guestUserTypes.includes(type as GuestUserType);
}

export function isGuestUser(user: OrgUserEntity): user is OrgUserEntity & {type: GuestUserType} {
  return isGuestUserType(user.type);
}
