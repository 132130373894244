import {cn} from '@octaved/ui';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {ChevronDown, ChevronUp, LucideIcon} from 'lucide-react';
import {ReactElement, ReactNode} from 'react';
import {Trans} from 'react-i18next';
import {NavLink} from 'react-router-dom';

interface BaseItem {
  isDisable?: boolean;
  isGroupChild?: boolean;
  isHeader?: boolean;
  label: string;
  onClick?: () => void;
  testId?: string;
  width?: string;
}

interface LinkItem extends BaseItem {
  path: string;
}

interface CustomItem {
  children: ReactNode;
  className: string;
  width?: string;
}

interface GroupItem extends BaseItem {
  children: ReactNode;
  icon: LucideIcon;
  isExpanded: boolean;
  isGroup: true;
}

function isGroup(item: BaseItem | CustomItem): item is GroupItem {
  return 'isGroup' in item;
}

function isLink(item: BaseItem | CustomItem): item is LinkItem {
  return 'path' in item;
}

function isCustom(item: BaseItem | CustomItem): item is CustomItem {
  return !('label' in item);
}

export type MenuItemOption = GroupItem | LinkItem | BaseItem | CustomItem;

export function MenuItem(item: MenuItemOption): ReactElement {
  const baseClass =
    'relative flex cursor-pointer px-5 py-1 text-base text-slate-600 hover:bg-slate-100 hover:text-slate-800 ' +
      item.width || 'w-[220px]';
  if (isGroup(item)) {
    const ToggleIcon = item.isExpanded ? ChevronUp : ChevronDown;
    const Icon = item.icon;

    return (
      <div className={'flex w-full flex-col'}>
        <div
          className={cn(baseClass, 'group items-center justify-between py-2.5 text-slate-500 transition-all')}
          data-test-id={item.testId}
          data-test-is-open={typeof item.testId === 'string' ? (item.isExpanded ? '1' : '0') : undefined}
          onClick={item.onClick}
        >
          <div className={'flex items-center gap-x-2'}>
            <Icon strokeWidth={1.5} className={'h-4 w-4 text-sky-800'} />
            <div className={'text-slate-600/85 transition-colors group-hover:text-sky-800'}>
              <Trans i18nKey={item.label} />
            </div>
          </div>
          <ToggleIcon className={'h-4 w-4 text-slate-500'} strokeWidth={1} />
        </div>
        <SlideToggleElement visible={item.isExpanded}>
          <div className={'pb-2'}>{item.children}</div>
        </SlideToggleElement>
      </div>
    );
  }

  if (isLink(item)) {
    return (
      <NavLink
        to={item.path}
        className={cn(
          baseClass,
          'group/menu pl-5 aria-[current=page]:font-medium aria-[current=page]:text-slate-700',
          item.isGroupChild && 'pl-11',
        )}
        data-test-id={item.testId}
      >
        <div
          className={cn(
            'absolute bottom-1.5 left-2 top-1.5 hidden w-[3px] bg-purple-700 group-aria-[current=page]/menu:block',
            item.isGroupChild && 'left-8',
          )}
          data-test-id={item.testId}
        />
        <Trans i18nKey={item.label} />
      </NavLink>
    );
  }

  if (isCustom(item)) {
    return <div className={cn(baseClass, item.className)}>{item.children}</div>;
  }

  return (
    <div onClick={item.onClick} className={cn(baseClass, item.isGroupChild && 'pl-11')} data-test-id={item.testId}>
      <Trans i18nKey={item.label} />
    </div>
  );
}
