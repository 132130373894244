import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {Bar, BarPluginList, BarProps} from '../../../Canvas/Bars/Bar';
import {MaturityDateBar} from '../../../Canvas/Bars/MaturityDateBar';
import {MinMaxDateBar} from '../../../Canvas/Bars/MinMaxDateBar';
import {PlanningBarProps, PlanningDateBar} from '../../../Canvas/Bars/PlanningDateBar';
import {ExpandArrowPlugin} from '../../../Canvas/Bars/Plugins/ExpandArrowPlugin';
import {GroupedBarRenderPlugin} from '../../../Canvas/Bars/Plugins/GroupedBarRenderPlugin';
import {DoneIcon} from '../../../Canvas/Bars/Plugins/Icons/DoneIcon';
import {FlameIcon} from '../../../Canvas/Bars/Plugins/Icons/FlameIcon';
import {IconListBarPlugin} from '../../../Canvas/Bars/Plugins/Icons/IconListBarPlugin';
import {LogicalDependencyIcon} from '../../../Canvas/Bars/Plugins/Icons/LogicalDependencyIcon';
import {SprintIcon} from '../../../Canvas/Bars/Plugins/Icons/SprintIcon';
import {MaturityPopupPlugin} from '../../../Canvas/Bars/Plugins/MaturityClickPlugin';
import {OpacityPlugin} from '../../../Canvas/Bars/Plugins/OpacityPlugin';
import {PlaceholderBarRenderPlugin} from '../../../Canvas/Bars/Plugins/PlaceholderBarRenderPlugin';
import {PlanningDateClickPlugin} from '../../../Canvas/Bars/Plugins/PlanningDateClickPlugin';
import {ResizeBarPlugin} from '../../../Canvas/Bars/Plugins/ResizeBarPlugin';
import {WorkPackageBarRenderPlugin} from '../../../Canvas/Bars/Plugins/WorkPackageBarRenderPlugin';
import {BarFactory, CreateRowComponentProps} from '../../../Canvas/Gantt/Bars/BarFactory';
import {BarRow, BarRowProps} from '../../../Canvas/Gantt/Bars/BarRow';
import {DependencyCreatePlugin} from '../../../Canvas/Gantt/Bars/Dependencies/DependencyCreatePlugin';
import {GhostBar} from '../../../Canvas/Gantt/Bars/GhostBar';
import {MaterialResourceBarRow} from '../../../Canvas/Gantt/Bars/MaterialResourceBarRow';
import {MinMaxBarRow} from '../../../Canvas/Gantt/Bars/MinMaxBarRow';
import {PlanningDatesBarRow} from '../../../Canvas/Gantt/Bars/PlanningDatesBarRow';
import {GhostDraggerPlugin} from '../../../Canvas/Gantt/Bars/Plugins/GhostDraggerPlugin';
import {NameBarPlugin} from '../../../Canvas/Gantt/Bars/Plugins/NameBarPlugin';
import {GroupedMilestoneResult} from '../../../Selectors/MilestoneSelectors';
import {canDndDependencySelector} from '../../../Selectors/PlanningDependencySelectors';
import {ColoredBarRenderPlugin} from './BarPlugins/ColoredBarRenderPlugin';
import {ColoredMaturityPlugin} from './BarPlugins/ColoredMaturityPlugin';
import {GanttComparisonRowBar, GanttComparisonRowBarProps} from './GanttComparisonBarRow';
import {GanttComparisonContext} from './GanttComparisonContext';
import {GanttComparisonGroupedRowBar} from './GanttComparisonGroupedRowBar';

export class GanttComparisonBarFactory extends BarFactory<GanttComparisonContext> {
  readonly #reducedRowHeight = this.ctx.rowHeight - 6;
  readonly #reducedYOffset = 6;

  createRowComponent(props: CreateRowComponentProps): BarRow {
    const extendedProps: BarRowProps = {...props, height: this.#reducedRowHeight, yOffset: this.#reducedYOffset};
    const comparisonProps: GanttComparisonRowBarProps = {
      ...props,
      ctx: this.ctx,
      height: this.#reducedRowHeight,
      yOffset: 0,
    };
    const minMaxRowProps: BarRowProps = {...props, height: this.ctx.rowHeight, yOffset: 0};
    const type = extendedProps.node.type;
    const subRows: BarRow[] = [new GanttComparisonRowBar(comparisonProps)];
    const planningDatesBarRowTypes: string[] = [
      EnumFlowNodeType.VALUE_TASK,
      EnumFlowNodeType.VALUE_WORK_PACKAGE,
      EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
    ];
    if (planningDatesBarRowTypes.includes(type)) {
      subRows.push(new PlanningDatesBarRow(extendedProps));
    } else if (type === EnumFlowNodeType.VALUE_GROUP) {
      const rootGroupType = getRootGroupTypeSelector(this.ctx.store.getState())(extendedProps.node.id);
      const isSprint = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
      if (isSprint) {
        subRows.push(new PlanningDatesBarRow(extendedProps));
      } else {
        subRows.push(new MinMaxBarRow(minMaxRowProps));
      }
    } else if (type === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
      subRows.push(new MaterialResourceBarRow(extendedProps));
    } else {
      subRows.push(new MinMaxBarRow(minMaxRowProps));
    }
    return new GanttComparisonGroupedRowBar({...extendedProps, subRows});
  }

  createMaterialResourceBar(_barProps: PlanningBarProps): Bar | null {
    //not supported
    return null;
  }

  createTaskBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar | null {
    const bar = new PlanningDateBar(barProps);

    const canDndDependency = canDndDependencySelector(this.ctx.store.getState())(barProps.treeNode.id);
    bar.init(
      new ColoredBarRenderPlugin({ctx: this.ctx, rootNodeId: this.ctx.rootNodeId}),
      new IconListBarPlugin({
        ctx: this.ctx,
        icons: [
          new DoneIcon({ctx: this.ctx, bar}),
          new FlameIcon({ctx: this.ctx, bar}),
          new LogicalDependencyIcon({ctx: this.ctx, bar}),
        ],
      }),
      new NameBarPlugin({ctx: this.ctx}),
      !this.ctx.isReadonly &&
        new ResizeBarPlugin({
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          planningDateId: barProps.planningDateId,
        }),
      !this.ctx.isReadonly &&
        new PlanningDateClickPlugin({
          nextPlanningDateId,
          prevPlanningDateId,
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          nodeType: EnumFlowNodeType.VALUE_TASK,
          planningDateId: barProps.planningDateId,
        }),
      !this.ctx.isReadonly && barProps.isFirst && canDndDependency && new DependencyCreatePlugin({ctx: this.ctx}),
      new OpacityPlugin({ctx: this.ctx, opacity: 0.4}),
    );
    return bar;
  }

  createWorkPackageBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar {
    const bar = new PlanningDateBar(barProps);

    const state = this.ctx.store.getState();
    const canDndDependency = canDndDependencySelector(state)(barProps.treeNode.id);
    const rootGroupType = getRootGroupTypeSelector(state)(barProps.treeNode.id);
    const isSprint = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
    const plugins: BarPluginList = [new NameBarPlugin({ctx: this.ctx})];
    if (isSprint) {
      plugins.push(new ColoredBarRenderPlugin({ctx: this.ctx, rootNodeId: this.ctx.rootNodeId}));
    } else {
      plugins.push(
        new ColoredBarRenderPlugin({ctx: this.ctx, rootNodeId: this.ctx.rootNodeId}),
        !this.ctx.isReadonly &&
          new ResizeBarPlugin({
            ctx: this.ctx,
            isFirst: barProps.isFirst,
            isLast: barProps.isLast,
            planningDateId: barProps.planningDateId,
          }),
      );
    }
    plugins.push(
      new IconListBarPlugin({
        ctx: this.ctx,
        icons: [new DoneIcon({ctx: this.ctx, bar}), new FlameIcon({ctx: this.ctx, bar})],
      }),
      !this.ctx.isReadonly &&
        new PlanningDateClickPlugin({
          nextPlanningDateId,
          prevPlanningDateId,
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          nodeType: EnumFlowNodeType.VALUE_WORK_PACKAGE,
          planningDateId: barProps.planningDateId,
        }),
      !this.ctx.isReadonly && barProps.isFirst && canDndDependency && new DependencyCreatePlugin({ctx: this.ctx}),
      new OpacityPlugin({ctx: this.ctx}),
    );
    bar.init(...plugins);
    return bar;
  }
  createSubWorkPackageBar(
    barProps: PlanningBarProps,
    prevPlanningDateId: Uuid | null,
    nextPlanningDateId: Uuid | null,
  ): Bar {
    const bar = new PlanningDateBar(barProps);

    const state = this.ctx.store.getState();
    const canDndDependency = canDndDependencySelector(state)(barProps.treeNode.id);
    const plugins: BarPluginList = [new NameBarPlugin({ctx: this.ctx})];

    plugins.push(
      new ColoredBarRenderPlugin({ctx: this.ctx, rootNodeId: this.ctx.rootNodeId}),
      !this.ctx.isReadonly &&
        new ResizeBarPlugin({
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          planningDateId: barProps.planningDateId,
        }),
      new IconListBarPlugin({
        ctx: this.ctx,
        icons: [new FlameIcon({ctx: this.ctx, bar})],
      }),
      !this.ctx.isReadonly &&
        new PlanningDateClickPlugin({
          nextPlanningDateId,
          prevPlanningDateId,
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          nodeType: EnumFlowNodeType.VALUE_WORK_PACKAGE,
          planningDateId: barProps.planningDateId,
        }),
      !this.ctx.isReadonly && barProps.isFirst && canDndDependency && new DependencyCreatePlugin({ctx: this.ctx}),
      new OpacityPlugin({ctx: this.ctx}),
    );
    bar.init(...plugins);
    return bar;
  }

  createSprintBar(barProps: PlanningBarProps, prevPlanningDateId: Uuid | null, nextPlanningDateId: Uuid | null): Bar {
    //is always sprint
    const bar = new PlanningDateBar(barProps);

    let barRenderer;
    if (bar.treeNode.isExpanded) {
      barRenderer = new ColoredBarRenderPlugin({ctx: this.ctx, rootNodeId: this.ctx.rootNodeId});
    } else {
      barRenderer = new GroupedBarRenderPlugin({ctx: this.ctx});
    }
    bar.init(
      barRenderer,
      !this.ctx.isReadonly &&
        new ResizeBarPlugin({
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          planningDateId: barProps.planningDateId,
        }),
      new IconListBarPlugin({
        ctx: this.ctx,
        icons: [new SprintIcon({ctx: this.ctx, bar})],
      }),
      new NameBarPlugin({ctx: this.ctx}),
      new ExpandArrowPlugin({ctx: this.ctx}),
      !this.ctx.isReadonly &&
        new PlanningDateClickPlugin({
          nextPlanningDateId,
          prevPlanningDateId,
          ctx: this.ctx,
          isFirst: barProps.isFirst,
          isLast: barProps.isLast,
          nodeType: EnumFlowNodeType.VALUE_GROUP,
          planningDateId: barProps.planningDateId,
        }),
    );
    return bar;
  }

  #createGroupedBar(barProps: BarProps): Bar {
    const bar = new MinMaxDateBar(barProps);

    bar.init(
      new GroupedBarRenderPlugin({ctx: this.ctx}),
      new NameBarPlugin({ctx: this.ctx}),
      new ExpandArrowPlugin({ctx: this.ctx}),
    );
    return bar;
  }

  createGroupBar(barProps: BarProps): Bar {
    //is never sprint
    return this.#createGroupedBar(barProps);
  }

  createProjectBar(barProps: BarProps): Bar {
    return this.#createGroupedBar(barProps);
  }

  createTaskSectionBar(barProps: BarProps): Bar {
    return this.#createGroupedBar(barProps);
  }

  createGroupedWorkPackageBar(_barProps: BarProps): Bar | null {
    return null;
  }

  createGroupedSubWorkPackageBar(_barProps: BarProps): Bar | null {
    return null;
  }

  createMaturityBar(barProps: BarProps, maturity: GroupedMilestoneResult): Bar {
    barProps.height = this.#reducedRowHeight;
    barProps.yOffset = this.#reducedYOffset;

    const bar = new MaturityDateBar({...barProps, maturity});
    bar.init(
      new ColoredMaturityPlugin({
        ctx: this.ctx,
        milestones: maturity.maturityData.milestones,
        rootNodeId: this.ctx.rootNodeId,
      }),
      new MaturityPopupPlugin({ctx: this.ctx, maturity}),
    );

    return bar;
  }

  createPlaceholder(barProps: BarProps): Bar {
    const bar = new GhostBar(barProps);
    const plugins: BarPluginList = [];
    const nodeType = bar.treeNode.type;
    if (nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
      plugins.push(new WorkPackageBarRenderPlugin({ctx: this.ctx}));
    } else if (nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
      plugins.push(new WorkPackageBarRenderPlugin({ctx: this.ctx}));
    } else if (nodeType === EnumFlowNodeType.VALUE_TASK) {
      bar.init(new PlaceholderBarRenderPlugin({ctx: this.ctx}));
    } else if (nodeType === EnumFlowNodeType.VALUE_GROUP) {
      plugins.push(new GroupedBarRenderPlugin({ctx: this.ctx}));
    } else {
      throw new Error(`Unsupported node type ${nodeType}`);
    }
    plugins.push(
      new OpacityPlugin({ctx: this.ctx, isGhost: true, opacity: 0.5}),
      new GhostDraggerPlugin({ctx: this.ctx}),
    );

    bar.init(...plugins);
    return bar;
  }
}
