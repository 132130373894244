import {Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useProjectPatch} from '@octaved/flow/src/Modules/Projects';
import {useOptionalFields} from '@octaved/hooks/src/OptionalFields';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import EnableFieldButton from '../../../Components/Fields/EnableFieldButton';
import ProjectCustomerSection from '../../../Components/Fields/General/NodeType/Project/Section/ProjectCustomerSection';
import NodeBoardStatsSection from '../../../Components/Fields/General/Section/NodeBoardStatsSection';
import NodeColorSection from '../../../Components/Fields/General/Section/NodeColorSection';
import NodeDescriptionSection from '../../../Components/Fields/General/Section/NodeDescriptionSection';
import NodeGuestAccessSection from '../../../Components/Fields/General/Section/NodeGuestAccessSection';
import NodeLabelSection from '../../../Components/Fields/General/Section/NodeLabelSection';
import NodeNameSection from '../../../Components/Fields/General/Section/NodeNameSection';
import NodeProjectManagerSection from '../../../Components/Fields/General/Section/NodeProjectManagerSection';
import NodeReferenceNumberSection from '../../../Components/Fields/General/Section/NodeReferenceNumberSection';
import {NodeTasksSection} from '../../../Components/Fields/General/Section/NodeTasksSection';
import NodeWPAndSWPStatsSection from '../../../Components/Fields/General/Section/NodeWPAndSWPStatsSection';
import ProjectPlanningSection from '../../../Components/Fields/Planning/NodeType/Project/Section/ProjectPlanningSection';
import InspectorViewFrame from '../../InspectorViewFrame';

export interface ProjectDetailsProps {
  node: Project;
}

export default function General({node}: ProjectDetailsProps): ReactElement {
  const patch = useProjectPatch(node.id);
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const canReadDescription = useIsGranted('FLOW_NODE_READ_DESCRIPTION', node.id);
  const canReadRefNumber = useIsGranted('FLOW_NODE_READ_REFERENCE_NUMBER', node.id);
  const optionals = useOptionalFields(node.id, {
    description: node.description,
    referenceNumber: node.referenceNumber,
  });

  const footer = (
    <div className={'flex justify-center gap-x-2'}>
      {canReadRefNumber && (
        <EnableFieldButton
          field={optionals.referenceNumber}
          label={'nodeDetails:field.referenceNumber.enable'}
          tooltip={'nodeDetails:field.referenceNumber.enableTooltipForProject'}
        />
      )}
      {canReadDescription && (
        <EnableFieldButton
          field={optionals.description}
          label={'nodeDetails:field.description.enable'}
          tooltip={'nodeDetails:field.description.enableTooltipForProject'}
        />
      )}
    </div>
  );

  return (
    <InspectorViewFrame footer={canManageBasic ? footer : undefined}>
      <NodeNameSection name={node.name} setName={(name) => patch({name})} readonly={!canManageBasic} />
      <ProjectCustomerSection node={node} />
      {optionals.referenceNumber.show && canReadRefNumber && (
        <NodeReferenceNumberSection
          nodeId={node.id}
          placeholder={'nodeDetails:field.referenceNumber.placeholderProject'}
          referenceNumber={node.referenceNumber || ''}
          setReferenceNumber={(referenceNumber) => patch({referenceNumber})}
          readonly={!canManageBasic}
        />
      )}
      {optionals.description.show && canReadDescription && (
        <NodeDescriptionSection
          description={node.description}
          setDescription={(description) => patch({description})}
          readonly={!canManageBasic}
        />
      )}
      <NodeProjectManagerSection nodeId={node.id} />
      <ProjectPlanningSection nodeId={node.id} projectDueDate={node.dueDate} />
      <NodeWPAndSWPStatsSection nodeId={node.id} nodeType={node.nodeType} />
      <NodeTasksSection node={node} />
      <NodeBoardStatsSection nodeId={node.id} nodeType={node.nodeType} />
      <NodeColorSection
        color={node.color}
        nodeType={node.nodeType}
        setColor={(color) => patch({color})}
        readonly={!canManageBasic}
      />
      <NodeLabelSection
        nodeId={node.id}
        labels={node.labels}
        nodeType={node.nodeType}
        readonly={!canManageBasic}
        setLabels={(labels) => patch({labels})}
      />
      <NodeGuestAccessSection nodeId={node.id} />
    </InspectorViewFrame>
  );
}
