import {AuditNode} from '../../../../EntityInterfaces/Audit/AuditNode';
import JsonCell from '../Table/Cells/JsonCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import ReferenceListCell from '../Table/Cells/ReferenceListCell';
import {AuditTableColumn} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

export const nodeColumns: AuditTableColumn<AuditNode>[] = [
  ...historyColumns,
  createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
  createColumnDef('parentNode', 'systemSettings:audit.tables.node.parentNodeHeader', ReferenceCell),
  createColumnDef('name', 'systemSettings:audit.tables.nameHeader'),
  createColumnDef('responsibleGroups', 'systemSettings:audit.tables.node.responsibleGroupsHeader', JsonCell),
  createColumnDef('responsibleUsers', 'systemSettings:audit.tables.node.responsibleUsersHeader', ({cell}) => {
    return <ReferenceListCell cell={cell} listItemComponent={ReferenceCell} />;
  }),
  createColumnDef('labels', 'systemSettings:audit.tables.node.labelsHeader', ({cell}) => {
    return <ReferenceListCell cell={cell} listItemComponent={ReferenceCell} />;
  }),
];
