import SingleBillingTypeProgress from '@octaved/flow/src/Components/BillingType/SingleBillingTypeProgress';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {ReactElement} from 'react';
import Section from '../../../../Section';
import {useWpOrSwpTimeBooking} from '../Values/WpOrSwpTimeBooking';

interface WorkPackageTimeBookingSectionProps {
  node: WorkPackage | SubWorkPackage;
}

export default function WpOrSwpTimeBookingSection({node}: WorkPackageTimeBookingSectionProps): ReactElement | null {
  const data = useWpOrSwpTimeBooking(node);
  if (!data) {
    return null;
  }
  const {billingType, customer, sum} = data;

  return (
    <Section labelToken={'nodeDetails:field.timeTracking.label'}>
      <div className={'grid grid-cols-[max-content_max-content_1fr] items-center gap-x-3 gap-y-1'}>
        <SingleBillingTypeProgress
          billingType={billingType}
          customer={customer}
          showBillingType
          showProgressLabel
          sum={sum}
        />
      </div>
    </Section>
  );
}
