import {CustomWorkTimeType} from '@octaved/env/src/dbalEnumTypes';
import {allOrgUserGroupIdSelector} from '@octaved/organization/src/Selectors/OrganizationSelectors';
import {EntityStates, hasLoadedOnce} from '@octaved/store/src/EntityState';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {
  findGenerationAtDate,
  getNearestWeeklyGenerations,
  resolveToNearest,
  resolveToNearestLowestCustomWorkTime,
} from '@octaved/working-time/src/WorkTime/WorkingTimeSettingsResolver';
import dayjs from 'dayjs';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {
  StoreUnitWorkTimes,
  UnitCustomWorkingTime,
  UnitWeeklyWorkingTime,
  UnitWeeklyWorkingTimeGeneration,
} from '../../EntityInterfaces/WorkTime';
import {IsoWeekday} from '../../IsoWeekday';
import {FlowState} from '../State';

export const defaultWorkingTimeGenerationDate = '2000-01-01' as DateStr;

export const workTimeSelector = (state: FlowState): StoreUnitWorkTimes => state.workTime.data;
export const workTimeStateSelector = (state: FlowState): EntityStates => state.workTime.state;

function memoizeJsonResolver(...args: unknown[]): string {
  return JSON.stringify(args);
}

export const workTimeOrganizationLevelUnitSelector = createSelector(
  allOrgUserGroupIdSelector,
  (unitId): SlimUnit => ({unitId, unitType: SimpleUnitType.group}),
);

export const getUnitWorkingTimeSettingsHasLoadedOnceSelector = createSelector(
  workTimeStateSelector,
  (state) =>
    (unitId: Uuid): boolean =>
      hasLoadedOnce(state[unitId] || {}),
);

const getUnitWeeklyWorkingTimesSelector = createSelector(workTimeSelector, (workTime) =>
  memoize((unitId: Uuid): UnitWeeklyWorkingTime[] => workTime[unitId]?.weeklyWorkingTimes || []),
);

export const getOwnUnitWeeklyWorkingTimeSelector = createSelector(
  getUnitWeeklyWorkingTimesSelector,
  (getUnitWeeklyWorkingTimes) =>
    memoize(
      (unitId: Uuid): UnitWeeklyWorkingTime | undefined =>
        getUnitWeeklyWorkingTimes(unitId).filter(({pathLength}) => pathLength === 0)[0],
    ),
);

export const getNearestUnitWeeklyWorkingTimeSelector = createSelector(
  getUnitWeeklyWorkingTimesSelector,
  (getUnitWeeklyWorkingTimes) =>
    memoize(
      (unitId: Uuid): UnitWeeklyWorkingTime | undefined => resolveToNearest(getUnitWeeklyWorkingTimes(unitId))[0],
    ),
);

export const getUnitWeeklyWorkingTimeGenerationAtDateSelector = createSelector(
  getUnitWeeklyWorkingTimesSelector,
  (getUnitWeeklyWorkingTimes) =>
    memoize(
      (unitId: Uuid, date: DateStr): UnitWeeklyWorkingTimeGeneration =>
        findGenerationAtDate(getNearestWeeklyGenerations(getUnitWeeklyWorkingTimes(unitId)), date),
      memoizeJsonResolver,
    ),
);

export const getUnitOwnCustomWorkingTimesSelector = createSelector(workTimeSelector, (workTime) =>
  memoize((unitId: Uuid): UnitCustomWorkingTime[] =>
    (workTime[unitId]?.customWorkingTimes || [])
      .filter(({pathLength}) => pathLength === 0)
      .sort((a, b) => a.startDate.localeCompare(b.startDate)),
  ),
);

export const getUnitCustomWorkTimesAtDateSelector = createSelector(
  workTimeSelector,
  (workTime): ((unitId: Uuid) => (isoDate: DateStr) => UnitCustomWorkingTime[]) =>
    memoize((unitId: Uuid): ((isoDate: DateStr) => UnitCustomWorkingTime[]) => {
      const unitCustomWorkTimes = workTime[unitId]?.customWorkingTimes || [];
      return memoize((isoDate: DateStr) => {
        return unitCustomWorkTimes.filter(({startDate, endDate}) => startDate <= isoDate && endDate >= isoDate);
      });
    }),
);

export interface WorkingTimeAtDate {
  project: number; //in minutes
  work: number; //in minutes
}

export interface GetWorkingTimeAtDate {
  /**
   * @param isoDate
   * @param ignoreOffDays ignores any custom work time that is not of type "nonStandardWorkTime"
   * result in minutes
   */
  (isoDate: DateStr, ignoreOffDays?: boolean): WorkingTimeAtDate;
}

export interface GetWorkingTimeAtDateForUser {
  (unitId: Uuid): GetWorkingTimeAtDate;
}

export interface GetNearestLowestCustomWorkTimeAtDate {
  (date: DateStr, isNonStdWorkTime?: boolean | null): UnitCustomWorkingTime | null;
}

export interface GetNearestLowestUnitCustomWorkTimeAtDate {
  (unitId: Uuid): GetNearestLowestCustomWorkTimeAtDate;
}

export const getNearestLowestUnitCustomWorkTimeAtDateSelector = createSelector(
  getUnitCustomWorkTimesAtDateSelector,
  (getUnitCustomWorkTimesAtDate): GetNearestLowestUnitCustomWorkTimeAtDate =>
    memoize((unitId) => {
      const getCustomWorkTimes = getUnitCustomWorkTimesAtDate(unitId);
      return memoize(
        (isoDate, isNonStdWorkTime = null) =>
          resolveToNearestLowestCustomWorkTime(getCustomWorkTimes(isoDate), isNonStdWorkTime),
        memoizeJsonResolver,
      );
    }),
);

export const getUnitWorkMinutesAtDateSelector = createSelector(
  getUnitWeeklyWorkingTimeGenerationAtDateSelector,
  getNearestLowestUnitCustomWorkTimeAtDateSelector,
  (getUnitWeeklyWorkingTimeGenerationAtDate, getNearestLowestUnitCustomWorkTimeAtDate): GetWorkingTimeAtDateForUser =>
    memoize((unitId: Uuid): GetWorkingTimeAtDate => {
      const getNearestLowestCustomWorkTimeAtDate = getNearestLowestUnitCustomWorkTimeAtDate(unitId);
      return memoize((isoDate: DateStr, ignoreOffDays) => {
        const custom = getNearestLowestCustomWorkTimeAtDate(isoDate, ignoreOffDays ? true : null);
        const weekday = fromIsoFormat(isoDate).isoWeekday() as IsoWeekday;
        const weekly = getUnitWeeklyWorkingTimeGenerationAtDate(unitId, isoDate);
        return {
          project: custom ? custom.projectMinutes : weekly.projectMinutes[weekday],
          work: custom ? custom.workingMinutes : weekly.workingMinutes[weekday],
        };
      }, memoizeJsonResolver);
    }),
);

export const getOrgWorkMinutesAtDateSelector = createSelector(
  getUnitWorkMinutesAtDateSelector,
  workTimeOrganizationLevelUnitSelector,
  (getUnitWorkMinutesAtDate, orgUnit): GetWorkingTimeAtDate => getUnitWorkMinutesAtDate(orgUnit.unitId),
);

interface GetUnitVacationDaysForYear {
  (year: number): number;
}

/**
 * see https://gitlab.local.hcom.de/octaved-flow/webapp/-/issues/1349
 * An absence day may only count with these rules:
 * - The user must have a **regular** working time greater than 0 for the day
 * - The day may not bet an **inherited** public holiday (which is the only custom reason that can be inherited)
 */
export const isDateCountingForAbsenceSelector = createSelector(
  getUnitWeeklyWorkingTimeGenerationAtDateSelector,
  getUnitCustomWorkTimesAtDateSelector,
  (getUnitWeeklyWorkingTimeGenerationAtDate, getUnitCustomWorkTimesAtDate) =>
    memoize((unitId: Uuid) => {
      const getCustomWorkingTimes = getUnitCustomWorkTimesAtDate(unitId);
      return memoize((date: DateStr) => {
        const customWorkingTimes = getCustomWorkingTimes(date);
        const inherited = customWorkingTimes.filter(({pathLength}) => pathLength > 0);
        const inheritedLowest = resolveToNearestLowestCustomWorkTime(inherited, false);
        const weekday = fromIsoFormat(date).isoWeekday() as IsoWeekday;
        const regularWorkingTime = getUnitWeeklyWorkingTimeGenerationAtDate(unitId, date).workingMinutes[weekday];
        return regularWorkingTime > 0 && (!inheritedLowest || inheritedLowest.workingMinutes > 0);
      });
    }),
);

export const getUnitTakenVacationDaysForYearSelector = createSelector(
  workTimeSelector,
  isDateCountingForAbsenceSelector,
  (workTime, isDateCountingForAbsenceSum) => {
    return memoize((unitId: Uuid): GetUnitVacationDaysForYear => {
      const isDateCounting = isDateCountingForAbsenceSum(unitId);
      const fullDays = new Map<number, Set<DateStr>>();
      const halfDays = new Map<number, Set<DateStr>>();
      const addDayToYear = (day: dayjs.Dayjs, iso: DateStr, isHalf: boolean): void => {
        const year = day.year();
        const map = isHalf ? halfDays : fullDays;
        map.set(year, (map.get(year) || new Set<DateStr>()).add(iso));
      };

      (workTime[unitId]?.customWorkingTimes || []).forEach(({startDate, endDate, type}) => {
        const isHalf = type === CustomWorkTimeType.halfVacationDay;
        if (type === CustomWorkTimeType.vacationDay || isHalf) {
          let currentIsoDate = startDate;
          let currentDate = fromIsoFormat(currentIsoDate);
          while (currentIsoDate <= endDate) {
            if (isDateCounting(currentIsoDate)) {
              addDayToYear(currentDate, currentIsoDate, isHalf);
            }
            currentDate = currentDate.add(1, 'day');
            currentIsoDate = toIsoFormat(currentDate);
          }
        }
      });

      //Remove half-days if there are already full days vacation:
      fullDays.forEach((set, year) => {
        if (halfDays.has(year)) {
          set.forEach((date) => {
            halfDays.get(year)!.delete(date);
          });
        }
      });

      return (year: number) => (fullDays.get(year)?.size || 0) + (halfDays.get(year)?.size || 0) / 2;
    });
  },
);

export const getUserVacationConfigsSelector = createSelector(
  workTimeSelector,
  (workTime) => (userId: Uuid) => workTime[userId]?.vacationConfigs,
);

export interface VacationDaysForYear {
  carry: number;
  carrySet: boolean; //whether a carryover was defined
  entitled: number;
  entitledSet: boolean; //whether an entitlement was defined
  remaining: number;
  taken: number;
  total: number;
}

export const getUserVacationDaysForYearSelector = createSelector(
  getUserVacationConfigsSelector,
  getUnitTakenVacationDaysForYearSelector,
  (getUserVacationConfigs, getUnitTakenVacationDaysForYear) =>
    (userId: Uuid, year: number): VacationDaysForYear => {
      const config = getUserVacationConfigs(userId)?.[year];
      const taken = getUnitTakenVacationDaysForYear(userId)(year);
      const carry = config?.carryover ?? 0;
      const entitled = config?.entitlement ?? 0;
      const total = carry + entitled;
      return {
        carry,
        entitled,
        taken,
        total,
        carrySet: typeof config?.carryover === 'number',
        entitledSet: typeof config?.entitlement === 'number',
        remaining: total - taken,
      };
    },
);
