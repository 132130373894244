import {lazy, ReactElement, Suspense, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Link from '../../Components/Link';

const ConfigureTimeSheetExportsDialog = lazy(() => import('./ConfigureTimeSheetExportsDialog'));

export default function ConfigureTimeSheetExports(): ReactElement {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  const close = useCallback(() => setShow(false), []);
  return (
    <>
      <Link onClick={() => setShow(true)}>
        <span>{t('dialogs:configureTimeSheetExports.dialogOpenLink')}</span>
      </Link>
      {show && (
        <Suspense fallback={<></>}>
          <ConfigureTimeSheetExportsDialog close={close} />
        </Suspense>
      )}
    </>
  );
}
