'use client';

import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';
import {cn} from './Hooks';

const TWSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({className, ...props}, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn('relative flex w-full touch-none select-none items-center', className)}
    {...props}
  >
    <SliderPrimitive.Track className={'relative h-2 w-full grow overflow-hidden rounded-full bg-slate-200'}>
      <SliderPrimitive.Range className={'absolute h-full bg-sky-700'} />
    </SliderPrimitive.Track>
    {new Array(props.value?.length ?? 1).fill(0).map((_, idx) => (
      <SliderPrimitive.Thumb
        key={idx}
        className={
          'ring-offset-background focus-visible:ring-ring block h-5 w-5 rounded-full border-2 border-sky-700 bg-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'
        }
      />
    ))}
  </SliderPrimitive.Root>
));
TWSlider.displayName = SliderPrimitive.Root.displayName;

export {TWSlider};
