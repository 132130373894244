import {parse} from '@octaved/users/src/Culture/NumberFormatter';

const dayTransformRegex = /^(\d+(?:(?:,|.)\d+)?)(d|t)$/;

export function transformEffort(effort: string): string {
  if (isTransformable(effort)) {
    const matches = effort.match(dayTransformRegex);
    if (matches && matches.length > 1) {
      return Number(parse(matches[1])) * 8 + '';
    }
  }
  return effort;
}

export function isTransformable(effort: string): boolean {
  return dayTransformRegex.test(effort);
}
