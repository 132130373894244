import {Check} from 'lucide-react';
import {forwardRef, useEffect, useRef, useState} from 'react';
import {cn} from './Hooks';
import {TWButton} from './TWButton';
import {TWInput, TWInputProps} from './TWInput';

export type TWAutosaveInputProps = Omit<TWInputProps, 'value' | 'onChange' | 'onFocus' | 'onBlur'> & {
  hasChanges?: boolean;
  onSave: (value: string) => Promise<void> | void;
  required?: boolean;
  requiredUntouched?: boolean; //same as required, but will cause error state without any user interaction
  rightAligned?: boolean;
  setValue?: (val: string) => void;
  value: string;
  valueTransformer?: (val: string) => string;
  wrapperClassname?: string;
};

export const TWAutosaveInput = forwardRef<HTMLInputElement, TWAutosaveInputProps>(
  (
    {
      hasChanges: outerHasChanges,
      hasError,
      onSave,
      required,
      requiredUntouched,
      rightAligned,
      setValue,
      value,
      valueTransformer,
      wrapperClassname = '',
      ...rest
    },
    ref,
  ) => {
    const [__value, __setValue] = useState<string>(value);
    const _value = setValue ? value : __value;
    const _setValue = setValue ? setValue : __setValue;

    const hasChanges = outerHasChanges || value !== _value;
    const focused = useRef(false);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
      if (!focused.current) {
        _setValue(value);
      }
    }, [_setValue, value]);

    return (
      <div className={cn('relative w-full', wrapperClassname)}>
        <TWInput
          ref={ref}
          className={cn(hasChanges && 'pr-8', rightAligned && 'text-right', hasChanges && rightAligned && 'pl-8 pr-3')}
          hasError={hasError || (((required && touched) || requiredUntouched) && !_value)}
          value={_value}
          onChange={(e) => _setValue(e.target.value)}
          onFocus={() => {
            focused.current = true;
          }}
          onBlur={async (e) => {
            let val = e.target.value;
            setTouched(true);
            //If `setValue` is given, we can assume that `val === value` all the time, so the check is useless:
            if ((!(required || requiredUntouched) || val) && (setValue || val !== value || hasError)) {
              const transformedValue = valueTransformer ? valueTransformer(val) : val;
              if (transformedValue !== val) {
                _setValue(transformedValue);
                val = transformedValue;
              }
              await onSave(transformedValue);
            }
            focused.current = false;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              (e.target as HTMLInputElement).blur();
            }
          }}
          autoComplete={'off'}
          {...rest}
        />

        {hasChanges && (
          <TWButton
            colorScheme={'primary'}
            variant={'solid'}
            size={'xs'}
            iconOnly
            className={cn('absolute right-1 top-1/2 size-6 -translate-y-1/2', rightAligned && 'left-1 right-auto')}
          >
            <Check className={'h-4 w-4'} />
          </TWButton>
        )}
      </div>
    );
  },
);
TWAutosaveInput.displayName = 'TWAutosaveInput';
