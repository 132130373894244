import {EnumFlowBillingInterval} from '@octaved/env/src/dbalEnumTypes';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {Dropdown} from 'semantic-ui-react';
import {TimePeriodInterval} from '../../../../Modules/Filter/TimePeriod';

const intervalOptions = [
  {
    text: (
      <span>
        <Trans i18nKey={'pages:projects.pages.billingInPeriod.filter.intervalMonth'} />
      </span>
    ),
    value: EnumFlowBillingInterval.VALUE_MONTH,
  },
  {
    text: (
      <span>
        <Trans i18nKey={'pages:projects.pages.billingInPeriod.filter.intervalQuarter'} />
      </span>
    ),
    value: EnumFlowBillingInterval.VALUE_QUARTER,
  },
  {
    text: (
      <span>
        <Trans i18nKey={'pages:projects.pages.billingInPeriod.filter.intervalYear'} />
      </span>
    ),
    value: EnumFlowBillingInterval.VALUE_YEAR,
  },
  {
    text: (
      <span>
        <Trans i18nKey={'pages:projects.pages.billingInPeriod.filter.intervalCustom'} />
      </span>
    ),
    value: EnumFlowBillingInterval.VALUE_CUSTOM,
  },
];

interface Props {
  interval: TimePeriodInterval;
  setInterval: (interval: TimePeriodInterval) => void;
}

export default function Interval({interval, setInterval}: Props): ReactElement {
  return (
    <Dropdown
      selection
      options={intervalOptions}
      value={interval}
      onChange={(_, {value}) => {
        if (value) {
          setInterval(value as EnumFlowBillingInterval);
        }
      }}
      selectOnBlur={false}
      selectOnNavigation={false}
      compact
    />
  );
}
