import {FlexItem, HStack} from '@octaved/ui';
import {ReactElement} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import ViewportHeight from '../Components/Layout/ViewportHeight';
import PageLoadingWrapper from '../PageLoadingWrapper';
import {routeObjects} from '../Routing/FlowRoutes';

const browserRouter = createBrowserRouter(routeObjects);

export default function PageWrapper(): ReactElement {
  return (
    <ViewportHeight>
      <HStack spacing={0} alignItems={'stretch'} height={'100%'} width={'100vw'} overflow={'hidden'}>
        <FlexItem flexGrow={1} width={'100%'} overflow={'hidden'}>
          <RouterProvider router={browserRouter} fallbackElement={<PageLoadingWrapper />} />
        </FlexItem>
      </HStack>
    </ViewportHeight>
  );
}
