import {cn} from '@octaved/ui';
import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';
import {TableInstance} from 'react-table';
import {Table} from 'semantic-ui-react';
import {useFlowTableStyle} from './TableStyle';

export interface ExtraCell {
  className?: string;
  content: ReactNode;
}

interface Props<T extends {id: string}> extends TableInstance<T> {
  canSelect?: boolean;
  celled?: boolean;
  disabledRows?: Set<string>;
  extraRows?: ExtraCell[][];
  hideEmptyTableOnlyFooter?: boolean;
  placeholder?: ReactNode;
  selected?: string | null;
  setSelected?: (key: string) => void;
  showFooter?: boolean;
  sortable?: boolean;
}

export default function TableRenderer<T extends {id: string}>({
  canSelect,
  celled = true,
  disabledRows,
  extraRows,
  footerGroups,
  getTableProps,
  headerGroups,
  hideEmptyTableOnlyFooter,
  placeholder,
  prepareRow,
  rows,
  selected,
  setSelected,
  showFooter,
  sortable = false,
}: Props<T>): ReactElement {
  const {className, styles} = useFlowTableStyle();

  return (
    <>
      <Table {...getTableProps()} celled={celled} sortable={sortable} className={classNames(className, 'table')}>
        <colgroup>
          {headerGroups[0].headers.map((column) => (
            <col style={{width: column.width}} key={column.getHeaderProps().key} />
          ))}
        </colgroup>
        {(!hideEmptyTableOnlyFooter || rows.length > 0) && (
          <>
            <Table.Header>
              {headerGroups.map((headerGroup) => (
                <Table.Row key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps();
                    return (
                      <Table.HeaderCell
                        {...headerProps}
                        textAlign={column.headerTextAlign}
                        className={classNames(className, 'headerCell')}
                        key={headerProps.key}
                        onClick={() => {
                          column.toggleSortBy?.(!column.isSortedDesc);
                        }}
                        sorted={column.isSorted ? (column.isSortedDesc ? 'descending' : 'ascending') : undefined}
                      >
                        {column.render('Header')}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Header>
            <Table.Body>
              {rows.map((row) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                const id = row.original.id;
                return (
                  <Table.Row
                    {...rowProps}
                    key={rowProps.key}
                    onClick={() => canSelect && setSelected && setSelected(id)}
                    className={classNames(className, 'group/row row', 'group', {
                      disabled: disabledRows?.has(id),
                      noSelect: !canSelect,
                    })}
                    active={id === selected}
                  >
                    {row.cells.map((cell, cellIndex) => {
                      const cellProps = cell.getCellProps();
                      return (
                        <Table.Cell
                          {...cellProps}
                          key={cellProps.key}
                          index={cellIndex}
                          textAlign={cell.column.textAlign}
                          className={classNames(className, cell.column.className, 'cell')}
                        >
                          {cell.render('Cell')}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
              {extraRows && (
                <>
                  {extraRows.map((cells, rowIdx) => (
                    <Table.Row
                      key={rowIdx}
                      className={classNames(className, 'group/row row', 'group', {noSelect: true})}
                    >
                      {cells.map((cell, cellIdx) => (
                        <Table.Cell key={cellIdx} className={classNames(className, cell.className, 'cell')}>
                          {cell.content}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </>
              )}
              {placeholder && rows.length === 0 && (!extraRows || extraRows.length === 0) && (
                <Table.Row>
                  <Table.Cell colSpan={headerGroups[0].headers.length}>{placeholder}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </>
        )}

        {showFooter && (
          <Table.Footer>
            {footerGroups.map((footerGroup) => (
              <Table.Row key={footerGroup.getFooterGroupProps().key}>
                {footerGroup.headers.map((column) => {
                  const footerProps = column.getFooterProps();
                  return (
                    <Table.Cell
                      {...footerProps}
                      textAlign={column.headerTextAlign}
                      className={classNames(
                        className,
                        cn('footerCell', {noTopBorder: hideEmptyTableOnlyFooter && rows.length === 0}),
                      )}
                      key={footerProps.key}
                      onClick={() => {
                        column.toggleSortBy?.(!column.isSortedDesc);
                      }}
                      sorted={column.isSorted ? (column.isSortedDesc ? 'descending' : 'ascending') : undefined}
                    >
                      {column.render('Footer')}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Footer>
        )}
      </Table>
      {styles}
    </>
  );
}
