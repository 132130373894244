import {PlanningEvent} from '@octaved/flow/src/Modules/Events';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ServerRequestAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {getInstanceUuid} from '@octaved/utilities';
import {Dispatch, isAction, MiddlewareAPI, UnknownAction} from 'redux';
import {isSimulatedAction} from '../Components/SimulatedPlanningStore/SimulatedMiddleware';
import {FLOW_PATCH_PLANNING_REQUEST, FLOW_UPDATE_WORKLOAD} from '../Modules/ActionTypes';
import {PatchPlanningRequest, PlanningPatchData} from '../Modules/Planning';
import {updatePlanning} from './UpdatePlanning/UpdatePlanning';
import {updateWorkload} from './UpdateWorkload/UpdateWorkload';

export default function planningMiddleware(api: MiddlewareAPI<Dispatch, FlowState>) {
  return function (next: (a: unknown) => unknown) {
    return function (action: unknown): Promise<unknown> | unknown {
      let _action = action;
      let nextResult;
      if (isAction(_action) && _action.type === FLOW_PATCH_PLANNING_REQUEST) {
        const r = handlePlanningRequest(
          _action as unknown as ServerRequestAction<{
            data: PlanningPatchData[];
          }>,
          next,
          api,
        );
        _action = r.action;
        nextResult = r.nextResult;
      } else {
        nextResult = next(_action);
      }
      if (isAction(_action)) {
        //execute workload calculations after updating the store
        handleWorkloadRequests(_action, next, api);
      }
      return nextResult;
    };
  };
}

function handlePlanningRequest(
  action: ServerRequestAction<{
    data: PlanningPatchData[];
  }>,
  next: (a: unknown) => unknown,
  api: MiddlewareAPI<Dispatch, FlowState>,
): {action: PatchPlanningRequest; nextResult: unknown} {
  const state = api.getState();
  const data = action.options.data;
  const isSimulation = isSimulatedAction(action);
  const updatePlanningData = updatePlanning(state, data, isSimulation);
  const patchPlanningRequest: PatchPlanningRequest = {...action, ...updatePlanningData};
  const nextResult = next(patchPlanningRequest);
  if (updatePlanningData.updatedNodePlanningDates) {
    const action: PlanningEvent = {
      changedDependencies: [],
      isServerSide: false,
      responsibleInstanceId: getInstanceUuid(),
      source: 'Update',
      type: 'flow.PlanningEvent',
      updatedDependencies: {planningSuccessors: {}, planningPredecessors: {}},
      updatedDueDates: {},
      updatedLogicalDependencies: {tasks: {}, workPackages: {}},
      updatedMilestoneNodeIds: [],
      updatedNodeIds: [],
      updatedNodePlanningDates: updatePlanningData.updatedNodePlanningDates || {},
      updatedPlanningDateNodeIds: [],
    };
    next(action);
  }
  return {action: patchPlanningRequest, nextResult};
}

function handleWorkloadRequests(
  action: UnknownAction,
  next: (a: unknown) => unknown,
  api: MiddlewareAPI<Dispatch, FlowState>,
): void {
  const state = api.getState();
  const updatedWorkload = updateWorkload(state, action);
  if (updatedWorkload) {
    next({updatedWorkload, type: FLOW_UPDATE_WORKLOAD});
  }
}
