import {forwardRef, ReactElement} from 'react';
import {Trans} from 'react-i18next';

interface Props {
  onClick: () => void;
  label: string;
  paddingLeft: string;
}

export default forwardRef<HTMLDivElement, Props>(function AddItem({onClick, label, paddingLeft}, ref): ReactElement {
  return (
    <div
      className={'addTaskRow'}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <span>
        <Trans i18nKey={label} />
      </span>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .addTaskRow {
          height: var(--row-height);
          display: flex;
          align-items: center;
          color: var(--color-text-lighter);
          padding-left: ${paddingLeft};
          cursor: pointer;
          transition: background-color 170ms ease-in-out;
          border-radius: 0 0 8px 8px;
        }

        .addTaskRow:hover {
          background-color: var(--color-hover-bg);
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
});
