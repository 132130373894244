import {exportOffers} from '@octaved/flow-api';
import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {dowoadBlob} from '@octaved/hooks/src/DownloadBlob';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWButton} from '@octaved/ui';
import {Download} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export default function ExcelExportButton({pidId, includeTasks}: {includeTasks: boolean; pidId: Uuid}): ReactElement {
  const {t} = useTranslation();
  const [download, isDownloading] = useAsyncExecution(async () => {
    await dowoadBlob(exportOffers, {
      pidId,
      format: 'excel',
      includeTasks: +includeTasks,
    });
  });
  return (
    <div className={'flex'}>
      <TWButton
        withIcon
        size={'md'}
        isLoading={isDownloading}
        disabled={isDownloading}
        onClick={download}
        variant={'solid'}
        colorScheme={'primary'}
      >
        <Download className={'size-4'} />
        {t('pages:projects.inspector.offer.export.downloadButton')}
      </TWButton>
    </div>
  );
}
