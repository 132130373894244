import {createLucideIcon, IconNode} from 'lucide-react';
import {defaultAttributes, SVGIconNode} from './LucideIconAttributes';

const iconDrawing: IconNode = [
  [
    'path',
    {
      d: 'M5 9H1V15H5C6.10457 15 7 14.1046 7 13V11C7 9.89543 6.10457 9 5 9Z',
      fill: 'currentColor',
      key: '1',
      strokeWidth: '1.5',
    },
  ],
  [
    'path',
    {
      d: 'M19 9H23V15H19C17.8954 15 17 14.1046 17 13V11C17 9.89543 17.8954 9 19 9Z',
      fill: 'currentColor',
      key: '2',
      strokeWidth: '1.5',
    },
  ],
  [
    'rect',
    {
      d: 'M19 9H23V15H19C17.8954 15 17 14.1046 17 13V11C17 9.89543 17.8954 9 19 9Z',
      fill: 'currentColor',
      height: '14',
      key: '3',
      rx: '1',
      width: '1.5',
      x: '11',
      y: '5',
    },
  ],
];

export const GapBarIconSvg = ['svg', defaultAttributes, iconDrawing] as SVGIconNode;
export const GapBarIcon = createLucideIcon('GapBarIcon', iconDrawing);
