import {Uuid} from '@octaved/typescript/src/lib';
import {Icon} from '@octaved/ui';
import {ListOrdered} from 'lucide-react';
import {Toast, toast} from 'react-hot-toast';
import {Trans} from 'react-i18next';
import {Task} from '../../EntityInterfaces/Task';
import SnackbarWrapper from './SnackbarWrapper';

export function createLogicalDependencyWorkflowControlSnackbar(taskId: Uuid, tasks: Task[]): void {
  toast(
    (toastInstance: Toast) => (
      <SnackbarWrapper toastId={toastInstance.id}>
        <span>
          <Trans
            i18nKey={'snackbars:logicalDependencyWorkflowControl.outOfOrderCompleted'}
            values={{
              count: tasks.length,
              taskNames: tasks.map(({name}) => name).join(', '),
            }}
          />
        </span>
      </SnackbarWrapper>
    ),
    {
      icon: (
        <Icon iconColor={'blue'} size={'bigger'}>
          <ListOrdered strokeWidth={1} />
        </Icon>
      ),
      id: `logicalDependencyWorkflowControl-${taskId}`,
    },
  );
}
