import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {Group, Project, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {
  isGroup,
  isNodeWithDueDate,
  isPlanningNode,
  isProject,
  isSubWorkPackage,
  isTask,
  isWorkPackage,
} from '@octaved/flow/src/Node/NodeIdentifiers';
import DueDatePidGridRow from '@octaved/planning/src/Components/DueDates/DueDates/DueDatePidGridRow';
import PlanningNodeDueDateRow from '@octaved/planning/src/Components/DueDates/DueDates/PlanningNodeDueDateRow';
import {useMinMaxPlanningDates} from '@octaved/planning/src/Hooks/MinMaxPlanningDates';
import {useLoadPlanningDates} from '@octaved/planning/src/Modules/PlanningDates';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {toDateStr} from '@octaved/typescript';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeDueDatesInParentsProps {
  readonly: boolean;
  node: Group | Project | Task | WorkPackage | SubWorkPackage;
}

export default function NodeDueDatesInParents({
  frame: Frame,
  node,
  readonly,
}: NodeDueDatesInParentsProps & FramedSectionOrTileProps): ReactElement | null {
  const ancestry = useNodeAncestry(node.id, false);
  const ancestors = ancestry.ancestors;
  const getRootGroupType = useSelector(getRootGroupTypeSelector);
  const dueDate = isNodeWithDueDate(node) ? node.dueDate : null;
  const canReadPlanning = useIsGranted('FLOW_NODE_READ_PLANNING', node.id);
  const hasDueDates = ancestors.filter((ancestor) => isNodeWithDueDate(ancestor) && ancestor.dueDate).length > 0;
  const {plannedEnd} = useMinMaxPlanningDates(node.id);
  const plannableAncestors = ancestors.filter((ancestor) => isPlanningNode(ancestor));
  useLoadPlanningDates(plannableAncestors.map((a) => a.id));

  if (!canReadPlanning && !hasDueDates) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.planning.labelInParents'}>
      <div className={'grid grid-cols-[repeat(2,max-content)] items-center gap-x-4'}>
        {ancestors.map((ancestor) => {
          if (isWorkPackage(ancestor) || isSubWorkPackage(ancestor) || isTask(ancestor)) {
            return (
              <PlanningNodeDueDateRow
                key={ancestor.id}
                node={ancestor}
                parentEndDate={plannedEnd ? toDateStr(plannedEnd) : undefined}
              />
            );
          }

          let token = undefined;
          if (isProject(ancestor)) {
            token = 'nodeDetails:field.planning.projectDueDate';
          } else if (isGroup(ancestor)) {
            token = 'nodeDetails:field.planning.subProjectDueDate';
          } else {
            return null;
          }

          return (
            <DueDatePidGridRow
              dueDateToken={token}
              key={ancestor.id}
              id={ancestor.id}
              nodeType={ancestor.nodeType}
              dueDate={ancestor.id === node.id ? dueDate : ancestor.dueDate}
              readonly={
                readonly || ancestor.id !== node.id || getRootGroupType(ancestor.id) !== EnumFlowGroupType.VALUE_GROUP
              }
              isMissconfigured={Boolean(
                plannedEnd && ancestor.dueDate && toDateStr(plannedEnd) > toDateStr(ancestor.dueDate),
              )}
            />
          );
        })}
      </div>
    </Frame>
  );
}
