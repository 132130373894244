import {getProjectControllingDegreeOfCompletion} from '@octaved/flow-api';
import {useInspectorId} from '@octaved/hooks';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, isLoaded, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  DegreeOfCompletion,
  StoreDegreeOfCompletion,
} from '../../../EntityInterfaces/Projects/ProjectControlling/DegreeOfCompletion';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_FAILURE,
  FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_REQUEST,
  FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_SUCCESS,
} from '../../ActionTypes';
import {
  degreeOfCompletionSelector,
  degreeOfCompletionStatesSelector,
} from '../../Selectors/Projects/ProjectControlling/DegreeOfCompletionSelectors';
import {workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector} from '../../Selectors/UiPages/ProjectsSelector';
import {FlowState} from '../../State';

interface LoadAction {
  key: string;
  response: DegreeOfCompletion;
}

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_SUCCESS,
  (state: StoreDegreeOfCompletion, {key, response}: LoadAction): StoreDegreeOfCompletion => {
    return {...state, [key]: response};
  },
);
export const degreeOfCompletionReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_SUCCESS, createTimestampReducer('key', LOADED));
addMultiToReducerMap(
  stateReducerMap,
  [
    FLOW_CHANGE_PID_SUCCESS,
    'flow.NodeRestoredFromTrashEvent',
    'flow.ProjectCustomerChangedEvent', //TODO: not sure if needed
    'flow.TaskCreatedEvent',
    'flow.TaskPatchedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  () => ({}),
);
export const degreeOfCompletionStateReducer = ReduceFromMap(stateReducerMap);

function loadDegreeOfCompletion(key: string, workPackageIds: Uuid[]): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = degreeOfCompletionStatesSelector(getState())[key];
    if (!state || isOutdated(state)) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: getProjectControllingDegreeOfCompletion,
          method: 'post',
          options: {data: {workPackageIds}},
          types: {
            failureType: FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_FAILURE,
            requestType: FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_REQUEST,
            successType: FLOW_LOAD_PROJECT_CONTROLLING_DEG_OF_COMPLETION_SUCCESS,
          },
        },
      });
    }
  };
}

const empty: DegreeOfCompletion = {
  countCompletedUnplannedTasks: 0,
  countExecutedWorkPackages: 0,
  countPlannedTasks: 0,
  countTasks: 0,
  countUnplannedTasks: 0,
  countWorkPackages: 0,
  countWorkPackagesWithLimit: 0,
  sumBillableTimeOfWorkPackagesWithLimit: 0,
  sumBillableTimeOfWorkPackagesWithoutLimit: 0,
  sumCompletedPlannedTaskTime: 0,
  sumPlannedTaskTime: 0,
  sumWorkPackageLimit: 0,
};

export function useDegreeOfCompletion(): {
  degreeOfCompletion: DegreeOfCompletion;
  isLoading: boolean;
} {
  const inspectorId = useInspectorId();
  const {key, workPackageIds} = useSelector(workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector)(
    inspectorId,
  );
  const dispatch = useDispatch();
  const state = useSelector(degreeOfCompletionStatesSelector)[key];
  const activities = useSelector(degreeOfCompletionSelector)[key];
  useEffect(() => {
    // noinspection BadExpressionStatementJS
    void state; //reload dep
    if (workPackageIds.length) {
      dispatch(loadDegreeOfCompletion(key, workPackageIds));
    }
  }, [dispatch, key, state, workPackageIds]);
  const isLoading = workPackageIds.length > 0 && (!state || !isLoaded(state));
  return {
    isLoading,
    degreeOfCompletion: activities || empty,
  };
}
