import {useBoolean} from '@octaved/hooks';
import dayjs from 'dayjs';
import {memo, ReactElement, useEffect, useRef, useState} from 'react';
import {FocusedInputShape} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {PickerViewProps} from './Internal/PickerView';
import PopupPickerView from './Internal/PopupPickerView';
import StandardDateOrRangePickerFooter, {
  StandardDateOrRangePickerFooterProps,
} from './Internal/StandardDateOrRangePickerFooter';
import StandardRangePickerTrigger from './Internal/StandardRangePickerTrigger';

export interface ConvertFromFn<DATE> {
  (date: DATE): dayjs.Dayjs;

  (date: null): null;

  (date: DATE | null): dayjs.Dayjs | null;
}

export interface ConvertToFn<DATE> {
  (date: dayjs.Dayjs): DATE;

  (date: null): null;

  (date: dayjs.Dayjs | null): DATE | null;
}

export interface OnChangeFn<DATE, CAN_CLEAR extends boolean> {
  (start: CAN_CLEAR extends false ? DATE : DATE | null, end: CAN_CLEAR extends false ? DATE : DATE | null): void;
}

export type PublicDatePickerOverride<DATE extends string, CAN_CLEAR extends boolean> = Partial<
  Omit<PickerViewProps<DATE, CAN_CLEAR>, 'start' | 'end' | 'onChangeDate' | 'isRange' | 'focused' | 'setFocused'>
>;

interface Props<DATE extends string, CAN_CLEAR extends boolean> {
  datePickerOverride?: PublicDatePickerOverride<DATE, CAN_CLEAR>;
  isRangeDefault: boolean;
  end: DATE | null;
  footerOverride?: Omit<
    StandardDateOrRangePickerFooterProps,
    'clear' | 'close' | 'dateRangeToggleProps' | 'canClear'
  > & {
    canClear?: CAN_CLEAR;
  };
  onChange: OnChangeFn<DATE, CAN_CLEAR>;
  readonly?: boolean;
  start: DATE | null;
  convertFrom: ConvertFromFn<DATE>;
  convertTo: ConvertToFn<DATE>;
  placeholderToken?: string;
  dateFormat?: string;
  triggerButtonClassName?: string;
}

function InnerDateOrRangePicker<DATE extends string, CAN_CLEAR extends boolean>({
  convertFrom,
  convertTo,
  datePickerOverride,
  end,
  footerOverride,
  dateFormat,
  onChange,
  placeholderToken,
  readonly,
  start,
  triggerButtonClassName,
}: Props<DATE, CAN_CLEAR>): ReactElement {
  const [open, setOpen] = useBoolean(false);
  const openRef = useRef(open);
  openRef.current = open;
  const [valueStart, setValueStart] = useState(start);
  const [valueEnd, setValueEnd] = useState(end);
  const singleDaySelected = valueStart === valueEnd;
  const [focused, setFocused] = useState<FocusedInputShape | null>(
    (valueStart && !valueEnd) || (valueStart && singleDaySelected) ? 'endDate' : 'startDate',
  );

  useEffect(() => setValueStart(start), [start]);
  useEffect(() => setValueEnd(end), [end]);
  useEffect(() => {
    setFocused((valueStart && !valueEnd) || (valueStart && singleDaySelected) ? 'endDate' : 'startDate');
  }, [valueStart, valueEnd, convertFrom, singleDaySelected]);
  const canClear = footerOverride && 'canClear' in footerOverride ? footerOverride.canClear : true;

  return (
    <PopupPickerView
      popupProps={{
        onClose: () => {
          if (valueStart && !valueEnd) {
            setValueStart(valueStart);
            setValueEnd(valueStart);
            onChange(valueStart, valueStart);
            setFocused('endDate');
          } else if (valueStart && valueEnd) {
            setValueStart(valueStart);
            setValueEnd(valueEnd);
            onChange(valueStart, valueEnd);
          }
          setOpen.off();
        },
        onOpen: setOpen.on,
        open: !readonly && open,
        trigger: (
          <div>
            <StandardRangePickerTrigger
              convertFrom={convertFrom}
              dateFormat={dateFormat}
              end={end}
              isActive={open}
              onToggle={setOpen.toggle}
              placeholderToken={placeholderToken}
              readonly={readonly}
              start={start}
              triggerClassName={triggerButtonClassName}
            />
          </div>
        ),
      }}
      datePickerProps={{
        focused,
        setFocused,
        isRange: true,
        ...datePickerOverride,
      }}
      start={valueStart}
      end={singleDaySelected ? null : valueEnd}
      onChangeDate={(startDate, endDate) => {
        if (focused === 'startDate') {
          setValueStart(startDate);
          setValueEnd(null);
          setFocused('endDate');
        } else {
          if (startDate && endDate) {
            setValueStart(startDate);
            setValueEnd(endDate);
          } else if (!endDate) {
            setValueStart(startDate);
            setValueEnd(valueStart);
          }
          setFocused('startDate');
        }
      }}
      footer={
        <StandardDateOrRangePickerFooter
          clear={() => canClear === true && onChange(null!, null!)}
          close={() => {
            if (valueStart && !valueEnd) {
              setValueStart(valueStart);
              setValueEnd(valueStart);
              onChange(valueStart, valueStart);
              setFocused('endDate');
            } else if (valueStart && valueEnd) {
              setValueStart(valueStart);
              setValueEnd(valueEnd);
              onChange(valueStart, valueEnd);
            }
            setOpen.off();
          }}
          {...footerOverride}
        />
      }
      convertFrom={convertFrom}
      convertTo={convertTo}
      canClear={footerOverride?.canClear}
    />
  );
}

const DateOrRangePicker = memo(InnerDateOrRangePicker) as typeof InnerDateOrRangePicker;
export {DateOrRangePicker};
