import Konva from 'konva';
import {Group} from 'konva/lib/Group';
import {Circle} from 'konva/lib/shapes/Circle';
import {Image} from 'konva/lib/shapes/Image';
import {Rect} from 'konva/lib/shapes/Rect';
import {Text} from 'konva/lib/shapes/Text';
import {throttle} from 'lodash';
import {ArrowLeftCircle, ArrowRightCircle} from 'lucide';
import {createText, defaultLineHeight} from '../CommonComponents/Text';
import {resetCursor, setMouseHoverEffect} from '../MouseHoverCursor';
import {RootContainer} from '../RootContainer';

const borderDistance = 50;

export class TodayButtons extends RootContainer {
  readonly #imageSize = defaultLineHeight;

  readonly #todayButtonLeft: Group = new Konva.Group({y: defaultLineHeight, visible: false});
  readonly #todayButtonRight: Group = new Konva.Group({y: defaultLineHeight, visible: false});

  #todayCircle: Circle | null = null;
  #todayRect: Rect | null = null;

  init(todayCircle: Circle, todayRect: Rect): void {
    this.#todayCircle = todayCircle;
    this.#todayRect = todayRect;
    this.#loadImages().then(([rightIcon, leftIcon]) => {
      this.#createButtonLeft(leftIcon);
      this.#createButtonRight(rightIcon);
      this.#updateTodayLocator();
    });

    this.disposables.push(
      this.ctx.eventEmitter.on('scroll', this.#updateTodayLocator),
      this.ctx.eventEmitter.on('resize', this.#updateTodayLocator),
      this.ctx.eventEmitter.on('columnWidthChanged', this.#updateTodayLocator),
    );
  }

  #updateTodayLocator = throttle((): void => {
    requestAnimationFrame(() => {
      const relativeTo = this.root.findAncestor('.Calendar');
      const circleClientRect = this.#todayCircle?.getParent()
        ? this.#todayCircle?.getClientRect({relativeTo})
        : this.#todayRect?.getClientRect({relativeTo});
      const containerWidth = this.ctx.calendarView.viewWidth;

      const circleX = circleClientRect?.x ?? 1;
      const circleWidth = circleClientRect?.width ?? 1;
      const circleOutsideLeft = circleX + circleWidth < 0;
      const circleOutsideRight = circleX > containerWidth;

      if (circleOutsideLeft) {
        this.#todayButtonLeft.x(borderDistance);
        this.#todayButtonLeft.visible(true);
        this.#todayButtonRight.visible(false);
      } else if (circleOutsideRight) {
        const rect = this.#todayButtonRight.findOne('Rect');
        if (rect) {
          this.#todayButtonRight.x(containerWidth - borderDistance - rect?.width());
        }
        this.#todayButtonLeft.visible(false);
        this.#todayButtonRight.visible(true);
      } else {
        this.#todayButtonLeft.visible(false);
        this.#todayButtonRight.visible(false);
      }
    });
  }, 30);

  #createButtonLeft(icon: Image): void {
    const text = this.#createText(this.#imageSize + 5);
    const rect = this.#createBackground(text);
    this.#todayButtonLeft.add(rect, text, icon);
    icon.x(3);
    this.#createClickHandler(this.#todayButtonLeft);
    this.root.add(this.#todayButtonLeft);
  }

  #createButtonRight(icon: Image): void {
    const text = this.#createText(5);
    const rect = this.#createBackground(text);
    this.#todayButtonRight.add(rect, text, icon);

    icon.x(text.width() + 7);
    this.#createClickHandler(this.#todayButtonRight);
    this.root.add(this.#todayButtonRight);
  }

  #createClickHandler(button: Group): void {
    setMouseHoverEffect(button, 'pointer');
    button.addEventListener('click tap', () => {
      this.ctx.scrolling.scrollToToday();
      resetCursor();
    });
  }

  #createBackground(text: Text): Rect {
    return new Konva.Rect({
      fill: '#aa18ff',
      height: text.height() + 6,
      width: text.width() + 10 + this.#imageSize,
      y: -3,
    });
  }

  #loadImages(): Promise<Image[]> {
    return Promise.all([
      this.iconToImage(ArrowRightCircle, {
        height: this.#imageSize,
        style: {
          fill: '#fff',
          stroke: '#aa18ff',
        },
        width: this.#imageSize,
      }),
      this.iconToImage(ArrowLeftCircle, {
        height: this.#imageSize,
        style: {
          fill: '#fff',
          stroke: '#aa18ff',
        },
        width: this.#imageSize,
      }),
    ]);
  }

  #createText(x: number): Text {
    return createText({
      x,
      fill: '#ffffff',
      text: this.ctx.t('general:today'),
      y: 1,
    });
  }
}
