import {Moment} from '@octaved/dayjs-moment';
import {ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import classNames from 'classnames';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {todayIsoDateSelector} from '../../../../../../Today';

interface Props {
  day: Moment;
  isWorkPackagePlanned: boolean;
  isDayWithinPlanned: boolean;
}

export default function DayView({day, isDayWithinPlanned, isWorkPackagePlanned}: Props): ReactElement {
  const currentIsoDate = useSelector(todayIsoDateSelector);
  const isToday = useMemo(() => currentIsoDate === day.format(ISO_DATE), [currentIsoDate, day]);
  return (
    <div className={classNames('date', {isDayWithinPlanned, isWorkPackagePlanned})}>
      {isWorkPackagePlanned && (
        <>
          {!isDayWithinPlanned && (
            <>
              <div className={'backgroundStatus'} />
              <div className={'date'}>
                <div className={classNames('numberOfMonth', {isToday})}>{day.format('D')}</div>
              </div>
            </>
          )}

          {isDayWithinPlanned && (
            <div className={'date'}>
              <div className={classNames('numberOfMonth', {isToday})}>{day.format('D')}</div>
            </div>
          )}
        </>
      )}
      {!isWorkPackagePlanned && (
        <div className={'date'}>
          <div className={classNames('numberOfMonth', {isToday})}>{day.format('D')}</div>
        </div>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .date {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        .backgroundStatus {
          position: absolute;
          inset: -1px;
          background-color: transparent;
        }

        .isWorkPackagePlanned:not(.isDayWithinPlanned) {
          background-color: var(--color-calendar-day-outside-bg);
        }

        .isWorkPackagePlanned:not(.isDayWithinPlanned) {
          color: var(--color-calendar-day-outside-text);
        }

        :global(.CalendarDay__selected) :not(.isDayWithinPlanned) .backgroundStatus {
          background-color: var(--color-calendar-day-outside-selectedBg);
        }

        :global(.CalendarDay__selected) :not(.isDayWithinPlanned) {
          color: var(--color-bg);
        }

        :global(.CalendarDay__selected_span) :not(.isDayWithinPlanned) .backgroundStatus {
          background-color: var(--color-calendar-day-outside-selectedSpanBg);
        }

        :global(.CalendarDay__selected_span) :not(.isDayWithinPlanned) {
          color: var(--color-text);
        }

        :global(.CalendarDaynot:not(.CalendarDay__selected_span):not(.CalendarDay__selected):hover) .backgroundStatus {
          background-color: var(--color-calendar-day-within-hoverBg);
        }

        :global(.CalendarDay:not(.CalendarDay__selected_span):not(.CalendarDay__selected):hover)
          :not(.isDayWithinPlanned)
          .backgroundStatus {
          background-color: var(--color-calendar-day-outside-hoverBg);
        }

        :global(.CalendarDay:not(.CalendarDay__selected_span):not(.CalendarDay__selected):hover) .date {
          color: var(--color-text);
        }

        :global(.CalendarDay__selected) .status,
        :global(.CalendarDay__selected_span) .status {
          color: var(--color-bg);
        }

        .date {
          position: relative;
          z-index: 1;
        }

        .isToday {
          font-weight: 700;
          color: var(--color-calendar-today);
          text-decoration: underline;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
