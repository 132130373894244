import useKeyboardEventListener, {KeyDownListenerMap} from '@octaved/ui-components/src/Hooks/UseKeyboardEventListener';
import classNames from 'classnames';
import {Dispatch, ReactElement, ReactNode, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {SearchHighlightText} from '../../Search/SearchHighlight';
import {useTaskListReadonlyContext} from '../TaskListReadonlyContext';

export interface OnEnterKeyDownOptions {
  blur: () => void;
}

export interface OnEnterKeyDown {
  (options: OnEnterKeyDownOptions): void;
}

export interface EditNameProps {
  className?: string;
  extendKeyDownMap?: KeyDownListenerMap;
  focused: boolean;
  fontWeight?: number;
  fullWidth?: boolean;
  name: string;
  nameInputTopSpacing?: number;
  onBlur?: () => void;
  onEnterKeyDown?: OnEnterKeyDown;
  patch: (name: string) => void;
  setFocused: Dispatch<SetStateAction<boolean>>;
  textAreaPlaceholder: string;
  viewNode: ReactNode;
}

export default function EditName({
  className,
  extendKeyDownMap,
  focused,
  fontWeight = 400,
  fullWidth,
  name,
  nameInputTopSpacing = 1,
  onBlur,
  onEnterKeyDown,
  patch,
  setFocused,
  textAreaPlaceholder,
  viewNode,
}: EditNameProps): ReactElement {
  const {t} = useTranslation();
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();

  const onKeyDownListener = useKeyboardEventListener<HTMLInputElement>({
    Enter: (e) => {
      patch(e.currentTarget.value);
      if (onEnterKeyDown) {
        onEnterKeyDown({
          blur: () => e.currentTarget.blur(),
        });
      }
    },
    ...extendKeyDownMap,
  });

  return (
    <div className={classNames(className, 'nameBox', {fullWidth})}>
      <div className={classNames(className, 'nameView', {notFocused: readonlyOrNotManageable || !focused})}>
        {!name && viewNode}
        {name && <SearchHighlightText text={name} />}
      </div>

      {!readonlyOrNotManageable && (
        <input
          autoFocus={focused}
          className={classNames(className, 'nameInput', {focused})}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
            setFocused(false);
          }}
          maxLength={191}
          type={'text'}
          onKeyDown={onKeyDownListener}
          value={name}
          onChange={(e) => patch(e.target.value)}
          placeholder={t(textAreaPlaceholder) || undefined}
          onClick={(e) => {
            e.stopPropagation();
            setFocused(true);
          }}
        />
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .nameBox {
          position: relative;
          overflow: hidden;
          min-width: 250px;
        }

        .fullWidth {
          width: 100%;
        }

        :global(.done:not(:hover) .cell:not(.focused)) .nameBox {
          opacity: var(--done-opacity);
        }

        .focused {
          color: #212121;
        }

        .nameView,
        .nameInput {
          overflow: hidden;
          font-size: 14px;
          line-height: 20px;
          white-space: pre;
          padding: 2px 4px;
        }

        .nameView {
          min-height: 1em;
          overflow: visible;
          padding: 3px 6px 2px 4px;
          border: 1px solid transparent;
          z-index: -1;
        }

        .notFocused .nameView {
          z-index: 1;
        }

        .nameInput {
          display: block;
          resize: none;
          height: 24px;
          border: 1px solid transparent;
          overflow: hidden;
          position: absolute;
          top: ${nameInputTopSpacing}px;
          width: 100%;
          border-radius: 0;
          background-color: transparent;
          font-weight: ${fontWeight};
          opacity: 0;
        }

        :global(.nameCell:hover) .nameInput,
        :global(.nameCell:focus-within) .nameInput {
          border-color: var(--color-border-hover);
          background-color: var(--color-bg);
          opacity: 1;

          &:hover {
            border-color: var(--color-border-focus);
          }
        }

        .nameInput:focus,
        .nameInput:hover:focus,
        :global(.nameCell:hover) .nameInput:focus {
          border-color: transparent;
          outline: transparent;
          background-color: var(--color-bg);
          opacity: 1;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
