import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {isTask, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getChildNodesSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {
  getTaskDepthSelector,
  getTaskIsAutoChainedSelector,
  getTaskSelector,
  getTasksPidIdSelector,
} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';

export const allLogicalPredecessorsCompletedSelector = createSelector(getNodeSelector, (getNode) =>
  memoize((id: Uuid) => {
    const node = getNode(id);
    if (node) {
      for (const predecessorId of node.planningLogicalPredecessors) {
        const predecessor = getNode(predecessorId);
        if (isTask(predecessor) && predecessor.status !== EnumFlowTaskStatus.VALUE_COMPLETE) {
          return false;
        }
        if (isWorkPackage(predecessor) && !predecessor.isCompleted) {
          return false;
        }
      }
    }
    return true;
  }),
);

export const getAutoChainLogicalPredecessorSelector = createSelector(
  getTaskDepthSelector,
  getTaskIsAutoChainedSelector,
  getTasksPidIdSelector,
  getChildNodesSelector,
  getTaskSelector,
  (getTaskDepth, getTaskIsAutoChained, getTasksPidId, getChildNodes, getTask) =>
    memoize((taskId: Uuid): Task | null => {
      const taskIsAutoChained = getTaskIsAutoChained(taskId);
      const depth = getTaskDepth(taskId);
      const task = getTask(taskId);
      if (taskIsAutoChained && depth === 1 && task) {
        const wpId = getTasksPidId(taskId);
        const children = getChildNodes(wpId);

        const previousTask =
          children
            .filter<Task>((node): node is Task => isTask(node) && node.sortOrder < task.sortOrder)
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .pop() || null;
        return previousTask;
      }
      return null;
    }),
);
