import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '../EntityInterfaces/Nodes';
import {isSubWorkPackage, isTask, isWorkPackage} from './NodeIdentifiers';

export function isNodeDone(node: NodeType | null | undefined): boolean | null {
  if (isWorkPackage(node) || isSubWorkPackage(node)) {
    return node.isCompleted;
  }
  if (isTask(node)) {
    return node.status === EnumFlowTaskStatus.VALUE_COMPLETE;
  }
  return null;
}
