import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {isEqual} from 'lodash';
import {getDaysFromStart, getWidth} from '../../../../Calculations/DateCalculations';
import {MinMaxPlanningDatesResult, getMinMaxPlanningDatesSelector} from '../../../../Selectors/PlanningDateSelectors';
import {Bar} from '../../../Bars/Bar';
import {RootContainer, RootContainerProps} from '../../../RootContainer';
import {createStoreSubscription} from '../../../StoreSubscription';
import {GanttContext} from '../../Context/GanttContext';
import {CurvedLine} from './DependencyLineShape';
import {calculateLinePoints} from './LineCalculation';

interface DependencyLineProps extends RootContainerProps<GanttContext> {
  color: string;
  dependencyType: EnumFlowPlanningDependencyType;
  index: number;
  predecessorId: Uuid;
  predecessorIndex: number;
  startInDays: number;
  dash?: number[];
  widthInDays: number;
  barHeight: number;
  yBarOffset: number;
}

export class DependencyLine extends RootContainer<GanttContext> {
  readonly #dependencyType: EnumFlowPlanningDependencyType;
  readonly #index: number;
  readonly #predecessorIndex: number;
  readonly #predecessorId: Uuid;
  readonly #startInDays: number;
  readonly #widthInDays: number;
  readonly #barHeight: number;
  readonly #yBarOffset: number;
  #predecessorMinMaxPlanning: MinMaxPlanningDatesResult | null = null;

  #line = new CurvedLine({strokeWidth: 1, radius: 3});
  #triangle = new Konva.Line({strokeWidth: 1, closed: true});

  constructor(props: DependencyLineProps) {
    super(props);

    this.#dependencyType = props.dependencyType;
    this.#index = props.index;
    this.#predecessorId = props.predecessorId;
    this.#startInDays = props.startInDays;
    this.#widthInDays = props.widthInDays;
    this.#predecessorIndex = props.predecessorIndex;
    this.#barHeight = props.barHeight;
    this.#yBarOffset = props.yBarOffset;

    this.#line.stroke(props.color);
    this.#triangle.fill(props.color);
    this.root.add(this.#line);
    this.root.add(this.#triangle);

    if (props.dash) {
      this.#line.dash(props.dash);
    }
  }

  init(): void {
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getMinMaxPlanningDatesSelector(s)(this.#predecessorId),
        (minMaxDates) => {
          this.#predecessorMinMaxPlanning = minMaxDates;
          this.#renderDependency();
        },
        isEqual,
      ),
    );
  }

  #renderDependency(): void {
    if (this.#predecessorMinMaxPlanning?.plannedStart) {
      const plannedStart = isoDateTimeToIsoFormat(this.#predecessorMinMaxPlanning.plannedStart);
      const plannedEnd = isoDateTimeToIsoFormat(this.#predecessorMinMaxPlanning.plannedEnd);
      const predecessorStart = getDaysFromStart(plannedStart, this.ctx.calendarView.dateStart);
      const predecessorWidth = getWidth(plannedStart, plannedEnd);
      const {linePath, trianglePath} = calculateLinePoints({
        predecessorStart,
        predecessorWidth,
        barHeight: this.#barHeight,
        barPaddingX: Bar.barPaddingX(this.ctx),
        barPaddingY: Bar.barPaddingY(this.ctx),
        cellHeight: this.ctx.rowHeight,
        cellWidth: this.ctx.columnWidth,
        dependencyType: this.#dependencyType,
        index: this.#index,
        predecessorIndex: this.#predecessorIndex,
        successorStart: this.#startInDays,
        successorWidth: this.#widthInDays,
        yBarOffset: this.#yBarOffset,
      });
      this.#line.points(linePath);
      this.#triangle.points(trianglePath);
    }
  }
}
