import {TWButton, TWButtonProps, cn} from '@octaved/ui';
import {ChevronDown, LucideIcon} from 'lucide-react';
import {ReactElement, ReactNode, forwardRef} from 'react';

export interface ActionTriggerProps extends Omit<TWButtonProps, 'variant'> {
  icon?: LucideIcon;
  label?: ReactNode;
  labelValue: ReactNode;
}

export const ActionTrigger = forwardRef<HTMLButtonElement, ActionTriggerProps>(function ActionTrigger(
  {icon: Icon, label, labelValue: value, className, ...props}: ActionTriggerProps,
  ref,
): ReactElement {
  return (
    <TWButton
      variant={'solid'}
      size={'sm'}
      colorScheme={'slate'}
      ref={ref}
      innerClassName={cn('max-w-[260px] overflow-hidden h-6', className)}
      withIcon
      {...props}
    >
      {Icon && <Icon className={'h-4 w-4 flex-shrink-0'} strokeWidth={1.5} />}
      {label && <span className={'flex-shrink-0 leading-4'}>{label}:</span>}
      <span className={'truncate font-semibold leading-4 text-current'}>{value}</span>
      <ChevronDown className={'ml-1 h-4 w-4 flex-shrink-0'} strokeWidth={1.5} />
    </TWButton>
  );
});
