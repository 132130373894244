import {schema} from 'normalizr';

export const node = new schema.Entity('node');

export const label = new schema.Entity('label');
export const kanbanBoard = new schema.Entity('kanbanBoard');
export const customer = new schema.Entity('customer');
export const priceCategory = new schema.Entity('priceCategory');
export const priceSurcharge = new schema.Entity('priceSurcharge');
export const timeRecord = new schema.Entity('timeRecord');
