import ComplexTrans from '@octaved/i18n/src/ComplexTrans';
import {Notification} from '@octaved/store/src/Notifications';
import {Icon} from '@octaved/ui';
import {Calendar} from 'lucide-react';
import {Toast, toast} from 'react-hot-toast';
import SnackbarWrapper from './SnackbarWrapper';

export function createPlanningErrorSnackbar(errors: Notification[]): void {
  for (const error of errors) {
    toast(
      (toastInstance: Toast) => (
        <SnackbarWrapper toastId={toastInstance.id}>
          <span>
            <ComplexTrans translatable={error.message} />
          </span>
        </SnackbarWrapper>
      ),
      {
        duration: Infinity,
        icon: (
          <Icon iconColor={'blue'}>
            <Calendar />
          </Icon>
        ),
        id: error.field,
      },
    );
  }
}

export function createPlanningWarningSnackbar(errors: Notification[]): void {
  for (const error of errors) {
    toast(
      (toastInstance: Toast) => (
        <SnackbarWrapper toastId={toastInstance.id}>
          <span>
            <ComplexTrans translatable={error.message} />
          </span>
        </SnackbarWrapper>
      ),
      {
        duration: Infinity,
        icon: (
          <Icon iconColor={'blue'}>
            <Calendar />
          </Icon>
        ),
        id: error.field,
      },
    );
  }
}
