import {EnumFlowGroupType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {useNodeColor, useTask} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {getTaskDepthSelector, getTaskIsAutoChainedSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Icon, Tooltip} from '@octaved/ui';
import {useDarkenColor} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import classNames from 'classnames';
import {CheckCircle2, Flame, Import, Link} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {
  allLogicalPredecessorsCompletedSelector,
  getAutoChainLogicalPredecessorSelector,
} from '../../../../../Selectors/LogicalDependencySelector';
import {getHasWarningSelector} from '../../../../../Selectors/PlanningWarningSelectors';
import ActionBar from './ActionBar';
import DependencyIcon from './DependencyIcon';
import {PlanningBarProps} from './PlanningBarProps';

export default function TaskBar({
  onClick,
  id,
  endCanBeResized,
  startCanBeResized,
  text,
  showText,
  readonly,
  canBeDragged,
  canDndDependency,
  isCreating,
  children,
  planningDate,
  isIndirectAssigned,
}: PlanningBarProps): ReactElement {
  const rootGroupType = useSelector((s: FlowState) => getRootGroupTypeSelector(s)(id));
  const isSprint = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
  const task = useTask(id);
  const isCompleted = task?.status === EnumFlowTaskStatus.VALUE_COMPLETE;

  const bottomColor = useNodeColor(id);
  const topColor = useDarkenColor(bottomColor, 5);
  const gradient = `linear-gradient(0deg, ${topColor} 0%, #${bottomColor} 100%)`;
  const completedAllPredecessors = useSelector((s: FlowState) => allLogicalPredecessorsCompletedSelector(s)(id));
  const hasLogicalDependencies = Boolean(
    task?.planningLogicalPredecessors.length || task?.planningLogicalSuccessors.length,
  );
  const isAutoChainActive = useSelector((s: FlowState) => getTaskIsAutoChainedSelector(s)(id));
  const isInAutoChain = useSelector((s: FlowState) => getTaskDepthSelector(s)(id) === 1);
  const isNextUp = useSelector((s: FlowState) => {
    const previousTask = getAutoChainLogicalPredecessorSelector(s)(id);
    return !previousTask || previousTask.status === EnumFlowTaskStatus.VALUE_COMPLETE;
  });
  const showLinkIconBecauseOfAutoChain = isInAutoChain && isAutoChainActive;
  const hasOpacity =
    isCreating || !completedAllPredecessors || (showLinkIconBecauseOfAutoChain && !isCompleted && !isNextUp);
  const warning = useSelector((s: FlowState) => getHasWarningSelector(s)(id, planningDate.id));
  const hasInheritedPlanning = task?.planningDates.length === 0;
  return (
    <ActionBar
      id={id}
      text={text}
      showText={showText}
      startCanBeResized={startCanBeResized}
      endCanBeResized={endCanBeResized}
      canBeDragged={canBeDragged}
      canDndDependency={canDndDependency}
      planningDate={planningDate}
      isCreating={isCreating}
      isIndirectAssigned={isIndirectAssigned}
      readonly={readonly}
    >
      <div
        className={classNames('wrapper', {isCompleted, isIndirectAssigned, isGhost: hasOpacity})}
        onClick={readonly ? undefined : onClick}
      >
        <div className={classNames('visibleBar', {readonly})}>
          {!isSprint && children}
          {isSprint && <div className={'line'} />}
        </div>
      </div>

      <div className={classNames('icon', {isGhost: hasOpacity})} onClick={readonly ? undefined : onClick}>
        <DependencyIcon nodeId={id} planningDateId={planningDate.id} />
        {(hasLogicalDependencies || showLinkIconBecauseOfAutoChain) && (
          <div className={'linkIcon blockedFullVisible'}>
            <Tooltip
              toolTipTranslation={'pages:projects.inspector.manage.planning.autoChainTooltip'}
              disabled={!showLinkIconBecauseOfAutoChain}
            >
              <Icon
                iconColor={
                  completedAllPredecessors &&
                  task?.status !== EnumFlowTaskStatus.VALUE_COMPLETE &&
                  (!showLinkIconBecauseOfAutoChain || isNextUp)
                    ? 'green'
                    : 'blue'
                }
                noMargin
              >
                <Link />
              </Icon>
            </Tooltip>
          </div>
        )}
        {!hasLogicalDependencies && !showLinkIconBecauseOfAutoChain && hasInheritedPlanning && (
          <div className={'linkIcon'}>
            <Icon iconColor={'black'} noMargin>
              <Import strokeWidth={1} />
            </Icon>
          </div>
        )}
        {warning && (
          <div className={classNames('linkIcon burningIcon')}>
            <Icon iconColor={'white'} size={'big'} noMargin>
              <Flame />
            </Icon>
          </div>
        )}
        {isCompleted && (
          <div className={classNames('linkIcon finishedIcon')}>
            <Icon iconColor={'white'} size={'big'} noMargin>
              <CheckCircle2 />
            </Icon>
          </div>
        )}
      </div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .wrapper {
          padding: 1px;
          border-radius: 4px;
          background: ${gradient};
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: 100%;
          transform: translateY(-50%);
          color: var(--color-grey-700);
          transition: opacity 120ms ease-in-out;
        }

        .icon {
          cursor: pointer;
        }

        .isCompleted {
          transition: opacity 120ms ease-in-out;

          &:hover {
            opacity: 1;
          }
        }

        .wrapper.isGhost,
        .wrapper.isGhost + .icon .linkIcon:not(.blockedFullVisible) {
          opacity: 0.4;
        }

        .wrapper.isCompleted,
        .wrapper.isCompleted + .icon .linkIcon:not(.finishedIcon) {
          opacity: 0.2;
        }

        .isIndirectAssigned {
          opacity: 0.7;
          filter: grayscale(0.7);
          transition:
            opacity 120ms ease-out,
            filter 120ms ease-out;
        }

        .isIndirectAssigned:hover {
          opacity: 1;
          filter: grayscale(0);
        }

        .wrapper.isGhost:hover,
        .wrapper.isGhost:hover + .icon .linkIcon,
        .wrapper.isCompleted:hover,
        .wrapper.isCompleted:hover + .icon .linkIcon {
          opacity: 1;
        }

        .visibleBar {
          border-radius: 3px;
          background: #f7f7f7;
          height: 100%;
          width: 100%;
        }

        .readonly {
          cursor: auto;
        }

        .icon {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .burningIcon {
          width: 16px;

          & :global(svg) {
            fill: #f00;
            stroke: #f00;
            stroke-width: 0;
          }
        }

        .finishedIcon {
          & :global(svg) {
            fill: var(--color-green-500);
          }
        }
      `}</style>
      {/*#endregion*/}
    </ActionBar>
  );
}
