import {nodeTreeSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {
  getOrgWorkMinutesAtDateSelector,
  getUnitWorkMinutesAtDateSelector,
} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {EntityStates} from '@octaved/store/src/EntityState';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {getUsersIdsForGroupSelector} from '@octaved/users/src/Selectors/GroupUserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {
  CalculatedDayWorkload,
  calculateWorkload,
  mergeWorkloadMaps,
  WorkloadCalculationContext,
  WorkloadMap,
} from '../Calculations/Workload';
import {Workloads} from '../EntityInterfaces/Workload';
import {PlanningState} from '../PlanningState';
import {myPlanningWorkloadDisplaymentSelector, teamPlanningWorkloadDisplaymentSelector} from './UiSelectors';

export const workloadStatesSelector = (state: PlanningState): EntityStates => state.entityStates.workloadState;
export const workloadSelector = (state: PlanningState): Workloads => state.entities.workload;

const calculateWorkloadMapForUserSelector = createSelector(
  workloadSelector,
  getUnitWorkMinutesAtDateSelector,
  getOrgWorkMinutesAtDateSelector,
  teamPlanningWorkloadDisplaymentSelector,
  nodeTreeSelector,
  (teamPlanningWorkload, getUnitWorkMinutesAtDate, getOrgWorkMinutesAtDate, workloadDisplayment, nodeTree) =>
    memoize(
      (userId: Uuid, visibleIds: Uuid[]): WorkloadMap => {
        const userWorkLoad = teamPlanningWorkload[userId];
        const workload: WorkloadMap = new Map();
        if (userWorkLoad) {
          const context: WorkloadCalculationContext = {
            getOrgWorkMinutesAtDate,
            nodeTree,
            userWorkLoad,
            calculatedWorkloadMap: workload,
            getWorkMinutesAtDate: getUnitWorkMinutesAtDate(userId),
            visibleIds: new Set(visibleIds),
          };
          calculateWorkload(workloadDisplayment, context);
        }
        return workload;
      },
      (userId: Uuid, visibleIds: Uuid[]) => `${userId}-${visibleIds.join('-')}`,
    ),
);

export const getWorkloadForUserSelector = createSelector(
  calculateWorkloadMapForUserSelector,
  (calculateWorkloadMapForUser) =>
    memoize(
      (userId: Uuid, visibleIds: Uuid[]): CalculatedDayWorkload[] => {
        const workload = calculateWorkloadMapForUser(userId, visibleIds);
        return [...workload.values()];
      },
      (userId: Uuid, visibleIds: Uuid[]) => `${userId}-${visibleIds.join('-')}`,
    ),
);

export const getWorkloadForGroupSelector = createSelector(
  getUnitWorkMinutesAtDateSelector,
  getUsersIdsForGroupSelector,
  calculateWorkloadMapForUserSelector,
  (getUnitWorkMinutesAtDate, getUsersIdsForGroup, calculateWorkloadMapForUser) =>
    memoize((groupId: Uuid): CalculatedDayWorkload[] => {
      const userIds = getUsersIdsForGroup(groupId);
      const workLoads: WorkloadMap[] = [];
      for (const userId of userIds) {
        const userWorkload = calculateWorkloadMapForUser(userId, []);
        workLoads.push(userWorkload);
      }
      const groupWorkload = mergeWorkloadMaps(getUnitWorkMinutesAtDate, userIds, ...workLoads);
      return [...groupWorkload.values()];
    }),
);

export const getMyCalendarWorkloadForUserSelector = createSelector(
  workloadSelector,
  getUnitWorkMinutesAtDateSelector,
  getOrgWorkMinutesAtDateSelector,
  myPlanningWorkloadDisplaymentSelector,
  currentOrgUserIdSelector,
  nodeTreeSelector,
  (teamPlanningWorkload, getUnitWorkMinutesAtDate, getOrgWorkMinutesAtDate, workloadDisplayment, userId, nodeTree) =>
    memoize(
      (visibleIds: Uuid[]): WorkloadMap => {
        const userWorkLoad = teamPlanningWorkload[userId];
        const workload: WorkloadMap = new Map();
        if (userWorkLoad) {
          const context: WorkloadCalculationContext = {
            getOrgWorkMinutesAtDate,
            nodeTree,
            userWorkLoad,
            calculatedWorkloadMap: workload,
            getWorkMinutesAtDate: getUnitWorkMinutesAtDate(userId),
            visibleIds: new Set(visibleIds),
          };
          calculateWorkload(workloadDisplayment, context);
        }
        return workload;
      },
      (visibleIds: Uuid[]) => visibleIds.join('-'),
    ),
);

export const getUserIdsForUnitSelector = createSelector(getUsersIdsForGroupSelector, (getUsersIdsForGroup) =>
  memoize(
    (unit: SlimUnit) => {
      if (unit.unitType === SimpleUnitType.user) {
        return [unit.unitId];
      }
      return getUsersIdsForGroup(unit.unitId);
    },
    (unit: SlimUnit) => unit.unitId,
  ),
);

export const getUserCountForUnitSelector = createSelector(getUserIdsForUnitSelector, (getUserIdsForUnit) =>
  memoize(
    (unit: SlimUnit) => {
      return getUserIdsForUnit(unit).length;
    },
    (unit: SlimUnit) => unit.unitId,
  ),
);

export const getWorkHourSumForUnitSelector = createSelector(
  getUnitWorkMinutesAtDateSelector,
  getUserIdsForUnitSelector,
  (getWorkMinutesAtDate, getUserIdsForUnit) => (unit: SlimUnit, date: DateStr) => {
    const userIds = getUserIdsForUnit(unit);
    const workMinutes = userIds.reduce((acc, userId) => acc + getWorkMinutesAtDate(userId)(date).project, 0);
    return workMinutes / 60;
  },
);

export const getWorkHoursForUnitSelector = createSelector(
  getUserCountForUnitSelector,
  getWorkHourSumForUnitSelector,
  (getUserCountForUnit, getWorkHourSumForUnit) => (unit: SlimUnit, date: DateStr) => {
    const workMinutes = getWorkHourSumForUnit(unit, date);
    const count = getUserCountForUnit(unit) || 1;
    return workMinutes / count;
  },
);
