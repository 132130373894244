import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {addUnit, removeUnit} from '@octaved/users/src/Modules/UnitLists';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {
  workPackageSingleSelectLeftDrawerUiState,
  WorkPackageSingleSelectLeftDrawerUiState,
} from '../../Pages/Components/LeftDrawer/ItemTypes/WorkPackage/WorkPackageLeftDrawer';
import {ThunkAct} from '../../Store/Thunk';
import {organizeBoardsTasksSelectedUnitsSelector} from '../Selectors/UiPages/OrganizeBoardsTasksSelectors';
import {setUiState} from '../Ui';

export interface OrganizeBoardsTasksUiState {
  leftDrawer: WorkPackageSingleSelectLeftDrawerUiState;
  selectedKanbanBoard: Uuid | null;
  selectedUnits: SlimUnit<SimpleUnitType.user>[];
}

export const initialOrganizeBoardsTasksUiState: OrganizeBoardsTasksUiState = {
  leftDrawer: workPackageSingleSelectLeftDrawerUiState,
  selectedKanbanBoard: null,
  selectedUnits: [],
};

export function patchOrganizeBoardsTasksUiState(
  organizeBoardsTasks: DeepPartial<OrganizeBoardsTasksUiState>,
): ThunkAct<void> {
  return setUiState({pages: {organizeBoardsTasks}});
}

export function setOrganizeBoardsTasksKanbanBoard(selectedKanbanBoard: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsTasksUiState({selectedKanbanBoard});
}

export function usePatchOrganizeBoardsTasksUiState(): (patch: DeepPartial<OrganizeBoardsTasksUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((patch) => dispatch(patchOrganizeBoardsTasksUiState(patch)), [dispatch]);
}

export function patchOrganizeBoardsTasksLeftDrawerUiState(
  patch: Partial<WorkPackageSingleSelectLeftDrawerUiState>,
): ThunkAct<void> {
  return patchOrganizeBoardsTasksUiState({leftDrawer: patch});
}

export function setOrganizeBoardsTasksSelectedPid(selected: Uuid | null): ThunkAct<void> {
  return patchOrganizeBoardsTasksLeftDrawerUiState({selected});
}

export function addSelectedUnit(unitId: Uuid, unitType: SimpleUnitType.user): ThunkAct<void> {
  return (dispatch, getState) => {
    const currentUnits = organizeBoardsTasksSelectedUnitsSelector(getState());
    dispatch(
      patchOrganizeBoardsTasksUiState({
        selectedUnits: addUnit(currentUnits, {unitId, unitType}),
      }),
    );
  };
}

export function removeSelectedUnit(unitId: Uuid): ThunkAct<void> {
  return (dispatch, getState) => {
    const currentUnits = organizeBoardsTasksSelectedUnitsSelector(getState());
    dispatch(
      patchOrganizeBoardsTasksUiState({
        selectedUnits: removeUnit(currentUnits, {unitId}),
      }),
    );
  };
}
