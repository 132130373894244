import {RefObject, useEffect, useRef} from 'react';

function isInViewport(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function useScrollIntoView<E extends HTMLElement>(scroll = true, delay = 0): RefObject<E> {
  const ref = useRef<E>(null);

  useEffect(() => {
    setTimeout(() => {
      if (scroll && ref.current && !isInViewport(ref.current)) {
        ref.current.scrollIntoView();
      }
    }, delay);
  }, [delay, scroll]);

  return ref;
}
