import {EntityState as SingleEntityState} from '@octaved/store/src/EntityState';
import {
  getAllAncestorsForNodeIds,
  getAllDescendantsForRootId,
  getAllDescendantsForRootIds,
  getParentsToChildrenMap,
  ParentToChildIdsMap,
} from '@octaved/trees/src/GenericTreeBuilder';
import {MaybeUuid, NoNullFields, Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, mapCache} from '@octaved/utilities';
import {Transform} from '@octaved/utilities/src/Condition/Types';
import memoize from 'lodash/memoize';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {NodeTree} from '../../EntityInterfaces/NodeTree';
import {Group, Project, WorkPackage} from '../../EntityInterfaces/Pid';
import {ProjectFolder} from '../../EntityInterfaces/ProjectFolder';
import {isResponsibleNode, ResponsibleNode} from '../../EntityInterfaces/ResponsibleNode';
import {SubWorkPackage} from '../../EntityInterfaces/SubWorkPackage';
import {Task} from '../../EntityInterfaces/Task';
import {TaskSection} from '../../EntityInterfaces/TaskSection';
import {UserNode} from '../../EntityInterfaces/UserNode';
import {
  isGroup,
  isProject,
  isProjectFolder,
  isSubWorkPackage,
  isTask,
  isTaskSection,
  isWorkPackage,
} from '../../Node/NodeIdentifiers';
import {FlowState} from '../State';
import {nodeEntitySelector} from './NodeSelectors';
import {isUserNode} from './UserNodeSelectors';

export type InvertedNodeTree = ParentToChildIdsMap<Uuid>;

export const nodeTreeSelector = (state: FlowState): NodeTree => state.nodeTree;
export const nodeTreeStateSelector = (state: FlowState): SingleEntityState => state.nodeTreeState;

export const nodeTreeInvertedSelector = createSelector(nodeTreeSelector, (nodeTree) =>
  getParentsToChildrenMap<Uuid>(nodeTree),
);

export const getAllDescendantIdsSelector = createSelector(nodeTreeSelector, (nodeTree) =>
  memoize(
    (rootId: string, includeSelf = false) => getAllDescendantsForRootId(nodeTree, rootId, includeSelf),
    (rootId: string, includeSelf = false) => `${rootId}@${+includeSelf}`,
  ),
);

export const extendWithAncestorsSelector = createSelector(
  nodeTreeSelector,
  (nodeTree): Transform<Uuid> => mapCache((nodeIds) => [...getAllAncestorsForNodeIds(nodeTree, nodeIds, true)]),
);

export const extendWithDescendantsSelector = createSelector(
  nodeTreeSelector,
  (nodeTree): Transform<Uuid> => mapCache((nodeIds) => [...getAllDescendantsForRootIds(nodeTree, nodeIds, true)]),
);

export const getAllAncestorsIdsSelector = createSelector(nodeTreeSelector, (nodeTree) =>
  memoize(
    (rootId: string, includeSelf = false) => getAllAncestorsForNodeIds(nodeTree, [rootId], includeSelf),
    (rootId: string, includeSelf = false) => `${rootId}@${+includeSelf}`,
  ),
);

const emptyIds: Uuid[] = [];

/**
 * WARNING: This method does not load any nodes!
 */
export const getChildNodesSelector = createSelector(
  nodeEntitySelector,
  nodeTreeInvertedSelector,
  (nodes, nodeTreeInverted) =>
    memoize((parentId: Uuid | null): NodeType[] => {
      const childIds = nodeTreeInverted.get(parentId) || emptyIds;
      return boolFilter(childIds.map((id) => nodes[id]));
    }),
);

/**
 * WARNING: This method does not load any nodes!
 *
 * @deprecated use a combination of useNodeSearch('descendantsOf') and useLoadedNodes() to get the exact nodes you want!
 */
export const getDescendantNodesSelector = createSelector(
  nodeEntitySelector,
  getAllDescendantIdsSelector,
  (nodes, getAllDescendantIds) =>
    memoize((parentId: Uuid) => {
      const childIds = getAllDescendantIds(parentId);
      return boolFilter([...childIds].map((id) => nodes[id]));
    }),
);

export const idPathSelector = createSelector(nodeTreeSelector, (nodeTree) =>
  memoize((id: Uuid | null): ReadonlyArray<Uuid> => {
    if (!id) {
      return [];
    }
    const idPath: Uuid[] = [id];
    let parent = nodeTree[id];
    while (parent) {
      idPath.push(parent);
      parent = nodeTree[parent];
    }
    idPath.reverse();
    return idPath;
  }),
);

export const getParentIdSelector = createSelector(nodeTreeSelector, (tree) =>
  memoize((id: Uuid | null) => (id ? tree[id] : null)),
);

export const getDepthSelector = createSelector(
  idPathSelector,
  (idPath) => (id: Uuid | null | undefined) => (id ? idPath(id).length : 0),
);

export interface NodeAncestry {
  readonly ancestors: ReadonlyArray<NodeType>; //all ancestors beginning with the closest (depending on `includeSelf`)
  readonly groups: ReadonlyArray<Group>;
  readonly path: ReadonlyArray<NodeType>; //same as ancestors, but reversed
  readonly project: Project | null;
  readonly projectFolders: ReadonlyArray<ProjectFolder>;
  readonly responsibleNodes: ReadonlyArray<ResponsibleNode>;
  readonly subWorkPackage: SubWorkPackage | null;
  readonly taskSection: TaskSection | null;
  readonly tasks: ReadonlyArray<Task>;
  readonly userNode: UserNode | null;
  readonly workPackage: WorkPackage | null;
}

export interface GetNodeAncestry {
  (id: MaybeUuid, includeSelf?: boolean): NodeAncestry;
}

/**
 * Gets the loaded node ancestry.
 * For arrays, the closest parent is always first.
 */
export const getNodeAncestrySelector = createSelector(
  nodeTreeSelector,
  nodeEntitySelector,
  (nodeTree, nodeEntities): GetNodeAncestry =>
    memoize(
      (id, includeSelf = false) => {
        let parentId = includeSelf ? id : nodeTree[id!];
        const ancestors: NodeType[] = [];
        const responsibleNodes: ResponsibleNode[] = [];
        const projectFolders: ProjectFolder[] = [];
        let project: Project | null = null;
        const groups: Group[] = [];
        let workPackage: WorkPackage | null = null;
        let subWorkPackage: SubWorkPackage | null = null;
        let taskSection: TaskSection | null = null;
        let userNode: UserNode | null = null;
        const tasks: Task[] = [];
        while (parentId) {
          const parent = nodeEntities[parentId];
          if (parent) {
            ancestors.push(parent);
          }
          if (isResponsibleNode(parent)) {
            responsibleNodes.push(parent);
          }
          if (isProjectFolder(parent)) {
            projectFolders.push(parent);
          } else if (isProject(parent)) {
            project = parent;
          } else if (isGroup(parent)) {
            groups.push(parent);
          } else if (isWorkPackage(parent)) {
            workPackage = parent;
          } else if (isSubWorkPackage(parent)) {
            subWorkPackage = parent;
          } else if (isTaskSection(parent)) {
            taskSection = parent;
          } else if (isTask(parent)) {
            tasks.push(parent);
          } else if (isUserNode(parent)) {
            userNode = parent;
          }
          parentId = nodeTree[parentId];
        }
        return {
          ancestors,
          groups,
          project,
          projectFolders,
          responsibleNodes,
          subWorkPackage,
          tasks,
          taskSection,
          userNode,
          workPackage,
          path: ancestors.toReversed(),
        };
      },
      (id, includeSelf = false): string => `${id}-${+includeSelf}`,
    ),
);

/**
 * No default for includeSelf because this is a common pitfall if not considered.
 */
export function useNodeAncestry(nodeId: MaybeUuid, includeSelf: boolean): NodeAncestry {
  return useSelector((s: FlowState) => getNodeAncestrySelector(s)(nodeId, includeSelf));
}

export const getParentNodeSelector = createSelector(
  getNodeAncestrySelector,
  (getNodeAncestry) =>
    (nodeId: Uuid): NodeType | undefined =>
      getNodeAncestry(nodeId, false).ancestors[0],
);

export type NodeAncestryWithWp = NodeAncestry & NoNullFields<Pick<NodeAncestry, 'project' | 'workPackage'>>;

export function isNodeAncestryWithWp(ancestry: NodeAncestry): ancestry is NodeAncestryWithWp {
  return !!ancestry.workPackage && !!ancestry.project;
}

export function getNodeAncestryWithWp(ancestry: NodeAncestry): NodeAncestryWithWp | null {
  return isNodeAncestryWithWp(ancestry) ? ancestry : null;
}

export const getNodeColorSelector = createSelector(getNodeAncestrySelector, (getNodeAncestry) =>
  memoize(
    (id: MaybeUuid, defaultColor = '666666'): string =>
      getNodeAncestry(id, true).ancestors.find((anc) => !!anc.color)?.color || defaultColor,
    (id, defaultColor) => `${id}-${defaultColor}`,
  ),
);
