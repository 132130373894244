import {resendVerificationEmail} from '@octaved/identity/config/routes';
import {
  identityEmailSelector,
  identityIsEmailVerifiedSelector,
} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {post} from '@octaved/network/src/Network';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PageStatusBar from '../../Pages/PageStatusBar';
import Link from '../Link';

export default function UserVerificationStatus(): ReactElement | null {
  const userIsNotVerified = !useSelector(identityIsEmailVerifiedSelector);
  const email = useSelector(identityEmailSelector);
  const {t} = useTranslation();
  const [wasResend, setWasResend] = useState(false);

  if (userIsNotVerified) {
    return (
      <PageStatusBar color={'var(--color-blue-600)'}>
        <div>{t('general:unverfiedText', {email})} </div>
        {!wasResend && (
          <Link
            onDark
            underline
            onClick={() => {
              post(resendVerificationEmail);
              setWasResend(true);
            }}
          >
            {t('general:unverfiedLink')}
          </Link>
        )}
        {wasResend && <span>{t('general:unverfiedResend')}</span>}
      </PageStatusBar>
    );
  }

  return null;
}
