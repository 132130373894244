import {MutableRefObject} from 'react';

export function createDummyRef<T>(value: T): MutableRefObject<T> {
  return {
    get current() {
      return value;
    },
    set current(_) {
      //nothing
    },
  };
}
