import {TWAutosaveTextarea} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from './Type';

export interface NodeDescriptionProps {
  description: string;
  readonly?: boolean;
  setDescription: (description: string) => void;
}

export default function NodeDescription({
  description,
  frame: Frame,
  readonly,
  setDescription,
}: NodeDescriptionProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Frame labelToken={'nodeDetails:field.description.label'}>
      {!readonly && (
        <TWAutosaveTextarea
          value={description}
          onSave={setDescription}
          required={false}
          placeholder={t('nodeDetails:field.description.placeholder')}
        />
      )}
      {readonly && <div>{description}</div>}
    </Frame>
  );
}
