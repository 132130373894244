import '@fortawesome/fontawesome-pro/css/solid.min.css';
import {HelpTooltip, Label} from '@octaved/ui';
import {ReactElement} from 'react';

import ColorPickerWithPalette, {LabelColors} from '../Form/ColorPicker/ColorPickerWithPalette';

interface Props {
  color: string;
  isEditing?: boolean;
  patch: (color: string) => void;
  readonly?: boolean;
  withoutLabel?: boolean;
}

export default function PidColor({color, isEditing, readonly, patch, withoutLabel}: Props): ReactElement {
  return (
    <div>
      {!withoutLabel && (
        <Label
          token={'general:pid.iconColor'}
          addition={<HelpTooltip toolTipTranslation={'tooltips:drawer.projectGeneral.helpIconColor'} />}
        />
      )}
      <ColorPickerWithPalette
        isEditing={isEditing}
        readonly={readonly}
        palette={LabelColors}
        color={'#' + color}
        setColor={(color) => patch(color.substr(1))}
      />
    </div>
  );
}
