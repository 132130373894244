import {warning} from '@octaved/env/src/Logger';
import {EntityStates, hasLoadedOnce} from '@octaved/store/src/EntityState';
import {DateStr, isDateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {
  StoreTrackedTimePerDay,
  StoreTrackedTimeQuotas,
  TrackedTimePerWorkPackage,
} from '../../../../EntityInterfaces/Projects/ProjectControlling/TrackedTime';
import {earliestIsoDate, latestIsoDate, todayIsoDateSelector} from '../../../../Today';
import {FlowState} from '../../../State';
import {
  getProjectsFilterStatesSelector,
  getProjectsFilterValueSelector,
  getWorkPackageIdsForRootNodeIdInMainProjectTreeSelector,
} from '../../UiPages/ProjectsSelector';

export const trackedTimePerDaySelector = (state: FlowState): StoreTrackedTimePerDay =>
  state.projectControlling.trackedTimePerDay.data;
export const trackedTimePerDayStatesSelector = (state: FlowState): EntityStates =>
  state.projectControlling.trackedTimePerDay.state;
const trackedTimePerWorkPackageSelector = (state: FlowState): TrackedTimePerWorkPackage =>
  state.projectControlling.trackedTimePerWorkPackage.data;
export const trackedTimePerWorkPackageStatesSelector = (state: FlowState): EntityStates =>
  state.projectControlling.trackedTimePerWorkPackage.state;
export const trackedTimeQuotasSelector = (state: FlowState): StoreTrackedTimeQuotas =>
  state.projectControlling.trackedTimeQuotas.data;
export const trackedTimeQuotasStatesSelector = (state: FlowState): EntityStates =>
  state.projectControlling.trackedTimeQuotas.state;

function getValidCustomDate(date: string, fallback: DateStr): DateStr {
  if (isDateStr(date)) {
    return date;
  }
  warning(`Invalid date '${date}' in filter, falling back to '${fallback}'`); //not sure if this warning is needed
  return fallback;
}

export const trackedTimeQuickFilterValueSelector = createSelector(
  getProjectsFilterValueSelector,
  (getProjectsFilterValue) => getProjectsFilterValue('controlTrackedTime', 'quickControlTrackedTime'),
);

const trackedTimeActiveLabelIdsSelector = createSelector(
  getProjectsFilterStatesSelector,
  (getProjectsFilterStates): Uuid[] => {
    const {advancedControlTrackedTimeLabelIds} = getProjectsFilterStates('controlTrackedTime');
    if (advancedControlTrackedTimeLabelIds && advancedControlTrackedTimeLabelIds.isActive) {
      return advancedControlTrackedTimeLabelIds.value;
    }
    return [];
  },
);

const trackedTimeFromToSelector = createSelector(
  trackedTimeQuickFilterValueSelector,
  todayIsoDateSelector,
  (filter, todayIsoDate) => {
    let from = earliestIsoDate;
    let to = latestIsoDate;
    switch (filter.timePeriod) {
      case 'last7days':
        from = dayjs(todayIsoDate).subtract(7, 'day').format(ISO_DATE) as DateStr;
        to = todayIsoDate;
        break;
      case 'last30days':
        from = dayjs(todayIsoDate).subtract(30, 'day').format(ISO_DATE) as DateStr;
        to = todayIsoDate;
        break;
      case 'currentMonth':
        from = dayjs(todayIsoDate).startOf('month').format(ISO_DATE) as DateStr;
        to = dayjs(todayIsoDate).endOf('month').format(ISO_DATE) as DateStr;
        break;
      case 'lastMonth':
        from = dayjs(todayIsoDate).startOf('month').subtract(1, 'month').format(ISO_DATE) as DateStr;
        to = dayjs(todayIsoDate).startOf('month').subtract(1, 'month').endOf('month').format(ISO_DATE) as DateStr;
        break;
      case 'last12Months':
        from = dayjs(todayIsoDate).startOf('month').subtract(12, 'month').format(ISO_DATE) as DateStr;
        to = todayIsoDate;
        break;
      case 'custom':
        from = getValidCustomDate(filter.timePeriodCustomFrom, from);
        to = getValidCustomDate(filter.timePeriodCustomTo, to);
        break;
      default:
      //all
    }
    return {from, to};
  },
);

export interface TrackedTimeRequestFilterOptions {
  from: string;
  labelIds: Uuid[];
  to: string;
  unitIds: Uuid[];
}

export const defaultTrackedTimeRequestFilterOptions: TrackedTimeRequestFilterOptions = {
  from: earliestIsoDate,
  labelIds: [],
  to: latestIsoDate,
  unitIds: [],
};

export function createTrackedTimeRequestFilterKey(options: TrackedTimeRequestFilterOptions): string {
  return `${options.from}@${options.to}@${[...options.labelIds].sort().join('-')}@${options.unitIds.join('-')}`;
}

export const trackedTimeRequestFilterOptionsSelector = createSelector(
  trackedTimeQuickFilterValueSelector,
  trackedTimeFromToSelector,
  trackedTimeActiveLabelIdsSelector,
  ({units}, {from, to}, labelIds): TrackedTimeRequestFilterOptions => {
    const unitIds = units.map(({unitId}) => unitId).sort();
    return {from, labelIds, to, unitIds};
  },
);

export const trackedTimeRequestFilterSelector = createSelector(
  trackedTimeRequestFilterOptionsSelector,
  trackedTimeFromToSelector,
  trackedTimeActiveLabelIdsSelector,
  (options) => ({...options, filterKey: createTrackedTimeRequestFilterKey(options)}),
);

export const getTrackedTimePerWorkPackageKeySelector = createSelector(
  trackedTimeRequestFilterSelector,
  ({filterKey}) =>
    (wpId: Uuid): string =>
      `${filterKey}@${wpId}`, //If this pattern is changed, the reducers in TrackedTimePerWorkPackage.ts
  // have to be adjusted!
);

export const getTrackedTimePerWorkPackageSelector = createSelector(
  trackedTimePerWorkPackageSelector,
  getTrackedTimePerWorkPackageKeySelector,
  (trackedTimePerWorkPackage, keyGen) => (wpId: Uuid) => trackedTimePerWorkPackage[keyGen(wpId)] || 0,
);

const getTrackedTimePerWorkPackageStateSelector = createSelector(
  trackedTimePerWorkPackageStatesSelector,
  getTrackedTimePerWorkPackageKeySelector,
  (states, keyGen) => (wpId: Uuid) => states[keyGen(wpId)],
);

/**
 * Gets the sum of tracked time in the visible subtree.
 * Return undefined if any id has not loaded, yet.
 */
export const getTrackedTimeSumPerNodeSelector = createSelector(
  getWorkPackageIdsForRootNodeIdInMainProjectTreeSelector,
  getTrackedTimePerWorkPackageSelector,
  getTrackedTimePerWorkPackageStateSelector,
  (getWorkPackageIds, getTrackedTime, getState) =>
    memoize((nodeId: Uuid): number | undefined => {
      return getWorkPackageIds(nodeId).reduce<number | undefined>((acc, wpId) => {
        const trackedTime = getTrackedTime(wpId);
        const state = getState(wpId);
        if (typeof acc === 'undefined' || !state || !hasLoadedOnce(state)) {
          return undefined;
        }
        return acc + trackedTime;
      }, 0);
    }),
);

export const workPackageIdsWithTrackedTimeSelector = createSelector(
  trackedTimePerWorkPackageSelector,
  trackedTimeRequestFilterSelector,
  (trackedTimePerWorkPackage, {filterKey}): Uuid[] => {
    const prefix = `${filterKey}@`;
    const prefixLength = prefix.length;
    return Object.entries(trackedTimePerWorkPackage).reduce<Uuid[]>((acc, [key, sum]) => {
      if (key.startsWith(prefix) && sum) {
        acc.push(key.substring(prefixLength));
      }
      return acc;
    }, []);
  },
);
