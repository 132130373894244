import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {RootFolders} from '../EntityInterfaces/RootFolders';
import {FLOW_INIT_LOAD_SUCCESS} from './ActionTypes';

import {InitAction} from './Initialization/Actions';

export const reducerMap = new Map();

reducerMap.set(FLOW_INIT_LOAD_SUCCESS, (rootFolder: RootFolders, action: InitAction): RootFolders => {
  if (action.isInOrganization) {
    return action.response.result.rootFolders;
  }
  return rootFolder;
});
export const rootFoldersReducer = ReduceFromMap<RootFolders>(reducerMap, {
  materialResourceFolder: '',
  projectFolder: '',
  userFolder: '',
});
