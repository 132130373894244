import {EntityStates} from '@octaved/store/src/EntityState';
import {getInstanceUuid} from '@octaved/utilities';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import type {WebsocketEvent} from '@octaved/websocket';
import {Action, AnyAction} from 'redux';
import {NodeSearchIdent} from '../../EntityInterfaces/NodeSearch';
import {reduceClearKeys} from '../NodeSearch';
import {FlowState} from '../State';

type ResultsReducer = (state: UuidSearchResults) => UuidSearchResults;
type StatesReducer = (state: EntityStates) => EntityStates;
type Reducers = {results?: ResultsReducer; states?: StatesReducer};
type Handler = 'clear' | 'clearIfNotInitiator' | 'handled' | 'ignored' | Reducers;
export type GetHandler<Event> = (action: Event, fullState: FlowState) => Handler;
type Listeners<Event> = Record<NodeSearchIdent, Handler | GetHandler<Event>>;

function isReducers(handler: Handler): handler is Reducers {
  return typeof handler !== 'string';
}

function isActionWithInstanceId(action: AnyAction): action is Action & WebsocketEvent {
  return typeof action.responsibleInstanceId === 'string';
}

const eventListeners = new Map<string, Listeners<unknown>>();

export function registerListeners<Event extends Action>(event: Event['type'], listeners: Listeners<Event>): void {
  if (eventListeners.has(event)) {
    throw new Error('Duplicate registration of search listener events is not supported!');
  }
  eventListeners.set(event, listeners as Listeners<unknown>);
}

export function nodeSearchListenersRootReducer(state: FlowState, action: Action): FlowState {
  let newState = state;
  const listeners = eventListeners.get(action.type);
  if (listeners) {
    //Pre-filter handlers that won't do anything at all:
    const listenersArray = (Object.entries(listeners) as Array<[NodeSearchIdent, Handler | GetHandler<Action>]>).filter(
      ([, handler]) => handler !== 'ignored' && handler !== 'handled',
    );

    const searchResults = listenersArray.reduce<UuidSearchResults>((acc, [, getHandler]) => {
      const handler = typeof getHandler === 'function' ? getHandler(action, state) : getHandler;
      return isReducers(handler) && handler.results ? handler.results(acc) : acc;
    }, state.nodeSearch.searchResults);

    let searchState = state.nodeSearch.searchState;
    const keysToClear = listenersArray.reduce<NodeSearchIdent[]>((acc, [key, getHandler]) => {
      const handler = typeof getHandler === 'function' ? getHandler(action, state) : getHandler;
      if (isReducers(handler) && handler.states) {
        searchState = handler.states(searchState);
      } else if (handler === 'clear') {
        acc.push(key);
      } else if (
        handler === 'clearIfNotInitiator' &&
        (!isActionWithInstanceId(action) || action.responsibleInstanceId !== getInstanceUuid())
      ) {
        acc.push(key);
      }
      return acc;
    }, []);
    searchState = reduceClearKeys(keysToClear)(searchState, action);

    if (state.nodeSearch.searchResults !== searchResults || state.nodeSearch.searchState !== searchState) {
      newState = {...newState, nodeSearch: {searchResults, searchState}};
    }
  }
  return newState;
}
