import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {PropsWithChildren, ReactElement, useContext} from 'react';
import {inspectorContext} from '../Drawer/InspectorContext/InspectorContext';

export default function InspectorContextLoadingWrapper({children}: PropsWithChildren<unknown>): ReactElement {
  const context = useContext(inspectorContext);
  if (context) {
    return <>{children}</>;
  }
  return <AppLoader />;
}
