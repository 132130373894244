import {hasFeature} from '@octaved/env/src/Features';
import {HStack} from '@octaved/ui';
import {ReactElement} from 'react';
import CreateGroupButton from './CreateGroupButton';
import CreateProjectButton from './CreateProjectButton';
import CreateSubWorkPackageButton from './CreateSubWorkPackageButton';
import CreateWorkPackageButton from './CreateWorkPackageButton';

export default function CreatePidButtonsHStack(): ReactElement | null {
  return (
    <HStack spacing={'15px'}>
      <CreateProjectButton />
      <CreateGroupButton />
      <CreateWorkPackageButton />
      {hasFeature('subWorkPackages') && <CreateSubWorkPackageButton />}
    </HStack>
  );
}
