import CheckCircleSvg from '@fortawesome/fontawesome-pro/svgs/solid/check-circle.svg';
import CloudUploadAltSvg from '@fortawesome/fontawesome-pro/svgs/solid/cloud-upload-alt.svg';
import {Uuid} from '@octaved/typescript/src/lib';
import {Icon, TWButton} from '@octaved/ui';
import {ResultStatus, WebhookCallResult} from '@octaved/webservice/src/Modules/Webhooks';
import WebhookCallResultView from '@octaved/webservice/src/Webhook/WebhookCallResultView';
import {ReactElement, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {transmitOffers} from '../../../../Modules/PidOffer';

export default function TransmitButton({pidId, includeTasks}: {includeTasks: boolean; pidId: Uuid}): ReactElement {
  const {t} = useTranslation();
  const [isTransmitting, setTransmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [calls, setCalls] = useState<WebhookCallResult[]>([]);
  const isTransmittingRef = useRef(isTransmitting);
  const onClick = useCallback(async () => {
    if (isTransmittingRef.current) {
      return;
    }
    isTransmittingRef.current = true;
    setTransmitting(isTransmittingRef.current);
    setSuccess(false);
    setCalls([]);
    try {
      const results = await transmitOffers(pidId, includeTasks);
      const failedCalls = Object.values(results || []).filter(({status}) => status !== ResultStatus.STATUS_OK);
      setCalls(failedCalls);
      setSuccess(failedCalls.length === 0);
    } finally {
      isTransmittingRef.current = false;
      setTransmitting(isTransmittingRef.current);
    }
  }, [includeTasks, pidId]);
  return (
    <>
      <div className={'flex'}>
        <TWButton
          withIcon
          size={'md'}
          isLoading={isTransmitting}
          disabled={isTransmitting}
          onClick={onClick}
          variant={'solid'}
          colorScheme={'primary'}
        >
          <CloudUploadAltSvg className={'size-4'} />
          {t('pages:projects.inspector.offer.export.transmitButton')}
        </TWButton>
      </div>

      {success && (
        <Icon iconColor={'green'} size={'bigger'} noMargin>
          <CheckCircleSvg />
        </Icon>
      )}
      {calls.map((call, idx) => (
        <WebhookCallResultView key={idx} call={call} />
      ))}
    </>
  );
}
