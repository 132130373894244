import {error} from '@octaved/env/src/Logger';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {Pid, Project} from '../../EntityInterfaces/Pid';
import {FlowState} from '../State';
import {isBoardPost} from './BoardPostSelectors';
import {
  isGroup,
  isMaterialResourceFolder,
  isPid,
  isProject,
  isSubWorkPackage,
  isTask,
  isTaskSection,
  isWorkPackage,
} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector} from './NodeSelectors';
import {getAllDescendantIdsSelector, getNodeAncestrySelector, nodeTreeSelector} from './NodeTreeSelectors';

function typedNodeSelectorFactory<T>(
  validator: (node: unknown) => node is T,
  expected: string,
): (state: FlowState) => (id: Uuid | undefined | null) => T | undefined {
  return createSelector(nodeEntitySelector, (nodes) =>
    memoize((id) => {
      const node = nodes[id!];
      if (node && !validator(node)) {
        error(new Error(`${expected} is requested but '${node?.nodeType}' type is returned`));
        return undefined;
      }
      return node;
    }),
  );
}

export const getPidSelector = typedNodeSelectorFactory(isPid, 'Pid');
export const getProjectSelector = typedNodeSelectorFactory(isProject, 'Project');
export const getGroupSelector = typedNodeSelectorFactory(isGroup, 'Group');
export const getWorkPackageSelector = typedNodeSelectorFactory(isWorkPackage, 'Work package');
export const getSubWorkPackageSelector = typedNodeSelectorFactory(isSubWorkPackage, 'Sub work package');

/**
 * Gets the parent pid. For nested tasks, this is not necessarily the direct parent.
 */
export const getParentPidSelector = createSelector(nodeTreeSelector, nodeEntitySelector, (nodeTree, nodes) =>
  memoize((id: Uuid | undefined): Pid | null => {
    let parentId = id && nodeTree[id];
    while (parentId) {
      const node = nodes[parentId];
      if (!node) {
        return null; //parents not loaded, yet, no need to go further up the tree
      }
      if (isPid(node)) {
        return node;
      }
      parentId = nodeTree[parentId];
    }
    return null;
  }),
);

/**
 * @see getNodeAncestrySelector for an alternative yielding entities
 */
export const pathSelector = createSelector(nodeTreeSelector, nodeEntitySelector, (nodeTree, nodes) =>
  memoize(
    (id: Uuid, until?: Uuid) => {
      let parent = id;
      const path = [];
      do {
        const parentNode = nodes[parent];
        if (
          isBoardPost(parentNode) ||
          isPid(parentNode) ||
          isTask(parentNode) ||
          isTaskSection(parentNode) ||
          isMaterialResourceFolder(parentNode)
        ) {
          path.push(parent);
        }
        parent = nodeTree[parent] as Uuid;
      } while (parent && parent !== until);

      return path.reverse() as ReadonlyArray<Uuid>;
    },
    (id: Uuid, until?: Uuid) => `${id}-${until}`,
  ),
);

export const getPidsByIdsSelector = createSelector(nodeEntitySelector, (nodes) =>
  memoize((ids: Uuid[]) => ids.map((id) => nodes[id]).filter(isPid)),
);

export const getProjectForNodeSelector = createSelector(
  getNodeAncestrySelector,
  (getNodeAncestry) =>
    (id: Uuid): Project | null =>
      getNodeAncestry(id, true).project,
);

/**
 * @deprecated use getProjectForNodeSelector
 */
export const getProjectForPidSelector = createSelector(getProjectForNodeSelector, (getProjectForNode) =>
  memoize((id: Uuid): Project | undefined => getProjectForNode(id) || undefined),
);

export const getDueDateSelector = createSelector(nodeTreeSelector, nodeEntitySelector, (nodeTree, nodes) =>
  memoize((id: Uuid): DateStr | null => {
    let parent = id;
    do {
      const node = nodes[parent];
      if (isPid(node) && node.dueDate) {
        return node.dueDate;
      }
      parent = nodeTree[parent] as Uuid;
    } while (parent);

    return null;
  }),
);

export const countDescendantOffersSelector = createSelector(
  nodeEntitySelector,
  getAllDescendantIdsSelector,
  (nodes, getAllDescendantIds) =>
    memoize((id: Uuid | null): number => {
      if (!id) {
        return 0;
      }

      return [...getAllDescendantIds(id, true)].filter((childId) => {
        const child = nodes[childId];
        return child && isWorkPackage(child) && child.isOffer;
      }).length;
    }),
);

export const hasOffersSelector = createSelector(countDescendantOffersSelector, (countsSelector) =>
  memoize((id: Uuid | null): boolean => {
    if (!id) {
      return false;
    }

    return countsSelector(id) > 0;
  }),
);
