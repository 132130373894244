import ComplexTrans, {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import classNames from 'classnames';
import {CSSProperties, forwardRef, ReactElement, ReactNode} from 'react';
import {Ref, Button as SemanticButton, StrictButtonProps as SemanticButtonProps} from 'semantic-ui-react';

export type ButtonVariant = 'solid' | 'ghost' | 'outline';
export type ButtonColorScheme = 'blue' | 'secondary' | 'gray' | 'add' | 'cancel' | 'delete' | 'green' | 'register';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type ButtonProps = Omit<SemanticButtonProps, 'size' | 'basic' | 'disabled' | 'icon'> & {
  children?: ReactNode;
  colorScheme?: ButtonColorScheme;
  cursor?: CSSProperties['cursor'];
  iconOnly?: boolean;
  iconStrokeWidth?: CSSProperties['strokeWidth'];
  isActive?: boolean;
  isDisabled?: boolean;
  label?: ComplexTranslatable;
  labelCount?: number;
  size?: ButtonSize;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
};

const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      children,
      className: propClassName,
      colorScheme = 'blue',
      cursor = 'pointer',
      iconOnly,
      iconStrokeWidth = 1,
      isActive,
      isDisabled,
      label,
      labelCount = 0,
      size = 'md',
      variant = 'solid',
      type = 'button',
      ...semanticButtonProps
    },
    ref,
  ): ReactElement => {
    return (
      <Ref innerRef={ref}>
        <SemanticButton
          className={classNames(
            propClassName,
            'flowButton',
            `flowButton--${colorScheme}`,
            `flowButton--${size}`,
            `flowButton--${variant}`,
            `flowButton--cursor-${cursor}`,
            `flowButton--iconStrokeWidth-${iconStrokeWidth}`,
            {
              [`flowButton--active`]: isActive,
              [`flowButton--iconOnly`]: iconOnly,
              [`flowButton--relative`]: Boolean(labelCount),
            },
            '!transition-transform duration-75 active:scale-95',
          )}
          basic={variant === 'outline'}
          disabled={isDisabled}
          type={type}
          {...semanticButtonProps}
        >
          {label && <ComplexTrans translatable={label} />}
          {labelCount > 0 && (
            <div className={'labelCount'}>
              {labelCount}

              <style jsx>{`
                .labelCount {
                  position: absolute;
                  top: -0.8em;
                  right: -0.8em;
                  height: 2em;
                  width: 2em;
                  border-radius: 1em;
                  background-color: #fff;
                  border: 2px solid var(--button-color);
                  font-weight: 600;
                  color: var(--button-color);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  line-height: 10px;
                }
              `}</style>
            </div>
          )}
          {children}
        </SemanticButton>
      </Ref>
    );
  },
);

Button.displayName = 'Button';

export {Button};
