import {getTaskIsAutoChainedSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {PlanningRealizationNode} from '@octaved/planning/src/EntityInterfaces/PlanningRealizationNode';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import AddLogicalDependency from './LogicalDependencies/AddLogicalDependency';
import AutoChain from './LogicalDependencies/AutoChain';
import NodeList from './LogicalDependencies/List/NodeList';

export interface NodeLogicalDependenciesProps {
  node: PlanningRealizationNode;
  readonly: boolean;
}

export default function NodeLogicalDependencies({
  frame: Frame,
  node,
  readonly,
}: NodeLogicalDependenciesProps & FramedSectionOrTileProps): ReactElement {
  const isAutoChainActive = useSelector(getTaskIsAutoChainedSelector)(node.id);

  return (
    <Frame labelToken={'pages:projects.inspector.manage.planning.logicalDependency'}>
      <div>
        {isAutoChainActive && <AutoChain />}
        {!isAutoChainActive && (
          <NodeList node={node} readonly={readonly} dependencyList={node.planningLogicalPredecessors} />
        )}
        {!readonly && !isAutoChainActive && <AddLogicalDependency node={node} />}
      </div>
    </Frame>
  );
}
