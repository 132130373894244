import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import PlaceholderText from '@octaved/flow/src/Components/Feedback/Placeholder/PlaceholderText';
import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useCombinedNodeSearch, useNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {difference, intersection} from 'lodash';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import NodeBillableQuotaChartSection from '../../../Components/Fields/Controlling/Section/NodeBillableQuotaChartSection';
import NodeSumFreeOfChargeSection from '../../../Components/Fields/Controlling/Section/NodeSumFreeOfChargeSection';
import NodeSumOfTimeLimitsSection from '../../../Components/Fields/Controlling/Section/NodeSumOfTimeLimitsSection';
import NodeSumTotalSection from '../../../Components/Fields/Controlling/Section/NodeSumTotalSection';
import NodeSumWithoutTimeLimitsSection from '../../../Components/Fields/Controlling/Section/NodeSumWithoutTimeLimitsSection';

interface ControllingTimeTrackingProps {
  node: Project | Group;
}

export default function ControllingTimeTracking({node}: ControllingTimeTrackingProps): ReactElement {
  const {t} = useTranslation();
  const {nodeIds} = useCombinedNodeSearch({
    and: [withDescendants(node.id, true), ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE]],
  });
  const {nodeIds: billableNodeIds} = useNodeSearch('customerIsBillable');
  const [billableWpIds, nonBillableWpIds] = useMemo(
    () => [intersection(nodeIds, billableNodeIds), difference(nodeIds, billableNodeIds)],
    [nodeIds, billableNodeIds],
  );

  return (
    <div className={'flex flex-col justify-between gap-y-10 pt-4'}>
      {billableWpIds.length > 0 && (
        <>
          <NodeSumOfTimeLimitsSection workPackageIds={billableWpIds} billable />
          <NodeSumWithoutTimeLimitsSection workPackageIds={billableWpIds} billable />
          <NodeSumFreeOfChargeSection workPackageIds={billableWpIds} />
          <NodeSumTotalSection workPackageIds={billableWpIds} />
          <NodeBillableQuotaChartSection nodeId={node.id} workPackageIds={nodeIds} />
        </>
      )}
      {nonBillableWpIds.length > 0 && (
        <>
          <NodeSumOfTimeLimitsSection workPackageIds={nonBillableWpIds} />
          <NodeSumWithoutTimeLimitsSection workPackageIds={nonBillableWpIds} />
          <NodeSumFreeOfChargeSection workPackageIds={nonBillableWpIds} />
          <NodeSumTotalSection workPackageIds={nonBillableWpIds} />
        </>
      )}
      {!billableWpIds.length && !nonBillableWpIds.length && (
        <PlaceholderText italic>{t('pages:myTasks.sections.noWorkPackagesPlaceholder')}</PlaceholderText>
      )}
    </div>
  );
}
