import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {groupInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/GroupTabs';
import {projectInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/ProjectTabs';
import {subWorkPackageInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/SubWorkPackageTabs';
import {taskInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/TaskTabs';
import {workPackageInspectorTabs} from '@octaved/node-details/src/InspectorView/Config/WorkPackageTabs';
import {specialPlanningRoute} from '@octaved/node-details/src/LargeView/RoutePaths';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import {dialogRouteObjects} from '../Dialogs';
import {
  advancedRouteObject,
  controllingRouteObject,
  generalRouteObject,
  guestUserNewInspectorRouteObject,
  historyRouteObject,
  planningRouteObject,
  responsibilityRouteObject,
  settingsRouteObject,
  timeAndMoneyRouteObject,
} from './Inspectors';
import SpecialPlanningInspector from './SpecialPlanningInspector';
import {getWrappedStandardInspectorRoutes} from './StandardInspector';

const specialPlanningRouteObjectWorkPackage = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <SpecialPlanningInspector />,
  path: specialPlanningRoute,
});

export const planningInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    specialPlanningRouteObjectWorkPackage(),
    generalRouteObject(),
    responsibilityRouteObject(),
    planningRouteObject(),
    timeAndMoneyRouteObject(),
    guestUserNewInspectorRouteObject(),
    settingsRouteObject(),
    advancedRouteObject(),
    controllingRouteObject(),
    historyRouteObject(),
    {
      element: <Navigate to={specialPlanningRoute} replace />,
      path: '*',
    },
  ],
  standardInspectorProps: {
    tabConfig: {
      [EnumFlowNodeType.VALUE_WORK_PACKAGE]: workPackageInspectorTabs,
      [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: subWorkPackageInspectorTabs,
      [EnumFlowNodeType.VALUE_GROUP]: groupInspectorTabs,
      [EnumFlowNodeType.VALUE_PROJECT]: projectInspectorTabs,
      [EnumFlowNodeType.VALUE_TASK]: taskInspectorTabs,
    },
  },
});
