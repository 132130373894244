import {getProjectControllingLastActivities} from '@octaved/flow-api';
import {useInspectorId} from '@octaved/hooks';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, hasLoadedOnce, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {
  LastActivities,
  StoreLastActivities,
} from '../../../EntityInterfaces/Projects/ProjectControlling/LastActivities';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_FAILURE,
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_REQUEST,
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_SUCCESS,
} from '../../ActionTypes';
import {
  lastActivitiesSelector,
  lastActivitiesStatesSelector,
} from '../../Selectors/Projects/ProjectControlling/ActivitySelectors';
import {workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector} from '../../Selectors/UiPages/ProjectsSelector';
import {FlowState} from '../../State';

interface LoadAction {
  key: string;
  response: LastActivities;
}

const reducerMap = new Map();
reducerMap.set(
  FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_SUCCESS,
  (state: StoreLastActivities, {key, response}: LoadAction): StoreLastActivities => {
    return {...state, [key]: response};
  },
);
export const lastActivitiesReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_SUCCESS, createTimestampReducer('key', LOADED));
addMultiToReducerMap(
  stateReducerMap,
  [
    FLOW_CHANGE_PID_SUCCESS,
    'flow.NodeCreatedEvent', //board posts really
    'flow.NodeRestoredFromTrashEvent',
    'flow.NodePatchedEvent', //board posts really
    'flow.ProjectCustomerChangedEvent', //TODO: not sure if needed
    'flow.TaskCreatedEvent',
    'flow.TaskPatchedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  () => ({}),
);
export const lastActivitiesStateReducer = ReduceFromMap(stateReducerMap);

function loadLastActivities(key: string, workPackageIds: Uuid[]): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = lastActivitiesStatesSelector(getState())[key];
    if (!state || isOutdated(state)) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: getProjectControllingLastActivities,
          method: 'post',
          options: {data: {workPackageIds}},
          types: {
            failureType: FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_FAILURE,
            requestType: FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_REQUEST,
            successType: FLOW_LOAD_PROJECT_CONTROLLING_LAST_ACTIVITIES_SUCCESS,
          },
        },
      });
    }
  };
}

const empty: LastActivities = {
  lastCompletedTasks: [],
  lastEditedBoardPosts: [],
  lastExecutedWorkPackages: [],
  lastStartedWorkPackages: [],
  lastTimeTrackingRecords: [],
};

export function useLastActivities(): {
  activities: LastActivities;
  hasLoadedOnce: boolean;
} {
  const inspectorId = useInspectorId();
  const {key, workPackageIds} = useSelector((s: FlowState) =>
    workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector(s)(inspectorId),
  );

  useStoreEffect(
    (dispatch) => {
      if (workPackageIds.length) {
        dispatch(loadLastActivities(key, workPackageIds));
      }
    },
    [key, workPackageIds],
    lastActivitiesStatesSelector,
  );

  return {
    activities: useSelector((s: FlowState) => lastActivitiesSelector(s)[key] || empty),
    hasLoadedOnce: useSelector((s: FlowState) => {
      const state = lastActivitiesStatesSelector(s)[key];
      return !workPackageIds.length || !!(state && hasLoadedOnce(state));
    }),
  };
}
