import {PropsWithChildren, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {AccessibleItemAccess, isItemAccessibleSelector} from '../AccessibleItem';
import {FlowState} from '../Modules/State';

interface Props {
  access: AccessibleItemAccess;
  redirectTo?: string;
}

export default function ProtectedPageRedirect({
  access,
  children,
  redirectTo = '..',
}: PropsWithChildren<Props>): ReactElement {
  const isAccessible = useSelector((s: FlowState) => isItemAccessibleSelector(s)({access}));
  if (isAccessible) {
    return <>{children}</>;
  }
  return <Navigate to={redirectTo} replace />;
}
