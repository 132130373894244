import {EnumFlowGroupType, EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import type {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {useCombinedNodeSearches} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {getNodeSearchQueryResultsSelector} from '@octaved/flow/src/Modules/Selectors/NodeSearchSelectors';
import type {FlowState} from '@octaved/flow/src/Modules/State';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import type {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {
  emptyPlanningStatus,
  PlanningStatus,
  PlanningStatusOverall,
  PlanningStatusPredecessors,
  PlanningStatusTime,
} from './PlanningStatus';

type NSC = NodeSearchCondition;

const notPlanned: NSC = ['nodeNotPlanned'];
const hasLogicalPreAny: NSC = ['hasLogicalPredecessor', 'any'];
const hasLogicalPreIncomplete: NSC = ['hasLogicalPredecessor', 'incomplete'];
const hasTimedPreAny: NSC = ['hasTimeBasedPredecessor', 'any:blocking'];
const hasTimedPreIncomplete: NSC = ['hasTimeBasedPredecessor', 'incomplete:blocking'];

const types: NSC = {
  or: [
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_TASK],
    {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_GROUP],
        ['grType', EnumFlowGroupType.VALUE_SPRINT],
      ],
    },
  ],
};

const done: NSC = {
  or: [['wpIsCompleted'], ['swpIsCompleted'], ['taskStatus', EnumFlowTaskStatus.VALUE_COMPLETE]],
};

const dateQueriesSelector = createSelector(todayIsoDateSelector, (todayIso) => {
  const plannedAfter: NSC = ['nodePlanningStartsAfterDate', todayIso];
  const plannedBefore: NSC = ['nodePlanningEndsBeforeDate', todayIso];
  const plannedAround: NSC = ['nodePlannedAroundDateRange', `${todayIso}-${todayIso}`];
  return {plannedAfter, plannedAround, plannedBefore};
});

const getPlanningStatusTimeSelector = createSelector(
  dateQueriesSelector,
  getNodeSearchQueryResultsSelector,
  ({plannedAfter, plannedAround, plannedBefore}, getNodeSearchQueryResults) => {
    const notPlannedIds = new Set(getNodeSearchQueryResults(notPlanned));
    const plannedAfterIds = new Set(getNodeSearchQueryResults(plannedAfter));
    const plannedAroundIds = new Set(getNodeSearchQueryResults(plannedAround));
    const plannedBeforeIds = new Set(getNodeSearchQueryResults(plannedBefore));
    return (nodeId: Uuid): PlanningStatusTime => {
      if (notPlannedIds.has(nodeId)) {
        return 'none';
      }
      if (plannedBeforeIds.has(nodeId)) {
        return 'inPast';
      }
      if (plannedAroundIds.has(nodeId)) {
        return 'active';
      }
      if (plannedAfterIds.has(nodeId)) {
        return 'inFuture';
      }
      return 'betweenBars';
    };
  },
);

const predecessorStatusSelectorFactory = (
  any: NSC,
  incompleted: NSC,
): ((state: FlowState) => (nodeId: Uuid) => PlanningStatusPredecessors) =>
  createSelector(getNodeSearchQueryResultsSelector, (getNodeSearchQueryResults) => {
    const anyIds = new Set(getNodeSearchQueryResults(any));
    const incompleteIds = new Set(getNodeSearchQueryResults(incompleted));
    return (nodeId: Uuid): PlanningStatusPredecessors => {
      if (incompleteIds.has(nodeId)) {
        return 'notDone';
      }
      if (anyIds.has(nodeId)) {
        return 'allDone';
      }
      return 'none';
    };
  });

const getPlanningStatusLogicalPreSelector = predecessorStatusSelectorFactory(hasLogicalPreAny, hasLogicalPreIncomplete);
const getPlanningStatusTimedPreSelector = predecessorStatusSelectorFactory(hasTimedPreAny, hasTimedPreIncomplete);

/**
 * @internal only exported for testing
 */
export function determineOverallStatus(
  isDone: boolean,
  time: PlanningStatusTime,
  logicalPredecessors: PlanningStatusPredecessors,
  planningPredecessors: PlanningStatusPredecessors,
  _defaultForTesting: 'none' = 'none',
): PlanningStatusOverall {
  const blocked = logicalPredecessors === 'notDone' || planningPredecessors === 'notDone';
  let overall: PlanningStatusOverall = _defaultForTesting; //the default is for typescript - actually all possible paths are taken
  if (time === 'none' && logicalPredecessors === 'none') {
    overall = 'none';
  } else if (isDone) {
    overall = 'done';
  } else if (time === 'inPast') {
    overall = 'overdue';
  } else if (time === 'inFuture' || (time === 'none' && logicalPredecessors === 'notDone')) {
    overall = 'notYetActive';
  } else if ((time === 'active' && !blocked) || (time === 'none' && logicalPredecessors === 'allDone')) {
    overall = 'active';
  } else if ((time === 'active' || time === 'betweenBars') && blocked) {
    overall = 'blocked';
  } else if (time === 'betweenBars') {
    overall = 'betweenBars';
  }
  return overall;
}

const getPlanningStatusSelector = createSelector(
  getPlanningStatusTimeSelector,
  getPlanningStatusLogicalPreSelector,
  getPlanningStatusTimedPreSelector,
  getNodeSearchQueryResultsSelector,
  (
    getPlanningStatusTime,
    getPlanningStatusLogicalPre,
    getPlanningStatusTimedPre,
    getNodeSearchQueryResults,
  ): ((nodeId: Uuid) => PlanningStatus) => {
    return memoize((nodeId: Uuid): PlanningStatus => {
      const doneIds = new Set(getNodeSearchQueryResults(done));
      const logicalPredecessors = getPlanningStatusLogicalPre(nodeId);
      const planningPredecessors = getPlanningStatusTimedPre(nodeId);
      const time = getPlanningStatusTime(nodeId);
      const isDone = doneIds.has(nodeId);
      return {
        isDone,
        logicalPredecessors,
        planningPredecessors,
        time,
        overall: determineOverallStatus(isDone, time, logicalPredecessors, planningPredecessors),
      };
    });
  },
);

const getEmptyPlanningStatusSelector = (): PlanningStatus => emptyPlanningStatus;
const emptyPlanningStatusSelector = (): (() => PlanningStatus) => getEmptyPlanningStatusSelector;

function useLoadPlanningStatus(isActive: boolean): boolean {
  const dateQueries = useSelector(dateQueriesSelector);
  const queriesToLoad: NSC[] = isActive
    ? [
        ...Object.values<NSC>(dateQueries),
        done,
        hasLogicalPreAny,
        hasLogicalPreIncomplete,
        hasTimedPreAny,
        hasTimedPreIncomplete,
        notPlanned,
      ]
    : [];
  const results = useCombinedNodeSearches({}, ...queriesToLoad);
  return results.every(({hasLoadedOnce}) => hasLoadedOnce);
}

export function useGetPlanningStatus(isActive = true): {
  hasLoadedOnce: boolean;
  getPlanningStatus: (nodeId: Uuid) => PlanningStatus;
} {
  const hasLoadedOnce = useLoadPlanningStatus(isActive);
  const getPlanningStatus = useSelector(isActive ? getPlanningStatusSelector : emptyPlanningStatusSelector);
  return {hasLoadedOnce, getPlanningStatus};
}

export function useNodePlanningStatus(nodeId: MaybeUuid): PlanningStatus {
  useLoadPlanningStatus(!!nodeId);
  return useSelector((s: FlowState) =>
    nodeId ? getPlanningStatusSelector(s)(nodeId) : emptyPlanningStatusSelector()(),
  );
}

interface PlanningStatusQueries {
  logicalPredecessors: {[k in PlanningStatusPredecessors]: NSC};
  overall: {[k in PlanningStatusOverall]: NSC};
  planningPredecessors: {[k in PlanningStatusPredecessors]: NSC};
  time: {[k in PlanningStatusTime]: NSC};
}

/**
 * Implements the same logic as `useGetPlanningStatus`, but in pure queries.
 * However this is only useful when filtering by a specific status and not when displaying the status.
 */
export const planningStatusQueriesSelector = createSelector(
  dateQueriesSelector,
  ({plannedAfter, plannedAround, plannedBefore}): PlanningStatusQueries => {
    const timeNone: NSC = {and: [types, notPlanned]};
    const timeInPast: NSC = {and: [types, plannedBefore]};
    const timeActive: NSC = {and: [types, plannedAround]};
    const timeInFuture: NSC = {and: [types, plannedAfter]};
    const timeBetweenBars: NSC = {
      and: [types, {not: notPlanned}, {not: plannedBefore}, {not: plannedAround}, {not: plannedAfter}],
    };

    const logicalPreNotDone: NSC = {and: [types, hasLogicalPreIncomplete]};
    const logicalPreAllDone: NSC = {and: [types, hasLogicalPreAny, {not: hasLogicalPreIncomplete}]};
    const logicalPreNone: NSC = {and: [types, {not: hasLogicalPreAny}]};

    const planningPreNotDone: NSC = {and: [types, hasTimedPreIncomplete]};
    const planningPreAllDone: NSC = {and: [types, hasTimedPreAny, {not: hasTimedPreIncomplete}]};
    const planningPreNone: NSC = {and: [types, {not: hasTimedPreAny}]};

    const blocked: NSC = {or: [logicalPreNotDone, planningPreNotDone]};

    const overallNone: NSC = {and: [timeNone, logicalPreNone]};
    const overallDone: NSC = {and: [done, {not: overallNone}]};
    const overallOverdue: NSC = {and: [timeInPast, {not: done}]};
    const overallNotYetActive: NSC = {
      and: [
        {
          or: [timeInFuture, {and: [timeNone, logicalPreNotDone]}],
        },
        {not: done},
      ],
    };
    const overallActive: NSC = {
      and: [
        {
          or: [{and: [timeActive, {not: blocked}]}, {and: [timeNone, logicalPreAllDone]}],
        },
        {not: done},
      ],
    };
    const overallBlocked: NSC = {and: [{or: [timeActive, timeBetweenBars]}, blocked, {not: done}]};
    const overallBetweenBars: NSC = {and: [timeBetweenBars, {not: blocked}, {not: done}]};

    return {
      logicalPredecessors: {
        allDone: logicalPreAllDone,
        none: logicalPreNone,
        notDone: logicalPreNotDone,
      },
      overall: {
        active: overallActive,
        betweenBars: overallBetweenBars,
        blocked: overallBlocked,
        done: overallDone,
        none: overallNone,
        notYetActive: overallNotYetActive,
        overdue: overallOverdue,
      },
      planningPredecessors: {
        allDone: planningPreAllDone,
        none: planningPreNone,
        notDone: planningPreNotDone,
      },
      time: {
        active: timeActive,
        betweenBars: timeBetweenBars,
        inFuture: timeInFuture,
        inPast: timeInPast,
        none: timeNone,
      },
    };
  },
);
