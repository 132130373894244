import {EnumFlowNodeType, EnumFlowPidBillingType, JourneyBillingMode} from '@octaved/env/src/dbalEnumTypes';
import {patchSettings as patchSettingsRoute} from '@octaved/flow-api';
import {SupportedLanguage} from '@octaved/i18n/src/Language/Languages';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {
  RulesList,
  validatePositiveIntegerStringFormat,
  validateLength,
  validateMinimum,
} from '@octaved/store/src/Validation';
import {DeepPartial, NumberToString} from '@octaved/typescript/src/lib';
import objectContains from '@octaved/validation/src/ObjectContains';
import cloneDeep from 'lodash/cloneDeep';
import {
  FLOW_CREATE_MATERIAL_RESOURCE_SUCCESS,
  FLOW_INIT_LOAD_SUCCESS,
  FLOW_PATCH_SETTINGS_FAILURE,
  FLOW_PATCH_SETTINGS_REQUEST,
  FLOW_PATCH_SETTINGS_SUCCESS,
} from './ActionTypes';
import {NodeCreatedEvent} from './Events';

import {InitAction} from './Initialization/Actions';
import {settingsSelector} from './Selectors/SettingSelectors';
import {
  BillingTypesBookLimits,
  getBillingTypesBookLimitsValidationRules,
  initialBillingTypesBookLimits,
  transformBillingTypesBookLimitsToPatchData,
  transformPatchDataToBillingTypesBookLimits,
} from './Settings/BillingTypeBookLimits';
import {FlowState} from './State';
import {validateErrorRules} from './Ui';

export interface BaseSettings {
  activeOauthClients: Array<'azure'>;
  allowIdentitySignUp: boolean;
  allowOrganizationSetup: boolean;
  aggridLicenseKey: string;
  heapIoAppId: string;
  isLdapEnabled: boolean;
  journeyBillingMode: JourneyBillingMode;
  passwordPolicy: {
    extendedChecksEnabled: boolean;
    minLength: number;
  };
  plausibleIoDomain: string;
  sentryDsnDesktop: string;
}

export enum StrictWorkflowControl {
  strict = 'strict',
  medium = 'medium',
  relaxed = 'relaxed',
}

export interface Settings extends BaseSettings {
  allowGaps: boolean;
  billingRoundingMinutes: number;
  billingTypesBookLimits: BillingTypesBookLimits;
  constants: {
    fileExport: {
      logoHeight: number;
      logoWidth: number;
    };
  };
  currencySymbol: string;
  defaultBillableBillingType: EnumFlowPidBillingType; //default billingType for billable customers
  defaults: Omit<Settings, 'defaults'> | null;
  enabledWorkingTimeWarnings: {
    germanyMissingBreak: boolean;
    germanyMissingBreakAfter6Hours: boolean;
    germanyMissingBreakSubtractMissedBreaksFromWorkingTime: boolean;
    moreThan10Hours: boolean;
  };
  fileExports: {
    highlightColor: string;
    logoUrl: string | null;
    showLogoOnFirstPageOnly: boolean;
    timeSheets: {
      footerTexts: Partial<Record<SupportedLanguage, string>>;
      headlines: Partial<Record<SupportedLanguage, string | null>>;
      showSignatureField: boolean;
    };
  };
  hasMaterialResources: boolean;
  logicalDependencyWorkflowControl: StrictWorkflowControl;
  markParentTasksDoneWorkflowControl: StrictWorkflowControl;
  markWorkPackagesDoneWorkflowControl: StrictWorkflowControl;
  forceDescription: boolean;
  readonly timezone: string;
  startFiscalYear: number | null;
  timeRecordingBookDaysTolerance: number;
  useProjectFolders: boolean;
  useTimeTracking: boolean;
  useWorkingTimeTracking: boolean;
  webhooks: {
    customerSearchByName: boolean;
    customerSearchByNumber: boolean;
    transmitBilling: boolean;
    transmitOffer: boolean;
  };
  webhooksEnabled: boolean;
}

export const initialState: Settings = {
  activeOauthClients: [],
  aggridLicenseKey: '',
  allowGaps: true,
  allowIdentitySignUp: false,
  allowOrganizationSetup: false,
  billingRoundingMinutes: 15,
  billingTypesBookLimits: initialBillingTypesBookLimits,
  constants: {
    fileExport: {
      logoHeight: 0,
      logoWidth: 0,
    },
  },
  currencySymbol: '€',
  defaultBillableBillingType: EnumFlowPidBillingType.VALUE_EFFORT,
  defaults: null,
  enabledWorkingTimeWarnings: {
    germanyMissingBreak: true,
    germanyMissingBreakAfter6Hours: false,
    germanyMissingBreakSubtractMissedBreaksFromWorkingTime: false,
    moreThan10Hours: true,
  },
  fileExports: {
    highlightColor: 'FA7800',
    logoUrl: null,
    showLogoOnFirstPageOnly: false,
    timeSheets: {
      footerTexts: {
        de: '',
        en: '',
      },
      headlines: {
        de: null,
        en: null,
      },
      showSignatureField: false,
    },
  },
  forceDescription: false,
  hasMaterialResources: false,
  heapIoAppId: '',
  isLdapEnabled: false,
  journeyBillingMode: JourneyBillingMode.hourly,
  logicalDependencyWorkflowControl: StrictWorkflowControl.medium,
  markParentTasksDoneWorkflowControl: StrictWorkflowControl.medium,
  markWorkPackagesDoneWorkflowControl: StrictWorkflowControl.medium,
  passwordPolicy: {
    extendedChecksEnabled: false,
    minLength: 10,
  },
  plausibleIoDomain: '',
  sentryDsnDesktop: '',
  startFiscalYear: null,
  timeRecordingBookDaysTolerance: 5,
  timezone: 'UTC',
  useProjectFolders: false,
  useTimeTracking: false,
  useWorkingTimeTracking: false,
  webhooks: {
    customerSearchByName: false,
    customerSearchByNumber: false,
    transmitBilling: false,
    transmitOffer: false,
  },
  webhooksEnabled: false,
};

const reducers = createReducerCollection<Settings>(initialState);
reducers.add(
  FLOW_INIT_LOAD_SUCCESS,
  (state: Settings, action: InitAction): Settings => mergeStates(state, action.response.result.settings),
);
reducers.add(FLOW_CREATE_MATERIAL_RESOURCE_SUCCESS, (state: Settings): Settings => {
  if (!state.hasMaterialResources) {
    return {...state, hasMaterialResources: true};
  }
  return state;
});
reducers.add<NodeCreatedEvent>('flow.NodeCreatedEvent', (state, action): Settings => {
  if (!state.useProjectFolders && action.nodeType === EnumFlowNodeType.VALUE_PROJECT_FOLDER) {
    return {...state, useProjectFolders: true};
  }
  return state;
});
export const reducer = reducers.reducer;

export type PatchSettings = NumberToString<
  Pick<
    Settings,
    'billingRoundingMinutes' | 'timeRecordingBookDaysTolerance' | 'billingTypesBookLimits' | 'startFiscalYear'
  >
> &
  Pick<
    Settings,
    | 'allowGaps'
    | 'currencySymbol'
    | 'defaultBillableBillingType'
    | 'enabledWorkingTimeWarnings'
    | 'fileExports'
    | 'forceDescription'
    | 'journeyBillingMode'
    | 'logicalDependencyWorkflowControl'
    | 'markParentTasksDoneWorkflowControl'
    | 'markWorkPackagesDoneWorkflowControl'
    | 'useTimeTracking'
    | 'useWorkingTimeTracking'
  > & {
    fileExports: {
      logoFile?: string | null; //the upload ident of a newly uploaded file or NULL to unset
    };
  };

function getValidationRules(data: DeepPartial<PatchSettings>): RulesList {
  const rules: RulesList = [];
  if (data.hasOwnProperty('billingTypesBookLimits')) {
    getBillingTypesBookLimitsValidationRules(data.billingTypesBookLimits!, rules);
  }
  if (data.hasOwnProperty('currencySymbol')) {
    rules.push([
      validateLength,
      data.currencySymbol,
      'systemSettings:general.currencySymbol.max3Chars',
      'currencySymbol',
      3,
    ]);
  }
  if (data.hasOwnProperty('timeRecordingBookDaysTolerance')) {
    if (data.timeRecordingBookDaysTolerance === '0') {
      rules.push([
        validateMinimum,
        data.timeRecordingBookDaysTolerance,
        'systemSettings:general.editAndDelayedTimerecordings.minValidationDays',
        'timeRecordingBookDaysTolerance',
        1,
      ]);
    } else {
      rules.push([
        validatePositiveIntegerStringFormat,
        data.timeRecordingBookDaysTolerance,
        'systemSettings:general.editAndDelayedTimerecordings.validationDays',
        'timeRecordingBookDaysTolerance',
      ]);
    }
  }
  return rules;
}

export function transformSettingsToPatchData(data: Settings): PatchSettings {
  return {
    ...cloneDeep(data),
    billingRoundingMinutes: data.billingRoundingMinutes.toString(),
    billingTypesBookLimits: transformBillingTypesBookLimitsToPatchData(data.billingTypesBookLimits),
    startFiscalYear: data.startFiscalYear ? data.startFiscalYear.toString() : null,
    timeRecordingBookDaysTolerance: data.timeRecordingBookDaysTolerance.toString(),
  };
}

function transformPatchDataToSettings(data: DeepPartial<PatchSettings>): DeepPartial<Settings> {
  const convertedData = cloneDeep(data) as DeepPartial<Settings>;
  if (data.hasOwnProperty('billingRoundingMinutes')) {
    convertedData.billingRoundingMinutes = parseInt(data.billingRoundingMinutes!, 10);
  }
  if (data.hasOwnProperty('timeRecordingBookDaysTolerance')) {
    convertedData.timeRecordingBookDaysTolerance = parseInt(data.timeRecordingBookDaysTolerance!, 10);
  }
  if (data.hasOwnProperty('startFiscalYear') && data.startFiscalYear !== null) {
    convertedData.startFiscalYear = parseInt(data.startFiscalYear!, 10);
  }
  if (data.billingTypesBookLimits) {
    convertedData.billingTypesBookLimits = transformPatchDataToBillingTypesBookLimits(data.billingTypesBookLimits);
  }
  return convertedData;
}

export function patchSettings(data: DeepPartial<PatchSettings>): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const oldSettings = settingsSelector(getState());
    if (!validateErrorRules(getValidationRules(data), dispatch)) {
      return false;
    }

    const convertedData = transformPatchDataToSettings(data);

    if (!objectContains(oldSettings, convertedData)) {
      dispatch({
        [CALL_API]: {
          endpoint: patchSettingsRoute,
          method: 'patch',
          options: {
            data: convertedData,
          },
          types: {
            failureType: FLOW_PATCH_SETTINGS_FAILURE,
            requestType: FLOW_PATCH_SETTINGS_REQUEST,
            successType: FLOW_PATCH_SETTINGS_SUCCESS,
          },
        },
      });
    }
    return true;
  };
}
