import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {NodesResponsibilitiesBulkPatchedEvent} from '@octaved/flow/src/Modules/Events';
import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {RightsExport} from '@octaved/security/src/Authorization/Authorization';
import {
  createFlatTimestampReducer,
  EntityState,
  INVALIDATED,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, nativeDispatch, onSelectorChange} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {getCurrentOrgUserRights} from '../../config/routes';
import {USER_RIGHTS_CHANGED} from '../ActionTypes';
import {
  currentOrgUserIdSelector,
  currentOrgUserRightsStateSelector,
  currentOrgUserTypeSelector,
} from '../Selectors/CurrentOrgUserSelectors';
import {isGuestUserType} from '../UserType';
import {INVALIDATE_RIGHTS, LOAD_RIGHTS_FAILURE, LOAD_RIGHTS_REQUEST, LOAD_RIGHTS_SUCCESS} from './ActionTypes';

let currentUserId: Uuid | null = null;
onSelectorChange(currentOrgUserIdSelector, true, (id) => {
  currentUserId = id;
});

export const rightsReducers = createReducerCollection<RightsExport>({
  globalRights: [],
  nodeRights: {
    nodeIdToRightSetId: {},
    rightSets: {},
  },
});

const stateReducers = createReducerCollection<EntityState>({
  loading: 0,
});

interface LoadAction {
  response: RightsExport;
  type: typeof LOAD_RIGHTS_SUCCESS;
}

rightsReducers.add<LoadAction>(LOAD_RIGHTS_SUCCESS, (state, {response}) => {
  if (JSON.stringify(state) !== JSON.stringify(response)) {
    setTimeout(() => {
      nativeDispatch({type: USER_RIGHTS_CHANGED});
    }, 5);
    return response;
  }
  return state;
});

const reduceLoaded = createFlatTimestampReducer(LOADED);
const reduceInvalidate = createFlatTimestampReducer(INVALIDATED);

stateReducers.add(LOAD_RIGHTS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducers.add(LOAD_RIGHTS_SUCCESS, reduceLoaded);
stateReducers.add(
  [
    'flow.anyUserRightsChanged',
    'flow.GuestRoleRightMatrixChangedEvent',
    'flow.groupIdsAffectedRightsChanged',
    'flow.InternalRoleRightMatrixChangedEvent',
    'flow.NodeRestoredFromTrashEvent',
    'flow.NodeRoleAssignmentEvent',
    'flow.PidCopiedEvent', //copies project role assignments, too
    'flow.RolePatchedEvent',
    'flow.RoleRemovedEvent',
    'UserGroupDelegationEvent',
    'flow.NodeMovedEvent',
    'flow.NodesRearrangeEvent',
    'flow.TasksMovedEvent',
    'flow.TaskSectionsMovedEvent',
    INVALIDATE_RIGHTS,
  ],
  reduceInvalidate,
);

stateReducers.add<NodesResponsibilitiesBulkPatchedEvent>(
  'flow.NodesResponsibilitiesBulkPatchedEvent',
  (state, action) =>
    currentUserId && action.affectedUserIds.includes(currentUserId) ? reduceInvalidate(state, action) : state,
);

rightsReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) =>
  action.isInOrganization ? action.response.result.orgUser.rights : state,
);
stateReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, reduceLoaded);

export const currentOrgUserRightsReducer = {
  data: rightsReducers.reducer,
  state: stateReducers.reducer,
};

/**
 * reloads the rights for the current user
 * this function is not cached and will force a server request on every call
 */
function loadCurrentOrgUserRights(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = currentOrgUserRightsStateSelector(getState());
    if (!state || isOutdated(state)) {
      dispatch({
        [CALL_API]: {
          endpoint: getCurrentOrgUserRights,
          types: {
            failureType: LOAD_RIGHTS_FAILURE,
            requestType: LOAD_RIGHTS_REQUEST,
            successType: LOAD_RIGHTS_SUCCESS,
          },
        },
      });
    }
  };
}

export function useCurrentOrgUserRights(): void {
  useStoreEffect((disp) => disp(loadCurrentOrgUserRights()), [], currentOrgUserRightsStateSelector);
}

const invalidateAction = {type: INVALIDATE_RIGHTS};

/**
 * Determines whether the userIds affect the current user
 */
function onUserIdsAffectedRights({userIds}: {userIds: Uuid[]}): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const userId = currentOrgUserIdSelector(getState());
    if (userIds.includes(userId)) {
      return dispatch(invalidateAction);
    }
    return null;
  };
}

//Keep the rights loaded at any time:
subscribe('flow.guestUserRightsChanged', () => (dispatch, getState) => {
  if (isGuestUserType(currentOrgUserTypeSelector(getState()))) {
    dispatch(invalidateAction);
  }
});
subscribe('flow.userIdsAffectedRightsChanged', onUserIdsAffectedRights);
subscribe('flow.globalRolesChanged', ({affectedUserId}: {affectedUserId?: Uuid}) => {
  if (affectedUserId) {
    return onUserIdsAffectedRights({userIds: [affectedUserId]});
  }
  return invalidateAction;
});
