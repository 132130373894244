import {getBillingTypeTranslatable} from '@octaved/flow/src/WorkPackage/BillingType';
import {TWProgress} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import HoursOfTotal from './HoursOfTotal';
import {RowProps} from './Interfaces';

export default function RowWithMaxEffort({billable = false, sum, type}: RowProps): ReactElement {
  return (
    <>
      <div>
        <Trans i18nKey={getBillingTypeTranslatable(type, billable)} />
      </div>
      <TWProgress
        progress={(sum.billableHours * 100) / sum.maxEffortHours}
        color={sum.billableHours > sum.maxEffortHours ? 'red' : 'blue'}
        size={'lg'}
      />
      <HoursOfTotal hours={sum.billableHours} total={sum.maxEffortHours} shortUnit />
    </>
  );
}
