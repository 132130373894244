import {getClientXY} from '@octaved/flow/src/Hooks/MouseMove';
import {Uuid} from '@octaved/typescript/src/lib';
import Konva from 'konva';
import {Line} from 'konva/lib/shapes/Line';
import {throttle} from 'lodash';
import {ArrowRightFromLine} from 'lucide';
import {HoverRowChangedEvent, ScrollEvent} from '../../Calendar/Context/CalendarContext';
import {GhostButton} from '../../CommonComponents/GhostButton';
import {createText} from '../../CommonComponents/Text';
import {DisposableArray} from '../../Disposable';
import {RootContainer, RootContainerProps} from '../../RootContainer';
import {defaultShadow} from '../../Shadow';
import {createStoreSubscription} from '../../StoreSubscription';
import {GanttContext} from '../Context/GanttContext';
import {GanttDataLoader} from '../Data/GanttDataLoader';
import {DataRow} from './DataRow';
import {HeaderRow} from './HeaderRow';
import {TableFactory} from './TableFactory';
import {TABLE_ROW_LIMIT} from '../../ProjectBasedGantt/ProjectBasedGanttDataLoader';

interface TableProps extends RootContainerProps<GanttContext> {
  planningDataLoader: GanttDataLoader;
  tableFactory: TableFactory;
}

export class Table extends RootContainer<GanttContext> {
  static readonly footerHeight = 120;
  readonly imageSize = 14;

  readonly #planningDataLoader: GanttDataLoader;
  readonly #dataRowGroup = new Konva.Group({id: 'dataRowGroup'});
  readonly #allHiddenGroup = new Konva.Group({id: 'allHiddenGroup'});
  readonly #background = new Konva.Rect({id: 'backgroundClicker', fill: '#fcfcfc', ...defaultShadow});
  readonly #hoverBackground = new Konva.Rect({fill: '#00000005', visible: false, id: 'hoverBackground'});
  readonly #selectedBackground = new Konva.Rect({fill: '#e9f5fb', visible: false, id: 'selectedBackground'});
  readonly #tableFactory: TableFactory;
  #headerRow: HeaderRow | null = null;
  #disposableRows: DisposableArray = [];

  constructor(props: TableProps) {
    super(props);

    this.#planningDataLoader = props.planningDataLoader;
    this.#tableFactory = props.tableFactory;
    this.root.add(this.#background);
    this.root.add(this.#hoverBackground);
    this.root.add(this.#selectedBackground);
    this.root.add(this.#dataRowGroup);
    this.root.add(this.#allHiddenGroup);

    this.#background.height(this.ctx.canvasView.maxHeight);
    this.#dataRowGroup.y(this.ctx.headerHeight);

    this.disposables.push(
      this.ctx.eventEmitter.on('flatTreeChanged', this.#render),
      this.ctx.eventEmitter.on('scroll', this.#onScroll),
      this.ctx.eventEmitter.on('hoverRowChanged', this.#onHoverRowChanged),
      this.ctx.eventEmitter.on('selectedNodeIdChanged', this.#onSelectedNodeIdChanged),
      () => this.#disposeRows(),
    );

    this.root.on('mousemove tap', this.#onMouseMove);
    this.root.on('mouseleave', () => {
      this.#onMouseMove.cancel();
      this.#emitHoverRowChanged(null);
    });
  }

  #onScroll = throttle((e: ScrollEvent) => {
    this.#dataRowGroup.offsetY(e.scrollTop);
    this.#hoverBackground.offsetY(e.scrollTop);
    this.#selectedBackground.offsetY(e.scrollTop);
  }, 30);

  #onMouseMove = throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
    const [, clientY] = getClientXY(e.evt);
    const content = this.ctx.canvasView.canvas;
    const {height, top} = content.getBoundingClientRect();
    const scaleY = height / content.clientHeight || 1;
    const mouseY = (clientY - top) / scaleY;
    //first row is header
    const y = mouseY - this.#dataRowGroup.y() + this.#dataRowGroup.offsetY();
    if (y < 0) {
      this.#emitHoverRowChanged(null);
      return;
    }

    const row = Math.floor(y / this.ctx.rowHeight);
    const node = this.#planningDataLoader.flatTree[row];
    if (node) {
      this.#emitHoverRowChanged({nodeId: node.id, rowIndex: row});
    } else {
      this.#emitHoverRowChanged(null);
    }
  }, 30);

  #lastHoverRow: HoverRowChangedEvent | null = null;
  #emitHoverRowChanged(value: HoverRowChangedEvent | null): void {
    if (value?.nodeId !== this.#lastHoverRow?.nodeId) {
      this.ctx.eventEmitter.emit('hoverRowChanged', value);
      this.#lastHoverRow = value;
    }
  }

  #onHoverRowChanged = (value: HoverRowChangedEvent | null): void => {
    if (value) {
      this.#hoverBackground.y(this.ctx.rowHeight * value.rowIndex + this.ctx.headerHeight);
      this.#hoverBackground.height(this.ctx.rowHeight);
      this.#hoverBackground.visible(true);
    } else {
      this.#hoverBackground.visible(false);
    }
  };

  #onSelectedNodeIdChanged = (value: Uuid | null): void => {
    if (value) {
      const rowIndex = this.#planningDataLoader.flatTree.findIndex((node) => node.id === value);
      this.#selectedBackground.y(this.ctx.rowHeight * rowIndex + this.ctx.headerHeight);
      this.#selectedBackground.height(this.ctx.rowHeight);
      this.#selectedBackground.visible(true);
    } else {
      this.#selectedBackground.visible(false);
    }
  };

  #disposeRows(): void {
    this.disposeList(this.#disposableRows);
    this.#disposableRows = [];
  }

  init(): void {
    this.#headerRow = new HeaderRow({ctx: this.ctx, tableFactory: this.#tableFactory, menuRoot: this.root});
    this.root.add(this.#headerRow.root);
    this.#headerRow.init();

    this.disposables.push(
      () => this.#headerRow?.dispose(),
      createStoreSubscription(
        this.ctx.store,
        () => this.ctx.calculateTableWidth(),
        (width) => {
          this.root.width(width);
          this.#background.width(width);
          this.#hoverBackground.width(width);
          this.#selectedBackground.width(width);
          this.root.find('Line').forEach((line) => {
            if (line instanceof Line) {
              const [x1, y1, , y2] = line.points();
              line.points([x1, y1, width, y2]);
            }
          });
          this.root.find('.addProjectText, .placeholder').forEach((text) => text.width(width));
          this.#headerRow?.root.width(width);
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        () => this.ctx.visibleColumns.includes('hideAll'),
        () => this.#render(),
      ),
    );
  }

  #createLine(y: number, width: number): Konva.Line {
    return new Konva.Line({
      name: 'seperatorLine',
      offsetY: 0.5,
      points: [0, y, width, y],
      stroke: '#e2e8f0',
      strokeWidth: 1,
    });
  }

  #render = (): void => {
    this.#dataRowGroup.destroyChildren();
    this.#allHiddenGroup.destroyChildren();
    this.#disposeRows();

    const width = this.ctx.calculateTableWidth();
    if (this.ctx.visibleColumns.includes('hideAll')) {
      this.#renderHideAll(width);
      this.#headerRow?.root.visible(false);
    } else {
      this.#renderDataRows(width);
      this.#headerRow?.root.visible(true);
    }
  };

  #renderDataRows(width: number): void {
    let y = 0;
    const line = this.#createLine(y, width);
    this.#dataRowGroup.add(line);
    const menuRoot = new Konva.Group();
    for (const row of this.#planningDataLoader.flatTree) {
      const rowComponent = new DataRow({ctx: this.ctx, node: row, menuRoot, tableFactory: this.#tableFactory});
      rowComponent.root.y(y);
      rowComponent.init();
      this.#disposableRows.push(() => rowComponent.dispose());

      y += this.ctx.rowHeight;

      const line = this.#createLine(y, width);
      this.#dataRowGroup.add(line, rowComponent.root);
    }
    if (this.ctx.canAddRootNodes()) {
      this.#renderFooter(y, width);
    }

    this.#dataRowGroup.add(menuRoot);
    this.#onSelectedNodeIdChanged(this.ctx.selectedNodeId);
  }

  #renderHideAll(width: number): void {
    const hideAllBackground = new Konva.Rect({
      width,
      fill: '#fcfcfc',
      height: this.ctx.canvasView.maxHeight,
      id: 'hideAllBackground',
    });
    this.#allHiddenGroup.add(hideAllBackground);

    const tableToggleButton = new GhostButton({
      ctx: this.ctx,
      icon: ArrowRightFromLine,
      imageSize: this.imageSize,
      onClick: () => {
        this.ctx.setVisibleColumns(this.ctx.visibleColumns.filter((c) => c !== 'hideAll'));
      },
    });
    tableToggleButton.root.x((width - tableToggleButton.imageSize) / 2);
    tableToggleButton.root.y((this.ctx.rowHeight - tableToggleButton.imageSize) * 0.5);

    this.#allHiddenGroup.add(tableToggleButton.root);
    tableToggleButton.init();
    this.#disposableRows.push(() => tableToggleButton.dispose());
  }

  #renderFooter(initialY: number, width: number): void {
    const fontSize = 12;
    const y = initialY + Table.footerHeight / 2 - fontSize * 2;

    if (this.#planningDataLoader.isTreeTruncated()) {
      const addProjectText = createText({
        fontSize,
        width,
        y,
        align: 'center',
        // fill: '#0b66a7',
        // listening: true,
        name: 'rowLimitText',
        offsetY: 0.5,
        text: this.ctx.t('pages:planning.ganttChart.rowLimit', {limit: TABLE_ROW_LIMIT}),
      });
      this.#dataRowGroup.add(addProjectText);
    }
  }
}
