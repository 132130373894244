import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {createSelector} from 'reselect';
import {Nodes, NodeType} from '../../EntityInterfaces/Nodes';
import {FlowState} from '../State';

export const nodeEntityStatesSelector = (state: FlowState): EntityStates => state.entityStates.node;
export const nodeEntitySelector = (state: FlowState): Nodes => state.entities.node;
export const getNodeSelector = createSelector(
  nodeEntitySelector,
  (nodes) =>
    (nodeId: Uuid | null | undefined): NodeType | undefined =>
      nodeId ? nodes[nodeId] : undefined,
);
