import {Message} from '@octaved/ui';
import {Eye, EyeOff} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface NodeGuestUserAccessMessageProps {
  hasDirectAccess: boolean;
  hasSubtreeAccess: boolean;
}

export default function NodeGuestUserAccessMessage({
  hasDirectAccess,
  hasSubtreeAccess,
}: NodeGuestUserAccessMessageProps): ReactElement {
  const {t} = useTranslation();
  return (
    <>
      {!hasSubtreeAccess && (
        <Message colorScheme={'slate'} icon={EyeOff}>
          {t('guestUser:inspector.noAccessAtAll')}
        </Message>
      )}
      {hasDirectAccess && (
        <Message colorScheme={'sky'} icon={Eye}>
          {t('guestUser:inspector.accessHere')}
        </Message>
      )}
      {!hasDirectAccess && hasSubtreeAccess && (
        <>
          <Message colorScheme={'slate'} icon={EyeOff}>
            {t('guestUser:inspector.noAccessHere')}
          </Message>
          <Message colorScheme={'sky'} icon={Eye}>
            {t('guestUser:inspector.accessBeneath')}
          </Message>
        </>
      )}
    </>
  );
}
