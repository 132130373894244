import {AuditUserNode} from '../../../../EntityInterfaces/Audit/AuditUserNode';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import type {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {nodeColumns} from './Node';

const config: AuditTableConfiguration<AuditUserNode> = {
  columns: [...nodeColumns, createColumnDef('user', 'systemSettings:audit.tables.userNode.userHeader', ReferenceCell)],
  tableIdent: 'userNodes',
  tableNameTranslationToken: 'systemSettings:audit.userNodeTableName',
};
export default config;
