import {Uuid} from '@octaved/typescript/src/lib';
import classNames from 'classnames';
import {ReactElement} from 'react';
import StringPath from '../../Node/StringPath';
import {useInProjectTreeContext} from '../../ProjectTree/ProjectTreeContext';

interface Props {
  autoHeight?: boolean;
  id: Uuid;
  noPadding?: boolean;
}

export default function Path({autoHeight, id, noPadding}: Props): ReactElement | null {
  const isInTreeContext = useInProjectTreeContext();

  if (!isInTreeContext) {
    return (
      <div className={classNames('pidPath', {autoHeight, noPadding})}>
        <StringPath nodeId={id} includeCustomer excludeSelf />

        {/*#region styles*/}
        {/*language=scss*/}
        <style jsx>{`
          .pidPath {
            font-size: 12px;
            line-height: 16px;
            padding-right: 10px;
            color: var(--color-font-workpackage-minorDetails, #888);
            height: 32px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .noPadding {
            padding-right: 0;
          }

          .autoHeight {
            height: auto;
          }
        `}</style>
        {/*#endregion*/}
      </div>
    );
  }

  return null;
}
