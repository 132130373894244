import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';

import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {MediaFileQuotaChangedEvent} from '@octaved/media-files/src/EntityInterfaces/Events';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {Quota} from '../EntityInterfaces/Quota';

const defaultQuota: Quota = {
  identity: {
    limit: 0,
    usage: 0,
  },
  organization: {
    limit: 0,
    usage: 0,
  },
};

const reducers = createReducerCollection<Quota>(defaultQuota);

reducers.add(FLOW_INIT_LOAD_SUCCESS, (state: Quota, action: InitAction): Quota => {
  if (action.isInOrganization) {
    return action.response.result.quota;
  }
  return state;
});

reducers.add('flow.mediaFileQuotaChanged', (state: Quota, action: MediaFileQuotaChangedEvent): Quota => {
  return mergeStates(state, action.quota);
});

export const reducer = reducers.reducer;
