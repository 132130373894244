import useMouseMoveHook, {getClientXY, MouseResizeEvents} from '@octaved/flow/src/Hooks/MouseMove';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {generateStandardInspectorRoute} from '@octaved/flow/src/Routing/Routes/Inspectors/StandardInspector';
import {Point} from '@octaved/svg';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {addDependency} from '../../../../../../Modules/PlanningDependencies';
import {isPlanningDependencyNode} from '../../../../../../Selectors/PlanningSelector';
import DragableLine from '../../../../../DragableLine/DragableLine';
import {getRootRowClassName} from '../../../../HoveredRow';
import {calculateDependency} from './CalculateDependency';

interface DndDependencyProps {
  id: Uuid;
}

function getTargetId(element: Element): Uuid | null {
  if (element.classList.contains('row') || element.classList.contains('ghost')) {
    return null;
  }
  let currentElement: Element | null = element;
  while (currentElement) {
    if (element.classList.contains('isGhost')) {
      return null;
    }
    if (currentElement.classList.contains('row') && currentElement.hasAttribute('data-id')) {
      return currentElement.getAttribute('data-id');
    }
    currentElement = currentElement.parentElement;
  }
  return null;
}

export default function DndDependency({id}: DndDependencyProps): ReactElement {
  const [start, setStart] = useState<Point | null>(null);
  const [current, setCurrent] = useState<Point | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const getNode = useSelector(getNodeSelector);
  const dispatch = useDispatch();
  const {getState} = useStore<FlowState>();
  const navigate = useNavigate();

  const onMove = useCallback((x: number, y: number) => {
    // only update the position if the current value is not null
    // as onMove is throttled it can be called after the finished method
    setCurrent((v) => (v ? {x, y} : null));
  }, []);

  const onFinish = useCallback(
    (_, currentY, event: MouseEvent | TouchEvent, __, startY) => {
      if (event.target instanceof Element) {
        const targetId = getTargetId(event.target);
        const sourceNode = getNode(id);
        const targetNode = getNode(targetId);
        if (id !== targetId && isPlanningDependencyNode(sourceNode) && isPlanningDependencyNode(targetNode)) {
          const draggedUp = startY > currentY;
          const dependency = calculateDependency(sourceNode, targetNode, draggedUp, getState());
          if (dependency) {
            dispatch(addDependency(dependency.nodeId, dependency.predecessorId));
          }
        }
      }
      setCurrent(null);
    },
    [dispatch, getNode, id, getState],
  );
  const onMouseDown = useMouseMoveHook(onMove, onFinish);

  function onStart(event: MouseResizeEvents): void {
    onMouseDown(event);
    if (ref.current) {
      const position = ref.current.getBoundingClientRect();
      setStart({x: position.left, y: position.top});
      navigate(generateStandardInspectorRoute(null));
    }
    const [x, y] = getClientXY(event);
    setCurrent({x, y});
  }

  return (
    <>
      <div className={'dependencyDnd'} onMouseDown={onStart} onTouchStart={onStart} />
      <div className={'dependencyRef'} ref={ref}>
        {current && start && <DragableLine current={current} start={start} />}
      </div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .dependencyRef {
          position: absolute;
          top: 50%;
          z-index: 2;
        }

        .dependencyDnd {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          visibility: hidden;
          transition: visibility 120ms ease-out;
          cursor: pointer;
          left: -20px;
          width: 10px;
          height: 10px;
          background-color: #fff;
          border: 2px solid #333;
          border-radius: 50%;
          z-index: 11;
        }

        :global(.${getRootRowClassName(id)}) .dependencyDnd {
          visibility: visible;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
