import {PropsWithChildren, ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import FlowOutlet from '../../Components/FlowOutlet';
import {PriceSurcharge} from '../../EntityInterfaces/PriceSurcharges';
import {priceSurchargesEntitiesSelector} from '../../Modules/Selectors/PriceSurchargeSelectors';
import {
  BaseInspectorContext,
  InspectorContext,
  inspectorContext,
  InspectorContextProps,
  InspectorParameterNames,
  useBaseInspectorContextParams,
  useInspectorContext,
} from './InspectorContext';

export interface IPriceSurchargeInspectorContext extends BaseInspectorContext {
  selectedEntity: PriceSurcharge;
  selectedType: 'priceSurcharge';
}

function isPriceSurchargeInspectorContext(
  context: InspectorContext | null,
): context is IPriceSurchargeInspectorContext {
  return context?.selectedType === 'priceSurcharge';
}

export function usePriceSurchargeInspectorContext(): IPriceSurchargeInspectorContext {
  const context = useInspectorContext();
  if (!isPriceSurchargeInspectorContext(context)) {
    throw new Error('is not price surcharge inspector');
  }
  return context;
}

function useCreateInspectorContext(parameterName: InspectorParameterNames): IPriceSurchargeInspectorContext | null {
  const baseParams = useBaseInspectorContextParams(parameterName);
  const selectedEntity = useSelector(priceSurchargesEntitiesSelector)[baseParams!.selectedId];

  return useMemo<IPriceSurchargeInspectorContext | null>(() => {
    if (baseParams && selectedEntity) {
      return {
        ...baseParams,
        selectedEntity,
        selectedType: 'priceSurcharge',
      };
    }
    return null;
  }, [baseParams, selectedEntity]);
}

export default function PriceSurchargeInspectorContext({
  children,
  parameterName = 'inspectId',
}: PropsWithChildren<InspectorContextProps>): ReactElement {
  const inspectorContextProps = useCreateInspectorContext(parameterName);

  if (!inspectorContextProps) {
    return <FlowOutlet />;
  }
  return <inspectorContext.Provider value={inspectorContextProps}>{children}</inspectorContext.Provider>;
}
