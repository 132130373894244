import {splitSearchTerm} from '@octaved/utilities/src/Search/SearchTerm';
import classNames from 'classnames';
import escapeRegExp from 'lodash/escapeRegExp';
import {createContext, ReactElement, ReactNode, useContext, useMemo} from 'react';
import css from 'styled-jsx/css';
import {FlowStyleBuilder, useFlowStyle} from '../../Styles/StyleContext';

interface Context {
  allowRegex: boolean;
  phrases: string[];
  searchTerm: string;
}

const context = createContext<Context>({
  allowRegex: false,
  phrases: [],
  searchTerm: '',
});

// noinspection FunctionNamingConventionJS
export function SearchHighlightContext({
  allowRegex,
  children,
  searchTerm,
}: {
  allowRegex?: boolean;
  children: ReactNode;
  searchTerm?: string;
}): ReactElement {
  const ctx = useMemo(() => {
    const phrases = searchTerm ? splitSearchTerm(searchTerm, {allowRegex}) : [];
    return {allowRegex: !!allowRegex, phrases, searchTerm: searchTerm || ''};
  }, [allowRegex, searchTerm]);
  return <context.Provider value={ctx}>{children}</context.Provider>;
}

//#region <styles>
/*language=SCSS*/
const getStyle: FlowStyleBuilder = ({
  COLORS: {
    BACKGROUND: {HIGHLIGHT_SEARCH},
  },
}) => css.resolve`
  .highlighted {
    background-color: ${HIGHLIGHT_SEARCH};
  }

  .ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

//#endregion

interface Props {
  ellipsis?: boolean;
  text: string;
}

export function useSearchHighlightContext(): Context {
  return useContext(context);
}

function getPhraseRegExp(phrase: string, allowRegex: boolean): RegExp {
  const escaped = allowRegex ? phrase : escapeRegExp(phrase);
  //SQL LIKE matches umlauts with their non-umlaut counterpart, so the highlight should do the same:
  const escapedUmlautMatching = escaped.replace(/[aä]/gi, '[aä]').replace(/[oö]/gi, '[oö]').replace(/[uü]/gi, '[uü]');
  return new RegExp(`(${escapedUmlautMatching})`, 'giu');
}

function getTextMatchesSearch(phrases: string[], allowRegex = false): (text: string) => boolean {
  return (text) => {
    if (phrases.length) {
      return phrases.every((phrase) => getPhraseRegExp(phrase, allowRegex).test(text));
    }
    return false;
  };
}

interface NameEntity {
  name: string;
}

export function filterNameEntitiesByPhrases<T extends NameEntity>(entities: T[], phrases: string[]): T[] {
  if (phrases.length) {
    const matcher = getTextMatchesSearch(phrases);
    return entities.filter(({name}) => matcher(name));
  }
  return entities;
}

export function filterUnitsByPhrases<T extends {unitName: string}>(entities: T[], phrases: string[]): T[] {
  if (phrases.length) {
    const matcher = getTextMatchesSearch(phrases);
    return entities.filter(({unitName}) => matcher(unitName));
  }
  return entities;
}

export function filterTextByPhrases(text: string, phrases: string[]): boolean {
  if (phrases.length) {
    const matcher = getTextMatchesSearch(phrases);
    return matcher(text);
  }
  return true;
}

// noinspection FunctionNamingConventionJS
export function SearchHighlightText({ellipsis, text}: Props): ReactElement {
  const {allowRegex, phrases} = useSearchHighlightContext();
  const {className, styles} = useFlowStyle(getStyle);

  const children = useMemo(() => {
    let nodes: ReactNode[] = [text];

    if (phrases.length) {
      for (const phrase of phrases) {
        const testRegExp = getPhraseRegExp(phrase, allowRegex);
        if (testRegExp.test(text)) {
          const parts = text.split(testRegExp);

          nodes = parts.map((part, i) => {
            return (
              <span key={i} className={classNames(className, {highlighted: testRegExp.test(part)})}>
                {part}
              </span>
            );
          });

          // noinspection BreakStatementJS
          break;
        }
      }
    }

    return nodes;
  }, [allowRegex, className, phrases, text]);

  return (
    <span className={classNames(className, {ellipsis})}>
      {children}
      {styles}
    </span>
  );
}

export function useFilterBySearchContext<E extends NameEntity>(entities: E[]): E[] {
  const {phrases} = useSearchHighlightContext();
  return useMemo(() => filterNameEntitiesByPhrases(entities, phrases), [entities, phrases]);
}
