import {ActionDispatcher} from '@octaved/store/src/Store';
import {Selector} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FlowState} from '../../Modules/State';
import PagesSubMenu, {PagesSubMenuOption} from '../PagesSubMenu';

interface SubMenuProps {
  collapsedSelector?: Selector<boolean>;
  menuOptions: PagesSubMenuOption[];
  patchUi?: (data: {collapsedSubMenu: boolean}) => ActionDispatcher<void, FlowState>;
}

export default function SubMenu({collapsedSelector = () => false, menuOptions, patchUi}: SubMenuProps): ReactElement {
  const collapsedSubMenu = useSelector(collapsedSelector);
  const dispatch = useDispatch();
  const setCollapsedMenu = useCallback(
    (collapsedSubMenu: boolean) => {
      if (patchUi) {
        dispatch(patchUi({collapsedSubMenu}));
      }
    },
    [dispatch, patchUi],
  );
  return <PagesSubMenu collapsed={collapsedSubMenu} menuOptions={menuOptions} setCollapsed={setCollapsedMenu} />;
}
