import {Nodes} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {ReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {optimisticUpdate} from '@octaved/store/src/Reducer/OptimisticReduce';
import {FLOW_PATCH_PLANNING_FAILURE, FLOW_PATCH_PLANNING_REQUEST, FLOW_PATCH_PLANNING_SUCCESS} from '../ActionTypes';
import {PatchPlanningRequest} from '../Planning';

export function addPlanningToNodeEntityReducer(entityReducer: ReducerCollection<Nodes>): void {
  {
    const reducer = optimisticUpdate<Nodes, PatchPlanningRequest>(null, ({nodes}, state) => {
      if (nodes) {
        return {...state, ...nodes};
      }
      return state;
    });
    entityReducer.add(FLOW_PATCH_PLANNING_REQUEST, reducer.onRequest);
    entityReducer.add(FLOW_PATCH_PLANNING_FAILURE, reducer.onRevert);
    entityReducer.add(FLOW_PATCH_PLANNING_SUCCESS, reducer.onSuccess);
  }
}
