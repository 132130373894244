import {useOnUrlChange} from '@octaved/hooks';
import AppLoader from '@octaved/ui-components/src/React/AppLoader';
import {ComponentType, lazy, memo, ReactElement, Suspense, useCallback, useMemo} from 'react';
import {createPortal} from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import Drawer from '../Components/Feedback/Drawer/Drawer';
import InspectorDrawerPage from '../Components/Inspector/InspectorDrawerPage';
import {projectsMovePidSelector, projectsRearrangeProjectSelector} from '../Modules/Selectors/UiPages/ProjectsSelector';
import {
  editingTimeRecordSelector,
  showInboxSelector,
  showProjectFolderSelector,
  showQuickNotesSelector,
  uiSelector,
} from '../Modules/Selectors/UiSelectors';
import {setInspector, UiState} from '../Modules/Ui';
import MoveGroupOrWorkPackage from '../Pages/Projects/Drawer/MoveGroupOrWorkPackage/MoveGroupOrWorkPackage';
import RearrangeProject from '../Pages/Projects/Drawer/RearrangeProject/RearrangeProject';
import {DEFAULT_INSPECTOR_WIDTH} from './StandardInspector/StandardInspectorPage';

const ProjectFolder = lazy(() => import('./ProjectFolder/ProjectFolderInspector'));
const TimeRecordDrawerRouterInspector = lazy(() => import('./TimeRecord/TimeRecordDrawerRouterInspector'));
const InboxInspector = lazy(() => import('@octaved/inbox/src/InboxInspector'));
const QuickNotesInspector = lazy(() => import('./QuickNotes/QuickNotesInspector'));

const uiInspectorMap: Array<[keyof UiState, ComponentType]> = [
  ['editTimeRecord', TimeRecordDrawerRouterInspector],
  ['showInbox', InboxInspector],
  ['showProjectFolder', ProjectFolder],
  ['showQuickNotes', QuickNotesInspector],
];

const inspectorComponentSelector = createSelector(
  uiSelector,
  projectsMovePidSelector,
  projectsRearrangeProjectSelector,
  (ui, moveGroupOrWorkPackage, rearrangeProject) => {
    for (const [key, component] of uiInspectorMap) {
      if (ui[key]) {
        return component;
      }
    }
    if (moveGroupOrWorkPackage) {
      return MoveGroupOrWorkPackage;
    }
    if (rearrangeProject) {
      return RearrangeProject;
    }

    return null;
  },
);

export default memo(function InspectorDrawerRouter(): ReactElement {
  const inbox = useSelector(showInboxSelector);
  const projectFolder = useSelector(showProjectFolderSelector);
  const quickNotes = useSelector(showQuickNotesSelector);
  const moveGroupOrWorkPackage = useSelector(projectsMovePidSelector);
  const rearrangeProject = useSelector(projectsRearrangeProjectSelector);
  const editTimeRecord = useSelector(editingTimeRecordSelector);
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch(setInspector({})), [dispatch]);
  const Component = useSelector(inspectorComponentSelector);

  const width = useMemo(() => {
    if (rearrangeProject || moveGroupOrWorkPackage) {
      return '100vw';
    }
    if (inbox) {
      return '700px';
    }
    return DEFAULT_INSPECTOR_WIDTH;
  }, [rearrangeProject, moveGroupOrWorkPackage, inbox]);

  useOnUrlChange(() => dispatch(setInspector({})));

  return createPortal(
    <Drawer
      alwaysAbove
      visible={Component !== null}
      onClose={editTimeRecord || inbox || quickNotes || projectFolder ? undefined : onClose}
      noScroll={inbox}
      animationKey={'generalInspector'}
      emptyInspector={!!rearrangeProject || !!moveGroupOrWorkPackage}
      fullScreen={Boolean(rearrangeProject || moveGroupOrWorkPackage)}
    >
      <InspectorDrawerPage
        fixedHeight={Boolean(inbox || editTimeRecord || quickNotes || projectFolder)}
        noPadding={Boolean(
          inbox || rearrangeProject || quickNotes || moveGroupOrWorkPackage || editTimeRecord || projectFolder,
        )}
        width={width}
      >
        <Suspense fallback={<AppLoader />}>{Component && <Component />}</Suspense>
      </InspectorDrawerPage>
    </Drawer>,
    document.querySelector('body')!,
  );
});
