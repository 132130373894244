import {TreeNode} from '../Data/TreeNode';
import {BaseRow, BaseRowProps, CellRendererProps} from './BaseRow';
import {Cell} from './Cells/Cell';

interface DataRowProps extends BaseRowProps {
  node: TreeNode;
}

export class DataRow extends BaseRow {
  protected readonly node: TreeNode;

  constructor(props: DataRowProps) {
    super(props);
    this.node = props.node;
  }

  protected createCell(props: CellRendererProps): Cell | null {
    return this.tableFactory.getDataCellRenderClass(
      {columnName: props.columnName},
      {
        ctx: this.ctx,
        height: this.height,
        imageSize: this.imageSize,
        menuRoot: this.menuRoot,
        node: this.node,
        padding: this.padding,
        textLineOffset: this.textLineOffset,
      },
    );
  }
}
