import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback, useContext, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {Loader} from 'semantic-ui-react';
import DefaultPlaceholder from '../../../../Components/ProjectTree/DefaultPlaceholder';
import {ProjectTreeContextProvider} from '../../../../Components/ProjectTree/ProjectTreeContext';
import {projectsTreeComponentContext} from '../../../../Components/Tree/ProjectTreeComponentContext';
import {ProjectTreeOptions} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {useCreateDnDTree} from '../Components/CreateTree';
import {projectDndContext} from '../Components/ProjectDndContext';
import {ProjectDragObject, saveDrag} from '../Components/ProjectSortable';

interface RearrangeProjectTreeProps {
  projectId: Uuid;
}

export default function RearrangeProjectTree({projectId}: RearrangeProjectTreeProps): ReactElement {
  const {groupsAndWorkPackages: GroupsAndWorkPackages} = useContext(projectsTreeComponentContext);

  const {flat, hasLoadedOnce, isLoading, move, resetNodeTree, resetSortOrder, treeOptions, allNodes} = useCreateDnDTree(
    {
      extraQueries: useMemo<ProjectTreeOptions['extraQueries']>(
        () => ({projects: [{fixResult: [projectId]}]}),
        [projectId],
      ),
    },
    undefined,
    projectId,
  );

  const dispatch = useDispatch();

  const onDrop = useCallback(
    (dragObject: ProjectDragObject) => {
      saveDrag(dragObject, allNodes, dispatch, resetSortOrder, resetNodeTree);
    },
    [allNodes, dispatch, resetSortOrder, resetNodeTree],
  );

  const project = flat[0];
  const hasChildren = project?.children.length > 0;

  return (
    <div className={'relative'}>
      <Loader active={!hasLoadedOnce} />
      {!isLoading && flat.length === 0 && <DefaultPlaceholder />}
      <projectDndContext.Provider value={{move, onDrop}}>
        <ProjectTreeContextProvider options={treeOptions}>
          {hasChildren && <GroupsAndWorkPackages parentNode={project} />}
        </ProjectTreeContextProvider>
      </projectDndContext.Provider>
      {/**#region css */}
      {/*language=SCSS*/}
      <style jsx>{`
        .relative {
          position: relative;
        }
      `}</style>
      {/**#endregion */}
    </div>
  );
}
