import ComplexTrans from '@octaved/i18n/src/ComplexTrans';
import {cn, Icon, IconColor, Label} from '@octaved/ui';
import classNames from 'classnames';
import {MouseEventHandler, ReactElement, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {Action, AnyAction} from 'redux';
import {FlowState} from '../../../../Modules/State';
import {ThunkAct} from '../../../../Store/Thunk';
import DropdownLabel from './DropdownLabel';
import {
  DropdownPopupItemOptions,
  isDivider,
  isDropdownPopupItem,
  isHeader,
  isHeaderDivider,
  isItemWithExternalLink,
  isItemWithLink,
} from './Types';

interface Props {
  className: string;
  closeOnSelect: boolean;
  hovering: string | undefined;
  item: DropdownPopupItemOptions;
  noIcon: boolean;
  onClose: () => void;
}

function useIconColorSelector(item: DropdownPopupItemOptions): (state: FlowState) => IconColor {
  return useMemo((): ((state: FlowState) => IconColor) => {
    if (isDropdownPopupItem(item) || isItemWithLink(item) || isItemWithExternalLink(item)) {
      const color = item.iconColor;
      return typeof color === 'function' ? color : () => color || (item.disabled ? 'brightGrey' : 'blue');
    }
    return () => 'brightGrey';
  }, [item]);
}

export default function Item({className, closeOnSelect, hovering, item, noIcon, onClose}: Props): ReactElement | null {
  const dispatch = useDispatch();
  const iconColor = useSelector(useIconColorSelector(item));

  if (item.hidden) {
    return null;
  }
  if (isDivider(item)) {
    return <div className={classNames(className, 'divider')} />;
  }
  if (isHeader(item)) {
    return (
      <div className={classNames(className, 'header')}>
        <Label token={item.token} className={'text-slate-800'} uppercase />
        {item.subToken && (
          <div className={classNames(className, 'subHeader')}>
            <ComplexTrans translatable={item.subToken} />
          </div>
        )}
      </div>
    );
  }
  if (isHeaderDivider(item)) {
    return (
      <div className={classNames(className, 'header')}>
        <Label token={item.token} className={'text-slate-800'} uppercase />
      </div>
    );
  }

  if (isItemWithLink(item)) {
    return (
      <NavLink
        to={item.link}
        className={({isActive}) =>
          classNames(className, item.className, 'item', {
            disabled: item.disabled,
            selected: isActive,
          })
        }
        onClick={() => {
          if (closeOnSelect) {
            onClose();
          }
        }}
      >
        {!noIcon && (
          <div className={classNames(className, 'icon', {customIcon: item.customIcon})}>
            {item.icon && (
              <Icon iconColor={iconColor} size={item.iconSize}>
                {item.icon}
              </Icon>
            )}
            {item.customIcon}
          </div>
        )}
        <DropdownLabel className={className} item={item} />
      </NavLink>
    );
  }
  if (isItemWithExternalLink(item)) {
    return (
      <a
        target={item.linkTarget}
        href={item.link}
        className={classNames(className, item.className, 'item', {disabled: item.disabled, selected: item.selected})}
      >
        {!noIcon && (
          <div className={classNames(className, 'icon', {customIcon: item.customIcon})}>
            {item.icon && (
              <Icon iconColor={iconColor} size={item.iconSize}>
                {item.icon}
              </Icon>
            )}
            {item.customIcon}
          </div>
        )}
        <DropdownLabel className={className} item={item} />
      </a>
    );
  }

  const getInteraction =
    (callback?: (() => Action | ThunkAct<void>) | (() => void)): MouseEventHandler<HTMLDivElement> =>
    async (e): Promise<void> => {
      if (callback) {
        e.preventDefault();
        e.stopPropagation();
        if (closeOnSelect) {
          onClose();
        }
        const clickResult = await callback();
        if (clickResult) {
          dispatch(clickResult as AnyAction);
        }
      }
    };

  return (
    <div
      className={cn(
        className,
        'item',
        {
          disabled: item.disabled,
          hovering: hovering && item.id === hovering,
          selected: item.selected,
        },
        item.className,
      )}
      onMouseDown={getInteraction(item.onMouseDown)}
      onClick={getInteraction(item.onClick)}
    >
      {!noIcon && (
        <div className={classNames(className, 'icon', {customIcon: item.customIcon})}>
          {item.icon && (
            <Icon iconColor={iconColor} size={item.iconSize}>
              {item.icon}
            </Icon>
          )}
          {item.customIcon}
        </div>
      )}
      <DropdownLabel className={className} item={item}>
        {item.keyboardShortCut && (
          <div className={classNames(className, 'keyboardShortCut')}>{item.keyboardShortCut}</div>
        )}
      </DropdownLabel>
    </div>
  );
}
