import {Uuid} from '@octaved/typescript/src/lib';
import {createContext, useContext} from 'react';
import {useSelector} from 'react-redux';
import {Pid, WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {nodeEntitySelector} from '../../../Modules/Selectors/NodeSelectors';
import {isPid, isPidOrSubWOrkPackage, isWorkPackage} from '../../../Node/NodeIdentifiers';

interface State {
  id: Uuid | null;
}

const ProjectsInspectorContext = createContext<State>({
  id: null,
});
export default ProjectsInspectorContext;

export function useProjectTreeSelectedId(): Uuid | null {
  return useContext(ProjectsInspectorContext).id;
}

export function useProjectsSelectedWorkPackage(): WorkPackage | null {
  const id = useProjectTreeSelectedId();
  const workPackage = useSelector(nodeEntitySelector)[id || ''];
  if (isWorkPackage(workPackage)) {
    return workPackage;
  }
  return null;
}

export function useProjectsSelectedPid(): Pid | null {
  const id = useProjectTreeSelectedId();
  const pid = useSelector(nodeEntitySelector)[id || ''];
  if (isPid(pid)) {
    return pid;
  }
  return null;
}

export function useProjectsSelectedPidOrSubWorkPackage(): Pid | SubWorkPackage | null {
  const id = useProjectTreeSelectedId();
  const pid = useSelector(nodeEntitySelector)[id || ''];
  if (isPidOrSubWOrkPackage(pid)) {
    return pid;
  }
  return null;
}

export function useProjectsIsWorkPackageSelected(): boolean {
  return !!useProjectsSelectedWorkPackage();
}
