import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useOnOutsideClick} from '@octaved/hooks/src/OnOutsideClick';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {Check} from 'lucide-react';
import {ReactElement, useCallback, useRef} from 'react';
import {TaskSectionPatchData} from '../../../../EntityInterfaces/TaskSection';
import {createTaskSection, getDefaultTaskSectionCreationData} from '../../../../Modules/TaskSections';
import TaskGroup from '../../TaskGroup/TaskGroup';
import {useTaskListContext} from '../../TaskListContext';
import {useNewRowFeatures} from '../NewItemHooks';
import TaskSectionName from './TaskSectionName';

interface Props {
  onFinish: () => void;
  parentId: Uuid;
  previousSiblingId: Uuid | undefined;
}

export default function NewTaskSectionRow({parentId, previousSiblingId, onFinish}: Props): ReactElement {
  const {showNewTaskRowOnceForParentIds} = useTaskListContext();
  const {patch, save, snapshot} = useNewRowFeatures<TaskSectionPatchData>(
    getDefaultTaskSectionCreationData,
    createTaskSection,
    parentId,
    previousSiblingId,
    EnumFlowNodeType.VALUE_TASK_SECTION,
    useCallback(
      (section) => {
        showNewTaskRowOnceForParentIds.add(section.id);
        return section;
      },
      [showNewTaskRowOnceForParentIds],
    ),
  );

  const onOutsideClickRef = useRef<HTMLDivElement>(null);
  useOnOutsideClick(onOutsideClickRef, () => {
    save();
    onFinish();
  });

  const onNameBlur = useCallback(() => {
    save();
    onFinish();
  }, [onFinish, save]);
  const onEnterKeyDown = useCallback(() => save(), [save]);
  const patchName = useCallback((name: string) => patch({name}), [patch]);

  const dragRef = useRef<HTMLDivElement>(null); //not actually used, but forces the display of the drag handle
  return (
    <TaskGroup
      id={snapshot.id}
      ref={onOutsideClickRef}
      dragRef={dragRef}
      name={
        <div className={'row'} id={'newRow'}>
          <TaskSectionName
            autoFocus
            name={snapshot.name}
            onBlur={onNameBlur}
            onEnterKeyDown={onEnterKeyDown}
            patch={patchName}
          />
          <IconButton colorScheme={'add'} size={'xs'} icon={<Check />} iconStrokeWidth={'2'} />

          {/*#region styles*/}
          {/*language=scss*/}
          <style jsx>{`
            .row {
              display: flex;
              grid-gap: 10px;
              align-items: center;
            }

            #newRow :global(svg) {
              stroke: #fff !important;
              fill: none !important;
            }
          `}</style>
          {/*#endregion*/}
        </div>
      }
    />
  );
}
