import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {dispatch} from '@octaved/store/src/Store';
import {DeepPartial} from '@octaved/typescript/src/lib';
import objectContains from '@octaved/validation/src/ObjectContains';
import {defaultVersionInfo, VersionInfo} from './Version/Version';

const ENV_PATCH_STATE = 'ENV_PATCH_STATE';

export interface EnvironmentState {
  isInMaintenance: boolean;
  version: VersionInfo;
}

export interface StoreEnvironmentState {
  environment: EnvironmentState;
}

const defaultMaintenanceState: EnvironmentState = {
  isInMaintenance: false,
  version: defaultVersionInfo,
};

interface PatchEnvironment {
  patch: Partial<EnvironmentState>;
  type: typeof ENV_PATCH_STATE;
}

const reducers = createReducerCollection<EnvironmentState>(defaultMaintenanceState);
reducers.add(FLOW_INIT_LOAD_SUCCESS, (state, action: InitAction) => {
  return mergeStates(state, {version: action.response.result.version});
});
reducers.add(ENV_PATCH_STATE, (state, {patch}: PatchEnvironment) => {
  return mergeStates(state, patch);
});
export const environmentStateReducer = reducers.reducer;

const environmentSelector = (state: StoreEnvironmentState): EnvironmentState => state.environment;
export const isInMaintenanceSelector = (state: StoreEnvironmentState): boolean => state.environment.isInMaintenance;
export const versionSelector = (state: StoreEnvironmentState): VersionInfo => state.environment.version;
export const versionRevisionSelector = (state: StoreEnvironmentState): string => state.environment.version.revision;

export function patchEnvironmentState(patch: DeepPartial<EnvironmentState>): void {
  dispatch((disp, getState) => {
    //Due to the event listener `online` and `visibilitychange` in Maintenance.ts, this method can be called so early
    // that the store is not set up and this selector yields `undefined`. Hence the extra check:
    const prev = environmentSelector(getState());
    if (prev && !objectContains(prev, patch)) {
      disp({type: ENV_PATCH_STATE, patch});
    }
  });
}
