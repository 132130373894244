import JsonPreviewLazy from '@octaved/ui-components/src/JsonPreviewLazy';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {ReactElement} from 'react';
import {Message} from 'semantic-ui-react';
import {ResultStatus, WebhookCallResult} from '../Modules/Webhooks';

const resultStatusTexts = {
  [ResultStatus.STATUS_OK]: 'ok',
  [ResultStatus.STATUS_INVALID_RESPONSE_STATUS]: 'invalid response status code',
  [ResultStatus.STATUS_JSON_ERROR]: 'malformed json response',
  [ResultStatus.STATUS_JSON_INVALID]: 'invalid json format',
  [ResultStatus.STATUS_NO_SUBSCRIPTION]: 'no subscription',
  [ResultStatus.STATUS_NETWORK_ERROR]: 'network error',
};

export default function WebhookCallResultView({call}: {call: WebhookCallResult | null}): ReactElement | null {
  if (!call) {
    return null;
  }
  return (
    <>
      <Spacer spacing={40} />
      <Message positive={!call.status} negative={!!call.status}>
        <div>Status: {resultStatusTexts[call.status]}</div>
        {call.exception && (
          <div>
            Exception: <span className={'break'}>{call.exception}</span>
          </div>
        )}
        {!!call.responseStatusCode && <div>Response status code: {call.responseStatusCode}</div>}
        <div>
          Response body: <span className={'break'}>&apos;{call.rawResponse}&apos;</span>
        </div>
        {call.parsedResponse && (
          <>
            <div>Parsed response json:</div>
            <JsonPreviewLazy obj={call.parsedResponse} />
          </>
        )}
        {/*language=SCSS*/}
        <style jsx>{`
          .break {
            word-break: break-word;
          }
        `}</style>
      </Message>
    </>
  );
}
