import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {WorkPackage} from '../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../EntityInterfaces/SubWorkPackage';
import {Task} from '../../EntityInterfaces/Task';
import {UserNode} from '../../EntityInterfaces/UserNode';
import {isTask, isWorkPackage} from '../../Node/NodeIdentifiers';
import {getIsResponsible, getNodeSearchQueryResultsSelector} from './NodeSearchSelectors';
import {getNodeSelector, nodeEntitySelector} from './NodeSelectors';
import {getDepthSelector, getDescendantNodesSelector, getNodeAncestrySelector} from './NodeTreeSelectors';
import {rootFoldersUserFolderSelector} from './RootFolderSelectors';

export const getTaskSelector = createSelector(nodeEntitySelector, (nodes) =>
  memoize((id: Uuid): Task | undefined => {
    const node = nodes[id];
    if (node && !isTask(node)) {
      throw new Error(`task type is requested but '${node?.nodeType}' type is returned`);
    }
    return node;
  }),
);

const getPidTasksSelector = createSelector(getDescendantNodesSelector, (getChildNodes) =>
  memoize((pidId: Uuid) => getChildNodes(pidId).filter(isTask)),
);

export const taskCountSelector = createSelector(getPidTasksSelector, (getPidTasks) =>
  memoize((pidId: Uuid) => {
    const tasks = getPidTasks(pidId);
    return tasks.reduce(
      (counter, task) => {
        if (task.status === EnumFlowTaskStatus.VALUE_COMPLETE) {
          ++counter.completed;
          if (task.plannedTime) {
            counter.plannedTimeFinished += task.plannedTime;
          }
        } else {
          ++counter.open;
        }
        if (task.plannedTime) {
          counter.plannedTime += task.plannedTime;
        } else {
          counter.allPlanned = false;
        }

        ++counter.total;
        return counter;
      },
      {
        allPlanned: true,
        completed: 0,
        open: 0,
        plannedTime: 0,
        plannedTimeFinished: 0,
        total: 0,
      },
    );
  }),
);

export const getTaskWorkPackageSelector = createSelector(getNodeAncestrySelector, (getNodeAncestry) =>
  memoize((taskId: Uuid | null | undefined): null | WorkPackage =>
    taskId ? getNodeAncestry(taskId).workPackage : null,
  ),
);

export const getIsPersonalTask = createSelector(
  getNodeAncestrySelector,
  rootFoldersUserFolderSelector,
  (getNodeAncestry) => memoize((taskId: Uuid | null): boolean => (taskId ? !!getNodeAncestry(taskId).userNode : false)),
);

export const getTasksRootSelector = createSelector(getNodeAncestrySelector, (getNodeAncestry) =>
  memoize((taskId: Uuid | null): null | WorkPackage | SubWorkPackage | UserNode => {
    if (taskId) {
      const {userNode, workPackage, subWorkPackage} = getNodeAncestry(taskId);
      return userNode || subWorkPackage || workPackage;
    }
    return null;
  }),
);

export const getTaskIsAutoChainedSelector = createSelector(getTasksRootSelector, (getTasksRoot) =>
  memoize((taskId: Uuid | undefined) => {
    const root = taskId && getTasksRoot(taskId);
    return root && isWorkPackage(root) ? root.isAutoChainActive : false;
  }),
);

/**
 * Gets the task depth relative to its root (work package/user node)
 * Work Package > Task: 1
 * Work Package > Task > Task: 2
 *
 * Returns 0 for non-tasks.
 */
export const getTaskDepthSelector = createSelector(
  getNodeSelector,
  getTasksRootSelector,
  getDepthSelector,
  (getNode, getTasksRoot, getDepth) => (nodeId: Uuid) => {
    const node = getNode(nodeId);
    return isTask(node) ? getDepth(node.id) - getDepth(getTasksRoot(node.id)?.id) : 0;
  },
);

export const getTasksPidIdSelector = createSelector(getTaskWorkPackageSelector, (getTasksPid) =>
  memoize((taskId: Uuid | null): null | Uuid => {
    const workpackage = getTasksPid(taskId);
    if (workpackage) {
      return workpackage.id;
    }
    return null;
  }),
);

export const pidTaskHoursSelector = createSelector(getDescendantNodesSelector, (getChildNodes) =>
  memoize((pidId: Uuid) => {
    return getChildNodes(pidId)
      .filter(isTask)
      .reduce<number>((acc, {plannedTime}) => {
        if (plannedTime) {
          return acc + plannedTime;
        }
        return acc;
      }, 0);
  }),
);

export const canChangeTaskStatusSelector = createSelector(
  nodeEntitySelector,
  isGrantedSelector,
  getIsPersonalTask,
  currentOrgUserIdSelector,
  getNodeSearchQueryResultsSelector,
  (nodes, isGranted, isPersonalTask, orgUserId, getNodeSearchQueryResults) =>
    memoize((taskId: Uuid) => {
      const task = nodes[taskId];
      if (!task || task.isArchived) {
        return false;
      }
      if (isPersonalTask(taskId) || getNodeSearchQueryResults(getIsResponsible(orgUserId)).includes(taskId)) {
        return isGranted('FLOW_NODE_TASK_MARK_DONE_ASSIGNED', taskId);
      }
      return isGranted('FLOW_NODE_TASK_MARK_DONE_ANY', taskId);
    }),
);
