import {searchCustomersByNameViaWebhook, searchCustomersByNumberViaWebhook} from '@octaved/flow-api';
import {useDebounce} from '@octaved/hooks';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, isLoaded, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CustomerWebhookSeachResult, CustomerWebhookSearchStore} from '../EntityInterfaces/Customers';
import {
  FLOW_CUSTOMER_PROVIDER_SEARCH_FAILURE,
  FLOW_CUSTOMER_PROVIDER_SEARCH_REQUEST,
  FLOW_CUSTOMER_PROVIDER_SEARCH_SUCCESS,
} from './ActionTypes';
import {
  customerWebhookSearchResultsSelector,
  customerWebhookSearchStateSelector,
} from './Selectors/Customer/CustomerWebhookSearchSelectors';
import {customerNamesLowerCaseSelector} from './Selectors/CustomerSelectors';
import {FlowState} from './State';

interface SearchActionResponse {
  key: string;
  response: CustomerWebhookSeachResult[];
}

const resultReducerMap = new Map();
resultReducerMap.set(
  FLOW_CUSTOMER_PROVIDER_SEARCH_SUCCESS,
  (state: CustomerWebhookSearchStore['results'], {key, response}: SearchActionResponse) => {
    return {...state, [key]: response};
  },
);

const stateReducerMap = new Map();
stateReducerMap.set(FLOW_CUSTOMER_PROVIDER_SEARCH_REQUEST, createTimestampReducer('key', LOADING));
stateReducerMap.set(FLOW_CUSTOMER_PROVIDER_SEARCH_SUCCESS, createTimestampReducer('key', LOADED));

export const customerWebhookSearch = {
  results: ReduceFromMap(resultReducerMap),
  state: ReduceFromMap(stateReducerMap),
};

function getKey(searchBy: 'customerName' | 'customerNumber', search: string): string {
  return `${searchBy}@@${search}`;
}

function searchAction(searchBy: 'customerName' | 'customerNumber', search: string): ActionDispatcher<void, FlowState> {
  const key = getKey(searchBy, search);
  return (dispatch, getState) => {
    const state = customerWebhookSearchStateSelector(getState())[key];
    if (!state || isOutdated(state)) {
      dispatch({
        key,
        [CALL_API]: {
          endpoint: searchBy === 'customerName' ? searchCustomersByNameViaWebhook : searchCustomersByNumberViaWebhook,
          method: 'get',
          options: {
            urlParams: {search},
          },
          types: {
            failureType: FLOW_CUSTOMER_PROVIDER_SEARCH_FAILURE,
            requestType: FLOW_CUSTOMER_PROVIDER_SEARCH_REQUEST,
            successType: FLOW_CUSTOMER_PROVIDER_SEARCH_SUCCESS,
          },
        },
      });
    }
  };
}

export function useCustomerWebhookSearch(searchBy: 'customerName' | 'customerNumber'): {
  searchValue: string;
  setSearchValue: (s: string) => void;
  results: CustomerWebhookSeachResult[];
  isLoading: boolean;
} {
  const excluded = useSelector(customerNamesLowerCaseSelector);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState<CustomerWebhookSeachResult[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const searchValueRef = useRef('');
  const dispatch = useDispatch();
  const doSearch = useDebounce(
    useCallback(() => {
      dispatch(searchAction(searchBy, searchValueRef.current));
    }, [dispatch, searchBy]),
  );

  const rawResults = useSelector(customerWebhookSearchResultsSelector);
  const rawState = useSelector(customerWebhookSearchStateSelector);

  useEffect(() => {
    if (!searchValue) {
      return;
    }
    const key = getKey(searchBy, searchValue);
    const searchState = rawState[key];
    const isLoading = !searchState || !isLoaded(searchState);
    if (!isLoading) {
      setLoading(false);
      setResults(
        (rawResults[key] || []).filter((item) => {
          return !excluded.has(item.customerName.toLocaleLowerCase());
        }),
      );
    }
  }, [searchValue, excluded, searchBy, rawState, rawResults]);

  return {
    isLoading,
    results,
    searchValue,
    setSearchValue: useCallback(
      (value: string) => {
        searchValueRef.current = value;
        setSearchValue(value);
        if (value) {
          const key = getKey(searchBy, value);
          const searchState = rawState[key];
          setLoading(!searchState || !isLoaded(searchState));
          doSearch();
        } else {
          setResults([]);
        }
      },
      [doSearch, rawState, searchBy],
    ),
  };
}
