import {TWLabel} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {Checkbox} from 'semantic-ui-react';

interface Props {
  includeTasks: boolean;
  setIncludeTasks: (i: boolean) => void;
}

export default function IncludeTasks({includeTasks, setIncludeTasks}: Props): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'flex flex-col gap-y-2'}>
      <TWLabel>{t('nodeDetails:field.export.label')}</TWLabel>
      <Checkbox
        checked={includeTasks}
        onClick={() => setIncludeTasks(!includeTasks)}
        label={<label>{t('pages:projects.inspector.offer.export.taskAsPositions')}</label>}
      />
    </div>
  );
}
