import {TWDialog, TWDialogBody, TWDialogContent, TWDialogHeader, TWDialogTitle} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import LogoSvg from '../svgs/logo.svg?tsx';

export default function InMaintenancePopup(): ReactElement {
  const {t} = useTranslation();
  return (
    <TWDialog open>
      <TWDialogContent size={'md'}>
        <LogoSvg />
        <TWDialogHeader noClose>
          <TWDialogTitle>{t('dialogs:inMaintenance.title')}</TWDialogTitle>
        </TWDialogHeader>
        <TWDialogBody>{t('dialogs:inMaintenance.description')}</TWDialogBody>
      </TWDialogContent>
    </TWDialog>
  );
}
