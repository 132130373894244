import RunningSvg from '@fortawesome/fontawesome-pro/svgs/solid/running.svg';
import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {useNodeColor} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {Icon} from '@octaved/ui';
import {useDarkenColor} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import classNames from 'classnames';
import {noop} from 'lodash';
import {ArrowDown} from 'lucide-react';
import {ReactElement, useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getWidth} from '../../../../../Calculations/DateCalculations';
import {calendarContainerContext} from '../../CalendarContainerContext';
import ActionBar from './ActionBar';
import {PlanningBarProps} from './PlanningBarProps';

export default function GroupBar({
  isExpanded,
  toggleExpanded = noop,
  showText,
  text,
  id,
  onClick,
  startCanBeResized,
  endCanBeResized,
  readonly,
  canBeDragged,
  canDndDependency,
  isCreating,
  planningDate,
}: PlanningBarProps): ReactElement {
  const {cellWidth} = useContext(calendarContainerContext);
  const rootGroupType = useSelector(getRootGroupTypeSelector)(id);
  const isSprint = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
  const bottomColor = useNodeColor(id);
  const topColor = useDarkenColor(bottomColor, 5);
  const gradient = `linear-gradient(0deg, ${topColor} 0%, #${bottomColor} 100%)`;

  const widthInDays = useMemo(
    () => getWidth(isoDateTimeToIsoFormat(planningDate.plannedStart), isoDateTimeToIsoFormat(planningDate.plannedEnd)),
    [planningDate.plannedEnd, planningDate.plannedStart],
  );

  return (
    <>
      <ActionBar
        id={id}
        text={text}
        showText={showText}
        startCanBeResized={startCanBeResized}
        endCanBeResized={endCanBeResized}
        canBeDragged={canBeDragged}
        canDndDependency={canDndDependency}
        planningDate={planningDate}
        isCreating={isCreating}
        readonly={readonly}
      >
        {isExpanded && (
          <div
            className={classNames('visibleBar', {isSprint, isGhost: isCreating})}
            onClick={readonly ? undefined : onClick}
          >
            {isSprint && (
              <div>
                <Icon iconColor={'white'} size={'big'}>
                  <RunningSvg />
                </Icon>
              </div>
            )}
            <div className={'leftArrow arrow'}></div>
            <div className={'rightArrow arrow'}></div>
          </div>
        )}
        {!isExpanded && (
          <div className={'wrapper'} onClick={() => toggleExpanded(true)}>
            <div className={classNames('collapsed visibleBar')}>
              <div className={'expandIcons'}>
                <Icon iconColor={'darkGrey'} noMargin>
                  <ArrowDown />
                </Icon>
                {cellWidth * widthInDays > 50 && (
                  <Icon iconColor={'darkGrey'} noMargin>
                    <ArrowDown />
                  </Icon>
                )}
              </div>

              <div className={'leftArrow arrow'}></div>
              <div className={'rightArrow arrow'}></div>
            </div>
          </div>
        )}
      </ActionBar>
      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .smallView {
          padding: 5px 0;
        }

        .visibleBar {
          background-color: #7a7a7a;
          height: 4px;
          top: 50%;
          position: absolute;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          margin-top: -3px;
        }

        .isGhost {
          opacity: 0.5;
        }

        .arrow {
          position: absolute;
          top: 2px;
          width: 0;
          height: 0;
          border-bottom: 8px solid transparent;
        }

        .leftArrow {
          left: 0;
          border-right: 8px solid transparent;
          border-left: 8px solid #7a7a7a;
        }

        .rightArrow {
          right: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid #7a7a7a;
        }

        .isSprint {
          background: rgb(12, 96, 175);
          background: ${gradient};
          height: 100%;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 0;
        }

        .isSprint .arrow {
          display: none;
        }

        .collapsed {
          cursor: pointer;
          background-color: #${bottomColor};

          & .leftArrow {
            border-left-color: #${bottomColor};
          }

          & .rightArrow {
            border-right-color: #${bottomColor};
          }
        }

        .expandIcons {
          display: flex;
          align-items: center;
          justify-content: space-around;
          opacity: 0;
          transition: opacity 120ms ease-in-out;

          & :global(svg) {
            stroke: #${bottomColor};
          }
        }

        .wrapper {
          cursor: pointer;
          height: 100%;
          width: 100%;
        }

        .wrapper:hover .expandIcons {
          opacity: 1;
        }
      `}</style>
      {/*#endregion*/}
    </>
  );
}
