import {Icon, IconButton} from '@octaved/ui';
import {useKeyboardListener} from '@octaved/ui-components/src/Hooks/UseKeydownListener';
import classNames from 'classnames';
import {debounce as debounceFn} from 'lodash';
import {Loader2, Search as SearchIcon, X} from 'lucide-react';
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';

interface BaseProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'value' | 'onChange' | 'ref' | 'className'
  > {
  canClearSearch?: boolean;
  debounce?: number;
  focusOnCtrlSpace?: boolean;
  fullWidth?: boolean;
  leftAttached?: boolean;
  onClearSearch?: () => void;
  onEnterKey?: (value: string) => void;
  onLeave?: (value: string) => void;
  search?: string;
  searchFieldRef?: RefObject<HTMLInputElement>;
  setSearch?: (value: string) => void;
  suffixContent?: ReactNode;
}

interface ControlledProps extends BaseProps {
  search: string;
  setSearch: (value: string) => void;
}

interface UncontrolledProps extends BaseProps {
  setSearch?: never;
}

type Props = ControlledProps | UncontrolledProps;

export default function Search({
  canClearSearch = false,
  debounce,
  focusOnCtrlSpace = true,
  fullWidth = false,
  leftAttached,
  onClearSearch,
  onEnterKey,
  onLeave,
  search,
  searchFieldRef,
  setSearch,
  suffixContent,
  ...props
}: Props): ReactElement {
  const {t} = useTranslation();
  const [value, setValue] = useState(search || '');
  useEffect(() => setValue(search || ''), [search]);
  const localInputRef = useRef<HTMLInputElement>(null);
  const inputRef = searchFieldRef || localInputRef;

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (onEnterKey && e.key === 'Enter' && inputRef.current) {
        e.preventDefault();
        onEnterKey(inputRef.current.value);
      }
    },
    [inputRef, onEnterKey],
  );

  const onBlur = useCallback(() => {
    if (onLeave && inputRef.current) {
      onLeave(inputRef.current.value);
    }
  }, [inputRef, onLeave]);

  const onKeyboard = useCallback(
    (e) => {
      if (e.ctrlKey && e.key === ' ' && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef],
  );
  useKeyboardListener(focusOnCtrlSpace ? onKeyboard : null);

  const [debouncePending, setDebouncePending] = useState(false);
  const debounceSearchFn = useRef(
    debounceFn((s: string): void => {
      setSearch?.(s);
      setDebouncePending(false);
    }, debounce),
  );

  return (
    <div className={classNames('search', {leftAttached, fullWidth})}>
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (debounce) {
            setDebouncePending(true);
            debounceSearchFn.current(e.target.value);
          } else {
            setSearch?.(e.target.value);
          }
        }}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={inputRef}
        className={'input'}
        type={'text'}
        placeholder={t('general:search') || undefined}
        autoComplete={'off'}
        {...props}
      />

      {suffixContent && <div className={'suffixContent'}>{suffixContent}</div>}

      {canClearSearch && !debouncePending && (
        <div className={'resetButton'}>
          <IconButton
            variant={'ghost'}
            icon={<X strokeWidth={1} />}
            size={'xs'}
            iconSize={'small'}
            iconColor={'darkGrey'}
            onClick={() => {
              setValue('');
              onClearSearch?.();
            }}
          />
        </div>
      )}

      {!canClearSearch && !debouncePending && (
        <div className={'searchButton'}>
          <Icon iconColor={'darkGrey'} noMargin size={'big'}>
            <SearchIcon strokeWidth={1} />
          </Icon>
        </div>
      )}

      {debouncePending && (
        <div className={'searchButton noPointer'}>
          <Icon iconColor={'darkGrey'} noMargin size={'big'} className={'spin'}>
            <Loader2 strokeWidth={1} />
          </Icon>
        </div>
      )}

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .search {
          height: 29px;
          flex-grow: 1;
          background-color: #fff;
          border-radius: 6px;
          display: flex;
          align-items: stretch;
          border: 1px solid #ccc;

          &.leftAttached {
            border-radius: 0 4px 4px 0;
          }

          &:focus-within {
            border-color: #2f2f2f;
          }
        }

        .input {
          user-select: text;
          outline: transparent;
          padding: 4px 14px;
          width: 180px;
          height: 100%;
          border: none !important;
          background-color: transparent;
          appearance: none;
          border-radius: 0;
        }

        .fullWidth .input {
          width: 100%;
        }

        .searchButton {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 4px;
          cursor: pointer;
        }

        .noPointer {
          cursor: default;
        }

        .resetButton {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 calc(4px - 0.1em);
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
