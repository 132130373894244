import {Check} from 'lucide-react';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createCircleOption} from '../Components/Form/Dropdown/DropdownPopup/CircleOption';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../Components/Form/Dropdown/DropdownPopup/Types';
import {useTimeTrackingSelector} from '../Modules/Selectors/SettingSelectors';
import {workPackageOptionsUiSelector} from '../Modules/Selectors/UiSelectors';
import {setUiState, UiState} from '../Modules/Ui';
import {ThunkAct} from '../Store/Thunk';

export function useWorkpackageOptions({path}: {path?: boolean} = {}): DropdownPopupItemOptions[] {
  const options = useSelector(workPackageOptionsUiSelector);
  const useTimeTracking = useSelector(useTimeTrackingSelector);

  return useMemo(() => {
    const opts: DropdownPopupItemOptions[] = [
      {
        token: 'workPackage:displayOptions.optionsHeader',
        type: DropdownPopupItemType.header,
      },
      {
        hidden: !path,
        icon: <Check />,
        iconColor: options.showPath ? 'green' : 'white',
        iconSize: 'small',
        onClick: () => setUiState({workpackageView: {showPath: !options.showPath}}),
        token: 'workPackage:displayOptions.path',
        type: DropdownPopupItemType.item,
      },
      {
        icon: <Check />,
        iconColor: options.showLabels ? 'green' : 'white',
        iconSize: 'small',
        onClick: () => setUiState({workpackageView: {showLabels: !options.showLabels}}),
        token: 'workPackage:displayOptions.labels',
        type: DropdownPopupItemType.item,
      },
      {
        icon: <Check />,
        iconColor: options.showPlanning ? 'green' : 'white',
        iconSize: 'small',
        onClick: () => setUiState({workpackageView: {showPlanning: !options.showPlanning}}),
        token: 'workPackage:displayOptions.planning',
        type: DropdownPopupItemType.item,
      },
      {
        icon: <Check />,
        iconColor: options.showStatusIcon ? 'green' : 'white',
        iconSize: 'small',
        onClick: () => setUiState({workpackageView: {showStatusIcon: !options.showStatusIcon}}),
        token: 'workPackage:displayOptions.statusIcons',
        type: DropdownPopupItemType.item,
      },
    ];

    if (useTimeTracking) {
      type ProgressBar = UiState['workpackageView']['progressBar'];
      const selector = (): ProgressBar => options.progressBar;
      const setter = (progressBar: ProgressBar): ThunkAct<void> => setUiState({workpackageView: {progressBar}});
      opts.push(
        {
          token: 'workPackage:progressBarOptions.optionsHeader',
          type: DropdownPopupItemType.header,
        },
        createCircleOption('trackedTime', selector, setter, 'workPackage:progressBarOptions.trackedTime'),
        createCircleOption('completedTasks', selector, setter, 'workPackage:progressBarOptions.completedTasks'),
      );
    }

    return opts;
  }, [
    path,
    options.showPath,
    options.showLabels,
    options.showPlanning,
    options.showStatusIcon,
    options.progressBar,
    useTimeTracking,
  ]);
}
