import {Dispatch, ReactElement, SetStateAction, useContext} from 'react';
import SearchableCustomerSelector from '../../../../Components/Customer/CustomerSelector/SearchableCustomerSelector';
import Search from '../../../../Components/Form/Search/Search';
import ProjectFavoritesToggle from '../../../../Components/ProjectTree/Controls/ProjectFavoritesToggle';
import ProjectTreeEllipsisMenu from '../../../../Components/ProjectTree/Controls/ProjectTreeEllipsisMenu';
import SortAndGroupOptions from '../../../../Components/ProjectTree/Controls/SortAndGroupOptions';
import {flowStyleContext} from '../../../../Styles/StyleContext';

interface SearchAndSortProps {
  search: string;
  setSearch: (search: string) => void;
  selectedCustomer: string;
  setSelectedCustomer: Dispatch<SetStateAction<string>>;
}

export default function SearchAndSort({
  search,
  setSearch,
  selectedCustomer,
  setSelectedCustomer,
}: SearchAndSortProps): ReactElement {
  const {
    COLORS: {BACKGROUND, BORDER},
  } = useContext(flowStyleContext);

  return (
    <div className={'header'}>
      <div className={'searchAndFilter'}>
        <Search
          canClearSearch={search.length > 0}
          onEnterKey={setSearch}
          onLeave={setSearch}
          onClearSearch={() => setSearch('')}
        />
        <SearchableCustomerSelector
          onChange={setSelectedCustomer}
          value={selectedCustomer}
          pageHeaderStyle
          canSelectAll
          showLockedCustomers
        />
        <div className={'buttonGrid'}>
          <SortAndGroupOptions />
          <ProjectFavoritesToggle />
          <ProjectTreeEllipsisMenu />
        </div>
      </div>

      {/*language=SCSS*/}
      <style jsx>{`
        .header {
          border-bottom: 1px solid ${BORDER.WEAK};
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px 0 20px;
          position: relative;
          z-index: 8;
          background-color: ${BACKGROUND.ALTERNATIVE};
          flex-shrink: 0;
        }

        .searchAndFilter {
          display: flex;
          align-items: center;
          grid-column-gap: 20px;
        }

        .buttonGrid {
          display: grid;
          align-items: center;
          grid-template-columns: max-content max-content max-content max-content;
          grid-column-gap: 10px;
        }
      `}</style>
    </div>
  );
}
