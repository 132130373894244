import {EnumFlowPidBillingType, EnumFlowPidFreeOfChargeReason} from '@octaved/env/src/dbalEnumTypes';
import {useTimeEffortPriceSumsPerBillingType} from '@octaved/flow/src/Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {createTotals} from '@octaved/flow/src/Pages/Projects/Inspector/Common/ParentTimeSums/TotalComputers';
import {freeOfChargeTranslations} from '@octaved/flow/src/WorkPackage/FreeOfCharge';
import {Uuid} from '@octaved/typescript/src/lib';
import {Fragment, ReactElement, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Hours from './Components/TimeSums/Hours';

export interface NodeSumFreeOfChargeProps {
  workPackageIds: Uuid[];
}

export default function NodeSumFreeOfCharge({
  frame: Frame,
  workPackageIds,
}: NodeSumFreeOfChargeProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const {sums} = useTimeEffortPriceSumsPerBillingType(workPackageIds);
  const totals = useMemo(() => createTotals(sums), [sums]);
  const sum = sums[EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE];
  if (!sum) {
    return null;
  }
  const reasons = Object.keys(sum.reasons) as EnumFlowPidFreeOfChargeReason[];

  return (
    <Frame labelToken={'nodeDetails:field.sumFreeOfCharge.label'}>
      <div className={'grid grid-cols-[max-content_1fr_max-content] items-center gap-x-4 gap-y-1 pt-2'}>
        {reasons.map((reason) => (
          <Fragment key={reason}>
            <Trans i18nKey={freeOfChargeTranslations[reason]} />
            <div />
            <Hours hours={sum.reasons[reason]!.billableHours} shortUnit />
          </Fragment>
        ))}

        <div className={'col-span-3 my-1 h-px bg-slate-200'} />

        <div className={'font-semibold'}>{t('nodeDetails:field.controllingTimeTracking.total')}</div>
        <div />
        <Hours hours={totals.neverBillableHours} shortUnit />
      </div>
    </Frame>
  );
}
