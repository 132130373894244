import {RestoreFromTrash} from '../../EntityInterfaces/RestoreFromTrash';
import {FlowState} from '../State';

function trash<R>(selector: (state: RestoreFromTrash) => R): (rootState: FlowState) => R {
  return (rootState: FlowState) => selector(rootState.restoreFromTrash);
}

export const restorableNodeCountsSelector = trash((state) => state.nodeCounts.results);
export const restorableNodeCountsStateSelector = trash((state) => state.nodeCounts.state);

export const restorableNodesSelector = trash((state) => state.nodes.results);
export const restorableNodesStateSelector = trash((state) => state.nodes.state);

export const restorableTimeRecordsSelector = trash((state) => state.timeRecords.results);
export const restorableTimeRecordsStateSelector = trash((state) => state.timeRecords.state);
