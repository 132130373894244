import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {IsGranted, isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {getAllDescendantsForRootId} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {TreeNodeData} from '@octaved/ui-components/src/React/Tree/Tree';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {Nodes} from '../../EntityInterfaces/Nodes';
import {NodeTree} from '../../EntityInterfaces/NodeTree';
import {ProjectFolder} from '../../EntityInterfaces/ProjectFolder';
import {isProjectFolder} from '../../Node/NodeIdentifiers';
import {getNodeSearchSelector} from './NodeSearchSelectors';
import {nodeEntitySelector} from './NodeSelectors';
import {
  getAllDescendantIdsSelector,
  InvertedNodeTree,
  nodeTreeInvertedSelector,
  nodeTreeSelector,
} from './NodeTreeSelectors';

export type ProjectFolderTreeNode = TreeNodeData<Uuid>;

export const getProjectFolderSelector = createSelector(nodeEntitySelector, (nodes) =>
  memoize((id: Uuid | undefined | null): ProjectFolder | undefined => {
    const node = nodes[id!];
    if (node && !isProjectFolder(node)) {
      throw new Error(`Project folder requested, but '${node?.nodeType}' type found`);
    }
    return node;
  }),
);

export function buildProjectFolderTreeNodes(
  nodeTree: NodeTree,
  nodeTreeInverted: InvertedNodeTree,
  readableProjectFolderIds: ReadonlySet<Uuid>,
  isGranted: IsGranted,
  nodes: Nodes,
  parentProjectFolderId: Uuid | null,
  parentNode: ProjectFolderTreeNode | null,
): ProjectFolderTreeNode[] {
  return (nodeTreeInverted.get(parentProjectFolderId) || []).reduce<ProjectFolderTreeNode[]>((acc, id) => {
    const allDescendantIds = [...getAllDescendantsForRootId(nodeTree, id)];
    const hasReadableDescendant = allDescendantIds.some((id) => readableProjectFolderIds.has(id));
    if (readableProjectFolderIds.has(id) || hasReadableDescendant) {
      const node: ProjectFolderTreeNode = {
        allDescendantIds,
        id,
        parentNode,
        children: [],
        name: nodes[id]?.name || '',
      };
      node.children = buildProjectFolderTreeNodes(
        nodeTree,
        nodeTreeInverted,
        readableProjectFolderIds,
        isGranted,
        nodes,
        id,
        node,
      );
      acc.push(node);
    }
    return acc;
  }, []);
}

const projectFolderIdsSearchSelector = getNodeSearchSelector('nodeType', EnumFlowNodeType.VALUE_PROJECT_FOLDER);

export const projectFolderTreeNodesSelector = createSelector(
  nodeTreeSelector,
  nodeTreeInvertedSelector,
  projectFolderIdsSearchSelector,
  nodeEntitySelector,
  isGrantedSelector,
  (nodeTree, nodeTreeInverted, projectFolderIds, nodes, isGranted): ProjectFolderTreeNode[] => {
    return buildProjectFolderTreeNodes(nodeTree, nodeTreeInverted, projectFolderIds, isGranted, nodes, null, null);
  },
);

export const allDescendantsAreProjectFoldersSelector = createSelector(
  getAllDescendantIdsSelector,
  nodeEntitySelector,
  (getAllDescendantIds, nodes) =>
    memoize((ouId: Uuid): boolean => {
      const descendantIds = getAllDescendantIds(ouId, true);
      return descendantIds.size > 0 && [...descendantIds].every((id) => isProjectFolder(nodes[id]));
    }),
);

export const getSearchResultProjectFolderSelector = createSelector(
  projectFolderIdsSearchSelector,
  nodeEntitySelector,
  (projectFolderIds, nodes) => {
    return [...projectFolderIds].map((id) => ({id, title: nodes[id]?.name || ''}));
  },
);

export const getProjectFolderForNodeSelector = createSelector(nodeTreeSelector, nodeEntitySelector, (nodeTree, nodes) =>
  memoize((id: Uuid | null | undefined): ProjectFolder | null => {
    let parent = id;
    do {
      const node = nodes[parent!];
      if (isProjectFolder(node)) {
        return node;
      }
      parent = nodeTree[parent!] as Uuid;
    } while (parent);

    return null;
  }),
);
