import {ReactElement, useCallback, useEffect, useState} from 'react';
import TourSelection from './TourSelection';

let showTourSelectionRef: (() => void) | null = null;

export function openTourSelection(): void {
  if (showTourSelectionRef) {
    showTourSelectionRef();
  }
}

export default function EntryPoint(): ReactElement {
  const [showTourSelection, setShowTourSelection] = useState(false);

  const closeAll = useCallback((): void => {
    setShowTourSelection(false);
  }, []);

  useEffect(() => {
    showTourSelectionRef = (): void => {
      closeAll();
      setShowTourSelection(true);
    };
    return () => {
      showTourSelectionRef = null;
    };
  }, [closeAll]);

  return <>{showTourSelection && <TourSelection close={closeAll} />}</>;
}
