export function isResponse(response: unknown): response is Response {
  return (
    !!response &&
    typeof response === 'object' &&
    'status' in response &&
    typeof (response as Response).clone === 'function' &&
    typeof (response as Response).json === 'function' &&
    typeof (response as Response).text === 'function'
  );
}
