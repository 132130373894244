import {useElementClientRect} from '@octaved/hooks';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {ReactElement, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Popup} from 'semantic-ui-react';
import {labelEntitiesSelector} from '../../../../../../Modules/Selectors/LabelSelectors';
import FlowLabel from '../../../../../Label/FlowLabel/FlowLabel';

interface Props {
  labels: Uuid[];
}

export default function LabelView({labels}: Props): ReactElement {
  const labelEntities = useSelector(labelEntitiesSelector);
  const ref = useRef<HTMLDivElement>(null);
  const assignedLabels = useMemo(
    () => boolFilter(labels.map((labelId) => labelEntities[labelId])).sort((a, b) => a.name.localeCompare(b.name)),
    [labelEntities, labels],
  );
  const hiddenRect = useElementClientRect(ref, 0, [labels, assignedLabels.length]);
  const hasOverflow = useMemo(() => labels && (hiddenRect?.height || 0) > 19, [hiddenRect, labels]);

  return (
    <div className={'container'}>
      <div className={'gridContainer'}>
        {!hasOverflow && assignedLabels.map((label) => <FlowLabel noEllipsis label={label} key={label.id} />)}
        {hasOverflow &&
          assignedLabels.map((label) => (
            <Popup
              key={label.id}
              on={'hover'}
              basic
              mouseEnterDelay={1000}
              trigger={
                <div className={'labelPile'} style={{backgroundColor: `#${label.color}`}}>
                  {label.name.includes('::') && <div className={'twoLevelPileMarker'} />}
                </div>
              }
            >
              <FlowLabel label={label} small={false} />
            </Popup>
          ))}
      </div>

      <div className={'gridContainer hidden'} ref={ref}>
        {assignedLabels.map((label) => (
          <FlowLabel noEllipsis label={label} key={label.id} />
        ))}
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .container {
          width: auto;
          position: relative;
          width: 100%;
        }

        .gridContainer {
          display: flex;
          grid-gap: 5px;
        }

        .hidden {
          position: absolute;
          top: 0;
          left: 0;
          visibility: hidden;
          flex-wrap: wrap;
        }

        .labelPile {
          height: 6px;
          width: 25px;
          border-radius: 3px;
          padding: 2px;
          position: relative;
        }

        .twoLevelPileMarker {
          position: absolute;
          top: 1px;
          right: 1px;
          bottom: 1px;
          width: 11px;
          background-color: var(--color-bg);
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
