import {FilterStatesValueArray, isComplete} from '../../../Modules/Filter/MergeFilterStatesWithDefaults';
import {PrognosisLevel, prognosisLevels} from '../../../Modules/Hooks/Projects/ProjectControlling/TaskTimePrognosis';

export interface QuickControlCriticalTimePrognosis {
  levels: FilterStatesValueArray<PrognosisLevel>;
}

export const quickControlCriticalTimePrognosisDefault: QuickControlCriticalTimePrognosis = {
  levels: prognosisLevels,
};

quickControlCriticalTimePrognosisDefault.levels[isComplete] = true;
