import {drawerPinnedSelector} from '@octaved/flow/src/Modules/Selectors/UiSelectors';
import ErrorBoundary from '@octaved/ui-components/src/React/ErrorBoundary';
import {PropsWithChildren, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {cn} from './Hooks';

export interface FluidInspectorDrawerProps {
  isEmpty: boolean;
}

export function FluidInspectorDrawer({children, isEmpty}: PropsWithChildren<FluidInspectorDrawerProps>): ReactElement {
  const drawerPinned = useSelector(drawerPinnedSelector);

  return (
    <aside
      className={cn(
        'relative w-inspector flex-shrink-0 border-l border-l-slate-200 bg-white transition-all duration-200',
        !drawerPinned && 'absolute bottom-0 right-0 top-0 z-10 border-l-slate-50',
        !drawerPinned && isEmpty && '-right-[600px]',
      )}
    >
      {!drawerPinned && (
        <div className={'absolute bottom-2 left-1 top-2 -z-[1] w-px shadow-[-3px_0px_20px_0px_#000]'} />
      )}
      <div className={'absolute inset-0 bg-white'}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </aside>
  );
}
