import {anyNode} from '@octaved/security/src/Authorization/Authorization';
import {isInternalUserType} from '@octaved/users/src/UserType';
import {Layers} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import StandardInspectorSubMenu from '../../Drawer/StandardInspector/StandardInspectorSubMenu';
import {MenuRootItem} from '../FlowRoutes';
import {boardRoutes} from './Boards';
import {dialogRouteObjects} from './Dialogs';
import {getStandardInspectorRoutes} from './Inspectors/StandardInspector';
import {editProjectsRoute, kanbanBoardsRoute, projectsRoute, teamTasksRoute} from './Routes';

const Edit = lazy(() => import('../../Pages/Projects/Pages/Edit/Edit'));
const NodeTablePage = lazy(() => import('@octaved/node-table/src/NodeTablePage'));
const Planning = lazy(() => import('../../Pages/Planning/Planning'));
const TeamTasks = lazy(() => import('../../Pages/TeamTasks/TeamTasks'));

export const organizeRoutes: RouteObject[] = [
  {
    children: getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu}),
    element: <Edit />,
    path: `${editProjectsRoute}/*`,
  },
  {
    //if no dialog matches, remove all other paths again:
    children: [...dialogRouteObjects, {path: '*', element: <Navigate to={`/${projectsRoute}`} replace />}],
    element: <NodeTablePage page={'projects'} />,
    path: `${projectsRoute}/*`,
  },
  {
    children: boardRoutes,
    element: <Planning />,
    path: `${kanbanBoardsRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({}),
    element: <TeamTasks />,
    path: `${teamTasksRoute}/*`,
  },
];

export const organizeMenu: MenuRootItem = {
  groupToken: 'pageMenu:projects',
  icon: Layers,
  id: 'organize',
  items: [
    {
      nameToken: 'pageMenu:projects.tiles',
      path: editProjectsRoute,
    },
    {
      nameToken: 'pageMenu:projects.table',
      path: projectsRoute,
    },
    {
      access: ({orgUserType, userSettings}) => isInternalUserType(orgUserType) && !!userSettings.useTeamTasks,
      nameToken: 'pageMenu:teamTasks',
      path: teamTasksRoute,
    },
    {
      //TODO: Temporarily hidden for guests until the project folder problem with kanban is resolved:
      access: ({isGranted, orgUserType}) =>
        isInternalUserType(orgUserType) && isGranted('FLOW_NODE_READ_LABELS', anyNode),
      nameToken: 'pageMenu:boards',
      path: kanbanBoardsRoute,
      testId: 'page-menu-projects-kanban',
    },
    {
      disabled: true,
      nameToken: 'pageMenu:closeProjects',
      path: 'closeProjects',
    },
  ],
};

export function getEditProjectRoute(): string {
  return editProjectsRoute;
}
