/* eslint-disable curly */
import {
  NodeIcon,
  ProjectIcon,
  SubProjectIcon,
  SubWorkPackageIcon,
  TaskIcon,
  TWDropdownMenuCheckboxItem,
  TWDropdownMenuItem,
  TWDropdownMenuSub,
  TWDropdownMenuSubContent,
  TWDropdownMenuSubTrigger,
  WorkPackageIcon,
} from '@octaved/ui';
import {subscribeToNode, unsubscribeFromNode} from '@octaved/flow/src/Modules/FavoriteNodes';
import {patchWorkPackage} from '@octaved/flow/src/Modules/WorkPackages';
import {
  Airplay,
  ArrowDownFromLine,
  ArrowUpFromLine,
  ChartBar,
  ChartLine,
  ChevronLeft,
  ChevronRight,
  ClipboardCopy,
  Clock,
  Copy,
  CornerDownRight,
  Download,
  Edit,
  ExternalLink,
  FileClock,
  FolderOpen,
  Link,
  Maximize2,
  NotebookText,
  NotepadTextDashed,
  Pipette,
  Shuffle,
  Star,
  StickyNote,
  Trash,
  User,
} from 'lucide-react';
import {PropsWithChildren, ReactElement, useCallback} from 'react';
import {Trans} from 'react-i18next';
import {useContextMenuContext} from './contextMenuContext';
import {generateLargeViewUrl} from '@octaved/flow/src/Routing/Routes/Node';
import {
  isGroup,
  isPid,
  isPidOrSubWOrkPackage,
  isProject,
  isProjectFolder,
  isSubWorkPackage,
  isTask,
  isTaskSection,
  isWorkPackage,
  isWpOrSwp,
} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useDispatch, useSelector} from 'react-redux';
import {createInspectorPatch, setUiState} from '@octaved/flow/src/Modules/Ui';
import {deleteNodeToken, duplicateNodeToken, newSameNodeSubMenu} from './contextMenuTokens';
import {patchTaskListUiState} from '@octaved/flow/src/Modules/Ui/TaskList';
import {
  deletePidDialogRoute,
  exportProjectRoute,
  responsibleBatchToolRoute,
} from '@octaved/flow/src/Routing/Routes/DialogRoutes';
import {moveGroupOrWorkPackage, rearrangeProject} from '@octaved/flow/src/Modules/UiPages/Projects';
import {useCanCreateProjectsSomewhere} from '@octaved/flow/src/Modules/Projects';
import {NodeRight} from '@octaved/security/src/Authorization/Rights';
import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {manageRights} from '@octaved/flow/src/Modules/ResponsibleNode';
import {isUserNode} from '@octaved/flow/src/Modules/Selectors/UserNodeSelectors';
import {useTaskGroupContext} from '@octaved/flow/src/Components/Task/TaskGroup/TaskGroupContext';
import {Uuid} from '@octaved/typescript/src/lib';
import {useTaskListContext} from '@octaved/flow/src/Components/Task/TaskListContext';
import {useLocation} from 'react-router-dom';
import {hasFeature} from '@octaved/env/src/Features';
import {nodeTreeInvertedSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {deleteTaskSection} from '@octaved/flow/src/Modules/TaskSections';

export const OpenDetails = (): ReactElement | null => {
  const {openDetails, node} = useContextMenuContext();
  if (!(isProjectFolder(node) || isPidOrSubWOrkPackage(node) || isTask(node))) return null;

  return (
    <TWDropdownMenuItem onClick={openDetails}>
      <Airplay className={'mr-2 h-4 w-4 rotate-90'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openDetails'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const OpenLargeDetailsView = (): ReactElement | null => {
  const {navigate, node, isGranted} = useContextMenuContext();
  if (!(isPidOrSubWOrkPackage(node) || isTask(node))) return null;

  const isLocked = !isGranted('FLOW_NODE_VIEW_DETAILS', node.id);

  return (
    <TWDropdownMenuItem onClick={() => navigate(generateLargeViewUrl(node.nodeType, node.id))} isLocked={isLocked}>
      <Maximize2 className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openLargeDetailsView'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const Indent = (): ReactElement | null => {
  const {node, isIndented, toggleIndentation, canToggleIndentation, isGranted} = useContextMenuContext();
  if (!isTask(node) || isIndented || !canToggleIndentation) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={toggleIndentation} isLocked={isLocked}>
      <ChevronRight className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.indent'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const Outdent = (): ReactElement | null => {
  const {node, isIndented, toggleIndentation, canToggleIndentation, isGranted} = useContextMenuContext();
  if (!isTask(node) || !isIndented || !canToggleIndentation) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={toggleIndentation} isLocked={isLocked}>
      <ChevronLeft className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.outdent'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const TrackTime = (): ReactElement | null => {
  const {node, canStartTimeRecord, usingTimeTracking} = useContextMenuContext();
  const dispatch = useDispatch();

  if (!(isWorkPackage(node) || isSubWorkPackage(node))) return null;
  if (!canStartTimeRecord || !usingTimeTracking) return null;

  return (
    <TWDropdownMenuItem
      onClick={() => {
        dispatch(
          setUiState(
            createInspectorPatch({
              editTimeRecord: {
                referenceNode: node.id,
                type: 'create',
              },
            }),
          ),
        );
      }}
    >
      <Clock className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.startTimeTracking'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const OpenBoard = (): ReactElement | null => {
  const {node, navigate, isGranted} = useContextMenuContext();
  if (!(isPidOrSubWOrkPackage(node) || isTask(node))) return null;
  const isLocked = !isGranted('FLOW_NODE_BOARD_POST_READ_BASIC', node.id);

  return (
    <TWDropdownMenuItem
      onClick={() => {
        navigate(generateLargeViewUrl(node.nodeType, node.id, 'board'));
      }}
      isLocked={isLocked}
    >
      <NotebookText className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openBoard'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const OpenNotes = (): ReactElement | null => {
  const {node, openDialog, isGranted} = useContextMenuContext();
  if (!isTask(node)) return null;
  const isLocked = !isGranted('FLOW_NODE_READ_DESCRIPTION', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('notes')} isLocked={isLocked}>
      <StickyNote className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openNotes'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const OpenTasks = (): ReactElement | null => {
  const {node, navigate, isGranted} = useContextMenuContext();
  const location = useLocation();
  const tasksRoute = generateLargeViewUrl(node.nodeType, node.id, 'tasks');
  if (tasksRoute.includes(location.pathname)) return null;
  if (!isPidOrSubWOrkPackage(node)) return null;
  const isLocked = !isGranted('FLOW_NODE_VIEW_DETAILS', node.id) || !isGranted('FLOW_NODE_TASK_READ_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => navigate(tasksRoute)} isLocked={isLocked}>
      <TaskIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openTasks'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const OpenReferenceNumber = (): ReactElement | null => {
  const {referenceNumberUrl} = useContextMenuContext();
  if (!referenceNumberUrl) return null;

  return (
    <TWDropdownMenuItem onClick={() => window.open(referenceNumberUrl, '_blank')}>
      <ExternalLink className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openReferenceNumber'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const OpenTimeSheet = (): ReactElement | null => {
  const {node, navigate, usingTimeTracking, isGranted} = useContextMenuContext();
  if (!isPidOrSubWOrkPackage(node)) return null;
  if (!usingTimeTracking) return null;
  const isLocked = !isGranted('FLOW_NODE_PROJECT_TIME_TRACKING_READ_OWN', node.id, 'anyInTree+self');

  return (
    <TWDropdownMenuItem
      onClick={() => {
        navigate(generateLargeViewUrl(node.nodeType, node.id, 'timeSheet'));
      }}
      isLocked={isLocked}
    >
      <FileClock className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.openTimeSheet'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewProjectFromTemplate = (): ReactElement | null => {
  const {node, openDialog, isGranted} = useContextMenuContext();
  const canCreateProjectSomewhere = useCanCreateProjectsSomewhere();
  if (!isProject(node)) return null;
  if (!node.isTemplate) return null;
  const isLocked = !isGranted('FLOW_NODE_PID_COPY', node.id, 'allInTree+self') || !canCreateProjectSomewhere;

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newProject')} isLocked={isLocked}>
      <ProjectIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newProjectFromTemplate'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewProject = (): ReactElement | null => {
  const {node, openDialog} = useContextMenuContext();
  const canCreateProjectSomewhere = useCanCreateProjectsSomewhere();
  const isLocked = !canCreateProjectSomewhere;
  if (!(isProject(node) || isProjectFolder(node))) return null;
  if (isProject(node) && node.isTemplate) return null;

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newProject')} isLocked={isLocked}>
      <ProjectIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newProject'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewTemplate = (): ReactElement | null => {
  const {node, openDialog} = useContextMenuContext();
  const canCreateProjectSomewhere = useCanCreateProjectsSomewhere();
  if (!isProject(node)) return null;
  if (!node.isTemplate) return null;
  const isLocked = !canCreateProjectSomewhere;

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newTemplate')} isLocked={isLocked}>
      <NotepadTextDashed className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newTemplate'} />
      </span>
    </TWDropdownMenuItem>
  );
};

const canManageBasicRight: Partial<Record<EnumFlowNodeType, NodeRight>> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'FLOW_NODE_PID_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_PROJECT]: 'FLOW_NODE_PID_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: 'FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_TASK]: 'FLOW_NODE_TASK_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: 'FLOW_NODE_PID_MANAGE_BASIC',
  [EnumFlowNodeType.VALUE_TASK_SECTION]: 'FLOW_NODE_TASK_MANAGE_BASIC',
};

export const NewSameNodeBefore = (): ReactElement | null => {
  const {node, openDialog, setShowNewSiblingTask, isGranted, parent} = useContextMenuContext();
  if (!(isGroup(node) || isWorkPackage(node) || isSubWorkPackage(node) || isTask(node))) return null;
  if (isTask(node) && !setShowNewSiblingTask) return null;
  const right = canManageBasicRight[node.nodeType];
  const isLocked = !right || !isGranted(right, parent?.id);

  return (
    <TWDropdownMenuItem
      onClick={() => {
        if (isGroup(node)) {
          openDialog('newGroupBefore');
        } else if (isWorkPackage(node)) {
          openDialog('newWorkPackageBefore');
        } else if (isSubWorkPackage(node)) {
          openDialog('newSubWorkPackageBefore');
        } else if (isTask(node)) {
          setTimeout(() => setShowNewSiblingTask?.('prev'), 500);
        }
      }}
      isLocked={isLocked}
    >
      <ArrowUpFromLine className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newBefore'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewSameNodeAfter = (): ReactElement | null => {
  const {node, openDialog, setShowNewSiblingTask, isGranted, parent} = useContextMenuContext();
  if (!(isGroup(node) || isWorkPackage(node) || isSubWorkPackage(node) || isTask(node))) return null;
  if (isTask(node) && !setShowNewSiblingTask) return null;
  const right = canManageBasicRight[node.nodeType];
  const isLocked = !right || !isGranted(right, parent?.id);

  return (
    <TWDropdownMenuItem
      onClick={() => {
        if (isGroup(node)) {
          openDialog('newGroupAfter');
        } else if (isWorkPackage(node)) {
          openDialog('newWorkPackage');
        } else if (isSubWorkPackage(node)) {
          openDialog('newSubWorkPackage');
        } else if (isTask(node)) {
          setTimeout(() => setShowNewSiblingTask?.('prev'), 500);
        }
      }}
      isLocked={isLocked}
    >
      <ArrowDownFromLine className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newAfter'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewSameNodeInside = (): ReactElement | null => {
  const {node, openDialog, isGranted, canCreateGroup} = useContextMenuContext();
  if (!isGroup(node) || !canCreateGroup) return null;
  const isLocked = !isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newGroup')} isLocked={isLocked}>
      <CornerDownRight className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newGroupInside'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewGroup = (): ReactElement | null => {
  const {node, openDialog, isGranted} = useContextMenuContext();
  if (!isProject(node)) return null;
  const isLocked = !isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newGroup')} isLocked={isLocked}>
      <SubProjectIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newGroup'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewSubWorkPackage = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  if (!isWorkPackage(node)) return null;
  const isLocked = !isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', node.id);
  if (!hasFeature('subWorkPackages')) return null;

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newSubWorkPackage')} isLocked={isLocked}>
      <SubWorkPackageIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newSubWorkPackage'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewWorkPackage = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  if (!(isProject(node) || isGroup(node))) return null;
  const isLocked = !isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('newWorkPackage')} isLocked={isLocked}>
      <WorkPackageIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newWorkPackage'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewTaskSection = (): ReactElement | null => {
  const {node, parent, focusNewTaskSection, isGranted} = useContextMenuContext();
  const dispatch = useDispatch();
  const taskListContext = useTaskListContext();
  if (!(isTask(node) || isUserNode(node) || isWpOrSwp(node) || isTaskSection(node))) return null;
  if (parent?.isArchived) return null;
  if (!taskListContext.allowCreationOfTaskSections || !focusNewTaskSection) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem
      onClick={() => {
        const parentNodeId = node.id;
        setTimeout(() => {
          setTimeout(() => {
            if (focusNewTaskSection) {
              focusNewTaskSection();
            }
          }, 10);
          dispatch(patchTaskListUiState({closedGroups: {[parentNodeId]: false}}));
        }, 500);
      }}
      isLocked={isLocked}
    >
      <TaskIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newTaskSection'} />
      </span>
    </TWDropdownMenuItem>
  );
};

function useAddNewTask(parentNodeId: Uuid): () => void {
  const {focusNewTask} = useTaskGroupContext();
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(patchTaskListUiState({closedGroups: {[parentNodeId]: false}}));
    setTimeout(() => {
      if (focusNewTask.current) {
        focusNewTask.current();
      }
    }, 10);
  }, [dispatch, focusNewTask, parentNodeId]);
}

export const NewTask = (): ReactElement | null => {
  const {node} = useContextMenuContext();
  const onClick = useAddNewTask(node.id);
  if (!(isUserNode(node) || isTaskSection(node))) return null;

  return (
    <TWDropdownMenuItem onClick={() => setTimeout(onClick, 500)}>
      <TaskIcon className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.newTaskSubMenu'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const Duplicate = (): ReactElement | null => {
  const {node, isGranted, openDialog, parent} = useContextMenuContext();
  const canCreateProjectSomewhere = useCanCreateProjectsSomewhere();
  if (!isPid(node)) return null;
  const right = canManageBasicRight[node.nodeType];
  const canCreate = isProject(node) ? canCreateProjectSomewhere : !!right && isGranted(right, parent?.id);
  const isLocked = !isGranted('FLOW_NODE_PID_COPY', node.id, 'allInTree+self') || !canCreate;

  return (
    <TWDropdownMenuItem onClick={() => openDialog('duplicatePid')} isLocked={isLocked}>
      <Copy className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={duplicateNodeToken(node)} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const Rearrange = (): ReactElement | null => {
  const {node, isGranted, project} = useContextMenuContext();
  const dispatch = useDispatch();
  if (!isPid(node)) return null;
  if (node.isArchived) return null;
  if (!project) return null;
  const isLocked = !isGranted('FLOW_NODE_PID_MANAGE_BASIC', project.id, 'anyInTree+self');

  return (
    <TWDropdownMenuItem onClick={() => dispatch(rearrangeProject(project.id))} isLocked={isLocked}>
      <Shuffle className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.rearrange'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const MoveToTarget = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  const dispatch = useDispatch();
  if (!isPid(node)) return null;
  if (node.isArchived) return null;
  const right = canManageBasicRight[node.nodeType];
  const isLocked = !right || !isGranted(right, node?.id);

  if (isProject(node)) {
    return (
      <TWDropdownMenuItem onClick={() => openDialog('changeProjectFolder')} isLocked={isLocked}>
        <FolderOpen className={'mr-2 h-4 w-4'} />
        <span>
          <Trans i18nKey={'components:contextMenu.moveToProjectFolder'} />
        </span>
      </TWDropdownMenuItem>
    );
  } else {
    return (
      <TWDropdownMenuItem onClick={() => dispatch(moveGroupOrWorkPackage(node.id))} isLocked={isLocked}>
        <Shuffle className={'mr-2 h-4 w-4'} />
        <span>
          <Trans i18nKey={'components:contextMenu.moveToProject'} />
        </span>
      </TWDropdownMenuItem>
    );
  }
};

export const MoveTasksToOtherWorkPackage = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  if (!(isWorkPackage(node) || isSubWorkPackage(node))) return null;
  if (node.isArchived) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('moveWorkPackageTasks')} isLocked={isLocked}>
      <Shuffle className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.moveTasksToOtherWorkPackage'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const CopyTasksFromOtherWork = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  if (!(isWorkPackage(node) || isSubWorkPackage(node))) return null;
  if (node.isArchived) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('copyWorkPackageTasks')} isLocked={isLocked}>
      <ClipboardCopy className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.copyTasksFromOtherWorkPackage'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const AutoChain = (): ReactElement | null => {
  const {node, isGranted} = useContextMenuContext();
  const dispatch = useDispatch();
  if (!isWorkPackage(node)) return null;
  if (node.isArchived) return null;
  const isLocked = !isGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuCheckboxItem
      checked={node.isAutoChainActive}
      onClick={() => {
        dispatch(patchWorkPackage(node.id, {isAutoChainActive: !node.isAutoChainActive}));
      }}
      isLocked={isLocked}
    >
      <span>
        <Trans i18nKey={'components:contextMenu.autoChain'} />
      </span>
    </TWDropdownMenuCheckboxItem>
  );
};

export const ExportProject = (): ReactElement | null => {
  const {node, navigate, isGranted, generateDynamicLink} = useContextMenuContext();
  if (!isProject(node)) return null;
  if (node.isArchived) return null;
  if (node.isTemplate) return null;
  if (!isGranted('FLOW_NODE_PROJECT_EXPORT', node.id, 'allInTree+self')) return null;

  return (
    <TWDropdownMenuItem onClick={() => navigate(generateDynamicLink('dialog', `${exportProjectRoute}/${node.id}`))}>
      <Download className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.exportProject'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const SelectPredecessorForLogicalDependency = (): ReactElement | null => {
  const {node, openDialog, isGranted} = useContextMenuContext();
  if (!isTask(node)) return null;
  if (node.isArchived) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('selectPredecessor')} isLocked={isLocked}>
      <Link className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.predecessor'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const AssignResponsible = (): ReactElement | null => {
  const {node, isGranted, navigate, generateDynamicLink} = useContextMenuContext();
  if (!(isProject(node) || isGroup(node))) return null;
  if (node.isArchived) return null;
  const responsibleRight = manageRights[node.nodeType];
  const isLocked = !isGranted(responsibleRight, node.id, 'allInTree+self');

  return (
    <TWDropdownMenuItem
      onClick={() => navigate(generateDynamicLink('dialog', `${responsibleBatchToolRoute}/${node.id}`))}
      isLocked={isLocked}
    >
      <User className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.assignResponsibility'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const SetStatus = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  if (!(isProject(node) || isGroup(node))) return null;
  if (node.isArchived) return null;
  const right = canManageBasicRight[node.nodeType];
  const isLocked = !right || !isGranted(right, node.id, 'allInTree+self');

  return (
    <TWDropdownMenuItem onClick={() => openDialog('workPackageStatusBatchTool')} isLocked={isLocked}>
      <Edit className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.setStatus'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const BaselineComparison = (): ReactElement | null => {
  const {node, openDialog} = useContextMenuContext();
  if (!(isProject(node) || isGroup(node))) return null;
  if (isProject(node) && node.isTemplate) return null;

  return (
    <TWDropdownMenuItem onClick={() => openDialog('baselineComparison')}>
      <ChartBar className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.baselineComparison'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const BurndownChart = (): ReactElement | null => {
  const {node, openDialog, isGranted} = useContextMenuContext();
  if (!(isTask(node) || isPidOrSubWOrkPackage(node))) return null;
  if (isProject(node) && node.isTemplate) return null;
  const isLocked = !isGranted('FLOW_NODE_VIEW_DETAILS', node.id) || !isGranted('FLOW_NODE_TASK_READ_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('burndownChart')} isLocked={isLocked}>
      <ChartLine className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.burndownChart'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const Favorite = (): ReactElement | null => {
  const {node, isFavorite} = useContextMenuContext();
  const dispatch = useDispatch();
  if (!isPid(node)) return null;
  if (node.isArchived) return null;

  return (
    <TWDropdownMenuItem
      onClick={(e) => {
        if (isFavorite) {
          dispatch(unsubscribeFromNode(node.id));
        } else {
          dispatch(subscribeToNode(node.id));
        }
        e.preventDefault();
      }}
    >
      <Star
        fill={isFavorite ? '#f59e0b' : '#fff'}
        stroke={isFavorite ? '#f59e0b' : 'rgba(0, 0, 0, 0.87)'}
        className={'mr-2 h-4 w-4'}
      />
      <span>
        <Trans i18nKey={'components:contextMenu.favorite'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const CustomizeColor = (): ReactElement | null => {
  const {node, isGranted, openDialog} = useContextMenuContext();
  if (!isTaskSection(node)) return null;
  const isLocked = !isGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id);

  return (
    <TWDropdownMenuItem onClick={() => openDialog('customizeColor')} isLocked={isLocked}>
      <Pipette className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={'components:contextMenu.customizeColor'} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const Delete = (): ReactElement | null => {
  const {node, isGranted, navigate, generateDynamicLink, openDialog} = useContextMenuContext();
  const childIds = useSelector(nodeTreeInvertedSelector).get(node.id);
  const dispatch = useDispatch();
  if (!(isPidOrSubWOrkPackage(node) || isTask(node) || isTaskSection(node))) return null;
  const right = canManageBasicRight[node.nodeType];
  const isLocked = !right || !isGranted(right, node.id, 'allInTree+self');

  return (
    <TWDropdownMenuItem
      colorScheme={'red'}
      onClick={() => {
        if (isTask(node)) openDialog('delete');
        else if (isTaskSection(node)) {
          if (childIds && childIds.length > 0) {
            openDialog('confirmDeleteTaskSection');
          } else {
            dispatch(deleteTaskSection(node.id));
          }
        } else navigate(generateDynamicLink('dialog', `${deletePidDialogRoute}/${node.id}`));
      }}
      isLocked={isLocked}
    >
      <Trash className={'mr-2 h-4 w-4'} />
      <span>
        <Trans i18nKey={deleteNodeToken(node)} />
      </span>
    </TWDropdownMenuItem>
  );
};

export const NewSameNodeSubMenu = (props: PropsWithChildren<Record<never, never>>): ReactElement | null => {
  const {node, setShowNewSiblingTask, isGranted, parent} = useContextMenuContext();
  if (!(isGroup(node) || isWorkPackage(node) || isSubWorkPackage(node) || isTask(node))) return null;
  if (isTask(node) && !setShowNewSiblingTask) return null;
  const right = canManageBasicRight[node.nodeType];
  const isLocked = !right || (!isGranted(right, parent?.id) && !isGranted(right, node?.id));

  return (
    <TWDropdownMenuSub>
      <TWDropdownMenuSubTrigger isLocked={isLocked}>
        <NodeIcon nodeType={node.nodeType} className={'mr-2 h-4 w-4'} />
        <span>
          <Trans i18nKey={newSameNodeSubMenu(node)} />
        </span>
      </TWDropdownMenuSubTrigger>
      <TWDropdownMenuSubContent>{props.children}</TWDropdownMenuSubContent>
    </TWDropdownMenuSub>
  );
};

export const MoreSubMenu = (props: PropsWithChildren<Record<never, never>>): ReactElement | null => {
  const {node} = useContextMenuContext();
  if (!(isPidOrSubWOrkPackage(node) || isTask(node))) return null;

  return (
    <TWDropdownMenuSub>
      <TWDropdownMenuSubTrigger>
        <div className={'mr-2 h-4 w-4'} />
        <span>
          <Trans i18nKey={'components:contextMenu.more'} />
        </span>
      </TWDropdownMenuSubTrigger>
      <TWDropdownMenuSubContent>{props.children}</TWDropdownMenuSubContent>
    </TWDropdownMenuSub>
  );
};
