import {ReactElement, useRef} from 'react';
import GroupsAndWorkPackages, {
  GroupsAndWorkPackagesProps,
} from '../../../../Components/Tree/Defaults/GroupsAndWorkPackages';
import {useProjectDrop} from '../Components/ProjectSortable';

export default function DndGroupAndWorkPackages(props: GroupsAndWorkPackagesProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  useProjectDrop(ref, props.parentNode.children.length, props.parentNode.id, props.parentNode.id);
  return <GroupsAndWorkPackages {...props} ref={ref} />;
}
