import Konva from 'konva';
import {RootContainer} from '../RootContainer';

export default class GridLines extends RootContainer {
  onCalenderDatesChanged(): void {
    this.root.destroyChildren();

    this.#createLines();
  }

  #createLines(): void {
    const headerLineHeight = this.ctx.headerHeight / 2;
    for (let i = 0; i <= this.ctx.calendarView.days; ++i) {
      const x = i * this.ctx.columnWidth;
      this.root.add(
        new Konva.Line({
          hitStrokeWidth: 0,
          listening: false,
          offsetX: 0.5,
          perfectDrawEnabled: false,
          points: [x, headerLineHeight, x, this.ctx.canvasView.maxHeight],
          shadowForStrokeEnabled: false,
          stroke: '#d1d7e080',
          strokeScaleEnabled: false,
          strokeWidth: 1,
        }),
      );
    }
  }
}
