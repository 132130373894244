import {Uuid} from '@octaved/typescript/src/lib';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {
  NodeSearch,
  NodeSearchIdent,
  NodeSearchIdentWithoutValue,
  NodeSearchIdentWithValue,
} from '../../EntityInterfaces/NodeSearch';
import {FLOW_SEARCH_NODE_SUCCESS} from '../ActionTypes';
import {getNodeSearchKey} from '../Selectors/NodeSearchSelectors';

type Ids = ReadonlyArray<Uuid> | ReadonlySet<Uuid>;

function isIds(ids: unknown): ids is Ids {
  return Array.isArray(ids) || ids instanceof Set;
}

interface NodeSearchChangeAction {
  key: string;
  nodeId?: Uuid;
  nodeIds?: Ids;
}

export interface NodeSearchSuccess {
  key: string;
  response: Uuid[];
  type: typeof FLOW_SEARCH_NODE_SUCCESS;
}

export function reduceAddToNodeSearch(
  state: UuidSearchResults,
  {key, nodeId, nodeIds}: NodeSearchChangeAction,
): UuidSearchResults {
  const result = state[key];
  if (result) {
    const set = new Set([...result]);
    const ids = nodeId ? [nodeId] : nodeIds ? nodeIds : [];
    let changed = false;
    ids.forEach((id) => {
      if (!set.has(id)) {
        set.add(id);
        changed = true;
      }
    });
    if (changed) {
      return {...state, [key]: [...set]};
    }
  }
  return state;
}

export function reduceAddOptional<I extends NodeSearchIdentWithoutValue>(
  state: UuidSearchResults,
  id: Uuid | Ids,
  ident: I,
): UuidSearchResults;
export function reduceAddOptional<I extends NodeSearchIdentWithValue>(
  state: UuidSearchResults,
  id: Uuid | Ids,
  ident: I,
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  value: NodeSearch[I],
): UuidSearchResults;
export function reduceAddOptional<I extends NodeSearchIdent>(
  state: UuidSearchResults,
  id: Uuid | Ids,
  ident: I,
  value?: NodeSearch[I],
): UuidSearchResults {
  const nodeIds = isIds(id) ? id : [id];
  return reduceAddToNodeSearch(state, {key: getNodeSearchKey(ident, value as NodeSearch[I]), nodeIds});
}
