import {Tooltip} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';
import {PopupProps} from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import PlaceholderText from '../../../../../Feedback/Placeholder/PlaceholderText';

interface Props {
  placeholder: string;
  tooltip?: string;
  position?: PopupProps['position'];
}

export default function CellPlaceholder({placeholder, position = 'top left', tooltip}: Props): ReactElement {
  if (tooltip) {
    return (
      <Tooltip position={position} toolTipTranslation={tooltip}>
        <PlaceholderText italic className={'onHover'}>
          <Trans i18nKey={placeholder} />
        </PlaceholderText>
      </Tooltip>
    );
  }

  return (
    <PlaceholderText italic className={'onHover'}>
      <Trans i18nKey={placeholder} />
    </PlaceholderText>
  );
}
