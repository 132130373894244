import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createFlatTimestampReducer, EntityState, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {useSelector} from 'react-redux';
import * as routes from '../../config/routes';
import {StaticRights} from '../EntityInterfaces/Rights';
import {rightsSelector, rightsStateSelector} from '../Selectors/Rights';

const FLOW_LOAD_RIGHTS_REQUEST = 'FLOW_LOAD_RIGHTS_REQUEST';
const FLOW_LOAD_RIGHTS_SUCCESS = 'FLOW_LOAD_RIGHTS_SUCCESS';
const FLOW_LOAD_RIGHTS_FAILURE = 'FLOW_LOAD_RIGHTS_FAILURE';

interface LoadSuccessAction {
  response: StaticRights;
  type: typeof FLOW_LOAD_RIGHTS_SUCCESS;
}

const reducers = createReducerCollection<StaticRights>({rights: {}});
reducers.add(FLOW_LOAD_RIGHTS_SUCCESS, (_, action: LoadSuccessAction) => action.response);
export const rightsReducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityState>({});
stateReducers.add(FLOW_LOAD_RIGHTS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducers.add(FLOW_LOAD_RIGHTS_SUCCESS, createFlatTimestampReducer(LOADED));
export const rightsStateReducer = stateReducers.reducer;

function loadStaticRights(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    if (isOutdated(rightsStateSelector(getState()))) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.getStaticRights,
          types: {
            failureType: FLOW_LOAD_RIGHTS_FAILURE,
            requestType: FLOW_LOAD_RIGHTS_REQUEST,
            successType: FLOW_LOAD_RIGHTS_SUCCESS,
          },
        },
      });
    }
  };
}

export function useStaticRights(): StaticRights {
  useStoreEffect((dispatch) => dispatch(loadStaticRights()), [], rightsStateSelector);
  return useSelector(rightsSelector);
}
