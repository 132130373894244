import {BoardPostType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api/config/routes';
import {CALL_API, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {RulesList} from '@octaved/store/src/Validation';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {pick} from 'lodash';
import {
  BaseBoardPost,
  BoardPost,
  BoardPostApprovalQuestionAnswer,
  BoardPostComment,
  BoardPostMergedData,
  BoardPostWithAnswers,
  BoardPostWithComments,
  isDataWithAnswers,
  isDataWithComments,
} from '../../EntityInterfaces/BoardPost';
import {NodeEntityPatchData} from '../../EntityInterfaces/NodeEntity';
import {
  FLOW_CHANGE_BOARD_POST_FAILURE,
  FLOW_CHANGE_BOARD_POST_REQUEST,
  FLOW_CHANGE_BOARD_POST_SUCCESS,
  FLOW_CREATE_BOARD_POST_FAILURE,
  FLOW_CREATE_BOARD_POST_REQUEST,
  FLOW_CREATE_BOARD_POST_SUCCESS,
  FLOW_REMOVE_BOARD_POST_FAILURE,
  FLOW_REMOVE_BOARD_POST_REQUEST,
  FLOW_REMOVE_BOARD_POST_SUCCESS,
  FLOW_RESORT_BOARD_POST_FAILURE,
  FLOW_RESORT_BOARD_POST_REQUEST,
  FLOW_RESORT_BOARD_POST_SUCCESS,
} from '../ActionTypes';
import {
  createNodeEntity,
  nodeEntityReducers,
  transformNodeEntityToPatchData,
  transformPatchDataToNodeEntity,
} from '../Nodes';
import {removeNotAllowedLabels} from '../ReduceRemovedLabels';
import {isBoardPost} from '../Selectors/BoardPostSelectors';
import {labelIdsSelector} from '../Selectors/LabelSelectors';
import {isPidOrSubWOrkPackage, isTask} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector} from '../Selectors/NodeSelectors';
import {getChildNodesSelector, getParentIdSelector} from '../Selectors/NodeTreeSelectors';
import {FlowState} from '../State';
import {validateErrorRules} from '../Ui';
import {getBoardPostTypeConfig} from './BoardPostTypeRegistry';

//#region types
export type BoardPostData<BP extends BaseBoardPost = BaseBoardPost> = NodeEntityPatchData &
  Pick<
    BoardPost,
    | 'boardPostType'
    | 'isPersonal'
    | 'labels'
    | 'lastAnswerBy'
    | 'lastAnswerOn'
    | 'lastCommentBy'
    | 'lastCommentOn'
    | 'lastContentChangeBy'
    | 'lastContentChangeOn'
  > & {
    data: BP['data'];
  };

//For patching, the `boardPostType` is always mandatory to distinguish validation rules and schemas:
export type PatchableBoardPostData = DeepPartial<Omit<BoardPostData, 'boardPostType'>> &
  Pick<BoardPost, 'boardPostType'>;
//#endregion

nodeEntityReducers.add(
  FLOW_CREATE_BOARD_POST_SUCCESS,
  (state, action: ServerResponseAction<{id: Uuid; data: Record<string, unknown>}>) => {
    //Updates server-side calculated data like link previews and file urls:
    return mergeStates(state, {[action.response!.id]: {data: action.response!.data}});
  },
);

export function createBoardPostEntity<BP extends BaseBoardPost = BaseBoardPost>(
  type: BoardPostType,
  currentUserId: Uuid,
): BP {
  const config = getBoardPostTypeConfig(type);
  return {
    ...createNodeEntity(EnumFlowNodeType.VALUE_BOARD_POST),
    boardPostType: type,
    data: config.defaultDataFactory() as BP['data'],
    isPersonal: true,
    lastAnswerBy: null,
    lastAnswerOn: null,
    lastCommentBy: null,
    lastCommentOn: null,
    lastContentChangeBy: currentUserId,
    lastContentChangeOn: unix(),
    nodeType: EnumFlowNodeType.VALUE_BOARD_POST,
    sortOrder: 0,
  } satisfies BaseBoardPost as BP;
}

function transformBoardPostToPatchData<BP extends BaseBoardPost = BaseBoardPost>(bp: BP): BoardPostData<BP> {
  return transformNodeEntityToPatchData<BoardPostData<BP>>(bp);
}

function transformPatchDataToBoardPost<BP extends BaseBoardPost = BaseBoardPost>(
  bp: DeepPartial<BoardPostData<BP>>,
): DeepPartial<BP> {
  return transformPatchDataToNodeEntity<BP>(bp);
}

export function getDefaultBoardPostCreationData<BP extends BaseBoardPost = BaseBoardPost>(
  type: BoardPostType,
  currentUserId: Uuid,
): BoardPostData<BP> {
  return transformBoardPostToPatchData<BP>(createBoardPostEntity<BP>(type, currentUserId));
}

function getValidationRules(id: Uuid, data: PatchableBoardPostData, isCreation: boolean): RulesList {
  const config = getBoardPostTypeConfig(data.boardPostType);
  if (data.data && config.getDataValidationRules) {
    return config.getDataValidationRules(id, data.data, isCreation);
  }
  return [];
}

export function createBoardPost(boardPost: BoardPostData, parentNodeId: Uuid): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(boardPost.id, boardPost, true), dispatch)) {
      return false;
    }
    const state = getState();
    const nodes = nodeEntitySelector(state);
    if (!isPidOrSubWOrkPackage(nodes[parentNodeId]) && !isTask(nodes[parentNodeId])) {
      throw new Error(
        `Invalid parentNodeId '${parentNodeId}' given - board post parent must be pid or sub work package`,
      );
    }

    const children = getChildNodesSelector(state)(parentNodeId);
    const boardPosts = children.filter(isBoardPost);

    const now = unix();
    const patch = removeNotAllowedLabels(transformPatchDataToBoardPost(boardPost), labelIdsSelector(state));
    delete patch.planningPredecessors; //not allowed to patch
    delete patch.planningSuccessors; //not allowed to patch

    dispatch({
      [CALL_API]: {
        endpoint: routes.putBoardPost,
        method: 'put',
        options: {
          data: {
            lastAnswerBy: null,
            lastAnswerOn: null,
            lastCommentBy: null,
            lastCommentOn: null,
            lastContentChangeBy: currentOrgUserIdSelector(state),
            lastContentChangeOn: unix(),
            ...patch,
            parentNodeId,
            createdOn: now,
            lastChangedBy: currentOrgUserIdSelector(state),
            lastChangedOn: now,
            nodeType: EnumFlowNodeType.VALUE_BOARD_POST,
            sortOrder: boardPosts.length,
          },
          urlParams: {
            boardPostId: boardPost.id,
          },
        },
        types: {
          failureType: FLOW_CREATE_BOARD_POST_FAILURE,
          requestType: FLOW_CREATE_BOARD_POST_REQUEST,
          successType: FLOW_CREATE_BOARD_POST_SUCCESS,
        },
      },
    });
    return true;
  };
}

const mainContentProps: ReadonlyArray<keyof BoardPostMergedData> = [
  'date',
  'files',
  'forUnits',
  'information',
  'location',
  'participants',
  'question',
  'text',
  'time',
  'topic',
];

function getMainContentSubset(data: BoardPost['data']): object {
  return pick(data, mainContentProps);
}

type LastAnswer = Pick<BoardPostApprovalQuestionAnswer, 'answeredBy' | 'answeredOn'>;
type LastComment = Pick<BoardPostComment, 'commentBy' | 'commentOn'>;

function getLastAnswer(data: BoardPostWithAnswers['data']): LastAnswer | null {
  let last: LastAnswer | null = null;
  data.answers.forEach((answer) => {
    if (!last || last.answeredOn < answer.answeredOn) {
      last = answer;
    }
  });
  return last;
}

function getLastComment(data: BoardPostWithComments['data']): LastComment | null {
  let last: LastComment | null = null;
  (data.comments || []).forEach((comment) => {
    if (!last || last.commentOn < comment.commentOn) {
      last = comment;
    }
    (comment.answers || []).forEach((answer) => {
      if (!last || last.commentOn < answer.commentOn) {
        last = answer;
      }
    });
  });
  return last;
}

function setLastChanges(
  prev: BoardPost['data'],
  next: BoardPost['data'],
  patch: DeepPartial<BoardPost>,
  currentUserId: Uuid,
): void {
  if (JSON.stringify(getMainContentSubset(prev)) !== JSON.stringify(getMainContentSubset(next))) {
    patch.lastContentChangeBy = currentUserId;
    patch.lastContentChangeOn = unix();
  }

  if (isDataWithAnswers(next)) {
    const lastAnswer = getLastAnswer(next);
    patch.lastAnswerBy = lastAnswer?.answeredBy || null;
    patch.lastAnswerOn = lastAnswer?.answeredOn || null;
  }

  if (isDataWithComments(next)) {
    const lastComment = getLastComment(next);
    patch.lastCommentBy = lastComment?.commentBy || null;
    patch.lastCommentOn = lastComment?.commentOn || null;
  }
}

export function patchBoardPost(id: Uuid, partial: PatchableBoardPostData): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    if (!validateErrorRules(getValidationRules(id, partial, false), dispatch)) {
      return false;
    }
    const state = getState();
    const nodes = nodeEntitySelector(state);
    const oldEntity = nodes[id];
    const patch = removeNotAllowedLabels(transformPatchDataToBoardPost(partial), labelIdsSelector(state));
    if (isBoardPost(oldEntity) && !objectContains(oldEntity, patch)) {
      if (patch.data) {
        const mergedData = mergeStates(oldEntity.data, patch.data);
        const currentUserId = currentOrgUserIdSelector(state);
        setLastChanges(oldEntity.data, mergedData, patch, currentUserId);
      }

      dispatch({
        [CALL_API]: {
          endpoint: routes.patchBoardPost,
          method: 'patch',
          options: {
            data: patch,
            urlParams: {
              boardPostId: id,
            },
          },
          types: {
            failureType: FLOW_CHANGE_BOARD_POST_FAILURE,
            requestType: FLOW_CHANGE_BOARD_POST_REQUEST,
            successType: FLOW_CHANGE_BOARD_POST_SUCCESS,
          },
        },
        patchedNodeId: id, //for the optimistic patch
      });
    }
    return true;
  };
}

export function removeBoardPost(boardPostId: Uuid): ActionDispatcher<boolean, FlowState> {
  return (dispatch) => {
    dispatch({
      [CALL_API]: {
        endpoint: routes.deleteBoardPost,
        method: 'del',
        options: {
          urlParams: {
            boardPostId,
          },
        },
        types: {
          failureType: FLOW_REMOVE_BOARD_POST_FAILURE,
          requestType: FLOW_REMOVE_BOARD_POST_REQUEST,
          successType: FLOW_REMOVE_BOARD_POST_SUCCESS,
        },
      },
      nodeIds: [boardPostId], //for the optimistic remove
    });
    return true;
  };
}

export enum SortDirection {
  up = -1,
  down = 1,
}

export function moveBoardPost(nodeId: Uuid, direction: SortDirection): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const parentNodeId = getParentIdSelector(state)(nodeId);
    const children = getChildNodesSelector(state)(parentNodeId);
    const boardPosts = children.filter(isBoardPost);
    boardPosts.sort((a, b) => a.sortOrder - b.sortOrder);
    const nodeIds = boardPosts.map((p) => p.id);
    const index = nodeIds.indexOf(nodeId);
    if (parentNodeId && index > -1) {
      nodeIds[index] = nodeIds[index + direction];
      nodeIds[index + direction] = nodeId;
      dispatch({
        nodeIds,
        [CALL_API]: {
          endpoint: routes.resortBoardPosts,
          method: 'patch',
          options: {data: {nodeIds}, urlParams: {parentNodeId}},
          types: {
            failureType: FLOW_RESORT_BOARD_POST_FAILURE,
            requestType: FLOW_RESORT_BOARD_POST_REQUEST,
            successType: FLOW_RESORT_BOARD_POST_SUCCESS,
          },
        },
      });
    }
  };
}
