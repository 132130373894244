import {ReactElement, useContext} from 'react';
import {flowStyleContext} from '../../../../../Styles/StyleContext';

export default function HRule(): ReactElement {
  const {
    COLORS: {BORDER},
  } = useContext(flowStyleContext);
  return (
    <div>
      <div className={'hr'} />
      {/*language=SCSS*/}
      <style jsx>{`
        .hr {
          height: 1px;
          background-color: ${BORDER.NORMAL};
          margin: 10px 0;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
