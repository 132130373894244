import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {useWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {DatePicker} from '@octaved/ui';
import {dateFormatSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import dayjs from 'dayjs';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../../../General/Common/Type';

export interface WorkPackageFreezeTimeTrackingProps {
  node: WorkPackage;
}

export default function WorkPackageFreezeTimeTracking({
  frame: Frame,
  node,
}: WorkPackageFreezeTimeTrackingProps & FramedSectionOrTileProps): ReactElement | null {
  const canRead = useIsGranted('FLOW_NODE_WORK_PACKAGE_READ_BILLING', node.id);
  const canManageBasic = useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) && !node.isArchived;
  const readonly = !canManageBasic;
  const patch = useWorkPackagePatch(node.id);
  const {t} = useTranslation();
  const displayedDateFormat = useSelector(dateFormatSelector);
  const useTimeTracking = useSelector(useTimeTrackingSelector);

  if (!useTimeTracking || !canRead || (!node.lastBillingPeriodEnd && !node.manualTimeTrackingEnd && readonly)) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.freezeTimeTracking.label'}>
      <div className={'flex items-center gap-x-2'}>
        <span>{t('nodeDetails:field.freezeTimeTracking.frozenByPartialBilling')}:</span>
        {node.lastBillingPeriodEnd ? (
          <span>{dayjs(node.lastBillingPeriodEnd).format(displayedDateFormat)}</span>
        ) : (
          <span className={'italic text-slate-500'}>{t('nodeDetails:field.freezeTimeTracking.noPartialBilling')}</span>
        )}
      </div>

      {(node.manualTimeTrackingEnd || !readonly) && (
        <div className={'flex items-center gap-x-2'}>
          <span>{t('nodeDetails:field.freezeTimeTracking.freezeManually')}:</span>
          {readonly ? (
            <span>{dayjs(node.manualTimeTrackingEnd).format(displayedDateFormat)}</span>
          ) : (
            <DatePicker date={node.manualTimeTrackingEnd} setDate={(date) => patch({manualTimeTrackingEnd: date})} />
          )}
        </div>
      )}
    </Frame>
  );
}
