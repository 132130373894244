import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {Message} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

export interface GroupBacklogProps {
  nodeId: Uuid;
}

export default function GroupBacklog({nodeId}: GroupBacklogProps): ReactElement | null {
  const {t} = useTranslation();
  const groupType = useSelector(getRootGroupTypeSelector)(nodeId);
  const isAgileBacklog = groupType === EnumFlowGroupType.VALUE_BACKLOG;
  if (!isAgileBacklog) {
    return null;
  }

  return (
    <Message colorScheme={'sky'} textSize={'sm'}>
      <span>{t('pages:planning.inspector.agileBacklogInfo')}</span>
    </Message>
  );
}
