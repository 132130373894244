export const FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_FAILURE =
  'FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_FAILURE';
export const FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_REQUEST =
  'FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_REQUEST';
export const FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS =
  'FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS';

export const FLOW_LOAD_WORKING_TIME_DAYS_FAILURE = 'FLOW_LOAD_WORKING_TIME_DAYS_FAILURE';
export const FLOW_LOAD_WORKING_TIME_DAYS_REQUEST = 'FLOW_LOAD_WORKING_TIME_DAYS_REQUEST';
export const FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS = 'FLOW_LOAD_WORKING_TIME_DAYS_SUCCESS';

export const FLOW_PUT_WORKING_TIME_ENTRY_FAILURE = 'FLOW_PUT_WORKING_TIME_ENTRY_FAILURE';
export const FLOW_PUT_WORKING_TIME_ENTRY_REQUEST = 'FLOW_PUT_WORKING_TIME_ENTRY_REQUEST';
export const FLOW_PUT_WORKING_TIME_ENTRY_SUCCESS = 'FLOW_PUT_WORKING_TIME_ENTRY_SUCCESS';

export const FLOW_DEL_WORKING_TIME_ENTRY_FAILURE = 'FLOW_DEL_WORKING_TIME_ENTRY_FAILURE';
export const FLOW_DEL_WORKING_TIME_ENTRY_REQUEST = 'FLOW_DEL_WORKING_TIME_ENTRY_REQUEST';
export const FLOW_DEL_WORKING_TIME_ENTRY_SUCCESS = 'FLOW_DEL_WORKING_TIME_ENTRY_SUCCESS';
