import {useIsMobileSafari} from '@octaved/ui-components/src/BrowserDetect/IsMobileSafari';
import classNames from 'classnames';
import {ReactElement, ReactNode} from 'react';
import {Popup} from 'semantic-ui-react';
import {PopupProps} from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';
import css from 'styled-jsx/css';

//#region <styles>
/*language=SCSS*/
const {className, styles} = css.resolve`
  .tooltip {
    z-index: 2000;
  }
`;

//#endregion

interface Props extends PopupProps {
  children: ReactNode;
  content: ReactNode;
  offset?: PopupProps['offset'];
  position?: PopupProps['position'];
}

function CustomTooltip({children, content, offset = [0], position = 'top center', ...props}: Props): ReactElement {
  const isIpad = useIsMobileSafari();

  if (isIpad) {
    return <>{children}</>;
  }

  return (
    <>
      <Popup
        className={classNames(className, 'tooltip')}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundary: window,
            },
          },
        ]}
        pinned
        inverted
        offset={offset}
        size={'mini'}
        position={position}
        on={'hover'}
        content={content}
        trigger={children}
        mouseEnterDelay={1000}
        {...props}
      />
      {styles}
    </>
  );
}

export {CustomTooltip};
