import {MaybeUuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {NodeType} from '../../EntityInterfaces/Nodes';
import {isCloseableNode, isLockingNode} from '../NodeIdentifiers';
import {getNodeAncestrySelector} from '../../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../../Modules/State';
import {NodeStatus} from './NodeStatus';

export const getNodeStatusWithBlameSelector = createSelector(getNodeAncestrySelector, (getNodeAncestry) =>
  memoize(<Id extends MaybeUuid>(nodeId: Id) => {
    const {ancestors, workPackage} = getNodeAncestry(nodeId, true);
    let sourceNodeId: Id | MaybeUuid = nodeId;
    let closedAncestors: NodeType[];
    let lockedAncestors: NodeType[];
    let status: NodeStatus;
    if ((closedAncestors = ancestors.filter((ancestor) => isCloseableNode(ancestor) && ancestor.isClosed)).length > 0) {
      status = 'isClosed';
      sourceNodeId = closedAncestors[closedAncestors.length - 1].id;
    } else if (workPackage?.isApprovedForBilling) {
      status = 'isApprovedForBilling';
      sourceNodeId = workPackage.id;
    } else if (
      (lockedAncestors = ancestors.filter((ancestor) => isLockingNode(ancestor) && ancestor.isLocked)).length > 0
    ) {
      status = 'isLocked';
      sourceNodeId = lockedAncestors[lockedAncestors.length - 1].id;
    } else {
      status = 'isOpen';
    }
    return {sourceNodeId, status} as {sourceNodeId: Id; status: NodeStatus};
  }),
);

export const getNodeStatusSelector = createSelector(
  getNodeStatusWithBlameSelector,
  (getNodeStatusWithBlame) => (nodeId: MaybeUuid) => getNodeStatusWithBlame(nodeId).status,
);

export function useNodeStatus(nodeId: MaybeUuid): NodeStatus {
  return useSelector((s: FlowState) => getNodeStatusSelector(s)(nodeId));
}
