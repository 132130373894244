import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import {cn} from './Hooks';

const TWTooltipProvider = TooltipPrimitive.Provider;

const TWTooltip = TooltipPrimitive.Root;

const TWTooltipTrigger = TooltipPrimitive.Trigger;

const TWTooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({className, sideOffset = 4, ...props}, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-white px-3 py-1.5 text-sm text-slate-800 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));
TWTooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {TWTooltip, TWTooltipContent, TWTooltipProvider, TWTooltipTrigger};
