import classNames from 'classnames';
import {ReactElement, useState} from 'react';
import {OnEnterKeyDown} from '../EditName';
import EditTaskSectionName from './EditTaskSectionName';

interface Props {
  autoFocus?: boolean;
  name: string;
  onBlur?: () => void;
  onEnterKeyDown?: OnEnterKeyDown;
  patch: (name: string) => void;
}

export default function TaskSectionName({autoFocus = false, onBlur, name, onEnterKeyDown, patch}: Props): ReactElement {
  const [focused, setFocused] = useState(autoFocus);

  return (
    <div className={classNames('taskSectionName', {focused})}>
      <EditTaskSectionName
        focused={focused}
        name={name}
        onBlur={onBlur}
        onEnterKeyDown={onEnterKeyDown}
        patch={patch}
        setFocused={setFocused}
      />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .taskSectionName {
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: space-between;
          border: 1px solid transparent;
          margin-right: -1px;
          padding: 0 calc(var(--cell-padding) / 2);
          transition: border-color 170ms ease-in-out;
          flex-grow: 1;
          font-size: 16px;
          font-weight: 600;
          height: 32px;
        }

        .taskSectionName :global(textarea) {
          font-weight: 600;
          color: transparent;
        }

        .taskSectionName.focused,
        .taskSectionName.focused:hover,
        .taskSectionName:focus-within {
          background-color: var(--color-bg);
          border-color: var(--color-border-focus);
          position: relative;
          z-index: 2;

          & :global(textarea) {
            background: #fff;
            color: #333;
          }
        }

        .taskSectionName:hover {
          position: relative;
          z-index: 1;
          border-color: var(--color-border-hover);
        }

        .taskSectionName :global(.onHover) {
          visibility: hidden;
          pointer-events: none;
        }

        .taskSectionName.focused :global(.onHover),
        .taskSectionName:hover :global(.onHover),
        .taskSectionName:focus-within :global(.onHover) {
          visibility: visible;
          pointer-events: all;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
