import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {generatePlanningDateInspectorRoute} from '@octaved/flow/src/Routing/Routes/Inspectors/PlanningDateInspectorRoute';
import {Uuid} from '@octaved/typescript/src/lib';
import {KonvaEventObject} from 'konva/lib/Node';
import {setHoveringNodeId} from '../../../Modules/ProjectPlanning';
import {setMouseHoverEffect} from '../../MouseHoverCursor';
import {Bar} from '../Bar';
import {BarPluginProps} from './BarPlugin';
import {BarRendererPlugin} from './BarRendererPlugin';

export interface ClickPluginProps extends BarPluginProps {
  nodeType: EnumFlowNodeType;
  planningDateId?: Uuid;
}

export class ClickPlugin extends BarRendererPlugin {
  #nodeType: EnumFlowNodeType;
  protected readonly planningDateId: Uuid | null;

  constructor(props: ClickPluginProps) {
    super(props);
    this.#nodeType = props.nodeType;
    this.planningDateId = props.planningDateId || null;
  }
  init(bar: Bar): void {
    super.init(bar);

    if (this.ctx.canInspectRow) {
      setMouseHoverEffect(this.rect, 'pointer', this.#onHoverPlanningDate);
      this.rect.on('click tap', this.#onClick);
    } else {
      setMouseHoverEffect(this.rect, undefined, this.#onHoverPlanningDate);
    }
  }

  #onClick = (e: KonvaEventObject<MouseEvent>): void => {
    if (e.evt.button === 0 || (window.TouchEvent && e.evt instanceof TouchEvent)) {
      //only allow left button
      if (!this.ctx.hasEditLock()) {
        if (this.#nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
          if (!this.planningDateId) {
            throw new Error('PlanningDateId is not set');
          }
          this.ctx.navigate(generatePlanningDateInspectorRoute(this.planningDateId, this.#nodeType));
        } else {
          this.ctx.setInspectorNodeId(this.nodeId, this.#nodeType);
        }
      }
    }
  };

  #onHoverPlanningDate = (isHovering: boolean): void => {
    if (!this.ctx.hasEditLock()) {
      if (this.#nodeType !== EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
        if (isHovering) {
          this.ctx.store.dispatch(setHoveringNodeId(this.bar.treeNode.id));
        } else {
          this.ctx.store.dispatch(setHoveringNodeId(null));
        }
      }
    }
  };

  protected getStyleColor(_state: FlowState): string {
    return 'transparent';
  }

  protected style(color: string): void {
    this.rect.fill(color);
  }
}
