import {EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {useMarkParentTasksDoneWorkflowControlModal} from '@octaved/flow/src/Dialogs/WorkflowControl/MarkParentTasksDoneWorkflowControl';
import {useTask} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {patchTaskWithTaskPatchData} from '@octaved/flow/src/Modules/Tasks';
import {useIsPlanningPage} from '@octaved/planning/src/Hooks/UseIsPlanningPage';
import {simulationModeActiveSelector} from '@octaved/planning/src/Selectors/UiSelectors';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWButton, cn} from '@octaved/ui';
import {CheckCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

interface Props {
  taskId: string;
}

export default function TaskToggleDone({taskId}: Props): ReactElement | null {
  const {t} = useTranslation();
  const task = useTask(taskId);
  const dispatch = useDispatch();
  const canManage = useIsGranted('FLOW_NODE_TASK_MANAGE_BASIC', taskId) && !task?.isArchived;
  const isInSimulationMode = useSelector(simulationModeActiveSelector);
  const buttonHidden = useIsPlanningPage() && isInSimulationMode;
  const {confirmCheckedModal, setChecked} = useMarkParentTasksDoneWorkflowControlModal(taskId, (checked) => {
    dispatch(
      patchTaskWithTaskPatchData(taskId, {
        status: checked ? EnumFlowTaskStatus.VALUE_COMPLETE : EnumFlowTaskStatus.VALUE_OPEN,
      }),
    );
  });

  if (!canManage || task?.isArchived || buttonHidden) {
    return null;
  }

  const isDone = task?.status === EnumFlowTaskStatus.VALUE_COMPLETE;
  return (
    <>
      <TWButton
        withIcon
        size={'md'}
        variant={isDone ? 'solid' : 'outline'}
        colorScheme={isDone ? 'green' : 'slate'}
        onClick={() => setChecked(!isDone)}
        className={'h-7'}
      >
        <CheckCircle className={cn('size-4 text-current')} />
        {t('pages:projects.inspector.header.taskDone')}
      </TWButton>
      {confirmCheckedModal}
    </>
  );
}
