import {Uuid} from '@octaved/typescript/src/lib';

type Rec<V> = Partial<Record<Uuid, V>>;

export interface SubtreeStatsBoard {
  lastAnswer: {boardPostId: Uuid; by: Uuid; on: number} | null;
  lastChange: {boardPostId: Uuid; by: Uuid; on: number} | null;
  lastComment: {boardPostId: Uuid; by: Uuid; on: number} | null;
  lastContentChange: {boardPostId: Uuid; by: Uuid; on: number} | null;
}

export const subtreeStatsBoardDefault: Readonly<SubtreeStatsBoard> = {
  lastAnswer: null,
  lastChange: null,
  lastComment: null,
  lastContentChange: null,
};

export interface SubtreeStatsHasGuestAccess {
  customer: {direct: boolean; subtree: boolean};
  other: {direct: boolean; subtree: boolean};
}

export interface SubtreeTaskCountsAndTimeSums {
  completeCount: number;
  completeTimeSum: number;
  totalCount: number;
  totalTimeSum: number;
  withTimeCount: number;
}

export interface SubtreeStats {
  board: Rec<SubtreeStatsBoard>;
  hasGuestAccess: Rec<SubtreeStatsHasGuestAccess>;
  taskCountsAndTimeSums: Rec<SubtreeTaskCountsAndTimeSums>; // NOTE: excludes tasks in offers!!
  trackedTimeWithEffort: Rec<[number, number]>; //[trackedTime, effectiveMaxEffort] both in hours
}

export type SubtreeStatIdent = keyof SubtreeStats;

export const initialSubtreeStats: SubtreeStats = {
  board: {},
  hasGuestAccess: {},
  taskCountsAndTimeSums: {},
  trackedTimeWithEffort: {},
};

export const subtreeStatsIdents = Object.keys(initialSubtreeStats) as ReadonlyArray<SubtreeStatIdent>;

export type LoadSubtreeStats = Partial<{[k in SubtreeStatIdent]: ReadonlyArray<Uuid> | ReadonlySet<Uuid>}>;
