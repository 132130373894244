import {Uuid} from '@octaved/typescript/src/lib';
import {UuidSearchResults} from '@octaved/utilities/src/Search/SearchReducers';
import {
  NodeSearch,
  NodeSearchIdent,
  NodeSearchIdentWithoutValue,
  NodeSearchIdentWithValue,
} from '../../EntityInterfaces/NodeSearch';
import {getNodeSearchKey} from '../Selectors/NodeSearchSelectors';

type Ids = ReadonlyArray<Uuid> | ReadonlySet<Uuid>;

/**
 * @internal
 */
function reduceRemoveIdsByKey(state: UuidSearchResults, ids: Ids, key: string): UuidSearchResults {
  const result = state[key];
  if (result) {
    const set = new Set([...result]);
    let changed = false;
    ids.forEach((id) => {
      if (set.has(id)) {
        set.delete(id);
        changed = true;
      }
    });
    if (changed) {
      return {...state, [key]: [...set]};
    }
  }
  return state;
}

/**
 * @internal
 */
function reduceRemoveIdsWithMatcher(state: UuidSearchResults, ids: Ids, matchKey?: RegExp): UuidSearchResults {
  let newState = state;
  Object.keys(state).forEach((key) => {
    if (!matchKey || matchKey.test(key)) {
      newState = reduceRemoveIdsByKey(newState, ids, key);
    }
  });
  return newState;
}

/**
 * @deprecated use reduceRemoveIdsByIdentPrefix or reduceRemoveIdsByIdentValue
 */
export function reduceRemoveIdsByKeyDEPRECATED(state: UuidSearchResults, ids: Ids, key: string): UuidSearchResults {
  return reduceRemoveIdsByKey(state, ids, key);
}

/**
 * If you don't have a NodeSearchIdentWithValue-ident, use reduceRemoveIdsByIdentValue()
 */
export function reduceRemoveIdsByIdentPrefix(
  state: UuidSearchResults,
  ids: Ids,
  ident: NodeSearchIdentWithValue,
): UuidSearchResults {
  return reduceRemoveIdsWithMatcher(state, ids, new RegExp(`^${ident}-`));
}

export function reduceRemoveIdsByIdentValue<I extends NodeSearchIdentWithoutValue>(
  state: UuidSearchResults,
  ids: Ids,
  ident: I,
): UuidSearchResults;
export function reduceRemoveIdsByIdentValue<I extends NodeSearchIdentWithValue>(
  state: UuidSearchResults,
  ids: Ids,
  ident: I,
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  value: NodeSearch[I],
): UuidSearchResults;
export function reduceRemoveIdsByIdentValue<I extends NodeSearchIdent>(
  state: UuidSearchResults,
  ids: Ids,
  ident: I,
  value?: NodeSearch[I],
): UuidSearchResults {
  return reduceRemoveIdsByKey(state, ids, getNodeSearchKey(ident, value as NodeSearch[I]));
}

export function reduceRemoveIdsFromAllSearches(state: UuidSearchResults, ids: Ids): UuidSearchResults {
  return reduceRemoveIdsWithMatcher(state, ids);
}
