import {error} from '@octaved/env/src/Logger';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import {useEffect} from 'react';

/**
 * This hook does not support IE11 yet, to add support consider adding this library or a polyfill as fallback
 * https://www.npmjs.com/package/element-resize-detector
 */
export default function useResizeObserver(
  element: HTMLElement | null | undefined,
  callback: () => void,
  delay = 200,
): void {
  useEffect(() => {
    const updateVisibleComponent = delay ? debounce(callback, delay) : callback;

    if (!window.hasOwnProperty('ResizeObserver')) {
      error('The ResizeObserver is not supported by the current browser.');
    }

    if (element && window.hasOwnProperty('ResizeObserver')) {
      const observer = new ResizeObserver(updateVisibleComponent);
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
    return noop;
  }, [element, callback, delay]);
}
