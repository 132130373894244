import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {noneRoles} from '@octaved/flow/src/Authorization/DefaultRoles';
import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import InheritanceInfoPopup from '@octaved/flow/src/Components/InheritanceInfoPopup';
import {RoleAssignmentOnNode} from '@octaved/flow/src/EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {Import, MoreHorizontal, Trash} from 'lucide-react';
import {ReactElement, useMemo, useRef, useState} from 'react';

function getInheritingCase(
  assignment: RoleAssignmentOnNode,
): 'inheriting' | 'notInheriting' | 'setHere' | 'noneWithoutParent' {
  if (assignment.isAdded) {
    return 'setHere';
  } else if (assignment.isOverridden || (assignment.isRemoved && assignment.parentDefiningTargetId)) {
    return 'notInheriting';
  } else if (!assignment.parentDefiningTargetId) {
    return 'noneWithoutParent';
  }
  return 'inheriting';
}
interface AssignmentRowMenuProps<RAN extends RoleAssignmentOnNode> {
  assignment: RAN;
  changeRole: (assignment: RAN, roleId: Uuid) => void;
  readonly: boolean;
  restoreInheritance: (assignment: RAN) => void;
  type: EnumFlowNodeRoleType;
}

export default function AssignmentRowMenu<RAN extends RoleAssignmentOnNode>({
  assignment,
  changeRole,
  readonly,
  restoreInheritance,
  type,
}: AssignmentRowMenuProps<RAN>): ReactElement {
  const [showInherited, setShowInherited] = useState(false);
  const infoPopupContext = useRef<HTMLDivElement>(null);

  const items = useMemo<DropdownPopupItemOptions[]>(() => {
    return [
      {
        icon: <Import />,
        iconColor: 'darkGrey',
        onClick: () => setShowInherited(true),
        token: 'general:showInheritance',
        type: DropdownPopupItemType.item,
      },
      {
        disabled: assignment.isRemoved && !!assignment.parentRole,
        hidden: readonly,
        icon: <Trash />,
        iconColor: 'red',
        onClick: () => changeRole(assignment, noneRoles[type]),
        token: 'general:remove',
        type: DropdownPopupItemType.item,
      },
    ];
  }, [changeRole, readonly, type, assignment]);

  return (
    <div ref={infoPopupContext}>
      <DropdownPopup items={items} position={'bottom left'} closeOnSelect>
        <IconButton size={'xs'} icon={<MoreHorizontal />} variant={'ghost'} />
      </DropdownPopup>

      {showInherited && (
        <InheritanceInfoPopup
          inheritanceCase={getInheritingCase(assignment)}
          mountContext={infoPopupContext}
          onClose={() => setShowInherited(false)}
          onRestoreInheritance={() => {
            restoreInheritance(assignment);
            setShowInherited(false);
          }}
          parentNodeId={assignment.parentDefiningTargetId}
          readonly={readonly}
          showInheritanceColorLegend
        />
      )}
    </div>
  );
}
