export function move(x: number, y: number): string {
  return `M ${x} ${y}`;
}

export function line(toX: number, toY: number): string {
  return `L ${toX} ${toY}`;
}

export function curve(toX: number, toY: number, dx1: number, dy1: number, dx2: number, dy2: number): string {
  return `C ${dx1} ${dy1}, ${dx2} ${dy2}, ${toX} ${toY}`;
}

export function joinMovements(list: Array<string | undefined | null>): string {
  return list.join(' ');
}
