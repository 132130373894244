import classNames from 'classnames';
import {ReactElement, useMemo} from 'react';
import CreatePidButtonsHStack from '../Components/CreatePidButtons/CreatePidButtonsHStack';
import {useProjectFrameContext} from './Pages/ProjectsFrameContext';

function useHasContentForContentHeader(): boolean {
  const {contentHeader, showCreationButtons} = useProjectFrameContext();
  return useMemo(() => {
    return !!contentHeader || !!showCreationButtons;
  }, [contentHeader, showCreationButtons]);
}

export default function ProjectsContentHeader(): ReactElement | null {
  const {
    contentHeader: CustomContentHeader,
    contentHeaderRef,
    contentHeaderVertical,
    showCreationButtons,
  } = useProjectFrameContext();
  const hasContent = useHasContentForContentHeader();

  if (hasContent) {
    return (
      <div className={classNames('contentHeader', {contentHeaderVertical})} ref={contentHeaderRef}>
        {showCreationButtons && <CreatePidButtonsHStack />}
        {CustomContentHeader && (
          <div className={'customContentHeader'}>
            <CustomContentHeader />
          </div>
        )}

        {/*language=SCSS*/}
        <style jsx>{`
          .contentHeader {
            display: flex;
            align-items: center;
            flex-shrink: 0;
          }

          .contentHeaderVertical {
            flex-direction: column;
            align-items: flex-start;
          }

          .customContentHeader {
            padding-bottom: 0;
          }
        `}</style>
      </div>
    );
  }
  return null;
}
