import {Uuid} from '@octaved/typescript/src/lib';
import {Label} from '@octaved/ui';
import Flex from '@octaved/ui-components/src/Flex';
import {NIL} from '@octaved/utilities/src/Uuid';
import {withValidation} from '@octaved/validation/src/ValidationField';
import {ReactElement, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, StrictDropdownProps} from 'semantic-ui-react';
import {sortedCustomersSelector} from '../../Modules/Selectors/CustomerSelectors';
import {errorsSelector} from '../../Modules/Selectors/UiSelectors';
import {removeErrorForField} from '../../Modules/Ui';

interface Props extends Omit<StrictDropdownProps, 'onChange'> {
  autoSelectInitialCustomer?: boolean;
  fieldName?: string;
  hideLockedCustomers?: boolean;
  onChange: (customerId: Uuid | null) => void;
  selectedCustomerId: Uuid | null;
  withAllOption?: boolean;
}

interface CustomerItem {
  text: string;
  value: Uuid;
}

const ValidationDropdown = withValidation(Dropdown);

export default function SelectCustomer({
  autoSelectInitialCustomer,
  hideLockedCustomers,
  onChange,
  selectedCustomerId,
  withAllOption,
  fieldName = '',
  ...props
}: Props): ReactElement {
  const {t} = useTranslation();
  const customers = useSelector(sortedCustomersSelector);
  //use a ref so that options don't update with selection:
  const initiallySelectedCustomerId = useRef(selectedCustomerId);
  const options = useMemo<CustomerItem[]>(() => {
    const filteredCustomers = hideLockedCustomers
      ? customers.filter(({id, isLocked}) => !isLocked || id === initiallySelectedCustomerId.current)
      : customers;
    const customerOptions = filteredCustomers.map(({id, name}) => ({text: name, value: id}));
    if (withAllOption) {
      customerOptions.unshift({
        text: t('pages:timeRecordings.all.filter.all'),
        value: NIL,
      });
    }
    return customerOptions;
  }, [hideLockedCustomers, customers, withAllOption, t]);

  //Always select down a default
  useEffect(() => {
    if (autoSelectInitialCustomer && !withAllOption && !selectedCustomerId && options.length) {
      onChange(options[0].value);
    }
  }, [selectedCustomerId, withAllOption, options, onChange, autoSelectInitialCustomer]);
  const errors = useSelector(errorsSelector);
  const dispatch = useDispatch();

  const onFieldChange = (value: string): void => {
    if (fieldName) {
      dispatch(removeErrorForField(fieldName));
    }
    onChange(value && value !== NIL ? value : null);
  };

  return (
    <Flex flexDirection={'column'} alignItems={'stretch'}>
      <Label token={'pages:timeRecordings.all.filter.customer'} />

      <ValidationDropdown
        errors={errors}
        fieldName={fieldName}
        search
        selection
        options={options}
        selectOnBlur={false}
        value={selectedCustomerId || (withAllOption ? NIL : undefined)}
        onChange={(_e, {value}) => onFieldChange(value as Uuid)}
        {...props}
      />
    </Flex>
  );
}
