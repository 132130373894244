import {DeepPartial, SubType} from '@octaved/typescript/src/lib';
import {parse} from '@octaved/users/src/Culture/NumberFormatter';

export function parseNullableNumber<Entity, EntityPatchData>(
  data: DeepPartial<EntityPatchData>,
  entity: DeepPartial<Entity>,
  key: keyof SubType<Entity, number | null | undefined> & keyof SubType<EntityPatchData, string | null | undefined>,
): void {
  const value = data[key] as string | null | undefined;
  if (typeof value !== 'undefined') {
    // @ts-ignore the key is right here...
    entity[key] = value ? parse(value) : null;
  }
}
