import {ProjectFilterQueries} from '../../../EntityInterfaces/Filter/ProjectFilters';

export function createProjectFilterQueries(): ProjectFilterQueries {
  return {
    descendants: [],
    indicatingDirectMatchForParents: [],
    projects: [],
    subprojects: [],
    workPackages: [],
  };
}
