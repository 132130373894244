import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {
  bulkPatchNodeInternalPermissionRoleAssignments,
  removeGroupNodePermissionRole,
  removeUserNodePermissionRole,
  setGroupNodePermissionRole,
  setUserNodePermissionRole,
} from '@octaved/flow-api';
import {bulkPatchNodeGuestPermissionRoleAssignments} from '@octaved/flow-api/config/routes';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {
  FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_FAILURE,
  FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_REQUEST,
  FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_SUCCESS,
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_FAILURE,
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_REQUEST,
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_SUCCESS,
  FLOW_EDIT_NODE_ROLE_FAILURE,
  FLOW_EDIT_NODE_ROLE_REQUEST,
  FLOW_EDIT_NODE_ROLE_SUCCESS,
} from '../ActionTypes';
import {nodePermissionRoleAssignmentEntrySelector} from '../Selectors/RoleAssignments/NodePermissionRoleAssignmentSelectors';
import {FlowState} from '../State';
import {createBulkPatchNodeRoleAssignmentsAction, unitToIdName} from './NodeRoleAssignments';

export interface RemovePermissionRoleAssignment {
  unitType: SimpleUnitType;
  unitId: Uuid;
}

export interface SetPermissionRoleAssignment extends RemovePermissionRoleAssignment {
  roleId: Uuid;
}

export const bulkPatchGuestNodePermissionRoles = createBulkPatchNodeRoleAssignmentsAction<
  SetPermissionRoleAssignment,
  RemovePermissionRoleAssignment
>(
  EnumFlowNodeRoleType.VALUE_PERMISSION,
  bulkPatchNodeGuestPermissionRoleAssignments,
  FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_FAILURE,
  FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_REQUEST,
  FLOW_BULK_PATCH_NODE_GUEST_PERMISSION_ROLES_SUCCESS,
);

export const bulkPatchInternalNodePermissionRoles = createBulkPatchNodeRoleAssignmentsAction<
  SetPermissionRoleAssignment,
  RemovePermissionRoleAssignment
>(
  EnumFlowNodeRoleType.VALUE_PERMISSION,
  bulkPatchNodeInternalPermissionRoleAssignments,
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_FAILURE,
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_REQUEST,
  FLOW_BULK_PATCH_NODE_INTERNAL_PERMISSION_ROLES_SUCCESS,
);

export function setNodePermissionRoleAssignment(
  isGuestRole: boolean,
  unitType: SimpleUnitType,
  unitId: Uuid,
  nodeId: Uuid,
  roleId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const asmt = nodePermissionRoleAssignmentEntrySelector(getState())(isGuestRole, nodeId, unitId);
    if (!asmt || asmt.isInherited || asmt.flowRoleId !== roleId) {
      await dispatch({
        [CALL_API]: {
          endpoint: {
            [SimpleUnitType.group]: setGroupNodePermissionRole,
            [SimpleUnitType.user]: setUserNodePermissionRole,
          }[unitType],
          method: 'put',
          options: {urlParams: {nodeId, [unitToIdName[unitType]]: unitId, roleId}},
          throwNetworkError: true,
          types: {
            failureType: FLOW_EDIT_NODE_ROLE_FAILURE,
            requestType: FLOW_EDIT_NODE_ROLE_REQUEST,
            successType: FLOW_EDIT_NODE_ROLE_SUCCESS,
          },
        },
      });
    }
  };
}

export function removeNodePermissionRoleAssignment(
  isGuestRole: boolean,
  unitType: SimpleUnitType,
  unitId: Uuid,
  nodeId: Uuid,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const currentAssignment = nodePermissionRoleAssignmentEntrySelector(getState())(isGuestRole, nodeId, unitId);
    if (currentAssignment) {
      await dispatch({
        [CALL_API]: {
          endpoint: {
            [SimpleUnitType.group]: removeGroupNodePermissionRole,
            [SimpleUnitType.user]: removeUserNodePermissionRole,
          }[unitType],
          method: 'del',
          options: {urlParams: {nodeId, [unitToIdName[unitType]]: unitId}},
          throwNetworkError: true,
          types: {
            failureType: FLOW_EDIT_NODE_ROLE_FAILURE,
            requestType: FLOW_EDIT_NODE_ROLE_REQUEST,
            successType: FLOW_EDIT_NODE_ROLE_SUCCESS,
          },
        },
      });
    }
  };
}
