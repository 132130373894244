import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {getClientXY} from '@octaved/flow/src/Hooks/MouseMove';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {isEqual, throttle} from 'lodash';
import {HoverCellChangedEvent, HoverRowChangedEvent, ScrollEvent} from '../../Calendar/Context/CalendarContext';
import {RootContainer, RootContainerProps} from '../../RootContainer';
import {createStoreSubscription} from '../../StoreSubscription';
import {GanttContext} from '../Context/GanttContext';
import {GanttDataLoader} from '../Data/GanttDataLoader';
import {BarFactory} from './BarFactory';
import {BarRow} from './BarRow';
import {PlaceholderCell} from './PlaceholderCell';

interface BarRowsProps extends RootContainerProps<GanttContext> {
  planningDataLoader: GanttDataLoader;
  barFactory: BarFactory;
}

export class BarRows extends RootContainer<GanttContext> {
  readonly #planningDataLoader: GanttDataLoader;
  #disposableRows: Array<() => void> = [];
  #disposableLoaderRows: Array<() => void> = [];

  readonly #scrollContainer = new Konva.Group({id: 'barScrollContainer'});
  readonly #dataRowGroup = new Konva.Group({id: 'dataRowGroup'});
  readonly #loaderDataRowGroup = new Konva.Group({visible: false, id: 'loaderDataRowGroup'});
  readonly #hoverBackground = new Konva.Rect({fill: '#0000001a', visible: false, id: 'hoverBackground'});
  readonly #selectedBackground = new Konva.Rect({fill: '#83c2e066', visible: false, id: 'selectedBackground'});
  readonly #hoverListener = new Konva.Rect({fill: 'transparent', name: 'hoverListener', id: 'hoverListener'}); //transparent rect so that the mouse events work
  readonly #menuRoot = new Konva.Group({id: 'menuRoot'});
  readonly #dependencyRoot = new Konva.Group({id: 'dependencyRoot'});

  readonly #placeholder: PlaceholderCell;
  readonly #barFactory: BarFactory;

  constructor(props: BarRowsProps) {
    super(props);

    this.#placeholder = new PlaceholderCell({ctx: this.ctx, menuRoot: this.#menuRoot, barFactory: props.barFactory});
    this.#planningDataLoader = props.planningDataLoader;

    this.#scrollContainer.add(this.#hoverBackground);
    this.#scrollContainer.add(this.#selectedBackground);
    this.#scrollContainer.add(new Konva.Group({id: 'todayPlaceholder'}));
    this.#scrollContainer.add(this.#hoverListener);
    this.#scrollContainer.add(this.#dependencyRoot);
    this.#scrollContainer.add(this.#placeholder.root);
    this.#scrollContainer.add(this.#dataRowGroup);
    this.#scrollContainer.add(this.#loaderDataRowGroup);
    this.#scrollContainer.add(this.#menuRoot);
    this.root.add(this.#scrollContainer);
    this.root.y(this.ctx.headerHeight);
    this.#barFactory = props.barFactory;

    this.root.on('mousemove tap', this.#onMouseMove);
    this.root.on('mouseleave', () => {
      this.#onMouseMove.cancel();
      this.#emitHoverChanged(null);
    });
  }

  #onMouseMove = throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
    const [clientX, clientY] = getClientXY(e.evt);
    const content = this.ctx.canvasView.canvas;
    const {height, top, width, left} = content.getBoundingClientRect();
    const scaleY = height / content.clientHeight || 1;
    const scaleX = width / content.clientWidth || 1;
    const mouseY = (clientY - top) / scaleY;
    const mouseX = (clientX - left) / scaleX;
    //first row is header
    const y = mouseY - this.root.y() + this.#scrollContainer.offsetY();
    const x = mouseX - this.#hoverListener.getClientRect().x;
    if (y < 0) {
      this.#emitHoverChanged(null);
      return;
    }
    const row = Math.floor(y / this.ctx.rowHeight);
    const column = Math.floor(x / this.ctx.columnWidth);
    const node = this.#planningDataLoader.flatTree[row];
    const currentDate = this.ctx.calendarView.dateStart.add(column, 'day');
    if (node) {
      this.#emitHoverChanged({
        columnIndex: column,
        date: toIsoFormat(currentDate),
        nodeId: node.id,
        rowIndex: row,
        rowIsCollapsed: !node.isExpanded,
      });
    } else {
      this.#emitHoverChanged(null);
    }
  }, 30);

  #lastHoverRow: HoverRowChangedEvent | null = null;
  #lastHoverCell: HoverCellChangedEvent | null = null;
  #emitHoverChanged(event: HoverCellChangedEvent | null): void {
    if (event?.nodeId !== this.#lastHoverCell?.nodeId || event?.date !== this.#lastHoverCell?.date) {
      this.ctx.eventEmitter.emit('hoverCellChanged', event);
      this.#lastHoverCell = event;
    }
    if (event?.nodeId !== this.#lastHoverRow?.nodeId) {
      this.ctx.hoveredRow = event;
      this.ctx.eventEmitter.emit('hoverRowChanged', event);
      this.#lastHoverRow = event;
    }
  }

  init(): void {
    if (!this.ctx.isReadonly) {
      this.#placeholder.init();
    }

    this.disposables.push(
      createStoreSubscription(this.ctx.store, getRootGroupTypeSelector, this.#updateByRootGroupTypes, isEqual),
      this.ctx.eventEmitter.on('flatTreeChanged', this.#updateByRootGroupTypes),
      this.ctx.eventEmitter.on('columnWidthChanged', this.#renderDataRows),
      this.ctx.eventEmitter.on('hoverRowChanged', this.#onHoverRowChanged),
      this.ctx.eventEmitter.on('selectedNodeIdChanged', this.#onSelectedNodeIdChanged),
      this.ctx.eventEmitter.on('calenderDatesChanged', this.#onCalenderDatesChanged),
      this.ctx.eventEmitter.on('scroll', this.#onScroll),
      this.ctx.eventEmitter.on('resize', this.#onResize),
      () => this.#placeholder.dispose(),
      () => this.#disposeRows(),
      createStoreSubscription(
        this.ctx.store,
        () => this.ctx.calculateTableWidth(),
        (width) => {
          this.root.x(width);
        },
      ),
    );
    this.#onResize();
  }

  #lastRootGroupTypes: Array<EnumFlowGroupType> = [];
  #updateByRootGroupTypes = this.debouncedAction((): void => {
    const getRootGroupType = getRootGroupTypeSelector(this.ctx.store.getState());
    const rootGroupTypes = this.#planningDataLoader.flatTree.map(({id}) => getRootGroupType(id));

    if (!isEqual(rootGroupTypes, this.#lastRootGroupTypes)) {
      this.#lastRootGroupTypes = rootGroupTypes;
      this.#renderDataRows();
    }
  });

  #renderDataRows = this.debouncedAction((): void => {
    let y = 0;
    for (let i = 0; i < this.#planningDataLoader.flatTree.length; i++) {
      const row = this.#planningDataLoader.flatTree[i];

      if (row.type !== 'customer') {
        const rowComponent: BarRow = this.#barFactory.createRowComponent({
          barFactory: this.#barFactory,
          ctx: this.ctx,
          dependencyRoot: this.#dependencyRoot,
          menuRoot: this.#menuRoot,
          node: row,
          planningDataLoader: this.#planningDataLoader,
          rowIndex: i,
        });

        this.#loaderDataRowGroup.add(rowComponent.root);
        rowComponent.init();
        rowComponent.root.y(y);
        this.#disposableLoaderRows.push(() => rowComponent!.dispose());
      }
      y += this.ctx.rowHeight;
    }

    this.#onSelectedNodeIdChanged(this.ctx.selectedNodeId);
    this.#hoverListener.width(this.ctx.calendarView.width);
    this.#hoverListener.height(this.ctx.calendarView.height);
    this.#menuRoot.width(this.ctx.calendarView.width);
    this.#menuRoot.height(this.ctx.calendarView.height);

    setTimeout(() => {
      //replace old bars after new bars are rendered
      this.#disposeRows();
      this.#dataRowGroup.add(...this.#loaderDataRowGroup.children);
      this.#disposableRows = this.#disposableLoaderRows;
      this.#disposableLoaderRows = [];
    }, 30);
  });

  #disposeRows(): void {
    for (const dispose of this.#disposableRows) {
      dispose();
    }
    this.#dataRowGroup.destroyChildren();
    this.#disposableRows = [];
  }

  #onCalenderDatesChanged = (): void => {
    this.#hoverBackground.width(this.ctx.calendarView.width);
    this.#selectedBackground.width(this.ctx.calendarView.width);
  };

  #onHoverRowChanged = (value: HoverRowChangedEvent | null): void => {
    if (value) {
      this.#hoverBackground.y(this.ctx.rowHeight * value.rowIndex);
      this.#hoverBackground.height(this.ctx.rowHeight);
      this.#hoverBackground.width(this.ctx.calendarView.width);
      this.#hoverBackground.visible(true);
    } else {
      this.#hoverBackground.visible(false);
    }
  };

  #onSelectedNodeIdChanged = (value: Uuid | null): void => {
    if (value) {
      const rowIndex = this.#planningDataLoader.flatTree.findIndex((node) => node.id === value);
      this.#selectedBackground.y(this.ctx.rowHeight * rowIndex);
      this.#selectedBackground.height(this.ctx.rowHeight);
      this.#selectedBackground.width(this.ctx.calendarView.width);
      this.#selectedBackground.visible(true);
    } else {
      this.#selectedBackground.visible(false);
    }
  };

  #onScroll = ({scrollLeft, scrollTop}: ScrollEvent): void => {
    this.#scrollContainer.offsetX(scrollLeft);
    this.#scrollContainer.offsetY(scrollTop);
  };

  #onResize = (): void => {
    this.root.clip({
      height: this.ctx.canvasView.height,
      width: this.ctx.canvasView.width,
      x: 0,
      y: 0,
    });
  };
}
