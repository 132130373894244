import {
  FLOW_LOAD_INTERNAL_ROLE_RIGHTS_FAILURE,
  FLOW_LOAD_INTERNAL_ROLE_RIGHTS_REQUEST,
  FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS,
  FLOW_PATCH_INTERNAL_ROLE_RIGHTS_FAILURE,
  FLOW_PATCH_INTERNAL_ROLE_RIGHTS_REQUEST,
  FLOW_PATCH_INTERNAL_ROLE_RIGHTS_SUCCESS,
} from '@octaved/flow/src/Modules/ActionTypes';
import {
  internalRoleRightMatrixSelector,
  internalRoleRightMatrixStateSelector,
} from '@octaved/flow/src/Modules/Selectors/RoleRightMatrix/RoleRightMatrixSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useObjectSnapshot} from '@octaved/hooks';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createFlatTimestampReducer,
  EntityState,
  INVALIDATED,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import objectContains from '@octaved/validation/src/ObjectContains';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as routes from '../../../config/routes';
import {InternalRoleRights} from '../../EntityInterfaces/RoleRights';

interface LoadSuccessAction {
  response: InternalRoleRights;
  type: typeof FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS;
}

const reducers = createReducerCollection<InternalRoleRights>({});
reducers.add(FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS, (_, action: LoadSuccessAction) => action.response);
export const reducer = reducers.reducer;

const stateReducers = createReducerCollection<EntityState>({});
stateReducers.add(FLOW_LOAD_INTERNAL_ROLE_RIGHTS_REQUEST, createFlatTimestampReducer(LOADING));
stateReducers.add(FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS, createFlatTimestampReducer(LOADED));
stateReducers.add('flow.InternalRoleRightMatrixChangedEvent', createFlatTimestampReducer(INVALIDATED));
export const stateReducer = stateReducers.reducer;

function getInternalRoleRights(): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = internalRoleRightMatrixStateSelector(getState());
    if (isOutdated(state)) {
      dispatch({
        [CALL_API]: {
          endpoint: routes.getInternalRoleRightMatrix,
          types: {
            failureType: FLOW_LOAD_INTERNAL_ROLE_RIGHTS_FAILURE,
            requestType: FLOW_LOAD_INTERNAL_ROLE_RIGHTS_REQUEST,
            successType: FLOW_LOAD_INTERNAL_ROLE_RIGHTS_SUCCESS,
          },
        },
      });
    }
  };
}

function patchInternalRoleRights(data: InternalRoleRights): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch, getState) => {
    const current = internalRoleRightMatrixSelector(getState());
    if (!objectContains(current, data)) {
      await dispatch({
        [CALL_API]: {
          endpoint: routes.patchInternalRoleRightMatrix,
          method: 'patch',
          options: {data},
          types: {
            failureType: FLOW_PATCH_INTERNAL_ROLE_RIGHTS_FAILURE,
            requestType: FLOW_PATCH_INTERNAL_ROLE_RIGHTS_REQUEST,
            successType: FLOW_PATCH_INTERNAL_ROLE_RIGHTS_SUCCESS,
          },
        },
      });
    }
  };
}

export function useLoadInternalRoleRights(): boolean {
  useStoreEffect((dispatch) => dispatch(getInternalRoleRights()), [], internalRoleRightMatrixStateSelector);
  return useSelector((state: FlowState) => !isLoaded(internalRoleRightMatrixStateSelector(state)));
}

export function usePatchInternalRoleRights(): {
  hasChanges: boolean;
  isLoading: boolean;
  matrix: InternalRoleRights;
  patch: (p: InternalRoleRights) => void;
  save: () => Promise<void>;
} {
  const isLoading = useLoadInternalRoleRights();

  const matrix = useSelector(internalRoleRightMatrixSelector);
  const {hasChanges, snapshot, patch, reset, refs} = useObjectSnapshot(() => matrix);

  useEffect(() => {
    if (!isLoading) {
      reset(true);
    }
  }, [isLoading, reset]);

  const dispatch = useDispatch();
  return {
    hasChanges,
    isLoading,
    patch,
    matrix: snapshot,
    save: useCallback(async () => {
      await dispatch(patchInternalRoleRights(refs.changeSet.current));
    }, [dispatch, refs.changeSet]),
  };
}
