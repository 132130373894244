import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {patchPlanning} from '@octaved/planning/src/Modules/Planning';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {cn, DatePicker, FormatDate, FormatDateFormats, NodeIcon} from '@octaved/ui';
import {Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface Props {
  dueDate: DateStr | null;
  dueDateToken?: string;
  id: Uuid;
  isMissconfigured?: boolean;
  nodeType: EnumFlowNodeType;
  readonly: boolean;
}

export default function DueDatePidGridRow({
  dueDate,
  dueDateToken = 'pages:projects.inspector.general.dueDate',
  id,
  isMissconfigured,
  nodeType,
  readonly,
}: Props): ReactElement | null {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <div className={cn('flex items-center gap-x-2', isMissconfigured && 'text-red-700')}>
        <NodeIcon nodeType={nodeType} /> {t(dueDateToken)}
      </div>
      <div className={cn('flex items-center gap-x-2', isMissconfigured && 'text-red-700')}>
        {readonly && !dueDate && (
          <div className={'italic text-slate-500'}>{t('nodeDetails:field.planning.projectDueDate.placeholder')}</div>
        )}
        {readonly && dueDate && <FormatDate date={dueDate} format={FormatDateFormats.short_with_year_with_day} />}
        {!readonly && (
          <>
            <DatePicker
              date={dueDate}
              setDate={(date: DateStr | null) => {
                dispatch(
                  patchPlanning([
                    {
                      dueDate: date,
                      nodeId: id,
                    },
                  ]),
                );
              }}
              readonly={readonly}
            />
          </>
        )}

        {isMissconfigured && <Flame className={'size-4'} fill={'#b91c1c'} stroke={'none'} />}
      </div>
    </>
  );
}
