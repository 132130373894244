import {useCallback, useEffect, useRef} from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDisposedSafeCallback<C extends (...args: any[]) => void>(cb: C): C {
  const cbRef = useRef<C | null>(cb);
  cbRef.current = cb;
  const wrapped = useCallback((...args) => {
    if (cbRef.current) {
      cbRef.current(...args);
    }
  }, []);
  useEffect(
    () => () => {
      cbRef.current = null;
    },
    [],
  );
  return wrapped as C;
}
