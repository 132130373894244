import {EnumFlowNodeType, EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {createSelector} from 'reselect';
import {
  ProjectFilterQueries,
  ProjectFilterQueryGenerator,
  ProjectFilterStates,
} from '../../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {userProjectRoleIdsQueryGenerator} from '../../Filter/AssignedProjectRoleId';
import {createBooleanNodeTypeSearchQueryGenerator} from '../../Filter/BooleanSearch';
import {createNodeTypeLabelIdsQueryGenerator, createScopedLabelIdsQuery} from '../../Filter/ScopedLabelIds';
import {
  createNodeTypeBoundStringsSearchQueryGenerator,
  createStringsSearchQueryGenerator,
} from '../../Filter/StringsSearch';
import {advancedBillingQueryGenerator} from './Custom/Billing';
import {advancedBillingInPeriodQueryGenerator} from './Custom/BillingInPeriod';
import {quickBillingPreparationQueryGenerator} from './Custom/BillingPreparation';
import {controlCriticalTimePrognosisQueryGenerator} from './Custom/ControlCriticalTimePrognosis';
import {controlTimeTransgressionQueryGenerator} from './Custom/ControlTimeTransgression';
import {advancedCreateOfferQueryGenerator} from './Custom/CreateOffer';
import {executionStatusQueryGeneratorSelector} from './ExecutionStatus';

function targetFilter<K extends keyof ProjectFilterStates, V = ProjectFilterStates[K]>(
  queryTarget: keyof ProjectFilterQueries,
  queryGenerator: (value: V) => NodeSearchCondition | null,
): ProjectFilterQueryGenerator<V> {
  return (queries, value) => {
    const query = queryGenerator(value);
    if (query !== null) {
      queries[queryTarget].push(query);
    }
  };
}

function taskFilter<V>(queryGenerator: (value: V) => NodeSearchCondition | null): ProjectFilterQueryGenerator<V> {
  return (queries, value) => {
    const query = queryGenerator(value);
    if (query !== null) {
      const cond: NodeSearchCondition = {and: [['nodeType', EnumFlowNodeType.VALUE_TASK], query]};
      queries.descendants.push(cond);
      queries.workPackages.push(withAncestors(cond, true));
    }
  };
}

function projectFilter<V>(queryGenerator: (value: V) => NodeSearchCondition | null): ProjectFilterQueryGenerator<V> {
  return (queries, value) => {
    const query = queryGenerator(value);
    if (query !== null) {
      queries.projects.push(query);
    }
  };
}

function workPackageFilter<V>(
  queryGenerator: (value: V) => NodeSearchCondition | null,
): ProjectFilterQueryGenerator<V> {
  return (queries, value) => {
    const query = queryGenerator(value);
    if (query !== null) {
      queries.descendants.push(query);
      queries.workPackages.push(query);
    }
  };
}

export type ProjectFilterRegistry = {
  [F in keyof ProjectFilterStates]: ProjectFilterQueryGenerator<ProjectFilterStates[F]['value']>;
};

const noop = (): void => undefined;

export const projectFilterRegistrySelector = createSelector(
  executionStatusQueryGeneratorSelector,
  (executionStatusQueryGenerator): ProjectFilterRegistry => {
    return {
      advancedBilling: advancedBillingQueryGenerator,
      advancedBillingInPeriod: advancedBillingInPeriodQueryGenerator,
      advancedControlTrackedTime: noop, //handled separately via the extraQueries tree option
      advancedControlTrackedTimeLabelIds: noop, //handled separately via the extraQueries tree option
      advancedCreateOffer: advancedCreateOfferQueryGenerator,
      boardPostLabelIds: createNodeTypeLabelIdsQueryGenerator([EnumFlowNodeType.VALUE_BOARD_POST]),
      descendantScopedLabelIds: targetFilter('descendants', createScopedLabelIdsQuery),
      executionStatus: executionStatusQueryGenerator,
      hideParentsWithoutMatches: noop, //handled in ProjectTreeFilters
      projectIsArchived: projectFilter(
        createBooleanNodeTypeSearchQueryGenerator('isArchived', EnumFlowNodeType.VALUE_PROJECT, true),
      ),
      projectIsLocked: projectFilter(
        createBooleanNodeTypeSearchQueryGenerator('prIsLocked', EnumFlowNodeType.VALUE_PROJECT, true),
      ),
      projectIsTemplate: projectFilter(
        createBooleanNodeTypeSearchQueryGenerator('prIsTemplate', EnumFlowNodeType.VALUE_PROJECT, true),
      ),
      projectLabelIds: createNodeTypeLabelIdsQueryGenerator([EnumFlowNodeType.VALUE_PROJECT]),
      projectUnitProjectRoleIds: targetFilter('projects', userProjectRoleIdsQueryGenerator),
      quickBillingInPeriod: noop, //handled in advancedBillingInPeriod
      quickBillingPreparation: quickBillingPreparationQueryGenerator,
      quickControlActivity: noop, //handled separately via the extraQueries tree option
      quickControlCriticalTimePrognosis: controlCriticalTimePrognosisQueryGenerator,
      quickControlFixedPriceAnalysis: noop, //handled separately via the extraQueries tree option
      quickControlPlanningTransgression: noop, //handled separately via the extraQueries tree option
      quickControlTimeTransgression: controlTimeTransgressionQueryGenerator,
      quickControlTrackedTime: noop, //handled separately via the extraQueries tree option
      quickExecutionStatus: executionStatusQueryGenerator,
      subprojectLabelIds: createNodeTypeLabelIdsQueryGenerator([EnumFlowNodeType.VALUE_GROUP]),
      taskLabelIds: taskFilter(createStringsSearchQueryGenerator('labelId')),
      workPackageBillingType: workPackageFilter(
        createNodeTypeBoundStringsSearchQueryGenerator(
          'wpBillingType',
          EnumFlowNodeType.VALUE_WORK_PACKAGE,
          Object.values(EnumFlowPidBillingType),
        ),
      ),
      workPackageIsApprovedForBilling: workPackageFilter(
        createBooleanNodeTypeSearchQueryGenerator('wpIsApprovedForBilling', EnumFlowNodeType.VALUE_WORK_PACKAGE, true),
      ),
      workPackageIsArchived: workPackageFilter(
        createBooleanNodeTypeSearchQueryGenerator('isArchived', EnumFlowNodeType.VALUE_WORK_PACKAGE, true),
      ),
      workPackageIsCompleted: workPackageFilter(
        createBooleanNodeTypeSearchQueryGenerator('wpIsCompleted', EnumFlowNodeType.VALUE_WORK_PACKAGE, true),
      ),
      workPackageIsLocked: workPackageFilter(
        createBooleanNodeTypeSearchQueryGenerator('wpIsLocked', EnumFlowNodeType.VALUE_WORK_PACKAGE, true),
      ),
      workPackageIsOffer: workPackageFilter(
        createBooleanNodeTypeSearchQueryGenerator('wpIsOffer', EnumFlowNodeType.VALUE_WORK_PACKAGE, true),
      ),
      workPackageLabelIds: workPackageFilter(
        createNodeTypeBoundStringsSearchQueryGenerator('labelId', EnumFlowNodeType.VALUE_WORK_PACKAGE),
      ),
      workPackageResponsibleUnits: workPackageFilter((units) =>
        units.length ? {or: units.map(({unitId}) => ['responsibleUnitId', unitId])} : null,
      ),
      workPackageUnitProjectRoleIds: workPackageFilter(userProjectRoleIdsQueryGenerator),
    };
  },
);
