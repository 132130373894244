import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {archiveNode} from '@octaved/flow/src/Modules/Nodes';
import {getParentNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {StatusWarningsPopup, useAsyncStatusWarnings} from '@octaved/flow/src/Node/NodeStatus/NodeStatusWarnings';
import {nodeTypeTokens} from '@octaved/flow/src/Node/NodeType';
import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWButton} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

interface Props {
  node: NodeType;
}

export default function ArchiveNode({node}: Props): ReactElement {
  const {t} = useTranslation();
  const isAllowed = useIsGranted('FLOW_NODE_ARCHIVE', node.id);
  const parent = useSelector((s: FlowState) => getParentNodeSelector(s)(node.id));
  const isArchivedAbove = !!parent?.isArchived;
  const canArchive = isAllowed && (!node.isArchived || !isArchivedAbove);
  const warnings = useAsyncStatusWarnings(node.id);

  const dispatch = useDispatch();

  const [onClick, isLoading] = useAsyncExecution(async () => {
    if (node.isArchived) {
      await dispatch(archiveNode(node.id, false));
    } else {
      const commit = async (): Promise<void> => {
        await dispatch(archiveNode(node.id, true));
      };
      if (await warnings.hasWarnings('isArchived')) {
        warnings.commit.current = commit;
      } else {
        await commit();
      }
    }
  });

  if (isArchivedAbove) {
    return (
      <div>
        <span>{t('general:nodeStatus.isArchived.inheritanceInfo')}</span>
        <span> </span>
        <span className={'font-semibold'}>
          {t(nodeTypeTokens[parent.nodeType])}
          {parent.nodeType !== EnumFlowNodeType.VALUE_PROJECT && <span className={'italic'}> {parent.name}</span>}
        </span>
      </div>
    );
  }

  return (
    <>
      {canArchive && (
        <div>
          <TWButton isLoading={isLoading} variant={'outline'} size={'md'} onClick={onClick}>
            {t(
              node.isArchived
                ? 'general:nodeStatus.isArchived.unarchiveButton'
                : 'general:nodeStatus.isArchived.archiveButton',
            )}
          </TWButton>
        </div>
      )}
      {warnings.stats && (
        <StatusWarningsPopup
          commit={warnings.commit}
          close={warnings.close}
          nodeType={node.nodeType}
          stats={warnings.stats}
          status={warnings.status}
        />
      )}
    </>
  );
}
