import {PropsWithChildren, ReactElement} from 'react';
import PickerViewWrapper from './PickerViewWrapper';

export default function PickerViewFooter({children}: PropsWithChildren<unknown>): ReactElement {
  return (
    <div className={'pickerFooter'}>
      <PickerViewWrapper>{children}</PickerViewWrapper>

      <style jsx>{`
        .pickerFooter {
          border-top: 1px solid var(--color-grey-100);
        }
      `}</style>
    </div>
  );
}
