import {DeepPartial} from '@octaved/typescript/src/lib';
import {AllOrSpecificUnits} from '@octaved/users/src/Components/AllOrSpecificUnitsSelector';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';

export interface WeeklyWorkingTimesUiState {
  search: string;
  selectedUnits: AllOrSpecificUnits;
}

export const initialWeeklyWorkingTimesUiState: WeeklyWorkingTimesUiState = {
  search: '',
  selectedUnits: 'users',
};

export function patchWeeklyWorkingTimesUiState(
  weeklyWorkingTimes: DeepPartial<WeeklyWorkingTimesUiState>,
): ThunkAct<void> {
  return setUiState({pages: {weeklyWorkingTimes}});
}
