import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {Uuid} from '@octaved/typescript/src/lib';
import {Selector, useSelector} from 'react-redux';
import {GroupEntity} from '../EntityInterfaces/GroupEntity';
import {OrgUserEntity} from '../EntityInterfaces/UserEntity';
import {loadGroups} from '../Modules/Group';
import {loadUsers} from '../Modules/OrgUser';
import {groupsSelector, groupStateSelector} from '../Selectors/GroupSelectors';
import {userEntitiesSelector, userEntityStatesSelector} from '../Selectors/UserSelectors';
import {SimpleUnitType} from '../UnitType';

export function useUnitEntity<F extends keyof OrgUserEntity, Type extends SimpleUnitType>(
  id: Uuid,
  type: Type,
  fields?: F[],
): (Type extends SimpleUnitType.user ? Pick<OrgUserEntity, F> : GroupEntity) | null | undefined {
  useStoreEffect(
    (dispatch) => {
      if (type === SimpleUnitType.user) {
        dispatch(loadUsers([id], fields));
      } else {
        dispatch(loadGroups([id]));
      }
    },
    [fields, id, type],
    userEntityStatesSelector as Selector<FlowState, ReturnType<typeof userEntityStatesSelector>>,
    groupStateSelector as Selector<FlowState, ReturnType<typeof groupStateSelector>>,
  );

  return useSelector(
    (state: FlowState) => (type === SimpleUnitType.user ? userEntitiesSelector : groupsSelector)(state)[id],
  ) as (Type extends SimpleUnitType.user ? Pick<OrgUserEntity, F> : GroupEntity) | null | undefined;
}

export function unitIsOrgUser(_unit: OrgUserEntity | GroupEntity, type: SimpleUnitType): _unit is OrgUserEntity {
  return type === SimpleUnitType.user;
}
