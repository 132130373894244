import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';

interface Props {
  from?: number;
  showEmptyHours?: boolean;
  to: number | null;
}

export default function DecimalHoursDuration({from = 0, showEmptyHours, to}: Props): ReactElement | null {
  if (to) {
    const diff = to - from;
    return <>{formatDecimal(diff)}&nbsp;h</>;
  }

  if (showEmptyHours) {
    return <>0&nbsp;h</>;
  }

  return null;
}
