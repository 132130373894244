import {getTransTokenForNodeType} from '@octaved/flow/src/Components/Node/NodeTypeName';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {DateStr} from '@octaved/typescript';
import {FormatDateFormats} from '@octaved/ui';
import {fromIsoFormat, isoToIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {X} from 'lucide';
import {moveBar} from '../../../Calculations/BarMovement';
import {updateMilestone} from '../../../Modules/Milestones';
import {patchPlanning} from '../../../Modules/Planning';
import {GroupedMilestoneResult} from '../../../Selectors/MilestoneSelectors';
import {GhostButton} from '../../CommonComponents/GhostButton';
import {createText} from '../../CommonComponents/Text';
import {resetCursor, setMouseHoverEffect} from '../../MouseHoverCursor';
import {MouseMove, MouseMoveEvent} from '../../MouseMove';
import {onOutsideClick} from '../../OutsideClick';
import {defaultShadow} from '../../Shadow';
import {Bar} from '../Bar';
import {BarPlugin, BarPluginProps} from './BarPlugin';
import {DueDateRenderPlugin} from './DueDateRenderPlugin';
import {MilestoneRenderPlugin} from './MilestoneRenderPlugin';

interface MaturityPopupPluginProps extends BarPluginProps {
  maturity: GroupedMilestoneResult;
}

interface ListProps<T> {
  items: T[];
  y: number;
  headlineToken: string;
  getItemText: (item: T) => string;
  createIcon: (size: number) => Konva.Rect;
}

export class MaturityPopupPlugin extends BarPlugin {
  static readonly dialogWidth = 250;
  static readonly paddingLeft = 18;

  #clickRect: Konva.Rect = new Konva.Rect({rotation: 45});
  #dialogRoot = new Konva.Group();
  #maturity: GroupedMilestoneResult;
  #isVisible = false;
  #mouseMove: MouseMove | null = null;

  constructor(props: MaturityPopupPluginProps) {
    super(props);
    this.#maturity = props.maturity;
  }

  init(bar: Bar): void {
    this.#clickRect.height(bar.height - 4);
    this.#clickRect.width(bar.height - 4);

    super.init(bar);

    this.root.add(this.#clickRect);

    setMouseHoverEffect(this.#clickRect, 'pointer');

    if (this.#maturity.maturityData.milestones.length + this.#maturity.maturityData.dueDateNodeIds.length === 1) {
      const mouseMove = new MouseMove({
        eventNode: this.#clickRect,
        onEnd: this.#onFinish,
        onMove: this.#onMove,
        onStart: this.#onStart,
      });
      mouseMove.init();
      this.disposables.push(() => mouseMove.dispose());
      this.#mouseMove = mouseMove;
    }

    this.#clickRect.on('click tap', () => {
      if (!this.#isVisible) {
        this.#createPopup();
        this.#updatePopupPosition();
        document.addEventListener('click', this.#onClickOutside);
        document.addEventListener('touchstart', this.#onClickOutside);
      }
    });
    this.disposables.push(
      () => this.#dialogRoot.destroy(),
      () => document.removeEventListener('click', this.#onClickOutside),
      () => document.removeEventListener('touchstart', this.#onClickOutside),
    );
  }

  getLockIdent(): string {
    return `move-${this.nodeId}`;
  }

  #closeInspector = false;
  #onStart = (): void => {
    if (this.ctx.hasEditLock()) {
      this.#mouseMove?.cancel();
      return;
    }
    this.#closeInspector = true;
  };

  #onMove = (e: MouseMoveEvent): void => {
    if (this.ctx.hasEditLock() && this.ctx.editLockIdent !== this.getLockIdent()) {
      this.#mouseMove?.cancel();
      return;
    }
    if (e.movementX > 10 || e.movementX < -10 || this.ctx.editLockIdent === this.getLockIdent()) {
      if (this.ctx.canInspectRow && this.#closeInspector) {
        this.ctx.onMoveCloseInspector(this.nodeId);
        this.#hide(); //close popup
        this.#closeInspector = false;
      }
      const result = this.#getMoveBarResult(e);
      const dateTimeResult = isoToIsoDateTimeFormat(result);
      this.bar.setDates(dateTimeResult, dateTimeResult);
      this.ctx.editLockIdent = this.getLockIdent();
    }
  };
  #onFinish = (e: MouseMoveEvent): void => {
    if (this.ctx.editLockIdent !== this.getLockIdent()) {
      //dragging only starts after moving some pixels to differentiate from click
      this.#mouseMove?.cancel();
      return;
    }
    const result = this.#getMoveBarResult(e);
    if (this.#maturity.maturityData.dueDateNodeIds.length === 1) {
      this.ctx.store.dispatch(patchPlanning([{dueDate: result, nodeId: this.nodeId}]));
    } else if (this.#maturity.maturityData.milestones.length === 1) {
      const milestoneId = this.#maturity.maturityData.milestones[0].id;
      this.ctx.store.dispatch(updateMilestone(this.nodeId, milestoneId, {milestoneDate: result}));
    }
    requestAnimationFrame(() => {
      //delay reset so that the click is handeld
      this.ctx.editLockIdent = null;
    });
  };

  #getMoveBarResult(e: MouseMoveEvent): DateStr {
    const result = moveBar(
      e.startX,
      e.mouseX,
      this.#maturity.date,
      this.#maturity.date,
      this.ctx.columnWidth,
      null,
      null,
      () => ({project: 8, work: 8}), //every day is a workday for milestones and due dates
    );
    return result.plannedStart;
  }

  #createPopup(): void {
    this.#isVisible = true;
    const background = new Konva.Rect({
      fill: 'white',
      height: 100,
      width: MaturityPopupPlugin.dialogWidth,
      ...defaultShadow,
    });

    let y = 12;

    const headline = createText({
      y,
      fontSize: 16,
      fontStyle: '500',
      text: fromIsoFormat(this.#maturity.date).format(FormatDateFormats.long_with_year),
      width: MaturityPopupPlugin.dialogWidth - MaturityPopupPlugin.paddingLeft * 2,
      x: MaturityPopupPlugin.paddingLeft,
    });
    y += headline.height() + 12;

    const close = new GhostButton({
      ctx: this.ctx,
      icon: X,
      onClick: () => {
        this.#hide();
        resetCursor();
      },
    });
    this.disposables.push(() => close.dispose());

    this.#dialogRoot.add(background);
    this.#dialogRoot.add(headline);
    this.#dialogRoot.add(close.root);
    close.init();

    const hoverBackground = close.root.findOne('.hoverBackground');
    if (hoverBackground) {
      close.root.x(MaturityPopupPlugin.dialogWidth - hoverBackground.width() - hoverBackground.y() - 1);
      close.root.y(0 - hoverBackground.y() + 1);
    }

    y = this.#createList({
      y,
      createIcon: MilestoneRenderPlugin.createMilestoneRect,
      getItemText: (item) => item.name || this.ctx.t('pages:projects.inspector.general.milestone.noName'),
      headlineToken: 'pages:projects.inspector.milestones.label',
      items: this.#maturity.maturityData.milestones,
    });
    y = this.#createList({
      y,
      createIcon: DueDateRenderPlugin.createDueDateRect,
      getItemText: (nodeId) => {
        const state = this.ctx.store.getState();
        const nodeType = getNodeSelector(this.ctx.store.getState())(nodeId)?.nodeType;
        if (nodeType) {
          const rootGroupType = getRootGroupTypeSelector(state)(nodeId);
          return this.ctx.t(getTransTokenForNodeType(nodeType, rootGroupType));
        }
        return '';
      },
      headlineToken: 'pages:projects.inspector.general.dueDateLabel',
      items: this.#maturity.maturityData.dueDateNodeIds,
    });

    background.height(y);
    this.bar.menuRoot.add(this.#dialogRoot);
  }

  #createList<T>({items, y, headlineToken: headlineToken, getItemText, createIcon}: ListProps<T>): number {
    if (items.length > 0) {
      const headline = createText({
        y,
        fontStyle: '500',
        text: this.ctx.t(headlineToken),
        width: MaturityPopupPlugin.dialogWidth - MaturityPopupPlugin.paddingLeft * 2,
        x: MaturityPopupPlugin.paddingLeft,
      });
      y += headline.height();
      this.#dialogRoot.add(headline, this.#createSeperatorLine(y));
      y += 12;

      for (const item of items) {
        const rect = createIcon(14);
        rect.y(y - 2);
        rect.x(MaturityPopupPlugin.paddingLeft + rect.width() * 0.5 + 1);

        const text = getItemText(item);
        this.#dialogRoot.add(
          rect,
          createText({
            text,
            y,
            fontStyle: '500',
            verticalAlign: 'middle',
            width: MaturityPopupPlugin.dialogWidth - MaturityPopupPlugin.paddingLeft * 2 - rect.width() - 4,
            x: rect.x() + rect.width() + 4,
          }),
        );

        y += rect.height() + 12;
      }
      y += 12 - 8;
    }
    return y;
  }

  #createSeperatorLine(y: number): Konva.Line {
    return new Konva.Line({
      points: [
        MaturityPopupPlugin.paddingLeft,
        y,
        MaturityPopupPlugin.dialogWidth - MaturityPopupPlugin.paddingLeft,
        y,
      ],
      stroke: '#2224261a',
      strokeWidth: 1,
    });
  }

  #updatePopupPosition(): void {
    const abs = this.#clickRect.getAbsoluteTransform(this.bar.menuRoot.getParent());
    const {x, y} = abs.getTranslation();
    this.#dialogRoot.x(x - MaturityPopupPlugin.dialogWidth * 0.5);
    this.#dialogRoot.y(y + Math.ceil(Math.sqrt(2 * Math.pow(this.bar.height - 4, 2))) + 5);
  }

  onBarUpdated(): void {
    this.#clickRect.x(this.bar.width);
  }

  #onClickOutside = (event: MouseEvent | TouchEvent): void => {
    if (onOutsideClick({event, root: this.root})) {
      this.#hide();
    }
  };

  #hide(): void {
    this.#dialogRoot.destroyChildren();
    document.removeEventListener('click', this.#onClickOutside);
    document.removeEventListener('touchstart', this.#onClickOutside);
    this.#isVisible = false;
  }
}
