import {boolFilter} from '@octaved/utilities';
import {isDevLocal} from './Environment';

const _features = [
  'permissionMatrix',
  'referenceNumberUrls:inInspectorHeader',
  'subWorkPackages',
  'workingTime:charts:showUntilToday',
  isDevLocal && 'devLanguageSwitcher',
] as const;

export const features = boolFilter(_features).sort();

type Feature = (typeof features)[number];

export function hasFeature(ident: Feature): boolean {
  return !!localStorage.getItem(`feature:${ident}`);
}

export function toggleFeature(ident: Feature, on?: boolean): void {
  if (on ?? !hasFeature(ident)) {
    localStorage.setItem(`feature:${ident}`, '1');
  } else {
    localStorage.removeItem(`feature:${ident}`);
  }
}
