import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {getParentsToChildrenMap} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {EffectiveRoleAssignment} from '../../EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {customerEntitiesSelector} from '../Selectors/CustomerSelectors';
import {isGroup, isProject, isWorkPackage} from '../../Node/NodeIdentifiers';
import {nodeEntitySelector} from '../Selectors/NodeSelectors';
import {
  ParentNode,
  PidNode,
  ProjectTreeChild,
  ProjectTreeGroup,
  ProjectTreeNode,
  ProjectTreeOptions,
  ProjectTreeProject,
  ProjectTreeWorkPackage,
  SelectableNode,
} from './ProjectTreeInterfaces';

interface BuiltProjectTree {
  allNodes: Map<Uuid, ProjectTreeNode>;
  treeProjects: ProjectTreeProject[];
}

const emptyTree: BuiltProjectTree = {
  allNodes: new Map(),
  treeProjects: [],
};

export function createProjectTreeSelectableNode(
  selectNodeId: ((id: string) => void) | undefined,
  selectedNodeId: string | null | undefined,
  id: Uuid,
): SelectableNode {
  return {
    id,
    isSelected: selectedNodeId === id,
    select: () => selectNodeId && selectNodeId(id),
  };
}

export function createProjectTreeParentNode<C>(
  expandedNodeIds: ReadonlySet<Uuid>,
  setExpandedNodeIds: Dispatch<SetStateAction<ReadonlySet<Uuid>>>,
  id: Uuid,
  children: C[],
): ParentNode<C> {
  return {
    children,
    collapse: () =>
      setExpandedNodeIds((oldSet) => {
        const newSet = new Set(oldSet);
        newSet.delete(id);
        return newSet;
      }),
    expand: () => setExpandedNodeIds((oldSet) => new Set(oldSet).add(id)),
    isExpanded: expandedNodeIds.has(id),
    toggleExpansion: (event) =>
      setExpandedNodeIds((oldSet) => {
        event.stopPropagation();
        event.preventDefault();
        const newSet = new Set(oldSet);
        if (newSet.has(id)) {
          newSet.delete(id);
        } else {
          newSet.add(id);
        }
        return newSet;
      }),
  };
}

function getSortOrder(
  id: Uuid,
  sortOrder: undefined | number,
  sortOrderOverride: Record<Uuid, number> | undefined,
): number {
  if (sortOrderOverride && sortOrderOverride.hasOwnProperty(id)) {
    return sortOrderOverride[id];
  }
  return sortOrder || 0;
}

function buildProjectManagersJoined(effectiveProjectRoleAssignments: EffectiveRoleAssignment[]): string {
  const pmRoles = effectiveProjectRoleAssignments.filter(({role}) => role.isProjectManagerRole);
  if (pmRoles.length) {
    return pmRoles.map(({unitName}) => unitName).join(', ');
  }
  //We must return a non-empty string here, so that the project grouping knows the grouping is fully done:
  return '-';
}

export function useBuildProjectTree(
  options: ProjectTreeOptions,
  isLoading: boolean,
  projectIds: ReadonlySet<Uuid>,
  groupIds: ReadonlySet<Uuid>,
  workPackageIds: ReadonlySet<Uuid>,
  searchedNodeIds: ReadonlySet<Uuid> | null,
  searchedNodeIdsForConcealedProperties: ReadonlySet<Uuid> | null,
  directMatchingParentIds: ReadonlySet<Uuid>,
  assignmentsOnNodes: Record<Uuid, EffectiveRoleAssignment[]>,
  expandedNodeIds: ReadonlySet<Uuid>,
  setExpandedNodeIds: Dispatch<SetStateAction<ReadonlySet<Uuid>>>,
): BuiltProjectTree {
  const nodeEntities = useSelector(nodeEntitySelector);
  const customerEntities = useSelector(customerEntitiesSelector);
  const tree = useMemo(() => {
    if (isLoading) {
      return emptyTree;
    }

    const allNodes = new Map<Uuid, ProjectTreeProject | ProjectTreeGroup | ProjectTreeWorkPackage>();

    const invertedNodeTree = getParentsToChildrenMap(options.nodeTree);

    function createPidNode(id: Uuid): PidNode {
      return {
        effectiveProjectRoleAssignments: assignmentsOnNodes[id] || [],
        isConcealedPropertySearchMatch: searchedNodeIdsForConcealedProperties
          ? searchedNodeIdsForConcealedProperties.has(id)
          : false,
        isDirectSearchMatch: searchedNodeIds ? searchedNodeIds.has(id) : false,
      };
    }

    function createSelectableNode(id: Uuid): SelectableNode {
      return createProjectTreeSelectableNode(options.selectNodeId, options.selectedNodeId, id);
    }

    function createParentNode<C>(id: Uuid, children: C[]): ParentNode<C> {
      return createProjectTreeParentNode(expandedNodeIds, setExpandedNodeIds, id, children);
    }

    function createWorkPackage(id: Uuid): ProjectTreeWorkPackage {
      const node = nodeEntities[id];
      const entity = isWorkPackage(node) ? node : undefined;
      return {
        ...createPidNode(id),
        ...createSelectableNode(id),
        entity,
        sortOrder: getSortOrder(id, entity?.sortOrder, options.sortOrderOverride),
        type: EnumFlowNodeType.VALUE_WORK_PACKAGE,
      };
    }

    function createGroup(id: Uuid, projectId: Uuid): ProjectTreeGroup | null {
      const node = nodeEntities[id];
      const entity = isGroup(node) ? node : undefined;
      const children = createChildren(id, projectId);
      if (children.length === 0 && options.hideEmptyParents && !directMatchingParentIds.has(id)) {
        return null;
      }
      return {
        ...createPidNode(id),
        ...createSelectableNode(id),
        ...createParentNode(id, children),
        entity,
        sortOrder: getSortOrder(id, entity?.sortOrder, options.sortOrderOverride),
        type: EnumFlowNodeType.VALUE_GROUP,
      };
    }

    function createChildren(parentId: Uuid, projectId: Uuid): ProjectTreeChild[] {
      const children: ProjectTreeChild[] = [];
      (invertedNodeTree.get(parentId) || []).forEach((childId) => {
        let child: ProjectTreeChild | null = null;
        if (groupIds.has(childId)) {
          child = createGroup(childId, projectId);
        } else if (workPackageIds.has(childId)) {
          child = createWorkPackage(childId);
        }
        if (child) {
          allNodes.set(child.id, child);
          children.push(child);
        }
      });
      children.sort((a, b) => a.sortOrder - b.sortOrder);
      return children;
    }

    function createProject(id: Uuid): ProjectTreeProject | null {
      const node = nodeEntities[id];
      const entity = isProject(node) ? node : undefined;
      const pidNode = createPidNode(id);
      const children = createChildren(id, id);
      if (children.length === 0 && options.hideEmptyParents && !directMatchingParentIds.has(id)) {
        return null;
      }
      const project: ProjectTreeProject = {
        ...pidNode,
        ...createSelectableNode(id),
        ...createParentNode(id, children),
        entity,
        customer: entity ? customerEntities[entity.flowCustomer] : undefined,
        projectManagersJoined: buildProjectManagersJoined(pidNode.effectiveProjectRoleAssignments),
        type: EnumFlowNodeType.VALUE_PROJECT,
      };
      allNodes.set(project.id, project);
      return project;
    }

    return {
      allNodes,
      treeProjects: boolFilter([...projectIds].map(createProject)),
    };
  }, [
    assignmentsOnNodes,
    customerEntities,
    directMatchingParentIds,
    expandedNodeIds,
    groupIds,
    isLoading,
    nodeEntities,
    options.hideEmptyParents,
    options.nodeTree,
    options.selectNodeId,
    options.selectedNodeId,
    options.sortOrderOverride,
    projectIds,
    searchedNodeIds,
    searchedNodeIdsForConcealedProperties,
    setExpandedNodeIds,
    workPackageIds,
  ]);
  return useLoadedValue(isLoading, tree);
}
