import Konva from 'konva';
import {CalendarContext} from '../Calendar/Context/CalendarContext';
import {resetCursor, setMouseHoverEffect} from '../MouseHoverCursor';
import {onOutsideClick} from '../OutsideClick';
import {RootContainer, RootContainerProps} from '../RootContainer';
import {defaultShadow} from '../Shadow';
import {IconNode} from '../SvgToCanvas';
import {createText, defaultFontSize, defaultLineHeight} from './Text';

export type MenuItems = Array<MenuItem | typeof MENU_DIVIDER>;

export interface MenuItem {
  icon: IconNode;
  token: string;
  iconColor: string;
  onClick: () => void;
}

export const MENU_DIVIDER = Symbol('MENU_DIVIDER');

interface MenuProps<CTX extends CalendarContext = CalendarContext> extends RootContainerProps<CTX> {
  outsideClickRoot?: Konva.Node;
}

export class Menu<CTX extends CalendarContext = CalendarContext> extends RootContainer<CTX> {
  readonly iconSize = 14;
  #isVisible = false;
  readonly #outsideClickRoot: Konva.Node[];

  constructor(props: MenuProps<CTX>) {
    super(props);

    this.#outsideClickRoot = props.outsideClickRoot ? [props.outsideClickRoot, this.root] : [this.root];
  }

  toggle(menuItems: MenuItems): void {
    if (this.#isVisible) {
      this.hide();
    } else {
      this.show(menuItems);
    }
  }

  show(menuItems: MenuItems): void {
    this.#cleanup();

    const backbround = new Konva.Rect({
      fill: '#fff',
      offsetX: 12,
      ...defaultShadow,
    });
    this.root.add(backbround);
    let y = 9;
    const widths: number[] = [];
    for (const item of menuItems) {
      if (item === MENU_DIVIDER) {
        this.#createDivider(y);
        y += 6;
      } else {
        const height = defaultLineHeight + 12;
        widths.push(this.#createRow(item, y, height));
        y += height;
      }
    }
    const backgroundWidth = Math.max(...widths) + 24;
    backbround.height(y - 9);
    backbround.width(backgroundWidth);
    this.root.find('.divider, .hoverBackground').forEach((item) => {
      if (item instanceof Konva.Line) {
        const [, y1, , y2] = item.points();
        item.points([0, y1, backgroundWidth, y2]);
      } else if (item instanceof Konva.Rect) {
        item.width(backgroundWidth);
      }
    });
    requestAnimationFrame(() => {
      document.addEventListener('mousedown', this.onClickOutside);
      document.addEventListener('touchstart', this.onClickOutside);
    });
    this.#isVisible = true;
  }

  onClickOutside = (event: MouseEvent | TouchEvent): void => {
    if (this.#outsideClickRoot.every((root) => onOutsideClick({event, root}))) {
      this.hide();
    }
  };

  #createDivider(y: number): void {
    const divider = new Konva.Line({
      name: 'divider',
      offsetX: 12,
      offsetY: -0.5,
      points: [0, y - 6, 200, y - 6],
      stroke: '#00000033',
      strokeWidth: 1,
    });
    this.root.add(divider);
  }

  #createRow(item: MenuItem, y: number, lineHeight: number): number {
    const group = new Konva.Group({
      y,
      height: 20,
      width: 200,
      x: 0,
    });
    group.on('click tap', () => {
      item.onClick();
      this.hide(true);
    });
    setMouseHoverEffect(group, 'pointer');

    const rect = new Konva.Rect({
      fill: '#fff',
      height: lineHeight,
      name: 'hoverBackground',
      offsetX: 12,
      offsetY: 9,
      width: 200,
    });
    rect.on('mousemove', () => {
      rect.fill('#0000000d');
    });
    rect.on('mouseleave', () => {
      rect.fill('#fff');
    });
    group.add(rect);

    this.iconToImage(item.icon, {
      height: this.iconSize,
      style: {
        color: item.iconColor,
      },
      width: this.iconSize,
    }).then((icon) => {
      group.add(icon);
    });

    const text = createText({
      fill: '#2d2d39',
      fontSize: defaultFontSize + 1,
      // listening: true,
      text: this.ctx.t(item.token),
      x: this.iconSize + 5,
      y: -2,
    });
    group.add(text);

    this.root.add(group);

    return text.x() + text.width();
  }

  #cleanup(): void {
    this.root.destroyChildren();
    document.removeEventListener('mousedown', this.onClickOutside);
    document.removeEventListener('touchstart', this.onClickOutside);
  }

  hide(doResetCursor = true): void {
    this.#cleanup();
    this.#isVisible = false;
    if (doResetCursor) {
      resetCursor();
    }
  }

  dispose(): void {
    super.dispose();
    this.#cleanup();
    this.root.destroy();
  }
}
