import {DateTimeStr} from '@octaved/typescript';
import {Bar, BarPluginList, BarProps} from '../../../Canvas/Bars/Bar';

export interface BaselineBarProps extends BarProps {
  plannedStart: DateTimeStr;
  plannedEnd: DateTimeStr;
}

export class BaselineDateBar extends Bar {
  #plannedStart: DateTimeStr;
  #plannedEnd: DateTimeStr;

  constructor(props: BaselineBarProps) {
    super(props);
    this.#plannedStart = props.plannedStart;
    this.#plannedEnd = props.plannedEnd;
  }

  init(...plugins: BarPluginList): void {
    this.setDates(this.#plannedStart, this.#plannedEnd);
    super.init(...plugins);
  }
}
