import {Uuid} from '@octaved/typescript/src/lib';
import {resolveBoolCondition} from '@octaved/utilities/src/Condition/ResolveBoolCondition';
import {AnyCondition} from '@octaved/utilities/src/Condition/Types';
import {FunctionComponent, ReactElement, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {IsGrantedMatchMode, IsGrantedNodes, isGrantedSelector} from './Authorization';
import {GlobalRight, NodeRight} from './Rights';

export type IsGrantedTuple =
  | GlobalRight
  | [NodeRight, IsGrantedNodes]
  | [NodeRight, IsGrantedNodes, IsGrantedMatchMode];

interface BaseProps {
  children: ReactNode;
  else?: ReactNode | ReactNode[] | FunctionComponent;
  not?: true; //inverts the right
}

interface PropsTuple extends BaseProps {
  matchMode?: never;
  right: AnyCondition<IsGrantedTuple | boolean>;
  unitId?: never;
}

interface PropsRightGlobal extends BaseProps {
  matchMode?: never;
  right: GlobalRight;
  unitId?: never;
}

interface PropsRightNode extends BaseProps {
  matchMode?: IsGrantedMatchMode;
  right: NodeRight;
  unitId: IsGrantedNodes;
}

export type IsGrantedProps = PropsTuple | PropsRightGlobal | PropsRightNode;

export default function IsGranted({
  children,
  matchMode,
  right,
  unitId,
  else: Else,
  not,
}: IsGrantedProps): ReactElement | null {
  const isGranted = useSelector(isGrantedSelector);

  const resolveConditionCallback = (value: IsGrantedTuple | boolean): boolean => {
    if (typeof value === 'boolean') {
      return value;
    }
    if (typeof value === 'string') {
      return isGranted(value);
    }
    return isGranted(value[0], value[1], value[2]);
  };

  let granted = false;
  if (typeof right === 'string') {
    granted = isGranted(right as NodeRight, unitId as Uuid, matchMode);
  } else {
    granted = resolveBoolCondition(right, resolveConditionCallback);
  }

  granted = not ? !granted : granted;

  if (granted) {
    return <>{children}</>;
  } else if (Else) {
    return typeof Else === 'function' ? <Else /> : <>{Else}</>;
  } else {
    return null;
  }
}
