import {getFlowCustomerSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {isGrantedSelector, useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from './Type';
import {Tooltip, TWButton} from '@octaved/ui';
import {Pen} from 'lucide-react';
import ChangeCustomer from '@octaved/flow/src/Dialogs/ChangeCustomer/ChangeCustomer';

export interface CustomerProps {
  customerId: Uuid;
  nodeId: Uuid;
  customerLocationId: Uuid | null;
}

export default function Customer({
  customerId,
  customerLocationId,
  nodeId,
  frame: Frame,
}: CustomerProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const customer = useSelector((state: FlowState) => getFlowCustomerSelector(state)(customerId));
  const location = customer?.locations.find((location) => location.id === customerLocationId);
  const [showDialog, setShowDialog] = useState(false);
  const isGranted = useSelector(isGrantedSelector);

  if (!useIsGranted('FLOW_GLOBAL_CUSTOMERS_READ')) {
    return null;
  }

  return (
    <>
      <Frame labelToken={'nodeDetails:field.customer.label'}>
        <div className={'flex items-center'}>
          {customer?.name}
          {customer?.customerNumber ? <> ({customer?.customerNumber})</> : <></>}
          {location && (
            <>
              <br />
              <i>{t('nodeDetails:field.customer.location')}:</i> {location.name}
              {location.number ? <> ({location.number})</> : <></>}
            </>
          )}
          {isGranted('FLOW_NODE_PID_MANAGE_BASIC', nodeId) && (
            <Tooltip toolTipTranslation={'pages:projects.inspector.header.changeCustomer'}>
              <TWButton variant={'ghost'} size={'xs'} onClick={() => setShowDialog(true)}>
                <Pen className={'h-3 w-3'} />
              </TWButton>
            </Tooltip>
          )}
        </div>
      </Frame>
      {showDialog && <ChangeCustomer changeCustomerProjectId={nodeId} close={() => setShowDialog(false)} />}
    </>
  );
}
