import {ComponentType, ReactElement} from 'react';
import {AuditReference} from '../../../../../EntityInterfaces/Audit/AuditReference';
import {ReferenceCellProps} from './ReferenceCell';

interface ReferenceListCellProps {
  cell: {value: Array<AuditReference | null> | null};
  disableLink?: boolean;
  listItemComponent: ComponentType<ReferenceCellProps>;
}

export default function ReferenceListCell({
  cell: {value},
  disableLink,
  listItemComponent: ListItemComponent,
}: ReferenceListCellProps): ReactElement | null {
  if (!value) {
    return null;
  }

  return (
    <div>
      {value.map((item) => {
        if (!item) {
          return null;
        }
        return (
          <div key={item.id}>
            <ListItemComponent cell={{value: item}} disableLink={disableLink} />
          </div>
        );
      })}
    </div>
  );
}
