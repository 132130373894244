import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {CalendarPlus2, Merge, Scissors, Trash} from 'lucide-react';
import {ReactElement, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {workdays} from '../../../Calculations/WorkdayCalculations';
import {PlanningDate} from '../../../EntityInterfaces/PlanningDates';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {
  mergeAllPlanningDates,
  mergePlanningDate,
  removePlanningDate,
  splitPlanningDate,
} from '../../../Modules/PlanningDates';
import {canAddGapBarSelector} from '../../../Selectors/CanAddGapBarSelector';
import RecurringPlanningDialog from '../RecurringPlanning/RecurringPlanningDialog';

interface MenuProps {
  asContextMenu?: boolean;
  canDelete: boolean;
  nextPlanningDate: PlanningDate | null;
  node: PlanningRealizationNode;
  planningDate: PlanningDate;
  previousPlanningDate: PlanningDate | null;
  trigger?: ReactElement;
}

export default function Menu({
  asContextMenu = false,
  canDelete,
  nextPlanningDate,
  node,
  planningDate,
  previousPlanningDate,
  trigger = <div />,
}: MenuProps): ReactElement | null {
  const dispatch = useDispatch();
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const canAddGapBar = useSelector(canAddGapBarSelector)(node.id);
  const [showAddRecurringBars, setShowAddRecurringBars] = useState(false);
  const items = useMemo(() => {
    const items: DropdownPopupItemOptions[] = [];

    if (
      canAddGapBar &&
      workdays(
        fromIsoDateTimeFormat(planningDate.plannedStart),
        fromIsoDateTimeFormat(planningDate.plannedEnd),
        getOrgWorkMinutesAtDate,
      ) > 1
    ) {
      items.push({
        icon: <Scissors />,
        iconColor: 'darkGrey',
        onClick: () => {
          dispatch(splitPlanningDate(node.id, planningDate.id));
        },
        token: 'pages:projects.inspector.manage.planning.splitGapBar',
        type: DropdownPopupItemType.item,
      });
    }

    items.push({
      icon: <CalendarPlus2 />,
      iconColor: 'darkGrey',
      onClick: () => setShowAddRecurringBars(true),
      token: 'pages:projects.inspector.manage.planning.recurringPlanning.addRecurring',
      type: DropdownPopupItemType.item,
    });

    if (nextPlanningDate || previousPlanningDate) {
      items.push({type: DropdownPopupItemType.divider});
    }

    if (previousPlanningDate) {
      items.push({
        icon: <Merge rotate={'90deg'} />,
        iconColor: 'darkGrey',
        onClick: () => {
          dispatch(mergePlanningDate(node.id, planningDate.id, previousPlanningDate.id));
        },
        token: 'pages:projects.inspector.manage.planning.mergeGapBarWithPrevious',
        type: DropdownPopupItemType.item,
      });
    }

    if (nextPlanningDate) {
      items.push({
        icon: <Merge rotate={'90deg'} />,
        iconColor: 'darkGrey',
        onClick: () => {
          dispatch(mergePlanningDate(node.id, planningDate.id, nextPlanningDate.id));
        },
        token: 'pages:projects.inspector.manage.planning.mergeGapBarWithNext',
        type: DropdownPopupItemType.item,
      });
    }

    if (nextPlanningDate || previousPlanningDate) {
      items.push({
        icon: <Merge rotate={'90deg'} />,
        iconColor: 'darkGrey',
        onClick: () => {
          dispatch(mergeAllPlanningDates(node.id));
        },
        token: 'pages:projects.inspector.manage.planning.mergeAllGapBars',
        type: DropdownPopupItemType.item,
      });
    }

    if (canDelete) {
      items.push({type: DropdownPopupItemType.divider});
      items.push({
        className: 'hover:!bg-red-50',
        icon: <Trash />,
        iconColor: 'red',
        onClick: () => removePlanningDate(node.id, planningDate.id),
        textColor: 'red',
        token: 'pages:projects.inspector.manage.planning.deleteGapBar',
        type: DropdownPopupItemType.item,
      });
    }

    return items;
  }, [
    planningDate.plannedStart,
    planningDate.plannedEnd,
    planningDate.id,
    getOrgWorkMinutesAtDate,
    canDelete,
    previousPlanningDate,
    nextPlanningDate,
    dispatch,
    node.id,
    canAddGapBar,
  ]);

  if (items.length === 0) {
    return null;
  }

  const props: {open?: boolean} = {};

  if (asContextMenu) {
    props.open = true;
  }

  return (
    <>
      <DropdownPopup
        items={items}
        position={'bottom left'}
        {...props}
        closeOnSelect
        inDrawer
        data-testid={'timePeriodMenu'}
      >
        {trigger}
      </DropdownPopup>
      {showAddRecurringBars && (
        <RecurringPlanningDialog nodeId={planningDate.nodeId} onClose={() => setShowAddRecurringBars(false)} />
      )}
    </>
  );
}
