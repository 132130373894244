import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {isResponsibleNode, ResponsibleNode} from '@octaved/flow/src/EntityInterfaces/ResponsibleNode';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getEffectiveProjectRoleAssignmentsForNodesSelector} from '@octaved/flow/src/Modules/Selectors/RoleAssignments/NodeProjectRoleAssignmentSelectors';
import {loadGroups} from '@octaved/users/src/Modules/Group';
import {loadUserDisplayNames} from '@octaved/users/src/Modules/OrgUser';
import {groupsSelector} from '@octaved/users/src/Selectors/GroupSelectors';
import {userEntitiesSelector} from '@octaved/users/src/Selectors/UserSelectors';
import {boolFilter} from '@octaved/utilities';
import {createStoreSubscription} from '../../../StoreSubscription';
import {SimpleTextCell} from './SimpleTextCell';

export class ExecutiveCell extends SimpleTextCell {
  protected nodeEntity: NodeEntity | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => getEffectiveProjectRoleAssignmentsForNodesSelector(store)([this.node.id]),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => groupsSelector(store),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => userEntitiesSelector(store),
        () => {
          this.render();
        },
      ),
    );
  }

  #getUnitTextList = (node: ResponsibleNode): string => {
    const groups = groupsSelector(this.ctx.store.getState());
    const users = userEntitiesSelector(this.ctx.store.getState());

    this.ctx.store.dispatch(loadGroups(node.responsibleGroups));
    this.ctx.store.dispatch(loadUserDisplayNames(node.responsibleUsers));

    const unitNames = [
      ...boolFilter(node.responsibleGroups.map((id) => groups[id]?.name)),
      ...boolFilter(node.responsibleUsers.map((id) => users[id]?.name)),
    ].sort();

    return unitNames.slice(0, 2).join(', ') + (unitNames.length > 2 ? ' +' + (unitNames.length - 2) : '');
  };

  protected getText(): string {
    if (isResponsibleNode(this.nodeEntity)) {
      return this.#getUnitTextList(this.nodeEntity);
    }
    return '';
  }
}
