import {Reducer} from 'redux';

export interface ReducerCollection<T = never> {
  add: <A extends {type: string | string[]}>(
    type: A['type'] | A['type'][],
    reducer: (state: T, action: A) => T,
  ) => void;
  reducer: Reducer<T> & {fieldsToCopy?: Set<string>};
}

export function createReducerCollection<T = never>(initialState: T): ReducerCollection<T> {
  const collection = new Map<string, Reducer<T>[]>();
  return {
    add: (type, reducer) => {
      const types = (Array.isArray(type) ? type : [type]) as string[];
      types.forEach((curType) => {
        if (!collection.has(curType)) {
          collection.set(curType, []);
        }
        collection.get(curType)!.push(reducer as Reducer<T>);
      });
    },
    reducer: (state = initialState, action) => {
      let newState = state;
      const types = Array.isArray(action.type) ? action.type : [action.type];
      types.forEach((type) => {
        const reducers = collection.get(type) || [];
        reducers.forEach((reducer) => {
          newState = reducer(newState, action);
        });
      });
      return newState;
    },
  };
}
