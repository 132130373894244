import {AuditCustomerPriceSurchargeOverride} from '../../../../EntityInterfaces/Audit/AuditCustomerPriceSurchargeOverride';
import BooleanCell from '../Table/Cells/BooleanCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditCustomerPriceSurchargeOverride> = {
  columns: [
    ...historyColumns,
    createColumnDef('surcharge', 'systemSettings:audit.tables.customerPriceSurchargeOverride.surchargeHeader'),
    createColumnDef(
      'flowCustomer',
      'systemSettings:audit.tables.customerPriceSurchargeOverride.flowCustomerHeader',
      ReferenceCell,
    ),
    createColumnDef(
      'priceSurcharge',
      'systemSettings:audit.tables.customerPriceSurchargeOverride.priceSurchargeHeader',
      ReferenceCell,
    ),
    createColumnDef(
      'surchargeDisabled',
      'systemSettings:audit.tables.customerPriceSurchargeOverride.surchargeDisabledHeader',
      BooleanCell,
    ),
    createColumnDef(
      'overrideIsActive',
      'systemSettings:audit.tables.customerPriceSurchargeOverride.overrideIsActiveHeader',
      BooleanCell,
    ),
  ],
  tableIdent: 'customerPriceSurchargeOverrides',
  tableNameTranslationToken: 'systemSettings:audit.customerPriceSurchargeOverridesTableName',
};
export default config;
