import {FlowState} from '@octaved/flow/src/Modules/State';
import {CALL_API, CallAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {toIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {patchUserSettings} from '@octaved/users/src/Modules/OrgUserSettings';
import {generateUuid} from '@octaved/utilities';
import dayjs from 'dayjs';
import {isPatchNodesResponsibilitiesRequest} from '../Calculations/MergeSimulationActions/MergePatchNodesResponsibilities';
import {isPatchPlanningRequest} from '../Calculations/MergeSimulationActions/MergePatchPlanningRequest';
import {isPatchTaskRequest} from '../Calculations/MergeSimulationActions/MergePatchTaskRequest';
import {isPatchWorkPackageRequest} from '../Calculations/MergeSimulationActions/MergePatchWorkPackageRequest';
import {mergeSimulationActions} from '../Calculations/MergeSimulationActions/MergeSimulationActions';
import {
  getCurrentPlanningSimulationSnapshotSelector,
  getPlanningSimulationSnapshotSelector,
  planningSimulationSnapshotsSelector,
} from '../Selectors/SimulationSnapshotSelectors';
import {selectedSimulationSnapshotIdSelector, simulationExcecutionLogSelector} from '../Selectors/UiSelectors';
import {SimulationExcecutionLogEntry, setPlanningState} from './Ui';

export interface PlanningSimulationSnapshot {
  id: Uuid;
  created: DateTimeStr;
  lastChanged: DateTimeStr;
  actions: CallAction[];
  name: string;
  changedNodeIds: Uuid[];
}

export type PlanningSimulationSnapshots = PlanningSimulationSnapshot[];

export function addActionToSimulationSnapshot(action: CallAction): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    let snapshot = getCurrentPlanningSimulationSnapshotSelector(state);
    const snapshots = planningSimulationSnapshotsSelector(state);
    const currentDateTime = toIsoDateTimeFormat(dayjs());
    if (!snapshot) {
      snapshot = {
        actions: [],
        changedNodeIds: [],
        created: currentDateTime,
        id: generateUuid(),
        lastChanged: currentDateTime,
        name: '',
      };
    } else {
      snapshot = {...snapshot};
    }
    const planningSimulationSnapshots = [...snapshots];
    const actions = mergeSimulationActions(snapshot, action);
    const index = planningSimulationSnapshots.findIndex(({id}) => id === snapshot!.id);
    if (index === -1) {
      planningSimulationSnapshots.push({
        ...snapshot,
        actions,
        lastChanged: currentDateTime,
      });
    } else {
      planningSimulationSnapshots[index] = {
        ...snapshot,
        actions,
        lastChanged: currentDateTime,
      };
    }

    return patchUserSettings({planningSimulationSnapshots})(dispatch, getState);
  };
}

export function patchSimulationSnapshot(snapshot: PlanningSimulationSnapshot): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const snapshots = planningSimulationSnapshotsSelector(state);
    const planningSimulationSnapshots = [...snapshots];
    const index = planningSimulationSnapshots.findIndex(({id}) => id === snapshot.id);
    if (index === -1) {
      planningSimulationSnapshots.push(snapshot);
    } else {
      planningSimulationSnapshots[index] = snapshot;
    }

    return patchUserSettings({planningSimulationSnapshots})(dispatch, getState);
  };
}

export function createSimulationSnapshot(
  snapshot: Pick<PlanningSimulationSnapshot, 'name'>,
  sourceSnaphotId: Uuid | null,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const planningSimulationSnapshots = [...planningSimulationSnapshotsSelector(state)];
    const source = getPlanningSimulationSnapshotSelector(state)(sourceSnaphotId);

    const currentDateTime = toIsoDateTimeFormat(dayjs());
    const id = generateUuid();
    planningSimulationSnapshots.push({
      ...snapshot,
      id,
      actions: source?.actions || [],
      changedNodeIds: source?.changedNodeIds || [],
      created: currentDateTime,
      lastChanged: currentDateTime,
    });

    patchUserSettings({planningSimulationSnapshots})(dispatch, getState);
    setPlanningState({selectedSimulationSnapshotId: id})(dispatch, getState);
  };
}

export function deleteSimulationSnapshot(id: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const planningSimulationSnapshots = [...planningSimulationSnapshotsSelector(state)];
    const index = planningSimulationSnapshots.findIndex((snapshot) => snapshot.id === id);
    if (index !== -1) {
      planningSimulationSnapshots.splice(index, 1);
      patchUserSettings({planningSimulationSnapshots})(dispatch, getState);

      const selectedId = selectedSimulationSnapshotIdSelector(state);
      if (selectedId === id) {
        const nextId = planningSimulationSnapshots[0]?.id || null;
        if (nextId) {
          setPlanningState({selectedSimulationSnapshotId: nextId})(dispatch, getState);
        } else {
          setPlanningState({selectedSimulationSnapshotId: null, simulationModeActive: false})(dispatch, getState);
        }
      }
    }
  };
}

function updateSimulationLogEntry(logEntry: SimulationExcecutionLogEntry): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const logs = simulationExcecutionLogSelector(state);
    const index = logs.findIndex(({id}) => id === logEntry.id);
    const simulationExcecutionLog = [...logs];
    if (index === -1) {
      simulationExcecutionLog.push(logEntry);
    } else {
      simulationExcecutionLog[index] = logEntry;
    }
    dispatch(setPlanningState({simulationExcecutionLog}));
  };
}

async function actionToLogEntry(action: CallAction, result: unknown, dispatch: Dispatch): Promise<void> {
  if (isPatchPlanningRequest(action) && result) {
    const _result = result as Record<string, string>;
    for (const [nodeId, nodeResult] of Object.entries(_result)) {
      const id = generateUuid();
      dispatch(updateSimulationLogEntry({id, nodeId, result: 'pending', action: 'planning'}));
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          dispatch(
            updateSimulationLogEntry({
              id,
              nodeId,
              action: 'planning',
              result: nodeResult === 'OK' ? 'success' : 'error',
            }),
          );
          resolve();
        }, 150);
      });
    }
  } else if (isPatchNodesResponsibilitiesRequest(action)) {
    for (const nodeId of Object.keys(action[CALL_API].options!.data)) {
      const id = generateUuid();
      dispatch(updateSimulationLogEntry({id, nodeId, result: 'pending', action: 'responsibilities'}));
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          dispatch(
            updateSimulationLogEntry({
              id,
              nodeId,
              action: 'responsibilities',
              result: 'success',
            }),
          );
          resolve();
        }, 150);
      });
    }
  } else if (isPatchWorkPackageRequest(action)) {
    const nodeId = action[CALL_API].options!.urlParams.workPackageId;
    const id = generateUuid();
    dispatch(updateSimulationLogEntry({id, nodeId, result: 'pending', action: 'timeMoney'}));
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        dispatch(
          updateSimulationLogEntry({
            id,
            nodeId,
            action: 'timeMoney',
            result: 'success',
          }),
        );
        resolve();
      }, 150);
    });
  } else if (isPatchTaskRequest(action)) {
    const nodeId = action[CALL_API].options!.urlParams.taskId;
    const id = generateUuid();
    dispatch(updateSimulationLogEntry({id, nodeId, result: 'pending', action: 'timeMoney'}));
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        dispatch(
          updateSimulationLogEntry({
            id,
            nodeId,
            action: 'timeMoney',
            result: 'success',
          }),
        );
        resolve();
      }, 150);
    });
  }
}

export function executeSimulationSnapshot(
  deleteAll: boolean,
  baseDispatch: Dispatch,
): ActionDispatcher<void, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const snapshot = getCurrentPlanningSimulationSnapshotSelector(state);
    if (snapshot) {
      for (const action of snapshot.actions) {
        const result = await baseDispatch(action);
        await actionToLogEntry(action, result, dispatch);
      }
      if (deleteAll) {
        patchUserSettings({planningSimulationSnapshots: []})(dispatch, getState);
        setPlanningState({selectedSimulationSnapshotId: null, simulationModeActive: false})(dispatch, getState);
      } else {
        deleteSimulationSnapshot(snapshot.id)(dispatch, getState);
      }
    }
  };
}
