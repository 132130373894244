import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodePidProps {
  pid: string;
}

export default function NodePid({frame: Frame, pid}: NodePidProps & FramedSectionOrTileProps): ReactElement {
  return <Frame labelToken={'nodeDetails:field.pid.label'}>{pid}</Frame>;
}
