import * as Logger from '@octaved/env/src/Logger';
import {StreamOptions} from '../Network';

interface Context {
  previousStringPiece?: string;
}

function decodeTextStream(value: Uint8Array, delimiter: string, context: Context): string[] {
  let lines;
  const string = new TextDecoder('utf-8').decode(value);
  if (delimiter) {
    const previous = context.previousStringPiece || '';
    lines = (previous + string).split(delimiter);
    context.previousStringPiece = lines.pop(); //cut off trailing edge (empty string or remainder)
  } else {
    lines = [string];
  }
  return lines;
}

/**
 * Handles a stream response
 *
 * Usage example that interprets the stream as text lines:
 *
 * Network.get('xxx', {stream: {
 *                       decode: 'text',
 *                       delimiter: '\n',
 *                       callback: (line) => {
 *                         //do something with `line`
 *                       },
 *                    }})
 *
 * @param {Response} response
 * @param {function} callback
 * @param {String} [decode] "text" decodes the byte stream to string.
 * @param {String} [delimiter] splits the decoded text with this delimiter
 * @return {Promise} resolves when the stream has finished
 */
function handleStream(response: Response, {callback, decode, delimiter}: StreamOptions): Promise<void> {
  const context = {};
  const reader = response.body!.getReader();
  return new Promise((resolve) => {
    const process = ({done, value}: ReadableStreamReadResult<Uint8Array>): void => {
      if (done) {
        resolve();
      } else if (value) {
        let lines: string[] | IterableIterator<number>;
        if (decode === 'text') {
          lines = decodeTextStream(value, delimiter, context);
        } else {
          lines = value.values(); //raw byte stream
        }
        for (const line of lines) {
          callback(line);
        }
        reader.read().then(process);
      }
    };
    reader.read().then(process);
  });
}

export function getResponseFilter(
  contentType: string,
  streamOptions: StreamOptions,
): {
  then: (response: Response) => Promise<Response> | Promise<void>;
  catch: (response: Response) => Promise<Response>;
} {
  return {
    catch: (response: Response): Promise<Response> => {
      //5xx errors or some 4xx usually do not have the content type we expect:
      if (
        response &&
        response.headers &&
        response.status < 500 &&
        ![403, 404, 405, 408, 413, 414].includes(response.status)
      ) {
        const responseContentType = response.headers.get('Content-Type');

        if (responseContentType && responseContentType.indexOf(contentType) === -1) {
          Logger.error(`[Network] Content type "${contentType}" expected, got "${responseContentType}"`, {
            status: response.status,
            url: response.url,
          });
        }
      }
      return Promise.reject(response);
    },

    /**
     * Transforms data into text. Handles 204 status codes.
     */
    then: (response: Response): Promise<Response> | Promise<void> => {
      if (streamOptions) {
        return handleStream(response, streamOptions);
      }

      if (response.status === 204) {
        // If the response is 204 (No content), then the json test will fail. Therefore, let the
        // Promise resolve and process. There is no parameter for this status code.
        return Promise.resolve();
      }

      const responseContentType = response.headers.get('Content-Type');
      let promise;
      if (
        responseContentType &&
        (responseContentType.indexOf('application/json') !== -1 ||
          responseContentType.indexOf('application/javascript') !== -1)
      ) {
        promise = response.json();
      } else {
        promise = response.text();
      }

      // Catch errors and return the response. Otherwise, an error is passed to the next Promise.
      return promise.catch((/* error */) => Promise.reject(response));
    },
  };
}
