import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {todayDayjsSelector} from '@octaved/flow/src/Today';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import dayjs from 'dayjs';
import {TFunction} from 'i18next';
import {debounce} from 'lodash';
import {Store} from 'redux';
import {Disposable} from '../../Disposable';
import {EventEmitter} from '../../EventEmitter';
import {CalendarView} from './CalendarView';
import {CanvasView} from './CanvasView';
import {Scroll, ScrollState} from './Scroll';

export interface SetInspectedRow {
  (id: string | null, type?: EnumFlowNodeType | 'customer'): void;
}

export interface CalendarContextProps<Events extends CalendarEvents = CalendarEvents> {
  vScrollBar: HTMLDivElement;
  hScrollBar: HTMLDivElement;
  scrollWheel: HTMLDivElement;
  canvas: HTMLDivElement;
  scrollbarSize: number;
  store: Store<FlowState>;
  t: TFunction;
  eventEmitter: EventEmitter<Events>;
  calendarView: CalendarView;
  navigate(to: string): void;
  isReadonly?: boolean;
  setInspectedRow?: SetInspectedRow;
  showContextMenu?: boolean;
}

export interface CalenderResizeEvent {
  width: number;
  height: number;
}

export interface CalendarDatesChangedEvent {
  start: dayjs.Dayjs;
  isoStart: DateStr;
  end: dayjs.Dayjs;
  isoEnd: DateStr;
  currentScrollDate: dayjs.Dayjs;
  startChanged: boolean;
  endChanged: boolean;
}
export interface HoverRowChangedEvent {
  nodeId: Uuid;
  rowIndex: number;
}

export interface HoverCellChangedEvent {
  date: DateStr;
  columnIndex: number;
  nodeId: Uuid;
  rowIndex: number;
  rowIsCollapsed: boolean;
}

export interface ColumnWidthChangedEvent {
  width: number;
  currentScrollDate: dayjs.Dayjs;
}

export type ScrollEvent = Readonly<ScrollState>;

export interface CalendarEvents {
  resize: CalenderResizeEvent;
  scroll: ScrollEvent;
  calenderDatesChanged: CalendarDatesChangedEvent;
  hoverRowChanged: HoverRowChangedEvent | null;
  hoverCellChanged: HoverCellChangedEvent | null;
  columnWidthChanged: ColumnWidthChangedEvent;
  editLockIdentChanged: Uuid | null;
}

export abstract class CalendarContext<Events extends CalendarEvents = CalendarEvents> extends Disposable {
  #rowHeight = 24;
  #columnWidth = 40;
  #headerHeight = 36;

  readonly scrollbarSize;
  readonly scrolling: Scroll;

  readonly store: Store<FlowState>;
  readonly eventEmitter: EventEmitter<Events>;

  readonly canvasView: CanvasView;
  calendarView: CalendarView;
  readonly t: TFunction;
  readonly navigate: (to: string) => void;
  readonly isReadonly: boolean;
  readonly canInspectRow: boolean;
  #editLockIdent: Uuid | null = null;
  hoveredRow: HoverRowChangedEvent | null = null;
  readonly #setInspectedRow?: SetInspectedRow;
  readonly showContextMenu: boolean;

  constructor({
    store,
    canvas,
    hScrollBar,
    scrollWheel,
    vScrollBar,
    scrollbarSize,
    t,
    eventEmitter,
    calendarView,
    navigate,
    isReadonly = false,
    setInspectedRow,
    showContextMenu = true,
  }: CalendarContextProps<Events>) {
    super();
    this.t = t;
    this.navigate = navigate;
    this.scrollbarSize = scrollbarSize;
    this.store = store;
    this.eventEmitter = eventEmitter;
    this.isReadonly = isReadonly;
    this.#setInspectedRow = setInspectedRow;
    this.canInspectRow = !!this.#setInspectedRow;

    this.scrolling = new Scroll({vScrollBar, hScrollBar, scrollWheel, ctx: this});
    this.scrolling.init();
    this.calendarView = calendarView;
    this.canvasView = new CanvasView({ctx: this, canvas});
    this.showContextMenu = showContextMenu;

    calendarView.init(this);
    this.canvasView.init();

    this.disposables.push(() => this.scrolling.dispose());

    if ('ResizeObserver' in window) {
      const resizeObserver = new ResizeObserver(() => this.#onResize());
      resizeObserver.observe(scrollWheel);
      this.disposables.push(() => resizeObserver.disconnect());
    } else {
      (window as Window).addEventListener('resize', this.#onResize);
      this.disposables.push(() => window.removeEventListener('resize', this.#onResize));
    }
  }

  init(): void {
    //
  }

  setInspectorNodeId(id: null): void;
  setInspectorNodeId(id: Uuid, type: EnumFlowNodeType | 'customer'): void;
  setInspectorNodeId(id: Uuid | null, type?: EnumFlowNodeType | 'customer'): void {
    this.#setInspectedRow?.(id, type);
  }

  onMoveCloseInspector(_id: Uuid): void {
    //The inspector used to be closed when moving bars.
    //Some places called `navigate(generateStandardInspectorRoute(null))` directly,
    // and some called setInspectorNodeId(null), this method consolidates this behavior.
    //For now this is a noop - we don't want to close the inspector anymore when moving bars.
  }

  #onResize = debounce((): void => {
    requestAnimationFrame(() => {
      this.eventEmitter.emit('resize', {
        height: this.canvasView.height,
        width: this.canvasView.width,
      });
    });
  }, 100);

  get headerHeight(): number {
    return this.#headerHeight;
  }

  get rowHeight(): number {
    return this.#rowHeight;
  }

  get columnWidth(): number {
    return this.#columnWidth;
  }

  set columnWidth(width: number) {
    if (this.#columnWidth !== width) {
      this.#columnWidth = width;
      this.eventEmitter.emit('columnWidthChanged', {width, currentScrollDate: this.canvasView.dateStart});
    }
  }

  get today(): dayjs.Dayjs {
    return todayDayjsSelector(this.store.getState());
  }

  set editLockIdent(lockIdent: Uuid | null) {
    if (this.#editLockIdent !== lockIdent) {
      this.#editLockIdent = lockIdent;
      this.eventEmitter.emit('editLockIdentChanged', lockIdent);
    }
  }

  get editLockIdent(): Uuid | null {
    return this.#editLockIdent;
  }

  hasEditLock(): boolean {
    return this.#editLockIdent !== null;
  }

  dispose(): void {
    super.dispose();
    this.canvasView.dispose();
    this.calendarView.dispose();
  }
}
