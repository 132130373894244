import {Uuid} from '@octaved/typescript/src/lib';
import {Tooltip} from '@octaved/ui';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {useExtendUnitsWithName} from '@octaved/users/src/Hooks/UnitLists';
import cs from 'classnames';
import {ReactElement, useMemo} from 'react';

export interface UnitTextListProps {
  ellipsis?: boolean;
  units: SlimUnit[];
  toolTipToken?: string;
}

export default function UnitTextList({
  ellipsis = false,
  units,
  toolTipToken = 'projectsTree:tooltipProjectManager',
}: UnitTextListProps): ReactElement | null {
  const fullUnits = useExtendUnitsWithName(units);
  const unitNames = useMemo(() => {
    const uniqueNames = new Map<Uuid, string>();
    fullUnits.forEach((unit) => {
      uniqueNames.set(unit.unitId, unit.unitName);
    });
    const names = [...uniqueNames.values()];
    names.sort();
    return names;
  }, [fullUnits]);

  if (unitNames.length) {
    return (
      <Tooltip
        toolTipTranslation={{
          i18nKey: toolTipToken,
          values: {users: unitNames.join(', ')},
        }}
      >
        <div className={cs('assignedUnitList', {ellipsis})}>
          {unitNames.slice(0, 2).join(', ')}
          {unitNames.length > 2 && <>,&nbsp;+{unitNames.length - 2}</>}
          {/*#region styles*/}
          {/*language=SCSS*/}
          <style jsx>{`
            .assignedUnitList {
              line-height: 1.1em;
              color: var(--color-font-project-minorDetails, #666);
            }

            .ellipsis {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}</style>
          {/*#endregion*/}
        </div>
      </Tooltip>
    );
  }
  return null;
}
