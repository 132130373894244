import {features, hasFeature, toggleFeature} from '@octaved/env/src/Features';
import {ReactElement} from 'react';
import {Checkbox} from 'semantic-ui-react';
import PageContent from '../PageFrame/PageContent/PageContent';
import StandardPageContent from '../PageFrame/PageContent/StandardPageContent';
import StandardPageFrame from '../PageFrame/StandardPageFrame';
import StandardPageFrameContent from '../PageFrame/StandardPageFrameContent';

export default function HiddenFeatures(): ReactElement {
  return (
    <StandardPageFrame>
      <StandardPageFrameContent>
        <StandardPageContent>
          <PageContent noScroll>
            <div className={'flex flex-col gap-y-4 px-6 py-6'}>
              {features.map((feature) => {
                return (
                  <div key={feature}>
                    <Checkbox
                      label={feature}
                      checked={hasFeature(feature)}
                      onChange={(_, {checked}) => {
                        toggleFeature(feature, !!checked);
                        window.location.reload();
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </PageContent>
        </StandardPageContent>
      </StandardPageFrameContent>
    </StandardPageFrame>
  );
}
