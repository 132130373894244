import {getOrganizationHeaders} from '@octaved/identity/src/Modules/OrganizationSwitcher';
import {generateUrl} from '@octaved/network/src/Network';
import {UrlParams} from '@octaved/network/src/NetworkMiddlewareTypes';
import {useCallback, useRef, useState} from 'react';

function downloadData(blob: Blob, contentType: string | null, fileName: string): void {
  const downloadLink = window.document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentType || ''}));
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export interface DownloadBlobSubmitEvent {
  preventDefault(): void;

  stopPropagation(): void;
}

function getFilenameFromHeader(response: Response, key: string | undefined): string | null {
  if (key) {
    const headerValue = response.headers.get(key);
    //Because utf8 is not supported in headers (https://dzone.com/articles/utf-8-in-http-headers),
    // we need to decode it:
    if (headerValue && headerValue.startsWith('json::')) {
      return JSON.parse(headerValue.slice(6));
    }
    return headerValue;
  }
  return null;
}

export interface DownloadBlobOptions {
  fileName?: string;
  fileNameFromHeaderKey?: string;
  method?: 'GET' | 'POST';
}

export async function dowoadBlob(
  route: string,
  urlParameters?: UrlParams,
  data?: unknown,
  {fileName, fileNameFromHeaderKey = 'octaved-filename', method = 'GET'}: DownloadBlobOptions = {},
): Promise<void> {
  const options = {
    method,
    body: method === 'GET' ? undefined : JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      ...getOrganizationHeaders(),
    },
  };
  const url = generateUrl(route, urlParameters);
  const response = await fetch(url, options);
  if (response.status === 200) {
    const blob = await response.blob();
    downloadData(
      blob,
      response.headers.get('Content-Type'),
      fileName || getFilenameFromHeader(response, fileNameFromHeaderKey) || 'unknown',
    );
  }
}

interface UseDownloadBlob {
  (
    route: string,
    urlParameters?: UrlParams,
    data?: unknown,
    options?: DownloadBlobOptions,
  ): {
    isLoading: boolean;
    onSubmit: (event?: DownloadBlobSubmitEvent) => Promise<void>;
  };
}

export const useDowoadBlob: UseDownloadBlob = (..._args) => {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const args = useRef(_args);
  args.current = _args;

  const onSubmit = useCallback(async (event?: DownloadBlobSubmitEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (isLoadingRef.current) {
      return;
    }
    setIsLoading(true);
    isLoadingRef.current = true;
    try {
      const [route, urlParameters, data, options] = args.current;
      await dowoadBlob(route, urlParameters, data, options);
    } finally {
      setIsLoading(false);
      isLoadingRef.current = false;
    }
  }, []);

  return {
    isLoading,
    onSubmit,
  };
};
