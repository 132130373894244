import {Stack} from '@octaved/ui';
import {ReactElement} from 'react';
import {Trans} from 'react-i18next';

export default function NodeScopeHelp(): ReactElement {
  return (
    <Stack spacing={'10px'}>
      <b>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-1.head'} />
      </b>
      <div>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-1.text'} />
      </div>
      <b>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-2.head'} />
      </b>
      <div>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-2.text'} />
      </div>
      <b>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-3.head'} />
      </b>
      <div>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-3.text'} />
      </div>
      <b>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-4.head'} />
      </b>
      <div>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-4.text'} />
      </div>
      <b>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-5.head'} />
      </b>
      <div>
        <Trans i18nKey={'nodeDetails:field.nodeScope.help-5.text'} />
      </div>
    </Stack>
  );
}
