import {getProjectControllingCriticalTimePrognosis} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {useSingleValueArray} from '@octaved/hooks/src/SingleValueArray';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {Uuid} from '@octaved/typescript/src/lib';
import {CriticalTimePrognosis} from '../../../EntityInterfaces/Projects/ProjectControlling/CriticalTimePrognosis';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_START,
  FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_SUCCESS,
} from '../../ActionTypes';
import {
  criticalTimePrognosisSelector,
  criticalTimePrognosisStatesSelector,
} from '../../Selectors/Projects/ProjectControlling/CriticalTimePrognosisSelectors';
import {FlowState} from '../../State';

const reducerMap = new Map();
export const criticalTimePrognosisReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
addMultiToReducerMap(
  stateReducerMap,
  [
    FLOW_CHANGE_PID_SUCCESS,
    'flow.NodeRestoredFromTrashEvent',
    'flow.ProjectCustomerChangedEvent', //TODO: not sure if needed
    'flow.TaskCreatedEvent',
    'flow.TaskPatchedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
    'flow.WorkPackagePatchedEvent',
  ],
  () => ({}),
);
export const criticalTimePrognosisStateReducer = ReduceFromMap(stateReducerMap);

export const [, useLoadCriticalTimePrognosis] = createUseEntityHook<FlowState, CriticalTimePrognosis>(
  FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_START,
  FLOW_LOAD_PROJECT_CONTROLLING_CRITICAL_TIME_PROGNOSIS_SUCCESS,
  getProjectControllingCriticalTimePrognosis,
  criticalTimePrognosisSelector,
  criticalTimePrognosisStatesSelector,
  reducerMap,
  stateReducerMap,
);

const empty: CriticalTimePrognosis = {
  effectiveMaxEffort: 0,
  id: '',
  openUnplannedTaskCount: 0,
  plannedTaskCount: 0,
  plannedTime: 0,
  remainingPlannedTime: 0,
  remainingTrackedTime: 0,
  taskCount: 0,
  trackedTime: 0,
};

export function useSingleCriticalTimePrognosis(workPackageId: Uuid): {
  isLoading: boolean;
  prognosis: CriticalTimePrognosis;
} {
  const result = useLoadCriticalTimePrognosis(useSingleValueArray(workPackageId), true);
  return {
    isLoading: result.isLoading,
    prognosis: result.entriesMapped.get(workPackageId) || empty,
  };
}
