import FullScreenDrawer from '@octaved/flow/src/Components/Drawer/FullScreenDrawer';
import {useLoading} from '@octaved/flow/src/Modules/Hooks/Loading';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, ReactNode} from 'react';
import {getPlanningBaseline} from '../../Modules/PlanningBaselines';
import {planningBaselineEntityStatesSelector} from '../../Selectors/PlanningBaselineSelectors';
import Canvas from './Canvas';
import Footer from './Footer';
import DrawerHeader from './Header/DrawerHeader';

interface BaselineComparisonDrawerProps {
  rootNodeId: Uuid;
  trigger?: ReactNode;
  close(): void;
  visible: boolean;
}

export default function BaselineComparisonDrawer({
  close,
  visible,
  trigger,
  rootNodeId,
}: BaselineComparisonDrawerProps): ReactElement {
  useLoading([rootNodeId], planningBaselineEntityStatesSelector, getPlanningBaseline);

  return (
    <FullScreenDrawer close={close} visible={visible} trigger={trigger}>
      <DrawerHeader rootNodeId={rootNodeId} />
      <div className={'relative flex-grow overflow-hidden'}>
        <Canvas nodeId={rootNodeId} />
      </div>
      <Footer />
    </FullScreenDrawer>
  );
}
