import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useAncestorBudgetSums} from '@octaved/flow/src/Modules/Projects/AncestorBudgetSums';
import {isGroup, isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {TWAutosaveInput, TWProgress} from '@octaved/ui';
import {formatDecimal, parse} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Checkbox, Radio} from 'semantic-ui-react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeTimeScopeProps {
  node: Project | Group;
  patch: (
    data: Partial<{
      maxEffort: number;
      showMaxEffortInDays: boolean;
      enforceMaxEffort: boolean;
    }>,
  ) => void;
  readonly?: boolean;
}

export default function NodeTimeScope({
  frame: Frame,
  node,
  patch,
  readonly,
}: NodeTimeScopeProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const trackedHours = node.trackedMinutes.billed / 60;
  const {countWorkPackages, countWorkPackagesWithLimit, sumTimeLimits} = useAncestorBudgetSums(node.id);

  if (!isProject(node) && !isGroup(node)) {
    return null;
  }

  return (
    <Frame
      labelToken={
        isProject(node) ? 'nodeDetails:field.timeLimit.label.project' : 'nodeDetails:field.timeLimit.label.subProject'
      }
    >
      <div className={'flex flex-col gap-y-4'}>
        <div className={'flex flex-col gap-y-2'}>
          <div className={'flex items-center gap-x-6'}>
            <TWAutosaveInput
              wrapperClassname={'w-numberInput'}
              type={'number'}
              value={node.maxEffort ? node.maxEffort * (node.showMaxEffortInDays ? 1 / 8 : 1) + '' : ''}
              onSave={(maxEffort) =>
                patch({maxEffort: maxEffort ? parse(maxEffort) * (node.showMaxEffortInDays ? 8 : 1) : undefined})
              }
              readOnly={readonly}
            />

            <Radio
              checked={node.showMaxEffortInDays}
              value={'days'}
              label={<label>{t('nodeDetails:field.timeLimit.inDays')}</label>}
              onChange={() => patch({showMaxEffortInDays: true, maxEffort: (node.maxEffort || 0) * 8})}
            />
            <Radio
              checked={!node.showMaxEffortInDays}
              value={'hours'}
              label={<label>{t('nodeDetails:field.timeLimit.inHours')}</label>}
              onChange={() => patch({showMaxEffortInDays: false, maxEffort: (node.maxEffort || 0) / 8})}
            />
          </div>
          {useTimeTracking && (
            <Checkbox
              label={t('nodeDetails:field.timeLimit.allowTimeTrackingUntilLimit')}
              checked={node.enforceMaxEffort}
              onChange={() => patch({enforceMaxEffort: !node.enforceMaxEffort})}
            />
          )}
        </div>

        {useTimeTracking && node.maxEffort !== undefined && node.maxEffort !== null && node.maxEffort > 0 && (
          <div className={'grid grid-cols-progressWithLabel items-center gap-x-3 gap-y-1'}>
            <TWProgress
              progress={(trackedHours * 100) / node.maxEffort}
              color={trackedHours > node.maxEffort ? 'red' : 'green'}
            />

            <div>
              <Trans
                i18nKey={'nodeDetails:field.timeLimit.alreadyTracked'}
                count={node.maxEffort / (node.showMaxEffortInDays ? 8 : 1)}
                values={{
                  count: node.maxEffort / (node.showMaxEffortInDays ? 8 : 1),
                  countFormatted: formatDecimal(node.maxEffort / (node.showMaxEffortInDays ? 8 : 1)),
                  n: formatDecimal(trackedHours / (node.showMaxEffortInDays ? 8 : 1)),
                }}
              />
            </div>
            <TWProgress
              className={'my-1.5 self-start'}
              progress={(sumTimeLimits * 100) / node.maxEffort}
              color={sumTimeLimits > node.maxEffort ? 'red' : sumTimeLimits < node.maxEffort ? 'orange' : 'green'}
            />

            <div className={'flex flex-col gap-y-1'}>
              <div>
                <Trans
                  i18nKey={'nodeDetails:field.timeLimit.alreadyPlanned'}
                  count={sumTimeLimits / (node.showMaxEffortInDays ? 8 : 1)}
                  values={{
                    count: node.maxEffort / (node.showMaxEffortInDays ? 8 : 1),
                    countFormatted: formatDecimal(node.maxEffort / (node.showMaxEffortInDays ? 8 : 1)),
                    n: formatDecimal(sumTimeLimits / (node.showMaxEffortInDays ? 8 : 1)),
                  }}
                />
              </div>
              <div className={'text-sm'}>
                {t('nodeDetails:field.timeLimit.workPackagesWithTimeLimit', {
                  total: countWorkPackages,
                  withLimit: countWorkPackagesWithLimit,
                })}
                <br />
                {t('nodeDetails:field.timeLimit.totalWorkPackageTimeLimit', {
                  count: sumTimeLimits,
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Frame>
  );
}
