import {Uuid} from '@octaved/typescript/src/lib';
import {createContext, ReactElement, ReactNode, useContext} from 'react';
import type {WorkPackage} from '../../../EntityInterfaces/Pid';
import type {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {useNodeAncestry} from '../../../Modules/Selectors/NodeTreeSelectors';
import {getReferenceNodeFromAncestry} from '../../../TimeTracking/ReferenceNode';

const context = createContext<Uuid | null>(null);

export function useTimeTrackingReferenceNodeFromCanvas(): WorkPackage | SubWorkPackage | null {
  const id = useContext(context);
  const ancestry = useNodeAncestry(id, true);
  return getReferenceNodeFromAncestry(ancestry);
}

interface Props {
  children: ReactNode;
  referenceNodeId: Uuid | null;
}

export default function TimeRecordingDrawerContextProvider({children, referenceNodeId}: Props): ReactElement {
  return <context.Provider value={referenceNodeId}>{children}</context.Provider>;
}
