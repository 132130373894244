import {error} from '@octaved/env/src/Logger';
import {createProjectFromTemplate} from '@octaved/flow-api';
import {useAsyncExecution} from '@octaved/hooks/src/AsyncExecution';
import {post} from '@octaved/network/src/Network';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {notNullOrUndefined, RulesList} from '@octaved/store/src/Validation';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {CopyPidOptions} from './CopyPid';
import {FlowState} from './State';
import {removeErrorForField, validateErrorRules} from './Ui';

interface CreateOptions {
  color: string;
  copyOptions: CopyPidOptions;
  customerId: Uuid;
  customerLocationId: Uuid | null;
  name: string;
  projectFolderId: Uuid;
}

interface CreateProjectFromTemplateParams {
  templateProjectId: Uuid;
}

interface CreateProjectFromTemplateSchema {
  color: string;
  copyAssignments: boolean;
  copyLogicalDependencies: boolean;
  copyPlanning: {
    alignment: 'start' | 'end';
    targetDate: DateStr | null;
  } | null;
  copyTasks: boolean;
  customerId: Uuid;
  customerLocationId: Uuid | null;
  name: string;
  projectFolderId: Uuid;
  timeControlFrom: DateStr | null;
}

function doCreate(sourceNodeId: Uuid, options: CreateOptions): ActionDispatcher<Promise<Uuid | false>, FlowState> {
  return async (dispatch) => {
    const rules: RulesList = [
      typeof options.copyOptions.copyPlanning !== 'undefined' && [
        notNullOrUndefined,
        options.copyOptions.copyPlanning.targetDate,
        'dialogs:duplicatePid.copyPlanning.error.noDate',
        `copy_pid_${sourceNodeId}_copyPlanning_date`,
      ],
    ];
    if (!validateErrorRules(rules, dispatch)) {
      return false;
    }

    const urlParams: CreateProjectFromTemplateParams = {
      templateProjectId: sourceNodeId,
    };

    const data: CreateProjectFromTemplateSchema = {
      color: options.color,
      copyAssignments: options.copyOptions.copyAssignments ?? false,
      copyLogicalDependencies: true,
      copyPlanning: options.copyOptions.copyPlanning ?? null,
      copyTasks: options.copyOptions.copyTasks ?? false,
      customerId: options.customerId,
      customerLocationId: options.customerLocationId,
      name: options.name,
      projectFolderId: options.projectFolderId,
      timeControlFrom: options.copyOptions.timeControlFromOverrides?.[sourceNodeId] ?? null,
    };

    try {
      const response = await post<{id: Uuid}>(createProjectFromTemplate, {data, urlParams});
      return response.id;
    } catch (e) {
      error(e);
      return false;
    }
  };
}

export function useCreateProjectFromTemplate(templateProjectId: Uuid): {
  create: (options: CreateOptions) => Promise<undefined | false | Uuid>;
  errorFields: string[];
  isCreating: boolean;
} {
  const dispatch = useDispatch();
  const errorFields = useMemo(() => [`copy_pid_${templateProjectId}_copyPlanning_date`], [templateProjectId]);

  useEffect(() => {
    return () => {
      dispatch(removeErrorForField(errorFields));
    };
  }, [dispatch, errorFields]);

  const [create, isCreating] = useAsyncExecution((options: CreateOptions) =>
    dispatch(doCreate(templateProjectId, options)),
  );

  return {isCreating, create, errorFields};
}
