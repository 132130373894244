import {getStatusChangeHistoryRoute} from '@octaved/flow-api';
import {CALL_API, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {INVALIDATED, LOADED, LOADING, createTimestampReducer, filterIdsToReload} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {chunk} from 'lodash';
import {TaskStatusHistories} from '../EntityInterfaces/TaskStatusHistory';
import {
  FLOW_GET_TASK_STATUS_HISTORY_FAILURE,
  FLOW_GET_TASK_STATUS_HISTORY_REQUEST,
  FLOW_GET_TASK_STATUS_HISTORY_START,
  FLOW_GET_TASK_STATUS_HISTORY_SUCCESS,
} from './ActionTypes';
import {taskStatusHistoryStatesSelector} from './Selectors/TaskStatusHistorySelectors';
import {FlowState} from './State';

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_GET_TASK_STATUS_HISTORY_START, createTimestampReducer('nodeIds', LOADING));
stateReducerMap.set(FLOW_GET_TASK_STATUS_HISTORY_SUCCESS, createTimestampReducer('options.urlParams.ids', LOADED));
stateReducerMap.set('flow.TaskPatchedEvent', createTimestampReducer('nodeId', INVALIDATED));
export const taskStatusHistoryStateReducer = ReduceFromMap(stateReducerMap);

//#endregion

//#region reducer
const taskStatusHistoryReducers = createReducerCollection<TaskStatusHistories>({});
taskStatusHistoryReducers.add(
  FLOW_GET_TASK_STATUS_HISTORY_SUCCESS,
  (state, {response}: ServerResponseAction<TaskStatusHistories>) => {
    return {...state, ...response};
  },
);
export const taskStatusHistoryReducer = taskStatusHistoryReducers.reducer;
//#endregion

export function getTaskStatusChangeHistory(ids: ReadonlyArray<Uuid>): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const pdToLoad = filterIdsToReload(taskStatusHistoryStatesSelector(getState()), ids);
    const toLoad = Array.from(pdToLoad);

    if (toLoad.length === 0) {
      return;
    }

    //Must set the state to "loading" before the chunking starts, otherwise multiple loads may happen for one id:
    await dispatch({nodeIds: toLoad, type: FLOW_GET_TASK_STATUS_HISTORY_START});

    await Promise.all(
      chunk(toLoad, 100).map((ids) =>
        dispatch({
          [CALL_API]: {
            endpoint: getStatusChangeHistoryRoute,
            method: 'get',
            options: {
              urlParams: {
                ids,
              },
            },
            types: {
              failureType: FLOW_GET_TASK_STATUS_HISTORY_FAILURE,
              requestType: FLOW_GET_TASK_STATUS_HISTORY_REQUEST,
              successType: FLOW_GET_TASK_STATUS_HISTORY_SUCCESS,
            },
          },
        }),
      ),
    );
  };
}
