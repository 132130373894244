import {isInternalUserType} from '@octaved/users/src/UserType';
import {Inbox} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import FlowOutlet from '../../Components/FlowOutlet';
import {MenuRootItem} from '../FlowRoutes';
import {planningRoute} from './Inspectors/InspectorRoutes';
import {getStandardInspectorRoutes} from './Inspectors/StandardInspector';
import {myTasksRoutes} from './MyTasks';

const Boards = lazy(() => import('../../Pages/Boards/Boards'));
const PersonalTasks = lazy(() => import('../../Pages/PersonalTasks/PersonalTasks'));
const TrackTime = lazy(() => import('../../Pages/TrackTime/TrackTime'));

export const myTasksRoute = 'myTasks';
const personalTasksRoute = 'personalTasks';
const workspaceBoardsRoute = 'workspaceBoards';
export const trackTimeRoute = 'trackTime';

export const workspaceRoutes: RouteObject[] = [
  {
    children: myTasksRoutes,
    element: <FlowOutlet />,
    path: `${myTasksRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({defaultRoute: planningRoute}),
    element: <Boards />,
    path: `${workspaceBoardsRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({}),
    element: <PersonalTasks />,
    path: `${personalTasksRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({}),
    element: <TrackTime />,
    path: `${trackTimeRoute}/*`,
  },
];

export const workspaceMenu: MenuRootItem = {
  access: ({orgUserType}) => isInternalUserType(orgUserType),
  groupToken: 'pageMenu:workspaceGroup',
  icon: Inbox,
  id: 'workspace',
  items: [
    {
      nameToken: 'pageMenu:myTasks',
      path: myTasksRoute,
      testId: 'page-menu-workspace-my-tasks',
    },
    {
      nameToken: 'pageMenu:boards',
      path: workspaceBoardsRoute,
    },
    {
      nameToken: 'pageMenu:personalTasks',
      path: personalTasksRoute,
    },
    {
      access: ({settings: {useTimeTracking}}) => useTimeTracking,
      nameToken: 'pageMenu:trackTime',
      path: trackTimeRoute,
    },
  ],
  testId: 'page-menu-workspace',
};

export function getMyTasksRoute(): string {
  return myTasksRoute;
}

export function getTrackTimeRoute(): string {
  return trackTimeRoute;
}

export function getWorkspaceBoardRoute(): string {
  return workspaceBoardsRoute;
}
