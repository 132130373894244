import Konva from 'konva';
import {Image as IImage, ImageConfig} from 'konva/lib/shapes/Image';
import {memoize} from 'lodash';
import {createElement} from 'lucide';

type SVGProps = Record<string, string | number>;
type IconNodeChild = readonly [tag: string, attrs: SVGProps];
export type IconNode = readonly [tag: string, attrs: SVGProps, children?: IconNodeChild[]];

export interface ImageOptions {
  style?: Partial<CSSStyleDeclaration>;
  width: number;
  height: number;
  imageConfig?: Omit<ImageConfig, 'image' | 'width' | 'height'>;
}

const svgToCanvas = (svg: SVGElement, {style}: ImageOptions): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    if (style) {
      Object.assign(svg.style, style);
    }
    svg.style.strokeWidth = '1.5px';
    const iconString = new XMLSerializer().serializeToString(svg);
    img.src = `data:image/svg+xml;base64,${btoa(iconString)}`;
  });
};

const createSvgFromString = memoize((svgString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  const svg = doc.documentElement;
  if (!(svg instanceof SVGElement)) {
    throw new Error('Invalid svg string');
  }
  return memoize(
    (options: ImageOptions) => {
      return svgToCanvas(svg, options);
    },
    (...args) => JSON.stringify(args),
  );
});

const createSvgImage = memoize((icon: IconNode) => {
  const svg = createElement(icon);
  return memoize(
    (options: ImageOptions) => {
      return svgToCanvas(svg, options);
    },
    (...args) => JSON.stringify(args),
  );
});

/**
 * @note use the iconToImage from Disposable.ts instead
 */
export async function iconToImage(icon: IconNode, options: ImageOptions): Promise<IImage> {
  const i = await createSvgImage(icon)(options);
  return new Konva.Image({
    height: options.height,
    //image: i.cloneNode(true) as HTMLCanvasElement,
    image: i,
    width: options.width,
    ...options.imageConfig,
  });
}

export async function svgStringToImage(svgString: string, options: ImageOptions): Promise<IImage> {
  const i = await createSvgFromString(svgString)(options);
  return new Konva.Image({
    height: options.height,
    image: i,
    width: options.width,
    ...options.imageConfig,
  });
}
