import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {error} from '@octaved/env/src/Logger';
import {forEachQueryIntersect, SearchResult} from '@octaved/hooks/src/CombinedSearch';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {nodeIdInTree, toChildren, withAncestors, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {
  getAllAncestorsForNodeIds,
  getAllDescendantsForRootId,
  getAllDescendantsForRootIds,
} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {splitSearchTerm} from '@octaved/utilities/src/Search/SearchTerm';
import {isPidNumber, isUuid} from '@octaved/validation';
import {intersection} from 'lodash';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useProjectTreeTaskListContext} from '../../Components/ProjectTree/ProjectTreeTaskListContext';
import {FilterState} from '../../EntityInterfaces/Filter/FilterState';
import {
  ProjectFilterQueries,
  ProjectFilterQueryGenerator,
  ProjectFilterStates,
} from '../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition, NodeSearchTuple} from '../../EntityInterfaces/NodeSearch';
import {NodeTree} from '../../EntityInterfaces/NodeTree';
import {useCombinedNodeSearches, useNodeSearch} from '../Hooks/NodeSearch';
import {settingsSelector} from '../Selectors/SettingSelectors';
import {Settings} from '../Settings';
import {projectHasNoWorkPackages} from './Filters/Generic';
import {createProjectFilterQueries} from './Filters/ProjectFilterQueries';
import {ProjectFilterRegistry, projectFilterRegistrySelector} from './Filters/ProjectFilterRegistry';
import {ProjectTreeOptions} from './ProjectTreeInterfaces';

const queryProjects: NodeSearchTuple = ['nodeType', EnumFlowNodeType.VALUE_PROJECT];
const queryGroups: NodeSearchTuple = ['nodeType', EnumFlowNodeType.VALUE_GROUP];
const queryWorkPackages: NodeSearchTuple = ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE];
const queryPids: NodeSearchCondition = {or: [queryProjects, queryGroups, queryWorkPackages]};

function useProjectFolderQuery({
  projectFolder,
  includeSubProjectFolders,
  showOnlyFavoriteProjects,
}: ProjectTreeOptions): NodeSearchCondition {
  const currentUserId = useSelector(currentOrgUserIdSelector);
  return useMemo((): NodeSearchCondition => {
    const conditions: NodeSearchCondition[] = [queryProjects];
    if (projectFolder) {
      if (includeSubProjectFolders) {
        conditions.push(withDescendants(projectFolder, true));
      } else {
        conditions.push(toChildren(projectFolder));
      }
    }
    if (showOnlyFavoriteProjects) {
      conditions.push(['favorite', currentUserId]);
    }
    return {and: conditions};
  }, [currentUserId, includeSubProjectFolders, projectFolder, showOnlyFavoriteProjects]);
}

function appendFilterStates(
  projectFilterRegistry: ProjectFilterRegistry,
  queries: ProjectFilterQueries,
  filterStates: Partial<ProjectFilterStates>,
  settings: Settings,
): void {
  Object.entries(filterStates).forEach(([key, _filter]) => {
    const generator = projectFilterRegistry[key as keyof ProjectFilterStates] as ProjectFilterQueryGenerator<unknown>;
    const filter = _filter as FilterState<unknown>;
    if (generator && filter.isActive) {
      generator(queries, filter.value, settings, filterStates);
    }
    if (!generator) {
      error(`Unconfigured filter key '${key}' found in projects`);
    }
  });
}

function wrapDescendantsWithEmptyProjects(queries: ProjectFilterQueries): void {
  if (queries.descendants.length) {
    //Wrap the descendants-contitions, so that empty projects are still found, despite them not matching anything.
    // This means as long as the filter is off, we show empty projects always:
    queries.descendants = [
      {
        or: [projectHasNoWorkPackages, {and: queries.descendants.map((c) => withAncestors(c, true))}],
      },
    ];
  }
}

function useFilterQueries({extraQueries, filterStates, includeCustomerId}: ProjectTreeOptions): ProjectFilterQueries {
  const projectFilterRegistry = useSelector(projectFilterRegistrySelector);
  const settings = useSelector(settingsSelector);
  return useMemo(() => {
    const queries = createProjectFilterQueries();

    //Add extra queries supplied through the options:
    (extraQueries?.descendants || []).forEach((q) => queries.descendants.push(q));
    (extraQueries?.indicatingDirectMatchForParents || []).forEach((q) =>
      queries.indicatingDirectMatchForParents.push(q),
    );
    (extraQueries?.projects || []).forEach((q) => queries.projects.push(q));
    (extraQueries?.subprojects || []).forEach((q) => queries.subprojects.push(q));
    (extraQueries?.workPackages || []).forEach((q) => queries.workPackages.push(q));

    //Add the queries from the filter states:
    appendFilterStates(projectFilterRegistry, queries, filterStates || {}, settings);

    //Apply the filter to show empty projects despite other filters above:
    wrapDescendantsWithEmptyProjects(queries);

    //Add customer filter:
    if (includeCustomerId) {
      queries.projects.push(['customerId', includeCustomerId]);
    }

    return queries;
  }, [
    extraQueries?.descendants,
    extraQueries?.indicatingDirectMatchForParents,
    extraQueries?.projects,
    extraQueries?.subprojects,
    extraQueries?.workPackages,
    projectFilterRegistry,
    filterStates,
    settings,
    includeCustomerId,
  ]);
}

interface ProjectSearchQueries {
  concealedProperties: NodeSearchCondition[];
  descendants: NodeSearchCondition[];
  phraseCount: number;
  projects: NodeSearchCondition[];
}

function useSearchQueries({searchTerm}: ProjectTreeOptions): ProjectSearchQueries {
  return useMemo(() => {
    const queries: ProjectSearchQueries = {
      concealedProperties: [],
      descendants: [],
      phraseCount: 0,
      projects: [],
    };
    if (searchTerm) {
      const phrases = splitSearchTerm(searchTerm);
      queries.phraseCount = phrases.length;
      phrases.forEach((phrase) => {
        const isUuidPhrase = isUuid(phrase);
        const nodeIdSearch: NodeSearchCondition[] = isUuidPhrase ? [nodeIdInTree(phrase)] : [];
        const pidSearch: NodeSearchCondition[] = !isUuidPhrase && isPidNumber(phrase) ? [['pidPid', phrase]] : [];

        //The subsequent logic requires every query to have one entry per phrase, so we must add empty "or"-conditions

        queries.concealedProperties.push({
          or: [...nodeIdSearch, ...pidSearch],
        });
        queries.descendants.push(isUuidPhrase ? {or: []} : ['name', phrase]);
        queries.projects.push(
          isUuidPhrase
            ? {or: []}
            : {
                or: [
                  ['customerName', phrase],
                  ['customerNumber', `%${phrase}%`],
                ],
              },
        );
      });
    }
    return queries;
  }, [searchTerm]);
}

function composeProjectIds(
  nodeTree: NodeTree,
  queries: ProjectFilterQueries,
  baseProjectIds: ReadonlyArray<Uuid>,
  searchResults: SearchResult<Uuid>[],
): ReadonlyArray<Uuid> {
  let projIds = baseProjectIds;

  //Reduce the result by intersecting with every query result that yields descendants of projects to find:
  projIds = forEachQueryIntersect(searchResults, projIds, queries.descendants.length, (ids) => [
    ...getAllAncestorsForNodeIds(nodeTree, ids, true),
  ]);

  //Reduce the result by intersecting with every query result that yields project ids directly:
  projIds = forEachQueryIntersect(searchResults, projIds, queries.projects.length);

  return projIds;
}

function intersectSets(set: ReadonlySet<Uuid>, filter: ReadonlySet<Uuid>): Set<Uuid> {
  return new Set([...set].filter((id) => filter.has(id)));
}

interface FilteredProjects {
  directMatchingParentIds: ReadonlySet<Uuid>;
  groupIds: ReadonlySet<Uuid>;
  nodeIds: ReadonlySet<Uuid>; //all node ids (projects + groups + wps - wps that do not match their filter)
  projectIds: ReadonlySet<Uuid>; //the project ids to display
  searchedNodeIds: ReadonlySet<Uuid> | null;
  searchedNodeIdsForConcealedProperties: ReadonlySet<Uuid> | null;
  searchedNodeIdsTrace: ReadonlySet<Uuid> | null; //ancestors of search matches, used for opening nodes
  workPackageIds: ReadonlySet<Uuid>;
}

const emptyResult: FilteredProjects = {
  directMatchingParentIds: new Set<Uuid>(),
  groupIds: new Set<Uuid>(),
  nodeIds: new Set<Uuid>(),
  projectIds: new Set<Uuid>(),
  searchedNodeIds: null,
  searchedNodeIdsForConcealedProperties: null,
  searchedNodeIdsTrace: null,
  workPackageIds: new Set<Uuid>(),
};

export function useProjectTreeFilters(options: ProjectTreeOptions): FilteredProjects & {isLoading: boolean} {
  const queryProjectFolder = useProjectFolderQuery(options);
  const queries = useFilterQueries(options);
  const searchQueries = useSearchQueries(options);
  const isGranted = useSelector(isGrantedSelector);
  const {excludedNodeIds, includedNodeIds, nodeTree, projectSubtreeGrant} = options;
  const {useTasksView} = useProjectTreeTaskListContext();

  //The order of queries here is important! It is handled by slicing the results accordingly!
  const results = useCombinedNodeSearches(
    {
      //Invalidate at least when the number of variable queries changes to not get cached results for a different
      // query entirely:
      invalidateCacheOn: [
        queries.descendants.length,
        queries.indicatingDirectMatchForParents.length,
        queries.projects.length,
        queries.subprojects.length,
        queries.workPackages.length,
        searchQueries.concealedProperties.length,
        searchQueries.descendants.length,
        searchQueries.projects.length,
      ].join('+'),
      trackIsLoading: true,
    },
    queryProjectFolder,
    queryGroups,
    queryWorkPackages,
    queryPids,
    ...queries.indicatingDirectMatchForParents,
    ...queries.descendants,
    ...queries.projects,
    ...queries.subprojects,
    ...queries.workPackages,
    ...searchQueries.concealedProperties,
    ...searchQueries.descendants,
    ...searchQueries.projects,
  );
  const {nodeIds: allTaskIds} = useNodeSearch('nodeType', EnumFlowNodeType.VALUE_TASK, useTasksView);

  const isLoading = results.reduce((acc, {isLoading}) => acc || isLoading, false);

  const includedIds = useMemo<ReadonlySet<Uuid>>(() => {
    const included = includedNodeIds
      ? getAllAncestorsForNodeIds(nodeTree, [...includedNodeIds], true)
      : new Set(Object.keys(nodeTree));
    if (excludedNodeIds) {
      excludedNodeIds.forEach((id) => included.delete(id));
    }
    return included;
  }, [excludedNodeIds, includedNodeIds, nodeTree]);

  const composed = useMemo<FilteredProjects>(() => {
    if (isLoading) {
      return emptyResult;
    }

    const resultsSliced = results.slice(0); //copy the results array because we modify it
    const folderProjectIds = resultsSliced.shift()!.ids; //all project ids within the folder
    const allGroupIds = resultsSliced.shift()!.ids;
    const allGroupIdsSet = new Set(allGroupIds);
    const allWorkPackageIds = resultsSliced.shift()!.ids;
    const allWorkPackageIdsSet = new Set(allWorkPackageIds);
    const allPidIdIds = resultsSliced.shift()!.ids;

    const directMatchingParentIds = new Set(
      resultsSliced
        .splice(0, queries.indicatingDirectMatchForParents.length)
        .reduce<Uuid[]>((acc, {ids}) => acc.concat(ids), []),
    );

    //Reduce the baseProjectIds by all filter results:
    let filteredProjectIds = composeProjectIds(nodeTree, queries, folderProjectIds, resultsSliced);

    //Extract group filters (they do not reduce the project ids, but are used to show/hide groups within the tree):
    const filteredGroupIds = new Set(
      forEachQueryIntersect<Uuid>(resultsSliced, allGroupIds, queries.subprojects.length),
    );

    //Extract work package filters (they do not reduce the project ids, but are used to show/hide work packages
    // within the tree):
    const filteredWpIds = new Set(
      forEachQueryIntersect<Uuid>(resultsSliced, allWorkPackageIds, queries.workPackages.length),
    );

    let searchedNodeIds: Set<Uuid> | undefined;
    let searchedDescendantIds: Set<Uuid> | undefined;
    let searchedNodeIdsForConcealedProperties: Set<Uuid> | undefined;
    let searchedNodeIdsTrace: Set<Uuid> | undefined;
    if (searchQueries.phraseCount) {
      //Apply the text search, phrase by phrase, keeping track of all the searched ids and their traces:
      const searchResultsConcealedProperties = resultsSliced.splice(0, searchQueries.phraseCount);
      const searchResultsDescendants = resultsSliced.splice(0, searchQueries.phraseCount);
      const searchResultsProjects = resultsSliced.splice(0, searchQueries.phraseCount);

      let searchMatchingDescendantIds = allPidIdIds;
      let searchDirectMatchingIds: Uuid[] = [];
      let searchConcealedPropMatchingIds: Uuid[] = [];
      for (let i = 0; i < searchQueries.phraseCount; i++) {
        //For each phrase, combine the results. This is to find work packages,
        searchDirectMatchingIds.push(
          ...searchResultsConcealedProperties[i].ids,
          ...searchResultsDescendants[i].ids,
          ...intersection(searchResultsProjects[i].ids, folderProjectIds), //for direct matching on e.g. customer name
        );
        searchConcealedPropMatchingIds.push(...searchResultsConcealedProperties[i].ids);
        searchMatchingDescendantIds = intersection(searchMatchingDescendantIds, [
          ...getAllDescendantsForRootIds(
            nodeTree,
            [
              ...searchResultsConcealedProperties[i].ids,
              ...searchResultsDescendants[i].ids,
              ...searchResultsProjects[i].ids,
            ],
            true,
          ),
        ]);
      }

      //Only keep those direct matches, that are still in the intersected descendants array:
      searchDirectMatchingIds = intersection(searchDirectMatchingIds, searchMatchingDescendantIds);
      searchConcealedPropMatchingIds = intersection(searchConcealedPropMatchingIds, searchMatchingDescendantIds);

      searchedNodeIds = new Set(searchDirectMatchingIds);
      searchedDescendantIds = new Set(searchMatchingDescendantIds);
      searchedNodeIdsForConcealedProperties = new Set(searchConcealedPropMatchingIds);

      //Now intersect the search trace with the project ids to limit them to found descendants:
      searchedNodeIdsTrace = getAllAncestorsForNodeIds(nodeTree, searchDirectMatchingIds);
      filteredProjectIds = intersection(filteredProjectIds, [...searchDirectMatchingIds, ...searchedNodeIdsTrace]);

      searchedNodeIds.forEach((id) => directMatchingParentIds.add(id));
    }

    //Screen out projects we are not allowed to see:
    filteredProjectIds = filteredProjectIds.filter(
      (id) => includedIds.has(id) && isGranted('FLOW_NODE_PROJECT_READ_BASIC', id),
    );
    if (projectSubtreeGrant) {
      filteredProjectIds = filteredProjectIds.filter((id) =>
        isGranted(projectSubtreeGrant.right, id, projectSubtreeGrant.any ? 'anyInTree+self' : 'allInTree+self'),
      );
    }

    const nodeIds = new Set<Uuid>(filteredProjectIds);
    const groupIds = new Set<Uuid>();
    const workPackageIds = new Set<Uuid>();

    filteredProjectIds.forEach((projectId) => {
      getAllDescendantsForRootId(nodeTree, projectId).forEach((id) => {
        //Only leave the entry if it matches itself or is on the match trace of something else:
        const keepDueToSearch =
          !searchedNodeIds ||
          searchedNodeIds.has(id) ||
          !searchedNodeIdsTrace ||
          searchedNodeIdsTrace.has(id) ||
          !searchedDescendantIds ||
          searchedDescendantIds.has(id);

        if (
          allGroupIdsSet.has(id) && //is a group
          includedIds.has(id) &&
          isGranted('FLOW_NODE_GROUP_READ_BASIC', id) && //is allowed to read
          filteredGroupIds.has(id) &&
          keepDueToSearch
        ) {
          nodeIds.add(id);
          groupIds.add(id);
        } else if (
          allWorkPackageIdsSet.has(id) && //is work package
          includedIds.has(id) &&
          isGranted('FLOW_NODE_WORK_PACKAGE_READ_BASIC', id) && // is allowed to read
          filteredWpIds.has(id) &&
          keepDueToSearch
        ) {
          nodeIds.add(id);
          workPackageIds.add(id);
        }
      });
    });

    if (useTasksView) {
      const allTaskIdsSet = new Set(allTaskIds);
      getAllDescendantsForRootIds(nodeTree, [...workPackageIds]).forEach((id) => {
        if (allTaskIdsSet.has(id)) {
          nodeIds.add(id);
        }
      });
    }

    //Clean up the searched ids sets to only include actual ids in the tree based on all the filtering:
    if (searchedNodeIds && searchedNodeIdsForConcealedProperties && searchedNodeIdsTrace) {
      searchedNodeIds = intersectSets(searchedNodeIds, nodeIds);
      searchedNodeIdsForConcealedProperties = intersectSets(searchedNodeIdsForConcealedProperties, nodeIds);
      searchedNodeIdsTrace = intersectSets(searchedNodeIdsTrace, nodeIds);
    }

    return {
      directMatchingParentIds,
      groupIds,
      nodeIds,
      workPackageIds,
      projectIds: new Set<Uuid>(filteredProjectIds),
      searchedNodeIds: searchedNodeIds || null, //direct search matches
      searchedNodeIdsForConcealedProperties: searchedNodeIdsForConcealedProperties || null,
      searchedNodeIdsTrace: searchedNodeIdsTrace || null, //ancestors of search matches, exclusive themselves
    };
  }, [
    allTaskIds,
    includedIds,
    isGranted,
    isLoading,
    useTasksView,
    nodeTree,
    projectSubtreeGrant,
    queries,
    results,
    searchQueries.phraseCount,
  ]);

  return {
    ...useLoadedValue(isLoading, composed),
    isLoading,
  };
}
