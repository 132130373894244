import {useCallback, useEffect} from 'react';

export function useKeydownListener(keys: string[], cb: (key: string) => void): void {
  const onKeyDown = useCallback(
    ({key}: KeyboardEvent) => {
      if (keys.includes(key)) {
        cb(key);
      }
    },
    [cb, keys],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });
}

export function useKeyboardListener(onKeyDown: ((e: KeyboardEvent) => void) | null): void {
  useEffect(() => {
    if (onKeyDown) {
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }
    return () => undefined;
  }, [onKeyDown]);
}
