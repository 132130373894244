import NetworkMiddleware from '@octaved/network/src/NetworkMiddleware';
import planningMiddleware from '@octaved/planning/src/Calculations/PlanningMiddleware';
import CreateStore from '@octaved/store/src/CreateStore';
import {createStorageMiddleware, setStateDefinitions} from '@octaved/store/src/StorageMiddleware';
import {setStore} from '@octaved/store/src/Store';
import {thunk} from 'redux-thunk';
import {getStorageMiddlewareStates, getUserSettingsMiddlewarePaths} from '../Store/StoreSync';
import UserSettingsMiddleware, {registerUserSettingsMiddlwareConfigs} from '../Store/UserSettingsMiddleware';
import {addEntityReducers, reducer, removeEntityReducers} from './RootReducer';
import {FlowState} from './State';

const inititialState = reducer({} as FlowState, {type: 'init'});

registerUserSettingsMiddlwareConfigs(getUserSettingsMiddlewarePaths(inititialState));

const store = CreateStore(
  [thunk, NetworkMiddleware, planningMiddleware, createStorageMiddleware(), UserSettingsMiddleware],
  reducer,
  addEntityReducers,
  removeEntityReducers,
  inititialState,
  'Flow',
);

setStateDefinitions(getStorageMiddlewareStates(inititialState));

setStore(store);

export default store;
