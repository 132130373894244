import {Text, TextConfig} from 'konva/lib/shapes/Text';
import {createText} from '../../../CommonComponents/Text';
import {NodeCell} from './NodeCell';

export abstract class SimpleTextCell extends NodeCell {
  #textNode: Text | null = null;
  protected textConfig: TextConfig = {};

  init(): void {
    super.init();
    this.#textNode = createText({
      ellipsis: true,
      offsetY: this.textLineOffset,
      ...this.textConfig,
    });
    this.root.add(this.#textNode);
  }

  protected abstract getText(): string;

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;
    this.root.visible(isVisble);
    if (!isVisble) {
      return;
    }
    if (!this.#textNode) {
      throw new Error('Text node is not initialized');
    }

    this.#textNode.width(width - this.padding * 2);
    this.#textNode.text(this.getText());
    this.#textNode.fill(this.getFontColor());
    this.#textNode.fontVariant(this.getFontVariant());
    this.#textNode.x(x);
  }
}
