import {Customer} from '@octaved/flow/src/EntityInterfaces/Customers';
import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {getFlowCustomerSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {createStoreSubscription} from '../../../StoreSubscription';
import {SimpleTextCell} from './SimpleTextCell';

export class NodeEntityCustomerCell extends SimpleTextCell {
  protected nodeEntity: NodeEntity | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getNodeSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => {
          if (!isProject(this.nodeEntity)) {
            return null;
          }
          return getFlowCustomerSelector(store)(this.nodeEntity?.flowCustomer);
        },
        () => {
          this.render();
        },
      ),
    );
  }

  protected getText(): string {
    if (isProject(this.nodeEntity)) {
      const customer = getFlowCustomerSelector(this.ctx.store.getState())(this.nodeEntity?.flowCustomer);
      return customer?.name || '';
    }
    return '';
  }
}

export class CustomerEntityCustomerCell extends SimpleTextCell {
  protected nodeEntity: Customer | null = null;

  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => getFlowCustomerSelector(store)(this.node.id),
        (nodeEntity) => {
          this.nodeEntity = nodeEntity || null;
          this.render();
        },
      ),
    );
  }

  protected getText(): string {
    return this.nodeEntity?.name || '';
  }
}
