import {cva} from 'class-variance-authority';

export const tableGrid = cva('grid items-center gap-y-2 max-w-full', {
  defaultVariants: {
    isBelowLabel: false,
  },
  variants: {
    isBelowLabel: {
      false: '',
      true: 'pt-0.5',
    },
  },
});

export const tableGridCellVariant = cva('', {
  defaultVariants: {
    isFooter: false,
    isHeader: false,
    position: 'center',
  },
  variants: {
    isFooter: {
      false: '',
      true: 'pt-1',
    },
    isHeader: {
      false: '',
      true: 'border-b border-b-slate-200 text-sm font-semibold text-slate-600',
    },
    position: {
      center: 'px-2',
      left: 'pl-0 pr-2',
      right: 'pl-2 pr-0',
    },
  },
});
