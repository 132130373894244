import ConfirmDialog from '@octaved/flow/src/Components/Dialog/ConfirmDialog';
import DropdownPopup from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup';
import {
  DropdownPopupItemOptions,
  DropdownPopupItemType,
} from '@octaved/flow/src/Components/Form/Dropdown/DropdownPopup/Types';
import {Milestone} from '@octaved/planning/src/EntityInterfaces/Milestones';
import {removeMilestone} from '@octaved/planning/src/Modules/Milestones';
import {Uuid} from '@octaved/typescript/src/lib';
import {IconButton} from '@octaved/ui';
import {MoreHorizontal, Trash} from 'lucide-react';
import {ReactElement, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

interface MilestoneRowMenuProps {
  milestone: Milestone;
  nodeId: Uuid;
}

export default function MilestoneMenu({nodeId, milestone}: MilestoneRowMenuProps): ReactElement {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const items = useMemo(() => {
    const items: DropdownPopupItemOptions[] = [
      {
        icon: <Trash />,
        iconColor: 'red',
        onClick: () => {
          setShowDeleteConfirm(true);
        },
        textColor: 'red',
        token: 'nodeDetails:field.milestones.deleteMilestone',
        type: DropdownPopupItemType.item,
      },
    ];

    return items;
  }, []);

  return (
    <>
      <DropdownPopup items={items} position={'bottom left'} closeOnSelect inDrawer>
        <IconButton variant={'ghost'} icon={<MoreHorizontal />} className={'onHover'} size={'xs'} />
      </DropdownPopup>
      {showDeleteConfirm && (
        <ConfirmDialog
          title={'nodeDetails:field.milestones.deleteMilestone'}
          buttonColorScheme={'delete'}
          buttonLabel={'general:delete'}
          buttonIcon={<Trash />}
          size={'mini'}
          cancelCallback={() => setShowDeleteConfirm(false)}
          submitCallback={() => {
            dispatch(removeMilestone(nodeId, milestone.id));
            setShowDeleteConfirm(false);
          }}
        >
          {t('nodeDetails:field.milestones.deleteMilestoneConfirm')}
        </ConfirmDialog>
      )}
    </>
  );
}
