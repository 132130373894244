import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import StandardInspectorSubMenu from '../../../Drawer/StandardInspector/StandardInspectorSubMenu';
import {InspectorTabItem, TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import {projectControllingDemoModeSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {setProjectControllingDemoMode} from '../../../Modules/UiPages/Projects';
import ProjectControllingInspectorSubMenu from '../../../Pages/Projects/Pages/ControlCommon/ProjectControllingInspectorSubMenu';
import {dialogRouteObjects} from '../Dialogs';
import {quotasRoute, subInspectorRoute} from './InspectorRoutes';
import {quotasRouteObject, subInspectorWrapper} from './Inspectors';
import ProjectControllingDemoModeWrapper from './ProjectControllingDemoModeWrapper';
import {getStandardInspectorRoutes, getWrappedStandardInspectorRoutes} from './StandardInspector';

const statusRoute = 'status';
const Status = lazy(() => import('../../../Pages/Projects/Pages/ControlDegreeOfCompletion/Inspector/Status'));
const ControlDegreeOfCompletionStatusTab = lazy(() =>
  import('@octaved/demo-mode').then((module) => ({default: module.ControlDegreeOfCompletionStatusTab})),
);

//#region <inspector tabs>
const workPackageTabs: InspectorTabItem[] = [
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    path: quotasRoute,
    token: 'pages:projects.inspector.menu.quotas',
  },
];
const projectAndGroupTabs: InspectorTabItem[] = [
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    path: quotasRoute,
    token: 'pages:projects.inspector.menu.quotas',
  },
];

const inspectorTabConfig: TabRecords = {
  [EnumFlowNodeType.VALUE_PROJECT]: projectAndGroupTabs,
  [EnumFlowNodeType.VALUE_GROUP]: projectAndGroupTabs,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: workPackageTabs,
};
//#endregion

const statusRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<ControlDegreeOfCompletionStatusTab />}>
      <Status />
    </ProjectControllingDemoModeWrapper>
  ),
  path: statusRoute,
});

export const controlDegreeOfCompletionInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: subInspectorWrapper(
    [
      quotasRouteObject(),
      statusRouteObject(),
      {
        element: <Navigate to={statusRoute} replace />,
        path: '*',
      },
    ],
    getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu, useOwnProjectsPid: false}),
    subInspectorRoute,
  ),
  standardInspectorProps: {
    actionMenu: ProjectControllingInspectorSubMenu,
    demoModeSelector: projectControllingDemoModeSelector,
    demoModeToggle: setProjectControllingDemoMode,
    tabConfig: inspectorTabConfig,
  },
});
