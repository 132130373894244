import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import SinglePercentagePie from './SinglePercentagePie';

interface Props {
  fillColor?: string;
  n: number | undefined;
  m: number | undefined;
  label: string;
  showPercentage?: boolean;
  showNOfM?: boolean;
}

export default function NOfMPie({fillColor, n, m, label, showPercentage, showNOfM}: Props): ReactElement {
  const {t} = useTranslation();
  let percentage: number | undefined = undefined;
  if (typeof n !== 'undefined' && typeof m !== 'undefined') {
    percentage = m ? Math.round((n / m) * 100) : 0;
  }
  return (
    <SinglePercentagePie percentage={percentage} size={'sm'} fillColor={fillColor}>
      <div>
        {showPercentage && (percentage ?? <>&nbsp;</>) + '% '}
        <span>
          <Trans i18nKey={label} values={{percentage: percentage ?? ''}} />
        </span>
        {showNOfM && (
          <>
            {': '}
            <span>
              {t('general:ranges.nOfM', {
                m: formatDecimal(m ?? 0),
                n: formatDecimal(n ?? 0),
              })}
            </span>
          </>
        )}
      </div>
    </SinglePercentagePie>
  );
}
