import {EnumFlowGroupType, EnumFlowNodeType, EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {
  getDescendantNodesSelector,
  getNodeAncestrySelector,
} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {
  isGroup,
  isMaterialResource,
  isProject,
  isSubWorkPackage,
  isTask,
  isWorkPackage,
} from '@octaved/flow/src/Node/NodeIdentifiers';
import {todayIsoDateSelector} from '@octaved/flow/src/Today';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {EntityStates} from '@octaved/store/src/EntityState';
import {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, mapCache} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {PlanningDependencies, PlanningDependency} from '../EntityInterfaces/PlanningDependency';
import {PlanningState} from '../PlanningState';
import {canAddGapBarSelector} from './CanAddGapBarSelector';
import {getPlanningDatesForNodeSelector} from './PlanningDateSelectors';

export const TYPE_FS = EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR;
export const TYPE_SS = EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR;
export const TYPE_FF = EnumFlowPlanningDependencyType.VALUE_ENDS_WITH_PREDECESSOR;

export function generatePlanningDependencyId(successor: Uuid, predecessor: Uuid): string {
  return `${successor}-${predecessor}`;
}

export const planningDependencyEntityStatesSelector = (state: PlanningState): EntityStates =>
  state.entityStates.planningDependency;
const planningDependencyEntitiesSelector = (state: PlanningState): PlanningDependencies =>
  state.entities.planningDependency;

export const getIsPlannedRelativeToParentSelector = createSelector(
  getNodeSelector,
  getNodeAncestrySelector,
  (getNode, getNodeAncestry) => (nodeId: MaybeUuid) => {
    const node = getNode(nodeId);
    if ((isTask(node) || isSubWorkPackage(node)) && node.planningPredecessors.length === 1) {
      const nodeAncestry = getNodeAncestry(nodeId);
      const parentId = nodeAncestry.subWorkPackage?.id || nodeAncestry.workPackage?.id;
      return node.planningPredecessors[0] === parentId;
    }
    return false;
  },
);

export const getPlanningDependencySelector = createSelector(
  planningDependencyEntitiesSelector,
  (planningDependencyEntities) =>
    (successor: Uuid, predecessor: Uuid): PlanningDependency | undefined =>
      planningDependencyEntities[generatePlanningDependencyId(successor, predecessor)],
);

function planningDependenciesShouldBeFiltered(forDisplay: boolean, node: NodeType): boolean {
  if (!forDisplay) {
    return false;
  }
  return isTask(node) || isSubWorkPackage(node);
}

export const getPlanningPredecessorsSelector = createSelector(
  getNodeSelector,
  getPlanningDependencySelector,
  getIsPlannedRelativeToParentSelector,
  (getNode, getPlanningDependency, getIsPlannedRelativeToParent) =>
    memoize(
      (nodeId: Uuid, forDisplay: boolean): PlanningDependency[] => {
        const node = getNode(nodeId);
        if (node) {
          if (planningDependenciesShouldBeFiltered(forDisplay, node) && getIsPlannedRelativeToParent(node.id)) {
            return [];
          }

          return boolFilter(
            node.planningPredecessors.map((predecessorId) => getPlanningDependency(node.id, predecessorId)),
          );
        }
        return [];
      },
      (...args) => args.join('-'),
    ),
);

export const getPlanningSuccessorSelector = createSelector(
  getNodeSelector,
  getPlanningDependencySelector,
  getIsPlannedRelativeToParentSelector,
  (getNode, getPlanningDependency, getIsPlannedRelativeToParent) =>
    memoize(
      (nodeId: Uuid, forDisplay: boolean): PlanningDependency[] => {
        const node = getNode(nodeId);
        if (node) {
          if (planningDependenciesShouldBeFiltered(forDisplay, node) && getIsPlannedRelativeToParent(node.id)) {
            return [];
          }
          return boolFilter(node.planningSuccessors.map((successorId) => getPlanningDependency(successorId, node.id)));
        }
        return [];
      },
      (...args) => args.join('-'),
    ),
);

export const getPlanningDateDependenceSelector = createSelector(
  getPlanningDatesForNodeSelector,
  getPlanningPredecessorsSelector,
  getPlanningSuccessorSelector,
  (
    getPlanningDatesForNode,
    getPlanningPredecessors,
    getPlanningSuccessor,
  ): ((
    nodeId: Uuid,
    planningDateId: Uuid,
  ) => {
    hasDependentSuccessor: boolean;
    isDependentOfPredecessor: boolean;
  }) =>
    mapCache((nodeId, planningDateId) => {
      const dates = getPlanningDatesForNode(nodeId);
      const isFirst = dates[0]?.id === planningDateId;
      const isLast = dates[dates.length - 1]?.id === planningDateId;
      if (!isFirst && !isLast) {
        return {
          hasDependentSuccessor: false,
          isDependentOfPredecessor: false,
        };
      }
      return {
        hasDependentSuccessor: getPlanningSuccessor(nodeId, false).some(
          ({type}) => (isFirst && type === TYPE_SS) || (isLast && (type === TYPE_FS || type === TYPE_FF)),
        ),
        isDependentOfPredecessor: getPlanningPredecessors(nodeId, false).some(
          ({type}) => (isFirst && (type === TYPE_FS || type === TYPE_SS)) || (isLast && type === TYPE_FF),
        ),
      };
    }),
);

export const canPlanNodeSelector = createSelector(getNodeSelector, isGrantedSelector, (getNode, isGranted) =>
  memoize((nodeId: Uuid) => {
    const node = getNode(nodeId);
    const nodeType = node?.nodeType;
    if (nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
      return true;
    }
    if (nodeType === EnumFlowNodeType.VALUE_TASK) {
      return isGranted('FLOW_NODE_TASK_MANAGE_BASIC', nodeId);
    }
    if (nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
      return isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', nodeId);
    }
    const pidTypes: Array<EnumFlowNodeType | undefined> = [
      EnumFlowNodeType.VALUE_PROJECT,
      EnumFlowNodeType.VALUE_GROUP,
      EnumFlowNodeType.VALUE_WORK_PACKAGE,
    ];

    if (pidTypes.includes(nodeType)) {
      return isGranted('FLOW_NODE_PID_MANAGE_BASIC', nodeId);
    }
    return false;
  }),
);

export const canChangeBarSelector = createSelector(
  getNodeSelector,
  getRootGroupTypeSelector,
  canPlanNodeSelector,
  getIsPlannedRelativeToParentSelector,
  (getNode, getRootGroupType, canPlanNode, getIsPlannedRelativeToWorkPackage) =>
    memoize((nodeId: Uuid): boolean => {
      const node = getNode(nodeId);
      if (!canPlanNode(nodeId)) {
        return false;
      }
      if ((isTask(node) || isSubWorkPackage(node)) && getIsPlannedRelativeToWorkPackage(node.id)) {
        return true;
      }
      if (isMaterialResource(node)) {
        return true;
      }
      return !(
        !node ||
        (isWorkPackage(node) && getRootGroupType(nodeId) !== EnumFlowGroupType.VALUE_GROUP) ||
        (isGroup(node) && node.groupType !== EnumFlowGroupType.VALUE_SPRINT) ||
        isProject(node)
      );
    }),
);

export const canDeleteBarSelector = createSelector(canChangeBarSelector, getNodeSelector, (canChangeBar, getNode) =>
  memoize(
    (nodeId: Uuid, isFirst: boolean, isLast: boolean): boolean => {
      const node = getNode(nodeId);
      return canChangeBar(nodeId) && !!node && (node.planningPredecessors.length === 0 || !(isFirst && isLast));
    },
    (...args) => args.join('-'),
  ),
);

export const canDndDependencySelector = createSelector(
  getNodeSelector,
  getRootGroupTypeSelector,
  canPlanNodeSelector,
  (getNode, getRootGroupType, canPlanNode) =>
    memoize((nodeId: Uuid): boolean => {
      const node = getNode(nodeId);
      if (isMaterialResource(node)) {
        //material resources does not have dependencies
        return false;
      }
      if (node && canPlanNode(nodeId)) {
        if (isWorkPackage(node)) {
          return getRootGroupType(nodeId) === EnumFlowGroupType.VALUE_GROUP;
        }
        if (isTask(node) || isSubWorkPackage(node)) {
          return true;
        }
      }
      return false;
    }),
);

export const showPlaceholderSelector = createSelector(
  getNodeSelector,
  isGrantedSelector,
  getRootGroupTypeSelector,
  canAddGapBarSelector,
  (getNode, isGranted, getRootGroupType, canAddGapBar) =>
    memoize((nodeId: Uuid): boolean => {
      const node = getNode(nodeId);
      if (node && !canAddGapBar(nodeId, true)) {
        return false;
      }

      if (isMaterialResource(node)) {
        return true;
      }

      if (isSubWorkPackage(node) && isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', nodeId)) {
        return true;
      }

      if (!isGranted('FLOW_NODE_PID_MANAGE_BASIC', nodeId)) {
        return false;
      }

      if (!isWorkPackage(node) && !isGroup(node) && !isTask(node)) {
        return false;
      }

      return (
        (isWorkPackage(node) && getRootGroupType(nodeId) === EnumFlowGroupType.VALUE_GROUP) ||
        (isGroup(node) && node.groupType === EnumFlowGroupType.VALUE_SPRINT) ||
        isTask(node)
      );
    }),
);

export enum DueDateStatus {
  inFuture,
  allWorkPackagesDone,
  notAllWorkPackagesDone,
}

const allWorkpackagesDoneSelector = createSelector(getDescendantNodesSelector, (getChildNodes) =>
  memoize((groupId: Uuid): boolean => {
    const children = getChildNodes(groupId);
    for (const child of children) {
      if (isWorkPackage(child) && !child.isCompleted) {
        return false;
      }
    }
    return true;
  }),
);

export const getDueDateStatusSelector = createSelector(
  getNodeSelector,
  allWorkpackagesDoneSelector,
  todayIsoDateSelector,
  (getNode, allWorkpackagesDone, todayIsoDate) =>
    memoize((groupId: Uuid): DueDateStatus => {
      const node = getNode(groupId);
      if ((isGroup(node) || isProject(node)) && node.dueDate && node.dueDate < todayIsoDate) {
        return allWorkpackagesDone(groupId) ? DueDateStatus.allWorkPackagesDone : DueDateStatus.notAllWorkPackagesDone;
      }
      return DueDateStatus.inFuture;
    }),
);
