import {Uuid} from '@octaved/typescript/src/lib';
import {createDummyRef} from '@octaved/utilities/src/React';
import {
  createContext,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {NodeTree} from '../../../../EntityInterfaces/NodeTree';
import {nodeTreeSelector} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../../../../Modules/State';

//parentIdent: "tUuid" for tasks, "sUuid" for sections because we handle them separately
type SortedIds = Record<string, Uuid[] | undefined>; //{parentIdent: childIds[]}
type Indexes = Record<Uuid, number | undefined>;
type SortedIdsIndexes = Record<string, Indexes | undefined>; //{parentIdent: {childId: index}}

interface StaticDragContext {
  getSortedIdsIndexesRef: (type: 'section' | 'task', parentId: Uuid) => Indexes | undefined;
  getSortedIdsRef: (type: 'section' | 'task', parentId: Uuid) => Uuid[] | undefined;
  isDraggingRef: MutableRefObject<Uuid | null>;
  isSet: boolean;
  patchSortedIds: (type: 'section' | 'task', orders: SortedIds, updateState?: boolean) => void;
  patchTree: (tree: NodeTree) => void;
}

interface DynamicDragContext {
  getSortedIdsIndexes: (type: 'section' | 'task', parentId: Uuid) => Indexes | undefined;
  treeSelector: (state: FlowState) => NodeTree;
}

const defaultStaticDragContext: StaticDragContext = {
  getSortedIdsIndexesRef: () => undefined,
  getSortedIdsRef: () => undefined,
  isDraggingRef: createDummyRef(null),
  isSet: false,
  patchSortedIds: () => undefined,
  patchTree: () => undefined,
};
const staticDragContext = createContext<StaticDragContext>(defaultStaticDragContext);

const defaultDynamicDragContext: DynamicDragContext = {
  getSortedIdsIndexes: () => undefined,
  treeSelector: nodeTreeSelector,
};
const dynamicDragContext = createContext<DynamicDragContext>(defaultDynamicDragContext);

export function useStaticDragContext(): StaticDragContext {
  return useContext(staticDragContext);
}

export function useDynamicDragContext(): DynamicDragContext {
  return useContext(dynamicDragContext);
}

function createSortedIdsIdent(parentId: Uuid, type: 'section' | 'task'): string {
  return `${type}${parentId}`;
}

function reduceSortedIdsToIndexes(sortedIds: SortedIds): SortedIdsIndexes {
  return Object.entries(sortedIds).reduce<SortedIdsIndexes>((acc, [parent, ids]) => {
    if (ids) {
      acc[parent] = ids.reduce<Record<Uuid, number>>((list, id, index) => {
        list[id] = index;
        return list;
      }, {});
    }
    return acc;
  }, {});
}

export default function DragContext({children}: {children: ReactNode}): ReactElement {
  const nodeTree = useSelector(nodeTreeSelector);
  const snapTreeRef = useRef(nodeTree);
  const [snapTree, setSnapTree] = useState(nodeTree);
  const [sortedIdsIndexes, setSortedIdsIndexes] = useState<SortedIdsIndexes>({});
  const sortedIdsIndexesRef = useRef(sortedIdsIndexes);
  const sortedIdsRef = useRef<SortedIds>({});

  const isDraggingRef = useRef(null);

  //Must update the ref synchronous
  if (!isDraggingRef.current) {
    snapTreeRef.current = nodeTree;
  }

  const patchSortedIds: StaticDragContext['patchSortedIds'] = useCallback((type, sortedIds, updateState = true) => {
    Object.entries(sortedIds).forEach(([key, value]) => {
      sortedIdsRef.current[createSortedIdsIdent(key, type)] = value;
    });
    const indexes = reduceSortedIdsToIndexes(sortedIdsRef.current);
    if (JSON.stringify(indexes) !== JSON.stringify(sortedIdsIndexesRef.current)) {
      sortedIdsIndexesRef.current = indexes;
      if (updateState) {
        setSortedIdsIndexes(sortedIdsIndexesRef.current);
      }
    }
  }, []);

  const treeSelector = useCallback(() => {
    //dependencies - treeSelector must upate, but then yield the latest fresh version of the tree
    // noinspection BadExpressionStatementJS
    void nodeTree;
    // noinspection BadExpressionStatementJS
    void snapTree;
    return snapTreeRef.current;
  }, [nodeTree, snapTree]);

  const getSortedIdsIndexes: DynamicDragContext['getSortedIdsIndexes'] = useCallback(
    (type, parentId) => {
      return sortedIdsIndexes[createSortedIdsIdent(parentId, type)];
    },
    [sortedIdsIndexes],
  );

  const getSortedIdsIndexesRef: StaticDragContext['getSortedIdsIndexesRef'] = useCallback(
    (type, parentId) => {
      return sortedIdsIndexesRef.current[createSortedIdsIdent(parentId, type)];
    },
    [sortedIdsIndexesRef],
  );

  const getSortedIdsRef: StaticDragContext['getSortedIdsRef'] = useCallback(
    (type, parentId) => {
      return sortedIdsRef.current[createSortedIdsIdent(parentId, type)];
    },
    [sortedIdsRef],
  );

  const staticCtx = useMemo((): StaticDragContext => {
    return {
      getSortedIdsIndexesRef,
      getSortedIdsRef,
      isDraggingRef,
      patchSortedIds,
      isSet: true,
      patchTree: (tree) => {
        snapTreeRef.current = {...snapTreeRef.current, ...tree};
        setSnapTree(snapTreeRef.current);
      },
    };
  }, [getSortedIdsIndexesRef, getSortedIdsRef, patchSortedIds]);

  const dynamicCtx = useMemo((): DynamicDragContext => {
    return {getSortedIdsIndexes, treeSelector};
  }, [getSortedIdsIndexes, treeSelector]);

  return (
    <staticDragContext.Provider value={staticCtx}>
      <dynamicDragContext.Provider value={dynamicCtx}>{children}</dynamicDragContext.Provider>
    </staticDragContext.Provider>
  );
}

// noinspection FunctionNamingConventionJS
export function DragContextReset({children}: {children: ReactNode}): ReactElement {
  return (
    <staticDragContext.Provider value={defaultStaticDragContext}>
      <dynamicDragContext.Provider value={defaultDynamicDragContext}>{children}</dynamicDragContext.Provider>
    </staticDragContext.Provider>
  );
}
