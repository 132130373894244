/* eslint-disable @typescript-eslint/no-explicit-any */

export function createPromise<T = void>(): [Promise<T>, (r: T) => void, (e: any) => void] {
  let resolve: (r: T) => void;
  let reject: (e: string | Error) => void;
  const prom: Promise<T> = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return [prom, resolve!, reject!];
}

export function findLastIndex<T>(array: T[], matcher: (item: T, index: number, array: T[]) => boolean): number {
  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i];
    if (matcher(item, i, array)) {
      return i;
    }
  }
  return -1;
}

export function findLast<T>(array: T[], matcher: (item: T, index: number, array: T[]) => boolean): T | undefined {
  return array[findLastIndex(array, matcher)];
}

export function pushOnArrayMap<K, V>(map: Map<K, V[]>, key: K, value: V): void {
  if (!map.has(key)) {
    map.set(key, []);
  }
  map.get(key)!.push(value);
}

export function pushOnSetMap<K, V>(map: Map<K, Set<V>>, key: K, value: V): void {
  if (!map.has(key)) {
    map.set(key, new Set());
  }
  map.get(key)!.add(value);
}

export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function boolFilter<T>(array: ReadonlyArray<T | undefined | null | false>): T[] {
  return castFilter(array, Boolean);
}

export function castFilter<T, Source = any>(
  array: ReadonlyArray<Source | T | undefined | null | false>,
  filter:
    | ((val: Source | T | undefined | null | false) => boolean)
    | ((val: Source | T | undefined | null | false) => val is T),
): T[] {
  return array.filter(filter) as T[];
}
