import {FlowState} from '@octaved/flow/src/Modules/State';
import {UnknownAction} from 'redux';
import {Workloads} from '../../EntityInterfaces/Workload';
import {handlePatchNodesResponsibilitiesAction} from './PatchNodesResponsibilitiesReqest';
import {handlePatchTaskAction} from './PatchTaskRequestAction';
import {handlePatchWorkPackageAction} from './PatchWorkPackageRequest';
import {handlePlanningRequestAction} from './PlanningPatchRequest';
import {handleRemoveRequestAction} from './RemoveTaskRequest';
import UpdateWorkloadContext from './UpdateWorkloadContext';

const handler = [
  handlePatchNodesResponsibilitiesAction,
  handlePlanningRequestAction,
  handlePatchWorkPackageAction,
  handlePatchTaskAction,
  handleRemoveRequestAction,
];

export function updateWorkload(state: FlowState, action: UnknownAction): Workloads | null {
  const context = new UpdateWorkloadContext(state);
  if (handleRequests(context, action)) {
    return context.currentWorkload;
  }
  return null;
}

function handleRequests(context: UpdateWorkloadContext, action: UnknownAction): boolean {
  for (const fn of handler) {
    if (fn(context, action)) {
      return true;
    }
  }
  return false;
}
