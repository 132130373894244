import {identityIsEmailVerifiedSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {useHasLicenseBar} from '@octaved/license/src/License';
import {HStack, IconButton, Tooltip} from '@octaved/ui';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {HelpCircle} from 'lucide-react';
import {ReactElement, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import css from 'styled-jsx/css';
import Close from '../../../Dialogs/Components/Close';
import {FlowStyleBuilder, useFlowStyle} from '../../../Styles/StyleContext';

/*language=SCSS*/
const getStyle: FlowStyleBuilder = ({COLORS: {BACKGROUND, LEVITATION}}) => css.resolve`
  .drawerContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 11;
    top: 70px;
    border-left: 1px solid #f8fafc;
  }

  .drawerContainer.inNodeDetails:not(.pinned):not(.fullScreen) {
    top: 110px;
  }

  .alwaysAbove {
    z-index: 12;
  }

  .userIsNotVerified,
  .isTrial {
    top: 110px;
  }

  .userIsNotVerified.isTrial {
    top: 150px;
  }

  .pinned,
  .userIsNotVerified.pinned,
  .isTrial.pinned {
    position: relative;
    top: 0;
    height: 100%;
    border-left: 1px solid #e2e8f0;
  }

  .drawerContainer.fullScreen,
  .userIsNotVerified.drawerContainer.fullScreen,
  .isTrial.drawerContainer.fullScreen {
    top: 0;
  }

  .withinLeftDrawer,
  .userIsNotVerified.withinLeftDrawer,
  .isTrial.withinLeftDrawer {
    top: 0;
  }

  .aboveDialog {
    z-index: 2010;
  }

  .drawerContent {
    background-color: ${BACKGROUND.STANDARD};
    height: 100%;
    overflow: hidden;
  }

  .dropShadow {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 2px;
    z-index: -1;
    width: 1px;
    box-shadow: -3px 0px 20px 0px ${LEVITATION.LEVEL_10};
  }
  .pinned .dropShadow {
    display: none;
  }

  .closeIcon {
    position: absolute;
    top: 11px;
    right: 20px;
    cursor: pointer;
    margin: -5px;
    padding: 5px;
  }

  .contentWrapper {
    height: 100%;
    overflow-y: auto;
    display: flex;
    align-items: stretch;

    &.noScroll {
      overflow-y: hidden;
    }
  }
`;

interface Props {
  aboveDialog?: boolean;
  alwaysAbove?: boolean;
  animationKey: string;
  children: ReactNode;
  emptyInspector?: boolean;
  fullScreen?: boolean;
  heplToolTip?: string;
  leftPosition?: number;
  noScroll?: boolean;
  onClose?: () => void;
  pinned?: boolean;
  visible: boolean;
  withinLeftDrawer?: boolean;
}

export default function Drawer({
  aboveDialog,
  alwaysAbove,
  animationKey,
  children,
  emptyInspector,
  fullScreen = false,
  heplToolTip = '',
  leftPosition = 0,
  noScroll = false,
  onClose,
  pinned = false,
  visible,
  withinLeftDrawer = false,
}: Props): ReactElement {
  const {pathname} = useLocation();
  const {className, styles} = useFlowStyle(getStyle);
  const userIsNotVerified = !useSelector(identityIsEmailVerifiedSelector);
  const isTrial = useHasLicenseBar();
  return (
    <motion.div
      key={animationKey}
      className={classNames(className, 'drawerContainer', {
        aboveDialog,
        alwaysAbove,
        emptyInspector,
        fullScreen,
        isTrial,
        pinned,
        userIsNotVerified,
        withinLeftDrawer,
        inNodeDetails: pathname.includes('/node/'),
      })}
      initial={{x: visible ? 0 : '130%'}}
      animate={{x: visible ? leftPosition : '130%'}}
      transition={{duration: 0.12}}
      exit={{x: '100%'}}
    >
      <div className={classNames(className, 'dropShadow')} />
      <div className={classNames(className, 'drawerContent')}>
        {!emptyInspector && onClose && visible && (
          <div className={classNames(className, 'closeIcon')}>
            <HStack spacing={'8px'}>
              {heplToolTip && (
                <Tooltip toolTipTranslation={heplToolTip} position={'bottom right'}>
                  <IconButton variant={'ghost'} icon={<HelpCircle />} size={'sm'} cursor={'help'} />
                </Tooltip>
              )}
              {onClose && <Close close={onClose} />}
            </HStack>
          </div>
        )}

        <div className={classNames(className, 'contentWrapper', {noScroll})}>{children}</div>
      </div>
      {styles}
    </motion.div>
  );
}
