import {cn, HelpPopup} from '@octaved/ui';
import {PropsWithChildren, ReactElement} from 'react';
import {Footer} from './Components/Footer';

export interface InspectorViewFrameProps {
  help?: string;
  footer?: ReactElement;
}

export default function InspectorViewFrame({
  children,
  footer,
  help,
}: PropsWithChildren<InspectorViewFrameProps>): ReactElement {
  return (
    <div
      className={cn(
        'flex h-full max-h-full w-inspector flex-grow overflow-y-scroll px-5 py-4',
        !!footer && 'flex-col justify-between gap-y-10',
      )}
    >
      <div className={'relative flex h-fit flex-grow flex-col gap-y-6'}>
        {help && (
          <div className={'absolute -right-4 -top-3'}>
            <HelpPopup text={help} position={'bottom right'} contentClassName={'max-w-5xl'} />
          </div>
        )}
        {children}
      </div>
      <Footer>{footer}</Footer>
    </div>
  );
}
