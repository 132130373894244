import {roundToHalfDays} from '@octaved/flow/src/Formatter/Decimal';
import {TWProgress} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeSumOfTimeLimitsProps {
  countWorkPackages: number;
  countWorkPackagesWithLimit: number;
  headline: string;
  isLoading: boolean;
  maxEffort: number | null; //hours
  sumTimeLimits: number; //hours
  useDays: boolean;
}

export default function NodeSumOfTimeLimits({
  frame: Frame,
  countWorkPackages,
  countWorkPackagesWithLimit,
  headline,
  maxEffort,
  sumTimeLimits,
  useDays,
}: NodeSumOfTimeLimitsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  let max = maxEffort || 0;
  let sum = sumTimeLimits;
  let tokenWithMax = 'general:ranges.nOfCountHours';
  let tokenWithoutMax = 'general:date.hours';
  if (useDays) {
    max = roundToHalfDays(max / 8);
    sum = roundToHalfDays(sum / 8);
    tokenWithMax = 'general:ranges.nOfCountDays';
    tokenWithoutMax = 'general:date.days';
  }

  if (!maxEffort) {
    return null;
  }

  return (
    <Frame labelToken={headline}>
      <div className={'flex flex-col gap-y-1'}>
        {!!maxEffort && (
          <div className={'grid grid-cols-2 items-center gap-x-3'}>
            <TWProgress progress={(sum * 100) / max} color={sum > max ? 'red' : 'green'} size={'xl'} />
            <Trans
              i18nKey={tokenWithMax}
              count={max}
              values={{
                count: max,
                countFormatted: formatDecimal(max),
                n: formatDecimal(sum),
              }}
            />
          </div>
        )}

        {!maxEffort && (
          <div className={'font-bold leading-8'}>
            <span>
              <Trans i18nKey={tokenWithoutMax} count={sum} values={{countFormatted: formatDecimal(sum)}} />
            </span>
          </div>
        )}

        <div className={'text-sm'}>
          {t('pages:projects.inspector.timeAndFinancesProject.workPackagesWithLimitLabel')}
          {': '}
          {t('general:ranges.nOfM', {
            m: countWorkPackages,
            n: countWorkPackagesWithLimit,
          })}
        </div>
      </div>
    </Frame>
  );
}
