import {settingsSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import ExcelExportButton from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/ExcelExportButton';
import {ExportMode} from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/ExportModeSelection';
import IncludeTasks from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/IncludeTasks';
import TransmitButton from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/TransmitButton';
import {ReactElement, useState} from 'react';
import {useSelector} from 'react-redux';
import {NodeTasksSection} from '../../../Components/Fields/General/Section/NodeTasksSection';
import NodeOfferSumsSection from '../../../Components/Fields/Offer/Section/NodeOfferSumsSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {ProjectDetailsProps} from './General';

export default function SpecialQuote({node}: ProjectDetailsProps): ReactElement {
  const {
    webhooks: {transmitOffer: isWebhookConfigured},
  } = useSelector(settingsSelector);
  const [exportMode] = useState<ExportMode>(isWebhookConfigured ? 'erp' : 'excel');
  const [includeTasks, setIncludeTasks] = useState(false);

  return (
    <InspectorViewFrame help={'nodeDetails:view.specialQuote.help'}>
      <NodeOfferSumsSection nodeId={node.id} />
      <NodeTasksSection node={node} />
      <div className={'flex flex-col gap-y-2'}>
        <IncludeTasks includeTasks={includeTasks} setIncludeTasks={setIncludeTasks} />
        {exportMode === 'excel' && <ExcelExportButton pidId={node.id} includeTasks={includeTasks} />}
        {exportMode === 'erp' && <TransmitButton pidId={node.id} includeTasks={includeTasks} />}
      </div>
    </InspectorViewFrame>
  );
}
