import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {getNodeAncestrySelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {setNodeToRelative} from '@octaved/planning/src/Modules/PlanningOptions';
import {getPlanningDependencySelector} from '@octaved/planning/src/Selectors/PlanningDependencySelectors';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeParentDependencyTypeProps {
  node: SubWorkPackage | Task;
}

export default function NodeParentDependencyType({
  frame: Frame,
  node,
}: NodeParentDependencyTypeProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const getPlanningDependency = useSelector(getPlanningDependencySelector);
  const parentId = useSelector((s: FlowState) => {
    const ancestry = getNodeAncestrySelector(s)(node.id);
    if (ancestry.subWorkPackage) {
      return ancestry.subWorkPackage.id;
    } else if (ancestry.workPackage) {
      return ancestry.workPackage.id;
    }
    return null;
  });
  const currentType =
    getPlanningDependency(node.id, parentId!)?.type || EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR;

  const dispatch = useDispatch();
  return (
    <Frame labelToken={'nodeDetails:field.parentDependencyType.label'}>
      <TWSelect
        value={currentType}
        onValueChange={(value) => {
          dispatch(
            setNodeToRelative(
              node.id,
              null,
              value as
                | EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR
                | EnumFlowPlanningDependencyType.VALUE_ENDS_WITH_PREDECESSOR,
            ),
          );
        }}
      >
        <TWSelectTrigger className={'w-1/2'}>
          <TWSelectValue />
        </TWSelectTrigger>
        <TWSelectContent>
          <TWSelectItem value={EnumFlowPlanningDependencyType.VALUE_STARTS_WITH_PREDECESSOR}>
            {t('nodeDetails:field.parentDependencyType.startsWithPredecessor')}
          </TWSelectItem>
          <TWSelectItem value={EnumFlowPlanningDependencyType.VALUE_ENDS_WITH_PREDECESSOR}>
            {t('nodeDetails:field.parentDependencyType.endsWithPredecessor')}
          </TWSelectItem>
        </TWSelectContent>
      </TWSelect>
    </Frame>
  );
}
