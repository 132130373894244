import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {hasPriceCategory} from '@octaved/flow/src/Modules/Pid';
import {isCustomerBillableSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {
  getBillingTypeTranslatable,
  getSortedBillingTypes,
  hourlyBillableTypesSet,
} from '@octaved/flow/src/WorkPackage/BillingType';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

const allowedInternalOptions = new Set([
  EnumFlowPidBillingType.VALUE_EFFORT,
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
]);

type TransfereablePidData = Partial<
  Pick<
    WorkPackage,
    'effortFrom' | 'effortTo' | 'fixedPrice' | 'maxEffort' | 'priceCategory' | 'usePriceCategoryPerTimeTracking'
  >
>;

function evaluateTransfereableBillingData(
  node: WorkPackage,
  billingType: EnumFlowPidBillingType | null,
): TransfereablePidData {
  const data: TransfereablePidData = {
    effortFrom: null,
    effortTo: null,
    fixedPrice: null,
    maxEffort: null,
  };

  if (
    billingType === EnumFlowPidBillingType.VALUE_CONTINGENT ||
    billingType === EnumFlowPidBillingType.VALUE_EFFORT_CAP ||
    billingType === EnumFlowPidBillingType.VALUE_EFFORT_EST
  ) {
    data.maxEffort = node.effortTo || node.maxEffort;
  } else if (billingType === EnumFlowPidBillingType.VALUE_FIXED_PRICE) {
    data.maxEffort = node.effortTo || node.maxEffort;
    data.fixedPrice = node.fixedPrice;
  } else if (billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO) {
    data.effortTo = node.maxEffort;
  }

  if (!hasPriceCategory({billingType})) {
    data.priceCategory = null;
    data.usePriceCategoryPerTimeTracking = false;
  } else if (billingType && !hourlyBillableTypesSet.has(billingType)) {
    data.usePriceCategoryPerTimeTracking = false;
  }

  return data;
}

export interface NodeBillingTypeProps {
  node: WorkPackage;
  readonly?: boolean;
  patch: (data: Partial<WorkPackage>) => void;
}

export default function NodeBillingType({
  frame: Frame,
  node,
  readonly,
  patch,
}: NodeBillingTypeProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const isCustomerBillable = useSelector(isCustomerBillableSelector)(node.flowCustomer);
  const options = useMemo(() => {
    return getSortedBillingTypes(isCustomerBillable)
      .filter((type) => isCustomerBillable || allowedInternalOptions.has(type) || type === node.billingType)
      .map((type) => ({
        id: type,
        name: t(getBillingTypeTranslatable(type, isCustomerBillable)),
      }));
  }, [isCustomerBillable, t, node.billingType]);

  const labelToken = isCustomerBillable
    ? 'nodeDetails:field.billingType.billable.label'
    : 'nodeDetails:field.billingType.notBillable.label';

  if (!node.billingType) {
    return null;
  }

  return (
    <Frame labelToken={labelToken}>
      {readonly && <>{t(getBillingTypeTranslatable(node.billingType, isCustomerBillable))}</>}
      {!readonly && (
        <TWSelect
          value={node.billingType}
          onValueChange={(billingType) =>
            patch({
              ...evaluateTransfereableBillingData(node, billingType as EnumFlowPidBillingType),
              billingType: billingType ? (billingType as EnumFlowPidBillingType) : undefined,
            })
          }
        >
          <TWSelectTrigger>
            <TWSelectValue placeholder={t(labelToken)} />
          </TWSelectTrigger>
          <TWSelectContent>
            {options.map(({id, name}) => (
              <TWSelectItem key={id} value={id}>
                {name}
              </TWSelectItem>
            ))}
          </TWSelectContent>
        </TWSelect>
      )}
    </Frame>
  );
}
