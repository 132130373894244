import {IsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  canEditTimeRecordSelector,
  canStartTimeRecordOnNodeSelector,
} from '../Modules/Selectors/TimeRecordingValidationSelectors';

export function canManageNonBookable(isGranted: IsGranted, superUserActive: boolean, workPackageId: Uuid): boolean {
  return isGranted('FLOW_NODE_PROJECT_TIME_TRACKING_MANAGE_NON_BOOKABLE', workPackageId) && superUserActive;
}

export function useCanManageTimeRecording(nodeId: Uuid | null, isNew: boolean, timeRecordId: Uuid): boolean {
  const canStartTimeRecordOnNode = useSelector(canStartTimeRecordOnNodeSelector);
  const canEditTimeRecord = useSelector(canEditTimeRecordSelector);
  return useMemo(() => {
    if (isNew) {
      return !nodeId || canStartTimeRecordOnNode(nodeId);
    } else {
      return canEditTimeRecord(timeRecordId);
    }
  }, [canEditTimeRecord, canStartTimeRecordOnNode, isNew, nodeId, timeRecordId]);
}
