import {mergeStates} from '@octaved/store/src/MergeStates';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {ProjectPages} from '../../Pages/Projects/ProjectContext';
import {createDefaultTimePeriod, TimePeriod} from '../Filter/TimePeriod';
import {defaultProjectTreeApi, ProjectTreeApi} from '../Projects/ProjectTreeApi';
import {getProjectsFilterStatesSelector} from '../Selectors/UiPages/ProjectsSelector';
import {FlowState} from '../State';
import {setInspector, setUiState} from '../Ui';

interface ProjectsUiStateDeprecated {
  groupPids: boolean;
  selectedTask: Uuid | null;
}

export interface ProjectsUiState extends ProjectsUiStateDeprecated {
  billing: {
    checkedWorkPackageIds: Uuid[];
    newBilling: {
      exportSumsAsExcel: boolean;
      exportTimesheetAsPdf: boolean;
      exportTimesheetAsPdfWithNames: boolean;
      transferToErp: boolean;
    };
  };
  billingInPeriod: {
    checkedWorkPackageIds: Uuid[];
  };
  billingPreparation: {
    checkedWorkPackageIds: Uuid[];
  };
  collapsedInspector: boolean;
  collapsedLeftFolder: boolean;
  collapsedSubMenu: boolean;
  filteredCustomer: null | Uuid;
  groupProjects: boolean;
  inspector: {
    timeTracking: {
      showDaysInsteadOfHours: boolean;
    };
  };
  mainProjectTreeApi: ProjectTreeApi; //updated by the main tree on the projects page - may be async one cycle
  moveGroupOrWorkPackage: null | Uuid;
  openNodes: Uuid[];
  projectControlling: {
    demoMode: boolean;
    showDaysInsteadHours: boolean;
  };
  rearrangeProject: Uuid | null;
  draggedPidId: Uuid | null;
  search: string;
  selectedProjectFolder: Uuid | null;
  showOnlyFavoriteProjects: boolean;
  showPinnedProjects: boolean;
  showProjectFolder: boolean;
  showSubFolderProjects: boolean;
  sortProjectsBy: 'alphanumeric' | 'customer' | 'pm';
  timesheetsExternal: {
    period: TimePeriod;
    search: string;
  };
}

export const initialState: ProjectsUiState = {
  billing: {
    checkedWorkPackageIds: [],
    newBilling: {
      exportSumsAsExcel: false,
      exportTimesheetAsPdf: false,
      exportTimesheetAsPdfWithNames: false,
      transferToErp: false,
    },
  },
  billingInPeriod: {
    checkedWorkPackageIds: [],
  },
  billingPreparation: {
    checkedWorkPackageIds: [],
  },
  collapsedInspector: false,
  collapsedLeftFolder: false,
  collapsedSubMenu: false,
  draggedPidId: null,
  filteredCustomer: null,
  groupPids: false,
  groupProjects: false,
  inspector: {
    timeTracking: {
      showDaysInsteadOfHours: false,
    },
  },
  mainProjectTreeApi: defaultProjectTreeApi,
  moveGroupOrWorkPackage: null,
  openNodes: [],
  projectControlling: {
    demoMode: false,
    showDaysInsteadHours: false,
  },
  rearrangeProject: null,
  search: '',
  selectedProjectFolder: null,
  selectedTask: null,
  showOnlyFavoriteProjects: false,
  showPinnedProjects: true,
  showProjectFolder: true,
  showSubFolderProjects: true, //must be true for guest users - they can't see the project folders!
  sortProjectsBy: 'alphanumeric',
  timesheetsExternal: {
    period: createDefaultTimePeriod(1),
    search: '',
  },
};

export function moveGroupOrWorkPackage(pidId: Uuid | null): ActionDispatcher<void, FlowState> {
  return setInspector({pages: {projects: {moveGroupOrWorkPackage: pidId}}});
}

export function rearrangeProject(projectId: Uuid | null): ActionDispatcher<void, FlowState> {
  return setInspector({pages: {projects: {rearrangeProject: projectId}}});
}

export function setDraggedPidId(draggedPidId: Uuid | null): ActionDispatcher<void, FlowState> {
  return setUiState({pages: {projects: {draggedPidId}}});
}

const lastFilter: Partial<Record<ProjectPages, unknown>> = {};

export const projectsUiRootReducer = (state: FlowState): FlowState => {
  let newState = state;
  // Unset the checkboxes whenever the filters change:
  ['billing' as const, 'billingInPeriod' as const, 'billingPreparation' as const].forEach((page) => {
    const newFilterState = getProjectsFilterStatesSelector(newState)(page);
    if (lastFilter[page] !== newFilterState) {
      lastFilter[page] = newFilterState;
      newState = mergeStates(newState, {ui: {pages: {projects: {[page]: {checkedWorkPackageIds: []}}}}});
    }
  });

  return newState;
};

export function setProjectControllingDemoMode(demoMode: boolean): ActionDispatcher<void, FlowState> {
  return setUiState({pages: {projects: {projectControlling: {demoMode}}}});
}
