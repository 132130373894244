import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {
  getOrgWorkMinutesAtDateSelector,
  GetWorkingTimeAtDate,
} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {DateStr, DateTimeStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  fromIsoDateTimeFormat,
  fromIsoFormat,
  toIsoDateTimeFormat,
} from '@octaved/users/src/Culture/DateFormatFunctions';
import {generateUuid} from '@octaved/utilities';
import dayjs from 'dayjs';
import {addWorkdays} from '../Calculations/WorkdayCalculations';
import {PlanningDatesList} from '../EntityInterfaces/PlanningDates';
import {MAX_PLANNING_DATES} from '../Selectors/CanAddGapBarSelector';
import {getMinMaxPlanningDatesSelector, getPlanningDatesForNodeSelector} from '../Selectors/PlanningDateSelectors';
import {canChangeBarSelector} from '../Selectors/PlanningDependencySelectors';
import {patchPlanning} from './Planning';
import {createPatchData} from './PlanningDates';

type WeekDays = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

interface RecurringDates {
  type: 'weekly' | 'monthly';
  first: DateStr | null;
  last: DateStr | null;
}

export interface WeeklyRecurringDates extends RecurringDates {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday?: false;
  sunday?: false;
}

export interface MonthlyRecurringDates extends RecurringDates {
  useFirstOfMonth: boolean;
  weekday: WeekDays;
  duration: number;
}

export function addWeeklyRecurringDates(
  nodeId: Uuid,
  config: WeeklyRecurringDates,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    if (node) {
      const currentPlanningDates = [...getPlanningDatesForNode(nodeId)];
      const {start, end} = getStartEndDate(config, nodeId, state);
      const planningDates = createWeeklyRecurringDates(nodeId, config, currentPlanningDates, start, end);
      dispatch(patchPlanning([createPatchData(state, node, planningDates)]));
    }
  };
}

export function addMonthlyRecurringDates(
  nodeId: Uuid,
  config: MonthlyRecurringDates,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const getNode = getNodeSelector(state);
    const getPlanningDatesForNode = getPlanningDatesForNodeSelector(state);
    const node = getNode(nodeId);

    if (node) {
      const currentPlanningDates = [...getPlanningDatesForNode(nodeId)];
      const getOrgWorkMinutesAtDate = getOrgWorkMinutesAtDateSelector(state);
      const {start, end} = getStartEndDate(config, nodeId, state);
      const planningDates = createMonthlyRecurringDates(
        nodeId,
        config,
        currentPlanningDates,
        getOrgWorkMinutesAtDate,
        start,
        end,
      );
      dispatch(patchPlanning([createPatchData(state, node, planningDates)]));
    }
  };
}

function getStartEndDate(
  config: RecurringDates,
  nodeId: Uuid,
  state: FlowState,
): {start: dayjs.Dayjs; end: dayjs.Dayjs} {
  const canChangeBar = canChangeBarSelector(state)(nodeId);
  const {plannedEnd, plannedStart} = getMinMaxPlanningDatesSelector(state)(nodeId);

  const today = dayjs().startOf('day');
  let start: dayjs.Dayjs | null = fromIsoFormat(config.first);
  let end: dayjs.Dayjs | null = fromIsoFormat(config.last);
  if (!start && !canChangeBar) {
    start = fromIsoDateTimeFormat(plannedStart);
  }
  if (!end && !canChangeBar) {
    end = fromIsoDateTimeFormat(plannedEnd);
  }
  if (!start) {
    start = today;
  }
  if (!end) {
    end = today.add(2, 'y');
  }
  return {start, end};
}

function createWeeklyRecurringDates(
  nodeId: Uuid,
  config: WeeklyRecurringDates,
  planningDates: PlanningDatesList,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
): PlanningDatesList {
  let today = start;
  let duration = 0;
  while (!today.isAfter(end) && planningDates.length < MAX_PLANNING_DATES) {
    const weekDay = today.weekday();
    const todayWeekDayName = weekDays[weekDay];
    const tomorrowWeekDayName = weekDays[weekDay + 1];
    const todayIsActive = config[todayWeekDayName] || false;
    const tomorrowIsActive = config[tomorrowWeekDayName] || false;

    if (todayIsActive && !tomorrowIsActive) {
      createRecurringPlanningDate(nodeId, today.subtract(duration, 'd'), today, planningDates);
      duration = 0;
    } else if (todayIsActive) {
      duration++;
    }
    today = today.add(1, 'd');
  }
  return planningDates;
}

function createMonthlyRecurringDates(
  nodeId: Uuid,
  config: MonthlyRecurringDates,
  planningDates: PlanningDatesList,
  getOrgWorkMinutesAtDate: GetWorkingTimeAtDate,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
): PlanningDatesList {
  let today = start;

  const requestedWeekDay = weekDays.indexOf(config.weekday);
  while (!today.isAfter(end) && planningDates.length < MAX_PLANNING_DATES) {
    let selectedDay: dayjs.Dayjs;
    if (config.useFirstOfMonth) {
      const first = today.startOf('M');
      const firstWeekDay = first.day() - 1;
      const toAdd = requestedWeekDay - firstWeekDay;
      selectedDay = first.add(toAdd < 0 ? 7 + toAdd : toAdd, 'day');
    } else {
      // endOf would change the time to 23:59:59
      const last = today.startOf('M').add(1, 'M').subtract(1, 'd');
      const lastWeekDay = last.day() - 1;
      const toRemove = lastWeekDay - requestedWeekDay;
      selectedDay = last.subtract(toRemove < 0 ? 7 + toRemove : toRemove, 'day');
    }

    if (!selectedDay.isBefore(start) && !selectedDay.isAfter(end)) {
      const endDate = addWorkdays(selectedDay, config.duration - 1, getOrgWorkMinutesAtDate);
      createRecurringPlanningDate(nodeId, selectedDay, endDate, planningDates);
    }
    today = today.add(1, 'M');
  }

  return planningDates;
}

function createRecurringPlanningDate(
  nodeId: Uuid,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  planningDates: PlanningDatesList,
): void {
  const id = generateUuid();
  const {plannedEnd, plannedStart} = limitDate(start, end, planningDates);
  if (plannedEnd) {
    planningDates.push({
      id,
      nodeId,
      plannedEnd,
      plannedStart,
      assignedNodeId: null,
      name: '',
    });
  }
}

function limitDate(
  plannedStart: dayjs.Dayjs,
  plannedEnd: dayjs.Dayjs,
  planningDates: PlanningDatesList,
): {plannedStart: DateTimeStr; plannedEnd: DateTimeStr} | {plannedStart: null; plannedEnd: null} {
  let isoPlannedStart = toIsoDateTimeFormat(plannedStart);
  let isoPlannedEnd = toIsoDateTimeFormat(plannedEnd);

  for (const planningDate of planningDates) {
    if (planningDate.plannedStart <= isoPlannedStart && planningDate.plannedEnd >= isoPlannedStart) {
      isoPlannedStart = toIsoDateTimeFormat(fromIsoDateTimeFormat(planningDate.plannedEnd).add(1, 'd'));
    }
    if (planningDate.plannedStart <= isoPlannedEnd && planningDate.plannedEnd >= isoPlannedEnd) {
      isoPlannedEnd = toIsoDateTimeFormat(fromIsoDateTimeFormat(planningDate.plannedStart).subtract(1, 'd'));
    }
  }

  if (isoPlannedEnd >= isoPlannedStart) {
    return {plannedEnd: isoPlannedEnd, plannedStart: isoPlannedStart};
  }

  return {plannedEnd: null, plannedStart: null};
}
