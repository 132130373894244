import {Lock} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export default function CannotViewDetailsPlaceholder(): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'flex h-full w-full flex-col items-center justify-center gap-y-5 pb-80'}>
      <Lock className={'size-20 text-slate-300'} strokeWidth={1} />
      <div className={'text-lg text-slate-500'}>{t('nodeDetails:cannotViewDetails')}</div>
    </div>
  );
}
