import {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';

export const timeTrackingErrorFields = [
  'timeTracking_endBeforeStart',
  'timeTracking_missingUser',
  'timeTracking_notAllowedAlreadyMarkedAsBilled',
  'timeTracking_notAllowedAncestorClosed',
  'timeTracking_notAllowedAncestorLocked',
  'timeTracking_notAllowedApprovedForBilling',
  'timeTracking_notAllowedArchived',
  'timeTracking_notAllowedExceedingBookTimeDaysTolerance',
  'timeTracking_notAllowedExceedingTimeControl',
  'timeTracking_notAllowedMissingPriceCategory',
  'timeTracking_notAllowedTemplate',
  'timeTracking_notAllowedToBookForOthers',
  'timeTracking_notAllowedToBookForOthersWithoutWorkPackage',
  'timeTracking_notAllowedToBookForSelf',
  'timeTracking_notAllowedToExceedGroupBudget',
  'timeTracking_notAllowedToExceedProjectBudget',
  'timeTracking_notAllowedToOverbook',
  'timeTracking_notAllowedWorkPackageIsFrozen',
] as const;

export type TimeTrackingErrorField = (typeof timeTrackingErrorFields)[number];

interface TimeTrackingError {
  field: TimeTrackingErrorField;
  message: ComplexTranslatable;
}

export type TimeTrackingErrors = TimeTrackingError[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const timeTrackingWarningFields = [
  'record_warn_alreadyMarkedAsBilled',
  'record_warn_approved_for_billing',
  'record_warn_archived',
  'record_warn_bookTimeDaysToleranceExceeded',
  'record_warn_closed',
  'record_warn_locked',
  'record_warn_newRecordNodeHasReachedTimeLimit',
  'record_warn_notBookableOpenRecord',
  'record_warn_template',
  'record_warn_timeControlExceeded',
  'record_warn_timeTrackingSuperUserModeCanBeActivated',
  'record_warn_timeTrackingSuperUserModeIsActive',
  'record_warn_workPackageIsFrozen',
] as const;

export type TimeTrackingWarningField = (typeof timeTrackingWarningFields)[number];

interface TimeTrackingWarning {
  field: TimeTrackingWarningField;
  message: ComplexTranslatable;
  superUserResolvable?: boolean;
}

export type TimeTrackingWarnings = TimeTrackingWarning[];
