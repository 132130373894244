import {lazy} from 'react';
import {Navigate} from 'react-router-dom';
import ProjectFolderInspectorContext from '../../../../Drawer/InspectorContext/ProjectFolderInspectorContext';
import {TabRecords} from '../../../../Drawer/StandardInspector/StandardInspectorTabs';
import InspectorContextLoadingWrapper from '../../../InspectorContextLoadingWrapper';
import {dialogRouteObjects} from '../../Dialogs';
import {getWrappedStandardInspectorRoutes} from '../StandardInspector';

const projectFolderRoute = 'projectFolder';

const ProjectFolderInspector = lazy(
  () => import('../../../../Pages/SystemSettings/ProjectFolders/Inspector/ProjectFolderInspector'),
);
const ProjectFolderInspectorSubMenu = lazy(
  () => import('../../../../Pages/SystemSettings/ProjectFolders/Inspector/ProjectFolderInspectorSubMenu'),
);

//#region <inspector tabs>
const inspectorTabConfig: TabRecords = {
  ['group']: [
    {
      path: projectFolderRoute,
      token: '',
    },
  ],
};
//endregion

export const projectFolderInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    {
      children: dialogRouteObjects,
      element: (
        <InspectorContextLoadingWrapper>
          <ProjectFolderInspector />
        </InspectorContextLoadingWrapper>
      ),
      path: projectFolderRoute,
    },
    {
      element: <Navigate to={projectFolderRoute} replace />,
      path: '*',
    },
  ],
  contextWrapper: ProjectFolderInspectorContext,
  standardInspectorProps: {
    actionMenu: ProjectFolderInspectorSubMenu,
    tabConfig: inspectorTabConfig,
  },
});
