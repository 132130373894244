import {createSelector} from 'reselect';
import {FlowState} from '../../State';

function taskListUi<R>(selector: (state: FlowState['ui']['taskList']) => R): (rootState: FlowState) => R {
  return (rootState) => selector(rootState.ui.taskList);
}

export const taskListStateSelector = taskListUi((state) => state);

export const taskListClosedGroupsSelector = taskListUi((state) => state.closedGroups);
export const taskListSectionColorSelector = taskListUi((state) => state.taskSectionColor);
const taskListVisibleColumnsSelector = taskListUi((state) => state.visibleColumns);

export const taskListTilePathSelector = taskListUi((state) => state.tile.taskPath);
export const taskListTileShowLabelsSelector = taskListUi((state) => state.tile.showLabels);
export const taskListTileShowStatusIconsSelector = taskListUi((state) => state.tile.showStatusIcons);

export const taskListVisibleColumnsSetSelector = createSelector(
  taskListVisibleColumnsSelector,
  (columns) => new Set(columns),
);
