import {Tooltip, cn} from '@octaved/ui';
import {ReactElement} from 'react';
import {ProjectTreeProject} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {SearchHighlightText} from '../../../Search/SearchHighlight';

interface CustomerNameProps {
  className?: string;
  node: ProjectTreeProject;
}

export default function CustomerName({className, node}: CustomerNameProps): ReactElement | null {
  const {name, customerNumber} = node.customer || {};
  const customerName = `${name || ''}${customerNumber ? ` (${customerNumber})` : ''}`;
  return (
    <Tooltip toolTipTranslation={{i18nKey: 'projectsTree:tooltipCustomer', values: {customer: customerName}}}>
      <div className={cn('mr-6 w-[8vw] truncate text-sm text-slate-600', className)}>
        <SearchHighlightText text={customerName} />
      </div>
    </Tooltip>
  );
}
