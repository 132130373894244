import {ReactElement, useContext} from 'react';
import {ProjectTreeProjectsGroup} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {flowStyleContext} from '../../../../Styles/StyleContext';

export default function Alphanumeric({node}: {node: ProjectTreeProjectsGroup}): ReactElement {
  const {
    COLORS: {AVATAR},
  } = useContext(flowStyleContext);
  return (
    <div className={'alphanumericRow'}>
      <div className={'alphanumericCircle'}>{node.groupDisplayName}</div>

      {/*language=SCSS*/}
      <style jsx>{`
        .alphanumericRow {
          grid-column: 2 / -1;
          display: flex;
          align-items: center;
        }

        .alphanumericCircle {
          background-color: #666;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${AVATAR.FONT};
          width: 30px;
          height: 30px;
          font-size: 14px;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
}
