import {v4} from 'uuid';

export const NIL = '00000000000000000000000000000000';

export function generateUuid(): string {
  return v4().replace(/-/g, '').toUpperCase();
}

function uuidHexToBytes(uuid: string): Uint8Array {
  const bytes = new Uint8Array(16);
  for (let i = 0; i < 16; i++) {
    bytes[i] = parseInt(`${uuid[i * 2] ?? '0'}${uuid[i * 2 + 1] ?? '0'}`, 16);
  }
  return bytes;
}

function uuidBase64ToBytes(base64: string): Uint8Array {
  const binary = atob(base64.replaceAll(/-/g, '+').replaceAll(/_/g, '/').padEnd(24, '='));
  const bytes = new Uint8Array(16);
  for (let i = 0; i < 16; i++) {
    const code = binary.charCodeAt(i);
    bytes[i] = isNaN(code) ? 0 : code;
  }
  return bytes;
}

export function uuidBase64ToHex(base64: string): string {
  const bytes = uuidBase64ToBytes(base64);
  let hex = '';
  for (let i = 0; i < 16; i++) {
    hex += (bytes[i] ?? 0).toString(16);
  }
  return hex.toUpperCase();
}

export function uuidHexToBase64(hex: string): string {
  return btoa(String.fromCharCode(...uuidHexToBytes(hex)))
    .replaceAll(/\+/g, '-')
    .replaceAll(/\//g, '_')
    .replace(/=*$/, '');
}

let instanceUuid: string | undefined;

export function getInstanceUuid(): string {
  if (!instanceUuid) {
    instanceUuid = generateUuid();
  }
  return instanceUuid;
}
