import {Uuid} from '@octaved/typescript/src/lib';
import {Label} from '@octaved/ui';
import CheckboxInput from '@octaved/ui-components/src/React/Formik/CheckboxInput';
import RadioInput from '@octaved/ui-components/src/React/Formik/RadioInput';
import SlideToggleElement from '@octaved/ui-components/src/SlideToggle/SlideToggleElement';
import {FormikProps} from 'formik';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import FirstLastDateSelection from './FirstLastDateSelection';
import {RecurringPlanningData} from './RecurringPlanningContent';

interface WeeklyConfigProps {
  disabled: boolean;
  formik: FormikProps<RecurringPlanningData>;
  nodeId: Uuid;
}

export default function WeeklyConfig({formik, disabled, nodeId}: WeeklyConfigProps): ReactElement {
  const {t} = useTranslation();
  return (
    <div>
      <RadioInput
        label={t('pages:projects.inspector.manage.planning.recurringPlanning.weekly')}
        name={'type'}
        value={'weekly'}
        formik={formik}
      />
      <SlideToggleElement visible={formik.values.type === 'weekly'}>
        <div className={'flex flex-col gap-y-4 py-2 pl-4 pr-7'}>
          <div>
            <div>
              <Label token={'pages:projects.inspector.manage.planning.recurringPlanning.recurOn'} />
            </div>
            <div className={'flex gap-x-6'}>
              <CheckboxInput
                label={t('generalCore:culture.weekDays.monday')}
                name={'monday'}
                formik={formik}
                disabled={disabled}
              />
              <CheckboxInput
                label={t('generalCore:culture.weekDays.tuesday')}
                name={'tuesday'}
                formik={formik}
                disabled={disabled}
              />
              <CheckboxInput
                label={t('generalCore:culture.weekDays.wednesday')}
                name={'wednesday'}
                formik={formik}
                disabled={disabled}
              />
              <CheckboxInput
                label={t('generalCore:culture.weekDays.thursday')}
                name={'thursday'}
                formik={formik}
                disabled={disabled}
              />
              <CheckboxInput
                label={t('generalCore:culture.weekDays.friday')}
                name={'friday'}
                formik={formik}
                disabled={disabled}
              />
            </div>
          </div>
          <FirstLastDateSelection formik={formik} disabled={disabled} nodeId={nodeId} />
        </div>
      </SlideToggleElement>
    </div>
  );
}
