import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement} from 'react';
import {useTaskGroupContext} from '../../TaskGroup/TaskGroupContext';
import {useTaskListContext} from '../../TaskListContext';
import AddItem from '../AddItem';
import {useShowNewRow} from '../NewItemHooks';
import NewTaskRow from './NewTaskRow';

interface Props {
  hasAnySubTasks: boolean;
  parentId: string;
  previousSiblingId: Uuid | undefined;
  reducedLeftPadding?: boolean;
}

const leftPadding =
  'calc(var(--drag-handle-width) + var(--cell-padding) + var(--check-circle-size) + var(--check-circle-spacing) + var(--check-circle-spacing) + 6px)';
const leftPaddingEmptyTable = 'calc(var(--drag-handle-width) + var(--cell-padding))';

function useShowInitially(parentId: Uuid): boolean {
  const {showNewTaskRowOnceForParentIds} = useTaskListContext();
  const show = showNewTaskRowOnceForParentIds.has(parentId);
  showNewTaskRowOnceForParentIds.delete(parentId);
  return show;
}

export default function AddTaskRows({
  hasAnySubTasks,
  parentId,
  previousSiblingId,
  reducedLeftPadding,
}: Props): ReactElement {
  const {focusNewTask} = useTaskGroupContext();
  const {scrollToRef, setShow, show} = useShowNewRow(focusNewTask, useShowInitially(parentId));
  const paddingLeft = reducedLeftPadding ? leftPaddingEmptyTable : leftPadding;
  return (
    <>
      {show && (
        <NewTaskRow
          hasAnySubTasks={hasAnySubTasks}
          onFinish={() => setShow(false)}
          parentId={parentId}
          previousSiblingId={previousSiblingId}
        />
      )}
      <AddItem onClick={() => setShow(true)} label={'taskList:addTask'} ref={scrollToRef} paddingLeft={paddingLeft} />
    </>
  );
}
