import * as SliderPrimitive from '@radix-ui/react-slider';
import {ReactElement} from 'react';
import {TWTooltip, TWTooltipContent, TWTooltipProvider, TWTooltipTrigger} from '../../TWTooltip';

export interface PercentageRangeSelectorProps {
  value?: [number, number];
  onChange: (value: [number, number]) => void;
}

export function PercentageRangeSelector({value = [0, 100], onChange}: PercentageRangeSelectorProps): ReactElement {
  return (
    <TWTooltipProvider>
      <SliderPrimitive.Root
        className={'relative flex h-5 w-full touch-none select-none items-center'}
        defaultValue={value}
        max={100}
        step={1}
        onValueChange={(v: [number, number]) => onChange(v)}
      >
        <SliderPrimitive.Track className={'relative h-2 w-full grow overflow-hidden rounded-full bg-slate-200'}>
          <SliderPrimitive.Range className={'absolute h-full bg-sky-700'} />
        </SliderPrimitive.Track>
        <TWTooltip open>
          <TWTooltipTrigger asChild>
            <SliderPrimitive.Thumb
              className={
                'relative z-20 block h-5 w-5 rounded-[10px] border border-slate-400 bg-white focus:bg-white focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2'
              }
            />
          </TWTooltipTrigger>
          <TWTooltipContent>{value[0]}%</TWTooltipContent>
        </TWTooltip>
        <TWTooltip open>
          <TWTooltipTrigger asChild>
            <SliderPrimitive.Thumb
              className={
                'relative z-20 block h-5 w-5 rounded-[10px] border border-slate-400 bg-white focus:bg-white focus:outline-none focus:ring-2 focus:ring-sky-700 focus:ring-offset-2'
              }
            />
          </TWTooltipTrigger>
          <TWTooltipContent>{value[1]}%</TWTooltipContent>
        </TWTooltip>
      </SliderPrimitive.Root>
    </TWTooltipProvider>
  );
}
