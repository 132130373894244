import get from 'lodash/get';
import setWith from 'lodash/setWith';
import isPlainObject from 'lodash/isPlainObject';
import unset from 'lodash/unset';
import {validateObject, validateString} from '@octaved/validation';

/**
 * Customizer for lodash-setWith to copy objects and arrays
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setWithCopyCustomizer(objValue: any): any {
  return isPlainObject(objValue) ? {...objValue} : Array.isArray(objValue) ? [...objValue] : undefined;
}

/**
 * Sets a state's property without losing reference to unchanged objects.
 *
 * Based on lodash/_baseSet
 *
 * @param state
 * @param path
 * @param value NOTE: undefined will cause the property to be deleted, not set to undefined!
 * @return {Object}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setStateProperty<T extends object>(state: T, path: string | string[], value: any): T {
  validateObject(state);
  if (!Array.isArray(path)) {
    validateString(path);
  }

  if (get(state, path) !== value) {
    const newState = {...state};
    setWith<T>(newState, path, value, setWithCopyCustomizer);
    if (value === undefined) {
      unset(newState, path);
    }
    return newState;
  }

  return state;
}
