import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Rect} from 'konva/lib/shapes/Rect';
import {TreeNodeEnumFlowNodeType} from '../../Gantt/Data/TreeNode';
import {Bar} from '../Bar';
import {BarPlugin, BarPluginProps} from './BarPlugin';

interface MilestoneRenderPluginProps extends BarPluginProps {
  nodeType: TreeNodeEnumFlowNodeType;
}

export class MilestoneRenderPlugin extends BarPlugin {
  #rect: Rect | null = null;
  #nodeType: TreeNodeEnumFlowNodeType;

  static createMilestoneRect(size: number, nodeType: TreeNodeEnumFlowNodeType = EnumFlowNodeType.VALUE_PROJECT): Rect {
    return new Rect({
      cornerRadius: 2,
      fillLinearGradientColorStops:
        nodeType === EnumFlowNodeType.VALUE_PROJECT ? [0, '#05a4fa', 1, '#58c5fe'] : [0, '#0483C8', 1, '#25B3FE'],
      fillLinearGradientEndPoint: {x: 0, y: 0},
      fillLinearGradientStartPoint: {x: 0, y: size},
      height: size,
      rotation: 45,
      width: size,
    });
  }

  constructor(props: MilestoneRenderPluginProps) {
    super(props);
    this.#nodeType = props.nodeType;
  }

  init(bar: Bar): void {
    this.#rect = MilestoneRenderPlugin.createMilestoneRect(bar.height - 4, this.#nodeType);
    super.init(bar);
    this.root.add(this.#rect);
  }

  onBarUpdated(): void {
    this.#rect?.x(this.bar.width + this.bar.barPaddingX);
  }
}
