import {Label} from '@octaved/ui';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import {motion} from 'framer-motion';
import {ReactElement, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import SearchableCustomerSelector from '../../Components/Customer/CustomerSelector/SearchableCustomerSelector';
import Errors from '../../Components/Feedback/Notifications/Errors';
import useBackdropShaking from '../../Drawer/BackdropShaking';
import {Project} from '../../EntityInterfaces/Pid';
import {patchProject} from '../../Modules/Projects';
import {customerEntitiesSelector, formatCustomerLocation} from '../../Modules/Selectors/CustomerSelectors';
import ProjectView from '../../Pages/Projects/Drawer/ProjectView';
import Save from '../../Pages/SystemSettings/Save';
import {useModalContext} from '../DialogFrame';

interface Props {
  project: Project;
}

const errorFields = ['flowCustomer'];

export default function ChangeProjectCustomerForm({project}: Props): ReactElement {
  const [customer, setCustomer] = useState(project.flowCustomer);
  const [location, setLocation] = useState(project.customerLocation);
  const {controls} = useBackdropShaking(true, true);
  const dispatch = useDispatch();
  const close = useModalContext()?.close;
  const customers = useSelector(customerEntitiesSelector);
  const newLocationOptions = useMemo((): DropdownItemProps[] => {
    return (customers[customer || '']?.locations || []).map((location) => ({
      text: formatCustomerLocation(location),
      value: location.id,
    }));
  }, [customers, customer]);
  const needsNewLocation = newLocationOptions.length > 0;
  const currentCustomer = customers[project.flowCustomer];
  const currentLocation = currentCustomer?.locations.find(({id}) => id === project.customerLocation);

  return (
    <div className={'pb-4'}>
      <Errors includeFields={errorFields} />

      <ProjectView project={project} />
      <Spacer spacing={30} />

      <div className={'grid grid-cols-2 gap-x-2 gap-y-8'}>
        <div>
          <Label token={'pages:projects.drawer.changeCustomer.currentCustomer'} />
          <div>{currentCustomer?.name}</div>
        </div>

        <div>
          {currentLocation && (
            <>
              <Label token={'pages:projects.drawer.changeCustomer.currentLocation'} />
              <div>{formatCustomerLocation(currentLocation)}</div>
            </>
          )}
        </div>

        <div>
          <Label token={'pages:projects.drawer.changeCustomer.newCustomer'} />
          <SearchableCustomerSelector
            onChange={(val) => {
              setCustomer(val);
              setLocation(null);
            }}
            value={customer}
            fieldName={'flowCustomer'}
          />
        </div>

        <div>
          {needsNewLocation && (
            <>
              <Label token={'pages:projects.drawer.changeCustomer.newLocation'} />
              <Dropdown
                error={!location}
                selection
                options={newLocationOptions}
                selectOnBlur={false}
                value={location || ''}
                onChange={(_e, {value}) => {
                  setLocation((value as string) || null);
                }}
              />
            </>
          )}
        </div>
      </div>

      <Spacer spacing={30} />

      <div className={'save'}>
        <motion.div animate={controls}>
          <Save
            disabled={
              (project.flowCustomer === customer && project.customerLocation === location) ||
              (needsNewLocation && !location)
            }
            onSave={() => {
              dispatch(patchProject(project.id, {flowCustomer: customer, customerLocation: location}));
              close?.();
            }}
          />
        </motion.div>
      </div>
    </div>
  );
}
