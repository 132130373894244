import {isInternalUserType} from '@octaved/users/src/UserType';
import {BarChart2} from 'lucide-react';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import FlowOutlet from '../../Components/FlowOutlet';
import {MenuRootItem} from '../FlowRoutes';
import {getStandardInspectorRoutes} from './Inspectors/StandardInspector';
import {userTimeBookingsRoute} from './PageRoutes';
import {projectControllingRoutes} from './ProjectControlling';
import {teamAnalysisRoutes} from './TeamAnalysis';

const UserTimeBookings = lazy(() => import('../../Pages/TimeRecordings/Pages/UserTimeBookings/UserTimeBookings'));

const projectControllingRoute = 'projectControlling';
export const teamAnalysisRoute = 'teamAnalysis';

export function getControllingRoutesWithoutStandardInspector(): string[] {
  return [teamAnalysisRoute];
}

export const controllingRoutes: RouteObject[] = [
  {
    children: projectControllingRoutes,
    element: <FlowOutlet />,
    path: `${projectControllingRoute}/*`,
  },
  {
    children: teamAnalysisRoutes,
    element: <FlowOutlet />,
    path: `${teamAnalysisRoute}/*`,
  },
  {
    children: getStandardInspectorRoutes({}),
    element: <UserTimeBookings />,
    path: `${userTimeBookingsRoute}/*`,
  },
];

export const controllingMenu: MenuRootItem = {
  access: ({orgUserType}) => isInternalUserType(orgUserType),
  groupToken: 'pageMenu:controllingGroup',
  icon: BarChart2,
  id: 'controlling',
  items: [
    {
      nameToken: 'pageMenu:projectControlling',
      path: projectControllingRoute,
    },
    {
      access: ({settings: {useTimeTracking}}) => useTimeTracking,
      nameToken: 'pageMenu:teamAnalysis',
      path: teamAnalysisRoute,
    },
    {
      access: ({settings: {useTimeTracking}}) => useTimeTracking,
      nameToken: 'pages:timeRecordings.userTimeBookings',
      path: userTimeBookingsRoute,
    },
  ],
};
