import {ReactElement, useState} from 'react';
import {HStack} from '../../Layout';
import {Popup} from '../../Motion';
import {IconButton} from '../Button';

type CalendarColor =
  | 'darkRed'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'brown'
  | 'green'
  | 'darkGreen'
  | 'darkCyan'
  | 'cyan'
  | 'default'
  | 'darkBlue'
  | 'darkPurple'
  | 'purple'
  | 'pink'
  | 'grey';

interface ColorEntry {
  background: string;
  font: string;
  primary: string;
}

const calendarColorMap: Record<CalendarColor, ColorEntry> = {
  brown: {
    background: 'rgba(142, 84, 42, 0.24)',
    font: '#50301a',
    primary: '#8e562e',
  },
  cyan: {
    background: 'rgba(0, 153, 188, 0.24)',
    font: '#002a33',
    primary: '#0099bc',
  },
  darkBlue: {
    background: 'rgba(0, 39, 180, 0.24)',
    font: '#001e89',
    primary: '#0027b4',
  },
  darkCyan: {
    background: 'rgba(0, 131, 135, 0.24)',
    font: '#02494c',
    primary: '#038387',
  },
  darkGreen: {
    background: 'rgba(12, 124, 12, 0.24)',
    font: '#094509',
    primary: '#107c10',
  },
  darkPurple: {
    background: 'rgba(90, 42, 145, 0.24)',
    font: '#46236e',
    primary: '#5c2e91',
  },
  darkRed: {
    background: 'rgba(164, 33, 39, 0.24)',
    font: '#5c1519',
    primary: '#a4262c',
  },
  default: {
    background: 'rgba(0, 120, 212, 0.24)',
    font: '#004377',
    primary: '#0078d4',
  },
  green: {
    background: 'rgba(0, 204, 106, 0.24)',
    font: '#004e29',
    primary: '#00cc6a',
  },
  grey: {
    background: 'rgba(101, 120, 126, 0.24)',
    font: '#3b4447',
    primary: '#69797e',
  },
  orange: {
    background: 'rgba(247, 94, 5, 0.24)',
    font: '#471d03',
    primary: '#f7630c',
  },
  pink: {
    background: 'rgba(191, 0, 119, 0.24)',
    font: '#6b0043',
    primary: '#bf0077',
  },
  purple: {
    background: 'rgba(176, 64, 194, 0.24)',
    font: '#63276d',
    primary: '#b146c2',
  },
  red: {
    background: 'rgba(209, 46, 50, 0.34)',
    font: '#751d1f',
    primary: '#d13438',
  },
  yellow: {
    background: 'rgba(253, 227, 0, 0.24)',
    font: '#655a00',
    primary: '#fde300',
  },
};

function getCalendarColor(color: CalendarColor): ColorEntry {
  return calendarColorMap[color];
}

const sortedColors: CalendarColor[] = [
  'darkRed',
  'red',
  'orange',
  'yellow',
  'brown',
  'green',
  'darkGreen',
  'darkCyan',
  'cyan',
  'default',
  'darkBlue',
  'darkPurple',
  'purple',
  'pink',
  'grey',
];

interface ColorBubbleProps {
  color: CalendarColor;
}

function ColorBubble({color}: ColorBubbleProps): ReactElement {
  return (
    <div className={'calendarBubble'}>
      <style jsx>{`
        .calendarBubble {
          height: 16px;
          width: 16px;
          background-color: ${getCalendarColor(color).primary};
          border-radius: 8px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}

interface Props {
  color?: CalendarColor;
  setColor: (newColor: CalendarColor) => void;
}

function CalendarColorPicker({color = 'default', setColor}: Props): ReactElement {
  const [open, setOpen] = useState(false);
  return (
    <Popup
      open={open}
      setOpen={setOpen}
      on={'click'}
      position={'top center'}
      trigger={<IconButton size={'sm'} icon={<ColorBubble color={color} />} variant={'ghost'} />}
      content={
        <div className={'body'}>
          <HStack spacing={'4px'} flexWrap={'wrap'}>
            {sortedColors.map((bubbleColor) => (
              <IconButton
                key={bubbleColor}
                onClick={() => {
                  setColor(bubbleColor);
                  setOpen(false);
                }}
                icon={<ColorBubble color={bubbleColor} />}
                size={'xs'}
                variant={'ghost'}
              />
            ))}
          </HStack>

          <style jsx>{`
            .body {
              padding: 8px 14px;
              max-width: 226px;
            }
          `}</style>
        </div>
      }
    />
  );
}

export {CalendarColorPicker, getCalendarColor};
export type {CalendarColor};
