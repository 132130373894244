import Flex from '@octaved/ui-components/src/Flex';
import Spacer from '@octaved/ui-components/src/React/Spacer';
import classNames from 'classnames';
import {CSSProperties, ReactElement, useContext, useMemo} from 'react';
import {Trans} from 'react-i18next';
import css from 'styled-jsx/css';
import {FlowColors} from '../../../Styles/Default';
import {flowStyleContext} from '../../../Styles/StyleContext';
import SvgIcon from '../../SvgIcon';

//#region <styles>
function getStyles(PLACEHOLDER: FlowColors['PLACEHOLDER']): {className: string; styles: JSX.Element} {
  /*language=SCSS*/
  return css.resolve`
    .label {
      color: ${PLACEHOLDER.FONT};
    }

    .column {
      text-align: center;
    }

    .topSpacing {
      padding-top: 50px;
    }
  `;
}

//#endregion

interface Props {
  label: string;
  direction?: 'row' | 'column';
  size?: 'normal' | 'big' | 'small';
  onAlternativeBackground?: boolean;
  topSpacing?: boolean;
  icon?: ReactElement;
  textTransform?: CSSProperties['textTransform'];
}

export default function Placeholder({
  label,
  direction,
  size = 'normal',
  onAlternativeBackground = false,
  topSpacing = false,
  icon,
  textTransform = 'none',
}: Props): ReactElement {
  const textStyle = useMemo<CSSProperties>(() => ({textTransform}), [textTransform]);
  const {
    COLORS: {PLACEHOLDER},
  } = useContext(flowStyleContext);
  const {className, styles} = getStyles(PLACEHOLDER);

  return (
    <Flex flexDirection={direction} className={classNames(className, 'wrapper', size, {topSpacing})}>
      {!icon && (
        <SvgIcon name={'inbox'} placeholder size={size} color={onAlternativeBackground ? 'darkGrey' : 'grey'} />
      )}
      {icon}

      <Spacer spacing={5} />
      <div style={textStyle} className={classNames(className, 'label', direction)}>
        <span>
          <Trans i18nKey={label} />
        </span>
      </div>

      {styles}
    </Flex>
  );
}
