import {LucideIcon} from 'lucide-react';
import React from 'react';
import {cn} from './Hooks';

interface GhostButtonWithHoverTextProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon: LucideIcon;
}

const GhostButtonWithHoverText = React.forwardRef<HTMLButtonElement, GhostButtonWithHoverTextProps>(
  ({children, className, icon: Icon, ...buttonProps}, ref) => {
    return (
      <div className={'group pr-0 transition-all duration-150 hover:pr-2'}>
        <button
          className={cn(
            'flex items-center gap-x-4 rounded-md px-2 py-0.5 text-sm text-black/40 transition-all duration-150 group-hover:gap-x-2 group-hover:bg-slate-800/5',
            className,
          )}
          ref={ref}
          {...buttonProps}
        >
          <Icon className={'h-4 w-4'} strokeWidth={1.5} />
          <div className={'opacity-0 transition-all duration-150 group-hover:opacity-100'}>{children}</div>
        </button>
      </div>
    );
  },
);

GhostButtonWithHoverText.displayName = 'GhostButtonWithHoverText';

export {GhostButtonWithHoverText};
export type {GhostButtonWithHoverTextProps};
