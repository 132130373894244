import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {NodeSearchCondition} from '@octaved/flow/src/EntityInterfaces/NodeSearch';
import {Group, Project, WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {Task} from '@octaved/flow/src/EntityInterfaces/Task';
import {useLoadedNodes, useLoadNodes, useNode} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {isPlanningNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useLoadPlanningsForNodes} from '../../Modules/Planning';

export function useLoadPlanningDependencies(nodeId: Uuid): boolean {
  const node = useNode(nodeId);
  const nodesToLoad = useMemo(() => {
    const nodesToLoad = [nodeId];
    if (isPlanningNode(node)) {
      nodesToLoad.push(
        ...node.planningPredecessors,
        ...node.planningSuccessors,
        ...node.planningLogicalPredecessors,
        ...node.planningLogicalSuccessors,
      );
    }
    return nodesToLoad;
  }, [node, nodeId]);
  const {hasLoadedOnce: hasLoadedNodes} = useLoadNodes(nodesToLoad);
  const {hasLoadedOnce: hasLoadedPlanning} = useLoadPlanningsForNodes(nodesToLoad);
  return hasLoadedNodes && hasLoadedPlanning;
}

const nodeTypesWithSomePlannableThings: NodeSearchCondition = {
  or: [
    ['nodeType', EnumFlowNodeType.VALUE_PROJECT],
    ['nodeType', EnumFlowNodeType.VALUE_GROUP],
    ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE],
    ['nodeType', EnumFlowNodeType.VALUE_TASK],
  ],
};

export type NodeWithPlannableThings = Project | Group | WorkPackage | SubWorkPackage | Task; //PlanningNode or those that have milestones/due dates

export function useLoadPlanningDependenciesForSubtreeMove(nodeId: Uuid): {
  descendants: readonly NodeWithPlannableThings[];
  hasLoaded: boolean;
} {
  const {hasLoadedOnce: hasSearched, nodeIds} = useCombinedNodeSearch({
    and: [nodeTypesWithSomePlannableThings, withDescendants(nodeId, true)],
  });
  const {hasLoadedOnce: hasDescs, nodes: descendants} = useLoadedNodes<NodeWithPlannableThings>(nodeIds);
  const dependencyIds = useMemo(() => {
    const depIds = new Set<Uuid>();
    descendants.forEach((node) => {
      if (isPlanningNode(node)) {
        [...node.planningPredecessors, ...node.planningSuccessors].forEach((id) => depIds.add(id));
      }
    });
    return depIds;
  }, [descendants]);
  const {hasLoadedOnce: hasDeps} = useLoadNodes(dependencyIds);
  const {hasLoadedOnce: hasPlanning} = useLoadPlanningsForNodes(dependencyIds);
  return {descendants, hasLoaded: hasSearched && hasDescs && hasDeps && hasPlanning};
}
