import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {identityLanguageSelector} from '@octaved/identity/src/Selectors/IdentitySelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {DropdownItemProps} from 'semantic-ui-react';
import FlowLabel from '../../Components/Label/FlowLabel/FlowLabel';
import {Label, Labels, TranslatedLabel} from '../../EntityInterfaces/Labels';
import {FlowState} from '../State';
import {getAllAncestorsIdsSelector} from './NodeTreeSelectors';
import {getProjectFolderForNodeSelector} from './ProjectFolderSelectors';

export enum LabelScope {
  projects = 'isActiveForProjects',
  groups = 'isActiveForGroups',
  workPackages = 'isActiveForWorkPackages',
  tasks = 'isActiveForTasks',
  boards = 'isActiveForBoards',
  timeRecords = 'isActiveForTimeRecords',
}

export function getScopeForNodeType(type: EnumFlowNodeType): LabelScope;
export function getScopeForNodeType(type: null): undefined;
export function getScopeForNodeType(type: EnumFlowNodeType | null): LabelScope | undefined {
  if (!type) {
    return;
  }
  if (type === EnumFlowNodeType.VALUE_PROJECT) {
    return LabelScope.projects;
  }
  if (type === EnumFlowNodeType.VALUE_GROUP) {
    return LabelScope.groups;
  }
  if (type === EnumFlowNodeType.VALUE_WORK_PACKAGE || type === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
    return LabelScope.workPackages;
  }
  if (type === EnumFlowNodeType.VALUE_TASK) {
    return LabelScope.tasks;
  }
  throw new Error('node type is not supported');
}

export const labelEntitiesSelector = (state: FlowState): Labels => state.entities.label;
export const labelSelector = createSelector(
  labelEntitiesSelector,
  (labels) =>
    (labelId: Uuid): Label | undefined =>
      labels[labelId],
);
export const labelIdsSelector = createSelector(labelEntitiesSelector, (labelEntities) => Object.keys(labelEntities));

const allLabelsSelector = createSelector(labelEntitiesSelector, (labelEntities) =>
  boolFilter(Object.values(labelEntities)),
);

export const getLabelsForProjectFolderSelector = createSelector(
  labelEntitiesSelector,
  getAllAncestorsIdsSelector,
  (labelEntities, getAllAncestorsIds) =>
    memoize((projectFolderId: Uuid | null | undefined): Label[] => {
      if (projectFolderId) {
        const parentIds = getAllAncestorsIds(projectFolderId, true);
        return boolFilter(Object.values(labelEntities)).filter(({rootFolder}) => parentIds.has(rootFolder));
      }
      return [];
    }),
);

/**
 * @param projectFolderId the project folder id or null for labels in any folder
 * @deprecated use getTranslatedLabelsForSelectionSelector
 */
export const getLabelsForSelectionSelector = createSelector(
  allLabelsSelector,
  getLabelsForProjectFolderSelector,
  (allLabels, getLabelsForProject) =>
    memoize(
      (scopes: LabelScope[], projectFolderId: Uuid | null): Label[] => {
        const labels = projectFolderId ? getLabelsForProject(projectFolderId) : allLabels;
        return labels.filter((label) => label.isActive && scopes.some((scope) => label[scope]));
      },
      (scopes: LabelScope[], projectFolderId: Uuid | null) => `${scopes.join('-')}-${projectFolderId}`,
    ),
);

export const getLabelDropdownOptionsSelector = createSelector(
  allLabelsSelector,
  getLabelsForProjectFolderSelector,
  identityLanguageSelector,
  (allLabels, getLabelsForProject, identityLanguage) =>
    memoize(
      (
        scopes: LabelScope[] | null,
        {projectFolderId, selectedLabelId}: {selectedLabelId?: Uuid | null; projectFolderId?: Uuid | null} = {},
      ): (DropdownItemProps & {value: string})[] => {
        const labels = projectFolderId ? getLabelsForProject(projectFolderId) : allLabels;
        return labels
          .filter(
            (label) =>
              (label.isActive && (!scopes || scopes.some((scope) => label[scope]))) || label.id === selectedLabelId,
          )
          .map((label) => ({
            text: <FlowLabel label={label} />,
            translation: label.translations?.[identityLanguage] || label.name,
            value: label.id,
          }))
          .sort((a, b) => a.translation.localeCompare(b.translation));
      },
      (...args) => JSON.stringify(args),
    ),
);

export const showLabelsSelector = createSelector(
  getLabelsForSelectionSelector,
  (getLabelsForSelection) =>
    (projectFolderId: Uuid | null | undefined, scope?: LabelScope | LabelScope[], assignedLabels?: Uuid[]): boolean => {
      if (scope && typeof projectFolderId !== 'undefined') {
        const scopes = Array.isArray(scope) ? scope : [scope];
        const labels = getLabelsForSelection(scopes, projectFolderId);
        return Boolean(labels.length || assignedLabels?.length);
      }
      return false;
    },
);

export const getForcedLabelsForTimeRecordSelector = createSelector(
  getLabelsForSelectionSelector,
  getProjectFolderForNodeSelector,
  (getLabelsFor, getProjectFolderForNode) =>
    memoize((nodeId: Uuid | null | undefined): Uuid[] => {
      const projectFolder = getProjectFolderForNode(nodeId);
      let labelIds: Uuid[] = [];
      if (projectFolder) {
        const labels = getLabelsFor([LabelScope.timeRecords], projectFolder.id);
        labelIds = labels.filter(({autoAssignToTimeRecords}) => autoAssignToTimeRecords).map(({id}) => id);
      }

      return labelIds;
    }),
);

export const getTranslatedSortedLabelsSelector = createSelector(
  labelEntitiesSelector,
  identityLanguageSelector,
  (labels, language): ReadonlyArray<TranslatedLabel> => {
    return boolFilter(Object.values(labels))
      .map((label) => ({...label, translated: label.translations?.[language] || label.name}))
      .sort((a, b) => a.translated.localeCompare(b.translated));
  },
);

export const getTranslatedSortedLabelsByIdsSelector = createSelector(getTranslatedSortedLabelsSelector, (labels) =>
  memoize(
    (labelIds: Uuid[] | null | undefined): ReadonlyArray<TranslatedLabel> => {
      const set = new Set(labelIds || []);
      return labels.filter(({id}) => set.has(id));
    },
    (labelIds) => labelIds?.toSorted().join(','),
  ),
);

export const getTranslatedLabelsForSelectionSelector = createSelector(
  getTranslatedSortedLabelsSelector,
  getAllAncestorsIdsSelector,
  (allLabels, getAllAncestorsIds) =>
    memoize(
      ({
        nodeId,
        selected,
        scope,
      }: {
        nodeId?: Uuid | null; //limits the labels to those available for this node
        scope?: LabelScope | null | ReadonlyArray<LabelScope>; //limits to this scope
        selected?: Uuid | null | ReadonlyArray<Uuid>; //those labels are always kept
      }): Label[] => {
        const selectedIds = new Set(typeof selected === 'string' && selected ? [selected] : selected || []);
        const ancestorNodeIds = nodeId ? getAllAncestorsIds(nodeId, true) : null;
        const scopes: ReadonlyArray<LabelScope> | null = scope ? (Array.isArray(scope) ? scope : [scope]) : null;
        return allLabels.filter(
          (label) =>
            selectedIds.has(label.id) ||
            (label.isActive &&
              (!scopes || scopes.some((scope) => label[scope])) &&
              (!ancestorNodeIds || ancestorNodeIds.has(label.rootFolder))),
        );
      },
      (opts) => JSON.stringify(opts),
    ),
);
