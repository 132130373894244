import Konva from 'konva';
import {Flame} from 'lucide';
import {getHasWarningSelector} from '../../../../Selectors/PlanningWarningSelectors';
import {createStoreSubscription} from '../../../StoreSubscription';
import {BarIcon} from './BarIcon';
import {IconListBarPlugin} from './IconListBarPlugin';

export class FlameIcon extends BarIcon {
  #icon: Konva.Image | null = null;

  init(iconList: IconListBarPlugin): void {
    super.init(iconList);
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getHasWarningSelector(s)(this.nodeId, null),
        (status) => {
          this.#updateIcon(status);
        },
      ),
    );
    this.root.name('opacity');
  }

  #updateIcon(hasWarning: boolean): void {
    this.visible = hasWarning;
    if (!this.#icon && this.visible) {
      this.#createIcon().then((icon) => {
        this.#icon = icon;
        this.root.add(icon);
      });
    }
    this.notifyIconList();
  }

  #createIcon(): Promise<Konva.Image> {
    const y = this.getIconY();
    return this.iconToImage(Flame, {
      height: this.height - 1,
      imageConfig: {
        y,
      },
      style: {
        fill: 'red',
        stroke: 'white',
      },
      width: this.height - 1,
    });
  }
}
