import {BoardPostType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {InlineFiles, VersionedFile} from '@octaved/media-files/src/File/MediaFile';
import {Uuid} from '@octaved/typescript/src/lib';
import {SlimUnit} from '@octaved/users/src/EntityInterfaces/UnitLists';
import {RawDraftContentState} from 'draft-js';
import {LinkPreviewData} from '../Components/LinkPreview/LinkPreview';
import {NodeEntity} from './NodeEntity';

export interface BoardPostComment {
  answers: BoardPostComment[];
  comment: RawDraftContentState;
  commentBy: Uuid;
  commentOn: number;
  id: Uuid;
  inlineFiles?: InlineFiles;
  linkList?: string[];
}

export interface BoardPostWithVersionedFiles extends BaseBoardPost {
  data: {
    files: VersionedFile[];
  };
}

export interface BoardPostApprovalQuestionQuestion {
  askedBy: Uuid;
  askedOn: number;
  forUnits: SlimUnit[];
  inlineFiles?: InlineFiles;
  linkList?: string[];
  linkPreviews?: Record<string, LinkPreviewData>;
  question: RawDraftContentState | null;
}

export interface BoardPostApprovalQuestionAnswer {
  answer: RawDraftContentState | null;
  answeredBy: Uuid;
  answeredOn: number;
  id: Uuid;
  inlineFiles?: InlineFiles;
  linkList?: string[];
  linkPreviews?: Record<string, LinkPreviewData>;
}

export interface BoardPostApprovalAnswer extends BoardPostApprovalQuestionAnswer {
  approvalResponse: 'granted' | 'denied' | 'abstain';
}

export interface BaseBoardPostData {
  inlineFiles?: InlineFiles;
}

export interface BaseBoardPost extends NodeEntity {
  boardPostType: BoardPostType;
  data: object;
  isPersonal: boolean;
  lastAnswerBy: Uuid | null;
  lastAnswerOn: number | null; //unix
  lastCommentBy: Uuid | null;
  lastCommentOn: number | null; //unix
  lastContentChangeBy: Uuid;
  lastContentChangeOn: number; //unix
  nodeType: EnumFlowNodeType.VALUE_BOARD_POST;
  sortOrder: number;
}

export interface BoardPostWithComment extends BaseBoardPost {
  data: BaseBoardPostData & {
    comments?: BoardPostComment[];
  };
}

export interface BoardPostApproval extends BaseBoardPost {
  boardPostType: BoardPostType.approval;
  data: BaseBoardPostData & {
    question: BoardPostApprovalQuestionQuestion;
    answers: BoardPostApprovalAnswer[];
  };
}

export interface BoardPostChangeRequest extends BoardPostWithComment {
  boardPostType: BoardPostType.changeRequest;
  data: BaseBoardPostData & {
    text: RawDraftContentState | null;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
    comments?: BoardPostComment[];
  };
}

export interface BoardPostConcept extends BoardPostWithComment {
  boardPostType: BoardPostType.concept;
  data: BaseBoardPostData & {
    text: RawDraftContentState | null;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
    comments?: BoardPostComment[];
  };
}

export interface BoardPostDocumentation extends BoardPostWithComment {
  boardPostType: BoardPostType.documentation;
  data: BaseBoardPostData & {
    text: RawDraftContentState | null;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
    comments?: BoardPostComment[];
  };
}

export interface BoardPostFile extends BoardPostWithComment {
  boardPostType: BoardPostType.file;
  data: {
    files: VersionedFile[];
    comments?: BoardPostComment[];
  };
}

export interface BoardPostFYI extends BaseBoardPost {
  boardPostType: BoardPostType.fyi;
  data: BaseBoardPostData & {
    forUnits: SlimUnit[];
    information: RawDraftContentState | null;
    postedBy: Uuid;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
  };
}

export interface BoardPostImage extends BoardPostWithComment {
  boardPostType: BoardPostType.image;
  data: BaseBoardPostData & {
    files: VersionedFile[];
    comments?: BoardPostComment[];
  };
}

export interface BoardPostMeetingReport extends BoardPostWithComment {
  boardPostType: BoardPostType.meetingReport;
  data: BaseBoardPostData & {
    date: string;
    location: string;
    participants: string;
    text: RawDraftContentState | null;
    time: string;
    topic: string;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
    comments?: BoardPostComment[];
  };
}

export interface BoardPostPhoneNote extends BoardPostWithComment {
  boardPostType: BoardPostType.phoneNote;
  data: BaseBoardPostData & {
    date: string;
    participants: string;
    text: RawDraftContentState | null;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
    comments?: BoardPostComment[];
  };
}

export interface BoardPostQuestion extends BaseBoardPost {
  boardPostType: BoardPostType.question;
  data: {
    question: BoardPostApprovalQuestionQuestion;
    answers: BoardPostApprovalQuestionAnswer[];
  };
}

export interface BoardPostText extends BoardPostWithComment {
  boardPostType: BoardPostType.text;
  data: BaseBoardPostData & {
    text: RawDraftContentState | null;
    linkList?: string[];
    linkPreviews?: Record<string, LinkPreviewData>;
    comments?: BoardPostComment[];
  };
}

export type BoardPost =
  | BoardPostApproval
  | BoardPostChangeRequest
  | BoardPostConcept
  | BoardPostDocumentation
  | BoardPostFile
  | BoardPostFYI
  | BoardPostImage
  | BoardPostMeetingReport
  | BoardPostPhoneNote
  | BoardPostQuestion
  | BoardPostText;

export type BoardPostMergedData = BoardPostApproval['data'] &
  BoardPostChangeRequest['data'] &
  BoardPostConcept['data'] &
  BoardPostDocumentation['data'] &
  BoardPostFile['data'] &
  BoardPostFYI['data'] &
  BoardPostImage['data'] &
  BoardPostMeetingReport['data'] &
  BoardPostPhoneNote['data'] &
  BoardPostQuestion['data'] &
  BoardPostText['data'];

export type BoardPostWithAnswers = Extract<BoardPost, {data: {answers: BoardPostApprovalAnswer[]}}>;

export function isDataWithAnswers(data: BoardPost['data']): data is BoardPostWithAnswers['data'] {
  return 'answers' in data;
}

export type BoardPostWithComments = Extract<BoardPost, {data: {comments?: BoardPostComment[]}}>;

export function isDataWithComments(data: BoardPost['data']): data is BoardPostWithComments['data'] {
  return 'comments' in data;
}
