import {EntityStates, isOutdated} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import {AnyCondition} from '@octaved/utilities/src/Condition/Types';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {FlowState} from '../State';

export type TimeRecordSearchIdent =
  | 'customerId'
  | 'date'
  | 'dateRange'
  | 'isBilled'
  | 'isJourney'
  | 'isOpen'
  | 'orgUserId'
  | 'rootNodeId' // value: {nodeId}
  | 'userGroupId'
  | 'referenceNodeId' // value: referenceNodeId or "null" for searching time records without reference node
  | 'workPackageId'; // value: workPackageId or "null" for searching time records without work package
export type TimeRecordSearchTuple = [TimeRecordSearchIdent] | [TimeRecordSearchIdent, string];
export type TimeRecordSearchCondition = AnyCondition<TimeRecordSearchTuple>;

export const timeRecordSearchSelector = (state: FlowState): FlowState['timeRecordSearch'] => state.timeRecordSearch;
export const timeRecordSearchStateSelector = (state: FlowState): EntityStates => state.timeRecordSearchState;

export function getTimeRecordSearchKey(ident: TimeRecordSearchIdent, value?: string): string {
  return value ? `${ident}-${value}` : ident;
}

export function getIsLoadedSelector(state: FlowState): (ident: TimeRecordSearchIdent, value?: string) => boolean {
  return (ident: TimeRecordSearchIdent, value?: string) => {
    const key = getTimeRecordSearchKey(ident, value);
    return !isOutdated(timeRecordSearchStateSelector(state)[key] || {});
  };
}

const emptyIds: Uuid[] = [];

export const getTimeRecordsInDateRangeSelector = createSelector(timeRecordSearchSelector, (search) =>
  memoize((dateRange: string) => search[getTimeRecordSearchKey('dateRange', dateRange)] || emptyIds),
);

export const getUserTimeRecordsSelector = createSelector(timeRecordSearchSelector, (search) =>
  memoize((userId: Uuid) => search[getTimeRecordSearchKey('orgUserId', userId)] || emptyIds),
);
