import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../../../EntityInterfaces/Pid';
import {useLoadedNodes} from '../../../../Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '../../../../Modules/Hooks/NodeSearch';
import {getNodeSelector} from '../../../../Modules/Selectors/NodeSelectors';
import {getSelectablePriceCategoriesSelector} from '../../../../Modules/Selectors/PriceCategorySelectors';
import {isPid} from '../../../../Node/NodeIdentifiers';

function getPriceCategories(workPackages: WorkPackage[]): Uuid[] {
  const priceCategories = new Set<Uuid>();
  for (const workPackage of workPackages) {
    if (workPackage.priceCategory) {
      priceCategories.add(workPackage.priceCategory);
    }
  }
  return [...priceCategories];
}

export function useCheckPriceCategories(movedPidId: Uuid): (droppedPidId: Uuid | null) => boolean {
  const {nodeIds: descendantWpIds} = useCombinedNodeSearch({
    and: [withDescendants(movedPidId, true), ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE]],
  });
  const {nodes: descendantWps} = useLoadedNodes<WorkPackage>(descendantWpIds);
  const getNode = useSelector(getNodeSelector);
  const priceCategories = getPriceCategories(descendantWps);
  const pidPriceCategory = useSelector(getSelectablePriceCategoriesSelector);

  return function checkPriceCategories(droppedParentPidId: Uuid | null): boolean {
    const parentNode = getNode(droppedParentPidId);
    if (parentNode && isPid(parentNode)) {
      const allowedPriceCategories = pidPriceCategory(parentNode.flowCustomer, null);
      const allowedPriceCategoryIds = new Set(allowedPriceCategories.map(({id}) => id));
      const notAllowedPriceCategories = priceCategories.filter((id) => !allowedPriceCategoryIds.has(id));
      return !notAllowedPriceCategories.length;
    }
    return false;
  };
}
