import {searchBillingsByIdentBulk} from '@octaved/flow-api/config/routes';
import {createUseCombinedSearch, SearchResult} from '@octaved/hooks/src/CombinedSearch';
import {post} from '@octaved/network/src/Network';
import {createTimestampReducer, EntityStates, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  concatReducers,
  createSearchKeyReducerFactory,
  reduceRemoveIdFromResults,
  UuidSearchResults,
} from '@octaved/utilities/src/Search/SearchReducers';
import {debounce} from 'lodash';
import {
  BillingSearchCondition,
  BillingSearchIdent,
  BillingSearchTuple,
} from '../../EntityInterfaces/Billing/BillingSearch';
import {FLOW_DELETE_BILLING_REQUEST, FLOW_SEARCH_BILLING_START, FLOW_SEARCH_BILLING_SUCCESS} from '../ActionTypes';
import {BillingPatchedEvent, BillingRemovedEvent, CustomerPatchedEvent, ProjectPatchedEvent} from '../Events';
import {billingSearchSelector, billingSearchStateSelector} from '../Selectors/Billing/BillingSearchSelectors';
import {FlowState} from '../State';

interface LoadSearchSuccessAction {
  keys: string[];
  result: Record<string, string[]>;
  type: typeof FLOW_SEARCH_BILLING_SUCCESS;
}

export const billingSearchResultsReducerMap = new Map();
export const billingSearchResultsReducer = ReduceFromMap(billingSearchResultsReducerMap);

addMultiToReducerMap(
  billingSearchResultsReducerMap,
  ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_REQUEST],
  (state: UuidSearchResults, {billingId}: BillingRemovedEvent) => reduceRemoveIdFromResults(state, billingId),
);

billingSearchResultsReducerMap.set(
  FLOW_SEARCH_BILLING_SUCCESS,
  (state: UuidSearchResults, {result}: LoadSearchSuccessAction): UuidSearchResults => ({...state, ...result}),
);

const clearAllReducer = (): EntityStates => ({});

const createReducer = createSearchKeyReducerFactory<BillingSearchIdent>();

export const billingSearchStateReducerMap = new Map();
billingSearchStateReducerMap.set('flow.BillingCreatedEvent', clearAllReducer);

const reduceBillingPatched = createReducer<BillingPatchedEvent, 'patchedProperties'>('patchedProperties');
billingSearchStateReducerMap.set(
  'flow.BillingPatchedEvent',
  concatReducers([
    reduceBillingPatched(['erpReference'], ['erpReference']),
    reduceBillingPatched(['erpStatus'], ['erpStatus']),
    reduceBillingPatched(['internalComment'], ['internalComment']),
    reduceBillingPatched(['workflowStatus'], ['workflowStatus']),
  ]),
);

const reduceCustomerPatched = createReducer<CustomerPatchedEvent, 'patchedProperties'>('patchedProperties');
billingSearchStateReducerMap.set(
  'flow.CustomerPatchedEvent',
  concatReducers([reduceCustomerPatched(['customerName'], ['name'])]),
);

const reduceNodePatched = createReducer<ProjectPatchedEvent, 'patchedKeys'>('patchedKeys');
addMultiToReducerMap(
  billingSearchStateReducerMap,
  'flow.ProjectPatchedEvent',
  concatReducers([
    reduceNodePatched(['customerId', 'customerName'], ['flowCustomer']),
    reduceNodePatched(['projectName'], ['name']),
  ]),
);

billingSearchStateReducerMap.set(FLOW_SEARCH_BILLING_START, createTimestampReducer('keys', LOADING));
billingSearchStateReducerMap.set(FLOW_SEARCH_BILLING_SUCCESS, createTimestampReducer('keys', LOADED));

export const billingSearchStateReducer = ReduceFromMap(billingSearchStateReducerMap);

interface SearchRequest {
  key: string;
  ident: string;
  value: string;
}

const searchDebounceStack = new Map<string, SearchRequest>();
const searchDebounced = debounce(async (dispatch: Dispatch) => {
  const jobs = [...searchDebounceStack.values()];
  searchDebounceStack.clear();
  const result = await post<Record<string, string[]>>(searchBillingsByIdentBulk, {data: jobs});
  dispatch({type: FLOW_SEARCH_BILLING_SUCCESS, keys: Object.keys(result), result} satisfies LoadSearchSuccessAction);
}, 5);

export function getBillingSearchKey<I extends BillingSearchIdent>(ident: I, value?: string): string {
  return value ? `${ident}-${value}` : ident;
}

function searchBillings(
  searches: ReadonlyArray<[ident: BillingSearchIdent, value?: string]>,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const keysToLoad: string[] = [];
    searches.forEach(([ident, value]) => {
      const key = getBillingSearchKey(ident, value);
      if (isOutdated(billingSearchStateSelector(getState())[key] || {})) {
        keysToLoad.push(key);
        searchDebounceStack.set(key, {key, ident, value: value || ''});
        searchDebounced(dispatch);
      }
    });
    if (keysToLoad.length) {
      dispatch({keys: keysToLoad, type: FLOW_SEARCH_BILLING_START});
    }
  };
}

export const useBillingSearches = createUseCombinedSearch<Uuid, BillingSearchTuple, FlowState>(
  getBillingSearchKey,
  billingSearchSelector,
  billingSearchStateSelector,
  searchBillings,
);

export function useBillingSearch(query: BillingSearchCondition | null): SearchResult<Uuid> {
  return useBillingSearches({}, query)[0];
}
