import cs from 'classnames';
import {ReactElement} from 'react';

export interface StatusCircleProps {
  color: 'red' | 'darkRed' | 'orange' | 'green' | 'blue' | 'grey' | 'brightGrey' | 'white' | 'darkBlue';
  size?: 'small';
  square?: boolean;
  type?: 'circle' | 'donut';
}

export default function StatusCircle({color, type = 'circle', size, square}: StatusCircleProps): ReactElement {
  return (
    <div className={cs('statusCircle', `color-${color}`, ` type-${type}`, `size-${size}`, {square})}>
      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .statusCircle {
          height: 14px;
          width: 14px;
          border-radius: 0.4375rem;
          border: 2px solid;
          flex-shrink: 0;

          &.size-small {
            height: 0.65rem;
            width: 0.65rem;
            border: 1px solid;
          }

          &.color-red {
            background-color: #fe4d58;
            border-color: #fe4d58;
          }

          &.color-darkRed {
            background-color: #cc0000;
            border-color: #cc0000;
          }

          &.color-orange {
            background-color: #ed9121;
            border-color: #ed9121;
          }

          &.color-green {
            background-color: #3cb371;
            border-color: #3cb371;
          }

          &.color-blue {
            background-color: #6699cc;
            border-color: #6699cc;
          }

          &.color-grey {
            background-color: #666666;
            border-color: #666666;
          }

          &.color-brightGrey {
            background-color: #aaaaaa;
            border-color: #aaaaaa;
          }

          &.color-white {
            background-color: #fff;
            border-color: #fff;
          }

          &.color-darkBlue {
            background-color: #005ab4;
            border-color: #005ab4;
          }

          &.type-donut {
            background-color: transparent;
          }

          &.square {
            border-radius: 0;
          }
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
