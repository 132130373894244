import {ReactElement} from 'react';
import GroupView from '../../../Components/Group/GroupView';
import NodeName from '../../../Components/Node/NodeName';
import WpOrSwpTile from '../../../Components/WorkPackage/Tile/WpOrSwpTile';
import {NodeType} from '../../../EntityInterfaces/Nodes';
import {isGroup, isProject, isSubWorkPackage, isWorkPackage} from '../../../Node/NodeIdentifiers';
import ProjectView from './ProjectView';

interface Props {
  node: NodeType;
  draggable?: boolean;
  isSelectable?: boolean;
}

export default function NodeTile({node, draggable, isSelectable}: Props): ReactElement | null {
  if (isProject(node)) {
    return <ProjectView project={node} />;
  }

  if (isGroup(node)) {
    return (
      <GroupView>
        <NodeName nodeId={node.id} />
      </GroupView>
    );
  }

  if (isWorkPackage(node) || isSubWorkPackage(node)) {
    return <WpOrSwpTile node={node} draggable={draggable} isSelectable={isSelectable} />;
  }

  return null;
}
