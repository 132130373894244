import {cn, IconButton, MenuItem} from '@octaved/ui';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {HelpCircle, MenuIcon} from 'lucide-react';
import {ReactElement, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch} from 'react-router-dom';
import {isItemAccessibleSelector} from '../AccessibleItem';
import BaseFlowAvatar from '../Components/Avatar/BaseFlowAvatar';
import UserName from '../Components/Unit/UserName';
import {pageMenuExpandedGroupsSelector, pageMenuExpandedSelector} from '../Modules/Selectors/UiSelectors';
import {setUiState, toggleExpandedMenuGroups} from '../Modules/Ui';
import {openTourSelection} from '../Onboarding/EntryPoint';
import {MenuItem as RouteMenuItem, MenuRootItem, menuStructure} from '../Routing/FlowRoutes';
import LogoSvg from '../svgs/logo.svg?tsx';
import UserMenuPopup from './Components/UserMenuPopup';
import {resetHelp} from './PageFrame/PageContent/PageContentHelp';
import PinMenu from './PinMenu';

export const PAGE_MENU_WIDTH = 40;
export const EXPANDED_MENU_WIDTH = 220;

export default function PageMenu(): ReactElement {
  const routeMatch = useMatch<'page', string>('/:page/*');
  const isStoreExapnded = useSelector(pageMenuExpandedSelector);
  const expandedGroups = useSelector(pageMenuExpandedGroupsSelector);
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const dispatch = useDispatch();
  const [isHoveringExpanded, setIsHoveringExpanded] = useState(false);
  const expandedMenu = isStoreExapnded || isHoveringExpanded;
  const isItemAccessible = useSelector(isItemAccessibleSelector);
  const visibleMenu = useMemo(() => {
    return menuStructure.reduce<MenuRootItem[]>((menu, rootMenuItem) => {
      if (!isItemAccessible(rootMenuItem)) {
        return menu;
      }

      const items = rootMenuItem.items.reduce<RouteMenuItem[]>((subMenu, menuItem) => {
        if (!menuItem.disabled && isItemAccessible(menuItem)) {
          subMenu.push(menuItem);
        }
        return subMenu;
      }, []);

      if (items.length) {
        menu.push({
          ...rootMenuItem,
          items,
        });
      }

      return menu;
    }, []);
  }, [isItemAccessible]);

  return (
    <div
      className={cn(
        'flex h-full w-10 flex-shrink-0 cursor-pointer flex-col items-stretch overflow-hidden border-r border-r-slate-200 bg-white text-sm text-slate-400 transition-all',
        expandedMenu && 'w-[220px]',
      )}
      onClick={() => !expandedMenu && setIsHoveringExpanded(true)}
      onMouseLeave={() => setIsHoveringExpanded(false)}
    >
      <div className={cn('flex-shrink-0 pb-2.5 pl-1 pr-2.5 pt-2.5 transition-all', expandedMenu && 'pl-5')}>
        <div className={'flex h-[70px] items-center justify-between'}>
          <div className={'flex-shrink-0'}>
            <LogoSvg />
          </div>
          <PinMenu />
        </div>
      </div>

      <div
        className={cn(
          'absolute top-[70px] flex w-10 items-center gap-x-2.5 px-1.5 py-2 transition-all',
          expandedMenu && 'pointer-events-none pl-3.5 opacity-0',
        )}
        onMouseEnter={() => setIsHoveringExpanded(true)}
      >
        <IconButton
          icon={<MenuIcon />}
          variant={'ghost'}
          iconSize={'bigger'}
          size={'sm'}
          iconColor={'darkGrey'}
          onClick={() => dispatch(setUiState({pageMenu: {isExpanded: !isStoreExapnded}}))}
        />
      </div>

      <div
        className={cn(
          'pointer-events-none flex flex-grow flex-col items-stretch gap-x-5 overflow-y-auto overflow-x-hidden pt-1 opacity-0 transition-opacity',
          expandedMenu && 'pointer-events-auto opacity-100',
        )}
      >
        <div className={'flex flex-grow flex-col items-stretch gap-y-1'}>
          {visibleMenu.map((rootItem) => {
            const {icon, id, groupToken, items, testId} = rootItem;
            return (
              <MenuItem
                testId={testId}
                key={id}
                icon={icon}
                label={groupToken}
                isGroup
                isExpanded={expandedGroups.includes(id)}
                onClick={() => dispatch(toggleExpandedMenuGroups(id))}
              >
                <>
                  {items.map((item) => (
                    <MenuItem
                      isGroupChild
                      key={item.path}
                      label={item.nameToken}
                      path={`/${item.path}`}
                      testId={item.testId}
                    />
                  ))}
                </>
              </MenuItem>
            );
          })}
        </div>

        <div>
          <MenuItem
            icon={HelpCircle}
            label={'pageMenu:help'}
            isGroup
            isExpanded={expandedGroups.includes('help')}
            onClick={() => dispatch(toggleExpandedMenuGroups('help'))}
          >
            <>
              <MenuItem label={'pageHelp:overview'} onClick={openTourSelection} />
              {routeMatch && <MenuItem label={'pageHelp:showHelpForPage'} onClick={() => dispatch(resetHelp())} />}
            </>
          </MenuItem>

          <UserMenuPopup>
            <MenuItem className={'pl-3.5'}>
              <div className={'flex items-center gap-x-2 overflow-hidden'}>
                <div className={'flex-shrink-0'}>
                  <BaseFlowAvatar id={currentUserId} type={SimpleUnitType.user} size={'small'} />
                </div>
                <div className={'truncate'}>
                  <UserName userId={currentUserId} noYou />
                </div>
              </div>
            </MenuItem>
          </UserMenuPopup>
        </div>
      </div>
    </div>
  );
}
