import {FlowState} from '@octaved/flow/src/Modules/State';
import {createPromise} from '@octaved/utilities';
import {Selector} from 'reselect';
import {getState, subscribe} from './Store';

export function awaitSelector<R>(selector: Selector<FlowState, R | null | undefined>): Promise<R>;
export function awaitSelector<T, R extends T>(selector: Selector<FlowState, T>, isLoaded: (v: T) => v is R): Promise<R>;
export function awaitSelector<T, R extends T>(
  selector: Selector<FlowState, T>,
  isLoaded: (v: T) => v is R = (v): v is R => v !== null && v !== undefined,
): Promise<R> {
  const [promise, resolve] = createPromise<R>();
  const v = selector(getState());
  if (isLoaded(v)) {
    resolve(v);
  } else {
    const unsubscribe = subscribe(() => {
      const v = selector(getState());
      if (isLoaded(v)) {
        resolve(v);
        unsubscribe();
      }
    });
  }
  return promise;
}
