import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import {Uuid} from '@octaved/typescript/src/lib';
import TextInput from '@octaved/ui-components/src/React/Formik/TextInput';
import VerticalForm from '@octaved/ui-components/src/React/Formik/VerticalForm';
import {useFormik} from 'formik';
import {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updatePlanningBaseline} from '../../../Modules/PlanningBaselines';
import {getPlanningBaselineSelector} from '../../../Selectors/PlanningBaselineSelectors';
import {useBaselineSchema} from './BaselineSchema';

interface RenameDialogProps {
  close: () => void;
  baselineId: Uuid;
  rootNodeId: Uuid;
}

export default function RenameDialog({baselineId, close, rootNodeId}: RenameDialogProps): ReactElement {
  const dispatch = useDispatch();
  const baseline = useSelector(getPlanningBaselineSelector)(rootNodeId, baselineId)!;
  const validationSchema = useBaselineSchema();

  const formik = useFormik({
    validationSchema,
    initialValues: {...baseline, option: 'current'},
    onSubmit: async (values) => {
      close();
      dispatch(updatePlanningBaseline(baselineId, {name: values.name}));
    },
  });

  return (
    <DialogFrame noOwnForm size={'mini'}>
      <DialogTitle growTitle onClose={close}>
        <DialogAndDrawerHeader header={'pages:planning.baselineComparison.rename'} noPadding />
      </DialogTitle>
      <DialogContent>
        <VerticalForm
          submitLabel={'pages:planning.baselineComparison.rename'}
          formik={formik}
          submitButtonColor={'blue'}
        >
          <TextInput label={'pages:planning.baselineComparison.name'} formik={formik} id={'name'} />
        </VerticalForm>
      </DialogContent>
    </DialogFrame>
  );
}
