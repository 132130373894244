import {HelpPopup, Tooltip} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

export default function Footer(): ReactElement {
  const {t} = useTranslation();
  return (
    <div className={'relative flex flex-shrink-0 justify-end gap-x-4 border-t border-solid px-5 py-2.5'}>
      <div className={'absolute inset-0 flex items-center justify-center gap-x-4'}>
        <div className={'flex items-center gap-x-1'}>
          <div className={'h-2 w-2 rounded-full bg-zinc-500'}></div>
          {t('pages:planning.baselineComparison.footer.legendGray')}
        </div>
        <div className={'flex items-center gap-x-1'}>
          <div className={'h-2 w-2 rounded-full bg-emerald-500'}></div>
          {t('pages:planning.baselineComparison.footer.legendGreen')}
        </div>
        <Tooltip toolTipTranslation={'pages:planning.baselineComparison.footer.legendRedTooltip'}>
          <div className={'flex items-center gap-x-1'}>
            <div className={'h-2 w-2 rounded-full bg-red-500'}></div>
            {t('pages:planning.baselineComparison.footer.legendRed')}
          </div>
        </Tooltip>
      </div>

      <div className={'relative z-[1] flex items-center gap-x-2 text-sm text-slate-500'}>
        {t('pages:planning.baselineComparison.footer.help')}
        <HelpPopup content={t('pages:planning.baselineComparison.footer.helpInfo')} />
      </div>
    </div>
  );
}
