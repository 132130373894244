import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {withAncestors} from '@octaved/node-search/src/Factories/Tree';
import {AdvancedBillingInPeriod} from '../../../../EntityInterfaces/Filter/Custom/BillingInPeriod';
import {ProjectFilterQueries, ProjectFilterStates} from '../../../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition} from '../../../../EntityInterfaces/NodeSearch';
import {hourlyBillableTypesCondition} from '../../../../WorkPackage/BillingType';
import {Settings} from '../../../Settings';

export function advancedBillingInPeriodQueryGenerator(
  queries: ProjectFilterQueries,
  {onlyProjectsWithNonBilledWorkPackagesInPeriod}: AdvancedBillingInPeriod,
  _settings: Settings,
  {quickBillingInPeriod}: Partial<ProjectFilterStates>,
): void {
  //Customer must always be billable:
  queries.projects.push(['customerIsBillable']);

  const {from, to} = quickBillingInPeriod!.value.period;
  const conditions: NodeSearchCondition[] = [
    hourlyBillableTypesCondition,
    {
      and: [
        ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE],
        withAncestors(['trRefHasTimeTrackingRecordsInDateRange', `${from}-${to}`], true),
      ],
    },
  ];
  if (onlyProjectsWithNonBilledWorkPackagesInPeriod) {
    conditions.push({not: ['wpHasBillingsInPeriod', `${from}-${to}`]});
  }
  queries.descendants.push({and: conditions});
}
