import {UserType} from '@octaved/env/src/dbalEnumTypes';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {Uuid} from '@octaved/typescript/src/lib';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FullUnit, SlimUnit} from '../EntityInterfaces/UnitLists';
import {useLoadedGroups} from '../Modules/Group';
import {loadUserDisplayNames} from '../Modules/OrgUser';
import {loadUnitList, splitUnitIds} from '../Modules/UnitLists';
import {groupsSelector} from '../Selectors/GroupSelectors';
import {
  isUnitListLoadedSelector,
  sortFullUnitsGroupsFirst,
  unitListsSelector,
  unitListsStatesSelector,
} from '../Selectors/UnitListSelectors';
import {userEntitiesSelector} from '../Selectors/UserSelectors';
import {
  extendedToSimpleUnitType,
  extendedToUserTypes,
  ExtendedUnitType,
  SimpleFromExtendedUnitType,
  SimpleUnitType,
} from '../UnitType';

export interface UnitListsOptions<UT extends ExtendedUnitType = ExtendedUnitType> {
  excludedUnitIds?: ReadonlySet<Uuid> | ReadonlyArray<Uuid>;
  excludeSystemControlledGroups?: boolean;
  extraUnits?: ReadonlyArray<FullUnit<SimpleFromExtendedUnitType<UT>>>;
  filterGuestUserByCustomerId?: Uuid | null;
  includedUnitIds?: ReadonlySet<Uuid> | ReadonlyArray<Uuid>;
  noAutoLoad?: boolean;
}

export function useUnitLists<UT extends ExtendedUnitType = ExtendedUnitType>(
  unitTypes: ReadonlyArray<UT>,
  {
    excludeSystemControlledGroups,
    excludedUnitIds,
    extraUnits,
    filterGuestUserByCustomerId,
    includedUnitIds,
    noAutoLoad,
  }: UnitListsOptions<UT> = {},
): {
  isLoaded: boolean;
  entries: FullUnit<SimpleFromExtendedUnitType<UT>>[];
  doLoad: () => void;
} {
  type SUT = SimpleFromExtendedUnitType<UT>;
  const isUnitListLoaded = useSelector(isUnitListLoadedSelector);
  const unitLists = useSelector(unitListsSelector);
  const userGroups = useSelector(groupsSelector);
  const orgUsers = useSelector(userEntitiesSelector);

  const simpleTypes = useMemo(() => [...new Set(unitTypes.map(extendedToSimpleUnitType))], [unitTypes]);

  const entries = useMemo(() => {
    let units = simpleTypes.reduce<FullUnit<SUT>[]>((acc, simpleType) => {
      let unitIds = unitLists && unitLists[simpleType];
      if (!unitIds) {
        return acc;
      }

      if (excludedUnitIds) {
        const excludedUnitIdsSet = new Set(excludedUnitIds);
        unitIds = unitIds.filter((id) => !excludedUnitIdsSet.has(id));
      }

      if (includedUnitIds) {
        const includedUnitIdsSet = new Set(includedUnitIds);
        unitIds = unitIds.filter((id) => includedUnitIdsSet.has(id));
      }

      if (simpleType === SimpleUnitType.group) {
        unitIds.forEach((id) => {
          const group = userGroups[id];
          if (group && (!excludeSystemControlledGroups || !group.isSystemControlled)) {
            acc.push({unitId: id, unitType: SimpleUnitType.group as SUT, unitName: group.name});
          }
        });
      } else if (simpleType === SimpleUnitType.user) {
        const userTypes = new Set(extendedToUserTypes(unitTypes));
        unitIds.forEach((id) => {
          const orgUser = orgUsers[id];
          if (
            orgUser &&
            userTypes.has(orgUser.type) &&
            (!filterGuestUserByCustomerId ||
              orgUser.type !== UserType.guestCustomer ||
              orgUser.customerId === filterGuestUserByCustomerId)
          ) {
            acc.push({unitId: id, unitType: SimpleUnitType.user as SUT, unitName: orgUser.name});
          }
        });
      }

      return acc;
    }, []);

    if (extraUnits) {
      const mergeMap = new Map<string, FullUnit<SUT>>();
      units.forEach((unit) => mergeMap.set(unit.unitId, unit));
      extraUnits.forEach((unit) => mergeMap.set(unit.unitId, unit));
      units = [...mergeMap.values()];
    }

    return units.sort((a, b) => a.unitName.localeCompare(b.unitName));
  }, [
    excludeSystemControlledGroups,
    excludedUnitIds,
    extraUnits,
    filterGuestUserByCustomerId,
    includedUnitIds,
    orgUsers,
    simpleTypes,
    unitLists,
    unitTypes,
    userGroups,
  ]);

  const [keepLoading, setKeepLoading] = useState(!noAutoLoad);
  useStoreEffect(
    (dispatch) => {
      if (keepLoading) {
        simpleTypes.forEach((unitType) => dispatch(loadUnitList(unitType)));
      }
    },
    [keepLoading, simpleTypes],
    unitListsStatesSelector,
  );

  useEffect(() => {
    if (!noAutoLoad) {
      setKeepLoading(true);
    }
  }, [noAutoLoad]);

  return {
    entries,
    doLoad: useCallback(() => setKeepLoading(true), []),
    isLoaded: useMemo(
      () => simpleTypes.every((unitType) => isUnitListLoaded(unitType)),
      [simpleTypes, isUnitListLoaded],
    ),
  };
}

export function isFullUnit<U extends SimpleUnitType>(unit: SlimUnit<U>): unit is FullUnit<U> {
  return unit.hasOwnProperty('unitName');
}

export function useExtendUnitsWithName<U extends SimpleUnitType = SimpleUnitType, I extends SlimUnit<U> = SlimUnit<U>>(
  units: ReadonlyArray<I>,
): Array<I & {unitName: string}> {
  const groups = useSelector(groupsSelector);
  const users = useSelector(userEntitiesSelector);
  const dispatch = useDispatch();

  useLoadedGroups(useMemo(() => splitUnitIds(units.filter((unit) => !isFullUnit(unit))).groupIds, [units]));
  useEffect(() => {
    const {userIds} = splitUnitIds(units.filter((unit) => !isFullUnit(unit)));
    if (userIds.length) {
      dispatch(loadUserDisplayNames(userIds));
    }
  }, [dispatch, units]);
  return useMemo(() => {
    return units
      .map((unit) => ({
        ...unit,
        unitName: (isFullUnit(unit) && unit.unitName) || groups[unit.unitId]?.name || users[unit.unitId]?.name || '',
      }))
      .sort(sortFullUnitsGroupsFirst);
  }, [groups, users, units]);
}
