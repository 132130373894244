import {Circle} from 'lucide-react';
import {Selector} from 'react-redux';
import {createSelector} from 'reselect';
import {FlowState} from '../../../../Modules/State';
import {ThunkAct} from '../../../../Store/Thunk';
import {DropdownPopupItemOptions, DropdownPopupItemType} from './Types';

export function createCircleOption<V>(
  value: V,
  selector: Selector<FlowState, V>,
  patch: (value: V) => void | ThunkAct<void>,
  token: string,
): DropdownPopupItemOptions {
  const iconColor = createSelector(selector, (current) => (current === value ? 'darkGrey' : 'white'));
  return {
    iconColor,
    token,
    icon: <Circle fill={'currentColor'} strokeWidth={0} />,
    iconSize: 'small',
    onClick: () => patch(value),
    type: DropdownPopupItemType.item,
  };
}
