import {Uuid} from '@octaved/typescript/src/lib';
import {HStack, IconButton} from '@octaved/ui';
import {Airplay} from 'lucide-react';
import {ReactElement, RefObject} from 'react';
import {useTranslation} from 'react-i18next';
import Close from '../../../../../../Dialogs/Components/Close';
import DialogAndDrawerHeader from '../../../../../Layout/DialogAndDrawerHeader';
import {useTaskListContext} from '../../../../TaskListContext';
import CellEditPopup from '../Components/CellEditPopup';
import PlanningDates from './PlanningDates';

interface GapsPopupProps {
  id: Uuid;
  contextRef: RefObject<HTMLDivElement>;
  open: boolean;
  onOpenInspector: () => void;
  onToggle: () => void;
}

export default function GapsPopup({contextRef, id, open, onOpenInspector, onToggle}: GapsPopupProps): ReactElement {
  const {showAdvancedPlanning} = useTaskListContext();
  const {t} = useTranslation();
  return (
    <>
      <CellEditPopup
        open={open}
        contextRef={contextRef}
        noYPadding
        minWidth={300}
        footer={
          showAdvancedPlanning && (
            <div className={'popup'}>
              <HStack justifyContent={'flex-end'} width={'100%'}>
                <IconButton
                  variant={'ghost'}
                  icon={<Airplay rotate={'90deg'} />}
                  size={'sm'}
                  onClick={onOpenInspector}
                  iconRight
                  label={'taskList:planning.openGapDetails'}
                />
              </HStack>
            </div>
          )
        }
      >
        <div className={'popup'}>
          <div className={'close'}>
            <Close close={onToggle} />
          </div>
          <div className={'header'}>
            <DialogAndDrawerHeader header={t('taskList:planning.gapDetailsHeadline')} noPadding />
          </div>
          <PlanningDates nodeId={id} />
          <div className={'info'}>{t('taskList:planning.gapDetailsInfo')}</div>
        </div>
      </CellEditPopup>

      <style jsx>{`
        .popup {
          cursor: default;
        }

        .header {
          padding: 10px 12px;
        }

        .info {
          font-size: 12px;
          margin-bottom: 12px;
          margin-top: 12px;
        }

        .close {
          position: absolute;
          right: 2px;
          top: 2px;
        }
      `}</style>
    </>
  );
}
