import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {TimeEffortPriceSumPerBillingType} from '@octaved/flow/src/EntityInterfaces/Statistics/TimeEffortPriceSumsPerBillingType';
import {SubWorkPackage} from '@octaved/flow/src/EntityInterfaces/SubWorkPackage';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {isSubWorkPackage, isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {getEffectiveMaxEffort} from '@octaved/flow/src/WorkPackage/MaxEffort';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

export function useWpOrSwpTimeBooking(node: WorkPackage | SubWorkPackage): {
  billingType: EnumFlowPidBillingType;
  customer: Uuid;
  sum: TimeEffortPriceSumPerBillingType;
} | null {
  const {workPackage} = useNodeAncestry(node.id, true);
  const sum = useMemo<TimeEffortPriceSumPerBillingType>(() => {
    const maxEffort = isSubWorkPackage(node)
      ? (node.maxEffort ?? (workPackage && getEffectiveMaxEffort(workPackage)))
      : getEffectiveMaxEffort(node);
    const billedHours = node.trackedMinutes.billed / 60;
    return {
      billableHours: billedHours,
      billableHoursWithMaxEffort: maxEffort ? billedHours : 0,
      billableHoursWithoutMaxEffort: maxEffort ? 0 : billedHours,
      count: 1,
      countHasMaxEffort: maxEffort ? 1 : 0,
      fixedPrice: workPackage?.fixedPrice || 0,
      maxEffortHours: maxEffort || 0,
      maxEffortPrice: -1, //unused - implement when needed
      minEffortHours: isWorkPackage(workPackage) ? workPackage.effortFrom || 0 : 0,
    };
  }, [node, workPackage]);

  const useTimeTracking = useSelector(useTimeTrackingSelector);
  const canRead = useIsGranted('FLOW_NODE_READ_TRACKED_HOURS', node.id);

  if (!canRead || !useTimeTracking || !workPackage || !workPackage.billingType) {
    return null;
  }

  return {sum, billingType: workPackage.billingType, customer: workPackage.flowCustomer};
}
