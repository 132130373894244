import {Uuid} from '@octaved/typescript/src/lib';
import {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {ProjectTreeOptions} from './ProjectTreeInterfaces';

type Setter = Dispatch<SetStateAction<ReadonlySet<Uuid>>>;

/**
 * Creates a separate pool for the expanded-state during a search
 */
export function useExpandedNodesPools(
  options: ProjectTreeOptions,
  isSearchLoading: boolean,
  searchedNodeIdsTrace: ReadonlySet<Uuid> | null,
): [ReadonlySet<Uuid>, Setter, Setter] {
  //Local pool if none is provided:
  const [localExpandedNodeIds, setLocalExpandedNodeIds] = useState<ReadonlySet<Uuid>>(new Set<Uuid>());
  const expandedNodeIds = options.expandedNodeIds || localExpandedNodeIds;
  const setExpandedNodeIds = options.setExpandedNodeIds || setLocalExpandedNodeIds;
  const searchTerm = options.searchTerm;

  //This is a separate pool of open nodes, stored locally for while the search is active:
  const [searchExpandedNodeIds, setSearchExpandedNodeIds] = useState<ReadonlySet<Uuid>>(new Set<Uuid>());

  //When the search changes, reset the open nodes to the entire found ancestry:
  const searchedNodeIdsTraceRef = useRef(searchedNodeIdsTrace);
  searchedNodeIdsTraceRef.current = searchedNodeIdsTrace;

  const searchResultSetOnceRef = useRef(false);
  useEffect(() => {
    searchResultSetOnceRef.current = false;
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm && !isSearchLoading && searchedNodeIdsTraceRef.current) {
      setSearchExpandedNodeIds((prevSet) => {
        if (searchResultSetOnceRef.current) {
          //When this search term had its result already set once, we merge the expansion together to not lose any
          // manually expanded nodes:
          return new Set([...prevSet, ...searchedNodeIdsTraceRef.current!]);
        } else {
          //Otherwise we reset the expansion:
          searchResultSetOnceRef.current = true;
          return new Set(searchedNodeIdsTraceRef.current);
        }
      });
    }
  }, [isSearchLoading, searchTerm]);

  //When searching, use dedicated pool only:
  const effectiveExpandedNodeIds = searchTerm ? searchExpandedNodeIds : expandedNodeIds;
  const effectiveExpandedNodeIdssRef = useRef(effectiveExpandedNodeIds);
  effectiveExpandedNodeIdssRef.current = effectiveExpandedNodeIds;

  const _setEffectiveExpandedNodeIds = searchTerm ? setSearchExpandedNodeIds : setExpandedNodeIds;
  const setEffectiveExpandedNodeIdsRef = useRef(_setEffectiveExpandedNodeIds);
  setEffectiveExpandedNodeIdsRef.current = _setEffectiveExpandedNodeIds;

  //Modify the active pool when setting:
  const setEffectiveExpandedNodeIds: Setter = useCallback((nodeIds): void => {
    const newExpandedNodeIds = typeof nodeIds === 'function' ? nodeIds(effectiveExpandedNodeIdssRef.current) : nodeIds;
    if (effectiveExpandedNodeIdssRef.current !== newExpandedNodeIds) {
      setEffectiveExpandedNodeIdsRef.current(newExpandedNodeIds);
    }
  }, []);

  return [effectiveExpandedNodeIds, setEffectiveExpandedNodeIds, setSearchExpandedNodeIds];
}
