import {AnyArray, AnyObject, DeepPartial} from '@octaved/typescript/src/lib';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';

interface MergeStatesOptions {
  deleteOnUndefined: boolean;
  explicitUndefines: boolean;
  fieldsToCopy: Set<string>; //fields copied w/o deeper merge; use path for deeper fields; use * for any key
  replaceArrays: boolean;
  skipLastChangedIfNotDirty: boolean; //does not update the object if `lastChanged`/`lastChangedOn` is the only changed
  // property
}

function mergeArrays(targetState: AnyArray, partialState: AnyArray, options: MergeStatesOptions): AnyArray {
  if (options.replaceArrays) {
    return JSON.stringify(targetState) === JSON.stringify(partialState) ? targetState : cloneDeep(partialState);
  }
  let changed = false;
  const newState = [...targetState];
  partialState.forEach((val, index) => {
    if (typeof val !== 'undefined') {
      const newValue = merge(targetState[index], val, options);
      if (newValue !== newState[index]) {
        newState[index] = newValue;
        changed = true;
      }
    }
  });
  return changed ? newState : targetState;
}

function optionsToNextKeyLevel(options: MergeStatesOptions, key: string): MergeStatesOptions {
  const prefix = `${key}.`;
  const prefixLength = prefix.length;
  return {
    ...options,
    fieldsToCopy: new Set(
      [...options.fieldsToCopy]
        .map((field) => (field.startsWith(prefix) ? field.slice(prefixLength) : ''))
        .filter((field) => !!field),
    ),
  };
}

function mergeObjects(targetState: AnyObject, partialState: AnyObject, options: MergeStatesOptions): AnyObject {
  let changed = false;
  let delayLastChangedIfDirty = false;
  const newState = {...targetState};
  Object.entries(partialState).forEach(([key, val]) => {
    if (
      options.skipLastChangedIfNotDirty &&
      (key === 'lastChanged' || key === 'lastChangedOn') &&
      newState[key] !== val
    ) {
      delayLastChangedIfDirty = true;
      return;
    }

    if (options.fieldsToCopy.has('*') || options.fieldsToCopy.has(key)) {
      newState[key] = val;
      changed = true;
      return;
    }

    const newValue = merge(targetState[key], val, optionsToNextKeyLevel(options, key));
    if (newValue === undefined && options.deleteOnUndefined && newState.hasOwnProperty(key)) {
      delete newState[key];
      changed = true;
    } else if (
      newValue !== newState[key] ||
      (newValue === undefined && options.explicitUndefines && !newState.hasOwnProperty(key))
    ) {
      newState[key] = newValue;
      changed = true;
    }
  });

  if (delayLastChangedIfDirty && changed) {
    if (partialState['lastChanged']) {
      newState['lastChanged'] = partialState['lastChanged'];
    }
    if (partialState['lastChangedOn']) {
      newState['lastChangedOn'] = partialState['lastChangedOn'];
    }
  }

  return changed ? newState : targetState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function merge(targetState: any, partialState: any, options: MergeStatesOptions): any {
  if (Array.isArray(partialState)) {
    if (targetState && !Array.isArray(targetState) && !options.replaceArrays) {
      throw new Error('Merging array into non-array!');
    }
    return targetState ? mergeArrays(targetState, partialState, options) : partialState;
  } else if (isPlainObject(partialState)) {
    if (targetState && !isPlainObject(targetState)) {
      throw new Error('Merging object into non-object!');
    }
    return targetState ? mergeObjects(targetState, partialState, options) : partialState;
  } else {
    return partialState;
  }
}

export interface MergeStates<S> {
  (targetState: S, partialState: DeepPartial<S>, options?: Partial<MergeStatesOptions>): S;
}

/**
 * Deep merge of states, creating new objects when merging, keeping untouched deep children.
 *
 * Do NOT use this for deep cloning! This method will keep new object instances from the partialState as is!
 * Use lodash/cloneDeep instead.
 */
export function mergeStates<S>(
  targetState: S,
  partialState: DeepPartial<S>,
  {
    deleteOnUndefined = false,
    explicitUndefines = false,
    fieldsToCopy = new Set(),
    replaceArrays = true,
    skipLastChangedIfNotDirty = false,
  }: Partial<MergeStatesOptions> = {},
): S {
  return merge(targetState, partialState, {
    deleteOnUndefined,
    explicitUndefines,
    fieldsToCopy,
    replaceArrays,
    skipLastChangedIfNotDirty,
  });
}
