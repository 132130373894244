import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {ReactElement} from 'react';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {FlowState} from '../../../Modules/State';
import {isWorkPackage} from '../../../Node/NodeIdentifiers';
import CopyWorkPackageTasks from '../CopyWorkPackageTasks/CopyWorkPackageTasks';
import MoveWorkPackageTasks from '../MoveWorkPackageTasks/MoveWorkPackageTasks';
import {useOnHoverOpenGroupDropRef} from '../TaskListForNode/DragAndDrop/DragItems';
import TaskListForNode from '../TaskListForNode/TaskListForNode';
import TaskListReadonlyContext, {useTaskListReadonlyContext} from '../TaskListReadonlyContext';
import WpOrSwpTaskGroup from './WpOrSwpTaskGroup';
import {usePatchableState} from '@octaved/hooks/src/PatchableState';

interface Props {
  node: WorkPackage | SubWorkPackage;
  select?: () => void;
  selected?: boolean;
  taskFilterQuerySelector?: (state: FlowState) => NodeSearchCondition | null;
}

export default function WpOrSwpGroup({node, select, selected, taskFilterQuerySelector}: Props): ReactElement {
  const patchableState = usePatchableState({openCopyTasks: false, openMoveWorkPackages: false});
  const [state, patch] = patchableState;
  const canManageTasks = useIsGranted('FLOW_NODE_TASK_MANAGE_BASIC', node.id) && !node.isArchived;
  const {readonly} = useTaskListReadonlyContext();
  const openGroupDropRef = useOnHoverOpenGroupDropRef(node.id, readonly || !canManageTasks);
  return (
    <TaskListReadonlyContext canManageTasks={canManageTasks} readonly={readonly}>
      <WpOrSwpTaskGroup dropRef={openGroupDropRef} node={node} select={select} selected={selected}>
        <TaskListForNode nodeId={node.id} taskFilterQuerySelector={taskFilterQuerySelector} />
      </WpOrSwpTaskGroup>

      {isWorkPackage(node) && (
        <CopyWorkPackageTasks
          workPackageId={node.id}
          open={state.openCopyTasks}
          setOpen={(open) => patch({openCopyTasks: open})}
        />
      )}

      {isWorkPackage(node) && (
        <MoveWorkPackageTasks
          workPackageId={node.id}
          open={state.openMoveWorkPackages}
          setOpen={(open) => patch({openMoveWorkPackages: open})}
        />
      )}
    </TaskListReadonlyContext>
  );
}
