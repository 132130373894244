import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {Cell} from '../../../Canvas/Gantt/Table/Cells/Cell';
import {CustomerEntityCustomerCell, NodeEntityCustomerCell} from '../../../Canvas/Gantt/Table/Cells/CustomerCell';
import {DoneCell} from '../../../Canvas/Gantt/Table/Cells/DoneCell';
import {DurationCell} from '../../../Canvas/Gantt/Table/Cells/DurationCell';
import {
  ProjectGroupEffortCell,
  TaskEffortCell,
  WorkPackageEffortCell,
} from '../../../Canvas/Gantt/Table/Cells/EffortCell';
import {ExecutiveCell} from '../../../Canvas/Gantt/Table/Cells/ExecutiveCell';
import {HeaderCell, HeaderCellProps} from '../../../Canvas/Gantt/Table/Cells/HeaderCell';
import {LabelCell} from '../../../Canvas/Gantt/Table/Cells/LabelCell';
import {CustomerMenuCell, PidMenuCell, TaskMenuCell} from '../../../Canvas/Gantt/Table/Cells/MenuCell';
import {CustomerEntityNameCell, ProjectEntityNameCell} from '../../../Canvas/Gantt/Table/Cells/NameCell';
import {NodeCell, NodeCellProps} from '../../../Canvas/Gantt/Table/Cells/NodeCell';
import {PlannedEndCell, PlannedStartCell} from '../../../Canvas/Gantt/Table/Cells/PlanningCells';
import {TypeCell} from '../../../Canvas/Gantt/Table/Cells/TypeCell';
import {CellConfigProps, CellCtr, TableFactory} from '../../../Canvas/Gantt/Table/TableFactory';
import {createStoreSubscription} from '../../../Canvas/StoreSubscription';
import {getSelectedBaselinesSelector} from '../../../Selectors/PlanningBaselineSelectors';
import {GanttComparisonDiffCell} from './Cells/GanttComparisonDiffCell';
import {GanttComparisonHeaderMenuCell} from './Cells/GanttComparisonHeaderMenuCell';
import {GanttComparisonPlannedEndCell, GanttComparisonPlannedStartCell} from './Cells/GanttComparisonPlanningCell';
import {GanttComparisonPlanningTextCellProps} from './Cells/GanttComparisonTextCell';
import {GanttComparisonContext} from './GanttComparisonContext';

export class GanttComparisonTableFactory extends TableFactory<GanttComparisonContext> {
  #columnConfig = new Map<string, number>([
    ['name', 320],
    ['customer', 200],
    ['type', 150],
    ['plannedStart', 106],
    ['plannedEnd', 106],
    ['duration', 80],
    ['executive', 150],
    ['done', 50],
    ['plannedEffort', 200],
    ['labels', 100],
    ['menu', 80],
  ]);

  #baselineColumnConfig = new Map<string, number>([
    ['plannedStart', 106],
    ['plannedEnd', 106],
    ['diff', 80],
  ]);

  #baselineIds: Uuid[] = [];
  #columnNames: string[] = [];

  init(): void {
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (state) => getSelectedBaselinesSelector(state)(this.ctx.rootNodeId),
        (selectedBaselineIds) => {
          this.#baselineIds = selectedBaselineIds.map(({id}) => id);
          this.#columnNames = this.#calculateColumnNames();
          this.ctx.eventEmitter.emit('visibleColumnsChanged', this.ctx.visibleColumns);
        },
      ),
    );
  }

  #calculateColumnNames(): string[] {
    const columnNames = Array.from(this.#columnConfig.keys());
    columnNames.pop(); //remove menu as it should be last
    for (const baselineId of this.#baselineIds) {
      for (const [key] of this.#baselineColumnConfig) {
        columnNames.push(`baseline-${baselineId}-${key}`);
      }
    }
    columnNames.push('menu');
    return columnNames;
  }

  getColumnNames(): string[] {
    return this.#columnNames;
  }

  getColumnWidth(columnName: string): number {
    if (columnName.startsWith('baseline-')) {
      const {subColumnName} = this.#splitBaselineColumnName(columnName);
      return this.#baselineColumnConfig.get(subColumnName) || 0;
    }
    return this.#columnConfig.get(columnName) || 0;
  }

  calculateColumnsWidth(visibleColumns: string[]): number {
    if (visibleColumns.includes('hideAll')) {
      return this.hideAllColumnWidth;
    }
    let width = 0;
    for (const columnName of visibleColumns) {
      if (columnName.startsWith('baseline-')) {
        const {baselineId} = this.#splitBaselineColumnName(columnName);
        if (this.#baselineIds.includes(baselineId)) {
          width += Array.from(this.#baselineColumnConfig.values()).reduce((acc, val) => acc + val, 0);
        }
      } else {
        width += this.#columnConfig.get(columnName) || 0;
      }
    }
    return width;
  }

  isColumnVisible(visibleColumns: string[], columnName: string): boolean {
    if (columnName.startsWith('baseline-')) {
      const {baselineId} = this.#splitBaselineColumnName(columnName);
      return visibleColumns.includes(`baseline-${baselineId}`);
    }
    return visibleColumns.includes(columnName);
  }

  readonly #headerColumnTransTokens = new Map<string, string>([
    ['name', 'pages:planning.ganttChart.name'],
    ['customer', 'general:customer'],
    ['type', 'pages:planning.ganttChart.type'],
    ['plannedStart', 'pages:planning.ganttChart.start'],
    ['plannedEnd', 'pages:planning.ganttChart.end'],
    ['duration', 'pages:planning.ganttChart.duration'],
    ['executive', 'pages:planning.ganttChart.executive'],
    ['plannedEffort', 'pages:planning.ganttChart.plannedEffort'],
    ['labels', 'pages:planning.ganttChart.labels'],
  ]);

  readonly #baselineheaderColumnTransTokens = new Map<string, string>([
    ['plannedStart', 'pages:planning.ganttChart.start'],
    ['plannedEnd', 'pages:planning.ganttChart.end'],
    ['diff', 'pages:planning.ganttChart.diff'],
  ]);

  #splitBaselineColumnName(columnName: string): {baselineId: string; subColumnName: string} {
    const [baselineId, subColumnName = ''] = columnName.split('-').slice(1);
    return {baselineId, subColumnName};
  }

  getHeaderCellRenderClass(cellConfigProps: CellConfigProps, cellProps: Omit<HeaderCellProps, 'token'>): Cell | null {
    if (cellConfigProps.columnName === 'menu') {
      return new GanttComparisonHeaderMenuCell({...cellProps, rootNodeId: this.ctx.rootNodeId});
    }
    if (cellConfigProps.columnName.startsWith('baseline-')) {
      const {subColumnName} = this.#splitBaselineColumnName(cellConfigProps.columnName);
      const token = this.#baselineheaderColumnTransTokens.get(subColumnName);
      if (token) {
        return new HeaderCell({...cellProps, token});
      }
    }
    const token = this.#headerColumnTransTokens.get(cellConfigProps.columnName);
    if (token) {
      return new HeaderCell({...cellProps, token});
    }
    return null;
  }

  readonly #customerCellRenderer = new Map<string, CellCtr>([
    ['name', CustomerEntityNameCell],
    ['customer', CustomerEntityCustomerCell],
    ['menu', CustomerMenuCell],
  ]);

  readonly #commonNodeCellRenderer = new Map<string, CellCtr>([
    ['name', ProjectEntityNameCell],
    ['customer', NodeEntityCustomerCell],
    ['type', TypeCell],
    ['plannedStart', PlannedStartCell],
    ['plannedEnd', PlannedEndCell],
    ['duration', DurationCell],
    ['executive', ExecutiveCell],
    ['labels', LabelCell],
    ['done', DoneCell],
  ]);

  readonly #baselineNodeCellRenderer = new Map<string, new (_: GanttComparisonPlanningTextCellProps) => NodeCell>([
    ['plannedStart', GanttComparisonPlannedStartCell],
    ['plannedEnd', GanttComparisonPlannedEndCell],
    ['diff', GanttComparisonDiffCell],
  ]);

  readonly #specificNodeCellRenderer = new Map<string, Map<string, CellCtr>>([
    [
      EnumFlowNodeType.VALUE_WORK_PACKAGE,
      new Map<string, CellCtr>([
        ['plannedEffort', WorkPackageEffortCell],
        ['menu', PidMenuCell],
      ]),
    ],
    [
      EnumFlowNodeType.VALUE_TASK,
      new Map<string, CellCtr>([
        ['plannedEffort', TaskEffortCell],
        ['menu', TaskMenuCell],
      ]),
    ],
    [
      EnumFlowNodeType.VALUE_GROUP,
      new Map<string, CellCtr>([
        ['plannedEffort', ProjectGroupEffortCell],
        ['menu', PidMenuCell],
      ]),
    ],
    [
      EnumFlowNodeType.VALUE_PROJECT,
      new Map<string, CellCtr>([
        ['plannedEffort', ProjectGroupEffortCell],
        ['menu', PidMenuCell],
      ]),
    ],
  ]);

  getDataCellRenderClass(cellConfigProps: CellConfigProps, cellProps: NodeCellProps): NodeCell | null {
    if (cellProps.node.type === 'customer') {
      const cellCtr = this.#customerCellRenderer.get(cellConfigProps.columnName);
      if (cellCtr) {
        return new cellCtr(cellProps);
      }
    } else {
      if (cellConfigProps.columnName.startsWith('baseline-')) {
        const {subColumnName, baselineId} = this.#splitBaselineColumnName(cellConfigProps.columnName);
        const cellCtr = this.#baselineNodeCellRenderer.get(subColumnName);
        if (cellCtr) {
          return new cellCtr({...cellProps, baselineId});
        }
      }
      const specificCellCtr = this.#specificNodeCellRenderer.get(cellProps.node.type);
      if (specificCellCtr) {
        const cellCtr = specificCellCtr.get(cellConfigProps.columnName);
        if (cellCtr) {
          return new cellCtr(cellProps);
        }
      }
      const cellCtr = this.#commonNodeCellRenderer.get(cellConfigProps.columnName);
      if (cellCtr) {
        return new cellCtr(cellProps);
      }
    }

    return null;
  }
}
