import {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {IconColor, IconSize} from '@octaved/ui';
import {AnchorHTMLAttributes, ReactElement} from 'react';
import {Action} from 'redux';
import {FlowState} from '../../../../Modules/State';
import {ThunkAct} from '../../../../Store/Thunk';

export enum DropdownPopupItemType {
  divider,
  headerDivider,
  item,
  itemWithLink,
  itemWithExternalLink,
  header,
}

interface BaseDropdownPopupItem {
  type: DropdownPopupItemType;
  className?: string;
  hidden?: boolean;
}

interface DropdownPopupDividerItem extends BaseDropdownPopupItem {
  type: DropdownPopupItemType.divider;
}

interface DropdownPopupHeaderDividerItem extends BaseDropdownPopupItem {
  type: DropdownPopupItemType.headerDivider;
  token: string;
}

interface DropdownPopupHeaderItem extends BaseDropdownPopupItem {
  subToken?: ComplexTranslatable;
  token: ComplexTranslatable;
  type: DropdownPopupItemType.header;
}

export interface DropdownPopupItem extends BaseDropdownPopupItem {
  id?: string;
  disabled?: boolean;
  customIcon?: ReactElement;
  icon?: ReactElement;
  iconColor?: IconColor | ((state: FlowState) => IconColor);
  iconSize?: IconSize;
  keyboardShortCut?: string;
  selected?: boolean;
  textColor?: 'red';
  token: ComplexTranslatable;
  type: DropdownPopupItemType.item;
  onMouseDown?: (() => Action | ThunkAct<void>) | (() => void);
  onClick?: (() => Action | ThunkAct<void>) | (() => void);
}

interface DropdownPopupItemWithLink extends BaseDropdownPopupItem {
  id?: string;
  disabled?: boolean;
  customIcon?: ReactElement;
  icon?: ReactElement;
  iconColor?: IconColor | ((state: FlowState) => IconColor);
  iconSize?: IconSize;
  link: string;
  selected?: boolean;
  token: ComplexTranslatable;
  type: DropdownPopupItemType.itemWithLink;
}

interface DropdownPopupItemWithExternalLink extends BaseDropdownPopupItem {
  id?: string;
  disabled?: boolean;
  customIcon?: ReactElement;
  icon?: ReactElement;
  iconColor?: IconColor | ((state: FlowState) => IconColor);
  iconSize?: IconSize;
  link: string;
  linkTarget?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  selected?: boolean;
  token: ComplexTranslatable;
  type: DropdownPopupItemType.itemWithExternalLink;
}

export type DropdownPopupItemOptions =
  | DropdownPopupDividerItem
  | DropdownPopupHeaderDividerItem
  | DropdownPopupHeaderItem
  | DropdownPopupItem
  | DropdownPopupItemWithExternalLink
  | DropdownPopupItemWithLink;

export function isDivider(item: DropdownPopupItemOptions): item is DropdownPopupDividerItem {
  return item.type === DropdownPopupItemType.divider;
}

export function isHeader(item: DropdownPopupItemOptions): item is DropdownPopupHeaderItem {
  return item.type === DropdownPopupItemType.header;
}

export function isHeaderDivider(item: DropdownPopupItemOptions): item is DropdownPopupHeaderDividerItem {
  return item.type === DropdownPopupItemType.headerDivider;
}

export function isItemWithLink(item: DropdownPopupItemOptions): item is DropdownPopupItemWithLink {
  return item.type === DropdownPopupItemType.itemWithLink;
}

export function isItemWithExternalLink(item: DropdownPopupItemOptions): item is DropdownPopupItemWithExternalLink {
  return item.type === DropdownPopupItemType.itemWithExternalLink;
}

export function isDropdownPopupItem(item: DropdownPopupItemOptions): item is DropdownPopupItem {
  return item.type === DropdownPopupItemType.item;
}
