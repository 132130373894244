import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {cn, StopClickPropagation, Tooltip, TWButton} from '@octaved/ui';
import classNames from 'classnames';
import {FileText, StickyNote} from 'lucide-react';
import {ReactElement, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {TaskPatchData} from '../../../../../../EntityInterfaces/Task';
import {useBoardPosts} from '../../../../../../Modules/Hooks/BoardPosts';
import {generateLargeViewUrl} from '../../../../../../Routing/Routes/Node';
import EditNoteDialog from '@octaved/flow/src/Dialogs/EditNoteDialog';

interface Props {
  taskId: string;
  taskName: string;
  description: string;
  patch: (data: Partial<TaskPatchData>) => void;
}

export default function TaskDescription({taskId, description, patch, taskName}: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const hasDescription = description.length > 0;
  const [edit, setEdit] = useState(false);
  const {combined} = useBoardPosts(taskId, {deep: false});
  const hasBoardPosts = combined.length > 0;
  const canReadDescription = useIsGranted('FLOW_NODE_READ_DESCRIPTION', taskId);
  const canReadBoard = useIsGranted('FLOW_NODE_BOARD_POST_READ_BASIC', taskId);

  return (
    <div ref={ref}>
      <StopClickPropagation>
        <div className={'flex items-center'}>
          {canReadBoard && (
            <Tooltip toolTipTranslation={'taskList:note.board'}>
              <Link to={generateLargeViewUrl(EnumFlowNodeType.VALUE_TASK, taskId, 'board')}>
                <TWButton
                  variant={'ghost'}
                  size={'md'}
                  iconOnly
                  className={cn(
                    'onHover hover:bg-slate-200',
                    (hasBoardPosts || edit) && '!pointer-events-auto !visible',
                  )}
                >
                  <FileText
                    className={'size-4'}
                    fill={hasBoardPosts ? 'var(--color-yellow-100)' : 'none'}
                    strokeWidth={1.5}
                  />
                </TWButton>
              </Link>
            </Tooltip>
          )}

          {canReadDescription && (
            <Tooltip toolTipTranslation={'taskList:note.note'}>
              <TWButton
                variant={'ghost'}
                size={'xs'}
                iconOnly
                className={classNames(
                  'onHover hover:bg-slate-200',
                  (hasDescription || edit) && '!pointer-events-auto !visible',
                )}
                onClick={() => setEdit(true)}
              >
                <StickyNote
                  className={'size-4'}
                  fill={hasDescription ? 'var(--color-yellow-100)' : 'none'}
                  strokeWidth={1.5}
                />
              </TWButton>
            </Tooltip>
          )}
        </div>
      </StopClickPropagation>

      {edit && (
        <EditNoteDialog onClose={() => setEdit(false)} description={description} patch={patch} taskName={taskName} />
      )}
    </div>
  );
}
