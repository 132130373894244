import {loadInitializeData} from '@octaved/flow/src/Modules/Initialization';
import {dispatch, getState, onSelectorChange} from '@octaved/store/src/Store';
import {unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {once} from 'lodash';
import {identityJwtExpiresAtSelector} from '../Selectors/IdentitySelectors';

const timeout = 60000;

function check(): void {
  const expiresAt = identityJwtExpiresAtSelector(getState());
  if (expiresAt && expiresAt - 30 * 60 < unix()) {
    dispatch(loadInitializeData());
  }
  setTimeout(check, timeout);
}

const start = once(() => setTimeout(check, timeout));

onSelectorChange(identityJwtExpiresAtSelector, true, (jwt) => jwt && start());
