import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {hasFeature} from '@octaved/env/src/Features';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {canCreateGroupSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {getNodeAncestrySelector, getParentIdSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {projectsSelectedProjectFolderSelector} from '@octaved/flow/src/Modules/Selectors/UiPages/ProjectsSelector';
import {
  getExportProjectDialogRoute,
  getNewGroupDialogRoute,
  getNewProjectDialogRoute,
  getNewSubWorkPackageDialogRoute,
  getNewWorkPackageDialogRoute,
} from '@octaved/flow/src/Routing/Routes/Dialogs';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {Airplay, Download, GitCompare, MoreHorizontal, PlusCircle} from 'lucide';
import {GanttProjectContext} from '../../../../Pages/Gantt/ProjectPlanning/Project/Canvas/GanttProjectContext';
import {simulationModeActiveSelector} from '../../../../Selectors/UiSelectors';
import {HoverRowChangedEvent} from '../../../Calendar/Context/CalendarContext';
import {GhostButton} from '../../../CommonComponents/GhostButton';
import {Menu, MENU_DIVIDER, MenuItem, MenuItems} from '../../../CommonComponents/Menu';
import {createStoreSubscription} from '../../../StoreSubscription';
import {NodeCell} from './NodeCell';
import {setPlanningContextMenu} from '@octaved/planning/src/Modules/Ui';

export abstract class MenuCell extends NodeCell {
  readonly buttonSize = 20;
  protected menu: Menu | null = null;

  init(): void {
    this.root.visible(false);
    this.disposables.push(this.ctx.eventEmitter.on('hoverRowChanged', this.#onHoverRowChanged));
  }

  #onHoverRowChanged = (value: HoverRowChangedEvent | null): void => {
    this.root.visible(value?.nodeId === this.node.id);
  };

  protected createInspectorButton(): GhostButton {
    const imageSize = this.imageSize + 2;
    const y = (this.height - imageSize) * 0.5;
    const button = new GhostButton({
      imageSize,
      buttonSize: this.buttonSize,
      ctx: this.ctx,
      icon: Airplay,
      onClick: () => {
        this.ctx.setInspectorNodeId(this.node.id, this.node.type);
      },
    });
    button.root.rotate(90);
    button.root.y(y);
    this.disposables.push(() => button.dispose());
    return button;
  }

  protected createMenuButton(): GhostButton {
    const button = new GhostButton({
      buttonSize: this.buttonSize,
      ctx: this.ctx,
      icon: MoreHorizontal,
      imageSize: this.imageSize,
      onClick: (e) => {
        const pos = e.target.getAbsolutePosition();
        this.ctx.store.dispatch(
          setPlanningContextMenu({
            nodeId: this.node.id,
            show: true,
            x: pos.x,
            y: pos.y + this.buttonSize,
          }),
        );
      },
    });

    const y = (this.height - this.imageSize) * 0.5;

    button.root.y(y);
    this.disposables.push(() => button.dispose());
    return button;
  }

  protected getMenuItems(): MenuItems {
    return [];
  }
}

export class CustomerMenuCell extends MenuCell {
  #menuButton: GhostButton | null = null;

  init(): void {
    super.init();
    if (this.ctx.showContextMenu) {
      const menuButton = this.createMenuButton();
      this.#menuButton = menuButton;
      this.root.add(menuButton.root);
      menuButton.init();
    }
    this.render();
    this.render.flush();

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => projectsSelectedProjectFolderSelector(store),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => isGrantedSelector(store),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => simulationModeActiveSelector(store),
        () => {
          this.render();
        },
      ),
    );
  }

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;

    if (!isVisble) {
      return;
    }

    this.root.x(x);
    this.#menuButton?.root.x(width - this.buttonSize - this.padding);
    this.#menuButton?.root.visible(false);
  }

  protected getMenuItems(): MenuItem[] {
    const isGranted = isGrantedSelector(this.ctx.store.getState());
    const projectFolderId = projectsSelectedProjectFolderSelector(this.ctx.store.getState());
    const simulationModeActive = simulationModeActiveSelector(this.ctx.store.getState());
    const menuItems: MenuItem[] = [];

    if (!simulationModeActive && isGranted('FLOW_NODE_PID_MANAGE_BASIC', projectFolderId)) {
      menuItems.push({
        icon: PlusCircle,
        iconColor: '#2d2d39',
        onClick: () => {
          this.ctx.navigate(getNewProjectDialogRoute() + `?customerId=${this.node.id}&onFinish=addToProjectPlanning`);
        },
        token: 'general:newProject',
      });
    }

    return menuItems;
  }
}

export class PidMenuCell extends MenuCell {
  #menuButton: GhostButton | null = null;
  #inspectorButton: GhostButton | null = null;

  init(): void {
    super.init();
    if (this.ctx.showContextMenu) {
      const menuButton = this.createMenuButton();
      this.#menuButton = menuButton;
      this.root.add(menuButton.root);
      menuButton.init();
    }

    if (this.ctx.canInspectRow) {
      const inspectorButton = this.createInspectorButton();
      this.#inspectorButton = inspectorButton;
      this.root.add(inspectorButton.root);
      inspectorButton.init();
    }

    this.render();
    this.render.flush();

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (store) => projectsSelectedProjectFolderSelector(store),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => isGrantedSelector(store),
        () => {
          this.render();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (store) => simulationModeActiveSelector(store),
        () => {
          this.render();
        },
      ),
    );
  }

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;

    if (!isVisble) {
      return;
    }

    this.root.x(x);
    this.#inspectorButton?.root.x(width - this.buttonSize);
    this.#menuButton?.root.x(width - this.buttonSize * 3 - this.padding);
    this.#menuButton?.root.visible(this.getMenuItems().length > 0);
  }

  protected getMenuItems(): MenuItems {
    const state = this.ctx.store.getState();
    const isGranted = isGrantedSelector(state);
    const ancestry = getNodeAncestrySelector(state)(this.node.id, true);
    const parent = ancestry.ancestors[1] as NodeType | undefined;
    const projectFolderId = projectsSelectedProjectFolderSelector(state);
    const canAddNodes = this.ctx.canAddNodes();
    const getParentId = getParentIdSelector(state);
    const canCreateGroup = canCreateGroupSelector(state);
    const parentNodeId = getParentId(this.node.id) || '';
    const menuItems: MenuItems = [];

    if (
      canAddNodes &&
      this.node.type === EnumFlowNodeType.VALUE_PROJECT &&
      isGranted('FLOW_NODE_PID_MANAGE_BASIC', projectFolderId)
    ) {
      menuItems.push({
        icon: PlusCircle,
        iconColor: '#2d2d39',
        onClick: () => {
          this.ctx.navigate(
            getNewProjectDialogRoute() +
              `?customerId=${ancestry.project?.flowCustomer || ''}&onFinish=addToProjectPlanning`,
          );
        },
        token: 'general:newProject',
      });
    }
    if (
      canAddNodes &&
      (this.node.type === EnumFlowNodeType.VALUE_PROJECT || this.node.type === EnumFlowNodeType.VALUE_GROUP) &&
      canCreateGroup(this.node.id)
    ) {
      menuItems.push({
        icon: PlusCircle,
        iconColor: '#2d2d39',
        onClick: () => this.ctx.navigate(getNewGroupDialogRoute(this.node.id)),
        token: 'pages:projects.inspector.newGroup',
      });
    }

    if (
      canAddNodes &&
      (this.node.type === EnumFlowNodeType.VALUE_PROJECT ||
        this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE ||
        this.node.type === EnumFlowNodeType.VALUE_GROUP) &&
      isGranted(
        'FLOW_NODE_PID_MANAGE_BASIC',
        this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE ? parentNodeId : this.node.id,
      ) &&
      !(this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE ? parent : ancestry.ancestors[0])?.isArchived
    ) {
      menuItems.push({
        icon: PlusCircle,
        iconColor: '#2d2d39',
        onClick: () => this.ctx.navigate(getNewWorkPackageDialogRoute(this.node.id)),
        token: 'pages:projects.inspector.newWorkPackage',
      });
    }

    if (
      hasFeature('subWorkPackages') &&
      canAddNodes &&
      this.node.type === EnumFlowNodeType.VALUE_WORK_PACKAGE &&
      isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', this.node.id) &&
      !ancestry.ancestors[0]?.isArchived
    ) {
      menuItems.push({
        icon: PlusCircle,
        iconColor: '#2d2d39',
        onClick: () => this.ctx.navigate(getNewSubWorkPackageDialogRoute(this.node.id)),
        token: 'pages:projects.inspector.newSubWorkPackage',
      });
    }

    if (this.node.type === EnumFlowNodeType.VALUE_PROJECT && this.ctx instanceof GanttProjectContext) {
      const ctx = this.ctx;
      if (menuItems.length > 0 && menuItems[menuItems.length - 1] !== MENU_DIVIDER) {
        menuItems.push(MENU_DIVIDER);
      }
      menuItems.push({
        icon: GitCompare,
        iconColor: '#2d2d39',
        onClick: () => ctx.openGanttComparison(this.node.id),
        token: 'pages:planning.inspector.openBaselineComparison',
      });
    }

    if (
      this.node.type === EnumFlowNodeType.VALUE_PROJECT &&
      isGranted('FLOW_NODE_PROJECT_EXPORT', this.node.id, 'allInTree+self')
    ) {
      if (menuItems.length > 0 && menuItems[menuItems.length - 1] !== MENU_DIVIDER) {
        menuItems.push(MENU_DIVIDER);
      }
      menuItems.push({
        icon: Download,
        iconColor: '#2d2d39',
        onClick: () => this.ctx.navigate(getExportProjectDialogRoute(this.node.id)),
        token: 'pages:projects.inspector.header.exportProject',
      });
    }

    return menuItems;
  }
}

export class TaskMenuCell extends MenuCell {
  #inspectorButton: GhostButton | null = null;

  init(): void {
    super.init();
    if (this.ctx.canInspectRow) {
      const inspectorButton = this.createInspectorButton();
      this.#inspectorButton = inspectorButton;
      this.root.add(inspectorButton.root);
      inspectorButton.init();
    }
    this.render();
    this.render.flush();
  }

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;

    if (!isVisble) {
      return;
    }
    this.root.x(x);
    this.#inspectorButton?.root.x(width - this.buttonSize);
  }
}

export class SubWorkPackageMenuCell extends MenuCell {
  #inspectorButton: GhostButton | null = null;
  #menuButton: GhostButton | null = null;

  init(): void {
    super.init();
    if (this.ctx.showContextMenu) {
      const menuButton = this.createMenuButton();
      this.#menuButton = menuButton;
      this.root.add(menuButton.root);
      menuButton.init();
    }

    if (this.ctx.canInspectRow) {
      const inspectorButton = this.createInspectorButton();
      this.#inspectorButton = inspectorButton;
      this.root.add(inspectorButton.root);
      inspectorButton.init();
    }
    this.render();
    this.render.flush();
  }

  protected doRender(): void {
    const {x, width, isVisble} = this.renderProps;

    if (!isVisble) {
      return;
    }
    this.root.x(x);
    this.#inspectorButton?.root.x(width - this.buttonSize);
    this.#menuButton?.root.x(width - this.buttonSize * 3 - this.padding);
  }

  protected getMenuItems(): MenuItems {
    const state = this.ctx.store.getState();
    const isGranted = isGrantedSelector(state);
    const canAddNodes = this.ctx.canAddNodes();
    const menuItems: MenuItems = [];

    if (
      hasFeature('subWorkPackages') &&
      canAddNodes &&
      isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', this.node.id)
    ) {
      menuItems.push({
        icon: PlusCircle,
        iconColor: '#2d2d39',
        onClick: () => this.ctx.navigate(getNewSubWorkPackageDialogRoute(this.node.id)),
        token: 'pages:projects.inspector.newSubWorkPackage',
      });
    }
    return menuItems;
  }
}
