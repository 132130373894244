import {ReactElement} from 'react';
import {BillingTypeComponentProps} from '../Components';
import BilledMax from './BilledMax';
import BilledOnly from './BilledOnly';

export default function BilledFixedPrice(props: BillingTypeComponentProps): ReactElement | null {
  if (props.sum.maxEffortHours) {
    return <BilledMax {...props} />;
  }
  return <BilledOnly {...props} />;
}
