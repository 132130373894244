import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {TranslatedRole} from '../../EntityInterfaces/Role';
import {
  EffectiveRoleAssignment,
  RoleAssignmentOnNode,
} from '../../EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {loadNodeGuestPermissionRoleAssignments} from '../RoleAssignments/NodeGuestPermissionRoleAssignments';
import {loadNodeInternalPermissionRoleAssignments} from '../RoleAssignments/NodeInternalPermissionRoleAssignments';
import {loadNodeProjectRoleAssignments} from '../RoleAssignments/NodeProjectRoleAssignments';
import {
  getEffectiveGuestPermissionRoleAssignmentsSelector,
  getEffectiveInternalPermissionRoleAssignmentsSelector,
  getGuestPermissionRoleAssignmentsLoadedOnceSelector,
  getGuestPermissionRoleAssignmentsLoadedSelector,
  getGuestPermissionRoleAssignmentsOnNodeSelector,
  getInternalPermissionRoleAssignmentsLoadedOnceSelector,
  getInternalPermissionRoleAssignmentsLoadedSelector,
  getInternalPermissionRoleAssignmentsOnNodeSelector,
  nodeGuestPermissionRoleAssignmentsStateSelector,
  nodeInternalPermissionRoleAssignmentsStateSelector,
} from '../Selectors/RoleAssignments/NodePermissionRoleAssignmentSelectors';
import {
  getEffectiveProjectRoleAssignmentsForNodesSelector,
  getProjectRoleAssignmentsLoadedOnceSelector,
  getProjectRoleAssignmentsLoadedSelector,
  getProjectRoleAssignmentsOnNodeSelector,
  nodeProjectRoleAssignmentsStateSelector,
} from '../Selectors/RoleAssignments/NodeProjectRoleAssignmentSelectors';
import {FlowState} from '../State';

export function useLoadNodeRoleAssignmentsOnly(
  type: EnumFlowNodeRoleType,
  isGuestRole: boolean,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
): void {
  useStoreEffect(
    (dispatch) => {
      const nodeIdsArray = [...nodeIds];
      if (type === EnumFlowNodeRoleType.VALUE_PERMISSION) {
        if (isGuestRole) {
          dispatch(loadNodeGuestPermissionRoleAssignments(nodeIdsArray));
        } else {
          dispatch(loadNodeInternalPermissionRoleAssignments(nodeIdsArray));
        }
      } else {
        dispatch(loadNodeProjectRoleAssignments(nodeIdsArray));
      }
    },
    [isGuestRole, nodeIds, type],
    nodeGuestPermissionRoleAssignmentsStateSelector,
    nodeInternalPermissionRoleAssignmentsStateSelector,
    nodeProjectRoleAssignmentsStateSelector,
  );
}

/**
 * @deprecated prefer using useNodeRoleAssignmentsHaveLoadedOnce() to not track each change in `isLoading` state
 */
export function useNodeRoleAssignmentsAreLoaded(
  type: EnumFlowNodeRoleType,
  isGuestRole: boolean,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
): boolean {
  return useSelector((s: FlowState) =>
    (type === EnumFlowNodeRoleType.VALUE_PERMISSION
      ? isGuestRole
        ? getGuestPermissionRoleAssignmentsLoadedSelector
        : getInternalPermissionRoleAssignmentsLoadedSelector
      : getProjectRoleAssignmentsLoadedSelector)(s)([...nodeIds]),
  );
}

export function useNodeRoleAssignmentsHaveLoadedOnce(
  type: EnumFlowNodeRoleType,
  isGuestRole: boolean,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
): boolean {
  return useSelector((s: FlowState) =>
    (type === EnumFlowNodeRoleType.VALUE_PERMISSION
      ? isGuestRole
        ? getGuestPermissionRoleAssignmentsLoadedOnceSelector
        : getInternalPermissionRoleAssignmentsLoadedOnceSelector
      : getProjectRoleAssignmentsLoadedOnceSelector)(s)([...nodeIds]),
  );
}

/**
 * @deprecated you are probably only interested in project roles, so better use useLoadNodeProjectRoleAssignments()
 *    and optionally useNodeProjectRoleAssignmentsLoadedOnce().
 *
 *  Alternatively prefer to use useLoadNodeRoleAssignmentsOnly() and useNodeRoleAssignmentsHaveLoadedOnce() to not
 *  track changes in the `isLoading` state of the entities.
 */
export function useLoadNodeRoleAssignments(
  type: EnumFlowNodeRoleType,
  isGuestRole: boolean,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
): boolean {
  useLoadNodeRoleAssignmentsOnly(type, isGuestRole, nodeIds);
  return useNodeRoleAssignmentsAreLoaded(type, isGuestRole, nodeIds);
}

interface NodeRoleAssignments<RAN extends RoleAssignmentOnNode> {
  assignments: RAN[];
  isLoaded: boolean;
}

/**
 * @internal for the role assignment components! Not for displaying!
 * Use useEffectiveRoleAssignmentsOnNodes() or useEffectiveRoleAssignmentsOnNode() for display!
 */
export function useNodeRoleAssignments<RAN extends RoleAssignmentOnNode>(
  type: EnumFlowNodeRoleType,
  isGuestRole: boolean,
  nodeId: Uuid,
): NodeRoleAssignments<RAN> {
  const nodeIds = useMemo(() => [nodeId], [nodeId]);
  const areRoleAssignmentsLoaded = useLoadNodeRoleAssignments(type, isGuestRole, nodeIds);
  const assignments = useSelector((s: FlowState) =>
    (type === EnumFlowNodeRoleType.VALUE_PERMISSION
      ? isGuestRole
        ? getGuestPermissionRoleAssignmentsOnNodeSelector
        : getInternalPermissionRoleAssignmentsOnNodeSelector
      : getProjectRoleAssignmentsOnNodeSelector)(s)(nodeId),
  );
  const isLoaded = areRoleAssignmentsLoaded;
  return {
    isLoaded,
    assignments: useLoadedValue(!isLoaded, assignments as RAN[]),
  };
}

interface EffectiveRoleAssignmentsOnNodes {
  assignmentsOnNodes: Record<Uuid, EffectiveRoleAssignment[]>;
  isLoading: boolean;
}

export function useEffectiveRoleAssignmentsOnNodes(
  type: EnumFlowNodeRoleType,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
  isGuestRole: boolean,
): EffectiveRoleAssignmentsOnNodes;
export function useEffectiveRoleAssignmentsOnNodes(
  type: EnumFlowNodeRoleType.VALUE_PROJECT,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
): EffectiveRoleAssignmentsOnNodes;
export function useEffectiveRoleAssignmentsOnNodes(
  type: EnumFlowNodeRoleType,
  nodeIds: Readonly<Uuid[]> | ReadonlySet<Uuid>,
  isGuestRole?: boolean,
): EffectiveRoleAssignmentsOnNodes {
  const areRoleAssignmentsLoaded = useLoadNodeRoleAssignments(type, !!isGuestRole, nodeIds);
  const isLoading = !areRoleAssignmentsLoaded;
  const nodeIdsArray = useMemo(() => [...nodeIds].sort(), [nodeIds]);
  const nodeIdsString = useMemo(() => nodeIdsArray.join('-'), [nodeIdsArray]);
  const effectiveRoleAssignments = useSelector((s: FlowState) =>
    (type === EnumFlowNodeRoleType.VALUE_PERMISSION
      ? isGuestRole
        ? getEffectiveGuestPermissionRoleAssignmentsSelector
        : getEffectiveInternalPermissionRoleAssignmentsSelector
      : getEffectiveProjectRoleAssignmentsForNodesSelector)(s)(nodeIdsArray),
  );
  return {
    isLoading,
    assignmentsOnNodes: useLoadedValue(isLoading, effectiveRoleAssignments, nodeIdsString),
  };
}

const empty: EffectiveRoleAssignment[] = [];

interface EffectiveRoleAssignmentsOnNode {
  assignments: EffectiveRoleAssignment[];
  isLoading: boolean;
}

export function useEffectiveRoleAssignmentsOnNode(
  type: EnumFlowNodeRoleType.VALUE_PERMISSION | EnumFlowNodeRoleType,
  nodeId: Uuid | null | undefined,
  isGuestRole: boolean,
): EffectiveRoleAssignmentsOnNode;
export function useEffectiveRoleAssignmentsOnNode(
  type: EnumFlowNodeRoleType.VALUE_PROJECT,
  nodeId: Uuid | null | undefined,
): EffectiveRoleAssignmentsOnNode;
export function useEffectiveRoleAssignmentsOnNode(
  type: EnumFlowNodeRoleType,
  nodeId: Uuid | null | undefined,
  isGuestRole?: boolean,
): EffectiveRoleAssignmentsOnNode {
  const nodeIds = useMemo(() => (nodeId ? [nodeId] : []), [nodeId]);
  const {assignmentsOnNodes, isLoading} = useEffectiveRoleAssignmentsOnNodes(type, nodeIds, !!isGuestRole);
  return {
    isLoading,
    assignments: nodeId ? assignmentsOnNodes[nodeId] : empty,
  };
}

export type GroupedRoles = Array<{assignemnts: EffectiveRoleAssignment[]; role: TranslatedRole}>;

function reduceRoles(): (acc: GroupedRoles, assignemnt: EffectiveRoleAssignment) => GroupedRoles {
  return (acc, assignment) => {
    const roleEntity = assignment.role;
    let item = acc.find(({role}) => role.id === roleEntity.id);
    if (!item) {
      acc.push({assignemnts: [], role: roleEntity});
      item = acc.slice(-1)[0];
    }

    item.assignemnts.push(assignment);
    return acc;
  };
}

export function useNodeGroupedRoleAssisngments(
  type: EnumFlowNodeRoleType.VALUE_PERMISSION | EnumFlowNodeRoleType,
  nodeId: Uuid,
  isGuestRole: boolean,
): GroupedRoles;
export function useNodeGroupedRoleAssisngments(type: EnumFlowNodeRoleType.VALUE_PROJECT, nodeId: Uuid): GroupedRoles;
export function useNodeGroupedRoleAssisngments(
  type: EnumFlowNodeRoleType,
  nodeId: Uuid,
  isGuestRole?: boolean,
): GroupedRoles {
  const {assignments} = useEffectiveRoleAssignmentsOnNode(type, nodeId, !!isGuestRole);
  return useMemo(
    () => assignments.reduce<GroupedRoles>(reduceRoles(), []).sort(({role: {weight: a}}, {role: {weight: b}}) => b - a),
    [assignments],
  );
}

export function useExcludedUnitIdsForAdd(roleType: EnumFlowNodeRoleType, assignments: Array<{unitId: Uuid}>): Uuid[] {
  return useMemo(() => {
    if (roleType === EnumFlowNodeRoleType.VALUE_PERMISSION) {
      return assignments.map(({unitId}) => unitId);
    }
    return []; //project roles do not exclude - units can be assigned multiple times
  }, [assignments, roleType]);
}
