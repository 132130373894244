import {Uuid} from '@octaved/typescript/src/lib';
import {SimpleUnitType} from '@octaved/users/src/UnitType';
import {ReactElement} from 'react';
import BaseFlowAvatar from '../../../../../Components/Avatar/BaseFlowAvatar';
import UserName from '../../../../../Components/Unit/UserName';

export default function Header({userId}: {userId: Uuid}): ReactElement {
  return (
    <div className={'flex items-center gap-x-3'}>
      <BaseFlowAvatar id={userId} type={SimpleUnitType.user} noYMargin />
      <UserName userId={userId} />
    </div>
  );
}
