import SnackbarWrapper from '@octaved/flow/src/Components/Snackbar/SnackbarWrapper';
import {NodeEntity} from '@octaved/flow/src/EntityInterfaces/NodeEntity';
import {renderKeyValueStoreValue} from '@octaved/flow/src/Node/KeyValueStore';
import {Button} from '@octaved/ui';
import {objectEntries} from '@octaved/utilities/src/Object';
import {CheckCircle2, Copy} from 'lucide-react';
import {Fragment, ReactElement} from 'react';
import {Toast, toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Placeholder from '../../PlaceholderText';

export interface NodeKeyValueStoreProps {
  keyValueStore: NodeEntity['keyValueStore'];
}

export default function NodeKeyValueStore({
  frame: Frame,
  keyValueStore,
}: NodeKeyValueStoreProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const entries = objectEntries(keyValueStore);
  return (
    <Frame labelToken={'nodeDetails:field.keyValueStore.label'}>
      {entries.length === 0 && <Placeholder text={'nodeDetails:field.keyValueStore.placeholder'} />}
      {entries.length > 0 && (
        <div
          className={
            'grid max-w-full grid-cols-[max-content_1fr_max-content] items-center gap-x-4 gap-y-1 overflow-hidden'
          }
        >
          <div className={'font-semibold text-slate-600'}>{t('nodeDetails:field.keyValueStore.key')}</div>
          <div className={'font-semibold text-slate-600'}>{t('nodeDetails:field.keyValueStore.value')}</div>
          <div />
          {entries
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => {
              if (value !== null) {
                return (
                  <Fragment key={key}>
                    <div>{key}</div>
                    <div className={'truncate'}>{renderKeyValueStoreValue(value, t)}</div>
                    <Button variant={'ghost'} iconOnly size={'xs'}>
                      <Copy
                        className={'h-4 w-4 cursor-pointer'}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            typeof value === 'object' ? JSON.stringify(value) : value.toLocaleString(),
                          );
                          toast(
                            (toastInstance: Toast) => (
                              <SnackbarWrapper toastId={toastInstance.id} noClose>
                                <span>{t('nodeDetails:field.keyValueStore.copiedValue')}</span>
                              </SnackbarWrapper>
                            ),
                            {
                              duration: 2000,
                              icon: (
                                <CheckCircle2 className={'h-6 w-6 cursor-pointer text-emerald-500'} strokeWidth={1.5} />
                              ),
                            },
                          );
                        }}
                      />
                    </Button>
                  </Fragment>
                );
              }
              return null;
            })}
        </div>
      )}
    </Frame>
  );
}
