import {FlowState} from '@octaved/flow/src/Modules/State';
import {createSelector} from 'reselect';
import {Identity} from '../Interfaces/Identity';

export const identitySelector = (state: FlowState): Identity => state.currentIdentity;

// noinspection FunctionNamingConventionJS
function id<R>(selector: (state: Identity) => R): (rootState: FlowState) => R {
  return (rootState) => selector(identitySelector(rootState));
}

export const identityEmailSelector = id((s) => s.email);
export const identityHiddenOrganizationIdsSelector = id((s): ReadonlyArray<number> => s.hiddenOrganizationIds);
export const identityHiddenOrganizationIdsSetSelector = createSelector(
  identityHiddenOrganizationIdsSelector,
  (ids): ReadonlySet<number> => new Set(ids),
);
export const identityIdSelector = id((s) => s.id);
export const identityIsEmailVerifiedSelector = id((s) => s.isEmailVerified);
export const identityIsLdapUserSelector = id((s) => s.isLdapUser);
export const identityJwtSelector = id((s) => s.jwt);
export const identityJwtExpiresAtSelector = id((s) => s.jwtExpiresAt);
export const identityLanguageSelector = id((s) => s.language);
export const identityNameSelector = id((s) => s.name);
export const identityOrganizationsSelector = id((s) => s.organizations);
export const identityTwoFaTypeSelector = id((s) => s.twoFaType);
export const identityUseDecimalPeriodSelector = id((s) => s.useDecimalPeriod);
