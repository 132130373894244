import {DateStr} from '@octaved/typescript';
import {toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import dayjs from 'dayjs';

export type TimePeriodInterval = 'month' | 'quarter' | 'year' | 'custom';

export interface TimePeriod {
  interval: TimePeriodInterval;
  from: DateStr; //ISO_DATE start of interval
  to: DateStr; //ISO_DATE end of interval
}

export function createDefaultTimePeriod(subMonth = 0): TimePeriod {
  const fromDefault = dayjs().subtract(subMonth, 'month').startOf('month');
  return {
    from: toIsoFormat(fromDefault),
    interval: 'month',
    to: toIsoFormat(fromDefault.endOf('month')),
  };
}
