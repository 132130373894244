import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Group} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useLoadedNodes} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {useCombinedNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {sortNodesByPathSelector} from '@octaved/flow/src/Modules/Selectors/NodeSortSelectors';
import {getDepthSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {Uuid} from '@octaved/typescript/src/lib';
import {FormatDate, FormatDateFormats, SubProjectIcon} from '@octaved/ui';
import {Fragment, ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import Placeholder from '../../PlaceholderText';

export interface NodeSubProjectsDueDatesProps {
  nodeId: Uuid;
}

export default function NodeSubProjectsDueDates({
  frame: Frame,
  nodeId,
}: NodeSubProjectsDueDatesProps & FramedSectionOrTileProps): ReactElement | null {
  const {nodeIds} = useCombinedNodeSearch({
    and: [withDescendants({fixResult: [nodeId]}, false), ['nodeType', EnumFlowNodeType.VALUE_GROUP]],
  });
  const {nodes} = useLoadedNodes(nodeIds);
  const sorted = useSelector((s: FlowState) => sortNodesByPathSelector(s)(nodes)) as Group[];
  const getDepth = useSelector(getDepthSelector);
  const nodeDepth = getDepth(nodeId);

  if (nodeIds.length === 0) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.planning.subProjectsDueDate'}>
      <div className={'grid grid-cols-[fit-content(50%)_1fr] gap-x-4'}>
        {sorted.map((group) => (
          <Fragment key={group.id}>
            <div
              className={'flex items-center gap-x-2 overflow-hidden'}
              style={{paddingLeft: `${(getDepth(group.id) - nodeDepth - 1) * 1.5}rem`}}
            >
              <SubProjectIcon className={'h-4 w-4 flex-shrink-0 text-slate-600'} />
              <div className={'truncate'}>{group.name}</div>
            </div>
            {group.dueDate && <FormatDate date={group.dueDate} format={FormatDateFormats.short_with_year} />}
            {!group.dueDate && <Placeholder text={'nodeDetails:field.planning.projectDueDate.placeholder'} />}
          </Fragment>
        ))}
      </div>
    </Frame>
  );
}
