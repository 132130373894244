import {isDevLocal} from '@octaved/env/src/Environment';
import {info} from '@octaved/env/src/Logger';

const debug = isDevLocal && false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class EventEmitter<EventMap extends Record<string | number | symbol, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  #events: Map<string | number | symbol, Array<(args: any) => void>> = new Map();

  on<E extends keyof EventMap>(event: E, listener: (args: EventMap[E]) => void): () => void {
    if (!this.#events.has(event)) {
      this.#events.set(event, []);
    }

    this.#events.get(event)!.push(listener);
    return () => {
      this.off(event, listener);
    };
  }

  off<E extends keyof EventMap>(event: E, listener: (args: EventMap[E]) => void): void {
    if (this.#events.has(event)) {
      const listeners = this.#events.get(event)!;
      const updatedListeners = listeners.filter((registeredListener) => registeredListener !== listener);

      this.#events.set(event, updatedListeners);
    }
  }

  emit<E extends keyof EventMap>(event: E, args: EventMap[E]): void {
    if (this.#events.has(event)) {
      if (debug) {
        info('event', event, args);
      }
      const listeners = this.#events.get(event)!;
      listeners.forEach((listener) => listener(args));
    }
  }
}
