import ConfirmPopup from '@octaved/flow/src/Components/Dialog/ConfirmPopup';
import {HStack, IconButton} from '@octaved/ui';
import {Trash} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {usePlanningDateInspectorContext} from '../../../../Inspector/PlanningDateInspectorContext';
import {removePlanningDate} from '../../../../Modules/PlanningDates';

export default function PlanningDateFooter(): ReactElement {
  const {selectedEntity, close} = usePlanningDateInspectorContext();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  return (
    <HStack justifyContent={'center'}>
      <ConfirmPopup
        title={'pages:projects.inspector.planningDates.delete.header'}
        content={<span>{t('pages:projects.inspector.planningDates.delete.text')}</span>}
        buttonColorScheme={'delete'}
        buttonLabel={'general:delete'}
        buttonIcon={<Trash />}
        onConfirm={() => {
          dispatch(removePlanningDate(selectedEntity.nodeId, selectedEntity.id));
          close?.();
          return true;
        }}
      >
        <IconButton
          colorScheme={'delete'}
          label={'general:delete'}
          size={'sm'}
          icon={<Trash />}
          variant={'outline'}
          type={'button'}
        />
      </ConfirmPopup>
    </HStack>
  );
}
