import {BillingErpStatus} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';

export interface BillingsListUiState {
  export: {
    csv: {
      type: 'sums' | 'timeSheet';
    };
    timeSheet: {
      advancedIsOpen: boolean;
    };
  };
  quickFilterValues: Partial<{
    customers: readonly Uuid[];
    erpStatus: readonly BillingErpStatus[];
  }>;
}

export const initialBillingsListUiState: BillingsListUiState = {
  export: {
    csv: {
      type: 'timeSheet',
    },
    timeSheet: {
      advancedIsOpen: false,
    },
  },
  quickFilterValues: {},
};
