import {rootFoldersProjectFolderSelector} from '@octaved/flow/src/Modules/Selectors/RootFolderSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {createContext, PropsWithChildren, ReactElement, useCallback, useContext, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

interface Props {
  close: () => void;
  selectFolderId: (id: Uuid | null) => void;
  selectedFolderId: Uuid | null;
}

interface ProjectFolderSelectorContext {
  expand: (nodeId: Uuid, open?: boolean) => void;
  isExpanded: (nodeId: Uuid) => boolean;
  isSelected: (nodeId: Uuid) => boolean;
  select: (nodeId: Uuid) => void;
}

const context = createContext<ProjectFolderSelectorContext>({
  expand: () => undefined,
  isExpanded: () => false,
  isSelected: () => false,
  select: () => undefined,
});

export function useProjectTreeSelectorContext(): ProjectFolderSelectorContext {
  return useContext(context);
}

// noinspection FunctionNamingConventionJS
export function ProjectFolderSelectorContextProvider({
  children,
  close,
  selectFolderId,
  selectedFolderId,
}: PropsWithChildren<Props>): ReactElement {
  const [collapsed, setCollapsed] = useState(() => new Set());

  const expand = useCallback((nodeId: Uuid, open?: boolean) => {
    setCollapsed((cur) => {
      const isOpen = !cur.has(nodeId);
      if (isOpen && open !== true) {
        return new Set(cur).add(nodeId);
      }
      if (!isOpen && open !== false) {
        const next = new Set(cur);
        next.delete(nodeId);
        return next;
      }
      return cur;
    });
  }, []);

  const rootFolderId = useSelector(rootFoldersProjectFolderSelector);

  const ctx = useMemo(
    (): ProjectFolderSelectorContext => ({
      expand,
      isExpanded: (id) => !collapsed.has(id),
      isSelected: (id) => selectedFolderId === id || (selectedFolderId === null && id === rootFolderId),
      select: (id) => {
        selectFolderId(id === rootFolderId ? null : id);
        close();
      },
    }),
    [expand, collapsed, selectedFolderId, selectFolderId, rootFolderId, close],
  );

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
