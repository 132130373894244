import * as routes from '@octaved/flow-api';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityState, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StoreBookedHours, StoreBookedHoursKeys} from '../../EntityInterfaces/Statistics/BookedHours';
import {getStatisticConditionsKey, StatisticConditions} from '../../EntityInterfaces/Statistics/Conditions';
import {
  FLOW_LOAD_BOOKED_HOURS_STATISTIC_FAILURE,
  FLOW_LOAD_BOOKED_HOURS_STATISTIC_REQUEST,
  FLOW_LOAD_BOOKED_HOURS_STATISTIC_SUCCESS,
} from '../ActionTypes';
import {NodeArchivedEvent} from '../Events';
import {statisticsSelector} from '../Selectors/Statistics';
import {FlowState} from '../State';

interface LoadAction {
  dataKey: string;
  statisticsIdent: StoreBookedHoursKeys;
  response: StoreBookedHours[StoreBookedHoursKeys]['data'][string];
  type: typeof FLOW_LOAD_BOOKED_HOURS_STATISTIC_REQUEST | typeof FLOW_LOAD_BOOKED_HOURS_STATISTIC_SUCCESS;
}

export function getBookedHoursDataKey(conditions: StatisticConditions, useBillingTimes: boolean): string {
  return `${+useBillingTimes}+${getStatisticConditionsKey(conditions)}`;
}

const loading = createTimestampReducer('dataKey', LOADING);
const loaded = createTimestampReducer('dataKey', LOADED);

const initialState: StoreBookedHours = {
  bookedHoursByBillingType: {
    data: {},
    state: {},
  },
  bookingUserCounts: {
    data: {},
    state: {},
  },
  dailyAverageUserBookedHours: {
    data: {},
    state: {},
  },
  nonBillableHoursByReasonByDate: {
    data: {},
    state: {},
  },
};

const reducers = createReducerCollection<StoreBookedHours>(initialState);

reducers.add<LoadAction>(FLOW_LOAD_BOOKED_HOURS_STATISTIC_REQUEST, (state, action) => {
  const {statisticsIdent} = action;
  return {
    ...state,
    [statisticsIdent]: {
      ...state[statisticsIdent],
      state: loading(state[statisticsIdent].state, action),
    },
  };
});

reducers.add<LoadAction>(FLOW_LOAD_BOOKED_HOURS_STATISTIC_SUCCESS, (state, action) => {
  const {dataKey, statisticsIdent, response} = action;
  return {
    ...state,
    [statisticsIdent]: {
      data: {
        ...state[statisticsIdent].data,
        [dataKey]: response,
      },
      state: loaded(state[statisticsIdent].state, action),
    },
  };
});

function reduceInvalidateStates(state: StoreBookedHours): StoreBookedHours {
  const idents = Object.keys(initialState) as Array<keyof StoreBookedHours>;
  const newState = {...state};
  idents.forEach((ident) => {
    // @ts-ignore it's the same ident! the types match!
    newState[ident] = {
      ...newState[ident],
      state: {},
    };
  });
  return newState;
}

reducers.add<NodeArchivedEvent>('flow.NodeArchivedEvent', (state) => {
  return reduceInvalidateStates(state);
});

export const bookedHoursReducer = reducers.reducer;

const mappedRoutes = {
  bookedHoursByBillingType: routes.getBookedHoursByBillingType,
  bookingUserCounts: routes.getBookingUserCounts,
  dailyAverageUserBookedHours: routes.getDailyAverageUserBookedHours,
  nonBillableHoursByReasonByDate: routes.getNonBillableHoursByReason,
};

function loadBookedHours(
  statisticsIdent: StoreBookedHoursKeys,
  conditions: StatisticConditions,
  useBillingTimes: boolean,
): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const dataKey = getBookedHoursDataKey(conditions, useBillingTimes);
    const state = statisticsSelector(getState())[statisticsIdent].state[dataKey];
    if (!state || isOutdated(state)) {
      dispatch({
        dataKey,
        statisticsIdent,
        [CALL_API]: {
          endpoint: mappedRoutes[statisticsIdent],
          options: {
            urlParams: {
              ...conditions,
              includeArchived: +conditions.includeArchived,
              useBillingTimes: +useBillingTimes,
            },
          },
          types: {
            failureType: FLOW_LOAD_BOOKED_HOURS_STATISTIC_FAILURE,
            requestType: FLOW_LOAD_BOOKED_HOURS_STATISTIC_REQUEST,
            successType: FLOW_LOAD_BOOKED_HOURS_STATISTIC_SUCCESS,
          },
        },
      });
    }
  };
}

export enum UsedRecordingTimes {
  recorded, //workTimeStart/workTimeEnd
  billing, //billingStart/billingEnd
  both,
}

export function useLoadBookedHoursStatistics(
  statisticsIdent: StoreBookedHoursKeys,
  conditions: StatisticConditions | null, //null if in demo mode
  usedRecordingTimes: UsedRecordingTimes,
): void {
  const dispatch = useDispatch();
  const statistics = useSelector(statisticsSelector)[statisticsIdent];

  const toLoad = useMemo(() => {
    const entries: Array<{state: EntityState | undefined; useBillingTimes: boolean}> = [];

    const pushToLoad = (useBillingTimes: boolean): void => {
      let state: EntityState | undefined;
      if (conditions) {
        const dataKey = getBookedHoursDataKey(conditions, useBillingTimes);
        state = statistics.state[dataKey];
      }
      entries.push({state, useBillingTimes});
    };

    if (usedRecordingTimes === UsedRecordingTimes.recorded || usedRecordingTimes === UsedRecordingTimes.both) {
      pushToLoad(false);
    }

    if (usedRecordingTimes === UsedRecordingTimes.billing || usedRecordingTimes === UsedRecordingTimes.both) {
      pushToLoad(true);
    }
    return entries;
  }, [conditions, statistics, usedRecordingTimes]);

  useEffect(() => {
    if (conditions) {
      toLoad.forEach(({useBillingTimes}) => {
        dispatch(loadBookedHours(statisticsIdent, conditions, useBillingTimes));
      });
    }
  }, [conditions, dispatch, statisticsIdent, toLoad]);
}
