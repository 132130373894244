import {DeepPartial} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {VisibleTasks} from '../../Components/Task/VisibleTasks';
import {ThunkAct} from '../../Store/Thunk';
import {setUiState} from '../Ui';

export interface PersonalTasksUiState {
  visibleTasks: VisibleTasks;
}

export const initialPersonalTasksUiState: PersonalTasksUiState = {
  visibleTasks: 'all',
};

export function patchPersonalTasksUiState(
  personalTasks: DeepPartial<PersonalTasksUiState>,
  force = false,
): ThunkAct<void> {
  return setUiState({pages: {personalTasks}}, force);
}

export function usePatchPersonalTasksUiState(): (patch: DeepPartial<PersonalTasksUiState>, force?: boolean) => void {
  const dispatch = useDispatch();
  return useCallback(
    (personalTasks, force = false) => dispatch(patchPersonalTasksUiState(personalTasks, force)),
    [dispatch],
  );
}
