import {MinMaxPlanningDateInSubtree} from '@octaved/planning/src/EntityInterfaces/MinMaxPlanningDateInSubtrees';
import {HelpTooltip} from '@octaved/ui';
import {ReactElement} from 'react';
import Toggle from '../../Components/Form/Toggle/Toggle';
import {Pid} from '../../EntityInterfaces/Pid';
import {CopyPidOptions} from '../../Modules/CopyPid';
import {isWorkPackage} from '../../Node/NodeIdentifiers';
import CopyPlanning from './CopyPlanning';

export interface CopyDetailsFormProps {
  autoTimeControl: boolean;
  autoTimeControlIsPossible: boolean;
  copyPidOptions: CopyPidOptions;
  initialCopyPlaning?: boolean;
  minMaxPlanning: MinMaxPlanningDateInSubtree | null;
  pid: Pid;
  setAutoTimeControl: (autoTimeControl: boolean) => void;
  setCopyAssignments: (copyAssignments: boolean) => void;
  setCopyTasks: (copyTasks: boolean) => void;
}

export default function CopyDetailsForm({
  autoTimeControl,
  autoTimeControlIsPossible,
  copyPidOptions,
  initialCopyPlaning,
  minMaxPlanning,
  pid,
  setAutoTimeControl,
  setCopyAssignments,
  setCopyTasks,
}: CopyDetailsFormProps): ReactElement {
  return (
    <div className={'flex flex-col gap-y-3'}>
      <Toggle
        checked={Boolean(copyPidOptions.copyTasks)}
        setChecked={setCopyTasks}
        label={'dialogs:duplicatePid.toggle.copyTasks'}
      />
      <div className={'flex items-center gap-x-2'}>
        <Toggle
          checked={Boolean(copyPidOptions.copyAssignments)}
          setChecked={setCopyAssignments}
          label={'dialogs:duplicatePid.toggle.copyAssignments'}
        />
        <HelpTooltip toolTipTranslation={'dialogs:duplicatePid.toggle.copyAssignments.toolTip'} inline />
      </div>

      <CopyPlanning
        copyPidOptions={copyPidOptions}
        initialCopyPlaning={initialCopyPlaning}
        minMaxPlanning={minMaxPlanning}
        sourceNode={pid}
      />

      {!isWorkPackage(pid) && (
        <div className={'flex items-center gap-x-2'}>
          <Toggle
            disabled={!autoTimeControlIsPossible}
            checked={autoTimeControlIsPossible && autoTimeControl}
            setChecked={setAutoTimeControl}
            label={'dialogs:duplicatePid.toggle.adjustTimeControl.label'}
          />
          <HelpTooltip
            toolTipTranslation={
              autoTimeControlIsPossible
                ? 'dialogs:duplicatePid.toggle.adjustTimeControl.toolTip'
                : 'dialogs:duplicatePid.toggle.adjustTimeControl.disabledToolTip'
            }
            inline
          />
        </div>
      )}
    </div>
  );
}
