import {PropsWithChildren, ReactElement} from 'react';

export default function PickerViewWrapper({children}: PropsWithChildren<unknown>): ReactElement {
  return (
    <div>
      {children}

      <style jsx>{`
        div {
          padding: 0.4375rem 0.75rem;
        }
      `}</style>
    </div>
  );
}
