import {useCallback, useContext} from 'react';
import {parsePath, Path, To, UNSAFE_RouteContext} from 'react-router';
import {resolvePath, useLocation} from 'react-router-dom';

function resolveTo(toArg: To, routePathnames: string[], locationPathname: string): Path {
  const to = typeof toArg === 'string' ? parsePath(toArg) : toArg;
  const toPathname = toArg === '' || to.pathname === '' ? '/' : to.pathname;

  // If a pathname is explicitly provided in `to`, it should be relative to the
  // route context. This is explained in `Note on `<Link to>` values` in our
  // migration guide from v5 as a means of disambiguation between `to` values
  // that begin with `/` and those that do not. However, this is problematic for
  // `to` values that do not provide a pathname. `to` can simply be a search or
  // hash string, in which case we should assume that the navigation is relative
  // to the current location's pathname and *not* the route pathname.
  let from: string;
  if (toPathname == null) {
    from = locationPathname;
  } else {
    let routePathnameIndex = routePathnames.length - 1;

    if (toPathname.startsWith('..')) {
      const toSegments = toPathname.split('/');

      // Each leading .. segment means 'go up one route' instead of 'go up one
      // URL segment'.  This is a key difference from how <a href> works and a
      // major reason we call this a 'to' value instead of a 'href'.
      while (toSegments[0] === '..') {
        toSegments.shift();
        routePathnameIndex -= 1;
      }

      to.pathname = toSegments.join('/');
    }

    // If there are more '..' segments than parent routes, resolve relative to
    // the root / URL.
    from = routePathnameIndex >= 0 ? routePathnames[routePathnameIndex] : '/';
  }

  const path = resolvePath(to, from);

  // Ensure the pathname has a trailing slash if the original to value had one.
  if (toPathname && toPathname !== '/' && toPathname.endsWith('/') && !path.pathname.endsWith('/')) {
    path.pathname += '/';
  }

  return path;
}

export function useResolvedPath(): (to: To) => Path {
  const {matches} = useContext(UNSAFE_RouteContext);
  const {pathname: locationPathname} = useLocation();

  const routePathnamesJson = JSON.stringify(matches.map((match) => match.pathnameBase));

  return useCallback(
    (to: To) => resolveTo(to, JSON.parse(routePathnamesJson), locationPathname),
    [routePathnamesJson, locationPathname],
  );
}
