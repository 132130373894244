import {useOnFocusOutside} from '@octaved/hooks/src/OnFocusOutside';
import {useOnOutsideClick} from '@octaved/hooks/src/OnOutsideClick';
import classNames from 'classnames';
import {ReactElement, useCallback, useRef, useState} from 'react';
import {TaskPatchData} from '../../../../../EntityInterfaces/Task';
import HoursDisplay from '../../../HoursDisplay';
import {useTaskListReadonlyContext} from '../../../TaskListReadonlyContext';
import CellActionButton from './Components/CellActionButton';
import CellPlaceholder from './Components/CellPlaceholder';
import TaskCell from './Components/TaskCell';
import CustomHoursPopup from './TimeDurationCell/CustomHoursPopup';
import DefaultOptionsDropdown, {hasCustomTaskDurationHours} from './TimeDurationCell/DefaultOptionsDropdown';

interface Props {
  plannedTime?: string | null;
  patch: (data: Partial<TaskPatchData>) => void;
}

export default function TimeDurationCell({plannedTime, patch}: Props): ReactElement {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const [focused, setFocused] = useState(false);
  const isFocusedRef = useRef(focused);
  isFocusedRef.current = focused;
  const [hasCustomHours, setHasCustomHours] = useState(false);
  const [customHours, setCustomHours] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  const toggleOpen = useCallback(() => {
    if (!readonlyOrNotManageable) {
      if (isFocusedRef.current) {
        if (hasCustomHours) {
          patch({plannedTime: customHours || null});
          setHasCustomHours(!!customHours);
        }
      } else {
        setCustomHours(plannedTime ? plannedTime : '');
        setHasCustomHours(plannedTime ? hasCustomTaskDurationHours(plannedTime) : false);
      }
    }
    setFocused(!isFocusedRef.current);
  }, [customHours, hasCustomHours, patch, plannedTime, readonlyOrNotManageable]);

  useOnOutsideClick(ref, () => {
    if (focused) {
      toggleOpen();
    }
  });

  useOnFocusOutside(ref, () => {
    if (focused) {
      toggleOpen();
    }
  });

  return (
    <TaskCell focused={focused} ref={ref} setFocused={setFocused} hasFocusElement smaller>
      <div className={classNames('duration', {readonly: readonlyOrNotManageable})}>
        {plannedTime && <HoursDisplay hours={plannedTime} showMinutes />}

        {!plannedTime && !hasCustomHours && (
          <CellPlaceholder placeholder={'taskList:effort.noEffort'} tooltip={'taskList:effort.noEffortTooltip'} />
        )}

        {!plannedTime && hasCustomHours && <CellPlaceholder placeholder={'Stunden angeben'} />}
      </div>

      {!readonlyOrNotManageable && (
        <CellActionButton
          focused={focused}
          hasContent={!!plannedTime}
          onToggleFocus={toggleOpen}
          onReset={() => {
            patch({plannedTime: null});
            setCustomHours('');
            setFocused(false);
          }}
          resetInfo={'taskList:effort.removeEffort'}
        />
      )}

      {!readonlyOrNotManageable && focused && (
        <>
          <DefaultOptionsDropdown
            contextRef={ref}
            plannedTime={plannedTime}
            open={focused && !hasCustomHours}
            enableCustomHours={() => setHasCustomHours(true)}
            patch={patch}
          />

          <CustomHoursPopup
            contextRef={ref}
            open={focused && hasCustomHours}
            customHours={customHours}
            setCustomHours={setCustomHours}
            toggleOpen={toggleOpen}
          />
        </>
      )}

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .duration {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .readonly {
          cursor: default;
        }
      `}</style>
      {/*#endregion*/}
    </TaskCell>
  );
}
