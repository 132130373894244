import {AuditPriceCategory} from '../../../../EntityInterfaces/Audit/AuditPriceCategory';
import BooleanCell from '../Table/Cells/BooleanCell';
import {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {historyColumns} from './History';

const config: AuditTableConfiguration<AuditPriceCategory> = {
  columns: [
    ...historyColumns,
    createColumnDef('id', 'systemSettings:audit.tables.idHeader'),
    createColumnDef('name', 'systemSettings:audit.tables.nameHeader'),
    createColumnDef('hourlyRate', 'systemSettings:audit.tables.priceCategory.hourlyRateHeader'),
    createColumnDef('isLocked', 'systemSettings:audit.tables.priceCategory.isLockedRateHeader', BooleanCell),
    createColumnDef('apiKey', 'systemSettings:audit.tables.priceCategory.apiKeyHeader'),
  ],
  tableIdent: 'priceCategories',
  tableNameTranslationToken: 'systemSettings:audit.priceCategoriesTableName',
};
export default config;
