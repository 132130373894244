import {error} from '@octaved/env/src/Logger';
import {ObjectSnapshot, useObjectSnapshot} from '@octaved/hooks';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {post} from '@octaved/network/src/Network';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createFlatTimestampReducer, INVALIDATED, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {mergeStates} from '@octaved/store/src/MergeStates';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {subscribe} from '@octaved/store/src/ReduxTopic';
import {ActionDispatcher, addReducers} from '@octaved/store/src/Store';
import {RuleResult, RulesList, validateHttpUrl, validateLength, validateRules} from '@octaved/store/src/Validation';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter, generateUuid} from '@octaved/utilities';
import {ObjectContains} from '@octaved/validation/src';
import {once} from 'lodash';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import * as routes from '../../config/routes';
import {
  WebhookEvents,
  WebhooksStoreState,
  WebhookSubscription,
  WebhookSubscriptionPatch,
  WebhookSubscriptionsByEvent,
} from '../EntityInterfaces/Webhooks';
import {webhookEventsSelector, webhookStateSelector, webhookSubscriptionsSelector} from '../Selectors/WebhookSelectors';
import {
  WEBSERVICE_INVALIDATE_WEBHOOKS,
  WEBSERVICE_LOAD_WEBHOOKS_FAILURE,
  WEBSERVICE_LOAD_WEBHOOKS_REQUEST,
  WEBSERVICE_LOAD_WEBHOOKS_SUCCESS,
  WEBSERVICE_PATCH_WEBHOOK_FAILURE,
  WEBSERVICE_PATCH_WEBHOOK_REQUEST,
  WEBSERVICE_PATCH_WEBHOOK_SUCCESS,
} from './ActionTypes';

const webhookDefaults = {
  callbackUrl: '',
  data: {},
  verifySsl: true,
};

export enum ResultStatus {
  STATUS_OK,
  STATUS_INVALID_RESPONSE_STATUS,
  STATUS_JSON_ERROR,
  STATUS_JSON_INVALID,
  STATUS_NO_SUBSCRIPTION,
  STATUS_NETWORK_ERROR,
}

const init = once(async () => {
  const eventsReducerMap = new Map();
  eventsReducerMap.set(
    WEBSERVICE_LOAD_WEBHOOKS_SUCCESS,
    (_state: WebhookEvents, {response}: {response: {events: WebhookEvents}}): WebhookEvents => response.events,
  );

  const subsReducerMap = new Map();
  subsReducerMap.set(
    WEBSERVICE_LOAD_WEBHOOKS_SUCCESS,
    (
      _state: WebhookSubscriptionsByEvent,
      {response}: {response: {subscriptions: WebhookSubscriptionsByEvent}},
    ): WebhookSubscriptionsByEvent => response.subscriptions,
  );
  subsReducerMap.set(
    WEBSERVICE_PATCH_WEBHOOK_REQUEST,
    (state: WebhookSubscriptionsByEvent, {patch}: {patch: WebhookSubscriptionsByEvent}): WebhookSubscriptionsByEvent =>
      mergeStates(state, patch),
  );

  const stateReducerMap = new Map();
  stateReducerMap.set(WEBSERVICE_LOAD_WEBHOOKS_REQUEST, createFlatTimestampReducer(LOADING));
  stateReducerMap.set(WEBSERVICE_LOAD_WEBHOOKS_SUCCESS, createFlatTimestampReducer(LOADED));
  stateReducerMap.set(WEBSERVICE_INVALIDATE_WEBHOOKS, createFlatTimestampReducer(INVALIDATED));

  await addReducers({
    webhooks: {
      events: ReduceFromMap(eventsReducerMap),
      state: ReduceFromMap(stateReducerMap),
      subscriptions: ReduceFromMap(subsReducerMap),
    },
  });

  subscribe('webservice.WebhooksPatchedEvent', () => ({type: WEBSERVICE_INVALIDATE_WEBHOOKS}));
});

function loadWebhooks(): ActionDispatcher<void, WebhooksStoreState> {
  return (dispatch, getState) => {
    init();
    if (isOutdated(webhookStateSelector(getState()))) {
      dispatch({
        [CALL_API]: {
          _debounceSimultaneousCalls: true,
          endpoint: routes.getWebhooks,
          types: {
            failureType: WEBSERVICE_LOAD_WEBHOOKS_FAILURE,
            requestType: WEBSERVICE_LOAD_WEBHOOKS_REQUEST,
            successType: WEBSERVICE_LOAD_WEBHOOKS_SUCCESS,
          },
        },
      });
    }
  };
}

function getValidationFields(id: Uuid): string[] {
  return [`webhook_callbackUrl_${id}`];
}

function getValidationRules(id: Uuid, data: WebhookSubscriptionPatch): RulesList {
  const rules: RulesList = [];
  if (typeof data.callbackUrl !== 'undefined') {
    rules.push([
      validateLength,
      data.callbackUrl,
      ['general:The callback URL is too long. The current limit is {LIMIT}.', {limit: 1000}],
      `webhook_callbackUrl_${id}`,
      1000,
    ]);
    rules.push([
      validateHttpUrl,
      data.callbackUrl,
      'general:The callback URL has an invalid format.',
      `webhook_callbackUrl_${id}`,
    ]);
  }
  return rules;
}

export interface WebhookCallResult {
  status: ResultStatus;
  rawResponse: string;
  exception: string | null;
  responseStatusCode: number;
  parsedResponse: Record<string, unknown> | null;
}

export function makeWebhookTestCall(eventName: string, subscriptionId: Uuid): Promise<WebhookCallResult> {
  return post(routes.makeWebhookTestCall, {urlParams: {eventName, subscriptionId}});
}

function upsertWebhook(
  event: string,
  webhookId: Uuid,
  webhook: WebhookSubscriptionPatch,
): ActionDispatcher<RuleResult, WebhooksStoreState> {
  return (dispatch, getState) => {
    const errors = validateRules(getValidationRules(webhookId, webhook));
    if (errors.length) {
      return errors;
    }
    const current = webhookSubscriptionsSelector(getState())[event]?.[webhookId];
    if (!current || !ObjectContains(current, webhook)) {
      //For creations, add the defaults and id:
      const fullPatch = current
        ? webhook
        : mergeStates({...webhookDefaults, id: webhookId} as WebhookSubscription, webhook);
      dispatch({
        [CALL_API]: {
          _debounceSimultaneousCalls: true,
          endpoint: routes.bulkPatchWebhooks,
          method: 'patch',
          options: {data: {[event]: {[webhookId]: webhook}}},
          types: {
            failureType: WEBSERVICE_PATCH_WEBHOOK_FAILURE,
            requestType: WEBSERVICE_PATCH_WEBHOOK_REQUEST,
            successType: WEBSERVICE_PATCH_WEBHOOK_SUCCESS,
          },
        },
        patch: {[event]: {[webhookId]: fullPatch}},
      });
    }
    return [];
  };
}

export function useWebhooks(): {
  events: WebhookEvents;
  subscriptions: WebhookSubscriptionsByEvent;
  upsert: (event: string, webhookId: Uuid, webhook: WebhookSubscriptionPatch) => RuleResult;
} {
  const dispatch = useDispatch<ThunkDispatch<WebhooksStoreState, unknown, Action>>();

  useStoreEffect((disp) => disp(loadWebhooks()), [], webhookStateSelector);

  return {
    events: useSelector(webhookEventsSelector),
    subscriptions: useSelector(webhookSubscriptionsSelector),
    upsert: useCallback(
      (event, webhookId, webhook) => {
        return dispatch(upsertWebhook(event, webhookId, webhook));
      },
      [dispatch],
    ),
  };
}

type Snap = ObjectSnapshot<WebhookSubscription, WebhookSubscription>;

/**
 * Hook to create/edit a webhook event subscriber, which is supposed to be unique.
 */
export function useEditUniqueWebhook(eventName: string): {
  hasChanges: boolean;
  id: Uuid;
  patch: Snap['patch'];
  snapshot: Snap['snapshot'];
  save: () => RuleResult;
  validationFields: string[];
} {
  const {subscriptions, upsert} = useWebhooks();
  const webhook = useMemo((): WebhookSubscription => {
    const hooks = boolFilter(Object.values(subscriptions[eventName] || {}));
    if (hooks.length) {
      if (hooks.length > 1) {
        error(`There are ${hooks.length} webhooks defined for event '${eventName}, expected 1.'`);
      }
      return hooks[0];
    }
    return {
      ...webhookDefaults,
      id: generateUuid(),
    };
  }, [eventName, subscriptions]);

  const webhookRef = useRef(webhook);
  webhookRef.current = webhook;

  const {hasChanges, reset, snapshot, patch, refs} = useObjectSnapshot(() => webhook);

  useEffect(() => {
    //As long as we have no changes made ourselves, keep updating the snapshot
    // noinspection BadExpressionStatementJS
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    webhook; //dependency
    if (!hasChanges) {
      reset(true);
    }
  }, [hasChanges, reset, webhook]);

  return {
    hasChanges,
    patch,
    snapshot,
    id: webhook.id,
    save: useCallback((): RuleResult => {
      //always use the freshest id in case of someone else has just upserted with a new id:
      const result = upsert(eventName, webhookRef.current.id, refs.changeSet.current);
      if (!result.length) {
        reset();
      }
      return result;
    }, [eventName, refs.changeSet, reset, upsert]),
    validationFields: useMemo(() => getValidationFields(webhook.id), [webhook.id]),
  };
}
