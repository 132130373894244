import {getWorkPackageBilling, getWorkPackageBillingInPeriod} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  LOADED,
  LOADING,
  reduceAllHaveLoadedOnce,
} from '@octaved/store/src/EntityState';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {boolFilter} from '@octaved/utilities';
import {chunk} from 'lodash';
import {useSelector} from 'react-redux';
import {WorkPackage} from '../../EntityInterfaces/Pid';
import {
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_DELETE_BILLING_SUCCESS,
  FLOW_LOAD_WORK_PACKAGE_BILLING_FAILURE,
  FLOW_LOAD_WORK_PACKAGE_BILLING_REQUEST,
  FLOW_LOAD_WORK_PACKAGE_BILLING_START,
  FLOW_LOAD_WORK_PACKAGE_BILLING_SUCCESS,
} from '../ActionTypes';
import {ChangePidSuccessEvent, WorkPackagePatchedEvent} from '../Events';
import {
  getWorkPackageBillingStoreKeyMapper,
  workPackageBillingStateSelector,
} from '../Selectors/WorkPackageBillingSelectors';
import {FlowState} from '../State';

function reduceLoadWorkPackageBilling<R extends {workPackageId: Uuid}>(
  state: Record<string, R>,
  {response, storeKeyMapper}: {response: R[]; storeKeyMapper: (workPackageId: Uuid) => string},
): Record<string, R> {
  const newState = {...state};
  response.forEach((record) => {
    newState[storeKeyMapper(record.workPackageId)] = record;
  });
  return newState;
}

function reduceClearStateForWorkPackageIds<K extends string>(actionKey: K) {
  return (state: EntityStates, action: Record<K, Uuid | Uuid[] | null>): EntityStates => {
    const ids = action[actionKey];
    const affectedWorkPackageIds = new Set(boolFilter(Array.isArray(ids) ? ids : [ids]));
    const newState = {...state};
    let changed = false;
    Object.keys(newState).forEach((storeId) => {
      const [wpId] = storeId.split('-');
      if (affectedWorkPackageIds.has(wpId)) {
        delete newState[storeId];
        changed = true;
      }
    });
    return changed ? newState : state;
  };
}

const workPackageBillingReducerMap = new Map();
workPackageBillingReducerMap.set(FLOW_LOAD_WORK_PACKAGE_BILLING_SUCCESS, reduceLoadWorkPackageBilling);
export const workPackageBillingReducer = ReduceFromMap(workPackageBillingReducerMap);

const workPackageBillingStateReducerMap = new Map();
workPackageBillingStateReducerMap.set(
  FLOW_LOAD_WORK_PACKAGE_BILLING_START,
  createTimestampReducer('storeIds', LOADING),
);
workPackageBillingStateReducerMap.set(
  FLOW_LOAD_WORK_PACKAGE_BILLING_SUCCESS,
  createTimestampReducer('storeIds', LOADED),
);
workPackageBillingStateReducerMap.set('flow.BillingCreatedEvent', reduceClearStateForWorkPackageIds('workPackageIds'));
addMultiToReducerMap(
  workPackageBillingStateReducerMap,
  ['flow.BillingRemovedEvent', FLOW_DELETE_BILLING_SUCCESS],
  reduceClearStateForWorkPackageIds('workPackageIds'),
);
addMultiToReducerMap(
  workPackageBillingStateReducerMap,
  ['flow.TimeRecordCreatedEvent', 'flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  reduceClearStateForWorkPackageIds('affectedTimeTrackingNodeIds'),
);
addMultiToReducerMap(
  workPackageBillingStateReducerMap,
  ['flow.TimeRecordsRestoredFromTrashEvent'],
  reduceClearStateForWorkPackageIds('workPackageId'),
);
addMultiToReducerMap(
  workPackageBillingStateReducerMap,
  ['flow.CustomerPatchedEvent', 'flow.PriceCategoryPatchedEvent'],
  () => ({}),
);

const affectingWorkPackageProps: Array<keyof WorkPackage> = ['billingType', 'fixedPrice', 'priceCategory'];
addMultiToReducerMap(
  workPackageBillingStateReducerMap,
  ['flow.WorkPackagePatchedEvent', FLOW_CHANGE_PID_SUCCESS],
  (state: EntityStates, action: WorkPackagePatchedEvent | ChangePidSuccessEvent): EntityStates => {
    const patchedKeys = new Set(action.patchedKeys);
    if (affectingWorkPackageProps.some((prop) => patchedKeys.has(prop))) {
      const nodeId = (action as WorkPackagePatchedEvent).nodeId || (action as ChangePidSuccessEvent).patchedNodeId;
      const nodeIds = Array.isArray(nodeId) ? nodeId : [nodeId];
      return reduceClearStateForWorkPackageIds('nodeIds')(state, {nodeIds});
    }
    return state;
  },
);
export const workPackageBillingStateReducer = ReduceFromMap(workPackageBillingStateReducerMap);

function loadWorkPackageBilling(workPackageIds: Uuid[], from = '', to = ''): ActionDispatcher<void, FlowState> {
  const storeKeyMapper = getWorkPackageBillingStoreKeyMapper(from, to);
  const endpoint = from ? getWorkPackageBillingInPeriod : getWorkPackageBilling;
  const urlParams = from ? {from, to} : {};
  return (dispatch, getState) => {
    const state = workPackageBillingStateSelector(getState());
    const toLoad = filterIdsToReload(state, workPackageIds, null, storeKeyMapper);
    if (toLoad.length) {
      dispatch({storeIds: toLoad.map(storeKeyMapper), type: FLOW_LOAD_WORK_PACKAGE_BILLING_START});
      const chunks = chunk(toLoad, 100);
      for (const ids of chunks) {
        dispatch({
          storeKeyMapper,
          [CALL_API]: {
            endpoint,
            options: {
              urlParams: {...urlParams, ids},
            },
            types: {
              failureType: FLOW_LOAD_WORK_PACKAGE_BILLING_FAILURE,
              requestType: FLOW_LOAD_WORK_PACKAGE_BILLING_REQUEST,
              successType: FLOW_LOAD_WORK_PACKAGE_BILLING_SUCCESS,
            },
          },
          storeIds: ids.map(storeKeyMapper),
        });
      }
    }
  };
}

export function useLoadWorkPackageBilling(workPackageIds: Uuid[], from = '', to = ''): boolean {
  useStoreEffect(
    (dispatch) => dispatch(loadWorkPackageBilling(workPackageIds, from, to)),
    [from, to, workPackageIds],
    workPackageBillingStateSelector,
  );

  //Has loaded once
  return useSelector((s: FlowState) => {
    const storeKeyMapper = getWorkPackageBillingStoreKeyMapper(from, to);
    return reduceAllHaveLoadedOnce(workPackageBillingStateSelector(s), workPackageIds.map(storeKeyMapper))[0];
  });
}
