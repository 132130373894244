import {warning} from '@octaved/env/src/Logger';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  ComponentType,
  createContext,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {TaskCreationData} from '../../EntityInterfaces/Task';
import {TaskListColumn, taskListColumns} from '../../Modules/Ui/TaskList';
import {TaskGroupProps} from './TaskGroup/TaskGroup';
import DragContext, {DragContextReset} from './TaskListForNode/DragAndDrop/DragContext';
import {TaskRowProps} from './TaskListForNode/Task/TaskRow';
import TaskListReadonlyContext, {TaskListReadonlyReset} from './TaskListReadonlyContext';

interface ExtraProps {
  readonly: boolean; //forces the readonly state additionally to the permission checks
}

interface OnBeforeCreateTaskMetaData {
  parentId: Uuid;
}

export interface OnBeforeCreateTask {
  (task: TaskCreationData, meta: OnBeforeCreateTaskMetaData): TaskCreationData;
}

interface ConfigurableTaskListCtx {
  allowCreationOfTasks?: boolean;
  allowCreationOfTaskSections?: boolean;
  displayDoneTasksAsOpen?: boolean; //used for preview tasks on copy tasks #482
  EmptyTaskRowBoxPlaceholder?: ComponentType;
  hideEmptyTaskList?: boolean;
  hideEmptyTaskSections?: boolean;
  hideTrackTime?: boolean;
  selectedTaskId?: string | null;
  selectTaskId?: (id: string | null) => void;
  showAdvancedPlanning?: boolean;
  showCopyTasks?: boolean;
  showCurrentUserAsYou?: boolean;
  showMoveTasks?: boolean;
  showNotes?: boolean;
  showOpenTaskInspector?: boolean | ((taskId: Uuid) => void);
  showOpenWorkPackageInspector?: boolean;
  TaskGroup?: ComponentType<TaskGroupProps>;
  TaskRow?: ComponentType<TaskRowProps>;
  toggleVisibleColumn?: (col: TaskListColumn, show?: boolean) => void;
  useOnBeforeCreateTask: () => OnBeforeCreateTask; //called before the save request is sent
  visibleColumns: Set<TaskListColumn>;
}

interface TaskListCtx extends ConfigurableTaskListCtx {
  focusNewTaskRefs: Record<string, MutableRefObject<(() => void) | undefined> | undefined>;
  showNewTaskRowOnceAfterTaskIds: Set<Uuid>; //task ids that should render their "after" <NewTaskRow/> once
  showNewTaskRowOnceForParentIds: Set<Uuid>;
}

const defaultContext = {} as TaskListCtx;
const context = createContext<TaskListCtx>(defaultContext);

export function useTaskListContext(): TaskListCtx {
  return useContext(context);
}

export default function TaskListContext({
  children,
  readonly,
  ...rest
}: {children: ReactNode} & Partial<ConfigurableTaskListCtx & ExtraProps>): ReactElement {
  const focusNewTaskRefs = useRef<TaskListCtx['focusNewTaskRefs']>({});
  const showNewTaskRowOnceAfterTaskIds = useRef<TaskListCtx['showNewTaskRowOnceAfterTaskIds']>(new Set());
  const showNewTaskRowOnceForParentIds = useRef<TaskListCtx['showNewTaskRowOnceForParentIds']>(new Set());
  const ctx = useMemo((): TaskListCtx => {
    if (rest.allowCreationOfTaskSections && rest.hideEmptyTaskSections) {
      warning("Options allowCreationOfTaskSections and hideEmptyTaskSections won't work together");
    }
    return {
      displayDoneTasksAsOpen: false,
      // Avoid "true" default flags. Prefer opt-in for all features.
      focusNewTaskRefs: focusNewTaskRefs.current,
      showNewTaskRowOnceAfterTaskIds: showNewTaskRowOnceAfterTaskIds.current,
      showNewTaskRowOnceForParentIds: showNewTaskRowOnceForParentIds.current,
      useOnBeforeCreateTask: () => (task) => task,
      visibleColumns: new Set(taskListColumns),
      ...rest,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(rest)]);
  return (
    <context.Provider value={ctx}>
      <TaskListReadonlyContext canManageTasks={false} readonly={!!readonly}>
        {readonly && <DragContextReset>{children}</DragContextReset>}
        {!readonly && <DragContext>{children}</DragContext>}
      </TaskListReadonlyContext>
    </context.Provider>
  );
}

// noinspection FunctionNamingConventionJS
export function TaskListContextOverride({
  children,
  ...rest
}: {children: ReactNode} & Partial<ConfigurableTaskListCtx>): ReactElement {
  const parent = useTaskListContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ctx = useMemo((): TaskListCtx => ({...parent, ...rest}), [parent, ...Object.values(rest)]);
  return <context.Provider value={ctx}>{children}</context.Provider>;
}

// noinspection FunctionNamingConventionJS
export function TaskListContextReset({children}: {children: ReactNode}): ReactElement {
  return (
    <context.Provider value={defaultContext}>
      <TaskListReadonlyReset>
        <DragContextReset>{children}</DragContextReset>
      </TaskListReadonlyReset>
    </context.Provider>
  );
}
