import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import Dependency from '@octaved/planning/src/Components/PlanningRealization/Dependency/Dependency';
import {ReactElement} from 'react';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeDependencyProps {
  node: NodeType;
  readonly: boolean;
}

export default function NodeDependency({
  frame: Frame,
  node,
  readonly,
}: NodeDependencyProps & FramedSectionOrTileProps): ReactElement {
  return (
    <Frame labelToken={'pages:projects.inspector.manage.planning.predecessor'}>
      <Dependency node={node} readonly={readonly} />
    </Frame>
  );
}
