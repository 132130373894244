import {FlowState} from '@octaved/flow/src/Modules/State';
import {EntityStates} from '@octaved/store/src/EntityState';
import {InboxEntries, InboxSearchResults, InboxSortBy} from './InboxInterfaces';

export const inboxSelector = (state: FlowState): InboxEntries => state.entities.inbox;
export const inboxStateSelector = (state: FlowState): EntityStates => state.entityStates.inbox;

export const inboxSearchSelector = (state: FlowState): InboxSearchResults => state.inboxSearch.searchResults;
export const inboxSearchStateSelector = (state: FlowState): EntityStates => state.inboxSearch.searchState;

export const inboxSortBySelector = (): InboxSortBy => 'createdOn';

export const inboxSearchQuerySelector = (): null => null;
