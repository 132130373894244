import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import {InspectorTabItem, TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import {dialogRouteObjects} from '../Dialogs';
import {getWrappedStandardInspectorRoutes} from './StandardInspector';

const generalRoute = 'general';
const downloadTimeSheetsRoute = 'downloadTimeSheets';
const billingRoute = 'billing';

const DownloadTimeSheets = lazy(
  () => import('../../../Pages/Projects/Pages/BillingsList/Inspector/DownloadTimeSheet/DownloadTimeSheets'),
);
const Billing = lazy(() => import('../../../Pages/Projects/Pages/BillingsList/Inspector/Billing/Billing'));
const General = lazy(() => import('../../../Pages/Projects/Pages/BillingsList/Inspector/General/General'));

//#region <inspector tabs>
const tabs: InspectorTabItem[] = [
  {
    path: generalRoute,
    token: 'pages:projects.inspector.menu.general',
  },
  {
    path: downloadTimeSheetsRoute,
    token: 'pages:projects.inspector.menu.downloadTimeSheets',
  },
  {
    path: billingRoute,
    token: 'pages:projects.inspector.menu.billing',
  },
];

const inspectorTabConfig: TabRecords = {
  ['billing']: tabs,
};
//endregion

const generalRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <General />,
  path: generalRoute,
});
const exportForCustomerRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <DownloadTimeSheets />,
  path: downloadTimeSheetsRoute,
});
const exportInternalRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: <Billing />,
  path: billingRoute,
});

export const billingListInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: [
    generalRouteObject(),
    exportForCustomerRouteObject(),
    exportInternalRouteObject(),
    {
      element: <Navigate to={generalRoute} replace />,
      path: '*',
    },
  ],
  standardInspectorProps: {
    tabConfig: inspectorTabConfig,
  },
});
