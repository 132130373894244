import {
  EnumFlowNodeType,
  EnumFlowPidBillingType,
  EnumFlowPidFreeOfChargeReason,
  TimeTrackingReferenceNodeType,
} from '@octaved/env/src/dbalEnumTypes';
import * as routes from '@octaved/flow-api';
import {patch} from '@octaved/network/src/Network';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import type {FieldCondition} from '@octaved/store/src/Notifications';
import {ActionDispatcher, getState} from '@octaved/store/src/Store';
import {
  RuleResult,
  RulesList,
  ValidateDecimalOptions,
  notNull,
  notNullOrUndefined,
  validateDecimalIfValidStringFormat,
  validateMatchRegex,
  validateNumberStringFormat,
  validateRules,
} from '@octaved/store/src/Validation';
import {DeepPartial, DeepPartialObject, Uuid} from '@octaved/typescript/src/lib';
import {ISO_DATE_REGEX, unix} from '@octaved/users/src/Culture/DateFormatFunctions';
import {format, formatDecimal, unformat} from '@octaved/users/src/Culture/NumberFormatter';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import objectContains from '@octaved/validation/src/ObjectContains';
import {pick} from 'lodash';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {NodeType} from '../EntityInterfaces/Nodes';
import {
  CommonPidNodePatchData,
  Group,
  WorkPackage,
  WorkPackageCreationData,
  WorkPackagePatchData,
} from '../EntityInterfaces/Pid';
import {SubWorkPackage} from '../EntityInterfaces/SubWorkPackage';
import {hourlyBillableTypesSet} from '../WorkPackage/BillingType';
import {
  FLOW_CHANGE_PID_FAILURE,
  FLOW_CHANGE_PID_REQUEST,
  FLOW_CHANGE_PID_SUCCESS,
  FLOW_CREATE_WORK_PAGKAGE_FAILURE,
  FLOW_CREATE_WORK_PAGKAGE_REQUEST,
  FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
} from './ActionTypes';
import {
  createCommonPidNode,
  createCommonPidNodeErrorFields,
  createDeleteCommonPidNode,
  getCommonPidNodeCopiedFields,
  getCommonPidNodeValidationRules,
  transformCommonPidNodeToPatchData,
  transformPatchDataToCommonPidNode,
} from './CommonPidNodes';
import {createCompletableNode, setCompletedOn} from './CompletableNodes';
import {EventData, WorkPackageCreateEvent, WorkPackageCreatedEvent, WorkPackagePatchedEvent} from './Events';
import {nodeEntityReducers} from './Nodes';
import {hasPriceCategory} from './Pid';
import {removeNotAllowedLabels} from './ReduceRemovedLabels';
import {copyResponsibleNode, createResponsibleNode, onResponsibleNodeCreation} from './ResponsibleNode';
import {isCustomerBillableSelector} from './Selectors/CustomerSelectors';
import {labelIdsSelector} from './Selectors/LabelSelectors';
import {isGroup, isWorkPackage} from '../Node/NodeIdentifiers';
import {getWorkPackageSelector} from './Selectors/PidSelectors';
import {reduceSortedSiblingNodeIds} from './SortedSiblingIds';
import {FlowState} from './State';
import {patchSubWorkPackage} from './SubWorkPackages';
import {parseNullableNumber} from './TransformPatches';
import {setErrors} from './Ui';

nodeEntityReducers.add<WorkPackageCreateEvent | WorkPackageCreatedEvent | WorkPackagePatchedEvent>(
  [FLOW_CREATE_WORK_PAGKAGE_REQUEST, 'flow.WorkPackageCreatedEvent', 'flow.WorkPackagePatchedEvent'],
  reduceSortedSiblingNodeIds((n): n is Group | WorkPackage => isGroup(n) || isWorkPackage(n)),
);

export function createWorkPackageEntity(flowCustomer: Uuid): WorkPackage {
  return {
    ...createCommonPidNode(EnumFlowNodeType.VALUE_WORK_PACKAGE, flowCustomer),
    ...createCompletableNode(),
    ...createResponsibleNode(),
    billingType: EnumFlowPidBillingType.VALUE_EFFORT,
    effortFrom: null,
    effortTo: null,
    fixedPrice: null,
    freeOfChargeReason: EnumFlowPidFreeOfChargeReason.VALUE_AQUISITION,
    isApprovedForBilling: false,
    isAutoChainActive: false,
    isClosed: false,
    isLocked: false,
    isOffer: false,
    lastBillingPeriodEnd: null,
    manualTimeTrackingEnd: null,
    maxEffort: null,
    nodeType: EnumFlowNodeType.VALUE_WORK_PACKAGE,
    priceCategory: null,
    timeControl: null,
    timeTrackingReferenceNodeType: TimeTrackingReferenceNodeType.any,
    usePriceCategoryPerTimeTracking: false,
  };
}

function getWorkPackageEntityCopiedFields(): Array<keyof WorkPackage> {
  return [
    ...getCommonPidNodeCopiedFields(),
    'billingType',
    'effortFrom',
    'effortTo',
    'fixedPrice',
    'freeOfChargeReason',
    'isAutoChainActive',
    'isOffer',
    'maxEffort',
    'priceCategory',
    'timeControl',
    'timeTrackingReferenceNodeType',
    'usePriceCategoryPerTimeTracking',
  ];
}

export function copyWorkPackageEntity(source: WorkPackage, targetNode: NodeType): WorkPackage {
  return {
    ...createWorkPackageEntity(source.flowCustomer),
    ...pick(source, getWorkPackageEntityCopiedFields()),
    ...copyResponsibleNode(source, targetNode),
  };
}

export function transformWorkPackageToPatchData<T extends CommonPidNodePatchData = WorkPackagePatchData>(
  workPackage: WorkPackage,
): T {
  return {
    ...transformCommonPidNodeToPatchData<T>(workPackage),
    effortFrom: formatDecimal(workPackage.effortFrom),
    effortTo: formatDecimal(workPackage.effortTo),
    fixedPrice: format(workPackage.fixedPrice || null, 2),
    maxEffort: formatDecimal(workPackage.maxEffort),
  };
}

function transformPatchDataToWorkPackage(data: WorkPackagePatchData): WorkPackage;
function transformPatchDataToWorkPackage(data: Partial<WorkPackagePatchData>): Partial<WorkPackage>;
function transformPatchDataToWorkPackage(data: DeepPartial<WorkPackagePatchData>): DeepPartial<WorkPackage>;
function transformPatchDataToWorkPackage(data: DeepPartial<WorkPackagePatchData>): DeepPartial<WorkPackage> {
  const workPackage = transformPatchDataToCommonPidNode<WorkPackage>(data);
  parseNullableNumber(data, workPackage, 'effortFrom');
  parseNullableNumber(data, workPackage, 'effortTo');
  parseNullableNumber(data, workPackage, 'fixedPrice');
  parseNullableNumber(data, workPackage, 'maxEffort');
  return workPackage;
}

const validationTexts = {
  nameEmpty: 'general:pid.validationErrors.workpackageNameNotEmpty',
  nameTooLong: 'general:pid.validationErrors.workpackageNameTooLong',
};

function validateEffortFromTo(effortFrom: string, effortTo: string, field: string, field2: string): RuleResult {
  const from = unformat(effortFrom);
  const to = unformat(effortTo);
  if (from !== null && to !== null && to < from) {
    return [
      {
        field,
        message: 'pages:projects.inspector.effortToBelowFromError',
      },
      {
        field: field2,
        message: 'pages:projects.inspector.effortFromAfterTo',
      },
    ];
  }

  return [];
}

function fallback<K extends keyof WorkPackagePatchData>(
  patch: DeepPartial<WorkPackagePatchData>,
  previous: WorkPackagePatchData | undefined,
  key: K,
): DeepPartial<WorkPackagePatchData>[K] | undefined {
  const patched = patch[key];
  return typeof patched === 'undefined' ? previous?.[key] : patched;
}

type MaxEffortBillingTypes =
  | EnumFlowPidBillingType.VALUE_EFFORT_EST
  | EnumFlowPidBillingType.VALUE_EFFORT_CAP
  | EnumFlowPidBillingType.VALUE_CONTINGENT
  | EnumFlowPidBillingType.VALUE_FIXED_PRICE;

const maxEffortBillingTypes = new Set<EnumFlowPidBillingType>([
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_CONTINGENT,
  EnumFlowPidBillingType.VALUE_FIXED_PRICE,
]);

function isMaxEffortBillingType(billingType?: EnumFlowPidBillingType | null): billingType is MaxEffortBillingTypes {
  return !!billingType && maxEffortBillingTypes.has(billingType);
}

function getMaxEffortValidationRules(
  id: Uuid,
  patch: DeepPartial<WorkPackagePatchData>,
  previous: WorkPackagePatchData | undefined,
  required: boolean,
): RulesList {
  const rules: RulesList = [
    required && [
      notNullOrUndefined,
      fallback(patch, previous, 'maxEffort'),
      'general:pid.validationErrors.workPackage.maxEffortEmpty',
      `maxEffort_${id}`,
    ],
    Boolean(patch.maxEffort) && [
      validateNumberStringFormat,
      patch.maxEffort,
      'general:error.invalidNumberFormat',
      `maxEffort_${id}`,
    ],
    Boolean(patch.maxEffort) && [
      validateDecimalIfValidStringFormat,
      patch.maxEffort,
      'general:pid.validationErrors.limitHoursOutOfRange',
      `maxEffort_${id}`,
      {
        minValueExclusive: true,
      } as ValidateDecimalOptions,
    ],
  ];

  return rules;
}

function getEffortFromToValidationRules(
  id: Uuid,
  patch: DeepPartial<WorkPackagePatchData>,
  previous?: WorkPackagePatchData,
): RulesList {
  const fromFallback = fallback(patch, previous, 'effortFrom');
  const toFallback = fallback(patch, previous, 'effortTo');
  return [
    [notNullOrUndefined, fromFallback, 'general:pid.validationErrors.workPackage.effortFromEmpty', `effortFrom_${id}`],
    [notNullOrUndefined, toFallback, 'general:pid.validationErrors.workPackage.effortToEmpty', `effortTo_${id}`],
    typeof patch.effortFrom === 'string' && [
      validateNumberStringFormat,
      patch.effortFrom,
      'general:error.invalidNumberFormat',
      `effortFrom_${id}`,
    ],
    typeof patch.effortFrom === 'string' && [
      validateDecimalIfValidStringFormat,
      patch.effortFrom,
      'general:pid.validationErrors.limitHoursOutOfRange',
      `effortFrom_${id}`,
      {
        minValueExclusive: true,
      } as ValidateDecimalOptions,
    ],
    typeof patch.effortTo === 'string' && [
      validateNumberStringFormat,
      patch.effortTo,
      'general:error.invalidNumberFormat',
      `effortTo_${id}`,
    ],
    typeof patch.effortTo === 'string' && [
      validateDecimalIfValidStringFormat,
      patch.effortTo,
      'general:pid.validationErrors.limitHoursOutOfRange',
      `effortTo_${id}`,
      {
        minValueExclusive: true,
      } as ValidateDecimalOptions,
    ],
    typeof fromFallback === 'string' &&
      typeof toFallback === 'string' && [
        validateEffortFromTo,
        fromFallback,
        toFallback,
        `effortFrom_${id}`,
        `effortTo_${id}`,
      ],
  ];
}

function getFixedPriceValidationRules(
  id: Uuid,
  patch: DeepPartial<WorkPackagePatchData>,
  previous?: WorkPackagePatchData,
): RulesList {
  return [
    [notNullOrUndefined, fallback(patch, previous, 'fixedPrice'), 'general:invalidPrice', `fixedPrice_${id}`],
    typeof patch.fixedPrice === 'string' && [
      validateNumberStringFormat,
      patch.fixedPrice,
      'general:invalidPrice',
      `fixedPrice_${id}`,
    ],
    typeof patch.fixedPrice === 'string' && [
      validateDecimalIfValidStringFormat,
      patch.fixedPrice,
      'general:error.decimalOutOfRange',
      `fixedPrice_${id}`,
    ],
  ];
}

const billingProperties: Array<keyof WorkPackagePatchData> = [
  'billingType',
  'effortFrom',
  'effortTo',
  'fixedPrice',
  'maxEffort',
  'priceCategory',
  'usePriceCategoryPerTimeTracking',
];

function judgeRequiresPriceCategory(
  patch: DeepPartial<WorkPackagePatchData>,
  previous?: WorkPackagePatchData,
): boolean {
  const billingType = fallback(patch, previous, 'billingType');
  const customerId = fallback(patch, previous, 'flowCustomer') || '';
  const usePriceCategoryPerTimeTracking = fallback(patch, previous, 'usePriceCategoryPerTimeTracking');
  const state = getState<FlowState>();
  const isCustomerBillable = isCustomerBillableSelector(state)(customerId);
  return (
    !!billingType &&
    hasPriceCategory({billingType}) &&
    (hourlyBillableTypesSet.has(billingType) ? !usePriceCategoryPerTimeTracking : true) &&
    isCustomerBillable
  );
}

function getWorkPackageBillingValidationRules(
  id: Uuid,
  patch: DeepPartial<WorkPackagePatchData>,
  previous?: WorkPackagePatchData,
): RulesList {
  const billingType = fallback(patch, previous, 'billingType');
  const hasEffortFromTo = billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO;
  const hasFixedPrice = billingType === EnumFlowPidBillingType.VALUE_FIXED_PRICE;
  const hasMaxEffort = isMaxEffortBillingType(billingType);
  const requiresMaxEffort = hasMaxEffort && !hasFixedPrice;
  const requiresPriceCategory = judgeRequiresPriceCategory(patch, previous);
  const flowCustomer = fallback(patch, previous, 'flowCustomer');
  const isCustomerBillable = isCustomerBillableSelector(getState())(flowCustomer);
  return [
    [
      notNull,
      billingType,
      isCustomerBillable
        ? 'general:pid.validationErrors.noBillingType'
        : 'general:pid.validationErrors.noBillingTypeNonBillable',
      `billingType_${id}`,
    ],
    requiresPriceCategory && [
      notNull,
      fallback(patch, previous, 'priceCategory'),
      'general:pid.validationErrors.workPackage.priceCategoryEmpty',
      `priceCategory_${id}`,
    ],
    ...(hasMaxEffort ? getMaxEffortValidationRules(id, patch, previous, requiresMaxEffort) : []),
    ...(hasEffortFromTo ? getEffortFromToValidationRules(id, patch, previous) : []),
    ...(hasFixedPrice ? getFixedPriceValidationRules(id, patch, previous) : []),
  ];
}

function getWorkPackageValidationRules(
  id: Uuid,
  patch: DeepPartial<WorkPackagePatchData>,
  isCreation: boolean,
  previous?: WorkPackagePatchData,
): RulesList {
  const hasBillingChanges = billingProperties.some((prop) => patch.hasOwnProperty(prop));

  return [
    ...getCommonPidNodeValidationRules(id, patch, isCreation, validationTexts),
    ...(hasBillingChanges ? getWorkPackageBillingValidationRules(id, patch, previous) : []),
    typeof patch.manualTimeTrackingEnd === 'string' && [
      validateMatchRegex,
      patch.manualTimeTrackingEnd,
      ISO_DATE_REGEX,
      'general:pid.validationErrors.workPackage.invalidManualTimeTrackingEnd',
      `manualTimeTrackingEnd_${id}`,
    ],
  ];
}

export function createWorkPackageBillingErrorFields(id: Uuid): FieldCondition[] {
  return billingProperties.map((key) => {
    return `${key}_${id}`;
  });
}

export function createWorkPackageErrorFields(id: Uuid): FieldCondition[] {
  return [
    ...createCommonPidNodeErrorFields(id),
    ...createWorkPackageBillingErrorFields(id),
    `plannedStart_${id}`,
    `plannedTime_${id}`,
  ];
}

export function createWorkPackage(
  data: WorkPackageCreationData,
  parentNodeId: Uuid,
  sortedSiblingIds?: Uuid[],
): ActionDispatcher<Promise<boolean>, FlowState> {
  return async (dispatch, getState) => {
    const state = getState();
    const rules: RulesList = getWorkPackageValidationRules(data.id, data, true, undefined);
    const errors = validateRules(rules);
    if (errors.length) {
      dispatch(setErrors(errors));
      return false;
    }

    const workPackage = transformPatchDataToWorkPackage(data);

    setCompletedOn(workPackage, currentOrgUserIdSelector(state));
    onResponsibleNodeCreation(workPackage, parentNodeId, state);

    if (sortedSiblingIds) {
      const sortOrder = sortedSiblingIds.findIndex((id) => id === data.id);
      workPackage.sortOrder = sortOrder > -1 ? sortOrder : 0;
    }

    const event: EventData<WorkPackageCreateEvent> = {
      parentNodeId,
      sortedSiblingIds,
      workPackage,
    };

    await dispatch({
      ...event,
      [CALL_API]: {
        endpoint: routes.putWorkPackage,
        method: 'put',
        options: {
          data: {
            ...workPackage,
            parentNodeId,
            sortedSiblingIds,
          },
          urlParams: {workPackageId: data.id},
        },
        types: {
          failureType: FLOW_CREATE_WORK_PAGKAGE_FAILURE,
          requestType: FLOW_CREATE_WORK_PAGKAGE_REQUEST,
          successType: FLOW_CREATE_WORK_PAGKAGE_SUCCESS,
        },
      },
    });
    return true;
  };
}

export function patchWorkPackage(id: Uuid, patch: DeepPartialObject<WorkPackage>): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const oldWp = getWorkPackageSelector(state)(id);
    if (oldWp) {
      const patchData = removeNotAllowedLabels(patch, labelIdsSelector(state));
      const hasSubtreeAction = 'isAutoChainActive' in patchData;
      if (hasSubtreeAction || !objectContains(oldWp, patchData)) {
        patchData.lastChangedBy = currentOrgUserIdSelector(state);
        patchData.lastChangedOn = unix();
        setCompletedOn(patchData, currentOrgUserIdSelector(state));
        dispatch({
          [CALL_API]: {
            endpoint: routes.patchWorkPackage,
            method: 'patch',
            options: {
              data: patchData,
              urlParams: {workPackageId: id},
            },
            types: {
              failureType: FLOW_CHANGE_PID_FAILURE,
              requestType: FLOW_CHANGE_PID_REQUEST,
              successType: FLOW_CHANGE_PID_SUCCESS,
            },
          },
          //e.g. for NodeSearch reducer that need to updated since no return event is coming:
          patchedKeys: Object.keys(patchData),
          patchedNodeId: id, //for the optimistic patch
        });
      }
    }
  };
}

/**
 * @deprecated use patchWorkPackage & auto-saving and local form validation
 */
export function patchWorkPackageDeprecated(
  id: Uuid,
  partial: Partial<WorkPackagePatchData>,
): ActionDispatcher<boolean, FlowState> {
  return (dispatch, getState) => {
    const state = getState();
    const oldWp = getWorkPackageSelector(state)(id);
    if (oldWp) {
      const rules: RulesList = getWorkPackageValidationRules(
        id,
        partial,
        false,
        transformWorkPackageToPatchData<WorkPackagePatchData>(oldWp),
      );
      const errors = validateRules(rules);
      if (errors.length) {
        dispatch(setErrors(errors));
        return false;
      }
      const transformed = transformPatchDataToWorkPackage(partial);
      dispatch(patchWorkPackage(id, transformed));
    }
    return true;
  };
}

export const deleteWorkPackage = createDeleteCommonPidNode('workPackageId', routes.deleteWorkPackage);

export async function patchWorkPackagesFlags(
  workPackageIds: Uuid[],
  flags: Partial<Pick<WorkPackage, 'isApprovedForBilling' | 'isLocked' | 'isOffer'>>,
): Promise<void> {
  await patch(routes.patchWorkPackagesFlags, {data: {flags, workPackageIds}});
}

export function useWorkPackagePatch(nodeId: Uuid): (data: Partial<WorkPackage>) => void {
  const dispatch = useDispatch();
  return useCallback((data: Partial<WorkPackage>) => dispatch(patchWorkPackage(nodeId, data)), [dispatch, nodeId]);
}

export function useSubWorkPackagePatch(nodeId: Uuid): (data: Partial<SubWorkPackage>) => void {
  const dispatch = useDispatch();
  return useCallback(
    (data: Partial<SubWorkPackage>) => dispatch(patchSubWorkPackage(nodeId, data)),
    [dispatch, nodeId],
  );
}

export function useSubWorkPackagePatchWithId(): (nodeId: Uuid, data: Partial<SubWorkPackage>) => void {
  const dispatch = useDispatch();
  return useCallback(
    (nodeId: Uuid, data: Partial<SubWorkPackage>) => dispatch(patchSubWorkPackage(nodeId, data)),
    [dispatch],
  );
}
