import {useInspectorId} from '@octaved/hooks';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {IconButton} from '@octaved/ui';
import {PlusCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import DeactivatableLink from '../../../Components/DeactivatableLink';
import {isWpOrSwp} from '../../../Node/NodeIdentifiers';
import {getNodeSelector} from '../../../Modules/Selectors/NodeSelectors';
import {useGetNewSubWorkPackageDialogRoute} from '../../../Routing/Routes/Dialogs';

export default function CreateSubWorkPackageButton(): ReactElement {
  const isGranted = useSelector(isGrantedSelector);
  const inspectorId = useInspectorId();
  const selectedNode = useSelector(getNodeSelector)(inspectorId);
  const relativeNode = isWpOrSwp(selectedNode) ? selectedNode : null;
  const canCreate = !!relativeNode && isGranted('FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC', relativeNode.id);
  return (
    <DeactivatableLink disabled={!canCreate} to={useGetNewSubWorkPackageDialogRoute()(relativeNode?.id || '')}>
      <IconButton
        size={'sm'}
        colorScheme={'add'}
        icon={<PlusCircle />}
        label={'pages:projects.inspector.newSubWorkPackage'}
        isDisabled={!canCreate}
      />
    </DeactivatableLink>
  );
}
