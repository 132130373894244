import {VariantProps, cva} from 'class-variance-authority';
import {FC} from 'react';
import {cn} from './Hooks';

export const progressWrapperVariant = cva('relative h-2 w-full overflow-hidden rounded-sm bg-slate-200', {
  defaultVariants: {
    size: 'md',
  },
  variants: {
    size: {
      lg: 'h-4',
      md: 'h-2',
      xl: 'h-6',
    },
  },
});

export const progressBarVariant = cva('absolute bottom-0 left-0 top-0 bg-sky-700', {
  defaultVariants: {
    color: 'blue',
  },
  variants: {
    color: {
      blue: 'bg-sky-700',
      green: 'bg-emerald-500',
      orange: 'bg-orange-500',
      red: 'bg-red-600',
      slate: 'bg-slate-500',
    },
  },
});

export interface TWProgressProps
  extends VariantProps<typeof progressWrapperVariant>,
    VariantProps<typeof progressBarVariant> {
  className?: string;
  progress: number;
}

export const TWProgress: FC<TWProgressProps> = ({size, className, color, progress}) => {
  return (
    <div className={cn(progressWrapperVariant({size}), className)}>
      <div className={cn(progressBarVariant({color}))} style={{width: `${progress}%`}} />
    </div>
  );
};
