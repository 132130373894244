const PRIMARY_COLOR_AREA = '#2970A3';
const PRIMARY_COLOR_LINE = '#0B66A7';

const SECONDARY_COLOR_AREA = '#63A008';
const SECONDARY_COLOR_LINE = '#219653';
const SECONDARY_COLOR_AREA_BRIGHT = '#72BF00';

const BLACK_1 = '#2d2d39';
const BLACK_2 = '#000';

const YELLOW_1 = '#FFA800';
const YELLOW_2 = 'rgba(255, 168, 0, 0.05)';
const YELLOW_3 = 'rgb(255,238,204)';
const YELLOW_4 = 'rgba(255, 168, 0, 0.3)';
const YELLOW_5 = '#F0AE04';
const YELLOW_6 = '#FEFFCC';

const RED_1 = '#cf1e4d';
const RED_2 = 'rgba(207,29,76,0.05)';
const RED_3 = '#b51943';
const RED_4 = '#FF0000';

const PURPLE_1 = '#D647F9';
const PURPLE_2 = 'rgba(155,81,224,0.1)';
const PURPLE_3 = '#6b0099';
const PURPLE_4 = '#921CAE';

const ORANGE_1 = '#F7821B';

const BLUE_1 = '#004161';
const BLUE_2 = 'rgba(12, 83, 179, 0.05)';
const BLUE_3 = '#1F547A';
const BLUE_4 = '#EDF5FB';
const BLUE_5 = '#E9F5FB';
const BLUE_6 = '#75C7F0';
const BLUE_7 = '#d5e9f7';
const BLUE_8 = '#2c5898';
const BLUE_9 = '#58AEFF';
const BLUE_10 = '#e1ebf2';
const BLUE_11 = '#0084FF';
const BLUE_12 = '#187CC3';

const GREEN_1 = '#1A7541';
const GREEN_2 = '#F4FAF6';
const GREEN_3 = 'rgba(33, 150, 47, 0.07)';
const GREEN_4 = '#9DB380';
const GREEN_5 = 'rgba(0,184,63,0.10)';
const GREEN_6 = '#3cb371';

const GREY_1 = '#D0D8DF';
const GREY_2 = '#F6F8FA';
const GREY_3 = '#E1E3E5';
const GREY_4 = '#D9DBDD';
const GREY_5 = '#C9CCCF';
const GREY_6 = 'rgba(0,0,0,.1)';
const GREY_7 = 'rgba(132,134,148,0.66)';
const GREY_8 = '#F3F3F7';
const GREY_9 = 'rgba(0,0,0,.05)';
const GREY_10 = '#D1D7E0';
const GREY_11 = '#D9D9D9';
const GREY_12 = '#3E3E3E';
const GREY_13 = 'rgba(209, 215, 224, .5)';
const GREY_14 = 'rgba(209, 215, 224, .4)';
const GREY_15 = 'rgba(209, 215, 224, .2)';
const GREY_16 = '#878CAB';
const GREY_17 = 'rgba(135, 140, 171, .3)';
const GREY_18 = '#595E65';
const GREY_19 = '#454D54';
const GREY_20 = '#2E3338';
const GREY_21 = '#F7F7F7';
const GREY_22 = '#707070';
const GREY_23 = '#848694';
const GREY_24 = '#ACACAE';
const GREY_25 = '#F7F8F8';
const GREY_26 = '#F9F9F9';
const GREY_27 = '#d2d2d2';
const GREY_28 = '#8594AD';
const GREY_29 = '#dfe1e2';
const GREY_30 = '#f8f8f8';
const GREY_31 = 'rgba(0,0,0,0.5)';
const GREY_32 = 'rgba(209, 215, 224, 0.33)';
const GREY_33 = 'rgba(0, 0, 0, .02)';
const GREY_34 = 'linear-gradient(180deg, #FFFFFF 0%, #EBE7E7 100%)';
const GREY_35 = '#E0E1E2';
const GREY_36 = 'rgba(0, 0, 0, 0.5)';
const GREY_37 = 'rgba(0, 0, 0, 0.25)';
const GREY_38 = 'rgba(34, 36, 38, 0.15)';
const GREY_39 = 'rgba(136,136,136,0.25)';
const GREY_40 = '#EEE';
const GREY_41 = '#E9E9E9';
const GREY_42 = '#F8F8F8';
const GREY_43 = 'rgba(0, 0, 0, 0.05)';
const GREY_44 = '#888';
const GREY_45 = 'rgba(0, 0, 0, .1)';
const GREY_46 = 'rgba(0, 0, 0, .2)';
const GREY_47 = 'rgba(209, 215, 224, 0.5)';
const GREY_48 = 'rgba(0, 0, 0, .4)';
const GREY_49 = '#999';
const GREY_50 = '#CCC';
const GREY_51 = '#AAA';
const GREY_52 = '#e6e6e6';
const GREY_53 = '#7B7B7B';
const GREY_54 = '#fcfcfc';

const WHITE_1 = '#FFFFFF';
const WHITE_2 = 'rgba(255,255,255,.5)';

export interface FlowTransitions {
  FAST: string;
  SLOW: string;
}

export const COLORS = {
  AVATAR: {
    BACKGROUND: GREY_51,
    FONT: WHITE_1,
  },

  BACKGROUND: {
    ALTERNATIVE: GREY_30,
    ALTERNATIVE_DARK: GREY_29,
    ALTERNATIVE_LIGHT: GREY_30,
    ALTERNATIVE_LIGHTER: GREY_54,
    DRAG_ALLOWED: GREEN_5,
    DRAG_FORBIDDEN: RED_2,
    HIGHLIGHT_INFO: YELLOW_6,
    HIGHLIGHT_SEARCH: YELLOW_1,
    HOVER: BLUE_5,
    STANDARD: WHITE_1,
  },

  BOARD: {
    NEW_POST: {
      ELEMENT_BACKGROUND: PRIMARY_COLOR_AREA,
    },
    POST: {
      DIVIDER: GREY_10,
    },
    READ_UNTIL_HERE_LINE: BLUE_11,
    UNIT_LIST_BORDER: GREY_38,
  },

  BORDER: {
    ALTERNATE_COLOR_BORDER: GREY_40,
    HIGHLIGHT: PRIMARY_COLOR_LINE,
    HOVER: BLUE_6,
    NORMAL: GREY_50,
    WEAK: GREY_47,
  },

  BUTTON: {
    ADD: {
      BASIC_HOVER_BG: GREEN_2,
      BORDER: SECONDARY_COLOR_LINE,
      COLOR: SECONDARY_COLOR_LINE,
      HOVER_BG: GREEN_1,
    },
    CANCEL: {
      BASIC_HOVER_BG: GREY_13,
      BORDER: GREY_19,
      COLOR: GREY_19,
      HOVER_BG: GREY_20,
    },
    DELETE: {
      BASIC_HOVER_BG: RED_2,
      BORDER: RED_1,
      COLOR: RED_1,
      HOVER_BG: RED_3,
    },
    DISABLED: {
      BASIC_HOVER_BG: GREY_13,
      BORDER: GREY_11,
      COLOR: GREY_11,
      HOVER_BG: GREY_13,
    },
    FAVORITE: {
      BASIC_HOVER_BG: PURPLE_2,
      BORDER: PURPLE_1,
      COLOR: PURPLE_1,
      HOVER_BG: PURPLE_2,
    },
    GHOST_HOVER_BG: GREY_40,
    IMPORTANT: {
      BASIC_HOVER_BG: BLUE_4,
      BORDER: PRIMARY_COLOR_LINE,
      COLOR: PRIMARY_COLOR_LINE,
      HOVER_BG: BLUE_3,
    },
    LIGHT: {
      BASIC_HOVER_BG: GREY_8,
      BORDER: GREY_16,
      COLOR: GREY_16,
      HOVER_BG: GREY_8,
    },
    UNIMPORTANT: {
      BASIC_HOVER_BG: BLUE_4,
      BORDER: PRIMARY_COLOR_LINE,
      COLOR: PRIMARY_COLOR_LINE,
      HOVER_BG: BLUE_3,
    },
  },

  CALENDAR: {
    ACTIVE_DAY_FONT: BLACK_1,
    BLOCKED_DAY_BACKGROUND: GREY_21,
    BORDER: GREY_10,
    DAY_BORDER: GREY_11,
    DISABLED_DAY_FONT: GREY_11,
    HIGHLIGHT_BACKGROUND: YELLOW_1,
    HIGHLIGHT_FONT: WHITE_1,
    MONTH_LABEL: GREY_22,
    RANGE_BACKGROUND: GREY_14,
    SELECTED_BACKGROUND: PRIMARY_COLOR_AREA,
    SELECTED_FONT: WHITE_1,
    SELECTED_SPAN_BACKGROUND: BLUE_5,
    SELECTED_SPAN_BACKGROUND_ON_BLOCKED: BLUE_10,
    TOGGLE_BACKGROUND: GREY_10,
    TOGGLE_ICON_COLOR: GREY_23,
  },

  CHART: {
    BILLABLE_QUOTA: {
      BILLABLE: PRIMARY_COLOR_AREA,
      NOT_BILLABLE: PURPLE_3,
    },
    LINES: GREY_50,
    TICK_FONT: BLACK_1,
  },

  CHECKBOX: {
    ACTIVE: GREEN_1,
    BORDER: GREY_28,
  },

  CIRCLE_CHART: {
    GREEN: SECONDARY_COLOR_AREA,
    GREY: GREY_16,
    ORANGE: YELLOW_1,
  },

  DRAWER: {
    DROP_SHADOW: GREY_31,
    INBOX: {
      ITEM_HOVER: GREY_43,
      ITEM_SELECTED: GREY_45,
      NOTIFICATION_BACKGROUND: RED_4,
      NOTIFICATION_FONT: WHITE_1,
      UNREAD: BLUE_8,
    },
    TIME_RECORDING: {
      SECTION_BOOKING_BACKGROUND: BLUE_2,
      SECTION_BORDER: GREY_10,
      SECTION_GAP_BACKGROUND: YELLOW_2,
      SECTION_GAP_BORDER: YELLOW_4,
    },
  },

  FILTER: {
    BACKGROUND: PRIMARY_COLOR_AREA,
    BACKGROUND_ACTIVE: ORANGE_1,
    PAGE_FILTER_BORDER: GREY_39,
  },

  FONT: {
    ERROR: RED_1,
    HIGHLIGHTED: PRIMARY_COLOR_LINE,
    HIGHLIGHTED_ALT: PRIMARY_COLOR_AREA,
    INVERTED: WHITE_1,
    LIGHT: GREY_16,
    PLACEHOLDER: GREY_51,
    STANDARD: BLACK_1,
    WEAK: GREY_44,
  },

  FORM: {
    HEADER: {
      BORDER: GREY_16,
      SUB_MENU: GREY_23,
      TEXT: GREY_23,
    },
    LABEL: {
      ACTIVE: PRIMARY_COLOR_LINE,
      INACTIVE: GREY_22,
    },
  },

  ICON: {
    FILL: {
      BLACK: {
        DEFAULT: BLACK_1,
      },
      BLUE: {
        ALTERNATIVE: BLUE_1,
        BRIGHT: BLUE_9,
        DEFAULT: PRIMARY_COLOR_AREA,
      },
      DEFAULT: GREY_16,
      FAVORITE: {
        DEFAULT: YELLOW_5,
      },
      GREEN: {
        ALTERNATIVE: SECONDARY_COLOR_LINE,
        BRIGHT: SECONDARY_COLOR_AREA_BRIGHT,
        DEFAULT: SECONDARY_COLOR_LINE,
      },
      GREY: {
        BRIGHT: GREY_13,
        DARK: GREY_18,
      },
      LIGHT: {
        DEFAULT: GREY_16,
      },
      ORANGE: {
        DEFAULT: ORANGE_1,
      },
      PURPLE: {
        DARK: PURPLE_4,
        DEFAULT: PURPLE_1,
      },
      RED: {
        DEFAULT: RED_1,
      },
      WHITE: {
        DEFAULT: WHITE_1,
      },
      YELLOW: {
        DEFAULT: YELLOW_1,
      },
    },
    STROKE: {
      BLUE: {
        ALTERNATIVE: BLUE_1,
        DEFAULT: PRIMARY_COLOR_LINE,
      },
      DEFAULT: GREY_16,
      FAVORITE: {
        DEFAULT: YELLOW_5,
      },
      GREEN: {
        ALTERNATIVE: SECONDARY_COLOR_LINE,
        BRIGHT: SECONDARY_COLOR_AREA_BRIGHT,
        DEFAULT: SECONDARY_COLOR_LINE,
      },
      GREY: {
        BRIGHT: GREY_13,
        DARK: GREY_19,
      },
      LIGHT: {
        DEFAULT: GREY_16,
      },
      ORANGE: {
        DEFAULT: ORANGE_1,
      },
      PURPLE: {
        DEFAULT: PURPLE_1,
      },
      RED: {
        DEFAULT: RED_1,
      },
      WHITE: {
        DEFAULT: WHITE_1,
      },
      YELLOW: {
        DEFAULT: YELLOW_1,
      },
    },
  },

  INPUT: {
    BORDER_ERROR: RED_1,
  },

  LAYOUT: {
    CIRCLE_BUTTON_BG: GREY_12,
    HEADER_BG: WHITE_1,
    HEADER_BUTTON_ACTIVE: BLUE_12,
    HEADER_FONT_COLOR: BLACK_1,
    HEADER_PRIMARY: GREY_41,
    HEADER_PRIMARY_ACTIVE: GREY_27,
    SIDE_BAR_ACTIVE_MENU_BG: PURPLE_4,
    SIDE_BAR_BG: GREY_12,
    SIDE_BAR_FONT_COLOR: WHITE_1,
    SIDE_BAR_HOVER_MENU_BG: GREY_45,
  },

  LEVITATION: {
    LEVEL_1: GREY_45,
    LEVEL_10: BLACK_2,
    LEVEL_2: GREY_46,
    LEVEL_3: GREY_48,
    LEVEL_4: GREY_36,
  },

  PAGE_SUB_MENU: {
    HEADER: GREY_18,
    ITEM: BLACK_1,
    ITEM_ACTIVE: PRIMARY_COLOR_LINE,
    PAGE_HEADER: GREY_53,
  },

  PAGES: {
    PLANNING: {
      AVAILABLE_DROPZONE: SECONDARY_COLOR_AREA_BRIGHT,
      BORDER: GREY_4,
      DEADLINE_BACKGROUND: WHITE_2,
      DISABLED_WORKDAY_BACKGROUND: GREY_43,
      NONE_WORKDAY_BACKGROUND: GREY_6,
      PLANNING_TILE: {
        BACKGROUND: WHITE_1,
        SHADOW: GREY_37,
      },
      SELECTION_BACKGROUND: GREY_5,
      TITLE: GREY_22,
      WEEK_SELECTION_BUTTON: GREY_5,
      WORKLOAD_BORDER: GREY_23,
      WORKLOAD_NUMBER_BACKGROUND: GREY_42,
      WORKLOAD_TASKS: PRIMARY_COLOR_AREA,
    },

    PROJECTS: {
      BORDER: GREY_10,
      FILTER_BACKGROUND: GREY_9,
      GROUP_BACKGROUND: GREY_21,
      GROUP_BORDER: GREEN_4,
      GROUP_SELECTED_BACKGROUND: GREY_52,
      SELECTION_BACKGROUND: BLUE_5,
      SELECTION_BORDER: BLUE_6,

      WORKPACKAGE: {
        BORDER: GREY_24,
        DISABLED: GREY_49,
        FREE_OF_CHARGE_BORDER: PURPLE_3,
        MAIN_BORDER: PRIMARY_COLOR_LINE,
      },
    },

    TIME_RECORDING: {
      TIME_GRID: {
        HOLE_BACKGROUND: YELLOW_3,
        HOLE_FONT: YELLOW_1,
        JOURNEY_BACKGROUND: BLUE_7,
        MAIN_BORDER: GREY_17,
        QUARTER_BORDERS: GREY_29,
        SURCHARGE_BACKGROUND: BLUE_4,
        //Note Start: Changing this colors does also require adjusting the svg image border in TimeRecordings
        TILE_ACTIVE_BACKGROUND: BLUE_5,
        TILE_ACTIVE_BORDER: BLUE_6,
        TILE_INACTIVE_BACKGROUND: WHITE_1,
        TILE_INACTIVE_BORDER: GREY_24,
        TILE_RUNNING_MAIN_BORDER: SECONDARY_COLOR_AREA,
        //Note End
        TIME_BORDER: GREY_17,
        TIME_FONT: GREY_7,
      },
    },

    WORKSPACE: {
      BORDER: GREY_10,

      MAIN_COLUMN: {
        BACKGROUND: GREY_25,

        INFO_TILE_BORDER: GREY_32,
      },

      SEARCH: {
        FAVORITE_BUTTON_ACTIVE_BACKGROUND: GREY_41,
        FAVORITE_BUTTON_HOVER_BACKGROUND: GREY_40,
        FILTER_BACKGROUND: GREY_26,
        PRESET_BUTTON_GROUP_ACTIVE_BACKGROUND: BLUE_12,
        PRESET_BUTTON_GROUP_ACTIVE_FONT: WHITE_1,
        PRESET_BUTTON_GROUP_BORDER: GREY_50,
      },
    },
  },

  PID_LIST: {
    ACTIVE_BACKGROUND: BLUE_5,
    ACTIVE_BORDER: BLUE_6,
    BORDER: GREY_24,
    GROUP_BACKGROUND: BLUE_1,
    GROUP_FONT: WHITE_1,
  },

  PLACEHOLDER: {
    FONT: GREY_7,
    ICON: GREY_3,
    ICON_ALTERNATIVE_BACKGROUND: GREY_5,
  },

  POPDOWN: {
    DROPDOWN: {
      ITEM_HOVER: GREY_43,
    },
  },

  RICH_TEXT: {
    CODE_BACKGROUND: GREY_9,
    MENTION_BACKGROUND: BLUE_7,
    QUOTE_BACKGROUND: GREY_9,
    QUOTE_FONT: GREY_23,
  },

  SIDE_STEPS: {
    PROJECT_NODE_DETAILS: {
      MENU: {
        HEADER: GREY_18,
      },
      PAGES: {
        SETTINGS_RIGHTS_AND_ASSIGNMENTS: {
          BORDER: GREY_10,
          SELECTED_ROW: BLUE_5,
          UNIT_REMOVED_INFO: GREY_16,
        },
      },
    },
  },

  TABLE: {
    BORDER: GREY_50,
    HEADER_BG: GREY_15,
    HEADER_COLOR: GREY_18,
    ROW: {
      HOVER: GREY_33,
      POSITIVE_BACKGROUND: GREEN_3,
      POSITIVE_FONT: SECONDARY_COLOR_LINE,
      SELECTED: BLUE_5,
      WARNING_BACKGROUND: YELLOW_2,
      WARNING_FONT: YELLOW_1,
    },
    WEAK_BORDER: GREY_47,
  },

  TASK_LIST: {
    BACKGROUND: WHITE_1,
    BORDER: GREY_32,
    SELECTED_BACKGROUND: BLUE_5,
    SELECTED_BORDER: BLUE_6,
  },

  TILE: {
    BACKGROUND: WHITE_1,
    BLUE_TOP_BORDER: BLUE_1,
    BORDER: GREY_1,
    GREEN_TOP_BORDER: SECONDARY_COLOR_LINE,
    HEADER_BACKGROUND: GREY_2,
    PRIMARY_TOP_BORDER: PRIMARY_COLOR_LINE,
  },

  TOGGLE: {
    BG: GREY_13,
    BG_ACTIVE: GREEN_6,
    SLIDE_BACKGROUND: GREY_34,
    SLIDE_BORDER: GREY_35,
    SLIDE_SHADOW: GREY_36,
  },

  TREE_PATH: {
    ICONS: GREY_16,
  },

  UNIT: {
    ADDED: GREEN_1,
    EDITED: ORANGE_1,
    HIGHLIGHTED: PRIMARY_COLOR_LINE,
    REMOVED: RED_1,
  },
} as const;

export type FlowColors = typeof COLORS;

export const GENERAL_DESIGN = {
  ANIMATION: {
    FAST: '120ms ease-in-out',
    SLOW: '240ms ease-in-out',
  },
};

export const RichTextEditorMonospaceFontStack = '"Inconsolata", "Menlo", "Consolas", monospace';
