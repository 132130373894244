import Konva from 'konva';
import {BaseRow, BaseRowProps, CellRendererProps} from './BaseRow';
import {Cell} from './Cells/Cell';

export class HeaderRow extends BaseRow {
  constructor(props: BaseRowProps) {
    super(props);
    this.height = this.ctx.headerHeight;
  }

  protected createCell(props: CellRendererProps): Cell | null {
    return this.tableFactory.getHeaderCellRenderClass(
      {columnName: props.columnName},
      {
        ctx: this.ctx,
        height: this.height,
        imageSize: this.imageSize,
        menuRoot: this.menuRoot,
        padding: this.padding,
        textLineOffset: this.textLineOffset,
      },
    );
  }

  init(): void {
    super.init();
    this.root.on('widthChange', () => {
      this.root.find('.background')[0].width(this.root.width());
    });
  }

  #createBackground(): void {
    if (!this.root.findOne('.background')) {
      this.root.add(
        new Konva.Rect({
          fill: '#fcfcfc',
          height: this.height - 1,
          name: 'background',
          width: 1000,
          x: 0,
          y: 0,
        }),
      );
    }
  }

  protected updateCells(): void {
    this.#createBackground();
    super.updateCells();
  }
}
