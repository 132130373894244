// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache = new WeakMap<ReadonlyArray<any>, Set<any>>();

/**
 * Weakly caches an array of values in a Set of values - saves memory compared to localized useMemo's when the same
 *  values are actually shared between multiple components.
 */
export function toCachedSet<V>(values: ReadonlyArray<V>): ReadonlySet<V> {
  const cached = cache.get(values) || new Set(values);
  cache.set(values, cached);
  return cached;
}
