import {hasMovedSufficientlyXY} from '@octaved/hooks/src/ReactDnd/Movement';
import {isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {ThunkDispatch} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize, once} from 'lodash';
import {RefObject, useCallback, useContext, useMemo} from 'react';
import {DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {rearrangePids} from '../../../../Modules/Pid';
import {ProjectTreeNode} from '../../../../Modules/Projects/ProjectTreeInterfaces';
import {
  getGroupDepthSelector,
  getGroupNestingLevelSelector,
  groupMaxDepth,
} from '../../../../Modules/Selectors/GroupSelectors';
import {getAllDescendantIdsSelector} from '../../../../Modules/Selectors/NodeTreeSelectors';
import {setDraggedPidId} from '../../../../Modules/UiPages/Projects';
import {projectDndContext, ProjectDndMoveFn} from './ProjectDndContext';
import {getSiblings} from './TreeCalculations';

interface ProjectSortableResult {
  isDragging: boolean;
}

export interface ProjectDragData {
  id: Uuid;
}

export const getSelfWithChilds = createSelector(getAllDescendantIdsSelector, (getAllDescendantIds) =>
  memoize((id: null | Uuid): Uuid[] => (id ? [...getAllDescendantIds(id), id] : [])),
);

export interface ProjectDragObject {
  index: number;
  parent: Uuid | null;
  data: ProjectDragData;
  startParent: Uuid | null;
  startIndex: number;
  lastIndex: number | null;
  lastDropZoneId: Uuid | null;
  idsToIgnore: Uuid[];
  groupNestingLevel: number;
}

function onHover(
  ownIndex: number,
  parent: Uuid | null,
  dropRef: RefObject<HTMLElement | null>,
  dropZoneId: Uuid | null,
  moveItem: ProjectDndMoveFn,
): (item: ProjectDragObject, monitor: DropTargetMonitor) => void {
  return function hover(item: ProjectDragObject, monitor: DropTargetMonitor): void {
    const dropRefCurrent = dropRef.current;
    if (!dropRefCurrent || !monitor.canDrop() || !monitor.isOver({shallow: true})) {
      return;
    }
    const dragIndex = item.index;
    const hoverIndex = ownIndex;
    // Don't replace items with themselves
    if (
      (item.lastIndex === hoverIndex || (dragIndex === hoverIndex && dropZoneId === item.lastDropZoneId)) &&
      parent === item.parent
    ) {
      return;
    }

    if (
      !hasMovedSufficientlyXY(
        dropRefCurrent,
        monitor,
        Math.min(dropRefCurrent.clientWidth * 0.1, 20),
        Math.min(dropRefCurrent.clientHeight * 0.15, 15),
      )
    ) {
      return;
    }
    // Time to actually perform the action
    moveItem(dragIndex, hoverIndex, item.parent, parent, item);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.lastIndex = item.index;
    item.lastDropZoneId = dropZoneId;
    item.index = hoverIndex;
    item.parent = parent;
  };
}

export function useProjectDrag(
  dragRef: RefObject<HTMLElement | null>,
  itemData: ProjectDragData,
  getIndex: () => number,
  parent: Uuid | null,
  idsToIgnore: Uuid[],
): boolean {
  const dispatch = useDispatch();
  const {onDragStart} = useContext(projectDndContext);
  const isGranted = useSelector(isGrantedSelector);
  const getGroupNestingLevel = useSelector(getGroupNestingLevelSelector);
  const canDrag = useMemo(() => {
    return once(() => isGranted('FLOW_NODE_PID_MANAGE_BASIC', parent));
  }, [isGranted, parent]);

  const [{isDragging}, drag] = useDrag<ProjectDragObject, Record<string, never>, {isDragging: boolean}>({
    canDrag() {
      return canDrag();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      dispatch(setDraggedPidId(null));
    },
    item: () => {
      dispatch(setDraggedPidId(itemData.id));
      if (onDragStart) {
        onDragStart();
      }
      const index = getIndex();
      return {
        idsToIgnore,
        index,
        parent,
        data: itemData,
        groupNestingLevel: getGroupNestingLevel(itemData.id),
        lastDropZoneId: null,
        lastIndex: null,
        startIndex: index,
        startParent: parent,
      };
    },
    type: 'workpackage',
  });

  drag(dragRef);

  return isDragging;
}

export function useProjectDrop(
  dropRef: RefObject<HTMLElement | null>,
  index: number,
  parent: Uuid,
  dropZoneId: Uuid,
): void {
  const {move, onDrop} = useContext(projectDndContext);
  const isGranted = useSelector(isGrantedSelector);
  const getGroupDepth = useSelector(getGroupDepthSelector);

  const onMove = useCallback<ProjectDndMoveFn>(
    (
      oldIndex: number,
      newIndex: number,
      oldParent: Uuid | null,
      newParent: Uuid | null,
      dragObject: ProjectDragObject,
    ) => {
      if (move) {
        move(oldIndex, newIndex, oldParent, newParent, dragObject);
      }
    },
    [move],
  );

  const hover = useMemo(
    () => onHover(index, parent, dropRef, dropZoneId, onMove),
    [index, parent, dropRef, onMove, dropZoneId],
  );

  const canDrop = useMemo(() => {
    return once(() => isGranted('FLOW_NODE_PID_MANAGE_BASIC', parent));
  }, [isGranted, parent]);

  const [, drop] = useDrop<ProjectDragObject>({
    hover,
    accept: 'workpackage',
    canDrop(item) {
      return (
        canDrop() &&
        !item.idsToIgnore.includes(parent) &&
        item.groupNestingLevel + getGroupDepth(parent) <= groupMaxDepth
      );
    },
    drop(item, monitor: DropTargetMonitor) {
      if (onDrop && !monitor.didDrop()) {
        onDrop(item);
      }
    },
  });

  drop(dropRef);
}

export function useProjectSortable(
  dragRef: RefObject<HTMLElement | null>,
  dropRef: RefObject<HTMLElement | null>,
  itemData: ProjectDragData,
  index: number,
  parent: Uuid,
  idsToIgnore: Uuid[],
): ProjectSortableResult {
  const isDragging = useProjectDrag(dragRef, itemData, () => index, parent, idsToIgnore);
  useProjectDrop(dropRef, index, parent, itemData.id);

  return {isDragging};
}

export async function saveDrag(
  dragObject: ProjectDragObject,
  allNodes: Map<Uuid, ProjectTreeNode>,
  dispatch: ThunkDispatch,
  resetSortOrder: () => void,
  resetNodeTree: () => void,
): Promise<void> {
  const {
    data: {id},
    startParent,
    startIndex,
    index,
    parent,
  } = dragObject;
  if (parent) {
    const siblings = getSiblings(parent, allNodes);
    if (siblings && (startParent !== parent || startIndex !== index)) {
      await dispatch(
        rearrangePids(
          parent,
          id,
          siblings.map(({id}) => id),
        ),
      );
    }

    resetSortOrder();
    resetNodeTree();
  }
}
