import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {DeepPartial} from '@octaved/typescript/src/lib';
import {withValidation} from '@octaved/validation/src/ValidationField';
import {ReactElement, useEffect, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown} from 'semantic-ui-react';
import {WorkPackagePatchData} from '../../EntityInterfaces/Pid';
import {hasPriceCategory} from '../../Modules/Pid';
import {isCustomerBillableSelector} from '../../Modules/Selectors/CustomerSelectors';
import {errorsSelector} from '../../Modules/Selectors/UiSelectors';
import {removeErrorForField} from '../../Modules/Ui';
import {createWorkPackageBillingErrorFields} from '../../Modules/WorkPackages';
import {getBillingTypeTranslatable, getSortedBillingTypes, hourlyBillableTypesSet} from '../../WorkPackage/BillingType';

const ValidationDropdown = withValidation(Dropdown);

interface Props {
  'data-selenium-id'?: string;
  createLabel: (translatable: string) => ReactElement;
  editAble?: boolean;
  pidData: WorkPackagePatchData;
  updatePid: (pid: DeepPartial<WorkPackagePatchData>) => void;
  upward?: boolean;
}

const allowedInternalOptions = new Set([
  EnumFlowPidBillingType.VALUE_EFFORT,
  EnumFlowPidBillingType.VALUE_EFFORT_EST,
  EnumFlowPidBillingType.VALUE_EFFORT_CAP,
  EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO,
]);

type TransfereablePidData = Partial<
  Pick<
    WorkPackagePatchData,
    'effortFrom' | 'effortTo' | 'fixedPrice' | 'maxEffort' | 'priceCategory' | 'usePriceCategoryPerTimeTracking'
  >
>;

function evaluateTransfereableBillingData(
  pidData: WorkPackagePatchData,
  billingType: EnumFlowPidBillingType | null,
): TransfereablePidData {
  const data: TransfereablePidData = {
    effortFrom: null,
    effortTo: null,
    fixedPrice: '',
    maxEffort: null,
  };

  if (
    billingType === EnumFlowPidBillingType.VALUE_CONTINGENT ||
    billingType === EnumFlowPidBillingType.VALUE_EFFORT_CAP ||
    billingType === EnumFlowPidBillingType.VALUE_EFFORT_EST
  ) {
    data.maxEffort = pidData.effortTo || pidData.maxEffort;
  } else if (billingType === EnumFlowPidBillingType.VALUE_FIXED_PRICE) {
    data.maxEffort = pidData.effortTo || pidData.maxEffort;
    data.fixedPrice = pidData.fixedPrice;
  } else if (billingType === EnumFlowPidBillingType.VALUE_EFFORT_FROM_TO) {
    data.effortTo = pidData.maxEffort;
  }

  if (!hasPriceCategory({billingType})) {
    data.priceCategory = null;
    data.usePriceCategoryPerTimeTracking = false;
  } else if (billingType && !hourlyBillableTypesSet.has(billingType)) {
    data.usePriceCategoryPerTimeTracking = false;
  }

  return data;
}

function getLabelTranslatable(isCustomerBillable: boolean): string {
  return isCustomerBillable ? 'dialogs:createWorkpackage.billing' : 'dialogs:createWorkpackage.timeLimit';
}

export default function PidBillingType({
  createLabel,
  editAble = false,
  pidData: pidData,
  updatePid,
  upward,
  ...props
}: Props): ReactElement {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const errors = useSelector(errorsSelector);
  const value = pidData.billingType || '';
  const isCustomerBillable = useSelector(isCustomerBillableSelector)(pidData.flowCustomer);
  const dropdownOptions = useMemo(() => {
    return getSortedBillingTypes(isCustomerBillable)
      .filter((type) => isCustomerBillable || allowedInternalOptions.has(type) || type === value)
      .map((type) => ({
        'data-selenium-id': 'pidBillingType-' + type,
        text: t(getBillingTypeTranslatable(type, isCustomerBillable)),
        value: type,
      }));
  }, [isCustomerBillable, t, value]);

  useEffect(() => {
    if (editAble && !dropdownOptions.find(({value}) => value === pidData.billingType)) {
      updatePid({billingType: dropdownOptions[0].value});
    }
  }, [dropdownOptions, pidData.billingType, editAble, updatePid]);

  //These other fields have validation depending on the billing type, so we clear them, too:
  const dependantErrors = useMemo(() => createWorkPackageBillingErrorFields(pidData.id), [pidData.id]);

  if (!editAble) {
    return pidData.billingType ? (
      <div>
        {createLabel(getLabelTranslatable(isCustomerBillable))}
        <span>
          <Trans i18nKey={getBillingTypeTranslatable(pidData.billingType, isCustomerBillable)} />
        </span>
      </div>
    ) : (
      <>-</>
    );
  }

  return (
    <>
      {createLabel(getLabelTranslatable(isCustomerBillable))}
      <ValidationDropdown
        errors={errors}
        fieldName={`billingType_${pidData.id}`}
        className={'dropdown billingTypeSelect'}
        selection
        fluid
        selectOnBlur={false}
        error
        upward={upward}
        value={value}
        onChange={(_e, {value}) => {
          dispatch(removeErrorForField(dependantErrors));
          updatePid({
            ...evaluateTransfereableBillingData(pidData, value as EnumFlowPidBillingType),
            billingType: value ? (value as EnumFlowPidBillingType) : null,
          });
        }}
        placeholder={t('general:dropdownSelectPlaceholder') || undefined}
        options={dropdownOptions}
        data-selenium-id={props['data-selenium-id']}
      />
    </>
  );
}
