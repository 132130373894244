import {useTaskListReadonlyContext} from '@octaved/flow/src/Components/Task/TaskListReadonlyContext';
import {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import {useTaskIndentation} from '@octaved/flow/src/Hooks/TaskIndentation';
import {useTimeTrackingSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {canStartTimeRecordOnNodeSelector} from '@octaved/flow/src/Modules/Selectors/TimeRecordingValidationSelectors';
import {isPid} from '@octaved/flow/src/Node/NodeIdentifiers';
import {generateStandardInspectorRoute} from '@octaved/flow/src/Routing/Routes/Inspectors/StandardInspector';
import {useSelector} from 'react-redux';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {DialogType} from './ContextMenuDialogs';
import {useReferenceNumberUrl} from '@octaved/flow/src/Node/ReferenceNumberUrl';
import {
  useGetNewGroupPidDialogRoute,
  useGetNewProjectDialogRoute,
  useGetNewSubWorkPackageDialogRoute,
  useGetNewWorkPackageDialogRoute,
} from '@octaved/flow/src/Routing/Routes/Dialogs';
import {getGroupDepthSelector, groupMaxDepth} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {IsGranted, isGrantedSelector} from '@octaved/security/src/Authorization/Authorization';
import {useTaskGroupContext} from '@octaved/flow/src/Components/Task/TaskGroup/TaskGroupContext';
import {LinkGeneratorFN, useGenerateDynamicLink} from '@octaved/flow/src/Components/DynamicLink';
import {useNodeAncestry} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {createContext, PropsWithChildren, ReactElement, useContext, useMemo} from 'react';
import {useNodeSearch} from '@octaved/flow/src/Modules/Hooks/NodeSearch';
import {currentOrgUserIdSelector} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useTaskListContext} from '@octaved/flow/src/Components/Task/TaskListContext';

interface UseContextMenuContextArgs {
  node?: NodeType;
  depth?: number;
  previousSiblingId?: string;
  openDialog: (dialogType: DialogType) => void;
  openDetails?: (nodeId: string | null) => void;
  setShowNewSiblingTask?: (show: null | 'prev' | 'next') => void;
}

const ContextMenuContext = createContext<ContextMenuContext | undefined>(undefined);

export const ContextMenuProvider = (props: PropsWithChildren<UseContextMenuContextArgs>): ReactElement => {
  const {node, depth = 0, previousSiblingId} = props;
  const usingTimeTracking = useSelector(useTimeTrackingSelector);
  const canStartTimeRecordSelector = useSelector(canStartTimeRecordOnNodeSelector);
  const canStartTimeRecord = !isPid(node) || canStartTimeRecordSelector(node.id);
  const navigate = useNavigate();
  const {readonlyOrNotManageable: taskReadonlyOrNotManageable} = useTaskListReadonlyContext();
  const {showOpenTaskInspector} = useTaskListContext();
  const {canToggleIndentation, isIndented, toggleIndentation} = useTaskIndentation(node?.id, depth, previousSiblingId);
  const referenceNumberUrl = useReferenceNumberUrl(node?.id);
  const groupDepth = useSelector((store: FlowState) => getGroupDepthSelector(store)(node?.id));
  const canCreateGroup = groupDepth < groupMaxDepth;
  const getNewWorkpackageDialogRoute = useGetNewWorkPackageDialogRoute();
  const getNewSubWorkpackageDialogRoute = useGetNewSubWorkPackageDialogRoute();
  const getNewProjectDialogRoute = useGetNewProjectDialogRoute();
  const getNewGroupPidDialogRoute = useGetNewGroupPidDialogRoute();
  const isGranted = useSelector(isGrantedSelector);
  const {focusNewTaskSection} = useTaskGroupContext();
  const generateDynamicLink = useGenerateDynamicLink();
  const ancestry = useNodeAncestry(typeof node?.id === 'string' ? node.id : null, true);
  const parent = ancestry.ancestors[1] as NodeType | undefined;
  const project = ancestry.project;
  const projectFolderId = ancestry.projectFolders[0]?.id;
  const currentUserId = useSelector(currentOrgUserIdSelector);
  const {nodeIds: favoriteNodeIds} = useNodeSearch('favorite', currentUserId);
  const isFavorite = useMemo(
    () => typeof node?.id === 'string' && favoriteNodeIds.includes(node?.id),
    [favoriteNodeIds, node?.id],
  );
  let openDetails = (): void => navigate(generateStandardInspectorRoute(node?.id));
  if (props.openDetails) {
    openDetails = () => props.openDetails?.(node?.id ?? null);
  }
  if (typeof showOpenTaskInspector === 'function' && node?.id) {
    openDetails = () => showOpenTaskInspector(node?.id);
  }

  const ctx: ContextMenuContext = {
    canCreateGroup,
    canStartTimeRecord,
    canToggleIndentation,
    generateDynamicLink,
    getNewGroupPidDialogRoute,
    getNewProjectDialogRoute,
    getNewSubWorkpackageDialogRoute,
    getNewWorkpackageDialogRoute,
    isFavorite,
    isGranted,
    isIndented,
    navigate,
    parent,
    project,
    projectFolderId,
    referenceNumberUrl,
    taskReadonlyOrNotManageable,
    toggleIndentation,
    usingTimeTracking,
    focusNewTaskSection: focusNewTaskSection.current,
    node: node!,
    openDetails: openDetails,
    openDialog: props.openDialog,
    setShowNewSiblingTask: props.setShowNewSiblingTask,
  };
  return <ContextMenuContext.Provider value={ctx}>{props.children}</ContextMenuContext.Provider>;
};

export interface ContextMenuContext {
  node: NodeType;
  parent: NodeType | undefined;
  canStartTimeRecord: boolean;
  usingTimeTracking: boolean;
  navigate: NavigateFunction;
  canToggleIndentation: boolean;
  isIndented: boolean;
  toggleIndentation: () => void;
  taskReadonlyOrNotManageable: boolean;
  openDialog: (dialogType: DialogType) => void;
  referenceNumberUrl: string;
  canCreateGroup: boolean;
  project: Project | null;
  getNewWorkpackageDialogRoute: (parentNodeId: string) => string;
  getNewSubWorkpackageDialogRoute: (parentNodeId: string) => string;
  getNewProjectDialogRoute: () => string;
  getNewGroupPidDialogRoute: (parentNodeId: string) => string;
  isGranted: IsGranted;
  projectFolderId: string;
  focusNewTaskSection: (() => void) | undefined;
  generateDynamicLink: LinkGeneratorFN;
  isFavorite: boolean;
  openDetails: () => void;
  setShowNewSiblingTask?: (show: null | 'prev' | 'next') => void;
}

export const useContextMenuContext = (): ContextMenuContext => {
  const context = useContext(ContextMenuContext);
  if (!context) {
    throw new Error('ContextMenuContext is not available');
  }
  return context;
};
