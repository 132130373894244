import {useLoading} from '@octaved/flow/src/Modules/Hooks/Loading';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Milestone} from '@octaved/planning/src/EntityInterfaces/Milestones';
import {getMilestones, updateMilestone} from '@octaved/planning/src/Modules/Milestones';
import {
  getMilestoneSelector,
  getMilestonesIdsForNodeSelector,
  milestoneEntityStatesSelector,
} from '@octaved/planning/src/Selectors/MilestoneSelectors';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {
  DatePicker,
  FormatDate,
  FormatDateFormats,
  LoadingDots,
  TWAutosaveInput,
  cn,
  tableGrid,
  tableGridCellVariant,
} from '@octaved/ui';
import {boolFilter} from '@octaved/utilities';
import {Fragment, ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import MilestoneMenu from './Components/MilestoneRowMenu';
import NewMilestone from './Components/NewMilestone';

export interface NodeMilestonesProps {
  nodeId: Uuid;
  readonly: boolean;
}

export default function NodeMilestones({
  frame: Frame,
  nodeId,
  readonly,
}: NodeMilestonesProps & FramedSectionOrTileProps): ReactElement | null {
  const {hasLoadedOnce} = useLoading([nodeId], milestoneEntityStatesSelector, getMilestones);
  const nodeMilestoneIds = useSelector((s: FlowState) => getMilestonesIdsForNodeSelector(s)(nodeId));
  const getMilestone = useSelector(getMilestoneSelector);
  const canReadPlanning = useIsGranted('FLOW_NODE_READ_PLANNING', nodeId);
  const milestones = useMemo(
    () => boolFilter<Milestone>(nodeMilestoneIds.map((id) => getMilestone(nodeId, id))),
    [nodeMilestoneIds, getMilestone, nodeId],
  );
  const {t} = useTranslation();
  const dispatch = useDispatch();

  if (!canReadPlanning) {
    return null;
  }

  return (
    <Frame labelToken={'nodeDetails:field.milestones.label'}>
      {!hasLoadedOnce && (
        <div className={'flex items-center justify-center py-4'}>
          <LoadingDots />
        </div>
      )}
      <div className={cn(tableGrid({className: 'grid-cols-[180px_1fr_max-content]', isBelowLabel: true}))}>
        {milestones.length > 0 && (
          <>
            <div className={cn(tableGridCellVariant({isHeader: true, position: 'left'}))}>
              {t('nodeDetails:field.milestones.tableHeader.date')}
            </div>
            <div className={cn(tableGridCellVariant({isHeader: true}))}>
              {t('nodeDetails:field.milestones.tableHeader.name')}
            </div>
            <div className={cn(tableGridCellVariant({isHeader: true, position: 'right'}))}>&nbsp;</div>
          </>
        )}
        {readonly &&
          milestones.map((milestone) => (
            <Fragment key={milestone.id}>
              <div className={cn(tableGridCellVariant({position: 'left'}))}>
                <FormatDate date={milestone.milestoneDate} format={FormatDateFormats.short_with_year} />
              </div>
              <div className={cn(tableGridCellVariant({position: 'right', className: 'col-span-2'}))}>
                {milestone.name}
              </div>
            </Fragment>
          ))}
        {!readonly &&
          milestones.map((milestone) => (
            <Fragment key={milestone.id}>
              <div className={cn(tableGridCellVariant({position: 'left'}))}>
                <DatePicker
                  date={milestone.milestoneDate}
                  setDate={(date: DateStr | null) => {
                    if (date) {
                      dispatch(updateMilestone(nodeId, milestone.id, {milestoneDate: date}));
                    }
                  }}
                  footerOverride={{canClear: false}}
                  triggerOverride={{placeholderToken: 'nodeDetails:field.milestones.date.placeholder'}}
                  triggerClassName={'w-full'}
                />
              </div>

              <div className={cn(tableGridCellVariant())}>
                <TWAutosaveInput
                  type={'text'}
                  value={milestone.name}
                  onSave={(name) => {
                    dispatch(updateMilestone(nodeId, milestone.id, {name}));
                  }}
                  placeholder={t('nodeDetails:field.milestones.name.placeholder')}
                />
              </div>
              <MilestoneMenu milestone={milestone} nodeId={nodeId} />
            </Fragment>
          ))}
        <NewMilestone readonly={readonly} nodeId={nodeId} />
      </div>
    </Frame>
  );
}
