import {PropsWithChildren, ReactElement} from 'react';

interface Props {
  color: string;
}

export default function PageStatusBar({children, color}: PropsWithChildren<Props>): ReactElement {
  return (
    <div className={'notVerifiedBar'}>
      {children}
      <style jsx>{`
        .notVerifiedBar {
          height: 40px;
          background-color: ${color};
          position: relative;
          z-index: 10;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 14px;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
}
