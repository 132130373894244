import {Uuid} from '@octaved/typescript/src/lib';

import {Tooltip} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useNodeResponsibleFullUnits} from '../../../Modules/Hooks/Nodes';
import BaseFlowAvatar from '../../Avatar/BaseFlowAvatar';

interface Props {
  id: Uuid;
  noTooltip?: boolean;
}

export default function ResponsibleUnits({id, noTooltip = false}: Props): ReactElement | null {
  const {t} = useTranslation();
  const responsibleFullUnits = useNodeResponsibleFullUnits(id);
  if (responsibleFullUnits.length > 0) {
    const firstEntry = responsibleFullUnits[0];
    return (
      <Tooltip
        disabled={noTooltip}
        position={'top left'}
        toolTipTranslation={''}
        content={
          <>
            {t('workPackage:tooltips.executedBy')}
            :&nbsp;
            {responsibleFullUnits.map(({unitName}) => unitName).join(', ')}
          </>
        }
      >
        <div className={'row'}>
          {firstEntry && (
            <BaseFlowAvatar id={firstEntry.unitId} type={firstEntry.unitType} className={'my-0'} size={'small'} />
          )}

          {responsibleFullUnits.length > 1 && <>+{responsibleFullUnits.length - 1}</>}

          {/*#region styles*/}
          {/*language=scss*/}
          <style jsx>{`
            .row {
              display: flex;
              align-items: center;
              font-size: 10px;
              color: #666;
              grid-gap: 4px;
            }
          `}</style>
          {/*#endregion*/}
        </div>
      </Tooltip>
    );
  }

  return null;
}
