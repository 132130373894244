import {WebsiteLanguage, useWebsiteLanguageFromContext} from '@octaved/flow/src/WebsiteLinks';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';

type WebsiteRouteIdent = 'tutorial_project_roles' | 'tutorial_permission_roles';

const websiteLinks: Record<WebsiteLanguage, Record<WebsiteRouteIdent, string>> = {
  de: {
    tutorial_permission_roles: 'https://flow.octaved.com/tutorials/berechtigungsrollen',
    tutorial_project_roles: 'https://flow.octaved.com/tutorials/projektrollen',
  },
  en: {
    tutorial_permission_roles: 'https://flow.octaved.com/en/tutorials/permission-roles',
    tutorial_project_roles: 'https://flow.octaved.com/en/tutorials/project-roles',
  },
};

function getLinkUrl(routeIdent: WebsiteRouteIdent, language: WebsiteLanguage): string {
  return websiteLinks[language][routeIdent];
}

export interface HelpLinkProps {
  routeIdent: WebsiteRouteIdent;
  token: string;
}

export default function HelpLink({routeIdent, token}: HelpLinkProps): ReactElement {
  const {t} = useTranslation();
  const language = useWebsiteLanguageFromContext();
  const links = getLinkUrl(routeIdent, language);
  return (
    <span>
      <Trans
        i18nKey={token}
        components={{
          b: <b />,
          br: <br />,
          i: <i />,
        }}
      />
      &nbsp;
      <a
        href={links}
        target={'_blank'}
        rel={'noreferrer'}
        className={'cursor-pointer text-sky-700 underline transition-colors hover:text-sky-600 hover:underline'}
      >
        {t('general:openTutorial')}
      </a>
    </span>
  );
}
