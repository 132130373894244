import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {getAllDescendantsForRootIds} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {useMemo} from 'react';
import {EffectiveRoleAssignment} from '../../EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {useEffectiveRoleAssignmentsOnNodes} from '../Hooks/NodeRoleAssignments';
import {useLoadNodes as useLoadNodesDefault} from '../Hooks/Nodes';
import {ProjectTreeOptions} from './ProjectTreeInterfaces';

export function useLoadExpandedNodes(
  {nodeTree, useLoadNodes}: ProjectTreeOptions,
  expandedNodeIds: ReadonlySet<Uuid>,
  nodeIds: ReadonlySet<Uuid>,
  projectIds: ReadonlySet<Uuid>,
  groupIds: ReadonlySet<Uuid>,
  workPackageIds: ReadonlySet<Uuid>,
): {
  assignmentsOnNodes: Record<Uuid, EffectiveRoleAssignment[]>;
  isLoading: boolean;
} {
  const nodeIdsToLoad = useMemo(() => {
    //We want to load all the projects plus all groups/work-packages of expanded projects:
    const expandedProjectIds = [...projectIds].filter((id) => expandedNodeIds.has(id));
    const descendants = [...projectIds, ...getAllDescendantsForRootIds(nodeTree, expandedProjectIds)].filter((id) =>
      nodeIds.has(id),
    );
    const toLoadSet = new Set(descendants);
    return {
      all: descendants,
      groupIds: [...groupIds].filter((id) => toLoadSet.has(id)),
      projectIds: [...projectIds].filter((id) => toLoadSet.has(id)),
      workPackageIds: [...workPackageIds].filter((id) => toLoadSet.has(id)),
    };
  }, [projectIds, nodeTree, groupIds, workPackageIds, expandedNodeIds, nodeIds]);

  const {isLoading: roleAssignmentsLoading, assignmentsOnNodes} = useEffectiveRoleAssignmentsOnNodes(
    EnumFlowNodeRoleType.VALUE_PROJECT,
    nodeIdsToLoad.all,
  );

  const {isLoading: isLoadingNodes} = useLoadNodes
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useLoadNodes(nodeIdsToLoad.all, nodeIdsToLoad)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useLoadNodesDefault(nodeIdsToLoad.all, true);

  return {
    assignmentsOnNodes,
    isLoading: roleAssignmentsLoading || isLoadingNodes,
  };
}
