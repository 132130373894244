import {EntityStates} from '@octaved/store/src/EntityState';
import {Uuid} from '@octaved/typescript/src/lib';
import memoize from 'lodash/memoize';
import {createSelector} from 'reselect';
import {TimeRecord, TimeRecords} from '../../EntityInterfaces/TimeRecords';
import {FlowState} from '../State';
import {getUserTimeRecordsSelector} from './TimeRecordSearchSelectors';

export const timeRecordsEntityStatesSelector = (state: FlowState): EntityStates => state.entityStates.timeRecord;

export const timeRecordsSelector = (state: FlowState): TimeRecords => state.entities.timeRecord;
export const getTimeRecordSelector = createSelector(
  timeRecordsSelector,
  (timeRecords) => (timeRecordId: Uuid | undefined | null) => timeRecords[timeRecordId!],
);
export const getTimeRecordsForIdsSelector = createSelector(
  timeRecordsSelector,
  (timeRecords) =>
    (timeRecordIds: ReadonlyArray<Uuid>): TimeRecord[] =>
      timeRecordIds.map((id) => timeRecords[id]).filter(Boolean) as TimeRecord[],
);

export const getRunningTimeRecordForUserSelector = createSelector(
  getUserTimeRecordsSelector,
  getTimeRecordsForIdsSelector,
  (getUserTimeRecords, timeRecordsForIdsFn) =>
    memoize((userId: Uuid) => {
      const userRecords = getUserTimeRecords(userId);
      if (userRecords) {
        const timeRecords = timeRecordsForIdsFn(userRecords);
        return timeRecords
          .filter((rec) => rec.workTimeEnd === null)
          .sort((a, b) => b.workTimeStart! - a.workTimeStart!)[0];
      }
      return undefined;
    }),
);
