import {difference} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {FilterState} from '../../EntityInterfaces/Filter/FilterState';
import {isArrayFilter} from './ArrayFilter';

function filterToActiveStates<FK extends string, States extends Record<FK, FilterState<unknown>>>(
  filterStates: Partial<States>,
): Partial<States> {
  const clone = cloneDeep(filterStates);
  Object.keys(clone).forEach((_key) => {
    const key = _key as keyof States;
    const state = clone[key];
    if (
      !state ||
      !state.isActive ||
      (key as string).startsWith('quick') ||
      (isArrayFilter(state) && state.value.length === 0)
    ) {
      delete clone[key];
    }
  });
  return clone;
}

export function doFilterStatesDeviateFromDefaults<FK extends string, States extends Record<FK, FilterState<unknown>>>(
  filterStates: Partial<States>,
  defaultFilterStates: Partial<States>,
): boolean {
  const cleanedFilterStates = filterToActiveStates(filterStates);
  const cleanedDefaultFilterStates = filterToActiveStates(defaultFilterStates);
  const filteStateKeys = Object.keys(cleanedFilterStates);
  const defaultStateKeys = Object.keys(cleanedDefaultFilterStates);

  if (difference(filteStateKeys, defaultStateKeys).length || difference(defaultStateKeys, filteStateKeys).length) {
    return true;
  }

  for (const [_key, _item] of Object.entries(cleanedDefaultFilterStates)) {
    const key = _key as FK;
    const defaultFilter = _item as FilterState<unknown>;
    const filter = cleanedFilterStates[key];

    if (!filter) {
      return true;
    }

    if (isArrayFilter(defaultFilter) && isArrayFilter(filter)) {
      if (defaultFilter.value.length !== filter.value.length) {
        return true;
      }

      //The saved user filters can be in any order, so we have to find each entry:
      for (const defaultArrayValue of defaultFilter.value) {
        if (!filter.value.find((arrayValue) => isEqual(arrayValue, defaultArrayValue))) {
          return true;
        }
      }
    } else if (!isEqual(filter, defaultFilter)) {
      return true;
    }
  }

  return false;
}
