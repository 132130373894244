import {useProjectPatch} from '@octaved/flow/src/Modules/Projects';
import BaselineComparisonDrawer from '@octaved/planning/src/Components/BaselineComparison/BaselineComparisonDrawer';
import ShowMovePlanningButton from '@octaved/planning/src/Dialogs/MovePlanning/ShowMovePlanningButton';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {TWButton} from '@octaved/ui';
import {CheckSquare} from 'lucide-react';
import {ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import NodeDueDateSection from '../../../Components/Fields/Planning/Section/NodeDueDateSection';
import NodeMilestonesSection from '../../../Components/Fields/Planning/Section/NodeMilestonesSection';
import NodeSubProjectsDueDatesSection from '../../../Components/Fields/Planning/Section/NodeSubProjectsDueDatesSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {ProjectDetailsProps} from './General';

export default function Planning({node}: ProjectDetailsProps): ReactElement {
  const patch = useProjectPatch(node.id);
  const readonly = !useIsGranted('FLOW_NODE_PID_MANAGE_BASIC', node.id) || node.isArchived;
  const [baselineComparisonVisible, setBaselineComparisonVisible] = useState(false);
  const {t} = useTranslation();

  return (
    <InspectorViewFrame
      footer={
        <div className={'flex justify-center gap-x-2'}>
          <ShowMovePlanningButton node={node} />
          <BaselineComparisonDrawer
            close={() => setBaselineComparisonVisible(false)}
            rootNodeId={node.id}
            visible={baselineComparisonVisible}
            trigger={
              <TWButton size={'md'} withIcon variant={'outline'} onClick={() => setBaselineComparisonVisible(true)}>
                <CheckSquare size={16} />
                {t('pages:planning.inspector.openBaselineComparison')}
              </TWButton>
            }
          />
        </div>
      }
    >
      <NodeDueDateSection node={node} readonly={readonly} onChangeDueDate={(dueDate) => patch({dueDate})} />
      <NodeSubProjectsDueDatesSection nodeId={node.id} />
      <NodeMilestonesSection nodeId={node.id} readonly={readonly} />
    </InspectorViewFrame>
  );
}
