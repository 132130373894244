import {HStack, Icon, Tooltip} from '@octaved/ui';
import {Link} from 'lucide-react';
import {memo, ReactElement} from 'react';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {isWorkPackage} from '../../../Node/NodeIdentifiers';
import FlowLabels from '../../Label/FlowLabel/FlowLabels';
import FavoriteStar from '../../Pid/FavoriteStar';
import Path from '../../WorkPackage/Tile/Path';
import Planning from '../../WorkPackage/Tile/Planning';
import Progress from '../../WorkPackage/Tile/Progress';
import StatusIcons from '../../WorkPackage/Tile/StatusIcons';

interface Props {
  node: WorkPackage | SubWorkPackage;
}

export default memo(function WpOrSwpDetails({node}: Props): ReactElement {
  return (
    <HStack spacing={'10px'} alignItems={'center'}>
      {isWorkPackage(node) && node.isAutoChainActive && (
        <>
          <Tooltip toolTipTranslation={'taskList:tooltips.autoChain'}>
            <Icon iconColor={'black'} noMargin>
              <Link />
            </Icon>
          </Tooltip>
          <div className={'divider'} />
        </>
      )}
      <Path id={node.id} autoHeight noPadding />

      {(node.planningDates.length || 0) > 0 && (
        <>
          <div className={'divider'} />
          <div className={'planning'}>
            <Planning id={node.id} />
          </div>
        </>
      )}

      {node.labels.length > 0 && (
        <>
          <div className={'divider'} />
          <div className={'labels'}>
            <FlowLabels noMargin showDots labels={node.labels} />
          </div>
        </>
      )}

      <div className={'divider'} />
      <StatusIcons node={node} />
      <Progress node={node} />
      <FavoriteStar nodeId={node.id} toolTipPosition={'top right'} />

      <style jsx>{`
        .planning {
          font-size: 12px;
          line-height: 16px;
          color: var(--color-font-workpackage-minorDetails, #888);
        }

        .divider {
          height: 1.2em;
          width: 1px;
          background-color: #ccc;
        }
      `}</style>
    </HStack>
  );
});
