import GeneralValidationInputField from '@octaved/semantic-ui/src/ValidationInputField';
import {Notification, NotificationRemoveTypes} from '@octaved/store/src/Notifications';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {ReactElement} from 'react';
import {InputProps} from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import {errorsSelector} from '../../../Modules/Selectors/UiSelectors';
import {removeErrorForField} from '../../../Modules/Ui';

interface Props extends Omit<InputProps, 'onChange'> {
  fieldName: string;
  setValue: (v: string) => void;
  inputType?: string;

  removeError?(fieldName: NotificationRemoveTypes): ActionDispatcher<void>;

  getErrorSelector?(state: unknown): Notification[];
}

export default function ValidationInputField({
  removeError = removeErrorForField,
  getErrorSelector = errorsSelector,
  ...props
}: Props): ReactElement {
  return (
    <>
      <GeneralValidationInputField removeError={removeError} getErrorSelector={getErrorSelector} {...props} />
    </>
  );
}
