import {AuditTaskSection} from '../../../../EntityInterfaces/Audit/AuditTaskSection';
import {AuditTableConfiguration} from './Confgurations';
import {nodeColumns} from './Node';

const config: AuditTableConfiguration<AuditTaskSection> = {
  columns: nodeColumns,
  tableIdent: 'taskSections',
  tableNameTranslationToken: 'systemSettings:audit.taskSectionTableName',
};
export default config;
