import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  WorkPackageSingleSelectLeftDrawerUiState,
  workPackageSingleSelectLeftDrawerUiState,
} from '../../Pages/Components/LeftDrawer/ItemTypes/WorkPackage/WorkPackageLeftDrawer';
import {ThunkAct} from '../../Store/Thunk';
import {
  trackTimeExpandedTeamUserIdsSelector,
  trackTimeTeamUserIdsSelector,
} from '../Selectors/UiPages/TrackTimeSelectors';
import {setUiState} from '../Ui';

export enum TrackTimeDescriptionDisplay {
  asIcon,
  asText,
  hidden,
}

export enum TrackTimeViews {
  myTimeRecordings = 'myTimeRecordings',
  myWorkTime = 'myWorkTime',
  week = 'week',
  team = 'team',
}

export interface TrackTimeUiState {
  createTimeTrackingDragging: boolean;
  descriptionDisplay: TrackTimeDescriptionDisplay;
  expandedTeamUserIds: Uuid[];
  leftDrawer: WorkPackageSingleSelectLeftDrawerUiState & {
    selectedCustomer: null | Uuid;
    showCustomerSelector: boolean;
  };
  selectedView: TrackTimeViews;
  showBillableInfo: boolean;
  showBillableTimes: boolean;
  table: {
    showCustomerColumn: boolean;
  };
  teamUserIds: Uuid[];
}

export const initialTrackTimeUiState: TrackTimeUiState = {
  createTimeTrackingDragging: true,
  descriptionDisplay: TrackTimeDescriptionDisplay.asText,
  expandedTeamUserIds: [],
  leftDrawer: {
    ...workPackageSingleSelectLeftDrawerUiState,
    columnCount: 1,
    isOpen: true,
    searchScope: 'mine',
    selectedCustomer: null,
    showCustomerSelector: false,
  },
  selectedView: TrackTimeViews.myTimeRecordings,
  showBillableInfo: true,
  showBillableTimes: true,
  table: {
    showCustomerColumn: true,
  },
  teamUserIds: [],
};

export function usePatchTrackTimeUiState(): (patch: DeepPartial<TrackTimeUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((trackTime) => dispatch(setUiState({pages: {trackTime}})), [dispatch]);
}

export function useToggleTeamUserId(): (userId: Uuid) => void {
  const selectedTeamIds = useSelector(trackTimeTeamUserIdsSelector);
  const expandedTeamIds = useSelector(trackTimeExpandedTeamUserIdsSelector);
  const patch = usePatchTrackTimeUiState();
  return useCallback(
    (userId) => {
      const isSelected = selectedTeamIds.includes(userId);
      const teamUserIds = isSelected ? selectedTeamIds.filter((id) => id !== userId) : selectedTeamIds.concat(userId);

      const data: DeepPartial<TrackTimeUiState> = {teamUserIds};
      if (!isSelected) {
        data.expandedTeamUserIds = [...new Set(expandedTeamIds.concat(userId))];
      }

      patch(data);
    },
    [expandedTeamIds, patch, selectedTeamIds],
  );
}

export function useToggleExpandedTeamUserId(): (userId: Uuid) => void {
  const expandedTeamIds = useSelector(trackTimeExpandedTeamUserIdsSelector);
  const patch = usePatchTrackTimeUiState();
  return useCallback(
    (userId) => {
      const expandedTeamUserIds = expandedTeamIds.includes(userId)
        ? expandedTeamIds.filter((id) => id !== userId)
        : expandedTeamIds.concat(userId);
      patch({expandedTeamUserIds});
    },
    [patch, expandedTeamIds],
  );
}

export function patchTrackTimeLeftDrawerUiState(patch: Partial<TrackTimeUiState['leftDrawer']>): ThunkAct<void> {
  return setUiState({pages: {trackTime: {leftDrawer: patch}}});
}
