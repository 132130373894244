import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {useObjectSnapshot} from '@octaved/hooks';
import {Label} from '@octaved/ui';
import {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import VerticalForm from '../../Components/Form/FormGrids/VerticalForm';
import ValidationInputField from '../../Components/Form/Input/ValidationInputField';
import {Group, GroupCreationData, Project} from '../../EntityInterfaces/Pid';
import {useCloseAndSelectNewNode} from '../../Hooks/CloseNodeCreationDialog';
import {useClearErrorFields, useSiblings} from '../../Hooks/CreatePid';
import {createGroup, createGroupEntity, createGroupErrorFields, transformGroupToPatchData} from '../../Modules/Groups';
import {getGroupDepthSelector} from '../../Modules/Selectors/GroupSelectors';
import {getParentPidSelector} from '../../Modules/Selectors/PidSelectors';
import {projectsOpenNodesSetSelector} from '../../Modules/Selectors/UiSelectors';
import {setUiState} from '../../Modules/Ui';
import GroupLocation from './GroupLocation';
import GroupParent from './GroupParent';
import GroupPosition from './GroupPosition';
import GroupType from './GroupType';
import {isGroup} from '../../Node/NodeIdentifiers';

export default function NewGroupFormBody({
  relative,
  after,
  before,
}: {
  relative: Project | Group;
  after?: boolean;
  before?: boolean;
}): ReactElement {
  const getGroupDepth = useSelector(getGroupDepthSelector);
  const getParentPid = useSelector(getParentPidSelector);
  const relativeGroupDepth = relative.nodeType === EnumFlowNodeType.VALUE_GROUP ? getGroupDepth(relative.id) : -1;
  const [parent, setParent] = useState<Project | Group>(
    (relative.nodeType === EnumFlowNodeType.VALUE_PROJECT || relativeGroupDepth === 1) && !after && !before
      ? relative
      : (getParentPid(relative.id) as Group),
  );
  const [position, setPosition] = useState(-1);
  const siblings = useSiblings(parent);
  useEffect(() => {
    if (after && isGroup(relative)) {
      setPosition(siblings.indexOf(relative));
    } else if (before && isGroup(relative)) {
      setPosition(siblings.indexOf(relative) - 1);
    } else {
      setPosition(siblings.length - 1);
    }
  }, [after, before, relative, siblings]);

  const dispatch = useDispatch();
  const {snapshot, patch} = useObjectSnapshot(() =>
    transformGroupToPatchData<GroupCreationData>(createGroupEntity(parent.flowCustomer)),
  );

  const projectsOpenNodes = useSelector(projectsOpenNodesSetSelector);
  const closeAndSelectNewPid = useCloseAndSelectNewNode();
  const create = useCallback(async () => {
    const sortedSiblingIds = siblings.map(({id}) => id);
    sortedSiblingIds.splice(position + 1, 0, snapshot.id);
    if (await dispatch(createGroup(snapshot, parent.id, sortedSiblingIds))) {
      const newOpenNodes = new Set(projectsOpenNodes);
      newOpenNodes.add(snapshot.id);
      dispatch(
        setUiState({
          pages: {
            projects: {
              //Open the new group node in the tree:
              openNodes: [...newOpenNodes],
            },
          },
        }),
      );
      closeAndSelectNewPid(snapshot.id, snapshot.nodeType);
    }
  }, [siblings, position, snapshot, dispatch, parent.id, projectsOpenNodes, closeAndSelectNewPid]);

  const canEditParent = relativeGroupDepth === 1;
  const canEditPosition = siblings.length > 0;

  const [editLocation, setEditLocation] = useState(false);

  const errorFields = useClearErrorFields(snapshot.id, createGroupErrorFields);

  return (
    <VerticalForm onSubmit={create} submitLabel={'general:create'} includeErrorFields={errorFields}>
      <div>
        <Label token={'dialogs:createGroup.groupName'} />
        <ValidationInputField
          fieldName={`name_${snapshot.id}`}
          autoFocus
          setValue={(name) => patch({name})}
          value={snapshot.name}
        />

        <GroupType group={snapshot} patch={patch} parent={parent} />

        {!editLocation && (
          <GroupLocation
            canEditLocation={canEditParent || canEditPosition}
            edit={() => setEditLocation(true)}
            parent={parent}
          />
        )}
        {editLocation && (
          <>
            {canEditParent && (
              <GroupParent
                relative={relative as Group}
                inside={relative === parent}
                below={relative !== parent}
                setBelow={() => setParent(getParentPid(relative.id) as Project)}
                setInside={() => setParent(relative)}
              />
            )}

            {canEditPosition && <GroupPosition position={position} setPosition={setPosition} siblings={siblings} />}
          </>
        )}
      </div>
    </VerticalForm>
  );
}
