import {FlowState} from '@octaved/flow/src/Modules/State';
import {Uuid} from '@octaved/typescript/src/lib';
import {ProjectBasedContext, ProjectBasedContextProps} from '../../../Canvas/ProjectBasedGantt/ProjectBasedContext';
import {BarTextDisplayment, setBaselineComparisonState, setBaselineSettingsForNode} from '../../../Modules/Ui';
import {
  baselineComparisonBarTextDisplaymentSelector,
  baselineComparisonColumnWidthSelector,
  getExtendedNodesForBaselineSelector,
  getVisibleColumnsForBaselineSelector,
} from '../../../Selectors/UiSelectors';

interface GanttComparisonContextProps extends ProjectBasedContextProps {
  rootNodeId: Uuid;
}

export class GanttComparisonContext extends ProjectBasedContext {
  readonly rootNodeId: Uuid;

  constructor(props: GanttComparisonContextProps) {
    super(props);
    this.rootNodeId = props.rootNodeId;
  }

  protected getVisibleColumnsSelector(state: FlowState): string[] {
    return getVisibleColumnsForBaselineSelector(state)(this.rootNodeId);
  }
  protected getExtendedNodesSelector(state: FlowState): Record<string, boolean> | null {
    return getExtendedNodesForBaselineSelector(state)(this.rootNodeId);
  }
  protected getColumnsWidthSelector(state: FlowState): number {
    return baselineComparisonColumnWidthSelector(state);
  }

  set columnWidth(columnWidth: number) {
    super.columnWidth = columnWidth;
    this.store.dispatch(setBaselineComparisonState({columnWidth}));
  }

  get columnWidth(): number {
    return super.columnWidth;
  }

  setVisibleColumns(visibleColumns: string[]): void {
    this.store.dispatch(setBaselineSettingsForNode(this.rootNodeId, {visibleColumns}));
  }

  setExtendedNodes(extendedNodes: Record<string, boolean>): void {
    this.store.dispatch(setBaselineSettingsForNode(this.rootNodeId, {extendedNodes}));
  }

  setSelectedNodeId(_nodeId: Uuid | null): void {
    //nothing to do here
  }

  getBarTextDisplaymentSelector(state: FlowState): BarTextDisplayment {
    return baselineComparisonBarTextDisplaymentSelector(state);
  }
}
