import {Tooltip} from '@octaved/ui';
import {ReactElement} from 'react';
import {WorkPackage} from '../../../EntityInterfaces/Pid';
import {SubWorkPackage} from '../../../EntityInterfaces/SubWorkPackage';
import {useNodeAncestry} from '../../../Modules/Selectors/NodeTreeSelectors';
import {isWorkPackage} from '../../../Node/NodeIdentifiers';
import {nodeStatusLabels} from '../../../Node/NodeStatus/NodeStatus';
import {NodeStatusIcon} from '../../../Node/NodeStatus/NodeStatusIcon';
import {IsArchivedIcon, IsCompletedIcon, IsOfferIcon, IsTemplateIcon} from '../../../Node/NodeStatus/NodeStatusIcons';
import OverdueIcon from './OverdueIcon';
import ResponsibleUnits from './ResponsibleUnits';

interface Props {
  node: WorkPackage | SubWorkPackage;
}

export default function StatusIcons({node}: Props): ReactElement | null {
  const {project, workPackage} = useNodeAncestry(node.id, true);
  return (
    <div className={'flex items-center gap-x-1'}>
      <ResponsibleUnits id={node.id} />
      <OverdueIcon node={node} />
      {node.isCompleted && (
        <Tooltip toolTipTranslation={nodeStatusLabels.isCompleted}>
          <div>
            <IsCompletedIcon />
          </div>
        </Tooltip>
      )}
      {(!isWorkPackage(node) || !node.isOffer) && <NodeStatusIcon nodeId={node.id} noOpenStatus />}
      {workPackage?.isOffer && <IsOfferIcon />}
      {project?.isTemplate && <IsTemplateIcon />}
      {node.isArchived && <IsArchivedIcon />}
    </div>
  );
}
