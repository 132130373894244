import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {projectsSubMenuCollapsedSelector} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {setUiState} from '../../Modules/Ui';
import MenuPage from '../../Pages/PageFrame/MenuPage';
import {PagesSubMenuOption} from '../../Pages/PagesSubMenu';
import LastVisitRedirect from '../LastVisitRedirect';
import {controlActivityInspectorRoutes} from './Inspectors/ControlActivityInspector';
import {controlCriticalTimePrognosisInspectorRoutes} from './Inspectors/ControlCriticalTimePrognosisInspector';
import {controlDegreeOfCompletionInspectorRoutes} from './Inspectors/ControlDegreeOfCompletionInspector';
import {controlFixedPriceAnalysisInspectorRoutes} from './Inspectors/ControlFixedPriceAnalysisInspector';
import {controlInProgressInspectorRoutes} from './Inspectors/ControlInProgressInspector';
import {controlPlanningTransgressionInspectorRoutes} from './Inspectors/ControlPlanningTransgressionInspector';
import {controlTimeTransgressionInspectorRoutes} from './Inspectors/ControlTimeTransgressionInspector';
import {controlTrackedTimeInspectorRoutes} from './Inspectors/ControlTrackedTimeInspector';

const controlActivityRoute = 'controlActivity';
const controlCriticalTimePrognosisRoute = 'controlCriticalTimePrognosis';
const controlDegreeOfCompletionRoute = 'controlDegreeOfCompletion';
const controlFixedPriceAnalysisRoute = 'controlFixedPriceAnalysis';
const controlInProgressRoute = 'controlInProgress';
const controlPlanningTransgressionRoute = 'controlPlanningTransgression';
const controlTimeTransgressionRoute = 'controlTimeTransgression';
const controlTrackedTimeRoute = 'controlTrackedTime';

const ControlActivity = lazy(() => import('../../Pages/Projects/Pages/ControlActivity/ControlActivity'));
const ControlCriticalTimePrognosis = lazy(
  () => import('../../Pages/Projects/Pages/ControlCriticalTimePrognosis/ControlCriticalTimePrognosis'),
);
const ControlDegreeOfCompletion = lazy(
  () => import('../../Pages/Projects/Pages/ControlDegreeOfCompletion/ControlDegreeOfCompletion'),
);
const ControlFixedPriceAnalysis = lazy(
  () => import('../../Pages/Projects/Pages/ControlFixedPriceAnalysis/ControlFixedPriceAnalysis'),
);
const ControlInProgress = lazy(() => import('../../Pages/Projects/Pages/ControlInProgress/ControlInProgress'));
const ControlPlanningTransgression = lazy(
  () => import('../../Pages/Projects/Pages/ControlPlanningTransgression/ControlPlanningTransgression'),
);
const ControlTimeTransgression = lazy(
  () => import('../../Pages/Projects/Pages/ControlTimeTransgression/ControlTimeTransgression'),
);
const ControlTrackedTime = lazy(() => import('../../Pages/Projects/Pages/ControlTrackedTime/ControlTrackedTime'));

//#region menu
const projectControllingMenuOptions: PagesSubMenuOption[] = [
  {
    header: true,
    name: 'pages:projects.subMenu.projectControlling',
  },
  {
    name: 'pages:projects.subMenu.controlInProgress',
    path: controlInProgressRoute,
  },
  {
    name: 'pages:projects.subMenu.controlDegreeOfCompletion',
    path: controlDegreeOfCompletionRoute,
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    name: 'pages:projects.subMenu.controlTrackedTime',
    path: controlTrackedTimeRoute,
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    name: 'pages:projects.subMenu.controlTimeTransgression',
    path: controlTimeTransgressionRoute,
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    name: 'pages:projects.subMenu.controlCriticalTimePrognosis',
    path: controlCriticalTimePrognosisRoute,
  },
  {
    name: 'pages:projects.subMenu.controlPlanningTransgression',
    path: controlPlanningTransgressionRoute,
  },
  {
    access: ({settings: {useTimeTracking}}) => useTimeTracking,
    name: 'pages:projects.subMenu.controlFixedPriceAnalysis',
    path: controlFixedPriceAnalysisRoute,
  },
  {
    name: 'pages:projects.subMenu.controlActivity',
    path: controlActivityRoute,
  },
];
//#endregion

export const projectControllingRoutes: RouteObject[] = [
  {
    children: [
      {
        children: controlActivityInspectorRoutes,
        element: <ControlActivity />,
        path: `${controlActivityRoute}/*`,
      },
      {
        children: controlCriticalTimePrognosisInspectorRoutes,
        element: <ControlCriticalTimePrognosis />,
        path: `${controlCriticalTimePrognosisRoute}/*`,
      },
      {
        children: controlDegreeOfCompletionInspectorRoutes,
        element: <ControlDegreeOfCompletion />,
        path: `${controlDegreeOfCompletionRoute}/*`,
      },
      {
        children: controlFixedPriceAnalysisInspectorRoutes,
        element: <ControlFixedPriceAnalysis />,
        path: `${controlFixedPriceAnalysisRoute}/*`,
      },
      {
        children: controlInProgressInspectorRoutes,
        element: <ControlInProgress />,
        path: `${controlInProgressRoute}/*`,
      },
      {
        children: controlPlanningTransgressionInspectorRoutes,
        element: <ControlPlanningTransgression />,
        path: `${controlPlanningTransgressionRoute}/*`,
      },
      {
        children: controlTimeTransgressionInspectorRoutes,
        element: <ControlTimeTransgression />,
        path: `${controlTimeTransgressionRoute}/*`,
      },
      {
        children: controlTrackedTimeInspectorRoutes,
        element: <ControlTrackedTime />,
        path: `${controlTrackedTimeRoute}/*`,
      },
    ],
    element: (
      <MenuPage
        menuOptions={projectControllingMenuOptions}
        collapsedSelector={projectsSubMenuCollapsedSelector}
        patchUi={(data) => setUiState({pages: {projects: data}})}
      />
    ),
  },
  {path: '*', element: <LastVisitRedirect default={controlInProgressRoute} />},
];
