import {cn} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

interface RemainingCharsProps {
  currentChars: number;
  maxChars: number;
}

export default function RemainingChars({currentChars, maxChars}: RemainingCharsProps): ReactElement | null {
  const remainingChars = maxChars - currentChars;
  const {t} = useTranslation();
  if (remainingChars > 2000) {
    return null;
  }

  return (
    <div
      className={cn(
        'mt-1 flex justify-end text-sm text-slate-600',
        remainingChars <= 20 && 'text-yellow-600',
        remainingChars <= 0 && 'text-red-600',
      )}
    >
      {t('general:remainingCharacters', {remainingChars, maxChars})}
    </div>
  );
}
