import {Uuid} from '@octaved/typescript/src/lib';
import {useDispatch} from 'react-redux';
import {markWorkPackagesDoneWorkflowControlSelector} from '../../Modules/Selectors/SettingSelectors';
import {StrictWorkflowControl} from '../../Modules/Settings';
import {patchSubWorkPackage} from '../../Modules/SubWorkPackages';
import {patchWorkPackage} from '../../Modules/WorkPackages';
import {useWorkflowControlDialog, WorkflowControlTranslationsMap} from './WorkflowControlDialog';

const translationMap: WorkflowControlTranslationsMap = {
  [StrictWorkflowControl.medium]: {
    close: 'general:cancel',
    content: 'workPackage:markAsDoneControl.medium.content',
    headline: 'workPackage:markAsDoneControl.medium.headline',
    pass: 'workPackage:markAsDoneControl.medium.pass',
  },
  [StrictWorkflowControl.strict]: {
    close: 'general:close',
    content: 'workPackage:markAsDoneControl.strict.content',
    headline: 'workPackage:markAsDoneControl.strict.headline',
  },
};

export function useMarkNodeDoneWorkflowControlModal(
  nodeId: Uuid,
  patchFN: typeof patchWorkPackage | typeof patchSubWorkPackage,
): ReturnType<typeof useWorkflowControlDialog> {
  const dispatch = useDispatch();
  return useWorkflowControlDialog(
    nodeId,
    markWorkPackagesDoneWorkflowControlSelector,
    (checked) => dispatch(patchFN(nodeId, {isCompleted: checked})),
    translationMap,
  );
}
