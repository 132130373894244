import {ReactElement, ReactNode, useContext} from 'react';
import {flowStyleContext} from '../../Styles/StyleContext';

interface Props {
  children: ReactNode;
}

export default function GroupView({children}: Props): ReactElement {
  const {
    COLORS: {PAGES},
  } = useContext(flowStyleContext);

  return (
    <div className={'group'}>
      {children}

      {/*language=SCSS*/}
      <style jsx>{`
        .group {
          border: 2px solid transparent;
          border-left: 7px solid ${PAGES.PROJECTS.GROUP_BORDER};
          background-color: ${PAGES.PROJECTS.GROUP_BACKGROUND};
          padding: 11px 15px;
          width: calc(100% + 15px);

          & .group {
            border-top: 1px solid ${PAGES.PROJECTS.WORKPACKAGE.BORDER};
            border-right: 1px solid ${PAGES.PROJECTS.WORKPACKAGE.BORDER};
            border-bottom: 1px solid ${PAGES.PROJECTS.WORKPACKAGE.BORDER};
            box-shadow: none;

            & .group {
              border-right: none;
            }
          }

          &.compact {
            padding: 5px 8px;
            border-left-width: 5px;
            width: calc(100% + 8px);
          }
        }
      `}</style>
    </div>
  );
}
