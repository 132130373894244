import CaretDown from '@fortawesome/fontawesome-pro/svgs/solid/caret-down.svg';
import CaretRight from '@fortawesome/fontawesome-pro/svgs/solid/caret-right.svg';
import {IconButton, StopClickPropagation} from '@octaved/ui';
import {MouseEvent, ReactElement} from 'react';

interface Props {
  hasSubTasks: boolean;
  toggleShowSubTasks?: (event?: MouseEvent) => void;
  showSubTasks?: boolean;
}

export default function ShowSubTaskToggle({hasSubTasks, toggleShowSubTasks, showSubTasks}: Props): ReactElement {
  if (!hasSubTasks) {
    return (
      <div className={'toggleSubTaskSpacer'}>
        {/*#region styles*/}
        {/*language=scss*/}
        <style jsx>{`
          .toggleSubTaskSpacer {
            width: 22px;
          }
        `}</style>
        {/*#endregion*/}
      </div>
    );
  }

  return (
    <StopClickPropagation>
      <IconButton
        variant={'ghost'}
        size={'xs'}
        icon={showSubTasks ? <CaretDown /> : <CaretRight />}
        onClick={toggleShowSubTasks}
      />
    </StopClickPropagation>
  );
}
