import {FlowState} from '@octaved/flow/src/Modules/State';
import {CALL_API, ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {INVALIDATED, LOADED, LOADING, createTimestampReducer, filterIdsToReload} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import ReduceFromMap from '@octaved/store/src/Reducer/ReduceFromMap';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {generateUuid} from '@octaved/utilities';
import {chunk} from 'lodash';
import {getMilestones as getMilestonesRoute} from '../../config/routes';
import {Milestone, Milestones} from '../EntityInterfaces/Milestones';
import {PlanningState} from '../PlanningState';
import {getMilestonesForNodeSelector, milestoneEntityStatesSelector} from '../Selectors/MilestoneSelectors';
import {
  FLOW_GET_MILESTONES_FAILURE,
  FLOW_GET_MILESTONES_REQUEST,
  FLOW_GET_MILESTONES_START,
  FLOW_GET_MILESTONES_SUCCESS,
  FLOW_GET_PLANNING_FOR_NODES_REQUEST,
  FLOW_GET_PLANNING_FOR_NODES_SUCCESS,
  FLOW_PATCH_PLANNING_REQUEST,
} from './ActionTypes';
import {PatchPlanningRequest, patchPlanning} from './Planning';

//#region State Reducer
const stateReducerMap = new Map();
stateReducerMap.set(FLOW_GET_PLANNING_FOR_NODES_REQUEST, createTimestampReducer('options.data.ids', LOADING));
stateReducerMap.set(FLOW_GET_PLANNING_FOR_NODES_SUCCESS, createTimestampReducer('options.data.ids', LOADED));
stateReducerMap.set(FLOW_GET_MILESTONES_START, createTimestampReducer('nodeIds', LOADING));
stateReducerMap.set(FLOW_GET_MILESTONES_SUCCESS, createTimestampReducer('options.urlParams.ids', LOADED));
stateReducerMap.set('flow.PlanningEvent', createTimestampReducer('updatedMilestoneNodeIds', INVALIDATED, true));
stateReducerMap.set('flow.NodeRestoredFromTrashEvent', createTimestampReducer('allAffectedNodeIds', INVALIDATED));
export const milestoneEntityStateReducer = ReduceFromMap(stateReducerMap);

//#endregion
//#region Reducer

const reducers = createReducerCollection<Milestones>({});

reducers.add(
  FLOW_GET_MILESTONES_SUCCESS,
  (
    state: Milestones,
    {options, response}: ServerResponseAction<Milestones, {urlParams: {ids: Uuid[]}}>,
  ): Milestones => {
    if (options?.urlParams && response) {
      const newState = {...state};
      options.urlParams.ids.forEach((id) => {
        newState[id] = response[id] || [];
      });
      return newState;
    }
    return state;
  },
);
reducers.add(
  FLOW_GET_PLANNING_FOR_NODES_SUCCESS,
  (state: Milestones, {response}: ServerResponseAction<{result: {milestones: Milestones}}>): Milestones => {
    if (response?.result.milestones) {
      return {...state, ...response.result.milestones};
    }
    return state;
  },
);
reducers.add(FLOW_PATCH_PLANNING_REQUEST, (state: Milestones, {milestones}: PatchPlanningRequest): Milestones => {
  if (milestones) {
    return {...state, ...milestones};
  }
  return state;
});

export const milestoneReducer = reducers.reducer;

//#endregion

export function getMilestones(ids: ReadonlyArray<Uuid>): ActionDispatcher<Promise<void>, PlanningState> {
  return async (dispatch: Dispatch, getState) => {
    const toLoad = filterIdsToReload(milestoneEntityStatesSelector(getState()), ids);

    if (toLoad.length === 0) {
      return;
    }

    //Must set the state to "loading" before the chunking starts, otherwise multiple loads may happen for one id:
    await dispatch({nodeIds: toLoad, type: FLOW_GET_MILESTONES_START});

    await Promise.all(
      chunk(toLoad, 100).map((ids) =>
        dispatch({
          [CALL_API]: {
            endpoint: getMilestonesRoute,
            method: 'get',
            options: {
              urlParams: {
                ids,
              },
            },
            types: {
              failureType: FLOW_GET_MILESTONES_FAILURE,
              requestType: FLOW_GET_MILESTONES_REQUEST,
              successType: FLOW_GET_MILESTONES_SUCCESS,
            },
          },
        }),
      ),
    );
  };
}

export function addMilestone(
  nodeId: Uuid,
  milestoneDate: DateStr,
  name: string = '',
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const milestones: Milestone[] = [
      ...getMilestonesForNodeSelector(state)(nodeId),
      {
        milestoneDate,
        name,
        nodeId,
        id: generateUuid(),
      },
    ];

    return dispatch(
      patchPlanning([
        {
          milestones,
          nodeId,
        },
      ]),
    );
  };
}

export function updateMilestone(
  nodeId: Uuid,
  milestoneId: Uuid,
  data: Omit<Partial<Milestone>, 'id' | 'nodeId'>,
): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const milestones: Milestone[] = [...getMilestonesForNodeSelector(state)(nodeId)];

    const index = milestones.findIndex(({id}) => id === milestoneId);
    if (index >= 0) {
      const oldPlanningDate = milestones[index];
      milestones[index] = {
        ...oldPlanningDate,
        ...data,
      };
      return dispatch(
        patchPlanning([
          {
            milestones,
            nodeId,
          },
        ]),
      );
    }
  };
}

export function removeMilestone(nodeId: Uuid, milestoneId: Uuid): ActionDispatcher<Promise<void>, FlowState> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const milestones: Milestone[] = getMilestonesForNodeSelector(state)(nodeId);

    return dispatch(
      patchPlanning([
        {
          nodeId,
          milestones: milestones.filter(({id}) => id !== milestoneId),
        },
      ]),
    );
  };
}
