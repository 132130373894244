import {TimeTrackingReferenceNodeType} from '@octaved/env/src/dbalEnumTypes';
import {WorkPackage} from '../EntityInterfaces/Pid';
import {SubWorkPackage} from '../EntityInterfaces/SubWorkPackage';
import {NodeAncestry} from '../Modules/Selectors/NodeTreeSelectors';

export function getReferenceNodeFromAncestry({
  subWorkPackage: swp,
  workPackage: wp,
}: NodeAncestry): SubWorkPackage | WorkPackage | null {
  if (
    swp &&
    wp &&
    (wp.timeTrackingReferenceNodeType === TimeTrackingReferenceNodeType.any ||
      wp.timeTrackingReferenceNodeType === TimeTrackingReferenceNodeType.subWorkPackages)
  ) {
    return swp;
  }
  return wp;
}
