import BaseToggle from '@octaved/ui-components/src/React/Form/Toggle';
import {FormEvent, ReactElement, useContext, useMemo} from 'react';
import {CheckboxProps} from 'semantic-ui-react';
import {flowStyleContext} from '../../../Styles/StyleContext';

interface Props {
  checked: boolean;
  description?: string;
  disabled?: boolean;
  label?: string | ReactElement;
  noDescriptionIdent?: boolean;
  normalDescriptionSize?: boolean;
  onChange?: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  prefix?: ReactElement;
  setChecked?: (v: boolean) => void;
  showDescriptionWhenUnchecked?: boolean;
  small?: boolean;
  suffix?: ReactElement;
}

export default function Toggle({
  checked,
  description,
  disabled,
  label = '',
  noDescriptionIdent,
  normalDescriptionSize,
  onChange,
  prefix,
  setChecked,
  showDescriptionWhenUnchecked,
  small,
  suffix,
}: Props): ReactElement {
  const {
    COLORS: {FONT, TOGGLE},
  } = useContext(flowStyleContext);
  const styleVariables = useMemo(
    () => ({
      containerBackgroundColorActive: TOGGLE.BG_ACTIVE,
      containerBackgroundColorNotActive: TOGGLE.BG,
      containerBorderColor: TOGGLE.SLIDE_BORDER,
      containerShadow: TOGGLE.SLIDE_SHADOW,
      fontColor: FONT.STANDARD,
      slideBackgroundColor: TOGGLE.SLIDE_BACKGROUND,
      width: 'fit-content',
    }),
    [FONT, TOGGLE],
  );
  return (
    <BaseToggle
      checked={checked}
      setChecked={setChecked}
      onChange={onChange}
      description={description}
      label={label}
      showDescriptionWhenUnchecked={showDescriptionWhenUnchecked}
      normalDescriptionSize={normalDescriptionSize}
      noDescriptionIdent={noDescriptionIdent}
      disabled={disabled}
      suffix={suffix}
      styleVariables={styleVariables}
      small={small}
      prefix={prefix}
    />
  );
}
