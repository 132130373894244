import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {Popup} from '@octaved/ui';
import {Import} from 'lucide-react';
import {ReactElement, RefObject, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Components/Form/Dropdown/DropdownPopup/Types';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import Close from '../../Dialogs/Components/Close';
import {useNode} from '../../Modules/Hooks/Nodes';
import {FlowState} from '../../Modules/State';
import {NodeStatusNode} from '../NodeIdentifiers';
import {nodeTypeTokens} from '../NodeType';
import {NodeStatus, nodeStatusLabels} from './NodeStatus';
import {getNodeStatusWithBlameSelector} from './NodeStatusSelectors';

export function useNodeStatusWithInheritance(nodeId: Uuid): {sourceNodeId: Uuid; status: NodeStatus} {
  return useSelector((s: FlowState) => getNodeStatusWithBlameSelector(s)(nodeId));
}

export function useNodeStatusInheritancePopup(node: NodeStatusNode): {
  close: () => void;
  contextRef: RefObject<HTMLDivElement>;
  menuItem: DropdownPopupItemOptions;
  show: boolean;
} {
  const [show, setShow] = useState(false);
  const {sourceNodeId} = useNodeStatusWithInheritance(node.id);
  const isInheriting = sourceNodeId !== node.id;
  const contextRef = useRef<HTMLDivElement>(null);
  const menuItem: DropdownPopupItemOptions = {
    hidden: node.nodeType === EnumFlowNodeType.VALUE_PROJECT,
    icon: <Import />,
    iconColor: isInheriting ? 'blue' : 'darkGrey',
    onClick: () => setShow(true),
    token: 'general:showInheritance',
    type: DropdownPopupItemType.item,
  };
  return {close: () => setShow(false), contextRef, menuItem, show};
}

interface Props {
  close: () => void;
  contextRef: RefObject<HTMLDivElement>;
  nodeId: Uuid;
}

export default function NodeStatusInheritancePopup({close, nodeId, contextRef}: Props): ReactElement {
  const {t} = useTranslation();
  const {sourceNodeId, status} = useNodeStatusWithInheritance(nodeId);
  const isInheriting = sourceNodeId !== nodeId;
  const sourceNode = useNode(sourceNodeId);
  return (
    <Popup open setOpen={close} basic context={contextRef.current} flowing position={'bottom right'}>
      <div className={'relative w-96 px-6 py-4'}>
        <div className={'absolute right-1 top-1'}>
          <Close close={close} />
        </div>
        <DialogAndDrawerHeader header={'general:showInheritance'} className={'my-0'} />
        <div className={'flex flex-col gap-y-4'}>
          {isInheriting && sourceNode ? (
            <>
              <div>
                <Trans
                  i18nKey={'general:nodeStatus.inheritancePopup.inheriting-1'}
                  values={{status: t(nodeStatusLabels[status])}}
                />
              </div>
              <div className={'font-semibold'}>
                {t(nodeTypeTokens[sourceNode.nodeType])}
                {sourceNode.nodeType !== EnumFlowNodeType.VALUE_PROJECT && (
                  <span className={'italic'}> {sourceNode.name}</span>
                )}
              </div>
              <div>{t('general:nodeStatus.inheritancePopup.inheriting-2')}</div>
            </>
          ) : (
            <div>{t('general:nodeStatus.inheritancePopup.notInheriting')}</div>
          )}
        </div>
      </div>
    </Popup>
  );
}
