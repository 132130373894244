import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {lazy} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import StandardInspectorSubMenu from '../../../Drawer/StandardInspector/StandardInspectorSubMenu';
import {InspectorTabItem, TabRecords} from '../../../Drawer/StandardInspector/StandardInspectorTabs';
import {projectControllingDemoModeSelector} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {setProjectControllingDemoMode} from '../../../Modules/UiPages/Projects';
import ProjectControllingInspectorSubMenu from '../../../Pages/Projects/Pages/ControlCommon/ProjectControllingInspectorSubMenu';
import {dialogRouteObjects} from '../Dialogs';
import {subInspectorRoute} from './InspectorRoutes';
import {subInspectorWrapper} from './Inspectors';
import ProjectControllingDemoModeWrapper from './ProjectControllingDemoModeWrapper';
import {getStandardInspectorRoutes, getWrappedStandardInspectorRoutes} from './StandardInspector';

const statusRoute = 'status';
const moreRoute = 'more';
const MoreTab = lazy(() => import('../../../Pages/Projects/Pages/ControlInProgress/Inspector/MoreTab'));
const Status = lazy(() => import('../../../Pages/Projects/Pages/ControlInProgress/Inspector/StatusTab'));
const StatusTabDemoMode = lazy(() => import('@octaved/demo-mode').then((module) => ({default: module.StatusTab})));
const MoreTabDemoMode = lazy(() => import('@octaved/demo-mode').then((module) => ({default: module.MoreTab})));

//#region <inspector tabs>
const parentTabs: InspectorTabItem[] = [
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
  },
  {
    path: moreRoute,
    token: 'pages:projects.inspector.menu.more',
  },
];

const wpTabs: InspectorTabItem[] = [
  {
    path: statusRoute,
    token: 'pages:projects.inspector.menu.status',
  },
];

const inspectorTabConfig: TabRecords = {
  [EnumFlowNodeType.VALUE_PROJECT]: parentTabs,
  [EnumFlowNodeType.VALUE_GROUP]: parentTabs,
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: wpTabs,
};
//#endregion

const statusRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<StatusTabDemoMode />}>
      <Status />
    </ProjectControllingDemoModeWrapper>
  ),
  path: statusRoute,
});
const moreRouteObject = (): RouteObject => ({
  children: dialogRouteObjects,
  element: (
    <ProjectControllingDemoModeWrapper demoModePage={<MoreTabDemoMode />}>
      <MoreTab />
    </ProjectControllingDemoModeWrapper>
  ),
  path: moreRoute,
});

export const controlInProgressInspectorRoutes = getWrappedStandardInspectorRoutes({
  children: subInspectorWrapper(
    [
      moreRouteObject(),
      statusRouteObject(),
      {
        element: <Navigate to={statusRoute} replace />,
        path: '*',
      },
    ],
    getStandardInspectorRoutes({actionMenu: StandardInspectorSubMenu, useOwnProjectsPid: false}),
    subInspectorRoute,
  ),
  standardInspectorProps: {
    actionMenu: ProjectControllingInspectorSubMenu,
    demoModeSelector: projectControllingDemoModeSelector,
    demoModeToggle: setProjectControllingDemoMode,
    tabConfig: inspectorTabConfig,
  },
});
