import {isWorkPackage} from '@octaved/flow/src/Node/NodeIdentifiers';
import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {addDependency} from '../../../Modules/PlanningDependencies';
import AddDependencyModal from '../../AddDependencyModal/AddDependencyModal';

interface AddTimeBasedDependencyProps {
  node: PlanningRealizationNode;
}

export default function AddTimeBasedDependency({node}: AddTimeBasedDependencyProps): ReactElement {
  const dispatch = useDispatch();
  const onSelect = useCallback(
    (predecessor: Uuid | null) => {
      if (predecessor) {
        dispatch(addDependency(node.id, predecessor));
        return true;
      }
      return false;
    },
    [node.id, dispatch],
  );

  const excludedNodeIds = useMemo<Set<Uuid>>(
    () => new Set([node.id, ...node.planningPredecessors]),
    [node.id, node.planningPredecessors],
  );
  return (
    <AddDependencyModal
      dialogInfo={
        isWorkPackage(node)
          ? 'pages:projects.inspector.manage.planning.newDependencyTimeBasedInfoForWorkpackages'
          : 'pages:projects.inspector.manage.planning.newDependencyTimeBasedInfoForTasks'
      }
      helpInfo={
        isWorkPackage(node)
          ? 'pages:projects.inspector.manage.planning.newDependencyHelpForWorkpackages'
          : 'pages:projects.inspector.manage.planning.newDependencyHelpForTasks'
      }
      excludedNodeIds={excludedNodeIds}
      buttonToken={'pages:projects.inspector.manage.planning.newDependency'}
      dialogHeaderToken={'pages:projects.inspector.manage.planning.newDependency'}
      node={node}
      onSelect={onSelect}
      plannedOnly
      dependencyType={'planning'}
    />
  );
}
