import type {NodeType} from '@octaved/flow/src/EntityInterfaces/Nodes';
import type {GetWorkingTimeAtDate} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {isNodeWithDueDate} from '@octaved/flow/src/Node/NodeIdentifiers';
import {earliestIsoDate, latestIsoDate} from '@octaved/flow/src/Today';
import {type DateStr, type DateTimeStr, toDateStr} from '@octaved/typescript';
import {toDateTimeStr} from '@octaved/typescript/src';
import type {MaybeUuid, Uuid} from '@octaved/typescript/src/lib';
import {fromIsoDateTimeFormat, fromIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import type {Dayjs} from 'dayjs';
import {NodeWithPlannableThings} from '../Components/PlanningRealization/LoadPlanningDependencies';
import {Milestone} from '../EntityInterfaces/Milestones';
import type {PlanningDate} from '../EntityInterfaces/PlanningDates';
import {PlanningDependency} from '../EntityInterfaces/PlanningDependency';
import type {PatchPlanningDependency, PlanningPatchData} from '../Modules/Planning';
import {calculateOffset} from './Offset';
import {absoluteWorkdaysInRange, addWorkdays, ensureWorkDay} from './WorkdayCalculations';

interface Summary {
  dateShifts: number;
  dueDateShifts: number;
  milestoneShifts: number;
  offsetShifts: number;
}

interface MaxEdgeWorkdays {
  source: Dayjs;
  target: Dayjs;
}

interface StoreSources {
  getMilestonesForNode: (nodeId: Uuid) => readonly Milestone[];
  getNode: (id: MaybeUuid) => NodeType | undefined;
  getPlanningDatesForNode: (nodeId: Uuid) => readonly PlanningDate[];
  getPlanningDependency: (successorId: Uuid, predecessorId: Uuid) => PlanningDependency | undefined;
  getWorkMinutesAtDate: GetWorkingTimeAtDate;
}

function getUpdatableMinMax(
  store: StoreSources,
  nodes: readonly NodeWithPlannableThings[],
): {hasPlanning: boolean; minDate: DateStr; maxDate: DateStr; updatableNodeIds: Set<Uuid>} {
  let hasPlanning = false;
  let minDate = latestIsoDate;
  let maxDate = earliestIsoDate;
  const updatableNodeIds = new Set<Uuid>(); //in subtree and not archived
  nodes.forEach((node) => {
    if (!node.isArchived) {
      updatableNodeIds.add(node.id);
      store.getPlanningDatesForNode(node.id).forEach((date) => {
        const start = toDateStr(date.plannedStart);
        const end = toDateStr(date.plannedEnd);
        if (start < minDate) {
          minDate = start;
        }
        if (end > maxDate) {
          maxDate = end;
        }
        hasPlanning = true;
      });

      store.getMilestonesForNode(node.id).forEach(({milestoneDate}) => {
        if (milestoneDate < minDate) {
          minDate = milestoneDate;
        }
        if (milestoneDate > maxDate) {
          maxDate = milestoneDate;
        }
        hasPlanning = true;
      });

      if (isNodeWithDueDate(node) && node.dueDate) {
        if (node.dueDate < minDate) {
          minDate = node.dueDate;
        }
        if (node.dueDate > maxDate) {
          maxDate = node.dueDate;
        }
        hasPlanning = true;
      }
    }
  });
  return {hasPlanning, minDate, maxDate, updatableNodeIds};
}

interface CalcNewStartEnd {
  (start: DateTimeStr, end: DateTimeStr): [Dayjs, Dayjs];
}

function getCalcNewStartEnd(
  maxEdgeWorkdays: MaxEdgeWorkdays,
  {getWorkMinutesAtDate}: StoreSources,
  alignToStart: boolean,
): CalcNewStartEnd {
  return (start, end) => {
    const sourceStart = fromIsoDateTimeFormat(start);
    const sourceEnd = fromIsoDateTimeFormat(end);

    const sourceDateEdge = alignToStart ? sourceStart : sourceEnd;
    const sourceDateEdgeWorkday = ensureWorkDay(sourceDateEdge, getWorkMinutesAtDate, alignToStart);
    const dateDuration = absoluteWorkdaysInRange(sourceStart, sourceEnd, getWorkMinutesAtDate);

    // Because sourceMaxEdgeWorkday and sourceDateEdgeWorkday are ensured workdays, this is never 0:
    const offset = absoluteWorkdaysInRange(maxEdgeWorkdays.source, sourceDateEdgeWorkday, getWorkMinutesAtDate);

    if (alignToStart) {
      const newStart = addWorkdays(maxEdgeWorkdays.target, offset - 1, getWorkMinutesAtDate);
      const newEnd = addWorkdays(newStart, dateDuration - 1, getWorkMinutesAtDate);
      return [newStart, newEnd];
    }

    const newEnd = addWorkdays(maxEdgeWorkdays.target, -(offset - 1), getWorkMinutesAtDate);
    const newStart = addWorkdays(newEnd, -(dateDuration - 1), getWorkMinutesAtDate);
    return [newStart, newEnd];
  };
}

function calculatePlanningChangeSet(
  node: NodeWithPlannableThings,
  calcNewStartEnd: CalcNewStartEnd,
  updates: PlanningPatchData[],
  summary: Summary,
  updatableNodeIds: Set<Uuid>,
  store: StoreSources,
  alignToStart: boolean,
): void {
  const planningDates = store.getPlanningDatesForNode(node.id); //sorted!
  if (!planningDates.length) {
    return;
  }

  if (node.planningPredecessors.length) {
    const [newStart, newEnd] = calcNewStartEnd(
      planningDates[0]!.plannedStart,
      planningDates[planningDates.length - 1]!.plannedEnd,
    );

    let nodeHasOffsetShift = false;
    const newDependencies: PatchPlanningDependency[] = [];
    node.planningPredecessors.forEach((predecessorId) => {
      const dependency = store.getPlanningDependency(node.id, predecessorId);
      const prededessorDates = store.getPlanningDatesForNode(predecessorId);
      const predecessor = store.getNode(predecessorId);
      if (dependency && predecessor && prededessorDates.length) {
        const isExternal = !updatableNodeIds.has(predecessorId);
        if (isExternal || predecessor.isArchived) {
          const newOffset = calculateOffset(
            dependency.type,
            store.getWorkMinutesAtDate,
            prededessorDates[0]!.plannedStart,
            prededessorDates[prededessorDates.length - 1]!.plannedEnd,
            newStart,
            newEnd,
            alignToStart,
          );

          if (newOffset !== dependency.offset) {
            newDependencies.push({...dependency, offset: newOffset});
            summary.offsetShifts++;
            nodeHasOffsetShift = true;
          } else {
            newDependencies.push(dependency);
          }
        } else {
          //Do nothing, this node will be moved automatically, but we have to keep the dependency in the array:
          newDependencies.push(dependency);
        }
      }
    });

    if (nodeHasOffsetShift) {
      updates.push({nodeId: node.id, dependencies: newDependencies});
    }
  } else {
    const dateUpdates: PlanningDate[] = [];
    planningDates.forEach((date) => {
      const [newStart, newEnd] = calcNewStartEnd(date.plannedStart, date.plannedEnd);
      dateUpdates.push({...date, plannedStart: toDateTimeStr(newStart), plannedEnd: toDateTimeStr(newEnd)});
      summary.dateShifts++;
    });
    updates.push({nodeId: node.id, planningDates: dateUpdates});
  }
}

function calculateMilestoneChangeSet(
  maxEdgeWorkdays: MaxEdgeWorkdays,
  node: NodeWithPlannableThings,
  updates: PlanningPatchData[],
  summary: Summary,
  {getMilestonesForNode, getWorkMinutesAtDate}: StoreSources,
  alignToStart: boolean,
): void {
  const milestones = getMilestonesForNode(node.id);
  if (!milestones.length) {
    return;
  }

  const newMilestones = milestones.map((milestone) => {
    const milestoneWorkday = ensureWorkDay(fromIsoFormat(milestone.milestoneDate), getWorkMinutesAtDate, alignToStart);
    const offset = absoluteWorkdaysInRange(maxEdgeWorkdays.source, milestoneWorkday, getWorkMinutesAtDate) - 1;
    const newMilestone = addWorkdays(maxEdgeWorkdays.target, offset * (alignToStart ? 1 : -1), getWorkMinutesAtDate);
    summary.milestoneShifts++;
    return {...milestone, milestoneDate: toDateStr(newMilestone)};
  });

  updates.push({nodeId: node.id, milestones: newMilestones});
}

function calculateDueDateChangeSet(
  maxEdgeWorkdays: MaxEdgeWorkdays,
  node: NodeWithPlannableThings,
  updates: PlanningPatchData[],
  summary: Summary,
  {getWorkMinutesAtDate}: StoreSources,
  alignToStart: boolean,
): void {
  if (!isNodeWithDueDate(node) || !node.dueDate) {
    return;
  }

  const dueDateWorkday = ensureWorkDay(fromIsoFormat(node.dueDate), getWorkMinutesAtDate, alignToStart);
  const offset = absoluteWorkdaysInRange(maxEdgeWorkdays.source, dueDateWorkday, getWorkMinutesAtDate) - 1;
  const newDueDate = addWorkdays(maxEdgeWorkdays.target, offset * (alignToStart ? 1 : -1), getWorkMinutesAtDate);
  summary.dueDateShifts++;

  updates.push({nodeId: node.id, dueDate: toDateStr(newDueDate)});
}
function calculateChangeSet(
  updates: PlanningPatchData[],
  summary: Summary,
  sourceMaxEdge: DateStr,
  updatableNodeIds: Set<Uuid>,
  store: StoreSources,
  nodes: readonly NodeWithPlannableThings[],
  alignToStart: boolean,
  targetDate: DateStr,
): void {
  const maxEdgeWorkdays = {
    source: ensureWorkDay(fromIsoFormat(sourceMaxEdge), store.getWorkMinutesAtDate, alignToStart),
    target: ensureWorkDay(fromIsoFormat(targetDate), store.getWorkMinutesAtDate, alignToStart),
  };
  const calcNewStartEnd = getCalcNewStartEnd(maxEdgeWorkdays, store, alignToStart);
  nodes.forEach((node) => {
    if (!node.isArchived) {
      calculatePlanningChangeSet(node, calcNewStartEnd, updates, summary, updatableNodeIds, store, alignToStart);
      calculateMilestoneChangeSet(maxEdgeWorkdays, node, updates, summary, store, alignToStart);
      calculateDueDateChangeSet(maxEdgeWorkdays, node, updates, summary, store, alignToStart);
    }
  });
}

export function createMoveMultiplePlanningChangeSet(
  store: StoreSources,
  nodes: readonly NodeWithPlannableThings[],
  alignToStart: boolean,
  targetDate: DateStr | null | undefined,
): {
  shiftIsNeeded: boolean;
  sourceMinMax: {start: DateStr; end: DateStr} | null;
  summary: Summary;
  updates: PlanningPatchData[];
} {
  const {hasPlanning, minDate, maxDate, updatableNodeIds} = getUpdatableMinMax(store, nodes);

  const sourceMaxEdge = alignToStart ? minDate : maxDate;
  const shiftIsNeeded = hasPlanning && !!targetDate && sourceMaxEdge !== targetDate;

  const summary: Summary = {
    dateShifts: 0,
    dueDateShifts: 0,
    milestoneShifts: 0,
    offsetShifts: 0,
  };
  const updates: PlanningPatchData[] = [];

  if (shiftIsNeeded) {
    calculateChangeSet(updates, summary, sourceMaxEdge, updatableNodeIds, store, nodes, alignToStart, targetDate);
  }

  return {
    shiftIsNeeded,
    summary,
    updates,
    sourceMinMax: hasPlanning ? {start: minDate, end: maxDate} : null,
  };
}
