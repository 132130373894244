import {EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import Close from '@octaved/flow/src/Dialogs/Components/Close';
import {PlanningNode} from '@octaved/flow/src/Node/NodeIdentifiers';
import {dependencyNames} from '@octaved/planning/src/Components/DependencyNames';
import {PlanningStatus} from '@octaved/planning/src/PlanningStatus/PlanningStatus';
import {PlanningStatusIconWithText} from '@octaved/planning/src/PlanningStatus/PlanningStatusIcon';
import {getPlanningDependencySelector} from '@octaved/planning/src/Selectors/PlanningDependencySelectors';
import {cn, Popup} from '@octaved/ui';
import {MutableRefObject, ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Info from './Details/Info';
import PlanningDateRow from './Details/PlanningDateRow';
import PredecessorsRow from './Details/PredecessorsRow';

export interface NodePlanningStatusDetailsProps {
  mountContext: MutableRefObject<HTMLDivElement | null>;
  node: PlanningNode;
  onClose: () => void;
  status: PlanningStatus;
}

export default function NodePlanningStatusDetails({
  mountContext,
  node,
  onClose,
  status,
}: NodePlanningStatusDetailsProps): ReactElement | null {
  const getPlanningDependency = useSelector(getPlanningDependencySelector);
  const depTypes = node.planningPredecessors.reduce<Set<EnumFlowPlanningDependencyType>>((acc, id) => {
    const dep = getPlanningDependency(node.id, id);
    return dep ? acc.add(dep.type) : acc;
  }, new Set());

  const {t} = useTranslation();

  return (
    <Popup context={mountContext.current} open setOpen={onClose} basic>
      <div className={'relative max-w-xl px-6 py-4'}>
        <div className={'absolute right-1 top-1'}>
          <Close close={onClose} />
        </div>
        <DialogAndDrawerHeader
          header={'nodeDetails:field.planningStatus.details.label'}
          className={cn('my-0', status.overall === 'done' && 'pb-3')}
        />

        {status.overall !== 'done' && (
          <div className={'grid grid-cols-[max-content_1fr] gap-x-14 gap-y-3'}>
            {/** Planning type */}
            <div>{t('nodeDetails:field.planningStatus.details.type')}</div>
            <div className={'flex flex-col'}>
              {status.time !== 'none' && <div>{t('nodeDetails:field.planningStatus.details.timebasedPlanning')}</div>}
              {node.planningPredecessors.length > 0 && (
                <div>
                  {t('nodeDetails:field.planningStatus.details.timebasedDependency')} (
                  {t(
                    depTypes.size > 1
                      ? 'nodeDetails:field.planningStatus.details.timebasedDependency.mixed'
                      : dependencyNames.get(depTypes.values().next().value!)?.long || '',
                  )}
                  )
                </div>
              )}
              {node.planningLogicalPredecessors.length > 0 && (
                <div>{t('nodeDetails:field.planningStatus.details.logicalDependency')}</div>
              )}
            </div>

            <PlanningDateRow node={node} status={status} />

            <PredecessorsRow node={node} status={status} />

            <div className={'col-span-2 h-px w-full bg-slate-200'} />
            <div className={'max-w-32'}>{t('nodeDetails:field.planningStatus.resulting.label')}</div>
            <PlanningStatusIconWithText status={status.overall} />
          </div>
        )}
        <Info node={node} status={status} />
      </div>
    </Popup>
  );
}
