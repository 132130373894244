import {EnumFlowNodeType, EnumFlowPlanningDependencyType} from '@octaved/env/src/dbalEnumTypes';
import {getNodeSelector} from '@octaved/flow/src/Modules/Selectors/NodeSelectors';
import {getTaskIsAutoChainedSelector} from '@octaved/flow/src/Modules/Selectors/TaskSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {isoDateTimeToIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import Konva from 'konva';
import {isEqual} from 'lodash';
import {getDaysFromStart, getWidth} from '../../../../Calculations/DateCalculations';
import {PlanningDependency} from '../../../../EntityInterfaces/PlanningDependency';
import {getAutoChainLogicalPredecessorSelector} from '../../../../Selectors/LogicalDependencySelector';
import {MinMaxPlanningDatesResult, getMinMaxPlanningDatesSelector} from '../../../../Selectors/PlanningDateSelectors';
import {
  getIsPlannedRelativeToParentSelector,
  getPlanningPredecessorsSelector,
} from '../../../../Selectors/PlanningDependencySelectors';
import {DisposableArray} from '../../../Disposable';
import {RootContainer, RootContainerProps} from '../../../RootContainer';
import {createStoreSubscription} from '../../../StoreSubscription';
import {GanttContext} from '../../Context/GanttContext';
import {GanttDataLoader} from '../../Data/GanttDataLoader';
import {TreeNode} from '../../Data/TreeNode';
import {DependencyLine} from './DependencyLine';

interface DependencyLinesProps extends RootContainerProps<GanttContext> {
  node: TreeNode;
  rowIndex: number;
  planningDataLoader: GanttDataLoader;
  barHeight: number;
  yBarOffset: number;
}

export class DependencyLines extends RootContainer<GanttContext> {
  readonly #node: TreeNode;
  readonly #rowIndex: number;
  readonly #planningDataLoader: GanttDataLoader;
  readonly #barHeight: number;
  readonly #yBarOffset: number;

  #planningDependencies: PlanningDependency[] = [];
  #planningLogicalPredecessors: Uuid[] = [];
  #minMaxPlanning: MinMaxPlanningDatesResult | null = null;
  #isAutoChain = false;
  #hidePlanningDependencies = false;

  #planningDependencyRoot = new Konva.Group();
  #planningLogicalDependencyRoot = new Konva.Group();
  #autoChainDependencyRoot = new Konva.Group();

  #planningDependencyDisposables: DisposableArray = [];
  #planningLogicalDependencyDisposables: DisposableArray = [];
  #autoChainDependencyDisposables: DisposableArray = [];

  constructor(props: DependencyLinesProps) {
    super(props);

    this.#node = props.node;
    this.#rowIndex = props.rowIndex;
    this.#planningDataLoader = props.planningDataLoader;
    this.#barHeight = props.barHeight;
    this.#yBarOffset = props.yBarOffset;
  }

  init(): void {
    this.root.add(this.#planningDependencyRoot);
    this.root.add(this.#planningLogicalDependencyRoot);
    this.root.add(this.#autoChainDependencyRoot);

    if (this.#node.type === EnumFlowNodeType.VALUE_TASK) {
      this.disposables.push(
        createStoreSubscription(
          this.ctx.store,
          (s) => getTaskIsAutoChainedSelector(s)(this.#node.id),
          (isAutoChain) => {
            this.#isAutoChain = isAutoChain;
            this.#renderAutoChainDependencies();
          },
        ),
        createStoreSubscription(
          this.ctx.store,
          (s) => getAutoChainLogicalPredecessorSelector(s)(this.#node.id),
          () => {
            this.#renderAutoChainDependencies();
          },
        ),
      );
    }
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getIsPlannedRelativeToParentSelector(s)(this.#node.id),
        (hidePlanningDependencies) => {
          this.#hidePlanningDependencies = hidePlanningDependencies;
          this.#renderPlanningDependencies();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => getPlanningPredecessorsSelector(s)(this.#node.id, true),
        (planningDependencies) => {
          this.#planningDependencies = planningDependencies;
          this.#renderPlanningDependencies();
        },
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => getMinMaxPlanningDatesSelector(s)(this.#node.id),
        (minMaxDates) => {
          this.#minMaxPlanning = minMaxDates;
          this.#renderPlanningDependencies();
          this.#renderLogicalDependencies();
          if (this.#node.type === EnumFlowNodeType.VALUE_TASK) {
            this.#renderAutoChainDependencies();
          }
        },
        isEqual,
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => getNodeSelector(s)(this.#node.id)?.planningLogicalPredecessors || [],
        (planningLogicalPredecessors) => {
          this.#planningLogicalPredecessors = planningLogicalPredecessors;
          this.#renderLogicalDependencies();
        },
      ),
      () => this.#disposePlanningDependencies(),
      () => this.#disposePlanningLogicalDependencies(),
      () => this.#disposeAutoChainDependencies(),
    );
  }

  #disposePlanningDependencies(): void {
    this.disposeList(this.#planningDependencyDisposables);
    this.#planningDependencyRoot.destroyChildren();
  }

  #disposePlanningLogicalDependencies(): void {
    this.disposeList(this.#planningLogicalDependencyDisposables);
    this.#planningLogicalDependencyRoot.destroyChildren();
  }

  #disposeAutoChainDependencies(): void {
    this.disposeList(this.#autoChainDependencyDisposables);
    this.#autoChainDependencyRoot.destroyChildren();
  }

  #getNodePlanningData(): {startInDays: number; widthInDays: number} | null {
    if (this.#minMaxPlanning?.plannedStart) {
      const plannedStart = isoDateTimeToIsoFormat(this.#minMaxPlanning.plannedStart);
      const plannedEnd = isoDateTimeToIsoFormat(this.#minMaxPlanning.plannedEnd);
      const startInDays = getDaysFromStart(plannedStart, this.ctx.calendarView.dateStart);
      const widthInDays = getWidth(plannedStart, plannedEnd);
      return {startInDays, widthInDays};
    }
    return null;
  }

  #renderPlanningDependencies = this.debouncedAction((): void => {
    this.#disposePlanningDependencies();
    if (!this.#hidePlanningDependencies && this.#planningDependencies.length) {
      const planningData = this.#getNodePlanningData();
      if (planningData) {
        for (const dependency of this.#planningDependencies) {
          const predecessorIndex = this.#planningDataLoader.getRowIndex(dependency.predecessor);
          if (predecessorIndex > -1) {
            const line = new DependencyLine({
              ...planningData,
              predecessorIndex,
              barHeight: this.#barHeight,
              color: '#4A5568',
              ctx: this.ctx,
              dependencyType: dependency.type,
              index: this.#rowIndex,
              predecessorId: dependency.predecessor,
              yBarOffset: this.#yBarOffset,
            });
            this.#planningDependencyRoot.add(line.root);
            line.init();
            this.#planningDependencyDisposables.push(() => line.dispose());
          }
        }
      }
    }
  });

  #renderLogicalDependencies = this.debouncedAction((): void => {
    this.#disposePlanningLogicalDependencies();
    if (this.#planningLogicalPredecessors.length) {
      const planningData = this.#getNodePlanningData();
      if (planningData) {
        for (const predecessorId of this.#planningLogicalPredecessors) {
          const predecessorIndex = this.#planningDataLoader.getRowIndex(predecessorId);
          if (predecessorIndex > -1) {
            const line = new DependencyLine({
              ...planningData,
              predecessorId,
              predecessorIndex,
              barHeight: this.#barHeight,
              color: '#2c7be5',
              ctx: this.ctx,
              dash: [6, 2],
              dependencyType: EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR,
              index: this.#rowIndex,
              yBarOffset: this.#yBarOffset,
            });
            this.#planningLogicalDependencyRoot.add(line.root);
            line.init();
            this.#planningLogicalDependencyDisposables.push(() => line.dispose());
          }
        }
      }
    }
  });

  #renderAutoChainDependencies = this.debouncedAction((): void => {
    this.#disposeAutoChainDependencies();
    if (this.#isAutoChain) {
      const planningData = this.#getNodePlanningData();
      const predecessor = getAutoChainLogicalPredecessorSelector(this.ctx.store.getState())(this.#node.id);
      if (predecessor && planningData) {
        const predecessorIndex = this.#planningDataLoader.getRowIndex(predecessor.id);
        if (predecessorIndex > -1) {
          const line = new DependencyLine({
            ...planningData,
            predecessorIndex,
            barHeight: this.#barHeight,
            color: '#2c7be5',
            ctx: this.ctx,
            dash: [6, 2],
            dependencyType: EnumFlowPlanningDependencyType.VALUE_START_AFTER_PREDECESSOR,
            index: this.#rowIndex,
            predecessorId: predecessor.id,
            yBarOffset: this.#yBarOffset,
          });
          this.#autoChainDependencyRoot.add(line.root);
          line.init();
          this.#autoChainDependencyDisposables.push(() => line.dispose());
        }
      }
    }
  }, 10);
}
