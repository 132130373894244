import {
  getNodeGuestPermissionRoleAssignments,
  getNodeInternalPermissionRoleAssignments,
  getNodeProjectRoleAssignments,
  getNodes,
} from '@octaved/flow-api';
import {executeNetworkMiddlewareFetch} from '@octaved/network/src/NetworkMiddleware';
import {
  CALL_API,
  CallAction,
  NETWORK_REQUEST,
  NETWORK_SUCCESS,
  NetworkType,
  ServerResponseAction,
} from '@octaved/network/src/NetworkMiddlewareTypes';
import {SomeAction} from '@octaved/store/src/Store';
import {patchOrgUserSettings} from '@octaved/users/config/routes';
import {omit} from 'lodash';
import {MiddlewareAPI, UnknownAction, isAction} from 'redux';
import {addActionToSimulationSnapshot} from '../../Modules/SimulationSnapshot';
import {isSimulationReplayNetworkCall, setSimulationReplayAction} from './ReplaySimulationMiddleware';
import {setSimulatedAction} from './SimulatedMiddleware';

export default function simulatedNetworkMiddleware() {
  return function (api: MiddlewareAPI) {
    return function (next: (a: unknown) => unknown) {
      function current(action: unknown): Promise<unknown> | unknown {
        if (isAction(action) && !(CALL_API in action && typeof action[CALL_API] === 'undefined')) {
          return next(action);
        }

        return simulateNetworkCall(action as CallAction, api.dispatch);
      }
      return current;
    };
  };
}

const ignoredRoutes = [
  patchOrgUserSettings,
  getNodeGuestPermissionRoleAssignments,
  getNodeInternalPermissionRoleAssignments,
  getNodeProjectRoleAssignments,
  getNodes,
];

const ignoredRoutesToReplay = [
  getNodeGuestPermissionRoleAssignments,
  getNodeInternalPermissionRoleAssignments,
  getNodeProjectRoleAssignments,
  getNodes,
];

function simulateNetworkCall(action: CallAction, next: (a: unknown) => unknown): Promise<unknown> {
  const callAPI = action[CALL_API];
  const {endpoint, types, options = {}, method = 'get'} = callAPI;
  const {requestType, successType} = types;

  function actionWith(data: UnknownAction & {networkType: NetworkType}): ServerResponseAction {
    return {
      endpoint,
      method,
      options,
      requestTime: Date.now(),
      ...omit(action, CALL_API),
      ...data,
    };
  }

  if (method !== 'get' && !ignoredRoutes.includes(endpoint)) {
    next(setSimulatedAction(actionWith({networkType: NETWORK_REQUEST, type: requestType})));
    next(setSimulatedAction(actionWith({networkType: NETWORK_SUCCESS, type: successType})));

    const isSimulationReplay = isSimulationReplayNetworkCall(action);
    if (!isSimulationReplay) {
      next(addActionToSimulationSnapshot(action));
    }
    return Promise.resolve();
  }
  return executeNetworkMiddlewareFetch(action, (action: SomeAction) => {
    if (
      'networkType' in action &&
      'method' in action &&
      action.networkType === NETWORK_SUCCESS &&
      (action.method === 'get' || ignoredRoutesToReplay.includes(endpoint))
    ) {
      setSimulationReplayAction(action);
    }
    return next(action);
  });
}
