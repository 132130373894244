import DialogAndDrawerHeader from '@octaved/flow/src/Components/Layout/DialogAndDrawerHeader';
import {DialogContent, DialogFrame, DialogTitle} from '@octaved/flow/src/Dialogs/DialogFrame';
import {nodeTreeInvertedSelector} from '@octaved/flow/src/Modules/Selectors/NodeTreeSelectors';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWButton} from '@octaved/ui';
import {CheckCircle} from 'lucide-react';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getPlanningSuccessorSelector} from '../../../Selectors/PlanningDependencySelectors';

type DeleteType = 'timeBased' | 'all';

const translationTokens: Record<
  DeleteType,
  {
    title: string;
    content: string;
  }
> = {
  all: {
    content: 'nodeDetails:field.planningStatus.deletePlanning.content',
    title: 'nodeDetails:field.planningStatus.deletePlanning.title',
  },
  timeBased: {
    content: 'nodeDetails:field.timeBasedPlanning.noPlanning.content',
    title: 'nodeDetails:field.timeBasedPlanning.noPlanning.title',
  },
};

interface SwitchPlanningTypeModalProps {
  deleteType?: DeleteType;
  nodeId: Uuid;
  onCancel(): void;
  onConfirm(): void;
}

export default function SwitchPlanningTypeModal({
  deleteType,
  nodeId,
  onCancel,
  onConfirm,
}: SwitchPlanningTypeModalProps): ReactElement | null {
  const {t} = useTranslation();

  const childIds = useSelector(nodeTreeInvertedSelector).get(nodeId);
  const predecessors = useSelector(getPlanningSuccessorSelector)(nodeId, true);

  if (deleteType) {
    const {content, title} = translationTokens[deleteType];
    return (
      <DialogFrame size={'tiny'} open closeOnDimmerClick closeOnDocumentClick centered={false}>
        <DialogTitle onClose={onCancel}>
          <DialogAndDrawerHeader header={title} />
        </DialogTitle>
        <DialogContent className={'flex flex-col gap-y-6 !pb-4'}>
          <div>{t(content)}</div>
          <div className={'flex justify-center'}>
            <TWButton withIcon variant={'solid'} size={'lg'} colorScheme={'primary'} onClick={onConfirm}>
              <CheckCircle className={'size-4'} />
              {t('general:ok')}
            </TWButton>
          </div>
        </DialogContent>
      </DialogFrame>
    );
  }

  return (
    <DialogFrame size={'tiny'} open closeOnDimmerClick closeOnDocumentClick centered={false}>
      <DialogTitle onClose={onCancel}>
        <DialogAndDrawerHeader header={'pages:projects.inspector.manage.planning.planningTypeSwitch.headline'} />
      </DialogTitle>
      <DialogContent className={'flex flex-col gap-y-6 !pb-4'}>
        {t('pages:projects.inspector.manage.planning.planningTypeSwitch.description')}

        <ul className={'flex list-inside list-disc flex-col gap-y-2'}>
          <li>{t('pages:projects.inspector.manage.planning.planningTypeSwitch.unplannedInProjectPlanning')}</li>
          <li>{t('pages:projects.inspector.manage.planning.planningTypeSwitch.unplannedInTeamPlanning')}</li>
          {predecessors.length > 0 && (
            <li>{t('pages:projects.inspector.manage.planning.planningTypeSwitch.dependendWorkPackages')}</li>
          )}
          {childIds && childIds.length > 0 && (
            <li>{t('pages:projects.inspector.manage.planning.planningTypeSwitch.unplannedTasks')}</li>
          )}
        </ul>

        <div className={'flex justify-center'}>
          <TWButton withIcon variant={'solid'} colorScheme={'primary'} onClick={onConfirm}>
            <CheckCircle className={'size-4'} />
            {t('general:ok')}
          </TWButton>
        </div>
      </DialogContent>
    </DialogFrame>
  );
}
