import {IconButton, Tooltip} from '@octaved/ui';
import {ChevronDown, Trash} from 'lucide-react';
import {ComponentType, MouseEvent, ReactElement, useCallback} from 'react';

interface Props {
  Icon?: ComponentType;
  ResetIcon?: ComponentType;
  alwaysVisible?: boolean;
  focused?: boolean;
  hasContent?: boolean;
  onReset?: (event: MouseEvent<HTMLButtonElement>) => void;
  onToggleFocus?: (event: MouseEvent<HTMLButtonElement>) => void;
  resetInfo?: string;
}

export default function CellActionButton({
  Icon = ChevronDown,
  ResetIcon = Trash,
  alwaysVisible = false,
  focused,
  hasContent,
  onReset,
  onToggleFocus,
  resetInfo,
}: Props): ReactElement {
  const reset = useCallback(
    (e) => {
      e.stopPropagation();
      if (onReset) {
        onReset(e);
      }
      return false;
    },
    [onReset],
  );

  if (focused && hasContent && onReset && resetInfo) {
    return (
      <Tooltip toolTipTranslation={resetInfo}>
        <IconButton className={'onHover'} variant={'ghost'} icon={<ResetIcon />} onClick={reset} size={'sm'} />
      </Tooltip>
    );
  }

  return (
    <IconButton
      className={alwaysVisible ? '' : 'onHover'}
      icon={<Icon />}
      onClick={onToggleFocus}
      size={'sm'}
      variant={'ghost'}
    />
  );
}
