import {FlowState} from '@octaved/flow/src/Modules/State';
import {generateStandardInspectorRoute} from '@octaved/flow/src/Routing/Routes/Inspectors/StandardInspector';
import {Dispatch, PropsWithChildren, SetStateAction, createContext, useContext} from 'react';
import {Store} from 'redux';
import {DragMode} from '../../../../../../../Modules/Ui';
import {CalendarContainerContext} from '../../../../CalendarContainerContext';
import {Add} from './Add';
import {Create} from './Create';
import {DefaultDragging} from './DefaultDragging';
import {Dragging} from './Dragging';
import {Resize} from './Resize';

export type SetDraggingMode = Dispatch<SetStateAction<DragMode | null>>;

interface BarManipulatingContextProps {
  store: Store<FlowState>;
  calendarContext: CalendarContainerContext;
  navigate(to: string): void;
  dragging?: typeof Dragging;
  setDragMode?: SetDraggingMode;
}

export class BarManipulatingContext {
  readonly store: Store<FlowState>;
  readonly calendarContext: CalendarContainerContext;

  readonly dragging: Dragging;
  readonly resize: Resize;
  readonly create: Create;
  readonly add: Add;

  readonly navigate: (to: string) => void;

  constructor({store, calendarContext, navigate, dragging: Dragging, setDragMode}: BarManipulatingContextProps) {
    this.store = store;
    this.calendarContext = calendarContext;
    this.navigate = navigate;
    if (!Dragging) {
      this.dragging = new DefaultDragging({ctx: this, setDragMode});
    } else {
      this.dragging = new Dragging({ctx: this, setDragMode});
    }
    this.resize = new Resize({ctx: this});
    this.create = new Create({ctx: this});
    this.add = new Add({ctx: this, setDragMode});
  }

  closeInspector(): void {
    this.navigate(generateStandardInspectorRoute(null));
  }

  isUpdating(): boolean {
    return this.resize.barIsUpdating() || this.create.barIsUpdating() || this.dragging.barIsUpdating();
  }

  cancel(): void {
    this.resize.cancel();
    this.create.cancel();
    this.dragging.cancel();
  }
}

const barManipulatingContext = createContext<BarManipulatingContext | null>(null);

export function useBarManipulatingContext(): BarManipulatingContext {
  const context = useContext(barManipulatingContext);
  if (!context) {
    throw new Error('barManipulatingContext not set');
  }
  return context;
}

interface BarManipulatingContextProviderProps {
  contextValue: BarManipulatingContext;
}

export default function BarManipulatingContextProvider({
  children,
  contextValue,
}: PropsWithChildren<BarManipulatingContextProviderProps>): React.ReactElement {
  return <barManipulatingContext.Provider value={contextValue}>{children}</barManipulatingContext.Provider>;
}
