import * as TabsPrimitive from '@radix-ui/react-tabs';
import {VariantProps, cva} from 'class-variance-authority';
import * as React from 'react';
import {cn} from './Hooks';

const TabButtons = TabsPrimitive.Root;

const tableButtonsListVariants = cva(
  'inline-flex items-center justify-center bg-slate-100 text-slate-600 border border-slate-300',
  {
    defaultVariants: {
      size: 'default',
    },
    variants: {
      size: {
        default: 'h-9 p-1 rounded-md',
        sm: 'h-7 py-0.5 px-1 rounded',
      },
    },
  },
);

type TabButtonsListProps = VariantProps<typeof tableButtonsListVariants>;

const TabButtonsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List> & TabButtonsListProps,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & TabButtonsListProps
>(({className, size, ...props}, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn('group/tabButtonList', tableButtonsListVariants({size, className}))}
    data-size={size}
    {...props}
  />
));
TabButtonsList.displayName = TabsPrimitive.List.displayName;

const TabButtonsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({className, ...props}, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1 text-sm font-medium ring-offset-2 transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-sky-700 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-slate-800 data-[state=active]:shadow-sm group-data-[size=sm]/tabButtonList:px-2 group-data-[size=sm]/tabButtonList:py-1',
      className,
    )}
    {...props}
  />
));
TabButtonsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabButtonsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({className, ...props}, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring mt-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
));
TabButtonsContent.displayName = TabsPrimitive.Content.displayName;

export {TabButtons, TabButtonsContent, TabButtonsList, TabButtonsTrigger};
