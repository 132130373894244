import {FilterStatesValueArray, isComplete} from '../../../Modules/Filter/MergeFilterStatesWithDefaults';

export type QuickControlActivityDomain = 'timeTracking' | 'tasks' | 'board' | 'execution';

export interface QuickControlActivity {
  domains: FilterStatesValueArray<QuickControlActivityDomain>;
  isWithin: boolean;
  showAllWorkPackages: boolean;
  withinCustomDate: string; //iso date
  withinPeriod: 'last24hours' | 'last7days' | 'last30days' | 'last6months' | 'custom';
  withoutCustomDate: string; //iso date
  withoutPeriod: '3days' | '30days' | '6months' | '12months' | 'custom';
}

export const quickControlActivityDefault: QuickControlActivity = {
  domains: ['timeTracking', 'tasks', 'board', 'execution'],
  isWithin: true,
  showAllWorkPackages: false,
  withinCustomDate: '',
  withinPeriod: 'last30days',
  withoutCustomDate: '',
  withoutPeriod: '3days',
};

quickControlActivityDefault.domains[isComplete] = true;
