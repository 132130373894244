import {EnumMediaFileType} from '@octaved/env/src/dbalEnumTypes';
import {getNumberFormatter} from '@octaved/users/src/Culture/Culture';

export const fileTypeTokens: Record<EnumMediaFileType, string> = {
  archive: 'generalCore:fileTypes.archive',
  audio: 'generalCore:fileTypes.audio',
  binary: 'generalCore:fileTypes.file',
  excel: 'generalCore:fileTypes.excel',
  image: 'generalCore:fileTypes.image',
  openofficecalc: 'generalCore:fileTypes.openOfficeCalc',
  openofficeimpress: 'generalCore:fileTypes.openOfficeImpress',
  openofficewriter: 'generalCore:fileTypes.openofficeWriter',
  pdf: 'generalCore:fileTypes.pdf',
  powerpoint: 'generalCore:fileTypes.powerPoint',
  text: 'generalCore:fileTypes.text',
  video: 'generalCore:fileTypes.video',
  word: 'generalCore:fileTypes.word',
};

/**
 * Formats the file size into a readable format
 *
 * Size is initally planned to be in bytes.
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes < 1024) {
    return '1 kB';
  }

  const numberFormatter = getNumberFormatter();
  const k = 1024;
  const sizes = ['B', 'kB', 'MB', 'GB'];
  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), sizes.length - 1);
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));
  return `${numberFormatter.formatNumber(size, decimals)} ${sizes[i]}`;
}
