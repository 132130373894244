import {lazy, ReactNode} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import FlowOutlet from '../../Components/FlowOutlet';
import {projectsSubMenuCollapsedSelector} from '../../Modules/Selectors/UiPages/ProjectsSelector';
import {setUiState} from '../../Modules/Ui';
import MenuPage from '../../Pages/PageFrame/MenuPage';
import {PagesSubMenuOption} from '../../Pages/PagesSubMenu';
import LastVisitRedirect from '../LastVisitRedirect';
import {dialogRouteObjects} from './Dialogs';
import {userTimeBookingsRoute} from './PageRoutes';

const chargeableQuotaRoute = 'chargeableQuota';
const bookedTimesRoute = 'bookedTimes';
const billingTypesRoute = 'billingTypes';
const noChargeRoute = 'noCharge';
const roundingGainRoute = 'roundingGain';

const ChargeableQuota = lazy(() => import('../../Pages/Reporting/Pages/ChargeableQuota/ChargeableQuota'));
const BookedTimes = lazy(() => import('../../Pages/Reporting/Pages/BookedTimes/BookedTimes'));
const BillingTypes = lazy(() => import('../../Pages/Reporting/Pages/BillingTypes/BillingTypes'));
const NoCharge = lazy(() => import('../../Pages/Reporting/Pages/NoCharge/NoCharge'));
const RoundingGain = lazy(() => import('../../Pages/Reporting/Pages/RoundingGain/RoundingGain'));

const teamAnalysisMenu: PagesSubMenuOption[] = [
  {
    header: true,
    name: 'pages:reporting.menu.timeBookings',
  },
  {
    name: 'pages:reporting.menu.billableTimes',
    path: bookedTimesRoute,
  },
  {
    name: 'pages:reporting.chargeableQuota.pageName',
    path: chargeableQuotaRoute,
  },
  {
    name: 'pages:reporting.menu.billTypes',
    path: billingTypesRoute,
  },
  {
    name: 'pages:reporting.menu.noCharge',
    path: noChargeRoute,
  },
  {
    name: 'pages:reporting.menu.roundingGain',
    path: roundingGainRoute,
  },
];

function createTeamAnalysisRoutes(path: string, element: ReactNode): RouteObject {
  return {
    children: [
      {children: dialogRouteObjects, element, path: ':startPeriod/:timePeriod/*'},
      {
        element: <LastVisitRedirect default={'last/fourteenDays'} />,
        path: '*',
      },
    ],
    element: <FlowOutlet />,
    path: `${path}/*`,
  };
}

export const teamAnalysisRoutes: RouteObject[] = [
  {
    children: [
      createTeamAnalysisRoutes(chargeableQuotaRoute, <ChargeableQuota />),
      createTeamAnalysisRoutes(bookedTimesRoute, <BookedTimes />),
      createTeamAnalysisRoutes(billingTypesRoute, <BillingTypes />),
      createTeamAnalysisRoutes(noChargeRoute, <NoCharge />),
      createTeamAnalysisRoutes(roundingGainRoute, <RoundingGain />),
      {
        element: <Navigate to={`/${userTimeBookingsRoute}`} replace />,
        path: `${userTimeBookingsRoute}/*`,
      },
    ],
    element: (
      <MenuPage
        menuOptions={teamAnalysisMenu}
        collapsedSelector={projectsSubMenuCollapsedSelector}
        patchUi={(data) => setUiState({pages: {projects: data}})}
      />
    ),
  },
  {path: '*', element: <LastVisitRedirect default={bookedTimesRoute} />},
];
