import {MouseResizeEvents} from '@octaved/flow/src/Hooks/MouseMove';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {Dispatch, SetStateAction} from 'react';
import {BarManipulatingContext, SetDraggingMode} from './BarManipulatingContext';

export interface DraggingProps {
  ctx: BarManipulatingContext;
  setDragMode?: SetDraggingMode;
}

export interface DragStart {
  setAdjustedPlannedStart: Dispatch<SetStateAction<DateStr>>;
  setAdjustedPlannedEnd: Dispatch<SetStateAction<DateStr>>;
  setTop: Dispatch<SetStateAction<number>>;
  nodeId: Uuid;
  planningDateId: Uuid;
  plannedStart: DateStr;
  plannedEnd: DateStr;
  event: MouseResizeEvents;
  createOnDrag?: boolean;
}

export class Dragging {
  protected readonly ctx: BarManipulatingContext;
  protected readonly setDragMode?: SetDraggingMode;

  constructor({ctx: context, setDragMode: setIsDragging}: DraggingProps) {
    this.ctx = context;
    this.setDragMode = setIsDragging;
  }

  protected getDragImpl(): DraggingImpl {
    throw new Error('getDragImpl not implemented');
  }

  onEnd = (
    clientX: number,
    clientY: number,
    event: MouseEvent | TouchEvent,
    clientStartX: number,
    clientStartY: number,
  ): void => {
    this.getDragImpl().onEnd(clientX, clientY, event, clientStartX, clientStartY);
  };

  onMove = (
    clientX: number,
    clientY: number,
    event: MouseEvent | TouchEvent,
    clientStartX: number,
    clientStartY: number,
  ): void => {
    this.getDragImpl().onMove(clientX, clientY, event, clientStartX, clientStartY);
  };

  onStart = (data: DragStart): void => {
    this.getDragImpl().onStart(data);
  };

  isDragging(): boolean {
    return this.getDragImpl().isDragging();
  }

  barIsUpdating(): boolean {
    return this.getDragImpl().barIsUpdating();
  }

  cancel(): void {
    this.getDragImpl().cancel();
  }
}

interface DragState {
  isDragging: boolean;
}

export abstract class DraggingImpl<STATE extends DragState = DragState, DRAG_START = DragStart> {
  protected readonly ctx: BarManipulatingContext;
  protected state: STATE | null = null;
  protected readonly setDragMode?: SetDraggingMode;

  constructor({ctx: context, setDragMode}: DraggingProps) {
    this.ctx = context;
    this.setDragMode = setDragMode;
  }

  abstract onEnd(
    clientX: number,
    clientY: number,
    event: MouseEvent | TouchEvent,
    clientStartX: number,
    clientStartY: number,
  ): void;

  abstract onMove(
    clientX: number,
    clientY: number,
    event: MouseEvent | TouchEvent,
    clientStartX: number,
    clientStartY: number,
  ): void;

  abstract onStart(data: DRAG_START): void;

  isDragging(): boolean {
    return Boolean(this.state?.isDragging);
  }

  barIsUpdating(): boolean {
    return Boolean(this.state);
  }

  delayedCancel(): void {
    //delay reset so that the click is handeld
    setTimeout(() => {
      this.cancel();
    }, 100);
  }

  cancel(): void {
    if (this.state) {
      this.state = null;
      this.setDragMode?.(null);
    }
  }
}
