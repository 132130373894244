import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {useAncestorBudgetSums} from '@octaved/flow/src/Modules/Projects/AncestorBudgetSums';
import {isGroup, isProject} from '@octaved/flow/src/Node/NodeIdentifiers';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {TWAutosaveInput, TWProgress} from '@octaved/ui';
import {formatDecimal, parse} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

export interface NodeBudgetScopeProps {
  node: Project | Group;
  patch: (
    data: Partial<{
      maxPrice: number;
    }>,
  ) => void;
  readonly?: boolean;
}

export default function NodeBudgetScope({
  frame: Frame,
  node,
  patch,
  readonly,
}: NodeBudgetScopeProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const currencySymbol = useSelector((state: FlowState) => state.settings.currencySymbol);
  const {countWorkPackages, countWorkPackagesWithPrice, sumPriceLimits} = useAncestorBudgetSums(node.id);

  if (!isProject(node) && !isGroup(node)) {
    return null;
  }

  return (
    <Frame
      labelToken={
        isProject(node) ? 'nodeDetails:field.budget.label.project' : 'nodeDetails:field.budget.label.subProject'
      }
    >
      <div className={'flex flex-col gap-y-4'}>
        <div className={'flex flex-col gap-y-2'}>
          <div className={'flex items-center gap-x-2'}>
            <TWAutosaveInput
              wrapperClassname={'w-numberInput'}
              type={'number'}
              value={node.maxPrice ? node.maxPrice + '' : ''}
              onSave={(maxPrice) => patch({maxPrice: maxPrice ? parse(maxPrice) : undefined})}
              readOnly={readonly}
            />
            <div>{currencySymbol}</div>
          </div>
        </div>

        {node.maxPrice !== null && node.maxPrice !== undefined && (
          <div className={'grid grid-cols-progressWithLabel items-center gap-x-3 gap-y-1'}>
            <TWProgress
              className={'my-1.5 self-start'}
              progress={(sumPriceLimits * 100) / node.maxPrice}
              color={sumPriceLimits > node.maxPrice ? 'red' : sumPriceLimits < node.maxPrice ? 'orange' : 'green'}
            />

            <div className={'flex flex-col gap-y-1'}>
              <Trans
                i18nKey={'nodeDetails:field.budget.assignedPrices'}
                count={node.maxPrice}
                values={{
                  currencySymbol,
                  m: formatDecimal(sumPriceLimits),
                  n: formatDecimal(node.maxPrice),
                }}
              />
              <div className={'text-sm'}>
                {t('nodeDetails:field.budget.workPackagesWithPrice', {
                  total: countWorkPackages,
                  withPrice: countWorkPackagesWithPrice,
                })}
                <br />
                {t('nodeDetails:field.budget.sumWorkPackagePrices', {
                  currencySymbol,
                  total: formatDecimal(sumPriceLimits),
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Frame>
  );
}
