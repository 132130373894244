import {IconButton} from '@octaved/ui';
import classNames from 'classnames';
import {GripVertical} from 'lucide-react';
import {MutableRefObject, ReactElement} from 'react';

export default function DragHandle({dragRef}: {dragRef?: MutableRefObject<HTMLDivElement | null>}): ReactElement {
  return (
    <div className={classNames('dragHandleBorder')}>
      <div className={'dragHandleWrapper'}>
        <IconButton size={'sm'} icon={<GripVertical />} variant={'ghost'} cursor={'grab'} ref={dragRef} />
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .dragHandleBorder {
          overflow: hidden;
          width: var(--drag-handle-width);
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          border-top: var(--drag-handle-vertical-border-width, 0) solid
            var(--drag-handle-vertical-border-color, var(--color-bg));
          border-bottom: var(--drag-handle-vertical-border-width, 0) solid
            var(--drag-handle-vertical-border-color, var(--color-bg));
        }

        .dragHandleWrapper {
          overflow: hidden;
          /* --drag-handle-visible is set to 1 upon hovering */
          width: calc(var(--drag-handle-width) * var(--drag-handle-visible, 0));
          height: 100%;
          z-index: 2;
          display: flex;
          align-items: center;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
