import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {getGroupedMilestonesForNodeSelector} from '../../../Selectors/MilestoneSelectors';
import {Bar, BarProps} from '../../Bars/Bar';
import {createStoreSubscription} from '../../StoreSubscription';
import {BarRow} from './BarRow';

export class MinMaxBarRow extends BarRow {
  init(): void {
    super.init();
    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => getRootGroupTypeSelector(s)(this.node.id),
        () => this.#renderBars(),
      ),
      createStoreSubscription(
        this.ctx.store,
        (s) => getGroupedMilestonesForNodeSelector(s)(this.node.id),
        () => this.#renderBars(),
      ),
    );
  }

  #renderBars = this.debouncedAction((): void => {
    this.root.destroyChildren();
    this.disposeRows();

    const barProps: BarProps = {
      ctx: this.ctx,
      height: this.height,
      isFirst: false,
      isLast: false,
      menuRoot: this.menuRoot,
      rowIndex: this.rowIndex,
      rowRoot: this.root,
      treeNode: this.node,
      yOffset: this.yOffset,
    };
    let bar: null | Bar = null;
    if (this.node.type === EnumFlowNodeType.VALUE_GROUP) {
      bar = this.barFactory.createGroupBar(barProps);
    } else if (this.node.type === EnumFlowNodeType.VALUE_PROJECT) {
      bar = this.barFactory.createProjectBar(barProps);
    } else if (this.node.type === EnumFlowNodeType.VALUE_TASK_SECTION) {
      bar = this.barFactory.createTaskSectionBar(barProps);
    }
    this.disposableRows.push(() => bar?.dispose());
    if (this.node.type === EnumFlowNodeType.VALUE_GROUP || this.node.type === EnumFlowNodeType.VALUE_PROJECT) {
      //render milestones and due dates
      const maturities = getGroupedMilestonesForNodeSelector(this.ctx.store.getState())(this.node.id);
      for (const maturity of maturities) {
        const bar = this.barFactory.createMaturityBar(barProps, maturity);
        this.disposableRows.push(() => bar?.dispose());
      }
    }
  });
}
