import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {createSubRecordSelector} from '@octaved/store/src/Selectors/CreateSubRecordSelector';
import {getParentsToChildrenMap} from '@octaved/trees/src/GenericTreeBuilder';
import {Uuid} from '@octaved/typescript/src/lib';
import {castFilter} from '@octaved/utilities';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isTask} from '../../../../Node/NodeIdentifiers';
import {getNodeSearchSelector} from '../../../../Modules/Selectors/NodeSearchSelectors';
import {nodeEntitySelector} from '../../../../Modules/Selectors/NodeSelectors';
import {useTaskListContext} from '../../TaskListContext';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';
import {useDynamicDragContext, useStaticDragContext} from '../DragAndDrop/DragContext';
import {useDropOnEmptyParent} from '../DragAndDrop/DragItems';
import {useManuallySortedIndexes} from '../DragAndDrop/ManuallySortedIndexes';
import {useTaskListForNodeContext} from '../TaskListForNodeContext';
import TableHeader from '../TasksHeader/TableHeader';
import AddTaskRows from './AddTaskRows';
import {makeTaskShallow} from './ShallowTask';
import TaskRowBox from './TaskRowBox';

interface Props {
  parentId: string;
  depth?: number;
}

export default function TaskList({depth = 0, parentId}: Props): ReactElement | null {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {treeSelector} = useDynamicDragContext();
  const tree = useSelector(treeSelector);
  const taskIds = useSelector(getNodeSearchSelector('nodeType', EnumFlowNodeType.VALUE_TASK));
  const {visibleTaskIds} = useTaskListForNodeContext();
  const {allowCreationOfTasks, hideEmptyTaskList} = useTaskListContext();

  const [filteredTaskIds, taskIdsWithSubTasks] = useMemo(() => {
    const invertedTree = getParentsToChildrenMap(tree);
    const getChildIds = (nodeId: Uuid): Uuid[] => (invertedTree.get(nodeId) || []).filter((id) => taskIds.has(id));
    const childTaskIds = getChildIds(parentId).filter((id) => visibleTaskIds.has(id));

    const childTaskIdsWithSubTasks = childTaskIds.reduce((acc, id) => {
      return getChildIds(id).length ? acc.add(id) : acc;
    }, new Set<Uuid>());

    return [childTaskIds, childTaskIdsWithSubTasks];
  }, [parentId, taskIds, tree, visibleTaskIds]);

  const filteredTasksRecord = useSelector(createSubRecordSelector(nodeEntitySelector, filteredTaskIds));
  const filteredTasks = useMemo(() => castFilter(Object.values(filteredTasksRecord), isTask), [filteredTasksRecord]);

  const {isDraggingRef, isSet} = useStaticDragContext();
  const indexes = useManuallySortedIndexes(parentId, 'task', filteredTasks);
  const sortedTasks = useMemo(() => {
    if (isSet && isDraggingRef.current) {
      return [...filteredTasks].sort((a, b) => (indexes[a.id] || -1) - (indexes[b.id] || -1));
    }
    return [...filteredTasks].sort((a, b) => a.sortOrder - b.sortOrder);
  }, [isSet, isDraggingRef, filteredTasks, indexes]);

  const dropOnEmptyTable = useDropOnEmptyParent(
    parentId,
    sortedTasks.length === 0 && depth === 0 && !readonlyOrNotManageable,
    'task',
  );

  if (hideEmptyTaskList && sortedTasks.length === 0) {
    return null;
  }

  return (
    <div ref={dropOnEmptyTable}>
      {depth === 0 && sortedTasks.length > 0 && <TableHeader />}

      {sortedTasks.map((task, idx) => (
        <TaskRowBox
          depth={depth}
          hasSiblingsWithSubTasks={taskIdsWithSubTasks.size > 0}
          hasSubTasks={taskIdsWithSubTasks.has(task.id)}
          index={idx}
          key={task.id}
          parentId={parentId}
          previousSiblingId={sortedTasks[idx - 1]?.id}
          task={makeTaskShallow(task)}
        />
      ))}

      {depth === 0 && !readonlyOrNotManageable && allowCreationOfTasks && (
        <AddTaskRows
          hasAnySubTasks={taskIdsWithSubTasks.size > 0}
          parentId={parentId}
          previousSiblingId={sortedTasks[sortedTasks.length - 1]?.id}
          reducedLeftPadding={sortedTasks.length === 0}
        />
      )}
    </div>
  );
}
