import {hasPidHours} from '@octaved/flow/src/Modules/Pid';
import {settingsSelector} from '@octaved/flow/src/Modules/Selectors/SettingSelectors';
import {useWorkPackagePatch} from '@octaved/flow/src/Modules/WorkPackages';
import ExcelExportButton from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/ExcelExportButton';
import {ExportMode} from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/ExportModeSelection';
import IncludeTasks from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/IncludeTasks';
import TransmitButton from '@octaved/flow/src/Pages/CreateOffer/Inspector/Export/TransmitButton';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {noop} from 'lodash';
import {ReactElement, useState} from 'react';
import {useSelector} from 'react-redux';
import {NodeTasksSection} from '../../../Components/Fields/General/Section/NodeTasksSection';
import NodeBillingTypeSection from '../../../Components/Fields/TimeAndMoney/Section/NodeBillingTypeSection';
import WorkPackageEffortSection from '../../../Components/Fields/TimeAndMoney/Section/WorkPackage/Section/WorkPackageEffortSection';
import InspectorViewFrame from '../../InspectorViewFrame';
import {WorkPackageDetailsProps} from './General';

export default function SpecialQuote({node}: WorkPackageDetailsProps): ReactElement {
  const patch = useWorkPackagePatch(node.id);
  const canManage = useIsGranted('FLOW_NODE_MANAGE_PRICES', node.id) && !node.isArchived;
  const {
    webhooks: {transmitOffer: isWebhookConfigured},
  } = useSelector(settingsSelector);
  const [exportMode] = useState<ExportMode>(isWebhookConfigured ? 'erp' : 'excel');
  const [includeTasks, setIncludeTasks] = useState(false);

  return (
    <InspectorViewFrame help={'nodeDetails:view.specialQuote.help'}>
      <NodeTasksSection node={node} />
      {hasPidHours(node) && <WorkPackageEffortSection node={node} patch={patch} readonly={!canManage} />}
      <NodeBillingTypeSection node={node} readonly patch={noop} />

      <div className={'flex flex-col gap-y-2'}>
        <IncludeTasks includeTasks={includeTasks} setIncludeTasks={setIncludeTasks} />
        {exportMode === 'excel' && <ExcelExportButton pidId={node.id} includeTasks={includeTasks} />}
        {exportMode === 'erp' && <TransmitButton pidId={node.id} includeTasks={includeTasks} />}
      </div>
    </InspectorViewFrame>
  );
}
