import RemainingChars from '@octaved/flow/src/Components/RemainingChars';
import {Check} from 'lucide-react';
import {forwardRef, useEffect, useRef, useState} from 'react';
import {cn} from './Hooks';
import {TWButton} from './TWButton';
import {TWTextArea, TWTextareaProps} from './TWTextArea';

export type TWAutosaveTextareaProps = Omit<TWTextareaProps, 'value' | 'onChange' | 'onFocus' | 'onBlur'> & {
  onSave: (value: string) => Promise<void> | void;
  required?: boolean;
  requiredUntouched?: boolean; //same as required, but will cause error state without any user interaction
  setValue?: (val: string) => void;
  value: string;
  wrapperClassname?: string;
};

export const TWAutosaveTextarea = forwardRef<HTMLTextAreaElement, TWAutosaveTextareaProps>(
  ({hasError, required, requiredUntouched, onSave, setValue, value, wrapperClassname = '', ...rest}, ref) => {
    const [__value, __setValue] = useState<string>(value);
    const _value = setValue ? value : __value;
    const _setValue = setValue ? setValue : __setValue;

    const hasChanges = value !== _value;
    const focused = useRef(false);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
      if (!focused.current) {
        _setValue(value);
      }
    }, [_setValue, value]);

    return (
      <div className={'flex flex-col gap-y-1'}>
        <div className={cn('relative w-full', wrapperClassname)}>
          <TWTextArea
            ref={ref}
            className={cn(hasChanges && 'pr-8')}
            hasError={hasError || (((required && touched) || requiredUntouched) && !_value)}
            value={_value}
            onChange={(e) => _setValue(e.target.value)}
            onFocus={() => {
              focused.current = true;
            }}
            onBlur={async (e) => {
              const val = e.target.value;
              setTouched(true);
              //If `setValue` is given, we can assume that `val === value` all the time, so the check is useless:
              if ((!(required || requiredUntouched) || val) && (setValue || val !== value)) {
                await onSave(e.target.value);
              }
              focused.current = false;
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                (e.target as HTMLTextAreaElement).blur();
              }
            }}
            {...rest}
          />

          {hasChanges && (
            <TWButton
              colorScheme={'primary'}
              variant={'solid'}
              size={'xs'}
              iconOnly
              className={'absolute bottom-1.5 right-1.5 h-6 w-6'}
            >
              <Check className={'h-4 w-4'} />
            </TWButton>
          )}
        </div>
        {rest.maxLength && !rest.disabled && <RemainingChars maxChars={rest.maxLength} currentChars={_value.length} />}
      </div>
    );
  },
);
TWAutosaveTextarea.displayName = 'TWAutosaveTextarea';
