import {useEffect, useRef} from 'react';

/**
 * Calls your callback periodically.
 *
 * NOTE: Do not use setInterval(), it can lead to unwanted calls if the main thread is blocked longer than the delay.
 */
export function useInterval(cb: () => void, delay: number): void {
  const fn = useRef<() => void>();
  useEffect(() => {
    fn.current = cb;
  }, [cb]);
  useEffect(() => {
    let disposed = false;
    let timer: NodeJS.Timeout;
    const saver = (): void => {
      if (!disposed) {
        if (fn.current) {
          fn.current();
        }
        timer = setTimeout(saver, delay);
      }
    };
    timer = setTimeout(saver, delay);
    return () => {
      disposed = true;
      clearTimeout(timer);
    };
  }, [delay]);
}
