import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {EffectiveRoleAssignment} from '@octaved/flow/src/EntityInterfaces/RoleAssignments/NodeRoleAssignments';
import {useEffectiveRoleAssignmentsOnNodes} from '@octaved/flow/src/Modules/Hooks/NodeRoleAssignments';
import {Uuid} from '@octaved/typescript/src/lib';
import {useResolvedGroupUsersFromUnits} from '@octaved/users/src/Hooks/ResolvedGroupUsers';
import {ReactElement, useMemo} from 'react';

interface NodeScopeInvolvedUsersProps {
  workPackageIds: ReadonlyArray<Uuid>;
}

export default function NodeScopeInvolvedUsers({workPackageIds}: NodeScopeInvolvedUsersProps): ReactElement {
  const {assignmentsOnNodes, isLoading: isLoadingRoles} = useEffectiveRoleAssignmentsOnNodes(
    EnumFlowNodeRoleType.VALUE_PROJECT,
    workPackageIds,
  );
  const allAssignments = useMemo(
    () =>
      Object.values<EffectiveRoleAssignment[]>(assignmentsOnNodes).reduce(
        (acc, assignments) => [...acc, ...assignments],
        [],
      ),
    [assignmentsOnNodes],
  );
  const {isLoading: isLoadingUserIds, userIds} = useResolvedGroupUsersFromUnits(allAssignments);
  const isLoading = isLoadingRoles || isLoadingUserIds;
  return <div>{isLoading ? '' : userIds.size}</div>;
}
