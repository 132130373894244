import {Uuid} from '@octaved/typescript/src/lib';

export interface SuitcaseDrawer {
  columnCount: 1 | 2 | 3;
  search: string;
  searchTarget: 'mine' | 'all';
  selectedPid: Uuid | null;
  visible: boolean;
}

export const initialSuitcaseDrawerState: SuitcaseDrawer = {
  columnCount: 1,
  search: '',
  searchTarget: 'all',
  selectedPid: null,
  visible: false,
};
