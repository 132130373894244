import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router';

export function useOnUrlChange(callback: () => void): void {
  const location = useLocation();
  const callbackRef = useRef(callback);
  useEffect(() => {
    void location.pathname; //required as dependency for closing after route change
    const cb = callbackRef.current;
    return () => {
      cb?.();
    };
  }, [location.pathname]);
}
