import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {TWColorPicker, TextLink} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from './Type';

const noParentElement = new Set([EnumFlowNodeType.VALUE_PROJECT]);

export interface NodeColorProps {
  nodeType: EnumFlowNodeType;
  color: string;
  setColor: (color: string) => void;
  readonly: boolean;
}

export default function NodeColor({
  color,
  frame: Frame,
  nodeType,
  setColor,
  readonly,
}: NodeColorProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  const hasParentResetLink = color && !noParentElement.has(nodeType);
  return (
    <Frame labelToken={'nodeDetails:field.color.label'}>
      <TWColorPicker
        color={color ? `#${color}` : null}
        setColor={(color) => setColor(color.substring(1))}
        readonly={readonly}
        additionalLinks={
          hasParentResetLink ? (
            <TextLink onClick={() => setColor('')}>{t('nodeDetails:field.color.overrideFromParent')}</TextLink>
          ) : undefined
        }
      />
    </Frame>
  );
}
