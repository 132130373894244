import {ReactElement, useContext} from 'react';
import {inspectorContext} from '../InspectorContext/InspectorContext';
import {ContextMenu} from '@octaved/node-details/src/Components/ContextMenu/ContextMenu';
import {ContextMenuTrigger} from '@octaved/node-details/src/Components/ContextMenu/ContextMenuTrigger';

export default function StandardInspectorSubMenu(): ReactElement {
  const context = useContext(inspectorContext);
  const node = context?.selectedEntity;

  return (
    <ContextMenu nodeId={node?.id} hideOpenDetails>
      <ContextMenuTrigger />
    </ContextMenu>
  );
}
