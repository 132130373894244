import {TWInput} from '@octaved/ui';
import {ReactElement, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {projectsSearchSelector} from '../../../Modules/Selectors/UiSelectors';
import {useProjectUiStatePatch} from '../Projects';

export default function Search(): ReactElement {
  const storedSearch = useSelector(projectsSearchSelector);
  const [search, setSearch] = useState(storedSearch);
  useEffect(() => setSearch(storedSearch), [storedSearch]);
  const patchProjectsUiState = useProjectUiStatePatch();
  const updateSearch = useCallback((search: string) => patchProjectsUiState({search}), [patchProjectsUiState]);
  const {t} = useTranslation();
  return (
    <TWInput
      className={'h-7'}
      value={search}
      onChange={(e) => setSearch(e.currentTarget.value)}
      onBlur={(e) => updateSearch(e.currentTarget.value)}
      placeholder={t('general:search')}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          updateSearch(e.currentTarget.value);
        }
      }}
    />
  );
}
