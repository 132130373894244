import classNames from 'classnames';
import {ReactElement, useContext, useMemo} from 'react';
import {flowStyleContext} from '../../Styles/StyleContext';
import WorkPackageAsTile, {WorkPackageParams} from '../Tree/Defaults/WorkPackageAsTile';

export default function PlanningDependentWorkPackage(props: WorkPackageParams): ReactElement {
  const {
    COLORS: {PAGES},
  } = useContext(flowStyleContext);
  const isUnplanned = useMemo(() => {
    const wp = props.node.entity;
    return wp?.planningDates.length === 0 && (!wp?.planningPredecessors || wp.planningPredecessors.length === 0);
  }, [props.node.entity]);

  return (
    <div className={classNames('wp', {isUnplanned})}>
      <WorkPackageAsTile {...props} />

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .wp:hover :global(.workPackageTile) {
          background-color: #ecf5ff;
          --wpBorderStatusColor: ${PAGES.PROJECTS.WORKPACKAGE.MAIN_BORDER};
        }

        :global(.dependencyType-planning) .isUnplanned :global(.workPackageTile) {
          --wpBorderStatusColor: ${PAGES.PROJECTS.WORKPACKAGE.DISABLED} !important;
          background-color: #e2e2e2 !important;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
