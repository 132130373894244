import {Uuid} from '@octaved/typescript/src/lib';
import {createContext, PropsWithChildren, ReactElement, useContext} from 'react';

interface ProjectFolderContext {
  selected: Uuid | null;
  setSelected: (id: Uuid | null) => void;
  canSelectSubFolder?: boolean;
}

const context = createContext<ProjectFolderContext | null>(null);

export function ProjectFolderContextProvider({
  children,
  ...props
}: PropsWithChildren<ProjectFolderContext>): ReactElement {
  return <context.Provider value={props}>{children}</context.Provider>;
}

export function useHasProjectFolderContext(): boolean {
  return !!useContext(context);
}

export function useProjectFolderContext(): ProjectFolderContext {
  const value = useContext(context);
  return value || {canSelectSubFolder: false, selected: null, setSelected: () => false};
}
