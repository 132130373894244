import {ComplexTranslatable} from '@octaved/i18n/src/ComplexTrans';
import {ServerResponseAction} from '@octaved/network/src/NetworkMiddlewareTypes';
import {boolFilter} from '@octaved/utilities';
import {validateArray, validateObject, validateString} from '@octaved/validation';
import ObjectContains from '@octaved/validation/src/ObjectContains';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import {Action} from 'redux';
import {ActionDispatcher, Dispatch, Reducer} from './Store';

const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET';
const NOTIFICATION_ADD = 'NOTIFICATIONS_ADD';
const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE';
const NOTIFICATION_FIELD_REMOVE = 'NOTIFICATION_FIELD_REMOVE';
const NOTIFICATIONS_SET_ALL = 'NOTIFICATIONS_SET_ALL';

//#region interfaces
type Level = 'error' | 'info' | 'inputError' | 'severeWarning' | 'warning';

export interface Notification {
  field?: string;
  message: ComplexTranslatable;
}

interface NotificationWithField extends Omit<Notification, 'field'> {
  field: string;
}

interface NotificationResponse {
  field?: string;
  message: string;
}

interface NotificationSetAction {
  type: typeof NOTIFICATIONS_SET;
  statePath: string;
  notificationsKey: string;
  level: Level;
  messages: Notification[];
}

interface NotificationAddAction {
  type: typeof NOTIFICATION_ADD;
  statePath: string;
  notificationsKey: string;
  level: Level;
  message: Notification;
}

interface NotificationRemoveAction {
  type: typeof NOTIFICATION_REMOVE;
  statePath: string;
  notificationsKey: string;
  level: Level;
  message: Notification;
}

interface NotificationFieldRemoveAction {
  type: typeof NOTIFICATION_FIELD_REMOVE;
  statePath: string;
  notificationsKey: string;
  level: Level;
  fieldNames: string[];
}

interface NotificationSetAllAction {
  type: typeof NOTIFICATIONS_SET_ALL;
  statePath: string;
  notificationsKey: string;
  notifications: {
    [key in Level]?: Notification[];
  };
}

export type FieldCondition = string | RegExp;

export type NotificationList = Partial<Record<Level, Notification[]>>;
export type NotificationState<NotiKey extends string> = Record<NotiKey, NotificationList>;

export type NotificationRemoveTypes = Notification | FieldCondition | ReadonlyArray<Notification | FieldCondition>;

export interface NotificationsResult<NotiKey extends string> {
  setTranslationTokens(translationTokens: LocalTranslationTokens): void;

  reduceClearAll<T extends NotificationState<NotiKey>>(state: T): T;

  reduceClearErrors<T extends NotificationState<NotiKey>>(state: T): T;

  reduceResponseFailure<T extends NotificationState<NotiKey>>(
    state: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: {[x: string]: (...args: any[]) => any},
  ): T;

  actions: {
    setErrors(message: Notification[]): ActionDispatcher<void>;
    setInputErrors(message: Notification[]): ActionDispatcher<void>;
    setSevereWarnings(message: Notification[]): ActionDispatcher<void>;
    setWarnings(message: Notification[]): ActionDispatcher<void>;
    setInfos(message: Notification[]): ActionDispatcher<void>;

    addError(message: Notification): ActionDispatcher<void>;
    addInputError(message: Notification): ActionDispatcher<void>;
    addSevereWarning(message: Notification): ActionDispatcher<void>;
    addWarning(message: Notification): ActionDispatcher<void>;
    addInfo(message: Notification): ActionDispatcher<void>;

    addErrors(message: Notification[]): ActionDispatcher<void>;

    removeError(message: Notification): ActionDispatcher<void>;
    removeInputError(message: Notification): ActionDispatcher<void>;
    removeSevereWarning(message: Notification): ActionDispatcher<void>;
    removeWarning(message: Notification): ActionDispatcher<void>;
    removeInfo(message: Notification): ActionDispatcher<void>;

    removeErrorForField(message: NotificationRemoveTypes): ActionDispatcher<void>;
    removeInputErrorForField(message: NotificationRemoveTypes): ActionDispatcher<void>;
    removeSevereWarningForField(message: NotificationRemoveTypes): ActionDispatcher<void>;
    removeWarningForField(message: NotificationRemoveTypes): ActionDispatcher<void>;
    removeInfoForField(message: NotificationRemoveTypes): ActionDispatcher<void>;
  };
}

interface LocalTranslationTokens {
  [x: string]: {
    [x: string]: string;
  };
}

//#endregion

export function notificationFieldMatches(
  notification: Notification,
  conditions: FieldCondition[],
): notification is NotificationWithField {
  const {field} = notification;
  return field
    ? conditions.some((condition) => (typeof condition === 'string' ? condition === field : condition.test(field)))
    : false;
}

function validateMessage(message: ComplexTranslatable): void {
  if (typeof message !== 'string' && !(isObject(message) && message.hasOwnProperty('i18nKey'))) {
    throw new Error('Expected message to either be a string or an array in form of the translation binding.');
  }
}

function hasErrors<NotiKey extends string, T extends NotificationState<NotiKey>>(
  state: T,
  notificationsKey: NotiKey,
): boolean {
  return (state[notificationsKey].error || []).length + (state[notificationsKey].inputError || []).length > 0;
}

function setHasErrors<NotiKey extends string, T extends NotificationState<NotiKey>>(
  state: T,
  hasErrorsKey: string,
  hasError: boolean,
): void {
  if (hasErrorsKey) {
    if (hasErrorsKey[0] === '!') {
      // @ts-ignore is required
      state[hasErrorsKey.substr(1)] = !hasError;
    } else {
      // @ts-ignore is required
      state[hasErrorsKey] = hasError;
    }
  }
}

function setNotifications<NotiKey extends string, T extends NotificationState<NotiKey>>(
  state: T,
  level: Level,
  messages: Notification[],
  hasErrorsKey: string,
  notificationsKey: NotiKey,
): T {
  const newState = {
    ...state,
    [notificationsKey]: {
      ...state[notificationsKey],
      [level]: [...messages],
    },
  };
  setHasErrors(newState, hasErrorsKey, hasErrors(newState, notificationsKey));
  return newState;
}

function clearAll<NotiKey extends string, T extends NotificationState<NotiKey>>(
  state: T,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
): T {
  const newState: T = {
    ...state,
    [notificationsKey]: {
      error: [],
      info: [],
      inputError: [],
      severeWarning: [],
      warning: [],
    },
  };
  setHasErrors(newState, hasErrorsKey, false);
  return newState;
}

function clearErrors<NotiKey extends string, T extends NotificationState<NotiKey>>(
  state: T,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
): T {
  const newState: T = {
    ...state,
    [notificationsKey]: {
      ...state[notificationsKey],
      error: [],
      inputError: [],
    },
  };
  setHasErrors(newState, hasErrorsKey, false);
  return newState;
}

function reduceSet<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
  state: T,
  action: NotificationSetAction = {} as NotificationSetAction,
): T {
  const defaultState = {[notificationsKey]: {}};
  if (action.statePath === statePath && action.notificationsKey === notificationsKey) {
    return setNotifications(state || defaultState, action.level, action.messages, hasErrorsKey, notificationsKey);
  }
  return state || (defaultState as T);
}

function reduceSetAll<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
  state: T,
  action: NotificationSetAllAction = {} as NotificationSetAllAction,
): T {
  const defaultState = {[notificationsKey]: {}};
  if (action.statePath === statePath && action.notificationsKey === notificationsKey) {
    const newState = {
      ...state,
      [notificationsKey]: cloneDeep(action.notifications),
    };
    setHasErrors(newState, hasErrorsKey, hasErrors(newState, notificationsKey));
    return newState;
  }
  return state || defaultState;
}

function reduceAdd<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
  state: T,
  action: NotificationAddAction = {} as NotificationAddAction,
): T {
  const defaultState = {[notificationsKey]: {}};
  if (action.statePath === statePath && action.notificationsKey === notificationsKey) {
    const activeState = state || defaultState;
    const messages = [...(activeState[notificationsKey][action.level] || []), action.message];
    return setNotifications(activeState, action.level, messages, hasErrorsKey, notificationsKey);
  }
  return state || defaultState;
}

function reduceRemove<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
  state: T,
  action: NotificationRemoveAction = {} as NotificationRemoveAction,
): T {
  const defaultState = {[notificationsKey]: {}};
  if (action.statePath === statePath && action.notificationsKey === notificationsKey) {
    const activeState = state || defaultState;
    const messages = [...(activeState[notificationsKey][action.level] || [])];
    const index = messages.findIndex(
      (Message: Notification) => Message.field === action.message.field && Message.message === action.message.message,
    );
    messages.splice(index, 1);
    return setNotifications(activeState, action.level, messages, hasErrorsKey, notificationsKey);
  }
  return state || defaultState;
}

function reduceRemoveField<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  hasErrorsKey: string,
  notificationsKey: NotiKey,
  state: T,
  action: NotificationFieldRemoveAction = {} as NotificationFieldRemoveAction,
): T {
  const defaultState = {[notificationsKey]: {}};
  if (action.statePath === statePath && action.notificationsKey === notificationsKey) {
    const activeState = state || defaultState;
    const messages = [...(activeState[notificationsKey][action.level] || [])];
    return setNotifications(
      activeState,
      action.level,
      messages.filter((message) => !message.field || !action.fieldNames.includes(message.field)),
      hasErrorsKey,
      notificationsKey,
    );
  }
  return state || defaultState;
}

function setMessages(
  statePath: string,
  notificationsKey: string,
  level: Level,
  messages: Notification[],
): ActionDispatcher<void> {
  validateArray(messages, validateObject, {message: validateMessage});
  return (dispatch: Dispatch, getState) => {
    const current = get(getState(), `${statePath}.${notificationsKey}.${level}`, []);
    if (!isEqual(current, messages)) {
      dispatch({
        level,
        messages,
        notificationsKey,
        statePath,
        type: NOTIFICATIONS_SET,
      });
    }
  };
}

function addMessage(
  statePath: string,
  notificationsKey: string,
  level: Level,
  message: Notification,
): ActionDispatcher<Promise<void> | null> {
  validateObject(message, {message: validateMessage});
  return (dispatch: Dispatch<NotificationAddAction>, getState) => {
    const current = get(getState(), `${statePath}.${notificationsKey}.${level}`, []);
    // lodash isEqual does not work as in dev mode the readonly proxy is defined for the oldMessage.
    if (
      current.find(
        (oldMessage: Notification) => oldMessage.field === message.field && oldMessage.message === message.message,
      )
    ) {
      return null;
    }
    return dispatch({
      level,
      message,
      notificationsKey,
      statePath,
      type: NOTIFICATION_ADD,
    });
  };
}

function addMessages(
  statePath: string,
  notificationsKey: string,
  level: Level,
  messages: Notification[],
): ActionDispatcher {
  validateArray(messages, validateObject, {message: validateMessage});
  return (dispatch, getState) => {
    const current = get(getState(), `${statePath}.${notificationsKey}.${level}`, []) as Notification[];
    const byField = new Map<string, Notification>();
    const byMessage = new Map<string, Notification>();
    current.forEach((cur) => {
      if (cur.field) {
        byField.set(cur.field, cur);
      } else {
        byMessage.set(JSON.stringify(cur.message), cur);
      }
    });
    for (const message of messages) {
      if (message.field) {
        byField.set(message.field, message);
      } else {
        byMessage.set(JSON.stringify(message.message), message);
      }
    }
    return dispatch(setMessages(statePath, notificationsKey, level, [...byMessage.values(), ...byField.values()]));
  };
}

function removeMessage(
  statePath: string,
  notificationsKey: string,
  level: Level,
  message: Notification,
): ActionDispatcher<Promise<void> | null> {
  validateObject(message, {message: validateMessage});
  return (dispatch: Dispatch<NotificationRemoveAction>, getState) => {
    const current = get(getState(), `${statePath}.${notificationsKey}.${level}`, []);
    if (!current.find(ObjectContains.bind(null, message))) {
      return null;
    }
    return dispatch({
      level,
      message,
      notificationsKey,
      statePath,
      type: NOTIFICATION_REMOVE,
    });
  };
}

function removeMessageForField(
  statePath: string,
  notificationsKey: string,
  level: Level,
  condition: NotificationRemoveTypes,
): ActionDispatcher<Promise<void> | null> {
  return (dispatch: Dispatch<NotificationFieldRemoveAction>, getState) => {
    //Cast to array:
    const conditions: ReadonlyArray<Notification | FieldCondition> = Array.isArray(condition) ? condition : [condition];
    //Pick out the `field` property from potential Notificaion objects:
    const plainConditions = boolFilter(
      conditions.map((condition) =>
        typeof condition === 'string' || condition instanceof RegExp ? condition : condition.field,
      ),
    );

    const notifications: Notification[] = get(getState(), `${statePath}.${notificationsKey}.${level}`, []);
    const toRemove: string[] = [];
    for (const notification of notifications) {
      if (notificationFieldMatches(notification, plainConditions)) {
        toRemove.push(notification.field);
      }
    }

    if (toRemove.length === 0) {
      return null;
    }

    return dispatch({
      level,
      notificationsKey,
      statePath,
      fieldNames: toRemove,
      type: NOTIFICATION_FIELD_REMOVE,
    });
  };
}

/**
 * @param {string} statePath
 * @param {string} notificationsKey
 * @param {string} field
 */
export function removeMessagesByField(
  statePath: string,
  notificationsKey: string,
  field: string,
): ActionDispatcher<Promise<void> | null> {
  validateString(statePath);
  validateString(notificationsKey);
  validateString(field);
  return (dispatch: Dispatch<NotificationSetAllAction>, getState) => {
    let removeNotifications = false;
    const newNotifications: {
      [key in Level]?: Notification[];
    } = {};
    const notifications = get(getState(), `${statePath}.${notificationsKey}`, {});
    const levels: Level[] = Object.keys(notifications) as Level[];
    for (let i = 0; i < levels.length; ++i) {
      newNotifications[levels[i]] = notifications[levels[i]].filter(
        (notification: Notification) => notification.field !== field,
      );
      removeNotifications = removeNotifications || newNotifications[levels[i]]!.length !== notifications[levels[i]];
    }
    if (!removeNotifications) {
      return null;
    }
    return dispatch({
      notificationsKey,
      statePath,
      notifications: newNotifications,
      type: NOTIFICATIONS_SET_ALL,
    });
  };
}

/**
 * @param {string} statePath
 * @param {string} notificationsKey
 */
export function removeAllMessages(statePath: string, notificationsKey: string): ActionDispatcher<Promise<void> | null> {
  validateString(statePath);
  validateString(notificationsKey);
  return (dispatch: Dispatch<NotificationSetAllAction>, getState) => {
    const notifications = get(getState(), `${statePath}.${notificationsKey}`, {});
    if (Object.values(notifications).length === 0) {
      return null;
    }
    return dispatch({
      notificationsKey,
      statePath,
      notifications: {},
      type: NOTIFICATIONS_SET_ALL,
    });
  };
}

/**
 * @param {Map} reducerMap
 * @param {String} action
 * @param {function} reducer
 */
function appendReducer<NotiKey extends string, T extends NotificationState<NotiKey>, Action>(
  reducerMap: Map<string, Reducer<T, Action>>,
  action: string,
  reducer: (state: T, action: Action) => T,
): void {
  if (!reducerMap.has(action)) {
    const reducers: Array<Reducer<T, Action>> = [];
    const combinedReducer: Reducer<T, Action> =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state: T, action: Action, ...rest: any[]) =>
        reducers.reduce((newState, red) => red(newState, action, ...rest), state);
    combinedReducer.reducers = reducers;
    reducerMap.set(action, combinedReducer);
  }
  reducerMap.get(action)!.reducers!.push(reducer);
}

function mapTranslationTokensToResponse(
  actionType: string,
  response: NotificationResponse[],
  translationTokens: LocalTranslationTokens,
): Notification[] {
  const translationTokensForAction = translationTokens[actionType] || {};
  return response.map((messageCfg) => ({
    ...messageCfg,
    message: translationTokensForAction[messageCfg.message] || messageCfg.message,
  }));
}

type ActionTypes =
  | typeof NOTIFICATIONS_SET
  | typeof NOTIFICATIONS_SET_ALL
  | typeof NOTIFICATION_ADD
  | typeof NOTIFICATION_REMOVE
  | typeof NOTIFICATION_FIELD_REMOVE;

const defaultNotificationsKey = 'notifications' as const;

export function Notifications<T extends NotificationState<'notifications'>>(
  statePath: string,
  reducerMap: Map<string, Reducer<T, Action<ActionTypes>>>,
  hasErrorsKey?: string,
): NotificationsResult<'notifications'>;
export function Notifications<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  reducerMap: Map<string, Reducer<T, Action<ActionTypes>>>,
  hasErrorsKey: string | undefined,
  notificationsKey: NotiKey,
): NotificationsResult<NotiKey>;
export default function Notifications<NotiKey extends string, T extends NotificationState<NotiKey>>(
  statePath: string,
  reducerMap: Map<string, Reducer<T, Action<ActionTypes>>>,
  hasErrorsKey = 'hasErrors',
  notificationsKey: NotiKey = defaultNotificationsKey as NotiKey,
): NotificationsResult<NotiKey> {
  appendReducer<NotiKey, T, NotificationSetAction>(
    reducerMap,
    NOTIFICATIONS_SET,
    (state: T, action: NotificationSetAction) => {
      return reduceSet(statePath, hasErrorsKey, notificationsKey, state, action);
    },
  );
  appendReducer<NotiKey, T, NotificationSetAllAction>(
    reducerMap,
    NOTIFICATIONS_SET_ALL,
    (state: T, action: NotificationSetAllAction) => {
      return reduceSetAll(statePath, hasErrorsKey, notificationsKey, state, action);
    },
  );
  appendReducer<NotiKey, T, NotificationAddAction>(
    reducerMap,
    NOTIFICATION_ADD,
    (state: T, action: NotificationAddAction) => {
      return reduceAdd(statePath, hasErrorsKey, notificationsKey, state, action);
    },
  );
  appendReducer<NotiKey, T, NotificationRemoveAction>(
    reducerMap,
    NOTIFICATION_REMOVE,
    (state: T, action: NotificationRemoveAction) => {
      return reduceRemove(statePath, hasErrorsKey, notificationsKey, state, action);
    },
  );
  appendReducer<NotiKey, T, NotificationFieldRemoveAction>(
    reducerMap,
    NOTIFICATION_FIELD_REMOVE,
    (state: T, action: NotificationFieldRemoveAction) => {
      return reduceRemoveField(statePath, hasErrorsKey, notificationsKey, state, action);
    },
  );
  let localTranslationTokens: LocalTranslationTokens = {};

  return {
    actions: {
      setErrors: setMessages.bind(null, statePath, notificationsKey, 'error'),
      setInfos: setMessages.bind(null, statePath, notificationsKey, 'info'),
      setInputErrors: setMessages.bind(null, statePath, notificationsKey, 'inputError'),
      setSevereWarnings: setMessages.bind(null, statePath, notificationsKey, 'severeWarning'),
      setWarnings: setMessages.bind(null, statePath, notificationsKey, 'warning'),

      // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
      addError: addMessage.bind(null, statePath, notificationsKey, 'error'),
      addInfo: addMessage.bind(null, statePath, notificationsKey, 'info'),
      addInputError: addMessage.bind(null, statePath, notificationsKey, 'inputError'),
      addSevereWarning: addMessage.bind(null, statePath, notificationsKey, 'severeWarning'),
      addWarning: addMessage.bind(null, statePath, notificationsKey, 'warning'),

      // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
      addErrors: addMessages.bind(null, statePath, notificationsKey, 'error'),

      removeError: removeMessage.bind(null, statePath, notificationsKey, 'error'),
      removeInfo: removeMessage.bind(null, statePath, notificationsKey, 'info'),
      removeInputError: removeMessage.bind(null, statePath, notificationsKey, 'inputError'),
      removeSevereWarning: removeMessage.bind(null, statePath, notificationsKey, 'severeWarning'),
      removeWarning: removeMessage.bind(null, statePath, notificationsKey, 'warning'),

      // eslint-disable-next-line sort-keys-shorthand/sort-keys-shorthand
      removeErrorForField: removeMessageForField.bind(null, statePath, notificationsKey, 'error'),
      removeInfoForField: removeMessageForField.bind(null, statePath, notificationsKey, 'info'),
      removeInputErrorForField: removeMessageForField.bind(null, statePath, notificationsKey, 'inputError'),
      removeSevereWarningForField: removeMessageForField.bind(null, statePath, notificationsKey, 'severeWarning'),
      removeWarningForField: removeMessageForField.bind(null, statePath, notificationsKey, 'warning'),
    },
    reduceClearAll<T extends NotificationState<NotiKey>>(state: T): T {
      return clearAll(state, hasErrorsKey, notificationsKey);
    },
    reduceClearErrors<T extends NotificationState<NotiKey>>(state: T): T {
      return clearErrors(state, hasErrorsKey, notificationsKey);
    },
    reduceResponseFailure<T extends NotificationState<NotiKey>>(
      state: T,
      action: ServerResponseAction<NotificationResponse[]>,
    ): T {
      if (Array.isArray(action.response) && action.response.every((cur) => cur.hasOwnProperty('message'))) {
        return setNotifications(
          state,
          'error',
          mapTranslationTokensToResponse(action.type as string, action.response, localTranslationTokens),
          hasErrorsKey,
          notificationsKey,
        );
      }
      return state;
    },
    setTranslationTokens(translationTokens: LocalTranslationTokens): void {
      localTranslationTokens = translationTokens;
    },
  };
}
