import {ReferenceNumberUrl} from '@octaved/flow/src/Node/ReferenceNumberUrl';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWAutosaveInput} from '@octaved/ui';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from './Type';

export type NodeReferenceNumberProps =
  | {
      nodeId: Uuid;
      placeholder?: string;
      readonly: true;
      referenceNumber: string;
      setReferenceNumber?: undefined;
    }
  | {
      nodeId: Uuid;
      placeholder?: string;
      readonly?: boolean;
      referenceNumber: string;
      setReferenceNumber: (referenceNumber: string) => void;
    };

export default function NodeReferenceNumber({
  frame: Frame,
  nodeId,
  placeholder = 'nodeDetails:field.referenceNumber.placeholder',
  readonly,
  referenceNumber,
  setReferenceNumber,
}: NodeReferenceNumberProps & FramedSectionOrTileProps): ReactElement {
  const {t} = useTranslation();
  return (
    <Frame
      labelToken={'nodeDetails:field.referenceNumber.label'}
      labelAddition={<ReferenceNumberUrl nodeId={nodeId} />}
    >
      {!readonly && (
        <TWAutosaveInput
          value={referenceNumber}
          onSave={setReferenceNumber}
          required={false}
          placeholder={t(placeholder)}
        />
      )}
      {readonly && <div>{referenceNumber}</div>}
    </Frame>
  );
}
