import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {boolFilter} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {PriceSurcharge, PriceSurcharges, PriceSurchargeTimeControl} from '../../EntityInterfaces/PriceSurcharges';
import {hourlyBillableTypesSet} from '../../WorkPackage/BillingType';
import {FlowState} from '../State';
import {priceSurchargesOverridesSelector} from './CustomerSelectors';
import {getNodeAncestrySelector} from './NodeTreeSelectors';
import {getWorkPackageSelector} from './PidSelectors';
import {TimeStrM} from '@octaved/typescript/src/TimeStr';

export const priceSurchargesEntitiesSelector = (state: FlowState): PriceSurcharges => state.entities.priceSurcharge;

export const getPriceSurchargeNameSelector = createSelector(
  priceSurchargesEntitiesSelector,
  (priceSurcharges) => (id: Uuid) => priceSurcharges[id]?.name || '',
);

export const hasPriceSurchargesSelector = createSelector(
  priceSurchargesEntitiesSelector,
  (priceSurcharges) => Object.values(priceSurcharges).length > 0,
);

export const priceSurchargeListSelector = createSelector(priceSurchargesEntitiesSelector, (priceSurcharges) =>
  memoize(() => boolFilter(Object.values(priceSurcharges)).sort((a, b) => a.name.localeCompare(b.name))),
);

export const allCustomerPriceSurchargeListSelector = createSelector(
  priceSurchargeListSelector,
  priceSurchargesOverridesSelector,
  (getPriceSurchargeList, getOverrides) =>
    memoize((customerId: Uuid): PriceSurcharge[] => {
      const overrides = getOverrides(customerId);
      if (!overrides) {
        //if there no overrides the original list can be returned
        return getPriceSurchargeList();
      }
      const result: PriceSurcharge[] = [];
      for (const priceSurcharge of getPriceSurchargeList()) {
        const currentOverride = overrides[priceSurcharge.id];
        if (!currentOverride || !currentOverride.surchargeDisabled) {
          result.push({
            ...priceSurcharge,
            surcharge:
              currentOverride?.overrideIsActive && currentOverride.surcharge
                ? currentOverride.surcharge
                : priceSurcharge.surcharge,
          });
        }
        result.sort((a, b) => a.name.localeCompare(b.name));
      }
      return result;
    }),
);

export const getSelectablePriceSurchargeSelector = createSelector(
  priceSurchargeListSelector,
  priceSurchargesOverridesSelector,
  (getPriceSurchargeList, getOverrides) =>
    memoize(
      (flowCustomer: Uuid, selectedPriceSurcharge: Uuid | null) => {
        const result: PriceSurcharge[] = [];
        const overrides = getOverrides(flowCustomer) || {};
        for (const priceSurcharge of getPriceSurchargeList()) {
          const currentOverride = overrides[priceSurcharge.id];
          if (
            (priceSurcharge.isActive && //hide surcharge if it is locked
              (!currentOverride || !currentOverride.surchargeDisabled)) ||
            priceSurcharge.id === selectedPriceSurcharge //the current surcharge is always visible
          ) {
            result.push({
              ...priceSurcharge,
              surcharge:
                currentOverride?.overrideIsActive && currentOverride.surcharge
                  ? currentOverride.surcharge
                  : priceSurcharge.surcharge,
            });
          }
        }
        result.sort((a, b) => a.name.localeCompare(b.name));
        return result;
      },
      (flowCustomer: Uuid, selectedPriceSurcharge: Uuid | null) => `${flowCustomer}-${selectedPriceSurcharge}`,
    ),
);

export const canUsePriceSurchargesSelector = createSelector(
  getNodeAncestrySelector,
  getSelectablePriceSurchargeSelector,
  (getNodeAncestry, customerPriceSurchargeList) =>
    memoize((nodeId: Uuid | undefined | null) => {
      const {workPackage} = getNodeAncestry(nodeId, true);
      if (workPackage && workPackage.billingType) {
        return (
          (!!workPackage.priceCategory || workPackage.usePriceCategoryPerTimeTracking) &&
          hourlyBillableTypesSet.has(workPackage.billingType) &&
          customerPriceSurchargeList(workPackage.flowCustomer, null).length > 0
        );
      }
      return false;
    }),
);

function getFieldNameByWeekday(weekday: number): keyof PriceSurchargeTimeControl['days'] {
  const list: Array<keyof PriceSurchargeTimeControl['days']> = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  return list[weekday];
}

export const fitsInPriceSurcharge = (
  priceSurcharge: PriceSurcharge,
  startTime: string,
  endTime: string,
  day: keyof PriceSurchargeTimeControl['days'],
): boolean => {
  const {times, days} = priceSurcharge.timeControl;
  const surchargePassesMidnight = times.endTime! < times.startTime!;
  let fitsTime;
  if (surchargePassesMidnight) {
    fitsTime = endTime > times.startTime! || startTime < times.endTime!;
  } else {
    fitsTime =
      (endTime > times.startTime! && endTime <= times.endTime!) ||
      (startTime < times.endTime! && startTime >= times.startTime!) ||
      (startTime <= times.startTime! && endTime >= times.endTime!);
  }
  const fitsDay = days[day];
  return fitsTime && fitsDay;
};

export const getAllMatchingPriceSurchargesForTimeSpanSelector = createSelector(
  getNodeAncestrySelector,
  getSelectablePriceSurchargeSelector,
  canUsePriceSurchargesSelector,
  (getNodeAncestry, customerPriceSurchargeList, canUsePriceSurcharges) =>
    memoize(
      (
        referenceNodeId: Uuid | undefined | null,
        day: DateStr,
        startTime: TimeStrM,
        endTime: TimeStrM,
      ): PriceSurcharge[] => {
        const {workPackage} = getNodeAncestry(referenceNodeId, true);

        if (!(workPackage && canUsePriceSurcharges(workPackage.id))) {
          return [];
        }

        const priceSurcharges = customerPriceSurchargeList(workPackage.flowCustomer, null);
        const dayFieldName = getFieldNameByWeekday(fromIsoFormat(day).day());
        return priceSurcharges.filter((priceSurcharge) => {
          if (priceSurcharge.timeControl.times.fullday) {
            return priceSurcharge.timeControl.days[dayFieldName];
          }

          return fitsInPriceSurcharge(priceSurcharge, startTime, endTime == '00:00' ? '24:00' : endTime, dayFieldName);
        });
      },
      (...args: unknown[]) => args.join('-'),
    ),
);

export const getAllMatchingPriceSurchargesForStartTimeSelector = createSelector(
  getNodeAncestrySelector,
  getSelectablePriceSurchargeSelector,
  canUsePriceSurchargesSelector,
  (getNodeAncestry, customerPriceSurchargeList, canUsePriceSurcharges) =>
    memoize(
      (referenceNodeId: Uuid | undefined | null, day: DateStr, startTime: string): PriceSurcharge[] => {
        const {workPackage} = getNodeAncestry(referenceNodeId, true);

        if (!(workPackage && canUsePriceSurcharges(workPackage.id))) {
          return [];
        }
        const priceSurcharges = customerPriceSurchargeList(workPackage.flowCustomer, null);
        const dayFieldName = getFieldNameByWeekday(fromIsoFormat(day).day());
        return priceSurcharges.filter((priceSurcharge) => {
          const {
            timeControl: {times, days},
          } = priceSurcharge;
          if (times.fullday) {
            return days[dayFieldName];
          }
          const isMidnightSurcharge = !times.fullday && times.startTime! > times.endTime!;
          if (isMidnightSurcharge) {
            return days[dayFieldName] && (times.startTime! <= startTime || times.endTime! > startTime);
          } else {
            return (
              days[dayFieldName] && (times.fullday || (times.startTime! <= startTime && times.endTime! > startTime))
            );
          }
        });
      },
      (...args: unknown[]) => args.join('-'),
    ),
);

export const getFirstMatchingPriceSurchargeForStartTimeSelector = createSelector(
  getAllMatchingPriceSurchargesForStartTimeSelector,
  (getAllMatchingPriceSurchargesForStartTime) =>
    memoize(
      (referenceNodeId: Uuid | undefined | null, day: DateStr, startTime: string): PriceSurcharge | null => {
        return getAllMatchingPriceSurchargesForStartTime(referenceNodeId, day, startTime)[0] || null;
      },
      (...args: unknown[]) => args.join('-'),
    ),
);

export const getMatchingPriceSurchageForWarningSelector = createSelector(
  getWorkPackageSelector,
  getSelectablePriceSurchargeSelector,
  canUsePriceSurchargesSelector,
  (getWorkPackage, customerPriceSurchargeList, canUsePriceSurcharges) =>
    memoize(
      (
        workPackageId: Uuid | undefined | null,
        selectedPriceSurchargeId: Uuid | undefined | null,
        day: DateStr,
        startTime: string,
        endTime: string,
      ): PriceSurcharge | null => {
        const canUse = canUsePriceSurcharges(workPackageId);
        const workpackage = getWorkPackage(workPackageId);
        const matchingPriceSurcharges = [];

        if (canUse && workpackage) {
          const priceSurcharges = customerPriceSurchargeList(workpackage.flowCustomer, null);
          const dayFieldName = getFieldNameByWeekday(fromIsoFormat(day).day());
          for (const priceSurcharge of priceSurcharges) {
            const {
              timeControl: {times, days},
              id,
            } = priceSurcharge;
            if (days[dayFieldName]) {
              if (
                (times.fullday ||
                  (times.startTime! <= startTime &&
                    times.endTime! >= startTime &&
                    times.startTime! <= endTime &&
                    times.endTime! >= endTime)) &&
                selectedPriceSurchargeId === id
              ) {
                //direct match
                return null;
              }
              if (
                times.fullday ||
                (times.startTime! >= startTime && times.startTime! <= endTime) ||
                (times.endTime! >= startTime && times.endTime! <= endTime)
              ) {
                matchingPriceSurcharges.push(priceSurcharge);
              }
            } else if (selectedPriceSurchargeId === id) {
              return null;
            }
          }
        }
        return matchingPriceSurcharges.length === 0 ? null : matchingPriceSurcharges[0];
      },
      (...args: unknown[]) => args.join('-'),
    ),
);
