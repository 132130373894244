import {getNumberFormatter} from './Culture';
import InvalidFormatError from './InvalidFormatError';

function getDefaultPrecision(): number {
  const formatter = getNumberFormatter();
  return formatter.settings.number.precision!;
}

export function roundToFactor(value: number, factor = 0.5): number {
  const inverted = 1.0 / factor;
  return Math.round(value * inverted) / inverted;
}

/**
 * Formats a given number against the local settings of a user
 */
export function format(number: number, roundTo?: number): string;
export function format(number: null | undefined, roundTo?: number): null;
export function format(number: number | null | undefined, roundTo?: number): string | null;
export function format(number: number | null | undefined, roundTo = getDefaultPrecision()): string | null {
  if (number === null || typeof number === 'undefined') {
    return null;
  }
  const formatter = getNumberFormatter();
  if (!Number.isInteger(number) && typeof number !== 'number') {
    throw new InvalidFormatError('Non Number or Integer has been tried to format, please parse it first.');
  }

  return formatter.formatNumber(number, roundTo);
}

function isPrecise(num: number, precision: number): boolean {
  const factored = num * Math.pow(10, precision);
  return Math.floor(factored) === factored;
}

/**
 * Formats a number, only keeping a maximum of maxPrecision decimal places, but skip trailing zeros
 */
export function formatDecimal(num: number, maxPrecision?: number): string;
export function formatDecimal(num: number | null, maxPrecision?: number): string | null;
export function formatDecimal(num: number | null, maxPrecision = 2): string | null {
  if (num === null) {
    return null;
  }
  let precision = 0;
  while (maxPrecision > precision && !isPrecise(num, precision)) {
    precision++;
  }
  return format(num, precision);
}

/**
 * Formats a number based on these rules:
 * - 2 decimal paces for 0-9
 * - 1 decimal paces for 10-99
 * - 0 decimal paces for 100+
 */
export function formatDynamicPrecision(num: number): string;
export function formatDynamicPrecision(num: null): null;
export function formatDynamicPrecision(num: number | null): string | null {
  if (num === null) {
    return null;
  }
  const abs = Math.abs(num);
  const precision = abs >= 100 ? 0 : abs >= 10 ? 1 : 2;
  return format(num, precision);
}

/**
 * Parses a given string from the local format to a Number
 */
export function parse(number: string, roundTo = getDefaultPrecision()): number {
  const formatter = getNumberFormatter();

  if (validateLocaleFormat(number)) {
    return formatter.unformat(formatter.formatNumber(('' + number) as unknown as number, roundTo));
  }

  throw new Error(`Could not parse number: ${number}`);
}

export function parseGraceful(value: string, roundTo?: number): number {
  try {
    return parse(value, roundTo);
  } catch (_e) {
    return 0;
  }
}

/**
 * Unformats a number from locale setting
 */
export function unformat(number: string): number | null {
  const formatter = getNumberFormatter();
  if (validateLocaleFormat(number)) {
    return formatter.unformat(number);
  }
  return null;
}

/**
 * Validates the given number has the correct locale format
 */
export function validateLocaleFormat(number: string): boolean {
  const formatter = getNumberFormatter();

  const tSeparator = formatter.settings.number.thousand;
  const dSeparator = formatter.settings.number.decimal;

  const regex = `^([+-])?(((\\d+)|(\\d{1,3}))(\\${tSeparator}\\d{3})*)?(\\${dSeparator}\\d+)?$`;
  const regexObj = new RegExp(regex);

  return number.length > 0 && regexObj.test('' + number);
}
