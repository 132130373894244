import {DeepPartial} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {setUiState} from '../../Modules/Ui';
import {ProjectsUiState} from '../../Modules/UiPages/Projects';

export function useProjectUiStatePatch(): (projects: DeepPartial<ProjectsUiState>) => void {
  const dispatch = useDispatch();
  return useCallback(
    (projects) => {
      dispatch(setUiState({pages: {projects}}));
    },
    [dispatch],
  );
}
