import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {Uuid} from '@octaved/typescript/src/lib';
import {memoize} from 'lodash';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {customerByNodeSelector} from '../Selectors/CustomerSelectors';
import {getNodeAncestrySelector} from '../Selectors/NodeTreeSelectors';
import {FlowState} from '../State';

export type NodeBillingStatus = 'external' | 'internal' | 'internalCostTransfere' | 'freeOfCharge';

export const nodeBillingStatusSelector = createSelector(
  getNodeAncestrySelector,
  customerByNodeSelector,
  (getNodeAncestry, getCustomer) =>
    memoize((nodeId: Uuid | null | undefined): NodeBillingStatus => {
      const {workPackage} = getNodeAncestry(nodeId, true);
      const customer = getCustomer(nodeId);
      if (workPackage && workPackage.billingType === EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE) {
        return 'freeOfCharge';
      } else if (customer && customer.isInternal) {
        if (customer.requiresInternalCharge) {
          return 'internalCostTransfere';
        }
        return 'internal';
      }
      return 'external';
    }),
);

export function useNodeBillingStatus(nodeId: Uuid | null | undefined): NodeBillingStatus {
  return useSelector((state: FlowState) => nodeBillingStatusSelector(state)(nodeId));
}
