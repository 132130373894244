import {Uuid} from '@octaved/typescript/src/lib';
import {Tooltip} from '@octaved/ui';
import {formatDecimal} from '@octaved/users/src/Culture/NumberFormatter';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useSingleSubtreeStat} from '../../../../Modules/Statistics/SubtreeStats';
import ExtendedProgress from '../../../../semantic-ui/ExtendedProgress';

interface Props {
  nodeId: Uuid;
}

export default function TaskProgress({nodeId}: Props): ReactElement | null {
  const {t} = useTranslation();
  const sums = useSingleSubtreeStat('taskCountsAndTimeSums', nodeId);
  if (!sums || !sums.totalCount) {
    return null;
  }

  let value: number;
  let total: number;
  let tooltip = '';
  if (sums.totalCount === sums.withTimeCount) {
    //all tasks have `plannedTime`
    tooltip = t('workPackage:tile.taskProgress.completedTaskHoursTooltip', {
      completed: formatDecimal(sums.completeTimeSum),
      count: Math.round(sums.totalTimeSum),
      total: formatDecimal(sums.totalTimeSum),
    });
    value = sums.completeTimeSum;
    total = sums.totalTimeSum;
  } else {
    tooltip = t('workPackage:tile.taskProgress.completedTasksTooltip', {
      completed: sums.completeCount,
      count: Math.round(sums.totalCount),
      total: sums.totalCount,
    });
    value = sums.completeCount;
    total = sums.totalCount;
  }

  return (
    <Tooltip toolTipTranslation={''} content={tooltip}>
      <div className={'progress'}>
        <ExtendedProgress value={value} total={total} color={'green'} size={'tiny'} />
        {/*language=scss*/}
        <style jsx>{`
          .progress {
            flex-shrink: 0;
            width: 40px;
          }
        `}</style>
      </div>
    </Tooltip>
  );
}
