import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {HelpLinkProps, HelpPopup} from '@octaved/ui';
import {ReactElement} from 'react';

const helpText: Record<EnumFlowNodeRoleType, string> = {
  [EnumFlowNodeRoleType.VALUE_PERMISSION]: 'roles:tooltipHelp.permissionRole',
  [EnumFlowNodeRoleType.VALUE_PROJECT]: 'roles:tooltipHelp.projectRole',
};

const link: Record<EnumFlowNodeRoleType, HelpLinkProps[]> = {
  [EnumFlowNodeRoleType.VALUE_PERMISSION]: [
    {
      routeIdent: 'tutorial_permission_roles',
      token: 'roles:tooltipHelp.permissionRoleLink',
    },
  ],
  [EnumFlowNodeRoleType.VALUE_PROJECT]: [
    {
      routeIdent: 'tutorial_project_roles',
      token: 'roles:tooltipHelp.projectRoleLink',
    },
  ],
};

interface SimpleRoleAssignmentHelpPopupProps {
  roleType: EnumFlowNodeRoleType;
}

export default function SimpleRoleAssignmentHelpPopup({roleType}: SimpleRoleAssignmentHelpPopupProps): ReactElement {
  return <HelpPopup text={helpText[roleType]} iconButtonSize={'xs'} helpLinks={link[roleType]} />;
}
