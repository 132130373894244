import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {cn} from './Hooks';

interface TWListEntry {
  className?: string;
  token: string;
}

interface TWListProps {
  className?: string;
  entries: TWListEntry[];
  title: string;
}

const TWList: FC<TWListProps> = ({className, entries, title}) => {
  const {t} = useTranslation();
  return (
    <div className={cn('flex flex-col gap-y-2', className)}>
      <div className={'text-lg font-semibold text-sky-700'}>{t(title)}</div>
      <ul className={'list-disc pl-4 text-sm text-slate-700'}>
        {entries.map((entry, index) => (
          <li key={index} className={cn('py-0.5 leading-4', entry.className)}>
            <Trans i18nKey={entry.token} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export {TWList};
export type {TWListEntry, TWListProps};
