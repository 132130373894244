import {error} from '@octaved/env/src/Logger';
import URI from 'urijs';
import Template, {URITemplate} from 'urijs/src/URITemplate';
import {UrlParams} from './NetworkMiddlewareTypes';

/**
 * Injects parameters into a URL.
 *
 * Example:
 * {{{
 *  generateUrl('/foo/bar/{param1}', {param1: 'a', b: 2}) -> '/foo/bar/a?b=2'
 *  // or
 *  generateUrl('/foo/bar/{param1}', {param1: null, b: 2}) -> '/foo/bar?b=2'
 * }}}
 */
export function generateUrl(url: string, urlParameters: UrlParams = {}): string {
  const removeEmptyFolder = !url.endsWith('/');
  const template = Template(url);
  //create a copy here as we remove the used parameter from the Object
  const urlParams: Record<string, unknown> = {...urlParameters};

  const missingParameters: string[] = [];
  const urlString = template.expand((key: string) => {
    const value = urlParams[key];
    delete urlParams[key];

    if (value === undefined) {
      if (key === 'apiVersionString') {
        return 'latest';
      }
      missingParameters.push(key);
      return value;
    }

    if (typeof value !== 'string' && typeof value !== 'number') {
      error('Invalid url path parameter, expected string or number', {key, value});
    }

    return value as string;
  });

  //Append "[]" to the keys of arrays - otherwise php won't understand this as array:
  Object.keys(urlParams).forEach((key) => {
    if (Array.isArray(urlParams[key])) {
      urlParams[`${key}[]`] = urlParams[key];
      delete urlParams[key];
    }
  });

  const uri = new URI(urlString.toString()).addSearch(urlParams as URI.QueryDataMap);
  // removes empty folders at the end of the url
  if (removeEmptyFolder) {
    removeEmptyParams(uri, missingParameters, template);
  }

  if (missingParameters.length) {
    throw new Error(`Missing url parameters, expected parameters ${missingParameters.join(',')} to be set for ${url}`);
  }

  return uri.toString();
}

export type UrlGenerator = typeof generateUrl;

/**
 * Removes empty parameters/segments that are might optional in that case
 *
 * @param {Object} uri
 * @param {Array} missingParameters
 * @param {Object} template
 */
function removeEmptyParams(uri: URI, missingParameters: string[], template: URITemplate): void {
  // collect all parameters for this uri
  const allparameters: string[] = [];
  (template.parts as ReadonlyArray<URITemplate.URITemplateExpression>).forEach((part) => {
    if (part.variables && part.variables[0] && part.variables[0].name) {
      allparameters.push(part.variables[0].name);
    }
  });

  if (uri.segment(-1) === '') {
    // remove all empty segments and remove all parameters marked as missing that are empty
    uri.segment().forEach((segment) => {
      if (!segment.length) {
        const emptyParameter = allparameters.pop() as string;
        const index = missingParameters.indexOf(emptyParameter);

        if (index !== -1) {
          missingParameters.splice(index, 1);
        }
      }
    });

    // remove all empty segments except the first segment of the empty parameters:
    // ['a', 'b', '', '', '', ''] -> ['a', 'b', '']
    uri.segment(uri.segment());

    // remove the missing empty segment from above
    // ['a', 'b', ''] -> ['a', 'b']
    const segments = uri.segment();
    segments.pop();
    uri.segment(segments);
  }
}
