import {getOrgWorkMinutesAtDateSelector} from '@octaved/flow/src/Modules/Selectors/WorkTimeSelectors';
import {cn, tableGrid, tableGridCellVariant, TWButton} from '@octaved/ui';
import {fromIsoDateTimeFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {PlusCircle} from 'lucide-react';
import {ReactElement, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {workdays} from '../../../Calculations/WorkdayCalculations';
import {PlanningRealizationNode} from '../../../EntityInterfaces/PlanningRealizationNode';
import {useNodePlanningStatus} from '../../../PlanningStatus/PlanningStatusQueries';
import {canAddGapBarSelector} from '../../../Selectors/CanAddGapBarSelector';
import {getPlanningDatesForNodeSelector} from '../../../Selectors/PlanningDateSelectors';
import EmptyTimePeriodRow from './EmptyTimePeriodRow';
import TimePeriodRow from './TimerPeriodRow';

interface TimePeriodProps {
  node: PlanningRealizationNode;
  forceRelativeToParent?: boolean;
  readonly: boolean;
}

export default function TimePeriod({node, readonly, forceRelativeToParent = false}: TimePeriodProps): ReactElement {
  const {t} = useTranslation();
  const planningDates = useSelector(getPlanningDatesForNodeSelector)(node.id);
  const [addNew, setAddNew] = useState(node.planningDates.length === 0);
  const canAddGapBar = useSelector(canAddGapBarSelector);
  const canAddNew = !readonly && canAddGapBar(node.id);
  const getOrgWorkMinutesAtDate = useSelector(getOrgWorkMinutesAtDateSelector);
  const combinedDuration = useMemo(() => {
    let result = 0;
    for (const {plannedEnd, plannedStart} of planningDates) {
      result += workdays(
        fromIsoDateTimeFormat(plannedStart),
        fromIsoDateTimeFormat(plannedEnd),
        getOrgWorkMinutesAtDate,
      );
    }
    return result;
  }, [getOrgWorkMinutesAtDate, planningDates]);

  const hasPlannedGapBars = planningDates.length > 1;
  const status = useNodePlanningStatus(node.id);
  return (
    <div>
      <div
        className={cn(
          tableGrid({
            className: hasPlannedGapBars
              ? 'grid-cols-[20px_1fr_minmax(120px,max-content)_25px]'
              : 'grid-cols-[0_1fr_minmax(120px,max-content)_25px]',
            isBelowLabel: true,
          }),
        )}
      >
        <div className={cn(tableGridCellVariant({isHeader: true, position: 'left'}))}>&nbsp;</div>
        <div className={cn(tableGridCellVariant({isHeader: true, position: hasPlannedGapBars ? 'center' : 'left'}))}>
          {t('pages:projects.inspector.manage.planning.timePlanning')}
        </div>
        <div className={cn(tableGridCellVariant({isHeader: true}))}>
          {t('pages:projects.inspector.manage.planning.duration')}
        </div>
        <div className={cn(tableGridCellVariant({isHeader: true, position: 'right'}))}>&nbsp;</div>
        {planningDates.map((planningDate, index) => (
          <TimePeriodRow
            node={node}
            planningDate={planningDate}
            readonly={readonly}
            key={planningDate.id}
            planningDates={planningDates}
            index={index}
            isFirst={index === 0}
            isLast={index === planningDates.length - 1}
            status={status}
          />
        ))}
        {addNew && canAddNew && (
          <EmptyTimePeriodRow
            node={node}
            reset={() => setAddNew(false)}
            planningDates={planningDates}
            forceRelativeToParent={forceRelativeToParent}
          />
        )}
      </div>
      {((!addNew && canAddNew) || planningDates.length > 1) && (
        <div
          className={cn(
            tableGridCellVariant({isFooter: true, position: 'left', className: 'flex items-center gap-x-2'}),
            'mt-1',
          )}
        >
          {!addNew && canAddNew && (
            <TWButton withIcon variant={'outline'} onClick={() => setAddNew(true)} size={'md'} colorScheme={'primary'}>
              <PlusCircle className={'size-4 text-current'} />
              <Trans i18nKey={'pages:projects.inspector.manage.planning.newBar'} />
            </TWButton>
          )}
          {planningDates.length > 1 && (
            <div>
              {t('pages:projects.inspector.manage.planning.combinedDuration')}:{' '}
              {t('general:date.days', {count: combinedDuration, countFormatted: combinedDuration})}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
