import {FLOW_TODAY_CHANGED} from '@octaved/flow/src/Modules/ActionTypes';
import {
  TimeRecordCreatedEvent,
  TimeRecordPatchedEvent,
  TimeRecordRemovedEvent,
  WorkTimeManagedEvent,
} from '@octaved/flow/src/Modules/Events';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  filterIdsToReload,
  INVALIDATED,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {getDaysInUnit} from '@octaved/users/src/Culture/DateFormatFunctions';
import {reduceRemoveByPrefix} from '@octaved/utilities/src/Search/SearchReducers';
import {useSelector} from 'react-redux';
import * as routes from '../../config/routes';
import {WorkingTimesChangedEvent} from '../Event/Events';
import {WorkingTimeEntriesForUserOnDate, WorkingTimeEntriesForUsersOnDates} from '../Interfaces/WorkingTimeEntries';
import {
  getOrgUserDateStoreKey,
  getWorkingTimeEntriesOnDatesSelector,
  workingTimeEntriesForUsersOnDateStateSelector,
} from '../Selectors/WorkingTimeEntriesSelectors';
import {
  FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_FAILURE,
  FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_REQUEST,
  FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS,
} from './ActionTypes';

interface LoadAction {
  keys: string[];
  orgUserId: Uuid;
  response: WorkingTimeEntriesForUserOnDate[];
  type: typeof FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS;
}

const reducers = createReducerCollection<WorkingTimeEntriesForUsersOnDates>({});
export const workingTimeEntryForUserOnDateReducer = reducers.reducer;
reducers.add<LoadAction>(FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS, (state, {response}) => {
  const newState = {...state};
  response.forEach((entriesForUserOnDate) => {
    newState[getOrgUserDateStoreKey(entriesForUserOnDate.userId, entriesForUserOnDate.date)] = entriesForUserOnDate;
  });
  return newState;
});

const stateReducers = createReducerCollection<EntityStates>({});
export const workingTimeEntryForUserOnDateStateReducer = stateReducers.reducer;
stateReducers.add(FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_REQUEST, createTimestampReducer('keys', LOADING));
stateReducers.add(FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS, createTimestampReducer('keys', LOADED));
stateReducers.add<TimeRecordCreatedEvent | TimeRecordPatchedEvent | TimeRecordRemovedEvent>(
  ['flow.TimeRecordCreatedEvent', 'flow.TimeRecordPatchedEvent', 'flow.TimeRecordRemovedEvent'],
  (state, action) => {
    const keys = action.affectedDates.map((date) => getOrgUserDateStoreKey(action.orgUserId, date));
    if (action.type === 'flow.TimeRecordPatchedEvent' && action.previousUserId) {
      keys.push(...action.affectedDates.map((date) => getOrgUserDateStoreKey(action.previousUserId!, date)));
    }
    return createTimestampReducer('keys', INVALIDATED)(state, {keys});
  },
);
stateReducers.add<WorkingTimesChangedEvent>(['flow.WorkingTimesChangedEvent'], (state, action) => {
  const keys = new Set<string>();
  Object.entries(action.datesByOrgUsers).forEach(([orgUserId, dates]) => {
    const addDate = (date: DateStr): Set<string> => keys.add(getOrgUserDateStoreKey(orgUserId, date));
    dates.forEach((date) => {
      addDate(date);
      //Because the entries contain stats on the week and month, we must clear the entire week/month, too:
      getDaysInUnit(date, 'isoWeek').forEach(addDate);
      getDaysInUnit(date, 'month').forEach(addDate);
    });
  });
  return createTimestampReducer('keys', INVALIDATED)(state, {keys: [...keys]});
});

//Dynamic warnings depend on the work time definitions and are calculated server-side:
stateReducers.add<WorkTimeManagedEvent>(['flow.WorkTimeManagedEvent'], (state, action) => {
  let newState = state;
  action.affectedUnitIds.forEach((unitId) => {
    newState = reduceRemoveByPrefix(newState, unitId);
  });
  return newState;
});

//Clear when today changes - the server-side warnings depend on what is "today":
stateReducers.add(FLOW_TODAY_CHANGED, () => ({}));

function loadWorkingTimeEntriesForUserOnDate(
  orgUserId: Uuid,
  date: DateStr | ReadonlyArray<DateStr> | null | undefined,
): ActionDispatcher<void> {
  return (dispatch, getState) => {
    const dates: ReadonlyArray<DateStr> = Array.isArray(date) ? date : date ? [date] : [];
    const state = workingTimeEntriesForUsersOnDateStateSelector(getState());
    const datesToLoad = filterIdsToReload(state, dates, null, (date) => getOrgUserDateStoreKey(orgUserId, date));
    if (datesToLoad.length) {
      dispatch({
        orgUserId,
        [CALL_API]: {
          endpoint: routes.getWorkingTimeDays,
          method: 'post',
          options: {
            data: {userIds: [orgUserId], dates: datesToLoad, includeEntries: true, includeStats: true},
          },
          types: {
            failureType: FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_FAILURE,
            requestType: FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_REQUEST,
            successType: FLOW_LOAD_WORKING_TIME_ENTRIES_FOR_USER_ON_DATE_SUCCESS,
          },
        },
        keys: datesToLoad.map((date) => getOrgUserDateStoreKey(orgUserId, date)),
      });
    }
  };
}

export function useLoadWorkingTimeEntriesForUserOnDate(
  orgUserId: Uuid,
  date: DateStr | ReadonlyArray<DateStr> | null | undefined,
): void {
  useStoreEffect(
    (dispatch) => {
      dispatch(loadWorkingTimeEntriesForUserOnDate(orgUserId, date));
    },
    [date, orgUserId],
    workingTimeEntriesForUsersOnDateStateSelector,
  );
}

export function useWorkingTimeEntriesForUserOnDates(
  orgUserId: Uuid,
  dates: ReadonlyArray<DateStr>,
): ReadonlyArray<WorkingTimeEntriesForUserOnDate> {
  useLoadWorkingTimeEntriesForUserOnDate(orgUserId, dates);
  return useSelector((s: FlowState) => getWorkingTimeEntriesOnDatesSelector(s)(orgUserId, dates));
}
