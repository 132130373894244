import {EnumFlowNodeRoleType} from '@octaved/env/src/dbalEnumTypes';
import {getProjectControllingTrackedTimeQuotas} from '@octaved/flow-api';
import {useLoadedValue} from '@octaved/hooks/src/LoadedValue';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {
  createTimestampReducer,
  EntityStates,
  isLoaded,
  isOutdated,
  LOADED,
  LOADING,
} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {Uuid} from '@octaved/typescript/src/lib';
import {useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {inspectorContext} from '../../../Drawer/InspectorContext/InspectorContext';
import {
  StoreTrackedTimeQuotas,
  TrackedTimeQuotas,
} from '../../../EntityInterfaces/Projects/ProjectControlling/TrackedTime';
import {
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_FAILURE,
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_REQUEST,
  FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_SUCCESS,
} from '../../ActionTypes';
import {
  CustomerPatchedEvent,
  GroupPatchedEvent,
  NodeRoleAssignmentEvent,
  ProjectPatchedEvent,
  TaskPatchedEvent,
  WorkPackagePatchedEvent,
} from '../../Events';
import {responsibleProps} from '../../ResponsibleNode';
import {
  createTrackedTimeRequestFilterKey,
  defaultTrackedTimeRequestFilterOptions,
  trackedTimeQuotasSelector,
  trackedTimeQuotasStatesSelector,
  TrackedTimeRequestFilterOptions,
} from '../../Selectors/Projects/ProjectControlling/TrackedTimeSelectors';
import {workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector} from '../../Selectors/UiPages/ProjectsSelector';
import {FlowState} from '../../State';

const reducers = createReducerCollection<StoreTrackedTimeQuotas>({});
export const trackedTimeQuotasReducer = reducers.reducer;

reducers.add<LoadAction>(FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_SUCCESS, (state, {key, response}) => {
  return {...state, [key]: response};
});

const stateReducers = createReducerCollection<EntityStates>({});
export const trackedTimeQuotasStateReducer = stateReducers.reducer;

stateReducers.add<CustomerPatchedEvent>('flow.CustomerPatchedEvent', (state, action) => {
  return action.patchedProperties.includes('isInternal') || action.patchedProperties.includes('requiresInternalCharge')
    ? {}
    : state;
});

//For the project role quota:
stateReducers.add<NodeRoleAssignmentEvent>('flow.NodeRoleAssignmentEvent', (state, action) => {
  return action.roleType === EnumFlowNodeRoleType.VALUE_PROJECT ? {} : state;
});
stateReducers.add<ProjectPatchedEvent | GroupPatchedEvent | TaskPatchedEvent | WorkPackagePatchedEvent>(
  ['flow.GroupPatchedEvent', 'flow.ProjectPatchedEvent', 'flow.TaskPatchedEvent', 'flow.WorkPackagePatchedEvent'],
  (state, action) => {
    if (responsibleProps.some((key) => action.patchedKeys.includes(key))) {
      return {};
    }
    return state;
  },
);

stateReducers.add<WorkPackagePatchedEvent>('flow.WorkPackagePatchedEvent', (state, action) => {
  return action.patchedKeys.includes('billingType') || action.patchedKeys.includes('flowCustomer') ? {} : state;
});
stateReducers.add(FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_REQUEST, createTimestampReducer('key', LOADING));
stateReducers.add(FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_SUCCESS, createTimestampReducer('key', LOADED));
stateReducers.add(
  [
    'flow.NodesResponsibilitiesBulkPatchedEvent',
    'flow.ProjectCustomerChangedEvent',
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
  ],
  () => ({}),
);

interface LoadAction {
  key: string;
  response: TrackedTimeQuotas;
  type: string;
}

const loadingValue = {};
const emptyValue = {
  billable: [],
  childProjectFolders: [],
  customers: [],
  labels: [],
  projectRoles: [],
  projects: [],
  totalBillingSecondsForCustomer: 0,
  totalBillingSecondsForParent: 0,
  totalBillingSecondsForProject: 0,
  totalBillingSecondsForSelection: 0,
  users: [],
};

interface Result {
  hasLoadedOnce: boolean;
  isLoading: boolean;
  data: TrackedTimeQuotas;
}

export function useTrackedTimeQuotasForNode(
  nodeId: Uuid | null | undefined,
  workPackageIds: readonly Uuid[],
  options: TrackedTimeRequestFilterOptions = defaultTrackedTimeRequestFilterOptions,
): Result {
  const {from, labelIds, to, unitIds} = options;
  const filterKey = createTrackedTimeRequestFilterKey(options);
  const key = useMemo(() => [...workPackageIds].sort().join('-'), [workPackageIds]);
  const fullKey = `${filterKey}@${nodeId}@${key}`;
  const dispatch = useDispatch();
  const state = useSelector((s: FlowState) => trackedTimeQuotasStatesSelector(s)[fullKey]);
  useEffect(() => {
    if (nodeId && (!state || isOutdated(state))) {
      dispatch({
        [CALL_API]: {
          endpoint: getProjectControllingTrackedTimeQuotas,
          method: 'post',
          options: {data: {workPackageIds, from, to, labelIds, unitIds}, urlParams: {nodeId}},
          types: {
            failureType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_FAILURE,
            requestType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_REQUEST,
            successType: FLOW_LOAD_PROJECT_CONTROLLING_TRACKED_TIME_QUOTAS_SUCCESS,
          },
        },
        key: fullKey,
      });
    }
  }, [dispatch, fullKey, state, labelIds, unitIds, workPackageIds, from, to, nodeId]);
  const isLoading = !!nodeId && (!state || !isLoaded(state));
  const data =
    useSelector((s: FlowState) => trackedTimeQuotasSelector(s)[fullKey]) || (isLoading ? loadingValue : emptyValue);
  return {
    data,
    isLoading,
    hasLoadedOnce: useLoadedValue(isLoading, !isLoading, fullKey),
  };
}

export function useTrackedTimeQuotasForWorkPackage(
  workPackageId: Uuid | null | undefined,
  options: TrackedTimeRequestFilterOptions = defaultTrackedTimeRequestFilterOptions,
): Result {
  const wpIds = useMemo(() => (workPackageId ? [workPackageId] : []), [workPackageId]);
  return useTrackedTimeQuotasForNode(workPackageId, wpIds, options);
}

export function useTrackedTimeQuotas(
  options: TrackedTimeRequestFilterOptions = defaultTrackedTimeRequestFilterOptions,
): Result {
  const context = useContext(inspectorContext);
  const nodeId = context?.selectedId;
  const {workPackageIds} = useSelector((s: FlowState) =>
    workPackageIdsBeneathSelectedNodeInMainProjectTreeAndCacheKeySelector(s)(nodeId),
  );
  return useTrackedTimeQuotasForNode(nodeId, workPackageIds, options);
}
