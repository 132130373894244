import {setUiState} from '@octaved/flow/src/Modules/Ui';
import {initialLeftDrawerUiState, LeftDrawerUiState} from '@octaved/flow/src/Modules/Ui/LeftDrawer';
import {ThunkAct} from '@octaved/flow/src/Store/Thunk';
import {DeepPartial, Uuid} from '@octaved/typescript/src/lib';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {ZoomLevel} from '../Components/Calendar/CalendarContainer/CalendarContainerContext';

export interface ProjectPlanningUiState {
  ganttZoom: ZoomLevel;
  ganttColumnWidth: number;
  hoveringNodeId: Uuid | null;
  leftDrawer: LeftDrawerUiState;
  showHoveringNodeInfo: boolean;
}

export const initialProjectPlanningUiState: ProjectPlanningUiState = {
  ganttColumnWidth: 20,
  ganttZoom: ZoomLevel['100%'],
  hoveringNodeId: null,
  leftDrawer: initialLeftDrawerUiState,
  showHoveringNodeInfo: false,
};

export function usePatchProjectPlanningUiState(): (patch: DeepPartial<ProjectPlanningUiState>) => void {
  const dispatch = useDispatch();
  return useCallback((projectPlanning) => dispatch(setUiState({pages: {projectPlanning}})), [dispatch]);
}

export function patchProjectPlanningZoomLevel(ganttZoom: ZoomLevel): ThunkAct<void> {
  return setUiState({pages: {projectPlanning: {ganttZoom}}});
}
export function patchProjectPlanningColumnWidth(ganttColumnWidth: ZoomLevel): ThunkAct<void> {
  return setUiState({pages: {projectPlanning: {ganttColumnWidth}}});
}

export function setHoveringNodeId(hoveringNodeId: Uuid | null): ThunkAct<void> {
  return setUiState({pages: {projectPlanning: {hoveringNodeId}}});
}

export function setShowHoveringNodeInfo(showHoveringNodeInfo: boolean): ThunkAct<void> {
  return setUiState({pages: {projectPlanning: {showHoveringNodeInfo}}});
}
