import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {Group, Project} from '@octaved/flow/src/EntityInterfaces/Pid';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {patchPlanning} from '@octaved/planning/src/Modules/Planning';
import {DateStr} from '@octaved/typescript';
import {DatePicker, FormatDate, FormatDateFormats} from '@octaved/ui';
import {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';

const nodeTypeLabel: Record<EnumFlowNodeType.VALUE_GROUP | EnumFlowNodeType.VALUE_PROJECT, string> = {
  [EnumFlowNodeType.VALUE_GROUP]: 'nodeDetails:field.planning.subProjectDueDate',
  [EnumFlowNodeType.VALUE_PROJECT]: 'nodeDetails:field.planning.projectDueDate',
};

export interface NodeDueDateProps {
  node: Project | Group;
  readonly: boolean;
  onChangeDueDate: (dueDate: DateStr | null) => void;
}

export default function NodeDueDate({
  frame: Frame,
  node,
  readonly,
}: NodeDueDateProps & FramedSectionOrTileProps): ReactElement | null {
  const dispatch = useDispatch();
  const groupType = useSelector(getRootGroupTypeSelector)(node.id);
  if (groupType !== EnumFlowGroupType.VALUE_GROUP) {
    return null;
  }

  return (
    <Frame labelToken={nodeTypeLabel[node.nodeType]}>
      {readonly && <>{node.dueDate && <FormatDate date={node.dueDate} />}</>}
      {!readonly && (
        <div className={'flex'}>
          <DatePicker
            date={node.dueDate}
            setDate={(dueDate) =>
              dispatch(
                patchPlanning([
                  {
                    dueDate,
                    nodeId: node.id,
                  },
                ]),
              )
            }
            readonly={readonly}
            triggerOverride={{
              labelOverride: node.dueDate ? (
                <FormatDate date={node.dueDate} format={FormatDateFormats.short_with_year} />
              ) : undefined,
            }}
          />
        </div>
      )}
    </Frame>
  );
}
