import {WorkPackage} from '@octaved/flow/src/EntityInterfaces/Pid';
import {hasPriceCategory} from '@octaved/flow/src/Modules/Pid';
import {isCustomerBillableSelector} from '@octaved/flow/src/Modules/Selectors/CustomerSelectors';
import {
  getPriceCategoryDropdownOptionsSelector,
  showPriceCategorySelectionSelector,
} from '@octaved/flow/src/Modules/Selectors/PriceCategorySelectors';
import {hourlyBillableTypesSet} from '@octaved/flow/src/WorkPackage/BillingType';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import {isUuid} from '@octaved/validation';
import {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import PerTimeTrackingConfirmDialog, {ConfirmPerTimeTracking} from './PerTimeTrackingConfirmDialog';

const perTimeTrackingValue = 'usePriceCategoryPerTimeTracking';

export interface NodePriceCategoryProps {
  node: WorkPackage;
  patch: (pid: Partial<WorkPackage>) => void;
  readonly?: boolean;
  requiredByAutosave?: boolean;
}

export default function NodePriceCategory({
  frame: Frame,
  node,
  patch,
  readonly,
  requiredByAutosave,
}: NodePriceCategoryProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const getPriceCategoryDropdownOptions = useSelector(getPriceCategoryDropdownOptionsSelector);
  const showPriceCategorySelection = useSelector(showPriceCategorySelectionSelector);
  const isCustomerBillable = useSelector(isCustomerBillableSelector)(node.flowCustomer);
  const billingTypeNeedsPriceCategory = hasPriceCategory(node);
  const priceCategoryIsRequired = isCustomerBillable && billingTypeNeedsPriceCategory;

  const options = useMemo(() => {
    const opts = getPriceCategoryDropdownOptions(node.flowCustomer, node?.priceCategory || null).slice(0);
    const availableCatOpts = getPriceCategoryDropdownOptions(node.flowCustomer, null); //determine pure available
    if (
      node.billingType &&
      hourlyBillableTypesSet.has(node.billingType) &&
      (node.usePriceCategoryPerTimeTracking || (isCustomerBillable && availableCatOpts.length > 1))
    ) {
      opts.push({
        text: <span className={'italic'}>{t('workPackage:priceCategorySelection.priceCategoryPerTimeTracking')}</span>,
        value: perTimeTrackingValue,
      });
    }
    return opts;
  }, [
    getPriceCategoryDropdownOptions,
    node.usePriceCategoryPerTimeTracking,
    isCustomerBillable,
    t,
    node.priceCategory,
    node.billingType,
    node.flowCustomer,
  ]);

  useEffect(() => {
    if (
      priceCategoryIsRequired &&
      !readonly &&
      !showPriceCategorySelection &&
      !node.priceCategory &&
      options.length === 1
    ) {
      patch({priceCategory: options[0].value as Uuid});
    }
  }, [priceCategoryIsRequired, showPriceCategorySelection, node.priceCategory, options, patch, readonly]);

  const [confirmPerTimeTracking, setConfirmPerTimeTracking] = useState<null | ConfirmPerTimeTracking>(null);
  const closeConfirm = useCallback(() => setConfirmPerTimeTracking(null), []);

  if (!priceCategoryIsRequired || !showPriceCategorySelection) {
    return null;
  }

  const selected = options.find((o) => o.value === node.priceCategory);
  const value = (node.usePriceCategoryPerTimeTracking ? perTimeTrackingValue : node.priceCategory) || undefined;
  return (
    <Frame labelToken={'nodeDetails:field.priceCategory.label'}>
      {readonly && <div>{selected?.text || t('workPackage:priceCategorySelection.priceCategoryPerTimeTracking')}</div>}
      {!readonly && (
        <TWSelect
          value={value}
          onValueChange={(priceCategory) => {
            if (priceCategory === perTimeTrackingValue) {
              const toPatch = {priceCategory: null, usePriceCategoryPerTimeTracking: true};
              if (node.usePriceCategoryPerTimeTracking) {
                patch(toPatch);
              } else {
                setConfirmPerTimeTracking(toPatch);
              }
            } else if (isUuid(priceCategory)) {
              const toPatch = {priceCategory, usePriceCategoryPerTimeTracking: false};
              if (!node.usePriceCategoryPerTimeTracking) {
                patch(toPatch);
              } else {
                setConfirmPerTimeTracking(toPatch);
              }
            }
          }}
          disabled={!!confirmPerTimeTracking}
        >
          <TWSelectTrigger hasWarning={requiredByAutosave && !value}>
            <TWSelectValue placeholder={t('nodeDetails:field.priceCategory.placeholder')} />
          </TWSelectTrigger>
          <TWSelectContent>
            {options.map(({value, text}) => (
              <TWSelectItem key={value} value={value}>
                {text}
              </TWSelectItem>
            ))}
          </TWSelectContent>
        </TWSelect>
      )}

      {confirmPerTimeTracking && (
        <PerTimeTrackingConfirmDialog close={closeConfirm} patch={patch} toPatch={confirmPerTimeTracking} node={node} />
      )}
    </Frame>
  );
}
