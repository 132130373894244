import {FLOW_INIT_LOAD_SUCCESS} from '@octaved/flow/src/Modules/ActionTypes';
import {LicenseChangedEvent} from '@octaved/flow/src/Modules/Events';

import {InitAction} from '@octaved/flow/src/Modules/Initialization/Actions';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {mergeStates} from '@octaved/store/src/MergeStates';
import {Notification} from '@octaved/store/src/Notifications';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher, Dispatch} from '@octaved/store/src/Store';
import {notEmpty, RulesList, validateRules} from '@octaved/store/src/Validation';
import {ObjectContains} from '@octaved/validation';
import * as routes from '../../config/routes';
import {OrganizationPatchData, OrganizationState} from '../EntityInterfaces/OrganizationState';
import {requiredOrganizationSelector} from '../Selectors/OrganizationSelectors';
import {UPDATE_CUSOTMER_FAILURE, UPDATE_CUSOTMER_REQUEST, UPDATE_CUSOTMER_SUCCESS} from './ActionTypes';

const organizationReducers = createReducerCollection<OrganizationState | null>(null);
export const organizationReducer = organizationReducers.reducer;
organizationReducers.add<InitAction>(FLOW_INIT_LOAD_SUCCESS, (state, action) =>
  action.isInOrganization ? action.response.result.organization : state,
);
organizationReducers.add<LicenseChangedEvent>('flow.LicenseChangedEvent', (state, {license}) => {
  return mergeStates(state, {license});
});

function collectValidationRules(data: OrganizationPatchData): RulesList {
  const rules = [];
  if (data.hasOwnProperty('name')) {
    rules.push([notEmpty, data.name, 'core:systemSettings.errors.nameEmptyError', 'customerName']);
  }
  return rules as RulesList;
}

export function patchOrganiziation(
  data: OrganizationPatchData,
  addErrors?: (message: Notification[]) => ActionDispatcher<void, FlowState>,
): ActionDispatcher<Promise<boolean>> {
  return async (dispatch: Dispatch, getState) => {
    const state = getState();
    const organization = requiredOrganizationSelector(state);
    const errors = validateRules(collectValidationRules(data));
    if (errors.length) {
      if (addErrors) {
        dispatch(addErrors(errors));
      }
      return false;
    }

    //Determine at least one entry is different from given data
    if (!ObjectContains(organization, data)) {
      await dispatch({
        [CALL_API]: {
          endpoint: routes.patchOrganiziation,
          method: 'patch',
          options: {data},
          types: {
            failureType: UPDATE_CUSOTMER_FAILURE,
            requestType: UPDATE_CUSOTMER_REQUEST,
            successType: UPDATE_CUSOTMER_SUCCESS,
          },
        },
      });
    }
    return true;
  };
}
