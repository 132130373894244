import {Uuid} from '@octaved/typescript/src/lib';
import {ReactElement, useMemo} from 'react';
import {TaskSection} from '../../../../EntityInterfaces/TaskSection';
import TaskSectionGroup from '../../TaskGroup/TaskSectionGroup';
import {useTaskListContext} from '../../TaskListContext';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';
import {useStaticDragContext} from '../DragAndDrop/DragContext';
import {useDropOnEmptyParent} from '../DragAndDrop/DragItems';
import {useManuallySortedIndexes} from '../DragAndDrop/ManuallySortedIndexes';
import AddTaskSectionRows from './AddTaskSectionRows';

interface Props {
  parentId: Uuid;
  taskSections: TaskSection[];
}

export default function TaskSections({parentId, taskSections}: Props): ReactElement | null {
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {isDraggingRef, isSet} = useStaticDragContext();
  const indexes = useManuallySortedIndexes(parentId, 'section', taskSections);
  const sortedTaskSections = useMemo(() => {
    if (isSet && isDraggingRef.current) {
      return [...taskSections].sort((a, b) => (indexes[a.id] || -1) - (indexes[b.id] || -1));
    }
    return [...taskSections].sort((a, b) => a.sortOrder - b.sortOrder);
  }, [indexes, isDraggingRef, isSet, taskSections]);
  const {allowCreationOfTaskSections} = useTaskListContext();

  const dropOnEmptyTable = useDropOnEmptyParent(
    parentId,
    sortedTaskSections.length === 0 && !readonlyOrNotManageable,
    'section',
  );

  return (
    <div className={'taskSections'} ref={dropOnEmptyTable}>
      {sortedTaskSections.map((taskSection, index) => (
        <TaskSectionGroup key={taskSection.id} index={index} parentNodeId={parentId} taskSection={taskSection} />
      ))}
      {!readonlyOrNotManageable && allowCreationOfTaskSections && (
        <AddTaskSectionRows
          parentId={parentId}
          previousSiblingId={sortedTaskSections[sortedTaskSections.length - 1]?.id}
        />
      )}
      {/*language=scss*/}
      <style jsx>{`
        .taskSections {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          grid-gap: 10px;
        }
      `}</style>
    </div>
  );
}
