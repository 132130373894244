import {AuditWorkPackage} from '../../../../EntityInterfaces/Audit/AuditWorkPackage';
import {tokensForBillableCustomer} from '../../../../WorkPackage/BillingType';
import {freeOfChargeTranslations} from '../../../../WorkPackage/FreeOfCharge';
import BooleanCell from '../Table/Cells/BooleanCell';
import DateStringCell from '../Table/Cells/DateStringCell';
import EnumTranslationCell from '../Table/Cells/EnumTranslationCell';
import ReferenceCell from '../Table/Cells/ReferenceCell';
import type {AuditTableConfiguration} from './Confgurations';
import {createColumnDef} from './CreateColumnDef';
import {pidColumns} from './Pid';

const config: AuditTableConfiguration<AuditWorkPackage> = {
  columns: [
    ...pidColumns,
    createColumnDef('maxEffort', 'systemSettings:audit.tables.workPackage.maxEffortHeader'),
    createColumnDef('effortFrom', 'systemSettings:audit.tables.workPackage.effortFromHeader'),
    createColumnDef('effortTo', 'systemSettings:audit.tables.workPackage.effortToHeader'),
    createColumnDef('billingType', 'systemSettings:audit.tables.workPackage.billingTypeHeader', ({cell: {value}}) => {
      return <EnumTranslationCell value={value} translationMapping={tokensForBillableCustomer} />;
    }),
    createColumnDef(
      'freeOfChargeReason',
      'systemSettings:audit.tables.workPackage.freeOfChargeReasonHeader',
      ({cell: {value}}) => {
        return <EnumTranslationCell value={value} translationMapping={freeOfChargeTranslations} />;
      },
    ),
    createColumnDef('fixedPrice', 'systemSettings:audit.tables.workPackage.fixedPriceHeader'),
    createColumnDef('plannedStart', 'systemSettings:audit.tables.workPackage.plannedStartHeader', DateStringCell),
    createColumnDef('plannedEnd', 'systemSettings:audit.tables.workPackage.plannedEndHeader', DateStringCell),
    createColumnDef('priceCategory', 'systemSettings:audit.tables.workPackage.priceCategoryHeader', ReferenceCell),
    createColumnDef('isCompleted', 'systemSettings:audit.tables.workPackage.isCompletedHeader', BooleanCell),
  ],
  tableIdent: 'workPackages',
  tableNameTranslationToken: 'systemSettings:audit.workPackageTableName',
};
export default config;
