import {EnumFlowGroupType, EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';

import {Uuid} from '@octaved/typescript/src/lib';
import {memo, ReactElement, useMemo} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {getRootGroupTypeSelector} from '../../Modules/Selectors/GroupSelectors';

interface NodeTypeNameProps {
  type: EnumFlowNodeType;
  nodeId: Uuid;
}

export function getTransTokenForNodeType(nodeType: EnumFlowNodeType, rootGroupType: EnumFlowGroupType): string {
  let token = '';
  if (nodeType === EnumFlowNodeType.VALUE_WORK_PACKAGE) {
    if (rootGroupType === EnumFlowGroupType.VALUE_GROUP) {
      token = 'general:pid.workPid';
    } else {
      token = 'general:pid.userStory';
    }
  } else if (nodeType === EnumFlowNodeType.VALUE_GROUP) {
    if (rootGroupType === EnumFlowGroupType.VALUE_GROUP) {
      token = 'general:pid.groupPid';
    } else if (rootGroupType === EnumFlowGroupType.VALUE_SPRINT) {
      token = 'general:pid.agilSprint';
    } else if (rootGroupType === EnumFlowGroupType.VALUE_BACKLOG) {
      token = 'general:pid.agilBacklog';
    }
  } else if (nodeType === EnumFlowNodeType.VALUE_PROJECT) {
    token = 'general:pid.projectPid';
  } else if (nodeType === EnumFlowNodeType.VALUE_PROJECT_FOLDER) {
    token = 'general:pid.projectFolder';
  } else if (nodeType === EnumFlowNodeType.VALUE_TASK) {
    token = 'general:taskText';
  } else if (nodeType === EnumFlowNodeType.VALUE_USER_NODE) {
    token = '';
    token = 'general:taskText';
  } else if (nodeType === EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE) {
    token = 'general:pid.subWorkPackage';
  } else if (nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE) {
    token = 'general:nodeType.materialResource';
  } else if (nodeType === EnumFlowNodeType.VALUE_MATERIAL_RESOURCE_FOLDER) {
    token = 'general:nodeType.materialResourceFolder';
  } else {
    throw new Error('Unkown pid type ' + nodeType);
  }

  return token;
}

export default memo(function PidTypeName({type, nodeId}: NodeTypeNameProps): ReactElement {
  const getRootGroupType = useSelector(getRootGroupTypeSelector);
  const nameToken = useMemo<string>(() => {
    const rootGroupType = getRootGroupType(nodeId);
    return getTransTokenForNodeType(type, rootGroupType);
  }, [getRootGroupType, nodeId, type]);

  return (
    <span>
      <Trans i18nKey={nameToken} />
    </span>
  );
});
