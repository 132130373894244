import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {IconButton, Tooltip} from '@octaved/ui';
import {Check, Circle, SortAsc} from 'lucide-react';
import {ReactElement, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  projectsGroupProjectsSelector,
  projectsSortProjectsBySelector,
} from '../../../Modules/Selectors/UiPages/ProjectsSelector';
import {useProjectUiStatePatch} from '../../../Pages/Projects/Projects';
import DropdownPopup from '../../Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../Form/Dropdown/DropdownPopup/Types';

interface Props {
  withGrouping?: boolean;
}

export default function SortAndGroupOptions({withGrouping}: Props): ReactElement {
  const patchProjectsUi = useProjectUiStatePatch();

  const groupProjects = useSelector(projectsGroupProjectsSelector);
  const sortBy = useSelector(projectsSortProjectsBySelector);
  const canReadCustomers = useIsGranted('FLOW_GLOBAL_CUSTOMERS_READ');

  const items = useMemo<DropdownPopupItemOptions[]>(() => {
    const items: DropdownPopupItemOptions[] = [
      {
        icon: <Circle fill={'currentColor'} strokeWidth={0} />,
        iconColor: sortBy === 'alphanumeric' || (!canReadCustomers && sortBy === 'customer') ? 'black' : 'white',
        iconSize: 'small',
        onMouseDown: () => patchProjectsUi({sortProjectsBy: 'alphanumeric'}),
        token: 'pages:projects.header.sortAndGroup.alphabetical',
        type: DropdownPopupItemType.item,
      },
    ];
    if (canReadCustomers) {
      items.push({
        icon: <Circle fill={'currentColor'} strokeWidth={0} />,
        iconColor: sortBy === 'customer' ? 'black' : 'white',
        iconSize: 'small',
        onMouseDown: () => patchProjectsUi({sortProjectsBy: 'customer'}),
        token: 'pages:projects.header.sortAndGroup.customer',
        type: DropdownPopupItemType.item,
      });
    }
    items.push({
      icon: <Circle fill={'currentColor'} strokeWidth={0} />,
      iconColor: sortBy === 'pm' ? 'black' : 'white',
      iconSize: 'small',
      onMouseDown: () => patchProjectsUi({sortProjectsBy: 'pm'}),
      token: 'pages:projects.header.sortAndGroup.projectManager',
      type: DropdownPopupItemType.item,
    });

    if (withGrouping) {
      items.push({type: DropdownPopupItemType.divider});
      items.push({
        icon: <Check />,
        iconColor: groupProjects ? 'green' : 'brightGrey',
        onMouseDown: () => patchProjectsUi({groupProjects: !groupProjects}),
        token: 'pages:projects.header.sortAndGroup.group',
        type: DropdownPopupItemType.item,
      });
    }
    return items;
  }, [canReadCustomers, groupProjects, patchProjectsUi, sortBy, withGrouping]);

  return (
    <DropdownPopup items={items} closeOnSelect position={'bottom right'} inDrawer>
      <Tooltip toolTipTranslation={'tooltips:components.pageHeader.buttonSort'} position={'bottom center'}>
        <IconButton variant={'ghost'} icon={<SortAsc />} size={'sm'} />
      </Tooltip>
    </DropdownPopup>
  );
}
