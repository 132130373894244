export type DisposableArray = Array<(() => void) | undefined | null | false>;
import {Image} from 'konva/lib/shapes/Image';
import {DebouncedFunc, debounce} from 'lodash';
import {IconNode, ImageOptions, iconToImage, svgStringToImage} from './SvgToCanvas';

export class Disposable {
  protected readonly disposables: DisposableArray = [];
  protected isDisposed = false;

  dispose(): void {
    this.isDisposed = true;
    this.disposeList(this.disposables);
  }

  protected disposeList(list: DisposableArray): void {
    list.forEach((cb) => cb && cb());
  }

  protected async iconToImage(icon: IconNode, options: ImageOptions): Promise<Image> {
    const image = await iconToImage(icon, options);
    if (this.isDisposed) {
      //destroy image if component is disposed
      requestAnimationFrame(() => image.destroy());
    }
    return image;
  }

  protected async svgStringToImage(svgString: string, options: ImageOptions): Promise<Image> {
    const image = await svgStringToImage(svgString, options);
    if (this.isDisposed) {
      //destroy image if component is disposed
      requestAnimationFrame(() => image.destroy());
    }
    return image;
  }

  protected debouncedAction = (cb: () => void, wait = 5): DebouncedFunc<() => void> => {
    return debounce(() => {
      if (!this.isDisposed) {
        cb();
      }
    }, wait);
  };
}
