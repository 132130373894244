import {ReactElement} from 'react';
import Section from '../../../../Section';
import WorkPackageFreezeTimeTracking, {
  WorkPackageFreezeTimeTrackingProps,
} from '../Common/WorkPackageFreezeTimeTracking';

export default function WorkPackageFreezeTimeTrackingSection(
  props: WorkPackageFreezeTimeTrackingProps,
): ReactElement | null {
  return <WorkPackageFreezeTimeTracking frame={Section} {...props} />;
}
