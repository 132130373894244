import {EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {useTimeEffortPriceSumsPerBillingType} from '@octaved/flow/src/Modules/Statistics/TimeEffortPriceSumsPerBillingType';
import {createTotals} from '@octaved/flow/src/Pages/Projects/Inspector/Common/ParentTimeSums/TotalComputers';
import {Uuid} from '@octaved/typescript/src/lib';
import {FunctionComponent, ReactElement, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FramedSectionOrTileProps} from '../../General/Common/Type';
import FixedPriceRowWithoutMaxEffortRow from './Components/TimeSums/FixedPriceRowWithoutMaxEffortRow';
import Hours from './Components/TimeSums/Hours';
import {RowProps} from './Components/TimeSums/Interfaces';
import RowWithoutMaxEffort from './Components/TimeSums/RowWithoutMaxEffort';

const withoutMaxEffort: [EnumFlowPidBillingType, FunctionComponent<RowProps>][] = [
  [EnumFlowPidBillingType.VALUE_EFFORT, RowWithoutMaxEffort],
  [EnumFlowPidBillingType.VALUE_FIXED_PRICE, FixedPriceRowWithoutMaxEffortRow],
];

export interface NodeSumWithoutTimeLimitsProps {
  workPackageIds: Uuid[];
  billable?: boolean;
}

export default function NodeSumWithoutTimeLimits({
  billable,
  frame: Frame,
  workPackageIds,
}: NodeSumWithoutTimeLimitsProps & FramedSectionOrTileProps): ReactElement | null {
  const {t} = useTranslation();
  const {sums} = useTimeEffortPriceSumsPerBillingType(workPackageIds);
  const totals = useMemo(() => createTotals(sums), [sums]);
  if (withoutMaxEffort.every(([type]) => !sums[type])) {
    return null;
  }
  return (
    <Frame
      labelToken={
        billable
          ? 'nodeDetails:field.sumWithoutLimits.billableLabel'
          : 'nodeDetails:field.sumWithoutLimits.nonBillableLabel'
      }
    >
      <div className={'grid grid-cols-[max-content_1fr_max-content] items-center gap-x-4 gap-y-1 pt-2'}>
        {withoutMaxEffort.map(([type, Component]) => {
          const sum = sums[type];
          return sum && <Component key={type} type={type} sum={sum} billable={billable} />;
        })}

        <div className={'col-span-3 my-1 h-px bg-slate-200'} />

        <div className={'font-semibold'}>{t('nodeDetails:field.controllingTimeTracking.total')}</div>
        <div />
        <Hours hours={totals.billableHoursWithoutMaxEffort} shortUnit />
      </div>
    </Frame>
  );
}
