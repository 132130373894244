import {ReactElement} from 'react';
import {useParams} from 'react-router-dom';
import DialogAndDrawerHeader from '../../Components/Layout/DialogAndDrawerHeader';
import {useLoadNode, useProject} from '../../Modules/Hooks/Nodes';
import ChangeProjectCustomerForm from './ChangeProjectCustomerForm';
import {DialogContent, DialogFrame, DialogTitle} from '../DialogFrame';

interface ChangeCustomerParams {
  changeCustomerProjectId?: string;
  close?: () => void;
}

export default function ChangeCustomer(props: ChangeCustomerParams): ReactElement | null {
  const {changeCustomerProjectId: changeCustomerProjectIdParam} = useParams<'changeCustomerProjectId'>();
  const changeCustomerProjectId = props.changeCustomerProjectId || changeCustomerProjectIdParam;
  useLoadNode(changeCustomerProjectId);
  const project = useProject(changeCustomerProjectId);
  if (!changeCustomerProjectId || !project) {
    return null;
  }
  return (
    <DialogFrame size={'tiny'} overflowVisible onClose={props.close}>
      <DialogTitle onClose={props.close}>
        <DialogAndDrawerHeader header={'pages:projects.inspector.header.changeCustomer'} />
      </DialogTitle>
      <DialogContent>
        <ChangeProjectCustomerForm project={project} />
      </DialogContent>
    </DialogFrame>
  );
}
