import {joinMovements, line, move, Point} from '@octaved/svg';
import {CSSProperties} from 'react';

const padding = 2;

export function calculateLine(
  start: Point,
  current: Point,
): {
  linePath: string;
  style: CSSProperties;
} {
  const movementX = current.x - start.x;
  const movementY = current.y - start.y;
  const startX = movementX > 0 ? 0 : -movementX;
  const startY = movementY > 0 ? 0 : -movementY;
  const endX = movementX > 0 ? movementX : 0;
  const endY = movementY > 0 ? movementY : 0;

  return {
    linePath: joinMovements([
      move(startX + padding, startY + padding),
      line(endX + padding, startY + padding),
      line(endX + padding, endY + padding),
    ]),
    style: {
      height: Math.abs(movementY) + padding * 2,
      left: Math.min(movementX, 0),
      position: 'absolute',
      top: Math.min(movementY, 0),
      width: Math.abs(movementX) + padding * 2,
    },
  };
}
