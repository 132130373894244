import {Notification} from '@octaved/store/src/Notifications';
import * as React from 'react';
import {LabelProps, SemanticShorthandItem} from 'semantic-ui-react';

export interface RequiredProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: any, data: any) => void;
  error?: boolean | SemanticShorthandItem<LabelProps>;
}

export interface InjectedProps {
  errors: Notification[];
  fieldName: string;
  beforeChange?: (message: Notification | null, fieldName: string) => void;
}

interface ValidationStatet {
  error: Notification | null;
}

export const withValidation = <P extends RequiredProps>(
  Component: React.ComponentType<P>,
  displayErrorNotification = false,
  errorNotificationDirection = '',
): typeof React.Component<P & InjectedProps, ValidationStatet> =>
  class ValidationField extends React.Component<P & InjectedProps, ValidationStatet> {
    state: ValidationStatet = {
      error: null,
    };

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<P & InjectedProps>): void {
      this.setState({
        error: nextProps.errors.find((notification) => notification.field === nextProps.fieldName) || null,
      });
    }

    render(): React.ReactElement {
      //remove props which are not used by child
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {fieldName, beforeChange, onChange, errors, ...childProps} = this.props;
      return (
        <Component
          {...(childProps as P)}
          error={
            displayErrorNotification && this.state.error
              ? errorNotificationDirection
                ? {content: this.state.error.message, pointing: errorNotificationDirection}
                : this.state.error.message
              : this.state.error !== null
          }
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          onChange={(event: React.ChangeEvent<HTMLInputElement>, data: any) => {
            this.setState({error: null});
            if (beforeChange) {
              beforeChange(this.state.error, fieldName);
            }
            if (onChange) {
              onChange(event, data);
            }
          }}
        />
      );
    }
  };
