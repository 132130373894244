import {EnumFlowNodeType, EnumFlowPidBillingType} from '@octaved/env/src/dbalEnumTypes';
import {useIsGranted} from '@octaved/security/src/Authorization/Authorization';
import {NodeRight} from '@octaved/security/src/Authorization/Rights';
import {Uuid} from '@octaved/typescript/src/lib';
import {TWSelect, TWSelectContent, TWSelectItem, TWSelectTrigger, TWSelectValue} from '@octaved/ui';
import memoize from 'lodash/memoize';
import {ReactElement, ReactNode, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {patchNode} from '../../Modules/AnyNode';
import {isCustomerBillableSelector} from '../../Modules/Selectors/CustomerSelectors';
import {getNodeAncestrySelector, useNodeAncestry} from '../../Modules/Selectors/NodeTreeSelectors';
import {useTimeTrackingSelector} from '../../Modules/Selectors/SettingSelectors';
import {FlowState} from '../../Modules/State';
import {NodeStatusNode} from '../NodeIdentifiers';
import {NodeStatus, nodeStatusSortWeights} from './NodeStatus';
import {NodeStatusIcon} from './NodeStatusIcon';
import NodeStatusLabel from './NodeStatusLabel';
import {useNodeStatus} from './NodeStatusSelectors';
import {StatusWarningsPopup, useAsyncStatusWarnings} from './NodeStatusWarnings';

type Conditional = Partial<Record<NodeStatus, boolean>>;

interface Config<NT extends NodeStatusNode> {
  available: (NodeStatus & keyof NT)[];
  conditionsSelector?: (s: FlowState) => (nodeId: Uuid) => Conditional;
  manageRight: NodeRight;
}

const configs: {
  [NT in NodeStatusNode as NT['nodeType']]: Config<NT>;
} = {
  [EnumFlowNodeType.VALUE_PROJECT]: {
    available: ['isLocked', 'isClosed'],
    conditionsSelector: createSelector(getNodeAncestrySelector, (getAncestry) =>
      memoize((nodeId): Conditional => {
        const {project} = getAncestry(nodeId, true);
        return {
          isClosed: !!project && !project.isTemplate,
        };
      }),
    ),
    manageRight: 'FLOW_NODE_PID_MANAGE_BASIC',
  },
  [EnumFlowNodeType.VALUE_GROUP]: {
    available: ['isLocked', 'isClosed'],
    conditionsSelector: createSelector(getNodeAncestrySelector, (getAncestry) =>
      memoize((nodeId): Conditional => {
        const {project} = getAncestry(nodeId, true);
        return {
          isClosed: !!project && !project.isTemplate,
        };
      }),
    ),
    manageRight: 'FLOW_NODE_PID_MANAGE_BASIC',
  },
  [EnumFlowNodeType.VALUE_WORK_PACKAGE]: {
    available: ['isLocked', 'isApprovedForBilling', 'isClosed'],
    conditionsSelector: createSelector(
      useTimeTrackingSelector,
      getNodeAncestrySelector,
      isCustomerBillableSelector,
      (useTimeTracking, getAncestry, isCustomerBillable) =>
        memoize((nodeId): Conditional => {
          const {project, workPackage} = getAncestry(nodeId, true);
          return {
            isApprovedForBilling:
              useTimeTracking &&
              !!project &&
              !project.isTemplate &&
              isCustomerBillable(project.flowCustomer) &&
              workPackage?.billingType !== EnumFlowPidBillingType.VALUE_FREE_OF_CHARGE,
            isClosed: !!project && !project.isTemplate,
          };
        }),
    ),
    manageRight: 'FLOW_NODE_PID_MANAGE_BASIC',
  },
  [EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE]: {
    available: ['isLocked'],
    manageRight: 'FLOW_NODE_SUB_WORK_PACKAGE_MANAGE_BASIC',
  },
};

const emptyConditions: Conditional = {};
const emptyConditionsFn: () => Conditional = () => emptyConditions;
const emptyConditionsSelector: () => () => Conditional = () => emptyConditionsFn;

interface Option {
  content: ReactNode;
  value: NodeStatus;
}

interface StatusProps {
  node: NodeStatusNode;
}

export default function NodeStatusDropdown({node}: StatusProps): ReactElement {
  const {ancestors} = useNodeAncestry(node.id, true);
  const {available, conditionsSelector, manageRight} = configs[node.nodeType];
  const canManage = useIsGranted(manageRight, node.id) && !node.isArchived;
  const conditions = useSelector((s: FlowState) => (conditionsSelector ?? emptyConditionsSelector)(s)(node.id));
  const ownStatus = useNodeStatus(node.id);
  const parentStatus = useNodeStatus(ancestors[1]?.id);
  const warnings = useAsyncStatusWarnings(node.id);

  const options = useMemo((): Option[] => {
    const parentWeight = nodeStatusSortWeights[parentStatus];
    const opts: Option[] = [];

    const push = (curStatus: NodeStatus): void => {
      const curWeight = nodeStatusSortWeights[curStatus];
      if ((conditions[curStatus] !== false || curStatus === ownStatus) && curWeight >= parentWeight) {
        opts.push({
          content: <NodeStatusIcon status={curStatus} withText />,
          value: curStatus,
        });
      }
    };

    push('isOpen');
    available.forEach(push);

    return opts;
  }, [available, conditions, ownStatus, parentStatus]);

  const dispatch = useDispatch();

  return (
    <>
      {options.length > 1 ? (
        <TWSelect
          value={ownStatus}
          disabled={!canManage}
          onValueChange={async (nextStatus: NodeStatus) => {
            if (canManage) {
              const commit = async (): Promise<void> => {
                const patch: Partial<NodeStatusNode> = {};
                available.forEach((curStatus) => {
                  // @ts-ignore this is the correct patch
                  patch[curStatus] = curStatus === nextStatus;
                });
                dispatch(patchNode(node.nodeType, node.id, patch));
              };

              if (await warnings.hasWarnings(nextStatus)) {
                warnings.commit.current = commit;
              } else {
                await commit();
              }
            }
          }}
        >
          <TWSelectTrigger className={'w-4/6'}>
            <TWSelectValue />
          </TWSelectTrigger>
          <TWSelectContent>
            {options.map(({content, value}) => (
              <TWSelectItem key={value} value={value}>
                {content}
              </TWSelectItem>
            ))}
          </TWSelectContent>
        </TWSelect>
      ) : (
        <NodeStatusLabel nodeId={node.id} />
      )}
      {warnings.stats && (
        <StatusWarningsPopup
          commit={warnings.commit}
          close={warnings.close}
          nodeType={node.nodeType}
          stats={warnings.stats}
          status={warnings.status}
        />
      )}
    </>
  );
}
