import {getClientXY} from '@octaved/flow/src/Hooks/MouseMove';
import Konva from 'konva';

interface OnOutsideClickProps {
  event: MouseEvent | TouchEvent;
  root: Konva.Node;
}

export function onOutsideClick({event, root}: OnOutsideClickProps): boolean {
  const content = root.getStage()?.content;
  if ((event.target as Element).parentElement !== content || !content) {
    // outside of canvas
    return true;
  }
  const [clientX, clientY] = getClientXY(event);
  const rect = content.getBoundingClientRect();
  const scaleX = rect.width / content.clientWidth || 1;
  const scaleY = rect.height / content.clientHeight || 1;
  const mouseX = (clientX - rect.left) / scaleX;
  const mouseY = (clientY - rect.top) / scaleY;
  const {x, y, width, height} = root.getClientRect({skipShadow: true});

  const intersects = mouseX >= x && mouseX <= x + width && mouseY >= y && mouseY <= y + height;
  return !intersects;
}
