import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import EditName, {EditNameProps} from '../EditName';

export default function EditTaskSectionName(
  props: Omit<EditNameProps, 'textAreaPlaceholder' | 'viewNode'>,
): ReactElement {
  const {t} = useTranslation();
  return (
    <EditName
      {...props}
      fontWeight={600}
      nameInputTopSpacing={1}
      textAreaPlaceholder={'taskList:name.sectionPlaceholder'}
      viewNode={
        <>
          {props.name && props.name}
          {!props.name && <span>{t('taskList:name.sectionPlaceholder')}</span>}
        </>
      }
    />
  );
}
