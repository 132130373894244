import {EnumFlowGroupType} from '@octaved/env/src/dbalEnumTypes';
import {useNodeColor, useWorkPackage} from '@octaved/flow/src/Modules/Hooks/Nodes';
import {getRootGroupTypeSelector} from '@octaved/flow/src/Modules/Selectors/GroupSelectors';
import {FlowState} from '@octaved/flow/src/Modules/State';
import {Icon} from '@octaved/ui';
import {useDarkenColor} from '@octaved/ui-components/src/Hooks/UseContrastColor';
import classNames from 'classnames';
import {CheckCircle2, Flame} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {getHasWarningSelector} from '../../../../../Selectors/PlanningWarningSelectors';
import ActionBar from './ActionBar';
import DependencyIcon from './DependencyIcon';
import {PlanningBarProps} from './PlanningBarProps';

export default function WorkPackageBar({
  onClick,
  id,
  endCanBeResized,
  startCanBeResized,
  text,
  showText,
  children,
  readonly,
  canBeDragged,
  canDndDependency,
  isCreating,
  isNotPlanned,
  planningDate,
  isIndirectAssigned,
}: PlanningBarProps): ReactElement {
  const rootGroupType = useSelector(getRootGroupTypeSelector)(id);
  const isSprint = rootGroupType === EnumFlowGroupType.VALUE_SPRINT;
  const wp = useWorkPackage(id);
  const bottomColor = useNodeColor(id);
  const topColor = useDarkenColor(bottomColor, 5);
  const gradient = `linear-gradient(45deg, ${topColor} 0%, #${bottomColor} 100%)`;
  const warning = useSelector((s: FlowState) => getHasWarningSelector(s)(id, planningDate.id));

  return (
    <ActionBar
      id={id}
      text={text}
      showText={showText}
      startCanBeResized={startCanBeResized}
      endCanBeResized={endCanBeResized}
      canBeDragged={canBeDragged}
      canDndDependency={canDndDependency}
      planningDate={planningDate}
      isCreating={isCreating}
      isIndirectAssigned={isIndirectAssigned}
      readonly={readonly}
    >
      <div
        className={classNames('visibleBar', {
          isIndirectAssigned,
          isNotPlanned,
          isSprint,
          readonly,
          finished: wp?.isCompleted,
          isGhost: isCreating,
        })}
        onClick={readonly ? undefined : onClick}
      >
        {isSprint && <div className={'line'} />}
        {children}
        <div className={classNames('icons')}>
          <DependencyIcon nodeId={id} planningDateId={planningDate.id} />
          {warning && (
            <div className={'burningIcon'}>
              <Icon iconColor={'white'} size={'big'} noMargin>
                <Flame />
              </Icon>
            </div>
          )}
          {wp?.isCompleted && (
            <div className={'finishedIcon'}>
              <Icon iconColor={'white'} size={'big'} noMargin>
                <CheckCircle2 />
              </Icon>
            </div>
          )}
        </div>
      </div>

      {/*#region styles*/}
      {/*language=SCSS*/}
      <style jsx>{`
        .visibleBar {
          background: ${bottomColor};
          background: ${gradient};
          height: 100%;
          border-radius: 6px;
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          font-size: 14px;
        }

        .isGhost,
        .isNotPlanned {
          opacity: 0.5;
          transition: opacity 120ms ease-out;
        }

        .isIndirectAssigned {
          opacity: 0.7;
          filter: grayscale(0.7);
          transition:
            opacity 120ms ease-out,
            filter 120ms ease-out;
        }

        .isNotPlanned:hover,
        .isIndirectAssigned:hover {
          opacity: 1;
          filter: grayscale(0);
        }

        .readonly {
          cursor: auto;
        }

        .isSprint {
          background: none;
          border: none;
          height: 40%;
          border-left: 2px solid #7a7a7a;
          border-right: 2px solid #7a7a7a;
          border-radius: 0;
          display: flex;
          align-items: center;
          pointer-events: none;
          cursor: default;

          & :global(.text) {
            position: absolute;
            left: 0;
            margin-top: -15px;
            padding: 0 2px;
            color: #7a7a7a;
            max-width: 100%;
          }
        }

        .line {
          height: 2px;
          width: 100%;
          background-color: #7a7a7a;
        }

        .finished {
          opacity: 0.2;
          transition: opacity 120ms ease-in-out;

          &:hover {
            opacity: 1;
          }
        }

        .icons {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .burningIcon {
          & :global(svg) {
            fill: #f00;
          }
        }

        .finishedIcon {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          & :global(svg) {
            fill: var(--color-green-500);
          }
        }
      `}</style>
      {/*#endregion*/}
    </ActionBar>
  );
}
