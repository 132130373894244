import {getTrackedTimeSumsInSubtrees} from '@octaved/flow-api';
import {createUseEntityHook} from '@octaved/hooks/src/Factories/EntityHookFactory';
import {useSingleValueArray} from '@octaved/hooks/src/SingleValueArray';
import ReduceFromMap, {addMultiToReducerMap} from '@octaved/store/src/Reducer/ReduceFromMap';
import {Uuid} from '@octaved/typescript/src/lib';
import {TrackedTimeSumsInSubtree} from '../EntityInterfaces/TrackedTimeSumsInSubtree';
import {FLOW_LOAD_TRACKED_TIME_SUMS_START, FLOW_LOAD_TRACKED_TIME_SUMS_SUCCESS} from './ActionTypes';
import {
  trackedTimeSumsInSubtreeSelector,
  trackedTimeSumsInSubtreeStatesSelector,
} from './Selectors/TrackedTimeSumsInSubtreeSelectors';
import {FlowState} from './State';

const reducerMap = new Map();
export const trackedTimeSumsInSubtreeReducer = ReduceFromMap(reducerMap);

const stateReducerMap = new Map();
addMultiToReducerMap(
  stateReducerMap,
  [
    'flow.TimeRecordCreatedEvent',
    'flow.TimeRecordPatchedEvent',
    'flow.TimeRecordRemovedEvent',
    'flow.TimeRecordsRestoredFromTrashEvent',
  ],
  () => ({}),
);
export const trackedTimeSumsInSubtreeStateReducer = ReduceFromMap(stateReducerMap);

const [, useTrackedTimeSumsInSubtrees] = createUseEntityHook<FlowState, TrackedTimeSumsInSubtree>(
  FLOW_LOAD_TRACKED_TIME_SUMS_START,
  FLOW_LOAD_TRACKED_TIME_SUMS_SUCCESS,
  getTrackedTimeSumsInSubtrees,
  trackedTimeSumsInSubtreeSelector,
  trackedTimeSumsInSubtreeStatesSelector,
  reducerMap,
  stateReducerMap,
);

export function useTrackedTimeSumsInSubtree(nodeId: Uuid): {
  trackedTimeSumsInSubtree: TrackedTimeSumsInSubtree | undefined;
} {
  const {entries} = useTrackedTimeSumsInSubtrees(useSingleValueArray(nodeId));
  return {trackedTimeSumsInSubtree: entries[0]};
}
