import {Icon, IconButton, Tooltip} from '@octaved/ui';
import classNames from 'classnames';
import {Calendar, ChevronDown, Clock, Tag, User} from 'lucide-react';
import {memo, ReactElement} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {TaskListColumn} from '../../../../Modules/Ui/TaskList';
import DropdownPopup from '../../../Form/Dropdown/DropdownPopup';
import {DropdownPopupItemOptions, DropdownPopupItemType} from '../../../Form/Dropdown/DropdownPopup/Types';
import {useTaskListContext} from '../../TaskListContext';
import {useTaskListReadonlyContext} from '../../TaskListReadonlyContext';

const headerCells: Array<{
  columnKey: TaskListColumn;
  headerName: string;
  icon: ReactElement;
  smaller?: boolean;
  tooltipTranslationToken: string;
  wider?: boolean;
}> = [
  {
    columnKey: 'effort',
    headerName: 'taskList:header.table.columnEffort',
    icon: <Clock />,
    smaller: true,
    tooltipTranslationToken: 'taskList:header.table.columnEffortTooltip',
  },
  {
    columnKey: 'planing',
    headerName: 'taskList:header.table.columnPlanning',
    icon: <Calendar />,
    tooltipTranslationToken: 'taskList:header.table.columnPlanningTooltip',
  },
  {
    columnKey: 'assignments',
    headerName: 'taskList:header.table.columnAssignments',
    icon: <User />,
    tooltipTranslationToken: 'taskList:header.table.columnAssignmentsTooltip',
    wider: true,
  },
  {
    columnKey: 'labels',
    headerName: 'taskList:header.table.columnLabels',
    icon: <Tag />,
    tooltipTranslationToken: 'taskList:header.table.columnLabelsTooltip',
    wider: true,
  },
];

export default memo(function TableHeader(): ReactElement {
  const {t} = useTranslation();
  const {readonlyOrNotManageable} = useTaskListReadonlyContext();
  const {visibleColumns, toggleVisibleColumn} = useTaskListContext();

  const headerMenuItems = (hideKey: TaskListColumn): DropdownPopupItemOptions[] => {
    const items: DropdownPopupItemOptions[] = [];
    if (toggleVisibleColumn) {
      items.push({
        onMouseDown: () => toggleVisibleColumn(hideKey, false),
        token: 'taskList:header.table.hideColumn',
        type: DropdownPopupItemType.item,
      });
    }
    return items;
  };

  return (
    <div className={'tableHeader'}>
      <div className={classNames('cell', 'nameCell', {readonly: readonlyOrNotManageable})}>
        <div className={'nameCellContent'}>
          <span>{t('taskList:header.table.columnName')}</span>
        </div>
      </div>

      <div className={'rightStructure'}>
        {headerCells
          .filter(({columnKey}) => visibleColumns.has(columnKey))
          .map(({headerName, icon, columnKey, tooltipTranslationToken, wider, smaller}, idx) => {
            const menuItems = headerMenuItems(columnKey);
            return (
              <Tooltip
                key={idx}
                toolTipTranslation={tooltipTranslationToken}
                position={'bottom center'}
                disabled={columnKey === 'labels'}
              >
                <div className={classNames('cell', {wider, smaller})}>
                  <div className={'cellLeftContent'}>
                    <Icon size={'large'}>{icon}</Icon>
                    <span>
                      <Trans i18nKey={headerName} />
                    </span>
                  </div>

                  {menuItems.length > 0 && (
                    <div className={'cellMenu'}>
                      <DropdownPopup items={menuItems} noIcon position={'bottom left'} closeOnSelect>
                        <IconButton variant={'ghost'} icon={<ChevronDown />} size={'sm'} />
                      </DropdownPopup>
                    </div>
                  )}
                </div>
              </Tooltip>
            );
          })}
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .tableHeader,
        .rightStructure {
          display: flex;
          align-items: stretch;
          height: var(--row-height);
        }

        .rightStructure {
          flex-shrink: 0;
        }

        .cell {
          display: flex;
          align-items: center;
          width: var(--cell-width-normal);
          padding: 0 var(--cell-padding);
          color: #777;
          font-size: 12px;
          justify-content: space-between;
          transition: background-color 170ms ease-in-out;
          border: 1px solid var(--cell-border);
          margin-right: -1px;
          border-top-color: transparent;
        }

        .wider {
          width: var(--cell-width-wider);
        }

        .smaller {
          width: var(--cell-width-smaller);
        }

        .cell:last-child {
          border-right: none;
        }

        .cell:not(.readonly):hover {
          background-color: var(--color-hover-bg);
        }

        .cellLeftContent {
          display: flex;
          align-items: center;
        }

        .cellMenu {
          visibility: hidden;
          pointer-events: none;
        }

        .cell:hover .cellMenu {
          visibility: visible;
          pointer-events: all;
        }

        .nameCell {
          width: 100%;
          height: 100%;
          border: none;
          flex-grow: 1;
          padding-left: var(--drag-handle-width);
          padding-right: 0;
          min-width: 450px;
        }

        .nameCellContent {
          border-top-color: transparent;
          border-bottom: 1px solid var(--cell-border);
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
        }

        .rightStructure {
          padding-right: var(--drag-handle-width);
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
});
