import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {MaybeUuid} from '@octaved/typescript/src/lib';
import {cn, Tooltip} from '@octaved/ui';
import {generateUuid} from '@octaved/utilities';
import memoize from 'lodash/memoize';
import {ExternalLink} from 'lucide-react';
import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {ProjectFolderDataReferenceNumberUrlPattern} from '../EntityInterfaces/ProjectFolder';
import {getNodeAncestrySelector, NodeAncestry} from '../Modules/Selectors/NodeTreeSelectors';
import {FlowState} from '../Modules/State';

export interface RefNumPatternWithId extends ProjectFolderDataReferenceNumberUrlPattern {
  id: string; //We need an id for the drag and drop to know the proper dom elements
}

export const simplePattern = '.+';

export function createEmptyPattern(): RefNumPatternWithId {
  return {id: generateUuid(), numberRegex: simplePattern, urlTemplate: '', nodeTypes: null};
}

export function isValidUrlTemplate(url: string): boolean {
  if (url === '') {
    return true;
  }

  return /^([a-z]+:\/\/[A-Za-z0-9._~:/?#[\]@!$&'()*+,;=-]+(\{referenceNumber})?[A-Za-z0-9._~:/?#[\]@!$&'()*+,;=-]*)$/.test(
    url,
  );
}

export function applyReferenceNumberPattern(
  referenceNumber: string,
  nodeType: EnumFlowNodeType,
  pattern: ProjectFolderDataReferenceNumberUrlPattern,
): string | null {
  if (pattern.urlTemplate && (!pattern.nodeTypes || pattern.nodeTypes.includes(nodeType))) {
    try {
      const reg = new RegExp(`^${pattern.numberRegex}$`, 'i');
      if (reg.test(referenceNumber)) {
        return referenceNumber.replace(reg, pattern.urlTemplate.replace(/\{referenceNumber}/g, referenceNumber));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  return null;
}

export function getReferenceNumberUrlForAncestry({ancestors, projectFolders}: NodeAncestry): string {
  const node = ancestors[0];
  if (!node) {
    return '';
  }
  for (const folder of projectFolders) {
    const patterns = folder.data?.referenceNumberUrlPatterns || [];
    for (const pattern of patterns) {
      const result = applyReferenceNumberPattern(node.referenceNumber, node.nodeType, pattern);
      if (result !== null) {
        return result;
      }
    }
  }
  return '';
}

export const getReferenceNumberUrlSelector = createSelector(getNodeAncestrySelector, (getAncestry) =>
  memoize((nodeId: MaybeUuid): string => getReferenceNumberUrlForAncestry(getAncestry(nodeId, true))),
);

export function useReferenceNumberUrl(nodeId: MaybeUuid): string {
  return useSelector((s: FlowState) => getReferenceNumberUrlSelector(s)(nodeId));
}

export function isAdvancedRefNumberPattern(pattern: ProjectFolderDataReferenceNumberUrlPattern): boolean {
  return pattern.numberRegex !== simplePattern || pattern.nodeTypes !== null;
}

interface ReferenceNumberUrlProps {
  className?: string;
  nodeId: MaybeUuid;
}

function getDomain(refUrl: string): string {
  try {
    const url = new URL(refUrl);
    return url.hostname;
  } catch {
    return '';
  }
}

export function ReferenceNumberUrl({className, nodeId}: ReferenceNumberUrlProps): ReactElement | null {
  const refUrl = useReferenceNumberUrl(nodeId);
  if (!refUrl) {
    return null;
  }
  const domain = getDomain(refUrl);
  return (
    <Tooltip
      toolTipTranslation={
        domain
          ? {
              i18nKey: 'components:referenceNumberUrl.tooltip',
              values: {domain},
            }
          : ''
      }
    >
      <a className={cn('inline-block shrink-0', className)} target={'_blank'} rel={'noreferrer'} href={refUrl}>
        <ExternalLink className={'size-4'} />
      </a>
    </Tooltip>
  );
}
