import {EnumFlowNodeType, EnumFlowTaskStatus} from '@octaved/env/src/dbalEnumTypes';
import {error} from '@octaved/env/src/Logger';
import {emptyNodeSearchResult, withAncestors, withDescendants} from '@octaved/node-search/src/Factories/Tree';
import {
  getEffectiveSwpsNotPlanned,
  getEffectiveSwpsPlannedAroundDateRange,
  getEffectiveSwpsPlannedBeforeDate,
  getEffectiveTasksNotPlanned,
  getEffectiveTasksPlannedAroundDateRange,
  getEffectiveTasksPlannedBeforeDate,
  getEffectiveWpsNotPlanned,
  getEffectiveWpsPlannedAroundDateRange,
  getEffectiveWpsPlannedBeforeDate,
} from '@octaved/planning/src/NodeSearchPlanningFactories';
import {nativeDispatch} from '@octaved/store/src/Store';
import {DateStr} from '@octaved/typescript';
import {Uuid} from '@octaved/typescript/src/lib';
import {fromIsoFormat, toIsoFormat} from '@octaved/users/src/Culture/DateFormatFunctions';
import {
  currentOrgUserIdSelector,
  currentOrgUserSettingsSelector,
} from '@octaved/users/src/Selectors/CurrentOrgUserSelectors';
import {mapCache} from '@octaved/utilities';
import {memoize} from 'lodash';
import {createSelector} from 'reselect';
import {getVisibleTasksSearchCondition} from '../../../Components/Task/VisibleTasks';
import {FilterState} from '../../../EntityInterfaces/Filter/FilterState';
import {
  MyTasksFilterSet,
  MyTasksFilterSetIndexed,
  MyTasksFilterStates,
} from '../../../EntityInterfaces/Filter/MyWorkPackagesFilters';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {todayIsoDateSelector} from '../../../Today';
import {createAssignedProjectRoleIdsQuery} from '../../Filter/AssignedProjectRoleId';
import {openLikeWpOrSwpQuery} from '../../MyTasks/BaseCondition';
import {FilterPreset, MyTasksListTimeFrame, patchMyTasksUiState} from '../../UiPages/MyTasks';
import {applyNodeTextSearch} from '../NodeTextSearch';
import {rootFoldersUserFolderSelector} from '../RootFolderSelectors';
import {
  myTasksAdvancedFiltersSelector,
  myTasksFilterPresetSelector,
  myTasksSearchSelector,
  myTasksShowBlockedTasksSelector,
  myTasksShowDueDatesSelector,
  myTasksShowTasksOfOtherUsersSelector,
  myTasksVisibleTasksSelector,
} from '../UiPages/MyTasksSelectors';
import {workPackageOptionsUiSelector} from '../UiSelectors';

type NSC = NodeSearchCondition;

const emptyArray: unknown[] = [];

export const myTasksFilterSetsSelector = createSelector(
  currentOrgUserSettingsSelector,
  (settings) => settings.workspace?.filterSets || (emptyArray as MyTasksFilterSet[]),
);

export const myTasksActiveFiltersIndexedSelector = createSelector(
  myTasksFilterSetsSelector,
  (sets): MyTasksFilterSetIndexed[] => {
    return sets.map((set, index) => ({...set, index}));
  },
);

export const myTasksActivePresetSelector = createSelector(
  myTasksFilterPresetSelector,
  myTasksFilterSetsSelector,
  (storePreset, userPresets): FilterPreset => {
    return typeof storePreset === 'number' ? (userPresets[storePreset] ? storePreset : 'mine') : storePreset;
  },
  {devModeChecks: {identityFunctionCheck: 'never'}},
);

export const myTasksMineFilterPreset: Partial<MyTasksFilterStates> = {
  hasTasksIAmResponsibelFor: {isActive: true, value: true},
  iAmResponsibleForWorkPackage: {isActive: true, value: true},
  isFavorite: {isActive: true, value: true},
  workPackageIsNotCompleted: {isActive: true, value: true},
};

export const myTasksActiveFiltersSelector = createSelector(
  myTasksAdvancedFiltersSelector,
  myTasksFilterSetsSelector,
  myTasksActivePresetSelector,
  (advancedFilter, filterSets, preset): Partial<MyTasksFilterStates> => {
    if (preset === 'advanced') {
      return advancedFilter;
    }
    if (preset === 'mine') {
      return myTasksMineFilterPreset;
    }
    if (!filterSets[preset]) {
      error(`Missing preset '${preset}' - resetting to 'mine'`);
      setTimeout(() => nativeDispatch(patchMyTasksUiState({filterPreset: 'mine'})), 10);
      return myTasksMineFilterPreset;
    }
    return filterSets[preset].filters;
  },
  {devModeChecks: {identityFunctionCheck: 'never'}},
);

type DateTuple = [DateStr, DateStr];
export type MyTasksTimeFrame = MyTasksListTimeFrame | 'timeline';

const timeFrameDaysToAdd = {'7days': 6, '30days': 29, today: 0};

/**
 * Gets tuples of start/end dates that are relevant for the time frame.
 *
 * For the timeline we use the same logic as the calendar events:
 *    - We use the current week, one week before and one week after the current week.
 *    - This ensures that we only make week-wise requests to the server and not for every individual time slot.
 */
export const getStartEndDates = mapCache((timeFrame: MyTasksTimeFrame, today: DateStr): DateTuple[] => {
  const dateTuples: DateTuple[] = [];
  if (timeFrame === 'timeline') {
    const _today = fromIsoFormat(today);
    dateTuples.push([toIsoFormat(_today.subtract(2, 'w')), toIsoFormat(_today.add(1, 'y'))]);
  } else if (timeFrame !== 'all') {
    //When not on the timeline, we use the exact date range:
    dateTuples.push([today, toIsoFormat(fromIsoFormat(today).add(timeFrameDaysToAdd[timeFrame], 'days'))]);
  }
  return dateTuples;
});

function createDateTupleConditionsWithFn(fn: (start: DateStr, end: DateStr) => NSC, dateTuples: DateTuple[]): NSC {
  return {or: dateTuples.map(([start, end]) => fn(start, end))};
}

function createDateTupleConditions(key: 'pidHasDueDate' | 'pidHasMilestoneDate', dateTuples: DateTuple[]): NSC {
  return createDateTupleConditionsWithFn((start, end) => [key, `${start}-${end}`], dateTuples);
}

const allTasks: NSC = ['nodeType', EnumFlowNodeType.VALUE_TASK];
const allWps: NSC = ['nodeType', EnumFlowNodeType.VALUE_WORK_PACKAGE];
const allSwps: NSC = ['nodeType', EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE];
const allWpsOrSwps: NSC = {or: [allWps, allSwps]};
const allSwpTasks: NSC = {and: [allTasks, withDescendants(allSwps, true)]};
const allWpTasks: NSC = {and: [allTasks, withDescendants(allWps, true), {not: allSwpTasks}]};
const emptyResult: NSC = emptyNodeSearchResult;

const applyTaskFiltersSelector = createSelector(
  myTasksActiveFiltersSelector,
  todayIsoDateSelector,
  myTasksVisibleTasksSelector,
  myTasksShowBlockedTasksSelector,
  (filters: Partial<MyTasksFilterStates>, today, visibleTasks, showBlockedTasks) => (baseTasks: NSC) => {
    const tasks: NSC[] = [];
    tasks.push(baseTasks);
    tasks.push(getVisibleTasksSearchCondition(visibleTasks, today));
    if (!showBlockedTasks) {
      tasks.push({not: ['taskBlockedByDependency']});
    }
    if (filters.taskLabelIds?.isActive && filters.taskLabelIds.value.length) {
      tasks.push({or: filters.taskLabelIds.value.map((id) => ['labelId', id])});
    }
    return {and: tasks};
  },
);

const myTasksSelector = createSelector(
  currentOrgUserIdSelector,
  rootFoldersUserFolderSelector,
  (
    userId: Uuid,
    userNodeId: Uuid,
  ): {
    allMyTasks: NSC;
    myAssignedTasks: NSC;
    myPersonalTasks: NSC;
  } => {
    const myAssignedTasks: NSC = {and: [allTasks, ['responsibleUnitId', userId]]};
    const myPersonalTasks: NSC = {and: [allTasks, withDescendants(userNodeId, true)]};

    return {
      myAssignedTasks,
      myPersonalTasks,
      allMyTasks: {or: [myAssignedTasks, myPersonalTasks]},
    };
  },
);

function applyArchive(nodes: NSC): NSC {
  return {and: [nodes, {not: ['isArchived']}]};
}

const applyFavoritesSelector = createSelector(
  myTasksActiveFiltersSelector,
  currentOrgUserIdSelector,
  (filters: Partial<MyTasksFilterStates>, userId: Uuid) =>
    (wpsOrSwps: NSC): NSC => {
      if (filters.isFavorite?.isActive) {
        return {or: [wpsOrSwps, {and: [allWpsOrSwps, ['favorite', userId]]}]};
      }
      return wpsOrSwps;
    },
);

const overduesSelector = createSelector(todayIsoDateSelector, (today) => {
  const tasks: NSC = {
    and: [getEffectiveTasksPlannedBeforeDate(today), ['taskStatus', EnumFlowTaskStatus.VALUE_OPEN]],
  };
  const wps: NSC = {
    and: [getEffectiveWpsPlannedBeforeDate(today), {not: ['wpIsCompleted']}],
  };
  const swps: NSC = {
    and: [getEffectiveSwpsPlannedBeforeDate(today), {not: ['swpIsCompleted']}],
  };
  const wpsOrSwps: NSC = {or: [wps, swps]};
  return {tasks, wpsOrSwps};
});

const applyTimeFrameSelector = createSelector(
  overduesSelector,
  todayIsoDateSelector,
  (overdues, today) =>
    (timeFrame: MyTasksTimeFrame, base: NSC, type: 'tasks' | 'wpsOrSwps'): [NSC, NSC] => {
      let inTimeFrame: NSC;
      let notPlanned: NSC;

      if (timeFrame === 'all') {
        //we display everything in the "timeFrame" section (a.k.a. "due/overdue"):
        inTimeFrame = base;
        notPlanned = emptyResult;
      } else {
        const dateTuples = getStartEndDates(timeFrame, today);
        if (type === 'tasks') {
          const plannedTasks = createDateTupleConditionsWithFn(getEffectiveTasksPlannedAroundDateRange, dateTuples);
          inTimeFrame = {and: [{or: [overdues[type], plannedTasks]}, base]};
          notPlanned = {and: [getEffectiveTasksNotPlanned(), base]};
        } else {
          const plannedWps = createDateTupleConditionsWithFn(getEffectiveWpsPlannedAroundDateRange, dateTuples);
          const plannedSwps = createDateTupleConditionsWithFn(getEffectiveSwpsPlannedAroundDateRange, dateTuples);
          const plannedWpsOrSwps: NSC = {or: [plannedWps, plannedSwps]};
          inTimeFrame = {and: [{or: [overdues[type], plannedWpsOrSwps]}, base]};
          notPlanned = {and: [{or: [getEffectiveWpsNotPlanned(), getEffectiveSwpsNotPlanned()]}, base]};
        }
      }

      return [inTimeFrame, notPlanned];
    },
);

const getDueDateQuerySelector = createSelector(
  todayIsoDateSelector,
  myTasksShowDueDatesSelector,
  (today, showDueDates) =>
    (timeFrame: MyTasksTimeFrame, wpsOrSwps: NSC): NSC => {
      if (showDueDates) {
        const dateTuples = getStartEndDates(timeFrame, today);
        return {
          and: [
            {
              or: [
                createDateTupleConditions('pidHasDueDate', dateTuples),
                createDateTupleConditions('pidHasMilestoneDate', dateTuples),
              ],
            },
            withAncestors(wpsOrSwps, true),
          ],
        };
      }
      return emptyResult;
    },
);

function applyDescendantIdsFilter(
  conditions: NSC[],
  filters: Partial<MyTasksFilterStates>,
  key: 'projectFolderIds' | 'projectIds' | 'groupIds' | 'workPackageIds',
): void {
  const filter: FilterState<string[]> | undefined = filters[key];
  if (filter?.isActive && filter.value.length) {
    conditions.push({or: filter.value.map((id) => withDescendants(id, true))});
  }
}

const applySearchSelector = createSelector(
  myTasksSearchSelector,
  workPackageOptionsUiSelector,
  (search, {showPath}) =>
    (searchTarget: NSC, validDescendants: NSC | null): NSC => {
      const nodeTypes: EnumFlowNodeType[] = [
        EnumFlowNodeType.VALUE_SUB_WORK_PACKAGE,
        EnumFlowNodeType.VALUE_TASK,
        EnumFlowNodeType.VALUE_TASK_SECTION,
        EnumFlowNodeType.VALUE_WORK_PACKAGE,
      ];
      if (showPath) {
        nodeTypes.push(EnumFlowNodeType.VALUE_PROJECT);
        nodeTypes.push(EnumFlowNodeType.VALUE_GROUP);
      }
      return applyNodeTextSearch(search, nodeTypes, showPath, searchTarget, validDescendants);
    },
);

function tasksToWpsOrSwps(tasks: NSC): NSC {
  return {
    or: [
      {and: [allWps, withAncestors({and: [allWpTasks, tasks]}, false)]},
      {and: [allSwps, withAncestors({and: [allSwpTasks, tasks]}, false)]},
    ],
  };
}

export interface MyTasksWorkPackagesQueries {
  dueDateQuery: NSC;
  overdueVisibleTasksQuery: NSC;
  overdueVisibleWpsQuery: NSC;
  taskFilterQuerySelectorForPersonalList: () => NSC; //for <UserNodeGroup /> (no unplanned)
  taskFilterQuerySelectorForWpList: () => NSC; //for <WorkPackageGroup /> (planned + unplanned)
  visibleTimeFramePersonalTasksQuery: NSC;
  visibleTimeFrameWpTasksQuery: NSC;
  visibleNotPlannedWpsQuery: NSC;
  visibleTimeFrameWpsQuery: NSC;
}

export const myTasksWorkPackagesQuerySelector = createSelector(
  myTasksActiveFiltersSelector,
  applyFavoritesSelector,
  applySearchSelector,
  applyTaskFiltersSelector,
  applyTimeFrameSelector,
  getDueDateQuerySelector,
  currentOrgUserIdSelector,
  myTasksSelector,
  overduesSelector,
  myTasksShowTasksOfOtherUsersSelector,
  (
    filters: Partial<MyTasksFilterStates>,
    applyFavorites,
    applySearch,
    applyTaskFilters,
    applyTimeFrame,
    getDueDateQuery,
    currentOrgUserId,
    {myAssignedTasks, myPersonalTasks, allMyTasks},
    overdues,
    showTasksOfOtherUsers,
  ) =>
    memoize((timeFrame: MyTasksTimeFrame): MyTasksWorkPackagesQueries => {
      const conditions: NSC[] = [allWpsOrSwps];

      {
        const assignedConditions: NSC[] = [];

        if (filters.myProjectRoleIds?.isActive && filters.myProjectRoleIds.value.length) {
          assignedConditions.push(createAssignedProjectRoleIdsQuery(filters.myProjectRoleIds.value, currentOrgUserId)!);
        }

        if (filters.iAmResponsibleForWorkPackage?.isActive) {
          assignedConditions.push(['responsibleUnitId', currentOrgUserId]);
        }

        if (filters.hasTasksIAmResponsibelFor?.isActive) {
          const searchedAndFilteredDirectTasks = applySearch(applyTaskFilters(myAssignedTasks), null);
          const [directTimeFrameTasks, directNotPlannedTasks] = applyTimeFrame(
            timeFrame,
            searchedAndFilteredDirectTasks,
            'tasks',
          );

          assignedConditions.push(tasksToWpsOrSwps({or: [directTimeFrameTasks, directNotPlannedTasks]}));
        }

        if (assignedConditions.length) {
          conditions.push({or: assignedConditions});
        }
      }

      if (filters.workPackageIsNotCompleted?.isActive) {
        conditions.push({not: ['wpIsCompleted']});
        conditions.push({not: ['swpIsCompleted']});
      }

      if (filters.hasOpenTasks?.isActive) {
        conditions.push(tasksToWpsOrSwps(['taskStatus', EnumFlowTaskStatus.VALUE_OPEN]));
      }

      if (filters.customerIds?.isActive && filters.customerIds.value.length) {
        // `customerId` yields pid-ids only, but we want SWPs, too:
        conditions.push(withDescendants({or: filters.customerIds.value.map((id) => ['customerId', id])}, true));
      }

      applyDescendantIdsFilter(conditions, filters, 'projectFolderIds');
      applyDescendantIdsFilter(conditions, filters, 'projectIds');
      applyDescendantIdsFilter(conditions, filters, 'groupIds');
      applyDescendantIdsFilter(conditions, filters, 'workPackageIds');

      if (filters.workPackageLabelIds?.isActive && filters.workPackageLabelIds.value.length) {
        conditions.push({or: filters.workPackageLabelIds.value.map((id) => ['labelId', id])});
      }

      //Push the "open-like" work package query before the favorites - we still show closed favorites:
      conditions.push(openLikeWpOrSwpQuery);

      //Apply archive after favorites - we show closed favorites, but not archived:
      const nonArchived: NSC = applyArchive(applyFavorites({and: conditions}));

      const [timeFrameWpsOrSwps, notPlannedWpsOrSwps] = applyTimeFrame(timeFrame, nonArchived, 'wpsOrSwps');

      const tasks: NSC = showTasksOfOtherUsers ? allTasks : allMyTasks;
      const tasksSearchedAndFiltered = applySearch(applyTaskFilters(tasks), null);
      const [timeFrameTasks, notPlannedTasks] = applyTimeFrame(timeFrame, tasksSearchedAndFiltered, 'tasks');

      //We show all work packages in the "timeFrame" section if they themselves are part of it, or they have any
      // task that is part of it:
      const timeFrameTaskAncestors: NSC = tasksToWpsOrSwps(timeFrameTasks);
      //We combine the self-planned wps with the non-planned wps with planned tasks:
      const wpsOrSwpsInTimeFrame: NSC = {or: [timeFrameWpsOrSwps, {and: [nonArchived, timeFrameTaskAncestors]}]};
      //The rest are non-planned wps that also have no planned tasks:
      const wpsOrSwpsInNotPlanned: NSC = {and: [notPlannedWpsOrSwps, {not: timeFrameTaskAncestors}]};

      const wpsOrSwpsInTimeFrameSearched = applySearch(wpsOrSwpsInTimeFrame, {
        //the time frame wps also show their not-planned tasks, so they are valid search descendants, too:
        or: [timeFrameTasks, notPlannedTasks],
      });
      const wpsOrSwpsInNotPlannedSearched = applySearch(wpsOrSwpsInNotPlanned, notPlannedTasks);

      //Visible time frame work package tasks are finally those that belong to a visible
      // work package. The generic wp filters have been applied to these wps:
      const visibleTimeFrameWpTasks: NSC = {
        and: [timeFrameTasks, withDescendants(wpsOrSwpsInTimeFrameSearched, true)],
      };

      //The same goes for not planned tasks, but they can appear below a planned or not planned work package:
      const visibleNotPlannedTasks: NSC = {
        and: [
          notPlannedTasks,
          withDescendants({or: [wpsOrSwpsInTimeFrameSearched, wpsOrSwpsInNotPlannedSearched]}, true),
        ],
      };

      //we don't display unplanned personal tasks in the "not planned" section
      const visibleTimeFramePersonalTasks: NSC = {and: [timeFrameTasks, myPersonalTasks]};

      //Only time frame tasks can be overdue, so we just seek those:
      const overdueVisibleTasks: NSC = {
        and: [{or: [visibleTimeFrameWpTasks, visibleTimeFramePersonalTasks]}, overdues.tasks],
      };

      const visibleWpTasks: NSC = {or: [visibleTimeFrameWpTasks, visibleNotPlannedTasks]};

      return {
        dueDateQuery: getDueDateQuery(timeFrame, wpsOrSwpsInTimeFrameSearched), //due dates only used in timeline
        overdueVisibleTasksQuery: overdueVisibleTasks,
        overdueVisibleWpsQuery: {and: [wpsOrSwpsInTimeFrameSearched, overdues.wpsOrSwps]},
        taskFilterQuerySelectorForPersonalList: (): NSC => visibleTimeFramePersonalTasks,
        taskFilterQuerySelectorForWpList: (): NSC => visibleWpTasks,
        visibleNotPlannedWpsQuery: wpsOrSwpsInNotPlannedSearched,
        visibleTimeFramePersonalTasksQuery: visibleTimeFramePersonalTasks,
        visibleTimeFrameWpsQuery: wpsOrSwpsInTimeFrameSearched,
        visibleTimeFrameWpTasksQuery: visibleTimeFrameWpTasks,
      };
    }),
);
