import {FlowState} from '@octaved/flow/src/Modules/State';
import Konva from 'konva';
import {createStoreSubscription} from '../../StoreSubscription';
import {Bar} from '../Bar';
import {BarPlugin} from './BarPlugin';

export abstract class BarRendererPlugin extends BarPlugin {
  protected rect = new Konva.Rect({
    cornerRadius: 3,
    strokeWidth: 1,
  });

  init(bar: Bar): void {
    super.init(bar);

    this.rect.height(this.bar.height);
    this.rect.width(this.bar.width);

    this.root.add(this.rect);
    this.root.name('opacity');

    this.disposables.push(
      createStoreSubscription(
        this.ctx.store,
        (s) => this.getStyleColor(s),
        (color) => this.style(color),
      ),
    );
  }

  protected abstract getStyleColor(state: FlowState): string;
  protected abstract style(styleColor: string): void;

  onBarUpdated(): void {
    this.rect.width(this.bar.width);
  }
}
