import {motion} from 'framer-motion';
import {CheckCircle} from 'lucide-react';
import {ReactElement} from 'react';
import useBackdropShaking from '@octaved/flow/src/Drawer/BackdropShaking';
import {Tooltip} from '../../Feedback';
import {IconButton} from './IconButton';

interface Props {
  isDisabled?: boolean;
  isSaving?: boolean;
  label?: string;
  save: () => void;
  useDrawerBackdropShaking?: boolean;
}

function SaveButton({
  isDisabled,
  isSaving,
  label = 'timeRecordings:save',
  save,
  useDrawerBackdropShaking,
}: Props): ReactElement {
  const {controls} = useBackdropShaking(!!useDrawerBackdropShaking && !isDisabled);

  return (
    <Tooltip toolTipTranslation={'tooltips:general.buttonSaveDisabled'} position={'top center'} disabled={!isDisabled}>
      <div className={'save'}>
        <motion.div animate={controls}>
          <IconButton
            label={label}
            icon={<CheckCircle strokeWidth={'2'} />}
            onClick={save}
            loading={isSaving}
            isDisabled={isDisabled}
          />
        </motion.div>
        {/*#region styles*/}
        {/*language=SCSS*/}
        <style jsx>{`
          .save {
            display: flex;
            max-width: 800px;
            flex-direction: column;
            align-items: center;
          }
        `}</style>
        {/*#endregion*/}
      </div>
    </Tooltip>
  );
}

export {SaveButton};
