import {Moment} from '@octaved/dayjs-moment';
import {ISO_DATE} from '@octaved/users/src/Culture/DateFormatFunctions';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {ReactElement, useMemo} from 'react';

export interface DayViewProps {
  day: Moment;
  isOutsideOfRange: boolean;
  hasBlockedDates: boolean;
}

export default function DayView({day, isOutsideOfRange, hasBlockedDates}: DayViewProps): ReactElement {
  const isToday = useMemo(() => dayjs().format(ISO_DATE) === day.format(ISO_DATE), [day]);
  return (
    <div className={classNames('date', {isDayWithinPlanned: !isOutsideOfRange, hasBlockedDates})}>
      {isOutsideOfRange && <div className={'backgroundStatus'} />}
      <div className={'date'}>
        <div className={classNames('numberOfMonth', {isToday})}>{day.format('D')}</div>
      </div>

      {/*#region styles*/}
      {/*language=scss*/}
      <style jsx>{`
        .date {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }

        .backgroundStatus {
          position: absolute;
          inset: -1px;
          background-color: transparent;
        }

        .hasBlockedDates:not(.isDayWithinPlanned) {
          background-color: var(--color-calendar-day-outside-bg);
          pointer-events: none;
        }

        .hasBlockedDates:not(.isDayWithinPlanned) {
          color: var(--color-calendar-day-outside-text);
        }

        :global(.CalendarDay__selected) :not(.isDayWithinPlanned) .backgroundStatus {
          background-color: var(--color-calendar-day-outside-selectedBg);
        }

        :global(.CalendarDay__selected) :not(.isDayWithinPlanned) {
          color: var(--color-bg);
        }

        :global(.CalendarDay__selected_span) :not(.isDayWithinPlanned) .backgroundStatus,
        :global(.CalendarDay__hovered_span) :not(.isDayWithinPlanned) .backgroundStatus {
          background-color: var(--color-calendar-day-outside-selectedSpanBg);
          color: var(--color-text);
        }

        :global(.CalendarDay__selected_span) :not(.isDayWithinPlanned) {
          color: var(--color-text);
        }

        :global(.CalendarDaynot:not(.CalendarDay__selected_span):not(.CalendarDay__selected):hover) .backgroundStatus {
          background-color: var(--color-calendar-day-within-hoverBg);
        }

        :global(.CalendarDay:not(.CalendarDay__selected_span):not(.CalendarDay__selected):hover)
          :not(.isDayWithinPlanned)
          .backgroundStatus {
          background-color: var(--color-calendar-day-outside-hoverBg);
        }

        :global(.CalendarDay:not(.CalendarDay__selected_span):not(.CalendarDay__selected):hover) .date {
          color: var(--color-text);
        }

        :global(.CalendarDay__selected) .status,
        :global(.CalendarDay__selected_span) .status {
          color: var(--color-bg);
        }

        .date {
          position: relative;
          z-index: 1;
        }

        .isToday {
          font-weight: 700;
          color: var(--color-calendar-today);
          text-decoration: underline;
        }
      `}</style>
      {/*#endregion*/}
    </div>
  );
}
