import {emptyNodeSearchResult} from '@octaved/node-search/src/Factories/Tree';
import {createSelector} from 'reselect';
import {ProjectFilterQueries} from '../../../EntityInterfaces/Filter/ProjectFilters';
import {NodeSearchCondition} from '../../../EntityInterfaces/NodeSearch';
import {
  EnumExecutionStatus,
  isWorkPackageNotStartedQuerySelector,
  isWorkPackageStartedQuerySelector,
} from '../../../WorkPackage/ExecutionStatus';

export const executionStatusQueryGeneratorSelector = createSelector(
  isWorkPackageStartedQuerySelector,
  isWorkPackageNotStartedQuerySelector,
  (isWorkPackageStartedQuery, isWorkPackageNotStartedQuery) => {
    return function executionStatusQueryGenerator(queries: ProjectFilterQueries, status: EnumExecutionStatus[]): void {
      if (status.length === 3) {
        return; //no need to filter anything
      }
      if (status.length === 0) {
        queries.projects.push(emptyNodeSearchResult); //If no checkbox is checked, do not show any projects
        return;
      }

      const executionConditions: NodeSearchCondition[] = [];
      if (status.includes(EnumExecutionStatus.notStarted)) {
        executionConditions.push(isWorkPackageNotStartedQuery);
      }
      if (status.includes(EnumExecutionStatus.started)) {
        executionConditions.push(isWorkPackageStartedQuery);
      }
      if (status.includes(EnumExecutionStatus.executed)) {
        executionConditions.push(['wpIsCompleted']);
      }
      queries.descendants.push({or: executionConditions});
      queries.workPackages.push({or: executionConditions});
    };
  },
);
