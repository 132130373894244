import {EnumFlowNodeType} from '@octaved/env/src/dbalEnumTypes';
import {getRestorableNodesFromTrash} from '@octaved/flow-api';
import {useStoreEffect} from '@octaved/hooks/src/StoreEffect';
import {CALL_API} from '@octaved/network/src/NetworkMiddlewareTypes';
import {createTimestampReducer, EntityStates, isOutdated, LOADED, LOADING} from '@octaved/store/src/EntityState';
import {createReducerCollection} from '@octaved/store/src/Reducer/CreateReducerCollection';
import {ActionDispatcher} from '@octaved/store/src/Store';
import {Uuid} from '@octaved/typescript/src/lib';
import {useSelector} from 'react-redux';
import {RestorableNodeFromTrash, StoreRestorableNodeFromTrash} from '../../EntityInterfaces/RestoreFromTrash';
import {
  FLOW_LOAD_RESTORABLE_NODES_FAILURE,
  FLOW_LOAD_RESTORABLE_NODES_REQUEST,
  FLOW_LOAD_RESTORABLE_NODES_SUCCESS,
} from '../ActionTypes';
import {NodeRestoredFromTrashEvent, NodesRemovedEvent} from '../Events';
import {restorableNodesSelector, restorableNodesStateSelector} from '../Selectors/RestoreFromTrashSelectors';
import {FlowState} from '../State';

interface LoadAction {
  parentNodeId: Uuid;
  response: RestorableNodeFromTrash[];
  type: typeof FLOW_LOAD_RESTORABLE_NODES_SUCCESS;
}

const reducers = createReducerCollection<StoreRestorableNodeFromTrash>({});
export const restorableNodesReducer = reducers.reducer;
const stateReducers = createReducerCollection<EntityStates>({});
export const restorableNodesStateReducer = stateReducers.reducer;

reducers.add<LoadAction>(FLOW_LOAD_RESTORABLE_NODES_SUCCESS, (state, {parentNodeId, response}) => {
  return {...state, [parentNodeId]: response.sort((a, b) => b.revisionStartDate - a.revisionStartDate)};
});

stateReducers.add(FLOW_LOAD_RESTORABLE_NODES_REQUEST, createTimestampReducer('parentNodeId', LOADING));
stateReducers.add(FLOW_LOAD_RESTORABLE_NODES_SUCCESS, createTimestampReducer('parentNodeId', LOADED));

stateReducers.add<NodesRemovedEvent | NodeRestoredFromTrashEvent>(
  ['flow.NodesRemovedEvent', 'flow.NodeRestoredFromTrashEvent'],
  () => ({}),
);

function loadRestorableNodes(parentNodeId: Uuid): ActionDispatcher<void, FlowState> {
  return (dispatch, getState) => {
    const state = restorableNodesStateSelector(getState())[parentNodeId];
    if (!state || isOutdated(state)) {
      dispatch({
        parentNodeId,
        [CALL_API]: {
          endpoint: getRestorableNodesFromTrash,
          options: {urlParams: {parentNodeId}},
          types: {
            failureType: FLOW_LOAD_RESTORABLE_NODES_FAILURE,
            requestType: FLOW_LOAD_RESTORABLE_NODES_REQUEST,
            successType: FLOW_LOAD_RESTORABLE_NODES_SUCCESS,
          },
        },
      });
    }
  };
}

export function useRestorableNodes(
  parentNodeId: Uuid | null | undefined,
  nodeTypes: ReadonlySet<EnumFlowNodeType>,
): RestorableNodeFromTrash[] | undefined {
  useStoreEffect(
    (dispatch) => parentNodeId && dispatch(loadRestorableNodes(parentNodeId)),
    [parentNodeId],
    restorableNodesStateSelector,
  );
  return useSelector((s: FlowState) => {
    const nodes = parentNodeId ? restorableNodesSelector(s)[parentNodeId] : undefined;
    return nodes && nodes.filter(({nodeType}) => nodeTypes.has(nodeType));
  });
}
